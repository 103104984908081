import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import MultiText from '../../Text/MultiText';
import PageText from '../../Text/PageText';
import Spacer from '../../Spacer';

import { SecondsToTime } from '../../../Functions';

const BarChild = styled.div`
    background-color: var(--${props => props.Color});
    border-radius: 10px;
    height: 10px;
    width: ${props => Math.min(props.Percentage, 100) || 0}%;
`;

const BarLineContainer = styled.div`
    background-color: var(--white);
    border: 2px solid var(--white-concrete);
    border-radius: 20px;
    ${props => !props.NoMargin && 'margin: 0px 5px;'}
    position: relative;
    width: 100%;
`;

const BarParent2 = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

const Container2 = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    padding: 5px 8px;
    width: 100%;
`;

const CurrentValueContainer = styled.div``;

const GoalContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: ${props => props.Percentage ? `${props.Percentage}%` : '0px'};
    ${props => props.Margin === 'bottom' && 'margin-bottom: 3px;'}
    ${props => props.Margin === 'top' && 'margin-top: 3px;'}

    > div:first-child {
        margin-bottom: -3px;
    }
`;

const VerticalBar = styled.div`
    height: 20px;
    width: 3px;
    background-color: var(--black);
    border-radius: 20px;
    position: absolute;
    top: -5px;
    left: ${props => props.Percentage}%;
`;

class Line extends React.Component {
    render() {
        var { t } = this.props;
        var { className, CompletionStatus, CurrentValue, GoalMax, GoalMin, HideCurrent, HideGoalText, IsTime, NoHeight, NoMargin, ProgressLine: { FillPercentage, GoalMaxPercentage, GoalMinPercentage }, Units } = this.props;

        var CompletionColor = CompletionStatus === 'low' ? 'abel-blue' : CompletionStatus === 'high' ? 'red-bittersweet' : 'green';

        var CurrentValueText = CurrentValue;
        var GoalMaxText = GoalMax;
        var GoalMinText = GoalMin;

        if (!!+IsTime) {
            CurrentValueText = SecondsToTime(CurrentValue);
            GoalMaxText = SecondsToTime(GoalMax);
            GoalMinText = SecondsToTime(GoalMin);
        }

        return (
            <Container2 className={className}>
                {
                    !HideCurrent &&
                    <>
                        <CurrentValueContainer>
                            <MultiText
                                Texts={[
                                    { FontFamily: "semibold", FontSize: "medium-1", Text: `${t('now')} -` },
                                    { FontColor: CompletionColor, FontFamily: "bold", FontSize: "small", Text: `${CurrentValueText} ${Units}` }
                                ]}
                            />
                        </CurrentValueContainer>

                        <Spacer Size="extra-extra-small" />
                    </>
                }

                <BarParent2>
                    {
                        (!!+GoalMax && !HideGoalText) ?
                        <GoalContainer Margin="bottom" Percentage={GoalMaxPercentage}>
                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${GoalMaxText}`} TextAlign="center" WordBreak="keep-all" />

                            <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={t('max')} TextAlign="center" WordBreak="keep-all" />
                        </GoalContainer>
                    :
                        !NoHeight ?
                        <Spacer Size="extra-small" />
                    :
                        null
                    }

                    <BarLineContainer NoMargin={NoMargin}>
                        <BarChild Color={CompletionColor} Percentage={FillPercentage} />
                        {!!+GoalMin && <VerticalBar Percentage={GoalMinPercentage} />}
                        {!!+GoalMax && <VerticalBar Percentage={GoalMaxPercentage} />}
                    </BarLineContainer>

                    {
                        (!!+GoalMin && !HideGoalText) ?
                        <GoalContainer Margin="top" Percentage={GoalMinPercentage}>
                            <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={t('min_minimum')} TextAlign="center" WordBreak="keep-all" />

                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${GoalMinText}`} TextAlign="center" WordBreak="keep-all" />
                        </GoalContainer>
                    :
                        !NoHeight ?
                        <Spacer Size="extra-small" />
                    :
                        null
                    }
                </BarParent2>
            </Container2>
        );
    }
}

Line.propTypes = {
    CompletionStatus: PropTypes.string,
    CurrentValue: PropTypes.number.isRequired,
    GoalMax: PropTypes.number,
    GoalMin: PropTypes.number,
    HideCurrent: PropTypes.bool,
    IsTime: PropTypes.bool,
    NoHeight: PropTypes.bool,
    NoMargin: PropTypes.bool,
    ProgressLine: PropTypes.object.isRequired,
    Units: PropTypes.string.isRequired
}

Line.defaultProps = {
    HideCurrent: false,
    IsTime: false,
    NoHeight: false,
    NoMargin: false
}

export default withTranslation()(Line);