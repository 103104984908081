import React from 'react';
import PropTypes from 'prop-types';
import { Container, MainContentContainer, PageContainer, SplitButtonContainer, SplitButtonsContainer, StyledSubmitButton } from './styles';

import Breadcrumbs from '../Breadcrumbs';
import Spacer from '../Spacer';
// import TopBar from '../TopBar';

class Page extends React.Component {
    renderSplitButtons = () => {
        var { ButtonOne, ButtonTwo } = this.props.renderSubmitButton.Buttons;
        // Disabled, OnClick, Title

        return (
            <SplitButtonsContainer>
                <SplitButtonContainer ButtonName="One" Disabled={ButtonOne.Disabled} onClick={ButtonOne.Disabled ? () => null : ButtonOne.OnClick}>{ButtonOne.Title}</SplitButtonContainer>
                <SplitButtonContainer ButtonName="Two" Disabled={ButtonTwo.Disabled} onClick={ButtonTwo.Disabled ? () => null : ButtonTwo.OnClick}>{ButtonTwo.Title}</SplitButtonContainer>
            </SplitButtonsContainer>
        );
    }

    render() {
        var { children, className, ContentWidth, MobileFriendly, NoBackground, NoMargin, NoPadding, NoTopSpacer, PageWidth, renderBreadcrumbs, renderSubmitButton } = this.props;

        return (
            <Container className={`page-container ${className}`} NoMargin={NoMargin} PageWidth={PageWidth}>
                {renderBreadcrumbs ? <Breadcrumbs Breadcrumbs={renderBreadcrumbs} MobileFriendly={MobileFriendly} /> : null}
                
                {
                    renderBreadcrumbs || !NoMargin || NoTopSpacer ?
                    <Spacer Size="small" />
                :
                    null
                }

                <PageContainer className="page-pagecontainer" NoBackground={NoBackground}>
                    {/* {renderTopBar ? <TopBar BackgroundColor={this.props.renderTopBar} /> : null} */}
                    <MainContentContainer className="page-maincontentcontainer" ContentWidth={ContentWidth} NoPadding={NoPadding}>
                        {children}
                    </MainContentContainer>

                    {
                        renderSubmitButton ?
                        renderSubmitButton.SplitButtons ?
                        this.renderSplitButtons()
                    :
                        <StyledSubmitButton {...renderSubmitButton} />
                    :
                        null
                    }

                </PageContainer>
            </Container>
        )
    }
}

Page.propTypes = {
    ContentWidth: PropTypes.string,
    MobileFriendly: PropTypes.bool,
    NoBackground: PropTypes.bool,
    NoMargin: PropTypes.bool,
    NoPadding: PropTypes.bool,
    NoTopSpacer: PropTypes.bool,
    PageWidth: PropTypes.string,
    renderBreadcrumbs: PropTypes.array,
    renderSubmitButton: PropTypes.object,
    SplitButtons: PropTypes.bool
}

Page.defaultProps = {
    SplitButtons: false
}

export default Page;