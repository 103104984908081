import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { GetGroupWorkouts } from '../../Services/Actions';
import moment from 'moment';

import CalendarHeader from '../../Components/Calendar/CalendarHeader';
import Loading from '../../Components/Loading';
import Spacer from '../../Components/Spacer';
import WeeklyView from '../../Components/Calendar/WeeklyView';

import AddWorkoutMenu from '../../Modals/AddWorkoutMenu';

class GroupWorkouts extends React.Component {
    _isMounted = false;

    state = {
        DayDate: null,
        Loading: true,
        ShowAddWorkoutModalMenu: false
    };

    componentDidMount() {
        this._isMounted = true;

        var { GroupId, StartingDate, WeeklyView_Difference, WeeklyView_EndDate, WeeklyView_StartDate } = this.props;

        // Calculate WeeklyView dates from StartingDate
        if (StartingDate) {
            var StartingDateWeek = moment(StartingDate).isoWeek();
            var TodayWeek = moment().isoWeek();
            
            WeeklyView_Difference = StartingDateWeek - TodayWeek;
            WeeklyView_StartDate = moment().add(WeeklyView_Difference, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
            WeeklyView_EndDate = moment().add(WeeklyView_Difference, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
        }

        this.props.GetGroupWorkouts({ EndDate: WeeklyView_EndDate, GroupId, StartDate: WeeklyView_StartDate, WeeklyDifference: WeeklyView_Difference }).then(() => {
            if (this._isMounted) this.setState({ Loading: false });
        });
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        if (!!prevProps.NeedsRefresh !== !!this.props.NeedsRefresh && !!this.props.NeedsRefresh) this.onUpdateDate(0);
    }

    onSelectDate = SelectedDate => {
        var { GroupId } = this.props;

        var EndDate, StartDate, WeeklyDifference;
        
        StartDate = moment(SelectedDate).startOf('isoWeek').format('YYYY-MM-DD');
        EndDate = moment(SelectedDate).endOf('isoWeek').format('YYYY-MM-DD');

        WeeklyDifference = moment(StartDate).isoWeekday(moment().isoWeekday()).diff(moment().format('YYYY-MM-DD'), 'weeks');
        
        this.props.GetGroupWorkouts({ EndDate, GroupId, StartDate, WeeklyDifference });
    }

    onToggleAddButton = (ShowAddWorkoutModalMenu, DayDate) => {
        this.setState({ ShowAddWorkoutModalMenu, DayDate: DayDate || null })
    }

    onUpdateDate = Direction => {
        var { GroupId, WeeklyView_Difference } = this.props;

        var WeeklyDifference = WeeklyView_Difference + Direction;
        var StartDate = moment().add(WeeklyDifference, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
        var EndDate = moment().add(WeeklyDifference, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');

        this.props.GetGroupWorkouts({ EndDate, GroupId, StartDate, WeeklyDifference });
    }

    renderAddWorkoutModalMenu = () => {
        var { GroupDetails } = this.props;
        var { DayDate, ShowAddWorkoutModalMenu } = this.state;

        if (ShowAddWorkoutModalMenu) {
            return <AddWorkoutMenu ForGroups SaveData={{ DayDate, GroupDetails }} Show={ShowAddWorkoutModalMenu} OnHide={() => this.onToggleAddButton(false)} />
        }
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { GroupWorkouts, OnMultiSelectWorkouts, SelectedWorkouts, TryingGetGroupWorkouts } = this.props;
        return (
            <>
                <div>
                    <CalendarHeader MonthlyView={false} OnSelectDate={SelectedDate => this.onSelectDate(SelectedDate)} OnUpdateDate={Direction => this.onUpdateDate(Direction)} SectionType="GroupWorkouts" />
                    
                    <Spacer Size="medium" />
                    
                    <WeeklyView
                        Loading={TryingGetGroupWorkouts}
                        MultiSelect={this.props.MultiSelect}
                        OnMultiSelectWorkouts={WorkoutId => OnMultiSelectWorkouts(WorkoutId)}
                        OnToggleAddButton={DayDate => this.onToggleAddButton(true, DayDate)}
                        SelectedWorkouts={SelectedWorkouts}
                        Type="GroupWorkouts"
                        Workouts={GroupWorkouts}
                    />
                </div>

                {this.renderAddWorkoutModalMenu()}
            </>
        );
    }
}

GroupWorkouts.propTypes = {
    GroupId: PropTypes.number.isRequired,
    MultiSelect: PropTypes.bool.isRequired,
    NeedsRefresh: PropTypes.bool.isRequired,
    OnMultiSelectWorkouts: PropTypes.func.isRequired,
    SelectedWorkouts: PropTypes.array.isRequired,
    StartingDate: PropTypes.string
}

const mapStateToProps = state => {
    return {
        GroupDetails: state.Group.GroupDetails,
        GroupWorkouts: state.Group.GroupWorkouts,
        WeeklyView_Difference: state.Group.WeeklyView_GroupWorkouts_Difference,
        WeeklyView_EndDate: state.Group.WeeklyView_GroupWorkouts_EndDate,
        WeeklyView_StartDate: state.Group.WeeklyView_GroupWorkouts_StartDate,
        
        TryingGetGroupWorkouts: state.Group.TryingGetGroupWorkouts,
        TryingGetGroupWorkoutsError: state.Group.TryingGetGroupWorkoutsError
    };
};
  
export default withTranslation()(connect(mapStateToProps, { GetGroupWorkouts } )(GroupWorkouts));