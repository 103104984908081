import { arrayMove } from '../../Functions';

import {
    TRYING_DELETE_ORGANIZATION_BRANCH,
    TRYING_DELETE_ORGANIZATION_BRANCH_SUCCESS,
    TRYING_DELETE_ORGANIZATION_BRANCH_ERROR,

    TRYING_DELETE_ORGANIZATION_MEMBER,
    TRYING_DELETE_ORGANIZATION_MEMBER_SUCCESS,
    TRYING_DELETE_ORGANIZATION_MEMBER_ERROR,

    TRYING_DELETE_ORGANIZATION_REGION,
    TRYING_DELETE_ORGANIZATION_REGION_SUCCESS,
    TRYING_DELETE_ORGANIZATION_REGION_ERROR,

    TRYING_DOWNLOAD_ORGANIZATION_INCOME_REPORT,
    TRYING_DOWNLOAD_ORGANIZATION_INCOME_REPORT_SUCCESS,
    TRYING_DOWNLOAD_ORGANIZATION_INCOME_REPORT_ERROR,

    TRYING_GET_ORGANIZATION_INCOME_REPORT,
    TRYING_GET_ORGANIZATION_INCOME_REPORT_SUCCESS,
    TRYING_GET_ORGANIZATION_INCOME_REPORT_ERROR,

    TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES,
    TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES_SUCCESS,
    TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES_ERROR,

    TRYING_GET_TRAINER_CONTRACTS,
    TRYING_GET_TRAINER_CONTRACTS_SUCCESS,
    TRYING_GET_TRAINER_CONTRACTS_ERROR,

    TRYING_INSERTEDIT_ORGANIZATION_BRANCH,
    TRYING_INSERTEDIT_ORGANIZATION_BRANCH_SUCCESS,
    TRYING_INSERTEDIT_ORGANIZATION_BRANCH_ERROR,

    TRYING_INSERT_EDIT_ORGANIZATION_REGION,
    TRYING_INSERT_EDIT_ORGANIZATION_REGION_SUCCESS,
    TRYING_INSERT_EDIT_ORGANIZATION_REGION_ERROR,

    TRYING_INVITE_ORGANIZATION_MEMBERS,
    TRYING_INVITE_ORGANIZATION_MEMBERS_SUCCESS,
    TRYING_INVITE_ORGANIZATION_MEMBERS_ERROR,

    TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION,
    TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION_SUCCESS,
    TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION_ERROR,

    TRYING_SAVE_ORGANIZATION_CUSTOMIZATION,
    TRYING_SAVE_ORGANIZATION_CUSTOMIZATION_SUCCESS,
    TRYING_SAVE_ORGANIZATION_CUSTOMIZATION_ERROR,

    TRYING_UPDATE_ORGANIZATION_INCOME,
    TRYING_UPDATE_ORGANIZATION_INCOME_SUCCESS,
    TRYING_UPDATE_ORGANIZATION_INCOME_ERROR,

    TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS,
    TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS_SUCCESS,
    TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS_ERROR,

    TRYING_UPLOAD_ORGANIZATION_PICTURE,
    TRYING_UPLOAD_ORGANIZATION_PICTURE_SUCCESS,
    TRYING_UPLOAD_ORGANIZATION_PICTURE_ERROR,

    TRYING_VIEW_ORGANIZATION_BRANCH,
    TRYING_VIEW_ORGANIZATION_BRANCH_SUCCESS,
    TRYING_VIEW_ORGANIZATION_BRANCH_ERROR,

    CLEAR_ORGANIZATION_DASHBOARD,
    TRYING_VIEW_ORGANIZATION_DASHBOARD,
    TRYING_VIEW_ORGANIZATION_DASHBOARD_SUCCESS,
    TRYING_VIEW_ORGANIZATION_DASHBOARD_ERROR,

    TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES,
    TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_SUCCESS,
    TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_ERROR,

    TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS,
    TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS_SUCCESS,
    TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS_ERROR,

    TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS,
    TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_SUCCESS,
    TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_ERROR,

    TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS,
    TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS_SUCCESS,
    TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS_ERROR,

    TRYING_VIEW_ORGANIZATION_MEMBERS,
    TRYING_VIEW_ORGANIZATION_MEMBERS_SUCCESS,
    TRYING_VIEW_ORGANIZATION_MEMBERS_ERROR
} from '../Types';

const INITIAL_STATE = {
    Income: {},
    IncomeShare: null,
    Members: { Accepted: [], Pending: [] },
    OrganizationDetails: {},
    OrganizationIncome: {},
    OrganizationRegionsAndBranches: [],
    
    TryingDeleteOrganizationBranch: false,
    TryingDeleteOrganizationBranchError: null,
    TryingDeleteOrganizationMember: false,
    TryingDeleteOrganizationMemberError: null,
    TryingDeleteOrganizationRegion: false,
    TryingDeleteOrganizationRegionError: null,
    TryingDownloadOrganizationIncomeReport: false,
    TryingDownloadOrganizationIncomeReportError: null,
    TryingGetOrganizationIncome: false,
    TryingGetOrganizationIncomeError: null,
    TryingGetTrainerContracts: false,
    TryingGetTrainerContractsError: null,
    TryingInsertEditOrganizationBranch: false,
    TryingInsertEditOrganizationBranchError: null,
    TryingInsertEditOrganizationRegion: false,
    TryingInsertEditOrganizationRegionError: null,
    TryingInviteOrganizationMembers: false,
    TryingInviteOrganizationMembersError: null,
    TryingGetOrganizationRegionsAndBranches: false,
    TryingGetOrganizationRegionsAndBranchesError: null,
    TryingMoveOrganizationBranchToNewRegion: false,
    TryingMoveOrganizationBranchToNewRegionError: null,
    TryingSaveOrganizationCustomization: false,
    TryingSaveOrganizationCustomizationError: null,
    TryingUpdateOrganizationIncome: false,
    TryingUpdateOrganizationIncomeError: null,
    TryingUpdateOrganizationMemberDetails: false,
    TryingUpdateOrganizationMemberDetailsError: null,
    TryingUploadOrganizationPicture: false,
    TryingUploadOrganizationPictureError: null,
    TryingViewOrganizationBranch: false,
    TryingViewOrganizationBranchError: null,
    TryingViewOrganizationDashboard: false,
    TryingViewOrganizationDashboardError: null,
    TryingViewOrganizationMemberContractInvoices: false,
    TryingViewOrganizationMemberContractInvoicesError: null,
    TryingViewOrganizationMemberContractInvoicesFilters: false,
    TryingViewOrganizationMemberContractInvoicesFiltersError: null,
    TryingViewOrganizationMemberContracts: false,
    TryingViewOrganizationMemberContractsError: null,
    TryingViewOrganizationMemberContractsFilters: false,
    TryingViewOrganizationMemberContractsFiltersError: null,
    TryingViewOrganizationMembers: false,
    TryingViewOrganizationMembersError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_DELETE_ORGANIZATION_BRANCH:
            return { ...state, TryingDeleteOrganizationBranch: true, TryingDeleteOrganizationBranchError: null };
        case TRYING_DELETE_ORGANIZATION_BRANCH_ERROR:
            return { ...state, TryingDeleteOrganizationBranch: false, TryingDeleteOrganizationBranchError: action.payload };
        case TRYING_DELETE_ORGANIZATION_BRANCH_SUCCESS:
            return { ...state, TryingDeleteOrganizationBranch: false, TryingDeleteOrganizationBranchError: null };

        case TRYING_DELETE_ORGANIZATION_MEMBER:
            return { ...state, TryingDeleteOrganizationMember: true, TryingDeleteOrganizationMemberError: null };
        case TRYING_DELETE_ORGANIZATION_MEMBER_ERROR:
            return { ...state, TryingDeleteOrganizationMember: false, TryingDeleteOrganizationMemberError: action.payload };
        case TRYING_DELETE_ORGANIZATION_MEMBER_SUCCESS:
            var { MemberIndex, MemberType } = action.payload;

            var DeletedMembers = { ...state.Members };
            DeletedMembers[MemberType] = [ ...DeletedMembers[MemberType].slice(0, MemberIndex), ...DeletedMembers[MemberType].slice(MemberIndex + 1) ];

            return { ...state, Members: DeletedMembers, TryingDeleteOrganizationMember: false, TryingDeleteOrganizationMemberError: null };

        case TRYING_DELETE_ORGANIZATION_REGION:
            return { ...state, TryingDeleteOrganizationRegion: true, TryingDeleteOrganizationRegionError: null };
        case TRYING_DELETE_ORGANIZATION_REGION_ERROR:
            return { ...state, TryingDeleteOrganizationRegion: false, TryingDeleteOrganizationRegionError: action.payload };
        case TRYING_DELETE_ORGANIZATION_REGION_SUCCESS:
            // eslint-disable-next-line
            var { RegionIndex } = action.payload;

            var DeletedOrganizationRegionsAndBranches = [ ...state.OrganizationRegionsAndBranches ];

            // Move Branches From Deleted To Region 0
                if (DeletedOrganizationRegionsAndBranches[RegionIndex].Branches.length) {
                    // If Region 0 Does Not Exist Yet, Then Add It
                        if (DeletedOrganizationRegionsAndBranches[DeletedOrganizationRegionsAndBranches.length - 1].RegionId !== 0) {
                            DeletedOrganizationRegionsAndBranches.push({
                                RegionId: 0,
                                RegionName: '',
                                Branches: []
                            })
                        }

                    // Add Branches To Region 0
                        DeletedOrganizationRegionsAndBranches[DeletedOrganizationRegionsAndBranches.length - 1].Branches = [
                            ...DeletedOrganizationRegionsAndBranches[DeletedOrganizationRegionsAndBranches.length - 1].Branches,
                            ...DeletedOrganizationRegionsAndBranches[RegionIndex].Branches
                        ]
                }

            DeletedOrganizationRegionsAndBranches = [ ...DeletedOrganizationRegionsAndBranches.slice(0, RegionIndex), ...DeletedOrganizationRegionsAndBranches.slice(RegionIndex + 1) ];

            return { ...state, OrganizationRegionsAndBranches: DeletedOrganizationRegionsAndBranches, TryingDeleteOrganizationRegion: false, TryingDeleteOrganizationRegionError: null };

        case TRYING_DOWNLOAD_ORGANIZATION_INCOME_REPORT:
            return { ...state, TryingDownloadOrganizationIncomeReport: true, TryingDownloadOrganizationIncomeReportError: null };
        case TRYING_DOWNLOAD_ORGANIZATION_INCOME_REPORT_ERROR:
            return { ...state, TryingDownloadOrganizationIncomeReport: false, TryingDownloadOrganizationIncomeReportError: action.payload };
        case TRYING_DOWNLOAD_ORGANIZATION_INCOME_REPORT_SUCCESS:
            return { ...state, TryingDownloadOrganizationIncomeReport: false, TryingDownloadOrganizationIncomeReportError: null };

        case TRYING_GET_ORGANIZATION_INCOME_REPORT:
            return { ...state, TryingGetOrganizationIncome: true, TryingGetOrganizationIncomeError: null };
        case TRYING_GET_ORGANIZATION_INCOME_REPORT_ERROR:
            return { ...state, TryingGetOrganizationIncome: false, TryingGetOrganizationIncomeError: action.payload };
        case TRYING_GET_ORGANIZATION_INCOME_REPORT_SUCCESS:
            return { ...state, OrganizationIncome: action.payload, TryingGetOrganizationIncome: false, TryingGetOrganizationIncomeError: null };

        case TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES:
            return { ...state, TryingGetOrganizationRegionsAndBranches: true, TryingGetOrganizationRegionsAndBranchesError: null };
        case TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES_ERROR:
            return { ...state, TryingGetOrganizationRegionsAndBranches: false, TryingGetOrganizationRegionsAndBranchesError: action.payload };
        case TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES_SUCCESS:
            return { ...state, OrganizationRegionsAndBranches: action.payload, TryingGetOrganizationRegionsAndBranches: false, TryingGetOrganizationRegionsAndBranchesError: null };

        case TRYING_GET_TRAINER_CONTRACTS:
            return { ...state, TryingGetTrainerContracts: true, TryingGetTrainerContractsError: null };
        case TRYING_GET_TRAINER_CONTRACTS_ERROR:
            return { ...state, TryingGetTrainerContracts: false, TryingGetTrainerContractsError: action.payload };
        case TRYING_GET_TRAINER_CONTRACTS_SUCCESS:
            return { ...state, TryingGetTrainerContracts: false, TryingGetTrainerContractsError: null };

        case TRYING_INSERTEDIT_ORGANIZATION_BRANCH:
            return { ...state, TryingInsertEditOrganizationBranch: true, TryingInsertEditOrganizationBranchError: null };
        case TRYING_INSERTEDIT_ORGANIZATION_BRANCH_ERROR:
            return { ...state, TryingInsertEditOrganizationBranch: false, TryingInsertEditOrganizationBranchError: action.payload };
        case TRYING_INSERTEDIT_ORGANIZATION_BRANCH_SUCCESS:
            // eslint-disable-next-line
            var { BankAccountNo, BranchId, BranchName, BranchIndex, NewBranchId, RegionId, RegionIndex } = action.payload;

            var InsertEditOrganizationRegionBranch = [ ...state.OrganizationRegionsAndBranches ];

            // New Branch Added
                if (!BranchId) {
                    InsertEditOrganizationRegionBranch[RegionIndex].Branches.push({
                        BranchId: NewBranchId,
                        BranchName: "",
                        RegionId,
                        BankAccountNo: ""
                    });

                    BranchIndex = InsertEditOrganizationRegionBranch[RegionIndex].Branches.length - 1;
                }
            
            InsertEditOrganizationRegionBranch[RegionIndex].Branches[BranchIndex].BankAccountNo = BankAccountNo;
            InsertEditOrganizationRegionBranch[RegionIndex].Branches[BranchIndex].BranchName = BranchName;

            return { ...state, OrganizationRegionsAndBranches: InsertEditOrganizationRegionBranch, TryingInsertEditOrganizationBranch: false, TryingInsertEditOrganizationBranchError: null };

        case TRYING_INSERT_EDIT_ORGANIZATION_REGION:
            return { ...state, TryingInsertEditOrganizationRegion: true, TryingInsertEditOrganizationRegionError: null };
        case TRYING_INSERT_EDIT_ORGANIZATION_REGION_ERROR:
            return { ...state, TryingInsertEditOrganizationRegion: false, TryingInsertEditOrganizationRegionError: action.payload };
        case TRYING_INSERT_EDIT_ORGANIZATION_REGION_SUCCESS:
            // eslint-disable-next-line
            var { NewRegionId, RegionId, RegionIndex, RegionName } = action.payload;

            var InsertEditOrganizationRegion = [ ...state.OrganizationRegionsAndBranches ];

            // New Region Added
                if (!RegionId) {
                    InsertEditOrganizationRegion.push({
                        RegionId: NewRegionId,
                        RegionName: '',
                        Branches: []
                    });

                    // Change To Be Second Last If No Region Obj Exists
                        if (InsertEditOrganizationRegion[InsertEditOrganizationRegion.length - 2].RegionId === 0) {
                            InsertEditOrganizationRegion = arrayMove(InsertEditOrganizationRegion, InsertEditOrganizationRegion.length - 2, InsertEditOrganizationRegion.length - 1);
                        }

                    RegionIndex = InsertEditOrganizationRegion.length - 2;
                }

            InsertEditOrganizationRegion[RegionIndex].RegionName = RegionName;

            return { ...state, OrganizationRegionsAndBranches: InsertEditOrganizationRegion, TryingInsertEditOrganizationRegion: false, TryingInsertEditOrganizationRegionError: null };

        case TRYING_INVITE_ORGANIZATION_MEMBERS:
            return { ...state, TryingInviteOrganizationMembers: true, TryingInviteOrganizationMembersError: null };
        case TRYING_INVITE_ORGANIZATION_MEMBERS_ERROR:
            return { ...state, TryingInviteOrganizationMembers: false, TryingInviteOrganizationMembersError: action.payload };
        case TRYING_INVITE_ORGANIZATION_MEMBERS_SUCCESS:
            return { ...state, TryingInviteOrganizationMembers: false, TryingInviteOrganizationMembersError: null };

        case TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION:
            return { ...state, TryingMoveOrganizationBranchToNewRegion: true, TryingMoveOrganizationBranchToNewRegionError: null };
        case TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION_ERROR:
            return { ...state, TryingMoveOrganizationBranchToNewRegion: false, TryingMoveOrganizationBranchToNewRegionError: action.payload };
        case TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION_SUCCESS:
            return { ...state, TryingMoveOrganizationBranchToNewRegion: false, TryingMoveOrganizationBranchToNewRegionError: null };

        case TRYING_SAVE_ORGANIZATION_CUSTOMIZATION:
            return { ...state, TryingSaveOrganizationCustomization: true, TryingSaveOrganizationCustomizationError: null };
        case TRYING_SAVE_ORGANIZATION_CUSTOMIZATION_ERROR:
            return { ...state, TryingSaveOrganizationCustomization: false, TryingSaveOrganizationCustomizationError: action.payload };
        case TRYING_SAVE_ORGANIZATION_CUSTOMIZATION_SUCCESS:
            // eslint-disable-next-line
            var { LightMode, SignatureColor, TextColor } = action.payload;

            var SaveOrgOrganizationDetails = { ...state.OrganizationDetails, LightMode, SignatureColor, TextColor };

            return { ...state, OrganizationDetails: SaveOrgOrganizationDetails, TryingSaveOrganizationCustomization: false, TryingSaveOrganizationCustomizationError: null };

        case TRYING_UPDATE_ORGANIZATION_INCOME:
            return { ...state, TryingUpdateOrganizationIncome: true, TryingUpdateOrganizationIncomeError: null };
        case TRYING_UPDATE_ORGANIZATION_INCOME_ERROR:
            return { ...state, TryingUpdateOrganizationIncome: false, TryingUpdateOrganizationIncomeError: action.payload };
        case TRYING_UPDATE_ORGANIZATION_INCOME_SUCCESS:
            var { IncomeShare } = action.payload;

            return { ...state, IncomeShare, TryingUpdateOrganizationIncome: false, TryingUpdateOrganizationIncomeError: null };

        case TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS:
            return { ...state, TryingUpdateOrganizationMemberDetails: true, TryingUpdateOrganizationMemberDetailsError: null };
        case TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS_ERROR:
            return { ...state, TryingUpdateOrganizationMemberDetails: false, TryingUpdateOrganizationMemberDetailsError: action.payload };
        case TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS_SUCCESS:
            // eslint-disable-next-line
            var { IncomeShare, MemberIndex } = action.payload;

            var Members = { ...state.Members };
            Members.Accepted[MemberIndex].IncomeShare = IncomeShare;

            return { ...state, Members, TryingUpdateOrganizationMemberDetails: false, TryingUpdateOrganizationMemberDetailsError: null };

        case TRYING_UPLOAD_ORGANIZATION_PICTURE:
            return { ...state, TryingUploadOrganizationPicture: true, TryingUploadOrganizationPictureError: null };
        case TRYING_UPLOAD_ORGANIZATION_PICTURE_ERROR:
            return { ...state, TryingUploadOrganizationPicture: false, TryingUploadOrganizationPictureError: action.payload };
        case TRYING_UPLOAD_ORGANIZATION_PICTURE_SUCCESS:
            var { MediaUrl } = action.payload;

            var UpdateOrganizationPictureOrgDetails = { ...state.OrganizationDetails, OrganizationImage: MediaUrl };

            return { ...state, OrganizationDetails: UpdateOrganizationPictureOrgDetails, TryingUploadOrganizationPicture: false, TryingUploadOrganizationPictureError: null };
        
        case TRYING_VIEW_ORGANIZATION_BRANCH:
            return { ...state, TryingViewOrganizationBranch: true, TryingViewOrganizationBranchError: null };
        case TRYING_VIEW_ORGANIZATION_BRANCH_ERROR:
            return { ...state, TryingViewOrganizationBranch: false, TryingViewOrganizationBranchError: action.payload };
        case TRYING_VIEW_ORGANIZATION_BRANCH_SUCCESS:
            // eslint-disable-next-line
            var { Coaches, Month, OrganizationBranchIndex, Year } = action.payload;

            var NewIncomeBranch = { ...state.Income };
            NewIncomeBranch[`${Year}`][`${Month}`].Branches[OrganizationBranchIndex].Coaches = Coaches;

            return { ...state, Income: NewIncomeBranch, TryingViewOrganizationBranch: false, TryingViewOrganizationBranchError: null };

        case CLEAR_ORGANIZATION_DASHBOARD:
            return { ...state, Income: {}, IncomeShare: null, Members: { Accepted: [], Pending: [] }, OrganizationDetails: {} };
        case TRYING_VIEW_ORGANIZATION_DASHBOARD:
            return { ...state, TryingViewOrganizationDashboard: true, TryingViewOrganizationDashboardError: null };
        case TRYING_VIEW_ORGANIZATION_DASHBOARD_ERROR:
            return { ...state, TryingViewOrganizationDashboard: false, TryingViewOrganizationDashboardError: action.payload };
        case TRYING_VIEW_ORGANIZATION_DASHBOARD_SUCCESS:
            // eslint-disable-next-line
            var { Branches, Details, Month, MonthlyIncome, Year } = action.payload;

            var NewIncome = { ...state.Income };
            NewIncome[`${Year}`] = { ...NewIncome[`${Year}`], [`${Month}`]: { Branches, MonthlyIncome } };

            return { ...state, Income: NewIncome, OrganizationDetails: Details, TryingViewOrganizationDashboard: false, TryingViewOrganizationDashboardError: null };

        case TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES:
            return { ...state, TryingViewOrganizationMemberContractInvoices: true, TryingViewOrganizationMemberContractInvoicesError: null };
        case TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_ERROR:
            return { ...state, TryingViewOrganizationMemberContractInvoices: false, TryingViewOrganizationMemberContractInvoicesError: action.payload };
        case TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_SUCCESS:
            return { ...state, TryingViewOrganizationMemberContractInvoices: false, TryingViewOrganizationMemberContractInvoicesError: null };

        case TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS:
            return { ...state, TryingViewOrganizationMemberContractInvoicesFilters: true, TryingViewOrganizationMemberContractInvoicesFiltersError: null };
        case TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS_ERROR:
            return { ...state, TryingViewOrganizationMemberContractInvoicesFilters: false, TryingViewOrganizationMemberContractInvoicesFiltersError: action.payload };
        case TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS_SUCCESS:
            return { ...state, TryingViewOrganizationMemberContractInvoicesFilters: false, TryingViewOrganizationMemberContractInvoicesFiltersError: null };

        case TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS:
            return { ...state, TryingViewOrganizationMemberContracts: true, TryingViewOrganizationMemberContractsError: null };
        case TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_ERROR:
            return { ...state, TryingViewOrganizationMemberContracts: false, TryingViewOrganizationMemberContractsError: action.payload };
        case TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_SUCCESS:
            return { ...state, TryingViewOrganizationMemberContracts: false, TryingViewOrganizationMemberContractsError: null };

        case TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS:
            return { ...state, TryingViewOrganizationMemberContractsFilters: true, TryingViewOrganizationMemberContractsFiltersError: null };
        case TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS_ERROR:
            return { ...state, TryingViewOrganizationMemberContractsFilters: false, TryingViewOrganizationMemberContractsFiltersError: action.payload };
        case TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS_SUCCESS:
            return { ...state, TryingViewOrganizationMemberContractsFilters: false, TryingViewOrganizationMemberContractsFiltersError: null };
        
        case TRYING_VIEW_ORGANIZATION_MEMBERS:
            return { ...state, TryingViewOrganizationMembers: true, TryingViewOrganizationMembersError: null };
        case TRYING_VIEW_ORGANIZATION_MEMBERS_ERROR:
            return { ...state, TryingViewOrganizationMembers: false, TryingViewOrganizationMembersError: action.payload };
        case TRYING_VIEW_ORGANIZATION_MEMBERS_SUCCESS:
            // eslint-disable-next-line
            var { AcceptedUsers: Accepted, IncomeShare, PendingUsers: Pending } = action.payload;

            var NewMembers = { Accepted, Pending };

            return { ...state, IncomeShare, Members: NewMembers, TryingViewOrganizationMembers: false, TryingViewOrganizationMembersError: null };

        default:
            return state;
    }
};
