import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DeliverySite_GetCompanies_Basic, DeliverySite_GetTopLeaderDetails, DeliverySite_GetTopLeaders, DeliverySite_InsertEditTopLeader } from '../../../Services/Actions';
import Select from 'react-select';
import Popover from 'react-popover';

import * as styles from './styles';

import ButtonGroup from '../../../Components/Buttons/ButtonGroup';
import Loading from '../../../Components/Loading';
import MultiText from '../../../Components/Text/MultiText';
import PageText from '../../../Components/Text/PageText';
import PaginationContainer from '../../../Components/PaginationCounter';
import ProfilePhoto from '../../../Components/ProfilePhoto';
import Spacer from '../../../Components/Spacer';

import Info from '../../../Modals/Info';

import DownArrow from '../../../Assets/Icons/DownArrow.png';
import InfoIcon from '../../../Assets/Icons/Info.png';
import Plus from '../../../Assets/Icons/PlusSign_White.png';
import UpArrow from '../../../Assets/Icons/UpArrow.png';

import { emailValidator } from '../../../Functions';

const TOP_LEADER_TABLE_COLUMNS = [
    { Id: 1, SortCol: null, Title: 'User Id' },
    { Id: 2, SortCol: 'DisplayName', Title: 'Name' }
];

const customStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "initial"
    }),
    input: (provided, state) => ({
        ...provided,
        minWidth: '20%'
    })
}

class TopLeadersComponent extends React.Component {
    TimeoutId = null;

    state = {
        DisplayName_Previous: '',
        DisplayName: '',
        TopLeaders: [],
        IsMoreResults: null,
        PageNo: 1,
        PageSize: 10,
        Sorting: {
            SortCol: 'DisplayName',
            SortDir: 'ASC'
        },
        TotalRecords: null,

        Filters: {
            Companies: {
                Companies: [],
                IsLoading: false,
                Loaded: false,
                Selected: []
            }
        },

        PopoverIsOpen_Companies: false,
        PopoverIsOpen_Users: false,

        ShowAddTopLeaderModal: false,
        ShowAddTopLeaderModal_TopLeaderEmail: '',
        ShowAddTopLeaderModal_Loading: false,

        ShowTopLeaderDetailModal: false,
        ShowTopLeaderDetailModal_Data: {},
        ShowTopLeaderDetailModal_Loading: false
    }

    componentDidMount() {
        this.onLoadTopLeaders();
    }

    onAddTopLeader = () => {
        var { ShowAddTopLeaderModal_TopLeaderEmail: Email } = this.state;

        this.setState({ ShowAddTopLeaderModal_Loading: true }, () => {
            this.props.DeliverySite_InsertEditTopLeader({ Email }).then(() => {
                this.onToggleShowAddTopLeaderModal({ ShowAddTopLeaderModal: false });
                this.onLoadTopLeaders();
            })
        })
    }

    onBlurFilter_Companies = () => {
        this.setState({ PageNo: 1 }, () => this.onLoadTopLeaders());
    }

    onBlurSearchTopLeaders = () => {
        var { DisplayName, DisplayName_Previous } = this.state;

        if (DisplayName !== DisplayName_Previous) {
            this.setState({ DisplayName_Previous: DisplayName, PageNo: 1 }, () => this.onLoadTopLeaders());
        }
    }

    onChangeAddLeaderModal_TopLeaderEmail = event => {
        this.setState({ ShowAddTopLeaderModal_TopLeaderEmail: event.target.value });
    }

    onChangeTopLeaderSearch = event => {
        this.setState({ DisplayName: event.target.value });
    }

    onChangeFilter_Companies = data => {
        if (!data) data = [];
        
        var Filters = { ...this.state.Filters };
        Filters.Companies.Selected = data;

        this.setState({ Filters });
    }

    onFocusFilter_Companies = () => {
        if (!this.state.Filters.Companies.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.Companies = { ...Filters.Companies };
            Filters.Companies.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetCompanies_Basic({}).then(({ Companies }) => {
                    Filters.Companies.IsLoading = false;
                    Filters.Companies.Companies = Companies;
                    Filters.Companies.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onLoadTopLeaders = Increment => {
        var { DisplayName, Filters: { Companies }, PageNo, PageSize, Sorting: { SortCol, SortDir } } = this.state;

        var CompanyIds = Companies.Selected.length > 0 ? Companies.Selected.map(({ CompanyId }) => CompanyId).join(',') : null;

        if (Increment) PageNo++;

        this.props.DeliverySite_GetTopLeaders({ CompanyIds, TopLeaderName: DisplayName, PageNo, PageSize, SortCol, SortDir }).then(({ TopLeaders, IsMoreResults, TotalRecords }) => {
            this.setState({ TopLeaders, IsMoreResults, TotalRecords });
        });
    }

    onPaginate = ({ NewPageNo }) => {
        if (this.statePageNo !== NewPageNo) this.setState({ PageNo: NewPageNo }, () => this.onLoadTopLeaders());
    }

    onTogglePopover = PopoverType => {
        this.setState({ [PopoverType]: !this.state[PopoverType] }, () => {
            if (!this.state[PopoverType] && !!this.TimeoutId) clearTimeout(this.TimeoutId);
        });
    }

    onToggleShowAddTopLeaderModal = ({ ShowAddTopLeaderModal }) => {
        if (!ShowAddTopLeaderModal) this.setState({ ShowAddTopLeaderModal, ShowAddTopLeaderModal_TopLeaderEmail: '', ShowAddTopLeaderModal_Loading: false });
        else this.setState({ ShowAddTopLeaderModal });
    }

    onToggleShowTopLeaderDetailModal = ({ TopLeader_UserId, ShowTopLeaderDetailModal }) => {
        if (!ShowTopLeaderDetailModal) this.setState({ ShowTopLeaderDetailModal, ShowTopLeaderDetailModal_Loading: false });
        else {
            this.setState({ ShowTopLeaderDetailModal, ShowTopLeaderDetailModal_Loading: true }, () => {
                this.props.DeliverySite_GetTopLeaderDetails({ TopLeader_UserId }).then(({ UserDetails }) => {
                    this.setState({ ShowTopLeaderDetailModal_Data: UserDetails, ShowTopLeaderDetailModal_Loading: false });
                })
            });
        }
    }

    onToggleSorting = ({ SortCol: NewSortCol }) => {
        var { SortCol, SortDir } = this.state.Sorting;

        // SortDir Goes From ASC --> DESC --> Nothing

        // Toggle Sorting Of Already Sorted Column
            if (SortCol === NewSortCol) {
                if (SortDir === 'ASC') SortDir = 'DESC';
                else if (SortDir === 'DESC') {
                    SortDir = '';
                    SortCol = '';
                }
                else if (SortDir === '') SortDir = 'ASC';
            }
        // Start Sorting On New Column
            else {
                SortCol = NewSortCol;
                SortDir = 'ASC';
            }

        this.setState({ PageNo: 1, Sorting: { SortCol, SortDir } }, () => this.onLoadTopLeaders());
    }

    renderAddTopLeaderModal = () => {
        var { t } = this.props;
        var { ShowAddTopLeaderModal, ShowAddTopLeaderModal_TopLeaderEmail, ShowAddTopLeaderModal_Loading } = this.state;

        if (ShowAddTopLeaderModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onAddTopLeader}
                    BottomButtonDisabled={!ShowAddTopLeaderModal_TopLeaderEmail || !emailValidator(ShowAddTopLeaderModal_TopLeaderEmail)}
                    BottomButtonText={t('_save')}
                    InformationRenderer={this.renderAddTopLeaderModal_Information}
                    Loading={ShowAddTopLeaderModal_Loading}
                    OnHide={() => this.onToggleShowAddTopLeaderModal({ ShowAddTopLeaderModal: false })}
                    Show={ShowAddTopLeaderModal}
                />
            );
        }
    }

    renderAddTopLeaderModal_Information = () => {
        var { ShowAddTopLeaderModal_TopLeaderEmail } = this.state;

        return (
            <styles.InsertEditModalContainer className="InsertEditModalContainer">
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text="Add Top Leader" TextAlign="center" />

                <Spacer Size="extra-small" />

                <styles.SearchInput
                    AutoFocus
                    FontSize="medium-1"
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeAddLeaderModal_TopLeaderEmail}
                    Placeholder="Top leader email"
                    Size="medium"
                    Type="text"
                    Value={ShowAddTopLeaderModal_TopLeaderEmail}
                />
            </styles.InsertEditModalContainer>
        );
    }

    renderTopLeaders = () => {
        var { TopLeaders, PageNo, PageSize, Sorting: { SortCol: StateSortCol, SortDir: StateSortDir }, TotalRecords } = this.state;

        return (
            <styles.BodyContainer className="BodyContainer">
                <styles.BodyHeader className="BodyHeader">
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text="Top Leaders" TextAlign="left" />

                    <styles.BodyHeaderRight className="BodyHeaderRight">
                        <ButtonGroup
                            Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onToggleShowAddTopLeaderModal({ ShowAddTopLeaderModal: true }), Title: "New Top Leader" }]}
                            ButtonWidth="fit-content"
                            ContainerWidth="fit-content"
                            JustifyContent="center"
                        />
                    </styles.BodyHeaderRight>
                </styles.BodyHeader>

                <Spacer Size="small" />

                {this.renderViewCount()}

                {
                    TotalRecords > 0 &&
                    <>
                        <Spacer Size="small" />

                        {
                            (PageNo !== 1 || PageSize < TotalRecords) &&
                            <>
                                <PaginationContainer OnPaginate={this.onPaginate} PageNo={PageNo} PageSize={PageSize} TotalRecords={TotalRecords} />
        
                                <Spacer Size="medium" />
                            </>
                        }

                        <styles.UsersContainer className="UsersContainer">
                            <styles.UsersTableContainer className="UsersTableContainer">
                                <styles.UsersTable className="UsersTable">
                                    <styles.UsersTableHeader className="UsersTableHeader">
                                        <styles.UsersTableHeaderRow className="UsersTableHeaderRow">
                                            {
                                                TOP_LEADER_TABLE_COLUMNS.map(({ Id, SortCol, Title }) => {
                                                    return (
                                                        <styles.UsersTableHeaderCell className="UsersTableHeaderCell" key={Id} onClick={SortCol ? () => this.onToggleSorting({ SortCol }) : null}>
                                                            <styles.UsersTableHeaderCellInnerDiv className="UsersTableHeaderCellInnerDiv">
                                                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Title} />
                                                                {SortCol ? StateSortCol === SortCol && <styles.SortingIcon ImageSrc={StateSortDir === 'DESC' ? DownArrow : UpArrow} /> : null}
                                                            </styles.UsersTableHeaderCellInnerDiv>
                                                        </styles.UsersTableHeaderCell>
                                                    );
                                                })
                                            }
                                        </styles.UsersTableHeaderRow>
                                    </styles.UsersTableHeader>
                                    <styles.UsersTableBody className="UsersTableBody">
                                        {
                                            TopLeaders.map((User, UserIndex) => {
                                                var {
                                                    DisplayName, ProfilePhotoUrl, Sex, UserId
                                                } = User;

                                                return (
                                                    <styles.UsersTableBodyRow className="UsersTableBodyRow" key={UserId}>
                                                        <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                            {UserId}
                                                        </styles.UsersTableBodyCell>
                                                        <styles.UsersTableBodyCell className="UsersTableBodyCell" onClick={() => this.onToggleShowTopLeaderDetailModal({ TopLeader_UserId: UserId, ShowTopLeaderDetailModal: true })}>
                                                            <styles.UsersTableBodyCellFlexed className="UsersTableBodyCellFlexed">
                                                                <styles.ProfilePhotoContainer className="ProfilePhotoContainer">
                                                                    <ProfilePhoto Sex={Sex || 'Male'} Size="small-3" Source={ProfilePhotoUrl || ''} />
                                                                </styles.ProfilePhotoContainer>
                                                                {DisplayName}
                                                            </styles.UsersTableBodyCellFlexed>
                                                        </styles.UsersTableBodyCell>
                                                    </styles.UsersTableBodyRow>
                                                )
                                            })
                                        }
                                    </styles.UsersTableBody>
                                </styles.UsersTable>
                            </styles.UsersTableContainer>
                        </styles.UsersContainer>
                    </>
                }
            </styles.BodyContainer>
        );
    }

    renderFilters = () => {
        var { t } = this.props;

        const popoverProps_Companies = {
            isOpen: this.state.PopoverIsOpen_Companies,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_Companies'),
            body: this.renderPopoverContent('Companies'),
            tipSize: 0.01
        }

        const popoverProps_Users = {
            isOpen: this.state.PopoverIsOpen_Users,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_Users'),
            body: this.renderPopoverContent('Users'),
            tipSize: 0.01
        }

        var { DisplayName, Filters: { Companies }} = this.state;

        return (
            <styles.FiltersContainer className="FiltersContainer">
                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Filters" TextAlign="left" />

                <Spacer Size="extra-small" />

                <styles.FiltersSubContainer className="FiltersSubContainer">
                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Search Top Leaders" />

                            <Popover {...popoverProps_Users}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_Users')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_Users')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <styles.SearchUsersInput
                            FontSize="medium-1"
                            NoLabel
                            NoMargin
                            OnBlur={this.onBlurSearchTopLeaders}
                            OnChange={this.onChangeTopLeaderSearch}
                            Placeholder={t('_search')}
                            Size="medium"
                            Type="text"
                            Value={DisplayName}
                        />
                    </styles.FilterContainer>

                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Companies" />

                            <Popover {...popoverProps_Companies}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_Companies')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_Companies')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            getOptionLabel={option => option.CompanyName}
                            getOptionValue={option => option.CompanyId}
                            isLoading={Companies.IsLoading}
                            isMulti
                            // components={{ ValueContainer }}
                            hideSelectedOptions={true}
                            isSearchable
                            onBlur={this.onBlurFilter_Companies}
                            onChange={data => this.onChangeFilter_Companies(data)}
                            onFocus={this.onFocusFilter_Companies}
                            options={Companies.Companies}
                            placeholder={t('Filter by Company')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>
                </styles.FiltersSubContainer>
            </styles.FiltersContainer>
        );
    }

    renderPopoverContent = ContentType => {
        // var { t } = this.props;

        return (
            <styles.PopoverContent className="PopoverContent">
                {ContentType === 'Companies' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Shows Top Leaders who have middle leaders who have leaders who have DHCs who have ACTIVE orders with employees in the selected companies" TextAlign="center" />}
                {ContentType === 'Users' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Search by first name, last name, full name or email address of the Top Leader" TextAlign="center" />}
            </styles.PopoverContent>
        );
    }

    renderShowTopLeaderDetailModal = () => {
        // var { t } = this.props;
        var { ShowTopLeaderDetailModal, ShowTopLeaderDetailModal_Loading } = this.state;

        if (ShowTopLeaderDetailModal) {
            return (
                <Info
                    BottomButton="cancel"
                    InformationRenderer={this.renderShowTopLeaderDetailModal_Information}
                    Loading={ShowTopLeaderDetailModal_Loading}
                    OnHide={() => this.onToggleShowTopLeaderDetailModal({ ShowTopLeaderDetailModal: false })}
                    Show={ShowTopLeaderDetailModal}
                    Size="small"
                />
            );
        }
    }

    renderShowTopLeaderDetailModal_Information = () => {
        var { ShowTopLeaderDetailModal_Data: UserDetails } = this.state;

        if (!UserDetails || Object.keys(UserDetails).length === 0) return null;

        var { DisplayName, DOB, Email, PhoneNumber, ProfilePhotoUrl, Sex } = UserDetails;

        return (
            <styles.DHCDetailModalInfoContainer className="DHCDetailModalInfoContainer">
                <styles.ProfilePhotoContainer_Popup className="ProfilePhotoContainer_Popup">
                    <ProfilePhoto Sex={Sex || 'Male'} Size="extra-large" Source={ProfilePhotoUrl || ''} />
                </styles.ProfilePhotoContainer_Popup>

                <Spacer Size="extra-small" />

                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin TextAlign="left" Text={DisplayName} />
                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Email} />

                {
                    PhoneNumber &&
                    <>
                        <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={PhoneNumber} />
                    </>
                }

                <styles.StyledHR />

                <styles.DHCDetailModalInfoFlexContainer className="DHCDetailModalInfoFlexContainer">
                    <MultiText
                        Texts={[
                            { FontColor: 'black', FontFamily: 'semibold-italic', FontSize: 'medium-1', Text: 'Sex:' },
                            { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: Sex }
                        ]}
                    />
                </styles.DHCDetailModalInfoFlexContainer>
                <styles.DHCDetailModalInfoFlexContainer className="DHCDetailModalInfoFlexContainer">
                    <MultiText
                        Texts={[
                            { FontColor: 'black', FontFamily: 'semibold-italic', FontSize: 'medium-1', Text: 'Birthday:' },
                            { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: DOB }
                        ]}
                    />
                </styles.DHCDetailModalInfoFlexContainer>
            </styles.DHCDetailModalInfoContainer>
        )
    }

    renderViewCount = () => {
        var { t } = this.props;

        var { PageNo, PageSize, TotalRecords } = this.state;

        if (!TotalRecords) TotalRecords = 0;

        return (
            <div className="ViewCount">
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${!TotalRecords ? 0 : ((PageNo - 1) * PageSize) + 1} - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                &nbsp;
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords} Top Leaders`} />
            </div>
        )
    }

    render() {
        // var { t } = this.props;

        return (
            <>
                {this.props.TryingDeliverySiteAction && <Loading />}

                {this.renderFilters()}
                {this.renderTopLeaders()}

                {this.renderAddTopLeaderModal()}
                {this.renderShowTopLeaderDetailModal()}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        TryingDeliverySiteAction: state.DeliverySite.TryingDeliverySiteAction,
        TryingDeliverySiteActionError: state.DeliverySite.TryingDeliverySiteActionError
    };
};

export default withTranslation()(connect(mapStateToProps, { DeliverySite_GetCompanies_Basic, DeliverySite_GetTopLeaderDetails, DeliverySite_GetTopLeaders, DeliverySite_InsertEditTopLeader } )(TopLeadersComponent));