import { w3cwebsocket as W3CWebSocket } from "websocket";
import { WS_URL } from '../../Config';

import {
    HANDLE_VISIBILITY_CHANGE,
    INIT_WS,
    RESIZE_WINDOW,

    TRYING_GENERATE_WORKOUT_TEMPLATE_PDF,
    TRYING_GENERATE_WORKOUT_TEMPLATE_PDF_SUCCESS
} from '../Types';

const INITIAL_STATE = {
    ClientDashboardDevice: null,
    Device: null,
    InnerWidth: null,
    IsHeaderMobile: null,
    IsHeaderMobileTwoRows: null,
    IsVerySmall: null,
    WindowVisible: null,
    WSClient: null,
    DownloadingFile: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INIT_WS:
            return { ...state, WSClient: new W3CWebSocket(WS_URL) };

        case HANDLE_VISIBILITY_CHANGE:
            return { ...state, WindowVisible: document.visibilityState === 'visible' ? true : false };

        case RESIZE_WINDOW:
            var ClientDashboardDevice = null;
            var Device = null;
            var IsHeaderMobile = null;
            var IsHeaderMobileTwoRows = null;
            var IsVerySmall = null;

            var InnerWidth = window.innerWidth;

            if (InnerWidth >= 1400) ClientDashboardDevice = 'laptop';
            else ClientDashboardDevice = 'mobile';

            if (InnerWidth >= 1126) Device = 'laptop';
            else if (InnerWidth >= 701) Device = 'ipad';
            else if (InnerWidth >= 451) Device = 'mobile';
            else Device = 'mobile_small';

            if (InnerWidth >= 901) IsHeaderMobile = false;
            else IsHeaderMobile = true;

            if (InnerWidth >= 615) IsHeaderMobileTwoRows = false;
            else IsHeaderMobileTwoRows = true;

            if (InnerWidth >= 351) IsVerySmall = false;
            else IsVerySmall = true;

            return { ...state, ClientDashboardDevice, Device, InnerWidth, IsHeaderMobile, IsHeaderMobileTwoRows, IsVerySmall };

        case TRYING_GENERATE_WORKOUT_TEMPLATE_PDF:
            return { ...state, DownloadingFile: true };
        case TRYING_GENERATE_WORKOUT_TEMPLATE_PDF_SUCCESS:
            return { ...state, DownloadingFile: false };

        default:
            return state;
    }
};
