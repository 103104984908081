import React from 'react';
import PropTypes from 'prop-types';

import { ButtonContainer, Container } from './styles';

import PageText from '../../Text/PageText';

class NumberInput extends React.Component {
    onChange = event => {
        this.props.OnChange(event.target.value);
    }

    onChangeNumber = (Direction, Disabled) => {
        if (!Disabled) this.props.OnChange(Direction);
    }

    render() {
        var { className, FontFamily, FontSize, MaxValue, MinValue, Value } = this.props;

        var MinusDisabled = MinValue && Value === MinValue;
        var PlusDisabled = MaxValue && Value === MaxValue;

        return (
            <Container className={className}>
                <ButtonContainer Disabled={MinusDisabled} onClick={() => this.onChangeNumber(-1, MinusDisabled)}>
                    <PageText FontFamily={FontFamily} FontSize={FontSize} NoMargin Text="-" />
                </ButtonContainer>
                <PageText FontFamily={FontFamily} FontSize={FontSize} NoMargin Text={`${Value ? Value : '-'}`} />
                <ButtonContainer Disabled={PlusDisabled} onClick={() => this.onChangeNumber(1, PlusDisabled)}>
                    <PageText FontFamily={FontFamily} FontSize={FontSize} NoMargin Text="+" />
                </ButtonContainer>
            </Container>            
        );
    }
}

NumberInput.propTypes = {
    FontFamily: PropTypes.string,
    FontSize: PropTypes.string,
    MaxValue: PropTypes.number,
    MinValue: PropTypes.number,
    OnChange: PropTypes.func.isRequired,
    Value: PropTypes.number.isRequired
};

export default NumberInput;