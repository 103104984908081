import styled from 'styled-components';

import Icon from '../../../Components/Icon';
import Image from '../../../Components/Image';
import Input from '../../../Components/Form/Input';
import PageText from '../../../Components/Text/PageText';

export const CenteredDivContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const CheckmarkItemName = styled(PageText)`
    ${props => props.Disabled && 'color: var(--gray-silver);'}
    margin-left: 10px;

    ${
        props => props.TwoBoxes &&
        `
            flex: 1;
            margin-right: 10px;
        `
    }
`;

export const CreateTagContainer = styled.div`
    margin-bottom: 10px;
    width: 100%;
`;

export const CreateTagsContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    width: 480px;
`;

export const CreateTagsHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    width: 480px;
`;

export const DragHandleIcon = styled(Icon)`
    height: 25px;
    width: 25px;
`;

export const EquipmentFilterContainer = styled.div`
    :not(:last-child) {
        margin-bottom: 25px;
    }
`;

export const FilterItem = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    padding: 7px 10px;
    width: 350px;
`;

export const FilterItemLeft = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    > div:first-child {
        margin-right: 10px;
    }
`;

export const FilterItemRight = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    > div:not(:first-child) {
        margin-left: 10px;
    }
`;

export const FiltersListContainer = styled.div`
    align-items: flex-start;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(2, max-content);
`;

export const FilterTagContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.JustifyContent ? props.JustifyContent : 'flex-start'};
    max-width: 100%;
    width: 200px;

    :not(:last-child) {
        margin-bottom: 7px;
    }
`;

export const HiddenFiltersListContainer = styled.div`
    > div:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const HiddenInstruction = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    > div:first-child {
        margin-right: 8px;
    }
`;

export const HiddenInstructionImageContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > svg {
        ${props => !!props.Hidden && `fill: #B1B1B1;`}

        width: 25px;

        ${
            props => !props.Disabled &&
            `
                @media(hover:hover) {
                    :hover {
                        cursor: pointer;
                        fill: var(--blue-abel);
                    }
                }
            `
        }
    }
`;

export const ReorderFilterItemsContainer = styled.div`
    > div:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const StyledNewTagInput = styled(Input)`
    flex: 1;

    > input {
        background-color: var(--white-concrete);
        border: none;
        border-radius: 10px;
    }
`;

export const StyledProfilePhoto = styled(Image)`
    height: 24px;
    margin-left: 10px;
    margin-right: -5px;
    width: 24px;

    ${props => !props.IsAbel && 'border-radius: 50%;'}
    overflow: hidden;
`;

export const VisibleFiltersListContainer = styled.div`
    > div:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const YourTagContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 7px 10px;

    > div:last-child {
        margin-left: 10px;
    }
`;

export const YourTagOptionsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const YourTagOptionsSVGContainer = styled.div`
    align-items: center;
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 35px;
    width: 35px;

    :first-child:not(:last-child) {
        margin-right: 10px;
    }

    > svg {
        width: 15px;
    }

    @media(hover:hover) {
        :hover {
            background-color: ${props => !!props.IsTrash ? 'var(--red-bittersweet)' : !!props.IsEdit ? 'var(--blue-astronaut)' : 'var(--white-concrete-hover)'};
            cursor: pointer;

            ${
                props => (!!props.IsTrash || !!props.IsEdit) &&
                `
                    > svg {
                        fill: var(--white);
                    }
                `
            }
        }
    }
`;

export const YourTagsContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 350px));
`;