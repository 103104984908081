import React from "react";
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

const { useState } = React;

const AddDaysButtonContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Disabled ? 'var(--gray)' : 'var(--blue-abel)'};
    color: ${props => props.Disabled ? 'var(--black)' : 'var(--white)'};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    width: 100%;

    ${props => !props.Disabled && `
        @media(hover:hover) {
            :hover {
                background-color: var(--blue-abel-hover);
                cursor: pointer;
            }
        }
    `}
`;

const CalendarDayContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const CalendarDayDot = styled.div`
    height: 7px;
    width: 7px;
    background-color: ${props => props.MatchingWorkout ? 'var(--blue-abel)' : 'var(--red-bittersweet)'};
    border-radius: 50%;
    display: inline-block;
`;

const CalendarDayOtherWorkoutsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-flow: wrap;
    gap: 5px;
    justify-content: center;
    margin-top: 5px;
    max-width: 30px;
`;

const CalendarDayTextContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Selected ? 'var(--blue-abel)' : 'var(--white)'};
    border: 2px solid ${props => props.Selected ? 'var(--blue-abel)' : 'var(--white-concrete)'};
    border-radius: 5px;
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    display: flex;
    flex-direction: column;
    height: 20px;
    justify-content: center;
    margin: 0 auto;
    width: 30px;

    @media(hover:hover) {
        :hover {
            background-color: var(--blue-abel);
            border-color: var(--blue-abel);
            color: var(--white);
            cursor: pointer;
        }
    }
`;

const CalendarDaysContainer = styled.div`
    align-items: flex-start;
    display: grid;
    grid-gap: 3px;
    grid-template-columns: repeat(7, 1fr);
`;

const StyledPopoverTitle = styled(PageText)``;

export default function CalendarOverlay(props) {
    const { t } = useTranslation();
    const [selectedDays, setSelectedDays] = useState([]);

    const SelectedDay = ({ DayNr, DaySelectedIndex }) => {
        var NewDays = [ ...selectedDays ];

        if (DaySelectedIndex !== -1) NewDays.splice(DaySelectedIndex, 1);
        else {
            if (props.ActionType === 'Move') NewDays = [ DayNr ];
            else NewDays = [ ...NewDays, DayNr ];
        }

        setSelectedDays(NewDays);
    }

    var Title = props.Title || t('meal_template_days');
    var SubmitButtonTitle = props.SubmitButtonTitle || t('add_to_these_days');

    return (
        <React.Fragment>
            <StyledPopoverTitle FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={Title} TextAlign="center" />
            
            <Spacer Size="small" />
            
            <CalendarDaysContainer className="CalendarDaysContainer">
                {
                    [ ...Array(props.NumDays || 0) ].map((e, i) => {
                        var DayNr = (i + 1);
                        var DaySelectedIndex = selectedDays.indexOf(DayNr);

                        return (
                            <CalendarDayContainer className="CalendarDayContainer" key={DayNr}>
                                <CalendarDayTextContainer className="CalendarDayTextContainer" onClick={() => SelectedDay({ DayNr, DaySelectedIndex })} Selected={DaySelectedIndex > -1}>
                                    <PageText FontFamily="semibold" FontSize="small" NoMargin Text={`${DayNr}`} />
                                </CalendarDayTextContainer>

                                <CalendarDayOtherWorkoutsContainer className="CalendarDayOtherWorkoutsContainer">
                                    {
                                        props.Days[i].DishIds.map((DishId, i2) => {
                                            var MatchingWorkout = DishId === `DishId-${props.DishId}`;

                                            return <CalendarDayDot key={i2} MatchingWorkout={MatchingWorkout} />
                                        })
                                    }
                                </CalendarDayOtherWorkoutsContainer>
                            </CalendarDayContainer>
                        );
                    })
                }
            </CalendarDaysContainer>

            <Spacer Size="small" />

            <AddDaysButtonContainer className="AddDaysButtonContainer" Disabled={!selectedDays.length} onClick={!selectedDays.length ? () => null : () => props.OnSelectDays(selectedDays)}>
                <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text={SubmitButtonTitle} TextAlign="center" />
            </AddDaysButtonContainer>
        </React.Fragment>
    );
}

CalendarOverlay.propTypes = {
    ActionType: PropTypes.string,
    NumDays: PropTypes.number.isRequired,
    OnSelectDays: PropTypes.func.isRequired,
    SubmitButtonTitle: PropTypes.string,
    Title: PropTypes.string
}

CalendarOverlay.defaultProps = {
    ActionType: 'Add',
    SubmitButtonTitle: '',
    Title: ''
}