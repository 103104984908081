import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddGroupWorkoutMembers, ClearGroupWorkoutDetail, DeleteGroupWorkouts, EditGroupWorkoutDetail, GetGroupMemberWorkoutCompletionStatus, GetGroupWorkoutDetail, SaveDataInProps, UpdateSelectedTab, UpdateWorkoutInformation } from '../../Services/Actions';
import moment from 'moment';
import history from '../../history';

import TimePicker from 'rc-time-picker';

import { AdditionalActivityItem, AdditionalActivityList, CircuitContainer, CircuitSeparator, ClientIdContainer, ClientNoteContainer, DateTimesContainer, DateTimeContainer, DateTimeUseAllDays, DateTimeUseAllDaysContainer, DurationContainer, DurationsContainer, EditingWorkoutNameContainer, FiltersContainer, HourType, HourTypesContainer, LapContainer, LapDetailsContainer, LapFooterContainer, LapHeaderContainer, LapHeaderContainerLeft, MemberCompletedStatusContainer, StyledDropdown, StyledDuration, StyledEditingWorkoutName, StyledEditWorkoutNameIcon, StyledIntensities, StyledPaginatedList, StyledProfilePhoto, StyledSearchInput, StyledWorkoutImage, StyledWorkoutNameInput, StyledWorkoutNoteTextArea, SurveyDataAveragesContainer, SurveyDataClientsContainer, SurveyDataContainer, WorkoutExercisesContainer, WorkoutInformationContainer, WorkoutInformationContainerInner, WorkoutLapsContainer, WorkoutNotesContainer } from './styles';

import ActiveLap from '../../Components/WorkoutLaps/ActiveLap';
import BreakLap from '../../Components/WorkoutLaps/BreakLap';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import { GroupMemberListItem } from '../../Components/Lists/ListItems';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import { TemplateExercise as TemplateExerciseComponent } from '../../Components/WorkoutExercise';

import AddGroupWorkoutMembersModal from '../../Modals/AddGroupWorkoutMembers';
import ChooseDates from '../../Modals/ChooseDates';
import ExerciseDetail from '../../Modals/ExerciseDetail';
import Info from '../../Modals/Info';

import DeleteX from '../../Assets/Icons/Delete_X.png';
import Edit_Light from '../../Assets/Icons/Edit_Light.png';
import Plus from '../../Assets/Icons/Plus.png';

const WorkoutTimeOptions = [ 30, 45, 60, 75, 90 ];

var i;
class WorkoutDetail extends React.Component {
    state = {
        EditingWorkoutName: false,
        LoadedWorkoutImage: false,
        Loading: true,
        OriginalWorkoutDetails: {},
        ShowChooseDatesModal: false,
        ShowDeleteWorkoutModal: false,

        GroupDetails: {},
        WorkoutDetails: {},

        GroupMembersPageNo: 1,
        GroupMembersPageSize: 30,

        LastSearchedValue: '',
        SearchValue: '',

        ActivityId: null,
        ActivityName: '',
        AdditionalActivities: [],
        AdditionalActivityDuration: 0,
        CustomName: '',
        DisplayingFilter: null,
        DropdownSize: 0,
        Duration: 0,
        HourType: 24,
        Intensities: [],
        IntensityId: null,
        Location: '',
        ShowAddExerciselModal: false,
        ShowAddWorkoutMembersModal: false,
        ShowDataTypeInfoModal: false,
        ShowDateTimes: null,
        ShowExerciseDetailModal: false,
        ShowExerciseDetailPreview: {},
        ShowLocation: null,
        TrainerNote: '',
        WorkoutExercises: [],
        WorkoutLaps: { Laps: [] },
        WorkoutType: null
    };

    componentDidMount() {
        this._isMounted = true;

        var { GroupWorkoutId } = this.props.match.params;
        GroupWorkoutId = +GroupWorkoutId;

        this.props.GetGroupWorkoutDetail({ GroupWorkoutId }).then(() => {
            if (this._isMounted && !this.props.TryingGetGroupWorkoutDetailError) {
                var { AdditionalActivityDetails, WorkoutDetails: { ActivityId, AssignedDate, CustomImage, CustomName, Duration, IntensityId, ShowWorkoutTime, TrainerNote, WorkoutLocation, WorkoutName, WorkoutType }, WorkoutExercises, WorkoutLaps } = this.props;

                AssignedDate = moment(AssignedDate.substring(0,10) + ' ' + AssignedDate.substring(11,16)).format('YYYY-MM-DD HH:mm');

                if (WorkoutType === 1 || WorkoutType === 3) WorkoutLaps = { ActiveType: null, CardioLevel: null, DataType: null, Laps: [] };
                if (WorkoutType === 2) WorkoutLaps.Laps = [].concat(...WorkoutLaps.Laps.map(WL => WL.Laps.map(Lap => ({ ...Lap, LapType: WL.Type }))));
                
                var { AdditionalActivities, Intensities } = AdditionalActivityDetails;
                var ActivityName = '';
                var IntensityName = '';

                for (i = 0; i < AdditionalActivities.length; i++) {
                    if (AdditionalActivities[i].value === ActivityId) {
                        ActivityName = AdditionalActivities[i].label;
                        break;
                    }
                }

                for (i = 0; i < Intensities.length; i++) {
                    if (Intensities[i].IntensityId === IntensityId) {
                        IntensityName = Intensities[i].IntensityName;
                        break;
                    }
                }

                var ShowDateTimes = ShowWorkoutTime;
                var ShowLocation = WorkoutLocation !== '' && WorkoutLocation !== null;

                this.setState({
                    ActivityId,
                    ActivityName,
                    AdditionalActivities,
                    AdditionalActivityDuration: Duration,
                    CustomImage,
                    CustomName,
                    GroupWorkoutId,
                    Intensities,
                    IntensityId,
                    IntensityName,
                    Loading: false,
                    Location: WorkoutLocation,
                    OriginalWorkoutDetails: { ActivityId, AdditionalActivityDuration: Duration, AssignedDate, Duration, IntensityId, Location: WorkoutLocation, ShowDateTimes, ShowLocation, WorkoutName },
                    ShowDateTimes,
                    ShowLocation,
                    TrainerNote,
                    WorkoutDetails: { ...this.props.WorkoutDetails, AssignedDate, Duration, WorkoutName },
                    WorkoutExercises,
                    WorkoutLaps,
                    WorkoutType
                });
            }
        });
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearGroupWorkoutDetail();
    }

    onAddGroupWorkoutMembers = UserIds => {
        var { GroupId } = this.props.GroupDetails;
        var { GroupMembersPageNo, GroupMembersPageSize, GroupWorkoutId } = this.state;

        this.onToggleShowAddWorkoutMembersModal(false);
        this.props.AddGroupWorkoutMembers({ GroupId, GroupWorkoutId, PageNo: GroupMembersPageNo, PageSize: GroupMembersPageSize, UserIds });
    }

    onBlurAdditionalActivityDuration = () => {
        var { AdditionalActivityDuration, OriginalWorkoutDetails: { AdditionalActivityDuration: OriginalAdditionalActivityDuration } } = this.state;
        if (`${AdditionalActivityDuration}`.trim() !== `${OriginalAdditionalActivityDuration}`.trim()) this.onEditGroupWorkoutDetail();
    }

    onBlurLocation = () => {
        var { Location, OriginalWorkoutDetails: { Location: OriginalLocation } } = this.state;
        if (Location.trim() !== OriginalLocation.trim()) {
            if (Location.trim() === '') this.setState({ ShowLocation: 0 });

            this.onEditGroupWorkoutDetail();
        }
    }

    onBlurWorkoutName = () => {
        this.setState({ EditingWorkoutName: false });
        if (this.state.WorkoutDetails.WorkoutName.trim() !== this.state.OriginalWorkoutDetails.WorkoutName.trim()) {
            if (this.state.WorkoutType === 3) this.setState({ ActivityCustomName: this.state.WorkoutDetails.WorkoutName.trim() });
            this.onEditGroupWorkoutDetail();
        }
    }

    onChangeAdditionalActivityDuration = event => {
        this.setState({ AdditionalActivityDuration: event.target.value });
    }

    onChangeDateTime = AssignedDate => {
        this.setState({ WorkoutDetails: { ...this.state.WorkoutDetails, AssignedDate: moment(AssignedDate).format('YYYY-MM-DD HH:mm') } });
    }

    onChangeHourType = HourType => {
        this.setState({ HourType });
    }

    onChangeLocation = event => {
        this.setState({ Location: event.target.value });
    }

    onChangeSearch = event => {
        this.setState({ SearchValue: event.target.value });
    }

    onChangeTrainerNote = event => {
        if (this.state.TrainerNote !== event.target.value.trim()) this.setState({ TrainerNote: event.target.value.trim() }, () => this.onEditGroupWorkoutDetail());
    }

    onChangeWorkoutName = event => {
        this.setState({ WorkoutDetails: { ...this.state.WorkoutDetails, WorkoutName: event.target.value } });
    }

    onCloseTimePicker = () => {
        var { OriginalWorkoutDetails: { AssignedDate: OriginalAssignedDate }, WorkoutDetails: { AssignedDate } } = this.state;

        if (AssignedDate !== OriginalAssignedDate) this.setState({ WorkoutDetails: { ...this.state.WorkoutDetails, AssignedDate } }, () => this.onEditGroupWorkoutDetail());
    }

    onDeleteGroupWorkout = () => {
        var { GroupWorkoutId } = this.props.match.params;

        this.onToggleShowDeleteWorkoutModal(false);
        this.props.DeleteGroupWorkouts([ GroupWorkoutId ]).then(() => {
            if (this._isMounted && !this.props.TryingDeleteGroupWorkoutsError) {
                history.push({ pathname: `/group/${this.props.GroupDetails.GroupId}`, state: { SelectedTab: 'Workouts', WorkoutDate: this.state.WorkoutDetails.AssignedDate } });
            }
        });
    }

    onEditGroupWorkoutDetail = () => {
        var { ActivityId, ActivityName, AdditionalActivityDuration, GroupWorkoutId, IntensityId, Location, ShowDateTimes, ShowLocation, TrainerNote, WorkoutDetails: { AssignedDate, Duration, WorkoutName }, WorkoutType } = this.state;

        AssignedDate = moment(AssignedDate).format('YYYY-MM-DD HH:mm');

        var CustomName = WorkoutName;
        if (WorkoutType === 3 && WorkoutName === ActivityName) CustomName = '';
        if (WorkoutType === 3) Duration = AdditionalActivityDuration;

        if (WorkoutType === 3 && !ShowDateTimes) AssignedDate = `${AssignedDate.substring(0,10)} 00:00`;

        this.props.EditGroupWorkoutDetail({ ActivityId, AssignedDate, CustomName, Duration, GroupWorkoutId, HasDateTimes: +ShowDateTimes, HasLocation: +ShowLocation, IntensityId, Location, TrainerNote, WorkoutType }).then(() => {
            if (!this.props.TryingEditGroupWorkoutDetailError) this.setState({ OriginalWorkoutDetails: { ActivityId, AssignedDate, Duration, Location, WorkoutName } });
            else {
                var { ActivityId: OriginalActivityId, AssignedDate: OriginalAssignedDate, Duration: OriginalDuration, WorkoutName: OriginalWorkoutName } = this.state.OriginalWorkoutDetails;

                this.setState({ ActivityId, IntensityId, Location, ShowDateTimes, ShowLocation, WorkoutDetails: { ...this.state.WorkoutDetails, ActivityId: OriginalActivityId, AssignedDate: OriginalAssignedDate, Duration: OriginalDuration, WorkoutName: OriginalWorkoutName } });
            }
        })
    }

    onGetSize = Height => {
        this.setState({ DropdownSize: Height });
    }

    onGoToUserWorkout = User => {
        history.push(`/workout/${User.WorkoutId}`);
    }

    onKeyPress = ({ key }) => {
        if (key === 'Enter' && !this.props.TryingSearchGroupMemberWorkoutCompletionStatus) {
            var { SearchValue, LastSearchedValue } = this.state;

            if (SearchValue !== LastSearchedValue) {
                this.setState({ GroupMembersPageNo: 1, GroupMembersPageSize: 30, LastSearchedValue: SearchValue }, () => this.onLoadMemberCompletedStatusUsers());
            }
        }
    }

    onLoadedWorkoutImage = () => {
        this.setState({ LoadedWorkoutImage: true });
    }

    onLoadMemberCompletedStatusUsers = Increment => {
        var { GroupMembersPageNo: PageNo, GroupMembersPageSize: PageSize, GroupWorkoutId, SearchValue } = this.state;

        if (Increment) PageNo = PageNo + 1;
        else PageNo = 1;

        this.setState({ GroupMembersPageNo: PageNo }, () => this.props.GetGroupMemberWorkoutCompletionStatus({ AddToExisting: Increment, GroupWorkoutId, PageNo, PageSize, UserSearch: SearchValue }));
    }

    onSelectAdditionalActivity = ({ ActivityId, ActivityName, ActivityPictures }) => {
        if (this.state.OriginalWorkoutDetails.OriginalActivityId !== ActivityId) this.setState({ WorkoutDetails: { ...this.state.WorkoutDetails, ActivityId, WorkoutName: this.state.ActivityName === this.state.WorkoutDetails.WorkoutName ? ActivityName : this.state.WorkoutDetails.WorkoutName, WorkoutImage: this.state.CustomImage ? this.state.WorkoutDetails.WorkoutImage : ActivityPictures[0] } });
        
        this.setState({ ActivityId, ActivityName, DisplayingFilter: null }, () => this.onEditGroupWorkoutDetail());
    }

    onSelectDates = SelectedDates => {
        if (this.state.WorkoutDetails.AssignedDate.substring(0,10) !== SelectedDates[0].substring(0,10)) this.setState({ WorkoutDetails: { ...this.state.WorkoutDetails, AssignedDate: moment(SelectedDates[0] + ' ' + this.state.WorkoutDetails.AssignedDate.substring(11,16)).format('YYYY-MM-DD HH:mm') }, ShowChooseDatesModal: false }, () => this.onEditGroupWorkoutDetail());
        else this.setState({ ShowChooseDatesModal: false });
    }

    onSelectDuration = Duration => {
        if (+this.state.WorkoutDetails.Duration !== +Duration) this.setState({ WorkoutDetails: { ...this.state.WorkoutDetails, Duration } }, () => this.onEditGroupWorkoutDetail());
    }

    onSelectIntensity = IntensityId => {
        this.setState({ IntensityId }, () => this.onEditGroupWorkoutDetail());
    }

    onToggleDropdown = (DisplayingFilter, ToggleStatus) => {
        this.setState({ DisplayingFilter: ToggleStatus ? DisplayingFilter : null });
    }

    onToggleEditingWorkoutName = EditingWorkoutName => {
        this.setState({ EditingWorkoutName });
    }

    onToggleShowAddWorkoutMembersModal = ShowAddWorkoutMembersModal => {
        this.setState({ ShowAddWorkoutMembersModal });
    }

    onToggleShowChooseDatesModal = ShowChooseDatesModal => {
        this.setState({ ShowChooseDatesModal });
    }

    onToggleShowDateTimes = () => {
        this.setState({ ShowDateTimes: !this.state.ShowDateTimes }, () => {
            if (this.state.ShowDateTimes) {
                var AssignedDate = moment(this.state.WorkoutDetails.AssignedDate.substring(0,10) + ' ' + moment().format('HH:mm')).format('YYYY-MM-DD HH:mm');

                this.setState({  WorkoutDetails: { ...this.state.WorkoutDetails, AssignedDate } }, () => this.onEditGroupWorkoutDetail());
            }
            else this.onEditGroupWorkoutDetail()
        });
    }

    onToggleShowDeleteWorkoutModal = ShowDeleteWorkoutModal => {
        this.setState({ ShowDeleteWorkoutModal });
    }

    onToggleShowExerciseDetailModal = (ShowExerciseDetailModal, Exercise) => {
        this.setState({ ShowExerciseDetailModal, ShowExerciseDetailPreview: Exercise || {} });
    }

    onToggleShowLocation = () => {
        var { Location, ShowLocation } = this.state;
        ShowLocation = !ShowLocation;

        this.setState({ ShowLocation }, () => {
            if (!ShowLocation && Location) this.onEditGroupWorkoutDetail()
            else if (ShowLocation && Location) this.onEditGroupWorkoutDetail()
        });
    }

    onUpdateAssignedDate = AssignedDate => {
        this.setState({ WorkoutDetails: { ...this.state.WorkoutDetails, AssignedDate } });
    }

    onUpdateWorkoutDetails = ({ CanDelete, WorkoutCompleted }) => {
        this.setState({ WorkoutDetails: { ...this.state.WorkoutDetails, CanDelete, Completed: WorkoutCompleted } });
    }

    onUpdateWorkoutName = WorkoutName => {
        // Changing from additional activity, only update if WorkoutDetails.WorkoutName === Additional Activity Name
        // Otherwise, it was custom name and shouldn't change
        if (this.props.AdditionalActivityDetails && (this.props.AdditionalActivityDetails.ActivityName === this.state.WorkoutDetails.WorkoutName)) {
            document.title = `ABEL - ${this.props.ClientDetails.FirstName && this.props.ClientDetails.LastName ? `${this.props.ClientDetails.FirstName} ${this.props.ClientDetails.LastName}` : this.props.ClientDetails.Email} - ${WorkoutName}`;
            this.setState({ WorkoutDetails: { ...this.state.WorkoutDetails, WorkoutName } });
        }
    }

    renderActivity = () => {
        var { Device } = this.props;
        var { WorkoutDetails: { WorkoutType } } = this.state;

        return (
            <>
                {this.renderAdditionalActivity()}

                {
                    WorkoutType === 3 &&
                    <>
                        <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                        {this.renderIntensities()}

                        <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                        {this.renderAdditionalActivityDuration()}
                    </>
                }

                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                {this.renderLocation()}

                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                {this.renderDateTimes()}
            </>
        );
    }

    renderAdditionalActivityDuration = () => {
        var { t } = this.props;
        var { AdditionalActivityDuration } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('WorkoutPlan_duration_on_minutes')} TextAlign="center" />
                
                <Spacer Size="extra-small" />

                <StyledDuration
                    NoDecimal
                    NoLabel
                    NoMargin
                    OnBlur={this.onBlurAdditionalActivityDuration}
                    OnChange={this.onChangeAdditionalActivityDuration}
                    Placeholder='0'
                    Size="medium"
                    Type="number"
                    Value={AdditionalActivityDuration}
                />
            </>
        );
    }

    renderAdditionalActivities = () => {
        var { AdditionalActivities } = this.state;

        return (
            <AdditionalActivityList>
                {
                    AdditionalActivities.map(({ label, Pictures, value }) => {
                        var Selected = value === this.state.ActivityId;

                        return (
                            <AdditionalActivityItem key={value} onClick={() => this.onSelectAdditionalActivity({ ActivityId: value, ActivityName: label, ActivityPictures: Pictures })} Selected={Selected}>
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={label} TextAlign="left" />
                            </AdditionalActivityItem>
                        );
                    })
                }
            </AdditionalActivityList>
        );
    }

    renderAdditionalActivity = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { ActivityName, AdditionalActivities, DisplayingFilter, DropdownSize } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('WorkoutPlan_ChooseActivity')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <StyledDropdown
                    ContentRenderer={this.renderAdditionalActivities}
                    Disabled={false}
                    ForceClose={DisplayingFilter !== 'AdditionalActivities'}
                    GetSize={Height => this.onGetSize(Height)}
                    Height={DisplayingFilter === 'AdditionalActivities' && DropdownSize}
                    IsList={false}
                    IsMobile={Device === 'mobile_small' || Device === 'mobile'}
                    ItemId="AdditionalActivities"
                    NeedsToLoad={AdditionalActivities.length === 0}
                    OnToggleDropdown={ToggleStatus => this.onToggleDropdown('AdditionalActivities', ToggleStatus)}
                    SelectedItemIds={[]}
                    Title={ActivityName || t('WorkoutPlan_ChooseActivity')}
                    TitleFontColor={ActivityName ? 'blue-astronaut' : null}
                    TitleFontFamily="semibold"
                />
            </>
        );
    }

    renderAddWorkoutMembersModal = () => {
        var { GroupId, GroupType } = this.props.GroupDetails;
        var { GroupWorkoutId, ShowAddWorkoutMembersModal } = this.state;

        if (ShowAddWorkoutMembersModal) {
            return (
                <AddGroupWorkoutMembersModal
                    GroupId={GroupId}
                    GroupType={GroupType}
                    GroupWorkoutId={GroupWorkoutId}
                    OnAddGroupWorkoutMembers={UserIds => this.onAddGroupWorkoutMembers(UserIds)}
                    OnHide={() => this.onToggleShowAddWorkoutMembersModal(false)}
                    Show={ShowAddWorkoutMembersModal}
                />
            )
        }
    }

    renderCircuitSeparator = ({ CircuitType }) => {
        var { t } = this.props;

        return (
            <CircuitSeparator BackgroundColor={CircuitType === 'Circuit' ? 'turquoise-dark' : 'blue-royal'}>
                <PageText FontColor="white" FontFamily="bold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t(`Workout_Set_Type_${CircuitType}`)} TextAlign="center" />
            </CircuitSeparator>
        );
    }

    renderClientNotes = () => {
        var { t } = this.props;
        var { ClientNotes } = this.props;
        var { WorkoutDetails: { TrainerNote } } = this.state;

        return (
            <WorkoutNotesContainer>
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('notes')} TextAlign="center" />

                <Spacer Size="medium" />

                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('WorkoutPlan_Trainer_note_to_client')} TextAlign="left" />

                <Spacer Size="small" />

                <StyledWorkoutNoteTextArea
                    OnBlur={this.onChangeTrainerNote}
                    NumRows={4}
                    Placeholder={t('WorkoutPlan_Trainer_note_to_client_placeholder')}
                    Value={TrainerNote}
                />

                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" Text={t('notes_from_clients')} TextAlign="left" />

                {
                    !!ClientNotes.length && ClientNotes.map(({ ClientNote, DisplayName, Gender: Sex, UserId, UserName, UserPhoto }) => {
                        return (
                            <ClientNoteContainer key={UserId}>
                                <ClientIdContainer>
                                    <StyledProfilePhoto
                                        Sex={Sex || 'Male'}
                                        ShowOverlay={false}
                                        Size="small-2"
                                        Source={UserPhoto || ''}
                                    />

                                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={DisplayName} TextAlign="left" />
                                </ClientIdContainer>

                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={ClientNote} TextAlign="left" WhiteSpace="pre-line" />
                            </ClientNoteContainer>
                        );
                    })
                }
                {
                    !!!ClientNotes.length && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('no_notes_from_clients')} TextAlign="center" />
                }
            </WorkoutNotesContainer>
        );
    }

    renderDateTimes = () => {
        var { t } = this.props;
        var { HourType: StateHourType, ShowDateTimes, WorkoutDetails: { AssignedDate } } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('activity_start_time')} />

                <DateTimeUseAllDaysContainer>
                    <DateTimeUseAllDays onClick={this.onToggleShowDateTimes} Selected={ShowDateTimes} />
                    <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={this.onToggleShowDateTimes} Text={t('additional_activity_choosetimes')} TextAlign="left" />
                </DateTimeUseAllDaysContainer>

                {
                    !!+ShowDateTimes &&
                    <>
                        <Spacer Size="small" />

                        <HourTypesContainer>
                            <HourType onClick={() => this.onChangeHourType(12)} Selected={StateHourType === 12}>
                                <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('hour_type', { HourType: '12' })} />
                            </HourType>
                            <HourType onClick={() => this.onChangeHourType(24)} Selected={StateHourType === 24}>
                                <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('hour_type', { HourType: '24' })} />
                            </HourType>
                        </HourTypesContainer>

                        <Spacer Size="small" />

                        <DateTimesContainer>
                            <DateTimeContainer>
                                <TimePicker
                                    defaultValue={moment(AssignedDate)}
                                    minuteStep={5}
                                    onChange={DateTime => this.onChangeDateTime(DateTime)}
                                    onClose={this.onCloseTimePicker}
                                    showSecond={false}
                                    use12Hours={StateHourType === 12}
                                    value={moment(AssignedDate)}
                                />
                            </DateTimeContainer>
                        </DateTimesContainer>
                    </>
                }
            </>
        );
    }

    renderDeleteGroupWorkoutButton = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var Buttons = [{ FontFamily: 'semibold', Icon: DeleteX, IconPosition: 'left', OnClick: this.onDeleteGroupWorkout, Title: t('WorkoutPlan_Delete_workout') }];

        return (
            <ButtonGroup
                Buttons={Buttons}
                ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                NotTouching
                OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
            />
        );
    }

    renderEndurance = () => {
        var { Device } = this.props;

        var Units = this.state.WorkoutDetails.Units;
        var New_ActiveUnits = Units === 'imperial' ? 'yards' : 'meters';

        return (
            <WorkoutLapsContainer>
                {
                    this.state.WorkoutLaps.Laps.map(({ Active, ActiveUnits, BreakTime, LapNumber, LapType, Rest, Speed, SpeedUnits, WorkoutLapId }, LapIndex) => {                            
                        ActiveUnits = ActiveUnits || New_ActiveUnits;

                        return (
                            <React.Fragment key={LapIndex}>
                                {(LapIndex !== 0) && <Spacer Size="extra-small" />}

                                <LapContainer>
                                    <LapHeaderContainer>
                                        {this.renderLapHeader(LapNumber, LapType)}
                                    </LapHeaderContainer>

                                    <LapDetailsContainer>
                                        {this.renderLapDetails({ Active, ActiveUnits, BreakTime, LapType, Rest, Speed, SpeedUnits, WorkoutLapId })}
                                    </LapDetailsContainer>

                                    {Device === 'laptop' && <LapFooterContainer />}
                                </LapContainer>
                            </React.Fragment>
                        );
                    })
                }
            </WorkoutLapsContainer>
        );
    }

    renderExerciseDetailModal = () => {
        var { ShowExerciseDetailModal, ShowExerciseDetailPreview } = this.state;

        if (ShowExerciseDetailModal) {
            return (
                <ExerciseDetail
                    ExerciseDetail_Preview={ShowExerciseDetailPreview}
                    OnHide={() => this.onToggleShowExerciseDetailModal(false)}
                    Show={ShowExerciseDetailModal}
                />
            );
        }
    }

    renderIntensities = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { Intensities } = this.state;
        var Buttons = Intensities.map(({ IntensityId, IntensityName }) => ({ BackgroundColor: 'white', BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', OnClick: () => this.onSelectIntensity(IntensityId), Selected: this.state.IntensityId === IntensityId, Title: IntensityName }));

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('WorkoutPlan_Intensity')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <StyledIntensities
                    Buttons={Buttons}
                    ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                    NotTouching
                    OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                />
            </>
        );
    }

    renderLapDetails = ({ Active, ActiveUnits, BreakTime, LapType, Rest, Speed, SpeedUnits, WorkoutLapId }) => {
        var { ActiveType, DataType } = this.state.WorkoutLaps;

        if (DataType === 'dynamic') SpeedUnits = '%';

        if (LapType === 'Active') {
            return (
                <ActiveLap
                    Active={Active}
                    ActiveUnits={ActiveUnits}
                    ActiveType={ActiveType}
                    DataType={DataType}
                    ReadOnly
                    Rest={Rest}
                    SetType="Template"
                    Speed={Speed}
                    SpeedUnits={SpeedUnits}
                    WorkoutLapId={WorkoutLapId}
                />
            )
        } else if (LapType === 'Break') {
            return (
                <BreakLap
                    BreakTime={BreakTime}
                    ReadOnly
                    WorkoutLapId={WorkoutLapId}
                />
            )
        }
    }

    renderLapHeader = (LapNumber, LapType) => {
        var { t } = this.props;

        return (
            <LapHeaderContainer className="LapHeaderContainer">
                <LapHeaderContainerLeft>
                    {LapType === 'Active' && <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${t('lap_str_all_capital').toUpperCase()} ${LapNumber}`} TextAlign="right" />}
                    {LapType === 'Break' && <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('break').toUpperCase()} TextAlign="right" />}
                </LapHeaderContainerLeft>
            </LapHeaderContainer>
        );
    }

    renderLocation = () => {
        var { t } = this.props;
        var { Location, ShowLocation } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" Text={t('location')} TextAlign="center" />
                    
                <DateTimeUseAllDaysContainer>
                    <DateTimeUseAllDays onClick={this.onToggleShowLocation} Selected={ShowLocation} />
                    <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={this.onToggleShowLocation} Text={t('additional_activity_chooselocation')} TextAlign="left" />
                </DateTimeUseAllDaysContainer>

                {
                    !!+ShowLocation &&
                    <>
                        <Spacer Size="small" />

                        <StyledDuration
                            NoLabel
                            NoMargin
                            OnBlur={this.onBlurLocation}
                            OnChange={this.onChangeLocation}
                            Placeholder={t('location_placeholder')}
                            Size="medium"
                            Type="text"
                            Value={Location}
                        />
                    </>
                }
            </>
        );
    }

    renderMemberCompletedStatus = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { GroupMembers, IsMoreResults, TotalRecords } = this.props.GroupMembers;
        var { GroupMembersPageNo, GroupMembersPageSize, WorkoutDetails: { AssignedDate, CanAddMembers } } = this.state;

        return (
            <MemberCompletedStatusContainer>
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('completed_status_for_group_member')} TextAlign="center" />

                <Spacer Size="extra-extra-small" />
                
                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('click_member_to_go_to_workout')} TextAlign="center" />

                <Spacer Size="small" />

                <StyledPaginatedList
                    CompletedFirstSearch={true}
                    FilterRenderer={this.renderMemberCompletedStatusFilters}
                    HideNoResults
                    IsLoading={false}
                    ItemDescriptionType={t(+TotalRecords === 1 ? 'member' : 'members').toLowerCase()}
                    ItemId="UserId"
                    ItemName="UserName"
                    ItemProps={{
                        AssignedDate,
                        BackgroundColor: 'var(--white-concrete)',
                        HasBorder: true,
                        OnSelectMember: User => this.onGoToUserWorkout(User),
                        ShowWorkoutStatus: true
                    }}
                    ItemRenderer={GroupMemberListItem}
                    Items={GroupMembers}
                    HasMoreItems={!!+IsMoreResults}
                    LoadMore={() => this.onLoadMemberCompletedStatusUsers(true)}
                    NewSearch={false}
                    NoItemsText={t('search_noresults')}
                    PageNo={GroupMembersPageNo}
                    PageSize={GroupMembersPageSize}
                    TotalRecords={TotalRecords}
                />

                {
                    !!+CanAddMembers &&
                    <>
                        <Spacer Size="small" />

                        <ButtonGroup
                            Buttons={[{ FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onToggleShowAddWorkoutMembersModal(true), Title: t('add_members') }]}
                            ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                            NotTouching
                            OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                        />
                    </>
                }
            </MemberCompletedStatusContainer>
        );
    }

    renderMemberCompletedStatusFilters = () => {
        var { t } = this.props;
        var { SearchValue } = this.state;

        return (
            <FiltersContainer>
                <StyledSearchInput
                    FontSize="medium-2"
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeSearch}
                    OnKeyPress={this.onKeyPress}
                    Placeholder={t('search_with_first_char_uppercase_dots')}
                    Size="medium"
                    Type="text"
                    Value={SearchValue}
                />
            </FiltersContainer>
        );
    }

    renderShowChooseDatesModal = () => {
        var { ShowChooseDatesModal } = this.state;

        if (ShowChooseDatesModal) {
            var AssignedDate = moment(this.state.WorkoutDetails.AssignedDate).format('YYYY-MM-DD');

            return (
                <ChooseDates
                    MaxDates={1}
                    OnHide={() => this.onToggleShowChooseDatesModal(false)}
                    OnSelectDates={SelectedDates => this.onSelectDates(SelectedDates)}
                    RequiredDate
                    SelectedDates={[ AssignedDate ]}
                    Show={ShowChooseDatesModal}
                />
            );
        }
    }

    renderShowDeleteWorkoutModal = () => {
        var { ShowDeleteWorkoutModal } = this.state;

        if (ShowDeleteWorkoutModal) {
            var { t } = this.props;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteGroupWorkout}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    Information={t('WorkoutPlan_are_you_sure_you_want_to_remove_this_workout')}
                    OnHide={() => this.onToggleShowDeleteWorkoutModal(false)}
                    Show={ShowDeleteWorkoutModal}
                    Size="small"
                    TextAlign="center"
                />
            );
        }
    }

    renderStrength = () => {
        var { WorkoutExercises } = this.state;

        return (
            <>
                <WorkoutExercisesContainer className="WorkoutExercisesContainer">
                    {
                        WorkoutExercises.map((WorkoutExercise, WorkoutExerciseIndex) => {
                            var CircuitType = WorkoutExercise.NumExercises >= 3 ? 'Circuit' : WorkoutExercise.NumExercises === 2 ? 'Superset' : 'WorkoutExercise';
    
                            return (
                                <React.Fragment key={WorkoutExercise.WorkoutExercises[0].ExerciseId}>
                                    {WorkoutExerciseIndex !== 0 && <Spacer Size="medium" />}
    
                                    <CircuitContainer CircuitType={CircuitType}>
                                        {
                                            WorkoutExercise.WorkoutExercises.map((CircuitWorkoutExercise, CircuitWorkoutExerciseIndex) => {                
                                                return (
                                                    <React.Fragment key={CircuitWorkoutExercise.ExerciseId}>
                                                        {CircuitWorkoutExerciseIndex !== 0 && this.renderCircuitSeparator({ CircuitType })}

                                                        <TemplateExerciseComponent
                                                            CircuitType={CircuitType}
                                                            Exercise={CircuitWorkoutExercise}
                                                            OnToggleShowExerciseDetailModal={(ShowExerciseDetailModal, Exercise) => this.onToggleShowExerciseDetailModal(ShowExerciseDetailModal, Exercise)}
                                                            ReadOnly
                                                        />
                                                    </React.Fragment>
                                                );
                                            })
                                        }    
                                    </CircuitContainer>
                                </React.Fragment>
                            );
                        })
                    }
                </WorkoutExercisesContainer>
            </>
        );
    }

    renderSurveyData = () => {
        var { t } = this.props;
        var { SurveyAverages, SurveyData } = this.state.WorkoutDetails;

        // [
        //     {
        //         "UserId": 1,
        //         "Gender": "Male",
        //         "UserPhoto": "https://abel-dev-userphoto.s3.amazonaws.com/1/55bf26a8-1a67-4a8d-b8ad-68b2180fc569.jpg",
        //         "DisplayName": "Ridge Robinson",
        //         "Surveys": [
        //             {
        //                 "SurveyType": "Sleep",
        //                 "SurveyResponse": 4.5
        //             },
        //             {
        //                 "SurveyType": "RPE",
        //                 "SurveyResponse": 6
        //             }
        //         ]
        //     }
        // ]

        return (
            <SurveyDataContainer className="SurveyDataContainer">
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" Text={t('_mini_surveys_attached_to_workout')} TextAlign="left" />

                <SurveyDataAveragesContainer className="SurveyDataAveragesContainer">
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('_average')}:`} TextAlign="left" />

                    {
                        SurveyAverages.map(({ AverageScore, SurveyType }, SurveyIndex1) => {
                            return <MultiText key={SurveyIndex1} Texts={[{ FontFamily: 'semibold', FontSize: 'small', Text: `${t(`_mini_survey_types_${SurveyType.toLowerCase()}`)}:` }, { FontFamily: 'medium', FontSize: 'small', Text: !!+AverageScore ? `${AverageScore} ${SurveyType === 'Sleep' ? t('_hours').toLowerCase() : ' / 10'}` : 'N/A' }]} />
                        })
                    }
                </SurveyDataAveragesContainer>

                <Spacer Size="small" />
                
                <SurveyDataClientsContainer className="SurveyDataClientsContainer">
                    {
                        SurveyData.map(({ UserId, Gender, UserPhoto, DisplayName, Surveys }) => {
                            return (
                                <ClientNoteContainer key={UserId}>
                                    <ClientIdContainer>
                                        <StyledProfilePhoto
                                            Sex={Gender || 'Male'}
                                            ShowOverlay={false}
                                            Size="small-2"
                                            Source={UserPhoto || ''}
                                        />

                                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={DisplayName} TextAlign="left" />
                                    </ClientIdContainer>

                                    {
                                        Surveys.map(({ SurveyResponse, SurveyType }, SurveyIndex) => {
                                            return <MultiText key={SurveyIndex} Texts={[{ FontFamily: 'semibold', FontSize: 'small', Text: `${t(`_mini_survey_types_${SurveyType.toLowerCase()}`)}:` }, { FontFamily: 'medium', FontSize: 'small', Text: !!+SurveyResponse ? `${SurveyResponse} ${SurveyType === 'Sleep' ? t('_hours').toLowerCase() : ' / 10'}` : 'N/A' }]} />
                                        })
                                    }
                                </ClientNoteContainer>
                            );
                        })
                    }
                </SurveyDataClientsContainer>
            </SurveyDataContainer>
        );
    }

    renderWorkoutDetails = WorkoutType => {
        if (WorkoutType === 1) return this.renderStrength();
        else if (WorkoutType === 2) return this.renderEndurance();
    }

    renderWorkoutImage = () => {
        return (
            <StyledWorkoutImage
                ImageSrc={this.state.WorkoutDetails.WorkoutImage}
                Loading={!this.state.LoadedWorkoutImage}
                OnLoaded={this.onLoadedWorkoutImage}
            />
        );
    }

    renderWorkoutInformation = () => {
        var { t } = this.props;
        var { WorkoutDetails: { AssignedDate, Duration, WorkoutType } } = this.state;

        var NumColumns = [ 1, 2 ].includes(WorkoutType) ? 2 : 1;

        return (
            <WorkoutInformationContainer NumColumns={NumColumns}>
                {
                    [ 1, 2 ].includes(WorkoutType) &&
                    <WorkoutInformationContainerInner AddMargin>
                        <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('length')} - `} />
                        <DurationsContainer>
                            {
                                WorkoutTimeOptions.map(WorkoutTime => {
                                    var Selected = WorkoutTime === Duration;

                                    return (
                                        <DurationContainer key={WorkoutTime} onClick={() => this.onSelectDuration(WorkoutTime)} Selected={Selected}>
                                            {WorkoutTime}
                                        </DurationContainer>
                                    );
                                })
                            }
                        </DurationsContainer>
                    </WorkoutInformationContainerInner>
                }

                <WorkoutInformationContainerInner>
                    <MultiText
                        TextAlign="center"
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('Date_str')} - ` },
                            { FontFamily: 'semibold', FontSize: 'medium-1', Text: moment(AssignedDate).format('MMM D') },
                            { FontColor: 'blue-abel', FontFamily: 'semibold', FontSize: 'medium-1', OnClick: () => this.onToggleShowChooseDatesModal(true), Text: ` ${t('edit_with_first_char_uppercase')} ${t('Date_str')}` }
                        ]}
                    />
                </WorkoutInformationContainerInner>
            </WorkoutInformationContainer>
        );
    }

    renderWorkoutName = WorkoutName => {
        var { t } = this.props;
        var { EditingWorkoutName } = this.state;

        if (EditingWorkoutName) {
            return (
                <StyledWorkoutNameInput
                    FontFamily="medium"
                    FontSize="large"
                    NoLabel
                    OnBlur={this.onBlurWorkoutName}
                    OnChange={this.onChangeWorkoutName}
                    Placeholder={t('ptadmin_clients_add_singular_placeholder')}
                    Size="medium"
                    Type="text"
                    Value={WorkoutName}
                />
            );
        }
        
        return (
            <EditingWorkoutNameContainer>
                <StyledEditingWorkoutName>{WorkoutName}</StyledEditingWorkoutName>
                <StyledEditWorkoutNameIcon
                    ImageSrc={Edit_Light}
                    Loading={false}
                    OnClick={() => this.onToggleEditingWorkoutName(true)}
                />
            </EditingWorkoutNameContainer>
        );
    }

    render() {
        if (this.state.Loading || this.props.TryingGetGroupWorkoutDetail) return <Loading />;

        var { t } = this.props;
        var { Device, GroupDetails, TryingAddGroupWorkoutMembers, TryingDeleteGroupWorkouts, TryingEditGroupWorkoutDetail, TryingSearchGroupMemberWorkoutCompletionStatus } = this.props;
        var { WorkoutDetails } = this.state;

        var WorkoutType = WorkoutDetails.WorkoutType;

        return (
            <>
                {(TryingAddGroupWorkoutMembers || TryingDeleteGroupWorkouts || TryingEditGroupWorkoutDetail || TryingSearchGroupMemberWorkoutCompletionStatus) && <Loading />}

                <Page
                    renderBreadcrumbs={[
                        { Name: t('ptadmin_navigation_clients'), LinkURL: '' },
                        { Name: `${GroupDetails.GroupName}`, LinkURL: `group/${GroupDetails.GroupId}`, Props: { SelectedTab: 'Workouts', WorkoutDate: WorkoutDetails.AssignedDate } },
                        { Name: WorkoutDetails.WorkoutName }
                    ]}
                >
                    {this.renderWorkoutName(WorkoutDetails.WorkoutName)}

                    <Spacer Size="medium" />

                    {this.renderWorkoutImage()}

                    <Spacer Size="small" />

                    {this.renderWorkoutInformation()}

                    <Spacer Size="medium" />

                    {this.renderClientNotes()}

                    <Spacer Size="medium" />

                    {this.renderMemberCompletedStatus()}

                    {
                        WorkoutDetails.HasSurveys &&
                        <>
                            <Spacer Size="medium" />
        
                            {this.renderSurveyData()}
                        </>
                    }

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderActivity()}

                    {
                        (WorkoutType === 1 || WorkoutType === 2) &&
                        <>
                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {this.renderWorkoutDetails(WorkoutType)}
                        </>
                    }

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderDeleteGroupWorkoutButton()}
                </Page>

                {this.renderAddWorkoutMembersModal()}
                {this.renderExerciseDetailModal()}
                {this.renderShowChooseDatesModal()}
                {this.renderShowDeleteWorkoutModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        UserId: state.Auth.UserDetails.UserId,

        AdditionalActivityDetails: state.GroupWorkout.AdditionalActivityDetails,
        ClientNotes: state.GroupWorkout.ClientNotes,
        GroupDetails: state.GroupWorkout.GroupDetails,
        GroupMembers: state.GroupWorkout.GroupMembers,
        WorkoutDetails: state.GroupWorkout.WorkoutDetails,
        WorkoutExercises: state.GroupWorkout.WorkoutExercises,
        WorkoutLaps: state.GroupWorkout.WorkoutLaps,

        TryingAddGroupWorkoutMembers: state.GroupWorkout.TryingAddGroupWorkoutMembers,
        TryingAddGroupWorkoutMembersError: state.GroupWorkout.TryingAddGroupWorkoutMembersError,
        TryingDeleteGroupWorkouts: state.Group.TryingDeleteGroupWorkouts,
        TryingDeleteGroupWorkoutsError: state.Group.TryingDeleteGroupWorkoutsError,
        TryingEditGroupWorkoutDetail: state.GroupWorkout.TryingEditGroupWorkoutDetail,
        TryingEditGroupWorkoutDetailError: state.GroupWorkout.TryingEditGroupWorkoutDetailError,
        TryingGetGroupWorkoutDetail: state.GroupWorkout.TryingGetGroupWorkoutDetail,
        TryingGetGroupWorkoutDetailError: state.GroupWorkout.TryingGetGroupWorkoutDetailError,
        TryingSearchGroupMemberWorkoutCompletionStatus: state.GroupWorkout.TryingSearchGroupMemberWorkoutCompletionStatus,
        TryingSearchGroupMemberWorkoutCompletionStatusError: state.GroupWorkout.TryingSearchGroupMemberWorkoutCompletionStatusError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddGroupWorkoutMembers, ClearGroupWorkoutDetail, DeleteGroupWorkouts, EditGroupWorkoutDetail, GetGroupMemberWorkoutCompletionStatus, GetGroupWorkoutDetail, SaveDataInProps, UpdateSelectedTab, UpdateWorkoutInformation } )(WorkoutDetail));