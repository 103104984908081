import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import CompactItem from './CompactItem';

const ListContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(314px, 1fr));
`;

class TrainerClients_Compact extends React.Component {
    render() {
        var { Clients, DeletingClientAccess_UserId, OnDeleteClient, OnToggleShowGetStartedMeals, OnUpdateMealAccess, OnUpdateWorkoutAccess, UpdatingAccess_AccessType, UpdatingAccess_ClientIndex } = this.props;

        return (
            <ListContainer className="ListContainer">
                {Clients.Clients.map((Client, ClientIndex) =>
                    <CompactItem
                        key={ClientIndex}
                        Client={Client}
                        ClientIndex={ClientIndex}
                        DeletingClientAccess_UserId={DeletingClientAccess_UserId}
                        OnDeleteClient={() => OnDeleteClient({ ClientIndex, UserId: Client.UserId })}
                        OnShowGetStartedMeals={() => OnToggleShowGetStartedMeals({ UserDetails: Client })}
                        OnUpdateMealAccess={({ UpdateType }) => OnUpdateMealAccess({ ClientIndex, UpdateType, UserId: Client.UserId })}
                        OnUpdateWorkoutAccess={({ UpdateType }) => OnUpdateWorkoutAccess({ ClientIndex, UpdateType, UserId: Client.UserId })}
                        UpdatingAccess_AccessType={UpdatingAccess_AccessType}
                        UpdatingAccess_ClientIndex={UpdatingAccess_ClientIndex}
                    />
                )}
            </ListContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        Clients: state.ClientDashboard.Clients
    };
};

export default connect(mapStateToProps, {})(TrainerClients_Compact);