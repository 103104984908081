import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { CollapsedWorkoutSetContainer, CollapsedWorkoutSetsContainer, HeaderLeftContainer, HeaderLeftContainer2, HeaderRightContainer, StyledExerciseImageSmall, StyledExerciseVideoSmall, StyledExerciseNameCollapsed, WEHeaderContainerCollapsed, WEHeaderTopContainerCollapsed, WorkoutExerciseContainer, StyledExerciseOptions } from './styles';

import MultiText from '../Text/MultiText';
import PageText from '../Text/PageText';
import Spacer from '../Spacer';

import DeleteX from '../../Assets/Icons/X_White.png';
import Edit from '../../Assets/Icons/Pencil_White_Filled.png';
import SwapIcon from '../../Assets/Icons/Swap_White.png';

class CollapsedExercise extends React.Component {
    renderExerciseSetTypeOptions = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { DataType, ExerciseType, MaxType } = this.props.Exercise;

        var ExerciseSetTypeOptionsArray = [];
        var ExerciseSetTypeOptionsText = '';
        if (DataType.indexOf('dynamic') === -1) ExerciseSetTypeOptionsArray.push(t('static'));
        else {
            ExerciseSetTypeOptionsArray.push(t('dynamic_str'));

            if (MaxType === 'Progress') ExerciseSetTypeOptionsArray.push(t('do_better_than_last_time'));
            else if (MaxType === 'AllTime') ExerciseSetTypeOptionsArray.push(t('get_a_new_record'));
            else if (MaxType === 'Custom') ExerciseSetTypeOptionsArray.push(t('custom'));

            if (MaxType !== 'Custom' && ExerciseType === 4) {
                if (DataType === 'dynamic-weight') ExerciseSetTypeOptionsArray.push(t('dynamic_sets_fill_in_reps_we_calc_weight'));
                else if (DataType === 'dynamic-reps') ExerciseSetTypeOptionsArray.push(t('dynamic_sets_fill_in_weight_we_calc_reps'));
            }
        }

        ExerciseSetTypeOptionsText = ExerciseSetTypeOptionsArray.join(' > ');

        return (
            <PageText FontFamily="medium" FontSize="small" JustifyContent={(Device === 'ipad' || Device === 'laptop') ? 'flex-start' : 'center'} NoMargin Text={ExerciseSetTypeOptionsText} TextAlign={(Device === 'ipad' || Device === 'laptop') ? 'left' : 'center'} />
        );
    }

    renderHeader = () => {
        var { t } = this.props;
        var { Device, Exercise: { ExerciseId, ExerciseImage, ExerciseName, ExerciseVideo, Notes, Sets: WorkoutSets }, OnDeleteExercise, OnToggleCollapsed, OnToggleShowChangeExerciseModal, OnToggleShowExerciseDetailModal } = this.props;

        var DeleteButton = [{ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', FontSize: 'small', Icon: DeleteX, IconPosition: 'left', IconSize: { Height: '15px', Width: '15px' },  OnClick: OnDeleteExercise, Size: 'small', TextAlign: 'left', Title: t('delete_with_first_char_uppercase') }];
        var ChangeExerciseButton = [{ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', FontSize: 'small', Icon: SwapIcon, IconPosition: 'left', IconSize: { Height: '15px', Width: '15px' }, OnClick: OnToggleShowChangeExerciseModal, Size: 'small', TextAlign: 'left', Title: t('change_exercise') }];
        var EditSetsButton = [{ BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', FontSize: 'small', Icon: Edit, IconPosition: 'left', IconSize: { Height: '15px', Width: '15px' }, OnClick: OnToggleCollapsed, Size: 'small', TextAlign: 'left', Title: t('edit_workout_sets') }];

        var Buttons = [ ...DeleteButton, ...ChangeExerciseButton, ...EditSetsButton ];

        return (
            <WEHeaderContainerCollapsed className="WEHeaderContainerCollapsed" Collapsed>
                <WEHeaderTopContainerCollapsed className="WEHeaderTopContainerCollapsed">
                    <HeaderLeftContainer className="HeaderLeftContainer">
                        {
                            !!ExerciseVideo ?
                            <StyledExerciseVideoSmall
                                autoplay={false}
                                controls={false}
                                muted
                                { ...ExerciseImage && { poster: ExerciseImage } }
                                onMouseOver={e => e.target.play()}
                                onMouseOut={e => e.target.pause()}
                                src={ExerciseVideo}
                                onClick={() => OnToggleShowExerciseDetailModal(true, { ExerciseId, ExerciseImage, ExerciseName })}
                            />
                        :
                            <StyledExerciseImageSmall
                                Alt={ExerciseName}
                                ImageSrc={ExerciseImage}
                                OnClick={() => OnToggleShowExerciseDetailModal(true, { ExerciseId, ExerciseImage, ExerciseName })}
                                SelfLoading
                            />
                        }

                        <HeaderLeftContainer2 className="HeaderLeftContainer2">
                            <StyledExerciseNameCollapsed FontFamily="bold" FontSize="medium-2" JustifyContent={(Device === 'ipad' || Device === 'laptop') ? 'flex-start' : 'center'} NoMargin OnClick={() => OnToggleShowExerciseDetailModal(true, { ExerciseId, ExerciseImage, ExerciseName })} Text={ExerciseName} TextAlign={(Device === 'ipad' || Device === 'laptop') ? 'left' : 'center'} />

                            {this.renderExerciseSetTypeOptions()}

                            <Spacer Size="small" />

                            <CollapsedWorkoutSetsContainer className="CollapsedWorkoutSetsContainer">
                                {
                                    WorkoutSets.map(({ MaxSet, WorkoutSetId }, WorkoutSetIndex) => {
                                        return (
                                            <CollapsedWorkoutSetContainer className="CollapsedWorkoutSetContainer" key={WorkoutSetIndex}>
                                                <PageText FontColor={!!MaxSet ? 'orange' : 'blue-astronaut'} FontFamily="bold" FontSize="medium-2" JustifyContent="center" NoMargin Text={`${WorkoutSetIndex + 1}`} TextAlign="center" />
                                            </CollapsedWorkoutSetContainer>
                                        );
                                    })
                                }
                            </CollapsedWorkoutSetsContainer>
                        </HeaderLeftContainer2>
                    </HeaderLeftContainer>
                    <HeaderRightContainer className="HeaderRightContainer">
                        <StyledExerciseOptions Buttons={Buttons} NotTouching OwnRows />
                    </HeaderRightContainer>
                </WEHeaderTopContainerCollapsed>

                {
                    Notes && Notes.Coach &&
                    <>
                        <Spacer Size="extra-small" />

                        <MultiText
                            Texts={[
                                { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${t('note')}: ` },
                                { FontFamily: 'medium', FontSize: 'medium-1', Text: Notes.Coach }
                            ]}
                        />
                    </>
                }
            </WEHeaderContainerCollapsed>
        );
    }

    render() {
        var { CircuitType, Exercise: { ExerciseId } } = this.props;

        return (
            <React.Fragment key={ExerciseId}>
                <WorkoutExerciseContainer className="WorkoutExerciseContainer" CircuitType={CircuitType}>
                    {this.renderHeader()}
                </WorkoutExerciseContainer>
            </React.Fragment>
        );
    }
}

CollapsedExercise.propTypes = {
    CircuitType: PropTypes.string,
    Exercise: PropTypes.object.isRequired,
    OnDeleteExercise: PropTypes.func,
    OnToggleCollapsed: PropTypes.func,
    OnToggleShowChangeExerciseModal: PropTypes.func,
    OnToggleShowExerciseDetailModal: PropTypes.func
}

CollapsedExercise.defaultProps = {
    CircuitType: null
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        InnerWidth: state.Window.InnerWidth,
    };
};

export default withTranslation()(connect(mapStateToProps, { } )(CollapsedExercise));