import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BETA_SITE_URL } from '../../Config';

import { LearnAbelAdminChildContainer, LearnAbelAdminChildContainer2, LearnAbelAdminContainer, LearnAbelFacebookGroupsContainer, LearnAbelFacebookImage, MenuButtons, NewFeaturesContainer, PlatformDifferencesContainer, PlatformDifferencesPicture, PlatformIconsContainer, PlatformTextsContainer, StyledCustomerLoveIcon, StyledNewFeaturesImage, StyledPage, StyledPlatformIcon } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Documents from './Documents';
import Videos from './Videos';

import Info from '../../Modals/Info';

import ABELCoaches_Denmark from '../../Assets/Illustrations/ABELCoaches_Denmark.png';
import ABELCoaches_Norge from '../../Assets/Illustrations/ABELCoaches_Norge.png';
import ABELCoaches_USA from '../../Assets/Illustrations/ABELCoaches_USA.png';
import Android from '../../Assets/Icons/Android.png';
import Apple from '../../Assets/Icons/Apple.png';
import CustomerLove from '../../Assets/Illustrations/CustomerLove.png';
import NewFeatures_Jan5 from '../../Assets/Illustrations/NewFeatures_Jan5.png';
import PlatformDifferences_English from '../../Assets/Illustrations/PlatformDifferences_English.png';
import PlatformDifferences_Norsk from '../../Assets/Illustrations/PlatformDifferences_Norsk.png';

class Manual extends React.Component {
    state = { SelectedMenu: 'Admin', ShowPlatformDifferencesModal: false };

    componentDidMount() {
        window.location = `${BETA_SITE_URL}/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=learn-abel`;

        // const QueryValues = queryString.parse(this.props.location.search);
        // if (Object.entries(QueryValues).length > 0 && QueryValues.tab) {
        //     var SelectedMenu = null;
            
        //     if (QueryValues.tab.toLowerCase() === 'admin') SelectedMenu = 'Admin';
        //     else if (QueryValues.tab.toLowerCase() === 'app') SelectedMenu = 'App';
        //     else SelectedMenu = 'Admin';

        //     this.setState({ SelectedMenu });
        // }
    }

    onSelectMenu = SelectedMenu => {
        this.setState({ SelectedMenu });
    }

    onToggleShowPlatformDifferencesModal = ShowPlatformDifferencesModal => {
        this.setState({ ShowPlatformDifferencesModal });
    }

    renderAdmin = () => {
        var { t } = this.props;

        return (
            <>
                <LearnAbelAdminContainer className="LearnAbelAdminContainer">
                    <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('learn_to_use_abel_watch_videos_below')} TextAlign="left" />

                    <Spacer Size="small" />

                    <LearnAbelAdminChildContainer className="LearnAbelAdminChildContainer">
                        <StyledCustomerLoveIcon ImageSrc={CustomerLove} />

                        <LearnAbelAdminChildContainer2 className="LearnAbelAdminChildContainer2">
                            <PageText FontFamily="bold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('learn_to_use_abel_need_extra_help_title')} TextAlign="left" />

                            <Spacer Size="small" />

                            <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('learn_to_use_abel_need_extra_help_description')} TextAlign="left" />
                        </LearnAbelAdminChildContainer2>
                    </LearnAbelAdminChildContainer>

                    <Spacer Size="medium" />

                    <PageText FontFamily="bold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('learn_to_use_abel_facebook_groups_title')} TextAlign="left" />

                    <Spacer Size="small" />

                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('learn_to_use_abel_facebook_groups_description')} TextAlign="left" />

                    <Spacer Size="medium" />

                    <LearnAbelFacebookGroupsContainer className="LearnAbelFacebookGroupsContainer">
                        <LearnAbelFacebookImage ImageSrc={ABELCoaches_USA} SelfLoading />
                        <LearnAbelFacebookImage ImageSrc={ABELCoaches_Norge} SelfLoading />
                        <LearnAbelFacebookImage ImageSrc={ABELCoaches_Denmark} SelfLoading />
                    </LearnAbelFacebookGroupsContainer>
                </LearnAbelAdminContainer>

                <Spacer Size="medium" />

                <NewFeaturesContainer className="NewFeaturesContainer">
                    <StyledNewFeaturesImage ImageSrc={NewFeatures_Jan5} SelfLoading />
                </NewFeaturesContainer>

                <Spacer Size="medium" />

                <Videos />
            </>
        );
    }

    renderApp = () => {
        return (
            <>
                {this.renderPlatformDifferences()}

                <Spacer Size="medium" />

                <Documents />
            </>
        );
    }

    renderMenus = () => {
        var { Device } = this.props;
        var { SelectedMenu } = this.state;
    
        var ButtonData = { BackgroundColorHover: 'white-concrete', BackgroundColorSelected: 'white-concrete', ColorHover: 'blue-abel', ColorSelected: 'blue-abel' };

        var DocumentMultiText = <MultiText Texts={[{ FontFamily: SelectedMenu === 'Admin' ? 'semibold' : 'medium', FontSize: 'medium-2', Text: 'Admin' }]} />;
        var VideoMultiText = <MultiText Texts={[{ FontFamily: SelectedMenu === 'App' ? 'semibold' : 'medium', FontSize: 'medium-2', Text: 'App' }]} />;
    
        return (
            <MenuButtons>
                <ButtonGroup
                    ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                    ContainerWidth={Device === 'mobile' || Device === 'mobile_small' ? '100%' : null}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                    Buttons={[
                        { ...ButtonData, OnClick: () => this.onSelectMenu('Admin'), Selected: SelectedMenu === 'Admin', Title: DocumentMultiText },
                        { ...ButtonData, OnClick: () => this.onSelectMenu('App'), Selected: SelectedMenu === 'App', Title: VideoMultiText },
                    ]}
                />
            </MenuButtons>
        );
    }

    renderPlatformDifferences = () => {
        var { t } = this.props;

        return (
            <PlatformDifferencesContainer className="PlatformDifferencesContainer">
                <PlatformIconsContainer className="PlatformIconsContainer">
                    <StyledPlatformIcon ImageSrc={Android} />

                    <Spacer Size="extra-small" />

                    <StyledPlatformIcon ImageSrc={Apple} />
                </PlatformIconsContainer>
                <PlatformTextsContainer className="PlatformTextsContainer">
                    <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('platform_differences_android_vs_ios')} TextAlign="left" />

                    <Spacer Size="extra-small" />

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('platform_differences_android_vs_ios_description')} TextAlign="left" />

                    <Spacer Size="medium" />

                    <PageText FontColor="blue-abel" FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onToggleShowPlatformDifferencesModal(true)} Text={t('click_here_to_see_differences')} TextAlign="left" />
                </PlatformTextsContainer>
            </PlatformDifferencesContainer>
        );
    }

    renderPlatformDifferencesInfo = () => {
        var { LanguageId } = this.props.UserDetails;
        
        if (+LanguageId === 2) return <PlatformDifferencesPicture className="PlatformDifferencesPicture" ImageSrc={PlatformDifferences_Norsk} SelfLoading />;

        return <PlatformDifferencesPicture className="PlatformDifferencesPicture" ImageSrc={PlatformDifferences_English} SelfLoading />;
    }

    renderPlatformDifferencesModal = () => {
        var { ShowPlatformDifferencesModal } = this.state;

        if (ShowPlatformDifferencesModal) {
            return (
                <Info
                    InformationRenderer={this.renderPlatformDifferencesInfo}
                    OnHide={() => this.onToggleShowPlatformDifferencesModal(false)}
                    Show={ShowPlatformDifferencesModal}
                />
            );
        }
    }

    render() {
        var { t } = this.props;
        var { SelectedMenu } = this.state;

        return (
            <>
                <StyledPage NoMargin>
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text={t('learn_to_use_abel')} TextAlign="center" />

                    <Spacer Size="medium" />

                    {this.renderMenus()}
        
                    <Spacer Size="medium" />

                    {SelectedMenu === 'Admin' && this.renderAdmin()}
                    {SelectedMenu === 'App' && this.renderApp()}
                </StyledPage>

                {this.renderPlatformDifferencesModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails
    };
};

export default withTranslation()(connect(mapStateToProps, null )(Manual));