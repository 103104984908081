import styled from 'styled-components';
import { device } from '../../Config/device';

import Image from '../../Components/Image';
import Input from '../../Components/Form/Input';
import PageText from '../../Components/Text/PageText';
import TextArea from '../../Components/Form/TextArea';

import NoImage from '../../Assets/Icons/NoImage.png';

export const CookingTimeIcon = styled(Image)`
    height: 45px;
    margin-bottom: 20px;
    width: 45px;
`;

export const CookingTimeInput = styled(Input)`
    margin-bottom: 20px;
    margin-top: 20px;

    > div {
        text-align: center;
    }

    > input {
        text-align: center;
        text-indent: 0px;
        width: 90px;
    }
`;

export const CookingTimeOptionContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;

    :first-child {
        margin-right: 15px;
    }
`;

export const CookingTimesContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 400px;
`;

export const CookingTimeOptionsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 400px;
`;

export const DeleteIcon = styled.div`
    align-items: center;
    background-color: var(--red-bittersweet);
    border: 2px solid var(--red-bittersweet);
    border-radius: 50%;
    color: var(--white);
    display: flex;
    height: 30px;
    justify-content: center;
    margin-left: 10px;
    width: 30px;

    @media ${device.minwidth.ipad} {
        background-color: var(--white);
        border-color: var(--white-concrete);
        color: var(--white-concrete);

        :hover {
            background-color: var(--red-bittersweet);
            border-color: var(--red-bittersweet);
            color: var(--white);
            cursor: pointer;
        }
    }
`;

export const DishImageContainer = styled.div``;

export const DishImageInputContainer = styled.div`
    align-items: center;
    background-image: url('${props => props.BackgroundImage}'), url('${NoImage}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    height: 338px;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    width: 600px;
`;

export const IngredientContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 15px;

    @media ${device.minwidth.mobile} {
        flex-direction: row;
    }
`;

export const IngredientListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const IngredientNameStyled = styled(PageText)`
    flex: 1;
`;

export const IngredientOptionsContainer = styled.div`
    display: flex;

    @media ${device.maxwidth.mobile_small} {
        margin-top: 10px;
        width: 100%;
    }
`;

export const IngredientQuantityStyledInput = styled(Input)`
    @media ${device.minwidth.mobile} {
        width: 60px;
    }

    @media ${device.maxwidth.mobile_small} {
        flex: 1;
    }

    > input {
        border: 2px solid var(--white-concrete);
        text-align: center;
        text-indent: 0px;
    }
`;

export const IngredientsContainer = styled.div``;

export const IngredientUnit = styled(PageText)`
    border: 2px solid var(--white-concrete);
    border-radius: 21px;
    margin-left: 10px;
    padding: 2.5px 7px;

    @media ${device.maxwidth.mobile_small} {
        flex: 1;
    }

    :hover {
        background-color: var(--white-concrete);
        cursor: pointer;
    }
`;

export const InstructionContainer = styled.div`
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    line-height: 21px;    
    margin-bottom: 15px;
    padding: 15px;
`;

export const InstructionsContainer = styled.div``;

export const RatingImage = styled.img`
    height: 45px;
    margin-bottom: 10px;
    margin-left: 10px;

    @media (hover:hover) {
        :hover {
            cursor: pointer;
        }
    }

    @media ${device.minwidth.mobile} {
        height: 75px;
    }
`;

export const RatingsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -10px;
    margin-left: -10px;
`;

export const RequiredHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    text-align: center;
`;

export const StyledDishInput = styled.input`
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    :hover {
        cursor: pointer;
    }
`;

export const StyledDishName = styled(Input)`
    > div {
        font-family: var(--font-family-medium);
        font-size: var(--font-size-medium-3);
        margin-bottom: 20px;
        text-align: center;
    }

    > input {
        text-align: center;
        text-indent: 0px;
    }
`;

export const StyledInputIcon = styled(Image)``;

export const StyledInstruction = styled(TextArea)`
    flex: 1;
    margin-bottom: 0px;

    > textarea {
        border: none;
        padding: 0px;
        resize: none;
    }
`