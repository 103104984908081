import styled from 'styled-components';

export const UnitContainer = styled.div`
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    border: 2px solid ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white-concrete)'};
    border-radius: 10px;
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    margin-top: 15px;
    padding: 10px 15px;

    :not(:last-child) {
        margin-right: 15px;
    }

    :hover {
        background-color: var(--blue-astronaut);
        border-color: var(--blue-astronaut);
        color: var(--white);
        cursor: pointer;
    }
`;

export const UnitsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -15px;
`;
