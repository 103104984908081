import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddExistingMealTemplate, ClearMealTemplates, ClearSaveData, SaveDataInProps, SearchMealTemplates, UpdateSelectedTab } from '../../Services/Actions';
import moment from 'moment';
import history from '../../history';

import { AllFilters, SelectedDatesContainer, SelectedPreviewContainer, SelectedTemplateContainer, StyledDropdown, StyledLoadMore, StyledSearchInput } from './styles';

import AssignClients from '../../Components/AssignClients';
import { MealTemplateListItem } from '../../Components/Lists/ListItems';
import Loading from '../../Components/Loading';
import MiniCalendar from '../../Components/Calendar/MiniCalendar';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import MealTemplate from '../../Modals/MealTemplate';

class AddMealTemplate extends React.Component {
    _isMounted = false;

    state = {
        ActiveSearch: false,
        CompletedFirstSearch: false,
        FilteredTemplateDays: [],
        LastSearchValue: '',
        Loading: true,
        NewSearch: false,
        PageNo: 1, PageSize: 48,
        SearchValue: '',
        SelectedDates: [],
        SelectedTemplateDays: null,
        SelectedTemplate: {},
        SelectedTemplateId: null,
        ShowTemplateDetailModal: false,
        StartDate: null,
        TemplateDetail_Preview: {}
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientDetails, DayDate, SelectedTemplate } = this.props;

        var StartDate = DayDate || null;
        var SelectedUserIds = [];
        var SelectedUsers = [];
        var SelectedTemplate_State = { ...SelectedTemplate };
        var SelectedTemplateId = Number(SelectedTemplate.TemplateId);
        var SelectedTemplateDays = SelectedTemplate.TemplateDays;

        if (Object.entries(ClientDetails).length !== 0) {
            SelectedUserIds = [ Number(ClientDetails.ClientId) ];
            SelectedUsers = [{ ...ClientDetails, UserId: Number(ClientDetails.ClientId) }];
        }

        this.setState({ SelectedTemplateDays, SelectedTemplate: SelectedTemplate_State, SelectedTemplateId, SelectedUserIds, SelectedUsers, StartDate }, () => this.onLoadTemplates());
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearSaveData();
        this.props.ClearMealTemplates();
    }

    onAddMealTemplate = () => {
        var { SelectedUserIds, StartDate, SelectedTemplateId } = this.state;

        this.props.AddExistingMealTemplate({ ClientIds: SelectedUserIds, StartDate, TemplateId: SelectedTemplateId }).then(() => {
            if (this._isMounted && !this.props.TryingAddExistingMealTemplateError) {
                if (this.props.location.state && this.props.location.state.from) history.goBack();
                else this.props.SaveDataInProps({ LibraryMenu: 'Meals', LibrarySubmenu: 'MealTemplates'}).then(() => history.push('/library'));
            }
        });
    }

    onAssignTemplateToClients = TemplateDetail => {
        this.onSelectTemplate(TemplateDetail);
    }

    onChangeSearch = event => {
        this.setState({ SearchValue: event.target.value });
    }

    onKeyPress = ({ key }) => {
        if (key === 'Enter' && !this.props.TryingSearchMealTemplates) {
            var { SearchValue, LastSearchValue } = this.state;

            if (SearchValue !== LastSearchValue) {
                this.setState({ PageNo: 1, PageSize: 48, LastSearchValue: SearchValue }, () => {
                    this.onLoadTemplates();
                });
            }
        }
    }

    onLoadTemplates = Increment => {
        var { FilteredTemplateDays, NewSearch, PageNo, PageSize, SearchValue } = this.state;

        if (Increment) {
            NewSearch = false;
            PageNo = PageNo + 1;
        } else NewSearch = true;

        this.setState({ ActiveSearch: true, NewSearch, PageNo }, () => this.props.SearchMealTemplates({ AddToExisting: Increment, PageNo, PageSize, TemplateDays: FilteredTemplateDays, TemplateName: SearchValue }).then(() => this._isMounted ? this.setState({ ActiveSearch: false, CompletedFirstSearch: true, Loading: false }) : null));
    }

    onSelectClients = ({ SelectedClients: SelectedUsers }) => {
        var SelectedUserIds_New = SelectedUsers.map(User => User.UserId);
        SelectedUsers = SelectedUsers.map(User => ({ ...User, Sex: User.Gender }));

        this.setState({ SelectedUsers, SelectedUserIds: SelectedUserIds_New });
    }

    onSelectDate = DayDate => {
        var { SelectedTemplateId, StartDate } = this.state;

        if (SelectedTemplateId && DayDate !== StartDate) {
            var SelectedDates = [];

            for (var i = 0; i < this.state.SelectedTemplateDays; i++) {
                SelectedDates.push(moment(DayDate).add(i, 'days').format('YYYY-MM-DD'));
            }

            this.setState({ SelectedDates, StartDate: DayDate });
        }
    }

    onSelectTemplate = SelectedTemplate => {
        var { SelectedTemplateId, StartDate } = this.state;

        if (+SelectedTemplateId !== +SelectedTemplate.TemplateId) {
            var SelectedDates = [];
            StartDate = StartDate || moment();

            for (var i = 0; i < SelectedTemplate.TemplateDays; i++) {
                SelectedDates.push(moment(StartDate).add(i, 'days').format('YYYY-MM-DD'));
            }

            this.setState({ SelectedDates, SelectedTemplateDays: SelectedTemplate.TemplateDays, SelectedTemplate, SelectedTemplateId: SelectedTemplate.TemplateId, StartDate });
        }
    }

    onSelectTemplateDay = (event, TemplateDayId, TemplateDayIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = TemplateDayIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return {
                    FilteredTemplateDays: [ ...prevState.FilteredTemplateDays, ...[ TemplateDayId ] ],
                }
            }, () => this.onLoadTemplates());
        } else {
            this.setState((prevState) => {
                return {
                    FilteredTemplateDays: [ ...prevState.FilteredTemplateDays.slice(0, TemplateDayIndex), ...prevState.FilteredTemplateDays.slice(TemplateDayIndex + 1) ],
                }
            }, () => this.onLoadTemplates());
        }
    }

    onToggleShowTemplateDetailModal = (ShowTemplateDetailModal, TemplateDetail_Preview, TemplateId) => {
        var { SelectedTemplate, SelectedTemplateId } = this.state;

        if (TemplateId && Number(TemplateId) === Number(SelectedTemplateId)) { SelectedTemplate = {}; SelectedTemplateId = null; }
        
        this.setState({ SelectedTemplate, SelectedTemplateId, ShowTemplateDetailModal, TemplateDetail_Preview });
    }

    renderFilters = () => {
        var { t } = this.props;
        var { DishTemplateFilterSettings: { TemplateDays }, TryingSearchMealTemplates } = this.props;
        var { FilteredTemplateDays, SearchValue } = this.state;

        return (
            <AllFilters>
                <StyledSearchInput
                    FontSize="medium-2"
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeSearch}
                    OnKeyPress={this.onKeyPress}
                    Placeholder={t('search_with_first_char_uppercase_dots')}
                    Size="medium"
                    Type="text"
                    Value={SearchValue}
                />

                <StyledDropdown
                    Disabled={TryingSearchMealTemplates}
                    ItemId="TemplateDayId"
                    ItemName="TemplateDay"
                    Items={TemplateDays}
                    Loading={false}
                    NeedsToLoad={false}
                    OnSelectItem={(event, TemplateDayId, TemplateDayIndex) => this.onSelectTemplateDay(event, TemplateDayId, TemplateDayIndex)}
                    SelectedItemIds={FilteredTemplateDays}
                    Title={t('template_days')}
                />
            </AllFilters>
        );
    }

    renderSelectedPreview = () => {
        var { t } = this.props;
        var { SelectedDates, SelectedTemplate: { TemplateDays, TemplateName }, SelectedTemplateId } = this.state;

        return (
            <SelectedPreviewContainer className="SelectedPreviewContainer">
                <SelectedTemplateContainer className="SelectedTemplateContainer">
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('WorkoutPlanSettings_selected')} TextAlign="center" />
                    <Spacer Size="small" />
                    {
                        SelectedTemplateId ?
                        <MealTemplateListItem
                            HideSelect
                            Item={{ ...this.state.SelectedTemplate, TemplateDays, TemplateId: SelectedTemplateId, TemplateName }}
                            SelectTemplate={() => null}
                            SelectedItemIds={[]}
                            ViewTemplate={() => this.onToggleShowTemplateDetailModal(true, { TemplateDays, TemplateId: SelectedTemplateId, TemplateName })}
                        />
                    :
                        null
                    }
                </SelectedTemplateContainer>
                <SelectedDatesContainer className="SelectedDatesContainer">
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('choose_dates')} TextAlign="center" />
                    
                    <Spacer Size="extra-small" />
                    
                    <MiniCalendar
                        Disabled={!SelectedTemplateId}
                        DisabledText={t('select_template_first')}
                        OnSelectDate={DayDate => this.onSelectDate(DayDate)}
                        SelectedDates={SelectedDates}
                    />
                </SelectedDatesContainer>
            </SelectedPreviewContainer>
        );
    }

    renderTemplateDetailModal = () => {
        var { ShowTemplateDetailModal, TemplateDetail_Preview } = this.state;

        if (ShowTemplateDetailModal) {
            return (
                <MealTemplate
                    OnAssignClients={() => this.onAssignTemplateToClients({ ...TemplateDetail_Preview })}
                    OnHide={TemplateId => this.onToggleShowTemplateDetailModal(false, null, TemplateId)}
                    OnLoadTemplates={this.onLoadTemplates}
                    TemplateDetail_Preview={TemplateDetail_Preview}
                    Show={ShowTemplateDetailModal}
                />
            );
        }
    }

    renderTemplatesList = () => {
        var { t } = this.props;
        var { MealTemplateList, MealTemplateList_TotalRecords, IsMoreMealTemplates, TryingSearchMealTemplates } = this.props;
        var { ActiveSearch, CompletedFirstSearch, NewSearch, PageNo, PageSize, SelectedTemplateId } = this.state;

        var SelectedItemIds = SelectedTemplateId ? [ Number(SelectedTemplateId) ] : [];

        return (
            <StyledLoadMore
                ActiveSearch={ActiveSearch}
                CompletedFirstSearch={CompletedFirstSearch}
                IsLoading={TryingSearchMealTemplates}
                ItemDescriptionType={t('templates_plural').toLowerCase()}
                ItemId="TemplateId"
                ItemName="TemplateName"
                ItemProps={{ SelectTemplate: Template => this.onSelectTemplate(Template), ViewTemplate: Template => this.onToggleShowTemplateDetailModal(true, Template) }}
                ItemRenderer={MealTemplateListItem}
                Items={MealTemplateList}
                HasMoreItems={IsMoreMealTemplates}
                LoadMore={() => this.onLoadTemplates(true)}
                NewSearch={NewSearch}
                NoItemsText={t('search_noresults')}
                PageNo={PageNo}
                PageSize={PageSize}
                SelectedItemIds={SelectedItemIds}
                TotalRecords={MealTemplateList_TotalRecords}
            />
        );
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { t } = this.props;
        var { Device, TryingAddExistingMealTemplate } = this.props;
        var { StartDate, SelectedTemplateId, SelectedUserIds, SelectedUsers } = this.state;

        var Breadcrumbs = [{ Name: t('add_meals_from_template') }];
        var SubmitButtonProps = {
            Disabled: !StartDate || !SelectedTemplateId || !SelectedUserIds.length,
            OnClick: this.onAddMealTemplate,
            Title: t('add_meals_from_template'),
        }

        return (
            <>
                {TryingAddExistingMealTemplate ? <Loading /> : null}

                <Page
                    renderBreadcrumbs={Breadcrumbs}
                    renderSubmitButton={SubmitButtonProps}
                >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={t('add_meals_from_template')} />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'medium' : 'small'} />

                    {this.renderFilters()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'large' : 'medium'} />

                    <AssignClients
                        GetStartedEligible="Meals"
                        HorizontalScroll={false}
                        OnSelectClients={Users => this.onSelectClients(Users)}
                        SelectedUserIds={SelectedUserIds}
                        SelectedUsers={SelectedUsers}
                    />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'large' : 'medium'} />

                    {this.renderTemplatesList()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'large' : 'medium'} />

                    {this.renderSelectedPreview()}
                </Page>

                {this.renderTemplateDetailModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        ClientDetails: state.SaveData.ClientDetails,
        DayDate: state.SaveData.DayDate,
        SelectedTemplate: state.SaveData.SelectedTemplate,

        DishTemplateFilterSettings: state.Templates.DishTemplateFilterSettings,
        IsMoreMealTemplates: state.Templates.IsMoreMealTemplates,
        MealTemplateList: state.Templates.MealTemplateList,
        MealTemplateList_TotalRecords: state.Templates.MealTemplateList_TotalRecords,

        TryingAddExistingMealTemplate: state.Templates.TryingAddExistingMealTemplate,
        TryingAddExistingMealTemplateError: state.Templates.TryingAddExistingMealTemplateError,
        TryingSearchMealTemplates: state.Templates.TryingSearchMealTemplates,
        TryingSearchMealTemplatesError: state.Templates.TryingSearchMealTemplatesError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddExistingMealTemplate, ClearMealTemplates, ClearSaveData, SaveDataInProps, SearchMealTemplates, UpdateSelectedTab } )(AddMealTemplate));