import styled from 'styled-components';

export const Container = styled.div`
    align-items: center;
    display: inline;
    margin-bottom: ${props => props.AddMargin ? 'var(--spacer-size-extra-small)' : '0px'};
    max-width: 100%;
    ${({ TextAlign }) => TextAlign ? `text-align: ${TextAlign.toLowerCase()};` : null}
    ${({ ContainerWidth }) => ContainerWidth ? `margin-left: auto; margin-right: auto; width: ${ContainerWidth};` : null}

    ${({ FontColorHover }) => (
        FontColorHover ?
        `
            @media(hover:hover) {
                :hover {
                    color: var(--${FontColorHover.toLowerCase()});
                }
            }
        `
    :
        null
    )}
`;

export const SpanText = styled.span`
    ${({ FontColor }) => (FontColor ? `color: var(--${FontColor.toLowerCase()});` : null)}
    ${({ FontColorHex }) => (FontColorHex ? `color: ${FontColorHex.toLowerCase()};` : null)}
    font-family: ${props => props.FontFamily ? `var(--font-family-${props.FontFamily.toLowerCase()})` : 'var(--font-family-medium)'};
    font-size: ${props => props.FontSize ? `var(--font-size-${props.FontSize.toLowerCase()})` : 'var(--font-size-medium-1)'};

    ${({ Underline }) => (Underline && 'text-decoration: underline;')}

    ${props => props.WhiteSpace && `white-space: ${props.WhiteSpace};`}

    ${({ Clickable }) => (
        Clickable ?
        `@media(hover:hover) {
            :hover {
                cursor: pointer;
            }
        }`
    :
        null
    )}

    ${({ FontColorHover }) => (
        FontColorHover ?
        `
            @media(hover:hover) {
                :hover {
                    color: var(--${FontColorHover.toLowerCase()});
                }
            }
        `
    :
        null
    )}
`;

export const TextsContainer = styled.div``;