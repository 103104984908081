import styled from 'styled-components';
import { device } from '../../../Config/device';

import Input from '../Input';

export const EllipsisText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const NoResultsContainer = styled.div`
    padding: 10px;
`;

export const SAYTResultsContainer = styled.div`
    background-color: var(--white);
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    max-height: 300px;
    overflow-y: scroll;

    width: 320px;
`;

export const SAYTResult = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;

    :not(:last-child) {
        border-bottom: 2px solid var(--white-concrete);
    }

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete-light);
            cursor: pointer;
        }
    }
`;

export const SearchInput = styled(Input)`
    margin-right: 10px;

    @media ${device.maxwidth.mobile} {
        width: 170px;
    }

    @media ${device.minwidth.mobile} {
        width: 260px;
    }

    @media ${device.minwidth.ipad} {
        width: 320px;
    }

    > input {
        border-radius: 10px;
    }
`;