import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { GetClientDashboardEvent } from '../../Services/Actions';
import moment from 'moment';
import history from '../../history';

import Popover from 'react-popover';

import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

const EventContainer = styled.div`
    background-color: ${props => props.Completed === 'complete' ? 'var(--green-lima)' : props.Completed === 'incomplete' ? 'var(--red-bittersweet)' : props.ClientType === 'Groups' ? 'var(--white)' : 'var(--white-concrete)'};
    border: 1px solid rgba(84, 84, 84, 0.1);
    border-radius: 100%;
    height: 9px;
    margin-right: 3px;
    margin-top: 3px;
    width: 9px;

    @media(hover:hover) {
        :hover {
            background-color: ${props => props.Completed === 'complete' ? 'var(--green-lima-hover)' : props.Completed === 'incomplete' ? 'var(--red-bittersweet-hover)' : 'var(--white-concrete-hover)'};
            cursor: pointer;
        }
    }
`;

const PopoverContent = styled.div`
    align-items: center;
    background-color: var(--white);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
`;

const StyledPopover = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    box-shadow: 1px 1px 8px #5A5A5A29;
    max-width: 250px;
`;

const StyledPopoverTitle = styled(PageText)`
    padding: 5px 15px;
`;

class PopoverEvent extends React.Component {
    state = { PopoverIsOpen: false };

    onClickDot = () => {
        var { EventType, uId: EventId } = this.props;

        if (EventType === 'Meals') history.push(`/meal/${EventId}`);
        else if (EventType === 'Workouts') history.push(`/workout/${EventId}`);
    }

    onTogglePopover = () => {
        var { DashboardEventDetails, EventType, uId: EventId, UserId } = this.props;

        this.setState({ PopoverIsOpen: !this.state.PopoverIsOpen }, () => {
            if (!!this.state.PopoverIsOpen && !DashboardEventDetails.hasOwnProperty(`${UserId}_${EventType}_${EventId}`)) this.props.GetClientDashboardEvent({ EventId, EventType, UserId });
        });
    }

    renderMealContent = () => {
        var { t } = this.props;
        var { DashboardEventDetails, EventType, uId: EventId, UserId } = this.props;
        var { Calories, DishName, IsLoggedFood } = DashboardEventDetails[`${UserId}_${EventType}_${EventId}`];

        return (
            <>
                <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text={!!IsLoggedFood ? t('logged_food_title') : DishName} TextAlign="center" />

                <Spacer Size="extra-extra-small" />

                <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text={`${Calories} kcal`} TextAlign="center" />
            </>
        );
    }

    renderWorkoutContent = () => {
        var { DashboardEventDetails, EventType, uId: EventId, UserId } = this.props;
        var { WorkoutName, WorkoutTypeName } = DashboardEventDetails[`${UserId}_${EventType}_${EventId}`];

        return (
            <>
                <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text={WorkoutName} TextAlign="center" />

                <Spacer Size="extra-extra-small" />

                <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text={WorkoutTypeName} TextAlign="center" />
            </>
        );
    }

    renderPopoverContent = () => {
        var { AssignedDate, DashboardEventDetails, EventType, TryingGetClientDashboardEvent, uId: EventId, UserId } = this.props;

        return (
            <StyledPopover>
                <StyledPopoverTitle FontFamily="medium" FontSize="small" NoMargin Text={moment.utc(AssignedDate).format('DD.MMM - ddd')} TextAlign="center" />
                <PopoverContent>
                    {
                        (TryingGetClientDashboardEvent || !DashboardEventDetails.hasOwnProperty(`${UserId}_${EventType}_${EventId}`)) ?
                        <i className="fas fa-spinner fa-spin"></i>
                    :
                        EventType === 'Meals' ?
                        this.renderMealContent()
                    :
                        this.renderWorkoutContent()
                    }
                </PopoverContent>
            </StyledPopover>
        );
    }

    render() {
        var { ClientType, CompletionStatus} = this.props;

        const popoverProps = {
            isOpen: this.state.PopoverIsOpen,
            preferPlace: 'below',
            onOuterAction: this.onTogglePopover,
            body: this.renderPopoverContent(),
            tipSize: 10
        }

        return (
            <Popover {...popoverProps}>
                <EventContainer ClientType={ClientType} Completed={CompletionStatus} onClick={this.onClickDot} onMouseEnter={this.onTogglePopover} onMouseLeave={this.onTogglePopover} />
            </Popover>
        );
    }
}

const mapStateToProps = state => {
    return {
        DashboardEventDetails: state.ClientDashboard.DashboardEventDetails,

        TryingGetClientDashboardEvent: state.ClientDashboard.TryingGetClientDashboardEvent,
        TryingGetClientDashboardEventError: state.ClientDashboard.TryingGetClientDashboardEventError
    };
};

export default withTranslation()(connect(mapStateToProps, { GetClientDashboardEvent } )(PopoverEvent));