import styled from 'styled-components';
import { device } from '../../Config/device';

import ButtonGroup from '../Buttons/ButtonGroup';
import Image from '../Image';
import InputLabelSideBySide from '../Form/InputLabelSideBySide';

import Checkmark from '../../Assets/Icons/Checkmark.png';
import Checkmark_Selected from '../../Assets/Icons/Checkmark_Selected.png';

export const AddMaxContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
`;

export const DeleteSetIcon = styled(Image)`
    height: 20px;
    width: 20px;
`;

export const LapContainer = styled.div`
    align-items: stretch;
    border: 2px solid ${props => props.HasBorder ? 'var(--blue-astronaut)' : 'var(--white-concrete)'};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

    @media ${device.minwidth.laptop} {
        display: grid;
        grid-template-columns: 1fr 8.5fr 0.5fr;
    }
`;

export const LapDetailsContainer = styled.div`
    width: 100%;
`;

export const LapFooterContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    display: flex;
    justify-content: center;
`;

export const LapHeaderContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    display: flex;
    flex-direction: row;
    padding: 5px;
    width: 100%;

    @media ${device.minwidth.laptop} {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

export const LapHeaderContainerLeft = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;

    @media ${device.minwidth.laptop} {
        justify-content: center
        width: 100%;
    }
`;

export const LapHeaderContainerRight = styled.div``;

export const StyledAddLap = styled(ButtonGroup)`
    justify-content: flex-start;

    @media ${device.maxwidth.mobile_small} {
        width: 100%;
    }
`;

export const StyledAddMaxInput = styled(InputLabelSideBySide)`
    & input {
        border-radius: 10px;
    }
`;

export const StyledCheckmark = styled.div`
    background-image: url(${props => props.ShowCompleted ? Checkmark_Selected : Checkmark});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 75%;
    border: 2px solid var(--black);
    border-radius: 20px;
    height: 20px;
    width: 20px;
`;

export const StyledCompletedButton = styled.div`
    border: 2px solid var(--black);
    border-radius: 10px;
    display: flex;
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-medium-1);
    max-width: 150px;
    padding: 3px 5px;
    text-align: center;

    ${
        props => props.Completed &&
        `
            background-color: var(--blue-astronaut);
            border: 2px solid var(--blue-astronaut);
            color: var(--white);
        `
    }

    ${
        props => props.HoveringCompletedButton &&
        `
            background-color: var(--red-bittersweet);
            border: 2px solid var(--red-bittersweet);
            color: var(--white);
        `
    }

    @media ${device.maxwidth.ipad} {
        margin-left: 10px;
    }

    @media(hover:hover) {
        :hover {
            ${props => !props.NoCursor && 'cursor: pointer;'}

            ${
                props => !props.Completed &&
                `
                    background-color: var(--white);
                `
            }
        }
    }
`;

export const WorkoutLapsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;