import styled from 'styled-components';
import { device } from '../../Config/device';

export const BottomBarContainer = styled.div`
    background-color: var(--white);
    border-top: 2px solid var(--white-concrete);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 2;
`;

export const BottomBarContent = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 20px;
    width: 100%;

    @media ${device.minwidth.ipad} { padding: 20px 0; width: 87%; }
    @media ${device.minwidth.laptop} { width: 85%; }
`;