import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddTrainerClient, ClearClientDashboard, ClientDashboard, DeleteTrainerClient, DeleteTrainerGroup, InsertEditUserGroup, UpdateMealAccess, UpdateWorkoutAccess, UploadToPresignedUrl } from '../../Services/Actions';
// import moment from 'moment';
// import history from '../../history';
import { BETA_SITE_URL, S3_PREFIX } from '../../Config';

import { LeftPagination, OptionsContainer, OptionsLeftContainer, OptionsRightContainer, PageNumberContainer, PageNumbersContainer, PaginationContainer, RightPagination, StyledPage, ViewCount, ViewTypeIcon, ViewTypesContainer } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import TrainerGroup from './TrainerGroup';
import TrainerClientsBox from './TrainerClientsBox';
import TrainerClientsCompact from './TrainerClientsCompact';
import TrainerClientsList from './TrainerClientsList';

import AddClient from '../../Modals/AddClient';
import AddEditGroupMembers from '../../Modals/AddEditGroupMembers';
import GetStartedMealsModal from '../../Modals/GetStartedMeals';
import Info from '../../Modals/Info';
import ReorderGroups from '../../Modals/ReorderGroups';

import Box from '../../Assets/Icons/ClientDashboard/Box.png';
import Box_Hover from '../../Assets/Icons/ClientDashboard/Box_Hover.png';
import Compact from '../../Assets/Icons/ClientDashboard/Compact.png';
import Compact_Hover from '../../Assets/Icons/ClientDashboard/Compact_Hover.png';
import List from '../../Assets/Icons/ClientDashboard/List.png';
import List_Hover from '../../Assets/Icons/ClientDashboard/List_Hover.png';
import Plus from '../../Assets/Icons/Plus.png';

import { PageRange } from '../../Functions';

class Clients extends React.Component {
    _isMounted = false;

    state = {
        ClientType: localStorage.getItem('ClientDashboard_ClientType') || 'TrainerClients',
        DeletingClientAccess_UserId: null,
        EditingGroup: {},
        GroupIdToDelete: null,
        GroupsPageNo: 1,
        GroupsPageSize: 3,
        Loading: true,
        LoadingGroupMembersIndex: null,
        PageNo: 1,
        PageSize: 48,
        RequestingClientAccess_UserId: null,
        ShowAddClientModal: false,
        ShowAddEditGroupModal: false,
        ShowDeleteGroupModal: false,
        ShowGetStartedMealsModal: false,
        ShowGetStartedMealsUserDetails: null,
        ShowReorderGroupsModal: false,
        UpdatingAccess_AccessType: null, UpdatingAccess_ClientIndex: null, UpdatingAccess_GroupIndex: null,
        ViewType: null
    };

    componentDidMount() {
        this._isMounted = true;

        window.location = `${BETA_SITE_URL}/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=clients`;

        // this.onCheckAvailableViewTypes();

        // this.onLoadClientDashboard({});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.ClientDashboardDevice !== this.props.ClientDashboardDevice) this.onCheckAvailableViewTypes();
    }

    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearClientDashboard();
    }

    onCheckAvailableViewTypes = () => {
        var ClientDashboardDevice = this.props.ClientDashboardDevice;
        var SavedViewType = localStorage.getItem('ClientDashboard_ViewType');

        var ViewType = SavedViewType;

        if (!ViewType || (ClientDashboardDevice === 'mobile' && SavedViewType === 'List')) ViewType = 'Box';

        if (this.state.ViewType !== ViewType) this.setState({ ViewType });
    }

    onDeleteClient = ({ ClientIndex, ClientType, UserId }) => {
        if (ClientType === 'Groups') {
            this.setState({ DeletingClientAccess_UserId: UserId }, () => {
                this.props.DeleteTrainerClient({ ClientId: UserId, ClientIndex, ClientType, FromDashboard: true }).then(() => {
                    this.setState({ DeletingClientAccess_UserId: null });
                });
            })
        } else this.props.DeleteTrainerClient({ ClientId: UserId, ClientIndex, ClientType, FromDashboard: true });
    }

    onDeleteGroup = () => {
        var { GroupId, GroupType } = this.state.GroupIdToDelete;

        this.props.DeleteTrainerGroup({ GroupId, GroupType }).then(() => {
            this.setState({ ShowDeleteGroupModal: false }, () => this.onLoadClientDashboard());
        });
    }

    onDownloadHenvisningskort = () => {
        var link = `${S3_PREFIX}manuals/Henvisning.pdf`;

        window.open(link, '_blank');
    }

    onInsertEditGroupImage = ({ GroupId, GroupImage, GroupImageObj, GroupIndex, GroupType, OriginalImage }) => {        
        return new Promise((resolve, reject) => {
            this.props.InsertEditUserGroup({ GroupId, GroupImage, GroupImageObj, GroupIndex, GroupType, OriginalImage }).then(({ PreSignedUrls }) => {
                resolve({ PreSignedUrls });
            }).catch(err => reject(err));
        });
    }

    onLoadClientDashboard = () => {
        var { ClientType, GroupsPageNo, GroupsPageSize, PageNo, PageSize } = this.state;

        this.props.ClientDashboard({ ClientType, GroupsPageNo, GroupsPageSize, PageNo, PageSize }).then(() => {
            this.setState({ Loading: false });
        });
    }

    onLoadGroupMembers = ({ ClientType, GroupId, GroupIndex, GroupType, PageNo }) => {
        var { PageSize } = this.state;

        this.setState({ LoadingGroupMembersIndex: GroupIndex }, () => {
            this.props.ClientDashboard({ ClientType, GroupId, GroupIndex, GroupType, PageNo, PageSize }).then(() => {
                this.setState({ LoadingGroupMembersIndex: null });
            });
        });
    }

    onPaginate = ({ ClientType, NewPageNo }) => {
        if (ClientType === 'TrainerClients' && this.state.PageNo !== NewPageNo) this.setState({ PageNo: NewPageNo }, () => this.onLoadClientDashboard());
        else if (ClientType === 'Groups' && this.state.GroupsPageNo !== NewPageNo) this.setState({ GroupsPageNo: NewPageNo }, () => this.onLoadClientDashboard());
    }

    onRefreshClients = ({ FromModal }) => {
        if (FromModal === 'AddClient') this.onToggleAddClientModal(false);
        if (FromModal === 'Reorder') this.onToggleReorderGroupsModal(false);
        this.onLoadClientDashboard();
    }

    onRequestClientAccess = ({ Email, UserId }) => {
        this.setState({ RequestingClientAccess_UserId: UserId }, () => {
            this.props.AddTrainerClient({ ClientIdentifier: Email, UserId }).then(() => {
                this.setState({ RequestingClientAccess_UserId: null });
            })
        });
    }

    onToggleAddClientModal = ShowAddClientModal => {
        this.setState({ ShowAddClientModal });
    }

    onToggleClientType = ClientType => {
        if (this.state.ClientType !== ClientType) {
            localStorage.setItem('ClientDashboard_ClientType', ClientType);
    
            this.setState({ ClientType, GroupsPageNo: 1, PageNo: 1 }, () => this.onLoadClientDashboard());
        }
    }

    onToggleDeleteGroupModal = ({ GroupId, GroupType, ShowDeleteGroupModal }) => {
        this.setState({ GroupIdToDelete: ShowDeleteGroupModal ? { GroupId, GroupType } : null, ShowDeleteGroupModal });
    }

    onToggleReorderGroupsModal = ShowReorderGroupsModal => {
        this.setState({ ShowReorderGroupsModal });
    }

    onToggleShowAddEditGroupModal = ({ EditingGroup = {}, ShowAddEditGroupModal }) => {
        this.setState({ EditingGroup, ShowAddEditGroupModal });
    }

    onToggleShowGetStartedMeals = ({ ShowGetStartedMealsModal, ShowGetStartedMealsUserDetails = null }) => {
        this.setState({ ShowGetStartedMealsModal, ShowGetStartedMealsUserDetails });
    }

    onToggleViewType = ViewType => {
        if (this.state.ViewType !== ViewType) {
            localStorage.setItem('ClientDashboard_ViewType', ViewType);
    
            this.setState({ ViewType });
        }
    }

    onUpdateMealAccess = ({ ClientIndex, GroupIndex = null, UpdateType, UserId }) => {
        var { ClientType } = this.state;

        this.setState({ UpdatingAccess_AccessType: 'Meals', UpdatingAccess_ClientIndex: ClientIndex, ...(GroupIndex !== null) && { UpdatingAccess_GroupIndex: GroupIndex } }, () => {
            this.props.UpdateMealAccess({ ClientType, ReturnDataType: 'Dashboard', UpdateType, UserId }).then(() => {
                this.setState({ UpdatingAccess_AccessType: null, UpdatingAccess_ClientIndex: null, ...(GroupIndex !== null) && { UpdatingAccess_GroupIndex: null } });
            });
        });
    }

    onUpdateWorkoutAccess = ({ ClientIndex, GroupIndex = null, UpdateType, UserId }) => {
        var { ClientType } = this.state;

        this.setState({ UpdatingAccess_AccessType: 'Workouts', UpdatingAccess_ClientIndex: ClientIndex, ...(GroupIndex !== null) && { UpdatingAccess_GroupIndex: GroupIndex } }, () => {
            this.props.UpdateWorkoutAccess({ ClientType, ReturnDataType: 'Dashboard', UpdateType, UserId }).then(() => {
                this.setState({ UpdatingAccess_AccessType: null, UpdatingAccess_ClientIndex: null, ...(GroupIndex !== null) && { UpdatingAccess_GroupIndex: null } });
            });
        });
    }

    renderAddClientModal = () => {
        if (this.state.ShowAddClientModal) {
            var { ShowAddClientModal } = this.state;

            return <AddClient Refresh={() => this.onRefreshClients({ FromModal: 'AddClient' })} Show={ShowAddClientModal} OnHide={() => this.onToggleAddClientModal(false)} />;
        }
    }

    renderAddEditGroupModal = () => {
        var { EditingGroup: { GroupId, GroupIndex, GroupName, GroupType }, ShowAddEditGroupModal } = this.state;

        if (ShowAddEditGroupModal) {
            return (
                <AddEditGroupMembers
                    FromDashboard={1}
                    GroupId={GroupId}
                    GroupIndex={GroupIndex}
                    GroupName={GroupName}
                    GroupType={GroupType}
                    OnlyClients={false}
                    OnHide={() => this.onToggleShowAddEditGroupModal({ ShowAddEditGroupModal: false })}
                    OnRefreshGroups={this.onRefreshGroups}
                    Show={ShowAddEditGroupModal}
                />
            )
        }
    }

    renderClients = () => {
        var { ClientType, DeletingClientAccess_UserId, LoadingGroupMembersIndex, RequestingClientAccess_UserId, UpdatingAccess_AccessType, UpdatingAccess_ClientIndex, UpdatingAccess_GroupIndex, ViewType } = this.state;

        if (ClientType === 'TrainerClients' && ViewType === 'Box') {
            return (
                <TrainerClientsBox
                    DeletingClientAccess_UserId={DeletingClientAccess_UserId}
                    OnDeleteClient={({ ClientIndex, UserId }) => this.onDeleteClient({ ClientIndex, ClientType: 'TrainerClients', UserId })}
                    OnToggleShowGetStartedMeals={({ UserDetails }) => this.onToggleShowGetStartedMeals({ ShowGetStartedMealsModal: true, ShowGetStartedMealsUserDetails: UserDetails })}
                    OnUpdateMealAccess={({ ClientIndex, UpdateType, UserId }) => this.onUpdateMealAccess({ ClientIndex, UpdateType, UserId })}
                    OnUpdateWorkoutAccess={({ ClientIndex, UpdateType, UserId }) => this.onUpdateWorkoutAccess({ ClientIndex, UpdateType, UserId })}
                    UpdatingAccess_AccessType={UpdatingAccess_AccessType}
                    UpdatingAccess_ClientIndex={UpdatingAccess_ClientIndex}
                />
            );
        } else if (ClientType === 'TrainerClients' && ViewType === 'Compact') {
            return (
                <TrainerClientsCompact
                    DeletingClientAccess_UserId={DeletingClientAccess_UserId}
                    OnDeleteClient={({ ClientIndex, UserId }) => this.onDeleteClient({ ClientIndex, ClientType: 'TrainerClients', UserId })}
                    OnToggleShowGetStartedMeals={({ UserDetails }) => this.onToggleShowGetStartedMeals({ ShowGetStartedMealsModal: true, ShowGetStartedMealsUserDetails: UserDetails })}
                    OnUpdateMealAccess={({ ClientIndex, UpdateType, UserId }) => this.onUpdateMealAccess({ ClientIndex, UpdateType, UserId })}
                    OnUpdateWorkoutAccess={({ ClientIndex, UpdateType, UserId }) => this.onUpdateWorkoutAccess({ ClientIndex, UpdateType, UserId })}
                    UpdatingAccess_AccessType={UpdatingAccess_AccessType}
                    UpdatingAccess_ClientIndex={UpdatingAccess_ClientIndex}
                />
            );
        } else if (ClientType === 'TrainerClients' && ViewType === 'List') {
            return (
                <TrainerClientsList
                    DeletingClientAccess_UserId={DeletingClientAccess_UserId}
                    OnDeleteClient={({ ClientIndex, UserId }) => this.onDeleteClient({ ClientIndex, ClientType: 'TrainerClients', UserId })}
                    OnToggleShowGetStartedMeals={({ UserDetails }) => this.onToggleShowGetStartedMeals({ ShowGetStartedMealsModal: true, ShowGetStartedMealsUserDetails: UserDetails })}
                    OnUpdateMealAccess={({ ClientIndex, UpdateType, UserId }) => this.onUpdateMealAccess({ ClientIndex, UpdateType, UserId })}
                    OnUpdateWorkoutAccess={({ ClientIndex, UpdateType, UserId }) => this.onUpdateWorkoutAccess({ ClientIndex, UpdateType, UserId })}
                    UpdatingAccess_AccessType={UpdatingAccess_AccessType}
                    UpdatingAccess_ClientIndex={UpdatingAccess_ClientIndex}
                />
            );
        } else if (ClientType === 'Groups') {
            return this.props.Groups.Groups.map((Group, GroupIndex) => {
                var { GroupId, GroupName, GroupType } = Group;

                return (
                    <TrainerGroup
                        key={`${GroupId}_${GroupType}`}
                        DeletingClientAccess_UserId={DeletingClientAccess_UserId}
                        Group={Group}
                        GroupIndex={GroupIndex}
                        LastGroup={GroupIndex === this.props.Groups.length - 1}
                        LoadingGroupMembers={LoadingGroupMembersIndex === GroupIndex}
                        OnDeleteClient={({ ClientIndex, UserId }) => this.onDeleteClient({ ClientIndex, ClientType: 'Groups', UserId })}
                        OnDeleteGroup={() => this.onToggleDeleteGroupModal({ GroupId, GroupType, ShowDeleteGroupModal: true })}
                        OnEditGroup={() => this.onToggleShowAddEditGroupModal({ EditingGroup: { GroupId, GroupIndex, GroupName, GroupType }, ShowAddEditGroupModal: true })}
                        OnInsertEditGroupImage={({ GroupImage, GroupImageObj }) => this.onInsertEditGroupImage({ GroupId, GroupImage, GroupImageObj, GroupIndex, GroupType, OriginalImage: Group.GroupImage })}
                        OnLoadGroupMembers={PageNo => this.onLoadGroupMembers({ ClientType: 'Group', GroupId, GroupIndex, GroupType, PageNo })}
                        OnRequestClientAccess={({ Email, UserId }) => this.onRequestClientAccess({ Email, UserId })}
                        OnToggleShowGetStartedMeals={({ UserDetails }) => this.onToggleShowGetStartedMeals({ ShowGetStartedMealsModal: true, ShowGetStartedMealsUserDetails: UserDetails })}
                        OnUpdateMealAccess={({ ClientIndex, UpdateType, UserId }) => this.onUpdateMealAccess({ ClientIndex, GroupIndex, UpdateType, UserId })}
                        OnUpdateWorkoutAccess={({ ClientIndex, UpdateType, UserId }) => this.onUpdateWorkoutAccess({ ClientIndex, GroupIndex, UpdateType, UserId })}
                        UploadToPresignedUrl={this.props.UploadToPresignedUrl}
                        RequestingClientAccess_UserId={RequestingClientAccess_UserId}
                        UpdatingAccess_AccessType={UpdatingAccess_AccessType}
                        UpdatingAccess_ClientIndex={UpdatingAccess_ClientIndex}
                        UpdatingAccess_GroupIndex={UpdatingAccess_GroupIndex}
                        ViewType={ViewType}
                    />
                );
            });
        }
    }

    renderDeleteGroupModal = () => {
        if (this.state.ShowDeleteGroupModal) {
            var { t } = this.props;
            var { ShowDeleteGroupModal } = this.state;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteGroup}
                    BottomButtonText={t('ptadmin_groups_delete')}
                    Information={t('ptadmin_groups_delete_warning')}
                    Loading={this.props.TryingDeleteTrainerGroup}
                    OnHide={() => this.onToggleDeleteGroupModal(false)}
                    Show={ShowDeleteGroupModal}
                />
            );
        }
    }

    renderOptions = () => {
        var { t } = this.props;
        var { ClientDashboardDevice, Device } = this.props;
        var { ClientType, ViewType } = this.state;

        var ButtonOptions = [{ OnClick: this.onDownloadHenvisningskort, Title: t('_henvisningskort') }];
        if (ClientType === 'Groups') ButtonOptions = [ ...ButtonOptions, { OnClick: () => this.onToggleReorderGroupsModal(true), Title: t('ptadmin_groups_reorder') }, { Icon: Plus, IconPosition: 'left', OnClick: () => this.onToggleShowAddEditGroupModal({ ShowAddEditGroupModal: true }), Title: t('ptadmin_groups_create') } ];
        else if (ClientType === 'TrainerClients') ButtonOptions = [ ...ButtonOptions, { Icon: Plus, IconPosition: 'left', OnClick: () => this.onToggleAddClientModal(true), Title: t('ptadmin_clients_add_singular') } ];

        return (
            <OptionsContainer className="OptionsContainer">
                <OptionsLeftContainer className="OptionsLeftContainer">
                    <ButtonGroup
                        Buttons={[
                            { OnClick: () => this.onToggleClientType('TrainerClients'), Selected: ClientType === 'TrainerClients', Title: t('ptadmin_clients_all') },
                            { OnClick: () => this.onToggleClientType('Groups'), Selected: ClientType === 'Groups', Title: t('Groups') }
                        ]}
                        ButtonWidth={Device === 'mobile_small' ? null : '200px'}
                        // NotTouching={Device === 'mobile_small' ? true : false}
                        // OwnRows={Device === 'mobile_small' ? true : false}
                    />
                    <ViewTypesContainer className="ViewTypesContainer">
                        {ClientDashboardDevice === 'laptop' && <ViewTypeIcon className="ViewTypeIcon" ImageSrc={ViewType === 'List' ? List_Hover : List} ImageSrcHover={List_Hover} OnClick={() => this.onToggleViewType('List')} />}
                        <ViewTypeIcon className="ViewTypeIcon" ImageSrc={ViewType === 'Box' ? Box_Hover : Box} ImageSrcHover={Box_Hover} OnClick={() => this.onToggleViewType('Box')} />
                        <ViewTypeIcon className="ViewTypeIcon" ImageSrc={ViewType === 'Compact' ? Compact_Hover : Compact} ImageSrcHover={Compact_Hover} OnClick={() => this.onToggleViewType('Compact')} />
                    </ViewTypesContainer>
                </OptionsLeftContainer>
                <OptionsRightContainer className="OptionsRightContainer">
                    <ButtonGroup
                        Buttons={ButtonOptions}
                        NotTouching
                        ButtonWidth={Device === 'mobile_small' ? null : 'fit-content'}
                        OwnRows={Device === 'mobile_small' ? true : false}
                    />
                </OptionsRightContainer>
            </OptionsContainer>
        );
    }

    renderPagination = () => {
        var { t } = this.props;
        var { Device, Clients, Groups } = this.props;
        var { ClientType, GroupsPageNo, PageNo } = this.state;

        var CurrentPageNo = 0;
        var TotalPages = 1;

        if (ClientType === 'Groups') { CurrentPageNo = GroupsPageNo; TotalPages = Groups.TotalPages; }
        else if (ClientType === 'TrainerClients') { CurrentPageNo = PageNo; TotalPages = Clients.TotalPages; }

        var { EndPageNo, StartPageNo } = PageRange({ PageNo: CurrentPageNo, TotalPages });

        return (
            <PaginationContainer className="PaginationContainer">
                <LeftPagination className="LeftPagination" HasPrevLink={CurrentPageNo !== 1}>
                    {
                        TotalPages !== 1 &&
                        <>
                            {CurrentPageNo !== 1 && <PageText FontColorHover={'blue-abel'} FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onPaginate({ ClientType, NewPageNo: CurrentPageNo - 1 })} Text={t('prev')} TextAlign="left" />}

                            <PageNumbersContainer className="PageNumbersContainer">
                                {
                                    Array((EndPageNo - StartPageNo) + 1).fill().map((item, index) => {
                                        var Selected = CurrentPageNo === StartPageNo + index;

                                        return (
                                            <PageNumberContainer className="PageNumberContainer" key={index} onClick={() => this.onPaginate({ ClientType, NewPageNo: StartPageNo + index })} Selected={Selected}>
                                                {`${StartPageNo + index}`}
                                            </PageNumberContainer>
                                        );
                                    })
                                }
                            </PageNumbersContainer>

                            {CurrentPageNo !== TotalPages && <PageText FontColorHover={'blue-abel'} FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-end" NoMargin OnClick={() => this.onPaginate({ ClientType, NewPageNo: CurrentPageNo + 1 })} Text={t('next_with_first_char_uppercase')} TextAlign="right" />}
                        </>
                    }
                </LeftPagination>
                
                {TotalPages !== 1 && Device === 'mobile_small' && <Spacer Size="extra-small" />}

                <RightPagination className="RightPagination">
                    {this.renderViewCount()}
                </RightPagination>
            </PaginationContainer>
        );
    }

    renderReorderGroupsModal = () => {
        var { ShowReorderGroupsModal } = this.state;

        if (ShowReorderGroupsModal) {
            return (
                <ReorderGroups
                    Show={ShowReorderGroupsModal}
                    OnHide={() => this.onToggleReorderGroupsModal(false)}
                    OnRefresh={() => this.onRefreshClients({ FromModal: 'Reorder' })}
                />
            );
        }
    }

    renderShowGetStartedMealsModal = () => {
        if (this.state.ShowGetStartedMealsModal) {
            var { ClientType, ShowGetStartedMealsModal, ShowGetStartedMealsUserDetails: { Height, HeightUnit, IsPreRegistered, UserId, UserUnits, Weight, WeightUnit } } = this.state;

            return (
                <GetStartedMealsModal
                    ClientType={ClientType}
                    Show={ShowGetStartedMealsModal}
                    OnHide={() => this.onToggleShowGetStartedMeals({ ShowGetStartedMealsModal: false })}
                    Height={Height}
                    HeightUnit={HeightUnit}
                    IsPreRegistered={IsPreRegistered}
                    UserId={UserId}
                    UserUnits={UserUnits}
                    Weight={Weight}
                    WeightUnit={WeightUnit}
                />
            );
        }
    }

    renderViewCount = () => {
        var { t } = this.props;
        var { Clients, Groups } = this.props;
        var { ClientType, GroupsPageNo, GroupsPageSize, PageNo, PageSize } = this.state;

        var ActualPageNo;
        var ActualPageSize;
        var TotalRecords = 0;

        if (ClientType === 'Groups') { ActualPageNo = GroupsPageNo; ActualPageSize = GroupsPageSize; TotalRecords = Groups.TotalRecords; }
        else if (ClientType === 'TrainerClients') { ActualPageNo = PageNo; ActualPageSize = PageSize;TotalRecords = Clients.TotalRecords; }

        return (
            <ViewCount className="ViewCount">
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${((ActualPageNo - 1) * ActualPageSize) + 1} - ${(ActualPageNo * ActualPageSize) < TotalRecords ? (ActualPageNo * ActualPageSize) : TotalRecords}`} />
                &nbsp;
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords}`} />
            </ViewCount>
        )
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { Clients, Groups, TryingAddTrainerClient, TryingGetClientDashboard, TryingInsertEditUserGroup } = this.props;
        var { ClientType } = this.state;

        return (
            <>
                {(TryingAddTrainerClient || TryingGetClientDashboard || TryingInsertEditUserGroup) && <Loading />}

                <StyledPage>
                    {this.renderOptions()}

                    {
                        ((ClientType === 'Groups' && !!Groups.TotalRecords) || (ClientType === 'TrainerClients' && !!Clients.TotalRecords)) &&
                        <>
                            <Spacer Size="extra-small" />

                            {this.renderPagination()}
                        </>
                    }

                    <Spacer Size="extra-small" />

                    {this.renderClients()}
                </StyledPage>

                {this.renderAddClientModal()}
                {this.renderAddEditGroupModal()}
                {this.renderDeleteGroupModal()}
                {this.renderReorderGroupsModal()}
                {this.renderShowGetStartedMealsModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        ClientDashboardDevice: state.Window.ClientDashboardDevice,
        Device: state.Window.Device,

        Clients: state.ClientDashboard.Clients,
        Groups: state.ClientDashboard.Groups,

        TryingGetClientDashboard: state.ClientDashboard.TryingGetClientDashboard,
        TryingGetClientDashboardError: state.ClientDashboard.TryingGetClientDashboardError,

        TryingAddTrainerClient: state.Clients.TryingAddTrainerClient,
        TryingDeleteTrainerGroup: state.Clients.TryingDeleteTrainerGroup,

        TryingInsertEditUserGroup: state.Group.TryingInsertEditUserGroup,
        TryingInsertEditUserGroupError: state.Group.TryingInsertEditUserGroupError,
    };
};

export default withTranslation()(connect(mapStateToProps, { AddTrainerClient, ClearClientDashboard, ClientDashboard, DeleteTrainerClient, DeleteTrainerGroup, InsertEditUserGroup, UpdateMealAccess, UpdateWorkoutAccess, UploadToPresignedUrl } )(Clients));