import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearAddEditGroupSettings, GetAddEditGroupSettings, InsertEditUserGroup, InsertEditUserGroupMembers, SearchGroupMembers, SearchTrainerClients } from '../../Services/Actions';

// import { GroupTypeContainer, GroupTypeSubContainer, StyledPaginatedList } from './styles';
import { CheckmarkContainer, GroupTypeContainer, GroupTypeSubContainer, StyledCheckmark, StyledPaginatedList } from './styles';

import AbelModal from '../../Components/Modal';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import { GroupMemberListItem } from '../../Components/Lists/ListItems';
import Input from '../../Components/Form/Input';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

class AddEditGroupMembers extends React.Component {
    _isMounted = false;

    state = {
        AddMemberIds: [],
        AddToFutureWorkouts: true,
        EditedMemberIdsList: [],
        EditedMembersList: [],
        EditingType: 'Members',
        ExistingMembersPageNo: 1,
        ExistingMembersPageSize: 40,
        GroupId: this.props.GroupId || null,
        GroupMembers: [],
        GroupName: this.props.GroupName || '',
        GroupType: this.props.GroupType || null,
        RemoveMemberIds: [],
        TrainerClients: [],
        TrainerClientsPageNo: 1,
        TrainerClientsPageSize: 40,
        Loading: true
    };

    componentDidMount() {
        this._isMounted = true;

        var { GroupId, GroupType } = this.props;

        // if (GroupType) {
            this.props.GetAddEditGroupSettings({ EditingType: this.state.EditingType, GroupId, GroupType }).then(() => {
                if (this._isMounted && !this.props.TryingGetAddEditGroupSettingsError) {
                    var { GroupMembers, IsMoreResults: GroupMembers_IsMoreResults, TotalRecords: GroupMembers_TotalRecords } = this.props.ExistingMembers;
                    var { IsMoreResults: TrainerClients_IsMoreResults, TotalRecords: TrainerClients_TotalRecords, TrainerClients } = this.props.NewMembers;

                    var EditedMemberIdsList = GroupMembers.map(Member => +Member.UserId);
                    var EditedMembersList = GroupMembers.map(Member => Member);

                    this.setState({ EditedMemberIdsList, EditedMembersList, GroupMembers, GroupMembers_IsMoreResults, GroupMembers_TotalRecords, Loading: false, TrainerClients, TrainerClients_IsMoreResults, TrainerClients_TotalRecords });
                }
                else this.props.OnHide();
            });
        // }
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.ClearAddEditGroupSettings();
    }

    onCheckAddToFutureWorkouts = () => {
        this.setState({ AddToFutureWorkouts: !this.state.AddToFutureWorkouts });
    }

    onClickSubmit = () => {
        var { FromDashboard, GroupIndex } = this.props;
        var { AddMemberIds, AddToFutureWorkouts, GroupId, GroupName, GroupType, RemoveMemberIds } = this.state;

        if (!GroupId) {
            this.props.InsertEditUserGroup({ GroupName, GroupType, UserIds: AddMemberIds }).then(() => {
                if (!this.props.TryingInsertEditUserGroupError) this.props.OnRefreshGroups ? this.props.OnRefreshGroups() : this.props.OnHide();
            })
        } else {
            var GetAllMembers = !!AddMemberIds.length || !!RemoveMemberIds.length;

            this.props.InsertEditUserGroupMembers({ AddToFutureWorkouts: +AddToFutureWorkouts, AddUserIds: AddMemberIds, EditingType: 'Members', FromDashboard: !!FromDashboard, GetAllMembers, GroupId, GroupIndex, GroupName, GroupType, RemoveUserIds: RemoveMemberIds }).then(UpdatedGroupMembers => {
                if (!this.props.TryingInsertEditUserGroupMembersError) {
                    // if ((this.props.OnUpdateGroup && GetAllMembers)) this.props.OnUpdateGroup({ GroupMembers: UpdatedGroupMembers.GroupMembers, GroupName });
                    // else if (this.props.OnUpdateGroup) this.props.OnUpdateGroup({ GroupName });
                    // else this.props.OnHide();
                    this.props.OnHide();
                }
            });
        }
    }

    onChangeGroupName = event => {
        this.setState({ GroupName: event.target.value });
    }

    onLoadExistingGroupMembers = Increment => {
        var { ExistingMembersPageNo: PageNo, ExistingMembersPageSize: PageSize, GroupType, NewSearch, RemoveMemberIds, SearchValue } = this.state;
        var { GroupId } = this.props;

        if (Increment) {
            NewSearch = false;
            PageNo = PageNo + 1;
        } else {
            NewSearch = true;
            PageNo = 1;
        }

        this.setState({ NewSearch, ExistingMembersPageNo: PageNo },
            () => this.props.SearchGroupMembers({ AddToExisting: Increment, GroupId, GroupType, PageNo, PageSize, ReturnData: true, UserSearch: SearchValue }).then(({ GroupMembers, IsMoreResults: GroupMembers_IsMoreResults, TotalRecords: GroupMembers_TotalRecords }) => {
                if (this._isMounted) {
                    var EditedMemberIdsList = [ ...this.state.EditedMemberIdsList, ...GroupMembers.filter(({ UserId }) => RemoveMemberIds.indexOf(+UserId) === -1).map(({ UserId }) => +UserId) ];

                    var NewGroupMembers = [];
                    
                    if (!NewSearch) NewGroupMembers = [ ...this.state.GroupMembers, ...GroupMembers ];
                    else NewGroupMembers = [ ...GroupMembers ];
                
                    this.setState({ CompletedFirstSearch: true, EditedMemberIdsList, GroupMembers: NewGroupMembers, GroupMembers_IsMoreResults, GroupMembers_TotalRecords, Loading: false });
                }
            })
        );
    }

    onLoadTrainerClients = Increment => {
        var { EditingType, GroupType, TrainerClientsPageNo: PageNo, TrainerClientsPageSize: PageSize, NewSearch, SearchValue } = this.state;
        var { GroupId } = this.props;

        if (Increment) {
            NewSearch = false;
            PageNo = PageNo + 1;
        } else {
            NewSearch = true;
            PageNo = 1;
        }

        this.setState({ NewSearch, TrainerClientsPageNo: PageNo },
            () => this.props.SearchTrainerClients({ AddToExisting: Increment, EditingType, GroupId, GroupType, PageNo, PageSize, ReturnData: true, UserSearch: SearchValue }).then(({ TrainerClients, IsMoreResults: TrainerClients_IsMoreResults, TotalRecords: TrainerClients_TotalRecords }) => {
                if (this._isMounted) {
                    var NewTrainerClients = [];
                    
                    if (!NewSearch) NewTrainerClients = [ ...this.state.TrainerClients, ...TrainerClients ];
                    else NewTrainerClients = [ ...TrainerClients ];
                
                    this.setState({ CompletedFirstSearch: true, TrainerClients: NewTrainerClients, TrainerClients_IsMoreResults, TrainerClients_TotalRecords, Loading: false });
                }
            })
        );
    }

    onSelectGroupType = GroupType => {
        this.setState({ GroupType });
    }

    onSelectMember = (User, FromList) => {
        var UserId = +User.UserId;

        var SelectedIndex = this.state.EditedMemberIdsList.indexOf(UserId);
        var AddMemberIndex = this.state.AddMemberIds.indexOf(UserId);
        var RemoveMemberIndex = this.state.RemoveMemberIds.indexOf(UserId);

        var AddMemberIds = [ ...this.state.AddMemberIds ];
        var EditedMemberIdsList = [ ...this.state.EditedMemberIdsList ];
        var RemoveMemberIds = [ ...this.state.RemoveMemberIds ];

        if (SelectedIndex === -1) {
            EditedMemberIdsList = [ ...this.state.EditedMemberIdsList, UserId ];

            // Add Member From Clients
                if (FromList === 'New' && AddMemberIndex === -1) AddMemberIds = [ ...AddMemberIds, UserId ];
            // Current Member Who Needs To Be Removed From Being Removed
                else if (RemoveMemberIndex !== -1) RemoveMemberIds.splice(RemoveMemberIndex, 1);
        }
        else {
            EditedMemberIdsList.splice(SelectedIndex, 1);

            // Added Member That Needs To Be Un-Added
                if (FromList === 'New' && AddMemberIndex !== -1) AddMemberIds.splice(AddMemberIndex, 1);
                // Existing Member Will Be Removed
                else if (RemoveMemberIndex === -1) RemoveMemberIds = [ ...RemoveMemberIds, UserId ];
        }

        this.setState({ AddMemberIds, EditedMemberIdsList, RemoveMemberIds });
    }

    renderGroupType = () => {
        var { t } = this.props;

        var ButtonOptions = [{ BackgroundColorHover: 'blue-abel', ColorHover: 'white', OnClick: () => this.onSelectGroupType('Team'), Title: t('Team') }, { BackgroundColorHover: 'blue-abel', ColorHover: 'white', OnClick: () => this.onSelectGroupType('Private'), Title: t('Group') } ];

        return (
            <GroupTypeContainer>
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('create_team_or_group')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <GroupTypeSubContainer>
                    <MultiText
                        Texts={[
                            { FontFamily: 'semibold', FontSize: 'medium-1', Text: t('Groups') },
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('groups_description') }
                        ]}
                        TextAlign="center"
                    />

                    <Spacer Size="extra-small" />

                    <MultiText
                        Texts={[
                            { FontFamily: 'semibold', FontSize: 'medium-1', Text: t('Teams') },
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('teams_description'), WhiteSpace: 'pre-line' }
                        ]}
                        TextAlign="center"
                    />
                </GroupTypeSubContainer>

                <Spacer Size="medium" />

                <ButtonGroup Buttons={ButtonOptions} NotTouching />
            </GroupTypeContainer>
        );
    }

    renderModalBody = () => {
        if (this.state.Loading || this.props.TryingGetAddEditGroupSettings) return <Loading />;

        var { t } = this.props;
        var { OnlyClients } = this.props;
        // var { EditedMemberIdsList, ExistingMembersPageNo, ExistingMembersPageSize, GroupMembers, GroupMembers_IsMoreResults, GroupMembers_TotalRecords, GroupType, TrainerClientsPageNo, TrainerClientsPageSize, TrainerClients, TrainerClients_IsMoreResults, TrainerClients_TotalRecords } = this.state;
        var { AddMemberIds, AddToFutureWorkouts, EditedMemberIdsList, ExistingMembersPageNo, ExistingMembersPageSize, GroupMembers, GroupMembers_IsMoreResults, GroupMembers_TotalRecords, GroupType, TrainerClientsPageNo, TrainerClientsPageSize, TrainerClients, TrainerClients_IsMoreResults, TrainerClients_TotalRecords } = this.state;

        if (!GroupType) return this.renderGroupType();

        return (
            <div>
                {(this.props.TryingInsertEditUserGroup || this.props.TryingInsertEditUserGroupMembers || this.props.TryingSearchGroupMembers || this.props.TryingSearchTrainerClients) && <Loading />}

                {
                    !OnlyClients &&
                    <>
                        <Input Centered FontSize="medium-2" NoLabel NoMargin OnChange={this.onChangeGroupName} Placeholder={t('ptadmin_groupname_placeholder')} Size="medium" Type="text" Value={this.state.GroupName} />
                
                        <Spacer Size="medium" />
                    </>
                }

                {/* EXISTING MEMBERS */}
                    {
                        !!GroupMembers.length &&
                        <>
                            <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('group_existing_members')} TextAlign="center" />
                    
                            <Spacer Size="small" />

                            <StyledPaginatedList
                                CompletedFirstSearch={true}
                                HideNoResults
                                HideViewCount
                                IsLoading={false}
                                ItemDescriptionType={t('ptadmin_navigation_clients').toLowerCase()}
                                ItemId="UserId"
                                ItemName="UserName"
                                ItemProps={{
                                    HasBorder: true,
                                    OnSelectMember: User => this.onSelectMember(User, 'Existing')
                                }}
                                ItemRenderer={GroupMemberListItem}
                                Items={GroupMembers}
                                HasMoreItems={!!+GroupMembers_IsMoreResults}
                                LoadMore={() => this.onLoadExistingGroupMembers(true)}
                                NewSearch={false}
                                NoItemsText={t('search_noresults')}
                                PageNo={ExistingMembersPageNo}
                                PageSize={ExistingMembersPageSize}
                                SelectedItemIds={EditedMemberIdsList}
                                TotalRecords={GroupMembers_TotalRecords}
                            />

                            <Spacer Size="medium" />
                        </>
                    }

                {/* NEW MEMBERS */}
                    <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('ptadmin_clients_add_plural')} TextAlign="center" />
                    
                    <Spacer Size="small" />

                    <StyledPaginatedList
                        CompletedFirstSearch={true}
                        HideNoResults
                        HideViewCount
                        IsLoading={false}
                        ItemDescriptionType={t('ptadmin_navigation_clients').toLowerCase()}
                        ItemId="UserId"
                        ItemName="UserName"
                        ItemProps={{
                            HasBorder: true,
                            OnSelectMember: User => this.onSelectMember(User, 'New')
                        }}
                        ItemRenderer={GroupMemberListItem}
                        Items={TrainerClients}
                        HasMoreItems={!!+TrainerClients_IsMoreResults}
                        LoadMore={() => this.onLoadTrainerClients(true)}
                        NewSearch={false}
                        NoItemsText={t('search_noresults')}
                        PageNo={TrainerClientsPageNo}
                        PageSize={TrainerClientsPageSize}
                        SelectedItemIds={EditedMemberIdsList}
                        TotalRecords={TrainerClients_TotalRecords}
                    />

                {
                    !!AddMemberIds.length &&
                    <>
                        <Spacer Size="medium" />

                        <CheckmarkContainer className="CheckmarkContainer">
                            <StyledCheckmark
                                ItemId={1}
                                OnSelectItem={this.onCheckAddToFutureWorkouts}
                                SelectedIndex={AddToFutureWorkouts ? 0 : -1}
                                Title={t('add_to_future_workouts')}
                            />

                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" OnClick={this.onCheckAddToFutureWorkouts} NoMargin Text={t('add_to_future_workouts')} TextAlign="center" />
                        </CheckmarkContainer>
                    </>
                }
            </div>
        );
    }

    render() {
        var { t } = this.props;
        var { OnHide, Show, TryingGetAddEditGroupSettings, TryingInsertEditUserGroup, TryingInsertEditUserGroupMembers, TryingSearchGroupMembers, TryingSearchTrainerClients } = this.props;
        var { GroupId, GroupName, GroupType } = this.state;

        var ModalSize = !GroupType ? 'small' : 'large';
        var BottomButtonText = !GroupType ? '' : GroupId ? `${t('edit_with_first_char_uppercase')} ${GroupType === 'Team' ? t('Team').toLowerCase() : t('Group').toLowerCase()}` : `${t('create')} ${GroupType === 'Team' ? t('Team').toLowerCase() : t('Group').toLowerCase()}`;

        return (
            <AbelModal
                BottomButton={GroupType && "submit"}
                BottomButtonOnClick={this.onClickSubmit}
                BottomButtonText={BottomButtonText}
                Disabled={!GroupType || (!GroupId && !GroupName) || TryingGetAddEditGroupSettings || TryingInsertEditUserGroup || TryingInsertEditUserGroupMembers || TryingSearchGroupMembers || TryingSearchTrainerClients}
                GA_PathName="AddEditGroupMembers"
                Show={Show}
                Size={ModalSize}
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

AddEditGroupMembers.propTypes = {
    FromDashboard: PropTypes.number,
    GroupId: PropTypes.number,
    GroupIndex: PropTypes.number,
    GroupName: PropTypes.string,
    OnlyClients: PropTypes.bool.isRequired,
    OnHide: PropTypes.func.isRequired,
    OnRefreshGroups: PropTypes.func,
    OnUpdateGroup: PropTypes.func,
    RefreshGroupMembers: PropTypes.func,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        ExistingMembers: state.Group.ExistingMembers,
        NewMembers: state.Group.NewMembers,

        TryingSearchTrainerClients: state.Clients.TryingSearchTrainerClients,
        TryingSearchTrainerClientsError: state.Clients.TryingSearchTrainerClientsError,

        TryingGetAddEditGroupSettings: state.Group.TryingGetAddEditGroupSettings,
        TryingGetAddEditGroupSettingsError: state.Group.TryingGetAddEditGroupSettingsError,
        TryingInsertEditUserGroup: state.Group.TryingInsertEditUserGroup,
        TryingInsertEditUserGroupError: state.Group.TryingInsertEditUserGroupError,
        TryingInsertEditUserGroupMembers: state.Group.TryingInsertEditUserGroupMembers,
        TryingInsertEditUserGroupMembersError: state.Group.TryingInsertEditUserGroupMembersError,
        TryingSearchGroupMembers: state.Group.TryingSearchGroupMembers,
        TryingSearchGroupMembersError: state.Group.TryingSearchGroupMembersError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearAddEditGroupSettings, GetAddEditGroupSettings, InsertEditUserGroup, InsertEditUserGroupMembers, SearchGroupMembers, SearchTrainerClients } )(AddEditGroupMembers));