import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DownloadTrainerPayoutSummary, ViewTrainerPayoutSummary } from '../../Services/Actions';

import moment from 'moment';

import * as PayoutStyles from './styles';

import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import PDF from '../../Assets/Icons/PDF.png';

class Payouts extends React.Component {
    state = { ActiveSearch: false, Loading: true, PageNo: 1, PageSize: 30 }

    componentDidMount() {
        this.onLoadPayouts();
    }

    onLoadPayouts = Increment => {
        var { PageNo, PageSize } = this.state;

        if (Increment) PageNo = PageNo + 1;
        else PageNo = 1;

        this.setState({ ActiveSearch: true, PageNo }, () => this.props.ViewTrainerPayoutSummary({ AddToExisting: Increment, PageNo, PageSize }).then(() => this.setState({ ActiveSearch: false, Loading: false })));
    }

    onViewInvoicePDF = ({ CSVFileName }) => {
        this.props.DownloadTrainerPayoutSummary({ CSVFileName });
    }

    renderPayoutHeader = () => {
        var { t } = this.props;

        return (
            <>
                <PayoutStyles.PayoutRow Header>
                    <div />
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('Payments_PayoutDate')} TextAlign="center" />
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('Payments_PayoutPDF')} TextAlign="left" />
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('Payments_PayoutAmount')} TextAlign="center" />
                </PayoutStyles.PayoutRow>

                <Spacer Size="extra-extra-small" />
            </>
        );
    }

    renderPayouts = ({ Item: { CSVFileName, PayoutAmount, PayoutDate } }) => {
        var { t } = this.props;

        return (
            <PayoutStyles.PayoutRow className="PayoutRow">
                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={moment(PayoutDate).format('MMM YYYY')} TextAlign="center" />
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={moment(PayoutDate).format('[NumbersDate]')} TextAlign="center" />
                
                <PayoutStyles.DownloadContainer>
                    <PayoutStyles.StyledPDFIcon ImageSrc={PDF} OnClick={() => this.onViewInvoicePDF({ CSVFileName })} />
                    <PageText FontColor="blue-abel" FontColorHover="blue-abel-hover" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onViewInvoicePDF({ CSVFileName })} Text={t('download.pdf')} TextAlign="left" />
                </PayoutStyles.DownloadContainer>
                <PageText FontColor="green-success" FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={PayoutAmount} TextAlign="center" />
            </PayoutStyles.PayoutRow>
        );
    }

    render() {
        var { t } = this.props;
        var { Payouts: { IsMoreResults, Payouts: PayoutList, TotalRecords }, TryingDownloadTrainerPayoutSummary, TryingSearchPayouts } = this.props;
        var { ActiveSearch, PageNo, PageSize } = this.state;

        if (this.state.Loading) return <Loading />;

        return (
            <>
                {(TryingDownloadTrainerPayoutSummary) && <Loading />}

                <PayoutStyles.PayoutsContainer>
                    <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('payment_plural')} TextAlign="center" />

                    <Spacer Size="small" />

                    <PayoutStyles.StyledPaymentsLoadMore
                        ActiveSearch={ActiveSearch}
                        CompletedFirstSearch
                        IsLoading={TryingSearchPayouts}
                        ItemDescriptionType={t('payment_plural').toLowerCase()}
                        ItemId="Id"
                        ItemName="PayoutAmount"
                        ItemRenderer={this.renderPayouts}
                        Items={PayoutList}
                        HasMoreItems={!!+IsMoreResults}
                        LoadMore={() => this.onLoadPayouts(true)}
                        NewSearch={false}
                        NoItemsText={t('no_payments')}
                        PageNo={PageNo}
                        PageSize={PageSize}
                        RenderHeader={this.renderPayoutHeader}
                        TotalRecords={TotalRecords}
                    />
                </PayoutStyles.PayoutsContainer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        Payouts: state.Sales.Payouts,

        TryingDownloadTrainerPayoutSummary: state.Sales.TryingDownloadTrainerPayoutSummary,
        TryingSearchPayouts: state.Sales.TryingSearchPayouts,
        TryingSearchPayoutsError: state.Sales.TryingSearchPayoutsError
    };
};

export default withTranslation()(connect(mapStateToProps, { DownloadTrainerPayoutSummary, ViewTrainerPayoutSummary } )(Payouts));