import styled from 'styled-components';
import { device } from '../../Config/device';

import BodyMuscles from '../../Components/BodyMuscles';
import Image from '../../Components/Image';
import LineBar from '../../Components/Charts/ProgressBars/Line';
import PageText from '../../Components/Text/PageText';

export const BodyContainer = styled.div``;

export const BottomArea = styled.div``;

export const ChartContainer = styled.div``;

export const Container = styled.div`
    margin: 0 5%;
`;

export const DetailContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
`;

export const ExerciseImageContainer = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    padding: 5px;
`;

export const LeftTopArea = styled.div`
    flex: 1;
    width: 100%;
`;

export const LineGraphContainer = styled.div`
    background-color: var(--white);
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    padding: 25px 15px;
`;

export const ProgressContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const ProgressContainerInner = styled.div`
    background-color: var(--white);
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    padding: 10px;

    :first-child {
        margin-right: 15px;
    }
`;

export const RightTopArea = styled.div`
    flex: 1;
    width: 100%;
`;

export const StyledBodyMuscles = styled(BodyMuscles)`
    margin: 0 auto;
    padding: 10px;
    width: 100%;

    > svg {
        max-height: 350px;
    }
`;

export const StyledExerciseImage = styled(Image)`
    border-radius: 10px;
    height: 235px;
    object-fit: cover;
    width: 100%;

    @media ${device.minwidth.ipad} {
        height: 350px;
    }
`;

export const StyledExerciseVideo = styled.video`
    border-radius: 10px;
    display: flex;
    height: 235px;
    max-width: 100%;
    width: 100%;

    @media ${device.minwidth.ipad} {
        height: 350px;
    }
`;

export const StyledInstructions = styled(PageText)`
    white-space: pre-line;
`;

export const StyledLineBar = styled(LineBar)`
    border: none;
`;

export const TopArea = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${device.minwidth.ipad} {
        align-items: start;
        flex-direction: row;
        justify-content: space-between;
    
        > div:first-child {
            margin-right: 20px;
        }
    }
`;

export const TrackingHistoryContainer = styled.div`
    background-color: var(--white);
    border-radius: 10px;
    padding: 10px;
    width: 100%;
`;

export const TrackingHistoryRow = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: repeat(${props => props.ColumnCount}, 1fr);
    text-align: center;

    :not(:last-child) {
        margin-bottom: 10px;
    }
`;