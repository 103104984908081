import styled from 'styled-components';

export const LeftSide = styled.div`
    display: grid;
    grid-template-columns: 100%;
    row-gap: 6px;
    margin-right: 12px;

    > label {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
    }
`;

export const ProgressBar = styled.div`
    width: 100%;
    height: 15px;
    background-color: lightgray;
    margin-bottom: 8px;
    border-radius: 20px;

    > div {
        height: 15px;
        background-color: lightgreen;
        border-radius: 20px;
        transition: width 0.2s;
    }
`;

export const RightSide = styled.div`
    display: grid;
    grid-template-columns: 100%;
    row-gap: 6px;
    margin-right: 12px;

    > span:first-child {
        font-size: 18px;
        cursor: pointer;
    }
`;

export const WrapperItem = styled.div`
    display: grid;
    grid-template-columns: calc(100% - 50px) 50px;
    padding: 16px;
    box-shadow: 0px 1px 2px lightgrey;
`;