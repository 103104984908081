import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { SearchGroupMembers } from '../../Services/Actions';

import { StyledPaginatedList } from './styles';

import AbelModal from '../../Components/Modal';
import { GroupMemberListItem } from '../../Components/Lists/ListItems';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

class AddGroupWorkoutMembers extends React.Component {
    _isMounted = false;

    state = {
        AddMemberIds: [],
        EditingType: 'Members',
        GroupMembers: [],
        IsMoreResults: false,
        Loading: true,
        PageNo: 1,
        PageSize: 30,
        TotalRecords: null
    };

    componentDidMount() {
        this._isMounted = true;

        this.onLoadNewWorkoutMembers();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onAddGroupWorkoutMembers = () => {
        this.props.OnAddGroupWorkoutMembers(this.state.AddMemberIds);
    }

    onLoadNewWorkoutMembers = Increment => {
        var { PageNo, PageSize, SearchValue } = this.state;
        var { GroupId, GroupType, GroupWorkoutId } = this.props;

        if (Increment) PageNo = PageNo + 1;
        else PageNo = 1;

        this.setState({ PageNo },
            () => this.props.SearchGroupMembers({ AddToExisting: Increment, GroupId, GroupType, GroupWorkoutId, PageNo, PageSize, ReturnData: true, UserSearch: SearchValue }).then(({ GroupMembers, IsMoreResults, TotalRecords }) => {
                if (this._isMounted) {
                    var NewGroupMembers = [];
                    
                    if (PageNo !== 1) NewGroupMembers = [ ...this.state.GroupMembers, ...GroupMembers ];
                    else NewGroupMembers = [ ...GroupMembers ];
                
                    this.setState({ GroupMembers: NewGroupMembers, IsMoreResults, TotalRecords, Loading: false });
                }
            })
        );
    }

    onSelectMember = User => {
        var UserId = +User.UserId;

        var AddMemberIndex = this.state.AddMemberIds.indexOf(UserId);
        var AddMemberIds = [ ...this.state.AddMemberIds ];

        if (AddMemberIndex === -1) AddMemberIds = [ ...AddMemberIds, UserId ]
        else AddMemberIds.splice(AddMemberIndex, 1);

        this.setState({ AddMemberIds });
    }

    renderModalBody = () => {
        if (this.state.Loading || this.props.TryingGetAddEditGroupSettings) return <Loading />;

        var { t } = this.props;
        var { AddMemberIds, GroupMembers, IsMoreResults, PageNo, PageSize, TotalRecords } = this.state;

        return (
            <>
                {(this.props.TryingSearchGroupMembers) && <Loading />}

                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('add_members')} TextAlign="center" />
        
                <Spacer Size="small" />

                <StyledPaginatedList
                    CompletedFirstSearch={true}
                    HideNoResults
                    IsLoading={false}
                    ItemDescriptionType={t('ptadmin_navigation_clients').toLowerCase()}
                    ItemId="UserId"
                    ItemName="UserName"
                    ItemProps={{
                        HasBorder: true,
                        OnSelectMember: User => this.onSelectMember(User)
                    }}
                    ItemRenderer={GroupMemberListItem}
                    Items={GroupMembers}
                    HasMoreItems={!!+IsMoreResults}
                    LoadMore={() => this.onLoadNewWorkoutMembers(true)}
                    NewSearch={false}
                    NoItemsText={t('search_noresults')}
                    PageNo={PageNo}
                    PageSize={PageSize}
                    SelectedItemIds={AddMemberIds}
                    TotalRecords={TotalRecords}
                />

                <Spacer Size="medium" />
            </>
        );
    }

    render() {
        var { t } = this.props;
        var { OnHide, Show, TryingSearchGroupMembers } = this.props;
        var { AddMemberIds } = this.state;

        var BottomButtonText = `${t('add')} ${AddMemberIds.length === 1 ? t('member').toLowerCase() : t('members').toLowerCase()}`;

        return (
            <AbelModal
                BottomButton="submit"
                BottomButtonOnClick={this.onAddGroupWorkoutMembers}
                BottomButtonText={BottomButtonText}
                Disabled={!AddMemberIds.length || TryingSearchGroupMembers}
                GA_PathName="AddGroupWorkoutMembers"
                Show={Show}
                Size="large"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

AddGroupWorkoutMembers.propTypes = {
    GroupId: PropTypes.number.isRequired,
    GroupType: PropTypes.string.isRequired,
    GroupWorkoutId: PropTypes.number.isRequired,
    OnAddGroupWorkoutMembers: PropTypes.func.isRequired,
    OnHide: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        TryingSearchGroupMembers: state.Group.TryingSearchGroupMembers,
        TryingSearchGroupMembersError: state.Group.TryingSearchGroupMembersError
    };
};

export default withTranslation()(connect(mapStateToProps, { SearchGroupMembers } )(AddGroupWorkoutMembers));