import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearActivityLevels, UpdateDailyActivityLevel, ViewActivityLevels } from '../../Services/Actions';
import moment from 'moment';

import  { ActivityLevelContainer, Button, ButtonContainer, Container, EqualSignContainer, StyledInput } from './styles';

import AbelModal from '../../Components/Modal';
import Accordion from '../../Components/Accordion';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import NumberInput from '../../Components/Form/NumberInput';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Edit from '../../Assets/Icons/Edit_Dark.png';

class ActivityLevels extends React.Component {
    _isMounted = false;

    state = {
        ActivityLevel: +this.props.CurrentActivityLevel.ActivityLevel,
        Calories: this.props.CurrentActivityLevel.Calories,
        Editing: false,
        Loading: true,
        ShowActivityLevelsForClient: false,
        ShowWhatIsActivityLevel: false
    };

    componentDidMount() {
        this._isMounted = true;

        this.props.ViewActivityLevels(this.props.ClientId).then(() => {
            if (this._isMounted) this.setState({ Loading: false });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.ClearActivityLevels();
    }

    onChangeActivityLevel = Direction => {
        var { ActivityLevel } = this.state;

        var { ActivityLevels } = this.props.ActivityLevels;

        if (!ActivityLevel && Direction === -1) this.setState({ ActivityLevel: 1, Calories: ActivityLevels[0].ActivityLevelCalories });
        else if (!ActivityLevel && Direction === 1) this.setState({ ActivityLevel: 5, Calories: ActivityLevels[4].ActivityLevelCalories });
        else this.setState({ ActivityLevel: ActivityLevel + Direction, Calories: ActivityLevels[ActivityLevel + Direction - 1].ActivityLevelCalories });
    }

    onChangeCalories = event => {
        this.setState({ ActivityLevel: 0, Calories: event.target.value });
    }

    onClickSubmit = () => {
        var { ActivityLevel, Calories } = this.state;
        var { ClientId, CurrentActivityLevel: { DayDate, DayIndex } } = this.props;

        var LevelType;
        if (!ActivityLevel) { ActivityLevel = 0; LevelType = 'c'; }
        else LevelType = 'Al';

        this.props.OnHide();
        this.props.UpdateDailyActivityLevel({ ActivityLevel, Calories, ClientId, DayDate, DayIndex, LevelType });
    }

    onToggleEditing = Editing => {
        this.setState({ Editing });
    }

    renderModalBody = () => {
        if (this.state.Loading || this.props.TryingGetActivityLevels) return <Loading />;

        var { t } = this.props; 
        var { CurrentActivityLevel } = this.props;
        var { ActivityLevel, Calories, Editing } = this.state;

        return (
            <>
                <PageText FontFamily="bold" FontSize="medium-3" NoMargin Text={moment(CurrentActivityLevel.DayDate).format('MMM DD')} />

                <Spacer Size="medium" />

                <ActivityLevelContainer>
                    <Container>
                        <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('level_str')} />
                        <Spacer Size="small" />
                        {
                            Editing ?
                            <NumberInput
                                FontFamily="bold"
                                FontSize="large"
                                MaxValue={5}
                                MinValue={1}
                                OnChange={Direction => this.onChangeActivityLevel(Direction)}
                                Value={ActivityLevel}
                            />
                        :
                            <PageText FontFamily="bold" FontSize="large" NoMargin Text={`${ActivityLevel}`} />
                        }
                    </Container>
                    <EqualSignContainer>
                        <PageText FontSize="large" NoMargin Text="=" />
                    </EqualSignContainer>
                    <Container>
                        <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('MealPlan_Calories')} />
                        <Spacer Size="small" />
                        {
                            Editing ?
                            <StyledInput
                                FontFamily="bold"
                                FontSize="large"
                                NoLabel
                                OnChange={this.onChangeCalories}
                                Size="medium"
                                Type="text"
                                Value={Calories}
                            />
                        :
                            <PageText FontFamily="bold" FontSize="large" NoMargin Text={Calories} />
                        }
                    </Container>
                </ActivityLevelContainer>

                {
                    Editing ?
                    <>
                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="small" NoMargin Text={t('ptadmin_activitylevel_instructions')} />

                        <Spacer Size="small" />

                        <ButtonContainer>
                            <Button onClick={this.onClickSubmit}>
                                <PageText FontSize="medium-1" NoMargin Text={t('save_changes_with_first_char_uppercase')} />
                            </Button>
                        </ButtonContainer>
                    </>
                :
                    <>
                        <Spacer Size="large" />
                        <ButtonGroup
                            Buttons={[
                                { Icon: Edit, IconPosition: 'left', OnClick: () => this.onToggleEditing(true), Title: t('ptadmin_activitylevel_editclient') },
                            ]}
                            ButtonWidth='65%'
                        />
                    </>
                }

                <Spacer Size="medium" />

                <Accordion BackgroundColor="var(--white)" BorderColor="var(--white-concrete)" Text={t('ptadmin_activitylevel_client_customized')}>
                    {
                        this.props.ActivityLevels.ActivityLevels.map(({ ActivityLevel, ActivityLevelCalories, ActivityLevelDescription, ActivityLevelName }, index) => {
                            var IsMoreLevels = index < this.props.ActivityLevels.ActivityLevels.length ? 1 : 0;

                            return (
                                <div key={ActivityLevel}>
                                    <PageText FontSize="medium-1" NoMargin Text={`${ActivityLevelName} = ${ActivityLevelCalories} ${t('MealPlan_Calories').toLowerCase()}`} />
                                    <Spacer Size="extra-small" />
                                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={ActivityLevelDescription} />
                                    {
                                        IsMoreLevels ?
                                        <Spacer Size="medium" />
                                    :
                                        null
                                    }
                                </div>
                            );
                        })
                    }
                </Accordion>

                <Spacer Size="small" />

                <Accordion BackgroundColor="var(--white)" BorderColor="var(--white-concrete)" Text={t('ptadmin_activitylevel_description_title')}>
                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_activitylevel_description')} TextAlign="center" WhiteSpace="pre-line" />
                </Accordion>
            </>
        );
    }

    render() {
        var { OnHide, Show } = this.props;

        return (
            <AbelModal
                BottomButton="cancel"
                BottomButtonOnClick={OnHide}
                GA_PathName="ActivityLevels"
                Show={Show}
                Size="large"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

ActivityLevels.propTypes = {
    CurrentActivityLevel: PropTypes.object,
    OnHide: PropTypes.func.isRequired,
    Refresh: PropTypes.func,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        ActivityLevels: state.Client.ActivityLevels,

        TryingGetActivityLevels: state.Client.TryingGetActivityLevels,
        TryingGetActivityLevelsError: state.Client.TryingGetActivityLevelsError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearActivityLevels, UpdateDailyActivityLevel, ViewActivityLevels } )(ActivityLevels));