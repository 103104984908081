import styled from 'styled-components';
import { device } from '../../Config/device';

export const Container = styled.div`
    border-radius: 10px;
    display: grid;

    @media ${device.minwidth.laptop} {
        grid-template-areas: "body filter";
        grid-template-columns: 1fr 300px;
    }

    @media ${device.maxwidth.ipad} {
        grid-template-areas: "header" "body";
        grid-template-columns: 1fr;
    }

    // grid-template-rows: 53px 1fr;
    overflow: hidden;
    width: 100%;
`;