import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import Chart from "chart.js";

//--Chart Style Options--//
Chart.defaults.global.defaultFontFamily = "'branding-medium'"
Chart.defaults.global.legend.display = false;
//--Chart Style Options--//

class BarChart extends PureComponent {
    chartRef = React.createRef();

    componentDidMount() {
        this.buildChart();
    }

    componentDidUpdate(prevProps) {
        var NeedsRerender = false;

        var { ChartType } = this.props.ReRenderObj;

        if (ChartType === 'PaymentDashboard' && ((prevProps.ReRenderObj.MonthlyYear !== this.props.ReRenderObj.MonthlyYear) || (prevProps.ReRenderObj.PaymentType !== this.props.ReRenderObj.PaymentType))) NeedsRerender = true;

        if (NeedsRerender) {
            this.myChart.destroy();
            this.buildChart();
        }
    }

    buildChart = () => {
        var { ChartData, Orientation } = this.props;

        var data = this.buildChartData({ ChartData });
        var type = Orientation.toLowerCase() === 'vertical' ? 'bar' : 'horizontalBar';

        this.myChart = new Chart(this.chartRef.current, {
            type, data,
            options: {
                scales: {
                    xAxes: [{
                        gridLines: {
                            offsetGridLines: true
                        }
                    }]
                }
            }
        });
    }

    buildChartData = ({ ChartData }) => {
        var INITIAL_DATASET = { backgroundColor: 'rgba(39, 71, 117, 1)' };

        var datasets = [{ ...INITIAL_DATASET, data: [] }];
        var labels = [];

        ChartData.map(({ Label, Measurement }) => {
            labels.push(Label);

            datasets[0].data.push(Measurement);

            return null;
        });

        return { labels, datasets };
    }

    render() {

        return (
            <div>
                <canvas ref={this.chartRef} />
            </div>
        )
    }
}

BarChart.propTypes = {
    ChartData: PropTypes.array.isRequired,
    Orientation: PropTypes.string,
    ReRenderObj: PropTypes.object.isRequired
}

BarChart.defaultProps = {
    Orientation: 'vertical'
}

export default BarChart;