import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddGetStartedMeals, AddGetStartedWorkouts, ClearGetStarted, GetClientMealSettings, ViewActivityLevels, ViewExperienceLevels, ViewGoals, ViewInjuries } from '../../Services/Actions';

import { ActivityLevelContainer, ActivityLevelsContainer, ActivityLevelDetailsContainer, AddGymNoteContainer, BackNextButtons, Container, DailyCalorieContainer, DefaultGymContainer, DropdownContainer, ExperienceLevelContainer, ExperienceLevelsContainer, GetStartedButton, GymIcon, GymLogo, HeightFeetIcon, HeightInput, Illustration, IllustrationContainer, IntroContainer, InstructionsContainer, MultiUnit, MultiUnitContainer, NumberOfMeal, NumberOfMealsContainer, QuestionContainer, QuestionCount, QuestionCountContainer, UnitInput, UnitInputContainer } from './styles';

import ButtonGroup from '../Buttons/ButtonGroup';
import Dropdown from '../Dropdown';
import Loading from '../Loading';
import PageText from '../Text/PageText';
import Spacer from '../Spacer';

import ChooseGym from '../../Modals/ChooseGym';

import ForgotPassword from '../../Assets/Illustrations/ForgotPassword.png';
import GainMuscleMass from '../../Assets/Illustrations/GainMuscleMass.png';
import GetHealthy from '../../Assets/Illustrations/Food.png';
import Gym from '../../Assets/Icons/Gym.png';
import LoseWeight from '../../Assets/Illustrations/LoseWeight.png';

class GetStarted extends React.Component {
    _isMounted = false;

    state = {
        GetStartedQuestionAnswers: {
            ActivityLevel: 0,
            AllergyIds: [],
            CustomCalories: 0,
            DailyCalorieType: null,
            DietTypeId: 1,
            GoalId: null,
            HasDietaryRestrictions: null,
            Height: 0,
            HeightUnit: '',
            Height_Feet: 0,
            Height_Inches: 0,
            NumberOfMeals: 0,

            IsPreRegistered: 0,

            DefaultGym: {},
            DefaultGymId: null,
            DefaultGymImageLoaded: false,
            ExperienceLevel_Endurance: null,
            ExperienceLevel_Strength: null,
            HasGym: null,
            JointIds: [],
            MuscleIds: [],

            Weight: 0,
            WeightUnit: ''
        },
        GetStartedQuestionId: 0,
        ShowChooseGymModal: false
    }

    componentDidMount() {
        this._isMounted = true;

        var { ClientDetails: { Height, HeightUnit, Weight, WeightUnit }, UserDetails: { PreferredMeasuringSystem } } = this.props;

        // Used In Dashboard Give Access
            if (this.props.ProvidedUserDetails) {
                // eslint-disable-next-line
                var { Height, HeightUnit, IsPreRegistered, UserUnits, Weight, WeightUnit } = this.props.ProvidedUserDetails;
                
                if (!IsPreRegistered) {
                    PreferredMeasuringSystem = UserUnits;

                    var Height_Feet;
                    var Height_Inches;

                    var NewValue = Height;

                    if (HeightUnit === 'Metric') NewValue = Math.round((Height / 2.54) * 10) / 10;
                    
                    Height_Feet = Math.floor(NewValue / 12);
                    Height_Inches = Math.floor(NewValue - (Height_Feet * 12));

                    if (WeightUnit !== UserUnits) {
                        if (WeightUnit === 'Metric') Weight = Math.round((Weight * 2.20462) * 10) / 10;
                        else Weight = Math.round((Weight / 2.20462) * 10) / 10;
                    }
                }
            }
        
        if (!IsPreRegistered) {
            HeightUnit = PreferredMeasuringSystem.toLowerCase() === 'imperial' ? 'FT' : 'CM';
            WeightUnit = PreferredMeasuringSystem.toLowerCase() === 'imperial' ? 'LBS' : 'KG';
        }

        this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, ...IsPreRegistered && { DailyCalorieType: 'custom' }, Height, Height_Feet, Height_Inches, HeightUnit, IsPreRegistered, Weight, WeightUnit } });
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        this.props.ClearGetStarted();
    }
    
    changeGetStartedQuestion = (Direction, CanGoNext, LastScreen) => {
        var { GetStartedQuestionId, GetStartedQuestionAnswers: { IsPreRegistered } } = this.state;
        var { ClientId, NeedsHeight, NeedsWeight, RetrievedActivityLevels, RetrievedExperienceLevels, RetrievedGoals, RetrievedInjuries, Type } = this.props;

        GetStartedQuestionId = Direction === 1 ? GetStartedQuestionId + 1 : GetStartedQuestionId - 1;

        // MEALS
            if (Type.toLowerCase() === 'meals') {
                if (Direction === 1 && CanGoNext && GetStartedQuestionId === 1 && !RetrievedGoals && !IsPreRegistered) {
                    this.setState({ GetStartedQuestionId }, () => this.props.ViewGoals());
                } else if (Direction === 1 && CanGoNext && !LastScreen && ((GetStartedQuestionId === 2 && !NeedsHeight && !NeedsWeight) || (GetStartedQuestionId === 3 && NeedsHeight && !NeedsWeight) || (GetStartedQuestionId === 3 && !NeedsHeight && NeedsWeight) || (GetStartedQuestionId === 4 && NeedsHeight && NeedsWeight)) && !RetrievedActivityLevels) {
                    this.setState({ GetStartedQuestionId }, () => this.props.ViewActivityLevels(ClientId));
                } else {
                    if (Direction === 1 && CanGoNext && LastScreen) this.onAddGetStarted_Meals();
                    else if (Direction === 1 && CanGoNext) this.setState({ GetStartedQuestionId });
                    else if (Direction === -1) this.setState({ GetStartedQuestionId });
                }


                // if (Direction === 1 && CanGoNext && GetStartedQuestionId === 1 && !RetrievedActivityLevels) {
                //     this.setState({ GetStartedQuestionId }, () => this.props.ViewActivityLevels(ClientId));
                // } else if (Direction === 1 && CanGoNext && ((GetStartedQuestionId === 2 && !NeedsHeight && !NeedsWeight) || (GetStartedQuestionId === 3 && NeedsHeight && !NeedsWeight) || (GetStartedQuestionId === 3 && !NeedsHeight && NeedsWeight) || (GetStartedQuestionId === 4 && NeedsHeight && NeedsWeight)) && !RetrievedGoals) {
                //     this.setState({ GetStartedQuestionId }, () => this.props.ViewGoals());
                // } else {
                //     if (Direction === 1 && CanGoNext && LastScreen) this.onAddGetStarted_Meals();
                //     else if (Direction === 1 && CanGoNext) this.setState({ GetStartedQuestionId });
                //     else if (Direction === -1) this.setState({ GetStartedQuestionId });
                // }
            }
        // WORKOUTS
            else if (Type.toLowerCase() === 'workouts') {
                if (Direction === 1 && CanGoNext && ((GetStartedQuestionId === 2 && NeedsWeight) || (GetStartedQuestionId === 1 && !NeedsWeight)) && !RetrievedExperienceLevels) {
                    this.setState({ GetStartedQuestionId }, () => this.props.ViewExperienceLevels(ClientId));
                } else if (Direction === 1 && CanGoNext && ((GetStartedQuestionId === 5 && NeedsWeight) || (GetStartedQuestionId === 4 && !NeedsWeight)) && !RetrievedInjuries) {
                    this.setState({ GetStartedQuestionId }, () => this.props.ViewInjuries());
                } else {
                    if (Direction === 1 && CanGoNext && LastScreen) this.onAddGetStarted_Workouts();
                    else if (Direction === 1 && CanGoNext) this.setState({ GetStartedQuestionId });
                    else if (Direction === -1) this.setState({ GetStartedQuestionId });
                }
            }
    }

    onAddGetStarted_Meals = () => {
        var { ClientId, ClientType, FromDashboard } = this.props;
        var { GetStartedQuestionAnswers: { ActivityLevel, AllergyIds, CustomCalories, DailyCalorieType, DietTypeId, GoalId, Height, Height_Feet, Height_Inches, HeightUnit, NumberOfMeals, Weight, WeightUnit } } = this.state;

        var Allergies = AllergyIds.join(',');

        HeightUnit = HeightUnit === 'FT' ? 'Imperial' : 'Metric';
        Height = HeightUnit === 'Imperial' ? (+Height_Feet * 12) + +Height_Inches : +Height;

        WeightUnit = WeightUnit === 'LBS' ? 'Imperial' : 'Metric';

        if (DailyCalorieType === 'abel') CustomCalories = 0;
        else if (DailyCalorieType === 'custom') GoalId = 0;

        this.props.AddGetStartedMeals({ ActivityLevel, Allergies, ClientId, ClientType, CustomCalories, DietTypeId, FromDashboard, GoalId, HasAccess: 1, Height, HeightUnit, NumberOfMeals, Weight, WeightUnit }).then(() => {
            if (!this.props.TryingAddGetStartedMealsError) this.props.RefreshWeekly();
        });
    }

    onAddGetStarted_Workouts = () => {
        var { ClientId } = this.props;
        var { DefaultGymId, ExperienceLevel_Endurance, ExperienceLevel_Strength, JointIds, MuscleIds, Weight, WeightUnit } = this.state.GetStartedQuestionAnswers;

        DefaultGymId = DefaultGymId || 1;
        var Joints = JointIds.join(',');
        var Muscles = MuscleIds.join(',');

        WeightUnit = WeightUnit === 'LBS' ? 'Imperial' : 'Metric';

        this.props.AddGetStartedWorkouts({ ClientId, DefaultGymId, ExperienceLevel_Endurance, ExperienceLevel_Strength, Joints, Muscles, Weight, WeightUnit }).then(() => {
            if (!this.props.TryingAddGetStartedWorkoutsError) this.props.RefreshWeekly();
        });
    }

    onChangeUnitInput = (event, StateName) => {
        var InputValue = event.target.value;
        
        if (!isNaN(InputValue)) {
            if (StateName === 'Height_Feet' || StateName === 'Height_Inches') {
                var { Height_Feet, Height_Inches } = this.state.GetStartedQuestionAnswers;
                Height_Feet = +Height_Feet;
                Height_Inches = +Height_Inches;

                Height_Feet = StateName === 'Height_Feet' ? InputValue : Height_Feet;
                Height_Inches = StateName === 'Height_Inches' ? InputValue >= 12 ? 11 : InputValue : Height_Inches;

                this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, Height_Feet, Height_Inches } });
            }
            else this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, [StateName]: InputValue } });
        }
    }

    onChangeUnitSelection = (StateName, UnitName) => {
        var CurrentUnit = this.state.GetStartedQuestionAnswers[StateName];
        var CurrentValue, NewValue;

        if (StateName === 'HeightUnit' && CurrentUnit !== UnitName) {
            var { Height, Height_Feet, Height_Inches } = this.state.GetStartedQuestionAnswers;
            Height = +Height;
            Height_Feet = +Height_Feet;
            Height_Inches = +Height_Inches;

            if (CurrentUnit === 'CM') {
                NewValue = Height ? Math.round((Height / 2.54) * 10) / 10 : Height;
                Height_Feet = Math.floor(NewValue / 12);
                Height_Inches = Math.floor(NewValue - (Height_Feet * 12));
            }
            else if (CurrentUnit === 'FT') {
                Height = Height_Feet ? Math.round((((Height_Feet * 12) + Height_Inches) * 2.54) * 10) / 10 : Height;
            }

            this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, Height, Height_Feet, Height_Inches, HeightUnit: UnitName } });
        }
        else if (StateName === 'WeightUnit' && CurrentUnit !== UnitName) {
            CurrentValue = +this.state.GetStartedQuestionAnswers.Weight;

            if (CurrentUnit === 'KG') NewValue = CurrentValue ? Math.round((CurrentValue * 2.20462) * 10) / 10 : CurrentValue;
            else if (CurrentUnit === 'LBS') NewValue = CurrentValue ? Math.round((CurrentValue / 2.20462) * 10) / 10 : CurrentValue;

            this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, Weight: NewValue, WeightUnit: UnitName } });
        }
    }

    onLoadedDefaultGymImage = () => {
        this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, DefaultGymImageLoaded: true } });
    }

    onSelectActivityLevel = ActivityLevel => {
        this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, ActivityLevel } });
    }

    onSelectAllergy = (event, AllergyId, AllergyIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = AllergyIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return { GetStartedQuestionAnswers: { ...prevState.GetStartedQuestionAnswers, AllergyIds: [ ...prevState.GetStartedQuestionAnswers.AllergyIds, ...[ AllergyId ] ] } }
            });
        } else {
            this.setState((prevState) => {
                return { GetStartedQuestionAnswers: { ...prevState.GetStartedQuestionAnswers, AllergyIds: [ ...prevState.GetStartedQuestionAnswers.AllergyIds.slice(0, AllergyIndex), ...prevState.GetStartedQuestionAnswers.AllergyIds.slice(AllergyIndex + 1) ] }}
            });
        }
    }

    onSelectDailyCalorieType = DailyCalorieType => {
        this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, DailyCalorieType } });
    }

    onSelectDietTypeId = DietTypeId => {
        this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, DietTypeId } });
    }

    onSelectExperienceLevel = (ExperienceLevelType, ExperienceLevel) => {
        this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, [`ExperienceLevel_${ExperienceLevelType}`]: ExperienceLevel } });
    }

    onSelectGoalId = GoalId => {
        this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, GoalId } });
    }

    onSelectGym = DefaultGym => {
        this.setState({
            GetStartedQuestionAnswers: {
                ...this.state.GetStartedQuestionAnswers,
                DefaultGym,
                DefaultGymId: DefaultGym.GymId,
                DefaultGymImageLoaded: false,
                HasGym: true
            },
            ShowChooseGymModal: false
        });
    }

    onSelectHasDietaryRestrictions = HasDietaryRestrictions => {
        if (HasDietaryRestrictions) this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, HasDietaryRestrictions } }, () => this.props.GetClientMealSettings({ ClientId: this.props.ClientId }));
        else this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, HasDietaryRestrictions } });
    }

    onSelectHasGym = (HasGym, DefaultGymId) => {
        if (HasGym) this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, HasGym }, ShowChooseGymModal: true });
        else this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, HasGym, DefaultGym: {}, DefaultGymId } });
    }

    onSelectJoint = (event, JointId, JointIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = JointIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return { GetStartedQuestionAnswers: { ...prevState.GetStartedQuestionAnswers, JointIds: [ ...prevState.GetStartedQuestionAnswers.JointIds, ...[ JointId ] ] } }
            });
        } else {
            this.setState((prevState) => {
                return { GetStartedQuestionAnswers: { ...prevState.GetStartedQuestionAnswers, JointIds: [ ...prevState.GetStartedQuestionAnswers.JointIds.slice(0, JointIndex), ...prevState.GetStartedQuestionAnswers.JointIds.slice(JointIndex + 1) ] }}
            });
        }
    }

    onSelectMuscle = (event, MuscleId, MuscleIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = MuscleIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return { GetStartedQuestionAnswers: { ...prevState.GetStartedQuestionAnswers, MuscleIds: [ ...prevState.GetStartedQuestionAnswers.MuscleIds, ...[ MuscleId ] ] } }
            });
        } else {
            this.setState((prevState) => {
                return { GetStartedQuestionAnswers: { ...prevState.GetStartedQuestionAnswers, MuscleIds: [ ...prevState.GetStartedQuestionAnswers.MuscleIds.slice(0, MuscleIndex), ...prevState.GetStartedQuestionAnswers.MuscleIds.slice(MuscleIndex + 1) ] }}
            });
        }
    }

    onSelectNumberOfMeals = NumberOfMeals => {
        this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, NumberOfMeals } });
    }

    onToggleChooseGymModal = ShowChooseGymModal => {
        var { DefaultGymId } = this.state.GetStartedQuestionAnswers;

        var HasGym = true;
        if (!ShowChooseGymModal && ((DefaultGymId === null || DefaultGymId === 0))) HasGym = false;

        this.setState({ GetStartedQuestionAnswers: { ...this.state.GetStartedQuestionAnswers, HasGym }, ShowChooseGymModal });
    }

    renderActivityLevel = () => {
        var { t } = this.props;
        var { ActivityLevels, RetrievedActivityLevels, TryingGetActivityLevels } = this.props;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ptadmin_client_questionnaire_questiontitle_activitylevel')} TextAlign="center" />
                <Spacer Size="extra-small" />
                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_client_questionnaire_questiondescription_activitylevel')} TextAlign="center" />
                <Spacer Size="medium" />
                {
                    !RetrievedActivityLevels || TryingGetActivityLevels ?
                    <Loading />
                :
                    <ActivityLevelsContainer>
                        {
                            ActivityLevels.ActivityLevels.map(({ ActivityLevel, ActivityLevelDescription, ActivityLevelName }) => {
                                var SelectedActivityLevel = this.state.GetStartedQuestionAnswers.ActivityLevel === ActivityLevel ? 1 : 0;

                                return (
                                    <ActivityLevelContainer key={ActivityLevel} onClick={() => this.onSelectActivityLevel(ActivityLevel)} Selected={SelectedActivityLevel}>
                                        <PageText FontFamily="bold" FontSize="large" NoMargin Text={ActivityLevel} />
                                        <ActivityLevelDetailsContainer>
                                            <PageText FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={ActivityLevelName} />
                                            <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={ActivityLevelDescription} />
                                        </ActivityLevelDetailsContainer>
                                    </ActivityLevelContainer>
                                );
                            })
                        }
                    </ActivityLevelsContainer>
                }
            </>
        );
    }

    renderChooseGymModal = () => {
        var { GetStartedQuestionAnswers: { DefaultGymId }, ShowChooseGymModal } = this.state;

        if (ShowChooseGymModal) {
            return <ChooseGym ClientId={this.props.ClientId} DefaultGymId={DefaultGymId} OnHide={() => this.onToggleChooseGymModal(false)} OnSelectGym={DefaultGym => this.onSelectGym(DefaultGym)} Show={ShowChooseGymModal} />;
        }
    }

    renderDailyCalories = () => {
        var { t } = this.props;
        var { Device, Goals, RetrievedGoals, TryingGetGoals } = this.props;
        var { GetStartedQuestionAnswers: { DailyCalorieType, GoalId: StateGoalId, IsPreRegistered } } = this.state;

        var BackgroundColorHover = 'blue-astronaut';
        var BackgroundColorSelected = 'blue-astronaut';
        var ColorHover = 'white';
        var ColorSelected = 'white';

        var ButtonStart = { BackgroundColorHover, BackgroundColorSelected, ColorHover, ColorSelected };

        var GoalButtons = [];
        if (Goals.length) GoalButtons = Goals.map(({ GoalId, GoalName }) => ({ ...ButtonStart, Disabled: DailyCalorieType !== 'abel', Icon: +GoalId === 1 ? LoseWeight : +GoalId === 3 ? GainMuscleMass : GetHealthy, IconPosition: 'top', IconSize: { Height: '70px', Width: '70px' }, OnClick: () => this.onSelectGoalId(+GoalId), Selected: +StateGoalId === +GoalId, Subtitle: t(+GoalId === 1 ? 'ClientGoal_Subtitle_LoseWeight' : +GoalId === 3 ? 'ClientGoal_Subtitle_GainMuscleMass' : 'ClientGoal_Subtitle_GetHealthy'), Title: GoalName }));

        var Buttons = [];
        if (!IsPreRegistered) {
            Buttons.push({ ...ButtonStart, OnClick: () => this.onSelectDailyCalorieType('custom'), Selected: DailyCalorieType === 'custom', Title: t('ptadmin_client_questionnaire_dailycalorie_option1') });
            Buttons.push({ ...ButtonStart, OnClick: () => this.onSelectDailyCalorieType('abel'), Selected: DailyCalorieType === 'abel', Title: t('ptadmin_client_questionnaire_dailycalorie_option2') });
        }
        
        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ptadmin_client_questionnaire_questiontitle_dailycalories')} TextAlign="center" />
                
                <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />

                {
                    !IsPreRegistered && (!RetrievedGoals || TryingGetGoals) ?
                    <Loading />
                :
                    Device === 'mobile_small' || Device === 'mobile' ?
                    <>
                        <ButtonGroup
                            Buttons={[
                                { ...ButtonStart, OnClick: () => this.onSelectDailyCalorieType('custom'), Selected: DailyCalorieType === 'custom', Title: t('ptadmin_client_questionnaire_dailycalorie_option1') },
                            ]}
                            NormalWhiteSpace
                            NotTouching
                            OwnRows
                        />
                        
                        <Spacer Size="small" />

                        <DailyCalorieContainer Opacity={DailyCalorieType === 'custom' ? 1 : 0.5}>
                            <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_client_questionnaire_dailycalorie_label')} TextAlign="center" />
                            
                            <Spacer Size="small" />
                            
                            {this.renderUnitInput('CustomCalories', DailyCalorieType !== 'custom')}
                        </DailyCalorieContainer>

                        {
                            !IsPreRegistered &&
                            <>
                                <Spacer Size="large" />

                                <ButtonGroup
                                    Buttons={[
                                        { ...ButtonStart, OnClick: () => this.onSelectDailyCalorieType('abel'), Selected: DailyCalorieType === 'abel', Title: t('ptadmin_client_questionnaire_dailycalorie_option2') }
                                    ]}
                                    NormalWhiteSpace
                                    NotTouching
                                    OwnRows
                                />

                                <Spacer Size="small" />

                                <DailyCalorieContainer Opacity={DailyCalorieType === 'abel' ? 1 : 0.5}>
                                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_client_questionnaire_choosegoal_label')} TextAlign="center" />
                                    
                                    <Spacer Size="small" />

                                    <ButtonGroup Buttons={GoalButtons} NormalWhiteSpace NotTouching OwnRows />
                                </DailyCalorieContainer>
                            </>
                        }
                    </>
                :
                    <>
                        <ButtonGroup Buttons={Buttons} NormalWhiteSpace NotTouching />

                        <Spacer Size="small" />

                        <Container>
                            <DailyCalorieContainer Opacity={DailyCalorieType === 'custom' ? 1 : 0.5}>
                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_client_questionnaire_dailycalorie_label')} TextAlign="center" />
                                
                                <Spacer Size="small" />
                                
                                {this.renderUnitInput('CustomCalories', DailyCalorieType !== 'custom')}
                            </DailyCalorieContainer>

                            {
                                !IsPreRegistered &&
                                <DailyCalorieContainer Opacity={DailyCalorieType === 'abel' ? 1 : 0.5}>
                                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_client_questionnaire_choosegoal_label')} TextAlign="center" />
                                    
                                    <Spacer Size="small" />

                                    <ButtonGroup Buttons={GoalButtons} NormalWhiteSpace NotTouching OwnRows />
                                </DailyCalorieContainer>
                            }
                        </Container>
                    </>
                }
            </>
        );
    }

    renderDietaryRestrictions = () => {
        var { t } = this.props;
        var { Allergies, Device, DietTypes, RetrievedDietaryRestrictions, TryingGetClientMealSettings } = this.props;
        var { AllergyIds, DietTypeId, HasDietaryRestrictions } = this.state.GetStartedQuestionAnswers;

        var BackgroundColorHover = 'blue-astronaut';
        var BackgroundColorSelected = 'blue-astronaut';
        var ColorHover = 'white';
        var ColorSelected = 'white';

        var ButtonStart = { BackgroundColorHover, BackgroundColorSelected, ColorHover, ColorSelected };

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ptadmin_client_questionnaire_questiontitle_dietaryrestrictions')} TextAlign="center" />
                <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />
                {
                    !HasDietaryRestrictions ?
                    <ButtonGroup
                        Buttons={[
                            { ...ButtonStart, OnClick: () => this.onSelectHasDietaryRestrictions(true), Selected: HasDietaryRestrictions, Title: t('yes_with_first_char_uppercase') },
                            { ...ButtonStart, OnClick: () => this.onSelectHasDietaryRestrictions(false), Selected: HasDietaryRestrictions === false, Title: t('no_with_first_char_uppercase') }
                        ]}
                        ButtonWidth="fit-content"
                        NotTouching
                    />
                :
                    !RetrievedDietaryRestrictions || TryingGetClientMealSettings ?
                    <Loading />
                :
                    <DropdownContainer>
                        <Dropdown
                            ItemId="AllergyId"
                            ItemName="AllergyName"
                            Items={Allergies}
                            OnSelectItem={(event, AllergyId, AllergyIndex) => this.onSelectAllergy(event, AllergyId, AllergyIndex)}
                            SelectedItemIds={AllergyIds}
                            Title={t('WorkoutPlanSettings_Allergies')}
                        />
                        <Dropdown
                            ItemId="DietTypeId"
                            ItemName="DietTypeName"
                            Items={DietTypes}
                            OnSelectItem={(event, DietTypeId, DietTypeIndex) => this.onSelectDietTypeId(DietTypeId)}
                            SelectedItemIds={[ DietTypeId ]}
                            Title={t('WorkoutPlanSettings_Dietary_restrictions')}
                        />
                    </DropdownContainer>
                }
            </>
        );
    }

    renderDefaultGym = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { DefaultGym: { GymCityAddress, GymImage, GymName, GymStreetAddress }, DefaultGymId, DefaultGymImageLoaded, HasGym } = this.state.GetStartedQuestionAnswers;

        var BackgroundColorHover = 'blue-astronaut';
        var BackgroundColorSelected = 'blue-astronaut';
        var ColorHover = 'white';
        var ColorSelected = 'white';

        var ButtonStart = { BackgroundColorHover, BackgroundColorSelected, ColorHover, ColorSelected };

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ptadmin_client_questionnaire_questiontitle_defaultgym')} TextAlign="center" />
                
                <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />

                <ButtonGroup
                    Buttons={[
                        { ...ButtonStart, OnClick: () => this.onSelectHasGym(true), Selected: HasGym, Title: t('ptadmin_client_questionnaire_choosegym_choosegym') },
                        { ...ButtonStart, OnClick: () => this.onSelectHasGym(false, 1), Selected: HasGym === false && DefaultGymId === 1, Title: t('ptadmin_client_questionnaire_choosegym_noequipment') },
                        { ...ButtonStart, OnClick: () => this.onSelectHasGym(false, 2), Selected: HasGym === false && DefaultGymId === 2, Title: t('ptadmin_client_questionnaire_choosegym_allequipment') }
                    ]}
                    ButtonWidth="fit-content"
                    NotTouching
                />

                {
                    (!!DefaultGymId && +DefaultGymId !== 1 && +DefaultGymId !== 2) ?
                    <>
                        <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />
                        <DefaultGymContainer>
                            <GymLogo ImageSrc={GymImage} Loading={DefaultGymImageLoaded} OnLoaded={this.onLoadedDefaultGymImage} />
                            
                            <Spacer Size="extra-small" />

                            <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={GymName} TextAlign="center" />
                            <PageText FontFamily="medium" FontSize="small" NoMargin Text={GymStreetAddress} TextAlign="center" />
                            <PageText FontFamily="medium" FontSize="small" NoMargin Text={GymCityAddress} TextAlign="center" />
                        </DefaultGymContainer>
                    </>
                :
                    null
                }

                <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />

                <AddGymNoteContainer>
                    <GymIcon alt="gym" src={Gym} />
                    <PageText FontFamily="medium-italic" FontSize="small" NoMargin Text={t('addgymnote')} TextAlign={Device === 'mobile_small' || Device === 'mobile' ? 'center' : 'left'} />
                </AddGymNoteContainer>
            </>
        );
    }

    renderExperienceLevels = ExperienceLevelType => {
        var { t } = this.props;
        var { ExperienceLevels, RetrievedExperienceLevels, TryingGetExperienceLevels } = this.props;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t(`ptadmin_client_questionnaire_questiontitle_experiencelevel_${ExperienceLevelType.toLowerCase()}`)} TextAlign="center" />
                <Spacer Size="medium" />
                {
                    !RetrievedExperienceLevels || TryingGetExperienceLevels ?
                    <Loading />
                :
                    <ExperienceLevelsContainer>
                        {
                            ExperienceLevels[ExperienceLevelType].map(({ ExperienceLevel, ExperienceLevelDescription }) => {
                                var SelectedExperienceLevel = this.state.GetStartedQuestionAnswers[`ExperienceLevel_${ExperienceLevelType}`] === ExperienceLevel ? 1 : 0;

                                return (
                                    <ExperienceLevelContainer key={ExperienceLevel} onClick={() => this.onSelectExperienceLevel(ExperienceLevelType, ExperienceLevel)} Selected={SelectedExperienceLevel}>
                                        <PageText FontFamily="bold" FontSize="large" NoMargin Text={ExperienceLevel} />

                                        <Spacer Size="extra-small" />

                                        <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={ExperienceLevelDescription} />
                                    </ExperienceLevelContainer>
                                );
                            })
                        }
                    </ExperienceLevelsContainer>
                }
            </>
        );
    }

    renderHeight = () => {
        var { t } = this.props;
        var { Device } = this.props;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ptadmin_client_questionnaire_questiontitle_height')} TextAlign="center" />
                
                <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />
                
                {this.renderUnitSelections('HeightUnit', 'FT', 'CM')}
                
                <Spacer Size="small" />
                
                {this.renderUnitInput('Height')}
            </>
        );
    }

    renderInjuries = () => {
        var { t } = this.props;
        var { Device, Joints, Muscles, RetrievedInjuries, TryingGetInjuries } = this.props;
        var { JointIds, MuscleIds } = this.state.GetStartedQuestionAnswers;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('WorkoutPlan_injuries')} TextAlign="center" />
                <Spacer Size="extra-small" />
                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_client_questionnaire_questiondescription_injuries')} TextAlign="center" />
                <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />

                {
                    !RetrievedInjuries || TryingGetInjuries ?
                    <Loading />
                :
                    <DropdownContainer>
                        <Dropdown
                            ItemId="JointId"
                            ItemName="JointName"
                            Items={Joints}
                            OnSelectItem={(event, JointId, JointIndex) => this.onSelectJoint(event, JointId, JointIndex)}
                            SelectedItemIds={JointIds}
                            Title={t('Joints')}
                        />
                        <Dropdown
                            ItemId="MuscleId"
                            ItemName="MuscleName"
                            Items={Muscles}
                            OnSelectItem={(event, MuscleId, MuscleIndex) => this.onSelectMuscle(event, MuscleId, MuscleIndex)}
                            SelectedItemIds={MuscleIds}
                            Title={t('Muscles')}
                        />
                    </DropdownContainer>
                }
            </>
        );
    }

    renderIntro = () => {
        var { t } = this.props;
        var { Type } = this.props;

        return (
            <IntroContainer>
                <IllustrationContainer>
                    <Illustration alt={`Get started with ${Type.toLowerCase()}`} src={ForgotPassword} />
                </IllustrationContainer>
                <InstructionsContainer>
                    <PageText FontFamily="medium" FontSize="large" Text={t('ptadmin_client_questionnaire_title', { Type: Type.toLowerCase() })} TextAlign="center" />
                    <PageText FontFamily="medium" FontSize="medium-1" Text={t('ptadmin_client_questionnaire_description')} TextAlign="center" />
                    <GetStartedButton
                        FontSize="medium-1"
                        OnClick={() => this.changeGetStartedQuestion(1, 1)}
                        Title={t('ptadmin_client_questionnaire_getstarted')}
                    />
                </InstructionsContainer>
            </IntroContainer>
        );
    }

    renderMealQuestions = () => {
        var { Device, NeedsHeight, NeedsWeight } = this.props;
        var { GetStartedQuestionId, GetStartedQuestionAnswers: { IsPreRegistered } } = this.state;

        return (
            <QuestionContainer>
                {
                    !IsPreRegistered &&
                    <>
                        {this.renderQuestionCount(GetStartedQuestionId)}
                
                        <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />
                    </>
                }

                {
                    GetStartedQuestionId === 1 ?
                    this.renderDailyCalories()
                :
                    GetStartedQuestionId === 2 && NeedsHeight ?
                    this.renderHeight()
                :
                    (
                        (GetStartedQuestionId === 2 && !NeedsHeight && NeedsWeight) ||
                        (GetStartedQuestionId === 3 && NeedsHeight && NeedsWeight)
                    ) ?
                    this.renderWeight()
                :
                    (
                        (GetStartedQuestionId === 2 && !NeedsHeight && !NeedsWeight) ||
                        (GetStartedQuestionId === 3 && ((!NeedsHeight && NeedsWeight) || (NeedsHeight && !NeedsWeight))) ||
                        (GetStartedQuestionId === 4 && NeedsHeight && NeedsWeight)
                    ) ?
                    this.renderActivityLevel()
                :
                    (
                        (GetStartedQuestionId === 3 && !NeedsHeight && !NeedsWeight) ||
                        (GetStartedQuestionId === 4 && ((!NeedsHeight && NeedsWeight) || (NeedsHeight && !NeedsWeight))) ||
                        (GetStartedQuestionId === 5 && NeedsHeight && NeedsWeight)
                    ) ?
                    this.renderNumberOfMeals()
                :
                    (
                        (GetStartedQuestionId === 4 && !NeedsHeight && !NeedsWeight) ||
                        (GetStartedQuestionId === 5 && ((!NeedsHeight && NeedsWeight) || (NeedsHeight && !NeedsWeight))) ||
                        (GetStartedQuestionId === 6 && NeedsHeight && NeedsWeight)
                    ) ?
                    this.renderDietaryRestrictions()
                :
                    null
                }

                {/* {
                    GetStartedQuestionId === 1 ?
                    this.renderActivityLevel()
                :
                    GetStartedQuestionId === 2 && NeedsHeight ?
                    this.renderHeight()
                :
                    (
                        (GetStartedQuestionId === 2 && !NeedsHeight && NeedsWeight) ||
                        (GetStartedQuestionId === 3 && NeedsHeight && NeedsWeight)
                    ) ?
                    this.renderWeight()
                :
                    (
                        (GetStartedQuestionId === 2 && !NeedsHeight && !NeedsWeight) ||
                        (GetStartedQuestionId === 3 && ((!NeedsHeight && NeedsWeight) || (NeedsHeight && !NeedsWeight))) ||
                        (GetStartedQuestionId === 4 && NeedsHeight && NeedsWeight)
                    ) ?
                    this.renderDailyCalories()
                :
                    (
                        (GetStartedQuestionId === 3 && !NeedsHeight && !NeedsWeight) ||
                        (GetStartedQuestionId === 4 && ((!NeedsHeight && NeedsWeight) || (NeedsHeight && !NeedsWeight))) ||
                        (GetStartedQuestionId === 5 && NeedsHeight && NeedsWeight)
                    ) ?
                    this.renderNumberOfMeals()
                :
                    (
                        (GetStartedQuestionId === 4 && !NeedsHeight && !NeedsWeight) ||
                        (GetStartedQuestionId === 5 && ((!NeedsHeight && NeedsWeight) || (NeedsHeight && !NeedsWeight))) ||
                        (GetStartedQuestionId === 6 && NeedsHeight && NeedsWeight)
                    ) ?
                    this.renderDietaryRestrictions()
                :
                    null
                } */}
            </QuestionContainer>
        );
    }

    renderMultiButton = () => {
        var { t } = this.props;
        var { GetStartedQuestionAnswers: { ActivityLevel, CustomCalories, DailyCalorieType, DefaultGymId, ExperienceLevel_Endurance, ExperienceLevel_Strength, GoalId, HasDietaryRestrictions, Height, Height_Feet, HeightUnit, NumberOfMeals, Weight }, GetStartedQuestionId } = this.state;
        var { NeedsHeight, NeedsWeight, Type } = this.props;

        var CanGoNext = 0;
        var LastScreen = 0;

        // MEALS
            if (Type.toLowerCase() === 'meals') {
                LastScreen = (GetStartedQuestionId === 1 && DailyCalorieType === 'custom') || (GetStartedQuestionId === 6 && NeedsHeight && NeedsWeight) || (GetStartedQuestionId === 5 && ((!NeedsHeight && NeedsWeight) || (NeedsHeight && !NeedsWeight))) || (GetStartedQuestionId === 4 && !NeedsHeight && !NeedsWeight) ? 1 : 0; 

                CanGoNext = 1;
                if (GetStartedQuestionId === 1 && (!DailyCalorieType || (DailyCalorieType === 'custom' && !CustomCalories) || (DailyCalorieType === 'abel' && !GoalId))) CanGoNext = 0;

                else if (GetStartedQuestionId === 2 && NeedsHeight && ((HeightUnit === 'CM' && !Number(Height)) || (HeightUnit === 'FT' && !Number(Height_Feet)))) CanGoNext = 0;
                else if (GetStartedQuestionId === 2 && !NeedsHeight && NeedsWeight && !Number(Weight)) CanGoNext = 0;
                else if (GetStartedQuestionId === 2 && !NeedsHeight && !NeedsWeight && !Number(ActivityLevel)) CanGoNext = 0;
                
                else if (GetStartedQuestionId === 3 && NeedsHeight && NeedsWeight && !Number(Weight)) CanGoNext = 0;
                else if (GetStartedQuestionId === 3 && ((!NeedsHeight && NeedsWeight) || (NeedsHeight && !NeedsWeight)) && !Number(ActivityLevel)) CanGoNext = 0;
                else if (GetStartedQuestionId === 3 && !NeedsHeight && !NeedsWeight && !Number(NumberOfMeals)) CanGoNext = 0;

                else if (GetStartedQuestionId === 4 && NeedsHeight && NeedsWeight && !Number(ActivityLevel)) CanGoNext = 0;
                else if (GetStartedQuestionId === 4 && ((!NeedsHeight && NeedsWeight) || (NeedsHeight && !NeedsWeight)) && !Number(NumberOfMeals)) CanGoNext = 0;
                else if (GetStartedQuestionId === 4 && !NeedsHeight && !NeedsWeight && HasDietaryRestrictions === null) CanGoNext = 0;

                else if (GetStartedQuestionId === 5 && NeedsHeight && NeedsWeight && !Number(NumberOfMeals)) CanGoNext = 0;
                else if (GetStartedQuestionId === 5 && ((!NeedsHeight && NeedsWeight) || (NeedsHeight && !NeedsWeight)) && HasDietaryRestrictions === null) CanGoNext = 0;

                else if (GetStartedQuestionId === 6 && HasDietaryRestrictions === null) CanGoNext = 0;


                // if (GetStartedQuestionId === 1 && !Number(ActivityLevel)) CanGoNext = 0;

                // else if (GetStartedQuestionId === 2 && NeedsHeight && ((HeightUnit === 'CM' && !Number(Height)) || (HeightUnit === 'FT' && !Number(Height_Feet)))) CanGoNext = 0;
                // else if (GetStartedQuestionId === 2 && !NeedsHeight && NeedsWeight && !Number(Weight)) CanGoNext = 0;
                // else if (GetStartedQuestionId === 2 && !NeedsHeight && !NeedsWeight && (!DailyCalorieType || (DailyCalorieType === 'custom' && !CustomCalories) || (DailyCalorieType === 'abel' && !GoalId))) CanGoNext = 0;
                
                // else if (GetStartedQuestionId === 3 && NeedsHeight && NeedsWeight && !Number(Weight)) CanGoNext = 0;
                // else if (GetStartedQuestionId === 3 && ((!NeedsHeight && NeedsWeight) || (NeedsHeight && !NeedsWeight)) && (!DailyCalorieType || (DailyCalorieType === 'custom' && !CustomCalories) || (DailyCalorieType === 'abel' && !GoalId))) CanGoNext = 0;
                // else if (GetStartedQuestionId === 3 && !NeedsHeight && !NeedsWeight && !Number(NumberOfMeals)) CanGoNext = 0;

                // else if (GetStartedQuestionId === 4 && NeedsHeight && NeedsWeight && (!DailyCalorieType || (DailyCalorieType === 'custom' && !CustomCalories) || (DailyCalorieType === 'abel' && !GoalId))) CanGoNext = 0;
                // else if (GetStartedQuestionId === 4 && ((!NeedsHeight && NeedsWeight) || (NeedsHeight && !NeedsWeight)) && !Number(NumberOfMeals)) CanGoNext = 0;
                // else if (GetStartedQuestionId === 4 && !NeedsHeight && !NeedsWeight && HasDietaryRestrictions === null) CanGoNext = 0;

                // else if (GetStartedQuestionId === 5 && NeedsHeight && NeedsWeight && !Number(NumberOfMeals)) CanGoNext = 0;
                // else if (GetStartedQuestionId === 5 && ((!NeedsHeight && NeedsWeight) || (NeedsHeight && !NeedsWeight)) && HasDietaryRestrictions === null) CanGoNext = 0;

                // else if (GetStartedQuestionId === 6 && HasDietaryRestrictions === null) CanGoNext = 0;
            }
        // WORKOUTS
            else if (Type.toLowerCase() === 'workouts') {
                LastScreen = (GetStartedQuestionId === 5 && NeedsWeight) || (GetStartedQuestionId === 4 && !NeedsWeight) ? 1 : 0; 

                CanGoNext = 1;
                if (GetStartedQuestionId === 1 && NeedsWeight && !Number(Weight)) CanGoNext = 0;
                else if (((GetStartedQuestionId === 2 && NeedsWeight) || (GetStartedQuestionId === 1 && !NeedsWeight)) && !Number(ExperienceLevel_Strength)) CanGoNext = 0;
                else if (((GetStartedQuestionId === 3 && NeedsWeight) || (GetStartedQuestionId === 2 && !NeedsWeight)) && !Number(ExperienceLevel_Endurance)) CanGoNext = 0;
                else if (((GetStartedQuestionId === 4 && NeedsWeight) || (GetStartedQuestionId === 3 && !NeedsWeight)) && DefaultGymId === null) CanGoNext = 0;
            }
        
        return (
            <BackNextButtons
                Buttons={[
                    { BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.changeGetStartedQuestion(-1), Title: t('back_with_first_char_uppercase') },
                    { BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled: !CanGoNext, FontFamily: 'semibold', OnClick: () => this.changeGetStartedQuestion(1, CanGoNext, LastScreen), Title: t(LastScreen ? 'finish_with_first_char_uppercase' : 'next_with_first_char_uppercase') }
                ]}
            />
        );
    }

    renderNumberOfMeals = () => {
        var { t } = this.props;
        var { NumberOfMeals } = this.state.GetStartedQuestionAnswers;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ptadmin_client_questionnaire_questiontitle_numberofmeals')} TextAlign="center" />
                <Spacer Size="extra-small" />
                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_client_questionnaire_questiondescription_numberofmeals')} TextAlign="center" />
                <Spacer Size="medium" />
                <NumberOfMealsContainer className="NumberOfMealsContainer">
                    {
                        [ 1, 2, 3, 4, 5, 6, 7, 8 ].map(MealNumber => {
                            var SelectedNumberOfMeals = NumberOfMeals === MealNumber ? 1 : 0;

                            return (
                                <NumberOfMeal className="NumberOfMeal" key={MealNumber} onClick={() => this.onSelectNumberOfMeals(MealNumber)} Selected={SelectedNumberOfMeals}>
                                    {MealNumber}
                                </NumberOfMeal>
                            );
                        })
                    }
                </NumberOfMealsContainer>
            </>
        );
    }

    renderQuestionCount = GetStartedQuestionId => {
        var { NeedsHeight, NeedsWeight, Type } = this.props;
        var { GetStartedQuestionAnswers: { DailyCalorieType } } = this.state;

        var GetStarted_TotalQuestions = [];

        // MEALS
            if (Type.toLowerCase() === 'meals') {
                if (DailyCalorieType === 'custom') GetStarted_TotalQuestions = [ 1 ];
                else if (NeedsHeight && NeedsWeight) GetStarted_TotalQuestions = [ 1, 2, 3, 4, 5, 6 ];
                else if ((!NeedsHeight && NeedsWeight) || (NeedsHeight && !NeedsWeight)) GetStarted_TotalQuestions = [ 1, 2, 3, 4, 5 ];
                else if (!NeedsHeight && !NeedsWeight) GetStarted_TotalQuestions = [ 1, 2, 3, 4 ];
            }
        // WORKOUTS
            else if (Type.toLowerCase() === 'workouts') {
                GetStarted_TotalQuestions = NeedsWeight ? [ 1, 2, 3, 4, 5 ] :  [ 1, 2, 3, 4 ];
            }

        return (
            <QuestionCountContainer>
                {
                    GetStarted_TotalQuestions.map(QuestionNumber => {
                        var Selected = GetStartedQuestionId === QuestionNumber;

                        return (
                            <QuestionCount key={QuestionNumber} Selected={Selected} />
                        );
                    })
                }
            </QuestionCountContainer>
        );
    }

    renderUnitInput = (StateName, ReadOnly) => {
        if (StateName === 'Height' && this.state.GetStartedQuestionAnswers.HeightUnit === 'FT') {
            var { Height_Feet, Height_Inches } = this.state.GetStartedQuestionAnswers;

            return (
                <UnitInputContainer>
                    <HeightInput
                        FontFamily="semibold"
                        FontSize="extra-large-2"
                        NoLabel
                        NoMargin
                        OnChange={e => this.onChangeUnitInput(e, 'Height_Feet')}
                        Placeholder="0"
                        Size="auto"
                        Type="number"
                        Value={`${Height_Feet}`}
                    />
                    <HeightFeetIcon ElementType="span" FontSize="extra-extra-large" Text="'" />
                    <HeightInput
                        FontFamily="semibold"
                        FontSize="extra-large-2"
                        NoLabel
                        NoMargin
                        OnChange={e => this.onChangeUnitInput(e, 'Height_Inches')}
                        Placeholder="0"
                        Size="auto"
                        Type="number"
                        Value={`${Height_Inches}`}
                    />
                    <HeightFeetIcon ElementType="span" FontSize="extra-extra-large" Text={`"`} />
                </UnitInputContainer>
            );
        }

        return (
            <UnitInputContainer>
                <UnitInput
                    FontFamily="semibold"
                    FontSize="extra-large-2"
                    NoLabel
                    NoMargin
                    OnChange={e => this.onChangeUnitInput(e, StateName)}
                    Placeholder="0"
                    ReadOnly={ReadOnly}
                    Size="auto"
                    Type="number"
                    Value={`${this.state.GetStartedQuestionAnswers[StateName]}`}
                />
            </UnitInputContainer>
        );
    }

    renderUnitSelections = (StateName, Unit1, Unit2) => {
        var SelectedUnit = this.state.GetStartedQuestionAnswers[StateName] === Unit1 ? 'Unit1' : 'Unit2';

        return (
            <MultiUnitContainer>
                <MultiUnit onClick={() => this.onChangeUnitSelection(StateName, Unit1)} Selected={SelectedUnit === 'Unit1'}>
                    <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={Unit1} />
                </MultiUnit>
                <MultiUnit onClick={() => this.onChangeUnitSelection(StateName, Unit2)} Selected={SelectedUnit === 'Unit2'}>
                    <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={Unit2} />
                </MultiUnit>
            </MultiUnitContainer>
        );
    }

    renderWeight = () => {
        var { t } = this.props;
        var { Device } = this.props;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ptadmin_client_questionnaire_questiontitle_weight')} TextAlign="center" />
                <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />
                {this.renderUnitSelections('WeightUnit', 'LBS', 'KG')}
                <Spacer Size="small" />
                {this.renderUnitInput('Weight')}
            </>
        );
    }

    renderWorkoutQuestions = () => {
        var { Device, NeedsWeight } = this.props;
        var { GetStartedQuestionId } = this.state;

        return (
            <QuestionContainer>
                {this.renderQuestionCount(GetStartedQuestionId)}
                
                <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />

                {
                    GetStartedQuestionId === 1 && NeedsWeight?
                    this.renderWeight()
                :
                    (
                        (GetStartedQuestionId === 1 && !NeedsWeight) ||
                        (GetStartedQuestionId === 2 && NeedsWeight)
                    ) ?
                    this.renderExperienceLevels('Strength')
                :
                    (
                        (GetStartedQuestionId === 2 && !NeedsWeight) ||
                        (GetStartedQuestionId === 3 && NeedsWeight)
                    ) ?
                    this.renderExperienceLevels('Endurance')
                :
                    (
                        (GetStartedQuestionId === 3 && !NeedsWeight) ||
                        (GetStartedQuestionId === 4 && NeedsWeight)
                    ) ?
                    this.renderDefaultGym()
                :
                    (
                        (GetStartedQuestionId === 4 && !NeedsWeight) ||
                        (GetStartedQuestionId === 5 && NeedsWeight)
                    ) ?
                    this.renderInjuries()
                :
                    null
                }
            </QuestionContainer>
        );
    }

    render() {
        var { Type } = this.props;
        var { TryingAddGetStartedMeals, TryingAddGetStartedWorkouts } = this.props;
        var { GetStartedQuestionId } = this.state;

        return (
            <>
                {TryingAddGetStartedMeals || TryingAddGetStartedWorkouts ? <Loading /> : null}

                {
                    GetStartedQuestionId === 0 ?
                    this.renderIntro()
                :
                    Type.toLowerCase() === 'meals' ?
                    this.renderMealQuestions()
                :
                    this.renderWorkoutQuestions()
                }
                {
                    GetStartedQuestionId !== 0 ?
                    this.renderMultiButton()
                :
                    null
                }

                {this.renderChooseGymModal()}
            </>
        );
    }
}

GetStarted.propTypes = {
    ClientId: PropTypes.number.isRequired,
    ClientType: PropTypes.string,
    FromDashboard: PropTypes.bool,
    NeedsHeight: PropTypes.bool,
    NeedsWeight: PropTypes.bool,
    ProvidedUserDetails: PropTypes.object,
    RefreshWeekly: PropTypes.func.isRequired,
    Type: PropTypes.string.isRequired
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        UserDetails: state.Auth.UserDetails,

        ActivityLevels: state.Client.ActivityLevels,
        Allergies: state.Client.ClientMealSettings.Allergies,
        ClientDetails: state.Client.ClientDetails,
        DietTypes: state.Client.ClientMealSettings.DietTypes,
        ExperienceLevels: state.Client.ExperienceLevels,
        Goals: state.Client.Goals,
        Joints: state.Client.Joints,
        Muscles: state.Client.Muscles,
        RetrievedActivityLevels: state.Client.RetrievedActivityLevels,
        RetrievedDietaryRestrictions: state.Client.RetrievedDietaryRestrictions,
        RetrievedExperienceLevels: state.Client.RetrievedExperienceLevels,
        RetrievedGoals: state.Client.RetrievedGoals,
        RetrievedInjuries: state.Client.RetrievedInjuries,

        TryingAddGetStartedMeals: state.Client.TryingAddGetStartedMeals,
        TryingAddGetStartedMealsError: state.Client.TryingAddGetStartedMealsError,
        TryingAddGetStartedWorkouts: state.Client.TryingAddGetStartedWorkouts,
        TryingAddGetStartedWorkoutsError: state.Client.TryingAddGetStartedWorkoutsError,
        TryingGetActivityLevels: state.Client.TryingGetActivityLevels,
        TryingGetActivityLevelsError: state.Client.TryingGetActivityLevelsError,
        TryingGetClientMealSettings: state.Client.TryingGetClientMealSettings,
        TryingGetClientMealSettingsError: state.Client.TryingGetClientMealSettingsError,
        TryingGetExperienceLevels: state.Client.TryingGetExperienceLevels,
        TryingGetExperienceLevelsError: state.Client.TryingGetExperienceLevelsError,
        TryingGetGoals: state.Client.TryingGetGoals,
        TryingGetGoalsError: state.Client.TryingGetGoalsError,
        TryingGetInjuries: state.Client.TryingGetInjuries,
        TryingGetInjuriesError: state.Client.TryingGetInjuriesError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddGetStartedMeals, AddGetStartedWorkouts, ClearGetStarted, GetClientMealSettings, ViewActivityLevels, ViewExperienceLevels, ViewGoals, ViewInjuries } )(GetStarted));