import React, { useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import useHasExpired from './useHasExpired';
import styled from 'styled-components';

import { BETA_SITE_URL } from "../../Config";

import PageText from '../Text/PageText';
import Spacer from '../Spacer';

import InfoModal from '../../Modals/ExpiredLogout';

const renderLogoutInfo = t => {
  return (
    <>
      <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('sessionExpired')} TextAlign="center" />

      <Spacer Size="small" />

      <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('relogin_again')} TextAlign="center" />
    </>
  );
}

const ContainerApp = styled.div`
  pointer-events: ${(props) => (props.hasExpired ? 'none' : "unset")};
`;

const AppContainer = ({ children, t }) => {
    const hasExpired = useHasExpired();

    const [isExpiredPopupVisible, setIsExpiredPopupVisible] = useState(false);

    const onHide = () => {
      return null;
    }

    const RedirectToLoginPage = () => {
      localStorage.clear();
      sessionStorage.clear();

      setTimeout(() => window.location.href = `${BETA_SITE_URL}/logout`, 100);
    }

  useEffect(() => {
    setIsExpiredPopupVisible(hasExpired);
  }, [hasExpired]);

  return (
    <>
      <InfoModal
        BottomButton="submit"
        BottomButtonOnClick={RedirectToLoginPage}
        BottomButtonText={t('relogin')}
        InformationRenderer={() => renderLogoutInfo(t)}
        OnHide={onHide}
        Show={isExpiredPopupVisible}
      />

      <ContainerApp className="ContainerApp" hasExpired={hasExpired}>
        {children}
      </ContainerApp>
    </>
  )
}

export default withTranslation()(AppContainer);