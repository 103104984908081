import styled from 'styled-components';

export const ButtonContainer = styled.div`
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none;
    width: 45px;

    :hover {
        background-color: ${props => props.Disabled ? null : 'var(--white-concrete)'};
        cursor: ${props => props.Disabled ? 'not-allowed' : 'pointer'};
    }

    :first-child {
        border-right: 2px solid var(--white-concrete);
    }

    :last-child {
        border-left: 2px solid var(--white-concrete);
    }
`;

export const Container = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    height: 45px;
`;