import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CopyGroupWorkouts, DeleteGroupWorkouts, MoveGroupWorkouts, SaveDataInProps } from '../../Services/Actions';
import { ClearGroupDetails, GetGroupDetails } from '../../Services/Actions';
import history from '../../history';
import moment from 'moment';

import { BottomBarHeightAdjustment, BottomBarItems, GroupMembersContainer, GroupOwnerContainer, GroupOwnerSubContainer, MultiOptionContainer, MultiOptionsContainer, SelectedItems, StyledBottomBar, StyledPaginatedList, StyledProfilePhoto } from './styles';

import Accordion from '../../Components/Accordion';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import { GA_Event } from '../../Components/GoogleAnalytics';
import { GroupMemberListItem } from '../../Components/Lists/ListItems';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import Toggle from '../../Components/Form/Toggle';

import GroupWorkouts from '../GroupWorkouts';

import AddEditGroupManagers from '../../Modals/AddEditGroupManagers';
import AddEditGroupMembers from '../../Modals/AddEditGroupMembers';
import ChooseDates from '../../Modals/ChooseDates';
import ClientAndDates from '../../Modals/ClientAndDates';
import Info from '../../Modals/Info';

import Plus from '../../Assets/Icons/Plus.png';

import { DateSort } from '../../Functions';

class GroupDetail extends React.Component {
    _isMounted = false;

    state = {
        BottomBarHeight: 0,
        ChooseDatesReason: null,
        GroupId: null,
        GroupName: '',
        GroupManagersPageNo: 1,
        GroupManagersPageSize: 30,
        GroupMembersPageNo: 1,
        GroupMembersPageSize: 30,
        GroupType: null,
        Loading: true,
        MonthlyView: false,
        MultiSelect_Workouts: [],
        NeedsRefresh_Workouts: false,
        SelectedDates: [],
        SelectedTab: 'Workouts',
        ShowChooseDatesModal: false,
        ShowCopyWorkoutsModal: false,
        ShowDeleteWorkoutsModal: false,
        ShowEditGroupManagersModal: false,
        ShowEditGroupMembersModal: false,
        ShowMultiSelect_Workouts: false
    };

    componentDidMount() {
        this._isMounted = true;

        var { GroupId } = this.props.match.params;

        if (!Number(GroupId)) history.push('/');
        else {
            this.props.GetGroupDetails(GroupId).then(() => {                
                if (this._isMounted) {
                    var { GroupName, GroupType } = this.props.GroupDetails;

                    this.setState({ GroupId: +GroupId, GroupName, GroupType, Loading: false, SelectedTab: this.props.location.state && this.props.location.state.SelectedTab ? this.props.location.state.SelectedTab : this.state.SelectedTab }, () => window.history.replaceState({}, ''));
                }
            });
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        
        this.props.ClearGroupDetails();
    }

    onCancelMultiSelect = () => {
        this.setState({ MultiSelect_Workouts: [], SelectedDates: [] });
    }

    onChangeToggle = event => {
        var Checked = event.target.checked;

        this.setState({ MonthlyView: !Checked }, () => this.onUpdateDate({ ChangedTo: Checked ? 'Weekly' : 'Monthly', Direction: 0 }));
    }

    onClickMember = UserId => {
        history.push(`/client/${UserId}`);
    }

    onCopyGroupWorkouts = (SelectedDates, SelectedGroups) => {
        this.onToggleShowCopyWorkoutsModal(false);

        this.setState({ MultiSelect_Workouts: [], ShowMultiSelect_Workouts: false });

        this.props.CopyGroupWorkouts({ AssignedDates: SelectedDates, Groups: SelectedGroups, GroupWorkoutIds: this.state.MultiSelect_Workouts }).then(() => {
            this.setState({ NeedsRefresh_Workouts: true }, () => this.setState({ NeedsRefresh_Workouts: false }));
        });
    }

    onDeleteWorkouts = () => {
        this.onToggleShowDeleteWorkoutsModal(false);
        this.onCancelMultiSelect();

        this.setState({ ShowMultiSelect_Workouts: false });
        
        this.props.DeleteGroupWorkouts(this.state.MultiSelect_Workouts);
    }

    onGetSizeBottomBar = Height => {
        this.setState({ BottomBarHeight: Height });
    }

    onMoveGroupWorkouts = AssignedDate => {
        var { WeeklyView_StartDate, WeeklyView_EndDate } = this.props;

        // If AssignedDate is in current week, then delete workouts that are moved
        var IsBetween = moment(AssignedDate).isBetween(WeeklyView_StartDate, WeeklyView_EndDate, null, []);

        this.setState({ MultiSelect_Workouts: [], SelectedDates: [], ShowMultiSelect_Workouts: false });
        this.props.MoveGroupWorkouts({ AssignedDate, IsBetween, GroupWorkoutIds: this.state.MultiSelect_Workouts }).then(() => {
            this.setState({ NeedsRefresh_Workouts: !!IsBetween }, () => this.setState({ NeedsRefresh_Workouts: false }));
        });
    }

    onMultiSelect = EventId => {
        var { SelectedTab } = this.state;

        var MultiSelect__Index = this.state[`MultiSelect_${SelectedTab}`].indexOf(+EventId);

        if (MultiSelect__Index === -1) this.setState({ [`MultiSelect_${SelectedTab}`]: [ ...this.state[`MultiSelect_${SelectedTab}`], +EventId ] });
        else this.setState({ [`MultiSelect_${SelectedTab}`]: [ ...this.state[`MultiSelect_${SelectedTab}`].slice(0, MultiSelect__Index), ...this.state[`MultiSelect_${SelectedTab}`].slice(MultiSelect__Index + 1) ] });
    }

    onSelectDates = (SelectedDates, ChooseDatesReason) => {
        var { SelectedTab } = this.state;

        this.setState({ SelectedDates: SelectedDates.sort(DateSort), ShowChooseDatesModal: false }, () => {
            if (SelectedTab === 'Workouts' && ChooseDatesReason === 'move') this.onMoveGroupWorkouts(SelectedDates[0]);
        });
    }

    onToggleMultiSelect = event => {
        GA_Event({ Category: 'GroupDetail', Action: `Group Detail - Toggle Weekly View Multi Select - ${this.state.SelectedTab}`, Label: `Group Detail Weekly View - Toggled ${!!event.target.checked ? 'On' : 'Off'}` });

        this.setState({
            [`ShowMultiSelect_${this.state.SelectedTab}`]: event.target.checked,
            MultiSelect_Workouts: []
        });
    }

    onToggleShowChooseDatesModal = (ShowChooseDatesModal, Reason) => {
        // Reason is either "move" or "copy"
        this.setState({ ShowChooseDatesModal, ChooseDatesReason: Reason });
    }

    onToggleShowCopyWorkoutsModal = ShowCopyWorkoutsModal => {
        this.setState({ ShowCopyWorkoutsModal });
    }

    onToggleShowDeleteWorkoutsModal = ShowDeleteWorkoutsModal => {
        this.setState({ ShowDeleteWorkoutsModal });
    }

    onToggleShowEditGroupManagers = ShowEditGroupManagersModal => {
        this.setState({ ShowEditGroupManagersModal })
    }

    onToggleShowEditGroupMembers = ShowEditGroupMembersModal => {
        this.setState({ ShowEditGroupMembersModal })
    }

    renderAddEditGroupManagersModal = () => {
        var { GroupId, GroupType, ShowEditGroupManagersModal } = this.state;

        if (ShowEditGroupManagersModal) {
            return (
                <AddEditGroupManagers
                    GroupId={GroupId}
                    GroupType={GroupType}
                    OnHide={() => this.onToggleShowEditGroupManagers(false)}
                    Show={ShowEditGroupManagersModal}
                />
            )
        }
    }

    renderAddEditGroupMembersModal = () => {
        var { GroupId, GroupName, GroupType, ShowEditGroupMembersModal } = this.state;

        if (ShowEditGroupMembersModal) {
            return (
                <AddEditGroupMembers
                    GroupId={GroupId}
                    GroupName={GroupName}
                    GroupType={GroupType}
                    OnlyClients
                    OnHide={() => this.onToggleShowEditGroupMembers(false)}
                    Show={ShowEditGroupMembersModal}
                />
            )
        }
    }

    renderBottomBar = () => {
        var { MultiSelect_Workouts, SelectedTab } = this.state;

        var MultiSelectLength = 0;
        if (SelectedTab === 'Workouts') MultiSelectLength = MultiSelect_Workouts.length;

        if (!MultiSelectLength) return null;

        var { t } = this.props;
        var { Device } = this.props;

        var Buttons = [];

        if (SelectedTab === 'Workouts') {
            Buttons = [
                { BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Color: 'white', ColorHover: 'white', OnClick: () => this.onToggleShowCopyWorkoutsModal(true), Title: t('copy') },
                { BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Color: 'white', ColorHover: 'white', OnClick: () => this.onToggleShowChooseDatesModal(true, 'move'), Title: t('move') },
                { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Color: 'white', ColorHover: 'white', OnClick: () => this.onToggleShowDeleteWorkoutsModal(true), Title: t('delete_with_first_char_uppercase') },
                { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Border: '2px solid var(--blue-astronaut)', ColorHover: 'black', OnClick: this.onCancelMultiSelect, Title: t('Cancel_with_first_char_uppercase') }
            ];
        }

        return (
            <StyledBottomBar GetSize={Height => this.onGetSizeBottomBar(Height)}>
                <BottomBarItems>
                    <SelectedItems ElementType="span" FontColor="white" FontFamily="bold" FontSize="medium-3" Text={`${MultiSelectLength}`} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('WorkoutPlanSettings_selected').toLowerCase()} />
                </BottomBarItems>
                <BottomBarItems FullWidth={true}>
                    <ButtonGroup
                        Buttons={Buttons}
                        ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                        NotTouching
                        OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                    />
                </BottomBarItems>
            </StyledBottomBar>
        );
    }

    renderBottomBarHeightAdjustment = () => {
        var { MultiSelect_Workouts, SelectedTab } = this.state;

        var MultiSelectLength = 0;
        if (SelectedTab === 'Workouts') MultiSelectLength = MultiSelect_Workouts.length;

        if (!MultiSelectLength) return null;

        return <BottomBarHeightAdjustment className="BottomBarHeightAdjustment" Height={this.state.BottomBarHeight} />
    }

    renderCopyWorkoutsModal = () => {
        var { ShowCopyWorkoutsModal } = this.state;

        if (ShowCopyWorkoutsModal) {
            var { t } = this.props;
            
            return (
                <ClientAndDates
                    EligibleTypes="Teams"
                    GroupDetails={this.props.GroupDetails}
                    OnHide={() => this.onToggleShowCopyWorkoutsModal(false)}
                    OnSelect={(ClientIds, SelectedDates, SelectedGroups) => this.onCopyGroupWorkouts(SelectedDates, SelectedGroups)}
                    Show={ShowCopyWorkoutsModal}
                    Title={t('choose_clients_dates_to_copy_to')}
                />
            )
        }
    }

    renderDeleteWorkoutsModal = () => {
        var { t } = this.props;
        var { TryingDeleteWorkouts } = this.props;
        var { ShowDeleteWorkoutsModal } = this.state;

        if (ShowDeleteWorkoutsModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteWorkouts}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    Information={t('delete_with_warning', { Items: t('ptadmin_workouts_plural').toLowerCase()})}
                    Loading={TryingDeleteWorkouts}
                    OnHide={() => this.onToggleShowDeleteWorkoutsModal(false)}
                    Show={ShowDeleteWorkoutsModal}
                />
            );
        }
    }

    renderGroupMembers = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { GroupManagers, GroupMembers, GroupOwner: { Gender, UserName, UserPhoto}, NumManagers, NumMembers } = this.props.GroupDetails;
        var { GroupManagersPageNo, GroupManagersPageSize, GroupMembersPageNo, GroupMembersPageSize } = this.state;

        return (
            <Accordion Text={t('group_members')}>
                <GroupMembersContainer>
                    {/* GROUP OWNER */}
                        <GroupOwnerContainer className="GroupOwnerContainer">
                            <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('group_owner')} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <GroupOwnerSubContainer>
                                <StyledProfilePhoto Sex={Gender} Size="large" ShowOverlay={false} Source={UserPhoto} />

                                <Spacer Size="extra-small" />

                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={`@${UserName}`} TextAlign="center" />
                            </GroupOwnerSubContainer>
                        </GroupOwnerContainer>

                    <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />

                    {/* GROUP MANAGERS */}
                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={`${t('group_managers')} (${NumManagers})`} TextAlign="center" />
                        

                        <Spacer Size="extra-extra-small" />
                        
                        <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('group_manager_description')} TextAlign="center" />

                        <Spacer Size="small" />

                        <StyledPaginatedList
                            CompletedFirstSearch={true}
                            HideNoResults
                            HideViewCount
                            IsLoading={false}
                            ItemDescriptionType={t('ptadmin_navigation_clients').toLowerCase()}
                            ItemId="UserId"
                            ItemName="UserName"
                            ItemRenderer={GroupMemberListItem}
                            Items={GroupManagers.GroupManagers}
                            HasMoreItems={!!+GroupManagers.IsMoreResults}
                            LoadMore={() => this.onLoadExercises(true)}
                            NewSearch={false}
                            NoItemsText={t('search_noresults')}
                            PageNo={GroupManagersPageNo}
                            PageSize={GroupManagersPageSize}
                            TotalRecords={GroupManagers.TotalRecords}
                        />

                        <ButtonGroup
                            Buttons={[{ FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onToggleShowEditGroupManagers(true), Title: !!+GroupManagers.TotalRecords ? t('edit_with_first_char_uppercase') : t('ptadmin_clientnote_options_add') }]}
                            ButtonWidth="fit-content"
                        />

                    <Spacer Size="medium" />

                    {/* GROUP MEMBERS */}
                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={`${t('group_members')} (${NumMembers})`} TextAlign="center" />
                        
                        {
                            !!+NumMembers &&
                            <>
                                <Spacer Size="extra-extra-small" />
                        
                                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('click_member_to_go_to_profile')} TextAlign="center" />
                            </>
                        }

                        <Spacer Size="small" />

                        <StyledPaginatedList
                            CompletedFirstSearch={true}
                            HideNoResults
                            HideViewCount
                            IsLoading={false}
                            ItemDescriptionType={t('ptadmin_navigation_clients').toLowerCase()}
                            ItemId="UserId"
                            ItemName="UserName"
                            ItemProps={{
                                OnSelectMember: User => this.onClickMember(User.UserId)
                            }}
                            ItemRenderer={GroupMemberListItem}
                            Items={GroupMembers.GroupMembers}
                            HasMoreItems={!!+GroupMembers.IsMoreResults}
                            LoadMore={() => this.onLoadExercises(true)}
                            NewSearch={false}
                            NoItemsText={t('search_noresults')}
                            PageNo={GroupMembersPageNo}
                            PageSize={GroupMembersPageSize}
                            TotalRecords={GroupMembers.TotalRecords}
                        />

                        <ButtonGroup
                            Buttons={[{ FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onToggleShowEditGroupMembers(true), Title: !!+GroupMembers.TotalRecords ? t('edit_with_first_char_uppercase') : t('add') }]}
                            ButtonWidth="fit-content"
                        />
                </GroupMembersContainer>
            </Accordion>           
        );
    }

    renderMultiOptions = () => {
        var { t } = this.props;
        var { SelectedTab } = this.state;

        var ShowMultiSelect = this.state[`ShowMultiSelect_${SelectedTab}`];

        return (
            <MultiOptionsContainer className="MultiOptionsContainer">
                {/* <MultiOptionContainer>
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('show_week_or_month')} TextAlign="center"  />
                    <Toggle
                        Checked={!MonthlyView}
                        OffLabel={t('monthly')}
                        OnChange={this.onChangeToggle}
                        OnLabel={t('weekly')}
                    />
                </MultiOptionContainer> */}
                <MultiOptionContainer className="MultiOptionContainer">
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('multi_select_and_delete')} TextAlign="center"  />
                    <Toggle
                        Checked={!!+ShowMultiSelect}
                        OffLabel={t('off')}
                        OnChange={this.onToggleMultiSelect}
                        OnLabel={t('on')}
                    />
                </MultiOptionContainer>
            </MultiOptionsContainer>
        );
    }

    renderShowChooseDatesModal = () => {
        var { ShowChooseDatesModal, ChooseDatesReason } = this.state;

        if (ShowChooseDatesModal) {
            return (
                <ChooseDates
                    MaxDates={ChooseDatesReason === 'move' ? 1 : null}
                    OnHide={() => this.onToggleShowChooseDatesModal(false)}
                    OnSelectDates={SelectedDates => this.onSelectDates(SelectedDates, ChooseDatesReason)}
                    SelectedDates={this.state.SelectedDates}
                    Show={ShowChooseDatesModal}
                />
            );
        }
    }

    render() {
        if (this.state.Loading || this.props.TryingGetGroupDetails) return <Loading />;

        var { t } = this.props;
        var { GroupDetails, Device } = this.props;
        var { GroupId, NeedsRefresh_Workouts } = this.state;

        if (Object.entries(GroupDetails).length === 0 || GroupDetails.constructor !== Object) return null;

        return (
            <>
                <Page renderBreadcrumbs={[{ Name: t('ptadmin_navigation_clients'), LinkURL: '' }, { Name: `${GroupDetails.GroupName}` }]}>
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text={`${GroupDetails.GroupName}`} TextAlign="center" />

                    <Spacer Size="extra-small" />

                    <MultiText
                        TextAlign="center"
                        Texts={[
                            { FontFamily: 'bold', FontSize: 'medium-2', Text: `${GroupDetails.NumMembers}` },
                            { FontFamily: 'medium', FontSize: 'medium-2', Text: GroupDetails.NumMembers === 1 ? t('ptadmin_clients_singular') : t('ptadmin_navigation_clients') }
                        ]}
                    />

                    <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />

                    {this.renderGroupMembers()}

                    <Spacer Size="extra-small" />

                    {this.renderMultiOptions()}

                    <Spacer Size="large" />

                    {
                        this.state.SelectedTab === 'Workouts' &&
                        <GroupWorkouts
                            GroupId={GroupId}
                            MultiSelect={this.state.ShowMultiSelect_Workouts}
                            NeedsRefresh={NeedsRefresh_Workouts}
                            OnMultiSelectWorkouts={WorkoutId => this.onMultiSelect(WorkoutId)}
                            SelectedWorkouts={this.state.MultiSelect_Workouts}
                            StartingDate={this.props.location.state && this.props.location.state.WorkoutDate}
                        />
                    }
                </Page>

                {this.renderBottomBarHeightAdjustment()}
                {this.renderBottomBar()}

                {this.renderAddEditGroupManagersModal()}
                {this.renderAddEditGroupMembersModal()}
                {this.renderCopyWorkoutsModal()}
                {this.renderDeleteWorkoutsModal()}
                {this.renderShowChooseDatesModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        GroupDetails: state.Group.GroupDetails,
        WeeklyView_EndDate: state.Group.WeeklyView_GroupWorkouts_EndDate,
        WeeklyView_StartDate: state.Group.WeeklyView_GroupWorkouts_StartDate,

        TryingGetGroupDetails: state.Group.TryingGetGroupDetails,
        TryingGetGroupDetailsError: state.Group.TryingGetGroupDetailsError
    };
};
  
export default withTranslation()(connect(mapStateToProps, { ClearGroupDetails, CopyGroupWorkouts, DeleteGroupWorkouts, GetGroupDetails, MoveGroupWorkouts, SaveDataInProps })(GroupDetail));