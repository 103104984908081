import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { SearchTrainerClientsSAYT } from '../../../Services/Actions';
import Popover from 'react-popover';

import { CustomSearchFunctionResultContainer, EllipsisText, HeaderPictureContainer, NoResultsContainer, ProfilePhotoContainer, SAYTResultsContainer, SAYTUser, SearchClientsInput, StyledSAYTLink } from './styles';

import PageText from '../../Text/PageText';
import ProfilePhoto from '../../ProfilePhoto';

class HeaderSAYT extends React.Component {
    TimeoutId = null;

    state = {
        Clients: [],
        ClientSearch: '',
        PopoverIsOpen: false
    };

    buildUserText = DisplayName => {
        DisplayName = DisplayName.replace(new RegExp(this.state.ClientSearch, "gi"), (match) => `<span style="color: var(--blue-abel); font-family: var(--font-family-semibold);">${match}</span>`);

        return <EllipsisText dangerouslySetInnerHTML={{ __html: DisplayName }} />
    }

    onChangeClientSearch = event => {
        var ClientSearch = event.target.value;

        this.setState({ ClientSearch, PopoverIsOpen: !!ClientSearch }, () => {
            if (!ClientSearch) {
                if (!!this.TimeoutId) {
                    clearTimeout(this.TimeoutId);
                }
            } else {
                var Delay = 500;
                if (ClientSearch.length === 1) Delay = 0;

                if (!!this.TimeoutId) {
                    clearTimeout(this.TimeoutId);
                }

                var Timer = Delay => setTimeout(() => {
                    this.props.SearchTrainerClientsSAYT({ Search: ClientSearch, TrainerId: this.props.UserDetails.UserId }).then(({ Clients }) => {
                        this.setState({ Clients });
                    });
                    this.TimeoutId = null;
                }, Delay);
        
                this.TimeoutId = Timer(Delay);
            }
        });
    }

    onClickSAYTLink = ({ UserDetails }) => {
        this.setState({ PopoverIsOpen: false }, () => {
            this.setState({ Clients: [], ClientSearch: '' });

            if (!!this.props.OnClickSearchResult) this.props.OnClickSearchResult({ UserDetails });
        });
    }

    onTogglePopover = () => {
        this.setState({ PopoverIsOpen: !this.state.PopoverIsOpen }, () => {
            if (!this.state.PopoverIsOpen && !!this.TimeoutId) clearTimeout(this.TimeoutId);
        });
    }

    renderPopoverContent = () => {
        var { t } = this.props;
        var { OnClickSearchResult, TryingSearchTrainerClientsSAYT } = this.props;
        var { Clients, PopoverIsOpen } = this.state;

        if (!PopoverIsOpen) return <SAYTResultsContainer></SAYTResultsContainer>;

        return (
            <SAYTResultsContainer className="SAYTResultsContainer">
                {
                    Clients.length ?
                    Clients.map(({ DisplayName, IsPreRegistered, ProfilePhotoUrl, Sex, UserId }, ClientIndex) => {                        
                        return (
                            <SAYTUser className="SAYTUser" key={ClientIndex}>
                                {
                                    !+IsPreRegistered &&
                                    <HeaderPictureContainer className="HeaderPictureContainer" to={`/client/${UserId}`}>
                                        <ProfilePhotoContainer className="ProfilePhotoContainer">
                                            <ProfilePhoto Sex={Sex || 'Male'} Size="small-3" Source={ProfilePhotoUrl || ''} />
                                        </ProfilePhotoContainer>
                                    </HeaderPictureContainer>
                                }
                                {
                                    !!OnClickSearchResult ?
                                    <CustomSearchFunctionResultContainer onClick={() => this.onClickSAYTLink({ UserDetails: Clients[ClientIndex] })}>
                                        {this.buildUserText(DisplayName)}
                                    </CustomSearchFunctionResultContainer>
                                :
                                    <StyledSAYTLink onClick={this.onClickSAYTLink} to={`/client/${UserId}`}>
                                        {this.buildUserText(DisplayName)}
                                    </StyledSAYTLink>   
                                }
                            </SAYTUser>
                        );
                    })
                :
                    TryingSearchTrainerClientsSAYT ?
                    <i className="fas fa-spinner fa-spin"></i>
                :
                    <NoResultsContainer className="NoResultsContainer">
                        <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('search_noresults')} TextAlign="center" />
                    </NoResultsContainer>
                }
            </SAYTResultsContainer>
        );
    }

    render() {
        var { t } = this.props;
        const popoverProps = {
            isOpen: this.state.PopoverIsOpen,
            preferPlace: 'below',
            onOuterAction: this.onTogglePopover,
            body: this.renderPopoverContent(),
            tipSize: 0.01
        }

        return (
            <Popover {...popoverProps}>
                <SearchClientsInput
                    FontSize="medium-1"
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeClientSearch}
                    Placeholder={t('search_for_client')}
                    Size="medium"
                    Type="text"
                    Value={this.state.ClientSearch}
                />
            </Popover>
        );
    }
}

const mapStateToProps = state => {
    return {
        UserDetails: state.Auth.UserDetails,
        
        TryingSearchTrainerClientsSAYT: state.Clients.TryingSearchTrainerClientsSAYT
    };
};
  
export default withTranslation()(connect(mapStateToProps, { SearchTrainerClientsSAYT } )(HeaderSAYT));