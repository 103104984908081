import styled from 'styled-components';

import InfiniteScroll from "react-infinite-scroll-component";

import Loading from '../../Loading';

export const LoadingContainer = styled.div`
    align-items: center;
    display: flex;
    grid-column-end: -1;
    grid-column-start: 1;
    justify-content: center;
    margin-bottom: 15px;
`;

export const ListItemContainer = styled.div`
    padding: 10px;

    :hover {
        background-color: var(--white-concrete-hover);
        color: var(--white);
        cursor: pointer;
    }

    :nth-child(2n) {
        background: var(--white-concrete);

        :hover {
            background-color: var(--white-concrete-hover);
            color: var(--white);
        }
    }
`;

export const StyledInfiniteScroll = styled(InfiniteScroll)`
    border: 2px solid var(--white-concrete);
    border-radius: 23px;
    max-height: ${props => props.height};
`;

export const StyledLoading = styled(Loading)`
    background-color: inherit;
    position: unset;
`;

export const ViewCount = styled.div`
    text-align: center;
`;