import React from 'react';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import history from '../../history';

import * as Styles from './styles';

import Contracts from './Contracts';
import Income from './Income';
import Invoices from './Invoices';
import Page from '../../Components/Page';

class OrgIncome extends React.Component {
    _isMounted = false;

    state = {
        PageTitle: ''
    }

    componentDidMount() {
        this._isMounted = true;

        const QueryValues = queryString.parse(this.props.location.search);

        var PageTitle = '';

        if (Object.entries(QueryValues).length > 0 && QueryValues.tab) {
            if (QueryValues.tab.toLowerCase() === 'income') PageTitle = 'Income';
            else if (QueryValues.tab.toLowerCase() === 'organize') PageTitle = 'Organize';
            else if (QueryValues.tab.toLowerCase() === 'contracts') PageTitle = 'Contracts';
            else if (QueryValues.tab.toLowerCase() === 'invoices') PageTitle = 'Invoices';
        } else history.push('/income?tab=income')

        this.setState({ PageTitle });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            const QueryValues = queryString.parse(this.props.location.search);

            var PageTitle = '';

            if (Object.entries(QueryValues).length > 0 && QueryValues.tab) {
                if (QueryValues.tab.toLowerCase() === 'income') PageTitle = 'Income';
                else if (QueryValues.tab.toLowerCase() === 'organize') PageTitle = 'Organize';
                else if (QueryValues.tab.toLowerCase() === 'contracts') PageTitle = 'Contracts';
                else if (QueryValues.tab.toLowerCase() === 'invoices') PageTitle = 'Invoices';
            } else history.push('/income?tab=income')

            this.setState({ PageTitle });
        }
    }

    renderBody = () => {
        var { PageTitle } = this.state;

        if (PageTitle === 'Income' || PageTitle === 'Organize') return <Income PageTitle={PageTitle} />
        else if (PageTitle === 'Contracts') return <Contracts PageTitle={PageTitle} />
        else if (PageTitle === 'Invoices') return <Invoices PageTitle={PageTitle} />

        return null;
    }

    render() {
        var { PageTitle } = this.state;

        return (
            <>
                <Page NoMargin NoPadding>
                    <Styles.PageContainer className="PageContainer" HideFilters={PageTitle === 'Organize'}>
                        {this.renderBody()}
                    </Styles.PageContainer>
                </Page>
            </>
        );
    }
}

export default withTranslation()(OrgIncome);