import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddTrainerClient, ClearAddTrainerClientError, GetAllTrainerGroupsForNewClient } from '../../Services/Actions';

import Select, { components } from 'react-select';

import { AddClientToGroupsContainer, BodyContainer, BottomButton, CustomOptionContainer, InputContainer, InstructionsContainer, Instructions1, Instructions2, MenuListContainer, StyledTeamPicture } from './styles';

import AbelModal from '../../Components/Modal';
import Input from '../../Components/Form/Input';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import DefaultGroupImage from '../../Assets/Icons/Group.png';
import DefaultTeamImage from '../../Assets/Icons/Team.png';

const CustomOption = props => {
    var { data, ...rest } = props;

    var { GroupImage, GroupName, GroupType } = data;

    var BackupPictureSrc = GroupType === 'team' ? DefaultTeamImage : DefaultGroupImage;

    return (
        <CustomOptionContainer className="CustomOptionContainer">
            <components.Option {...rest}>
                <StyledTeamPicture
                    src={GroupImage || BackupPictureSrc}
                    Margin="0 20px 0 0"
                    RoundImage
                    SelfLoading
                />
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={GroupName} TextAlign="left" />
            </components.Option>
        </CustomOptionContainer>
    );
}

const CustomMenuList = props => {
    return (
        <MenuListContainer className="MenuListContainer">
            <components.MenuList {...props}>
                {props.children}
            </components.MenuList>
        </MenuListContainer>
    );
};

const DropdownStyles = {
    control: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '500px' }),
    input: styles => ({ ...styles, maxWidth: '100%', width: '500px' }),
    menu: styles => ({ ...styles, maxWidth: '100%', width: '500px' }),
    option: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '500px' })
};

class AddClient extends React.Component {
    _isMounted = false;

    state = { AllTrainerGroups: [], ClientIdentifier: '', DefaultGroups: [], DropdownIsOpen: false, RetrievedAllTrainerGroups: false, ScreenName: 'Search' };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.ClearAddTrainerClientError();
    }

    onBlurAllTrainerGroups = () => {
        this.setState({ DropdownIsOpen: false });
    }
    
    onChangeScreenName = ScreenName => {
        this.setState({ ScreenName });
    }

    onClickSubmit = () => {
        var { ClientIdentifier, DefaultGroups, ScreenName } = this.state;

        DefaultGroups = DefaultGroups.map(({ GroupId, GroupType }) => ({ AddToFutureWorkouts: GroupType === 'Team' ? 1 : 0, GroupId, GroupType }));

        this.props.AddTrainerClient({ ClientIdentifier, DefaultGroups, ...ScreenName === 'Invite' && { PreRegister: 1 } }).then(({ IsEmail }) => {
            if (!this.props.TryingAddTrainerClientError) {
                if (IsEmail) this.setState({ ScreenName: 'Invite' });
                else this.props.Refresh();
            }
        });
    }

    onChangeClientIdentifier = event => {
        this.setState({ ClientIdentifier: event.target.value });
    }

    onChangeTrainerGroups = DefaultGroups => {
        this.setState({ DefaultGroups });
    }

    onFocusAllTrainerGroups = () => {
        this.setState({ DropdownIsOpen: true });

        if (!this.state.RetrievedAllTrainerGroups) {
            this.props.GetAllTrainerGroupsForNewClient().then(({ AllTrainerGroups }) => {
                this.setState({ AllTrainerGroups, RetrievedAllTrainerGroups: true });
            });
        }
    }

    onKeyPress = event => {
        if (event.key === 'Enter' && !this.props.TryingAddTrainerClient) {
            this.onClickSubmit();
        }
    }

    renderAddClientToGroups = () => {
        var { t } = this.props;
        var { TryingGetAllTrainerGroupsForNewClient } = this.props;
        var { AllTrainerGroups, DropdownIsOpen } = this.state;

        return (
            <AddClientToGroupsContainer className="AddClientToGroupsContainer">
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('add_new_client_to_groups')} TextAlign="center" />

                <Spacer Size="small" />

                <Select
                    closeMenuOnSelect={false}
                    getOptionLabel={option => option.GroupName}
                    getOptionValue={option => option.Id}
                    components={{ MenuList: CustomMenuList, Option: CustomOption }}
                    isLoading={TryingGetAllTrainerGroupsForNewClient}
                    isMulti
                    isSearchable
                    onBlur={this.onBlurAllTrainerGroups}
                    onChange={this.onChangeTrainerGroups}
                    onFocus={this.onFocusAllTrainerGroups}
                    options={AllTrainerGroups}
                    placeholder={t('Groups')}
                    styles={DropdownStyles}
                />

                {!!DropdownIsOpen && <Spacer Size="200" />}
            </AddClientToGroupsContainer>
        );
    }

    renderBottomButton = () => {
        var { t } = this.props;
        var { ClientIdentifier, ScreenName } = this.state;

        var Disabled = !ClientIdentifier || this.props.TryingAddTrainerClient;

        var Buttons = [];
        if (ScreenName === 'Search') Buttons = [{ BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: this.onClickSubmit, Title: t('ptadmin_clients_add_singular') }]
        else if (ScreenName === 'Invite') {
            Buttons = [
                { BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onChangeScreenName('Search'), Title: t('ptadmin_search_client_try_again') },
                { BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: this.onClickSubmit, Title: t('ptadmin_search_client_invite_user') }
            ]
        }

        return <BottomButton Buttons={Buttons} NormalWhiteSpace />;
    }

    renderModalBody = () => {
        var { t } = this.props;
        var { ClientIdentifier, ScreenName } = this.state;

        if (ScreenName === 'Invite') {
            return (
                <BodyContainer>
                    {this.props.TryingAddTrainerClient && <Loading />}

                    <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text={t('ptadmin_search_client_does_not_exist')} TextAlign="center" />

                    <Spacer Size="medium" />

                    <PageText FontFamily="bold" FontSize="large" JustifyContent="center" NoMargin Text={ClientIdentifier} TextAlign="center" />
                </BodyContainer>
            );
        }

        var Texts1 = [
            { FontFamily: "bold", FontSize: "medium-1", Text: t('ptadmin_search_client_invite_instructions_1') },
            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('ptadmin_search_client_invite_instructions_2') }
        ];

        var Texts2 = [
            { FontFamily: "bold", FontSize: "medium-1", Text: t('ptadmin_search_client_invite_instructions_3') },
            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('ptadmin_search_client_invite_instructions_4') }
        ];

        var Texts3 = [
            { FontFamily: "bold", FontSize: "medium-1", Text: t('ptadmin_search_client_request_instructions_1') },
            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('ptadmin_search_client_request_instructions_2') }
        ];

        var Texts4 = [
            { FontFamily: "bold", FontSize: "medium-1", Text: t('ptadmin_search_client_request_instructions_3') },
            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('ptadmin_search_client_request_instructions_4') }
        ];

        return (
            <BodyContainer>
                {this.props.TryingAddTrainerClient ? <Loading /> : null}

                <PageText FontFamily="medium" FontSize="large" NoMargin Text={t('ptadmin_clients_add_singular')} TextAlign="center" />

                <Spacer Size="small" />

                <InstructionsContainer className="InstructionsContainer">
                    <Instructions1 className="Instructions1">
                        <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('ptadmin_search_client_send_invite')} TextAlign="left" />

                        <Spacer Size="extra-extra-small" />

                        <MultiText Texts={Texts1} />
                        
                        <Spacer Size="extra-extra-small" />

                        <MultiText Texts={Texts2} />
                    </Instructions1>
                    <Instructions2 className="Instructions2">
                        <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('ptadmin_search_client_send_request')} TextAlign="left" />

                        <Spacer Size="extra-extra-small" />

                        <MultiText Texts={Texts3} />
                        
                        <Spacer Size="extra-extra-small" />

                        <MultiText Texts={Texts4} />
                    </Instructions2>
                </InstructionsContainer>

                <Spacer Size="large" />

                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('ptadmin_search_client_input_label')} TextAlign="center" />

                <Spacer Size="small" />

                <InputContainer>
                    <Input FontSize="medium-2" NoLabel OnChange={this.onChangeClientIdentifier} OnKeyPress={this.onKeyPress} Placeholder={t('ptadmin_clients_add_singular_placeholder')} Size="medium" Type="text" Value={this.state.ClientIdentifier} />
                </InputContainer>

                <Spacer Size="medium" />

                {this.renderAddClientToGroups()}

                {
                    this.props.TryingAddTrainerClientError ?
                    <PageText FontColor="red-wellread" FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={this.props.TryingAddTrainerClientError} />
                :
                    null
                }
            </BodyContainer>
        );
    }

    render() {
        var { OnHide, Show } = this.props;

        return (
            <AbelModal
                BottomButtonRender={this.renderBottomButton}
                GA_PathName="AddClient"
                NoMargin
                Show={Show}
                Size="large"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

AddClient.propTypes = {
    OnHide: PropTypes.func.isRequired,
    Refresh: PropTypes.func,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        TryingAddTrainerClient: state.Clients.TryingAddTrainerClient,
        TryingAddTrainerClientError: state.Clients.TryingAddTrainerClientError,
        TryingGetAllTrainerGroupsForNewClient: state.Clients.TryingGetAllTrainerGroupsForNewClient,
        TryingGetAllTrainerGroupsForNewClientError: state.Clients.TryingGetAllTrainerGroupsForNewClientError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddTrainerClient, ClearAddTrainerClientError, GetAllTrainerGroupsForNewClient } )(AddClient));