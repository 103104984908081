import styled from 'styled-components';
import { device } from '../../Config/device';

import Checkmark from '../../Components/Form/Checkmark';
import Paginated from '../../Components/Lists/Paginated';

export const CheckmarkContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const GroupTypeContainer = styled.div``;

export const GroupTypeSubContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const StyledCheckmark = styled(Checkmark)`
    margin-right: 5px;
`;

export const StyledPaginatedList = styled(Paginated)`
    padding: 15px;

    > div:first-child {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(1, 2fr);

        @media ${device.minwidth.mobile} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media ${device.minwidth.ipad} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media ${device.minwidth.laptop} {
            grid-template-columns: repeat(4, 1fr);
        }
    }
`;