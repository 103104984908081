import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearMealTemplates, DeleteMealTemplates, SaveDataInProps, SearchMealTemplates_SAYT, SearchMealTemplates, UpdateShareAbilityStatus_MealTemplates, UpdateSharedToCoachStatusAll_MealTemplates, UpdateSharedToCoachStatus_MealTemplates } from '../../Services/Actions';
import history from '../../history';

import { device } from '../../Config/device';
import styled from 'styled-components';

import BottomBar from '../../Components/BottomBar';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Dropdown from '../../Components/Dropdown';
import { GA_PageView } from '../../Components/GoogleAnalytics';
// import Input from '../../Components/Form/Input';
import Loading from '../../Components/Loading';
import LoadMore from '../../Components/Lists/LoadMore';
import { MealTemplateListItem } from '../../Components/Lists/ListItems';
import PageText from '../../Components/Text/PageText';
import SAYT from '../../Components/Form/SAYT';
import Spacer from '../../Components/Spacer';
import Toggle from '../../Components/Form/Toggle';

import EditShareAbilityStatusModal from '../../Modals/EditShareAbilityStatusModal';
import HowSharingWorksModal from '../../Modals/HowSharingWorksModal';
import Info from '../../Modals/Info';
import MealTemplate from '../../Modals/MealTemplate';
import ShareContentModal from '../../Modals/ShareContentModal';

import Plus from '../../Assets/Icons/Plus.png';

const AllFilters = styled.div`
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
    }
`;

const BottomBarItems = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

const Container = styled.div`
    align-items: stretch;
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
`;

const ContainerHeader = styled.div`
    // align-items: center;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;

    @media ${device.maxwidth.ipad} {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr;
    }

    @media ${device.minwidth.laptop} {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

const DeleteTemplatesList = styled.div`
    display: grid;
    grid-gap: 40px 15px;
    grid-template-columns: repeat(1, 2fr);
    justify-content: center;

    @media ${device.minwidth.mobile} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.minwidth.laptop} {
        grid-template-columns: repeat(4, 1fr);
    }
`;

const ListHeaderContainer = styled.div``;

const SelectedItems = styled(PageText)`
    background-color: var(--blue-abel);
    border-radius: 20px;
    padding: 5px 15px;
`;

const StyledBottomBar = styled(BottomBar)`
    background-color: var(--blue-astronaut);
`;

const StyledDropdown = styled(Dropdown)`
    margin-bottom: 10px;
    width: 100%;

    @media ${device.minwidth.laptop} {
        // margin: 0 60px;
        margin-left: 60px;
        max-width: 300px;
    }
`;

const StyledLoadMore = styled(LoadMore)`
    background-color: var(--white);
    border: none;

    > div:first-child {
        display: grid;
        grid-gap: 40px 15px;
        // grid-template-columns: repeat(1, 2fr);

        // @media ${device.minwidth.ipad} {
        //     grid-template-columns: repeat(2, 1fr);
        // }

        // @media ${device.minwidth.laptop} {
        //     grid-template-columns: repeat(4, 1fr);
        // }

        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
        max-width: 100%;
    }
`;

// const StyledSearchInput = styled(Input)`
//     margin-bottom: 10px;
//     width: 100%;

//     > input {
//         border: 2px solid var(--white-concrete);
//     }

//     @media ${device.minwidth.ipad} {
//         margin-bottom: 0px;
//     }
// `;

const ToggleContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ViewCount = styled.div`
    text-align: center;
`;

const INITIAL_STATE = {
    PageNo: 1, PageSize: 48
}

class MealTemplates extends React.Component {
    state = {
        ...INITIAL_STATE,
        ActiveSearch: false,
        CompletedFirstSearch: false,
        FilteredTemplateDays: [],
        LastSearchValue: '',
        Loading: true,
        MultiSelectTemplateIds: [],
        MultiSelectTemplates: [],
        NewSearch: false,
        SearchValue: '',
        SelectedDates: [],
        SelectedTemplateDays: null,
        SelectedTemplate: {},
        SelectedTemplateId: null,
        ShowDeleteTemplatesModal: false,
        ShowMultiSelect: false,
        ShowTemplateDetailModal: false,
        StartDate: null,
        TemplateDetail_Preview: {}
    };

    componentDidMount() {
        this._isMounted = true;

        GA_PageView('Library-MealTemplates');

        this.onLoadTemplates();
    }

    componentDidUpdate(prevProps) {
        if (this.props.NeedsUpdate.indexOf('MealTemplate') > -1 && prevProps.NeedsUpdate !== this.props.NeedsUpdate) this.onLoadTemplates();
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearMealTemplates();
    }

    onAssignToClients = (event, Template) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        this.props.SaveDataInProps({ SelectedTemplate: Template }).then(() => history.push({ pathname: '/add-meal-template', state: { from: 'Library' } }));
    }

    onCancelMultiSelect = () => {
        this.setState({ MultiSelectTemplateIds: [], MultiSelectTemplates: [], ShowMultiSelect: false });
    }

    onChangeSearch = event => {
        this.setState({ SearchValue: event.target.value });
    }

    onClickCreateTemplate = () => {
        history.push('library/meal-template');
    }

    onDeleteTemplates = () => {
        var { MultiSelectTemplateIds } = this.state;

        this.props.DeleteMealTemplates({ TemplateIds: MultiSelectTemplateIds }).then(() => {
            if (!this.props.TryingDeleteMealTemplatesError) {
                this.setState({ MultiSelectTemplateIds: [], MultiSelectTemplates: [] }, () => {
                    this.onToggleShowDeleteTemplatesModal(false);
                    this.onLoadTemplates();
                });
            }
        });
    }

    onKeyPress = ({ key }) => {
        if (key === 'Enter' && !this.props.TryingSearchMealTemplates) {
            var { SearchValue, LastSearchValue } = this.state;

            if (SearchValue !== LastSearchValue) {
                this.setState({ PageNo: 1, PageSize: 48, LastSearchValue: SearchValue }, () => {
                    this.onLoadTemplates();
                });
            }
        }
    }

    onLoadTemplates = Increment => {
        var { FilteredTemplateDays, NewSearch, PageNo, PageSize, SearchValue } = this.state;

        if (Increment) {
            NewSearch = false;
            PageNo = PageNo + 1;
        } else {
            NewSearch = true;
            PageNo = 1;
        }

        this.setState({ ActiveSearch: true, NewSearch, PageNo }, () => this.props.SearchMealTemplates({ AddToExisting: Increment, PageNo, PageSize, TemplateDays: FilteredTemplateDays, TemplateName: SearchValue }).then(() => this._isMounted ? this.setState({ ActiveSearch: false, CompletedFirstSearch: true, Loading: false }) : null));
    }

    onMultiSelect = (Template, MultiSelectIndex) => {
        if (MultiSelectIndex === -1) this.setState({ MultiSelectTemplateIds: [ ...this.state.MultiSelectTemplateIds, Number(Template.TemplateId)], MultiSelectTemplates: [ ...this.state.MultiSelectTemplates, Template ] });
        else {
            this.setState((prevState) => {
                return {
                    MultiSelectTemplateIds: [ ...prevState.MultiSelectTemplateIds.slice(0, MultiSelectIndex), ...prevState.MultiSelectTemplateIds.slice(MultiSelectIndex + 1) ],
                    MultiSelectTemplates: [ ...prevState.MultiSelectTemplates.slice(0, MultiSelectIndex), ...prevState.MultiSelectTemplates.slice(MultiSelectIndex + 1) ]
                }
            });
        }
    }

    onRenderListHeader = () => {
        var { t } = this.props;
        var { MealTemplateList_NumPendingShared } = this.props;

        return (
            <ListHeaderContainer className="ListHeaderContainer">
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${t('you_have_X_pending_shared_requests', { NumPendingShared: MealTemplateList_NumPendingShared })}:`} TextAlign="center" />

                <Spacer Size="extra-extra-small" />

                <ButtonGroup
                    Buttons={[
                        { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', OnClick: () => this.onUpdateSharedToCoachStatus_All({ NewStatus: 'reject' }), Size: 'small', Title: t('reject_all') },
                        { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', OnClick: () => this.onUpdateSharedToCoachStatus_All({ NewStatus: 'accept' }), Size: 'small', Title: t('accept_all') }
                    ]}
                    ButtonWidth="fit-content"
                    NotTouching
                />

                <Spacer Size="medium" />
            </ListHeaderContainer>
        );
    }

    onSearchFromSAYT = SearchValue => {
        if (!this.props.TryingSearchMealTemplates) {
            var { LastSearchedValue } = this.state;

            if (SearchValue !== LastSearchedValue) {
                this.setState({ PageNo: 1, PageSize: INITIAL_STATE.PageSize, LastSearchedValue: SearchValue, SearchValue }, () => this.onLoadTemplates());
            }
        }
    }

    onSelectTemplateDay = (event, TemplateDayId, TemplateDayIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = TemplateDayIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return {
                    FilteredTemplateDays: [ ...prevState.FilteredTemplateDays, ...[ TemplateDayId ] ],
                }
            }, () => this.onLoadTemplates());
        } else {
            this.setState((prevState) => {
                return {
                    FilteredTemplateDays: [ ...prevState.FilteredTemplateDays.slice(0, TemplateDayIndex), ...prevState.FilteredTemplateDays.slice(TemplateDayIndex + 1) ],
                }
            }, () => this.onLoadTemplates());
        }
    }

    onShareContentToEmails = ({ Emails, ContentIds }) => {
        this.props.UpdateSharedToCoachStatus_MealTemplates({ Emails, NewStatus: 'Pending', TemplateIds: ContentIds }).then(() => {
            this.onToggleShowShareContentModal(false);
        });
    }

    onToggleMultiSelect = event => {
        // GA_Event({ Category: 'ClientDetail', Action: `Toggle Weekly View Multi Select - Workouts`, Label: `Toggled ${!!event.target.checked ? 'On' : 'Off'}` });

        this.setState({ MultiSelectTemplateIds: [], MultiSelectTemplates: [], ShowMultiSelect: event.target.checked });
    }

    onToggleShowDeleteTemplatesModal = ShowDeleteTemplatesModal => {
        this.setState({ ShowDeleteTemplatesModal });
    }

    onToggleShowEditShareAbilityStatusModal = ({ ContentId = null, ContentIndex = null, ShowEditShareAbilityStatusModal, CurrentStatus = null }) => {
        this.setState({ ShowEditShareAbilityStatusModal, ShowEditShareAbilityStatusModalDetails: { ContentId, ContentIndex, CurrentStatus } });
    }

    onToggleShowHowSharingWorksModal = ShowHowSharingWorksModal => {
        this.setState({ ShowHowSharingWorksModal });
    }

    onToggleShowShareContentModal = ShowShareContentModal => {
        if (!this.state.MultiSelectTemplateIds.length && !!ShowShareContentModal) {}
        else this.setState({ ShowShareContentModal });
    }

    onToggleShowTemplateDetailModal = (ShowTemplateDetailModal, TemplateDetail_Preview, TemplateId) => {
        var { SelectedTemplate, SelectedTemplateId } = this.state;

        if (TemplateId && Number(TemplateId) === Number(SelectedTemplateId)) { SelectedTemplate = {}; SelectedTemplateId = null; }
        
        this.setState({ SelectedTemplate, SelectedTemplateId, ShowTemplateDetailModal, TemplateDetail_Preview });
    }

    onUpdateMultiSelectList = ({ ContentId, NewStatus, NewStatusName }) => {
        var ContentIndex = this.state.MultiSelectTemplateIds.indexOf(+ContentId);

        if (ContentIndex !== -1) {
            this.setState(prevState => {
                var MultiSelectTemplates = [ ...prevState.MultiSelectTemplates ];
                MultiSelectTemplates[ContentIndex] = { ...MultiSelectTemplates[ContentIndex], ShareAbility: NewStatusName, ShareAbilityStatusId: NewStatus };
                
                return { ...prevState, MultiSelectTemplates };
            });
        }
    }

    onUpdateShareAbilityStatus = ({ ContentId, ContentIndex, NewStatus, NewStatusName }) => {
        return this.props.UpdateShareAbilityStatus_MealTemplates({ NewStatus, NewStatusName, TemplateId: ContentId }).then(() => {
            this.setState(prevState => {
                var MultiSelectTemplates = [ ...prevState.MultiSelectTemplates ];
                MultiSelectTemplates[ContentIndex] = { ...MultiSelectTemplates[ContentIndex], ShareAbility: NewStatusName, ShareAbilityStatusId: NewStatus };
                
                return { ...prevState, MultiSelectTemplates };
            });
        });
    }

    onUpdateSharedToCoachStatus = ({ ContentId, NewStatus, PersonalResponse }) => {
        this.props.UpdateSharedToCoachStatus_MealTemplates({ Emails: [ this.props.UserDetails.Email ], NewStatus, PersonalResponse, TemplateIds: [ ContentId ] }).then(this.onLoadTemplates);
    }

    onUpdateSharedToCoachStatus_All = ({ NewStatus }) => {
        this.props.UpdateSharedToCoachStatusAll_MealTemplates({ NewStatus }).then(this.onLoadTemplates);
    }

    renderBottomBar = () => {
        var { MultiSelectTemplateIds, ShowMultiSelect } = this.state;

        if (!ShowMultiSelect) return null;

        var { t } = this.props;
        var { Device } = this.props;

        return (
            <>
                <Spacer Size="extra-large" />

                <StyledBottomBar className="StyledBottomBar">
                    <BottomBarItems className="BottomBarItems">
                        <SelectedItems ElementType="span" FontColor="white" FontFamily="bold" FontSize="medium-3" Text={`${MultiSelectTemplateIds.length}`} />
                        &nbsp;
                        <PageText ElementType="span" FontColor="white" FontFamily="medium" FontSize="medium-1" Text={t('WorkoutPlanSettings_selected').toLowerCase()} />
                    </BottomBarItems>
                    <BottomBarItems className='BottomBarItems'>
                        <ButtonGroup
                            Buttons={[
                                { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', OnClick: () => this.onToggleShowShareContentModal(true), Title: t('share_with_another_coach') },
                                { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', OnClick: () => this.onToggleShowDeleteTemplatesModal(true), Title: t('delete_with_first_char_uppercase') },
                                { BackgroundColor: 'white', BackgroundColorHover: 'white-hover', Border: 'none', Color: 'black', ColorHover: 'black', OnClick: this.onCancelMultiSelect, Title: t('Cancel_with_first_char_uppercase') }
                            ]}
                            NotTouching
                            OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                        />
                    </BottomBarItems>
                </StyledBottomBar>
            </>
        );
    }

    renderDeleteTemplatesInformation = () => {
        var { t } = this.props;
        var { MultiSelectTemplates } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" Text={t('template_delete_warning')} TextAlign="center" />

                <DeleteTemplatesList>
                    {
                        MultiSelectTemplates.map(Template => <MealTemplateListItem key={Template.TemplateId} HideSelect Item={Template} ViewTemplate={Template => this.onToggleShowTemplateDetailModal(true, Template)} />)
                    }
                </DeleteTemplatesList>
            </>
        );
    }

    renderDeleteTemplatesModal = () => {
        var { t } = this.props;
        var { TryingDeleteMealTemplates } = this.props;
        var { ShowDeleteTemplatesModal, ShowTemplateDetailModal } = this.state;

        if (ShowDeleteTemplatesModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteTemplates}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    DisplayNone={ShowTemplateDetailModal}
                    InformationRenderer={this.renderDeleteTemplatesInformation}
                    Loading={TryingDeleteMealTemplates}
                    OnHide={() => this.onToggleShowDeleteTemplatesModal(false)}
                    Show={ShowDeleteTemplatesModal}
                />
            );
        }
    }

    renderFilters = () => {
        var { t } = this.props;
        var { DishTemplateFilterSettings: { TemplateDays }, TryingSearchMealTemplates } = this.props;
        var { FilteredTemplateDays } = this.state;

        return (
            <AllFilters>
                {/* <StyledSearchInput
                    FontSize="medium-2"
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeSearch}
                    OnKeyPress={this.onKeyPress}
                    Placeholder={t('search_with_first_char_uppercase_dots')}
                    Size="medium"
                    Type="text"
                    Value={SearchValue}
                /> */}

                <SAYT
                    ItemName="TemplateName"
                    OnSearch={this.onSearchFromSAYT}
                    Placeholder={t('search_with_first_char_uppercase_dots')}
                    SearchFunc={this.props.SearchMealTemplates_SAYT}
                />

                <StyledDropdown
                    Disabled={TryingSearchMealTemplates}
                    ItemId="TemplateDayId"
                    ItemName="TemplateDay"
                    Items={TemplateDays}
                    Loading={false}
                    NeedsToLoad={false}
                    OnSelectItem={(event, TemplateDayId, TemplateDayIndex) => this.onSelectTemplateDay(event, TemplateDayId, TemplateDayIndex)}
                    SelectedItemIds={FilteredTemplateDays}
                    Title={t('template_days')}
                />
            </AllFilters>
        );
    }

    renderShowEditShareAbilityStatusModal = () => {
        var { ShowEditShareAbilityStatusModal, ShowEditShareAbilityStatusModalDetails } = this.state;

        if (ShowEditShareAbilityStatusModal) {
            return (
                <EditShareAbilityStatusModal
                    ContentId={ShowEditShareAbilityStatusModalDetails.ContentId}
                    ContentIndex={ShowEditShareAbilityStatusModalDetails.ContentIndex}
                    CurrentStatus={ShowEditShareAbilityStatusModalDetails.CurrentStatus}
                    Loading={this.props.TryingUpdateShareAbilityStatus_MealTemplates}
                    OnHide={() => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: false })}
                    OnUpdateShareAbilityStatus={this.onUpdateShareAbilityStatus}
                    Show={ShowEditShareAbilityStatusModal}
                />
            );
        }
    }

    renderShowHowSharingWorksModal = () => {
        var { ShowHowSharingWorksModal } = this.state;

        if (ShowHowSharingWorksModal) {
            return (
                <HowSharingWorksModal
                    OnHide={() => this.onToggleShowHowSharingWorksModal(false)}
                    Show={ShowHowSharingWorksModal}
                />
            );
        }
    }

    renderShowShareContentModal = () => {
        var { MultiSelectTemplates, ShowEditShareAbilityStatusModal, ShowHowSharingWorksModal, ShowShareContentModal } = this.state;

        if (ShowShareContentModal) {
            var { TryingUpdateSharedToCoachStatus_MealTemplates } = this.props;

            return (
                <ShareContentModal
                    ContentIdName="TemplateId"
                    ContentType="MealTemplates"
                    DisplayNone={!!ShowEditShareAbilityStatusModal || !!ShowHowSharingWorksModal}
                    OnHide={() => this.onToggleShowShareContentModal(false)}
                    OnSubmit={({ Emails, ContentIds }) => this.onShareContentToEmails({ Emails, ContentIds })}
                    OnToggleShowEditShareAbilityStatusModal={({ ContentIndex, ContentId, CurrentStatus }) => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: true, ContentId, ContentIndex, CurrentStatus })}
                    OnToggleShowHowSharingWorksModal={() => this.onToggleShowHowSharingWorksModal(true)}
                    Loading={TryingUpdateSharedToCoachStatus_MealTemplates}
                    SharingContent={MultiSelectTemplates}
                    Show={ShowShareContentModal}
                />
            );
        }
    }

    renderTemplateDetailModal = () => {
        var { ShowTemplateDetailModal, TemplateDetail_Preview } = this.state;

        if (ShowTemplateDetailModal) {
            return (
                <MealTemplate
                    OnHide={TemplateId => this.onToggleShowTemplateDetailModal(false, null, TemplateId)}
                    OnLoadTemplates={this.onLoadTemplates}
                    OnUpdateMultiSelectList={this.onUpdateMultiSelectList}
                    TemplateDetail_Preview={TemplateDetail_Preview}
                    Show={ShowTemplateDetailModal}
                />
            );
        }
    }

    renderViewCount = () => {
        var { t } = this.props;
        var { MealTemplateList_TotalRecords: TotalRecords } = this.props;
        var { PageNo, PageSize } = this.state;

        if (!TotalRecords) TotalRecords = 0;

        return (
            <ViewCount>
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`1 - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                &nbsp;
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords}`} />
                &nbsp;
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('templates_plural').toLowerCase()} />
            </ViewCount>
        );
    }

    render() {
        var { t } = this.props;
        var { Device, IsMoreMealTemplates, MealTemplateList, MealTemplateList_NumPendingShared, MealTemplateList_TotalRecords, TryingSearchMealTemplates, TryingUpdateSharedToCoachStatusAll_MealTemplates, TryingUpdateSharedToCoachStatus_MealTemplates } = this.props;
        var { ActiveSearch, CompletedFirstSearch, MultiSelectTemplateIds, NewSearch, PageNo, PageSize, SelectedTemplateId, ShowMultiSelect } = this.state;

        var SelectedItemIds = SelectedTemplateId ? [ +SelectedTemplateId ] : [];

        return (
            <>
                {(TryingSearchMealTemplates || TryingUpdateSharedToCoachStatusAll_MealTemplates || TryingUpdateSharedToCoachStatus_MealTemplates) && <Loading />}

                {this.renderFilters()}

                <Spacer Size="medium" />

                <Container className="Container">
                    <ContainerHeader className="ContainerHeader">
                        <ToggleContainer className="ToggleContainer">
                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('multi_select_and_delete')} TextAlign="center"  />
                            <Toggle
                                Checked={!!+ShowMultiSelect}
                                OffLabel={t('off')}
                                OnChange={this.onToggleMultiSelect}
                                OnLabel={t('on')}
                            />
                        </ToggleContainer>

                        {this.renderViewCount()}

                        <ButtonGroup
                            Buttons={[{ Icon: Plus, IconPosition: 'left', OnClick: this.onClickCreateTemplate, Title: t('MealPlan_Create_meal_template') }]}
                            ContainerWidth={Device === 'mobile_small' || Device === 'mobile' ? '100%' : null}
                        />
                    </ContainerHeader>

                    <Spacer Size="small" />

                    <StyledLoadMore
                        ActiveSearch={ActiveSearch}
                        CompletedFirstSearch={CompletedFirstSearch}
                        HideViewCount
                        IsLoading={false}
                        ItemDescriptionType={t('templates_plural').toLowerCase()}
                        ItemId="TemplateId"
                        ItemName="TemplateName"
                        ItemProps={{
                            AssignToClients: true,
                            HideSelect: !ShowMultiSelect,
                            IsMultiSelect: true,
                            MultiSelectItemIds: MultiSelectTemplateIds,
                            OnAssignToClients: (event, Template) => this.onAssignToClients(event, Template),
                            OnMultiSelect: (Template, MultiSelectIndex) => this.onMultiSelect(Template, MultiSelectIndex),
                            OnUpdateSharedToCoachStatus: this.onUpdateSharedToCoachStatus,
                            SelectTemplate: Template => this.onSelectTemplate(Template),
                            ViewTemplate: Template => this.onToggleShowTemplateDetailModal(true, Template)
                        }}
                        ItemRenderer={MealTemplateListItem}
                        Items={MealTemplateList}
                        HasMoreItems={IsMoreMealTemplates}
                        LoadMore={() => this.onLoadTemplates(true)}
                        NewSearch={NewSearch}
                        NoItemsText={t('search_noresults')}
                        PageNo={PageNo}
                        PageSize={PageSize}
                        RenderHeader={(!!+MealTemplateList_NumPendingShared && !ShowMultiSelect) ? this.onRenderListHeader : null}
                        SelectedItemIds={SelectedItemIds}
                        TotalRecords={MealTemplateList_TotalRecords}
                    />
                </Container>

                {this.renderBottomBar()}

                {this.renderDeleteTemplatesModal()}
                {this.renderShowEditShareAbilityStatusModal()}
                {this.renderShowHowSharingWorksModal()}
                {this.renderShowShareContentModal()}
                {this.renderTemplateDetailModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        NeedsUpdate: state.ContentRequests.NeedsUpdate,

        DishTemplateFilterSettings: state.Templates.DishTemplateFilterSettings,
        IsMoreMealTemplates: state.Templates.IsMoreMealTemplates,
        MealTemplateList: state.Templates.MealTemplateList,
        MealTemplateList_NumPendingShared: state.Templates.MealTemplateList_NumPendingShared,
        MealTemplateList_TotalRecords: state.Templates.MealTemplateList_TotalRecords,

        TryingDeleteMealTemplates: state.Templates.TryingDeleteMealTemplates,
        TryingDeleteMealTemplatesError: state.Templates.TryingDeleteMealTemplatesError,
        TryingSearchMealTemplates: state.Templates.TryingSearchMealTemplates,
        TryingSearchMealTemplatesError: state.Templates.TryingSearchMealTemplatesError,
        TryingUpdateShareAbilityStatus_MealTemplates: state.Templates.TryingUpdateShareAbilityStatus_MealTemplates,
        TryingUpdateShareAbilityStatus_MealTemplatesError: state.Templates.TryingUpdateShareAbilityStatus_MealTemplatesError,
        TryingUpdateSharedToCoachStatusAll_MealTemplates: state.Templates.TryingUpdateSharedToCoachStatusAll_MealTemplates,
        TryingUpdateSharedToCoachStatusAll_MealTemplatesError: state.Templates.TryingUpdateSharedToCoachStatusAll_MealTemplatesError,
        TryingUpdateSharedToCoachStatus_MealTemplates: state.Templates.TryingUpdateSharedToCoachStatus_MealTemplates,
        TryingUpdateSharedToCoachStatus_MealTemplatesError: state.Templates.TryingUpdateSharedToCoachStatus_MealTemplatesError
    };
};
  
export default withTranslation()(connect(mapStateToProps, { ClearMealTemplates, DeleteMealTemplates, SaveDataInProps, SearchMealTemplates_SAYT, SearchMealTemplates, UpdateShareAbilityStatus_MealTemplates, UpdateSharedToCoachStatusAll_MealTemplates, UpdateSharedToCoachStatus_MealTemplates } )(MealTemplates));