import styled from 'styled-components';
import { device } from '../../Config/device';

import Image from '../../Components/Image';
import Input from '../../Components/Form/Input';
import LoadMore from '../../Components/Lists/LoadMore';
import PageText from '../../Components/Text/PageText';

export const DayContainer = styled.div`
    border: 2px solid var(--dark-gray);
    border-radius: 10px;
    overflow: hidden;
`;

export const DayDishesContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    padding: 3px 5px;
`;

export const DaysContainer = styled.div`
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
`;

export const DaysParentContainer = styled.div``;

export const DayTitleContainer = styled.div`
    background-color: var(--dark-gray);
    padding: 3px 5px;
`;

export const DeleteIcon = styled.div`
    align-items: center;
    background-color: var(--red-bittersweet);
    border: 2px solid var(--red-bittersweet);
    border-radius: 50%;
    color: var(--white);
    display: flex;
    height: 30px;
    justify-content: center;
    margin-left: 10px;
    width: 30px;

    @media ${device.minwidth.ipad} {
        background-color: var(--white);
        border-color: var(--white-concrete);
        color: var(--white-concrete);

        :hover {
            background-color: var(--red-bittersweet);
            border-color: var(--red-bittersweet);
            color: var(--white);
            cursor: pointer;
        }
    }
`;

export const DemoUserContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    > div:first-child {
        margin-right: 5px;
    }
`;

export const DishContainer = styled.div`
    border: 2px solid var(--black);
    border-radius: 10px;
    overflow: hidden;
    padding: 5px;
`;

export const DishFoodHiddenContainer = styled.div``;

export const DishFoodsContainer = styled.div``;

export const DishHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const DishImage = styled(Image)`
    margin-right: 10px;
    max-height: 100px;
    max-width: 75px;
`;

export const IngredientContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    margin-bottom: 15px;
    padding: 15px;
`;

export const IngredientListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const IngredientNameStyled = styled(PageText)`
    flex: 1;
`;

export const IngredientOptionsContainer = styled.div`
    display: flex;

    @media ${device.maxwidth.mobile_small} {
        margin-top: 10px;
        width: 100%;
    }
`;

export const IngredientQuantityStyledInput = styled(Input)`
    @media ${device.minwidth.mobile} {
        width: 60px;
    }

    @media ${device.maxwidth.mobile_small} {
        flex: 1;
    }

    > input {
        border: 2px solid var(--white-concrete);
        text-align: center;
        text-indent: 0px;
    }
`;

export const IngredientsContainer = styled.div``;

export const IngredientUnit = styled(PageText)`
    border: 2px solid var(--white-concrete);
    border-radius: 21px;
    margin-left: 10px;
    padding: 2.5px 7px;

    @media ${device.maxwidth.mobile_small} {
        flex: 1;
    }

    :hover {
        background-color: var(--white-concrete);
        cursor: pointer;
    }
`;

export const MainLayoutContainer = styled.div``;

export const NutrientCell = styled.div`
    background-color: var(--${props => !props.WithinLimits ? 'white' : props.WithinLimits === 'below' ? 'abel-blue' : props.WithinLimits === 'above' ? 'red-bittersweet' : 'green'});
`;

export const NutrientCellHeader = styled.div``;

export const NutritionProgressTableContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const NutrientRow = styled.div`
    align-items: center;
    border-bottom: 1px solid var(--black);
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: space-between;
    padding: 5px 10px;
    width: 100%;
`;

export const NutrientRowHeader = styled.div`
    align-items: center;
    background: var(--black);
    color: var(--white);
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: space-between;
    padding: 5px 10px;
    width: 100%;
`;

export const ParentLayoutContainer = styled.div``;

export const ProfilePhotoContainer = styled.div`
    margin-right: 5px;
    width: var(--profilephoto-size-small-3);
`;

export const SidebarLayoutContainer = styled.div``;

export const StyledInput = styled(Input)`
    ${props => props.MaxWidth && `width: ${props.MaxWidth};`}

    margin-bottom: 0px;

    > input {
        border-radius: 10px;
    }
`;

export const StyledSearchInput = styled(Input)`
    margin-top: 10px;

    > input {
        border: 2px solid var(--white-concrete);
    }
`;

export const StyledLoadMore = styled(LoadMore)`
    max-height: 500px;
    overflow: auto;

    > div:first-child {
        display: grid;
        grid-gap: 40px 15px;
        // grid-template-columns: repeat(1, 2fr);
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

        // @media ${device.minwidth.mobile} {
        //     grid-template-columns: repeat(2, 1fr);
        // }

        // @media ${device.minwidth.ipad} {
        //     grid-template-columns: repeat(4, 1fr);
        // }

        // @media ${device.minwidth.laptop} {
        //     grid-template-columns: repeat(7, 1fr);
        // }
    }
`;

export const UserContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;