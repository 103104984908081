import styled from 'styled-components';
import { device } from '../../Config/device';

import Icon from '../../Components/Icon';
import Input from '../../Components/Form/Input';

export const ChangeEmailInputContainer = styled.div``;

export const ProfilePhotoContainer = styled.div`
    margin-right: 5px;
    width: var(--profilephoto-size-small-3);
`;

export const SearchUsersInput = styled(Input)`
    margin-right: 10px;

    @media ${device.maxwidth.mobile} {
        width: 170px;
    }

    @media ${device.minwidth.mobile} {
        width: 260px;
    }

    @media ${device.minwidth.ipad} {
        width: 320px;
    }

    > input {
        border-radius: 10px;
    }
`;

export const ShowChangeEmailContainer = styled.div``;

export const SortingIcon = styled(Icon)`
    width: 15px;
`;

export const UserDetailModalContainer = styled.div``;

export const UserDetailModal_UpdateRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
`;

export const UserDetailModal_UserName = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    > div:first-child {
        margin-right: 5px;
    }
`;

export const UsersContainer = styled.div``;

export const UsersTableContainer = styled.div`
    overflow: scroll;
    max-height: 800px;
`;

export const UsersTable = styled.table`
    border: 1px solid var(--black);
    position: relative;
`;

export const UsersTableBody = styled.tbody`
    tr:nth-child(even) { background: #CCC; }
    tr:nth-child(odd) { background: #FFF; }
`;

export const UsersTableBodyRow = styled.tr`
    @media(hover:hover) {
        :hover {
            background-color: var(--abel-blue-hover) !important;
            color: var(--white) !important;
            cursor: pointer;
        }
    }
`;

export const UsersTableBodyCell = styled.td`
    padding: 5px 7px;
    white-space: nowrap;
`;

export const UsersTableHeader = styled.thead``;

export const UsersTableHeaderRow = styled.tr``;

export const UsersTableHeaderCell = styled.th`
    background-color: #CCC;
    font-family: var(--font-family-semibold);
    padding: 5px 7px;
    position: sticky;
    top: 0;

    white-space: nowrap;
    overflow-y: scroll;
    height: 35px;
    text-align: left;

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const UsersTableHeaderCellInnerDiv = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
`;