import styled from 'styled-components';
import { device } from '../../Config/device';

import PageText from '../Text/PageText';

export const CheckmarkContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    > div:first-child {
        margin-right: 15px;
    }
`;

export const Container = styled.div`
    background-color: ${props => props.BackgroundColor ? props.BackgroundColor : 'var(--white-concrete)'};
    ${props => props.BorderColor ? `border: 2px solid ${props.BorderColor};` : null}
    border-radius: 10px;
    padding: 15px;
`;

export const Flex1 = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-end;

    ${({ MarginLeft, MarginRight }) => (
        MarginLeft ?
            'margin-right: 45px;'
    :
        MarginRight ?
            'margin-right: 45px;'
        :
            null
    )}

    :first-child {
        @media ${device.maxwidth.mobile_small} {
            display: none;
        }
    }
`;

export const DeleteIcon = styled.div`
    align-items: center;
    background-color: var(--red-bittersweet);
    border: 2px solid var(--red-bittersweet);
    border-radius: 50%;
    color: var(--white);
    display: flex;
    font-size: 15px;
    height: 25px;
    justify-content: center;
    line-height: 25px;
    margin-left: 10px;
    width: 25px;

    position: absolute;
    top: 0;
    right: 45px;

    @media ${device.minwidth.ipad} {
        background-color: var(--white-concrete);
        border-color: var(--white-concrete);
        color: var(--white);

        :hover {
            background-color: var(--red-bittersweet);
            border-color: var(--red-bittersweet);
            color: var(--white);
            cursor: pointer;
        }
    }
`;

export const EditIcon = styled.img`
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 25px;
    justify-content: center;
    margin-left: 10px;
    width: 25px;

    position: absolute;
    top: 0;
    right: ${props => props.ShowDelete ? '75px' : '45px'};

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: ${props => props.HasHeader ? 'flex-start' : 'center'};
    position: relative;
`;

export const Icon = styled.div`
    position: absolute;
    top: 0;
    right: 15px;

    > img {
        height: 10px;

        :hover {
            cursor: pointer;
        }
    }
`;

export const SelectAllHeaderContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => (props.HideSelectAll || +props.NumClients === 0) ? '2' : '3'}, 1fr);
    width: calc(100% - 50px);
`;

export const StyledPageTextWithDotAfter = styled(PageText)`
    align-items: center;

    :after {
        background-color: var(--black);
        border-radius: 7px;
        content: '';
        height: 7px;
        margin: 0 7px;
        width: 7px;
    }
`;

export const ThirdColumn = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;