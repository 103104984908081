import {
    REMOVE_PENDING_CONTENT_REQUEST
} from '../Types';

const INITIAL_STATE = {
    NeedsUpdate: '',
    PendingContentRequests: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REMOVE_PENDING_CONTENT_REQUEST:
            var { ContentId, ContentType, NewStatus, PendingContentRequestIndex } = action.payload;

            var NeedsUpdate = '';
            if (NewStatus === 'accept') NeedsUpdate = `${ContentType}-${ContentId}`;

            return { ...state, NeedsUpdate, PendingContentRequests: [ ...state.PendingContentRequests.slice(0, PendingContentRequestIndex), ...state.PendingContentRequests.slice(PendingContentRequestIndex + 1) ] };

        default:
            return state;
    }
};
