import styled from 'styled-components';

export const ButtonContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Disabled ? 'var(--gray)' : props.BackgroundColor ? `var(--${props.BackgroundColor})` : 'var(--abel-blue)'};
    border: ${props => props.Border ? props.Border : 'none'};
    border-radius: 30px;
    color: ${props => props.Disabled ? 'var(--black)' : props.FontColor ? props.FontColor : 'var(--white)'};
    display: flex;
    height: ${props => props.Size ? `var(--button-height-${props.Size})` : 'var(--button-height-large)'};
    justify-content: center;
    padding: 10px 30px;

    @media(hover:hover) {
        :hover {
            background-color: ${props => props.Disabled ? 'var(--dark-gray)' : props.BackgroundColor_Hover ? `var(--${props.BackgroundColor_Hover})` : 'var(--abel-blue-hover)'};
            border: ${props => props.Border_Hover && !props.Disabled ? props.Border_Hover : props.Disabled && props.Border ? props.Border : 'none'};
            cursor: ${props => props.Disabled ? 'not-allowed' : 'pointer'};
            ${({ Disabled, FontColor_Hover }) => FontColor_Hover && !Disabled ? `color: ${FontColor_Hover};` : null}
        }
    }
`;

export const ButtonTitle = styled.div`
    font-family: ${props => props.FontFamily ? `var(--font-family-${props.FontFamily})` : 'var(--font-family-semibold)'};
    font-size: ${props => props.FontSize ? `var(--font-size-${props.FontSize})` : 'var(--font-size-large)'};
`;