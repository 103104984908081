import React from 'react';
import { withTranslation } from 'react-i18next';
import history from '../../../history';

import * as Styles from './styles';

import PageText from '../../../Components/Text/PageText';

// const LibraryTypes = [{ Name: '_exercises', Slug: 'exercises', Type: 'Exercises' }, { Name: '_sessions', Slug: 'workout-templates', Type: 'WorkoutTemplates' }, { Name: '_programs', Slug: 'training-programs', Type: 'TrainingPrograms' }];
const LibraryTypes = {
    'Exercises': { Name: '_exercises', Slug: 'exercises' },
    'WorkoutTemplates': { Name: '_sessions', Slug: 'workout-templates' },
    'TrainingPrograms': { Name: '_programs', Slug: 'training-programs' },
    'Statuses': { Name: 'statuses', Slug: 'statuses' },
    'Tasks': { Name: 'tasks', Slug: 'tasks' }
}

// ({ Name, Slug, Type }, LTIndex)
class Header extends React.Component {
    onChangeLibraryType = LibraryTypeSlug => {
        history.push({ pathname: `/library-workouts`, search: `?tab=${LibraryTypeSlug}&ShowTrash=0&ShowCustomizeFilters=0` });
    }

    onShowTrash = () => {
        history.push({ pathname: `/library-workouts`, search: `?tab=${LibraryTypes[this.props.LibraryType].Slug}&ShowTrash=1` });
    }

    render() {
        var { t } = this.props;
        var { HideTrashLink, LibraryType, ShowTrash } = this.props;

        return (
            <Styles.HeaderContainer className="HeaderContainer">
                <Styles.HeaderLeftContainer className="HeaderLeftContainer">
                    {
                        Object.keys(LibraryTypes).map((key, LTIndex) => {
                            var { Name, Slug } = LibraryTypes[key];

                            return (
                                <React.Fragment key={LTIndex}>
                                    <PageText
                                        FontColor="black"
                                        FontFamily={(!+ShowTrash && LibraryType === key) ? 'bold' : 'medium'}
                                        FontFamilyHover="bold"
                                        FontSize="medium-1"
                                        JustifyContent="flex-start"
                                        NoMargin
                                        OnClick={() => this.onChangeLibraryType(Slug)}
                                        Selectable
                                        SelectedOptions={{ Position: 'left', Selected: (!+ShowTrash && LibraryType === key) }}
                                        Text={t(Name)}
                                        TextAlign="left"
                                    />
                                </React.Fragment>
                            );
                        })
                    }
                </Styles.HeaderLeftContainer>
                
                {
                    !HideTrashLink &&
                    <Styles.HeaderRightContainer>
                        <PageText
                            FontColor="black"
                            FontFamily={!!+ShowTrash ? 'bold' : 'medium'}
                            FontFamilyHover="bold"
                            FontSize="medium-1"
                            JustifyContent="flex-start"
                            NoMargin
                            OnClick={this.onShowTrash}
                            Text={t('_trash')}
                            TextAlign="left"
                        />
                    </Styles.HeaderRightContainer>
                }
            </Styles.HeaderContainer>
        );
    }
}

export default withTranslation()(Header);