const Languages = {
    '1': {
        i18nLocale: 'en',
        longDateFormat: {
            "[NumbersDate]": "MM-DD-YYYY",
            "[TrackingHistory]": "MM-DD-YYYY hh:mm a",
            "[TrackingHistoryTime]": "hh:mm a",
            "[WeeklyViewDayHeader]" : "MMM D",
            "[WeeklyViewHeader]": "MMM DD, YYYY"
        },
        momentLocale: 'en',
        relativeTime : {
            future: "in %s",
            past:   "%s",
            ss: '%ds',
            m:  "1m",
            mm: "%dm",
            h:  "1h",
            hh: "%dh",
            d:  "1d",
            dd: "%dd",
            w:  "1w",
            ww: "%dw",
            M:  "1mn",
            MM: "%dmn",
            y:  "1y",
            yy: "%dy"
        }
    },
    '2': {
        i18nLocale: 'no',
        longDateFormat: {
            "[NumbersDate]": "DD.MM.YYYY",
            "[TrackingHistory]": "DD.MM.YYYY HH:mm",
            "[TrackingHistoryTime]": "HH:mm",
            "[WeeklyViewDayHeader]" : "DD. MMM",
            "[WeeklyViewHeader]": "DD. MMM YYYY"
        },
        momentLocale: 'nb',
        relativeTime : {
                future: "i %s",
                past:   "%s",
                ss: '%ds',
                m:  "1m",
                mm: "%dm",
                h:  "1t",
                hh: "%dt",
                d:  "1d",
                dd: "%dd",
                w:  "1u",
                ww: "%du",
                M:  "1mn",
                MM: "%dmn",
                y:  "1å",
                yy: "%då"
            }
    },
    '3': {
        i18nLocale: 'da',
        longDateFormat: {
            "[NumbersDate]": "DD.MM.YYYY",
            "[TrackingHistory]": "DD.MM.YYYY HH:mm",
            "[TrackingHistoryTime]": "HH:mm",
            "[WeeklyViewDayHeader]" : "DD. MMM",
            "[WeeklyViewHeader]": "DD. MMM YYYY"
        },
        momentLocale: 'da',
        relativeTime : {
                future: "i %s",
                past:   "%s",
                ss: '%ds',
                m:  "1m",
                mm: "%dm",
                h:  "1t",
                hh: "%dt",
                d:  "1d",
                dd: "%dd",
                w:  "1u",
                ww: "%du",
                M:  "1mn",
                MM: "%dmn",
                y:  "1å",
                yy: "%då"
            }
    },
    '4': {
        i18nLocale: 'sv',
        longDateFormat: {
            "[NumbersDate]": "DD.MM.YYYY",
            "[TrackingHistory]": "DD.MM.YYYY HH:mm",
            "[TrackingHistoryTime]": "HH:mm",
            "[WeeklyViewDayHeader]" : "DD. MMM",
            "[WeeklyViewHeader]": "DD. MMM YYYY"
        },
        momentLocale: 'sv',
        relativeTime : {
                future: "i %s",
                past:   "%s",
                ss: '%ds',
                m:  "1m",
                mm: "%dm",
                h:  "1t",
                hh: "%dt",
                d:  "1d",
                dd: "%dd",
                w:  "1u",
                ww: "%du",
                M:  "1mn",
                MM: "%dmn",
                y:  "1å",
                yy: "%då"
            }
    }
}

export default Languages;