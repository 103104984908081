import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { BoxListContainer, GroupHeaderContainer, GroupInfoContainer, GroupInfoSubContainer, StyledGroupName, StyledLink, StyledPageTextWithDotAfter, StyledTeamPicture } from './TrainerGroup_styles.js'
import { Container1, Container2, Container3, Container4, Container5, Container6, Container7, Container8, LoadMoreContainer, ListBodyContainer, ListContainer, ListHeaderContainer } from './TrainerGroup_styles';
import { CompactListContainer } from './TrainerGroup_styles';

import BoxItem from './BoxItem';
import CompactItem from './CompactItem';
import ListItem from './ListItem';

import Accordion from '../../Components/Accordion';
import Button from '../../Components/Buttons/Button';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import ImageUpload from '../../Modals/ImageUpload';

import DefaultGroupImage from '../../Assets/Icons/Group.png';
import DefaultTeamImage from '../../Assets/Icons/Team.png';

import { CreateUploadToPresignedUrlPromise } from '../../Functions';

class TrainerGroup extends React.Component {
    state = { PageNo: 1, ShowEditGroupPicture: false };

    onDeleteGroup = () => {
        this.props.OnDeleteGroup();
    }

    onEditGroup = () => {
        this.props.OnEditGroup();
    }

    onLoadMore = () => {
        this.setState({ PageNo: this.state.PageNo + 1 }, () => {
            this.props.OnLoadGroupMembers(this.state.PageNo);
        });
    }

    onUpdateGroupImage = GroupImage => {
        var GroupImageObj = { ContentType: GroupImage.type, FileName: GroupImage.name, FileSize: GroupImage.size };

        this.props.OnInsertEditGroupImage({ GroupImage, GroupImageObj }).then(({ PreSignedUrls }) => {
            if (PreSignedUrls.length) {
                var UploadFunction = this.props.UploadToPresignedUrl;
                var Promises = PreSignedUrls.map(PreSignedUrl => CreateUploadToPresignedUrlPromise({ ContentType: 'UserGroup', File: GroupImage, PresignedFormData: PreSignedUrl, UploadFunction }));

                Promise.all(Promises).then(() => this.setState({ ShowEditGroupPicture: false }));
            } else this.setState({ ShowEditGroupPicture: false });
        });
    }

    onToggleShowEditGroupPicture = ({ event, ShowEditGroupPicture }) => {
        if (ShowEditGroupPicture) event.stopPropagation();

        this.setState({ ShowEditGroupPicture });
    }

    renderBoxItems = () => {
        var { DeletingClientAccess_UserId, Group, GroupIndex, OnDeleteClient, OnRequestClientAccess, OnToggleShowGetStartedMeals, OnUpdateMealAccess, OnUpdateWorkoutAccess, RequestingClientAccess_UserId, UpdatingAccess_AccessType, UpdatingAccess_ClientIndex, UpdatingAccess_GroupIndex } = this.props;

        return (
            <BoxListContainer className="BoxListContainer">
                {
                    Group.GroupMembers.GroupMembers.map((Client, ClientIndex) => {
                        return (
                            <BoxItem
                                key={ClientIndex}
                                Client={Client}
                                ClientIndex={ClientIndex}
                                ClientType="Groups"
                                DeletingClientAccess_UserId={DeletingClientAccess_UserId}
                                OnDeleteClient={() => OnDeleteClient({ ClientIndex, UserId: Client.UserId })}
                                OnRequestClientAccess={() => OnRequestClientAccess({ Email: Client.Email, UserId: Client.UserId })}
                                OnShowGetStartedMeals={() => OnToggleShowGetStartedMeals({ UserDetails: Client })}
                                OnUpdateMealAccess={({ UpdateType }) => OnUpdateMealAccess({ ClientIndex, UpdateType, UserId: Client.UserId })}
                                OnUpdateWorkoutAccess={({ UpdateType }) => OnUpdateWorkoutAccess({ ClientIndex, UpdateType, UserId: Client.UserId })}
                                RequestingClientAccess_UserId={RequestingClientAccess_UserId}
                                UpdatingAccess_AccessType={UpdatingAccess_GroupIndex === GroupIndex ? UpdatingAccess_AccessType : null}
                                UpdatingAccess_ClientIndex={UpdatingAccess_ClientIndex}
                            />
                        );
                    })
                }
            </BoxListContainer>

        )
    }

    renderCompactItems = () => {
        var { DeletingClientAccess_UserId, Group, GroupIndex, OnDeleteClient, OnRequestClientAccess, OnToggleShowGetStartedMeals, OnUpdateMealAccess, OnUpdateWorkoutAccess, RequestingClientAccess_UserId, UpdatingAccess_AccessType, UpdatingAccess_ClientIndex, UpdatingAccess_GroupIndex } = this.props;

        return (
            <CompactListContainer className="CompactListContainer">
                {Group.GroupMembers.GroupMembers.map((Client, ClientIndex) =>
                    <CompactItem
                        key={ClientIndex}
                        Client={Client}
                        ClientIndex={ClientIndex}
                        ClientType="Groups"
                        DeletingClientAccess_UserId={DeletingClientAccess_UserId}
                        OnDeleteClient={() => OnDeleteClient({ ClientIndex, UserId: Client.UserId })}
                        OnRequestClientAccess={() => OnRequestClientAccess({ Email: Client.Email, UserId: Client.UserId })}
                        OnShowGetStartedMeals={() => OnToggleShowGetStartedMeals({ UserDetails: Client })}
                        OnUpdateMealAccess={({ UpdateType }) => OnUpdateMealAccess({ ClientIndex, UpdateType, UserId: Client.UserId })}
                        OnUpdateWorkoutAccess={({ UpdateType }) => OnUpdateWorkoutAccess({ ClientIndex, UpdateType, UserId: Client.UserId })}
                        RequestingClientAccess_UserId={RequestingClientAccess_UserId}
                        UpdatingAccess_AccessType={UpdatingAccess_GroupIndex === GroupIndex ? UpdatingAccess_AccessType : null}
                        UpdatingAccess_ClientIndex={UpdatingAccess_ClientIndex}
                    />
                )}
            </CompactListContainer>
        );
    }

    renderEditGroupPicture = () => {
        var { Group: { GroupImage }, TryingInsertEditUserGroup, UploadProgress } = this.props;
        var { ShowEditGroupPicture } = this.state;

        if (ShowEditGroupPicture) {
            return (
                <ImageUpload
                    CurrentImage={GroupImage}
                    Loading={TryingInsertEditUserGroup}
                    OnHide={() => this.onToggleShowEditGroupPicture({ ShowEditGroupPicture: false })}
                    OnSaveImage={GroupImage => this.onUpdateGroupImage(GroupImage)}
                    Show={ShowEditGroupPicture}
                    UploadProgress={UploadProgress.UserGroup}
                />
            );
        }
    }

    renderHeader = ({ HeaderData: { GroupId, GroupImage, GroupName, GroupType, NumManagers, NumMembers }, OnToggle }) => {
        var { t } = this.props;

        GroupType = GroupType.toLowerCase();
        var Clickable = GroupType === 'team';

        var GroupTypeText = t(`${GroupType === 'team' ? 'Team' : 'Group'}`);
        
        if (GroupType === 'team') var ManagerTexts = [{ FontFamily: "semibold", FontSize: "medium-1", Text: `${NumManagers}` }, { FontFamily: 'medium', FontSize: 'medium-1', Text: NumManagers === 1 ? t('manager').toLowerCase() : t('managers').toLowerCase() }];
        var MemberTexts = [{ FontFamily: "semibold", FontSize: "medium-1", Text: `${NumMembers}` }, { FontFamily: 'medium', FontSize: 'medium-1', Text: NumMembers === 1 ? t('member').toLowerCase() : t('members').toLowerCase() }];

        var BackupPictureSrc = GroupType === 'team' ? DefaultTeamImage : DefaultGroupImage;

        return (
            <GroupHeaderContainer className="GroupHeaderContainer" Clickable={Clickable} HasPicture={+GroupId !== 0} onClick={OnToggle}>
                {
                    +GroupId !== 0 &&
                    <StyledTeamPicture
                        ImageSrc={GroupImage || BackupPictureSrc}
                        Margin="0 20px 0 0"
                        OnClick={event => this.onToggleShowEditGroupPicture({ event, ShowEditGroupPicture: true })}
                        RoundImage
                        SelfLoading
                        ShowOverlay
                    />
                }                
                <GroupInfoContainer className="GroupInfoContainer">
                    {
                        GroupType === 'team' ?
                        <StyledLink to={`/group/${GroupId}`}>
                            <StyledGroupName FontFamily="semibold" FontSize="medium-4" JustifyContent="flex-start" NoMargin Text={GroupName} TextAlign="left" />
                        </StyledLink>
                    :
                        <StyledGroupName FontFamily="semibold" FontSize="medium-4" JustifyContent="flex-start" NoMargin Text={GroupName} TextAlign="left" />
                    }

                    <GroupInfoSubContainer className="GroupInfoSubContainer">
                        {!!+GroupId && <StyledPageTextWithDotAfter FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={GroupTypeText} TextAlign="left" />}

                        <MultiText Texts={MemberTexts} />

                        {
                            GroupType === 'team' &&
                            <>
                                <StyledPageTextWithDotAfter FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="" TextAlign="left" />

                                <MultiText Texts={ManagerTexts} />
                            </>
                        }
                    </GroupInfoSubContainer>
                </GroupInfoContainer>
            </GroupHeaderContainer>
        );
    }

    renderLoadMore = () => {
        var { t } = this.props;
        var { LoadingGroupMembers } = this.props;

        return (
            <>
                <Spacer Size="medium" />
                
                <LoadMoreContainer className="LoadMoreContainer">
                    <Button
                        BackgroundColor="white"
                        BackgroundColor_Hover="blue-abel"
                        Border="2px solid var(--white-concrete)"
                        Border_Hover="2px solid var(--blue-abel)"
                        Disabled={LoadingGroupMembers}
                        FontColor="black"
                        FontColor_Hover="white"
                        FontFamily="semibold"
                        FontSize="medium-1"
                        OnClick={this.onLoadMore}
                        Size="medium"
                        Title={LoadingGroupMembers ? `${t('loading')}...` : t('load_more')}
                    />
                </LoadMoreContainer>
            </>
        );
    }

    renderListItems = () => {
        var { t } = this.props;
        var { DeletingClientAccess_UserId, Group, GroupIndex, OnDeleteClient, OnRequestClientAccess, OnToggleShowGetStartedMeals, OnUpdateMealAccess, OnUpdateWorkoutAccess, RequestingClientAccess_UserId, UpdatingAccess_AccessType, UpdatingAccess_ClientIndex, UpdatingAccess_GroupIndex } = this.props;

        return (
            <ListContainer className="ListContainer">
                <ListHeaderContainer className="ListHeaderContainer">
                    <Container1 className="Container1">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('Name')} TextAlign="left" />
                    </Container1>

                    <Container2 className="Container2">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('sex')} TextAlign="left" />
                    </Container2>

                    <Container3 className="Container3">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('email_with_first_char_uppercase')} TextAlign="left" />
                    </Container3>

                    <Container4 className="Container4">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('birthday')} TextAlign="left" />
                    </Container4>

                    <Container5 className="Container5">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('Date_Added_Client')} TextAlign="left" />
                    </Container5>

                    <Container6 className="Container6">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('ptadmin_contenttype_header_meals')} TextAlign="left" />
                    </Container6>

                    <Container7 className="Container7">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('ptadmin_contenttype_header_workouts')} TextAlign="left" />
                    </Container7>

                    <Container8 className="Container8" />
                </ListHeaderContainer>
                <ListBodyContainer className="ListBodyContainer">
                    {Group.GroupMembers.GroupMembers.map((Client, ClientIndex) =>
                        <ListItem
                            key={ClientIndex}
                            Client={Client}
                            ClientIndex={ClientIndex}
                            DeletingClientAccess_UserId={DeletingClientAccess_UserId}
                            OnDeleteClient={() => OnDeleteClient({ ClientIndex, UserId: Client.UserId })}
                            OnRequestClientAccess={() => OnRequestClientAccess({ Email: Client.Email, UserId: Client.UserId })}
                            OnShowGetStartedMeals={() => OnToggleShowGetStartedMeals({ UserDetails: Client })}
                            OnUpdateMealAccess={({ UpdateType }) => OnUpdateMealAccess({ ClientIndex, UpdateType, UserId: Client.UserId })}
                            OnUpdateWorkoutAccess={({ UpdateType }) => OnUpdateWorkoutAccess({ ClientIndex, UpdateType, UserId: Client.UserId })}
                            RequestingClientAccess_UserId={RequestingClientAccess_UserId}
                            UpdatingAccess_AccessType={UpdatingAccess_GroupIndex === GroupIndex ? UpdatingAccess_AccessType : null}
                            UpdatingAccess_ClientIndex={UpdatingAccess_ClientIndex}
                        />
                    )}
                </ListBodyContainer>
            </ListContainer>
        );
    }

    render() {
        var { Group, LastGroup, OnEditGroup, UserDetails, ViewType } = this.props;
        var { FounderId, GroupId, GroupImage, GroupMembers: { IsMoreResults }, GroupName, NumManagers, NumMembers, GroupType } = Group;

        return (
            <>
                <Accordion
                    BackgroundColor="var(--white)"
                    BorderColor="var(--white-concrete)"
                    HeaderData={{ GroupId, GroupImage, GroupName, GroupType, NumManagers, NumMembers }}
                    HeaderRenderer={this.renderHeader}
                    HideToggle={!NumMembers}
                    OnDelete={this.onDeleteGroup}
                    OnEdit={OnEditGroup}
                    ShowDelete={GroupId !== 0 && (GroupType.toLowerCase() === 'private' || +FounderId === +UserDetails.UserId)}
                    ShowEdit={GroupId !== 0}
                    Text={GroupName}
                    ToggleOpen={!!+NumMembers}
                >
                    {ViewType === 'Box' && this.renderBoxItems()}
                    {ViewType === 'Compact' && this.renderCompactItems()}
                    {ViewType === 'List' && this.renderListItems()}

                    {!!IsMoreResults && this.renderLoadMore()}
                </Accordion>

                {!LastGroup && <Spacer Size="medium" />}

                {this.renderEditGroupPicture()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        UserDetails: state.Auth.UserDetails,

        UploadProgress: state.UploadContent.UploadProgress,

        TryingGetClientDashboard: state.ClientDashboard.TryingGetClientDashboard,
        
        TryingInsertEditUserGroup: state.Group.TryingInsertEditUserGroup,
    }
};

export default withTranslation()(connect(mapStateToProps, {})(TrainerGroup));