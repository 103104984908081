import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearNutritionPreviewFilters, ViewNutritionPreviewFilters, UpdateNutritionPreviewFilter } from '../../Services/Actions';

import * as styles from './styles';

import AbelModal from '../../Components/Modal';
import Checkmark from '../../Components/Form/Checkmark';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

class NutritionPreviewFiltersModal extends React.Component {
    _isMounted = false;

    state = { Loading: true, SelectedNutrients: [] };

    componentDidMount() {
        this._isMounted = true;

        this.props.ViewNutritionPreviewFilters().then(() => {
            if (this._isMounted) {
                var SelectedNutrients = [].concat.apply([], this.props.NutritionPreviewFilters.map(({ NutrientPreview }) => NutrientPreview.filter(NP => !!+NP.IsSelected).map(({ NutrientId }) => +NutrientId)).filter(Item => !!Item.length));

                this.setState({ Loading: false, SelectedNutrients });
            }
            else this.props.OnHide();
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.ClearNutritionPreviewFilters();
    }

    onSubmit = () => {
        var { SelectedNutrients } = this.state;

        var IsBasic = +!!(SelectedNutrients.indexOf(0) > -1);
        SelectedNutrients = SelectedNutrients.filter(NutrientId => !!+NutrientId).join(',');

        this.props.UpdateNutritionPreviewFilter({ IsBasic, NutrientIds: SelectedNutrients }).then(() => {
            if (!this.props.TryingUpdateNutritionPreviewFiltersError) this.props.OnHide();
        });
    }

    onSelectItem = (event, ItemId, SelectedIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = SelectedIndex === -1;

        ItemId = +ItemId;

        if (checked) this.setState(prevState => ({ SelectedNutrients: [ ...prevState.SelectedNutrients, ...[ ItemId ] ] }));
        else this.setState(prevState => ({ SelectedNutrients: [ ...prevState.SelectedNutrients.slice(0, SelectedIndex), ...prevState.SelectedNutrients.slice(SelectedIndex + 1) ] }));
    }

    renderModalBody = () => {
        if (this.state.Loading || this.props.TryingViewNutritionPreviewFilters) return <Loading />;

        var { t } = this.props;
        var { NutritionPreviewFilters } = this.props;
        var { SelectedNutrients } = this.state;

        return (
            <>
                {this.props.TryingUpdateNutritionPreviewFilters && <Loading />}

                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('nutrition_preview_edit_title')} TextAlign="center" />

                <Spacer Size="medium" />

                {
                    NutritionPreviewFilters.map(({ CategoryName, NutrientPreview }, NutritionCategoryIndex) => {
                        return (
                            <styles.NutritionCategoryContainer className="NutritionCategoryContainer" key={NutritionCategoryIndex}>
                                {
                                    CategoryName &&
                                    <>
                                        <PageText FontFamily="bold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={CategoryName} TextAlign="left" />

                                        <Spacer Size="extra-small" />
                                    </>
                                }

                                <styles.NutrientsContainer className="NutrientsContainer">
                                    {
                                        NutrientPreview.map(({ NutrientId, NutrientName }) => {
                                            var SelectedIndex = SelectedNutrients.indexOf(+NutrientId);

                                            return (
                                                <styles.NutrientContainer className="NutrientContainer" key={+NutrientId}>
                                                    <Checkmark
                                                        ItemId={+NutrientId}
                                                        OnSelectItem={e => this.onSelectItem(e, NutrientId, SelectedIndex)}
                                                        SelectedIndex={SelectedIndex}
                                                        Title={NutrientName}
                                                    />
                                                    <PageText FontColorHover="blue-abel" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectItem(null, NutrientId, SelectedIndex)} Text={NutrientName} TextAlign="left" />
                                                </styles.NutrientContainer>
                                            );
                                        })
                                    }
                                </styles.NutrientsContainer>
                            </styles.NutritionCategoryContainer>
                        );
                    })
                }

                {
                    ((SelectedNutrients.indexOf(0) > -1 && SelectedNutrients.length > 6) || (SelectedNutrients.indexOf(0) === -1 && SelectedNutrients.length > 5)) &&
                    <>
                        <Spacer Size="medium" />

                        <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('nutrition_preview_edit_max_number_error')} TextAlign="center" />
                    </>
                }
            </>
        );
    }

    render() {
        var { OnHide, Show } = this.props;
        var { SelectedNutrients } = this.state;

        return (
            <AbelModal
                BottomButton="submit"
                BottomButtonOnClick={this.onSubmit}
                Disabled={(SelectedNutrients.indexOf(0) > -1 && SelectedNutrients.length > 6) || (SelectedNutrients.indexOf(0) === -1 && SelectedNutrients.length > 5)}
                GA_PathName="NutritionPreviewFiltersModal"
                Show={Show}
                Size="medium"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

NutritionPreviewFiltersModal.propTypes = {
    OnHide: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
    return {
        NutritionPreviewFilters: state.Nutrition.NutritionPreviewFilters,

        TryingUpdateNutritionPreviewFilters: state.Nutrition.TryingUpdateNutritionPreviewFilters,
        TryingUpdateNutritionPreviewFiltersError: state.Nutrition.TryingUpdateNutritionPreviewFiltersError,
        TryingViewNutritionPreviewFilters: state.Nutrition.TryingViewNutritionPreviewFilters,
        TryingViewNutritionPreviewFiltersError: state.Nutrition.TryingViewNutritionPreviewFiltersError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearNutritionPreviewFilters, ViewNutritionPreviewFilters, UpdateNutritionPreviewFilter } )(NutritionPreviewFiltersModal));