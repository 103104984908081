import styled from 'styled-components';
import { device } from '../../Config/device';

export const MainContainer = styled.div`
    border-radius: 10px;
    display: grid;

    ${
        props => !props.ShowCustomizeFilters ?
        `
            @media ${device.minwidth.laptop} {
                grid-template-areas: "header filter"
                                    "body filter";
                grid-template-columns: minmax(0, 1fr) 300px;
            }

            @media ${device.maxwidth.ipad} {
                grid-template-areas: "header" "body";
                grid-template-columns: minmax(0, 1fr);
            }
        `
    :
        `
            grid-template-areas: "header" "body";
            grid-template-columns: minmax(0, 1fr);
        `
    }

    grid-template-rows: 53px minmax(0, 1fr);
    overflow: hidden;
    width: 100%;
`;