import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearWorkoutTemplates, ClearTrainingProgram, ClearSaveData, GetTrainingProgramDetail, InsertEditTrainingProgram, SaveDataInProps, SearchWorkoutTemplates } from '../../Services/Actions';
import history from '../../history';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from 'moment';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { AllFilters, ButtonsContainer, ChooseDishesContainer, ColumnContainer, ColumnTaskList, ColumnTitle, Container, CustomizationContainer, CustomizationItemContainer, CustomizationItemContainerHeader, CustomizationTextInput, CustomInstructionContainer, CustomInstructionsContainer, DeleteContainer, DraggableWorkoutListItem, DraggingWTContainer, JointEquipmentContainer, JointEquipmentItem, WorkoutTemplateContainer, PseudoCheckbox, ReadMoreLessContainer, ReadMoreText, RequiredHeaderContainer, RestDayContainer, RestDayImage, SelectedDishesContainer, SelectedWorkoutTemplates, StyledCalendarIcon, StyledDescriptionTextArea, StyledDishRemoveIcon, StyledDropdown, StyledMultiText, StyledPopover, StyledProfilePhoto, StyledSearchInput, StyledLoadMore, StyledPlusSignIcon, StyledTemplateDays, StyledTemplateName, StyledTemplateNameText, StyledTimePicker, StyledWorkoutNoteTextArea, Template, TemplateDetailContainer, TemplateDaysContainer2, TemplateDaysInnerContainer, TemplateIcon, WorkoutTemplatesContainer } from './styles';

import CalendarOverlay from './CalendarOverlay';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Toggle from '../../Components/Form/Toggle';
import Spacer from '../../Components/Spacer';
import { WorkoutTemplateListItem } from '../../Components/Lists/ListItems';
import WorkoutTemplates from '../Library2/Workout/WorkoutTemplates';

import WorkoutTemplateModal from '../../Modals/WorkoutTemplate';

import AbelIcon from '../../Assets/Icons/Abel_HeaderIcon.png';
import AdditionalActivity from '../../Assets/Icons/AdditionalActivity.png';
import CalendarIcon from '../../Assets/Icons/Calendar.png';
import Delete_X from '../../Assets/Icons/Delete_X.png';
import Endurance from '../../Assets/Icons/Endurance.png';
import Female from '../../Assets/Icons/Female.png';
import Male from '../../Assets/Icons/Male.png';
import Plus from '../../Assets/Icons/PlusSign.png';
import Strength from '../../Assets/Icons/Strength.png';
import WorkoutRestDay from '../../Assets/Illustrations/WorkoutRestDay.png';

import { getNewDays } from '../../Functions';

const CUSTOM = {
    CustomSettings: {
        Location: '', Location_IsCustom: false,
        Name: '', Name_IsCustom: false,
        Note: '', Note_IsCustom: false,
        StartTime: '', StartTime_IsCustom: false
    },
    ShowCustomSettings: false
};

const INITIAL_STATE = {
    LastSearchedValue: '',
    PageNo: 1, PageSize: 48,
    SearchValue: ''
}

const OPACITY = 0.5;

const DaysAndWeeksDaysConversion = ({ NumDays = 0 }) => {
    if (NumDays === 0) return { Days: 0, Weeks: 0 };

    return { Days: NumDays - (7 * (Math.floor(NumDays / 7)) ), Weeks: Math.floor(NumDays / 7) };
}

const MakeCustomSettings = (CustomSettings = {}) => {
    var NewCustomSettings = { ...CUSTOM, CustomSettings: { ...CUSTOM.CustomSettings, ...CustomSettings } };

    if (CustomSettings.hasOwnProperty('Location')) NewCustomSettings.CustomSettings.Location_IsCustom = true;
    if (CustomSettings.hasOwnProperty('Name')) NewCustomSettings.CustomSettings.Name_IsCustom = true;
    if (CustomSettings.hasOwnProperty('Note')) NewCustomSettings.CustomSettings.Note_IsCustom = true;
    if (CustomSettings.hasOwnProperty('StartTime')) NewCustomSettings.CustomSettings.StartTime_IsCustom = true;

    return NewCustomSettings;
}

class WorkoutTemplate extends React.Component {
    shouldComponentUpdate(nextProps) {
        // console.log('WorkoutTemplate');
        // console.log('UniqueId SHOULD UPDATE', `${this.props.DayIndex}-${this.props.WorkoutTemplateIndex}`, nextProps.WorkoutTemplate !== this.props.WorkoutTemplate);
        // console.log('nextProps: ', nextProps.WorkoutTemplate);
        // console.log('this.props: ', this.props.WorkoutTemplate);
        // console.log('----------------------');

        if ((nextProps.WorkoutTemplate !== this.props.WorkoutTemplate) || (nextProps.Days !== this.props.Days) || (nextProps.IsDragging !== this.props.IsDragging) || (nextProps.TemplateDays !== this.props.TemplateDays)) return true;
        return false;
    }
    
    onChangeDateTime = ({ DateTime, ToggleType, UniqueId }) => {
        this.props.onChangeCustomSetting(moment(DateTime).format('HH:mm'), ToggleType, UniqueId);
    }

    onChangeInput = ({ event, ToggleType, UniqueId }) => {
        this.props.onChangeCustomSetting(event.target.value, ToggleType, UniqueId);
    }

    onChangeToggle = ({ event, ToggleType, UniqueId }) => {
        this.props.onChangeCustomSetting(event.target.checked, ToggleType, UniqueId);
    }

    onToggleShowCustomSettings = ({ UniqueId }) => {
        this.props.onChangeCustomSettingDisplay(UniqueId);
    }

    renderDraggableWorkoutListItem = ({ TemplateDays, WorkoutTemplate }) => {
        var { TemplateId, TemplateName } = WorkoutTemplate;
        var { Days } = this.props;

        return (
            <DraggableWorkoutListItem className="DraggableWorkoutListItem">
                {this.renderUserPhoto()}
                
                <OverlayTrigger trigger="click" placement="left" rootClose overlay={
                    <StyledPopover>
                        <CalendarOverlay Days={Days} NumDays={TemplateDays} OnSelectDays={SelectedDays => this.props.OnAddToSelectedDays({ SelectedDays, WorkoutTemplate })} TemplateId={TemplateId} />
                    </StyledPopover>
                }>
                    <StyledCalendarIcon alt="ABEL" src={CalendarIcon} />
                </OverlayTrigger>

                <TemplateDetailContainer className="TemplateDetailContainer">
                    <StyledTemplateNameText FontFamily="semibold" FontSize="small" NoMargin Text={TemplateName} TextAlign="center"/>
                </TemplateDetailContainer>
            </DraggableWorkoutListItem>
        );
    }

    renderUserPhoto = () => {
        var { DisplayName, Sex, UserId, UserPhoto } = this.props.WorkoutTemplate;

        var SexImage = Sex === 'Male' ? Male : Female;

        if (UserId === 16) UserPhoto = AbelIcon;

        return <StyledProfilePhoto Alt={DisplayName} ImageSrc={UserPhoto} IsAbel={UserId === 16} NoImage={SexImage} NoParent />;
    }

    render() {
        var { t } = this.props;
        var UniqueId = this.props.UniqueId || `${this.props.DayIndex}-${this.props.WorkoutTemplateIndex}`;
        var { CustomSettings, ShowCustomSettings } = this.props.WorkoutTemplate.CustomSettings;

        // console.log('UniqueId RENDER: ', UniqueId);
        // console.log('----------------------');

        var StartHour = CustomSettings.StartTime ? CustomSettings.StartTime.split(':')[0] : 0;
        var StartMinutes = CustomSettings.StartTime ? CustomSettings.StartTime.split(':')[1] : 0;

        var TimePickerDate = moment().hour(StartHour).minute(StartMinutes);

        var draggableId = `${this.props.WorkoutTemplate.TemplateId}-${UniqueId}`;

        return (
            <Draggable
                draggableId={draggableId}
                index={this.props.WorkoutTemplateIndex}
            >
                {(provided, snapshot) => (
                    <WorkoutTemplateContainer
                        className="WorkoutTemplateContainer"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        isDragging={snapshot.isDragging}
                    >
                        {
                            !!this.props.ShowCalendar ?
                            this.renderDraggableWorkoutListItem({ TemplateDays: this.props.TemplateDays, WorkoutTemplate: this.props.WorkoutTemplate })
                        :
                            <WorkoutTemplateListItem
                                Item={this.props.WorkoutTemplate}
                                HideSelect
                                IsMultiSelect
                                ViewTemplate={this.props.ViewTemplate}
                            />
                        }

                        {
                            (!this.props.IsDragging || this.props.IsDragging === draggableId) &&
                            <>
                                {
                                    ShowCustomSettings &&
                                    <CustomizationContainer className="CustomizationContainer">
                                        <CustomizationItemContainer className="CustomizationItemContainer">
                                            <CustomizationItemContainerHeader className="CustomizationItemContainerHeader">
                                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('Name')}:`} />

                                                <Toggle
                                                    Checked={!!CustomSettings.Name_IsCustom}
                                                    OffLabel={t('off')}
                                                    OnChange={event => this.onChangeToggle({ event, ToggleType: 'Name_IsCustom', UniqueId })}
                                                    OnLabel={t('on')}
                                                />
                                            </CustomizationItemContainerHeader>
                                            <CustomizationTextInput
                                                FontSize="small"
                                                NoLabel
                                                NoMargin
                                                OnChange={event => this.onChangeInput({ event, ToggleType: 'Name', UniqueId })}
                                                Opacity={!!CustomSettings.Name_IsCustom ? 1 : OPACITY}
                                                Placeholder={t('Name')}
                                                Size="medium"
                                                Type="text"
                                                Value={CustomSettings.Name}
                                            />
                                        </CustomizationItemContainer>

                                        <CustomizationItemContainer className="CustomizationItemContainer">
                                            <CustomizationItemContainerHeader className="CustomizationItemContainerHeader">
                                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('custom_trainer_note')}:`} />

                                                <Toggle
                                                    Checked={!!CustomSettings.Note_IsCustom}
                                                    OffLabel={t('off')}
                                                    OnChange={event => this.onChangeToggle({ event, ToggleType: 'Note_IsCustom', UniqueId })}
                                                    OnLabel={t('on')}
                                                />
                                            </CustomizationItemContainerHeader>
                                            <StyledWorkoutNoteTextArea
                                                OnBlur={event => this.onChangeInput({ event, ToggleType: 'Note', UniqueId })}
                                                NumRows={3}
                                                Opacity={!!CustomSettings.Note_IsCustom ? 1 : OPACITY}
                                                Placeholder={t('WorkoutPlan_Trainer_note_to_client_placeholder')}
                                                Value={CustomSettings.Note}
                                            />
                                        </CustomizationItemContainer>

                                        <CustomizationItemContainer className="CustomizationItemContainer">
                                            <CustomizationItemContainerHeader className="CustomizationItemContainerHeader">
                                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('custom_location')}:`} />

                                                <Toggle
                                                    Checked={!!CustomSettings.Location_IsCustom}
                                                    OffLabel={t('off')}
                                                    OnChange={event => this.onChangeToggle({ event, ToggleType: 'Location_IsCustom', UniqueId })}
                                                    OnLabel={t('on')}
                                                />
                                            </CustomizationItemContainerHeader>
                                            <CustomizationTextInput
                                                FontSize="small"
                                                NoLabel
                                                NoMargin
                                                OnChange={event => this.onChangeInput({ event, ToggleType: 'Location', UniqueId })}
                                                Opacity={!!CustomSettings.Location_IsCustom ? 1 : OPACITY}
                                                Placeholder={t('custom_location')}
                                                Size="medium"
                                                Type="text"
                                                Value={CustomSettings.Location}
                                            />
                                        </CustomizationItemContainer>

                                        <CustomizationItemContainer className="CustomizationItemContainer">
                                            <CustomizationItemContainerHeader className="CustomizationItemContainerHeader">
                                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('custom_starttime')}:`} />

                                                <Toggle
                                                    Checked={!!CustomSettings.StartTime_IsCustom}
                                                    OffLabel={t('off')}
                                                    OnChange={event => this.onChangeToggle({ event, ToggleType: 'StartTime_IsCustom', UniqueId })}
                                                    OnLabel={t('on')}
                                                />
                                            </CustomizationItemContainerHeader>
                                            <StyledTimePicker
                                                className="StyledTimePicker"
                                                defaultValue={TimePickerDate}
                                                minuteStep={5}
                                                onChange={DateTime => this.onChangeDateTime({ DateTime, ToggleType: 'StartTime', UniqueId })}
                                                showSecond={false}
                                                use12Hours={false}
                                                value={TimePickerDate}
                                                Opacity={!!CustomSettings.StartTime_IsCustom ? 1 : OPACITY}
                                            />
                                        </CustomizationItemContainer>
                                    </CustomizationContainer>
                                }

                                <ReadMoreLessContainer className="ReadMoreLessContainer" HasTopPadding={!!ShowCustomSettings}>
                                    <ReadMoreText FontFamily="medium-italic" FontSize="small" NoMargin OnClick={() => this.onToggleShowCustomSettings({ UniqueId })} Text={ShowCustomSettings ? t('hide_customizations') : t('show_customizations')} TextAlign="left" />
                                </ReadMoreLessContainer>

                                {
                                    !this.props.HideRemove &&
                                    <ButtonsContainer className="ButtonsContainer">
                                        <DeleteContainer className="DeleteContainer">
                                            <StyledDishRemoveIcon
                                                ImageSrc={Delete_X}
                                                Loading={false}
                                                OnClick={() => this.props.onRemoveWorkoutTemplate(UniqueId)}
                                            />
    
                                            <PageText FontColor="red-bittersweet" FontColorHover="red-bittersweet-hover" FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.props.onRemoveWorkoutTemplate(UniqueId)} Text={t('remove')} TextAlign="left" />
                                        </DeleteContainer>
                                    </ButtonsContainer>
                                }
                            </>
                        }
                        
                    </WorkoutTemplateContainer>
                )}
            </Draggable>
        )
    }
}

class InnerList extends React.Component {
    shouldComponentUpdate(nextProps) {
        // console.log('InnerList');
        // console.log('nextProps: ', nextProps);
        // console.log('this.props: ', this.props);
        // console.log('----------------------');
        // if (nextProps.DishIds === this.props.DishIds) return false;
        return true;
    }

    render() {
        var { t } = this.props;

        if (!this.props.WorkoutTemplates.length) {
            return (
                <RestDayContainer className="RestDayContainer">
                    <PageText FontColor="white" FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('rest_day').toUpperCase()} />

                    <Spacer Size="small" />

                    <RestDayImage ImageSrc={WorkoutRestDay} SelfLoading />
                </RestDayContainer>
            );
        }

        return (
            this.props.WorkoutTemplates.map((DayWorkoutTemplate, index) => (
                <WorkoutTemplate
                    key={index}
                    t={t}
                    IsDragging={this.props.IsDragging}
                    WorkoutTemplate={DayWorkoutTemplate}
                    DayIndex={this.props.DayIndex}
                    OnAddToSelectedDays={this.props.OnAddToSelectedDays}
                    TemplateDays={this.props.TemplateDays}
                    WorkoutTemplateIndex={index}
                    onChangeCustomSettingDisplay={this.props.onChangeCustomSettingDisplay}
                    onChangeCustomSetting={(e, Setting, UniqueId) => this.props.onChangeCustomSetting(e, Setting, UniqueId)}
                    onRemoveWorkoutTemplate={UniqueId => this.props.onRemoveWorkoutTemplate(UniqueId)}
                    ViewTemplate={this.props.ViewTemplate}
                />
            )
        ))
    }
}

class DayColumn extends React.Component {
    shouldComponentUpdate(nextProps) {
        // console.log('DayColumn');
        // console.log('nextProps: ', nextProps);
        // console.log('this.props: ', this.props);
        // console.log('----------------------');
        // if (nextProps.DishIds === this.props.DishIds) return false;
        return true;
    }

    render() {
        var { t } = this.props;

        var IsRestDay = this.props.WorkoutTemplates.length === 0;

        return (
            <ColumnContainer>
                <ColumnTitle IsRestDay={IsRestDay}>{t('day')} {this.props.DayNumber}</ColumnTitle>
                <Droppable droppableId={this.props.DayId}>
                    {(provided, snapshot) => (
                        <ColumnTaskList
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            isDraggingOver={snapshot.isDraggingOver}
                        >
                            <InnerList
                                t={t}
                                WorkoutTemplates={this.props.WorkoutTemplates}
                                WorkoutTemplateIds={this.props.WorkoutTemplateIds}
                                DayIndex={this.props.DayIndex}
                                TemplateDays={this.props.TemplateDays}
                                OnAddToSelectedDays={this.props.OnAddToSelectedDays}
                                IsDragging={this.props.IsDragging}
                                onChangeCustomSettingDisplay={this.props.onChangeCustomSettingDisplay}
                                onChangeCustomSetting={(e, Setting, UniqueId) => this.props.onChangeCustomSetting(e, Setting, UniqueId)}
                                onRemoveWorkoutTemplate={UniqueId => this.props.onRemoveWorkoutTemplate(UniqueId)}
                                ViewTemplate={this.props.ViewTemplate}
                            />
                            {provided.placeholder}
                        </ColumnTaskList>
                    )}
                </Droppable>
            </ColumnContainer>
        );
    }
}

class TrainingProgramComponent extends React.Component {
    _isMounted = false;
    _TooManyDaysTimeout = 0;

    state = {
        ...INITIAL_STATE,
        ActiveSearch: false,
        CompletedFirstSearch: false,
        CopiedTrainingProgramId: null,
        DayIds: [],
        Days: [],
        DescriptionIsValidLength: true,
        DisplayingFilter: null,
        Injuries: [],
        IsDragging: false,
        Loading: true,
        NewSearch: false,
        NumDays: { Days: '', Weeks: '' },
        SelectedDates: [],
        SelectedWorkoutTemplateIdsIndex: [], // Used in AddDishModal so that templates turn blue when selected
        SelectedWorkoutTemplatesIndex: [], // Used for indexOf SelectedWorkoutTemplates
        SelectedWorkoutTemplates: {}, // The list of templates to show in the Selected Templates area
        SelectedUserIds: [],
        SelectedUsers: [],
        ShowTemplateDetailModal: false,
        Tags: [],
        TemplateDetail_Preview: {},
        TemplateDays: null,
        TooManyDays: 0,
        TrainingProgramId: null,
        TrainingFocus: [],
        ProgramDescription: '',
        ProgramName: '',

        WorkoutTypes: []
    };

    componentDidMount() {
        this._isMounted = true;

        var { TrainingProgramId } = this.props.match.params;
        TrainingProgramId = +TrainingProgramId || null;

        this.props.GetTrainingProgramDetail({ TrainingProgramId }).then(() => {
            if (!Number.isInteger(TrainingProgramId)) {
                var { CopyTrainingProgramDetails } = this.props;
    
                if (Object.entries(CopyTrainingProgramDetails).length) {
                    var { CopiedTrainingProgramId, ProgramDetails } = CopyTrainingProgramDetails;
    
                    var { Days, Injuries, NumDays: TemplateDays, ProgramDescription, ProgramName, TrainingFocus } = ProgramDetails.TrainingProgram;
    
                        var NewDayIds = [];
                        var NewDays = [];
    
                        var DayId;
                        for (var i = 0; i < Days.length; i++) {
                            DayId = `Day-${Days[i].DayNr}`;
    
                            NewDayIds.push(DayId);
                            NewDays.push({ DayId, WorkoutTemplateIds: [], WorkoutTemplates: [] });
    
                            for (var a = 0; a < Days[i].Workouts.length; a++) {
                                var { CustomSettings, TemplateDetails: { Location, OwnerDetails, StartTime, TemplateName, TrainerNote, Units, WorkoutType }, TemplateId } = Days[i].Workouts[a];
    
                                CustomSettings = MakeCustomSettings(CustomSettings);
    
                                NewDays[NewDays.length - 1].WorkoutTemplateIds.push(`TemplateId-${TemplateId}`);
                                NewDays[NewDays.length - 1].WorkoutTemplates.push({ CustomSettings, TemplateDetails: { Location, StartTime, TemplateName, TrainerNote }, TemplateId, TemplateName, Units, ...OwnerDetails, WorkoutType });
                            }
                        }
    
                        var { Days: CalculatedDays, Weeks: CalculatedWeeks } = DaysAndWeeksDaysConversion({ NumDays: TemplateDays });
    
                        this.setState({ CopiedTrainingProgramId, DayIds: NewDayIds, Days: NewDays, Injuries, NumDays: { Days: CalculatedDays, Weeks: CalculatedWeeks }, TemplateDays, TrainingProgramId, ProgramDescription, ProgramName, TrainingFocus, Loading: false }, () => this.onLoadWorkoutTemplates());
                } else this.setState({ Loading: false }, () => this.onLoadWorkoutTemplates());
            }
            else {
                if (!this.props.TryingGetTrainingProgramDetailError) {
                    // eslint-disable-next-line
                    var { Days, Injuries, NumDays: TemplateDays, ProgramDescription, ProgramName, Tags, TrainingFocus } = this.props.TrainingProgram.TrainingProgram;
                    
                    // eslint-disable-next-line
                    var NewDayIds = [];
                    // eslint-disable-next-line
                    var NewDays = [];

                    // eslint-disable-next-line
                    var DayId;
                    // eslint-disable-next-line
                    for (var i = 0; i < Days.length; i++) {
                        DayId = `Day-${Days[i].DayNr}`;

                        NewDayIds.push(DayId);
                        NewDays.push({ DayId, WorkoutTemplateIds: [], WorkoutTemplates: [] });

                        // eslint-disable-next-line
                        for (var a = 0; a < Days[i].Workouts.length; a++) {
                            // eslint-disable-next-line
                            var { CustomSettings, TemplateDetails: { Location, OwnerDetails, StartTime, TemplateName, TrainerNote, Units, WorkoutType }, TemplateId } = Days[i].Workouts[a];

                            CustomSettings = MakeCustomSettings(CustomSettings);

                            NewDays[NewDays.length - 1].WorkoutTemplateIds.push(`TemplateId-${TemplateId}`);
                            NewDays[NewDays.length - 1].WorkoutTemplates.push({ CustomSettings, TemplateDetails: { Location, StartTime, TemplateName, TrainerNote }, TemplateId, TemplateName, Units, ...OwnerDetails, WorkoutType });
                        }
                    }

                    // eslint-disable-next-line
                    var { Days: CalculatedDays, Weeks: CalculatedWeeks } = DaysAndWeeksDaysConversion({ NumDays: TemplateDays });

                    this.setState({ DayIds: NewDayIds, Days: NewDays, Injuries, NumDays: { Days: CalculatedDays, Weeks: CalculatedWeeks }, TemplateDays, TrainingProgramId, ProgramDescription, ProgramName, Tags, TrainingFocus, Loading: false }, () => this.onLoadWorkoutTemplates());
                } else this.setState({ Loading: false }, () => this.onLoadWorkoutTemplates());
            }
        })
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearWorkoutTemplates();
        if (this.state.TrainingProgramId) this.props.ClearTrainingProgram();

        this.props.ClearSaveData();
    }

    onAddToSelectedDays = ({ SelectedDays, WorkoutTemplate }) => {
        document.body.click();

        var { TemplateId } = WorkoutTemplate;

        var NewWorkoutTemplateDetail = { ...WorkoutTemplate, TemplateId: `TemplateId-${TemplateId}`};
        // NewWorkoutTemplateDetail.CustomSettings = MakeCustomSettings();
        NewWorkoutTemplateDetail.TemplateDetails = { Location: WorkoutTemplate.Location, StartTime: WorkoutTemplate.StartTime, TemplateName: WorkoutTemplate.TemplateName, TrainerNote: WorkoutTemplate.TrainerNote };

        var NewDays = [ ...this.state.Days ];

        for (var i = 0; i < SelectedDays.length; i++) {
            var DayNr = SelectedDays[i];

            NewDays[DayNr - 1].WorkoutTemplateIds = [ ...NewDays[DayNr - 1].WorkoutTemplateIds, `TemplateId-${TemplateId}` ];
            NewDays[DayNr - 1].WorkoutTemplates = [ ...NewDays[DayNr - 1].WorkoutTemplates, NewWorkoutTemplateDetail ];
        }

        this.setState({ Days: NewDays });
    }

    onBlurTemplateDays = () => {
        var { Days, Weeks } = this.state.NumDays;

        var TotalDays = (+Weeks * 7) + +Days;

        if (+TotalDays > 84) {
            this.setState({ NumDays: { Days: 0, Weeks: 12 }, TooManyDays: TotalDays }, () => {
                this.onChangeTemplateDays(84);

                if (this._TooManyDaysTimeout) clearTimeout(this._TooManyDaysTimeout);
        
                this._TooManyDaysTimeout = setTimeout(() => this.setState({ TooManyDays: 0 }), 2000);
            });
        }
        else if (Days > 7) {
            var { Days: CalculatedDays, Weeks: CalculatedWeeks } = DaysAndWeeksDaysConversion({ NumDays: TotalDays });
            this.setState({ NumDays: { Days: CalculatedDays, Weeks: CalculatedWeeks } }, () => this.onChangeTemplateDays(TotalDays));
        }
        else this.setState({ NumDays: { Days: +Days, Weeks: +Weeks } }, () => this.onChangeTemplateDays(TotalDays));
    }

    onChangeCustomSettingDisplay = UniqueId => {
        // UniqueId = DayIndex-WorkoutTemplateIndex
        var DayIndex = +(UniqueId.split('-')[0]);
        var WorkoutTemplateIndex = +(UniqueId.split('-')[1]);

        this.setState({
            Days: this.state.Days.map((Day, DI) => {
                if (DI !== DayIndex) return Day;
                return {
                    ...Day,
                    WorkoutTemplates: Day.WorkoutTemplates.map((WT, WI) => {
                        if (WI !== WorkoutTemplateIndex) return WT;

                        return { ...WT, CustomSettings: { ...WT.CustomSettings, ShowCustomSettings: !WT.CustomSettings.ShowCustomSettings } }
                    })
                }
            })
        });
    }

    onChangeCustomSettingDisplay_SelectedTemplates = WorkoutTemplateId => {
        var ObjectKey = `TemplateId-${WorkoutTemplateId}`;
        var NewSelectedWorkoutTemplates = { ...this.state.SelectedWorkoutTemplates };
        NewSelectedWorkoutTemplates[ObjectKey] = { ...NewSelectedWorkoutTemplates[ObjectKey], CustomSettings: { ...NewSelectedWorkoutTemplates[ObjectKey].CustomSettings, ShowCustomSettings: !NewSelectedWorkoutTemplates[ObjectKey].CustomSettings.ShowCustomSettings } };

        this.setState({ SelectedWorkoutTemplates: NewSelectedWorkoutTemplates });
    }

    onChangeCustomSetting = (Value, SettingName, UniqueId) => {
        // UniqueId = DayIndex-WorkoutTemplateIndex
        var DayIndex = +(UniqueId.split('-')[0]);
        var WorkoutTemplateIndex = +(UniqueId.split('-')[1]);

        this.setState({
            Days: this.state.Days.map((Day, DI) => {
                if (DI !== DayIndex) return Day;
                return {
                    ...Day,
                    WorkoutTemplates: Day.WorkoutTemplates.map((WT, WI) => {
                        if (WI !== WorkoutTemplateIndex) return WT;

                        // If Setting Is Not IsCustom, And Setting Was Empty Before AND IsCustom = true, Set IsCustom = False So That It Changes New
                            if (Value !== '' && SettingName.indexOf('IsCustom') === -1 && WT.CustomSettings.CustomSettings[SettingName] === '' && !WT.CustomSettings.CustomSettings[`${SettingName}_IsCustom`]) {
                                return { ...WT, CustomSettings: { ...WT.CustomSettings, CustomSettings: { ...WT.CustomSettings.CustomSettings, [SettingName]: Value, [`${SettingName}_IsCustom`]: true } } };
                            }

                        // If Setting Is "Name" and is now empty and IsCustom = false, set IsCustom = true as name is required
                            if (Value === '' && SettingName === 'Name' && WT.CustomSettings.CustomSettings[`${SettingName}_IsCustom`]) {
                                return { ...WT, CustomSettings: { ...WT.CustomSettings, CustomSettings: { ...WT.CustomSettings.CustomSettings, [SettingName]: Value, [`${SettingName}_IsCustom`]: false } } };
                            }

                        return { ...WT, CustomSettings: { ...WT.CustomSettings, CustomSettings: { ...WT.CustomSettings.CustomSettings, [SettingName]: Value } } };
                    })
                }
            })
        });
    }

    onChangeCustomSetting_SelectedTemplates = (Value, SettingName, WorkoutTemplateId) => {
        var ObjectKey = `TemplateId-${WorkoutTemplateId}`;
        var NewSelectedWorkoutTemplates = { ...this.state.SelectedWorkoutTemplates };

        // If Setting Is Not IsCustom, And Setting Was Empty Before AND IsCustom = true, Set IsCustom = False So That It Changes New
            if (Value !== '' && SettingName.indexOf('IsCustom') === -1 && NewSelectedWorkoutTemplates[ObjectKey].CustomSettings.CustomSettings[SettingName] === '' && !NewSelectedWorkoutTemplates[ObjectKey].CustomSettings.CustomSettings[`${SettingName}_IsCustom`]) {
                NewSelectedWorkoutTemplates[ObjectKey] = { ...NewSelectedWorkoutTemplates[ObjectKey], CustomSettings: { ...NewSelectedWorkoutTemplates[ObjectKey].CustomSettings, CustomSettings: { ...NewSelectedWorkoutTemplates[ObjectKey].CustomSettings.CustomSettings, [SettingName]: Value, [`${SettingName}_IsCustom`]: true } } };
            }

        // If Setting Is "Name" and is now empty and IsCustom = false, set IsCustom = true as name is required
            else if (Value === '' && SettingName === 'Name' && NewSelectedWorkoutTemplates[ObjectKey].CustomSettings.CustomSettings[`${SettingName}_IsCustom`]) {
                NewSelectedWorkoutTemplates[ObjectKey] = { ...NewSelectedWorkoutTemplates[ObjectKey], CustomSettings: { ...NewSelectedWorkoutTemplates[ObjectKey].CustomSettings, CustomSettings: { ...NewSelectedWorkoutTemplates[ObjectKey].CustomSettings.CustomSettings, [SettingName]: Value, [`${SettingName}_IsCustom`]: false } } };
            }

            else NewSelectedWorkoutTemplates[ObjectKey] = { ...NewSelectedWorkoutTemplates[ObjectKey], CustomSettings: { ...NewSelectedWorkoutTemplates[ObjectKey].CustomSettings, CustomSettings: { ...NewSelectedWorkoutTemplates[ObjectKey].CustomSettings.CustomSettings, [SettingName]: Value } } };

        this.setState({ SelectedWorkoutTemplates: NewSelectedWorkoutTemplates });
    }

    onChangeSearch = event => {
        this.setState({ SearchValue: event.target.value });
    }

    onChangeTemplateDays = TemplateDays => {
        var { DayIds, Days } = this.state;

        var { NewDays, NewDayIds } = getNewDays(DayIds, Days, TemplateDays, 'WorkoutTemplateIds', 'WorkoutTemplates');

        this.setState({ Days: NewDays, DayIds: NewDayIds, TemplateDays: +TemplateDays });
    }

    onChangeTemplateDaysInput = ({ event, Type }) => {
        var NewValue = event.target.value;

        this.setState({ NumDays: { ...this.state.NumDays, [Type]: NewValue } });
    }

    onChangeProgramDescription = event => {
        this.setState({ ProgramDescription: event.target.value });
    }

    onChangeProgramDescription_MaxChangeValidation = DescriptionIsValidLength => {
        this.setState({ DescriptionIsValidLength });
    }

    onChangeProgramName = event => {
        this.setState({ ProgramName: event.target.value });
    }

    onDragEnd = result => {
        // this.setState({ IsDragging: null });
        var { destination, source, draggableId } = result;

        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        draggableId = draggableId.split('-')[1];
        var NewWorkoutTemplateId = `TemplateId-${draggableId}`;

        var NewWorkoutTemplateDetail;

        var StartDayIndex = this.state.DayIds.indexOf(source.droppableId);
        var FinishDayIndex = this.state.DayIds.indexOf(destination.droppableId);

        var StartDay = this.state.Days[StartDayIndex];
        var FinishDay = this.state.Days[FinishDayIndex];

        var NewDays, FinishDay_WorkoutTemplateIds, FinishDay_WorkoutTemplates, NewFinishDay;

        if (source.droppableId === 'SelectedWorkoutTemplates') {
            var WorkoutTemplate = this.state.SelectedWorkoutTemplates[NewWorkoutTemplateId];
            NewWorkoutTemplateDetail = WorkoutTemplate;

            // NewWorkoutTemplateDetail.CustomSettings = MakeCustomSettings();
            NewWorkoutTemplateDetail.TemplateDetails = { Location: WorkoutTemplate.Location, StartTime: WorkoutTemplate.StartTime, TemplateName: WorkoutTemplate.TemplateName, TrainerNote: WorkoutTemplate.TrainerNote };

            FinishDay_WorkoutTemplateIds = Array.from(FinishDay.WorkoutTemplateIds);
            FinishDay_WorkoutTemplateIds.splice(destination.index, 0, NewWorkoutTemplateId);

            FinishDay_WorkoutTemplates = Array.from(FinishDay.WorkoutTemplates);
            FinishDay_WorkoutTemplates.splice(destination.index, 0, NewWorkoutTemplateDetail);

            NewFinishDay = { ...FinishDay, WorkoutTemplateIds: FinishDay_WorkoutTemplateIds, WorkoutTemplates: FinishDay_WorkoutTemplates };

            NewDays = [ ...this.state.Days.slice(0, FinishDayIndex), NewFinishDay, ...this.state.Days.slice(FinishDayIndex + 1) ];

            this.setState({ Days: NewDays });
        } else {
            // eslint-disable-next-line
            var WorkoutTemplate = StartDay.WorkoutTemplates[source.index];
            NewWorkoutTemplateDetail = WorkoutTemplate;

            if (StartDayIndex === FinishDayIndex) {
                var SameDay_WorkoutTemplateIds = Array.from(StartDay.WorkoutTemplateIds);
                SameDay_WorkoutTemplateIds.splice(source.index, 1);
                SameDay_WorkoutTemplateIds.splice(destination.index, 0, NewWorkoutTemplateId);

                var SameDay_WorkoutTemplates = Array.from(StartDay.WorkoutTemplates);
                SameDay_WorkoutTemplates.splice(source.index, 1);
                SameDay_WorkoutTemplates.splice(destination.index, 0, NewWorkoutTemplateDetail);
                
                var NewSameDay = { ...StartDay, WorkoutTemplateIds: SameDay_WorkoutTemplateIds, WorkoutTemplates: SameDay_WorkoutTemplates };

                NewDays = [ ...this.state.Days.slice(0, StartDayIndex), NewSameDay, ...this.state.Days.slice(StartDayIndex + 1) ];

                this.setState({ Days: NewDays });
                return;
            }

            var StartDay_WorkoutTemplateIds = Array.from(StartDay.WorkoutTemplateIds);
            StartDay_WorkoutTemplateIds.splice(source.index, 1);

            var StartDay_WorkoutTemplates = Array.from(StartDay.WorkoutTemplates);
            StartDay_WorkoutTemplates.splice(source.index, 1);

            var NewStartDay = { ...StartDay, WorkoutTemplateIds: StartDay_WorkoutTemplateIds, WorkoutTemplates: StartDay_WorkoutTemplates };

            NewDays = [ ...this.state.Days.slice(0, StartDayIndex), NewStartDay, ...this.state.Days.slice(StartDayIndex + 1) ];

            FinishDay_WorkoutTemplateIds = Array.from(FinishDay.WorkoutTemplateIds);
            FinishDay_WorkoutTemplateIds.splice(destination.index, 0, NewWorkoutTemplateId);

            FinishDay_WorkoutTemplates = Array.from(FinishDay.WorkoutTemplates);
            FinishDay_WorkoutTemplates.splice(destination.index, 0, NewWorkoutTemplateDetail);

            NewFinishDay = { ...FinishDay, WorkoutTemplateIds: FinishDay_WorkoutTemplateIds, WorkoutTemplates: FinishDay_WorkoutTemplates };

            NewDays = [ ...NewDays.slice(0, FinishDayIndex), NewFinishDay, ...NewDays.slice(FinishDayIndex + 1) ];

            this.setState({ Days: NewDays });
        }
    }

    onInsertEditTrainingProgram = () => {
        var { CopiedTrainingProgramId, Days, Injuries, ProgramDescription, ProgramName, Tags, TemplateDays, TrainingFocus, TrainingProgramId } = this.state;
        // var { Days } = this.state;

        var NewCustomSettings;
        var NewDays = [];
        var WorkoutObj = {};
        for (var i = 0; i < Days.length; i++) {
            var Obj = { DayNr: (i + 1), Workouts: [] };

            for (var a = 0; a < Days[i].WorkoutTemplates.length; a++) {
                NewCustomSettings = {};
                var TemplateId = `${Days[i].WorkoutTemplates[a].TemplateId}`.indexOf('TemplateId-') > -1 ? +Days[i].WorkoutTemplates[a].TemplateId.split('TemplateId-')[1] : +Days[i].WorkoutTemplates[a].TemplateId;
                WorkoutObj = { WorkoutSource: 'Template', TemplateId };

                var { CustomSettings } = Days[i].WorkoutTemplates[a].CustomSettings;

                if (CustomSettings.Location_IsCustom) NewCustomSettings.Location = CustomSettings.Location;
                // Name cannot be empty
                if (CustomSettings.Name_IsCustom && CustomSettings.Name) NewCustomSettings.Name = CustomSettings.Name;
                if (CustomSettings.Note_IsCustom) NewCustomSettings.Note = CustomSettings.Note;
                if (CustomSettings.StartTime_IsCustom) NewCustomSettings.StartTime = CustomSettings.StartTime;

                if (Object.keys(NewCustomSettings).length !== 0 && NewCustomSettings.constructor === Object) WorkoutObj.CustomSettings = NewCustomSettings;

                Obj.Workouts.push(WorkoutObj);
            }

            NewDays.push(Obj);
        }

        Injuries = Injuries.join(',');
        Tags = Tags.join(',');
        TrainingFocus = TrainingFocus.join(',');

        this.props.InsertEditTrainingProgram({ CopiedTrainingProgramId, Days: NewDays, Injuries, NumDays: TemplateDays, ProgramDescription, ProgramName, Tags, TrainingFocus, TrainingProgramId: +TrainingProgramId }).then(() => {
            if (!this.props.TryingInsertEditTrainingProgramError) history.push({ pathname: `/library-workouts`, search: '?tab=training-programs' });
        });
    }

    onKeyPress = ({ key }) => {
        if (key === 'Enter' && !this.props.TryingSearchWorkoutTemplates) {
            var { SearchValue, LastSearchValue } = this.state;

            if (SearchValue !== LastSearchValue) {
                this.setState({ PageNo: 1, PageSize: 48, LastSearchValue: SearchValue }, () => {
                    this.onLoadWorkoutTemplates();
                });
            }
        }
    }

    onLoadWorkoutTemplates = Increment => {
        var { NewSearch, PageNo, PageSize, SearchValue, WorkoutTypes } = this.state;

        if (Increment) { NewSearch = false; PageNo = PageNo + 1; }
        else { NewSearch = true; PageNo = 1; }

        WorkoutTypes = WorkoutTypes.join(',');

        var SharedType = 'active';

        this.setState({ ActiveSearch: true, NewSearch, PageNo }, () => this.props.SearchWorkoutTemplates({ AddToExisting: Increment, TemplateName: SearchValue, PageNo, PageSize, SharedType, WorkoutType: WorkoutTypes }).then(() => this._isMounted ? this.setState({ ActiveSearch: false, CompletedFirstSearch: true, Loading: false }) : null));
    }

    onRemoveWorkoutTemplate = UniqueId => {
        // UniqueId = DayIndex-WorkoutTemplateIndex
        var DayIndex = UniqueId.split('-')[0];
        var WorkoutTemplateIndex = UniqueId.split('-')[1];

        var RemoveWorkoutTemplate = this.state.Days[DayIndex];

        var RemoveWorkoutTemplate_WorkoutTemplateIds = Array.from(RemoveWorkoutTemplate.WorkoutTemplateIds);
        RemoveWorkoutTemplate_WorkoutTemplateIds.splice(WorkoutTemplateIndex, 1);

        var RemoveWorkoutTemplate_WorkoutTemplates = Array.from(RemoveWorkoutTemplate.WorkoutTemplates);
        RemoveWorkoutTemplate_WorkoutTemplates.splice(WorkoutTemplateIndex, 1);

        var NewRemoveWorkoutTemplate_Day = { ...RemoveWorkoutTemplate, WorkoutTemplateIds: RemoveWorkoutTemplate_WorkoutTemplateIds, WorkoutTemplates: RemoveWorkoutTemplate_WorkoutTemplates };

        var RemoveWorkoutTemplate_Days = Array.from(this.state.Days);
        RemoveWorkoutTemplate_Days.splice(DayIndex, 1, NewRemoveWorkoutTemplate_Day);

        this.setState({ Days: RemoveWorkoutTemplate_Days });
    }

    onSelectInjury = ({ InjuryId, InjuryIndex }) => {
        if (InjuryIndex === -1) this.setState({ Injuries: [ ...this.state.Injuries, +InjuryId ] });
        else this.setState({ Injuries: [ ...this.state.Injuries.slice(0, InjuryIndex), ...this.state.Injuries.slice(InjuryIndex + 1) ] });
    }

    onSelectTag = ({ TagId, TagIndex }) => {
        if (TagIndex === -1) this.setState({ Tags: [ ...this.state.Tags, +TagId ] });
        else this.setState({ Tags: [ ...this.state.Tags.slice(0, TagIndex), ...this.state.Tags.slice(TagIndex + 1) ] });
    }

    onSelectTemplate = WorkoutTemplate => {
        var TemplateId = +WorkoutTemplate.TemplateId;

        WorkoutTemplate.CustomSettings = MakeCustomSettings();

        var SelectedWorkoutTemplateId = `TemplateId-${TemplateId}`;
        var SelectedWorkoutTemplatesIndex_Index = this.state.SelectedWorkoutTemplatesIndex.indexOf(SelectedWorkoutTemplateId);

        var NewSelectedWorkoutTemplates = { ...this.state.SelectedWorkoutTemplates };
        var NewSelectedWorkoutTemplatesIndex = [ ...this.state.SelectedWorkoutTemplatesIndex ];
        var NewSelectedWorkoutTemplateIdsIndex = [ ...this.state.SelectedWorkoutTemplateIdsIndex ];

        // Remove Template From Selected Templates
            if (SelectedWorkoutTemplatesIndex_Index > -1) {
                delete NewSelectedWorkoutTemplates[SelectedWorkoutTemplateId];
                NewSelectedWorkoutTemplatesIndex = [ ...this.state.SelectedWorkoutTemplatesIndex.slice(0, SelectedWorkoutTemplatesIndex_Index), ...this.state.SelectedWorkoutTemplatesIndex.slice(SelectedWorkoutTemplatesIndex_Index + 1) ]
                NewSelectedWorkoutTemplateIdsIndex = [ ...this.state.SelectedWorkoutTemplateIdsIndex.slice(0, SelectedWorkoutTemplatesIndex_Index), ...this.state.SelectedWorkoutTemplateIdsIndex.slice(SelectedWorkoutTemplatesIndex_Index + 1) ]
            } else {
                NewSelectedWorkoutTemplates = { ...NewSelectedWorkoutTemplates, [SelectedWorkoutTemplateId]: WorkoutTemplate };
                NewSelectedWorkoutTemplatesIndex = [ ...NewSelectedWorkoutTemplatesIndex, SelectedWorkoutTemplateId ];
                NewSelectedWorkoutTemplateIdsIndex = [ ...NewSelectedWorkoutTemplateIdsIndex, TemplateId ];
            }

        this.setState({ SelectedWorkoutTemplates: NewSelectedWorkoutTemplates, SelectedWorkoutTemplatesIndex: NewSelectedWorkoutTemplatesIndex, SelectedWorkoutTemplateIdsIndex: NewSelectedWorkoutTemplateIdsIndex });
    }

    onSelectTrainingFocus = ({ TrainingFocusId, TrainingFocusIndex }) => {
        if (TrainingFocusIndex === -1) this.setState({ TrainingFocus: [ ...this.state.TrainingFocus, +TrainingFocusId ] });
        else this.setState({ TrainingFocus: [ ...this.state.TrainingFocus.slice(0, TrainingFocusIndex), ...this.state.TrainingFocus.slice(TrainingFocusIndex + 1) ] });
    }

    onSelectWorkoutType = (event, WorkoutTypeId, WorkoutTypeIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = WorkoutTypeIndex === -1;

        if (checked) { this.setState({ WorkoutTypes: [ ...this.state.WorkoutTypes, WorkoutTypeId ] }, () => this.onLoadWorkoutTemplates()); }
        else {
            this.setState((prevState) => {
                return {
                    WorkoutTypes: [ ...prevState.WorkoutTypes.slice(0, WorkoutTypeIndex), ...prevState.WorkoutTypes.slice(WorkoutTypeIndex + 1) ],
                }
            }, () => this.onLoadWorkoutTemplates());
        }
    }

    onToggleShowTemplateDetailModal = (ShowTemplateDetailModal, TemplateDetail_Preview = {}, TemplateId) => {
        console.log('ShowTemplateDetailModal: ', ShowTemplateDetailModal);
        console.log('TemplateDetail_Preview: ', TemplateDetail_Preview);
        console.log('TemplateId: ', TemplateId);
        if (!!ShowTemplateDetailModal) {
            if (`${TemplateDetail_Preview.TemplateId}` !== `${+TemplateDetail_Preview.TemplateId}`) TemplateDetail_Preview.TemplateId = +TemplateDetail_Preview.TemplateId.split('TemplateId-')[1];
            else TemplateDetail_Preview.TemplateId = +TemplateDetail_Preview.TemplateId;
        }
        
        this.setState({ ShowTemplateDetailModal, TemplateDetail_Preview });
    }

    renderAddWorkoutTemplates = () => {
        var { t } = this.props;

        return (
            <>
                <RequiredHeaderContainer>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-3" Text={t('ptadmin_templates_header_workouts')} />
                    <PageText ElementType="span" FontColor="red-bittersweet" FontFamily="medium" FontSize="medium-3" Text=" *" />
                </RequiredHeaderContainer>

                {/* {this.renderFilters()}

                <Spacer Size="small" />

                {this.renderWorkoutTemplatesList()} */}

                <WorkoutTemplatesContainer className="WorkoutTemplatesContainer">
                    <WorkoutTemplates
                        IsModal
                        OnSelectTemplate={this.onSelectTemplate}
                        OnShowTemplateDetail={() => null}
                        // OnShowTemplateDetail={this.onToggleShowTemplateDetailModal}
                        // OnShowTemplateDetail={this.onToggleShowAddWorkoutTemplateToProgramModal_DisplayNone}
                        FromAddWorkouts
                        SelectedItemIds={this.state.SelectedWorkoutTemplateIdsIndex}
                        WorkoutTypes={[]}
                    />
                </WorkoutTemplatesContainer>

                <Spacer Size="small" />

                {this.renderTemplate()}
            </>
        );
    }

    renderCustomInstructions = () => {
        var { t } = this.props;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('custom_training_program_instructions_title')} TextAlign="center" />

                <Spacer Size="extra-extra-small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'bold', FontSize: 'small', Text: `${t('important')}!` },
                        { FontFamily: 'medium', FontSize: 'small', Text: t('custom_training_program_instructions_top_description')}
                    ]}
                />

                <Spacer Size="small" />

                <CustomInstructionsContainer className="CustomInstructionsContainer">
                    <CustomInstructionContainer className="CustomInstructionContainer">
                        <MultiText
                            Texts={[
                                { FontFamily: 'bold', FontSize: 'small', Text: `${t('custom_on')}:` },
                                { FontFamily: 'medium', FontSize: 'small', Text: t('custom_on_desc')}
                            ]}
                        />
                    </CustomInstructionContainer>

                    <CustomInstructionContainer className="CustomInstructionContainer">
                        <MultiText
                            Texts={[
                                { FontFamily: 'bold', FontSize: 'small', Text: `${t('custom_off')}:` },
                                { FontFamily: 'medium', FontSize: 'small', Text: t('custom_off_desc')}
                            ]}
                        />
                    </CustomInstructionContainer>
                </CustomInstructionsContainer>

                <Spacer Size="small" />

                <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text={t('custom_training_footer_title')} TextAlign="center" />
            </>
        );
    }

    renderFilters = () => {
        var { t } = this.props;
        var { TryingSearchWorkoutTemplates } = this.props;
        var { SearchValue, WorkoutTypes } = this.state;

        var WorkoutTypeOptions = [{ WorkoutTypeId: 1, WorkoutTypeName: t('Strength') }, { WorkoutTypeId: 2, WorkoutTypeName: t('Endurance') }, { WorkoutTypeId: 3, WorkoutTypeName: t('additional_activity') }];

        return (
            <>
                <AllFilters>
                    <StyledSearchInput
                        FontSize="medium-2"
                        NoLabel
                        NoMargin
                        OnChange={this.onChangeSearch}
                        OnKeyPress={this.onKeyPress}
                        Placeholder={t('search_with_first_char_uppercase_dots')}
                        Size="medium"
                        Type="text"
                        Value={SearchValue}
                    />

                    <StyledDropdown
                        Disabled={TryingSearchWorkoutTemplates}
                        ItemId="WorkoutTypeId"
                        ItemName="WorkoutTypeName"
                        Items={WorkoutTypeOptions}
                        Loading={false}
                        NeedsToLoad={false}
                        OnSelectItem={(event, WorkoutTypeId, WorkoutTypeIndex) => this.onSelectWorkoutType(event, WorkoutTypeId, WorkoutTypeIndex)}
                        SelectedItemIds={WorkoutTypes}
                        Title={t('Workout_types')}
                    />
                </AllFilters>
            </>
        );
    }

    renderInjuries = () => {
        var { t } = this.props;
        var { TrainingProgram: { Injuries } } = this.props;
        var { Injuries: StateInjuries } = this.state;

        return (
            <>
                <StyledMultiText
                    TextAlign="left"
                    Texts={[{ FontColor: 'blue-astronaut', FontFamily: 'semibold', FontSize: 'medium-3', Text: `${t('custom_template_select_blank_used', { Item: t('WorkoutPlan_injuries').toLowerCase()})}` }]}
                />

                <Spacer Size="extra-small" />

                <JointEquipmentContainer className="JointEquipmentContainer" Type="Injuries">
                    {
                        Injuries.map(({ label: InjuryName, value: InjuryId }) => {
                            var SelectedIndex = StateInjuries.indexOf(+InjuryId);

                            return (
                                <JointEquipmentItem className="JointEquipmentItem" key={InjuryId}>
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="1A97FF" onClick={() => this.onSelectInjury({ InjuryId, InjuryIndex: SelectedIndex })} Selected={SelectedIndex !== -1} />

                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectInjury({ InjuryId, InjuryIndex: SelectedIndex })} Text={InjuryName} TextAlign="left" />
                                </JointEquipmentItem>
                            );
                        })
                    }
                </JointEquipmentContainer>
            </>
        );
    }

    renderProgramDescription = () => {
        var { t } = this.props;
        var { ProgramDescription } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin JustifyContent="center" Text={t('training_program_description')} TextAlign="center" />

                <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin JustifyContent="center" Text={t('optional')} TextAlign="center" />

                <StyledDescriptionTextArea
                    OnChangeMaxCharactersValidation={this.onChangeProgramDescription_MaxChangeValidation}
                    MaxCharacters={300}
                    NumRows={4}
                    OnBlur={this.onChangeProgramDescription}
                    Placeholder={t('description')}
                    ShowCharacterCount
                    Value={ProgramDescription}
                />
            </>
        );
    }

    renderProgramName = () => {
        var { t } = this.props;
        var { ProgramName } = this.state;

        return (
            <>
                <RequiredHeaderContainer>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-3" Text={t('name_your_program')} />
                    <PageText ElementType="span" FontColor="red-bittersweet" FontFamily="medium" FontSize="medium-3" Text=" *" />
                </RequiredHeaderContainer>

                <StyledTemplateName
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeProgramName}
                    Placeholder={t('name_your_program')}
                    Size="medium"
                    Type="text"
                    Value={ProgramName}
                />
            </>
        );
    }

    // onBeforeCapture = ({ draggableId }) => {
    //     this.setState({ IsDragging: draggableId });
    // }

    renderSelectedWorkoutTemplates = () => {
        var { t } = this.props;
        var { Days, IsDragging, TemplateDays, SelectedWorkoutTemplatesIndex } = this.state;

        return (
            <DragDropContext
                // onBeforeCapture={this.onBeforeCapture}
                onDragEnd={this.onDragEnd}
            >
                <SelectedDishesContainer>
                    <Droppable droppableId="SelectedWorkoutTemplates" isDropDisabled>
                        {provided => (
                            <div
                                className="template-dish-selections-container margintop50 border2px"
                                ref={provided.innerRef}
                                // {...provided.droppableProps}
                            >
                                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={`${t('selected_templates')} (${SelectedWorkoutTemplatesIndex.length})`} TextAlign="center" />

                                <Spacer Size="small" />

                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('training_program_instructions')} TextAlign="center" />

                                <Spacer Size="medium" />

                                {this.renderCustomInstructions()}

                                <Spacer Size="small" />

                                <SelectedWorkoutTemplates>
                                    {
                                        this.state.SelectedWorkoutTemplatesIndex.map((SelectedWorkoutTemplateId, index) => {
                                            var MappedWorkoutTemplate = this.state.SelectedWorkoutTemplates[SelectedWorkoutTemplateId];
                                            var TemplateId = +MappedWorkoutTemplate.TemplateId;

                                            if (1 < 2) {
                                                return (
                                                    <WorkoutTemplate
                                                        key={index}
                                                        t={t}
                                                        Days={Days}
                                                        HideRemove
                                                        ShowCalendar
                                                        TemplateDays={TemplateDays}
                                                        OnAddToSelectedDays={this.onAddToSelectedDays}
                                                        WorkoutTemplate={MappedWorkoutTemplate}
                                                        UniqueId={`${TemplateId}`}
                                                        WorkoutTemplateIndex={index}
                                                        onChangeCustomSettingDisplay={this.onChangeCustomSettingDisplay_SelectedTemplates}
                                                        onChangeCustomSetting={(e, Setting, UniqueId) => this.onChangeCustomSetting_SelectedTemplates(e, Setting, UniqueId)}
                                                        ViewTemplate={Template => this.onToggleShowTemplateDetailModal(true, Template)}
                                                    />
                                                );
                                            }

                                            return (
                                                <Draggable
                                                    draggableId={`TemplateId-${TemplateId}`}
                                                    index={index}
                                                    key={`${TemplateId}_${index}`}
                                                >
                                                    {(provided, snapshot) => (
                                                        <React.Fragment>
                                                            <div
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                                style={provided.draggableProps.style}
                                                            >
                                                                {this.renderDraggableWorkoutListItem({ DraggingItem: 'top', IsDragging: snapshot.isDragging, WorkoutTemplate: MappedWorkoutTemplate })}
                                                            </div>

                                                            {snapshot.isDragging && (
                                                                <DraggingWTContainer className="DraggingWTContainer">
                                                                    {this.renderDraggableWorkoutListItem({ DraggingItem: 'bottom', IsDragging: snapshot.isDragging, WorkoutTemplate: MappedWorkoutTemplate })}
                                                                </DraggingWTContainer>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                    }
                                </SelectedWorkoutTemplates>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </SelectedDishesContainer>
                <Container>
                    {this.state.Days.map(({ DayId, WorkoutTemplateIds, WorkoutTemplates }, index) => {
                        return (
                            <DayColumn
                                key={DayId}
                                DayId={DayId}
                                DayIndex={index}
                                WorkoutTemplateIds={WorkoutTemplateIds}
                                WorkoutTemplates={WorkoutTemplates}
                                DayNumber={index + 1}
                                IsDragging={IsDragging}
                                onChangeCustomSettingDisplay={this.onChangeCustomSettingDisplay}
                                onChangeCustomSetting={(e, Setting, UniqueId) => this.onChangeCustomSetting(e, Setting, UniqueId)}
                                onRemoveWorkoutTemplate={UniqueId => this.onRemoveWorkoutTemplate(UniqueId)}
                                t={t}
                                ViewTemplate={Template => this.onToggleShowTemplateDetailModal(true, Template)}
                            />
                        )
                    })}
                </Container>
            </DragDropContext>
        );
    }

    renderTags = () => {
        var { t } = this.props;
        var { TrainingProgram: { Tags } } = this.props;
        var { Tags: StateTags } = this.state;

        return (
            <>
                <StyledMultiText
                    TextAlign="left"
                    Texts={[{ FontColor: 'blue-astronaut', FontFamily: 'semibold', FontSize: 'medium-3', Text: `${t('custom_template_select_blank_used', { Item: t('_tags').toLowerCase()})}` }]}
                />

                <Spacer Size="extra-small" />

                <JointEquipmentContainer className="JointEquipmentContainer" Type="Tags">
                    {
                        Tags.map(({ label: TagName, value: TagId }) => {
                            var SelectedIndex = StateTags.indexOf(+TagId);

                            return (
                                <JointEquipmentItem className="JointEquipmentItem" key={TagId}>
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="1A97FF" onClick={() => this.onSelectTag({ TagId, TagIndex: SelectedIndex })} Selected={SelectedIndex !== -1} />

                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectTag({ TagId, TagIndex: SelectedIndex })} Text={TagName} TextAlign="left" />
                                </JointEquipmentItem>
                            );
                        })
                    }
                </JointEquipmentContainer>
            </>
        );
    }

    renderTemplate = () => {
        return (
            <Template>
                <ChooseDishesContainer>
                    {this.renderSelectedWorkoutTemplates()}
                </ChooseDishesContainer>
            </Template>
        );
    }

    renderTemplateDays = () => {
        var { t } = this.props;

        return (
            <>
                <RequiredHeaderContainer>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-3" Text={t('program_length')} />
                    <PageText ElementType="span" FontColor="red-bittersweet" FontFamily="medium" FontSize="medium-3" Text=" *" />
                </RequiredHeaderContainer>

                <Spacer Size="small" />

                {this.renderTemplateDaysSelect()}
            </>
        );
    }

    renderTemplateDaysSelect = () => {
        var { t } = this.props;
        var { NumDays: { Days, Weeks }, TooManyDays } = this.state;

        return (
            <>
                <TemplateDaysContainer2 className="TemplateDaysContainer2">
                    <TemplateDaysInnerContainer className="TemplateDaysInnerContainer">
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('num_weeks')} TextAlign="center" />

                        <Spacer Size="extra-extra-small" />

                        <StyledTemplateDays
                            NoLabel
                            NoMargin
                            OnBlur={this.onBlurTemplateDays}
                            OnChange={event => this.onChangeTemplateDaysInput({ event, Type: 'Weeks' })}
                            Placeholder="0"
                            Size="medium"
                            Type="number"
                            Value={Weeks}
                        />
                    </TemplateDaysInnerContainer>

                    <StyledPlusSignIcon ImageSrc={Plus} Loading={false} />

                    <TemplateDaysInnerContainer className="TemplateDaysInnerContainer">
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('num_days')} TextAlign="center" />

                        <Spacer Size="extra-extra-small" />

                        <StyledTemplateDays
                            NoLabel
                            NoMargin
                            OnBlur={this.onBlurTemplateDays}
                            OnChange={event => this.onChangeTemplateDaysInput({ event, Type: 'Days' })}
                            Placeholder="0"
                            Size="medium"
                            Type="number"
                            Value={Days}
                        />
                    </TemplateDaysInnerContainer>
                </TemplateDaysContainer2>

                {
                    !!+TooManyDays &&
                    <>
                        <Spacer Size="small" />

                        <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('too_many_days', { MaxDays: '84', SelectedDays: `${TooManyDays}` })} TextAlign="center" />
                    </>
                }
            </>
        );
    }

    renderTemplateDetailModal = () => {
        var { ShowTemplateDetailModal, TemplateDetail_Preview } = this.state;

        if (ShowTemplateDetailModal) {
            return (
                <WorkoutTemplateModal
                    OnHide={() => this.onToggleShowTemplateDetailModal(false)}
                    TemplateDetail_Preview={TemplateDetail_Preview}
                    Show={ShowTemplateDetailModal}
                />
            );
        }
    }

    renderTrainingFocus = () => {
        var { t } = this.props;
        var { TrainingProgram: { TrainingFocus } } = this.props;
        var { TrainingFocus: StateTrainingFocus } = this.state;

        return (
            <>
                <StyledMultiText
                    TextAlign="left"
                    Texts={[{ FontColor: 'blue-astronaut', FontFamily: 'semibold', FontSize: 'medium-3', Text: `${t('custom_template_select_blank_used', { Item: t('_trainingfocus').toLowerCase()})}` }]}
                />

                <Spacer Size="extra-small" />

                <JointEquipmentContainer className="JointEquipmentContainer" Type="TrainingFocus">
                    {
                        TrainingFocus.map(({ label: TrainingFocusName, value: TrainingFocusId }) => {
                            var SelectedIndex = StateTrainingFocus.indexOf(+TrainingFocusId);

                            return (
                                <JointEquipmentItem className="JointEquipmentItem" key={TrainingFocusId}>
                                    <PseudoCheckbox className="PseudoCheckbox" BackgroundColor="1A97FF" onClick={() => this.onSelectTrainingFocus({ TrainingFocusId, TrainingFocusIndex: SelectedIndex })} Selected={SelectedIndex !== -1} />

                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectTrainingFocus({ TrainingFocusId, TrainingFocusIndex: SelectedIndex })} Text={TrainingFocusName} TextAlign="left" />
                                </JointEquipmentItem>
                            );
                        })
                    }
                </JointEquipmentContainer>
            </>
        );
    }

    renderWorkoutTemplatesList = () => {
        var { t } = this.props;
        var { IsMoreWorkoutTemplates, TryingSearchWorkoutTemplates, WorkoutTemplateList, WorkoutTemplateList_TotalRecords } = this.props;
        var { ActiveSearch, CompletedFirstSearch, NewSearch, PageNo, PageSize, SelectedWorkoutTemplateIdsIndex } = this.state;

        return (
            <StyledLoadMore
                ActiveSearch={ActiveSearch}
                CompletedFirstSearch={CompletedFirstSearch}
                HideViewCount
                IsLoading={TryingSearchWorkoutTemplates}
                ItemDescriptionType={t('templates_plural').toLowerCase()}
                ItemId="TemplateId"
                ItemName="ProgramName"
                ItemProps={{ SelectTemplate: Template => this.onSelectTemplate(Template), ViewTemplate: Template => this.onToggleShowTemplateDetailModal(true, Template) }}
                ItemRenderer={WorkoutTemplateListItem}
                Items={WorkoutTemplateList}
                HasMoreItems={IsMoreWorkoutTemplates}
                LoadMore={() => this.onLoadWorkoutTemplates(true)}
                NewSearch={NewSearch}
                NoItemsText={t('search_noresults')}
                PageNo={PageNo}
                PageSize={PageSize}
                SelectedItemIds={SelectedWorkoutTemplateIdsIndex}
                TotalRecords={WorkoutTemplateList_TotalRecords}
            />
        );
    }

    renderWorkoutType = WorkoutType => {
        var IconSrc = WorkoutType === 1 ? Strength : WorkoutType === 2 ? Endurance : AdditionalActivity;

        return <TemplateIcon src={IconSrc} alt="Workout Type" />
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { t } = this.props;
        var { Device, TryingGetTrainingProgramDetail, TryingInsertEditTrainingProgram } = this.props;
        var { Days, DescriptionIsValidLength, TemplateDays, TrainingProgramId, ProgramName } = this.state;

        var ActionText = TrainingProgramId ? t('Edit_training_program') : t('Create_training_program');

        var Breadcrumbs = [{ Name: t('ptadmin_navigation_library'), LinkURL: 'library-workouts', Props: true, Search: '?tab=training-programs' }, { Name: ActionText }];

        var Disabled = TemplateDays && ProgramName ? false : true;
        var HasAtLeast1Workout = false;

        if (!DescriptionIsValidLength) Disabled = true;
        if (!Disabled) {
            for (var i = 0; i < Days.length; i++) {
                if (Days[i].WorkoutTemplateIds.length) { HasAtLeast1Workout = true; break; }
            }

            if (!HasAtLeast1Workout) Disabled = true;
        }

        var SubmitButtonProps = { Disabled, OnClick: this.onInsertEditTrainingProgram, Title: ActionText };

        return (
            <>
                {(TryingGetTrainingProgramDetail || TryingInsertEditTrainingProgram) && <Loading />}

                <Page
                    renderBreadcrumbs={Breadcrumbs}
                    renderSubmitButton={SubmitButtonProps}
                >
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin OnClick={() => console.log('state: ', this.state)} Text={ActionText} TextAlign="center" />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderTemplateDays()}
                    
                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderProgramName()}

                    <Spacer Size="small" />

                    {this.renderProgramDescription()}

                    <Spacer Size="medium" />

                    {this.renderInjuries()}

                    <Spacer Size="medium" />

                    {this.renderTrainingFocus()}

                    <Spacer Size="medium" />

                    {this.renderTags()}
                    
                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderAddWorkoutTemplates()}
                </Page>

                {this.renderTemplateDetailModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        CopyTrainingProgramDetails: state.SaveData.CopyTrainingProgramDetails,

        IsMoreWorkoutTemplates: state.Templates.IsMoreWorkoutTemplates,
        WorkoutTemplateFilters: state.Templates.WorkoutTemplateFilters,
        WorkoutTemplateList: state.Templates.WorkoutTemplateList,
        WorkoutTemplateList_TotalRecords: state.Templates.WorkoutTemplateList_TotalRecords,

        TryingDeleteWorkoutTemplates: state.Templates.TryingDeleteWorkoutTemplates,
        TryingDeleteWorkoutTemplatesError: state.Templates.TryingDeleteWorkoutTemplatesError,
        TryingSearchWorkoutTemplates: state.Templates.TryingSearchWorkoutTemplates,
        TryingSearchWorkoutTemplatesError: state.Templates.TryingSearchWorkoutTemplatesError,

        TrainingProgram: state.Templates.TrainingProgram,

        TryingGetTrainingProgramDetail: state.Templates.TryingGetTrainingProgramDetail,
        TryingGetTrainingProgramDetailError: state.Templates.TryingGetTrainingProgramDetailError,
        TryingInsertEditTrainingProgram: state.Templates.TryingInsertEditTrainingProgram,
        TryingInsertEditTrainingProgramError: state.Templates.TryingInsertEditTrainingProgramError,
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearWorkoutTemplates, ClearTrainingProgram, ClearSaveData, GetTrainingProgramDetail, InsertEditTrainingProgram, SaveDataInProps, SearchWorkoutTemplates } )(TrainingProgramComponent));