import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { CompleteButton, CompleteButtonIcon, DeleteButton, DeleteSetIcon, EditButton, EditSetIcon, ExerciseDetailContainer, ExerciseNameContainer, ExerciseNotesContainer, ExerciseNotesTextArea, LoadingContainer, SetOptionsContainer, StyledAddAnotherSet, StyledExerciseImageWorkout, StyledExerciseName, StyledExerciseOptions, StyledExerciseOptionsTest, StyledExerciseVideo, StyledLoading, StyledMaxSetButton, StyledSkipTest, StyledTestHeader, StyledTestSetOptions, TestSetDescriptionContainer, TestSetDescriptionContainerInner, TestSetDescriptionImage, WEHeaderContainer, WESetAndMaxContainer, WESetAndMaxContainerLeft, WESetAndMaxContainerRight, WESetContainer, WESetDetailsContainer, WESetFooterContainer2, WESetHeaderContainer, WESetsContainer, WorkoutExerciseContainer } from './styles';

import PageText from '../Text/PageText';
import Spacer from '../Spacer';

import ExerciseTypeOne from '../ExerciseTypes/One_Reps';
import ExerciseTypeTwo from '../ExerciseTypes/Two_Seconds';
import ExerciseTypeThree from '../ExerciseTypes/Three_Distance';
import ExerciseTypeFour from '../ExerciseTypes/Four_RepsWeight';
import ExerciseTypeFive from '../ExerciseTypes/Five_SecondsWeight';

import Checkmark from '../../Assets/Icons/Checkmark_White.png';
import DeleteX from '../../Assets/Icons/X_White.png';
import LeftArrow from '../../Assets/Icons/LeftArrow.png';
import Pencil from '../../Assets/Icons/Pencil_White_Filled.png';
import Plus from '../../Assets/Icons/Plus.png';

import Testing from '../../Assets/Illustrations/TestSet.png';
import ThumbsUp from '../../Assets/Illustrations/ThumbsUp.png';

import { SecondsToTime } from '../../Functions';

class WorkoutExercise extends React.Component {
    state = {
        EditingSet: null,
        ExerciseNoteCharactersLeft: 300,
        ExerciseNoteMaxChars: 300,
        ExerciseNoteMaxCharsValid: true,
        TestSetScreen: 'Description'
    };

    componentDidMount() {
        var { Notes } = this.props.Exercise;

        if (Notes && Notes.Coach) {
            var ExerciseNoteCharactersLeft = this.state.ExerciseNoteMaxChars - Notes.Coach.length;
            var ExerciseNoteMaxCharsValid = true;

            if (ExerciseNoteCharactersLeft < 0) ExerciseNoteMaxCharsValid = false;

            this.setState({ ExerciseNoteCharactersLeft, ExerciseNoteMaxCharsValid });
        }
    }

    onChangeExerciseNote = Note => {
        // var CharactersLeft = this.state.MaxCharacters - event.target.value.length;
        // var MaxCharactersValid = CharactersLeft >= 0;

        // this.setState({ CharactersLeft, EditingBranchName: event.target.value, MaxCharactersValid, NameTaken: false });
    }

    onRegenerateMax = ({ MaxDistance, MaxReps, MaxSeconds, MaxWeight }) => {
        MaxDistance = MaxDistance || 0;
        MaxReps = MaxReps || 0;
        MaxSeconds = MaxSeconds || 0;
        MaxWeight = MaxWeight || 0;
        
        this.props.OnRegenerateMax({ MaxDistance, MaxReps, MaxSeconds, MaxWeight });
    }

    onToggleEditSet = WorkoutSetIndex => {
        var EditingSet = this.state.EditingSet === WorkoutSetIndex ? null : WorkoutSetIndex;

        this.setState({ EditingSet });
    }

    onToggleSetCompleted = (Completed, MaxSet, WorkoutSetIndex) => {
        this.props.OnToggleSetCompleted(Completed, MaxSet, WorkoutSetIndex);
    }

    onUpdateTestSetScreen = TestSetScreen => {
        this.setState({ TestSetScreen });
    }

    onUpdateWorkoutSet = ({ Completed, Distance, ExerciseId, ExerciseType, MaxSet, Reps, Seconds, TotalBreak, UpdateState, Weight, WorkoutExerciseId, WorkoutSetId, WorkoutSetIndex }) => {
        this.setState({ EditingSet: null });
        this.props.OnUpdateWorkoutSet({ Completed, Distance, ExerciseId, ExerciseType, MaxSet, Reps, Seconds, TotalBreak, UpdateState, Weight, WorkoutExerciseId, WorkoutSetId, WorkoutSetIndex });
    }

    renderHeader = FullyCompleted => {
        var { t } = this.props;
        var { Device, Exercise: { ExerciseId, ExerciseImage, ExerciseName, ExerciseVideo, Sets: WorkoutSets, TestSets }, OnDeleteExercise, OnToggleShowExerciseDetailModal, TryingRegenerateTestSet } = this.props;

        var Buttons = [];

        var MaxCompleted = (WorkoutSets.length > 0 && WorkoutSets.filter(({ Completed, MaxSet }) => !!+Completed && !!+MaxSet)) || (TestSets.length > 0 && TestSets.filter(({ Completed, MaxSet }) => !!+Completed && !!+MaxSet));
        MaxCompleted = MaxCompleted.length > 0 ? true : false;

        if (!FullyCompleted && !MaxCompleted) {
            // Buttons = [{ Color: 'blue-astronaut', FontFamily: 'semibold', FontSize: 'small', IconSize: { Height: '20px', Width: '20px' }, OnClick: OnChangeExercise, Title: `${t('change_with_first_char_uppercase')} ${t('ptadmin_exercises_singular').toLowerCase()}` }];
            Buttons.push({ BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', FontSize: 'small', OnClick: OnDeleteExercise, Title: t('delete_with_first_char_uppercase') });
        }

        return (
            <WEHeaderContainer className="WEHeaderContainer" Completed={FullyCompleted} TestSets={TestSets.length > 0 && MaxCompleted}>
                <ExerciseDetailContainer className="ExerciseDetailContainer">
                    {
                        !!ExerciseVideo ?
                        <StyledExerciseVideo
                            autoplay={false}
                            controls={false}
                            muted
                            { ...ExerciseImage && { poster: ExerciseImage } }
                            onMouseOver={e => e.target.play()}
                            onMouseOut={e => e.target.pause()}
                            src={ExerciseVideo}
                            onClick={() => OnToggleShowExerciseDetailModal(true, { ExerciseId, ExerciseImage, ExerciseName })}
                        />
                    :
                        <StyledExerciseImageWorkout
                            Alt={ExerciseName}
                            ImageSrc={ExerciseImage}
                            OnClick={() => OnToggleShowExerciseDetailModal(true, { ExerciseId, ExerciseImage, ExerciseName })}
                            SelfLoading
                        />
                    }

                    <ExerciseNameContainer className="ExerciseNameContainer">
                        <StyledExerciseName
                            FontColor={FullyCompleted && TestSets.length > 0 && MaxCompleted ? 'white-concrete' : 'black'}
                            FontFamily="bold"
                            FontSize="medium-2"
                            NoMargin
                            OnClick={() => OnToggleShowExerciseDetailModal(true, { ExerciseId, ExerciseImage, ExerciseName })}
                            Text={ExerciseName}
                        />

                        {
                            TestSets.length > 0 && MaxCompleted ?
                            (Device === 'mobile' || Device === 'mobile_small') ?
                            null
                        :
                            <StyledTestHeader
                                FontColor="white-concrete"
                                FontFamily="semibold"
                                FontSize="medium-1"
                                NoMargin
                                Text={t('WorkoutPlan_test_Header')}
                            />
                        :
                            TestSets.length > 0 ?
                            <StyledExerciseOptionsTest Buttons={Buttons} ButtonWidth="75px" />
                        :
                            null
                        }
                    </ExerciseNameContainer>
                </ExerciseDetailContainer>
                {
                    WorkoutSets.length > 0 && Buttons.length > 0 && !TryingRegenerateTestSet ?
                    <StyledExerciseOptions Buttons={Buttons} ButtonWidth="75px" />
                :
                    (!TryingRegenerateTestSet && TestSets.length > 0) && this.renderTestSetOptions()
                }
            </WEHeaderContainer>
        );
    }

    renderMaxSetOption = (MaxSet, WorkoutSetId, WorkoutSetIndex) => {
        var { t } = this.props;

        return (
            <StyledMaxSetButton IsMax={MaxSet} onClick={() => this.props.OnChangeMaxSet(MaxSet, WorkoutSetId, WorkoutSetIndex)}>
                {t('max')}
            </StyledMaxSetButton>
        );
    }

    renderRealSets = (ExerciseId, ExerciseType, FullyCompleted, Header_Reps, Progress, WorkoutExerciseId, WorkoutSets) => {
        var { Device } = this.props;
        var { RealTimeSetUpdatingIndex } = this.props;
        var { EditingSet } = this.state;

        return (
            <>
                {
                    WorkoutSets.map((WorkoutSet, WorkoutSetIndex) => {
                        var { Break, Completed, Distance, MaxSet, Reps, Seconds, SetId: WorkoutSetId, Units, Weight } = WorkoutSet;

                        Break = +Break;
                        Completed = !!+Completed;
                        Distance = +Distance;
                        MaxSet = !!+MaxSet;
                        Reps = +Reps;
                        Seconds = +Seconds;
                        Weight = +Weight;

                        return (
                            <React.Fragment key={WorkoutSetId}>
                                {WorkoutSetIndex !== 0 && <Spacer Size="extra-small" />}

                                <WESetContainer className="WESetContainer" Completed={Completed} JustUpdated={RealTimeSetUpdatingIndex === WorkoutSetIndex} MaxSet={MaxSet}>
                                    <WESetHeaderContainer className="WESetHeaderContainer" Completed={Completed} MaxSet={MaxSet}>
                                        {this.renderSetHeader(Completed, MaxSet, WorkoutSetId, WorkoutSetIndex)}
                                    </WESetHeaderContainer>
                                    <WESetDetailsContainer className="WESetDetailsContainer">
                                        {ExerciseType === 1 && <ExerciseTypeOne BreakTime={Break} Completed={Completed} EditingSet={EditingSet === WorkoutSetIndex} MaxSet={MaxSet} OnUpdateWorkoutSet={({ Reps, TotalBreak }) => this.onUpdateWorkoutSet(({ Completed, ExerciseId, ExerciseType, MaxSet, Reps, TotalBreak, WorkoutExerciseId, WorkoutSetId, WorkoutSetIndex }))} Reps={Reps} Progress={Progress} SetType="Real" Units={Units} />}
                                        {ExerciseType === 2 && <ExerciseTypeTwo BreakTime={Break} Completed={Completed} EditingSet={EditingSet === WorkoutSetIndex} MaxSet={MaxSet} OnUpdateWorkoutSet={({ Seconds, TotalBreak }) => this.onUpdateWorkoutSet(({ Completed, ExerciseId, ExerciseType, MaxSet, Seconds, TotalBreak, WorkoutExerciseId, WorkoutSetId, WorkoutSetIndex }))} Seconds={Seconds} Progress={Progress} SetType="Real" Units={Units} />}
                                        {ExerciseType === 3 && <ExerciseTypeThree BreakTime={Break} Completed={Completed} Distance={Distance} EditingSet={EditingSet === WorkoutSetIndex} MaxSet={MaxSet} OnUpdateWorkoutSet={({ Distance, TotalBreak }) => this.onUpdateWorkoutSet(({ Completed, Distance, ExerciseId, ExerciseType, MaxSet, TotalBreak, WorkoutExerciseId, WorkoutSetId, WorkoutSetIndex }))} Progress={Progress} SetType="Real" Units={Units} />}
                                        {ExerciseType === 4 && <ExerciseTypeFour BreakTime={Break} Completed={Completed} Header_Reps={Header_Reps} EditingSet={EditingSet === WorkoutSetIndex} MaxSet={MaxSet} OnUpdateWorkoutSet={({ Reps, TotalBreak, Weight }) => this.onUpdateWorkoutSet(({ Completed, ExerciseId, ExerciseType, MaxSet, Reps, TotalBreak, Weight, WorkoutExerciseId, WorkoutSetId, WorkoutSetIndex }))} Reps={Reps} Progress={Progress} SetType="Real" Units={Units} Weight={Weight} />}
                                        {ExerciseType === 5 && <ExerciseTypeFive  BreakTime={Break} Completed={Completed} EditingSet={EditingSet === WorkoutSetIndex} MaxSet={MaxSet} OnUpdateWorkoutSet={({ Seconds, TotalBreak, Weight }) => this.onUpdateWorkoutSet(({ Completed, ExerciseId, ExerciseType, MaxSet, Seconds, TotalBreak, Weight, WorkoutExerciseId, WorkoutSetId, WorkoutSetIndex }))} Seconds={Seconds} Progress={Progress} SetType="Real" Units={Units} Weight={Weight}/>}
                                    </WESetDetailsContainer>
                                    {
                                        Device === 'laptop' &&
                                        <WESetFooterContainer2 className="WESetFooterContainer2" Completed={Completed}>
                                            {this.renderSetOptions({ Completed, MaxSet, WorkoutSetId, WorkoutSetIndex })}
                                        </WESetFooterContainer2>
                                    }
                                </WESetContainer>
                            </React.Fragment>
                        );
                    })
                }
            </>
        );
    }

    renderSetHeader = (Completed, MaxSet, WorkoutSetId, WorkoutSetIndex) => {
        var { Device } = this.props;

        return (
            <>
                <WESetAndMaxContainer className="WESetAndMaxContainer">
                    <WESetAndMaxContainerLeft>
                        <PageText FontColor="white" FontFamily="bold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={`${WorkoutSetIndex + 1}.`} TextAlign="left" />
                        
                        {this.renderMaxSetOption(MaxSet, WorkoutSetId, WorkoutSetIndex)}
                    </WESetAndMaxContainerLeft>

                    {
                        Device !== 'laptop' &&
                        <WESetAndMaxContainerRight>
                            {this.renderSetOptions({ Completed, MaxSet, WorkoutSetId, WorkoutSetIndex })}
                        </WESetAndMaxContainerRight>
                    }
                </WESetAndMaxContainer>
            </>
        );
    }

    renderSetOptions = ({ Completed, MaxSet, WorkoutSetId, WorkoutSetIndex }) => {
        // Show delete, edit and / or complete button
        var { OnDeleteSet } = this.props;

        return (
            <SetOptionsContainer className="SetOptionsContainer">
                {
                    Completed &&
                        <EditButton className="EditButton" MaxSet={MaxSet} onClick={() => this.onToggleEditSet(WorkoutSetIndex)}>
                            <EditSetIcon Alt={`Edit ${WorkoutSetId}`} ImageSrc={Pencil} />
                        </EditButton>
                }

                {
                    !Completed &&
                        <DeleteButton className="DeleteButton" onClick={() => OnDeleteSet(WorkoutSetId, WorkoutSetIndex)} MaxSet={MaxSet}>
                            <DeleteSetIcon Alt={`Delete ${WorkoutSetId}`} ImageSrc={DeleteX} />
                        </DeleteButton>
                }

                {// Show if this set is completed AND the next set is not completed (or last set), or show if incomplete and previous set is completed
                    ((Completed && ((WorkoutSetIndex === this.props.Exercise.Sets.length - 1) || (!+this.props.Exercise.Sets[WorkoutSetIndex + 1].Completed))) || (!Completed && (WorkoutSetIndex === 0 || (this.props.Exercise.Sets[WorkoutSetIndex - 1] && !!+this.props.Exercise.Sets[WorkoutSetIndex - 1].Completed)))) &&
                        <CompleteButton className="CompleteButton" onClick={() => this.onToggleSetCompleted(Completed, MaxSet, WorkoutSetIndex)} Completed={Completed} MaxSet={MaxSet}>
                            <CompleteButtonIcon Alt={`Complete ${WorkoutSetId}`} ImageSrc={Checkmark} />
                        </CompleteButton>
                }
            </SetOptionsContainer>
        );
    }

    renderTestSetOptions = () => {
        var { t } = this.props;
        var { Device, Exercise: { ExerciseType, Header_Reps, TestSets } } = this.props;
        var { TestSetScreen } = this.state;

        // Completed Test Sets
            if (!!+TestSets[TestSets.length - 1].Completed) {
                var JustifyContent = (Device === 'laptop' || Device === 'ipad') ? 'flex-end' : 'center';
                var TextAlign = (Device === 'laptop' || Device === 'ipad') ? 'right' : 'center';

                var { Distance, Reps, Seconds, Units, Weight } = TestSets[TestSets.length - 1];

                if (ExerciseType === 2 || ExerciseType === 5) var Time = SecondsToTime(Seconds);

                var CompletedText = '';
                if (ExerciseType === 1) CompletedText = `${t('Workout_users_max_was')} ${Reps} ${Units}`;
                else if (ExerciseType === 2) CompletedText = `${t('Workout_users_max_was')} ${Time}`;
                else if (ExerciseType === 3) CompletedText = `${t('Workout_users_max_was')} ${Distance} ${Units}`;
                else if (ExerciseType === 4) CompletedText = `${t('Workout_users_max_was')} ${Weight} ${Units} ${t('for_str')} ${Reps} ${Header_Reps}`;
                else if (ExerciseType === 5) CompletedText = `${t('Workout_users_max_was')} ${Weight} ${Units} ${t('for_str')} ${Time}`;

                return (
                    <>
                        {(Device !== 'laptop' && Device !== 'ipad') && <Spacer Size="extra-small" />}

                        <TestSetDescriptionContainer>
                            <TestSetDescriptionContainerInner>
                                <PageText FontColor="white-concrete" FontFamily="semibold" FontSize="medium-2" JustifyContent={JustifyContent} NoMargin Text={t('WorkoutPlan_test_completed')} TextAlign={TextAlign} />

                                <Spacer Size="extra-extra-small" />

                                <PageText
                                    FontColor="white-concrete"
                                    FontFamily="medium"
                                    FontSize="medium-1"
                                    JustifyContent={JustifyContent}
                                    NoMargin
                                    Text={CompletedText}
                                    TextAlign={TextAlign}
                                />
                            </TestSetDescriptionContainerInner>
                            
                            <TestSetDescriptionImage
                                ImageSrc={ThumbsUp}
                                Loading={false}
                            />
                        </TestSetDescriptionContainer>
                    </>
                );
            }

        if (TestSetScreen === 'Description') {
            JustifyContent = (Device === 'laptop' || Device === 'ipad') ? 'flex-end' : 'flex-start';
            TextAlign = (Device === 'laptop' || Device === 'ipad') ? 'right' : 'left';

            // Spacer is because no "TEST" title appears by ExerciseName

            return (
                <>
                    {<Spacer Size="extra-small" />}

                    <TestSetDescriptionContainer className="TestSetDescriptionContainer">
                        {Device !== 'laptop' && <TestSetDescriptionImage ImageSrc={Testing} Loading={false} />}

                        <TestSetDescriptionContainerInner className="TestSetDescriptionContainerInner">
                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent={JustifyContent} NoMargin Text={t('this_is_a_test')} TextAlign={TextAlign} />

                            {/* <Spacer Size="extra-extra-small" /> */}

                            <PageText FontFamily="medium" FontSize="small" JustifyContent={JustifyContent} NoMargin Text={t('WorkoutPlan_test_description')} TextAlign={TextAlign} />

                            {/* <Spacer Size="extra-extra-small" /> */}

                            <StyledSkipTest FontColor="blue-abel" FontFamily="semibold" FontSize="medium-1" JustifyContent={JustifyContent} NoMargin OnClick={() => this.onUpdateTestSetScreen('AddMax')} Text={t('WorkoutPlan_test_skip')} TextAlign={TextAlign} />
                        </TestSetDescriptionContainerInner>
                        
                        {Device === 'laptop' && <TestSetDescriptionImage ImageSrc={Testing} Loading={false} />}
                    </TestSetDescriptionContainer>
                </>
            );
        }

        if (TestSetScreen === 'Options') {
            var Buttons = [
                { Icon: LeftArrow, IconPosition: 'left', FontFamily: 'semibold', OnClick: () => this.onUpdateTestSetScreen('Description'), Title: t('back_with_first_char_uppercase') },
                { FontFamily: 'semibold', OnClick: () => this.onUpdateTestSetScreen('AddMax'), Title: t('add_max_for_user') }
            ];
    
            return (
                <>
                    {(Device !== 'laptop' && Device !== 'ipad') && <Spacer Size="extra-small" />}
    
                    <StyledTestSetOptions
                        Buttons={Buttons}
                        NotTouching
                    />
                </>
            );
        }

        return null;
    }

    renderWorkoutSets = FullyCompleted => {
        if (this.props.TryingRegenerateTestSet) {
            return (
                <LoadingContainer>
                    <StyledLoading />
                </LoadingContainer>
            );
        }

        var { t } = this.props;
        var { Exercise: { ExerciseId, ExerciseType, Header_Reps, Progress, Sets: WorkoutSets, TestSets, WorkoutExerciseId }, OnAddSet } = this.props;
        var { TestSetScreen } = this.state;

        if (TestSets.length > 0 && TestSetScreen !== 'AddMax') return null;

        ExerciseType = +ExerciseType;

        var HasOnlyBottomMargin = TestSets.length > 0 && TestSetScreen === 'AddMax';

        return (
            <WESetsContainer className="WESetsContainer" HasOnlyBottomMargin={HasOnlyBottomMargin}>
                {
                    WorkoutSets.length > 0 &&
                    <>
                        {this.renderRealSets(ExerciseId, ExerciseType, FullyCompleted, Header_Reps, Progress, WorkoutExerciseId, WorkoutSets)}

                        <Spacer Size="extra-small" />

                        <StyledAddAnotherSet
                            Buttons={[{ Border: 'none', FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: OnAddSet, Title: t('add_another_workout_set') }]}
                            ButtonWidth="fit-content"
                        />
                    </>
                }

                {
                    TestSets.length > 0 && TestSetScreen === 'AddMax' &&
                    <>
                        {ExerciseType === 1 && <ExerciseTypeOne SetType="Test" OnRegenerateMax={MaxReps => this.onRegenerateMax({ MaxReps })} OnUpdateTestSetScreen={TestSetScreen => this.onUpdateTestSetScreen(TestSetScreen)} Units={TestSets[0].Units} />}
                        {ExerciseType === 2 && <ExerciseTypeTwo SetType="Test" OnRegenerateMax={MaxSeconds => this.onRegenerateMax({ MaxSeconds })} OnUpdateTestSetScreen={TestSetScreen => this.onUpdateTestSetScreen(TestSetScreen)} Units={TestSets[0].Units} />}
                        {ExerciseType === 3 && <ExerciseTypeThree SetType="Test" OnRegenerateMax={MaxDistance => this.onRegenerateMax({ MaxDistance })} OnUpdateTestSetScreen={TestSetScreen => this.onUpdateTestSetScreen(TestSetScreen)} Units={TestSets[0].Units} />}
                        {ExerciseType === 4 && <ExerciseTypeFour SetType="Test" Header_Reps={Header_Reps} OnRegenerateMax={({ MaxReps, MaxWeight }) => this.onRegenerateMax({ MaxReps, MaxWeight })} OnUpdateTestSetScreen={TestSetScreen => this.onUpdateTestSetScreen(TestSetScreen)} Units={TestSets[0].Units} />}
                        {ExerciseType === 5 && <ExerciseTypeFive SetType="Test" OnRegenerateMax={({ MaxSeconds, MaxWeight }) => this.onRegenerateMax({ MaxSeconds, MaxWeight })} OnUpdateTestSetScreen={TestSetScreen => this.onUpdateTestSetScreen(TestSetScreen)} Units={TestSets[0].Units} />}
                    </>
                }
            </WESetsContainer>
        );
    }

    render() {
        var { t } = this.props;
        var { CircuitType, Exercise: { ExerciseId, Notes, Sets: WorkoutSets, TestSets } } = this.props;
        // var { ExerciseNoteCharactersLeft, ExerciseNoteMaxChars, ExerciseNoteMaxCharsValid } = this.state;

        var FullyCompleted = WorkoutSets.length && !!+WorkoutSets[WorkoutSets.length - 1].Completed ? true : TestSets.length && !!+TestSets[TestSets.length - 1].Completed ? true : false;

        return (
            <React.Fragment key={ExerciseId}>
                <WorkoutExerciseContainer className="WorkoutExerciseContainer" CircuitType={CircuitType} Completed={FullyCompleted}>
                    {this.renderHeader(FullyCompleted)}

                    {!TestSets.length && <Spacer Size="smallest" />}

                    {this.renderWorkoutSets(FullyCompleted)}

                    <Spacer Size="smallest" />

                    <ExerciseNotesContainer className="ExerciseNotesContainer">
                            {/* <styles.BranchNameInputContainer className="BranchNameInputContainer">
                            <Input FontSize="medium-2" Label={t('income_branch_name')} LabelFontColor={FontColor} NoMargin OnChange={this.onChangeBranchName} OnKeyPress={this.onKeyPressBranchName} Placeholder={t('income_branch_name')} Size="medium" Type="text" Value={EditingBranchName} />

                            <PageText FontColorHex={ExerciseNoteMaxCharsValid ? FontColor : 'var(--red-bittersweet)'} FontFamily={ExerciseNoteMaxCharsValid ? 'medium' : 'semibold'} FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('characters_left')}: ${CharactersLeft}`} TextAlign="left" />

                            {NameTaken && <PageText FontColor="red-wellread" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('name_taken_try_new')} TextAlign="left" />}
                        </styles.BranchNameInputContainer>

                        <Spacer Size="small" /> */}

                        <ExerciseNotesTextArea
                            Label={t('_exercise_note_to_client')}
                            LabelFontFamily="semibold"
                            LabelFontSize="medium-2"
                            OnBlur={this.props.OnChangeExerciseNote}
                            MaxCharacters={300}
                            NumRows={4}
                            Placeholder={t('_exercise_note_to_client')}
                            ShowCharacterCount
                            ShowExtraWillBeRemoved
                            Value={Notes.Coach}
                        />
                    </ExerciseNotesContainer>
                </WorkoutExerciseContainer>
            </React.Fragment>
        );
    }
}

WorkoutExercise.propTypes = {
    CircuitType: PropTypes.string,
    Exercise: PropTypes.object.isRequired,
    OnAddSet: PropTypes.func.isRequired,
    OnChangeExercise: PropTypes.func.isRequired,
    OnChangeMaxSet: PropTypes.func.isRequired,
    OnDeleteExercise: PropTypes.func.isRequired,
    OnDeleteSet: PropTypes.func.isRequired,
    OnRegenerateMax: PropTypes.func.isRequired,
    OnToggleSetCompleted: PropTypes.func.isRequired,
    OnToggleShowExerciseDetailModal: PropTypes.func.isRequired,
    OnUpdateWorkoutSet: PropTypes.func.isRequired,
    RealTimeSetUpdatingIndex: PropTypes.number,
    TryingRegenerateTestSet: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device
    };
};

export default withTranslation()(connect(mapStateToProps, { } )(WorkoutExercise));