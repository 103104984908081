import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const ButtonContainer = styled.div`
    align-items: center;

    ${
        props => props.BackgroundGradient ? 
        `background-image: var(--${props.BackgroundGradient});`
    :
        !props.NoBackground && `background-color: ${props.Disabled ? 'var(--gray-silver);' : props.Selected ? props.BackgroundColorSelected ? `var(--${props.BackgroundColorSelected})` : 'var(--blue-astronaut)' : props.BackgroundColorHex ? props.BackgroundColorHex : props.BackgroundColor ? `var(--${props.BackgroundColor})` : 'var(--white)'};`
    }

    border: ${props => props.Border ? props.Border : '2px solid var(--white-concrete)'};
    border-radius: 10px;
    color: ${props => props.Disabled ? 'var(--black);' : props.Selected ? props.ColorSelected ? `var(--${props.ColorSelected})` : 'var(--white)' : props.ColorHex ? props.ColorHex : props.Color ? `var(--${props.Color})` : 'var(--black)'};
    display: inline-flex;
    flex-direction: ${props => props.IconPosition === 'top' ? 'column' : 'row'};
    font-family: ${props => props.FontFamily ? `var(--font-family-${props.FontFamily.toLowerCase()})` : 'var(--font-family-medium)'};
    font-size: ${props => props.FontSize ? `var(--font-size-${props.FontSize.toLowerCase()})` : 'var(--font-size-medium-1)'};
    justify-content: ${props => props.JustifyContent ? props.JustifyContent : 'center'};
    ${({ Margin, NotTouching, OwnRows }) => (
        NotTouching && !OwnRows &&
            `
                :not(:first-child) {
                    margin-left: ${Margin ? Margin : '10px'};
                }
            `
    )}
    ${({ Margin, OwnRows }) => (
        OwnRows &&
            `
                :not(:first-child) {
                    margin-top: ${Margin ? Margin : '10px'};
                }
            `
    )}
    min-height: var(--button-height-${props => props.Size ? props.Size : 'medium'});
    padding: ${props => props.Padding ? props.Padding : '5px 10px'};
    position: relative;
    text-align: ${props => props.TextAlign ? props.TextAlign : 'center'};
    width: ${props => props.ButtonWidth ? props.ButtonWidth : '100%'};
    white-space: ${props => props.ButtonWidth || props.NormalWhiteSpace ? 'normal' : 'nowrap'};
    word-break: ${props => props.WordBreak || 'break-word'};

    ${({ NotTouching }) => (
        NotTouching ?
            null
        :
            `
                border-radius: 0px;

                :first-child {
                    border-bottom-left-radius: 10px;
                    border-top-left-radius: 10px;
                }
            
                :last-child {
                    border-bottom-right-radius: 10px;
                    border-top-right-radius: 10px;
                }
            
                :not(:last-child) {
                    border-right: none;
                }
            `
    )}

    @media (hover:hover) {
        :hover {
            ${props => props.Disabled ? 'background-color: var(--gray-silver);' : props.BackgroundColorHover ? `background-color: var(--${props.BackgroundColorHover});` : props.IsButton ? props.IsSelected ? 'background-color: var(--white-concrete);' : 'background-color: var(--white-concrete);' : null}
            ${props => !!!props.Disabled && props.BorderHover && `border: ${props.BorderHover};`}
            ${props => props.Disabled ? 'color: var(--black);' : props.ColorHover ? `color: var(--${props.ColorHover});` : props.IsButton ? props.IsSelected ? 'color: var(--black);' : 'color: var(--black);' : null}
            ${props => props.Disabled ? 'cursor: not-allowed' : props.IsButton ? 'cursor: pointer;' : null};
        }
    }
`;

export const ButtonIcon = styled.img`
    ${props => props.IconSize ? `height: ${props.IconSize.Height};` : 'max-height: 25px;'}
    ${props => (!props.IconOnly && props.IconPosition !== 'top') && 'margin-right: 10px;'}
    ${props => props.IconSize ? `width: ${props.IconSize.Width};` : 'max-width: 25px;'}
`;

export const ButtonGroupContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.OwnRows ? 'column' : 'row'};
    justify-content: ${props => props.JustifyContent ? props.JustifyContent : props.FitContent ? 'center' : 'space-evenly'};
    ${props => props.ContainerWidth ? `width: ${props.ContainerWidth};` : null}
    max-width: 100%;
`;

export const IconSVGContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;

    > svg {
        fill: ${props => props.IconSVGColor ? `var(--${props.IconSVGColor})` : 'var(--black)'};
        ${props => props.IconSize ? `height: ${props.IconSize.Height};` : 'max-height: 25px;'}
        ${props => (!props.IconOnly && props.IconPosition !== 'top') && 'margin-right: 10px;'}
        ${props => props.IconSize ? `width: ${props.IconSize.Width};` : 'max-width: 25px;'}
    }
`;

// width: ${props => props.FitContent ? 'fit-content' : 'auto'};

export const StyledInput = styled.input`
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    @media (hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const StyledLink = styled(Link)`
    align-items: center;
    background-color: ${props => props.disabled ? 'var(--gray-silver);' : props.selected ? props.backgroundcolorselected ? `var(--${props.backgroundcolorselected})` : 'var(--blue-astronaut)' : props.backgroundcolor ? `var(--${props.backgroundcolor})` : 'var(--white)'};
    border: ${props => props.border ? props.border : '2px solid var(--white-concrete)'};
    border-radius: 10px;
    color: ${props => props.disabled ? 'var(--black);' : props.selected ? props.colorselected ? `var(--${props.colorselected})` : 'var(--white)' : props.color ? `var(--${props.color})` : 'var(--black)'};
    display: inline-flex;
    flex-direction: ${props => props.iconposition === 'top' ? 'column' : 'row'};
    font-family: ${props => props.fontfamily ? `var(--font-family-${props.fontfamily.toLowerCase()})` : 'var(--font-family-medium)'};
    font-size: ${props => props.fontsize ? `var(--font-size-${props.fontsize.toLowerCase()})` : 'var(--font-size-medium-1)'};
    justify-content: center;
    ${({ nottouching, ownrows }) => (
        nottouching && !ownrows ?
            `
                :not(:first-child) {
                    margin-left: 10px;
                }
            `
        :
            null
    )}
    ${({ ownrows }) => (
        ownrows ?
            `
                :not(:first-child) {
                    margin-top: 10px;
                }
            `
        :
            null
    )}
    min-height: var(--button-height-${props => props.size ? props.size : 'medium'});
    padding: 5px 10px;
    position: relative;
    text-align: ${props => props.textalign ? props.textalign : 'center'};
    width: ${props => props.buttonwidth ? props.buttonwidth : '100%'};
    white-space: ${props => props.buttonwidth || props.normalwhiteSpace ? 'normal' : 'nowrap'};
    word-break: break-word;

    ${({ nottouching }) => (
        nottouching ?
            null
        :
            `
                border-radius: 0px;
            `
    )}

    @media (hover:hover) {
        :hover {
            ${props => props.disabled ? 'background-color: var(--gray-silver);' : props.backgroundcolorhover ? `background-color: var(--${props.backgroundcolorhover});` : props.isbutton ? props.isselected ? 'background-color: var(--white-concrete);' : 'background-color: var(--white-concrete);' : null}
            ${props => !!!props.disabled && props.borderhover && `border: ${props.borderhover};`}
            ${props => props.disabled ? 'color: var(--black);' : props.colorhover ? `color: var(--${props.colorhover});` : props.isbutton ? props.isselected ? 'color: var(--black);' : 'color: var(--black);' : null}
            ${props => props.disabled ? 'cursor: not-allowed' : props.isbutton ? 'cursor: pointer;' : null};
        }
    }
`;