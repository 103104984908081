import styled from 'styled-components';
import { device } from '../../Config/device';

import BottomBar from '../../Components/BottomBar';
import Image from '../../Components/Image';
import PageText from '../../Components/Text/PageText';

export const BottomBarHeightAdjustment = styled.div`
    height: ${props => props.Height}px;
    width: 100%;
`;

export const BottomBarItems = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    @media ${device.maxwidth.mobile} {
        :first-child {
            margin-bottom: 15px;
        }
    }

    @media ${device.minwidth.ipad} {
        justify-content: flex-start;

        :last-child {
            justify-content: flex-end;
        }
    }
`;

export const CalendarIcon = styled(Image)`
    height: 25px;
`;

export const ChartDateCustomContainer = styled.div`
    align-items: center;
    border: 2px solid ${props => props.Selected ? 'var(--blue-abel)' : 'var(--white-concrete)'};
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 10px;

    @media(hover:hover) {
        :hover {
            border-color: var(--blue-abel);
            cursor: pointer;
        }
    }
`;

export const ChartDateCustomTextContainer = styled.div`
    margin-right: 10px;
`;

export const GetStartedButton = styled.div`
    background-color: var(--blue-abel);
    border-radius: 20px;
    color: var(--white);
    font-size: var(--font-size-medium-2);
    margin: 5px auto 0;
    padding: 5px 10px;
    width: fit-content;

    :hover {
        background-color: var(--blue-abel-hover);
        cursor: pointer;
    }
`;

export const MultiOptionContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px 30px;

    @media ${device.minwidth.mobile} {
        :last-child {
            grid-column-start: 5;
        }
    }

    ${props => props.Clickable &&
        `
            @media(hover:hover) {
                :hover {
                    border-color: var(--blue-abel);
                    cursor: pointer;
                }
            }
        `
    }
`;

export const MultiOptionsContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;

    @media ${device.minwidth.mobile} {
        grid-template-columns: repeat(5, 1fr);
    }
`;

export const SelectAllContainer = styled.div`
    margin-left: 20px;
`;

export const SelectedItems = styled(PageText)`
    background-color: var(--blue-abel);
    border-radius: 20px;
    padding: 5px 15px;
`;

export const StyledBottomBar = styled(BottomBar)`
    background-color: var(--blue-astronaut);
    z-index: 100;

    > div {
        flex-direction: column;

        @media ${device.minwidth.ipad} {
            flex-direction: row;
        }
    }
`;

export const WorkoutNoteContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const WorkoutNotesContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div:not(:last-child) {
        margin-bottom: 25px;
    }
`;