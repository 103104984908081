import React, { createContext, forwardRef } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// import history from '../../history';
import { GetOrganizationRegionsAndBranches, ViewDataCenterSuperAdminDashboard } from '../../Services/Actions';

import { FixedSizeList as List } from "react-window";
import AutoSizer from 'react-virtualized-auto-sizer';

import Filters from './Dashboard_Filter';
// import Header from './Header';

import * as styles from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Info from '../../Modals/Info';

import { DynamicSort } from '../../Functions';

import DownArrow from '../../Assets/Icons/DownArrow.png';
import { ReactComponent as FilterSVG } from '../../Assets/SVG/Filter.svg';
import UpArrow from '../../Assets/Icons/UpArrow.png';

const ITEM_SIZE = 35;

const StickyListContext = createContext();
StickyListContext.displayName = "StickyListContext";

const TABLE_COLUMNS = [
    { Id: 1, SortCol: 'DisplayName', Title: 'Name' },
    { Id: 2, SortCol: 'OrgNames', Title: 'Chain/Gym' },
    { Id: 3, SortCol: 'LastUsedBranch', Title: 'Last Used Branch' },
    { Id: 4, SortCol: 'PaidLast30', Title: 'Paid last 30' },
    { Id: 5, SortCol: 'PaidLast90', Title: 'Paid last 90' },
    { Id: 6, SortCol: 'PaidLast1Year', Title: 'Paid last year' },
    { Id: 7, SortCol: 'DisplayName', Title: 'Name' },
    { Id: 8, SortCol: 'SoldLast30', Title: 'Sold last 30' },
    { Id: 9, SortCol: 'SoldLast90', Title: 'Sold last 90' },
    { Id: 10, SortCol: 'SoldLast1Year', Title: 'Sold last year' },
    { Id: 11, SortCol: 'NumContracts30', Title: 'Contracts last 30' },
    { Id: 12, SortCol: 'NumContracts90', Title: 'Contracts last 90' },
    { Id: 13, SortCol: 'NumContracts1Year', Title: 'Contracts last year' },
    { Id: 14, SortCol: 'PendingPayments', Title: 'Pending payment (w/o fee)' },
    { Id: 15, SortCol: 'DisplayName', Title: 'Name' },
    { Id: 16, SortCol: 'NumPayingCustomers', Title: '# paying customers' },
    { Id: 17, SortCol: 'NumClients', Title: '# admin clients' },
    { Id: 18, SortCol: 'CompletedWeeklySessions', Title: '# weekly completed sessions for clients' },
    { Id: 19, SortCol: 'CompletedWeeklySessionsPercent', Title: 'Completion % clients' },
    { Id: 20, SortCol: 'TotalExerciseRecords', Title: '# exercise records for clients last 7 days' },
    { Id: 21, SortCol: 'MonthlySessionsForCoach', Title: 'Monthly completed sessions for coach' },
    { Id: 22, SortCol: 'DisplayName', Title: 'Name' }
]

const ListComponent = ({ items, Locale_Language, OnToggleSorting, SortCol: StateSortCol, SortDir: StateSortDir }) => {
    const Row = ({ index, style }) => {
        var { DisplayName, OrgNames, LastUsedBranch, SoldLast30, SoldLast90, SoldLast1Year, PaidLast30, PaidLast90, PaidLast1Year, NumContracts30, NumContracts90, NumContracts1Year, PendingPayments, NumPayingCustomers, NumClients, CompletedWeeklySessions, CompletedWeeklySessionsPercent, TotalExerciseRecords, MonthlySessionsForCoach } = items[index];

        return (
            <tr style={style}>
                <styles.TDCell Bold>{DisplayName}</styles.TDCell>
                <styles.TDCell>{OrgNames}</styles.TDCell>
                <styles.TDCell>{LastUsedBranch}</styles.TDCell>
                <styles.TDCell IsNumber>{new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(PaidLast30)}</styles.TDCell>
                <styles.TDCell IsNumber>{new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(PaidLast90)}</styles.TDCell>
                <styles.TDCell IsNumber>{new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(PaidLast1Year)}</styles.TDCell>
                <styles.TDCell Bold>{DisplayName}</styles.TDCell>
                <styles.TDCell IsNumber>{new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(SoldLast30)}</styles.TDCell>
                <styles.TDCell IsNumber>{new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(SoldLast90)}</styles.TDCell>
                <styles.TDCell IsNumber>{new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(SoldLast1Year)}</styles.TDCell>
                <styles.TDCell IsNumber>{new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(NumContracts30)}</styles.TDCell>
                <styles.TDCell IsNumber>{new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(NumContracts90)}</styles.TDCell>
                <styles.TDCell IsNumber>{new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(NumContracts1Year)}</styles.TDCell>
                <styles.TDCell IsNumber>{new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(PendingPayments)}</styles.TDCell>
                <styles.TDCell Bold>{DisplayName}</styles.TDCell>
                <styles.TDCell IsNumber>{new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(NumPayingCustomers)}</styles.TDCell>
                <styles.TDCell IsNumber>{new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(NumClients)}</styles.TDCell>
                <styles.TDCell IsNumber>{new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(CompletedWeeklySessions)}</styles.TDCell>
                <styles.TDCell>{CompletedWeeklySessionsPercent}</styles.TDCell>
                <styles.TDCell IsNumber>{new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(TotalExerciseRecords)}</styles.TDCell>
                <styles.TDCell IsNumber>{new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(MonthlySessionsForCoach)}</styles.TDCell>
                <styles.TDCell Bold>{DisplayName}</styles.TDCell>
            </tr>
        );
    }

    const StickyRow = ({ style }) => (
        <tr className="sticky" style={style}>
            {
                TABLE_COLUMNS.map(({ Id, DataType, SortCol, Title }) => {
                    return (
                        <styles.THCell key={Id} onClick={() => OnToggleSorting({ SortCol })}>
                            <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Title} />
                            {StateSortCol === SortCol && <styles.SortingIcon ImageSrc={StateSortDir === 'DESC' ? DownArrow : UpArrow} />}
                        </styles.THCell>
                    );
                })
            }
        </tr>
    );
    
    const StickyList = ({ children, stickyIndices, ...rest }) => (
        <StickyListContext.Provider value={{ ItemRenderer: children, stickyIndices }}>
            <List itemData={{ ItemRenderer: children, stickyIndices }} {...rest}>
                {ItemWrapper}
            </List>
        </StickyListContext.Provider>
    );
    
    const ItemWrapper = ({ data, index, style }) => {
        const { ItemRenderer, stickyIndices } = data;
    
        if (stickyIndices && stickyIndices.includes(index)) return null;
    
        return <ItemRenderer index={index} style={style} />;
    };
    
    const innerElementType = forwardRef(({ children, ...rest }, ref) => (
        <StickyListContext.Consumer>
            {() => (
                <table ref={ref} {...rest}>
                    <tbody>
                        <StickyRow style={{ top: 0, left: 0, width: "100%", height: ITEM_SIZE }} />
    
                        {children}
                    </tbody>
                </table>
            )}
        </StickyListContext.Consumer>
    ));

    return (
        <AutoSizer className="auto-sizer">
            {({ height, width }) => (
                <StickyList
                    className="data-center-table"
                    height={height}
                    innerElementType={innerElementType}
                    itemCount={items.length}
                    itemSize={ITEM_SIZE}
                    stickyIndices={[0]}
                    width={width}
                >
                    {Row}
                </StickyList>
            )}
        </AutoSizer>
    );
}

class DataCenter extends React.Component {
    state = {
        HasLoadedFilters: false,
        FirstLoad: false,
        FiltersUpdated: false,
        FilterOptions: {
            Branches: [],
            Regions: []
        },
        Filters: {
            Branches: [],
            DisplayName: '',
            Regions: []
        },
        Sorting: {
            SortCol: 'DisplayName',
            SortDir: 'ASC'
        },
        DataCenterDashboard: [],
        Locale_Language: +this.props.UserDetails.LanguageId === 2 ? 'no-NO' : 'en-US',
        ShowFiltersModals: false
    };
    
    componentDidMount() {
        // if (!this.props.UserDetails.DataCenterAccess) history.push('/clients');
        // else this.onLoadDataCenter()
        this.onLoadDataCenter();
    }

    onBlurFilter = () => {
        if (this.state.FiltersUpdated) {
            this.setState({ FiltersUpdated: false, PageNo: 1 }, () => {
                this.onLoadDataCenter();
            })
        }
    }
    
    onChangeFilter = ({ Data, Type }) => {
        var Filters = { ...this.state.Filters };
        Filters[Type] = Data;

        // this.setState({ Filters, FiltersUpdated: true });

        this.setState({ Filters, PageNo: 1 }, () => {
            this.onLoadDataCenter();
        })
    }

    onLoadFilters = () => {
        if (this.state.HasLoadedFilters) return null;

        this.props.GetOrganizationRegionsAndBranches().then(({ Branches, Organizations }) => {
            var FilterOptions = { ...this.state.FilterOptions, Branches, Organizations };
            this.setState({ HasLoadedFilters: true, FilterOptions });
        })
    }

    onLoadDataCenter = () => {
        // var { Filters, Sorting } = this.state;
        // var { Branches, Organizations, SubscriptionStatus } = Filters;

        // var BranchIds = Branches.map(({ BranchId }) => BranchId).join(',');
        // var OrgIds = Organizations.map(({ OrganizationId }) => OrganizationId).join(',');
        // var SubStatus = SubscriptionStatus.map(({ SubStatusId }) => SubStatusId).join(',');
        // var { SortCol, SortDir } = Sorting;

        this.props.ViewDataCenterSuperAdminDashboard().then(({ SuperAdminDashboard: DataCenterDashboard }) => {
            if (DataCenterDashboard.length) DataCenterDashboard = [ { DisplayName: '' }, ...DataCenterDashboard ]; // Add Empty Row Which Hides Behind Sticky Row
            this.setState({ FirstLoad: true, DataCenterDashboard });
        })
    }

    onToggleShowFiltersModal = ShowFiltersModals => {
        this.setState({ ShowFiltersModals });
    }

    onToggleSorting = ({ SortCol: NewSortCol }) => {
        if (!this.state.DataCenterDashboard.length) return null;

        var { SortCol, SortDir } = this.state.Sorting;

        // SortDir Goes From ASC --> DESC --> Nothing

        // Toggle Sorting Of Already Sorted Column
            if (SortCol === NewSortCol) {
                if (SortDir === 'ASC') SortDir = 'DESC';
                else if (SortDir === 'DESC') {
                    SortDir = '';
                    SortCol = '';
                }
                else if (SortDir === '') SortDir = 'ASC';
            }
        // Start Sorting On New Column
            else {
                SortCol = NewSortCol;
                SortDir = 'ASC';
            }

        // Remove first empty object
            var DataCenterDashboard = [ ...this.state.DataCenterDashboard ];
            DataCenterDashboard.splice(0, 1)

        // Sort and Add Empty Object In Again
            var NewDataCenterDashboard = [ { DisplayName: '' }, ...DataCenterDashboard.sort(DynamicSort({ SortCol, SortDir })) ];

        this.setState({ DataCenterDashboard: NewDataCenterDashboard, Sorting: { SortCol, SortDir }})
    }

    onTypeSearchInput = ({ ContentType, Text }) => {
        var Filters = { ...this.state.Filters };
        Filters[ContentType] = Text;

        this.setState({ Filters });
    }

    renderBody = () => {
        var { t } = this.props;
        var { Device, TryingGetOrganizationRegionsAndBranches } = this.props;
        var { Branches, DisplayName, Regions } = this.state.FilterOptions;

        return (
            <>
                {/* {<Header PageTitle={this.props.PageTitle} />} */}

                {
                    Device === 'laptop' &&
                    <Filters
                        Branches={Branches}
                        DisplayName={DisplayName}
                        Regions={Regions}
                        Loading={TryingGetOrganizationRegionsAndBranches}
                        OnBlurFilter={this.onBlurFilter}
                        OnChangeFilter={this.onChangeFilter}
                        OnFocusFilter={this.onLoadFilters}
                        OnTypeSearchInput={this.onTypeSearchInput}
                    />
                }

                <styles.BodyContainer className="BodyContainer">
                    {
                        Device !== 'laptop' &&
                        <>
                            <ButtonGroup
                                Buttons={[{ BackgroundColor: "white-concrete", BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', IconOnly: true, IconSVG: FilterSVG, IconSVGColor: 'black', IconPosition: 'left', OnClick: () => this.onToggleShowFiltersModal(true), Title: t('_select_filters') }]}
                                NotTouching
                            />

                            <Spacer Size="small" />
                        </>
                    }

                    <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`Num Coaches: ${this.state.DataCenterDashboard.length ? this.state.DataCenterDashboard.length - 1 : 0}`} />

                    {this.renderDashboard()}
                </styles.BodyContainer>
            </>
        );
    }

    renderDashboard = () => {
        var { DataCenterDashboard, Filters, Sorting: { SortCol, SortDir } } = this.state;

        if (!DataCenterDashboard.length) return null;

        if (Filters.DisplayName !== '') DataCenterDashboard = DataCenterDashboard.filter(Trainer => (Trainer.DisplayName.toLowerCase().indexOf(Filters.DisplayName.toLowerCase()) > -1 || Trainer.DisplayName === ''));

        return <ListComponent items={DataCenterDashboard} OnToggleSorting={this.onToggleSorting} SortCol={SortCol} SortDir={SortDir} />
    }

    renderFiltersModal_Info = () => {
        var { TryingGetDataCenterDashboard, TryingGetOrganizationRegionsAndBranches } = this.props;
        var { Branches, DisplayName, Organizations, SubscriptionStatus } = this.state.FilterOptions;
        var { Branches: SelectedBranches, Organizations: SelectedOrganizations, SubscriptionStatus: SelectedSubscriptionStatus } = this.state.Filters;
        
        return (
            <Filters
                Branches={Branches}
                DisplayName={DisplayName}
                Organizations={Organizations}
                SubscriptionStatus={SubscriptionStatus}
                SelectedContent={{
                    Branches: SelectedBranches,
                    Organizations: SelectedOrganizations,
                    SubscriptionStatus: SelectedSubscriptionStatus
                }}
                IsModal
                Loading={TryingGetDataCenterDashboard || TryingGetOrganizationRegionsAndBranches}
                OnBlurFilter={this.onBlurFilter}
                OnChangeFilter={this.onChangeFilter}
                OnFocusFilter={this.onLoadFilters}
            />
        );
    }

    renderFiltersModal = () => {
        var { ShowFiltersModals } = this.state;

        if (ShowFiltersModals) {
            return (
                <Info
                    BottomButton={null}
                    InformationRenderer={this.renderFiltersModal_Info}
                    OnHide={() => this.onToggleShowFiltersModal(false)}
                    Show={ShowFiltersModals}
                />
            );
        }
    }

    render() {
        var { TryingGetDataCenterDashboard, TryingGetOrganizationRegionsAndBranches } = this.props;

        return (
            <>
                {(TryingGetDataCenterDashboard || TryingGetOrganizationRegionsAndBranches) && <Loading />}

                {this.renderBody()}

                {this.renderFiltersModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        OrganizationRegionsAndBranches: state.Income.OrganizationRegionsAndBranches,

        TryingGetDataCenterDashboard: state.DataCenter.TryingGetDataCenterDashboard,
        TryingGetDataCenterDashboardError: state.DataCenter.TryingGetDataCenterDashboardError,
        TryingGetOrganizationRegionsAndBranches: state.Income.TryingGetOrganizationRegionsAndBranches,
        TryingGetOrganizationRegionsAndBranchesError: state.Income.TryingGetOrganizationRegionsAndBranchesError
    };
};

export default withTranslation()(connect(mapStateToProps, { GetOrganizationRegionsAndBranches, ViewDataCenterSuperAdminDashboard })(DataCenter));