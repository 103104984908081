import ReactGA from "react-ga";
import history from '../../history';

import { GA_TRACKING_ID } from '../../Config';

export const GA_Init = ({ UserId }) => {
    ReactGA.initialize(GA_TRACKING_ID);
    UserId && ReactGA.set({ UserId });
}

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const GA_Event = ({ Category: category, Action: action, Label: label }) => {
    ReactGA.event({ category, action, label });
}

export const GA_ModalView = GAPathName => {
    GAPathName = `Modal/${GAPathName}`;

    ReactGA.set({ page: GAPathName }); // Update the user's current page
    ReactGA.pageview(GAPathName); // Record a pageview for the given page
}

export const GA_PageView = GAPathName => {
    GAPathName = GAPathName || GetGAPathName();

    if (GAPathName) {
        ReactGA.set({ page: GAPathName }); // Update the user's current page
        ReactGA.pageview(GAPathName); // Record a pageview for the given page
    }
}

const GetGAPathName = () => {
    var CurrentPathname = history.location.pathname;
    var GAPathName = CurrentPathname;

    if (CurrentPathname === '/library') return null; // Individual Library Tabs Are Calling Their Own PageViews

    if (CurrentPathname === '/') GAPathName = 'MyClients-Dashboard';

    else if (CurrentPathname.includes('/client')) GAPathName = 'Client-Detail';

    else if (CurrentPathname.includes('/group')) GAPathName = 'Group-Detail';

    else if (CurrentPathname.includes('/library/dish')) GAPathName = 'Dish-Detail';
    else if (CurrentPathname.includes('/library/exercise')) GAPathName = 'Exercise-Detail';
    else if (CurrentPathname.includes('/library/food')) GAPathName = 'Food-Detail';
    else if (CurrentPathname.includes('/library/meal-template')) GAPathName = 'MealTemplate-Detail';
    else if (CurrentPathname.includes('/library/workout-template')) GAPathName = 'WorkoutTemplate-Detail';

    else if (CurrentPathname.includes('/meal')) GAPathName = 'MealDish-Detail';

    else if (CurrentPathname.includes('/messages')) GAPathName = 'Messages';

    else if (CurrentPathname.includes('/workout')) GAPathName = 'Workout-Detail';

    else if (CurrentPathname.includes('/add-additional-activity')) GAPathName = 'AdditionalActivity-Add';
    else if (CurrentPathname.includes('/add-dish')) GAPathName = 'Dish-Add';
    else if (CurrentPathname.includes('/add-meal-template')) GAPathName = 'MealTemplate-Add';
    else if (CurrentPathname.includes('/add-single-generated-workout')) GAPathName = 'SingleGeneratedWorkout-Add';
    else if (CurrentPathname.includes('/add-workout-plan')) GAPathName = 'WorkoutPlan-Add';
    else if (CurrentPathname.includes('/add-workout-template')) GAPathName = 'WorkoutTemplate-Add';

    else if (CurrentPathname === '/notifications') GAPathName = 'Notifications';

    else if (CurrentPathname === '/plan') GAPathName = 'Organize';

    else if (CurrentPathname === '/login') GAPathName = 'Login';

    return GAPathName;
}