import styled from 'styled-components';
import { device } from '../../Config/device';

import Popover from 'react-bootstrap/Popover';
import TimePicker from 'rc-time-picker';

import ButtonGroup from '../Buttons/ButtonGroup';
import Icon from '../Icon';
import Image from '../Image';
import Input from '../Form/Input';
import MultiText from '../Text/MultiText';
import PageText from '../Text/PageText';
import TextArea from '../Form/TextArea';
import WorkoutCircuits from '../WorkoutCircuits';

import Checkmark from '../../Assets/Icons/Checkmark_Selected.png';

export const ActivityPictureContainer = styled.div`
    border: 4px solid ${props => props.Selected ? 'var(--blue-abel)' : 'transparent'};
    border-radius: 15px;
    width: 150px;

    @media(hover:hover) {
        :hover {
            border: 4px solid var(--blue-abel);
            cursor: pointer;
        }
    }
`;

export const ActivityPicturesContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;

export const AddWorkoutsBodyContainer = styled.div``;

export const AddWorkoutsCollapsedContainer = styled.div`
    align-items: center;
    background-color: var(--blue-abel);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;

    @media(hover:hover) {
        :hover {
            background-color: var(--blue-abel-hover);
            cursor: pointer;
        }
    }
`;

export const AddWorkoutsCollapsedIcon = styled(Icon)`
    height: 20px;
    margin-right: 10px;
    max-width: 20px;
`;

export const AddWorkoutsOuterContainer = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
`;

export const AddWorkoutsParentContainer = styled.div`
    padding: 20px 25px;
`;

export const AddWorkoutsTopHeaderContainer = styled.div``;

export const AddWorkoutsTopHeaderContainerOne = styled.div``;

export const AddWorkoutsTopHeaderContainerTwo = styled.div``;

export const AssigningToColumnOne = styled.div`
    @media ${device.maxwidth.ipad} {
        border-bottom: 2px solid var(--white);
        padding-bottom: 15px;
    }

    @media ${device.minwidth.laptop} {
        border-right: 2px solid var(--white);
        padding-right: 15px;
    }
`;

export const AssigningToColumnThree = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

export const AssigningToColumnTwo = styled.div`
    @media ${device.maxwidth.ipad} {
        border-bottom: 2px solid var(--white);
        padding-bottom: 15px;
    }

    @media ${device.minwidth.laptop} {
        border-right: 2px solid var(--white);
        padding-right: 15px;
    }
`;

export const AssigningToThreeColumnsContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;

    @media ${device.minwidth.laptop} {
        grid-template-columns: 1fr 1fr 2fr;
    }
`;

export const AttachSurveysContainer = styled.div``;

export const AttachSurveysOptionContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
`;

export const BackNextButtons = styled(ButtonGroup)`
    > div {
        :first-child {
            border-top-left-radius: 0px
        }

        :last-child {
            border-top-right-radius: 0px;
        }
    }
`;

export const CircuitContainer = styled.div`
    ${
        props => props.CircuitType !== 'WorkoutExercise' &&
        `
            border: 5px solid ${props.CircuitType === 'Circuit' ? 'var(--turquoise-dark)' : 'var(--blue-royal)'};   
        `
    }
    border-radius: 10px;
`;

export const CircuitSeparator = styled.div`
    align-items: center;
    background-color: var(--${props => props.BackgroundColor});
    display: flex;
    flex-direction: column;
    height: ${props => props.Collapsed ? '5px' : 'var(--spacer-size-medium)'};
    justify-content: center;
`;

export const Container = styled.div`
    border-radius: 10px;
    display: grid;

    @media ${device.minwidth.laptop} {
        grid-template-areas: "body filter";
        grid-template-columns: 1fr 300px;
    }

    @media ${device.maxwidth.ipad} {
        grid-template-areas: "header" "body";
        grid-template-columns: 1fr;
    }

    // grid-template-rows: 53px 1fr;
    overflow: hidden;
    width: 100%;
`;

export const CustomizationContainer = styled.div`
    margin-top: 10px;
    width: 100%;
`;

export const CustomizationItemContainer = styled.div`
    :not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const CustomizationItemContainerHeader = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 7px;
`;

export const CustomizationTextInput = styled(Input)`
    opacity: ${props => props.Opacity};

    > input {
        background-color: var(--white-concrete);
    }
`;

export const DateTimeContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
    margin-top: 15px;
    width: 175px;

    > span > input {
        background-color: var(--white);
    }
`;

export const DateTimeParentContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const DateTimesContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -15px;
    margin-top: -15px;
`;

export const DateTimeUseAllDays = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    display: block;
    height: 25px;
    margin-right: 5px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
            border: none;
            cursor: pointer;
        }
    }

    :nth-child(2) {
        max-width: 600px;
    }
`;

export const DateTimeUseAllDaysContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
`;

export const DeleteSetIcon = styled(Image)`
    height: 20px;
    width: 20px;
`;

export const HourType = styled.div`
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    padding: 6px 15px;

    :hover {
        cursor: pointer;
    }
`;

export const HourTypesContainer = styled.div`
    align-items: center;
    border: 2px solid var(--blue-astronaut);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    min-height: 35px;
    margin-right: 15px;
    overflow: hidden;
    width: fit-content;
`;

export const HoverInfoIcon = styled(Icon)`
    height: 20px;
    width: 20px;

    ${
        props => !props.NotAbsolute &&
        `
            @media (min-width: 901px) {
                position: absolute;
                right: 10px;
                top: 10px;
            }
        `
    }
`;

export const InputLabelContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const InputLabelOptionalContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    > div:first-child {
        margin-right: 7px;
    }

    ${props => !!props.MarginLeft && 'margin-left: 15px;'}
`;

export const InputParentContainer = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

export const IntensityContainer = styled.div``;

export const LapContainer = styled.div`
    align-items: stretch;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

    @media ${device.minwidth.laptop} {
        display: grid;
        grid-template-columns: 1fr 8.5fr 0.5fr;
    }
`;

export const LapDetailsContainer = styled.div`
    width: 100%;
`;

export const LapFooterContainer = styled.div`
    align-items: center;
    background-color: var(--blue-astronaut);
    display: flex;
    justify-content: center;

    @media(hover:hover) {
        :hover {
            background-color: var(--blue-astronaut-hover);
            cursor: pointer;
        }
    }
`;

export const LapHeaderContainer = styled.div`
    align-items: center;
    background-color: ${props => !!props.ActiveLap ? 'var(--blue-astronaut)' : 'var(--orange)'};
    display: flex;
    flex-direction: row;
    padding: 5px;
    width: 100%;

    @media ${device.minwidth.laptop} {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

export const LapHeaderContainerLeft = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;

    @media ${device.minwidth.laptop} {
        justify-content: center
        width: 100%;
    }
`;

export const LapHeaderContainerRight = styled.div``;

export const MenuItem = styled.div`
    align-items: center;
    background-color: var(--white);
    border: 5px solid;
    border-radius: 10px;
    color: var(--black);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 5px;
    padding: 10px;
    position: relative;
    width: 100%;

    @media ${device.minwidth.ipad} {
        flex-direction: column;
        justify-content: center;
        max-width: 250px;
        min-width: 200px;
        margin: 15px;
    }

    ${props => props.Selected ? `border-color: var(--blue-abel);` : `border-color: var(--white);`}

    @media(hover:hover) {
        :hover {
            border-color: var(--blue-abel);
            color: var(--link-hover);
            cursor: pointer;
        }
    }
`;

export const MenuItemContainerFirst = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const MenuItemIllustration = styled.img`
    height: 60px;

    @media ${device.maxwidth.mobile} {
        margin-left: 10px;
        margin-right: 10px;
    }

    @media ${device.minwidth.ipad} {
        height: 120px;
    }
`;

export const MenuOptionsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: -5px;

    @media ${device.minwidth.ipad} {
        margin: -15px;
    }
`;

export const OptionalInfoContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
`;

export const OptionalInfoInput = styled(Input)`
    > input {
        border-radius: 8px;
        height: 45px;
    }
`;

export const OptionalInfoLeftContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr;

    @media ${device.minwidth.laptop} {
        grid-template-columns: 1fr 1fr;
    }
`;

export const OptionalInfoRightContainer = styled.div``;

export const OptionalInformationIcon = styled(Icon)`
    max-width: 15px;
`;

export const PopoverContent = styled.div`
    align-items: center;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 200px;
    padding: 10px;
`;

export const ReadMoreLessContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const ReadMoreText = styled(PageText)`
    text-decoration: underline;
    text-decoration-color: var(--black);
`;

export const RestDayContainer = styled.div`
    align-items: center; 
    background-color: var(--orange);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    padding: 10px;
`;

export const RestDayImage = styled(Image)`
    max-width: 80px;
`;

export const SaveChangesContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const SaveOptionsContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    > div:not(:last-child) {
        margin-bottom: 5px;
    }
`;

export const SelectedDatesContainer = styled.div`
    @media ${device.minwidth.laptop} {
        padding: 0 15px;
    }
`;

export const SessionDetailHeaderTwoColumns = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;

    @media ${device.minwidth.ipad} {
        grid-template-columns: 1fr 2fr;
    }
`;

export const StyledActivityImage = styled(Image)`
    border-radius: 10px;
    height: 130px;
    object-fit: cover;
    width: 100%;

    > div {
        width: 100%;
    }
`;

export const StyledAddLap = styled(ButtonGroup)`
    justify-content: flex-start;

    @media ${device.maxwidth.mobile_small} {
        width: 100%;
    }
`;

export const StyledHR = styled.div`
    background-color: var(--white);
    border-radius: 10px;
    height: 2px;
    margin: 20px 0px;
    width: 100%;
`;

export const StyledInputLabelMultiText = styled(MultiText)`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 10px;

    ${props => props.TextAlign === 'left' && 'justify-content: flex-start;'}
`;

export const StyledPopover = styled(Popover)`
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 1px 1px 8px #5A5A5A29;    
    ${props => (props.calendartype || props.showcalendar) && 'padding: 15px'};
    max-width: 300px;
    // width: 300px;
    overflow: hidden;
`;

export const StyledSaveTemplateButtonGroup = styled(ButtonGroup)`
    max-width: 250px;
`;

export const StyledTimePicker = styled(TimePicker)`
    opacity: ${props => props.Opacity};
    width: 100%;

    > input {
        background-color: var(--white-concrete);
        border-radius: 20px;
        font-family: var(--font-family-medium);
        font-size: var(--font-size-small);
    }
`;

export const StyledWorkoutCircuits = styled(WorkoutCircuits)`
    align-items: flex-start;
`;

export const StyledWorkoutNoteTextArea = styled(TextArea)`
    margin-bottom: 0px;
    opacity: ${props => props.Opacity};
    width: 100%;

    > textarea {
        background-color: var(--white-concrete);
        border: none;
        padding: 5px;
        resize: none;
    }
`;

export const TemplateIcon = styled.img`
    height: 20px;
    width: 20px;
`;

export const TrainingProgramAddDayContainer = styled.div`
    align-items: center;
    background-color: var(--white);
    border: 2px dashed var(--black);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: center;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete-light);
            cursor: pointer;
        }
    }
`;

export const TrainingProgramAddDayIcon = styled(Icon)`
    height: 30px;
    margin-bottom: 10px;
    max-width: 30px;
`;

export const TrainingProgramDayContainer = styled.div`
    transition: background-color 0.2s ease;
    ${props => (props.isDraggingOver && 'background-color: skyblue;')}
    flex-grow: 1;
    // min-height: 200px;

    > div:not(:last-child) {
        margin-bottom: 20px;
    }
`;

export const TrainingProgramDayHeaderContainer = styled.div`
    align-items: center;
    background-color: ${props => !!props.IsRestDay ? 'var(--orange)' : 'var(--blue-astronaut)'};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 15px;
    padding: 8px;
`;

export const TrainingProgramDayHeaderTopRowContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const TrainingProgramDaysContainer = styled.div`
    display: grid;
    grid-gap: 40px 15px;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
`;

export const TrainingProgramTitleContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div:first-child {
        margin-right: 15px;
    }
`;

export const UniqueWorkoutTemplatesContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    margin: 30px 0px;
    max-width: 100%;
`;

export const UploadButtonsContainer = styled(ButtonGroup)`
    justify-content: center;
`;

export const UploadContainer = styled.div`
    margin-top: 10px;
`;

export const UploadButtonsRow = styled.div`
    margin-bottom: 10px;
`;

export const WorkoutExercisesContainer = styled.div``;

export const WorkoutLapsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const WorkoutLapsOptionsContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const WorkoutLapsOptionsIntensityContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 15px;
`;

export const WorkoutLapsOptionsMeasureTypeContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const WorkoutNoteTextArea = styled(TextArea)`
    background-color: var(--white);
    border-radius: 10px;
    margin-bottom: 0px;
    padding: 10px;
    width: 100%;

    > textarea {
        background-color: var(--white);
        border: none;
        padding: 0px;
        resize: none;
    }
`;

export const WorkoutTemplateCalendarOptionContainer = styled.div`
    padding: 10px;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete-light);
            cursor: pointer;
        }
    }
`;

export const WorkoutTemplateCalendarOptionsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;

    > div:first-child {
        border-bottom: 1px solid var(--white-concrete);
    }
`;

export const WorkoutTemplateContainer = styled.div`
    overflow: hidden;

    &:last-child {
        margin-bottom: 0px;
    }
`;

export const WorkoutTemplateContainerBody = styled.div`
    align-items: flex-start;
    background-color: var(--white);
    // border-bottom-left-radius: 10px;
    // border-bottom-right-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const WorkoutTemplateContainerHeader = styled.div`
    align-items: flex-start;
    background-color: var(--white);
    border-bottom: 2px solid var(--white-concrete);
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const WorkoutTemplateContainerHeaderIcon = styled(Icon)`
    max-height: 15px;
    max-width: 15px;
`;

export const WorkoutTemplateContainerHeaderIconContainer = styled.div`
    background-color: var(--blue-abel);
    border-radius: 10px;
    height: 30px;
    margin-right: 3px;
    padding: 7px;

    @media(hover:hover) {
        :hover {
            background-color: var(--blue-abel-hover);
            cursor: pointer;
        }
    }
`;

export const WorkoutTemplateContainerHeaderTopRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
    width: 100%;
`;

export const WorkoutTemplateContainerHeaderTopRowRight = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const WorkoutTemplateInnerContainer = styled.div`
    border: 2px solid ${props => !!props.IsModified ? 'var(--green)' : !!props.IsModifiedInSync ? 'var(--orange)' : 'var(--white)'};
    border-radius: 10px;
    overflow: hidden;
`;