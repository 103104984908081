import { combineReducers } from 'redux';
import AdditionalActivities from './AdditionalActivities';
import Auth from './Auth';
import Client from './Client';
import ClientDashboard from './ClientDashboard';
import Clients from './Clients';
import Companies from './Companies';
import ContentRequests from './ContentRequests';
import DataCenter from './DataCenter';
import DeliverySite from './DeliverySite';
import Dish from './Dish';
import Dishes from './Dishes';
import Endurance from './Endurance';
import Endurances from './Endurances';
import Exercise from './Exercise';
import Exercises from './Exercises';
import Food from './Food';
import Group from './Group';
import Groups from './Groups';
import GroupWorkout from './GroupWorkout';
import Gym from './Gym';
import Income from './Income';
import Meal from './Meal';
import Meals from './Meals';
import Notifications from './Notifications';
import Nutrition from './Nutrition';
import Progress from './Progress';
import Sales from './Sales';
import SaveData from './SaveData';
import Tasks from './Tasks';
import Templates from './Templates';
import UploadContent from './UploadContent';
import UserAccounts from './UserAccounts';
import VideoSession from './VideoSession';
import Window from './Window';
import Workout from './Workout';
import Workouts from './Workouts';

export default combineReducers({
  AdditionalActivities, Auth, Client, ClientDashboard, Clients, Companies, ContentRequests, DataCenter, DeliverySite, Dish, Dishes, Endurance, Endurances, Exercise, Exercises, Food, Group, Groups, GroupWorkout, Gym, Income, Meal, Meals, Notifications, Nutrition, Progress, Sales, SaveData, Tasks, Templates, UploadContent, UserAccounts, VideoSession, Window, Workout, Workouts
});