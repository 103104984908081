import React from 'react';
import { withTranslation } from 'react-i18next';

import * as Styles from './styles';

import Dashboard from './Dashboard';
import Page from '../../Components/Page';

class OrgIncome extends React.Component {
    _isMounted = false;

    state = {
        PageTitle: ''
    }

    componentDidMount() {
        this._isMounted = true;

        // const QueryValues = queryString.parse(this.props.location.search);

        // var PageTitle = '';

        // if (Object.entries(QueryValues).length > 0 && QueryValues.tab) {
        //     if (QueryValues.tab.toLowerCase() === 'income') PageTitle = 'Income';
        //     else if (QueryValues.tab.toLowerCase() === 'organize') PageTitle = 'Organize';
        //     else if (QueryValues.tab.toLowerCase() === 'contracts') PageTitle = 'Contracts';
        //     else if (QueryValues.tab.toLowerCase() === 'invoices') PageTitle = 'Invoices';
        // } else history.push('/income?tab=income')

        var PageTitle = 'Dashboard';
        this.setState({ PageTitle });
    }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.location.search !== this.props.location.search) {
    //         const QueryValues = queryString.parse(this.props.location.search);

    //         var PageTitle = '';

    //         if (Object.entries(QueryValues).length > 0 && QueryValues.tab) {
    //             if (QueryValues.tab.toLowerCase() === 'income') PageTitle = 'Income';
    //             else if (QueryValues.tab.toLowerCase() === 'organize') PageTitle = 'Organize';
    //             else if (QueryValues.tab.toLowerCase() === 'contracts') PageTitle = 'Contracts';
    //             else if (QueryValues.tab.toLowerCase() === 'invoices') PageTitle = 'Invoices';
    //         } else history.push('/income?tab=income')

    //         this.setState({ PageTitle });
    //     }
    // }

    renderBody = () => {
        var { PageTitle } = this.state;

        if (PageTitle === 'Dashboard') return <Dashboard PageTitle={PageTitle} />

        return null;
    }

    render() {
        return (
            <>
                <Page NoMargin NoPadding>
                    <Styles.PageContainer className="PageContainer">
                        {this.renderBody()}
                    </Styles.PageContainer>
                </Page>
            </>
        );
    }
}

export default withTranslation()(OrgIncome);