import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Container, RatingImage } from './styles';

import AbelModal from '../../Components/Modal';
import Loading from '../../Components/Loading';

import Blocked from '../../Assets/Icons/Ratings/Blocked_White.png';
import Blocked_Selected from '../../Assets/Icons/Ratings/Blocked_Filled.png';
import FourStar from '../../Assets/Icons/Ratings/4Star_White.png';
import FourStar_Selected from '../../Assets/Icons/Ratings/4Star_Filled.png';
import FiveStar from '../../Assets/Icons/Ratings/5Star_White.png';
import FiveStar_Selected from '../../Assets/Icons/Ratings/5Star_Filled.png';
import ThreeStar from '../../Assets/Icons/Ratings/3Star_White.png';
import ThreeStar_Selected from '../../Assets/Icons/Ratings/3Star_Filled.png';
import TwoStar from '../../Assets/Icons/Ratings/2Star_White.png';
import TwoStar_Selected from '../../Assets/Icons/Ratings/2Star_Filled.png';

class ChooseDishRating extends React.Component {
    _isMounted = false;

    state = { HoveringRating: null, SelectedRating: null };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onClickSubmit = () => {
        var { SelectedRating } = this.state;

        this.props.OnSelectRating(SelectedRating);
    }

    onChangeRating = SelectedRating => {
        this.setState({ SelectedRating });
    }

    onHoverRating = HoveringRating => {
        this.setState({ HoveringRating });
    }

    renderModalBody = () => {
        var { HoveringRating, SelectedRating } = this.state;
        
        var Ratings = [
            { Id: 1, Image: (HoveringRating === 1 || SelectedRating === 1) ? Blocked_Selected : Blocked },
            { Id: 2, Image: (HoveringRating === 2 || SelectedRating === 2) ? TwoStar_Selected : TwoStar },
            { Id: 3, Image: (HoveringRating === 3 || SelectedRating === 3) ? ThreeStar_Selected : ThreeStar },
            { Id: 4, Image: (HoveringRating === 4 || SelectedRating === 4) ? FourStar_Selected : FourStar },
            { Id: 5, Image: (HoveringRating === 5 || SelectedRating === 5) ? FiveStar_Selected : FiveStar }
        ];

        return (
            <Container>
                {
                    Ratings.map(({ Id, Image }) =>
                        <RatingImage
                            key={Id}
                            alt="Rating"
                            onClick={() => this.onChangeRating(Id)}
                            onMouseEnter={() => this.onHoverRating(Id)}
                            onMouseLeave={this.onHoverRating}
                            src={Image}
                        />
                    )
                }
            </Container>
        );
    }

    render() {
        var { t } = this.props;
        var { IsLoading, OnHide, Show } = this.props;
        var { SelectedRating } = this.state;

        return (
            <AbelModal
                BottomButton="submit"
                BottomButtonOnClick={this.onClickSubmit}
                BottomButtonText={t('save_with_first_char_uppercase')}
                Disabled={!SelectedRating}
                GA_PathName="ChooseDishRating"
                Show={Show}
                Size="large"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {IsLoading ? <Loading /> : null}
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

ChooseDishRating.propTypes = {
    IsLoading: PropTypes.bool,
    OnHide: PropTypes.func.isRequired,
    OnSelectRating: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired
}

export default withTranslation()(ChooseDishRating);