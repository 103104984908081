import styled from 'styled-components';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';

export const AbelLogo = styled.img`
    height: ${props => props.IsHeaderMobile ? '28px' : '50px'};
    width: ${props => props.IsHeaderMobile ? '30px' : '55px'};
`;

export const AbelLogoImageContainer = styled.div`
    margin-right: 10px;
`;

export const AbelNameContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const AcceptMembershipContainer = styled.div``;

export const ChooseSubscriptionButtonContainer = styled.div`
    align-items: center;
    background-color: var(--yellow-gold);
    border-radius: 10px;
    bottom: -15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3px 7px;
    position: absolute;
    width: 85%;

    @media(hover:hover) {
        :hover {
            background-color: var(--yellow-gold-dark);
            cursor: pointer;
        }
    }
`;

export const CircularPhotoContainer = styled.div`
    position: absolute;
    top: -30px;
`;

export const FrozenContainer = styled.div`
    height: 100%;
    padding: 30px;
    width: 100%;
`;

export const FrozenStatementContainer = styled.div`
    background-color: var(--white);
    border-radius: 10px;
    padding: 10px;
`;

export const LogoContainer = styled.div`
    align-items: center;
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
`;

export const LogOutContainer = styled.div`
    background-color: var(--white);
    border-radius: 10px;
    padding: 10px;
`;

export const MembershipRequestContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    ${
        props => props.MembershipStatus === 'Pending' &&
        `
            background-color: var(--white-concrete-light);
            border-radius: 10px;
            padding: 10px;
        `
    }
`;

export const MembershipRequestsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div:not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const StyledAcceptMembershipBottomButtons = styled(ButtonGroup)`
    > div {
        :first-child {
            border-top-left-radius: 0px
        }

        :last-child {
            border-top-right-radius: 0px;
        }
    }
`;

export const SubscriptionDescriptionContainer = styled.div`
    width: 100%;
`;

export const SubscriptionDetailsContainer = styled.div``;

export const SubscriptionListContainer = styled.ul`
    margin: 0px;
    padding-left: 20px;
`;

export const SubscriptionPriceContainer = styled.div`
    align-items: center;
    background-color: var(--${props => props.Color});
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    width: calc(100% + 20px);
`;

export const StyledImage = styled.img`
    border-radius: 50%;
    height: ${props => props.Size ? `var(--profilephoto-size-${props.Size.toLowerCase()})` : 'var(--profilephoto-size-large)'};
    width: ${props => props.Size ? `var(--profilephoto-size-${props.Size.toLowerCase()})` : 'var(--profilephoto-size-large)'};
`;

export const SubscriptionsContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    // grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: ${props => props.HasMembershipRequests ? 'minmax(150px, 250px) minmax(150px, 250px) 1fr' : '1fr 1fr'};
    margin-top: 15px;
    max-width: 1200px;

    ${
        props => !props.HasMembershipRequests &&
        `
            margin-left: auto;
            margin-right: auto;
            max-width: 550px;
        `
    }
`;

export const SubscriptionTypeContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete-light);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 10px 30px;
    position: relative;
`;

export const TopRowContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;