import {
    CLEAR_ADDITIONAL_ACTIVITY_DETAILS,

    TRYING_ADD_EXISTING_MEAL_TEMPLATE,
    TRYING_ADD_EXISTING_MEAL_TEMPLATE_SUCCESS,
    TRYING_ADD_EXISTING_MEAL_TEMPLATE_ERROR,

    TRYING_ADD_EXISTING_WORKOUT_TEMPLATE,
    TRYING_ADD_EXISTING_WORKOUT_TEMPLATE_SUCCESS,
    TRYING_ADD_EXISTING_WORKOUT_TEMPLATE_ERROR,

    TRYING_ADD_TRAINING_PROGRAM,
    TRYING_ADD_TRAINING_PROGRAM_SUCCESS,
    TRYING_ADD_TRAINING_PROGRAM_ERROR,

    TRYING_DELETE_MEAL_TEMPLATES,
    TRYING_DELETE_MEAL_TEMPLATES_SUCCESS,
    TRYING_DELETE_MEAL_TEMPLATES_ERROR,

    TRYING_DELETE_TRAINING_PROGRAMS,
    TRYING_DELETE_TRAINING_PROGRAMS_SUCCESS,
    TRYING_DELETE_TRAINING_PROGRAMS_ERROR,

    TRYING_DELETE_WORKOUT_TEMPLATES,
    TRYING_DELETE_WORKOUT_TEMPLATES_SUCCESS,
    TRYING_DELETE_WORKOUT_TEMPLATES_ERROR,

    CLEAR_MEAL_TEMPLATE_DETAIL,
    TRYING_GET_MEAL_TEMPLATE_DETAIL,
    TRYING_GET_MEAL_TEMPLATE_DETAIL_SUCCESS,
    TRYING_GET_MEAL_TEMPLATE_DETAIL_ERROR,

    CLEAR_TRAINING_PROGRAM_DETAIL,
    TRYING_GET_TRAINING_PROGRAM_DETAIL,
    TRYING_GET_TRAINING_PROGRAM_DETAIL_SUCCESS,
    TRYING_GET_TRAINING_PROGRAM_DETAIL_ERROR,

    TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS,
    TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_SUCCESS,
    TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_ERROR,

    CLEAR_WORKOUT_TEMPLATE_DETAIL,
    TRYING_GET_WORKOUT_TEMPLATE_DETAIL,
    TRYING_GET_WORKOUT_TEMPLATE_DETAIL_SUCCESS,
    TRYING_GET_WORKOUT_TEMPLATE_DETAIL_ERROR,

    TRYING_GET_WORKOUT_TEMPLATE_FILTERS,
    TRYING_GET_WORKOUT_TEMPLATE_FILTERS_SUCCESS,
    TRYING_GET_WORKOUT_TEMPLATE_FILTERS_ERROR,
    
    TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS,
    TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_SUCCESS,
    TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_ERROR,

    TRYING_GET_WORKOUT_TEMPLATE_ADDITIONAL_ACTIVITY_DETAILS_SUCCESS,

    TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS,
    TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_SUCCESS,
    TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_ERROR,

    TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES,
    TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_SUCCESS,
    TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_ERROR,

    TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER,
    TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER_SUCCESS,
    TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER_ERROR,

    TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL,
    TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL_SUCCESS,
    TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL_ERROR,

    TRYING_INSERTEDIT_TRAINING_PROGRAM,
    TRYING_INSERTEDIT_TRAINING_PROGRAM_SUCCESS,
    TRYING_INSERTEDIT_TRAINING_PROGRAM_ERROR,

    TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL,
    TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL_SUCCESS,
    TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL_ERROR,

    CLEAR_SEARCH_MEAL_TEMPLATES,
    TRYING_SEARCH_MEAL_TEMPLATES,
    TRYING_SEARCH_MEAL_TEMPLATES_SUCCESS,
    TRYING_SEARCH_MEAL_TEMPLATES_ERROR,

    CLEAR_SEARCH_TRAINING_PROGRAMS,
    TRYING_SEARCH_TRAINING_PROGRAMS,
    TRYING_SEARCH_TRAINING_PROGRAMS_SUCCESS,
    TRYING_SEARCH_TRAINING_PROGRAMS_ERROR,

    CLEAR_SEARCH_WORKOUT_TEMPLATES,
    TRYING_SEARCH_WORKOUT_TEMPLATES,
    TRYING_SEARCH_WORKOUT_TEMPLATES_SUCCESS,
    TRYING_SEARCH_WORKOUT_TEMPLATES_ERROR,

    TRYING_UPDATE_CLIENT_ASSIGNING,
    TRYING_UPDATE_CLIENT_ASSIGNING_SUCCESS,
    TRYING_UPDATE_CLIENT_ASSIGNING_ERROR,

    TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES,
    TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES_SUCCESS,
    TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES_ERROR,

    TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS,
    TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS_SUCCESS,
    TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS_ERROR,

    TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES,
    TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES_SUCCESS,
    TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES_ERROR,

    TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES,
    TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES_SUCCESS,
    TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES_ERROR,

    TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS,
    TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS_SUCCESS,
    TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS_ERROR,

    TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES,
    TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES_SUCCESS,
    TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES_ERROR,

    TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES,
    TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES_SUCCESS,
    TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES_ERROR,

    TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS,
    TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS_SUCCESS,
    TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS_ERROR,

    TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES,
    TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES_SUCCESS,
    TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES_ERROR,

    TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES,
    TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES_SUCCESS,
    TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES_ERROR,

    TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS,
    TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS_SUCCESS,
    TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS_ERROR,

    TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES,
    TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES_SUCCESS,
    TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES_ERROR,

    TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER,
    TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER_SUCCESS,
    TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER_ERROR
} from '../Types';

const INITIAL_STATE = {
    DishTemplateFilterSettings: { TemplateDays: [] },
    IsMoreMealTemplates: false,
    MealTemplate: { TemplateId: null, TemplateDetails: {} },
    MealTemplateList: [],
    MealTemplateList_NumPendingShared: null,
    MealTemplateList_TotalRecords: null,

    TrainingProgramsFilterSettings: { TemplateDays: [] },
    TrainingProgramFilterSettings: {},
    IsMoreTrainingPrograms: false,
    TrainingProgram: { TrainingProgramId: null },
    TrainingProgramsList: [],
    TrainingProgramsList_NumPendingShared: null,
    TrainingProgramsList_TotalRecords: null,

    IsMoreWorkoutTemplates: false,
    WorkoutTemplate: { TemplateId: null, TemplateDetails: {}, WorkoutExercises: [], WorkoutLaps: [] },
    WorkoutTemplateFilterSettings: {},
    WorkoutTemplateFilters: { Retrieved: false, MadeBy: [{ id: 'Self', label: 'Self', options: [] }, { id: 'Abel', label: 'Abel', options: [] }, { id: 'Others', label: 'Others', options: [] }, { id: 'Organizations', label: 'Organizations', options: [] }] },
    WorkoutTemplateList: [],
    WorkoutTemplateList_NumPendingShared: null,
    WorkoutTemplateList_TotalRecords: null,

    RetrievedAdditionalActivitySettings: false,
    
    TryingAddExistingMealTemplate: false,
    TryingAddExistingMealTemplateError: null,
    TryingDeleteMealTemplates: false,
    TryingDeleteMealTemplatesError: null,
    TryingGetMealTemplateDetail: false,
    TryingGetMealTemplateDetailError: null,
    TryingInsertEditMealTemplateDetail: false,
    TryingInsertEditMealTemplateDetailError: null,
    TryingSearchMealTemplates: false,
    TryingSearchMealTemplatesError: null,
    TryingUpdatePausedSharing_MealTemplates: false,
    TryingUpdatePausedSharing_MealTemplatesError: null,
    TryingUpdateShareAbilityStatus_MealTemplates: false,
    TryingUpdateShareAbilityStatus_MealTemplatesError: null,
    TryingUpdateSharedToCoachStatusAll_MealTemplates: false,
    TryingUpdateSharedToCoachStatusAll_MealTemplatesError: null,
    TryingUpdateSharedToCoachStatus_MealTemplates: false,
    TryingUpdateSharedToCoachStatus_MealTemplatesError: null,

    TryingUpdateClientAssigning: false,
    TryingUpdateClientAssigningError: null,

    TryingAddTrainingProgram: false,
    TryingAddTrainingProgramError: null,
    TryingDeleteTrainingPrograms: false,
    TryingDeleteTrainingProgramsError: null,
    TryingGetTrainingProgramFilterSettings: false,
    TryingGetTrainingProgramFilterSettingsError: null,
    TryingInsertEditLibraryFilterTrainingPrograms: false,
    TryingInsertEditLibraryFilterTrainingProgramsError: null,
    TryingInsertEditTrainingProgram: false,
    TryingInsertEditTrainingProgramError: null,
    TryingSearchTrainingPrograms: false,
    TryingSearchTrainingProgramsError: null,
    TryingUpdatePausedSharing_TrainingPrograms: false,
    TryingUpdatePausedSharing_TrainingProgramsError: null,
    TryingUpdateShareAbilityStatus_TrainingPrograms: false,
    TryingUpdateShareAbilityStatus_TrainingProgramsError: null,
    TryingUpdateSharedToCoachStatusAll_TrainingPrograms: false,
    TryingUpdateSharedToCoachStatusAll_TrainingPrograms_Error: null,
    TryingUpdateSharedToCoachStatus_TrainingPrograms: false,
    TryingUpdateSharedToCoachStatus_TrainingProgramsError: null,

    TryingAddExistingWorkoutTemplate: false,
    TryingAddExistingWorkoutTemplateError: null,
    TryingDeleteWorkoutTemplates: false,
    TryingDeleteWorkoutTemplatesError: null,
    TryingGetWorkoutTemplateDetail: false,
    TryingGetWorkoutTemplateDetailError: null,
    TryingGetWorkoutTemplateFilters: false,
    TryingGetWorkoutTemplateFiltersError: null,
    TryingGetWorkoutTemplateFilterSettings: false,
    TryingGetWorkoutTemplateFilterSettingsError: null,
    TryingInsertEditWorkoutTemplateDetail: false,
    TryingInsertEditWorkoutTemplateDetailError: null,
    TryingInsertEditLibraryFilterWorkoutTemplates: false,
    TryingInsertEditLibraryFilterWorkoutTemplatesError: null,
    TryingSearchWorkoutTemplates: false,
    TryingSearchWorkoutTemplatesError: null,
    TryingUpdatePausedSharing_WorkoutTemplates: false,
    TryingUpdatePausedSharing_WorkoutTemplatesError: null,
    TryingUpdateShareAbilityStatus_WorkoutTemplates: false,
    TryingUpdateShareAbilityStatus_WorkoutTemplatesError: null,
    TryingUpdateSharedToCoachStatusAll_WorkoutTemplates: false,
    TryingUpdateSharedToCoachStatusAll_WorkoutTemplatesError: null,
    TryingUpdateSharedToCoachStatus_WorkoutTemplates: false,
    TryingUpdateSharedToCoachStatus_WorkoutTemplatesError: null,

    TryingInsertMealPlanMealPlanBuilder: false,
    TryingInsertMealPlanMealPlanBuilderError: null,

    TryingViewDishFoodsMealPlanBuilder: false,
    TryingViewDishFoodsMealPlanBuilderError: null
};

var i;
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_ADDITIONAL_ACTIVITY_DETAILS:
            return { ...state, RetrievedAdditionalActivitySettings: false };

        case TRYING_ADD_EXISTING_MEAL_TEMPLATE:
            return { ...state, TryingAddExistingMealTemplate: true, TryingAddExistingMealTemplateError: null };
        case TRYING_ADD_EXISTING_MEAL_TEMPLATE_ERROR:
            return { ...state, TryingAddExistingMealTemplate: false, TryingAddExistingMealTemplateError: action.payload };
        case TRYING_ADD_EXISTING_MEAL_TEMPLATE_SUCCESS:
            return { ...state, TryingAddExistingMealTemplate: false, TryingAddExistingMealTemplateError: null };

        case TRYING_DELETE_MEAL_TEMPLATES:
            return { ...state, TryingDeleteMealTemplates: true, TryingDeleteMealTemplatesError: null };
        case TRYING_DELETE_MEAL_TEMPLATES_ERROR:
            return { ...state, TryingDeleteMealTemplates: false, TryingDeleteMealTemplatesError: action.payload };
        case TRYING_DELETE_MEAL_TEMPLATES_SUCCESS:
            // eslint-disable-next-line
            var { TemplateIds } = action.payload;
            var MealTemplateList_New = [];
            var MealTemplateList_TotalRecords_New = state.MealTemplateList_TotalRecords - TemplateIds.length;

            for (i = 0; i < state.MealTemplateList.length; i++) {
                if (TemplateIds.indexOf(Number(state.MealTemplateList[i].TemplateId)) === -1) MealTemplateList_New.push(state.MealTemplateList[i]);
            }

            return { ...state, MealTemplateList: MealTemplateList_New, MealTemplateList_TotalRecords: MealTemplateList_TotalRecords_New, TryingDeleteMealTemplates: false, TryingDeleteMealTemplatesError: null };

        case CLEAR_MEAL_TEMPLATE_DETAIL:
            return { ...state, MealTemplate: { TemplateId: null, TemplateDetails: {} } };
        case TRYING_GET_MEAL_TEMPLATE_DETAIL:
            return { ...state, TryingGetMealTemplateDetail: true, TryingGetMealTemplateDetailError: null };
        case TRYING_GET_MEAL_TEMPLATE_DETAIL_ERROR:
            return { ...state, TryingGetMealTemplateDetail: false, TryingGetMealTemplateDetailError: action.payload };
        case TRYING_GET_MEAL_TEMPLATE_DETAIL_SUCCESS:
            return { ...state, MealTemplate: action.payload, TryingGetMealTemplateDetail: false, TryingGetMealTemplateDetailError: null };

        case TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS:
            return { ...state, TryingGetWorkoutTemplateFilterSettings: true, TryingGetWorkoutTemplateFilterSettingsError: null };
        case TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_ERROR:
            return { ...state, TryingGetWorkoutTemplateFilterSettings: false, TryingGetWorkoutTemplateFilterSettingsError: action.payload };
        case TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_SUCCESS:
            return { ...state, WorkoutTemplateFilterSettings: action.payload, TryingGetWorkoutTemplateFilterSettings: false, TryingGetWorkoutTemplateFilterSettingsError: null };

        case TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL:
            return { ...state, TryingInsertEditMealTemplateDetail: true, TryingInsertEditMealTemplateDetailError: null };
        case TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL_ERROR:
            return { ...state, TryingInsertEditMealTemplateDetail: false, TryingInsertEditMealTemplateDetailError: action.payload };
        case TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL_SUCCESS:
            return { ...state, TryingInsertEditMealTemplateDetail: false, TryingInsertEditMealTemplateDetailError: null };

        case TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES:
            return { ...state, TryingInsertEditLibraryFilterWorkoutTemplates: true, TryingInsertEditLibraryFilterWorkoutTemplatesError: null };
        case TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_ERROR:
            return { ...state, TryingInsertEditLibraryFilterWorkoutTemplates: false, TryingInsertEditLibraryFilterWorkoutTemplatesError: action.payload };
        case TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_SUCCESS:
            return { ...state, TryingInsertEditLibraryFilterWorkoutTemplates: false, TryingInsertEditLibraryFilterWorkoutTemplatesError: null };

        case CLEAR_SEARCH_MEAL_TEMPLATES:
            return { ...state, DishTemplateFilterSettings: { TemplateDays: [] }, MealTemplateList: [], MealTemplateList_NumPendingShared: null, MealTemplateList_TotalRecords: null, IsMoreMealTemplates: false };
        case TRYING_SEARCH_MEAL_TEMPLATES:
            return { ...state, TryingSearchMealTemplates: true, TryingSearchMealTemplatesError: null };
        case TRYING_SEARCH_MEAL_TEMPLATES_ERROR:
            return { ...state, MealTemplateList: [], MealTemplateList_TotalRecords: null, IsMoreMealTemplates: false, TryingSearchMealTemplates: false, TryingSearchMealTemplatesError: action.payload };
        case TRYING_SEARCH_MEAL_TEMPLATES_SUCCESS:
            // eslint-disable-next-line
            var { AddToExisting, MealTemplateFilters: { TemplateDays }, NumPendingShared, Templates, Templates_IsMoreResults, TotalRecords } = action.payload;
            var MealTemplateList = [];

            if (AddToExisting) MealTemplateList = [ ...state.MealTemplateList, ...Templates ];
            else MealTemplateList = [ ...Templates ];

            TemplateDays = TemplateDays.map(TemplateDay => ({ ...TemplateDay, TemplateDayId: TemplateDay.TemplateDay }));

            return { ...state, DishTemplateFilterSettings: { TemplateDays }, IsMoreMealTemplates: !!Templates_IsMoreResults, MealTemplateList, MealTemplateList_NumPendingShared: NumPendingShared, MealTemplateList_TotalRecords: +TotalRecords, TryingSearchMealTemplates: false, TryingSearchMealTemplatesError: null };

        case TRYING_UPDATE_CLIENT_ASSIGNING:
            return { ...state, TryingUpdateClientAssigning: true, TryingUpdateClientAssigningError: null };
        case TRYING_UPDATE_CLIENT_ASSIGNING_ERROR:
            return { ...state, TryingUpdateClientAssigning: false, TryingUpdateClientAssigningError: action.payload };
        case TRYING_UPDATE_CLIENT_ASSIGNING_SUCCESS:
            return { ...state, TryingUpdateClientAssigning: false, TryingUpdateClientAssigningError: null };

        case TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES:
            return { ...state, TryingUpdatePausedSharing_MealTemplates: true, TryingUpdatePausedSharing_MealTemplatesError: null };
        case TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES_ERROR:
            return { ...state, TryingUpdatePausedSharing_MealTemplates: false, TryingUpdatePausedSharing_MealTemplatesError: action.payload };
        case TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES_SUCCESS:
            // eslint-disable-next-line
            var { IsPaused } = action.payload;

            // eslint-disable-next-line
            var MealTemplate = { ...state.MealTemplate };
            MealTemplate.TemplateDetails.SharingDetails = { ...MealTemplate.TemplateDetails.SharingDetails, IsPaused };

            return { ...state, MealTemplate, TryingUpdatePausedSharing_MealTemplates: false, TryingUpdatePausedSharing_MealTemplatesError: null };

        case TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES:
            return { ...state, TryingUpdateShareAbilityStatus_MealTemplates: true, TryingUpdateShareAbilityStatus_MealTemplatesError: null };
        case TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES_ERROR:
            return { ...state, TryingUpdateShareAbilityStatus_MealTemplates: false, TryingUpdateShareAbilityStatus_MealTemplatesError: action.payload };
        case TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES_SUCCESS:
            // eslint-disable-next-line
            var { NewStatus: ShareAbilityStatusId, NewStatusName: ShareAbility, TemplateId } = action.payload;

            // eslint-disable-next-line
            var MealTemplate = { ...state.MealTemplate };
            MealTemplate.TemplateDetails.SharingDetails = { ...MealTemplate.TemplateDetails.SharingDetails, ShareAbility, ShareAbilityStatusId };

            // eslint-disable-next-line
            var MealTemplateList_New = [ ...state.MealTemplateList ];

            for (i = 0; i < MealTemplateList_New.length; i++) {
                if (+MealTemplateList_New[i].TemplateId === +TemplateId) {
                    MealTemplateList_New[i] = { ...MealTemplateList_New[i], ShareAbility, ShareAbilityStatusId };
                    break;
                }
            }

            return { ...state, MealTemplateList: MealTemplateList_New, MealTemplate, TryingUpdateShareAbilityStatus_MealTemplates: false, TryingUpdateShareAbilityStatus_MealTemplatesError: null };

        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES:
            return { ...state, TryingUpdateSharedToCoachStatusAll_MealTemplates: true, TryingUpdateSharedToCoachStatusAll_MealTemplatesError: null };
        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES_ERROR:
            return { ...state, TryingUpdateSharedToCoachStatusAll_MealTemplates: false, TryingUpdateSharedToCoachStatusAll_MealTemplatesError: action.payload };
        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES_SUCCESS:
            return { ...state, TryingUpdateSharedToCoachStatusAll_MealTemplates: false, TryingUpdateSharedToCoachStatusAll_MealTemplatesError: null };

        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES:
            return { ...state, TryingUpdateSharedToCoachStatus_MealTemplates: true, TryingUpdateSharedToCoachStatus_MealTemplatesError: null };
        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES_ERROR:
            return { ...state, TryingUpdateSharedToCoachStatus_MealTemplates: false, TryingUpdateSharedToCoachStatus_MealTemplatesError: action.payload };
        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES_SUCCESS:
            // eslint-disable-next-line
            var { UpdateSingleTemplate, SharingDetails } = action.payload;

            if (!UpdateSingleTemplate) return { ...state, TryingUpdateSharedToCoachStatus_MealTemplates: false, TryingUpdateSharedToCoachStatus_MealTemplatesError: null };

            // eslint-disable-next-line
            var MealTemplate = { ...state.MealTemplate, TemplateDetails: { ...state.MealTemplate.TemplateDetails, SharingDetails } };

            return { ...state, MealTemplate, TryingUpdateSharedToCoachStatus_MealTemplates: false, TryingUpdateSharedToCoachStatus_MealTemplatesError: null };







        case TRYING_ADD_TRAINING_PROGRAM:
            return { ...state, TryingAddTrainingProgram: true, TryingAddTrainingProgramError: null };
        case TRYING_ADD_TRAINING_PROGRAM_ERROR:
            return { ...state, TryingAddTrainingProgram: false, TryingAddTrainingProgramError: action.payload };
        case TRYING_ADD_TRAINING_PROGRAM_SUCCESS:
            return { ...state, TryingAddTrainingProgram: false, TryingAddTrainingProgramError: null };

        case TRYING_DELETE_TRAINING_PROGRAMS:
            return { ...state, TryingDeleteTrainingPrograms: true, TryingDeleteTrainingProgramsError: null };
        case TRYING_DELETE_TRAINING_PROGRAMS_ERROR:
            return { ...state, TryingDeleteTrainingPrograms: false, TryingDeleteTrainingProgramsError: action.payload };
        case TRYING_DELETE_TRAINING_PROGRAMS_SUCCESS:
            // eslint-disable-next-line
            var { TrainingProgramIds } = action.payload;
            var TrainingProgramsList_New = [];
            var TrainingProgramsList_TotalRecords_New = state.TrainingProgramsList_TotalRecords - TrainingProgramIds.length;

            for (i = 0; i < state.TrainingProgramsList.length; i++) {
                if (TrainingProgramIds.indexOf(Number(state.TrainingProgramsList[i].TemplateId)) === -1) TrainingProgramsList_New.push(state.TrainingProgramsList[i]);
            }

            return { ...state, TrainingProgramsList: TrainingProgramsList_New, TrainingProgramsList_TotalRecords: TrainingProgramsList_TotalRecords_New, TryingDeleteTrainingPrograms: false, TryingDeleteTrainingProgramsError: null };

        case CLEAR_TRAINING_PROGRAM_DETAIL:
            return { ...state, TrainingProgram: {} };
        case TRYING_GET_TRAINING_PROGRAM_DETAIL:
            return { ...state, TryingGetTrainingProgramDetail: true, TryingGetTrainingProgramDetailError: null };
        case TRYING_GET_TRAINING_PROGRAM_DETAIL_ERROR:
            return { ...state, TryingGetTrainingProgramDetail: false, TryingGetTrainingProgramDetailError: action.payload };
        case TRYING_GET_TRAINING_PROGRAM_DETAIL_SUCCESS:
            return { ...state, TrainingProgram: action.payload, TryingGetTrainingProgramDetail: false, TryingGetTrainingProgramDetailError: null };

        case TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS:
            return { ...state, TryingGetTrainingProgramFilterSettings: true, TryingGetTrainingProgramFilterSettingsError: null };
        case TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_ERROR:
            return { ...state, TryingGetTrainingProgramFilterSettings: false, TryingGetTrainingProgramFilterSettingsError: action.payload };
        case TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_SUCCESS:
            return { ...state, TrainingProgramFilterSettings: action.payload, TryingGetTrainingProgramFilterSettings: false, TryingGetTrainingProgramFilterSettingsError: null };

        case TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS:
            return { ...state, TryingInsertEditLibraryFilterTrainingPrograms: true, TryingInsertEditLibraryFilterTrainingProgramsError: null };
        case TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_ERROR:
            return { ...state, TryingInsertEditLibraryFilterTrainingPrograms: false, TryingInsertEditLibraryFilterTrainingProgramsError: action.payload };
        case TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_SUCCESS:
            return { ...state, TryingInsertEditLibraryFilterTrainingPrograms: false, TryingInsertEditLibraryFilterTrainingProgramsError: null };

        case TRYING_INSERTEDIT_TRAINING_PROGRAM:
            return { ...state, TryingInsertEditTrainingProgram: true, TryingInsertEditTrainingProgramError: null };
        case TRYING_INSERTEDIT_TRAINING_PROGRAM_ERROR:
            return { ...state, TryingInsertEditTrainingProgram: false, TryingInsertEditTrainingProgramError: action.payload };
        case TRYING_INSERTEDIT_TRAINING_PROGRAM_SUCCESS:
            return { ...state, TryingInsertEditTrainingProgram: false, TryingInsertEditTrainingProgramError: null };

        case CLEAR_SEARCH_TRAINING_PROGRAMS:
            return { ...state, TrainingProgramsFilterSettings: { TemplateDays: [] }, TrainingProgramsList: [], TrainingProgramsList_NumPendingShared: null, TrainingProgramsList_TotalRecords: null, IsMoreTrainingPrograms: false };
        case TRYING_SEARCH_TRAINING_PROGRAMS:
            return { ...state, TryingSearchTrainingPrograms: true, TryingSearchTrainingProgramsError: null };
        case TRYING_SEARCH_TRAINING_PROGRAMS_ERROR:
            return { ...state, TrainingProgramsList: [], TrainingProgramsList_TotalRecords: null, IsMoreTrainingPrograms: false, TryingSearchTrainingPrograms: false, TryingSearchTrainingProgramsError: action.payload };
        case TRYING_SEARCH_TRAINING_PROGRAMS_SUCCESS:
            // eslint-disable-next-line
            var { AddToExisting, NumPendingShared, TemplateDays, TrainingPrograms, IsMoreResults, TotalRecords } = action.payload;
            var TrainingProgramsList = [];

            if (AddToExisting) TrainingProgramsList = [ ...state.TrainingProgramsList, ...TrainingPrograms ];
            else TrainingProgramsList = [ ...TrainingPrograms ];

            return { ...state, IsMoreTrainingPrograms: !!IsMoreResults, TrainingProgramsList, TrainingProgramsList_NumPendingShared: NumPendingShared, TrainingProgramsList_TotalRecords: +TotalRecords, TryingSearchTrainingPrograms: false, TryingSearchTrainingProgramsError: null };

        case TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS:
            return { ...state, TryingUpdatePausedSharing_TrainingPrograms: true, TryingUpdatePausedSharing_TrainingProgramsError: null };
        case TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS_ERROR:
            return { ...state, TryingUpdatePausedSharing_TrainingPrograms: false, TryingUpdatePausedSharing_TrainingProgramsError: action.payload };
        case TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS_SUCCESS:
            // eslint-disable-next-line
            var { IsPaused } = action.payload;

            // eslint-disable-next-line
            var TrainingProgram = { ...state.TrainingProgram };
            TrainingProgram.SharingDetails = { ...TrainingProgram.SharingDetails, IsPaused };

            return { ...state, TrainingProgram, TryingUpdatePausedSharing_TrainingPrograms: false, TryingUpdatePausedSharing_TrainingProgramsError: null };

        case TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS:
            return { ...state, TryingUpdateShareAbilityStatus_TrainingPrograms: true, TryingUpdateShareAbilityStatus_TrainingProgramsError: null };
        case TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS_ERROR:
            return { ...state, TryingUpdateShareAbilityStatus_TrainingPrograms: false, TryingUpdateShareAbilityStatus_TrainingProgramsError: action.payload };
        case TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS_SUCCESS:
            // eslint-disable-next-line
            var { NewStatus: ShareAbilityStatusId, NewStatusName: ShareAbility, TrainingProgramId } = action.payload;

            // eslint-disable-next-line
            var TrainingProgram = { ...state.TrainingProgram };
            TrainingProgram.SharingDetails = { ...TrainingProgram.SharingDetails, ShareAbility, ShareAbilityStatusId };

            // eslint-disable-next-line
            var TrainingProgramList_New = [ ...state.TrainingProgramsList ];

            for (i = 0; i < TrainingProgramList_New.length; i++) {
                if (+TrainingProgramList_New[i].TrainingProgramId === +TrainingProgramId) {
                    TrainingProgramList_New[i] = { ...TrainingProgramList_New[i], ShareAbility, ShareAbilityStatusId };
                    break;
                }
            }

            return { ...state, TrainingProgramsList: TrainingProgramList_New, TrainingProgram, TryingUpdateShareAbilityStatus_TrainingPrograms: false, TryingUpdateShareAbilityStatus_TrainingProgramsError: null };

        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS:
            return { ...state, TryingUpdateSharedToCoachStatusAll_TrainingPrograms: true, TryingUpdateSharedToCoachStatusAll_TrainingProgramsError: null };
        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS_ERROR:
            return { ...state, TryingUpdateSharedToCoachStatusAll_TrainingPrograms: false, TryingUpdateSharedToCoachStatusAll_TrainingProgramsError: action.payload };
        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS_SUCCESS:
            return { ...state, TryingUpdateSharedToCoachStatusAll_TrainingPrograms: false, TryingUpdateSharedToCoachStatusAll_TrainingProgramsError: null };

        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS:
            return { ...state, TryingUpdateSharedToCoachStatus_TrainingPrograms: true, TryingUpdateSharedToCoachStatus_TrainingProgramsError: null };
        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS_ERROR:
            return { ...state, TryingUpdateSharedToCoachStatus_TrainingPrograms: false, TryingUpdateSharedToCoachStatus_TrainingProgramsError: action.payload };
        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS_SUCCESS:
            // eslint-disable-next-line
            var { UpdateSingleTemplate, SharingDetails } = action.payload;

            if (!UpdateSingleTemplate) return { ...state, TryingUpdateSharedToCoachStatus_TrainingPrograms: false, TryingUpdateSharedToCoachStatus_TrainingProgramsError: null };

            // eslint-disable-next-line
            var TrainingProgram = { ...state.TrainingProgram, SharingDetails };

            return { ...state, TrainingProgram, TryingUpdateSharedToCoachStatus_TrainingPrograms: false, TryingUpdateSharedToCoachStatus_TrainingProgramsError: null };
        





        case TRYING_ADD_EXISTING_WORKOUT_TEMPLATE:
            return { ...state, TryingAddExistingWorkoutTemplate: true, TryingAddExistingWorkoutTemplateError: null };
        case TRYING_ADD_EXISTING_WORKOUT_TEMPLATE_ERROR:
            return { ...state, TryingAddExistingWorkoutTemplate: false, TryingAddExistingWorkoutTemplateError: action.payload };
        case TRYING_ADD_EXISTING_WORKOUT_TEMPLATE_SUCCESS:
            return { ...state, TryingAddExistingWorkoutTemplate: false, TryingAddExistingWorkoutTemplateError: null };
        
        case TRYING_DELETE_WORKOUT_TEMPLATES:
            return { ...state, TryingDeleteWorkoutTemplates: true, TryingDeleteWorkoutTemplatesError: null };
        case TRYING_DELETE_WORKOUT_TEMPLATES_ERROR:
            return { ...state, TryingDeleteWorkoutTemplates: false, TryingDeleteWorkoutTemplatesError: action.payload };
        case TRYING_DELETE_WORKOUT_TEMPLATES_SUCCESS:
            // eslint-disable-next-line
            var { TemplateIds } = action.payload;
            var WorkoutTemplateList_New = [];
            var WorkoutTemplateList_TotalRecords_New = state.WorkoutTemplateList_TotalRecords - TemplateIds.length;

            for (i = 0; i < state.WorkoutTemplateList.length; i++) {
                if (TemplateIds.indexOf(Number(state.WorkoutTemplateList[i].TemplateId)) === -1) WorkoutTemplateList_New.push(state.WorkoutTemplateList[i]);
            }

            return { ...state, WorkoutTemplateList: WorkoutTemplateList_New, WorkoutTemplateList_TotalRecords: WorkoutTemplateList_TotalRecords_New, TryingDeleteWorkoutTemplates: false, TryingDeleteWorkoutTemplatesError: null };

        case CLEAR_WORKOUT_TEMPLATE_DETAIL:
            return { ...state, RetrievedAdditionalActivitySettings: false, WorkoutTemplate: { TemplateId: null, TemplateDetails: {}, WorkoutExercises: [], WorkoutLaps: [] } };
        case TRYING_GET_WORKOUT_TEMPLATE_DETAIL:
            return { ...state, TryingGetWorkoutTemplateDetail: true, TryingGetWorkoutTemplateDetailError: null };
        case TRYING_GET_WORKOUT_TEMPLATE_DETAIL_ERROR:
            return { ...state, TryingGetWorkoutTemplateDetail: false, TryingGetWorkoutTemplateDetailError: action.payload };
        case TRYING_GET_WORKOUT_TEMPLATE_DETAIL_SUCCESS:
            return { ...state, WorkoutTemplate: action.payload, RetrievedAdditionalActivitySettings: true, TryingGetWorkoutTemplateDetail: false, TryingGetWorkoutTemplateDetailError: null };

        case TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL:
            return { ...state, TryingInsertEditWorkoutTemplateDetail: true, TryingInsertEditWorkoutTemplateDetailError: null };
        case TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL_ERROR:
            return { ...state, TryingInsertEditWorkoutTemplateDetail: false, TryingInsertEditWorkoutTemplateDetailError: action.payload };
        case TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL_SUCCESS:
            return { ...state, TryingInsertEditWorkoutTemplateDetail: false, TryingInsertEditWorkoutTemplateDetailError: null };

        case TRYING_GET_WORKOUT_TEMPLATE_ADDITIONAL_ACTIVITY_DETAILS_SUCCESS:
            return { ...state, RetrievedAdditionalActivitySettings: true };

        case TRYING_GET_WORKOUT_TEMPLATE_FILTERS:
            return { ...state, TryingGetWorkoutTemplateFilters: true, TryingGetWorkoutTemplateFiltersError: null };
        case TRYING_GET_WORKOUT_TEMPLATE_FILTERS_ERROR:
            return { ...state, TryingGetWorkoutTemplateFilters: false, TryingGetWorkoutTemplateFiltersError: action.payload };
        case TRYING_GET_WORKOUT_TEMPLATE_FILTERS_SUCCESS:
            var { WorkoutTemplateFilters } = action.payload;

            var NewWorkoutTemplateFilters = { Retrieved: true, MadeBy: [{ id: 'Self', label: 'Self', options: WorkoutTemplateFilters.MadeByOptions.Self }, { id: 'Abel', label: 'Abel', options: WorkoutTemplateFilters.MadeByOptions.Abel }, { id: 'Others', label: 'Others', options: WorkoutTemplateFilters.MadeByOptions.Others }, { id: 'Organizations', label: 'Organizations', options: WorkoutTemplateFilters.MadeByOptions.Organizations }] };

            return { ...state, WorkoutTemplateFilters: NewWorkoutTemplateFilters, TryingGetWorkoutTemplateFilters: false, TryingGetWorkoutTemplateFiltersError: null };

        case CLEAR_SEARCH_WORKOUT_TEMPLATES:
            return { ...state, WorkoutTemplateFilters: { Retrieved: false, MadeBy: [{ id: 'Self', label: 'Self', options: [] }, { id: 'Abel', label: 'Abel', options: [] }, { id: 'Others', label: 'Others', options: [] }, { id: 'Organizations', label: 'Organizations', options: [] }] }, WorkoutTemplateList: [], WorkoutTemplateList_NumPendingShared: null, WorkoutTemplateList_TotalRecords: null, IsMoreWorkoutTemplates: false };
        case TRYING_SEARCH_WORKOUT_TEMPLATES:
            return { ...state, TryingSearchWorkoutTemplates: true, TryingSearchWorkoutTemplatesError: null };
        case TRYING_SEARCH_WORKOUT_TEMPLATES_ERROR:
            return { ...state, WorkoutTemplateList: [], WorkoutTemplateList_NumPendingShared: null, WorkoutTemplateList_TotalRecords: null, IsMoreWorkoutTemplates: false, TryingSearchWorkoutTemplates: false, TryingSearchWorkoutTemplatesError: action.payload };
        case TRYING_SEARCH_WORKOUT_TEMPLATES_SUCCESS:
            // eslint-disable-next-line
            var { AddToExisting, NumPendingShared, TemplateDays, Templates, Templates_IsMoreResults, TotalRecords } = action.payload;
            var WorkoutTemplateList = [];

            if (AddToExisting) WorkoutTemplateList = [ ...state.WorkoutTemplateList, ...Templates ];
            else WorkoutTemplateList = [ ...Templates ];

            return { ...state, IsMoreWorkoutTemplates: Templates_IsMoreResults ? true : false, WorkoutTemplateList, WorkoutTemplateList_NumPendingShared: NumPendingShared, WorkoutTemplateList_TotalRecords: +TotalRecords, TryingSearchWorkoutTemplates: false, TryingSearchWorkoutTemplatesError: null };

        case TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES:
            return { ...state, TryingUpdatePausedSharing_WorkoutTemplates: true, TryingUpdatePausedSharing_WorkoutTemplatesError: null };
        case TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES_ERROR:
            return { ...state, TryingUpdatePausedSharing_WorkoutTemplates: false, TryingUpdatePausedSharing_WorkoutTemplatesError: action.payload };
        case TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES_SUCCESS:
            // eslint-disable-next-line
            var { IsPaused } = action.payload;

            // eslint-disable-next-line
            var WorkoutTemplate = { ...state.WorkoutTemplate };
            WorkoutTemplate.TemplateDetails.SharingDetails = { ...WorkoutTemplate.TemplateDetails.SharingDetails, IsPaused };

            return { ...state, WorkoutTemplate, TryingUpdatePausedSharing_WorkoutTemplates: false, TryingUpdatePausedSharing_WorkoutTemplatesError: null };

        case TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES:
            return { ...state, TryingUpdateShareAbilityStatus_WorkoutTemplates: true, TryingUpdateShareAbilityStatus_WorkoutTemplatesError: null };
        case TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES_ERROR:
            return { ...state, TryingUpdateShareAbilityStatus_WorkoutTemplates: false, TryingUpdateShareAbilityStatus_WorkoutTemplatesError: action.payload };
        case TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES_SUCCESS:
            // eslint-disable-next-line
            var { NewStatus: ShareAbilityStatusId, NewStatusName: ShareAbility, TemplateId } = action.payload;

            // eslint-disable-next-line
            var WorkoutTemplate = { ...state.WorkoutTemplate };
            WorkoutTemplate.TemplateDetails.SharingDetails = { ...WorkoutTemplate.TemplateDetails.SharingDetails, ShareAbility, ShareAbilityStatusId };

            // eslint-disable-next-line
            var WorkoutTemplateList_New = [ ...state.WorkoutTemplateList ];

            for (i = 0; i < WorkoutTemplateList_New.length; i++) {
                if (+WorkoutTemplateList_New[i].TemplateId === +TemplateId) {
                    WorkoutTemplateList_New[i] = { ...WorkoutTemplateList_New[i], ShareAbility, ShareAbilityStatusId };
                    break;
                }
            }

            return { ...state, WorkoutTemplateList: WorkoutTemplateList_New, WorkoutTemplate, TryingUpdateShareAbilityStatus_WorkoutTemplates: false, TryingUpdateShareAbilityStatus_WorkoutTemplatesError: null };

        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES:
            return { ...state, TryingUpdateSharedToCoachStatusAll_WorkoutTemplates: true, TryingUpdateSharedToCoachStatusAll_WorkoutTemplatesError: null };
        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES_ERROR:
            return { ...state, TryingUpdateSharedToCoachStatusAll_WorkoutTemplates: false, TryingUpdateSharedToCoachStatusAll_WorkoutTemplatesError: action.payload };
        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES_SUCCESS:
            return { ...state, TryingUpdateSharedToCoachStatusAll_WorkoutTemplates: false, TryingUpdateSharedToCoachStatusAll_WorkoutTemplatesError: null };

        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES:
            return { ...state, TryingUpdateSharedToCoachStatus_WorkoutTemplates: true, TryingUpdateSharedToCoachStatus_WorkoutTemplatesError: null };
        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES_ERROR:
            return { ...state, TryingUpdateSharedToCoachStatus_WorkoutTemplates: false, TryingUpdateSharedToCoachStatus_WorkoutTemplatesError: action.payload };
        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES_SUCCESS:
            // eslint-disable-next-line
            var { UpdateSingleTemplate, SharingDetails } = action.payload;

            if (!UpdateSingleTemplate) return { ...state, TryingUpdateSharedToCoachStatus_WorkoutTemplates: false, TryingUpdateSharedToCoachStatus_WorkoutTemplatesError: null };

            // eslint-disable-next-line
            var WorkoutTemplate = { ...state.WorkoutTemplate, TemplateDetails: { ...state.WorkoutTemplate.TemplateDetails, SharingDetails } };

            return { ...state, WorkoutTemplate, TryingUpdateSharedToCoachStatus_WorkoutTemplates: false, TryingUpdateSharedToCoachStatus_WorkoutTemplatesError: null };


        case TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER:
            return { ...state, TryingInsertMealPlanMealPlanBuilder: true, TryingInsertMealPlanMealPlanBuilderError: null };
        case TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER_ERROR:
            return { ...state, TryingInsertMealPlanMealPlanBuilder: false, TryingInsertMealPlanMealPlanBuilderError: action.payload };
        case TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER_SUCCESS:
            return { ...state, TryingInsertMealPlanMealPlanBuilder: false, TryingInsertMealPlanMealPlanBuilderError: null };

        case TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER:
            return { ...state, TryingViewDishFoodsMealPlanBuilder: true, TryingViewDishFoodsMealPlanBuilderError: null };
        case TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER_ERROR:
            return { ...state, TryingViewDishFoodsMealPlanBuilder: false, TryingViewDishFoodsMealPlanBuilderError: action.payload };
        case TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER_SUCCESS:
            return { ...state, TryingViewDishFoodsMealPlanBuilder: false, TryingViewDishFoodsMealPlanBuilderError: null };
            
        default:
            return state;
    }
};
