import {
    TRYING_DELETE_DISH_DETAIL_FOOD,
    TRYING_DELETE_DISH_DETAIL_FOOD_SUCCESS,
    TRYING_DELETE_DISH_DETAIL_FOOD_ERROR,

    CLEAR_DISH_DETAIL,
    TRYING_GET_DISH_DETAIL,
    TRYING_GET_DISH_DETAIL_SUCCESS,
    TRYING_GET_DISH_DETAIL_ERROR,

    TRYING_UPDATE_DISH_COMPLETION_STATUS,
    TRYING_UPDATE_DISH_COMPLETION_STATUS_SUCCESS,
    TRYING_UPDATE_DISH_COMPLETION_STATUS_ERROR,

    TRYING_UPDATE_DISH_DETAIL_FOOD,
    TRYING_UPDATE_DISH_DETAIL_FOOD_SUCCESS,
    TRYING_UPDATE_DISH_DETAIL_FOOD_ERROR
} from '../Types';

const INITIAL_STATE = {
    DishDetail: {},
    
    TryingDeleteDishDetailFood: false,
    TryingDeleteDishDetailFoodError: null,
    TryingGetDishDetail: false,
    TryingGetDishDetailError: null,
    TryingUpdateDishCompletionStatus: false,
    TryingUpdateDishCompletionStatusError: null,
    TryingUpdateDishDetailFood: false,
    TryingUpdateDishDetailFoodError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_DELETE_DISH_DETAIL_FOOD:
            return { ...state, TryingDeleteDishDetailFood: true, TryingDeleteDishDetailFoodError: null };
        case TRYING_DELETE_DISH_DETAIL_FOOD_ERROR:
            return { ...state, TryingDeleteDishDetailFood: false, TryingDeleteDishDetailFoodError: action.payload };
        case TRYING_DELETE_DISH_DETAIL_FOOD_SUCCESS:
            return { ...state, TryingDeleteDishDetailFood: false, TryingDeleteDishDetailFoodError: null };

        case CLEAR_DISH_DETAIL:
            return { ...state, DishDetail: {} };
        case TRYING_GET_DISH_DETAIL:
            return { ...state, TryingGetDishDetail: true, TryingGetDishDetailError: null };
        case TRYING_GET_DISH_DETAIL_ERROR:
            return { ...state, TryingGetDishDetail: false, TryingGetDishDetailError: action.payload };
        case TRYING_GET_DISH_DETAIL_SUCCESS:
            var DishDetail = action.payload;

            return { ...state, DishDetail, TryingGetDishDetail: false, TryingGetDishDetailError: null };

        case TRYING_UPDATE_DISH_COMPLETION_STATUS:
            return { ...state, TryingUpdateDishCompletionStatus: true, TryingUpdateDishCompletionStatusError: null };
        case TRYING_UPDATE_DISH_COMPLETION_STATUS_ERROR:
            return { ...state, TryingUpdateDishCompletionStatus: false, TryingUpdateDishCompletionStatusError: action.payload };
        case TRYING_UPDATE_DISH_COMPLETION_STATUS_SUCCESS:
            return { ...state, TryingUpdateDishCompletionStatus: false, TryingUpdateDishCompletionStatusError: null };

        case TRYING_UPDATE_DISH_DETAIL_FOOD:
            return { ...state, TryingUpdateDishDetailFood: true, TryingUpdateDishDetailFoodError: null };
        case TRYING_UPDATE_DISH_DETAIL_FOOD_ERROR:
            return { ...state, TryingUpdateDishDetailFood: false, TryingUpdateDishDetailFoodError: action.payload };
        case TRYING_UPDATE_DISH_DETAIL_FOOD_SUCCESS:
            return { ...state, TryingUpdateDishDetailFood: false, TryingUpdateDishDetailFoodError: null };
        default:
            return state;
    }
};
