import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearExerciseDetail, DeleteExercises, GetExerciseDetail, SaveDataInProps, UpdatePausedSharing_Exercises, UpdateShareAbilityStatus_Exercises, UpdateSharedToCoachStatus_Exercises } from '../../Services/Actions';
import moment from 'moment';
import history from '../../history';

import { BodyContainer, BottomArea, Container, DetailContainer, ExerciseImageContainer, LeftTopArea, ProgressContainer, ProgressContainerInner, RightTopArea, StyledBodyMuscles, StyledExerciseImage, StyledInstructions, TopArea, TrackingHistoryContainer, TrackingHistoryRow } from './styles';
import { ChartContainer, LineGraphContainer, StyledExerciseVideo, StyledLineBar } from './styles';

import AbelModal from '../../Components/Modal';
import Accordion from '../../Components/Accordion';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import ContentSharingDetails from '../../Components/ContentSharingDetails';
import LineGraph from '../../Components/Charts/LineGraph';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import EditShareAbilityStatusModal from '../EditShareAbilityStatusModal';
import HowSharingWorksModal from '../HowSharingWorksModal';
import Info from '../Info';
import ShareContentModal from '../ShareContentModal';

import Copy from '../../Assets/Icons/Copy_White.png';
import DeleteX from '../../Assets/Icons/X_White.png';
import Edit from '../../Assets/Icons/Pencil_White_Filled.png';

import { SecondsToTime } from '../../Functions';

class ExerciseDetailModal extends React.Component {
    state = { Loading: true };

    state = {
        Loading: true,
        ShowDeleteExerciseModal: false,
        ShowEditShareAbilityStatusModal: false,
        ShowEditShareAbilityStatusModalDetails: {},
        ShowHowSharingWorksModal: false,
        ShowShareContentModal: false,
        Updating: false
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientId, ExerciseDetail_Preview: { ExerciseId } } = this.props;

        this.props.GetExerciseDetail({ ClientId, ExerciseId }).then(() => {
            if (this._isMounted) this.setState({ Loading: false });
        });
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        this.props.ClearExerciseDetail();
    }
    
    onClickEditExercise = () => {
        this.props.OnHide();

        history.push(`/library/exercise/${this.props.ExerciseDetail_Preview.ExerciseId}`);
    }

    onCopyExerciseToExercise = () => {
        var ExerciseId = this.props.ExerciseDetail_Preview.ExerciseId;

        history.push({ pathname: '/library/exercise', state: { CopiedExerciseId: ExerciseId, ExerciseDetails: this.props.ExerciseDetail } });
    }

    onDeleteExercise = () => {
        var { ExerciseId } = this.props.ExerciseDetail_Preview;

        this.props.DeleteExercises({ ExerciseId }).then(() => {
            if (!this.props.TryingDeleteExercisesError) {
                this.onToggleDeleteExerciseModal(false);
                this.props.OnHide(ExerciseId);
            }
        });
    }

    onPauseSharing = IsPaused => {
        this.props.UpdatePausedSharing_Exercises({ ExerciseId: this.props.ExerciseDetail_Preview.ExerciseId, IsPaused })
    }

    onShareAgain = Email => {
        this.props.UpdateSharedToCoachStatus_Exercises({ Emails: [ Email ], ExerciseId: this.props.ExerciseDetail_Preview.ExerciseId, NewStatus: 'Pending' });
    }

    onShareContentToEmails = ({ Emails, ContentIds }) => {
        this.props.UpdateSharedToCoachStatus_Exercises({ Emails, ExerciseId: this.props.ExerciseDetail_Preview.ExerciseId, ExerciseIds: ContentIds, NewStatus: 'Pending' }).then(() => {
            this.onToggleShowShareContentModal(false);
        });
    }

    onToggleDeleteExerciseModal = ShowDeleteExerciseModal => {
        this.setState({ ShowDeleteExerciseModal });
    }

    onToggleShowEditShareAbilityStatusModal = ({ ContentId = null, ContentIndex = null, ShowEditShareAbilityStatusModal, CurrentStatus = null }) => {
        this.setState({ ShowEditShareAbilityStatusModal, ShowEditShareAbilityStatusModalDetails: { ContentId, ContentIndex, CurrentStatus } });
    }

    onToggleShowExerciseDetailModal = (ShowExerciseDetailModal, Exercise) => {
        this.setState({ ShowExerciseDetailModal, ShowExerciseDetailPreview: Exercise || {} });
    }

    onToggleShowHowSharingWorksModal = ShowHowSharingWorksModal => {
        this.setState({ ShowHowSharingWorksModal });
    }

    onToggleShowShareContentModal = ShowShareContentModal => {
        this.setState({ ShowShareContentModal });
    }

    onUnshareContent = Email => {
        this.props.UpdateSharedToCoachStatus_Exercises({ Emails: [ Email ], ExerciseId: this.props.ExerciseDetail_Preview.ExerciseId, NewStatus: 'Unshare' });
    }

    onUpdateShareAbilityStatus = ({ ContentId, NewStatus, NewStatusName }) => {
        if (+NewStatus !== +this.props.ExerciseDetail.SharingDetails.ShareAbilityStatusId) return this.props.UpdateShareAbilityStatus_Exercises({ ExerciseId: ContentId, NewStatus, NewStatusName }).then(() => this.props.OnUpdateMultiSelectList({ ContentId, NewStatus, NewStatusName }))

        return;
    }

    onUpdateSharedToCoachStatus = ({ NewStatus, PersonalResponse }) => {
        this.setState({ Updating: true });
        this.props.UpdateSharedToCoachStatus_Exercises({ Emails: [ this.props.UserDetails.Email ], ExerciseId: this.props.ExerciseDetail_Preview.ExerciseId, NewStatus, PersonalResponse }).then(() => {
            this.setState({ Updating: false }, () => {
                if (NewStatus === 'reject' || NewStatus === 'delete') this.props.OnHide();

                if (this.props.OnLoadExercises) this.props.OnLoadExercises();
            });
        });
    }

    renderButtons = () => {
        var { t } = this.props;
        var { Device, ExerciseDetail } = this.props;

        var Buttons = [];
        if (!!ExerciseDetail && !!ExerciseDetail && !!+ExerciseDetail.IsOwner) {
            Buttons.push({ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: Edit, IconPosition: 'left', OnClick: this.onClickEditExercise, Title: t('edit_with_first_char_uppercase') });
            Buttons.push({ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: DeleteX, IconPosition: 'left', OnClick: () => this.onToggleDeleteExerciseModal(true), Title: t('delete_with_first_char_uppercase') });
        }
        if (ExerciseDetail.SharingDetails.SharedStatus === 1) Buttons.push({ BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: Copy, IconPosition: 'left', OnClick: this.onCopyExerciseToExercise, Title: t('copy_exercise_to_exercise') });

        if (!Buttons.length) return null;

        return (
            <>
                <ButtonGroup
                    Buttons={Buttons}
                    ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />
            </>
        );
    }

    renderChart = () => {
        var { t } = this.props;
        var { Chart, ExerciseType, Units } = this.props.ExerciseDetail;

        if (!Chart) return null;

        return (
            <Accordion
                BackgroundColor="var(--white-concrete)"
                BorderColor="var(--white-concrete)"
                Text={t('progress_graph_history')}
            >
                <ChartContainer>                    
                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('graph_shows_alltime_maxes')} TextAlign="center" />

                    <Spacer Size="small" />

                    <LineGraphContainer>
                        <LineGraph
                            AllTime
                            Goals={Chart.Goals}
                            Increment={Chart.Increment}
                            IsTime={+ExerciseType === 2}
                            Maximum={Chart.Maximum}
                            Minimum={Chart.Minimum}
                            Measurements={Chart.Measurements}
                            TimePeriod="1M"
                            Units={Units}
                        />
                    </LineGraphContainer>

                    <Spacer Size="medium" />

                    {this.renderTrackingHistory()}
                </ChartContainer>
            </Accordion>
        );
    }

    renderDeleteExerciseModal = () => {
        var { t } = this.props;
        var { ShowDeleteExerciseModal } = this.state;

        if (ShowDeleteExerciseModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteExercise}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    Information={t('exercise_delete_warning')}
                    Loading={this.props.TryingDeleteExercises}
                    OnHide={() => this.onToggleDeleteExerciseModal(false)}
                    Show={ShowDeleteExerciseModal}
                />
            );
        }
    }

    renderInstructions = () => {
        if (this.state.Loading || this.props.TryingGetExerciseDetail) return null;

        var { t } = this.props;
        var { ExerciseDetail: { ExerciseInstructions } } = this.props;

        return (
            <Accordion
                BackgroundColor="var(--white-concrete)"
                BorderColor="var(--white-concrete)"
                Text={t('instructions')}
            >
                <StyledInstructions FontFamily="medium" FontSize="medium-1" NoMargin Text={ExerciseInstructions} TextAlign="left" />
            </Accordion>
        );
    }

    renderMusclesUsed = () => {
        if (this.state.Loading || this.props.TryingGetExerciseDetail) return null;

        var { t } = this.props;
        var { ExerciseDetail: { UsedMuscles: { PrimaryMuscleIds, PrimaryMuscleNames, SecondaryMuscleIds, SecondaryMuscleNames, TertiaryMuscleColor, TertiaryMuscleIds, TertiaryMuscleNames } } } = this.props;

        return (
            <DetailContainer>
                <PageText FontFamily="semibold" FontSize="medium-2" NoMargin Text={t('WorkoutPlan_Muscles_used')} TextAlign="center" />

                <Spacer Size="small" />

                <StyledBodyMuscles
                    PrimaryMuscleIds={PrimaryMuscleIds}
                    SecondaryMuscleIds={SecondaryMuscleIds}
                    TertiaryMuscleIds={TertiaryMuscleIds}
                />

                <MultiText
                    ContainerWidth="100%"
                    TextAlign="left"
                    Texts={[
                        { FontColor: 'blue-abel', FontFamily: 'semibold', FontSize: 'medium', Text: `${t('WorkoutPlan_Primary')} -` },
                        { FontColor: 'blue-abel', FontFamily: 'medium', FontSize: 'medium', Text: PrimaryMuscleNames.join(', ') }
                    ]}
                />

                {
                    SecondaryMuscleIds && SecondaryMuscleIds.length > 0 &&
                    <MultiText
                        ContainerWidth="100%"
                        TextAlign="left"
                        Texts={[
                            { FontColor: 'blue-light', FontFamily: 'semibold', FontSize: 'medium', Text: `${t('WorkoutPlan_Secondary')} -` },
                            { FontColor: 'blue-light', FontFamily: 'medium', FontSize: 'medium', Text: SecondaryMuscleNames.join(', ') }
                        ]}
                    />
                }

                {
                    TertiaryMuscleIds && TertiaryMuscleIds.length > 0 &&
                    <MultiText
                        ContainerWidth="100%"
                        TextAlign="left"
                        Texts={[
                            { FontColorHex: `#${TertiaryMuscleColor}`, FontFamily: 'semibold', FontSize: 'medium', Text: `${t('WorkoutPlan_Tertiary')} -` },
                            { FontColorHex: `#${TertiaryMuscleColor}`, FontFamily: 'medium', FontSize: 'medium', Text: TertiaryMuscleNames.join(', ') }
                        ]}
                    />
                }
            </DetailContainer>
        );
    }

    renderProgress = () => {
        if (this.state.Loading || this.props.TryingGetExerciseDetail) return null;

        var { t } = this.props;
        var { ExerciseDetail: { ExerciseType, Progress: { CompletionStatus, Current, Goal, ProgressLine, Starting, Units } } } = this.props;

        return (
            <>
                <DetailContainer>
                    <PageText FontFamily="semibold" FontSize="medium-2" NoMargin Text={`${t('ptadmin_contenttype_header_progress')} (${Units})`} TextAlign="center" />

                    <Spacer Size="small" />

                    {
                        Starting !== null ?
                        <>
                            {this.renderStats()}

                            {
                                !!+Goal &&
                                <>
                                    <Spacer Size="extra-small" />

                                    <StyledLineBar
                                        CompletionStatus={CompletionStatus}
                                        CurrentValue={Current}
                                        GoalMax={0}
                                        GoalMin={Goal}
                                        HideCurrent
                                        IsTime={+ExerciseType === 2}
                                        ProgressLine={ProgressLine}
                                        Units={Units}
                                    />
                                </>
                            }
                        </>
                    :
                        <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('WorkoutPlan_This_is_a_new_exercise')} TextAlign="center" />
                    }
                </DetailContainer>
            </>
        );
    }

    renderShowEditShareAbilityStatusModal = () => {
        var { ShowEditShareAbilityStatusModal } = this.state;

        if (ShowEditShareAbilityStatusModal) {
            var { SharingDetails } = this.props.ExerciseDetail;

            return (
                <EditShareAbilityStatusModal
                    ContentId={this.props.ExerciseDetail_Preview.ExerciseId}
                    CurrentStatus={SharingDetails.ShareAbilityStatusId}
                    Loading={this.props.TryingUpdateShareAbilityStatus_Exercises}
                    OnHide={() => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: false })}
                    OnUpdateShareAbilityStatus={this.onUpdateShareAbilityStatus}
                    Show={ShowEditShareAbilityStatusModal}
                />
            );
        }
    }

    renderShowHowSharingWorksModal = () => {
        var { ShowHowSharingWorksModal } = this.state;

        if (ShowHowSharingWorksModal) {
            return (
                <HowSharingWorksModal
                    OnHide={() => this.onToggleShowHowSharingWorksModal(false)}
                    Show={ShowHowSharingWorksModal}
                />
            );
        }
    }

    renderShowShareContentModal = () => {
        var { ShowEditShareAbilityStatusModal, ShowHowSharingWorksModal, ShowShareContentModal } = this.state;

        if (ShowShareContentModal) {
            var { TryingUpdateSharedToCoachStatus_Exercises } = this.props;

            return (
                <ShareContentModal
                    ContentIdName="ExerciseId"
                    ContentType="Exercises"
                    DisplayNone={!!ShowEditShareAbilityStatusModal || !!ShowHowSharingWorksModal}
                    OnHide={() => this.onToggleShowShareContentModal(false)}
                    OnSubmit={({ Emails, ContentIds }) => this.onShareContentToEmails({ Emails, ContentIds })}
                    OnToggleShowEditShareAbilityStatusModal={({ ContentIndex, ContentId, CurrentStatus }) => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: true, ContentId, ContentIndex, CurrentStatus })}
                    OnToggleShowHowSharingWorksModal={() => this.onToggleShowHowSharingWorksModal(true)}
                    Loading={TryingUpdateSharedToCoachStatus_Exercises}
                    SharingContent={[{ ...this.props.ExerciseDetail, ...this.props.ExerciseDetail.SharingDetails, ...this.props.ExerciseDetail.SharingDetails.OwnerDetails, ExerciseId: this.props.ExerciseDetail_Preview.ExerciseId }]}
                    Show={ShowShareContentModal}
                />
            );
        }
    }

    renderStats = () => {
        var { t } = this.props;
        var { ExerciseDetail: { ExerciseType, Progress: { Current, Starting, Units } } } = this.props;

        var CurrentText = '';
        var StartingText = '';

        if (ExerciseType !== 2) {
            CurrentText = `${Current} ${Units.toLowerCase()}`;
            StartingText = `${Starting} ${Units.toLowerCase()}`;
        } else if (ExerciseType === 2) {
            CurrentText = SecondsToTime(Current);
            StartingText = SecondsToTime(Starting);
        }

        return (
            <ProgressContainer>
                <ProgressContainerInner>
                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('WorkoutPlan_Starting')} ${t('max').toLowerCase()}`} TextAlign="center" />

                    <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={StartingText} TextAlign="center" />
                </ProgressContainerInner>

                <ProgressContainerInner>
                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('WorkoutPlan_Current')} ${t('max').toLowerCase()}`} TextAlign="center" />

                    <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={CurrentText} TextAlign="center" />
                </ProgressContainerInner>
            </ProgressContainer>
        );
    }

    renderTrackingHistory = () => {
        var { t } = this.props;
        var { ExerciseDetail: { ExerciseType, TrackingHistory: { TotalRecords, TrackingHistory } } } = this.props;

        var ColumnCount = [ 1, 2, 3 ].includes(ExerciseType) ? 3 : 4;

        var Column1Text = '';
        var Column2Text = '';

        if ([ 1, 4 ].includes(ExerciseType)) Column1Text = t('Reps');
        if ([ 2, 5 ].includes(ExerciseType)) Column1Text = t('Seconds');
        if ([ 3 ].includes(ExerciseType)) Column1Text = t('Distance');
        if ([ 4 ].includes(ExerciseType)) Column2Text = t('Weight');
        if ([ 5 ].includes(ExerciseType)) Column2Text = t('Weight');

        var Column1Value = null;
        var Column2Value = null;
        var CalculatedMaxText = '';

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={`${t('ProgressSection_Tracking_history')} (${TotalRecords})`} TextAlign="left" />

                <Spacer Size="small" />

                <TrackingHistoryContainer>
                    <TrackingHistoryRow ColumnCount={ColumnCount}>
                        <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('Date_str')} TextAlign="center" />
                        <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={Column1Text} TextAlign="center" />
                        {
                            ColumnCount === 4 &&
                            <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={Column2Text} TextAlign="center" />
                        }
                        <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('ProgressSection_CalculatedMax')} TextAlign="center" />
                    </TrackingHistoryRow>
                    {
                        TrackingHistory.map(({ CalculatedMax, Id, Distance, Reps, Seconds, Units, UpdateDate, Weight }) => {
                            if ([ 1, 4 ].includes(ExerciseType)) Column1Value = `${Reps}`;
                            if ([ 2, 5 ].includes(ExerciseType)) Column1Value = SecondsToTime(Seconds);
                            if ([ 3 ].includes(ExerciseType)) Column1Value = `${Distance} ${Units.toLowerCase()}`;
                            if ([ 4 ].includes(ExerciseType)) Column2Value = `${Weight} ${Units.toLowerCase()}`;
                            if ([ 5 ].includes(ExerciseType)) Column2Value = `${Weight} ${Units.toLowerCase()}`;

                            if ([ 2 ].includes(ExerciseType)) CalculatedMaxText = SecondsToTime(CalculatedMax);
                            else CalculatedMaxText = `${CalculatedMax} ${Units.toLowerCase()}`;

                            return (
                                <TrackingHistoryRow key={Id} ColumnCount={ColumnCount}>
                                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={moment(UpdateDate).format('YYYY-MM-DD')} TextAlign="center" />
                                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={Column1Value} TextAlign="center" />
                                    {
                                        ColumnCount === 4 &&
                                        <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={Column2Value} TextAlign="center" />
                                    }
                                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={CalculatedMaxText} TextAlign="center" />
                                </TrackingHistoryRow>
                            );
                        })
                    }
                </TrackingHistoryContainer>
            </>
        );
    }

    renderModalBody = () => {
        var { Device, ExerciseDetail: { ExerciseVideo, IsDeleted, SharingDetails }, ExerciseDetail_Preview: { ExerciseName, ExerciseImage }, TryingGetExerciseDetail, TryingUpdatePausedSharing_ExercisesError, TryingUpdateShareAbilityStatus_Exercises, TryingUpdateSharedToCoachStatus_Exercises } = this.props;

        if (this.state.Loading || TryingGetExerciseDetail) {
            return (
                <Container>
                    <Loading />

                    <PageText FontFamily="bold" FontSize="large" JustifyContent="center" NoMargin Text={ExerciseName} TextAlign="center" />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'medium' : 'small'} />

                    <ExerciseImageContainer className="ExerciseImageContainer">
                        <StyledExerciseImage ImageSrc={ExerciseImage} SelfLoading />
                    </ExerciseImageContainer>
                </Container>
            );
        }

        var { Updating } = this.state;

        return (
            <>
                {Updating && <Loading />}

                <Container>
                    <PageText FontFamily="bold" FontSize="large" JustifyContent="center" NoMargin Text={ExerciseName} TextAlign="center" />

                    <Spacer Size="medium" />

                    {this.renderButtons()}

                    <Spacer Size="medium" />

                    <BodyContainer className="BodyContainer">
                        <TopArea className="TopArea">
                            <LeftTopArea className="LeftTopArea">
                                <ExerciseImageContainer className="ExerciseImageContainer">
                                {
                                    !!ExerciseVideo ?
                                    <StyledExerciseVideo id="video" { ...ExerciseImage && { poster: ExerciseImage } } controls><source src={ExerciseVideo} type="video/mp4" />Your browser does not support the video tag.</StyledExerciseVideo>
                                :
                                    <StyledExerciseImage ImageSrc={ExerciseImage} SelfLoading />
                                }
                                </ExerciseImageContainer>

                                <Spacer Size="medium" />

                                {this.renderInstructions()}
                            </LeftTopArea>

                            {(Device === 'mobile_small' || Device === 'mobile') && <Spacer Size="medium" />}

                            <RightTopArea className="RightTopArea">
                                {this.renderMusclesUsed()}
                            </RightTopArea>
                        </TopArea>
                        
                        {
                            (!!this.props.ClientId || (this.props.ExerciseDetail && +this.props.ExerciseDetail.UserId === +this.props.UserId)) &&
                            <>
                                <Spacer Size="medium" />

                                <BottomArea className="BottomArea">
                                    {this.renderProgress()}

                                    <Spacer Size="medium" />

                                    {this.renderChart()}
                                </BottomArea>
                            </>
                        }
                    </BodyContainer>

                    <Spacer Size="medium" />

                    <ContentSharingDetails
                        IsDeleted={IsDeleted}
                        Loading={TryingUpdatePausedSharing_ExercisesError || TryingUpdateShareAbilityStatus_Exercises || TryingUpdateSharedToCoachStatus_Exercises}
                        OnShareAgain={this.onShareAgain}
                        OnPauseSharing={this.onPauseSharing}
                        onToggleShowShareContentModal={() => this.onToggleShowShareContentModal(true)}
                        OnToggleShowEditShareAbilityStatusModal={() => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: true })}
                        OnToggleShowHowSharingWorksModal={() => this.onToggleShowHowSharingWorksModal(true)}
                        OnUnshare={this.onUnshareContent}
                        OnUpdateSharedToCoachStatus={this.onUpdateSharedToCoachStatus}
                        SharingDetails={SharingDetails}
                    />
                </Container>
            </>
        );
    }

    render() {
        var { OnHide, Show } = this.props;
        var { ShowDeleteExerciseModal, ShowEditShareAbilityStatusModal, ShowHowSharingWorksModal, ShowShareContentModal } = this.state;

        return (
            <>
                <AbelModal
                    BottomButton="cancel"
                    BottomButtonOnClick={OnHide}
                    DisplayNone={(ShowDeleteExerciseModal || ShowEditShareAbilityStatusModal || ShowHowSharingWorksModal || ShowShareContentModal)}
                    GA_PathName="ExerciseDetail"
                    NoMargin
                    Show={Show}
                    Size="large"
                    OnHide={OnHide}
                    TopButton="cancel"
                    TopButtonOnClick={OnHide}
                >
                    {this.renderModalBody()}
                </AbelModal>

                {this.renderDeleteExerciseModal()}
                {this.renderShowEditShareAbilityStatusModal()}
                {this.renderShowHowSharingWorksModal()}
                {this.renderShowShareContentModal()}
            </>
        );
    }
}

ExerciseDetailModal.propTypes = {
    ClientId: PropTypes.number,
    ExerciseDetail_Preview: PropTypes.object.isRequired,
    OnHide: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        UserId: state.Auth.UserDetails.UserId,

        UserDetails: state.Auth.UserDetails,

        ExerciseDetail: state.Exercise.ExerciseDetail,

        TryingDeleteExercises: state.Exercises.TryingDeleteExercises,
        TryingDeleteExercisesError: state.Exercises.TryingDeleteExercisesError,
        TryingGetExerciseDetail: state.Exercise.TryingGetExerciseDetail,
        TryingGetExerciseDetailError: state.Exercise.TryingGetExerciseDetailError,
        TryingUpdatePausedSharing_Exercises: state.Exercise.TryingUpdatePausedSharing_Exercises,
        TryingUpdatePausedSharing_ExercisesError: state.Exercise.TryingUpdatePausedSharing_ExercisesError,
        TryingUpdateShareAbilityStatus_Exercises: state.Exercises.TryingUpdateShareAbilityStatus_Exercises,
        TryingUpdateShareAbilityStatus_ExercisesError: state.Exercises.TryingUpdateShareAbilityStatus_ExercisesError,
        TryingUpdateSharedToCoachStatus_Exercises: state.Exercise.TryingUpdateSharedToCoachStatus_Exercises,
        TryingUpdateSharedToCoachStatus_ExercisesError: state.Exercise.TryingUpdateSharedToCoachStatus_ExercisesError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearExerciseDetail, DeleteExercises, GetExerciseDetail, SaveDataInProps, UpdatePausedSharing_Exercises, UpdateShareAbilityStatus_Exercises, UpdateSharedToCoachStatus_Exercises } )(ExerciseDetailModal));