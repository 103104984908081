import styled from "styled-components";
import { device } from "../../Config/device";

import ButtonGroup from "../../Components/Buttons/ButtonGroup";
import Image from "../../Components/Image";
import Input from "../../Components/Form/Input";
import TextArea from "../../Components/Form/TextArea";

import DeleteX from "../../Assets/Icons/Delete_X.png";
import DeleteX_Gray from "../../Assets/Icons/Delete_X_Gray.png";

export const ButtonIcon = styled.img`
  height: 25px;
  margin-right: 10px;
  max-width: 25px;
`;

export const ChartContainer = styled.div`
  background-color: var(--white-concrete);
  border-radius: 10px;
  flex: 1;
  padding: 10px 15px;
`;

export const ChartContainerHeader = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    grid-gap: 20px;
`;

export const ChartContainerHeaderLeft = styled.div``;

export const ChartContainerHeaderRight = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-gap: 10px;
`;

export const DurationContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const DurationInnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const EditButton = styled.div`
  align-items: center;
  background-color: var(--white-concrete);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;

  @media (hover: hover) {
    :hover {
      background-color: var(--white-concrete-hover);
      cursor: pointer;
    }
  }
`;

export const HeartRateZoneChartContainer = styled.div``;

export const HeartRateZonesContainer = styled.div`
  background-color: var(--white-concrete);
  border-radius: 10px;
  flex: 1;
  padding: 10px 15px;
`;

export const LineGraphContainer = styled.div`
    background-color: var(--white);
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    padding: 25px 15px;
`;

export const MediaPreview = styled.div`
  position: relative;
`;

export const MultiSelect = styled.span`
  ${(props) =>
    props.Selected
      ? `background-image: url(${DeleteX});`
      : `background-image: url(${DeleteX_Gray});`}

  background-color: var(--white);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  border-radius: 50%;
  height: 20px;
  right: 5px;
  position: absolute;
  top: 5px;
  width: 20px;
  z-index: 1;

  @media (hover: hover) {
    :hover {
      background-image: url(${DeleteX});
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px;
      border: none;
      cursor: pointer;
    }
  }
`;

export const StyledDuration = styled(Input)`
  margin: 0 auto;
  max-width: 100%;
  width: 350px;

  > input {
    background-color: var(--white-concrete);
    border: none;
    border-radius: 10px;
    text-align: center;
    text-indent: 0px;
  }
`;

export const StyledEditWorkoutNameIcon = styled(Image)`
  height: 15px;
  width: 15px;
`;

export const StyledExternalLink = styled.a`
  margin: 5px;
`;

export const StyledFrozenWorkoutIcon = styled(Image)`
  height: 15px;
  width: 15px;
`;

export const StyledHR = styled.div`
  background-color: var(--white-concrete);
  border-radius: 10px;
  height: 2px;
  width: 100%;
`;

export const StyledMediaDocumentPreview = styled.div`
  align-items: center;
  border: 2px solid var(--white-concrete);
  ${(props) =>
    props.IsDeletedSelected && "border-color: var(--red-bittersweet);"}
  ${(props) => props.IsNewMedia && "border-color: var(--blue-abel);"}
    ${(props) =>
    props.NewlySaved && "border-color: var(--orange-selectiveyellow);"}
    border-radius: 10px;
  color: var(--black);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 45px;
  padding: 7px;
  padding-right: 30px;
  position: relative;

  ${(props) =>
    !props.IsNewMedia &&
    `
            @media(hover:hover) {
                :hover {
                    color: var(--link-hover);
                    cursor: pointer;
                }
            }

            :active {
                color: var(--blue-abel);
            }
        `}
`;

export const StyledMediaPreview = styled(Image)`
  ${(props) =>
    props.IsDeletedSelected && "border: 2px solid var(--red-bittersweet);"}
  ${(props) => props.IsNewMedia && "border: 2px solid var(--blue-abel);"}
    ${(props) =>
    props.NewlySaved && "border: 2px solid var(--orange-selectiveyellow);"}

    border-radius: 10px;
  height: 45px;
  object-fit: cover;
  width: 90px;

  > div {
    width: 100%;
  }
`;

export const StyledVideoPreview = styled.video`
  border: 2px solid var(--black);

  ${(props) =>
    props.IsDeletedSelected && "border-color: var(--red-bittersweet);"}
  ${(props) => props.IsNewMedia && "border-color: var(--blue-abel);"}
  ${(props) =>
    props.NewlySaved && "border-color: var(--orange-selectiveyellow);"}

  border-radius: 10px;
  height: 45px;
  object-fit: cover;
  width: 90px;

  > div {
    width: 100%;
  }

  @media (hover: hover) {
    :hover {
      cursor: pointer;
    }
  }
`;

export const StyledVideoScreenIcon = styled(Image)`
  height: 100px;
  object-fit: cover;
  width: 100px;
  margin-right: 10px;

  @media (hover: hover) {
    :hover {
      cursor: pointer;
    }
  }
`;

export const StyledWorkoutImage = styled(Image)`
  border-radius: 10px;
  height: 200px;
  object-fit: cover;
  width: 100%;

  > div {
    width: 100%;
  }
`;

export const StyledWorkoutNameInput = styled(Input)`
  margin: 0;
  max-width: 350px;

  > input {
    background-color: var(--white-concrete);
    border: none;
    border-radius: 10px;
    text-align: center;
    text-indent: 0px;
  }
`;

export const StyledWorkoutNoteTextArea = styled(TextArea)`
  margin-bottom: 0px;
  width: 100%;

  > textarea {
    background-color: var(--white-concrete);
    border: none;
    padding: 0px;
    resize: none;
  }
`;

export const UploadButtonsContainer = styled(ButtonGroup)`
  justify-content: space-between;
`;

export const UploadContainer = styled.div`
  margin-top: 10px;
`;

export const UploadButtonsRow = styled.div`
  margin-bottom: 10px;
`;

export const UploadDocumentsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  > div {
    margin: 5px;
  }
`;

export const WearableDataContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  justify-content: space-between;
`;

export const WorkoutVideoSessionContainer = styled.div`
  background-color: var(--white-concrete);
  border: none;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  display: flex;
  width: 50%;

  @media ${device.maxwidth.ipad} {
    width: 100%;
  }
`;

export const WorkoutDateAndNameContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.minwidth.ipad} {
    flex-direction: row;
    justify-content: space-between;

    > div:first-child {
      margin-right: 10px;
    }
  }

  @media ${device.maxwidth.mobile} {
    > div:first-child {
      margin-bottom: 10px;
    }
  }
`;

export const WorkoutFrozenStatusContainer = styled.div`
  align-items: center;
`;

export const WorkoutFrozenContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  > div:first-child {
    margin-right: 25px;
  }
`;
export const WorkoutFrozen = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div:first-child {
    margin-right: 5px;
  }
`;

export const UnfreezeTextContainer = styled.div`
  background-color: var(--white-concrete);
  border: none;
  border-radius: 10px;
  text-align: center;
  padding: 5px 10px;

  @media (hover: hover) {
    :hover {
      background-color: var(--white-concrete-hover);
      cursor: pointer;
    }
  }
`;

export const WorkoutDateContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  > div:not(:last-child) {
    margin-right: 10px;
  }
`;

export const WorkoutNameContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  > div:first-child {
    margin-right: 10px;
  }
`;

export const WorkoutNoteContainer = styled.div``;

export const WorkoutNotesContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;

  @media ${device.minwidth.ipad} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const WorkoutNotesContainerInner = styled.div`
  align-items: flex-start;
  background-color: var(--white-concrete);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;

  @media ${device.maxwidth.mobile} {
    :not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;