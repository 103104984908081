import styled from 'styled-components';

export const BarChild = styled.div`
    background-color: var(${props => props.Color});
    border-radius: 10px;
    height: 10px;
    width: ${props => Math.min(props.Percentage, 100) || 0}%;
`;

export const BarLineContainer = styled.div`
    background-color: var(--white);
    border: 2px solid var(--white-concrete);
    border-radius: 20px;
    position: relative;
    width: 100%;
`;

export const ZoneBarAreaContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
`;

export const ZoneBarAreaHeader = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5px;
    width: 100%;
`;

export const ZoneContainer = styled.div`
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const ZonesContainer = styled.div``;