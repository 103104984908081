import styled from 'styled-components';
import { device } from '../../Config/device';

import { Link } from 'react-router-dom';

import LoadMore from '../../Components/Lists/LoadMore';

export const CheckmarkIcon = styled.img``;

export const Container = styled.div`
    position: relative;
`;

export const HasNewNotificationsContainer = styled.div`
    background-color: var(--blue-abel);
    border-radius: 30px;
    bottom: 30px;
    left: 50%;
    padding: 10px 15px;
    position: fixed;
    transform: translateX(-50%);
    width: auto;
    z-index: 1000;

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const MarkAsReadContainer = styled.div`
    align-items: center;
    background-color: var(--black);
    display: flex;
    margin-bottom: ${props => props.IsModal ? '2px' : '0px'};
    padding: 10px;

    ${props => !props.IsModal && props.FirstItem && 'border-top-right-radius: 10px;'}
    ${props => !props.IsModal && props.LastItem && 'border-bottom-right-radius: 10px;'}

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const NotificationContainer = styled.div`
    align-items: center;
    background-color: ${props => props.IsRead ? 'var(--white-concrete)' : 'var(--blue-gray)'};
    ${props => props.IsModal ? 'border-bottom: 2px solid var(--white);' : ''}
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;

    ${
        props => !props.IsModal && props.FirstItem &&
        `
            border-top-left-radius: 10px;
            ${props => !props.IsRead && 'border-top-right-radius: 10px;'}
        `
    }

    ${
        props => !props.IsModal && props.LastItem &&
        `
            border-bottom-left-radius: 10px;
            ${props => !props.IsRead && 'border-bottom-right-radius: 10px;'}
        `
    }
`;

export const NotificationContent = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const NotificationText = styled.div`
    margin-left: 10px;
`;

export const ReactionContainer = styled.div`
    align-items: center;
    background-color: ${props => props.IsRead ? 'var(--white-concrete)' : 'var(--blue-gray)'};
    ${props => props.IsModal ? 'border-bottom: 2px solid var(--white);' : ''}
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding: 10px;

    @media(hover:hover) {
        > svg {
            :hover {
                cursor: pointer;
            }
        }
    }
`;

export const StyledLink = styled(Link)`
    color: var(--black);
    display: flex;
    flex: 1;
    flex-direction: row;

    @media(hover:hover) {
        :hover {
            color: var(--link-hover);
            cursor: pointer;
        }
    }

    :active {
        color: var(--blue-abel);
    }
`;

export const StyledLoadMore = styled(LoadMore)`
    background-color: var(--white);
    border: none;
    padding: ${props => props.IsModal ? '0px' : '15px'};

    > div:first-child {
        display: grid;
        grid-gap: ${props => props.IsModal ? '0px' : '3px'};
        grid-template-columns: 1fr;

        @media ${device.minwidth.ipad} {
            margin: 0 auto;
            max-width: 600px;
        }
    }
`;