import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DeleteOrganizationRegion, DownloadOrganizationIncomeReport, GetOrganizationIncomeReport, GetOrganizationRegionsAndBranches, InsertEditOrganizationBranch2, InsertEditOrganizationRegion, MoveOrganizationBranchToNewRegion } from '../../Services/Actions';
import history from '../../history';

import { toast } from 'react-toastify';
import Popover from "react-popover";

import * as styles from './styles';

import Filters from './Filters';
import Header from './Header';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import DeleteBranch from '../../Modals/DeleteBranch';
import Info from '../../Modals/Info';

import DownArrow from '../../Assets/Icons/DownArrow.png';
import DownloadIcon from '../../Assets/Icons/Download_Gray.png';
import { ReactComponent as FilterSVG } from '../../Assets/SVG/Filter.svg';
import Plus from '../../Assets/Icons/PlusSign.png';
import Reorder from '../../Assets/Icons/Reorder_Dark.png';
import UpArrow from '../../Assets/Icons/UpArrow.png';
import X_Image from '../../Assets/Icons/X.png';

class OrganizationRegionBranchClient extends React.Component {
    renderData = () => {
        var { t } = this.props;
        var { Client, DataTypes, Locale_Language, ValueType } = this.props;

        if (ValueType === 'Product') {
            return (
                <styles.OrganizationRegionContainerHeaderDataContainer className="OrganizationRegionContainerHeaderDataContainer">
                    {
                        DataTypes.Sold &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_sold')} />

                            <Spacer Size="extra-extra-small" />

                            {
                                Client.Products.ProductList.Products.map(Product => {
                                    var { ProductId, ProductName, Sold } = Product;

                                    return (
                                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer" key={ProductId}>
                                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${Sold}`} />

                                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={ProductName} />
                                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer>
                                    )
                                })
                            }
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Delivered &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_delivered')} />

                            <Spacer Size="extra-extra-small" />

                            {
                                Client.Products.ProductList.Products.map(Product => {
                                    var { ProductId, ProductName, Delivered } = Product;

                                    return (
                                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer" key={ProductId}>
                                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-end" NoMargin TextAlign="right" Text={`${Delivered}`} />

                                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={ProductName} />
                                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer>
                                    )
                                })
                            }
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Delivered && DataTypes.Sold &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_change')} />

                            <Spacer Size="extra-extra-small" />

                            {
                                Client.Products.ProductList.Products.map(Product => {
                                    var { ProductId, ProductName, Change } = Product;

                                    return (
                                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer" key={ProductId}>
                                            <PageText FontColor={Change < 0 ? 'red-bittersweet' : 'black'} FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-end" NoMargin TextAlign="right" Text={`${Change}`} />

                                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={ProductName} />
                                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer>
                                    )
                                })
                            }
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }
                </styles.OrganizationRegionContainerHeaderDataContainer>
            );
        } else {
            var { Change, TotalDelivered, TotalPaid, TotalSales } = Client;

            return (
                <styles.OrganizationRegionContainerHeaderDataContainer className="OrganizationRegionContainerHeaderDataContainer" Columns={4}>
                    {
                        DataTypes.Sold &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_sold')} />

                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(TotalSales)}`} />
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Delivered &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_delivered')} />

                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(TotalDelivered)}`} />
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Paid &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_paid')} />

                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(TotalPaid)}`} />
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Sold && DataTypes.Delivered &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_change')} />

                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Change)}`} />
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }
                </styles.OrganizationRegionContainerHeaderDataContainer>
            );
        }
    }

    render() {
        // var { t } = this.props;
        var { Client } = this.props;
        var { DisplayName, Photo, Sex } = Client;

        var Clickable = false;

        return (
            <styles.OrganizationRegionBranchContainer className="OrganizationRegionBranchContainer">
                <styles.OrganizationRegionContainerHeader className="OrganizationRegionContainerHeader" Clickable={Clickable} onClick={this.onToggleOpen}>
                    <styles.PhotoNameContainer className="PhotoNameContainer">
                        <styles.StyledProfilePhoto Sex={Sex} ShowOverlay={false} Size="small-1" Source={Photo || ''} />
                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={DisplayName} />
                    </styles.PhotoNameContainer>
                </styles.OrganizationRegionContainerHeader>

                <styles.OrganizationRegionDataContainer className="OrganizationRegionDataContainer">
                    {this.renderData()}
                </styles.OrganizationRegionDataContainer>
            </styles.OrganizationRegionBranchContainer>
        );
    }
}

class OrganizationRegionBranchTrainer extends React.Component {
    state = {
        Open: false
    }

    onToggleOpen = () => {
        this.setState({ Open: !this.state.Open });
    }

    renderData = () => {
        var { t } = this.props;
        var { DataTypes, Locale_Language, Trainer, ValueType } = this.props;

        if (ValueType === 'Product') {
            return (
                <styles.OrganizationRegionContainerHeaderDataContainer className="OrganizationRegionContainerHeaderDataContainer">
                    {
                        DataTypes.Sold &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_sold')} />

                            <Spacer Size="extra-extra-small" />

                            {
                                Trainer.Products.ProductList.Products.map(Product => {
                                    var { ProductId, ProductName, Sold } = Product;

                                    return (
                                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer" key={ProductId}>
                                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-end" NoMargin TextAlign="right" Text={`${Sold}`} />

                                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={ProductName} />
                                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer>
                                    )
                                })
                            }
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Delivered &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_delivered')} />

                            <Spacer Size="extra-extra-small" />

                            {
                                Trainer.Products.ProductList.Products.map(Product => {
                                    var { ProductId, ProductName, Delivered } = Product;

                                    return (
                                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer" key={ProductId}>
                                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-end" NoMargin TextAlign="right" Text={`${Delivered}`} />

                                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={ProductName} />
                                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer>
                                    )
                                })
                            }
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Delivered && DataTypes.Sold &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_change')} />

                            <Spacer Size="extra-extra-small" />

                            {
                                Trainer.Products.ProductList.Products.map(Product => {
                                    var { ProductId, ProductName, Change } = Product;

                                    return (
                                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer" key={ProductId}>
                                            <PageText FontColor={Change < 0 ? 'red-bittersweet' : 'black'} FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-end" NoMargin TextAlign="right" Text={`${Change}`} />

                                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={ProductName} />
                                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer>
                                    )
                                })
                            }
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }
                </styles.OrganizationRegionContainerHeaderDataContainer>
            );
        } else {
            var { Change, TotalDelivered, TotalPaid, TotalSales } = Trainer;

            return (
                <styles.OrganizationRegionContainerHeaderDataContainer className="OrganizationRegionContainerHeaderDataContainer" Columns={4}>
                    {
                        DataTypes.Sold &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_sold')} />

                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(TotalSales)}`} />
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Delivered &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_delivered')} />

                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(TotalDelivered)}`} />
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Paid &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_paid')} />

                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(TotalPaid)}`} />
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Sold && DataTypes.Delivered &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_change')} />

                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Change)}`} />
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }
                </styles.OrganizationRegionContainerHeaderDataContainer>
            );
        }
    }

    renderToggle = () => {
        return (
            <styles.ToggleIcon onClick={this.onToggleOpen}>
                <img src={this.state.Open ? UpArrow : DownArrow} alt="Open and close the accordion" />
            </styles.ToggleIcon>
        );
    }

    render() {
        var { t } = this.props;
        var { DataTypes, Locale_Language, Trainer, ValueType } = this.props;
        var { DisplayName, Clients, Photo, Sex } = Trainer;

        var Clickable = !!Clients.length;

        return (
            <styles.OrganizationRegionBranchTrainerContainer className="OrganizationRegionBranchTrainerContainer">
                <styles.OrganizationRegionBranchTrainerContainerHeader className="OrganizationRegionBranchTrainerContainerHeader" Clickable={Clickable} onClick={this.onToggleOpen}>
                    <styles.PhotoNameContainer className="PhotoNameContainer">
                        <styles.StyledProfilePhoto Sex={Sex} ShowOverlay={false} Size="small-1" Source={Photo || ''} />
                        <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin TextAlign="left" Text={DisplayName} />
                    </styles.PhotoNameContainer>

                    <styles.OrganizationRegionContainerHeaderRight className="OrganizationRegionContainerHeaderRight">
                        <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${Clients.length} ${t('_clients').toLowerCase()}`} />

                        {Clickable && this.renderToggle()}
                    </styles.OrganizationRegionContainerHeaderRight>
                </styles.OrganizationRegionBranchTrainerContainerHeader>

                <styles.OrganizationRegionBranchTrainerDataContainer className="OrganizationRegionBranchTrainerDataContainer">
                    {
                        !Clickable ?
                        <styles.NoResultsTextContainer className="NoResultsTextContainer">
                            <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_no_results')} />
                        </styles.NoResultsTextContainer>
                    :
                        <>
                            {this.renderData()}

                            <styles.OrganizationRegionSubDataContainer className="OrganizationRegionSubDataContainer">
                                {
                                    this.state.Open && Clients.map(Client => {
                                        return <OrganizationRegionBranchClient key={Client.ClientId} Client={Client} DataTypes={DataTypes} Locale_Language={Locale_Language} ValueType={ValueType} t={t} />;
                                    })
                                }
                            </styles.OrganizationRegionSubDataContainer>
                        </>
                    }
                </styles.OrganizationRegionBranchTrainerDataContainer>
            </styles.OrganizationRegionBranchTrainerContainer>
        );
    }
}

class OrganizationRegionBranch extends React.Component {
    state = {
        Open: false
    }

    onToggleOpen = () => {
        this.setState({ Open: !this.state.Open });
    }

    renderData = () => {
        var { t } = this.props;
        var { Branch, DataTypes, Locale_Language, ValueType } = this.props;

        if (ValueType === 'Product') {
            return (
                <styles.OrganizationRegionContainerHeaderDataContainer className="OrganizationRegionContainerHeaderDataContainer">
                    {
                        DataTypes.Sold &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_sold')} />

                            <Spacer Size="extra-extra-small" />

                            {
                                Branch.Products.ProductList.Products.map(Product => {
                                    var { ProductId, ProductName, Sold } = Product;

                                    return (
                                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer" key={ProductId}>
                                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-end" NoMargin TextAlign="right" Text={`${Sold}`} />

                                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={ProductName} />
                                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer>
                                    )
                                })
                            }
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Delivered &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_delivered')} />

                            <Spacer Size="extra-extra-small" />

                            {
                                Branch.Products.ProductList.Products.map(Product => {
                                    var { ProductId, ProductName, Delivered } = Product;

                                    return (
                                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer" key={ProductId}>
                                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-end" NoMargin TextAlign="right" Text={`${Delivered}`} />

                                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={ProductName} />
                                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer>
                                    )
                                })
                            }
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Delivered && DataTypes.Sold &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_change')} />

                            <Spacer Size="extra-extra-small" />

                            {
                                Branch.Products.ProductList.Products.map(Product => {
                                    var { ProductId, ProductName, Change } = Product;

                                    return (
                                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer" key={ProductId}>
                                            <PageText FontColor={Change < 0 ? 'red-bittersweet' : 'black'} FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-end" NoMargin TextAlign="right" Text={`${Change}`} />

                                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={ProductName} />
                                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer>
                                    )
                                })
                            }
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }
                </styles.OrganizationRegionContainerHeaderDataContainer>
            );
        } else {
            var { Change, TotalDelivered, TotalPaid, TotalSales } = Branch;

            return (
                <styles.OrganizationRegionContainerHeaderDataContainer className="OrganizationRegionContainerHeaderDataContainer" Columns={4}>
                    {
                        DataTypes.Sold &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_sold')} />

                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(TotalSales)}`} />
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Delivered &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_delivered')} />

                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(TotalDelivered)}`} />
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Paid &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_paid')} />

                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(TotalPaid)}`} />
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Sold && DataTypes.Delivered &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_change')} />

                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Change)}`} />
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }
                </styles.OrganizationRegionContainerHeaderDataContainer>
            );
        }
    }

    renderToggle = () => {
        return (
            <styles.ToggleIcon onClick={this.onToggleOpen}>
                <img src={this.state.Open ? UpArrow : DownArrow} alt="Open and close the accordion" />
            </styles.ToggleIcon>
        );
    }

    render() {
        var { t } = this.props;
        var { Branch, DataTypes, Locale_Language, ValueType } = this.props;
        var { BranchName, Trainers } = Branch;

        var Clickable = !!Trainers.length;

        return (
            <styles.OrganizationRegionBranchContainer className="OrganizationRegionBranchContainer">
                <styles.OrganizationRegionContainerHeader className="OrganizationRegionContainerHeader" Clickable={Clickable} onClick={this.onToggleOpen}>
                    <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin TextAlign="left" Text={BranchName} />

                    <styles.OrganizationRegionContainerHeaderRight className="OrganizationRegionContainerHeaderRight">
                        <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${Trainers.length} ${t('_coaches').toLowerCase()}`} />

                        {Clickable && this.renderToggle()}
                    </styles.OrganizationRegionContainerHeaderRight>
                </styles.OrganizationRegionContainerHeader>

                <styles.OrganizationRegionDataContainer className="OrganizationRegionDataContainer">
                    {
                        !Clickable ?
                        <styles.NoResultsTextContainer className="NoResultsTextContainer">
                            <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_no_results')} />
                        </styles.NoResultsTextContainer>
                    :
                        <>
                            {this.renderData()}

                            <styles.OrganizationRegionSubDataContainer className="OrganizationRegionSubDataContainer">
                                {
                                    this.state.Open && Trainers.map(Trainer => {
                                        return <OrganizationRegionBranchTrainer key={Trainer.TrainerId} DataTypes={DataTypes} Locale_Language={Locale_Language} ValueType={ValueType} Trainer={Trainer} t={t} />;
                                    })
                                }
                            </styles.OrganizationRegionSubDataContainer>
                        </>
                    }
                </styles.OrganizationRegionDataContainer>
            </styles.OrganizationRegionBranchContainer>
        );
    }
}

class OrganizationRegion extends React.Component {
    state = {
        Open: false
    }

    onToggleOpen = () => {
        this.setState({ Open: !this.state.Open });
    }

    renderData = () => {
        var { t } = this.props;
        var { DataTypes, Locale_Language, Region, ValueType } = this.props;

        if (ValueType === 'Product') {
            return (
                <styles.OrganizationRegionContainerHeaderDataContainer className="OrganizationRegionContainerHeaderDataContainer">
                    {
                        DataTypes.Sold &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_sold')} />

                            <Spacer Size="extra-extra-small" />

                            {
                                Region.Products.ProductList.Products.map(Product => {
                                    var { ProductId, ProductName, Sold } = Product;

                                    return (
                                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer" key={ProductId}>
                                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-end" NoMargin TextAlign="right" Text={`${Sold}`} />

                                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={ProductName} />
                                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer>
                                    )
                                })
                            }
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Delivered &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_delivered')} />

                            <Spacer Size="extra-extra-small" />

                            {
                                Region.Products.ProductList.Products.map(Product => {
                                    var { ProductId, ProductName, Delivered } = Product;

                                    return (
                                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer" key={ProductId}>
                                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-end" NoMargin TextAlign="right" Text={`${Delivered}`} />

                                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={ProductName} />
                                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer>
                                    )
                                })
                            }
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Delivered && DataTypes.Sold &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_change')} />

                            <Spacer Size="extra-extra-small" />

                            {
                                Region.Products.ProductList.Products.map(Product => {
                                    var { ProductId, ProductName, Change } = Product;

                                    return (
                                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer" key={ProductId}>
                                            <PageText FontColor={Change < 0 ? 'red-bittersweet' : 'black'} FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-end" NoMargin TextAlign="right" Text={`${Change}`} />

                                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={ProductName} />
                                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer>
                                    )
                                })
                            }
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }
                </styles.OrganizationRegionContainerHeaderDataContainer>
            );
        } else {
            var { Change, TotalDelivered, TotalPaid, TotalSales } = Region;

            return (
                <styles.OrganizationRegionContainerHeaderDataContainer className="OrganizationRegionContainerHeaderDataContainer" Columns={4}>
                    {
                        DataTypes.Sold &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_sold')} />

                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(TotalSales)}`} />
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Delivered &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_delivered')} />

                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(TotalDelivered)}`} />
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Paid &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_paid')} />

                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(TotalPaid)}`} />
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }

                    {
                        DataTypes.Sold && DataTypes.Delivered &&
                        <styles.OrganizationRegionContainerHeaderDataContainerColumnContainer className="OrganizationRegionContainerHeaderDataContainerColumnContainer">
                            <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_change')} />

                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Change)}`} />
                        </styles.OrganizationRegionContainerHeaderDataContainerColumnContainer>
                    }
                </styles.OrganizationRegionContainerHeaderDataContainer>
            );
        }
    }

    renderToggle = () => {
        return (
            <styles.ToggleIcon onClick={this.onToggleOpen}>
                <img src={this.state.Open ? UpArrow : DownArrow} alt="Open and close the accordion" />
            </styles.ToggleIcon>
        );
    }

    render() {
        var { t } = this.props;
        var { DataTypes, Locale_Language, Region, ValueType } = this.props;
        var { RegionName, Branches } = Region;

        if (!RegionName) RegionName = 'No region saved';

        var Clickable = !!Branches.length;

        return (
            <styles.OrganizationRegionContainer className="OrganizationRegionContainer">
                <styles.OrganizationRegionContainerHeader className="OrganizationRegionContainerHeader" Clickable={Clickable} onClick={this.onToggleOpen}>
                    <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin TextAlign="left" Text={RegionName} />

                    <styles.OrganizationRegionContainerHeaderRight className="OrganizationRegionContainerHeaderRight">
                        <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${Branches.length} ${t('_departments').toLowerCase()}`} />

                        {Clickable && this.renderToggle()}
                    </styles.OrganizationRegionContainerHeaderRight>
                </styles.OrganizationRegionContainerHeader>

                <styles.OrganizationRegionDataContainer className="OrganizationRegionDataContainer">
                    {
                        !Clickable ?
                        <styles.NoResultsTextContainer className="NoResultsTextContainer">
                            <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_no_results')} />
                        </styles.NoResultsTextContainer>
                    :
                        <>
                            {this.renderData()}

                            <styles.OrganizationRegionSubDataContainer className="OrganizationRegionSubDataContainer">
                                {
                                    this.state.Open && Branches.map(Branch => {
                                        return <OrganizationRegionBranch key={Branch.BranchId} Branch={Branch} DataTypes={DataTypes} Locale_Language={Locale_Language} ValueType={ValueType} t={t} />;
                                    })
                                }
                            </styles.OrganizationRegionSubDataContainer>
                        </>
                    }
                </styles.OrganizationRegionDataContainer>
            </styles.OrganizationRegionContainer>
        );
    }
}

class OrganizationRegionRAB extends React.Component {
    state = { Branches: [], RegionId: 0, RegionName: '', MoveBranchPopover_IsOpen: false, MovingBranchId: null }

    componentDidMount() {
        if (this.props.Region) {
            var { Branches, RegionId, RegionName } = JSON.parse(JSON.stringify(this.props.Region));

            this.setState({ Branches, RegionId, RegionName });
        }
    }

    componentDidUpdate() {
        // Added or deleted Branch, So Update Branches
            if (this.props.Region.RegionId === 0 && this.state.Branches.length !== this.props.Region.Branches.length) {
                this.setState({ Branches: [ ...this.props.Region.Branches ] });
            }

        // Close Other Popovers
            if (
                this.props.CloseOtherPopovers !== null &&
                this.props.CloseOtherPopovers !== this.state.MovingBranchId &&
                this.state.MoveBranchPopover_IsOpen
            ) this.setState({ MoveBranchPopover_IsOpen: false });
    }

    onAddNewBranch = () => {
        var Branches = [ ...this.state.Branches ];

        Branches.push({ BankAccountNo: '', BranchId: null, BranchName: '', RegionId: this.state.RegionId });

        this.setState({ Branches });
    }

    onBlurBranchBankAccount = (BranchId, BranchIndex) => {
        if (!!BranchId && (this.state.Branches[BranchIndex].BankAccountNo !== JSON.parse(JSON.stringify(this.props.Region.Branches[BranchIndex].BankAccountNo)))) {
            var { BankAccountNo, BranchName } = this.state.Branches[BranchIndex];
            this.props.OnInsertEditBranch({ BankAccountNo, BranchId, BranchName, BranchIndex });
        }
    }

    onBlurBranchName = (BranchId, BranchIndex) => {
        if (!BranchId || (this.state.Branches[BranchIndex].BranchName !== JSON.parse(JSON.stringify(this.props.Region.Branches[BranchIndex].BranchName)))) {
            var { BankAccountNo, BranchName } = this.state.Branches[BranchIndex];
            this.props.OnInsertEditBranch({ BankAccountNo, BranchId, BranchName, BranchIndex }).then(({ NewBranchId }) => {
                // New Branch Created, So Add Real BranchId
                    if (!BranchId) {
                        var Branches = [ ...this.state.Branches ];

                        Branches[Branches.length - 1].BranchId = NewBranchId;

                        this.setState({ Branches });
                    }
            })
        }
    }

    onBlurRegionName = () => {
        var { RegionName: OriginalRegionName } = this.props.Region;

        if (!this.state.RegionName) this.setState({ RegionName: OriginalRegionName });
        else if (this.state.RegionName !== OriginalRegionName) {
            var { RegionId, RegionName } = this.state;
            this.props.OnInsertEditRegion({ RegionId, RegionName });
        }
    }

    onChangeBankAccountNo = (event, BranchIndex) => {
        var Branches = [ ...this.state.Branches ];
        Branches[BranchIndex].BankAccountNo = event.target.value;

        this.setState({ Branches });
    }

    onChangeBranchName = (event, BranchIndex) => {
        var Branches = [ ...this.state.Branches ];
        Branches[BranchIndex].BranchName = event.target.value;

        this.setState({ Branches });
    }

    onChangeRegionName = event => {
        this.setState({ RegionName: event.target.value });
    }

    onToggleMoveBranchPopover = BranchId => {
        var DoShowPopover = !this.state.MoveBranchPopover_IsOpen;

        this.setState({ MoveBranchPopover_IsOpen: DoShowPopover, MovingBranchId: DoShowPopover ? BranchId : null }, () => {
            if (!DoShowPopover) this.props.OnCloseOtherPopovers(BranchId);
        });
    };

    renderPopoverContent = () => {
        var { t } = this.props;
        var { OtherRegions } = this.props;

        return (
            <styles.StyledPopover className="StyledPopover">
                <styles.PopoverContent className="PopoverContent">
                    <styles.ChooseRegionsContainer className="ChooseRegionsContainer">
                        <styles.ChooseRegionContainer className="ChooseRegionContainer">
                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${t('_move_to')}:`} />
                        </styles.ChooseRegionContainer>

                        {
                            OtherRegions.map(Region => {
                                // Don't Show Option If "No Region" Or Same Region Already Chosen
                                    if (Region.RegionId === 0 || Region.RegionId === this.props.Region.RegionId) return null;

                                return (
                                    <styles.ChooseRegionContainer className="ChooseRegionContainer" key={Region.RegionId} Clickable onClick={() => this.props.OnMoveToRegion(this.state.MovingBranchId, Region.RegionId)}>
                                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Region.RegionName} />
                                    </styles.ChooseRegionContainer>
                                );
                            })
                        }
                    </styles.ChooseRegionsContainer>
                </styles.PopoverContent>
            </styles.StyledPopover>
        );
    };

    render() {
        var { t } = this.props;

        var { Branches, RegionId, RegionName } = this.state;
        var { OtherRegions } = this.props;

        return (
            <styles.OrganizationRegion_RABContainer className="OrganizationRegion_RABContainer">
                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_name_of_region')} />

                {
                    RegionId === 0 ?
                    <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_no_region')} />
                :
                    <styles.StyledInput
                        FontSize="medium-1"
                        NoLabel
                        NoMargin
                        OnBlur={this.onBlurRegionName}
                        OnChange={this.onChangeRegionName}
                        Size="small"
                        Type="text"
                        Value={RegionName}
                    />
                }

                {
                    RegionId !== 0 &&
                    <>
                        <Spacer Size="extra-extra-small" />

                        <PageText FontColor="red-bittersweet" FontColorHover="red-bittersweet-hover" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={this.props.OnToggleShowDeleteRegionModal} TextAlign="left" Text={t('_delete_region')} />
                    </>
                }

                {
                    (RegionId !== null && !!Branches.length) &&
                    <>
                        <Spacer Size="small" />

                        <styles.OrganzationRegionBranches_RABContainer className="OrganzationRegionBranches_RABContainer">
                            <styles.OrganizationRegionBranch_RABContainer className="OrganizationRegionBranch_RABContainer">
                                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_name_of_branch')} />
                                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_bank_account')} />
                            </styles.OrganizationRegionBranch_RABContainer>

                            {
                                Branches.map((Branch, BranchIndex) => {
                                    var { BranchId, BranchName, BankAccountNo } = Branch;

                                    const popoverProps = {
                                        isOpen: this.state.MoveBranchPopover_IsOpen && this.state.MovingBranchId === BranchId,
                                        preferPlace: "above",
                                        onOuterAction: this.onToggleMoveBranchPopover,
                                        body: this.renderPopoverContent(),
                                        tipSize: 10
                                    }

                                    return (
                                        <styles.OrganizationRegionBranch_RABContainer className="OrganizationRegionBranch_RABContainer" key={BranchIndex}>
                                            <styles.StyledInput
                                                FontSize="medium-1"
                                                NoLabel
                                                NoMargin
                                                OnBlur={() => this.onBlurBranchName(BranchId, BranchIndex)}
                                                OnChange={(event) => this.onChangeBranchName(event, BranchIndex)}
                                                Size="small"
                                                Type="text"
                                                Value={BranchName}
                                            />

                                            <styles.StyledInput
                                                FontSize="medium-1"
                                                NoLabel
                                                NoMargin
                                                OnBlur={() => this.onBlurBranchBankAccount(BranchId, BranchIndex)}
                                                OnChange={(event) => this.onChangeBankAccountNo(event, BranchIndex)}
                                                Size="small"
                                                Type="text"
                                                Value={BankAccountNo}
                                            />

                                            <ButtonGroup Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Icon: X_Image, IconOnly: true, IconSize: { Height: '15px', Width: '15px' }, Margin: '3px', OnClick: () => this.props.OnToggleShowDeleteBranchModal({ BranchId, BranchIndex }), Padding: '7px', Size: 'small' }]} />

                                            {
                                                OtherRegions.length > 1 &&
                                                <Popover {...popoverProps}>
                                                    <ButtonGroup Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Icon: Reorder, IconOnly: true, IconSize: { Height: '15px', Width: '15px' }, Margin: '3px', OnClick: () => this.onToggleMoveBranchPopover(BranchId), Padding: '7px', Size: 'small' }]} />
                                                </Popover>
                                            }
                                        </styles.OrganizationRegionBranch_RABContainer>
                                    )
                                })
                            }
                        </styles.OrganzationRegionBranches_RABContainer>
                    </>
                }

                {
                    RegionId !== null &&
                    <>
                        <Spacer Size="extra-small" />

                        <ButtonGroup
                            Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: this.onAddNewBranch, Size: 'small', TextAlign: 'left', Title: t('_add_new_branch') }]}
                            ButtonWidth={'fit-content'}
                            JustifyContent="flex-start"
                            NotTouching
                        />
                    </>
                }
            </styles.OrganizationRegion_RABContainer>
        );
    }
}

class OrganizationIncomeComponent extends React.Component {
    state = {
        DataTypes: {
            Sold: true,
            Paid: true,
            Delivered: true
        },
        TimeRange: {
            Type: null,
            StartDate: '', // new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1).toISOString().slice(0, 10),
            EndDate: '' // new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 0).toISOString().slice(0, 10)
        },
        ValueType: 'Currency', // 'Product',
        Locale_Language: +this.props.UserDetails.LanguageId === 2 ? 'no-NO' : 'en-US',
        IncomeNeedsUpdateFromEdits: false,
        RefreshBranches: false,
        CloseOtherPopovers: null,
        ShowAddRegionsAndBranches: false,
        ShowAddNewRegion: false,
        ShowDeleteBranchModal: false,
        ShowDeleteBranchModal_BranchId: null,
        ShowDeleteBranchModal_BranchIndex: null,
        ShowDeleteBranchModal_RegionIndex: null,
        ShowDeleteRegionModal: false,
        ShowDeleteRegionModal_RegionId: null,
        ShowDeleteRegionModal_RegionIndex: null,
        ShowFiltersModals: false
    };
    
    componentDidMount() {
        if (this.props.PageTitle === 'Income') this.onLoadIncome();
        else {
            this.setState({ ShowAddRegionsAndBranches: true }, () => {
                this.props.GetOrganizationRegionsAndBranches();
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.PageTitle !== this.props.PageTitle) {
            if (this.props.PageTitle === 'Income') this.onLoadIncome();
            else {
                this.setState({ ShowAddRegionsAndBranches: true }, () => {
                    this.props.GetOrganizationRegionsAndBranches();
                });
            }
        }
    }

    onCloseOtherPopovers = BranchId => {
        this.setState({ CloseOtherPopovers: BranchId }, () =>
          this.setState({ CloseOtherPopovers: null })
        );
    }

    onDeletedBranch = () => {
        this.onToggleShowDeleteBranchModal(false);
        this.setState({ RefreshBranches: true }, () => {
            this.props.GetOrganizationRegionsAndBranches().then(() => {
                this.setState({ RefreshBranches: false });
            })
        });
    }

    onDeleteRegion = () => {
        var { ShowDeleteRegionModal_RegionId: RegionId, ShowDeleteRegionModal_RegionIndex: RegionIndex } = this.state;

        this.onToggleShowDeleteRegionModal(false);
        this.setState({ IncomeNeedsUpdateFromEdits: true });
        this.props.DeleteOrganizationRegion({ RegionId, RegionIndex });
    }

    onExportReport = () => {
        var { TimeRange } = this.state;
        var { StartDate, EndDate } = TimeRange;

        var { OrgName } = this.props.OrganizationIncome;

        this.props.DownloadOrganizationIncomeReport({ OrgName, StartDate, EndDate });
    }

    onInsertEditRegionBranch = ({ BankAccountNo, BranchId, BranchName, BranchIndex }, RegionId, RegionIndex) => {
        this.setState({ IncomeNeedsUpdateFromEdits: true });
        return this.props.InsertEditOrganizationBranch2({ BankAccountNo, BranchId, BranchName, BranchIndex, RegionId, RegionIndex }).then(({ NewBranchId }) => {
            return { NewBranchId };
        })
    }

    onInsertEditRegion = ({ RegionId, RegionName }, RegionIndex) => {
        this.setState({ IncomeNeedsUpdateFromEdits: true });
        this.props.InsertEditOrganizationRegion({ RegionId, RegionIndex, RegionName }).then(() => {
            if (!RegionId && this.state.ShowAddNewRegion) this.setState({ ShowAddNewRegion: false }); 
        });
    }

    onLoadIncome = () => {
        var { TimeRange } = this.state;
        var { StartDate, EndDate } = TimeRange;

        if ((TimeRange.Type === 'Custom' && !!StartDate && !!EndDate) || (TimeRange.Type === 'CurrentMonth' || TimeRange.Type === 'CurrentYear' || TimeRange.Type === 'AllTime')) {
            if (TimeRange.Type === 'Custom') {
                StartDate = TimeRange.StartDate;
                EndDate = TimeRange.EndDate;
            } else if (TimeRange.Type === 'CurrentMonth') {
                // First & Last Day Of Month
                StartDate = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), 1).toISOString().slice(0, 10);
                EndDate = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 0).toISOString().slice(0, 10);
            } else if (TimeRange.Type === 'CurrentYear') {
                StartDate = `${new Date().getUTCFullYear()}-01-01`; // January 1 of current year
                EndDate = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 0).toISOString().slice(0, 10);
            } else if (TimeRange.Type === 'AllTime') {
                // StartDate = 'AllTime'; Not Updated In State, Only Sent To API so that previous start date can be saved
                EndDate = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1, 0).toISOString().slice(0, 10);
            }
    
            this.setState({ IncomeNeedsUpdateFromEdits: false, TimeRange: { ...this.state.TimeRange, StartDate, EndDate } }, () => {
                this.props.GetOrganizationIncomeReport({ StartDate: (TimeRange.Type === 'AllTime' ? 'AllTime' : StartDate), EndDate });
            });
        }
    }

    onMoveBranchToNewRegion = (BranchId, NewRegionId) => {
        this.onCloseOtherPopovers(0);
        this.setState({ IncomeNeedsUpdateFromEdits: true });

        this.props.MoveOrganizationBranchToNewRegion({ BranchId, RegionId: NewRegionId }).then(() => {
            this.setState({ RefreshBranches: true }, () => {
                this.props.GetOrganizationRegionsAndBranches().then(() => {
                    this.setState({ RefreshBranches: false });
                })
            });
        })
    }

    onSelectCustomDates = ({ StartDate, EndDate }) => {
        StartDate = StartDate.toISOString().slice(0, 10);
        EndDate = EndDate.toISOString().slice(0, 10);

        this.setState({ TimeRange: { ...this.state.TimeRange, StartDate, EndDate } }, () => {
            this.onLoadIncome();
        });
    }

    onSelectDataType = DataType => {
        var NewDataTypes = { ...this.state.DataTypes };
        NewDataTypes[DataType] = !NewDataTypes[DataType];

        this.setState({ DataTypes: NewDataTypes });
    }

    onSelectTimeRange = Type => {
        if (this.state.TimeRange.Type !== Type) {
            if (Type === 'Custom') this.setState({ TimeRange: { ...this.state.TimeRange, Type } });
            else this.setState({ TimeRange: { ...this.state.TimeRange, Type } }, () => this.onLoadIncome());
        }
    }

    onSelectValueType = ValueType => {
        if (ValueType === 'Product' && this.state.ValueType !== 'Product') {
            this.setState({
                DataTypes: { Sold: true, Paid: false, Delivered: true },
                ValueType
            })
        }
        else this.setState({ ValueType });
    }

    onShowDeleteLastBranchWarning = () => {
        this.onToggleShowDeleteBranchModal(false);

        toast.warn(this.props.t('_delete_last_branch_error'), {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    onToggleShowAddNewRegion = ShowAddNewRegion => {
        this.setState({ ShowAddNewRegion });
    }

    onToggleShowAddRegionsAndBranches = () => {
        var ShowAddRegionsAndBranches = !this.state.ShowAddRegionsAndBranches;

        this.setState({ ShowAddRegionsAndBranches }, () => {
            if (!!ShowAddRegionsAndBranches) {
                // this.props.GetOrganizationRegionsAndBranches();
                history.push('/income?tab=organize')
            }
            else {
                if (!!this.state.IncomeNeedsUpdateFromEdits) this.onLoadIncome();
                history.push('/income?tab=income');
            }
        });
    }

    onToggleShowDeleteBranchModal = (ShowDeleteBranchModal, BranchData, RegionIndex) => {
        if (!ShowDeleteBranchModal) this.setState({ ShowDeleteBranchModal, ShowDeleteBranchModal_BranchId: null, ShowDeleteBranchModal_BranchIndex: null, ShowDeleteBranchModal_RegionIndex: null });
        else {
            var { BranchId, BranchIndex } = BranchData;
            this.setState({ ShowDeleteBranchModal, ShowDeleteBranchModal_BranchId: BranchId, ShowDeleteBranchModal_BranchIndex: BranchIndex, ShowDeleteBranchModal_RegionIndex: RegionIndex })
        }
    }

    onToggleShowDeleteRegionModal = (ShowDeleteRegionModal, RegionId, RegionIndex) => {
        if (!ShowDeleteRegionModal) this.setState({ ShowDeleteRegionModal, ShowDeleteRegionModal_RegionId: null, ShowDeleteRegionModal_RegionIndex: null });
        else {
            // If new region, just delete without confirmation
            if (!RegionId) this.onToggleShowAddNewRegion(false);
            else this.setState({ ShowDeleteRegionModal, ShowDeleteRegionModal_RegionId: RegionId, ShowDeleteRegionModal_RegionIndex: RegionIndex });
        }
    }

    onToggleShowFiltersModal = ShowFiltersModals => {
        this.setState({ ShowFiltersModals });
    }

    renderAddRegionsAndBranches = () => {
        var { t } = this.props;
        var { OrganizationRegionsAndBranches } = this.props;
        var { RefreshBranches, ShowAddNewRegion } = this.state;

        return (
            <>
                <styles.AddRegionsAndBranchesHeaderContainer className="AddRegionsAndBranchesHeaderContainer">
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_add_regions_and_branches')} />
                    <PageText OnClick={this.onToggleShowAddRegionsAndBranches} FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_view_income')} />
                </styles.AddRegionsAndBranchesHeaderContainer>

                <Spacer Size="small" />

                <ButtonGroup
                    Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onToggleShowAddNewRegion(true), Size: 'small', TextAlign: 'left', Title: t('_add_new_region') }]}
                    ButtonWidth={'fit-content'}
                    JustifyContent="flex-start"
                    NotTouching
                />
        
                <Spacer Size="small-1" />

                {
                    !RefreshBranches &&
                    <styles.RegionsContainer className="RegionsContainer">
                        {
                                !!ShowAddNewRegion &&
                                <OrganizationRegionRAB
                                    Region={{ Branches: [], RegionId: null, RegionName: ''}}
                                    OnInsertEditBranch={data => this.onInsertEditRegionBranch(data, 0, OrganizationRegionsAndBranches.length - 1)}
                                    OnInsertEditRegion={data => this.onInsertEditRegion(data, OrganizationRegionsAndBranches.length - 1)}
                                    OnToggleShowDeleteRegionModal={() => this.onToggleShowDeleteRegionModal(true, 0, OrganizationRegionsAndBranches.length - 1)}
                                    t={t}
                                />
                            }

                            {
                                OrganizationRegionsAndBranches.map((Region, RegionIndex) => {
                                    return (
                                        <OrganizationRegionRAB
                                            key={Region.RegionId}
                                            OtherRegions={OrganizationRegionsAndBranches}
                                            Region={Region}
                                            CloseOtherPopovers={this.state.CloseOtherPopovers}
                                            OnCloseOtherPopovers={this.onCloseOtherPopovers}
                                            OnInsertEditBranch={data => this.onInsertEditRegionBranch(data, Region.RegionId, RegionIndex)}
                                            OnInsertEditRegion={data => this.onInsertEditRegion(data, RegionIndex)}
                                            OnMoveToRegion={(BranchId, NewRegionId) => this.onMoveBranchToNewRegion(BranchId, NewRegionId)}
                                            OnToggleShowDeleteBranchModal={data => this.onToggleShowDeleteBranchModal(true, data, RegionIndex)}
                                            OnToggleShowDeleteRegionModal={() => this.onToggleShowDeleteRegionModal(true, Region.RegionId, RegionIndex)}
                                            t={t}
                                        />
                                    )
                                })
                            }
                    </styles.RegionsContainer>
                }
            </>
        );
    }

    renderBody = () => {
        var { DataTypes, ShowAddRegionsAndBranches, TimeRange, ValueType } = this.state;

        if (ShowAddRegionsAndBranches) {
            return (
                <>
                    {<Header PageTitle={this.props.PageTitle} />}

                    <styles.BodyContainer className="BodyContainer">
                        {this.renderAddRegionsAndBranches()}
                    </styles.BodyContainer>
                </>
            );
        }

        var { t } = this.props;
        var { Device, TryingGetOrganizationIncome, UserDetails } = this.props;

        return (
            <>
                {<Header PageTitle={this.props.PageTitle} />}

                {
                    Device === 'laptop' &&
                    <Filters
                        OnAddRegionsAndBranches={this.onToggleShowAddRegionsAndBranches}
                        CurrencyName={UserDetails.CurrencyName}
                        DataTypes={DataTypes}
                        Loading={TryingGetOrganizationIncome}
                        OnExportReport={this.onExportReport}
                        TimeRange={TimeRange}
                        ValueType={ValueType}
                        OnSelectCustomDates={this.onSelectCustomDates}
                        OnSelectDataType={this.onSelectDataType}
                        OnSelectTimeRange={this.onSelectTimeRange}
                        OnSelectValueType={this.onSelectValueType}
                    />
                }

                <styles.BodyContainer className="BodyContainer">
                    {this.renderOverallSummary()}

                    {
                        Device !== 'laptop' &&
                        <>
                            <Spacer Size="small" />

                            <ButtonGroup
                                Buttons={[
                                    { BackgroundColor: "white-concrete", BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', IconOnly: true, IconSVG: FilterSVG, IconSVGColor: 'black', IconPosition: 'left', OnClick: () => this.onToggleShowFiltersModal(true), Title: t('_select_filters') },
                                    { BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', FontFamily: 'semibold', IconOnly: true, Icon: Plus, IconPosition: 'left', OnClick: this.onToggleShowAddRegionsAndBranches, TextAlign: 'left', Title: t('_add_regions_and_branches') },
                                    { BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', FontFamily: 'semibold', IconOnly: true, Icon: DownloadIcon, IconPosition: 'left', OnClick: this.onExportReport, TextAlign: 'left', Title: t('_export_salary_report') }
                                ]}
                                NotTouching
                            />
                        </>
                    }

                    <Spacer Size="small" />

                    {this.renderOrgIncome()}
                </styles.BodyContainer>
            </>
        );
    }

    renderDeleteBranchModal = () => {
        if (this.state.ShowDeleteBranchModal) {
            var { ShowDeleteBranchModal_BranchId, ShowDeleteBranchModal_BranchIndex, ShowDeleteBranchModal_RegionIndex } = this.state;

            var BranchData = {
                BranchId: ShowDeleteBranchModal_BranchId,
                BranchIndex: ShowDeleteBranchModal_BranchIndex,
                RegionIndex: ShowDeleteBranchModal_RegionIndex
            }

            return (
                <DeleteBranch
                    BranchData={BranchData}
                    OnHide={() => this.onToggleShowDeleteBranchModal(false)}
                    Refresh={this.onDeletedBranch}
                    Show={this.state.ShowDeleteBranchModal}
                    ShowDeleteLastBranchWarning={this.onShowDeleteLastBranchWarning}
                />
            );
        }
    }

    renderDeleteRegionModal = () => {
        var { ShowDeleteRegionModal } = this.state;

        if (ShowDeleteRegionModal) {
            var { t } = this.props;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteRegion}
                    BottomButtonText={t('_yes_delete')}
                    Information={t('_confirm_delete_region')}
                    OnHide={() => this.onToggleShowDeleteRegionModal(false)}
                    Show={ShowDeleteRegionModal}
                    Size="small"
                />
            );
        }
    }

    renderFiltersModal_Info = () => {
        var { TryingGetOrganizationIncome, UserDetails } = this.props;
        var { DataTypes, TimeRange, ValueType } = this.state;
        
        return (
            <Filters
                OnAddRegionsAndBranches={this.onToggleShowAddRegionsAndBranches}
                CurrencyName={UserDetails.CurrencyName}
                DataTypes={DataTypes}
                Loading={TryingGetOrganizationIncome}
                IsModal
                OnExportReport={this.onExportReport}
                TimeRange={TimeRange}
                ValueType={ValueType}
                OnSelectCustomDates={this.onSelectCustomDates}
                OnSelectDataType={this.onSelectDataType}
                OnSelectTimeRange={this.onSelectTimeRange}
                OnSelectValueType={this.onSelectValueType}
            />
        );
    }

    renderFiltersModal = () => {
        var { ShowFiltersModals } = this.state;

        if (ShowFiltersModals) {
            return (
                <Info
                    BottomButton={null}
                    InformationRenderer={this.renderFiltersModal_Info}
                    OnHide={() => this.onToggleShowFiltersModal(false)}
                    Show={ShowFiltersModals}
                />
            );
        }
    }

    renderOrgIncome = () => {
        var { t } = this.props;
        var { OrganizationIncome } = this.props;

        if (!OrganizationIncome.Regions) return null;

        var { DataTypes, Locale_Language, ValueType } = this.state;

        return (
            <styles.OrganizationIncomeContainer className="OrganizationIncomeContainer">
                {
                    OrganizationIncome.Regions.map(Region => {
                        return <OrganizationRegion key={Region.RegionId} DataTypes={DataTypes} Locale_Language={Locale_Language} Region={Region} ValueType={ValueType} t={t} />;
                    })
                }
            </styles.OrganizationIncomeContainer>
        );
    }

    renderOverallSummary = () => {
        var { t } = this.props;
        var { OrganizationIncome, UserDetails } = this.props;

        var { Locale_Language } = this.state;
        if (OrganizationIncome.Totals) var { TotalSales } = OrganizationIncome.Totals;

        return (
            <styles.OverallSummaryHeaderContainer className="OverallSummaryHeaderContainer">
                <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_income')} />

                <styles.OverallSummaryHeaderContainerRightContainer className="OverallSummaryHeaderContainerRightContainer">
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-end" NoMargin TextAlign="right" Text={`${t('_income_this_period')}:`} />
                    
                    {(OrganizationIncome.Totals) && <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-end" NoMargin TextAlign="right" Text={`${new Intl.NumberFormat(Locale_Language, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(TotalSales)} ${UserDetails.CurrencyName}`} />}
                </styles.OverallSummaryHeaderContainerRightContainer>
            </styles.OverallSummaryHeaderContainer>
        );
    }

    render() {
        var { TryingDeleteOrganizationRegion, TryingDownloadOrganizationIncomeReport, TryingGetOrganizationIncome, TryingGetOrganizationRegionsAndBranches, TryingInsertEditOrganizationBranch, TryingInsertEditOrganizationRegion, TryingMoveOrganizationBranchToNewRegion } = this.props;

        return (
            <>
                {(TryingDeleteOrganizationRegion || TryingDownloadOrganizationIncomeReport || TryingGetOrganizationIncome || TryingGetOrganizationRegionsAndBranches || TryingInsertEditOrganizationBranch || TryingInsertEditOrganizationRegion || TryingMoveOrganizationBranchToNewRegion) && <Loading />}

                {this.renderBody()}

                {this.renderDeleteBranchModal()}
                {this.renderDeleteRegionModal()}
                {this.renderFiltersModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        OrganizationIncome: state.Income.OrganizationIncome,
        OrganizationRegionsAndBranches: state.Income.OrganizationRegionsAndBranches,

        TryingDeleteOrganizationRegion: state.Income.TryingDeleteOrganizationRegion,
        TryingDeleteOrganizationRegionError: state.Income.TryingDeleteOrganizationRegionError,
        TryingDownloadOrganizationIncomeReport: state.Income.TryingDownloadOrganizationIncomeReport,
        TryingDownloadOrganizationIncomeReportError: state.Income.TryingDownloadOrganizationIncomeReportError,
        TryingGetOrganizationIncome: state.Income.TryingGetOrganizationIncome,
        TryingGetOrganizationIncomeError: state.Income.TryingGetOrganizationIncomeError,
        TryingGetOrganizationRegionsAndBranches: state.Income.TryingGetOrganizationRegionsAndBranches,
        TryingGetOrganizationRegionsAndBranchesError: state.Income.TryingGetOrganizationRegionsAndBranchesError,
        TryingInsertEditOrganizationBranch: state.Income.TryingInsertEditOrganizationBranch,
        TryingInsertEditOrganizationBranchError: state.Income.TryingInsertEditOrganizationBranchError,
        TryingInsertEditOrganizationRegion: state.Income.TryingInsertEditOrganizationRegion,
        TryingInsertEditOrganizationRegionError: state.Income.TryingInsertEditOrganizationRegionError,
        TryingMoveOrganizationBranchToNewRegion: state.Income.TryingMoveOrganizationBranchToNewRegion,
        TryingMoveOrganizationBranchToNewRegionError: state.Income.TryingMoveOrganizationBranchToNewRegionError
    };
};

export default withTranslation()(connect(mapStateToProps, { DeleteOrganizationRegion, DownloadOrganizationIncomeReport, GetOrganizationIncomeReport, GetOrganizationRegionsAndBranches, InsertEditOrganizationBranch2, InsertEditOrganizationRegion, MoveOrganizationBranchToNewRegion })(OrganizationIncomeComponent));