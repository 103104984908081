import React from 'react';
import PropTypes from 'prop-types';

import { Label, ToggleContainer, StyledToggle } from './styles';

class Toggle extends React.Component {
    render() {
        var { Checked, className, OffLabel, OnChange, OneLabel = null, OnLabel } = this.props;

        return (
            <ToggleContainer>
                {((OffLabel && OneLabel !== 'after') || OneLabel === 'before') && <Label Margin="right" Name="Off" Selected={!Checked} TextAlign="right">{OneLabel === 'before' ? (Checked ? OnLabel : OffLabel) : OffLabel}</Label>}

                <StyledToggle
                    checked={Checked}
                    className={className}
                    icons={false}
                    onChange={OnChange}
                />

                {((OnLabel && OneLabel !== 'before') || OneLabel === 'after') && <Label Margin="left" Name="On" Selected={Checked} TextAlign="left">{OneLabel === 'after' ? (Checked ? OnLabel : OffLabel) : OnLabel}</Label>}
            </ToggleContainer>
        );
    }
}

Toggle.propTypes = {
    Checked: PropTypes.bool.isRequired,
    OffLabel: PropTypes.string,
    OnChange: PropTypes.func.isRequired,
    OneLabel: PropTypes.string,
    OnLabel: PropTypes.string,
}

export default Toggle;