import Icon from './Icon';
import Achievement1 from './Icons/achievement-1.png';
import Achievement from './Icons/achievement.png';
import Avocado from './Icons/avocado.png';
import BadBreath from './Icons/bad-breath.png';
import Bicycle from './Icons/bicycle.png';
import BikeHelmet from './Icons/bike-helmet.png';
import BlowingAir from './Icons/blowingair.png';
import Blueberry from './Icons/blueberry.png';
import Bomb from './Icons/bomb.png';
import Brain from './Icons/brain.png';
import Bread from './Icons/bread.png';
import Broccoli from './Icons/broccoli.png';
import Burger from './Icons/burger.png';
import Cake from './Icons/cake.png';
import Candy1 from './Icons/candy-1.png';
import Chat1 from './Icons/chat-1.png';
import Chat2 from './Icons/chat-2.png';
import Chat from './Icons/chat.png';
import Checkmark from './Icons/checkmark.png';
import Circle1 from './Icons/circle-1.png';
import Circle2 from './Icons/circle-2.png';
import Circle3 from './Icons/circle-3.png';
import Circle4 from './Icons/circle-4.png';
import Circle5 from './Icons/circle-5.png';
import Circle6 from './Icons/circle-6.png';
import Circle7 from './Icons/circle-7.png';
import Clock from './Icons/clock.png';
import Dialog from './Icons/dialog.png';
import Diary from './Icons/diary.png';
import Diary1 from './Icons/diary-1.png';
import Dish from './Icons/dish.png';
import Dissasociative from './Icons/dissociative-identity-disorder.png';
import Dog from './Icons/dog.png';
import Donut from './Icons/donut.png';
import Feedback from './Icons/feedback.png';
import Fist from './Icons/fist.png';
import Flat from './Icons/flat.png';
import Flower from './Icons/flower.png';
import FullBattery from './Icons/full-battery.png';
import Goal from './Icons/goal.png';
import Happy1 from './Icons/happy-1.png';
import Happy from './Icons/happy.png';
import Healthy from './Icons/healthy.png';
import Heart1 from './Icons/heart-1.png';
import Heart from './Icons/heart.png';
import Hunger from './Icons/hunger.png';
import Journal from './Icons/journal.png';
import Leadership from './Icons/leadership.png';
import Lightning from './Icons/lightning.png';
import Lotus from './Icons/lotus.png';
import LowBattery from './Icons/low-battery-level.png';
import Lung from './Icons/lung.png';
import Mascot1 from './Icons/mascot-1.png';
import Mascot2 from './Icons/mascot-2.png';
import Mascot3 from './Icons/mascot-3.png';
import Medal from './Icons/medal.png';
import Menstruation from './Icons/menstruation.png';
import Motivation from './Icons/motivation.png';
import Mountains from './Icons/mountains.png';
import Muscle1 from './Icons/muscle-1.png';
import Muscle from './Icons/muscle.png';
import Muscles from './Icons/muscles.png';
import Note from './Icons/note.png';
import Om from './Icons/om.png';
import OpenBook from './Icons/open-book.png';
import Pain1 from './Icons/pain-1.png';
import PeaceOfMind from './Icons/peace-of-mind.png';
import Pencil from './Icons/pencil.png';
import Pill from './Icons/pill.png';
import Pills from './Icons/pills.png';
import Portfolio from './Icons/portfolio.png';
import Praying from './Icons/praying.png';
import ProjectManagement from './Icons/project-management.png';
import Rating from './Icons/rating.png';
import Run from './Icons/run.png';
import Running from './Icons/running.png';
import Sad from './Icons/sad.png';
import Shoe1 from './Icons/shoe-1.png';
import Shoe2 from './Icons/shoe-2.png';
import Shower from './Icons/shower.png';
import Sleeping2 from './Icons/sleeping-2.png';
import SleepingMask from './Icons/sleeping-mask.png';
import Sleeping from './Icons/sleeping.png';
import SmartphoneCharger from './Icons/smartphone-charger.png';
import Star2 from './Icons/star-2.png';
import StarTarget from './Icons/star-target.png';
import Star from './Icons/star.png';
import Store from './Icons/store.png';
import Strawberry from './Icons/strawberry.png';
import Stress from './Icons/stress.png';
import Success from './Icons/success.png';
import Suitcase from './Icons/suitcase.png';
import Sun from './Icons/sun.png';
import TeethBrushing1 from './Icons/teeth-brushing-1.png';
import Tired from './Icons/tired.png';
import Tomato from './Icons/tomato.png';
import Trophy1 from './Icons/trophy-1.png';
import Vote from './Icons/vote.png';
import Walker from './Icons/walker.png';
import Walking from './Icons/walking.png';
import Water1 from './Icons/water-1.png';
import Wheel from './Icons/wheel.png';
import Yoga from './Icons/yoga.png';
import Zzz from './Icons/zzz.png';

const IconsListByName = [ 'Achievement1', 'Achievement', 'Avocado', 'BadBreath', 'Bicycle', 'BikeHelmet', 'BlowingAir', 'Blueberry', 'Bomb', 'Brain', 'Bread', 'Broccoli', 'Burger', 'Cake', 'Candy1', 'Chat1', 'Chat2', 'Chat', 'Checkmark', 'Circle1', 'Circle2', 'Circle3', 'Circle4', 'Circle5', 'Circle6', 'Circle7', 'Clock', 'Dialog', 'Diary', 'Diary1', 'Dish', 'Dissasociative', 'Dog', 'Donut', 'Feedback', 'Fist', 'Flat', 'Flower', 'FullBattery', 'Goal', 'Happy1', 'Happy', 'Healthy', 'Heart1', 'Heart', 'Hunger', 'Journal', 'Leadership', 'Lightning', 'Lotus', 'LowBattery', 'Lung', 'Mascot1', 'Mascot2', 'Mascot3', 'Medal', 'Menstruation', 'Motivation', 'Mountains', 'Muscle1', 'Muscle', 'Muscles', 'Note', 'Om', 'OpenBook', 'Pain1', 'PeaceOfMind', 'Pencil', 'Pill', 'Pills', 'Portfolio', 'Praying', 'ProjectManagement', 'Rating', 'Run', 'Running', 'Sad', 'Shoe1', 'Shoe2', 'Shower', 'Sleeping2', 'SleepingMask', 'Sleeping', 'SmartphoneCharger', 'Star2', 'StarTarget', 'Star', 'Store', 'Strawberry', 'Stress', 'Success', 'Suitcase', 'Sun', 'TeethBrushing1', 'Tired', 'Tomato', 'Trophy1', 'Vote', 'Walker', 'Walking', 'Water1', 'Wheel', 'Yoga', 'Zzz' ];

export { Icon, IconsListByName, Achievement1, Achievement, Avocado, BadBreath, Bicycle, BikeHelmet, BlowingAir, Blueberry, Bomb, Brain, Bread, Broccoli, Burger, Cake, Candy1, Chat1, Chat2, Chat, Checkmark, Circle1, Circle2, Circle3, Circle4, Circle5, Circle6, Circle7, Clock, Dialog, Diary, Diary1, Dish, Dissasociative, Dog, Donut, Feedback, Fist, Flat, Flower, FullBattery, Goal, Happy1, Happy, Healthy, Heart1, Heart, Hunger, Journal, Leadership, Lightning, Lotus, LowBattery, Lung, Mascot1, Mascot2, Mascot3, Medal, Menstruation, Motivation, Mountains, Muscle1, Muscle, Muscles, Note, Om, OpenBook, Pain1, PeaceOfMind, Pencil, Pill, Pills, Portfolio, Praying, ProjectManagement, Rating, Run, Running, Sad, Shoe1, Shoe2, Shower, Sleeping2, SleepingMask, Sleeping, SmartphoneCharger, Star2, StarTarget, Star, Store, Strawberry, Stress, Success, Suitcase, Sun, TeethBrushing1, Tired, Tomato, Trophy1, Vote, Walker, Walking, Water1, Wheel, Yoga, Zzz };