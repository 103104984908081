import styled from 'styled-components';

import Icon from '../../Components/Icon';
import Image from '../../Components/Image';
import PageText from '../../Components/Text/PageText';

export const Container = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;

    ${props => props.CannotShare && 'opacity: 0.7;'}
`;

export const LeftContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
`;

export const RightContainer = styled.div`
    ${props => !props.CannotShare && 'border-left: 2px solid var(--white);'}
    
    padding-left: 10px;
`;

export const ShareAbilityStatusContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const StyledContentName = styled(PageText)`
    flex: 1;
    margin-right: 10px;
`;

export const StyledEditIcon = styled(Icon)`
    height: 13px;
    margin-left: 4px;
    width: 13px;
`;

export const StyledProfilePhoto = styled(Image)`
    height: 25px;
    margin-right: 7px;
    width: 25px;

    ${props => !props.IsAbel && 'border-radius: 50%;'}
    overflow: hidden;
`;

export const TemplateDaysContainer = styled.div`
    align-items: center;
    background-color: var(--blue-astronaut);
    border-radius: 16px;
    color: var(--white);
    display: flex;
    justify-content: center;
    padding: 5px 12px;

    height: 20px;
    margin-right: 7px;
    width: 20px;
`;

export const WorkoutTypeIcon = styled(Icon)`
    height: 20px;
    margin-right: 7px;
    width: 20px;
`;