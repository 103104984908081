import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearMealTemplateDetail, DeleteMealTemplates, GetMealTemplateDetail, SaveDataInProps, UpdatePausedSharing_MealTemplates, UpdateShareAbilityStatus_MealTemplates, UpdateSharedToCoachStatus_MealTemplates } from '../../Services/Actions';
import history from '../../history';

import { DayContainer, DayNumber, DaysContainer, DishContainer, DishOptionsContainer } from './styles';

import AbelModal from '../../Components/Modal';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import ContentSharingDetails from '../../Components/ContentSharingDetails';
import DishImage from './DishImage';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import EditShareAbilityStatusModal from '../EditShareAbilityStatusModal';
import HowSharingWorksModal from '../HowSharingWorksModal';
import Info from '../Info';
import ShareContentModal from '../ShareContentModal';

import Copy from '../../Assets/Icons/Copy_White.png';
import DeleteX from '../../Assets/Icons/X_White.png';
import Edit from '../../Assets/Icons/Pencil_White_Filled.png';

import { getNewDays } from '../../Functions';

class MealTemplate extends React.Component {
    state = {
        DayIds: [],
        Days: [],
        Loading: true,
        ShowDeleteMealTemplateModal: false,
        ShowEditShareAbilityStatusModal: false,
        ShowEditShareAbilityStatusModalDetails: {},
        ShowHowSharingWorksModal: false,
        ShowShareContentModal: false,
        Updating: false
    };

    componentDidMount() {
        this._isMounted = true;

        this.props.GetMealTemplateDetail({ TemplateId: this.props.TemplateDetail_Preview.TemplateId }).then(() => {
            if (!this.props.TryingGetMealTemplateDetailError) {
                var { DayIds, Days } = this.state;
                var { TemplateId, TemplateDetails: { Dishes, TemplateDays, TemplateName } } = this.props.MealTemplate;

                var { NewDays, NewDayIds } = getNewDays(DayIds, Days, TemplateDays);

                for (var i = 0; i < Dishes.length; i++) {
                    var { DailyPercent, DayNumber, DishId, DishImage, DishName } = Dishes[i];
                    var NewDishId = `DishId-${DishId}`;

                    var Obj = { DailyPercent, DailyPercentError: null, DishId: NewDishId, DishName, DishUrl: DishImage };

                    NewDays[DayNumber - 1].DishIds.push(NewDishId);
                    NewDays[DayNumber - 1].Dishes.push(Obj);
                }

                if (this._isMounted) this.setState({ DayIds: NewDayIds, Days: NewDays, Loading: false, TemplateDays, TemplateId, TemplateName });
            } else this.setState({ Loading: false });
        });
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        this.props.ClearMealTemplateDetail();
    }

    onClickAssignClients = () => {
        var { OnAssignClients, MealTemplate: { TemplateId, TemplateDetails: { TemplateDays, TemplateName } } } = this.props;

        this.props.OnHide();

        if (OnAssignClients) OnAssignClients();
        else this.props.SaveDataInProps({ SelectedTemplate: { TemplateId, TemplateDays, TemplateName } }).then(() => history.push('/add-meal-template'));
    }

    onClickEditTemplate = () => {
        this.props.OnHide();
        history.push(`/library/meal-template/${this.props.TemplateDetail_Preview.TemplateId}`);
    }

    onCopyTemplateToTemplate = () => {
        var TemplateId = this.props.TemplateDetail_Preview.TemplateId
        var { TemplateDetails } = this.props.MealTemplate;

        this.props.SaveDataInProps({ TemplateToTemplateDetails: { FromTemplate: true, CopiedTemplateId: TemplateId, TemplateDetails } }).then(() => history.push('/library/meal-template'));
    }

    onDeleteMealTemplate = () => {
        var { TemplateId } = this.props.TemplateDetail_Preview;

        this.props.DeleteMealTemplates({ TemplateId }).then(() => {
            if (!this.props.TryingDeleteMealTemplatesError) {
                this.onToggleDeleteMealTemplateModal(false);
                this.props.OnHide(TemplateId);
            }
        });
    }

    onPauseSharing = IsPaused => {
        this.props.UpdatePausedSharing_MealTemplates({ IsPaused, TemplateId: this.props.TemplateDetail_Preview.TemplateId })
    }

    onShareAgain = Email => {
        this.props.UpdateSharedToCoachStatus_MealTemplates({ Emails: [ Email ], NewStatus: 'Pending', TemplateId: this.props.TemplateDetail_Preview.TemplateId });
    }

    onShareContentToEmails = ({ Emails, ContentIds }) => {
        this.props.UpdateSharedToCoachStatus_MealTemplates({ Emails, NewStatus: 'Pending', TemplateId: this.props.TemplateDetail_Preview.TemplateId, TemplateIds: ContentIds }).then(() => {
            this.onToggleShowShareContentModal(false);
        });
    }

    onToggleDeleteMealTemplateModal = ShowDeleteMealTemplateModal => {
        this.setState({ ShowDeleteMealTemplateModal });
    }

    onToggleShowEditShareAbilityStatusModal = ({ ContentId = null, ContentIndex = null, ShowEditShareAbilityStatusModal, CurrentStatus = null }) => {
        this.setState({ ShowEditShareAbilityStatusModal, ShowEditShareAbilityStatusModalDetails: { ContentId, ContentIndex, CurrentStatus } });
    }

    onToggleShowHowSharingWorksModal = ShowHowSharingWorksModal => {
        this.setState({ ShowHowSharingWorksModal });
    }

    onToggleShowShareContentModal = ShowShareContentModal => {
        this.setState({ ShowShareContentModal });
    }

    onUnshareContent = Email => {
        this.props.UpdateSharedToCoachStatus_MealTemplates({ Emails: [ Email ], NewStatus: 'Unshare', TemplateId: this.props.TemplateDetail_Preview.TemplateId });
    }

    onUpdateShareAbilityStatus = ({ ContentId, NewStatus, NewStatusName }) => {
        if (+NewStatus !== +this.props.MealTemplate.TemplateDetails.SharingDetails.ShareAbilityStatusId) return this.props.UpdateShareAbilityStatus_MealTemplates({ NewStatus, NewStatusName, TemplateId: ContentId }).then(() => this.props.OnUpdateMultiSelectList({ ContentId, NewStatus, NewStatusName }))

        return;
    }

    onUpdateSharedToCoachStatus = ({ NewStatus, PersonalResponse }) => {
        this.setState({ Updating: true });
        this.props.UpdateSharedToCoachStatus_MealTemplates({ Emails: [ this.props.UserDetails.Email ], NewStatus, PersonalResponse, TemplateId: this.props.TemplateDetail_Preview.TemplateId }).then(() => {
            this.setState({ Updating: false }, () => {
                if (NewStatus === 'reject' || NewStatus === 'delete') this.props.OnHide();

                this.props.OnLoadTemplates();
            });
        });
    }

    renderButtons = () => {
        if (this.props.TryingGetMealTemplateDetail) return null;

        var { t } = this.props;
        var { Device, MealTemplate } = this.props;

        var Buttons = [];
        if (!!MealTemplate && !!MealTemplate.TemplateDetails && !!+MealTemplate.TemplateDetails.IsOwner) {
            Buttons.push({ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: Edit, IconPosition: 'left', OnClick: this.onClickEditTemplate, Title: t('edit_with_first_char_uppercase') });
            Buttons.push({ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: DeleteX, IconPosition: 'left', OnClick: () => this.onToggleDeleteMealTemplateModal(true), Title: t('delete_with_first_char_uppercase') });
        }
        if (MealTemplate.TemplateDetails.SharingDetails.SharedStatus === 1) Buttons.push({ BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: Copy, IconPosition: 'left', OnClick: this.onCopyTemplateToTemplate, Title: t('copy_template_to_template') });

        if (!Buttons.length) return null;

        return (
            <>
                <ButtonGroup
                    Buttons={Buttons}
                    ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />
            </>
        );
    }

    renderDeleteMealTemplateModal = () => {
        var { t } = this.props;
        var { ShowDeleteMealTemplateModal } = this.state;

        if (ShowDeleteMealTemplateModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteMealTemplate}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    Information={t('template_delete_warning')}
                    Loading={this.props.TryingDeleteMealTemplates}
                    OnHide={() => this.onToggleDeleteMealTemplateModal(false)}
                    Show={ShowDeleteMealTemplateModal}
                />
            );
        }
    }

    renderTemplateDays = () => {
        if (this.props.TryingGetMealTemplateDetail) return null;

        var { t } = this.props;
        var { Days } = this.state;

        return (
            <DaysContainer>
                {
                    Days.map(({ DayId, Dishes }, DayIndex) => {
                        return (
                            <DayContainer key={DayId}>
                                <DayNumber>{t('day')} {DayIndex + 1}</DayNumber>
                                {
                                    Dishes.map(({ DailyPercent, DishName, DishUrl }, DishIndex) => {
                                        return (
                                            <DishContainer key={DishIndex}>
                                                <DishImage DishUrl={DishUrl} />

                                                <Spacer Size="extra-small" />

                                                <DishOptionsContainer>
                                                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={DishName} TextAlign="center" />

                                                    <Spacer Size="extra-small" />

                                                    <PageText FontFamily="bold" FontSize="medium-1" NoMargin Text={`${DailyPercent}%`} TextAlign="center" />
                                                </DishOptionsContainer>
                                            </DishContainer>
                                        );
                                    })
                                }
                            </DayContainer>
                        );
                    })
                }
            </DaysContainer>
        );
    }

    renderShowEditShareAbilityStatusModal = () => {
        var { ShowEditShareAbilityStatusModal } = this.state;

        if (ShowEditShareAbilityStatusModal) {
            var { SharingDetails } = this.props.MealTemplate.TemplateDetails;

            return (
                <EditShareAbilityStatusModal
                    ContentId={this.props.TemplateDetail_Preview.TemplateId}
                    CurrentStatus={SharingDetails.ShareAbilityStatusId}
                    Loading={this.props.TryingUpdateShareAbilityStatus_MealTemplates}
                    OnHide={() => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: false })}
                    OnUpdateShareAbilityStatus={this.onUpdateShareAbilityStatus}
                    Show={ShowEditShareAbilityStatusModal}
                />
            );
        }
    }

    renderShowHowSharingWorksModal = () => {
        var { ShowHowSharingWorksModal } = this.state;

        if (ShowHowSharingWorksModal) {
            return (
                <HowSharingWorksModal
                    OnHide={() => this.onToggleShowHowSharingWorksModal(false)}
                    Show={ShowHowSharingWorksModal}
                />
            );
        }
    }

    renderShowShareContentModal = () => {
        var { ShowEditShareAbilityStatusModal, ShowHowSharingWorksModal, ShowShareContentModal } = this.state;

        if (ShowShareContentModal) {
            var { TryingUpdateSharedToCoachStatus_MealTemplates } = this.props;

            return (
                <ShareContentModal
                    ContentIdName="TemplateId"
                    ContentType="MealTemplates"
                    DisplayNone={!!ShowEditShareAbilityStatusModal || !!ShowHowSharingWorksModal}
                    OnHide={() => this.onToggleShowShareContentModal(false)}
                    OnSubmit={({ Emails, ContentIds }) => this.onShareContentToEmails({ Emails, ContentIds })}
                    OnToggleShowEditShareAbilityStatusModal={({ ContentIndex, ContentId, CurrentStatus }) => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: true, ContentId, ContentIndex, CurrentStatus })}
                    OnToggleShowHowSharingWorksModal={() => this.onToggleShowHowSharingWorksModal(true)}
                    Loading={TryingUpdateSharedToCoachStatus_MealTemplates}
                    SharingContent={[{ ...this.props.MealTemplate.TemplateDetails, ...this.props.MealTemplate.TemplateDetails.SharingDetails, ...this.props.MealTemplate.TemplateDetails.SharingDetails.OwnerDetails, TemplateId: this.props.TemplateDetail_Preview.TemplateId }]}
                    Show={ShowShareContentModal}
                />
            );
        }
    }

    renderModalBody = () => {
        if (this.state.Loading || this.props.TryingGetMealTemplateDetail) return <Loading />;

        var { t } = this.props;
        var { Device, MealTemplate: { TemplateDetails: { SharingDetails } }, TemplateDetail_Preview: { TemplateDays, TemplateName }, TryingUpdatePausedSharing_MealTemplates, TryingUpdateShareAbilityStatus_MealTemplates, TryingUpdateSharedToCoachStatus_MealTemplates } = this.props;
        var { Updating } = this.state;

        var TemplateLengthText = <MultiText
            Texts={[
                { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('length')} -` },
                { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${TemplateDays} ${t('days').toLowerCase()}` }
            ]}
        />

        var Buttons = [];
        Buttons.push({ Title: TemplateLengthText });
        if (SharingDetails.SharedStatus === 1) Buttons.push({ Color: 'blue-astronaut', ColorHover: 'blue-astronaut', FontFamily: 'semibold', OnClick: this.onClickAssignClients, Title: t('assign_to_clients') });

        return (
            <>
                {Updating && <Loading />}

                <PageText FontFamily="medium" FontSize="large" NoMargin Text={TemplateName} TextAlign="center" />

                <Spacer Size="medium" />

                {this.renderButtons()}

                <Spacer Size="medium" />

                <ButtonGroup
                    Buttons={Buttons}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />

                <Spacer Size="medium" />

                {this.renderTemplateDays()}

                <Spacer Size="medium" />

                <ContentSharingDetails
                    Loading={TryingUpdatePausedSharing_MealTemplates || TryingUpdateShareAbilityStatus_MealTemplates || TryingUpdateSharedToCoachStatus_MealTemplates}
                    OnShareAgain={this.onShareAgain}
                    OnPauseSharing={this.onPauseSharing}
                    onToggleShowShareContentModal={() => this.onToggleShowShareContentModal(true)}
                    OnToggleShowEditShareAbilityStatusModal={() => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: true })}
                    OnToggleShowHowSharingWorksModal={() => this.onToggleShowHowSharingWorksModal(true)}
                    OnUnshare={this.onUnshareContent}
                    OnUpdateSharedToCoachStatus={this.onUpdateSharedToCoachStatus}
                    SharingDetails={SharingDetails}
                />
            </>
        );
    }

    render() {
        var { OnHide, Show } = this.props;
        var { ShowDeleteMealTemplateModal, ShowEditShareAbilityStatusModal, ShowHowSharingWorksModal, ShowShareContentModal } = this.state;

        return (
            <>
                <AbelModal
                    BottomButton="cancel"
                    BottomButtonOnClick={OnHide}
                    DisplayNone={(ShowDeleteMealTemplateModal || ShowEditShareAbilityStatusModal || ShowHowSharingWorksModal || ShowShareContentModal)}
                    GA_PathName="MealTemplate"
                    Show={Show}
                    Size="large"
                    OnHide={OnHide}
                    TopButton="cancel"
                    TopButtonOnClick={OnHide}
                >
                    {this.renderModalBody()}
                </AbelModal>

                {this.renderDeleteMealTemplateModal()}

                {this.renderShowEditShareAbilityStatusModal()}
                {this.renderShowHowSharingWorksModal()}
                {this.renderShowShareContentModal()}
            </>
        );
    }
}

MealTemplate.propTypes = {
    OnAssignClients: PropTypes.func,
    OnHide: PropTypes.func.isRequired,
    OnLoadTemplates: PropTypes.func.isRequired,
    OnUpdateMultiSelectList: PropTypes.func,
    OnUpdateSharedToCoachStatus: PropTypes.func,
    Show: PropTypes.bool.isRequired,
    TemplateDetail_Preview: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        MealTemplate: state.Templates.MealTemplate,

        TryingDeleteMealTemplates: state.Templates.TryingDeleteMealTemplates,
        TryingDeleteMealTemplatesError: state.Templates.TryingDeleteMealTemplatesError,
        TryingGetMealTemplateDetail: state.Templates.TryingGetMealTemplateDetail,
        TryingGetMealTemplateDetailError: state.Templates.TryingGetMealTemplateDetailError,
        TryingUpdatePausedSharing_MealTemplates: state.Templates.TryingUpdatePausedSharing_MealTemplates,
        TryingUpdatePausedSharing_MealTemplatesError: state.Templates.TryingUpdatePausedSharing_MealTemplatesError,
        TryingUpdateShareAbilityStatus_MealTemplates: state.Templates.TryingUpdateShareAbilityStatus_MealTemplates,
        TryingUpdateShareAbilityStatus_MealTemplatesError: state.Templates.TryingUpdateShareAbilityStatus_MealTemplatesError,
        TryingUpdateSharedToCoachStatus_MealTemplates: state.Templates.TryingUpdateSharedToCoachStatus_MealTemplates,
        TryingUpdateSharedToCoachStatus_MealTemplatesError: state.Templates.TryingUpdateSharedToCoachStatus_MealTemplatesError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearMealTemplateDetail, DeleteMealTemplates, GetMealTemplateDetail, SaveDataInProps, UpdatePausedSharing_MealTemplates, UpdateShareAbilityStatus_MealTemplates, UpdateSharedToCoachStatus_MealTemplates } )(MealTemplate));