import styled from 'styled-components';
import { device } from '../../Config/device'

import Image from '../../Components/Image';
import PageText from '../../Components/Text/PageText';

import Dishes from '../Library/Dishes';

export const DateContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    margin-top: 15px;
    padding: 10px;

    :not(:last-child) {
        margin-right: 15px;
    }
`;

export const DishImage = styled(Image)`
    border-radius: 10px;
    height: 90px;
    object-fit: cover;
    width: 140px;
`;

export const SelectedDateContainerInner = styled.div`
    align-items: center;
    display: flex;
`;

export const SelectedDatesContainer = styled.div`
    border-top: 2px solid var(--white-concrete);
    flex: 1;
    margin-top: -1px;
    padding: 15px 15px 0;

    @media ${device.minwidth.ipad} {
        padding: 25px 25px 0;
    }

    @media ${device.minwidth.laptop} {
        border-left: 2px solid var(--white-concrete);
        border-top: none;
        margin-left: -1px;
        margin-top: 0;
        padding: 0 25px;
    }
`;

export const SelectedDatesDetailContainer = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-right: 5px;
    margin-top: -15px;
`;

export const SelectedDishContainer = styled.div`
    border-bottom: 2px solid var(--white-concrete);
    flex: 1;
    margin-bottom: -1px;
    padding: 0 15px 15px;

    @media ${device.minwidth.ipad} {
        padding: 0 25px 25px;
    }

    @media ${device.minwidth.laptop} {
        border-bottom: none;
        border-right: 2px solid var(--white-concrete);
        margin-bottom: 0px;
        margin-right: -1px;
        padding: 0 25px;
    }
`;

export const SelectedDishDetailContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;

    @media ${device.minwidth.mobile} {
        flex-direction: row;
    }
`;

export const SelectedPreviewContainer = styled.div`
    align-items: center;
    border: 5px solid var(--blue-abel);
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 15px;

    @media ${device.minwidth.ipad} {
        padding: 25px;
    }

    @media ${device.minwidth.laptop} {
        align-items: flex-start;
        flex-direction: row;
        padding-left: 0px;
        padding-right: 0px;
    }
`;

export const StyledDishes = styled(Dishes)`
    @media (max-width: 1350px) {
        > .dish-filters .filters-container .DropdownFilters {
            display: none;
        }

        > .dish-filters .filters-container .StyledToggleMobileFilters {
            display: block;
        }
    }

    @media (min-width: 1351px) {
        > .dish-filters .filters-container .DropdownFilters {
            display: flex;
        }

        > .dish-filters .filters-container .StyledToggleMobileFilters {
            display: none;
        }
    }
`;

export const StyledDishName = styled(PageText)`
    margin-left: 15px;
    text-transform: uppercase;
`;