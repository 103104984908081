import styled from 'styled-components';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Input from '../../Components/Form/Input';

import Checkmark from '../../Assets/Icons/Checkmark_Selected.png';

export const BackNextButtons = styled(ButtonGroup)`
    > div {
        :first-child {
            border-top-left-radius: 0px
        }

        :last-child {
            border-top-right-radius: 0px;
        }
    }
`;

export const InputContainer = styled.div``;

export const MultiLineButtonTitle = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const QuestionContainer = styled.div``;

export const StyledInput = styled(Input)`
    margin: 0 auto;
    max-width: 300px;

    > input {
        text-align: center;
        text-indent: 0px;
    }
`;

export const StyledMeasurementTypeButtons = styled(ButtonGroup)`
    margin: 0 auto;
    max-width: 300px;
`;

export const Toggle = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            // background-repeat: no-repeat;
            background-size: 25px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    display: block;
    height: 25px;
    margin-right: 5px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            // background-repeat: no-repeat;
            background-size: 25px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const ToggleContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 10px;
`;