import styled from 'styled-components';

import Input from '..//Form/Input';

export const AddRemoveTagsPopoverContentContainer = styled.div`
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 1px 1px 8px #5a5a5a29;
    max-height: 450px;
    max-width: 100%;
    overflow-y: scroll;
    padding: 15px 20px;
    width: 335px;
`;

export const AddRemoveTagsPopoverTagContainer = styled.div`
    align-items: center;
    background-color: ${props => !!props.Selected ? props.ActionType === 'Adding' ? 'var(--blue-abel)' : 'var(--red-bittersweet)' : 'var(--white-concrete)'};
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 7px 10px;

    ${
        props => !!props.Selected &&
        `
            > div {
                color: var(--white);
            }
        `
    }

    > svg {
        fill: ${props => !!props.Selected ? 'var(--white)' : 'var(--black)'};
        height: 12px;
        margin-left: 4px;
    }

    @media(hover:hover) {
        :hover {
            background-color: ${props => props.ActionType === 'Adding' ? 'var(--blue-abel)' : 'var(--red-bittersweet)'};
            cursor: pointer;

            > div {
                color: var(--white);
            }

            > svg {
                fill: var(--white);
            }
        }
    }
`;

export const AddRemoveTagsPopoverTagsListContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 8px;
    max-height: 250px;
    overflow: scroll;
`;

export const StyledSearchInput = styled(Input)`
    flex: 1;

    > input {
        background-color: var(--white-concrete);
        border: none;
        border-radius: 10px;
    }
`;