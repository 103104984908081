import React from 'react';
import { withTranslation } from 'react-i18next';

import { BETA_SITE_URL } from '../../Config/index';

import { MenuItemContainer, StyledLink } from './styles_leftnavigation';

class MenuLink extends React.Component {
    render() {
        var { BetaAdmin, Icon, LinkUrl, Selected } = this.props;

        if (BetaAdmin) {
            var link = BETA_SITE_URL + `/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=${BetaAdmin}`;

            return (
                <a
                    href={link}
                    target='_self'
                    rel="noopener noreferrer"
                >
                    <MenuItemContainer className="MenuItemContainer" Selected={Selected}>
                        <Icon />
                    </MenuItemContainer>
                </a>
            )
        }

        return (
            <StyledLink to={LinkUrl}>
                <MenuItemContainer className="MenuItemContainer" Selected={Selected}>
                    <Icon />
                </MenuItemContainer>
            </StyledLink>
        );
    }
}

export default withTranslation()(MenuLink);