import styled from 'styled-components';
import { device } from '../../Config/device';

export const MenuItem = styled.a`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    color: var(--black);
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    justify-content: center;
    margin: 15px;
    padding: 10px;

    @media ${device.minwidth.ipad} {
        flex: 0 0 calc(50% - 30px);
    }

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete-hover);
            color: var(--link-hover);
            cursor: pointer;
        }
    }

    :active {
        color: var(--blue-abel);
    }
`;

export const MenuItemIllustration = styled.img`
    height: 120px;
`;

export const MenuOptionsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;