import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import PageText from '../Text/PageText';

const ViewCountContainer = styled.div`
    text-align: ${props => props.TextAlign};
`;

class ViewCountComponent extends React.Component {
    render() {
        var { t } = this.props;
        var { PageNo, PageSize, TextAlign = "center", TotalRecords, ViewingObjectType } = this.props;

        return (
            <ViewCountContainer className="ViewCountContainer" TextAlign={TextAlign}>
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${+TotalRecords === 0 ? '0' : '1'} - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                &nbsp;
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords}`} />
                &nbsp;
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={ViewingObjectType} />
            </ViewCountContainer>
        );
    }
}

export default withTranslation()(ViewCountComponent);