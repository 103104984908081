import styled from 'styled-components';

export const ImageContainer = styled.div`
    position: relative;
    
    ${
        props => props.RoundImage &&
        `
            border-radius: 50%;
            overflow: hidden;
        `
    }

    ${
        props => props.ContainerWidth &&
        `
            width: ${props.ContainerWidth};
        `
    }

    ${
        props => props.Margin &&
        `
            margin: ${props.Margin};
        `
    }
`;

export const Overlay = styled.div`
    background-color: ${props => props.OverlayColor ? props.OverlayColor : 'rgba(255,255,255,0.3)'};
    border-radius: ${props => props.RoundImage ? '50%' : '10px'};
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .5s ease;
    width: 100%;

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const StyledImage = styled.img`
    display: block;

    ${props => props.onClick ? '@media(hover:hover) { :hover { cursor: pointer; } }' : null}
`;