import React from 'react';
import { withTranslation } from 'react-i18next';

import NotificationsList from '../../Components/Notifications';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import { BETA_SITE_URL } from '../../Config';

class Notifications extends React.Component {
    componentDidMount() {
        this._isMounted = true;

        var RedirectLink = `${BETA_SITE_URL}/notifications`;

        window.location = RedirectLink;
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        var { t } = this.props;

        return (
            <>
                <Page NoMargin>
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={t('ptadmin_navigation_notifications')} />

                    <Spacer Size="medium" />

                    <NotificationsList />
                </Page>
            </>
        );
    }
}

export default withTranslation()(Notifications);