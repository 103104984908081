import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import ButtonGroup from '../../Buttons/ButtonGroup';
import Image from '../../Image';
import MultiText from '../../Text/MultiText';
import PageText from '../../Text/PageText';
import Spacer from '../../Spacer';

import AbelIcon from '../../../Assets/Icons/Abel_HeaderIcon.png';
import Checkmark from '../../../Assets/Icons/Checkmark_Selected.png';
import Female from '../../../Assets/Icons/Female.png';
import Male from '../../../Assets/Icons/Male.png';

const DescriptionContainer = styled.div`
    margin-left: -25px;
    margin-right: -25px;
`;

const FullWidthDiv = styled.div`
    background-color: var(--white);
    border-radius: 10px;    
    height: 5px;
`;

const MultiSelect = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 20px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 10px;
    height: 20px;
    left: 15px;
    position: absolute;
    top: 15px;
    width: 20px;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 20px;
            border: none;
            cursor: pointer;
        }
    }
`;

const StyledProfilePhoto = styled(Image)`
    height: 25px;
    left: 15px;
    position: absolute;
    top: 15px;
    width: 25px;

    ${props => !props.IsAbel && 'border-radius: 50%;'}
    overflow: hidden;
`;

const TemplateContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    ${
        props => props.Selected && 1 === 2 ?
        `
            border: 5px solid var(--blue-abel);
            border-radius: 10px;
            padding: 10px;
        `
    :
        null
    }
`;

const TemplateDetailContainer = styled.div`
    background-color: var(--white-concrete-light);
    border: 5px solid var(--white-concrete-light);
    border-radius: 20px;
    padding: 15px 38px;
    width: 100%;

    @media(hover:hover) {
        :hover {
            // background-color: var(--white-concrete-hover);
            cursor: pointer;
        }
    }

    ${
        props => props.Selected ?
        `
            background-color: rgba(26, 151, 255, 0.35);
            border: 5px solid var(--blue-abel);
            border-radius: 20px;

            @media(hover:hover) {
                :hover {
                    background-color: rgba(26, 151, 255, 0.35);
                }
            }
        `
    :
        null
    }
`;

// ^^ padding: ${props => props.IsMultiSelect ? '15px 38px' : '15px'};

const TemplateDaysContainer = styled.div`
    align-items: center;
    background-color: var(--blue-astronaut);
    border-radius: 16px;
    color: var(--white);
    display: flex;
    justify-content: center;
    padding: 5px 12px;
`;

const TemplateDaysOuterContainer = styled.div`
    right: 15px;
    position: absolute;
    top: 15px;
`;

const TemplateNameContainer = styled.div``;

const StyledTemplateName = styled(PageText)`
    text-transform: uppercase;
`;

class TrainingProgramListItem extends React.Component {
    state = { IsReadMore: false };

    onMultiSelect = (event, Item, MultiSelectIndex) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        this.props.OnMultiSelect(Item, MultiSelectIndex);
    }

    onToggleReadMore = event => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        this.setState({ IsReadMore: !this.state.IsReadMore });
    }

    onUpdateSharedToCoachStatus = ({ event, ContentId, NewStatus }) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        this.props.OnUpdateSharedToCoachStatus({ ContentId, NewStatus, PersonalResponse: 1 });
    }

    renderDescription = () => {
        var { t } = this.props;
        var { ProgramDescription } = this.props.Item;
        var { IsReadMore } = this.state;

        var Texts = [{ FontFamily: 'medium', FontSize: 'small', Text: ProgramDescription }];

        // Only care about read more text if length > 100
            if (ProgramDescription.length > 100) {
                if (!IsReadMore) Texts[0].Text = `${ProgramDescription.substring(0, 100)}...`;

                Texts.push({ FontColor: 'blue-abel', FontColorHover: 'blue-abel-hover', FontFamily: 'semibold', FontSize: 'small', OnClick: event => this.onToggleReadMore(event), Text: IsReadMore ? t('read_less') : t('read_more') });
            }

        return (
            <DescriptionContainer>
                <Spacer Size="extra-small" />

                <FullWidthDiv />

                <Spacer Size="extra-small" />

                <MultiText Texts={Texts} />

            </DescriptionContainer>
        );
    }

    renderMultiSelect = () => {
        var { Item, MultiSelectItemIds, OnMultiSelect } = this.props;

        var { TrainingProgramId } = Item;

        var MultiSelectIndex = MultiSelectItemIds.indexOf(+TrainingProgramId);

        return <MultiSelect onClick={() => OnMultiSelect(Item, MultiSelectIndex)} Selected={MultiSelectIndex !== -1} />;
    }

    renderMultiSelectButton = () => {
        var { t } = this.props;
        var { Item, MultiSelectItemIds } = this.props;

        var { TrainingProgramId } = Item;

        var MultiSelectIndex = MultiSelectItemIds.indexOf(+TrainingProgramId);

        return (
            <>
                <Spacer Size="extra-small" />

                <ButtonGroup
                    Buttons={[{ BackgroundColor: 'white', BackgroundColorHover: 'blue-abel', Border: 'none', Color: 'black', ColorHover: 'white', ColorSelected: 'white', FontFamily: 'semibold', FontSize: 'medium-1', OnClick: e => this.onMultiSelect(e, Item, MultiSelectIndex), Selected: MultiSelectIndex !== -1, Size: 'small', Title: t(MultiSelectIndex !== -1 ? 'WorkoutPlanSettings_selected' : 'select_with_first_char_uppercase') }]}
                    ContainerWidth="100%"
                />
            </>
        );
    }

    renderNonSelectable = () => {
        var { t } = this.props;
        var { className, HideMadeBy, Item, ViewTemplate } = this.props;

        var { TrainingProgramId, ProgramName } = Item;

        return (
            <TemplateContainer id={`itemid-${TrainingProgramId}`} className={className}>
                {!HideMadeBy && this.renderUserPhoto()}

                {/* {this.renderTemplateDays()} */}

                <TemplateDetailContainer className="TemplateDetailContainer" onClick={!!ViewTemplate ? () => ViewTemplate(Item) : () => null}>
                    <TemplateNameContainer className="TemplateNameContainer">
                        <StyledTemplateName FontFamily="semibold" FontSize="small" NoMargin Text={ProgramName} TextAlign="center"/>
                    </TemplateNameContainer>

                    <Spacer Size="extra-small" />

                    <ButtonGroup
                        Buttons={[
                            {
                                BackgroundColorHover: 'blue-abel',
                                ColorHover: 'white',
                                Disabled: true,
                                FontFamily: 'medium',
                                FontSize: 'small',
                                Size: 'small',
                                Title: t('select_with_first_char_uppercase')
                            }
                        ]}
                        ContainerWidth="100%"
                    />
                </TemplateDetailContainer>
            </TemplateContainer>
        );
    }

    renderTemplateDays = () => {
        var { TemplateDays } = this.props.Item;

        return (
            <TemplateDaysOuterContainer className="TemplateDaysOuterContainer">
                <TemplateDaysContainer className="TemplateDaysContainer">
                    <PageText ElementType="span" FontFamily="medium" FontSize="small" NoMargin Text={`${TemplateDays}`} TextAlign="center"/>
                </TemplateDaysContainer>
            </TemplateDaysOuterContainer>
        );
    }

    renderUserPhoto = () => {
        var { DisplayName, Sex, UserId, UserPhoto } = this.props.Item;

        var SexImage = Sex === 'Male' ? Male : Female;

        if (UserId === 16) UserPhoto = AbelIcon;

        return <StyledProfilePhoto Alt={DisplayName} ImageSrc={UserPhoto} IsAbel={UserId === 16} NoImage={SexImage} NoParent />;
    }
    
    render() {
        var { t } = this.props;
        var { className, AssignToClients, HideMadeBy, HideSelect, MultiSelectItemIds, IsMultiSelect, Item, OnAssignToClients, SelectTemplate, SelectedItemIds, ShowDescription, ViewTemplate } = this.props;

        var { ProgramDescription, ProgramName, RelationshipType, SharedStatus, TrainingProgramId } = Item;

        // If Select Button is Showing, Hide Abel Templates and Pending SharedTo as they can't do anything in the multi-select options
            // if ((RelationshipType === 'Abel' || (RelationshipType === 'SharedTo' && SharedStatus === 2)) && !HideSelect && IsMultiSelect) return this.renderNonSelectable();

        // If RelationshipType === 'SharedTo' && SharedStatus === 2, Then Show Only Accept / Pending Buttons
            if ((RelationshipType === 'SharedTo' && SharedStatus === 2)) {
                return (
                    <TemplateContainer id={`itemid-${TrainingProgramId}`} className={className}>
                        {!HideMadeBy && this.renderUserPhoto()}

                        {/* {this.renderTemplateDays()} */}

                        <TemplateDetailContainer className="TemplateDetailContainer" onClick={!!ViewTemplate ? () => ViewTemplate(Item) : () => null}>
                            <TemplateNameContainer className="TemplateNameContainer">
                                <StyledTemplateName FontFamily="semibold" FontSize="small" NoMargin Text={ProgramName} TextAlign="center"/>
                            </TemplateNameContainer>

                            <Spacer Size="extra-small" />

                            <ButtonGroup
                                Buttons={[
                                    { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', OnClick: event => this.onUpdateSharedToCoachStatus({ event, ContentId: TrainingProgramId, NewStatus: 'reject' }), Size: 'small', Title: t('reject') },
                                    { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', OnClick: event => this.onUpdateSharedToCoachStatus({ event, ContentId: TrainingProgramId, NewStatus: 'accept' }), Size: 'small', Title: t('accept') }
                                ]}
                                // ButtonWidth="fit-content"
                                NotTouching
                                OwnRows
                            />
                            
                        </TemplateDetailContainer>
                    </TemplateContainer>
                );
            }

        var Selected = SelectedItemIds.indexOf(+TrainingProgramId) > -1 || MultiSelectItemIds.indexOf(+TrainingProgramId) > -1;

        return (
            <TemplateContainer className="TemplateContainer" id={`itemid-${TrainingProgramId}`} Selected={Selected}>
                {/* {IsMultiSelect && this.renderMultiSelect()} */}

                {!HideMadeBy && this.renderUserPhoto()}

                {/* {this.renderTemplateDays()} */}

                <TemplateDetailContainer className="TemplateDetailContainer" IsMultiSelect={IsMultiSelect} onClick={() => ViewTemplate(Item)} Selected={Selected}>
                    <TemplateNameContainer className="TemplateNameContainer">
                        <StyledTemplateName FontFamily="semibold" FontSize="small" NoMargin Text={ProgramName} TextAlign="center"/>
                    </TemplateNameContainer>

                    {(!!ShowDescription && !!ProgramDescription) && this.renderDescription()}

                    {
                        AssignToClients && HideSelect &&
                        <>
                            <Spacer Size="extra-small" />

                            <ButtonGroup
                                Buttons={[{ BackgroundColor: 'white', BackgroundColorHover: 'blue-abel', Border: 'none', Color: 'black', ColorHover: 'white', ColorSelected: 'white', FontFamily: 'semibold', FontSize: 'medium-1', OnClick: e => OnAssignToClients(e, Item), Size: 'small', Title: t('assign_to_clients') }]}
                                ContainerWidth="100%"
                            />
                        </>
                    }

                    {IsMultiSelect && !HideSelect && this.renderMultiSelectButton()}
                </TemplateDetailContainer>

                {
                    !HideSelect && !IsMultiSelect &&
                    <>
                        <Spacer Size="extra-small" />
                        
                        <ButtonGroup
                            Buttons={[{ BackgroundColorHover: 'blue-abel', BackgroundColorSelected: 'blue-abel', ColorHover: 'white', ColorSelected: 'white', FontFamily: 'medium', FontSize: 'small', OnClick: () => SelectTemplate(Item), Selected, Size: 'small', Title: t(Selected ? 'WorkoutPlanSettings_selected' : 'select_with_first_char_uppercase') }]}
                            ContainerWidth="100%"
                        />
                    </>
                }
            </TemplateContainer>
        );
    }
}

TrainingProgramListItem.propTypes = {
    AssignToClients: PropTypes.bool,
    HideSelect: PropTypes.bool,
    IsMultiSelect: PropTypes.bool,
    Item: PropTypes.object,
    OnAssignToClients: PropTypes.func,
    OnMultiSelect: PropTypes.func,
    MultiSelectItemIds: PropTypes.array,
    SelectTemplate: PropTypes.func,
    SelectedItemIds: PropTypes.array,
    ShowDescription: PropTypes.bool,
    ViewTemplate: PropTypes.func
}

TrainingProgramListItem.defaultProps = {
    MultiSelectItemIds: [],
    SelectedItemIds: [],
    ShowDescription: false
}

export default withTranslation()(TrainingProgramListItem);