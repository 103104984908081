import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { SaveDataInProps } from '../../Services/Actions';
import history from '../../history';

import { DisabledMenuItem, DisabledMenuItemContainer, MenuItem, MenuItemIllustration, MenuOptionsContainer } from './styles';

import AbelModal from '../../Components/Modal';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import AllWorkoutTypes from '../../Assets/Illustrations/AllWorkoutTypes_Small.png';
import Endurance from '../../Assets/Illustrations/Endurance_Blue_Small.png';
import Strength from '../../Assets/Illustrations/Strength_Small.png';
import Template from '../../Assets/Illustrations/Template_Small.png';
import WorkoutPlan from '../../Assets/Illustrations/WorkoutPlan.png';

class AddWorkoutMenu extends React.Component {
    onClickMenuItem = ({ event, href, search }) => {
        event.preventDefault();
        var { OnHide, SaveData, SaveDataInProps } = this.props;

        SaveDataInProps(SaveData).then(() => history.push({ pathname: href, ...search ? { search } : {}, state: { from: 'Client' } }));
        OnHide();
    }

    renderGenerateEndurance = () => {
        var { t } = this.props;
        var { ExperienceLevel_Endurance } = this.props.SaveData.ClientDetails;

        if (!ExperienceLevel_Endurance) {
            return (
                <DisabledMenuItemContainer className="DisabledMenuItemContainer">
                    <DisabledMenuItem>
                        <MenuItemIllustration alt={t('Endurance')} src={Endurance} />
                        
                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('Endurance_indoors')} TextAlign="center" />
                        <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text={t('ptadmin_algo_disabled_without_explevel')} TextAlign="center" />
                    </DisabledMenuItem>
                </DisabledMenuItemContainer>
            );
        }

        return (
            <MenuItem onClick={event => this.onClickMenuItem({ event, href: "/add-single-generated-workout", search: `?type=endurance` })} href="/add-single-generated-workout?type=endurance">
                <MenuItemIllustration alt={t('Endurance')} src={Endurance} />
                
                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('Endurance_indoors')} TextAlign="center" />
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('Quick_add')} TextAlign="center" />
            </MenuItem>
        );
    }

    renderGenerateStrength = () => {
        var { t } = this.props;
        var { ExperienceLevel_Strength } = this.props.SaveData.ClientDetails;

        if (!ExperienceLevel_Strength) {
            return (
                <DisabledMenuItemContainer className="DisabledMenuItemContainer">
                    <DisabledMenuItem>
                        <MenuItemIllustration alt={t('Strength')} src={Strength} />
                        
                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('Strength')} TextAlign="center" />
                        <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text={t('ptadmin_algo_disabled_without_explevel')} TextAlign="center" />
                    </DisabledMenuItem>
                </DisabledMenuItemContainer>
            );
        }

        return (
            <MenuItem onClick={event => this.onClickMenuItem({ event, href: "/add-single-generated-workout", search: `?type=strength` })} href="/add-single-generated-workout?type=strength">
                <MenuItemIllustration alt={t('Strength')} src={Strength} />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('Strength')} TextAlign="center" />
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('Quick_add')} TextAlign="center" />
            </MenuItem>
        );
    }

    render() {
        var { t } = this.props;
        var { className, ForGroups, OnHide, Show } = this.props;

        return (
            <AbelModal
                className={className}
                GA_PathName="AddWorkoutMenu"
                Show={Show}
                Size="medium"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                <MenuOptionsContainer>
                    <MenuItem onClick={event => this.onClickMenuItem({ event, href: "/add-training-program" })} href="/add-training-program">
                        <MenuItemIllustration alt={t('add_workouts_from_training_program')} src={WorkoutPlan} />
                        
                        <Spacer Size="small" />
                        
                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('add_workouts_from_training_program')} TextAlign="center" />
                    </MenuItem>
                    <MenuItem onClick={event => this.onClickMenuItem({ event, href: "/add-workout-template" })} href="/add-workout-template">
                        <MenuItemIllustration alt={t('add_workout_template')} src={Template} />
                        
                        <Spacer Size="small" />
                        
                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('add_workout_template')} TextAlign="center" />
                    </MenuItem>
                    <MenuItem onClick={event => this.onClickMenuItem({ event, href: "/add-workout" })} href="/add-workout">
                        <MenuItemIllustration alt={t('Strength')} src={AllWorkoutTypes} />
                        
                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('WorkoutPlan_Create_workout')} TextAlign="center" />
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('WorkoutPlan_Create_workout_types')} TextAlign="center" />
                    </MenuItem>
                    {
                        !ForGroups &&
                        <>
                            {this.renderGenerateEndurance()}
                            {this.renderGenerateStrength()}
                        </>
                    }
                </MenuOptionsContainer>
            </AbelModal>
        );
    }
}

AddWorkoutMenu.propTypes = {
    ForGroups: PropTypes.bool,
    OnHide: PropTypes.func.isRequired,
    SaveData: PropTypes.object,
    Show: PropTypes.bool.isRequired
}

AddWorkoutMenu.defaultProps = {
    ForGroups: false
}

export default withTranslation()(connect(null, { SaveDataInProps } )(AddWorkoutMenu));