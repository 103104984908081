import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { CheckmarkContainer, SelectAllHeaderContainer, StyledPageTextWithDotAfter, ThirdColumn } from './styles';

import Checkmark from '../Form/Checkmark';
import MultiText from '../Text/MultiText';
import PageText from '../Text/PageText';

class ChooseClientHeader extends React.Component {
    renderGroupType = OnClick => {
        var { t } = this.props;
        var { GroupType } = this.props.HeaderData;

        var GroupTypeText = t(`${GroupType.toLowerCase() === 'team' ? 'Team' : 'Group'}`);

        return <StyledPageTextWithDotAfter FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={OnClick} Text={GroupTypeText} TextAlign="left" />
    }

    render() {
        var { t } = this.props;
        var { HeaderData: { GroupId, GroupName, HideSelectAll, HideToggle, NumClients, OnSelectGroup, SelectedExcept, SelectedIndex }, OnToggle } = this.props;

        var OnClick = !!HideToggle ? null : OnToggle;

        var Texts = [{ FontFamily: "semibold", FontSize: "medium-1", OnClick, Text: `${NumClients}` }, { FontFamily: 'medium', FontSize: 'medium-1', OnClick, Text: NumClients === 1 ? t('ptadmin_clients_singular').toLowerCase() : t('ptadmin_clients_plural').toLowerCase() }];

        return (
            <SelectAllHeaderContainer HideSelectAll={HideSelectAll} NumClients={NumClients}>
                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" OnClick={OnClick} NoMargin Text={GroupName} TextAlign="left" />

                {
                    !!!HideSelectAll && !!+NumClients &&
                    <CheckmarkContainer className="CheckmarkContainer">
                        <Checkmark
                            Disabled={false}
                            ItemId={1}
                            OnSelectItem={OnSelectGroup}
                            SelectedExcept={SelectedExcept}
                            SelectedIndex={SelectedIndex}
                            Title={GroupName}
                        />
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" OnClick={OnSelectGroup} NoMargin Text={t('select_all')} TextAlign="center" />
                    </CheckmarkContainer>
                }

                <ThirdColumn>
                    {!!+GroupId && this.renderGroupType(OnClick)}
                    
                    <MultiText Texts={Texts} />
                </ThirdColumn>
            </SelectAllHeaderContainer>
        )
    }
}

ChooseClientHeader.propTypes = {
    HeaderData: PropTypes.object.isRequired,
    HideToggle: PropTypes.bool,
    OnToggle: PropTypes.func.isRequired
}

export default withTranslation()(ChooseClientHeader);