import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import PageText from "../../Text/PageText";
import Spacer from "../../Spacer";
import Image from "../../Image";

import VideoIcon from "../../../Assets/Icons/Camera_White.png";
import ClientIcon from "../../../Assets/Icons/Client_White.png";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const VideoCallBlock = styled.div`
  border-radius: 10px;
  width: ${(props) => (props.WorkoutSession ? "85%" : "100%")};
  min-height: 62px;
  padding: 8px;
  background-color: ${(props) => props.BackgroundColor};
  color: var(--white);
  margin: 0 auto;
  opacity: ${(props) => (props.Finished ? "0.6" : "1")};
`;

export const Icon = styled(Image)`
  height: 20px;
  width: ${(props) => (props.ExtraWidth ? "30px" : "20px")};
  transform: scale(0.7);
  margin-right: 3px;
`;

class VideoCall extends React.Component {
  renderText(acceptedUsers) {
    const { SingleSession } = this.props;
    const sessionDate = moment(SingleSession.videoSessionDate);
    const currentDate = moment().format();
    const hourDiff = sessionDate.diff(currentDate, "hours");
    return acceptedUsers === 0 && hourDiff >= 0 && hourDiff <= 1;
  }

  render() {
    const { t, SingleSession, IsWorkoutSession } = this.props;
    const { TotalAccepted, TotalRejected, TotalUsers } = SingleSession;
    const BackgroundColor =
      TotalAccepted > 0
        ? "var(--abel-blue)"
        : TotalRejected === TotalUsers
        ? "var(--warning)"
        : "var(--blue-astronaut)";

    return (
      <VideoCallBlock
        className="VideoCallBlock"
        WorkoutSession={IsWorkoutSession}
        BackgroundColor={BackgroundColor}
        Finished={SingleSession.HasFinished}
      >
        <Container>
          <LeftContainer>
            <Icon Alt={"VideoIcon"} ImageSrc={VideoIcon} ExtraWidth={true} />
            <PageText
              FontFamily="medium"
              FontSize="medium-1"
              NoMargin
              JustifyContent="flex-start"
              Text={moment(SingleSession.VideoSessionDate).format("HH:mm")}
              TextAlign="left"
            />
          </LeftContainer>
          {(TotalRejected !== TotalUsers || TotalAccepted > 0) && (
            <RightContainer>
              <Icon Alt={"ClientIcon"} ImageSrc={ClientIcon} />
              <PageText
                FontFamily="medium"
                FontSize="medium-1"
                NoMargin
                JustifyContent="flex-start"
                Text={String(TotalAccepted)}
                TextAlign="right"
              />
            </RightContainer>
          )}
        </Container>

        <Spacer Size="extra-extra-small" />

        <PageText
          FontFamily="medium"
          FontSize="medium-1"
          NoMargin
          JustifyContent="flex-start"
          Text={
            SingleSession.IsGroupSession
              ? t("video_session_group_call")
              : t("video_session_one_to_one_call")
          }
          TextAlign="left"
        />

        <Spacer Size="extra-extra-small" />

        {this.renderText(TotalAccepted) && !SingleSession.HasFinished && (
          <PageText
            FontFamily="medium-italic"
            FontSize="small"
            NoMargin
            JustifyContent="flex-start"
            Text={t("video_session_no_one_accepted_invitation_yet")}
            TextAlign="left"
          />
        )}

        {SingleSession.HasFinished && (
          <PageText
            FontFamily="medium-italic"
            FontSize="small"
            NoMargin
            JustifyContent="flex-start"
            Text={t("video_session_meeting_has_ended")}
            TextAlign="left"
          />
        )}
      </VideoCallBlock>
    );
  }
}

VideoCall.propTypes = {
  SingleSession: PropTypes.shape({}).isRequired,
};

export default withTranslation()(connect()(VideoCall));
