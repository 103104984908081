import React from 'react';
import { withTranslation } from 'react-i18next';
import history from '../../../history';

import * as Styles from './styles';

import Checkmark from '../../../Components/Form/Checkmark';
import MultiText from '../../../Components/Text/MultiText';
import PageText from '../../../Components/Text/PageText';
import Spacer from '../../../Components/Spacer';

import { GetTranslatedFilterName } from '../../../Functions';

import AbelIcon from '../../../Assets/Icons/Abel_HeaderIcon.png';
import Blocked from '../../../Assets/Icons/Blocked.png';
import Checkmark_Selected from '../../../Assets/Icons/Checkmark_Selected.png';
import { ReactComponent as CustomizeSVG } from '../../../Assets/SVG/Customize.svg';
import DownArrow from '../../../Assets/Icons/DownArrow.png';
import Female from '../../../Assets/Icons/Female.png';
import Male from '../../../Assets/Icons/Male.png';
import UpArrow from '../../../Assets/Icons/UpArrow.png';

const FilterEquipment = (Equipment, AllFiltersSearchText, FilterSearchText) => {
    var result = [];

    Equipment.forEach(EquipmentCategory => {
        var temp = {
            EquipmentCategory: EquipmentCategory.EquipmentCategory,
            EquipmentList: []
        };
        var found = false;

        EquipmentCategory.EquipmentList.forEach(EquipmentList => {
            if ((AllFiltersSearchText !== '' && EquipmentList.TagName.toLowerCase().includes(AllFiltersSearchText.toLowerCase())) || (FilterSearchText !== '' && EquipmentList.TagName.toLowerCase().includes(FilterSearchText.toLowerCase()))) {
                temp.EquipmentList.push(EquipmentList);

                found = true;
            }
        })

        if (found) result.push(temp);
    });

    return result;
}

const GetActiveFiltersCount = Selected => {
    let CountAvoidFilters = 0;
    let CountSelectedFilters = 0;

    Object.keys(Selected).forEach(key => {
        if (key === 'AvoidJoints' || key === 'AvoidMuscles') CountAvoidFilters += Selected[key].length;
        else CountSelectedFilters += Selected[key].length;
    });

    return { CountAvoidFilters, CountSelectedFilters };
}

class FilterSection extends React.Component {
    state =  {
        IsOpen: true,
        SearchName: ''
    }

    componentDidMount() {
        var { Filter: { StartOpen } } = this.props;

        this.setState({ IsOpen: StartOpen });
    }

    onChangeSearchName = event => {
        this.setState({ SearchName: event.target.value });
    }

    onClearFilterSection = () => {
        this.props.OnClearFilterSection();
    }

    onSelectAll = ShowingTags => {
        var NewlySelected = [];
        var { FilterName, IsAbel } = this.props.Filter;

        if (IsAbel && FilterName === 'Equipment') NewlySelected = ShowingTags.filter(ST => ST.EquipmentCategory !== '').map(ST => ST.EquipmentList.map(Eq => Eq.TagId)).flat();
        else NewlySelected = ShowingTags.map(({ TagId }) => +TagId);

        this.props.OnSelectAllTagsInSection(NewlySelected);
    }

    onToggleIsOpen = () => {
        this.setState({ IsOpen: !this.state.IsOpen });
    }

    renderActiveFilterCount = ({ Avoid, Filter, Selected, Target }) => {
        var { FilterName, IsAbel } = Filter;

        if (IsAbel && (FilterName === 'Joints' || FilterName === 'Muscles') && (Avoid.length > 0 || Target.length > 0)) {
            return (
                <>
                    {Avoid.length > 0 && <PageText FontColor="red-bittersweet" FontFamily="bold" FontSize="medium" JustifyContent="flex-end" NoMargin Text={`${Avoid.length}`} TextAlign="right" />}
                    {Target.length > 0 && <PageText FontColor="blue-abel" FontFamily="bold" FontSize="medium" JustifyContent="flex-end" NoMargin Text={`${Target.length}`} TextAlign="right" />}
                </>
            );
        } else if (Selected.length > 0) return <PageText FontColor="blue-abel" FontFamily="bold" FontSize="medium" JustifyContent="flex-end" NoMargin Text={`${Selected.length}`} TextAlign="right" />;

        return null;
    }

    renderTag = Tag => {
        var { Avoid, Selected, Target } = this.props;

        var key = Tag.TagId;
        if (this.props.Filter.IsAbel && this.props.Filter.FilterName === 'Equipment') key = Tag.EquipmentCategory;

        if (this.props.Filter.IsAbel && (this.props.Filter.FilterName === 'Joints' || this.props.Filter.FilterName === 'Muscles')) {
            var SelectedIndex_Avoid = Avoid.indexOf(Tag.TagId);
            var SelectedIndex_Target = Target.indexOf(Tag.TagId);

            return (
                <Styles.FilterTagContainer className="FilterTagContainer" key={key}>
                    <Checkmark
                        BlockIcon
                        ItemId={Tag.TagId}
                        OnSelectItem={event => this.props.OnSelectTag(event, Tag.TagId, SelectedIndex_Avoid, 'Avoid', SelectedIndex_Target)}
                        SelectedIndex={SelectedIndex_Avoid}
                        Title={Tag.TagName}
                    />

                    <Styles.CheckmarkItemName
                        ElementType="div"
                        FontFamily="medium"
                        FontSize="small"
                        NoMargin
                        // OnClick={() => this.props.OnSelectTag(null, Tag.TagId, SelectedIndex)}
                        Text={Tag.TagName}
                        Title={Tag.TagName}
                        TwoBoxes
                    />

                    <Checkmark
                        ItemId={Tag.TagId}
                        OnSelectItem={event => this.props.OnSelectTag(event, Tag.TagId, SelectedIndex_Target, 'Target', SelectedIndex_Avoid)}
                        SelectedIndex={SelectedIndex_Target}
                        Title={Tag.TagName}
                    />
                </Styles.FilterTagContainer>
            );
        }
        else if (!(this.props.Filter.IsAbel && this.props.Filter.FilterName === 'Equipment')) {
            var SelectedIndex = Selected.indexOf(Tag.TagId);

            return (
                <Styles.FilterTagContainer className="FilterTagContainer" key={key}>
                    <Checkmark
                        ItemId={Tag.TagId}
                        OnSelectItem={event => this.props.OnSelectTag(event, Tag.TagId, SelectedIndex)}
                        SelectedIndex={SelectedIndex}
                        Title={Tag.TagName}
                    />

                    {this.props.Filter.FilterName === 'MadeBy' && this.renderUserPhoto(Tag, SelectedIndex)}

                    <Styles.CheckmarkItemName
                        ElementType="div"
                        FontFamily="medium"
                        FontSize="small"
                        NoMargin
                        OnClick={() => this.props.OnSelectTag(null, Tag.TagId, SelectedIndex)}
                        Text={Tag.TagName}
                        Title={Tag.TagName}
                    />
                </Styles.FilterTagContainer>
            );
        }
        else if (this.props.Filter.IsAbel && this.props.Filter.FilterName === 'Equipment') {
            if (!Tag.EquipmentList) return null;

            return (
                <Styles.EquipmentFilterContainer className="EquipmentFilterContainer" key={key}>
                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={Tag.EquipmentCategory} TextAlign="left" />

                    <Spacer Size="extra-small" />

                    {
                        Tag.EquipmentList && Tag.EquipmentList.map(Equipment => {
                            var ShowBlocked = Selected.length === 1 && Selected[0] === 0 && Equipment.TagId !== 0;
                            var SelectedIndex = Selected.indexOf(Equipment.TagId);
                            // var Selected = Selected.indexOf(Equipment.TagId) !== -1;

                            return (
                                <Styles.FilterTagContainer className="FilterTagContainer" key={Equipment.TagId}>
                                    <Checkmark
                                        Disabled={ShowBlocked}
                                        ItemId={Equipment.TagId}
                                        OnSelectItem={event => this.props.OnSelectTag(event, Equipment.TagId, SelectedIndex)}
                                        SelectedIndex={SelectedIndex}
                                        Title={Equipment.TagName}
                                    />

                                    <Styles.CheckmarkItemName
                                        Disabled={ShowBlocked}
                                        ElementType="div"
                                        FontFamily="medium"
                                        FontSize="small"
                                        NoMargin
                                        OnClick={(ShowBlocked && SelectedIndex === -1) ? null : () => this.props.OnSelectTag(null, Equipment.TagId, SelectedIndex)}
                                        Text={Equipment.TagName}
                                        Title={Equipment.TagName}
                                    />
                                </Styles.FilterTagContainer>
                            );
                        })
                    }
                </Styles.EquipmentFilterContainer>
            );
        }

        return (
            <pre key={key}>
                {JSON.stringify(Tag, null, 2)}
            </pre>
        );
    }

    renderUserPhoto = (Tag, SelectedIndex) => {
        var { Sex, TagId, TagName, UserId, UserPhoto } = Tag;

        var SexImage = Sex === 'Male' ? Male : Female;

        if (+UserId === 16) UserPhoto = AbelIcon;

        return <Styles.StyledProfilePhoto Alt={TagName} ImageSrc={UserPhoto} IsAbel={UserId === 16} NoImage={SexImage} NoParent OnClick={() => this.props.OnSelectTag(null, TagId, SelectedIndex)} />;
    }

    render() {
        var { t } = this.props;
        var { IsOpen, SearchName } = this.state;
        var { AllFiltersSearchText, Avoid, Filter, Filter: { FilterName, IsAbel, IsHidden, Tags }, Selected, Target } = this.props;

        if (!!IsHidden) return null;

        var ShowingTags = Tags;
        var TagsLength = Tags.length;

        if (AllFiltersSearchText !== '' || SearchName !== '') {
            if (IsAbel && FilterName === 'Equipment') {
                ShowingTags = FilterEquipment(Tags, AllFiltersSearchText, SearchName);
                TagsLength = ShowingTags.reduce((acc, curr) => acc + curr.EquipmentList.length, 0);
            }
            else {
                ShowingTags = Tags.filter(Tag => ((AllFiltersSearchText !== '' && Tag.TagName.toLowerCase().includes(AllFiltersSearchText.toLowerCase())) || (SearchName !== '' && Tag.TagName.toLowerCase().includes(SearchName.toLowerCase()))));
                TagsLength = ShowingTags.length;
            }
        } else if (IsAbel && FilterName === 'Equipment') TagsLength = ShowingTags.reduce((acc, curr) => acc + curr.EquipmentList.length, 0);

        var TranslatedFilterName = GetTranslatedFilterName(Filter, t);

        return (
            <Styles.FilterContainer className="FilterContainer">
                <Styles.FilterContainerHeader className="FilterContainerHeader" onClick={this.onToggleIsOpen}>
                    <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={TranslatedFilterName} TextAlign="left" />

                    {this.renderActiveFilterCount({ Avoid, Filter, Selected, Target })}

                    <Styles.ToggleIcon>
                        <img src={this.state.IsOpen ? UpArrow : DownArrow} alt="Open and close the accordion" />
                    </Styles.ToggleIcon>
                </Styles.FilterContainerHeader>

                {
                    !!+IsOpen &&
                    <>
                        <Spacer Size="extra-extra-small" />

                        <Styles.StyledSearchInput
                            FontSize="medium-1"
                            NoLabel
                            NoMargin
                            OnChange={this.onChangeSearchName}
                            Placeholder={t('_search')}
                            Size="small-2"
                            Type="text"
                            Value={SearchName}
                        />

                        {
                            ((IsAbel && (FilterName === 'Joints' || FilterName === 'Muscles') && ((Target.length > 0 || Target.length < TagsLength)))) ?
                            <>
                                {/* <Spacer Size="extra-extra-small" />

                                <Styles.FilterTagsListOptions className="FilterTagsListOptions">
                                    {Target.length > 0 && <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={this.onClearFilterSection} Text={t('_clear')} TextAlign="left" />}
                                    
                                    {Target.length < TagsLength && <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectAll(ShowingTags)} Text={t('select_all')} TextAlign="left" />}
                                </Styles.FilterTagsListOptions> */}
                            </>
                        :
                            (Selected.length > 0 || Selected.length < TagsLength) &&
                            <>
                                <Spacer Size="extra-extra-small" />

                                <Styles.FilterTagsListOptions className="FilterTagsListOptions">
                                    {Selected.length > 0 && <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={this.onClearFilterSection} Text={t('_clear')} TextAlign="left" />}
                                    
                                    {Selected.length < TagsLength && <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onSelectAll(ShowingTags)} Text={t('select_all')} TextAlign="left" />}
                                </Styles.FilterTagsListOptions>
                            </>
                        }

                        <Spacer Size="extra-extra-small" />

                        <Styles.TagsContainer className="TagsContainer">
                            {
                                !!+IsAbel && (FilterName === 'Joints' || FilterName === 'Muscles') &&
                                <Styles.FilterTagContainer className="FilterTagContainer" JustifyContent="space-between">
                                    <Styles.CheckmarkIcon ImageSrc={Blocked} Loading={false} />

                                    <Styles.CheckmarkIcon ImageSrc={Checkmark_Selected} Loading={false} />
                                </Styles.FilterTagContainer>
                            }
                            {ShowingTags.map(this.renderTag)}
                        </Styles.TagsContainer>
                    </>
                }
            </Styles.FilterContainer>
        );
    }
}

class FiltersComponent extends React.Component {
    state = {
        DefaultOrgTags: {},
        SearchName: '',
        Selected: {
            AvoidJoints: [],
            AvoidMuscles: [],
            Equipment: [],
            Injuries: [],
            MadeBy: [],
            Movement: [],
            Plane: [],
            RepRange: [],
            StartPosition: [],
            Tags: [],
            TargetJoints: [],
            TargetMuscles: [],
            TrainingFocus: [],
            TrainingForm: [],
            WorkoutType: [],

            OrgTags: {}
        }
    }

    componentDidMount() {
        this.setState({ DefaultOrgTags: { ...this.props.DefaultSelections.OrgTags }, Selected: { ...this.props.DefaultSelections } });
    }

    onChangeSearchName = event => {
        this.setState({ SearchName: event.target.value });
    }

    onClearFilterSection = Filter => {
        var { Selected } = this.state;

        var FilterName = Filter.FilterName;
        if (FilterName === 'Joints' || FilterName === 'Muscles') {
            FilterName = `Target${FilterName}`;
            
            var OppositeFilterName = '';
            if (Filter.FilterName === 'Joints') OppositeFilterName = 'AvoidJoints';
            else if (Filter.FilterName === 'Muscles') OppositeFilterName = 'AvoidMuscles';

            Selected[OppositeFilterName] = [];
        }
        
        if (!!+Filter.IsOrg) {
            FilterName = 'OrgTags';
            var TagFilterId = Filter.TagFilterId;

            Selected[FilterName][`${TagFilterId}`] = [];
        } else Selected[FilterName] = [];

        this.setState({ Selected }, () => this.props.OnUpdateSelections(Filter, [], false));
    }

    onClearFilters = () => {
        this.setState({
            Selected: {
                AvoidJoints: [],
                AvoidMuscles: [],
                Equipment: [],
                Injuries: [],
                MadeBy: [],
                Movement: [],
                Plane: [],
                RepRange: [],
                StartPosition: [],
                Tags: [],
                TargetJoints: [],
                TargetMuscles: [],
                TrainingFocus: [],
                TrainingForm: [],
                WorkoutType: [],

                OrgTags: { ...this.state.DefaultOrgTags }
            }
        }, () => this.props.OnClearAllFilters());
    }

    onSelectAllTagsInSection = (Filter, NewlySelected) => {
        var { Selected } = this.state;

        var FilterName = Filter.FilterName;
        if (FilterName === 'Joints' || FilterName === 'Muscles') {
            FilterName = `Target${FilterName}`;
            
            var OppositeFilterName = '';
            if (Filter.FilterName === 'Joints') OppositeFilterName = 'AvoidJoints';
            else if (Filter.FilterName === 'Muscles') OppositeFilterName = 'AvoidMuscles';

            Selected[OppositeFilterName] = [];
        }

        if (!!+Filter.IsOrg) {
            FilterName = 'OrgTags';
            var TagFilterId = Filter.TagFilterId;

            Selected[FilterName][TagFilterId] = NewlySelected;
        } else Selected[FilterName] = NewlySelected;

        this.setState({ Selected }, () => this.props.OnUpdateSelections(Filter, Selected[FilterName], Selected[OppositeFilterName]));
    }

    onSelectTag = (event, Filter, TagId, TagIndex, TargetAvoid, OppositeTargetAvoidIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = TagIndex === -1;

        var { Selected } = this.state;

        var FilterName = Filter.FilterName;
        if (FilterName === 'Joints' || FilterName === 'Muscles') {
            FilterName = `${TargetAvoid}${FilterName}`;
            Filter.TargetAvoid = TargetAvoid;
        }

        if (!!+Filter.IsOrg) {
            FilterName = 'OrgTags';
            var TagFilterId = Filter.TagFilterId;

            if (checked) {
                if (TagId === 0) Selected[FilterName][TagFilterId] = [ TagId ];
                else Selected[FilterName][TagFilterId] = [ ...Selected[FilterName][TagFilterId], TagId ];
            } else Selected[FilterName][TagFilterId] = [ ...this.state.Selected[FilterName][TagFilterId].slice(0, TagIndex), ...this.state.Selected[FilterName][TagFilterId].slice(TagIndex + 1) ];
        } else {
            if (checked) {
                if (TagId === 0) Selected[FilterName] = [ TagId ];
                else {
                    Selected[FilterName] = [ ...Selected[FilterName], TagId ];
    
                    // Remove the opposite target/avoid if already selected
                        if ((Filter.FilterName === 'Joints' || Filter.FilterName === 'Muscles') && OppositeTargetAvoidIndex !== -1) {
                            var OppositeFilterName = `${TargetAvoid === 'Avoid' ? 'Target' : 'Avoid'}${Filter.FilterName}`;
                            Selected[OppositeFilterName] = [ ...this.state.Selected[OppositeFilterName].slice(0, OppositeTargetAvoidIndex), ...this.state.Selected[OppositeFilterName].slice(OppositeTargetAvoidIndex + 1) ];
                        }
                }
            } else Selected[FilterName] = [ ...this.state.Selected[FilterName].slice(0, TagIndex), ...this.state.Selected[FilterName].slice(TagIndex + 1) ];
        }

        this.setState({ Selected }, () => this.props.OnUpdateSelections(Filter, Selected[FilterName], Selected[OppositeFilterName]));
    }

    onToggleShowCustomizeFilters = () => {
        let pathname = window.location.pathname; 
        // returns path: '/app/books'
        let searchParams = new URLSearchParams(window.location.search); 
        // returns the existing query string: '?type=fiction&author=fahid'
        searchParams.set('ShowCustomizeFilters', 1);
        
        history.push({ pathname, search: searchParams.toString() });
    }

    render() {
        var { t } = this.props;
        var { SearchName, Selected } = this.state;

        let { CountAvoidFilters, CountSelectedFilters } = GetActiveFiltersCount(Selected);

        return (
            <Styles.FiltersContainer className="FiltersContainer">
                <Styles.FiltersContainerHeaderRow1 className="FiltersContainerHeaderRow1">
                    <MultiText
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('MealPlan_Filters') },
                            !!CountAvoidFilters && { FontColor: 'red-bittersweet', FontFamily: 'bold', FontSize: 'medium-1', Text: `${CountAvoidFilters}` },
                            !!CountSelectedFilters && { FontColor: 'blue-abel', FontFamily: 'bold', FontSize: 'medium-1', Text: `${CountSelectedFilters}` }
                        ]}
                    />

                    {
                        !this.props.ShowTrash && this.props.Device === 'laptop' && !this.props.HideCustomizeButton &&
                        <Styles.CustomizeSVGContainer className="CustomizeSVGContainer" onClick={this.onToggleShowCustomizeFilters}>
                            <CustomizeSVG />
                        </Styles.CustomizeSVGContainer>
                    }
                </Styles.FiltersContainerHeaderRow1>

                {
                    (!!CountAvoidFilters || !!CountSelectedFilters) &&
                    <>
                        <Spacer Size="extra-extra-small" />

                        <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={this.onClearFilters} Text={t('_clear_all')} TextAlign="left" />
                    </>
                }

                <Spacer Size="extra-extra-small" />

                <Styles.StyledSearchInput
                    FontSize="medium-1"
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeSearchName}
                    Placeholder={t('_search_in_all_filters')}
                    Size="small-2"
                    Type="text"
                    Value={SearchName}
                />

                <Spacer Size="small" />

                {
                    this.props.Filters && this.props.Filters.map(Filter => {
                        if (this.props.HideSections && this.props.HideSections.indexOf(Filter.FilterName) !== -1) return null; // Don't Show Categories For Other Workout Types as this setting comes from AddWorkouts where a workout type is already selected. Choosing wrong kind will lead to bugs / crashes

                        var { FilterName, IsAbel, IsOrg, TagFilterId } = Filter;
                        if (!!+IsOrg) FilterName = 'OrgTags';

                        return (
                            <FilterSection
                                key={TagFilterId}
                                AllFiltersSearchText={SearchName}
                                Avoid={(!!IsAbel && (FilterName === 'Joints' || FilterName === 'Muscles')) ? Selected[`Avoid${FilterName}`] : []}
                                Filter={Filter}
                                OnClearFilterSection={() => this.onClearFilterSection(Filter)}
                                OnSelectAllTagsInSection={NewlySelected => this.onSelectAllTagsInSection(Filter, NewlySelected)}
                                OnSelectTag={(event, TagId, TagIndex, TargetAvoid, OppositeTargetAvoidIndex) => this.onSelectTag(event, Filter, TagId, TagIndex, TargetAvoid, OppositeTargetAvoidIndex)}
                                Selected={(!!+IsOrg ? Selected[`${FilterName}`][TagFilterId] : Selected[`${FilterName}`]) || []}
                                Target={(!!IsAbel && (FilterName === 'Joints' || FilterName === 'Muscles')) ? Selected[`Target${FilterName}`] : []}
                                t={t}
                            />
                        );
                    })
                }
            </Styles.FiltersContainer>
        );
    }
}

export default withTranslation()(FiltersComponent);