import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DeliverySite_GetDHCs_Basic, DeliverySite_GetSalesByProducts } from '../../../Services/Actions';
import Select from 'react-select';
import Popover from 'react-popover';
import moment from 'moment';

import * as styles from './styles';

import Loading from '../../../Components/Loading';
import PageText from '../../../Components/Text/PageText';
import ProfilePhoto from '../../../Components/ProfilePhoto';
import Spacer from '../../../Components/Spacer';

import InfoIcon from '../../../Assets/Icons/Info.png';

const customStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "initial"
    }),
    input: (provided, state) => ({
        ...provided,
        minWidth: '20%'
    })
}

class SalesTableRow extends React.Component {
    render() {
        var { IsRowExpanded, Months, ProductName } = this.props.Product;

        if (!IsRowExpanded) {
            return (
                <styles.UsersTableBodyRow className="UsersTableBodyRow" onClick={this.props.OnToggleExpandRow} IsRowExpanded={IsRowExpanded}>
                    <styles.UsersTableBodyCell className="UsersTableBodyCell">
                        {ProductName}
                    </styles.UsersTableBodyCell>
                    {
                        Months.map((Month, MonthIndex) => {
                            var { TotalSales } = Month;
    
                            return (
                                <styles.UsersTableBodyCell className="UsersTableBodyCell" key={MonthIndex}>
                                    {`${TotalSales} kr`}
                                </styles.UsersTableBodyCell>
                            );
                        })
                    }
                </styles.UsersTableBodyRow>
            );
        }

        var { DHCs } = this.props.Product;

        return (
            <>
                <styles.UsersTableBodyRow className="UsersTableBodyRow" onClick={this.props.OnToggleExpandRow} IsRowExpanded={IsRowExpanded}>
                    <styles.UsersTableBodyCell className="UsersTableBodyCell">
                        {ProductName}
                    </styles.UsersTableBodyCell>
                    {
                        Months.map((Month, MonthIndex) => {
                            var { TotalSales } = Month;
    
                            return (
                                <styles.UsersTableBodyCell className="UsersTableBodyCell" key={MonthIndex}>
                                    {`${TotalSales} kr`}
                                </styles.UsersTableBodyCell>
                            );
                        })
                    }
                </styles.UsersTableBodyRow>

                {
                    DHCs.map(({ DHC_UserId, Months, TotalSales, DHC_DisplayName, DHC_Sex, DHC_ProfilePhotoUrl }) => {
                        return (
                            <styles.UsersTableBodyRow className="UsersTableBodyRow" key={DHC_UserId} IsRowExpanded={IsRowExpanded} IsChildRow>
                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                    <styles.UsersTableBodyCellFlexed className="UsersTableBodyCellFlexed">
                                        <styles.ProfilePhotoContainer className="ProfilePhotoContainer">
                                            <ProfilePhoto Sex={DHC_Sex || 'Male'} Size="small-3" Source={DHC_ProfilePhotoUrl || ''} />
                                        </styles.ProfilePhotoContainer>
                                        {DHC_DisplayName} {`(${TotalSales} kr)`}
                                    </styles.UsersTableBodyCellFlexed>
                                </styles.UsersTableBodyCell>
                                {
                                    Months.map((Month, MonthIndex) => {
                                        var { TotalSales } = Month;
                
                                        return (
                                            <styles.UsersTableBodyCell className="UsersTableBodyCell" key={MonthIndex}>
                                                {`${TotalSales} kr`}
                                            </styles.UsersTableBodyCell>
                                        );
                                    })
                                }
                            </styles.UsersTableBodyRow>
                        );
                    })
                }
            </>
        );
    }
}

class SalesComponent extends React.Component {
    TimeoutId = null;

    state = {
        Months: [],
        Products: [],

        Filters: {
            StartDate: '',
            EndDate: '',
            DHCs: {
                IsLoading: false,
                Loaded: false,
                Selected: {},
                Users: []
            }
        },

        PopoverIsOpen_DHCs: false,
        PopoverIsOpen_EndDate: false,
        PopoverIsOpen_StartDate: false
    }

    componentDidMount() {
        this.onLoadSalesByProducts();
    }

    onBlurDate_EndDate = () => {
        this.onLoadSalesByProducts();
    }

    onBlurDate_StartDate = () => {
        this.onLoadSalesByProducts();
    }

    onBlurFilter_DHCs = () => {
        this.onLoadSalesByProducts();
    }

    onChangeDate_EndDate = event => {
        var Filters = { ...this.state.Filters };
        Filters.EndDate = event.target.value;

        this.setState({ Filters });
    }

    onChangeDate_StartDate = event => {
        var Filters = { ...this.state.Filters };
        Filters.StartDate = event.target.value;

        this.setState({ Filters });
    }

    onChangeFilter_DHCs = data => {
        if (!data) data = {};
        
        var Filters = { ...this.state.Filters };
        Filters.DHCs.Selected = data;

        this.setState({ Filters });
    }

    onFocusFilter_DHCs = () => {
        if (!this.state.Filters.DHCs.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.DHCs = { ...Filters.DHCs };
            Filters.DHCs.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetDHCs_Basic().then(({ Users }) => {
                    Filters.DHCs.IsLoading = false;
                    Filters.DHCs.Users = Users;
                    Filters.DHCs.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onLoadSalesByProducts = () => {
        var { Filters: { DHCs, EndDate, StartDate } } = this.state;

        var DHC_UserIds = DHCs.Selected.length > 0 ? DHCs.Selected.map(({ UserId }) => UserId).join(',') : null;

        this.props.DeliverySite_GetSalesByProducts({ DHC_UserIds, EndDate, StartDate }).then(({ EndDate, Months, Products, StartDate }) => {
            this.setState({ Filters: { ...this.state.Filters, EndDate, StartDate }, Months, Products });
        });
    }

    onToggleExpandRow = ProductIndex => {
        var Products = [ ...this.state.Products ];

        Products[ProductIndex].IsRowExpanded = !Products[ProductIndex].IsRowExpanded;

        this.setState({ Products });
    }

    onTogglePopover = PopoverType => {
        this.setState({ [PopoverType]: !this.state[PopoverType] }, () => {
            if (!this.state[PopoverType] && !!this.TimeoutId) clearTimeout(this.TimeoutId);
        });
    }

    renderSalesByProducts = () => {
        var { Months, Products } = this.state;

        if (!Months.length) return null;

        return (
            <styles.BodyContainer className="BodyContainer">
                <styles.BodyHeader className="BodyHeader">
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text="Sales By Product" TextAlign="left" />
                </styles.BodyHeader>

                <Spacer Size="small" />

                <styles.UsersContainer className="UsersContainer">
                    <styles.UsersTableContainer className="UsersTableContainer">
                        <styles.UsersTable className="UsersTable">
                            <styles.UsersTableHeader className="UsersTableHeader">
                                <styles.UsersTableHeaderRow className="UsersTableHeaderRow">
                                    <styles.UsersTableHeaderCell className="UsersTableHeaderCell">
                                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Product" />
                                    </styles.UsersTableHeaderCell>
                                    {
                                        Months.map(({ MonthNumber, TotalSales, YearNumber }) => {
                                            return (
                                                <styles.UsersTableHeaderCell className="UsersTableHeaderCell" key={`${YearNumber}_${MonthNumber}`}>
                                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin TextAlign="center" Text={moment(`${YearNumber}-${MonthNumber}-01`).format('MMM YYYY')} />
                                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin TextAlign="center" Text={`${TotalSales} kr`} />
                                                </styles.UsersTableHeaderCell>
                                            );
                                        })
                                    }
                                </styles.UsersTableHeaderRow>
                            </styles.UsersTableHeader>
                            <styles.UsersTableBody className="UsersTableBody">
                                {
                                    Products.map((Product, ProductIndex) => {
                                        return (
                                            <SalesTableRow
                                                key={Product.ProductId}
                                                OnToggleExpandRow={() => this.onToggleExpandRow(ProductIndex)}
                                                Product={Product}
                                            />
                                        )
                                    })
                                }
                            </styles.UsersTableBody>
                        </styles.UsersTable>
                    </styles.UsersTableContainer>
                </styles.UsersContainer>
            </styles.BodyContainer>
        );
    }

    renderFilters = () => {
        var { t } = this.props;

        const popoverProps_DHCs = {
            isOpen: this.state.PopoverIsOpen_DHCs,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_DHCs'),
            body: this.renderPopoverContent('DHCs'),
            tipSize: 0.01
        }

        const popoverProps_EndDate = {
            isOpen: this.state.PopoverIsOpen_EndDate,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_EndDate'),
            body: this.renderPopoverContent('EndDate'),
            tipSize: 0.01
        }
        
        const popoverProps_StartDate = {
            isOpen: this.state.PopoverIsOpen_StartDate,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_StartDate'),
            body: this.renderPopoverContent('StartDate'),
            tipSize: 0.01
        }

        var { Filters: { DHCs, EndDate, StartDate } } = this.state;

        return (
            <styles.FiltersContainer className="FiltersContainer">
                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Filters" TextAlign="left" />

                <Spacer Size="extra-small" />

                <styles.FiltersSubContainer className="FiltersSubContainer">
                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Start date" />

                            <Popover {...popoverProps_StartDate}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_StartDate')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_StartDate')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <input
                            type="date"
                            onBlur={this.onBlurDate_StartDate}
                            onChange={this.onChangeDate_StartDate}
                            value={StartDate}
                        />
                    </styles.FilterContainer>

                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="End date" />

                            <Popover {...popoverProps_EndDate}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_EndDate')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_EndDate')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <input
                            type="date"
                            onBlur={this.onBlurDate_EndDate}
                            onChange={this.onChangeDate_EndDate}
                            value={EndDate}
                        />
                    </styles.FilterContainer>
                
                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="DHCs" />

                            <Popover {...popoverProps_DHCs}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_DHCs')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_DHCs')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            getOptionLabel={option => option.DisplayName}
                            getOptionValue={option => option.UserId}
                            isLoading={DHCs.IsLoading}
                            isMulti
                            // components={{ ValueContainer }}
                            hideSelectedOptions={true}
                            isSearchable
                            onBlur={this.onBlurFilter_DHCs}
                            onChange={data => this.onChangeFilter_DHCs(data)}
                            onFocus={this.onFocusFilter_DHCs}
                            options={DHCs.Users}
                            placeholder={t('Filter by DHCs')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>
                </styles.FiltersSubContainer>
            </styles.FiltersContainer>
        );
    }

    renderPopoverContent = ContentType => {
        // var { t } = this.props;

        return (
            <styles.PopoverContent className="PopoverContent">
                {ContentType === 'DHCs' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Filter statistics for just the selected DHCs" TextAlign="center" />}
                {ContentType === 'EndDate' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Filter statistics for a custom date range" TextAlign="center" />}
                {ContentType === 'StartDate' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Filter statistics for a custom date range" TextAlign="center" />}
            </styles.PopoverContent>
        );
    }

    render() {
        // var { t } = this.props;

        return (
            <>
                {this.props.TryingDeliverySiteAction && <Loading />}

                {this.renderFilters()}
                {this.renderSalesByProducts()}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        TryingDeliverySiteAction: state.DeliverySite.TryingDeliverySiteAction,
        TryingDeliverySiteActionError: state.DeliverySite.TryingDeliverySiteActionError
    };
};

export default withTranslation()(connect(mapStateToProps, { DeliverySite_GetDHCs_Basic, DeliverySite_GetSalesByProducts } )(SalesComponent));