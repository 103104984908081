import styled from 'styled-components';
import { device } from '../../Config/device';

import Popover from 'react-bootstrap/Popover';

import BackgroundImage from '../../Components/BackgroundImage';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Dropdown from '../../Components/Dropdown';
import Image from '../../Components/Image';
import Input from '../../Components/Form/Input';
import LoadMore from '../../Components/Lists/LoadMore';
import PageText from '../../Components/Text/PageText';

export const AllFilters = styled.div``;

export const BlockedRestrictionsContainer = styled.div``;

export const ChooseDishesContainer = styled.div``;

export const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ColumnTaskList = styled.div`
    transition: background-color 0.2s ease;
    background-color: ${props => (props.isDraggingOver ? 'skyblue' : 'white')}
    flex-grow: 1;
    min-height: 200px;
`;

export const ColumnTitle = styled.div`
    padding: 8px;
    border: 2px solid #F2F2F2;
    border-radius: 10px;
    font-family: "Branding-Semibold";
    font-size: 22px;
    text-align: center;
`;

export const Container = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

    @media (min-width: 1168px) {
        grid-template-columns: repeat(7, minmax(100px, 140px));
    }
`;

export const DishContainer = styled.div`
    background-color: var(--white);
    border: 2px solid ${props => props.Error ? 'var(--red-bittersweet)' : 'var(--white-concrete)'};
    border-radius: 10px;
    margin: 20px 0px;
    overflow: hidden;

    &:last-child {
        margin-bottom: 0px;
    }
`;

export const DishDetailContainer = styled.div`
    background-color: var(--white-concrete-light);
    border: 5px solid var(--white-concrete-light);
    border-radius: 20px;
    padding: 15px 38px;
`;

export const DishOptionsContainer = styled.div`
    padding: 5px;
`;

export const DishOptionsContainer2 = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
`;

export const DraggableWorkoutListItem = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    > div:first-of-type {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
`;

export const FiltersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -15px;
    margin-left: -15px;
`;

export const ReadMoreText = styled(PageText)`
    text-decoration: underline;
    text-decoration-color: var(--blue-abel);
`;

export const RequiredHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    text-align: center;
`;

export const StyledCalendarIcon = styled.img`
    height: 20px;
    right: 15px;
    position: absolute;
    top: 20px;
    width: 20px;

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const SelectedDishes = styled.div`
    // align-items: center;
    // display: grid;
    // grid-column-gap: 20px;
    // grid-row-gap: 20px;
    // grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    // justify-content: center;
    // margin: 30px 0px;

    // @media and (min-width: 1168px) {
    //     grid-template-columns: repeat(7, minmax(100px, 1fr));
    // }

    display: grid;
    gap: 40px 15px;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    margin: 30px 0px;
    max-width: 100%;
`;

export const SelectedDishesContainer = styled.div``;


export const SelectedDishImage = styled(Image)`
    border-radius: 10px;
    height: 75px;
    max-width: 100%;
    object-fit: cover;
`;

export const SelectedDishImage2 = styled(BackgroundImage)`
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 75px;
    max-width: 100%;
    object-fit: cover;
`;

export const StyledClearFilters = styled(ButtonGroup)`
    height: 45px;
    margin-bottom: 15px;
    margin-left: 15px;
`;

export const StyledDishNameText = styled(PageText)`
    text-transform: uppercase;
`;

export const StyledDishPercentage = styled(Input)`
    width: 75px;

    > input {
        text-align: center;
        text-indent: -10px;
    }
`;

export const StyledDishPercentageSign = styled(PageText)`
    left: 50px;
    position: absolute;
    top: 4px;
`;

export const StyledDishRemoveIcon = styled(Image)`
    height: 20px;
    width: 20px;
`;

export const StyledDropdown = styled(Dropdown)`
    flex-grow: 1;
    margin-bottom: 15px;
    margin-left: 15px;
    max-width: 400px;
    min-width: 200px;
`;

export const StyledLoadMore = styled(LoadMore)`
    max-height: 500px;
    overflow: auto;

    > div:first-child {
        display: grid;
        grid-gap: 40px 15px;
        // grid-template-columns: repeat(1, 2fr);
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

        // @media ${device.minwidth.mobile} {
        //     grid-template-columns: repeat(2, 1fr);
        // }

        // @media ${device.minwidth.ipad} {
        //     grid-template-columns: repeat(4, 1fr);
        // }

        // @media ${device.minwidth.laptop} {
        //     grid-template-columns: repeat(7, 1fr);
        // }
    }
`;

export const StyledPopover = styled(Popover)`
    background-color: var(--white-concrete);
    border-radius: 10px;
    box-shadow: 1px 1px 8px #5A5A5A29;
    padding: 15px;
    max-width: 300px;
    width: 300px;
`;

export const StyledSearchInput = styled(Input)`
    margin-top: 10px;

    > input {
        border: 2px solid var(--white-concrete);
    }
`;

export const StyledTemplateName = styled(Input)`
    > div {
        font-family: var(--font-family-medium);
        font-size: var(--font-size-medium-3);
        margin-bottom: 20px;
        text-align: center;
    }

    > input {
        text-align: center;
        text-indent: 0px;
    }
`;

export const Template = styled.div`
    border: 2px solid var(--white-concrete);
    border-radius: 23px;
    padding: 15px;
`;

export const ToggleContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px 20px;

    :first-child {
        margin-bottom: 10px;
    }

    @media ${device.minwidth.ipad} {
        :first-child {
            margin-bottom: 0px;
            margin-right: 10px;
        }
    }
`;

export const ToggleInnerContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const TogglesContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
    }
`;

export const ViewAllPageText = styled(PageText)`
    color: var(--blue-abel);
    text-decoration: underline;

    :hover {
        cursor: pointer;
    }
`;

export const TemplateDayContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Selected ? 'var(--blue-abel)' : 'var(--white)'};
    border: 2px solid var(--white-concrete);
    border-radius: 35px;
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    display: flex;
    height: 35px;
    justify-content: center;
    margin: 0 auto;
    width: 35px;

    :hover {
        background-color: var(--blue-abel);
        color: var(--white);
        cursor: pointer;
    }

    @media ${device.minwidth.mobile} {
        border-radius: 45px;
        height: 45px;
        width: 45px;
    }
`;

export const TemplateDaysContainer = styled.div`
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(7, 1fr);
    margin: 0 auto;
    max-width: 400px;

    @media ${device.minwidth.mobile} {
        grid-gap: 9px;
    }
`;