import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Container, DateTimeUseAllDaysContainer, DateTimeUseAllDays } from './styles';

import AbelModal from '../../Components/Modal';
import AssignClients from '../../Components/AssignClients';
import MiniCalendar from '../../Components/Calendar/MiniCalendar';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

class ClientsAndDates extends React.Component {
    state = {
        CopyWorkoutNotes: 0,
        SelectedDates: [],
        SelectedGroups: {},
        SelectedUsers: [],
        SelectedUserIds: [],
        ShowClientSelectionModal: false
    };

    componentDidMount() {
        var { ClientDetails, GroupDetails } = this.props;

        if (ClientDetails && Object.entries(ClientDetails).length !== 0) {
            var SelectedUsers = [];
            var SelectedUserIds = [];

            var ClientId = +ClientDetails.ClientId;

            SelectedUserIds = [ ClientId ];
            SelectedUsers = [{ ...ClientDetails, UserId: ClientId }];

            this.setState({ SelectedUserIds, SelectedUsers });
        }

        if (GroupDetails && Object.entries(GroupDetails).length !== 0) {
            var SelectedGroups = {};

            var { GroupId, GroupImage, GroupName, GroupType } = GroupDetails;

            SelectedGroups = { [`${GroupType}_GroupId-${GroupId}`]: { AllMembers: true, GroupImage, GroupName, Members: [], RemoveMembers: [] } }

            this.setState({ SelectedGroups });
        }
    }

    onClickSubmit = () => {
        var { CopyWorkoutNotes, SelectedDates, SelectedGroups, SelectedUserIds } = this.state;

        this.props.OnSelect(SelectedUserIds, SelectedDates, SelectedGroups, CopyWorkoutNotes);
    }

    onSelectClients = ({ SelectedClients: SelectedUsers, SelectedGroups }) => {
        if (SelectedUsers.length) {
            var SelectedUserIds_New = SelectedUsers.map(User => User.UserId);

            this.setState({ SelectedGroups, SelectedUsers, SelectedUserIds: SelectedUserIds_New });
        } else this.setState({ SelectedGroups: {}, SelectedUsers: [], SelectedUserIds: [] });
    }

    onSelectDate = DayDate => {
        var { SelectedDates } = this.state;

        var SelectedIndex = SelectedDates.indexOf(DayDate);

        if (SelectedIndex === -1) this.setState({ SelectedDates: [ ...this.state.SelectedDates, DayDate ] });
        else {
            var SelectedDates_New = [ ...SelectedDates ];
            SelectedDates_New.splice(SelectedIndex, 1);

            this.setState({ SelectedDates: SelectedDates_New });
        }
    }

    onToggleCopyWorkoutNotes = () => {
        var { CopyWorkoutNotes } = this.state;
        CopyWorkoutNotes = !CopyWorkoutNotes;

        this.setState({ CopyWorkoutNotes });
    }

    onToggledShowClientSelectionModal = ShowClientSelectionModal => {
        this.setState({ ShowClientSelectionModal });
    }

    renderModalBody = () => {
        var { t } = this.props;
        var { EligibleTypes, ShowCopyNotesOption, Title } = this.props;
        var { CopyWorkoutNotes, SelectedDates, SelectedGroups, SelectedUsers, SelectedUserIds } = this.state;

        return (
            <Container>
                <PageText FontFamily="semibold" FontSize="medium-3" NoMargin Text={Title} TextAlign="center" />

                <Spacer Size="medium" />

                <AssignClients
                    EligibleTypes={EligibleTypes}
                    GetStartedEligible="Workouts"
                    // HideTitle
                    HorizontalScroll={false}
                    OnSelectClients={this.onSelectClients}
                    SelectedGroups={SelectedGroups}
                    SelectedUserIds={SelectedUserIds}
                    SelectedUsers={SelectedUsers}
                    OnToggledShowClientSelectionModal={this.onToggledShowClientSelectionModal}
                />

                {
                    ShowCopyNotesOption &&
                    <>
                        <Spacer Size="medium" />

                        <DateTimeUseAllDaysContainer>
                            <DateTimeUseAllDays onClick={this.onToggleCopyWorkoutNotes} Selected={CopyWorkoutNotes} />
                            <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={this.onToggleCopyWorkoutNotes} Text={t('copy_workout_notes_also')} TextAlign="left" />
                        </DateTimeUseAllDaysContainer>
                    </>
                }

                <Spacer Size="large" />

                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('choose_dates')} TextAlign="center" />

                <Spacer Size="medium" />

                <MiniCalendar
                    OnSelectDate={this.onSelectDate}
                    SelectedDates={SelectedDates}
                />
            </Container>
        );
    }

    render() {
        var { t } = this.props;
        var { OnHide, Show } = this.props;
        var { SelectedDates, SelectedGroups, SelectedUsers } = this.state;

        return (
            <AbelModal
                BottomButton="submit"
                BottomButtonOnClick={this.onClickSubmit}
                BottomButtonText={t('copy')}
                Disabled={SelectedDates.length === 0 || (Object.entries(SelectedGroups).length === 0 && SelectedUsers.length === 0)}
                DisplayNone={this.state.ShowClientSelectionModal}
                GA_PathName="ClientAndDates"
                Show={Show}
                Size="medium"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

ClientsAndDates.propTypes = {
    ClientDetails: PropTypes.object,
    EligibleTypes: PropTypes.string,
    GroupDetails: PropTypes.object,
    OnHide: PropTypes.func.isRequired,
    OnSelect: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired,
    ShowCopyNotesOption: PropTypes.number,
    Title: PropTypes.string.isRequired
}

export default withTranslation()(ClientsAndDates);