import React from 'react';
import { device } from '../../Config/device';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ListItem from './ListItem';

import PageText from '../../Components/Text/PageText';

const Container1 = styled.div``;

const Container2 = styled.div``;

const Container3 = styled.div`
    overflow: hidden;

    @media (max-width: 1350px) {
        display: none;
    }
`;

const Container4 = styled.div``;

const Container5 = styled.div`
    overflow: hidden;

    @media ${device.maxwidth.ipad} {
        display: none;
    }
`;

const Container6 = styled.div`
    padding-right: 10px;
    width: 322px;
`;

const Container7 = styled.div`
    padding-right: 10px;
    width: 118px;
`;

const Container8 = styled.div``;

const ListBodyContainer = styled.div`
    > div:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    > div:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    > div:not(:last-child) {
        border-bottom: 2px solid var(--white-concrete);
    }
`;

const ListContainer = styled.div``;

const ListHeaderContainer = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 4fr 1fr 3.5fr 2fr 1.5fr 4.25fr 2fr 0.25fr;
    padding: 10px;

    @media (max-width: 1350px) {
        grid-template-columns: 4fr 1fr 2fr 1.5fr 4.25fr 2fr 0.25fr;
    }

    @media ${device.maxwidth.ipad} {
        grid-template-columns: 4fr 1fr 1.5fr 4.25fr 2fr 0.25fr;
    }
`;

class TrainerClients_List extends React.Component {
    render() {
        var { t } = this.props;
        var { Clients, DeletingClientAccess_UserId, OnDeleteClient, OnToggleShowGetStartedMeals, OnUpdateMealAccess, OnUpdateWorkoutAccess, UpdatingAccess_AccessType, UpdatingAccess_ClientIndex } = this.props;

        return (
            <ListContainer className="ListContainer">
                <ListHeaderContainer className="ListHeaderContainer">
                    <Container1 className="Container1">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('Name')} TextAlign="left" />
                    </Container1>

                    <Container2 className="Container2">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('sex')} TextAlign="left" />
                    </Container2>

                    <Container3 className="Container3">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('email_with_first_char_uppercase')} TextAlign="left" />
                    </Container3>

                    <Container4 className="Container4">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('birthday')} TextAlign="left" />
                    </Container4>

                    <Container5 className="Container5">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('Date_Added_Client')} TextAlign="left" />
                    </Container5>

                    <Container6 className="Container6">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('ptadmin_contenttype_header_meals')} TextAlign="left" />
                    </Container6>

                    <Container7 className="Container7">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('ptadmin_contenttype_header_workouts')} TextAlign="left" />
                    </Container7>

                    <Container8 className="Container8" />
                </ListHeaderContainer>
                <ListBodyContainer className="ListBodyContainer">
                    {Clients.Clients.map((Client, ClientIndex) =>
                        <ListItem
                            key={ClientIndex}
                            Client={Client}
                            ClientIndex={ClientIndex}
                            DeletingClientAccess_UserId={DeletingClientAccess_UserId}
                            OnDeleteClient={() => OnDeleteClient({ ClientIndex, UserId: Client.UserId })}
                            OnShowGetStartedMeals={() => OnToggleShowGetStartedMeals({ UserDetails: Client })}
                            OnUpdateMealAccess={({ UpdateType }) => OnUpdateMealAccess({ ClientIndex, UpdateType, UserId: Client.UserId })}
                            OnUpdateWorkoutAccess={({ UpdateType }) => OnUpdateWorkoutAccess({ ClientIndex, UpdateType, UserId: Client.UserId })}
                            UpdatingAccess_AccessType={UpdatingAccess_AccessType}
                            UpdatingAccess_ClientIndex={UpdatingAccess_ClientIndex}
                        />
                    )}
                </ListBodyContainer>
            </ListContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        Clients: state.ClientDashboard.Clients
    };
};

export default withTranslation()(connect(mapStateToProps, {})(TrainerClients_List));