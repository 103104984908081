import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearAddEditGroupSettings, GetAddEditGroupSettings, InsertEditUserGroupMembers, SearchGroupManagers, SearchTrainerClients } from '../../Services/Actions';

import { StyledPaginatedList } from './styles';

import AbelModal from '../../Components/Modal';
import { GroupMemberListItem } from '../../Components/Lists/ListItems';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

class AddEditGroupManagers extends React.Component {
    _isMounted = false;

    state = {
        AddManagerIds: [],
        EditedManagerIdsList: [],
        EditedManagersList: [],
        EditingType: 'Managers',
        ExistingManagersPageNo: 1,
        ExistingManagersPageSize: 30,
        GroupManagers: [],
        RemoveManagerIds: [],
        TrainerClients: [],
        TrainerClientsPageNo: 1,
        TrainerClientsPageSize: 30,
        Loading: true
    };

    componentDidMount() {
        this._isMounted = true;

        var { GroupId } = this.props;

        this.props.GetAddEditGroupSettings({ EditingType: this.state.EditingType, GroupId }).then(() => {
            if (this._isMounted && !this.props.TryingGetAddEditGroupSettingsError) {
                var { GroupManagers, IsMoreResults: GroupManagers_IsMoreResults, TotalRecords: GroupManagers_TotalRecords } = this.props.ExistingMembers;
                var { IsMoreResults: TrainerClients_IsMoreResults, TotalRecords: TrainerClients_TotalRecords, TrainerClients } = this.props.NewMembers;

                var EditedManagerIdsList = GroupManagers.map(Member => +Member.UserId);
                var EditedManagersList = GroupManagers.map(Member => Member);

                this.setState({ GroupManagers, GroupManagers_IsMoreResults, GroupManagers_TotalRecords, EditedManagerIdsList, EditedManagersList, GroupId, Loading: false, TrainerClients, TrainerClients_IsMoreResults, TrainerClients_TotalRecords });
            }
            else this.props.OnHide();
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.ClearAddEditGroupSettings();
    }

    onClickSubmit = () => {
        var { GroupId, GroupType } = this.props;
        var { AddManagerIds, RemoveManagerIds } = this.state;

        if (!AddManagerIds.length && !RemoveManagerIds.length) this.props.OnHide();
        else {
            this.props.InsertEditUserGroupMembers({ AddUserIds: AddManagerIds, EditingType: 'Managers', GetAllMembers: true, GroupId, GroupType, RemoveUserIds: RemoveManagerIds }).then(() => {
                if (!this.props.TryingInsertEditUserGroupMembersError) this.props.OnHide();
            })
        };
    }

    onLoadExistingGroupManagers = Increment => {
        var { ExistingManagersPageNo: PageNo, ExistingManagersPageSize: PageSize, NewSearch, RemoveManagerIds, SearchValue } = this.state;
        var { GroupId } = this.props;

        if (Increment) {
            NewSearch = false;
            PageNo = PageNo + 1;
        } else {
            NewSearch = true;
            PageNo = 1;
        }

        this.setState({ NewSearch, ExistingManagersPageNo: PageNo },
            () => this.props.SearchGroupManagers({ AddToExisting: Increment, GroupId, PageNo, PageSize, ReturnData: true, UserSearch: SearchValue }).then(({ GroupManagers, IsMoreResults: GroupManagers_IsMoreResults, TotalRecords: GroupManagers_TotalRecords }) => {
                if (this._isMounted) {
                    var EditedManagerIdsList = [ ...this.state.EditedManagerIdsList, ...GroupManagers.filter(({ UserId }) => RemoveManagerIds.indexOf(+UserId) === -1).map(({ UserId }) => +UserId) ];

                    var NewGroupManagers = [];
                    
                    if (!NewSearch) NewGroupManagers = [ ...this.state.GroupManagers, ...GroupManagers ];
                    else NewGroupManagers = [ ...GroupManagers ];
                
                    this.setState({ CompletedFirstSearch: true, EditedManagerIdsList, GroupManagers: NewGroupManagers, GroupManagers_IsMoreResults, GroupManagers_TotalRecords, Loading: false });
                }
            })
        );
    }

    onLoadTrainerClients = Increment => {
        var { EditingType, TrainerClientsPageNo: PageNo, TrainerClientsPageSize: PageSize, NewSearch, SearchValue } = this.state;
        var { GroupId } = this.props;

        if (Increment) {
            NewSearch = false;
            PageNo = PageNo + 1;
        } else {
            NewSearch = true;
            PageNo = 1;
        }

        this.setState({ NewSearch, TrainerClientsPageNo: PageNo },
            () => this.props.SearchTrainerClients({ AddToExisting: Increment, EditingType, GroupId, PageNo, PageSize, ReturnData: true, UserSearch: SearchValue }).then(({ TrainerClients, IsMoreResults: TrainerClients_IsMoreResults, TotalRecords: TrainerClients_TotalRecords }) => {
                if (this._isMounted) {
                    var NewTrainerClients = [];
                    
                    if (!NewSearch) NewTrainerClients = [ ...this.state.TrainerClients, ...TrainerClients ];
                    else NewTrainerClients = [ ...TrainerClients ];
                
                    this.setState({ CompletedFirstSearch: true, TrainerClients: NewTrainerClients, TrainerClients_IsMoreResults, TrainerClients_TotalRecords, Loading: false });
                }
            })
        );
    }

    onSelectMember = (User, FromList) => {
        var UserId = +User.UserId;

        var SelectedIndex = this.state.EditedManagerIdsList.indexOf(UserId);
        var AddManagerIndex = this.state.AddManagerIds.indexOf(UserId);
        var RemoveManagerIndex = this.state.RemoveManagerIds.indexOf(UserId);

        var AddManagerIds = [ ...this.state.AddManagerIds ];
        var EditedManagerIdsList = [ ...this.state.EditedManagerIdsList ];
        var RemoveManagerIds = [ ...this.state.RemoveManagerIds ];

        if (SelectedIndex === -1) {
            EditedManagerIdsList = [ ...this.state.EditedManagerIdsList, UserId ];

            // Add Manager From Clients
                if (FromList === 'New' && AddManagerIndex === -1) AddManagerIds = [ ...AddManagerIds, UserId ];
            // Current Manager Who Needs To Be Removed From Being Removed
                else if (RemoveManagerIndex !== -1) RemoveManagerIds.splice(RemoveManagerIndex, 1);
        }
        else {
            EditedManagerIdsList.splice(SelectedIndex, 1);

            // Added Manager That Needs To Be Un-Added
                if (FromList === 'New' && AddManagerIndex !== -1) AddManagerIds.splice(AddManagerIndex, 1);
                // Existing Manager Will Be Removed
                else if (RemoveManagerIndex === -1) RemoveManagerIds = [ ...RemoveManagerIds, UserId ];
        }

        this.setState({ AddManagerIds, EditedManagerIdsList, RemoveManagerIds });
    }

    renderModalBody = () => {
        if (this.state.Loading || this.props.TryingGetAddEditGroupSettings) return <Loading />;

        var { t } = this.props;
        var { GroupManagers, GroupManagers_IsMoreResults, GroupManagers_TotalRecords, EditedManagerIdsList, ExistingManagersPageNo, ExistingManagersPageSize, TrainerClientsPageNo, TrainerClientsPageSize, TrainerClients, TrainerClients_IsMoreResults, TrainerClients_TotalRecords } = this.state;

        return (
            <div>
                {(this.props.TryingInsertEditUserGroupMembers || this.props.TryingSearchGroupManagers || this.props.TryingSearchTrainerClients) && <Loading />}

                {/* EXISTING MEMBERS */}
                    {
                        !!GroupManagers.length &&
                        <>
                            <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('group_existing_managers')} TextAlign="center" />
                    
                            <Spacer Size="small" />

                            <StyledPaginatedList
                                CompletedFirstSearch={true}
                                HideNoResults
                                HideViewCount
                                IsLoading={false}
                                ItemDescriptionType={t('ptadmin_navigation_clients').toLowerCase()}
                                ItemId="UserId"
                                ItemName="UserName"
                                ItemProps={{
                                    HasBorder: true,
                                    OnSelectMember: User => this.onSelectMember(User, 'Existing')
                                }}
                                ItemRenderer={GroupMemberListItem}
                                Items={GroupManagers}
                                HasMoreItems={!!+GroupManagers_IsMoreResults}
                                LoadMore={() => this.onLoadExistingGroupManagers(true)}
                                NewSearch={false}
                                NoItemsText={t('search_noresults')}
                                PageNo={ExistingManagersPageNo}
                                PageSize={ExistingManagersPageSize}
                                SelectedItemIds={EditedManagerIdsList}
                                TotalRecords={GroupManagers_TotalRecords}
                            />

                            <Spacer Size="medium" />
                        </>
                    }

                {/* NEW MEMBERS */}
                    <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('ptadmin_clients_add_plural')} TextAlign="center" />
                    
                    <Spacer Size="small" />

                    <StyledPaginatedList
                        CompletedFirstSearch={true}
                        HideNoResults
                        HideViewCount
                        IsLoading={false}
                        ItemDescriptionType={t('ptadmin_navigation_clients').toLowerCase()}
                        ItemId="UserId"
                        ItemName="UserName"
                        ItemProps={{
                            HasBorder: true,
                            OnSelectMember: User => this.onSelectMember(User, 'New')
                        }}
                        ItemRenderer={GroupMemberListItem}
                        Items={TrainerClients}
                        HasMoreItems={!!+TrainerClients_IsMoreResults}
                        LoadMore={() => this.onLoadTrainerClients(true)}
                        NewSearch={false}
                        NoItemsText={t('search_noresults')}
                        PageNo={TrainerClientsPageNo}
                        PageSize={TrainerClientsPageSize}
                        SelectedItemIds={EditedManagerIdsList}
                        TotalRecords={TrainerClients_TotalRecords}
                    />
            </div>
        );
    }

    render() {
        var { t } = this.props;
        var { GroupId, OnHide, Show } = this.props;
        var { GroupName } = this.state;

        return (
            <AbelModal
                BottomButton="submit"
                BottomButtonOnClick={this.onClickSubmit}
                BottomButtonText={GroupId ? t('ptadmin_groups_edit') : t('ptadmin_groups_create')}
                Disabled={(!GroupId && !GroupName) || this.props.TryingGetAddEditGroupSettings}
                GA_PathName="AddEditGroupManagers"
                Show={Show}
                Size="large"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

AddEditGroupManagers.propTypes = {
    GroupId: PropTypes.number,
    GroupName: PropTypes.string,
    GroupType: PropTypes.string,
    OnHide: PropTypes.func.isRequired,
    RefreshGroupMembers: PropTypes.func,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        ExistingMembers: state.Group.ExistingMembers,
        NewMembers: state.Group.NewMembers,

        TryingSearchTrainerClients: state.Clients.TryingSearchTrainerClients,
        TryingSearchTrainerClientsError: state.Clients.TryingSearchTrainerClientsError,

        TryingGetAddEditGroupSettings: state.Group.TryingGetAddEditGroupSettings,
        TryingGetAddEditGroupSettingsError: state.Group.TryingGetAddEditGroupSettingsError,
        TryingInsertEditUserGroupMembers: state.Group.TryingInsertEditUserGroupMembers,
        TryingInsertEditUserGroupMembersError: state.Group.TryingInsertEditUserGroupMembersError,
        TryingSearchGroupManagers: state.Group.TryingSearchGroupManagers,
        TryingSearchGroupManagersError: state.Group.TryingSearchGroupManagersError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearAddEditGroupSettings, GetAddEditGroupSettings, InsertEditUserGroupMembers, SearchGroupManagers, SearchTrainerClients } )(AddEditGroupManagers));