import styled from 'styled-components';

import Image from '../../Image';
import PageText from '../../Text/PageText';

export const ArrowIcon = styled(Image)`
    height: 18px;
`;

export const CalendarContainer = styled.div`
    margin: 0 auto;
    max-width: 350px;
    position: relative;
`;

export const CalendarHeader = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

export const DayContainer = styled.div`
    align-items: center;
    background-color: ${props => props.DisabledDay ? 'var(--white-concrete)' : props.Selected ? 'var(--blue-abel)' : 'var(--white)'};
    border: 2px solid ${props => props.DisabledDay ? 'var(--white-concrete)' : props.Selected ? 'var(--blue-abel)' : props.IsToday ? 'var(--blue-steelblue)' : 'var(--white-concrete)'};
    border-radius: 5px;
    color: ${props => props.DisabledDay ? 'var(--black)' : props.Selected ? 'var(--white)' : 'var(--black)'};
    display: flex;
    flex-direction: column;
    height: 35px;
    justify-content: center;
    margin: 0 auto;
    opacity: ${props => props.WrongMonth ? '0.7' : '1'};
    width: 35px;

    ${
        props => !props.DisabledDay &&
        `
            @media (hover:hover) {
                :hover {
                    background-color: var(--blue-abel);
                    border-color: var(--blue-abel);
                    color: var(--white);
                    cursor: pointer;
                }
            }
        `
    }
`;

export const DayHeaderText = styled(PageText)``;

export const DaysContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(7, 1fr);
    margin-top: 20px;
    min-height: 290px;
`;

export const DisabledContainer = styled.div`
    align-items: center;
    background-color: rgba(0,0,0,0.3);
    bottom: 0;
    color: var(--black);
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
`;