import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearDishes, ClearMealTemplateDetail, ClearSaveData, GetClientMealSettings, GetDishFilterSettings, GetMealTemplateDetail, InsertEditMealTemplateDetail, SaveDataInProps, SearchDishes } from '../../Services/Actions';
import history from '../../history';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { AllFilters, BlockedRestrictionsContainer, ChooseDishesContainer, ColumnContainer, ColumnTaskList, ColumnTitle, Container, DishContainer, DishDetailContainer, DishOptionsContainer, DishOptionsContainer2, DraggableWorkoutListItem, FiltersContainer, ReadMoreText, RequiredHeaderContainer, SelectedDishImage, SelectedDishImage2, SelectedDishes, SelectedDishesContainer, StyledCalendarIcon, StyledClearFilters, StyledDishNameText, StyledDishPercentage, StyledDishPercentageSign, StyledDishRemoveIcon, StyledDropdown, StyledSearchInput, StyledLoadMore, StyledPopover, StyledTemplateName, Template, TemplateDayContainer, TemplateDaysContainer, ToggleContainer, ToggleInnerContainer, TogglesContainer, ViewAllPageText } from './styles';

import CalendarOverlay from './CalendarOverlay';
import { DishListItem } from '../../Components/Lists/ListItems';
import InputRange from '../../Components/Form/InputRange';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import Toggle from '../../Components/Form/Toggle';

import DishDetail from '../../Modals/DishDetail';
import Info from '../../Modals/Info';

import Blocked from '../../Assets/Icons/Blocked.png';
import CalendarIcon from '../../Assets/Icons/Calendar.png';
import Delete_X from '../../Assets/Icons/Delete_X.png';
import FourStars from '../../Assets/Icons/4Stars.png';
import Heart from '../../Assets/Icons/Heart.png';
import New from '../../Assets/Icons/New.png';
import NoImage from '../../Assets/Icons/NoImage.png';
import ThreeStars from '../../Assets/Icons/3Stars.png';
import TwoStars from '../../Assets/Icons/2Stars.png';

import { getNewDays } from '../../Functions';

const INITIAL_STATE = {
    DishTypeIds: [],
    IsBlockedAllergiesDietTypes: false,
    IsBlockedIngredients: false,
    LastSearchedValue: '',
    LoadedImages: [],
    MadeBy: { Abel: true, You: true }, MadeByAtMininum: false,
    MaxCookingTime: 60,
    MealTypeIds: [],
    MultiRatings: [],
    PageNo: 1, PageSize: 48,
    SearchValue: ''
}

const RatingOptions = [ { RatingId: 0, RatingName: New }, { RatingId: 5, RatingName: Heart }, { RatingId: 4, RatingName: FourStars }, { RatingId: 3, RatingName: ThreeStars }, { RatingId: 2, RatingName: TwoStars }, { RatingId: 1, RatingName: Blocked } ];

class Dish extends React.Component {
    shouldComponentUpdate(nextProps) {
        // console.log('Dish');
        // console.log('nextProps: ', nextProps);
        // console.log('this.props: ', this.props);
        // console.log('----------------------');
        if (nextProps.Dish === this.props.Dish) return false;
        return true;
    }

    handleImageError = event => {
        event.target.src = NoImage;
    }

    render() {
        var UniqueId = `${this.props.DayIndex}-${this.props.DishIndex}`;

        return (
            <Draggable
                draggableId={`${this.props.Dish.DishId}-${UniqueId}`}
                index={this.props.DishIndex}
            >
                {(provided, snapshot) => (
                    <DishContainer
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        isDragging={snapshot.isDragging}
                        Error={!Number(this.props.Dish.DailyPercent)}
                    >
                        <SelectedDishImage2 ImageSrc={this.props.Dish.DishUrl} />

                        <Spacer Size="extra-small" />

                        <DishOptionsContainer>
                            <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={this.props.Dish.DishName} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <DishOptionsContainer2>
                                <StyledDishPercentage
                                    FontFamily="bold"
                                    FontSize="medium-1"
                                    NoLabel
                                    NoMargin
                                    OnChange={e => this.props.onChangeDailyPercent(e, UniqueId)}
                                    Placeholder="0"
                                    Type="number"
                                    Value={this.props.Dish.DailyPercent}
                                />

                                <StyledDishPercentageSign FontFamily="bold" FontSize="medium-1" NoMargin Text='%' TextAlign="center" />

                                <StyledDishRemoveIcon
                                    ImageSrc={Delete_X}
                                    Loading={false}
                                    OnClick={() => this.props.onRemoveDish(UniqueId)}
                                />
                            </DishOptionsContainer2>
                        </DishOptionsContainer>
                    </DishContainer>
                )}
            </Draggable>
        )
    }
}

class InnerList extends React.Component {
    shouldComponentUpdate(nextProps) {
        // console.log('InnerList');
        // console.log('nextProps: ', nextProps);
        // console.log('this.props: ', this.props);
        // console.log('----------------------');
        // if (nextProps.DishIds === this.props.DishIds) return false;
        return true;
    }

    render() {
        return (
            this.props.Dishes.map((DayDish, index) => (
                <Dish
                    key={index}
                    Dish={DayDish}
                    DayIndex={this.props.DayIndex}
                    DishIndex={index}
                    onChangeDailyPercent={(e, UniqueId) => this.props.onChangeDailyPercent(e, UniqueId)}
                    onRemoveDish={UniqueId => this.props.onRemoveDish(UniqueId)}
                />
            )
        ))
    }
}

class DayColumn extends React.Component {
    shouldComponentUpdate(nextProps) {
        // console.log('DayColumn');
        // console.log('nextProps: ', nextProps);
        // console.log('this.props: ', this.props);
        // console.log('----------------------');
        // if (nextProps.DishIds === this.props.DishIds) return false;
        return true;
    }

    render() {
        var { t } = this.props;

        return (
            <ColumnContainer>
                <ColumnTitle>{t('day')} {this.props.DayNumber}</ColumnTitle>
                <Droppable droppableId={this.props.DayId}>
                    {(provided, snapshot) => (
                        <ColumnTaskList
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            isDraggingOver={snapshot.isDraggingOver}
                        >
                            <InnerList
                                Dishes={this.props.Dishes}
                                DishIds={this.props.DishIds}
                                DayIndex={this.props.DayIndex}
                                onChangeDailyPercent={(e, UniqueId) => this.props.onChangeDailyPercent(e, UniqueId)}
                                onRemoveDish={UniqueId => this.props.onRemoveDish(UniqueId)}
                            />
                            {provided.placeholder}
                        </ColumnTaskList>
                    )}
                </Droppable>
            </ColumnContainer>
        );
    }
}

class MealTemplate extends React.Component {
    _isMounted = false;

    state = {
        ...INITIAL_STATE,
        ActiveSearch: false,
        CompletedFirstSearch: false,
        CopiedTemplateId: null,
        DayIds: [],
        Days: [],
        DisplayingFilter: null,
        IsBlockedAllergiesDietTypes: false,
        IsBlockedIngredients: false,
        Loading: true,
        NewSearch: false,
        RenewClientMealSettings: false,
        RetrievedDishFilterSettings: false,
        SelectedDates: [],
        SelectedDishIdsIndex: [], // Used in AddDishModal so that dishes turn blue when selected
        SelectedDishesIndex: [], // Used for indexOf SelectedDishes
        SelectedDishes: {}, // The list of dishes to show in the Selected Dishes area
        SelectedDishImageLoading: false,
        SelectedUserIds: [],
        SelectedUsers: [],
        ShowBlockedAllergiesModal: false,
        ShowBlockedIngredientsModal: false,
        ShowClientSelectionModal: false,
        ShowDishDetailModal: false,
        ShowDishDetailPreview: {},
        ShowReadMoreModal: false,
        TemplateDays: null,
        TemplateId: null,
        TemplateName: ''
    };

    componentDidMount() {
        this._isMounted = true;

        var { TemplateId } = this.props.match.params;

        TemplateId = +TemplateId;

        if (!Number.isInteger(TemplateId)) {
            var { TemplateToTemplateDetails, WeeklyMealsToTemplateDetails } = this.props;

            if (Object.entries(TemplateToTemplateDetails).length > 0) {
                var { CopiedTemplateId, TemplateDetails } = TemplateToTemplateDetails;
                
                // eslint-disable-next-line
                var { DayIds, Days } = this.state;
                // eslint-disable-next-line
                var { Dishes, TemplateDays, TemplateName } = TemplateDetails;

                var { NewDays, NewDayIds } = getNewDays(DayIds, Days, TemplateDays);

                for (var i = 0; i < Dishes.length; i++) {
                    var { DailyPercent, DayNumber, DishId, DishImage, DishName } = Dishes[i];
                    var NewDishId = `DishId-${DishId}`;

                    var Obj = { DailyPercent, DailyPercentError: null, DishId: NewDishId, DishName, DishUrl: DishImage };

                    NewDays[DayNumber - 1].DishIds.push(NewDishId);
                    NewDays[DayNumber - 1].Dishes.push(Obj);
                }

                this.setState({ CopiedTemplateId, DayIds: NewDayIds, Days: NewDays, TemplateDays, TemplateName, Loading: false }, () => this.onLoadDishes());
            }
            else if (Object.entries(WeeklyMealsToTemplateDetails).length > 0) {
                var { MultiSelect_Meals, WeeklyMeals } = WeeklyMealsToTemplateDetails;

                // eslint-disable-next-line
                var DayIds = []; var Days = [];

                // eslint-disable-next-line
                var i = 0; var a = 0;

                for (i = 0; i < WeeklyMeals.length; i++) {
                    var DayId = `Day-${i + 1}`;

                    for (a = 0; a < WeeklyMeals[i].dataMeals.length; a++) {
                        // eslint-disable-next-line
                        var { DishId, DishName, DishUrl, uId } = WeeklyMeals[i].dataMeals[a];

                        if (!+DishId) continue;

                        // Dish was selected by user
                            if (MultiSelect_Meals.indexOf(+uId) !== -1) {
                                var DaysIndex = DayIds.indexOf(DayId);
                                if (DaysIndex === -1) {
                                    DayIds.push(DayId);
                                    Days.push({ DayId, DishIds: [], Dishes: [] });

                                    DaysIndex = DayIds.length - 1;
                                }

                                Days[DaysIndex].DishIds.push(`DishId-${DishId}`);
                                Days[DaysIndex].Dishes.push({ DailyPercent: 0, DailyPercentError: null, DishId: `DishId-${DishId}`, DishName, DishUrl });
                            }
                    }
                }
                
                // eslint-disable-next-line
                var TemplateDays = DayIds.length;

                for (i = 0; i < TemplateDays; i++) {
                    var NewDayId = `Day-${i + 1}`;
                    DayIds[i] = `${NewDayId}`;
                    Days[i].DayId = NewDayId;
                }

                this.setState({ DayIds, Days, Loading: false, TemplateDays }, () => this.onLoadDishes());
                window.scrollTo(0,0);
            }
            else this.setState({ Loading: false }, () => this.onLoadDishes());
        }
        else {
            this.props.GetMealTemplateDetail({ TemplateId }).then(() => {
                if (!this.props.TryingGetMealTemplateDetailError) {
                    var { DayIds, Days } = this.state;
                    var { TemplateId, TemplateDetails: { TemplateDays, Dishes, TemplateName } } = this.props.MealTemplate;

                    var { NewDays, NewDayIds } = getNewDays(DayIds, Days, TemplateDays);

                    for (var i = 0; i < Dishes.length; i++) {
                        var { DailyPercent, DayNumber, DishId, DishImage, DishName } = Dishes[i];
                        var NewDishId = `DishId-${DishId}`;

                        var Obj = { DailyPercent, DailyPercentError: null, DishId: NewDishId, DishName, DishUrl: DishImage };

                        NewDays[DayNumber - 1].DishIds.push(NewDishId);
                        NewDays[DayNumber - 1].Dishes.push(Obj);
                    }

                    this.setState({ DayIds: NewDayIds, Days: NewDays, TemplateDays, TemplateId, TemplateName, Loading: false }, () => this.onLoadDishes());
                } else this.setState({ Loading: false }, () => this.onLoadDishes());
            });
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearDishes();
        if (this.state.TemplateId) this.props.ClearMealTemplateDetail();

        this.props.ClearSaveData();
    }

    imageLoaded = DishId => {
        this.setState({ LoadedImages: [ ...this.state.LoadedImages, DishId ] });
    }

    onAddToSelectedDays = ({ SelectedDays, Dish }) => {
        document.body.click();

        var { DishId } = Dish;

        var NewDishDetail = { ...Dish, DishId: `DishId-${DishId}` };
        var NewDays = [ ...this.state.Days ];

        for (var i = 0; i < SelectedDays.length; i++) {
            var DayNr = SelectedDays[i];

            NewDays[DayNr - 1].DishIds = [ ...NewDays[DayNr - 1].DishIds, `DishId-${DishId}` ];
            NewDays[DayNr - 1].Dishes = [ ...NewDays[DayNr - 1].Dishes, NewDishDetail ];
        }

        this.setState({ Days: NewDays });
    }

    onChangeDailyPercent = (event, UniqueId) => {
        // UniqueId = DayIndex-DishIndex
        var DayIndex = UniqueId.split('-')[0];
        var DishIndex = UniqueId.split('-')[1];

        var { value } = event.target;

        var ChangeDailyPercent = [ ...this.state.Days ];

        if (/^-?\d+\.?\d*$/.test(value) || Number(value) === 0) {
            if (Number(value) > 999) ChangeDailyPercent[DayIndex].Dishes[DishIndex] = { ...ChangeDailyPercent[DayIndex].Dishes[DishIndex], DailyPercentError: 'Must be less than 1000' };
            else if (Number(value) <= 0) ChangeDailyPercent[DayIndex].Dishes[DishIndex] = { ...ChangeDailyPercent[DayIndex].Dishes[DishIndex], DailyPercent: value, DailyPercentError: 'Must be greater than 0' };
            else ChangeDailyPercent[DayIndex].Dishes[DishIndex] = { ...ChangeDailyPercent[DayIndex].Dishes[DishIndex], DailyPercent: value, DailyPercentError: null };
        } else ChangeDailyPercent[DayIndex].Dishes[DishIndex] = { ...ChangeDailyPercent[DayIndex].Dishes[DishIndex], DailyPercentError: 'Must be a number' };

        this.setState({ Days: ChangeDailyPercent });
        return;
    }

    onChangeMaxCookingTime = MaxCookingTime => {
        var { MaxCookingTime: CurrentMaxCookingTime } = this.state;
        
        if (Number(CurrentMaxCookingTime) !== Number(MaxCookingTime)) this.setState({ MaxCookingTime }, () => this.onLoadDishes());
    }

    onChangeSearch = event => {
        this.setState({ SearchValue: event.target.value });
    }

    onChangeTemplateDays = TemplateDays => {
        var { DayIds, Days } = this.state;

        var { NewDays, NewDayIds } = getNewDays(DayIds, Days, TemplateDays);

        this.setState({ Days: NewDays, DayIds: NewDayIds, TemplateDays: Number(TemplateDays) });
    }

    onChangeTemplateName = event => {
        this.setState({ TemplateName: event.target.value });
    }

    onClearFilters = () => {
        var { DishTypeIds: Initial_DishTypeIds, MealTypeIds: Initial_MealTypeIds, MultiRatings: Initial_MultiRatings, PageNo, PageSize } = INITIAL_STATE;
        var { DishTypeIds: State_DishTypeIds, MealTypeIds: State_MealTypeIds, MultiRatings: State_MultiRatings } = this.state;

        var NeedsClear = ((Initial_DishTypeIds !== State_DishTypeIds) || (Initial_MealTypeIds !== State_MealTypeIds) || (Initial_MultiRatings !== State_MultiRatings))

        if (NeedsClear) this.setState({  DishTypeIds: Initial_DishTypeIds, MealTypeIds: Initial_MealTypeIds, MultiRatings: Initial_MultiRatings, PageNo, PageSize }, () => this.onLoadDishes());
    }

    onDragEnd = result => {
        var { destination, source, draggableId } = result;

        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        draggableId = draggableId.split('-')[1];
        var NewDishId = `DishId-${draggableId}`;

        var NewDishDetail;

        var StartDayIndex = this.state.DayIds.indexOf(source.droppableId);
        var FinishDayIndex = this.state.DayIds.indexOf(destination.droppableId);

        var StartDay = this.state.Days[StartDayIndex];
        var FinishDay = this.state.Days[FinishDayIndex];

        var NewDays, FinishDay_DishIds, FinishDay_Dishes, NewFinishDay;

        if (source.droppableId === 'SelectedDishes') {
            var { DishName, DishUrl } = this.state.SelectedDishes[NewDishId];
            NewDishDetail = { DishId: NewDishId, DishName, DishUrl, DailyPercent: 25, DailyPercentError: '' };

            FinishDay_DishIds = Array.from(FinishDay.DishIds);
            FinishDay_DishIds.splice(destination.index, 0, NewDishId);

            FinishDay_Dishes = Array.from(FinishDay.Dishes);
            FinishDay_Dishes.splice(destination.index, 0, NewDishDetail);

            NewFinishDay = { ...FinishDay, DishIds: FinishDay_DishIds, Dishes: FinishDay_Dishes };

            NewDays = [ ...this.state.Days.slice(0, FinishDayIndex), NewFinishDay, ...this.state.Days.slice(FinishDayIndex + 1) ];

            this.setState({ Days: NewDays });
        } else {
            // eslint-disable-next-line
            var { DishName, DishUrl, DailyPercent } = StartDay.Dishes[source.index];
            NewDishDetail = { DishId: NewDishId, DishName, DishUrl, DailyPercent, DailyPercentError: '' };

            if (StartDayIndex === FinishDayIndex) {
                var SameDay_DishIds = Array.from(StartDay.DishIds);
                SameDay_DishIds.splice(source.index, 1);
                SameDay_DishIds.splice(destination.index, 0, NewDishId);

                var SameDay_Dishes = Array.from(StartDay.Dishes);
                SameDay_Dishes.splice(source.index, 1);
                SameDay_Dishes.splice(destination.index, 0, NewDishDetail);
                
                var NewSameDay = { ...StartDay, DishIds: SameDay_DishIds, Dishes: SameDay_Dishes };

                NewDays = [ ...this.state.Days.slice(0, StartDayIndex), NewSameDay, ...this.state.Days.slice(StartDayIndex + 1) ];

                this.setState({ Days: NewDays });
                return;
            }

            var StartDay_DishIds = Array.from(StartDay.DishIds);
            StartDay_DishIds.splice(source.index, 1);

            var StartDay_Dishes = Array.from(StartDay.Dishes);
            StartDay_Dishes.splice(source.index, 1);

            var NewStartDay = { ...StartDay, DishIds: StartDay_DishIds, Dishes: StartDay_Dishes };

            NewDays = [ ...this.state.Days.slice(0, StartDayIndex), NewStartDay, ...this.state.Days.slice(StartDayIndex + 1) ];

            FinishDay_DishIds = Array.from(FinishDay.DishIds);
            FinishDay_DishIds.splice(destination.index, 0, NewDishId);

            FinishDay_Dishes = Array.from(FinishDay.Dishes);
            FinishDay_Dishes.splice(destination.index, 0, NewDishDetail);

            NewFinishDay = { ...FinishDay, DishIds: FinishDay_DishIds, Dishes: FinishDay_Dishes };

            NewDays = [ ...NewDays.slice(0, FinishDayIndex), NewFinishDay, ...NewDays.slice(FinishDayIndex + 1) ];

            this.setState({ Days: NewDays });
        }
    }

    onInsertEditMealTemplate = () => {
        var { CopiedTemplateId, Days, TemplateDays, TemplateId, TemplateName } = this.state;
        var TemplateDishes = '';

        for (var i = 0; i < Days.length; i++) {
            var DayNumber = i + 1;

            for (var a = 0; a < Days[i].Dishes.length; a++) {
                if (TemplateDishes !== '') TemplateDishes += ';';
                
                var DishOrder = a + 1;
                var DishScale = 0;
                var { DishId, DailyPercent } = Days[i].Dishes[a];
                DishId = `${DishId}`.indexOf('DishId-') > -1 ? +DishId.split('DishId-')[1] : +DishId;
                // DishId = DishId.split('-')[1]; // DishId-47 --> 47

                TemplateDishes += `${DishId},${DishScale},${DailyPercent},${DayNumber},${DishOrder}`;
            }
        }
        
        this.props.InsertEditMealTemplateDetail({ CopiedTemplateId, TemplateDays, TemplateDishes, TemplateId, TemplateName }).then(() => {
            if (!this.props.TryingInsertEditMealTemplateDetailError) history.push({ pathname: `/library`, search: `?tab=meal-templates` });
        });
    }

    onKeyPress = ({ key }) => {
        if (key === 'Enter' && !this.props.TryingSearchMealTemplates) {
            var { SearchValue, LastSearchValue } = this.state;

            if (SearchValue !== LastSearchValue) {
                this.setState({ PageNo: 1, PageSize: 48, LastSearchValue: SearchValue }, () => {
                    this.onLoadDishes();
                });
            }
        }
    }

    onLoadDishes = Increment => {
        var { DishTypeIds, IsBlockedAllergiesDietTypes, IsBlockedIngredients, MadeBy, MaxCookingTime, MealTypeIds, MultiRatings, NewSearch, PageNo, PageSize, SearchValue, SelectedUserIds } = this.state;

        if (Increment) {
            NewSearch = false;
            PageNo = PageNo + 1;
        } else NewSearch = true;

        var ClientId = SelectedUserIds.length === 1 ? SelectedUserIds[0] : null;
        MultiRatings = SelectedUserIds.length === 1 ? MultiRatings : [];

        var DishCategoryIds = [];
        if (MadeBy.Abel) DishCategoryIds.push(1);
        if (MadeBy.You) DishCategoryIds.push(2);

        this.setState({ ActiveSearch: true, NewSearch, PageNo }, () => this.props.SearchDishes({ AddToExisting: Increment, ClientId, DishCategoryIds, DishName: SearchValue, DishTypeIds, IsBlockedAllergiesDietTypes, IsBlockedIngredients, MaxCookingTime, MealTypeIds, MultiRatings, PageNo, PageSize }).then(() => this._isMounted ? this.setState({ ActiveSearch: false, CompletedFirstSearch: true, Loading: false }) : null));
    }

    onLoadedSelectedDishImage = () => {
        this.setState({ SelectedDishImageLoading: false });
    }

    onRetrieveDishFilterSettings = () => {
        this.props.GetDishFilterSettings().then(() => {
            if (this._isMounted && !this.props.TryingGetDishFilterSettingsError) this.setState({ RetrievedDishFilterSettings: true });
        })
    }

    onRemoveDish = UniqueId => {
        // UniqueId = DayIndex-DishIndex
        var DayIndex = UniqueId.split('-')[0];
        var DishIndex = UniqueId.split('-')[1];

        var RemoveDish = this.state.Days[DayIndex];

        var RemoveDish_DishIds = Array.from(RemoveDish.DishIds);
        RemoveDish_DishIds.splice(DishIndex, 1);

        var RemoveDish_Dishes = Array.from(RemoveDish.Dishes);
        RemoveDish_Dishes.splice(DishIndex, 1);

        var NewRemoveDish_Day = { ...RemoveDish, DishIds: RemoveDish_DishIds, Dishes: RemoveDish_Dishes };

        var RemoveDish_Days = Array.from(this.state.Days);
        RemoveDish_Days.splice(DayIndex, 1, NewRemoveDish_Day);

        this.setState({ Days: RemoveDish_Days });
    }

    onSelectDish = ({ DishId, DishName, DishUrl }) => {
        DishId = Number(DishId);

        var SelectedDishId = `DishId-${DishId}`;
        var SelectedDishesIndex_Index = this.state.SelectedDishesIndex.indexOf(SelectedDishId);

        var NewSelectedDishes = { ...this.state.SelectedDishes };
        var NewSelectedDishesIndex = [ ...this.state.SelectedDishesIndex ];
        var NewSelectedDishIdsIndex = [ ...this.state.SelectedDishIdsIndex ];

        // Remove Dish From Selected Dishes
        if (SelectedDishesIndex_Index > -1) {
            delete NewSelectedDishes[SelectedDishId];
            NewSelectedDishesIndex = [ ...this.state.SelectedDishesIndex.slice(0, SelectedDishesIndex_Index), ...this.state.SelectedDishesIndex.slice(SelectedDishesIndex_Index + 1) ]
            NewSelectedDishIdsIndex = [ ...this.state.SelectedDishIdsIndex.slice(0, SelectedDishesIndex_Index), ...this.state.SelectedDishIdsIndex.slice(SelectedDishesIndex_Index + 1) ]
        } else {
            NewSelectedDishes = { ...NewSelectedDishes, [SelectedDishId]: { DishId, DishName, DishUrl, DailyPercent: 25 } };
            NewSelectedDishesIndex = [ ...NewSelectedDishesIndex, SelectedDishId ];
            NewSelectedDishIdsIndex = [ ...NewSelectedDishIdsIndex, DishId ];
        }

        this.setState({ SelectedDishes: NewSelectedDishes, SelectedDishesIndex: NewSelectedDishesIndex, SelectedDishIdsIndex: NewSelectedDishIdsIndex });
    }

    onSelectDishType = (event, DishTypeId, DishTypeIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = DishTypeIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return {
                    DishTypeIds: [ ...prevState.DishTypeIds, ...[ DishTypeId ] ],
                }
            }, () => this.onLoadDishes());
        } else {
            this.setState((prevState) => {
                return {
                    DishTypeIds: [ ...prevState.DishTypeIds.slice(0, DishTypeIndex), ...prevState.DishTypeIds.slice(DishTypeIndex + 1) ],
                }
            }, () => this.onLoadDishes());
        }
    }

    onSelectMealType = (event, MealTypeId, MealTypeIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = MealTypeIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return {
                    MealTypeIds: [ ...prevState.MealTypeIds, ...[ MealTypeId ] ],
                }
            }, () => this.onLoadDishes());
        } else {
            this.setState((prevState) => {
                return {
                    MealTypeIds: [ ...prevState.MealTypeIds.slice(0, MealTypeIndex), ...prevState.MealTypeIds.slice(MealTypeIndex + 1) ],
                }
            }, () => this.onLoadDishes());
        }
    }

    onSelectRating = (event, Rating, RatingIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = RatingIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return {
                    MultiRatings: [ ...prevState.MultiRatings, ...[ Rating ] ],
                }
            }, () => this.onLoadDishes());
        } else {
            this.setState((prevState) => {
                return {
                    MultiRatings: [ ...prevState.MultiRatings.slice(0, RatingIndex), ...prevState.MultiRatings.slice(RatingIndex + 1) ],
                }
            }, () => this.onLoadDishes());
        }
    }

    OnToggleDropdown = (DisplayingFilter, ToggleStatus) => {
        this.setState({ DisplayingFilter: ToggleStatus ? DisplayingFilter : null });
    }

    onToggleShowBlockedAllergiesModal = ShowBlockedAllergiesModal => {
        var { RetrievedDietaryRestrictions } = this.props;
        var { RenewClientMealSettings, SelectedUserIds } = this.state;

        this.setState({ ShowBlockedAllergiesModal }, () => {
            if ((RenewClientMealSettings || !RetrievedDietaryRestrictions) && ShowBlockedAllergiesModal) this.props.GetClientMealSettings({ ClientId: Number(SelectedUserIds[0]) }).then(() => this.setState({ RenewClientMealSettings: false }));
        });
    }

    onToggleShowBlockedIngredientsModal = ShowBlockedIngredientsModal => {
        var { RetrievedDietaryRestrictions } = this.props;
        var { RenewClientMealSettings, SelectedUserIds } = this.state;

        this.setState({ ShowBlockedIngredientsModal }, () => {
            if ((RenewClientMealSettings || !RetrievedDietaryRestrictions) && ShowBlockedIngredientsModal) this.props.GetClientMealSettings({ ClientId: Number(SelectedUserIds[0]) }).then(() => this.setState({ RenewClientMealSettings: false }));
        });
    }

    onToggleShowClientSelectionModal = ShowClientSelectionModal => {
        this.setState({ ShowClientSelectionModal });
    }

    onToggleShowDishDetailModal = (ShowDishDetailModal, Dish) => {
        this.setState({ ShowDishDetailModal, ShowDishDetailPreview: Dish || {} });
    }

    onToggleShowReadMoreModal = ShowReadMoreModal => {
        this.setState({ ShowReadMoreModal });
    }

    renderAddDishes = () => {
        var { t } = this.props;
        var { Device } = this.props;

        return (
            <>
                <RequiredHeaderContainer>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-3" Text={t('template_adddishes')} />
                    <PageText ElementType="span" FontColor="red-bittersweet" FontFamily="medium" FontSize="medium-3" Text=" *" />
                </RequiredHeaderContainer>

                {this.renderFilters()}

                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'large' : 'medium'} />

                {this.renderDishesList()}

                <Spacer Size="small" />

                {this.renderTemplate()}
            </>
        );
    }

    renderBlockedAllergiesInformation = () => {
        var { t } = this.props;
        var { Device, ClientMealSettings: { Allergies, DietTypes }, TryingGetClientMealSettings } = this.props;
        var ClientAllergies = [];
        var ClientDietTypes = [];

        if (TryingGetClientMealSettings) return null;

        return (
            <BlockedRestrictionsContainer>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('WorkoutPlanSettings_Allergies')} TextAlign="center" />

                <Spacer Size={Device === 'ipad' || Device === 'laptop' ? 'medium' : 'small'} />

                {
                    Allergies.map(({ AllergyName, IsSelected }) => {
                        if (!Number(IsSelected)) return null;

                        ClientAllergies.push(AllergyName);
                        return null;
                    })
                }
                {
                    DietTypes.map(({ DietTypeId, DietTypeName, IsSelected }) => {
                        if (Number(DietTypeId) !== 3 || !Number(IsSelected)) return null;

                        ClientDietTypes.push(DietTypeName);
                        return null;
                    })
                }
                
                <div>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('WorkoutPlanSettings_Allergies')}:`} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={ClientAllergies.length ? ClientAllergies.join(', ') : 'None'} />
                </div>

                <div>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('WorkoutPlanSettings_Dietary_restrictions')}:`} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={ClientDietTypes.length ? ClientDietTypes.join(', ') : 'None'} />
                </div>

                <Spacer Size={Device === 'ipad' || Device === 'laptop' ? 'medium' : 'small'} />

                <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('ptadmin_filter_allergies_description')} TextAlign="center" />
            </BlockedRestrictionsContainer>
        );
    }

    renderBlockedAllergiesModal = () => {
        var { TryingGetClientMealSettings } = this.props;
        var { ShowBlockedAllergiesModal } = this.state;

        if (ShowBlockedAllergiesModal) {
            return (
                <Info
                    BottomButton="cancel"
                    BottomButtonOnClick={() => this.onToggleShowBlockedAllergiesModal(false)}
                    InformationRenderer={this.renderBlockedAllergiesInformation}
                    Loading={TryingGetClientMealSettings}
                    OnHide={() => this.onToggleShowBlockedAllergiesModal(false)}
                    Show={ShowBlockedAllergiesModal}
                />
            );
        }
    }

    renderBlockedIngredientsInformation = () => {
        var { t } = this.props;
        var { Device, ClientMealSettings: { BlockedIngredients }, TryingGetClientMealSettings } = this.props;
        var ClientBlockedIngredients = [];

        if (TryingGetClientMealSettings) return null;

        return (
            <BlockedRestrictionsContainer>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('WorkoutPlanSettings_Blocked_ingredients')} TextAlign="center" />

                <Spacer Size={Device === 'ipad' || Device === 'laptop' ? 'medium' : 'small'} />

                {
                    BlockedIngredients.map(({ FoodName }) => {

                        ClientBlockedIngredients.push(FoodName);
                        return null;
                    })
                }
                
                <div>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('WorkoutPlanSettings_Blocked_ingredients')}:`} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={ClientBlockedIngredients.length ? ClientBlockedIngredients.join(', ') : 'None'} />
                </div>

                <Spacer Size={Device === 'ipad' || Device === 'laptop' ? 'medium' : 'small'} />

                <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('ptadmin_filter_blockedingredients_description')} TextAlign="center" />
            </BlockedRestrictionsContainer>
        );
    }

    renderBlockedIngredientsModal = () => {
        var { TryingGetClientMealSettings } = this.props;
        var { ShowBlockedIngredientsModal } = this.state;

        if (ShowBlockedIngredientsModal) {
            return (
                <Info
                    BottomButton="cancel"
                    BottomButtonOnClick={() => this.onToggleShowBlockedIngredientsModal(false)}
                    InformationRenderer={this.renderBlockedIngredientsInformation}
                    Loading={TryingGetClientMealSettings}
                    OnHide={() => this.onToggleShowBlockedIngredientsModal(false)}
                    Show={ShowBlockedIngredientsModal}
                />
            );
        }
    }

    renderDishDetailModal = () => {
        var { ShowDishDetailModal, ShowDishDetailPreview } = this.state;

        if (ShowDishDetailModal) {
            return (
                <DishDetail
                    DishDetail_Preview={ShowDishDetailPreview}
                    OnHide={() => this.onToggleShowDishDetailModal(false)}
                    Show={ShowDishDetailModal}
                />
            );
        }
    }

    renderDishesList = () => {
        var { t } = this.props;
        var { Device, DishList, DishList_TotalRecords, IsMoreDishes, TryingSearchDishes } = this.props;
        var { ActiveSearch, CompletedFirstSearch, NewSearch, PageNo, PageSize, SelectedDishIdsIndex, SelectedUserIds } = this.state;

        var IsFlexed = Device === 'laptop' ? 6 : Device === 'ipad' ? 3 : Device === 'mobile' ? 3 : 2;
        var ShowRating = SelectedUserIds.length === 1 ? true : false;

        return (
            <StyledLoadMore
                ActiveSearch={ActiveSearch}
                CompletedFirstSearch={CompletedFirstSearch}
                IsFlexed={IsFlexed}
                IsLoading={TryingSearchDishes}
                ItemDescriptionType={t('ptadmin_dishes_plural').toLowerCase()}
                ItemHeight={145}
                ItemId="DishId"
                ItemName="DishName"
                ItemProps={{ SelectDish: Dish => this.onSelectDish(Dish), ShowRating, ViewDish: Dish => this.onToggleShowDishDetailModal(true, Dish) }}
                ItemRenderer={DishListItem}
                Items={DishList}
                HasMoreItems={IsMoreDishes}
                LoadMore={() => this.onLoadDishes(true)}
                NewSearch={NewSearch}
                NoItemsText={t('search_noresults')}
                PageNo={PageNo}
                PageSize={PageSize}
                SelectedItemIds={SelectedDishIdsIndex}
                TotalRecords={DishList_TotalRecords}
            />
        );
    }

    renderFilters = () => {
        var { t } = this.props;
        var { Device, DishFilterSettings: { DishTypes, MealTypes }, TryingGetDishFilterSettings, TryingSearchDishes } = this.props;
        var { DishTypeIds, DisplayingFilter, IsBlockedAllergiesDietTypes, IsBlockedIngredients, MealTypeIds, MultiRatings, RetrievedDishFilterSettings, SearchValue, SelectedUserIds } = this.state;

        var ShowRatings = SelectedUserIds.length === 1;

        return (
            <AllFilters>
                {
                    ShowRatings ?
                    <>
                        <TogglesContainer>
                            <ToggleContainer>
                                <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('filter_blockallergies')} TextAlign="left" />

                                <Spacer Size="extra-small" />

                                <ToggleInnerContainer>
                                    <ViewAllPageText FontFamily="semibold" FontSize="medium-1" NoMargin OnClick={() => this.onToggleShowBlockedAllergiesModal(true)} Text={t('view_all')} TextAlign="center" />

                                    <Toggle
                                        Checked={IsBlockedAllergiesDietTypes}
                                        OffLabel={t('off')}
                                        OnChange={event => this.onChangeToggle(event, 'IsBlockedAllergiesDietTypes')}
                                        OnLabel={t('on')}
                                    />
                                </ToggleInnerContainer>
                            </ToggleContainer>
                            <ToggleContainer>
                            <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('filter_blockingredients')} TextAlign="center" />

                                <Spacer Size="extra-small" />

                                <ToggleInnerContainer>
                                    <ViewAllPageText FontFamily="semibold" FontSize="medium-1" NoMargin OnClick={() => this.onToggleShowBlockedIngredientsModal(true)} Text={t('view_all')} TextAlign="center" />

                                    <Toggle
                                        Checked={IsBlockedIngredients}
                                        OffLabel={t('off')}
                                        OnChange={event => this.onChangeToggle(event, 'IsBlockedIngredients')}
                                        OnLabel={t('on')}
                                    />
                                </ToggleInnerContainer>
                            </ToggleContainer>
                        </TogglesContainer>

                        <Spacer Size="extra-small" />
                    </>
                :
                    null
                }
                <FiltersContainer>
                    {
                        ShowRatings ?
                        <StyledDropdown
                            Disabled={TryingSearchDishes}
                            ForceClose={DisplayingFilter !== 'Ratings'}
                            ItemId="RatingId"
                            ItemIsIcon
                            ItemName="RatingName"
                            Items={RatingOptions}
                            OnSelectItem={(event, RatingId, RatingIndex) => this.onSelectRating(event, RatingId, RatingIndex)}
                            OnToggleDropdown={ToggleStatus => this.OnToggleDropdown('Ratings', ToggleStatus)}
                            SelectedItemIds={MultiRatings}
                            Title={t('ratings')}
                        />
                    :
                        null
                    }
                    <StyledDropdown
                        Disabled={TryingSearchDishes}
                        ForceClose={DisplayingFilter !== 'MealTypes'}
                        ItemId="MealTypeId"
                        ItemName="MealTypeName"
                        Items={MealTypes}
                        Loading={TryingGetDishFilterSettings}
                        NeedsToLoad={!RetrievedDishFilterSettings}
                        NeedsToLoadFunc={this.onRetrieveDishFilterSettings}
                        OnSelectItem={(event, MealTypeId, MealTypeIndex) => this.onSelectMealType(event, MealTypeId, MealTypeIndex)}
                        OnToggleDropdown={ToggleStatus => this.OnToggleDropdown('MealTypes', ToggleStatus)}
                        SelectedItemIds={MealTypeIds}
                        Title={t('MealSection_Meal_types')}
                    />
                    <StyledDropdown
                        Disabled={TryingSearchDishes}
                        ForceClose={DisplayingFilter !== 'DishTypes'}
                        ItemId="DishTypeId"
                        ItemName="DishTypeName"
                        Items={DishTypes}
                        Loading={TryingGetDishFilterSettings}
                        NeedsToLoad={!RetrievedDishFilterSettings}
                        NeedsToLoadFunc={this.onRetrieveDishFilterSettings}
                        OnSelectItem={(event, DishTypeId, DishTypeIndex) => this.onSelectDishType(event, DishTypeId, DishTypeIndex)}
                        OnToggleDropdown={ToggleStatus => this.OnToggleDropdown('DishTypes', ToggleStatus)}
                        SelectedItemIds={DishTypeIds}
                        ShowRatings={ShowRatings}
                        Title={t('MealSection_Dish_type')}
                    />
                    <StyledDropdown
                        ContentRenderer={this.renderMaxCookingTime}
                        Disabled={TryingSearchDishes}
                        ForceClose={DisplayingFilter !== 'CookingTime'}
                        IsList={false}
                        NeedsToLoad={false}
                        OnToggleDropdown={ToggleStatus => this.OnToggleDropdown('CookingTime', ToggleStatus)}
                        SelectedItemIds={[ 1 ]}
                        ShowRatings={ShowRatings}
                        Title={t('MealPlan_Cooking_Time')}
                    />
                    
                    <StyledClearFilters
                        Buttons={[{ FontFamily: 'semibold', FontSize: 'medium-1', OnClick: this.onClearFilters, Title: t('clear_filters') }]}
                        ContainerWidth={Device === 'mobile_small' ? '100%' : null}
                    />
                </FiltersContainer>

                <StyledSearchInput
                    FontSize="medium-2"
                    NoLabel
                    OnChange={this.onChangeSearch}
                    OnKeyPress={this.onKeyPress}
                    Placeholder={t('search_with_first_char_uppercase_dots')}
                    Size="medium"
                    Type="text"
                    Value={SearchValue}
                />
            </AllFilters>
        );
    }

    renderMaxCookingTime = () => {
        var { MaxCookingTime } = this.state;

        return (
            <InputRange
                MaxValue={60}
                MinValue={5}
                OnChangeComplete={MaxCookingTime => this.onChangeMaxCookingTime(MaxCookingTime, true)}
                RenderText={Value => this.renderMaxCookingTimeText(Value)}
                Step={5}
                Value={MaxCookingTime}
            />
        );
    }

    renderMaxCookingTimeText = MaxCookingTime => {
        var { t } = this.props;

        return (Number(MaxCookingTime) === 60 ? '60+' : `${MaxCookingTime}`) + ' ' + t('min_str');
    }

    renderReadMoreModal = () => {
        var { ShowReadMoreModal } = this.state;

        if (ShowReadMoreModal) {
            return (
                <Info
                    BottomButton="cancel"
                    BottomButtonOnClick={() => this.onToggleShowReadMoreModal(false)}
                    InformationRenderer={this.renderReadMoreModalInformation}
                    OnHide={() => this.onToggleShowReadMoreModal(false)}
                    Show={ShowReadMoreModal}
                />
            );
        }
    }

    renderReadMoreModalInformation = () => {
        var { t } = this.props;

        var Part1 = t('template_readmore').split('/n')[0] || '';
        var Part2 = t('template_readmore').split('/n')[1] || '';

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={Part1} TextAlign="center" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={Part2} TextAlign="center" />
            </>
        );
    }

    renderSelectedDishes = () => {
        var { t } = this.props;
        var { Days, SelectedDishesIndex, TemplateDays } = this.state;

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <SelectedDishesContainer>
                    <Droppable droppableId={'SelectedDishes'} isDropDisabled>
                        {provided => (
                            <div
                                className="template-dish-selections-container margintop50 border2px"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                <PageText OnClick={() => console.log('State: ', this.state)} FontFamily="medium" FontSize="medium-2" NoMargin Text={`${t('selected_dishes')} (${SelectedDishesIndex.length})`} TextAlign="center" />

                                <Spacer Size="small" />

                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('template_meals_instructions')} TextAlign="center" />
                                <ReadMoreText FontColor="blue-abel" FontFamily="medium" FontSize="medium-1" NoMargin OnClick={() => this.onToggleShowReadMoreModal(true)} Text={t('read_more')} TextAlign="center" />

                                <Spacer Size="small" />

                                <SelectedDishes className="SelectedDishes">
                                    {
                                        this.state.SelectedDishesIndex.map((SelectedDishId, index) => {
                                            var { DishId, DishName, DishUrl } = this.state.SelectedDishes[SelectedDishId];
                                            var ImageLoading = this.state.LoadedImages.indexOf(DishId) === -1;

                                            return (
                                                <Draggable
                                                    draggableId={`DishId-${DishId}`}
                                                    index={index}
                                                    key={`${DishId}_${index}`}
                                                >
                                                    {provided => (
                                                        <div
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            <DraggableWorkoutListItem className="DraggableWorkoutListItem">                                                                
                                                                <OverlayTrigger trigger="click" placement="left" rootClose overlay={
                                                                    <StyledPopover>
                                                                        <CalendarOverlay Days={Days} NumDays={TemplateDays} OnSelectDays={SelectedDays => this.onAddToSelectedDays({ SelectedDays, Dish: this.state.SelectedDishes[SelectedDishId] })} DishId={DishId} />
                                                                    </StyledPopover>
                                                                }>
                                                                    <StyledCalendarIcon alt="ABEL" src={CalendarIcon} />
                                                                </OverlayTrigger>

                                                                <DishDetailContainer className="DishDetailContainer">
                                                                    <StyledDishNameText FontFamily="semibold" FontSize="small" NoMargin Text={DishName} TextAlign="center"/>

                                                                    <Spacer Size="extra-extra-small" />

                                                                    <SelectedDishImage Alt={DishName} ImageSrc={DishUrl} Loading={ImageLoading} OnLoaded={() => this.imageLoaded(DishId)} ShowOverlay />
                                                                </DishDetailContainer>
                                                            </DraggableWorkoutListItem>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })
                                    }
                                </SelectedDishes>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </SelectedDishesContainer>
                <Container>
                    {this.state.Days.map(({ DayId, DishIds, Dishes }, index) => {
                        return (
                            <DayColumn
                                key={DayId}
                                DayId={DayId}
                                DayIndex={index}
                                DishIds={DishIds}
                                Dishes={Dishes}
                                DayNumber={index + 1}
                                onChangeDailyPercent={(e, UniqueId) => this.onChangeDailyPercent(e, UniqueId)}
                                onRemoveDish={UniqueId => this.onRemoveDish(UniqueId)}
                                t={t}
                            />
                        )
                    })}
                </Container>
            </DragDropContext>
        );
    }

    renderTemplate = () => {
        return (
            <Template>
                <ChooseDishesContainer>
                    {this.renderSelectedDishes()}
                </ChooseDishesContainer>
            </Template>
        );
    }

    renderTemplateDays = () => {
        var { t } = this.props;
        var TemplateDayOptions = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28 ];

        return (
            <>
                <RequiredHeaderContainer>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-3" Text={t('template_length')} />
                    <PageText ElementType="span" FontColor="red-bittersweet" FontFamily="medium" FontSize="medium-3" Text=" *" />
                </RequiredHeaderContainer>

                <Spacer Size="small" />

                <TemplateDaysContainer>
                    {
                        TemplateDayOptions.map(TemplateDay => {
                            var Selected = TemplateDay <= this.state.TemplateDays ? 1 : 0;

                            return (
                                <TemplateDayContainer key={TemplateDay} onClick={() => this.onChangeTemplateDays(TemplateDay)} Selected={Selected}>
                                    {TemplateDay}
                                </TemplateDayContainer>
                            );
                        })
                    }
                </TemplateDaysContainer>
            </>
        );
    }

    renderTemplateName = () => {
        var { t } = this.props;
        var { TemplateName } = this.state;

        return (
            <>
                <RequiredHeaderContainer>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-3" Text={t('MealPlan_name_your_template')} />
                    <PageText ElementType="span" FontColor="red-bittersweet" FontFamily="medium" FontSize="medium-3" Text=" *" />
                </RequiredHeaderContainer>

                <StyledTemplateName
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeTemplateName}
                    Placeholder={t('MealPlan_name_your_template')}
                    Size="medium"
                    Type="text"
                    Value={TemplateName}
                />
            </>
        );
    }

    render() {
        var { t } = this.props;
        var { Device, TryingGetMealTemplateDetail, TryingInsertEditMealTemplateDetail } = this.props;
        var { Days, TemplateDays, TemplateId, TemplateName } = this.state;

        var ActionText = TemplateId ? t('MealPlan_Edit_meal_template') : t('MealPlan_Create_meal_template');

        var Breadcrumbs = [{ Name: t('ptadmin_navigation_library'), LinkURL: 'library' }, { Name: ActionText }];

        var Disabled = TemplateDays && TemplateName ? false : true;
        if (!Disabled) {
            for (var i = 0; i < Days.length; i++) {
                if (!Days[i].DishIds.length) { Disabled = true; break; }
                
                for (var a = 0; a < Days[i].Dishes.length; a++) {
                    if (!Number(Days[i].Dishes[a].DailyPercent)) { Disabled = true; break; }
                }
            }
        }

        var SubmitButtonProps = {
            Disabled,
            OnClick: this.onInsertEditMealTemplate,
            Title: ActionText,
        }

        return (
            <>
                {TryingGetMealTemplateDetail || TryingInsertEditMealTemplateDetail ? <Loading /> : null}

                <Page
                    renderBreadcrumbs={Breadcrumbs}
                    renderSubmitButton={SubmitButtonProps}
                >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={ActionText} OnClick={() => console.log('state: ', this.state)} />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderTemplateDays()}
                    
                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderTemplateName()}
                    
                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderAddDishes()}
                </Page>

                {this.renderBlockedAllergiesModal()}
                {this.renderBlockedIngredientsModal()}
                {this.renderDishDetailModal()}
                {this.renderReadMoreModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        DishFilterSettings: state.Dishes.DishFilterSettings,
        DishList: state.Dishes.DishList,
        DishList_TotalRecords: state.Dishes.DishList_TotalRecords,
        IsMoreDishes: state.Dishes.IsMoreDishes,
        MealTemplate: state.Templates.MealTemplate,

        ClientMealSettings: state.Client.ClientMealSettings,
        RetrievedDietaryRestrictions: state.Client.RetrievedDietaryRestrictions,
        TryingGetClientMealSettings: state.Client.TryingGetClientMealSettings,
        TryingGetClientMealSettingsError: state.Client.TryingGetClientMealSettingsError,

        TryingGetDishFilterSettings: state.Dishes.TryingGetDishFilterSettings,
        TryingGetDishFilterSettingsError: state.Dishes.TryingGetDishFilterSettingsError,
        TryingSearchDishes: state.Dishes.TryingSearchDishes,
        TryingSearchDishesError: state.Dishes.TryingSearchDishesError,

        TemplateToTemplateDetails: state.SaveData.TemplateToTemplateDetails,
        WeeklyMealsToTemplateDetails: state.SaveData.WeeklyMealsToTemplateDetails,

        TryingGetMealTemplateDetail: state.Templates.TryingGetMealTemplateDetail,
        TryingGetMealTemplateDetailError: state.Templates.TryingGetMealTemplateDetailError,
        TryingInsertEditMealTemplateDetail: state.Templates.TryingInsertEditMealTemplateDetail,
        TryingInsertEditMealTemplateDetailError: state.Templates.TryingInsertEditMealTemplateDetailError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearDishes, ClearMealTemplateDetail, ClearSaveData, GetClientMealSettings, GetDishFilterSettings, GetMealTemplateDetail, InsertEditMealTemplateDetail, SaveDataInProps, SearchDishes } )(MealTemplate));