import React from 'react';
import styled from 'styled-components';

// const { useState } = React;

import PageText from '../Components/Text/PageText';
import Spacer from '../Components/Spacer';

// import DashJS from './DashJS';
import HLSJS from './HLSJS';
import VideoJS from './VideoJS';

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    margin: 20px;
    max-width: 100%;
`;

const videoJsOptions = {
    autoplay: true,
    controls: true,
    // sources: [{
    //     src1: 'https://abel-dev-videos-output.s3.amazonaws.com/testing/MP4/Abel_Intro_3_1920x1080.mp4',
    //     src: 'https://abel-dev-videos-output.s3.amazonaws.com/testing/HLS/Abel_Intro_3_1920x1080.m3u8',
    //     // type: 'video/mp4'
    // }]
}

var Videos = [
    // { Type: 'HLS', Title: 'Horizontal Small', Url: 'https://vod-watchfolder-ridge-robinson.s3.amazonaws.com/outputs/test2/horizontal/hls/Horizontal_small.m3u8' },
    // { Type: 'HLS', Title: 'Vertical Small', Url: 'https://vod-watchfolder-ridge-robinson.s3.amazonaws.com/outputs/test2/vertical/hls/Vertical_small.m3u8' },
    { Type: 'MP4', Title: 'Horizontal Small', Url: 'https://vod-watchfolder-ridge-robinson.s3.amazonaws.com/outputs/test1/horizontal/mp4/Horizontal_small.mp4' },
    // { Type: 'MP4', Title: 'Verticle Small', Url: 'https://vod-watchfolder-ridge-robinson.s3.amazonaws.com/outputs/test1/vertical/mp4/Vertical_small.mp4' },
]

function VideoPlayer() {
    return (
        <Container className="maincontainer">
            {/* <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text="MP4" TextAlign="left" />

            <Spacer Size="small" />

            <VideoJS { ...videoJsOptions } /> */}

            <Spacer Size="extra-large" />

            {
                Videos.map(({ Type, Title, Url }, VideoIndex) => {
                    if (Type === 'MP4') var options = { ...videoJsOptions, sources: [{ src: Url }] };
                    return (
                        <React.Fragment key={VideoIndex}>
                            <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={Type} TextAlign="left" />

                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={Title} TextAlign="center" />

                            <Spacer Size="extra-extra-small" />

                            {Type === 'HLS' ? <HLSJS VideoUrl={Url} /> : <VideoJS { ...options} />}

                            <Spacer Size="medium" />
                        </React.Fragment>
                    );
                })
            }
        </Container>
    );
}

export default VideoPlayer;