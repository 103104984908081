import {
    TRYING_CHANGE_DISHES_CATEGORY,
    TRYING_CHANGE_DISHES_CATEGORY_SUCCESS,
    TRYING_CHANGE_DISHES_CATEGORY_ERROR,

    TRYING_CHANGE_SELECTED_DISHES_RATING,
    TRYING_CHANGE_SELECTED_DISHES_RATING_SUCCESS,
    TRYING_CHANGE_SELECTED_DISHES_RATING_ERROR,

    TRYING_CREATE_DISH_CATEGORY,
    TRYING_CREATE_DISH_CATEGORY_SUCCESS,
    TRYING_CREATE_DISH_CATEGORY_ERROR,

    TRYING_DELETE_DISHES,
    TRYING_DELETE_DISHES_SUCCESS,
    TRYING_DELETE_DISHES_ERROR,

    TRYING_GET_DISH_FILTER_SETTINGS,
    TRYING_GET_DISH_FILTER_SETTINGS_SUCCESS,
    TRYING_GET_DISH_FILTER_SETTINGS_ERROR,

    CLEAR_SEARCH_DISHES,
    TRYING_SEARCH_DISHES,
    TRYING_SEARCH_DISHES_SUCCESS,
    TRYING_SEARCH_DISHES_ERROR
} from '../Types';

const INITIAL_STATE = {
    DishFilterSettings: { DishCategories: [], DishTypes: [], MealTypes: [] },
    DishList: [],
    DishList_TotalRecords: null,
    IsMoreDishes: false,
    
    TryingChangeDishesCategory: false,
    TryingChangeDishesCategoryError: null,
    TryingChangeSelectedDishesRating: false,
    TryingChangeSelectedDishesRatingError: null,
    TryingCreateDishCategory: false,
    TryingCreateDishCategoryError: null,
    TryingDeletePrivateDishes: false,
    TryingDeletePrivateDishesError: null,
    TryingGetDishFilterSettings: false,
    TryingGetDishFilterSettingsError: null,
    TryingSearchDishes: false,
    TryingSearchDishesError: null
};

var i;
var DishList;
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_CHANGE_DISHES_CATEGORY:
            return { ...state, TryingChangeDishesCategory: true, TryingChangeDishesCategoryError: null };
        case TRYING_CHANGE_DISHES_CATEGORY_ERROR:
            return { ...state, TryingChangeDishesCategory: false, TryingChangeDishesCategoryError: action.payload };
        case TRYING_CHANGE_DISHES_CATEGORY_SUCCESS:
            return { ...state, TryingChangeDishesCategory: false, TryingChangeDishesCategoryError: null };

        case TRYING_CHANGE_SELECTED_DISHES_RATING:
            return { ...state, TryingChangeSelectedDishesRating: true, TryingChangeSelectedDishesRatingError: null };
        case TRYING_CHANGE_SELECTED_DISHES_RATING_ERROR:
            return { ...state, TryingChangeSelectedDishesRating: false, TryingChangeSelectedDishesRatingError: action.payload };
        case TRYING_CHANGE_SELECTED_DISHES_RATING_SUCCESS:
            var { DishIds, Rating } = action.payload;
            DishList = [];

            for (i = 0; i < state.DishList.length; i++) {
                if (DishIds.indexOf(Number(state.DishList[i].DishId)) > -1) DishList.push({ ...state.DishList[i], Rating });
                else DishList.push({ ...state.DishList[i] });
            }

            return { ...state, DishList, TryingChangeSelectedDishesRating: false, TryingChangeSelectedDishesRatingError: null };

        case TRYING_CREATE_DISH_CATEGORY:
            return { ...state, TryingCreateDishCategory: true, TryingCreateDishCategoryError: null };
        case TRYING_CREATE_DISH_CATEGORY_ERROR:
            return { ...state, TryingCreateDishCategory: false, TryingCreateDishCategoryError: action.payload };
        case TRYING_CREATE_DISH_CATEGORY_SUCCESS:
            return { ...state, TryingCreateDishCategory: false, TryingCreateDishCategoryError: null };

        case TRYING_DELETE_DISHES:
            return { ...state, TryingDeleteDishes: true, TryingDeleteDishesError: null };
        case TRYING_DELETE_DISHES_ERROR:
            return { ...state, DishList: [], DishList_TotalRecords: null, IsMoreDishes: false, TryingDeleteDishes: false, TryingDeleteDishesError: action.payload };
        case TRYING_DELETE_DISHES_SUCCESS:
            // eslint-disable-next-line
            var { DishIds } = action.payload;
            var DishList_New = [];
            var DishList_TotalRecords_New = state.DishList_TotalRecords - DishIds.length;

            for (i = 0; i < state.DishList.length; i++) {
                if (DishIds.indexOf(Number(state.DishList[i].DishId)) === -1) DishList_New.push(state.DishList[i]);
            }

            return { ...state, DishList: DishList_New, DishList_TotalRecords: DishList_TotalRecords_New, TryingDeleteDishes: false, TryingDeleteDishesError: null };

        case TRYING_GET_DISH_FILTER_SETTINGS:
            return { ...state, TryingGetDishFilterSettings: true, TryingGetDishFilterSettingsError: null };
        case TRYING_GET_DISH_FILTER_SETTINGS_ERROR:
            return { ...state, TryingGetDishFilterSettings: false, TryingGetDishFilterSettingsError: action.payload };
        case TRYING_GET_DISH_FILTER_SETTINGS_SUCCESS:
            return { ...state, DishFilterSettings: action.payload, TryingGetDishFilterSettings: false, TryingGetDishFilterSettingsError: null };

        case CLEAR_SEARCH_DISHES:
            return { ...state, DishFilterSettings: { DishCategories: [], DishTypes: [], MealTypes: [] }, DishList: [], DishList_TotalRecords: null, IsMoreDishes: false };
        case TRYING_SEARCH_DISHES:
            return { ...state, TryingSearchDishes: true, TryingSearchDishesError: null };
        case TRYING_SEARCH_DISHES_ERROR:
            return { ...state, DishList: [], DishList_TotalRecords: null, IsMoreDishes: false, TryingSearchDishes: false, TryingSearchDishesError: action.payload };
        case TRYING_SEARCH_DISHES_SUCCESS:
            // eslint-disable-next-line
            var { AddDishes_IsMoreResults, AddToExisting, Dishes, TotalRecords } = action.payload;
            DishList = [];

            if (AddToExisting) DishList = [ ...state.DishList, ...Dishes ];
            else DishList = [ ...Dishes ];

            return { ...state, DishList, DishList_TotalRecords: Number(TotalRecords), IsMoreDishes: AddDishes_IsMoreResults ? true : false, TryingSearchDishes: false, TryingSearchDishesError: null };
        default:
            return state;
    }
};
