import styled from 'styled-components';

export const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const MenuItem = styled.div`
    align-items: center;
    background-color: var(--white);
    color: var(--black);
    display: flex;
    justify-content: center;
    padding: 7px 20px;
    width: 100%;

    :not(:last-child) {
        border-bottom: 1px solid var(--gray-light);
    }

    :hover {
        background-color: var(--white-concrete);
        cursor: pointer;
    }
`;