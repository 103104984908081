import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DeleteOrganizationMember, GetTrainerContracts, UpdateOrganizationIncome, UpdateOrganizationMemberDetails, ViewOrganizationMembers } from '../../Services/Actions';
import moment from 'moment';

import * as styles from './styles';

// import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import OrgMemberAccepted from './OrgMember_Accepted';

import AddOrgMembers from '../../Modals/AddOrgMembers';
import Info from '../../Modals/Info';

// import PlusWhite from '../../Assets/Icons/PlusSign_White.png';
import RedX from '../../Assets/Icons/X_Red.png';

class OrganizationCoachesComponent extends React.Component {
    state = {
        CoachDetail: {},
        DeleteMember: null,
        IncomeShare: '',
        Loading: true,
        ShowAddCoachesModal: false,
        ShowDeleteCoachesModal: false,
        ShowTrainerContractsModal: false,
        RetrievedTrainerContracts: false,
        TrainerContracts: []
    };
    
    componentDidMount() {
        this.onLoadCoaches();
    }

    onAddedCoaches = () => {
        this.setState({ ShowAddCoachesModal: false }, () => this.onLoadCoaches());
    }

    onBlurIncomeShare = () => {
        var { IncomeShare } = this.state;

        IncomeShare = (Math.round(+IncomeShare * 10)) / 10;

        if (IncomeShare < 0) IncomeShare = 0;
        if (IncomeShare > 100) IncomeShare = 100;

        if (IncomeShare !== +this.state.IncomeShare) this.setState({ IncomeShare });
        if (IncomeShare !== +this.props.IncomeShare) this.props.UpdateOrganizationIncome({ IncomeShare });
    }

    onChangeIncomeShare = event => {
        this.setState({ IncomeShare: event.target.value });
    }

    onDeleteCoach = () => {
        var { HistoryId, MemberIndex, MemberType, OrganizationMemberId } = this.state.DeleteMember;

        this.props.DeleteOrganizationMember({ HistoryId, MemberIndex, MemberType, OrganizationMemberId }).then(() => {
            this.setState({ DeleteMember: null, ShowDeleteCoachesModal: false });
        });
    }

    onLoadCoaches = () => {
        this.props.ViewOrganizationMembers().then(() => {
            var { IncomeShare } = this.props;

            if (this.state.Loading || this.state.IncomeShare === null) this.setState({ IncomeShare, Loading: false });
        });
    }

    onSaveMemberDetails = ({ Details: { IncomeShare, OrganizationMemberId }, MemberIndex }) => {
        // Set IncomeShare to null or a number
            if (IncomeShare === '') IncomeShare = null;
            else IncomeShare = +IncomeShare;

        if (IncomeShare !== this.props.Members.Accepted[MemberIndex].IncomeShare) this.props.UpdateOrganizationMemberDetails({ IncomeShare, MemberIndex, OrganizationMemberId });
    }

    onToggleShowAddCoachesModal = ShowAddCoachesModal => {
        this.setState({ ShowAddCoachesModal });
    }

    onToggleShowDeleteCoachesModal = ({ event = null, DeleteMember = null, ShowDeleteCoachesModal }) => {
        if (event) {
            event.stopPropagation();
            // event.nativeEvent.stopImmediatePropagation();
        }

        this.setState({ DeleteMember, ShowDeleteCoachesModal });
    }

    onToggleShowTrainerContractsModal = ({ CoachDetail = {}, RetrievedTrainerContracts = false, ShowTrainerContractsModal, TrainerContracts = [] }) => {
        this.setState({ CoachDetail, RetrievedTrainerContracts, ShowTrainerContractsModal, TrainerContracts });
    }

    renderAddCoachesModal = () => {
        var { ShowAddCoachesModal } = this.state;

        if (ShowAddCoachesModal) {
            return (
                <AddOrgMembers
                    OnHide={() => this.onToggleShowAddCoachesModal(false)}
                    Refresh={this.onAddedCoaches}
                    Show={ShowAddCoachesModal}
                />
            );
        }
    }

    renderCoaches = () => {
        var { t } = this.props;
        var { IncomeShare, Members } = this.props;

        return (
            <styles.AllMembersContainer className="AllMembersContainer">
                <styles.AcceptedCoachesParentContainer className="AcceptedCoachesParentContainer">
                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('coaches_accepted')} />
                    
                    <Spacer Size="small" />
                    
                    <styles.AcceptedCoachesContainer className="AcceptedCoachesContainer">
                        {
                            Members.Accepted.map((OrganizationMember, MemberIndex) => {
                                return (
                                    <OrgMemberAccepted
                                        key={OrganizationMember.OrganizationMemberId}
                                        MemberIndex={MemberIndex}
                                        OrgIncomeShare={IncomeShare}
                                        OrganizationMember={OrganizationMember}
                                        SaveMemberDetails={Details => this.onSaveMemberDetails({ Details, MemberIndex })}
                                        ToggleShowDeleteCoachesModal={this.onToggleShowDeleteCoachesModal}
                                        ToggleShowTrainerContractsModal={this.onToggleShowTrainerContractsModal}
                                    />
                                )
                            })
                        }
                    </styles.AcceptedCoachesContainer>
                </styles.AcceptedCoachesParentContainer>

                <styles.PendingCoachesParentContainer className="PendingCoachesParentContainer">
                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('coaches_pending')} />
                    
                    <Spacer Size="small" />
                    
                    <styles.PendingCoachesContainer className="PendingCoachesContainer">
                        {
                            Members.Pending.map(({ DisplayName, HistoryId }, MemberIndex) => {
                                return (
                                    <styles.CoachContainer className="CoachContainer" key={HistoryId}>
                                        <styles.CoachDetailsContainer className="CoachDetailsContainer">
                                            <styles.DeleteIconContainer className="DeleteIconContainer">
                                                <styles.DeleteIcon ImageSrc={RedX} OnClick={() => this.onToggleShowDeleteCoachesModal({ DeleteMember: { HistoryId, MemberIndex, MemberType: 'Pending' }, ShowDeleteCoachesModal: true })} />
                                            </styles.DeleteIconContainer>

                                            <styles.StyledDisplayName>
                                                <styles.StyledEllipsisText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={DisplayName} TextAlign="left" />
                                            </styles.StyledDisplayName>
                                        </styles.CoachDetailsContainer>

                                        <styles.StyledDisplayName>
                                            <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('pending')} TextAlign="left" />
                                        </styles.StyledDisplayName>
                                    </styles.CoachContainer>
                                );
                            })
                        }
                    </styles.PendingCoachesContainer>
                </styles.PendingCoachesParentContainer>
            </styles.AllMembersContainer>
        );
    }

    renderDeleteCoachesModal = () => {
        var { ShowDeleteCoachesModal } = this.state;

        if (this.state.ShowDeleteCoachesModal) {
            var { t } = this.props;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteCoach}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    Information={t('delete_orgmember_confirmation')}
                    Loading={this.props.TryingDeleteOrganizationMember}
                    OnHide={() => this.onToggleShowDeleteCoachesModal({ ShowDeleteCoachesModal: false })}
                    Show={ShowDeleteCoachesModal}
                    Size="small"
                />
            );
        }
    }

    renderTrainerContracts = () => {
        var { CoachDetail: { CoachId, DisplayName }, RetrievedTrainerContracts, TrainerContracts } = this.state;

        if (!RetrievedTrainerContracts && !this.props.TryingGetTrainerContracts) this.setState({ RetrievedTrainerContracts: true }, () => this.props.GetTrainerContracts({ CoachId }).then(({ TrainerContracts }) => this.setState({ TrainerContracts })));

        var { t } = this.props;

        return (
            <styles.TrainerContractsContainer className="TrainerContractsContainer">
                <PageText FontFamily="bold" FontSize="large" JustifyContent="center" NoMargin Text={`${t('contracts_for')} ${DisplayName}`} TextAlign="center" />

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text={t('contracts_with_border_belong_to_organization')} TextAlign="center" />

                <Spacer Size="medium" />

                <styles.TrainerContractsListContainer className="TrainerContractsListContainer">
                    {
                        TrainerContracts.map(({ BelongsToOrg, ContractId, DisplayName, SignatureColor, UserSigned }) => {
                            return (
                                <styles.TrainerContractContainer className="TrainerContractContainer" key={ContractId} BelongsToOrg={!!+BelongsToOrg ? SignatureColor : null}>
                                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={DisplayName} TextAlign="center" />

                                    <Spacer Size="small" />

                                    <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={moment(UserSigned).format('[WeeklyViewHeader]')} TextAlign="center" />
                                </styles.TrainerContractContainer>
                            );
                        })
                    }
                </styles.TrainerContractsListContainer>

                {
                    !TrainerContracts.length && !!RetrievedTrainerContracts && !this.props.TryingGetTrainerContracts &&
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('contracts_for_trainer_in_org_empty', { DisplayName })} TextAlign="center" />
                }
            </styles.TrainerContractsContainer>
        );
    }

    renderTrainerContractsModal = () => {
        var { ShowTrainerContractsModal } = this.state;

        if (ShowTrainerContractsModal) {
            return (
                <Info
                    InformationRenderer={this.renderTrainerContracts}
                    Loading={this.props.TryingGetTrainerContracts}
                    OnHide={() => this.onToggleShowTrainerContractsModal({ ShowTrainerContractsModal: false })}
                    Show={ShowTrainerContractsModal}
                    Size="large"
                />
            );
        }
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { t } = this.props;
        var { Members, TryingUpdateOrganizationIncome, TryingUpdateOrganizationMemberDetails, TryingViewOrganizationMembers } = this.props;
        var { IncomeShare } = this.state;

        return (
            <Page NoMargin>
                {(TryingUpdateOrganizationIncome || TryingUpdateOrganizationMemberDetails || TryingViewOrganizationMembers) && <Loading />}

                <PageText FontFamily="medium" FontSize="large" NoMargin JustifyContent="flex-start" Text={t('your_coaches')} TextAlign="left" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" NoMargin JustifyContent="flex-start" Text={t('your_coaches_description')} TextAlign="left" />

                {/* <Spacer Size="small" />

                <styles.OptionsContainer className="OptionsContainer">
                    <ButtonGroup
                        Buttons={[
                            { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Color: 'white', ColorHover: 'white-hover', FontFamily: 'semibold', Icon: PlusWhite, IconPosition: 'left', OnClick: () => this.onToggleShowAddCoachesModal(true), Title: t('add_new_coach') }
                        ]}
                        ButtonWidth="fit-content"
                        NotTouching
                    />
                </styles.OptionsContainer> */}

                <Spacer Size="small" />

                <styles.IncomeChoiceContainer className="IncomeChoiceContainer">
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('income_organization_commission')} TextAlign="center" />

                    <styles.IncomeChoiceInputContainer className="IncomeChoiceInputContainer">
                        <styles.IncomeChoiceInput FontFamily="bold" FontSize="medium-2" NoLabel NoMargin OnBlur={this.onBlurIncomeShare} OnChange={this.onChangeIncomeShare} Placeholder="0" Size="small-1" Type="number" Value={IncomeShare} />
                    </styles.IncomeChoiceInputContainer>

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text="%" TextAlign="center" />
                </styles.IncomeChoiceContainer>

                {
                    (!!Members.Accepted.length || !!Members.Pending.length) &&
                    <>
                        <Spacer Size="medium" />

                        {this.renderCoaches()}
                    </>
                }

                {this.renderAddCoachesModal()}
                {this.renderDeleteCoachesModal()}
                {this.renderTrainerContractsModal()}
            </Page>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        IncomeShare: state.Income.IncomeShare,
        Members: state.Income.Members,
        TrainerContracts: state.Income.TrainerContracts,

        TryingDeleteOrganizationMember: state.Income.TryingDeleteOrganizationMember,
        TryingDeleteOrganizationMemberError: state.Income.TryingDeleteOrganizationMemberError,
        TryingGetTrainerContracts: state.Income.TryingGetTrainerContracts,
        TryingGetTrainerContractsError: state.Income.TryingGetTrainerContractsError,
        TryingUpdateOrganizationIncome: state.Income.TryingUpdateOrganizationIncome,
        TryingUpdateOrganizationIncomeError: state.Income.TryingUpdateOrganizationIncomeError,
        TryingUpdateOrganizationMemberDetails: state.Income.TryingUpdateOrganizationMemberDetails,
        TryingUpdateOrganizationMemberDetailsError: state.Income.TryingUpdateOrganizationMemberDetailsError,
        TryingViewOrganizationMembers: state.Income.TryingViewOrganizationMembers,
        TryingViewOrganizationMembersError: state.Income.TryingViewOrganizationMembersError
    };
};

export default withTranslation()(connect(mapStateToProps, { DeleteOrganizationMember, GetTrainerContracts, UpdateOrganizationIncome, UpdateOrganizationMemberDetails, ViewOrganizationMembers })(OrganizationCoachesComponent));