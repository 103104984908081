import styled from 'styled-components';

export const ButtonContainer = styled.div`
    align-items: center;
    background-color: ${props => !!props.Selected ? 'var(--blue-abel)' : 'var(--white-concrete)'};
    border-radius: 10px;
    color: ${props => !!props.Selected ? 'var(--white)' : 'var(--black)'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;

    @media(hover:hover) {
        :hover {
            background-color: var(--blue-abel);
            color: var(--white);
            cursor: pointer;
        }
    }
`;

export const Container = styled.div``;