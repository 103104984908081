import styled from 'styled-components';
import { device } from '../../../Config/device';

import Icon from '../../../Components/Icon';
import Input from '../../../Components/Form/Input';

export const BodyContainer = styled.div`
    grid-area: body;
    padding: 15px 30px;
`;

export const BodyHeader = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media ${device.maxwidth.ipad} {
        align-items: flex-start;
        flex-direction: column;
    }
`;

export const BodyHeaderRight = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media ${device.minwidth.laptop} {
        margin-left: 10px;
    }
`;

export const DHCDetailModalInfoContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const DHCDetailModalInfoFlexContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const FilterContainer = styled.div`
    flex: 1;
`;

export const FilterHeaderContainer = styled.div`
    display: flex;
    position: relative;
    width: 100%;
`;

export const FiltersContainer = styled.div`
    border-left: 2px solid var(--white-concrete);
    grid-area: filter;
    padding: 15px 30px;
`;

export const FiltersSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    justify-content: space-between;
`;

export const InsertEditModalContainer = styled.div``;

export const MaxPercentInfoIcon = styled(Icon)`
    height: 20px;
    width: 20px;

    @media (min-width: 901px) {
        position: absolute;
        right: 10px;
        top: 0px;
    }
`;

export const PopoverContent = styled.div`
    align-items: center;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 200px;
    padding: 10px;
`;

export const ProfilePhotoContainer = styled.div`
    margin-right: 5px;
    width: var(--profilephoto-size-small-3);
`;

export const ProfilePhotoContainer_Popup = styled.div`
    margin-right: 5px;
    width: var(--profilephoto-size-extra-large);
`;

export const SearchInput = styled(Input)`
    margin-right: 10px;

    > input {
        border-radius: 10px;
    }
`;

export const SearchUsersInput = styled(Input)`
    margin-right: 10px;
    max-width: 100%;

    @media ${device.maxwidth.mobile} {
        width: 170px;
    }

    @media ${device.minwidth.mobile} {
        width: 260px;
    }

    @media ${device.minwidth.ipad} {
        width: 320px;
    }

    > input {
        border-radius: 10px;
    }
`;

export const StyledHR = styled.div`
    background-color: var(--black);
    border-radius: 10px;
    height: 2px;
    margin: 20px 0px;
    width: 100%;
`;

export const SortingIcon = styled(Icon)`
    width: 15px;
`;

export const UsersContainer = styled.div``;

export const UsersTableContainer = styled.div`
    overflow: scroll;
    max-height: 800px;
`;

export const UsersTable = styled.table`
    border: 1px solid var(--black);
    position: relative;
`;

export const UsersTableBody = styled.tbody`
    tr:nth-child(even) { background: #CCC; }
    tr:nth-child(odd) { background: #FFF; }
`;

export const UsersTableBodyRow = styled.tr`
    @media(hover:hover) {
        :hover {
            background-color: var(--abel-blue-hover) !important;
            color: var(--white) !important;
            cursor: pointer;
        }
    }
`;

export const UsersTableBodyCell = styled.td`
    padding: 5px 7px;
    white-space: nowrap;

    ${
        props => props.Flex &&
        `
            align-items: center;
            display: flex;
            flex-direction: row;
        `
    }
`;

export const UsersTableBodyCellFlexed = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;

export const UsersTableHeader = styled.thead``;

export const UsersTableHeaderRow = styled.tr``;

export const UsersTableHeaderCell = styled.th`
    background-color: #CCC;
    font-family: var(--font-family-semibold);
    padding: 5px 7px;
    position: sticky;
    top: 0;
    z-index: 99;

    white-space: nowrap;
    overflow-y: scroll;
    height: 35px;
    text-align: left;

    ${
        props => !!props.onClick &&
        `
            @media(hover:hover) {
                :hover {
                    cursor: pointer;
                }
            }
        `
    }
`;

export const UsersTableHeaderCellInnerDiv = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
`;