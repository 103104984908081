import styled from 'styled-components';

import Icon from '../../Components/Icon';
import Image from '../../Components/Image';
import PageText from '../../Components/Text/PageText';

export const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const CircuitContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    :not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const CircuitDetailContainer = styled.div`
    ${props => props.NumExercises === 2 && `background-color: var(--blue-royal);`}
    ${props => props.NumExercises >= 3 && `background-color: var(--turquoise-dark);`}

    margin-left: 15px;

    ${props => props.Type === 'Circuit' ? 'padding: 16px 18px;' : 'padding-left: 18px;'}
    
    border-radius: 10px;
    width: 100%;
`;

export const CircuitHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const CircuitsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    padding: 8px;
    width: 800px;
`;

export const DragHandleIcon = styled(Icon)`
    height: 25px;
    width: 25px;
`;

export const DynamicDragHandle = styled.span`
    // ${props => props.Type === 'WorkoutExercise' && 'display: none;'}
`;

export const ExerciseDetailContainer = styled.div`
    align-items: center;
    background-color: ${props => !!props.FromAddWorkouts ? 'var(--white)' : 'var(--white-concrete)'};
    border-radius: 10px;
    color: var(--black);
    display: flex;
    flex: 1;
    justify-content: flex-start;
    margin-left: 15px;
    padding: 10px 20px;
    width: 100%;

    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

export const ExercisesContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 32px;
    width: 100%;
`;

export const ExerciseContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    :not(:last-child) {
        margin-bottom: 5px;
    }
`;

export const ExplanationContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
    width: 800px;
`;

export const IconTextContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const IconTextContainerIcon = styled(Icon)`
    height: 25px;
    margin-right: 15px;
    width: 25px;
`;

export const StyledExerciseImage = styled(Image)`
    border-radius: 10px;
    height: 38px;
    object-fit: cover;
    margin-right: 20px;
    width: 52px;

    @media (max-width: 400px) {
        margin-bottom: 20px;
        margin-right: 0px;
    }
`;

export const StyledExerciseName = styled(PageText)`
    text-transform: uppercase;
`;