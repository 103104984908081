import styled from 'styled-components';
import { device } from '../../Config/device';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
// import Image from '../../Components/Image';

export const AddClientToGroupsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    > div:nth-child(3) {
        max-width: 100%;
    }
`;

export const BodyContainer = styled.div`
    margin: 0 5%;
`;

export const BottomButton = styled(ButtonGroup)`
    > div {
        :first-child {
            border-top-left-radius: 0px
        }

        :last-child {
            border-top-right-radius: 0px;
        }
    }
`;

export const CustomOptionContainer = styled.div`
    > div:first-child {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-start;
    }
`;

export const InputContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    > div {
        width: 85%;
    }
`;

export const InstructionsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;

    @media ${device.maxwidth.ipad} {
        > div:first-child {
            margin-bottom: 10px;
        }
    }

    @media ${device.minwidth.laptop} {
        grid-template-columns: 2fr 1fr;
    }
`;

export const Instructions1 = styled.div`
    align-items: start;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
    padding: 20px;
`;

export const Instructions2 = styled.div`
    align-items: start;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
    padding: 20px;
`;

export const MenuListContainer = styled.div`
    max-height: 200px;
    overflow-y: scroll;
`;

export const StyledTeamPicture = styled.img`
    border-radius: 50%;
    height: 50px;
    object-fit: cover;
    margin-right: 10px;
    width: 50px;

    > div {
        width: 100%;
    }
`;