import styled from 'styled-components';

export const ButtonContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Disabled ? 'var(--gray)' : props.Type.toLowerCase() === 'cancel' ? 'var(--white-concrete)' : 'var(--abel-blue)'};
    border-radius: ${props => props.Position.toLowerCase() === 'top' ? '10px 10px 0px 0px' : '0px 0px 10px 10px'};
    display: flex;
    height: var(--button-height-large);
    justify-content: center;
    padding: 10px 30px;

    :hover {
        background-color: ${props => props.Disabled ? 'var(--dark-gray)' : props.Type.toLowerCase() === 'cancel' ? 'var(--white-concrete-hover)' : 'var(--abel-blue-hover)'};
        cursor: ${props => props.Disabled ? 'not-allowed' : 'pointer'};
    }
`;

export const ButtonTitle = styled.div`
    color: ${props => props.Disabled ? 'var(--black)' : props.Type.toLowerCase() === 'cancel' ? 'var(--black)' : 'var(--white)'};
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-medium-3);
`;

export const Image = styled.img`
    height: 20px;
    width: 20px;
`;