import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddNewWorkout, ClearAdditionalActivityDetails, ClearSaveData, GetAdditionalActivityDetails, GetWorkoutTemplateDetail, SaveDataInProps, UpdateSelectedTab, UploadToPresignedUrl } from '../../Services/Actions';
import history from '../../history';
import moment from 'moment';

import TimePicker from 'rc-time-picker';

import { ActivityPictureContainer, ActivityPicturesContainer, AdditionalActivityItem, AdditionalActivityList, CircuitContainer, CircuitSeparator, DateTimeContainer, DateTimesContainer, DateTimeUseAllDays, DateTimeUseAllDaysContainer, DeleteSetIcon, EditIcon, HourType, HourTypesContainer, LapContainer, LapDetailsContainer, LapFooterContainer, LapHeaderContainer, LapHeaderContainerLeft, LapHeaderContainerRight, SelectedDateContainer, SelectedDatesContainer, StyledActivityImage, StyledAddLap, StyledDropdown, StyledDuration, StyledInputLabelMultiText, StyledIntensities, StyledWorkoutName, TrainerNoteContainer, TrainerNoteTextArea, UploadButtonsContainer, UploadButtonsRow, UploadContainer, WorkoutExercisesContainer, WorkoutLapsContainer } from './styles';

import ActiveLap from '../../Components/WorkoutLaps/ActiveLap';
import AssignClients from '../../Components/AssignClients';
import BreakLap from '../../Components/WorkoutLaps/BreakLap';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import { TemplateExercise as TemplateExerciseComponent } from '../../Components/WorkoutExercise';
import UploadProgressComponent from '../../Components/UploadProgress';
import WorkoutCircuits from '../../Components/WorkoutCircuits';

import AddExercise from '../../Modals/AddExercise';
import ChooseDates from '../../Modals/ChooseDates';
import ExerciseDetail from '../../Modals/ExerciseDetail';
import WorkoutTemplateFillIn from '../../Modals/WorkoutTemplateFillIn';

import Camera from '../../Assets/Icons/Camera.png';
import DeleteX from '../../Assets/Icons/Delete_X.png';
import Edit_Dark from '../../Assets/Icons/Edit_Dark.png';
import Plus from '../../Assets/Icons/Plus.png';

import ActivityIllustration from '../../Assets/Illustrations/Activity.png';
import EnduranceIllustration from '../../Assets/Illustrations/Endurance_Blue.png';
import StrengthIllustration from '../../Assets/Illustrations/Strength.png';

import { CreateUploadToPresignedUrlPromise, DateSort, getFileExtension, validateFile } from '../../Functions';

class AddWorkout extends React.Component {
    _isMounted = false;

    state = {
        ActivityId: null,
        ActivityName: '',
        ActivityPicture: null,
        ActivityPictureId: null,
        ActivityPictures: [],
        AdditionalActivityDuration: 0,
        CopiedTemplateId: null,
        CopyImage: false,
        CustomName: '',
        DisplayingFilter: null,
        DropdownSize: 0,
        Duration: 0,
        HourType: 24,
        ImageTooLarge: false,
        IntensityId: null,
        Loading: false,
        Location: '',
        MaxImageSize: 10000000,
        SelectedDates_Existing: [],
        SelectedDates: [],
        SelectedImage: null,
        SelectedGroups: {},
        SelectedUserIds: [],
        SelectedUsers: [],
        ShowAddExerciselModal: false,
        ShowChooseDatesModal: false,
        ShowCustomName: null,
        ShowDateTimes: false,
        ShowExerciseDetailModal: false,
        ShowExerciseDetailPreview: {},
        ShowLearnMoreModal: false,
        ShowReorderExercises: false,
        SyncAllDateTimes: false,
        TrainerNote: '',
        WorkoutExercises: [],
        WorkoutLaps: { Laps: [] },
        WorkoutName: '',
        WorkoutImage: null,
        WorkoutType: null,
        WrongImageType: false
    };

    componentDidMount() {
        this._isMounted = true;

        if (this.props.location.state && !!this.props.location.state.IsReturnData) this.setState({ ...this.props.location.state });
        else {
            var { ClientDetails, DayDate, GroupDetails } = this.props;

            var SelectedDates = DayDate ? [ DayDate ] : [];
            var SelectedGroups = {};
            var SelectedUserIds = [];
            var SelectedUsers = [];

            if (Object.entries(ClientDetails).length !== 0) {
                SelectedUserIds = [ Number(ClientDetails.ClientId) ];
                SelectedUsers = [{ ...ClientDetails, UserId: Number(ClientDetails.ClientId) }];
            }

            if (Object.entries(GroupDetails).length !== 0) {
                var { GroupId, GroupImage, GroupName, GroupType } = GroupDetails;
                SelectedGroups[`${GroupType}_GroupId-${GroupId}`] = { AllMembers: true, GroupImage, GroupName, GroupType, Members: [], RemoveMembers: [] };
            }

            var { TemplateToWorkoutDetails } = this.props;

            // Build Template From Workout Details
            if (Object.entries(TemplateToWorkoutDetails).length) {
                var { AdditionalActivityDetails, CopiedTemplateId, FromTemplate, WorkoutDetails, WorkoutExercises, WorkoutLaps } = TemplateToWorkoutDetails;

                // eslint-disable-next-line
                var { ActivityId, CustomImage, CustomName, Duration, IntensityId, Location, TrainerNote, WorkoutImage, WorkoutType } = WorkoutDetails;

                if (+WorkoutType === 1) {
                    WorkoutExercises = WorkoutExercises.map((Circuit, CircuitIndex) => {
                        return {
                            ...Circuit,
                            id: CircuitIndex,
                            WorkoutExercises: Circuit.WorkoutExercises.map(({ DataType, ExerciseId, ExerciseImage, ExerciseName, ExerciseType, Notes, Header_Distance, Header_Reps, Header_Seconds, Header_Weight, MaxType, Sets, TestSets }) => {
                                DataType = FromTemplate ? DataType : 'static';
                                MaxType = FromTemplate ? MaxType : 'Progress';
    
                                if (Sets.length) {
                                    return { DataType, ExerciseId: +ExerciseId, ExerciseImage, ExerciseName, ExerciseType: +ExerciseType, Notes, Header_Distance, Header_Reps, Header_Seconds, Header_Weight, MaxType,
                                        Sets: Sets.map(({ Break, Distance, MaxSet, Reps, Rest, Seconds, Units, Weight }) => {
                                            return { Distance: +Distance || null, MaxSet: +MaxSet, Rest: FromTemplate ? +Rest : +Break, Reps: +Reps || null, Seconds: +Seconds || null, Units, Weight: +Weight || null, WorkoutSetId: 0 };
                                        }),
                                        WorkoutExerciseId: 0
                                    };
                                } else if (TestSets.length) {
                                    return { DataType, ExerciseId: +ExerciseId, ExerciseImage, ExerciseName, ExerciseType: +ExerciseType, Notes, Header_Distance, Header_Reps, Header_Seconds, Header_Weight, MaxType,
                                        Sets: TestSets.map(({ Break, Distance, MaxSet, Reps, Seconds, Units, Weight }) => {
                                            return { Distance: +Distance, MaxSet: +MaxSet, Rest: +Break, Reps: +Reps, Seconds: +Seconds, Units, Weight: +Weight, WorkoutSetId: 0 };
                                        }),
                                        WorkoutExerciseId: 0
                                    };
                                } else return [];
                            })
                        }
                    });

                    WorkoutLaps = { Laps: [] };
                } else if (+WorkoutType === 2) {
                    var NewDataType = FromTemplate ? WorkoutLaps.DataType : 'static';
                    
                    WorkoutLaps = {
                        ActiveType: WorkoutLaps.ActiveType,
                        CardioLevel: 1,
                        DataType: NewDataType,
                        Laps: WorkoutLaps.Laps
                    };

                    WorkoutExercises = [];
                } else if (+WorkoutType === 3) {
                    var ShowCustomName = !!CustomName;
                }

                var { AdditionalActivities, Intensities } = AdditionalActivityDetails;
                var ActivityName = '';
                var ActivityPicture;
                var ActivityPictures = [];
                var IntensityName = '';

                var i;
                for (i = 0; i < AdditionalActivities.length; i++) {
                    if (AdditionalActivities[i].value === ActivityId) {
                        ActivityName = AdditionalActivities[i].label;
                        ActivityPicture = AdditionalActivities[i].Pictures[0];
                        ActivityPictures = AdditionalActivities[i].Pictures;
                        break;
                    }
                }

                for (i = 0; i < Intensities.length; i++) {
                    if (Intensities[i].IntensityId === IntensityId) {
                        IntensityName = Intensities[i].IntensityName;
                        break;
                    }
                }

                if (WorkoutType !== 2) WorkoutLaps = { Laps: [] };

                var ActivityPictureId = ActivityId;
                if (CustomImage) {
                    ActivityPicture = WorkoutImage;
                    var CopyImage = true;
                    ActivityPictureId = 'custom';
                }

                this.setState({ ActivityId, ActivityName, ActivityPicture, ActivityPictureId, ActivityPictures, AdditionalActivities, AdditionalActivityDuration: Duration, CopyImage, CopiedTemplateId, CustomName, Duration, Intensities, IntensityId, IntensityName, Loading: false, Location, SelectedDates, SelectedGroups, SelectedUserIds, SelectedUsers, ShowCustomName, TrainerNote, WorkoutExercises, WorkoutImage, WorkoutLaps, WorkoutName: CustomName, WorkoutType });
                window.scrollTo(0,0);
            } else this.setState({ SelectedDates, SelectedGroups, SelectedUserIds, SelectedUsers });
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        if (this.props.RetrievedAdditionalActivitySettings) this.props.ClearAdditionalActivityDetails();

        this.props.ClearSaveData();
    }

    onAddBreak = LapIndex => {
        var Laps = [ ...this.state.WorkoutLaps.Laps ];

        Laps.splice(LapIndex + 1, 0, { ...Laps[LapIndex], Active: 0, ActiveUnits: null, BreakTime: 60, LapType: 'Break', Rest: 0, Speed: 0, WorkoutLapId: null });

        var LapNumber = 1;
        Laps = Laps.map(Lap => ({ ...Lap, LapNumber: Lap.LapType === 'Active' ? LapNumber++ : 0 }));

        this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, Laps } });
    }

    onAddExercises = SelectedExercises => {
        var { t } = this.props;
        var Units = this.props.UserDetails.PreferredMeasuringSystem.toLowerCase();

        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        var NewExercise = {};
        SelectedExercises.map(({ ExerciseId, ExerciseImage, ExerciseName, ExerciseType }) => {
            NewExercise = { ExerciseId, ExerciseImage, ExerciseName, ExerciseType, Notes: { Coach: '' }, MaxType: 'Progress', Sets: [], WorkoutExerciseId: 0 };

            if (ExerciseType === 1) { NewExercise.DataType = 'dynamic-reps'; NewExercise.Sets.push({ MaxSet: 0, Reps: '', Rest: '', Units: t('Reps'), WorkoutSetId: 0 }); }
            else if (ExerciseType === 2) { NewExercise.DataType = 'dynamic-seconds'; NewExercise.Sets.push({ MaxSet: 0, Rest: '', Seconds: '', WorkoutSetId: 0 }); }
            else if (ExerciseType === 3) { NewExercise.DataType = 'dynamic-distance'; NewExercise.Sets.push({ Distance: '', MaxSet: 0, Rest: '', Units: Units === 'imperial' ? 'yards' : 'meters', WorkoutSetId: 0 }); }
            else if (ExerciseType === 4) { NewExercise.DataType = 'dynamic-weight'; NewExercise.Header_Reps = t('Reps'); NewExercise.Sets.push({ MaxSet: 0, Reps: '', Rest: '', Units: Units === 'imperial' ? 'lbs' : 'kg', Weight: '', WorkoutSetId: 0 }); }
            else if (ExerciseType === 5) { NewExercise.DataType = 'dynamic-weight'; NewExercise.Sets.push({ MaxSet: 0, Rest: '', Seconds: '', Units: Units === 'imperial' ? 'lbs' : 'kg', Weight: '', WorkoutSetId: 0 }); }

            WorkoutExercises.push({ CircuitNumber: 0, NumExercises: 1, Type: 'WorkoutExercise', WorkoutExercises: [{ ...NewExercise }] });

            return null;
        });

        this.setState({ WorkoutExercises });
    }

    onAddLap = LapIndex => {
        var { t } = this.props;
        var { ActiveType } = this.state.WorkoutLaps;

        var Laps = [ ...this.state.WorkoutLaps.Laps ];

        // Copy if previous lap is not a break lap && not the first lap
            if (Laps.length && Laps[LapIndex].LapType === 'Active') Laps.splice(LapIndex + 1, 0, { ...Laps[LapIndex], WorkoutLapId: null });
        // Else create new lap with 0 seconds
            else {
                var Units = this.props.UserDetails.PreferredMeasuringSystem.toLowerCase();

                var NewActiveUnits = ActiveType === 'Time' ? null : t(Units === 'imperial' ? 'yards' : 'meters').toLowerCase();
                var NewSpeedUnits = Units === 'imperial' ? 'mph' : 'km/h';

                Laps.splice(LapIndex + 1, 0, { ...Laps[LapIndex], Active: 0, ActiveUnits: NewActiveUnits, BreakTime: 0, LapType: 'Active', Rest: 0, Speed: 10, SpeedUnits: NewSpeedUnits, WorkoutLapId: null });
            }

        var LapNumber = 1;
        Laps = Laps.map(Lap => ({ ...Lap, LapNumber: Lap.LapType === 'Active' ? LapNumber++ : 0 }));

        this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, Laps } });
    }

    onAddSet = ({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex }) => {
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets = [
            ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets,
            { ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.length - 1], MaxSet: 0, WorkoutSetId: 0 }
        ];

        this.setState({ WorkoutExercises });
    }

    onAddWorkout = () => {
        var { ActivityId, ActivityPictureId, AdditionalActivityDuration, CopyImage, CopiedTemplateId, CustomName, Duration, IntensityId, Location, SelectedDates, SelectedGroups, SelectedImage, SelectedUserIds, ShowCustomName, ShowDateTimes, TrainerNote, WorkoutExercises, WorkoutLaps: { ActiveType, CardioLevel, DataType, Laps }, WorkoutName, WorkoutType } = this.state;

        var Units = this.props.UserDetails.PreferredMeasuringSystem.toLowerCase();

        if (WorkoutType === 3) {
            Duration = AdditionalActivityDuration;
            
            if (!ShowCustomName) CustomName = '';
        }
        else CustomName = WorkoutName;

        var AssignedDates = '';
        if (!ShowDateTimes) AssignedDates = SelectedDates.map(AssignedDate => `${AssignedDate.substring(0,10)} 00:00`);
        AssignedDates = SelectedDates.join(',');

        var CustomImage = 0;

        var WorkoutImage = null;
        if (ActivityPictureId === 'custom') {
            CustomImage = 1;
            if (SelectedImage && !CopyImage) WorkoutImage = { ContentType: SelectedImage.type, FileName: SelectedImage.name, FileSize: SelectedImage.size };
        } else CopyImage = 0;

        // Update WorkoutExercises So That CircuitNumber Is Added Property To Each Exercise
        if (WorkoutType === 1) {
            var NewWorkoutExercises = [];

            for (let i = 0; i < WorkoutExercises.length; i++) {
                let { CircuitNumber, WorkoutExercises: WEArr } = WorkoutExercises[i];

                WEArr.map(WorkoutExercise => {
                    var ExerciseNotes = WorkoutExercise.Notes.Coach;

                    NewWorkoutExercises.push(({ ...WorkoutExercise, CircuitNumber: CircuitNumber || 0, ExerciseNotes }));

                    return null;
                });
            }
        }

        var ClientIds = SelectedUserIds.join(',');
        this.props.AddNewWorkout({ ActiveType, ActivityId, AssignedDates, CardioLevel, ClientIds, CopyImage: +CopyImage, CopiedTemplateId, CustomImage, CustomName, DataType, Duration, Groups: SelectedGroups, HasDateTimes: ShowDateTimes, IntensityId, Location, TrainerNote, Units, WorkoutExercises: NewWorkoutExercises, WorkoutImage, WorkoutLaps: Laps, WorkoutType }).then(({ PreSignedUrls }) => {
            if (WorkoutImage && PreSignedUrls.length > 0) {
                var UploadFunction = this.props.UploadToPresignedUrl;
                var Promises = PreSignedUrls.map((PreSignedUrl, index) => CreateUploadToPresignedUrlPromise({ ContentType: 'Workout', CustomLabelName: `${SelectedImage.name} - ${index + 1}`, File: SelectedImage, PresignedFormData: PreSignedUrl, UploadFunction }));

                Promise.all(Promises).then(() => {
                    if (Object.keys(SelectedGroups).length === 1) {
                        var GroupId = /Team_GroupId-(.*)/.exec(Object.keys(SelectedGroups)[0]) && /Team_GroupId-(.*)/.exec(Object.keys(SelectedGroups)[0])[1];
                        
                        if (GroupId) history.push({ pathname: `group/${GroupId}`, state: { WorkoutDate: SelectedDates[0] } });
                        else if (SelectedUserIds.length === 1) history.push({ pathname: `/client/${SelectedUserIds[0]}`, search: '?type=workouts', state: { WorkoutDate: SelectedDates[0] } });
                        else history.push('/clients');
                    }
                    else if (SelectedUserIds.length === 1) history.push({ pathname: `/client/${SelectedUserIds[0]}`, search: '?type=workouts', state: { WorkoutDate: SelectedDates[0] } });
                    else history.push('/clients');
                });
            }
            else {
                if (Object.keys(SelectedGroups).length === 1) {
                    var GroupId = /Team_GroupId-(.*)/.exec(Object.keys(SelectedGroups)[0]) && /Team_GroupId-(.*)/.exec(Object.keys(SelectedGroups)[0])[1];
                    
                    if (GroupId) history.push({ pathname: `group/${GroupId}`, state: { WorkoutDate: SelectedDates[0] } });
                    else if (SelectedUserIds.length === 1) history.push({ pathname: `/client/${SelectedUserIds[0]}`, search: '?type=workouts', state: { WorkoutDate: SelectedDates[0] } });
                    else history.push('/clients');
                }
                else if (SelectedUserIds.length === 1) history.push({ pathname: `/client/${SelectedUserIds[0]}`, search: '?type=workouts', state: { WorkoutDate: SelectedDates[0] } });
                else history.push('/clients');
            }
        });
    }

    onCancelCircuits = () => {
        this.setState({ ShowReorderExercises: false });
    }

    onChangeActiveType = ActiveType => {
        // Used for Endurance Template to change between "Time" && "Distance"
        this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, ActiveType } });
    }

    onChangeAdditionalActivityDuration = event => {
        this.setState({ AdditionalActivityDuration: event.target.value });
    }

    onChangeCustomName = event => {
        this.setState({ CustomName: event.target.value });
    }

    onChangeDataType = ({ CircuitWorkoutExerciseIndex, DataType, WorkoutExerciseIndex }) => {
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].DataType = DataType;

        this.setState({ WorkoutExercises });
    }

    onChangeMaxType = ({ CircuitWorkoutExerciseIndex, MaxType, WorkoutExerciseIndex }) => {
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].MaxType = MaxType;

        this.setState({ WorkoutExercises });
    }

    onChangeDateTime = (DateTime, SelectedDateIndex) => {
        var NewDateTime = moment(DateTime).format('YYYY-MM-DD HH:mm');

        var { SelectedDates_Existing, SelectedDates, SyncAllDateTimes } = this.state;

        if (!SyncAllDateTimes) {
            SelectedDates_Existing[SelectedDateIndex] = NewDateTime;
            SelectedDates[SelectedDateIndex] = NewDateTime;

            this.setState({ SelectedDates_Existing, SelectedDates });
        } else {
            var NewSelectedDates_Existing = [];
            var NewSelectedDates = [];

            var FirstTime = moment(NewDateTime).format('HH:mm');
            var NewDate;

            for (var i = 0; i < SelectedDates.length; i++) {
                NewDate = moment(moment(SelectedDates[i]).format('YYYY-MM-DD') + ' ' + FirstTime).format('YYYY-MM-DD HH:mm');
                NewSelectedDates_Existing.push(NewDate);
                NewSelectedDates.push(NewDate);
            }

            this.setState({ SelectedDates_Existing: NewSelectedDates_Existing, SelectedDates: NewSelectedDates });
        }
    }

    onChangeDuration = event => {
        this.setState({ Duration: event.target.value });
    }

    onChangeEnduranceDataType = DataType => {
        // Used for Endurance Template to change between "dynamic" && "static"
        this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, DataType } });
    }

    onChangeExerciseNote = ({ CircuitWorkoutExerciseIndex, Note, WorkoutExerciseIndex }) => {
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Notes.Coach = Note;

        this.setState({ WorkoutExercises });
    }

    onChangeHourType = HourType => {
        this.setState({ HourType });
    }

    onChangeLocation = event => {
        this.setState({ Location: event.target.value });
    }

    onChangeWorkoutName = event => {
        this.setState({ WorkoutName: event.target.value });
    }

    onChangeTrainerNote = event => {
        this.setState({ TrainerNote: event.target.value });
    }

    onChangeWorkoutType = WorkoutType => {
        this.setState({ WorkoutType }, () => {
            if (!this.state.ActivityId && (WorkoutType === 1 || WorkoutType === 2)) {
                if (!this.props.AdditionalActivities.length && !this.props.RetrievedAdditionalActivitySettings) {
                    this.props.GetAdditionalActivityDetails({ ForWorkoutTemplates: false }).then(({ AdditionalActivities, Intensities }) => {
                        for (var i = 0; i < AdditionalActivities.length; i++) {
                            var { value: ActivityId } = AdditionalActivities[i];
    
                            if ((WorkoutType === 1 && ActivityId === 60) || (WorkoutType === 2 && ActivityId === 34)) {
                                var { label: ActivityName, Pictures: ActivityPictures } = AdditionalActivities[i];
    
                                this.setState({ ActivityId, ActivityName, ActivityPicture: ActivityPictures[0], ActivityPictureId: ActivityId, ActivityPictures });
                                break;
                            }
                        }
                    });
                }
            }
        });
    }

    onDeleteExercise = ({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex }) => {
        var NewWorkoutExercises = [];
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        WorkoutExercises.map((Circuit, index) => {
            NewWorkoutExercises.push({ ...Circuit });

            if (WorkoutExerciseIndex === index) {
                NewWorkoutExercises[NewWorkoutExercises.length - 1].WorkoutExercises = [];

                Circuit.WorkoutExercises.map((WE, WEIndex) => {
                    if (WEIndex !== CircuitWorkoutExerciseIndex) NewWorkoutExercises[NewWorkoutExercises.length - 1].WorkoutExercises.push({ ...WE });
                    
                    return null;
                });
            }

            return null;
        });

        var CircuitNumber = 1;
        var UpdatableCircuits = NewWorkoutExercises.map(Circuit => {
            Circuit.NumExercises = Circuit.WorkoutExercises.length;

            if (Circuit.WorkoutExercises.length === 1) Circuit = { ...Circuit, CircuitNumber: 0, Type: 'WorkoutExercise' };
            else {
                Circuit.CircuitNumber = CircuitNumber;
                CircuitNumber++;
            }

            return Circuit;
        }).filter(Circuit => !!Circuit.NumExercises);

        this.setState({ WorkoutExercises: UpdatableCircuits });
    }

    onDeleteLap = LapIndex => {
        var Laps = [ ...this.state.WorkoutLaps.Laps ];

        Laps = [ ...Laps.slice(0, LapIndex), ...Laps.slice(LapIndex + 1) ];

        var LapNumber = 1;
        Laps = Laps.map((Lap, index) => ({ ...Lap, LapNumber: Lap.LapType === 'Active' ? LapNumber++ : 0, LapOrder: index + 1 }));

        this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, Laps } });
    }

    onDeleteSet = ({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex, WorkoutSetIndex }) => {
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        // Delete Individual Set
            if (WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.length > 1) {
                WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets = [ ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.slice(0, WorkoutSetIndex), ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.slice(WorkoutSetIndex + 1) ];

                this.setState({ WorkoutExercises });
            }
        // Deleting last set, so remove full Workout Exercise
            else this.onDeleteExercise({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex });
    }

    onGetSize = Height => {
        this.setState({ DropdownSize: Height });
    }

    onSaveCircuits = WorkoutExercises => {
        this.setState({ ShowReorderExercises: false, WorkoutExercises });
    }

    onSelectActivityPicture = ({ ActivityPicture, ActivityPictureId }) => {
        this.setState({ ActivityPicture, ActivityPictureId });
    }

    onSelectAdditionalActivity = ({ ActivityId, ActivityName, ActivityPictures }) => {
        var ActivityPicture = this.state.ActivityPicture;
        var ActivityPictureId = this.state.ActivityPictureId;

        if (this.state.ActivityPictureId !== 'custom') {
            ActivityPicture = ActivityPictures[0];
            ActivityPictureId = ActivityId;
        }

        this.setState({ ActivityId, ActivityName, ActivityPicture, ActivityPictureId, ActivityPictures, DisplayingFilter: null });
    }

    onSelectCardioLevel = CardioLevel => {
        if (this.state.WorkoutLaps.CardioLevel !== CardioLevel) this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, CardioLevel } });
    }

    onSelectClients = ({ SelectedClients: SelectedUsers, SelectedGroups }) => {
        var SelectedUserIds_New = SelectedUsers.map(User => User.UserId);
        SelectedUsers = SelectedUsers.map(User => ({ ...User, Sex: User.Gender }));

        this.setState({ SelectedGroups, SelectedUsers, SelectedUserIds: SelectedUserIds_New });
    }

    onSelectDates = SelectedDates => {
        SelectedDates.sort(DateSort);

        this.setState({ SelectedDates, ShowChooseDatesModal: false });
    }

    onSelectIntensity = IntensityId => {
        this.setState({ IntensityId });
    }

    onSelectUseAllDays = () => {
        var { SelectedDates, SyncAllDateTimes } = this.state;
        
        if (SyncAllDateTimes) this.setState({ SyncAllDateTimes: false });
        else if (!SyncAllDateTimes && SelectedDates.length === 1) this.setState({ SyncAllDateTimes: true });
        
        // Sync all times and more than one date selected
        else {
            var NewSelectedDates_Existing = [];
            var NewSelectedDates = [];

            var FirstTime = moment(SelectedDates[0]).format('HH:mm');
            var NewDate;
            for (var i = 0; i < SelectedDates.length; i++) {
                if (i === 0) {
                    NewSelectedDates_Existing.push(SelectedDates[i]);
                    NewSelectedDates.push(SelectedDates[i]);
                } else {
                    NewDate = moment(moment(SelectedDates[i]).format('YYYY-MM-DD') + ' ' + FirstTime).format('YYYY-MM-DD HH:mm');
                    NewSelectedDates_Existing.push(NewDate);
                    NewSelectedDates.push(NewDate);
                }
            }

            this.setState({ SelectedDates_Existing: NewSelectedDates_Existing, SelectedDates: NewSelectedDates, SyncAllDateTimes: true });
        }
    }

    onToggleDropdown = (DisplayingFilter, ToggleStatus) => {
        if (DisplayingFilter === 'AdditionalActivities' && ToggleStatus && !this.props.RetrievedAdditionalActivitySettings) this.props.GetAdditionalActivityDetails({ ForWorkoutTemplates: false });

        this.setState({ DisplayingFilter: ToggleStatus ? DisplayingFilter : null });
    }

    onToggleMaxSet = ({ CircuitWorkoutExerciseIndex, MaxSet, WorkoutExerciseIndex, WorkoutSetIndex }) => {
        MaxSet = !!!+MaxSet;

        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets = WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.map((WorkoutSet, SetIndex) => {
            if (SetIndex === WorkoutSetIndex) return { ...WorkoutSet, MaxSet };
            else return { ...WorkoutSet, MaxSet: 0 }
        });

        this.setState({ WorkoutExercises });
    }

    onToggleShowAddExerciseModal = ShowAddExerciselModal => {
        this.setState({ ShowAddExerciselModal });
    }

    onToggleShowChooseDatesModal = ShowChooseDatesModal => {
        this.setState({ ShowChooseDatesModal });
    }

    onToggleShowCustomName = () => {
        this.setState({ ShowCustomName: !this.state.ShowCustomName });
    }

    onToggleShowDateTimes = () => {
        this.setState({ ShowDateTimes: !this.state.ShowDateTimes });
    }

    onToggleShowExerciseDetailModal = (ShowExerciseDetailModal, Exercise = {}) => {
        this.setState({ ShowExerciseDetailModal, ShowExerciseDetailPreview: Exercise });
    }

    onToggleShowLearnMoreModal = ShowLearnMoreModal => {
        this.setState({ ShowLearnMoreModal });
    }

    onToggleShowReorderExercises = ShowReorderExercises => {
        this.setState({ ShowReorderExercises });
    }

    onUpdateWorkoutLap = ({ Active, BreakTime, LapIndex, LapType, Rest, Speed }) => {
        if (LapType === 'Active') {
            Active = +Active;
            BreakTime = 0;
            Rest = +Rest;
            Speed = +Speed;
        } else if (LapType === 'Break') {
            Active = 0;
            BreakTime = +BreakTime;
            Rest = 0;
            Speed = 0;
        }

        var Laps = [ ...this.state.WorkoutLaps.Laps ];
        Laps[LapIndex] = { ...Laps[LapIndex], Active, BreakTime, Rest, Speed };

        this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, Laps } });
    }

    onUpdateWorkoutSet = ({ CircuitWorkoutExerciseIndex, Distance, MaxSet, Reps, Seconds, TotalBreak: Rest, Weight, WorkoutExerciseIndex, WorkoutSetIndex }) => {
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];
        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutSetIndex] = { ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutSetIndex], ...Distance && { Distance }, MaxSet, ...Reps && { Reps }, Rest, ...Seconds && { Seconds }, ...Weight && { Weight } };
        
        this.setState({ WorkoutExercises });
    }

    onUploadWorkoutImage = event => {
        if (event.target.files.length) {
            var SelectedImage = event.target.files[0];
            var FileExtension = getFileExtension(SelectedImage.name);

            if (validateFile({ FileExtension, FileType: 'Images' })) {
                if (SelectedImage.size <= this.state.MaxImageSize) {
                    var WorkoutImage = URL.createObjectURL(SelectedImage);

                    this.setState({ ActivityPicture: WorkoutImage, ActivityPictureId: 'custom', CopyImage: false, ImageTooLarge: false, SelectedImage, WorkoutImage, WrongImageType: false});
                } else {
                    document.getElementById('WorkoutImage').value = null;
                    this.setState({ CopyImage: false, ImageTooLarge: true, WrongImageType: false });
                }
            } else this.setState({ ImageTooLarge: false, WrongImageType: true });
        }
    }

    renderAddExerciseModal = () => {
        var { ShowAddExerciselModal, ShowExerciseDetailModal } = this.state;

        if (ShowAddExerciselModal) {
            return (
                <AddExercise
                    DisplayNone={ShowExerciseDetailModal}
                    ExcludeExercises={this.state.WorkoutExercises.map(({ WorkoutExercises }) => WorkoutExercises.map(({ ExerciseId }) => +ExerciseId))}
                    OnAddExercises={SelectedExercises => this.onAddExercises(SelectedExercises)}
                    OnHide={() => this.onToggleShowAddExerciseModal(false)}
                    OnToggleShowExerciseDetailModal={(ShowExerciseDetailModal, Exercise) => this.onToggleShowExerciseDetailModal(ShowExerciseDetailModal, Exercise)}
                    ReturnData={{ pathname: '/add-workout', state: { ...this.state, IsReturnData: true } }}
                    Show={ShowAddExerciselModal}
                />
            );
        }
    }

    renderAdditionalActivities = () => {
        var { AdditionalActivities } = this.props;

        return (
            <AdditionalActivityList>
                {
                    AdditionalActivities.map(({ label, Pictures, value }) => {
                        var Selected = value === this.state.ActivityId;

                        return (
                            <AdditionalActivityItem key={value} onClick={() => this.onSelectAdditionalActivity({ ActivityId: value, ActivityName: label, ActivityPictures: Pictures })} Selected={Selected}>
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={label} TextAlign="left" />
                            </AdditionalActivityItem>
                        );
                    })
                }
            </AdditionalActivityList>
        );
    }

    renderAdditionalActivity = () => {
        var { t } = this.props;
        var { Device, AdditionalActivities } = this.props;
        var { ActivityId, ActivityName, ActivityPicture, ActivityPictures, DisplayingFilter, DropdownSize } = this.state;

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('WorkoutPlan_ChooseActivity') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-3', Text: '*' },
                    ]}
                />

                <Spacer Size="extra-small" />

                <StyledDropdown
                    ContentRenderer={this.renderAdditionalActivities}
                    Disabled={false}
                    ForceClose={DisplayingFilter !== 'AdditionalActivities'}
                    GetSize={Height => this.onGetSize(Height)}
                    Height={DisplayingFilter === 'AdditionalActivities' && DropdownSize}
                    IsList={false}
                    IsMobile={Device === 'mobile_small' || Device === 'mobile'}
                    ItemId="AdditionalActivities"
                    NeedsToLoad={AdditionalActivities.length === 0}
                    OnToggleDropdown={ToggleStatus => this.onToggleDropdown('AdditionalActivities', ToggleStatus)}
                    SelectedItemIds={[]}
                    Title={ActivityName || t('WorkoutPlan_ChooseActivity')}
                    TitleFontColor={ActivityName ? 'blue-astronaut' : null}
                    TitleFontFamily="semibold"
                />

                <Spacer Size="extra-small" />

                {
                    ActivityPictures.length > 0 &&
                    <ActivityPicturesContainer>
                        {
                            ActivityPictures.map(Picture => {
                                var Selected = ActivityPicture === Picture;

                                return (
                                    <ActivityPictureContainer key={Picture} onClick={() => this.onSelectActivityPicture({ ActivityPicture: Picture, ActivityPictureId: ActivityId })} Selected={Selected}>
                                        <StyledActivityImage
                                            ContainerWidth="100%"
                                            ImageSrc={Picture}
                                            SelfLoading
                                        />
                                    </ActivityPictureContainer>
                                );
                            })
                        }
                    </ActivityPicturesContainer>
                }

                {
                    !!+ActivityId &&
                    <>
                        <Spacer Size="medium" />

                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('or_upload_your_own')} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        {this.renderUploadPicture()}
                    </>
                }
            </>
        );
    }

    renderAdditionalActivityDuration = () => {
        var { t } = this.props;
        var { AdditionalActivityDuration } = this.state;

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('WorkoutPlan_duration_on_minutes') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-3', Text: '*' },
                    ]}
                />

                <Spacer Size="extra-small" />

                <StyledDuration
                    NoDecimal
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeAdditionalActivityDuration}
                    Placeholder='0'
                    Size="medium"
                    Type="number"
                    Value={AdditionalActivityDuration}
                />
            </>
        );
    }

    renderAddTrainerNote = () => {
        var { t } = this.props;
        var { TrainerNote } = this.state;

        return (
            <TrainerNoteContainer>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('WorkoutPlan_Trainer_note_to_client')} TextAlign="center" />

                <Spacer Size="small" />

                <TrainerNoteTextArea
                    OnBlur={this.onChangeTrainerNote}
                    NumRows={4}
                    Placeholder={t('WorkoutPlan_Trainer_note_to_client_placeholder')}
                    Value={TrainerNote}
                />
            </TrainerNoteContainer>
        );
    }

    renderCardioLevels = () => {
        var { t } = this.props;
        var { Device } = this.props;

        var CardioLevels = [{ CardioLevel: t('low') }, { CardioLevel: t('medium') }, { CardioLevel: t('high') }];

        var CardioLevelButton = { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold'};
        var CardioLevelButtons = CardioLevels.map(({ CardioLevel }, CardioLevelIndex) => ({ ...CardioLevelButton, OnClick: () => this.onSelectCardioLevel(CardioLevelIndex + 1), Selected: (CardioLevelIndex + 1) === this.state.WorkoutLaps.CardioLevel, Title: CardioLevel }));

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-3', Text: t('WorkoutPlan_Intensity') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-3', Text: '*' },
                    ]}
                />
                
                <Spacer Size="small" />

                <ButtonGroup
                    Buttons={CardioLevelButtons}
                    ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />
            </>
        );
    }

    renderCircuitSeparator = ({ CircuitType }) => {
        var { t } = this.props;

        return (
            <CircuitSeparator BackgroundColor={CircuitType === 'Circuit' ? 'turquoise-dark' : 'blue-royal'}>
                <PageText FontColor="white" FontFamily="bold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t(`Workout_Set_Type_${CircuitType}`)} TextAlign="center" />
            </CircuitSeparator>
        );
    }

    renderCustomName = () => {
        var { t } = this.props;
        var { CustomName, ShowCustomName } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" Text={t('custom_name')} TextAlign="center" />
                    
                <DateTimeUseAllDaysContainer>
                    <DateTimeUseAllDays onClick={this.onToggleShowCustomName} Selected={ShowCustomName} />
                    <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={this.onToggleShowCustomName} Text={t('custom_name_additional_activity_info')} TextAlign="left" />
                </DateTimeUseAllDaysContainer>

                {
                    ShowCustomName &&
                    <>
                        <Spacer Size="small" />

                        <StyledDuration
                            NoDecimal
                            NoLabel
                            NoMargin
                            OnChange={this.onChangeCustomName}
                            Placeholder={t('custom_name')}
                            Size="medium"
                            Type="text"
                            Value={CustomName}
                        />
                    </>
                }
            </>
        );
    }

    renderDates = () => {
        var { t } = this.props;
        var { SelectedDates } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('choose_dates')} />
                
                <Spacer Size="small" />

                {
                    SelectedDates.length ?
                    this.renderSelectedDates()
                :
                    <ButtonGroup
                        Buttons={[
                            {
                                FontFamily: 'semibold',
                                Icon: Plus,
                                IconPosition: 'left',
                                OnClick: () => this.onToggleShowChooseDatesModal(true),
                                Title: t('add_dates')
                            }
                        ]}
                        ButtonWidth="fit-content"
                        NotTouching
                    />
                }
            </>
        );
    }

    renderDateTimes = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { HourType: StateHourType, SelectedDates, ShowDateTimes, SyncAllDateTimes } = this.state;

        if (!SelectedDates.length) return null;

        return (
            <>
                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('activity_start_time')} />

                <DateTimeUseAllDaysContainer>
                    <DateTimeUseAllDays onClick={this.onToggleShowDateTimes} Selected={ShowDateTimes} />
                    <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={this.onToggleShowDateTimes} Text={t('additional_activity_choosetimes')} TextAlign="left" />
                </DateTimeUseAllDaysContainer>

                {
                    ShowDateTimes &&
                    <>
                        <Spacer Size="small" />

                        <HourTypesContainer>
                            <HourType onClick={() => this.onChangeHourType(12)} Selected={StateHourType === 12}>
                                <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('hour_type', { HourType: '12' })} />
                            </HourType>
                            <HourType onClick={() => this.onChangeHourType(24)} Selected={StateHourType === 24}>
                                <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('hour_type', { HourType: '24' })} />
                            </HourType>
                        </HourTypesContainer>

                        <Spacer Size="small" />

                        <DateTimesContainer>
                            {
                                SelectedDates.map((SelectedDate, index) => {
                                    return (
                                        <DateTimeContainer key={SelectedDate}>
                                            <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={moment(SelectedDate).format('DD.MMM')} />
                                            
                                            <Spacer Size="extra-extra-small" />
                                            
                                            <TimePicker
                                                defaultValue={moment(SelectedDate)}
                                                disabled={SyncAllDateTimes && index !== 0}
                                                minuteStep={5}
                                                onChange={DateTime => this.onChangeDateTime(DateTime, index)}
                                                showSecond={false}
                                                use12Hours={StateHourType === 12}
                                                value={moment(SelectedDate)}
                                            />
                                            {
                                                index === 0 && SelectedDates.length > 1 ?
                                                <DateTimeUseAllDaysContainer>
                                                    <DateTimeUseAllDays onClick={this.onSelectUseAllDays} Selected={SyncAllDateTimes} />
                                                    <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" Text={t('use_for_all_days')} TextAlign="left" />
                                                </DateTimeUseAllDaysContainer>
                                            :
                                                null
                                            }
                                        </DateTimeContainer>
                                    );
                                })
                            }
                        </DateTimesContainer>
                    </>
                }
            </>
        );
    }

    renderEndurance = () => {
        var { t } = this.props;
        var { ActiveType, CardioLevel, DataType } = this.state.WorkoutLaps;

        if (!ActiveType || !CardioLevel || !DataType) {
            return <PageText FontFamily="medium-italic" FontSize="medium-2" NoMargin Text={t('fill_above_to_see_rest')} TextAlign="center" />;
        }

        var { Device } = this.props;
        var { Laps } = this.state.WorkoutLaps;

        var Units = this.props.UserDetails.PreferredMeasuringSystem.toLowerCase();
        
        var New_ActiveUnits = Units === 'imperial' ? 'yards' : 'meters';

        var Buttons = [];

        return (
            <WorkoutLapsContainer className="WorkoutLapsContainer">
                {
                    Laps.length > 0 ?
                    Laps.map(({ Active, ActiveUnits, BreakTime, LapNumber, LapType, Rest, Speed, SpeedUnits, WorkoutLapId }, LapIndex) => {                            
                        Buttons = [{ FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onAddLap(LapIndex), Title: t('add_endurance_lap') }]
                        if (LapType === 'Active' && ((Laps[LapIndex + 1] && Laps[LapIndex + 1].LapType === 'Active') || (!Laps[LapIndex + 1 ]))) Buttons.unshift({ FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onAddBreak(LapIndex), Title: t('add_endurance_break') });

                        ActiveUnits = ActiveUnits || New_ActiveUnits;

                        return (
                            <React.Fragment key={LapIndex}>
                                {(LapIndex !== 0) && <Spacer Size="extra-small" />}

                                <LapContainer className="LapContainer">
                                    <LapHeaderContainer className="LapHeaderContainer">
                                        {this.renderLapHeader(LapIndex, LapNumber, LapType, WorkoutLapId)}
                                    </LapHeaderContainer>
                                    <LapDetailsContainer className="LapDetailsContainer">
                                        {this.renderLapDetails({ Active, ActiveUnits, BreakTime, LapIndex, LapType, Rest, Speed, SpeedUnits, WorkoutLapId })}
                                    </LapDetailsContainer>
                                    {
                                        Device === 'laptop' &&
                                        <LapFooterContainer className="LapFooterContainer">
                                            <DeleteSetIcon
                                                Alt={`Delete lap ${WorkoutLapId}`}
                                                ImageSrc={DeleteX}
                                                Loading={false}
                                                OnClick={() => this.onDeleteLap(LapIndex)}
                                            />
                                        </LapFooterContainer>
                                    }
                                </LapContainer>

                                {
                                    ((Laps[LapIndex + 1]) || (LapIndex === Laps.length - 1)) &&
                                    <>
                                        <Spacer Size="extra-small" />

                                        <StyledAddLap
                                            Buttons={Buttons}
                                            ButtonWidth={Device === 'mobile_small' ? '100%' : 'fit-content'}
                                            NotTouching
                                            OwnRows={Device === 'mobile_small'}
                                        />
                                    </>
                                }
                            </React.Fragment>
                        );
                    })
                :
                    <>
                        <PageText FontFamily="medium-italic" FontSize="medium-2" NoMargin Text={t('add_first_exercise')} TextAlign="center" />
                        
                        <Spacer Size="extra-small" />

                        <StyledAddLap
                            Buttons={[{ FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onAddLap(0), Title: t('add_endurance_lap') }]}
                            ButtonWidth={Device === 'mobile_small' ? '100%' : 'fit-content'}
                            NotTouching
                            OwnRows={Device === 'mobile_small'}
                        />
                    </>
                }
            </WorkoutLapsContainer>
        );
    }

    renderEnduranceActiveType = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { WorkoutLaps: { ActiveType } } = this.state;

        var Buttons = [
            { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onChangeActiveType('Time'), Selected: ActiveType === 'Time', Title: t('Time') },
            { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onChangeActiveType('Distance'), Selected: ActiveType === 'Distance', Title: t('Distance') }
        ];

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-3', Text: t('WorkoutTemplate_Endurance_Time_or_Distance') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-3', Text: '*' },
                    ]}
                />

                <Spacer Size="small" />

                <ButtonGroup
                    Buttons={Buttons}
                    ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />
            </>
        );
    }

    renderEnduranceDataType = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { WorkoutLaps: { DataType } } = this.state;

        var Buttons = [
            { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onChangeEnduranceDataType('dynamic'), Selected: DataType === 'dynamic', Title: t('dynamic_str') },
            { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onChangeEnduranceDataType('static'), Selected: DataType === 'static', Title: t('static_str') }
        ];

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-3', Text: t('WorkoutTemplate_Endurance_Dynamic_or_Static') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-3', Text: '*' },
                    ]}
                />

                <Spacer Size="small" />

                <ButtonGroup
                    Buttons={Buttons}
                    ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />
            </>
        );
    }

    renderExerciseDetailModal = () => {
        var { ShowExerciseDetailModal, ShowExerciseDetailPreview } = this.state;

        if (ShowExerciseDetailModal) {
            return (
                <ExerciseDetail
                    ExerciseDetail_Preview={ShowExerciseDetailPreview}
                    OnHide={() => this.onToggleShowExerciseDetailModal(false)}
                    Show={ShowExerciseDetailModal}
                />
            );
        }
    }

    renderIntensities = () => {
        var { t } = this.props;
        var { Device, Intensities } = this.props;
        var Buttons = Intensities.map(({ IntensityId, IntensityName }) => ({ BackgroundColor: 'white', BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', OnClick: () => this.onSelectIntensity(IntensityId), Selected: this.state.IntensityId === IntensityId, Title: IntensityName }));

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('WorkoutPlan_Intensity') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-3', Text: '*' },
                    ]}
                />

                <Spacer Size="extra-small" />

                <StyledIntensities
                    Buttons={Buttons}
                    ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                    NotTouching
                    OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                />
            </>
        );
    }

    renderLapDetails = ({ Active, ActiveUnits, BreakTime, LapIndex, LapType, Rest, Speed, SpeedUnits, WorkoutLapId }) => {
        var { ActiveType, DataType } = this.state.WorkoutLaps;

        if (DataType === 'dynamic') SpeedUnits = '%';

        if (LapType === 'Active') {
            return (
                <ActiveLap
                    Active={Active}
                    ActiveUnits={ActiveUnits}
                    ActiveType={ActiveType}
                    DataType={DataType}
                    OnUpdateWorkoutLap={({ Active, Rest, Speed }) => this.onUpdateWorkoutLap({ Active, LapIndex, LapType, Rest, Speed })}
                    Rest={Rest}
                    SetType="Template"
                    Speed={Speed}
                    SpeedUnits={SpeedUnits}
                    WorkoutLapId={WorkoutLapId}
                />
            )
        } else if (LapType === 'Break') {
            return (
                <BreakLap
                    BreakTime={BreakTime}
                    OnUpdateWorkoutLap={({ BreakTime }) => this.onUpdateWorkoutLap({ BreakTime, LapIndex, LapType })}
                    WorkoutLapId={WorkoutLapId}
                />
            )
        }
    }

    renderLapHeader = (LapIndex, LapNumber, LapType, WorkoutLapId) => {
        var { t } = this.props;
        var { Device } = this.props;

        return (
            <LapHeaderContainer className="LapHeaderContainer">
                <LapHeaderContainerLeft>
                    {LapType === 'Active' && <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${t('lap_str_all_capital').toUpperCase()} ${LapNumber}`} TextAlign="right" />}
                    {LapType === 'Break' && <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('break').toUpperCase()} TextAlign="right" />}
                </LapHeaderContainerLeft>

                {
                    Device !== 'laptop' &&
                    <LapHeaderContainerRight>
                        <DeleteSetIcon
                            Alt={`Delete lap ${WorkoutLapId}`}
                            ImageSrc={DeleteX}
                            Loading={false}
                            OnClick={() => this.onDeleteLap(LapIndex)}
                        />
                    </LapHeaderContainerRight>
                }
            </LapHeaderContainer>
        );
    }

    renderLearnMoreModal = () => {
        var { ShowLearnMoreModal } = this.state;

        if (ShowLearnMoreModal) return <WorkoutTemplateFillIn OnHide={() => this.onToggleShowLearnMoreModal(false)} Show={ShowLearnMoreModal} />
    }

    renderLocation = () => {
        var { t } = this.props;
        var { Location } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('location')} TextAlign="center" />
  
                <Spacer Size="small" />

                <StyledDuration
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeLocation}
                    Placeholder={t('location_placeholder')}
                    Size="medium"
                    Type="text"
                    Value={Location}
                />
            </>
        );
    }

    renderReorderExercises = () => {
        var { WorkoutExercises } = this.state;

        return <WorkoutCircuits Circuits={WorkoutExercises} OnCancelCircuits={this.onCancelCircuits} OnSaveCircuits={this.onSaveCircuits} />;
    }

    renderSelectedDates = () => {
        var { SelectedDates } = this.state;

        return (
            <SelectedDatesContainer>
                {
                    SelectedDates.map(SelectedDate => 
                        <SelectedDateContainer key={SelectedDate}>
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={moment(SelectedDate).format('DD.MMM')} />
                        </SelectedDateContainer>
                    )
                }

                <EditIcon
                    ImageSrc={Edit_Dark}
                    Loading={false}
                    OnClick={() => this.onToggleShowChooseDatesModal(true)}
                />
            </SelectedDatesContainer>
        );
    }

    renderShowChooseDatesModal = () => {
        var { ShowChooseDatesModal } = this.state;

        if (ShowChooseDatesModal) {
            return (
                <ChooseDates
                    // MaxDates={1}
                    OnHide={() => this.onToggleShowChooseDatesModal(false)}
                    OnSelectDates={SelectedDates => this.onSelectDates(SelectedDates)}
                    SelectedDates={this.state.SelectedDates}
                    Show={ShowChooseDatesModal}
                />
            );
        }
    }

    renderStrength = () => {
        if (this.state.ShowReorderExercises) return this.renderReorderExercises();

        var { t } = this.props;
        var { Device } = this.props;
        var { WorkoutExercises } = this.state;

        return (
            <>
                <WorkoutExercisesContainer className="WorkoutExercisesContainer">
                    {
                        WorkoutExercises.length > 0 ?
                        WorkoutExercises.map((WorkoutExercise, WorkoutExerciseIndex) => {
                            var CircuitType = WorkoutExercise.NumExercises >= 3 ? 'Circuit' : WorkoutExercise.NumExercises === 2 ? 'Superset' : 'WorkoutExercise';

                            return (
                                <React.Fragment key={WorkoutExercise.WorkoutExercises[0].ExerciseId}>
                                    {WorkoutExerciseIndex !== 0 && <Spacer Size="medium" />}

                                    <CircuitContainer CircuitType={CircuitType}>
                                        {
                                            WorkoutExercise.WorkoutExercises.map((CircuitWorkoutExercise, CircuitWorkoutExerciseIndex) => {                
                                                return (
                                                    <React.Fragment key={CircuitWorkoutExercise.ExerciseId}>
                                                        {CircuitWorkoutExerciseIndex !== 0 && this.renderCircuitSeparator({ CircuitType })}
                    
                                                        <TemplateExerciseComponent
                                                            CircuitType={CircuitType}
                                                            Exercise={CircuitWorkoutExercise}
                                                            OnAddSet={() => this.onAddSet({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex })}
                                                            OnChangeDataType={DataType => this.onChangeDataType({ CircuitWorkoutExerciseIndex, DataType, WorkoutExerciseIndex })}
                                                            OnChangeExerciseNote={Note => this.onChangeExerciseNote({ CircuitWorkoutExerciseIndex, Note, WorkoutExerciseIndex })}
                                                            OnChangeMaxType={MaxType => this.onChangeMaxType({ CircuitWorkoutExerciseIndex, MaxType, WorkoutExerciseIndex })}
                                                            OnDeleteExercise={() => this.onDeleteExercise({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex })}
                                                            OnDeleteSet={WorkoutSetIndex => this.onDeleteSet({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex, WorkoutSetIndex })}
                                                            OnToggleMaxSet={(MaxSet, WorkoutSetIndex) => this.onToggleMaxSet({ CircuitWorkoutExerciseIndex, MaxSet, WorkoutExerciseIndex, WorkoutSetIndex })}
                                                            OnToggleShowExerciseDetailModal={(ShowExerciseDetailModal, Exercise) => this.onToggleShowExerciseDetailModal(ShowExerciseDetailModal, Exercise)}
                                                            OnToggleShowDataTypeInfoModal={ShowDataTypeInfoModal => this.onToggleShowDataTypeInfoModal(ShowDataTypeInfoModal)}
                                                            OnUpdateWorkoutSet={({ Distance, MaxSet, Reps, Seconds, TotalBreak, Weight, WorkoutSetIndex }) => this.onUpdateWorkoutSet({ CircuitWorkoutExerciseIndex, Distance, MaxSet, Reps, Seconds, TotalBreak, Weight, WorkoutExerciseIndex, WorkoutSetIndex })}
                                                        />
                                                    </React.Fragment>
                                                );
                                            })
                                        }    
                                    </CircuitContainer>
                                </React.Fragment>
                            );
                        })
                    :
                        <PageText FontFamily="medium-italic" FontSize="medium-2" NoMargin Text={t('add_first_exercise')} TextAlign="center" />
                    }
                </WorkoutExercisesContainer>

                <Spacer Size="medium" />

                <ButtonGroup
                    Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onToggleShowAddExerciseModal(true), Title: t('WorkoutPlan_add_new_exercise') }]}
                    ButtonWidth="100%"
                />

                {
                    WorkoutExercises.length > 0 &&
                    <>
                        <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                        <ButtonGroup
                            Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', FontFamily: 'semibold', OnClick: () => this.onToggleShowReorderExercises(true), Title: t('WorkoutPlan_reorder_exercises') }]}
                            ButtonWidth={Device === 'laptop' || Device === 'ipad' ? 'fit-content' : '100%'}
                            NotTouching
                        />
                    </>
                }
            </>
        );
    }

    renderUploadPicture = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { ActivityPictureId, ImageTooLarge, WorkoutImage, WrongImageType } = this.state;

        var Buttons = [{ BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: Camera, IconPosition: 'left', IsInput: true, InputProps: { accept: "image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg", id: "WorkoutImage", type: "file", onChange: this.onUploadWorkoutImage }, Title: t('Upload') }];

        return (
            <UploadContainer>
                <UploadButtonsRow>
                    <UploadButtonsContainer
                        Buttons={Buttons}
                        ButtonWidth="fit-content"
                        ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                        NotTouching
                        OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                    />
                </UploadButtonsRow>

                {
                    WorkoutImage &&
                    <ActivityPicturesContainer>
                        <ActivityPictureContainer onClick={() => this.onSelectActivityPicture({ ActivityPicture: WorkoutImage, ActivityPictureId: 'custom' })} Selected={ActivityPictureId === 'custom'}>
                            <StyledActivityImage
                                ContainerWidth="100%"
                                ImageSrc={WorkoutImage}
                                SelfLoading
                            />
                        </ActivityPictureContainer>
                    </ActivityPicturesContainer>
                }
                                
                {
                    (ImageTooLarge || WrongImageType) &&
                    <>
                        <Spacer Size="small" />

                        <PageText
                            FontColor="red-bittersweet"
                            FontFamily="semibold-italic"
                            FontSize="medium-1"
                            Text={t(ImageTooLarge ? 'media_wrongsize' : 'media_wrongtype', { AllowedTypes: '.jpg, .png, .jpeg', MaxSize: '10MB', Type: 'Image' })}
                            TextAlign="center"
                        />
                    </>
                }
            </UploadContainer>
        );
    }

    renderWorkoutDuration = () => {
        var { t } = this.props;
        var { Duration } = this.state;

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('WorkoutPlan_duration_on_minutes') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                    ]}
                />

                <Spacer Size="small" />
                
                <StyledDuration
                    NoDecimal
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeDuration}
                    Placeholder='0'
                    Size="medium"
                    Type="number"
                    Value={Duration}
                />
            </>
        );
    }

    renderWorkoutName = () => {
        var { t } = this.props;
        var { WorkoutName } = this.state;

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('custom_name') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                    ]}
                />

                <Spacer Size="small" />

                <StyledWorkoutName
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeWorkoutName}
                    Placeholder={t('custom_name')}
                    Size="medium"
                    Type="text"
                    Value={WorkoutName}
                />

                <Spacer Size="extra-extra-small" />

                <PageText FontFamily="medium-italic" FontSize="medium-1" Text={t('this_is_also_name_of_workout')} TextAlign="center" />
            </>
        );
    }

    renderWorkoutType = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { WorkoutType } = this.state;

        var Buttons = [
            { BackgroundColor: 'white-concrete', Border: WorkoutType === 1 ? '5px solid var(--blue-astronaut)' : '5px solid var(--white-concrete)', BorderHover: '5px solid var(--blue-astronaut)', ColorHover: 'black', FontFamily: WorkoutType === 1 ? 'semibold' : 'medium', Icon: StrengthIllustration, IconPosition: 'top', IconSize: { Height: '100px' }, OnClick: () => this.onChangeWorkoutType(1), Title: t('Strength') },
            { BackgroundColor: 'white-concrete', Border: WorkoutType === 2 ? '5px solid var(--blue-astronaut)' : '5px solid var(--white-concrete)', BorderHover: '5px solid var(--blue-astronaut)', ColorHover: 'black', FontFamily: WorkoutType === 2 ? 'semibold' : 'medium', Icon: EnduranceIllustration, IconPosition: 'top', IconSize: { Height: '100px' }, OnClick: () => this.onChangeWorkoutType(2), Title: t('Endurance') },
            { BackgroundColor: 'white-concrete', Border: WorkoutType === 3 ? '5px solid var(--blue-astronaut)' : '5px solid var(--white-concrete)', BorderHover: '5px solid var(--blue-astronaut)', ColorHover: 'black', FontFamily: WorkoutType === 3 ? 'semibold' : 'medium', Icon: ActivityIllustration, IconPosition: 'top', IconSize: { Height: '100px' }, OnClick: () => this.onChangeWorkoutType(3), Title: t('additional_activity') }
        ];

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-3', Text: t('what_type_of_workout_session') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-3', Text: '*' },
                    ]}
                />

                <Spacer Size="small" />

                <ButtonGroup
                    Buttons={Buttons}
                    ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />
            </>
        );
    }

    render() {
        if (this.state.Loading || this.props.TryingGetWorkoutDetail) return <Loading />;

        var { t } = this.props;
        var { Device, TryingGetAdditionalActivityDetails, TryingAddNewWorkout, UploadProgress } = this.props;
        var { ActivityId, ActivityPicture, ActivityPictureId, AdditionalActivityDuration, Duration, IntensityId, SelectedDates, SelectedGroups, SelectedUserIds, SelectedUsers, WorkoutExercises, WorkoutImage, WorkoutLaps: { ActiveType, CardioLevel, DataType, Laps }, WorkoutName, WorkoutType } = this.state;

        var ActionText = t('WorkoutPlan_Create_workout');
        var Breadcrumbs = [{ Name: ActionText }];

        var Disabled = WorkoutType && SelectedDates.length > 0 && (Object.entries(SelectedGroups).length !== 0 || SelectedUserIds.length > 0) && ActivityId && ActivityPicture && ((ActivityPictureId === 'custom' && WorkoutImage) || ActivityId === ActivityPictureId) && (
            (WorkoutType === 1 && Duration && WorkoutName && WorkoutExercises.length) ||
            (WorkoutType === 2 && Duration && WorkoutName && ActiveType && CardioLevel && DataType && Laps.length) ||
            (WorkoutType === 3 && ActivityId && +AdditionalActivityDuration && IntensityId)
        ) ? false : true;

        var SubmitButtonProps = { Disabled, OnClick: this.onAddWorkout, Title: ActionText }

        return (
            <>
                {(TryingGetAdditionalActivityDetails || TryingAddNewWorkout) && <Loading />}

                <Page renderBreadcrumbs={Breadcrumbs} renderSubmitButton={SubmitButtonProps} >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={ActionText} />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderWorkoutType()}

                    {
                        WorkoutType &&
                        <>
                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            <AssignClients
                                EligibleTypes="Both"
                                GetStartedEligible="Workouts"
                                HorizontalScroll={false}
                                OnSelectClients={Users => this.onSelectClients(Users)}
                                SelectedGroups={SelectedGroups}
                                SelectedUserIds={SelectedUserIds}
                                SelectedUsers={SelectedUsers}
                            />

                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {this.renderDates()}

                            {this.renderDateTimes()}

                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {this.renderAdditionalActivity()}

                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {this.renderLocation()}
                        </>
                    }

                    {
                        (WorkoutType === 1 || WorkoutType === 2) &&
                        <>
                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {this.renderWorkoutName()}

                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {
                                WorkoutType === 2 &&
                                <>
                                    {this.renderEnduranceActiveType()}

                                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                                    {this.renderEnduranceDataType()}

                                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                                    {this.renderCardioLevels()}

                                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />
                                </>
                            }

                            {WorkoutType === 1 && this.renderStrength()}
                            {WorkoutType === 2 && this.renderEndurance()}

                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {this.renderWorkoutDuration()}
                        </>
                    }

                    {
                        WorkoutType === 3 &&
                        <>
                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {this.renderCustomName()}

                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />
                            
                            {this.renderIntensities()}

                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {this.renderAdditionalActivityDuration()}
                        </>
                    }

                    {
                        WorkoutType &&
                        <>
                            <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                            {this.renderAddTrainerNote()}
                        </>
                    }

                    {
                        UploadProgress.Workout && Object.entries(UploadProgress.Workout).length > 0 && 
                            <>
                                <Spacer Size="medium" />

                                <UploadProgressComponent UploadingItems={UploadProgress.Workout} />
                            </>
                    }
                </Page>

                {this.renderAddExerciseModal()}
                {this.renderExerciseDetailModal()}
                {this.renderLearnMoreModal()}
                {this.renderShowChooseDatesModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        UserDetails: state.Auth.UserDetails,

        ClientDetails: state.SaveData.ClientDetails,
        DayDate: state.SaveData.DayDate,
        GroupDetails: state.SaveData.GroupDetails,

        AdditionalActivities: state.AdditionalActivities.AdditionalActivities,
        Intensities: state.AdditionalActivities.Intensities,
        RetrievedAdditionalActivitySettings: state.AdditionalActivities.RetrievedAdditionalActivitySettings,

        UploadProgress: state.UploadContent.UploadProgress,

        TryingGetAdditionalActivityDetails: state.AdditionalActivities.TryingGetAdditionalActivityDetails,
        TryingGetAdditionalActivityDetailsError: state.AdditionalActivities.TryingGetAdditionalActivityDetailsError,

        TryingAddNewWorkout: state.Workouts.TryingAddNewWorkout,
        TryingAddNewWorkoutError: state.Workouts.TryingAddNewWorkoutError,

        TemplateToWorkoutDetails: state.SaveData.TemplateToWorkoutDetails
    };
};

export default withTranslation()(connect(mapStateToProps, { AddNewWorkout, ClearAdditionalActivityDetails, ClearSaveData, GetAdditionalActivityDetails, GetWorkoutTemplateDetail, SaveDataInProps, UpdateSelectedTab, UploadToPresignedUrl } )(AddWorkout));