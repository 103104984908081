import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DownloadContractPDF, ViewOrganizationMemberContracts, ViewOrganizationMemberContractsFilters } from '../../Services/Actions';
import moment from 'moment';

import * as styles from './styles';
import * as IStyles from './Invoice_styles';

import Filters from './Contracts_Filters';
import Header from './Header';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Info from '../../Modals/Info';

import { PageRange } from '../../Functions';

import DownArrow from '../../Assets/Icons/DownArrow.png';
import DownloadIcon from '../../Assets/Icons/Download_Gray.png';
import { ReactComponent as FilterSVG } from '../../Assets/SVG/Filter.svg';
import UpArrow from '../../Assets/Icons/UpArrow.png';

class ContractComponent extends React.Component {
    onDownloadContract = () => {
        this.props.OnDownloadContract(this.props.Contract.ContractId);
    }

    render() {
        var { t } = this.props;
        var { Device } = this.props;
        var { BranchName, Client, UserSigned, RegionName, TotalValueText, CompletionPercentage, Products, Trainer } = this.props.Contract;

        if (Device !== 'laptop') {
            return (
                <IStyles.InvoiceContainerMobile className="InvoiceContainerMobile">
                    {/* Download Icon */}
                        <IStyles.InfoContainerMobile className="InfoContainerMobile">
                            <ButtonGroup
                                Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', FontFamily: 'semibold', IconOnly: true, Icon: DownloadIcon, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: this.onDownloadContract, Title: t('download.pdf') }]}
                                ButtonWidth="fit-content"
                                NotTouching
                            />
                            <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('download.pdf')} />
                        </IStyles.InfoContainerMobile>

                    {/* Coach Name */}
                        <IStyles.InfoContainerMobile className="InfoContainerMobile">
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'small', Text: `${t('coach')}:` },
                                    { FontFamily: 'medium', FontSize: 'small', Text: Trainer }
                                ]}
                            />
                        </IStyles.InfoContainerMobile>

                    {/* Client Name */}
                        <IStyles.InfoContainerMobile className="InfoContainerMobile">
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'small', Text: `${t('client')}:` },
                                    { FontFamily: 'medium', FontSize: 'small', Text: Client }
                                ]}
                            />
                        </IStyles.InfoContainerMobile>

                    {/* Branch Name */}
                        <IStyles.InfoContainerMobile className="InfoContainerMobile">
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'small', Text: `${t('_branch')}:` },
                                    { FontFamily: 'medium', FontSize: 'small', Text: BranchName }
                                ]}
                            />
                        </IStyles.InfoContainerMobile>

                    {/* Region Name */}
                        <IStyles.InfoContainerMobile className="InfoContainerMobile">
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'small', Text: `${t('_region')}:` },
                                    { FontFamily: 'medium', FontSize: 'small', Text: BranchName ? RegionName ? RegionName : t('_no_region') : '' }
                                ]}
                            />
                        </IStyles.InfoContainerMobile>

                    {/* Signed Date */}
                        <IStyles.InfoContainerMobile className="InfoContainerMobile">
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'small', Text: `${t('_signed_date')}:` },
                                    { FontFamily: 'medium', FontSize: 'small', Text: moment(UserSigned).utc().format('[NumbersDate]') }
                                ]}
                            />
                        </IStyles.InfoContainerMobile>

                    {/* Completion % */}
                        <IStyles.InfoContainerMobile className="InfoContainerMobile">
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'small', Text: `${t('_completion')}:` },
                                    { FontColor: CompletionPercentage === 100 ? 'success-green' : 'black', FontFamily: 'medium', FontSize: 'small', Text: `${CompletionPercentage}%` }
                                ]}
                            />
                        </IStyles.InfoContainerMobile>

                    {/* Value */}
                        <IStyles.InfoContainerMobile className="InfoContainerMobile">
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'small', Text: `${t('_value')}:` },
                                    { FontFamily: 'medium', FontSize: 'small', Text: TotalValueText }
                                ]}
                            />
                        </IStyles.InfoContainerMobile>

                    {/* Products */}
                        <IStyles.ProductContainerMobile className="ProductContainerMobile">
                            <MultiText Texts={[{ FontFamily: 'semibold', FontSize: 'small', Text: `${t('_products')}:` }]} />

                            <IStyles.ProductListContainer className="ProductListContainer">
                                <ul>
                                    {
                                        Products.map(({ ProductId, ProductName, ProductQuantity }) => {
                                            return <li key={ProductId}><PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${ProductQuantity} ${ProductName}`} /></li>
                                        })
                                    }
                                </ul>
                            </IStyles.ProductListContainer>
                        </IStyles.ProductContainerMobile>
                </IStyles.InvoiceContainerMobile>
            );
        }
          
        return (
            <IStyles.ContractContainer className="ContractContainer">
                {/* Download Icon */}
                    <ButtonGroup
                        Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', FontFamily: 'semibold', IconOnly: true, Icon: DownloadIcon, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: this.onDownloadContract }]}
                        NotTouching
                    />
                {/* Coach Name */}
                    <IStyles.InfoContainer className="InfoContainer">
                        <IStyles.StyledEllipsisText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Trainer} />
                    </IStyles.InfoContainer>

                {/* Client Name */}
                    <IStyles.InfoContainer className="InfoContainer">
                        <IStyles.StyledEllipsisText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Client} />
                    </IStyles.InfoContainer>

                {/* Branch Name */}
                    <IStyles.InfoContainer className="InfoContainer">
                        <IStyles.StyledEllipsisText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={BranchName} />
                    </IStyles.InfoContainer>

                {/* Region Name */}
                    <IStyles.InfoContainer className="InfoContainer">
                        <IStyles.StyledEllipsisText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={BranchName ? RegionName ? RegionName : t('_no_region') : ''} />
                    </IStyles.InfoContainer>

                {/* Signed Date */}
                    <IStyles.InfoContainer className="InfoContainer">
                        <IStyles.StyledEllipsisText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={moment(UserSigned).utc().format('[NumbersDate]')} />
                    </IStyles.InfoContainer>

                {/* Completion % */}
                    <IStyles.InfoContainer className="InfoContainer">
                        <IStyles.StyledEllipsisText FontColor={CompletionPercentage === 100 ? 'success-green' : 'black'} FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${CompletionPercentage}%`} />
                    </IStyles.InfoContainer>

                {/* Value */}
                    <IStyles.InfoContainer className="InfoContainer">
                        <IStyles.StyledEllipsisText FontFamily="medium" FontSize="small" JustifyContent="flex-end" NoMargin TextAlign="left" Text={`${TotalValueText}`} />
                    </IStyles.InfoContainer>

                {/* Products */}
                    <IStyles.InfoContainer className="InfoContainer">
                        <IStyles.StyledEllipsisText FontColor="abel-blue" FontColorHover="abel-blue-hover" FontFamily="medium" FontSize="small" JustifyContent="flex-end" NoMargin OnClick={this.props.OnToggleShowContractProductsModal} TextAlign="left" Text={t('_view_products')} />
                    </IStyles.InfoContainer>
            </IStyles.ContractContainer>
        )
    }
}

class OrganizationContracts extends React.Component {
    state = {
        HasLoadedFilters: false,
        FirstLoad: false,
        FiltersUpdated: false,
        FilterOptions: {
            Coaches: [],
            Clients: [],
            Branches: [],
            Products: [],
            Regions: []
        },
        Filters: {
            Coaches: [],
            Clients: [],
            Branches: [],
            Products: [],
            Regions: [],
            SignedDate: {
                StartDate: null,
                EndDate: null
            },
            InsideOrg: true,
            OutsideOrg: true
        },
        Sorting: {
            SortCol: 'SignedDate',
            SortDir: 'DESC'
        },
        Contracts: [],
        IsMoreResults: false,
        PageNo: 1,
        PageSize: 20,
        TotalRecords: 0,
        ShowContractProductsModal: false,
        ShowContractProductsModal_Contract: {},
        ShowFiltersModals: false
    };
    
    componentDidMount() {
        this.onLoadContracts();
    }

    onDownloadContract = ContractId => {
        this.props.DownloadContractPDF({ ContractId });
    }

    onBlurFilter = () => {
        if (this.state.FiltersUpdated) {
            this.setState({ FiltersUpdated: false, PageNo: 1 }, () => {
                this.onLoadContracts();
            })
        }
    }

    onChangeDate = ({ StartDate, EndDate, Type }) => {
        var Filters = { ...this.state.Filters };

        // Calendar dates were selected (otherwise they are null as calendar was cleared)
            if (StartDate !== null && EndDate !== null) {
                StartDate = StartDate.toISOString().slice(0, 10);
                EndDate = EndDate.toISOString().slice(0, 10);
            }

        Filters[Type] = { StartDate, EndDate };

        this.setState({ Filters, PageNo: 1 }, () => {
            this.onLoadContracts();
        });
    }
    
    onChangeFilter = ({ Data, Type }) => {
        var Filters = { ...this.state.Filters };
        Filters[Type] = Data;

        // this.setState({ Filters, FiltersUpdated: true });

        this.setState({ Filters, PageNo: 1 }, () => {
            this.onLoadContracts();
        })
    }

    onLoadFilters = () => {
        if (this.state.HasLoadedFilters) return null;

        this.props.ViewOrganizationMemberContractsFilters().then(({ Branches, Clients, Coaches, Products, Regions }) => {
            var FilterOptions = { ...this.state.FilterOptions, Branches, Clients, Coaches, Products, Regions };
            this.setState({ HasLoadedFilters: true, FilterOptions });
        })
    }

    onLoadContracts = () => {
        var { Filters, PageNo, PageSize, Sorting } = this.state;
        var { Coaches, Clients, Branches, InsideOrg, OutsideOrg, Products, Regions, SignedDate } = Filters;

        // Ignore Branches and Regions If Only Filtering For OutsideOrg
        var BranchIds = (!InsideOrg && !!OutsideOrg) ? '' : Branches.map(({ BranchId }) => BranchId).join(',');
        var ClientIds = Clients.map(({ UserId }) => UserId).join(',');
        var ProductIds = Products.map(({ ProductId }) => ProductId).join(',');
        var RegionIds = (!InsideOrg && !!OutsideOrg) ? '' : Regions.map(({ RegionId }) => RegionId).join(',');
        var TrainerIds = Coaches.map(({ UserId }) => UserId).join(',');
        var { EndDate, StartDate } = SignedDate;
        var { SortCol, SortDir } = Sorting;

        this.props.ViewOrganizationMemberContracts({ BranchIds, ClientIds, EndDate, InsideOrg, OutsideOrg, PageNo, PageSize, ProductIds, RegionIds, SortCol, SortDir, StartDate, TrainerIds }).then(({ Contracts, IsMoreResults, TotalRecords }) => {
            this.setState({ FirstLoad: true, Contracts, IsMoreResults, TotalRecords });
        })
    }

    onPaginate = ({ NewPageNo }) => {
        if (this.state.PageNo !== NewPageNo) this.setState({ PageNo: NewPageNo }, () => this.onLoadContracts());
    }

    onSelectInsideOrg = () => {
        var Filters = { ...this.state.Filters };
        Filters.InsideOrg = !this.state.Filters.InsideOrg;

        // Hide All Contracts If Both InsideOrg And OutsideOrg are false, w/o calling API
            if (!Filters.InsideOrg && !Filters.OutsideOrg) this.setState({ Filters, Contracts: [], IsMoreResults: 0, TotalRecords: 0 })
            else {
                this.setState({ Filters, PageNo: 1 }, () => {
                    this.onLoadContracts();
                });
            }
    }

    onSelectOutsideOrg = () => {
        var Filters = { ...this.state.Filters };
        Filters.OutsideOrg = !this.state.Filters.OutsideOrg;

        // Hide All Contracts If Both InsideOrg And OutsideOrg are false, w/o calling API
        if (!Filters.InsideOrg && !Filters.OutsideOrg) this.setState({ Filters, Contracts: [], IsMoreResults: 0, TotalRecords: 0 })
        else {
            this.setState({ Filters, PageNo: 1 }, () => {
                this.onLoadContracts();
            });
        }
    }

    onToggleShowFiltersModal = ShowFiltersModals => {
        this.setState({ ShowFiltersModals });
    }

    onToggleShowContractProductsModal = ({ Contract = {}, ShowContractProductsModal }) => {
        this.setState({ ShowContractProductsModal_Contract: Contract, ShowContractProductsModal });
    }

    onToggleSorting = ({ SortCol: NewSortCol }) => {
        var { SortCol, SortDir } = this.state.Sorting;

        // SortDir Goes From ASC --> DESC --> Nothing

        // Toggle Sorting Of Already Sorted Column
            if (SortCol === NewSortCol) {
                if (SortDir === 'ASC') SortDir = 'DESC';
                else if (SortDir === 'DESC') {
                    SortDir = '';
                    SortCol = '';
                }
                else if (SortDir === '') SortDir = 'ASC';
            }
        // Start Sorting On New Column
            else {
                SortCol = NewSortCol;
                SortDir = 'ASC';
            }

        this.setState({ Sorting: { SortCol, SortDir } }, () => this.onLoadContracts());
    }

    renderContracts = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { FirstLoad, Contracts, Sorting: { SortCol, SortDir } } = this.state;

        if (!FirstLoad) return null;

        if (!Contracts.length) return <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_no_results')} />

        if (Device !== 'laptop') {
            return (
                <>
                    {this.renderPagination()}

                    <Spacer Size="small" />

                    <IStyles.InvoicesContainerMobile className="InvoicesContainerMobile">
                        {
                            Contracts.map(Contract => {
                                return <ContractComponent key={Contract.ContractId} Device={Device} Contract={Contract} OnDownloadContract={this.onDownloadContract} t={t} />
                            })
                        }
                    </IStyles.InvoicesContainerMobile>
                </>
            );
        }

        return (
            <>
                {this.renderPagination()}

                <Spacer Size="small" />

                <IStyles.InvoicesContainer className="InvoicesContainer">
                    <IStyles.ContractContainer className="ContractContainer" Header>
                        <div />
                        {/* Coach Name */}
                            <IStyles.InfoContainer className="InfoContainer">
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" OnClick={() => this.onToggleSorting({ SortCol: 'CoachName' })} NoMargin TextAlign="left" Text={t('coach')} />
                                {SortCol === 'CoachName' && <IStyles.SortingIcon ImageSrc={SortDir === 'DESC' ? DownArrow : UpArrow} OnClick={() => this.onToggleSorting({ SortCol: 'CoachName' })} />}
                            </IStyles.InfoContainer>

                        {/* Client Name */}
                            <IStyles.InfoContainer className="InfoContainer">
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onToggleSorting({ SortCol: 'ClientName' })} TextAlign="left" Text={t('client')} />
                                {SortCol === 'ClientName' && <IStyles.SortingIcon ImageSrc={SortDir === 'DESC' ? DownArrow : UpArrow} OnClick={() => this.onToggleSorting({ SortCol: 'ClientName' })} />}
                            </IStyles.InfoContainer>

                        {/* Branch Name */}
                            <IStyles.InfoContainer className="InfoContainer">
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onToggleSorting({ SortCol: 'BranchName' })} TextAlign="left" Text={t('_branch')} />
                                {SortCol === 'BranchName' && <IStyles.SortingIcon ImageSrc={SortDir === 'DESC' ? DownArrow : UpArrow} OnClick={() => this.onToggleSorting({ SortCol: 'BranchName' })} />}
                            </IStyles.InfoContainer>

                        {/* Region Name */}
                            <IStyles.InfoContainer className="InfoContainer">
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onToggleSorting({ SortCol: 'RegionName' })} TextAlign="left" Text={t('_region')} />
                                {SortCol === 'RegionName' && <IStyles.SortingIcon ImageSrc={SortDir === 'DESC' ? DownArrow : UpArrow} OnClick={() => this.onToggleSorting({ SortCol: 'RegionName' })} />}
                            </IStyles.InfoContainer>

                        {/* Signed Date */}
                            <IStyles.InfoContainer className="InfoContainer">
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" OnClick={() => this.onToggleSorting({ SortCol: 'SignedDate' })} NoMargin TextAlign="left" Text={t('_signed_date')} />
                                {SortCol === 'SignedDate' && <IStyles.SortingIcon ImageSrc={SortDir === 'DESC' ? DownArrow : UpArrow} OnClick={() => this.onToggleSorting({ SortCol: 'SignedDate' })} />}
                            </IStyles.InfoContainer>

                        {/* Completion */}
                            <IStyles.InfoContainer className="InfoContainer">
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" OnClick={() => this.onToggleSorting({ SortCol: 'CompletionPercentage' })} NoMargin TextAlign="left" Text={t('_completion')} />
                                {SortCol === 'CompletionPercentage' && <IStyles.SortingIcon ImageSrc={SortDir === 'DESC' ? DownArrow : UpArrow} OnClick={() => this.onToggleSorting({ SortCol: 'CompletionPercentage' })} />}
                            </IStyles.InfoContainer>

                        {/* Value */}
                            <IStyles.InfoContainer className="InfoContainer">
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-end" OnClick={() => this.onToggleSorting({ SortCol: 'TotalValue' })} NoMargin TextAlign="left" Text={t('_value')} />
                                {SortCol === 'TotalValue' && <IStyles.SortingIcon ImageSrc={SortDir === 'DESC' ? DownArrow : UpArrow} OnClick={() => this.onToggleSorting({ SortCol: 'TotalValue' })} />}
                            </IStyles.InfoContainer>

                        {/* Products */}
                            <IStyles.InfoContainer className="InfoContainer">
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-end" NoMargin TextAlign="left" Text={t('_products')} />
                            </IStyles.InfoContainer>
                    </IStyles.ContractContainer>
                    {
                        Contracts.map(Contract => {
                            return <ContractComponent key={Contract.ContractId} Device={Device} Contract={Contract} OnDownloadContract={this.onDownloadContract} OnToggleShowContractProductsModal={() => this.onToggleShowContractProductsModal({ Contract, ShowContractProductsModal: true })} t={t} />
                        })
                    }
                </IStyles.InvoicesContainer>
            </>
        )
    }

    renderPagination = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { PageNo, PageSize, TotalRecords } = this.state;

        var CurrentPageNo = PageNo;
        var TotalPages = Math.ceil(TotalRecords / PageSize);

        var { EndPageNo, StartPageNo } = PageRange({ PageNo: CurrentPageNo, TotalPages });

        return (
            <IStyles.PaginationContainer className="PaginationContainer">
                <IStyles.LeftPagination className="LeftPagination" HasPrevLink={CurrentPageNo !== 1}>
                    {
                        TotalPages !== 1 &&
                        <>
                            {CurrentPageNo !== 1 && <PageText FontColorHover={'blue-abel'} FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onPaginate({ NewPageNo: CurrentPageNo - 1 })} Text={t('prev')} TextAlign="left" />}

                            <IStyles.PageNumbersContainer className="PageNumbersContainer">
                                {
                                    Array((EndPageNo - StartPageNo) + 1).fill().map((item, index) => {
                                        var Selected = CurrentPageNo === StartPageNo + index;

                                        return (
                                            <IStyles.PageNumberContainer className="PageNumberContainer" key={index} onClick={() => this.onPaginate({ NewPageNo: StartPageNo + index })} Selected={Selected}>
                                                {`${StartPageNo + index}`}
                                            </IStyles.PageNumberContainer>
                                        );
                                    })
                                }
                            </IStyles.PageNumbersContainer>

                            {CurrentPageNo !== TotalPages && <PageText FontColorHover={'blue-abel'} FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-end" NoMargin OnClick={() => this.onPaginate({ NewPageNo: CurrentPageNo + 1 })} Text={t('next_with_first_char_uppercase')} TextAlign="right" />}
                        </>
                    }
                </IStyles.LeftPagination>
                
                {TotalPages !== 1 && Device === 'mobile_small' && <Spacer Size="extra-small" />}

                <IStyles.RightPagination className="RightPagination">
                    {this.renderViewCount()}
                </IStyles.RightPagination>
            </IStyles.PaginationContainer>
        );
    }

    renderBody = () => {
        var { t } = this.props;
        var { Device, TryingViewOrganizationMemberContractsFilters } = this.props;
        var { Coaches, Clients, Branches, Products, Regions } = this.state.FilterOptions;
        var { InsideOrg, OutsideOrg, SignedDate } = this.state.Filters;

        return (
            <>
                {<Header PageTitle={this.props.PageTitle} />}

                {
                    Device === 'laptop' &&
                    <Filters
                        Branches={Branches}
                        Clients={Clients}
                        Coaches={Coaches}
                        InsideOrg={InsideOrg}
                        OutsideOrg={OutsideOrg}
                        Products={Products}
                        Regions={Regions}
                        SignedDate={SignedDate}
                        Loading={TryingViewOrganizationMemberContractsFilters}
                        OnBlurFilter={this.onBlurFilter}
                        OnChangeFilter={this.onChangeFilter}
                        OnFocusFilter={this.onLoadFilters}
                        OnChangeDate={this.onChangeDate}
                        OnSelectInsideOrg={this.onSelectInsideOrg}
                        OnSelectOutsideOrg={this.onSelectOutsideOrg}
                    />
                }

                <styles.BodyContainer className="BodyContainer">
                    {this.renderOverallSummary()}

                    {
                        Device !== 'laptop' &&
                        <>
                            <Spacer Size="small" />

                            <ButtonGroup
                                Buttons={[{ BackgroundColor: "white-concrete", BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', IconOnly: true, IconSVG: FilterSVG, IconSVGColor: 'black', IconPosition: 'left', OnClick: () => this.onToggleShowFiltersModal(true), Title: t('_select_filters') }]}
                                NotTouching
                            />
                        </>
                    }

                    <Spacer Size="small" />

                    {this.renderContracts()}
                </styles.BodyContainer>
            </>
        );
    }

    renderContractProductsModal_Info = () => {
        var { t } = this.props;
        var { ShowContractProductsModal_Contract } = this.state;
        
        return (
            <IStyles.ProductContainerMobile className="ProductContainerMobile">
                <MultiText Texts={[{ FontFamily: 'semibold', FontSize: 'small', Text: `${t('_products')}:` }]} />

                <IStyles.ProductListContainer className="ProductListContainer">
                    <ul>
                        {
                            ShowContractProductsModal_Contract.Products.map(({ ProductId, ProductName, ProductQuantity }) => {
                                return <li key={ProductId}><PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${ProductQuantity} ${ProductName}`} /></li>
                            })
                        }
                    </ul>
                </IStyles.ProductListContainer>
            </IStyles.ProductContainerMobile>
        );
    }

    renderContractProductsModal = () => {
        var { ShowContractProductsModal } = this.state;

        if (ShowContractProductsModal) {
            return (
                <Info
                    BottomButton={null}
                    InformationRenderer={this.renderContractProductsModal_Info}
                    OnHide={() => this.onToggleShowContractProductsModal({ ShowContractProductsModal: false })}
                    Show={ShowContractProductsModal}
                    Size="small"
                />
            );
        }
    }

    renderFiltersModal_Info = () => {
        var { TryingViewOrganizationMemberContracts, TryingViewOrganizationMemberContractsFilters } = this.props;
        var { Coaches, Clients, Branches, Products, Regions } = this.state.FilterOptions;
        var { Branches: SelectedBranches, Clients: SelectedClients, Coaches: SelectedCoaches, InsideOrg, OutsideOrg, Products: SelectedProducts, Regions: SelectedRegions, SignedDate } = this.state.Filters;
        
        return (
            <Filters
                Branches={Branches}
                Clients={Clients}
                Coaches={Coaches}
                InsideOrg={InsideOrg}
                OutsideOrg={OutsideOrg}
                Products={Products}
                Regions={Regions}
                SignedDate={SignedDate}
                SelectedContent={{
                    Branches: SelectedBranches,
                    Clients: SelectedClients,
                    Coaches: SelectedCoaches,
                    Products: SelectedProducts,
                    Regions: SelectedRegions
                }}
                IsModal
                Loading={TryingViewOrganizationMemberContracts || TryingViewOrganizationMemberContractsFilters}
                OnBlurFilter={this.onBlurFilter}
                OnChangeFilter={this.onChangeFilter}
                OnFocusFilter={this.onLoadFilters}
                OnChangeDate={this.onChangeDate}
                OnSelectInsideOrg={this.onSelectInsideOrg}
                OnSelectOutsideOrg={this.onSelectOutsideOrg}
            />
        );
    }

    renderFiltersModal = () => {
        var { ShowFiltersModals } = this.state;

        if (ShowFiltersModals) {
            return (
                <Info
                    BottomButton={null}
                    InformationRenderer={this.renderFiltersModal_Info}
                    OnHide={() => this.onToggleShowFiltersModal(false)}
                    Show={ShowFiltersModals}
                />
            );
        }
    }

    renderOverallSummary = () => {
        var { t } = this.props;

        return (
            <styles.OverallSummaryHeaderContainer className="OverallSummaryHeaderContainer">
                <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_contracts')} />
            </styles.OverallSummaryHeaderContainer>
        );
    }

    renderViewCount = () => {
        var { t } = this.props;
        var { PageNo, PageSize, TotalRecords } = this.state;

        return (
            <IStyles.ViewCount className="ViewCount">
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${((PageNo - 1) * PageSize) + 1} - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                &nbsp;
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords}`} />
            </IStyles.ViewCount>
        )
    }

    render() {
        var { TryingDownloadContractPDF, TryingViewOrganizationMemberContracts } = this.props;

        return (
            <>
                {(TryingDownloadContractPDF || TryingViewOrganizationMemberContracts) && <Loading />}

                {this.renderBody()}

                {this.renderContractProductsModal()}
                {this.renderFiltersModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        TryingViewOrganizationMemberContracts: state.Income.TryingViewOrganizationMemberContracts,
        TryingViewOrganizationMemberContractsError: state.Income.TryingViewOrganizationMemberContractsError,
        TryingViewOrganizationMemberContractsFilters: state.Income.TryingViewOrganizationMemberContractsFilters,
        TryingViewOrganizationMemberContractsFiltersError: state.Income.TryingViewOrganizationMemberContractsFiltersError,

        TryingDownloadContractPDF: state.Sales.TryingDownloadContractPDF,
        TryingDownloadContractPDFError: state.Sales.TryingDownloadContractPDFError
    };
};

export default withTranslation()(connect(mapStateToProps, { DownloadContractPDF, ViewOrganizationMemberContracts, ViewOrganizationMemberContractsFilters })(OrganizationContracts));