import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { InviteOrganizationMembers } from '../../Services/Actions';

import { BodyContainer, BottomButton, InputContainer, InputInnerContainer, InstructionContainer, InstructionsContainer, StyledDeleteIcon, StyledInput } from './styles';

import AbelModal from '../../Components/Modal';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import { emailValidator } from '../../Functions';

import Plus from '../../Assets/Icons/PlusButton_Active.png';
import RedX from '../../Assets/Icons/X_Red.png';

class AddOrgMembers extends React.Component {
    _isMounted = false;

    state = { Emails: [{ Email: '', Valid: false }] };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onAddEmail = () => {
        this.setState({ Emails: [ ...this.state.Emails, { Email: '', Valid: false } ] });
    }

    onBlurEmail = EmailIndex => {
        var Emails = [ ...this.state.Emails ];
        Emails[EmailIndex].Valid = !!emailValidator(Emails[EmailIndex].Email);

        this.setState({ Emails });
    }

    onClickSubmit = () => {
        var { Emails } = this.state;

        Emails = Emails.filter(({ Valid }) => !!Valid).map(({ Email }) => Email).join(';');

        if (!Emails.length) this.props.OnHide();
        else {
            this.props.InviteOrganizationMembers({ Emails }).then(({ MembersAdded }) => {
                if (!!MembersAdded) this.props.Refresh();
                else this.props.OnHide();
            });
        }
    }

    onChangeEmail = ({ event, EmailIndex }) => {
        var Emails = [ ...this.state.Emails ];
        Emails[EmailIndex] = { Email: event.target.value, Valid: true };

        this.setState({ Emails });
    }

    onDeleteEmail = EmailIndex => {
        this.setState({ Emails: [ ...this.state.Emails.slice(0, EmailIndex), ...this.state.Emails.slice(EmailIndex + 1) ] });
    }

    renderBottomButton = () => {
        var { t } = this.props;
        var { Emails } = this.state;

        var Disabled = !Emails.length || this.props.TryingInviteOrganizationMembers;

        var Buttons = [{ BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: this.onClickSubmit, Title: t('ptadmin_clientnote_options_add') }];

        return <BottomButton Buttons={Buttons} NormalWhiteSpace />;
    }

    renderModalBody = () => {
        var { t } = this.props;
        var { Emails } = this.state;

        return (
            <BodyContainer>
                {this.props.TryingInviteOrganizationMembers && <Loading />}

                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('add_new_coach')} TextAlign="center" />

                <Spacer Size="small" />

                <InstructionsContainer className="InstructionsContainer">
                    <InstructionContainer className="InstructionContainer">
                        <MultiText
                            Texts={[
                                { FontFamily: 'medium', FontSize: 'small', Text: t('add_new_coach_if_already') },
                                { FontFamily: 'semibold', FontSize: 'small', Text: t('add_new_coach_have') },
                                { FontFamily: 'medium', FontSize: 'small', Text: t('add_new_coach_after_have') }
                            ]}
                        />
                    </InstructionContainer>

                    <InstructionContainer className="InstructionContainer">
                        <MultiText
                            Texts={[
                                { FontFamily: 'medium', FontSize: 'small', Text: t('add_new_coach_if_do_not_already') },
                                { FontFamily: 'semibold', FontSize: 'small', Text: t('add_new_coach_do_not_have') },
                                { FontFamily: 'medium', FontSize: 'small', Text: t('add_new_coach_after_have') }
                            ]}
                        />
                    </InstructionContainer>
                </InstructionsContainer>

                <Spacer Size="medium" />

                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('add_new_coach_by_email_title')} TextAlign="center" />

                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('add_new_coach_by_email_title_description')} TextAlign="center" />

                <Spacer Size="small" />

                {
                    Emails.map(({ Email, Valid }, EmailIndex) => {
                        return (
                            <InputContainer className="InputContainer" key={EmailIndex}>
                                <InputInnerContainer className="InputInnerContainer">
                                    <StyledInput FontSize="medium-2" NoLabel NoMargin OnBlur={() => this.onBlurEmail(EmailIndex)} OnChange={event => this.onChangeEmail({ event, EmailIndex })} Placeholder={t('email_with_first_char_uppercase')} Size="medium" Type="text" Value={Email} />

                                    {
                                        EmailIndex !== 0 ?
                                        <StyledDeleteIcon ImageSrc={RedX} OnClick={() => this.onDeleteEmail(EmailIndex)} />
                                    :
                                        <div />
                                    }
                                </InputInnerContainer>

                                {!!Email.length && !Valid && <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('provide_valid_email_address')} TextAlign="left" />}
                            </InputContainer>           
                        );
                    })
                }

                <Spacer Size="extra-small" />

                <ButtonGroup
                    Buttons={[
                        { BackgroundColor: 'white', BackgroundColorHover: 'white-hover', Color: 'black', ColorHover: 'black-hover', FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: this.onAddEmail, Title: t('add_new_coach_again') }
                    ]}
                    ButtonWidth="fit-content"
                    NotTouching
                />
            </BodyContainer>
        );
    }

    render() {
        var { OnHide, Show } = this.props;

        return (
            <AbelModal
                BottomButtonRender={this.renderBottomButton}
                GA_PathName="AddOrgMembers"
                NoMargin
                Show={Show}
                Size="large"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

AddOrgMembers.propTypes = {
    OnHide: PropTypes.func.isRequired,
    Refresh: PropTypes.func,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        TryingInviteOrganizationMembers: state.Income.TryingInviteOrganizationMembers,
        TryingInviteOrganizationMembersError: state.Income.TryingInviteOrganizationMembersError
    };
};

export default withTranslation()(connect(mapStateToProps, { InviteOrganizationMembers } )(AddOrgMembers));