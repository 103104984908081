import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CopyWorkouts, DeleteWorkouts, GetClientWorkouts, GetWorkoutNotesByDateRange, MoveWorkouts, SaveDataInProps, UpdateWorkoutAccess } from '../../Services/Actions';
import history from '../../history';

import moment from 'moment';
import { toast } from 'react-toastify';

import { BottomBarHeightAdjustment, BottomBarItems, CalendarIcon, ChartDateCustomContainer, ChartDateCustomTextContainer, GetStartedButton, MultiOptionContainer, MultiOptionsContainer, SelectAllContainer, SelectedItems, StyledBottomBar, WorkoutNoteContainer, WorkoutNotesContainer }  from './styles';

import AddWorkouts from '../../Components/AddWorkouts';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import CalendarHeader from '../../Components/Calendar/CalendarHeader';
import { GA_Event } from '../../Components/GoogleAnalytics';
import GetStarted from '../../Components/GetStarted';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import Toggle from '../../Components/Form/Toggle';
import WeeklyView from '../../Components/Calendar/WeeklyView';

import AddWorkoutMenu from '../../Modals/AddWorkoutMenu';
import ChooseDates from '../../Modals/ChooseDates';
import ClientAndDates from '../../Modals/ClientAndDates';
import Info from '../../Modals/Info';

import Calendar from '../../Assets/Icons/Calendar.png';

import { DateSort } from '../../Functions';

const INIT_GET_WORKOUT_NOTES_DETAILS = { ClientDetails: {}, EndDate: null, StartDate: null, WorkoutNotes: [] };

class ClientWorkouts extends React.Component {
    _isMounted = false;

    state = {
        BottomBarHeight: 0,
        ChooseDatesReason: null,
        DayDate: null,
        EndDate: null,
        ForceGetStarted: null,
        GetWorkoutNotesDetails: { ...INIT_GET_WORKOUT_NOTES_DETAILS },
        Loading: true,
        MonthlyView: false,
        SelectedDates: [],
        SelectedWorkouts: [],
        ShowAddWorkoutModalMenu: false,
        ShowChooseDateRangeModal: false,
        ShowChooseDatesModal: false,
        ShowCopyWorkoutsModal: false,
        ShowDeleteWorkoutsModal: false,
        ShowGetWorkoutNotesByDateRangeModal: false,
        ShowMultiSelect: false,
        ShowWorkoutImage: true,
        ShowWorkoutPreview: false,
        StartDate: null,
        TryingGetWorkoutNotesDetails: false
    };

    componentDidMount() {
        this._isMounted = true;

        var { CalendarDifference, ClientId, ForceGetStarted, StartingDate } = this.props;

        var MonthlyView = false;
        var ShowWorkoutImage = true;
        var ShowWorkoutPreview = false;
        var CustomSettings_LS = localStorage.getItem('CustomSettings');
        if (CustomSettings_LS) {
            CustomSettings_LS = JSON.parse(CustomSettings_LS);
            MonthlyView = CustomSettings_LS.Workouts_MonthlyView;
            ShowWorkoutImage = CustomSettings_LS.Workouts_ShowImage;
            ShowWorkoutPreview = CustomSettings_LS.Workouts_ShowPreview;
        }

        var StartDate;
        var EndDate;

        if (MonthlyView) {
            StartDate = moment().add(CalendarDifference, 'months').startOf('month').startOf('isoWeek').format('YYYY-MM-DD');
            EndDate = moment().add(CalendarDifference, 'months').endOf('month').endOf('isoWeek').format('YYYY-MM-DD');

            // Calculate WeeklyView dates from StartingDate
                if (StartingDate) CalendarDifference = moment(StartingDate).diff(moment().startOf('month').format('YYYY-MM-DD'), 'months');
        }
        else {
            StartDate = moment().add(CalendarDifference, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
            EndDate = moment().add(CalendarDifference, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');

            // Calculate WeeklyView dates from StartingDate
                if (StartingDate) CalendarDifference = moment(StartingDate).isoWeekday(moment().isoWeekday()).diff(moment().format('YYYY-MM-DD'), 'weeks');
                // if (StartingDate) CalendarDifference = moment(StartingDate).startOf('isoWeek').diff(moment().startOf('isoWeek'), 'weeks');
        }

        // var StartDate = moment().add(CalendarDifference, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
        // var EndDate = moment().add(CalendarDifference, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');

        this.setState({ EndDate, MonthlyView, ShowWorkoutImage, ShowWorkoutPreview, StartDate }, () => {
            this.props.GetClientWorkouts({ CalendarDifference, ClientId, EndDate, GetWorkoutPreviewData: +ShowWorkoutPreview, StartDate }).then(() => {
                if (this._isMounted) this.setState({ ForceGetStarted, Loading: false });
            });
        });
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        if (!!prevProps.NeedsRefresh !== !!this.props.NeedsRefresh && !!this.props.NeedsRefresh) this.onUpdateDate({ Direction: 0 });
    }

    onAddedWorkouts = ({ ClientIds, SelectedDates }) => {
        // Current Client Had Workouts Added and Weekly/Month View Start-EndDate Has At Least 1 SelectedDates Day In It's Range
        if (ClientIds.indexOf(+this.props.ClientId) > -1 && SelectedDates.filter(SelectedDate => new Date(SelectedDate) >= new Date(this.state.StartDate) && new Date(SelectedDate) <= new Date(this.state.EndDate)).length) this.onRefreshWeeklyView();
    }

    onCancelMultiSelect = ({ ShowMultiSelect = false }) => {
        this.setState({ SelectedDates: [], SelectedWorkouts: [], ShowMultiSelect });
    }

    onChangeToggle = event => {
        var Checked = event.target.checked;

        var CustomSettings_LS = localStorage.getItem('CustomSettings');
        if (CustomSettings_LS) CustomSettings_LS = JSON.parse(CustomSettings_LS);
        else CustomSettings_LS = { Meals_MonthlyView: false, Tasks_MonthlyView: true, Workouts_MonthlyView: false, Workouts_ShowImage: true, Workouts_ShowPreview: false };

        if (Checked) CustomSettings_LS.Workouts_MonthlyView = false;
        else CustomSettings_LS.Workouts_MonthlyView = true;

        localStorage.setItem('CustomSettings', JSON.stringify(CustomSettings_LS));

        this.setState({ MonthlyView: !Checked }, () => this.onUpdateDate({ ChangedTo: Checked ? 'Weekly' : 'Monthly', Direction: 0 }));
    }

    onClickBuildWorkoutPlanShortcut = () => {
        var { ClientDetails } = this.props;

        this.props.SaveDataInProps({ ClientDetails }).then(() => history.push(`/add-workout-plan`));
    }

    onCopyWorkoutNotes = ({ ClientDetails, WorkoutNotes }) => {        
        var str = '';
        str += `${ClientDetails.DisplayName}`;

        for (var i = 0; i < WorkoutNotes.length; i++) {
            str += `
        
${moment(WorkoutNotes[i].AssignedDate).format('[WeeklyViewHeader]')}:
${WorkoutNotes[i].ClientNote}`;
        }

        navigator.clipboard.writeText(str);

        toast.success(this.props.t('copied'), {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    onCopyWorkouts = (ClientIds, SelectedDates, CopyWorkoutNotes) => {
        this.onToggleShowCopyWorkoutsModal(false);
        
        // this.setState({ ShowMultiSelect: false });

        this.props.CopyWorkouts({ AssignedDates: SelectedDates, ClientIds, CopyNotes: CopyWorkoutNotes, WorkoutIds: this.state.SelectedWorkouts }).then(() => {
            this.onUpdateDate({ Direction: 0 });
            this.onCancelMultiSelect({ ShowMultiSelect: true });
        });
    }

    onDeleteWorkouts = () => {
        this.onToggleShowDeleteWorkoutsModal(false);

        // this.setState({ ShowMultiSelect: false });

        this.props.DeleteWorkouts(this.state.SelectedWorkouts).then(() => {
            this.onUpdateDate({ Direction: 0 });
            this.onCancelMultiSelect({ ShowMultiSelect: true });
        });
    }

    onFinishGetStarted = () => {
        this.setState({ ForceGetStarted: false });
        this.onUpdateDate({ Direction: 0 });
    }

    onGetSizeBottomBar = Height => {
        this.setState({ BottomBarHeight: Height });
    }

    onMoveWorkouts = AssignedDate => {
        var { MonthlyView } = this.state;

        var EndDate, StartDate;
        
        if (MonthlyView) {
            StartDate = moment(AssignedDate).startOf('month').format('YYYY-MM-DD');
            EndDate = moment(AssignedDate).endOf('month').format('YYYY-MM-DD');
        } else {
            StartDate = moment(AssignedDate).startOf('isoWeek').format('YYYY-MM-DD');
            EndDate = moment(AssignedDate).endOf('isoWeek').format('YYYY-MM-DD');
        }

        // If AssignedDate is in current week, then delete workouts that are moved
        var IsBetween = moment(AssignedDate).isBetween(StartDate, EndDate, null, []);

        // this.setState({ ShowMultiSelect: false });

        this.props.MoveWorkouts({ AssignedDate, IsBetween, WorkoutIds: this.state.SelectedWorkouts }).then(() => {
            !!IsBetween && this.onUpdateDate({ Direction: 0 });
            this.onCancelMultiSelect({ ShowMultiSelect: true });
        });
    }

    onMultiSelect = WorkoutId => {
        var MultiSelect_Index = this.state.SelectedWorkouts.indexOf(+WorkoutId);

        if (MultiSelect_Index === -1) this.setState({ SelectedWorkouts: [ ...this.state.SelectedWorkouts, +WorkoutId ] });
        else this.setState({ SelectedWorkouts: [ ...this.state.SelectedWorkouts.slice(0, MultiSelect_Index), ...this.state.SelectedWorkouts.slice(MultiSelect_Index + 1) ] });
    }

    onRefreshWeeklyView = () => {
        this.onUpdateDate({ Direction: 0 });
    }

    onSelectAll = () => {
        var SelectedWorkouts = [].concat.apply([], this.props.ClientWorkouts.map(({ Workouts }) => Workouts.map(({ WorkoutId }) => +WorkoutId)));

        this.setState({ SelectedWorkouts });
    }

    onSelectCustomDates = ({ EndDate, StartDate }) => {
        var { GetWorkoutNotesDetails } = this.state;
        GetWorkoutNotesDetails.EndDate = EndDate;
        GetWorkoutNotesDetails.StartDate = StartDate;

        this.setState({ GetWorkoutNotesDetails, ShowChooseDateRangeModal: false, TryingGetWorkoutNotesDetails: true }, () => this.props.GetWorkoutNotesByDateRange({ ClientId: this.props.ClientId, EndDate, StartDate }).then(({ ClientDetails, WorkoutNotes }) => this.setState({ GetWorkoutNotesDetails: { ...this.state.GetWorkoutNotesDetails, ClientDetails, WorkoutNotes }, TryingGetWorkoutNotesDetails: false })));
    }

    onSelectDate = SelectedDate => {
        var { ClientId } = this.props;
        var { MonthlyView, ShowWorkoutPreview } = this.state;

        var CalendarDifference, EndDate, StartDate;
        
        if (MonthlyView) {
            StartDate = moment(SelectedDate).startOf('month').format('YYYY-MM-DD');
            EndDate = moment(SelectedDate).endOf('month').format('YYYY-MM-DD');

            CalendarDifference = moment(StartDate).diff(moment().startOf('month').format('YYYY-MM-DD'), 'months');
        } else {
            StartDate = moment(SelectedDate).startOf('isoWeek').format('YYYY-MM-DD');
            EndDate = moment(SelectedDate).endOf('isoWeek').format('YYYY-MM-DD');

            CalendarDifference = moment(StartDate).isoWeekday(moment().isoWeekday()).diff(moment().format('YYYY-MM-DD'), 'weeks');
        }
        
        this.setState({ EndDate, StartDate });
        this.props.GetClientWorkouts({ CalendarDifference, ClientId, EndDate, GetWorkoutPreviewData: ShowWorkoutPreview, StartDate });
    }

    onSelectDates = (SelectedDates, ChooseDatesReason) => {
        this.setState({ SelectedDates: SelectedDates.sort(DateSort), ShowChooseDatesModal: false }, () => {
            if (ChooseDatesReason === 'move') this.onMoveWorkouts(SelectedDates[0]);
        });
    }

    onToggleAddButton = (ShowAddWorkoutModalMenu, DayDate) => {
        this.setState({ ShowAddWorkoutModalMenu, DayDate: DayDate || null })
    }

    onToggleMultiSelect = event => {
        GA_Event({ Category: 'ClientDetail', Action: `Toggle Weekly View Multi Select - Workouts`, Label: `Toggled ${!!event.target.checked ? 'On' : 'Off'}` });

        this.setState({ ShowMultiSelect: event.target.checked, SelectedWorkouts: [] });
    }

    onToggleShowChooseDateRangeModal = ShowChooseDateRangeModal => {
        this.setState({ ShowChooseDateRangeModal });
    }

    onToggleShowChooseDatesModal = (ShowChooseDatesModal, Reason) => {
        // Reason is either "move" or "copy"
        this.setState({ ShowChooseDatesModal, ChooseDatesReason: Reason });
    }

    onToggleShowCopyWorkoutsModal = ShowCopyWorkoutsModal => {
        this.setState({ ShowCopyWorkoutsModal });
    }

    onToggleShowDeleteWorkoutsModal = ShowDeleteWorkoutsModal => {
        this.setState({ ShowDeleteWorkoutsModal });
    }

    onToggleShowGetWorkoutNotesByDateRangeModal = ShowGetWorkoutNotesByDateRangeModal => {
        this.setState({ GetWorkoutNotesDetails: { ...INIT_GET_WORKOUT_NOTES_DETAILS }, ShowGetWorkoutNotesByDateRangeModal });
    }

    onToggleShowWorkoutImage = event => {
        var Checked = !event.target.checked;

        var CustomSettings_LS = localStorage.getItem('CustomSettings');
        if (CustomSettings_LS) CustomSettings_LS = JSON.parse(CustomSettings_LS);
        else CustomSettings_LS = { Meals_MonthlyView: false, Workouts_MonthlyView: false, Workouts_ShowImage: true, Workouts_ShowPreview: false }; 

        if (Checked) CustomSettings_LS.Workouts_ShowImage = false;
        else CustomSettings_LS.Workouts_ShowImage = true;

        var ShowImage = CustomSettings_LS.Workouts_ShowImage;

        localStorage.setItem('CustomSettings', JSON.stringify(CustomSettings_LS));

        this.setState({ ShowWorkoutImage: ShowImage });
    }

    onToggleShowWorkoutPreview= event => {
        var Checked = !event.target.checked;

        var CustomSettings_LS = localStorage.getItem('CustomSettings');
        if (CustomSettings_LS) CustomSettings_LS = JSON.parse(CustomSettings_LS);
        else CustomSettings_LS = { Meals_MonthlyView: false, Workouts_MonthlyView: false, Workouts_ShowImage: true, Workouts_ShowPreview: false }; 

        if (Checked) CustomSettings_LS.Workouts_ShowPreview = false;
        else CustomSettings_LS.Workouts_ShowPreview = true;

        var ShowPreview = CustomSettings_LS.Workouts_ShowPreview;

        localStorage.setItem('CustomSettings', JSON.stringify(CustomSettings_LS));

        this.setState({ ShowWorkoutPreview: ShowPreview }, () => {
            // If Currently Not Showing Preview, Then Call API to Get Preview Data
                if (ShowPreview) this.onUpdateDate({ Direction: 0 })
        });
    }

    onUpdateDate = ({ ChangedTo, Direction }) => {
        var { CalendarDifference, ClientId } = this.props;
        var { MonthlyView, ShowWorkoutPreview } = this.state;

        var CalculationDate, EndDate, StartDate;
        CalendarDifference += Direction;

        if (ChangedTo) {
            // Was Monthly, Now Weekly ... Get First Day Of Week Of Week Containing 1st Day Of Month
                if (ChangedTo === 'Weekly') {
                    CalculationDate = moment().add(CalendarDifference, 'months').format('YYYY-MM-DD');
                    StartDate = moment(CalculationDate).startOf('month').startOf('isoWeek').format('YYYY-MM-DD');
                    EndDate = moment(CalculationDate).startOf('month').endOf('isoWeek').format('YYYY-MM-DD');

                    // Compare to Today isoWeekday()
                    CalendarDifference = moment(StartDate).isoWeekday(moment().isoWeekday()).diff(moment().format('YYYY-MM-DD'), 'weeks');
                }
            // Was Weekly, Now Monthly ... Get First Day Of Month Of Month Container 1st Day Of Week
                else if (ChangedTo === 'Monthly') {
                    CalculationDate = moment().add(CalendarDifference, 'weeks').format('YYYY-MM-DD');
                    StartDate = moment(CalculationDate).startOf('isoWeek').startOf('month').startOf('isoWeek').format('YYYY-MM-DD');
                    EndDate = moment(CalculationDate).startOf('isoWeek').endOf('month').endOf('isoWeek').format('YYYY-MM-DD');

                    // Compare to today first day of month
                    CalendarDifference = moment(StartDate).diff(moment().startOf('month').format('YYYY-MM-DD'), 'months');
                }
        } else {
            if (MonthlyView) {
                StartDate = moment().add(CalendarDifference, 'months').startOf('month').startOf('isoWeek').format('YYYY-MM-DD');
                EndDate = moment().add(CalendarDifference, 'months').endOf('month').endOf('isoWeek').format('YYYY-MM-DD');
            }
            else {
                StartDate = moment().add(CalendarDifference, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
                EndDate = moment().add(CalendarDifference, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
            }
        }

        this.setState({ EndDate, StartDate });
        this.props.GetClientWorkouts({ CalendarDifference, ClientId, EndDate, GetWorkoutPreviewData: ShowWorkoutPreview, StartDate });
    }

    onUpdateWorkoutAccess = ({ UpdateType }) => {
        var StartDate = moment().startOf('isoWeek').format('YYYY-MM-DD');
        var EndDate = moment().endOf('isoWeek').format('YYYY-MM-DD');

        this.props.UpdateWorkoutAccess({ EndDate, ReturnDataType: 'ClientDetail', StartDate, UpdateType, UserId: this.props.ClientId });
    }

    renderAddWorkoutModalMenu = () => {
        var { ClientDetails } = this.props;
        var { DayDate, ShowAddWorkoutModalMenu } = this.state;

        if (ShowAddWorkoutModalMenu) {
            return <AddWorkoutMenu SaveData={{ ClientDetails, DayDate }} Show={ShowAddWorkoutModalMenu} OnHide={() => this.onToggleAddButton(false)} />
        }
    }

    renderBottomBar = () => {
        var { SelectedWorkouts, ShowMultiSelect } = this.state;

        var MultiSelectLength = SelectedWorkouts.length;
        if (!ShowMultiSelect) return null;

        var { t } = this.props;
        var { Device } = this.props;

        var Buttons = [
            { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', OnClick: !MultiSelectLength ? () => this.onCancelMultiSelect({}) : () => this.onToggleShowCopyWorkoutsModal(true), Title: t('copy') },
            { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', OnClick: !MultiSelectLength ? () => this.onCancelMultiSelect({}) : () => this.onToggleShowChooseDatesModal(true, 'move'), Title: t('move') },
            { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', OnClick: !MultiSelectLength ? () => this.onCancelMultiSelect({}) : () => this.onToggleShowDeleteWorkoutsModal(true), Title: t('delete_with_first_char_uppercase') },
            { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Border: 'none', ColorHover: 'black', OnClick: () => this.onCancelMultiSelect({}), Title: t('Cancel_with_first_char_uppercase') }
        ];

        return (
            <StyledBottomBar GetSize={Height => this.onGetSizeBottomBar(Height)}>
                <BottomBarItems>
                    <SelectedItems ElementType="span" FontColor="white" FontFamily="bold" FontSize="medium-3" Text={`${MultiSelectLength}`} />
                    &nbsp;
                    <PageText ElementType="span" FontColor="white" FontFamily="medium" FontSize="medium-1" Text={t('WorkoutPlanSettings_selected').toLowerCase()} />

                    <SelectAllContainer>
                        <PageText FontColor="white" FontFamily="bold" FontSize="medium-1" NoMargin OnClick={this.onSelectAll} Text={t('select_all')} />
                    </SelectAllContainer>
                </BottomBarItems>
                <BottomBarItems FullWidth={true}>
                    <ButtonGroup
                        Buttons={Buttons}
                        ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                        NotTouching
                        OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                    />
                </BottomBarItems>
            </StyledBottomBar>
        );
    }

    renderBottomBarHeightAdjustment = () => {
        if (!this.state.ShowMultiSelect) return null;

        return <BottomBarHeightAdjustment className="BottomBarHeightAdjustment" Height={this.state.BottomBarHeight} />
    }

    renderCopyWorkoutsModal = () => {
        var { ShowCopyWorkoutsModal } = this.state;

        if (ShowCopyWorkoutsModal) {
            var { t } = this.props;
            
            return (
                <ClientAndDates
                    ClientDetails={this.props.ClientDetails}
                    EligibleTypes="Private"
                    OnHide={() => this.onToggleShowCopyWorkoutsModal(false)}
                    OnSelect={(ClientIds, SelectedDates, Groups, CopyWorkoutNotes) => this.onCopyWorkouts(ClientIds, SelectedDates, CopyWorkoutNotes)}
                    Show={ShowCopyWorkoutsModal}
                    ShowCopyNotesOption={1}
                    Title={t('choose_clients_dates_to_copy_to')}
                />
            )
        }
    }

    renderDeleteWorkoutsModal = () => {
        var { t } = this.props;
        var { TryingDeleteWorkouts } = this.props;
        var { ShowDeleteWorkoutsModal } = this.state;

        if (ShowDeleteWorkoutsModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteWorkouts}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    Information={t('delete_with_warning', { Items: t('ptadmin_workouts_plural').toLowerCase()})}
                    Loading={TryingDeleteWorkouts}
                    OnHide={() => this.onToggleShowDeleteWorkoutsModal(false)}
                    Show={ShowDeleteWorkoutsModal}
                />
            );
        }
    }

    renderMultiOptions = () => {
        var { t } = this.props;
        var { WorkoutAccess } = this.props;
        var { MonthlyView, ShowMultiSelect, ShowWorkoutImage, ShowWorkoutPreview } = this.state;

        if (!WorkoutAccess) return null;

        return (
            <MultiOptionsContainer>
                <MultiOptionContainer Clickable onClick={this.onClickBuildWorkoutPlanShortcut}>
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('need_some_help')} TextAlign="center"  />
                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={t('let_algo_build_plan')} TextAlign="center"  />
                </MultiOptionContainer>
                <MultiOptionContainer>
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('_show_pictures')} TextAlign="center"  />
                    <Toggle
                        Checked={ShowWorkoutImage}
                        OffLabel={t('_no')}
                        OnChange={this.onToggleShowWorkoutImage}
                        OnLabel={t('_yes')}
                    />

                    <Spacer Size="extra-extra-small" />

                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('_show_session_info')} TextAlign="center"  />
                    <Toggle
                        Checked={ShowWorkoutPreview}
                        OffLabel={t('_no')}
                        OnChange={this.onToggleShowWorkoutPreview}
                        OnLabel={t('_yes')}
                    />
                </MultiOptionContainer>
                <MultiOptionContainer>
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('show_week_or_month')} TextAlign="center"  />
                    <Toggle
                        Checked={!MonthlyView}
                        OffLabel={t('monthly')}
                        OnChange={this.onChangeToggle}
                        OnLabel={t('weekly')}
                    />
                </MultiOptionContainer>
                <MultiOptionContainer>
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('multi_select_and_delete')} TextAlign="center"  />
                    <Toggle
                        Checked={!!+ShowMultiSelect}
                        OffLabel={t('off')}
                        OnChange={this.onToggleMultiSelect}
                        OnLabel={t('on')}
                    />
                </MultiOptionContainer>
                <MultiOptionContainer Clickable onClick={() => this.onToggleShowGetWorkoutNotesByDateRangeModal(true)}>
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('copy_all_workout_notes')} TextAlign="center"  />
                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={t('copy_all_workout_notes_info')} TextAlign="center"  />
                </MultiOptionContainer>
            </MultiOptionsContainer>
        );
    }

    renderShowChooseDatesModal = () => {
        var { ShowChooseDatesModal, ChooseDatesReason } = this.state;

        if (ShowChooseDatesModal) {
            return (
                <ChooseDates
                    MaxDates={ChooseDatesReason === 'move' ? 1 : null}
                    OnHide={() => this.onToggleShowChooseDatesModal(false)}
                    OnSelectDates={SelectedDates => this.onSelectDates(SelectedDates, ChooseDatesReason)}
                    SelectedDates={this.state.SelectedDates}
                    Show={ShowChooseDatesModal}
                />
            );
        }
    }

    renderShowChooseDateRangeModal = () => {
        var { ShowChooseDateRangeModal } = this.state;

        if (ShowChooseDateRangeModal) {
            var { EndDate, StartDate } = this.state.GetWorkoutNotesDetails;

            return (
                <ChooseDates
                    IsDateRange
                    OnHide={() => this.onToggleShowChooseDateRangeModal(false)}
                    OnSelectDates={({ EndDate, StartDate }) => this.onSelectCustomDates({ EndDate, StartDate })}
                    RequiredDate
                    SelectedDates={[]}
                    ShowTodayShortcut
                    StartDate={StartDate}
                    EndDate={EndDate}
                    Show={ShowChooseDateRangeModal}
                />
            );
        }
    }

    renderShowGetWorkoutNotesByDateRangeModal = () => {
        var { ShowGetWorkoutNotesByDateRangeModal } = this.state;

        if (ShowGetWorkoutNotesByDateRangeModal) {
            // var { t } = this.props;
            var { ShowChooseDateRangeModal } = this.state;

            return (
                <Info
                    BottomButton="cancel"
                    BottomButtonOnClick={() => this.onToggleShowGetWorkoutNotesByDateRangeModal(false)}
                    DisplayNone={ShowChooseDateRangeModal}
                    InformationRenderer={this.renderShowGetWorkoutNotesByDateRangeModal_Information}
                    OnHide={() => this.onToggleShowGetWorkoutNotesByDateRangeModal(false)}
                    Show={ShowGetWorkoutNotesByDateRangeModal}
                    Size="large"
                />
            );
        }
    }

    renderShowGetWorkoutNotesByDateRangeModal_Information = () => {
        var { t } = this.props;
        var { ClientDetails, EndDate, StartDate, WorkoutNotes } = this.state.GetWorkoutNotesDetails;

        var { TryingGetWorkoutNotesDetails } = this.state;

        return (
            <>
                {TryingGetWorkoutNotesDetails && <Loading />}

                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('copy_all_workout_notes_info')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <ChartDateCustomContainer className="ChartDateCustomContainer" onClick={() => this.onToggleShowChooseDateRangeModal(true)}>
                    <ChartDateCustomTextContainer className="ChartDateCustomTextContainer">
                        <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('custom_date_range')}:`} TextAlign="left" WordBreak="keep-all" />&nbsp;
                        
                        {(EndDate && StartDate) && <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${moment(StartDate).format('[WeeklyViewDayHeader]')} - ${moment(EndDate).format('[WeeklyViewDayHeader]')}`} TextAlign="left" WordBreak="keep-all" />}
                    </ChartDateCustomTextContainer>

                    <CalendarIcon ImageSrc={Calendar} />
                </ChartDateCustomContainer>

                {
                    (!!StartDate && !!EndDate && !TryingGetWorkoutNotesDetails) ?
                    WorkoutNotes.length > 0 ?
                    <>
                        <Spacer Size="medium" />

                        <ButtonGroup
                            Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Color: 'black', ColorHover: 'black', OnClick: () => this.onCopyWorkoutNotes({ ClientDetails, WorkoutNotes }), Title: t('copy_all_workout_notes') }]}
                            ButtonWidth="fit-content"
                        />

                        <Spacer Size="extra-small" />

                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={ClientDetails.DisplayName} TextAlign="left" />

                        <Spacer Size="extra-small" />

                        <WorkoutNotesContainer className="WorkoutNotesContainer">
                            {
                                WorkoutNotes.map(({ AssignedDate, ClientNote }, WorkoutIndex) => {
                                    return (
                                        <WorkoutNoteContainer className="WorkoutNoteContainer" key={WorkoutIndex}>
                                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${moment(AssignedDate).format('[WeeklyViewHeader]')}`} TextAlign="left" />

                                            <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={ClientNote} TextAlign="left" WhiteSpace="pre-line" />
                                        </WorkoutNoteContainer>
                                    );
                                })
                            }
                        </WorkoutNotesContainer>
                    </>
                :
                    <>
                        <Spacer Size="medium" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('no_workout_notes_during_this_date_range')} TextAlign="left" />
                    </>
                :
                    null
                }
            </>
        );
    }

    render() {
        if (this.state.Loading || this.props.TryingGiveWorkoutAccess) return <Loading />;

        var { t } = this.props;
        var { ClientId, ClientDetails, TryingGetClientWorkouts, WorkoutAccess } = this.props;
        var { ForceGetStarted, MonthlyView, SelectedWorkouts, ShowMultiSelect, ShowWorkoutImage, ShowWorkoutPreview } = this.state;

        ClientId = +ClientId;

        return (
            <>
                <div>
                    {
                        ForceGetStarted ?
                        <GetStarted
                            ClientId={ClientId}
                            // NeedsWeight={!!+GetStarted_NeedsWeight}
                            NeedsWeight
                            RefreshWeekly={this.onFinishGetStarted}
                            Type="Workouts"
                        />
                    :
                        !WorkoutAccess ?
                        <>
                            <Spacer Size="medium" />

                            <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('ptadmin_user_has_no_access')} TextAlign="center" />

                            <Spacer Size="medium" />

                            <GetStartedButton onClick={() => this.onUpdateWorkoutAccess({ UpdateType: "give" })}>{t('ptadmin_getstarted_workouts')}</GetStartedButton>
                        </>
                    :
                        <>
                            <AddWorkouts
                                OnAddedWorkouts={this.onAddedWorkouts}
                                ClientDetails={ClientDetails}
                            />

                            <Spacer Size="small" />

                            {this.renderMultiOptions()}
        
                            <Spacer Size="large" />

                            <CalendarHeader MonthlyView={MonthlyView} OnSelectDate={SelectedDate => this.onSelectDate(SelectedDate)} OnUpdateDate={Direction => this.onUpdateDate({ Direction })} SectionType="Workouts" />

                            <Spacer Size="medium" />

                            <WeeklyView
                                ClientId={ClientId}
                                Loading={TryingGetClientWorkouts}
                                MultiSelect={ShowMultiSelect}
                                OnMultiSelectWorkouts={WorkoutId => this.onMultiSelect(WorkoutId)}
                                OnToggleAddButton={DayDate => this.onToggleAddButton(true, DayDate)}
                                SelectedWorkouts={SelectedWorkouts}
                                ShowWorkoutImage={ShowWorkoutImage}
                                ShowWorkoutPreview={ShowWorkoutPreview}
                                Type="Workouts"
                                Workouts={this.props.ClientWorkouts}
                            />
                        </> 
                    }
                </div>

                {this.renderBottomBarHeightAdjustment()}
                {this.renderBottomBar()}

                {this.renderAddWorkoutModalMenu()}
                {this.renderCopyWorkoutsModal()}
                {this.renderDeleteWorkoutsModal()}
                {this.renderShowChooseDateRangeModal()}
                {this.renderShowChooseDatesModal()}
                {this.renderShowGetWorkoutNotesByDateRangeModal()}
            </>
        );
    }
}

ClientWorkouts.propTypes = {
    ClientId: PropTypes.string.isRequired,
    ForceGetStarted: PropTypes.bool,
    NeedsRefresh: PropTypes.bool.isRequired,
    StartingDate: PropTypes.string
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        
        ClientDetails: state.Client.ClientDetails,

        ClientWorkouts: state.Workouts.ClientWorkouts,
        GetStarted_NeedsWeight: state.Workouts.GetStarted_NeedsWeight,
        ShowGetStarted: state.Workouts.ShowGetStarted,
        WorkoutAccess: state.Workouts.WorkoutAccess,
        
        CalendarDifference: state.Client.CalendarDifference_Workouts,
        
        TryingGetClientWorkouts: state.Workouts.TryingGetClientWorkouts,
        TryingGetClientWorkoutsError: state.Workouts.TryingGetClientWorkoutsError,
        TryingGiveWorkoutAccess: state.Workouts.TryingGiveWorkoutAccess,
        TryingGiveWorkoutAccessError: state.Workouts.TryingGiveWorkoutAccessError
    };
};
  
export default withTranslation()(connect(mapStateToProps, { CopyWorkouts, DeleteWorkouts, GetClientWorkouts, GetWorkoutNotesByDateRange, MoveWorkouts, SaveDataInProps, UpdateWorkoutAccess } )(ClientWorkouts));