import {
    CLEAR_NEWLY_READ_NOTIFICATION_ID,

    LOGOUT_USER,

    CLEAR_READ_ALL_NOTIFICATIONS,
    TRYING_READ_ALL_NOTIFICATIONS,
    TRYING_READ_ALL_NOTIFICATIONS_SUCCESS,
    TRYING_READ_ALL_NOTIFICATIONS_ERROR,

    TRYING_READ_NOTIFICATION_SUCCESS,

    TRYING_UPDATE_NOTIFICATION_SETTINGS,
    TRYING_UPDATE_NOTIFICATION_SETTINGS_SUCCESS,
    TRYING_UPDATE_NOTIFICATION_SETTINGS_ERROR,

    TRYING_VIEW_NOTIFICATION_HISTORY,
    TRYING_VIEW_NOTIFICATION_HISTORY_SUCCESS,
    TRYING_VIEW_NOTIFICATION_HISTORY_ERROR,

    CLEAR_NOTIFICATION_SETTINGS,
    TRYING_VIEW_NOTIFICATION_SETTINGS,
    TRYING_VIEW_NOTIFICATION_SETTINGS_SUCCESS,
    TRYING_VIEW_NOTIFICATION_SETTINGS_ERROR,

    TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT,
    TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT_SUCCESS,
    TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT_ERROR,

    UPDATE_UNREAD_NOTIFICATION_COUNT
} from '../Types';

const INITIAL_STATE = {
    MarkAllAsRead: false,
    NewNotification: null,
    NewlyReadNotificationId: null,
    NotificationCategories: [],
    UnreadNotificationCount: 0,

    TryingReadAllNotifications: false,
    TryingReadAllNotificationsError: null,
    TryingUpdateNotificationSettings: false,
    TryingUpdateNotificationSettingsError: null,
    TryingViewNotificationHistory: false,
    TryingViewNotificationHistoryError: null,
    TryingViewNotificationSettings: false,
    TryingViewNotificationSettingsError: null,
    TryingViewUnreadNotificationCount: false,
    TryingViewUnreadNotificationCountError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_NEWLY_READ_NOTIFICATION_ID:
            return { ...state, NewlyReadNotificationId: null };

        case LOGOUT_USER:
            return { ...state, UnreadNotificationCount: 0 };

        case CLEAR_READ_ALL_NOTIFICATIONS:
            return { ...state, MarkAllAsRead: false };
        case TRYING_READ_ALL_NOTIFICATIONS:
            return { ...state, MarkAllAsRead: false, TryingReadAllNotifications: true, TryingReadAllNotificationsError: null };
        case TRYING_READ_ALL_NOTIFICATIONS_ERROR:
            return { ...state, MarkAllAsRead: false, TryingReadAllNotifications: false, TryingReadAllNotificationsError: action.payload };
        case TRYING_READ_ALL_NOTIFICATIONS_SUCCESS:
            return { ...state, MarkAllAsRead: true, UnreadNotificationCount: 0, TryingReadAllNotifications: false, TryingReadAllNotificationsError: null };

        case TRYING_READ_NOTIFICATION_SUCCESS:
            var UnreadNotificationCount = state.UnreadNotificationCount;
            UnreadNotificationCount--;

            if (UnreadNotificationCount < 0) UnreadNotificationCount = 0;

            return { ...state, UnreadNotificationCount };

        case TRYING_UPDATE_NOTIFICATION_SETTINGS:
            return { ...state, TryingUpdateNotificationSettings: true, TryingUpdateNotificationSettingsError: null };
        case TRYING_UPDATE_NOTIFICATION_SETTINGS_ERROR:
            return { ...state, TryingUpdateNotificationSettings: false, TryingUpdateNotificationSettingsError: action.payload };
        case TRYING_UPDATE_NOTIFICATION_SETTINGS_SUCCESS:
            return { ...state, TryingUpdateNotificationSettings: false, TryingUpdateNotificationSettingsError: null };

        case TRYING_VIEW_NOTIFICATION_HISTORY:
            return { ...state, TryingViewNotificationHistory: true, TryingViewNotificationHistoryError: null };
        case TRYING_VIEW_NOTIFICATION_HISTORY_ERROR:
            return { ...state, TryingViewNotificationHistory: false, TryingViewNotificationHistoryError: action.payload };
        case TRYING_VIEW_NOTIFICATION_HISTORY_SUCCESS:
            return { ...state, NewNotification: null, TryingViewNotificationHistory: false, TryingViewNotificationHistoryError: null };

        case CLEAR_NOTIFICATION_SETTINGS:
            return { ...state, NotificationCategories: [] };
        case TRYING_VIEW_NOTIFICATION_SETTINGS:
            return { ...state, TryingViewNotificationSettings: true, TryingViewNotificationSettingsError: null };
        case TRYING_VIEW_NOTIFICATION_SETTINGS_ERROR:
            return { ...state, TryingViewNotificationSettings: false, TryingViewNotificationSettingsError: action.payload };
        case TRYING_VIEW_NOTIFICATION_SETTINGS_SUCCESS:
            return { ...state, NotificationCategories: action.payload.Categories, TryingViewNotificationSettings: false, TryingViewNotificationSettingsError: null };

        case TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT:
            return { ...state, TryingViewUnreadNotificationCount: true, TryingViewUnreadNotificationCountError: null };
        case TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT_ERROR:
            return { ...state, TryingViewUnreadNotificationCount: false, TryingViewUnreadNotificationCountError: action.payload };
        case TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT_SUCCESS:
            return { ...state, UnreadNotificationCount: action.payload, TryingViewUnreadNotificationCount: false, TryingViewUnreadNotificationCountError: null };

        case UPDATE_UNREAD_NOTIFICATION_COUNT:
            var { NewNotification, NewlyReadNotificationId, UnreadCount } = action.payload;

            var NewUnreadNotificationCount = state.UnreadNotificationCount + +UnreadCount;

            if (NewUnreadNotificationCount < 0) NewUnreadNotificationCount = 0;

            return { ...state, NewNotification, NewlyReadNotificationId, UnreadNotificationCount: NewUnreadNotificationCount };

        default:
            return state;
    }
};
