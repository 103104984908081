import styled from 'styled-components';

export const DayContainer = styled.div``;

export const DayNumber = styled.div`
    border: 2px solid #F2F2F2;
    border-radius: 10px;
    font-family: "Branding-Semibold";
    font-size: 22px;
    padding: 8px;
    text-align: center;
`;

export const DaysContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

    @media (min-width: 1168px) {
        grid-template-columns: repeat(7, minmax(100px, 140px));
    }
`;

export const DishContainer = styled.div`
    background-color: var(--white);
    border: 2px solid ${props => props.Error ? 'var(--red-bittersweet)' : 'var(--white-concrete)'};
    border-radius: 10px;
    margin: 20px 0px;
    overflow: hidden;

    &:last-child {
        margin-bottom: 0px;
    }
`;

export const DishOptionsContainer = styled.div`
    padding: 5px;
`;