import styled from 'styled-components';
import { device } from '../../Config/device';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Image from '../../Components/Image';
import Input from '../../Components/Form/Input';

import LoginBackground from '../../Assets/Illustrations/LoginBackground.png';

export const AbelLogo = styled.img`
    height: 50px;
    width: 55px;
`;

export const AbelLogoContainer = styled.div`
    display: flex;
    left: 25px;
    margin-right: 50px;
    position: absolute;
    top: 25px;

    @media ${device.minwidth.ipad} {
        left: 80px;
        top: 15px;
    }
`;

export const AbelLogoImageContainer = styled.div`
    margin-right: 10px;
`;

export const AbelNameContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export const BottomHalf = styled.div``;

export const Container = styled.div`
    background: ${props => props.DisplayScreen === 'CurusLogin' ? 'var(--curus-green)' : `var(--light-gray) url(${LoginBackground}) no-repeat center center / cover`};
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px;
    max-height: 100%;
    max-width: 80%;
    min-height: 845px;
    position: relative;
    width: 1162px;

    @media (max-width: 767px) {
        border-radius: 0px;
        margin: 0
        max-width: none;
        width: 100%;
    }
`;

export const CurusLoginContainer = styled.div`
    align-items: center;
    background-color: #E6F8F3;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
`;

export const CurusLoginFields = styled.div`
    width: 100%;
`;

export const CurusLogoContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const CurusLogoStyledImage = styled(Image)`
    width: 250px;
`

export const CurusStyledInput = styled(Input)`
    > input {
        border: 2px solid var(--white-concrete);
        border-radius: 5px;
        text-align: left;
        text-indent: 0px;
    }
`

export const ForgotPasswordImageContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 45px;

    & img {
        height: 270px;
        width: 270px;

        @media (max-width: 767px) {
            height: 160px;
            width: 160px;
        }
    }
`;

export const InnerContainer = styled.div`
    margin: 100px auto;
    max-width: 510px;
    width: 90%;

    @media ${device.minwidth.ipad} {
        margin: 50px auto;
        width: 100%;
    }
`;

export const LanguageContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    top: 25px;

    @media ${device.maxwidth.mobile} {
        right: 25px;
    }

    @media ${device.minwidth.ipad} {
        left: 25px;
    }

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const LanguageFlagIcon = styled.img`
    height: 28px;
    margin-right: 10px;
    width: 28px;
`;

export const LeftArrowContainer = styled.div`
    left: 50%;
    margin-left: -16px;
    position: absolute;
    top: 40px;
    width: 32px;

    @media ${device.minwidth.ipad} {
        top: 40px;
    }

    @media (max-width: 767px) {
        margin-left: -10.5px;
        width: 21px;
    }

    & img {
        height: 30px;
        width: 32px;

        @media (max-width: 767px) {
            height: 20px;
            width: 21px;

            left: 20px;
            top: 20px;
        }
    }

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const LoginFormContainer = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const LoginOptionsContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const OptionalLine = styled.div`
    border-bottom: 1px solid var(--gray-silver);
    line-height: 0.1em;
    margin: 10px auto;
    max-width: 800px;
    text-align: center;
    width: 100%;

    > span {
        background-color: var(--white); 
        padding: 0 10px; 
    }
`;

export const SocialLoginOptions = styled.div``;

export const SocialLoginsTopRow = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(1, 1fr);

    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(3, 1fr);
    }

    margin-bottom: 10px;
`;

export const StyledInput = styled(Input)`
    > input {
        background-color: var(--white-concrete);
        border: 2px solid var(--white-concrete);
        border-radius: 10px;
        text-align: left;
        text-indent: 0px;
    }
`;

export const StyledSexButtonGroups = styled(ButtonGroup)`
    justify-content: center;
    margin-top: 10px;
`;

export const TopHalf = styled.div``;