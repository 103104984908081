import React from 'react';
import { connect } from 'react-redux';
import { UpdateUserPaymentGatewayInfo, ProcessStageSubscriber, ReactivateSubscription } from '../../Services/Actions';
import history from '../../history';

import Loading from '../../Components/Loading';

class SubscriptionCallback extends React.Component {
    state = { Loading: true };

    componentDidMount() {
        var { type } = this.props.match.params;

        console.log('type: ', type);

        if (type === 'AddSubscriber') this.props.ProcessStageSubscriber().then(history.push('/account?section=subscriptions'));
        else if (type === 'RegisterUser') this.props.UpdateUserPaymentGatewayInfo().then(history.push('/account?section=subscriptions'));
        else if (type === 'ReactivateUser') this.props.ReactivateSubscription().then(history.push('/account?section=subscriptions'));
        // else history.push('/account?section=subscriptions');
        else console.log('no type')

        // history.push('/account?section=subscriptions');
    }

    render() {
        if (this.state.Loading) return <Loading />;

        return null;
    }
}

export default connect(null, { UpdateUserPaymentGatewayInfo, ProcessStageSubscriber, ReactivateSubscription } )(SubscriptionCallback);