import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import { BodyContainer, BottomButton, BranchSelectionContainer, ExtraSpaceDiv, OrganizationsListContainer, OrganizationsListOrgContainer, OrganizationsListOrgContainerNone } from './styles';

import AbelModal from '../../Components/Modal';
// import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

const DropdownStyles = {
    control: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '200px' }),
    input: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    menu: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    option: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '200px' })
};

class ChooseOrganization extends React.Component {
    _isMounted = false;

    state = {
        BranchSelectionMenuOpen: false,
        SelectedOrganization: null
    };

    componentDidMount() {
        this._isMounted = true;

        if (this.props.SelectedOrganization) this.setState({ SelectedOrganization: this.props.SelectedOrganization });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChangeBranch = ({ BranchId, BranchName }) => {
        this.setState({ SelectedOrganization: { ...this.state.SelectedOrganization, BranchId, BranchName } });
    }

    onClearSelectedOrganization = () => {
        this.setState({ SelectedOrganization: null });
    }

    onClickSubmit = () => {
        this.props.OnSelectOrganization(this.state.SelectedOrganization);
    }

    onSelectOrganization = ({ DefaultBranch = { BranchId: 0, BranchName: '' }, IncomeShare = 0, OrgId, OrgIndex = -1, OrgName = '' }) => {
        this.setState({ SelectedOrganization: { ...DefaultBranch, IncomeShare, OrgId, OrgIndex, OrgName } });
    }

    onToggleBranchSelectionMenuOpen = BranchSelectionMenuOpen => {
        this.setState({ BranchSelectionMenuOpen });
    } 

    renderBottomButton = () => {
        var { t } = this.props;
        var { Organizations } = this.props;
        var { SelectedOrganization } = this.state;

        var Buttons = [];

        if (Organizations.length > 1 && SelectedOrganization === null) return null;

        // Trainer Clicked That It Does Not Belong To An Organization, So Show Warning That Orgs Can Still See It
            if (SelectedOrganization !== null && !SelectedOrganization.OrgId) {
                Buttons.push({ BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: this.onClearSelectedOrganization, Title: t('back_with_first_char_uppercase') });
                Buttons.push({ BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: this.onClickSubmit, Title: t('agree') });
            }
        else {
            var OrgIndex = 0;
            if (SelectedOrganization) OrgIndex = SelectedOrganization.OrgIndex;

            var { DefaultBranch, IncomeShare, Name, OrganizationId } = Organizations[OrgIndex];

            var Disabled = false;
            if (SelectedOrganization !== null && (!SelectedOrganization.BranchId || !SelectedOrganization.BranchName)) Disabled = true;

            Buttons.push({ BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onSelectOrganization({ OrgId: 0 }), Title: t('no_with_first_char_uppercase') });
            Buttons.push({ BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: SelectedOrganization !== null ? this.onClickSubmit : () => this.onSelectOrganization({ DefaultBranch, IncomeShare, OrgId: OrganizationId, OrgIndex: 0, OrgName: Name }), Title: t(SelectedOrganization !== null ? 'save_with_first_char_uppercase' : 'yes_with_first_char_uppercase') });
        }

        return <BottomButton Buttons={Buttons} />;
    }

    renderMultiOrganizations = () => {
        var { t } = this.props;
        var { Organizations } = this.props;
        var { BranchSelectionMenuOpen, SelectedOrganization } = this.state;

        var MenuHeight = 200;

        return (
            <>
                <OrganizationsListContainer className="OrganizationsListContainer">
                    {
                        Organizations.map(({ DefaultBranch, IncomeShare, Name, OrganizationId }, OrgIndex) => {
                            if (Object.keys(DefaultBranch).length === 0) DefaultBranch = null;

                            return (
                                <OrganizationsListOrgContainer className="OrganizationsListOrgContainer" key={OrganizationId} onClick={() => this.onSelectOrganization({ ...DefaultBranch && { DefaultBranch }, IncomeShare, OrgId: OrganizationId, OrgIndex, OrgName: Name })}  Selected={SelectedOrganization && SelectedOrganization.OrgId === OrganizationId}>
                                    <PageText FontFamily="bold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={Name} TextAlign="left" />

                                    <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-end" NoMargin Text={t('contract_organization_takes_percent_income', { Percent: IncomeShare })} TextAlign="right" />
                                </OrganizationsListOrgContainer>
                            );
                        })
                    }

                    <Spacer Size="medium" />

                    <OrganizationsListOrgContainerNone className="OrganizationsListOrgContainerNone" onClick={() => this.onSelectOrganization({ OrgId: 0 })}>
                        <PageText FontFamily="bold" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('none_with_first_char_uppercase')} TextAlign="center" />
                    </OrganizationsListOrgContainerNone>
                </OrganizationsListContainer>

                {
                    SelectedOrganization !== null && !!SelectedOrganization.OrgId &&
                    <>
                        <Spacer Size="large" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('contract_organization_which_branch', { OrgName: SelectedOrganization.OrgName })} />

                        <Spacer Size="small" />

                        <BranchSelectionContainer className="BranchSelectionContainer">
                            <Select
                                getOptionLabel={option => option.BranchName}
                                getOptionValue={option => option.BranchId}
                                maxMenuHeight={MenuHeight}
                                onChange={this.onChangeBranch}
                                onMenuClose={() => this.onToggleBranchSelectionMenuOpen(false)}
                                onMenuOpen={() => this.onToggleBranchSelectionMenuOpen(true)}
                                options={Organizations[SelectedOrganization.OrgIndex].Branches}
                                placeholder={t('choose_branch')}
                                styles={DropdownStyles}
                                value={SelectedOrganization && SelectedOrganization.BranchId && { BranchId: SelectedOrganization.BranchId, BranchName: SelectedOrganization.BranchName }}
                            />
                        </BranchSelectionContainer>

                        {BranchSelectionMenuOpen && <ExtraSpaceDiv className="ExtraSpaceDiv" MenuHeight={MenuHeight} />}
                    </>
                }
            </>
        );
    }

    renderSingleOrganization = () => {
        var { t } = this.props;
        var { Organizations } = this.props;
        var { BranchSelectionMenuOpen, SelectedOrganization } = this.state;

        var { Branches, IncomeShare, Name } = Organizations[0];

        var MenuHeight = 200;

        return (
            <>
                <PageText FontFamily="bold" FontSize="extra-large-2" JustifyContent="center" NoMargin Text={Name.toUpperCase()} TextAlign="center" />

                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={`(${Name} ${t('contract_organization_takes_percent_income', { Percent: IncomeShare }).toLowerCase()})`} TextAlign="center" />

                {
                    SelectedOrganization !== null && !!SelectedOrganization.OrgId &&
                    <>
                        <Spacer Size="large" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('contract_organization_which_branch', { OrgName: Name })} />

                        <Spacer Size="small" />

                        <BranchSelectionContainer className="BranchSelectionContainer">
                            <Select
                                getOptionLabel={option => option.BranchName}
                                getOptionValue={option => option.BranchId}
                                maxMenuHeight={MenuHeight}
                                onChange={this.onChangeBranch}
                                onMenuClose={() => this.onToggleBranchSelectionMenuOpen(false)}
                                onMenuOpen={() => this.onToggleBranchSelectionMenuOpen(true)}
                                options={Branches}
                                placeholder={t('choose_branch')}
                                styles={DropdownStyles}
                                value={SelectedOrganization && SelectedOrganization.BranchId && { BranchId: SelectedOrganization.BranchId, BranchName: SelectedOrganization.BranchName }}
                            />
                        </BranchSelectionContainer>

                        {BranchSelectionMenuOpen && <ExtraSpaceDiv className="ExtraSpaceDiv" MenuHeight={MenuHeight} />}
                    </>
                }
            </>
        );
    }

    renderModalBody = () => {
        var { t } = this.props;
        var { Organizations } = this.props;
        var { SelectedOrganization } = this.state;

        // Trainer Clicked That It Does Not Belong To An Organization
            if (SelectedOrganization !== null && !SelectedOrganization.OrgId) {
                var OrgNames = Organizations.map(({ Name }) => Name).join(', ');

                return (
                    <BodyContainer>
                        <MultiText
                            Texts={[
                                { FontColor: 'red-bittersweet', FontFamily: 'bold', FontSize: 'medium-1', Text: `${t('please_note')}!` },
                                { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-1', Text: t('contract_made_without_organization_part1', { OrgNames }) }
                                // { FontColor: 'red-bittersweet', FontFamily: 'bold', FontSize: 'medium-1', Text: t('contract_made_without_organization_part2') }
                            ]}
                        />
                    </BodyContainer>
                );
            }

        var TitleText = t(Organizations.length > 1 ? 'contract_made_with_organizations' : 'contract_made_with_organization');

        return (
            <BodyContainer>
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={TitleText} TextAlign="center" />

                <Spacer Size="small" />

                {Organizations.length > 1 ? this.renderMultiOrganizations() : this.renderSingleOrganization()}
            </BodyContainer>
        );
    }

    render() {
        var { OnHide, Show } = this.props;

        return (
            <AbelModal
                Backdrop="static"
                BottomButtonRender={this.renderBottomButton}
                // GA_PathName="ChooseOrganization"
                NoMargin
                Show={Show}
                Size="medium"
                OnHide={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

ChooseOrganization.propTypes = {
    OnHide: PropTypes.func.isRequired,
    OnSelectOrganization: PropTypes.func.isRequired,
    Organizations: PropTypes.array.isRequired,
    SelectedOrganization: PropTypes.object,
    Show: PropTypes.bool.isRequired
}

export default withTranslation()(ChooseOrganization);