import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import history from '../../history';

import { AbelLogoContainer, AbelLogoImageContainer, AbelSettingsImageContainer, Container, MenuFooterContainer, MenuItemsContainer } from './styles_leftnavigation';

import MenuLink from './MenuLink';

import Spacer from '../Spacer';

import { ReactComponent as AbelIconSVG } from '../../Assets/SVG/ABEL.svg';
import { ReactComponent as BasketSVG } from '../../Assets/SVG/Basket.svg';
import { ReactComponent as BookingSVG } from '../../Assets/SVG/Booking.svg';
import { ReactComponent as ClientsSVG } from '../../Assets/SVG/Clients.svg';
import { ReactComponent as LearnABELSVG } from '../../Assets/SVG/Learn_ABEL.svg';
import { ReactComponent as LibrarySVG } from '../../Assets/SVG/Nutrition_library.svg';
import { ReactComponent as PlusSVG } from '../../Assets/SVG/Plus.svg';
import { ReactComponent as SalesSVG } from '../../Assets/SVG/Sales.svg';
import { ReactComponent as SettingsSVG } from '../../Assets/SVG/Settings.svg';
import { ReactComponent as VideoSVG } from '../../Assets/SVG/Videocalls.svg';
import { ReactComponent as WorkoutLibrarySVG } from '../../Assets/SVG/Workout_library.svg';

// import Clients from '../../Assets/Icons/AdminMenu/Clients.png';
// import Clients_Hover from '../../Assets/Icons/AdminMenu/Clients_Hover.png';
// import Dashboard from '../../Assets/Icons/AdminMenu/Dashboard.png';
// import Dashboard_Hover from '../../Assets/Icons/AdminMenu/Dashboard_Hover.png';
// import Features from '../../Assets/Icons/AdminMenu/Features.png';
// import Features_Hover from '../../Assets/Icons/AdminMenu/Features_Hover.png';
// import Journal from '../../Assets/Icons/AdminMenu/Journal.png';
// import Journal_Hover from '../../Assets/Icons/AdminMenu/Journal_Hover.png';
// import Library from '../../Assets/Icons/AdminMenu/Library.png';
// import Library_Hover from '../../Assets/Icons/AdminMenu/Library_Hover.png';
// import Sales from '../../Assets/Icons/AdminMenu/Sales.png';
// import Sales_Hover from '../../Assets/Icons/AdminMenu/Sales_Hover.png';
// import SessionLibrary from '../../Assets/Icons/AdminMenu/SessionLibrary.png';
// import SessionLibrary_Hover from '../../Assets/Icons/AdminMenu/SessionLibrary_Hover.png';
// import Settings from '../../Assets/Icons/AdminMenu/Settings.png';
// import Settings_Hover from '../../Assets/Icons/AdminMenu/Settings_Hover.png';
// import Video from "../../Assets/Icons/AdminMenu/Video.png";
// import Video_Hover from "../../Assets/Icons/AdminMenu/Video_Hover.png";

class LeftNavigation extends React.Component {
    state = {
        MenuItems: [
            // { Id: 1, Icon: Dashboard, IconHover: Dashboard_Hover, LinkUrl: '/', Name: 'Dashboard' },
            { Id: 2, Icon: ClientsSVG, LinkUrl: '/clients', Name: 'ptadmin_navigation_clients', BetaAdmin: 'clients' },
            { Id: 3, Icon: SalesSVG, LinkUrl: '/sales', Name: 'ptadmin_navigation_sales' },
            { Id: 10, Icon: BookingSVG, LinkUrl: '/video-calls', Name: 'ptadmin_navigation_video_calls', BetaAdmin: 'booking?tab=booking' },
            { Id: 5, Icon: WorkoutLibrarySVG, LinkUrl: '/library-workouts', Name: 'ptadmin_navigation_library' },
            { Id: 4, Icon: LibrarySVG, LinkUrl: '/library', Name: 'ptadmin_navigation_library' },
            // { Id: 6, Icon: Journal, IconHover: Journal_Hover, LinkUrl: '/', Name: 'Journal' },
            { Id: 7, Icon: LearnABELSVG, LinkUrl: '/manual', Name: 'ptadmin_navigation_features', BetaAdmin: 'learn-abel' },
            // { Id: 8, Icon: SessionLibrary, IconHover: SessionLibrary_Hover, LinkUrl: '/', Name: 'Session Library in App' },
            // { Id: 9, Icon: VideoSVG, LinkUrl: '/video-calls', Name: 'ptadmin_navigation_video_calls' },
        ]
    }

    componentDidMount() {
        var MenuItems;

        if (!!this.props.UserDetails.OrganizationId) {
            MenuItems = [ { ...this.state.MenuItems[1] }, { ...this.state.MenuItems[0] }, { ...this.state.MenuItems[3] }, { ...this.state.MenuItems[4] }, { Id: 9, Icon: BasketSVG, LinkUrl: '/products', Name: 'products', BetaAdmin: 'sales?tab=products' } ];
            MenuItems[0] = { ...MenuItems[0], LinkUrl: '/income', Name: 'income' };
            MenuItems[1] = { ...MenuItems[1], LinkUrl: '/coaches', Name: 'coaches', BetaAdmin: null };

            // Bulk Add Clients
                if (+this.props.UserDetails.UserId === 25734 || +this.props.UserDetails.UserId === 4871 ) {
                    MenuItems = [ ...MenuItems, { Id: 102, Icon: PlusSVG, LinkUrl: '/bulk-upload', Name: 'bulkupload' }]
                }

            this.setState({ MenuItems });
        } else if (!!this.props.UserDetails.DataCenterAccess || !!this.props.UserDetails.UserAccountsAccess) {
            MenuItems = [ ...this.state.MenuItems ];
            if (!!this.props.UserDetails.DataCenterAccess) MenuItems = [ ...MenuItems, { Id: 100, Icon: VideoSVG, LinkUrl: '/datacenter', Name: 'datacenter' }]
            if (!!this.props.UserDetails.UserAccountsAccess) MenuItems = [ ...MenuItems, { Id: 101, Icon: ClientsSVG, LinkUrl: '/user-accounts', Name: 'useraccounts' }]

            this.setState({ MenuItems });
        }
    }

    renderLogo = () => {
        var { IsHeaderMobile } = this.props;

        return (
            <AbelLogoContainer>
                <AbelLogoImageContainer className="clickable" to="/clients" isheadermobile={IsHeaderMobile ? 'true' : 'false'}>
                    <AbelIconSVG />
                </AbelLogoImageContainer>
            </AbelLogoContainer>
        );
    }

    renderMenuFooter = () => {
        return (
            <MenuFooterContainer className="MenuFooterContainer">
                <AbelSettingsImageContainer className="clickable" to="/account" isselected={history.location.pathname === '/account' ? 'true' : 'false'}>
                    <SettingsSVG />
                </AbelSettingsImageContainer>
            </MenuFooterContainer>
        );
    }

    renderMenuItems = () => {
        var { MenuItems } = this.state;

        return (
            <MenuItemsContainer className="MenuItemsContainer">
                {
                    MenuItems.map(MenuItem => {
                        var Selected = history.location.pathname === MenuItem.LinkUrl;

                        return <MenuLink key={MenuItem.Id} {...MenuItem} Selected={Selected} />
                    })
                }
            </MenuItemsContainer>
        );
    }

    render() {
        var { TopBar } = this.props;

        return (
            <Container className="Container" TopBar={TopBar}>
                {this.renderLogo()}

                <Spacer Size="medium" />

                {this.renderMenuItems()}

                {this.renderMenuFooter()}
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        UserDetails: state.Auth.UserDetails,

        IsHeaderMobile: state.Window.IsHeaderMobile
    };
};
  
export default withTranslation()(connect(mapStateToProps, {} )(LeftNavigation));