import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import ButtonGroup from '../../Buttons/ButtonGroup';
import PageText from '../../Text/PageText';
import Spacer from '../../Spacer';

import Checkmark from '../../../Assets/Icons/Checkmark_Selected.png';

const MultiSelect = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 20px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 10px;
    height: 20px;
    left: 15px;
    position: absolute;
    top: 15px;
    width: 20px;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 20px;
            border: none;
            cursor: pointer;
        }
    }
`;

const FoodContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    ${
        props => props.Selected && 1 === 2 ?
        `
            border: 5px solid var(--blue-abel);
            border-radius: 10px;
            padding: 10px;
        `
    :
        null
    }

    > div:first-of-type {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
`;

const FoodDetailContainer = styled.div`
    background-color: var(--white-concrete);
    border-radius: 20px;
    padding: ${props => props.IsMultiSelect ? '15px 35px' : '15px'};

    :hover {
        background-color: var(--white-concrete-hover);
        cursor: pointer;
    }

    ${
        props => props.Selected ?
        `
            background-color: rgba(26, 151, 255, 0.35);
            border: 5px solid var(--blue-abel);
            border-radius: 20px;
            padding: 10px;

            :hover {
                background-color: rgba(26, 151, 255, 0.35);
            }
        `
    :
        null
    }
`;

const StyledFoodName = styled(PageText)`
    text-transform: uppercase;
`;

class FoodListItem extends React.Component {
    renderMultiSelect = () => {
        var { Item, MultiSelectItemIds, OnMultiSelect } = this.props;

        var { FoodId } = Item;

        var MultiSelectIndex = MultiSelectItemIds.indexOf(Number(FoodId));

        return <MultiSelect onClick={() => OnMultiSelect(Item, MultiSelectIndex)} Selected={MultiSelectIndex !== -1} />;
    }
    
    render() {
        var { t } = this.props;
        var { HideSelect, MultiSelectItemIds, IsMultiSelect, Item, SelectFood, SelectedItemIds, ViewFood } = this.props;

        var { FoodId, FoodName } = Item;

        var Selected = SelectedItemIds.indexOf(Number(FoodId)) > -1 || MultiSelectItemIds.indexOf(Number(FoodId)) > -1;

        return (
            <FoodContainer id={`itemid-${FoodId}`} Selected={Selected}>
                {
                    IsMultiSelect ?
                    this.renderMultiSelect()
                :
                    null
                }

                <FoodDetailContainer IsMultiSelect={IsMultiSelect} onClick={() => ViewFood(Item)} Selected={Selected}>
                    <StyledFoodName FontFamily="semibold" FontSize="small" NoMargin Text={FoodName} TextAlign="center"/>
                </FoodDetailContainer>

                {
                    !HideSelect && !IsMultiSelect ?
                    <>
                        <Spacer Size="extra-small" />
                        
                        <ButtonGroup
                            Buttons={[{ BackgroundColorHover: 'blue-abel', BackgroundColorSelected: 'blue-abel', ColorHover: 'white', ColorSelected: 'white', FontFamily: 'medium', FontSize: 'small', OnClick: () => SelectFood(Item), Selected, Size: 'small', Title: t(Selected ? 'WorkoutPlanSettings_selected' : 'select_with_first_char_uppercase') }]}
                            ContainerWidth="100%"
                        />
                    </>
                :
                    null
                }
            </FoodContainer>
        );
    }
}

FoodListItem.propTypes = {
    HideSelect: PropTypes.bool,
    IsMultiSelect: PropTypes.bool,
    Item: PropTypes.object,
    OnMultiSelect: PropTypes.func,
    MultiSelectItemIds: PropTypes.array,
    SelectFood: PropTypes.func,
    SelectedItemIds: PropTypes.array,
    ViewFood: PropTypes.func
}

FoodListItem.defaultProps = {
    MultiSelectItemIds: [],
    SelectedItemIds: []
}

export default withTranslation()(FoodListItem);