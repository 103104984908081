import styled from 'styled-components';

import Loading from '../../Loading';

export const ItemsContainer = styled.div``;

export const LoadingContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

export const LoadMoreContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

export const ListContainer = styled.div`
    border: 2px solid var(--white-concrete);
    border-radius: 23px;
    padding: 15px;
`;

export const ListItemContainer = styled.div`
    padding: 10px;

    :hover {
        background-color: var(--white-concrete-hover);
        color: var(--white);
        cursor: pointer;
    }

    :nth-child(2n) {
        background: var(--white-concrete);

        :hover {
            background-color: var(--white-concrete-hover);
            color: var(--white);
        }
    }
`;

export const StyledLoading = styled(Loading)`
    background-color: inherit;
    position: unset;
`;

export const ViewCount = styled.div`
    text-align: center;
`;