import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import * as styles from './styles';

import AbelModal from '../../Components/Modal';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

const BaseS3Url = 'https://abel-technologies.s3.amazonaws.com/manuals/workouttemplatefillin/';

const PosterUrl = `${BaseS3Url}WorkoutFillIn_Poster.png`;
const VideoUrl = `${BaseS3Url}Workout_fill_in_video.mp4`;

class WorkoutTemplateFillIn extends React.Component {
    state = {};

    componentDidMount() {
        var { LanguageId } = this.props.UserDetails;

        if (+LanguageId === 3) LanguageId = 2;

        var Picture1 = `${BaseS3Url}workouttemplatefillin_dynamic_section_nonmax_${LanguageId}.png`;
        var Picture2 = `${BaseS3Url}workouttemplatefillin_createmaxset_${LanguageId}.png`;
        var Picture3 = `${BaseS3Url}workouttemplatefillin_chart_${LanguageId}.png`;
        var Picture4 = `${BaseS3Url}workouttemplatefillin_tellingmax_1_${LanguageId}.png`;
        var Picture5 = `${BaseS3Url}workouttemplatefillin_tellingmax_2_${LanguageId}.png`;
        var Picture6 = `${BaseS3Url}workouttemplatefillin_tellingmax_3_${LanguageId}.png`;
        var Picture7 = `${BaseS3Url}workouttemplatefillin_tellingmax_4_${LanguageId}.png`;

        this.setState({ Picture1, Picture2, Picture3, Picture4, Picture5, Picture6, Picture7 });
    }

    renderCreateMaxSet = () => {
        var { t } = this.props;

        return (
            <>
                {this.renderSection({ SectionName: 'workouttemplatefillin_createmaxset' })}

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_createmaxset_description_1')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <styles.StyledPicture2 ImageSrc={this.state.Picture2} SelfLoading />

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_createmaxset_description_2')} TextAlign="left" />
            </>
        );
    }

    renderDifferentWays = () => {
        var { t } = this.props;

        return (
            <>
                {this.renderSection({ SectionName: 'workouttemplatefillin_5ways_to_fill_in' })}

                <Spacer Size="extra-small" />

                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_static_title')} TextAlign="left" />
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_static_description')} TextAlign="left" WhiteSpace="pre-line" />

                <Spacer Size="extra-small" />

                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_percentofmax_progress_title')} TextAlign="left" />
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_percentofmax_progress_description')} TextAlign="left" WhiteSpace="pre-line" />

                <Spacer Size="extra-small" />

                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_percentofmax_alltime_title')} TextAlign="left" />
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_percentofmax_alltime_description')} TextAlign="left" WhiteSpace="pre-line" />

                <Spacer Size="extra-small" />

                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_percentofmax_custom_title')} TextAlign="left" />
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_percentofmax_custom_description')} TextAlign="left" WhiteSpace="pre-line" />
            </>
        );
    }

    renderDynamic = () => {
        var { t } = this.props;

        return (
            <>
                {this.renderSection({ HasRecommended: true, SectionName: 'dynamic' })}

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_description')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <PageText FontFamily="bold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_progress_record_title')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_progress_record_description_1')} TextAlign="left" />

                <Spacer Size="small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: '-' },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('workouttemplatefillin_dynamic_section_progress_record_description_2'), Underline: true },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('workouttemplatefillin_dynamic_section_progress_record_description_3') }
                    ]}
                />

                <Spacer Size="extra-small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: '-' },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('workouttemplatefillin_dynamic_section_progress_record_description_4'), Underline: true },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('workouttemplatefillin_dynamic_section_progress_record_description_5') }
                    ]}
                />

                <Spacer Size="medium" />

                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_progress_record_description_6')} TextAlign="left" />

                <Spacer Size="small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: '-' },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('workouttemplatefillin_dynamic_section_progress_record_description_7'), Underline: true },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('workouttemplatefillin_dynamic_section_progress_record_description_8'), WhiteSpace: 'pre-line' }
                    ]}
                />

                <Spacer Size="extra-small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: '-' },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('workouttemplatefillin_dynamic_section_progress_record_description_9'), Underline: true },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('workouttemplatefillin_dynamic_section_progress_record_description_10'), WhiteSpace: 'pre-line' }
                    ]}
                />

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_progress_record_description_11')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_progress_record_description_12')} TextAlign="left" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_progress_record_description_13')} TextAlign="left" Underline />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_progress_record_description_14')} TextAlign="left" WhiteSpace="pre-line" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_progress_record_description_15')} TextAlign="left" Underline />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_progress_record_description_16')} TextAlign="left" WhiteSpace="pre-line" />

                
                
                <Spacer Size="medium" />



                <PageText FontFamily="bold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_custom_title')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_custom_description_1')} TextAlign="left" />

                <Spacer Size="small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: '-' },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('workouttemplatefillin_dynamic_section_custom_description_2') },
                        { FontFamily: 'semibold', FontSize: 'medium-1', Text: t('workouttemplatefillin_dynamic_section_custom_description_3') },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('workouttemplatefillin_dynamic_section_custom_description_4') }
                    ]}
                />

                <Spacer Size="small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: '-' },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('workouttemplatefillin_dynamic_section_custom_description_5') },
                        { FontFamily: 'semibold', FontSize: 'medium-1', Text: t('workouttemplatefillin_dynamic_section_custom_description_6') },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('workouttemplatefillin_dynamic_section_custom_description_7') }
                    ]}
                />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_custom_description_8')} TextAlign="left" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_custom_description_9')} TextAlign="left" />
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_custom_description_10')} TextAlign="left" Underline />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_custom_description_11')} TextAlign="left" />
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_custom_description_12')} TextAlign="left" Underline />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_custom_description_13')} TextAlign="left" />
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_custom_description_14')} TextAlign="left" Underline />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_custom_description_15')} TextAlign="left" />
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_custom_description_16')} TextAlign="left" Underline />



                <Spacer Size="medium" />



                <PageText FontFamily="bold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_nonmax_sets_title')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_dynamic_section_nonmax_sets_description')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <styles.StyledPicture1 ImageSrc={this.state.Picture1} SelfLoading />
            </>
        );
    }

    renderHowRoundingWorks = () => {
        var { t } = this.props;

        return (
            <>
                {this.renderSection({ SectionName: 'workouttemplatefillin_howroundingworks' })}

                <Spacer Size="extra-small" />

                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_howroundingworks_description_1')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_howroundingworks_description_2')} TextAlign="left" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_howroundingworks_description_3')} TextAlign="left" Underline />
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_howroundingworks_description_4')} TextAlign="left" WhiteSpace="pre-line" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_howroundingworks_description_5')} TextAlign="left" Underline />
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_howroundingworks_description_6')} TextAlign="left" WhiteSpace="pre-line" />

                <Spacer Size="small" />

                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_howroundingworks_description_7')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_howroundingworks_description_8')} TextAlign="left" WhiteSpace="pre-line" />
            </>
        );
    }

    renderSection = ({ HasRecommended = false, SectionName }) => {
        var { t } = this.props;

        return (
            <styles.SectionContainer className="SectionContainer">
                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t(`${SectionName}`)} TextAlign="left" />

                {
                    !!HasRecommended &&
                    <styles.RecommendedContainer className="RecommendedContainer">
                        <PageText FontColor="white" FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text={t(`recommended`)} TextAlign="center" />
                    </styles.RecommendedContainer>
                }
            </styles.SectionContainer>
        );
    }

    renderStatic = () => {
        var { t } = this.props;

        return (
            <>
                {this.renderSection({ SectionName: 'static' })}

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_static_section_description')} TextAlign="left" WhiteSpace="pre-line" />
            </>
        );
    }

    renderWhyTellingMax = () => {
        var { t } = this.props;

        return (
            <>
                {this.renderSection({ SectionName: 'workouttemplatefillin_whytellingmax' })}

                <Spacer Size="extra-small" />

                <PageText FontFamily="bold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_whytellingmax_description_1')} TextAlign="left" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_whytellingmax_description_2')} TextAlign="left" />

                <Spacer Size="small" />

                <styles.StyledPicture3 ImageSrc={this.state.Picture3} SelfLoading />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_whytellingmax_description_3')} TextAlign="left" />

                <Spacer Size="small" />

                <styles.StyledPicture4 ImageSrc={this.state.Picture4} SelfLoading />

                <Spacer Size="small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('workouttemplatefillin_whytellingmax_description_4') },
                        { FontFamily: 'semibold', FontSize: 'medium-1', Text: t('workouttemplatefillin_whytellingmax_description_5') },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('workouttemplatefillin_whytellingmax_description_6') }
                    ]}
                />

                <Spacer Size="small" />

                <styles.StyledPicture5 ImageSrc={this.state.Picture5} SelfLoading />

                <Spacer Size="medium" />

                <PageText FontFamily="bold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_whytellingmax_description_7')} TextAlign="left" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('workouttemplatefillin_whytellingmax_description_8')} TextAlign="left" />

                <Spacer Size="small" />

                <styles.StyledPicture6 ImageSrc={this.state.Picture6} SelfLoading />

                <Spacer Size="extra-small" />

                <styles.StyledPicture7 ImageSrc={this.state.Picture7} SelfLoading />
            </>
        );
    }

    renderModalBody = () => {
        var { t } = this.props;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('workouttemplatefillin_title')} TextAlign="center" />

                <Spacer Size="small" />

                <styles.StyledVideo controls poster={PosterUrl}><source src={VideoUrl} />Your browser does not support the video tag.</styles.StyledVideo>

                <Spacer Size="large" />

                {this.renderDifferentWays()}

                <Spacer Size="medium" />

                {this.renderStatic()}

                <Spacer Size="medium" />

                {this.renderDynamic()}

                <Spacer Size="medium" />

                {this.renderCreateMaxSet()}

                <Spacer Size="medium" />

                {this.renderWhyTellingMax()}

                <Spacer Size="medium" />

                {this.renderHowRoundingWorks()}
            </>
        );
    }

    render() {
        var { OnHide, Show } = this.props;

        return (
            <AbelModal
                BottomButton="cancel"
                BottomButtonOnClick={OnHide}
                GA_PathName="WorkoutTemplateFillIn"
                Show={Show}
                Size="medium"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

WorkoutTemplateFillIn.propTypes = {
    OnHide: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        UserDetails: state.Auth.UserDetails
    };
};

export default withTranslation()(connect(mapStateToProps, null )(WorkoutTemplateFillIn));