import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: ${props => props.JustifyContent ? props.JustifyContent.toLowerCase() : 'center'};
    margin-bottom: ${props => props.NoMargin ? '0px' : 'var(--spacer-size-extra-small)'};
    ${({ TextAlign }) => TextAlign ? `text-align: ${TextAlign.toLowerCase()};` : null}
    ${({ ContainerWidth }) => ContainerWidth ? `margin-left: auto; margin-right: auto; width: ${ContainerWidth};` : null}
    max-width: 100%;
    ${({ NoWrap }) => NoWrap ? 'white-space: nowrap;' : null}
    ${({ WhiteSpace }) => WhiteSpace ? `white-space: ${WhiteSpace};` : null}
    word-break: ${props => props.WordBreak ? props.WordBreak.toLowerCase() : 'break-word'};
`;

export const TitleText = styled.div`
    align-items: flex-end;
    ${({ FontColor }) => (FontColor ? `color: var(--${FontColor.toLowerCase()});` : null)}
    ${({ FontColorHex }) => (FontColorHex ? `color: ${FontColorHex.toLowerCase()};` : null)}
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-family: ${props => props.FontFamily ? `var(--font-family-${props.FontFamily.toLowerCase()})` : 'var(--font-family-semibold)'};
    font-size: ${props => props.FontSize ? `var(--font-size-${props.FontSize.toLowerCase()})` : 'var(--font-size-large)'};
    ${props => props.Underline && 'text-decoration: underline;'}
    ${props => props.Lowercase && `text-transform: lowercase;`}
    ${props => props.Uppercase && `text-transform: uppercase;`}
    ${props => props.Strikethrough && `text-decoration: line-through;`}

    ${({ Clickable }) => (
        Clickable &&
        `@media(hover:hover) {
            :hover {
                cursor: pointer;
            }
        }`
    )}

    ${({ FontColorHover }) => (
        FontColorHover &&
        `@media(hover:hover) {
            :hover {
                color: var(--${FontColorHover.toLowerCase()});
            }
        }`
    )}

    ${({ FontFamilyHover }) => (
        FontFamilyHover &&
        `@media(hover:hover) {
            :hover {
                font-family: var(--font-family-${FontFamilyHover.toLowerCase()});
            }
        }`
    )}

    ${
        props => !!props.SelectedOptions && props.SelectedOptions.Position === 'left' &&
        `
            :before {
                background-color: ${!!props.SelectedOptions.BackgroundColor ? `var(--${props.SelectedOptions.BackgroundColor})` : 'var(--blue-abel)'};
                content: '';
                display: block;
                height: ${!!props.SelectedOptions.Selected ? '100%' : '0px'};
                margin-right: 5px;
                width: 5px;

                -webkit-transition: all .2s ease-in-out;
                -moz-transition: all .2s ease-in-out;
                -o-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;
            }
        
            ${
                props.Selectable &&
                `
                    @media(hover:hover) {
                        :hover:before {
                            height: 100%;
                        }  
                    }
                `
            }
        `
    }

    ${
        props => !!props.SelectedOptions && props.SelectedOptions.Position === 'right' &&
        `
            :before {
                background-color: ${!!props.SelectedOptions.BackgroundColor ? `var(--${props.SelectedOptions.BackgroundColor})` : 'var(--blue-abel)'};
                content: '';
                display: block;
                height: ${!!props.SelectedOptions.Selected ? '100%' : '0px'};
                margin-left: 5px;
                width: 5px;

                -webkit-transition: all .2s ease-in-out;
                -moz-transition: all .2s ease-in-out;
                -o-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;
            }
        
            ${
                props.Selectable &&
                `
                    @media(hover:hover) {
                        :hover:before {
                            height: 100%;
                        }  
                    }
                `
            }
        `
    }
`;

export const TitleTextSpan = styled.span`
    ${({ FontColor }) => (FontColor ? `color: var(--${FontColor.toLowerCase()});` : null)}
    ${({ FontColorHex }) => (FontColorHex ? `color: ${FontColorHex.toLowerCase()};` : null)}
    font-family: ${props => props.FontFamily ? `var(--font-family-${props.FontFamily.toLowerCase()})` : 'var(--font-family-semibold)'};
    font-size: ${props => props.FontSize ? `var(--font-size-${props.FontSize.toLowerCase()})` : 'var(--font-size-large)'};
    ${props => props.Underline && 'text-decoration: underline;'}
    ${props => props.Lowercase && `text-transform: lowercase;`}
    ${props => props.Uppercase && `text-transform: uppercase;`}
    ${props => props.Strikethrough && `text-decoration: line-through;`}
    ${({ NoWrap }) => NoWrap ? 'white-space: nowrap;' : null}
    ${({ WhiteSpace }) => WhiteSpace ? `white-space: ${WhiteSpace};` : null}
    word-break: ${props => props.WordBreak ? props.WordBreak.toLowerCase() : 'break-word'};

    ${({ Clickable }) => (
        Clickable &&
        `@media(hover:hover) {
            :hover {
                cursor: pointer;
            }
        }`
    )}

    ${({ FontColorHover }) => (
        FontColorHover &&
        `@media(hover:hover) {
            :hover {
                color: var(--${FontColorHover.toLowerCase()});
            }
        }`
    )}

    ${({ FontFamilyHover }) => (
        FontFamilyHover &&
        `@media(hover:hover) {
            :hover {
                font-family: var(--font-family-${FontFamilyHover.toLowerCase()});
            }
        }`
    )}
`;