import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearSearchFood, DeleteFoods, DeleteFoodsConfirmation, SaveDataInProps, SearchFood_SAYT, SearchFood } from '../../Services/Actions';
import history from '../../history';

import { device } from '../../Config/device';
import styled from 'styled-components';

import BottomBar from '../../Components/BottomBar';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import { DishListItem, FoodListItem } from '../../Components/Lists/ListItems';
import { GA_PageView } from '../../Components/GoogleAnalytics';
// import Input from '../../Components/Form/Input';
import LoadMore from '../../Components/Lists/LoadMore';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import SAYT from '../../Components/Form/SAYT';
import Spacer from '../../Components/Spacer';

import DishDetail from '../../Modals/DishDetail';
import Info from '../../Modals/Info';
import NutritionFacts from '../../Modals/NutritionFacts';

import Plus from '../../Assets/Icons/Plus.png';

const AllFilters = styled.div`
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
    }
`;

const BottomBarItems = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

const DeleteFoodsList = styled.div`
    display: grid;
    grid-gap: 40px 15px;
    grid-template-columns: repeat(1, 2fr);
    justify-content: center;

    @media ${device.minwidth.mobile} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.minwidth.laptop} {
        grid-template-columns: repeat(4, 1fr);
    }
`;

const PrivateDishesContainer = styled.div`
    background-color: var(--white);
    display: grid;
    grid-gap: 40px 15px;
    grid-template-columns: repeat(1, 2fr);
    padding: 15px;        

    @media ${device.minwidth.mobile} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.minwidth.laptop} {
        grid-template-columns: repeat(4, 1fr);
    }
`;

const SelectedItems = styled(PageText)`
    background-color: var(--blue-abel);
    border-radius: 20px;
    padding: 5px 15px;
`;

const StyledLoadMore = styled(LoadMore)`
    background-color: var(--white);
    padding: 15px;

    > div:first-child {
        display: grid;
        grid-gap: 40px 15px;
        grid-template-columns: repeat(1, 2fr);

        @media ${device.minwidth.mobile} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media ${device.minwidth.ipad} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media ${device.minwidth.laptop} {
            grid-template-columns: repeat(4, 1fr);
        }
    }
`;

// const StyledSearchInput = styled(Input)`
//     margin-bottom: 10px;
//     max-width: 400px;
//     width: 100%;

//     > input {
//         border: 2px solid var(--white-concrete);
//     }

//     @media ${device.minwidth.ipad} {
//         margin-bottom: 0px;
//     }
// `;

const INITIAL_STATE = {
    PageNo: 1, PageSize: 48
}

class Food extends React.Component {
    state = {
        ...INITIAL_STATE,
        ActiveSearch: false,
        CompletedFirstSearch: false,
        ConfirmDeleteDishes: [],
        IngredientsShown: [ 2 ],
        LastSearchValue: '',
        Loading: true,
        MultiSelectFoodIds: [],
        MultiSelectFoods: [],
        NewSearch: false,
        SearchValue: '',
        SelectedFoodId: null,
        ShowConfirmDeleteDishesModal: false,
        ShowDeleteFoodsModal: false,
        ShowDishDetailModal: false,
        ShowDishDetailPreview: {},
        ShowFoodDetailModal: false,
        StartDate: null
    };

    componentDidMount() {
        this._isMounted = true;

        GA_PageView('Library-Food');

        this.onLoadFoods();
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearSearchFood();
    }

    onCancelMultiSelect = () => {
        this.setState({ MultiSelectFoodIds: [], MultiSelectFoods: [] });
    }

    onChangeSearch = event => {
        this.setState({ SearchValue: event.target.value });
    }

    onClickCreatePrivateFood = () => {
        history.push('library/food');
    }

    onClickDeleteFood = SelectedFoodId => {
        this.setState({ ShowFoodDetailModal: false });

        this.props.DeleteFoodsConfirmation({ FoodIds: [ SelectedFoodId ], UserId: +this.props.UserDetails.UserId }).then(({ DishesList, NeedsConfirm }) => {
            if (NeedsConfirm) this.setState({ ConfirmDeleteDishes: DishesList, ShowConfirmDeleteDishesModal: true });
        });
    }

    onClickEditFood = SelectedFoodId => {
        this.onToggleShowFoodDetailModal(false);
        history.push(`library/food/${SelectedFoodId}`);
    }

    onConfirmDeleteFoods = () => {
        this.onToggleShowConfirmDeleteDishesModal(false);

        var DishesList = this.state.ConfirmDeleteDishes.map(({ DishId, dataDishFoods }) => ({ DishId, lstDishFoods: dataDishFoods.map(Food => Food) }));

        this.props.DeleteFoods({ DishesList, UserId: +this.props.UserDetails.UserId }).then(() => {
            this.onCancelMultiSelect();
            this.onLoadFoods();
        });
    }

    onDeleteFoods = () => {
        var { MultiSelectFoodIds } = this.state;

        this.setState({ ShowDeleteFoodsModal: false });

        this.props.DeleteFoodsConfirmation({ FoodIds: MultiSelectFoodIds, UserId: +this.props.UserDetails.UserId }).then(({ DishesList, NeedsConfirm }) => {
            // Load Foods Immediately, so that if only some foods require confirmation but others were deleted, they won't show behind the popup incorrectly
            this.onLoadFoods();
            
            if (NeedsConfirm) {
                // Update Multi-Select To Only Be Foods in DishesList
                    var MultiSelectFoodIds = [];
                    var MultiSelectFoods = [];

                    var DishesListLength = DishesList.length;
                    for (var i = 0; i < DishesListLength; i++) {
                        var DishFoodsLength = DishesList[i].dataDishFoods.length;
                        for (var a = 0; a < DishFoodsLength; a++) {
                            var FoodId = +DishesList[i].dataDishFoods[a].FoodId;

                            if (MultiSelectFoodIds.indexOf(FoodId) === -1) {
                                var StateIndex = this.state.MultiSelectFoodIds.indexOf(FoodId);

                                MultiSelectFoodIds.push(FoodId);
                                MultiSelectFoods.push({ ...this.state.MultiSelectFoods[StateIndex] });
                            }
                        }
                    }

                this.setState({ ConfirmDeleteDishes: DishesList, MultiSelectFoodIds, MultiSelectFoods, ShowConfirmDeleteDishesModal: true });
            }
            else this.onCancelMultiSelect();
        });
    }

    onKeyPress = ({ key }) => {
        if (key === 'Enter' && !this.props.TryingSearchFood) {
            var { SearchValue, LastSearchValue } = this.state;

            if (SearchValue !== LastSearchValue) {
                this.setState({ PageNo: 1, PageSize: 48, LastSearchValue: SearchValue }, () => {
                    this.onLoadFoods();
                });
            }
        }
    }

    onLoadFoods = Increment => {
        var { IngredientsShown, NewSearch, PageNo, PageSize, SearchValue } = this.state;

        if (Increment) {
            NewSearch = false;
            PageNo = PageNo + 1;
        } else {
            NewSearch = true;
            PageNo = 1;
        }

        this.setState({ ActiveSearch: true, NewSearch, PageNo }, () => this.props.SearchFood({ AddToExisting: Increment, PageNo, PageSize, FoodName: SearchValue, IngredientsShown }).then(() => this._isMounted ? this.setState({ ActiveSearch: false, CompletedFirstSearch: true, Loading: false }) : null));
    }

    onMultiSelect = (Food, MultiSelectIndex) => {
        if (MultiSelectIndex === -1) this.setState({ MultiSelectFoodIds: [ ...this.state.MultiSelectFoodIds, Number(Food.FoodId)], MultiSelectFoods: [ ...this.state.MultiSelectFoods, Food ] });
        else {
            this.setState((prevState) => {
                return {
                    MultiSelectFoodIds: [ ...prevState.MultiSelectFoodIds.slice(0, MultiSelectIndex), ...prevState.MultiSelectFoodIds.slice(MultiSelectIndex + 1) ],
                    MultiSelectFoods: [ ...prevState.MultiSelectFoods.slice(0, MultiSelectIndex), ...prevState.MultiSelectFoods.slice(MultiSelectIndex + 1) ]
                }
            });
        }
    }

    onSearchFromSAYT = SearchValue => {
        if (!this.props.TryingSearchFood) {
            var { LastSearchedValue } = this.state;

            if (SearchValue !== LastSearchedValue) {
                this.setState({ PageNo: 1, PageSize: INITIAL_STATE.PageSize, LastSearchedValue: SearchValue, SearchValue }, () => this.onLoadFoods());
            }
        }
    }

    onToggleShowConfirmDeleteDishesModal = ShowConfirmDeleteDishesModal => {
        if (!ShowConfirmDeleteDishesModal) this.setState({ ConfirmDeleteDishes: [], ShowConfirmDeleteDishesModal });
        else this.setState({ ShowConfirmDeleteDishesModal });
    }

    onToggleShowDeleteFoodsModal = ShowDeleteFoodsModal => {
        this.setState({ ShowDeleteFoodsModal });
    }

    onToggleShowDishDetailModal = (ShowDishDetailModal, Dish) => {
        this.setState({ ShowDishDetailModal, ShowDishDetailPreview: Dish || {} });
    }

    onToggleShowFoodDetailModal = (ShowFoodDetailModal, Food) => {
        var { SelectedFoodId } = this.state;

        if (!ShowFoodDetailModal) SelectedFoodId = null;
        else if (Food) SelectedFoodId = +Food.FoodId;
        
        this.setState({ SelectedFoodId, ShowFoodDetailModal });
    }

    renderBottomBar = () => {
        var { MultiSelectFoodIds } = this.state;

        if (!MultiSelectFoodIds.length) return null;

        var { t } = this.props;

        return (
            <>
                <Spacer Size="extra-large" />

                <BottomBar>
                    <BottomBarItems>
                        <SelectedItems ElementType="span" FontColor="white" FontFamily="bold" FontSize="medium-3" Text={`${MultiSelectFoodIds.length}`} />
                        &nbsp;
                        <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('WorkoutPlanSettings_selected').toLowerCase()} />
                    </BottomBarItems>
                    <BottomBarItems>
                        <ButtonGroup
                            Buttons={[
                                { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Color: 'white', ColorHover: 'white', OnClick: () => this.onToggleShowDeleteFoodsModal(true), Title: t('delete_with_first_char_uppercase') },
                                { BackgroundColor: 'white', BackgroundColorHover: 'blue-astronaut', Border: '2px solid var(--blue-astronaut)', ColorHover: 'white', OnClick: this.onCancelMultiSelect, Title: t('Cancel_with_first_char_uppercase') }
                            ]}
                            NotTouching
                        />
                    </BottomBarItems>
                </BottomBar>
            </>
        );
    }

    renderConfirmDeleteDishesInformation = () => {
        var { t } = this.props;
        var { ConfirmDeleteDishes } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" Text={t('dishes_delete_warning_from_foods')} TextAlign="center" />

                <PrivateDishesContainer>
                    {
                        ConfirmDeleteDishes.map(PrivateDish => <DishListItem key={PrivateDish.DishId} HideOwnerRating HideSelect Item={PrivateDish} ViewDish={Dish => this.onToggleShowDishDetailModal(true, Dish)} />)
                    }
                </PrivateDishesContainer>
            </>
        );
    }

    renderConfirmDeleteDishesModal = () => {
        var { t } = this.props;
        var { TryingDeleteFoods } = this.props;
        var { ShowConfirmDeleteDishesModal, ShowDishDetailModal } = this.state;

        if (ShowConfirmDeleteDishesModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onConfirmDeleteFoods}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    DisplayNone={ShowDishDetailModal}
                    InformationRenderer={this.renderConfirmDeleteDishesInformation}
                    Loading={TryingDeleteFoods}
                    OnHide={() => this.onToggleShowConfirmDeleteDishesModal(false)}
                    Show={ShowConfirmDeleteDishesModal}
                />
            );
        }
    }

    renderDeleteFoodsInformation = () => {
        var { t } = this.props;
        var { MultiSelectFoods } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" Text={t('food_delete_warning')} TextAlign="center" />

                <DeleteFoodsList>
                    {
                        MultiSelectFoods.map(Food => <FoodListItem key={Food.FoodId} HideSelect Item={Food} ViewFood={Food => this.onToggleShowFoodDetailModal(true, Food)} />)
                    }
                </DeleteFoodsList>
            </>
        );
    }

    renderDeleteFoodsModal = () => {
        var { t } = this.props;
        var { TryingDeleteFoods } = this.props;
        var { ShowDeleteFoodsModal, ShowFoodDetailModal } = this.state;

        if (ShowDeleteFoodsModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteFoods}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    DisplayNone={ShowFoodDetailModal}
                    InformationRenderer={this.renderDeleteFoodsInformation}
                    Loading={TryingDeleteFoods}
                    OnHide={() => this.onToggleShowDeleteFoodsModal(false)}
                    Show={ShowDeleteFoodsModal}
                />
            );
        }
    }

    renderDishDetailModal = () => {
        var { ShowDishDetailModal, ShowDishDetailPreview } = this.state;

        if (ShowDishDetailModal) {
            return (
                <DishDetail
                    DishDetail_Preview={ShowDishDetailPreview}
                    OnHide={() => this.onToggleShowDishDetailModal(false)}
                    Show={ShowDishDetailModal}
                />
            );
        }
    }

    renderFilters = () => {
        var { t } = this.props;
        var { Device } = this.props;

        return (
            <AllFilters>
                {/* <StyledSearchInput
                    FontSize="medium-2"
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeSearch}
                    OnKeyPress={this.onKeyPress}
                    Placeholder={t('search_with_first_char_uppercase_dots')}
                    Size="medium"
                    Type="text"
                    Value={SearchValue}
                /> */}

                <SAYT
                    ExtraSearchProps={{ IngredientsShown: [ 2 ]}}
                    ItemName="FoodName"
                    OnSearch={this.onSearchFromSAYT}
                    Placeholder={t('search_with_first_char_uppercase_dots')}
                    SearchFunc={this.props.SearchFood_SAYT}
                />

                <ButtonGroup
                    Buttons={[{ Icon: Plus, IconPosition: 'left', OnClick: this.onClickCreatePrivateFood, Title: t('create_private_food') }]}
                    ContainerWidth={Device === 'mobile_small' || Device === 'mobile' ? '100%' : null}
                />
            </AllFilters>
        );
    }

    renderFoodDetailModal = () => {
        var { ShowFoodDetailModal, SelectedFoodId } = this.state;

        if (ShowFoodDetailModal) {
            return (
                <NutritionFacts
                    FoodId={+SelectedFoodId}
                    OnDeleteFood={() => this.onClickDeleteFood(SelectedFoodId)}
                    OnEditFood={() => this.onClickEditFood(SelectedFoodId)}
                    OnHide={() => this.onToggleShowFoodDetailModal(false)}
                    Show={ShowFoodDetailModal}
                    ShowFoodOptions
                    UserId={+this.props.UserDetails.UserId}
                />
            );
        }
    }

    render() {
        var { t } = this.props;
        var { Device, IsMoreFood, FoodList, FoodList_TotalRecords, TryingDeleteFoods, TryingDeleteFoodsConfirmation, TryingSearchFood } = this.props;
        var { ActiveSearch, CompletedFirstSearch, MultiSelectFoodIds, NewSearch, PageNo, PageSize, SelectedFoodId } = this.state;

        var SelectedItemIds = SelectedFoodId ? [ Number(SelectedFoodId) ] : [];

        return (
            <>
                {(TryingDeleteFoods || TryingDeleteFoodsConfirmation) && <Loading />}

                {this.renderFilters()}

                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'large' : 'medium'} />

                <StyledLoadMore
                    ActiveSearch={ActiveSearch}
                    CompletedFirstSearch={CompletedFirstSearch}
                    IsLoading={TryingSearchFood}
                    ItemDescriptionType={t('foods').toLowerCase()}
                    ItemId="FoodId"
                    ItemName="FoodName"
                    ItemProps={{ HideSelect: true, IsMultiSelect: true, MultiSelectItemIds: MultiSelectFoodIds, OnMultiSelect: (Food, MultiSelectIndex) => this.onMultiSelect(Food, MultiSelectIndex), SelectFood: Food => this.onSelectFood(Food), ViewFood: Food => this.onToggleShowFoodDetailModal(true, Food) }}
                    ItemRenderer={FoodListItem}
                    Items={FoodList}
                    HasMoreItems={IsMoreFood}
                    LoadMore={() => this.onLoadFoods(true)}
                    NewSearch={NewSearch}
                    NoItemsText={t('search_noresults')}
                    PageNo={PageNo}
                    PageSize={PageSize}
                    SelectedItemIds={SelectedItemIds}
                    TotalRecords={FoodList_TotalRecords}
                />

                {this.renderBottomBar()}

                {this.renderConfirmDeleteDishesModal()}
                {this.renderDeleteFoodsModal()}
                {this.renderDishDetailModal()}
                {this.renderFoodDetailModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        FoodList: state.Food.FoodList,
        FoodList_TotalRecords: state.Food.FoodList_TotalRecords,
        IsMoreFood: state.Food.IsMoreFood,

        TryingDeleteFoods: state.Food.TryingDeleteFoods,
        TryingDeleteFoodsConfirmation: state.Food.TryingDeleteFoodsConfirmation,
        TryingSearchFood: state.Food.TryingSearchFood,
        TryingSearchFoodError: state.Food.TryingSearchFoodError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearSearchFood, DeleteFoods, DeleteFoodsConfirmation, SaveDataInProps, SearchFood_SAYT, SearchFood } )(Food));