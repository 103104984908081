import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Popover from 'react-popover';

import { Container, DataContainer, DataParentContainer, ExtraContainer, MaxPercentInfoIcon, PopoverContent, StyledHeaders, StyledInputLabelSideBySide, StyledTestSetsButtonGroup, TestContainer, TestDataContainer, TestDataParentContainer, TestInnerContainer } from './styles';

import ButtonGroup from '../Buttons/ButtonGroup';
import MultiText from '../Text/MultiText';
import PageText from '../Text/PageText';
import Spacer from '../Spacer';

import Info from '../../Assets/Icons/Info.png';

import { CalculateDynamicRepsFromWeight, CalculateDynamicWeightFromReps, SecondsToMinutesAndSeconds, SecondsToTime } from '../../Functions';

class ExerciseTypeFour extends React.Component {
    TimeoutId = null;

    state = {
        BreakMinutes: '',
        BreakSeconds: '',
        MaxReps: '',
        MaxWeight: '',
        OriginalBreak: 0,
        OriginalReps: +this.props.Reps,
        OriginalWeight: +this.props.Weight,
        PopoverIsOpen_Reps: false,
        PopoverIsOpen_Weight: false,
        Reps: this.props.Reps,
        TotalBreak: '',
        Weight: this.props.Weight
    };

    componentDidMount() {
        var { BreakTime } = this.props;
        
        if (BreakTime !== '') {
            var { Minutes: BreakMinutes, Seconds: BreakSeconds } = SecondsToMinutesAndSeconds(BreakTime);

            BreakSeconds = +BreakSeconds === 0 ? '00' : +BreakSeconds < 10 ? `0${BreakSeconds}` : BreakSeconds;

            this.setState({ BreakMinutes, BreakSeconds, OriginalBreak: BreakTime, TotalBreak: BreakTime });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.SetType !== 'Test') {
            var { BreakTime: PrevBreakTime, Reps: PrevReps, Weight: PrevWeight } = prevProps;
            var { BreakTime: CurrBreakTime, Reps: CurrReps, Weight: CurrWeight } = this.props;

            PrevBreakTime = +PrevBreakTime;
            PrevReps = +PrevReps;
            PrevWeight = +PrevWeight;
            CurrBreakTime = +CurrBreakTime;
            CurrReps = +CurrReps;
            CurrWeight = +CurrWeight;

            if (PrevReps !== CurrReps) this.setState({ OriginalReps: CurrReps, Reps: CurrReps });
            if (PrevWeight !== CurrWeight) this.setState({ OriginalWeight: CurrWeight, Weight: CurrWeight });

            if (PrevBreakTime !== CurrBreakTime) {
                var { Minutes: BreakMinutes, Seconds: BreakSeconds } = SecondsToMinutesAndSeconds(CurrBreakTime);

                BreakSeconds = +BreakSeconds === 0 ? '00' : +BreakSeconds < 10 ? `0${BreakSeconds}` : BreakSeconds;

                this.setState({ BreakMinutes, BreakSeconds, OriginalBreak: CurrBreakTime, TotalBreak: CurrBreakTime });
            }
        }
    }

    onBlurBreakTime = () => {
        var TotalBreak = 0;
        TotalBreak += (this.state.BreakMinutes * 60) + +this.state.BreakSeconds;

        var { Minutes: BreakMinutes, Seconds: BreakSeconds } = SecondsToMinutesAndSeconds(TotalBreak);

        BreakSeconds = +BreakSeconds === 0 ? '00' : +BreakSeconds < 10 ? `0${BreakSeconds}` : BreakSeconds;
        BreakMinutes = +BreakMinutes === 0 ? 0 : BreakMinutes;

        if (+TotalBreak !== +this.state.OriginalBreak) this.setState({ BreakMinutes, BreakSeconds, TotalBreak }, () => this.onUpdateWorkoutSet());
        else this.setState({ BreakMinutes, BreakSeconds, TotalBreak });
    }

    onBlurMaxReps = () => {
        var { MaxReps } = this.state;

        if (+MaxReps === 0) this.setState({ MaxReps: 0 });
    }

    onBlurMaxWeight = () => {
        var { MaxWeight } = this.state;

        if (+MaxWeight === 0) this.setState({ MaxWeight: 0 });
    }
    
    onBlurReps = () => {
        var { OriginalReps, Reps } = this.state;

        if (+Reps === 0) this.setState({ Reps: 0 });

        if (+OriginalReps !== +Reps) this.onUpdateWorkoutSet();
    }

    onBlurWeight = () => {
        var { OriginalWeight, Weight } = this.state;

        if (+Weight === 0) this.setState({ Weight: 0 });

        if (+OriginalWeight !== +Weight) this.onUpdateWorkoutSet();
    }

    onChangeMaxReps = event => {
        this.setState({ MaxReps: event.target.value });
    }

    onChangeMaxWeight = event => {
        this.setState({ MaxWeight: event.target.value });
    }

    onChangeReps = event => {
        this.setState({ Reps: event.target.value });
    }

    onChangeTime = (event, TimeType, OtherTimeTypeValue) => {
        var TotalBreak = 0;
        if (TimeType === 'Minutes') TotalBreak += (event.target.value * 60) + OtherTimeTypeValue;
        else if (TimeType === 'Seconds') TotalBreak += event.target.value + (OtherTimeTypeValue * 60);

        this.setState({ [`Break${TimeType}`]: event.target.value, TotalBreak });
    }

    onChangeWeight = event => {
        this.setState({ Weight: event.target.value });
    }

    onRegenerateMax = () => {
        var { MaxReps, MaxWeight } = this.state;
        this.props.OnRegenerateMax({ MaxReps, MaxWeight });
    }

    onTogglePopover = PopoverType => {
        this.setState({ [PopoverType]: !this.state[PopoverType] }, () => {
            if (!this.state[PopoverType] && !!this.TimeoutId) clearTimeout(this.TimeoutId);
        });
    }

    onUpdateWorkoutSet = () => {
        var { Reps, TotalBreak, Weight } = this.state;

        this.setState({ OriginalBreak: TotalBreak, OriginalReps: Reps, OriginalWeight: Weight }, () => this.props.OnUpdateWorkoutSet({ Reps, TotalBreak, Weight }));
    }

    renderAddMax = () => {
        var { t } = this.props;
        var { Device, Header_Reps, Units } = this.props;
        var { MaxReps, MaxWeight } = this.state;

        var Disabled = !+MaxReps || !+MaxWeight;

        var Buttons = [
            { BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.props.OnUpdateTestSetScreen('Description'), Title: t('back_with_first_char_uppercase') },
            { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: this.onRegenerateMax, Title: t('regenerate') }
        ]

        return (
            <>
                <TestContainer className="TestContainer">
                    <TestInnerContainer className="TestInnerContainer" BreakEarly>
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('Users_max')}:`} TextAlign="left" />

                        <TestDataParentContainer className="TestDataParentContainer">
                            <TestDataContainer className="TestDataContainer">
                                <StyledInputLabelSideBySide
                                    InputFontFamily="semibold"
                                    InputFontSize="medium-2"
                                    LabelFontFamily="medium"
                                    LabelFontSize="medium-1"
                                    Label={Header_Reps.toLowerCase()}
                                    OnBlur={this.onBlurMaxReps}
                                    OnChange={this.onChangeMaxReps}
                                    Placeholder="0"
                                    Type="number"
                                    Value={MaxReps}
                                />
                            </TestDataContainer>
                        </TestDataParentContainer>

                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text="+" TextAlign="center" />

                        <TestDataParentContainer className="TestDataParentContainer">
                            <TestDataContainer className="TestDataContainer">
                                <StyledInputLabelSideBySide
                                    InputFontFamily="semibold"
                                    InputFontSize="medium-2"
                                    LabelFontFamily="medium"
                                    LabelFontSize="medium-1"
                                    Label={Units.toLowerCase()}
                                    OnBlur={this.onBlurMaxWeight}
                                    OnChange={this.onChangeMaxWeight}
                                    Placeholder="0"
                                    Type="number"
                                    Value={MaxWeight}
                                />
                            </TestDataContainer>
                        </TestDataParentContainer>
                    </TestInnerContainer>

                    {
                        (Device === 'laptop' || Device === 'ipad') &&
                        <StyledTestSetsButtonGroup
                            Buttons={Buttons}
                            ButtonWidth="fit-content"
                            NotTouching
                        />
                    }
                </TestContainer>

                {
                    (Device === 'mobile' || Device === 'mobile_small') &&
                    <>
                        <Spacer Size="extra-small" />

                        <ButtonGroup
                            Buttons={Buttons}
                            ButtonWidth="fit-content"
                            NotTouching
                        />
                    </>
                }
            </>
        );
    }

    renderPopoverContent = ContentType => {
        var { t } = this.props;
        var { Progress, SetType } = this.props;
        var { Reps, Weight } = this.state;

        if (!Reps) Reps = 1;
        if (!Weight) Weight = 1;

        return (
            <PopoverContent className="PopoverContent">
                {ContentType === 'Reps' && SetType === 'Real' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('dynamic_sets_estimate_to_be_around_X_weight_popover', { Weight: `${Math.round(CalculateDynamicWeightFromReps({ Reps, Progress, SetType }))}${Progress.Units}`, Reps: `${Reps}` })} TextAlign="center" />}
                {ContentType === 'Reps' && SetType === 'Template' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('dynamic_sets_estimate_to_be_around_X_percent_popover', { Percent: `${Math.round(CalculateDynamicWeightFromReps({ Reps, SetType }))}`, Reps: `${Reps}` })} TextAlign="center" />}
                {ContentType === 'Weight' && SetType === 'Real' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('dynamic_sets_estimate_to_be_around_X_reps_popover_real', { Reps: `${Math.round(CalculateDynamicRepsFromWeight({ Progress, SetType, Weight }))}`, Weight: `${Weight}${Progress.Units}` })} TextAlign="center" />}
                {ContentType === 'Weight' && SetType === 'Template' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('dynamic_sets_estimate_to_be_around_X_reps_popover', { Reps: `${Math.round(CalculateDynamicRepsFromWeight({ SetType, Weight }))}`, Weight: `${Weight}%` })} TextAlign="center" />}
            </PopoverContent>
        );
    }

    render() {
        var { t } = this.props;
        var { Device, InnerWidth, Completed, DataType, EditingSet, Header_Reps, MaxSet, MaxType, ReadOnly, SetType, Units } = this.props;
        var { BreakMinutes, BreakSeconds, Reps, TotalBreak, Weight } = this.state;

        if (SetType === 'Test') return this.renderAddMax();

        const popoverProps_Reps = {
            isOpen: this.state.PopoverIsOpen_Reps,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_Reps'),
            body: this.renderPopoverContent('Reps'),
            tipSize: 0.01
        }

        const popoverProps_Weight = {
            isOpen: this.state.PopoverIsOpen_Weight,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_Weight'),
            body: this.renderPopoverContent('Weight'),
            tipSize: 0.01
        }

        // Add % text if template and dynamic
            var ExerciseTypeColumnText = '';
            if (SetType === 'Template' && (MaxType === 'Custom' || DataType === 'dynamic-reps')) ExerciseTypeColumnText = t('percent_of_max', { MeasureType: t('Weight').toLowerCase() });
            else ExerciseTypeColumnText = t('Weight');

        return (
            <Container className="Container" Completed={Completed} MaxSet={MaxSet}>
                <DataParentContainer className="DataParentContainer" BorderColor={MaxSet && !Completed && 'var(--orange)'}>
                    {
                        InnerWidth > 900 && (SetType === 'Real' || (SetType === 'Template' && (!MaxSet || MaxType === 'Custom' || DataType === 'dynamic-weight'))) && !!+Weight &&
                        <Popover {...popoverProps_Reps}>
                            <MaxPercentInfoIcon ImageSrc={Info} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_Reps')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_Reps')} />
                        </Popover>
                    }

                    <DataContainer className="DataContainer" Completed={Completed} MaxSet={MaxSet}>
                        <StyledHeaders FontFamily="bold" FontSize="medium-1" NoMargin Text={t('Reps')} />

                        {
                            SetType === 'Template' && MaxType !== 'Custom' && DataType === 'dynamic-reps' && !!MaxSet ?
                            <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent={(Device === 'mobile' || Device === 'mobile_small') ? 'flex-end' : 'center'} NoMargin Text={t('dynamic_sets_we_calculate_reps')} TextAlign={(Device === 'mobile' || Device === 'mobile_small') ? 'right' : 'center'} />
                        :
                            // (ReadOnly || (Completed && ((!EditingSet && MaxSet) || !MaxSet))) ?
                            (ReadOnly || (Completed && !EditingSet)) ?
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'medium-3', Text: `${Reps}` },
                                    { FontFamily: 'medium', FontSize: 'medium-1', Text: `${Header_Reps.toLowerCase()}` }
                                ]}
                            />
                        :
                            <ExtraContainer className="ExtraContainer">
                                {
                                    InnerWidth <= 900 && (SetType === 'Real' || (SetType === 'Template' && (!MaxSet || MaxType === 'Custom' || DataType === 'dynamic-weight'))) && !!+Weight &&
                                    <Popover {...popoverProps_Reps}>
                                        <MaxPercentInfoIcon ImageSrc={Info} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_Reps')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_Reps')} />
                                    </Popover>
                                }

                                <StyledInputLabelSideBySide
                                    className="StyledInputLabelSideBySide"
                                    InputFontFamily="semibold"
                                    InputFontSize="medium-2"
                                    LabelFontFamily="medium"
                                    LabelFontSize="medium-1"
                                    Label={Header_Reps.toLowerCase()}
                                    OnBlur={this.onBlurReps}
                                    OnChange={this.onChangeReps}
                                    Placeholder="0"
                                    Type="number"
                                    Value={Reps}
                                />
                            </ExtraContainer>
                        }
                    </DataContainer>
                </DataParentContainer>

                <DataParentContainer className="DataParentContainer" BorderColor={MaxSet && !Completed && 'var(--orange)'}>
                    {
                        InnerWidth > 900 && (SetType === 'Real' || (SetType === 'Template' && (!MaxSet || MaxType === 'Custom' || DataType === 'dynamic-reps'))) && !!+Weight &&
                        <Popover {...popoverProps_Weight}>
                            <MaxPercentInfoIcon ImageSrc={Info} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_Weight')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_Weight')} />
                        </Popover>
                    }

                    <DataContainer className="DataContainer" Completed={Completed} MaxSet={MaxSet}>
                        <StyledHeaders FontFamily="bold" FontSize="medium-1" NoMargin Text={ExerciseTypeColumnText} />

                        {
                            SetType === 'Template' && MaxType !== 'Custom' && DataType === 'dynamic-weight' && !!MaxSet ?
                            <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent={(Device === 'mobile' || Device === 'mobile_small') ? 'flex-end' : 'center'} NoMargin Text={t('dynamic_sets_estimate_to_be_around_X_percent', { Percent: Reps ? `${Math.round(CalculateDynamicWeightFromReps({ Reps, SetType }))}` : 'X'})} TextAlign={(Device === 'mobile' || Device === 'mobile_small') ? 'right' : 'center'} />
                        :
                            // (ReadOnly || (Completed && ((!EditingSet && MaxSet) || !MaxSet))) ?
                            (ReadOnly || (Completed && !EditingSet)) ?
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'medium-3', Text: `${Weight}` },
                                    { FontFamily: 'medium', FontSize: 'medium-1', Text: `${Units.toLowerCase()}` }
                                ]}
                            />
                        :
                            <ExtraContainer className="ExtraContainer">
                                {
                                    InnerWidth <= 900 && (SetType === 'Real' || (SetType === 'Template' && (!MaxSet || MaxType === 'Custom' || DataType === 'dynamic-reps'))) && !!+Weight &&
                                    <Popover {...popoverProps_Weight}>
                                        <MaxPercentInfoIcon ImageSrc={Info} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_Weight')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_Weight')} />
                                    </Popover>
                                }

                                <StyledInputLabelSideBySide
                                    InputFontFamily="semibold"
                                    InputFontSize="medium-2"
                                    LabelFontFamily="medium"
                                    LabelFontSize="medium-1"
                                    Label={Units.toLowerCase()}
                                    OnBlur={this.onBlurWeight}
                                    OnChange={this.onChangeWeight}
                                    Placeholder="0"
                                    Type="number"
                                    Value={Weight}
                                />
                            </ExtraContainer>
                        }
                    </DataContainer>
                </DataParentContainer>

                <DataParentContainer className="DataParentContainer">
                    <DataContainer className="DataContainer" Completed={Completed} MaxSet={MaxSet}>
                        <StyledHeaders FontFamily="bold" FontSize="medium-1" NoMargin Text={t('break')} />

                        {
                            (ReadOnly || Completed) ?
                            <PageText FontFamily="semibold" FontSize="medium-3" NoMargin Text={SecondsToTime(TotalBreak)} />
                        :
                            <StyledInputLabelSideBySide
                                InputFontFamily="semibold"
                                InputFontSize="medium-2"
                                LabelFontFamily="medium"
                                LabelFontSize="medium-1"
                                Label={`${t('min_str').toLowerCase()} / ${t('Seconds_short').toLowerCase()}`}
                                OnBlur={this.onBlurBreakTime}
                                OnChange={(event, TimeType, OtherTimeTypeValue) => this.onChangeTime(event, TimeType, OtherTimeTypeValue)}
                                Placeholder="0"
                                TimeValueMinutes={BreakMinutes}
                                TimeValueSeconds={BreakSeconds}
                                Type="time"
                            />
                        }
                    </DataContainer>
                </DataParentContainer>
            </Container>
        )
    }
}

ExerciseTypeFour.propTypes = {
    BreakTime: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    Completed: PropTypes.bool,
    DataType: PropTypes.string,
    EditingSet: PropTypes.bool,
    Header_Reps: PropTypes.string,
    MaxSet: PropTypes.bool,
    MaxType: PropTypes.string,
    OnRegenerateMax: PropTypes.func,
    OnUpdateTestSetScreen: PropTypes.func,
    OnUpdateWorkoutSet: PropTypes.func,
    ReadOnly: PropTypes.bool,
    Reps: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    SetType: PropTypes.string.isRequired,
    Units: PropTypes.string,
    Weight: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        InnerWidth: state.Window.InnerWidth
    };
};

export default withTranslation()(connect(mapStateToProps, { } )(ExerciseTypeFour));