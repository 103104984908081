import React from 'react';

import { BottomBarContainer, BottomBarContent } from './styles';

class BottomBar extends React.Component {
    // componentDidUpdate(prevProps) {
    //     if (prevProps.children !== this.props.children) {
    //         console.log('Children in child list not equal');
    //         if (this.props.GetSize) {
    //             this.props.GetSize(this.elementRef.getBoundingClientRect().height);
    //         }
    //     }
    // }

    refCallback = element => {
        if (this.props.GetSize && element) {
            this.elementRef = element;
            this.props.GetSize(element.getBoundingClientRect().height);
        }
    }

    render() {
        return (
            <BottomBarContainer className={this.props.className} ref={this.refCallback}>
                <BottomBarContent className="BottomBarContent">
                    {this.props.children}
                </BottomBarContent>
            </BottomBarContainer>
        );
    }
}

export default BottomBar;