import styled from 'styled-components';
import { device } from '../../Config/device';

import { Link } from 'react-router-dom';

import Icon from '../../Components/Icon';
import PageText from '../../Components/Text/PageText';

export const GiveAccessButton = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 100px;
    padding: 3px;

    @media(hover:hover) {
        :hover {
            background-color: var(--abel-blue);
            color: var(--white);
            cursor: pointer;
        }
    }
`;

export const Container = styled.div`
    align-items: center;
    background-color: var(--chat-gray);
    display: grid;
    grid-template-columns: ${props => props.IsPreRegistered ? '4fr 6.5fr 1.5fr 4.25fr 2fr 0.25fr' : '4fr 1fr 3.5fr 2fr 1.5fr 4.25fr 2fr 0.25fr'};
    padding: 10px;

    @media (max-width: 1350px) {
        grid-template-columns: ${props => props.IsPreRegistered ? '4fr 4.5fr 4.25fr 2fr 0.25fr' : '4fr 1fr 2fr 1.5fr 4.25fr 2fr 0.25fr'};
    }

    @media ${device.maxwidth.ipad} {
        grid-template-columns: ${props => props.IsPreRegistered ? '4fr 6.75fr 2fr 0.25fr' : '4fr 1fr 1.5fr 4.25fr 2fr 0.25fr'};
    }

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete);
        }

        &:hover ${GiveAccessButton} {
            border-color: var(--black);
        }
    }
`;

export const Container1 = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: hidden;
`;

export const Container2 = styled.div`
    overflow: hidden;
`;

export const Container2Half = styled.div`
    padding: 0 5px;
`;

export const Container3 = styled.div`
    overflow: hidden;

    @media (max-width: 1350px) {
        display: none;
    }
`;

export const Container4 = styled.div`
    overflow: hidden;
`;

export const Container5 = styled.div`
    overflow: hidden;

    @media ${device.maxwidth.ipad} {
        display: none;
    }
`;

export const Container6 = styled.div`
    // display: grid;
    // grid-gap: 5px;
    // grid-template-columns: repeat(26, 1fr);
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: -3px;
    margin-top: -3px;
    padding-right: 10px;
    width: 322px;
`;

export const Container6HalfA = styled.div`
    padding-right: 10px;
    width: 322px;

    ${props => props.RequestTypeId === 0 && '> div { justify-content: flex-start; }'}
`;

export const Container6HalfB = styled.div`
    padding-right: 10px;
    width: 118px;
`;

export const Container7 = styled.div`
    // display: grid;
    // grid-gap: 5px;
    // grid-template-columns: repeat(9, 1fr);
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: -3px;
    margin-top: -3px;
    padding-right: 10px;
    width: 118px;
`;

export const Container8 = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const CopiedPageText = styled(PageText)`
    margin-left: 5px;
`;

export const GiveAccessIcon = styled(Icon)`
    margin-right: 3px;

    > img {
        height: 20px;
    }
`;

export const HeaderPictureContainer = styled(Link)``;

export const PopoverContainer = styled.div`
    align-items: center;
    background-color: var(--white);
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    width: 300px;
`;

export const PopoverHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const PopoverHeaderEmailContainer = styled.div`
    flex: 1;
    overflow: hidden;
`;

export const ProfilePhotoContainer = styled.div`
    margin-right: 5px;
    width: var(--profilephoto-size-small-1);
`;

export const StyledDisplayNameLink = styled(Link)`
    color: inherit;
`;

export const StyledEllipsisText = styled(PageText)`
    overflow: hidden;

    > div {    
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const StyledThreeDotsIcon = styled(Icon)`
    > img {
        height: var(--profilephoto-size-small-1);
    }
`;