import React from 'react';
import PropTypes from 'prop-types'

import { StyledInputRange } from './styles';

import PageText from '../../Text/PageText';
import Spacer from '../../Spacer';


class InputRange extends React.Component {
    state = { Value: this.props.Value };

    componentDidMount(){
        window.addEventListener('touchstart', this.touchStart);
        window.addEventListener('touchmove', this.preventTouch, {passive: false});
    }
    
    componentWillUnmount(){
        window.removeEventListener('touchstart', this.touchStart);
        window.removeEventListener('touchmove', this.preventTouch, {passive: false});
    }
    
    touchStart(e){
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }
    
    preventTouch(e){
        const minValue = 5; // threshold
    
        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;
    
        // Vertical scrolling does not work when you start swiping horizontally.
        if(Math.abs(this.clientX) > minValue){ 
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
    }

    onChange = NewValue => {
        this.setState({ Value: NewValue });
    }

    render() {
        var { MaxValue, MinValue, OnChangeComplete, RenderText, Step } = this.props;
        var { Value } = this.state;

        var Text = RenderText ? RenderText(Value) : `${Value}`;

        return (
            <>
                <StyledInputRange
                    formatLabel={() => null}
                    step={Step}
                    maxValue={MaxValue}
                    minValue={MinValue}
                    value={Value}
                    onChange={NewValue => this.onChange(NewValue)}
                    onChangeComplete={FinalValue => OnChangeComplete(FinalValue)}
                />

                <Spacer Size="extra-small" />

                <PageText FontColor="blue-abel" FontFamily="semibold" FontSize="small" NoMargin Text={Text} TextAlign="left" />
            </>
        );
    }
}

InputRange.propTypes = {
    MaxValue: PropTypes.number.isRequired,
    MinValue: PropTypes.number.isRequired,
    OnChangeComplete: PropTypes.func.isRequired,
    RenderText: PropTypes.func,
    Step: PropTypes.number,
    Value: PropTypes.number.isRequired
}

InputRange.defaultProps = {
    Step: 5
}

export default InputRange;