import styled from 'styled-components';

import BodyMuscles from '../../Components/BodyMuscles';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Checkmark from '../../Components/Form/Checkmark';
import MultiText from '../../Components/Text/MultiText';

import NoImage from '../../Assets/Icons/NoImage.png';

export const CheckmarkContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const ExerciseImageInputContainer = styled.div`
    align-items: center;
    background-image: url('${props => props.BackgroundImage}'), url('${NoImage}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    height: 170px;
    justify-content: center;
    max-width: 100%;
    width: 300px;
`;

export const ExerciseMediaContainer = styled.div``;

export const ExerciseMediaParentContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`;

export const JointEquipmentContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(${props => props.Type === 'Joints' ? '120px' : '200px'}, 1fr));
`;

export const JointEquipmentItem = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    > div:first-child {
        margin-right: 7px;
    }
`;

export const MuscleListColumn = styled.div`
    align-items: ${props => !!props.Left ? 'start': 'center'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

export const MuscleListRow = styled.div`
    // align-items: center;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;

    display: grid;
    grid-template-columns: ${props => !!props.Names ? 'repeat(4, 1fr)' : '1fr 1fr 1fr 1.5fr'};
    width: 100%;
`;

export const MusclesContainer = styled.div`
    align-items: start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media (max-width: 1350px) {
        align-items: center;
        flex-direction: column;
    }
`;

export const MusclesListContainer = styled.div`
    align-items: start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media (min-width: 801px) {
        > div:first-child {
            margin-right: 10px;
        }
    }

    @media (max-width: 800px) {
        align-items: center;
        flex-direction: column;

        > div:first-child {
            margin-bottom: 10px;
        }
    }
`;

export const MusclesListContainerSection = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    min-width: 300px;

    > div:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const PseudoCheckbox = styled.div`
    ${
        props => props.Selected && `background-color: #${props.BackgroundColor};`
    }

    border: 2px solid var(--white-concrete);
    border-radius: 5px;
    height: 25px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            ${props => `background-color: #${props.BackgroundColor};`}
            cursor: pointer;
        }
    }
`;

export const StyledBodyMuscles = styled(BodyMuscles)`
    max-width: 100%;
    min-width: 350px;
    width: 350px;
`;

export const StyledButtonGroupExerciseTypes = styled(ButtonGroup)`
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: -15px;
    max-width: 100%;

    > div {
        margin-top: 15px;
    }
`;

export const StyledCheckmark = styled(Checkmark)`
    margin-right: 5px;
`;

export const StyledExerciseVideo = styled.video`
    border-radius: 10px;
    display: flex;
    height: 170px;
    max-width: 100%;
    width: 300px;
`;

export const StyledMediaButtonGroup = styled(ButtonGroup)`
    justify-content: flex-start;
`;

export const StyledMultiText = styled(MultiText)`
    align-items: center;
    justify-content: center;
`;