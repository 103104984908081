import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import * as styles from './styles';

import AbelModal from '../../Components/Modal';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

const { useState } = React;

function EditShareAbilityStatusModal(props) {
    const { t } = useTranslation();

    const [selectedShareAbilityStatus, setSelectedShareAbilityStatus] = useState(+props.CurrentStatus);
    
    const onUpdateShareAbilityStatus = ({ NewStatus, NewStatusName }) => {
        if (NewStatus === selectedShareAbilityStatus) props.OnHide();
        else {
            setSelectedShareAbilityStatus(+NewStatus);
            props.OnUpdateShareAbilityStatus({ ContentId: props.ContentId, ContentIndex: props.ContentIndex, NewStatus, NewStatusName }).then(props.OnHide);
        }
    }

    const renderModalBody = () => {
        return (
            <>
                {props.Loading && <Loading />}

                <styles.Container>
                    <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('sharing_details_edit_shareability')} TextAlign="center" />

                    <Spacer Size="small" />

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('sharing_details_what_is_shareability_info')} TextAlign="center" />

                    <Spacer Size="medium" />

                    <styles.ButtonContainer className="ButtonContainer" onClick={() => onUpdateShareAbilityStatus({ NewStatus: 1, NewStatusName: t('sharing_details_shareability_status_option_always') })} Selected={selectedShareAbilityStatus === 1}>
                        <MultiText
                            Texts={[
                                { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${t('sharing_details_shareability_status_option_always')}: `},
                                { FontFamily: 'medium', FontSize: 'medium-1', Text: t('sharing_details_shareability_status_option_always_info') }
                            ]}
                        />
                    </styles.ButtonContainer>

                    <Spacer Size="small" />

                    <styles.ButtonContainer className="ButtonContainer" onClick={() => onUpdateShareAbilityStatus({ NewStatus: 3, NewStatusName: t('sharing_details_shareability_status_option_never') })} Selected={selectedShareAbilityStatus === 3}>
                        <MultiText
                            Texts={[
                                { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${t('sharing_details_shareability_status_option_never')}: `},
                                { FontFamily: 'medium', FontSize: 'medium-1', Text: t('sharing_details_shareability_status_option_never_info') }
                            ]}
                        />
                    </styles.ButtonContainer>

                    {/* <Spacer Size="small" />

                    <styles.ButtonContainer className="ButtonContainer" onClick={() => onUpdateShareAbilityStatus({ NewStatus: 2, NewStatusName: t('sharing_details_shareability_status_option_ask') })} Selected={selectedShareAbilityStatus === 2}>
                        <MultiText
                            Texts={[
                                { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${t('sharing_details_shareability_status_option_ask')}: `},
                                { FontFamily: 'medium', FontSize: 'medium-1', Text: t('sharing_details_shareability_status_option_ask_info') }
                            ]}
                        />
                    </styles.ButtonContainer> */}
                </styles.Container>
            </>
        );
    }

    return (
        <AbelModal
            Show={props.Show}
            Size="medium"
            OnHide={props.OnHide}
            TopButton="cancel"
            TopButtonOnClick={props.OnHide}
        >
            {renderModalBody()}
        </AbelModal>
    );
}

EditShareAbilityStatusModal.propTypes = {
    ContentId: PropTypes.number,
    ContentIndex: PropTypes.number,
    CurrentStatus: PropTypes.number,
    Loading: PropTypes.bool.isRequired,
    OnHide: PropTypes.func.isRequired,
    OnUpdateShareAbilityStatus: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired
}

export default EditShareAbilityStatusModal;