import React from "react";
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Icon from '../../Icon';
import LineBar from '../../Charts/ProgressBars/Line';
import MultiText from '../../Text/MultiText';
import PageText from '../../Text/PageText';
import Spacer from '../../Spacer';

import DownArrow from '../../../Assets/Icons/DownArrow.png';
import Pencil from '../../../Assets/Icons/Pencil_Gray.png';
import UpArrow from '../../../Assets/Icons/UpArrow.png';

const { useState } = React;

const CaloriesContainer = styled.div`
    width: 100%;
`;

const CaloriesHeader = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const Container = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    padding: 10px;

    ${props => !props.IsCurrentMonth && 'opacity: var(--opacity-low);'}
`;

const EditIconContainer = styled.div`
    align-items: center;
    background-color: var(--white);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-contents: center;
    padding: 3px;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete-hover);
            cursor: pointer;
        }
    }
`;

const FooterContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const HeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div:first-child {
        flex: 1;
    }
`;

const NutrientRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const StyledEditIcon = styled(Icon)`
    > img {
        height: 15px;
    }
`;

const StyledHR = styled.div`
    background-color: var(--white);
    border-radius: 10px;
    height: 2px;
    width: 100%;
`;

const StyledIcon = styled(Icon)`
    margin-left: 5px;

    > img {
        height: 10px;

        :hover {
            cursor: pointer;
        }
    }
`;

const StyledLineBar = styled(LineBar)`
    border: none;
    padding: 0px;
`;

function NutritionPreview(props) {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const onToggle = () => {
        setIsOpen(!isOpen);
    }

    const CalorieCompletionStatus = props.NutritionData.Calories.Status;

    var FontColor = 'blue-abel';
    if (CalorieCompletionStatus === 'ok') FontColor = 'green-success';
    else if (CalorieCompletionStatus === 'high') FontColor = 'red-bittersweet';

    return (
        <Container className="Container" IsCurrentMonth={props.IsCurrentMonth}>
            <HeaderContainer className="HeaderContainer">
                <MultiText
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'extra-small', Text: `${t('calories_added')} - ` },
                        { FontColor, FontFamily: 'bold', FontSize: 'extra-small', Text: props.NutritionData.Calories.Value }
                    ]}
                />

                <StyledIcon ImageSrc={isOpen ? DownArrow : UpArrow} OnClick={onToggle} />
            </HeaderContainer>

            {
                isOpen &&
                <>
                    {
                        (Object.entries(props.NutritionData.lstChart).length !== 0) &&
                        <>
                            <Spacer Size="smallest" />

                            {
                                props.NutritionData.lstChart.map(({ BackgroundHexColor, NutritionFactsCategoryId, NutrientName, Percentage, Units, Value }) => {
                                    return (
                                        <MultiText key={NutritionFactsCategoryId}
                                            ContainerWidth="100%"
                                            TextAlign="left"
                                            Texts={[
                                                { FontColorHex: `#${BackgroundHexColor}`, FontFamily: 'bold', FontSize: 'extra-small', Text: NutrientName },
                                                { FontColorHex: `#${BackgroundHexColor}`, FontFamily: 'medium', FontSize: 'extra-small', Text: `${Math.round(Percentage)}% (${Math.round(+Value)} ${Units})` }
                                            ]}
                                        />
                                    );
                                })
                            }
                        </>
                    }

                    <Spacer Size="smallest" />

                    <StyledHR />

                    <Spacer Size="smallest" />

                    <CaloriesContainer className="CaloriesContainer">
                        <CaloriesHeader className="CaloriesHeader">
                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin Text={`${props.NutritionData.Calories.Value} ${props.NutritionData.Calories.Units}`} TextAlign="left" />
                            <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-end" NoMargin Text={`${t('Goal')} - ${props.NutritionData.Calories.Goal} ${props.NutritionData.Calories.Units}`} TextAlign="right" />
                        </CaloriesHeader>

                        <Spacer Size="extra-extra-small" />

                        <StyledLineBar
                            CompletionStatus={CalorieCompletionStatus}
                            CurrentValue={+props.NutritionData.Calories.Value}
                            GoalMax={+props.NutritionData.Calories.MaxIndicator}
                            GoalMin={0}
                            HideCurrent
                            HideGoalText
                            NoHeight
                            NoMargin
                            ProgressLine={{ FillPercentage: +props.NutritionData.Calories.FillPercentage, GoalMaxPercentage: +props.NutritionData.Calories.MaxIndicator, GoalMinPercentage: null }}
                            Units={props.NutritionData.Calories.Units}
                        />
                    </CaloriesContainer>

                    <Spacer Size="smallest" />

                    <StyledHR />

                    {
                        props.NutritionData.nutrients.map(({ NutrientName, Units, Value }, NutrientIndex) => {
                            return (
                                <React.Fragment key={NutrientIndex}>
                                    <Spacer Size="smallest" />

                                    <NutrientRow className="NutrientRow">
                                        <PageText FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-start" NoMargin Text={NutrientName} TextAlign="left" />

                                        <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-end" NoMargin Text={`${Math.round(+Value)} ${Units}`} TextAlign="right" />
                                    </NutrientRow>

                                    <Spacer Size="smallest" />

                                    <StyledHR />
                                </React.Fragment>
                            );
                        })
                    }

                    <Spacer Size="smallest" />

                    <FooterContainer className="FooterContainer">
                        <EditIconContainer className="EditIconContainer" onClick={() => props.OnToggleShowNutritionPreviewFiltersModal(true)}>
                            <StyledEditIcon ImageSrc={Pencil} />
                        </EditIconContainer>

                        <PageText FontColorHover="black-hover" FontFamily="semibold" FontSize="extra-small" JustifyContent="flex-end" NoMargin OnClick={() => props.OnToggleShowNutritionFactsModal(true)} Text={t('view_all_nutrients')} TextAlign="right" />
                    </FooterContainer>
                </>
            }
        </Container>
    );
}

NutritionPreview.propTypes = {
    Calories: PropTypes.string.isRequired,
    IsCurrentMonth: PropTypes.bool.isRequired,
    NutritionData: PropTypes.object.isRequired,
    OnToggleShowNutritionFactsModal: PropTypes.func.isRequired,
    OnToggleShowNutritionPreviewFiltersModal: PropTypes.func.isRequired
}

export default NutritionPreview;