import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddSingleGeneratedAbelWorkout, ClearAdditionalActivityDetails, ClearSaveData, ClearSingleGeneratedWorkoutDetails, GetAdditionalActivityDetails, GetSingleGeneratedWorkoutDetails, UpdateSelectedTab, UploadToPresignedUrl } from '../../Services/Actions';
import moment from 'moment';
import queryString from 'query-string';
import history from '../../history';

import TimePicker from 'rc-time-picker';

import { DurationContainer, DurationsContainer, EditIcon, GoalContainer, GoalImage, GoalsContainer, GymContainer, GymNameText, InjuriesDropdownContainer, MainGym, PublicGymItem, PublicGymsList, SavedGymsContainer, SelectedDateContainer, SelectedDatesContainer, StyledBodyMuscles, StyledDropdown, StyledDuration, StyledNumberInput, TrainerNoteContainer, TrainerNoteTextArea } from './styles';
import { DateTimeContainer, DateTimesContainer, DateTimeUseAllDays, DateTimeUseAllDaysContainer, HourType, HourTypesContainer } from './styles';
import { ActivityPictureContainer, ActivityPicturesContainer, AdditionalActivityItem, AdditionalActivityList, StyledActivityImage, StyledInputLabelMultiText, UploadButtonsContainer, UploadButtonsRow, UploadContainer } from './styles';

import AssignClients from '../../Components/AssignClients';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import FlexWrap from '../../Components/Lists/FlexWrap';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import UploadProgressComponent from '../../Components/UploadProgress';

import ChooseDates from '../../Modals/ChooseDates';

import Camera from '../../Assets/Icons/Camera.png';
import Edit_Dark from '../../Assets/Icons/Edit_Dark.png';
import Plus from '../../Assets/Icons/Plus.png';

import GainMuscleMass from '../../Assets/Illustrations/GainMuscleMass.png';
import IncreaseStrength from '../../Assets/Illustrations/IncreaseStrength.png';
import LoseWeight from '../../Assets/Illustrations/LoseWeight.png';

import { CreateUploadToPresignedUrlPromise, DateSort, getFileExtension, validateFile } from '../../Functions';

const WorkoutTimeOptions = [ 30, 45, 60, 75, 90 ];

class AddSingleGeneratedWorkout extends React.Component {
    state = {
        ActivityId: null,
        ActivityName: '',
        ActivityPicture: null,
        ActivityPictureId: null,
        ActivityPictures: [],
        CardioLevel: null,
        CardioLevelIndex: null,
        CustomName: '',
        DisplayingFilter: null,
        DropdownSize: 0,
        Duration: null,
        EnduranceTemplateId: null,
        EnduranceTemplateName: '',
        GoalId: null,
        GymId: null,
        GymName: '',
        HourType: 24,
        ImageTooLarge: false,
        Injuries: {
            Joints: [],
            Muscles: []
        },
        Loading: true,
        Location: '',
        MaxImageSize: 10000000,
        SelectedDates_Existing: [],
        SelectedDates: [],
        SelectedUserIds: [],
        SelectedUsers: [],
        SelectedImage: null,
        ShowCustomName: null,
        ShowDateTimes: null,
        ShowLocation: null,
        SingleGeneratedDetails: {},
        SyncAllDateTimes: false,
        TargetMuscleBodyAreas: [],
        TargetMuscles: [],
        TargetMusclesFullBody: [],
        TrainerNote: '',
        WorkoutImage: null,
        WorkoutType: null,
        WrongImageType: false
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientDetails, DayDate } = this.props;

        const QueryValues = queryString.parse(this.props.location.search);
        var WorkoutType = null;
        if (Object.entries(QueryValues).length > 0 && QueryValues.type) {
            if (QueryValues.type.toLowerCase() === 'endurance') WorkoutType = 'Endurance'
            else if (QueryValues.type.toLowerCase() === 'strength') WorkoutType = 'Strength';
        }

        if (Object.entries(ClientDetails).length !== 0) {
            var SelectedDates = DayDate ? [ DayDate ] : [];
            var SelectedUsers = [];
            var SelectedUserIds = [];

            var ClientId = +ClientDetails.ClientId;

            SelectedUserIds = [ ClientId ];
            SelectedUsers = [{ ...ClientDetails, UserId: ClientId }];

            this.props.GetSingleGeneratedWorkoutDetails({ ClientId }).then(() => {
                this.setState({
                    GymId: this.props.SingleGeneratedDetails.DefaultGym[0].GymId,
                    GymName: this.props.SingleGeneratedDetails.DefaultGym[0].GymName,
                    Injuries: {
                        Joints: this.props.SingleGeneratedDetails.Injuries.Joints.filter(({ InjuredJoint }) => InjuredJoint).map(InjuredJoints => InjuredJoints.JointId),
                        Muscles: this.props.SingleGeneratedDetails.Injuries.Muscles.filter(({ InjuredMuscle }) => InjuredMuscle).map(InjuredMuscles => InjuredMuscles.MuscleGroupId)
                    },
                    Loading: false,
                    SelectedDates,
                    SelectedUserIds,
                    SelectedUsers,
                    SingleGeneratedDetails: this.props.SingleGeneratedDetails,
                    WorkoutType
                });
            });
        } else this.setState({ Loading: false, WorkoutType });
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearAdditionalActivityDetails();
        this.props.ClearSingleGeneratedWorkoutDetails();
        this.props.ClearSaveData();
    }

    onAddSingleGeneratedWorkouts = () => {
        var { ActivityId, ActivityPictureId, CustomName, Duration, EnduranceTemplateId, GoalId, GymId, Injuries: { Joints, Muscles }, Location, SelectedDates, SelectedImage, SelectedUserIds, ShowCustomName, ShowDateTimes, ShowLocation, SingleGeneratedDetails: { ExperienceLevel_Endurance, ExperienceLevel_Strength }, TargetMuscles, TrainerNote, WorkoutType } = this.state;

        var AssignedDates = SelectedDates.join(',');
        var ClientId = SelectedUserIds[0];
        var JointPains = Joints.join(',');
        var MusclePains = Muscles.join(',');
        TargetMuscles = TargetMuscles.join(',');

        if (!ShowCustomName) CustomName = '';
        if (!ShowLocation) Location = '';
        
        var HasDateTimes = +ShowDateTimes;

        var CustomImage = 0;

        var WorkoutImage = null;
        if (ActivityPictureId === 'custom') {
            CustomImage = 1;
            if (SelectedImage) WorkoutImage = { ContentType: SelectedImage.type, FileName: SelectedImage.name, FileSize: SelectedImage.size };
        }

        this.props.AddSingleGeneratedAbelWorkout({ ActivityId, AssignedDates, ClientId, CustomImage, CustomName, Duration, EnduranceTemplateId, ExperienceLevel_Endurance, ExperienceLevel_Strength, GoalId, GymId, HasDateTimes, JointPains, Location, MusclePains, TargetMuscles, TrainerNote, WorkoutImage, WorkoutType }).then(({ PreSignedUrls }) => {
            if (this._isMounted && !this.props.TryingAddSingleGeneratedWorkoutError) {
                if (WorkoutImage && PreSignedUrls.length > 0) {
                    var UploadFunction = this.props.UploadToPresignedUrl;
                    var Promises = PreSignedUrls.map((PreSignedUrl, index) => CreateUploadToPresignedUrlPromise({ ContentType: 'SingleGenerated', CustomLabelName: `${SelectedImage.name} - ${index + 1}`, File: SelectedImage, PresignedFormData: PreSignedUrl, UploadFunction }));
    
                    Promise.all(Promises).then(() => history.push({ pathname: `/client/${ClientId}`, search: '?tab=workouts' }));   
                }
                else history.push({ pathname: `/client/${ClientId}`, search: '?tab=workouts' });
            }
        });
    }

    onChangeCustomName = event => {
        this.setState({ CustomName: event.target.value });
    }

    onChangeDateTime = (DateTime, SelectedDateIndex) => {
        var NewDateTime = moment(DateTime).format('YYYY-MM-DD HH:mm');

        var { SelectedDates_Existing, SelectedDates, SyncAllDateTimes } = this.state;

        if (!SyncAllDateTimes) {
            SelectedDates_Existing[SelectedDateIndex] = NewDateTime;
            SelectedDates[SelectedDateIndex] = NewDateTime;

            this.setState({ SelectedDates_Existing, SelectedDates });
        } else {
            var NewSelectedDates_Existing = [];
            var NewSelectedDates = [];

            var FirstTime = moment(NewDateTime).format('HH:mm');
            var NewDate;

            for (var i = 0; i < SelectedDates.length; i++) {
                NewDate = moment(moment(SelectedDates[i]).format('YYYY-MM-DD') + ' ' + FirstTime).format('YYYY-MM-DD HH:mm');
                NewSelectedDates_Existing.push(NewDate);
                NewSelectedDates.push(NewDate);
            }

            this.setState({ SelectedDates_Existing: NewSelectedDates_Existing, SelectedDates: NewSelectedDates });
        }
    }

    onChangeExperienceLevel = (ExperienceLevelType, Direction) => {
        this.setState({
            SingleGeneratedDetails: {
                ...this.state.SingleGeneratedDetails,
                [`ExperienceLevel_${ExperienceLevelType}`]: this.state.SingleGeneratedDetails[`ExperienceLevel_${ExperienceLevelType}`] + Direction
            }
        });
    }

    onChangeHourType = HourType => {
        this.setState({ HourType });
    }

    onChangeLocation = event => {
        this.setState({ Location: event.target.value });
    }

    onChangeTrainerNote = event => {
        this.setState({ TrainerNote: event.target.value });
    }

    onChangeWorkoutType = WorkoutType => {
        this.setState({ WorkoutType });
    }

    onGetSize = Height => {
        this.setState({ DropdownSize: Height });
    }

    onSelectActivityPicture = ({ ActivityPicture, ActivityPictureId }) => {
        this.setState({ ActivityPicture, ActivityPictureId });
    }

    onSelectAdditionalActivity = ({ ActivityId, ActivityName, ActivityPictures }) => {
        var ActivityPicture = this.state.ActivityPicture;
        var ActivityPictureId = this.state.ActivityPictureId;

        if (this.state.ActivityPictureId !== 'custom') {
            ActivityPicture = ActivityPictures[0];
            ActivityPictureId = ActivityId;
        }

        this.setState({ ActivityId, ActivityName, ActivityPicture, ActivityPictureId, ActivityPictures, DisplayingFilter: null });
    }

    onSelectBodyArea = ({ BodyAreaMuscleIds }, BodyAreaIndex) => {
        var NewTargetMuscles = [];

        // Build NewTargetMuscles
            if (BodyAreaIndex === -1) NewTargetMuscles = [ ...new Set([ ...this.state.TargetMuscles, ...BodyAreaMuscleIds.split(', ').map(Number) ].map(x => x)) ]
            else NewTargetMuscles = [ ...this.state.TargetMuscles.filter(TargetMuscle => ![ ...BodyAreaMuscleIds.split(', ').map(Number) ].includes(TargetMuscle) ) ];

        // Build NewTargetMuscleBodyAreas if all body area muscles are in NewTargetMuscles
            var NewTargetMuscleBodyAreas = this.state.SingleGeneratedDetails.TargetMuscles.BodyAreas.map(({ BodyAreaId, BodyAreaMuscleIds }) => {
                if ([ ...BodyAreaMuscleIds.split(', ').map(Number) ].every(val => NewTargetMuscles.includes(val))) return BodyAreaId;
                
                return null;
            });

        // Determine Full Body
            var NewTargetMusclesFullBody = NewTargetMuscles.length === 13 ? [ 1 ] : [];

        this.setState({ TargetMuscles: NewTargetMuscles, TargetMuscleBodyAreas: NewTargetMuscleBodyAreas, TargetMusclesFullBody: NewTargetMusclesFullBody });
    }

    onSelectCardioLevel = (CardioLevel, CardioLevelIndex) => {
        if (this.state.CardioLevelIndex !== CardioLevelIndex) this.setState({ CardioLevel, CardioLevelIndex, EnduranceTemplateId: null, EnduranceTemplateName: '' });
    }

    onSelectClients = ({ SelectedClients: SelectedUsers }) => {
        if (SelectedUsers.length) {
            var { SelectedUserIds, WorkoutType } = this.state;

            if (SelectedUserIds[0] !== +SelectedUsers[0].UserId) {
                var SelectedUserIds_New = SelectedUsers.map(User => User.UserId);

                this.setState({ SelectedUsers, SelectedUserIds: SelectedUserIds_New, WorkoutType: null },
                    () => this.props.GetSingleGeneratedWorkoutDetails({ ClientId: SelectedUsers[0].UserId }).then(
                        () => this.setState({
                            GymId: this.props.SingleGeneratedDetails.DefaultGym[0].GymId,
                            GymName: this.props.SingleGeneratedDetails.DefaultGym[0].GymName,
                            Injuries: {
                                Joints: this.props.SingleGeneratedDetails.Injuries.Joints.filter(({ InjuredJoint }) => InjuredJoint).map(InjuredJoints => InjuredJoints.JointId),
                                Muscles: this.props.SingleGeneratedDetails.Injuries.Muscles.filter(({ InjuredMuscle }) => InjuredMuscle).map(InjuredMuscles => InjuredMuscles.MuscleGroupId)
                            },
                            SingleGeneratedDetails: this.props.SingleGeneratedDetails,
                            WorkoutType
                        })
                    )
                );
            }
        } else this.setState({ SelectedUsers: [], SelectedUserIds: [], SingleGeneratedDetails: {} });
    }

    onSelectDates = SelectedDates => {
        SelectedDates.sort(DateSort);

        this.setState({ SelectedDates, ShowChooseDatesModal: false });
    }

    onSelectDuration = Duration => {
        this.setState({ Duration });
    }

    onSelectEnduranceTemplate = (EnduranceTemplateId, EnduranceTemplateName) => {
        this.setState({ DisplayingFilter: null, EnduranceTemplateId, EnduranceTemplateName });
    }

    onSelectFullBody = ({ FullBodyId }, FullBodyIndex) => {
        var NewTargetMuscles = [];
        var NewTargetMuscleBodyAreas = [];
        var NewTargetMusclesFullBody = [];

        if (FullBodyIndex === -1) {
            var { BodyAreas, Muscles } = this.state.SingleGeneratedDetails.TargetMuscles;

            NewTargetMuscles = Muscles.map(({ MuscleGroupId }) => +MuscleGroupId);
            NewTargetMuscleBodyAreas = BodyAreas.map(({ BodyAreaId }) => +BodyAreaId);
            NewTargetMusclesFullBody = [ FullBodyId ];
        }

        this.setState({ TargetMuscles: NewTargetMuscles, TargetMuscleBodyAreas: NewTargetMuscleBodyAreas, TargetMusclesFullBody: NewTargetMusclesFullBody })
    }

    onSelectGoal = GoalId => {
        this.setState({ GoalId });
    }

    onSelectGym = (GymId, GymName) => {
        this.setState({ DisplayingFilter: null, GymId, GymName });
    }

    onSelectJoint = (event, JointId, JointIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = JointIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return { Injuries: { ...prevState.Injuries, Joints: [ ...prevState.Injuries.Joints, ...[ JointId ] ] } }
            });
        } else {
            this.setState((prevState) => {
                return { Injuries: { ...prevState.Injuries, Joints: [ ...prevState.Injuries.Joints.slice(0, JointIndex), ...prevState.Injuries.Joints.slice(JointIndex + 1) ] }}
            });
        }
    }

    onSelectMuscle = (event, MuscleId, MuscleIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = MuscleIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return { Injuries: { ...prevState.Injuries, Muscles: [ ...prevState.Injuries.Muscles, ...[ MuscleId ] ] } }
            });
        } else {
            this.setState((prevState) => {
                return { Injuries: { ...prevState.Injuries, Muscles: [ ...prevState.Injuries.Muscles.slice(0, MuscleIndex), ...prevState.Injuries.Muscles.slice(MuscleIndex + 1) ] }}
            });
        }
    }

    onSelectTargetMuscle = ({ MuscleGroupId }, MuscleGroupIndex) => {
        if (MuscleGroupId !== 0 && !MuscleGroupIndex) MuscleGroupIndex = this.state.TargetMuscles.indexOf(+MuscleGroupId);

        // Build NewTargetMuscles
            var NewTargetMuscles = [];
            if (MuscleGroupIndex === -1) NewTargetMuscles = [ ...this.state.TargetMuscles, +MuscleGroupId ];
            else NewTargetMuscles = [ ...this.state.TargetMuscles.slice(0, MuscleGroupIndex), ...this.state.TargetMuscles.slice(MuscleGroupIndex + 1) ];

        // Build NewTargetMuscleBodyAreas if all body area muscles are in NewTargetMuscles
            var NewTargetMuscleBodyAreas = this.state.SingleGeneratedDetails.TargetMuscles.BodyAreas.map(({ BodyAreaId, BodyAreaMuscleIds }) => {
                if ([ ...BodyAreaMuscleIds.split(', ').map(Number) ].every(val => NewTargetMuscles.includes(val))) return BodyAreaId;

                return null;
            });

        // Determine Full Body
            var NewTargetMusclesFullBody = NewTargetMuscles.length === 13 ? [ 1 ] : [];

        this.setState({ TargetMuscles: NewTargetMuscles, TargetMuscleBodyAreas: NewTargetMuscleBodyAreas, TargetMusclesFullBody: NewTargetMusclesFullBody });
    }

    onSelectUseAllDays = () => {
        var { SelectedDates, SyncAllDateTimes } = this.state;
        
        if (SyncAllDateTimes) this.setState({ SyncAllDateTimes: false });
        else if (!SyncAllDateTimes && SelectedDates.length === 1) this.setState({ SyncAllDateTimes: true });
        
        // Sync all times and more than one date selected
        else {
            var NewSelectedDates_Existing = [];
            var NewSelectedDates = [];

            var FirstTime = moment(SelectedDates[0]).format('HH:mm');
            var NewDate;
            for (var i = 0; i < SelectedDates.length; i++) {
                if (i === 0) {
                    NewSelectedDates_Existing.push(SelectedDates[i]);
                    NewSelectedDates.push(SelectedDates[i]);
                } else {
                    NewDate = moment(moment(SelectedDates[i]).format('YYYY-MM-DD') + ' ' + FirstTime).format('YYYY-MM-DD HH:mm');
                    NewSelectedDates_Existing.push(NewDate);
                    NewSelectedDates.push(NewDate);
                }
            }

            this.setState({ SelectedDates_Existing: NewSelectedDates_Existing, SelectedDates: NewSelectedDates, SyncAllDateTimes: true });
        }
    }

    onToggleDropdown = (DisplayingFilter, ToggleStatus) => {
        if (DisplayingFilter === 'AdditionalActivities' && ToggleStatus && !this.props.RetrievedAdditionalActivitySettings) this.props.GetAdditionalActivityDetails({ ForWorkoutTemplates: false });

        this.setState({ DisplayingFilter: ToggleStatus ? DisplayingFilter : null });
    }

    onToggleShowLocation = () => {
        this.setState({ ShowLocation: !this.state.ShowLocation });
    }

    onToggleShowChooseDatesModal = ShowChooseDatesModal => {
        this.setState({ ShowChooseDatesModal });
    }

    onToggleShowCustomName = () => {
        this.setState({ ShowCustomName: !this.state.ShowCustomName });
    }

    onToggleShowDateTimes = () => {
        this.setState({ ShowDateTimes: !this.state.ShowDateTimes });
    }

    onUploadWorkoutImage = event => {
        if (event.target.files.length) {
            var SelectedImage = event.target.files[0];
            var FileExtension = getFileExtension(SelectedImage.name);

            if (validateFile({ FileExtension, FileType: 'Images' })) {
                if (SelectedImage.size <= this.state.MaxImageSize) {
                    var WorkoutImage = URL.createObjectURL(SelectedImage);

                    this.setState({ ActivityPicture: WorkoutImage, ActivityPictureId: 'custom', ImageTooLarge: false, SelectedImage, WorkoutImage, WrongImageType: false});
                } else {
                    document.getElementById('WorkoutImage').value = null;
                    this.setState({ ImageTooLarge: true, WrongImageType: false });
                }
            } else this.setState({ ImageTooLarge: false, WrongImageType: true });
        }
    }

    renderAdditionalActivities = () => {
        var { AdditionalActivities } = this.props;

        return (
            <AdditionalActivityList>
                {
                    AdditionalActivities.map(({ label, Pictures, value }) => {
                        var Selected = value === this.state.ActivityId;

                        return (
                            <AdditionalActivityItem key={value} onClick={() => this.onSelectAdditionalActivity({ ActivityId: value, ActivityName: label, ActivityPictures: Pictures })} Selected={Selected}>
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={label} TextAlign="left" />
                            </AdditionalActivityItem>
                        );
                    })
                }
            </AdditionalActivityList>
        );
    }

    renderAdditionalActivity = () => {
        var { t } = this.props;
        var { Device, AdditionalActivities } = this.props;
        var { ActivityId, ActivityName, ActivityPicture, ActivityPictures, DisplayingFilter, DropdownSize } = this.state;

        return (
            <>
                <StyledInputLabelMultiText
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('WorkoutPlan_ChooseActivity') },
                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                    ]}
                />

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('choose_activity_and_picture')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <StyledDropdown
                    ContentRenderer={this.renderAdditionalActivities}
                    Disabled={false}
                    ForceClose={DisplayingFilter !== 'AdditionalActivities'}
                    GetSize={Height => this.onGetSize(Height)}
                    Height={DisplayingFilter === 'AdditionalActivities' && DropdownSize}
                    IsList={false}
                    IsMobile={Device === 'mobile_small' || Device === 'mobile'}
                    ItemId="AdditionalActivities"
                    NeedsToLoad={AdditionalActivities.length === 0}
                    OnToggleDropdown={ToggleStatus => this.onToggleDropdown('AdditionalActivities', ToggleStatus)}
                    SelectedItemIds={[]}
                    Title={ActivityName || t('WorkoutPlan_ChooseActivity')}
                    TitleFontColor={ActivityName ? 'blue-astronaut' : null}
                    TitleFontFamily="semibold"
                />

                <Spacer Size="extra-small" />

                {
                    ActivityPictures.length > 0 &&
                    <ActivityPicturesContainer>
                        {
                            ActivityPictures.map(Picture => {
                                var Selected = ActivityPicture === Picture;

                                return (
                                    <ActivityPictureContainer key={Picture} onClick={() => this.onSelectActivityPicture({ ActivityPicture: Picture, ActivityPictureId: ActivityId })} Selected={Selected}>
                                        <StyledActivityImage
                                            ContainerWidth="100%"
                                            ImageSrc={Picture}
                                            SelfLoading
                                        />
                                    </ActivityPictureContainer>
                                );
                            })
                        }
                    </ActivityPicturesContainer>
                }

                {
                    !!+ActivityId &&
                    <>
                        <Spacer Size="medium" />

                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('or_upload_your_own')} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        {this.renderUploadPicture()}
                    </>
                }
            </>
        );
    }

    renderAddTrainerNote = () => {
        var { t } = this.props;
        var { TrainerNote } = this.state;

        return (
            <TrainerNoteContainer>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('WorkoutPlan_Trainer_note_to_client')} TextAlign="center" />

                <Spacer Size="small" />

                <TrainerNoteTextArea
                    OnBlur={this.onChangeTrainerNote}
                    NumRows={4}
                    Placeholder={t('WorkoutPlan_Trainer_note_to_client_placeholder')}
                    Value={TrainerNote}
                />
            </TrainerNoteContainer>
        );
    }

    renderCardioLevels = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { EnduranceTemplates } = this.state.SingleGeneratedDetails;

        var CardioLevelButton = { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold'};
        var CardioLevelButtons = EnduranceTemplates.map(({ CardioLevel }, CardioLevelIndex) => ({ ...CardioLevelButton, OnClick: () => this.onSelectCardioLevel(CardioLevel, CardioLevelIndex), Selected: CardioLevelIndex === this.state.CardioLevelIndex, Title: CardioLevel }));

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('WorkoutPlan_Intensity')} />
                
                <Spacer Size="small" />

                <ButtonGroup
                    Buttons={CardioLevelButtons}
                    ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />
            </>
        );
    }

    renderCustomName = () => {
        var { t } = this.props;
        var { CustomName, ShowCustomName } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" Text={t('custom_name')} TextAlign="center" />
                    
                <DateTimeUseAllDaysContainer>
                    <DateTimeUseAllDays onClick={this.onToggleShowCustomName} Selected={ShowCustomName} />
                    <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={this.onToggleShowCustomName} Text={t('custom_name_additional_activity_info')} TextAlign="left" />
                </DateTimeUseAllDaysContainer>

                {
                    ShowCustomName &&
                    <>
                        <Spacer Size="small" />

                        <StyledDuration
                            NoDecimal
                            NoLabel
                            NoMargin
                            OnChange={this.onChangeCustomName}
                            Placeholder={t('custom_name')}
                            Size="medium"
                            Type="text"
                            Value={CustomName}
                        />
                    </>
                }
            </>
        );
    }

    renderDates = () => {
        var { t } = this.props;
        var { SelectedDates } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('choose_dates')} />
                
                <Spacer Size="small" />

                {
                    SelectedDates.length ?
                    this.renderSelectedDates()
                :
                    <ButtonGroup
                        Buttons={[
                            {
                                FontFamily: 'semibold',
                                Icon: Plus,
                                IconPosition: 'left',
                                OnClick: () => this.onToggleShowChooseDatesModal(true),
                                Title: t('add_dates')
                            }
                        ]}
                        ButtonWidth="fit-content"
                        NotTouching
                    />
                }
            </>
        );
    }

    renderDateTimes = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { HourType: StateHourType, SelectedDates, ShowDateTimes, SyncAllDateTimes } = this.state;

        if (!SelectedDates.length) return null;

        return (
            <>
                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('activity_start_time')} />

                <DateTimeUseAllDaysContainer>
                    <DateTimeUseAllDays onClick={this.onToggleShowDateTimes} Selected={ShowDateTimes} />
                    <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={this.onToggleShowDateTimes} Text={t('additional_activity_choosetimes')} TextAlign="left" />
                </DateTimeUseAllDaysContainer>

                {
                    ShowDateTimes &&
                    <>
                        <Spacer Size="small" />

                        <HourTypesContainer>
                            <HourType onClick={() => this.onChangeHourType(12)} Selected={StateHourType === 12}>
                                <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('hour_type', { HourType: '12' })} />
                            </HourType>
                            <HourType onClick={() => this.onChangeHourType(24)} Selected={StateHourType === 24}>
                                <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('hour_type', { HourType: '24' })} />
                            </HourType>
                        </HourTypesContainer>

                        <Spacer Size="small" />

                        <DateTimesContainer>
                            {
                                SelectedDates.map((SelectedDate, index) => {
                                    return (
                                        <DateTimeContainer key={SelectedDate}>
                                            <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={moment(SelectedDate).format('DD.MMM')} />
                                            
                                            <Spacer Size="extra-extra-small" />
                                            
                                            <TimePicker
                                                defaultValue={moment(SelectedDate)}
                                                disabled={SyncAllDateTimes && index !== 0}
                                                minuteStep={5}
                                                onChange={DateTime => this.onChangeDateTime(DateTime, index)}
                                                showSecond={false}
                                                use12Hours={StateHourType === 12}
                                                value={moment(SelectedDate)}
                                            />
                                            {
                                                index === 0 && SelectedDates.length > 1 ?
                                                <DateTimeUseAllDaysContainer>
                                                    <DateTimeUseAllDays onClick={this.onSelectUseAllDays} Selected={SyncAllDateTimes} />
                                                    <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" Text={t('use_for_all_days')} TextAlign="left" />
                                                </DateTimeUseAllDaysContainer>
                                            :
                                                null
                                            }
                                        </DateTimeContainer>
                                    );
                                })
                            }
                        </DateTimesContainer>
                    </>
                }
            </>
        );
    }

    renderDurations = () => {
        var { Duration } = this.state;

        return (
            <DurationsContainer>
                {
                    WorkoutTimeOptions.map(WorkoutTime => {
                        var Selected = WorkoutTime === Duration;

                        return (
                            <DurationContainer key={WorkoutTime} onClick={() => this.onSelectDuration(WorkoutTime)} Selected={Selected}>
                                {WorkoutTime}
                            </DurationContainer>
                        );
                    })
                }
            </DurationsContainer>
        );
    }

    renderEnduranceQuestions = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { CardioLevel, DisplayingFilter, DropdownSize, EnduranceTemplateId, EnduranceTemplateName } = this.state;

        return (
            <>
                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                {this.renderExperienceLevel('Endurance')}

                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                {this.renderCardioLevels()}

                {CardioLevel &&
                    <>
                        <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                        <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('WorkoutPlan_ChooseActivity')} />
                
                        <Spacer Size="small" />

                        <StyledDropdown
                            ContentRenderer={this.renderEnduranceTemplates}
                            Disabled={false}
                            ForceClose={DisplayingFilter !== 'EnduranceTemplates'}
                            GetSize={Height => this.onGetSize(Height)}
                            Height={DisplayingFilter === 'EnduranceTemplates' && DropdownSize}
                            IsList={false}
                            IsMobile={Device === 'mobile_small' || Device === 'mobile'}
                            ItemId="EnduranceTemplates"
                            NeedsToLoad={false}
                            OnToggleDropdown={ToggleStatus => this.onToggleDropdown('EnduranceTemplates', ToggleStatus)}
                            SelectedItemIds={[]}
                            Title={EnduranceTemplateName || t('WorkoutPlan_Activity')}
                            TitleFontColor={EnduranceTemplateId ? 'var(--blue-astronaut)' : null}
                            TitleFontFamily="semibold"
                        />
                    </>
                }
            </>
        )
    }

    renderEnduranceTemplates = () => {
        var { CardioLevelIndex, EnduranceTemplateId } = this.state;
        var { Templates } = this.props.SingleGeneratedDetails.EnduranceTemplates[CardioLevelIndex];

        return (
            <PublicGymsList>
                {
                    Templates.map(({ value, label }) => {
                        var Selected = value === EnduranceTemplateId;

                        return (
                            <PublicGymItem key={value} onClick={() => this.onSelectEnduranceTemplate(value, label)} Selected={Selected}>
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={label} TextAlign="left" />
                            </PublicGymItem>
                        );
                    })
                }
            </PublicGymsList>
        );
    }

    renderEquipment = () => {
        var { t } = this.props;
        var { Device, SingleGeneratedDetails: { DefaultGym, SavedGyms } } = this.props;
        var { DisplayingFilter, DropdownSize } = this.state;

        var DefaultGymId = DefaultGym[0].GymId;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('WorkoutPlan_what_equipment_do_you_have_available')} TextAlign="center" />

                <Spacer Size="small" />

                <SavedGymsContainer>
                    <GymContainer onClick={() => this.onSelectGym(1)} Selected={this.state.GymId === 1}>
                        <GymNameText>
                            {t('No_gym')}{DefaultGymId === 1 && <MainGym> - {t('Main')}</MainGym>}
                        </GymNameText>
                    </GymContainer>
                </SavedGymsContainer>

                {
                    SavedGyms.length &&
                    <>
                        <Spacer Size="medium" />

                        <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('client_gymlist')} TextAlign="center" />

                        <Spacer Size="small" />

                        <SavedGymsContainer>
                            {
                                SavedGyms.map(({ GymId, GymName }) => {
                                    var Selected = GymId === this.state.GymId;

                                    return (
                                        <GymContainer key={GymId} onClick={() => this.onSelectGym(GymId, GymName)} Selected={Selected}>
                                            <GymNameText>
                                                {GymName}{DefaultGymId === GymId && <MainGym Selected={Selected}>&nbsp;- {t('Main')}</MainGym>}
                                            </GymNameText>
                                        </GymContainer>
                                    );
                                })
                            }
                        </SavedGymsContainer>
                    </>
                }

                <Spacer Size="medium" />

                <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('WorkoutPlan_find_public_gym_from_list')} TextAlign="center" />

                <Spacer Size="small" />

                <StyledDropdown
                    ContentRenderer={this.renderPublicGyms}
                    Disabled={false}
                    ForceClose={DisplayingFilter !== 'PublicGyms'}
                    GetSize={Height => this.onGetSize(Height)}
                    Height={DisplayingFilter === 'PublicGyms' && DropdownSize}
                    IsList={false}
                    IsMobile={Device === 'mobile_small' || Device === 'mobile'}
                    ItemId="PublicGyms"
                    NeedsToLoad={false}
                    OnToggleDropdown={ToggleStatus => this.onToggleDropdown('PublicGyms', ToggleStatus)}
                    SelectedItemIds={[]}
                    Title={this.state.GymId !== 1 ? this.state.GymName : t('WorkoutPlan_public_gyms')}
                    TitleFontColor={this.state.GymId !== 1 ? 'var(--blue-astronaut)' : null}
                    TitleFontFamily="semibold"
                />
            </>
        );
    }

    renderExperienceLevel = ExperienceLevelType => {
        var { t } = this.props;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t(`WorkoutPlan_${ExperienceLevelType}_Experience_Level_title`)} TextAlign="center" />

                <Spacer Size="extra-small" />

                <StyledNumberInput
                    FontFamily="bold"
                    FontSize="large"
                    MaxValue={5}
                    MinValue={1}
                    OnChange={Direction => this.onChangeExperienceLevel(ExperienceLevelType, Direction)}
                    Value={this.state.SingleGeneratedDetails[`ExperienceLevel_${ExperienceLevelType}`]}
                />
            </>
        );
    }

    renderInjuries = () => {
        var { t } = this.props;
        var { Device, SingleGeneratedDetails: { Injuries: { Joints, Muscles } } } = this.props;
        var { DisplayingFilter, DropdownSize, Injuries: { Joints: StateJoints, Muscles: StateMuscles } } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('WorkoutPlan_injuries')} TextAlign="center" />
                
                <Spacer Size="extra-small" />
                
                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_client_questionnaire_questiondescription_injuries')} TextAlign="center" />
                
                <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />

                <InjuriesDropdownContainer>
                    <StyledDropdown
                        Disabled={false}
                        ForceClose={DisplayingFilter !== 'Joints'}
                        GetSize={Height => this.onGetSize(Height)}
                        Height={DisplayingFilter === 'Joints' && DropdownSize}
                        IsMobile={Device === 'mobile_small' || Device === 'mobile'}
                        ItemId="JointId"
                        ItemName="JointName"
                        Items={Joints}
                        NeedsToLoad={false}
                        OnSelectItem={(event, JointId, JointIndex) => this.onSelectJoint(event, JointId, JointIndex)}
                        OnToggleDropdown={ToggleStatus => this.onToggleDropdown('Joints', ToggleStatus)}
                        SelectedItemIds={StateJoints}
                        Title={t('Joints')}
                    />

                    <StyledDropdown
                        Disabled={false}
                        ForceClose={DisplayingFilter !== 'Muscles'}
                        GetSize={Height => this.onGetSize(Height)}
                        Height={DisplayingFilter === 'Muscles' && DropdownSize}
                        IsMobile={Device === 'mobile_small' || Device === 'mobile'}
                        ItemId="MuscleGroupId"
                        ItemName="MuscleGroupName"
                        Items={Muscles}
                        NeedsToLoad={false}
                        OnSelectItem={(event, MuscleId, MuscleIndex) => this.onSelectMuscle(event, MuscleId, MuscleIndex)}
                        OnToggleDropdown={ToggleStatus => this.onToggleDropdown('Muscles', ToggleStatus)}
                        SelectedItemIds={StateMuscles}
                        Title={t('Muscles')}
                    />
                </InjuriesDropdownContainer>
            </>
        );
    }

    renderGoals = () => {
        var { t } = this.props;
        var { Goals } = this.props.SingleGeneratedDetails;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('BothPlans_whats_your_main_goal_str')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <GoalsContainer>
                    {
                        Goals.map(({ GoalId, GoalName }) => {
                            var Selected = GoalId === this.state.GoalId;
                            var ImageSrc = GoalId === 1 ? LoseWeight : GoalId === 2 ? IncreaseStrength : GainMuscleMass;

                            return (
                                <GoalContainer key={GoalId} onClick={() => this.onSelectGoal(GoalId)} Selected={Selected}>
                                    <GoalImage ImageSrc={ImageSrc} Loading={false} />
                                    <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={GoalName} TextAlign="center" />
                                </GoalContainer>
                            );
                        })
                    }
                </GoalsContainer>
            </>
        );
    }

    renderLocation = () => {
        var { t } = this.props;
        var { Location, ShowLocation } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" Text={t('location')} TextAlign="center" />
                    
                <DateTimeUseAllDaysContainer>
                    <DateTimeUseAllDays onClick={this.onToggleShowLocation} Selected={ShowLocation} />
                    <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={this.onToggleShowLocation} Text={t('additional_activity_chooselocation')} TextAlign="left" />
                </DateTimeUseAllDaysContainer>

                {
                    ShowLocation &&
                    <>
                        <Spacer Size="small" />

                        <StyledDuration
                            NoLabel
                            NoMargin
                            OnChange={this.onChangeLocation}
                            Placeholder={t('location_placeholder')}
                            Size="medium"
                            Type="text"
                            Value={Location}
                        />
                    </>
                }
            </>
        );
    }

    renderPublicGyms = () => {
        return (
            <PublicGymsList>
                {
                    this.props.SingleGeneratedDetails.Gyms.map(({ value, label }) => {
                        var Selected = value === this.state.GymId;

                        return (
                            <PublicGymItem key={value} onClick={() => this.onSelectGym(value, label)} Selected={Selected}>
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={label} TextAlign="left" />
                            </PublicGymItem>
                        );
                    })
                }
            </PublicGymsList>
        );
    }

    renderSelectedDates = () => {
        var { SelectedDates } = this.state;

        return (
            <SelectedDatesContainer>
                {
                    SelectedDates.map(SelectedDate => 
                        <SelectedDateContainer key={SelectedDate}>
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={moment(SelectedDate).format('DD.MMM')} />
                        </SelectedDateContainer>
                    )
                }

                <EditIcon
                    ImageSrc={Edit_Dark}
                    Loading={false}
                    OnClick={() => this.onToggleShowChooseDatesModal(true)}
                />
            </SelectedDatesContainer>
        );
    }

    renderShowChooseDatesModal = () => {
        var { ShowChooseDatesModal } = this.state;

        if (ShowChooseDatesModal) {
            return (
                <ChooseDates
                    // MaxDates={1}
                    OnHide={() => this.onToggleShowChooseDatesModal(false)}
                    OnSelectDates={SelectedDates => this.onSelectDates(SelectedDates)}
                    SelectedDates={this.state.SelectedDates}
                    Show={ShowChooseDatesModal}
                />
            );
        }
    }

    renderStrengthQuestions = () => {
        var { Device } = this.props;

        return (
            <>
                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                {this.renderExperienceLevel('Strength')}

                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                {this.renderGoals()}

                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                {this.renderEquipment()}

                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                {this.renderTargetMuscles()}

                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                {this.renderInjuries()}
            </>
        )
    }

    renderTargetMuscles = () => {
        var { t } = this.props;
        var { TargetMuscles: { BodyAreas, Muscles } } = this.props.SingleGeneratedDetails;
        var { TargetMuscleBodyAreas, TargetMuscles, TargetMusclesFullBody } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('WorkoutPlan_target_area_title')} TextAlign="center" />

                <Spacer Size="small" />

                <FlexWrap
                    IsMultiSelect
                    ItemId="FullBodyId"
                    ItemName="FullBodyName"
                    Items={[{ FullBodyId: 1, FullBodyName: t('WorkoutPlan_Full_Body') }]}
                    OnSelectItem={(Item, ItemIndex) => this.onSelectFullBody(Item, ItemIndex)}
                    SelectedItemIds={TargetMusclesFullBody}
                />

                <Spacer Size="medium" />
                
                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('body_areas')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <FlexWrap
                    IsMultiSelect
                    ItemId="BodyAreaId"
                    ItemName="BodyAreaName"
                    Items={BodyAreas}
                    OnSelectItem={(Item, ItemIndex) => this.onSelectBodyArea(Item, ItemIndex)}
                    SelectedItemIds={TargetMuscleBodyAreas}
                />

                <Spacer Size="medium" />

                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('Muscles')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <FlexWrap
                    IsMultiSelect
                    ItemId="MuscleGroupId"
                    ItemName="MuscleGroupName"
                    Items={Muscles}
                    OnSelectItem={(Item, ItemIndex) => this.onSelectTargetMuscle(Item, ItemIndex)}
                    SelectedItemIds={TargetMuscles}
                />

                <Spacer Size="medium" />

                <StyledBodyMuscles OnSelectMuscle={MuscleGroupId => this.onSelectTargetMuscle({ MuscleGroupId })} PrimaryMuscleIds={TargetMuscles} />
            </>
        );
    }

    renderUploadPicture = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { ActivityPictureId, ImageTooLarge, WorkoutImage, WrongImageType } = this.state;

        var Buttons = [{ BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: Camera, IconPosition: 'left', IsInput: true, InputProps: { accept: "image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg", id: "WorkoutImage", type: "file", onChange: this.onUploadWorkoutImage }, Title: t('Upload') }];

        return (
            <UploadContainer>
                <UploadButtonsRow>
                    <UploadButtonsContainer
                        Buttons={Buttons}
                        ButtonWidth="fit-content"
                        ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                        NotTouching
                        OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                    />
                </UploadButtonsRow>

                {
                    WorkoutImage &&
                    <ActivityPicturesContainer>
                        <ActivityPictureContainer onClick={() => this.onSelectActivityPicture({ ActivityPicture: WorkoutImage, ActivityPictureId: 'custom' })} Selected={ActivityPictureId === 'custom'}>
                            <StyledActivityImage
                                ContainerWidth="100%"
                                ImageSrc={WorkoutImage}
                                SelfLoading
                            />
                        </ActivityPictureContainer>
                    </ActivityPicturesContainer>
                }
                                
                {
                    ImageTooLarge || WrongImageType ?
                    <>
                        <Spacer Size="small" />

                        <PageText
                            FontColor="red-bittersweet"
                            FontFamily="semibold-italic"
                            FontSize="medium-1"
                            Text={t(ImageTooLarge ? 'media_wrongsize' : 'media_wrongtype', { AllowedTypes: '.jpg, .png, .jpeg', MaxSize: '10MB', Type: 'Image' })}
                            TextAlign="center"
                        />
                    </>
                :
                    null
                }
            </UploadContainer>
        );
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { t } = this.props;
        var { Device, TryingAddSingleGeneratedWorkout, TryingGetAdditionalActivityDetails, TryingGetSingleGeneratedWorkoutDetails, UploadProgress } = this.props;
        var { ActivityId, ActivityPicture, ActivityPictureId, Duration, EnduranceTemplateId, GoalId, GymId, SelectedDates, SelectedUsers, SelectedUserIds, SingleGeneratedDetails: { ExperienceLevel_Strength }, TargetMuscles, WorkoutImage, WorkoutType } = this.state;

        var Disabled = SelectedUserIds.length === 1 && SelectedDates.length > 0 && WorkoutType && ActivityId && ActivityPicture && ((ActivityPictureId === 'custom' && WorkoutImage) || ActivityId === ActivityPictureId) && (
            (WorkoutType === 'Strength' && Duration && ExperienceLevel_Strength && GoalId && GymId && TargetMuscles.length) ||
            (WorkoutType === 'Endurance' && EnduranceTemplateId)
        ) ? false : true;

        var Breadcrumbs = [{ Name: t('Build_workout_str') }];
        var SubmitButtonProps = {
            Disabled,
            OnClick: this.onAddSingleGeneratedWorkouts,
            Title: t('Build_workout_str')
        }

        return (
            <>
                {(TryingAddSingleGeneratedWorkout || TryingGetAdditionalActivityDetails || TryingGetSingleGeneratedWorkoutDetails) ? <Loading /> : null}

                <Page
                    renderBreadcrumbs={Breadcrumbs}
                    renderSubmitButton={SubmitButtonProps}
                >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={t('add_single_generated_workout')} TextAlign="center" />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    <AssignClients
                        GetStartedEligible="Workouts"
                        HideSelectAll
                        // HideTitle
                        // HorizontalScroll={false}
                        MaxClients={1}
                        OnSelectClients={Users => this.onSelectClients(Users)}
                        SelectedUserIds={SelectedUserIds}
                        SelectedUsers={SelectedUsers}
                    />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderDates()}

                    {this.renderDateTimes()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('Workout_single_plan_Would_you_like_a_Strength_or_Endurance_workout')} TextAlign="center" />

                    <Spacer Size="small" />

                    <ButtonGroup
                        Buttons={[
                            { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onChangeWorkoutType('Strength'), Selected: WorkoutType === 'Strength', Title: t('Strength') },
                            { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onChangeWorkoutType('Endurance'), Selected: WorkoutType === 'Endurance', Title: t('Endurance') }
                        ]}
                        ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                        NotTouching
                        OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                    />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderAdditionalActivity()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderCustomName()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderLocation()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('Workout_single_plan_Choose_the_duration_of_your_workout')} TextAlign="center" />

                    <Spacer Size="small" />

                    {this.renderDurations()}

                    {
                        SelectedUsers.length && WorkoutType === 'Strength' ?
                        this.renderStrengthQuestions()
                    :
                        SelectedUsers.length && WorkoutType === 'Endurance' ?
                        this.renderEnduranceQuestions()
                    :
                        null
                    }

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderAddTrainerNote()}

                    {
                        UploadProgress.SingleGenerated && Object.entries(UploadProgress.SingleGenerated).length > 0 && 
                            <>
                                <Spacer Size="medium" />

                                <UploadProgressComponent UploadingItems={UploadProgress.SingleGenerated} />
                            </>
                    }
                </Page>

                {this.renderShowChooseDatesModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        ClientDetails: state.SaveData.ClientDetails,
        DayDate: state.SaveData.DayDate,

        AdditionalActivities: state.AdditionalActivities.AdditionalActivities,
        Intensities: state.AdditionalActivities.Intensities,
        RetrievedAdditionalActivitySettings: state.AdditionalActivities.RetrievedAdditionalActivitySettings,

        TryingGetAdditionalActivityDetails: state.AdditionalActivities.TryingGetAdditionalActivityDetails,
        TryingGetAdditionalActivityDetailsError: state.AdditionalActivities.TryingGetAdditionalActivityDetailsError,

        SingleGeneratedDetails: state.Workouts.SingleGeneratedDetails,

        UploadProgress: state.UploadContent.UploadProgress,

        TryingAddSingleGeneratedWorkout: state.Workouts.TryingAddSingleGeneratedWorkout,
        TryingAddSingleGeneratedWorkoutError: state.Workouts.TryingAddSingleGeneratedWorkoutError,
        TryingGetSingleGeneratedWorkoutDetails: state.Workouts.TryingGetSingleGeneratedWorkoutDetails,
        TryingGetSingleGeneratedWorkoutDetailsError: state.Workouts.TryingGetSingleGeneratedWorkoutDetailsError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddSingleGeneratedAbelWorkout, ClearAdditionalActivityDetails, ClearSaveData, ClearSingleGeneratedWorkoutDetails, GetAdditionalActivityDetails, GetSingleGeneratedWorkoutDetails, UpdateSelectedTab, UploadToPresignedUrl } )(AddSingleGeneratedWorkout));