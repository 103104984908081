import {
    TRYING_DELETE_FOODS,
    TRYING_DELETE_FOODS_SUCCESS,
    TRYING_DELETE_FOODS_ERROR,

    TRYING_DELETE_FOODS_CONFIRMATION,
    TRYING_DELETE_FOODS_CONFIRMATION_SUCCESS,
    TRYING_DELETE_FOODS_CONFIRMATION_ERROR,

    CLEAR_VIEW_PRIVATE_FOOD_SETTINGS,
    TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS,
    TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS_SUCCESS,
    TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS_ERROR,

    TRYING_INSERTEDIT_PRIVATE_FOOD,
    TRYING_INSERTEDIT_PRIVATE_FOOD_SUCCESS,
    TRYING_INSERTEDIT_PRIVATE_FOOD_ERROR,

    TRYING_VIEW_FOOD_UNITS,
    TRYING_VIEW_FOOD_UNITS_SUCCESS,
    TRYING_VIEW_FOOD_UNITS_ERROR,

    CLEAR_SEARCH_FOOD,
    TRYING_SEARCH_FOOD,
    TRYING_SEARCH_FOOD_SUCCESS,
    TRYING_SEARCH_FOOD_ERROR
} from '../Types';

const INITIAL_STATE = {
    CustomFoodSettings: {},
    FoodList: [],
    FoodList_TotalRecords: null,
    IsMoreFood: false,

    TryingDeleteFoods: false,
    TryingDeleteFoodsError: null,
    TryingDeleteFoodsConfirmation: false,
    TryingDeleteFoodsConfirmationError: null,
    TryingGetCreatePrivateFoodSettings: false,
    TryingGetCreatePrivateFoodSettingsError: null,
    TryingInsertEditPrivateFood: false,
    TryingInsertEditPrivateFoodError: null,
    TryingViewFoodUnits: false,
    TryingViewFoodUnitsError: null,
    TryingSearchFood: false,
    TryingSearchFoodError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_DELETE_FOODS:
            return { ...state, TryingDeleteFoods: true, TryingDeleteFoodsError: null };
        case TRYING_DELETE_FOODS_ERROR:
            return { ...state, TryingDeleteFoods: false, TryingDeleteFoodsError: action.payload };
        case TRYING_DELETE_FOODS_SUCCESS:
            return { ...state, TryingDeleteFoods: false, TryingDeleteFoodsError: null };

        case TRYING_DELETE_FOODS_CONFIRMATION:
            return { ...state, TryingDeleteFoodsConfirmation: true, TryingDeleteFoodsConfirmationError: null };
        case TRYING_DELETE_FOODS_CONFIRMATION_ERROR:
            return { ...state, TryingDeleteFoodsConfirmation: false, TryingDeleteFoodsConfirmationError: action.payload };
        case TRYING_DELETE_FOODS_CONFIRMATION_SUCCESS:
            return { ...state, TryingDeleteFoodsConfirmation: false, TryingDeleteFoodsConfirmationError: null };

        case CLEAR_VIEW_PRIVATE_FOOD_SETTINGS:
            return { ...state, CustomFoodSettings: {} };
        case TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS:
            return { ...state, TryingGetCreatePrivateFoodSettings: true, TryingGetCreatePrivateFoodSettingsError: null };
        case TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS_ERROR:
            return { ...state, TryingGetCreatePrivateFoodSettings: false, TryingGetCreatePrivateFoodSettingsError: action.payload };
        case TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS_SUCCESS:
            return { ...state, CustomFoodSettings: action.payload, TryingGetCreatePrivateFoodSettings: false, TryingGetCreatePrivateFoodSettingsError: null };

        case TRYING_INSERTEDIT_PRIVATE_FOOD:
            return { ...state, TryingInsertEditPrivateFood: true, TryingInsertEditPrivateFoodError: null };
        case TRYING_INSERTEDIT_PRIVATE_FOOD_ERROR:
            return { ...state, TryingInsertEditPrivateFood: false, TryingInsertEditPrivateFoodError: action.payload };
        case TRYING_INSERTEDIT_PRIVATE_FOOD_SUCCESS:
            return { ...state, TryingInsertEditPrivateFood: false, TryingInsertEditPrivateFoodError: null };

        case TRYING_VIEW_FOOD_UNITS:
            return { ...state, TryingViewFoodUnits: true, TryingViewFoodUnitsError: null };
        case TRYING_VIEW_FOOD_UNITS_ERROR:
            return { ...state, TryingViewFoodUnits: false, TryingViewFoodUnitsError: action.payload };
        case TRYING_VIEW_FOOD_UNITS_SUCCESS:
            return { ...state, TryingViewFoodUnits: false, TryingViewFoodUnitsError: null };

        case CLEAR_SEARCH_FOOD:
            return { ...state, FoodList: [], FoodList_TotalRecords: null, IsMoreFood: false };
        case TRYING_SEARCH_FOOD:
            return { ...state, TryingSearchFood: true, TryingSearchFoodError: null };
        case TRYING_SEARCH_FOOD_ERROR:
            return { ...state, TryingSearchFood: false, TryingSearchFoodError: action.payload };
        case TRYING_SEARCH_FOOD_SUCCESS:
            // eslint-disable-next-line
            var { AddIngredients_IsMoreResults, AddToExisting, Ingredients, TotalRecords } = action.payload;
            var FoodList = [];

            if (AddToExisting) FoodList = [ ...state.FoodList, ...Ingredients ];
            else FoodList = [ ...Ingredients ];

            return { ...state, FoodList, FoodList_TotalRecords: Number(TotalRecords), IsMoreFood: AddIngredients_IsMoreResults ? true : false, TryingSearchFood: false, TryingSearchFoodError: null };
        default:
            return state;
    }
};
