import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { LogoutUser } from "../../Services/Actions";
import history from "../../history";

import { BETA_SITE_URL, SUPPORT_LINK } from '../../Config/index';

import {
  AbelHeader,
  AbelHeaderContainer,
  HeaderMenuIcon,
  MenuContainer,
  MenuIcon,
  MenuLink,
  MobileIcon,
  MobileIconContainer,
  MobileIconsContainer,
  MobileMenuRightContainer,
  MobileMenuTopContainer1,
  MobileProfilePhoto,
  NotificationCountContainer,
  ProfilePhotoContainer,
} from "./styles";
import {
  AbelLogo,
  AbelLogoContainer,
  AbelLogoImageContainer,
  AbelNameContainer,
  BottomHeaderSection,
  HeaderContainer,
  LeftHeaderContainer,
  MobileMenuLeftContainer,
  RightHeaderContainer,
  SearchClientsContainer,
  TopHeaderSection,
} from "./styles";
import {
  ActionsRow,
  ActionsButtonContainer,
  ActionsButtonsContainer,
  ActionsRowHeader,
  MultiActionsInfoContainer,
} from "./styles";

import HeaderSAYT from "../Form/HeaderSAYT";
import PageText from "../Text/PageText";
import ProfilePhoto from "../ProfilePhoto";

import AbelIcon from "../../Assets/Icons/Abel_HeaderIcon.png";
import Hamburger from "../../Assets/Icons/AdminMenu/Hamburger.png";
import Hamburger_Hover from "../../Assets/Icons/AdminMenu/Hamburger_Hover.png";
import Hamburger_Close from "../../Assets/Icons/AdminMenu/Hamburger_Close.png";
import Hamburger_Close_Hover from "../../Assets/Icons/AdminMenu/Hamburger_Close_Hover.png";
import Messages from "../../Assets/Icons/AdminMenu/Chat.png";
import Messages_Hover from "../../Assets/Icons/AdminMenu/Chat_Hover.png";
import Notifications from "../../Assets/Icons/AdminMenu/Notifications.png";
import Notifications_Hover from "../../Assets/Icons/AdminMenu/Notifications_Hover.png";
// import SearchIcon from '../../Assets/Icons/NumberOperators.png';

import { ReactComponent as BookingSVG } from '../../Assets/SVG/Booking.svg';
import { ReactComponent as ClientsSVG } from '../../Assets/SVG/Clients.svg';
import { ReactComponent as LearnABELSVG } from '../../Assets/SVG/Learn_ABEL.svg';
import { ReactComponent as LibrarySVG } from '../../Assets/SVG/Nutrition_library.svg';
import { ReactComponent as SalesSVG } from '../../Assets/SVG/Sales.svg';
import { ReactComponent as SupportSVG } from '../../Assets/SVG/Support.svg';
// import { ReactComponent as SettingsSVG } from '../../Assets/SVG/Settings.svg';
// import { ReactComponent as VideoSVG } from '../../Assets/SVG/Videocalls.svg';
import { ReactComponent as WorkoutLibrarySVG } from '../../Assets/SVG/Workout_library.svg';


import Settings from "../../Assets/Icons/AdminMenu/Settings.png";
import Settings_Hover from "../../Assets/Icons/AdminMenu/Settings_Hover.png";

import Info from "../../Modals/Info";
import ModalMenu from "../../Modals/Menu";
import NotificationsModal from "../../Modals/Notifications";

class Header extends React.Component {
  state = {
    Clients: [],
    ClientSearch: "",
    PopoverIsOpen: false,
    ShowMenuModal: false,
    ShowMobileDropdownMenu: false,
    ShowMultiActionsModal: false,
    ShowNotificationsModal: false,
  };

  onClickLogout = () => {
    this.onToggleShowMenuModal(false);
    this.props.LogoutUser();
  };

  onClickMenuItem = ({ event, BetaAdmin, LinkUrl }) => {
    if (event) event.preventDefault();

    var { ShowMobileDropdownMenu, ShowNotificationsModal } = this.state;

    if (ShowMobileDropdownMenu || ShowNotificationsModal) this.setState({ ShowMobileDropdownMenu: false, ShowNotificationsModal: false }, () => history.push(`/${LinkUrl}`));
    else if (BetaAdmin) {
      var link = BETA_SITE_URL + `/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=${BetaAdmin}`;
      window.location.href = link;
    }
    else history.push(`/${LinkUrl}`);
  };

  onClickMobileMenuItem = ({ BetaAdmin, Link, LinkType, OnClick }) => {
    if (OnClick) OnClick();
    else if (LinkType && LinkType === 'external') {
      // window.location.href = Link;
      window.open(Link, '_blank');
    }
    else if (BetaAdmin) {
      var link = BETA_SITE_URL + `/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=${BetaAdmin}`;
      window.location.href = link;
    }
    else
      this.setState(
        { ShowMobileDropdownMenu: false, ShowNotificationsModal: false },
        () => history.push(`/${Link}`)
      );
  };

  onClickMultiActionLink = () => {
    this.onToggleShowMultiActionsModal(false);

    if (this.props.IsHeaderMobile) this.onToggleMobileDropdownMenu();
  };

  onToggleMobileDropdownMenu = () => {
    this.setState({
      ShowMobileDropdownMenu: !this.state.ShowMobileDropdownMenu,
    });
  };

  onToggleShowMenuModal = (ShowMenuModal) => {
    this.setState({ ShowMenuModal });
  };

  onToggleShowNotificationsModal = (ShowNotificationsModal) => {
    this.setState({ ShowNotificationsModal });
  };

  onToggleShowMultiActionsModal = (ShowMultiActionsModal) => {
    this.setState({ ShowMultiActionsModal });
  };

  renderHeader = () => {
    var {
      IsHeaderMobile,
      IsHeaderMobileTwoRows,
      UnreadNotificationCount,
      UserDetails,
    } = this.props;
    var { ShowMobileDropdownMenu } = this.state;

    return (
      <HeaderContainer className="HeaderContainer">
        <TopHeaderSection className="TopHeaderSection">
          <LeftHeaderContainer className="LeftHeaderContainer">
            {IsHeaderMobile && this.renderLogo()}

            {!UserDetails.OrganizationId && (
              <>
                <SearchClientsContainer className="SearchClientsContainer">
                  <HeaderSAYT />
                </SearchClientsContainer>
                {/* {!IsHeaderMobileTwoRows && <ButtonGroup Buttons={[ { OnClick: () => this.onToggleShowMultiActionsModal(true), Title: `${t('ptadmin_multi_client_actions')}`} ]} NoMargin NotTouching />} */}
              </>
            )}
          </LeftHeaderContainer>
          <RightHeaderContainer className="RightHeaderContainer">
            {IsHeaderMobile ? (
              <HeaderMenuIcon>
                <MenuIcon
                  ImageSrc={
                    ShowMobileDropdownMenu ? Hamburger_Close : Hamburger
                  }
                  ImageSrcHover={
                    ShowMobileDropdownMenu
                      ? Hamburger_Close_Hover
                      : Hamburger_Hover
                  }
                  OnClick={this.onToggleMobileDropdownMenu}
                />
              </HeaderMenuIcon>
            ) : (
              <>
                {this.renderMenuLink({
                  LinkUrl: SUPPORT_LINK,
                  SVGIcon: SupportSVG,
                  LinkType: 'external',
                  Type: 'svg'
                })}
                {this.renderMenuLink({
                  BetaAdmin: 'chat',
                  Icon: Messages,
                  IconHover: Messages_Hover,
                  LinkString: "ptadmin_navigation_messages",
                  LinkUrl: "messages",
                  NotificationCount: 0,
                })}
                {this.renderMenuLink({
                  Icon: Notifications,
                  IconHover: Notifications_Hover,
                  LinkUrl: "notifications",
                  LinkString: "ptadmin_navigation_notifications",
                  IsModal: true,
                  NotificationCount: UnreadNotificationCount,
                })}
                {this.renderProfilePhoto()}
              </>
            )}
          </RightHeaderContainer>
        </TopHeaderSection>
        {IsHeaderMobile && ShowMobileDropdownMenu && (
          <BottomHeaderSection
            className="BottomHeaderSection"
            IsHeaderMobileTwoRows={IsHeaderMobileTwoRows}
          >
            {this.renderMobileDropdownMenu()}
          </BottomHeaderSection>
        )}
      </HeaderContainer>
    );
  };

  renderLogo = () => {
    var { IsHeaderMobile } = this.props;

    return (
      <AbelLogoContainer className="AbelLogoContainer">
        <AbelLogoImageContainer className="clickable" to="/clients">
          <AbelLogo
            src={AbelIcon}
            alt="ABEL - The Digital Personal Trainer"
            IsHeaderMobile={IsHeaderMobile}
          />
        </AbelLogoImageContainer>
        <AbelNameContainer to="/clients">
          <PageText
            FontFamily="semibold"
            FontSize={IsHeaderMobile ? "medium-1half" : "medium-3"}
            JustifyContent="flex-start"
            NoMargin
            Text="ABEL"
          />

          <PageText
            FontFamily="medium"
            FontSize={IsHeaderMobile ? "small" : "medium-1half"}
            JustifyContent="flex-start"
            NoMargin
            Text="Admin"
          />
        </AbelNameContainer>
      </AbelLogoContainer>
    );
  };

  renderMenuLink = ({
    BetaAdmin,
    Icon,
    IconHover,
    IsModal,
    LinkString,
    LinkUrl,
    NotificationCount,
    SVGIcon,
    LinkType,
    Type
  }) => {
    if (Type === 'svg') {
      return (
        <MobileIconContainer
          className="MobileIconContainer"
          onClick={() => this.onClickMobileMenuItem({ BetaAdmin, Link: LinkUrl, LinkType })}
          Selected={false}
          Type={Type}
        >
          <SVGIcon />
        </MobileIconContainer>
      );
    }

    var { t } = this.props;
    var BetaLink = BetaAdmin ? BETA_SITE_URL + `/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=${BetaAdmin}` : null;

    return (
      <MenuLink
        href={IsModal ? null : BetaAdmin ? BetaLink : `/${LinkUrl}`}
        onClick={
          IsModal
            ? () => this.onToggleShowNotificationsModal(true)
            : (event) => this.onClickMenuItem({ event, BetaAdmin, LinkUrl })
        }
      >
        {Icon ? (
          <HeaderMenuIcon>
            <MenuIcon ImageSrc={Icon} ImageSrcHover={IconHover || null} />
          </HeaderMenuIcon>
        ) : (
          `${t(LinkString)} `
        )}
        {!!+NotificationCount && (
          <NotificationCountContainer>
            {NotificationCount}
          </NotificationCountContainer>
        )}
      </MenuLink>
    );
  };

  renderMenuLinks = (Side) => {
    var { t } = this.props;
    var { UnreadNotificationCount } = this.props;

    var Menu = [
      {
        LinkUrl: SUPPORT_LINK,
        SVGIcon: SupportSVG,
        LinkType: 'external',
        Type: 'svg'
      },
      {
        BetaAdmin: 'chat',
        Icon: Messages,
        IconHover: Messages_Hover,
        LinkUrl: "messages",
        LinkString: "ptadmin_navigation_messages",
        NotificationCount: 0,
      },
      {
        Icon: Notifications,
        IconHover: Notifications_Hover,
        LinkUrl: "notifications",
        LinkString: "ptadmin_navigation_notifications",
        IsModal: true,
        NotificationCount: UnreadNotificationCount,
      },
    ];

    return (
      <MenuContainer>
        {Menu.map(
          ({
            BetaAdmin,
            Icon,
            IconHover,
            IsModal,
            LinkUrl,
            LinkString,
            NotificationCount,
            SVGIcon,
            LinkType,
            Type
          }) => {
            if (Type === 'svg') {
              return (
                <MobileIconContainer
                  className="MobileIconContainer"
                  onClick={() => this.onClickMobileMenuItem({ BetaAdmin, Link: LinkUrl, LinkType })}
                  Selected={false}
                  Type={Type}
                >
                  <SVGIcon />
                </MobileIconContainer>
              );
            }

            var ActiveLink = (LinkUrl === "" && history.location.pathname.includes(`/client`)) || (LinkUrl !== "" && history.location.pathname.includes(`/${LinkUrl}`));
            var BetaLink = BetaAdmin ? BETA_SITE_URL + `/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=${BetaAdmin}` : null;

            return (
              <MenuLink
                key={LinkString}
                ActiveLink={ActiveLink}
                href={IsModal ? null : BetaAdmin ? BetaLink : `/${LinkUrl}`}
                onClick={IsModal ? () => this.onToggleShowNotificationsModal(true) : (event) => this.onClickMenuItem({ event, BetaAdmin, LinkUrl }) }
              >
                {Icon ? (
                  <HeaderMenuIcon>
                    <MenuIcon
                      ImageSrc={Icon}
                      ImageSrcHover={IconHover || null}
                    />
                  </HeaderMenuIcon>
                ) : (
                  `${t(LinkString)} `
                )}
                {!!+NotificationCount && (
                  <NotificationCountContainer>
                    {NotificationCount}
                  </NotificationCountContainer>
                )}
              </MenuLink>
            );
          }
        )}
      </MenuContainer>
    );
  };

  renderMobileDropdownMenu = () => {
    var {
      IsHeaderMobileTwoRows,
      UserDetails: { Gender, ProfilePhotoUrl },
    } = this.props;

    const LeftNavMenu = [
      // { Id: 1, Icon: Dashboard, IconHover: Dashboard_Hover, Link: '/' },
      { Id: 2, SVGIcon: ClientsSVG, Link: "clients", Type: 'svg', BetaAdmin: 'clients' },
      { Id: 3, SVGIcon: SalesSVG, Link: "sales", Type: 'svg' },
      { Id: 4, SVGIcon: LibrarySVG, Link: "library", Type: 'svg' },
      { Id: 5, SVGIcon: WorkoutLibrarySVG, Link: "library-workouts", Type: 'svg' },
      // { Id: 5, Icon: Journal, Link: '/', Type: 'png' },
      { Id: 6, SVGIcon: LearnABELSVG, Link: "manual", Type: 'svg', BetaAdmin: 'learn-abel' },
      // { Id: 7, Icon: SessionLibrary, Link: '/' }
      { Id: 8, SVGIcon: BookingSVG, Link: "video-calls", Type: 'svg', BetaAdmin: 'booking?tab=booking' },
    ];

    var IconMenu = [
      { Id: 1, SVGIcon: SupportSVG, LinkType: 'external', Link: SUPPORT_LINK, Type: 'svg' },
      { Id: 2, Icon: Messages, IconHover: Messages_Hover, BetaAdmin: 'chat', Link: "messages" },
      { Id: 3, Icon: Notifications, IconHover: Notifications_Hover, Link: "notifications" },
      { Id: 4, Icon: Settings, IconHover: Settings_Hover, Link: "account" }
    ];

    return (
      <>
        <MobileMenuLeftContainer
          className="MobileMenuLeftContainer"
          IsHeaderMobileTwoRows={IsHeaderMobileTwoRows}
        >
          {LeftNavMenu.map(({ BetaAdmin, Id, Icon, IconHover, Link, OnClick, SVGIcon, Type }) => {
            var Selected = history.location.pathname === `/${Link}`;

            if (Type === 'svg') {
              return (
                <MobileIconContainer
                  className="MobileIconContainer"
                  key={Id}
                  onClick={() => this.onClickMobileMenuItem({ BetaAdmin, Link, OnClick })}
                  Selected={Selected}
                  Type={Type}
                >
                  <SVGIcon />
                </MobileIconContainer>
              );
            }

            return (
              <MobileIconContainer className="MobileIconContainer" key={Id} Type={Type}>
                <MobileIcon
                  ImageSrc={Selected ? IconHover : Icon}
                  ImageSrcHover={IconHover || null}
                  OnClick={() => this.onClickMobileMenuItem({ Link, OnClick })}
                />
              </MobileIconContainer>
            );
          })}
        </MobileMenuLeftContainer>
        <MobileMenuRightContainer
          className="MobileMenuRightContainer"
          IsHeaderMobileTwoRows={IsHeaderMobileTwoRows}
        >
          {/* {IsHeaderMobileTwoRows && <ButtonGroup Buttons={[ { OnClick: () => this.onToggleShowMultiActionsModal(true), Title: `${t('ptadmin_multi_client_actions')}`} ]} NoMargin NotTouching />} */}
          <MobileIconsContainer className="MobileIconsContainer">
            {IconMenu.map(({ BetaAdmin, Id, Icon, IconHover, Link, LinkType, OnClick, SVGIcon, Type }) => {
              if (Type === 'svg') {
                return (
                  <MobileIconContainer
                    className="MobileIconContainer"
                    key={Id}
                    onClick={() => this.onClickMobileMenuItem({ BetaAdmin, Link, LinkType, OnClick })}
                    Selected={false}
                    Type={Type}
                  >
                    <SVGIcon />
                  </MobileIconContainer>
                );
              }

              return (
                <MobileIconContainer className="MobileIconContainer" key={Id}>
                  <MobileIcon
                    ImageSrc={Icon}
                    ImageSrcHover={IconHover || null}
                    OnClick={() =>
                      this.onClickMobileMenuItem({ BetaAdmin, Link, OnClick })
                    }
                  />
                </MobileIconContainer>
              );
            })}
          </MobileIconsContainer>
          <MobileMenuTopContainer1 className="MobileMenuTopContainer1">
            <MobileProfilePhoto
              OnClick={() => this.onClickMobileMenuItem({ Link: "account" })}
              Size="small-3"
              Source={ProfilePhotoUrl}
              Sex={Gender}
            />
          </MobileMenuTopContainer1>
        </MobileMenuRightContainer>
      </>
    );
  };

  renderModal = () => {
    var { t } = this.props;
    var { ShowMenuModal } = this.state;

    var MenuItems = [
      { Name: t("Profile_Log_Out"), OnClick: this.onClickLogout },
    ];

    if (ShowMenuModal)
      return (
        <ModalMenu
          MenuItems={MenuItems}
          Show={ShowMenuModal}
          OnHide={() => this.onToggleShowMenuModal(false)}
        />
      );
  };

  renderMultiActionsInfo = () => {
    var { t } = this.props;

    var MealButtons = [
      { Link: "/add-dish", Title: t("ptadmin_menu_addsingledish") },
      { Link: "/add-meal-template", Title: t("ptadmin_menu_addmealtemplate") },
    ];

    var WorkoutButtons = [
      //Link:  { 'add-additional-activity', Title: t('add_activity') },
      { Link: "/add-workout", Title: t("WorkoutPlan_Create_workout") },
      {
        Link: "/add-single-generated-workout",
        Title: t("add_single_generated_workout"),
      },
      { Link: "/add-workout-template", Title: t("add_workout_template") },
      { Link: "/add-workout-plan", Title: t("MealPlan_create_new_workout") },
    ];

    return (
      <MultiActionsInfoContainer className="MultiActionsInfoContainer">
        <ActionsRow>
          <ActionsRowHeader>
            {t("ptadmin_contenttype_header_workouts")}
          </ActionsRowHeader>
          <ActionsButtonsContainer>
            {WorkoutButtons.map(({ Link, Title }, ButtonIndex) => (
              <ActionsButtonContainer
                key={ButtonIndex}
                onClick={this.onClickMultiActionLink}
                to={Link}
              >
                {Title}
              </ActionsButtonContainer>
            ))}
          </ActionsButtonsContainer>
        </ActionsRow>
        <ActionsRow>
          <ActionsRowHeader>
            {t("ptadmin_contenttype_header_meals")}
          </ActionsRowHeader>
          <ActionsButtonsContainer>
            {MealButtons.map(({ Link, Title }, ButtonIndex) => (
              <ActionsButtonContainer
                key={ButtonIndex}
                onClick={this.onClickMultiActionLink}
                to={Link}
              >
                {Title}
              </ActionsButtonContainer>
            ))}
          </ActionsButtonsContainer>
        </ActionsRow>
      </MultiActionsInfoContainer>
    );
  };

  renderMultiActionsInfoModal = () => {
    var { ShowMultiActionsModal } = this.state;

    if (ShowMultiActionsModal) {
      return (
        <Info
          InformationRenderer={this.renderMultiActionsInfo}
          OnHide={() => this.onToggleShowMultiActionsModal(false)}
          Show={ShowMultiActionsModal}
        />
      );
    }
  };

  renderNotificationsModal = () => {
    var { ShowNotificationsModal } = this.state;

    if (ShowNotificationsModal)
      return (
        <NotificationsModal
          Show={ShowNotificationsModal}
          OnHide={() => this.onToggleShowNotificationsModal(false)}
        />
      );
  };

  renderProfilePhoto = () => {
    var { Gender, ProfilePhotoUrl } = this.props.UserDetails;

    return (
      <ProfilePhotoContainer>
        <ProfilePhoto
          OnClick={() => this.onClickMenuItem({ LinkUrl: "account" })}
          Size="medium"
          Source={ProfilePhotoUrl}
          Sex={Gender}
        />
      </ProfilePhotoContainer>
    );
  };

  render() {
    var { IsHeaderMobile } = this.props;

    return (
      <>
        <AbelHeader className="AbelHeader" IsHeaderMobile={IsHeaderMobile}>
          <AbelHeaderContainer IsHeaderMobile={IsHeaderMobile}>
            {this.renderHeader()}
          </AbelHeaderContainer>
        </AbelHeader>

        {this.renderModal()}
        {this.renderMultiActionsInfoModal()}
        {this.renderNotificationsModal()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Device: state.Window.Device,
    IsHeaderMobile: state.Window.IsHeaderMobile,
    IsHeaderMobileTwoRows: state.Window.IsHeaderMobileTwoRows,
    IsVerySmall: state.Window.IsVerySmall,

    IsLoggedIn: state.Auth.IsLoggedIn,
    UserDetails: state.Auth.UserDetails,

    UnreadNotificationCount: state.Notifications.UnreadNotificationCount,

    TryingLoginUser: state.Auth.TryingLoginUser,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { LogoutUser })(Header)
);
