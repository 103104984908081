import React from 'react';
import PropTypes from 'prop-types';

import { IconContainer, StyledIcon } from './styles';

import NoImage from '../../Assets/Icons/NoImage.png';

class Icon extends React.Component {
    state = { IsHovering: false };

    handleImageError = event => {
        console.log('image error');
        event.target.src = NoImage;
    }

    onToggleIsHovering = IsHovering => {
        this.setState({ IsHovering });
    }

    render() {
        var { className, ImageSrc, ImageSrcHover, OnClick, OnMouseEnter, OnMouseLeave } = this.props;
        var { IsHovering } = this.state;

        var src = (IsHovering && ImageSrcHover) ? ImageSrcHover : ImageSrc;
        
        return (
            <IconContainer
                className={className}
                onMouseEnter={OnMouseEnter ? OnMouseEnter : () => this.onToggleIsHovering(true)}
                onMouseLeave={OnMouseLeave ? OnMouseLeave : () => this.onToggleIsHovering(false)}
            >
                <StyledIcon
                    alt="ABEL Technologies"
                    onClick={OnClick ? OnClick : () => null}
                    onError={this.handleImageError}
                    src={src}
                />
            </IconContainer>
        );
    }
}

Icon.propTypes = {
    ImageSrc: PropTypes.string.isRequired,
    ImageSrcHover: PropTypes.string,
    OnClick: PropTypes.func,
    OnMouseEnter: PropTypes.func,
    OnMouseLeave: PropTypes.func,
}

Icon.defaultProps = {
    ImageSrc: NoImage,
    OnMouseEnter: null,
    OnMouseLeave: null
}

export default Icon;