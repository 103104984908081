import React from "react";
import Hls from "hls.js";

export default class VideoPlayer extends React.Component {
    state = {};

    componentDidMount() {
        const video = this.player;
        const hls = new Hls();
        const url = this.props.VideoUrl;
    
        hls.loadSource(url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function() { video.play(); });
    }

    render() {
        return <video className="videoCanvas" ref={player => (this.player = player)} autoPlay={true} />;
    }
}