import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearNutrition, GetNutrition } from '../../Services/Actions';
import moment from 'moment';

import  { CategoryContainer, CategoryTitleContainer, ChartContainer, ChartDetailContainer, MacroContainer, MacroText, NutrientContainer, NutritionChartContainer, ResponsiveColumn, ResponsiveColumns, StyledPieChart } from './styles';

import AbelModal from '../../Components/Modal';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Edit from '../../Assets/Icons/Edit_Dark.png';
import DeleteX from '../../Assets/Icons/Delete_X.png';

class NutritionFacts extends React.Component {
    _isMounted = false;

    state = {
        Loading: true
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientId, DayDate, DishId, FoodId, FromDate, ToDate, uId, UserId } = this.props;

        this.props.GetNutrition({ ClientId, DayDate, DishId, FoodId, FromDate, ToDate, uId, UserId }).then(() => {
            if (this._isMounted) this.setState({ Loading: false });
            else this.props.OnHide();
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.ClearNutrition();
    }

    renderFoodOptions = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { OnDeleteFood, OnEditFood } = this.props;

        var Buttons = [];
        Buttons.push({ Color: 'blue-astronaut', ColorHover: 'blue-astronaut', FontFamily: 'semibold', Icon: Edit, IconPosition: 'left', OnClick: OnEditFood, Title: t('edit_with_first_char_uppercase') });
        Buttons.push({ Color: 'red-bittersweet', ColorHover: 'red-bittersweet', FontFamily: 'semibold', Icon: DeleteX, IconPosition: 'left', OnClick: OnDeleteFood, Title: t('delete_with_first_char_uppercase') });

        return (
            <>
                <Spacer Size="medium" />
                
                <ButtonGroup
                    Buttons={Buttons}
                    ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />
            </>
        );
    }

    renderMacrosChart = () => {
        var { lstChart } = this.props.NutritionData;

        return (
            <NutritionChartContainer className="NutritionChartContainer">
                <ChartContainer>
                    <StyledPieChart
                        data={[
                            { title: "1", value: Number(lstChart[0].Percentage), color: `#${lstChart[0].BackgroundHexColor}` },
                            { title: "1", value: Number(lstChart[1].Percentage), color: `#${lstChart[1].BackgroundHexColor}` },
                            { title: "1", value: Number(lstChart[2].Percentage), color: `#${lstChart[2].BackgroundHexColor}` },
                        ]}
                        lineWidth={50}
                    />
                </ChartContainer>
                <Spacer Size="extra-small" />
                <ChartDetailContainer className="ChartDetailContainer">
                    {
                        lstChart.map(({ BackgroundHexColor, NutritionFactsCategoryId, NutrientName, Percentage}) => {
                            return (
                                <MacroContainer key={NutritionFactsCategoryId} className="MacroContainer">
                                    <MacroText ElementType="span" FontFamily="bold" FontSize="medium-1" NoMargin Text={NutrientName} TextColor={BackgroundHexColor} />
                                    &nbsp;
                                    <MacroText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={`${Percentage}%`} TextColor={BackgroundHexColor} />
                                </MacroContainer>
                            );
                        })
                    }
                </ChartDetailContainer>
            </NutritionChartContainer>
        );
    }

    renderNutritionFacts = () => {
        return (
            <ResponsiveColumns>
                <ResponsiveColumn>
                    {
                        this.props.NutritionData.lstCategories.map(({ BackgroundHexColor, HasValue, lstNutrients, NutritionFactsCategoryId, NutritionFactsName, NutritionValueTotal, TextHexColor, Unit }, CategoryIndex) => {
                            if (CategoryIndex > 3) return null;

                            return (
                                <CategoryContainer key={NutritionFactsCategoryId} BackgroundColor={BackgroundHexColor} TextColor={TextHexColor}>
                                    <CategoryTitleContainer>
                                        <PageText FontFamily="bold" FontSize="small" NoMargin Text={NutritionFactsName} />
                                        {
                                            Number(HasValue) ?
                                            <PageText FontFamily="bold" FontSize="small" NoMargin Text={`${NutritionValueTotal} ${Unit}`} />
                                        :
                                            null
                                        }
                                    </CategoryTitleContainer>
                                    {
                                        lstNutrients.length > 1 ?
                                        lstNutrients.map(({ CalculatedNutrition, NutrientId, NutrientName, Unit: NutrientUnit }, index) => {
                                            return index === 0 && NutrientName === NutritionFactsName ?
                                            null
                                        :
                                            <NutrientContainer key={NutrientId}>
                                                <PageText FontFamily="medium" FontSize="small" NoMargin Text={NutrientName} />
                                                <PageText FontFamily="medium" FontSize="small" NoMargin Text={`${CalculatedNutrition} ${NutrientUnit}`} />
                                            </NutrientContainer>
                                        })
                                    :
                                        null
                                    }
                                </CategoryContainer>
                            );
                        })
                    }
                </ResponsiveColumn>
                <ResponsiveColumn>
                    {
                        this.props.NutritionData.lstCategories.map(({ BackgroundHexColor, HasValue, lstNutrients, NutritionFactsCategoryId, NutritionFactsName, NutritionValueTotal, TextHexColor, Unit }, CategoryIndex) => {
                            if (CategoryIndex <= 3) return null;
                            
                            return (
                                <CategoryContainer key={NutritionFactsCategoryId} BackgroundColor={BackgroundHexColor} TextColor={TextHexColor}>
                                    <CategoryTitleContainer>
                                        <PageText FontFamily="bold" FontSize="small" NoMargin Text={NutritionFactsName} />
                                        {
                                            Number(HasValue) ?
                                            <PageText FontFamily="bold" FontSize="small" NoMargin Text={`${NutritionValueTotal} ${Unit}`} />
                                        :
                                            null
                                        }
                                    </CategoryTitleContainer>
                                    {
                                        lstNutrients.length > 1 ?
                                        lstNutrients.map(({ CalculatedNutrition, NutrientId, NutrientName, Unit: NutrientUnit }, index) => {
                                            return index === 0 && NutrientName === NutritionFactsName ?
                                            null
                                        :
                                            <NutrientContainer key={NutrientId}>
                                                <PageText FontFamily="medium" FontSize="small" NoMargin Text={NutrientName} />
                                                <PageText FontFamily="medium" FontSize="small" NoMargin Text={`${CalculatedNutrition} ${NutrientUnit}`} />
                                            </NutrientContainer>
                                        })
                                    :
                                        null
                                    }
                                </CategoryContainer>
                            );
                        })
                    }
                </ResponsiveColumn>
            </ResponsiveColumns>
        );
    }

    renderModalBody = () => {
        if (this.state.Loading || this.props.TryingGetNutrition) return <Loading />;

        var { t } = this.props;
        var { DayDate, ShowFoodOptions } = this.props;

        return (
            <>
                {
                    DayDate &&
                    <>
                        <PageText FontFamily="bold" FontSize="medium-3" NoMargin Text={moment(DayDate).format('[WeeklyViewDayHeader]').toUpperCase()} />
                
                        <Spacer Size="extra-small" />
                    </>
                }

                <PageText FontFamily="bold" FontSize="medium-3" NoMargin Text={t('MealPlan_nutrition_facts_title')} />
                
                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1half" NoMargin Text={t('MealPlan_percent_calories_from_collons')} />

                <Spacer Size="medium" />

                {this.renderMacrosChart()}

                <Spacer Size="medium" />

                {this.renderNutritionFacts()}

                {ShowFoodOptions && this.renderFoodOptions()}
            </>
        );
    }

    render() {
        var { OnHide, Show } = this.props;

        return (
            <AbelModal
                BottomButton="cancel"
                BottomButtonOnClick={OnHide}
                GA_PathName="NutritionFacts"
                Show={Show}
                Size="medium"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

NutritionFacts.propTypes = {
    ClientId: PropTypes.number,
    DayDate: PropTypes.string,
    DishId: PropTypes.number,
    FoodId: PropTypes.number,
    FromDate: PropTypes.string,
    OnDeleteFood: PropTypes.func,
    OnEditFood: PropTypes.func,
    OnHide: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired,
    ShowFoodOptions: PropTypes.bool,
    ToDate: PropTypes.string,
    uId: PropTypes.number,
    UserId: PropTypes.number
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        NutritionData: state.Nutrition.NutritionData,

        TryingGetNutrition: state.Nutrition.TryingGetNutrition,
        TryingGetNutritionError: state.Nutrition.TryingGetNutritionError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearNutrition, GetNutrition } )(NutritionFacts));