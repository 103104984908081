import styled from 'styled-components';

import PieChart from 'react-minimal-pie-chart';

import PageText from '../../Components/Text/PageText';

export const CategoryContainer = styled.div`
    background-color: #${props => props.BackgroundColor};
    color: #${props => props.TextColor};

    :not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const CategoryTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
`;

export const ChartContainer = styled.div`
    @media (min-width: 400px) {
        margin-right: 25px;
    }
`;

export const ChartDetailContainer = styled.div``;

export const MacroContainer = styled.div`
    :not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const MacroText = styled(PageText)`
    color: #${props => props.TextColor};
`;

export const NutrientContainer = styled.div`
    border-top: 0.1px solid #FFFFFF;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
`;

export const NutritionChartContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 399px) {
        flex-direction: column;
    }
`;

export const ResponsiveColumn = styled.div`
`;

export const ResponsiveColumns = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
`;

export const StyledPieChart = styled(PieChart)`
    height: 125px;
    width: 125px;
`;