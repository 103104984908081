import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { SearchGroupMembers } from '../../Services/Actions';

import  { StyledPaginatedList } from './styles';

import Accordion from '../../Components/Accordion';
import ChooseClientHeader from '../../Components/Accordion/ChooseClientHeader';
import { GroupMemberListItem } from '../../Components/Lists/ListItems';

class Group extends React.PureComponent {
    _isMounted = false;

    state = {
        GetOtherGroups: this.props.GetOtherGroups,
        GroupMembers: [],
        IsMoreResults: null,
        TotalRecords: null,
        PageNo: 1,
        PageSize: 30
    };

    componentDidMount() {
        this._isMounted = true;

        var { GroupMembers, IsMoreResults, TotalRecords } = this.props.Group.GroupMembers;

        this.setState({ GroupMembers, IsMoreResults, TotalRecords });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onLoadExistingGroupMembers = Increment => {
        var { GetOtherGroups, PageNo, PageSize, SearchValue } = this.state;
        var { GroupId, GroupType } = this.props.Group;

        if (Increment) PageNo = PageNo + 1;
        else PageNo = 1;

        this.setState({ PageNo },
            () => this.props.SearchGroupMembers({ AddToExisting: Increment, GetOtherGroups, GroupId, GroupType, PageNo, PageSize, ReturnData: true, UserSearch: SearchValue }).then(({ GroupMembers, IsMoreResults, TotalRecords }) => {
                if (this._isMounted) {
                    var NewGroupMembers = [];
                    
                    if (Increment) NewGroupMembers = [ ...this.state.GroupMembers, ...GroupMembers ];
                    else NewGroupMembers = [ ...GroupMembers ];
                
                    this.props.OnLoadedNewUsers(GroupMembers);
                    this.setState({ GroupMembers: NewGroupMembers, IsMoreResults, TotalRecords, Loading: false });
                }
            })
        );
    }

    render() {
        var { t } = this.props;

        var { GroupMembers, IsMoreResults, PageNo, PageSize, TotalRecords } = this.state;
        var { Group: { GroupId, GroupName, GroupType, NumMembers }, HideSelectAll, OnSelectGroup, OnSelectUser, Selected, SelectedExcept, SelectedMembers, SelectedRemoveMembers, ToggleOpen } = this.props;

        var BorderColor = Selected ? 'var(--blue-abel)' : 'var(--white-concrete)';

        var HideToggle = +NumMembers === 0;

        return (
            <Accordion
                BackgroundColor="var(--white)"
                BorderColor={BorderColor}
                HeaderData={{ GroupId, GroupName, GroupType, HideSelectAll, HideToggle, NumClients: NumMembers, OnSelectGroup: () => OnSelectGroup(GroupId), SelectedExcept, SelectedIndex: Selected ? 1 : -1 }}
                HeaderRenderer={ChooseClientHeader}
                HideToggle={HideToggle}
                Text={GroupName}
                ToggleOpen={ToggleOpen}
            >
                <StyledPaginatedList
                    CompletedFirstSearch={true}
                    HideNoResults
                    HideViewCount
                    IsLoading={false}
                    ItemDescriptionType={t('ptadmin_navigation_clients').toLowerCase()}
                    ItemId="UserId"
                    ItemName="UserName"
                    ItemProps={{
                        ForceSelected: Selected,
                        HasBorder: true,
                        OnSelectMember: User => OnSelectUser(User),
                        SelectedRemoveMembers
                    }}
                    ItemRenderer={GroupMemberListItem}
                    Items={GroupMembers}
                    HasMoreItems={!!+IsMoreResults}
                    LoadMore={() => this.onLoadExistingGroupMembers(true)}
                    NewSearch={false}
                    NoItemsText={t('search_noresults')}
                    PageNo={PageNo}
                    PageSize={PageSize}
                    SelectedItemIds={SelectedMembers}
                    TotalRecords={TotalRecords}
                />
            </Accordion>
        );
    }
}

Group.propTypes = {
    GetOtherGroups: PropTypes.number.isRequired,
    Group: PropTypes.object.isRequired,
    HideSelectAll: PropTypes.bool,
    OnLoadedNewUsers: PropTypes.func.isRequired,
    OnSelectGroup: PropTypes.func.isRequired,
    OnSelectUser: PropTypes.func.isRequired,
    Selected: PropTypes.bool.isRequired,
    SelectedMembers: PropTypes.array,
    SelectedRemoveMembers: PropTypes.array,
    SelectedExcept: PropTypes.bool.isRequired,
    ToggleOpen: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        TryingSearchGroupMembers: state.Group.TryingSearchGroupMembers,
        TryingSearchGroupMembersError: state.Group.TryingSearchGroupMembersError
    };
};

export default withTranslation()(connect(mapStateToProps, { SearchGroupMembers } )(Group));