import React from 'react';
import PropTypes from 'prop-types';

import Emoji from "react-emoji-render";

import { Container, TitleText, TitleTextSpan } from './styles';

class PageText extends React.Component {
    render() {
        var { className, ContainerWidth, ElementType, FontColor, FontColorHex, FontColorHover, FontFamily, FontFamilyHover, FontSize, JustifyContent, Lowercase, NoMargin, NoWrap, OnClick, OnClickTarget, RenderEmoji, Selectable, SelectedOptions, Strikethrough, Text, TextAlign, Underline, Uppercase, WhiteSpace, WordBreak } = this.props;

        if (this.props.Console) console.log('Props: ', this.props);

        if (ElementType && ElementType.toLowerCase() === 'span') {
            return (
                <TitleTextSpan
                    className={className}
                    Clickable={OnClick ? true : false}
                    FontColor={FontColor}
                    FontColorHex={FontColorHex}
                    FontColorHover={FontColorHover}
                    FontFamily={FontFamily}
                    FontFamilyHover={FontFamilyHover}
                    FontSize={FontSize}
                    Lowercase={Lowercase}
                    NoWrap={NoWrap}
                    onClick={OnClick}
                    Strikethrough={Strikethrough}
                    Underline={Underline}
                    Uppercase={Uppercase}
                    WhiteSpace={WhiteSpace}
                    WordBreak={WordBreak}
                >
                    {RenderEmoji ? <Emoji text={Text || ''} /> : Text}
                </TitleTextSpan>
            );
        }

        return (
            <Container
                className={className}
                ContainerWidth={ContainerWidth}
                JustifyContent={JustifyContent}
                NoMargin={NoMargin}
                NoWrap={NoWrap}
                onClick={OnClickTarget === 'container' && OnClick ? OnClick : null}
                TextAlign={TextAlign}
                WhiteSpace={WhiteSpace}
                WordBreak={WordBreak}
            >
                <TitleText
                    Clickable={OnClick ? true : false}
                    FontColor={FontColor}
                    FontColorHex={FontColorHex}
                    FontColorHover={FontColorHover}
                    FontFamily={FontFamily}
                    FontFamilyHover={FontFamilyHover}
                    FontSize={FontSize}
                    Lowercase={Lowercase}
                    onClick={OnClickTarget !== 'container' && OnClick ? OnClick : null}
                    Selectable={Selectable}
                    SelectedOptions={SelectedOptions}
                    Strikethrough={Strikethrough}
                    Underline={Underline}
                    Uppercase={Uppercase}
                >
                    {RenderEmoji ? <Emoji text={Text || ''} /> : Text}
                </TitleText>
            </Container>
        );
    }
}

PageText.propTypes = {
    ContainerWidth: PropTypes.string,
    ElementType: PropTypes.string,
    FontColor: PropTypes.string,
    FontColorHex: PropTypes.string,
    FontColorHover: PropTypes.string,
    FontFamily: PropTypes.string,
    FontFamilyHover: PropTypes.string,
    FontSize: PropTypes.string,
    JustifyContent: PropTypes.string,
    Lowercase: PropTypes.bool,
    NoMargin: PropTypes.bool,
    NoWrap: PropTypes.bool,
    OnClick: PropTypes.func,
    OnClickTarget: PropTypes.string,
    RenderEmoji: PropTypes.bool,
    Selectable: PropTypes.bool,
    SelectedOptions: PropTypes.object,
    Text: PropTypes.string.isRequired,
    TextAlign: PropTypes.string,
    Underline: PropTypes.bool,
    Uppercase: PropTypes.bool,
    WhiteSpace: PropTypes.string,
    WordBreak: PropTypes.string
};

PageText.defaultProps = {
    Selectable: false
}

export default PageText;