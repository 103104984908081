import styled from 'styled-components';

import Input from '../../Components/Form/Input';

import Checkmark from '../../Assets/Icons/Checkmark_Selected.png';

export const CircleDiv = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 15px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    display: block;
    height: 15px;
    margin-right: 5px;
    margin-top: 3px;
    width: 15px;
    z-index: 1;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 15px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const ForgotPasswordPicture = styled.img`
    height: 150px;
    width: 150px;
`;

export const ForgotPasswordPictureContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15px;
`;

export const ShowPasswordsContainer = styled.div`
    display: flex;
`;

export const StyledInput = styled(Input)`
    > input {
        border-radius: 10px;
    }
`;