import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DeleteOrganizationBranch } from '../../Services/Actions';
import Select from 'react-select';

import { BodyContainer, BottomButton, CenterDiv } from './styles';

import AbelModal from '../../Components/Modal';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

const DropdownStyles = {
    control: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '400px' }),
    input: styles => ({ ...styles, maxWidth: '100%', width: '400px' }),
    menu: styles => ({ ...styles, maxWidth: '100%', width: '400px' }),
    option: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '400px' })
};

class DeleteBranch extends React.Component {
    _isMounted = false;

    state = { NewOrganizationBranchId: null, NewOrganizationBranchName: '', NeedReplacement: false };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChangeNewOrganizationBranch = ({ BranchId: NewOrganizationBranchId, BranchName: NewOrganizationBranchName }) => {
        this.setState({ NewOrganizationBranchId, NewOrganizationBranchName });
    }

    onClickSubmit = () => {
        var { BranchId: OrganizationBranchId, BranchIndex, RegionIndex } = this.props.BranchData;
        var { NewOrganizationBranchId } = this.state;

        this.props.DeleteOrganizationBranch({ NewOrganizationBranchId, OrganizationBranchId, BranchIndex, RegionIndex }).then(({ LastBranch, NeedReplacement }) => {
            if (NeedReplacement) this.setState({ NeedReplacement });
            else if (LastBranch) this.props.ShowDeleteLastBranchWarning();
            else this.props.Refresh();
        });
    }

    renderBottomButton = () => {
        var { t } = this.props;
        var { NewOrganizationBranchId, NeedReplacement } = this.state;

        var Disabled = (!!NeedReplacement && !NewOrganizationBranchId) || this.props.TryingDeleteOrganizationBranch;

        var Buttons = [{ BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: this.onClickSubmit, Title: t('delete_with_first_char_uppercase') }];

        return <BottomButton Buttons={Buttons} NormalWhiteSpace />;
    }

    renderModalBody = () => {
        var { t } = this.props;
        var { BranchId } = this.props.BranchData;
        var { NewOrganizationBranchId, NewOrganizationBranchName, NeedReplacement } = this.state;

        var Branches = [];
        if (NeedReplacement) {
            var OrganizationRegionsAndBranches = this.props.OrganizationRegionsAndBranches;

            Branches = OrganizationRegionsAndBranches.flatMap(Region => Region.Branches).filter(Branch => Branch.BranchId !== BranchId) || [];
        }

        return (
            <BodyContainer>
                {this.props.TryingDeleteOrganizationBranch && <Loading />}

                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('income_branch_delete_warning')} TextAlign="center" />

                {
                    NeedReplacement && 
                    <>
                        <Spacer Size="small" />

                        <PageText FontColor="red-wellread" FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('income_branch_move_contracts_first')} />

                        <Spacer Size="small" />

                        <CenterDiv className="CenterDiv">
                            <Select
                                getOptionLabel={option => option.BranchName}
                                getOptionValue={option => option.BranchId}
                                maxMenuHeight={200}
                                onChange={this.onChangeNewOrganizationBranch}
                                options={Branches}
                                placeholder={"Branch"}
                                styles={DropdownStyles}
                                value={{ BranchId: NewOrganizationBranchId, BranchName: NewOrganizationBranchName }}
                            />
                        </CenterDiv>
                    </>    
                }
            </BodyContainer>
        );
    }

    render() {
        var { OnHide, Show } = this.props;

        return (
            <AbelModal
                BottomButtonRender={this.renderBottomButton}
                GA_PathName="DeleteBranch"
                NoMargin
                Show={Show}
                Size="small"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

DeleteBranch.propTypes = {
    BranchData: PropTypes.object.isRequired,
    OnHide: PropTypes.func.isRequired,
    Refresh: PropTypes.func,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        OrganizationRegionsAndBranches: state.Income.OrganizationRegionsAndBranches,

        TryingDeleteOrganizationBranch: state.Income.TryingDeleteOrganizationBranch,
        TryingDeleteOrganizationBranchError: state.Income.TryingDeleteOrganizationBranchError
    };
};

export default withTranslation()(connect(mapStateToProps, { DeleteOrganizationBranch } )(DeleteBranch));