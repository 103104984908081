import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddExistingDish, ChangeDishesCategory, ChangeSelectedDishesRating, ClearDishes, ClearSaveData, DeleteDishes, GetClientMealSettings, GetDishFilterSettings, SearchDishes_SAYT, SearchDishes } from '../../Services/Actions';
import history from '../../history';

import BottomBar from '../../Components/BottomBar';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Checkmark from '../../Components/Form/Checkmark';
import { DishListItem } from '../../Components/Lists/ListItems';
import Dropdown from '../../Components/Dropdown';
import { GA_PageView } from '../../Components/GoogleAnalytics';
// import Input from '../../Components/Form/Input';
import InputRange from '../../Components/Form/InputRange';
import Loading from '../../Components/Loading';
import LoadMore from '../../Components/Lists/LoadMore';
import PageText from '../../Components/Text/PageText';
import SAYT from '../../Components/Form/SAYT';
import Spacer from '../../Components/Spacer';
import Toggle from '../../Components/Form/Toggle';

import ChooseDishRating from '../../Modals/ChooseDishRating';
import DishDetail from '../../Modals/DishDetail';
import Info from '../../Modals/Info';
import PrivateDishCategories from '../../Modals/PrivateDishCategories';

import Blocked from '../../Assets/Icons/Ratings/Blocked_Transparent.png';
import FourStar from '../../Assets/Icons/Ratings/4Star_Transparent.png';
import FiveStar from '../../Assets/Icons/Ratings/5Star_Transparent.png';
import New from '../../Assets/Icons/Ratings/New_Transparent.png';
import Plus from '../../Assets/Icons/Plus.png';
import ThreeStar from '../../Assets/Icons/Ratings/3Star_Transparent.png';
import TwoStar from '../../Assets/Icons/Ratings/2Star_Transparent.png';

import styled from 'styled-components';
import { device } from '../../Config/device';

const AllFilters = styled.div``;

const BlockedRestrictionsContainer = styled.div``;

const BottomBarHeightAdjustment = styled.div`
    height: ${props => props.Height}px;
    width: 100%;
`;

const BottomBarItems = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    @media ${device.maxwidth.mobile} {
        :first-child {
            margin-bottom: 15px;
        }
    }

    @media ${device.minwidth.ipad} {
        justify-content: flex-start;

        :last-child {
            justify-content: flex-end;
        }
    }
`;

const DropdownFilters = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;

    @media ${device.maxwidth.ipad} {
        display: none;
    }
`;

const FilterRowSearchMadeBy = styled.div`
    align-items: unset;
    display: flex;
    flex-direction: column;

    @media (min-width: 975px) {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }
`;

const FiltersContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: space-between;
    margin-bottom: -15px;
    margin-left: -15px;
`;

const MadeByContainer = styled.div`
    align-items: center;
    background-color: var(--white);
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
    padding: 10px;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
        height: 45px;
    }
`;

const MadeByOption = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;

    @media (min-width: 400px) {
        margin-top: 0px;
        
        :not(:last-child) {
            margin-right: 30px;
        }
    }
`;

const MadeByOptions = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;

    @media (min-width: 400px) {
        flex-direction: row;
    }
    
    @media ${device.minwidth.ipad} {
        margin-left: 30px;
        margin-top: 0px;
    }
`;

const OwnerIcon = styled.span`
    background-color: var(--${props => props.BackgroundColor});
    border-radius: 10px;
    margin-left: 10px;
    height: 20px;
    width: 20px;
`;

const PrivateDishesContainer = styled.div`
    background-color: var(--white);
    display: grid;
    grid-gap: 40px 15px;
    grid-template-columns: repeat(1, 2fr);
    padding: 15px;        

    @media ${device.minwidth.mobile} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.minwidth.laptop} {
        grid-template-columns: repeat(4, 1fr);
    }
`;

const SelectedItems = styled(PageText)`
    background-color: var(--blue-abel);
    border-radius: 20px;
    padding: 5px 15px;
`;

const StyledBottomBar = styled(BottomBar)`
    > div {
        flex-direction: column;

        @media ${device.minwidth.ipad} {
            flex-direction: row;
        }
    }
`;

const StyledCheckmark = styled(Checkmark)`
    margin-right: 5px;
`;

const StyledClearFilters = styled(ButtonGroup)`
    height: 45px;
    margin-bottom: 15px;
    margin-left: 15px;
`;

const StyledCreateDish = styled(ButtonGroup)`
    height: 45px;
    margin-top: 10px;
`;

const StyledDropdown = styled(Dropdown)`
    margin-bottom: 15px;
    width: 100%;

    ${props => props.ForceClose === false ? 'z-index: 1001;' : null};

    ${
        props => props.IsMobile ?
        `
            > div { max-height: none; }
        `
    :
        `
            flex-grow: 1;
            ${props.Height ? `height: ${props.Height + 45 + 15}px;` : ''}
            margin-left: 15px;
            width: 20%;
        `
    }
`;

const StyledLoadMore = styled(LoadMore)`
    background-color: var(--white);
    padding: 15px;

    > div:first-child {
        display: grid;
        grid-gap: 40px 15px;
        // grid-template-columns: repeat(1, 2fr);
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

        // @media ${device.minwidth.mobile} {
        //     grid-template-columns: repeat(2, 1fr);
        // }

        // @media ${device.minwidth.ipad} {
        //     grid-template-columns: repeat(4, 1fr);
        // }

        // @media ${device.minwidth.laptop} {
        //     grid-template-columns: repeat(7, 1fr);
        // }
    }
`;

// const StyledSearchInput = styled(Input)`
//     flex: 1;
//     margin-bottom: 10px;

//     > input {
//         border: 2px solid var(--white-concrete);
//     }

//     @media (min-width: 975px) {
//         margin-right: 10px;
//     }

//     @media ${device.minwidth.laptop} {
//         margin-right: 60px;
//     }
// `;

const StyledToggleMobileFilters = styled(ButtonGroup)`
    height: 45px;
    margin-bottom: 15px;
    margin-left: 15px;

    @media ${device.minwidth.laptop} {
        display: none;
    }
`;

const ToggleContainer = styled.div`
    align-items: center;
    background-color: var(--white);
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px 20px;

    :first-child {
        margin-bottom: 10px;
    }

    @media ${device.minwidth.ipad} {
        :first-child {
            margin-bottom: 0px;
            margin-right: 10px;
        }
    }
`;

const ToggleInnerContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const TogglesContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
    }
`;

const ViewAllPageText = styled(PageText)`
    color: var(--blue-abel);
    text-decoration: underline;

    :hover {
        cursor: pointer;
    }
`;

const INITIAL_STATE = {
    DishTypeIds: [],
    IsBlockedAllergiesDietTypes: false,
    IsBlockedIngredients: false,
    LastSearchedValue: '',
    MadeBy: { Abel: true, Client: true, You: true }, MadeByAtMininum: false,
    MaxCookingTime: 60,
    MealTypeIds: [],
    MultiRatings: [],
    PageNo: 1, PageSize: 48,
    SearchValue: ''
}
const RatingOptions = [ { RatingId: 0, RatingName: New }, { RatingId: 5, RatingName: FiveStar }, { RatingId: 4, RatingName: FourStar }, { RatingId: 3, RatingName: ThreeStar }, { RatingId: 2, RatingName: TwoStar }, { RatingId: 1, RatingName: Blocked } ];

class Dishes extends React.Component {
    _isMounted = false;

    state = {
        ...INITIAL_STATE,
        ActiveSearch: false,
        BottomBarHeight: 0,
        CompletedFirstSearch: false,
        DisplayingFilter: null,
        Loading: true,
        NewSearch: false,
        OpenFilterSize: 0,
        RenewClientMealSettings: false,
        RetrievedDishFilterSettings: false,
        ShowBlockedAllergiesModal: false,
        ShowBlockedIngredientsModal: false,
        ShowChooseDishRatingModal: false,
        ShowDishDetailModal: false,
        ShowDishDetailPreview: {},
        ShowChoosePrivateDishCategoryModal: false,
        ShowDeletePrivateDishesModal: false,
        ShowMobileFiltersModal: false
    };

    componentDidMount() {
        this._isMounted = true;

        GA_PageView('Library-Dishes');

        this.props.GetDishFilterSettings().then(() => {
            if (this._isMounted && !this.props.TryingGetDishFilterSettingsError) this.setState({ RetrievedDishFilterSettings: true }, () => this.onLoadDishes());
        });
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearSaveData();
        this.props.ClearDishes();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.SelectedUsers !== this.props.SelectedUsers) this.setState({ RenewClientMealSettings: true }, () => this.onLoadDishes());
    }

    onChangeMaxCookingTime = MaxCookingTime => {
        var { MaxCookingTime: CurrentMaxCookingTime } = this.state;
        
        if (Number(CurrentMaxCookingTime) !== Number(MaxCookingTime)) this.setState({ MaxCookingTime }, () => this.onLoadDishes());
    }

    onChangePrivateDishCategory = ({ PrivateDishCategoryId }) => {
        var { PrivateDishIds, UserId } = this.props;

        this.props.ChangeDishesCategory({ CategoryId: PrivateDishCategoryId, DishIds: PrivateDishIds, UserId }).then(() => {
            if (this._isMounted && !this.props.TryingChangeDishesCategoryError) this.onToggleShowChoosePrivateDishCategoryModal(false);
        });
    }

    onChangeSearch = event => {
        this.setState({ SearchValue: event.target.value });
    }

    onChangeToggle = (event, ToggleType) => {
        this.setState({ [ToggleType]: event.target.checked }, () => this.onLoadDishes());
    }

    onCheckMadeBy = (ItemId, SelectedIndex) => {
        var MadeByType = ItemId === 1 ? 'Abel' : ItemId === 2 ? 'You' : 'Client';

        this.setState({ MadeBy: { ...this.state.MadeBy, [MadeByType]: SelectedIndex === 1 ? false : true } }, () => this.onLoadDishes());
    }

    onChooseDishRating = Rating => {
        var { MultiSelectDishIds, UserId } = this.props;

        this.props.ChangeSelectedDishesRating({ DishIds: MultiSelectDishIds, Rating, UserId }).then(() => {
            if (this._isMounted && !this.props.TryingChangeSelectedDishesRatingError) this.onToggleShowChooseDishRatingModal(false);
        });
    }

    onClearFilters = () => {
        var { DishTypeIds: Initial_DishTypeIds, MadeBy: { Abel: Initial_Abel, Client: Initial_Client, You: Initial_You }, MealTypeIds: Initial_MealTypeIds, MultiRatings: Initial_MultiRatings, PageNo, PageSize } = INITIAL_STATE;
        var { DishTypeIds: State_DishTypeIds, MadeBy: { Abel: State_Abel, Client: State_Client, You: State_You }, MealTypeIds: State_MealTypeIds, MultiRatings: State_MultiRatings } = this.state;

        var NeedsClear = ((Initial_DishTypeIds !== State_DishTypeIds) || ((Initial_Abel !== State_Abel) || (Initial_Client !== State_Client) || (Initial_You !== State_You)) || (Initial_MealTypeIds !== State_MealTypeIds) || (Initial_MultiRatings !== State_MultiRatings))

        if (NeedsClear) this.setState({ DishTypeIds: Initial_DishTypeIds, DisplayingFilter: null, MadeBy: { Abel: true, Client: true, You: true }, MealTypeIds: Initial_MealTypeIds, MultiRatings: Initial_MultiRatings, OpenFilterSize: 0, PageNo, PageSize }, () => this.onLoadDishes());
        this.setState({ DisplayingFilter: null, OpenFilterSize: 0 });
    }

    onClickCreatePrivateDish = () => {
        history.push('library/dish');
    }

    onDeletePrivateDishes = () => {
        var { DeleteDishes, OnDeleteDishes, PrivateDishIds, TryingDeleteDishesError } = this.props;

        DeleteDishes({ DishIds: PrivateDishIds }).then(() => {
            if (this._isMounted && !TryingDeleteDishesError) {
                this.onToggleShowDeletePrivateDishesModal(false);
                OnDeleteDishes(PrivateDishIds);
            }
        })
    }

    onGetSize = Height => {
        this.setState({ OpenFilterSize: Height });
    }

    onGetSizeBottomBar = Height => {
        this.setState({ BottomBarHeight: Height });
    }

    onKeyPress = ({ key }) => {
        if (key === 'Enter' && !this.props.TryingGetDishFilterSettings && !this.props.TryingSearchDishes) {
            var { SearchValue, LastSearchedValue } = this.state;

            if (SearchValue !== LastSearchedValue) {
                this.setState({ PageNo: 1, PageSize: INITIAL_STATE.PageSize, LastSearchedValue: SearchValue }, () => this.onLoadDishes());
            }
        }
    }

    onLoadDishes = Increment => {
        var { SelectedUsers } = this.props;
        var { DishTypeIds, IsBlockedAllergiesDietTypes, IsBlockedIngredients, MadeBy, MaxCookingTime, MealTypeIds, MultiRatings, NewSearch, PageNo, PageSize, SearchValue } = this.state;

        if (Increment) {
            NewSearch = false;
            PageNo = PageNo + 1;
        } else {
            NewSearch = true;
            PageNo = 1;
        }

        var ClientId = SelectedUsers.length === 1 ? Number(SelectedUsers[0].UserId) : null;

        var DishCategoryIds = [];
        if (MadeBy.Abel) DishCategoryIds.push(1);
        if (MadeBy.You) DishCategoryIds.push(2);
        if (MadeBy.Client) DishCategoryIds.push(3);

        this.setState({ ActiveSearch: true, NewSearch, PageNo }, () => this.props.SearchDishes({ AddToExisting: Increment, ClientId, DishCategoryIds, DishName: SearchValue, DishTypeIds, IsBlockedAllergiesDietTypes, IsBlockedIngredients, MaxCookingTime, MealTypeIds, MultiRatings, PageNo, PageSize }).then(() => this._isMounted ? this.setState({ ActiveSearch: false, CompletedFirstSearch: true, Loading: false }) : null));
    }

    onRetrieveDishFilterSettings = () => {
        this.props.GetDishFilterSettings().then(() => {
            if (this._isMounted && !this.props.TryingGetDishFilterSettingsError) this.setState({ RetrievedDishFilterSettings: true });
        });
    }

    onSearchFromSAYT = SearchValue => {
        if (!this.props.TryingSearchDishes) {
            var { LastSearchedValue } = this.state;

            if (SearchValue !== LastSearchedValue) {
                this.setState({ PageNo: 1, PageSize: INITIAL_STATE.PageSize, LastSearchedValue: SearchValue, SearchValue }, () => this.onLoadDishes());
            }
        }
    }

    onSelectDishType = (event, DishTypeId, DishTypeIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = DishTypeIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return {
                    DishTypeIds: [ ...prevState.DishTypeIds, ...[ DishTypeId ] ],
                }
            }, () => this.onLoadDishes());
        } else {
            this.setState((prevState) => {
                return {
                    DishTypeIds: [ ...prevState.DishTypeIds.slice(0, DishTypeIndex), ...prevState.DishTypeIds.slice(DishTypeIndex + 1) ],
                }
            }, () => this.onLoadDishes());
        }
    }

    onSelectMealType = (event, MealTypeId, MealTypeIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = MealTypeIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return {
                    MealTypeIds: [ ...prevState.MealTypeIds, ...[ MealTypeId ] ],
                }
            }, () => this.onLoadDishes());
        } else {
            this.setState((prevState) => {
                return {
                    MealTypeIds: [ ...prevState.MealTypeIds.slice(0, MealTypeIndex), ...prevState.MealTypeIds.slice(MealTypeIndex + 1) ],
                }
            }, () => this.onLoadDishes());
        }
    }

    onSelectRating = (event, Rating, RatingIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = RatingIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return {
                    MultiRatings: [ ...prevState.MultiRatings, ...[ Rating ] ],
                }
            }, () => this.onLoadDishes());
        } else {
            this.setState((prevState) => {
                return {
                    MultiRatings: [ ...prevState.MultiRatings.slice(0, RatingIndex), ...prevState.MultiRatings.slice(RatingIndex + 1) ],
                }
            }, () => this.onLoadDishes());
        }
    }

    onToggleDropdown = (DisplayingFilter, ToggleStatus) => {
        this.setState({ DisplayingFilter: ToggleStatus ? DisplayingFilter : null });
    }

    onToggleShowBlockedAllergiesModal = ShowBlockedAllergiesModal => {
        var { RetrievedDietaryRestrictions, SelectedUsers, UserId } = this.props;
        var { RenewClientMealSettings } = this.state;

        this.setState({ ShowBlockedAllergiesModal }, () => {
            if ((RenewClientMealSettings || !RetrievedDietaryRestrictions) && ShowBlockedAllergiesModal) this.props.GetClientMealSettings({ ClientId: SelectedUsers.length === 1 ? Number(SelectedUsers[0].ClientId) : Number(UserId) }).then(() => this.setState({ RenewClientMealSettings: false }));
        });
    }

    onToggleShowBlockedIngredientsModal = ShowBlockedIngredientsModal => {
        var { RetrievedDietaryRestrictions, SelectedUsers, UserId } = this.props;
        var { RenewClientMealSettings } = this.state;

        this.setState({ ShowBlockedIngredientsModal }, () => {
            if ((RenewClientMealSettings || !RetrievedDietaryRestrictions) && ShowBlockedIngredientsModal) this.props.GetClientMealSettings({ ClientId: SelectedUsers.length === 1 ? Number(SelectedUsers[0].ClientId) : Number(UserId) }).then(() => this.setState({ RenewClientMealSettings: false }));
        });
    }

    onToggleShowChooseDishRatingModal = ShowChooseDishRatingModal => {
        this.setState({ ShowChooseDishRatingModal });
    }

    onToggleShowDishDetailModal = (ShowDishDetailModal, Dish) => {
        this.setState({ ShowDishDetailModal, ShowDishDetailPreview: Dish || {} });
    }

    onToggleShowChoosePrivateDishCategoryModal = ShowChoosePrivateDishCategoryModal => {
        this.setState({ ShowChoosePrivateDishCategoryModal });
    }

    onToggleShowDeletePrivateDishesModal = ShowDeletePrivateDishesModal => {
        this.setState({ ShowDeletePrivateDishesModal });
    }

    onToggleShowMobileFiltersModal = ShowMobileFiltersModal => {
        this.setState({ ShowMobileFiltersModal });
    }

    renderBlockedAllergiesInformation = () => {
        var { t } = this.props;
        var { Device, ClientMealSettings: { Allergies, DietTypes }, TryingGetClientMealSettings } = this.props;
        var ClientAllergies = [];
        var ClientDietTypes = [];

        if (TryingGetClientMealSettings) return null;

        return (
            <BlockedRestrictionsContainer>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('WorkoutPlanSettings_Allergies')} TextAlign="center" />

                <Spacer Size={Device === 'ipad' || Device === 'laptop' ? 'medium' : 'small'} />

                {
                    Allergies.map(({ AllergyName, IsSelected }) => {
                        if (!Number(IsSelected)) return null;

                        ClientAllergies.push(AllergyName);
                        return null;
                    })
                }
                {
                    DietTypes.map(({ DietTypeId, DietTypeName, IsSelected }) => {
                        if (Number(DietTypeId) !== 3 || !Number(IsSelected)) return null;

                        ClientDietTypes.push(DietTypeName);
                        return null;
                    })
                }
                
                <div>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('WorkoutPlanSettings_Allergies')}:`} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={ClientAllergies.length ? ClientAllergies.join(', ') : 'None'} />
                </div>

                <div>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('WorkoutPlanSettings_Dietary_restrictions')}:`} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={ClientDietTypes.length ? ClientDietTypes.join(', ') : 'None'} />
                </div>

                <Spacer Size={Device === 'ipad' || Device === 'laptop' ? 'medium' : 'small'} />

                <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('ptadmin_filter_allergies_description')} TextAlign="center" />
            </BlockedRestrictionsContainer>
        );
    }

    renderBlockedAllergiesModal = () => {
        var { TryingGetClientMealSettings } = this.props;
        var { ShowBlockedAllergiesModal } = this.state;

        if (ShowBlockedAllergiesModal) {
            return (
                <Info
                    BottomButton="cancel"
                    BottomButtonOnClick={() => this.onToggleShowBlockedAllergiesModal(false)}
                    InformationRenderer={this.renderBlockedAllergiesInformation}
                    Loading={TryingGetClientMealSettings}
                    OnHide={() => this.onToggleShowBlockedAllergiesModal(false)}
                    Show={ShowBlockedAllergiesModal}
                />
            );
        }
    }

    renderBlockedIngredientsInformation = () => {
        var { t } = this.props;
        var { Device, ClientMealSettings: { BlockedIngredients }, TryingGetClientMealSettings } = this.props;
        var ClientBlockedIngredients = [];

        if (TryingGetClientMealSettings) return null;

        return (
            <BlockedRestrictionsContainer>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('WorkoutPlanSettings_Blocked_ingredients')} TextAlign="center" />

                <Spacer Size={Device === 'ipad' || Device === 'laptop' ? 'medium' : 'small'} />

                {
                    BlockedIngredients.map(({ FoodName }) => {

                        ClientBlockedIngredients.push(FoodName);
                        return null;
                    })
                }
                
                <div>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('WorkoutPlanSettings_Blocked_ingredients')}:`} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={ClientBlockedIngredients.length ? ClientBlockedIngredients.join(', ') : 'None'} />
                </div>

                <Spacer Size={Device === 'ipad' || Device === 'laptop' ? 'medium' : 'small'} />

                <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('ptadmin_filter_blockedingredients_description')} TextAlign="center" />
            </BlockedRestrictionsContainer>
        );
    }

    renderBlockedIngredientsModal = () => {
        var { TryingGetClientMealSettings } = this.props;
        var { ShowBlockedIngredientsModal } = this.state;

        if (ShowBlockedIngredientsModal) {
            return (
                <Info
                    BottomButton="cancel"
                    BottomButtonOnClick={() => this.onToggleShowBlockedIngredientsModal(false)}
                    InformationRenderer={this.renderBlockedIngredientsInformation}
                    Loading={TryingGetClientMealSettings}
                    OnHide={() => this.onToggleShowBlockedIngredientsModal(false)}
                    Show={ShowBlockedIngredientsModal}
                />
            );
        }
    }

    renderBlockedSettings = () => {
        var { t } = this.props;
        var { SelectedUsers } = this.props;
        var { IsBlockedAllergiesDietTypes, IsBlockedIngredients } = this.state;

        if (SelectedUsers.length !== 1) return null;

        return (
            <>
                <TogglesContainer>
                    <ToggleContainer>
                        <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('filter_blockallergies')} TextAlign="left" />

                        <Spacer Size="extra-small" />

                        <ToggleInnerContainer>
                            <ViewAllPageText FontFamily="semibold" FontSize="medium-1" NoMargin OnClick={() => this.onToggleShowBlockedAllergiesModal(true)} Text={t('view_all')} TextAlign="center" />

                            <Toggle
                                Checked={IsBlockedAllergiesDietTypes}
                                OffLabel={t('off')}
                                OnChange={event => this.onChangeToggle(event, 'IsBlockedAllergiesDietTypes')}
                                OnLabel={t('on')}
                            />
                        </ToggleInnerContainer>
                    </ToggleContainer>
                    <ToggleContainer>
                    <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('filter_blockingredients')} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <ToggleInnerContainer>
                            <ViewAllPageText FontFamily="semibold" FontSize="medium-1" NoMargin OnClick={() => this.onToggleShowBlockedIngredientsModal(true)} Text={t('view_all')} TextAlign="center" />

                            <Toggle
                                Checked={IsBlockedIngredients}
                                OffLabel={t('off')}
                                OnChange={event => this.onChangeToggle(event, 'IsBlockedIngredients')}
                                OnLabel={t('on')}
                            />
                        </ToggleInnerContainer>
                    </ToggleContainer>
                </TogglesContainer>

                <Spacer Size="extra-small" />
            </>
        );
    }

    renderBottomBar = () => {
        var { MultiSelectDishIds } = this.props;

        if (!MultiSelectDishIds.length) return null;

        var { t } = this.props;
        var { Device, OnCancelMultiSelect, PrivateDishIds } = this.props;

        var Buttons = [
            { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Color: 'white', ColorHover: 'white', OnClick: () => this.onToggleShowChooseDishRatingModal(true), Title: t('MealPlan_Change_rating') },
            { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Border: '2px solid var(--blue-astronaut)', ColorHover: 'black', OnClick: OnCancelMultiSelect, Title: t('Cancel_with_first_char_uppercase') }
        ];

        if (PrivateDishIds.length) {
            Buttons.unshift({ BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Color: 'white', ColorHover: 'white', OnClick: () => this.onToggleShowChoosePrivateDishCategoryModal(true), Title: 'Move to' });
            Buttons.unshift({ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Color: 'white', ColorHover: 'white', OnClick: () => this.onToggleShowDeletePrivateDishesModal(true), Title: t('delete_with_first_char_uppercase') });
        }

        return (
            <>
                <Spacer Size="extra-large" />

                <StyledBottomBar GetSize={Height => this.onGetSizeBottomBar(Height)}>
                    <BottomBarItems>
                        <SelectedItems ElementType="span" FontColor="white" FontFamily="bold" FontSize="medium-3" Text={`${MultiSelectDishIds.length}`} />
                        &nbsp;
                        <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('WorkoutPlanSettings_selected').toLowerCase()} />
                    </BottomBarItems>
                    <BottomBarItems FullWidth={true}>
                        <ButtonGroup
                            Buttons={Buttons}
                            ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                            NotTouching
                            OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                        />
                    </BottomBarItems>
                </StyledBottomBar>
            </>
        );
    }

    renderBottomBarHeightAdjustment = () => {
        var { MultiSelectDishIds } = this.props;

        if (!MultiSelectDishIds.length) return null;

        return <BottomBarHeightAdjustment Height={this.state.BottomBarHeight} />
    }

    renderChooseDishRatingModal = () => {
        var { ShowChooseDishRatingModal } = this.state;
        var { TryingChangeSelectedDishesRating } = this.props;

        if (ShowChooseDishRatingModal) {
            return (
                <ChooseDishRating
                    IsLoading={TryingChangeSelectedDishesRating}
                    OnHide={() => this.onToggleShowChooseDishRatingModal(false)}
                    OnSelectRating={Rating => this.onChooseDishRating(Rating)}
                    Show={ShowChooseDishRatingModal}
                />
            );
        }
    }

    renderChoosePrivateDishCategoryModal = () => {
        var { TryingChangeDishesCategory } = this.props;
        var { ShowChoosePrivateDishCategoryModal } = this.state;

        if (ShowChoosePrivateDishCategoryModal) {
            return (
                <PrivateDishCategories
                    IsLoading={TryingChangeDishesCategory}
                    OnHide={() => this.onToggleShowChoosePrivateDishCategoryModal(false)}
                    OnSelectCategory={PrivateDishCategoryId => this.onChangePrivateDishCategory(PrivateDishCategoryId)}
                    PrivateDishCategories={this.props.DishFilterSettings.PrivateDishCategories}
                    Show={ShowChoosePrivateDishCategoryModal}
                />
            );
        }
    }

    renderDeletePrivateDishesModal = () => {
        var { t } = this.props;
        var { TryingDeleteDishes } = this.props;
        var { ShowDeletePrivateDishesModal, ShowDishDetailModal } = this.state;

        if (ShowDeletePrivateDishesModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeletePrivateDishes}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    DisplayNone={ShowDishDetailModal}
                    InformationRenderer={this.renderDeletePrivateDishesInformation}
                    Loading={TryingDeleteDishes}
                    OnHide={() => this.onToggleShowDeletePrivateDishesModal(false)}
                    Show={ShowDeletePrivateDishesModal}
                />
            );
        }
    }

    renderDeletePrivateDishesInformation = () => {
        var { t } = this.props;
        var { PrivateDishes } = this.props;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" Text={t('delete_with_warning', { Items: t('ptadmin_dishes_plural').toLowerCase()})} TextAlign="center" />

                <PrivateDishesContainer>
                    {
                        PrivateDishes.map(PrivateDish => <DishListItem key={PrivateDish.DishId} HideOwnerRating HideSelect Item={PrivateDish} ViewDish={Dish => this.onToggleShowDishDetailModal(true, Dish)} />)
                    }
                </PrivateDishesContainer>
            </>
        );
    }

    renderDishDetailModal = () => {
        var { ShowDishDetailModal, ShowDishDetailPreview } = this.state;

        if (ShowDishDetailModal) {
            return (
                <DishDetail
                    DishDetail_Preview={ShowDishDetailPreview}
                    OnHide={() => this.onToggleShowDishDetailModal(false)}
                    Show={ShowDishDetailModal}
                />
            );
        }
    }

    renderDishesList = () => {
        var { t } = this.props;
        var { Device, DishList, DishList_TotalRecords, HideSelect, IsMoreDishes, IsMultiSelect, MultiSelectDishIds, OnMultiSelect, OnSelectDish, SelectedItemIds, SelectedUsers, TryingSearchDishes } = this.props;
        var { ActiveSearch, CompletedFirstSearch, NewSearch, PageNo, PageSize } = this.state;

        var IsFlexed = Device === 'laptop' ? 6 : Device === 'ipad' ? 3 : Device === 'mobile' ? 3 : 2;
        var ShowRating = true;
        var ShowClientRating = SelectedUsers.length === 1;// && Number(SelectedUsers[0].UserId) !== Number(this.props.UserId);

        return (
            <StyledLoadMore
                ActiveSearch={ActiveSearch}
                CompletedFirstSearch={CompletedFirstSearch}
                IsFlexed={IsFlexed}
                IsLoading={TryingSearchDishes}
                ItemDescriptionType={t('ptadmin_dishes_plural').toLowerCase()}
                ItemHeight={145}
                ItemId="DishId"
                ItemName="DishName"
                ItemProps={{
                    HideSelect,
                    IsMultiSelect,
                    MultiSelectItemIds: MultiSelectDishIds,
                    OnAssignToClients: (event, Template) => this.onAssignToClients(event, Template),
                    OnMultiSelect: (Dish, MultiSelectIndex) => OnMultiSelect(Dish, MultiSelectIndex),
                    SelectDish: Dish => OnSelectDish(Dish),
                    ShowClientRating: ShowClientRating,
                    ShowRating,
                    SelectedUsers: ShowClientRating ? SelectedUsers : null,
                    ViewDish: Dish => this.onToggleShowDishDetailModal(true, Dish)
                }}
                ItemRenderer={DishListItem}
                Items={DishList}
                HasMoreItems={IsMoreDishes}
                LoadMore={() => this.onLoadDishes(true)}
                NewSearch={NewSearch}
                NoItemsText={t('search_noresults')}
                PageNo={PageNo}
                PageSize={PageSize}
                SelectedItemIds={SelectedItemIds}
                TotalRecords={DishList_TotalRecords}
            />
        );
    }

    renderDropdownFilters = Mobile => {
        var { t } = this.props;
        var { Device, DishFilterSettings: { DishTypes, MealTypes }, TryingGetDishFilterSettings, TryingSearchDishes } = this.props;
        var { DishTypeIds, DisplayingFilter, MadeBy: { Abel }, MealTypeIds, MultiRatings, OpenFilterSize, RetrievedDishFilterSettings } = this.state;

        return (
            <>
                {(Device !== 'laptop' || Mobile) && TryingSearchDishes ? <Loading /> : null}
                <StyledDropdown
                    className="StyledDropdown"
                    Disabled={TryingSearchDishes}
                    ForceClose={DisplayingFilter !== 'MealTypes'}
                    GetSize={Height => this.onGetSize(Height)}
                    Height={DisplayingFilter === 'MealTypes' ? OpenFilterSize : null}
                    IsMobile={Device !== 'laptop' || Mobile}
                    ItemId="MealTypeId"
                    ItemName="MealTypeName"
                    Items={MealTypes}
                    Loading={TryingGetDishFilterSettings}
                    NeedsToLoad={!RetrievedDishFilterSettings}
                    NeedsToLoadFunc={this.onRetrieveDishFilterSettings}
                    OnToggleDropdown={ToggleStatus => this.onToggleDropdown('MealTypes', ToggleStatus)}
                    OnSelectItem={(event, MealTypeId, MealTypeIndex) => this.onSelectMealType(event, MealTypeId, MealTypeIndex)}
                    SelectedItemIds={MealTypeIds}
                    Title={t('MealSection_Meal_types')}
                />
                {
                    Abel &&
                    <StyledDropdown
                        className="StyledDropdown"
                        Disabled={TryingSearchDishes}
                        ForceClose={DisplayingFilter !== 'DishTypes'}
                        GetSize={Height => this.onGetSize(Height)}
                        Height={DisplayingFilter === 'DishTypes' ? OpenFilterSize : null}
                        IsMobile={Device !== 'laptop' || Mobile}
                        ItemId="DishTypeId"
                        ItemName="DishTypeName"
                        Items={DishTypes}
                        Loading={TryingGetDishFilterSettings}
                        NeedsToLoad={!RetrievedDishFilterSettings}
                        NeedsToLoadFunc={this.onRetrieveDishFilterSettings}
                        OnToggleDropdown={ToggleStatus => this.onToggleDropdown('DishTypes', ToggleStatus)}
                        OnSelectItem={(event, DishTypeId, DishTypeIndex) => this.onSelectDishType(event, DishTypeId, DishTypeIndex)}
                        SelectedItemIds={DishTypeIds}
                        Title={t('MealSection_Dish_type')}
                    />
                }
                <StyledDropdown
                    className="StyledDropdown"
                    ContentRenderer={this.renderMaxCookingTime}
                    Disabled={TryingSearchDishes}
                    ForceClose={DisplayingFilter !== 'CookingTime'}
                    GetSize={Height => this.onGetSize(Height)}
                    Height={DisplayingFilter === 'CookingTime' ? OpenFilterSize : null}
                    IsList={false}
                    IsMobile={Device !== 'laptop' || Mobile}
                    ItemId="CookingTime"
                    NeedsToLoad={false}
                    OnToggleDropdown={ToggleStatus => this.onToggleDropdown('CookingTime', ToggleStatus)}
                    SelectedItemIds={[ 1 ]}
                    Title={t('MealPlan_Cooking_Time')}
                />
                <StyledDropdown
                    className="StyledDropdown"
                    Disabled={TryingSearchDishes}
                    ForceClose={DisplayingFilter !== 'Ratings'}
                    GetSize={Height => this.onGetSize(Height)}
                    Height={DisplayingFilter === 'Ratings' ? OpenFilterSize : null}
                    IsMobile={Device !== 'laptop' || Mobile}
                    ItemId="RatingId"
                    ItemIsIcon
                    ItemName="RatingName"
                    Items={RatingOptions}
                    OnToggleDropdown={ToggleStatus => this.onToggleDropdown('Ratings', ToggleStatus)}
                    OnSelectItem={(event, RatingId, RatingIndex) => this.onSelectRating(event, RatingId, RatingIndex)}
                    SelectedItemIds={MultiRatings}
                    Title={t('ratings')}
                />  
            </>
        );
    }

    renderFilters = () => {
        var { t } = this.props;
        var { Device } = this.props;

        return (
            <AllFilters className="dish-filters">
                {this.renderBlockedSettings()}

                {this.renderSearchMadeBy()}

                {this.renderFiltersContainer()}

                <StyledCreateDish
                    Buttons={[{ Icon: Plus, IconPosition: 'left', OnClick: this.onClickCreatePrivateDish, Title: t('MealPlan_Create_private_dish') }]}
                    ContainerWidth={Device === 'mobile_small' || Device === 'mobile' ? '100%' : null}
                />
            </AllFilters>
        );
    }

    renderFiltersContainer = () => {
        var { t } = this.props;

        return (
            <FiltersContainer className="filters-container">
                <DropdownFilters className="DropdownFilters">{this.renderDropdownFilters()}</DropdownFilters>
                
                <StyledToggleMobileFilters
                    className="StyledToggleMobileFilters"
                    Buttons={[{ FontFamily: 'semibold', FontSize: 'medium-1', OnClick: () => this.onToggleShowMobileFiltersModal(true), Title: t('MealPlan_Filters') }]}
                    ContainerWidth="100%"
                />

                <StyledClearFilters
                    Buttons={[{ FontFamily: 'semibold', FontSize: 'medium-1', OnClick: this.onClearFilters, Title: t('clear_filters') }]}
                />
            </FiltersContainer>
        );
    }

    renderMaxCookingTime = () => {
        var { MaxCookingTime } = this.state;

        return (
            <InputRange
                MaxValue={60}
                MinValue={5}
                OnChangeComplete={MaxCookingTime => this.onChangeMaxCookingTime(MaxCookingTime, true)}
                RenderText={Value => this.renderMaxCookingTimeText(Value)}
                Step={5}
                Value={MaxCookingTime}
            />
        );
    }

    renderMaxCookingTimeText = MaxCookingTime => {
        var { t } = this.props;

        return (Number(MaxCookingTime) === 60 ? '60+' : `${MaxCookingTime}`) + ' ' + t('min_str');
    }

    renderMobileFiltersModal = () => {
        var { ShowMobileFiltersModal } = this.state;

        if (ShowMobileFiltersModal) {
            return (
                <Info
                    InformationRenderer={() => this.renderDropdownFilters(true)}
                    OnHide={() => this.onToggleShowMobileFiltersModal(false)}
                    Show={ShowMobileFiltersModal}
                />
            );
        }
    }

    renderSearchMadeBy = () => {
        var { t } = this.props;
        var { MadeBy: { Abel, Client, You } } = this.state;

        return (
            <FilterRowSearchMadeBy>
                {/* <StyledSearchInput
                    FontSize="medium-2"
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeSearch}
                    OnKeyPress={this.onKeyPress}
                    Placeholder={t('search_with_first_char_uppercase_dots')}
                    Size="medium"
                    Type="text"
                    Value={SearchValue}
                /> */}

                <SAYT
                    ItemName="DishName"
                    OnSearch={this.onSearchFromSAYT}
                    Placeholder={t('search_with_first_char_uppercase_dots')}
                    SearchFunc={this.props.SearchDishes_SAYT}
                />
                
                <MadeByContainer>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('MealSection_Dishes_shown')}:`} />
                    <MadeByOptions>
                        <MadeByOption>
                            <StyledCheckmark
                                Disabled={false}
                                IsMultiSelect
                                ItemId={1}
                                OnSelectItem={(e, ItemId, SelectedIndex) => this.onCheckMadeBy(ItemId, SelectedIndex)}
                                SelectedIndex={Abel ? 1 : -1}
                                Title="Abel"
                            />
                            <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin OnClick={() => this.onCheckMadeBy(1, Abel ? 1 : -1)} Text="Abel" />
                            <OwnerIcon BackgroundColor="blue-abel" />
                        </MadeByOption>
                        <MadeByOption>
                            <StyledCheckmark
                                Disabled={false}
                                IsMultiSelect
                                ItemId={2}
                                OnSelectItem={(e, ItemId, SelectedIndex) => this.onCheckMadeBy(ItemId, SelectedIndex)}
                                SelectedIndex={You ? 1 : -1}
                                Title="You"
                            />
                            <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin OnClick={() => this.onCheckMadeBy(2, You ? 1 : -1)} Text={t('you')} />
                            <OwnerIcon BackgroundColor="orange-selectiveyellow" />
                        </MadeByOption>
                        <MadeByOption>
                            <StyledCheckmark
                                Disabled={false}
                                IsMultiSelect
                                ItemId={3}
                                OnSelectItem={(e, ItemId, SelectedIndex) => this.onCheckMadeBy(ItemId, SelectedIndex)}
                                SelectedIndex={Client ? 1 : -1}
                                Title="Client"
                            />
                            <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin OnClick={() => this.onCheckMadeBy(3, Client ? 1 : -1)} Text={t('client')} />
                            <OwnerIcon BackgroundColor="green-lima-dark" />
                        </MadeByOption>
                    </MadeByOptions>
                </MadeByContainer>
            </FilterRowSearchMadeBy>
        );
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { Device, className } = this.props;

        return (
            <div className={className}>
                {this.renderFilters()}

                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'large' : 'medium'} />

                {this.renderDishesList()}

                {this.renderBottomBarHeightAdjustment()}

                {this.renderBottomBar()}

                {this.renderBlockedAllergiesModal()}
                {this.renderBlockedIngredientsModal()}
                {this.renderChooseDishRatingModal()}
                {this.renderChoosePrivateDishCategoryModal()}
                {this.renderDeletePrivateDishesModal()}
                {this.renderDishDetailModal()}
                {this.renderMobileFiltersModal()}
            </div>
        );
    }
}

Dishes.propTypes = {
    HideSelect: PropTypes.bool,
    IsMultiSelect: PropTypes.bool,
    MultiSelectDishIds: PropTypes.array,
    OnCancelMultiSelect: PropTypes.func,
    OnMultiSelect: PropTypes.func,
    OnSelectDish: PropTypes.func,
    PrivateDishes: PropTypes.array,
    PrivateDishIds: PropTypes.array,
    SelectedItemIds: PropTypes.array,
    SelectedUsers: PropTypes.array
}

Dishes.defaultProps = {
    HideSelect: true,
    IsMultiSelect: true,
    MultiSelectDishIds: [],
    OnCancelMultiSelect: () => null,
    OnMultiSelect: () => null,
    OnSelectDish: () => null,
    PrivateDishes: [],
    PrivateDishIds: [],
    SelectedItemIds: [],
    SelectedUsers: []
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        UserId: state.Auth.UserDetails.UserId,

        DishFilterSettings: state.Dishes.DishFilterSettings,
        DishList: state.Dishes.DishList,
        DishList_TotalRecords: state.Dishes.DishList_TotalRecords,
        IsMoreDishes: state.Dishes.IsMoreDishes,

        ClientMealSettings: state.Client.ClientMealSettings,
        RetrievedDietaryRestrictions: state.Client.RetrievedDietaryRestrictions,
        TryingAddExistingDish: state.Meals.TryingAddExistingDish,
        TryingAddExistingDishError: state.Meals.TryingAddExistingDishError,
        TryingChangeDishesCategory: state.Dishes.TryingChangeDishesCategory,
        TryingChangeDishesCategoryError: state.Dishes.TryingChangeDishesCategoryError,
        TryingChangeSelectedDishesRating: state.Dishes.TryingChangeSelectedDishesRating,
        TryingChangeSelectedDishesRatingError: state.Dishes.TryingChangeSelectedDishesRatingError,
        TryingDeleteDishes: state.Dishes.TryingDeleteDishes,
        TryingDeleteDishesError: state.Dishes.TryingDeleteDishesError,
        TryingGetClientMealSettings: state.Client.TryingGetClientMealSettings,
        TryingGetClientMealSettingsError: state.Client.TryingGetClientMealSettingsError,
        TryingGetDishFilterSettings: state.Dishes.TryingGetDishFilterSettings,
        TryingGetDishFilterSettingsError: state.Dishes.TryingGetDishFilterSettingsError,
        TryingSearchDishes: state.Dishes.TryingSearchDishes,
        TryingSearchDishesError: state.Dishes.TryingSearchDishesError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddExistingDish, ChangeDishesCategory, ChangeSelectedDishesRating, ClearDishes, ClearSaveData, DeleteDishes, GetClientMealSettings, GetDishFilterSettings, SearchDishes_SAYT, SearchDishes } )(Dishes));