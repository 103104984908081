import React from 'react';
import { withTranslation } from 'react-i18next';
import { DateRangePicker } from '@mantine/dates';
import Select, { components } from 'react-select';

import * as styles from './Filters_Styles';

import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import CalendarIcon from '../../Assets/Icons/Calendar.png';

const customStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "initial"
    }),
    input: (provided, state) => ({
        ...provided,
        minWidth: '20%'
    })
}

const ValueContainer = ({ selectProps, children, ...props }) => {
    let [values, input] = children;

    if (Array.isArray(values)) {
        values = selectProps.value.map((x) => x.DisplayName).join(', ');
    }

    return (
      <components.ValueContainer {...props}>
        <div
            style={{
                maxWidth: "80%",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                marginBottom: '0px'
            }}
        >
            {values}
        </div>
        {input}
      </components.ValueContainer>
    );
};

class InvoicesFiltersComponent extends React.Component {
    onChangeDate = ({ Data, Type }) => {
        if ((Data[0] && Data[1]) || (Data[0] === null && Data[1] === null)) {
            this.props.OnChangeDate({ StartDate: Data[0], EndDate: Data[1], Type });
        }
    }

    renderDateRangePicker = ({ ContentType, Label }) => {
        var { t } = this.props;
        var { EndDate, StartDate } = this.props[ContentType];

        StartDate = StartDate ? new Date(StartDate.split('-')[0], StartDate.split('-')[1] - 1, StartDate.split('-')[2]) : null;
        EndDate = EndDate ? new Date(EndDate.split('-')[0], EndDate.split('-')[1] - 1, EndDate.split('-')[2]) : null;

        return (
            <styles.FilterContainer>
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`..${t(Label)}`} />

                <Spacer Size="extra-extra-small" />

                <DateRangePicker
                    placeholder={t('_select')}
                    value={[ StartDate, EndDate ]}
                    onChange={Data => this.onChangeDate({ Data, Type: ContentType })}
                    icon={<styles.StyledIcon ImageSrc={CalendarIcon} />}
                    inputFormat="MMM DD, YYYY"
                />
            </styles.FilterContainer>
        );
    }

    renderPaidStatus = () => {
        var { t } = this.props;
        var { OnSelectPaidStatus } = this.props;
        var { Paid, Refunded, Unpaid_Late, Unpaid_OnTime } = this.props.PaidStatus;

        return (
            <styles.TimeRangeContainer className="TimeRangeContainer">
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_paid_status')} />

                <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                    <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => OnSelectPaidStatus('Paid')} Selected={!!Paid} />

                    <styles.RadioButtonPageText ElementType="span" FontFamily={!!Paid ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => OnSelectPaidStatus('Paid')} Text={t('_paid')} TextAlign="left" />
                </styles.RadioButtonParentContainer>

                <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                    <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => OnSelectPaidStatus('Refunded')} Selected={!!Refunded} />

                    <styles.RadioButtonPageText ElementType="span" FontFamily={!!Refunded ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => OnSelectPaidStatus('Refunded')} Text={t('_refunded')} TextAlign="left" />
                </styles.RadioButtonParentContainer>

                <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                    <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => OnSelectPaidStatus('Unpaid_Late')} Selected={!!Unpaid_Late} />

                    <styles.RadioButtonPageText ElementType="span" FontFamily={!!Unpaid_Late ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => OnSelectPaidStatus('Unpaid_Late')} Text={t('_unpaid_late')} TextAlign="left" />
                </styles.RadioButtonParentContainer>

                <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                    <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => OnSelectPaidStatus('Unpaid_OnTime')} Selected={!!Unpaid_OnTime} />

                    <styles.RadioButtonPageText ElementType="span" FontFamily={!!Unpaid_OnTime ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => OnSelectPaidStatus('Unpaid_OnTime')} Text={t('_unpaid_ontime')} TextAlign="left" />
                </styles.RadioButtonParentContainer>
            </styles.TimeRangeContainer>
        );
    }

    renderSelectFilter = ({ ContentType, ContentTypeId, Label }) => {
        var { t } = this.props;
        var { Loading, OnBlurFilter, OnChangeFilter, OnFocusFilter } = this.props;

        var FilterContent = this.props[ContentType];
        var SelectedContent = this.props.SelectedContent ? this.props.SelectedContent[ContentType] : [];

        return (
            <styles.FilterContainer className="FilterContainer">
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`..${t(Label)}`} />

                <Spacer Size="extra-extra-small" />
                
                <Select
                    closeMenuOnSelect={false}
                    defaultValue={SelectedContent}
                    getOptionLabel={option => option.DisplayName}
                    getOptionValue={option => option[ContentTypeId]}
                    isLoading={Loading}
                    isMulti
                    components={{ ValueContainer }}
                    hideSelectedOptions={false}
                    isSearchable
                    onBlur={OnBlurFilter}
                    onChange={Data => OnChangeFilter({ Data, Type: ContentType })}
                    onFocus={OnFocusFilter}
                    options={FilterContent}
                    placeholder={t('_select')}
                    styles={customStyles}
                />
            </styles.FilterContainer>
        );
    }

    render() {
        var { t } = this.props;
        var { IsModal, Loading: IsLoading } = this.props;

        return (
            <>
                {IsModal && IsLoading && <Loading />}

                <styles.FiltersContainer className="FiltersContainer">
                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${t('_show_invoices')}..`} />

                    <Spacer Size="small-1" />

                    {this.renderSelectFilter({ ContentType: 'Coaches', ContentTypeId: 'UserId', Label: '_for_coach'})}

                    <Spacer Size="small-1" />

                    {this.renderSelectFilter({ ContentType: 'Clients', ContentTypeId: 'UserId', Label: '_for_client'})}

                    <Spacer Size="small-1" />

                    {this.renderSelectFilter({ ContentType: 'Branches', ContentTypeId: 'BranchId', Label: '_for_branch'})}

                    <Spacer Size="small-1" />

                    {this.renderSelectFilter({ ContentType: 'Regions', ContentTypeId: 'RegionId', Label: '_for_region'})}

                    <Spacer Size="small-1" />

                    {this.renderDateRangePicker({ ContentType: 'SentDate', Label: '_with_sent_date_range' })}

                    <Spacer Size="small-1" />

                    {this.renderDateRangePicker({ ContentType: 'DueDate', Label: '_with_due_date_range' })}

                    <Spacer Size="small-1" />

                    {this.renderPaidStatus()}
                </styles.FiltersContainer>
            </>
        );
    }
}

export default withTranslation()(InvoicesFiltersComponent);