export const device = {
  maxwidth: {
    ipad: `(max-width: 1125px)`,
    mobile: `(max-width: 700px)`,
    mobile_small: `(max-width: 450px)`,
  },
  minwidth: {
    laptop: `(min-width: 1126px)`,
    ipad: `(min-width: 701px)`,
    mobile: `(min-width: 451px)`,
  },
};
