import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BulkAddClients } from '../../Services/Actions';

import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

class BulkAddClientsComponent extends React.Component {
    state = {
        isSelected: false,
        isSubmitted: false,
        selectedFile: {}
    }

    changeHandler = event => {
        this.setState({ isSelected: true, selectedFile: event.target.files[0] });
    }

    handleSubmission = () => {
        if (this.state.selectedFile.type === 'text/csv') {
            this.props.BulkAddClients({ CSVFile: this.state.selectedFile }).then(() => {
                this.setState({ isSubmitted: true, isSelected: false, selectedFile: {} });
            });
        }
    }

    render() {

        var { isSelected, isSubmitted, selectedFile } = this.state;

        return (
            <>
                {(this.props.TryingBulkAddClients) && <Loading />}

                <Page NoMargin>
                    {
                        isSubmitted ?
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Upload was successful. You will receive email when processing complete" TextAlign="left" />
                    :
                    <div>
                        <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text="Bulk Add Members" TextAlign="left" />

                        <Spacer Size="medium" />

                        <input type="file" name="file" accept=".csv" onChange={this.changeHandler} />
                        {isSelected ? (
                            <div>
                                <p>Filename: {selectedFile.name}</p>
                                <p>Filetype: {selectedFile.type}</p>
                                <p>Size in bytes: {selectedFile.size}</p>
                                <p>
                                    lastModifiedDate:{' '}
                                    {selectedFile.lastModifiedDate.toLocaleDateString()}
                                </p>
                            </div>
                        ) : (
                            <p>Upload a csv</p>
                        )}
                        <div>
                            <button disabled={!selectedFile} onClick={this.handleSubmission}>Upload</button>
                        </div>
                    </div>   
                    }
                </Page>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        TryingBulkAddClients: state.UploadContent.TryingBulkAddClients,
        TryingBulkAddClientsError: state.UploadContent.TryingBulkAddClientsError
    };
};

export default withTranslation()(connect(mapStateToProps, { BulkAddClients })(BulkAddClientsComponent));