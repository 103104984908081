import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { ButtonsContainer, DeleteSetIcon, ExerciseNotesContainer, ExerciseNotesTextArea, ExerciseSetTypeOptions, ExerciseSetTypeOptionsRowContainer, OuterWorkoutSetsContainer, StyledAddAnotherSet, StyledExerciseImage, StyledExerciseName, StyledExerciseOptions, StyledExerciseVideo, StyledLearnButton, StyledMaxSetButton, WEHeaderContainer, WEHeaderTopContainer, WESetAndMaxContainer, WESetAndMaxContainerLeft, WESetAndMaxContainerRight, WESetContainer, WESetDetailsContainer, WESetFooterContainer, WESetHeaderContainer, WESetsContainer, WorkoutExerciseContainer } from './styles';

import MultiText from '../Text/MultiText';
import PageText from '../Text/PageText';
import Spacer from '../Spacer';

import ExerciseTypeOne from '../ExerciseTypes/One_Reps';
import ExerciseTypeTwo from '../ExerciseTypes/Two_Seconds';
import ExerciseTypeThree from '../ExerciseTypes/Three_Distance';
import ExerciseTypeFour from '../ExerciseTypes/Four_RepsWeight';
import ExerciseTypeFive from '../ExerciseTypes/Five_SecondsWeight';

import Edit from '../../Assets/Icons/Pencil_White_Filled.png';
import DeleteX from '../../Assets/Icons/X_White.png';
import Info from '../../Assets/Icons/Info.png';
import Plus from '../../Assets/Icons/Plus.png';
import SwapIcon from '../../Assets/Icons/Swap_White.png';

class TemplateExercise extends React.Component {
    onChangeDataType = DataType => {
        if (DataType !== this.props.Exercise.DataType) this.props.OnChangeDataType(DataType);
    }

    onChangeMaxType = MaxType => {
        if (MaxType !== this.props.Exercise.MaxType) {
            this.props.OnChangeMaxType(MaxType);

            // Change to dynamic-weight data type if custom
                if (MaxType === 'Custom' && +this.props.Exercise.ExerciseType === 4 && this.props.Exercise.DataType !== 'dynamic-weight') this.props.OnChangeDataType('dynamic-weight');
        }
    }

    onToggleMaxSet = (MaxSet, WorkoutSetIndex) => {
        this.props.OnToggleMaxSet(MaxSet, WorkoutSetIndex);
    }

    onUpdateWorkoutSet = ({ Distance, MaxSet, Reps, Seconds, TotalBreak, Weight, WorkoutSetIndex }) => {
        this.props.OnUpdateWorkoutSet({ Distance, MaxSet, Reps, Seconds, TotalBreak, Weight, WorkoutSetIndex });
    }

    renderExerciseSetTypeOptions = () => {
        var { t } = this.props;
        var { Device, Exercise: { DataType, ExerciseId, ExerciseImage, ExerciseName, ExerciseType, MaxType }, FromAddWorkouts, OnToggleShowExerciseDetailModal, ReadOnly } = this.props;

        var Selectable = !ReadOnly;

        // Build Data Type Text that is dynamic depending on Exercise Type
            var DynamicTypeText = 'dynamic';
            if (ExerciseType === 1) DynamicTypeText = 'dynamic-reps';
            else if (ExerciseType === 2) DynamicTypeText = 'dynamic-seconds';
            else if (ExerciseType === 3) DynamicTypeText = 'dynamic-distance';
            else if (ExerciseType === 4) DynamicTypeText = 'dynamic-weight';
            else if (ExerciseType === 5) DynamicTypeText = 'dynamic-weight';

        return (
            <ExerciseSetTypeOptions className="ExerciseSetTypeOptions">
                {Device === 'laptop' && <StyledExerciseName FontFamily="bold" FontSize="medium-2" JustifyContent="flex-start" NoMargin OnClick={() => OnToggleShowExerciseDetailModal(true, { ExerciseId, ExerciseImage, ExerciseName })} Text={ExerciseName} TextAlign="left" />}

                <ExerciseSetTypeOptionsRowContainer className="ExerciseSetTypeOptionsRowContainer" FromAddWorkouts={FromAddWorkouts}>
                    <PageText
                        FontColor="black"
                        FontFamily={DataType.indexOf('dynamic') > -1 ? 'bold' : 'medium'}
                        FontFamilyHover={Selectable ? 'bold' : null}
                        FontSize="medium-1"
                        JustifyContent="flex-start"
                        NoMargin
                        OnClick={Selectable ? () => this.onChangeDataType(DynamicTypeText) : null}
                        Selectable={Selectable}
                        SelectedOptions={{ Position: 'left', Selected: DataType.indexOf('dynamic') > -1 }}
                        Text={t('dynamic_str')}
                        TextAlign="left"
                    />

                    <PageText
                        FontColor="black"
                        FontFamily={DataType === 'static' ? 'bold' : 'medium'}
                        FontFamilyHover={Selectable ? 'bold' : null}
                        FontSize="medium-1"
                        JustifyContent="flex-start"
                        NoMargin
                        OnClick={Selectable ? () => this.onChangeDataType('static') : null}
                        Selectable={Selectable}
                        SelectedOptions={{ Position: 'left', Selected: DataType === 'static' }}
                        Text={t('static')}
                        TextAlign="left"
                    />
                </ExerciseSetTypeOptionsRowContainer>

                {
                    DataType.indexOf('dynamic') > -1 &&
                    <ExerciseSetTypeOptionsRowContainer className="ExerciseSetTypeOptionsRowContainer" FromAddWorkouts={FromAddWorkouts}>
                        <PageText
                            FontColor="black"
                            FontFamily={MaxType === 'Progress' ? 'bold' : 'medium'}
                            FontFamilyHover={Selectable ? 'bold' : null}
                            FontSize="medium-1"
                            JustifyContent="flex-start"
                            NoMargin
                            OnClick={Selectable ? () => this.onChangeMaxType('Progress') : null}
                            Selectable={Selectable}
                            SelectedOptions={{ Position: 'left', Selected: MaxType === 'Progress' }}
                            Text={t('do_better_than_last_time')}
                            TextAlign="left"
                        />

                        <PageText
                            FontColor="black"
                            FontFamily={MaxType === 'AllTime' ? 'bold' : 'medium'}
                            FontFamilyHover={Selectable ? 'bold' : null}
                            FontSize="medium-1"
                            JustifyContent="flex-start"
                            NoMargin
                            OnClick={Selectable ? () => this.onChangeMaxType('AllTime') : null}
                            Selectable={Selectable}
                            SelectedOptions={{ Position: 'left', Selected: MaxType === 'AllTime' }}
                            Text={t('get_a_new_record')}
                            TextAlign="left"
                        />

                        <PageText
                            FontColor="black"
                            FontFamily={MaxType === 'Custom' ? 'bold' : 'medium'}
                            FontFamilyHover={Selectable ? 'bold' : null}
                            FontSize="medium-1"
                            JustifyContent="flex-start"
                            NoMargin
                            OnClick={Selectable ? () => this.onChangeMaxType('Custom') : null}
                            Selectable={Selectable}
                            SelectedOptions={{ Position: 'left', Selected: MaxType === 'Custom' }}
                            Text={t('custom')}
                            TextAlign="left"
                        />
                    </ExerciseSetTypeOptionsRowContainer>
                }

                {
                    ((DataType.indexOf('dynamic') > -1) && (MaxType !== 'Custom') && (ExerciseType === 4)) &&
                    <ExerciseSetTypeOptionsRowContainer className="ExerciseSetTypeOptionsRowContainer" FromAddWorkouts={FromAddWorkouts}>
                        <PageText
                            FontColor="black"
                            FontFamily={DataType === 'dynamic-weight' ? 'bold' : 'medium'}
                            FontFamilyHover={Selectable ? 'bold' : null}
                            FontSize="medium-1"
                            JustifyContent="flex-start"
                            NoMargin
                            OnClick={Selectable ? () => this.onChangeDataType('dynamic-weight') : null}
                            Selectable={Selectable}
                            SelectedOptions={{ Position: 'left', Selected: DataType === 'dynamic-weight' }}
                            Text={t('dynamic_sets_fill_in_reps_we_calc_weight')}
                            TextAlign="left"
                        />

                        <PageText
                            FontColor="black"
                            FontFamily={DataType === 'dynamic-reps' ? 'bold' : 'medium'}
                            FontFamilyHover={Selectable ? 'bold' : null}
                            FontSize="medium-1"
                            JustifyContent="flex-start"
                            NoMargin
                            OnClick={Selectable ? () => this.onChangeDataType('dynamic-reps') : null}
                            Selectable={Selectable}
                            SelectedOptions={{ Position: 'left', Selected: DataType === 'dynamic-reps' }}
                            Text={t('dynamic_sets_fill_in_weight_we_calc_reps')}
                            TextAlign="left"
                        />
                    </ExerciseSetTypeOptionsRowContainer>
                }
            </ExerciseSetTypeOptions>
        );
    }

    renderHeader = () => {
        var { t } = this.props;
        var { Device, Exercise: { ExerciseId, ExerciseImage, ExerciseName, ExerciseVideo, Sets: WorkoutSets }, FromAddWorkouts, InnerWidth, OnDeleteExercise, OnToggleCollapsed, OnToggleShowChangeExerciseModal, OnToggleShowExerciseDetailModal, OnToggleShowLearnMoreModal, ReadOnly } = this.props;

        var Buttons = [];
        !ReadOnly && Buttons.push({ BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', FontSize: 'small', OnClick: OnDeleteExercise, Title: t('delete_with_first_char_uppercase') });

        var DeleteButton = [{ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', FontSize: 'small', Icon: DeleteX, IconPosition: 'left', IconSize: { Height: '15px', Width: '15px' },  OnClick: OnDeleteExercise, Size: 'small', TextAlign: 'left', Title: t('delete_with_first_char_uppercase') }];
        var ChangeExerciseButton = [{ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', FontSize: 'small', Icon: SwapIcon, IconPosition: 'left', IconSize: { Height: '15px', Width: '15px' }, OnClick: OnToggleShowChangeExerciseModal, Size: 'small', TextAlign: 'left', Title: t('change_exercise') }];
        var EditSetsButton = [{ BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', FontSize: 'small', Icon: Edit, IconPosition: 'left', IconSize: { Height: '15px', Width: '15px' }, OnClick: OnToggleCollapsed, Size: 'small', TextAlign: 'left', Title: t('edit_workout_sets_stop') }];

        var FromAddWorkoutsButtons = [ ...DeleteButton, ...ChangeExerciseButton, ...EditSetsButton ];

        return (
            <WEHeaderContainer className="WEHeaderContainer" FromAddWorkouts={!!FromAddWorkouts}>
                <WEHeaderTopContainer className="WEHeaderTopContainer">
                    {
                        !!ExerciseVideo ?
                        <StyledExerciseVideo
                            autoplay={false}
                            controls={false}
                            muted
                            { ...ExerciseImage && { poster: ExerciseImage } }
                            onMouseOver={e => e.target.play()}
                            onMouseOut={e => e.target.pause()}
                            src={ExerciseVideo}
                            onClick={() => OnToggleShowExerciseDetailModal(true, { ExerciseId, ExerciseImage, ExerciseName })}
                        />
                    :
                        <StyledExerciseImage
                            Alt={ExerciseName}
                            ImageSrc={ExerciseImage}
                            OnClick={() => OnToggleShowExerciseDetailModal(true, { ExerciseId, ExerciseImage, ExerciseName })}
                            SelfLoading
                        />
                    }

                    {Device === 'laptop' ? this.renderExerciseSetTypeOptions() : <StyledExerciseName FontFamily="bold" FontSize="medium-2" JustifyContent="flex-start" NoMargin OnClick={() => OnToggleShowExerciseDetailModal(true, { ExerciseId, ExerciseImage, ExerciseName })} Text={ExerciseName} TextAlign="left" />}

                    {
                        InnerWidth >= 550 && !ReadOnly &&
                        <ButtonsContainer className="ButtonsContainer">
                            {
                                !!FromAddWorkouts ?
                                <StyledExerciseOptions Buttons={FromAddWorkoutsButtons} NotTouching OwnRows />
                            :
                                <>
                                    <StyledLearnButton
                                        Buttons={[{ BackgroundColor: 'white', BackgroundColorHover: 'white-hover', Border: 'none', Color: 'black', ColorHover: 'black', Icon: Info, IconPosition: 'top', FontFamily: 'medium', FontSize: 'small', OnClick: () => OnToggleShowLearnMoreModal(true), Title: t('User_manual') }]}
                                        ButtonWidth="75px"
                                    />

                                    {WorkoutSets.length > 0 && !ReadOnly && <StyledExerciseOptions Buttons={Buttons} ButtonWidth="75px" />}
                                </>
                            }
                        </ButtonsContainer>
                    }
                </WEHeaderTopContainer>

                {
                    InnerWidth < 550 && !ReadOnly &&
                    <ButtonsContainer className="ButtonsContainer" FromAddWorkouts={FromAddWorkouts}>
                        {
                            !!FromAddWorkouts ?
                            <StyledExerciseOptions Buttons={FromAddWorkoutsButtons} NotTouching OwnRows />
                        :
                            <>
                                <StyledLearnButton
                                    Buttons={[{ BackgroundColor: 'white', BackgroundColorHover: 'white-hover', Border: 'none', Color: 'black', ColorHover: 'black', Icon: Info, IconPosition: 'left', FontFamily: 'medium', FontSize: 'small', OnClick: () => OnToggleShowLearnMoreModal(true), Title: t('User_manual') }, ...Buttons]}
                                    NotTouching
                                />
                            </>
                        }
                    </ButtonsContainer>
                }

                {Device !== 'laptop' && this.renderExerciseSetTypeOptions()}
            </WEHeaderContainer>
        );
    }

    renderMaxSetOption = (MaxSet, WorkoutSetIndex) => {
        var { t } = this.props;
        var { ReadOnly } = this.props;

        if (ReadOnly && !MaxSet) return null;

        return (
            <StyledMaxSetButton className="StyledMaxSetButton" IsMax={MaxSet} onClick={ReadOnly ? null : () => this.onToggleMaxSet(MaxSet, WorkoutSetIndex)} ReadOnly={ReadOnly}>
                {t('max')}
            </StyledMaxSetButton>
        );
    }

    renderTemplateSets = ({ DataType, ExerciseType, Header_Reps, MaxType, WorkoutSets }) => {
        var { Device } = this.props;
        var { OnDeleteSet, ReadOnly } = this.props;

        return (
            <>
                {
                    WorkoutSets.map((WorkoutSet, WorkoutSetIndex) => {
                        var { Distance, MaxSet, Reps, Rest, Seconds, Units, Weight, WorkoutSetId } = WorkoutSet;

                        MaxSet = !!+MaxSet;

                        // Distance = +Distance;
                        // Reps = +Reps;
                        // Rest = +Rest;
                        // Seconds = +Seconds;
                        // Weight = +Weight;

                        if (DataType.indexOf('dynamic') > -1) Units = '%';

                        return (
                            <React.Fragment key={WorkoutSetIndex}>
                                {WorkoutSetIndex !== 0 && <Spacer Size="extra-small" />}

                                <WESetContainer className="WESetContainer" BorderColor="var(--orange)" MaxSet={MaxSet}>
                                    <WESetHeaderContainer className="WESetHeaderContainer" MaxSet={MaxSet}>
                                        <WESetAndMaxContainer className="WESetAndMaxContainer">
                                            <WESetAndMaxContainerLeft>
                                                <PageText FontColor="white" FontFamily="bold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={`${WorkoutSetIndex + 1}.`} TextAlign="left" />

                                                {this.renderMaxSetOption(MaxSet, WorkoutSetIndex)}
                                            </WESetAndMaxContainerLeft>
                                            
                                            {
                                                Device !== 'laptop' && !ReadOnly &&
                                                <WESetAndMaxContainerRight className="WESetAndMaxContainerRight">
                                                    <DeleteSetIcon Alt={`Delete set ${WorkoutSetId}`} ImageSrc={DeleteX} OnClick={!ReadOnly ? () => OnDeleteSet(WorkoutSetIndex) : () => null} />
                                                </WESetAndMaxContainerRight>
                                            }
                                        </WESetAndMaxContainer>
                                    </WESetHeaderContainer>
                                    <WESetDetailsContainer className="WESetDetailsContainer">
                                        {ExerciseType === 1 && <ExerciseTypeOne BreakTime={Rest} DataType={DataType} MaxSet={MaxSet} MaxType={MaxType} OnUpdateWorkoutSet={({ Reps, TotalBreak }) => this.onUpdateWorkoutSet(({ MaxSet, Reps, TotalBreak, WorkoutSetIndex }))} ReadOnly={ReadOnly} Reps={Reps} SetType="Template" Units={Units} />}
                                        {ExerciseType === 2 && <ExerciseTypeTwo BreakTime={Rest} DataType={DataType} MaxSet={MaxSet} MaxType={MaxType} OnUpdateWorkoutSet={({ Seconds, TotalBreak }) => this.onUpdateWorkoutSet(({ MaxSet, Seconds, TotalBreak, WorkoutSetIndex }))} ReadOnly={ReadOnly} Seconds={Seconds} SetType="Template" Units={Units} />}
                                        {ExerciseType === 3 && <ExerciseTypeThree BreakTime={Rest} DataType={DataType} Distance={Distance} MaxSet={MaxSet} MaxType={MaxType} OnUpdateWorkoutSet={({ Distance, TotalBreak }) => this.onUpdateWorkoutSet(({ Distance, MaxSet, TotalBreak, WorkoutSetIndex }))} ReadOnly={ReadOnly} SetType="Template" Units={Units} />}
                                        {ExerciseType === 4 && <ExerciseTypeFour BreakTime={Rest} DataType={DataType} Header_Reps={Header_Reps} MaxSet={MaxSet} MaxType={MaxType} OnUpdateWorkoutSet={({ Reps, TotalBreak, Weight }) => this.onUpdateWorkoutSet(({ MaxSet, Reps, TotalBreak, Weight, WorkoutSetIndex }))} ReadOnly={ReadOnly} Reps={Reps} SetType="Template" Units={Units} Weight={Weight} />}
                                        {ExerciseType === 5 && <ExerciseTypeFive  BreakTime={Rest} DataType={DataType} MaxSet={MaxSet} MaxType={MaxType} OnUpdateWorkoutSet={({ Seconds, TotalBreak, Weight }) => this.onUpdateWorkoutSet(({ MaxSet, Seconds, TotalBreak, Weight, WorkoutSetIndex }))} ReadOnly={ReadOnly} Seconds={Seconds} SetType="Template" Units={Units} Weight={Weight}/>}
                                    </WESetDetailsContainer>
                                    {
                                        Device === 'laptop' &&
                                        <WESetFooterContainer className="WESetFooterContainer" onClick={!ReadOnly ? () => OnDeleteSet(WorkoutSetIndex) : () => null}>
                                            {!ReadOnly && <DeleteSetIcon Alt={`Delete set ${WorkoutSetId}`} ImageSrc={DeleteX} />}
                                        </WESetFooterContainer>
                                    }
                                </WESetContainer>
                            </React.Fragment>
                        );
                    })
                }
            </>
        );
    }

    renderWorkoutSets = () => {
        var { t } = this.props;
        var { Exercise: { DataType, ExerciseType, Header_Reps, MaxType, Sets: WorkoutSets }, FromAddWorkouts, OnAddSet, ReadOnly } = this.props;

        ExerciseType = +ExerciseType;

        var HasMaxTypeAndNoMax = !ReadOnly && DataType !== 'static' && MaxType !== 'Custom' && WorkoutSets.filter(({ MaxSet }) => !!MaxSet).length === 0;

        return (
            <WESetsContainer className="WESetsContainer" FromAddWorkouts={FromAddWorkouts}>
                {
                    WorkoutSets.length > 0 &&
                    <>
                        {this.renderTemplateSets({ DataType, ExerciseType, Header_Reps, MaxType, WorkoutSets })}

                        {
                            HasMaxTypeAndNoMax &&
                            <>
                                <Spacer Size="extra-small" />

                                <PageText
                                    FontColor="orange"
                                    FontFamily="semibold"
                                    FontSize="medium-1"
                                    JustifyContent="flex-start"
                                    NoMargin
                                    Text={
                                        +ExerciseType === 4 ?
                                        t('dynamic_set_calculation_only_happens_on_max_set', {
                                            CalculationType: DataType === 'dynamic-weight' ? t('dynamic_sets_fill_in_reps_we_calc_weight') : t('dynamic_sets_fill_in_weight_we_calc_reps'),
                                            MaxType: MaxType === 'Progress' ? t('do_better_than_last_time') : t('get_a_new_record')
                                        })
                                    :
                                        t('dynamic_set_calculation_only_happens_on_max_set_2', {
                                            MaxType: MaxType === 'Progress' ? t('do_better_than_last_time') : t('get_a_new_record')
                                        })
                                    }
                                    TextAlign="left"
                                />
                            </>
                        }

                        {
                            !ReadOnly &&
                            <>
                                <Spacer Size="extra-small" />

                                <StyledAddAnotherSet
                                    Buttons={[{ Border: 'none', FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: OnAddSet, Title: t('add_another_workout_set') }]}
                                    ButtonWidth="fit-content"
                                />
                            </>
                        }
                    </>
                }
            </WESetsContainer>
        );
    }

    render() {
        var { t } = this.props;
        var { CircuitType, Exercise: { ExerciseId, Notes = {} }, FromAddWorkouts, ReadOnly } = this.props;

        return (
            <React.Fragment key={ExerciseId}>
                <WorkoutExerciseContainer className="WorkoutExerciseContainer" CircuitType={CircuitType}>
                    {this.renderHeader()}

                    {
                        !!FromAddWorkouts ?
                        <OuterWorkoutSetsContainer className="OuterWorkoutSetsContainer" CircuitType={CircuitType}>
                            {this.renderWorkoutSets()}
                        </OuterWorkoutSetsContainer>
                    :
                        <>
                            <Spacer Size="smallest" />
                            
                            {this.renderWorkoutSets()}
                        </>
                    }

                    {
                        !!ReadOnly ?
                        Notes.Coach ?
                        <ExerciseNotesContainer className="ExerciseNotesContainer">
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'medium-2', Text: `${t('_exercise_note_to_client')}: ` },
                                    { FontFamily: 'medium', FontSize: 'medium-2', Text: Notes.Coach }
                                ]}
                            />
                        </ExerciseNotesContainer>
                    :
                        null
                    :
                    <>
                        {!FromAddWorkouts && <Spacer Size="smallest" /> }

                        <ExerciseNotesContainer className="ExerciseNotesContainer" FromAddWorkouts={FromAddWorkouts}>
                            <ExerciseNotesTextArea
                                FromAddWorkouts={FromAddWorkouts}
                                Label={t('_exercise_note_to_client')}
                                LabelFontFamily="semibold"
                                LabelFontSize="medium-2"
                                OnBlur={this.props.OnChangeExerciseNote}
                                MaxCharacters={300}
                                NumRows={4}
                                Placeholder={t('_exercise_note_to_client')}
                                ShowCharacterCount
                                ShowExtraWillBeRemoved
                                Value={Notes.Coach}
                            />
                        </ExerciseNotesContainer>
                    </>
                    }
                </WorkoutExerciseContainer>
            </React.Fragment>
        );
    }
}

TemplateExercise.propTypes = {
    CircuitType: PropTypes.string,
    Exercise: PropTypes.object.isRequired,
    FromAddWorkouts: PropTypes.bool,
    OnAddSet: PropTypes.func,
    OnChangeDataType: PropTypes.func,
    OnDeleteExercise: PropTypes.func,
    OnDeleteSet: PropTypes.func,
    OnToggleCollapsed: PropTypes.func,
    OnToggleMaxSet: PropTypes.func,
    OnToggleShowChangeExerciseModal: PropTypes.func,
    OnToggleShowExerciseDetailModal: PropTypes.func,
    OnToggleShowLearnMoreModal: PropTypes.func,
    OnUpdateWorkoutSet: PropTypes.func,
    ReadOnly: PropTypes.bool
}

TemplateExercise.defaultProps = {
    CircuitType: null,
    FromAddWorkouts: false,
    ReadOnly: false
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        InnerWidth: state.Window.InnerWidth,
    };
};

export default withTranslation()(connect(mapStateToProps, { } )(TemplateExercise));