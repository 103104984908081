import styled from "styled-components";
import { device } from '../../../Config/device';

import Image from '../../../Components/Image';
import Input from '../../../Components/Form/Input';
import PageText from '../../../Components/Text/PageText';

export const CheckmarkIcon = styled(Image)`
    height: 14px;
    width: 14px;
`;

export const CheckmarkItemName = styled(PageText)`
    ${props => props.Disabled && 'color: var(--gray-silver);'}
    margin-left: 10px;

    ${
        props => props.TwoBoxes &&
        `
            flex: 1;
            margin-right: 10px;
        `
    }
`;

export const CustomizeSVGContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;

    > svg {
        height: 15px;
        width: 15px;
    }

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete-hover);
            cursor: pointer;
        }
    }
`;

export const EquipmentFilterContainer = styled.div`
    :not(:last-child) {
        margin-bottom: 25px;
    }
`;

export const FilterContainer = styled.div`
    align-items: flex-start;
    border-top: 2px solid var(--white-concrete);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px 0;
`;

export const FilterContainerHeader = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    > div:first-child {
        flex: 1;
        margin-right: 5px;
    }

    > div:not(:first-child) {
        margin-left: 5px;
    }

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const FiltersContainerHeaderRow1 = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const FiltersContainerHeaderRow2 = styled.div``;

export const FiltersContainer = styled.div`
    @media ${device.minwidth.laptop} {
        border-left: 2px solid var(--white-concrete);
        grid-area: filter;
        padding: 15px 30px;
    }
`;

export const FilterTagContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.JustifyContent ? props.JustifyContent : 'flex-start'};

    :not(:last-child) {
        margin-bottom: 7px;
    }
`;

export const FilterTagsListOptions = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    > div:first-child {
        margin-right: 10px;
    }
`;

export const StyledProfilePhoto = styled(Image)`
    height: 24px;
    margin-left: 10px;
    margin-right: -5px;
    width: 24px;

    ${props => !props.IsAbel && 'border-radius: 50%;'}
    overflow: hidden;
`;

export const StyledSearchInput = styled(Input)`
    flex: 1;

    > input {
        background-color: var(--white-concrete);
        border: none;
        border-radius: 10px;
    }
`;

export const TagsContainer = styled.div`
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
`;

export const ToggleIcon = styled.div`
    margin-left: 5px;

    > img {
        height: 10px;
    }
`;