import styled from "styled-components";
import { device } from '../../../Config/device';

import Image from '../../../Components/Image';
import PageText from '../../../Components/Text/PageText';

export const CheckmarkIcon = styled(Image)`
    height: 14px;
    width: 14px;
`;

export const CheckmarkItemName = styled(PageText)`
    ${props => props.Disabled && 'color: var(--gray-silver);'}
    margin-left: 10px;

    ${
        props => props.TwoBoxes &&
        `
            flex: 1;
            margin-right: 10px;
        `
    }
`;

export const FiltersContainer = styled.div`
    @media ${device.minwidth.laptop} {
        border-left: 2px solid var(--white-concrete);
        grid-area: filter;
        padding: 15px 30px;
    }
`;

export const MultiSelectRadioButton = styled.div`
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : props.ReadOnly ? 'var(--white-concrete)' : 'var(--white)'};
    border: 2px solid var(--white-concrete);
    border-radius: 50%;
    height: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            ${props => !props.ReadOnly ?
                `
                    background-color: var(--blue-astronaut);
                    cursor: pointer;
                `
            :
                `
                    cursor: not-allowed;
                `
            }
        }
    }
`;

export const MultiSelectRadioButtonContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: ${props => props.LeftAlign ? 'flex-start' : 'center'};
    flex-direction: column;

    ${props => props.ReadOnly &&
        `
            background-color: var(--white-concrete);
            border-radius: 10px;
        `
    }
`;

export const PseudoCheckbox = styled.div`
    ${
        props => props.Selected && `background-color: #${props.BackgroundColor};`
    }

    border: 2px solid var(--white-concrete);
    border-radius: 5px;
    height: 25px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            ${props => `background-color: #${props.BackgroundColor};`}
            cursor: pointer;
        }
    }
`;

export const RadioButtonPageText = styled(PageText)`
    @media(hover:hover) {
        :hover {
            ${props => !props.ReadOnly ? `cursor: pointer;` : `cursor: not-allowed;`}
        }
    }
`;

export const RadioButtonParentContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;

    > div:first-child {
        margin-right: 5px;
    }
`;

export const TimeRangeContainer = styled.div``;