import styled from 'styled-components';
import Input from '../../Components/Form/Input';

export const ActivityLevelContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const Button = styled.div`
    background-color: var(--blue-abel);
    border-radius: 10px;
    color: var(--white);
    padding: 10px 20px;

    :hover {
        background-color: var(--blue-abel-hover);
        cursor: pointer;
    }
`;

export const ButtonContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const Container = styled.div`
    width: 155px;
`;

export const EqualSignContainer = styled.div`
    align-items: flex-end;
    display: flex;
    justify-content: center;
    margin: 0 10px 7px;
`;

export const StyledInput = styled(Input)`
    > input {
        border: 2px solid var(--white-concrete);
        border-radius: 10px;
        text-align: center;
        text-indent: 0px;
    }
`;