import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import { AnnotationContainer, AnnotationsContainer, VideoDetailContainer } from './styles';

import MultiText from '../../Components/Text/MultiText';

import { SecondsToTime } from '../../Functions';

class Video extends React.Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
    }

    onClick = () => {
        
    }

    onSeekTo = Seconds => {
        this.player.current.seekTo(Seconds);
    }

    renderAnnotations = () => {
        var { Annotations } = this.props;

        return (
            <AnnotationsContainer>
                {
                    Annotations.map(({ Seconds, Title }, AnnotationIndex) => {
                        var Texts = [{ FontColor: 'blue-abel', FontColorHover: 'blue-astronaut', FontFamily: 'medium', FontSize: 'medium-1', OnClick: this.onClick, Text: Title }, { FontFamily: 'bold', FontSize: 'medium-1', OnClick: this.onClick, Text: SecondsToTime(Seconds) }];

                        return (
                            <AnnotationContainer key={AnnotationIndex} onClick={() => this.onSeekTo(Seconds)}>
                                <MultiText Texts={Texts} />
                            </AnnotationContainer>
                        );
                    })
                }
            </AnnotationsContainer>
        );
    }
    
    render() {
        var { Annotations, Url } = this.props;

        return (
            <VideoDetailContainer>
                <div className='player-wrapper'>
                    <ReactPlayer
                        ref={this.player}
                        className='react-player'
                        controls
                        url={Url}
                        width='100%'
                        height='100%'
                    />
                </div>

                {Annotations.length > 0 && this.renderAnnotations()}
            </VideoDetailContainer>
        );
    }
}

Video.propTypes = {
    Annotations: PropTypes.array,
    Url: PropTypes.string.isRequired
}

export default Video;