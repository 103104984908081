import styled from 'styled-components';
import { device } from '../../Config/device';

import Icon from '../../Components/Icon';
import PageText from '../../Components/Text/PageText';

export const ContractContainer = styled.div`
    align-items: center;
    background-color: ${props => !!props.Header ? 'var(--white)' : 'var(--white-concrete)'};
    border-radius: 10px;
    display: grid;
    grid-template-columns: 0.5fr 2fr 2fr 1fr 1fr 1fr 1fr 1fr 1.5fr;
    padding: 5px 7px;
`;

export const DetailText = styled.div`
    ${props => props.FontColor && `color: var(--${props.FontColor});`}
    font-family: ${props => props.FontFamily ? `var(--font-family-${props.FontFamily})` :  'var(--font-family-medium)'};
    font-size: var(--font-size-small);
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${props => props.Underline && 'text-decoration: underline;'}
`;

export const FilterContainer = styled.div``;

export const InfoContainer = styled.div`
    overflow: hidden;
    padding: 0px 3px;

    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const InvoicesContainerMobile = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
`;

export const InvoiceContainerMobile = styled.div`
    align-items: flex-start;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
`;

export const InfoContainerMobile = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const InvoiceContainer = styled.div`
    align-items: center;
    background-color: ${props => !!props.Header ? 'var(--white)' : 'var(--white-concrete)'};
    border-radius: 10px;
    display: grid;
    grid-template-columns: 0.5fr 2fr 2fr 1fr 1fr 1fr 1fr 1.5fr 1fr;
    padding: 5px 7px;
`;

export const InvoicesContainer = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 10px;
`;

export const LeftPagination = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    ${props => !props.HasPrevLink && 'margin-left: -10px;'}
`;

export const OnTimeContainer = styled.div``;

export const PageNumberContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    border-radius: 10px;
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    display: flex;
    flex-direction: row;
    font-family: var(--font-family-medium);
    font-size: var(--font-size-medium-1);
    height: 35px;
    justify-content: center;
    width: 35px;

    @media(hover:hover) {
        :hover {
            background-color: var(--blue-astronaut);
            color: var(--white);
            cursor: pointer;
        }
    }
`;

export const PageNumbersContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 10px;

    > div:not(:last-child) {
        margin-right: 10px;
    }
`;

export const PaginationContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media ${device.maxwidth.mobile_small} {
        flex-direction: column;
        justify-content: center;
    }
`;

export const ProductContainerMobile = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

export const ProductListContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

export const ProductsListBlockRowContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 30px;

    > div:first-child {
        margin-right: 5px;
    }
`;

export const RightPagination = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const SortingIcon = styled(Icon)`
    height: 15px;
    max-width: 15px;
`;

export const StyledEllipsisText = styled(PageText)`
    > div {
        display: inline;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const ViewCount = styled.div`
    text-align: center;
`;