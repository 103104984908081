import styled from 'styled-components';
import { device } from '../../Config/device'

import Input from '../../Components/Form/Input';
import LoadMore from '../../Components/Lists/LoadMore';

export const StyledInput = styled(Input)`
    > input {
        border: 2px solid var(--white-concrete);
    }
`;

export const StyledLoadMore = styled(LoadMore)`
    border: none;
    border-radius: 0px;

    > div:first-child {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(1, 1fr);

        @media ${device.minwidth.mobile} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media ${device.minwidth.ipad} {
            grid-template-columns: repeat(3, 1fr);
        }

        @media ${device.minwidth.laptop} {
            grid-template-columns: repeat(4, 1fr);
        }
    }
`;