import styled from 'styled-components';
import { device } from '../../Config/device';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Image from '../../Components/Image';

export const CustomizationContainer = styled.div`
    margin-bottom: 15px;
    padding: 0px 15px;
`;

export const CustomizationItemContainer = styled.div`
    :not(:last-child) {
        margin-bottom: 20px;
    }
`;

export const CustomizationItemContainerHeader = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 4px;
`;

export const DayContainer = styled.div``;

export const DayNumber = styled.div`
    border: 2px solid #F2F2F2;
    border-radius: 10px;
    font-family: "Branding-Semibold";
    font-size: 22px;
    padding: 8px;
    text-align: center;
`;

export const DaysContainer = styled.div`
    display: grid;
    grid-gap: 40px 15px;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
`;

export const RestDayContainer = styled.div`
    align-items: center; 
    background-color: var(--orange);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    padding: 10px;
`;

export const RestDayImage = styled(Image)`
    // height: 20px;
    max-width: 80px;

    // margin-right: 7px;
`;

export const StyledHeaderButtonGroup = styled(ButtonGroup)`

    @media ${device.minwidth.ipad} {
        > div {
            max-width: ${props => props.Buttons.length === 2 ? '50%' : '33%'};
            white-space: nowrap;
    
            > div {
                > div {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
`;

export const WorkoutTemplateContainer = styled.div`
    background-color: var(--white-concrete-light);
    border-radius: 10px;
    margin: 20px 0px;
    overflow: hidden;

    &:last-child {
        margin-bottom: 0px;
    }
`;