import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { ButtonContainer, ButtonTitle, Image } from './styles';

import X from '../../../Assets/Icons/X.png';

class ModalButton extends React.Component {
    render() {
        var { t } = this.props;
        var { ButtonText, Disabled, OnClick, Position, Type } = this.props;

        ButtonText = ButtonText || (Type.toLowerCase() === 'cancel' ? 'X' : t('save_with_first_char_uppercase'));

        return (
            <ButtonContainer
                Disabled={Disabled}
                onClick={OnClick}
                Position={Position}
                Type={Type}
            >
                <ButtonTitle Disabled={Disabled} Type={Type}>
                    {
                        ButtonText === 'X' ?
                        <Image src={X} alt="close modal" />
                    :
                        ButtonText
                    }
                </ButtonTitle>
            </ButtonContainer>
        );
    }
}

ModalButton.propTypes = {
    ButtonText: PropTypes.string,
    Disabled: PropTypes.bool,
    OnClick: PropTypes.func,
    Position: PropTypes.string.isRequired,
    Type: PropTypes.string.isRequired
}

export default withTranslation()(ModalButton);