import React from 'react';
import { withTranslation } from 'react-i18next';
import Select, { components } from 'react-select';

import * as styles from './filter_styles';

import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

const customStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "initial"
    }),
    input: (provided, state) => ({
        ...provided,
        minWidth: '20%'
    })
}

const ValueContainer = ({ selectProps, children, ...props }) => {
    let [values, input] = children;

    if (Array.isArray(values)) {
        values = selectProps.value.map((x) => x.DisplayName).join(', ');
    }

    return (
      <components.ValueContainer {...props}>
        <div
            style={{
                maxWidth: "80%",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                marginBottom: '0px'
            }}
        >
            {values}
        </div>
        {input}
      </components.ValueContainer>
    );
};

class DataCenterDashboardFiltersComponent extends React.Component {
    onChangeInput = ({ event, ContentType }) => {
        this.props.OnTypeSearchInput({ ContentType, Text: event.target.value });
    }

    renderSearchFilter = ({ ContentType, Label }) => {
        var { t } = this.props;
        // var { Loading, OnChangeFilter } = this.props;

        // var FilterContent = this.props[ContentType];
        // var SelectedContent = this.props.SelectedContent ? this.props.SelectedContent[ContentType] : [];

        return (
            <styles.FilterContainer className="FilterContainer">
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`..${t(Label)}`} />

                <Spacer Size="extra-extra-small" />
                
                <input
                    type="text"
                    onChange={event => this.onChangeInput({ event, ContentType })}
                />
            </styles.FilterContainer>
        );
    }

    renderSelectFilter = ({ ContentType, ContentTypeId, Label }) => {
        var { t } = this.props;
        var { Loading, OnBlurFilter, OnChangeFilter, OnFocusFilter } = this.props;

        var FilterContent = this.props[ContentType];
        var SelectedContent = this.props.SelectedContent ? this.props.SelectedContent[ContentType] : [];

        return (
            <styles.FilterContainer className="FilterContainer">
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`${t(Label)}`} />

                <Spacer Size="extra-extra-small" />
                
                <Select
                    closeMenuOnSelect
                    defaultValue={SelectedContent}
                    getOptionLabel={option => option.DisplayName}
                    getOptionValue={option => option[ContentTypeId]}
                    isLoading={Loading}
                    isMulti={false}
                    components={{ ValueContainer }}
                    hideSelectedOptions={false}
                    isSearchable
                    onBlur={OnBlurFilter}
                    onChange={Data => OnChangeFilter({ Data, Type: ContentType })}
                    onFocus={OnFocusFilter}
                    options={FilterContent}
                    placeholder={t('_select')}
                    styles={customStyles}
                />
            </styles.FilterContainer>
        );
    }

    render() {
        var { IsModal, Loading: IsLoading } = this.props;

        return (
            <>
                {IsModal && IsLoading && <Loading />}

                <styles.FiltersContainer className="FiltersContainer">
                    {this.renderSelectFilter({ ContentType: 'Organizations', ContentTypeId: 'OrganizationId', Label: 'Organizations'})}
                </styles.FiltersContainer>
            </>
        );
    }
}

export default withTranslation()(DataCenterDashboardFiltersComponent);