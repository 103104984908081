import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as styles from './styles';

import AbelModal from '../../Components/Modal';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import ExerciseDetail from './ExerciseDetail';
import Loading from '../../Components/Loading';
import MealTemplate from './MealTemplate';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import TrainingProgram from './TrainingProgram';
import WorkoutTemplate from './WorkoutTemplate';

import InfoIcon from '../../Assets/Icons/Info.png';
import PlusIcon from '../../Assets/Icons/PlusButton_Active.png';

import { emailValidator } from '../../Functions';

class ShareContentModal extends React.Component {
    _isMounted = false;

    state = { ContentType: null, Emails: [], SharingContent: [] };

    componentDidMount() {
        this._isMounted = true;

        var { ContentType, Emails, SharingContent } = this.props;

        if (!this.props.Emails) Emails = [{ Email: '', IsValid: true }];

        this.setState({ ContentType, Emails, SharingContent });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.SharingContent !== this.props.SharingContent) this.setState({ SharingContent: this.props.SharingContent });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onAddEmail = () => {
        this.setState(prevState => ({ ...prevState, Emails: [ ...prevState.Emails, { Email: '', IsValid: true } ] }));
    }

    onBlurEmail = ({ EmailIndex }) => {
        var BlurEmails = [ ...this.state.Emails ];

        // var Emails = BlurEmails[EmailIndex].Email.split(',');
        var Emails = BlurEmails[EmailIndex].Email.split(/\s*[\s,]\s*/);

        for (var i = 0; i < Emails.length; i++) {
            var Email = {};
            Email.Email = Emails[i];
            Email.IsValid = emailValidator(Emails[i]);

            if (i === 0) BlurEmails[EmailIndex] = Email;
            else BlurEmails = [ ...BlurEmails, Email ];
        }

        // var Email = BlurEmails[EmailIndex];
        // Email.IsValid = emailValidator(Email.Email);

        this.setState({ Emails: BlurEmails });
    }

    onChangeEmail = ({ event, EmailIndex }) => {
        if (event.target.value[event.target.value.length - 1] === ',') return this.onAddEmail();

        var ChangeEmails = [ ...this.state.Emails ];
        ChangeEmails[EmailIndex].Email = event.target.value;

        this.setState({ Emails: ChangeEmails });
    }

    onClickSubmit = () => {
        var Emails = [ ...new Set(this.state.Emails.filter(Email => (!!Email.Email && !!Email.IsValid)).map(Email => Email.Email)) ];
        var ContentIds = [ ...new Set(this.state.SharingContent.filter(SharedContent => ((+SharedContent.OwnerId === +this.props.UserDetails.UserId || +SharedContent.UserId === +this.props.UserDetails.UserId) || (SharedContent.ShareAbilityStatusId !== 3 && +SharedContent.OwnerId !== 16))).map(Content => Content[this.props.ContentIdName])) ];

        this.props.OnSubmit({ Emails, ContentIds });
    }

    onDeleteEmail = EmailIndex => {
        var DeleteEmail = [ ...this.state.Emails ];
        DeleteEmail = DeleteEmail.splice(EmailIndex, 1);

        this.setState({ Emails: DeleteEmail });
    }

    renderAddEmails = () => {
        var { t } = this.props;
        var { Emails } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('add_new_coach_by_email_title')} TextAlign="center" />

                <Spacer Size="extra-extra-small" />

                <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text={t('_add_multiple_coaches_type_in_emails')} TextAlign="center" />

                <Spacer Size="small" />

                <styles.EmailsContainer className="EmailsContainer">
                    {
                        Emails.map(({ Email, IsValid }, EmailIndex) => {
                            return (
                                <React.Fragment key={EmailIndex}>
                                    <styles.StyledEmailInput
                                        FontSize="medium-2"
                                        NoLabel
                                        OnBlur={() => this.onBlurEmail({ EmailIndex })}
                                        OnChange={(event) => this.onChangeEmail({ event, EmailIndex })}
                                        Placeholder={t('email_with_first_char_uppercase')}
                                        Size="medium"
                                        Type="text"
                                        Value={Email}
                                    />
                                    {
                                        !!Email.length && !IsValid &&
                                        <>
                                            <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('provide_valid_email_address')} TextAlign="center" />

                                            <Spacer Size="extra-extra-small" />
                                        </>
                                    }
                                </React.Fragment>
                            );
                        })
                    }
                </styles.EmailsContainer>

                <Spacer Size="small" />

                <styles.AddCoachTextContainer className="AddCoachTextContainer">
                    <styles.AddCoachPlusIcon ImageSrc={PlusIcon} OnClick={this.onAddEmail} />

                    <PageText FontColor="blue-astronaut" FontColorHover="blue-astronaut-hover" FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin OnClick={this.onAddEmail} Text={t('add_new_coach')} TextAlign="center" />
                </styles.AddCoachTextContainer>
            </>
        );
    }

    renderModalBody = () => {
        var { t } = this.props;

        return (
            <>
                {this.props.Loading && <Loading />}

                <styles.Container>
                    <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('share_content_with_other_coaches')} TextAlign="center" />

                    <Spacer Size="small" />

                    <ButtonGroup Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', FontFamily: 'medium', FontSize: 'medium-1', Icon: InfoIcon, IconPosition: 'left', OnClick: this.props.OnToggleShowHowSharingWorksModal, Title: t('sharing_details_how_it_works_and_what_it_is') }]} />

                    <Spacer Size="small" />

                    {this.renderSharingContent()}

                    <Spacer Size="medium" />

                    {this.renderAddEmails()}

                    <Spacer Size="large" />

                    <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text={t('share_content_with_other_coaches_note')} TextAlign="center" />
                </styles.Container>
            </>
        );
    }

    renderSharingContent = () => {
        var { UserDetails } = this.props;
        var { ContentType, SharingContent } = this.state;

        return (
            <styles.SharingContentContainer className="SharingContentContainer">
                {
                    SharingContent.map((SharedContent, index) => {
                        return (
                            <React.Fragment key={index}>
                                {ContentType === 'Exercises' && <ExerciseDetail AuthedUserId={+UserDetails.UserId} Content={SharedContent} OnToggleShowEditShareAbilityStatusModal={({ ContentId, CurrentStatus }) => this.props.OnToggleShowEditShareAbilityStatusModal({ ContentId, ContentIndex: index, CurrentStatus })} />}
                                {ContentType === 'MealTemplates' && <MealTemplate AuthedUserId={+UserDetails.UserId} Content={SharedContent} OnToggleShowEditShareAbilityStatusModal={({ ContentId, CurrentStatus }) => this.props.OnToggleShowEditShareAbilityStatusModal({ ContentId, ContentIndex: index, CurrentStatus })} />}
                                {ContentType === 'TrainingPrograms' && <TrainingProgram AuthedUserId={+UserDetails.UserId} Content={SharedContent} OnToggleShowEditShareAbilityStatusModal={({ ContentId, CurrentStatus }) => this.props.OnToggleShowEditShareAbilityStatusModal({ ContentId, ContentIndex: index, CurrentStatus })} />}
                                {ContentType === 'WorkoutTemplates' && <WorkoutTemplate AuthedUserId={+UserDetails.UserId} Content={SharedContent} OnToggleShowEditShareAbilityStatusModal={({ ContentId, CurrentStatus }) => this.props.OnToggleShowEditShareAbilityStatusModal({ ContentId, ContentIndex: index, CurrentStatus })} />}
                            </React.Fragment>
                        );
                    })
                }
            </styles.SharingContentContainer>
        );
    }

    render() {
        var { t } = this.props;
        var { DisplayNone, OnHide, Show, UserDetails } = this.props;
        var { ContentType, Emails, SharingContent } = this.state;

        var BottomButtonOnClick = OnHide;
        var BottomButtonText = t('_got_it');
        var ShowNoSharingScreen = true;

        for (var i = 0; i < SharingContent.length; i++) {
            if (+SharingContent[i].OwnerId !== this.props.UserDetails.UserId && +SharingContent[i].OwnerId !== 16 && SharingContent[i].ShareAbilityStatusId !== 3) {
                BottomButtonOnClick = this.onClickSubmit;
                BottomButtonText = t('share');
                ShowNoSharingScreen = false;
                break;
            }
        }

        return (
            <AbelModal
                BottomButton="submit"
                BottomButtonOnClick={BottomButtonOnClick}
                BottomButtonText={BottomButtonText}
                Disabled={ShowNoSharingScreen ? false : !ContentType || !Emails.filter(Email => !!Email.Email).length || !SharingContent.filter(SharedContent => (+SharedContent.UserId === +UserDetails.UserId || SharedContent.ShareAbilityStatusId !== 3)).length}
                DisplayNone={DisplayNone}
                GA_PathName="ShareContent"
                Show={Show}
                Size="medium"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {
                    ShowNoSharingScreen ?
                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('_the_content_cant_be_shared_to_coaches')} TextAlign="center" />
                :
                    this.renderModalBody()
                }
            </AbelModal>
        );
    }
}

ShareContentModal.propTypes = {
    ContentIdName: PropTypes.string.isRequired,
    ContentType: PropTypes.string.isRequired,
    DisplayNone: PropTypes.bool.isRequired,
    Emails: PropTypes.array,
    Loading: PropTypes.bool,
    OnHide: PropTypes.func.isRequired,
    OnSubmit: PropTypes.func.isRequired,
    OnToggleShowEditShareAbilityStatusModal: PropTypes.func.isRequired,
    OnToggleShowHowSharingWorksModal: PropTypes.func.isRequired,
    SharingContent: PropTypes.array.isRequired,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        UserDetails: state.Auth.UserDetails
    };
};

export default withTranslation()(connect(mapStateToProps, null )(ShareContentModal));