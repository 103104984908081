import {
    TRYING_DELIVERY_SITE_ACTION,
    TRYING_DELIVERY_SITE_ACTION_SUCCESS,
    TRYING_DELIVERY_SITE_ACTION_ERROR
} from '../Types';

const INITIAL_STATE = {
    TryingDeliverySiteAction: false,
    TryingDeliverySiteActionError: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_DELIVERY_SITE_ACTION:
            return { ...state, TryingDeliverySiteAction: true, TryingDeliverySiteActionError: null };
        case TRYING_DELIVERY_SITE_ACTION_ERROR:
            return { ...state, TryingDeliverySiteAction: false, TryingDeliverySiteActionError: action.payload };
        case TRYING_DELIVERY_SITE_ACTION_SUCCESS:
            return { ...state, TryingDeliverySiteAction: false, TryingDeliverySiteActionError: null };
        default:
            return state;
    }
};
