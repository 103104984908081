import styled from 'styled-components';

import Checkmark from '../../Assets/Icons/Checkmark_Selected.png';

export const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const DateTimeUseAllDays = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    display: block;
    height: 25px;
    margin-right: 5px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const DateTimeUseAllDaysContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 10px;
`;