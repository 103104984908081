import React from 'react';
import PropTypes from 'prop-types';

import { ImageContainer, Overlay, StyledImage } from './styles';

import LoadingImage from '../../Assets/Icons/LoadingImage.png';
import NoImage from '../../Assets/Icons/NoImage.png';

class Image extends React.Component {
    state = { LoadedImage: false, Visible: false };

    handleImageError = event => {
        event.target.src = this.props.NoImage || NoImage;

        if (this.props.HandleImageError) this.props.HandleImageError();
    }

    onLoadedImage = () => {
        var { OnLoaded, SelfLoading } = this.props;

        if (!SelfLoading && OnLoaded) OnLoaded();
        else this.setState({ LoadedImage: true });
    }

    onToggleOverlay = Visible => {
        if (!this.props.ShowOverlay) return;

        this.setState({ Visible });
    }

    render() {
        var { Alt, className, ContainerWidth, ForceOverlay, ImageSrc, Loading, LoadingImageSrc, Margin, NoParent, OnClick, OverlayColor, RoundImage, SelfLoading, ShowOverlay } = this.props;
        var { LoadedImage, Visible } = this.state;

        var src = ImageSrc ? ((SelfLoading && !LoadedImage) || Loading) ? LoadingImageSrc ? LoadingImageSrc : LoadingImage : ImageSrc : this.props.NoImage ? this.props.NoImage : NoImage;

        if (NoParent) {
            return (
                <StyledImage
                    alt={Alt || 'ABEL Technologies'}
                    className={className}
                    onClick={OnClick}
                    onLoad={this.onLoadedImage}
                    onError={this.handleImageError}
                    src={src}
                />
            );
        }
        
        return (
            <ImageContainer
                ContainerWidth={ContainerWidth}
                Margin={Margin}
                onMouseEnter={() => this.onToggleOverlay(true)}
                onMouseLeave={() => this.onToggleOverlay(false)}
                RoundImage={RoundImage}
            >
                <StyledImage
                    alt={Alt || 'ABEL Technologies'}
                    className={className}
                    onClick={OnClick}
                    onLoad={this.onLoadedImage}
                    onError={this.handleImageError}
                    src={src}
                />
                {ForceOverlay || (ShowOverlay && Visible) ? <Overlay onClick={OnClick} OverlayColor={OverlayColor} RoundImage={RoundImage} /> : null}
            </ImageContainer>
        );
    }
}

Image.propTypes = {
    Alt: PropTypes.string,
    ContainerWidth: PropTypes.string,
    ForceOverlay: PropTypes.bool,
    HandleImageError: PropTypes.func,
    ImageSrc: PropTypes.string.isRequired,
    Loading: PropTypes.bool,
    LoadingImageSrc: PropTypes.string,
    Margin: PropTypes.string,
    NoImage: PropTypes.string,
    NoParent: PropTypes.bool,
    OnClick: PropTypes.func,
    OnLoaded: PropTypes.func,
    OverlayColor: PropTypes.string,
    RoundImage: PropTypes.bool,
    SelfLoading: PropTypes.bool,
    ShowOverlay: PropTypes.bool
}

Image.defaultProps = {
    ImageSrc: NoImage
}

export default Image;