import styled from 'styled-components';
import { device } from '../../../../Config/device';

import BottomBar from '../../../../Components/BottomBar';
import ButtonGroup from '../../../../Components/Buttons/ButtonGroup';
import Icon from '../../../../Components/Icon';
import Image from '../../../../Components/Image';
import LoadMore from '../../../../Components/Lists/LoadMore';
import PageText from '../../../../Components/Text/PageText';
import SAYT from '../../../../Components/Form/SAYT';

export const AddCategoryIcon = styled(Icon)`
    height: 21px;
    width: 21px;
`;

export const AddRemoveTagsButtons = styled(ButtonGroup)`
    margin-left: 10px;
`;

export const BodyContainer = styled.div`
    grid-area: body;
    padding: 15px 30px;
`;

export const BodyHeader = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    ${
        props => !!props.IsModal &&
        `
            align-items: flex-start;
            flex-direction: column;
        `
    }

    @media ${device.maxwidth.ipad} {
        align-items: flex-start;
        flex-direction: column;
    }
`;

export const BodyHeaderRight = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    ${
        props => !props.IsModal ? `
            @media ${device.minwidth.laptop} {
                margin-left: 10px;
            }
        `
    :
        `
            align-items: flex-start;
            flex-direction: column;
            gap: 15px;
            margin-top: 10px;
        `
    }

    ${
        props => !props.ShowTrash && `
        
            > div:first-child {
                margin-right: 30px;
            }
        `
    }
`;

export const BottomBarItems = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    @media ${device.maxwidth.mobile} {
        :first-child {
            margin-bottom: 15px;
        }

        ${props => !!props.FirstOne && `flex-direction: column;`}
    }

    @media ${device.minwidth.ipad} {
        justify-content: flex-start;

        :last-child {
            justify-content: flex-end;
        }
    }
`;

export const BottomBarLeftContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const BottomBarRightContainer = styled.div`
    @media ${device.maxwidth.mobile} {
        margin-bottom: 10px;
    }

    @media ${device.minwidth.ipad} {
        margin-left: 20px;
    }
`;

export const CategoriesContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-top: -15px;
`;

export const CategoryContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border: 2px solid ${props => !!props.Selected ? 'var(--blue-abel)' : 'var(--white-concrete)'};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
    margin-top: 15px;
    padding: 10px 15px;
    width: fit-content;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete-hover);
            border: 2px solid ${props => !!props.Selected ? 'var(--blue-abel)' : 'var(--white-concrete-hover)'};
            cursor: pointer;
        }
    }
`;

export const CheckmarkIcon = styled(Image)`
    height: 14px;
    width: 14px;
`;

export const CheckmarkItemName = styled(PageText)`
    ${props => props.Disabled && 'color: var(--gray-silver);'}
    margin-left: 10px;

    ${
        props => props.TwoBoxes &&
        `
            flex: 1;
            margin-right: 10px;
        `
    }
`;

export const CheckmarkTextContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 7px;
`;

export const DeleteExercisesList = styled.div`
    display: grid;
    grid-gap: 40px 15px;
    grid-template-columns: repeat(1, 2fr);
    justify-content: center;

    @media ${device.minwidth.mobile} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.minwidth.laptop} {
        grid-template-columns: repeat(4, 1fr);
    }
`;

export const EquipmentFilterContainer = styled.div`
    :not(:last-child) {
        margin-bottom: 25px;
    }
`;

export const FilterSectionContainer = styled.div``;

export const FiltersContainer = styled.div`
    > div:not(:last-child) {
        margin-bottom: 30px;
    }
`;

export const FilterTagContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.JustifyContent ? props.JustifyContent : 'flex-start'};
    max-width: 100%;
    width: 200px;

    :not(:last-child) {
        margin-bottom: 7px;
    }
`;

export const PendingSharedContainer = styled.div`
    align-items: stretch;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15px;
    padding: 10px 15px;
`;

export const PendingSharedImage = styled(Image)`
    max-width: 100%;
    width: 125px;
`;

export const PendingSharedLeftContainer = styled.div`
    margin-right: 25px;
`;

export const PendingSharedRightContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`;

export const PendingSharingRightTopContainer = styled.div``;

export const SearchAndCategoriesContainer = styled.div`
    ${
        props => !!props.IsModal ?
        `
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-top: -15px;
        `
    :
        `
            @media ${device.minwidth.laptop} {
                align-items: flex-start;
                display: flex;
                flex-wrap: wrap;
                margin-right: -15px;
                margin-top: -15px;
            }
        
            @media ${device.maxwidth.ipad} {
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 15px;
            }   
        `
    }
`;

export const SearchAndCategoriesTopContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 15px;
    margin-right: 15px;

    ${props => !!props.IsModal && 'margin-bottom: 15px;'}
`;

export const SelectedItems = styled(PageText)`
    background-color: var(--blue-abel);
    border-radius: 20px;
    padding: 5px 15px;
`;

// export const StyledBottomBar = styled.div`
//     background-color: var(--white-concrete);
//     bottom: 0;
//     left: 0;
//     position: fixed;
//     right: 0;
//     z-index: 2;

//     @media ${device.minwidth.mobile_small} { width: 100%; }
//     @media ${device.minwidth.mobile} { width: 100%; }
//     @media ${device.minwidth.ipad} { padding: 20px 0; width: 87%; }
//     @media ${device.minwidth.laptop} { width: 85%; }

//     align-items: center;
//     display: flex;
//     justify-content: space-between;
//     margin: 0 auto;
//     padding: 20px;
// `;

export const StyledBottomBar = styled(BottomBar)`
    background-color: var(--white-concrete);

    > div {
        flex-direction: column;

        @media ${device.minwidth.ipad} {
            flex-direction: row;
        }
    }
`;

export const StyledLoadMore = styled(LoadMore)`
    background-color: var(--white);
    ${props => !props.IsModal && 'border: none; padding: 15px 0px;'}

    > div:first-child {
        display: grid;
        grid-gap: 40px 15px;
        grid-template-columns: 1fr;

        @media ${device.minwidth.mobile} {
            grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        }

        // @media ${device.minwidth.ipad} {
        //     grid-template-columns: repeat(4, 1fr);
        // }

        // @media ${device.minwidth.laptop} {
        //     grid-template-columns: repeat(7, 1fr);
        // }

        // grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        // max-width: 100%;
    }
`;

export const StyledProfilePhoto = styled(Image)`
    height: 24px;
    margin-left: 10px;
    margin-right: -5px;
    width: 24px;

    ${props => !props.IsAbel && 'border-radius: 50%;'}
    overflow: hidden;
`;

export const StyledSAYT = styled(SAYT)`
    ${
        props => !props.IsModal &&
        `
            @media ${device.minwidth.laptop} {
                margin-right: 15px;
                margin-top: 15px;
            }
        `
    }
`;

export const TagsContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ToggleContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    > div:first-child {
        margin-right: 10px;
    }
`;