import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DeleteProducts, InsertEditProduct, SearchCurrencies, SearchProducts } from '../../Services/Actions';

import { StyledLoadMore } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import { ProductListItem } from '../../Components/Lists/ListItems';
import Spacer from '../../Components/Spacer';

import Info from '../../Modals/Info';

import Plus from '../../Assets/Icons/Plus.png';

class Products extends React.Component {
    state = {
        AddProduct: { CurrencyId: null, CurrencyName: '', Editing: true, Price: 0, ProductId: 0, ProductName: '', ProductDescription: '' },
        DeleteProduct: {},
        IsMoreResults: false,
        PageNo: 1,
        PageSize: 30,
        Products: [],
        ShowAddProduct: false,
        ShowDeleteProductsModal: false,
        TotalRecords: 0
    }

    componentDidMount() {
        if (this.props.Currencies.length === 0) this.props.SearchCurrencies().then(() => {
            var DefaultCurrency = this.props.Currencies.filter(({ CurrencyId }) => +CurrencyId === +this.props.UserDetails.CurrencyId);
            if (!Object.keys(DefaultCurrency).length) DefaultCurrency = { CurrencyId: 3, CurrencyName: 'NOK' };

            this.setState({ AddProduct: { ...this.state.AddProduct, ...DefaultCurrency } });
        });

        this.onLoadProducts();
    }

    onCancelNewProduct = () => {
        this.onToggleShowAddProduct(false);
    }

    onDeleteProduct = () => {
        var { ProductId, ProductIndex } = this.state.DeleteProduct;
        
        this.props.DeleteProducts({ ProductIds: [ ProductId ] }).then(() => {
            if (!this.props.TryingDeleteProductsError) {
                var Products = [ ...this.state.Products.slice(0, ProductIndex), ...this.state.Products.slice(ProductIndex + 1) ];

                this.setState({ DeleteProduct: {}, Products, ShowDeleteProductsModal: false, TotalRecords: this.state.TotalRecords - 1 });
            }
        })
    }

    onInsertEditProduct = ProductDetails => {
        var { ProductId, ProductIndex } = ProductDetails;

        this.props.InsertEditProduct({ ...ProductDetails }).then(({ ProductId: NewProductId }) => {
            if (!this.props.TryingInsertEditProductError) {
                var Products = [ ...this.state.Products ];
                var TotalRecords = this.state.TotalRecords;

                // New Product
                    if (!!!+ProductId) {
                        Products[0] = { ...ProductDetails, ProductId: NewProductId };
                        TotalRecords = TotalRecords + 1;
                    }
                // Edit Product
                    else Products[ProductIndex] = ProductDetails;

                this.setState({ Products, ShowAddProduct: false, TotalRecords });
            }
        });
    }

    onLoadProducts = Increment => {
        var { PageNo, PageSize } = this.state;

        if (Increment) PageNo = PageNo + 1;
        else PageNo = 1;

        this.setState({ PageNo }, () => this.props.SearchProducts({ PageNo, PageSize }).then(({ IsMoreResults, Products: NewProducts, TotalRecords }) => {
            if (Increment) NewProducts = [ ...this.state.Products, ...NewProducts ];

            this.setState({ IsMoreResults, Products: NewProducts, TotalRecords });
        }))
    }

    onToggleShowAddProduct = ShowAddProduct => {
        if (ShowAddProduct) var Products = [ { ...this.state.AddProduct }, ...this.state.Products ];
        else Products = [ ...this.state.Products.slice(0, 0), ...this.state.Products.slice(0 + 1) ]

        this.setState({ Products, ShowAddProduct });
    }

    onToggleShowDeleteProductsModal = ({ ProductId, ProductIndex, ShowDeleteProductsModal }) => {
        if (ShowDeleteProductsModal) this.setState({ DeleteProduct: { ProductId, ProductIndex }, ShowDeleteProductsModal });
        else this.setState({ DeleteProduct: {}, ShowDeleteProductsModal });
    }

    renderDeleteProductsModal = () => {
        var { t } = this.props;
        var { TryingDeleteProducts } = this.props;
        var { ShowDeleteProductsModal } = this.state;

        if (ShowDeleteProductsModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteProduct}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    Information={t('delete_with_warning', { Items: t('products').toLowerCase()})}
                    Loading={TryingDeleteProducts}
                    OnHide={() => this.onToggleShowDeleteProductsModal({ ShowDeleteProductsModal: false })}
                    Show={ShowDeleteProductsModal}
                />
            );
        }
    }

    render() {
        var { t } = this.props;
        var { Device, TryingInsertEditProduct, TryingSearchProducts } = this.props;
        var { IsMoreResults, PageNo, PageSize, Products, TotalRecords } = this.state;

        return (
            <div>
                {(TryingInsertEditProduct) && <Loading />}

                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('products_you_offer')} TextAlign="center" />

                <Spacer Size="small" />

                <MultiText
                    ContainerWidth="500px"
                    TextAlign="center"
                    Texts={[
                        { FontFamily: 'semibold', FontSize: 'small', Text: `${t('example')}:` },
                        { FontFamily: 'medium-italic', FontSize: 'small', Text: t('products_description') }
                    ]}
                />

                <Spacer Size="small" />

                <ButtonGroup
                    Buttons={[{ Icon: Plus, IconPosition: 'left', FontFamily: 'semibold', FontSize: 'medium-1', OnClick: () => this.onToggleShowAddProduct(true), Size: 'small', Title: t('product_add') }]}
                    ButtonWidth="fit-content"
                    ContainerWidth={Device === 'mobile' || Device === 'mobile_small' ? '100%' : null}
                />

                <Spacer Size="medium" />

                <StyledLoadMore
                    ActiveSearch
                    CompletedFirstSearch
                    IsLoading={TryingSearchProducts}
                    ItemDescriptionType={t('products').toLowerCase()}
                    ItemId="ProductId"
                    ItemName="ProductName"
                    ItemProps={{ OnCancelNewProduct: this.onCancelNewProduct, OnDeleteProduct: ({ ProductId, ProductIndex }) => this.onToggleShowDeleteProductsModal({ ProductId, ProductIndex, ShowDeleteProductsModal: true }), OnInsertEditProduct: this.onInsertEditProduct }}
                    ItemRenderer={ProductListItem}
                    Items={Products}
                    HasMoreItems={!!+IsMoreResults}
                    LoadMore={() => this.onLoadProducts(true)}
                    NewSearch={false}
                    NoItemsText={t('no_products')}
                    PageNo={PageNo}
                    PageSize={PageSize}
                    TotalRecords={TotalRecords}
                />

                {this.renderDeleteProductsModal()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        Currencies: state.Sales.Currencies,

        TryingDeleteProducts: state.Sales.TryingDeleteProducts,
        TryingDeleteProductsError: state.Sales.TryingDeleteProductsError,
        TryingInsertEditProduct: state.Sales.TryingInsertEditProduct,
        TryingInsertEditProductError: state.Sales.TryingInsertEditProductError,
        TryingSearchProducts: state.Sales.TryingSearchProducts,
        TryingSearchProductsError: state.Sales.TryingSearchProductsError
    };
};

export default withTranslation()(connect(mapStateToProps, { DeleteProducts, InsertEditProduct, SearchCurrencies, SearchProducts } )(Products));