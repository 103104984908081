import styled from 'styled-components';
import { device } from '../../Config/device'

import Image from '../../Components/Image';
import TextArea from '../../Components/Form/TextArea';

export const Container = styled.div`
    border-radius: 10px;
    display: grid;

    @media ${device.minwidth.laptop} {
        grid-template-areas: "body filter";
        grid-template-columns: 1fr 300px;
    }

    @media ${device.maxwidth.ipad} {
        grid-template-areas: "header" "body";
        grid-template-columns: 1fr;
    }

    // grid-template-rows: 53px 1fr;
    overflow: hidden;
    width: 100%;
`;

export const EditIcon = styled(Image)`
    height: 30px;
    margin-right: 15px;
    margin-top: 15px;
    width: 30px;
`;

export const SelectedDateContainer = styled.div`
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    margin-right: 15px;
    margin-top: 15px;
    padding: 5px 12px;
`;

export const SelectedDatesContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -15px;
    margin-top: -15px;
`;

export const SelectedTemplateContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    @media ${device.minwidth.mobile} {
        width: 50%
    }

    @media ${device.minwidth.laptop} {
        width: 25%
    }

    > div {
        width: 100%;
    }
`;

export const TrainerNoteContainer = styled.div``;

export const TrainerNoteTextArea = styled(TextArea)``;