import {
  TRYING_CREATE_VIDEO_SESSION,
  TRYING_CREATE_VIDEO_SESSION_ERROR,
  TRYING_CREATE_VIDEO_SESSION_SUCCESS,

  TRYING_DELETE_VIDEO_SESSION,
  TRYING_DELETE_VIDEO_SESSION_ERROR,
  TRYING_DELETE_VIDEO_SESSION_SUCCESS,
  
  CLEAR_SINGLE_VIDEO_SESSION,
  TRYING_EDIT_VIDEO_SESSION,
  TRYING_EDIT_VIDEO_SESSION_ERROR,
  TRYING_EDIT_VIDEO_SESSION_SUCCESS,

  TRYING_GET_SINGLE_VIDEO_SESSION,
  TRYING_GET_SINGLE_VIDEO_SESSION_ERROR,
  TRYING_GET_SINGLE_VIDEO_SESSION_SUCCESS,

  TRYING_GET_VIDEO_SESSIONS,
  TRYING_GET_VIDEO_SESSIONS_ERROR,
  TRYING_GET_VIDEO_SESSIONS_SUCCESS,

} from '../Types';

const INITIAL_STATE = {
  VideoSessions: [],
  SingleSessionData: {},
  TotalSessionCount: null,
  TotalPages: null,
  TryingCreateVideoSession: false,
  TryingCreateVideoSessionError: null,
  TryingDeleteVideoSession: false,
  TryingDeleteVideoSessionError: null,
  TryingEditVideoSession: false,
  TryingEditVideoSessionError: null,
  TryingGetSingleVideoSession: false,
  TryingGetSingleVideoSessionError: null,
  TryingGetVideoSessions: false,
  TryingGetVideoSessionsError: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case TRYING_CREATE_VIDEO_SESSION:
      return { ...state, TryingCreateVideoSession: true, TryingCreateVideoSessionError: null };
    case TRYING_CREATE_VIDEO_SESSION_ERROR:
      return { ...state, TryingCreateVideoSession: false, TryingCreateVideoSessionError: action.payload };
    case TRYING_CREATE_VIDEO_SESSION_SUCCESS:
      return { ...state, TryingCreateVideoSession: false, TryingCreateVideoSessionError: null };

    case TRYING_DELETE_VIDEO_SESSION:
      return { ...state, TryingDeleteVideoSession: true, TryingDeleteVideoSessionError: null };
    case TRYING_DELETE_VIDEO_SESSION_ERROR:
      return { ...state, TryingDeleteVideoSession: false, TryingDeleteVideoSessionError: action.payload };
    case TRYING_DELETE_VIDEO_SESSION_SUCCESS:
      return { ...state, TryingDeleteVideoSession: false, TryingDeleteVideoSessionError: null };

    case TRYING_EDIT_VIDEO_SESSION:
      return { ...state, TryingEditVideoSession: true, TryingEditVideoSessionError: null };
    case TRYING_EDIT_VIDEO_SESSION_ERROR:
      return { ...state, TryingEditVideoSession: false, TryingEditVideoSessionError: action.payload };
    case TRYING_EDIT_VIDEO_SESSION_SUCCESS:
      return { ...state, TryingEditVideoSession: false, TryingEditVideoSessionError: null };
        
    case CLEAR_SINGLE_VIDEO_SESSION:
      return { ...state, SingleSessionData: {}, TryingGetSingleVideoSession: false, TryingGetSingleVideoSessionError: null}
    case TRYING_GET_SINGLE_VIDEO_SESSION:
      return { ...state, TryingGetSingleVideoSession: true, TryingGetSingleVideoSessionError: null };
    case TRYING_GET_SINGLE_VIDEO_SESSION_ERROR:
      return { ...state, TryingGetSingleVideoSession: false, TryingGetSingleVideoSessionError: action.payload };
    case TRYING_GET_SINGLE_VIDEO_SESSION_SUCCESS:
      return { ...state, SingleSessionData: { ...action.payload }, TryingGetSingleVideoSession: false, TryingGetSingleVideoSessionError: null };

    case TRYING_GET_VIDEO_SESSIONS:
      return { ...state, TryingGetVideoSessions: true, TryingGetVideoSessionsError: null };
    case TRYING_GET_VIDEO_SESSIONS_ERROR:
      return { ...state, TryingGetVideoSessions: false, TryingGetVideoSessionsError: action.payload };
    case TRYING_GET_VIDEO_SESSIONS_SUCCESS:
      return {
        ...state,
        VideoSessions: action.payload.Sessions,
        TotalSessionCount: action.payload.TotalCount,
        TotalPages:  action.payload.TotalPages,
        TryingGetVideoSessions: false,
        TryingGetVideoSessionsError: null,
      };
      
    default:
      return state;
  }
};
