import React from 'react';
import PropTypes from 'prop-types';
// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { LoadingContainer, LoadingRingContainer } from './styles';

class Loading extends React.Component {
    componentDidMount() {
        // disableBodyScroll();
        // document.body.style.overflow = 'hidden';
    }

    componentWillUnmount() {
        // enableBodyScroll();
        // document.body.style.overflow = 'unset';
    }

    render() {
        var { className, NoBackground, Position } = this.props;

        return (
            <LoadingContainer className={className} NoBackground={NoBackground} Position={Position}>
                <LoadingRingContainer>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </LoadingRingContainer>
            </LoadingContainer>
        );
    }
}

Loading.propTypes = {
    NoBackground: PropTypes.bool,
    Position: PropTypes.string
}

Loading.defaultProps = {
    Position: 'fixed'
}

export default Loading;