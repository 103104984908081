import styled from 'styled-components';

export const BodyMusclesContainer = styled.div``;

export const ClickablePath = styled.path`
    ${
        props => props.Clickable &&
        `
            @media(hover:hover) {
                :hover {
                    cursor: pointer;
                }
            }
        `
    }
`;