import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import { Container } from './styles';

import AbelModal from '../../Components/Modal';
import MiniCalendar from '../../Components/Calendar/MiniCalendar';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

class ChooseDates extends React.Component {
    state = { EndDate: this.props.EndDate, SelectedDates: this.props.SelectedDates, StartDate: this.props.StartDate };

    componentDidMount() {
        var { EndDate, StartDate } = this.props;

        var SelectedDates = [];
        if (this.props.SelectedDates.length > 0) SelectedDates = this.props.SelectedDates.map(SelectedDate => moment(SelectedDate).format('YYYY-MM-DD'));
        else if (StartDate && EndDate) SelectedDates = this.onBuildDateRange({ EndDate, StartDate });

        this.setState({ SelectedDates });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.SelectedDates !== this.props.SelectedDates) this.setState({ SelectedDates: this.props.SelectedDates.map(SelectedDate => moment(SelectedDate).format('YYYY-MM-DD')) });
    }

    onBuildDateRange = ({ EndDate, StartDate }) => {
        EndDate = moment(EndDate).format('YYYY-MM-DD');
        StartDate = moment(StartDate).format('YYYY-MM-DD');

        var CurrentDate = StartDate;
        var SelectedDates = [];
        var Counter = 0;

        while (CurrentDate <= EndDate) {
            SelectedDates.push(moment(StartDate).add(Counter, 'days').format('YYYY-MM-DD'));

            Counter++;
            CurrentDate = moment(StartDate).add(Counter, 'days').format('YYYY-MM-DD');
        }

        return SelectedDates;
    }

    onClickSubmit = () => {
        var { IsDateRange } = this.props;
        var { EndDate, SelectedDates, StartDate } = this.state;

        if (!IsDateRange) this.props.OnSelectDates(SelectedDates)
        else this.props.OnSelectDates({ EndDate, SelectedDates, StartDate });
    }

    onSelectDate = DayDate => {
        var { IsDateRange, MaxDates } = this.props;
        var { EndDate, SelectedDates, StartDate } = this.state;

        var SelectedDates_New = [];

        if (!IsDateRange) {
            var SelectedIndex = SelectedDates.indexOf(DayDate);

            if (SelectedIndex === -1) {
                if (!MaxDates || SelectedDates.length < MaxDates) this.setState({ SelectedDates: [ ...this.state.SelectedDates, DayDate ] });
                else this.setState({ SelectedDates: [ ...this.state.SelectedDates.slice(1), DayDate ] });
            }
            else {
                SelectedDates_New = [ ...SelectedDates ];
                SelectedDates_New.splice(SelectedIndex, 1);

                this.setState({ SelectedDates: SelectedDates_New });
            }
        } else {
            if (!StartDate || DayDate <= StartDate) { StartDate = DayDate; EndDate = null; }
            else if (!EndDate) EndDate = DayDate;
            else { StartDate = DayDate; EndDate = null; }

            if (StartDate && EndDate) SelectedDates_New = this.onBuildDateRange({ EndDate, StartDate });
            else SelectedDates_New = [ StartDate ];
            
            this.setState({ EndDate, SelectedDates: [ ...SelectedDates_New ], StartDate });
        }
    }

    renderModalBody = () => {
        var { t } = this.props;
        var { DisabledMaxDate, DisabledMinDate, DisabledReason, IsDateRange, MaxDates, ShowTodayShortcut } = this.props;
        var { SelectedDates } = this.state;

        return (
            <Container>
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t(MaxDates === 1 ? 'choose_date' : 'choose_dates')} TextAlign="center" />

                <Spacer Size="medium" />

                {
                    DisabledReason &&
                    <>
                        <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={DisabledReason} TextAlign="center" />

                        <Spacer Size="medium" />
                    </>
                }

                {
                    (IsDateRange && ShowTodayShortcut && SelectedDates.length === 1 && SelectedDates[0] < moment().format('YYYY-MM-DD')) &&
                    <>
                        <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin OnClick={() => this.onSelectDate(moment().format('YYYY-MM-DD'))} Text={t('select_date_today_shortcut')} TextAlign="center" />

                        <Spacer Size="medium" />
                    </>
                }

                <MiniCalendar
                    DisabledMaxDate={DisabledMaxDate}
                    DisabledMinDate={DisabledMinDate}
                    OnSelectDate={DayDate => this.onSelectDate(DayDate)}
                    SelectedDates={SelectedDates}
                />
            </Container>
        );
    }

    render() {
        var { t } = this.props;
        var { IsDateRange, MaxDates, OnHide, RequiredDate, Show } = this.props;
        var { SelectedDates } = this.state;

        return (
            <AbelModal
                BottomButton="submit"
                BottomButtonOnClick={this.onClickSubmit}
                BottomButtonText={t(MaxDates === 1 ? 'choose_date' : 'choose_dates')}
                Disabled={(RequiredDate && SelectedDates.length === 0) || (IsDateRange && SelectedDates.length < 2)}
                GA_PathName="ChooseDates"
                Show={Show}
                Size="medium"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

ChooseDates.propTypes = {
    DisabledMaxDate: PropTypes.string,
    DisabledMinDate: PropTypes.string,
    DisabledReason: PropTypes.string,
    EndDate: PropTypes.string,
    IsDateRange: PropTypes.bool,
    MaxDates: PropTypes.number,
    OnHide: PropTypes.func.isRequired,
    OnSelectDates: PropTypes.func.isRequired,
    RequiredDate: PropTypes.bool,
    SelectedDates: PropTypes.array,
    Show: PropTypes.bool.isRequired,
    ShowTodayShortcut: PropTypes.bool,
    StartDate: PropTypes.string
}

export default withTranslation()(ChooseDates);