import styled from 'styled-components';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';

export const BodyContainer = styled.div`
    margin: 0 5%;
`;

export const BottomButton = styled(ButtonGroup)`
    > div {
        :first-child {
            border-top-left-radius: 0px
        }

        :last-child {
            border-top-right-radius: 0px;
        }
    }
`;

export const CenterDiv = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 200px;
`;