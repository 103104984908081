import React from 'react';
import PropTypes from 'prop-types';

import { ButtonContainer, ButtonTitle } from './styles';

class Button extends React.Component {
    render() {
        var { BackgroundColor, BackgroundColor_Hover, Border, Border_Hover, className, Disabled, FontColor, FontColor_Hover, FontFamily, FontSize, OnClick, Size, Title } = this.props;

        return (
            <ButtonContainer
                className={className}
                BackgroundColor={BackgroundColor}
                BackgroundColor_Hover={BackgroundColor_Hover}
                Border={Border}
                Border_Hover={Border_Hover}
                Disabled={Disabled}
                FontColor={FontColor}
                FontColor_Hover={FontColor_Hover}
                onClick={!Disabled ? OnClick : null}
                Size={Size}
            >
                <ButtonTitle
                    Disabled={Disabled}
                    FontFamily={FontFamily}
                    FontSize={FontSize}
                >
                    {Title}
                </ButtonTitle>
            </ButtonContainer>
        );
    }
}

Button.propTypes = {
    BackgroundColor: PropTypes.string,
    BackgroundColor_Hover: PropTypes.string,
    Border: PropTypes.string,
    Border_Hover: PropTypes.string,
    Disabled: PropTypes.bool,
    FontColor: PropTypes.string,
    FontColor_Hover: PropTypes.string,
    FontFamily: PropTypes.string,
    FontSize: PropTypes.string,
    OnClick: PropTypes.func,
    Size: PropTypes.string,
    Title: PropTypes.string
};

Button.defaultProps = {
    FontSize: 'medium-3'
}

export default Button;