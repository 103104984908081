import styled from 'styled-components';

export const ImageContainer = styled.div``;

export const StyledInput = styled.input`
    // bottom: 0;
    // left: 0;
    // opacity: 100;
    // position: absolute;
    // right: 0;
    // top: 0;
    // width: 100%;

    :hover {
        cursor: pointer;
    }
`;