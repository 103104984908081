import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { BuildWorkoutPlan, ClearBuildWorkoutPlanDetails, ClearSaveData, GetBuildWorkoutPlanDetails, UpdateSelectedTab } from '../../Services/Actions';
import moment from 'moment';
import history from '../../history';

import { DateTimeContainer, DateTimesContainer, EditIcon, GoalContainer, GoalImage, GoalsContainer, InjuriesDropdownContainer, SelectedDateContainer, SelectedDatesContainer, StyledBodyMuscles, StyledDropdown, TrainerNoteContainer, TrainerNoteTextArea, WorkoutTimeOptionContainer, WorkoutTimeOptionsContainer, WorkoutTimeUseAllDays, WorkoutTimeUseAllDaysContainer } from './styles';

import AssignClients from '../../Components/AssignClients';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import FlexWrap from '../../Components/Lists/FlexWrap';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import ChooseDates from '../../Modals/ChooseDates';

import Edit_Dark from '../../Assets/Icons/Edit_Dark.png';
import Plus from '../../Assets/Icons/Plus.png';

import Endurance from '../../Assets/Illustrations/Endurance.png';
import Food from '../../Assets/Illustrations/Food.png';
import GainMuscleMass from '../../Assets/Illustrations/GainMuscleMass.png';
import IncreaseStrength from '../../Assets/Illustrations/IncreaseStrength.png';
import LoseWeight from '../../Assets/Illustrations/LoseWeight.png';

import { DateSort } from '../../Functions';

const WorkoutTimeOptions = [ 30, 45, 60, 75, 90 ];

class AddWorkoutPlan extends React.Component {
    state = {
        DisplayingFilter: null,
        DropdownSize: 0,
        EnduranceTypes: [ 1 ],
        GoalId: null,
        Injuries: {
            Joints: [],
            Muscles: []
        },
        Loading: true,
        SelectedDates_Existing: [],
        SelectedDates: {},
        SelectedUserIds: [],
        SelectedUsers: [],
        ShowChooseDatesModal: false,
        SyncAllWorkoutTimes: false,
        TargetMuscleBodyAreas: [],
        TargetMuscles: [],
        TargetMusclesFullBody: [],
        TrainerNote: ''
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientDetails, DayDate } = this.props;

        var SelectedDates_Existing = DayDate ? [ DayDate ] : [];
        var SelectedDates = DayDate ? { [`${DayDate}`]: 60 } : {};
        var SelectedUserIds = [];
        var SelectedUsers = [];

        if (Object.entries(ClientDetails).length !== 0) {
            SelectedUserIds = [ Number(ClientDetails.ClientId) ];
            SelectedUsers = [{ ...ClientDetails, UserId: Number(ClientDetails.ClientId) }];
        }

        this.props.GetBuildWorkoutPlanDetails().then(() => {
            this.setState({ Loading: false, SelectedDates_Existing, SelectedDates, SelectedUserIds, SelectedUsers });
        });
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearBuildWorkoutPlanDetails();
        this.props.ClearSaveData();
    }

    onAddWorkoutPlan = () => {
        var { EnduranceTypes, GoalId, Injuries: { Joints: JointPains, Muscles: MusclePains }, SelectedDates, SelectedUserIds, TargetMuscles, TrainerNote } = this.state;

        var ClientIds = SelectedUserIds.join(',');
        TargetMuscles = TargetMuscles.filter(MuscleId => ![ 14, 15, 16 ].includes(+MuscleId));

        var WorkoutAvailability = '';
        for (var i = 0; i < Object.keys(SelectedDates).length; i++) {
            if (!Number(SelectedDates[Object.keys(SelectedDates)[i]])) continue;

            if (WorkoutAvailability !== '') WorkoutAvailability += ';';
            
            WorkoutAvailability += `${Object.keys(SelectedDates)[i]},${SelectedDates[Object.keys(SelectedDates)[i]]}`;
        }

        this.props.BuildWorkoutPlan({ ClientIds, EnduranceTypes, GoalId, JointPains, MusclePains, TargetMuscles, TrainerNote, WorkoutAvailability }).then(() => {
            if (this._isMounted && !this.props.TryingBuildWorkoutPlanError) {
                if (SelectedUserIds.length === 1) history.push({ pathname: `/client/${SelectedUserIds[0]}`, search: '?type=workouts' });
                else history.push('/');
            }
        })
    }

    onChangeTrainerNote = event => {
        this.setState({ TrainerNote: event.target.value });
    }

    onChangeWorkoutTime = (WorkoutTime, SelectedDate) => {
        var { SelectedDates, SyncAllWorkoutTimes } = this.state;

        if (!SyncAllWorkoutTimes) this.setState({ SelectedDates: { ...this.state.SelectedDates, [`${SelectedDate}`]: WorkoutTime } });
        else {
            var NewSelectedDates = { ...SelectedDates };

            Object.keys(NewSelectedDates).forEach(key => NewSelectedDates[key] = WorkoutTime);

            this.setState({ SelectedDates: NewSelectedDates });
        }
    }

    onGetSize = Height => {
        this.setState({ DropdownSize: Height });
    }

    onSelectBodyArea = ({ BodyAreaMuscleIds }, BodyAreaIndex) => {
        var NewTargetMuscles = [];

        // Build NewTargetMuscles
            if (BodyAreaIndex === -1) NewTargetMuscles = [ ...new Set([ ...this.state.TargetMuscles, ...BodyAreaMuscleIds.split(', ').map(Number) ].map(x => x)) ]
            else NewTargetMuscles = [ ...this.state.TargetMuscles.filter(TargetMuscle => ![ ...BodyAreaMuscleIds.split(', ').map(Number) ].includes(TargetMuscle) ) ];

        // Build NewTargetMuscleBodyAreas if all body area muscles are in NewTargetMuscles
            var NewTargetMuscleBodyAreas = this.props.BuildWorkoutPlanDetails.TargetMuscles.BodyAreas.map(({ BodyAreaId, BodyAreaMuscleIds }) => {
                if ([ ...BodyAreaMuscleIds.split(', ').map(Number) ].every(val => NewTargetMuscles.includes(val))) return BodyAreaId;
                
                return null;
            });

        // Determine Full Body
            var NewTargetMusclesFullBody = NewTargetMuscles.length === 13 ? [ 1 ] : [];

        this.setState({ TargetMuscles: NewTargetMuscles, TargetMuscleBodyAreas: NewTargetMuscleBodyAreas, TargetMusclesFullBody: NewTargetMusclesFullBody });
    }

    onSelectClients = ({ SelectedClients: SelectedUsers }) => {
        var SelectedUserIds_New = SelectedUsers.map(User => User.UserId);
        SelectedUsers = SelectedUsers.map(User => ({ ...User, Sex: User.Gender }));

        this.setState({ SelectedUsers, SelectedUserIds: SelectedUserIds_New });
    }

    onSelectDates = SelectedDates => {
        var { SelectedDates_Existing, SelectedDates: StateSelectedDates, SyncAllWorkoutTimes } = this.state;

        var NewSelectedDates_Existing = [];
        var NewSelectedDates = {};

        var Time = (SyncAllWorkoutTimes && SelectedDates_Existing.length > 1) ? StateSelectedDates[SelectedDates_Existing[0]] : 60;

        var SelectedDates_ExistingIndex = null;
        for (var i = 0; i < SelectedDates.length; i++) {
            SelectedDates_ExistingIndex = SelectedDates_Existing.indexOf(SelectedDates[i]);

            NewSelectedDates_Existing.push(SelectedDates[i]);

            if (SelectedDates_ExistingIndex > -1) NewSelectedDates[`${SelectedDates[i]}`] = StateSelectedDates[`${SelectedDates[i]}`];
            else NewSelectedDates[`${SelectedDates[i]}`] = Time;
        }

        NewSelectedDates_Existing.sort(DateSort);

        this.setState({ SelectedDates_Existing: NewSelectedDates_Existing, SelectedDates: NewSelectedDates, ShowChooseDatesModal: false });
    }

    onSelectFullBody = ({ FullBodyId }, FullBodyIndex) => {
        var NewTargetMuscles = [];
        var NewTargetMuscleBodyAreas = [];
        var NewTargetMusclesFullBody = [];

        if (FullBodyIndex === -1) {
            var { BodyAreas, Muscles } = this.props.BuildWorkoutPlanDetails.TargetMuscles;

            NewTargetMuscles = Muscles.map(({ MuscleId }) => +MuscleId);
            NewTargetMuscleBodyAreas = BodyAreas.map(({ BodyAreaId }) => +BodyAreaId);
            NewTargetMusclesFullBody = [ FullBodyId ];
        }

        this.setState({ TargetMuscles: NewTargetMuscles, TargetMuscleBodyAreas: NewTargetMuscleBodyAreas, TargetMusclesFullBody: NewTargetMusclesFullBody })
    }

    onSelectGoal = GoalId => {
        this.setState({ GoalId });
    }

    onSelectJoint = (event, JointId, JointIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = JointIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return { Injuries: { ...prevState.Injuries, Joints: [ ...prevState.Injuries.Joints, ...[ JointId ] ] } }
            });
        } else {
            this.setState((prevState) => {
                return { Injuries: { ...prevState.Injuries, Joints: [ ...prevState.Injuries.Joints.slice(0, JointIndex), ...prevState.Injuries.Joints.slice(JointIndex + 1) ] }}
            });
        }
    }

    onSelectMuscle = (event, MuscleId, MuscleIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = MuscleIndex === -1;

        if (checked) {
            this.setState((prevState) => {
                return { Injuries: { ...prevState.Injuries, Muscles: [ ...prevState.Injuries.Muscles, ...[ MuscleId ] ] } }
            });
        } else {
            this.setState((prevState) => {
                return { Injuries: { ...prevState.Injuries, Muscles: [ ...prevState.Injuries.Muscles.slice(0, MuscleIndex), ...prevState.Injuries.Muscles.slice(MuscleIndex + 1) ] }}
            });
        }
    }

    onSelectTargetMuscle = ({ MuscleId }, MuscleIndex) => {
        if (MuscleId !== 0 && !MuscleIndex) MuscleIndex = this.state.TargetMuscles.indexOf(+MuscleId);

        // Build NewTargetMuscles
            var NewTargetMuscles = [];
            if (MuscleIndex === -1) NewTargetMuscles = [ ...this.state.TargetMuscles, +MuscleId ];
            else NewTargetMuscles = [ ...this.state.TargetMuscles.slice(0, MuscleIndex), ...this.state.TargetMuscles.slice(MuscleIndex + 1) ];

        // Build NewTargetMuscleBodyAreas if all body area muscles are in NewTargetMuscles
            var NewTargetMuscleBodyAreas = this.props.BuildWorkoutPlanDetails.TargetMuscles.BodyAreas.map(({ BodyAreaId, BodyAreaMuscleIds }) => {
                if ([ ...BodyAreaMuscleIds.split(', ').map(Number) ].every(val => NewTargetMuscles.includes(val))) return BodyAreaId;

                return null;
            });

        // Determine Full Body
            var NewTargetMusclesFullBody = NewTargetMuscles.length === 13 ? [ 1 ] : [];

        this.setState({ TargetMuscles: NewTargetMuscles, TargetMuscleBodyAreas: NewTargetMuscleBodyAreas, TargetMusclesFullBody: NewTargetMusclesFullBody });
    }

    onSelectUseAllDays = () => {
        var { SelectedDates_Existing, SelectedDates, SyncAllWorkoutTimes } = this.state;
        
        if (SyncAllWorkoutTimes) this.setState({ SyncAllWorkoutTimes: false });
        else if (!SyncAllWorkoutTimes && SelectedDates_Existing.length === 1) this.setState({ SyncAllWorkoutTimes: true });
        
        // Sync all times if more than one date selected
        else {
            var NewSelectedDates_Existing = [];
            var NewSelectedDates = {};

            var FirstTime = SelectedDates[SelectedDates_Existing[0]];

            for (var i = 0; i < SelectedDates_Existing.length; i++) {
                NewSelectedDates_Existing.push(SelectedDates_Existing[i]);
                NewSelectedDates[SelectedDates_Existing[i]] = FirstTime;
            }

            this.setState({ SelectedDates_Existing: NewSelectedDates_Existing, SelectedDates: NewSelectedDates, SyncAllWorkoutTimes: true });
        }
    }

    onToggleDropdown = (DisplayingFilter, ToggleStatus) => {
        this.setState({ DisplayingFilter: ToggleStatus ? DisplayingFilter : null });
    }

    onToggleShowChooseDatesModal = ShowChooseDatesModal => {
        this.setState({ ShowChooseDatesModal });
    }

    renderAddTrainerNote = () => {
        var { t } = this.props;
        var { TrainerNote } = this.state;

        return (
            <TrainerNoteContainer>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('WorkoutPlan_Trainer_note_to_client')} TextAlign="center" />

                <Spacer Size="small" />

                <TrainerNoteTextArea
                    OnBlur={this.onChangeTrainerNote}
                    NumRows={4}
                    Placeholder={t('WorkoutPlan_Trainer_note_to_client_placeholder')}
                    Value={TrainerNote}
                />
            </TrainerNoteContainer>
        );
    }

    renderDates = () => {
        var { t } = this.props;
        var { SelectedDates_Existing } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('WorkoutPlan_workout_schedule')} TextAlign="center" />
                
                <Spacer Size="small" />

                {
                    SelectedDates_Existing.length ?
                    this.renderSelectedDates()
                :
                    <ButtonGroup
                        Buttons={[
                            {
                                Icon: Plus,
                                IconPosition: 'left',
                                OnClick: () => this.onToggleShowChooseDatesModal(true),
                                Title: t('add_dates')
                            }
                        ]}
                        ButtonWidth="fit-content"
                        NotTouching
                    />
                }
            </>
        );
    }

    renderDateTimes = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { SelectedDates_Existing, SelectedDates, SyncAllWorkoutTimes } = this.state;

        if (!SelectedDates_Existing.length) return null;

        let Disabled = SyncAllWorkoutTimes;

        return (
            <>
                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('WorkoutPlan_choose_session_length_for_each_day')} TextAlign="center" />

                <Spacer Size="small" />

                <DateTimesContainer>
                    {
                        SelectedDates_Existing.map((SelectedDate, SelectedDateIndex) => {
                            return (
                                <DateTimeContainer key={SelectedDate}>
                                    <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={moment(SelectedDate).format('DD.MMM')} />

                                    <Spacer Size="extra-small" />
                                                                        
                                    <WorkoutTimeOptionsContainer>
                                        {
                                            WorkoutTimeOptions.map(WorkoutTime => {
                                                var Selected = WorkoutTime === SelectedDates[`${SelectedDate}`];
                                                Disabled = SyncAllWorkoutTimes && SelectedDateIndex !== 0;

                                                return (
                                                    <WorkoutTimeOptionContainer key={WorkoutTime} Disabled={Disabled} onClick={Disabled ? null : () => this.onChangeWorkoutTime(WorkoutTime, SelectedDate)} Selected={Selected}>
                                                        <PageText FontFamily="semibold" FontSize="medium-2" NoMargin Text={`${WorkoutTime}`} />
                                                        <PageText FontFamily="medium" FontSize="small" NoMargin Text={t('min_str')} />
                                                    </WorkoutTimeOptionContainer>
                                                );
                                            })
                                        }
                                    </WorkoutTimeOptionsContainer>

                                    {
                                        SelectedDateIndex === 0 && SelectedDates_Existing.length > 1 ?
                                        <WorkoutTimeUseAllDaysContainer>
                                            <WorkoutTimeUseAllDays onClick={this.onSelectUseAllDays} Selected={SyncAllWorkoutTimes} />
                                            <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" Text={t('use_for_all_days')} TextAlign="left" />
                                        </WorkoutTimeUseAllDaysContainer>
                                    :
                                        null
                                    }
                                </DateTimeContainer>
                            );
                        })
                    }
                </DateTimesContainer>
            </>
        );
    }

    renderGoals = () => {
        var { t } = this.props;
        var { Goals } = this.props.BuildWorkoutPlanDetails;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('BothPlans_whats_your_main_goal_str')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <GoalsContainer>
                    {
                        Goals.map(({ GoalId, GoalName }) => {
                            var Selected = GoalId === this.state.GoalId;
                            var ImageSrc = GoalId === 1 ? LoseWeight : GoalId === 2 ? IncreaseStrength : GoalId === 3 ? GainMuscleMass : (GoalId === 4 || GoalId === 5) ? Endurance : Food;

                            return (
                                <GoalContainer key={GoalId} onClick={() => this.onSelectGoal(GoalId)} Selected={Selected}>
                                    <GoalImage ImageSrc={ImageSrc} Loading={false} />
                                    <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={GoalName} TextAlign="center" />
                                </GoalContainer>
                            );
                        })
                    }
                </GoalsContainer>
            </>
        );
    }

    renderInjuries = () => {
        var { t } = this.props;
        var { Device, BuildWorkoutPlanDetails: { Injuries: { Joints, Muscles } } } = this.props;
        var { DisplayingFilter, DropdownSize, Injuries: { Joints: StateJoints, Muscles: StateMuscles } } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('WorkoutPlan_injuries')} TextAlign="center" />
                
                <Spacer Size="extra-small" />
                
                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_client_questionnaire_questiondescription_injuries')} TextAlign="center" />
                
                <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />

                <InjuriesDropdownContainer>
                    <StyledDropdown
                        Disabled={false}
                        ForceClose={DisplayingFilter !== 'Joints'}
                        GetSize={Height => this.onGetSize(Height)}
                        Height={DisplayingFilter === 'Joints' && DropdownSize}
                        IsMobile={Device === 'mobile_small' || Device === 'mobile'}
                        ItemId="JointId"
                        ItemName="JointName"
                        Items={Joints}
                        NeedsToLoad={false}
                        OnSelectItem={(event, JointId, JointIndex) => this.onSelectJoint(event, JointId, JointIndex)}
                        OnToggleDropdown={ToggleStatus => this.onToggleDropdown('Joints', ToggleStatus)}
                        SelectedItemIds={StateJoints}
                        Title={t('Joints')}
                    />

                    <StyledDropdown
                        Disabled={false}
                        ForceClose={DisplayingFilter !== 'Muscles'}
                        GetSize={Height => this.onGetSize(Height)}
                        Height={DisplayingFilter === 'Muscles' && DropdownSize}
                        IsMobile={Device === 'mobile_small' || Device === 'mobile'}
                        ItemId="MuscleId"
                        ItemName="MuscleName"
                        Items={Muscles}
                        NeedsToLoad={false}
                        OnSelectItem={(event, MuscleId, MuscleIndex) => this.onSelectMuscle(event, MuscleId, MuscleIndex)}
                        OnToggleDropdown={ToggleStatus => this.onToggleDropdown('Muscles', ToggleStatus)}
                        SelectedItemIds={StateMuscles}
                        Title={t('Muscles')}
                    />
                </InjuriesDropdownContainer>
            </>
        );
    }

    renderSelectedDates = () => {
        var { SelectedDates_Existing } = this.state;

        return (
            <SelectedDatesContainer>
                {
                    SelectedDates_Existing.map(SelectedDate => 
                        <SelectedDateContainer key={SelectedDate}>
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={moment(SelectedDate).format('DD.MMM')} />
                        </SelectedDateContainer>
                    )
                }

                <EditIcon
                    ImageSrc={Edit_Dark}
                    Loading={false}
                    OnClick={() => this.onToggleShowChooseDatesModal(true)}
                />
            </SelectedDatesContainer>
        );
    }

    renderShowChooseDatesModal = () => {
        var { ShowChooseDatesModal } = this.state;

        if (ShowChooseDatesModal) {
            return (
                <ChooseDates
                    OnHide={() => this.onToggleShowChooseDatesModal(false)}
                    OnSelectDates={SelectedDates => this.onSelectDates(SelectedDates)}
                    SelectedDates={this.state.SelectedDates_Existing}
                    Show={ShowChooseDatesModal}
                />
            );
        }
    }

    renderStrengthQuestions = () => {
        var { Device } = this.props;

        return (
            <>
                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                {this.renderTargetMuscles()}

                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                {this.renderInjuries()}
            </>
        );
    }

    renderTargetMuscles = () => {
        var { t } = this.props;
        var { TargetMuscles: { BodyAreas, Muscles } } = this.props.BuildWorkoutPlanDetails;
        var { TargetMuscleBodyAreas, TargetMuscles, TargetMusclesFullBody } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('WorkoutPlan_target_area_title')} TextAlign="center" />

                <Spacer Size="small" />

                <FlexWrap
                    IsMultiSelect
                    ItemId="FullBodyId"
                    ItemName="FullBodyName"
                    Items={[{ FullBodyId: 1, FullBodyName: t('WorkoutPlan_Full_Body') }]}
                    OnSelectItem={(Item, ItemIndex) => this.onSelectFullBody(Item, ItemIndex)}
                    SelectedItemIds={TargetMusclesFullBody}
                />

                <Spacer Size="medium" />
                
                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('body_areas')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <FlexWrap
                    IsMultiSelect
                    ItemId="BodyAreaId"
                    ItemName="BodyAreaName"
                    Items={BodyAreas}
                    OnSelectItem={(Item, ItemIndex) => this.onSelectBodyArea(Item, ItemIndex)}
                    SelectedItemIds={TargetMuscleBodyAreas}
                />

                <Spacer Size="medium" />

                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('Muscles')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <FlexWrap
                    IsMultiSelect
                    ItemId="MuscleId"
                    ItemName="MuscleName"
                    Items={Muscles}
                    OnSelectItem={(Item, ItemIndex) => this.onSelectTargetMuscle(Item, ItemIndex)}
                    SelectedItemIds={TargetMuscles}
                />

                <Spacer Size="medium" />

                <StyledBodyMuscles OnSelectMuscle={MuscleId => this.onSelectTargetMuscle({ MuscleId })} PrimaryMuscleIds={TargetMuscles} />
            </>
        );
    }

    render() {
        if (this.state.Loading || this.props.TryingGetBuildWorkoutPlanDetails) return <Loading />;

        var { t } = this.props;
        var { Device, TryingBuildWorkoutPlan } = this.props;
        var { GoalId, SelectedDates_Existing, SelectedUserIds, SelectedUsers, TargetMuscles } = this.state;

        var Disabled = true;
        if (!SelectedUserIds.length || !SelectedDates_Existing.length || !GoalId) Disabled = true;
        else if ((GoalId === 1 || GoalId === 2 || GoalId === 3 || GoalId === 6) && (TargetMuscles.filter(MuscleId => ![ 14, 15, 16 ].includes(+MuscleId))).length) Disabled = false;
        else if (GoalId === 4 || GoalId === 5) Disabled = false;

        var Breadcrumbs = [{ Name: t('build_blank', { PlanType: t('WorkoutPlan_workout_plan_Header').toLowerCase() }) }];
        var SubmitButtonProps = {
            Disabled,
            OnClick: this.onAddWorkoutPlan,
            Title: t('build_blank', { PlanType: t('WorkoutPlan_workout_plan_Header').toLowerCase() })
        }

        return (
            <>
                {TryingBuildWorkoutPlan && <Loading />}

                <Page
                    renderBreadcrumbs={Breadcrumbs}
                    renderSubmitButton={SubmitButtonProps}
                >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={t('build_blank', { PlanType: t('WorkoutPlan_workout_plan_Header').toLowerCase() })} TextAlign="center" />

                    <Spacer Size="small" />

                    <MultiText
                        ContainerWidth="600px"
                        TextAlign="center"
                        Texts={[
                            { FontFamily: 'semibold', Text: t('ptadmin_what_is_a_workout_plan') },
                            { Text: t('ptadmin_what_is_a_workout_plan_description') }
                        ]}
                    />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ptadmin_who_do_you_want_to_assign_this_plan_to', { PlanType: t('WorkoutPlan_workout_plan_Header').toLowerCase() })} TextAlign="center" />

                    <Spacer Size="small" />

                    <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('ptadmin_build_plan_same_settings_all_clients')} TextAlign="center" />

                    <Spacer Size="small" />

                    <AssignClients
                        GetStartedEligible="Workouts"
                        HideTitle
                        HorizontalScroll={false}
                        OnSelectClients={Users => this.onSelectClients(Users)}
                        SelectedUserIds={SelectedUserIds}
                        SelectedUsers={SelectedUsers}
                    />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderDates()}

                    {this.renderDateTimes()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderGoals()}

                    {(GoalId === 1 || GoalId === 2 || GoalId === 3 || GoalId === 6) && this.renderStrengthQuestions()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderAddTrainerNote()}
                </Page>

                {this.renderShowChooseDatesModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        ClientDetails: state.SaveData.ClientDetails,
        DayDate: state.SaveData.DayDate,

        BuildWorkoutPlanDetails: state.Workouts.BuildWorkoutPlanDetails,

        TryingBuildWorkoutPlan: state.Workouts.TryingBuildWorkoutPlan,
        TryingBuildWorkoutPlanError: state.Workouts.TryingBuildWorkoutPlanError,
        TryingGetBuildWorkoutPlanDetails: state.Workouts.TryingGetBuildWorkoutPlanDetails,
        TryingGetBuildWorkoutPlanDetailsError: state.Workouts.TryingGetBuildWorkoutPlanDetailsError
    };
};

export default withTranslation()(connect(mapStateToProps, { BuildWorkoutPlan, ClearBuildWorkoutPlanDetails, ClearSaveData, GetBuildWorkoutPlanDetails, UpdateSelectedTab } )(AddWorkoutPlan));