import styled from "styled-components";
import Checkmark from "../../../Assets/Icons/AdminMenu/SessionLibrary_Hover.png";
import { device } from "../../../Config/device";
import ButtonGroup from "../../Buttons/ButtonGroup";
import TextArea from "../../Form/TextArea";

export const BottomButtons = styled(ButtonGroup)`
  > div {
    :first-child {
      border-top-left-radius: 0px;
    }

    :last-child {
      border-top-right-radius: 0px;
    }
  }
`;

export const InputContainer = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-evenly;
`;

export const NotesContainer = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: 0.45fr 0.55fr;

  @media ${device.maxwidth.ipad} {
    grid-template-columns: 1fr;
  }
`;

export const NotificationReminderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const ScheduleSession = styled.div`
  align-items: top;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));

  @media ${device.maxwidth.ipad} {
    grid-template-columns: 0.4fr 0.3fr 0.3fr;
    padding: 10px;
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    padding: 10px;
  }

  @media ${device.maxwidth.mobile} {
    grid-template-columns: 0.9fr;
    padding: 10px;
  }
`;

export const SessionItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-right: 3px solid var(--white);
  border-bottom: 3px solid var(--white);
`;

export const AlignItemsColumnWise = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const StyledCalendar = styled.div`
  > div {
    transform: scale(0.8);
  }
`;

export const StyledInput = styled.div`
  width: 30%;
`;

export const StyledScheduleSessionContainer = styled.div`
  background-color: var(--white-concrete);
  border-radius: 5px;
  Width: 100%;
`;

export const StyledSessionNotesTextarea = styled(TextArea)`
  > textarea {
    background-color: var(--white);
    border: none;
    border-radius: 10px;
    text-align: left;
    text-indent: 0px;
    resize: none;
  }
`;

export const DateTimeUseAllDaysContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
`;

export const TimePickerContainer = styled.div`
  width: 130px;
`;

export const DateTimeUseAllDays = styled.span`
  ${(props) =>
    props.Selected
      ? `
          background-image: url(${Checkmark});
          background-position: center;
          background-repeat: no-repeat;
          background-size: 25px;
      `
      : `
          background-color: var(--white);
          border: 2px solid var(--gray-light);
      `};
  border-radius: 50%;
  display: block;
  height: 25px;
  margin-right: 5px;
  min-width: 25px;
  width: 25px;

  @media (hover: hover) {
    :hover {
      background-image: url(${Checkmark});
      background-position: center;
      background-repeat: no-repeat;
      background-size: 25px;
      border: none;
      cursor: pointer;
    }
  }
`;
