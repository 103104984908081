import React from 'react';

import * as styles from './styles';

const UploadProgress = props => {
    var { UploadingItems } = props;

    if (!Object.keys(UploadingItems).length) return null;

    return (
        Object.keys(UploadingItems).map(ItemKey => {
            var { LabelName, Percentage } = UploadingItems[ItemKey];
    
            return (
                <styles.WrapperItem className="styles.WrapperItem" key={LabelName}>
                    <styles.LeftSide className="styles.LeftSide">
                        <styles.ProgressBar className="styles.ProgressBar">
                            <div style={{ width: `${Percentage}%` }} />
                        </styles.ProgressBar>
                        {LabelName && <label>{LabelName}</label>}
                    </styles.LeftSide>
                    <styles.RightSide className="styles.RightSide">
                        <span>{Percentage}%</span>
                    </styles.RightSide>
                </styles.WrapperItem>
            );
        })
    );
}

export default UploadProgress;