import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ChooseWorkoutTemplateTags, DeleteWorkoutTemplateTags, EditWorkoutTemplateTag, GetAllTagsForAddingRemoving_Templates, GetWorkoutTemplateFilterSettings, InsertEditWorkoutTemplateFilter, InsertWorkoutTemplateTags, MoveTemplatesToTrash, RecoverTemplatesFromTrash, RemoveCategory_WorkoutTemplate, ReorderTemplateFilters, SaveDataInProps, SearchWorkoutTemplates_SAYT, SearchWorkoutTemplates, UpdateTemplatesTags, UpdateShareAbilityStatus_WorkoutTemplates, UpdateSharedToCoachStatus_WorkoutTemplates, UpdateSharedToCoachStatusAll_WorkoutTemplates } from '../../../../Services/Actions';
import history from '../../../../history';
import Popover from 'react-popover';

import * as Styles from './styles';

import AddRemoveTagsPopoverComponent from '../../../../Components/AddRemoveTagsPopover';
import ButtonGroup from '../../../../Components/Buttons/ButtonGroup';
import Checkmark from '../../../../Components/Form/Checkmark';
import CustomizeFilters from '../../CustomizeFilters';
import Filters from '../../Filters';
import Header from '../../Header';
import Loading from '../../../../Components/Loading';
import MultiText from '../../../../Components/Text/MultiText';
import Spacer from '../../../../Components/Spacer';
import PageText from '../../../../Components/Text/PageText';
import Toggle from '../../../../Components/Form/Toggle';
import ViewCount from '../../../../Components/ViewCount';
import { WorkoutTemplateListItem } from '../../../../Components/Lists/ListItems';

import EditShareAbilityStatusModal from '../../../../Modals/EditShareAbilityStatusModal';
import HowSharingWorksModal from '../../../../Modals/HowSharingWorksModal';
import Info from '../../../../Modals/Info';
import ShareContentModal from '../../../../Modals/ShareContentModal';
import WorkoutTemplate from '../../../../Modals/WorkoutTemplate';

import { GetTranslatedFilterName } from '../../../../Functions';

import AbelIcon from '../../../../Assets/Icons/Abel_HeaderIcon.png';
import Blocked from '../../../../Assets/Icons/Blocked.png';
import Checkmark_Selected from '../../../../Assets/Icons/Checkmark_Selected.png';
import Female from '../../../../Assets/Icons/Female.png';
import Male from '../../../../Assets/Icons/Male.png';
import Plus from '../../../../Assets/Icons/PlusSign_White.png';
import Plus_Dark from '../../../../Assets/Icons/PlusSign.png';
import Sharing_Gift from '../../../../Assets/Illustrations/Sharing_gift.png';
import { ReactComponent as FilterSVG } from '../../../../Assets/SVG/Filter.svg';
import { ReactComponent as TrashSVG } from '../../../../Assets/SVG/Trash.svg';

const INITIAL_STATE = {
    Injuries: [],
    LastSearchedValue: '',
    Owners: [],
    PageNo: 1, PageSize: 49,
    RepRange: [],
    TagIds: [],
    TemplateName: '',
    TrainingFocus: [],
    SearchValue: '',
    WorkoutType: []
}

const SELECTED_CATEGORIES_DEFAULT = {
    Injuries: [],
    MadeBy: [],
    RepRange: [],
    Tags: [],
    TrainingFocus: [],
    WorkoutType: []
}

const GetSelectedCategories = Categories => {
    var SelectedCategories = JSON.parse(JSON.stringify(SELECTED_CATEGORIES_DEFAULT));

    for (var i = 0; i < Categories.length; i++) {
        var { IsAbel, SecondaryTagType, TagId, TagType } = Categories[i];

        if (!!IsAbel) {
            if (TagType === 'Joints' || TagType === 'Muscles') SelectedCategories[`${SecondaryTagType}${TagType}`].push(+TagId);
            else SelectedCategories[TagType].push(+TagId);
        } else SelectedCategories[TagType].push(+TagId);;
    }

    return SelectedCategories;
}

class WorkoutTemplateLibrary extends React.Component {
    _isMounted = false;

    state = {
        ...INITIAL_STATE,
        EditWorkoutTemplateTagError: false,
        ForceReload: false,
        Loading: true,
        MergeTags: true,
        MultiSelectTemplateIds: [],
        MultiSelectTemplates: [],
        SelectedCategories: [],
        SelectedCategoriesIndex: [],
        SelectedCategories_Modal: { ...SELECTED_CATEGORIES_DEFAULT },
        SelectedTemplateDays: null,
        SelectedTemplate: {},
        SelectedTemplateId: null,
        ShowAddRemoveTagsPopover: false,
        ShowAddRemoveTagsPopover_ActionType: null,
        ShowChooseCategoriesModal: false,
        ShowDeleteTemplatesModal: false,
        ShowEditShareAbilityStatusModal: false,
        ShowFiltersModals: false,
        ShowHowSharingWorksModal: false,
        ShowShareContentModal: false,
        ShowMultiSelect: false,
        ShowTemplateDetailModal: false,
        StateLoading: false,
        TemplateDetail_Preview: {},
        TryingChooseCategories: false
    };

    componentDidMount() {
        this._isMounted = true;

        this.props.GetWorkoutTemplateFilterSettings(true).then(() => {
            if (this.props.WorkoutTypes) this.setState({ WorkoutType: this.props.WorkoutTypes }, this.onLoadTemplates);
            else if (this._isMounted && !this.props.TryingGetWorkoutTemplateFiltersError) this.onLoadTemplates();
        });
    }

    componentDidUpdate(prevProps) {
        if (!!prevProps.ShowCustomizeFilters && !this.props.ShowCustomizeFilters && this.state.ForceReload) {
            this.setState({ ForceReload: false });
            this.onLoadTemplates();
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    onAssignToClients = (event, Template) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        this.props.SaveDataInProps({ SelectedTemplate: Template }).then(() => history.push({ pathname: '/add-workout-template', state: { from: 'Library' } }));
    }

    onCancelMultiSelect = () => {
        this.setState({ MultiSelectTemplateIds: [], MultiSelectTemplates: [], ShowMultiSelect: false });
    }

    onChooseCategories = () => {
        var Categories = this.state.SelectedCategories_Modal;

        var SelectedCategories = [];

        let IsAbel, TagType, SecondaryTagType, ContentId, TagId;

        Object.keys(Categories).map(key => {
            TagType = key;
            SecondaryTagType = null;
            ContentId = null;
            TagId = null;

            if (TagType === 'Tags') IsAbel = 0;
            else IsAbel = 1;

            for (var i = 0; i < Categories[key].length; i++) {
                if (TagType === 'Tags') TagId = Categories[key][i];
                else ContentId = Categories[key][i];

                SelectedCategories.push({ IsAbel, TagType, SecondaryTagType, ContentId, TagId })
            }

            return null;
        });

        this.setState({ TryingChooseCategories: true });
        this.props.ChooseWorkoutTemplateTags({ Categories: JSON.stringify(SelectedCategories) }).then(() => {
            this.setState({ TryingChooseCategories: false });
            this.onToggleShowChooseCategoryTagsModal(false);
        });
    }

    onClearAllFilters = () => {
        var { Device } = this.props;

        this.setState({ Injuries: [], Owners: [], RepRange: [], TagIds: [], TrainingFocus: [], WorkoutType: [] }, () => Device === 'laptop' && this.onLoadTemplates());
    }

    onClickCreateTemplate = () => {
        history.push('library/workout-template');
    }

    onDeleteCategory = ({ CategoryId }) => {
        var SelectedCategoryIndex = this.state.SelectedCategoriesIndex.indexOf(CategoryId);

        if (SelectedCategoryIndex !== -1) {
            var SelectedCategories = [ ...this.state.SelectedCategories.slice(0, SelectedCategoryIndex), ...this.state.SelectedCategories.slice(SelectedCategoryIndex + 1) ];
            var SelectedCategoriesIndex = [ ...this.state.SelectedCategoriesIndex.slice(0, SelectedCategoryIndex), ...this.state.SelectedCategoriesIndex.slice(SelectedCategoryIndex + 1) ];
            
            this.setState({ ForceReload: true, SelectedCategories, SelectedCategoriesIndex });
            this.props.RemoveCategory_WorkoutTemplate({ CategoryId });
        }
        else this.props.RemoveCategory_WorkoutTemplate({ CategoryId });
    }

    onDeleteTemplates = () => {
        var { MultiSelectTemplateIds } = this.state;

        if (!MultiSelectTemplateIds.length) this.onToggleShowDeleteTemplatesModal(false);
        else {
            console.log('MultiSelectTemplateIds: ', MultiSelectTemplateIds);
            // this.props.DeleteWorkoutTemplates({ TemplateIds: MultiSelectTemplateIds }).then(() => {
            //     if (!this.props.TryingDeleteWorkoutTemplatesError) {
            //         this.setState({ MultiSelectTemplateIds: [], MultiSelectTemplates: [], ShowMultiSelect: false }, () => {
            //             this.onToggleShowDeleteTemplatesModal(false);
            //             this.onLoadTemplates();
            //         });
            //     }
            // });
        }
    }

    onDeleteTags = ({ Tag }) => {
        var SelectedTagIndex = this.state.TagIds.indexOf(+Tag.TagId);
        if (SelectedTagIndex !== -1) this.setState({ ForceReload: true, TagIds: [ ...this.state.TagIds.slice(0, SelectedTagIndex), ...this.state.TagIds.slice(SelectedTagIndex + 1) ] });
        
        this.props.DeleteWorkoutTemplateTags({ TagIds: [ Tag.TagId ] });
    }

    onDeleteToTrash = () => {
        var { MultiSelectTemplateIds } = this.state;

        this.setState({ StateLoading: true });
        this.props.MoveTemplatesToTrash({ TemplateIds: MultiSelectTemplateIds }).then(() => {
            this.setState({ MultiSelectTemplateIds: [], MultiSelectTemplates: [], ShowMultiSelect: false, StateLoading: false }, () => {
                this.onLoadTemplates();
            });
        });
    }

    onEditWorkoutTemplateTag = ({ TagId, TagName }) => {
        return this.props.EditWorkoutTemplateTag({ TagId, TagName }).then(({ IsDuplicate }) => IsDuplicate);
    }

    onInsertEditWorkoutTemplateFilter = FilterSettings => {
        this.props.InsertEditWorkoutTemplateFilter({ ...FilterSettings });
    }

    onInsertWorkoutTemplateTags = NewTags => {
        this.props.InsertWorkoutTemplateTags({ NewTags });
    }

    onLoadTemplates = Increment => {
        var { ForceReload, Injuries, NewSearch, Owners, PageNo, PageSize, RepRange, SearchValue, SelectedCategories, TagIds, TrainingFocus, WorkoutType } = this.state;
        var { WorkoutTemplateFilterSettings: { HasDeleted }, ShowTrash, UserDetails: { LanguageId } } = this.props;

        var HideDeleted = !!ShowTrash ? 0 : 1;

        if (!HasDeleted && !!ShowTrash && !ForceReload) this.setState({ Loading: false });
        else {
            if (Increment) {
                NewSearch = false;
                PageNo = PageNo + 1;
            } else {
                NewSearch = true;
                PageNo = 1;
            }
    
            for (var i = 0; i < SelectedCategories.length; i++) {
                var { IsAbel, TagId, TagType } = SelectedCategories[i];
    
                if (IsAbel) {
                    if (TagType === 'Injuries') TagIds = [ ...new Set([ ...Injuries, TagId ]) ];
                    else if (TagType === 'MadeBy') Owners = [ ...new Set([ ...Owners, TagId ]) ];
                    else if (TagType === 'RepRange') RepRange = [ ...new Set([ ...RepRange, TagId ]) ];
                    else if (TagType === 'Tags') TagIds = [ ...new Set([ ...TagIds, TagId ]) ];
                    else if (TagType === 'TrainingFocus') Owners = [ ...new Set([ ...TrainingFocus, TagId ]) ];
                    else if (TagType === 'WorkoutType') WorkoutType = [ ...new Set([ ...WorkoutType, TagId ]) ];
                }
                else TagIds = [ ...new Set([ ...TagIds, TagId ]) ];
            }
        
            this.setState({ ActiveSearch: true, ForceReload: false, NewSearch, PageNo }, () => this.props.SearchWorkoutTemplates({ AddToExisting: Increment, HasDeleted, HideDeleted, Injuries, LanguageId, Owners, PageNo, PageSize, RepRange, ShowTrash, TagIds, TemplateName: SearchValue, TrainingFocus, WorkoutType }).then(this.setState({ ActiveSearch: false, CompletedFirstSearch: true, Loading: false })));
        }
    }

    onMultiSelect = (Template, MultiSelectIndex) => {
        if (MultiSelectIndex === -1) this.setState({ MultiSelectTemplateIds: [ ...this.state.MultiSelectTemplateIds, Number(Template.TemplateId)], MultiSelectTemplates: [ ...this.state.MultiSelectTemplates, Template ] });
        else {
            this.setState((prevState) => {
                return {
                    MultiSelectTemplateIds: [ ...prevState.MultiSelectTemplateIds.slice(0, MultiSelectIndex), ...prevState.MultiSelectTemplateIds.slice(MultiSelectIndex + 1) ],
                    MultiSelectTemplates: [ ...prevState.MultiSelectTemplates.slice(0, MultiSelectIndex), ...prevState.MultiSelectTemplates.slice(MultiSelectIndex + 1) ]
                }
            });
        }
    }

    onRecoverFromTrash = () => {
        var { MultiSelectTemplateIds } = this.state;

        this.setState({ ForceReload: true, StateLoading: true });
        this.props.RecoverTemplatesFromTrash({ TemplateIds: MultiSelectTemplateIds }).then(() => {
            this.setState({ MultiSelectTemplateIds: [], MultiSelectTemplates: [], ShowMultiSelect: false, StateLoading: false }, () => {
                this.onLoadTemplates();
            });
        });
    }

    onReorderFilters = ReorderedFilters => {
        this.props.ReorderTemplateFilters({ Filters: ReorderedFilters });
    }

    onSearchFromFiltersModal = () => {
        this.setState({ ShowFiltersModals: false }, () => this.onLoadTemplates());
    }

    onSearchFromSAYT = SearchValue => {
        if (!this.props.TryingSearchWorkoutTemplates) {
            var { LastSearchedValue } = this.state;

            if (SearchValue !== LastSearchedValue) {
                this.setState({ PageNo: 1, PageSize: INITIAL_STATE.PageSize, LastSearchedValue: SearchValue, SearchValue }, () => this.onLoadTemplates());
            }
        }
    }

    onSelectAll = () => {
        var { WorkoutTemplateList } = this.props;

        var MultiSelectTemplates = [ ...this.state.MultiSelectTemplates ];
        var MultiSelectTemplateIds = [ ...this.state.MultiSelectTemplateIds ];

        WorkoutTemplateList.forEach(element => {
            var TemplateId = +element.TemplateId;

            if (MultiSelectTemplateIds.indexOf(TemplateId) === -1) {
                MultiSelectTemplates.push(element);
                MultiSelectTemplateIds.push(TemplateId);
            }
        });

        this.setState({ MultiSelectTemplates, MultiSelectTemplateIds });
    }

    onSelectCategory = (Category, SelectedCategoryIndex) => {
        var { SelectedCategories, SelectedCategoriesIndex } = this.state;

        if (SelectedCategoryIndex === -1) { SelectedCategories = [ ...SelectedCategories, Category ]; SelectedCategoriesIndex = [ ...SelectedCategoriesIndex, +Category.CategoryId ]; }
        else {
            SelectedCategories = [ ...SelectedCategories.slice(0, SelectedCategoryIndex), ...SelectedCategories.slice(SelectedCategoryIndex + 1) ];
            SelectedCategoriesIndex = [ ...SelectedCategoriesIndex.slice(0, SelectedCategoryIndex), ...SelectedCategoriesIndex.slice(SelectedCategoryIndex + 1) ];
        }

        this.setState({ SelectedCategories, SelectedCategoriesIndex }, () => this.onLoadTemplates());
    }

    onSelectMergeTagsCheckmark = event => {
        var { MergeTags } = this.state;

        if (event) MergeTags = +(event.target.checked);
        else MergeTags = +!MergeTags;

        this.setState({ MergeTags });
    }

    onSelectTag = (event, Filter, TagId, TagIndex, TargetAvoid, OppositeTargetAvoidIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = TagIndex === -1;

        var { SelectedCategories_Modal } = this.state;

        var FilterName = Filter.FilterName;
        if (FilterName === 'Joints' || FilterName === 'Muscles') {
            FilterName = `${TargetAvoid}${FilterName}`;
            Filter.TargetAvoid = TargetAvoid;
        }

        if (checked) {
            SelectedCategories_Modal[FilterName] = [ ...SelectedCategories_Modal[FilterName], TagId ];

            // Remove the opposite target/avoid if already selected
                if ((Filter.FilterName === 'Joints' || Filter.FilterName === 'Muscles') && OppositeTargetAvoidIndex !== -1) {
                    var OppositeFilterName = `${TargetAvoid === 'Avoid' ? 'Target' : 'Avoid'}${Filter.FilterName}`;
                    SelectedCategories_Modal[OppositeFilterName] = [ ...this.state.SelectedCategories_Modal[OppositeFilterName].slice(0, OppositeTargetAvoidIndex), ...this.state.SelectedCategories_Modal[OppositeFilterName].slice(OppositeTargetAvoidIndex + 1) ];
                }
        } else SelectedCategories_Modal[FilterName] = [ ...this.state.SelectedCategories_Modal[FilterName].slice(0, TagIndex), ...this.state.SelectedCategories_Modal[FilterName].slice(TagIndex + 1) ];

        this.setState({ SelectedCategories_Modal });
    }

    onSelectTemplate = Template => {
        this.props.OnSelectTemplate(Template);
    }

    onShareContentToEmails = ({ Emails, ContentIds }) => {
        this.props.UpdateSharedToCoachStatus_WorkoutTemplates({ Emails, NewStatus: 'Pending', TemplateIds: ContentIds }).then(() => {
            this.onToggleShowShareContentModal(false);
        });
    }

    onToggleShowAddRemoveTagsPopover = ({ ShowAddRemoveTagsPopover_ActionType = null }) => {
        this.setState({ ShowAddRemoveTagsPopover: !this.state.ShowAddRemoveTagsPopover, ShowAddRemoveTagsPopover_ActionType });
    }

    onToggleFilterStartOpen = Filter => {
        var { StartOpen, TagFilterId } = Filter;

        this.onInsertEditWorkoutTemplateFilter({ FilterId: TagFilterId, ...Filter, StartOpen: +!!!StartOpen });
    }

    onToggleFilterVisibility = (Filter, VisibilityStatus) => {
        var { TagFilterId } = Filter;
        var IsHidden = VisibilityStatus === 'Show' ? 0 : 1;

        this.onInsertEditWorkoutTemplateFilter({ FilterId: TagFilterId, ...Filter, IsHidden });
    }

    onToggleMultiSelect = event => {
        this.setState({ MultiSelectTemplateIds: [], MultiSelectTemplates: [], ShowMultiSelect: event.target.checked });
    }

    onToggleShowChooseCategoryTagsModal = ShowChooseCategoryTagsModal => {
        var SelectedCategories_Modal = ShowChooseCategoryTagsModal ? GetSelectedCategories(this.props.WorkoutTemplateFilterSettings.Categories) : { ...SELECTED_CATEGORIES_DEFAULT };

        this.setState({ SelectedCategories_Modal, ShowChooseCategoryTagsModal });
    }

    onToggleShowDeleteTemplatesModal = ShowDeleteTemplatesModal => {
        if (!this.state.MultiSelectTemplateIds.length && !!ShowDeleteTemplatesModal) {}
        else this.setState({ ShowDeleteTemplatesModal });
    }

    onToggleShowEditShareAbilityStatusModal = ({ ContentId = null, ContentIndex = null, ShowEditShareAbilityStatusModal, CurrentStatus = null }) => {
        this.setState({ ShowEditShareAbilityStatusModal, ShowEditShareAbilityStatusModalDetails: { ContentId, ContentIndex, CurrentStatus } });
    }

    onToggleShowFiltersModal = ShowFiltersModals => {
        this.setState({ ShowFiltersModals });
    }

    onToggleShowHowSharingWorksModal = ShowHowSharingWorksModal => {
        this.setState({ ShowHowSharingWorksModal });
    }

    onToggleShowShareContentModal = ShowShareContentModal => {
        if (!this.state.MultiSelectTemplateIds.length && !!ShowShareContentModal) {}
        else this.setState({ ShowShareContentModal });
    }

    onToggleShowTemplateDetailModal = (ShowTemplateDetailModal, TemplateDetail_Preview, TemplateId) => {
        var { SelectedTemplate, SelectedTemplateId } = this.state;

        if (TemplateId && Number(TemplateId) === Number(SelectedTemplateId)) { SelectedTemplate = {}; SelectedTemplateId = null; }

        if (!this.props.UseNativeTemplateDetail && !!this.props.FromAddWorkouts) this.props.OnShowTemplateDetail(ShowTemplateDetailModal, TemplateDetail_Preview);
        
        this.setState({ SelectedTemplate, SelectedTemplateId, ShowTemplateDetailModal, TemplateDetail_Preview });
    }

    onUpdateFilters = (Filter, Selected, DontSearch = false) => {
        var { FilterName, IsAbel } = Filter;

        if (IsAbel) {
            if (FilterName === 'Injuries') this.setState({ Injuries: Selected }, () => !DontSearch && this.onLoadTemplates());
            else if (FilterName === 'MadeBy') this.setState({ Owners: Selected }, () => !DontSearch && this.onLoadTemplates());
            else if (FilterName === 'RepRange') this.setState({ RepRange: Selected }, () => !DontSearch && this.onLoadTemplates());
            else if (FilterName === 'Tags') this.setState({ TagIds: Selected }, () => !DontSearch && this.onLoadTemplates());
            else if (FilterName === 'TrainingFocus') this.setState({ TrainingFocus: Selected }, () => !DontSearch && this.onLoadTemplates());
            else if (FilterName === 'WorkoutType') this.setState({ WorkoutType: Selected }, () => !DontSearch && this.onLoadTemplates());
        }
        else this.setState({ TagIds: Selected }, () => !DontSearch && this.onLoadTemplates());
    }

    onUpdateMultiSelectList = ({ ContentId, NewStatus, NewStatusName }) => {
        var ContentIndex = this.state.MultiSelectTemplateIds.indexOf(+ContentId);

        if (ContentIndex !== -1) {
            this.setState(prevState => {
                var MultiSelectTemplates = [ ...prevState.MultiSelectTemplates ];
                MultiSelectTemplates[ContentIndex] = { ...MultiSelectTemplates[ContentIndex], ShareAbility: NewStatusName, ShareAbilityStatusId: NewStatus };
                
                return { ...prevState, MultiSelectTemplates };
            });
        }
    }
    
    onUpdateShareAbilityStatus = ({ ContentId, ContentIndex, NewStatus, NewStatusName }) => {
        return this.props.UpdateShareAbilityStatus_WorkoutTemplates({ TemplateId: ContentId, NewStatus, NewStatusName }).then(() => {
            this.setState(prevState => {
                var MultiSelectTemplates = [ ...prevState.MultiSelectTemplates ];
                MultiSelectTemplates[ContentIndex] = { ...MultiSelectTemplates[ContentIndex], ShareAbility: NewStatusName, ShareAbilityStatusId: NewStatus };
                
                return { ...prevState, MultiSelectTemplates };
            });
        });
    }

    onUpdateSharedToCoachStatus = ({ ContentId, NewStatus, PersonalResponse }) => {
        this.setState({ StateLoading: true });
        this.props.UpdateSharedToCoachStatus_WorkoutTemplates({ Emails: [ this.props.UserDetails.Email ], TemplateIds: [ ContentId ], NewStatus, PersonalResponse }).then(() => {
            this.setState({ StateLoading: false });
            this.onLoadTemplates();
        });
    }

    onUpdateSharedToCoachStatus_All = ({ NewStatus }) => {
        var { MergeTags } = this.state;

        this.setState({ StateLoading: true });
        this.props.UpdateSharedToCoachStatusAll_WorkoutTemplates({ MergeTags, NewStatus }).then(() => {
            this.setState({ StateLoading: false });
            this.onLoadTemplates();
        });
    }

    onUpdateTemplatesTags = ({ ActionType, TagIds }) => {
        this.props.UpdateTemplatesTags({ ActionType, TemplateIds: this.state.MultiSelectTemplateIds.join(','), TagIds }).then(() => {
            this.onToggleShowAddRemoveTagsPopover({});
            // this.onCancelMultiSelect();
            if (this.state.TagIds.some(v => TagIds.includes(v))) this.onLoadTemplates();
        });
    }

    renderBottomBar = () => {
        var { t } = this.props;
        var { MultiSelectTemplateIds, ShowAddRemoveTagsPopover_ActionType, ShowMultiSelect } = this.state;

        if (!ShowMultiSelect && !MultiSelectTemplateIds.length) return null;
        // if (!MultiSelectTemplateIds.length) return null;

        var { Device } = this.props;

        var Buttons = [];
        var AddRemoveTagsButtons = [];

        if (this.props.ShowTrash) {
            Buttons = [
                ...Buttons,
                { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', OnClick: () => this.onRecoverFromTrash(true), Title: t('_recover') },
                // { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', IconSVG: TrashSVG, IconPosition: 'left', OnClick: this.onDeleteTemplates, Title: t('_delete_for_good') },
                { Border: 'none', ColorHover: 'black', NoBackground: true, OnClick: this.onCancelMultiSelect, Title: t('Cancel_with_first_char_uppercase') }
            ];
        }
        else {
            Buttons = [
                ...Buttons,
                { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', Disabled: !MultiSelectTemplateIds.length, OnClick: () => this.onToggleShowShareContentModal(true), Title: t('_share_with') },
                { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', Disabled: !MultiSelectTemplateIds.length, IconSVG: TrashSVG, IconPosition: 'left', OnClick: this.onDeleteToTrash, Title: t('_move_to_trash') },
                { Border: 'none', ColorHover: 'black', NoBackground: true, OnClick: this.onCancelMultiSelect, Title: t('Cancel_with_first_char_uppercase') }
            ];

            AddRemoveTagsButtons = [
                { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', Disabled: !MultiSelectTemplateIds.length, OnClick: () => this.onToggleShowAddRemoveTagsPopover({ ShowAddRemoveTagsPopover_ActionType: 'Adding' }), Title: t('_add_tags') },
                { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', Disabled: !MultiSelectTemplateIds.length, OnClick: () => this.onToggleShowAddRemoveTagsPopover({ ShowAddRemoveTagsPopover_ActionType: 'Removing' }), Title: t('_remove_tags') }
            ]
        }

        const popoverProps = {
            isOpen: this.state.ShowAddRemoveTagsPopover,
            preferPlace: 'above',
            onOuterAction: () => this.onToggleShowAddRemoveTagsPopover({}),
            body: <AddRemoveTagsPopoverComponent ActionType={ShowAddRemoveTagsPopover_ActionType} TagIds={MultiSelectTemplateIds} GetAllTagsForAddingRemoving={({ ActionType, TagIds: TemplateIds }) => this.props.GetAllTagsForAddingRemoving_Templates({ ActionType, TemplateIds })} t={t} UpdateTags={this.onUpdateTemplatesTags} />,
            tipSize: 10
        }

        return (
            <>
                <Spacer Size="extra-large" />

                <Styles.StyledBottomBar className="StyledBottomBar">
                    <Styles.BottomBarItems className="BottomBarItems" FirstOne>
                        <Styles.BottomBarLeftContainer className="BottomBarLeftContainer">
                            <PageText ElementType="span" FontColor="blue-abel" FontFamily="bold" FontSize="medium-3" Text={`${MultiSelectTemplateIds.length}`} />
                            &nbsp;
                            <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('WorkoutPlanSettings_selected').toLowerCase()} />
                        </Styles.BottomBarLeftContainer>

                        <Styles.BottomBarRightContainer className="BottomBarRightContainer">
                            <PageText FontFamily="bold" FontSize="medium-1" NoMargin OnClick={this.onSelectAll} Text={t('select_all')} />
                        </Styles.BottomBarRightContainer>

                        {
                            !this.props.ShowTrash &&
                            <Popover {...popoverProps}>
                                <Styles.AddRemoveTagsButtons
                                    Buttons={AddRemoveTagsButtons}
                                    ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                                    NotTouching
                                    OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                                />
                            </Popover>
                        }
                    </Styles.BottomBarItems>
                    <Styles.BottomBarItems className="BottomBarItems" FullWidth={true}>
                        <ButtonGroup
                            Buttons={Buttons}
                            ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                            NotTouching
                            OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                        />
                    </Styles.BottomBarItems>
                </Styles.StyledBottomBar>
            </>
        );
    }

    renderDeleteTemplatesInformation = () => {
        var { t } = this.props;
        var { MultiSelectTemplates } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" Text={t('template_delete_warning')} TextAlign="center" />

                <Styles.DeleteTemplatesList>
                    {
                        MultiSelectTemplates.map(Template => <WorkoutTemplateListItem key={Template.TemplateId} HideSelect Item={Template} ViewTemplate={Template => this.onToggleShowTemplateDetailModal(true, Template)} />)
                    }
                </Styles.DeleteTemplatesList>
            </>
        );
    }

    renderDeleteTemplatesModal = () => {
        var { t } = this.props;
        var { TryingDeleteWorkoutTemplates } = this.props;
        var { ShowDeleteTemplatesModal, ShowTemplateDetailModal } = this.state;

        if (ShowDeleteTemplatesModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteTemplates}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    DisplayNone={ShowTemplateDetailModal}
                    InformationRenderer={this.renderDeleteTemplatesInformation}
                    Loading={TryingDeleteWorkoutTemplates}
                    OnHide={() => this.onToggleShowDeleteTemplatesModal(false)}
                    Show={ShowDeleteTemplatesModal}
                />
            );
        }
    }

    renderFiltersModal_Info = () => {
        var { Device, FromAddWorkouts, ShowTrash, WorkoutTypes } = this.props;
        var { Injuries, Owners, RepRange, TagIds, TrainingFocus, WorkoutType } = this.state;

        var DefaultSelections = { Injuries, MadeBy: Owners, RepRange, Tags: TagIds, TrainingFocus, WorkoutType };
        
        return (
            <Filters
                DefaultSelections={DefaultSelections}
                Device={Device}
                Filters={this.props.WorkoutTemplateFilterSettings ? this.props.WorkoutTemplateFilterSettings.Filters : []}
                HideCustomizeButton
                HideSections1={[ 'WorkoutType' ]}
                HideSections={!!FromAddWorkouts && WorkoutTypes.length && [ 'WorkoutType' ]}
                OnClearAllFilters={this.onClearAllFilters}
                OnDeleteTag={this.onDeleteTags}
                OnUpdateSelections={(Filter, Selected) => this.onUpdateFilters(Filter, Selected, true)}
                ShowTrash={ShowTrash}
            />
        );
    }

    renderFiltersModal = () => {
        var { t } = this.props;
        var { ShowFiltersModals } = this.state;

        if (ShowFiltersModals) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onSearchFromFiltersModal}
                    BottomButtonText={t('_search')}
                    InformationRenderer={this.renderFiltersModal_Info}
                    OnHide={() => this.onToggleShowFiltersModal(false)}
                    Show={ShowFiltersModals}
                />
            );
        }
    }

    renderTemplates = () => {
        var { t } = this.props;
        var { WorkoutTemplateList, WorkoutTemplateList_NumPendingShared, WorkoutTemplateList_TotalRecords, FromAddWorkouts, IsMoreWorkoutTemplates, SelectedItemIds } = this.props;
        var { ActiveSearch, CompletedFirstSearch, MergeTags, NewSearch, PageNo, PageSize, ShowMultiSelect } = this.state;

        ActiveSearch = false;
        CompletedFirstSearch = false;

        return (
            <>
                {
                    !!WorkoutTemplateList_NumPendingShared &&
                    <Styles.PendingSharedContainer className="PendingSharedContainer">
                        <Styles.PendingSharedLeftContainer className="PendingSharedLeftContainer">
                            <Styles.PendingSharedImage className="PendingSharedImage" ImageSrc={Sharing_Gift} SelfLoading />
                        </Styles.PendingSharedLeftContainer>
                        <Styles.PendingSharedRightContainer className="PendingSharedRightContainer">
                            <Styles.PendingSharingRightTopContainer className="PendingSharingRightTopContainer">
                                <MultiText
                                    Texts={[
                                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('_you_have') },
                                        { FontFamily: 'bold', FontSize: 'medium-2', Text: `${WorkoutTemplateList_NumPendingShared}` },
                                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('_sharing_requests_pending') }
                                    ]}
                                />
                                
                                <Styles.CheckmarkTextContainer className="CheckmarkTextContainer">
                                    <Checkmark
                                        ItemId={0}
                                        OnSelectItem={this.onSelectMergeTagsCheckmark}
                                        SelectedIndex={MergeTags ? 1 : -1}
                                        Title="MergeTags"
                                    />

                                    <Styles.CheckmarkItemName
                                        ElementType="div"
                                        FontFamily="medium"
                                        FontSize="small"
                                        NoMargin
                                        OnClick={() => this.onSelectMergeTagsCheckmark(null)}
                                        Text={t('_i_want_receive_custom_tags_generic')}
                                        Title="MergeTags"
                                    />
                                </Styles.CheckmarkTextContainer>
                            </Styles.PendingSharingRightTopContainer>
                            <ButtonGroup
                                Buttons={[
                                    { BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', OnClick: () => this.onUpdateSharedToCoachStatus_All({ NewStatus: 'accept' }), Size: 'small', Title: t('_accept') },
                                    { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', OnClick: () => this.onUpdateSharedToCoachStatus_All({ NewStatus: 'reject' }), Size: 'small', Title: t('_decline') }
                                ]}
                                ButtonWidth="fit-content"
                                JustifyContent="flex-start"
                                NotTouching
                            />
                        </Styles.PendingSharedRightContainer>
                    </Styles.PendingSharedContainer>
                }

                <Styles.StyledLoadMore
                    ActiveSearch={ActiveSearch}
                    CompletedFirstSearch={CompletedFirstSearch}
                    HideViewCount
                    IsLoading={false}
                    ItemDescriptionType={t('templates_plural').toLowerCase()}
                    ItemId="TemplateId"
                    ItemName="TemplateName"
                    ItemProps={{
                        AssignToClients: !!FromAddWorkouts ? false : true,
                        HideMadeBy: true,
                        HideSelect: !!FromAddWorkouts ? false : !ShowMultiSelect,
                        IsMultiSelect: !!FromAddWorkouts ? false : true,
                        MultiSelectItemIds: this.state.MultiSelectTemplateIds,
                        OnAssignToClients: (event, Template) => this.onAssignToClients(event, Template),
                        OnMultiSelect: (Template, MultiSelectIndex) => this.onMultiSelect(Template, MultiSelectIndex),
                        OnUpdateSharedToCoachStatus: this.onUpdateSharedToCoachStatus,
                        SelectTemplate: Template => this.onSelectTemplate(Template),
                        ViewTemplate: Template => this.onToggleShowTemplateDetailModal(true, Template)
                    }}
                    ItemRenderer={WorkoutTemplateListItem}
                    Items={WorkoutTemplateList}
                    HasMoreItems={IsMoreWorkoutTemplates}
                    LoadMore={() => this.onLoadTemplates(true)}
                    NewSearch={NewSearch}
                    NoItemsText={t('search_noresults')}
                    PageNo={PageNo}
                    PageSize={PageSize}
                    RenderHeader={(!!+WorkoutTemplateList_NumPendingShared && !ShowMultiSelect) ? this.onRenderListHeader : null}
                    SelectedItemIds={SelectedItemIds}
                    TotalRecords={WorkoutTemplateList_TotalRecords}
                />
            </>
        );
    }

    renderSearchAndCategories = () => {
        var { t } = this.props;
        var { Device, IsModal, WorkoutTemplateFilterSettings, UserDetails: { LanguageId } } = this.props;
        var { SelectedCategoriesIndex } = this.state;

        if (Device === 'laptop') {
            return (
                <Styles.SearchAndCategoriesContainer className="SearchAndCategoriesContainer">
                    <Styles.StyledSAYT
                        ExtraSearchProps={{ LanguageId }}
                        ItemName="TemplateName"
                        OnSearch={this.onSearchFromSAYT}
                        Placeholder={t('_search')}
                        SearchFunc={this.props.SearchWorkoutTemplates_SAYT}
                    />
    
                    {
                        WorkoutTemplateFilterSettings.Categories && WorkoutTemplateFilterSettings.Categories.map(Category => {
                            var { CategoryId, TagName } = Category;
                            var SelectedCategoryIndex = SelectedCategoriesIndex.indexOf(+CategoryId);
    
                            return (
                                <Styles.CategoryContainer className="CategoryContainer" key={Category.CategoryId} onClick={() => this.onSelectCategory(Category, SelectedCategoryIndex)} Selected={SelectedCategoryIndex !== -1}>
                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={TagName} TextAlign="center" />
                                </Styles.CategoryContainer>
                            );
                        })
                    }
    
                    <Styles.CategoryContainer className="CategoryContainer" onClick={() => this.onToggleShowChooseCategoryTagsModal(true)}>
                        <Styles.AddCategoryIcon ImageSrc={Plus_Dark} />
                    </Styles.CategoryContainer>
                </Styles.SearchAndCategoriesContainer>
            );
        } else {
            return (
                <Styles.SearchAndCategoriesContainer className="SearchAndCategoriesContainer" IsModal={IsModal}>
                    <Styles.SearchAndCategoriesTopContainer className="SearchAndCategoriesTopContainer" IsModal={IsModal}>
                        <Styles.StyledSAYT
                            ExtraSearchProps={{ LanguageId }}
                            IsModal={IsModal}
                            ItemName="TemplateName"
                            OnSearch={this.onSearchFromSAYT}
                            Placeholder={t('_search')}
                            SearchFunc={this.props.SearchWorkoutTemplates_SAYT}
                        />

                        <ButtonGroup Buttons={[{ BackgroundColor: "white-concrete", BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', IconSVG: FilterSVG, IconSVGColor: 'black', IconPosition: 'left', OnClick: () => this.onToggleShowFiltersModal(true), Title: t('_select_filters') }]} />
                    </Styles.SearchAndCategoriesTopContainer>
    
                    <Styles.CategoriesContainer className="CategoriesContainer">
                        {
                            WorkoutTemplateFilterSettings.Categories && WorkoutTemplateFilterSettings.Categories.map(Category => {
                                if (this.props.WorkoutTypes && Category.TagType === 'WorkoutType') return null; // Don't Show Categories For Other Workout Types as this setting comes from AddWorkouts where a workout type is already selected. Choosing wrong kind will lead to bugs / crashes

                                var { CategoryId, TagName } = Category;
                                var SelectedCategoryIndex = SelectedCategoriesIndex.indexOf(+CategoryId);
        
                                return (
                                    <Styles.CategoryContainer className="CategoryContainer" key={Category.CategoryId} onClick={() => this.onSelectCategory(Category, SelectedCategoryIndex)} Selected={SelectedCategoryIndex !== -1}>
                                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={TagName} TextAlign="center" />
                                    </Styles.CategoryContainer>
                                );
                            })
                        }
        
                        {
                            !IsModal &&
                            <Styles.CategoryContainer className="CategoryContainer" onClick={() => this.onToggleShowChooseCategoryTagsModal(true)}>
                                <Styles.AddCategoryIcon ImageSrc={Plus_Dark} />
                            </Styles.CategoryContainer>
                        }
                    </Styles.CategoriesContainer>
                </Styles.SearchAndCategoriesContainer>
            );
        }
    }

    renderShowChooseCategoryTagsModal = () => {
        var { ShowChooseCategoryTagsModal } = this.state;

        if (ShowChooseCategoryTagsModal) {
            var { t } = this.props;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onChooseCategories}
                    BottomButtonText={t('_save')}
                    InformationRenderer={this.renderShowChooseCategoryTagsModal_Information}
                    OnHide={() => this.onToggleShowChooseCategoryTagsModal(false)}
                    Show={ShowChooseCategoryTagsModal}
                    Size="medium"
                />
            );
        }
    }

    renderShowChooseCategoryTagsModal_Information = () => {
        var { t } = this.props;
        var { Filters } = this.props.WorkoutTemplateFilterSettings;

        var { TryingChooseCategories } = this.state;

        return (
            <>
                {TryingChooseCategories && <Loading />}

                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('_pick_tags_as_highlights1')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <Styles.FiltersContainer className="FiltersContainer">
                    {
                        Filters.map(F => {
                            var { FilterName, IsAbel, TagFilterId, Tags } = F;
                            var TranslatedFilterName = GetTranslatedFilterName(F, t);

                            return (
                                <Styles.FilterSectionContainer className="FilterSectionContainer" key={TagFilterId}>
                                    <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={TranslatedFilterName} TextAlign="left" />

                                    <Spacer Size="extra-extra-small" />

                                    <Styles.TagsContainer className="TagsContainer">
                                        {
                                            IsAbel && (FilterName === 'Joints' || FilterName === 'Muscles') &&
                                            <Styles.FilterTagContainer className="FilterTagContainer" JustifyContent="space-between">
                                                <Styles.CheckmarkIcon ImageSrc={Blocked} Loading={false} />

                                                <Styles.CheckmarkIcon ImageSrc={Checkmark_Selected} Loading={false} />
                                            </Styles.FilterTagContainer>
                                        }
                                        {Tags.map(Tag => this.renderTag(F, Tag))}
                                    </Styles.TagsContainer>
                                </Styles.FilterSectionContainer>
                            );
                        })
                    }
                </Styles.FiltersContainer>
            </>
        );
    }

    renderShowEditShareAbilityStatusModal = () => {
        var { ShowEditShareAbilityStatusModal, ShowEditShareAbilityStatusModalDetails } = this.state;

        if (ShowEditShareAbilityStatusModal) {
            return (
                <EditShareAbilityStatusModal
                    ContentId={ShowEditShareAbilityStatusModalDetails.ContentId}
                    ContentIndex={ShowEditShareAbilityStatusModalDetails.ContentIndex}
                    CurrentStatus={ShowEditShareAbilityStatusModalDetails.CurrentStatus}
                    Loading={this.props.TryingUpdateShareAbilityStatus_WorkoutTemplates}
                    OnHide={() => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: false })}
                    OnUpdateShareAbilityStatus={this.onUpdateShareAbilityStatus}
                    Show={ShowEditShareAbilityStatusModal}
                />
            );
        }
    }

    renderShowHowSharingWorksModal = () => {
        var { ShowHowSharingWorksModal } = this.state;

        if (ShowHowSharingWorksModal) {
            return (
                <HowSharingWorksModal
                    OnHide={() => this.onToggleShowHowSharingWorksModal(false)}
                    Show={ShowHowSharingWorksModal}
                />
            );
        }
    }

    renderShowShareContentModal = () => {
        var { MultiSelectTemplates, ShowEditShareAbilityStatusModal, ShowHowSharingWorksModal, ShowShareContentModal } = this.state;

        if (ShowShareContentModal) {
            var { TryingUpdateSharedToCoachStatus_WorkoutTemplates } = this.props;

            return (
                <ShareContentModal
                    ContentIdName="TemplateId"
                    ContentType="WorkoutTemplates"
                    DisplayNone={!!ShowEditShareAbilityStatusModal || !!ShowHowSharingWorksModal}
                    OnHide={() => this.onToggleShowShareContentModal(false)}
                    OnSubmit={({ Emails, ContentIds }) => this.onShareContentToEmails({ Emails, ContentIds })}
                    OnToggleShowEditShareAbilityStatusModal={({ ContentIndex, ContentId, CurrentStatus }) => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: true, ContentId, ContentIndex, CurrentStatus })}
                    OnToggleShowHowSharingWorksModal={() => this.onToggleShowHowSharingWorksModal(true)}
                    Loading={TryingUpdateSharedToCoachStatus_WorkoutTemplates}
                    SharingContent={MultiSelectTemplates}
                    Show={ShowShareContentModal}
                />
            );
        }
    }

    renderTag = (Filter, Tag) => {
        var { SelectedCategories_Modal: SelectedCategories } = this.state;

        var key = Tag.TagId;
        if (Filter.IsAbel && Filter.FilterName === 'Equipment') key = Tag.EquipmentCategory;

        if (Filter.IsAbel && (Filter.FilterName === 'Joints' || Filter.FilterName === 'Muscles')) {
            var SelectedIndex_Avoid = SelectedCategories[`Avoid${Filter.FilterName}`].indexOf(Tag.TagId);
            var SelectedIndex_Target = SelectedCategories[`Target${Filter.FilterName}`].indexOf(Tag.TagId);

            return (
                <Styles.FilterTagContainer className="FilterTagContainer" key={key}>
                    <Checkmark
                        BlockIcon
                        ItemId={Tag.TagId}
                        OnSelectItem={event => this.onSelectTag(event, Filter, Tag.TagId, SelectedIndex_Avoid, 'Avoid', SelectedIndex_Target)}
                        SelectedIndex={SelectedIndex_Avoid}
                        Title={Tag.TagName}
                    />

                    <Styles.CheckmarkItemName
                        ElementType="div"
                        FontFamily="medium"
                        FontSize="small"
                        NoMargin
                        // OnClick={() => this.onSelectTag(null, Tag.TagId, SelectedIndex)}
                        Text={Tag.TagName}
                        Title={Tag.TagName}
                        TwoBoxes
                    />

                    <Checkmark
                        ItemId={Tag.TagId}
                        OnSelectItem={event => this.onSelectTag(event, Filter, Tag.TagId, SelectedIndex_Target, 'Target', SelectedIndex_Avoid)}
                        SelectedIndex={SelectedIndex_Target}
                        Title={Tag.TagName}
                    />
                </Styles.FilterTagContainer>
            );
        }
        else if (!(Filter.IsAbel && Filter.FilterName === 'Equipment')) {
            var SelectedIndex = SelectedCategories[Filter.FilterName].indexOf(Tag.TagId);

            return (
                <Styles.FilterTagContainer className="FilterTagContainer" key={key}>
                    <Checkmark
                        ItemId={Tag.TagId}
                        OnSelectItem={event => this.onSelectTag(event, Filter, Tag.TagId, SelectedIndex)}
                        SelectedIndex={SelectedIndex}
                        Title={Tag.TagName}
                    />

                    {Filter.FilterName === 'MadeBy' && this.renderUserPhoto(Filter, Tag, SelectedIndex)}

                    <Styles.CheckmarkItemName
                        ElementType="div"
                        FontFamily="medium"
                        FontSize="small"
                        NoMargin
                        OnClick={() => this.onSelectTag(null, Filter, Tag.TagId, SelectedIndex)}
                        Text={Tag.TagName}
                        Title={Tag.TagName}
                    />
                </Styles.FilterTagContainer>
            );
        }
        else if (Filter.IsAbel && Filter.FilterName === 'Equipment') {
            if (!Tag.EquipmentList) return null;

            return (
                <Styles.EquipmentFilterContainer className="EquipmentFilterContainer" key={key}>
                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={Tag.EquipmentCategory} TextAlign="left" />

                    <Spacer Size="extra-small" />

                    {
                        Tag.EquipmentList && Tag.EquipmentList.map(Equipment => {
                            var SelectedIndex = SelectedCategories[Filter.FilterName].indexOf(Equipment.TagId);

                            return (
                                <Styles.FilterTagContainer className="FilterTagContainer" key={Equipment.TagId}>
                                    <Checkmark
                                        ItemId={Equipment.TagId}
                                        OnSelectItem={event => this.onSelectTag(event, Filter, Equipment.TagId, SelectedIndex)}
                                        SelectedIndex={SelectedIndex}
                                        Title={Equipment.TagName}
                                    />

                                    <Styles.CheckmarkItemName
                                        ElementType="div"
                                        FontFamily="medium"
                                        FontSize="small"
                                        NoMargin
                                        OnClick={() => this.onSelectTag(null, Filter, Equipment.TagId, SelectedIndex)}
                                        Text={Equipment.TagName}
                                        Title={Equipment.TagName}
                                    />
                                </Styles.FilterTagContainer>
                            );
                        })
                    }
                </Styles.EquipmentFilterContainer>
            );
        }

        return (
            <pre key={key}>
                {JSON.stringify(Tag, null, 2)}
            </pre>
        );
    }

    renderTemplateDetailModal = () => {
        var { ShowTemplateDetailModal, TemplateDetail_Preview } = this.state;

        if (ShowTemplateDetailModal) {
            return (
                <WorkoutTemplate
                    OnAssignClients={!!this.props.FromAddWorkouts ? this.onSelectTemplate : null}
                    OnHide={TemplateId => this.onToggleShowTemplateDetailModal(false, null, TemplateId)}
                    OnLoadTemplates={this.onLoadTemplates}
                    OnUpdateMultiSelectList={this.onUpdateMultiSelectList}
                    TemplateDetail_Preview={TemplateDetail_Preview}
                    Show={ShowTemplateDetailModal}
                />
            );
        }
    }

    renderUserPhoto = (Filter, Tag, SelectedIndex) => {
        var { Sex, TagId, TagName, UserId, UserPhoto } = Tag;

        var SexImage = Sex === 'Male' ? Male : Female;

        if (+UserId === 16) UserPhoto = AbelIcon;

        return <Styles.StyledProfilePhoto Alt={TagName} ImageSrc={UserPhoto} IsAbel={UserId === 16} NoImage={SexImage} NoParent OnClick={() => this.onSelectTag(null, Filter, TagId, SelectedIndex)} />;
    }

    renderViewCount = () => {
        var { t } = this.props;
        var { WorkoutTemplateList_TotalRecords: TotalRecords } = this.props;
        var { PageNo, PageSize } = this.state;

        if (!TotalRecords) TotalRecords = 0;

        return <ViewCount PageNo={PageNo} PageSize={PageSize} TextAlign="left" TotalRecords={TotalRecords} ViewingObjectType={t('_sessions').toLowerCase()} />;
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { Device } = this.props;

        if (this.props.ShowCustomizeFilters && Device === 'laptop') {
            return (
                <>
                    {(this.props.TryingInsertEditLibraryFilterWorkoutTemplates) && <Loading />}

                    <Header LibraryType="WorkoutTemplates" ShowTrash={this.props.ShowTrash} />

                    <Styles.BodyContainer>
                        <CustomizeFilters
                            Categories={this.props.WorkoutTemplateFilterSettings ? this.props.WorkoutTemplateFilterSettings.Categories : []}
                            Filters={this.props.WorkoutTemplateFilterSettings ? this.props.WorkoutTemplateFilterSettings.Filters : []}
                            OnChooseCategories={this.onChooseCategories}
                            OnDeleteCategory={this.onDeleteCategory}
                            OnDeleteTag={this.onDeleteTags}
                            OnEditTag={this.onEditWorkoutTemplateTag}
                            OnInsertTags={this.onInsertWorkoutTemplateTags}
                            OnReorderFilters={this.onReorderFilters}
                            OnToggleFilterStartOpen={this.onToggleFilterStartOpen}
                            OnToggleFilterVisibility={this.onToggleFilterVisibility}
                            OnToggleShowChooseCategoryTagsModal={this.onToggleShowChooseCategoryTagsModal}
                            OnToggleShowHowToTagModal={this.props.OnToggleShowHowToTagModal}
                        />
                    </Styles.BodyContainer>

                    {this.renderShowChooseCategoryTagsModal()}
                </>
            );
        }

        var { t } = this.props;
        var { FromAddWorkouts, IsModal, ShowTrash, TryingSearchWorkoutTemplates, WorkoutTypes } = this.props;
        var { Injuries, Owners, RepRange, ShowFiltersModals, ShowMultiSelect, StateLoading, TagIds, TrainingFocus, WorkoutType } = this.state;

        var DefaultSelections = { Injuries, MadeBy: Owners, RepRange, Tags: TagIds, TrainingFocus, WorkoutType };

        var HeaderTitle = !!ShowTrash ? '_trash' : '_sessions';

        if (!!IsModal && ShowFiltersModals) return this.renderFiltersModal();

        return (
            <>
                {(StateLoading || TryingSearchWorkoutTemplates) && <Loading />}

                {!IsModal && <Header LibraryType="WorkoutTemplates" ShowTrash={this.props.ShowTrash} />}

                {
                    Device === 'laptop' &&
                    <Filters
                        DefaultSelections={DefaultSelections}
                        Device={Device}
                        Filters={this.props.WorkoutTemplateFilterSettings ? this.props.WorkoutTemplateFilterSettings.Filters : []}
                        HideCustomizeButton={!!IsModal}
                        HideSections={!!FromAddWorkouts && WorkoutTypes.length && [ 'WorkoutType' ]}
                        OnClearAllFilters={this.onClearAllFilters}
                        OnDeleteTag={this.onDeleteTags}
                        OnUpdateSelections={this.onUpdateFilters}
                        ShowTrash={ShowTrash}
                    />
                }

                <Styles.BodyContainer>
                    {
                        !IsModal &&
                        <Styles.BodyHeader className="BodyHeader">
                            <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={t(HeaderTitle)} TextAlign="left" />
    
                            <Styles.BodyHeaderRight className="BodyHeaderRight" ShowTrash={ShowTrash}>
                                <Styles.ToggleContainer className="ToggleContainer">
                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('_multi_select')}: ${t('_multiselect_variation1')}`} TextAlign="left"  />
    
                                    <Toggle
                                        Checked={!!+ShowMultiSelect}
                                        OffLabel={t('_off')}
                                        OnChange={this.onToggleMultiSelect}
                                        OneLabel="after"
                                        OnLabel={t('_on')}
                                    />
                                </Styles.ToggleContainer>
    
                                {!ShowTrash && <ButtonGroup Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: this.onClickCreateTemplate, Title: t('_create_new') }]} />}
                            </Styles.BodyHeaderRight>
                        </Styles.BodyHeader>
                    }

                    <Spacer Size="small" />

                    {this.renderSearchAndCategories()}

                    {
                        (!!ShowTrash && !this.props.WorkoutTemplateFilterSettings.HasDeleted) ?
                        <>
                            <Spacer Size="large" />

                            <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('_trash_empty')} TextAlign="center" />
                        </>
                    :
                        <>
                            <Spacer Size="small" />

                            {this.renderViewCount()}

                            <Spacer Size="small" />

                            {this.renderTemplates()}
                        </>
                    }

                    {this.renderBottomBar()}
                </Styles.BodyContainer>

                {this.renderDeleteTemplatesModal()}
                {this.renderFiltersModal()}
                {this.renderShowChooseCategoryTagsModal()}
                {this.renderShowEditShareAbilityStatusModal()}
                {this.renderShowHowSharingWorksModal()}
                {this.renderShowShareContentModal()}
                {this.renderTemplateDetailModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        WorkoutTemplateFilterSettings: state.Templates.WorkoutTemplateFilterSettings,
        WorkoutTemplateList: state.Templates.WorkoutTemplateList,
        WorkoutTemplateList_NumPendingShared: state.Templates.WorkoutTemplateList_NumPendingShared,
        WorkoutTemplateList_TotalRecords: state.Templates.WorkoutTemplateList_TotalRecords,
        IsMoreWorkoutTemplates: state.Templates.IsMoreWorkoutTemplates,

        TryingDeleteWorkoutTemplates: state.Templates.TryingDeleteWorkoutTemplates,
        TryingDeleteWorkoutTemplatesError: state.Templates.TryingDeleteWorkoutTemplatesError,
        TryingGetWorkoutTemplateFilters: state.Templates.TryingGetWorkoutTemplateFilters,
        TryingGetWorkoutTemplateFiltersError: state.Templates.TryingGetWorkoutTemplateFiltersError,
        TryingInsertEditLibraryFilterWorkoutTemplates: state.Templates.TryingInsertEditLibraryFilterWorkoutTemplates,
        TryingInsertEditLibraryFilterWorkoutTemplatesError: state.Templates.TryingInsertEditLibraryFilterWorkoutTemplatesError,
        TryingSearchWorkoutTemplates: state.Templates.TryingSearchWorkoutTemplates,
        TryingSearchWorkoutTemplatesError: state.Templates.TryingSearchWorkoutTemplatesError,
        TryingUpdateShareAbilityStatus_WorkoutTemplates: state.Templates.TryingUpdateShareAbilityStatus_WorkoutTemplates,
        TryingUpdateShareAbilityStatus_WorkoutTemplatesError: state.Templates.TryingUpdateShareAbilityStatus_WorkoutTemplatesError,
        TryingUpdateSharedToCoachStatusAll_WorkoutTemplates: state.Templates.TryingUpdateSharedToCoachStatusAll_WorkoutTemplates,
        TryingUpdateSharedToCoachStatusAll_WorkoutTemplatesError: state.Templates.TryingUpdateSharedToCoachStatusAll_WorkoutTemplatesError,
        TryingUpdateSharedToCoachStatus_WorkoutTemplates: state.Templates.TryingUpdateSharedToCoachStatus_WorkoutTemplates,
        TryingUpdateSharedToCoachStatus_WorkoutTemplatesError: state.Templates.TryingUpdateSharedToCoachStatus_WorkoutTemplatesError
    };
};

export default withTranslation()(connect(mapStateToProps, { ChooseWorkoutTemplateTags, DeleteWorkoutTemplateTags, EditWorkoutTemplateTag, GetAllTagsForAddingRemoving_Templates, GetWorkoutTemplateFilterSettings, InsertEditWorkoutTemplateFilter, InsertWorkoutTemplateTags, MoveTemplatesToTrash, RecoverTemplatesFromTrash, RemoveCategory_WorkoutTemplate, ReorderTemplateFilters, SaveDataInProps, SearchWorkoutTemplates_SAYT, SearchWorkoutTemplates, UpdateTemplatesTags, UpdateShareAbilityStatus_WorkoutTemplates, UpdateSharedToCoachStatus_WorkoutTemplates, UpdateSharedToCoachStatusAll_WorkoutTemplates } )(WorkoutTemplateLibrary));