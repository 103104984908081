import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearWorkoutTemplateDetail, DeleteWorkoutTemplates, FreezeWorkoutForPrint, GenerateWorkoutTemplatePDF, GetWorkoutTemplateDetail, SaveDataInProps, UpdateClientAssigning, UpdatePausedSharing_WorkoutTemplates, UpdatePDFLogo, UpdateShareAbilityStatus_WorkoutTemplates, UpdateSharedToCoachStatus_WorkoutTemplates, UploadToPresignedUrl } from '../../Services/Actions';
import history from '../../history';

import Popover from 'react-popover';
import { toast } from 'react-toastify';

import { CircuitContainer, CircuitSeparator, LapContainer, LapDetailsContainer, LapFooterContainer, LapHeaderContainer, LapHeaderContainerLeft, StyledHeaderButtonGroup, TrainerNoteContainer, WorkoutExercisesContainer, WorkoutLapsContainer } from './styles';
import * as coachAddedSession_Styles from './CoachAddedSession_Styles';
import * as PDFStyles from './ExportPDFPreviewStyles';

import AbelModal from '../../Components/Modal';
import ActiveLap from '../../Components/WorkoutLaps/ActiveLap';
import BreakLap from '../../Components/WorkoutLaps/BreakLap';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import ContentSharingDetails from '../../Components/ContentSharingDetails';
import HeaderSAYT from "../../Components/Form/HeaderSAYT";
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import ProfilePhoto from '../../Components/ProfilePhoto';
import Spacer from '../../Components/Spacer';
import { TemplateExercise as TemplateExerciseComponent } from '../../Components/WorkoutExercise';
import Toggle from '../../Components/Form/Toggle';
import UploadProgressComponent from '../../Components/UploadProgress';

import EditShareAbilityStatusModal from '../EditShareAbilityStatusModal';
import ExerciseDetail from '../ExerciseDetail';
import HowSharingWorksModal from '../HowSharingWorksModal';
import Info from '../Info';
import ShareContentModal from '../ShareContentModal';

import Camera from '../../Assets/Icons/Camera.png';
import Copy from '../../Assets/Icons/Copy_White.png';
import CrossRed from '../../Assets/Icons/X_Red.png';
import DeleteX from '../../Assets/Icons/X_White.png';
import DeleteXGray from '../../Assets/Icons/X.png';
import Edit from '../../Assets/Icons/Pencil_White_Filled.png';
import InfoImg from '../../Assets/Icons/Info.png';
import Plus from '../../Assets/Icons/Plus.png';

import { CreateUploadToPresignedUrlPromise, emailValidator, getFileExtension, IsOnlyUnique, validateFile } from '../../Functions';

var i;

const PDFPreviewObj = {
    EmailBody: '',
    EmailsList: [{ EmailAddress: '', IsValid: true }],
    HasExerciseInstructions: 1,
    HasExercisePicture: 1,
    HasMusclesIllustration: 1,
    LogoImageFile: null,
    LogoPictureUrl: null,
    MadeBy: '',
    MadeFor: '',
    MainTitle: '',
    NumEmptySpaces: 0,
    SecondaryTitle: '',
    TrainerNote: '',
}

const CheckDynamicTemplate = WorkoutExercises => {
    return new Promise((resolve, reject) => {
        var PDFPreview_IsDynamic = 0;

        WE_Loop:
        for (var i = 0; i < WorkoutExercises.length; i++) {
            for (var a = 0; a < WorkoutExercises[i].WorkoutExercises.length; a++) {
                if (WorkoutExercises[i].WorkoutExercises[a].DataType !== 'static') {
                    PDFPreview_IsDynamic = 1;
                    break WE_Loop;
                }
            }
        }

        resolve(PDFPreview_IsDynamic);
    })
}

class EmailInputComponent extends React.Component {
    state = {
        EmailAddress: this.props.EmailAddress,
        IsValid: this.props.IsValid
    };

    onBlur = event => {
        var EmailAddress = event.target.value;
        var IsValid = emailValidator(EmailAddress);
        
        this.setState({ EmailAddress, IsValid }, () => {
            this.props.OnBlur({ EmailAddress, EmailAddressIndex: this.props.index, IsValid });
        });
    }

    onChange = event => {
        this.setState({ EmailAddress: event.target.value });
    }

    render() {
        var { t } = this.props;
        var { EmailAddress, IsValid } = this.state;

        return (
            <PDFStyles.EmailContainer className="EmailContainer">
                <PDFStyles.EmailInputContainer className="EmailInputContainer" EmailAddress={EmailAddress} IsValid={IsValid}>
                    <PDFStyles.EmailFormInput
                        NoLabel
                        NoMargin
                        OnBlur={this.onBlur}
                        OnChange={this.onChange}
                        Placeholder={t('fill.in')}
                        Size="medium"
                        Type="text"
                        Value={EmailAddress}
                    />

                    {/* <ButtonGroup Buttons={[{ BackgroundColor: 'white', BackgroundColorHover: 'white-hover', OnClick: this.props.OnAddEmail, Title: t('pick.from.list') }]} /> */}
                </PDFStyles.EmailInputContainer>

                {
                    !!EmailAddress && !IsValid &&
                    <PageText FontColor="red-bittersweet" FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('invalid.email.address')} TextAlign="left" />
                }
            </PDFStyles.EmailContainer>
        );
    }
}

class WorkoutTemplate extends React.Component {
    TimeoutId = null;

    state = {
        ClientAssigning: [],
        ClientAssigning_UserIds: [],
        ClientAssigningId: null,
        ExerciseNotes: {},
        ImageTooLarge: false,
        MaxImageSize: 10000000, // 10MB
        WrongImageType: null,
        Loading: true,
        PDFPreview: { ...PDFPreviewObj },
        PDFPreview_IsDynamic: null,
        PopoverIsOpen: false,
        SaveLogo: false,
        ShowDeleteWorkoutTemplateModal: false,
        ShowEditShareAbilityStatusModal: false,
        ShowEditShareAbilityStatusModalDetails: {},
        ShowExerciseDetailModal: false,
        ShowExerciseDetailPreview: {},
        ShowExportPDFPreview: false,
        ShowHowSharingWorksModal: false,
        ShowShareContentModal: false,
        Updating: false
    };

    componentDidMount() {
        this._isMounted = true;

        this.props.GetWorkoutTemplateDetail({ TemplateId: this.props.TemplateDetail_Preview.TemplateId }).then(() => {
            if (!this.props.TryingGetWorkoutTemplateDetailError) {
                var { AdditionalActivityDetails, TemplateDetails: { ActivityId, ClientAssigning, ClientAssigningId, Duration, IntensityId, Location, StartTime, TemplateName, TrainerNote, WorkoutType }, WorkoutLaps } = this.props.WorkoutTemplate;

                var { AdditionalActivities, Intensities } = AdditionalActivityDetails;
                var ActivityName = '';
                var IntensityName = '';

                for (i = 0; i < AdditionalActivities.length; i++) {
                    if (AdditionalActivities[i].value === ActivityId) {
                        ActivityName = AdditionalActivities[i].label;
                        break;
                    }
                }

                for (i = 0; i < Intensities.length; i++) {
                    if (Intensities[i].IntensityId === IntensityId) {
                        IntensityName = Intensities[i].IntensityName;
                        break;
                    }
                }

                if (WorkoutType === 2) WorkoutLaps.Laps = [].concat(...WorkoutLaps.Laps.map(WL => WL.Laps.map(Lap => ({ ...Lap, LapType: WL.Type }))));

                var { FirstName, LastName, Email, PDFLogo } = this.props.UserDetails;

                var SelectedCheckboxes = new Set();
                SelectedCheckboxes.add('HasExerciseInstructions');
                SelectedCheckboxes.add('HasExercisePicture');
                SelectedCheckboxes.add('HasMusclesIllustration');

                var HowToExport = new Set();
                HowToExport.add('DownloadAsPDF');

                var PDFPreview = {
                    ...this.state.PDFPreview,
                    HowToExport,
                    LogoPictureUrl: PDFLogo,
                    MadeBy: (FirstName && LastName) ? `${FirstName} ${LastName}` : Email,
                    MainTitle: TemplateName,
                    SelectedCheckboxes,
                    TrainerNote
                };

                var ClientAssigning_UserIds = ClientAssigning.map(({ UserId }) => +UserId);

                this.setState({ ActivityName, ClientAssigning, ClientAssigning_UserIds, ClientAssigningId, Duration, IntensityName, Loading: false, Location, PDFPreview, StartTime, TrainerNote, WorkoutLaps });
            } else this.setState({ Loading: false });
        });
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        this.props.ClearWorkoutTemplateDetail();
    }

    onAddEmail = () => {
        var PDFPreview = { ...this.state.PDFPreview };

        PDFPreview.EmailsList = [ ...PDFPreview.EmailsList, { EmailAddress: '', IsValid: true } ]

        this.setState({ PDFPreview });
    }

    onBlurAddEmail = ({ EmailAddress, EmailAddressIndex, IsValid }) => {
        var PDFPreview = { ...this.state.PDFPreview };

        PDFPreview.EmailsList[EmailAddressIndex] = { EmailAddress, IsValid };

        this.setState({ PDFPreview });
    }

    onChangeClientAssigningId_Toggle = ({ event, type }) => {
        var ClientAssigningId = +event.target.checked;

        if (type === 'on_off') {}
        else if (type === 'public') {
            if (+ClientAssigningId === 0) ClientAssigningId = 1;
            else ClientAssigningId = 2;
        }

        var TemplateId = this.props.TemplateDetail_Preview.TemplateId;
        var { ClientAssigning } = this.props.WorkoutTemplate.TemplateDetails;
        
        var Clients = '';
        if (+ClientAssigningId === 2) Clients = ClientAssigning.map(({ UserId }) => UserId).join(',');

        this.props.UpdateClientAssigning({ ClientAssigningId, Clients, TemplateIds: `${TemplateId}` })

        this.setState({ ClientAssigningId });
    }

    onChangeEmailBody = event => {
        var PDFPreview = { ...this.state.PDFPreview };

        PDFPreview.EmailBody = event.target.value;

        this.setState({ PDFPreview });
    }

    onChangeExerciseNote = ({ event, ExerciseId }) => {
        var ExerciseNotes =  { ...this.state.ExerciseNotes };

        ExerciseNotes[`${ExerciseId}`] = event.target.value;

        this.setState({ ExerciseNotes });
    }

    onChangeNumEmptySpaces = Amount => {
        var PDFPreview = { ...this.state.PDFPreview };

        if (
            (PDFPreview.NumEmptySpaces > 0 && PDFPreview.NumEmptySpaces < 7) ||
            (PDFPreview.NumEmptySpaces <= 0 && Amount > 0) ||
            (PDFPreview.NumEmptySpaces >= 7 && Amount < 0)
        ) {
            PDFPreview.NumEmptySpaces += Amount;
    
            this.setState({ PDFPreview });
        }
    }

    onChangePDFPreview_Checkmark = label => {
        var PDFPreview = { ...this.state.PDFPreview };

        if (PDFPreview.SelectedCheckboxes.has(label)) PDFPreview.SelectedCheckboxes.delete(label);
        else PDFPreview.SelectedCheckboxes.add(label);

        this.setState({ PDFPreview });
    }

    onChangePDFPreview_Checkmark_HowToExport = label => {
        var PDFPreview = { ...this.state.PDFPreview };

        if (PDFPreview.HowToExport.has(label)) PDFPreview.HowToExport.delete(label);
        else PDFPreview.HowToExport.add(label);

        this.setState({ PDFPreview });
    }

    onChangePDFPreviewInfo = ({ event, InputName }) => {
        var PDFPreview = { ...this.state.PDFPreview };
        PDFPreview[InputName] = event.target.value;

        this.setState({ PDFPreview });
    }

    onClickAssignClients = () => {
        var { OnAssignClients, WorkoutTemplate: { TemplateId, TemplateDetails: { TemplateName, Units } } } = this.props;

        this.props.OnHide();

        if (OnAssignClients) OnAssignClients(this.props.WorkoutTemplate);
        else this.props.SaveDataInProps({ SelectedTemplate: { TemplateId, TemplateName, Units } }).then(() => history.push('/add-workout-template'));
    }

    onClickEditTemplate = () => {
        this.props.OnHide();
        history.push(`/library/workout-template/${this.props.TemplateDetail_Preview.TemplateId}`);
    }

    onCopyWorkoutToTemplate = () => {
        var TemplateId = this.props.TemplateDetail_Preview.TemplateId
        var { AdditionalActivityDetails, TemplateDetails, TemplateDetails: { Injuries, RepRange, TrainingFocus }, WorkoutExercises, WorkoutLaps } = this.props.WorkoutTemplate;

        this.props.SaveDataInProps({ WorkoutToTemplateDetails: { FromTemplate: true, AdditionalActivityDetails, CopiedTemplateId: TemplateId, Injuries, RepRange, TrainingFocus, WorkoutDetails: TemplateDetails, WorkoutExercises, WorkoutLaps } }).then(() => history.push('/library/workout-template'));
    }

    onCopyWorkoutToWorkout = () => {
        var TemplateId = this.props.TemplateDetail_Preview.TemplateId
        var { AdditionalActivityDetails, TemplateDetails, WorkoutExercises, WorkoutLaps } = this.props.WorkoutTemplate;

        this.props.SaveDataInProps({ TemplateToWorkoutDetails: { FromTemplate: true, AdditionalActivityDetails, CopiedTemplateId: TemplateId, WorkoutDetails: TemplateDetails, WorkoutExercises, WorkoutLaps } }).then(() => history.push('/add-workout'));
    }

    onDeleteImage = () => {
        this.setState({
            ImageTooLarge: false,
            WrongImageType: false,
            SaveLogo: false,
            PDFPreview: {
                ...this.state.PDFPreview,
                LogoPictureUrl: null,
                LogoImageFile: null,
            }
        });
    }

    onDeleteWorkoutTemplate = () => {
        var { TemplateId } = this.props.TemplateDetail_Preview;

        this.props.DeleteWorkoutTemplates({ TemplateId }).then(() => {
            if (!this.props.TryingDeleteWorkoutTemplatesError) {
                this.onToggleDeleteWorkoutTemplateModal(false);
                this.props.OnHide(TemplateId);
            }
        });
    }

    onExportToPdf = () => {
        var { TemplateId, TemplateName } = this.props.TemplateDetail_Preview;
        var { EmailBody, EmailsList, HowToExport, LogoImageFile, LogoPictureUrl, MadeBy, MadeFor, MainTitle, NumEmptySpaces, SecondaryTitle, SelectedCheckboxes, TrainerNote } = this.state.PDFPreview;
        var { ExerciseNotes, SaveLogo } = this.state;

        var HasExerciseInstructions = +!!SelectedCheckboxes.has('HasExerciseInstructions');
        var HasExercisePicture = +!!SelectedCheckboxes.has('HasExercisePicture');
        var HasMusclesIllustration = +!!SelectedCheckboxes.has('HasMusclesIllustration');

        var WorkoutTemplateId = TemplateId;

        var StringifiedExerciseNotes = JSON.stringify(ExerciseNotes);

        var SendToEmails = '';

        if (HowToExport.has('SendAsEmail')) SendToEmails = EmailsList.filter(EL => !!EL.IsValid).map(({ EmailAddress }) => EmailAddress).filter(IsOnlyUnique).join(',');

        if (HowToExport.has('DownloadAsPDF') && !HowToExport.has('SendAsEmail')) HowToExport = 'download';
        else if (HowToExport.has('DownloadAsPDF') && HowToExport.has('SendAsEmail')) HowToExport = 'both';
        else if (!HowToExport.has('DownloadAsPDF') && HowToExport.has('SendAsEmail')) HowToExport = 'send';

        try {
            // Upload image for logo
                if (LogoImageFile) {
                    this.props.FreezeWorkoutForPrint({ FromTemplate: 1, IsFrozen: 1, IsTemporary: +!SaveLogo, PDFLogo: { ContentType: LogoImageFile.type, FileName: LogoImageFile.name }, WorkoutId: 1 }).then(({ PresignedFormData_Image }) => {
                        if (LogoImageFile && PresignedFormData_Image) {
                            var Promises = [];
                            var UploadFunction = this.props.UploadToPresignedUrl;

                            const { url, fields: { Key } } = PresignedFormData_Image
                            const PDFLogo = url.concat('/', Key);
        
                            // Update UserDetails With New PDF Logo
                                if (!!SaveLogo) this.props.UpdatePDFLogo(PDFLogo);
        
                            if (PresignedFormData_Image) Promises.push(CreateUploadToPresignedUrlPromise({ ContentType: 'PDFLogo', File: LogoImageFile, PresignedFormData: PresignedFormData_Image, UploadFunction }));
                
                            Promise.all(Promises).then(() => {
                                document.getElementsByClassName('modal-content')[0].scrollIntoView();
                                this.setState({ ShowExportPDFPreview: false }, () => {
                                    this.props.GenerateWorkoutTemplatePDF({ DownloadName: MainTitle || TemplateName, EmailBody, ExerciseNotes: StringifiedExerciseNotes, HasExerciseInstructions, HasExercisePicture, HasMusclesIllustration, HowToExport, LogoImageUrl: PDFLogo, MadeBy, MadeFor, MainTitle, NumEmptySpaces, SecondaryTitle, SendToEmails, TrainerNote, WorkoutTemplateId }).then(() => {
                                        if (HowToExport === 'both' || HowToExport === 'send') this.onShowEmailSentToast();
                                    })
                                });
                            });
                        } else {
                            document.getElementsByClassName('modal-content')[0].scrollIntoView();
                            this.setState({ ShowExportPDFPreview: false }, () => {
                                this.props.GenerateWorkoutTemplatePDF({ DownloadName: MainTitle || TemplateName, EmailBody, ExerciseNotes: StringifiedExerciseNotes, HasExerciseInstructions, HasExercisePicture, HasMusclesIllustration, HowToExport, LogoImageUrl: LogoPictureUrl, MadeBy, MadeFor, MainTitle, NumEmptySpaces, SecondaryTitle, SendToEmails, TrainerNote, WorkoutTemplateId }).then(() => {
                                    if (HowToExport === 'both' || HowToExport === 'send') this.onShowEmailSentToast();
                                })
                            });
                        }
                    })
                } else {
                    document.getElementsByClassName('modal-content')[0].scrollIntoView();
                    this.setState({ ShowExportPDFPreview: false }, () => {
                        this.props.GenerateWorkoutTemplatePDF({ DownloadName: MainTitle || TemplateName, EmailBody, ExerciseNotes: StringifiedExerciseNotes, HasExerciseInstructions, HasExercisePicture, HasMusclesIllustration, HowToExport, LogoImageUrl: LogoPictureUrl, MadeBy, MadeFor, MainTitle, NumEmptySpaces, SecondaryTitle, SendToEmails, TrainerNote, WorkoutTemplateId }).then(() => {
                            if (HowToExport === 'both' || HowToExport === 'send') this.onShowEmailSentToast();
                        })
                    });
                }
        } catch (e) {
            console.log('Error ========>>>', e)
        }
    }

    onPauseSharing = IsPaused => {
        this.props.UpdatePausedSharing_WorkoutTemplates({ IsPaused, TemplateId: this.props.TemplateDetail_Preview.TemplateId })
    }

    onSelectAddClient = ({ UserDetails }) => {
        if (this.state.ClientAssigning_UserIds.indexOf(+UserDetails.UserId) !== -1) {
            toast.success(this.props.t('_user_already_added'), {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            var ClientAssigning = [ ...this.state.ClientAssigning ];
            var ClientAssigning_UserIds = [ ...this.state.ClientAssigning_UserIds ];
            var ClientAssigningId = this.state.ClientAssigningId;

            ClientAssigning.push(UserDetails);
            ClientAssigning_UserIds.push(+UserDetails.UserId);

            var TemplateId = this.props.TemplateDetail_Preview.TemplateId;

            this.props.UpdateClientAssigning({ ClientAssigningId, Clients: ClientAssigning_UserIds.join(','), TemplateIds: `${TemplateId}` });
            this.setState({ ClientAssigning, ClientAssigning_UserIds });
        }
    }

    onSelectRemoveClient = ({ UserId }) => {
        var ClientAssigningId = this.state.ClientAssigningId;

        var UserId_Index = this.state.ClientAssigning_UserIds.indexOf(+UserId);
        
        var ClientAssigning = [ ...this.state.ClientAssigning.slice(0, UserId_Index), ...this.state.ClientAssigning.slice(UserId_Index + 1) ];
        var ClientAssigning_UserIds = [ ...this.state.ClientAssigning_UserIds.slice(0, UserId_Index), ...this.state.ClientAssigning_UserIds.slice(UserId_Index + 1) ];

        var TemplateId = this.props.TemplateDetail_Preview.TemplateId;

        this.props.UpdateClientAssigning({ ClientAssigningId, Clients: ClientAssigning_UserIds.join(','), TemplateIds: `${TemplateId}` });
        this.setState({ ClientAssigning, ClientAssigning_UserIds });
    }

    onSelectLogo = event => {
        if (event.target.files.length) {
            var LogoImageFile = event.target.files[0];
            var FileExtension = getFileExtension(LogoImageFile.name);
        
            if (validateFile({ FileExtension, FileType: 'Images' })) {
                if (LogoImageFile.size <= this.state.MaxImageSize) {
                this.setState({ PDFPreview: { ...this.state.PDFPreview, LogoImageFile, LogoPictureUrl: URL.createObjectURL(LogoImageFile) }, ImageTooLarge: false, WrongImageType: false });
                } else {
                this.setState({ ImageTooLarge: true, WrongImageType: false });
                }
            } else this.setState({ ImageTooLarge: false, WrongImageType: true });
        }
    }

    onShareAgain = Email => {
        this.props.UpdateSharedToCoachStatus_WorkoutTemplates({ Emails: [ Email ], NewStatus: 'Pending', TemplateId: this.props.TemplateDetail_Preview.TemplateId });
    }

    onShareContentToEmails = ({ Emails, ContentIds }) => {
        this.props.UpdateSharedToCoachStatus_WorkoutTemplates({ Emails, NewStatus: 'Pending', TemplateId: this.props.TemplateDetail_Preview.TemplateId, TemplateIds: ContentIds }).then(() => {
            this.onToggleShowShareContentModal(false);
        });
    }

    onShowEmailSentToast = () => {
        toast.success(this.props.t('email.success.sent'), {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    onToggleDeleteWorkoutTemplateModal = ShowDeleteWorkoutTemplateModal => {
        this.setState({ ShowDeleteWorkoutTemplateModal });
    }

    onTogglePopover = () => {
        this.setState({ PopoverIsOpen: !this.state.PopoverIsOpen }, () => {
            if (!this.state.PopoverIsOpen && !!this.TimeoutId) clearTimeout(this.TimeoutId);
        });
    }
    
    onToggleSaveLogo = () => {
        this.setState({ SaveLogo: !this.state.SaveLogo })
    }

    onToggleShowEditShareAbilityStatusModal = ({ ContentId = null, ContentIndex = null, ShowEditShareAbilityStatusModal, CurrentStatus = null }) => {
        this.setState({ ShowEditShareAbilityStatusModal, ShowEditShareAbilityStatusModalDetails: { ContentId, ContentIndex, CurrentStatus } });
    }

    onToggleShowExerciseDetailModal = (ShowExerciseDetailModal, Exercise) => {
        this.setState({ ShowExerciseDetailModal, ShowExerciseDetailPreview: Exercise || {} });
    }

    onToggleShowExportPDFPreview = async ShowExportPDFPreview  => {
        if (!!ShowExportPDFPreview && this.state.PDFPreview_IsDynamic === null) {
            var PDFPreview_IsDynamic = await CheckDynamicTemplate(this.props.WorkoutTemplate.WorkoutExercises);

            this.setState({ ShowExportPDFPreview, PDFPreview_IsDynamic });
        }
        else {
            document.getElementsByClassName('modal-content')[0].scrollIntoView();

            this.setState({ ShowExportPDFPreview });
        }
    }

    onToggleShowHowSharingWorksModal = ShowHowSharingWorksModal => {
        this.setState({ ShowHowSharingWorksModal });
    }

    onToggleShowShareContentModal = ShowShareContentModal => {
        this.setState({ ShowShareContentModal });
    }

    onUnshareContent = Email => {
        this.props.UpdateSharedToCoachStatus_WorkoutTemplates({ Emails: [ Email ], NewStatus: 'Unshare', TemplateId: this.props.TemplateDetail_Preview.TemplateId });
    }

    onUpdateShareAbilityStatus = ({ ContentId, NewStatus, NewStatusName }) => {
        if (+NewStatus !== +this.props.WorkoutTemplate.TemplateDetails.SharingDetails.ShareAbilityStatusId) return this.props.UpdateShareAbilityStatus_WorkoutTemplates({ NewStatus, NewStatusName, TemplateId: ContentId }).then(() => this.props.OnUpdateMultiSelectList({ ContentId, NewStatus, NewStatusName }))

        return;
    }

    onUpdateSharedToCoachStatus = ({ NewStatus, PersonalResponse }) => {
        this.setState({ Updating: true });
        this.props.UpdateSharedToCoachStatus_WorkoutTemplates({ Emails: [ this.props.UserDetails.Email ], NewStatus, PersonalResponse, TemplateId: this.props.TemplateDetail_Preview.TemplateId }).then(() => {
            this.setState({ Updating: false }, () => {
                if (NewStatus === 'reject' || NewStatus === 'delete') this.props.OnHide();

                this.props.OnLoadTemplates();
            });
        });
    }

    renderAdditionalActivity = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { Duration, IntensityName, Location, StartTime, WorkoutType } = this.state;

        var Buttons = [];

        if (+WorkoutType === 3) {
            var DurationButtonText = <MultiText
                Texts={[
                    { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('length')} -` },
                    { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${Duration} ${t('min_str').toLowerCase()}` }
                ]}
            />

            Buttons.push({ BackgroundColor: 'white-concrete', Title: DurationButtonText });
        }

        if (IntensityName) {
            var IntensityButtonText = <MultiText
                Texts={[
                    { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('WorkoutPlan_Intensity')} -` },
                    { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${IntensityName}` }
                ]}
            />

            Buttons.push({ BackgroundColor: 'white-concrete', Title: IntensityButtonText });
        }

        if (StartTime) {
            var StartTimeButtonText = <MultiText
                Texts={[
                    { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('start_time')} -` },
                    { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${StartTime}` }
                ]}
            />

            Buttons.push({ BackgroundColor: 'white-concrete', Title: StartTimeButtonText });
        }

        if (Location) {
            var LocationButtonText = <MultiText
                Texts={[
                    { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('location')} -` },
                    { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${Location}` }
                ]}
            />

            Buttons.push({ BackgroundColor: 'white-concrete', Title: LocationButtonText });
        }

        if (!Buttons.length) return null;

        return (
            <>
                <ButtonGroup
                    Buttons={Buttons}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />

                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'medium' : 'small'} />
            </>
        );
    }

    renderButtons = () => {
        if (this.props.TryingGetWorkoutTemplateDetail) return null;

        var { t } = this.props;
        var { Device, WorkoutTemplate } = this.props;

        var Buttons = [];
        if (!!WorkoutTemplate && !!WorkoutTemplate.TemplateDetails && !!+WorkoutTemplate.TemplateDetails.IsOwner) {
            Buttons.push({ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: Edit, IconPosition: 'left', OnClick: this.onClickEditTemplate, Title: t('edit_with_first_char_uppercase') });
            Buttons.push({ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: DeleteX, IconPosition: 'left', OnClick: () => this.onToggleDeleteWorkoutTemplateModal(true), Title: t('delete_with_first_char_uppercase') });
        }
        if (!!WorkoutTemplate && !!WorkoutTemplate.TemplateDetails && !!WorkoutTemplate.TemplateDetails.SharingDetails && WorkoutTemplate.TemplateDetails.SharingDetails.SharedStatus === 1) {
            Buttons.push({ BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: Copy, IconPosition: 'left', OnClick: this.onCopyWorkoutToTemplate, Title: t('copy_template_to_template') });
            Buttons.push({ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: this.onCopyWorkoutToWorkout, Title: t('copy_template_to_workout') });
        }

        if (+WorkoutTemplate.TemplateDetails.WorkoutType === 1) Buttons.push({ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onToggleShowExportPDFPreview(true), Title: t('export.pdf') });

        if (!Buttons.length) return null;

        return (
            <>
                <ButtonGroup
                    Buttons={Buttons}
                    ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />
            </>
        );
    }

    renderCoachAddedSession = () => {
        var { t } = this.props;
        var { IsOwner } = this.props.WorkoutTemplate.TemplateDetails;
        var { ClientAssigning, ClientAssigningId } = this.state;

        if (!IsOwner) {
            return (
                <coachAddedSession_Styles.CoachAddedSessionContainer className="CoachAddedSessionContainer">
                    <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('_add_sessions_for_clients_from_templates_title')} TextAlign="left" />

                    <Spacer Size="small" />

                    <PageText FontFamily="medium-italic" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('_add_sessions_for_clients_from_templates_must_be_owner')} TextAlign="left" />
                </coachAddedSession_Styles.CoachAddedSessionContainer>
            );
        }

        return (
            <coachAddedSession_Styles.CoachAddedSessionContainer className="CoachAddedSessionContainer">
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('_add_sessions_for_clients_from_templates_title')} TextAlign="left" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('_add_sessions_for_clients_from_templates_on_off')} TextAlign="left" />

                <Toggle
                    Checked={+ClientAssigningId !== 0}
                    OffLabel={t('_no')}
                    OnChange={event => this.onChangeClientAssigningId_Toggle({ event, type: 'on_off' })}
                    OnLabel={t('_yes')}
                />

                <Spacer Size="small" />

                {
                    +ClientAssigningId !== 0 &&
                    <>
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('_add_sessions_for_clients_from_templates_share_all_or_custom')} TextAlign="left" />

                        <Toggle
                            Checked={+ClientAssigningId === 2}
                            OffLabel={t('_all')}
                            OnChange={event => this.onChangeClientAssigningId_Toggle({ event, type: 'public' })}
                            OnLabel={t('_custom')}
                        />
                    </>
                }

                {
                    +ClientAssigningId === 2 &&
                    <>
                        <Spacer Size="small" />

                        <HeaderSAYT
                            OnClickSearchResult={this.onSelectAddClient}
                        />

                        <Spacer Size="small" />

                        <coachAddedSession_Styles.AddedClientsContainer>
                            {
                                ClientAssigning.map(({ DisplayName, ProfilePhotoUrl, Sex, UserId }) => {
                                    return (
                                        <coachAddedSession_Styles.Container1 className="Container1" key={UserId}>
                                            <coachAddedSession_Styles.LeftContainer className="LeftContainer">
                                                <div className="HeaderPictureContainer">
                                                    <coachAddedSession_Styles.ProfilePhotoContainer className="ProfilePhotoContainer">
                                                        <ProfilePhoto Sex={Sex || 'Male'} Size="small-1" Source={ProfilePhotoUrl || ''} />
                                                    </coachAddedSession_Styles.ProfilePhotoContainer>
                                                </div>
                    
                                                <div>
                                                    <coachAddedSession_Styles.StyledEllipsisText FontColorHover="blue-abel" FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={DisplayName} TextAlign="left" />
                                                </div>
                                            </coachAddedSession_Styles.LeftContainer>

                                            <coachAddedSession_Styles.RightContainer className="RightContainer">
                                                <ButtonGroup Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Icon: DeleteXGray, IconOnly: true, IconSize: { Height: '15px', Width: '15px' }, OnClick: () => this.onSelectRemoveClient({ UserId }) }]} />
                                            </coachAddedSession_Styles.RightContainer>
                                        </coachAddedSession_Styles.Container1>
                                    );
                                })
                            }
                        </coachAddedSession_Styles.AddedClientsContainer>
                    </>
                }
            </coachAddedSession_Styles.CoachAddedSessionContainer>
        );
    }

    renderCircuitSeparator = ({ CircuitType }) => {
        var { t } = this.props;

        return (
            <CircuitSeparator BackgroundColor={CircuitType === 'Circuit' ? 'turquoise-dark' : 'blue-royal'}>
                <PageText FontColor="white" FontFamily="bold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t(`Workout_Set_Type_${CircuitType}`)} TextAlign="center" />
            </CircuitSeparator>
        );
    }

    renderDeleteWorkoutTemplateModal = () => {
        var { t } = this.props;
        var { ShowDeleteWorkoutTemplateModal } = this.state;

        if (ShowDeleteWorkoutTemplateModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteWorkoutTemplate}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    Information={t('template_delete_warning')}
                    Loading={this.props.TryingDeleteWorkoutTemplates}
                    OnHide={() => this.onToggleDeleteWorkoutTemplateModal(false)}
                    Show={ShowDeleteWorkoutTemplateModal}
                />
            );
        }
    }

    renderExerciseDetailModal = () => {
        var { ShowExerciseDetailModal, ShowExerciseDetailPreview } = this.state;

        if (ShowExerciseDetailModal) {
            return (
                <ExerciseDetail
                    ExerciseDetail_Preview={ShowExerciseDetailPreview}
                    OnHide={() => this.onToggleShowExerciseDetailModal(false)}
                    Show={ShowExerciseDetailModal}
                />
            );
        }
    }

    renderExportPDFPreview = () => {
        var { t } = this.props;
        var { Device, TryingFreezeWorkout, UploadProgress, WorkoutTemplate: { WorkoutExercises } } = this.props;
        var { ExerciseNotes, ImageTooLarge, PDFPreview_IsDynamic, PopoverIsOpen, WrongImageType } = this.state;
        var { EmailBody, EmailsList, HowToExport, LogoPictureUrl, MadeBy, MadeFor, MainTitle, NumEmptySpaces, SecondaryTitle, SelectedCheckboxes, TrainerNote } = this.state.PDFPreview;

        const popoverProps = {
            isOpen: PopoverIsOpen,
            preferPlace: 'above',
            onOuterAction: this.onTogglePopover,
            body: this.renderPopoverContent(),
            tipSize: 0.01
        }

        const UploadButtons = [{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: Camera, IconPosition: 'left', IsInput: true, InputProps: { accept: "image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg", id: "UploadLogo", type: "file", onChange: this.onSelectLogo }, Title: t('Upload') }];

        return (
            <>
                {TryingFreezeWorkout && <Loading/>}

                {
                    UploadProgress.PDFLogo && Object.entries(UploadProgress.PDFLogo).length > 0 &&
                    <>
                        <Spacer Size="medium" />

                        <UploadProgressComponent UploadingItems={UploadProgress.PDFLogo} />
                    </>
                }

                <PDFStyles.ExportPDFPreviewContainer className="ExportPDFPreviewContainer">
                    <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('preview')} TextAlign="left" />

                    <Spacer Size="medium" />

                    {
                        !!+PDFPreview_IsDynamic &&
                        <>
                            <PDFStyles.DynamicTemplateWarning FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('dynamic.export.error')} TextAlign="left" />

                            <Spacer Size="medium" />
                        </>
                    }

                    <PDFStyles.FormContainer className="FormContainer">
                        <PDFStyles.FormContainerFirst className="FormContainerFirst">
                            <PDFStyles.FormInputContainer className="FormInputContainer">
                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('main.title')} TextAlign="left" />
                                
                                <PDFStyles.FormInput
                                    NoLabel
                                    NoMargin
                                    OnChange={event => this.onChangePDFPreviewInfo({ event, InputName: 'MainTitle' })}
                                    Placeholder={t('fill.in')}
                                    Size="medium"
                                    Type="text"
                                    Value={MainTitle}
                                />
                            </PDFStyles.FormInputContainer>
                            <PDFStyles.FormInputContainer className="FormInputContainer">
                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('secondary.title')} TextAlign="left" />
                                
                                <PDFStyles.FormInput
                                    NoLabel
                                    NoMargin
                                    OnChange={event => this.onChangePDFPreviewInfo({ event, InputName: 'SecondaryTitle' })}
                                    Placeholder={t('fill.in')}
                                    Size="medium"
                                    Type="text"
                                    Value={SecondaryTitle}
                                />
                            </PDFStyles.FormInputContainer>
                            <PDFStyles.FormInputContainer className="FormInputContainer">
                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('note.to.session')} TextAlign="left" />
                                
                                <PDFStyles.FormTextArea
                                    OnBlur={event => this.onChangePDFPreviewInfo({ event, InputName: 'TrainerNote' })}
                                    NumRows={4}
                                    Placeholder={t('WorkoutPlan_Trainer_note_to_client_placeholder')}
                                    Value={TrainerNote}
                                />
                            </PDFStyles.FormInputContainer>
                        </PDFStyles.FormContainerFirst>
                        <PDFStyles.FormContainerMiddle className="FormContainerMiddle">
                            <PDFStyles.FormInputContainer>
                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('upload_picture_or_logo')} TextAlign="left" />

                                {
                                    !LogoPictureUrl &&
                                    <PDFStyles.UploadButtonsRow>
                                        <PDFStyles.UploadButtonsContainer
                                            Buttons={UploadButtons}
                                            ButtonWidth="fit-content"
                                            ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                                            NotTouching
                                        />
                                    </PDFStyles.UploadButtonsRow>
                                }

                                {
                                    LogoPictureUrl &&
                                    <>
                                        {this.renderLogoPreview()}

                                        <Spacer Size="extra-extra-small" />

                                        {
                                            (ImageTooLarge || WrongImageType) &&
                                            <>
                                                <Spacer Size="small" />

                                                <PageText
                                                    FontColor="red-bittersweet"
                                                    FontFamily="semibold-italic"
                                                    FontSize="medium-1"
                                                    Text={t(ImageTooLarge ? 'media_wrongsize' : 'media_wrongtype', { AllowedTypes: '.jpg, .png, .jpeg', MaxSize: '10MB', Type: 'Image' })}
                                                    TextAlign="left"
                                                />
                                            </>
                                        }
                                    </>
                                }

                                <PDFStyles.StyledMultiText
                                    TextAlign="left"
                                    Texts={[
                                        { FontFamily: 'semibold', FontSize: 'small', Text: `${t('max_upload_size')}:` },
                                        { FontFamily: 'medium', FontSize: 'small', Text: '10MB' },
                                    ]}
                                />
                            </PDFStyles.FormInputContainer>

                            <PDFStyles.FormInputContainer className="FormInputContainer">
                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('made.by')} TextAlign="left" />
                                
                                <PDFStyles.FormInput
                                    NoLabel
                                    NoMargin
                                    OnChange={event => this.onChangePDFPreviewInfo({ event, InputName: 'MadeBy' })}
                                    Placeholder={t('fill.in')}
                                    Size="medium"
                                    Type="text"
                                    Value={MadeBy}
                                />
                            </PDFStyles.FormInputContainer>
                            <PDFStyles.FormInputContainer className="FormInputContainer">
                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('made.for')} TextAlign="left" />
                                
                                <PDFStyles.FormInput
                                    NoLabel
                                    NoMargin
                                    OnChange={event => this.onChangePDFPreviewInfo({ event, InputName: 'MadeFor' })}
                                    Placeholder={t('fill.in')}
                                    Size="medium"
                                    Type="text"
                                    Value={MadeFor}
                                />
                            </PDFStyles.FormInputContainer>
                        </PDFStyles.FormContainerMiddle>
                        <PDFStyles.FormContainerLast className="FormContainerLast">
                            <PDFStyles.MultipleChoiceContainer className="MultipleChoiceContainer">
                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('PDF.include')} TextAlign="left" />

                                <Spacer Size="extra-extra-small-2" />

                                <PDFStyles.DateTimeUseAllDaysContainer>
                                    <PDFStyles.DateTimeUseAllDays onClick={() => this.onChangePDFPreview_Checkmark('HasExercisePicture')} Selected={SelectedCheckboxes.has('HasExercisePicture')} />
                                    
                                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" OnClick={() => this.onChangePDFPreview_Checkmark('HasExercisePicture')} Text={t('exercise.picture')} TextAlign="left" />
                                </PDFStyles.DateTimeUseAllDaysContainer>

                                <PDFStyles.DateTimeUseAllDaysContainer>
                                    <PDFStyles.DateTimeUseAllDays onClick={() => this.onChangePDFPreview_Checkmark('HasExerciseInstructions')} Selected={SelectedCheckboxes.has('HasExerciseInstructions')} />
                                    
                                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" OnClick={() => this.onChangePDFPreview_Checkmark('HasExerciseInstructions')} Text={t('exercise.instructions')} TextAlign="left" />
                                </PDFStyles.DateTimeUseAllDaysContainer>

                                <PDFStyles.DateTimeUseAllDaysContainer>
                                    <PDFStyles.DateTimeUseAllDays onClick={() => this.onChangePDFPreview_Checkmark('HasMusclesIllustration')} Selected={SelectedCheckboxes.has('HasMusclesIllustration')} />
                                    
                                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" OnClick={() => this.onChangePDFPreview_Checkmark('HasMusclesIllustration')} Text={t('muscle.illustration')} TextAlign="left" />
                                </PDFStyles.DateTimeUseAllDaysContainer>

                                <Spacer Size="extra-extra-small-2" />

                                <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" Text={t('results.export')} TextAlign="left" />
                            </PDFStyles.MultipleChoiceContainer>
                            <PDFStyles.NumEmptySpacesContainer className="NumEmptySpacesContainer">
                                <PDFStyles.NumEmptySpacesTitleContainer className="NumEmptySpacesTitleContainer">
                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" Text={t('empty.spaces')} TextAlign="left" />

                                    <Popover {...popoverProps}>
                                        <PDFStyles.MaxPercentInfoIcon ImageSrc={InfoImg} OnMouseEnter={this.onTogglePopover} OnMouseLeave={this.onTogglePopover} />
                                    </Popover>
                                </PDFStyles.NumEmptySpacesTitleContainer>

                                <PDFStyles.PlusMinusInputContainer className="PlusMinusInputContainer">
                                    <PDFStyles.MinusContainer className="MinusContainer" disabled={NumEmptySpaces <=0}  onClick={() => this.onChangeNumEmptySpaces(-1)}>
                                        <PageText FontFamily="bold" FontSize="medium-2" JustifyContent="center" NoMargin Text="-" TextAlign="center" />
                                    </PDFStyles.MinusContainer>
                                    <PDFStyles.PlusMinusNumberContainer className="PlusMinusNumberContainer">
                                        <PageText FontFamily="bold" FontSize="medium-2" JustifyContent="center" NoMargin Text={`${NumEmptySpaces}`} TextAlign="center" />
                                    </PDFStyles.PlusMinusNumberContainer>
                                    <PDFStyles.PlusContainer className="PlusContainer" disabled={NumEmptySpaces >= 7} onClick={() => this.onChangeNumEmptySpaces(1)}>
                                        <PageText FontFamily="bold" FontSize="medium-2" JustifyContent="center" NoMargin Text="+" TextAlign="center" />
                                    </PDFStyles.PlusContainer>
                                </PDFStyles.PlusMinusInputContainer>

                                <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('from.0_7')} TextAlign="left" />
                            </PDFStyles.NumEmptySpacesContainer>
                        </PDFStyles.FormContainerLast>
                    </PDFStyles.FormContainer>

                    <PDFStyles.StyledHR />

                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('the.session')} TextAlign="left" />

                    <Spacer Size="medium" />

                    <PDFStyles.ExercisesContainer className="ExercisesContainer">
                        {
                            WorkoutExercises.map(WorkoutExercise => {
                                return WorkoutExercise.WorkoutExercises.map(CircuitWorkoutExercise => {
                                    return (
                                        <PDFStyles.ExerciseContainer key={CircuitWorkoutExercise.ExerciseId} className="ExerciseContainer">
                                            <PDFStyles.ExerciseHeaderContainer className="ExerciseHeaderContainer">
                                                <PDFStyles.ExerciseImage
                                                    ImageSrc={CircuitWorkoutExercise.ExerciseImage}
                                                    SelfLoading
                                                />

                                                <PDFStyles.ExerciseName FontFamily="bold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={CircuitWorkoutExercise.ExerciseName} TextAlign="left" />
                                            </PDFStyles.ExerciseHeaderContainer>

                                            <Spacer Size="extra-small" />

                                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('add.note.to.exercise')} TextAlign="left" />

                                            <Spacer Size="extra-extra-small-2" />

                                            <PDFStyles.ExerciseNoteTextArea
                                                OnBlur={event => this.onChangeExerciseNote({ event, ExerciseId: CircuitWorkoutExercise.ExerciseId })}
                                                NumRows={4}
                                                Placeholder={t('fill.in')}
                                                Value={ExerciseNotes[`${CircuitWorkoutExercise.ExerciseId}`] || ''}
                                            />
                                        </PDFStyles.ExerciseContainer>
                                    );
                                })
                            })
                        }
                    </PDFStyles.ExercisesContainer>

                    <PDFStyles.StyledHR />

                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('how.export')} TextAlign="left" />

                    <Spacer Size="extra-small" />

                    <PDFStyles.DateTimeUseAllDaysContainer>
                        <PDFStyles.DateTimeUseAllDays onClick={() => this.onChangePDFPreview_Checkmark_HowToExport('DownloadAsPDF')} Selected={HowToExport.has('DownloadAsPDF')} />
                        
                        <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" OnClick={() => this.onChangePDFPreview_Checkmark_HowToExport('DownloadAsPDF')} Text={t('download.pdf')} TextAlign="left" />
                    </PDFStyles.DateTimeUseAllDaysContainer>

                    <PDFStyles.DateTimeUseAllDaysContainer>
                        <PDFStyles.DateTimeUseAllDays onClick={() => this.onChangePDFPreview_Checkmark_HowToExport('SendAsEmail')} Selected={HowToExport.has('SendAsEmail')} />
                        
                        <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" OnClick={() => this.onChangePDFPreview_Checkmark_HowToExport('SendAsEmail')} Text={t('send_email_to_client')} TextAlign="left" />
                    </PDFStyles.DateTimeUseAllDaysContainer>

                    <Spacer Size="extra-small" />

                    {
                        !!HowToExport.has('SendAsEmail') &&
                        <>
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('email.of.clients')} TextAlign="left" />

                            <Spacer Size="extra-extra-small-2" />

                            <PDFStyles.EmailsListParentContainer className="EmailsListParentContainer">
                                {EmailsList.map(({ EmailAddress, IsValid }, EAIndex) => <EmailInputComponent t={t} key={EAIndex} index={EAIndex} EmailAddress={EmailAddress} IsValid={IsValid} OnBlur={this.onBlurAddEmail} />)}
                                
                                <PDFStyles.StyledAddEmailButtonGroup Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Icon: Plus, IconOnly: true, IconPosition: 'left', OnClick: this.onAddEmail }]} />
                            </PDFStyles.EmailsListParentContainer>

                            <Spacer Size="medium" />

                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('text.in.email')} TextAlign="left" />

                            <Spacer Size="extra-extra-small-2" />

                            <PDFStyles.EmailBodyTextArea
                                OnBlur={this.onChangeEmailBody}
                                NumRows={4}
                                Placeholder={t('fill.in')}
                                Value={EmailBody}
                            />
                        </>
                    }

                </PDFStyles.ExportPDFPreviewContainer>
            </>
        );
    }

    renderLapDetails = ({ Active, ActiveUnits, BreakTime, LapType, Rest, Speed, SpeedUnits, WorkoutLapId }) => {
        var { ActiveType, DataType } = this.state.WorkoutLaps;

        if (DataType === 'dynamic') SpeedUnits = '%';

        if (LapType === 'Active') {
            return (
                <ActiveLap
                    Active={Active}
                    ActiveUnits={ActiveUnits}
                    ActiveType={ActiveType}
                    DataType={DataType}
                    ReadOnly
                    Rest={Rest}
                    SetType="Template"
                    Speed={Speed}
                    SpeedUnits={SpeedUnits}
                    WorkoutLapId={WorkoutLapId}
                />
            )
        } else if (LapType === 'Break') {
            return (
                <BreakLap
                    BreakTime={BreakTime}
                    ReadOnly
                    WorkoutLapId={WorkoutLapId}
                />
            )
        }
    }

    renderLapHeader = (LapNumber, LapType) => {
        var { t } = this.props;

        return (
            <LapHeaderContainer className="LapHeaderContainer">
                <LapHeaderContainerLeft>
                    {LapType === 'Active' && <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${t('lap_str_all_capital').toUpperCase()} ${LapNumber}`} TextAlign="right" />}
                    {LapType === 'Break' && <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('break').toUpperCase()} TextAlign="right" />}
                </LapHeaderContainerLeft>
            </LapHeaderContainer>
        );
    }

    renderLogoPreview() {
        const { t } = this.props;
        const { Device } = this.props;
        const { SaveLogo, PDFPreview } = this.state;
        
        let Buttons = [];
    
        if (PDFPreview.LogoImageFile || PDFPreview.LogoPictureUrl) {
            Buttons = [
                { BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: Camera, IconOnly: true, IconPosition: 'left', IsInput: true, InputProps: { accept: "image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg", id: "UploadLogo", type: "file", onChange: this.onSelectLogo } },
                { BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: CrossRed, IconOnly: true, IconPosition: 'left', OnClick: this.onDeleteImage },
            ]
        } else Buttons = [{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: Camera, IconPosition: 'left', IsInput: true, InputProps: { accept: "image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg", id: "UploadLogo", type: "file", onChange: this.onSelectLogo }, Title: t('Upload') }]
    
        return (
            <>
                <PDFStyles.StyledImageContainer className="StyledImageContainer">
                {
                    PDFPreview.LogoPictureUrl &&
                    <PDFStyles.StyledLogoImage
                        ImageSrc={PDFPreview.LogoPictureUrl}
                        SelfLoading
                        className="StyledLogoImage"
                    />
                }

                <PDFStyles.StyledLogoButtons
                    Buttons={Buttons}
                    ButtonWidth="fit-content"
                    ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                    NotTouching
                    OwnRows
                    className="StyledLogoButtons"
                    NeedsMargin={!!PDFPreview.LogoPictureUrl}
                />
        
                </PDFStyles.StyledImageContainer>
        
                {
                    PDFPreview.LogoImageFile && 
                    <PDFStyles.SaveLogoContainer>
                        <PDFStyles.SaveLogoCheckmark onClick={this.onToggleSaveLogo} Selected={SaveLogo} />
                        <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" Text={t('save_image_for_later')} TextAlign="left" />
                    </PDFStyles.SaveLogoContainer>
                }
            </>
        );
    }

    renderPopoverContent = () => {
        var { t } = this.props;

        return (
            <PDFStyles.PopoverContent className="PopoverContent">
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('info.empty.space')} TextAlign="center" WhiteSpace="pre-line" />
            </PDFStyles.PopoverContent>
        );
    }

    renderShowEditShareAbilityStatusModal = () => {
        var { ShowEditShareAbilityStatusModal } = this.state;

        if (ShowEditShareAbilityStatusModal) {
            var { SharingDetails } = this.props.WorkoutTemplate.TemplateDetails;

            return (
                <EditShareAbilityStatusModal
                    ContentId={this.props.TemplateDetail_Preview.TemplateId}
                    CurrentStatus={SharingDetails.ShareAbilityStatusId}
                    Loading={this.props.TryingUpdateShareAbilityStatus_WorkoutTemplates}
                    OnHide={() => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: false })}
                    OnUpdateShareAbilityStatus={this.onUpdateShareAbilityStatus}
                    Show={ShowEditShareAbilityStatusModal}
                />
            );
        }
    }

    renderShowHowSharingWorksModal = () => {
        var { ShowHowSharingWorksModal } = this.state;

        if (ShowHowSharingWorksModal) {
            return (
                <HowSharingWorksModal
                    OnHide={() => this.onToggleShowHowSharingWorksModal(false)}
                    Show={ShowHowSharingWorksModal}
                />
            );
        }
    }

    renderShowShareContentModal = () => {
        var { ShowEditShareAbilityStatusModal, ShowHowSharingWorksModal, ShowShareContentModal } = this.state;

        if (ShowShareContentModal) {
            var { TryingUpdateSharedToCoachStatus_WorkoutTemplates } = this.props;

            return (
                <ShareContentModal
                    ContentIdName="TemplateId"
                    ContentType="WorkoutTemplates"
                    DisplayNone={!!ShowEditShareAbilityStatusModal || !!ShowHowSharingWorksModal}
                    OnHide={() => this.onToggleShowShareContentModal(false)}
                    OnSubmit={({ Emails, ContentIds }) => this.onShareContentToEmails({ Emails, ContentIds })}
                    OnToggleShowEditShareAbilityStatusModal={({ ContentIndex, ContentId, CurrentStatus }) => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: true, ContentId, ContentIndex, CurrentStatus })}
                    OnToggleShowHowSharingWorksModal={() => this.onToggleShowHowSharingWorksModal(true)}
                    Loading={TryingUpdateSharedToCoachStatus_WorkoutTemplates}
                    SharingContent={[{ ...this.props.WorkoutTemplate.TemplateDetails, ...this.props.WorkoutTemplate.TemplateDetails.SharingDetails, ...this.props.WorkoutTemplate.TemplateDetails.SharingDetails.OwnerDetails, TemplateId: this.props.TemplateDetail_Preview.TemplateId }]}
                    Show={ShowShareContentModal}
                />
            );
        }
    }

    renderTemplateExercises = () => {
        var { t } = this.props;
        var { WorkoutTemplate: { WorkoutExercises } } = this.props;

        return (
            <WorkoutExercisesContainer className="WorkoutExercisesContainer">
                {
                    WorkoutExercises.length > 0 ?
                    WorkoutExercises.map((WorkoutExercise, WorkoutExerciseIndex) => {
                        var CircuitType = WorkoutExercise.NumExercises >= 3 ? 'Circuit' : WorkoutExercise.NumExercises === 2 ? 'Superset' : 'WorkoutExercise';

                        return (
                            <React.Fragment key={WorkoutExercise.WorkoutExercises[0].ExerciseId}>
                                {WorkoutExerciseIndex !== 0 && <Spacer Size="medium" />}

                                <CircuitContainer CircuitType={CircuitType}>
                                    {
                                        WorkoutExercise.WorkoutExercises.map((CircuitWorkoutExercise, CircuitWorkoutExerciseIndex) => {                
                                            return (
                                                <React.Fragment key={CircuitWorkoutExercise.ExerciseId}>
                                                    {CircuitWorkoutExerciseIndex !== 0 && this.renderCircuitSeparator({ CircuitType })}
                
                                                    <TemplateExerciseComponent
                                                        CircuitType={CircuitType}
                                                        Exercise={CircuitWorkoutExercise}
                                                        OnToggleShowExerciseDetailModal={(ShowExerciseDetailModal, Exercise) => this.onToggleShowExerciseDetailModal(ShowExerciseDetailModal, Exercise)}
                                                        ReadOnly
                                                    />
                                                </React.Fragment>
                                            );
                                        })
                                    }    
                                </CircuitContainer>
                            </React.Fragment>
                        );
                    })
                :
                    <PageText FontFamily="medium-italic" FontSize="medium-2" NoMargin Text={t('add_first_exercise')} TextAlign="center" />
                }
            </WorkoutExercisesContainer>
        );
    }

    renderTemplateLaps = () => {
        var { Device } = this.props;

        var Units = this.props.WorkoutTemplate.TemplateDetails.Units;
        var New_ActiveUnits = Units === 'imperial' ? 'yards' : 'meters';

        return (
            <WorkoutLapsContainer className="WorkoutLapsContainer">
                {
                    this.state.WorkoutLaps.Laps.map(({ Active, ActiveUnits, BreakTime, LapNumber, LapType, Rest, Speed, SpeedUnits, WorkoutLapId }, LapIndex) => {                            
                        ActiveUnits = ActiveUnits || New_ActiveUnits;

                        return (
                            <React.Fragment key={LapIndex}>
                                {(LapIndex !== 0) && <Spacer Size="extra-small" />}

                                <LapContainer className="LapContainer">
                                    <LapHeaderContainer className="LapHeaderContainer">
                                        {this.renderLapHeader(LapNumber, LapType)}
                                    </LapHeaderContainer>
                                    <LapDetailsContainer className="LapDetailsContainer">
                                        {this.renderLapDetails({ Active, ActiveUnits, BreakTime, LapType, Rest, Speed, SpeedUnits, WorkoutLapId })}
                                    </LapDetailsContainer>
                                    {Device === 'laptop' && <LapFooterContainer className="LapFooterContainer" />}
                                </LapContainer>
                            </React.Fragment>
                        );
                    })
                }
            </WorkoutLapsContainer>
        );
    }

    renderModalBody = () => {
        if (this.state.Loading || this.props.TryingGetWorkoutTemplateDetail) return <Loading />;

        if (this.state.ShowExportPDFPreview) return this.renderExportPDFPreview();

        var { t } = this.props;
        var { Device, DownloadingFile, TemplateDetail_Preview: { TemplateName }, TryingUpdateClientAssigning, TryingUpdatePausedSharing_WorkoutTemplates, TryingUpdateShareAbilityStatus_WorkoutTemplates, TryingUpdateSharedToCoachStatus_WorkoutTemplates, WorkoutTemplate: { TemplateDetails: { Duration, SharingDetails, WorkoutType } } } = this.props;
        var { ActivityName, TrainerNote, Updating } = this.state;

        var Buttons = [];

        var ActivityButtonText = <MultiText
            Texts={[
                { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('WorkoutPlan_Activity')} -` },
                { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${ActivityName}` }
            ]}
        />

        var DurationButtonText = <MultiText
            Texts={[
                { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('length')} -` },
                { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${Duration} ${t('min_str').toLowerCase()}` }
            ]}
        />

        Buttons.push({ BackgroundColor: 'white-concrete', Title: ActivityButtonText });
        Buttons.push({ BackgroundColor: 'white-concrete', Title: DurationButtonText });
        if (!!SharingDetails && SharingDetails.SharedStatus === 1) Buttons.push({ Color: 'blue-astronaut', ColorHover: 'blue-astronaut', FontFamily: 'semibold', OnClick: this.onClickAssignClients, Title: t('assign_to_clients') });

        return (
            <>
                {(Updating || DownloadingFile || TryingUpdateClientAssigning) && <Loading />}

                <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text={TemplateName} TextAlign="center" />

                <Spacer Size="medium" />

                {this.renderButtons()}

                <Spacer Size="medium" />

                <StyledHeaderButtonGroup
                    Buttons={Buttons}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />

                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'medium' : 'small'} />

                <TrainerNoteContainer>
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" Text={t('WorkoutPlan_Trainer_note_to_client')} TextAlign="left" />
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={TrainerNote || t('no_note')} TextAlign="left" WhiteSpace="pre-wrap" />
                </TrainerNoteContainer>

                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'medium' : 'small'} />

                {this.renderAdditionalActivity()}

                {WorkoutType === 1 && this.renderTemplateExercises()}
                {WorkoutType === 2 && this.renderTemplateLaps()}

                <Spacer Size="medium" />

                <ContentSharingDetails
                    Loading={TryingUpdatePausedSharing_WorkoutTemplates || TryingUpdateShareAbilityStatus_WorkoutTemplates || TryingUpdateSharedToCoachStatus_WorkoutTemplates}
                    OnShareAgain={this.onShareAgain}
                    OnPauseSharing={this.onPauseSharing}
                    onToggleShowShareContentModal={() => this.onToggleShowShareContentModal(true)}
                    OnToggleShowEditShareAbilityStatusModal={() => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: true })}
                    OnToggleShowHowSharingWorksModal={() => this.onToggleShowHowSharingWorksModal(true)}
                    OnUnshare={this.onUnshareContent}
                    OnUpdateSharedToCoachStatus={this.onUpdateSharedToCoachStatus}
                    SharingDetails={SharingDetails}
                />

                <Spacer Size="medium" />

                {this.renderCoachAddedSession()}
            </>
        );
    }

    render() {
        var { OnHide, Show } = this.props;
        var { ShowDeleteWorkoutTemplateModal, ShowEditShareAbilityStatusModal, ShowExerciseDetailModal, ShowExportPDFPreview, ShowHowSharingWorksModal, ShowShareContentModal } = this.state;

        var BottomButton = 'cancel';
        var BottomButtonDisabled = false;
        var BottomButtonOnClick = OnHide;
        var BottomButtonText = null;

        var TopButtonOnClick = OnHide;

        if (ShowExportPDFPreview) {
            var { t } = this.props;
            var { EmailBody, EmailsList, HowToExport } = this.state.PDFPreview;

            var BottomActionType = null;
            BottomButton = 'submit';

            if (HowToExport.has('DownloadAsPDF') && !HowToExport.has('SendAsEmail')) { BottomActionType = 'download'; BottomButtonText=t('export.pdf'); }
            else if (HowToExport.has('DownloadAsPDF') && HowToExport.has('SendAsEmail')) { BottomActionType = 'both'; BottomButtonText=t('export.and.send'); }
            else if (!HowToExport.has('DownloadAsPDF') && HowToExport.has('SendAsEmail')) { BottomActionType = 'send'; BottomButtonText=t('send'); }
            else BottomButton = 'cancel';

            if (!BottomActionType && BottomButton !== 'cancel') BottomButtonDisabled = true;

            if (BottomActionType === 'both' || BottomActionType === 'send') {
                if (!EmailBody) BottomButtonDisabled = true;
                else {
                    BottomButtonDisabled = true;

                    for (var i = 0; i < EmailsList.length; i++) {
                        if (!!EmailsList[i].EmailAddress && EmailsList[i].IsValid) {
                            BottomButtonDisabled = false;
                            break;
                        }
                    }
                }
            }

            BottomButtonOnClick = BottomButton === 'cancel' ? () => this.onToggleShowExportPDFPreview(false) : this.onExportToPdf;
            TopButtonOnClick = () => this.onToggleShowExportPDFPreview(false);
        }

        return (
            <>
                <AbelModal
                    BottomButton={BottomButton}
                    BottomButtonOnClick={BottomButtonOnClick}
                    BottomButtonText={BottomButtonText}
                    Disabled={BottomButtonDisabled}
                    DisplayNone={(ShowDeleteWorkoutTemplateModal || ShowEditShareAbilityStatusModal || ShowExerciseDetailModal || ShowHowSharingWorksModal || ShowShareContentModal)}
                    GA_PathName="WorkoutTemplate"
                    Show={Show}
                    Size="large"
                    OnHide={OnHide}
                    TopButton="cancel"
                    TopButtonOnClick={TopButtonOnClick}
                >
                    {this.renderModalBody()}
                </AbelModal>

                {this.renderDeleteWorkoutTemplateModal()}
                {this.renderExerciseDetailModal()}
                {this.renderShowEditShareAbilityStatusModal()}
                {this.renderShowHowSharingWorksModal()}
                {this.renderShowShareContentModal()}
            </>
        );
    }
}

WorkoutTemplate.propTypes = {
    OnAssignClients: PropTypes.func,
    OnHide: PropTypes.func.isRequired,
    OnLoadTemplates: PropTypes.func.isRequired,
    OnUpdateMultiSelectList: PropTypes.func,
    OnUpdateSharedToCoachStatus: PropTypes.func,
    Show: PropTypes.bool.isRequired,
    TemplateDetail_Preview: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        DownloadingFile: state.Window.DownloadingFile,

        UserDetails: state.Auth.UserDetails,

        WorkoutTemplate: state.Templates.WorkoutTemplate,

        TryingDeleteWorkoutTemplates: state.Templates.TryingDeleteWorkoutTemplates,
        TryingDeleteWorkoutTemplatesError: state.Templates.TryingDeleteWorkoutTemplatesError,
        TryingGetWorkoutTemplateDetail: state.Templates.TryingGetWorkoutTemplateDetail,
        TryingGetWorkoutTemplateDetailError: state.Templates.TryingGetWorkoutTemplateDetailError,
        TryingUpdatePausedSharing_WorkoutTemplates: state.Templates.TryingUpdatePausedSharing_WorkoutTemplates,
        TryingUpdatePausedSharing_WorkoutTemplatesError: state.Templates.TryingUpdatePausedSharing_WorkoutTemplatesError,
        TryingUpdateClientAssigning: state.Templates.TryingUpdateClientAssigning,
        TryingUpdateClientAssigningError: state.Templates.TryingUpdateClientAssigningError,
        TryingUpdateShareAbilityStatus_WorkoutTemplates: state.Templates.TryingUpdateShareAbilityStatus_WorkoutTemplates,
        TryingUpdateShareAbilityStatus_WorkoutTemplatesError: state.Templates.TryingUpdateShareAbilityStatus_WorkoutTemplatesError,
        TryingUpdateSharedToCoachStatus_WorkoutTemplates: state.Templates.TryingUpdateSharedToCoachStatus_WorkoutTemplates,
        TryingUpdateSharedToCoachStatus_WorkoutTemplatesError: state.Templates.TryingUpdateSharedToCoachStatus_WorkoutTemplatesError,

        UploadProgress: state.UploadContent.UploadProgress,

        TryingFreezeWorkout: state.Workout.TryingFreezeWorkout
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearWorkoutTemplateDetail, DeleteWorkoutTemplates, FreezeWorkoutForPrint, GenerateWorkoutTemplatePDF, GetWorkoutTemplateDetail, SaveDataInProps, UpdateClientAssigning, UpdatePausedSharing_WorkoutTemplates, UpdatePDFLogo, UpdateShareAbilityStatus_WorkoutTemplates, UpdateSharedToCoachStatus_WorkoutTemplates, UploadToPresignedUrl } )(WorkoutTemplate));