import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';

import { ImageContainer, StyledInput } from './styles';

import { getFileExtension, validateFile } from '../../Functions';

import AbelModal from '../../Components/Modal';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import UploadProgressComponent from '../../Components/UploadProgress';

class ImageUploadCrop extends React.Component {
    state = {
        ImageTooLarge: false,
        MaxImageSize: 10000000,
        WrongImageType: false,

        src: null,
        crop: { unit: null, width: null, aspect: null },
        croppedImageUrl: null
    }

    componentDidMount() {
        var { CropSettings } = this.props;

        this.setState({ crop: { ...this.state.crop, ...CropSettings } });
    }

    handleFile = event => {
        if (event.target.files.length) {
            var SelectedImage = event.target.files[0];
            var FileExtension = getFileExtension(SelectedImage.name);

            if (validateFile({ FileExtension, FileType: 'Images' })) {
                if (SelectedImage.size <= this.state.MaxImageSize) {
                    
                    const fileReader = new FileReader();

                    fileReader.onloadend = () => {
                        this.setState({ src: fileReader.result });
                    }   

                    fileReader.readAsDataURL(SelectedImage);

                    this.setState({ ImageTooLarge: false, WrongImageType: false });
                } else {
                    document.getElementById('ImageUploadCrop').value = null;
                    this.setState({ ImageTooLarge: true, WrongImageType: false });
                }
            } else this.setState({ ImageTooLarge: false, WrongImageType: true });
        }
    }
    
    onClickSubmit = () => {
        this.props.OnSaveMedia(this.state.croppedImage);
    }

    onImageLoaded = image => {
        this.imageRef = image;
    }
    
    onCropChange = (crop) => {
        this.setState({ crop });
    }
    
    onCropComplete = crop => {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = this.getCroppedImg(this.imageRef, crop);

            this.setState({ croppedImageUrl });
        }
    }
    
    getCroppedImg(image, crop) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        
        ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);
    
        const reader = new FileReader();

        canvas.toBlob(blob => {
            reader.readAsDataURL(blob);

            reader.onloadend = () => {
                this.dataURLtoFile(reader.result, 'cropped.png');
            }
        });
    }

    dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
                
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }

        let croppedImage = new File([u8arr], filename, { type:mime });
        this.setState({ croppedImage: croppedImage }) ;
    }

    renderModalBody = () => {
        var { t } = this.props;
        var { UploadProgress } = this.props;
        var { crop, src, ImageTooLarge, WrongImageType } = this.state;

        return (
            <>
                <ImageContainer>
                        <StyledInput
                            accept="image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg"
                            id="ImageUploadCrop"
                            onChange={this.handleFile}
                            type="file"
                        />

                    {
                        src &&
                        <>
                            <Spacer Size="small" />

                            <ReactCrop
                                src={src}
                                crop={crop}
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                            />
                        </>
                    }

                    {
                        (ImageTooLarge || WrongImageType) &&
                        <>
                            <Spacer Size="small" />

                            <PageText
                                FontColor="red-bittersweet"
                                FontFamily="semibold-italic"
                                FontSize="medium-1"
                                Text={t(ImageTooLarge ? 'media_wrongsize' : 'media_wrongtype', { AllowedTypes: '.jpg, .png, .jpeg', MaxSize: '10MB', Type: 'Image' })}
                                TextAlign="center"
                            />
                        </>
                    }
                </ImageContainer>

                {
                    UploadProgress && Object.entries(UploadProgress).length > 0 && 
                        <>
                            <Spacer Size="medium" />

                            <UploadProgressComponent UploadingItems={UploadProgress} />
                        </>
                }
            </>
        );
    }

    render() {
        var { t } = this.props;
        var { OnHide, Show } = this.props;

        return (
            <AbelModal
                BottomButton="submit"
                BottomButtonOnClick={this.onClickSubmit}
                BottomButtonText={t('save_with_first_char_uppercase')}
                GA_PathName="ImageUploadCrop"
                Show={Show}
                Size="medium"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.props.Loading && <Loading />}

                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

ImageUploadCrop.propTypes = {
    CropSettings: PropTypes.object,
    Loading: PropTypes.bool.isRequired,
    OnHide: PropTypes.func.isRequired,
    OnSaveMedia: PropTypes.func.isRequired,
    UploadProgress: PropTypes.object,
    Show: PropTypes.bool.isRequired
}

ImageUploadCrop.defaultProps = {
    OnSaveMedia: () => null,
    UploadProgress: null
}

export default withTranslation()(ImageUploadCrop);