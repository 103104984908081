import styled from 'styled-components';
import { device } from '../../Config/device';

import PageText from '../../Components/Text/PageText';
import PaginatedList from '../../Components/Lists/Paginated';

export const ClientGroupContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(2, 1fr);

    @media ${device.minwidth.mobile} {
        grid-template-columns: repeat(3, 1fr);
    }

    @media ${device.minwidth.laptop} {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 1400px) {
        grid-template-columns: repeat(6, 1fr);
    }
`;

export const GroupClientContainer = styled.div`
    align-items: center;
    background-color: var(--white);
    border: ${props => props.Selected ? '5px solid var(--blue-abel)' : '2px solid var(--white-concrete)'};
    border-radius: 10px;
    display: flex;
    padding: 5px;

    :hover {
        background-color: var(--white-concrete);
        cursor: pointer;
    }
`;

export const GroupHeaderContainer = styled.div``;

export const LoadMoreContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

export const StyledClientName = styled(PageText)`
    margin-left: 15px;
`;

export const StyledPaginatedList = styled(PaginatedList)`
    border: none;
    padding: 0px;

    > div:first-child {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(1, 2fr);

        @media ${device.minwidth.mobile} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media ${device.minwidth.ipad} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media ${device.minwidth.laptop} {
            grid-template-columns: repeat(4, 1fr);
        }
    }
`;

export const StyledTrainerClientsPaginatedList = styled(PaginatedList)`
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    padding: 15px;

    > div:first-child {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(1, 2fr);

        @media ${device.minwidth.mobile} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media ${device.minwidth.ipad} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media ${device.minwidth.laptop} {
            grid-template-columns: repeat(4, 1fr);
        }
    }
`;