import React from 'react';
import { withTranslation } from 'react-i18next';
// import history from '../../../history';
import { DateRangePicker } from '@mantine/dates';

import * as styles from './styles';

import ButtonGroup from '../../../Components/Buttons/ButtonGroup';
import Loading from '../../../Components/Loading';
import PageText from '../../../Components/Text/PageText';
import Spacer from '../../../Components/Spacer';

import DownloadIcon from '../../../Assets/Icons/Download_Gray.png';
import Plus from '../../../Assets/Icons/PlusSign.png';

class FiltersComponent extends React.Component {
    state = {
        ShowCustomDates: this.props.TimeRange.Type === 'Custom'
    }

    componentDidMount() {
        this.setState({ Selected: { ...this.props.DefaultSelections } });
    }

    onChangeDate = NewDates => {
        if (NewDates[0] && NewDates[1]) {
            this.props.OnSelectCustomDates({ StartDate: NewDates[0], EndDate: NewDates[1] });
        }
    }

    onSelectDataType = DataType => {
        this.props.OnSelectDataType(DataType)
    }

    onSelectTimeRange = Type => {
        this.props.OnSelectTimeRange(Type);
    }

    onSelectValueType = ValueType => {
        this.props.OnSelectValueType(ValueType);
    }

    renderDataTypes = () => {
        var { t } = this.props;
        var { ReadOnly } = this.props;
        var { Delivered, Paid, Sold } = this.props.DataTypes;

        return (
            <styles.TimeRangeContainer className="TimeRangeContainer">
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_view')} />

                <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                    <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={ReadOnly ? () => null : () => this.onSelectDataType('Sold')} ReadOnly={ReadOnly} Selected={!!Sold} />

                    <styles.RadioButtonPageText ElementType="span" FontFamily={!!Sold ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={ReadOnly ? () => null : () => this.onSelectDataType('Sold')} ReadOnly={ReadOnly} Text={t('_sold')} TextAlign="left" />
                </styles.RadioButtonParentContainer>

                {
                    this.props.ValueType !== 'Product' &&
                    <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                        <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={ReadOnly ? () => null : () => this.onSelectDataType('Paid')} ReadOnly={ReadOnly} Selected={!!Paid} />
    
                        <styles.RadioButtonPageText ElementType="span" FontFamily={!!Paid ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={ReadOnly ? () => null : () => this.onSelectDataType('Paid')} ReadOnly={ReadOnly} Text={t('_paid')} TextAlign="left" />
                    </styles.RadioButtonParentContainer>
                }

                <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                    <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={ReadOnly ? () => null : () => this.onSelectDataType('Delivered')} ReadOnly={ReadOnly} Selected={!!Delivered} />

                    <styles.RadioButtonPageText ElementType="span" FontFamily={!!Delivered ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={ReadOnly ? () => null : () => this.onSelectDataType('Delivered')} ReadOnly={ReadOnly} Text={t('_delivered')} TextAlign="left" />
                </styles.RadioButtonParentContainer>
            </styles.TimeRangeContainer>
        );
    }

    renderTimeRange = () => {
        var { t } = this.props;
        var { ReadOnly } = this.props;
        var { EndDate, StartDate, Type } = this.props.TimeRange;

        StartDate = !!StartDate ? new Date(StartDate.split('-')[0], StartDate.split('-')[1] - 1, StartDate.split('-')[2]) : '';
        EndDate = !!EndDate ? new Date(EndDate.split('-')[0], EndDate.split('-')[1] - 1, EndDate.split('-')[2]) : '';

        return (
            <styles.TimeRangeContainer className="TimeRangeContainer">
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_view')} />

                <styles.RadioButtonParentContainer>
                    <styles.MultiSelectRadioButton onClick={ReadOnly ? () => null : () => this.onSelectTimeRange('AllTime')} ReadOnly={ReadOnly} Selected={Type === "AllTime"} />
                    
                    <styles.RadioButtonPageText ElementType="span" FontFamily={Type === "AllTime" ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={ReadOnly ? () => null : () => this.onSelectTimeRange('AllTime')} ReadOnly={ReadOnly} Text={t('_alltime')} TextAlign="left" />
                </styles.RadioButtonParentContainer>

                <styles.RadioButtonParentContainer>
                    <styles.MultiSelectRadioButton onClick={ReadOnly ? () => null : () => this.onSelectTimeRange('CurrentYear')} ReadOnly={ReadOnly} Selected={Type === "CurrentYear"} />
                    
                    <styles.RadioButtonPageText ElementType="span" FontFamily={Type === "CurrentYear" ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={ReadOnly ? () => null : () => this.onSelectTimeRange('CurrentYear')} ReadOnly={ReadOnly} Text={t('_current_year')} TextAlign="left" />
                </styles.RadioButtonParentContainer>

                <styles.RadioButtonParentContainer>
                    <styles.MultiSelectRadioButton onClick={ReadOnly ? () => null : () => this.onSelectTimeRange('CurrentMonth')} ReadOnly={ReadOnly} Selected={Type === "CurrentMonth"} />
                    
                    <styles.RadioButtonPageText ElementType="span" FontFamily={Type === "CurrentMonth" ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={ReadOnly ? () => null : () => this.onSelectTimeRange('CurrentMonth')} ReadOnly={ReadOnly} Text={t('_current_month')} TextAlign="left" />
                </styles.RadioButtonParentContainer>

                <styles.RadioButtonParentContainer>
                    <styles.MultiSelectRadioButton onClick={ReadOnly ? () => null : () => this.onSelectTimeRange('Custom')} ReadOnly={ReadOnly} Selected={Type === "Custom"} />
                    
                    <styles.RadioButtonPageText ElementType="span" FontFamily={Type === "Custom" ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={ReadOnly ? () => null : () => this.onSelectTimeRange('Custom')} ReadOnly={ReadOnly} Text={t('_custom_period')} TextAlign="left" />
                </styles.RadioButtonParentContainer>

                {
                    Type === 'Custom' &&
                    <>
                        <Spacer Size="extra-extra-small" />

                        <DateRangePicker
                            value={[ StartDate, EndDate ]}
                            onChange={this.onChangeDate}
                            inputFormat="MMM DD, YYYY"
                        />
                    </>
                }
            </styles.TimeRangeContainer>
        );
    }

    renderValueType = () => {
        var { t } = this.props;
        var { CurrencyName, ReadOnly, ValueType } = this.props;

        return (
            <styles.TimeRangeContainer className="TimeRangeContainer">
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={t('_view')} />

                <styles.RadioButtonParentContainer>
                    <styles.MultiSelectRadioButton onClick={ReadOnly ? () => null : () => this.onSelectValueType('Currency')} ReadOnly={ReadOnly} Selected={ValueType === "Currency"} />
                    
                    <styles.RadioButtonPageText ElementType="span" FontFamily={ValueType === "Currency" ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={ReadOnly ? () => null : () => this.onSelectValueType('Currency')} ReadOnly={ReadOnly} Text={CurrencyName} TextAlign="left" />
                </styles.RadioButtonParentContainer>

                <styles.RadioButtonParentContainer>
                    <styles.MultiSelectRadioButton onClick={ReadOnly ? () => null : () => this.onSelectValueType('Product')} ReadOnly={ReadOnly} Selected={ValueType === "Product"} />
                    
                    <styles.RadioButtonPageText ElementType="span" FontFamily={ValueType === "Product" ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={ReadOnly ? () => null : () => this.onSelectValueType('Product')} ReadOnly={ReadOnly} Text={t('_products')} TextAlign="left" />
                </styles.RadioButtonParentContainer>
            </styles.TimeRangeContainer>
        );
    }

    render() {
        var { t } = this.props;
        var { IsModal, Loading: IsLoading } = this.props;

        return (
            <>
                {IsModal && IsLoading && <Loading />}

                <styles.FiltersContainer className="FiltersContainer">
                    {
                        !IsModal &&
                        <>
                            <ButtonGroup
                                Buttons={[
                                    { BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: this.props.OnAddRegionsAndBranches, TextAlign: 'left', Title: t('_add_regions_and_branches') }
                                ]}
                                ButtonWidth={'fit-content'}
                                NotTouching
                            />
                    
                            <Spacer Size="small-1" />
                        </>
                    }

                    {this.renderTimeRange()}

                    <Spacer Size="small-1" />

                    {this.renderDataTypes()}

                    <Spacer Size="small-1" />

                    {this.renderValueType()}

                    {
                        !IsModal &&
                        <>
                            <Spacer Size="small-1" />

                            <ButtonGroup
                                Buttons={[
                                    { BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', FontFamily: 'semibold', Icon: DownloadIcon, IconPosition: 'left', OnClick: this.props.OnExportReport, TextAlign: 'left', Title: t('_export_salary_report') }
                                ]}
                                ButtonWidth={'fit-content'}
                                NotTouching
                            />
                        </>
                    }
                </styles.FiltersContainer>
            </>
        );
    }
}

export default withTranslation()(FiltersComponent);