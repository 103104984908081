import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';

import { CalendarIcon, Container, DateContainer, Image } from './styles';

import PageText from '../../Text/PageText';
import Spacer from '../../Spacer';

import ChooseDates from '../../../Modals/ChooseDates';

import Calendar from '../../../Assets/Icons/Calendar.png';
import LeftArrow from '../../../Assets/Icons/LeftArrow.png';
import RightArrow from '../../../Assets/Icons/RightArrow.png';

class CalendarHeader extends React.Component {
    state = {
        SelectedDates: [ moment().format('YYYY-MM-DD') ],
        ShowChooseDatesModal: false
    }

    onSelectDates = SelectedDates => {
        this.setState({ SelectedDates, ShowChooseDatesModal: false }, () => this.props.OnSelectDate(SelectedDates[0]));
    }

    onToggleShowChooseDatesModal = ShowChooseDatesModal => {
        this.setState({ ShowChooseDatesModal });
    }

    renderCurrentInterval = () => {
        var { MonthlyView, SectionType } = this.props;

        var EndDate, StartDate;
        var CalendarDifference = this.props[`CalendarDifference_${SectionType}`];

        if (SectionType === 'GroupWorkouts') CalendarDifference = this.props.WeeklyView_GroupWorkouts_Difference;

        var Text = '';

        if (MonthlyView) Text = moment().add(CalendarDifference, 'months').format('MMMM YYYY');
        else {
            EndDate = moment().add(CalendarDifference, 'weeks').endOf('isoWeek');
            StartDate = moment().add(CalendarDifference, 'weeks').startOf('isoWeek');

            Text = `${moment(StartDate).format('[WeeklyViewHeader]')} - ${moment(EndDate).format('[WeeklyViewHeader]')}`;
        }

        return <PageText FontSize="medium-2" NoMargin Text={Text} />;
    }

    renderShowChooseDatesModal = () => {
        var { ShowChooseDatesModal } = this.state;

        if (ShowChooseDatesModal) {
            return (
                <ChooseDates
                    MaxDates={1}
                    OnHide={() => this.onToggleShowChooseDatesModal(false)}
                    OnSelectDates={SelectedDates => this.onSelectDates(SelectedDates)}
                    SelectedDates={this.state.SelectedDates}
                    Show={ShowChooseDatesModal}
                />
            );
        }
    }

    renderTimePeriod = () => {
        var { t } = this.props;
        var { MonthlyView, SectionType } = this.props;

        var TimePeriod = MonthlyView ? 'month' : 'week';

        var CalendarDifference = this.props[`CalendarDifference_${SectionType}`];

        if (SectionType === 'GroupWorkouts') CalendarDifference = this.props.WeeklyView_GroupWorkouts_Difference;

        var Text = '';
        if (CalendarDifference === 0) Text = `${t('timeperiod_current')} ${t(`timeperiod_${TimePeriod}_singular`)}`;
        else if (CalendarDifference < 0) Text = `${Math.abs(CalendarDifference)} ${t(CalendarDifference === -1 ? `timeperiod_${TimePeriod}_singular` : `timeperiod_${TimePeriod}_plural`)} ${t('timeperiod_past')}`;
        else if (CalendarDifference > 0) Text = `${CalendarDifference} ${t(CalendarDifference === 1 ? `timeperiod_${TimePeriod}_singular` : `timeperiod_${TimePeriod}_plural`)} ${t('timeperiod_future')}`;

        return Text;
    }

    render() {
        var { OnUpdateDate } = this.props;

        return (
            <>
                <Container>
                    <CalendarIcon src={Calendar} onClick={() => this.onToggleShowChooseDatesModal(true)} alt="Choose dates" />

                    <Spacer Size="extra-extra-small" />
                    
                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={this.renderTimePeriod()} />

                    <Spacer Size="extra-extra-small" />

                    <DateContainer>
                        <Image src={LeftArrow} onClick={() => OnUpdateDate(-1)} alt="Go back" />
                        {this.renderCurrentInterval()}
                        <Image src={RightArrow} onClick={() => OnUpdateDate(1)} alt="Go forward" />
                    </DateContainer>
                </Container>

                {this.renderShowChooseDatesModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        CalendarDifference_Meals: state.Client.CalendarDifference_Meals,
        CalendarDifference_Tasks: state.Client.CalendarDifference_Tasks,
        CalendarDifference_Workouts: state.Client.CalendarDifference_Workouts,

        WeeklyView_GroupWorkouts_Difference: state.Group.WeeklyView_GroupWorkouts_Difference,
        WeeklyView_GroupWorkouts_EndDate: state.Group.WeeklyView_GroupWorkouts_EndDate,
        WeeklyView_GroupWorkouts_StartDate: state.Group.WeeklyView_GroupWorkouts_StartDate
    };
};

CalendarHeader.propTypes = {
    MonthlyView: PropTypes.bool,
    OnSelectDate: PropTypes.func.isRequired,
    OnUpdateDate: PropTypes.func.isRequired,
    SectionType: PropTypes.string.isRequired
}

export default withTranslation()(connect(mapStateToProps, {} )(CalendarHeader));