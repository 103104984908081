import React from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import Popover from 'react-popover';

import { Container, CopiedPageText, GiveAccessButton, GiveAccessIcon, HeaderContainer, HeaderContainerLeft, HeaderContainerRight, HeaderNameContainer, HeaderPictureContainer, PopoverContainer, PopoverHeaderContainer, PopoverHeaderEmailContainer, ProfilePhotoContainer, StyledDisplayNameLink, StyledEllipsisText, StyledThreeDotsIcon } from './CompactItem_styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import ProfilePhoto from '../../Components/ProfilePhoto';
import Spacer from '../../Components/Spacer';

import GiveAccess_Meals from '../../Assets/Icons/ClientDashboard/Meals.png';
import GiveAccess_Workouts from '../../Assets/Icons/ClientDashboard/Workouts.png';
import ThreeDots from '../../Assets/Icons/ClientDashboard/ThreeDots.png';

class TrainerClientCompactItem extends React.Component {
    TimeoutId = null;

    state = { CopiedSuccess: null, PopoverIsOpen: false };

    onCopyEmail = () => {
        var { Email } = this.props.Client;

        if (!!this.TimeoutId) clearTimeout(this.TimeoutId);

        navigator.clipboard.writeText(Email);

        this.setState({ CopiedSuccess: true });

        var Timer = () => setTimeout(() => {
            this.setState({ CopiedSuccess: false });
            this.TimeoutId = null;
        }, 1000);

        this.TimeoutId = Timer();
    }

    onDeleteClient = () => {
        if (this.props.ClientType !== 'Groups') this.onTogglePopover();
        this.props.OnDeleteClient();
    }

    onGiveAccess = ({ AccessType, UpdateType }) => {
        if (AccessType === 'Workouts') this.props.OnUpdateWorkoutAccess({ UpdateType });
        else if (AccessType === 'Meals') this.props.OnUpdateMealAccess({ UpdateType });
    }

    onRequestAccess = () => {
        this.props.OnRequestClientAccess();
    }

    onShowGetStartedMeals = () => {
        this.onTogglePopover();
        this.props.OnShowGetStartedMeals();
    }

    onTogglePopover = () => {
        this.setState({ PopoverIsOpen: !this.state.PopoverIsOpen }, () => {
            if (!this.state.PopoverIsOpen && !!this.TimeoutId) clearTimeout(this.TimeoutId);
        });
    }

    renderGiveAccess = ({ AccessType, ShowGetStartedMeals }) => {
        var { ClientIndex, UpdatingAccess_AccessType, UpdatingAccess_ClientIndex } = this.props;

        var OnClick;
        if (AccessType === 'Workouts' || !ShowGetStartedMeals) OnClick = () => this.onGiveAccess({ AccessType, UpdateType: 'give' });
        else OnClick = () => this.props.OnShowGetStartedMeals();

        return (
            <GiveAccessButton className="GiveAccessButton" onClick={OnClick}>
                <GiveAccessIcon ImageSrc={AccessType === 'Meals' ? GiveAccess_Meals : GiveAccess_Workouts} />

                {(UpdatingAccess_AccessType === AccessType && ClientIndex === UpdatingAccess_ClientIndex) && <i className="fas fa-spinner fa-spin"></i>}
            </GiveAccessButton>
        );
    }

    renderPopoverContent = () => {
        var { t } = this.props;
        var { Client: { Age, DateAdded, DOB, Email, IsPreRegistered, MealAccess, RequestTypeId, Sex, ShowGetStartedMeals, UserId, WorkoutAccess }, ClientIndex, DeletingClientAccess_UserId, RequestingClientAccess_UserId, UpdatingAccess_AccessType, UpdatingAccess_ClientIndex } = this.props;
        var { CopiedSuccess } = this.state;

        var Buttons = [];

        if (RequestTypeId === 3) {
            // Meal Button
                if (!!MealAccess) Buttons.push({ FontSize: 'small', FontFamily: 'semibold', OnClick: () => this.onGiveAccess({ AccessType: 'Meals', UpdateType: 'remove' }), Title: (UpdatingAccess_AccessType === 'Meals' && ClientIndex === UpdatingAccess_ClientIndex) ? <i className="fas fa-spinner fa-spin"></i> : t('ptadmin_getstarted_meals_remove') });
                else Buttons.push({ FontSize: 'small', FontFamily: 'semibold', OnClick: !!ShowGetStartedMeals ? this.onShowGetStartedMeals : () => this.onGiveAccess({ AccessType: 'Meals', UpdateType: 'give' }), Title: (UpdatingAccess_AccessType === 'Meals' && ClientIndex === UpdatingAccess_ClientIndex) ? <i className="fas fa-spinner fa-spin"></i> : t('ptadmin_getstarted_meals') });

            // Workout Button
                if (!!WorkoutAccess) Buttons.push({ FontSize: 'small', FontFamily: 'semibold', OnClick: () => this.onGiveAccess({ AccessType: 'Workouts', UpdateType: 'remove' }), Title: (UpdatingAccess_AccessType === 'Workouts' && ClientIndex === UpdatingAccess_ClientIndex) ? <i className="fas fa-spinner fa-spin"></i> : t('ptadmin_getstarted_workouts_remove') });
                else Buttons.push({ FontSize: 'small', FontFamily: 'semibold', OnClick: () => this.onGiveAccess({ AccessType: 'Workouts', UpdateType: 'give' }), Title: (UpdatingAccess_AccessType === 'Workouts' && ClientIndex === UpdatingAccess_ClientIndex) ?<i className="fas fa-spinner fa-spin"></i> : t('ptadmin_getstarted_workouts') });
        }
        else if (RequestTypeId === 0) Buttons.push({ FontSize: 'small', FontFamily: 'semibold', OnClick: this.onRequestAccess, Title: RequestingClientAccess_UserId === UserId ? <i className="fas fa-spinner fa-spin"></i> : t('TrainerClient_Request_Client_Access') });
        else Buttons.push({ FontSize: 'small', FontFamily: 'semibold', OnClick: this.onDeleteClient, Title: DeletingClientAccess_UserId === UserId ? <i className="fas fa-spinner fa-spin"></i> : t('TrainerClient_Request_Delete') });

        return (
            <PopoverContainer className="PopoverContainer">
                <PopoverHeaderContainer className="PopoverHeaderContainer">
                    <PopoverHeaderEmailContainer className="PopoverHeaderEmailContainer">
                        <StyledEllipsisText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={Email} TextAlign="left" />
                    </PopoverHeaderEmailContainer>

                    {navigator.clipboard && <CopiedPageText FontColor="abel-blue" FontFamily="semibold" FontSize="small" JustifyContent="flex-end" NoMargin OnClick={this.onCopyEmail} Text={t(!!CopiedSuccess ? 'copied': 'copy')} TextAlign="right" />}
                </PopoverHeaderContainer>

                <Spacer Size="extra-small" />

                {
                    RequestTypeId === 3 &&
                    <>
                        <MultiText ContainerWidth="100%" TextAlign="left" Texts={[{ FontFamily: 'medium', FontSize: 'small', Text: `${t('ptadmin_client_was_added')}:` }, { FontFamily: 'semibold', FontSize: 'small', Text: moment.utc(DateAdded).format('[NumbersDate]') }]} />

                        {
                            !IsPreRegistered &&
                            <>
                                <Spacer Size="extra-extra-small" />

                                <MultiText ContainerWidth="100%" TextAlign="left" Texts={[{ FontFamily: 'medium', FontSize: 'small', Text:`${t('birthday')}:` }, { FontFamily: 'semibold', FontSize: 'small', Text: `${moment.utc(DOB).format('[NumbersDate]')} (${Age} ${t('years_old')})` }]} />

                                <Spacer Size="extra-extra-small" />

                                <MultiText ContainerWidth="100%" TextAlign="left" Texts={[{ FontFamily: 'medium', FontSize: 'small', Text:`${t('sex')}:` }, { FontFamily: 'semibold', FontSize: 'small', Text: Sex }]} />
                            </>
                        }

                        <Spacer Size="extra-small" />
                    </>
                }

                {
                    RequestTypeId === 1 &&
                    <>
                        <MultiText ContainerWidth="100%" TextAlign="left" Texts={[{ FontFamily: 'medium', FontSize: 'small', Text:`${t('Age')}:` }, { FontFamily: 'semibold', FontSize: 'small', Text: `${Age} ${t('years_old')}` }]} />

                        <Spacer Size="extra-small" />
                    </>
                }

                {
                    !!IsPreRegistered &&
                    <>
                        <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('ptadmin_giveaccess_preregistered_info')} TextAlign="left" />

                        <Spacer Size="extra-small" />
                    </>
                }

                {
                    RequestTypeId === 1 &&
                    <>
                        <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('ptadmin_giveaccess_pendingacceptance_info')} TextAlign="left" />

                        <Spacer Size="extra-small" />
                    </>
                }

                {
                    RequestTypeId === 0 &&
                    <>
                        <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('ptadmin_giveaccess_not_a_client')} TextAlign="left" />

                        <Spacer Size="extra-small" />
                    </>
                }
                
                <ButtonGroup Buttons={Buttons} ContainerWidth="100%" NotTouching OwnRows />

                {
                    RequestTypeId === 3 &&
                    <>
                        <Spacer Size="extra-small" />
                        
                        {
                            DeletingClientAccess_UserId === UserId ?
                            <i className="fas fa-spinner fa-spin"></i>
                        :
                            <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin OnClick={this.onDeleteClient} Text={t('ptadmin_clients_delete_singular')} TextAlign="center" />
                        }
                    </>
                }
            </PopoverContainer>
        );
    }

    render() {
        var { t } = this.props;
        var { Client: { DisplayName, IsPreRegistered, MealAccess, RequestTypeId, Sex, ShowGetStartedMeals, UserId, UserPhoto, WorkoutAccess }, ClientType } = this.props;

        const popoverProps = {
            isOpen: this.state.PopoverIsOpen,
            preferPlace: 'below',
            onOuterAction: this.onTogglePopover,
            body: this.renderPopoverContent(),
            tipSize: 10
        }

        return (
            <Container className="Container" ClientType={ClientType}>
                <HeaderContainer className="HeaderContainer">
                    <HeaderContainerLeft className="HeaderContainerLeft">
                        {
                            !IsPreRegistered && RequestTypeId === 3 &&
                            <HeaderPictureContainer className="HeaderPictureContainer" to={`/client/${UserId}`}>
                                <ProfilePhotoContainer className="ProfilePhotoContainer">
                                    <ProfilePhoto Sex={Sex || 'Male'} Size="small-3" Source={UserPhoto || ''} />
                                </ProfilePhotoContainer>
                            </HeaderPictureContainer>
                        }
                        <HeaderNameContainer className="HeaderNameContainer">
                            <StyledDisplayNameLink to={`/client/${UserId}`}>
                                <StyledEllipsisText FontColorHover="blue-abel" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={DisplayName} TextAlign="left" />
                            </StyledDisplayNameLink>
                            
                            {RequestTypeId === 1 && <PageText FontFamil="bold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('TrainerClient_Request_Sent_Short')} TextAlign="left" />}

                            {RequestTypeId === 3 && !!IsPreRegistered && <PageText FontFamil="bold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('TrainerClient_Invite_Sent_Short')} TextAlign="left" />}
                        </HeaderNameContainer>
                    </HeaderContainerLeft>
                    <HeaderContainerRight className="HeaderContainerRight">
                        {RequestTypeId === 3 && !MealAccess && this.renderGiveAccess({ AccessType: 'Meals', ShowGetStartedMeals })}

                        {RequestTypeId === 3 && !WorkoutAccess && this.renderGiveAccess({ AccessType: 'Workouts' })}

                        <Popover {...popoverProps}>
                            <StyledThreeDotsIcon ImageSrc={ThreeDots} OnClick={this.onTogglePopover} />
                        </Popover>
                    </HeaderContainerRight>
                </HeaderContainer>
            </Container>
        );
    }
}

export default withTranslation()(TrainerClientCompactItem);