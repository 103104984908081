import styled from 'styled-components';

import Toggle from 'react-toggle';

export const Label = styled.div`
    color: ${props => props.Selected ? props.Name === 'Off' ? 'var(--black)' : 'var(--blue-abel)' : 'var(--gray-silver)'};
    font-family: var(--font-family-semibold);
    font-size: var(--font-family-medium-1);
    margin-${props => props.Margin}: 5px;
    text-align: ${props => props.TextAlign};
`;

export const ToggleContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
`;

export const StyledToggle = styled(Toggle)`
    
`;