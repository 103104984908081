import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { InsertEditExpense, SearchCurrencies, UploadToPresignedUrl, ViewExpenseCategories } from '../../../Services/Actions';

import moment from 'moment';
import Select from 'react-select';

import styled from 'styled-components';
import { device } from '../../../Config/device';

import ButtonGroup from '../../Buttons/ButtonGroup';
import Image from '../../Image';
import Input from '../../Form/Input';
import MultiText from '../../Text/MultiText';
import PageText from '../../Text/PageText';
import Spacer from '../../Spacer';
import TextArea from '../../Form/TextArea';
import UploadProgressComponent from '../../UploadProgress';

import ChooseDates from '../../../Modals/ChooseDates';

import Camera from '../../../Assets/Icons/Camera.png';
import DeleteX from '../../../Assets/Icons/Delete_X.png';
import DeleteX_Gray from '../../../Assets/Icons/Delete_X_Gray.png';
import Pencil from '../../../Assets/Icons/Pencil.png';
import PDF from '../../../Assets/Icons/PDF.png';

import { CreateUploadToPresignedUrlPromise, getFileExtension } from '../../../Functions';

const ButtonIcon = styled.img`
    height: 25px;
    ${props => !props.IsPreview && 'margin-right: 10px;'}
    max-width: 25px;
`;

const DetailsContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
`;

const DetailsDescriptionContainer = styled.div`
    width: 100%;
`;

const DetailsHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: -5px;
    margin-top: -5px;

    > div {
        margin-bottom: 5px;
        margin-right: 20px;
        margin-top: 5px;
    }
`;

const ExpenseAmountContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const ExpenseCategoryContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const ExpenseContainer = styled.div`
    align-items: ${props => props.Editing ? 'stretch' : 'flex-start'};
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px 21px;

    :not(:last-child) {
        margin-bottom: 15px;
    }

    @media ${device.minwidth.laptop} {
        flex-direction: ${props => props.Editing ? 'column' : 'row'};
    }
`;

const ExpenseDateContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const ExpenseMediaContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

// const ExpenseMediaEditingContainer1 = styled.div`
//     ${props => props.IsDeletedSelected && 'border: 2px solid var(--red-bittersweet);'}
//     ${props => props.IsNewMedia && 'border: 2px solid var(--blue-abel);'}

//     margin-right: 10px;
//     margin-top: 10px;
//     position: relative;
// `;

const ExpenseMediaEditingContainer = styled.div`
    ${
        props => !props.NoBorder &&
        `
            border: 2px solid var(--white-concrete);
            padding: 7px;
            ${!props.IsPreview && 'padding-right: 30px;'}
        `
    }

    align-items: center;
    background-color: var(--white);
    ${props => props.IsDeletedSelected && 'border: 2px solid var(--red-bittersweet);'}
    ${props => props.IsNewMedia && 'border: 2px solid var(--blue-abel);'}
    border-radius: 10px;
    color: var(--black);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 45px;
    position: relative;
    
    ${
        props => !props.IsNewMedia &&
        `
            @media(hover:hover) {
                :hover {
                    color: var(--link-hover);
                    cursor: pointer;
                }
            }

            :active {
                color: var(--blue-abel);
            }
        `
    }
`;

const ExpenseMediaFakeImageMoreImagesContainer = styled.div`
    align-items: center;
    background-color: var(--white);
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
`;

const FakeDateInput = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 10px;

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

const MediaContainer = styled.div`    
    ${
        props => props.Editing ?
        `
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-right: -10px;
            margin-top: -10px;
        `
    :
        `
            display: grid;
            grid-gap: 10px;
            grid-template-columns: ${props.NumDocs === 1 ? '1fr' : '1fr 1fr'};
            margin-right: 10px;
        `
    }
`;

const MultiSelect = styled.span`
    ${props => props.Selected ? `background-image: url(${DeleteX});` : `background-image: url(${DeleteX_Gray});`}

    background-color: var(--white);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    border-radius: 50%;
    height: 20px;
    right: 5px;
    position: absolute;
    top: 5px;
    width: 20px;
    z-index: 1;

    @media(hover:hover) {
        :hover {
            background-image: url(${DeleteX});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 20px;
            border: none;
            cursor: pointer;
        }
    }
`;

const StyledButtonGroup = styled(ButtonGroup)`
    > div {
        white-space: nowrap;
    }
`;

const StyledDescriptionTextArea = styled(TextArea)`
    margin-bottom: 0px;
    margin-top: 5px;
    width: 100%;

    > textarea {
        font-family: var(--font-family-semibold);
    }
`;

const StyledExpenseMedia = styled(Image)`
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    object-fit: cover;

    ${
        props => props.Editing ?
        `
            height: 105px;
            width: 105px;
        `
    :
        `
            height: 50px;
            width: 50px;
        `
    }
`;

const StyledExternalLink = styled.a`
    margin: 5px;
`;

const StyledPriceInput = styled(Input)`
    margin-left: 5px;
    margin-right: 5px;
    max-width: 75px;
    width: 100%;

    > input {
        border-radius: 10px;
        text-indent: 0px;
    }
`;

const UploadButtonsContainer = styled(ButtonGroup)`
    justify-content: space-between;
`;

const UploadButtonsRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
`;

const DropdownStyles = {
    control: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '200px' }),
    input: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    menu: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    option: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '200px' })
};

class ExpenseListItem extends React.Component {
    state = {
        Amount: 0,
        AmountText: '',
        CurrencyId: null,
        CurrencyName: '',
        DeleteMedia: [],
        Description: '',
        DescriptionIsValidLength: true,
        DisplayingDropdown: null,
        DropdownSize: 0,
        Editing: false,
        ExpenseCategoryId: null,
        ExpenseCategoryName: '',
        ExpenseDate: '',
        ExpenseId: 0,
        ExpenseMedia: { Media: [], TotalFileSize: 0 },
        IsNewExpense: false,
        MaxFileSizesKB: 10240, // 10MB
        MediaTooLarge: 0,
        OriginalExpense: {},
        SelectedMedia: [],
        ShowChooseDatesModal: false,
        UploadMedia: []
    };

    componentDidMount() {
        var { Expense } = this.props;

        this.setState({ ...Expense, OriginalExpense: { ...Expense } });
    }

    onBlurAmount = () => {
        if (this.state.IsNewExpense) this.onSaveExpenseToParent();
    }

    onCancelEditing = () => {
        if (this.state.IsNewExpense) this.props.OnCancelNewExpense();
        else this.setState({ ...this.state, ...this.state.OriginalExpense, Editing: false });
    }

    onChangeAmount = event => {
        this.setState({ Amount: event.target.value });
    }

    onChangeCurrency = ({ CurrencyId, CurrencyName }) => {
        this.setState({ CurrencyId, CurrencyName }, () => {
            if (this.state.IsNewExpense) this.onSaveExpenseToParent();
        });
    }

    onChangeExpenseCategory = ({ ExpenseCategoryId, ExpenseCategoryName }) => {
        this.setState({ ExpenseCategoryId, ExpenseCategoryName }, () => {
            if (this.state.IsNewExpense) this.onSaveExpenseToParent();
        });
    }

    onChangeDescription = event => {
        this.setState({ Description: event.target.value }, () => {
            if (this.state.IsNewExpense) this.onSaveExpenseToParent();
        });
    }

    onChangeDescription_MaxChangeValidation = DescriptionIsValidLength => {
        this.setState({ DescriptionIsValidLength });
    }

    onDeleteExpense = () => {
        this.props.OnDeleteExpense();
    }

    onDeleteMedia = ({ event, DeleteMediaIndex, FileSize_KB, Id, IsNewMedia, MediaUrl }) => {
        if (event) event.preventDefault();

        FileSize_KB = (!!IsNewMedia || DeleteMediaIndex === -1) ? this.state.ExpenseMedia.TotalFileSize - FileSize_KB : this.state.ExpenseMedia.TotalFileSize + FileSize_KB;
        FileSize_KB = Math.round(FileSize_KB * 10) / 10;

        if (!!IsNewMedia) {
            for (var i = 0; i < this.state.SelectedMedia.length; i++) {
                if (this.state.SelectedMedia[i].MediaUrl === MediaUrl) {
                    var SelectedIndex = i;
                    break;
                }
            }

            this.setState({
                MediaTooLarge: 0,
                SelectedMedia: [ ...this.state.SelectedMedia.slice(0, SelectedIndex), ...this.state.SelectedMedia.slice(SelectedIndex + 1) ],
                UploadMedia: [ ...this.state.UploadMedia.slice(0, SelectedIndex), ...this.state.UploadMedia.slice(SelectedIndex + 1) ],
                ExpenseMedia: { ...this.state.ExpenseMedia, TotalFileSize: FileSize_KB }
            }, () => {
                if (this.state.IsNewExpense) this.onSaveExpenseToParent();
            });
        } else {
            if (DeleteMediaIndex === -1) this.setState({ DeleteMedia: [ ...this.state.DeleteMedia, Id ], MediaTooLarge: 0, ExpenseMedia: { ...this.state.ExpenseMedia, TotalFileSize: FileSize_KB } }, () => { if (this.state.IsNewExpense) this.onSaveExpenseToParent(); });
            else {
                if (FileSize_KB > this.state.MaxFileSizesKB) this.setState({ MediaTooLarge: FileSize_KB }, () => { if (this.state.IsNewExpense) this.onSaveExpenseToParent(); });
                else {
                    this.setState({
                        DeleteMedia: [ ...this.state.DeleteMedia.slice(0, DeleteMediaIndex), ...this.state.DeleteMedia.slice(DeleteMediaIndex + 1) ],
                        MediaTooLarge: 0,
                        ExpenseMedia: { ...this.state.ExpenseMedia, TotalFileSize: FileSize_KB }
                    }, () => {
                        if (this.state.IsNewExpense) this.onSaveExpenseToParent();
                    });
                }
            }
        }
    }

    onFocusCurrencies = () => {
        var { Currencies, TryingSearchCurrencies } = this.props;
        if (!Currencies.length && !TryingSearchCurrencies) this.props.SearchCurrencies();
    }

    onFocusExpenseCategories = () => {
        var { ExpenseCategories, TryingViewExpenseCategories } = this.props;
        if (!ExpenseCategories.length && !TryingViewExpenseCategories) this.props.ViewExpenseCategories();
    }

    onGetSize = Height => {
        this.setState({ DropdownSize: Height });
    }

    onInsertEditExpense = () => {
        var { ExpenseIndex, Year } = this.props;
        var { Amount, CurrencyId, CurrencyName, DeleteMedia, Description, ExpenseCategoryId, ExpenseDate, ExpenseId, IsNewExpense, UploadMedia } = this.state;

        if (!this.props.TryingInsertEditExpense) {
            if (IsNewExpense) ExpenseId = null;

            var ExpenseMedia = JSON.stringify(UploadMedia.map(({ name: FileName, size: FileSize, type: ContentType }) => ({ ContentType, FileName, FileSize })));

            this.props.InsertEditExpense({ Amount, CurrencyId, CurrencyName, DeleteMedia, Description, ExpenseCategoryId, ExpenseDate, ExpenseId, ExpenseIndex, ExpenseMedia, Year }).then(({ Expense, PreSignedUrls }) => {
                if (this.props.TryingInsertEditExpenseError) this.setState({ ...this.state, ...this.state.OriginalExpense });
                else {
                    if (UploadMedia.length > 0 && PreSignedUrls.length > 0) {
                        var UploadFunction = this.props.UploadToPresignedUrl;
                        var Promises = PreSignedUrls.map((PreSignedUrl, index) => CreateUploadToPresignedUrlPromise({ ContentType: 'Expenses', File: UploadMedia[index], PresignedFormData: PreSignedUrl, UploadFunction }));
    
                        Promise.all(Promises).then(() => {
                            if (IsNewExpense) this.props.OnCancelNewExpense(true); // Showing that a new expense is added, and that this box will close
                            else this.setState({ ...this.state, ...Expense, OriginalExpense: { ...Expense }, Editing: false, DeleteMedia: [], MediaTooLarge: 0, SelectedMedia: [], UploadMedia: [] });
                        });   
                    } else {
                        if (IsNewExpense) this.props.OnCancelNewExpense(true); // Showing that a new expense is added, and that this box will close
                        else this.setState({ ...this.state, ...Expense, OriginalExpense: { ...Expense }, Editing: false, DeleteMedia: [], MediaTooLarge: 0, SelectedMedia: [], UploadMedia: [] });
                    }
                }
            });
        }
    }

    onSaveExpenseToParent = () => {
        if (this.state.IsNewExpense) this.props.OnSaveExpenseToParent({ ...this.state });
    }

    onSelectDates = SelectedDate => {
        this.setState({ ExpenseDate: SelectedDate[0], ShowChooseDatesModal: false }, () => {
            if (this.state.IsNewExpense) this.onSaveExpenseToParent();
        });
    }

    onSelectExpenseMedia = event => {
        var FileSize = 0;
        var Files = event.target.files;
        var NumFiles = Files.length;

        var i;

        if (NumFiles > 0) {
            for (i = 0; i < NumFiles; i++) {
                FileSize += Math.round((Files[i].size / 1000) * 10) / 10;
            }

            var TotalUsedSize = FileSize + this.state.ExpenseMedia.TotalFileSize;

            if (TotalUsedSize > this.state.MaxFileSizesKB) this.setState({ MediaTooLarge: TotalUsedSize }, () => {
                if (this.state.IsNewExpense) this.onSaveExpenseToParent();
            });
            else {
                var SelectedMedia = [];

                for (i = 0; i < NumFiles; i++) {
                    SelectedMedia.push({ FileExtension: getFileExtension(Files[i].name), FileName: Files[i].name, FileSize_KB: Math.round((Files[i].size / 1000) * 10) / 10, IsNewMedia: true, MediaUrl: URL.createObjectURL(Files[i]) });
                }

                this.setState({ MediaTooLarge: 0, ExpenseMedia: { ...this.state.ExpenseMedia, TotalFileSize: TotalUsedSize }, UploadMedia: [ ...this.state.UploadMedia, ...Files ], SelectedMedia: [ ...this.state.SelectedMedia, ...SelectedMedia ] }, () => {
                    if (this.state.IsNewExpense) this.onSaveExpenseToParent();
                });
            }

            document.getElementById('ExpenseMedia').value = null;
        }
    }

    onToggleDropdown = (DisplayingDropdown, ToggleStatus) => {
        this.setState({ DisplayingDropdown: ToggleStatus ? DisplayingDropdown : null });
    }

    onToggleEditing = () => {
        this.setState({ Editing: !this.state.Editing });
    }

    onToggleShowChooseDatesModal = ShowChooseDatesModal => {
        this.setState({ ShowChooseDatesModal });
    }

    renderAmount = () => {
        var { t } = this.props;
        var { Currencies, TryingSearchCurrencies } = this.props;
        var { Amount, AmountText, CurrencyId, CurrencyName, Editing } = this.state;

        return (
            <ExpenseAmountContainer className="ExpenseAmountContainer">
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin NoWrap Text={`${t('contracts_invoice_amount')}:`} TextAlign="left" />
                
                {
                    Editing ?
                    <>
                        <StyledPriceInput FontFamily="semibold" FontSize="medium-1" NoLabel NoMargin OnBlur={this.onBlurAmount} OnChange={this.onChangeAmount} Placeholder={t('contracts_invoice_amount')} Size="small-1" Type="number" Value={Amount} />

                        &nbsp;
                        <Select
                            getOptionLabel={option => option.CurrencyName}
                            getOptionValue={option => option.CurrencyId}
                            isLoading={TryingSearchCurrencies}
                            onChange={this.onChangeCurrency}
                            onFocus={this.onFocusCurrencies}
                            options={Currencies}
                            placeholder={t('currency')}
                            styles={DropdownStyles}
                            value={CurrencyId && { CurrencyId, CurrencyName }}
                        />
                        {/* <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={CurrencyName} TextAlign="left" /> */}
                    </>
                :
                    <>
                        &nbsp;
                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={AmountText} TextAlign="left" />
                    </>
                }
            </ExpenseAmountContainer>
        );
    }

    renderDescription = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { Editing, Description } = this.state;

        if (Device !== 'laptop' && !Editing) {
            return (
                <MultiText
                    Texts={[
                        { FontFamily: "medium", FontSize: "medium-1", Text: `${t('description')}:` },
                        { FontFamily: "medium", FontSize: "medium-1", Text: Description },
                    ]}
                />
            );
        }

        return (
            <DetailsDescriptionContainer className="DetailsDescriptionContainer">
                {
                    Editing ?
                    <>
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin NoWrap Text={`${t('description')}:`} TextAlign="left" />

                        <Spacer Size="extra-extra-small" />
                        
                        <StyledDescriptionTextArea
                            OnChangeMaxCharactersValidation={this.onChangeDescription_MaxChangeValidation}
                            MaxCharacters={300}
                            NumRows={4}
                            OnBlur={this.onChangeDescription}
                            Placeholder={t('description')}
                            ShowCharacterCount
                            Value={Description}
                        />
                    </>
                :
                    <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={Description} TextAlign="left" />
                }
            </DetailsDescriptionContainer>
        );
    }

    renderDetailsContainer = () => {
        return (
            <DetailsContainer className="DetailsContainer">
                <DetailsHeaderContainer className="DetailsHeaderContainer">
                    {this.renderExpenseCategory()}

                    {this.renderExpenseDate()}

                    {this.renderAmount()}
                </DetailsHeaderContainer>

                <Spacer Size="extra-extra-small" />

                {this.renderDescription()}
            </DetailsContainer>
        );
    }

    renderExpenseCategory = () => {
        var { t } = this.props;
        var { ExpenseCategories, TryingViewExpenseCategories } = this.props;
        var { Editing, ExpenseCategoryId, ExpenseCategoryName } = this.state;

        return (
            <ExpenseCategoryContainer className="ExpenseCategoryContainer">
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin NoWrap Text={`${t('category')}:`} TextAlign="left" />
                
                &nbsp;

                {
                    Editing ?
                    <Select
                        getOptionLabel={option => option.ExpenseCategoryName}
                        getOptionValue={option => option.ExpenseCategoryId}
                        isLoading={TryingViewExpenseCategories}
                        onChange={this.onChangeExpenseCategory}
                        onFocus={this.onFocusExpenseCategories}
                        options={ExpenseCategories}
                        placeholder={t('category')}
                        styles={DropdownStyles}
                        value={ExpenseCategoryId && { ExpenseCategoryId, ExpenseCategoryName }}
                    />
                :
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={ExpenseCategoryName} TextAlign="left" />
                }
            </ExpenseCategoryContainer>
        );
    }

    renderExpenseDate = () => {
        var { t } = this.props;
        var { Editing, ExpenseDate } = this.state;

        return (
            <ExpenseDateContainer className="ExpenseDateContainer">
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin NoWrap Text={`${t('Date_str')}:`} TextAlign="left" />

                &nbsp;
                
                {
                    Editing ?
                    <FakeDateInput className="FakeDateInput" onClick={() => this.onToggleShowChooseDatesModal(true)}>
                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={ExpenseDate ? moment.utc(ExpenseDate).format('[NumbersDate]') : t('choose_date')} TextAlign="left" />
                    </FakeDateInput>
                :
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={moment.utc(ExpenseDate).format('[NumbersDate]')} TextAlign="left" />
                }
            </ExpenseDateContainer>
        );
    }

    renderMediaContainer = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { Editing, ExpenseMedia, MaxFileSizesKB, MediaTooLarge, SelectedMedia } = this.state;

        var Media = [ ...ExpenseMedia.Media ];

        if (!Editing) {
            var TotalMedia = Media.length;

            return (
                <MediaContainer className="MediaContainer" Editing={Editing} NumDocs={TotalMedia}>
                    {
                        TotalMedia > 1 &&
                        <ExpenseMediaContainer className="ExpenseMediaContainer">
                            <ExpenseMediaFakeImageMoreImagesContainer className="ExpenseMediaFakeImageMoreImagesContainer">
                                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={`${TotalMedia - 1}+`} TextAlign="center" />
                            </ExpenseMediaFakeImageMoreImagesContainer>
                        </ExpenseMediaContainer>
                    }
                    {
                        Media.slice(0, 1).map(({ FileExtension, Id, MediaUrl }) => {
                            FileExtension = FileExtension || getFileExtension(MediaUrl);
                            switch (FileExtension) {
                                case 'png':
                                case 'jpg':
                                case 'jpeg':
                                    return (
                                        <ExpenseMediaContainer key={Id} className="ExpenseMediaContainer">
                                            <StyledExpenseMedia Editing={Editing} ImageSrc={MediaUrl} SelfLoading />
                                        </ExpenseMediaContainer>
                                    );
                    
                                case 'pdf':
                                    return (
                                        <ExpenseMediaEditingContainer className="ExpenseMediaEditingContainer" key={Id} IsPreview>
                                            <ButtonIcon src={PDF} alt="Button icon" IsPreview />
                                            {/* <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={FileName} TextAlign="left" /> */}
                                            {/* <MultiSelect onClick={() => this.onDeleteMedia({ DeleteMediaIndex, FileSize_KB, Id, IsNewMedia, MediaUrl })} Selected={IsDeletedSelected} /> */}
                                        </ExpenseMediaEditingContainer>
                                    )
                    
                                default:
                                    return (
                                        <ExpenseMediaContainer key={Id} className="ExpenseMediaContainer">
                                            <StyledExpenseMedia Editing={Editing} ImageSrc={MediaUrl} SelfLoading />
                                        </ExpenseMediaContainer>
                                    );
                            }
                        })
                    }
                </MediaContainer>
            );
        }

        var Buttons = [{ BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: Camera, IconPosition: 'left', IsInput: true, InputProps: { accept: "image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg,application/pdf,.pdf", id: "ExpenseMedia", multiple: "multiple", type: "file", onChange: this.onSelectExpenseMedia }, Title: t('Upload') }];

        MaxFileSizesKB = MaxFileSizesKB / 1024;

        var Documents = [ ...Media, ...SelectedMedia ];

        return (
            <>
                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('upload_media')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <UploadButtonsRow className="UploadButtonsRow">
                    <UploadButtonsContainer
                        Buttons={Buttons}
                        ButtonWidth="fit-content"
                        ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                        NotTouching
                        OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                    />
                </UploadButtonsRow>

                <Spacer Size="extra-small" />
                
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${(Math.round(((ExpenseMedia.TotalFileSize) / 1024) * 100) / 100)}MB / ${MaxFileSizesKB}MB ${t('used')}`} TextAlign="center" />

                <Spacer Size="extra-small" />
                
                {
                    !!+MediaTooLarge &&
                    <PageText
                        ContainerWidth="100%"
                        FontColor="red-bittersweet"
                        FontFamily="medium-italic"
                        FontSize="medium-1"
                        JustifyContent="center"
                        Text={t('file_too_large', { MaxUploadSize: `${MaxFileSizesKB}MB`, UploadedSize: `${(Math.round(((MediaTooLarge) / 1024) * 100) / 100)}MB` })}
                        TextAlign="center"
                    />
                }

                <MediaContainer className="MediaContainer" Editing={Editing} NumDocs={Documents.length}>
                    {Documents.map(Document => this.renderMediaPreview({ Document }))}
                </MediaContainer>
            </>
        );
    }

    renderMediaPreview = ({ Document: { Id, FileExtension, FileName, FileSize_KB, IsNewMedia, MediaUrl } }) => {
        FileExtension = FileExtension || getFileExtension(MediaUrl);
        IsNewMedia = IsNewMedia || false;

        if (FileName.length > 30) FileName = `${FileName.substring(0, 22)}...${FileName.substring(FileName.length - 5)}`;

        var { DeleteMedia, Editing } = this.state;

        var DeleteMediaIndex = DeleteMedia.indexOf(Id);
        var IsDeletedSelected = DeleteMediaIndex !== -1;

        switch (FileExtension) {
            case 'png':
            case 'jpg':
            case 'jpeg':
                return (
                    <StyledExternalLink key={MediaUrl} href={MediaUrl} target="_new">
                        <ExpenseMediaEditingContainer className="ExpenseMediaEditingContainer" IsDeletedSelected={IsDeletedSelected} NoBorder>
                            <StyledExpenseMedia Editing={Editing} ImageSrc={MediaUrl} SelfLoading />
                            <MultiSelect onClick={event => this.onDeleteMedia({ event, DeleteMediaIndex, FileSize_KB, Id, IsNewMedia, MediaUrl })} Selected={IsDeletedSelected} />
                        </ExpenseMediaEditingContainer>
                    </StyledExternalLink>
                );

            case 'pdf':
                if (IsNewMedia) {
                    return (
                        <ExpenseMediaEditingContainer key={MediaUrl} IsDeletedSelected={IsDeletedSelected} IsNewMedia={IsNewMedia}>
                            <ButtonIcon src={PDF} alt="Button icon" />
                            <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={FileName} TextAlign="left" />
                            <MultiSelect onClick={() => this.onDeleteMedia({ DeleteMediaIndex, FileSize_KB, Id, IsNewMedia, MediaUrl })} Selected={IsDeletedSelected} />
                        </ExpenseMediaEditingContainer>
                    )
                }
                return (
                    <StyledExternalLink key={MediaUrl} href={MediaUrl} target="_new">
                        <ExpenseMediaEditingContainer IsDeletedSelected={IsDeletedSelected} IsNewMedia={IsNewMedia}>
                            <ButtonIcon src={PDF} alt="Button icon" />
                            <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={FileName} TextAlign="left" />
                            <MultiSelect onClick={event => this.onDeleteMedia({ event, DeleteMediaIndex, FileSize_KB, Id, IsNewMedia, MediaUrl })} Selected={IsDeletedSelected} />
                        </ExpenseMediaEditingContainer>
                    </StyledExternalLink>
                );

            default:
                return (
                    <StyledExternalLink key={MediaUrl} href={MediaUrl} target="_new">
                        <ExpenseMediaEditingContainer IsDeletedSelected={IsDeletedSelected} IsNewMedia={IsNewMedia}>
                            <ButtonIcon src={PDF} alt="Button icon" />
                            <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={FileName} TextAlign="left" />
                            <MultiSelect onClick={event => this.onDeleteMedia({ event, DeleteMediaIndex, FileSize_KB, Id, IsNewMedia, MediaUrl })} Selected={IsDeletedSelected} />
                        </ExpenseMediaEditingContainer>
                    </StyledExternalLink>
                )
        }
    }

    renderOptions = () => {
        var { t } = this.props;
        var { Amount, CurrencyId, Description, DescriptionIsValidLength, Editing, ExpenseCategoryId, ExpenseDate } = this.state;

        var Buttons = [];

        if (Editing) {
            var Disabled = false;
            if (!Amount || !CurrencyId || !Description || !DescriptionIsValidLength || !ExpenseCategoryId || !ExpenseDate) Disabled = true;

            Buttons.push({ BackgroundColor: Disabled ? 'white-concrete' : 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Color: Disabled ? 'black' : 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', FontSize: 'small', OnClick: this.onInsertEditExpense, Title: t('save_with_first_char_uppercase') });
            Buttons.push({ BackgroundColor: 'black', BackgroundColorHover: 'black-hover', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', FontSize: 'small', OnClick: this.onCancelEditing, Title: t('Cancel_with_first_char_uppercase') });
        } else {
            Buttons.push({ BackgroundColor: 'white', BackgroundColorHover: 'blue-abel', Icon: Pencil, IconPosition: 'top', IconSize: { Height: '15px', Width: '15px' }, OnClick: this.onToggleEditing, Size: 'small' });
            Buttons.push({ BackgroundColor: 'white', BackgroundColorHover: 'red-bittersweet', Icon: DeleteX, IconPosition: 'top', IconSize: { Height: '15px', Width: '15px' }, OnClick: this.onDeleteExpense, Size: 'small' });
        }
        
        return <StyledButtonGroup Buttons={Buttons} ButtonWidth="fit-content" ContainerWidth="fit-content" NotTouching />;
    }

    renderShowChooseDatesModal = () => {
        var { ExpenseDate, ShowChooseDatesModal } = this.state;

        if (!!ExpenseDate) ExpenseDate = moment.utc(ExpenseDate).format('YYYY-MM-DD');

        if (ShowChooseDatesModal) {
            return (
                <ChooseDates
                    MaxDates={1}
                    OnHide={() => this.onToggleShowChooseDatesModal(false)}
                    OnSelectDates={this.onSelectDates}
                    SelectedDates={[ ExpenseDate ]}
                    Show={ShowChooseDatesModal}
                />
            );
        }
    }

    render() {
        var { Device, UploadProgress } = this.props;
        var { Editing } = this.state;

        return (
            <>
                <ExpenseContainer className="ExpenseContainer" Editing={Editing}>
                    {this.renderDetailsContainer()}

                    {(Editing || Device !== 'laptop') && <Spacer Size="small" />}

                    {this.renderMediaContainer()}

                    {(Editing || Device !== 'laptop') && <Spacer Size="small" />}

                    {this.renderOptions()}

                    {
                        UploadProgress.Expenses && Object.entries(UploadProgress.Expenses).length > 0 && 
                            <>
                                <Spacer Size="medium" />

                                <UploadProgressComponent UploadingItems={UploadProgress.Expenses} />
                            </>
                    }
                </ExpenseContainer>

                {this.renderShowChooseDatesModal()}
            </>
        );
    }
}

ExpenseListItem.propTypes = {
    Expense: PropTypes.object,
    ExpenseIndex: PropTypes.number,
    OnCancelNewExpense: PropTypes.func,
    OnDeleteExpense: PropTypes.func,
    Year: PropTypes.number
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        Currencies: state.Sales.Currencies,
        ExpenseCategories: state.Sales.ExpenseCategories,

        UploadProgress: state.UploadContent.UploadProgress,

        TryingInsertEditExpense: state.Sales.TryingInsertEditExpense,
        TryingInsertEditExpenseError: state.Sales.TryingInsertEditExpenseError,
        TryingSearchCurrencies: state.Sales.TryingSearchCurrencies,
        TryingSearchCurrenciesError: state.Sales.TryingSearchCurrenciesError,
        TryingViewExpenseCategories: state.Sales.TryingViewExpenseCategories,
        TryingViewExpenseCategoriesError: state.Sales.TryingViewExpenseCategoriesError
    };
};

export default withTranslation()(connect(mapStateToProps, { InsertEditExpense, SearchCurrencies, UploadToPresignedUrl, ViewExpenseCategories })(ExpenseListItem));