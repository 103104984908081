import styled from 'styled-components';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';

export const BodyContainer = styled.div`
    margin: 0 5%;
`;

export const BottomButton = styled(ButtonGroup)`
    > div {
        :first-child {
            border-top-left-radius: 0px
        }

        :last-child {
            border-top-right-radius: 0px;
        }
    }
`;

export const BranchSelectionContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const ExtraSpaceDiv = styled.div`
    height: ${props => props.MenuHeight}px;
`;

export const OrganizationsListContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const OrganizationsListOrgContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border: 2px solid ${props => !!props.Selected ? 'var(--abel-blue)' : 'var(--white-concrete)'};
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 500px;
    padding: 10px;
    width: 100%;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete-hover);
            cursor: pointer;
        }
    }
`;

export const OrganizationsListOrgContainerNone = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 500px;
    padding: 10px;
    width: 100%;

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete-hover);
            cursor: pointer;
        }
    }
`;