import i18n from "i18next";
import ChainedBackend from "i18next-chained-backend";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import LocalStorageBackend from "i18next-localstorage-backend";

// 2 minutes
const timeToLive = 30 * 60 * 1000;

const translationToken = process.env.REACT_APP_TRANSLATION_SYSTEM_TOKEN;

if (!translationToken) {
  throw new Error("Missing translation system token");
}

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS: "all",
    lng: "en",
    fallbackLng: "en",
    returnEmptyString: false,
    returnNull: false,
    backend: {
      backends: [ LocalStorageBackend, Backend ],
      backendOptions: [
        {
          expirationTime: timeToLive,
        },
        {
          loadPath: `${process.env.REACT_APP_TRANSLATION_API_URL}api/translations/legacy-web/all/{{lng}}/file/`,
          requestOptions: {
            cache: "no-store",
          },
          customHeaders: {
            Authorization: `Token ${translationToken}`,
          },
          expirationTime: timeToLive,
        },
      ],
    },
    react: {
        useSuspense: false
    }
  });

export default i18n;