import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { LoginUser } from '../../Services/Actions';
import history from '../../history';
import jwt from 'jsonwebtoken';
import { Link } from 'react-router-dom';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Input from '../../Components/Form/Input';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import styled from 'styled-components';
import { device } from '../../Config/device';
import LoginBackground from '../../Assets/Illustrations/LoginBackground.png';

import { emailValidator } from '../../Functions';

const Container = styled.div`
    background: var(--light-gray) url(${LoginBackground}) no-repeat center center / cover;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px;
    max-height: 100%;
    max-width: 80%;
    min-height: 845px;
    position: relative;
    width: 1162px;

    @media (max-width: 767px) {
        border-radius: 0px;
        margin: 0
        max-width: none;
        width: 100%;
    }
`;

const InnerContainer = styled.div`
    margin: 100px auto;
    max-width: 510px;
    width: 90%;

    @media ${device.minwidth.ipad} {
        margin: 50px auto;
        width: 100%;
    }
`;

const LinkContainer = styled(Link)`
    color: var(--blue-abel);
    
    @media(hover:hover) {
        :hover {
            color: var(--link-hover);
            cursor: pointer;
        }
    }

    :active {
        color: var(--blue-abel);
    }
`;

const StyledInput = styled(Input)`
    > input {
        background-color: var(--white-concrete);
        border: 2px solid var(--white-concrete);
        border-radius: 10px;
        text-align: left;
        text-indent: 0px;
    }
`;

class AppleCallback extends React.Component {
    state = { AppleId: null, Email: '', IsEmail: false, NeedsEmail: false }

    componentDidMount() {
        var hash = window.location.hash.substring(1);
        var params = {};

        if (hash) {
            hash.split('&').map(hk => { 
                let temp = hk.split('='); 
                params[temp[0]] = temp[1];
                
                return null;
            });

            console.log('params: ', params);

            if (params.error) history.push('/login');
            else {
                var { sub: AppleId, email: Email } = jwt.decode(params.id_token);

                console.log('AppleId: ', AppleId);
                console.log('Email: ', Email);

                if (!Email) this.setState({ AppleId, NeedsEmail: true });
                else this.props.LoginUser({ AppleId, Email });
            }
        }
        else history.push('/login');
    }

    onChangeInput = (event, InputName, InputValue) => {
        var { IsEmail } = this.state;
        if (InputName === 'Email') IsEmail = emailValidator(event.target.value);

        var NewValue = '';
        if (event) NewValue = event.target.value;
        else NewValue = InputValue;

        this.setState({ [`${InputName}`]: NewValue, IsEmail });
    }

    onKeyPress = event => {
        if (event.key === 'Enter' && !this.props.TryingLoginUser) {
            var { AppleId, Email, IsEmail } = this.state;

            if (AppleId && Email && IsEmail) this.props.LoginUser({ AppleId, Email });
        }
    }

    renderConfirmEmailButton = () => {
        var { t } = this.props;
        var { AppleId, Email, IsEmail } = this.state;

        var Disabled = false;
        if (!Email || !IsEmail) Disabled = true;

        var Buttons = [{ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', FontSize: 'medium-2', OnClick: Disabled ? null : () => this.props.LoginUser({ AppleId, Email }), Size: 'large', Title: t('login_with_first_char_uppercase') }];

        return <ButtonGroup Buttons={Buttons} />;
    }

    renderTryAgainLink = () => {
        var { t } = this.props;

        return (
            <LinkContainer to={`/login`}>
                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('try_again_login')} TextAlign="center" />
            </LinkContainer>
        );
    }

    render() {
        if (this.props.TryingLoginUserError) {
            return (
                <Container className="Container">
                    <InnerContainer className="InnerContainer">
                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" Text={this.props.TryingLoginUserError} TextAlign="center" />

                        <Spacer Size="medium" />

                        {this.renderTryAgainLink()}
                    </InnerContainer>
                </Container>
            );
        }

        if (this.state.NeedsEmail) {
            var { t } = this.props;
            
            return (
                <Container className="Container">
                    <InnerContainer className="InnerContainer">
                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" Text={t('confirm_email_apple')} TextAlign="center" />

                        <Spacer Size="medium" />

                        <StyledInput
                            AutoComplete="email"
                            FontSize="medium-2"
                            NoLabel
                            OnChange={event => this.onChangeInput(event, 'Email')}
                            OnKeyPress={this.onKeyPress}
                            Placeholder={t('email_with_first_char_uppercase')}
                            Size="extra-large"
                            Type="text"
                            Value={this.state.Email}
                        />

                        <Spacer Size="medium" />

                        {this.renderConfirmEmailButton()}
                    </InnerContainer>
                </Container>
            );
        }

        return <Loading />;
    }
}

const mapStateToProps = state => {
    return {
        TryingLoginUser: state.Auth.TryingLoginUser,
        TryingLoginUserError: state.Auth.TryingLoginUserError
    };
};

export default withTranslation()(connect(mapStateToProps, { LoginUser } )(AppleCallback));