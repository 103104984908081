import React from 'react';
import styled from 'styled-components';

import "./styles.css";

const Container = styled.svg`
    height: 20px;
    display: block;
    transform: rotate(-90deg);
`;

class LoadingCircle extends React.Component {
    render() {
        return (
            <Container className={this.props.className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="45" pathLength="1"/>
            </Container>
        );
    }
}

export default LoadingCircle;