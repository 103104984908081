import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Select, { components } from 'react-select';

import * as styles from './styles';

import MultiText from '../Text/MultiText';
import PageText from '../Text/PageText';
import PopoverItem from '../Popover';
import Spacer from '../Spacer';

import Info from '../../Modals/Info';

import Delete_White from '../../Assets/Icons/X_White.png';
import LanguageIcon from '../../Assets/Icons/Globe.png';

const DropdownStyles = {
    control: (styles, { isDisabled }) => ({ ...styles, backgroundColor: 'var(--white-concrete)', border: 'none', cursor: isDisabled ? 'not-allowed' : 'default', height: '45px', maxWidth: '100%', width: '200px' }),
    input: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    menu: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    option: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '200px' })
};

const CustomOption = ({ children, isDisabled, ...rest }) => {
    if (isDisabled) return null;

    return <components.Option {...rest}>{children}</components.Option>;
}

class TranslationsComponent extends React.Component {
    state = { ShowManageLanguagesModal: false, OnlyShowDefault: true };

    onToggleShowManageLanguagesModal = ShowManageLanguagesModal => {
        if (!!ShowManageLanguagesModal) this.setState({ ShowManageLanguagesModal, OnlyShowDefault: false });
        else this.setState({ ShowManageLanguagesModal, OnlyShowDefault: true });
    }
    
    renderExerciseTranslation = ({ key = null, ExerciseInstructions, ExerciseName, LanguageId, LanguageName, LastLanguage }) => {
        var { t } = this.props;
        var { DefaultLanguageId, Languages, OnChangeDefault, OnChangeLanguage, OnChangeTranslation, OnDeleteLanguage, Translations } = this.props;
        var { OnlyShowDefault, ShowManageLanguagesModal } = this.state;

        var IsDefaultLanguage = +DefaultLanguageId === +LanguageId;

        return (
            <styles.TranslationContainer className="TranslationContainer" key={key} FullWidth={!!ShowManageLanguagesModal}>
                <styles.LanguageSelectorRow className="LanguageSelectorRow">
                    <styles.LeftContainer className="LeftContainer">
                        {
                            !!OnlyShowDefault ?
                            <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={LanguageName} TextAlign="left" />
                        :
                            <Select
                                getOptionLabel={option => option.LanguageName}
                                getOptionValue={option => option.LanguageId}
                                onChange={NewLanguage => OnChangeLanguage({ NewLanguage, TranslationKey: key })}
                                components={{ Option: CustomOption }}
                                options={Languages}
                                isOptionDisabled={option => option.LanguageId !== LanguageId && Translations.hasOwnProperty(option.LanguageId)}
                                placeholder={t('select_language')}
                                styles={DropdownStyles}
                                value={{ LanguageId, LanguageName }}
                            />
                        }

                        {
                            !OnlyShowDefault &&
                            <styles.RadioButtonParentContainer>
                                <styles.RadioButtonContainer onClick={IsDefaultLanguage ? () => null : () => OnChangeDefault(LanguageId)} Selected={!!IsDefaultLanguage} />
                                
                                <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={IsDefaultLanguage ? () => null : () => OnChangeDefault(LanguageId)} Text={t('default_language')} TextAlign="left" />

                                {IsDefaultLanguage && <PopoverItem Text={t('default_language_info')} />}
                            </styles.RadioButtonParentContainer>
                        }
                    </styles.LeftContainer>

                    {
                        !!OnlyShowDefault ?
                        <>
                            <styles.StyledAddLanguageButtonGroup
                                Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: LanguageIcon, IconPosition: 'left', OnClick: () => this.onToggleShowManageLanguagesModal(true), Title: Object.entries(Translations).length > 1 ? t('manage_languages', { LanguageCount: Object.entries(Translations).length }) : t('add_another_language') }]}
                                ButtonWidth="fit-content"
                            />
                        </>
                    :
                        <>
                            {
                                !IsDefaultLanguage &&
                                <styles.StyledDeleteLanguageButton
                                    Buttons={[{ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Icon: Delete_White, IconOnly: true, IconPosition: 'left', OnClick: () => OnDeleteLanguage(LanguageId), Title: '' }]}
                                    ButtonWidth="fit-content"
                                />
                            }
                        </>
                    }
                </styles.LanguageSelectorRow>

                <Spacer Size="small" />

                <styles.StyledInputContainer className="StyledInputContainer">
                    <MultiText
                        TextAlign="left"
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('add_name') },
                            { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-1', Text: '*' },
                        ]}
                    />

                    <Spacer Size="extra-extra-small" />

                    <styles.StyledInput
                        NoLabel
                        NoMargin
                        OnChange={event => OnChangeTranslation({ event, InputName: 'ExerciseName', LanguageId })}
                        Placeholder={t('add_name')}
                        Size="medium"
                        Type="text"
                        Value={ExerciseName}
                    />
                </styles.StyledInputContainer>

                <Spacer Size="small" />

                <styles.StyledInputContainer className="StyledInputContainer">
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('add_instructions')} TextAlign="left" />

                    <Spacer Size="extra-extra-small" />

                    <styles.StyledTextArea
                        ControlledComponent
                        OnChange={event => OnChangeTranslation({ event, InputName: 'ExerciseInstructions', LanguageId })}
                        NumRows={4}
                        Placeholder={t('add_instructions')}
                        Value={ExerciseInstructions}
                    />
                </styles.StyledInputContainer>

                {!LastLanguage && !OnlyShowDefault && <Spacer Size="extra-large" />}
            </styles.TranslationContainer>
        );
    }

    renderExercise = ({ DefaultView }) => {
        var { DefaultLanguageId, Languages, Translations } = this.props;
        var  { OnlyShowDefault } = this.state;

        // Loop Through Translations Object And Show Translation For Each
            if (Object.entries(Translations).length) {
                if (!!OnlyShowDefault || !!DefaultView) {
                    var { LanguageId, LanguageName } = Languages[DefaultLanguageId - 1];
                    var { ExerciseInstructions, ExerciseName } = Translations[`${DefaultLanguageId}`];

                    return this.renderExerciseTranslation({ ExerciseInstructions, ExerciseName, LanguageId, LanguageName });
                }

                return Object.keys(Translations).map((key, index) => {
                    var { LanguageId, LanguageName } = Languages[key - 1];
                    var { ExerciseInstructions, ExerciseName } = Translations[key];

                    var LastLanguage = index === Object.entries(Translations).length - 1;

                    return this.renderExerciseTranslation({ key, ExerciseInstructions, ExerciseName, LanguageId, LanguageName, LastLanguage });
                });
            }

        return null;
    }

    renderInformation = ({ DefaultView = false }) => {
        var { t } = this.props;
        var { ContentType, Languages, OnAddLanguage, Translations } = this.props;
        var  { OnlyShowDefault } = this.state;

        return (
            <>
                {ContentType === 'Exercise' && this.renderExercise({ DefaultView })}
                {ContentType === 'Status' && this.renderStatus({ DefaultView })}
                {ContentType === 'Task' && this.renderTask({ DefaultView })}

                {
                    !OnlyShowDefault && Object.entries(Translations).length < Languages.length &&
                    <>
                        <Spacer Size="medium" />

                        <styles.StyledAddLanguageButtonGroup
                            Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: LanguageIcon, IconPosition: 'left', OnClick: OnAddLanguage, Title: t('add_another_language') }]}
                            ButtonWidth="fit-content"
                        />
                    </>
                }
            </>
        );
    }

    renderShowManageLanguagesModal = () => {
        var { ShowManageLanguagesModal } = this.state;

        if (ShowManageLanguagesModal) {
            return (
                <Info
                    BottomButton="cancel"
                    BottomButtonOnClick={() => this.onToggleShowManageLanguagesModal(false)}
                    InformationRenderer={() => this.renderInformation({})}
                    OnHide={() => this.onToggleShowManageLanguagesModal(false)}
                    Show={ShowManageLanguagesModal}
                    Size="large"
                />
            );
        }
    }

    renderStatus = ({ DefaultView }) => {
        var { DefaultLanguageId, Languages, Translations } = this.props;
        var  { OnlyShowDefault } = this.state;

        // Loop Through Translations Object And Show Translation For Each
            if (Object.entries(Translations).length) {
                if (!!OnlyShowDefault || !!DefaultView) {
                    var { LanguageId, LanguageName } = Languages[DefaultLanguageId - 1];
                    var { Description, StatusName } = Translations[`${DefaultLanguageId}`];

                    return this.renderStatusTranslation({ Description, StatusName, LanguageId, LanguageName });
                }

                return Object.keys(Translations).map((key, index) => {
                    var { LanguageId, LanguageName } = Languages[key - 1];
                    var { Description, StatusName } = Translations[key];

                    var LastLanguage = index === Object.entries(Translations).length - 1;

                    return this.renderStatusTranslation({ key, Description, StatusName, LanguageId, LanguageName, LastLanguage });
                });
            }

        return null;
    }
    
    renderStatusTranslation = ({ key = null, Description, StatusName, LanguageId, LanguageName, LastLanguage }) => {
        var { t } = this.props;
        var { DefaultLanguageId, Languages, OnChangeDefault, OnChangeLanguage, OnChangeTranslation, OnDeleteLanguage, Translations } = this.props;
        var { OnlyShowDefault, ShowManageLanguagesModal } = this.state;

        var IsDefaultLanguage = +DefaultLanguageId === +LanguageId;

        return (
            <styles.TranslationContainer className="TranslationContainer" key={key} FullWidth={!!ShowManageLanguagesModal}>
                <styles.LanguageSelectorRow className="LanguageSelectorRow">
                    <styles.LeftContainer className="LeftContainer">
                        {
                            !!OnlyShowDefault ?
                            <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={LanguageName} TextAlign="left" />
                        :
                            <Select
                                getOptionLabel={option => option.LanguageName}
                                getOptionValue={option => option.LanguageId}
                                onChange={NewLanguage => OnChangeLanguage({ NewLanguage, TranslationKey: key })}
                                components={{ Option: CustomOption }}
                                options={Languages}
                                isOptionDisabled={option => option.LanguageId !== LanguageId && Translations.hasOwnProperty(option.LanguageId)}
                                placeholder={t('select_language')}
                                styles={DropdownStyles}
                                value={{ LanguageId, LanguageName }}
                            />
                        }

                        {
                            !OnlyShowDefault &&
                            <styles.RadioButtonParentContainer>
                                <styles.RadioButtonContainer onClick={IsDefaultLanguage ? () => null : () => OnChangeDefault(LanguageId)} Selected={!!IsDefaultLanguage} />
                                
                                <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={IsDefaultLanguage ? () => null : () => OnChangeDefault(LanguageId)} Text={t('default_language')} TextAlign="left" />

                                {IsDefaultLanguage && <PopoverItem Text={t('default_language_info')} />}
                            </styles.RadioButtonParentContainer>
                        }
                    </styles.LeftContainer>

                    {
                        !!OnlyShowDefault ?
                        <>
                            <styles.StyledAddLanguageButtonGroup
                                Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: LanguageIcon, IconPosition: 'left', OnClick: () => this.onToggleShowManageLanguagesModal(true), Title: Object.entries(Translations).length > 1 ? t('manage_languages', { LanguageCount: Object.entries(Translations).length }) : t('add_another_language') }]}
                                ButtonWidth="fit-content"
                            />
                        </>
                    :
                        <>
                            {
                                !IsDefaultLanguage &&
                                <styles.StyledDeleteLanguageButton
                                    Buttons={[{ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Icon: Delete_White, IconOnly: true, IconPosition: 'left', OnClick: () => OnDeleteLanguage(LanguageId), Title: '' }]}
                                    ButtonWidth="fit-content"
                                />
                            }
                        </>
                    }
                </styles.LanguageSelectorRow>

                <Spacer Size="small" />

                <styles.StyledInputContainer className="StyledInputContainer">
                    <MultiText
                        TextAlign="left"
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('status_title') },
                            { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-1', Text: '*' },
                        ]}
                    />

                    <Spacer Size="extra-extra-small" />

                    <styles.StyledTextArea
                        ControlledComponent
                        OnChange={event => OnChangeTranslation({ event, InputName: 'StatusName', LanguageId })}
                        MaxCharacters={30}
                        NumRows={1}
                        Placeholder={t('status_title')}
                        ShowCharacterCount
                        ShowExtraWillBeRemoved
                        Value={StatusName}
                    />
                </styles.StyledInputContainer>

                <Spacer Size="small" />

                <styles.StyledInputContainer className="StyledInputContainer">
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('status_description')} TextAlign="left" />

                    <Spacer Size="extra-extra-small" />

                    <styles.StyledTextArea
                        ControlledComponent
                        OnChange={event => OnChangeTranslation({ event, InputName: 'Description', LanguageId })}
                        MaxCharacters={200}
                        NumRows={4}
                        Placeholder={t('status_description')}
                        ShowCharacterCount
                        ShowExtraWillBeRemoved
                        Value={Description}
                    />
                </styles.StyledInputContainer>

                {!LastLanguage && !OnlyShowDefault && <Spacer Size="extra-large" />}
            </styles.TranslationContainer>
        );
    }

    renderTask = ({ DefaultView }) => {
        var { DefaultLanguageId, Languages, Translations } = this.props;
        var  { OnlyShowDefault } = this.state;

        // Loop Through Translations Object And Show Translation For Each
            if (Object.entries(Translations).length) {
                if (!!OnlyShowDefault || !!DefaultView) {
                    var { LanguageId, LanguageName } = Languages[DefaultLanguageId - 1];
                    var { Description, TaskName } = Translations[`${DefaultLanguageId}`];

                    return this.renderTaskTranslation({ Description, TaskName, LanguageId, LanguageName });
                }

                return Object.keys(Translations).map((key, index) => {
                    var { LanguageId, LanguageName } = Languages[key - 1];
                    var { Description, TaskName } = Translations[key];

                    var LastLanguage = index === Object.entries(Translations).length - 1;

                    return this.renderTaskTranslation({ key, Description, TaskName, LanguageId, LanguageName, LastLanguage });
                });
            }

        return null;
    }
    
    renderTaskTranslation = ({ key = null, Description, TaskName, LanguageId, LanguageName, LastLanguage }) => {
        var { t } = this.props;
        var { DefaultLanguageId, Languages, OnChangeDefault, OnChangeLanguage, OnChangeTranslation, OnDeleteLanguage, Translations } = this.props;
        var { OnlyShowDefault, ShowManageLanguagesModal } = this.state;

        var IsDefaultLanguage = +DefaultLanguageId === +LanguageId;

        return (
            <styles.TranslationContainer className="TranslationContainer" key={key} FullWidth={!!ShowManageLanguagesModal}>
                <styles.LanguageSelectorRow className="LanguageSelectorRow">
                    <styles.LeftContainer className="LeftContainer">
                        {
                            !!OnlyShowDefault ?
                            <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={LanguageName} TextAlign="left" />
                        :
                            <Select
                                getOptionLabel={option => option.LanguageName}
                                getOptionValue={option => option.LanguageId}
                                onChange={NewLanguage => OnChangeLanguage({ NewLanguage, TranslationKey: key })}
                                components={{ Option: CustomOption }}
                                options={Languages}
                                isOptionDisabled={option => option.LanguageId !== LanguageId && Translations.hasOwnProperty(option.LanguageId)}
                                placeholder={t('select_language')}
                                styles={DropdownStyles}
                                value={{ LanguageId, LanguageName }}
                            />
                        }

                        {
                            !OnlyShowDefault &&
                            <styles.RadioButtonParentContainer>
                                <styles.RadioButtonContainer onClick={IsDefaultLanguage ? () => null : () => OnChangeDefault(LanguageId)} Selected={!!IsDefaultLanguage} />
                                
                                <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={IsDefaultLanguage ? () => null : () => OnChangeDefault(LanguageId)} Text={t('default_language')} TextAlign="left" />

                                {IsDefaultLanguage && <PopoverItem Text={t('default_language_info')} />}
                            </styles.RadioButtonParentContainer>
                        }
                    </styles.LeftContainer>

                    {
                        !!OnlyShowDefault ?
                        <>
                            <styles.StyledAddLanguageButtonGroup
                                Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: LanguageIcon, IconPosition: 'left', OnClick: () => this.onToggleShowManageLanguagesModal(true), Title: Object.entries(Translations).length > 1 ? t('manage_languages', { LanguageCount: Object.entries(Translations).length }) : t('add_another_language') }]}
                                ButtonWidth="fit-content"
                            />
                        </>
                    :
                        <>
                            {
                                !IsDefaultLanguage &&
                                <styles.StyledDeleteLanguageButton
                                    Buttons={[{ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Icon: Delete_White, IconOnly: true, IconPosition: 'left', OnClick: () => OnDeleteLanguage(LanguageId), Title: '' }]}
                                    ButtonWidth="fit-content"
                                />
                            }
                        </>
                    }
                </styles.LanguageSelectorRow>

                <Spacer Size="small" />

                <styles.StyledInputContainer className="StyledInputContainer">
                    <MultiText
                        TextAlign="left"
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('task_title') },
                            { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-1', Text: '*' },
                        ]}
                    />

                    <Spacer Size="extra-extra-small" />

                    <styles.StyledTextArea
                        ControlledComponent
                        OnChange={event => OnChangeTranslation({ event, InputName: 'TaskName', LanguageId })}
                        MaxCharacters={30}
                        NumRows={1}
                        Placeholder={t('task_title')}
                        ShowCharacterCount
                        ShowExtraWillBeRemoved
                        Value={TaskName}
                    />
                </styles.StyledInputContainer>

                <Spacer Size="small" />

                <styles.StyledInputContainer className="StyledInputContainer">
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('task_description')} TextAlign="left" />

                    <Spacer Size="extra-extra-small" />

                    <styles.StyledTextArea
                        ControlledComponent
                        OnChange={event => OnChangeTranslation({ event, InputName: 'Description', LanguageId })}
                        MaxCharacters={200}
                        NumRows={4}
                        Placeholder={t('task_description')}
                        ShowCharacterCount
                        ShowExtraWillBeRemoved
                        Value={Description}
                    />
                </styles.StyledInputContainer>

                {!LastLanguage && !OnlyShowDefault && <Spacer Size="extra-large" />}
            </styles.TranslationContainer>
        );
    }

    render() {
        var { t } = this.props;
        var { ContentType } = this.props;

        var TitleText = '';
        if (ContentType === 'Exercise') TitleText = t('exercise_name_and_instructions');
        else if (ContentType === 'Status') TitleText = null;
        else if (ContentType === 'Task') TitleText = null;

        return (
            <>
                <styles.TranslationsContainer className="TranslationsContainer">
                    {
                        TitleText &&
                        <>
                            <PageText FontColor="blue-astronaut" FontFamily="bold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={TitleText} />
        
                            <Spacer Size="extra-small" />
                        </>
                    }

                    {this.renderInformation({ DefaultView: true })}
                </styles.TranslationsContainer>

                {this.renderShowManageLanguagesModal()}
            </>
        );
    }
}

TranslationsComponent.propTypes = {
    ContentType: PropTypes.string.isRequired,
    DefaultLanguageId: PropTypes.number.isRequired,
    OnAddLanguage: PropTypes.func.isRequired,
    OnChangeDefault: PropTypes.func.isRequired,
    OnChangeLanguage: PropTypes.func.isRequired,
    OnChangeTranslation: PropTypes.func.isRequired,
    OnDeleteLanguage: PropTypes.func.isRequired,
    Languages: PropTypes.array.isRequired,
    Translations: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        UserDetails: state.Auth.UserDetails
    };
};

export default withTranslation()(connect(mapStateToProps, null)(TranslationsComponent));