import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ChooseExerciseTags, DeleteExerciseTags, EditExerciseTag, GetAllTagsForAddingRemoving_Exercises, GetExerciseFilterSettings, InsertEditExerciseFilter, InsertExerciseTags, MoveExercisesToTrash, RecoverExercisesFromTrash, RemoveCategory_Exercise, ReorderExerciseFilters, SearchExercises_SAYT, SearchExercises, UpdateExercisesTags, UpdateShareAbilityStatus_Exercises, UpdateSharedToCoachStatus_Exercises, UpdateSharedToCoachStatusAll_Exercises } from '../../../../Services/Actions';
import history from '../../../../history';
import Popover from 'react-popover';

import * as Styles from './styles';

import AddRemoveTagsPopoverComponent from '../../../../Components/AddRemoveTagsPopover';
import ButtonGroup from '../../../../Components/Buttons/ButtonGroup';
import Checkmark from '../../../../Components/Form/Checkmark';
import CustomizeFilters from '../../CustomizeFilters';
import { ExerciseListItem } from '../../../../Components/Lists/ListItems';
import Filters from '../../Filters';
import Header from '../../Header';
import Loading from '../../../../Components/Loading';
import MultiText from '../../../../Components/Text/MultiText';
import Spacer from '../../../../Components/Spacer';
import PageText from '../../../../Components/Text/PageText';
import Toggle from '../../../../Components/Form/Toggle';
import ViewCount from '../../../../Components/ViewCount';

import EditShareAbilityStatusModal from '../../../../Modals/EditShareAbilityStatusModal';
import ExerciseDetail from '../../../../Modals/ExerciseDetail';
import HowSharingWorksModal from '../../../../Modals/HowSharingWorksModal';
import Info from '../../../../Modals/Info';
import ShareContentModal from '../../../../Modals/ShareContentModal';

import { GetTranslatedFilterName } from '../../../../Functions';

import AbelIcon from '../../../../Assets/Icons/Abel_HeaderIcon.png';
import Blocked from '../../../../Assets/Icons/Blocked.png';
import Checkmark_Selected from '../../../../Assets/Icons/Checkmark_Selected.png';
import Female from '../../../../Assets/Icons/Female.png';
import Male from '../../../../Assets/Icons/Male.png';
import Plus from '../../../../Assets/Icons/PlusSign_White.png';
import Plus_Dark from '../../../../Assets/Icons/PlusSign.png';
import Sharing_Gift from '../../../../Assets/Illustrations/Sharing_gift.png';
import { ReactComponent as FilterSVG } from '../../../../Assets/SVG/Filter.svg';
import { ReactComponent as TrashSVG } from '../../../../Assets/SVG/Trash.svg';

const INITIAL_STATE = {
    AvoidJoints: [],
    AvoidMuscles: [],
    Equipment: [],
    ExerciseName: '',
    LastSearchedValue: '',
    Movement: [],
    Plane: [],
    StartPosition: [],
    TagIds: [],
    TargetJoints: [],
    TargetMuscles: [],
    TrainingFocus: [],
    TrainingForm: [],
    PageNo: 1, PageSize: 49,
    SearchValue: '',
    Owners: [],

    OrgTags: {}
}

const SELECTED_CATEGORIES_DEFAULT = {
    AvoidJoints: [],
    AvoidMuscles: [],
    Equipment: [],
    MadeBy: [],
    Movement: [],
    Plane: [],
    StartPosition: [],
    Tags: [],
    TargetJoints: [],
    TargetMuscles: [],
    TrainingFocus: [],
    TrainingForm: [],

    OrgTags: {}
}

const GetSelectedCategories = Categories => {
    var SelectedCategories = JSON.parse(JSON.stringify(SELECTED_CATEGORIES_DEFAULT));

    for (var i = 0; i < Categories.length; i++) {
        var { IsAbel, SecondaryTagType, TagId, TagType } = Categories[i];

        if (!!IsAbel) {
            if (TagType === 'Joints' || TagType === 'Muscles') SelectedCategories[`${SecondaryTagType}${TagType}`].push(+TagId);
            else SelectedCategories[TagType].push(+TagId);
        } else SelectedCategories[TagType].push(+TagId);;
    }

    return SelectedCategories;
}

class ExerciseLibrary extends React.Component {
    _isMounted = false;

    state = {
        ...INITIAL_STATE,
        EditExerciseTagError: false,
        ForceReload: false,
        Loading: true,
        MergeTags: true,
        MultiSelectExerciseIds: [],
        MultiSelectExercises: [],
        SelectedCategories: [],
        SelectedCategoriesIndex: [],
        SelectedCategories_Modal: { ...SELECTED_CATEGORIES_DEFAULT },
        ShowAddRemoveTagsPopover: false,
        ShowAddRemoveTagsPopover_ActionType: null,
        ShowChooseCategoriesModal: false,
        ShowDeleteExercisesModal: false,
        ShowEditShareAbilityStatusModal: false,
        ShowExerciseDetailModal: false,
        ShowExerciseDetailPreview: {},
        ShowFiltersModals: false,
        ShowHowSharingWorksModal: false,
        ShowShareContentModal: false,
        ShowMultiSelect: false,
        StateLoading: false,
        TryingChooseCategories: false
    };

    componentDidMount() {
        this._isMounted = true;

        this.props.GetExerciseFilterSettings(true).then(() => {
            if (this._isMounted && !this.props.TryingGetExerciseFilterSettingsError) {
                var OrgTags = {};
                for (var i = 0; i < this.props.ExerciseFilterSettings.Filters.length; i++) {
                    var { IsOrg, TagFilterId } = this.props.ExerciseFilterSettings.Filters[i];

                    if (!+IsOrg) continue;
                    OrgTags[`${TagFilterId}`] = [];
                }

                this.setState({ DefaultOrgTags: { ...OrgTags }, OrgTags }, () => this.onLoadExercises());
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (!!prevProps.ShowCustomizeFilters && !this.props.ShowCustomizeFilters && this.state.ForceReload) {
            this.setState({ ForceReload: false });
            this.onLoadExercises();
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    onCancelMultiSelect = () => {
        this.setState({ MultiSelectExerciseIds: [], MultiSelectExercises: [], ShowMultiSelect: false });
    }

    onChooseCategories = () => {
        var Categories = this.state.SelectedCategories_Modal;

        var SelectedCategories = [];

        let IsAbel, TagType, SecondaryTagType, ContentId, TagId;

        Object.keys(Categories).map(key => {
            TagType = key;
            SecondaryTagType = null;
            ContentId = null;
            TagId = null;

            if (TagType === 'Tags') IsAbel = 0;
            else IsAbel = 1;

            if (TagType === 'AvoidJoints' || TagType === 'TargetJoints') {
                SecondaryTagType = TagType.indexOf('Avoid') > -1 ? 'Avoid' : 'Target';
                TagType = 'Joints';
            }
            else if (TagType === 'AvoidMuscles' || TagType === 'TargetMuscles') {
                SecondaryTagType = TagType.indexOf('Avoid') > -1 ? 'Avoid' : 'Target';
                TagType = 'Muscles';
            }

            for (var i = 0; i < Categories[key].length; i++) {
                if (TagType === 'Tags') TagId = Categories[key][i];
                else ContentId = Categories[key][i];

                SelectedCategories.push({ IsAbel, TagType, SecondaryTagType, ContentId, TagId })
            }

            return null;
        });

        this.setState({ TryingChooseCategories: true });
        this.props.ChooseExerciseTags({ Categories: JSON.stringify(SelectedCategories) }).then(() => {
            this.setState({ TryingChooseCategories: false });
            this.onToggleShowChooseCategoryTagsModal(false);
        });
    }

    onClearAllFilters = () => {
        var { Device } = this.props;

        this.setState({
            AvoidJoints: [],
            AvoidMuscles: [],
            Equipment: [],
            Movement: [],
            Plane: [],
            StartPosition: [],
            TagIds: [],
            TargetJoints: [],
            TargetMuscles: [],
            TrainingFocus: [],
            TrainingForm: [],
            Owners: [],

            OrgTags: { ...this.state.DefaultOrgTags }
        }, () => Device === 'laptop' && this.onLoadExercises());
    }

    onClickCreatePrivateExercise = () => {
        history.push({ pathname: '/library/exercise', state: { ReturnData: this.props.ReturnData } });
    }

    onDeleteCategory = ({ CategoryId }) => {
        var SelectedCategoryIndex = this.state.SelectedCategoriesIndex.indexOf(CategoryId);

        if (SelectedCategoryIndex !== -1) {
            var SelectedCategories = [ ...this.state.SelectedCategories.slice(0, SelectedCategoryIndex), ...this.state.SelectedCategories.slice(SelectedCategoryIndex + 1) ];
            var SelectedCategoriesIndex = [ ...this.state.SelectedCategoriesIndex.slice(0, SelectedCategoryIndex), ...this.state.SelectedCategoriesIndex.slice(SelectedCategoryIndex + 1) ];
            
            this.setState({ ForceReload: true, SelectedCategories, SelectedCategoriesIndex });
            this.props.RemoveCategory_Exercise({ CategoryId });
        }
        else this.props.RemoveCategory_Exercise({ CategoryId });
    }

    onDeleteExercises = () => {
        var { MultiSelectExerciseIds } = this.state;

        if (!MultiSelectExerciseIds.length) this.onToggleShowDeleteExercisesModal(false);
        else {
            console.log('MultiSelectExerciseIds: ', MultiSelectExerciseIds);
            // this.props.DeleteExercises({ ExerciseIds: MultiSelectExerciseIds }).then(() => {
            //     if (!this.props.TryingDeleteExercisesError) {
            //         this.setState({ MultiSelectExerciseIds: [], MultiSelectExercises: [], ShowMultiSelect: false }, () => {
            //             this.onToggleShowDeleteExercisesModal(false);
            //             this.onLoadExercises();
            //         });
            //     }
            // });
        }
    }

    onDeleteTags = ({ Tag }) => {
        var SelectedTagIndex = this.state.TagIds.indexOf(+Tag.TagId);
        if (SelectedTagIndex !== -1) this.setState({ ForceReload: true, TagIds: [ ...this.state.TagIds.slice(0, SelectedTagIndex), ...this.state.TagIds.slice(SelectedTagIndex + 1) ] });
        
        this.props.DeleteExerciseTags({ TagIds: [ Tag.TagId ] })
    }

    onDeleteToTrash = () => {
        var { MultiSelectExerciseIds } = this.state;

        this.setState({ StateLoading: true });
        this.props.MoveExercisesToTrash({ ExerciseIds: MultiSelectExerciseIds }).then(() => {
            this.setState({ MultiSelectExerciseIds: [], MultiSelectExercises: [], ShowMultiSelect: false, StateLoading: false }, () => {
                this.onLoadExercises();
            });
        });
    }

    onEditExerciseTag = ({ TagId, TagName }) => {
        return this.props.EditExerciseTag({ TagId, TagName }).then(({ IsDuplicate }) => IsDuplicate);
    }

    onInsertEditExerciseFilter = FilterSettings => {
        this.props.InsertEditExerciseFilter({ ...FilterSettings });
    }

    onInsertExerciseTags = NewTags => {
        this.props.InsertExerciseTags({ NewTags });
    }

    onLoadExercises = Increment => {
        var { AvoidJoints, AvoidMuscles, Equipment, ForceReload, Movement, NewSearch, OrgTags, PageNo, PageSize, Plane, SearchValue, SelectedCategories, StartPosition, TagIds, TargetJoints, TargetMuscles, TrainingFocus, TrainingForm, Owners } = this.state;
        var { ExcludeExercises, ExerciseFilterSettings: { HasDeleted }, ShowTrash, UserDetails: { LanguageId } } = this.props;

        var HideDeleted = !!ShowTrash ? 0 : 1;

        if (!HasDeleted && !!ShowTrash && !ForceReload) this.setState({ Loading: false });
        else {
            if (Increment) {
                NewSearch = false;
                PageNo = PageNo + 1;
            } else {
                NewSearch = true;
                PageNo = 1;
            }
    
            for (var i = 0; i < SelectedCategories.length; i++) {
                var { IsAbel, TagId, TagType } = SelectedCategories[i];
    
                if (IsAbel) {
                    if (TagType === 'Equipment') Equipment = [ ...new Set([ ...Equipment, TagId ]) ];
                    else if (TagType === 'Joints') TargetJoints = [ ...new Set([ ...TargetJoints, TagId ]) ];
                    else if (TagType === 'MadeBy') Owners = [ ...new Set([ ...Owners, TagId ]) ];
                    else if (TagType === 'Movement') Movement = [ ...new Set([ ...Movement, TagId ]) ];
                    else if (TagType === 'Muscles') TargetMuscles = [ ...new Set([ ...TargetMuscles, TagId ]) ];
                    else if (TagType === 'Plane') Plane = [ ...new Set([ ...Plane, TagId ]) ];
                    else if (TagType === 'StartPosition') StartPosition = [ ...new Set([ ...StartPosition, TagId ]) ];
                    else if (TagType === 'Tags') TagIds = [ ...new Set([ ...TagIds, TagId ]) ];
                    else if (TagType === 'TrainingFocus') TrainingFocus = [ ...new Set([ ...TrainingFocus, TagId ]) ];
                    else if (TagType === 'TrainingForm') TrainingForm = [ ...new Set([ ...TrainingForm, TagId ]) ];
                }
                else TagIds = [ ...new Set([ ...TagIds, TagId ]) ];
            }

            // Add Org Tags To Tags List
                if (!!Object.keys(OrgTags).length) {
                    var OrgTags_Keys = Object.keys(OrgTags);
                    for (i = 0; i < OrgTags_Keys.length; i++) {
                        TagIds = [ ...new Set([ ...TagIds, ...OrgTags[OrgTags_Keys[i]] ]) ]
                    }
                }
    
            var ExercisesShown = [];
    
            this.setState({ ActiveSearch: true, ForceReload: false, NewSearch, PageNo }, () => this.props.SearchExercises({ AddToExisting: Increment, AvoidJoints, AvoidMuscles, Equipment, ExcludeExercises, ExerciseName: SearchValue, ExercisesShown, HasDeleted, HideDeleted, LanguageId, Movement, PageNo, PageSize, Plane, ShowTrash, StartPosition, TagIds, TargetJoints, TargetMuscles, TrainingFocus, TrainingForm, Owners }).then(() => this._isMounted && this.setState({ ActiveSearch: false, CompletedFirstSearch: true, Loading: false })));
        }
    }

    onMultiSelect = (Exercise, MultiSelectIndex) => {
    //     if (this.props.IsModal) this.props.OnMultiSelect(Exercise, MultiSelectIndex);
    //     else {
            if (MultiSelectIndex === -1) {
                if (this.props.MaxExercises === 1) this.setState({ MultiSelectExercises: [ Exercise ], MultiSelectExerciseIds: [ +Exercise.ExerciseId ]})
                else {
                    this.setState({
                        MultiSelectExercises: [ ...this.state.MultiSelectExercises, Exercise ],
                        MultiSelectExerciseIds: [ ...this.state.MultiSelectExerciseIds, Number(Exercise.ExerciseId)]
                    });
                }
            }
            else {
                this.setState({
                    MultiSelectExercises: [ ...this.state.MultiSelectExercises.slice(0, MultiSelectIndex), ...this.state.MultiSelectExercises.slice(MultiSelectIndex + 1) ],
                    MultiSelectExerciseIds: [ ...this.state.MultiSelectExerciseIds.slice(0, MultiSelectIndex), ...this.state.MultiSelectExerciseIds.slice(MultiSelectIndex + 1) ]
                });
            }
    // }
    }

    onRecoverFromTrash = () => {
        var { MultiSelectExerciseIds } = this.state;

        this.setState({ ForceReload: true, StateLoading: true });
        this.props.RecoverExercisesFromTrash({ ExerciseIds: MultiSelectExerciseIds }).then(() => {
            this.setState({ MultiSelectExerciseIds: [], MultiSelectExercises: [], ShowMultiSelect: false, StateLoading: false }, () => {
                this.onLoadExercises();
            });
        });
    }

    onReorderFilters = ReorderedFilters => {
        this.props.ReorderExerciseFilters({ Filters: ReorderedFilters });
    }

    onSearchFromFiltersModal = () => {
        this.setState({ ShowFiltersModals: false }, () => this.onLoadExercises());
    }

    onSearchFromSAYT = SearchValue => {
        if (!this.props.TryingSearchExercises) {
            var { LastSearchedValue } = this.state;

            if (SearchValue !== LastSearchedValue) {
                this.setState({ PageNo: 1, PageSize: INITIAL_STATE.PageSize, LastSearchedValue: SearchValue, SearchValue }, () => this.onLoadExercises());
            }
        }
    }

    onSelectAll = () => {
        var { ExerciseList } = this.props;

        var MultiSelectExercises = [ ...this.state.MultiSelectExercises ];
        var MultiSelectExerciseIds = [ ...this.state.MultiSelectExerciseIds ];

        ExerciseList.forEach(element => {
            var ExerciseId = +element.ExerciseId;

            if (MultiSelectExerciseIds.indexOf(ExerciseId) === -1) {
                MultiSelectExercises.push(element);
                MultiSelectExerciseIds.push(ExerciseId);
            }
        });

        this.setState({ MultiSelectExercises, MultiSelectExerciseIds });
    }

    onSelectCategory = (Category, SelectedCategoryIndex) => {
        var { SelectedCategories, SelectedCategoriesIndex } = this.state;

        if (SelectedCategoryIndex === -1) { SelectedCategories = [ ...SelectedCategories, Category ]; SelectedCategoriesIndex = [ ...SelectedCategoriesIndex, +Category.CategoryId ]; }
        else {
            SelectedCategories = [ ...SelectedCategories.slice(0, SelectedCategoryIndex), ...SelectedCategories.slice(SelectedCategoryIndex + 1) ];
            SelectedCategoriesIndex = [ ...SelectedCategoriesIndex.slice(0, SelectedCategoryIndex), ...SelectedCategoriesIndex.slice(SelectedCategoryIndex + 1) ];
        }

        this.setState({ SelectedCategories, SelectedCategoriesIndex }, () => this.onLoadExercises());
    }

    onSelectMergeTagsCheckmark = event => {
        var { MergeTags } = this.state;

        if (event) MergeTags = +(event.target.checked);
        else MergeTags = +!MergeTags;

        this.setState({ MergeTags });
    }

    onSelectTag = (event, Filter, TagId, TagIndex, TargetAvoid, OppositeTargetAvoidIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = TagIndex === -1;

        var { SelectedCategories_Modal } = this.state;

        var FilterName = Filter.FilterName;
        if (FilterName === 'Joints' || FilterName === 'Muscles') {
            FilterName = `${TargetAvoid}${FilterName}`;
            Filter.TargetAvoid = TargetAvoid;
        }

        if (!!+Filter.IsOrg) {
            FilterName = 'OrgTags';
            var TagFilterId = Filter.TagFilterId;

            if (checked) {
                if (TagId === 0) SelectedCategories_Modal[FilterName][TagFilterId] = [ TagId ];
                else SelectedCategories_Modal[FilterName][TagFilterId] = [ ...SelectedCategories_Modal[FilterName][TagFilterId], TagId ];
            } else SelectedCategories_Modal[FilterName][TagFilterId] = [ ...this.state.SelectedCategories_Modal[FilterName][TagFilterId].slice(0, TagIndex), ...this.state.SelectedCategories_Modal[FilterName][TagFilterId].slice(TagIndex + 1) ];
        } else {
            if (checked) {
                SelectedCategories_Modal[FilterName] = [ ...SelectedCategories_Modal[FilterName], TagId ];

                // Remove the opposite target/avoid if already selected
                    if ((Filter.FilterName === 'Joints' || Filter.FilterName === 'Muscles') && OppositeTargetAvoidIndex !== -1) {
                        var OppositeFilterName = `${TargetAvoid === 'Avoid' ? 'Target' : 'Avoid'}${Filter.FilterName}`;
                        SelectedCategories_Modal[OppositeFilterName] = [ ...this.state.SelectedCategories_Modal[OppositeFilterName].slice(0, OppositeTargetAvoidIndex), ...this.state.SelectedCategories_Modal[OppositeFilterName].slice(OppositeTargetAvoidIndex + 1) ];
                    }
            } else SelectedCategories_Modal[FilterName] = [ ...this.state.SelectedCategories_Modal[FilterName].slice(0, TagIndex), ...this.state.SelectedCategories_Modal[FilterName].slice(TagIndex + 1) ];
        }

        this.setState({ SelectedCategories_Modal });
    }

    onShareContentToEmails = ({ Emails, ContentIds }) => {
        this.props.UpdateSharedToCoachStatus_Exercises({ Emails, ExerciseIds: ContentIds, NewStatus: 'Pending' }).then(() => {
            this.onToggleShowShareContentModal(false);
        });
    }

    onToggleShowAddRemoveTagsPopover = ({ ShowAddRemoveTagsPopover_ActionType = null }) => {
        this.setState({ ShowAddRemoveTagsPopover: !this.state.ShowAddRemoveTagsPopover, ShowAddRemoveTagsPopover_ActionType });
    }

    onToggleFilterStartOpen = Filter => {
        var { StartOpen, TagFilterId } = Filter;

        this.onInsertEditExerciseFilter({ FilterId: TagFilterId, ...Filter, StartOpen: +!!!StartOpen });
    }

    onToggleFilterVisibility = (Filter, VisibilityStatus) => {
        var { TagFilterId } = Filter;
        var IsHidden = VisibilityStatus === 'Show' ? 0 : 1;

        this.onInsertEditExerciseFilter({ FilterId: TagFilterId, ...Filter, IsHidden });
    }

    onToggleMultiSelect = event => {
        this.setState({ MultiSelectExerciseIds: [], MultiSelectExercises: [], ShowMultiSelect: event.target.checked });
    }

    onToggleShowChooseCategoryTagsModal = ShowChooseCategoryTagsModal => {
        var SelectedCategories_Modal = ShowChooseCategoryTagsModal ? GetSelectedCategories(this.props.ExerciseFilterSettings.Categories) : { ...SELECTED_CATEGORIES_DEFAULT };

        this.setState({ SelectedCategories_Modal, ShowChooseCategoryTagsModal });
    }

    onToggleShowDeleteExercisesModal = ShowDeleteExercisesModal => {
        if (!this.state.MultiSelectExerciseIds.length && !!ShowDeleteExercisesModal) {}
        else this.setState({ ShowDeleteExercisesModal });
    }

    onToggleShowEditShareAbilityStatusModal = ({ ContentId = null, ContentIndex = null, ShowEditShareAbilityStatusModal, CurrentStatus = null }) => {
        this.setState({ ShowEditShareAbilityStatusModal, ShowEditShareAbilityStatusModalDetails: { ContentId, ContentIndex, CurrentStatus } });
    }

    onToggleShowExerciseDetailModal = (ShowExerciseDetailModal, Exercise = {}) => {
        if (this.props.IsModal) return this.props.OnToggleShowExerciseDetailModal(ShowExerciseDetailModal, Exercise);
        
        this.setState({ ShowExerciseDetailModal, ShowExerciseDetailPreview: Exercise });
    }

    onToggleShowFiltersModal = ShowFiltersModals => {
        this.setState({ ShowFiltersModals });
    }

    onToggleShowHowSharingWorksModal = ShowHowSharingWorksModal => {
        this.setState({ ShowHowSharingWorksModal });
    }

    onToggleShowShareContentModal = ShowShareContentModal => {
        if (!this.state.MultiSelectExerciseIds.length && !!ShowShareContentModal) {}
        else this.setState({ ShowShareContentModal });
    }

    onUpdateExercisesTags = ({ ActionType, TagIds }) => {
        this.props.UpdateExercisesTags({ ActionType, ExerciseIds: this.state.MultiSelectExerciseIds.join(','), TagIds }).then(() => {
            this.onToggleShowAddRemoveTagsPopover({});
            // this.onCancelMultiSelect();
            if (this.state.TagIds.some(v => TagIds.includes(v))) this.onLoadExercises();
        });
    }

    onUpdateFilters = (Filter, Selected, OppositeTargetAvoidSelected, DontSearch = false) => {
        var { FilterName, IsAbel, IsOrg, TargetAvoid } = Filter;

        if (IsAbel) {
            if (FilterName === 'Equipment') this.setState({ Equipment: Selected }, () => !DontSearch && this.onLoadExercises());
            else if (FilterName === 'Joints') this.setState({ [`${TargetAvoid}Joints`]: Selected, [`${TargetAvoid === 'Avoid' ? 'Target' : 'Avoid'}Joints`]: OppositeTargetAvoidSelected }, () => !DontSearch && this.onLoadExercises());
            else if (FilterName === 'MadeBy') this.setState({ Owners: Selected }, () => !DontSearch && this.onLoadExercises());
            else if (FilterName === 'Movement') this.setState({ Movement: Selected }, () => !DontSearch && this.onLoadExercises());
            else if (FilterName === 'Muscles') this.setState({ [`${TargetAvoid}Muscles`]: Selected, [`${TargetAvoid === 'Avoid' ? 'Target' : 'Avoid'}Muscles`]: OppositeTargetAvoidSelected }, () => !DontSearch && this.onLoadExercises());
            else if (FilterName === 'Plane') this.setState({ Plane: Selected }, () => !DontSearch && this.onLoadExercises());
            else if (FilterName === 'StartPosition') this.setState({ StartPosition: Selected }, () => !DontSearch && this.onLoadExercises());
            else if (FilterName === 'Tags') this.setState({ TagIds: Selected }, () => !DontSearch && this.onLoadExercises());
            else if (FilterName === 'TrainingFocus') this.setState({ TrainingFocus: Selected }, () => !DontSearch && this.onLoadExercises());
            else if (FilterName === 'TrainingForm') this.setState({ TrainingForm: Selected }, () => !DontSearch && this.onLoadExercises());
        }
        else if (!!+IsOrg) {
            var OrgTags = { ...this.state.OrgTags };
            OrgTags[`${Filter.TagFilterId}`] = Object(Selected).hasOwnProperty(`${Filter.TagFilterId}`) ? Object.values(Selected[`${Filter.TagFilterId}`]) : [];
            this.setState({ OrgTags }, () => !DontSearch && this.onLoadExercises());
        }
        else this.setState({ TagIds: Selected }, () => !DontSearch && this.onLoadExercises());
    }

    onUpdateMultiSelectList = ({ ContentId, NewStatus, NewStatusName }) => {
        var ContentIndex = this.state.MultiSelectExerciseIds.indexOf(+ContentId);

        if (ContentIndex !== -1) {
            this.setState(prevState => {
                var MultiSelectExercises = [ ...prevState.MultiSelectExercises ];
                MultiSelectExercises[ContentIndex] = { ...MultiSelectExercises[ContentIndex], ShareAbility: NewStatusName, ShareAbilityStatusId: NewStatus };
                
                return { ...prevState, MultiSelectExercises };
            });
        }
    }
    
    onUpdateShareAbilityStatus = ({ ContentId, ContentIndex, NewStatus, NewStatusName }) => {
        return this.props.UpdateShareAbilityStatus_Exercises({ ExerciseId: ContentId, NewStatus, NewStatusName }).then(() => {
            this.setState(prevState => {
                var MultiSelectExercises = [ ...prevState.MultiSelectExercises ];
                MultiSelectExercises[ContentIndex] = { ...MultiSelectExercises[ContentIndex], ShareAbility: NewStatusName, ShareAbilityStatusId: NewStatus };
                
                return { ...prevState, MultiSelectExercises };
            });
        });
    }

    onUpdateSharedToCoachStatus = ({ ContentId, NewStatus, PersonalResponse }) => {
        this.setState({ StateLoading: true });
        this.props.UpdateSharedToCoachStatus_Exercises({ Emails: [ this.props.UserDetails.Email ], ExerciseIds: [ ContentId ], NewStatus, PersonalResponse }).then(() => {
            this.setState({ StateLoading: false });
            this.onLoadExercises();
        });
    }

    onUpdateSharedToCoachStatus_All = ({ NewStatus }) => {
        var { MergeTags } = this.state;

        this.setState({ StateLoading: true });
        this.props.UpdateSharedToCoachStatusAll_Exercises({ MergeTags, NewStatus }).then(() => {
            this.setState({ StateLoading: false });
            this.onLoadExercises();
        });
    }

    renderBottomBar = () => {
        var { t } = this.props;
        var { ChangeExercise, OnAddExercises, FromAddExercise } = this.props;
        var { MultiSelectExerciseIds, MultiSelectExercises, ShowAddRemoveTagsPopover_ActionType, ShowMultiSelect } = this.state;

        if (!ShowMultiSelect && ((!!FromAddExercise && !MultiSelectExerciseIds.length) || !FromAddExercise)) return null;
        // if (!MultiSelectExerciseIds.length) return null;

        var { Device } = this.props;

        var Buttons = [];
        var AddRemoveTagsButtons = [];

        if (this.props.ShowTrash) {
            Buttons = [
                ...Buttons,
                { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', OnClick: () => this.onRecoverFromTrash(true), Title: t('_recover') },
                // { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', IconSVG: TrashSVG, IconPosition: 'left', OnClick: this.onDeleteExercises, Title: t('_delete_for_good') },
                { Border: 'none', ColorHover: 'black', NoBackground: true, OnClick: this.onCancelMultiSelect, Title: t('Cancel_with_first_char_uppercase') }
            ];
        }
        else if (FromAddExercise) {
            Buttons = [
                { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', OnClick: () => OnAddExercises(MultiSelectExercises), Title: ChangeExercise ? t('change_exercise') : t('WorkoutPlan_add_exercises') },
                { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Border: '2px solid var(--blue-astronaut)', ColorHover: 'black', OnClick: this.onCancelMultiSelect, Title: t('Cancel_with_first_char_uppercase') }
            ];
        } else {
            Buttons = [
                ...Buttons,
                { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', Disabled: !MultiSelectExerciseIds.length, OnClick: () => this.onToggleShowShareContentModal(true), Title: t('_share_with') },
                { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', Disabled: !MultiSelectExerciseIds.length, IconSVG: TrashSVG, IconPosition: 'left', OnClick: this.onDeleteToTrash, Title: t('_move_to_trash') },
                { Border: 'none', ColorHover: 'black', NoBackground: true, OnClick: this.onCancelMultiSelect, Title: t('Cancel_with_first_char_uppercase') }
            ];

            AddRemoveTagsButtons = [
                { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', Disabled: !MultiSelectExerciseIds.length, OnClick: () => this.onToggleShowAddRemoveTagsPopover({ ShowAddRemoveTagsPopover_ActionType: 'Adding' }), Title: t('_add_tags') },
                { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', Disabled: !MultiSelectExerciseIds.length, OnClick: () => this.onToggleShowAddRemoveTagsPopover({ ShowAddRemoveTagsPopover_ActionType: 'Removing' }), Title: t('_remove_tags') }
            ]
        }

        const popoverProps = {
            isOpen: this.state.ShowAddRemoveTagsPopover,
            preferPlace: 'above',
            onOuterAction: () => this.onToggleShowAddRemoveTagsPopover({}),
            body: <AddRemoveTagsPopoverComponent ActionType={ShowAddRemoveTagsPopover_ActionType} TagIds={MultiSelectExerciseIds} GetAllTagsForAddingRemoving={({ ActionType, TagIds: ExerciseIds }) => this.props.GetAllTagsForAddingRemoving_Exercises({ ActionType, ExerciseIds })} t={t} UpdateTags={this.onUpdateExercisesTags} />,
            tipSize: 10
        }

        return (
            <>
                <Spacer Size="extra-large" />

                <Styles.StyledBottomBar className="StyledBottomBar">
                    <Styles.BottomBarItems className="BottomBarItems" FirstOne>
                        <Styles.BottomBarLeftContainer className="BottomBarLeftContainer">
                            <PageText ElementType="span" FontColor="blue-abel" FontFamily="bold" FontSize="medium-3" Text={`${MultiSelectExerciseIds.length}`} />
                            &nbsp;
                            <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('WorkoutPlanSettings_selected').toLowerCase()} />
                        </Styles.BottomBarLeftContainer>
                        {
                            !this.props.MaxExercises &&
                            <Styles.BottomBarRightContainer className="BottomBarRightContainer">
                                <PageText FontFamily="bold" FontSize="medium-1" NoMargin OnClick={this.onSelectAll} Text={t('select_all')} />
                            </Styles.BottomBarRightContainer>
                        }

                        {
                            !this.props.ShowTrash &&
                            <Popover {...popoverProps}>
                                <Styles.AddRemoveTagsButtons
                                    Buttons={AddRemoveTagsButtons}
                                    ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                                    NotTouching
                                    OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                                />
                            </Popover>
                        }
                    </Styles.BottomBarItems>
                    <Styles.BottomBarItems className="BottomBarItems" FullWidth={true}>
                        <ButtonGroup
                            Buttons={Buttons}
                            ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                            NotTouching
                            OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                        />
                    </Styles.BottomBarItems>
                </Styles.StyledBottomBar>
            </>
        );
    }

    renderDeleteExercisesInformation = () => {
        var { t } = this.props;
        var { MultiSelectExercises } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" Text={t('exercise_delete_warning')} TextAlign="center" />

                <Styles.DeleteExercisesList>
                    {
                        MultiSelectExercises.map(Exercise => <ExerciseListItem key={Exercise.ExerciseId} HideSelect Item={Exercise} ViewExercise={Exercise => this.onToggleShowExerciseDetailModal(true, Exercise)} />)
                    }
                </Styles.DeleteExercisesList>
            </>
        );
    }

    renderDeleteExercisesModal = () => {
        var { t } = this.props;
        var { TryingDeleteExercises } = this.props;
        var { ShowDeleteExercisesModal, ShowExerciseDetailModal } = this.state;

        if (ShowDeleteExercisesModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteExercises}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    DisplayNone={ShowExerciseDetailModal}
                    InformationRenderer={this.renderDeleteExercisesInformation}
                    Loading={TryingDeleteExercises}
                    OnHide={() => this.onToggleShowDeleteExercisesModal(false)}
                    Show={ShowDeleteExercisesModal}
                />
            );
        }
    }

    renderExerciseDetailModal = () => {
        var { ShowExerciseDetailModal, ShowExerciseDetailPreview } = this.state;

        if (ShowExerciseDetailModal) {
            return (
                <ExerciseDetail
                    ExerciseDetail_Preview={ShowExerciseDetailPreview}
                    OnHide={() => this.onToggleShowExerciseDetailModal(false, null)}
                    OnLoadExercises={this.onLoadExercises}
                    OnUpdateMultiSelectList={this.onUpdateMultiSelectList}
                    Show={ShowExerciseDetailModal}
                />
            );
        }
    }

    renderExercises = () => {
        var { t } = this.props;
        var { Device, ExerciseList, ExerciseList_NumPendingShared, ExerciseList_TotalRecords, FromAddExercise, HideSelect, IsModal, IsMoreExercises, IsMultiSelect, OnSelectExercise, SelectedItemIds } = this.props;
        var { ActiveSearch, CompletedFirstSearch, MergeTags, NewSearch, PageNo, PageSize, ShowMultiSelect } = this.state;

        var IsFlexed = Device === 'laptop' ? 6 : Device === 'ipad' ? 3 : Device === 'mobile' ? 3 : 2;
        var ShowRating = true;

        ActiveSearch = false;
        CompletedFirstSearch = false;

        return (
            <>
                {
                    !!ExerciseList_NumPendingShared &&
                    <Styles.PendingSharedContainer className="PendingSharedContainer">
                        <Styles.PendingSharedLeftContainer className="PendingSharedLeftContainer">
                            <Styles.PendingSharedImage className="PendingSharedImage" ImageSrc={Sharing_Gift} SelfLoading />
                        </Styles.PendingSharedLeftContainer>
                        <Styles.PendingSharedRightContainer className="PendingSharedRightContainer">
                            <Styles.PendingSharingRightTopContainer className="PendingSharingRightTopContainer">
                                <MultiText
                                    Texts={[
                                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('_you_have') },
                                        { FontFamily: 'bold', FontSize: 'medium-2', Text: `${ExerciseList_NumPendingShared}` },
                                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('_sharing_requests_pending') }
                                    ]}
                                />
                                
                                <Styles.CheckmarkTextContainer className="CheckmarkTextContainer">
                                    <Checkmark
                                        ItemId={0}
                                        OnSelectItem={this.onSelectMergeTagsCheckmark}
                                        SelectedIndex={MergeTags ? 1 : -1}
                                        Title="MergeTags"
                                    />

                                    <Styles.CheckmarkItemName
                                        ElementType="div"
                                        FontFamily="medium"
                                        FontSize="small"
                                        NoMargin
                                        OnClick={() => this.onSelectMergeTagsCheckmark(null)}
                                        Text={t('_i_want_receive_custom_tags_generic')}
                                        Title="MergeTags"
                                    />
                                </Styles.CheckmarkTextContainer>
                            </Styles.PendingSharingRightTopContainer>
                            <ButtonGroup
                                Buttons={[
                                    { BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', OnClick: () => this.onUpdateSharedToCoachStatus_All({ NewStatus: 'accept' }), Size: 'small', Title: t('_accept') },
                                    { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', OnClick: () => this.onUpdateSharedToCoachStatus_All({ NewStatus: 'reject' }), Size: 'small', Title: t('_decline') }
                                ]}
                                ButtonWidth="fit-content"
                                JustifyContent="flex-start"
                                NotTouching
                            />
                        </Styles.PendingSharedRightContainer>
                    </Styles.PendingSharedContainer>
                }

                <Styles.StyledLoadMore
                    ActiveSearch={ActiveSearch}
                    CompletedFirstSearch={CompletedFirstSearch}
                    HideViewCount
                    IsFlexed={IsFlexed}
                    IsLoading={false}
                    IsModal={IsModal}
                    ItemDescriptionType={t('ptadmin_exercises_plural').toLowerCase()}
                    ItemHeight={145}
                    ItemId="ExerciseId"
                    ItemName="ExerciseName"
                    ItemProps={{
                        FromAddExercise,
                        HideMadeBy: true,
                        HideSelect: !!IsModal ? HideSelect : !ShowMultiSelect,
                        IsMultiSelect: !!IsModal ? IsMultiSelect : true,
                        MultiSelectItemIds: this.state.MultiSelectExerciseIds,
                        OnAssignToClients: (event, Template) => this.onAssignToClients(event, Template),
                        OnMultiSelect: (Exercise, MultiSelectIndex) => this.onMultiSelect(Exercise, MultiSelectIndex),
                        OnUpdateSharedToCoachStatus: this.onUpdateSharedToCoachStatus,
                        SelectExercise: Exercise => OnSelectExercise(Exercise),
                        ShowRating,
                        SelectedUsers: null,
                        ViewExercise: Exercise => this.onToggleShowExerciseDetailModal(true, Exercise)
                    }}
                    ItemRenderer={ExerciseListItem}
                    Items={ExerciseList}
                    HasMoreItems={IsMoreExercises}
                    LoadMore={() => this.onLoadExercises(true)}
                    NewSearch={NewSearch}
                    NoItemsText={t('search_noresults')}
                    PageNo={PageNo}
                    PageSize={PageSize}
                    RenderHeader={(!!+ExerciseList_NumPendingShared && !ShowMultiSelect && !FromAddExercise) ? this.onRenderListHeader : null}
                    SelectedItemIds={SelectedItemIds}
                    TotalRecords={ExerciseList_TotalRecords}
                />
            </>
        );
    }

    renderFiltersModal_Info = () => {
        var { Device, ShowTrash } = this.props;
        var { AvoidJoints, AvoidMuscles, Equipment, Movement, OrgTags, Owners, Plane, StartPosition, TagIds, TargetJoints, TargetMuscles, TrainingFocus, TrainingForm } = this.state;

        var DefaultSelections = { AvoidJoints, AvoidMuscles, Equipment, MadeBy: Owners, Movement, OrgTags, Plane, StartPosition, Tags: TagIds, TargetJoints, TargetMuscles, TrainingFocus, TrainingForm };
        
        return (
             <Filters
                DefaultSelections={DefaultSelections}
                Device={Device}
                Filters={this.props.ExerciseFilterSettings ? this.props.ExerciseFilterSettings.Filters : []}
                HideCustomizeButton
                OnClearAllFilters={this.onClearAllFilters}
                OnDeleteTag={this.onDeleteTags}
                OnUpdateSelections={(Filter, Selected, OppositeTargetAvoidSelected) => this.onUpdateFilters(Filter, Selected, OppositeTargetAvoidSelected, true)}
                ShowTrash={ShowTrash}
            />
        );
    }

    renderFiltersModal = () => {
        var { t } = this.props;
        var { ShowFiltersModals } = this.state;

        if (ShowFiltersModals) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onSearchFromFiltersModal}
                    BottomButtonText={t('_search')}
                    InformationRenderer={this.renderFiltersModal_Info}
                    OnHide={() => this.onToggleShowFiltersModal(false)}
                    Show={ShowFiltersModals}
                />
            );
        }
    }

    renderSearchAndCategories = () => {
        var { t } = this.props;
        var { Device, ExerciseFilterSettings, IsModal, UserDetails: { LanguageId } } = this.props;
        var { SelectedCategoriesIndex } = this.state;

        if (Device === 'laptop') {
            return (
                <Styles.SearchAndCategoriesContainer className="SearchAndCategoriesContainer">
                    <Styles.StyledSAYT
                        ExtraSearchProps={{ LanguageId }}
                        ItemName="ExerciseName"
                        OnSearch={this.onSearchFromSAYT}
                        Placeholder={t('_search')}
                        SearchFunc={this.props.SearchExercises_SAYT}
                    />
    
                    {
                        ExerciseFilterSettings.Categories && ExerciseFilterSettings.Categories.map(Category => {
                            var { CategoryId, IsAbel, SecondaryTagType, TagName, TagType } = Category;
                            var SelectedCategoryIndex = SelectedCategoriesIndex.indexOf(+CategoryId);
                            
                            if (IsAbel && (TagType === 'Joints' || TagType === 'Muscles')) TagName = `${t(`_${SecondaryTagType.toLowerCase()}`)} - ${TagName}`;
    
                            return (
                                <Styles.CategoryContainer className="CategoryContainer" key={Category.CategoryId} onClick={() => this.onSelectCategory(Category, SelectedCategoryIndex)} Selected={SelectedCategoryIndex !== -1}>
                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={TagName} TextAlign="center" />
                                </Styles.CategoryContainer>
                            );
                        })
                    }
    
                    <Styles.CategoryContainer className="CategoryContainer" onClick={() => this.onToggleShowChooseCategoryTagsModal(true)}>
                        <Styles.AddCategoryIcon ImageSrc={Plus_Dark} />
                    </Styles.CategoryContainer>
                </Styles.SearchAndCategoriesContainer>
            );
        } else {
            return (
                <Styles.SearchAndCategoriesContainer className="SearchAndCategoriesContainer" IsModal={IsModal}>
                    <Styles.SearchAndCategoriesTopContainer className="SearchAndCategoriesTopContainer" IsModal={IsModal}>
                        <Styles.StyledSAYT
                            ExtraSearchProps={{ LanguageId }}
                            IsModal={IsModal}
                            ItemName="ExerciseName"
                            OnSearch={this.onSearchFromSAYT}
                            Placeholder={t('_search')}
                            SearchFunc={this.props.SearchExercises_SAYT}
                        />

                        <ButtonGroup Buttons={[{ BackgroundColor: "white-concrete", BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', IconSVG: FilterSVG, IconSVGColor: 'black', IconPosition: 'left', OnClick: () => this.onToggleShowFiltersModal(true), Title: t('_select_filters') }]} />
                    </Styles.SearchAndCategoriesTopContainer>
    
                    <Styles.CategoriesContainer className="CategoriesContainer">
                        {
                            ExerciseFilterSettings.Categories && ExerciseFilterSettings.Categories.map(Category => {
                                var { CategoryId, IsAbel, SecondaryTagType, TagName, TagType } = Category;
                                var SelectedCategoryIndex = SelectedCategoriesIndex.indexOf(+CategoryId);
                                
                                if (IsAbel && (TagType === 'Joints' || TagType === 'Muscles')) TagName = `${t(`_${SecondaryTagType.toLowerCase()}`)} - ${TagName}`;
        
                                return (
                                    <Styles.CategoryContainer className="CategoryContainer" key={Category.CategoryId} onClick={() => this.onSelectCategory(Category, SelectedCategoryIndex)} Selected={SelectedCategoryIndex !== -1}>
                                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={TagName} TextAlign="center" />
                                    </Styles.CategoryContainer>
                                );
                            })
                        }
        
                        {
                            !IsModal &&
                            <Styles.CategoryContainer className="CategoryContainer" onClick={() => this.onToggleShowChooseCategoryTagsModal(true)}>
                                <Styles.AddCategoryIcon ImageSrc={Plus_Dark} />
                            </Styles.CategoryContainer>
                        }
                    </Styles.CategoriesContainer>
                </Styles.SearchAndCategoriesContainer>
            );
        }
    }

    renderShowChooseCategoryTagsModal = () => {
        var { ShowChooseCategoryTagsModal } = this.state;

        if (ShowChooseCategoryTagsModal) {
            var { t } = this.props;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onChooseCategories}
                    BottomButtonText={t('_save')}
                    InformationRenderer={this.renderShowChooseCategoryTagsModal_Information}
                    OnHide={() => this.onToggleShowChooseCategoryTagsModal(false)}
                    Show={ShowChooseCategoryTagsModal}
                    Size="medium"
                />
            );
        }
    }

    renderShowChooseCategoryTagsModal_Information = () => {
        var { t } = this.props;
        var { Filters } = this.props.ExerciseFilterSettings;

        var { TryingChooseCategories } = this.state;

        return (
            <>
                {TryingChooseCategories && <Loading />}

                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('_pick_tags_as_highlights1')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <Styles.FiltersContainer className="FiltersContainer">
                    {
                        Filters.map(F => {
                            var { FilterName, IsAbel, TagFilterId, Tags } = F;
                            var TranslatedFilterName = GetTranslatedFilterName(F, t);

                            return (
                                <Styles.FilterSectionContainer className="FilterSectionContainer" key={TagFilterId}>
                                    <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={TranslatedFilterName} TextAlign="left" />

                                    <Spacer Size="extra-extra-small" />

                                    <Styles.TagsContainer className="TagsContainer">
                                        {
                                            IsAbel && (FilterName === 'Joints' || FilterName === 'Muscles') &&
                                            <Styles.FilterTagContainer className="FilterTagContainer" JustifyContent="space-between">
                                                <Styles.CheckmarkIcon ImageSrc={Blocked} Loading={false} />

                                                <Styles.CheckmarkIcon ImageSrc={Checkmark_Selected} Loading={false} />
                                            </Styles.FilterTagContainer>
                                        }
                                        {Tags.map(Tag => this.renderTag(F, Tag))}
                                    </Styles.TagsContainer>
                                </Styles.FilterSectionContainer>
                            );
                        })
                    }
                </Styles.FiltersContainer>
            </>
        );
    }

    renderShowEditShareAbilityStatusModal = () => {
        var { ShowEditShareAbilityStatusModal, ShowEditShareAbilityStatusModalDetails } = this.state;

        if (ShowEditShareAbilityStatusModal) {
            return (
                <EditShareAbilityStatusModal
                    ContentId={ShowEditShareAbilityStatusModalDetails.ContentId}
                    ContentIndex={ShowEditShareAbilityStatusModalDetails.ContentIndex}
                    CurrentStatus={ShowEditShareAbilityStatusModalDetails.CurrentStatus}
                    Loading={this.props.TryingUpdateShareAbilityStatus_Exercises}
                    OnHide={() => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: false })}
                    OnUpdateShareAbilityStatus={this.onUpdateShareAbilityStatus}
                    Show={ShowEditShareAbilityStatusModal}
                />
            );
        }
    }

    renderShowHowSharingWorksModal = () => {
        var { ShowHowSharingWorksModal } = this.state;

        if (ShowHowSharingWorksModal) {
            return (
                <HowSharingWorksModal
                    OnHide={() => this.onToggleShowHowSharingWorksModal(false)}
                    Show={ShowHowSharingWorksModal}
                />
            );
        }
    }

    renderShowShareContentModal = () => {
        var { MultiSelectExercises, ShowEditShareAbilityStatusModal, ShowHowSharingWorksModal, ShowShareContentModal } = this.state;

        if (ShowShareContentModal) {
            var { TryingUpdateSharedToCoachStatus_Exercises } = this.props;

            return (
                <ShareContentModal
                    ContentIdName="ExerciseId"
                    ContentType="Exercises"
                    DisplayNone={!!ShowEditShareAbilityStatusModal || !!ShowHowSharingWorksModal}
                    OnHide={() => this.onToggleShowShareContentModal(false)}
                    OnSubmit={({ Emails, ContentIds }) => this.onShareContentToEmails({ Emails, ContentIds })}
                    OnToggleShowEditShareAbilityStatusModal={({ ContentIndex, ContentId, CurrentStatus }) => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: true, ContentId, ContentIndex, CurrentStatus })}
                    OnToggleShowHowSharingWorksModal={() => this.onToggleShowHowSharingWorksModal(true)}
                    Loading={TryingUpdateSharedToCoachStatus_Exercises}
                    SharingContent={MultiSelectExercises}
                    Show={ShowShareContentModal}
                />
            );
        }
    }

    renderTag = (Filter, Tag) => {
        var { SelectedCategories_Modal: SelectedCategories } = this.state;

        var key = Tag.TagId;
        if (Filter.IsAbel && Filter.FilterName === 'Equipment') key = Tag.EquipmentCategory;

        if (Filter.IsAbel && (Filter.FilterName === 'Joints' || Filter.FilterName === 'Muscles')) {
            var SelectedIndex_Avoid = SelectedCategories[`Avoid${Filter.FilterName}`].indexOf(Tag.TagId);
            var SelectedIndex_Target = SelectedCategories[`Target${Filter.FilterName}`].indexOf(Tag.TagId);

            return (
                <Styles.FilterTagContainer className="FilterTagContainer" key={key}>
                    <Checkmark
                        BlockIcon
                        ItemId={Tag.TagId}
                        OnSelectItem={event => this.onSelectTag(event, Filter, Tag.TagId, SelectedIndex_Avoid, 'Avoid', SelectedIndex_Target)}
                        SelectedIndex={SelectedIndex_Avoid}
                        Title={Tag.TagName}
                    />

                    <Styles.CheckmarkItemName
                        ElementType="div"
                        FontFamily="medium"
                        FontSize="small"
                        NoMargin
                        // OnClick={() => this.onSelectTag(null, Tag.TagId, SelectedIndex)}
                        Text={Tag.TagName}
                        Title={Tag.TagName}
                        TwoBoxes
                    />

                    <Checkmark
                        ItemId={Tag.TagId}
                        OnSelectItem={event => this.onSelectTag(event, Filter, Tag.TagId, SelectedIndex_Target, 'Target', SelectedIndex_Avoid)}
                        SelectedIndex={SelectedIndex_Target}
                        Title={Tag.TagName}
                    />
                </Styles.FilterTagContainer>
            );
        }
        else if (!(Filter.IsAbel && Filter.FilterName === 'Equipment')) {
            var SelectedIndex = SelectedCategories[Filter.FilterName].indexOf(Tag.TagId);

            return (
                <Styles.FilterTagContainer className="FilterTagContainer" key={key}>
                    <Checkmark
                        ItemId={Tag.TagId}
                        OnSelectItem={event => this.onSelectTag(event, Filter, Tag.TagId, SelectedIndex)}
                        SelectedIndex={SelectedIndex}
                        Title={Tag.TagName}
                    />

                    {Filter.FilterName === 'MadeBy' && this.renderUserPhoto(Filter, Tag, SelectedIndex)}

                    <Styles.CheckmarkItemName
                        ElementType="div"
                        FontFamily="medium"
                        FontSize="small"
                        NoMargin
                        OnClick={() => this.onSelectTag(null, Filter, Tag.TagId, SelectedIndex)}
                        Text={Tag.TagName}
                        Title={Tag.TagName}
                    />
                </Styles.FilterTagContainer>
            );
        }
        else if (Filter.IsAbel && Filter.FilterName === 'Equipment') {
            if (!Tag.EquipmentList) return null;

            return (
                <Styles.EquipmentFilterContainer className="EquipmentFilterContainer" key={key}>
                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={Tag.EquipmentCategory} TextAlign="left" />

                    <Spacer Size="extra-small" />

                    {
                        Tag.EquipmentList && Tag.EquipmentList.map(Equipment => {
                            var SelectedIndex = SelectedCategories[Filter.FilterName].indexOf(Equipment.TagId);

                            return (
                                <Styles.FilterTagContainer className="FilterTagContainer" key={Equipment.TagId}>
                                    <Checkmark
                                        ItemId={Equipment.TagId}
                                        OnSelectItem={event => this.onSelectTag(event, Filter, Equipment.TagId, SelectedIndex)}
                                        SelectedIndex={SelectedIndex}
                                        Title={Equipment.TagName}
                                    />

                                    <Styles.CheckmarkItemName
                                        ElementType="div"
                                        FontFamily="medium"
                                        FontSize="small"
                                        NoMargin
                                        OnClick={() => this.onSelectTag(null, Filter, Equipment.TagId, SelectedIndex)}
                                        Text={Equipment.TagName}
                                        Title={Equipment.TagName}
                                    />
                                </Styles.FilterTagContainer>
                            );
                        })
                    }
                </Styles.EquipmentFilterContainer>
            );
        }

        return (
            <pre key={key}>
                {JSON.stringify(Tag, null, 2)}
            </pre>
        );
    }

    renderUserPhoto = (Filter, Tag, SelectedIndex) => {
        var { Sex, TagId, TagName, UserId, UserPhoto } = Tag;

        var SexImage = Sex === 'Male' ? Male : Female;

        if (+UserId === 16) UserPhoto = AbelIcon;

        return <Styles.StyledProfilePhoto Alt={TagName} ImageSrc={UserPhoto} IsAbel={UserId === 16} NoImage={SexImage} NoParent OnClick={() => this.onSelectTag(null, Filter, TagId, SelectedIndex)} />;
    }

    renderViewCount = () => {
        var { t } = this.props;
        var { ExerciseList_TotalRecords: TotalRecords } = this.props;
        var { PageNo, PageSize } = this.state;

        if (!TotalRecords) TotalRecords = 0;

        return <ViewCount PageNo={PageNo} PageSize={PageSize} TextAlign="left" TotalRecords={TotalRecords} ViewingObjectType={t('ptadmin_exercises_plural').toLowerCase()} />;
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { Device } = this.props;

        if (this.props.ShowCustomizeFilters && Device === 'laptop') {
            return (
                <>
                    {(this.props.TryingInsertEditLibraryFilterExercises) && <Loading />}

                    <Header LibraryType="Exercises" ShowTrash={this.props.ShowTrash} />

                    <Styles.BodyContainer>
                        <CustomizeFilters
                            Categories={this.props.ExerciseFilterSettings ? this.props.ExerciseFilterSettings.Categories : []}
                            Filters={this.props.ExerciseFilterSettings ? this.props.ExerciseFilterSettings.Filters : []}
                            OnChooseCategories={this.onChooseCategories}
                            OnDeleteCategory={this.onDeleteCategory}
                            OnDeleteTag={this.onDeleteTags}
                            OnEditTag={this.onEditExerciseTag}
                            OnInsertTags={this.onInsertExerciseTags}
                            OnReorderFilters={this.onReorderFilters}
                            OnToggleFilterStartOpen={this.onToggleFilterStartOpen}
                            OnToggleFilterVisibility={this.onToggleFilterVisibility}
                            OnToggleShowChooseCategoryTagsModal={this.onToggleShowChooseCategoryTagsModal}
                            OnToggleShowHowToTagModal={this.props.OnToggleShowHowToTagModal}
                        />
                    </Styles.BodyContainer>

                    {this.renderShowChooseCategoryTagsModal()}
                </>
            );
        }

        var { t } = this.props;
        var { IsModal, ShowTrash, TryingSearchExercises } = this.props;
        var { AvoidJoints, AvoidMuscles, Equipment, Movement, OrgTags, Owners, Plane, ShowFiltersModals, ShowMultiSelect, StartPosition, StateLoading, TagIds, TargetJoints, TargetMuscles, TrainingFocus, TrainingForm } = this.state;

        var DefaultSelections = { AvoidJoints, AvoidMuscles, Equipment, MadeBy: Owners, Movement, OrgTags, Plane, StartPosition, Tags: TagIds, TargetJoints, TargetMuscles, TrainingFocus, TrainingForm };

        var HeaderTitle = !!ShowTrash ? '_trash' : '_exercises';

        if (!!IsModal && ShowFiltersModals) return this.renderFiltersModal();

        return (
            <>
                {(StateLoading || TryingSearchExercises) && <Loading />}

                {!IsModal && <Header LibraryType="Exercises" ShowTrash={this.props.ShowTrash} />}

                {
                    Device === 'laptop' &&
                    <Filters
                        DefaultSelections={DefaultSelections}
                        Device={Device}
                        Filters={this.props.ExerciseFilterSettings ? this.props.ExerciseFilterSettings.Filters : []}
                        HideCustomizeButton={!!IsModal}
                        OnClearAllFilters={this.onClearAllFilters}
                        OnDeleteTag={this.onDeleteTags}
                        OnUpdateSelections={this.onUpdateFilters}
                        ShowTrash={ShowTrash}
                    />
                }

                <Styles.BodyContainer>
                    {
                        !IsModal &&
                        <Styles.BodyHeader className="BodyHeader" IsModal={IsModal}>
                            <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={t(HeaderTitle)} TextAlign="left" />

                            <Styles.BodyHeaderRight className="BodyHeaderRight" IsModal={IsModal} ShowTrash={ShowTrash}>
                                <Styles.ToggleContainer className="ToggleContainer">
                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('_multi_select')}: ${t('_multiselect_variation1')}`} TextAlign="left"  />

                                    <Toggle
                                        Checked={!!+ShowMultiSelect}
                                        OffLabel={t('_off')}
                                        OnChange={this.onToggleMultiSelect}
                                        OneLabel="after"
                                        OnLabel={t('_on')}
                                    />
                                </Styles.ToggleContainer>

                                {!ShowTrash && <ButtonGroup Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: this.onClickCreatePrivateExercise, Title: t('_create_new') }]} />}
                            </Styles.BodyHeaderRight>
                        </Styles.BodyHeader>
                    }

                    <Spacer Size="small" />

                    {this.renderSearchAndCategories()}

                    {
                        (!!ShowTrash && !this.props.ExerciseFilterSettings.HasDeleted) ?
                        <>
                            <Spacer Size="large" />

                            <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('_trash_empty')} TextAlign="center" />
                        </>
                    :
                        <>
                            <Spacer Size="small" />

                            {this.renderViewCount()}

                            <Spacer Size="small" />

                            {this.renderExercises()}
                        </>
                    }

                    {this.renderBottomBar()}
                </Styles.BodyContainer>

                {this.renderDeleteExercisesModal()}
                {this.renderExerciseDetailModal()}
                {this.renderFiltersModal()}
                {this.renderShowChooseCategoryTagsModal()}
                {this.renderShowEditShareAbilityStatusModal()}
                {this.renderShowHowSharingWorksModal()}
                {this.renderShowShareContentModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        ExerciseFilterSettings: state.Exercises.ExerciseFilterSettings,
        ExerciseList: state.Exercises.ExerciseList,
        ExerciseList_NumPendingShared: state.Exercises.ExerciseList_NumPendingShared,
        ExerciseList_TotalRecords: state.Exercises.ExerciseList_TotalRecords,
        IsMoreExercises: state.Exercises.IsMoreExercises,

        TryingDeleteExercises: state.Exercises.TryingDeleteExercises,
        TryingDeleteExercisesError: state.Exercises.TryingDeleteExercisesError,
        TryingGetExerciseFilterSettings: state.Exercises.TryingGetExerciseFilterSettings,
        TryingGetExerciseFilterSettingsError: state.Exercises.TryingGetExerciseFilterSettingsError,
        TryingInsertEditLibraryFilterExercises: state.Exercises.TryingInsertEditLibraryFilterExercises,
        TryingInsertEditLibraryFilterExercisesError: state.Exercises.TryingInsertEditLibraryFilterExercisesError,
        TryingSearchExercises: state.Exercises.TryingSearchExercises,
        TryingSearchExercisesError: state.Exercises.TryingSearchExercisesError,
        TryingUpdateShareAbilityStatus_Exercises: state.Exercises.TryingUpdateShareAbilityStatus_Exercises,
        TryingUpdateShareAbilityStatus_ExercisesError: state.Exercises.TryingUpdateShareAbilityStatus_ExercisesError,
        TryingUpdateSharedToCoachStatusAll_Exercises: state.Exercises.TryingUpdateSharedToCoachStatusAll_Exercises,
        TryingUpdateSharedToCoachStatusAll_ExercisesError: state.Exercises.TryingUpdateSharedToCoachStatusAll_ExercisesError,
        TryingUpdateSharedToCoachStatus_Exercises: state.Exercise.TryingUpdateSharedToCoachStatus_Exercises,
        TryingUpdateSharedToCoachStatus_ExercisesError: state.Exercise.TryingUpdateSharedToCoachStatus_ExercisesError
    };
};

export default withTranslation()(connect(mapStateToProps, { ChooseExerciseTags, DeleteExerciseTags, EditExerciseTag, GetAllTagsForAddingRemoving_Exercises, GetExerciseFilterSettings, InsertEditExerciseFilter, InsertExerciseTags, MoveExercisesToTrash, RecoverExercisesFromTrash, RemoveCategory_Exercise, ReorderExerciseFilters, SearchExercises_SAYT, SearchExercises, UpdateExercisesTags, UpdateShareAbilityStatus_Exercises, UpdateSharedToCoachStatus_Exercises, UpdateSharedToCoachStatusAll_Exercises } )(ExerciseLibrary));