import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ArrowIcon, CalendarContainer, CalendarHeader, DayContainer, DayHeaderText, DaysContainer, DisabledContainer } from './styles';

import PageText from '../../Text/PageText';
import Spacer from '../../Spacer';

import LeftArrow from '../../../Assets/Icons/LeftArrow.png';
import RightArrow from '../../../Assets/Icons/RightArrow.png';

class MiniCalendar extends React.Component {
    state = {
        MonthlyDifference: 0,
        StartDate: moment().startOf('month').format('YYYY-MM-DD'),
        EndDate: moment().endOf('month').format('YYYY-MM-DD'),
        SelectedDates: [],
        Today: moment().format('YYYY-MM-DD')
    }

    componentDidMount() {
        var SelectedDates = this.props.SelectedDates.map(SelectedDate => moment(SelectedDate).format('YYYY-MM-DD'));

        this.setState({ SelectedDates });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.SelectedDates !== this.props.SelectedDates) this.setState({ SelectedDates: this.props.SelectedDates.map(SelectedDate => moment(SelectedDate).format('YYYY-MM-DD')) });
    }

    renderCalendarHeader = () => {
        var { StartDate } = this.state;

        return (
            <CalendarHeader className="CalendarHeader">
                <ArrowIcon
                    Alt="Previous month"
                    ImageSrc={LeftArrow}
                    Loading={false}
                    OnClick={() => this.onUpdateDate(-1)}
                />

                <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={moment(StartDate).format('MMMM YYYY')} TextAlign="center" />

                <ArrowIcon
                    Alt="Next month"
                    ImageSrc={RightArrow}
                    Loading={false}
                    OnClick={() => this.onUpdateDate(1)}
                />
            </CalendarHeader>
        );
    }

    renderDays = () => {
        var { SelectedDates, StartDate, Today } = this.state;
        var { Disabled, DisabledMaxDate, DisabledMinDate, DisabledText, HideExtraDays } = this.props;

        var Days = [{ DayId: 1 }, { DayId: 2 }, { DayId: 3 }, { DayId: 4 }, { DayId: 5 }, { DayId: 6 }, { DayId: 7 }];
        var MonthDays = [];
        
        var StartMonth = moment(StartDate).format('MMMM');
        var EndMonth = moment(StartDate).format('MMMM');
        var Counter = 0;

        var DisabledDay, WeekDay;
        while (StartMonth === EndMonth) {
            DisabledDay = false;
            if (DisabledMinDate && !DisabledMaxDate) DisabledDay = moment(StartDate).add(Counter, 'days') < moment(DisabledMinDate);
            else if (!DisabledMinDate && DisabledMaxDate) DisabledDay = moment(StartDate).add(Counter, 'days') > moment(DisabledMaxDate);
            else if (DisabledMinDate && DisabledMaxDate) DisabledDay = (moment(StartDate).add(Counter, 'days') < moment(DisabledMinDate)) || (moment(StartDate).add(Counter, 'days') > moment(DisabledMaxDate));

            WeekDay = moment(StartDate).add(Counter, 'days').isoWeekday() - 1;

            MonthDays.push({
                CurrentMonth: true,
                DayId: Days[WeekDay].DayId,
                Date: moment(StartDate).add(Counter, 'days').format('D'),
                DayOfYear: Number(moment(StartDate).add(Counter, 'days').format('DDD')),
                DisabledDay,
                IsToday: Today ===  moment(StartDate).add(Counter, 'days').format('YYYY-MM-DD'),
                Render: true,
                StateDate: moment(StartDate).add(Counter, 'days').format('YYYY-MM-DD')
            });
            
            Counter++;
            EndMonth = moment(StartDate).add(Counter, 'days').format('MMMM');
        }

        var i;
        var Render = HideExtraDays ? false : true;
        var CurrentMonth = false;

        var FirstDate = MonthDays[0].StateDate;
        var LastDate = MonthDays[MonthDays.length - 1].StateDate;

        // Add Empty Days Before and After Month to Fill Out Monday - Sunday Each Week
            if (MonthDays[0].DayId !== 1) {
                var FirstDayId = MonthDays[0].DayId;
                var FirstDayOfYear = MonthDays[0].DayOfYear;

                for (i = 1; i < FirstDayId; i++) {
                    DisabledDay = false;
                    if (Render && DisabledMinDate && !DisabledMaxDate) DisabledDay = moment(FirstDate).subtract(i, 'days') < moment(DisabledMinDate);
                    else if (Render && !DisabledMinDate && DisabledMaxDate) DisabledDay = moment(FirstDate).subtract(i, 'days') > moment(DisabledMaxDate);
                    else if (Render && DisabledMinDate && DisabledMaxDate) DisabledDay = (moment(FirstDate).subtract(i, 'days') < moment(DisabledMinDate)) || (moment(FirstDate).subtract(i, 'days') > moment(DisabledMaxDate));

                    MonthDays.unshift({
                        CurrentMonth,
                        Date: Render ? moment(FirstDate).subtract(i, 'days').format('D') : null,
                        DayOfYear: FirstDayOfYear - i,
                        DisabledDay,
                        IsToday: false,
                        Render,
                        StateDate: Render ? moment(FirstDate).subtract(i, 'days').format('YYYY-MM-DD') : null
                    });
                }
            }

            if (MonthDays[MonthDays.length - 1].DayId !== 7) {
                var LastDayId = MonthDays[MonthDays.length - 1].DayId;
                var LastDayOfYear = MonthDays[MonthDays.length - 1].DayOfYear;

                for (i = 1; i <= 7 - LastDayId; i++) {
                    DisabledDay = false;
                    if (Render && DisabledMinDate && !DisabledMaxDate) DisabledDay = moment(LastDate).add(i, 'days') < moment(DisabledMinDate);
                    else if (Render && !DisabledMinDate && DisabledMaxDate) DisabledDay = moment(LastDate).add(i, 'days') > moment(DisabledMaxDate);
                    else if (Render && DisabledMinDate && DisabledMaxDate) DisabledDay = (moment(LastDate).add(i, 'days') < moment(DisabledMinDate)) || (moment(LastDate).add(i, 'days') > moment(DisabledMaxDate));
                    
                    MonthDays.push({
                        CurrentMonth,
                        Date: Render ? moment(LastDate).add(i, 'days').format('D') : null,
                        DayOfYear: LastDayOfYear + i,
                        DisabledDay,
                        IsToday: false,
                        Render,
                        StateDate: Render ? moment(LastDate).add(i, 'days').format('YYYY-MM-DD') : null,
                    });
                }
            }

        return (
            <DaysContainer className="DaysContainer">
                {[ 1, 2, 3, 4, 5, 6, 7 ].map(Day => <DayHeaderText key={Day} FontFamily="medium" FontSize="small" NoMargin Text={moment().isoWeekday(Day).format('dd')} />)}

                {
                    MonthDays.map(({ CurrentMonth, Date, DayOfYear, DisabledDay, IsToday, Render, StateDate }) => {
                        var SelectedDateIndex = SelectedDates.indexOf(StateDate);

                        return (
                            <DayContainer
                                className="DayContainer"
                                key={DayOfYear}
                                DisabledDay={DisabledDay}
                                ExtraDay={Render}
                                IsToday={IsToday}
                                onClick={DisabledDay ? () => null : () => this.onSelectDate(StateDate)}
                                Selected={SelectedDateIndex !== -1}
                                WrongMonth={!CurrentMonth}
                            >
                                <PageText FontFamily="semibold" FontSize="small" NoMargin Text={Date} />
                            </DayContainer>
                        );
                    })
                }
                {
                    Disabled  &&
                    <DisabledContainer>
                        <PageText FontColor="red-bittersweet" FontFamily="medium" FontSize="large" NoMargin Text={DisabledText} TextAlign="center" />
                    </DisabledContainer>
                }
            </DaysContainer>
        );
    }

    onSelectDate = DayDate => {
        this.props.OnSelectDate(DayDate);
    }

    onUpdateDate = Direction => {
        var MonthlyDifference = this.state.MonthlyDifference + Direction;
        var StartDate = moment().add(MonthlyDifference, 'months').startOf('month').format('YYYY-MM-DD');
        var EndDate = moment().add(MonthlyDifference, 'months').endOf('month').format('YYYY-MM-DD');

        this.setState({ MonthlyDifference, StartDate, EndDate });
    }

    renderTitle = () => {
        var { Title } = this.props;

        if (Title) return (
            <>
                <PageText FontFamily="medium" FontSize="medium" NoMargin Text={Title} TextAlign="center" />

                <Spacer Size="extra-small" />
            </>
        )
    }
    
    render() {
        return (
            <CalendarContainer className="CalendarContainer">
                {this.renderTitle()}

                {this.renderCalendarHeader()}

                {this.renderDays()}
            </CalendarContainer>
        );
    }
}

MiniCalendar.propTypes = {
    Disabled: PropTypes.bool,
    DisabledMaxDate: PropTypes.string,
    DisabledMinDate: PropTypes.string,
    DisabledText: PropTypes.string,
    HideExtraDays: PropTypes.bool,
    OnSelectDate: PropTypes.func,
    SelectedDates: PropTypes.array.isRequired,
    Title: PropTypes.string
}

MiniCalendar.defaultProps = {
    DisabledText: 'disabled'
}

export default MiniCalendar;