import React, { useMemo } from 'react';
import styled from 'styled-components';
import { GetUploadContent, UploadContent } from '../Services/Actions';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ButtonGroup from '../Components/Buttons/ButtonGroup';
import Checkmark from '../Components/Form/Checkmark';
import Loading from '../Components/Loading';
import PageText from '../Components/Text/PageText';
import Spacer from '../Components/Spacer';

import Plus from '../Assets/Icons/Plus.png';

import { getFileExtension, validateFile } from '../Functions';

const { useEffect, useState } = React;

const MAX_VIDEO_SIZE = 25000000;

const CheckmarkContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    margin: 20px;
    max-width: 100%;
`;

const StyledCheckmark = styled(Checkmark)`
    margin-right: 5px;
`;

const StyledExerciseVideo = styled.video`
    border-radius: 10px;
    display: flex;
    height: 338px;
    margin: 0 auto;
    max-width: 100%;
    width: 600px;
`;

const UploadContentContainer = styled.div``;


const LeftSide = styled.div`
    display: grid;
    grid-template-columns: 100%;
    row-gap: 6px;
    margin-right: 12px;

    > label {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
    }
`;

const ProgressBar = styled.div`
    width: 100%;
    height: 15px;
    background-color: lightgray;
    margin-bottom: 8px;
    border-radius: 20px;

    > div {
        height: 15px;
        background-color: lightgreen;
        border-radius: 20px;
        transition: width 1s;
    }
`;

const RightSide = styled.div`
    display: grid;
    grid-template-columns: 100%;
    row-gap: 6px;
    margin-right: 12px;

    > span:first-child {
        font-size: 18px;
        cursor: pointer;
    }
`;

const WrapperItem = styled.div`
    display: grid;
    grid-template-columns: calc(100% - 50px) 50px;
    padding: 16px;
    box-shadow: 0px 1px 2px lightgrey;
`;



const UploadContentPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [uploadedContent, setUploadedContent] = useState([]);

    const { UploadProgress, TryingGetUploadContent, TryingUploadContent } = useSelector(state => state.UploadContent);

    const getUploadContent = () => {
        dispatch(GetUploadContent({ PageNo: 1, PageSize: 100 })).then(({ UploadContent }) => setUploadedContent(UploadContent));
    }

    useEffect(() => {
        // getUploadContent();
    }, []);

    const onToggleIsMuted = ({ ContentIndex }) => {
        var newUploadedContent = [ ...uploadedContent ];
        newUploadedContent[ContentIndex].NewVideo.IsMuted = !newUploadedContent[ContentIndex].NewVideo.IsMuted;

        setUploadedContent(newUploadedContent);
    }

    const onUploadContent = ({ ContentId, ContentIndex }) => {
        var { ContentName, NewVideo: { IsMuted, UploadedContent } } = uploadedContent[ContentIndex];

        dispatch(UploadContent({ ContentId, ContentName, IsMuted: +IsMuted, IsProcessingVideos: +!!UploadedContent, UploadedContent }));
    }

    const onUpload = ({ event, ContentId, ContentIndex }) => {
        if (event.target.files.length) {
            var UploadedVideo = event.target.files[0];
            var FileExtension = getFileExtension(UploadedVideo.name);

            var newUploadedContent = [ ...uploadedContent ];
    
            if (validateFile({ FileExtension, FileType: 'Videos' })) {
                if (UploadedVideo.size <= MAX_VIDEO_SIZE) {
                    var MediaUrl = URL.createObjectURL(UploadedVideo);

                    newUploadedContent[ContentIndex] = { ...newUploadedContent[ContentIndex], NewVideo: { IsMuted: false, MediaUrl, VideoTooLarge: false, UploadedContent: UploadedVideo, WrongVideoType: false } };

                    var VideoElement = document.getElementById(`video_${ContentId}`);
                    if (VideoElement) {
                        VideoElement.src = MediaUrl;
                        VideoElement.load();

                        VideoElement.addEventListener('error', (ex) => {
                            console.log("error when loading video file", ex);
                        });
                    }
                } else {
                    document.getElementById(`video_${ContentId}`).value = null;
                    newUploadedContent[ContentIndex] = { ...newUploadedContent[ContentIndex], NewVideo: { IsMuted: false, MediaUrl, VideoTooLarge: true, WrongVideoType: false } };
                }
            } else {
                document.getElementById(`video_${ContentId}`).value = null;
                newUploadedContent[ContentIndex] = { ...newUploadedContent[ContentIndex], NewVideo: { IsMuted: false, MediaUrl, VideoTooLarge: false, WrongVideoType: true } };
            }
        } else newUploadedContent[ContentIndex] = { ...newUploadedContent[ContentIndex], NewVideo: null };

        setUploadedContent(newUploadedContent);
    }

    const renderIcon = useMemo(() => {
        if (UploadProgress['UploadContent'] < 100) return <span title="Cancel upload" style={{ color: 'red' }}>✕</span>;
        else if (UploadProgress['UploadContent'] === 100) return <span title="Success upload" style={{ color: 'green', cursor: 'initial' }}>✓</span>;

        return null;
    }, [UploadProgress]);

    const renderUploadingContent = () => {
        return (
            <WrapperItem>
                <LeftSide>
                    <ProgressBar>
                        <div style={{ width: `${UploadProgress['UploadContent']}%` }} />
                    </ProgressBar>
                    <label>File Label</label>
                </LeftSide>
                <RightSide>
                    {renderIcon}
                    <span>{UploadProgress['UploadContent']}%</span>
                </RightSide>
            </WrapperItem>
        );
    }

    return (
        <>
            {(TryingGetUploadContent || TryingUploadContent) && <Loading />}

            <Container className="maincontainer">
                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text="Uploaded Content" TextAlign="left" />

                <Spacer Size="medium" />

                <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin OnClick={getUploadContent} Text="Refresh" TextAlign="center" />

                <Spacer Size="medium" />

                {TryingUploadContent && renderUploadingContent()}

                {
                    uploadedContent.map(({ ContentId, ContentName, CreatedDate, IsProcessingVideos, MediaUrl, NewVideo }, ContentIndex) => {
                        var ContentButtons = [{ BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', IsInput: true, InputProps: { accept: "video/*", id: `UploadedContent_${ContentId}`, type: "file", onChange: event => onUpload({ event, ContentId, ContentIndex }) }, Title: t('Upload') }];
                        if (NewVideo && NewVideo.MediaUrl) ContentButtons.push({ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Color: 'white', ColorHover: 'white', Disabled: (NewVideo.VideoTooLarge || NewVideo.WrongVideoType), FontFamily: 'semibold', OnClick: () => onUploadContent({ ContentId, ContentIndex }), Title: t('save_with_first_char_uppercase') });
                        return (
                            <UploadContentContainer className="UploadContentContainer" key={ContentId}>
                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={ContentName} TextAlign="center" />

                                <Spacer Size="small" />

                                {
                                    !!IsProcessingVideos ?
                                    <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text="Video Processing..." TextAlign="center" />
                                :
                                    (NewVideo && NewVideo.MediaUrl) ?
                                    <>
                                        <StyledExerciseVideo id={`video_${ContentId}`} controls><source src={NewVideo.MediaUrl} />Your browser does not support the video tag.</StyledExerciseVideo>

                                        <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text={NewVideo.MediaUrl} TextAlign="center" />
                                    </>
                                :
                                    MediaUrl &&
                                    <>
                                        <StyledExerciseVideo id={`video_${ContentId}`} controls><source src={MediaUrl} />Your browser does not support the video tag.</StyledExerciseVideo>

                                        <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text={MediaUrl} TextAlign="center" />
                                    </>
                                }

                                {(!!IsProcessingVideos || MediaUrl || (NewVideo && NewVideo.MediaUrl)) && <Spacer Size="small" />}

                                {
                                    (NewVideo && NewVideo.MediaUrl) &&
                                    <>
                                        <CheckmarkContainer className="CheckmarkContainer">
                                            <StyledCheckmark
                                                ItemId={1}
                                                OnSelectItem={() => onToggleIsMuted({ ContentIndex })}
                                                SelectedIndex={!!NewVideo.IsMuted ? 0 : -1}
                                                Title={t('mute_video')}
                                            />

                                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" OnClick={() => onToggleIsMuted({ ContentIndex })} NoMargin Text={t('mute_video')} TextAlign="center" />
                                        </CheckmarkContainer>

                                        <Spacer Size="small" />
                                    </>
                                }

                                <ButtonGroup
                                    Buttons={ContentButtons}
                                    ButtonWidth="fit-content"
                                    NotTouching
                                />

                                <Spacer Size="small" />

                                <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text={`Created: ${moment(CreatedDate).format('[TrackingHistory]')}`} TextAlign="center" />
                            </UploadContentContainer>
                        );
                    })
                }
            </Container>
        </>
    );
}

export default UploadContentPage;