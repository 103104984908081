import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import Image from '../../Image';
import PageText from '../../Text/PageText';
import ProfilePhoto from '../../ProfilePhoto';

import Blocked from '../../../Assets/Icons/Blocked.png';
import Checkmark from '../../../Assets/Icons/Checkmark_Selected.png';
import Male from '../../../Assets/Icons/Male.png';

const GroupMemberContainer = styled.div`
    align-items: center;
    background-color: ${props => props.BackgroundColor ? props.BackgroundColor : 'var(--white)'};
    border: ${props => props.BorderColor ? `2px solid var(--${props.BorderColor})` : props.HasBorder ? '2px solid var(--white-concrete)' : '5px solid var(--white)'};
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: ${props => props.HasBorder ? '8px' : '5px'};
    overflow: hidden;

    ${
        props => props.Selected &&
        `
            border: 5px solid var(--blue-abel);
            box-sizing: border-box;
            padding: 5px;

            @media(hover:hover) {
                :hover {
                    cursor: pointer;
                }
            } 
        `
    }

    ${
        props => !props.HideSelect && !props.Selected && props.Clickable &&
        `
            @media(hover:hover) {
                :hover {
                    border: 5px solid var(--blue-abel);
                    box-sizing: border-box;
                    cursor: pointer;
                    padding: 5px;
                }
            }
        `
    }
`;

const ManagerIcon = styled(Image)`
    height: var(--profilephoto-size-small-2);
    width: var(--profilephoto-size-small-2);
`;

const MultiSelect = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    height: 25px;
    left: 15px;
    position: absolute;
    top: 15px;
    width: 25px;
    z-index: 1;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
            border: none;
            cursor: pointer;
        }
    }
`;

const StyledProfilePhoto = styled(ProfilePhoto)`
    margin-right: 10px;
`;

const StyledUserName = styled(PageText)`
    > div {    
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

const UserNameContainer = styled.div`
    flex: 1;
    overflow: hidden;
`;

class GroupMemberListItem extends React.Component {
    state = { LoadingImage: true };

    onLoadedImage = () => {
        this.setState({ LoadingImage: false });
    }

    renderMultiSelect = () => {
        var { Item, MultiSelectItemIds, OnMultiSelect } = this.props;

        var { UserId } = Item;

        var MultiSelectIndex = MultiSelectItemIds.indexOf(Number(UserId));

        return <MultiSelect onClick={() => OnMultiSelect(Item, MultiSelectIndex)} Selected={MultiSelectIndex !== -1} />;
    }

    render() {
        var { AssignedDate, BackgroundColor, ForceSelected, HasBorder, Item, MultiSelectItemIds, OnSelectMember, SelectedItemIds, SelectedRemoveMembers, ShowIsManager, ShowWorkoutStatus } = this.props;

        var { DisplayName, Gender, HasAccess, IsPreRegistered, IsManager, PartiallyCompleted, UserId, UserPhoto, WorkoutCompleted } = Item;

        if (HasAccess === 0) {
            BackgroundColor = 'var(--white-concrete)';
            OnSelectMember = null;
        }
        
        var Selected = ForceSelected && SelectedRemoveMembers.indexOf(+UserId) === -1 ? true : SelectedItemIds.indexOf(Number(UserId)) > -1 || MultiSelectItemIds.indexOf(Number(UserId)) > -1;

        if (!!ShowWorkoutStatus) var BorderColor = !!+WorkoutCompleted ? 'blue-abel' : PartiallyCompleted ? 'yellow-medium' : moment(AssignedDate.substring(0, 10)).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ? 'red-bittersweet' : null;

        return (
            <GroupMemberContainer id={`itemid-${UserId}`} BackgroundColor={BackgroundColor} BorderColor={BorderColor} Clickable={!!OnSelectMember} HasBorder={HasBorder} onClick={OnSelectMember ? () => OnSelectMember(Item) : () => null} Selected={Selected}>
                {!IsPreRegistered && <StyledProfilePhoto Sex={Gender || 'Male'} Size="small-2" ShowOverlay={false} Source={UserPhoto || ''} />}

                <UserNameContainer>
                    <StyledUserName FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={DisplayName} TextAlign="left"/>
                </UserNameContainer>

                {ShowIsManager && IsManager && <ManagerIcon ImageSrc={Male} Loading={false} />}
                {HasAccess === 0 && <ManagerIcon ImageSrc={Blocked} Loading={false} />}
            </GroupMemberContainer>
        );
    }
}

GroupMemberListItem.propTypes = {
    ForceSelected: PropTypes.bool,
    HasBorder: PropTypes.bool,
    IsMultiSelect: PropTypes.bool,
    Item: PropTypes.object,
    OnMultiSelect: PropTypes.func,
    OnSelectMember: PropTypes.func,
    MultiSelectItemIds: PropTypes.array,
    SelectedItemIds: PropTypes.array,
    SelectedRemoveMembers: PropTypes.array,
    ShowIsManager: PropTypes.bool,
    ShowWorkoutStatus: PropTypes.bool
}

GroupMemberListItem.defaultProps = {
    MultiSelectItemIds: [],
    SelectedItemIds: []
}

export default GroupMemberListItem;