import React from 'react';
import { withTranslation } from 'react-i18next';
import { S3_PREFIX } from '../../Config';

import { SubContainer, VideoContainer } from './styles';

import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import Video from './Video';

class Videos extends React.Component {
    render() {
        var VideoList = [
            {
                Annotations: [
                    { Seconds: 0, Title: 'What is ABEL?' },
                    { Seconds: 24, Title: 'Who are we?' },
                    { Seconds: 150, Title: 'Add clients' },
                    { Seconds: 297, Title: 'Tell your clients this' },
                    { Seconds: 343, Title: 'Revoke access' },
                    { Seconds: 452, Title: 'Create groupings' },
                    { Seconds: 585, Title: 'Notifications and chat' }
                ],
                VideoName: 'Get started', VideoUrl: `${S3_PREFIX}manuals/GetStarted.mp4`
            },
            {
                Annotations: [
                    { Seconds: 0, Title: 'Måltidsinntillinger' },
                    { Seconds: 38, Title: 'Legge til en enkelt rett' },
                    { Seconds: 153, Title: 'Legg til måltidsmal' },
                    { Seconds: 196, Title: 'Aktivitetsnivå og næringsstoffer' },
                    { Seconds: 274, Title: 'Autoskalering av egne retter i appen' },
                    { Seconds: 307, Title: 'Logge mat' },
                    { Seconds: 358, Title: 'Biblioteket & lage egne retter' },
                    { Seconds: 399, Title: 'Lage en måltidsmal' },
                    { Seconds: 487, Title: 'Obs obs måltidsmal' }
                ],
                VideoName: 'Kostholdsdelen', VideoUrl: `${S3_PREFIX}manuals/Meals.mp4`
            },
            {
                Annotations: [
                    { Seconds: 0, Title: 'Strength sessions / templates' },
                    { Seconds: 815, Title: 'Test session' },
                    { Seconds: 911, Title: 'Legge til økt fra mal' },
                    { Seconds: 1245, Title: 'Progress' }
                ],
                VideoName: 'Strength', VideoUrl: `${S3_PREFIX}manuals/Strength.mp4`
            },
            {
                Annotations: [
                    { Seconds: 0, Title: 'Endurance' },
                    { Seconds: 540, Title: 'Activities' },
                    { Seconds: 725, Title: 'Body graphs / ABEL scale' }
                ],
                VideoName: 'Endurance, activity and body graphs', VideoUrl: `${S3_PREFIX}manuals/Endurance.mp4`
            }
        ];

        return (
            <SubContainer>
                {
                    VideoList.map(({ Annotations, VideoName, VideoUrl }, VideoIndex) => {
                        return (
                            <VideoContainer key={VideoIndex}>
                                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={VideoName} TextAlign="center" />

                                <Spacer Size="small" />

                                <Video Annotations={Annotations} Url={VideoUrl} />
                            </VideoContainer>
                        );
                    })
                }
            </SubContainer>
        );
    }
}

export default withTranslation()(Videos);