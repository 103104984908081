import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearTrainingProgram, DeleteTrainingPrograms, GetTrainingProgramDetail, SaveDataInProps, UpdatePausedSharing_TrainingPrograms, UpdateShareAbilityStatus_TrainingPrograms, UpdateSharedToCoachStatus_TrainingPrograms } from '../../Services/Actions';
import history from '../../history';

import { CustomizationContainer, CustomizationItemContainer, CustomizationItemContainerHeader, DayContainer, DayNumber, DaysContainer, RestDayContainer, RestDayImage, StyledHeaderButtonGroup, WorkoutTemplateContainer } from './styles';

import AbelModal from '../../Components/Modal';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import ContentSharingDetails from '../../Components/ContentSharingDetails';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import { WorkoutTemplateListItem } from '../../Components/Lists/ListItems';

import EditShareAbilityStatusModal from '../EditShareAbilityStatusModal';
import HowSharingWorksModal from '../HowSharingWorksModal';
import Info from '../Info';
import ShareContentModal from '../ShareContentModal';
import WorkoutTemplateModal from '../WorkoutTemplate';

import Copy from '../../Assets/Icons/Copy_White.png';
import DeleteX from '../../Assets/Icons/X_White.png';
import Edit from '../../Assets/Icons/Pencil_White_Filled.png';
import WorkoutRestDay from '../../Assets/Illustrations/WorkoutRestDay.png';

const CUSTOM = {
    CustomSettings: {
        Location: '', Location_IsCustom: false,
        Name: '', Name_IsCustom: false,
        Note: '', Note_IsCustom: false,
        StartTime: '', StartTime_IsCustom: false
    },
    ShowCustomSettings: false
};

const MakeCustomSettings = (CustomSettings = {}) => {
    var NewCustomSettings = { ...CUSTOM, CustomSettings: { ...CUSTOM.CustomSettings, ...CustomSettings } };

    if (CustomSettings.hasOwnProperty('Location')) NewCustomSettings.CustomSettings.Location_IsCustom = true;
    if (CustomSettings.hasOwnProperty('Name')) NewCustomSettings.CustomSettings.Name_IsCustom = true;
    if (CustomSettings.hasOwnProperty('Note')) NewCustomSettings.CustomSettings.Note_IsCustom = true;
    if (CustomSettings.hasOwnProperty('StartTime')) NewCustomSettings.CustomSettings.StartTime_IsCustom = true;

    return NewCustomSettings;
}

class TrainingProgram extends React.Component {
    state = {
        DayIds: [],
        Days: [],
        Loading: true,
        ShowDeleteTrainingProgramModal: false,
        ShowEditShareAbilityStatusModal: false,
        ShowEditShareAbilityStatusModalDetails: {},
        ShowHowSharingWorksModal: false,
        ShowShareContentModal: false,
        ShowTemplateDetailModal: false,
        TemplateDetail_Preview: {},
        Updating: false
    };

    componentDidMount() {
        this._isMounted = true;

        this.props.GetTrainingProgramDetail({ TrainingProgramId: this.props.TemplateDetail_Preview.TrainingProgramId }).then(() => {
            if (!this.props.TryingGetTrainingProgramDetailError) {
                var { Days, NumDays: TemplateDays, ProgramName } = this.props.TrainingProgram.TrainingProgram;

                var NewDayIds = [];
                var NewDays = [];

                var DayId;
                for (var i = 0; i < Days.length; i++) {
                    DayId = `Day-${Days[i].DayNr}`;

                    NewDayIds.push(DayId);
                    NewDays.push({ DayId, WorkoutTemplateIds: [], WorkoutTemplates: [] });

                    for (var a = 0; a < Days[i].Workouts.length; a++) {
                        var { CustomSettings, TemplateDetails: { Location, OwnerDetails, StartTime, TemplateName, TrainerNote, Units, WorkoutType }, TemplateId } = Days[i].Workouts[a];

                        CustomSettings = MakeCustomSettings(CustomSettings);

                        NewDays[NewDays.length - 1].WorkoutTemplateIds.push(`TemplateId-${TemplateId}`);
                        NewDays[NewDays.length - 1].WorkoutTemplates.push({ CustomSettings, TemplateDetails: { Location, StartTime, TemplateName, TrainerNote }, TemplateId, TemplateName, Units, ...OwnerDetails, WorkoutType });
                    }
                }

                if (this._isMounted) this.setState({ DayIds: NewDayIds, Days: NewDays, TemplateDays, TrainingProgramId: this.props.TemplateDetail_Preview.TrainingProgramId, ProgramName, Loading: false });
            } else this.setState({ Loading: false });
        });
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        this.props.ClearTrainingProgram();
    }

    onClickAssignClients = () => {
        var { OnAssignClients, TrainingProgram: { TrainingProgram: { ProgramName, NumDays: TemplateDays, TrainingProgramId, DisplayName, Sex, UserId, UserPhoto } } } = this.props;

        this.props.OnHide();

        var Template = { ProgramName, TemplateDays, TrainingProgramId, UserDetails: { DisplayName, Sex, UserId, UserPhoto } };

        if (OnAssignClients) OnAssignClients(this.props.TrainingProgram.TrainingProgram);
        else this.props.SaveDataInProps({ SelectedTemplate: Template }).then(() => history.push('/add-training-program'));
    }

    onClickEditTemplate = () => {
        this.props.OnHide();
        history.push(`/library/training-program/${this.props.TemplateDetail_Preview.TrainingProgramId}`);
    }

    onCopyTrainingProgram = () => {
        var TrainingProgramId = this.props.TemplateDetail_Preview.TrainingProgramId;
        var { TrainingProgram } = this.props;

        this.props.SaveDataInProps({ CopyTrainingProgramDetails: { CopiedTrainingProgramId: TrainingProgramId, ProgramDetails: TrainingProgram } }).then(() => history.push('/library/training-program'));
    }

    onDeleteTrainingProgram = () => {
        var { TrainingProgramId } = this.props.TemplateDetail_Preview;

        this.props.DeleteTrainingPrograms({ TrainingProgramId }).then(() => {
            if (!this.props.TryingDeleteTrainingProgramsError) {
                this.onToggleDeleteTrainingProgramModal(false);
                this.props.OnHide(TrainingProgramId);
            }
        });
    }

    onToggleDeleteTrainingProgramModal = ShowDeleteTrainingProgramModal => {
        this.setState({ ShowDeleteTrainingProgramModal });
    }

    onToggleShowTemplateDetailModal = (ShowTemplateDetailModal, TemplateDetail_Preview, TemplateId) => {
        var { SelectedTemplate, SelectedTemplateId } = this.state;

        if (TemplateId && Number(TemplateId) === Number(SelectedTemplateId)) { SelectedTemplate = {}; SelectedTemplateId = null; }
        
        this.setState({ SelectedTemplate, SelectedTemplateId, ShowTemplateDetailModal, TemplateDetail_Preview });
    }

    onPauseSharing = IsPaused => {
        this.props.UpdatePausedSharing_TrainingPrograms({ IsPaused, TrainingProgramId: this.props.TemplateDetail_Preview.TrainingProgramId })
    }

    onShareAgain = Email => {
        this.props.UpdateSharedToCoachStatus_TrainingPrograms({ Emails: [ Email ], NewStatus: 'Pending', TrainingProgramId: this.props.TemplateDetail_Preview.TrainingProgramId });
    }

    onShareContentToEmails = ({ Emails, ContentIds }) => {
        this.props.UpdateSharedToCoachStatus_TrainingPrograms({ Emails, NewStatus: 'Pending', TrainingProgramId: this.props.TemplateDetail_Preview.TrainingProgramId, TrainingProgramIds: ContentIds }).then(() => {
            this.onToggleShowShareContentModal(false);
        });
    }

    onToggleShowEditShareAbilityStatusModal = ({ ContentId = null, ContentIndex = null, ShowEditShareAbilityStatusModal, CurrentStatus = null }) => {
        this.setState({ ShowEditShareAbilityStatusModal, ShowEditShareAbilityStatusModalDetails: { ContentId, ContentIndex, CurrentStatus } });
    }

    onToggleShowHowSharingWorksModal = ShowHowSharingWorksModal => {
        this.setState({ ShowHowSharingWorksModal });
    }

    onToggleShowShareContentModal = ShowShareContentModal => {
        this.setState({ ShowShareContentModal });
    }

    onUnshareContent = Email => {
        this.props.UpdateSharedToCoachStatus_TrainingPrograms({ Emails: [ Email ], NewStatus: 'Unshare', TrainingProgramId: this.props.TemplateDetail_Preview.TrainingProgramId });
    }

    onUpdateShareAbilityStatus = ({ ContentId, NewStatus, NewStatusName }) => {
        if (+NewStatus !== +this.props.TrainingProgram.SharingDetails.ShareAbilityStatusId) return this.props.UpdateShareAbilityStatus_TrainingPrograms({ NewStatus, NewStatusName, TrainingProgramId: ContentId }).then(() => this.props.OnUpdateMultiSelectList({ ContentId, NewStatus, NewStatusName }))

        return;
    }

    onUpdateSharedToCoachStatus = ({ NewStatus, PersonalResponse }) => {
        this.setState({ Updating: true });
        this.props.UpdateSharedToCoachStatus_TrainingPrograms({ Emails: [ this.props.UserDetails.Email ], NewStatus, PersonalResponse, TrainingProgramId: this.props.TemplateDetail_Preview.TrainingProgramId }).then(() => {
            this.setState({ Updating: false }, () => {
                if (NewStatus === 'reject' || NewStatus === 'delete') this.props.OnHide();

                this.props.OnLoadPrograms();
            });
        });
    }

    renderButtons = () => {
        if (this.props.TryingGetTrainingProgramDetail) return null;

        var { t } = this.props;
        var { Device, TrainingProgram: { TrainingProgram } } = this.props;

        var Buttons = [];
        if (!!TrainingProgram && !!+TrainingProgram.IsOwner) {
            Buttons.push({ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: Edit, IconPosition: 'left', OnClick: this.onClickEditTemplate, Title: t('edit_with_first_char_uppercase') });
            Buttons.push({ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: DeleteX, IconPosition: 'left', OnClick: () => this.onToggleDeleteTrainingProgramModal(true), Title: t('delete_with_first_char_uppercase') });
        }
        if (TrainingProgram.SharingDetails.SharedStatus === 1) Buttons.push({ BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: Copy, IconPosition: 'left', OnClick: this.onCopyTrainingProgram, Title: t('copy_template_to_template') });

        if (!Buttons.length) return null;

        return (
            <>
                <ButtonGroup
                    Buttons={Buttons}
                    ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />
            </>
        );
    }

    renderDeleteTrainingProgramModal = () => {
        var { t } = this.props;
        var { ShowDeleteTrainingProgramModal } = this.state;

        if (ShowDeleteTrainingProgramModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteTrainingProgram}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    Information={t('template_delete_warning')}
                    Loading={this.props.TryingDeleteTrainingPrograms}
                    OnHide={() => this.onToggleDeleteTrainingProgramModal(false)}
                    Show={ShowDeleteTrainingProgramModal}
                />
            );
        }
    }

    renderShowEditShareAbilityStatusModal = () => {
        var { ShowEditShareAbilityStatusModal } = this.state;

        if (ShowEditShareAbilityStatusModal) {
            var { SharingDetails } = this.props.TrainingProgram;

            return (
                <EditShareAbilityStatusModal
                    ContentId={this.props.TemplateDetail_Preview.TrainingProgramId}
                    CurrentStatus={SharingDetails.ShareAbilityStatusId}
                    Loading={this.props.TryingUpdateShareAbilityStatus_TrainingPrograms}
                    OnHide={() => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: false })}
                    OnUpdateShareAbilityStatus={this.onUpdateShareAbilityStatus}
                    Show={ShowEditShareAbilityStatusModal}
                />
            );
        }
    }

    renderShowHowSharingWorksModal = () => {
        var { ShowHowSharingWorksModal } = this.state;

        if (ShowHowSharingWorksModal) {
            return (
                <HowSharingWorksModal
                    OnHide={() => this.onToggleShowHowSharingWorksModal(false)}
                    Show={ShowHowSharingWorksModal}
                />
            );
        }
    }

    renderShowShareContentModal = () => {
        var { ShowEditShareAbilityStatusModal, ShowHowSharingWorksModal, ShowShareContentModal } = this.state;

        if (ShowShareContentModal) {
            var { TryingUpdateSharedToCoachStatus_TrainingPrograms } = this.props;

            var { TrainingProgram } = this.props.TrainingProgram;

            return (
                <ShareContentModal
                    ContentIdName="TrainingProgramId"
                    ContentType="TrainingPrograms"
                    DisplayNone={!!ShowEditShareAbilityStatusModal || !!ShowHowSharingWorksModal}
                    OnHide={() => this.onToggleShowShareContentModal(false)}
                    OnSubmit={({ Emails, ContentIds }) => this.onShareContentToEmails({ Emails, ContentIds })}
                    OnToggleShowEditShareAbilityStatusModal={({ ContentIndex, ContentId, CurrentStatus }) => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: true, ContentId, ContentIndex, CurrentStatus })}
                    OnToggleShowHowSharingWorksModal={() => this.onToggleShowHowSharingWorksModal(true)}
                    Loading={TryingUpdateSharedToCoachStatus_TrainingPrograms}
                    SharingContent={[{ ...TrainingProgram, TemplateDays: TrainingProgram.NumDays, ...TrainingProgram.SharingDetails, ...TrainingProgram.SharingDetails.OwnerDetails, TrainingProgramId: this.props.TemplateDetail_Preview.TrainingProgramId }]}
                    Show={ShowShareContentModal}
                />
            );
        }
    }

    renderTemplateDays = () => {
        if (this.props.TryingGetTrainingProgramDetail) return null;

        var { t } = this.props;
        var { Days } = this.state;

        return (
            <DaysContainer>
                {
                    Days.map(({ DayId, WorkoutTemplates }, DayIndex) => {
                        return (
                            <DayContainer key={DayId}>
                                <DayNumber>{t('day')} {DayIndex + 1}</DayNumber>
                                {
                                    !WorkoutTemplates.length ?
                                    <RestDayContainer className="RestDayContainer">
                                        <PageText FontColor="white" FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('rest_day').toUpperCase()} />
                    
                                        <Spacer Size="small" />
                    
                                        <RestDayImage ImageSrc={WorkoutRestDay} SelfLoading />
                                    </RestDayContainer>
                                :
                                    WorkoutTemplates.map(WorkoutTemplate => {
                                        var { CustomSettings } = WorkoutTemplate.CustomSettings;

                                        return (
                                            <WorkoutTemplateContainer key={WorkoutTemplate.TemplateId} className="WorkoutTemplateContainer">
                                                <WorkoutTemplateListItem
                                                    Item={WorkoutTemplate}
                                                    HideSelect
                                                    IsMultiSelect
                                                    ViewTemplate={Template => this.onToggleShowTemplateDetailModal(true, Template)}
                                                />

                                                {
                                                    <CustomizationContainer className="CustomizationContainer">
                                                        <CustomizationItemContainer className="CustomizationItemContainer">
                                                            <CustomizationItemContainerHeader className="CustomizationItemContainerHeader">
                                                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('Name')}:`} />

                                                                <MultiText
                                                                    Texts={[
                                                                        { FontFamily: 'medium', FontSize: 'small', Text: `${t('custom')}: ` },
                                                                        { FontColor: !!CustomSettings.Name_IsCustom ? 'abel-blue' : 'black', FontFamily: 'semibold', FontSize: 'small', Text: !!CustomSettings.Name_IsCustom ? t('on') : t('off') }
                                                                    ]}
                                                                />
                                                            </CustomizationItemContainerHeader>

                                                            {
                                                                !!CustomSettings.Name_IsCustom ?
                                                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={CustomSettings.Name} TextAlign="left" />
                                                            :
                                                                <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('none_with_first_char_uppercase')} TextAlign="left" />
                                                            }
                                                        </CustomizationItemContainer>

                                                        <CustomizationItemContainer className="CustomizationItemContainer">
                                                            <CustomizationItemContainerHeader className="CustomizationItemContainerHeader">
                                                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('custom_trainer_note')}:`} />

                                                                <MultiText
                                                                    Texts={[
                                                                        { FontFamily: 'medium', FontSize: 'small', Text: `${t('custom')}: ` },
                                                                        { FontColor: !!CustomSettings.Note_IsCustom ? 'abel-blue' : 'black', FontFamily: 'semibold', FontSize: 'small', Text: !!CustomSettings.Note_IsCustom ? t('on') : t('off') }
                                                                    ]}
                                                                />
                                                            </CustomizationItemContainerHeader>

                                                            {
                                                                !!CustomSettings.Note_IsCustom ?
                                                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={CustomSettings.Note} TextAlign="left" />
                                                            :
                                                                <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('none_with_first_char_uppercase')} TextAlign="left" />
                                                            }
                                                        </CustomizationItemContainer>

                                                        <CustomizationItemContainer className="CustomizationItemContainer">
                                                            <CustomizationItemContainerHeader className="CustomizationItemContainerHeader">
                                                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('custom_location')}:`} />

                                                                <MultiText
                                                                    Texts={[
                                                                        { FontFamily: 'medium', FontSize: 'small', Text: `${t('custom')}: ` },
                                                                        { FontColor: !!CustomSettings.Location_IsCustom ? 'abel-blue' : 'black', FontFamily: 'semibold', FontSize: 'small', Text: !!CustomSettings.Location_IsCustom ? t('on') : t('off') }
                                                                    ]}
                                                                />
                                                            </CustomizationItemContainerHeader>

                                                            {
                                                                !!CustomSettings.Location_IsCustom ?
                                                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={CustomSettings.Location} TextAlign="left" />
                                                            :
                                                                <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('none_with_first_char_uppercase')} TextAlign="left" />
                                                            }
                                                        </CustomizationItemContainer>
                                                        
                                                        <CustomizationItemContainer className="CustomizationItemContainer">
                                                            <CustomizationItemContainerHeader className="CustomizationItemContainerHeader">
                                                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('custom_starttime')}:`} />

                                                                <MultiText
                                                                    Texts={[
                                                                        { FontFamily: 'medium', FontSize: 'small', Text: `${t('custom')}: ` },
                                                                        { FontColor: !!CustomSettings.StartTime_IsCustom ? 'abel-blue' : 'black', FontFamily: 'semibold', FontSize: 'small', Text: !!CustomSettings.StartTime_IsCustom ? t('on') : t('off') }
                                                                    ]}
                                                                />
                                                            </CustomizationItemContainerHeader>

                                                            {
                                                                !!CustomSettings.StartTime_IsCustom ?
                                                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={CustomSettings.StartTime} TextAlign="left" />
                                                            :
                                                                <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('none_with_first_char_uppercase')} TextAlign="left" />
                                                            }
                                                        </CustomizationItemContainer>
                                                    </CustomizationContainer>
                                                }
                                                
                                            </WorkoutTemplateContainer>
                                        );
                                    })   
                                }
                            </DayContainer>
                        );
                    })
                }
            </DaysContainer>
        );
    }

    renderTemplateDetailModal = () => {
        var { ShowTemplateDetailModal, TemplateDetail_Preview } = this.state;

        if (ShowTemplateDetailModal) {
            return (
                <WorkoutTemplateModal
                    OnHide={TemplateId => this.onToggleShowTemplateDetailModal(false, null, TemplateId)}
                    OnLoadTemplates={() => null}
                    TemplateDetail_Preview={TemplateDetail_Preview}
                    Show={ShowTemplateDetailModal}
                />
            );
        }
    }

    renderModalBody = () => {
        if (this.state.Loading || this.props.TryingGetTrainingProgramDetail) return <Loading />;

        var { t } = this.props;
        var { Device, TemplateDetail_Preview: { ProgramName }, TrainingProgram: { TrainingProgram: { NumDays, ProgramDescription, SharingDetails } }, TryingUpdatePausedSharing_TrainingPrograms, TryingUpdateShareAbilityStatus_TrainingPrograms, TryingUpdateSharedToCoachStatus_TrainingPrograms } = this.props;
        var { Updating } = this.state;

        var TemplateLengthText = <MultiText
            Texts={[
                { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('length')} -` },
                { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${NumDays} ${t('days').toLowerCase()}` }
            ]}
        />

        var Buttons = [];

        Buttons.push({ BackgroundColor: 'white-concrete', Title: TemplateLengthText });
        if (SharingDetails.SharedStatus === 1) Buttons.push({ Color: 'blue-astronaut', ColorHover: 'blue-astronaut', FontFamily: 'semibold', OnClick: this.onClickAssignClients, Title: t('assign_to_clients') });

        return (
            <>
                {Updating && <Loading />}

                <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text={ProgramName} TextAlign="center" />

                <Spacer Size="medium" />
                
                {this.renderButtons()}

                <Spacer Size="medium" />
                
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={ProgramDescription} TextAlign="center" />

                <Spacer Size="medium" />

                <StyledHeaderButtonGroup
                    Buttons={Buttons}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />

                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'medium' : 'small'} />

                {this.renderTemplateDays()}

                <Spacer Size="medium" />

                <ContentSharingDetails
                    Loading={TryingUpdatePausedSharing_TrainingPrograms || TryingUpdateShareAbilityStatus_TrainingPrograms || TryingUpdateSharedToCoachStatus_TrainingPrograms}
                    OnShareAgain={this.onShareAgain}
                    OnPauseSharing={this.onPauseSharing}
                    onToggleShowShareContentModal={() => this.onToggleShowShareContentModal(true)}
                    OnToggleShowEditShareAbilityStatusModal={() => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: true })}
                    OnToggleShowHowSharingWorksModal={() => this.onToggleShowHowSharingWorksModal(true)}
                    OnUnshare={this.onUnshareContent}
                    OnUpdateSharedToCoachStatus={this.onUpdateSharedToCoachStatus}
                    SharingDetails={SharingDetails}
                />
            </>
        );
    }

    render() {
        var { OnHide, Show } = this.props;
        var { ShowDeleteTrainingProgramModal, ShowEditShareAbilityStatusModal, ShowHowSharingWorksModal, ShowShareContentModal, ShowTemplateDetailModal } = this.state;

        return (
            <>
                <AbelModal
                    BottomButton="cancel"
                    BottomButtonOnClick={OnHide}
                    DisplayNone={(ShowDeleteTrainingProgramModal || ShowEditShareAbilityStatusModal || ShowHowSharingWorksModal || ShowShareContentModal || ShowTemplateDetailModal)}
                    GA_PathName="TrainingProgram"
                    Show={Show}
                    Size="large"
                    OnHide={OnHide}
                    TopButton="cancel"
                    TopButtonOnClick={OnHide}
                >
                    {this.renderModalBody()}
                </AbelModal>

                {this.renderDeleteTrainingProgramModal()}
                {this.renderShowEditShareAbilityStatusModal()}
                {this.renderShowHowSharingWorksModal()}
                {this.renderShowShareContentModal()}
                {this.renderTemplateDetailModal()}
            </>
        );
    }
}

TrainingProgram.propTypes = {
    OnAssignClients: PropTypes.func,
    OnHide: PropTypes.func.isRequired,
    OnLoadPrograms: PropTypes.func.isRequired,
    OnUpdateMultiSelectList: PropTypes.func,
    OnUpdateSharedToCoachStatus: PropTypes.func,
    Show: PropTypes.bool.isRequired,
    TemplateDetail_Preview: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        TrainingProgram: state.Templates.TrainingProgram,

        TryingDeleteTrainingPrograms: state.Templates.TryingDeleteTrainingPrograms,
        TryingDeleteTrainingProgramsError: state.Templates.TryingDeleteTrainingProgramsError,
        TryingGetTrainingProgramDetail: state.Templates.TryingGetTrainingProgramDetail,
        TryingGetTrainingProgramDetailError: state.Templates.TryingGetTrainingProgramDetailError,
        TryingUpdatePausedSharing_TrainingPrograms: state.Templates.TryingUpdatePausedSharing_TrainingPrograms,
        TryingUpdatePausedSharing_TrainingProgramsError: state.Templates.TryingUpdatePausedSharing_TrainingProgramsError,
        TryingUpdateShareAbilityStatus_TrainingPrograms: state.Templates.TryingUpdateShareAbilityStatus_TrainingPrograms,
        TryingUpdateShareAbilityStatus_TrainingProgramsError: state.Templates.TryingUpdateShareAbilityStatus_TrainingProgramsError,
        TryingUpdateSharedToCoachStatus_TrainingPrograms: state.Templates.TryingUpdateSharedToCoachStatus_TrainingPrograms,
        TryingUpdateSharedToCoachStatus_TrainingProgramsError: state.Templates.TryingUpdateSharedToCoachStatus_TrainingProgramsError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearTrainingProgram, DeleteTrainingPrograms, GetTrainingProgramDetail, SaveDataInProps, UpdatePausedSharing_TrainingPrograms, UpdateShareAbilityStatus_TrainingPrograms, UpdateSharedToCoachStatus_TrainingPrograms } )(TrainingProgram));