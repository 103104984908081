import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import * as styles from './styles';

import PageText from '../Text/PageText';

import { PageRange } from '../../Functions';

class PaginationCounter extends React.Component {
    state = { IsHovering: false };

    render() {
        var { t } = this.props;
        var { OnPaginate, PageNo, PageSize, TotalPages, TotalRecords } = this.props;

        if (!TotalPages) TotalPages = Math.floor(TotalRecords / PageSize) + 1;

        var { EndPageNo, StartPageNo } = PageRange({ PageNo, TotalPages });

        return (
            <styles.PaginationContainer className="PaginationContainer">
                {
                        TotalPages !== 1 &&
                        <>
                            {PageNo !== 1 && <PageText FontColorHover={'blue-abel'} FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => OnPaginate({ NewPageNo: PageNo - 1 })} Text={t('prev')} TextAlign="left" />}

                            <styles.PageNumbersContainer className="PageNumbersContainer">
                                {
                                    Array((EndPageNo - StartPageNo) + 1).fill().map((item, index) => {
                                        var Selected = PageNo === StartPageNo + index;

                                        return (
                                            <styles.PageNumberContainer className="PageNumberContainer" key={index} onClick={() => OnPaginate({ NewPageNo: StartPageNo + index })} Selected={Selected}>
                                                {`${StartPageNo + index}`}
                                            </styles.PageNumberContainer>
                                        );
                                    })
                                }
                            </styles.PageNumbersContainer>

                            {!!TotalPages && PageNo !== TotalPages && <PageText FontColorHover={'blue-abel'} FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-end" NoMargin OnClick={() => OnPaginate({ NewPageNo: PageNo + 1 })} Text={t('next_with_first_char_uppercase')} TextAlign="right" />}
                        </>
                    }
            </styles.PaginationContainer>
        );
    }
}

PaginationCounter.propTypes = {
    OnPaginate: PropTypes.func,
    PageNo: PropTypes.number,
    TotalPages: PropTypes.number
}

export default withTranslation()(PaginationCounter);