import styled, { keyframes } from 'styled-components';

const Animation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const LoadingContainer = styled.div`
    align-items: center;
    background-color: ${props => props.NoBackground ? null : 'rgba(0,0,0,0.1)'};
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: ${props => props.Position};
    right: 0;
    top: 0;
    z-index: 1001;
`;

export const LoadingRingContainer = styled.div`
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  
    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 51px;
        height: 51px;
        margin: 6px;
        border: 6px solid var(--abel-blue);
        border-radius: 50%;
        animation: ${Animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: var(--abel-blue) transparent transparent transparent;
    }

    div:nth-child(1) {
        animation-delay: -0.45s;
    }

    div:nth-child(2) {
        animation-delay: -0.3s;
    }

    div:nth-child(3) {
        animation-delay: -0.15s;
    }
`;