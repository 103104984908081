import React from "react";

import { useTranslation } from 'react-i18next';
import moment from 'moment';

import * as styles from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import EditIcon from '../../Assets/Icons/Pencil_Gray.png';
import InfoIcon from '../../Assets/Icons/Info.png';
import PlusIcon from '../../Assets/Icons/PlusButton_Active.png';

export default function ContentSharingDetails(props) {
    const { t } = useTranslation();

    var { SharingDetails } = props;
    
    const onShareAgain = Email => {
        props.OnShareAgain(Email);
    }

    const onStopSharing = Email => {
        props.OnUnshare(Email);
    }

    const onToggleIsPaused = () => {
        props.OnPauseSharing(+(!props.SharingDetails.IsPaused));
    }

    const onUpdateSharedToCoachStatus = ({ NewStatus }) => {
        props.OnUpdateSharedToCoachStatus({ NewStatus });
    }

    // Show Just Owner As It Is Only Available As It Is A Child
        if (SharingDetails.SharedStatus === null) {
            return (
                <styles.Container>
                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={`${t('sharing_details_content_owned_by')}: ${SharingDetails.OwnerDetails.Email}`} TextAlign="left" />
                </styles.Container>
            );
        }

    // Show Just Owner As It Is Deleted
        if (!!props.IsDeleted) {
            return (
                <styles.Container>
                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={`${t('sharing_details_content_owned_by')}: ${SharingDetails.OwnerDetails.Email}`} TextAlign="left" />
                
                    <Spacer Size="small" />

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('sharing_details_content_deleted')} TextAlign="left" />
                </styles.Container>
            );
        }

    // Show Preview Information Only
        if (SharingDetails.SharedStatus !== 1) {
            return (
                <styles.Container>
                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={`${t('sharing_details_content_owned_by')}: ${SharingDetails.OwnerDetails.Email}`} TextAlign="left" />
                
                    <Spacer Size="small" />

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('sharing_details_shared_by')}: ${SharingDetails.OwnerDetails.InvitedBy_Email}`} TextAlign="left" />

                    {
                        SharingDetails.SharedStatus === 2 &&
                        <>
                            <Spacer Size="extra-small" />

                            <ButtonGroup
                                Buttons={[
                                    { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', OnClick: () => onUpdateSharedToCoachStatus({ NewStatus: 'reject' }), Size: 'small', Title: t('reject') },
                                    { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', OnClick: () => onUpdateSharedToCoachStatus({ NewStatus: 'accept' }), Size: 'small', Title: t('accept') }
                                ]}
                                ButtonWidth="fit-content"
                                NotTouching
                                OwnRows={false}
                            />
                        </>
                    }
                </styles.Container>
            );
        }

    if (SharingDetails.RelationshipType === 'Self') {
        return (
            <styles.Container>
                {props.Loading && <Loading />}

                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('sharing_details_sharing_status')} TextAlign="left" />
                
                <Spacer Size="small" />

                <ButtonGroup
                    Buttons={[
                        { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-light', FontFamily: 'medium', FontSize: 'medium-1', Icon: InfoIcon, IconPosition: 'left', OnClick: props.OnToggleShowHowSharingWorksModal, Title: t('sharing_details_how_it_works_and_what_it_is') }
                    ]}
                    NormalWhiteSpace
                />

                <Spacer Size="small" />
    
                <styles.ShareAbilityStatusContainer className="ShareAbilityStatusContainer">
                    <MultiText
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('sharing_details_shareability_status')}: ` },
                            { FontColorHover: 'black-hover', FontFamily: 'semibold', FontSize: 'medium-1', OnClick: props.OnToggleShowEditShareAbilityStatusModal, Text: SharingDetails.ShareAbility }
                        ]}
                    />

                    <styles.StyledEditIcon ImageSrc={EditIcon} OnClick={props.OnToggleShowEditShareAbilityStatusModal} />
                </styles.ShareAbilityStatusContainer>
    
                <Spacer Size="extra-extra-small" />
    
                <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('sharing_details_shareability_status_info')} TextAlign="left" />
    
                <Spacer Size="small" />

                {
                    !!SharingDetails.ActiveShares.length &&
                    <>
                        <styles.PausedContainer className="PausedContainer">
                        {!!+SharingDetails.IsPaused && <styles.StyledPauseText FontColor="red-bittersweet" FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('sharing_details_sharing_paused')} TextAlign="left" />}

                        <ButtonGroup
                            Buttons={[
                                { BackgroundColorHover: 'white-concrete-light', FontFamily: 'semibold', FontSize: 'small', OnClick: onToggleIsPaused, Title: !!+SharingDetails.IsPaused ? t('sharing_details_sharing_resume') : t('sharing_details_sharing_pause_sharing') }
                            ]}
                        />
                    </styles.PausedContainer>

                    <Spacer Size="small" />
                    </>
                }

                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('sharing_details_active_sharing')} TextAlign="left" />

                <Spacer Size="extra-extra-small" />
                    
                {
                    !SharingDetails.ActiveShares.length ?
                    <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('sharing_details_active_sharing_none')} TextAlign="left" />
                :
                    <styles.SharingListContainer className="SharingListContainer">
                        {
                            SharingDetails.ActiveShares.map(ActiveShare => {
                                var { Email, NumCopies, SharedDate, StatusName } = ActiveShare;

                                return (
                                    <styles.ActiveShareContainer className="ActiveShareContainer" key={Email}>
                                        <styles.StyledEllipsisText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={Email} TextAlign="left" />

                                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={moment(SharedDate).format('[WeeklyViewHeader]')} TextAlign="left" />

                                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={StatusName} TextAlign="left" />

                                        {NumCopies ? <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('sharing_details_has_copied_times', { NumCopies })} TextAlign="left" /> : <div />}

                                        <PageText FontColor="red-bittersweet" FontColorHover="red-bittersweet-hover" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-end" NoMargin OnClick={() => onStopSharing(Email)} Text={t('sharing_details_sharing_stop_sharing')} TextAlign="right" />
                                    </styles.ActiveShareContainer>
                                );
                            })
                        }
                    </styles.SharingListContainer>
                }

                <Spacer Size="small" />

                <styles.AddCoachTextContainer className="AddCoachTextContainer">
                    <styles.AddCoachPlusIcon ImageSrc={PlusIcon} OnClick={props.onToggleShowShareContentModal} />

                    <PageText FontColor="blue-astronaut" FontColorHover="blue-astronaut-hover" FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin OnClick={props.onToggleShowShareContentModal} Text={t('add_new_coach')} TextAlign="center" />
                </styles.AddCoachTextContainer>

                <Spacer Size="small" />

                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('sharing_details_inactive_sharing')} TextAlign="left" />

                <Spacer Size="extra-extra-small" />
                    
                {
                    !SharingDetails.InactiveShares.length ?
                    <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('sharing_details_inactive_sharing_none')} TextAlign="left" />
                :
                    <styles.SharingListContainer className="SharingListContainer">
                        {
                            SharingDetails.InactiveShares.map(ActiveShare => {
                                var { Email, NumCopies, SharedDate, StatusName } = ActiveShare;
        
                                return (
                                    <styles.InactiveShareContainer className="InactiveShareContainer" key={Email}>
                                        <styles.StyledEllipsisText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={Email} TextAlign="left" />
        
                                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={moment(SharedDate).format('[WeeklyViewHeader]')} TextAlign="left" />
        
                                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={StatusName} TextAlign="left" />
        
                                        {NumCopies ? <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('sharing_details_has_copied_times', { NumCopies })} TextAlign="left" /> : <div />}
        
                                        <PageText FontColor="blue-abel" FontColorHover="blue-abel-hover" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-end" NoMargin OnClick={() => onShareAgain(Email)} Text={t('sharing_details_sharing_share_again')} TextAlign="right" />
                                    </styles.InactiveShareContainer>
                                );
                            })
                        }
                    </styles.SharingListContainer>
                }
            </styles.Container>
        );
    }

    if (SharingDetails.RelationshipType === 'Abel') {
        return (
            <styles.Container>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={`${t('sharing_details_content_owned_by')}: ${SharingDetails.OwnerDetails.DisplayName}`} TextAlign="left" />
                
                <Spacer Size="small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('sharing_details_shareability_status')}: ` },
                        { FontFamily: 'semibold', FontSize: 'medium-1', Text: t('sharing_details_cannot_share_abel_content') }
                    ]}
                />
            </styles.Container>
        );
    }

    return (
        <styles.Container>
            {
                SharingDetails.RelationshipType === 'Organization' ?
                <>
                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={`${t('sharing_details_content_owned_by')}: ${SharingDetails.OwnerDetails.DisplayName}`} TextAlign="left" />
                
                    <Spacer Size="extra-extra-small" />

                    <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('sharing_details_shared_by_organization')} TextAlign="left" />
                </>
            :
                <>
                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={`${t('sharing_details_content_owned_by')}: ${SharingDetails.OwnerDetails.Email}`} TextAlign="left" />
                
                    <Spacer Size="small" />

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('sharing_details_shared_by')}: ${SharingDetails.OwnerDetails.InvitedBy_Email}`} TextAlign="left" />

                    <Spacer Size="extra-small" />

                    <PageText FontColor="red-bittersweet" FontColorHover="red-bittersweet-hover" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => onUpdateSharedToCoachStatus({ NewStatus: 'delete' })} Text={t('sharing_details_delete_access')} TextAlign="left" />
                </>
            }

            <Spacer Size="small" />

            <MultiText
                Texts={[
                    { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('sharing_details_shareability_status')}: ` },
                    { FontFamily: 'semibold', FontSize: 'medium-1', Text: SharingDetails.ShareAbility }
                ]}
            />

            <Spacer Size="medium" />

            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('sharing_details_reshares')} TextAlign="left" />

            <Spacer Size="extra-small" />

            {
                !SharingDetails.Reshares.length ?
                    <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('sharing_details_reshares_none')} TextAlign="left" />
                :
                    <styles.SharingListContainer className="SharingListContainer">
                        {
                            SharingDetails.Reshares.map(ActiveShare => {
                                var { Email, NumCopies, SharedDate, StatusName } = ActiveShare;
        
                                return (
                                    <styles.InactiveShareContainer className="InactiveShareContainer" key={Email}>
                                        <styles.StyledEllipsisText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={Email} TextAlign="left" />
        
                                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={moment(SharedDate).format('[WeeklyViewHeader]')} TextAlign="left" />
        
                                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={StatusName} TextAlign="left" />
        
                                        {NumCopies ? <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('sharing_details_has_copied_times', { NumCopies })} TextAlign="left" /> : <div />}
        
                                        <div />
                                    </styles.InactiveShareContainer>
                                );
                            })
                        }
                    </styles.SharingListContainer>
                }

                {
                    SharingDetails.ShareAbilityStatusId !== 3 &&
                    <>
                        <Spacer Size="small" />

                        <styles.AddCoachTextContainer className="AddCoachTextContainer">
                            <styles.AddCoachPlusIcon ImageSrc={PlusIcon} OnClick={props.onToggleShowShareContentModal} />

                            <PageText FontColor="blue-astronaut" FontColorHover="blue-astronaut-hover" FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin OnClick={props.onToggleShowShareContentModal} Text={t('add_new_coach')} TextAlign="center" />
                        </styles.AddCoachTextContainer>
                    </>
                }
        </styles.Container>
    );
}