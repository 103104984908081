import styled from 'styled-components';
import { device } from '../../Config/device';

import Image from '../../Components/Image';
import Input from '../../Components/Form/Input';
import PageText from '../../Components/Text/PageText';

import Checkmark from '../../Assets/Icons/Checkmark_Selected.png';

export const DeleteIcon = styled.div`
    align-items: center;
    background-color: var(--red-bittersweet);
    border: 2px solid var(--red-bittersweet);
    border-radius: 50%;
    color: var(--white);
    display: flex;
    height: 30px;
    justify-content: center;
    margin-left: 10px;
    width: 30px;

    @media ${device.minwidth.ipad} {
        background-color: var(--white);
        border-color: var(--white-concrete);
        color: var(--white-concrete);

        :hover {
            background-color: var(--red-bittersweet);
            border-color: var(--red-bittersweet);
            color: var(--white);
            cursor: pointer;
        }
    }
`;

export const EditIcon = styled(Image)`
    height: 30px;
    margin-right: 15px;
    margin-top: 15px;
    width: 30px;
`;

export const IngredientContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 15px;

    @media ${device.minwidth.mobile} {
        flex-direction: row;
    }
`;

export const IngredientListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const IngredientNameStyled = styled(PageText)`
    flex: 1;
`;

export const IngredientOptionsContainer = styled.div`
    display: flex;

    @media ${device.maxwidth.mobile_small} {
        margin-top: 10px;
        width: 100%;
    }
`;

export const IngredientQuantityStyledInput = styled(Input)`
    @media ${device.minwidth.mobile} {
        width: 60px;
    }

    @media ${device.maxwidth.mobile_small} {
        flex: 1;
    }

    > input {
        border: 2px solid var(--white-concrete);
        text-align: center;
        text-indent: 0px;
    }
`;

export const IngredientsContainer = styled.div``;

export const IngredientUnit = styled(PageText)`
    border: 2px solid var(--white-concrete);
    border-radius: 21px;
    margin-left: 10px;
    padding: 2.5px 7px;

    @media ${device.maxwidth.mobile_small} {
        flex: 1;
    }

    :hover {
        background-color: var(--white-concrete);
        cursor: pointer;
    }
`;

export const MarkAsCompletedContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -15px;
    margin-top: -15px;
`;

export const MarkAsCompleted = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    display: block;
    height: 25px;
    margin-right: 5px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const ResponsiveColumns = styled.div`
    display: flex;
    flex-direction: column;
    
    > div {
        flex: 1
    }

    @media ${device.minwidth.laptop} {
        flex-direction: row;
    }
`;

export const SelectedDateContainer = styled.div`
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    margin-right: 15px;
    margin-top: 15px;
    padding: 5px 12px;
`;

export const SelectedDatesContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -15px;
    margin-top: -15px;
`;