export const ENV = process.env.REACT_APP_ENV;

export const RIDGE_API = process.env.REACT_APP_RIDGE_API;
export const SARBJIT_API = process.env.REACT_APP_SARBJIT_API;
export const SARBJIT_API_PROFILE_PICTURE = process.env.REACT_APP_SARBJIT_API_PROFILE_PICTURE;
export const AGGREGATION_API = process.env.REACT_APP_AGGREGATION_API;
export const NOTIFICATION_API = process.env.REACT_APP_NOTIFICATION_API;
export const CHAT_API = process.env.REACT_APP_CHAT_API;
export const AUTH_API = process.env.REACT_APP_AUTH_API;

export const OAUTH_TOKEN = process.env.REACT_APP_OAUTH_TOKEN;

export const S3_PREFIX = process.env.REACT_APP_S3_PREFIX;

export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

export const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;

export const APPLE_REDIRECT_URI = process.env.REACT_APP_APPLE_REDIRECT_URI;

export const BETA_SITE_URL = process.env.REACT_APP_BETA_SITE_URL;

export const FACEBOOK_CLIENT_ID = process.env.REACT_APP_FACEBOOK_CLIENT_ID;

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const WS_URL = process.env.REACT_APP_WS_URL;

export const SUBSCRIPTION_CANCEL_URL = process.env.REACT_APP_SUBSCRIPTION_CANCEL_URL;
export const SUBSCRIPTION_RETURN_URL = process.env.REACT_APP_SUBSCRIPTION_RETURN_URL;

export const SUPPORT_LINK = 'https://support.abel.fit';

// LIVE CHECKLIST
// DownloadTrainerPayoutSummary is using hard-coded localhost:3005 link
// Login Page Has Curus Credentials For API
// LeftNavigation.js and App.js Have HardCoded Values For BulkAddClients with UserId = 25734 OR 4871 (Trento, Feel24 Super admin)