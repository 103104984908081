import styled from 'styled-components';
import { device } from '../../Config/device';

import ButtonGroup from '../Buttons/ButtonGroup';
import Icon from '../Icon';
import InputLabelSideBySide from '../Form/InputLabelSideBySide';
import PageText from '../Text/PageText';

export const Container = styled.div`
    align-items: stretch;
    background-color: ${props => props.Completed ? props.MaxSet ? 'var(--orange)' : 'var(--blue-astronaut)' : 'var(--white)'};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 901px) {
        flex-direction: row;
    }
`;

export const DataContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Completed ? props.MaxSet ? 'var(--orange)' : 'var(--blue-astronaut)' : 'var(--white)'};
    color: ${props => props.Completed ? 'var(--white)' : 'var(--black)'};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    width: 100%;

    @media (max-width: 400px) {
        flex: 1;
        flex-direction: column;
        justify-content: center;
    }

    @media (min-width: 901px) {
        flex: 1;
        flex-direction: column;
        justify-content: center;
    }
`;

export const DataParentContainer = styled.div`
    // align-items: center;
    display: flex;
    position: relative;
    width: 100%;

    @media (max-width: 900px) {
        :not(:last-child) {
            border-bottom: 2px solid ${props => props.BorderColor ? props.BorderColor : 'var(--white-concrete)'};
        }
    }

    @media (min-width: 901px) {
        :not(:last-child) {
            border-right: 2px solid ${props => props.BorderColor ? props.BorderColor : 'var(--white-concrete)'};
        }
    }
`;

export const ExtraContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    > div:nth-child(2) {
        margin-left: 5px;
    }
`;

export const MaxPercentInfoIcon = styled(Icon)`
    height: 20px;
    width: 20px;

    @media (min-width: 901px) {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    // @media ${device.min}
`;

export const PopoverContent = styled.div`
    align-items: center;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 200px;
    padding: 10px;
`;

export const StyledHeaders = styled(PageText)`
    text-transform: uppercase;

    @media (max-width: 400px) {
        margin-bottom: 10px;
    }

    @media (min-width: 901px) {
        margin-bottom: 10px;
    }
`;

export const StyledInputLabelSideBySide = styled(InputLabelSideBySide)`
    background-color: var(--gray-ea);
    border: none;
    overflow: hidden;

    > div:first-child {
        background-color: var(--chat-gray);
        border-right: none;

        & input {
            background-color: var(--chat-gray);
        }
    }

    & input {
        border-radius: 10px;
    }
`;

export const StyledTestSetsButtonGroup = styled(ButtonGroup)`
    @media ${device.minwidth.ipad} {
        margin-right: 15px;
        margin-top: 15px;
    }
`;

export const TestContainer = styled.div`
    align-items: center;
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px;
    padding: 20px;

    @media ${device.maxwidth.mobile} {
        flex-direction: column;
        justify-content: center;
    }
`;

export const TestDataContainer = styled.div`
    // align-items: center;
    // display: flex;
    // flex-direction: row;
    // justify-content: flex-start;

    // > div:first-child {
    //     margin-right: 10px;
    // }
`;

export const TestDataParentContainer = styled.div`
    // align-items: center;
    // background-color: var(--white);
    // border-radius: 10px;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    // margin: 20px;
    // padding: 20px;

    // @media ${device.maxwidth.mobile} {
    //     justify-content: center;
    // }
`;

export const TestInnerContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    > div:not(:last-child) {
        margin-right: 10px;
    }

    @media ${device.minwidth.ipad} {
        margin-right: 15px;
        margin-top: 15px;
    }

    ${
        props => props.BreakEarly &&
        `
            @media ${device.maxwidth.mobile} {
                flex-direction: column;
                justify-content: center;

                > div:not(:last-child) {
                    margin-bottom: 10px;
                    margin-right: 0px;
                }
            }
        `
    }
`;