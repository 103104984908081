import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Popover from 'react-popover';

import { Container, DataContainer, DataParentContainer, ExtraContainer, MaxPercentInfoIcon, PopoverContent, StyledHeaders, StyledInputLabelSideBySide, StyledTestSetsButtonGroup, TestContainer, TestDataContainer, TestDataParentContainer, TestInnerContainer } from './styles';

import ButtonGroup from '../Buttons/ButtonGroup';
import PageText from '../Text/PageText';
import Spacer from '../Spacer';

import Info from '../../Assets/Icons/Info.png';

import { SecondsToMinutesAndSeconds, SecondsToTime } from '../../Functions';

class ExerciseTypeTwo extends React.Component {
    TimeoutId = null;

    state = {
        BreakMinutes: '',
        BreakSeconds: '',
        MaxMinutes: '',
        MaxSeconds: '',
        Minutes: '',
        OriginalBreak: 0,
        OriginalSeconds: 0,
        PopoverIsOpen: false,
        Seconds: '',
        TotalBreak: '',
        TotalMaxSeconds: '',
        TotalSeconds: ''
    };

    componentDidMount() {
        var { BreakTime, Seconds: TotalSeconds } = this.props;

        if (BreakTime !== '' || TotalSeconds !== '') {
            var { Minutes: BreakMinutes, Seconds: BreakSeconds } = SecondsToMinutesAndSeconds(BreakTime);
            var { Minutes, Seconds } = SecondsToMinutesAndSeconds(TotalSeconds);

            BreakSeconds = +BreakSeconds === 0 ? '00' : +BreakSeconds < 10 ? `0${BreakSeconds}` : BreakSeconds;
            Seconds = +Seconds === 0 ? '00' : +Seconds < 10 ? `0${Seconds}` : Seconds;
            var MaxSeconds = '';

            this.setState({ BreakMinutes, BreakSeconds, MaxSeconds, Minutes, OriginalBreak: BreakTime, OriginalSeconds: +TotalSeconds, Seconds, TotalBreak: BreakTime, TotalSeconds });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.SetType !== 'Test') {
            var { BreakTime: PrevBreakTime, Seconds: PrevSeconds } = prevProps;
            var { BreakTime: CurrBreakTime, Seconds: CurrSeconds } = this.props;

            PrevBreakTime = +PrevBreakTime;
            PrevSeconds = +PrevSeconds;
            CurrBreakTime = +CurrBreakTime;
            CurrSeconds = +CurrSeconds;

            if (PrevSeconds !== CurrSeconds) {
                var { Minutes, Seconds } = SecondsToMinutesAndSeconds(CurrSeconds);

                Seconds = +Seconds === 0 ? '00' : +Seconds < 10 ? `0${Seconds}` : Seconds;
                this.setState({ Minutes, OriginalSeconds: CurrSeconds, Seconds, TotalSeconds: CurrSeconds });
            }

            if (PrevBreakTime !== CurrBreakTime) {
                var { Minutes: BreakMinutes, Seconds: BreakSeconds } = SecondsToMinutesAndSeconds(CurrBreakTime);

                BreakSeconds = +BreakSeconds === 0 ? '00' : +BreakSeconds < 10 ? `0${BreakSeconds}` : BreakSeconds;

                this.setState({ BreakMinutes, BreakSeconds, OriginalBreak: CurrBreakTime, TotalBreak: CurrBreakTime });
            }
        }
    }

    onBlurBreakTime = () => {
        var TotalBreak = 0;
        TotalBreak += (this.state.BreakMinutes * 60) + +this.state.BreakSeconds;

        var { Minutes: BreakMinutes, Seconds: BreakSeconds } = SecondsToMinutesAndSeconds(TotalBreak);

        BreakSeconds = +BreakSeconds === 0 ? '00' : +BreakSeconds < 10 ? `0${BreakSeconds}` : BreakSeconds;
        BreakMinutes = +BreakMinutes === 0 ? 0 : BreakMinutes;

        if (+TotalBreak !== +this.state.OriginalBreak) this.setState({ BreakMinutes, BreakSeconds, TotalBreak }, () => this.onUpdateWorkoutSet());
        else this.setState({ BreakMinutes, BreakSeconds, TotalBreak });
    }

    onBlurMaxSeconds = () => {
        var TotalMaxSeconds = 0;
        TotalMaxSeconds += (this.state.MaxMinutes * 60) + +this.state.MaxSeconds;

        var { Minutes, Seconds } = SecondsToMinutesAndSeconds(TotalMaxSeconds);

        var MaxSeconds = +Seconds === 0 ? '00' : +Seconds < 10 ? `0${Seconds}` : Seconds;
        var MaxMinutes = +Minutes === 0 ? 0 : Minutes;

        this.setState({ MaxMinutes, MaxSeconds, TotalMaxSeconds });
    }

    onBlurSeconds = () => {
        var TotalSeconds = 0;
        TotalSeconds += (this.state.Minutes * 60) + +this.state.Seconds;

        var { Minutes, Seconds } = SecondsToMinutesAndSeconds(TotalSeconds);

        Seconds = +Seconds === 0 ? '00' : +Seconds < 10 ? `0${Seconds}` : Seconds;
        Minutes = +Minutes === 0 ? 0 : Minutes;

        if (+TotalSeconds !== +this.state.OriginalSeconds) this.setState({ Minutes, Seconds, TotalSeconds }, () => this.onUpdateWorkoutSet());
        else this.setState({ Minutes, Seconds, TotalSeconds });
    }

    onBlurTotalSeconds = () => {
        var { TotalSeconds } = this.state;

        var { Minutes, Seconds } = SecondsToMinutesAndSeconds(TotalSeconds);

        Seconds = +Seconds === 0 ? '00' : +Seconds < 10 ? `0${Seconds}` : Seconds;
        Minutes = +Minutes === 0 ? 0 : Minutes;

        if (+TotalSeconds !== +this.state.OriginalSeconds) this.setState({ Minutes, Seconds, TotalSeconds }, () => this.onUpdateWorkoutSet());
        else this.setState({ Minutes, Seconds, TotalSeconds });
    }

    onChangeMaxSeconds = (event, TimeType, OtherTimeTypeValue) => {
        var TotalMaxSeconds = 0;
        if (TimeType === 'Minutes') TotalMaxSeconds += (event.target.value * 60) + OtherTimeTypeValue;
        else if (TimeType === 'Seconds') TotalMaxSeconds += event.target.value + (OtherTimeTypeValue * 60);

        this.setState({ [`Max${TimeType}`]: event.target.value, TotalMaxSeconds });
    }

    onChangeSeconds = (event, TimeType, OtherTimeTypeValue) => {
        var TotalSeconds = 0;
        if (TimeType === 'Minutes') TotalSeconds += (event.target.value * 60) + OtherTimeTypeValue;
        else if (TimeType === 'Seconds') TotalSeconds += event.target.value + (OtherTimeTypeValue * 60);

        this.setState({ [`${TimeType}`]: event.target.value, TotalSeconds });
    }

    onChangeTotalSeconds = event => {
        this.setState({ TotalSeconds: event.target.value });
    }

    onChangeTime = (event, TimeType, OtherTimeTypeValue) => {
        var TotalBreak = 0;
        if (TimeType === 'Minutes') TotalBreak += (event.target.value * 60) + OtherTimeTypeValue;
        else if (TimeType === 'Seconds') TotalBreak += event.target.value + (OtherTimeTypeValue * 60);

        this.setState({ TotalBreak, [`Break${TimeType}`]: event.target.value });
    }

    onRegenerateMax = () => {
        this.props.OnRegenerateMax(this.state.TotalMaxSeconds);
    }

    onTogglePopover = () => {
        this.setState({ PopoverIsOpen: !this.state.PopoverIsOpen }, () => {
            if (!this.state.PopoverIsOpen && !!this.TimeoutId) clearTimeout(this.TimeoutId);
        });
    }

    onUpdateWorkoutSet = () => {
        var { TotalBreak, TotalSeconds } = this.state;

        this.setState({ OriginalBreak: TotalBreak, OriginalSeconds: TotalSeconds }, () => this.props.OnUpdateWorkoutSet({ Seconds: TotalSeconds, TotalBreak }));
    }

    renderAddMax = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { MaxMinutes, MaxSeconds, TotalMaxSeconds } = this.state;

        var Disabled = !+TotalMaxSeconds;

        var Buttons = [
            { BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.props.OnUpdateTestSetScreen('Description'), Title: t('back_with_first_char_uppercase') },
            { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: this.onRegenerateMax, Title: t('regenerate') }
        ]

        return (
            <>
                <TestContainer className="TestContainer">
                    <TestInnerContainer className="TestInnerContainer">
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('Users_max')}:`} TextAlign="left" />

                        <TestDataParentContainer className="TestDataParentContainer">
                            <TestDataContainer className="TestDataContainer">
                                <StyledInputLabelSideBySide
                                    InputFontFamily="semibold"
                                    InputFontSize="medium-2"
                                    LabelFontFamily="medium"
                                    LabelFontSize="medium-1"
                                    Label={`${t('min_str').toLowerCase()} / ${t('Seconds_short').toLowerCase()}`}
                                    OnBlur={this.onBlurMaxSeconds}
                                    OnChange={(event, TimeType, OtherTimeTypeValue) => this.onChangeMaxSeconds(event, TimeType, OtherTimeTypeValue)}
                                    Placeholder="0"
                                    TimeValueMinutes={MaxMinutes}
                                    TimeValueSeconds={MaxSeconds}
                                    Type="time"
                                />
                            </TestDataContainer>
                        </TestDataParentContainer>
                    </TestInnerContainer>

                    {
                        (Device === 'laptop' || Device === 'ipad') &&
                        <StyledTestSetsButtonGroup
                            Buttons={Buttons}
                            ButtonWidth="fit-content"
                            NotTouching
                        />
                    }
                </TestContainer>

                {
                    (Device === 'mobile' || Device === 'mobile_small') &&
                    <>
                        <Spacer Size="extra-small" />

                        <ButtonGroup
                            Buttons={Buttons}
                            ButtonWidth="fit-content"
                            NotTouching
                        />
                    </>
                }
            </>
        );
    }

    renderPopoverContent = () => {
        var { t } = this.props;

        return (
            <PopoverContent className="PopoverContent">
                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('dynamic_sets_estimate_to_be_around_X_percent_popover_noreps', { Percent: `100` })} TextAlign="center" />
            </PopoverContent>
        );
    }

    render() {
        var { t } = this.props;
        var { Device, InnerWidth, Completed, DataType, EditingSet, MaxSet, MaxType, ReadOnly, SetType, Units } = this.props;
        var { BreakMinutes, BreakSeconds, Minutes, Seconds, TotalBreak, TotalSeconds } = this.state;

        if (SetType === 'Test') return this.renderAddMax();

        const popoverProps = {
            isOpen: this.state.PopoverIsOpen,
            preferPlace: 'below',
            onOuterAction: this.onTogglePopover,
            body: this.renderPopoverContent(),
            tipSize: 0.01
        }

        // Add % text if template and dynamic
            var ExerciseTypeColumnText = '';
            if (SetType === 'Template' && (MaxType === 'Custom' || DataType === 'dynamic-seconds')) ExerciseTypeColumnText = t('percent_of_max', { MeasureType: t('Seconds').toLowerCase() });
            else ExerciseTypeColumnText = t('Seconds');

        return (
            <Container className="Container" Completed={Completed} MaxSet={MaxSet}>
                <DataParentContainer className="DataParentContainer" BorderColor={MaxSet && !Completed && 'var(--orange)'}>
                    {
                        InnerWidth > 900 && SetType === 'Template' && DataType === 'dynamic-seconds' && (MaxType === 'Custom' || !MaxSet) &&
                        <Popover {...popoverProps}>
                            <MaxPercentInfoIcon ImageSrc={Info} OnMouseEnter={this.onTogglePopover} OnMouseLeave={this.onTogglePopover} />
                        </Popover>
                    }

                    <DataContainer className="DataContainer" Completed={Completed} MaxSet={MaxSet}>
                        <StyledHeaders FontFamily="bold" FontSize="medium-1" NoMargin Text={ExerciseTypeColumnText} />
                        
                        {
                        //     (ReadOnly && DataType === 'dynamic-seconds') ?
                        //     <PageText FontFamily="semibold" FontSize="medium-3" NoMargin Text={`${TotalSeconds}%`} />
                        // :
                            SetType === 'Template' && MaxType !== 'Custom' && DataType === 'dynamic-seconds' && !!MaxSet ?
                            <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent={(Device === 'mobile' || Device === 'mobile_small') ? 'flex-end' : 'center'} NoMargin Text={t('dynamic_sets_estimate_to_be_around_X_percent', { Percent: '101'})} TextAlign={(Device === 'mobile' || Device === 'mobile_small') ? 'right' : 'center'} />
                        :
                        // (ReadOnly || (Completed && ((!EditingSet && MaxSet) || !MaxSet))) ?
                            (ReadOnly || (Completed && !EditingSet)) ?
                            <PageText FontFamily="semibold" FontSize="medium-3" NoMargin Text={MaxType === 'Custom' ? `${TotalSeconds}` : SecondsToTime(TotalSeconds)} />
                        :
                            (SetType === 'Template' && DataType === 'dynamic-seconds') ?
                            <ExtraContainer className="ExtraContainer">
                                {
                                    InnerWidth <= 900 && SetType === 'Template' && DataType === 'dynamic-seconds' && (MaxType === 'Custom' || !MaxSet) &&
                                    <Popover {...popoverProps}>
                                        <MaxPercentInfoIcon ImageSrc={Info} OnMouseEnter={this.onTogglePopover} OnMouseLeave={this.onTogglePopover} />
                                    </Popover>
                                }

                                <StyledInputLabelSideBySide
                                    InputFontFamily="semibold"
                                    InputFontSize="medium-2"
                                    LabelFontFamily="medium"
                                    LabelFontSize="medium-1"
                                    Label={Units}
                                    OnBlur={this.onBlurTotalSeconds}
                                    OnChange={this.onChangeTotalSeconds}
                                    Placeholder="0"
                                    Type="number"
                                    Value={TotalSeconds}
                                />
                            </ExtraContainer>
                        :
                            <StyledInputLabelSideBySide
                                InputFontFamily="semibold"
                                InputFontSize="medium-2"
                                LabelFontFamily="medium"
                                LabelFontSize="medium-1"
                                Label={`${t('min_str').toLowerCase()} / ${t('Seconds_short').toLowerCase()}`}
                                OnBlur={this.onBlurSeconds}
                                OnChange={(event, TimeType, OtherTimeTypeValue) => this.onChangeSeconds(event, TimeType, OtherTimeTypeValue)}
                                Placeholder="0"
                                TimeValueMinutes={Minutes}
                                TimeValueSeconds={Seconds}
                                Type="time"
                            />
                        }
                    </DataContainer>
                </DataParentContainer>

                <DataParentContainer className="DataParentContainer">
                    <DataContainer className="DataContainer" Completed={Completed} MaxSet={MaxSet}>
                        <StyledHeaders FontFamily="bold" FontSize="medium-1" NoMargin Text={t('break')} />
                        
                        {
                            (ReadOnly || Completed) ?
                            <PageText FontFamily="semibold" FontSize="medium-3" NoMargin Text={SecondsToTime(TotalBreak)} />
                        :
                            <StyledInputLabelSideBySide
                                InputFontFamily="semibold"
                                InputFontSize="medium-2"
                                LabelFontFamily="medium"
                                LabelFontSize="medium-1"
                                Label={`${t('min_str').toLowerCase()} / ${t('Seconds_short').toLowerCase()}`}
                                OnBlur={this.onBlurBreakTime}
                                OnChange={(event, TimeType, OtherTimeTypeValue) => this.onChangeTime(event, TimeType, OtherTimeTypeValue)}
                                Placeholder="0"
                                TimeValueMinutes={BreakMinutes}
                                TimeValueSeconds={BreakSeconds}
                                Type="time"
                            />
                        }
                    </DataContainer>
                </DataParentContainer>
            </Container>
        )
    }
}

ExerciseTypeTwo.propTypes = {
    BreakTime: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    Completed: PropTypes.bool,
    DataType: PropTypes.string,
    EditingSet: PropTypes.bool,
    MaxSet: PropTypes.bool,
    MaxType: PropTypes.string,
    OnRegenerateMax: PropTypes.func,
    OnUpdateTestSetScreen: PropTypes.func,
    OnUpdateWorkoutSet: PropTypes.func,
    ReadOnly: PropTypes.bool,
    Seconds: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    SetType: PropTypes.string.isRequired,
    Units: PropTypes.string
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        InnerWidth: state.Window.InnerWidth
    };
};

export default withTranslation()(connect(mapStateToProps, { } )(ExerciseTypeTwo));