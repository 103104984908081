import {
    TRYING_CREATE_DISH_CATEGORY_SUCCESS,

    CLEAR_PRIVATE_DISH_SETTINGS,
    TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS,
    TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS_SUCCESS,
    TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS_ERROR,

    TRYING_INSERTEDIT_PRIVATE_DISH,
    TRYING_INSERTEDIT_PRIVATE_DISH_SUCCESS,
    TRYING_INSERTEDIT_PRIVATE_DISH_ERROR
} from '../Types';

const INITIAL_STATE = {
    DishCategories: [],
    MealTypes: [],

    TryingCreatePrivateDish: false,
    TryingCreatePrivateDishError: null,
    TryingGetCreatePrivateDishSettings: false,
    TryingGetCreatePrivateDishSettingsError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_CREATE_DISH_CATEGORY_SUCCESS:
            return { ...state, DishCategories: [ ...state.DishCategories, { ...action.payload } ] };

        case CLEAR_PRIVATE_DISH_SETTINGS:
            return { ...state, DishCategories: [], MealTypes: [] };
        case TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS:
            return { ...state, TryingGetCreatePrivateDishSettings: true, TryingGetCreatePrivateDishSettingsError: null };
        case TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS_ERROR:
            return { ...state, TryingGetCreatePrivateDishSettings: false, TryingGetCreatePrivateDishSettingsError: action.payload };
        case TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS_SUCCESS:
            var { DishCategories, MealTypes } = action.payload;

            return { ...state, DishCategories, MealTypes, TryingGetCreatePrivateDishSettings: false, TryingGetCreatePrivateDishSettingsError: null };

        case TRYING_INSERTEDIT_PRIVATE_DISH:
            return { ...state, TryingCreatePrivateDish: true, TryingCreatePrivateDishError: null };
        case TRYING_INSERTEDIT_PRIVATE_DISH_ERROR:
            return { ...state, TryingCreatePrivateDish: false, TryingCreatePrivateDishError: action.payload };
        case TRYING_INSERTEDIT_PRIVATE_DISH_SUCCESS:
            return { ...state, TryingCreatePrivateDish: false, TryingCreatePrivateDishError: null }; 
        default:
            return state;
    }
};
