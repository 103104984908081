import React from 'react';
import styled from 'styled-components';

import Popover from 'react-popover';

import { ReactComponent as Emoji } from '../../Assets/SVG/Emoji.svg';

import { ConvertEmojiToUnicode, ConvertUnicodeToEmoji } from '../../Functions';

const ComponentContainer = styled.div`
  display: flex;
  position: relative;
`;

const ReactionPickerContainer = styled.div`
  position: absolute;
  display: flex;
  width: max-content;
  bottom: 35px;
  right: 10px;
  background-color: var(--white-concrete-hover);
  border-radius: 10px;
  z-index: 1;

  @media screen and (max-width: 530px) {
    left: -5px;
  }
`;

const StyledEmoji = styled(Emoji)`
  cursor: pointer;
  opacity: 0.5;

  :hover {
    opacity: 1;
  }
`;

const SelectedStyle = `
  border: 3px solid #1998FC;
`;

const EmojiText = styled.div`
  padding: 5px;
  font-size: 22px;
  cursor: pointer;
  border: 3px solid var(--white-concrete-hover);
  border-radius: 10px;

  ${props => props.selected && SelectedStyle}
`;

const EmojiContainer = styled.div`
  user-select: none;
  cursor: pointer;
  font-size: 22px;

  :hover {
    opacity: 0.8;
  }
`;

const EMOJIS = ["👏", "🏆", "🥳", "❤️", "🥵", "🥹", "😍"];

class NotificationReactionPicker extends React.Component {
    ref = React.createRef();

    state = {
        PopoverIsOpen: false
    };

    onReactToNotification = ({ NewEmoji, OldEmoji }) => {
        this.onTogglePopover(false);

        if (NewEmoji !== OldEmoji) this.props.OnChangeReaction({ NewEmoji, NewEmojiUnicode: ConvertEmojiToUnicode(NewEmoji) });
    }

    onTogglePopover = PopoverIsOpen => {
        this.setState({ PopoverIsOpen });
    }

    renderPopoverContent = ({ selectedEmoji }) => {
        return (
            <ReactionPickerContainer ref={this.ref}>
                {
                    EMOJIS.map(emoji => (
                        <EmojiText
                            key={emoji}
                            selected={emoji === selectedEmoji}
                            onClick={() => this.onReactToNotification({ NewEmoji: emoji, OldEmoji: selectedEmoji })}
                        >
                            {emoji}
                        </EmojiText>
                    ))
                }
                </ReactionPickerContainer>
        );
    }

    render() {
        var selectedEmoji = this.props.Reaction ? ConvertUnicodeToEmoji(this.props.Reaction) : undefined;

        const popoverProps = {
            isOpen: this.state.PopoverIsOpen,
            preferPlace: 'above',
            onOuterAction: () => this.onTogglePopover(false),
            body: this.renderPopoverContent({ selectedEmoji }),
            tipSize: 0.01
        }

        return (
            <ComponentContainer>
                <Popover {...popoverProps}>
                    {
                        selectedEmoji ?
                        (
                            <EmojiContainer onClick={() => this.onTogglePopover(true)}>
                                {selectedEmoji}
                            </EmojiContainer>
                        )
                    :
                        (
                            <StyledEmoji
                                color="#000000"
                                onClick={() => this.onTogglePopover(true)}
                            />
                        )
                    }
                </Popover>
            </ComponentContainer>
        );
    }
}

export default NotificationReactionPicker;