import React from 'react';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';

import * as Styles from './styles';

import Exercises from './Exercises';
import MultiText from '../../../Components/Text/MultiText';
import Page from '../../../Components/Page';
import PageText from '../../../Components/Text/PageText';
import Spacer from '../../../Components/Spacer';
import Statuses from './Statuses';
import Tasks from './Tasks';
import TrainingPrograms from './TrainingPrograms';
import Trash from './Trash';
import WorkoutTemplates from './WorkoutTemplates';

import Info from '../../../Modals/Info';

import HowToTagPictureOne from '../../../Assets/Illustrations/Usermanual_tagging.png';
import HowToTagPictureTwo from '../../../Assets/Illustrations/Usermanual_tagging2.png';

class WorkoutLibrary extends React.Component {
    _isMounted = false;

    state = {
        LibraryType: null,
        ShowCustomizeFilters: false,
        ShowHowToTagModal: false,
        ShowTrash: false
    }

    componentDidMount() {
        this._isMounted = true;

        const QueryValues = queryString.parse(this.props.location.search);

        var LibraryType = 'WorkoutTemplates';
        var ShowCustomizeFilters = false;
        var ShowTrash = false;

        if (Object.entries(QueryValues).length > 0 && QueryValues.tab) {
            if (QueryValues.tab.toLowerCase() === 'workout-templates') LibraryType = 'WorkoutTemplates';
            else if (QueryValues.tab.toLowerCase() === 'exercises') LibraryType = 'Exercises';
            else if (QueryValues.tab.toLowerCase() === 'training-programs') LibraryType = 'TrainingPrograms';
            else if (QueryValues.tab.toLowerCase() === 'tasks') LibraryType = 'Tasks';
            else if (QueryValues.tab.toLowerCase() === 'statuses') LibraryType = 'Statuses';
        }

        if (Object.entries(QueryValues).length > 0 && !!+QueryValues.ShowTrash) ShowTrash = true;
        if (Object.entries(QueryValues).length > 0 && !!+QueryValues.ShowCustomizeFilters) ShowCustomizeFilters = true;

        this.setState({ LibraryType, ShowCustomizeFilters, ShowTrash });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            const QueryValues = queryString.parse(this.props.location.search);

            var LibraryType = 'WorkoutTemplates';
            var ShowCustomizeFilters = false;
            var ShowTrash = false;

            if (Object.entries(QueryValues).length > 0 && QueryValues.tab) {
                if (QueryValues.tab.toLowerCase() === 'workout-templates') LibraryType = 'WorkoutTemplates';
                else if (QueryValues.tab.toLowerCase() === 'exercises') LibraryType = 'Exercises';
                else if (QueryValues.tab.toLowerCase() === 'training-programs') LibraryType = 'TrainingPrograms';
                else if (QueryValues.tab.toLowerCase() === 'tasks') LibraryType = 'Tasks';
                else if (QueryValues.tab.toLowerCase() === 'statuses') LibraryType = 'Statuses';
            }

            if (Object.entries(QueryValues).length > 0 && !!+QueryValues.ShowTrash) ShowTrash = true;
            if (Object.entries(QueryValues).length > 0 && !!+QueryValues.ShowCustomizeFilters) ShowCustomizeFilters = true;

            this.setState({ LibraryType, ShowCustomizeFilters, ShowTrash });
        }
    }

    onToggleShowHowToTagModal = ShowHowToTagModal => {
        this.setState({ ShowHowToTagModal });
    }

    renderBody = () => {
        var { LibraryType, ShowTrash } = this.state;

        if (ShowTrash) return <Trash LibraryType={LibraryType} ShowTrash />

        if (LibraryType === 'Exercises') return <Exercises OnToggleShowHowToTagModal={this.onToggleShowHowToTagModal} ShowCustomizeFilters={this.state.ShowCustomizeFilters} ShowTrash={ShowTrash} />

        if (LibraryType === 'Statuses') return <Statuses OnToggleShowHowToTagModal={this.onToggleShowHowToTagModal} ShowCustomizeFilters={this.state.ShowCustomizeFilters} ShowTrash={ShowTrash} />

        if (LibraryType === 'Tasks') return <Tasks OnToggleShowHowToTagModal={this.onToggleShowHowToTagModal} ShowCustomizeFilters={this.state.ShowCustomizeFilters} ShowTrash={ShowTrash} />

        if (LibraryType === 'TrainingPrograms') return <TrainingPrograms OnToggleShowHowToTagModal={this.onToggleShowHowToTagModal} ShowCustomizeFilters={this.state.ShowCustomizeFilters} ShowTrash={ShowTrash} />

        if (LibraryType === 'WorkoutTemplates') return <WorkoutTemplates OnToggleShowHowToTagModal={this.onToggleShowHowToTagModal} ShowCustomizeFilters={this.state.ShowCustomizeFilters} ShowTrash={ShowTrash} />

        return null;
    }

    renderHowToTagModal = () => {
        var { ShowHowToTagModal } = this.state;

        if (ShowHowToTagModal) {
            return (
                <Info
                    InformationRenderer={this.renderHowToTagModal_Info}
                    OnHide={() => this.onToggleShowHowToTagModal(false)}
                    Show={ShowHowToTagModal}
                />
            );
        }
    }

    renderHowToTagModal_Info = () => {
        var { t } = this.props;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('_how_to_tag_in_library')} TextAlign="left" />

                <Spacer Size="small" />

                <MultiText
                    Texts={[
                        { FontColor: 'blue-abel', FontFamily: 'bold', FontSize: 'medium-1', Text: '1.' },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('_how_to_tag_in_library_step1')}
                    ]}
                />

                <Spacer Size="extra-small" />

                <MultiText
                    Texts={[
                        { FontColor: 'blue-abel', FontFamily: 'bold', FontSize: 'medium-1', Text: '2.' },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('_how_to_tag_in_library_step2')}
                    ]}
                />

                <Spacer Size="extra-small" />

                <MultiText
                    Texts={[
                        { FontColor: 'blue-abel', FontFamily: 'bold', FontSize: 'medium-1', Text: '3.' },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('_how_to_tag_in_library_step3')}
                    ]}
                />

                <Spacer Size="extra-extra-small" />

                <Styles.HowToTagModalImage ImageSrc={HowToTagPictureOne} SelfLoading />

                <Spacer Size="extra-small" />

                <MultiText
                    Texts={[
                        { FontColor: 'blue-abel', FontFamily: 'bold', FontSize: 'medium-1', Text: '4.' },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('_how_to_tag_in_library_step4')}
                    ]}
                />

                <Spacer Size="extra-small" />

                <MultiText
                    Texts={[
                        { FontColor: 'blue-abel', FontFamily: 'bold', FontSize: 'medium-1', Text: '5.' },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('_how_to_tag_in_library_step5')}
                    ]}
                />

                <Spacer Size="extra-extra-small" />

                <Styles.HowToTagModalImage ImageSrc={HowToTagPictureTwo} SelfLoading />

                <Spacer Size="medium" />

                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('_how_to_tag_in_library_step6')} TextAlign="left" />
                
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('_how_to_tag_in_library_step7')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('_how_to_tag_in_library_step8')} TextAlign="left" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('_how_to_tag_in_library_step9')} TextAlign="left" />
            </>
        );
    }

    render() {
        return (
            <>
                <Page NoMargin NoPadding>
                    <Styles.LibraryContainer className="LibraryContainer" ShowCustomizeFilters={this.state.ShowCustomizeFilters}>
                        {this.renderBody()}
                    </Styles.LibraryContainer>
                </Page>

                {this.renderHowToTagModal()}
            </>
        );
    }
}

export default withTranslation()(WorkoutLibrary);