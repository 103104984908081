import moment from "moment";
import TimePicker from "rc-time-picker";
import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import MiniCalendar from "../../Calendar/MiniCalendar";
import Input from "../../Form/Input";
import Spacer from "../../Spacer";
import PageText from "../../Text/PageText";
import {
  AlignItemsColumnWise,
  BottomButtons,
  DateTimeUseAllDaysContainer,
  DateTimeUseAllDays,
  InputContainer,
  NotesContainer,
  NotificationReminderContainer,
  ScheduleSession,
  SessionItemsContainer,
  StyledCalendar,
  StyledInput,
  StyledScheduleSessionContainer,
  StyledSessionNotesTextarea,
  TimePickerContainer,
} from "./styles";
import AssignClients from "../../AssignClients";
import {
  CreateVideoSession,
  EditVideoSession,
  GetAllVideoSessions,
  GetSingleVideoSessionDetails,
} from "../../../Services/Actions";
import { getDateDifference } from "../../../Functions";
import Loading from "../../Loading";
import { workout } from "../../../Utils/constants";

class ScheduleVideoSession extends React.Component {
  state = {
    AddNotificationReminder: false,
    Description: "",
    NotifyBeforeTime: 0,
    HourType: 24,
    IsTimeChanged: false,
    SelectedDates: [],
    SelectedTime: moment().set({ minutes: 0, hours: 0 }),
    SessionType: workout,
    AssigningDetails: [],
  };

  componentDidMount() {
    const { SelectedSession, WorkoutDetails, WorkoutId } = this.props;
    const currentDate = moment().utc().format();
    if (!_.isEmpty(SelectedSession)) {
      this.setState({
        AddNotificationReminder: !!SelectedSession.NotifyBefore,
        Description: SelectedSession.Description,
        HourType: SelectedSession.Is24HourFormat ? 24 : 12,
        NotifyBeforeTime: SelectedSession.NotifyBefore,
        SelectedDates:
          getDateDifference(SelectedSession.Date, currentDate, "days") <= 0
            ? [moment(SelectedSession.Date).format("YYYY-MM-DD")]
            : [],
        SelectedTime: moment(SelectedSession.Date),
        IsTimeChanged: true,
      });
      this.onSelectClients({ SelectedClients: SelectedSession.Users });
    }
    if (WorkoutId && !SelectedSession) {
      this.setState({
        SelectedDates: [
          moment(WorkoutDetails.AssignedDate).format("YYYY-MM-DD"),
        ],
      });
    }
  }

  getPastDisabledHours = () => {
    const { SelectedDates } = this.state;
    const hours = [];
    const currentDate = moment().subtract(1, "days").format();
    if (
      SelectedDates &&
      getDateDifference(
        SelectedDates && SelectedDates[0],
        currentDate,
        "days"
      ) === 0
    ) {
      for (let i = 0; i < moment().hour(); i++) {
        hours.push(i);
      }
    }
    return hours;
  };

  getPastDisabledMinutes = () => {
    const { SelectedTime } = this.state;
    var minutes = [];

    if (SelectedTime.hours() === moment().hour()) {
      for (var i = 0; i < moment().minute(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  onChangeTime = (ScheduleTime) => {
    this.setState({ SelectedTime: ScheduleTime, IsTimeChanged: true });
  };

  onChangeScheduleEventType = (EventValue, EventType) => {
    this.setState({ [EventType]: EventValue });
  };

  onChangeHourType = (HourType) => {
    this.setState({ HourType });
  };

  onChangeVideoSessionText = ({ event, TextName }) => {
    const text = event.target.value;
    if (TextName === "NotifyBeforeTime") {
      if (Number(text) >= 0 && Number(text) <= 120) {
        this.setState({ [TextName]: text });
      } else {
        this.setState({ [TextName]: 0 });
      }
    } else this.setState({ [TextName]: text });
  };

  onChangeSessionType = (SessionType) => {
    this.setState({ SessionType });
  };

  onClearState() {
    this.props.hideCreateSession();
    this.setState({
      AddNotificationReminder: false,
      Description: "",
      NotifyBeforeTime: 0,
      HourType: 24,
      SelectedDates: [],
      SelectedTime: moment().set({ minutes: 0, hours: 0 }),
      SessionType: workout,
      AssigningDetails: [],
      SubmitButtonLabel: "Create",
    });
  }

  onSelectClients = ({ SelectedClients: Users }) => {
    var UserIds = Users.map((User) => User.UserId);
    var AssigningDetails = { ...this.state.AssigningDetails, UserIds, Users };
    this.setState({ AssigningDetails });
  };

  onSelectDate = (DayDate) => {
    this.setState({ SelectedDates: [DayDate] });
  };

  onSubmit = () => {
    const {
      SelectedDates,
      SelectedTime,
      HourType,
      Description,
      SessionType,
      AssigningDetails: { UserIds },
      NotifyBeforeTime,
      AddNotificationReminder,
    } = this.state;

    let startDate = moment(SelectedDates[0]);

    startDate = startDate
      .set({
        minutes: SelectedTime.minute(),
        hours: SelectedTime.hour(),
      })
      .toISOString();

    const SessionData = {
      startDate,
      description: Description,
      sessionType: SessionType,
      isGroupSession: UserIds && UserIds.length > 1,
      notifyBefore: AddNotificationReminder
        ? parseInt(NotifyBeforeTime) || 0
        : 0,
      notificationPeriodId: 1,
      is24HourFormat: HourType === 24,
      userIds: UserIds,
    };

    const {
      SelectedSession,
      onCancelSession,
      hideCreateSession,
      WorkoutId,
      CurrentPageStatus,
    } = this.props;

    if (!_.isEmpty(this.props.SelectedSession)) {
      this.props
        .EditVideoSession({
          ...SessionData,
          videoSessionId: SelectedSession.VideoSessionId,
        })
        .then((result) => {
          onCancelSession();
          if (WorkoutId) {
            this.props.GetSingleVideoSessionDetails({
              videoSessionId: result.videoSessionId,
            });
          } else {   
            this.props.GetAllVideoSessions(CurrentPageStatus);
          }
          hideCreateSession(false);
        });
    } else {
      const { WorkoutId } = this.props;
      if (WorkoutId) {
        SessionData.workoutId = WorkoutId;
      }
      this.props.CreateVideoSession(SessionData).then((result) => {
        this.onClearState();
        if (WorkoutId) {
          this.props.GetSingleVideoSessionDetails({
            videoSessionId: result.sessionId,
          });
          hideCreateSession(false);
        } else {
          hideCreateSession();
          this.props.GetAllVideoSessions(CurrentPageStatus);
        }
      });
    }
  };

  renderNotes() {
    const { t } = this.props;
    const { Description } = this.state;
    return (
      <NotesContainer className="NotesContainer">
        <div>
          <NotificationReminderContainer className="NotificationReminderContainer">
            <PageText
              FontFamily="medium"
              FontSize="medium-2"
              JustifyContent="flex-start"
              Text={t("video_session_note_title")}
              TextAlign="left"
            />
            <PageText
              FontFamily="bold"
              FontSize="medium-1"
              JustifyContent="flex-start"
              Text={t("optional")}
              TextAlign="left"
            />
          </NotificationReminderContainer>
          <StyledSessionNotesTextarea
            ControlledComponent
            className="StyledSessionNotesTextarea"
            OnChange={(event) =>
              this.onChangeVideoSessionText({ event, TextName: "Description" })
            }
            FontFamily="medium"
            FontSize="medium-1"
            NumRows={8}
            MaxRows={10}
            Placeholder={t("video_session_note_placeholder")}
            Value={Description}
          />
        </div>
      </NotesContainer>
    );
  }

  renderNotificationReminder() {
    const { t } = this.props;
    const { AddNotificationReminder } = this.state;
    return (
      <SessionItemsContainer className="SessionItemsContainer">
        <PageText
          FontFamily="medium"
          FontSize="medium-2"
          JustifyContent="flex-start"
          Text={t("video_session_notification_reminder")}
          TextAlign="left"
        />

        <NotificationReminderContainer className="NotificationReminderContainer">
          <AlignItemsColumnWise className="AlignItemsColumnWise">
            <DateTimeUseAllDaysContainer className="DateTimeUseAllDaysContainer">
              <DateTimeUseAllDays
                Selected={AddNotificationReminder}
                onClick={() =>
                  this.onChangeScheduleEventType(
                    true,
                    "AddNotificationReminder"
                  )
                }
              />
              <PageText
                FontFamily="medium"
                FontSize="medium-1"
                NoMargin
                Text="Yes"
              />
            </DateTimeUseAllDaysContainer>

            <DateTimeUseAllDaysContainer className="DateTimeUseAllDaysContainer">
              <DateTimeUseAllDays
                Selected={!AddNotificationReminder}
                onClick={() =>
                  this.onChangeScheduleEventType(
                    false,
                    "AddNotificationReminder"
                  )
                }
              />
              <PageText
                FontFamily="medium"
                FontSize="medium-1"
                NoMargin
                Text="No"
              />
            </DateTimeUseAllDaysContainer>
          </AlignItemsColumnWise>

          <InputContainer className="InputContainer">
            <StyledInput className="StyledInput">
              <Input
                FontSize="medium-2"
                NoLabel
                Disabled={!AddNotificationReminder}
                OnChange={(event) =>
                  this.onChangeVideoSessionText({
                    event,
                    TextName: "NotifyBeforeTime",
                  })
                }
                Placeholder="30"
                Size="medium"
                Type="number"
                Value={this.state.NotifyBeforeTime}
              />
            </StyledInput>
            <PageText
              FontFamily="medium"
              FontSize="medium-1"
              NoMargin
              Text={`${t("min_str")} ${t("before_str")}`}
            />
          </InputContainer>
        </NotificationReminderContainer>
      </SessionItemsContainer>
    );
  }

  renderSessionType() {
    const { t } = this.props;
    const { SessionType } = this.state;
    return (
      <SessionItemsContainer className="SessionItemsContainer">
        <PageText
          FontFamily="medium"
          FontSize="medium-2"
          JustifyContent="flex-start"
          Text={t("video_session_which_calendar_should_this_go_to")}
          TextAlign="left"
        />

        <DateTimeUseAllDaysContainer className="DateTimeUseAllDaysContainer">
          <DateTimeUseAllDays
            Selected={SessionType === workout}
            onClick={() => this.onChangeSessionType(workout)}
          />
          <PageText
            FontFamily="medium"
            FontSize="medium-1"
            NoMargin
            Text={t("Workout")}
          />
        </DateTimeUseAllDaysContainer>

        <DateTimeUseAllDaysContainer className="DateTimeUseAllDaysContainer">
          <DateTimeUseAllDays
            Selected={SessionType === "meal"}
            onClick={() => this.onChangeSessionType("meal")}
          />
          <PageText
            FontFamily="medium"
            FontSize="medium-1"
            NoMargin
            Text={t("Meal")}
          />
        </DateTimeUseAllDaysContainer>
      </SessionItemsContainer>
    );
  }

  renderScheduledDates() {
    const { t } = this.props;
    const { SelectedDates } = this.state;

    return (
      <SessionItemsContainer className="SessionItemsContainer ">
        <PageText
          FontFamily="medium"
          FontSize="medium-2"
          JustifyContent="flex-start"
          Text={t("choose_dates")}
          TextAlign="left"
        />
        <StyledCalendar className="StyledCalendar">
          <MiniCalendar
            OnSelectDate={(DayDate) => this.onSelectDate(DayDate)}
            DisabledMinDate={moment().subtract(1, "days").toISOString()}
            SelectedDates={SelectedDates}
          />
        </StyledCalendar>
      </SessionItemsContainer>
    );
  }

  renderScheduledTime() {
    var { t } = this.props;
    const { SelectedTime, HourType } = this.state;
    return (
      <>
        <SessionItemsContainer className="SessionItemsContainer">
          <PageText
            FontFamily="medium"
            FontSize="medium-2"
            JustifyContent="flex-start"
            Text={t("start_time")}
            TextAlign="left"
          />

          <DateTimeUseAllDaysContainer className="DateTimeUseAllDaysContainer">
            <DateTimeUseAllDays
              Selected={HourType === 12}
              onClick={() => this.onChangeHourType(12)}
            />
            <PageText
              FontFamily="medium"
              FontSize="medium-1"
              NoMargin
              Text={t("hour_type", { HourType: "12" })}
            />
          </DateTimeUseAllDaysContainer>

          <DateTimeUseAllDaysContainer className="DateTimeUseAllDaysContainer">
            <DateTimeUseAllDays
              Selected={HourType === 24}
              onClick={() => this.onChangeHourType(24)}
            />
            <PageText
              FontFamily="medium"
              FontSize="medium-1"
              NoMargin
              Text={t("hour_type", { HourType: "24" })}
            />
          </DateTimeUseAllDaysContainer>

          <Spacer Size="extra-small" />

          <TimePickerContainer className="TimePickerContainer">
            <TimePicker
              // disabled={true}
              disabledHours={this.getPastDisabledHours}
              disabledMinutes={this.getPastDisabledMinutes}
              defaultValue={moment(SelectedTime)}
              minuteStep={5}
              onChange={(ScheduleTime) => this.onChangeTime(ScheduleTime)}
              showSecond={false}
              use12Hours={HourType === 12}
              value={moment(SelectedTime)}
            />
          </TimePickerContainer>
        </SessionItemsContainer>
      </>
    );
  }

  renderSessionClients() {
    const { t } = this.props;
    const {
      AssigningDetails: { UserIds, Users },
    } = this.state;
    return (
      <SessionItemsContainer className="SessionItemsContainer">
        <PageText
          FontFamily="medium"
          FontSize="medium-2"
          JustifyContent="flex-start"
          Text={t("assign_to_clients")}
          TextAlign="left"
        />

        <AssignClients
          EligibleTypes="Private"
          GetStartedEligible="Workouts"
          HideTitle
          HideSelectAll
          HorizontalScroll={false}
          LeftAlign={true}
          OnSelectClients={(Users) => this.onSelectClients(Users)}
          SelectedUserIds={UserIds}
          SelectedUsers={Users}
          WhiteBackground
        />
      </SessionItemsContainer>
    );
  }

  render() {
    const {
      IsTimeChanged,
      SelectedDates,
      SelectedTime,
      SessionType,
      AssigningDetails: { UserIds },
    } = this.state;

    const { t, TryingCreateVideoSession, TryingEditVideoSession } = this.props;

    const Disabled = !Boolean(
      IsTimeChanged &&
        SelectedDates.length &&
        SelectedTime &&
        SessionType &&
        UserIds &&
        UserIds.length
    );

    if (TryingCreateVideoSession || TryingEditVideoSession) return <Loading />;

    return (
      <StyledScheduleSessionContainer className="StyledScheduleSessionContainer">
        <ScheduleSession className="ScheduleSession container-fluid">
          {this.renderSessionClients()}

          {!this.props.WorkoutId && this.renderScheduledDates()}

          {this.renderScheduledTime()}

          {!this.props.WorkoutId && this.renderSessionType()}

          {/* {this.renderNotificationReminder()} */}
        </ScheduleSession>

        {this.renderNotes()}

        <BottomButtons
          Buttons={[
            {
              BackgroundColor: "warning",
              BackgroundColorHover: "warning-hover",
              Border: "none",
              Color: "white",
              ColorHover: "white",
              FontFamily: "semibold",
              OnClick: () => {
                this.props.SelectedSession
                  ? this.props.onCancelSession()
                  : this.props.WorkoutId
                  ? this.props.hideCreateSession(false)
                  : this.onClearState();
              },
              Title: t("Cancel_with_first_char_uppercase"),
            },
            {
              BackgroundColor: "abel-blue",
              BackgroundColorHover: "abel-blue-hover",
              Border: "none",
              Color: "white",
              ColorHover: "white",
              Disabled: Disabled,
              FontFamily: "semibold",
              OnClick: () => this.onSubmit(),
              Title: this.props.SelectedSession
                ? t("update_with_first_char_uppercase")
                : t("create"),
            },
          ]}
        />
        {!this.validateSessionDetails}
      </StyledScheduleSessionContainer>
    );
  }
}

ScheduleVideoSession.propTypes = {
  CurrentPageStatus: PropTypes.shape({
    Page: PropTypes.number,
    When: PropTypes.string,
  }),
  SelectedSession: PropTypes.shape({}),
  WorkoutId: PropTypes.number,
  onCancelSession: PropTypes.func,
  hideCreateSession: PropTypes.func,
};

ScheduleVideoSession.defaultProps = {
  CurrentPageStatus: null,
  SelectedSession: null,
  WorkoutId: null,
  onCancelSession: () => {},
  hideCreateSession: () => {},
};

const mapStateToProps = (state) => {
  return {
    Device: state.Window.Device,
    WorkoutDetails: state.Workout.WorkoutDetails,

    TryingCreateVideoSession: state.VideoSession.TryingCreateVideoSession,
    TryingCreateVideoSessionError:
      state.VideoSession.TryingCreateVideoSessionError,
    TryingEditVideoSession: state.VideoSession.TryingEditVideoSession,
    TryingEditVideoSessionError: state.VideoSession.TryingEditVideoSessionError,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    CreateVideoSession,
    EditVideoSession,
    GetAllVideoSessions,
    GetSingleVideoSessionDetails,
  })(ScheduleVideoSession)
);
