import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Tasks_GetUserTasksAndStatuses } from '../../Services/Actions';

import moment from 'moment';

import { MultiOptionContainer, MultiOptionsContainer }  from './styles';

import AddTasksStatuses from '../../Components/AddTasksStatuses';
import CalendarHeader from '../../Components/Calendar/CalendarHeader';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import Toggle from '../../Components/Form/Toggle';
import WeeklyView from '../../Components/Calendar/WeeklyView';

class ClientTasksStatuses extends React.Component {
    _isMounted = false;

    state = {
        DayDate: null,
        EndDate: null,
        Loading: true,
        MonthlyView: true,
        StartDate: null,
        UserTasksAndStatuses: []
    };

    componentDidMount() {
        this._isMounted = true;

        var { CalendarDifference, ClientId, StartingDate } = this.props;

        var MonthlyView = true;
        var CustomSettings_LS = localStorage.getItem('CustomSettings');
        if (CustomSettings_LS) {
            CustomSettings_LS = JSON.parse(CustomSettings_LS);
            MonthlyView = CustomSettings_LS.Tasks_MonthlyView;
        } else {
            CustomSettings_LS = {
                Meals_MonthlyView: false,
                Tasks_MonthlyView: true,
                Workouts_MonthlyView: false,
            };
            
            localStorage.setItem('CustomSettings', JSON.stringify(CustomSettings_LS));
        }

        var StartDate;
        var EndDate;

        if (MonthlyView) {
            StartDate = moment().add(CalendarDifference, 'months').startOf('month').startOf('isoWeek').format('YYYY-MM-DD');
            EndDate = moment().add(CalendarDifference, 'months').endOf('month').endOf('isoWeek').format('YYYY-MM-DD');

            // Calculate WeeklyView dates from StartingDate
                if (StartingDate) CalendarDifference = moment(StartingDate).diff(moment().startOf('month').format('YYYY-MM-DD'), 'months');
        }
        else {
            StartDate = moment().add(CalendarDifference, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
            EndDate = moment().add(CalendarDifference, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');

            // Calculate WeeklyView dates from StartingDate
                if (StartingDate) CalendarDifference = moment(StartingDate).isoWeekday(moment().isoWeekday()).diff(moment().format('YYYY-MM-DD'), 'weeks');
        }

        this.setState({ EndDate, MonthlyView, StartDate }, () => {
            this.props.Tasks_GetUserTasksAndStatuses({ CalendarDifference, ClientId, EndDate, StartDate }).then(({ UserTasksAndStatuses }) => {
                if (this._isMounted) this.setState({ Loading: false, UserTasksAndStatuses });
            });
        });
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps) {
        if (!!prevProps.NeedsRefresh !== !!this.props.NeedsRefresh && !!this.props.NeedsRefresh) this.onUpdateDate({ Direction: 0 });
    }

    onAddedTasksStatuses = ({ ClientIds }) => {
        // Current Client Had Tasks or Statuses Added
        if (ClientIds.indexOf(+this.props.ClientId) > -1) this.onRefreshWeeklyView();
    }

    onChangeToggle = event => {
        var Checked = event.target.checked;

        var CustomSettings_LS = localStorage.getItem('CustomSettings');
        if (CustomSettings_LS) CustomSettings_LS = JSON.parse(CustomSettings_LS);
        else CustomSettings_LS = { Meals_MonthlyView: false, Tasks_MonthlyView: false, Workouts_MonthlyView: false };

        if (Checked) CustomSettings_LS.Tasks_MonthlyView = false;
        else CustomSettings_LS.Tasks_MonthlyView = true;

        localStorage.setItem('CustomSettings', JSON.stringify(CustomSettings_LS));

        this.setState({ MonthlyView: !Checked }, () => this.onUpdateDate({ ChangedTo: Checked ? 'Weekly' : 'Monthly', Direction: 0 }));
    }

    onRefreshWeeklyView = () => {
        this.onUpdateDate({ Direction: 0 });
    }

    onSelectDate = SelectedDate => {
        var { ClientId } = this.props;
        var { MonthlyView } = this.state;

        var CalendarDifference, EndDate, StartDate;
        
        if (MonthlyView) {
            StartDate = moment(SelectedDate).startOf('month').format('YYYY-MM-DD');
            EndDate = moment(SelectedDate).endOf('month').format('YYYY-MM-DD');

            CalendarDifference = moment(StartDate).diff(moment().startOf('month').format('YYYY-MM-DD'), 'months');
        } else {
            StartDate = moment(SelectedDate).startOf('isoWeek').format('YYYY-MM-DD');
            EndDate = moment(SelectedDate).endOf('isoWeek').format('YYYY-MM-DD');

            CalendarDifference = moment(StartDate).isoWeekday(moment().isoWeekday()).diff(moment().format('YYYY-MM-DD'), 'weeks');
        }
        
        this.setState({ EndDate, StartDate });
        this.props.Tasks_GetUserTasksAndStatuses({ CalendarDifference, ClientId, EndDate, StartDate }).then(({ UserTasksAndStatuses }) => {
            this.setState({ UserTasksAndStatuses });
        });
    }

    onUpdateDate = ({ ChangedTo, Direction }) => {
        var { CalendarDifference, ClientId } = this.props;
        var { MonthlyView } = this.state;

        var CalculationDate, EndDate, StartDate;
        CalendarDifference += Direction;

        if (ChangedTo) {
            // Was Monthly, Now Weekly ... Get First Day Of Week Of Week Containing 1st Day Of Month
                if (ChangedTo === 'Weekly') {
                    CalculationDate = moment().add(CalendarDifference, 'months').format('YYYY-MM-DD');
                    StartDate = moment(CalculationDate).startOf('month').startOf('isoWeek').format('YYYY-MM-DD');
                    EndDate = moment(CalculationDate).startOf('month').endOf('isoWeek').format('YYYY-MM-DD');

                    // Compare to Today isoWeekday()
                    CalendarDifference = moment(StartDate).isoWeekday(moment().isoWeekday()).diff(moment().format('YYYY-MM-DD'), 'weeks');
                }
            // Was Weekly, Now Monthly ... Get First Day Of Month Of Month Container 1st Day Of Week
                else if (ChangedTo === 'Monthly') {
                    CalculationDate = moment().add(CalendarDifference, 'weeks').format('YYYY-MM-DD');
                    StartDate = moment(CalculationDate).startOf('isoWeek').startOf('month').startOf('isoWeek').format('YYYY-MM-DD');
                    EndDate = moment(CalculationDate).startOf('isoWeek').endOf('month').endOf('isoWeek').format('YYYY-MM-DD');

                    // Compare to today first day of month
                    CalendarDifference = moment(StartDate).diff(moment().startOf('month').format('YYYY-MM-DD'), 'months');
                }
        } else {
            if (MonthlyView) {
                StartDate = moment().add(CalendarDifference, 'months').startOf('month').startOf('isoWeek').format('YYYY-MM-DD');
                EndDate = moment().add(CalendarDifference, 'months').endOf('month').endOf('isoWeek').format('YYYY-MM-DD');
            }
            else {
                StartDate = moment().add(CalendarDifference, 'weeks').startOf('isoWeek').format('YYYY-MM-DD');
                EndDate = moment().add(CalendarDifference, 'weeks').endOf('isoWeek').format('YYYY-MM-DD');
            }
        }

        this.setState({ EndDate, StartDate });
        this.props.Tasks_GetUserTasksAndStatuses({ CalendarDifference, ClientId, EndDate, StartDate }).then(({ UserTasksAndStatuses }) => {
            this.setState({ UserTasksAndStatuses });
        })
    }

    renderMultiOptions = () => {
        var { t } = this.props;
        var { MonthlyView } = this.state;

        return (
            <MultiOptionsContainer>
                <MultiOptionContainer>
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('show_week_or_month')} TextAlign="center"  />
                    <Toggle
                        Checked={!MonthlyView}
                        OffLabel={t('monthly')}
                        OnChange={this.onChangeToggle}
                        OnLabel={t('weekly')}
                    />
                </MultiOptionContainer>
            </MultiOptionsContainer>
        );
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { ClientId, ClientDetails, TryingTasksAction } = this.props;
        var { MonthlyView, UserTasksAndStatuses } = this.state;

        ClientId = +ClientId;

        return (
            <>
                <div>
                    {
                        <>
                            <AddTasksStatuses
                                OnAddedTasksStatuses={this.onAddedTasksStatuses}
                                ClientDetails={ClientDetails}
                            />
        
                            <Spacer Size="large" />

                            <CalendarHeader MonthlyView={MonthlyView} OnSelectDate={SelectedDate => this.onSelectDate(SelectedDate)} OnUpdateDate={Direction => this.onUpdateDate({ Direction })} SectionType="Tasks" />

                            <Spacer Size="medium" />

                            <WeeklyView
                                ClientId={ClientId}
                                Loading={TryingTasksAction}
                                RefreshWeeklyView={this.onRefreshWeeklyView}
                                Type="Tasks"
                                UserTasksAndStatuses={UserTasksAndStatuses}
                            />
                        </> 
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        ClientDetails: state.Client.ClientDetails,

        CalendarDifference: state.Client.CalendarDifference_Tasks,
        
        TryingTasksAction: state.Tasks.TryingTasksAction,
        TryingTasksActionError: state.Tasks.TryingTasksActionError
    };
};
  
export default withTranslation()(connect(mapStateToProps, { Tasks_GetUserTasksAndStatuses } )(ClientTasksStatuses));