import styled from 'styled-components';

export const InputContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    > div {
        width: 85%;
    }
`;