import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearSaveData } from '../../Services/Actions';
import history from '../../history';
import queryString from 'query-string';

import { MenuButtons, StyledPage } from './styles';

import Spacer from '../../Components/Spacer';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';

import Dishes from './Dishes';
import Food from './Food';
import MealTemplates from './MealTemplates';

class Library extends React.Component {
    _isMounted = false;

    state = {
        Dishes: { MultiSelectDishes: [], MultiSelectDishIds: [], PrivateDishes: [], PrivateDishIds: [] },
        Loading: false,
        Menu: this.props.LibraryMenu || 'Meals',
        Submenu_Meals: this.props.LibraryMenu === 'Meals' ? this.props.LibrarySubmenu : 'MealTemplates'
    };

    componentDidMount() {
        this._isMounted = true;

        const QueryValues = queryString.parse(this.props.location.search);

        var Menu = 'Meals';
        var Submenu_Meals = 'MealTemplates';

        if (Object.entries(QueryValues).length > 0 && QueryValues.tab) {
            if (QueryValues.tab.toLowerCase() === 'meal-templates') { Menu = 'Meals'; Submenu_Meals = 'MealTemplates' }
            else if (QueryValues.tab.toLowerCase() === 'dishes') { Menu = 'Meals'; Submenu_Meals = 'Dishes' }
            else if (QueryValues.tab.toLowerCase() === 'food') { Menu = 'Meals'; Submenu_Meals = 'Food' }
            else { Menu = 'Meals'; Submenu_Meals = 'Dishes' };

            this.setState({ Menu, Submenu_Meals });
        }
        else history.push({ pathname: `/library`, search: `?tab=dishes` });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            const QueryValues = queryString.parse(this.props.location.search);

            var Menu = 'Meals';
            var Submenu_Meals = 'MealTemplates';

            if (Object.entries(QueryValues).length > 0 && QueryValues.tab) {
                if (QueryValues.tab.toLowerCase() === 'meal-templates') { Menu = 'Meals'; Submenu_Meals = 'MealTemplates' }
                else if (QueryValues.tab.toLowerCase() === 'dishes') { Menu = 'Meals'; Submenu_Meals = 'Dishes' }
                else if (QueryValues.tab.toLowerCase() === 'food') { Menu = 'Meals'; Submenu_Meals = 'Food' }
                else { Menu = 'Meals'; Submenu_Meals = 'Dishes' };

                this.setState({ Menu, Submenu_Meals });
            }
        }

        // if ((!!!+prevProps.ClientDetails.GetStartedCompleted_Workouts && !!+this.props.ClientDetails.GetStartedCompleted_Workouts) || (!!!+prevProps.ClientDetails.GetStartedCompleted_Meals && !!+this.props.ClientDetails.GetStartedCompleted_Meals)) {
        //     this.updateClientDetailsToState({ SamePage: true });
        // }
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearSaveData();
    }

    onCancelMultiSelectDishes = () => {
        this.setState({ Dishes: { MultiSelectDishes: [], MultiSelectDishIds: [], PrivateDishes: [], PrivateDishIds: [] } });
    }

    onDeleteDishes = DishIds => {
        var NewMultiSelectDishes = []; var NewMultiSelectDishIds = [];
        var NewPrivateDishes = []; var NewPrivateDishIds = [];

        for (var i = 0; i < this.state.Dishes.MultiSelectDishIds.length; i++) {
            if (DishIds.indexOf(Number(this.state.Dishes.MultiSelectDishIds[i])) === -1) {
                NewMultiSelectDishes.push({ ...this.state.Dishes.MultiSelectDishes[i] });
                NewMultiSelectDishIds.push({ ...this.state.Dishes.MultiSelectDishIds[i] });
            }
        }

        for (var a = 0; a < this.state.Dishes.PrivateDishIds.length; a++) {
            if (DishIds.indexOf(Number(this.state.Dishes.PrivateDishIds[a])) === -1) {
                NewPrivateDishes.push({ ...this.state.Dishes.PrivateDishes[a] });
                NewPrivateDishIds.push({ ...this.state.Dishes.PrivateDishIds[a] });
            }
        }

        this.setState({
            Dishes: {
                MultiSelectDishes: NewMultiSelectDishes,
                MultiSelectDishIds: NewMultiSelectDishIds,
                PrivateDishes: NewPrivateDishes,
                PrivateDishIds: NewPrivateDishIds
            }
        });
    }

    onMultiSelectDishes = (Dish, MultiSelectIndex) => {
        var PrivateDish = Number(Dish.UserId) === Number(this.props.UserId);
        var PrivateDishIndex = this.state.Dishes.PrivateDishIds.indexOf(Number(Dish.DishId));

        if (MultiSelectIndex === -1) {
            this.setState({
                Dishes: {
                    MultiSelectDishes: [ ...this.state.Dishes.MultiSelectDishes, Dish ],
                    MultiSelectDishIds: [ ...this.state.Dishes.MultiSelectDishIds, Number(Dish.DishId)],
                    PrivateDishes: PrivateDish ? [ ...this.state.Dishes.PrivateDishes, Dish ] : [ ...this.state.Dishes.PrivateDishes ],
                    PrivateDishIds: PrivateDish ? [ ...this.state.Dishes.PrivateDishIds, Number(Dish.DishId) ] : [ ...this.state.Dishes.PrivateDishIds ]
                }
            });
        }
        else {
            this.setState((prevState) => {
                return {
                    Dishes: {
                        MultiSelectDishes: [ ...prevState.Dishes.MultiSelectDishes.slice(0, MultiSelectIndex), ...prevState.Dishes.MultiSelectDishes.slice(MultiSelectIndex + 1) ],
                        MultiSelectDishIds: [ ...prevState.Dishes.MultiSelectDishIds.slice(0, MultiSelectIndex), ...prevState.Dishes.MultiSelectDishIds.slice(MultiSelectIndex + 1) ],
                        PrivateDishes: PrivateDish ? [ ...prevState.Dishes.PrivateDishes.slice(0, PrivateDishIndex), ...prevState.Dishes.PrivateDishes.slice(PrivateDishIndex + 1) ] : [ ...prevState.Dishes.PrivateDishes ],
                        PrivateDishIds: PrivateDish ? [ ...prevState.Dishes.PrivateDishIds.slice(0, PrivateDishIndex), ...prevState.Dishes.PrivateDishIds.slice(PrivateDishIndex + 1) ] : [ ...prevState.Dishes.PrivateDishIds ]
                    }
                }
            });
        }
    }

    onSelectMenuItem = SelectedTab => {

        history.push({ pathname: `/library`, search: `?tab=${SelectedTab.toLowerCase()}` });

        // if (MenuType === 'Menu') this.setState({ Menu: SelectedTab });
        // else if (MenuType === 'Submenu') this.setState({ [`Submenu_${this.state.Menu}`]: SelectedTab });
    }

    renderDishes = () => {
        var { Menu, Submenu_Meals } = this.state;

        if (Menu !== 'Meals' || Submenu_Meals !== 'Dishes') return null;

        var { Dishes: { MultiSelectDishIds, PrivateDishes, PrivateDishIds } } = this.state;

        return (
            <Dishes
                HideSelect={false}
                MultiSelectDishIds={MultiSelectDishIds}
                OnCancelMultiSelect={this.onCancelMultiSelectDishes}
                OnDeleteDishes={DishIds => this.onDeleteDishes(DishIds)}
                OnMultiSelect={(Dish, MultiSelectIndex) => this.onMultiSelectDishes(Dish, MultiSelectIndex)}
                PrivateDishes={PrivateDishes}
                PrivateDishIds={PrivateDishIds}
            />
        );
    }

    renderFood = () => {
        var { Menu, Submenu_Meals } = this.state;

        if (Menu !== 'Meals' || Submenu_Meals !== 'Food') return null;

        var { Dishes: { MultiSelectDishIds, PrivateDishes, PrivateDishIds } } = this.state;

        return (
            <Food
                MultiSelectDishIds={MultiSelectDishIds}
                OnCancelMultiSelect={this.onCancelMultiSelectDishes}
                OnDeleteDishes={DishIds => this.onDeleteDishes(DishIds)}
                OnMultiSelect={(Dish, MultiSelectIndex) => this.onMultiSelectDishes(Dish, MultiSelectIndex)}
                PrivateDishes={PrivateDishes}
                PrivateDishIds={PrivateDishIds}
            />
        );
    }

    renderMealTemplates = () => {
        var { Menu, Submenu_Meals } = this.state;

        if (Menu !== 'Meals' || Submenu_Meals !== 'MealTemplates') return null;

        return <MealTemplates />;
    }

    renderMenus = () => {
        var { t } = this.props;
        var { Submenu_Meals } = this.state;

        var ButtonData = { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white' };

        var SubmenuButtons = [];
        SubmenuButtons.push({ ...ButtonData, OnClick: () => this.onSelectMenuItem('meal-templates'), Selected: Submenu_Meals === 'MealTemplates', Title: t('ptadmin_templates_header_meals') });
        SubmenuButtons.push({ ...ButtonData, OnClick: () => this.onSelectMenuItem('dishes'), Selected: Submenu_Meals === 'Dishes', Title: t('ptadmin_dishes_plural') });
        SubmenuButtons.push({ ...ButtonData, OnClick: () => this.onSelectMenuItem('food'), Selected: Submenu_Meals === 'Food', Title: t('food') });

        return (
            <MenuButtons>
                <ButtonGroup Buttons={SubmenuButtons} />
            </MenuButtons>
        );
    }

    render() {
        return (
            <StyledPage NoMargin>
                {this.renderMenus()}

                <Spacer Size="medium" />

                {this.renderDishes()}
                {this.renderFood()}
                {this.renderMealTemplates()}
            </StyledPage>
        );
    }
}

const mapStateToProps = state => {
    return {
        UserId: state.Auth.UserDetails.UserId,

        LibraryMenu: state.SaveData.LibraryMenu,
        LibrarySubmenu: state.SaveData.LibrarySubmenu,

        ClientDetails: state.Client.ClientDetails,

        TryingGetClientDetails: state.Client.TryingGetClientDetails,
        TryingGetClientDetailsError: state.Client.TryingGetClientDetailsError,
        TryingUpdateTrainerClientNote: state.Client.TryingUpdateTrainerClientNote,
        TryingUpdateTrainerClientNoteError: state.Client.TryingUpdateTrainerClientNoteError,
    };
};
  
export default withTranslation()(connect(mapStateToProps, { ClearSaveData } )(Library));