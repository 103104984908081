import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { SearchGroups, ReorderTrainerGroups } from '../../Services/Actions';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import  { ReorderGroupContainer, ReorderGroupsContainer, ReorderNumberContainer } from './styles';

import AbelModal from '../../Components/Modal';
import Group from './Group';
import Loading from '../../Components/Loading';

import { arrayMove } from '../../Functions';

const DragHandle = SortableHandle(({ OrderNumber }) => <ReorderNumberContainer>{OrderNumber}</ReorderNumberContainer>);

const SortableItem = SortableElement(({ OrderNumber, Group: GroupDetails, t }) => {
    return (
        <ReorderGroupContainer>
            <DragHandle OrderNumber={OrderNumber} />
            <Group Group={GroupDetails} />
        </ReorderGroupContainer>
    );
});

const SortableList = SortableContainer(({ Groups, t }) => {
    return (
        <ReorderGroupsContainer>
            {
                Groups.map((Group, index) => {
                    if (Group.GroupId === 0) return null;

                    return <SortableItem key={`${Group.GroupType}_${Group.GroupId}`} index={index} OrderNumber={index + 1} Group={Group} t={t} />
                })
            }
        </ReorderGroupsContainer>
    );
});

class ReorderGroups extends React.Component {
    _isMounted = false;

    state = { Groups: [], Loading: true, PageNo: 1, PageSize: 100000 }

    componentDidMount() {
        this._isMounted = true;

        this.onLoadGroups();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onClickSubmit = () => {
        var { Groups } = this.state;

        // Only 1 Group Exists
            if (Groups.length === 1 || (Groups.length === 2 && Groups[1].GroupId === 0)) this.props.OnHide();
            else {
                Groups = Groups.map(({ GroupId, GroupType }) => `${GroupType}_${GroupId}`);

                this.props.ReorderTrainerGroups({ Groups }).then(() => {
                    if (!this.props.TryingReorderTrainerGroupsError) this.props.OnRefresh();
                })
            }
    }

    onLoadGroups = Increment => {
        var { PageNo, PageSize } = this.state;

        if (Increment) PageNo = PageNo + 1;
        else PageNo = 1;

        this.setState({ PageNo },
            () => this.props.SearchGroups({ AddToExisting: Increment, EligibleTypes: 'Both', NoUsers: 1, PageNo, PageSize, ReturnData: true }).then(({ Groups, IsMoreResults: Groups_IsMoreResults, TotalRecords: Groups_TotalRecords }) => {
                if (this._isMounted && !this.props.TryingSearchGroupsError) {
                    var GroupsList = [];
                    
                    if (Increment) GroupsList = [ ...this.state.Groups, ...Groups ];
                    else GroupsList = [ ...Groups ];
                
                    this.setState({ Groups: GroupsList, Groups_IsMoreResults, Groups_TotalRecords, Loading: false });
                }
            })
        );
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState({ Groups: arrayMove(this.state.Groups, oldIndex, newIndex) });
    };

    renderModalBody = () => {
        if (this.state.Loading || this.props.TryingReorderTrainerGroups) return <Loading />;

        var { t } = this.props;

        return (
            <div>
                <SortableList Groups={this.state.Groups} onSortEnd={this.onSortEnd} t={t} />
            </div>
        );
    }

    render() {
        var { t } = this.props;
        var { OnHide, Show } = this.props;

        return (
            <AbelModal
                BottomButton="submit"
                BottomButtonOnClick={this.onClickSubmit}
                BottomButtonText={t('ptadmin_groups_reorder')}
                Disabled={this.props.TryingReorderTrainerGroups}
                GA_PathName="ReorderGroups"
                Show={Show}
                Size="large"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

ReorderGroups.propTypes = {
    OnHide: PropTypes.func.isRequired,
    OnRefresh: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        TryingReorderTrainerGroups: state.Clients.TryingReorderTrainerGroups,
        TryingReorderTrainerGroupsError: state.Clients.TryingReorderTrainerGroupsError,

        TryingSearchGroups: state.Groups.TryingSearchGroups,
        TryingSearchGroupsError: state.Groups.TryingSearchGroupsError
    };
};

export default withTranslation()(connect(mapStateToProps, { SearchGroups, ReorderTrainerGroups } )(ReorderGroups));