import styled from 'styled-components';

import Image from '../../Components/Image';
import PageText from '../../Components/Text/PageText';
import ProfilePhoto from '../../Components/ProfilePhoto';

export const ClientContainer = styled.div`
    align-items: center;
    ${props => !!props.WhiteBackground && 'background-color: var(--white);'}
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    padding: 10px;

    ${props => props.HorizontalScroll &&
        `
            :not(:last-child) {
                margin-right: 15px;
            }
        `
    }

    ${props => !props.HorizontalScroll &&
        `
            margin-right: 15px;
            margin-top: 15px;
        `
    }
`;

export const ClientsContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: ${props => props.Centered ? 'center' : 'flex-start'};
    max-width: 100%;
    overflow: hidden;
`;

export const ClientSelectionContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    max-width: 100%;
    padding: 7px 10px;
`;

export const EditedGroupIcon = styled(Image)`
    height: 20px;
    margin-left: 10px;
    width: 20px;
`;

export const EditIcon = styled(Image)`
    height: 30px;
    margin-right: 15px;
    width: 30px;
`;

export const SelectedContainer = styled.div``;

export const SelectedUsersContainer = styled.div`
    align-items: center;
    display: flex;
    max-width: 100%;
    overflow: auto;

    ${props => !props.HorizontalScroll &&
        `
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-right: -15px;
            margin-top: -15px;
            overflow: hidden;
        `
    }
`;

export const SingleClientContainer = styled.div`
    justify-content: center;
`;

export const StyledAssignedTo = styled(PageText)`
    margin-right: 15px;
    white-space: nowrap;
`;

export const StyledProfilePhoto = styled(ProfilePhoto)`
    margin-right: 10px;
`;