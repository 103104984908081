import {
    TRYING_USER_ACCOUNTS_ACTION,
    TRYING_USER_ACCOUNTS_ACTION_SUCCESS,
    TRYING_USER_ACCOUNTS_ACTION_ERROR
} from '../Types';

const INITIAL_STATE = {
    TryingUserAccountsAction: false,
    TryingUserAccountsActionError: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_USER_ACCOUNTS_ACTION:
            return { ...state, TryingUserAccountsAction: true, TryingUserAccountsActionError: null };
        case TRYING_USER_ACCOUNTS_ACTION_ERROR:
            return { ...state, TryingUserAccountsAction: false, TryingUserAccountsActionError: action.payload };
        case TRYING_USER_ACCOUNTS_ACTION_SUCCESS:
            return { ...state, TryingUserAccountsAction: false, TryingUserAccountsActionError: null };
        default:
            return state;
    }
};
