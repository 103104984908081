import styled from 'styled-components';
import { device } from '../../Config/device';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Dropdown from '../../Components/Dropdown';
import Image from '../../Components/Image';
import Input from '../../Components/Form/Input';
import Paginated from '../../Components/Lists/Paginated';
import ProfilePhoto from '../../Components/ProfilePhoto';
import TextArea from '../../Components/Form/TextArea';

import Checkmark from '../../Assets/Icons/Checkmark_Selected.png';

export const AdditionalActivityItem = styled.div`
    ${
        props => props.Selected ?
        `
            color: var(--blue-abel);
        `
    :
        null
    }

    :not(:last-child) {
        margin-bottom: 5px;
    }

    @media(hover:hover) {
        :hover {
            color: var(--blue-abel);
            cursor: pointer;
        }
    }
`;

export const AdditionalActivityList = styled.div``;

export const CircuitContainer = styled.div`
    ${
        props => props.CircuitType !== 'WorkoutExercise' &&
        `
            border: 5px solid ${props.CircuitType === 'Circuit' ? 'var(--turquoise-dark)' : 'var(--blue-royal)'};   
        `
    }
    border-radius: 10px;
`;

export const CircuitSeparator = styled.div`
    align-items: center;
    background-color: var(--${props => props.BackgroundColor});
    display: flex;
    flex-direction: column;
    height: var(--spacer-size-medium);
    justify-content: center;
`;

export const ClientIdContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 5px;
`;

export const ClientNoteContainer = styled.div`
    align-items: flex-start;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    width: 100%;

    :not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const DateTimeContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
    margin-top: 15px;
    width: 175px;
`;

export const DateTimesContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -15px;
    margin-top: -15px;
`;

export const DateTimeUseAllDays = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    display: block;
    height: 25px;
    margin-right: 5px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
            border: none;
            cursor: pointer;
        }
    }

    :nth-child(2) {
        max-width: 600px;
    }
`;

export const DateTimeUseAllDaysContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 10px;
`;

export const DurationContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    border: 2px solid ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white-concrete)'};
    border-radius: 10px;
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    display: flex;
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-medium-1);
    justify-content: center;
    margin-right: 15px;
    margin-top: 15px;
    padding: 7px 15px;

    @media(hover:hover) {
        :hover {
            background-color: var(--blue-astronaut);
            color: var(--white);
            cursor: pointer;
        }
    }
`;

export const DurationsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 10px;
    margin-right: -15px;
    margin-top: -15px;
`;

export const EditingWorkoutNameContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const FiltersContainer = styled.div``;

export const HourType = styled.div`
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    padding: 6px 15px;

    :hover {
        cursor: pointer;
    }
`;

export const HourTypesContainer = styled.div`
    align-items: center;
    border: 2px solid var(--blue-astronaut);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    height: 35px;
    margin: 0 auto;
    overflow: hidden;
    width: fit-content;
`;

export const LapContainer = styled.div`
    align-items: center;
    border: 2px solid ${props => props.HasBorder ? 'var(--blue-astronaut)' : 'var(--white-concrete)'};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

    @media ${device.minwidth.laptop} {
        display: grid;
        grid-template-columns: 1fr 8.5fr 0.5fr;
    }
`;

export const LapDetailsContainer = styled.div`
    width: 100%;
`;

export const LapFooterContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    display: flex;
    height: 100%;
    justify-content: center;
`;

export const LapHeaderContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    display: flex;
    flex-direction: row;
    padding: 5px;
    width: 100%;

    @media ${device.minwidth.laptop} {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }
`;

export const LapHeaderContainerLeft = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;

    @media ${device.minwidth.laptop} {
        justify-content: center
        width: 100%;
    }
`;

export const MemberCompletedStatusContainer = styled.div`
    align-items: stretch;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;

    @media ${device.minwidth.ipad} {
        padding: 30px 40px;
    }
`;

export const StyledDropdown = styled(Dropdown)`
    margin: 0 auto;
    max-width: 350px;
    width: 100%;

    ${props => props.ForceClose === false ? 'z-index: 1001;' : null};

    ${
        props => props.IsMobile ?
        `
            > div { max-height: none; }
        `
    :
        `
            ${props.Height ? `height: ${props.Height + 45 + 15}px;` : ''}
            margin: 0 auto;
            width: 350px
        `
    }
`;

export const StyledDuration = styled(Input)`
    margin: 0 auto;
    max-width: 100%;
    width: 350px;

    > input {
        text-align: center;
        text-indent: 0px;
    }
`;

export const StyledEditingWorkoutName = styled.div`
    font-family: var(--font-family-medium);
    font-size: var(--font-size-large);
    text-align: center;
`;

export const StyledEditWorkoutNameIcon = styled(Image)`
    height: 30px;
    margin-left: 10px;
    width: 30px;
`;

export const StyledIntensities = styled(ButtonGroup)`
    margin: 0 auto;
    max-width: 100%;
    width: 350px;
`;

export const StyledPaginatedList = styled(Paginated)`
    padding: 15px;

    > div:first-child {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(1, 2fr);

        @media ${device.minwidth.mobile} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media ${device.minwidth.ipad} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media ${device.minwidth.laptop} {
            grid-template-columns: repeat(3, 1fr);
        }
    }
`;

export const StyledProfilePhoto = styled(ProfilePhoto)`
    margin-right: 10px;
`;

export const StyledSearchInput = styled(Input)`
    flex: 1;
    margin-bottom: 10px;

    > input {
        border: 2px solid var(--white-concrete);
    }

    @media (min-width: 975px) {
        margin-right: 10px;
    }

    @media ${device.minwidth.laptop} {
        margin-right: 60px;
    }
`;

export const StyledWorkoutImage = styled(Image)`
    border-radius: 10px;    
    height: 200px;
    object-fit: cover;
    width: 100%;

    > div {
        width: 100%;
    }
`;

export const StyledWorkoutNameInput = styled(Input)`
    margin: 0 auto;
    max-width: 350px;
    width: 100%;

    > input {
        text-align: center;
        text-indent: 0px;
    }
`;

export const StyledWorkoutNoteTextArea = styled(TextArea)`
    margin-bottom: 20px;
    width: 100%;

    > textarea {
        border: 2px solid var(--white-concrete);
        padding: 10px;
        resize: none;
    }
`;

export const SurveyDataAveragesContainer = styled.div``;

export const SurveyDataClientsContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(1, 2fr);

    @media ${device.minwidth.mobile} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.minwidth.laptop} {
        grid-template-columns: repeat(3, 1fr);
    }

    > div {
        margin-bottom: 0px !important;
    }
`;

export const SurveyDataContainer = styled.div``;

export const WorkoutExercisesContainer = styled.div``;

export const WorkoutInformationContainer = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;

    ${
        props => props.NumColumns === 2 &&
        `
            @media ${device.minwidth.ipad} {
                grid-template-columns: 1fr 1fr;
            }
        `
    }
`;

export const WorkoutInformationContainerInner = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 10px;

    :not(:last-child) {
        margin-bottom: 10px;
    }

    :first-child {
        flex-direction: column;

        ${
            props => props.AddMargin &&
            `
                @media (max-width: 1279px) {
                    > div:first-child {
                        margin-bottom: 10px;
                    }
                }
            `
        }

        @media (min-width: 1280px) {
            flex-direction: row;
        }
    }

    @media ${device.minwidth.ipad} {
        :not(:last-child) {
            margin-bottom: 0px;
        }
    }
`;

export const WorkoutLapsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const WorkoutNotesContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;

    @media ${device.minwidth.ipad} {
        padding: 30px 40px;
    }
`;