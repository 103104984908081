import React from 'react';
import PropTypes from 'prop-types';

import * as Styles from './styles';

import AbelModal from '../../Components/Modal';
import Exercises from '../../Pages/Library2/Workout/Exercises';

class AddExercise extends React.Component {
    onAddExercises = MultiSelectExercises => {
        var SelectedExercises = MultiSelectExercises.map(({ ExerciseId, ExerciseImage, ExerciseName, ExerciseType }) => ({ ExerciseId: +ExerciseId, ExerciseImage, ExerciseName, ExerciseType: +ExerciseType }));

        this.props.OnHide();
        this.props.OnAddExercises(SelectedExercises);
    }

    renderModalBody = () => {
        var { ChangeExercise, MaxExercises, ReturnData } = this.props;

        return (
            <Styles.Container>
                <Exercises
                    ChangeExercise={ChangeExercise}
                    ExcludeExercises={this.props.ExcludeExercises}
                    FromAddExercise={true}
                    HideSelect={false}
                    IsModal
                    IsMultiSelect
                    MaxExercises={MaxExercises}
                    OnAddExercises={this.onAddExercises}
                    OnToggleShowExerciseDetailModal={this.props.OnToggleShowExerciseDetailModal}
                    ReturnData={ReturnData}
                />
            </Styles.Container>
        );
    }

    render() {
        var { DisplayNone, OnHide, Show } = this.props;

        return (
            <AbelModal
                DisplayNone={DisplayNone}
                GA_PathName="AddExercise"
                NoMargin
                Show={Show}
                Size="full"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

AddExercise.propTypes = {
    ChangeExercise: PropTypes.bool,
    OnAddExercises: PropTypes.func.isRequired,
    DisplayNone: PropTypes.bool,
    ExcludeExercises: PropTypes.array,
    OnHide: PropTypes.func.isRequired,
    OnToggleShowExerciseDetailModal: PropTypes.func,
    MaxExercises: PropTypes.number,
    Refresh: PropTypes.func,
    ReturnData: PropTypes.object,
    Show: PropTypes.bool.isRequired
}

AddExercise.defaultProps = {
    ChangeExercise: false,
    OnAddExercises: () => null,
    ExcludeExercises: [],
    MaxExercises: 0
}

export default AddExercise;