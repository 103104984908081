import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { TransactionHistory_Get, ViewDataCenterDashboardFilters } from '../../Services/Actions';
import history from '../../history';
import moment from 'moment';
import { toast } from 'react-toastify';

import * as styles from './styles';

import Filters from './filter';
import Loading from '../../Components/Loading';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

const TABLE_COLUMNS = [
    { Id: 1, SortCol: 'TransactionId', Title: 'TransactionId' },
    { Id: 2, SortCol: 'Trainer', Title: 'DHC' },
    { Id: 3, SortCol: 'Client', Title: 'Sluttbruker' },
    { Id: 4, SortCol: 'ProductNames', Title: 'Product' },
    { Id: 5, SortCol: 'PaymentAmount', Title: 'Payment Amount' },
    { Id: 6, SortCol: 'PaymentMadeDate', Title: 'Payment Made Date' }
];

const LOCALE_LANGUAGE = 'no-NO';

class TransactionHistoryComponent extends React.Component {
    state = {
        TransactionHistory: [],

        HasLoadedFilters: false,
        FilterOptions: {
            Organizations: []
        },
        Filters: {
            EndDate: moment().endOf('month').format('YYYY-MM-DD'),
            Organizations: { OrganizationId: 41, DisplayName: 'PT Gruppen' },
            StartDate: moment().startOf('month').format('YYYY-MM-DD')
        },
        FiltersUpdated: false
    }

    componentDidMount() {
        if (!this.props.UserDetails.DataCenterAccess) history.push('/clients');
        else this.onGetTransactionHistory();
    }

    onBlurDate_EndDate = () => {
        this.onGetTransactionHistory();
    }

    onBlurDate_StartDate = () => {
        this.onGetTransactionHistory();
    }

    onBlurFilter = () => {
        if (this.state.FiltersUpdated) {
            this.setState({ FiltersUpdated: false }, () => {
                this.onGetTransactionHistory();
            })
        }
    }

    onChangeDate_EndDate = event => {
        var Filters = { ...this.state.Filters };
        Filters.EndDate = event.target.value;

        this.setState({ Filters });
    }

    onChangeDate_StartDate = event => {
        var Filters = { ...this.state.Filters };
        Filters.StartDate = event.target.value;

        this.setState({ Filters });
    }
    
    onChangeFilter = ({ Data, Type }) => {
        var Filters = { ...this.state.Filters };
        Filters[Type] = Data;

        this.setState({ Filters }, () => {
            this.onGetTransactionHistory();
        })
    }

    onCopyResults = () => {
        var displayedColumns = [ 'TransactionId', 'DHC', 'Sluttbruker', 'Produkter', 'Payment Amount', 'Payment Made Date'];

        let nodeData = this.state.TransactionHistory;

        var tbl = document.createElement('table');
        var tblBody = document.createElement('tbody');
        var headerow = document.createElement('tr');
        var header = displayedColumns;

        headerow.innerHTML = `<td>${header[0]}</td><td>${header[1]}</td><td>${header[2]}</td><td>${header[3]}</td><td>${header[4]}</td><td>${header[5]}</td>`;
        tblBody.appendChild(headerow);
        nodeData.forEach((data) => {
            var row = document.createElement('tr');
            row.innerHTML = `<td>${data.TransactionId}</td><td>${data.Trainer}</td><td>${data.Client}</td><td>${data.ProductNames}</td><td>${data.PaymentAmount}</td><td>${data.PaymentMadeDate}</td>`;
            tblBody.appendChild(row);
        });
        tbl.appendChild(tblBody);
        document.body.appendChild(tbl);
        // Copy the table element innerText to clipboard
        navigator.clipboard.writeText(tbl.innerText);
        // Hide the table element from DOM after copied
        tbl.style.display = "none";

        toast.success('Table copied to clipboard', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    onGetTransactionHistory = () => {
        var { EndDate, Organizations: { OrganizationId }, StartDate } = this.state.Filters;

        this.props.TransactionHistory_Get({ EndDate, OrganizationId, StartDate }).then(({ TransactionHistory }) => {
            this.setState({ TransactionHistory });
        })
    }

    onLoadFilters = () => {
        if (this.state.HasLoadedFilters) return null;

        this.props.ViewDataCenterDashboardFilters().then(({ Organizations }) => {
            var FilterOptions = { ...this.state.FilterOptions, Organizations };
            this.setState({ HasLoadedFilters: true, FilterOptions });
        })
    }

    renderTransactionHistory = () => {
        var { TransactionHistory } = this.state;

        return (
            <>
                <Spacer Size="small" />

                <ButtonGroup
                    Buttons={[
                        { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Color: 'white', ColorHover: 'white-hover', FontFamily: 'semibold', OnClick: this.onCopyResults, Title: 'Copy table' }
                    ]}
                    ButtonWidth="fit-content"
                    NotTouching
                />

                <styles.UsersContainer className="UsersContainer">
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${TransactionHistory.length} payments`} />

                    <Spacer Size="small" />

                    <styles.UsersTableContainer className="UsersTableContainer">
                        <styles.UsersTable className="UsersTable">
                            <styles.UsersTableHeader className="UsersTableHeader">
                                <styles.UsersTableHeaderRow className="UsersTableHeaderRow">
                                    {
                                        TABLE_COLUMNS.map(({ Id, Title }) => {
                                            return (
                                                <styles.UsersTableHeaderCell className="UsersTableHeaderCell" key={Id}>
                                                    <styles.UsersTableHeaderCellInnerDiv className="UsersTableHeaderCellInnerDiv">
                                                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Title} />
                                                    </styles.UsersTableHeaderCellInnerDiv>
                                                </styles.UsersTableHeaderCell>
                                            );
                                        })
                                    }
                                </styles.UsersTableHeaderRow>
                            </styles.UsersTableHeader>
                            <styles.UsersTableBody className="UsersTableBody">
                                {
                                    TransactionHistory.map(TransactionRecord => {
                                        var { Client, PaymentAmount, PaymentMadeDate, ProductNames, Trainer, TransactionId } = TransactionRecord;

                                        return (
                                            <styles.UsersTableBodyRow className="UsersTableBodyRow" key={TransactionId}>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {TransactionId}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {Trainer}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {Client}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {ProductNames}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {new Intl.NumberFormat(LOCALE_LANGUAGE, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(PaymentAmount)}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {moment(PaymentMadeDate).format('[TrackingHistory]')}
                                                </styles.UsersTableBodyCell>
                                            </styles.UsersTableBodyRow>
                                        )
                                    })
                                }
                            </styles.UsersTableBody>
                        </styles.UsersTable>
                    </styles.UsersTableContainer>
                </styles.UsersContainer>
            </>
        );
    }

    render() {
        var { TryingGetDataCenterDashboardFilters } = this.props;
        var { EndDate, Organizations: SelectedOrganizations, StartDate } = this.state.Filters;
        var { Organizations } = this.state.FilterOptions;

        return (
            <>
                {(this.props.TryingUserAccountsAction) && <Loading />}

                <Page NoMargin>
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text="Transaction History" TextAlign="center" />

                    <Spacer Size="medium" />

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Start date" />
                    <input
                        type="date"
                        onBlur={this.onBlurDate_StartDate}
                        onChange={this.onChangeDate_StartDate}
                        value={StartDate}
                    />

                    <Spacer Size="extra-small" />

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="End date" />
                    <input
                        type="date"
                        onBlur={this.onBlurDate_EndDate}
                        onChange={this.onChangeDate_EndDate}
                        value={EndDate}
                    />

                    <Spacer Size="extra-small" />

                    <Filters
                        Organizations={Organizations}
                        Loading={TryingGetDataCenterDashboardFilters}
                        OnBlurFilter={this.onBlurFilter}
                        OnChangeFilter={this.onChangeFilter}
                        OnFocusFilter={this.onLoadFilters}
                        OnTypeSearchInput={this.onTypeSearchInput}
                        SelectedContent={{
                            Organizations: SelectedOrganizations
                        }}
                    />

                    <Spacer Size="medium" />

                    {this.renderTransactionHistory()}
                </Page>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        TryingGetDataCenterDashboardFilters: state.DataCenter.TryingGetDataCenterDashboardFilters,
        TryingGetDataCenterDashboardFiltersError: state.DataCenter.TryingGetDataCenterDashboardFiltersError,
        TryingUserAccountsAction: state.UserAccounts.TryingUserAccountsAction,
        TryingUserAccountsActionError: state.UserAccounts.TryingUserAccountsActionError
    };
};

export default withTranslation()(connect(mapStateToProps, { TransactionHistory_Get, ViewDataCenterDashboardFilters })(TransactionHistoryComponent));