import styled from "styled-components";
import { device } from "../../Config/device";

import Image from '../../Components/Image';
import Input from "../../Components/Form/Input";
import TextArea from "../../Components/Form/TextArea";
import ButtonGroup from "../../Components/Buttons/ButtonGroup";
import MultiText from "../../Components/Text/MultiText";

import Checkmark from '../../Assets/Icons/Checkmark_Selected.png';
import BodyMuscles from "../../Components/BodyMuscles";

export const TestSetDescriptionContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 25px;

  @media ${device.maxwidth.ipad} {
    border-top: 3px solid var(--white);
    padding-top: 15px;
    width: 100%;
  }
`;

export const TestSetDescriptionImage = styled(Image)`
  height: 75px;

  @media ${device.maxwidth.ipad} {
    margin-right: 10px;
  }

  @media ${device.minwidth.laptop} {
    margin-left: 10px;
  }
`;

export const WorkoutPdfPreviewContainer = styled.div``;

export const BodyContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.minwidth.ipad} {
    align-items: start;
    flex-direction: row;
    justify-content: space-between;

    > div:first-child {
      margin-right: 20px;
    }
  }
`;

export const LeftArea = styled.div`
  flex: 1;
  width: 100%;

  @media ${device.minwidth.ipad} {
    align-items: start;
    flex-direction: row;
    justify-content: space-between;

    > div:first-child {
      margin-right: 20px;
    }
  }
`;

export const RightArea = styled.div`
  flex: 1;
  width: 100%;
`;

export const LeftAreaItem = styled.div`
  width: 100%;
`;

export const RightAreaItem = styled.div`
  width: 100%;
`;

export const StyledTitle = styled(Input)`
  margin: 10px auto;
  width: 100%;

  > input {
    background-color: var(--white-concrete);
    border: none;
    border-radius: 10px;
    text-align: left;
    text-indent: 0px;
  }
`;

export const StyledWorkoutPdfPreviewTextArea = styled(TextArea)`
  margin: 10px auto;
  width: 100%;

  > textarea {
    background-color: var(--white-concrete);
    border: none;
    border-radius: 10px;
    text-align: left;
    text-indent: 0px;
    resize: none;
  }
`;

export const UploadButtonsContainer = styled(ButtonGroup)`
  justify-content: space-between;
`;

export const UploadButtonsRow = styled.div`
  margin: 10px 0;
`;

export const StyledMultiText = styled(MultiText)`
  align-items: center;
  justify-content: center;
  font-style: italic;
`;

export const MediaPreview = styled.div`
    position: relative;
`;

export const StyledImageContainer = styled.div`
  width: 50%;
  display: inline-flex;
  justify-content: space-between;
  margin: 10px 0;
`;

export const StyledLogoImage = styled(Image)`
    border-radius: 10px;
    height: 130px;
    object-fit: cover;
`;

export const StyledLogoButtons = styled(ButtonGroup)`
  display: flex;
  flex: 2;
  justify-content: space-between;

  ${props => !!props.NeedsMargin && 'margin-left: 10px;'}
`;



export const SaveLogoCheckmark = styled.span`
    ${props => props.Selected ?
    `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
        `
    :
    `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
  };
    border-radius: 50%;
    display: block;
    height: 25px;
    margin-right: 5px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
            border: none;
            cursor: pointer;
        }
    }

    :nth-child(2) {
        max-width: 600px;
    }
`;

export const SaveLogoContainer = styled.div`
    align-items: center;
    display: flex;
    margin-top: 10px;
`;

export const WorkoutDetailsContainer = styled.div`
  background-color: ${props => props.LapTypeBreak ? 'var(--orange)' : 'var(--white-concrete)'} ;
  border: none;
  border-radius: 10px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.minwidth.ipad} {
    align-items: start;
    flex-direction: row;
    justify-content: space-between;

    > div:first-child {
      margin-right: 20px;
    }
  }
`;

export const ExerciseImageContainer = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  padding: 5px;
  width: 50%;
  height: 240px;

  @media ${device.minwidth.ipad} {
    height: ${props => props.Device === 'ipad' ? '100px' : 'auto'};
  }
`;

export const StyledExerciseImage = styled(Image)`
  border-radius: 10px;
  height: 235px;
  max-height: 235px;
  object-fit: cover;
  width: 100%;
  max-height: 235px;

  @media ${device.minwidth.ipad} {
    height: ${props => props.Device === 'ipad' ? '90px' : '235px'};
  }
`;

export const MusclesUsedContainer = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  padding: 5px;
  width: 50%;
  margin-left: 5px;
  height: 240px;

  @media ${device.minwidth.ipad} {
    height: ${props => props.Device === 'ipad' ? '100px' : 'auto'};
  }
`;

export const StyledBodyMuscles = styled(BodyMuscles)`
  margin: 0 auto;
  width: 100%;
  height: 240px;

  > svg {
    height: 235px;
    max-height: 235px;
    width: 100%;
  }

  @media ${device.minwidth.ipad} {
    height: ${props => props.Device === 'ipad' ? '100px' : '240px'};

    > svg {
      height: ${props => props.Device === 'ipad' ? '90px' : '235px'};
    }
  }
`;

export const LeftAreaTop = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  margin: 10px 0;
`;

export const LeftAreaBottom = styled.div`
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px 0;
`;

export const StyledWorkoutInstructionsTextArea = styled(TextArea)`
  // margin: 0 10px;
  width: 95%;
  height: 250px;

  @media ${device.minwidth.ipad} {
    height: auto;
  }

  @media ${device.maxwidth.mobile} {
    height: 240px;

    > textarea {
      height: 230px
    }
  }

  > textarea {
    background-color: var(--white);
    border: none;
    border-radius: 10px;
    text-align: left;
    text-indent: 0px;
    resize: none;
    padding: 15px;
  }
`;

export const WorkoutSetsContainer = styled.div`
  > div:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const WorkoutSetContainer = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: ${props => props.HasWeight ? '0.2fr 0.3fr 0.3fr 0.2fr;' : '0.2fr 0.4fr 0.4fr;'}

  @media ${device.minwidth.ipad} {
    display: grid;
    grid-template-columns:  ${props => props.HasWeight ? '0.2fr 0.3fr 0.3fr 0.2fr;' : '0.2fr 0.4fr 0.4fr;'}
  }

`;

export const WorkoutSetCount = styled.div`
  background-color: var(--blue-abel);
  padding: 10px;
  font-size: 30px;
  text-align: left;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
`;

export const WorkoutSetDetails = styled.div`
  background-color: var(--white);
  padding: 10px;
  font-size: 30px;
  text-align: left;
`;

export const WorkoutSetBreak = styled.div`
  background-color: var(--white);
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  font-size: 30px;
  text-align: left;
`;

export const WorkoutHeading = styled.div`
  margin: 10px 0;
`;

export const WorkoutLapsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
`;

export const DownloadPDFContainer = styled.div`
  display: none;
`;