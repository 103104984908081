import React from 'react';
import { withTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import * as Styles from './styles';

import ButtonGroup from '../../../Components/Buttons/ButtonGroup';
import Checkmark from '../../../Components/Form/Checkmark';
import Loading from '../../../Components/Loading';
import MultiText from '../../../Components/Text/MultiText';
import PageText from '../../../Components/Text/PageText';
import Spacer from '../../../Components/Spacer';

import Info from '../../../Modals/Info';

import { GetTranslatedFilterName } from '../../../Functions';

import AbelIcon from '../../../Assets/Icons/Abel_HeaderIcon.png';
import { ReactComponent as CheckmarkSVG } from '../../../Assets/SVG/Checkmark.svg';
import { ReactComponent as EditSVG } from '../../../Assets/SVG/Edit.svg';
import Female from '../../../Assets/Icons/Female.png';
import { ReactComponent as HiddenSVG } from '../../../Assets/SVG/Hidden.svg';
import Male from '../../../Assets/Icons/Male.png';
import { ReactComponent as PlusSVG } from '../../../Assets/SVG/Plus.svg';
import { ReactComponent as ReorderSVG } from '../../../Assets/SVG/Reorder.svg';
import { ReactComponent as TrashSVG } from '../../../Assets/SVG/Trash.svg';
import { ReactComponent as VisibleSVG } from '../../../Assets/SVG/Visible.svg';
import { ReactComponent as XSVG } from '../../../Assets/SVG/X.svg';

const getItemStyle = (isDragging, draggableStyle) => ({
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "",
  
    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({ background: isDraggingOver ? "lightblue" : "" });

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [ removed ] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};

class CustomizeFilters extends React.Component {
    state = {
        DeleteTagDetail: {},
        EditTagDetail: {},
        IsDuplicate_EditTag: 0,
        NewTags: [ '' ],
        ReorderedFilters: [],
        ShowChooseCategoryTagsModal: false,
        ShowCreateTagModal: false,
        ShowDeleteTagModal: false,
        ShowEditTagModal: false,
        ShowInsertEditTagModal: false,
        ShowReorderFilters: false,
        TagDetails: { TagId: null },
        TryingChooseCategories: false,
        TryingEditTagName: false
    }

    onAddTagRow = () => {
        this.setState({ NewTags: [ ...this.state.NewTags, '' ] });
    }

    onAddTags = () => {
        var NewTags = [ ...new Set(this.state.NewTags.filter(NT => NT !== '').map(NT => NT.trim().substring(0, 22))) ].join(';');

        this.props.OnInsertTags(NewTags);
        this.onToggleShowCreateTagModal(false);
    }

    onBlurEditTagName = event => {
        var EditTagDetail = { ...this.state.EditTagDetail };
        EditTagDetail.TagName = event.target.value.trim();

        this.setState({ EditTagDetail });
    }

    onBlurTagName = ({ event, index }) => {
        var NewTag = event.target.value;

        var NewTags = [ ...this.state.NewTags ];
        if (NewTag !== NewTag.trim()) NewTags[index] = NewTag.trim();
        
        this.setState({ NewTags });
    }

    onChangeEditTagName = event => {
        var EditTagName = event.target.value;

        if (EditTagName.length >= 22 || EditTagName.indexOf(';') !== -1) return;

        var EditTagDetail = { ...this.state.EditTagDetail };
        EditTagDetail.TagName = EditTagName;

        this.setState({ EditTagDetail });
    }

    onChangeTagName = ({ event, index }) => {
        var NewTag = event.target.value;

        if (NewTag.length >= 22 || NewTag.indexOf(';') !== -1) return;

        var NewTags = [ ...this.state.NewTags ];
        NewTags[index] = NewTag;

        this.setState({ NewTags });
    }

    onDeleteCategory = ({ CategoryId }) => {
        this.props.OnDeleteCategory({ CategoryId });
    }

    onDeleteTag = () => {
        this.props.OnDeleteTag({ Tag: this.state.DeleteTagDetail });
        this.setState({ DeleteTagDetail: {}, ShowDeleteTagModal: false });
    }

    onDragEnd = result => {
        if (!result.destination) return;

        const sourceIndex = result.source.index;
        const destIndex = result.destination.index;

        if (result.type === 'droppableItem') {
            var ReorderedFilters = [ ...reorder(this.state.ReorderedFilters, sourceIndex, destIndex) ];

            this.setState({ ReorderedFilters });
        }
    }

    onEditTag = () => {
        var { OriginalTagName, TagId, TagName } = this.state.EditTagDetail;

        if (OriginalTagName === TagName) this.onToggleShowEditTagModal(false);
        else {
            this.setState({ TryingEditTagName: true });
            this.props.OnEditTag({ TagId, TagName }).then(IsDuplicate => {
                this.setState({ IsDuplicate_EditTag: IsDuplicate, TryingEditTagName: false });
                
                if (!IsDuplicate) this.onToggleShowEditTagModal(false);
            });
        }
    }

    onSaveReordering = () => {
        this.props.OnReorderFilters(this.state.ReorderedFilters);

        this.setState({ ReorderedFilters: [], ShowReorderFilters: false });
    }

    onSelectTag = (event, Filter, TagId, TagIndex, TargetAvoid, OppositeTargetAvoidIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = TagIndex === -1;

        var { SelectedCategories } = this.state;

        var FilterName = Filter.FilterName;
        if (FilterName === 'Joints' || FilterName === 'Muscles') {
            FilterName = `${TargetAvoid}${FilterName}`;
            Filter.TargetAvoid = TargetAvoid;
        }

        if (checked) {
            SelectedCategories[FilterName] = [ ...SelectedCategories[FilterName], TagId ];

            // Remove the opposite target/avoid if already selected
                if ((Filter.FilterName === 'Joints' || Filter.FilterName === 'Muscles') && OppositeTargetAvoidIndex !== -1) {
                    var OppositeFilterName = `${TargetAvoid === 'Avoid' ? 'Target' : 'Avoid'}${Filter.FilterName}`;
                    SelectedCategories[OppositeFilterName] = [ ...this.state.SelectedCategories[OppositeFilterName].slice(0, OppositeTargetAvoidIndex), ...this.state.SelectedCategories[OppositeFilterName].slice(OppositeTargetAvoidIndex + 1) ];
                }
        } else SelectedCategories[FilterName] = [ ...this.state.SelectedCategories[FilterName].slice(0, TagIndex), ...this.state.SelectedCategories[FilterName].slice(TagIndex + 1) ];

        this.setState({ SelectedCategories });
    }

    onToggleShowCreateTagModal = ShowCreateTagModal => {
        this.setState({ NewTags: [ '' ], ShowCreateTagModal });
    }

    onToggleShowDeleteTagModal = (ShowDeleteTagModal, TagId, TagIndex) => {
        this.setState({ ShowDeleteTagModal, DeleteTagDetail: ShowDeleteTagModal ? { TagId, TagIndex } : {} });
    }

    onToggleShowEditTagModal = (ShowEditTagModal, TagId, TagName) => {
        this.setState({ ShowEditTagModal, EditTagDetail: ShowEditTagModal ? { OriginalTagName: TagName, TagId, TagName } : {} });
    }

    onToggleShowReorderFilters = ShowReorderFilters => {
        this.setState({ ReorderedFilters: !!ShowReorderFilters ? this.props.Filters : [], ShowReorderFilters });
    }

    renderDeleteTagModal = () => {
        var { ShowDeleteTagModal } = this.state;

        if (ShowDeleteTagModal) {
            var { t } = this.props;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteTag}
                    BottomButtonText={t('_yes_delete')}
                    Information={t('_are_you_sure_delete_tag')}
                    OnHide={() => this.onToggleShowDeleteTagModal(false)}
                    Show={ShowDeleteTagModal}
                    Size="medium"
                    TextAlign="center"
                />
            );
        }
    }

    renderFilters = () => {
        var { t } = this.props;
        var { Filters } = this.props;

        var HiddenFilters = Filters.filter(F => F.IsHidden);
        var VisibleFilters = Filters.filter(F => !F.IsHidden);

        var ReorderOptions = [];

        if (!this.state.ShowReorderFilters) ReorderOptions = [{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', IconSVG: ReorderSVG, IconSVGColor: 'white', IconPosition: 'left', OnClick: () => this.onToggleShowReorderFilters(true), Title: t('_reorder') }]
        else {
            ReorderOptions = [
                { BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', IconSVG: CheckmarkSVG, IconSVGColor: 'white', IconPosition: 'left', OnClick: this.onSaveReordering, Title: t('_save') },
                { BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', IconSVG: XSVG, IconSVGColor: 'black', IconPosition: 'left', OnClick: () => this.onToggleShowReorderFilters(false), Title: t('_cancel') }
            ]
        }

        return (
            <>
                <PageText FontFamily="bold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('_filters')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <Styles.HiddenInstruction className="HiddenInstruction">
                    <Styles.HiddenInstructionImageContainer className="HiddenInstructionImageContainer" Disabled>
                        <VisibleSVG />
                    </Styles.HiddenInstructionImageContainer>

                    <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('_click_on_the_eye_to_hide')} TextAlign="left" />
                </Styles.HiddenInstruction>

                <Spacer Size="extra-extra-small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'bold', FontSize: 'small', Text: t('_open_close') },
                        { FontFamily: 'medium-italic', FontSize: 'small', Text: t('_decide_default_open_close_state') },
                    ]}
                />

                <Spacer Size="extra-small" />

                <Styles.FiltersListContainer className="FiltersListContainer">
                    <Styles.VisibleFiltersListContainer className="VisibleFiltersListContainer">
                        {
                            this.state.ShowReorderFilters ?
                            <DragDropContext className="DragDropContext" onDragEnd={this.onDragEnd}>
                                {this.renderReorderFilters()}
                            </DragDropContext>
                        :
                            VisibleFilters.map((F, index) => {
                                var TranslatedFilterName = GetTranslatedFilterName(F, t);

                                return (
                                    <Styles.FilterItem className="FilterItem" key={F.TagFilterId}>
                                        <Styles.FilterItemLeft className="FilterItemLeft">
                                            <PageText FontFamily="bold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={`${index + 1}`} TextAlign="left" />
                                            
                                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={TranslatedFilterName} TextAlign="left" />
                                        </Styles.FilterItemLeft>
                                        <Styles.FilterItemRight className="FilterItemRight">
                                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.props.OnToggleFilterStartOpen(F)} NoMargin Text={F.StartOpen ? t('_open') : t('_close')} TextAlign="left" WordBreak="keep-all" />

                                            <Styles.HiddenInstructionImageContainer className="HiddenInstructionImageContainer" onClick={() => this.props.OnToggleFilterVisibility(F, 'Hide')}>
                                                <VisibleSVG />
                                            </Styles.HiddenInstructionImageContainer>
                                        </Styles.FilterItemRight>
                                    </Styles.FilterItem>
                                );
                            })
                        }

                        <ButtonGroup
                            Buttons={ReorderOptions}
                            ButtonWidth="fit-content"
                            JustifyContent="flex-start"
                            NotTouching
                        />
                    </Styles.VisibleFiltersListContainer>

                    {
                        !this.state.ShowReorderFilters &&
                        <Styles.HiddenFiltersListContainer className="HiddenFiltersListContainer">
                            {
                                HiddenFilters.map(F => {
                                    var TranslatedFilterName = GetTranslatedFilterName(F, t);

                                    return (
                                        <Styles.FilterItem className="FilterItem" key={F.TagFilterId}>
                                            <Styles.FilterItemLeft className="FilterItemLeft">                                            
                                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Strikethrough Text={TranslatedFilterName} TextAlign="left" />
                                            </Styles.FilterItemLeft>
                                            <Styles.FilterItemRight className="FilterItemRight">
                                                <Styles.HiddenInstructionImageContainer className="HiddenInstructionImageContainer" Hidden onClick={() => this.props.OnToggleFilterVisibility(F, 'Show')}>
                                                    <HiddenSVG />
                                                </Styles.HiddenInstructionImageContainer>
                                            </Styles.FilterItemRight>
                                        </Styles.FilterItem>
                                    );
                                })
                            }
                        </Styles.HiddenFiltersListContainer>
                    }
                </Styles.FiltersListContainer>
            </>
        );
    }

    renderHighlightedTags = () => {
        var { t } = this.props;
        var { Categories } = this.props;

        return (
            <>
                <PageText FontFamily="bold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('_highlighted_tags')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('_pick_tags_as_highlights')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <ButtonGroup
                    Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', IconSVG: PlusSVG, IconSVGColor: 'white', IconPosition: 'left', OnClick: () => this.props.OnToggleShowChooseCategoryTagsModal(true), Title: t('_pick_tags') }]}
                    ButtonWidth="fit-content"
                    JustifyContent="flex-start"
                />

                {
                    Categories.length > 0 &&
                    <>
                        <Spacer Size="extra-small" />

                        <Styles.YourTagsContainer className="YourTagsContainer">
                            {
                                Categories.map(({ CategoryId, IsAbel, SecondaryTagType, TagName, TagType }) => {
                                    if (IsAbel && (TagType === 'Joints' || TagType === 'Muscles')) TagName = `${t(`_${SecondaryTagType.toLowerCase()}`)} - ${TagName}`;

                                    return (
                                        <Styles.FilterItem className="FilterItem" key={CategoryId}>
                                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={TagName} TextAlign="left" />

                                            <Styles.YourTagOptionsContainer className="YourTagOptionsContainer" onClick={() => this.onDeleteCategory({ CategoryId })}>
                                                <Styles.YourTagOptionsSVGContainer className="YourTagOptionsSVGContainer">
                                                    <XSVG />
                                                </Styles.YourTagOptionsSVGContainer>
                                            </Styles.YourTagOptionsContainer>
                                        </Styles.FilterItem>
                                    );
                                })
                            }
                        </Styles.YourTagsContainer>
                    </>
                }
            </>
        );
    }

    renderReorderFilters = () => {
        var { t } = this.props;

        return (
            <Droppable className="Droppable2" droppableId="Filters" type="droppableItem">
                {(provided, snapshot) => (
                    <Styles.ReorderFilterItemsContainer ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                        {
                            this.state.ReorderedFilters.map((F, index) => {
                                var TranslatedFilterName = GetTranslatedFilterName(F, t);

                                return (
                                    <Draggable key={F.TagFilterId} draggableId={`${F.TagFilterId}`} index={index}>
                                        {(provided, snapshot) => (
                                            <Styles.FilterItem className="FilterItem"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                <Styles.FilterItemLeft>
                                                    <span {...provided.dragHandleProps}>
                                                        {/* <Styles.DragHandleIcon ImageSrc={ReorderPng} /> */}
                                                        <Styles.HiddenInstructionImageContainer className="HiddenInstructionImageContainer" Hidden>
                                                            <ReorderSVG />
                                                        </Styles.HiddenInstructionImageContainer>
                                                    </span>

                                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Strikethrough={F.IsHidden} Text={TranslatedFilterName} TextAlign="left" />
                                                </Styles.FilterItemLeft>

                                                <Styles.HiddenInstructionImageContainer className="HiddenInstructionImageContainer" Disabled Hidden>
                                                    {F.IsHidden ? <HiddenSVG /> : <VisibleSVG />}
                                                </Styles.HiddenInstructionImageContainer>
                                            </Styles.FilterItem>
                                        )}
                                    </Draggable>
                                );
                            })
                        }
                        {provided.placeholder}
                    </Styles.ReorderFilterItemsContainer>
                )}
            </Droppable>
        );
    }

    renderShowCreateTagModal = () => {
        var { ShowCreateTagModal } = this.state;

        if (ShowCreateTagModal) {
            var { t } = this.props;
            var { NewTags } = this.state;

            var Disabled = !NewTags.filter(NewTag => NewTag !== '').length;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonDisabled={Disabled}
                    BottomButtonOnClick={Disabled ? null : this.onAddTags}
                    BottomButtonText={t('_create')}
                    InformationRenderer={this.renderShowCreateTagModal_Information}
                    OnHide={() => this.onToggleShowCreateTagModal(false)}
                    Show={ShowCreateTagModal}
                    Size="medium"
                />
            );
        }
    }

    renderShowCreateTagModal_Information = () => {
        var { t } = this.props;
        var { NewTags } = this.state;

        return (
            <Styles.CenteredDivContainer className="CenteredDivContainer">
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('_create_tags')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <Styles.CreateTagsHeaderContainer className="CreateTagsHeaderContainer">
                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('_name')} TextAlign="left" />
                    <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-end" NoMargin Text={t('_max_22_char')} TextAlign="right" />
                </Styles.CreateTagsHeaderContainer>

                <Spacer Size="extra-extra-small" />

                <Styles.CreateTagsContainer className="CreateTagsContainer">
                    {
                        NewTags.map((NewTag, NewTagIndex) => {
                            return (
                                <Styles.CreateTagContainer key={NewTagIndex} className="CreateTagContainer">
                                    <Styles.StyledNewTagInput
                                        FontSize="medium-1"
                                        NoLabel
                                        NoMargin
                                        OnBlur={event => this.onBlurTagName({ event, index: NewTagIndex })}
                                        OnChange={event => this.onChangeTagName({ event, index: NewTagIndex })}
                                        Placeholder={t('_fill_in')}
                                        Size="medium"
                                        Type="text"
                                        Value={NewTag}
                                    />
                                </Styles.CreateTagContainer>
                            );
                        })
                    }
                </Styles.CreateTagsContainer>

                <ButtonGroup
                    Buttons={[
                        { BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', IconSVG: PlusSVG, IconPosition: 'left', OnClick: this.onAddTagRow, Title: t('_create_a_new_tag') }
                    ]}
                    ButtonWidth="fit-content"
                    JustifyContent="flex-start"
                    NotTouching
                />
            </Styles.CenteredDivContainer>
        );
    }

    renderShowEditTagModal = () => {
        var { ShowEditTagModal } = this.state;

        if (ShowEditTagModal) {
            var { t } = this.props;
            var { TagName } = this.state.EditTagDetail;

            var Disabled = !TagName.length;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonDisabled={Disabled}
                    BottomButtonOnClick={Disabled ? null : this.onEditTag}
                    BottomButtonText={t('_save')}
                    InformationRenderer={this.renderShowEditTagModal_Information}
                    OnHide={() => this.onToggleShowEditTagModal(false)}
                    Show={ShowEditTagModal}
                />
            );
        }
    }

    renderShowEditTagModal_Information = () => {
        var { t } = this.props;
        var { EditTagDetail: { TagName }, IsDuplicate_EditTag, TryingEditTagName } = this.state;

        return (
            <>
                {TryingEditTagName && <Loading />}

                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('_edit_tag')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <Styles.CreateTagsHeaderContainer className="CreateTagsHeaderContainer">
                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('_name')} TextAlign="left" />
                    <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-end" NoMargin Text={t('_max_22_char')} TextAlign="right" />
                </Styles.CreateTagsHeaderContainer>

                <Spacer Size="extra-extra-small" />

                <Styles.CreateTagsContainer className="CreateTagsContainer">
                    <Styles.CreateTagContainer className="CreateTagContainer">
                        <Styles.StyledNewTagInput
                            FontSize="medium-1"
                            NoLabel
                            NoMargin
                            OnBlur={this.onBlurEditTagName}
                            OnChange={this.onChangeEditTagName}
                            Placeholder={t('_fill_in')}
                            Size="medium"
                            Type="text"
                            Value={TagName}
                        />
                    </Styles.CreateTagContainer>

                    {!!IsDuplicate_EditTag && <PageText FontColor="red-bittersweet" FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('_already_have_tag_with_this_name')} TextAlign="left" />}
                </Styles.CreateTagsContainer>
            </>
        );
    }

    renderTag = (Filter, Tag) => {
        var { SelectedCategories } = this.state;

        var key = Tag.TagId;
        if (Filter.IsAbel && Filter.FilterName === 'Equipment') key = Tag.EquipmentCategory;

        if (Filter.IsAbel && (Filter.FilterName === 'Joints' || Filter.FilterName === 'Muscles')) {
            var SelectedIndex_Avoid = SelectedCategories[`Avoid${Filter.FilterName}`].indexOf(Tag.TagId);
            var SelectedIndex_Target = SelectedCategories[`Target${Filter.FilterName}`].indexOf(Tag.TagId);

            return (
                <Styles.FilterTagContainer className="FilterTagContainer" key={key}>
                    <Checkmark
                        BlockIcon
                        ItemId={Tag.TagId}
                        OnSelectItem={event => this.onSelectTag(event, Filter, Tag.TagId, SelectedIndex_Avoid, 'Avoid', SelectedIndex_Target)}
                        SelectedIndex={SelectedIndex_Avoid}
                        Title={Tag.TagName}
                    />

                    <Styles.CheckmarkItemName
                        ElementType="div"
                        FontFamily="medium"
                        FontSize="small"
                        NoMargin
                        // OnClick={() => this.onSelectTag(null, Tag.TagId, SelectedIndex)}
                        Text={Tag.TagName}
                        Title={Tag.TagName}
                        TwoBoxes
                    />

                    <Checkmark
                        ItemId={Tag.TagId}
                        OnSelectItem={event => this.onSelectTag(event, Filter, Tag.TagId, SelectedIndex_Target, 'Target', SelectedIndex_Avoid)}
                        SelectedIndex={SelectedIndex_Target}
                        Title={Tag.TagName}
                    />
                </Styles.FilterTagContainer>
            );
        }
        else if (!(Filter.IsAbel && Filter.FilterName === 'Equipment')) {
            var SelectedIndex = SelectedCategories[Filter.FilterName].indexOf(Tag.TagId);

            return (
                <Styles.FilterTagContainer className="FilterTagContainer" key={key}>
                    <Checkmark
                        ItemId={Tag.TagId}
                        OnSelectItem={event => this.onSelectTag(event, Filter, Tag.TagId, SelectedIndex)}
                        SelectedIndex={SelectedIndex}
                        Title={Tag.TagName}
                    />

                    {Filter.FilterName === 'MadeBy' && this.renderUserPhoto(Filter, Tag, SelectedIndex)}

                    <Styles.CheckmarkItemName
                        ElementType="div"
                        FontFamily="medium"
                        FontSize="small"
                        NoMargin
                        OnClick={() => this.onSelectTag(null, Filter, Tag.TagId, SelectedIndex)}
                        Text={Tag.TagName}
                        Title={Tag.TagName}
                    />
                </Styles.FilterTagContainer>
            );
        }
        else if (Filter.IsAbel && Filter.FilterName === 'Equipment') {
            if (!Tag.EquipmentList) return null;

            return (
                <Styles.EquipmentFilterContainer className="EquipmentFilterContainer" key={key}>
                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={Tag.EquipmentCategory} TextAlign="left" />

                    <Spacer Size="extra-small" />

                    {
                        Tag.EquipmentList && Tag.EquipmentList.map(Equipment => {
                            var SelectedIndex = SelectedCategories[Filter.FilterName].indexOf(Equipment.TagId);

                            return (
                                <Styles.FilterTagContainer className="FilterTagContainer" key={Equipment.TagId}>
                                    <Checkmark
                                        ItemId={Equipment.TagId}
                                        OnSelectItem={event => this.onSelectTag(event, Filter, Equipment.TagId, SelectedIndex)}
                                        SelectedIndex={SelectedIndex}
                                        Title={Equipment.TagName}
                                    />

                                    <Styles.CheckmarkItemName
                                        ElementType="div"
                                        FontFamily="medium"
                                        FontSize="small"
                                        NoMargin
                                        OnClick={() => this.onSelectTag(null, Filter, Equipment.TagId, SelectedIndex)}
                                        Text={Equipment.TagName}
                                        Title={Equipment.TagName}
                                    />
                                </Styles.FilterTagContainer>
                            );
                        })
                    }
                </Styles.EquipmentFilterContainer>
            );
        }

        return (
            <pre key={key}>
                {JSON.stringify(Tag, null, 2)}
            </pre>
        );
    }

    renderUserPhoto = (Filter, Tag, SelectedIndex) => {
        var { Sex, TagId, TagName, UserId, UserPhoto } = Tag;

        var SexImage = Sex === 'Male' ? Male : Female;

        if (+UserId === 16) UserPhoto = AbelIcon;

        return <Styles.StyledProfilePhoto Alt={TagName} ImageSrc={UserPhoto} IsAbel={UserId === 16} NoImage={SexImage} NoParent OnClick={() => this.onSelectTag(null, Filter, TagId, SelectedIndex)} />;
    }

    renderYourTags = () => {
        var { t } = this.props;
        var { Filters } = this.props;

        var YourTags = Filters.filter(F => (F.IsAbel === 1 && F.FilterName === 'Tags'))[0];

        return (
            <>
                <PageText FontFamily="bold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('_your_tags')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <ButtonGroup
                    Buttons={[
                        { BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', IconSVG: PlusSVG, IconSVGColor: 'white', IconPosition: 'left', OnClick: () => this.onToggleShowCreateTagModal(true), Title: t('_create_tag') },
                        { BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', IconSVG: ReorderSVG, IconPosition: 'left', OnClick: () => this.props.OnToggleShowHowToTagModal(true), Title: t('_how_to_tag_in_library') }
                    ]}
                    ButtonWidth="fit-content"
                    JustifyContent="flex-start"
                    NotTouching
                />

                {
                    YourTags.Tags.length > 0 &&
                    <>
                        <Spacer Size="extra-small" />

                        <Styles.YourTagsContainer className="YourTagsContainer">
                            {
                                YourTags.Tags.map(({ TagId, TagName }, TagIndex) => {
                                    return (
                                        <Styles.YourTagContainer className="YourTagContainer" key={TagId}>
                                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={TagName} TextAlign="left" />

                                            <Styles.YourTagOptionsContainer className="YourTagOptionsContainer">
                                                <Styles.YourTagOptionsSVGContainer className="YourTagOptionsSVGContainer" IsEdit onClick={() => this.onToggleShowEditTagModal(true, TagId, TagName)}>
                                                    <EditSVG />
                                                </Styles.YourTagOptionsSVGContainer>
                                                <Styles.YourTagOptionsSVGContainer className="YourTagOptionsSVGContainer" IsTrash onClick={() => this.onToggleShowDeleteTagModal(true, TagId, TagIndex)}>
                                                    <TrashSVG />
                                                </Styles.YourTagOptionsSVGContainer>
                                            </Styles.YourTagOptionsContainer>
                                        </Styles.YourTagContainer>
                                    );
                                })
                            }
                        </Styles.YourTagsContainer>
                    </>
                }
            </>
        );
    }

    render() {
        var { t } = this.props;

        return (
            <>
                <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={t('_customize')} TextAlign="left" />

                <Spacer Size="medium" />

                {this.renderFilters()}

                <Spacer Size="extra-large" />

                {this.renderYourTags()}

                <Spacer Size="extra-large" />

                {this.renderHighlightedTags()}

                {this.renderDeleteTagModal()}
                {this.renderShowCreateTagModal()}
                {this.renderShowEditTagModal()}
            </>
        );
    }
}

export default withTranslation()(CustomizeFilters);