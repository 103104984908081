import React from 'react';
import PropTypes from 'prop-types';

import { Container, ImageContainer, LoadingImage, Overlay } from './styles';

import NoImage from '../../Assets/Icons/NoImage.png';

class BackgroundImage extends React.Component {
    state = { LoadedImage: false, Visible: false };

    // componentDidMount() {
    //     const imageLoader = new Image();
    //     imageLoader.src = this.props.ImageSrc;

    //     imageLoader.onload = () => {
    //         console.log('image loaded');
    //         this.setState({ LoadedImage: true });
    //     };
    // }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     console.log('componentDidUpdate');
    //     console.log('prevProps: ', prevProps);
    //     console.log('prevState: ', prevState);
    //     console.log('snapshot: ', snapshot);
    //     console.log('--------------------');
    // }

    // shouldComponentUpdate(nextProps, nextState) {
    //     console.log('nextProps: ', nextProps);
    //     console.log('nextState: ', nextState);
    //     console.log('current state: ', this.state);
    //     console.log('--------------------');
        // if (this.props.ImageSrc === 'http://52.173.83.63//dev_v2/DishImages/34.jpg') {
        //     console.log('LoadedImage: ', this.state.LoadedImage);
        //     console.log('nextState: ', nextState);
        //     console.log('---------------');
        // }

        // return false;

        // if (!this.state.LoadedImage && nextState.LoadedImage) return true;
    //     return true;
    // }

    onLoaded = () => {
        this.setState({ LoadedImage: true });
    }

    onToggleOverlay = Visible => {
        this.setState({ Visible });
    }

    render() {
        var { className, ImageSrc, NoImage: NoImageUrl, OnClick, ShowOverlay } = this.props;
        var { LoadedImage, Visible } = this.state;

        return (
            <>
                <ImageContainer
                    onClick={OnClick}
                    onMouseEnter={() => this.onToggleOverlay(true)}
                    onMouseLeave={() => this.onToggleOverlay(false)}
                >
                    <Container
                        className={className}
                        ImageSrc={LoadedImage ? ImageSrc : NoImage}
                        NoImage={NoImageUrl || NoImage}
                    />
                    {ShowOverlay && Visible ? <Overlay /> : null}
                </ImageContainer>

                {LoadedImage || !ImageSrc ? null : <LoadingImage alt="preloading image" onLoad={this.onLoaded} src={ImageSrc} />}
            </>
        );
    }
}

BackgroundImage.propTypes = {
    ImageSrc: PropTypes.string.isRequired,
    NoImage: PropTypes.string,
    OnClick: PropTypes.func,
    ShowOverlay: PropTypes.bool
}

export default BackgroundImage;