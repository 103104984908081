import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { device } from '../../../Config/device';

import ButtonGroup from '../../Buttons/ButtonGroup';
import Dropdown from '../../Dropdown';
import Icon from '../../Icon';
import Input from '../../Form/Input';
import MultiText from '../../Text/MultiText';
import PageText from '../../Text/PageText';
import Spacer from '../../Spacer';
import TextArea from '../../Form/TextArea';

import DeleteX from '../../../Assets/Icons/Delete_X.png';
import DeleteXGray from '../../../Assets/Icons/Delete_X_Gray.png';
import EditDark from '../../../Assets/Icons/Edit_Dark.png';
import EditLight from '../../../Assets/Icons/Edit_Light.png';

const CurrencyItem = styled.div`
    ${
        props => props.Selected ?
        `
            color: var(--blue-abel);
        `
    :
        null
    }

    :not(:last-child) {
        margin-bottom: 5px;
    }

    @media(hover:hover) {
        :hover {
            color: var(--blue-abel);
            cursor: pointer;
        }
    }
`;

const CurrencyList = styled.div``;

const DetailContainer = styled.div`
    width: 100%;
`;

const OptionsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${props => props.MarginAuto && 'margin-left: auto; margin-right: auto;'}
    margin-top: 15px;

    > div:first-child {
        margin-right: 15px;
    }

    @media ${device.minwidth.laptop} {
        margin-left: 10px;
        margin-top: 0px;
    }
`;

const ProductContainer = styled.div`
    align-items: ${props => props.Editing ? 'center' : 'flex-start'};
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px 21px;

    :not(:last-child) {
        margin-bottom: 15px;
    }

    @media ${device.minwidth.laptop} {
        flex-direction: row;
    }
`;

const ProductDescriptionContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const ProductNameContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 10px;
    width: 100%;

    @media ${device.minwidth.mobile} {
        align-items: center;
        flex-direction: row;
    }
`;

const ProductNamePriceContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${device.minwidth.laptop} {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }
`;

const ProductPriceContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const StyledButtonGroup = styled(ButtonGroup)`
    > div {
        white-space: nowrap;
    }
`;

const StyledCurrencyDropdown = styled(Dropdown)`
    margin: 0 auto;
    max-width: 150px;
    padding: 5px 15px;
    width: 100%;

    ${props => props.ForceClose === false ? 'z-index: 1001;' : null};

    ${
        props => props.IsMobile ?
        `
            > div { max-height: none; }
        `
    :
        `
            ${props.Height ? `height: ${props.Height + 45 + 15}px;` : ''}
            margin: 0 auto;
            width: 150px
        `
    }
`;

const StyledDescriptionTextArea = styled(TextArea)`
    margin-bottom: 0px;
    margin-top: 5px;
    width: 100%;

    > textarea {
        font-family: var(--font-family-semibold);
    }
`;

const StyledIcon = styled(Icon)`
    height: 20px;
    width: 20px;
`;

const StyledNameInput = styled(Input)`
    max-width: 500px;
    width: 100%;

    > input {
        border-radius: 10px;
        text-indent: 0px;
    }

    @media ${device.minwidth.mobile} {
        margin-left: 5px;
    }
`;

const StyledPriceInput = styled(Input)`
    margin-left: 5px;
    margin-right: 5px;
    max-width: 100px;
    width: 100%;

    > input {
        border-radius: 10px;
        text-indent: 0px;
    }
`;

class ProductListItem extends React.Component {
    state = {
        CurrencyId: null,
        CurrencyName: '',
        DisplayingCurrency: null,
        DropdownSize: 0,
        Editing: false,
        OriginalProduct: {},
        Price: 0,
        ProductId: 0,
        ProductName: '',
        ProductDescription: ''
    };

    componentDidMount() {
        var { Item } = this.props;
        var { CurrencyId, CurrencyName, Editing, Price, ProductId, ProductName, ProductDescription } = Item;

        this.setState({ CurrencyId, CurrencyName, Editing: Editing || false, OriginalProduct: { ...Item }, Price, ProductId, ProductName, ProductDescription });
    }

    onChangeProductDescription = event => {
        this.setState({ ProductDescription: event.target.value });
    }

    onChangeProductName = event => {
        this.setState({ ProductName: event.target.value });
    }

    onChangeProductPrice = event => {
        this.setState({ Price: event.target.value });
    }

    onDeleteProduct = () => {
        this.props.OnDeleteProduct({ ProductId: this.state.ProductId, ProductIndex: this.props.ItemIndex });
    }

    onGetSize = Height => {
        this.setState({ DropdownSize: Height });
    }

    onSaveProduct = () => {
        this.onToggleEditing();

        var { CurrencyId, CurrencyName, Price, ProductDescription, ProductId, ProductName } = this.state;

        this.props.OnInsertEditProduct({ CurrencyId, CurrencyName, Price, ProductDescription, ProductId, ProductIndex: this.props.ItemIndex, ProductName });
    }

    onSelectCurrency = (CurrencyId, CurrencyName) => {
        this.setState({ CurrencyId, CurrencyName, DisplayingCurrency: null });
    }

    onToggleDropdown = (DisplayingCurrency, ToggleStatus) => {
        this.setState({ DisplayingCurrency: ToggleStatus ? DisplayingCurrency : null });
    }

    onToggleEditing = () => {
        this.setState({ Editing: !this.state.Editing });
    }

    renderCurrencies = () => {
        return (
            <CurrencyList>
                {
                    this.props.Currencies.map(({ CurrencyId, CurrencyName }) => {
                        var Selected = CurrencyId === this.state.CurrencyId;

                        return (
                            <CurrencyItem key={CurrencyId} onClick={() => this.onSelectCurrency(CurrencyId, CurrencyName)} Selected={Selected}>
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={CurrencyName} TextAlign="left" />
                            </CurrencyItem>
                        );
                    })
                }
            </CurrencyList>
        );
    }

    renderOptions = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { Editing, CurrencyId, Price, ProductId, ProductDescription, ProductName } = this.state;

        var Disabled = false;
        if (Editing && (!CurrencyId || !Price || !ProductDescription || !ProductName)) Disabled = true;

        var Buttons = [];
        Buttons.push({ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', FontSize: 'medium-1', OnClick: this.onSaveProduct, Title: Editing ? !!+ProductId ? t('save_with_first_char_uppercase') : t('ptadmin_clientnote_options_add') : t('edit_with_first_char_uppercase') });

        if (Device !== 'laptop') {
            Buttons.push({ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', FontSize: 'medium-1', OnClick: Editing ? !!+ProductId ? this.onToggleEditing : this.props.OnCancelNewProduct : this.onDeleteProduct, Title: (Editing || !!!+ProductId) ? t('Cancel_with_first_char_uppercase') : t('delete_with_first_char_uppercase') });

            return (
                <StyledButtonGroup Buttons={Buttons} ButtonWidth="fit-content" ContainerWidth="fit-content" NotTouching />
            );
        }
        
        return (
            <>
                {
                    Editing ?
                    <StyledButtonGroup Buttons={Buttons} ButtonWidth="fit-content" ContainerWidth="fit-content" />
                :
                    <StyledIcon ImageSrc={EditLight} ImageSrcHover={EditDark} OnClick={this.onToggleEditing} />
                }

                <StyledIcon ImageSrc={DeleteXGray} ImageSrcHover={DeleteX} OnClick={Editing ? !!+ProductId ? this.onToggleEditing : this.props.OnCancelNewProduct : this.onDeleteProduct} />
            </>
        );
    }

    renderProductDescription = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { Editing, ProductDescription } = this.state;

        if (Device !== 'laptop' && !Editing) {
            return (
                <MultiText
                    Texts={[
                        { FontFamily: "medium", FontSize: "medium-1", Text: `${t('product_description')}:` },
                        { FontFamily: "medium-italic", FontSize: "medium-1", Text: ProductDescription },
                    ]}
                />
            );
        }

        return (
            <ProductDescriptionContainer>
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin NoWrap Text={`${t('product_description')}:`} TextAlign="left" />
                
                {
                    Editing ?
                    <StyledDescriptionTextArea
                        OnBlur={this.onChangeProductDescription}
                        NumRows={4}
                        Placeholder={t('product_description')}
                        Value={ProductDescription}
                    />
                :
                    <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={ProductDescription} TextAlign="left" />
                }
            </ProductDescriptionContainer>
        );
    }

    renderProductName = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { Editing, ProductName } = this.state;

        if (Device !== 'laptop' && !Editing) {
            return (
                <MultiText
                    Texts={[
                        { FontFamily: "medium", FontSize: "medium-1", Text: `${t('product_name')}:` },
                        { FontFamily: "semibold", FontSize: "medium-1", Text: ProductName },
                    ]}
                />
            );
        }

        return (
            <ProductNameContainer>
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin NoWrap Text={`${t('product_name')}:`} TextAlign="left" />
                
                {
                    Editing ?
                    <StyledNameInput FontFamily="semibold" FontSize="medium-1" NoLabel NoMargin OnChange={this.onChangeProductName} Placeholder={t('product_name')} Size="small-1" Type="text" Value={ProductName} />
                :
                    <>
                        &nbsp;
                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={ProductName} TextAlign="left" />
                    </>
                }
            </ProductNameContainer>
        );
    }

    renderProductPrice = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { CurrencyName, DisplayingCurrency, DropdownSize, Editing, Price } = this.state;

        return (
            <ProductPriceContainer>
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin NoWrap Text={`${t('product_price')}:`} TextAlign="left" />
                
                {
                    Editing ?
                    <>
                        <StyledPriceInput FontFamily="semibold" FontSize="medium-1" NoLabel NoMargin OnChange={this.onChangeProductPrice} Placeholder={t('product_price')} Size="small-1" Type="number" Value={Price} />
                        <StyledCurrencyDropdown
                            ContentRenderer={this.renderCurrencies}
                            DefaultHeight="35px"
                            Disabled={false}
                            ForceClose={DisplayingCurrency !== 'Currency'}
                            GetSize={Height => this.onGetSize(Height)}
                            Height={DisplayingCurrency === 'Currencies' && DropdownSize}
                            IsList={false}
                            IsMobile={Device === 'mobile_small' || Device === 'mobile'}
                            ItemId="Currencies"
                            NeedsToLoad={false}
                            OnToggleDropdown={ToggleStatus => this.onToggleDropdown('Currencies', ToggleStatus)}
                            SelectedItemIds={[]}
                            Title={CurrencyName}
                            TitleFontColor={'blue-astronaut'}
                            TitleFontFamily="semibold"
                        />
                    </>
                :
                    <>
                        &nbsp;
                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${Price} ${CurrencyName}`} TextAlign="left" />
                    </>
                }
            </ProductPriceContainer>
        );
    }

    render() {
        var { Device } = this.props;
        var { Editing } = this.state;

        return (
            <ProductContainer Editing={Editing}>
                <DetailContainer>
                    <ProductNamePriceContainer>
                        {this.renderProductName()}

                        {Device !== 'laptop' && <Spacer Size="extra-small" />}
                        
                        {this.renderProductPrice()}
                    </ProductNamePriceContainer>

                    <Spacer Size="extra-small" />

                    {this.renderProductDescription()}
                </DetailContainer>
                <OptionsContainer MarginAuto={Device !== 'laptop' && !Editing}>
                    {this.renderOptions()}
                </OptionsContainer>
            </ProductContainer>
        );
    }
}

ProductListItem.propTypes = {
    Item: PropTypes.object,
    OnCancelNewProduct: PropTypes.func,
    OnDeleteProduct: PropTypes.func,
    OnInsertEditProduct: PropTypes.func
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        Currencies: state.Sales.Currencies
    };
};

export default withTranslation()(connect(mapStateToProps, null)(ProductListItem));