import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DeleteExpense, SearchExpenses, ViewExpenseCategories } from '../../Services/Actions';

import moment from 'moment';
import Select from 'react-select';

import { ExpensesContainer, FiltersContainer, FiltersContainer2, PageNumberContainer, PageNumbersContainer, PaginationContainer, StatsContainer, StatsInnerContainer, StyledSearchInput, TopFiltersContainer, ViewCount, YearlyChoicesContainer } from './AccountingStyles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import { ExpenseListItem } from '../../Components/Lists/ListItems';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Info from '../../Modals/Info';

import Plus from '../../Assets/Icons/Plus.png';

import { PageRange } from '../../Functions';

const DropdownStyles = {
    control: (styles, { isDisabled }) => ({ ...styles, backgroundColor: 'var(--white-concrete)', border: 'none', cursor: isDisabled ? 'not-allowed' : 'default', height: '45px', maxWidth: '100%', width: '200px' }),
    input: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    menu: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    option: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '200px' })
};

const SortingOptions = [
    { SortId: 1, SortName: 'sort_oldest_newest' },
    { SortId: 2, SortName: 'sort_newest_oldest' },
    { SortId: 3, SortName: 'sort_amount_lowest_highest' },
    { SortId: 4, SortName: 'sort_amount_highest_lowest' }
]

class Accounting extends React.Component {
    state = {
        DeleteExpense: {},
        Filters: {
            Description: '',
            ExpenseCategoryId: null,
            ExpenseCategoryName: '',
            LastSearchedDescription: '',
            SortId: 2, // 1 = ExpenseDate ASC, 2 = ExpenseDate DESC, 3 = Amount ASC, 4 = Amount DESC
            SortName: 'sort_newest_oldest',
            Year: moment().format('YYYY')
        },
        NeedStats: true,
        NewExpense: {},
        Loading: true,
        PageNo: 1,
        PageSize: 2,
        ShowAddExpense: false,
        ShowDeleteExpenseModal: false
    }

    componentDidMount() {
        this.onLoadExpenses();
    }

    onChangeExpenseCategory = ({ ExpenseCategoryId, ExpenseCategoryName }) => {
        if (ExpenseCategoryId !== this.state.Filters.ExpenseCategoryId) this.setState({ Filters: { ...this.state.Filters, ExpenseCategoryId, ExpenseCategoryName }, PageNo: 1 }, () => this.onLoadExpenses());
    }

    onChangeSearch = event => {
        this.setState({ Filters: { ...this.state.Filters, Description: event.target.value } });
    }

    onChangeSorting = ({ SortId, SortName }) => {
        if (SortId !== this.state.Filters.SortId) this.setState({ Filters: { ...this.state.Filters, SortId, SortName }, PageNo: 1 }, () => this.onLoadExpenses());
    }

    onDeleteExpense = () => {
        var { ExpenseId } = this.state.DeleteExpense;

        this.props.DeleteExpense({ ExpenseId }).then(() => {
            if (!this.props.TryingDeleteExpenseError) this.setState({ DeleteExpense: {}, NeedStats: true, ShowDeleteExpenseModal: false }, () => {
                this.onLoadExpenses();
            })
        });
    }

    onFilterYear = ({ CalculatedYear }) => {
        if (CalculatedYear !== this.state.Filters.Year) {
            this.setState({ Filters: { ...this.state.Filters, Year: CalculatedYear }, NeedStats: true, PageNo: 1 }, () => this.onLoadExpenses());
        }
    }

    onFocusExpenseCategories = () => {
        var { ExpenseCategories, TryingViewExpenseCategories } = this.props;
        if (!ExpenseCategories.length && !TryingViewExpenseCategories) this.props.ViewExpenseCategories();
    }

    onKeyPress = ({ key }) => {
        if (key === 'Enter' && !this.props.TryingSearchExpenses) {
            var { Description, LastSearchedDescription } = this.state.Filters;

            if (Description !== LastSearchedDescription) this.setState({ Filters: { ...this.state.Filters, LastSearchedDescription: Description }, PageNo: 1 }, () => this.onLoadExpenses());
        }
    }

    onLoadExpenses = () => {
        var { Filters: { Description, ExpenseCategoryId, SortId, Year }, NeedStats, PageNo, PageSize } = this.state;

        var OrderBy = (SortId === 1 || SortId === 2) ? 'ExpenseDate' : 'Amount';
        var OrderType = (SortId === 1 || SortId === 3) ? 'ASC' : 'DESC';

        this.setState({ NeedStats: false, PageNo }, () => this.props.SearchExpenses({ Description, ExpenseCategoryId, NeedStats, OrderBy, OrderType, PageNo, PageSize, Year }).then(() => {
            this.setState({ Loading: false });
        }));
    }

    onPaginate = ({ NewPageNo }) => {
        if (this.state.PageNo !== NewPageNo) this.setState({ PageNo: NewPageNo }, () => this.onLoadExpenses());
    }

    onSaveNewExpenseToParent = ({ NewExpense }) => {
        this.setState({ NewExpense });
    }

    onToggleShowAddExpense = ({ AddedNewExpense = false, ShowAddExpense }) => {
        this.setState({ NewExpense: {}, ShowAddExpense }, () => {
            if (AddedNewExpense && this.state.PageNo === 1) this.setState({ NeedStats: true }, () => this.onLoadExpenses());
        });
    }

    onToggleShowDeleteExpenseModal = ({ ExpenseId, ShowDeleteExpenseModal }) => {
        if (ShowDeleteExpenseModal) this.setState({ DeleteExpense: { ExpenseId }, ShowDeleteExpenseModal });
        else this.setState({ DeleteExpense: {}, ShowDeleteExpenseModal });
    }

    renderDeleteExpensesModal = () => {
        var { t } = this.props;
        var { TryingDeleteExpense } = this.props;
        var { ShowDeleteExpenseModal } = this.state;

        if (ShowDeleteExpenseModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteExpense}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    Information={t('delete_with_warning', { Items: t('expenses').toLowerCase()})}
                    Loading={TryingDeleteExpense}
                    OnHide={() => this.onToggleShowDeleteExpenseModal({ ShowDeleteExpenseModal: false })}
                    Show={ShowDeleteExpenseModal}
                />
            );
        }
    }

    renderExpenses = () => {
        var { t } = this.props;
        var { Expenses, TotalRecords } = this.props.Expenses;
        var { Filters: { Year }, NewExpense, ShowAddExpense } = this.state;

        var ExpensesToMap = [ ...Expenses ];

        if (ShowAddExpense) ExpensesToMap = [ { CurrencyId: null, CurrencyName: '', Editing: true, ExpenseId: Math.ceil(Math.random() * 1000000000), ...NewExpense, IsNewExpense: true }, ...ExpensesToMap ];

        return (
            <ExpensesContainer className="ExpensesContainer">
                {
                    (ShowAddExpense || TotalRecords > 0) ?
                    ExpensesToMap.map((Expense, ExpenseIndex) => {
                        return (
                            <ExpenseListItem
                                key={Expense.ExpenseId}
                                Expense={Expense}
                                ExpenseIndex={ExpenseIndex}
                                OnCancelNewExpense={Added => this.onToggleShowAddExpense({ AddedNewExpense: Added, ShowAddExpense: false })}
                                OnDeleteExpense={() => this.onToggleShowDeleteExpenseModal({ ExpenseId: Expense.ExpenseId, ShowDeleteExpenseModal: true })}
                                OnSaveExpenseToParent={NewExpense => this.onSaveNewExpenseToParent({ NewExpense })}
                                Year={+Year}
                            />
                        )
                    })
                :
                    <PageText FontFamily="medium-italic" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('no_expenses')} TextAlign="center" />
                }
            </ExpensesContainer>
        );
    }

    renderPagination = () => {
        var { t } = this.props;
        var { Device, ExpenseCategories, Expenses, TryingViewExpenseCategories } = this.props;
        var { Filters: { Description, ExpenseCategoryId, ExpenseCategoryName, SortId, SortName }, PageNo } = this.state;

        var { TotalPages, TotalRecords } = Expenses;

        var { EndPageNo, StartPageNo } = PageRange({ PageNo, TotalPages });

        return (
            <FiltersContainer className="FiltersContainer">
                <TopFiltersContainer className="TopFiltersContainer">
                    <FiltersContainer2 className="FiltersContainer2">
                        <StyledSearchInput
                            FontSize="medium-2"
                            NoLabel
                            NoMargin
                            OnChange={this.onChangeSearch}
                            OnKeyPress={this.onKeyPress}
                            Placeholder={t('search_with_first_char_uppercase_dots')}
                            Size="medium"
                            Type="text"
                            Value={Description}
                        />

                        <Select
                            getOptionLabel={option => option.ExpenseCategoryName}
                            getOptionValue={option => option.ExpenseCategoryId}
                            isLoading={TryingViewExpenseCategories}
                            onChange={this.onChangeExpenseCategory}
                            onFocus={this.onFocusExpenseCategories}
                            options={ExpenseCategories}
                            placeholder={t('category')}
                            styles={DropdownStyles}
                            value={ExpenseCategoryId && { ExpenseCategoryId, ExpenseCategoryName }}
                        />

                        <Select
                            getOptionLabel={option => t(`${option.SortName}`)}
                            getOptionValue={option => option.SortId}
                            onChange={this.onChangeSorting}
                            options={SortingOptions}
                            placeholder={t('search_with_first_char_uppercase_dots')}
                            styles={DropdownStyles}
                            value={SortId && { SortId, SortName }}
                        />
                    </FiltersContainer2>

                    {Device !== 'laptop' && <Spacer Size="small" />}

                    <PaginationContainer className="PaginationContainer">
                        {
                                TotalPages !== 1 &&
                                <>
                                    {PageNo !== 1 && <PageText FontColorHover={'blue-abel'} FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onPaginate({ NewPageNo: PageNo - 1 })} Text={t('prev')} TextAlign="left" />}

                                    <PageNumbersContainer className="PageNumbersContainer">
                                        {
                                            Array((EndPageNo - StartPageNo) + 1).fill().map((item, index) => {
                                                var Selected = PageNo === StartPageNo + index;

                                                return (
                                                    <PageNumberContainer className="PageNumberContainer" key={index} onClick={() => this.onPaginate({ NewPageNo: StartPageNo + index })} Selected={Selected}>
                                                        {`${StartPageNo + index}`}
                                                    </PageNumberContainer>
                                                );
                                            })
                                        }
                                    </PageNumbersContainer>

                                    {!!TotalPages && PageNo !== TotalPages && <PageText FontColorHover={'blue-abel'} FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-end" NoMargin OnClick={() => this.onPaginate({ NewPageNo: PageNo + 1 })} Text={t('next_with_first_char_uppercase')} TextAlign="right" />}
                                </>
                            }
                    </PaginationContainer>
                </TopFiltersContainer>

                {
                    !!TotalRecords &&
                    <>
                        <Spacer Size="medium" />

                        {this.renderViewCount()}
                    </>
                }
            </FiltersContainer>
        );
    }

    renderStats = () => {
        var { t } = this.props;
        var { Device, Expenses: { Stats: { Yearly_ExpensesText, Yearly_SoldText, Yearly_TotalAmountText } } } = this.props;
        var { Year } = this.state.Filters;

        var JustifyContent = (Device === 'ipad' || Device === 'laptop') ? 'flex-start' : 'center';
        var TextAlign = (Device === 'ipad' || Device === 'laptop') ? 'left' : 'center';

        return (
            <StatsContainer className="StatsContainer">
                <StatsInnerContainer className="StatsInnerContainer">
                    <PageText FontFamily="medium" FontSize="small" JustifyContent={JustifyContent} NoMargin Text={`${t('income')} ${t('in')} ${Year}`} TextAlign={TextAlign} />
                    
                    <PageText FontFamily="semibold" FontSize="large" JustifyContent={JustifyContent} NoMargin Text={`${Yearly_SoldText}`} TextAlign={TextAlign} />
                </StatsInnerContainer>
                <StatsInnerContainer className="StatsInnerContainer">
                    <PageText FontFamily="medium" FontSize="small" JustifyContent={JustifyContent} NoMargin Text={`${t('expenses')} ${t('in')} ${Year}`} TextAlign={TextAlign} />
                    
                    <PageText FontFamily="semibold" FontSize="large" JustifyContent={JustifyContent} NoMargin Text={`${Yearly_ExpensesText}`} TextAlign={TextAlign} />
                </StatsInnerContainer>
                <StatsInnerContainer className="StatsInnerContainer">
                    <PageText FontFamily="medium" FontSize="small" JustifyContent={JustifyContent} NoMargin Text={`${t('profit')} ${t('in')} ${Year}`} TextAlign={TextAlign} />
                    
                    <PageText FontFamily="semibold" FontSize="large" JustifyContent={JustifyContent} NoMargin Text={`${Yearly_TotalAmountText}`} TextAlign={TextAlign} />
                </StatsInnerContainer>
            </StatsContainer>
        );
    }

    renderViewCount = () => {
        var { t } = this.props;
        var { Expenses: { TotalRecords } } = this.props;
        var { PageNo, PageSize } = this.state;

        return (
            <ViewCount className="ViewCount">
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${!TotalRecords ? 0 : ((PageNo - 1) * PageSize) + 1} - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                &nbsp;
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords} ${t('expenses').toLowerCase()}`} />
            </ViewCount>
        )
    }

    renderYearlyChoices = () => {
        var { t } = this.props;
        var { FirstYear, LastYear } = this.props.Expenses.Stats;
        var { Year } = this.state.Filters;

        var Buttons = [];
        Array((LastYear - FirstYear) + 1).fill().map((item, index) => {
            var CalculatedYear = LastYear - index;
            var Selected = +Year === CalculatedYear;
            Buttons.push({ BackgroundColor: Selected ? 'blue-astronaut' : 'white-concrete', BackgroundColorHover: 'blue-astronaut', Color: Selected ? 'white' : 'black', ColorHover: 'white', OnClick: () => this.onFilterYear({ CalculatedYear }), Selected, Title: `${CalculatedYear}` });

            return null;
        });

        return (
            <YearlyChoicesContainer className="YearlyChoicesContainer">
                <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={t('choose_year')} TextAlign="center" />

                <Spacer Size="extra-extra-small" />

                <ButtonGroup Buttons={Buttons} ButtonWidth="fit-content" ContainerWidth="fit-content" NotTouching />
            </YearlyChoicesContainer>
        );
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { t } = this.props;
        var { Device, Expenses: { Stats: { FirstYear } }, TryingDeleteExpense, TryingInsertEditExpense, TryingSearchExpenses } = this.props;

        return (
            <>
                <div>
                    {(TryingDeleteExpense, TryingInsertEditExpense || TryingSearchExpenses) && <Loading />}

                    <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('accounting')} TextAlign="center" />

                    <Spacer Size="small" />

                    <PageText ContainerWidth="400px" FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text={t('accounting_info')} TextAlign="center" />

                    {
                        !!FirstYear &&
                        <>
                            <Spacer Size="medium" />

                            {this.renderYearlyChoices()}

                            <Spacer Size="medium" />

                            {this.renderStats()}

                            <Spacer Size="large" />

                            <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('expenses')} TextAlign="center" />
                        </>
                    }

                    <Spacer Size="small" />

                    <ButtonGroup
                        Buttons={[{ Icon: Plus, IconPosition: 'left', FontFamily: 'semibold', FontSize: 'medium-1', OnClick: () => this.onToggleShowAddExpense({ ShowAddExpense: true }), Size: 'small', Title: t('expense_add') }]}
                        ButtonWidth="fit-content"
                        ContainerWidth={Device === 'mobile' || Device === 'mobile_small' ? '100%' : null}
                    />

                    {
                        !!FirstYear &&
                        <>
                            <Spacer Size="medium" />

                            {this.renderPagination()}
                        </>
                    }

                    <Spacer Size="medium" />

                    {this.renderExpenses()}
                </div>

                {this.renderDeleteExpensesModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        Expenses: state.Sales.Expenses,
        ExpenseCategories: state.Sales.ExpenseCategories,

        TryingDeleteExpense: state.Sales.TryingDeleteExpense,
        TryingDeleteExpenseError: state.Sales.TryingDeleteExpenseError,
        TryingInsertEditExpense: state.Sales.TryingInsertEditExpense,
        TryingInsertEditExpenseError: state.Sales.TryingInsertEditExpenseError,
        TryingSearchExpenses: state.Sales.TryingSearchExpenses,
        TryingSearchExpensesError: state.Sales.TryingSearchExpensesError,
        TryingViewExpenseCategories: state.Sales.TryingViewExpenseCategories,
        TryingViewExpenseCategoriesError: state.Sales.TryingViewExpenseCategoriesError
    };
};

export default withTranslation()(connect(mapStateToProps, { DeleteExpense, SearchExpenses, ViewExpenseCategories } )(Accounting));