import {
    CLEAR_NUTRITION,
    TRYING_GET_NUTRITION,
    TRYING_GET_NUTRITION_SUCCESS,
    TRYING_GET_NUTRITION_ERROR,

    TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS,
    TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS_SUCCESS,
    TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS_ERROR,

    CLEAR_NUTRITION_PREVIEW_FILTERS,
    TRYING_VIEW_NUTRITION_PREVIEW_FILTERS,
    TRYING_VIEW_NUTRITION_PREVIEW_FILTERS_SUCCESS,
    TRYING_VIEW_NUTRITION_PREVIEW_FILTERS_ERROR
} from '../Types';

const INITIAL_STATE = {
    NutritionData: {},
    NutritionPreviewFilters: [],
    
    TryingGetNutrition: false,
    TryingGetNutritionError: null,
    TryingUpdateNutritionPreviewFilters: false,
    TryingUpdateNutritionPreviewFiltersError: null,
    TryingViewNutritionPreviewFilters: false,
    TryingViewNutritionPreviewFiltersError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_NUTRITION:
            return { ...state, NutritionData: {} };
        case TRYING_GET_NUTRITION:
            return { ...state, TryingGetNutrition: true, TryingGetNutritionError: null };
        case TRYING_GET_NUTRITION_ERROR:
            return { ...state, TryingGetNutrition: false, TryingGetNutritionError: action.payload };
        case TRYING_GET_NUTRITION_SUCCESS:
            return { ...state, NutritionData: action.payload, TryingGetNutrition: false, TryingGetNutritionError: null };

        case TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS:
            return { ...state, TryingUpdateNutritionPreviewFilters: true, TryingUpdateNutritionPreviewFiltersError: null };
        case TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS_ERROR:
            return { ...state, TryingUpdateNutritionPreviewFilters: false, TryingUpdateNutritionPreviewFiltersError: action.payload };
        case TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS_SUCCESS:
            return { ...state, TryingUpdateNutritionPreviewFilters: false, TryingUpdateNutritionPreviewFiltersError: null };

        case CLEAR_NUTRITION_PREVIEW_FILTERS:
            return { ...state, NutritionPreviewFilters: [] };
        case TRYING_VIEW_NUTRITION_PREVIEW_FILTERS:
            return { ...state, TryingViewNutritionPreviewFilters: true, TryingViewNutritionPreviewFiltersError: null };
        case TRYING_VIEW_NUTRITION_PREVIEW_FILTERS_ERROR:
            return { ...state, TryingViewNutritionPreviewFilters: false, TryingViewNutritionPreviewFiltersError: action.payload };
        case TRYING_VIEW_NUTRITION_PREVIEW_FILTERS_SUCCESS:
            var { NutritionPreviewFilters } = action.payload;

            return { ...state, NutritionPreviewFilters, TryingViewNutritionPreviewFilters: false, TryingViewNutritionPreviewFiltersError: null };

        default:
            return state;
    }
};
