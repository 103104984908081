import styled from 'styled-components';
import { device } from '../../../../Config/device';

import Icon from '../../../../Components/Icon';
import Input from '../../../../Components/Form/Input';
import PageText from '../../../../Components/Text/PageText';
import TextArea from '../../../../Components/Form/TextArea';

export const AssignTaskContainer = styled.div``;

export const BodyContainer = styled.div`
    grid-area: body;
    padding: 15px 30px;
`;

export const BodyHeader = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media ${device.maxwidth.ipad} {
        align-items: flex-start;
        flex-direction: column;
    }
`;

export const BodyHeaderRight = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media ${device.minwidth.laptop} {
        margin-left: 10px;
    }
`;

export const DescriptionTextArea = styled(TextArea)`
    margin: 0 auto;
    max-width: 675px;

    > textarea {
        background-color: var(--white-concrete);
        border: none;
    }
`;

export const DHCDetailModalInfoContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const DHCDetailModalInfoFlexContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const FilterContainer = styled.div`
    flex: 1;
`;

export const FilterHeaderContainer = styled.div`
    display: flex;
    position: relative;
    width: 100%;
`;

export const FiltersContainer = styled.div`
    border-left: 2px solid var(--white-concrete);
    grid-area: filter;
    padding: 15px 30px;
`;

export const FiltersSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    justify-content: space-between;
`;

export const IconContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    grid-gap: 5px;
    flex-direction: row;
    justify-content: center;
    padding: 10px;

    img {
        max-height: 50px !important;
        max-width: 50px !important;
    }

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete-hover);
            border: 2px solid var(--white-concrete-hover);
            cursor: pointer;
        }
    }

    ${
        props => !!props.Selected &&
        `
            border: 2px solid var(--abel-blue);

            @media(hover:hover) {
                :hover {
                    border: 2px solid var(--abel-blue);
                }
            }
        `
    }
`;

export const IconsListContainer = styled.div`
    display: grid;
    grid-gap: 10px 5px;
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
`;

export const InsertEditModalContainer = styled.div``;

export const MaxPercentInfoIcon = styled(Icon)`
    height: 20px;
    width: 20px;

    @media (min-width: 901px) {
        position: absolute;
        right: 10px;
        top: 0px;
    }
`;

export const PopoverContent = styled.div`
    align-items: center;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 200px;
    padding: 10px;
`;

export const ProfilePhotoContainer = styled.div`
    margin-right: 5px;
    width: var(--profilephoto-size-small-3);
`;

export const ProfilePhotoContainer_Popup = styled.div`
    margin-right: 5px;
    width: var(--profilephoto-size-extra-large);
`;

export const PseudoCheckbox = styled.div`
    ${
        props => props.Selected && `background-color: #${props.BackgroundColor};`
    }

    border: 2px solid var(--white-concrete);
    border-radius: 5px;
    height: 25px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            ${props => `background-color: #${props.BackgroundColor};`}
            cursor: pointer;
        }
    }
`;

export const RadioButtonPageText = styled(PageText)`
    @media(hover:hover) {
        :hover {
            ${props => !props.ReadOnly ? `cursor: pointer;` : `cursor: not-allowed;`}
        }
    }
`;

export const RadioButtonParentContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;

    > div:first-child {
        margin-right: 5px;
    }
`;

export const SearchInput = styled(Input)`
    margin-right: 10px;

    > input {
        border-radius: 10px;
    }
`;

export const SearchUsersInput = styled(Input)`
    margin-right: 10px;
    max-width: 100%;

    @media ${device.maxwidth.mobile} {
        width: 170px;
    }

    @media ${device.minwidth.mobile} {
        width: 260px;
    }

    @media ${device.minwidth.ipad} {
        width: 320px;
    }

    > input {
        border-radius: 10px;
    }
`;

export const SelectedIconContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 10px;
`;

export const StyledAccountInput = styled(Input)`
    max-width: 300px;
    
    > input {
        border-radius: 10px;
    }
`;

export const StyledHR = styled.div`
    background-color: var(--black);
    border-radius: 10px;
    height: 2px;
    margin: 20px 0px;
    width: 100%;
`;

export const StyledTemplateName = styled(Input)`
    margin: 0 auto;
    max-width: 500px;

    > div {
        font-family: var(--font-family-medium);
        font-size: var(--font-size-medium-3);
        margin-bottom: 20px;
        text-align: center;
    }

    > input {
        background-color: var(--white-concrete);
        border: none;
        border-radius: 10px;
        text-align: center;
        text-indent: 0px;
    }
`;

export const TaskContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;

    img {
        max-height: 30px !important;
        max-width: 30px !important;
    }

    svg {
        margin-right: 0px !important;
    }

    ${
        props => !props.ReadOnly &&
        `
            @media(hover:hover) {
                :hover {
                    background-color: var(--white-concrete-hover);
                    cursor: pointer;
                }
            }
        `
    }
`;

export const TaskContainerLeft = styled.div`
    align-items: center;
    display: flex;
    grid-gap: 5px;
    flex-direction: row;
    justify-content: flex-start;
`;

export const TasksContainer = styled.div`
    display: grid;
    grid-gap: 20px 15px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

export const WeeklyFrequencyDayContainer = styled.div``;

export const WeeklyFrequencyDaysContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    justify-content: flex-start;
`;