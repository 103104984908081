import styled from "styled-components";
import { Link } from "react-router-dom";

import Image from "../Image";

export const AbelLogoContainer = styled.div`
  padding: 0px 24px;
`;

export const AbelLogoImageContainer = styled(Link)`
  > svg {
    fill: var(--abel-blue);
    height: ${props => props.isheadermobile === "true" ? '28px' : '40px'};
    width: ${props => props.isheadermobile === "true" ? '30px' : '45px'};
  }
`;

export const AbelNameContainer = styled(Link)`
  align-items: center;
  color: inherit;
  display: flex;
  flex-direction: ${(props) => props.isheadermobile === "true" ? "row" : "column"};
`;

export const AbelSettingsImageContainer = styled(Link)`
  > svg {
    ${
      props => props.isselected === 'true' ?
      'fill: var(--abel-blue);'
    :
      'fill: var(--black);'
    }

    @media(hover:hover) {
      :hover {
        fill: var(--abel-blue);
      }
    }
  }
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: ${(props) => props.TopBar ? `calc(100% - ${props.TopBar}px - 36px)` : "calc(100% - 36px)"};
  position: fixed;
`;

export const MenuFooterContainer = styled.div`
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 0px;
  position: absolute;
  width: 100%;
`;

export const MenuIcon = styled(Image)`
  width: 25px;
`;

export const MenuItemsContainer = styled.ul`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style-type: none;
  padding: 0px;
  width: 100%;
`;

export const MenuItemContainer = styled.li`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 15px 0;

  > svg {
    height: 25px;
    width: 25px;

    fill: var(--black);
  }

  ${
    props => props.Selected &&
    `
      border-left: 5px solid var(--abel-blue);
      width: calc(100% - 5px);

      > svg {
        fill: var(--abel-blue);
      }
    `
  }

  @media (hover: hover) {
    :hover {
      cursor: pointer;

      > svg {
        fill: var(--abel-blue);
      }
    }
  }
`;

export const StyledLink = styled(Link)`
  width: 100%;
`;
