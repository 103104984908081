import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { ClientContainer, ClientsContainer, ClientSelectionContainer, EditedGroupIcon, EditIcon, SelectedUsersContainer, SingleClientContainer, StyledAssignedTo, StyledProfilePhoto } from './styles';

import ButtonGroup from '../Buttons/ButtonGroup';
import PageText from '../Text/PageText';
import Spacer from '../Spacer';

import ChooseClients from '../../Modals/ChooseClients';

import Edit_Dark from '../../Assets/Icons/Edit_Dark.png';
import Pencil from '../../Assets/Icons/Pencil.png';
import Plus from '../../Assets/Icons/Plus.png';

import { SortValues } from '../../Functions';

class AssignClients extends React.Component {
    state = { ShowClientSelectionModal: false }

    onSelectClients = Users => {
        this.onToggleShowClientSelectionModal(false);
        this.props.OnSelectClients(Users);
    }

    onToggleShowClientSelectionModal = ShowClientSelectionModal => {
        this.props.OnToggledShowClientSelectionModal && this.props.OnToggledShowClientSelectionModal(ShowClientSelectionModal);
        this.setState({ ShowClientSelectionModal });
    }

    renderClients = () => {
        var { t } = this.props;
        var { EligibleTypes, HorizontalScroll, LeftAlign, MaxClients, SelectedGroups, SelectedUsers, WhiteBackground } = this.props;
        
        var AddText = EligibleTypes === 'Groups' ? t('add_groups') : EligibleTypes === 'Both' ? t('add_clients_and_groups') : MaxClients === 1 ? t('ptadmin_clients_add_singular') : t('ptadmin_clients_add_plural');
                
        SelectedGroups = [ ...Object.keys(SelectedGroups).map(ObjectKey => ({ ...SelectedGroups[ObjectKey], SortName: SelectedGroups[ObjectKey].GroupName, Type: 'Group' })) ].sort(SortValues('SortName'));
        // SelectedUsers = [ ...SelectedUsers.map(User => ({ ...User, SortName: User.UserName, Type: 'User' })) ].sort(SortValues('SortName'));

        // var Selected = [
        //     ...Object.keys(SelectedGroups).map(ObjectKey => ({ ...SelectedGroups[ObjectKey], SortName: SelectedGroups[ObjectKey].GroupName, Type: 'Group' })).filter(({ GroupType }) => GroupType === 'Team'),
        //     ...SelectedUsers.map(User => ({ ...User, SortName: User.UserName, Type: 'User' }))
        // ];
        // Selected.sort(SortValues('SortName'));

        if (1 < 2) {
            return (
                <ClientsContainer className="ClientsContainer" Centered={(MaxClients === 1 || !HorizontalScroll) && !LeftAlign}>
                    {
                        SelectedGroups.length > 0 ?
                        <>
                            <EditIcon
                                ImageSrc={Edit_Dark}
                                Loading={false}
                                OnClick={() => this.onToggleShowClientSelectionModal(true)}
                            />
                                <SelectedUsersContainer HorizontalScroll={HorizontalScroll} className="SelectedUsersContainer">
                                    {
                                        SelectedGroups.map(({ AllMembers, Email, GroupImage, GroupName, IsPreRegistered, RemoveMembers, Sex, Type, UserName, UserPhoto }, SelectedIndex) => {
                                            var key = SelectedIndex;
                                            var ImageSrc = Type === 'Group' ? GroupImage : UserPhoto;
                                            var Name = Type === 'Group' ? GroupName : !!IsPreRegistered ? Email : `@${UserName}`;
                                            Sex = Type === 'Group' ? 'Male' : Sex;

                                            return (
                                                <ClientContainer key={key} className="ClientContainer" HorizontalScroll={HorizontalScroll} WhiteBackground={WhiteBackground}>
                                                    {
                                                        !IsPreRegistered && <StyledProfilePhoto Sex={Sex} ShowOverlay={false} Size="small-1" Source={ImageSrc || ''} />
                                                    }
                                                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={Name} WordBreak="unset" />
                                                    
                                                    {
                                                        Type === 'Group' && (!!!AllMembers || (!!AllMembers && !!+RemoveMembers.length)) &&
                                                        <EditedGroupIcon ImageSrc={Pencil} Loading={false} />
                                                    }
                                                </ClientContainer>
                                            );
                                        })
                                    }
                                </SelectedUsersContainer>
                        </>
                    :
                        (MaxClients === 1 || SelectedUsers.length > 0) ?
                        null
                    :
                        <ButtonGroup
                            Buttons={[{ Icon: Plus, IconPosition: 'left', FontFamily: 'semibold', FontSize: 'medium-1', OnClick: () => this.onToggleShowClientSelectionModal(true), Size: 'small', Title: AddText }]}
                        />
                    }
                    {
                        (SelectedGroups.length === 0 && (MaxClients === 1 || SelectedUsers.length > 0)) ?
                        (SelectedUsers.length === 0) ?
                            <ButtonGroup
                                Buttons={[{ Icon: Plus, IconPosition: 'left', FontFamily: 'semibold', FontSize: 'medium-1', OnClick: () => this.onToggleShowClientSelectionModal(true), Size: 'small', Title: AddText }]}
                            />
                    :
                        <>
                            <EditIcon
                                ImageSrc={Edit_Dark}
                                Loading={false}
                                OnClick={() => this.onToggleShowClientSelectionModal(true)}
                            />

                            <SelectedUsersContainer HorizontalScroll={HorizontalScroll} className='SelectedUsersContainer'>
                                {
                                    SelectedUsers.map(({ AllMembers, DisplayName, Gender, GroupImage, GroupName, IsPreRegistered, RemoveMembers, Sex, Type, UserPhoto }, SelectedIndex) => {
                                        var key = SelectedIndex;
                                        var ImageSrc = Type === 'Group' ? GroupImage : UserPhoto;
                                        var Name = Type === 'Group' ? GroupName : DisplayName;
                                        Sex = Type === 'Group' ? 'Male' : Sex ? Sex : Gender;

                                        return (
                                            <ClientContainer key={key} className="ClientContainer" HorizontalScroll={HorizontalScroll} WhiteBackground={WhiteBackground}>
                                                {
                                                    !IsPreRegistered && <StyledProfilePhoto Sex={Sex || 'Male'} ShowOverlay={false} Size="small-1" Source={ImageSrc || ''} />
                                                }
                                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={Name} WordBreak="unset" />
                                                
                                                {
                                                    Type === 'Group' && (!!!AllMembers || (!!AllMembers && !!+RemoveMembers.length)) &&
                                                    <EditedGroupIcon ImageSrc={Pencil} Loading={false} />
                                                }
                                            </ClientContainer>
                                        );
                                    })
                                }
                            </SelectedUsersContainer>
                        </>
                    :
                        null
                    }
                </ClientsContainer>
            );
        }

        return (
            <ClientsContainer Centered={MaxClients === 1 || !HorizontalScroll}>
                {
                    (SelectedGroups.length > 0 || SelectedUsers.length > 0) ?
                    <>
                        <EditIcon
                            ImageSrc={Edit_Dark}
                            Loading={false}
                            OnClick={() => this.onToggleShowClientSelectionModal(true)}
                        />
                        {/* <SelectedContainer> */}
                            {
                                SelectedGroups.length > 0 &&
                                    <SelectedUsersContainer HorizontalScroll={HorizontalScroll}>
                                        {
                                            SelectedGroups.map(({ AllMembers, GroupImage, GroupName, RemoveMembers, Sex, Type, UserName, UserPhoto }, SelectedIndex) => {
                                                var key = SelectedIndex;
                                                var ImageSrc = Type === 'Group' ? GroupImage : UserPhoto;
                                                var Name = Type === 'Group' ? GroupName : `@${UserName}`;
                                                Sex = Type === 'Group' ? 'Male' : Sex;

                                                return (
                                                    <ClientContainer key={key} HorizontalScroll={HorizontalScroll}>
                                                        <StyledProfilePhoto
                                                            Sex={Sex}
                                                            ShowOverlay={false}
                                                            Size="small-1"
                                                            Source={ImageSrc || ''}
                                                        />
                                                        <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={Name} WordBreak="unset" />
                                                        
                                                        {
                                                            Type === 'Group' && (!!!AllMembers || (!!AllMembers && !!+RemoveMembers.length)) &&
                                                            <EditedGroupIcon
                                                                ImageSrc={Pencil}
                                                                Loading={false}
                                                            />
                                                        }
                                                    </ClientContainer>
                                                );
                                            })
                                        }
                                    </SelectedUsersContainer>
                            }
                            {
                                SelectedUsers.length > 0 &&
                                    <SelectedUsersContainer HorizontalScroll={HorizontalScroll}>
                                        {
                                            SelectedUsers.map(({ AllMembers, GroupImage, GroupName, RemoveMembers, Sex, Type, UserName, UserPhoto }, SelectedIndex) => {
                                                var key = SelectedIndex;
                                                var ImageSrc = Type === 'Group' ? GroupImage : UserPhoto;
                                                var Name = Type === 'Group' ? GroupName : `@${UserName}`;
                                                Sex = Type === 'Group' ? 'Male' : Sex;

                                                return (
                                                    <ClientContainer key={key} HorizontalScroll={HorizontalScroll}>
                                                        <StyledProfilePhoto
                                                            Sex={Sex}
                                                            ShowOverlay={false}
                                                            Size="small-1"
                                                            Source={ImageSrc || ''}
                                                        />
                                                        <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={Name} WordBreak="unset" />
                                                        
                                                        {
                                                            Type === 'Group' && (!!!AllMembers || (!!AllMembers && !!+RemoveMembers.length)) &&
                                                            <EditedGroupIcon
                                                                ImageSrc={Pencil}
                                                                Loading={false}
                                                            />
                                                        }
                                                    </ClientContainer>
                                                );
                                            })
                                        }
                                    </SelectedUsersContainer>
                            }
                        {/* </SelectedContainer> */}
                    </>
                :
                    <ButtonGroup
                        Buttons={[{ Icon: Plus, IconPosition: 'left', FontFamily: 'semibold', FontSize: 'medium-1', OnClick: () => this.onToggleShowClientSelectionModal(true), Size: 'small', Title: AddText }]}
                    />
                }
            </ClientsContainer>
        );
    }

    renderClientSelection = () => {
        var { t } = this.props;
        var { HideTitle, HorizontalScroll, MaxClients } = this.props;

        if (MaxClients === 1 || !HorizontalScroll) {
            return (
                <SingleClientContainer>
                    {
                        !HideTitle &&
                        <>
                            <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('assigned_to')} TextAlign="center" />

                            <Spacer Size="small" />
                        </>
                    }

                    {this.renderClients()}
                </SingleClientContainer>
            );
        }

        return (
            <ClientSelectionContainer>
                {!HideTitle && <StyledAssignedTo FontFamily="medium" FontSize="medium-2" NoMargin Text={t('assigned_to')} WordBreak="unset" />}

                {this.renderClients()}
            </ClientSelectionContainer>
        );
    }

    renderClientSelectionModal = () => {
        var { EligibleTypes, GetStartedEligible, HideSelectAll, MaxClients, SelectedGroups, SelectedUserIds, SelectedUsers } = this.props;
        var { ShowClientSelectionModal } = this.state;

        if (ShowClientSelectionModal) {
            return (
                <ChooseClients
                    EligibleTypes={EligibleTypes}
                    GetStartedEligible={GetStartedEligible}
                    HideSelectAll={HideSelectAll}
                    MaxClients={MaxClients}
                    OnHide={() => this.onToggleShowClientSelectionModal(false)}
                    OnSelectClients={Users => this.onSelectClients(Users)}
                    SelectedClientIds={SelectedUserIds}
                    SelectedClients={SelectedUsers}
                    SelectedGroups={SelectedGroups}
                    Show={ShowClientSelectionModal}
                />
            );
        }
    }

    render() {
        return (
            <>
                {this.renderClientSelection()}

                {this.renderClientSelectionModal()}
            </>
        );
    }
}

AssignClients.propTypes = {
    EligibleTypes: PropTypes.string,
    GetStartedEligible: PropTypes.string,
    HideSelectAll: PropTypes.bool,
    HideTitle: PropTypes.bool,
    HorizontalScroll: PropTypes.bool,
    LeftAlign: PropTypes.bool,
    MaxClients: PropTypes.number,
    OnSelectClients: PropTypes.func,
    OnToggledShowClientSelectionModal: PropTypes.func,
    SelectedUserIds: PropTypes.array,
    SelectedUsers: PropTypes.array,
    WhiteBackground: PropTypes.bool
}

AssignClients.defaultProps = {
    EligibleTypes: 'Private',
    HideSelectAll: false,
    HideTitle: false,
    HorizontalScroll: true,
    LeftAlign: false,
    MaxClients: null,
    OnSelectClients: () => null,
    SelectedGroups: {},
    SelectedUserIds: [],
    SelectedUsers: [],
    WhiteBackground: false
}

export default withTranslation()(AssignClients);