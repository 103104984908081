import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ButtonGroup from '../../Buttons/ButtonGroup';
import Image from '../../Image';
import PageText from '../../Text/PageText';
import ProfilePhoto from '../../ProfilePhoto';
import Spacer from '../../Spacer';

import Blocked from '../../../Assets/Icons/Ratings/Blocked_Transparent.png';
import Checkmark from '../../../Assets/Icons/Checkmark_Selected.png';
import FourStar from '../../../Assets/Icons/Ratings/4Star_Transparent.png';
import FiveStar from '../../../Assets/Icons/Ratings/5Star_Transparent.png';
import New from '../../../Assets/Icons/Ratings/New_Transparent.png';
import NoImage from '../../../Assets/Icons/NoImage.png';
import ThreeStar from '../../../Assets/Icons/Ratings/3Star_Transparent.png';
import TwoStar from '../../../Assets/Icons/Ratings/2Star_Transparent.png';

const DishContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;

    ${
        props => props.Selected ?
        `
            background-color: rgba(26, 151, 255, 0.35);
            border: 5px solid var(--blue-abel);
            border-radius: 20px;
            overflow: hidden;

            :hover {
                background-color: rgba(26, 151, 255, 0.35);
            }
        `
    :
        null
    }

    > div:first-child {
        width: 100%;
    }
`;

const DishImage = styled(Image)`
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    height: 130px;
    object-fit: cover;
    width: 100%;

    > div {
        width: 100%;
    }
`;

const MultiSelect = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    height: 25px;
    left: 15px;
    position: absolute;
    top: 15px;
    width: 25px;
    z-index: 1;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
            border: none;
            cursor: pointer;
        }
    }
`;

const OwnerIcon = styled.div`
    background-color: var(--${props => props.BackgroundColor});
    border-radius: 50%;
    height: 30px;
    width: 30px;
`;

const OwnerRating = styled.div`
    display: flex;
    flex-direction: row;
    margin-${props => props.First ? 'right' : 'left'}: 20px;
`;

const OwnerRatings = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
`;

const RatingImage = styled.img`
    height: 30px;
    z-index: 1;
`;

const StyledDishName = styled(PageText)`
    text-transform: uppercase;
`;

const StyledProfilePhoto = styled(ProfilePhoto)`
    margin-${props => props.First ? 'right' : 'left'}: -15px;
`;

class DishListItem extends React.Component {
    onSelectDish = Item => {
        var { IsMultiSelect, MultiSelectItemIds, OnMultiSelect, SelectDish } = this.props;
        if (!IsMultiSelect) SelectDish(Item);
        else {
            var MultiSelectIndex = MultiSelectItemIds.indexOf(+Item.DishId);

            OnMultiSelect(Item, MultiSelectIndex);
        }
    }

    renderMultiSelect = DishOwner => {
        var { Item, MultiSelectItemIds, OnMultiSelect } = this.props;

        var { DishId } = Item;

        if (DishOwner === 'Client') return null;

        var MultiSelectIndex = MultiSelectItemIds.indexOf(Number(DishId));

        return <MultiSelect onClick={() => OnMultiSelect(Item, MultiSelectIndex)} Selected={MultiSelectIndex !== -1} />;
    }

    renderOwnerIcon = DishOwner => {
        var BackgroundColor = DishOwner === 'Abel' ? 'blue-abel' : DishOwner === 'Self' ? 'orange-selectiveyellow' : 'green-lima-dark';

        return <OwnerIcon BackgroundColor={BackgroundColor} />;
    }

    renderOwnerRating = OwnerType => {
        var { Item: { ClientRating, Rating }, SelectedUsers, UserDetails: { Gender, ProfilePhotoUrl } } = this.props;
        
        Rating = OwnerType === 'Trainer' ? Number(Rating) : Number(ClientRating);

        var RatingImageSource = Rating === 0 ? New : Rating === 1 ? Blocked : Rating === 2 ? TwoStar : Rating === 3 ? ThreeStar : Rating === 4 ? FourStar : Rating === 5 ? FiveStar : NoImage;

        if (OwnerType === 'Client') {
            Gender = SelectedUsers[0].Sex;
            ProfilePhotoUrl = SelectedUsers[0].UserPhoto;

            return (
                <OwnerRating First={true}>
                    <StyledProfilePhoto First={true} Sex={Gender} Size="small-2" Source={ProfilePhotoUrl} />
                    <RatingImage alt="Dish Rating" src={RatingImageSource} />
                </OwnerRating>
            );
        }

        return (
            <OwnerRating First={false}>
                <RatingImage alt="Dish Rating" src={RatingImageSource} />
                <StyledProfilePhoto First={false} Sex={Gender} Size="small-2" Source={ProfilePhotoUrl} />
            </OwnerRating>
        );
    }

    renderOwnerRatings = DishOwner => {
        return (
            <>
                <Spacer Size="extra-small" />

                <OwnerRatings>
                    {
                        this.props.ShowClientRating ?
                        this.renderOwnerRating('Client')
                    :
                        null
                    }

                    {this.renderOwnerIcon(DishOwner)}

                    {
                        DishOwner !== 'Client' ?
                        this.renderOwnerRating('Trainer')
                    :
                        null
                    }
                </OwnerRatings>
            </>
        );
    }

    render() {
        var { t } = this.props;
        var { HideOwnerRating, HideSelect, Item, MultiSelectItemIds, SelectedItemIds, UserDetails, ViewDish } = this.props;

        var { DishId, DishName, DishUrl, UserId } = Item;
        
        var Selected = SelectedItemIds.indexOf(Number(DishId)) > -1 || MultiSelectItemIds.indexOf(Number(DishId)) > -1;
        var DishOwner = Number(UserId) === 0 ? 'Abel' : Number(UserId) === Number(UserDetails.UserId) ? 'Self' : 'Client';

        return (
            <DishContainer id={`itemid-${DishId}`} Selected={HideSelect && Selected}>
                {/* {IsMultiSelect && this.renderMultiSelect(DishOwner)}                 */}

                <DishImage
                    Alt="Dish"
                    ContainerWidth="100%"
                    ForceOverlay={Selected}
                    ImageSrc={DishUrl}
                    OnClick={() => ViewDish(Item)}
                    OverlayColor={(HideSelect && Selected) ? 'rgba(26, 151, 255, 0.35)' : null}
                    SelfLoaded
                    ShowOverlay
                />

                {!HideOwnerRating ? this.renderOwnerRatings(DishOwner) : null}

                {
                    !HideSelect &&
                    <>
                        <Spacer Size="extra-small" />
                
                        <ButtonGroup
                            Buttons={[
                                {
                                    BackgroundColorHover: 'blue-abel',
                                    BackgroundColorSelected: 'blue-abel',
                                    Border: Selected ? '2px solid var(--blue-abel)' : '2px solid var(--white-concrete)',
                                    ColorHover: 'white',
                                    ColorSelected: 'white',
                                    FontFamily: 'medium',
                                    FontSize: 'small',
                                    OnClick: () => this.onSelectDish(Item), Selected, Size: 'small',
                                    Title: t(Selected ? 'WorkoutPlanSettings_selected' : 'select_with_first_char_uppercase')
                                }]}
                            ContainerWidth="100%"
                            NormalWhiteSpace
                        />
                    </>
                }

                <Spacer Size="extra-extra-small" />

                <StyledDishName className="styled-dish-name" FontFamily="bold" FontSize="small" NoMargin Text={DishName} TextAlign="center"/>
            </DishContainer>
        );
    }
}

DishListItem.propTypes = {
    AssignToClients: PropTypes.bool,
    HideOwnerRating: PropTypes.bool,
    HideSelect: PropTypes.bool,
    IsMultiSelect: PropTypes.bool,
    Item: PropTypes.object,
    OnAssignToClients: PropTypes.func,
    OnMultiSelect: PropTypes.func,
    MultiSelectItemIds: PropTypes.array,
    SelectTemplate: PropTypes.func,
    SelectedItemIds: PropTypes.array,
    SelectedUsers: PropTypes.array,
    ShowClientRating: PropTypes.bool,
    ViewTemplate: PropTypes.func
}

DishListItem.defaultProps = {
    MultiSelectItemIds: [],
    SelectedItemIds: []
}

const mapStateToProps = state => {
    return {
        UserDetails: state.Auth.UserDetails
    };
};

export default withTranslation()(connect(mapStateToProps, null )(DishListItem));