import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { InsertMealPlan_MealPlanBuilder, SearchDishes, ViewFoods_MealPlanBuilder, ViewDishFoods_MealPlanBuilder, ViewNutritionProgress } from '../../Services/Actions';
import moment from 'moment';
import { toast } from 'react-toastify';

import  * as Styles from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import { DishListItem } from '../../Components/Lists/ListItems';
import HeaderSAYT from '../../Components/Form/HeaderSAYT';
import Loading from '../../Components/Loading';
import MiniCalendar from '../../Components/Calendar/MiniCalendar';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import ProfilePhoto from '../../Components/ProfilePhoto';
import Spacer from '../../Components/Spacer';

import AddFood from '../../Modals/AddFood';
import FoodUnits from '../../Modals/FoodUnits';
import Info from '../../Modals/Info';

import Plus from '../../Assets/Icons/Plus.png';

const CalculateGramsConversion = ({ Quantity, UnitId, Units }) => {
    var GramsConversion = 0;

    // Unit Is Already Grams, So Return New Quantity
        if (+UnitId === 1) GramsConversion = Quantity;
    // Unit Is Not Grams, So Convert To Grams
        else {
            for (var i = 0; i < Units.length; i++) {
                if (+Units[i].UnitId !== +UnitId) continue;

                if (+Units[i].UnitId === +UnitId) {
                    GramsConversion = Units[i].GramsConversion * Quantity;

                    break;
                }
            }
        }

    return GramsConversion;
}

class MealPlanBuilder extends React.PureComponent {
    _isMounted = false;

    state = {
        DemoUser: {},
        Dishes: [],
        DishesIndex: [],
        Loading: false,
        MealPlan: {},
        NumberDays: '',
        NutritionProgress_1Day: [],
        NutritionProgress: [],
        NutritionProgressEmpty: [],
        NutritionProgressIndex: [],
        NutritionProgressIndex_Calories: null,
        SelectedDates: [],
        StartDate: null,

        Dishes_NewSearch: false,
        Dishes_PageNo: 1,
        Dishes_PageSize: 20,
        DishName_SearchValue: '',
        DishName_SearchValue_Last: '',
        LoadedDishes_FirstTime: false,
        ShowChooseDishModal: false,
        ShowChooseDishModal_Day: null,

        ExcludeFoodIds: [],
        ShowAddFoodModal: false,
        ShowAddFoodModal_Day: null,
        ShowAddFoodModal_Dish: null,

        ShowFoodUnitsModal: false,
        ShowFoodUnitsModal_Data: {}
    };

    componentDidMount() {
        this._isMounted = true;
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    onAddLoggedFood = ({ MealPlanDay }) => {
        var MealPlan = { ...this.state.MealPlan };
        MealPlan[MealPlanDay].Dishes = [ ...MealPlan[MealPlanDay].Dishes, { DishId: 0, DishType: 'LoggedFood', Foods: [], NutritionProgress: JSON.parse(JSON.stringify(this.state.NutritionProgressEmpty)) } ];

        this.setState({ MealPlan });
    }

    onAddPlanToUser = () => {
        var { DemoUser: { UserId: ClientId }, StartDate } = this.state;
        var MealPlan = { ...this.state.MealPlan };

        var Days = [];

        for (var i = 0; i < Object.keys(MealPlan).length; i++) {
            var DayKey = Object.keys(MealPlan)[i];

            if (!MealPlan[DayKey].Dishes.length) continue;

            var Day = [];

            for (var a = 0; a < MealPlan[DayKey].Dishes.length; a++) {
                var Dish = MealPlan[DayKey].Dishes[a];

                var { DishId, Scale, Foods } = Dish;

                var DishObj = { DishId, Scale: Scale || 0, Foods: [] };

                for (var b = 0; b < Foods.length; b++) {
                    var { FoodId, Quantity, UnitId } = Foods[b];

                    DishObj.Foods.push({ FoodId, Quantity: +Quantity, UnitId });
                }

                Day.push(DishObj)
            }

            Days.push(Day);
        }

        this.props.InsertMealPlan_MealPlanBuilder({ ClientId, MealPlan: Days, StartDate }).then(() => {
            if (!this.props.TryingInsertMealPlanMealPlanBuilderError) {
                toast.success('Meals have been added', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
    }

    onBlurDishFoodQuantity = ({ DishIndex, FoodIndex, MealPlanDay }) => {
        var NutritionProgress = [ ...this.state.NutritionProgress ];

        var MealPlan = { ...this.state.MealPlan };
        var UpdatedFood = { ...this.state.MealPlan[MealPlanDay].Dishes[DishIndex].Foods[FoodIndex] };
        var PreviousGramsConversion = +UpdatedFood.GramsConversion;
        var PreviousQuantity = +UpdatedFood.PreviousQuantity;
        var NewQuantity = Math.round(MealPlan[MealPlanDay].Dishes[DishIndex].Foods[FoodIndex].Quantity);

        var NewGramsConversion;

        if (PreviousQuantity !== NewQuantity) {
            MealPlan[MealPlanDay].Dishes[DishIndex].Touched = 1;
            MealPlan[MealPlanDay].Dishes[DishIndex].Foods[FoodIndex].PreviousQuantity = NewQuantity;
            MealPlan[MealPlanDay].Dishes[DishIndex].Foods[FoodIndex].Quantity = NewQuantity; // Updated So That Rounding Is Saved
            MealPlan[MealPlanDay].Dishes[DishIndex].Foods[FoodIndex].GramsConversion = CalculateGramsConversion({ Quantity: NewQuantity, UnitId: UpdatedFood.UnitId, Units: UpdatedFood.Units });

            NewGramsConversion = MealPlan[MealPlanDay].Dishes[DishIndex].Foods[FoodIndex].GramsConversion;

            var NutritionIndex;

            // For Each Nutrient In A Food, Update Nutrition Progress To Remove Calculation For Previous Quantity, Then Add New Quantity
                for (var i = 0; i < UpdatedFood.Nutrients_100Grams.length; i++) {
                    var { NutrientId, Value } = UpdatedFood.Nutrients_100Grams[i];

                    NutritionIndex = this.state.NutritionProgressIndex.indexOf(NutrientId);

                    // Set Nutrition Progress As If This Nutrient Didn't Exist Yet
                        // Overall
                            NutritionProgress[NutritionIndex].Value -= Math.round((((PreviousGramsConversion / 100) * Value) + Number.EPSILON) * 100) / 100;
                        // Daily
                            MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value -= Math.round((((PreviousGramsConversion / 100) * Value) + Number.EPSILON) * 100) / 100;
                        // Dish
                            MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value -= Math.round((((PreviousGramsConversion / 100) * Value) + Number.EPSILON) * 100) / 100;

                    // Set Nutrition Progress For New Value Of This Nutrient
                        // Overall
                            NutritionProgress[NutritionIndex].Value += ((NewGramsConversion / 100) * Value);
                        // Daily
                            MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value += ((NewGramsConversion / 100) * Value);
                        // Dish
                            MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value += ((NewGramsConversion / 100) * Value);

                    // Round To 2 Decimal Places
                        // Overall
                            NutritionProgress[NutritionIndex].Value = Math.round((NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;
                        // Daily
                            MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value = Math.round((MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;
                        // Dish
                            MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value = Math.round(( MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;

                    // Calculate New WithinLimits Status
                        var { LowerLimit, UpperLimit, Value: NewValue, WithinLimits } = NutritionProgress[NutritionIndex];
                        if (!!+LowerLimit && NewValue < LowerLimit) WithinLimits = "below";
                        else if (!!+UpperLimit && NewValue > UpperLimit) WithinLimits = "above";
                        else WithinLimits = "within";

                        NutritionProgress[NutritionIndex].WithinLimits = WithinLimits;
                }

            this.setState({ MealPlan, NutritionProgress });
        }
    }

    onBlurNumberDays = () => {
        var NumberDays = Math.round(this.state.NumberDays);

        if (NumberDays > 28) NumberDays = 28;
        else if (NumberDays < 1) NumberDays = 1;
        
        // Build Meal Plan Days
            var MealPlan = { ...this.state.MealPlan };
            var NutritionProgress = [ ...this.state.NutritionProgress ];

            var MealPlanLength = Object.keys(MealPlan).length;
            var Difference = NumberDays - MealPlanLength;
            var d;

            // Add New Days
                if (MealPlanLength < NumberDays) {
                    for (d = 0; d < Difference; d++) {
                        MealPlan[`Day_${MealPlanLength + (d + 1)}`] = { Dishes: [], NutritionProgress: JSON.parse(JSON.stringify(this.state.NutritionProgressEmpty)) }
                    }
                }
            // Remove Existing Days --> Need to Remove Calculations From Nutrition Progress For Dishes On Deleted Days
                else if (MealPlanLength > NumberDays) {
                    Difference = Math.abs(Difference);

                    var NutritionIndex;

                    for (d = 0; d < Difference; d++) {
                        // Has Dishes, Delete From Nutrition Progress
                            if (MealPlan[`Day_${MealPlanLength - d}`].Dishes.length > 0) {
                                for (var b = 0; b < MealPlan[`Day_${MealPlanLength - d}`].Dishes.length; b++) {
                                    // For Each Food In A Dish, Update Nutrition Progress To Remove Calculation
                                        for (var a = 0; a < MealPlan[`Day_${MealPlanLength - d}`].Dishes[b].Foods.length; a++) {
                                            var DeletedFood = MealPlan[`Day_${MealPlanLength - d}`].Dishes[b].Foods[a];

                                            // For Each Nutrient In A Food, Update Nutrition Progress To Remove Calculation For Previous Quantity
                                                for (var i = 0; i < DeletedFood.Nutrients_100Grams.length; i++) {
                                                    var { NutrientId, Value } = DeletedFood.Nutrients_100Grams[i];

                                                    NutritionIndex = this.state.NutritionProgressIndex.indexOf(NutrientId);

                                                    // Set Nutrition Progress As If This Nutrient Didn't Exist Yet
                                                        NutritionProgress[NutritionIndex].Value -= Math.round((((DeletedFood.Quantity / 100) * Value) + Number.EPSILON) * 100) / 100;

                                                    // Round To 2 Decimal Places
                                                        NutritionProgress[NutritionIndex].Value = Math.round((NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;
                                                }
                                        }
                                }
                            }
                        delete MealPlan[`Day_${MealPlanLength - d}`];
                    }
                }

        // Update Nutrition Progress Lower/Upper Limits && WithinLimits Status
            for (i = 0; i < NutritionProgress.length; i++) {
                // eslint-disable-next-line
                var { LowerLimit, UpperLimit, Value, WithinLimits } = NutritionProgress[i];

                // Calculate Lower Limit
                    if (!!+LowerLimit) NutritionProgress[i].LowerLimit = Math.round(((this.state.NutritionProgress_1Day[i].LowerLimit * NumberDays) + Number.EPSILON) * 100) / 100;
                
                // Calculate Upper Limit
                    if (!!+UpperLimit) NutritionProgress[i].UpperLimit = Math.round(((this.state.NutritionProgress_1Day[i].UpperLimit * NumberDays) + Number.EPSILON) * 100) / 100;

                // Calculate WithinLimits Status
                    if (!!+LowerLimit && Value < LowerLimit) WithinLimits = "below";
                    else if (!!+UpperLimit && Value > UpperLimit) WithinLimits = "above";
                    else WithinLimits = "within";

                    NutritionProgress[i].WithinLimits = WithinLimits;
            }

        this.setState({ MealPlan, NumberDays, NutritionProgress });
    }

    onBlurDishScale = ({ DishIndex, MealPlanDay }) => {
        var NutritionProgress = [ ...this.state.NutritionProgress ];

        var MealPlan = { ...this.state.MealPlan };
        var UpdatedDish = { ...this.state.MealPlan[MealPlanDay].Dishes[DishIndex] };
        var PreviousScale = +UpdatedDish.PreviousScale;
        var NewScale = Math.round(MealPlan[MealPlanDay].Dishes[DishIndex].Scale);

        if (PreviousScale !== NewScale) {
            MealPlan[MealPlanDay].Dishes[DishIndex].PreviousScale = NewScale;
            MealPlan[MealPlanDay].Dishes[DishIndex].Scale = NewScale; // Updated So That Rounding Is Saved

            var NutritionIndex;

            var PreviousGramsConversion;
            var NewGramsConversion;

            // For Each Food In A Dish
                for (var a = 0; a < UpdatedDish.Foods.length; a++) {
                    var UpdatedFood = UpdatedDish.Foods[a];
                    
                    UpdatedFood.Quantity = Math.round((((UpdatedFood.Quantity / PreviousScale) * NewScale) + Number.EPSILON) * 100) / 100;
                    UpdatedFood.PreviousQuantity = UpdatedFood.Quantity;
                    
                    PreviousGramsConversion = +UpdatedFood.GramsConversion;
                    NewGramsConversion = Math.round((((UpdatedFood.GramsConversion / PreviousScale) * NewScale) + Number.EPSILON) * 100) / 100;
                    UpdatedFood.GramsConversion = NewGramsConversion;

                    // For Each Nutrient In A Food, Update Nutrition Progress To Remove Calculation For Previous Quantity, Then Add New Quantity
                        for (var i = 0; i < UpdatedFood.Nutrients_100Grams.length; i++) {
                            var { NutrientId, Value } = UpdatedFood.Nutrients_100Grams[i];

                            NutritionIndex = this.state.NutritionProgressIndex.indexOf(NutrientId);

                            // Set Nutrition Progress As If This Nutrient Didn't Exist Yet
                                // Overall
                                    NutritionProgress[NutritionIndex].Value -= Math.round((((PreviousGramsConversion / 100) * Value) + Number.EPSILON) * 100) / 100;
                                // Daily
                                    MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value -= Math.round((((PreviousGramsConversion / 100) * Value) + Number.EPSILON) * 100) / 100;
                                // Dish
                                    MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value -= Math.round((((PreviousGramsConversion / 100) * Value) + Number.EPSILON) * 100) / 100;

                            // Set Nutrition Progress For New Value Of This Nutrient
                                // Overall
                                    NutritionProgress[NutritionIndex].Value += ((NewGramsConversion / 100) * Value);
                                // Daily
                                    MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value += ((NewGramsConversion / 100) * Value);
                                // Dish
                                    MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value += ((NewGramsConversion / 100) * Value);

                            // Round To 2 Decimal Places
                                // Overall
                                    NutritionProgress[NutritionIndex].Value = Math.round((NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;
                                // Daily
                                    MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value = Math.round((MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;
                                // Dish
                                    MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value = Math.round(( MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;

                            // Calculate New WithinLimits Status
                                var { LowerLimit, UpperLimit, Value: NewValue, WithinLimits } = NutritionProgress[NutritionIndex];
                                if (!!+LowerLimit && NewValue < LowerLimit) WithinLimits = "below";
                                else if (!!+UpperLimit && NewValue > UpperLimit) WithinLimits = "above";
                                else WithinLimits = "within";

                                NutritionProgress[NutritionIndex].WithinLimits = WithinLimits;
                        }
                }

            this.setState({ MealPlan, NutritionProgress });
        }
    }

    onChangeDishFoodQuantity = ({ event, DishIndex, FoodIndex, MealPlanDay }) => {
        var MealPlan = { ...this.state.MealPlan };
        MealPlan[MealPlanDay].Dishes = [ ...MealPlan[MealPlanDay].Dishes ];

        MealPlan[MealPlanDay].Dishes[DishIndex].Foods[FoodIndex].Quantity = event.target.value;

        this.setState({ MealPlan });
    }

    onChangeDishScale = ({ event, DishIndex, MealPlanDay }) => {
        var MealPlan = { ...this.state.MealPlan };
        MealPlan[MealPlanDay].Dishes[DishIndex].Scale = event.target.value;

        this.setState({ MealPlan });
    }

    onChangeNumberDays = event => {
        this.setState({ NumberDays: event.target.value });
    }

    onChangeSearch_DishName = event => {
        this.setState({ DishName_SearchValue: event.target.value });
    }

    onClickAddDish = ({ Dish = {}, MealPlanDay }) => {
        var NutritionProgress = [ ...this.state.NutritionProgress ];

        // Set Previous Quantity For Each Food In The Added Dish
            for (var c = 0; c < Dish.Foods.length; c++) {
                Dish.Foods[c].PreviousQuantity = Dish.Foods[c].Quantity;
            }

        var MealPlan = { ...this.state.MealPlan };
        MealPlan[MealPlanDay].Dishes = [ ...MealPlan[MealPlanDay].Dishes, { ...Dish, DishType: 'Dish', PreviousScale: 1, Scale: 1, Touched: 0, NutritionProgress: JSON.parse(JSON.stringify(this.state.NutritionProgressEmpty)) } ];
        
        var AddedDish = MealPlan[MealPlanDay].Dishes[MealPlan[MealPlanDay].Dishes.length - 1];
        var DishIndex = MealPlan[MealPlanDay].Dishes.length - 1;
        var NutritionIndex;

        // For Each Food In A Dish, Update Nutrition Progress To Include Calculation
            for (var a = 0; a < AddedDish.Foods.length; a++) {
                var AddedFood = { ...AddedDish.Foods[a] };

                 // For Each Nutrient In A Food, Update Nutrition Progress To Include Calculation
                    for (var i = 0; i < AddedFood.Nutrients_100Grams.length; i++) {
                        var { NutrientId, Value } = AddedFood.Nutrients_100Grams[i];
                        NutritionIndex = this.state.NutritionProgressIndex.indexOf(+NutrientId);

                        // Set Nutrition Progress For New Value Of This Nutrient
                            // Overall
                                NutritionProgress[NutritionIndex].Value += ((AddedFood.GramsConversion / 100) * Value);
                            // Daily
                                MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value += ((AddedFood.GramsConversion / 100) * Value);
                            // Dish
                                MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value += ((AddedFood.GramsConversion / 100) * Value);

                        // Round To 2 Decimal Places
                            // Overall
                                NutritionProgress[NutritionIndex].Value = Math.round((NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;
                            // Daily
                                MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value = Math.round((MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;
                            // Dish
                                MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value = Math.round((MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;

                        // Calculate New WithinLimits Status
                            var { LowerLimit, UpperLimit, Value: NewValue, WithinLimits } = NutritionProgress[NutritionIndex];
                            if (!!+LowerLimit && NewValue < LowerLimit) WithinLimits = "below";
                            else if (!!+UpperLimit && NewValue > UpperLimit) WithinLimits = "above";
                            else WithinLimits = "within";

                            NutritionProgress[NutritionIndex].WithinLimits = WithinLimits;
                    }
            }

        this.setState({ MealPlan, NutritionProgress });
    }

    onClickAddDishFood = ({ Food }) => {
        var NutritionProgress = [ ...this.state.NutritionProgress ];

        var DishIndex = this.state.ShowAddFoodModal_Dish;
        var MealPlanDay = this.state.ShowAddFoodModal_Day;

        var MealPlan = { ...this.state.MealPlan };
        MealPlan[MealPlanDay].Dishes[DishIndex].Touched = 1;
        MealPlan[MealPlanDay].Dishes[DishIndex].Foods = [ ...MealPlan[MealPlanDay].Dishes[DishIndex].Foods, { ...Food, PreviousQuantity: 1, Quantity: 1 } ];

        var UpdatedFood = MealPlan[MealPlanDay].Dishes[DishIndex].Foods[MealPlan[MealPlanDay].Dishes[DishIndex].Foods.length - 1];

        var NutritionIndex;

        // For Each Nutrient In A Food, Update Nutrition Progress To Remove Calculation For Previous Quantity, Then Add New Quantity
            for (var i = 0; i < UpdatedFood.Nutrients_100Grams.length; i++) {
                var { NutrientId, Value } = UpdatedFood.Nutrients_100Grams[i];

                NutritionIndex = this.state.NutritionProgressIndex.indexOf(NutrientId);

                // Set Nutrition Progress For New Value Of This Nutrient
                    // Overall
                        NutritionProgress[NutritionIndex].Value += ((UpdatedFood.GramsConversion / 100) * Value);
                    // Daily
                        MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value += ((UpdatedFood.GramsConversion / 100) * Value);
                    // Dish
                        MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value += ((UpdatedFood.GramsConversion / 100) * Value);

                // Round To 2 Decimal Places
                    // Overall
                        NutritionProgress[NutritionIndex].Value = Math.round((NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;
                    // Daily
                        MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value = Math.round((MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;
                    // Dish
                        MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value = Math.round((MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;

                // Calculate New WithinLimits Status
                    var { LowerLimit, UpperLimit, Value: NewValue, WithinLimits } = NutritionProgress[NutritionIndex];
                    if (!!+LowerLimit && NewValue < LowerLimit) WithinLimits = "below";
                    else if (!!+UpperLimit && NewValue > UpperLimit) WithinLimits = "above";
                    else WithinLimits = "within";

                    NutritionProgress[NutritionIndex].WithinLimits = WithinLimits;
            }

        this.setState({ MealPlan, NutritionProgress });
        this.onToggleShowAddFoodModal({ ShowAddFoodModal: false });
    }

    onClickDeleteDish = ({ DishIndex, MealPlanDay }) => {
        var NutritionProgress = [ ...this.state.NutritionProgress ];

        var MealPlan = { ...this.state.MealPlan };
        var DeletedDish = { ...this.state.MealPlan[MealPlanDay].Dishes[DishIndex] };
        var NutritionIndex;

        // For Each Food In A Dish, Update Nutrition Progress To Remove Calculation
            for (var a = 0; a < DeletedDish.Foods.length; a++) {
                var DeletedFood = { ...this.state.MealPlan[MealPlanDay].Dishes[DishIndex].Foods[a] };

                 // For Each Nutrient In A Food, Update Nutrition Progress To Remove Calculation For Previous Quantity
                    for (var i = 0; i < DeletedFood.Nutrients_100Grams.length; i++) {
                        var { NutrientId, Value } = DeletedFood.Nutrients_100Grams[i];

                        NutritionIndex = this.state.NutritionProgressIndex.indexOf(NutrientId);

                        // Set Nutrition Progress As If This Nutrient Didn't Exist Yet (Delete Rounded Amount To Match What Was Added Originally)
                            // Overall
                                NutritionProgress[NutritionIndex].Value -= Math.round((((DeletedFood.Quantity / 100) * Value) + Number.EPSILON) * 100) / 100;
                            // Daily
                                MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value -= Math.round((((DeletedFood.Quantity / 100) * Value) + Number.EPSILON) * 100) / 100;
                            // Dish
                                MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value -= Math.round((((DeletedFood.Quantity / 100) * Value) + Number.EPSILON) * 100) / 100;

                        // Round To 2 Decimal Places
                            // Overall
                                NutritionProgress[NutritionIndex].Value = Math.round((NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;
                            // Daily
                                MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value = Math.round((MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;
                            // Dish
                                MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value = Math.round((MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;

                        // Calculate New WithinLimits Status
                            var { LowerLimit, UpperLimit, Value: NewValue, WithinLimits } = NutritionProgress[NutritionIndex];
                            if (!!+LowerLimit && NewValue < LowerLimit) WithinLimits = "below";
                            else if (!!+UpperLimit && NewValue > UpperLimit) WithinLimits = "above";
                            else WithinLimits = "within";

                            NutritionProgress[NutritionIndex].WithinLimits = WithinLimits;
                    }
            }

        MealPlan[MealPlanDay].Dishes.splice(DishIndex, 1);

        this.setState({ MealPlan, NutritionProgress });
    }

    onClickToggleShowDishFoods = ({ DishIndex, MealPlanDay, ShowFoods }) => {
        var MealPlan = { ...this.state.MealPlan };
        MealPlan[MealPlanDay].Dishes = [ ...MealPlan[MealPlanDay].Dishes ];

        MealPlan[MealPlanDay].Dishes[DishIndex].ShowFoods = ShowFoods;

        this.setState({ MealPlan });
    }

    onDeleteDishFood = ({ DishIndex, FoodIndex, MealPlanDay }) => {
        var NutritionProgress = [ ...this.state.NutritionProgress ];

        var MealPlan = { ...this.state.MealPlan };
        var UpdatedDish = { ...this.state.MealPlan[MealPlanDay].Dishes[DishIndex] };
        var DeletedFood = UpdatedDish.Foods[FoodIndex];
        var NutritionIndex;

        // Deleting Last Food, So Delete Dish Also
            if (UpdatedDish.Foods.length === 1) this.onClickDeleteDish({ DishIndex, MealPlanDay });
        // Dish Has Other Ingredients, So Remove Single One Only
            else {
                MealPlan[MealPlanDay].Dishes[DishIndex].Touched = 1;

                // For Each Nutrient In A Food, Update Nutrition Progress To Remove Calculation For Quantity
                    for (var i = 0; i < DeletedFood.Nutrients_100Grams.length; i++) {
                        var { NutrientId, Value } = DeletedFood.Nutrients_100Grams[i];

                        NutritionIndex = this.state.NutritionProgressIndex.indexOf(NutrientId);

                        // Set Nutrition Progress As If This Nutrient Didn't Exist Yet
                            // Overall
                                NutritionProgress[NutritionIndex].Value -= Math.round((((DeletedFood.GramsConversion / 100) * Value) + Number.EPSILON) * 100) / 100;
                            // Daily
                                MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value -= Math.round((((DeletedFood.GramsConversion / 100) * Value) + Number.EPSILON) * 100) / 100;
                            // Dish
                                MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value -= Math.round((((DeletedFood.GramsConversion / 100) * Value) + Number.EPSILON) * 100) / 100;

                        // Round To 2 Decimal Places
                            // Overall
                                NutritionProgress[NutritionIndex].Value = Math.round((NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;
                            // Daily
                                MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value = Math.round((MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;
                            // Dish
                                MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value = Math.round((MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;

                        // Calculate New WithinLimits Status
                            var { LowerLimit, UpperLimit, Value: NewValue, WithinLimits } = NutritionProgress[NutritionIndex];
                            if (!!+LowerLimit && NewValue < LowerLimit) WithinLimits = "below";
                            else if (!!+UpperLimit && NewValue > UpperLimit) WithinLimits = "above";
                            else WithinLimits = "within";

                            NutritionProgress[NutritionIndex].WithinLimits = WithinLimits;
                    }

                MealPlan[MealPlanDay].Dishes[DishIndex].Foods.splice(FoodIndex, 1);

                this.setState({ MealPlan, NutritionProgress });
            }
    }

    onKeyPress_DishName = ({ key }) => {
        if (key === 'Enter' && !this.props.TryingSearchDishes) {
            var { DishName_SearchValue, DishName_SearchValue_Last } = this.state;

            if (DishName_SearchValue !== DishName_SearchValue_Last) {
                this.setState({ Dishes_PageNo: 1, Dishes_PageSize: 20, DishName_SearchValue_Last: DishName_SearchValue }, () => {
                    this.onLoadDishes();
                });
            }
        }
    }

    onLoadDishes = Increment => {
        var { Dishes_NewSearch, Dishes_PageNo: PageNo, Dishes_PageSize: PageSize, DishName_SearchValue } = this.state;

        if (Increment) {
            Dishes_NewSearch = false;
            PageNo = PageNo + 1;
        } else Dishes_NewSearch = true;

        this.setState({ Dishes_NewSearch, Dishes_PageNo: PageNo }, () => this.props.SearchDishes({ AddToExisting: Increment, DishName: DishName_SearchValue, PageNo, PageSize }).then(() => this._isMounted ? this.setState({ LoadedDishes_FirstTime: true }) : null));
    }

    onSelectDemoUser = ({ UserDetails }) => {
        if (!Object.keys(this.state.DemoUser).length || (Object.keys(this.state.DemoUser).length && this.state.DemoUser.UserId !== UserDetails.UserId)) {
            this.setState({ DemoUser: UserDetails }, () => {
                this.props.ViewNutritionProgress({ NumberDays: 1, UserId: UserDetails.UserId }).then(({ NutritionProgress }) => {
                    if (!this.props.TryingViewNutritionProgressError) {
                        var NutritionProgressIndex = NutritionProgress.map(({ NutrientId }) => +NutrientId);
                        var NutritionProgressEmpty = NutritionProgress.map(({ NutrientId, NutrientName }) => ({ NutrientId, NutrientName, Value: 0 }));
                        var NutritionProgress_1Day = JSON.parse(JSON.stringify(NutritionProgress));

                        var NutritionProgressIndex_Calories = NutritionProgressIndex.indexOf(1);

                        this.setState({ NutritionProgress_1Day, NutritionProgress, NutritionProgressEmpty, NutritionProgressIndex, NutritionProgressIndex_Calories });
                    }
                });
            });
        }
    }

    onSelectDish = Dish => {
        var MealPlanDay = this.state.ShowChooseDishModal_Day;

        this.onToggleShowChooseDishModal({ ShowChooseDishModal: false });

        var DishesIndex_Index = this.state.DishesIndex.indexOf(+Dish.DishId);
        if (DishesIndex_Index === -1) {
            this.props.ViewDishFoods_MealPlanBuilder({ DishId: Dish.DishId }).then(({ Foods }) => {
                Dish.Foods = Foods;

                var Dishes = [ ...this.state.Dishes, { ...Dish } ];
                var DishesIndex = [ ...this.state.DishesIndex, +Dish.DishId ];

                this.setState({ Dishes, DishesIndex });
                this.onClickAddDish({ Dish, MealPlanDay });
            });
        } else this.onClickAddDish({ Dish: JSON.parse(JSON.stringify(this.state.Dishes[DishesIndex_Index])), MealPlanDay });
    }

    onSelectFoodUnit = ({ DishIndex, FoodIndex, MealPlanDay, UnitId }) => {
        var NutritionProgress = [ ...this.state.NutritionProgress ];

        var MealPlan = { ...this.state.MealPlan };
        var UpdatedFood = MealPlan[MealPlanDay].Dishes[DishIndex].Foods[FoodIndex];

        var NutritionIndex;

        // Close Modal, No Change
            if (+UpdatedFood.UnitId === +UnitId) this.onToggleShowFoodUnitsModal({ ShowFoodUnitsModal: false });
        // Change Unit & Calculations
            else {
                // Previously Selected GramsConversion
                    var GramsConversion = +UpdatedFood.GramsConversion;
                // Newly Selected GramsConversion
                    var NewGramsConversion;
                    for (var i = 0; i < UpdatedFood.Units.length; i++) {
                        if (+UpdatedFood.Units[i].UnitId !== +UnitId) continue;

                        if (+UpdatedFood.Units[i].UnitId === +UnitId) {
                            NewGramsConversion = UpdatedFood.Units[i].GramsConversion * UpdatedFood.Quantity;
                            UpdatedFood.UnitName = UpdatedFood.Units[i].UnitName;

                            break;
                        }
                    }

                // For Each Nutrient In A Food, Update Nutrition Progress To Remove Calculation For Previous Quantity, Then Add New Quantity
                    for (i = 0; i < UpdatedFood.Nutrients_100Grams.length; i++) {
                        var { NutrientId, Value } = UpdatedFood.Nutrients_100Grams[i];

                        NutritionIndex = this.state.NutritionProgressIndex.indexOf(NutrientId);

                        // Set Nutrition Progress As If This Nutrient Didn't Exist Yet
                            // Overall
                                NutritionProgress[NutritionIndex].Value -= Math.round((((GramsConversion / 100) * Value) + Number.EPSILON) * 100) / 100;
                            // Daily
                                MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value -= Math.round((((GramsConversion / 100) * Value) + Number.EPSILON) * 100) / 100;
                            // Dish
                                MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value -= Math.round((((GramsConversion / 100) * Value) + Number.EPSILON) * 100) / 100;

                        // Set Nutrition Progress For New Value Of This Nutrient
                            // Overall
                                NutritionProgress[NutritionIndex].Value += ((NewGramsConversion / 100) * Value);
                            // Daily
                                MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value += ((NewGramsConversion / 100) * Value);
                            // Dish
                                MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value += ((NewGramsConversion / 100) * Value);

                        // Round To 2 Decimal Places
                            // Overall
                                NutritionProgress[NutritionIndex].Value = Math.round((NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;
                            // Daily
                                MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value = Math.round((MealPlan[MealPlanDay].NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;
                            // Dish
                                MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value = Math.round(( MealPlan[MealPlanDay].Dishes[DishIndex].NutritionProgress[NutritionIndex].Value + Number.EPSILON) * 100) / 100;

                        // Calculate New WithinLimits Status
                            var { LowerLimit, UpperLimit, Value: NewValue, WithinLimits } = NutritionProgress[NutritionIndex];
                            if (!!+LowerLimit && NewValue < LowerLimit) WithinLimits = "below";
                            else if (!!+UpperLimit && NewValue > UpperLimit) WithinLimits = "above";
                            else WithinLimits = "within";

                            NutritionProgress[NutritionIndex].WithinLimits = WithinLimits;
                    }

                // Update Food Data To Be New Unit
                    UpdatedFood.GramsConversion = NewGramsConversion;
                    UpdatedFood.UnitId = UnitId;

                    MealPlan[MealPlanDay].Dishes[DishIndex].Foods[FoodIndex] = UpdatedFood;

                this.setState({ MealPlan, NutritionProgress, ShowFoodUnitsModal: false, ShowFoodUnitsModal_Data: {} });
            }
    }

    onSelectStartDate = DayDate => {
        var SelectedDates = [];

        for (var i = 0; i < this.state.NumberDays; i++) {
            SelectedDates.push(moment(DayDate).add(i, 'days').format('YYYY-MM-DD'));
        }

        this.setState({ SelectedDates, StartDate: DayDate });
    }

    onToggleShowAddFoodModal_Helper = ({ DishIndex, MealPlanDay }) => {
        var ExcludeFoodIds = this.state.MealPlan[MealPlanDay].Dishes[DishIndex].Foods.map(({ FoodId }) => +FoodId);

        this.onToggleShowAddFoodModal({ ExcludeFoodIds, ShowAddFoodModal: true, ShowAddFoodModal_Day: MealPlanDay, ShowAddFoodModal_Dish: DishIndex });
    }

    onToggleShowAddFoodModal = ({ ExcludeFoodIds = [], ShowAddFoodModal, ShowAddFoodModal_Day = null, ShowAddFoodModal_Dish = null }) => {
        this.setState({ ExcludeFoodIds, ShowAddFoodModal, ShowAddFoodModal_Day, ShowAddFoodModal_Dish });
    }

    onToggleShowChooseDishModal = ({ MealPlanDay = null, ShowChooseDishModal }) => {
        // Load Dishes
            if (ShowChooseDishModal && !this.state.LoadedDishes_FirstTime) this.onLoadDishes();

        this.setState({ ShowChooseDishModal, ShowChooseDishModal_Day: MealPlanDay });
    }

    onToggleShowFoodUnitsModal = ({ DishIndex, FoodIndex, MealPlanDay, ShowFoodUnitsModal, Units, UnitId }) => {
        if (!ShowFoodUnitsModal) this.setState({ ShowFoodUnitsModal, ShowFoodUnitsModal_Data: {} });
        else this.setState({ ShowFoodUnitsModal, ShowFoodUnitsModal_Data: { DishIndex, FoodIndex, MealPlanDay, Units, UnitId } });
    }

    renderAddFoodModal = () => {
        var { ExcludeFoodIds, ShowAddFoodModal } = this.state;

        if (ShowAddFoodModal) {
            return (
                <AddFood
                    ExcludeFoodIds={ExcludeFoodIds}
                    IngredientsShown={[ 1, 2 ]}
                    OnAddFood={Food => this.onClickAddDishFood({ Food })}
                    OnHide={() => this.onToggleShowAddFoodModal({ ShowAddFoodModal: false })}
                    SearchFunc={this.props.ViewFoods_MealPlanBuilder}
                    Show={ShowAddFoodModal}
                />
            );
        }
    }

    renderChooseDishModal = () => {
        var { ShowChooseDishModal } = this.state;

        if (ShowChooseDishModal) {
            return (
                <Info
                    BottomButton="cancel"
                    BottomButtonOnClick={() => this.onToggleShowChooseDishModal({ ShowChooseDishModal: false })}
                    InformationRenderer={this.renderDishesList}
                    OnHide={() => this.onToggleShowChooseDishModal({ ShowChooseDishModal: false })}
                    Show={ShowChooseDishModal}
                />
            );
        }
    }

    renderDay = ({ DayIndex, MealPlanDay }) => {
        var DayNumber = MealPlanDay.split('_')[1];
        var Dishes = this.state.MealPlan[MealPlanDay].Dishes;

        return (
            <Styles.DayContainer className="DayContainer" key={DayIndex}>
                <Styles.DayTitleContainer className="DayTitleContainer">
                    <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={`Day ${DayNumber}`} />
                </Styles.DayTitleContainer>

                <Styles.DishContainer className="DishContainer">
                    <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onToggleShowChooseDishModal({ MealPlanDay, ShowChooseDishModal: true })} TextAlign="left" Text="Add dish?" />
                    <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onAddLoggedFood({ MealPlanDay })} TextAlign="left" Text="Add logged food?" />
                </Styles.DishContainer>

                {
                    !!Dishes.length &&
                    <>
                        <Spacer Size="extra-small" />

                        <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={`Daily Calories: ${this.state.MealPlan[MealPlanDay].NutritionProgress[this.state.NutritionProgressIndex_Calories].Value} kcal`} />

                        <Spacer Size="medium" />

                        <Styles.DayDishesContainer className="DayDishesContainer">
                            {
                                Dishes.map((Dish, DishIndex) => this.renderDish({ Dish, DishIndex, MealPlanDay }))
                            }
                        </Styles.DayDishesContainer>   
                    </>
                }
            </Styles.DayContainer>
        )
    }

    renderDays = () => {
        var { MealPlan, NumberDays } = this.state;

        return (
            <Styles.DaysParentContainer className="DaysParentContainer">
                <Styles.StyledInput
                    FontSize="medium-1"
                    Label="Number of days"
                    OnBlur={this.onBlurNumberDays}
                    OnChange={this.onChangeNumberDays}
                    Placeholder="7"
                    Size="medium"
                    Step={1}
                    Type="number"
                    Value={NumberDays}
                />

                {
                    !!+NumberDays &&
                    <>
                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text="Meal Plan" />

                        <Spacer Size="medium" />

                        <Styles.DaysContainer className="DaysContainer" NumberDays={NumberDays}>
                            {
                                Object.keys(MealPlan).map((MealPlanDay, DayIndex) => this.renderDay({ DayIndex, MealPlanDay }))
                            }
                        </Styles.DaysContainer>   
                    </>
                }
            </Styles.DaysParentContainer>
        );
    }

    renderDemoUser = () => {
        var { DemoUser } = this.state;

        return (
            <Styles.UserContainer className="UserContainer">
                <HeaderSAYT OnClickSearchResult={this.onSelectDemoUser} />

                <Spacer Size="small" />

                {
                    !!Object.keys(DemoUser).length &&
                    <Styles.DemoUserContainer className="DemoUserContainer">
                        <Styles.ProfilePhotoContainer className="ProfilePhotoContainer">
                            <ProfilePhoto Sex={DemoUser.Sex || 'Male'} Size="small-3" Source={DemoUser.ProfilePhotoUrl || ''} />
                        </Styles.ProfilePhotoContainer>

                        <PageText FontFamily="medium" FontSize="large" NoMargin Text={DemoUser.DisplayName} />                      
                    </Styles.DemoUserContainer>
                }
            </Styles.UserContainer>
        );
    }

    renderDish = ({ Dish, DishIndex, MealPlanDay }) => {
        return (
            <Styles.DishContainer className="DishContainer" key={DishIndex}>
                {this.renderDishHeader({ Dish })}

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`Dish Calories: ${Dish.NutritionProgress[this.state.NutritionProgressIndex_Calories].Value} kcal`} />

                <Spacer Size="extra-small" />

                {/* <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`Edited? ${Dish.Touched ? 'yes' : 'no'}`} />

                <Spacer Size="extra-small" /> */}

                {
                    Dish.DishType === 'Dish' && !Dish.Touched &&
                    <>
                        <Styles.StyledInput
                            FontSize="small"
                            Label="Scale"
                            OnBlur={() => this.onBlurDishScale({ DishIndex, MealPlanDay })}
                            OnChange={event => this.onChangeDishScale({ event, DishIndex, MealPlanDay })}
                            Placeholder="1"
                            Size="small"
                            Step={1}
                            Type="number"
                            Value={Dish.Scale}
                        />

                        <Spacer Size="extra-small" />
                    </>
                }

                <Styles.DishFoodsContainer className="DishFoodsContainer">
                    {
                        Dish.ShowFoods ?
                        <>
                            <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onClickToggleShowDishFoods({ DishIndex, MealPlanDay, ShowFoods: false })} TextAlign="left" Text="Hide food?" />

                            <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onClickDeleteDish({ DishIndex, MealPlanDay })} TextAlign="left" Text="Delete dish?" />

                            {this.renderIngredients({ DishFoods: Dish.Foods, DishIndex, MealPlanDay })}
                        </>
                    :
                        <Styles.DishFoodHiddenContainer className="DishFoodHiddenContainer">
                            <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onClickToggleShowDishFoods({ DishIndex, MealPlanDay, ShowFoods: true })} TextAlign="left" Text="Show food?" />

                            <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onClickDeleteDish({ DishIndex, MealPlanDay })} TextAlign="left" Text="Delete dish?" />
                        </Styles.DishFoodHiddenContainer>
                    }
                </Styles.DishFoodsContainer>
            </Styles.DishContainer>
        );
    }

    renderDishesList = () => {
        var { t } = this.props;
        var { Device, DishList, DishList_TotalRecords, IsMoreDishes, TryingSearchDishes } = this.props;
        var { DishName_SearchValue, LoadedDishes_FirstTime, Dishes_NewSearch, Dishes_PageNo, Dishes_PageSize } = this.state;

        var IsFlexed = Device === 'laptop' ? 6 : Device === 'ipad' ? 3 : Device === 'mobile' ? 3 : 2;
        var ShowRating = true;

        return (
            <>
                <Styles.StyledSearchInput
                    FontSize="medium-2"
                    NoLabel
                    OnChange={this.onChangeSearch_DishName}
                    OnKeyPress={this.onKeyPress_DishName}
                    Placeholder={t('search_with_first_char_uppercase_dots')}
                    Size="medium"
                    Type="text"
                    Value={DishName_SearchValue}
                />

                <Styles.StyledLoadMore
                    ActiveSearch={TryingSearchDishes}
                    CompletedFirstSearch={LoadedDishes_FirstTime}
                    IsFlexed={IsFlexed}
                    IsLoading={TryingSearchDishes}
                    ItemDescriptionType={t('ptadmin_dishes_plural').toLowerCase()}
                    ItemHeight={145}
                    ItemId="DishId"
                    ItemName="DishName"
                    ItemProps={{ SelectDish: Dish => this.onSelectDish(Dish), ShowRating, ViewDish: Dish => this.onSelectDish(Dish) }}
                    ItemRenderer={DishListItem}
                    Items={DishList}
                    HasMoreItems={IsMoreDishes}
                    LoadMore={() => this.onLoadDishes(true)}
                    NewSearch={Dishes_NewSearch}
                    NoItemsText={t('search_noresults')}
                    PageNo={Dishes_PageNo}
                    PageSize={Dishes_PageSize}
                    TotalRecords={DishList_TotalRecords}
                />
            </>
        );
    }

    renderDishHeader = ({ Dish }) => {
        if (Dish.DishType === 'LoggedFood') {
            return (
                <Styles.DishHeaderContainer className="DishHeaderContainer">
                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text="Logged food" />
                </Styles.DishHeaderContainer>
            );
        }

        return (
            <Styles.DishHeaderContainer className="DishHeaderContainer">
                <Styles.DishImage ImageSrc={Dish.DishUrl} />

                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={Dish.DishName} />
            </Styles.DishHeaderContainer>
        );
    }

    renderFoodUnitsModal = () => {
        var { ShowFoodUnitsModal, ShowFoodUnitsModal_Data: { DishIndex, FoodIndex, MealPlanDay, Units, UnitId } } = this.state;

        if (ShowFoodUnitsModal) {
            return (
                <FoodUnits
                    FoodUnits={Units}
                    OnHide={() => this.onToggleShowFoodUnitsModal({ ShowFoodUnitsModal: false })}
                    OnSelectUnit={({ UnitId }) => this.onSelectFoodUnit({ DishIndex, FoodIndex, MealPlanDay, UnitId })}
                    SelectedUnitId={+UnitId}
                    Show={ShowFoodUnitsModal}
                />
            );
        }
    }

    renderIngredients = ({ DishFoods, DishIndex, MealPlanDay }) => {
        var { t } = this.props;

        return (
            <Styles.IngredientsContainer>
                {/* <PageText FontFamily="medium" FontSize="medium-3" Text={t('ingredients')} /> */}
                <Styles.IngredientListContainer>
                    {
                        DishFoods.map(({ FoodId, FoodName, Quantity, UnitId, UnitName, Units }, FoodIndex) => {
                            return (
                                <Styles.IngredientContainer key={FoodId}>
                                    <Styles.IngredientNameStyled FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={FoodName} TextAlign="left" />
                                    <Styles.IngredientOptionsContainer>
                                        <Styles.IngredientQuantityStyledInput
                                            FontFamily="semibold"
                                            NoLabel
                                            NoMargin
                                            OnBlur={() => this.onBlurDishFoodQuantity({ DishIndex, FoodIndex, MealPlanDay })}
                                            OnChange={event => this.onChangeDishFoodQuantity({ event, DishIndex, FoodIndex, MealPlanDay })}
                                            Type="number"
                                            Value={Quantity}
                                        />
                                        <Styles.IngredientUnit FontSize="medium-1" NoMargin OnClick={() => this.onToggleShowFoodUnitsModal({ DishIndex, FoodIndex, MealPlanDay, ShowFoodUnitsModal: true, Units, UnitId })} OnClickTarget="container" Text={UnitName} />
                                        <Styles.DeleteIcon onClick={() => this.onDeleteDishFood({ DishIndex, FoodIndex, MealPlanDay })}>X</Styles.DeleteIcon>
                                    </Styles.IngredientOptionsContainer>
                                </Styles.IngredientContainer>
                            );
                        })
                    }
                    <ButtonGroup
                        Buttons={[
                            { Icon: Plus, IconPosition: 'left', OnClick: () => this.onToggleShowAddFoodModal_Helper({ DishIndex, MealPlanDay }), Title: `${t('MealPlan_Add_ingredient')}`}
                        ]}
                    />
                </Styles.IngredientListContainer>
            </Styles.IngredientsContainer>
        );
    }

    renderNutrient = Nutrient => {
        var { LowerLimit, NutrientId, NutrientName, Unit, UpperLimit, Value, WithinLimits } = Nutrient;

        return (
            <Styles.NutrientRow className="NutrientRow" key={NutrientId}>
                <Styles.NutrientCell className="NutrientCell">
                    <PageText FontFamily="medium" FontSize="medium" NoMargin Text={NutrientName} />
                </Styles.NutrientCell>
                <Styles.NutrientCell className="NutrientCell">
                    <PageText FontFamily="medium" FontSize="medium" NoMargin Text={Unit} />
                </Styles.NutrientCell>
                <Styles.NutrientCell className="NutrientCell">
                    <PageText FontFamily="medium" FontSize="medium" NoMargin Text={`${Value}`} />
                </Styles.NutrientCell>
                <Styles.NutrientCell className="NutrientCell">
                    <PageText FontFamily="medium" FontSize="medium" NoMargin Text={LowerLimit ? `${LowerLimit}` : ''} />
                </Styles.NutrientCell>
                <Styles.NutrientCell className="NutrientCell">
                    <PageText FontFamily="medium" FontSize="medium" NoMargin Text={UpperLimit ? `${UpperLimit}` : ''} />
                </Styles.NutrientCell>
                <Styles.NutrientCell className="NutrientCell" WithinLimits={WithinLimits}>
                    <PageText FontFamily="medium" FontSize="medium" NoMargin Text={`${WithinLimits}`} />
                </Styles.NutrientCell>
            </Styles.NutrientRow>
        );
    }

    renderNutrients = () => {
        return (
            <Styles.NutritionProgressTableContainer className="NutritionProgressTableContainer">
                <Styles.NutrientRowHeader className="NutrientRow">
                    <Styles.NutrientCellHeader className="NutrientCellHeader">
                        <PageText FontFamily="semibold" FontSize="medium" NoMargin Text={`Nutrient Name`} />
                    </Styles.NutrientCellHeader>
                    <Styles.NutrientCellHeader className="NutrientCellHeader">
                        <PageText FontFamily="semibold" FontSize="medium" NoMargin Text={`Unit`} />
                    </Styles.NutrientCellHeader>
                    <Styles.NutrientCellHeader className="NutrientCellHeader">
                        <PageText FontFamily="semibold" FontSize="medium" NoMargin Text={'Current Value'} />
                    </Styles.NutrientCellHeader>
                    <Styles.NutrientCellHeader className="NutrientCellHeader">
                        <PageText FontFamily="semibold" FontSize="medium" NoMargin Text={'Lower Limit'} />
                    </Styles.NutrientCellHeader>
                    <Styles.NutrientCellHeader className="NutrientCellHeader">
                        <PageText FontFamily="semibold" FontSize="medium" NoMargin Text={'Upper Limit'} />
                    </Styles.NutrientCellHeader>
                    <Styles.NutrientCellHeader className="NutrientCellHeader">
                        <PageText FontFamily="semibold" FontSize="medium" NoMargin Text={'Status'} />
                    </Styles.NutrientCellHeader>
                </Styles.NutrientRowHeader>

                {
                    this.state.NutritionProgress.map(Nutrient => {
                        return this.renderNutrient(Nutrient);
                    })
                }
            </Styles.NutritionProgressTableContainer>
        );
    }

    renderSaveButton = () => {
        var { t } = this.props;

        var { DemoUser: { UserId }, MealPlan, StartDate } = this.state;

        var Disabled = false;
        if (!UserId) Disabled = true;
        if (!Object.keys(MealPlan).length) Disabled = true;
        if (!StartDate) Disabled = true;

        return (
            <ButtonGroup
                Buttons={[{ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled, OnClick: this.onAddPlanToUser, Title: t('_save') }]}
                ButtonWidth="100%"
            />
        );
    }

    renderStartDate = () => {
        var { t } = this.props;
        var { SelectedDates } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('choose_dates')} TextAlign="center" />
                    
                <Spacer Size="extra-small" />
                
                <MiniCalendar
                    // Disabled={!SelectedTemplateId}
                    // DisabledText={t('select_template_first')}
                    OnSelectDate={DayDate => this.onSelectStartDate(DayDate)}
                    SelectedDates={SelectedDates}
                />
            </>
        );
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { TryingInsertMealPlanMealPlanBuilder, TryingViewDishFoodsMealPlanBuilder, TryingViewNutritionProgress } = this.props;

        return (
            <>
                {(TryingInsertMealPlanMealPlanBuilder || TryingViewDishFoodsMealPlanBuilder || TryingViewNutritionProgress) && <Loading />}

                <Page>
                    <PageText FontFamily="medium" FontSize="large" NoMargin OnClick={() => console.log('this.state: ', this.state)} Text="Meal Plan Builder" />

                    <Spacer Size="large" />

                    <Styles.ParentLayoutContainer className="ParentLayoutContainer">
                        <Styles.MainLayoutContainer className="MainLayoutContainer">
                            {this.renderDemoUser()}

                            {
                                !!this.state.NutritionProgress.length &&
                                <>
                                    <Spacer Size="medium" />

                                    {this.renderNutrients()}

                                    <Spacer Size="medium" />

                                    {this.renderDays()}

                                    {
                                        !!Object.keys(this.state.MealPlan).length &&
                                        <>
                                            <Spacer Size="medium" />

                                            {this.renderStartDate()}
                                        </>
                                    }
                                </>
                            }

                            <Spacer Size="medium" />

                            {this.renderSaveButton()}
                        </Styles.MainLayoutContainer>
                        <Styles.SidebarLayoutContainer className="SidebarLayoutContainer">

                        </Styles.SidebarLayoutContainer>
                    </Styles.ParentLayoutContainer>
                </Page>

                {this.renderAddFoodModal()}
                {this.renderChooseDishModal()}
                {this.renderFoodUnitsModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        DishFilterSettings: state.Dishes.DishFilterSettings,
        DishList: state.Dishes.DishList,
        DishList_TotalRecords: state.Dishes.DishList_TotalRecords,
        IsMoreDishes: state.Dishes.IsMoreDishes,

        TryingGetDishFilterSettings: state.Dishes.TryingGetDishFilterSettings,
        TryingGetDishFilterSettingsError: state.Dishes.TryingGetDishFilterSettingsError,
        TryingSearchDishes: state.Dishes.TryingSearchDishes,
        TryingSearchDishesError: state.Dishes.TryingSearchDishesError,

        TryingViewNutritionProgress: state.Progress.TryingViewNutritionProgress,
        TryingViewNutritionProgressError: state.Progress.TryingViewNutritionProgressError,
        
        TryingInsertMealPlanMealPlanBuilder: state.Templates.TryingInsertMealPlanMealPlanBuilder,
        TryingInsertMealPlanMealPlanBuilderError: state.Templates.TryingInsertMealPlanMealPlanBuilderError,
        TryingViewDishFoodsMealPlanBuilder: state.Templates.TryingViewDishFoodsMealPlanBuilder,
        TryingViewDishFoodsMealPlanBuilderError: state.Templates.TryingViewDishFoodsMealPlanBuilderError
    };
};

export default withTranslation()(connect(mapStateToProps, { InsertMealPlan_MealPlanBuilder, SearchDishes, ViewFoods_MealPlanBuilder, ViewDishFoods_MealPlanBuilder, ViewNutritionProgress })(MealPlanBuilder));