import {
    TRYING_TASKS_ACTION,
    TRYING_TASKS_ACTION_SUCCESS,
    TRYING_TASKS_ACTION_ERROR
} from '../Types';

const INITIAL_STATE = {
    TryingTasksAction: false,
    TryingTasksActionError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_TASKS_ACTION:
            return { ...state, TryingTasksAction: true, TryingTasksActionError: null };
        case TRYING_TASKS_ACTION_ERROR:
            return { ...state, TryingTasksAction: false, TryingTasksActionError: action.payload };
        case TRYING_TASKS_ACTION_SUCCESS:
            return { ...state, TryingTasksAction: false, TryingTasksActionError: null }; 
        default:
            return state;
    }
};
