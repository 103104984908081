import styled from 'styled-components';
import { device } from '../../Config/device';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';

export const CircuitContainer = styled.div`
    ${
        props => props.CircuitType !== 'WorkoutExercise' &&
        `
            border: 5px solid ${props.CircuitType === 'Circuit' ? 'var(--turquoise-dark)' : 'var(--blue-royal)'};   
        `
    }
    border-radius: 10px;
`;

export const CircuitSeparator = styled.div`
    align-items: center;
    background-color: var(--${props => props.BackgroundColor});
    display: flex;
    flex-direction: column;
    height: var(--spacer-size-medium);
    justify-content: center;
`;

export const LapContainer = styled.div`
    align-items: center;
    border: 2px solid ${props => props.HasBorder ? 'var(--blue-astronaut)' : 'var(--white-concrete)'};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

    @media ${device.minwidth.laptop} {
        display: grid;
        grid-template-columns: 1fr 8.5fr 0.5fr;
    }
`;

export const LapDetailsContainer = styled.div`
    width: 100%;
`;

export const LapFooterContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    display: flex;
    height: 100%;
    justify-content: center;
`;

export const LapHeaderContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    display: flex;
    flex-direction: row;
    padding: 5px;
    width: 100%;

    @media ${device.minwidth.laptop} {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }
`;

export const LapHeaderContainerLeft = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;

    @media ${device.minwidth.laptop} {
        justify-content: center
        width: 100%;
    }
`;

export const StyledHeaderButtonGroup = styled(ButtonGroup)`

    @media ${device.minwidth.ipad} {
        > div {
            max-width: ${props => props.Buttons.length === 2 ? '50%' : '33%'};
            white-space: nowrap;
    
            > div {
                > div {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
`;

export const TrainerNoteContainer = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    padding: 5px 10px;
`;

export const WorkoutExercisesContainer = styled.div``;

export const WorkoutLapsContainer = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    flex-direction: column;
    justify-content: space-between;
`;