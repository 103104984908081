import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types';

import { SecondsToTime } from '../../../Functions';

import * as styles from './styles';

import PageText from '../../Text/PageText';

class HeartRateZoneChart extends PureComponent {
    render() {
        var { t } = this.props;
        var { Zones } = this.props;

        return (
            <>
                <styles.ZonesContainer className="ZonesContainer">
                {
                        Zones.map(( Zone, ZoneIndex) => {
                            var { lowerBound, percentage, seconds, upperBound, zoneId } = Zone;

                            var BoundText = '';
                            if (zoneId === 5) BoundText = `${lowerBound}+`
                            else BoundText = `${lowerBound} - ${upperBound}`;

                            BoundText += ` ${t('heart_rate_bpm')} - ${t(`heart_rate_bounds_description_zone_${zoneId}`).toLowerCase()}`;

                            return (
                                <styles.ZoneContainer className="ZoneContainer" key={ZoneIndex}>
                                    <styles.ZoneBarAreaContainer className="ZoneBarAreaContainer">
                                        <styles.ZoneBarAreaHeader className="ZoneBarAreaHeader">
                                            <PageText FontFamily="medium" FontSize="small" NoMargin Text={`${t('heart_rate_zone')} ${zoneId}`} TextAlign="center" />
                                            <PageText FontFamily="medium-italic" FontSize="small" NoMargin Text={BoundText} TextAlign="center" />
                                        </styles.ZoneBarAreaHeader>

                                        <styles.BarLineContainer>
                                            <styles.BarChild Color={`--heart-rate-zone-${zoneId}`} Percentage={percentage} />
                                        </styles.BarLineContainer>
                                    </styles.ZoneBarAreaContainer>
                                    <PageText FontFamily="medium" FontSize="small" NoMargin Text={SecondsToTime(seconds)} TextAlign="center" />
                                    <PageText FontFamily="medium" FontSize="small" NoMargin Text={`${percentage}%`} TextAlign="center" />
                                </styles.ZoneContainer>
                            );
                        })
                    }
                </styles.ZonesContainer>
            </>
        )
    }
}

HeartRateZoneChart.propTypes = {
    // AllTime: PropTypes.bool,
    // EndDate: PropTypes.string,
    // Goals: PropTypes.array.isRequired,
    // Increment: PropTypes.number.isRequired,
    // IsTime: PropTypes.bool,
    // Maximum: PropTypes.number.isRequired,
    // Minimum: PropTypes.number.isRequired,
    // Measurements: PropTypes.array.isRequired,
    // StartDate: PropTypes.string,
    // TimePeriod: PropTypes.string,
    // Units: PropTypes.string.isRequired
}

export default HeartRateZoneChart;