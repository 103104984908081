import React from 'react';
// import styled from 'styled-components';

import BodyMuscles from './Components/BodyMuscles';

class BodyMusclesTest extends React.Component {
    render() {
        return <BodyMuscles />
    }
}

export default BodyMusclesTest;