import React from 'react';

import * as Styles from './styles';

import ButtonGroup from '../Buttons/ButtonGroup';
import PageText from '../Text/PageText';
import Spacer from '../Spacer';

import { ReactComponent as CheckmarkSVG } from '../../Assets/SVG/Checkmark.svg';
import { ReactComponent as XSVG } from '../../Assets/SVG/X.svg';

class AddRemoveTagsPopoverComponent extends React.Component {
    state = {
        Loading: true,
        SearchName: '',
        SelectedTags: [],
        Tags: []
    }

    componentDidMount() {
        var { ActionType, TagIds } = this.props;

        this.props.GetAllTagsForAddingRemoving({ ActionType, TagIds }).then(data => {
            this.setState({ Loading: false, Tags: data });
        })
    }
    
    onChangeSearchName = event => {
        this.setState({ SearchName: event.target.value });
    }

    onSaveTagUpdates = () => {
        this.setState({ Loading: true });
        this.props.UpdateTags({ ActionType: this.props.ActionType, TagIds: this.state.SelectedTags.join(',') });
    }

    onSelectTag = ({ SelectedTagIndex, TagId }) => {
        if (SelectedTagIndex === -1) this.setState({ SelectedTags: [ ...this.state.SelectedTags, +TagId ] });
        else this.setState({ SelectedTags: [ ...this.state.SelectedTags.slice(0, SelectedTagIndex), ...this.state.SelectedTags.slice(SelectedTagIndex + 1) ] });
    }

    render() {
        var { t } = this.props;
        var { ActionType } = this.props;
        var { Loading, SearchName, SelectedTags, Tags } = this.state;

        var FilteredTags = [ ...Tags ];
        if (SearchName !== '') FilteredTags = Tags.filter(({ TagName }) => TagName.toLowerCase().includes(SearchName.toLowerCase()));

        return (
            <Styles.AddRemoveTagsPopoverContentContainer className="AddRemoveTagsPopoverContentContainer">
                <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin Text={ActionType === 'Adding' ? t('_add_tags_to_the_selected_content') : t('_remove_the_selected_content_from')} TextAlign="left" />

                <Spacer Size="small" />

                {
                    Tags.length > 0 && 
                    <Styles.StyledSearchInput
                        FontSize="medium-1"
                        NoLabel
                        NoMargin
                        OnChange={this.onChangeSearchName}
                        Placeholder={t('_search_in_all_filters')}
                        Size="small-2"
                        Type="text"
                        Value={SearchName}
                    />
                }

                <Spacer Size="small" />

                {
                    Loading ?
                    <i className="fas fa-spinner fa-spin"></i>
                :
                    <>
                        <Styles.AddRemoveTagsPopoverTagsListContainer className="AddRemoveTagsPopoverTagsListContainer">
                            {
                                FilteredTags.map(({ TagId, TagName }) => {
                                    var SelectedTagIndex = SelectedTags.indexOf(TagId);

                                    return (
                                        <Styles.AddRemoveTagsPopoverTagContainer className="AddRemoveTagsPopoverTagContainer" key={TagId} ActionType={ActionType} onClick={() => this.onSelectTag({ SelectedTagIndex, TagId })} Selected={SelectedTagIndex !== -1}>
                                            <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={TagName} TextAlign="left" />

                                            {
                                                SelectedTagIndex !== -1 ?
                                                ActionType === 'Adding' ?
                                                <CheckmarkSVG />
                                            :
                                                <XSVG />
                                            :
                                                null
                                            }
                                        </Styles.AddRemoveTagsPopoverTagContainer>
                                    );
                                })
                            }
                        </Styles.AddRemoveTagsPopoverTagsListContainer>

                        {
                            !!SelectedTags.length &&
                            <>
                                <Spacer Size="small" />
                        
                                <ButtonGroup
                                    Buttons={[{ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', OnClick: this.onSaveTagUpdates, Title: t('_save') }]}
                                    ButtonWidth="100%"
                                />
                            </>
                        }
                    </>
                }
            </Styles.AddRemoveTagsPopoverContentContainer>
        );
    }
}

export default AddRemoveTagsPopoverComponent;