import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { FreezeWorkoutForPrint, UploadToPresignedUrl, UpdatePDFLogo } from '../../Services/Actions';

import AbelModal from '../../Components/Modal';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import MultiText from '../../Components/Text/MultiText';
import Spacer from '../../Components/Spacer';
import UploadProgressComponent from '../../Components/UploadProgress';

import TestWorkout from '../../Assets/Illustrations/TestSet.png';
import Camera from '../../Assets/Icons/Camera.png';
import CrossRed from '../../Assets/Icons/X_Red.png';

import { CreateUploadToPresignedUrlPromise, getFileExtension, SecondsToTime, validateFile } from '../../Functions';

import {
  TestSetDescriptionContainer, TestSetDescriptionImage, WorkoutPdfPreviewContainer, BodyContainer, LeftArea, RightArea,
  LeftAreaItem, RightAreaItem, StyledTitle, StyledWorkoutPdfPreviewTextArea, UploadButtonsRow, UploadButtonsContainer,
  StyledMultiText, StyledImageContainer, StyledLogoImage, StyledLogoButtons, SaveLogoContainer, SaveLogoCheckmark,
  WorkoutDetailsContainer, ExerciseImageContainer, StyledExerciseImage, MusclesUsedContainer, StyledBodyMuscles, LeftAreaTop,
  StyledWorkoutInstructionsTextArea, LeftAreaBottom, WorkoutSetsContainer, WorkoutSetContainer, WorkoutSetCount, WorkoutSetDetails,
  WorkoutSetBreak, WorkoutHeading, WorkoutLapsContainer, DownloadPDFContainer
} from './styles';

import Pdf from './pdf';
import { saveAs } from 'file-saver';

class ExportWorkoutPdfModal extends Component {
  _isMounted = false;

  state = {
    Loading: true,
    isTestWorkoutPresent: null,
    testExercisesCount: 0,
    ImageTooLarge: false,
    MaxImageSize: 10000000, // 10MB
    WrongImageType: false,
    SaveLogo: false,
    WorkoutExercisesData: [],
    WorkoutLapsData: [],
    WorkoutPdfDetails: {
      MadeBy: '',
      MadeFor: '',
      MainTitle: '',
      SecondaryTitle: '',
      TrainerNote: '',
      LogoPictureUrl: null,
      LogoImageFile: null,
    },
    DownloadPdf: false,
  }

  componentDidMount() {
    this._isMounted = true;
    const { UserDetails, ClientDetails, WorkoutDetails, WorkoutExercises, WorkoutLaps } = this.props;

    var { TrainerNote, WorkoutName: MainTitle } = WorkoutDetails;


    let ClientDisplayName = `${ClientDetails.FirstName && ClientDetails.LastName ? `${ClientDetails.FirstName} ${ClientDetails.LastName}` : ClientDetails.Email}`;
    if (ClientDetails.FirstName && ClientDetails.LastName) ClientDisplayName = `${ClientDetails.FirstName} ${ClientDetails.LastName}`;

    let TrainerDisplayName = `@${UserDetails.UserName}`;
    if (UserDetails.FirstName && UserDetails.LastName) TrainerDisplayName = `${UserDetails.FirstName} ${UserDetails.LastName}`;

    let exercisesData = [];

    this.setState({ WorkoutPdfDetails: { ...this.state.WorkoutPdfDetails, ClientDisplayName, MadeBy: TrainerDisplayName, MadeFor: ClientDisplayName, MainTitle, LogoPictureUrl: UserDetails.PDFLogo, TrainerNote } }, () => {
      if (WorkoutDetails && WorkoutDetails.WorkoutType === 1) {
        let countTestExercise = 0;
        // eslint-disable-next-line
        WorkoutExercises && WorkoutExercises.map(subWorkoutExercise => {
          subWorkoutExercise.WorkoutExercises.map(exercise => {
            // Checking is test exercise present
            if (exercise.TestSets.length > 0) {
              countTestExercise += exercise.TestSets.length
              this.setState({ isTestWorkoutPresent: true, testExercisesCount: countTestExercise })
            } else {
              exercisesData.push(exercise);
            }
            return countTestExercise
          })
        })

        this.setState({ WorkoutExercisesData: exercisesData });
      } else {
        exercisesData = {
          ActiveType: WorkoutLaps.ActiveType,
          Laps: []
        };

        let countTestExercise = 0;
        if (WorkoutLaps.TestLaps.length > 0) {
          countTestExercise += WorkoutLaps.TestLaps.length;

          this.setState({ isTestWorkoutPresent: true, testExercisesCount: countTestExercise })
        } else {
          // eslint-disable-next-line
          WorkoutLaps && WorkoutLaps.Laps.map(LapsData => {
            exercisesData.Laps.push(LapsData);
          })
        }

        this.setState({ WorkoutLapsData: exercisesData })
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  exportPdfFromBlob = async GeneratedPDF => {
    if (!!GeneratedPDF) this.onSavePDF();
    else this.onGeneratePDF();
  }

  onGeneratePDF = () => {
    this.setState({ DownloadPdf: true });
  }

  onSavePDF = async () => {
    this.setState({ DownloadPdf: false });
    const blobUrl = localStorage.getItem("blob");
    const blob = await fetch(blobUrl).then(r => r.blob());

    var ClientDisplayName_NoWhiteSpaces = this.state.WorkoutPdfDetails.ClientDisplayName.replace(/\s+/g, '');
    var WorkoutName_NoWhiteSpaces = this.state.WorkoutPdfDetails.MainTitle.replace(/[\s.&!%^&*()=+|:<>?,]/g, '');

    saveAs(blob, `${ClientDisplayName_NoWhiteSpaces}_${WorkoutName_NoWhiteSpaces}`);
    localStorage.removeItem("blob");

    this.props.OnHide();
  }

  getInstructionsData(exercise) {
    if (this.state.WorkoutExercisesData) {
      const data = this.state.WorkoutExercisesData.filter((Exercise) => (Exercise.ExerciseId === exercise.ExerciseId))
      return data[0] ? data[0].ExerciseInstructions : "";
    } else return ""
  }

  onChangeWorkoutDetailText = ({ event, TextName }) => {
    this.setState({ WorkoutPdfDetails: { ...this.state.WorkoutPdfDetails, [TextName]: event.target.value } })
  }

  onChangeWorkoutInstructions = ({ event, exercise }) => {
    const modifiedWorkoutData = this.state.WorkoutExercisesData.map((Exercise) => {
      if (Exercise.ExerciseId === exercise.ExerciseId) {
        return { ...Exercise, ExerciseInstructions: event.target.value }
      }
      return Exercise
    })
    this.setState({ WorkoutExercisesData: modifiedWorkoutData })
  }

  onDeleteImage = () => {
    this.setState({
      ImageTooLarge: false,
      WrongImageType: false,
      SaveLogo: false,
      WorkoutPdfDetails: {
        ...this.state.WorkoutPdfDetails,
        LogoPictureUrl: null,
        LogoImageFile: null,
      }
    });
  }

  onDownloadPDF = async () => {
    var { WorkoutId } = this.props;
    var { LogoImageFile } = this.state.WorkoutPdfDetails;
    const { SaveLogo } = this.state

    try {
      this.props.FreezeWorkoutForPrint({ IsFrozen: 1, PDFLogo: SaveLogo && LogoImageFile ? { ContentType: LogoImageFile.type, FileName: LogoImageFile.name } : null, WorkoutId }).then(({ PresignedFormData_Image }) => {
        this.props.onToggleWorkoutFrozenStatus(true);
        if (LogoImageFile && PresignedFormData_Image) {
          var Promises = [];
          var UploadFunction = this.props.UploadToPresignedUrl;

          // Update UserDetails With New PDF Logo
            if (!!SaveLogo) {
              const { url, fields: { Key } } = PresignedFormData_Image

              const PDFLogo = url.concat('/', Key);
              this.props.UpdatePDFLogo(PDFLogo);
            }

          if (PresignedFormData_Image) Promises.push(CreateUploadToPresignedUrlPromise({ ContentType: 'PDFLogo', File: LogoImageFile, PresignedFormData: PresignedFormData_Image, UploadFunction }));

          Promise.all(Promises).then(() => this.exportPdfFromBlob());
        } else this.exportPdfFromBlob();
      })
    } catch (e) {
      console.log('Error ========>>>', e)
    }
  }

  onSelectLogo = event => {
    if (event.target.files.length) {
      var LogoImageFile = event.target.files[0];
      var FileExtension = getFileExtension(LogoImageFile.name);

      if (validateFile({ FileExtension, FileType: 'Images' })) {
        if (LogoImageFile.size <= this.state.MaxImageSize) {
          this.setState({ WorkoutPdfDetails: { ...this.state.WorkoutPdfDetails, LogoImageFile, LogoPictureUrl: URL.createObjectURL(LogoImageFile) }, ImageTooLarge: false, WrongImageType: false });
        } else {
          this.setState({ ImageTooLarge: true, WrongImageType: false });
        }
      } else this.setState({ ImageTooLarge: false, WrongImageType: true });
    }
  }

  onToggleSaveLogo = () => {
    this.setState({ SaveLogo: !this.state.SaveLogo })
  }

  renderExportPdfModal() {
    const { t, Device, UploadProgress } = this.props;
    const { ImageTooLarge, WrongImageType, WorkoutPdfDetails } = this.state;

    const Buttons = [
      { BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: Camera, IconPosition: 'left', IsInput: true, InputProps: { accept: "image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg", id: "UploadLogo", type: "file", onChange: this.onSelectLogo }, Title: t('Upload') },
    ];

    return (
      <>
        <WorkoutPdfPreviewContainer className='WorkoutPdfPreviewContainer'>
          <PageText FontFamily="medium" FontSize="large" NoMargin Text={t('preview')} JustifyContent='flex-start' TextAlign='left' />
          <Spacer Size="medium" />
          <BodyContainer className='BodyContainer'>
            <LeftArea className='LeftArea'>
              <LeftAreaItem className='LeftAreaItem'>
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('pdfexport_preview_main_title')} JustifyContent='flex-start' TextAlign='left' />
                <StyledTitle
                  FontFamily="semibold"
                  FontSize="medium-1"
                  NoLabel
                  OnChange={event => this.onChangeWorkoutDetailText({ event, TextName: 'MainTitle' })}
                  Placeholder={t('fill_in')}
                  Size="medium"
                  TextAlign='left'
                  Type="text"
                  Value={WorkoutPdfDetails.MainTitle ? WorkoutPdfDetails.MainTitle : ''}
                />
              </LeftAreaItem>

              <Spacer Size="small" />

              <LeftAreaItem className='LeftAreaItem'>
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('pdfexport_preview_secondary_title')} JustifyContent='flex-start' TextAlign='left' />
                <StyledTitle
                  className="StyledTitle"
                  FontFamily="medium"
                  FontSize="medium-1"
                  NoLabel
                  OnChange={event => this.onChangeWorkoutDetailText({ event, TextName: 'SecondaryTitle' })}
                  Placeholder={t('fill_in')}
                  Size="medium"
                  TextAlign='left'
                  Type="text"
                  Value={WorkoutPdfDetails.SecondaryTitle ? WorkoutPdfDetails.SecondaryTitle : ''}
                />
              </LeftAreaItem>

              <Spacer Size="small" />

              <LeftAreaItem className='LeftAreaItem'>
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('pdfexport_preview_note_to_session')} JustifyContent='flex-start' TextAlign='left' />
                <StyledWorkoutPdfPreviewTextArea
                  ControlledComponent
                  OnChange={event => this.onChangeWorkoutDetailText({ event, TextName: 'TrainerNote' })}
                  FontFamily="medium"
                  FontSize="medium-1"
                  NumRows={4}
                  MaxRows={8}
                  Placeholder={t('fill_in')}
                  Value={WorkoutPdfDetails.TrainerNote ? WorkoutPdfDetails.TrainerNote : ''}
                />
              </LeftAreaItem>
            </LeftArea>

            <RightArea className='RightArea'>
              <RightAreaItem className='RightAreaItem'>
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('upload_picture_or_logo')} JustifyContent='flex-start' TextAlign='left' />

                {!WorkoutPdfDetails.LogoPictureUrl && <UploadButtonsRow>
                  <UploadButtonsContainer
                    Buttons={Buttons}
                    ButtonWidth="fit-content"
                    ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                    NotTouching
                  />
                </UploadButtonsRow>}

                {
                  WorkoutPdfDetails.LogoPictureUrl &&
                  <>
                    {this.renderLogoPreview()}

                    <Spacer Size="extra-extra-small" />

                    {
                      (ImageTooLarge || WrongImageType) &&
                      <>
                        <Spacer Size="small" />

                        <PageText
                          FontColor="red-bittersweet"
                          FontFamily="semibold-italic"
                          FontSize="medium-1"
                          Text={t(ImageTooLarge ? 'media_wrongsize' : 'media_wrongtype', { AllowedTypes: '.jpg, .png, .jpeg', MaxSize: '10MB', Type: 'Image' })}
                          TextAlign="center"
                        />
                      </>
                    }
                  </>
                }

                <StyledMultiText
                  TextAlign="left"
                  Texts={[
                    { FontFamily: 'semibold', FontSize: 'small', Text: `${t('max_upload_size')}:` },
                    { FontFamily: 'medium', FontSize: 'small', Text: '10MB' },
                  ]}
                />

              </RightAreaItem>

              <Spacer Size="small" />

              <RightAreaItem className='RightAreaItem'>
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={`${t('made_for')}:`} JustifyContent='flex-start' TextAlign='left' />
                <StyledTitle
                  FontFamily="semibold"
                  FontSize="medium-1"
                  OnChange={event => this.onChangeWorkoutDetailText({ event, TextName: 'MadeFor' })}
                  NoLabel
                  Size="medium"
                  Type="text"
                  Value={WorkoutPdfDetails.MadeFor ? WorkoutPdfDetails.MadeFor : ''}
                  TextAlign='left'
                />
              </RightAreaItem>

              <Spacer Size="small" />

              <RightAreaItem className='RightAreaItem'>
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={`${t('made_by')}:`} JustifyContent='flex-start' TextAlign='left' />
                
                <StyledTitle
                  FontFamily="semibold"
                  FontSize="medium-1"
                  OnChange={event => this.onChangeWorkoutDetailText({ event, TextName: 'MadeBy' })}
                  NoLabel
                  Size="medium"
                  Type="text"
                  Value={WorkoutPdfDetails.MadeBy ? WorkoutPdfDetails.MadeBy : ''}
                  TextAlign='left'
                />
              </RightAreaItem>

            </RightArea>
          </BodyContainer>

          <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('the_session')} JustifyContent='flex-start' TextAlign='left' />

          <Spacer Size="small" />

          {this.renderWorkoutDetails()}
        </WorkoutPdfPreviewContainer>

        {
          UploadProgress.PDFLogo && Object.entries(UploadProgress.PDFLogo).length > 0 &&
          <>
            <Spacer Size="medium" />

            <UploadProgressComponent UploadingItems={UploadProgress.PDFLogo} />
          </>
        }
      </>
    )
  }

  renderLogoPreview() {
    const { t } = this.props;
    const { Device } = this.props;
    const { SaveLogo, WorkoutPdfDetails } = this.state;
    
    let Buttons = [];

    if (WorkoutPdfDetails.LogoImageFile || WorkoutPdfDetails.LogoPictureUrl) {
      Buttons = [
        { BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: Camera, IconOnly: true, IconPosition: 'left', IsInput: true, InputProps: { accept: "image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg", id: "UploadLogo", type: "file", onChange: this.onSelectLogo } },
        { BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: CrossRed, IconOnly: true, IconPosition: 'left', OnClick: this.onDeleteImage },
      ]
    } else Buttons = [{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: Camera, IconPosition: 'left', IsInput: true, InputProps: { accept: "image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg", id: "UploadLogo", type: "file", onChange: this.onSelectLogo }, Title: t('Upload') }]

    return (
      <>
        <StyledImageContainer className="StyledImageContainer">
          {
           WorkoutPdfDetails.LogoPictureUrl &&
            <StyledLogoImage
              ImageSrc={WorkoutPdfDetails.LogoPictureUrl}
              SelfLoading
              className="StyledLogoImage"
            />
          }

          <StyledLogoButtons
            Buttons={Buttons}
            ButtonWidth="fit-content"
            ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
            NotTouching
            OwnRows
            className="StyledLogoButtons"
            NeedsMargin={!!WorkoutPdfDetails.LogoPictureUrl}
          />

        </StyledImageContainer>

        {
          WorkoutPdfDetails.LogoImageFile && 
          <SaveLogoContainer>
            <SaveLogoCheckmark onClick={this.onToggleSaveLogo} Selected={SaveLogo} />
            <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" Text={t('save_image_for_later')} TextAlign="left" />
          </SaveLogoContainer>
        }
      </>
    );
  }

  renderMusclesUsed = ({ ExerciseDetail }) => {
    const { Device } = this.props;

    const { UsedMuscles: { PrimaryMuscleIds, SecondaryMuscleIds, TertiaryMuscleIds } } = ExerciseDetail;
    return (
      <MusclesUsedContainer className="MusclesUsedContainer" Device={Device}>
        <StyledBodyMuscles
          PrimaryMuscleIds={PrimaryMuscleIds}
          SecondaryMuscleIds={SecondaryMuscleIds}
          TertiaryMuscleIds={TertiaryMuscleIds}
          Device={Device}
        />
      </MusclesUsedContainer>
    );
  }

  renderWorkoutDetails() {
    const { t, WorkoutDetails: { WorkoutType }, WorkoutExercises, WorkoutLaps, Device } = this.props;
    const StrengthWorkout = WorkoutType === 1;
    const EnduranceWorkout = WorkoutType === 2;

    return (
      <>
        {StrengthWorkout && (
          WorkoutExercises && WorkoutExercises.map((subWorkoutExercise) => (
            subWorkoutExercise.WorkoutExercises.map((exercise) => (

              <div key={exercise.ExerciseId}>
                <WorkoutDetailsContainer className='WorkoutDetailsContainer'>

                  <LeftArea className='LeftArea'>
                    <LeftAreaTop className='LeftAreaTop'>
                      <ExerciseImageContainer className="ExerciseImageContainer">
                        <StyledExerciseImage ImageSrc={exercise.ExerciseImage} SelfLoading Device={Device} />
                      </ExerciseImageContainer>

                      {/* Muscles Used */}
                      {this.renderMusclesUsed({ ExerciseDetail: exercise })}

                    </LeftAreaTop>
                    <LeftAreaBottom className='LeftAreaBottom'>
                      {/* Exercise Set Details */}
                      {this.renderWorkoutSets({ ExerciseDetail: exercise })}
                    </LeftAreaBottom>
                  </LeftArea>

                  <RightArea className='RightArea'>
                    <WorkoutHeading className='WorkoutHeading'>
                      <PageText FontFamily="bold" FontSize="large" Text={exercise.ExerciseName} JustifyContent='flex-start' TextAlign='left' />
                    </WorkoutHeading>

                    <StyledWorkoutInstructionsTextArea
                      ControlledComponent
                      OnChange={event => this.onChangeWorkoutInstructions({ event, exercise })}
                      FontFamily="medium"
                      FontSize="medium-1"
                      NumRows={16}
                      MaxRows={20}
                      Placeholder={t('fill_in')}
                      Value={this.getInstructionsData(exercise)}
                    />

                  </RightArea>

                </WorkoutDetailsContainer>
                <Spacer Size="small" />
              </div>
            ))
          ))
        )}

        {EnduranceWorkout && (WorkoutLaps && WorkoutLaps.Laps.map(LapsData => (
          LapsData.Type === 'Active' ?
            (LapsData.Laps.map(laps => (
              <div key={laps.WorkoutLapId}>
                <WorkoutDetailsContainer className='WorkoutDetailsContainer'>
                  <WorkoutLapsContainer className='WorkoutLapsContainer'>
                    <PageText NoMargin FontFamily="bold" FontSize="medium" Text={`${t('lap_str_all_capital')} ${laps.LapNumber}`} JustifyContent='flex-start' TextAlign='left' />

                    <MultiText
                      Texts={[
                        { FontFamily: 'bold', FontSize: 'medium', Text: `${t('WorkoutPlan_endurance_active_type_header')}:` },
                        { FontFamily: 'medium', FontSize: 'medium', Text: WorkoutLaps.ActiveType === 'Distance' ? `${laps.Active} ${laps.ActiveUnits}` : `${SecondsToTime(laps.Active)}` }
                      ]}
                    />

                    <MultiText
                      Texts={[
                        { FontFamily: 'bold', FontSize: 'medium', Text: `${t('Speed')}:` },
                        { FontFamily: 'medium', FontSize: 'medium', Text: `${laps.Speed ? laps.Speed : 0}` },
                        { FontFamily: 'medium', FontSize: 'medium', Text: laps.SpeedUnits },
                      ]}
                    />

                    <MultiText
                      Texts={[
                        { FontFamily: 'bold', FontSize: 'medium', Text: `${t('break')}:` },
                        { FontFamily: 'medium', FontSize: 'medium', Text: `${SecondsToTime(laps.Rest)}` },
                      ]}
                    />
                  </WorkoutLapsContainer>
                </WorkoutDetailsContainer>
                <Spacer Size="small" />
              </div>))
            ) : (LapsData.Laps.map((laps) => (
              <div key={laps.WorkoutLapId}>
                <WorkoutDetailsContainer className='WorkoutDetailsContainer' LapTypeBreak={true}>
                  <WorkoutLapsContainer className='WorkoutLapsContainer'>
                    <MultiText
                      Texts={[
                        { FontFamily: 'bold', FontSize: 'medium', FontColor: 'white', Text: `${t('break')}:` },
                        { FontFamily: 'bold', FontSize: 'medium', FontColor: 'white', Text: `${SecondsToTime(laps.BreakTime)}` },
                      ]}
                    />
                  </WorkoutLapsContainer>
                </WorkoutDetailsContainer>
                <Spacer Size="small" />
              </div>))
            )
        )))}
      </>
    );
  }

  renderWorkoutSets({ ExerciseDetail }) {
    const { t } = this.props;
    return (
      <>
        <WorkoutSetsContainer className='WorkoutSetsContainer'>

          < WorkoutSetContainer className='WorkoutSetContainer' HasWeight={ExerciseDetail.ExerciseType === 4 || ExerciseDetail.ExerciseType === 5} >

            {ExerciseDetail.ExerciseType === 1 && (
              <>
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('Set')} JustifyContent='flex-start' TextAlign='left' />
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('Reps')} JustifyContent='flex-start' TextAlign='left' />
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('Break')} JustifyContent='flex-start' TextAlign='left' />
              </>)}

            {ExerciseDetail.ExerciseType === 2 && (
              <>
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('Set')} JustifyContent='flex-start' TextAlign='left' />
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('Time')} JustifyContent='flex-start' TextAlign='left' />
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('Break')} JustifyContent='flex-start' TextAlign='left' />
              </>)}

            {ExerciseDetail.ExerciseType === 3 && (
              <>
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('Set')} JustifyContent='flex-start' TextAlign='left' />
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('Distance')} JustifyContent='flex-start' TextAlign='left' />
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('Break')} JustifyContent='flex-start' TextAlign='left' />

              </>)}

            {ExerciseDetail.ExerciseType === 4 && (
              <>
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('Set')} JustifyContent='flex-start' TextAlign='left' />
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('Reps')} JustifyContent='flex-start' TextAlign='left' />
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('Weight')} JustifyContent='flex-start' TextAlign='left' />
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('Break')} JustifyContent='flex-start' TextAlign='left' />
              </>)}

            {ExerciseDetail.ExerciseType === 5 && (
              <>
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('Set')} JustifyContent='flex-start' TextAlign='left' />
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('Time')} JustifyContent='flex-start' TextAlign='left' />
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('Weight')} JustifyContent='flex-start' TextAlign='left' />
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('Break')} JustifyContent='flex-start' TextAlign='left' />
              </>)}

          </WorkoutSetContainer>

          {ExerciseDetail.Sets.map((SetDetails, index) => (
            < WorkoutSetContainer key={SetDetails.SetId} className='WorkoutSetContainer' HasWeight={ExerciseDetail.ExerciseType === 4 || ExerciseDetail.ExerciseType === 5} >

              <WorkoutSetCount className='WorkoutSetCount'>
                <PageText FontFamily="bold" FontSize="medium-2" NoMargin FontColor={'white'} Text={`${index + 1}.`} JustifyContent='flex-start' TextAlign='left' />
              </WorkoutSetCount>

              <WorkoutSetDetails className='WorkoutSetDetails'>
                {ExerciseDetail.ExerciseType === 1 && (
                  <PageText FontFamily="bold" FontSize="medium-2" NoMargin Text={`${SetDetails.Reps}`} JustifyContent='flex-start' TextAlign='left' />
                )}
                {ExerciseDetail.ExerciseType === 2 && (
                  <PageText FontFamily="bold" FontSize="medium-2" NoMargin Text={`${SecondsToTime(SetDetails.Seconds)}`} JustifyContent='flex-start' TextAlign='left' />
                )}
                {ExerciseDetail.ExerciseType === 3 && (
                  <PageText FontFamily="bold" FontSize="medium-2" NoMargin Text={`${SetDetails.Distance} ${SetDetails.Units}`} JustifyContent='flex-start' TextAlign='left' />
                )}
                {ExerciseDetail.ExerciseType === 4 && (
                  <PageText FontFamily="bold" FontSize="medium-2" NoMargin Text={`${SetDetails.Reps}`} JustifyContent='flex-start' TextAlign='left' />
                )}
                {ExerciseDetail.ExerciseType === 5 && (
                  <PageText FontFamily="bold" FontSize="medium-2" NoMargin Text={`${SecondsToTime(SetDetails.Seconds)}`} JustifyContent='flex-start' TextAlign='left' />
                )}
              </WorkoutSetDetails>

              {(ExerciseDetail.ExerciseType === 4 || ExerciseDetail.ExerciseType === 5)
                && (
                  <WorkoutSetDetails className='WorkoutSetDetails'>
                    <PageText FontFamily="bold" FontSize="medium-2" NoMargin Text={`${SetDetails.Weight} ${SetDetails.Units}`} JustifyContent='flex-start' TextAlign='left' />
                  </WorkoutSetDetails>
                )
              }

              <WorkoutSetBreak className='WorkoutSetBreak'>
                <PageText FontFamily="bold" FontSize="medium-2" NoMargin Text={`${SecondsToTime(SetDetails.Break)}`} JustifyContent='flex-start' TextAlign='left' />
              </WorkoutSetBreak>

            </WorkoutSetContainer>
          ))}
        </WorkoutSetsContainer>
      </>
    )
  }

  render() {
    const { t } = this.props;
    const { className, OnHide, Show, Size, TextAlign, TryingFreezeWorkout, TryingAddWorkoutLap, TryingAddWorkoutExercises } = this.props;
    let { DownloadPdf, isTestWorkoutPresent, WorkoutPdfDetails, testExercisesCount } = this.state;

    return (
      <AbelModal
        className={className}
        BottomButton="submit"
        BottomButtonOnClick={isTestWorkoutPresent ? OnHide : this.onDownloadPDF}
        BottomButtonText={isTestWorkoutPresent ? t('got_it') : !!DownloadPdf ? t('generating_pdf') : t('download.pdf')}
        Disabled={!WorkoutPdfDetails.MainTitle || DownloadPdf || TryingFreezeWorkout}
        Show={Show}
        Size={Size || "large"}
        OnHide={OnHide}
        TopButton="cancel"
        TopButtonOnClick={OnHide}
      >
        {(this.props.Loading || DownloadPdf || TryingFreezeWorkout || TryingAddWorkoutLap || TryingAddWorkoutExercises) && <Loading />}

        {isTestWorkoutPresent &&
          (
            <>
              <PageText FontFamily="semibold" FontSize="medium-2" NoMargin Text={t('pdfexport_transform_testsets_title')} TextAlign={TextAlign || 'center'} />
              <Spacer Size="small" />
              <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('pdfexport_transform_testsets_subtitle', { TestExercisesCount: testExercisesCount })} />

              <TestSetDescriptionContainer className="TestSetDescriptionContainer">
                <TestSetDescriptionImage ImageSrc={TestWorkout} />
              </TestSetDescriptionContainer>
            </>
          )}

        {!isTestWorkoutPresent && (this.renderExportPdfModal())}

        <DownloadPDFContainer>
          {!isTestWorkoutPresent && this.state.WorkoutPdfDetails && this.state.DownloadPdf && <Pdf
            state={this.state}
            onGeneratedPDF={() => this.exportPdfFromBlob(true)}
          />}
        </DownloadPDFContainer>

      </AbelModal>
    )
  }
}

ExportWorkoutPdfModal.propTypes = {
  WorkoutId: PropTypes.string.isRequired,
  onToggleWorkoutFrozenStatus: PropTypes.func.isRequired,
  WorkoutDetails: PropTypes.object.isRequired,
  OnHide: PropTypes.func.isRequired,
  Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  return {
    Device: state.Window.Device,
    UserDetails: state.Auth.UserDetails,
    ClientDetails: state.Client.ClientDetails,

    ExerciseDetail: state.Exercise.ExerciseDetail,
    TryingGetExerciseDetail: state.Exercise.TryingGetExerciseDetail,
    TryingGetExerciseDetailError: state.Exercise.TryingGetExerciseDetailError,

    TryingAddWorkoutExercises: state.Workout.TryingAddWorkoutExercises,
    TryingAddWorkoutLap: state.Workout.TryingAddWorkoutLap,

    TryingFreezeWorkout: state.Workout.TryingFreezeWorkout,
    WorkoutDetails: state.Workout.WorkoutDetails,
    WorkoutExercises: state.Workout.WorkoutExercises,
    WorkoutLaps: state.Workout.WorkoutLaps,

    UploadProgress: state.UploadContent.UploadProgress,
  };
};

export default withTranslation()(connect(mapStateToProps, { FreezeWorkoutForPrint, UploadToPresignedUrl, UpdatePDFLogo })(ExportWorkoutPdfModal))

