import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearPrivateFoodSettings, InsertEditPrivateFood, SaveDataInProps, ViewCreatePrivateFoodSettings } from '../../Services/Actions';
import history from '../../history';

import { BETA_SITE_URL } from '../../Config';

import { ExampleContainer, HelpBoxContainer, MultiSelectRadioButton, NutrientImage, NutrientInput, NutrientInputContainer, NutrientContainer, NutrientsContainer, NutrientSection, OptionalLine, RequiredHeaderContainer, StyledDropdown, StyledFoodName, StyledHR, StyledInput, StyledInputLabelSideBySide, UnitListColumnContainer, UnitOptionsItem, UnitOptionsList, UnitsListContainer, UnitsListRow, WordExplanationContainer, WordExplanationsContainer } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Info from '../../Modals/Info';

import Carbs from '../../Assets/Icons/Carbs.png';
import DeleteX from '../../Assets/Icons/Delete_X.png';
import DeleteX_Gray from '../../Assets/Icons/Delete_X_Gray.png';
import Fat from '../../Assets/Icons/Fat.png';
import Plus from '../../Assets/Icons/Plus.png';
import Protein from '../../Assets/Icons/Protein.png';

// var i;
class Food extends React.Component {
    _isMounted = false;

    state = {
        AlreadySelectedUnitIds: [],
        DefaultUnit_Imperial: null,
        DefaultUnit_Metric: null,
        DisplayingFilter: null,
        DropdownSize: 0,
        FoodId: null,
        FoodMeasurements_Default: {},
        FoodMeasurements_Optional: {},
        FoodName: '',
        HoveringUnitId: null,
        Nutrients: {},
        Loading: true,
        ShowHasErrorModal: false
    };

    componentDidMount() {
        var { FoodId } = this.props.match.params;
        FoodId = +FoodId || null;

        var RedirectLink = `${BETA_SITE_URL}/nutrition-library/ingredient`;

        if (!!+FoodId) RedirectLink += `/${FoodId}`;

        window.location = RedirectLink;

        // this.props.ViewCreatePrivateFoodSettings({ FoodId }).then(() => {
        //     if (this._isMounted && !this.props.TryingGetCreatePrivateFoodSettingsError) {
        //         var { Food, Nutrients, Units } = this.props.CustomFoodSettings;
        //         var DefaultUnit_Imperial = 1;
        //         var DefaultUnit_Metric = 1;
        //         var FoodMeasurements_Default = {};
        //         var FoodMeasurements_Optional = {};

        //         var AlreadySelectedUnitIds = [];

        //         var FoodMeasurementKey = null;
        //         var FoodMeasurement = null;

        //         var StateNutrients = { Calories: {}, dataMacronutrient: {}, dataMicronutrient: {} };
        //         var NutrientId;

        //         var UnitId = null;
        //         var UnitName = null;

        //         for (i = 0; i < Nutrients.dataMacronutrient.length; i++) {
        //             NutrientId = +Nutrients.dataMacronutrient[i].NutrientId;
        //             StateNutrients.dataMacronutrient[`NutrientId-${NutrientId}`] = { ...Nutrients.dataMacronutrient[i], NutrientId, Quantity: 0 };
        //         }

        //         for (i = 0; i < Nutrients.dataMicronutrient.length; i++) {
        //             NutrientId = +Nutrients.dataMicronutrient[i].NutrientId;
        //             if (NutrientId !== 1) StateNutrients.dataMicronutrient[`NutrientId-${NutrientId}`] = { ...Nutrients.dataMicronutrient[i], NutrientId, Quantity: 0 };
        //             else StateNutrients.Calories[`NutrientId-${NutrientId}`] = { ...Nutrients.dataMicronutrient[i], NutrientId, Quantity: 0 };
        //         }

        //         if (Food) {
        //             var { dataFoodMeasurements: FoodMeasurements, FoodName, dataNutrient: FoodNutrients } = Food;
        //             Nutrients = FoodNutrients;

        //             for (i = 0; i < Nutrients.length; i++) {
        //                 NutrientId = +Nutrients[i].NutrientId;
        //                 var { Quantity } = Nutrients[i];

        //                 var NutrientType = [ 25, 26, 31 ].includes(NutrientId) ? 'dataMacronutrient' : NutrientId === 1 ? 'Calories' : 'dataMicronutrient';
        //                 StateNutrients[NutrientType][`NutrientId-${NutrientId}`] = { ...StateNutrients[NutrientType][`NutrientId-${NutrientId}`], Quantity };
        //             }

        //             for (i = 0; i < FoodMeasurements.length; i++) {
        //                 var {  UnitId: MeasurementUnitId, UnitName: MeasurementUnitName, Conversion, RoundNearest, MaxError, DefaultImperial, DefaultMetric } = FoodMeasurements[i];
        //                 UnitId = +MeasurementUnitId;
        //                 UnitName = MeasurementUnitName;
        //                 Conversion = +Conversion;
        //                 RoundNearest = +RoundNearest;
        //                 MaxError = +MaxError;
        //                 DefaultImperial = !!+DefaultImperial;
        //                 DefaultMetric = !!+DefaultMetric;

        //                 FoodMeasurement = { UnitId, UnitName, Conversion, RoundNearest, MaxError, DefaultImperial, DefaultMetric };
        //                 FoodMeasurementKey = `UnitId-${UnitId}`;

        //                 AlreadySelectedUnitIds.push(UnitId);

        //                 if (DefaultImperial) DefaultUnit_Imperial = UnitId;
        //                 if (DefaultMetric) DefaultUnit_Metric = UnitId;

        //                 if ([ 1, 2, 3, 4 ].includes(UnitId)) FoodMeasurements_Default[FoodMeasurementKey] = FoodMeasurement;
        //                 else FoodMeasurements_Optional[FoodMeasurementKey] = FoodMeasurement;
        //             }
        //         } else {
        //             for (i = 0; i < Units.length; i++) {
        //                 var { UnitId: UnitsUnitId, UnitName: UnitsUnitName } = Units[i];
        //                 UnitId = +UnitsUnitId;
        //                 UnitName = UnitsUnitName;

        //                 if (![ 1, 2, 3, 4 ].includes(UnitId)) break;

        //                 FoodMeasurementKey = `UnitId-${UnitId}`;

        //                 AlreadySelectedUnitIds.push(UnitId);

        //                 if (UnitId === 1) FoodMeasurement = { UnitId, UnitName, Conversion: 1, RoundNearest: 1, MaxError: 20, DefaultImperial: 1, DefaultMetric: 1 };
        //                 else if (UnitId === 2) FoodMeasurement = { UnitId, UnitName, Conversion: 1000, RoundNearest: 0.1, MaxError: 20, DefaultImperial: 0, DefaultMetric: 0 };
        //                 else if (UnitId === 3) FoodMeasurement = { UnitId, UnitName, Conversion: 28.35, RoundNearest: 0.5, MaxError: 20, DefaultImperial: 0, DefaultMetric: 0 };
        //                 else if (UnitId === 4) FoodMeasurement = { UnitId, UnitName, Conversion: 453.59, RoundNearest: 0.5, MaxError: 20, DefaultImperial: 0, DefaultMetric: 0 };
                        
        //                 FoodMeasurements_Default[FoodMeasurementKey] = FoodMeasurement;
        //             }
        //         }

        //         this.setState({ AlreadySelectedUnitIds, DefaultUnit_Imperial, DefaultUnit_Metric, FoodId, FoodMeasurements_Default, FoodMeasurements_Optional, FoodName: FoodName || '', Loading: false, Nutrients: StateNutrients });
        //     } else this.setState({ Loading: false });
        // });
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearPrivateFoodSettings();
    }

    onAddNewUnit = () => {
        this.setState({
            FoodMeasurements_Optional: {
                ...this.state.FoodMeasurements_Optional,
                NewUnit: { UnitId: null, UnitName: '', Conversion: 0, RoundNearest: 1, MaxError: 20, DefaultImperial: 0, DefaultMetric: 0 }
            }
        });
    }

    onBlurFoodMeasurement = (event, FoodMeasurementType, FoodMeasurementsKey, DataType) => {
        if (!+event.target.value) {
            var FoodMeasurements = this.state[`FoodMeasurements_${FoodMeasurementType}`];

            var NewValue = 1;
            if (DataType === 'Conversion') NewValue = 10;
            if (DataType === 'MaxError') NewValue = 10;
            if (DataType === 'RoundNearest') NewValue = 1;

            FoodMeasurements[FoodMeasurementsKey][DataType] = NewValue;

            this.setState({ [`FoodMeasurements_${FoodMeasurementType}`]: FoodMeasurements });
        }
    }

    onBlurNutrientQuantity = (event, NutrientKey, NutrientType) => {
        if (!+event.target.value) {
            // var Quantity = [ 'Calories', 'dataMacronutrient' ].includes(NutrientType) ? 1 : 0;
            var Quantity = [ 'Calories' ].includes(NutrientType) ? 1 : 0;

            this.setState({
                Nutrients: {
                    ...this.state.Nutrients,
                    [NutrientType]: { ...this.state.Nutrients[NutrientType], [NutrientKey]: { ...this.state.Nutrients[NutrientType][NutrientKey], Quantity } }
                }
            });
        }
    }

    onChangeNutrientQuantity = (event, NutrientKey, NutrientType) => {
        this.setState({
            Nutrients: {
                ...this.state.Nutrients,
                [NutrientType]: { ...this.state.Nutrients[NutrientType], [NutrientKey]: { ...this.state.Nutrients[NutrientType][NutrientKey], Quantity: event.target.value } }
            }
        });
    }

    onChangeFoodMeasurement = (event, FoodMeasurementType, FoodMeasurementsKey, DataType) => {
        var FoodMeasurements = this.state[`FoodMeasurements_${FoodMeasurementType}`];

        FoodMeasurements[FoodMeasurementsKey][DataType] = event.target.value;

        this.setState({ [`FoodMeasurements_${FoodMeasurementType}`]: FoodMeasurements });
    }

    onChangeFoodName = event => {
        this.setState({ FoodName: event.target.value });
    }

    onDeleteUnit = (UnitId, FoodMeasurementsKey) => {
        var AfterDeletedFoodMeasurements = { ...this.state.FoodMeasurements_Optional };
        delete AfterDeletedFoodMeasurements[FoodMeasurementsKey];

        var AfterDeletedAlreadySelectedUnitIds = [ ...this.state.AlreadySelectedUnitIds ];
        if (+UnitId) AfterDeletedAlreadySelectedUnitIds.splice(AfterDeletedAlreadySelectedUnitIds.indexOf(+UnitId), 1);

        var { DefaultUnit_Imperial, DefaultUnit_Metric } = this.state;
        if (+DefaultUnit_Imperial === +UnitId) DefaultUnit_Imperial = 1;
        if (+DefaultUnit_Metric === +UnitId) DefaultUnit_Metric = 1;

        this.setState({ AlreadySelectedUnitIds: AfterDeletedAlreadySelectedUnitIds, DefaultUnit_Imperial, DefaultUnit_Metric, FoodMeasurements_Optional: AfterDeletedFoodMeasurements });
    }

    onGetSize = Height => {
        this.setState({ DropdownSize: Height });
    }

    onHoverUnitId = HoveringUnitId => {
        this.setState({ HoveringUnitId });
    }

    onInsertEditFood = () => {
        var { DefaultUnit_Imperial, DefaultUnit_Metric, FoodId, FoodMeasurements_Default, FoodMeasurements_Optional, FoodName, Nutrients } = this.state;

        var HasError = false;
        
        var FoodMeasurements = [];
        // Add Default Units
            Object.entries(FoodMeasurements_Default).map(([ FoodMeasurementsKey, { Conversion, MaxError, RoundNearest, UnitId } ]) => {
                FoodMeasurements.push({
                    Conversion, MaxErrorPercentage: MaxError, RoundToNearest: RoundNearest, UnitId,
                    DefaultImperial: DefaultUnit_Imperial === +UnitId ? 1 : 0, DefaultMetric: DefaultUnit_Metric === +UnitId ? 1 : 0
                });

                if (!UnitId || !Conversion || !MaxError || !RoundNearest) HasError = true;

                return null;
            });

        // Add Optional Units
            Object.entries(FoodMeasurements_Optional).map(([ FoodMeasurementsKey, { Conversion, MaxError, RoundNearest, UnitId } ]) => {
                !!+UnitId && FoodMeasurements.push({
                    Conversion, MaxErrorPercentage: MaxError, RoundToNearest: RoundNearest, UnitId,
                    DefaultImperial: DefaultUnit_Imperial === +UnitId ? 1 : 0, DefaultMetric: DefaultUnit_Metric === +UnitId ? 1 : 0
                });

                if (!UnitId || !Conversion || !MaxError || !RoundNearest) HasError = true;

                return null;
            });

        // Add Nutrients
            var dataNutrients = [];
            // Calories
                Object.entries(Nutrients.Calories).map(([ CaloriesKey, { NutrientId, Quantity } ]) => {
                    dataNutrients.push({ NutrientId, NutrientValue: Quantity });

                    return null;
                });
            // Macronutrients
                Object.entries(Nutrients.dataMacronutrient).map(([ CaloriesKey, { NutrientId, Quantity } ]) => {
                    dataNutrients.push({ NutrientId, NutrientValue: Quantity });

                    return null;
                });
            // Micronutrients
                Object.entries(Nutrients.dataMicronutrient).map(([ CaloriesKey, { NutrientId, Quantity } ]) => {
                    dataNutrients.push({ NutrientId, NutrientValue: Quantity });

                    return null;
                });

        if (!HasError) {
            this.props.InsertEditPrivateFood({ FoodId, FoodMeasurements, FoodName, Nutrients: dataNutrients }).then(() => {
                if (this._isMounted && !this.props.TryingInsertEditPrivateFoodError) {
                    this.props.SaveDataInProps({ LibraryMenu: 'Meals', LibrarySubmenu: 'Food' }).then(() => history.push('/library'));
                }
            });
        } else this.onToggleShowHasErrorModal(true);
    }

    onSelectDefaultUnit = (UnitId, MeasurementType) => {
        this.setState({ [`DefaultUnit_${MeasurementType}`]: UnitId || 'NewUnit' });
    }

    onSelectUnit = (UnitId, UnitName, FoodMeasurementsKey) => {
        var NewFoodMeasurements_Optional = { ...this.state.FoodMeasurements_Optional };
        var NewAlreadySelectedUnitIds = this.state.AlreadySelectedUnitIds;

        if (FoodMeasurementsKey === 'NewUnit') {
            FoodMeasurementsKey = `UnitId-${UnitId}`;
            NewFoodMeasurements_Optional[FoodMeasurementsKey] = { ...NewFoodMeasurements_Optional.NewUnit };
            delete NewFoodMeasurements_Optional.NewUnit;

            NewAlreadySelectedUnitIds.push(+UnitId);
        }

        NewFoodMeasurements_Optional[FoodMeasurementsKey] = { ...NewFoodMeasurements_Optional[FoodMeasurementsKey], ...this.state.FoodMeasurements_Optional[FoodMeasurementsKey], UnitId, UnitName };

        this.setState({ AlreadySelectedUnitIds: NewAlreadySelectedUnitIds, DisplayingFilter: null, FoodMeasurements_Optional: NewFoodMeasurements_Optional });
    }

    onToggleDropdown = (DisplayingFilter, ToggleStatus) => {
        this.setState({ DisplayingFilter: ToggleStatus ? DisplayingFilter : null });
    }

    onToggleShowHasErrorModal = ShowHasErrorModal => {
        this.setState({ ShowHasErrorModal });
    }

    renderDefaultUnits = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { DefaultUnit_Imperial, DefaultUnit_Metric, FoodMeasurements_Default } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('default_units')} TextAlign="center" />
                <PageText ContainerWidth="450px" FontFamily="medium" FontSize="medium-1" Text={t('default_units_description')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <UnitsListContainer className="UnitsListContainer">
                    {Device === 'laptop' && this.renderUnitsListHeaderRow()}

                    {
                        Object.entries(FoodMeasurements_Default).map(([ FoodMeasurementsKey, { Conversion, MaxError, RoundNearest, UnitId, UnitName } ]) => {
                            return (
                                <UnitsListRow key={UnitId}>
                                    <UnitListColumnContainer>
                                        {Device !== 'laptop' && <><PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('unit_name')} TextAlign="center" /><Spacer Size="extra-extra-small" /></>}

                                        <StyledInput
                                            FontFamily="medium"
                                            FontSize="medium-1"
                                            NoLabel
                                            NoMargin
                                            OnChange={() => null}
                                            Placeholder=""
                                            ReadOnly
                                            TextAlign="left"
                                            Type="text"
                                            Value={UnitName}
                                        />
                                    </UnitListColumnContainer>
                                    <UnitListColumnContainer>
                                        {Device !== 'laptop' && <><PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('conversions')} TextAlign="center" /><Spacer Size="extra-extra-small" /></>}

                                        <StyledInputLabelSideBySide
                                            InputFontFamily="medium"
                                            InputFontSize="medium-1"
                                            InputWidth="100%"
                                            LabelFontFamily="medium"
                                            LabelFontSize="medium-1"
                                            Label="g"
                                            OnChange={() => null}
                                            Placeholder="0"
                                            ReadOnly
                                            Type="number"
                                            Value={Conversion}
                                        />
                                    </UnitListColumnContainer>
                                    <UnitListColumnContainer>
                                        {Device !== 'laptop' && <><PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('round_nearest')} TextAlign="center" /><Spacer Size="extra-extra-small" /></>}

                                        <StyledInput
                                            FontFamily="medium"
                                            FontSize="medium-1"
                                            NoLabel
                                            NoMargin
                                            OnBlur={event => this.onBlurFoodMeasurement(event, 'Default', FoodMeasurementsKey, 'RoundNearest')}
                                            OnChange={event => this.onChangeFoodMeasurement(event, 'Default', FoodMeasurementsKey, 'RoundNearest')}
                                            Placeholder="0"
                                            ReadOnly={UnitId === 1}
                                            Type="number"
                                            Value={RoundNearest}
                                        />
                                    </UnitListColumnContainer>
                                    <UnitListColumnContainer>
                                        {Device !== 'laptop' && <><PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('max_error_percentage')} TextAlign="center" /><Spacer Size="extra-extra-small" /></>}

                                        <StyledInputLabelSideBySide
                                            InputFontFamily="medium"
                                            InputFontSize="medium-1"
                                            InputWidth="100%"
                                            LabelFontFamily="medium"
                                            LabelFontSize="medium-1"
                                            Label="%"
                                            OnBlur={event => this.onBlurFoodMeasurement(event, 'Default', FoodMeasurementsKey, 'MaxError')}
                                            OnChange={event => this.onChangeFoodMeasurement(event, 'Default', FoodMeasurementsKey, 'MaxError')}
                                            Placeholder="0"
                                            ReadOnly={UnitId === 1}
                                            Type="number"
                                            Value={MaxError}
                                        />
                                    </UnitListColumnContainer>
                                    <UnitListColumnContainer>
                                        {Device !== 'laptop' && <><PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('default_units')} - ${t('imperial')}`} TextAlign="center" /><Spacer Size="extra-extra-small" /></>}

                                        <MultiSelectRadioButton onClick={() => this.onSelectDefaultUnit(UnitId, 'Imperial')} Selected={UnitId === DefaultUnit_Imperial} />
                                    </UnitListColumnContainer>
                                    <UnitListColumnContainer>
                                        {Device !== 'laptop' && <><PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('default_units')} - ${t('metric')}`} TextAlign="center" /><Spacer Size="extra-extra-small" /></>}

                                        <MultiSelectRadioButton onClick={() => this.onSelectDefaultUnit(UnitId, 'Metric')} Selected={UnitId === DefaultUnit_Metric} />
                                    </UnitListColumnContainer>

                                    {Device !== 'laptop' && (FoodMeasurementsKey !== Object.keys(FoodMeasurements_Default)[Object.keys(FoodMeasurements_Default).length-1]) && <StyledHR />}
                                </UnitsListRow>
                            );
                        })
                    }
                </UnitsListContainer>
            </>
        );
    }

    renderFoodName = () => {
        var { t } = this.props;
        var { FoodName } = this.state;

        return (
            <>
                <RequiredHeaderContainer>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-3" Text={t('MealPlan_Name_your_food')} />
                    <PageText ElementType="span" FontColor="red-bittersweet" FontFamily="medium" FontSize="medium-3" Text="&nbsp;*" />
                </RequiredHeaderContainer>

                <StyledFoodName
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeFoodName}
                    Placeholder={t('MealPlan_Name_your_food_placeholder')}
                    Size="medium"
                    Type="text"
                    Value={FoodName}
                />
            </>
        );
    }

    renderHasErrorModalInformation = () => {
        var { t } = this.props;

        return <PageText FontFamily="medium" FontSize="medium-3" Text={t('create_food_missing_information')} TextAlign="center" />;
    }

    renderHasErrorModal = () => {
        var { t } = this.props;
        var { ShowHasErrorModal } = this.state;

        if (ShowHasErrorModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={() => this.onToggleShowHasErrorModal(false)}
                    BottomButtonText={t('ok')}
                    InformationRenderer={this.renderHasErrorModalInformation}
                    OnHide={() => this.onToggleShowHasErrorModal(false)}
                    Show={ShowHasErrorModal}
                />
            );
        }
    }

    renderNutrient = ({ NutrientId, NutrientKey, NutrientName, NutrientType, Quantity, Required, Unit }) => {
        var { t } = this.props;
        var Texts = [];

        Texts.push({ FontFamily: 'medium', FontSize: 'medium-3', Text: +NutrientId === 1 ? t('MealPlan_Calories') : NutrientName });
        if (Required) Texts.push({ FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-3', Text: '*' });

        return (
            <NutrientContainer className="NutrientContainer">
                {
                    [ 25, 26, 31 ].includes(NutrientId) &&
                    <>
                        <NutrientImage ImageSrc={NutrientId === 25 ? Protein : NutrientId === 26 ? Carbs : Fat} Loading={false} />

                        <Spacer Size="extra-small" />
                    </>
                }

                <MultiText TextAlign="center" Texts={Texts} />

                <Spacer Size="small" />

                <NutrientInputContainer>
                    <NutrientInput
                        FontFamily="medium"
                        FontSize="medium-3"
                        NoLabel
                        NoMargin
                        OnBlur={event => this.onBlurNutrientQuantity(event, NutrientKey, NutrientType)}
                        OnChange={event => this.onChangeNutrientQuantity(event, NutrientKey, NutrientType)}
                        Placeholder="0"
                        Size="medium"
                        Type="number"
                        Value={Quantity}
                    />
                </NutrientInputContainer>

                <Spacer Size="extra-extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${Unit} ${t('per_100g')}`} TextAlign="center" />
            </NutrientContainer>
        );
    }

    renderNutrients = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { Nutrients } = this.state;

        return (
            <>
                <NutrientsContainer className="NutrientsContainer">
                    {/* Calories */}
                        <NutrientSection className="NutrientSection" OneColumn>
                            {
                                Object.entries(Nutrients.Calories).map(([ CaloriesKey, { NutrientId, NutrientName, Quantity, Unit } ]) => {
                                    NutrientId = +NutrientId;

                                    return (
                                        <React.Fragment key={NutrientId}>
                                            {this.renderNutrient({ NutrientId, NutrientKey: CaloriesKey, NutrientName, NutrientType: 'Calories', Quantity, Required: true, Unit })}
                                        </React.Fragment>
                                    );
                                })
                            }
                        </NutrientSection>

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />
                    
                    {/* Macronutrients */}
                        <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('macronutrients')} TextAlign="center" />
                        <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={`grams ${t('per_100g')}`} TextAlign="center" />

                        <Spacer Size="medium" />

                        <NutrientSection className="NutrientSection">
                            {
                                // Nutrients.dataMacronutrient.map(({ NutrientId, NutrientName, Unit }) => {
                                Object.entries(Nutrients.dataMacronutrient).map(([ MacronutrientKey, { NutrientId, NutrientName, Quantity, Unit } ]) => {
                                    NutrientId = +NutrientId;

                                    return (
                                        <React.Fragment key={NutrientId}>
                                            {this.renderNutrient({ NutrientId, NutrientKey: MacronutrientKey, NutrientName, NutrientType: 'dataMacronutrient', Quantity, Required: true, Unit })}
                                        </React.Fragment>
                                    );
                                })
                            }
                        </NutrientSection>

                        <Spacer Size="medium" />

                        <HelpBoxContainer>
                            <ExampleContainer>
                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('example')} 1:`} TextAlign="center" Underline />
                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('macronutrient_example_1')} TextAlign="center" />
                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('macronutrient_example_1_calories')} TextAlign="center" />
                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('macronutrient_example_1_protein')} TextAlign="center" />
                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('macronutrient_example_1_fat')} TextAlign="center" />
                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('macronutrient_example_1_carbs')} TextAlign="center" />
                            </ExampleContainer>
                            <ExampleContainer>
                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('example')} 2:`} TextAlign="center" Underline />
                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('macronutrient_example_2')} TextAlign="center" />
                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('macronutrient_example_2_calories')} TextAlign="center" />
                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('macronutrient_example_2_protein')} TextAlign="center" />
                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('macronutrient_example_2_fat')} TextAlign="center" />
                                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('macronutrient_example_2_carbs')} TextAlign="center" />
                            </ExampleContainer>
                        </HelpBoxContainer>

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    <OptionalLine>
                        <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('optional')} TextAlign="center" />
                    </OptionalLine>

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />
                    
                    {/* Micronutrients */}
                        <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('micronutrients')} TextAlign="center" />
                        <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={`${t('per_100g')}`} TextAlign="center" />

                        <Spacer Size="medium" />

                        <NutrientSection className="NutrientSection">
                                {
                                    // Nutrients.dataMicronutrient.map(({ NutrientId, NutrientName, Unit }) => {
                                    Object.entries(Nutrients.dataMicronutrient).map(([ MicronutrientKey, { NutrientId, NutrientName, Quantity, Unit } ]) => {
                                        NutrientId = +NutrientId;

                                        if (NutrientId === 1) return null;

                                        return (
                                            <React.Fragment key={NutrientId}>
                                                {this.renderNutrient({ NutrientId, NutrientKey: MicronutrientKey, NutrientName, NutrientType: 'dataMicronutrient', Quantity, Unit })}
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </NutrientSection>
                </NutrientsContainer>
            </>
        );
    }

    renderOptionalUnits = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { DefaultUnit_Imperial, DefaultUnit_Metric, DisplayingFilter, DropdownSize, FoodMeasurements_Optional } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('optional_units')} TextAlign="center" />
                <PageText ContainerWidth="450px" FontFamily="medium" FontSize="medium-1" Text={t('optional_units_description')} TextAlign="center" />

                <Spacer Size="extra-small" />

                {
                    Object.entries(FoodMeasurements_Optional).length ?
                        <UnitsListContainer className="UnitsListContainer">
                        {Device === 'laptop' && this.renderUnitsListHeaderRow()}

                        {
                            Object.entries(FoodMeasurements_Optional).map(([ FoodMeasurementsKey, { Conversion, MaxError, RoundNearest, UnitId, UnitName } ]) => {
                                var ReadOnly = !!!+UnitId; // If it is a new unit, that hasn't had Unit selected yet

                                return (
                                    <UnitsListRow key={UnitId}>
                                        <UnitListColumnContainer>
                                            {Device !== 'laptop' && <><PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('unit_name')} TextAlign="center" /><Spacer Size="extra-extra-small" /></>}

                                            <StyledDropdown
                                                ContentRenderer={() => this.renderUnitOptions(UnitId, FoodMeasurementsKey)}
                                                Disabled={false}
                                                ForceClose={DisplayingFilter !== FoodMeasurementsKey}
                                                GetSize={Height => this.onGetSize(Height)}
                                                Height={DisplayingFilter === FoodMeasurementsKey && DropdownSize}
                                                IsList={false}
                                                IsMobile={Device === 'mobile_small' || Device === 'mobile'}
                                                ItemId="Units"
                                                NeedsToLoad={false}
                                                OnToggleDropdown={ToggleStatus => this.onToggleDropdown(FoodMeasurementsKey, ToggleStatus)}
                                                SelectedItemIds={[]}
                                                Title={UnitName || t('MealPlan_Pick_unit')}
                                                TitleFontColor={UnitName ? 'blue-astronaut' : null}
                                                TitleFontFamily="semibold"
                                                TitleIcon={Device !== 'laptop' ? DeleteX : DeleteX_Gray}
                                                TitleIconOnHover={Device !== 'laptop' ? null : DeleteX}
                                                TitleIconOnClick={() => this.onDeleteUnit(UnitId, FoodMeasurementsKey)}
                                            />
                                        </UnitListColumnContainer>
                                        <UnitListColumnContainer>
                                            {Device !== 'laptop' && <><PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('conversions')} TextAlign="center" /><Spacer Size="extra-extra-small" /></>}

                                            <StyledInputLabelSideBySide
                                                InputFontFamily="medium"
                                                InputFontSize="medium-1"
                                                InputWidth="100%"
                                                LabelFontFamily="medium"
                                                LabelFontSize="medium-1"
                                                Label="g"
                                                OnBlur={event => this.onBlurFoodMeasurement(event, 'Optional', FoodMeasurementsKey, 'Conversion')}
                                                OnChange={event => this.onChangeFoodMeasurement(event, 'Optional', FoodMeasurementsKey, 'Conversion')}
                                                Placeholder="0"
                                                ReadOnly={ReadOnly}
                                                Type="number"
                                                Value={Conversion}
                                            />
                                        </UnitListColumnContainer>
                                        <UnitListColumnContainer>
                                            {Device !== 'laptop' && <><PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('round_nearest')} TextAlign="center" /><Spacer Size="extra-extra-small" /></>}

                                            <StyledInput
                                                FontFamily="medium"
                                                FontSize="medium-1"
                                                NoLabel
                                                NoMargin
                                                OnBlur={event => this.onBlurFoodMeasurement(event, 'Optional', FoodMeasurementsKey, 'RoundNearest')}
                                                OnChange={event => this.onChangeFoodMeasurement(event, 'Optional', FoodMeasurementsKey, 'RoundNearest')}
                                                Placeholder="0"
                                                ReadOnly={ReadOnly}
                                                Type="number"
                                                Value={RoundNearest}
                                            />
                                        </UnitListColumnContainer>
                                        <UnitListColumnContainer>
                                            {Device !== 'laptop' && <><PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('max_error_percentage')} TextAlign="center" /><Spacer Size="extra-extra-small" /></>}

                                            <StyledInputLabelSideBySide
                                                InputFontFamily="medium"
                                                InputFontSize="medium-1"
                                                InputWidth="100%"
                                                LabelFontFamily="medium"
                                                LabelFontSize="medium-1"
                                                Label="%"
                                                OnBlur={event => this.onBlurFoodMeasurement(event, 'Optional', FoodMeasurementsKey, 'MaxError')}
                                                OnChange={event => this.onChangeFoodMeasurement(event, 'Optional', FoodMeasurementsKey, 'MaxError')}
                                                Placeholder="0"
                                                ReadOnly={ReadOnly}
                                                Type="number"
                                                Value={MaxError}
                                            />
                                        </UnitListColumnContainer>
                                        <UnitListColumnContainer>
                                            {Device !== 'laptop' && <><PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('default_units')} - ${t('imperial')}`} TextAlign="center" /><Spacer Size="extra-extra-small" /></>}

                                            <MultiSelectRadioButton onClick={ReadOnly ? () => null : () => this.onSelectDefaultUnit(UnitId, 'Imperial')} ReadOnly={ReadOnly} Selected={UnitId === DefaultUnit_Imperial} />
                                        </UnitListColumnContainer>
                                        <UnitListColumnContainer>
                                            {Device !== 'laptop' && <><PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('default_units')} - ${t('metric')}`} TextAlign="center" /><Spacer Size="extra-extra-small" /></>}

                                            <MultiSelectRadioButton onClick={ReadOnly ? () => null : () => this.onSelectDefaultUnit(UnitId, 'Metric')} ReadOnly={ReadOnly} Selected={UnitId === DefaultUnit_Metric} />
                                        </UnitListColumnContainer>

                                        {Device !== 'laptop' && (FoodMeasurementsKey !== Object.keys(FoodMeasurements_Optional)[Object.keys(FoodMeasurements_Optional).length-1]) && <StyledHR />}
                                    </UnitsListRow>
                                );
                            })
                        }

                        <UnitsListRow>
                            <ButtonGroup
                                Buttons={[{ FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: this.onAddNewUnit, Title: t('add_new_unit') }]}
                                ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                                NotTouching
                                OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                            />
                        </UnitsListRow>
                    </UnitsListContainer>
                :
                    <ButtonGroup
                        Buttons={[{ FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: this.onAddNewUnit, Title: t('add_new_unit') }]}
                        ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                        NotTouching
                        OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                    />
                }
            </>
        );
    }

    renderUnitOptions = (UnitId, FoodMeasurementsKey) => {
        var { AlreadySelectedUnitIds } = this.state;

        return (
            <UnitOptionsList>
                {
                    this.props.CustomFoodSettings.Units.map(({ UnitId: OptionsUnitId, UnitName }) => {
                        OptionsUnitId = +OptionsUnitId;
                        var Selected = +UnitId === OptionsUnitId;
                        var Disabled = AlreadySelectedUnitIds.indexOf(OptionsUnitId) !== -1 && !Selected;

                        return (
                            <UnitOptionsItem key={OptionsUnitId} Disabled={Disabled} onClick={Disabled ? () => null : () => this.onSelectUnit(OptionsUnitId, UnitName, FoodMeasurementsKey)} Selected={Selected}>
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={UnitName} TextAlign="left" />
                            </UnitOptionsItem>
                        );
                    })
                }
            </UnitOptionsList>
        );
    }

    renderUnitsListHeaderRow = () => {
        var { t } = this.props;

        return (
            <UnitsListRow>
                <UnitListColumnContainer>
                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('unit_name')} TextAlign="center" />
                </UnitListColumnContainer>
                <UnitListColumnContainer>
                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('conversions')} TextAlign="center" />
                </UnitListColumnContainer>
                <UnitListColumnContainer>
                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('round_nearest')} TextAlign="center" />
                </UnitListColumnContainer>
                <UnitListColumnContainer>
                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('max_error_percentage')} TextAlign="center" />
                </UnitListColumnContainer>
                <UnitListColumnContainer>
                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('default_units')} - ${t('imperial')}`} TextAlign="center" />
                </UnitListColumnContainer>
                <UnitListColumnContainer>
                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('default_units')} - ${t('metric')}`} TextAlign="center" />
                </UnitListColumnContainer>
            </UnitsListRow>
        );
    }

    renderWordExplanations = () => {
        var { t } = this.props;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('word_explanations')} TextAlign="center" />

                <Spacer Size="small" />

                <WordExplanationsContainer>
                    <WordExplanationContainer>
                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" Text={t('conversions')} TextAlign="center" />

                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" Text={t('grams_in_whole')} TextAlign="center" />

                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${t('example')}:`} TextAlign="center" Underline />
                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={t('kg_is_1000g')} TextAlign="center" />
                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={t('dl_milk_is_100g')} TextAlign="center" />
                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={t('dl_flour_is_60g')} TextAlign="center" />
                    </WordExplanationContainer>
                    <WordExplanationContainer>
                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" Text={t('round_nearest')} TextAlign="center" />

                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" Text={t('round_nearest_food_description')} TextAlign="center" />

                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${t('example')}:`} TextAlign="center" Underline />
                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={t('round_nearest_food_example_1')} TextAlign="center" />
                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={t('round_nearest_food_example_2')} TextAlign="center" />
                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={t('round_nearest_food_example_3')} TextAlign="center" />
                    </WordExplanationContainer>
                    <WordExplanationContainer>
                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" Text={t('max_error_percentage')} TextAlign="center" />

                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${t('example')}:`} TextAlign="center" Underline />
                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={t('max_error_percentage_food_description')} TextAlign="center" />
                    </WordExplanationContainer>
                </WordExplanationsContainer>
            </>
        );
    }

    render() {
        if (this.state.Loading || this.props.TryingGetCreatePrivateFoodSettings) return <Loading />;
        if (this.props.TryingGetCreatePrivateFoodSettingsError) return null;

        var { t } = this.props;
        var { Device, TryingInsertEditPrivateFood } = this.props;
        var { FoodId, FoodName, Nutrients: { Calories } } = this.state;

        var Disabled = false;
        if (!FoodName) Disabled = true;
        // if (+Calories[`NutrientId-1`].Quantity === 0 || +dataMacronutrient[`NutrientId-25`].Quantity === 0 || +dataMacronutrient[`NutrientId-26`].Quantity === 0 || +dataMacronutrient[`NutrientId-31`].Quantity === 0) Disabled = true;
        if (+Calories[`NutrientId-1`].Quantity === 0) Disabled = true;

        var Breadcrumbs = [{ Name: t('ptadmin_navigation_library'), LinkURL: 'library', Props: { LibraryMenu: 'Meals', LibrarySubmenu: 'Food' } }, { Name: t(FoodId ? 'edit_private_food' : 'create_private_food') }];
        var SubmitButtonProps = {
            Disabled,
            OnClick: this.onInsertEditFood,
            Title: t(FoodId ? 'ptadmin_clientnote_options_save' : 'create_private_food'),
        }

        return (
            <>
                {TryingInsertEditPrivateFood && <Loading />}

                <Page
                    renderBreadcrumbs={Breadcrumbs}
                    renderSubmitButton={SubmitButtonProps}
                >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={t(FoodId ? 'edit_private_food' : 'create_private_food')} />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderFoodName()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderDefaultUnits()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'large' : 'medium'} />

                    {this.renderWordExplanations()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'large' : 'medium'} />

                    {this.renderOptionalUnits()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderNutrients()}
                </Page>

                {this.renderHasErrorModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        CustomFoodSettings: state.Food.CustomFoodSettings,

        TryingGetCreatePrivateFoodSettings: state.Food.TryingGetCreatePrivateFoodSettings,
        TryingGetCreatePrivateFoodSettingsError: state.Food.TryingGetCreatePrivateFoodSettingsError,
        TryingInsertEditPrivateFood: state.Food.TryingInsertEditPrivateFood,
        TryingInsertEditPrivateFoodError: state.Food.TryingInsertEditPrivateFoodError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearPrivateFoodSettings, InsertEditPrivateFood, SaveDataInProps, ViewCreatePrivateFoodSettings } )(Food));