import styled from 'styled-components';
import { device } from '../../Config/device'

import Dropdown from '../../Components/Dropdown';
import Input from '../../Components/Form/Input';
import LoadMore from '../../Components/Lists/LoadMore';

export const AllFilters = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
    }
`;

export const SelectedDatesContainer = styled.div`
    border-top: 2px solid var(--white-concrete);
    flex: 1;
    margin-top: -1px;
    padding: 15px 15px 0;

    @media ${device.minwidth.ipad} {
        padding: 25px 25px 0;
    }

    @media ${device.minwidth.laptop} {
        border-left: 2px solid var(--white-concrete);
        border-top: none;
        margin-left: -1px;
        margin-top: 0;
        padding: 0 25px;
    }
`;

export const SelectedPreviewContainer = styled.div`
    align-items: center;
    border: 5px solid var(--blue-abel);
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 15px;

    @media ${device.minwidth.ipad} {
        padding: 25px;
    }

    @media ${device.minwidth.laptop} {
        align-items: flex-start;
        flex-direction: row;
        padding-left: 0px;
        padding-right: 0px;
    }
`;

export const SelectedTemplateContainer = styled.div`
    border-bottom: 2px solid var(--white-concrete);
    flex: 1;
    margin-bottom: -1px;
    padding: 0 15px 15px;
    width: 100%;

    @media ${device.minwidth.ipad} {
        padding: 0 25px 25px;
    }

    @media ${device.minwidth.laptop} {
        border-bottom: none;
        border-right: 2px solid var(--white-concrete);
        margin-bottom: 0px;
        margin-right: -1px;
        padding: 0 25px;
    }
`;

export const StyledDropdown = styled(Dropdown)`
    margin-bottom: 10px;
    width: 100%;

    @media ${device.minwidth.ipad} {
        margin-bottom: 0px;
        max-width: 300px;
        width: auto;
    }
`;

export const StyledLoadMore = styled(LoadMore)`
    > div:first-child {
        display: grid;
        grid-gap: 40px 15px;
        grid-template-columns: repeat(1, 2fr);

        @media ${device.minwidth.mobile} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media ${device.minwidth.ipad} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media ${device.minwidth.laptop} {
            grid-template-columns: repeat(4, 1fr);
        }
    }
`;

export const StyledSearchInput = styled(Input)`
    margin-bottom: 10px;
    width: 100%;

    > input {
        border: 2px solid var(--white-concrete);
    }

    @media ${device.minwidth.ipad} {
        margin-bottom: 0px;
        width: auto;
    }
`;