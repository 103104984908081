import {
    TRYING_ADD_WORKOUT_EXERCISES,
    TRYING_ADD_WORKOUT_EXERCISES_SUCCESS,
    TRYING_ADD_WORKOUT_EXERCISES_ERROR,

    TRYING_ADD_WORKOUT_LAP,
    TRYING_ADD_WORKOUT_LAP_SUCCESS,
    TRYING_ADD_WORKOUT_LAP_ERROR,

    TRYING_ADD_WORKOUT_SET,
    TRYING_ADD_WORKOUT_SET_SUCCESS,
    TRYING_ADD_WORKOUT_SET_ERROR,

    TRYING_AUTOCOMPLETE_WORKOUT,
    TRYING_AUTOCOMPLETE_WORKOUT_SUCCESS,
    TRYING_AUTOCOMPLETE_WORKOUT_ERROR,

    TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET,
    TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET_SUCCESS,
    TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET_ERROR,

    TRYING_CHANGE_WORKOUT_EXERCISE_NOTE,
    TRYING_CHANGE_WORKOUT_EXERCISE_NOTE_SUCCESS,
    TRYING_CHANGE_WORKOUT_EXERCISE_NOTE_ERROR,

    TRYING_DELETE_WORKOUT,
    TRYING_DELETE_WORKOUT_SUCCESS,
    TRYING_DELETE_WORKOUT_ERROR,

    TRYING_DELETE_WORKOUT_EXERCISE,
    TRYING_DELETE_WORKOUT_EXERCISE_SUCCESS,
    TRYING_DELETE_WORKOUT_EXERCISE_ERROR,

    TRYING_DELETE_WORKOUT_LAP,
    TRYING_DELETE_WORKOUT_LAP_SUCCESS,
    TRYING_DELETE_WORKOUT_LAP_ERROR,

    TRYING_DELETE_WORKOUT_SET,
    TRYING_DELETE_WORKOUT_SET_SUCCESS,
    TRYING_DELETE_WORKOUT_SET_ERROR,

    TRYING_FREEZE_WORKOUT_FOR_PRINT,
    TRYING_FREEZE_WORKOUT_FOR_PRINT_SUCCESS,
    TRYING_FREEZE_WORKOUT_FOR_PRINT_ERROR,

    CLEAR_WORKOUT_DETAIL,
    TRYING_GET_WORKOUT_DETAIL,
    TRYING_GET_WORKOUT_DETAIL_SUCCESS,
    TRYING_GET_WORKOUT_DETAIL_ERROR,

    TRYING_GET_WORKOUT_NOTES,
    TRYING_GET_WORKOUT_NOTES_SUCCESS,
    TRYING_GET_WORKOUT_NOTES_ERROR,

    TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY,
    TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY_SUCCESS,
    TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY_ERROR,

    TRYING_INSERTEDIT_WORKOUT_NOTE,
    TRYING_INSERTEDIT_WORKOUT_NOTE_SUCCESS,
    TRYING_INSERTEDIT_WORKOUT_NOTE_ERROR,

    TRYING_REGENERATE_MAXSPEED,
    TRYING_REGENERATE_MAXSPEED_SUCCESS,
    TRYING_REGENERATE_MAXSPEED_ERROR,

    TRYING_REGENERATE_TESTSET,
    TRYING_REGENERATE_TESTSET_SUCCESS,
    TRYING_REGENERATE_TESTSET_ERROR,

    TRYING_REORDER_EXERCISES,
    TRYING_REORDER_EXERCISES_SUCCESS,
    TRYING_REORDER_EXERCISES_ERROR,

    TRYING_UPDATE_WORKOUT_INFORMATION,
    TRYING_UPDATE_WORKOUT_INFORMATION_SUCCESS,
    TRYING_UPDATE_WORKOUT_INFORMATION_ERROR,

    TRYING_UPDATE_WORKOUT_LAP,
    TRYING_UPDATE_WORKOUT_LAP_SUCCESS,
    TRYING_UPDATE_WORKOUT_LAP_ERROR,
    
    TRYING_UPDATE_WORKOUT_SET,
    TRYING_UPDATE_WORKOUT_SET_SUCCESS,
    TRYING_UPDATE_WORKOUT_SET_ERROR
} from '../Types';

const INITIAL_STATE = {
    AdditionalActivityDetails: {},
    WorkoutDetails: {},
    WorkoutExercises: [],
    WorkoutLaps: [],
    VideoSessionInfo: {},

    TryingAddWorkoutExercises: false,
    TryingAddWorkoutExercisesError: null,
    TryingAddWorkoutLap: false,
    TryingAddWorkoutLapError: null,
    TryingAddWorkoutSet: false,
    TryingAddWorkoutSetError: null,
    TryingChangeWorkoutExerciseMaxSet: false,
    TryingChangeWorkoutExerciseMaxSetError: null,
    TryingChangeWorkoutExerciseNote: false,
    TryingChangeWorkoutExerciseNoteError: null,
    TryingCompleteWorkout: false,
    TryingCompleteWorkoutError: null,
    TryingDeleteWorkout: false,
    TryingDeleteWorkoutError: null,
    TryingDeleteWorkoutExercise: false,
    TryingDeleteWorkoutExerciseError: null,
    TryingDeleteWorkoutLap: false,
    TryingDeleteWorkoutLapError: null,
    TryingDeleteWorkoutSet: false,
    TryingDeleteWorkoutSetError: null,
    TryingFreezeWorkout: false,
    TryingFreezeWorkoutError: null,
    TryingGetWorkoutDetail: false,
    TryingGetWorkoutDetailError: null,
    TryingGetWorkoutNotes: false,
    TryingGetWorkoutNotesError: null,
    TryingInsertEditAdditionalActivity: false,
    TryingInsertEditAdditionalActivityError: null,
    TryingInsertEditWorkoutNote: false,
    TryingInsertEditWorkoutNoteError: null,
    TryingRegenerateMaxSpeed: false,
    TryingRegenerateMaxSpeedError: null,
    TryingRegenerateTestSet: false,
    TryingRegenerateTestSetError: null,
    TryingReorderExercises: false,
    TryingReorderExercisesError: null,
    TryingUpdateWorkoutInformation: false,
    TryingUpdateWorkoutInformationError: null,
    TryingUpdateWorkoutLap: false,
    TryingUpdateWorkoutLapError: null,
    TryingUpdateWorkoutSet: false,
    TryingUpdateWorkoutSetError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_ADD_WORKOUT_EXERCISES:
            return { ...state, TryingAddWorkoutExercises: true, TryingAddWorkoutExercisesError: null };
        case TRYING_ADD_WORKOUT_EXERCISES_ERROR:
            return { ...state, TryingAddWorkoutExercises: false, TryingAddWorkoutExercisesError: action.payload };
        case TRYING_ADD_WORKOUT_EXERCISES_SUCCESS:
            return { ...state, TryingAddWorkoutExercises: false, TryingAddWorkoutExercisesError: null };

        case TRYING_ADD_WORKOUT_LAP:
            return { ...state, TryingAddWorkoutLap: true, TryingAddWorkoutLapError: null };
        case TRYING_ADD_WORKOUT_LAP_ERROR:
            return { ...state, TryingAddWorkoutLap: false, TryingAddWorkoutLapError: action.payload };
        case TRYING_ADD_WORKOUT_LAP_SUCCESS:
            return { ...state, TryingAddWorkoutLap: false, TryingAddWorkoutLapError: null };

        case TRYING_ADD_WORKOUT_SET:
            return { ...state, TryingAddWorkoutSet: true, TryingAddWorkoutSetError: null };
        case TRYING_ADD_WORKOUT_SET_ERROR:
            return { ...state, TryingAddWorkoutSet: false, TryingAddWorkoutSetError: action.payload };
        case TRYING_ADD_WORKOUT_SET_SUCCESS:
            return { ...state, TryingAddWorkoutSet: false, TryingAddWorkoutSetError: null };

        case TRYING_AUTOCOMPLETE_WORKOUT:
            return { ...state, TryingCompleteWorkout: true, TryingCompleteWorkoutError: null };
        case TRYING_AUTOCOMPLETE_WORKOUT_ERROR:
            return { ...state, TryingCompleteWorkout: false, TryingCompleteWorkoutError: action.payload };
        case TRYING_AUTOCOMPLETE_WORKOUT_SUCCESS:
            return { ...state, TryingCompleteWorkout: false, TryingCompleteWorkoutError: null };

        case TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET:
            return { ...state, TryingChangeWorkoutExerciseMaxSet: true, TryingChangeWorkoutExerciseMaxSetError: null };
        case TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET_ERROR:
            return { ...state, TryingChangeWorkoutExerciseMaxSet: false, TryingChangeWorkoutExerciseMaxSetError: action.payload };
        case TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET_SUCCESS:
            return { ...state, TryingChangeWorkoutExerciseMaxSet: false, TryingChangeWorkoutExerciseMaxSetError: null };

        case TRYING_CHANGE_WORKOUT_EXERCISE_NOTE:
            return { ...state, TryingChangeWorkoutExerciseNote: true, TryingChangeWorkoutExerciseNoteError: null };
        case TRYING_CHANGE_WORKOUT_EXERCISE_NOTE_ERROR:
            return { ...state, TryingChangeWorkoutExerciseNote: false, TryingChangeWorkoutExerciseNoteError: action.payload };
        case TRYING_CHANGE_WORKOUT_EXERCISE_NOTE_SUCCESS:
            return { ...state, TryingChangeWorkoutExerciseNote: false, TryingChangeWorkoutExerciseNoteError: null };
            
        case TRYING_DELETE_WORKOUT:
            return { ...state, TryingDeleteWorkout: true, TryingDeleteWorkoutError: null };
        case TRYING_DELETE_WORKOUT_ERROR:
            return { ...state, TryingDeleteWorkout: false, TryingDeleteWorkoutError: action.payload };
        case TRYING_DELETE_WORKOUT_SUCCESS:
            return { ...state, TryingDeleteWorkout: false, TryingDeleteWorkoutError: null };
        
        case TRYING_DELETE_WORKOUT_EXERCISE:
            return { ...state, TryingDeleteWorkoutExercise: true, TryingDeleteWorkoutExerciseError: null };
        case TRYING_DELETE_WORKOUT_EXERCISE_ERROR:
            return { ...state, TryingDeleteWorkoutExercise: false, TryingDeleteWorkoutExerciseError: action.payload };
        case TRYING_DELETE_WORKOUT_EXERCISE_SUCCESS:
            return { ...state, TryingDeleteWorkoutExercise: false, TryingDeleteWorkoutExerciseError: null };

        case TRYING_DELETE_WORKOUT_LAP:
            return { ...state, TryingDeleteWorkoutLap: true, TryingDeleteWorkoutLapError: null };
        case TRYING_DELETE_WORKOUT_LAP_ERROR:
            return { ...state, TryingDeleteWorkoutLap: false, TryingDeleteWorkoutLapError: action.payload };
        case TRYING_DELETE_WORKOUT_LAP_SUCCESS:
            return { ...state, TryingDeleteWorkoutLap: false, TryingDeleteWorkoutLapError: null };

        case TRYING_DELETE_WORKOUT_SET:
            return { ...state, TryingDeleteWorkoutSet: true, TryingDeleteWorkoutSetError: null };
        case TRYING_DELETE_WORKOUT_SET_ERROR:
            return { ...state, TryingDeleteWorkoutSet: false, TryingDeleteWorkoutSetError: action.payload };
        case TRYING_DELETE_WORKOUT_SET_SUCCESS:
            return { ...state, TryingDeleteWorkoutSet: false, TryingDeleteWorkoutSetError: null };

        case TRYING_FREEZE_WORKOUT_FOR_PRINT:
            return { ...state, TryingFreezeWorkout: true, TryingFreezeWorkoutError: null };
        case TRYING_FREEZE_WORKOUT_FOR_PRINT_ERROR:
            return { ...state, TryingFreezeWorkout: false, TryingFreezeWorkoutError: action.payload };
        case TRYING_FREEZE_WORKOUT_FOR_PRINT_SUCCESS:
            return { ...state, TryingFreezeWorkout: false, TryingFreezeWorkoutError: null };

        case CLEAR_WORKOUT_DETAIL:
            return { ...state, AdditionalActivityDetails: {}, WorkoutDetails: {}, WorkoutExercises: [], WorkoutLaps: [] };
        case TRYING_GET_WORKOUT_DETAIL:
            return { ...state, TryingGetWorkoutDetail: true, TryingGetWorkoutDetailError: null };
        case TRYING_GET_WORKOUT_DETAIL_ERROR:
            return { ...state, TryingGetWorkoutDetail: false, TryingGetWorkoutDetailError: action.payload };
        case TRYING_GET_WORKOUT_DETAIL_SUCCESS:
            return { ...state, ...action.payload, TryingGetWorkoutDetail: false, TryingGetWorkoutDetailError: null };

        case TRYING_GET_WORKOUT_NOTES:
            return { ...state, TryingGetWorkoutNotes: true, TryingGetWorkoutNotesError: null };
        case TRYING_GET_WORKOUT_NOTES_ERROR:
            return { ...state, TryingGetWorkoutNotes: false, TryingGetWorkoutNotesError: action.payload };
        case TRYING_GET_WORKOUT_NOTES_SUCCESS:
            return { ...state, WorkoutDetail: { ...state.WorkoutDetails, ...action.payload }, TryingGetWorkoutNotes: false, TryingGetWorkoutNotesError: null };

        case TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY:
            return { ...state, TryingInsertEditAdditionalActivity: true, TryingInsertEditAdditionalActivityError: null };
        case TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY_ERROR:
            return { ...state, TryingInsertEditAdditionalActivity: false, TryingInsertEditAdditionalActivityError: action.payload };
        case TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY_SUCCESS:
            return { ...state, WorkoutDetails: { ...state.WorkoutDetails, WorkoutName: action.payload.ActivityName }, TryingInsertEditAdditionalActivity: false, TryingInsertEditAdditionalActivityError: null };

        case TRYING_INSERTEDIT_WORKOUT_NOTE:
            return { ...state, TryingInsertEditWorkoutNote: true, TryingInsertEditWorkoutNoteError: null };
        case TRYING_INSERTEDIT_WORKOUT_NOTE_ERROR:
            return { ...state, TryingInsertEditWorkoutNote: false, TryingInsertEditWorkoutNoteError: action.payload };
        case TRYING_INSERTEDIT_WORKOUT_NOTE_SUCCESS:
            return { ...state, TryingInsertEditWorkoutNote: false, TryingInsertEditWorkoutNoteError: null };

        case TRYING_REGENERATE_MAXSPEED:
            return { ...state, TryingRegenerateMaxSpeed: true, TryingRegenerateMaxSpeedError: null };
        case TRYING_REGENERATE_MAXSPEED_ERROR:
            return { ...state, TryingRegenerateMaxSpeed: false, TryingRegenerateMaxSpeedError: action.payload };
        case TRYING_REGENERATE_MAXSPEED_SUCCESS:
            return { ...state, TryingRegenerateMaxSpeed: false, TryingRegenerateMaxSpeedError: null };

        case TRYING_REGENERATE_TESTSET:
            return { ...state, TryingRegenerateTestSet: true, TryingRegenerateTestSetError: null };
        case TRYING_REGENERATE_TESTSET_ERROR:
            return { ...state, TryingRegenerateTestSet: false, TryingRegenerateTestSetError: action.payload };
        case TRYING_REGENERATE_TESTSET_SUCCESS:
            return { ...state, TryingRegenerateTestSet: false, TryingRegenerateTestSetError: null };

        case TRYING_REORDER_EXERCISES:
            return { ...state, TryingReorderExercises: true, TryingReorderExercisesError: null };
        case TRYING_REORDER_EXERCISES_ERROR:
            return { ...state, TryingReorderExercises: false, TryingReorderExercisesError: action.payload };
        case TRYING_REORDER_EXERCISES_SUCCESS:
            return { ...state, TryingReorderExercises: false, TryingReorderExercisesError: null };

        case TRYING_UPDATE_WORKOUT_INFORMATION:
            return { ...state, TryingUpdateWorkoutInformation: true, TryingUpdateWorkoutInformationError: null };
        case TRYING_UPDATE_WORKOUT_INFORMATION_ERROR:
            return { ...state, TryingUpdateWorkoutInformation: false, TryingUpdateWorkoutInformationError: action.payload };
        case TRYING_UPDATE_WORKOUT_INFORMATION_SUCCESS:
            return { ...state, TryingUpdateWorkoutInformation: false, TryingUpdateWorkoutInformationError: null };

        case TRYING_UPDATE_WORKOUT_LAP:
            return { ...state, TryingUpdateWorkoutLap: true, TryingUpdateWorkoutLapError: null };
        case TRYING_UPDATE_WORKOUT_LAP_ERROR:
            return { ...state, TryingUpdateWorkoutLap: false, TryingUpdateWorkoutLapError: action.payload };
        case TRYING_UPDATE_WORKOUT_LAP_SUCCESS:
            return { ...state, TryingUpdateWorkoutLap: false, TryingUpdateWorkoutLapError: null };
        
        case TRYING_UPDATE_WORKOUT_SET:
            return { ...state, TryingUpdateWorkoutSet: true, TryingUpdateWorkoutSetError: null };
        case TRYING_UPDATE_WORKOUT_SET_ERROR:
            return { ...state, TryingUpdateWorkoutSet: false, TryingUpdateWorkoutSetError: action.payload };
        case TRYING_UPDATE_WORKOUT_SET_SUCCESS:
            return { ...state, TryingUpdateWorkoutSet: false, TryingUpdateWorkoutSetError: null };
        default:
            return state;
    }
};
