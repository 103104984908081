import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { ImageContainer, ImageInputContainer, StyledInput, StyledInputIcon } from './styles';

import { getFileExtension, validateFile } from '../../Functions';

import AbelModal from '../../Components/Modal';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import UploadProgressComponent from '../../Components/UploadProgress';

import Camera from '../../Assets/Icons/PlusButton_Inactive.png';

class ImageUpload extends React.Component {
    state = {
        GroupImage: this.props.CurrentImage,
        ImageTooLarge: false,
        MaxImageSize: 10000000,
        SelectedImage: null,
        WrongImageType: false
    }

    onClickFileOpen = () => {
        document.getElementById('ImageUpload').click();
    }

    onClickSubmit = () => {
        var { SelectedImage } = this.state;

        this.props.OnSaveImage(SelectedImage);
    }

    onDeleteImage = () => {
        this.setState({ GroupImage: '', ImageTooLarge: false, IsImageNew: false, SelectedImage: 0, WrongImageType: false });
    }

    onSelectGroupImage = event => {
        if (event.target.files.length) {
            var SelectedImage = event.target.files[0];
            var FileExtension = getFileExtension(SelectedImage.name);

            if (validateFile({ FileExtension, FileType: 'Images' })) {
                if (SelectedImage.size <= this.state.MaxImageSize) {
                    this.setState({ GroupImage: URL.createObjectURL(SelectedImage), ImageTooLarge: false, IsImageNew: true, SelectedImage, WrongImageType: false});
                } else {
                    document.getElementById('ImageUpload').value = null;
                    this.setState({ ImageTooLarge: true, WrongImageType: false });
                }
            } else this.setState({ ImageTooLarge: false, WrongImageType: true });
        }
    }

    renderModalBody = () => {
        var { t } = this.props;
        var  { UploadProgress } = this.props;
        var { GroupImage, ImageTooLarge, WrongImageType } = this.state;

        var Buttons = [{ BackgroundColor: 'white', BackgroundColorHover: 'blue-abel', Color: 'black', ColorHover: 'white', FontFamily: 'semibold', OnClick: this.onClickFileOpen, Title: t('Upload') }];
        if (GroupImage !== '') Buttons.push({ BackgroundColor: 'white', BackgroundColorHover: 'red-bittersweet', Color: 'black', ColorHover: 'white', FontFamily: 'semibold', OnClick: this.onDeleteImage, Title: t('delete_with_first_char_uppercase') })

        return (
            <>
                {this.props.Loading && <Loading />}

                <ImageContainer>
                    <ImageInputContainer BackgroundImage={GroupImage}>
                        {GroupImage && <StyledInputIcon ImageSrc={Camera} Loading={false} />}

                        <StyledInput
                            accept="image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg"
                            id="ImageUpload"
                            type="file"
                            onChange={this.onSelectGroupImage}
                        />
                    </ImageInputContainer>

                    {
                        ImageTooLarge || WrongImageType ?
                        <>
                            <Spacer Size="small" />

                            <PageText
                                FontColor="red-bittersweet"
                                FontFamily="semibold-italic"
                                FontSize="medium-1"
                                Text={t(ImageTooLarge ? 'media_wrongsize' : 'media_wrongtype', { AllowedTypes: '.jpg, .png, .jpeg', MaxSize: '10MB', Type: 'Image' })}
                                TextAlign="center"
                            />
                        </>
                    :
                        null
                    }
                </ImageContainer>

                <Spacer Size="small" />

                <ButtonGroup
                    Buttons={Buttons}
                    ButtonWidth="fit-content"
                    NotTouching
                />

                {
                    UploadProgress && Object.entries(UploadProgress).length > 0 && 
                        <>
                            <Spacer Size="medium" />

                            <UploadProgressComponent UploadingItems={UploadProgress} />
                        </>
                }
            </>
        );
    }

    render() {
        var { t } = this.props;
        var { OnHide, Show } = this.props;

        return (
            <AbelModal
                BottomButton="submit"
                BottomButtonOnClick={this.onClickSubmit}
                BottomButtonText={t('save_with_first_char_uppercase')}
                GA_PathName="ImageUpload"
                Show={Show}
                Size="medium"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

ImageUpload.propTypes = {
    CurrentImage: PropTypes.string,
    Loading: PropTypes.bool,
    OnHide: PropTypes.func.isRequired,
    OnSaveImage: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired,
    UploadProgress: PropTypes.object
}

ImageUpload.defaultProps = {
    CurrentImage: '',
    Loading: false,
    OnSaveImage: () => null,
    UploadProgress: null
}

export default withTranslation()(ImageUpload);