import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearSearchFood, SearchFood_SAYT, SearchFood } from '../../Services/Actions';

import { StyledSAYT } from './styles';

import AbelModal from '../../Components/Modal';
import PageText from '../../Components/Text/PageText';
import InfiniteScroll from '../../Components/Lists/InfiniteScroll';
import Spacer from '../../Components/Spacer';

const INITIAL_STATE = {
    PageNo: 1, PageSize: 50
}

class AddFood extends React.Component {
    _isMounted = false;

    state = {
        ...INITIAL_STATE,
        ActiveSearch: false,
        CompletedFirstSearch: false,
        ExcludeFoodIds: this.props.ExcludeFoodIds || [],
        SearchValue: '',
        IngredientsShown: this.props.IngredientsShown || [ 1, 2 ],
        LastSearchedValue: '',
        Loading: false,
        NewSearch: false
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.ClearSearchFood();
    }

    onChangeSearch = event => {
        this.setState({ SearchValue: event.target.value });
    }

    onKeyPress = ({ key }) => {
        if (key === 'Enter' && !this.props.TryingSearchFood) {
            var { SearchValue, LastSearchedValue } = this.state;

            if (SearchValue !== LastSearchedValue) {
                this.setState({ PageNo: 1, PageSize: 50, LastSearchedValue: SearchValue }, () => {
                    if (SearchValue !== '') this.onLoadFood();
                });
            }
        }
    }

    onLoadFood = Increment => {
        var { ExcludeFoodIds, IngredientsShown, NewSearch, PageNo, PageSize, SearchValue } = this.state;

        if (Increment) {
            NewSearch = false;
            PageNo = PageNo + 1;
        } else NewSearch = true;

        var SearchFunc = this.props.SearchFunc || this.props.SearchFood;

        this.setState({ ActiveSearch: true, NewSearch, PageNo }, () => SearchFunc({ AddToExisting: Increment, ExcludeFoodIds, FoodName: SearchValue, IngredientsShown, PageNo, PageSize }).then(() => this.setState({ ActiveSearch: false, CompletedFirstSearch: true })));
    }

    onSearchFromSAYT = SearchValue => {
        if (!this.props.TryingSearchFood) {
            var { LastSearchedValue } = this.state;

            if (SearchValue !== LastSearchedValue) {
                this.setState({ PageNo: 1, PageSize: INITIAL_STATE.PageSize, LastSearchedValue: SearchValue, SearchValue }, () => this.onLoadFood());
            }
        }
    }

    onSelectFood = Food => {
        this.props.OnAddFood(Food);
    }

    renderModalBody = () => {
        var { t } = this.props;

        var { FoodList, FoodList_TotalRecords, IsMoreFood, TryingSearchFood } = this.props;
        var { ActiveSearch, CompletedFirstSearch, ExcludeFoodIds, NewSearch, PageNo, PageSize } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="large" NoMargin Text={t('MealPlan_Add_ingredient')} />

                <Spacer Size="medium" />

                {/* <StyledInput
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeSearch}
                    OnKeyPress={this.onKeyPress}
                    Placeholder={t('MealPlan_search_ingredient')}
                    Size="medium"
                    Type="text"
                    Value={FoodName}
                /> */}

                <StyledSAYT
                    ExtraSearchProps={{ ExcludeFoodIds, IngredientsShown: [ 1, 2 ]}}
                    ItemName="FoodName"
                    OnSearch={this.onSearchFromSAYT}
                    Placeholder={t('search_with_first_char_uppercase_dots')}
                    SearchFunc={this.props.SearchFood_SAYT}
                />

                <Spacer Size="small" />

                <InfiniteScroll
                    ActiveSearch={ActiveSearch}
                    CompletedFirstSearch={CompletedFirstSearch}
                    IsLoading={TryingSearchFood}
                    ItemDescriptionType={t('foods')}
                    ItemId="FoodId"
                    ItemName="FoodName"
                    Items={FoodList}
                    HasMoreItems={IsMoreFood}
                    LoadMore={() => this.onLoadFood(true)}
                    NewSearch={NewSearch}
                    NoItemsText={t('search_noresults')}
                    OnSelectItem={Food => this.onSelectFood(Food)}
                    PageNo={PageNo}
                    PageSize={PageSize}
                    TotalRecords={FoodList_TotalRecords}
                />
            </>
        );
    }

    render() {
        var { OnHide, Show } = this.props;

        return (
            <AbelModal
                GA_PathName="AddFood"
                Show={Show}
                Size="medium"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

AddFood.propTypes = {
    ExcludeFoodIds: PropTypes.array,
    IngredientsShown: PropTypes.array,
    OnHide: PropTypes.func.isRequired,
    OnRefresh: PropTypes.func,
    SearchFunc: PropTypes.func,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        FoodList: state.Food.FoodList,
        FoodList_TotalRecords: state.Food.FoodList_TotalRecords,
        IsMoreFood: state.Food.IsMoreFood,

        TryingSearchFood: state.Food.TryingSearchFood,
        TryingSearchFoodError: state.Food.TryingSearchFoodError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearSearchFood, SearchFood_SAYT, SearchFood } )(AddFood));