import {
    TRYING_ADD_GROUP_WORKOUT_MEMBERS,
    TRYING_ADD_GROUP_WORKOUT_MEMBERS_SUCCESS,
    TRYING_ADD_GROUP_WORKOUT_MEMBERS_ERROR,

    TRYING_EDIT_GROUP_WORKOUT_DETAIL,
    TRYING_EDIT_GROUP_WORKOUT_DETAIL_SUCCESS,
    TRYING_EDIT_GROUP_WORKOUT_DETAIL_ERROR,

    CLEAR_WORKOUT_DETAIL,
    TRYING_GET_GROUP_WORKOUT_DETAIL,
    TRYING_GET_GROUP_WORKOUT_DETAIL_SUCCESS,
    TRYING_GET_GROUP_WORKOUT_DETAIL_ERROR,

    TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS,
    TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS_SUCCESS,
    TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS_ERROR
} from '../Types';

const INITIAL_STATE = {
    AdditionalActivityDetails: {},
    ClientNotes: [],
    GroupDetails: {},
    GroupMembers: {},
    WorkoutDetails: {},
    WorkoutExercises: [],
    WorkoutLaps: { Laps: [] },

    TryingAddGroupWorkoutMembers: false,
    TryingAddGroupWorkoutMembersError: null,
    TryingEditGroupWorkoutDetail: false,
    TryingEditGroupWorkoutDetailError: null,
    TryingGetGroupWorkoutDetail: false,
    TryingGetGroupWorkoutDetailError: null,
    TryingSearchGroupMemberWorkoutCompletionStatus: false,
    TryingSearchGroupMemberWorkoutCompletionStatusError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_ADD_GROUP_WORKOUT_MEMBERS:
            return { ...state, TryingAddGroupWorkoutMembers: true, TryingAddGroupWorkoutMembersError: null };
        case TRYING_ADD_GROUP_WORKOUT_MEMBERS_ERROR:
            return { ...state, TryingAddGroupWorkoutMembers: false, TryingAddGroupWorkoutMembersError: action.payload };
        case TRYING_ADD_GROUP_WORKOUT_MEMBERS_SUCCESS:
            // eslint-disable-next-line
            var { GroupMembers, IsMoreResults, TotalRecords } = action.payload;
            var AddGroupWorkoutMembers_WorkoutCompletionStatus = [];

            AddGroupWorkoutMembers_WorkoutCompletionStatus = [ ...GroupMembers ];

            return { ...state, GroupMembers: { GroupMembers: AddGroupWorkoutMembers_WorkoutCompletionStatus, IsMoreResults, TotalRecords }, TryingAddGroupWorkoutMembers: false, TryingAddGroupWorkoutMembersError: null };

        case TRYING_EDIT_GROUP_WORKOUT_DETAIL:
            return { ...state, TryingEditGroupWorkoutDetail: true, TryingEditGroupWorkoutDetailError: null };
        case TRYING_EDIT_GROUP_WORKOUT_DETAIL_ERROR:
            return { ...state, TryingEditGroupWorkoutDetail: false, TryingEditGroupWorkoutDetailError: action.payload };
        case TRYING_EDIT_GROUP_WORKOUT_DETAIL_SUCCESS:
            return { ...state, TryingEditGroupWorkoutDetail: false, TryingEditGroupWorkoutDetailError: null };

        case CLEAR_WORKOUT_DETAIL:
            return { ...state, ClientNotes: [], GroupDetails: {}, GroupMembers: {}, WorkoutDetails: {}, WorkoutExercises: [], WorkoutLaps: { Laps: [] } };
        case TRYING_GET_GROUP_WORKOUT_DETAIL:
            return { ...state, TryingGetGroupWorkoutDetail: true, TryingGetGroupWorkoutDetailError: null };
        case TRYING_GET_GROUP_WORKOUT_DETAIL_ERROR:
            return { ...state, TryingGetGroupWorkoutDetail: false, TryingGetGroupWorkoutDetailError: action.payload };
        case TRYING_GET_GROUP_WORKOUT_DETAIL_SUCCESS:
            return { ...state, ...action.payload, TryingGetGroupWorkoutDetail: false, TryingGetGroupWorkoutDetailError: null };

        case TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS:
            return { ...state, TryingSearchGroupMemberWorkoutCompletionStatus: true, TryingSearchGroupMemberWorkoutCompletionStatusError: null };
        case TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS_ERROR:
            return { ...state, TryingSearchGroupMemberWorkoutCompletionStatus: false, TryingSearchGroupMemberWorkoutCompletionStatusError: action.payload };
        case TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS_SUCCESS:
            // eslint-disable-next-line
            var { AddToExisting, GroupMembers, IsMoreResults, TotalRecords } = action.payload;
            var SearchGroupMemberWorkoutCompletionStatus_GroupMembers = [];

            if (AddToExisting) SearchGroupMemberWorkoutCompletionStatus_GroupMembers = [ ...state.GroupMembers.GroupMembers, ...GroupMembers ];
            else SearchGroupMemberWorkoutCompletionStatus_GroupMembers = [ ...GroupMembers ];

            return { ...state, GroupMembers: { GroupMembers: SearchGroupMemberWorkoutCompletionStatus_GroupMembers, IsMoreResults, TotalRecords }, TryingSearchGroupMemberWorkoutCompletionStatus: false, TryingSearchGroupMemberWorkoutCompletionStatusError: null };

        default:
            return state;
    }
};
