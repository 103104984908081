import styled from 'styled-components';

import Icon from '../../Components/Icon';
import Input from '../../Components/Form/Input';

export const AddCoachPlusIcon = styled(Icon)`
    height: 20px;
    margin-right: 10px;
    width: 20px;
`;

export const AddCoachTextContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center
`;

export const Container = styled.div``;

export const EmailsContainer = styled.div``;

export const SharingContentContainer = styled.div`
    > div:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const StyledEmailInput = styled(Input)``;