import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddExistingWorkoutTemplate, AddTrainingProgram_Modified, AddNewWorkout, GetAdditionalActivityDetails, GetTrainingProgramDetail, GetWorkoutTemplateDetail, UploadToPresignedUrl } from '../../Services/Actions';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-popover';
import Select from 'react-select';
import TimePicker from 'rc-time-picker';

import * as styles from './styles';

import ActiveLap from '../WorkoutLaps/ActiveLap';
import BreakLap from '../WorkoutLaps/BreakLap';
import AssignClients from '../AssignClients';
import ButtonGroup from '../Buttons/ButtonGroup';
import CalendarOverlay from '../../Pages/TrainingProgram/CalendarOverlay';
import { CollapsedExercise as CollapsedExerciseComponent } from '../WorkoutExercise';
import Loading from '../Loading';
import MiniCalendar from '../Calendar/MiniCalendar';
import MultiText from '../Text/MultiText';
import PageText from '../Text/PageText';
import Spacer from '../Spacer';
import { TemplateExercise as TemplateExerciseComponent } from '../WorkoutExercise';
import Toggle from '../Form/Toggle';
import UploadProgressComponent from '../UploadProgress';

import AddExercise from '../../Modals/AddExercise';
import ExerciseDetail from '../../Modals/ExerciseDetail';
import Info from '../../Modals/Info';

// import TrainingPrograms from '../../Pages/Library/TrainingPrograms';
// import WorkoutTemplates from '../../Pages/Library/WorkoutTemplates';
import TrainingPrograms from '../../Pages/Library2/Workout/TrainingPrograms';
import WorkoutTemplates from '../../Pages/Library2/Workout/WorkoutTemplates';

import { CreateUploadToPresignedUrlPromise, getFileExtension, GetSetUnits, validateFile } from '../../Functions';

// import AbelWizard from '../../Assets/Illustrations/AbelWizard.png';
import Activity from '../../Assets/Illustrations/Activity.png';
import AdditionalActivityIcon from '../../Assets/Icons/AdditionalActivity.png';
import Calendar from '../../Assets/Icons/Calendar_White.png';
import Camera from '../../Assets/Icons/Camera.png';
import DeleteX from '../../Assets/Icons/X_White.png';
import DownArrow from '../../Assets/Icons/DownArrow.png';
import Endurance from '../../Assets/Illustrations/Endurance_Blue_Small.png';
import EnduranceIcon from '../../Assets/Icons/Endurance.png';
import FromScratchBase from '../../Assets/Illustrations/FromScratchBase.png';
import InfoIcon from '../../Assets/Icons/Info.png';
import Pencil_White from '../../Assets/Icons/Pencil_White.png';
import Plus from '../../Assets/Icons/Plus.png';
import PlusButton_Active from '../../Assets/Icons/PlusButton_Active.png';
import PlusSign_Dark from '../../Assets/Icons/PlusSign.png';
import PlusSign_White from '../../Assets/Icons/PlusSign_White.png';
import Reorder from '../../Assets/Icons/Reorder_Dark.png';
import Strength from '../../Assets/Illustrations/Strength_Small.png';
import StrengthIcon from '../../Assets/Icons/Strength.png';
import TemplateBase from '../../Assets/Illustrations/TemplateBase.png';
import TrainingProgram from '../../Assets/Illustrations/WorkoutPlan.png';
import UpArrow from '../../Assets/Icons/UpArrow.png';
import WorkoutRestDay from '../../Assets/Illustrations/WorkoutRestDay.png';

const DefaultCustomSettings = {
    CustomSettings: {
      Location: "",
      Location_IsCustom: false,
      Name: "",
      Name_IsCustom: false,
      Note: "",
      Note_IsCustom: false,
      StartTime: "",
      StartTime_IsCustom: false
    },
    ShowCustomSettings: false
}

const DefaultOptionalDetails = {
    ActivityId: null,
    ActivityName: '',
    ActivityPicture: null,
    ActivityPictureId: null,
    ActivityPictures: [],
    CustomImage: 0,
    Duration: 0,
    ImageTooLarge: false,
    Location: '',
    Name: '',
    SelectedImage: null,
    StartTime: moment().format('YYYY-MM-DD HH:mm'),
    WorkoutImage: null,
    WrongImageType: false
}

const DropdownStyles = {
    control: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '200px' }),
    input: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    menu: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    option: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '200px' })
};

const HowToBuildSessionTypes = {
    Activity: [
        { HowToBuildType: 'Scratch', ImageName: FromScratchBase, PopoverTranslation: null, Translation: 'addworkout_create_session_from_scratch' },
        { HowToBuildType: 'Template', ImageName: TemplateBase, PopoverTranslation: 'addworkout_create_session_from_template_description', Translation: 'addworkout_create_session_from_template' }
    ],
    Endurance: [
        { HowToBuildType: 'Scratch', ImageName: FromScratchBase, PopoverTranslation: null, Translation: 'addworkout_create_session_from_scratch' },
        { HowToBuildType: 'Template', ImageName: TemplateBase, PopoverTranslation: 'addworkout_create_session_from_template_description', Translation: 'addworkout_create_session_from_template' },
        // { HowToBuildType: 'Algorithm', ImageName: AbelWizard, PopoverTranslation: null, Translation: 'addworkout_create_session_from_algorithm', Translation2: 'addworkout_create_session_from_algorithm2' }
    ],
    Strength: [
        { HowToBuildType: 'Scratch', ImageName: FromScratchBase, PopoverTranslation: null, Translation: 'addworkout_create_session_from_scratch' },
        { HowToBuildType: 'Template', ImageName: TemplateBase, PopoverTranslation: 'addworkout_create_session_from_template_description', Translation: 'addworkout_create_session_from_template' },
        // { HowToBuildType: 'Algorithm', ImageName: AbelWizard, PopoverTranslation: null, Translation: 'addworkout_create_session_from_algorithm', Translation2: 'addworkout_create_session_from_algorithm2' }
    ]
}

const WorkoutTypes = [
    { ImageName: Strength, PopoverTranslation: 'addworkout_strength_description', Translation: 'Strength', WorkoutType: 'Strength' },
    { ImageName: Endurance, PopoverTranslation: 'addworkout_endurance_description', Translation: 'Endurance', WorkoutType: 'Endurance' },
    { ImageName: Activity, PopoverTranslation: 'addworkout_activity_description', Translation: 'WorkoutPlan_Activity', WorkoutType: 'Activity' },
    { ImageName: TrainingProgram, PopoverTranslation: 'addworkout_trainingprogram_description', Translation: 'add_workouts_from_training_program', WorkoutType: 'TrainingProgram' }
]

const CUSTOM = {
    CustomSettings: {
        Location: '', Location_IsCustom: false,
        Name: '', Name_IsCustom: false,
        Note: '', Note_IsCustom: false,
        StartTime: '', StartTime_IsCustom: false
    },
    ShowCustomSettings: false
};

const OPACITY = 0.5;

const ConvertTrainingProgramToDetails = TrainingProgram => {
    var { Days, NumDays: TemplateDays, ProgramDescription, ProgramName } = TrainingProgram;

    var NewTrainingProgram = {
        DayIds: [],
        Days: [],
        ModifiedUniqueTemplateIds: [],
        ProgramDescription,
        ProgramName,
        TemplateDays,
        TrainingProgramId: TrainingProgram.TrainingProgramId,
        WorkoutTemplates: {}
    }

    var TemplateDetails = {};

    var DayId;
    for (var i = 0; i < Days.length; i++) {
        DayId = `Day-${Days[i].DayNr}`;

        NewTrainingProgram.DayIds.push(DayId);
        NewTrainingProgram.Days.push({ DayId, WorkoutTemplateIds: [], WorkoutTemplates: [] });

        for (var a = 0; a < Days[i].Workouts.length; a++) {
            var { CustomSettings, TemplateDetails: { Location, OwnerDetails, StartTime, TemplateName, TrainerNote, Units, WorkoutType }, TemplateId } = Days[i].Workouts[a];

            CustomSettings = MakeCustomSettings(CustomSettings);

            TemplateDetails = { CustomSettings, IsModified: 0, RetrievedFullTemplateData: 0, FullTemplateData: {}, TemplateDetails: { Location, StartTime, TemplateName, TrainerNote }, TemplateId, TemplateName, Units, ...OwnerDetails, WorkoutType };

            if (!NewTrainingProgram.WorkoutTemplates.hasOwnProperty(`TemplateId-${TemplateId}`)) NewTrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`] = { ...TemplateDetails, CustomSettings: DefaultCustomSettings };
            // else {
            //     if (NewTrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].Days.hasOwnProperty(DayId)) NewTrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].Days[DayId].push(a);
            //     else NewTrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].Days[DayId] = [ a ];
            // }

            NewTrainingProgram.Days[NewTrainingProgram.Days.length - 1].WorkoutTemplateIds.push(`TemplateId-${TemplateId}`);
            NewTrainingProgram.Days[NewTrainingProgram.Days.length - 1].WorkoutTemplates.push(TemplateDetails);
        }
    }

    return NewTrainingProgram;
}

const ConvertWorkoutExercisesForAPI = ({ RemoveWorkoutExerciseIds, WorkoutExercises }) => {
    var NewWorkoutExercises = [];

    for (let i = 0; i < WorkoutExercises.length; i++) {
        let { CircuitNumber, WorkoutExercises: WEArr } = WorkoutExercises[i];

        WEArr.map(WorkoutExercise => {
            var { DataType, MaxType, Notes } = WorkoutExercise;
            DataType = DataType === 'static' ? 'static' : MaxType === 'Custom' ? 'dynamic-custom' : DataType;
            MaxType = (DataType === 'static' || MaxType === 'Custom') ? 'Progress' : MaxType;

            var ExerciseNotes = Notes.Coach;

            delete WorkoutExercise.Collapsed;

            if (!!RemoveWorkoutExerciseIds) WorkoutExercise.WorkoutExerciseId = 0;

            NewWorkoutExercises.push(({ ...WorkoutExercise, CircuitNumber: CircuitNumber || 0, DataType, ExerciseNotes, MaxType }));

            return null;
        });
    }

    return NewWorkoutExercises;
}

const MakeCustomSettings = (CustomSettings = {}) => {
    var NewCustomSettings = { ...CUSTOM, CustomSettings: { ...CUSTOM.CustomSettings, ...CustomSettings } };

    if (CustomSettings.hasOwnProperty('Location') && CustomSettings.Location !== '') NewCustomSettings.CustomSettings.Location_IsCustom = true;
    if (CustomSettings.hasOwnProperty('Name') && CustomSettings.Name !== '') NewCustomSettings.CustomSettings.Name_IsCustom = true;
    if (CustomSettings.hasOwnProperty('Note') && CustomSettings.Note !== '') NewCustomSettings.CustomSettings.Note_IsCustom = true;
    if (CustomSettings.hasOwnProperty('StartTime') && CustomSettings.StartTime !== '') NewCustomSettings.CustomSettings.StartTime_IsCustom = true;

    return NewCustomSettings;
}

class ButtonWithHoverSpot extends React.Component {
    TimeoutId = null;

    state = { PopoverIsOpen: false };

    onTogglePopover = () => {
        this.setState({ PopoverIsOpen: !this.state.PopoverIsOpen }, () => {
            if (!this.state.PopoverIsOpen && !!this.TimeoutId) clearTimeout(this.TimeoutId);
        });
    }

    renderPopoverContent = () => {
        var { t } = this.props;
        var { PopoverText } = this.props;

        return (
            <styles.PopoverContent className="PopoverContent">
                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t(PopoverText || '')} TextAlign="center" />
            </styles.PopoverContent>
        );
    }

    render() {
        var { t } = this.props;
        var { Device, ImageName, OnClick, PopoverText, Selected, TranslationKey, TranslationKey2 } = this.props;

        const popoverProps = {
            isOpen: this.state.PopoverIsOpen,
            preferPlace: 'below',
            onOuterAction: this.onTogglePopover,
            body: this.renderPopoverContent(),
            tipSize: 0.01
        }

        return (
            <styles.MenuItem onClick={OnClick} Selected={Selected}>
                <styles.MenuItemContainerFirst>
                    {
                        !!PopoverText &&
                        <Popover {...popoverProps}>
                            <styles.HoverInfoIcon ImageSrc={InfoIcon} OnMouseEnter={this.onTogglePopover} OnMouseLeave={this.onTogglePopover} />
                        </Popover>
                    }

                    <styles.MenuItemIllustration alt={t(TranslationKey)} src={ImageName} />
                </styles.MenuItemContainerFirst>

                {(Device === 'ipad' || Device === 'laptop') && <Spacer Size="small" />}

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent={(Device === 'ipad' || Device === 'laptop') ? 'center' : 'flex-start'} NoMargin Text={t(TranslationKey)} TextAlign={(Device === 'ipad' || Device === 'laptop') ? 'center' : 'left'} />

                {
                    !!TranslationKey2 &&
                    <>
                        <Spacer Size="extra-extra-small" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent={(Device === 'ipad' || Device === 'laptop') ? 'center' : 'flex-start'} NoMargin Text={t(TranslationKey2)} TextAlign={(Device === 'ipad' || Device === 'laptop') ? 'center' : 'left'} />
                    </>
                }
            </styles.MenuItem>
        );
    }
}

class DayColumn extends React.Component {
    render() {
        var { t } = this.props;

        var IsRestDay = this.props.WorkoutTemplates.length === 0;

        return (
            <styles.TrainingProgramDayContainer className="TrainingProgramDayContainer">
                <styles.TrainingProgramDayHeaderContainer className="TrainingProgramDayHeaderContainer" IsRestDay={IsRestDay}>
                    <styles.TrainingProgramDayHeaderTopRowContainer className="TrainingProgramDayHeaderTopRowContainer" IsRestDay={IsRestDay}>
                        <PageText FontColor="white" FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('day')} ${this.props.DayNumber}`} TextAlign="left" />

                        <ButtonGroup
                            Buttons={[
                                { BackgroundColor: 'white', BackgroundColorHover: 'white-hover', Border: 'none', Icon: PlusSign_Dark, IconOnly: true, IconSize: { Height: '15px', Width: '15px' }, Margin: '3px', OnClick: this.props.onToggleShowAddWorkoutTemplateToProgramModal, Padding: '7px', Size: 'small' },
                                { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Icon: DeleteX, IconOnly: true, IconSize: { Height: '15px', Width: '15px' }, Margin: '3px', OnClick: this.props.onRemoveTrainingProgramDay, Padding: '7px', Size: 'small' }
                            ]}
                            NotTouching
                        />
                    </styles.TrainingProgramDayHeaderTopRowContainer>
                </styles.TrainingProgramDayHeaderContainer>

                <Droppable droppableId={this.props.DayId}>
                    {(provided, snapshot) => (
                        <styles.TrainingProgramDayContainer
                            className="TrainingProgramDayContainer"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            isDraggingOver={snapshot.isDraggingOver}
                        >
                            <InnerList
                                t={t}
                                DayId={this.props.DayId}
                                IsUnique={false}
                                WorkoutTemplates={this.props.WorkoutTemplates}
                                WorkoutTemplateIds={this.props.WorkoutTemplateIds}
                                DayIndex={this.props.DayIndex}
                                ModifiedUniqueTemplateIds={this.props.ModifiedUniqueTemplateIds}
                                TemplateDays={this.props.TemplateDays}
                                IsDragging={this.props.IsDragging}
                                onChangeCustomSettingDisplay={this.props.onChangeCustomSettingDisplay}
                                onChangeCustomSetting={({ DayIndex, SettingName, Value, WorkoutTemplateIndex }) => this.props.onChangeCustomSetting({ DayIndex, SettingName, Value, WorkoutTemplateIndex })}
                                onClickShowEditWorkoutTemplate={({ TemplateId, WorkoutTemplateIndex }) => this.props.onClickShowEditWorkoutTemplate({ DayIndex: this.props.DayIndex, TemplateId, WorkoutTemplateIndex })}
                                onMoveWorkoutTemplate={this.props.onMoveWorkoutTemplate}
                                onRemoveWorkoutTemplate={({ DayIndex, TemplateId, WorkoutTemplateIndex }) => this.props.onRemoveWorkoutTemplate({ DayIndex, TemplateId, WorkoutTemplateIndex })}
                                ViewTemplate={this.props.ViewTemplate}
                            />
                            {provided.placeholder}
                        </styles.TrainingProgramDayContainer>
                    )}
                </Droppable>
            </styles.TrainingProgramDayContainer>
        );
    }
}

class InnerList extends React.Component {
    render() {
        var { t } = this.props;

        if (!this.props.WorkoutTemplates.length) {
            return (
                <styles.RestDayContainer className="RestDayContainer">
                    <PageText FontColor="white" FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('rest_day').toUpperCase()} />

                    <Spacer Size="small" />

                    <styles.RestDayImage ImageSrc={WorkoutRestDay} SelfLoading />
                </styles.RestDayContainer>
            );
        }

        return (
            this.props.WorkoutTemplates.map((DayWorkoutTemplate, index) => (
                <WorkoutTemplate
                    key={index}
                    t={t}
                    DayId={this.props.DayId}
                    IsDragging={this.props.IsDragging}
                    WorkoutTemplate={DayWorkoutTemplate}
                    DayIndex={this.props.DayIndex}
                    ModifiedUniqueTemplateIds={this.props.ModifiedUniqueTemplateIds}
                    TemplateDays={this.props.TemplateDays}
                    WorkoutTemplateIndex={index}
                    onChangeCustomSettingDisplay={this.props.onChangeCustomSettingDisplay}
                    onChangeCustomSetting={this.props.onChangeCustomSetting}
                    onClickShowEditWorkoutTemplate={() => this.props.onClickShowEditWorkoutTemplate({ TemplateId: DayWorkoutTemplate.TemplateId, WorkoutTemplateIndex: index })}
                    onMoveWorkoutTemplate={this.props.onMoveWorkoutTemplate}
                    onRemoveWorkoutTemplate={this.props.onRemoveWorkoutTemplate}
                    ViewTemplate={this.props.ViewTemplate}
                />
            )
        ))
    }
}

class HoverIcon extends React.Component {
    TimeoutId = null;

    state = { PopoverIsOpen: false };

    onTogglePopover = () => {
        this.setState({ PopoverIsOpen: !this.state.PopoverIsOpen }, () => {
            if (!this.state.PopoverIsOpen && !!this.TimeoutId) clearTimeout(this.TimeoutId);
        });
    }

    renderPopoverContent = () => {
        var { t } = this.props;
        var { PopoverText } = this.props;

        return (
            <styles.PopoverContent className="PopoverContent">
                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t(PopoverText || '')} TextAlign="center" />
            </styles.PopoverContent>
        );
    }

    render() {
        const popoverProps = {
            isOpen: this.state.PopoverIsOpen,
            preferPlace: 'below',
            onOuterAction: this.onTogglePopover,
            body: this.renderPopoverContent(),
            tipSize: 0.01
        }

        return (
            <Popover {...popoverProps}>
                <styles.HoverInfoIcon ImageSrc={InfoIcon} NotAbsolute={this.props.NotAbsolute} OnMouseEnter={this.onTogglePopover} OnMouseLeave={this.onTogglePopover} />
            </Popover>
        );
    }
}

class WorkoutTemplate extends React.Component {
    state = { CalendarType: null };

    onChangeDateTime = ({ DateTime, ToggleType }) => {
        var { DayIndex, WorkoutTemplateIndex } = this.props;

        this.props.onChangeCustomSetting({ DayIndex, SettingName: ToggleType, Value: moment(DateTime).format('HH:mm'), WorkoutTemplateIndex });
    }

    onChangeInput = ({ event, ToggleType }) => {
        var { DayIndex, WorkoutTemplateIndex } = this.props;

        this.props.onChangeCustomSetting({ DayIndex, SettingName: ToggleType, Value: event.target.value, WorkoutTemplateIndex });
    }

    onChangeToggle = ({ event, ToggleType }) => {
        var { DayIndex, WorkoutTemplateIndex } = this.props;

        this.props.onChangeCustomSetting({ DayIndex, SettingName: ToggleType, Value: event.target.checked, WorkoutTemplateIndex });
    }

    onClickCalendarType = (CalendarType = null) => {
        this.setState({ CalendarType });
    }

    onSelectDays = ({ SelectedDays, TemplateId }) => {
        var MockDrag = {
            destinations: [],
            destination: { droppableId: null, index: null },
            source: { droppableId: null, index: null },
            TemplateId
        }

        // { destination: { droppableId: "Day-3", index: 0 }, source: { index: 0, droppableId: "Day-2" }, draggableId: "10469-1-0" }

        if (this.state.CalendarType === 'Move') {
            MockDrag.destination = { droppableId: `Day-${SelectedDays[0]}`, index: 0 };
            MockDrag.source = { droppableId: this.props.DayId, index: this.props.WorkoutTemplateIndex };
        }
        else {
            MockDrag.ActionType = this.state.CalendarType === 'Copy' ? 'Copy' : 'Add';
            for (var i = 0; i < SelectedDays.length; i++) {
                MockDrag.destinations.push( { droppableId: `Day-${SelectedDays[i]}`, index: 0 })
            }

            MockDrag.source = { droppableId: !!this.props.ShowCalendar ? 'SelectedWorkoutTemplates' : this.props.DayId, index: this.props.WorkoutTemplateIndex };

            delete MockDrag.destination;
        }

        document.body.click();
        this.onClickCalendarType(null);
        this.props.onMoveWorkoutTemplate(MockDrag);
    }

    onToggleShowCustomSettings = () => {
        this.props.onChangeCustomSettingDisplay({ DayIndex: this.props.DayIndex, WorkoutTemplateIndex: this.props.WorkoutTemplateIndex });
    }

    renderWorkoutType = WorkoutType => {
        var IconSrc = WorkoutType === 1 ? StrengthIcon : WorkoutType === 2 ? EnduranceIcon : AdditionalActivityIcon;

        return <styles.TemplateIcon src={IconSrc} alt="Workout Type" />
    }

    render() {
        var { t } = this.props;
        var { IsUnique, ModifiedUniqueTemplateIds, ShowCalendar, TemplateDays } = this.props;
        var { CustomSettings: { CustomSettings, ShowCustomSettings }, IsModified, TemplateId, TemplateName, WorkoutType } = this.props.WorkoutTemplate;
        var { CalendarType } = this.state;

        var StartHour = CustomSettings.StartTime ? CustomSettings.StartTime.split(':')[0] : 0;
        var StartMinutes = CustomSettings.StartTime ? CustomSettings.StartTime.split(':')[1] : 0;

        var TimePickerDate = moment().hour(StartHour).minute(StartMinutes);

        var draggableId = IsUnique ? `${TemplateId}` : `${TemplateId}-${this.props.DayIndex}-${this.props.WorkoutTemplateIndex}`;

        var CalendarPopoverSubmitButtonTitle = t('add_to_these_days');
        var CalendarPopoverTitle = t('training_program_days');

        if (!!CalendarType) {
            CalendarPopoverSubmitButtonTitle = CalendarType === 'Move' ? t('move_to_another_date') : t('copy_to_other_days');
            CalendarPopoverTitle = CalendarType === 'Move' ? t('move') : t('copy');
        }

        var Buttons = [];
        if (WorkoutType !== 3) Buttons.push({ BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Icon: Pencil_White, IconOnly: true, IconSize: { Height: '15px', Width: '15px' }, Margin: '3px', OnClick: this.props.onClickShowEditWorkoutTemplate, Padding: '7px', Size: 'small' });
        Buttons.push({ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Icon: DeleteX, IconOnly: true, IconSize: { Height: '15px', Width: '15px' }, Margin: '3px', OnClick: () => this.props.onRemoveWorkoutTemplate(({ DayIndex: this.props.DayIndex, TemplateId, WorkoutTemplateIndex: this.props.WorkoutTemplateIndex })), Padding: '7px', Size: 'small' });

        return (
            <Draggable
                draggableId={draggableId}
                index={this.props.WorkoutTemplateIndex}
            >
                {(provided, snapshot) => (
                    <styles.WorkoutTemplateContainer
                        className="WorkoutTemplateContainer"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        isDragging={snapshot.isDragging}
                    >
                        <styles.WorkoutTemplateInnerContainer className="WorkoutTemplateInnerContainer" IsModified={IsModified && !IsUnique} IsModifiedInSync={ModifiedUniqueTemplateIds.indexOf(+TemplateId) > -1}>
                            <styles.WorkoutTemplateContainerHeader className="WorkoutTemplateContainerHeader">
                                <styles.WorkoutTemplateContainerHeaderTopRow className="WorkoutTemplateContainerHeaderTopRow">
                                    {this.renderWorkoutType(WorkoutType)}

                                    <styles.WorkoutTemplateContainerHeaderTopRowRight className="WorkoutTemplateContainerHeaderTopRowRight">
                                        <OverlayTrigger onExited={() => this.onClickCalendarType(null)} trigger="click" placement="top" rootClose overlay={
                                            <styles.StyledPopover calendartype={CalendarType} showcalendar={+!!ShowCalendar}>
                                                {
                                                    (!!CalendarType || !!ShowCalendar) ? 
                                                    <CalendarOverlay
                                                        ActionType={CalendarType}
                                                        NumDays={TemplateDays}
                                                        OnSelectDays={SelectedDays => this.onSelectDays({ SelectedDays, TemplateId })}
                                                        SubmitButtonTitle={CalendarPopoverSubmitButtonTitle}
                                                        Title={CalendarPopoverTitle}
                                                    />
                                                :
                                                    <styles.WorkoutTemplateCalendarOptionsContainer className="WorkoutTemplateCalendarOptionsContainer">
                                                        <styles.WorkoutTemplateCalendarOptionContainer className="WorkoutTemplateCalendarOptionContainer" onClick={() => this.onClickCalendarType('Move')}>
                                                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('move_to_another_date')} TextAlign="left" />
                                                        </styles.WorkoutTemplateCalendarOptionContainer>
                                                        <styles.WorkoutTemplateCalendarOptionContainer className="WorkoutTemplateCalendarOptionContainer" onClick={() => this.onClickCalendarType('Copy')}>
                                                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('copy_to_other_days')} TextAlign="left" />
                                                        </styles.WorkoutTemplateCalendarOptionContainer>
                                                    </styles.WorkoutTemplateCalendarOptionsContainer>
                                                }
                                            </styles.StyledPopover>
                                        }>
                                            <styles.WorkoutTemplateContainerHeaderIconContainer className="WorkoutTemplateContainerHeaderIconContainer">
                                                <styles.WorkoutTemplateContainerHeaderIcon ImageSrc={Calendar} />
                                            </styles.WorkoutTemplateContainerHeaderIconContainer>
                                        </OverlayTrigger>

                                        <ButtonGroup
                                            Buttons={Buttons}
                                            NotTouching
                                        />
                                    </styles.WorkoutTemplateContainerHeaderTopRowRight>
                                </styles.WorkoutTemplateContainerHeaderTopRow>

                                <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin Text={CustomSettings.Name_IsCustom ? CustomSettings.Name : TemplateName} TextAlign="left" />
                            </styles.WorkoutTemplateContainerHeader>

                            <styles.WorkoutTemplateContainerBody className="WorkoutTemplateContainerBody">
                                <styles.ReadMoreLessContainer className="ReadMoreLessContainer" onClick={() => this.onToggleShowCustomSettings()}>
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('optional_information')} TextAlign="left" />

                                    <styles.OptionalInformationIcon ImageSrc={ShowCustomSettings ? UpArrow : DownArrow} />
                                </styles.ReadMoreLessContainer>

                                {
                                    ShowCustomSettings &&
                                    <styles.CustomizationContainer className="CustomizationContainer">
                                        <styles.CustomizationItemContainer className="CustomizationItemContainer">
                                            <styles.CustomizationItemContainerHeader className="CustomizationItemContainerHeader">
                                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('Name')}:`} />

                                                <Toggle
                                                    Checked={!!CustomSettings.Name_IsCustom}
                                                    OffLabel={t('off')}
                                                    OnChange={event => this.onChangeToggle({ event, ToggleType: 'Name_IsCustom' })}
                                                    OnLabel={t('on')}
                                                />
                                            </styles.CustomizationItemContainerHeader>
                                            <styles.CustomizationTextInput
                                                FontSize="small"
                                                NoLabel
                                                NoMargin
                                                OnChange={event => this.onChangeInput({ event, ToggleType: 'Name' })}
                                                Opacity={!!CustomSettings.Name_IsCustom ? 1 : OPACITY}
                                                Placeholder={t('Name')}
                                                Size="medium"
                                                Type="text"
                                                Value={CustomSettings.Name}
                                            />
                                        </styles.CustomizationItemContainer>

                                        <styles.CustomizationItemContainer className="CustomizationItemContainer">
                                            <styles.CustomizationItemContainerHeader className="CustomizationItemContainerHeader">
                                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('custom_trainer_note')}:`} />

                                                <Toggle
                                                    Checked={!!CustomSettings.Note_IsCustom}
                                                    OffLabel={t('off')}
                                                    OnChange={event => this.onChangeToggle({ event, ToggleType: 'Note_IsCustom' })}
                                                    OnLabel={t('on')}
                                                />
                                            </styles.CustomizationItemContainerHeader>
                                            <styles.StyledWorkoutNoteTextArea
                                                OnBlur={event => this.onChangeInput({ event, ToggleType: 'Note' })}
                                                NumRows={3}
                                                Opacity={!!CustomSettings.Note_IsCustom ? 1 : OPACITY}
                                                Placeholder={t('WorkoutPlan_Trainer_note_to_client_placeholder')}
                                                Value={CustomSettings.Note}
                                            />
                                        </styles.CustomizationItemContainer>

                                        <styles.CustomizationItemContainer className="CustomizationItemContainer">
                                            <styles.CustomizationItemContainerHeader className="CustomizationItemContainerHeader">
                                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('custom_location')}:`} />

                                                <Toggle
                                                    Checked={!!CustomSettings.Location_IsCustom}
                                                    OffLabel={t('off')}
                                                    OnChange={event => this.onChangeToggle({ event, ToggleType: 'Location_IsCustom' })}
                                                    OnLabel={t('on')}
                                                />
                                            </styles.CustomizationItemContainerHeader>
                                            <styles.CustomizationTextInput
                                                FontSize="small"
                                                NoLabel
                                                NoMargin
                                                OnChange={event => this.onChangeInput({ event, ToggleType: 'Location' })}
                                                Opacity={!!CustomSettings.Location_IsCustom ? 1 : OPACITY}
                                                Placeholder={t('custom_location')}
                                                Size="medium"
                                                Type="text"
                                                Value={CustomSettings.Location}
                                            />
                                        </styles.CustomizationItemContainer>

                                        <styles.CustomizationItemContainer className="CustomizationItemContainer">
                                            <styles.CustomizationItemContainerHeader className="CustomizationItemContainerHeader">
                                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('custom_starttime')}:`} />

                                                <Toggle
                                                    Checked={!!CustomSettings.StartTime_IsCustom}
                                                    OffLabel={t('off')}
                                                    OnChange={event => this.onChangeToggle({ event, ToggleType: 'StartTime_IsCustom' })}
                                                    OnLabel={t('on')}
                                                />
                                            </styles.CustomizationItemContainerHeader>
                                            <styles.StyledTimePicker
                                                className="StyledTimePicker"
                                                defaultValue={TimePickerDate}
                                                minuteStep={5}
                                                onChange={DateTime => this.onChangeDateTime({ DateTime, ToggleType: 'StartTime' })}
                                                showSecond={false}
                                                use12Hours={false}
                                                value={TimePickerDate}
                                                Opacity={!!CustomSettings.StartTime_IsCustom ? 1 : OPACITY}
                                            />
                                        </styles.CustomizationItemContainer>
                                    </styles.CustomizationContainer>
                                }
                            </styles.WorkoutTemplateContainerBody>
                        </styles.WorkoutTemplateInnerContainer>
                    </styles.WorkoutTemplateContainer>
                )}
            </Draggable>
        );
    }
}

class AddWorkouts extends React.Component {
    // topRef = React.createRef();
    
    state = {
        Collapsed: true,
        ScreenNumber: 1,
        WorkoutType: null,

        AdditionalActivityDetails: {
            AdditionalActivities: [],
            Intensities: []
        },

        AttachSurveys: {
            Sleep: 0,
            Pain: 0,
            RPE: 0
        },

        ChangeExerciseInfo: {},
        HourType: 24,
        EditOriginal: 0,
        IsModified: 0,
        MaxImageSize: 10000000,
        SaveAsNew: 0,

        IsDragging: false,

        ShowAddExerciselModal: false,
        ShowAddWorkoutTemplateToProgramModal: false,
        ShowAddWorkoutTemplateToProgramModal_DayIndex: null,
        ShowAddWorkoutTemplateToProgramModal_DisplayNone: false,
        ShowChangeExerciselModal: false,
        ShowDateTimes: false,
        ShowEditTrainingProgramWorkoutTemplate: false,
        ShowEditTrainingProgramWorkoutTemplateDetails: {
            Template: {},
            WhereToSave: {}
        },
        ShowExerciseDetailModal: false,
        ShowExerciseDetailPreview: {},
        ShowHowToBuildResetWarningModal: false,
        ShowHowToBuildResetWarningModal_NewStatus: null,
        ShowRemoveWorkoutTemplateWarningModal: false,
        ShowRemoveWorkoutTemplateWarningModal_TemplateId: null,
        ShowTemplateLibraryModal_DisplayNone: false,
        ShowTemplateLibraryModal_Activity: false,
        ShowTemplateLibraryModal_Endurance: false,
        ShowTemplateLibraryModal_Strength: false,
        ShowTrainingProgramLibraryModal_DisplayNone: false,
        ShowTrainingProgramLibraryModal: false,

        Activity: {
            HowToBuildSession: null,
            Template: {
                TemplateDetails: {
                    IntensityId: null,
                    TrainerNote: ''
                }
            }
        },

        AssigningDetails: {
            Dates: [],
            Groups: {},
            StartDate: null,
            UserIds: [],
            Users: []
        },

        Endurance: {
            HowToBuildSession: null,
            Template: {
                TemplateDetails: {
                    TrainerNote: ''
                },
                WorkoutLaps: {
                    ActiveType: null,
                    CardioLevel: null,
                    DataType: null,
                    EnduranceTypeId: null,
                    Laps: []
                }
            }
        },

        OptionalDetails: { ...DefaultOptionalDetails },

        Strength: {
            HowToBuildSession: null,
            ShowReorderExercises: false,
            Template: {
                TemplateDetails: {
                    TrainerNote: ''
                },
                WorkoutExercises: []
            }
        },

        TrainingProgram: {
            DayIds: [],
            Days: [],
            ModifiedUniqueTemplateIds: [],
            ProgramDescription: '',
            ProgramName: '',
            TemplateDays: null,
            TrainingProgramId: null,
            WorkoutTemplates: {}
        }
    };

    componentDidMount() {
        this.addDefaultClients();
    }

    addDefaultClients = () => {
        var { ClientDetails } = this.props;

        var UserIds = [];
        var Users = [];

        if (!!ClientDetails && Object.entries(ClientDetails).length !== 0) {
            UserIds = [ +ClientDetails.ClientId ];
            Users = [{ ...ClientDetails, UserId: +ClientDetails.ClientId }];
        }

        if (!!UserIds.length) {
            var AssigningDetails = { ...this.state.AssigningDetails, UserIds, Users };
            this.setState({ AssigningDetails });
        }
    }

    getActivityPicturesFromActivity = ({ AdditionalActivities, ActivityId, CustomImage, WorkoutImage }) => {
        var ActivityDetails = { ActivityId, ActivityName: '', ActivityPicture: null, ActivityPictureId: ActivityId, ActivityPictures: [] };

        if (!AdditionalActivities) AdditionalActivities = this.state.AdditionalActivityDetails.AdditionalActivities;

        for (var i = 0; i < AdditionalActivities.length; i++) {
            if (AdditionalActivities[i].value === ActivityId) {
                ActivityDetails.ActivityName = AdditionalActivities[i].label;
                ActivityDetails.ActivityPicture = AdditionalActivities[i].Pictures[0];
                ActivityDetails.ActivityPictures = AdditionalActivities[i].Pictures;
                break;
            }
        }

        if (!!CustomImage) {
            ActivityDetails.ActivityPicture = WorkoutImage;
            ActivityDetails.ActivityPictureId = 'custom';
        }

        return ActivityDetails;
    }

    onAddBreak = LapIndex => {
        var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

        var Laps = [];

        if (!!ShowEditTrainingProgramWorkoutTemplate) Laps = [ ...ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutLaps.Laps ];
        else Laps = [ ...this.state.Endurance.Template.WorkoutLaps.Laps ];

        Laps.splice(LapIndex + 1, 0, { ...Laps[LapIndex], Active: 0, ActiveUnits: null, BreakTime: 60, LapType: 'Break', Rest: 0, Speed: 0, WorkoutLapId: null });

        var LapNumber = 1;
        Laps = Laps.map(Lap => ({ ...Lap, LapNumber: Lap.LapType === 'Active' ? LapNumber++ : 0 }));

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            var NewShowEditTrainingProgramWorkoutTemplateDetails = { ...ShowEditTrainingProgramWorkoutTemplateDetails };
            NewShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutLaps.Laps = Laps;

            this.setState({ ShowEditTrainingProgramWorkoutTemplateDetails: NewShowEditTrainingProgramWorkoutTemplateDetails });
        }
        else {
            var NewEndurance = { ...this.state.Endurance };
            NewEndurance.Template.WorkoutLaps.Laps = Laps;

            this.setState({ IsModified: 1, Endurance: NewEndurance });
        }
    }

    onAddExercises = SelectedExercises => {
        var { t } = this.props;

        var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

        var Units = null;
        var WorkoutExercises = [];

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            WorkoutExercises = [ ...ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises ];
            Units = ShowEditTrainingProgramWorkoutTemplateDetails.Template.TemplateDetails.Units;
        }
        else {
            WorkoutExercises = [ ...this.state.Strength.Template.WorkoutExercises ];
            Units = this.state.Strength.Template.TemplateDetails.Units;
        }

        var NewExercise = {};
        SelectedExercises.map(({ ExerciseId, ExerciseImage, ExerciseName, ExerciseType }) => {
            NewExercise = { Collapsed: false, ExerciseId, ExerciseImage, ExerciseName, Notes: { Coach: '' }, ExerciseType, MaxType: 'Progress', Sets: [], WorkoutExerciseId: 0 };

            if (ExerciseType === 1) { NewExercise.DataType = 'dynamic-reps'; NewExercise.Sets.push({ MaxSet: 0, Reps: '', Rest: '', Units: t('Reps'), WorkoutSetId: 0 }); }
            else if (ExerciseType === 2) { NewExercise.DataType = 'dynamic-seconds'; NewExercise.Sets.push({ MaxSet: 0, Rest: '', Seconds: '', WorkoutSetId: 0 }); }
            else if (ExerciseType === 3) { NewExercise.DataType = 'dynamic-distance'; NewExercise.Sets.push({ Distance: '', MaxSet: 0, Rest: '', Units: Units === 'imperial' ? 'yards' : 'meters', WorkoutSetId: 0 }); }
            else if (ExerciseType === 4) { NewExercise.DataType = 'dynamic-weight'; NewExercise.Header_Reps = t('Reps'); NewExercise.Sets.push({ MaxSet: 0, Reps: '', Rest: '', Units: Units === 'imperial' ? 'lbs' : 'kg', Weight: '', WorkoutSetId: 0 }); }
            else if (ExerciseType === 5) { NewExercise.DataType = 'dynamic-weight'; NewExercise.Sets.push({ MaxSet: 0, Rest: '', Seconds: '', Units: Units === 'imperial' ? 'lbs' : 'kg', Weight: '', WorkoutSetId: 0 }); }

            WorkoutExercises.push({ CircuitNumber: 0, NumExercises: 1, Type: 'WorkoutExercise', WorkoutExercises: [{ ...NewExercise }] });

            return null;
        });

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            var NewShowEditTrainingProgramWorkoutTemplateDetails = { ...ShowEditTrainingProgramWorkoutTemplateDetails };
            NewShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ ShowEditTrainingProgramWorkoutTemplateDetails: NewShowEditTrainingProgramWorkoutTemplateDetails });
        }
        else {
            var NewStrength = { ...this.state.Strength };
            NewStrength.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ IsModified: 1, Strength: NewStrength });
        }
    }

    onAddLap = LapIndex => {
        var { t } = this.props;
        var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

        var Laps = [];
        var Units = '';

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            Laps = [ ...ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutLaps.Laps ];

            var { ActiveType } = ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutLaps;
            Units = ShowEditTrainingProgramWorkoutTemplateDetails.Template.TemplateDetails.Units;
        }
        else {
            Laps = [ ...this.state.Endurance.Template.WorkoutLaps.Laps ];

            // eslint-disable-next-line
            var { ActiveType } = this.state.Endurance.Template.WorkoutLaps;

            if (this.state.Endurance.Template.TemplateId) Units = this.state.Endurance.Template.TemplateDetails.Units;
            else Units = this.props.UserDetails.PreferredMeasuringSystem.toLowerCase();
        }

        // Copy if previous lap is not a break lap && not the first lap
            if (Laps.length && Laps[LapIndex].LapType === 'Active') Laps.splice(LapIndex + 1, 0, { ...Laps[LapIndex], WorkoutLapId: null });
        // Else create new lap with 0 seconds
            else {
                var NewActiveUnits = ActiveType === 'Time' ? null : t(Units === 'imperial' ? 'yards' : 'meters').toLowerCase();
                var NewSpeedUnits = Units === 'imperial' ? 'mph' : 'km/h';

                Laps.splice(LapIndex + 1, 0, { ...Laps[LapIndex], Active: 0, ActiveUnits: NewActiveUnits, BreakTime: 0, LapType: 'Active', Rest: 0, Speed: 10, SpeedUnits: NewSpeedUnits, WorkoutLapId: null });
            }

        var LapNumber = 1;
        Laps = Laps.map(Lap => ({ ...Lap, LapNumber: Lap.LapType === 'Active' ? LapNumber++ : 0 }));

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            var NewShowEditTrainingProgramWorkoutTemplateDetails = { ...ShowEditTrainingProgramWorkoutTemplateDetails };
            NewShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutLaps.Laps = Laps;

            this.setState({ ShowEditTrainingProgramWorkoutTemplateDetails: NewShowEditTrainingProgramWorkoutTemplateDetails });
        }
        else {
            var NewEndurance = { ...this.state.Endurance };
            NewEndurance.Template.WorkoutLaps.Laps = Laps;

            this.setState({ IsModified: 1, Endurance: NewEndurance });
        }
    }

    onAddModifiedTrainingProgram = () => {
        // TrainingProgram = {
        //     Days: [
        //         {
        //             DayId: 'Day-1',
        //             WorkoutTemplates: [
        //                 {
        //                     CustomSettings: {
        //                         Location: '',
        //                         Location_IsCustom: 1,
        //                         Name: '',
        //                         Name_IsCustom: 0,
        //                         Note: '',
        //                         Note_IsCustom: 1,
        //                         StartTime: '',
        //                         StartTime_IsCustom: 0
        //                     },
        //                     FullTemplateData: {},
        //                     IsModified: 0,
        //                     RetrievedFullTemplateData: 0,
        //                     TemplateId: 1,
        //                     TemplateName: 'All Exercise Types Maxes',
        //                     WorkoutType: 1
        //                 }
        //             ]
        //         }
        //     ],
        //     IsModified: 1,
        //     TemplateDays: 5,
        //     WorkoutTemplates: {
        //         'TemplateId-1': {
        //             FullTemplateData: {},
        //             IsModified: 0,
        //             RetrievedFullTemplateData: 0,
        //             TemplateId: 1,
        //             TemplateName: 'All Exercise Types Maxes',
        //             WorkoutType: 1
        //         }
        //     }
        // }
    }

    onAddSet = ({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex }) => {
        var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

        var WorkoutExercises = [];

        if (!!ShowEditTrainingProgramWorkoutTemplate) WorkoutExercises = [ ...ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises ];
        else WorkoutExercises = [ ...this.state.Strength.Template.WorkoutExercises ];

        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets = [
            ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets,
            { ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.length - 1], MaxSet: 0, WorkoutSetId: 0 }
        ];

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            var NewShowEditTrainingProgramWorkoutTemplateDetails = { ...ShowEditTrainingProgramWorkoutTemplateDetails };
            NewShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ ShowEditTrainingProgramWorkoutTemplateDetails: NewShowEditTrainingProgramWorkoutTemplateDetails });
        }
        else {
            var NewStrength = { ...this.state.Strength };
            NewStrength.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ IsModified: 1, Strength: NewStrength });
        }
    }

    onAddTemplateToWorkoutProgram = async ({ Template }) => {
        var { ShowAddWorkoutTemplateToProgramModal_DayIndex } = this.state;
        this.onToggleShowAddWorkoutTemplateToProgramModal({ ShowAddWorkoutTemplateToProgramModal: false });

        var ActivityDetails = {};
        var OptionalDetails = {
            ActivityId: null,
            ActivityName: '',
            ActivityPicture: null,
            ActivityPictureId: null,
            ActivityPictures: [],
            CustomImage: 0,
            Duration: 0,
            ImageTooLarge: false,
            Location: '',
            Name: '',
            SelectedImage: null,
            StartTime: moment().format('YYYY-MM-DD HH:mm'),
            WorkoutImage: null,
            WrongImageType: false
        }

        var TrainingProgram = { ...this.state.TrainingProgram };

        // Template Was Selected From Library View, So It Is Missing Template Details and Need To Retrieve
            if (!Template.TemplateDetails) {
                Template = await this.props.GetWorkoutTemplateDetail({ TemplateId: Template.TemplateId });

                // eslint-disable-next-line
                var { AdditionalActivityDetails, TemplateDetails: { ActivityId, CustomImage, CustomName: Name, Duration, Location, StartTime, WorkoutImage, WorkoutType } } = Template;

                ActivityDetails = this.getActivityPicturesFromActivity({ AdditionalActivities: AdditionalActivityDetails.AdditionalActivities, ActivityId, CustomImage, WorkoutImage });

                OptionalDetails = { ...ActivityDetails, CustomImage, Duration, Location, Name, StartTime: StartTime ? moment(`${moment().format('YYYY-MM-DD')} ${StartTime}`).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm'), WorkoutImage };

                Template.CustomSettings = MakeCustomSettings();

                // Make Each WorkoutExercise Be { Collapsed: true }
                    if (WorkoutType === 'Strength') Template = { ...Template, WorkoutExercises: Template.WorkoutExercises.map(WorkoutExercise => ({ ...WorkoutExercise, WorkoutExercises: WorkoutExercise.WorkoutExercises.map(CircuitWorkoutExercise => ({ ...CircuitWorkoutExercise, Collapsed: true })) })) }
                    else if (WorkoutType === 'Endurance') Template.WorkoutLaps.Laps = [].concat(...Template.WorkoutLaps.Laps.map(WL => WL.Laps.map(Lap => ({ ...Lap, LapType: WL.Type }))))

                TrainingProgram.WorkoutTemplates[`TemplateId-${Template.TemplateId}`] = { ...Template, ...Template.TemplateDetails, IsModified: 0, RetrievedFullTemplateData: 1, FullTemplateData: { ...Template }, AdditionalActivityDetails, OptionalDetails, ShowDateTimes: !!StartTime };
            }
        // Template Was Selected From Template Detail Modal, So It Has All Required Details Already
            else {
                // eslint-disable-next-line
                var { AdditionalActivityDetails, TemplateDetails: { ActivityId, CustomImage, CustomName: Name, Duration, Location, StartTime, WorkoutImage, WorkoutType } } = Template;
                
                ActivityDetails = this.getActivityPicturesFromActivity({ AdditionalActivities: AdditionalActivityDetails.AdditionalActivities, ActivityId, CustomImage, WorkoutImage });

                OptionalDetails = { ...ActivityDetails, CustomImage, Duration, Location, Name, StartTime: StartTime ? moment(`${moment().format('YYYY-MM-DD')} ${StartTime}`).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm'), WorkoutImage };

                Template.CustomSettings = MakeCustomSettings();

                // Make Each WorkoutExercise Be { Collapsed: true }
                    if (WorkoutType === 'Strength') Template = { ...Template, WorkoutExercises: Template.WorkoutExercises.map(WorkoutExercise => ({ ...WorkoutExercise, WorkoutExercises: WorkoutExercise.WorkoutExercises.map(CircuitWorkoutExercise => ({ ...CircuitWorkoutExercise, Collapsed: true })) })) }
                    else if (WorkoutType === 'Endurance') Template.WorkoutLaps.Laps = [].concat(...Template.WorkoutLaps.Laps.map(WL => WL.Laps.map(Lap => ({ ...Lap, LapType: WL.Type }))))

                    TrainingProgram.WorkoutTemplates[`TemplateId-${Template.TemplateId}`] = { ...Template, ...Template.TemplateDetails, IsModified: 0, RetrievedFullTemplateData: 1, FullTemplateData: { ...Template }, AdditionalActivityDetails, OptionalDetails, ShowDateTimes: !!StartTime };
            }

        this.setState({ IsModified: 1, TrainingProgram }, () => {
            var MockDrag = {
                destinations: [],
                destination: { droppableId: null, index: null },
                source: { droppableId: null, index: null },
                draggableId: null,
                TemplateId: Template.TemplateId
            }
    
            // { destination: { droppableId: "Day-3", index: 0 }, source: { index: 0, droppableId: "Day-2" }, draggableId: "10469-1-0" }
    
            MockDrag.destination = { droppableId: `Day-${ShowAddWorkoutTemplateToProgramModal_DayIndex}`, index: 0 };
            MockDrag.source = { droppableId: 'SelectedWorkoutTemplates', index: 0 };
            MockDrag.draggableId = `${Template.TemplateId}`;

            this.onMoveWorkoutTemplate(MockDrag);
        });
    }

    onAddTrainingProgramDay = () => {
        var TrainingProgram = { ...this.state.TrainingProgram };
        TrainingProgram.TemplateDays++;

        var NewDayId = `Day-${TrainingProgram.TemplateDays}`;
        TrainingProgram.DayIds.push(NewDayId);
        TrainingProgram.Days.push({ DayId: NewDayId, WorkoutTemplateIds: [], WorkoutTemplates: [] });

        // Edit Selected Dates In AssigningInfo Automatically If Already Selected
        var { AssigningDetails } = this.state;
        if (!!AssigningDetails.StartDate) {
            var SelectedDates = [];

            for (var i = 0; i < TrainingProgram.TemplateDays; i++) {
                SelectedDates.push(moment(AssigningDetails.StartDate).add(i, 'days').format('YYYY-MM-DD'));
            }

            AssigningDetails.Dates = SelectedDates;
        }

        this.setState({ AssigningDetails, IsModified: 1, TrainingProgram });
    }

    onCancelCircuits = () => {
        var NewStrength = { ...this.state.Strength };
        NewStrength.ShowReorderExercises = false;

        this.setState({ IsModified: 1, Strength: NewStrength });
    }

    onCancelEditTrainingProgramTemplate = () => {
        this.setState({
            ShowEditTrainingProgramWorkoutTemplate: false,
            ShowEditTrainingProgramWorkoutTemplateDetails: {
                Template: {},
                WhereToSave: {}
            }
        })
    }

    onChangeActivity = ({ value: ActivityId, label: ActivityName, Pictures: ActivityPictures }) => {
        var OptionalDetails = { ...this.state.OptionalDetails, ActivityId, ActivityName, ActivityPictures };

        var ActivityPicture = OptionalDetails.ActivityPicture;
        var ActivityPictureId = OptionalDetails.ActivityPictureId;

        if (ActivityPictureId !== 'custom') {
            ActivityPicture = ActivityPictures[0];
            ActivityPictureId = ActivityId;
        }

        OptionalDetails = { ...OptionalDetails, ActivityPicture, ActivityPictureId };

        this.setState({ IsModified: 1, OptionalDetails });
    }

    onChangeActiveType = ActiveType => {
        // Used for Endurance Template to change between "Time" && "Distance"
        var NewEndurance = { ...this.state.Endurance };
        NewEndurance.Template.WorkoutLaps.ActiveType = ActiveType;

        this.setState({ IsModified: 1, Endurance: NewEndurance });
    }

    onChangeAttachSurveyToggle = ({ event, ToggleType }) => {
        var AttachSurveys = { ...this.state.AttachSurveys };
        AttachSurveys[ToggleType] = event.target.checked;

        this.setState({ AttachSurveys });
    }

    onChangeCustomSettingDisplay = ({ DayIndex, WorkoutTemplateIndex }) => {
        var NewTrainingProgram = { ...this.state.TrainingProgram };

        NewTrainingProgram.Days = NewTrainingProgram.Days.map((Day, DI) => {
            if (DI !== DayIndex) return Day;
            return {
                ...Day,
                WorkoutTemplates: Day.WorkoutTemplates.map((WT, WI) => {
                    if (WI !== WorkoutTemplateIndex) return WT;

                    return { ...WT, CustomSettings: { ...WT.CustomSettings, ShowCustomSettings: !WT.CustomSettings.ShowCustomSettings } }
                })
            }
        });

        this.setState({ TrainingProgram: NewTrainingProgram });
    }

    onChangeCustomSettingDisplay_SelectedTemplates = TemplateId => {
        var ObjectKey = `TemplateId-${TemplateId}`;
        var NewTrainingProgram = { ...this.state.TrainingProgram };
        NewTrainingProgram.WorkoutTemplates[ObjectKey] = { ...NewTrainingProgram.WorkoutTemplates[ObjectKey], CustomSettings: { ...NewTrainingProgram.WorkoutTemplates[ObjectKey].CustomSettings, ShowCustomSettings: !NewTrainingProgram.WorkoutTemplates[ObjectKey].CustomSettings.ShowCustomSettings } };

        this.setState({ TrainingProgram: NewTrainingProgram });
    }

    onChangeCustomSetting = ({ DayIndex, SettingName, Value, WorkoutTemplateIndex }) => {
        var NewTrainingProgram = { ...this.state.TrainingProgram };

        NewTrainingProgram.Days = NewTrainingProgram.Days.map((Day, DI) => {
            if (DI !== DayIndex) return Day;
            return {
                ...Day,
                WorkoutTemplates: Day.WorkoutTemplates.map((WT, WI) => {
                    if (WI !== WorkoutTemplateIndex) return WT;

                    // If Setting Is Not IsCustom, And Setting Was Empty Before AND IsCustom = true, Set IsCustom = False So That It Changes New
                        if (Value !== '' && SettingName.indexOf('IsCustom') === -1 && WT.CustomSettings.CustomSettings[SettingName] === '' && !WT.CustomSettings.CustomSettings[`${SettingName}_IsCustom`]) {
                            return { ...WT, CustomSettings: { ...WT.CustomSettings, CustomSettings: { ...WT.CustomSettings.CustomSettings, [SettingName]: Value, [`${SettingName}_IsCustom`]: true } } };
                        }

                    // If Setting Is "Name" and is now empty and IsCustom = false, set IsCustom = true as name is required
                        if (Value === '' && SettingName === 'Name' && WT.CustomSettings.CustomSettings[`${SettingName}_IsCustom`]) {
                            return { ...WT, CustomSettings: { ...WT.CustomSettings, CustomSettings: { ...WT.CustomSettings.CustomSettings, [SettingName]: Value, [`${SettingName}_IsCustom`]: false } } };
                        }

                    return { ...WT, CustomSettings: { ...WT.CustomSettings, CustomSettings: { ...WT.CustomSettings.CustomSettings, [SettingName]: Value } } };
                })
            }
        })

        this.setState({ TrainingProgram: NewTrainingProgram });
    }

    onChangeCustomSetting_SelectedTemplates = ({ SettingName, TemplateId, Value }) => {
        var ObjectKey = `TemplateId-${TemplateId}`;
        var NewTrainingProgram = { ...this.state.TrainingProgram };

        // If Setting Is Not IsCustom, And Setting Was Empty Before AND IsCustom = true, Set IsCustom = False So That It Changes New
            if (Value !== '' && SettingName.indexOf('IsCustom') === -1 && NewTrainingProgram.WorkoutTemplates[ObjectKey].CustomSettings.CustomSettings[SettingName] === '' && !NewTrainingProgram.WorkoutTemplates[ObjectKey].CustomSettings.CustomSettings[`${SettingName}_IsCustom`]) {
                NewTrainingProgram.WorkoutTemplates[ObjectKey] = { ...NewTrainingProgram.WorkoutTemplates[ObjectKey], CustomSettings: { ...NewTrainingProgram.WorkoutTemplates[ObjectKey].CustomSettings, CustomSettings: { ...NewTrainingProgram.WorkoutTemplates[ObjectKey].CustomSettings.CustomSettings, [SettingName]: Value, [`${SettingName}_IsCustom`]: true } } };
            }

        // If Setting Is "Name" and is now empty and IsCustom = false, set IsCustom = true as name is required
            else if (Value === '' && SettingName === 'Name' && NewTrainingProgram.WorkoutTemplates[ObjectKey].CustomSettings.CustomSettings[`${SettingName}_IsCustom`]) {
                NewTrainingProgram.WorkoutTemplates[ObjectKey] = { ...NewTrainingProgram.WorkoutTemplates[ObjectKey], CustomSettings: { ...NewTrainingProgram.WorkoutTemplates[ObjectKey].CustomSettings, CustomSettings: { ...NewTrainingProgram.WorkoutTemplates[ObjectKey].CustomSettings.CustomSettings, [SettingName]: Value, [`${SettingName}_IsCustom`]: false } } };
            }

            else NewTrainingProgram.WorkoutTemplates[ObjectKey] = { ...NewTrainingProgram.WorkoutTemplates[ObjectKey], CustomSettings: { ...NewTrainingProgram.WorkoutTemplates[ObjectKey].CustomSettings, CustomSettings: { ...NewTrainingProgram.WorkoutTemplates[ObjectKey].CustomSettings.CustomSettings, [SettingName]: Value } } };

            this.setState({ TrainingProgram: NewTrainingProgram });
    }

    onChangeDataType = ({ CircuitWorkoutExerciseIndex, DataType, WorkoutExerciseIndex }) => {
        var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

        var WorkoutExercises = [];

        if (!!ShowEditTrainingProgramWorkoutTemplate) WorkoutExercises = [ ...ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises ];
        else WorkoutExercises = [ ...this.state.Strength.Template.WorkoutExercises ];

        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].DataType = DataType;

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            var NewShowEditTrainingProgramWorkoutTemplateDetails = { ...ShowEditTrainingProgramWorkoutTemplateDetails };
            NewShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ ShowEditTrainingProgramWorkoutTemplateDetails: NewShowEditTrainingProgramWorkoutTemplateDetails });
        }
        else {
            var NewStrength = { ...this.state.Strength };
            NewStrength.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ IsModified: 1, Strength: NewStrength });
        }
    }

    onChangeEnduranceDataType = DataType => {
        // Used for Endurance Template to change between "dynamic" && "static"
        var NewEndurance = { ...this.state.Endurance };
        NewEndurance.Template.WorkoutLaps.DataType = DataType;

        this.setState({ IsModified: 1, Endurance: NewEndurance });
    }

    onChangeExercises = SelectedExercises => {
        var { t } = this.props;

        var { CircuitWorkoutExerciseIndex, WorkoutExerciseIndex } = this.state.ChangeExerciseInfo;

        var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

        var Units = null;
        var WorkoutExercises = [];

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            WorkoutExercises = [ ...ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises ];
            Units = ShowEditTrainingProgramWorkoutTemplateDetails.Template.TemplateDetails.Units;
        }
        else {
            WorkoutExercises = [ ...this.state.Strength.Template.WorkoutExercises ];
            Units = this.state.Strength.Template.TemplateDetails.Units;
        }

        var ChangedToExercise = SelectedExercises[0];
        var OriginalExercise = { ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex] };

        // Update ExerciseId, Image, Name and Type; Open Exercise and Reset WorkoutExerciseId
            var ChangeExercise = {
                ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex],
                ...ChangedToExercise,
                Collapsed: false,
                WorkoutExerciseId: 0
            }
            delete ChangeExercise.ExerciseVideo;

        var CTE_ET = ChangedToExercise.ExerciseType;
        var OE_ET = OriginalExercise.ExerciseType;

        var IsDynamic = OriginalExercise.DataType.indexOf('static') === -1;

        // Update DataType Depending On Exercise Type
            if (CTE_ET !== OE_ET) {
                ChangeExercise.SetUnits = GetSetUnits(CTE_ET, Units);

                if (CTE_ET === 1) {
                    if (!!IsDynamic) ChangeExercise.DataType = 'dynamic-reps';

                    ChangeExercise.Header_Reps = t('Reps');

                    delete ChangeExercise.Header_Seconds;
                    delete ChangeExercise.Header_Distance;
                    delete ChangeExercise.Header_Weight;
                }
                else if (CTE_ET === 2) {
                    if (!!IsDynamic) ChangeExercise.DataType = 'dynamic-seconds';

                    ChangeExercise.Header_Seconds = t('Time');

                    delete ChangeExercise.Header_Reps;
                    delete ChangeExercise.Header_Distance;
                    delete ChangeExercise.Header_Weight;
                }
                else if (CTE_ET === 3) {
                    if (!!IsDynamic) ChangeExercise.DataType = 'dynamic-distance';

                    ChangeExercise.Header_Distance = ChangeExercise.SetUnits;

                    delete ChangeExercise.Header_Reps;
                    delete ChangeExercise.Header_Seconds;
                    delete ChangeExercise.Header_Weight;
                }
                else if (CTE_ET === 4) {
                    if (!!IsDynamic) {
                        if (OE_ET === 1 || OE_ET === 2 || OE_ET === 3) ChangeExercise.DataType = 'dynamic-reps';
                        else ChangeExercise.DataType = 'dynamic-weight';
                    }

                    ChangeExercise.Header_Reps = t('Reps');
                    ChangeExercise.Header_Weight = `Weight (${ChangeExercise.SetUnits})`;

                    delete ChangeExercise.Header_Seconds;
                    delete ChangeExercise.Header_Distance;
                }
                else if (CTE_ET === 5) {
                    if (!!IsDynamic) ChangeExercise.DataType = 'dynamic-weight';

                    ChangeExercise.Header_Seconds = t('Time');
                    ChangeExercise.Header_Weight = `Weight (${ChangeExercise.SetUnits})`;

                    delete ChangeExercise.Header_Reps;
                    delete ChangeExercise.Header_Distance;
                }
            }

        // Update Workout Sets (reset WorkoutSetId to 0 and update set data according to ExerciseType)
            ChangeExercise.Sets = ChangeExercise.Sets.map(WorkoutSet => {
                var NewWorkoutSet = { ...WorkoutSet, WorkoutSetId: 0 };

                if (CTE_ET === OE_ET) return NewWorkoutSet;
                
                if (OE_ET === 1) {
                    if (CTE_ET === 2) { NewWorkoutSet.Seconds = NewWorkoutSet.Reps; NewWorkoutSet.Units = ChangeExercise.SetUnits; }
                    else if (CTE_ET === 3) { NewWorkoutSet.Distance = NewWorkoutSet.Reps; NewWorkoutSet.Units = Units === 'imperial' ? 'yards' : 'meters'; }
                    else if (CTE_ET === 4) {
                        if (IsDynamic) { NewWorkoutSet.Weight = NewWorkoutSet.Reps; NewWorkoutSet.Reps = 0; }
                        else NewWorkoutSet.Weight = 0; // Keep Reps

                        NewWorkoutSet.Units = Units === 'imperial' ? 'lbs' : 'kg';
                    }
                    else if (CTE_ET === 5) {
                        if (IsDynamic) { NewWorkoutSet.Weight = NewWorkoutSet.Reps; NewWorkoutSet.Seconds = 0; }
                        else { NewWorkoutSet.Weight = 0; NewWorkoutSet.Seconds = NewWorkoutSet.Reps; }

                        NewWorkoutSet.Units = Units === 'imperial' ? 'lbs' : 'kg';
                    }

                    if (CTE_ET !== 4) delete NewWorkoutSet.Reps;
                }
                else if (OE_ET === 2) {
                    if (CTE_ET === 1) { NewWorkoutSet.Reps = NewWorkoutSet.Seconds; NewWorkoutSet.Units = t('Reps'); }
                    else if (CTE_ET === 3) { NewWorkoutSet.Distance = NewWorkoutSet.Seconds; NewWorkoutSet.Units = Units === 'imperial' ? 'yards' : 'meters'; }
                    else if (CTE_ET === 4) {
                        if (IsDynamic) { NewWorkoutSet.Weight = NewWorkoutSet.Seconds; NewWorkoutSet.Reps = 0; }
                        else { NewWorkoutSet.Weight = 0; NewWorkoutSet.Reps = NewWorkoutSet.Seconds; }

                        NewWorkoutSet.Units = Units === 'imperial' ? 'lbs' : 'kg';
                    }
                    else if (CTE_ET === 5) {
                        if (IsDynamic) { NewWorkoutSet.Weight = NewWorkoutSet.Seconds; NewWorkoutSet.Seconds = 0; }
                        else NewWorkoutSet.Weight = 0; // Keep Seconds

                        NewWorkoutSet.Units = Units === 'imperial' ? 'lbs' : 'kg';
                    }

                    if (CTE_ET !== 5) delete NewWorkoutSet.Seconds;
                }
                else if (OE_ET === 3) {
                    if (CTE_ET === 1) { NewWorkoutSet.Reps = NewWorkoutSet.Distance; NewWorkoutSet.Units = t('Reps'); }
                    else if (CTE_ET === 2) { NewWorkoutSet.Seconds = NewWorkoutSet.Distance; NewWorkoutSet.Units = ChangeExercise.SetUnits; }
                    else if (CTE_ET === 4) {
                        if (IsDynamic) { NewWorkoutSet.Weight = NewWorkoutSet.Distance; NewWorkoutSet.Reps = 0; }
                        else { NewWorkoutSet.Weight = 0; NewWorkoutSet.Reps = NewWorkoutSet.Distance; }

                        NewWorkoutSet.Units = Units === 'imperial' ? 'lbs' : 'kg';
                    }
                    else if (CTE_ET === 5) {
                        if (IsDynamic) { NewWorkoutSet.Weight = NewWorkoutSet.Distance; NewWorkoutSet.Seconds = 0; }
                        else { NewWorkoutSet.Weight = 0; NewWorkoutSet.Seconds = NewWorkoutSet.Distance; }

                        NewWorkoutSet.Units = Units === 'imperial' ? 'lbs' : 'kg';
                    }

                    delete NewWorkoutSet.Distance;
                }
                else if (OE_ET === 4) {
                    if (CTE_ET === 1) { NewWorkoutSet.Reps = NewWorkoutSet.Weight; NewWorkoutSet.Units = t('Reps'); }
                    else if (CTE_ET === 2) { NewWorkoutSet.Seconds = NewWorkoutSet.Weight; NewWorkoutSet.Units = ChangeExercise.SetUnits; }
                    else if (CTE_ET === 3) { NewWorkoutSet.Distance = NewWorkoutSet.Weight; NewWorkoutSet.Units = Units === 'imperial' ? 'yards' : 'meters'; }
                    else if (CTE_ET === 5) { NewWorkoutSet.Seconds = NewWorkoutSet.Reps; NewWorkoutSet.Units = Units === 'imperial' ? 'lbs' : 'kg'; }

                    if (CTE_ET !== 1) delete NewWorkoutSet.Reps;
                    if (CTE_ET !== 5) delete NewWorkoutSet.Weight;
                }
                else if (OE_ET === 5) {
                    if (CTE_ET === 1) { NewWorkoutSet.Reps = NewWorkoutSet.Weight; NewWorkoutSet.Units = t('Reps'); }
                    else if (CTE_ET === 2) { NewWorkoutSet.Seconds = NewWorkoutSet.Weight; NewWorkoutSet.Units = ChangeExercise.SetUnits; }
                    else if (CTE_ET === 3) { NewWorkoutSet.Distance = NewWorkoutSet.Weight; NewWorkoutSet.Units = Units === 'imperial' ? 'yards' : 'meters'; }
                    else if (CTE_ET === 4) { NewWorkoutSet.Reps = NewWorkoutSet.Seconds; NewWorkoutSet.Units = Units === 'imperial' ? 'lbs' : 'kg'; }

                    if (CTE_ET !== 2) delete NewWorkoutSet.Seconds;
                    if (CTE_ET !== 4) delete NewWorkoutSet.Weight;
                }

                return NewWorkoutSet;
            });
        
        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex] = ChangeExercise;

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            var NewShowEditTrainingProgramWorkoutTemplateDetails = { ...ShowEditTrainingProgramWorkoutTemplateDetails };
            NewShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ ShowEditTrainingProgramWorkoutTemplateDetails: NewShowEditTrainingProgramWorkoutTemplateDetails });
        }
        else {
            var NewStrength = { ...this.state.Strength };
            NewStrength.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ IsModified: 1, Strength: NewStrength });
        }
    }

    onChangeExerciseNote = ({ CircuitWorkoutExerciseIndex, Note, WorkoutExerciseIndex }) => {
        var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

        var WorkoutExercises = [];

        if (!!ShowEditTrainingProgramWorkoutTemplate) WorkoutExercises = [ ...ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises ];
        else WorkoutExercises = [ ...this.state.Strength.Template.WorkoutExercises ];

        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Notes.Coach = Note;

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            var NewShowEditTrainingProgramWorkoutTemplateDetails = { ...ShowEditTrainingProgramWorkoutTemplateDetails };
            NewShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ ShowEditTrainingProgramWorkoutTemplateDetails: NewShowEditTrainingProgramWorkoutTemplateDetails });
        }
        else {
            var NewStrength = { ...this.state.Strength };
            NewStrength.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ IsModified: 1, Strength: NewStrength });
        }
    }

    onChangeHourType = HourType => {
        this.setState({ HourType });
    }

    onChangeMaxType = ({ CircuitWorkoutExerciseIndex, MaxType, WorkoutExerciseIndex }) => {
        var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

        var WorkoutExercises = [];

        if (!!ShowEditTrainingProgramWorkoutTemplate) WorkoutExercises = [ ...ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises ];
        else WorkoutExercises = [ ...this.state.Strength.Template.WorkoutExercises ];

        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].MaxType = MaxType;

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            var NewShowEditTrainingProgramWorkoutTemplateDetails = { ...ShowEditTrainingProgramWorkoutTemplateDetails };
            NewShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ ShowEditTrainingProgramWorkoutTemplateDetails: NewShowEditTrainingProgramWorkoutTemplateDetails });
        }
        else {
            var NewStrength = { ...this.state.Strength };
            NewStrength.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ IsModified: 1, Strength: NewStrength });
        }
    }

    onChangeOptionalInfo = ({ event, InfoType }) => {
        var OptionalDetails = { ...this.state.OptionalDetails };
        OptionalDetails[InfoType] = event.target.value;

        this.setState({ IsModified: 1, OptionalDetails });
    }

    onChangeScreenNumber = async (Direction, CanGoNext, LastScreen) => {
        var { Activity, Endurance, OptionalDetails, ScreenNumber, ShowDateTimes, Strength, TrainingProgram, WorkoutType } = this.state;

        var NewWorkoutExercises = [];

        document.getElementsByClassName('AddWorkoutsParentContainer')[0].scrollIntoView();

        // Call API If LastScreen
            if (LastScreen) {
                var { AttachSurveys, AssigningDetails: { Dates, Groups, StartDate, UserIds }, OptionalDetails: { ActivityId, ActivityPictureId, Name: CustomName, Duration, Location, SelectedImage, StartTime }, EditOriginal, IsModified, SaveAsNew } = this.state;

                var AssignedDates = Dates.join(',');
                var ClientIds = UserIds.join(',');

                AttachSurveys.Sleep = +AttachSurveys.Sleep;
                AttachSurveys.Pain = +AttachSurveys.Pain;
                AttachSurveys.RPE = +AttachSurveys.RPE;

                if (WorkoutType === 'TrainingProgram') {
                    var { Days, TemplateDays, WorkoutTemplates } = this.state.TrainingProgram;

                    // eslint-disable-next-line
                    var TrainingProgram = {
                        Days: [],
                        IsModified: this.state.IsModified,
                        TemplateDays,
                        WorkoutTemplates: []
                    }

                    TrainingProgram.Days = Days.map(TPD => {
                        return {
                            DayId: TPD.DayId,
                            WorkoutTemplates: TPD.WorkoutTemplates.map(TPD_WT => {
                                var { CustomSettings, FullTemplateData: { AdditionalActivityDetails, ...FTDRest }, IsModified, TemplateId, WorkoutType } = TPD_WT;

                                if (WorkoutType === 1 && !!IsModified) FTDRest.WorkoutExercises = JSON.stringify(ConvertWorkoutExercisesForAPI({ WorkoutExercises: FTDRest.WorkoutExercises, RemoveWorkoutExerciseIds: true }));
                                else if (WorkoutType === 2 && !!IsModified) FTDRest.WorkoutLaps = JSON.stringify(FTDRest.WorkoutLaps.Laps);

                                // Update Custom Settings From Unique Template If Required
                                CustomSettings = { ...CustomSettings.CustomSettings };

                                if (this.state.TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`] && this.state.TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].CustomSettings) {
                                    if (!CustomSettings.Location_IsCustom && !!this.state.TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].CustomSettings.CustomSettings.Location_IsCustom && !!this.state.TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].CustomSettings.CustomSettings.Location) {
                                        CustomSettings.Location_IsCustom = 1;
                                        CustomSettings.Location = this.state.TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].CustomSettings.CustomSettings.Location;
                                    }

                                    if (!CustomSettings.Name_IsCustom && !!this.state.TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].CustomSettings.CustomSettings.Name_IsCustom && !!this.state.TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].CustomSettings.CustomSettings.Name) {
                                        CustomSettings.Name_IsCustom = 1;
                                        CustomSettings.Name = this.state.TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].CustomSettings.CustomSettings.Name;
                                    }

                                    if (!CustomSettings.Note_IsCustom && !!this.state.TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].CustomSettings.CustomSettings.Note_IsCustom && !!this.state.TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].CustomSettings.CustomSettings.Note) {
                                        CustomSettings.Note_IsCustom = 1;
                                        CustomSettings.Note = this.state.TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].CustomSettings.CustomSettings.Note;
                                    }

                                    if (!CustomSettings.StartTime_IsCustom && !!this.state.TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].CustomSettings.CustomSettings.StartTime_IsCustom && !!this.state.TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].CustomSettings.CustomSettings.StartTime) {
                                        CustomSettings.StartTime_IsCustom = 1;
                                        CustomSettings.StartTime = this.state.TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].CustomSettings.CustomSettings.StartTime;
                                    }
                                }

                                return { CustomSettings, FullTemplateData: FTDRest, IsModified, TemplateId, WorkoutType }
                            })
                        };
                    });

                    var NewWorkoutTemplates = {};

                    Object.keys(WorkoutTemplates).map(key => {
                        var { FullTemplateData: { AdditionalActivityDetails, ...FTDRest }, IsModified, TemplateId, WorkoutType } = this.state.TrainingProgram.WorkoutTemplates[key];

                        if (WorkoutType === 1 && !!IsModified) FTDRest.WorkoutExercises = JSON.stringify(ConvertWorkoutExercisesForAPI({ WorkoutExercises: FTDRest.WorkoutExercises, RemoveWorkoutExerciseIds: true }));
                        else if (WorkoutType === 2 && !!IsModified) FTDRest.WorkoutLaps = JSON.stringify(FTDRest.WorkoutLaps.Laps);

                        NewWorkoutTemplates[`TemplateId-${TemplateId}`] = { FullTemplateData: FTDRest, IsModified, TemplateId, WorkoutType }

                        return null;
                    });

                    TrainingProgram.WorkoutTemplates = NewWorkoutTemplates;

                    this.props.AddTrainingProgram_Modified({ ClientIds, Groups, StartDate, TrainingProgram }).then(() => this.onResetWeeklyView());
                } else {
                    if (!ShowDateTimes) StartTime = '';
                    else StartTime = moment(StartTime).format('HH:mm');

                    var CustomImage = 0;

                    var WorkoutImage = null;
                    if (ActivityPictureId === 'custom') {
                        CustomImage = 1;
                        if (SelectedImage) WorkoutImage = { ContentType: SelectedImage.type, FileName: SelectedImage.name, FileSize: SelectedImage.size };
                    }

                    if (WorkoutType === 'Strength') {
                        var { TemplateDetails: { Injuries, RepRange, TrainerNote, TrainingFocus, Units }, TemplateId, WorkoutExercises } = this.state.Strength.Template;

                        if (Strength.HowToBuildSession === 'Scratch') Units = this.props.UserDetails.PreferredMeasuringSystem.toLowerCase();

                        // Update WorkoutExercises So That CircuitNumber Is Added Property To Each Exercise, And Add Note From Coach Into Top Layer in Object
                            NewWorkoutExercises = ConvertWorkoutExercisesForAPI({ WorkoutExercises });

                        if (Strength.HowToBuildSession === 'Template' || (Strength.HowToBuildSession === 'Scratch' && +!!SaveAsNew)) {
                            if (Strength.HowToBuildSession === 'Scratch') { IsModified = 1; TemplateId = 0; }

                            this.props.AddExistingWorkoutTemplate({ ActivityId, AssignedDates, AttachSurveys, ClientIds, CustomImage, CustomName, Duration, EditOriginal: +!!EditOriginal, Groups, Injuries, IsModified, Location, RepRange, SaveAsNew: +!!SaveAsNew, StartTime, TemplateId, TrainerNote, TrainingFocus, Units, WorkoutExercises: NewWorkoutExercises, WorkoutImage, WorkoutType: 1 }).then(({ PreSignedUrls }) => {
                                if (WorkoutImage && PreSignedUrls.length > 0) {
                                    var UploadFunction = this.props.UploadToPresignedUrl;
                                    var Promises = PreSignedUrls.map((PreSignedUrl, index) => CreateUploadToPresignedUrlPromise({ ContentType: 'AddWorkouts', CustomLabelName: `${SelectedImage.name} - ${index + 1}`, File: SelectedImage, PresignedFormData: PreSignedUrl, UploadFunction }));
                    
                                    Promise.all(Promises).then(() => this.onResetWeeklyView());
                                } else this.onResetWeeklyView();
                            });
                        }
                        else if (Strength.HowToBuildSession === 'Scratch') {
                            // if (!!ShowDateTimes) AssignedDates = Dates.map(AssignedDate => `${AssignedDate.substring(0,10)} 00:00`).join(',');
                            if (!!ShowDateTimes) AssignedDates = Dates.map(AssignedDate => moment(moment(AssignedDate).format('YYYY-MM-DD') + ' ' + StartTime).format('YYYY-MM-DD HH:mm')).join(',');
                            // moment(moment(SelectedDates[i]).format('YYYY-MM-DD') + ' ' + FirstTime).format('YYYY-MM-DD HH:mm');

                            this.props.AddNewWorkout({ ActivityId, AssignedDates, AttachSurveys, ClientIds, CustomImage, CustomName, Duration, Groups, HasDateTimes: !!ShowDateTimes, Location, SaveAsNew: +!!SaveAsNew, StartTime, TrainerNote, Units, WorkoutExercises: NewWorkoutExercises, WorkoutImage, WorkoutType: 1 }).then(({ PreSignedUrls }) => {
                                if (WorkoutImage && PreSignedUrls.length > 0) {
                                    var UploadFunction = this.props.UploadToPresignedUrl;
                                    var Promises = PreSignedUrls.map((PreSignedUrl, index) => CreateUploadToPresignedUrlPromise({ ContentType: 'AddWorkouts', CustomLabelName: `${SelectedImage.name} - ${index + 1}`, File: SelectedImage, PresignedFormData: PreSignedUrl, UploadFunction }));
                    
                                    Promise.all(Promises).then(() => this.onResetWeeklyView());
                                } else this.onResetWeeklyView();
                            });
                        }
                    }
                    else if (WorkoutType === 'Endurance') {
                        // eslint-disable-next-line
                        var { TemplateDetails: { TrainerNote, Units }, TemplateId, WorkoutLaps: { ActiveType, CardioLevel, DataType, Laps } } = this.state.Endurance.Template;

                        if (Endurance.HowToBuildSession === 'Scratch') Units = this.props.UserDetails.PreferredMeasuringSystem.toLowerCase();

                        if (Endurance.HowToBuildSession === 'Template' || (Endurance.HowToBuildSession === 'Scratch' && +!!SaveAsNew)) {
                            if (Endurance.HowToBuildSession === 'Scratch') { IsModified = 1; TemplateId = 0; }

                            this.props.AddExistingWorkoutTemplate({ ActiveType, ActivityId, AssignedDates, AttachSurveys, CardioLevel, ClientIds, CustomImage, CustomName, DataType, Duration, EditOriginal: +!!EditOriginal, Groups, Injuries, IsModified, Location, RepRange, SaveAsNew: +!!SaveAsNew, StartTime, TemplateId, TrainerNote, TrainingFocus, Units, WorkoutImage, WorkoutLaps: Laps, WorkoutType: 2 }).then(({ PreSignedUrls }) => {
                                if (WorkoutImage && PreSignedUrls.length > 0) {
                                    var UploadFunction = this.props.UploadToPresignedUrl;
                                    var Promises = PreSignedUrls.map((PreSignedUrl, index) => CreateUploadToPresignedUrlPromise({ ContentType: 'AddWorkouts', CustomLabelName: `${SelectedImage.name} - ${index + 1}`, File: SelectedImage, PresignedFormData: PreSignedUrl, UploadFunction }));
                    
                                    Promise.all(Promises).then(() => this.onResetWeeklyView());
                                } else this.onResetWeeklyView();
                            });
                        }
                        else if (Endurance.HowToBuildSession === 'Scratch') {
                            if (!!ShowDateTimes) AssignedDates = Dates.map(AssignedDate => moment(moment(AssignedDate).format('YYYY-MM-DD') + ' ' + StartTime).format('YYYY-MM-DD HH:mm')).join(',');

                            this.props.AddNewWorkout({ ActiveType, ActivityId, AssignedDates, AttachSurveys, CardioLevel, ClientIds, CustomImage, CustomName, DataType, Duration, Groups, HasDateTimes: !!ShowDateTimes, Location, SaveAsNew: +!!SaveAsNew, TrainerNote, Units, WorkoutImage, WorkoutLaps: Laps, WorkoutType: 2 }).then(({ PreSignedUrls }) => {
                                if (WorkoutImage && PreSignedUrls.length > 0) {
                                    var UploadFunction = this.props.UploadToPresignedUrl;
                                    var Promises = PreSignedUrls.map((PreSignedUrl, index) => CreateUploadToPresignedUrlPromise({ ContentType: 'AddWorkouts', CustomLabelName: `${SelectedImage.name} - ${index + 1}`, File: SelectedImage, PresignedFormData: PreSignedUrl, UploadFunction }));
                    
                                    Promise.all(Promises).then(() => this.onResetWeeklyView());
                                } else this.onResetWeeklyView();
                            });
                        }
                    }
                    else if (WorkoutType === 'Activity') {
                        Units = this.props.UserDetails.PreferredMeasuringSystem.toLowerCase();

                        // eslint-disable-next-line
                        var { Template: { TemplateDetails: { IntensityId, TrainerNote }, TemplateId } } = this.state.Activity;

                        if (Activity.HowToBuildSession === 'Template' || (Activity.HowToBuildSession === 'Scratch' && +!!SaveAsNew)) {
                            if (Activity.HowToBuildSession === 'Scratch') { IsModified = 1; TemplateId = 0; }

                            this.props.AddExistingWorkoutTemplate({ ActivityId, AssignedDates, AttachSurveys, ClientIds, CustomImage, CustomName, Duration, EditOriginal: +!!EditOriginal, Groups, Injuries, IntensityId, IsModified, Location, RepRange, SaveAsNew: +!!SaveAsNew, StartTime, TemplateId, TrainerNote, TrainingFocus, Units, WorkoutImage, WorkoutType: 3 }).then(({ PreSignedUrls }) => {
                                if (WorkoutImage && PreSignedUrls.length > 0) {
                                    var UploadFunction = this.props.UploadToPresignedUrl;
                                    var Promises = PreSignedUrls.map((PreSignedUrl, index) => CreateUploadToPresignedUrlPromise({ ContentType: 'AddWorkouts', CustomLabelName: `${SelectedImage.name} - ${index + 1}`, File: SelectedImage, PresignedFormData: PreSignedUrl, UploadFunction }));
                    
                                    Promise.all(Promises).then(() => this.onResetWeeklyView());
                                } else this.onResetWeeklyView();
                            });
                        }
                        else if (Activity.HowToBuildSession === 'Scratch') {
                            if (!!ShowDateTimes) AssignedDates = Dates.map(AssignedDate => moment(moment(AssignedDate).format('YYYY-MM-DD') + ' ' + StartTime).format('YYYY-MM-DD HH:mm')).join(',');

                            this.props.AddNewWorkout({ ActivityId, AssignedDates, AttachSurveys, ClientIds, CustomImage, CustomName, Duration, Groups, HasDateTimes: !!ShowDateTimes, IntensityId, Location, SaveAsNew: +!!SaveAsNew, TrainerNote, Units, WorkoutImage, WorkoutType: 3 }).then(({ PreSignedUrls }) => {
                                if (WorkoutImage && PreSignedUrls.length > 0) {
                                    var UploadFunction = this.props.UploadToPresignedUrl;
                                    var Promises = PreSignedUrls.map((PreSignedUrl, index) => CreateUploadToPresignedUrlPromise({ ContentType: 'AddWorkouts', CustomLabelName: `${SelectedImage.name} - ${index + 1}`, File: SelectedImage, PresignedFormData: PreSignedUrl, UploadFunction }));
                    
                                    Promise.all(Promises).then(() => this.onResetWeeklyView());
                                } else this.onResetWeeklyView();
                            });
                        }
                    }
                }
            } else {
                ScreenNumber = Direction === 1 ? ScreenNumber + 1 : ScreenNumber - 1;

                // Get Additional Activity Details (for intensities) if ScreenNumber === 3 And On Activity
                // OR ScreenNumber === 4 And On Strength Or Endurance And HowToBuildSession === 'Scratch'
                    if (!!Direction && !this.state.AdditionalActivityDetails.AdditionalActivities.length && (
                        (ScreenNumber === 3 && WorkoutType === 'Activity' && Activity.HowToBuildSession === 'Scratch') ||
                        (ScreenNumber === 4 && WorkoutType === 'Endurance' && Endurance.HowToBuildSession === 'Scratch') ||
                        (ScreenNumber === 4 && WorkoutType === 'Strength' && Strength.HowToBuildSession === 'Scratch')
                    )) {
                        var { AdditionalActivities, Intensities } = await this.props.GetAdditionalActivityDetails({ });
                        var NewOptionalDetails = { ...OptionalDetails };

                        // Update Default Activity For Strength And Endurance
                            if (WorkoutType === 'Endurance' || WorkoutType === 'Strength') {
                                for (var i = 0; i < AdditionalActivities.length; i++) {
                                    if (WorkoutType === 'Endurance' && +AdditionalActivities[i].value === 34) {
                                        NewOptionalDetails = {
                                            ...NewOptionalDetails,
                                            ActivityId: +AdditionalActivities[i].value,
                                            ActivityName: AdditionalActivities[i].label,
                                            ActivityPictures: AdditionalActivities[i].Pictures,
                                            ActivityPicture: AdditionalActivities[i].Pictures[0],
                                            ActivityPictureId: +AdditionalActivities[i].value
                                        }
                                        break;
                                    } else if (WorkoutType === 'Strength' && +AdditionalActivities[i].value === 60) {
                                        NewOptionalDetails = {
                                            ...NewOptionalDetails,
                                            ActivityId: +AdditionalActivities[i].value,
                                            ActivityName: AdditionalActivities[i].label,
                                            ActivityPictures: AdditionalActivities[i].Pictures,
                                            ActivityPicture: AdditionalActivities[i].Pictures[0],
                                            ActivityPictureId: +AdditionalActivities[i].value
                                        }
                                        break;
                                    }
                                }
                            }

                        this.setState({ AdditionalActivityDetails: { AdditionalActivities, Intensities }, OptionalDetails: NewOptionalDetails });
                    }

                // Show Library Modal After Clicking On Next Button, And Don't Increment Screen Number
                    if (!!Direction && ScreenNumber === 3 && WorkoutType === 'Strength' && Strength.HowToBuildSession === 'Template' && !Strength.Template.WorkoutExercises.length) {
                        ScreenNumber--;
                        this.onToggleShowTemplateLibraryModal({ ShowTemplateLibraryModal: true, TemplateType: 'Strength' });
                    }
                    else if (!!Direction && ScreenNumber === 3 && WorkoutType === 'Endurance' && Endurance.HowToBuildSession === 'Template' && !Endurance.Template.WorkoutLaps.Laps.length) {
                        ScreenNumber--;
                        this.onToggleShowTemplateLibraryModal({ ShowTemplateLibraryModal: true, TemplateType: 'Endurance' });
                    }
                    else if (!!Direction && ScreenNumber === 3 && WorkoutType === 'Activity' && Activity.HowToBuildSession === 'Template' && !Activity.Template.TemplateId) {
                        ScreenNumber--;
                        this.onToggleShowTemplateLibraryModal({ ShowTemplateLibraryModal: true, TemplateType: 'Activity' });
                    }
                    else if (!!Direction && ScreenNumber === 2 && WorkoutType === 'TrainingProgram' && !TrainingProgram.TrainingProgramId) {
                        ScreenNumber--;
                        this.onToggleShowTrainingProgramLibraryModal(true);
                    }

                this.setState({ ScreenNumber });

                // window.scrollTo(0, this.topRef.current.offsetTop);
            }
    }

    onChangeDateTime = StartTime => {
        var OptionalDetails = { ...this.state.OptionalDetails, StartTime: moment(StartTime).format('YYYY-MM-DD HH:mm') };

        this.setState({ IsModified: 1, OptionalDetails });
    }

    onChangeTrainerNote = event => {
        var { WorkoutType } = this.state;

        var NewTemplate = { ...this.state[WorkoutType] };
        NewTemplate.Template.TemplateDetails.TrainerNote = event.target.value;

        this.setState({ IsModified: 1, [WorkoutType]: NewTemplate });
    }

    onClickShowEditWorkoutTemplate = async ({ DayIndex = null, FromUniqueTemplates = false, TemplateId, WorkoutTemplateIndex = null }) => {
        // Add Template Details To ShowEditTrainingProgramWorkoutTemplateDetails
        var ShowEditTrainingProgramWorkoutTemplateDetails = {
            Template: {},
            WhereToSave: { DayIndex, FromUniqueTemplates, TemplateId, WorkoutTemplateIndex }
        };

        var { TrainingProgram } = this.state;

        // Getting From Unique Templates, Check If Unique Template Has Already Called API For Template First
            if (!!FromUniqueTemplates) {
                if (!TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].RetrievedFullTemplateData) {
                    TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].FullTemplateData = await this.props.GetWorkoutTemplateDetail({ TemplateId });
                    TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].RetrievedFullTemplateData = 1;

                    if (TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].WorkoutType === 2) TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].FullTemplateData.WorkoutLaps.Laps = [].concat(...TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].FullTemplateData.WorkoutLaps.Laps.map(WL => WL.Laps.map(Lap => ({ ...Lap, LapType: WL.Type }))))
                }

                ShowEditTrainingProgramWorkoutTemplateDetails.Template = { ...TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].FullTemplateData };
            }
        // Getting From Specific Template, Check First If Specific Template Has Detail (for custom changes already made), otherwise use data from Unique Template if exists, otherwise call API
            else {
                // If Specific Template Does Not Have Full Template Data OR It Is !IsModified, Then Check Unique Template
                    if (!TrainingProgram.Days[DayIndex].WorkoutTemplates[WorkoutTemplateIndex].RetrievedFullTemplateData || !TrainingProgram.Days[DayIndex].WorkoutTemplates[WorkoutTemplateIndex].IsModified) {
                        // If Unique Template Does Not Have Full Template Data, Then Call API
                            if (!TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].RetrievedFullTemplateData) {
                                var FullTemplateData = await this.props.GetWorkoutTemplateDetail({ TemplateId });

                                TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`] = { ...TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`], FullTemplateData, RetrievedFullTemplateData: 1 };

                                if (TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].WorkoutType === 2) TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`].FullTemplateData.WorkoutLaps.Laps = [].concat(...FullTemplateData.WorkoutLaps.Laps.map(WL => WL.Laps.map(Lap => ({ ...Lap, LapType: WL.Type }))));
                            }

        
                        TrainingProgram.Days[DayIndex].WorkoutTemplates[WorkoutTemplateIndex].RetrievedFullTemplateData = 1;
                        TrainingProgram.Days[DayIndex].WorkoutTemplates[WorkoutTemplateIndex].FullTemplateData = FullTemplateData;

                        ShowEditTrainingProgramWorkoutTemplateDetails.Template = { ...FullTemplateData };
                    }
                // If Specific Template Does Already Have Full Template, Use It's Data
                    else ShowEditTrainingProgramWorkoutTemplateDetails.Template = { ...TrainingProgram.Days[DayIndex].WorkoutTemplates[WorkoutTemplateIndex].FullTemplateData };
            }

        this.setState({ ShowEditTrainingProgramWorkoutTemplate: true, ShowEditTrainingProgramWorkoutTemplateDetails, TrainingProgram });
    }

    onClickHowToBuildSession = ({ HowToBuildSession, HowToBuildSessionType }) => {
        // Show Modal Letting Them Know That If They Switch To A New Type (either null or different), it will restart their customizations if they've previously had workout exercises
        if (HowToBuildSessionType === 'Strength') {
            if (!!this.state.Strength.HowToBuildSession && this.state.Strength.HowToBuildSession === HowToBuildSession) HowToBuildSession = null;

            if (HowToBuildSession === null || (!!this.state.Strength.HowToBuildSession && this.state.Strength.HowToBuildSession !== HowToBuildSession)) {
                // If Modified, Then Show Warning
                    if (!!this.state.IsModified) this.setState({ ShowHowToBuildResetWarningModal: true, ShowHowToBuildResetWarningModal_NewStatus: HowToBuildSession });
                // Else Reset Automatically && Switch HowToBuildSessionTypes
                    else this.onResetStrengthDetails(HowToBuildSession);
            }
            else this.setState({ Strength: { ...this.state.Strength, HowToBuildSession } }, () => {
                if (!!HowToBuildSession) this.onChangeScreenNumber(1);
            });
        }
        else if (HowToBuildSessionType === 'Endurance') {
            if (!!this.state.Endurance.HowToBuildSession && this.state.Endurance.HowToBuildSession === HowToBuildSession) HowToBuildSession = null;

            if (HowToBuildSession === null || (!!this.state.Endurance.HowToBuildSession && this.state.Endurance.HowToBuildSession !== HowToBuildSession)) {
                // If Modified, Then Show Warning
                    if (!!this.state.IsModified) this.setState({ ShowHowToBuildResetWarningModal: true, ShowHowToBuildResetWarningModal_NewStatus: HowToBuildSession });
                // Else Reset Automatically && Switch HowToBuildSessionTypes
                    else this.onResetEnduranceDetails(HowToBuildSession);
            }
            else this.setState({ Endurance: { ...this.state.Endurance, HowToBuildSession } }, () => {
                if (!!HowToBuildSession) this.onChangeScreenNumber(1);
            });
        }
        else if (HowToBuildSessionType === 'Activity') {
            if (!!this.state.Activity.HowToBuildSession && this.state.Activity.HowToBuildSession === HowToBuildSession) HowToBuildSession = null;

            if (HowToBuildSession === null || (!!this.state.Activity.HowToBuildSession && this.state.Activity.HowToBuildSession !== HowToBuildSession)) {
                // If Modified, Then Show Warning
                    if (!!this.state.IsModified) this.setState({ ShowHowToBuildResetWarningModal: true, ShowHowToBuildResetWarningModal_NewStatus: HowToBuildSession });
                // Else Reset Automatically && Switch HowToBuildSessionTypes
                    else this.onResetActivityDetails(HowToBuildSession);
            }
            else this.setState({ Activity: { ...this.state.Activity, HowToBuildSession } }, () => {
                if (!!HowToBuildSession) this.onChangeScreenNumber(1);
            });
        }
    }

    onClickWorkoutType = WorkoutType => {
        if (this.state.WorkoutType === WorkoutType) WorkoutType = null;

        if (this.state.WorkoutType === 'TrainingProgram' && WorkoutType === null) {
            var TrainingProgram = {
                DayIds: [],
                Days: [],
                ProgramName: '',
                TemplateDays: null,
                TrainingProgramId: null,
                WorkoutTemplates: {}
            }

            this.setState({ TrainingProgram, WorkoutType }, () => {
                if (!!WorkoutType) this.onChangeScreenNumber(1);
            });
        }
        else this.setState({ WorkoutType }, () => {
            if (!!WorkoutType) this.onChangeScreenNumber(1);
        });
    }

    onDeleteExercise = ({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex }) => {
        var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

        var CircuitNumber = 1;
        var NewWorkoutExercises = [];
        var UpdatableCircuits = [];
        var WorkoutExercises = [];

        if (!!ShowEditTrainingProgramWorkoutTemplate) WorkoutExercises = [ ...ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises ];
        else WorkoutExercises = [ ...this.state.Strength.Template.WorkoutExercises ];

        WorkoutExercises.map((Circuit, index) => {
            NewWorkoutExercises.push({ ...Circuit });

            if (WorkoutExerciseIndex === index) {
                NewWorkoutExercises[NewWorkoutExercises.length - 1].WorkoutExercises = [];

                Circuit.WorkoutExercises.map((WE, WEIndex) => {
                    if (WEIndex !== CircuitWorkoutExerciseIndex) NewWorkoutExercises[NewWorkoutExercises.length - 1].WorkoutExercises.push({ ...WE });
                    
                    return null;
                });
            }

            return null;
        });

        CircuitNumber = 1;
        UpdatableCircuits = NewWorkoutExercises.map(Circuit => {
            Circuit.NumExercises = Circuit.WorkoutExercises.length;

            if (Circuit.WorkoutExercises.length === 1) Circuit = { ...Circuit, CircuitNumber: 0, Type: 'WorkoutExercise' };
            else {
                Circuit.CircuitNumber = CircuitNumber;
                CircuitNumber++;
            }

            return Circuit;
        }).filter(Circuit => !!Circuit.NumExercises);

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            var NewShowEditTrainingProgramWorkoutTemplateDetails = { ...ShowEditTrainingProgramWorkoutTemplateDetails };
            NewShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises = UpdatableCircuits;

            this.setState({ ShowEditTrainingProgramWorkoutTemplateDetails: NewShowEditTrainingProgramWorkoutTemplateDetails });
        }
        else {
            var NewStrength = { ...this.state.Strength };
            NewStrength.Template.WorkoutExercises = UpdatableCircuits;

            this.setState({ IsModified: 1, Strength: NewStrength });
        }
    }

    onDeleteLap = LapIndex => {
        var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

        var Laps = [];

        if (!!ShowEditTrainingProgramWorkoutTemplate) Laps = [ ...ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutLaps.Laps ];
        else Laps = [ ...this.state.Endurance.Template.WorkoutLaps.Laps ];

        Laps = [ ...Laps.slice(0, LapIndex), ...Laps.slice(LapIndex + 1) ];

        var LapNumber = 1;
        Laps = Laps.map((Lap, index) => ({ ...Lap, LapNumber: Lap.LapType === 'Active' ? LapNumber++ : 0, LapOrder: index + 1 }));

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            var NewShowEditTrainingProgramWorkoutTemplateDetails = { ...ShowEditTrainingProgramWorkoutTemplateDetails };
            NewShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutLaps.Laps = Laps;

            this.setState({ ShowEditTrainingProgramWorkoutTemplateDetails: NewShowEditTrainingProgramWorkoutTemplateDetails });
        }
        else {
            var NewEndurance = { ...this.state.Endurance };
            NewEndurance.Template.WorkoutLaps.Laps = Laps;

            this.setState({ IsModified: 1, Endurance: NewEndurance });
        }
    }

    onDeleteSet = ({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex, WorkoutSetIndex }) => {
        var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

        var WorkoutExercises = [];

        if (!!ShowEditTrainingProgramWorkoutTemplate) WorkoutExercises = [ ...ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises ];
        else WorkoutExercises = [ ...this.state.Strength.Template.WorkoutExercises ];

        // Delete Individual Set
            if (WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.length > 1) {
                WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets = [ ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.slice(0, WorkoutSetIndex), ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.slice(WorkoutSetIndex + 1) ];

                if (!!ShowEditTrainingProgramWorkoutTemplate) {
                    var NewShowEditTrainingProgramWorkoutTemplateDetails = { ...ShowEditTrainingProgramWorkoutTemplateDetails };
                    NewShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises = WorkoutExercises;
        
                    this.setState({ ShowEditTrainingProgramWorkoutTemplateDetails: NewShowEditTrainingProgramWorkoutTemplateDetails });
                }
                else {
                    var NewStrength = { ...this.state.Strength };
                    NewStrength.Template.WorkoutExercises = WorkoutExercises;
        
                    this.setState({ IsModified: 1, Strength: NewStrength });
                }
            }
        // Deleting last set, so remove full Workout Exercise
            else this.onDeleteExercise({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex });
    }

    onDragEnd = result => {
        // this.setState({ IsDragging: null });
        var { ActionType = null, destination, destinations = null, source, draggableId, TemplateId } = result;

        if (!destination && !destinations) return;
        if (destination && destination.droppableId === source.droppableId && destination.index === source.index) return;

        draggableId = TemplateId ? TemplateId : draggableId.split('-')[0];
        var NewWorkoutTemplateId = `TemplateId-${draggableId}`;

        var NewWorkoutTemplateDetail;

        var TrainingProgram = { ...this.state.TrainingProgram };

        var StartDayIndex = TrainingProgram.DayIds.indexOf(source.droppableId);
        var StartDay = { ...TrainingProgram.Days[StartDayIndex] };

        var FinishDayIndex = destination && TrainingProgram.DayIds.indexOf(destination.droppableId);
        var FinishDay = destination && { ...TrainingProgram.Days[FinishDayIndex] };

        var NewDays, FinishDay_WorkoutTemplateIds, FinishDay_WorkoutTemplates, NewFinishDay;

        if (source.droppableId === 'SelectedWorkoutTemplates' || ActionType === 'Copy') {
            var DestinationsArr = [];

            var WorkoutTemplate = {};
            if (!ActionType || ActionType === 'Add') WorkoutTemplate = { ...TrainingProgram.WorkoutTemplates[NewWorkoutTemplateId] };
            else WorkoutTemplate = { ...StartDay.WorkoutTemplates[source.index] };
            
            NewWorkoutTemplateDetail = WorkoutTemplate;
            // NewWorkoutTemplateDetail.CustomSettings = MakeCustomSettings();
            NewWorkoutTemplateDetail.TemplateDetails = { Location: WorkoutTemplate.Location, StartTime: WorkoutTemplate.StartTime, TemplateName: WorkoutTemplate.TemplateName, TrainerNote: WorkoutTemplate.TrainerNote };

            // Check Data in destinations, instead of just destination, as multiple days can be added to
                if (ActionType === 'Add' || ActionType === 'Copy') DestinationsArr = [ ...destinations ];
                else DestinationsArr = [ destination ];

            for (var i = 0; i < DestinationsArr.length; i++) {
                destination = DestinationsArr[i];

                FinishDayIndex = TrainingProgram.DayIds.indexOf(destination.droppableId);
                FinishDay = TrainingProgram.Days[FinishDayIndex];

                FinishDay_WorkoutTemplateIds = Array.from(FinishDay.WorkoutTemplateIds);
                FinishDay_WorkoutTemplateIds.splice(destination.index, 0, NewWorkoutTemplateId);

                FinishDay_WorkoutTemplates = Array.from(FinishDay.WorkoutTemplates);
                FinishDay_WorkoutTemplates.splice(destination.index, 0, NewWorkoutTemplateDetail);

                // Remove the IsModified: 1 status from all FinishDay_WorkoutTemplates If From SelectedWorkoutTemplates, As That Status Is Used For Being In Sync With Unique Template (IsModified: 0 = in sync)
                    if (source.droppableId === 'SelectedWorkoutTemplates') FinishDay_WorkoutTemplates = FinishDay_WorkoutTemplates.map(FDWT => ({ ...FDWT, IsModified: 0 }));

                NewFinishDay = { ...FinishDay, WorkoutTemplateIds: FinishDay_WorkoutTemplateIds, WorkoutTemplates: FinishDay_WorkoutTemplates };

                NewDays = [ ...TrainingProgram.Days.slice(0, FinishDayIndex), NewFinishDay, ...TrainingProgram.Days.slice(FinishDayIndex + 1) ];
                
                TrainingProgram.Days = NewDays;
            }

            // Add Template Day Index Details To Workout Template For Unique Sessions
                // if (Object(TrainingProgram.WorkoutTemplates[NewWorkoutTemplateId].Days).hasOwnProperty(destination.droppableId)) TrainingProgram.WorkoutTemplates[NewWorkoutTemplateId].Days.push(destination.index);
                // else TrainingProgram.WorkoutTemplates[NewWorkoutTemplateId].Days[destination.droppableId] = [ destination.index ];

            this.setState({ IsModified: 1, TrainingProgram });
        } else {
            // eslint-disable-next-line
            var WorkoutTemplate = StartDay.WorkoutTemplates[source.index];
            NewWorkoutTemplateDetail = WorkoutTemplate;

            if (StartDayIndex === FinishDayIndex) {
                var SameDay_WorkoutTemplateIds = Array.from(StartDay.WorkoutTemplateIds);
                SameDay_WorkoutTemplateIds.splice(source.index, 1);
                SameDay_WorkoutTemplateIds.splice(destination.index, 0, NewWorkoutTemplateId);

                var SameDay_WorkoutTemplates = Array.from(StartDay.WorkoutTemplates);
                SameDay_WorkoutTemplates.splice(source.index, 1);
                SameDay_WorkoutTemplates.splice(destination.index, 0, NewWorkoutTemplateDetail);
                
                var NewSameDay = { ...StartDay, WorkoutTemplateIds: SameDay_WorkoutTemplateIds, WorkoutTemplates: SameDay_WorkoutTemplates };

                NewDays = [ ...TrainingProgram.Days.slice(0, StartDayIndex), NewSameDay, ...TrainingProgram.Days.slice(StartDayIndex + 1) ];

                TrainingProgram.Days = NewDays;

                this.setState({ IsModified: 1, TrainingProgram });
                return;
            }

            var StartDay_WorkoutTemplateIds = Array.from(StartDay.WorkoutTemplateIds);
            StartDay_WorkoutTemplateIds.splice(source.index, 1);

            var StartDay_WorkoutTemplates = Array.from(StartDay.WorkoutTemplates);
            StartDay_WorkoutTemplates.splice(source.index, 1);

            var NewStartDay = { ...StartDay, WorkoutTemplateIds: StartDay_WorkoutTemplateIds, WorkoutTemplates: StartDay_WorkoutTemplates };

            NewDays = [ ...TrainingProgram.Days.slice(0, StartDayIndex), NewStartDay, ...TrainingProgram.Days.slice(StartDayIndex + 1) ];

            FinishDay_WorkoutTemplateIds = Array.from(FinishDay.WorkoutTemplateIds);
            FinishDay_WorkoutTemplateIds.splice(destination.index, 0, NewWorkoutTemplateId);

            FinishDay_WorkoutTemplates = Array.from(FinishDay.WorkoutTemplates);
            FinishDay_WorkoutTemplates.splice(destination.index, 0, NewWorkoutTemplateDetail);

            NewFinishDay = { ...FinishDay, WorkoutTemplateIds: FinishDay_WorkoutTemplateIds, WorkoutTemplates: FinishDay_WorkoutTemplates };

            NewDays = [ ...NewDays.slice(0, FinishDayIndex), NewFinishDay, ...NewDays.slice(FinishDayIndex + 1) ];

            TrainingProgram.Days = NewDays;

            this.setState({ IsModified: 1, TrainingProgram });
        }
    }

    onFocusAdditionalActivities = () => {
        if (!this.state.AdditionalActivityDetails.AdditionalActivities.length) {
            this.props.GetAdditionalActivityDetails({}).then(({ AdditionalActivities, Intensities }) => {
                this.setState({ AdditionalActivityDetails: { AdditionalActivities, Intensities } });
            });
        }
    }

    onMoveWorkoutTemplate = MockDrag => {
        this.onDragEnd(MockDrag);
    }

    onRemoveTrainingProgramDay = ({ DayIndex }) => {
        var TrainingProgram = { ...this.state.TrainingProgram };

        // Remove Last Day in TrainingProgram.DayIds
        TrainingProgram.DayIds.splice(-1, 1);

        // Remove Day At Index In TrainingProgram.Days, Then Update Each Day in TrainingProgram.Days > DayIndex To Have DayId be one greater
        TrainingProgram.Days.splice(DayIndex, 1);
        TrainingProgram.Days = TrainingProgram.Days.map((Day, index) => ({ ...Day, DayId: `Day-${index + 1}`}));

        TrainingProgram.TemplateDays--;

        // Edit Selected Dates In AssigningInfo Automatically If Already Selected
        var { AssigningDetails } = this.state;

        if (!!AssigningDetails.StartDate) {
            var SelectedDates = [];

            for (var i = 0; i < TrainingProgram.TemplateDays; i++) {
                SelectedDates.push(moment(AssigningDetails.StartDate).add(i, 'days').format('YYYY-MM-DD'));
            }

            AssigningDetails.Dates = SelectedDates;
        }

        this.setState({ AssigningDetails, IsModified: 1, TrainingProgram });
    }

    onRemoveWorkoutTemplate = ({ DayIndex = null, FromUniqueTemplates = false, TemplateId, WorkoutTemplateIndex = null }) => {
        var TrainingProgram = { ...this.state.TrainingProgram };

        // FromSelectedTemplates ... if this option is true, then need to delete this template from each day in the training program (this only is called AFTER a warning modal is shown)
        if (!!FromUniqueTemplates) {
            this.onToggleShowRemoveWorkoutTemplateWarningModal({ ShowRemoveWorkoutTemplateWarningModal: false });

            var NewDays = [ ...TrainingProgram.Days ]

            NewDays = NewDays.map(TrainingProgramDay => ({
                ...TrainingProgramDay,
                WorkoutTemplateIds: TrainingProgramDay.WorkoutTemplateIds.filter(WTId => +WTId !== +TemplateId),
                WorkoutTemplates: TrainingProgramDay.WorkoutTemplates.filter(WT => +WT.TemplateId !== +TemplateId) 
            }));

            TrainingProgram.Days = NewDays;

            delete TrainingProgram.WorkoutTemplates[`TemplateId-${TemplateId}`];
        } else {
            var RemoveWorkoutTemplate = TrainingProgram.Days[DayIndex];

            var RemoveWorkoutTemplate_WorkoutTemplateIds = Array.from(RemoveWorkoutTemplate.WorkoutTemplateIds);
            RemoveWorkoutTemplate_WorkoutTemplateIds.splice(WorkoutTemplateIndex, 1);

            var RemoveWorkoutTemplate_WorkoutTemplates = Array.from(RemoveWorkoutTemplate.WorkoutTemplates);
            RemoveWorkoutTemplate_WorkoutTemplates.splice(WorkoutTemplateIndex, 1);

            var NewRemoveWorkoutTemplate_Day = { ...RemoveWorkoutTemplate, WorkoutTemplateIds: RemoveWorkoutTemplate_WorkoutTemplateIds, WorkoutTemplates: RemoveWorkoutTemplate_WorkoutTemplates };

            var RemoveWorkoutTemplate_Days = Array.from(TrainingProgram.Days);
            RemoveWorkoutTemplate_Days.splice(DayIndex, 1, NewRemoveWorkoutTemplate_Day);

            TrainingProgram.Days = RemoveWorkoutTemplate_Days;
        }

        this.setState({ IsModified: 1, TrainingProgram });
    }

    onResetActivityDetails = HowToBuildSession => {
        var Activity = { ...this.state.Activity };
        Activity.HowToBuildSession = HowToBuildSession || this.state.ShowHowToBuildResetWarningModal_NewStatus;
        Activity.Template = { TemplateDetails: { IntensityId: null, TrainerNote: '' } };

        // var AssigningDetails = { Dates: [], Groups: {}, StartDate: null, UserIds: [], Users: [] }
        var OptionalDetails = { ...DefaultOptionalDetails };

        this.setState({ Activity, IsModified: 0, OptionalDetails, ShowHowToBuildResetWarningModal_NewStatus: null }, () => this.onToggleShowHowToBuildResetWarningModal(false));
    }

    onResetEnduranceDetails = HowToBuildSession => {
        var Endurance = { ...this.state.Endurance };
        Endurance.HowToBuildSession = HowToBuildSession || this.state.ShowHowToBuildResetWarningModal_NewStatus;
        Endurance.Template = { TemplateDetails: { TrainerNote: '' }, WorkoutLaps: { ActiveType: null, CardioLevel: null, DataType: null, EnduranceTypeId: null, Laps: [] } };

        // var AssigningDetails = { Dates: [], Groups: {}, StartDate: null, UserIds: [], Users: [] }
        var OptionalDetails = DefaultOptionalDetails;

        this.setState({ Endurance, IsModified: 0, OptionalDetails, ShowHowToBuildResetWarningModal_NewStatus: null }, () => this.onToggleShowHowToBuildResetWarningModal(false));
    }

    onResetStrengthDetails = HowToBuildSession => {
        var Strength = { ...this.state.Strength };
        Strength.HowToBuildSession = HowToBuildSession || this.state.ShowHowToBuildResetWarningModal_NewStatus;
        Strength.Template = { TemplateDetails: { TrainerNote: '' }, WorkoutExercises: [] };

        // var AssigningDetails = { Dates: [], Groups: {}, StartDate: null, UserIds: [], Users: [] }
        var OptionalDetails = DefaultOptionalDetails;

        this.setState({ IsModified: 0, OptionalDetails, ShowHowToBuildResetWarningModal_NewStatus: null, Strength }, () => this.onToggleShowHowToBuildResetWarningModal(false));
    }

    onResetTrainingProgram = async () => {
        var { TrainingProgram } = await this.props.GetTrainingProgramDetail({ TrainingProgramId: this.state.TrainingProgram.TrainingProgramId });

        var NewTrainingProgram = ConvertTrainingProgramToDetails(TrainingProgram);

        this.setState({ IsModified: 0, TrainingProgram: NewTrainingProgram });
    }

    onResetWeeklyView = () => {
        var AssigningDetails = { ...this.state.AssigningDetails };

        this.setState({
            Collapsed: true,
            ScreenNumber: 1,
            WorkoutType: null,

            AdditionalActivityDetails: {
                AdditionalActivities: [],
                Intensities: []
            },

            ChangeExerciseInfo: {},
            HourType: 24,
            EditOriginal: 0,
            IsModified: 0,
            MaxImageSize: 10000000,
            SaveAsNew: 0,

            IsDragging: false,

            ShowAddExerciselModal: false,
            ShowAddWorkoutTemplateToProgramModal: false,
            ShowAddWorkoutTemplateToProgramModal_DayIndex: null,
            ShowAddWorkoutTemplateToProgramModal_DisplayNone: false,
            ShowChangeExerciselModal: false,
            ShowDateTimes: false,
            ShowEditTrainingProgramWorkoutTemplate: false,
            ShowEditTrainingProgramWorkoutTemplateDetails: {
                Template: {},
                WhereToSave: {}
            },
            ShowExerciseDetailModal: false,
            ShowExerciseDetailPreview: {},
            ShowHowToBuildResetWarningModal: false,
            ShowHowToBuildResetWarningModal_NewStatus: null,
            ShowRemoveWorkoutTemplateWarningModal: false,
            ShowRemoveWorkoutTemplateWarningModal_TemplateId: null,
            ShowTemplateLibraryModal_DisplayNone: false,
            ShowTemplateLibraryModal_Activity: false,
            ShowTemplateLibraryModal_Endurance: false,
            ShowTemplateLibraryModal_Strength: false,
            ShowTrainingProgramLibraryModal_DisplayNone: false,
            ShowTrainingProgramLibraryModal: false,

            Activity: {
                HowToBuildSession: null,
                Template: {
                    TemplateDetails: {
                        IntensityId: null,
                        TrainerNote: ''
                    }
                }
            },

            AssigningDetails: {
                Dates: [],
                Groups: {},
                StartDate: null,
                UserIds: [],
                Users: []
            },

            Endurance: {
                HowToBuildSession: null,
                Template: {
                    TemplateDetails: {
                        TrainerNote: ''
                    },
                    WorkoutLaps: {
                        ActiveType: null,
                        CardioLevel: null,
                        DataType: null,
                        EnduranceTypeId: null,
                        Laps: []
                    }
                }
            },

            OptionalDetails: { ...DefaultOptionalDetails },

            Strength: {
                HowToBuildSession: null,
                ShowReorderExercises: false,
                Template: {
                    TemplateDetails: {
                        TrainerNote: ''
                    },
                    WorkoutExercises: []
                }
            },

            TrainingProgram: {
                DayIds: [],
                Days: [],
                ModifiedUniqueTemplateIds: [],
                ProgramDescription: '',
                ProgramName: '',
                TemplateDays: null,
                TrainingProgramId: null,
                WorkoutTemplates: {}
            }
        }, () => {
            this.addDefaultClients();
            this.props.OnAddedWorkouts({ ClientIds: AssigningDetails.UserIds, SelectedDates: AssigningDetails.Dates });
        });
    }

    onSaveEditTrainingProgramTemplate = () => {
        var { Template, WhereToSave } = this.state.ShowEditTrainingProgramWorkoutTemplateDetails;

        var TrainingProgram = { ...this.state.TrainingProgram };

        // Save Changes To Unique Template
            if (!!WhereToSave.FromUniqueTemplates) {
                TrainingProgram.WorkoutTemplates[`TemplateId-${WhereToSave.TemplateId}`].FullTemplateData = { ...Template };
                TrainingProgram.WorkoutTemplates[`TemplateId-${WhereToSave.TemplateId}`].IsModified = 1;

                // Add TemplateId To TrainingProgram.ModifiedUniqueTemplateIds
                if (TrainingProgram.ModifiedUniqueTemplateIds.indexOf(+WhereToSave.TemplateId) === -1) TrainingProgram.ModifiedUniqueTemplateIds.push(+WhereToSave.TemplateId);
            }
        // Save Changes To Specific Session
            else {
                TrainingProgram.Days[WhereToSave.DayIndex].WorkoutTemplates[WhereToSave.WorkoutTemplateIndex].FullTemplateData = { ...Template };
                TrainingProgram.Days[WhereToSave.DayIndex].WorkoutTemplates[WhereToSave.WorkoutTemplateIndex].IsModified = 1;
            }

        this.setState({
            IsModified: 1,
            ShowEditTrainingProgramWorkoutTemplate: false,
            ShowEditTrainingProgramWorkoutTemplateDetails: {
                Template: {},
                WhereToSave: {}
            },
            TrainingProgram
        });
    }

    onSelectActivityPicture = ({ ActivityPicture, ActivityPictureId }) => {
        var OptionalDetails = { ...this.state.OptionalDetails, ActivityPicture, ActivityPictureId };
        
        this.setState({ OptionalDetails });
    }

    onSelectCardioLevel = CardioLevel => {
        if (this.state.Endurance.Template.WorkoutLaps.CardioLevel !== CardioLevel) {
            var NewEndurance = { ...this.state.Endurance };
            NewEndurance.Template.WorkoutLaps.CardioLevel = CardioLevel;

            this.setState({ IsModified: 1, Endurance: NewEndurance });
        }
    }

    onSaveCircuits = WorkoutExercises => {
        var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

        var NewStrength = { ...this.state.Strength };
        NewStrength.ShowReorderExercises = false;

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            var NewShowEditTrainingProgramWorkoutTemplateDetails = { ...ShowEditTrainingProgramWorkoutTemplateDetails };
            NewShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ ShowEditTrainingProgramWorkoutTemplateDetails: NewShowEditTrainingProgramWorkoutTemplateDetails, Strength: NewStrength });
        }
        else {
            
            NewStrength.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ IsModified: 1, Strength: NewStrength });
        }
    }

    onSelectClients = ({ SelectedClients: Users, SelectedGroups: Groups }) => {
        var UserIds = Users.map(User => User.UserId);
        Users = Users.map(User => ({ ...User, Sex: User.Gender }));

        var AssigningDetails = { ...this.state.AssigningDetails, Groups, UserIds, Users };

        this.setState({ AssigningDetails });
    }

    onSelectDate = DayDate => {
        var AssigningDetails = { ...this.state.AssigningDetails };

        var { WorkoutType } = this.state;

        var SelectedDates_New = [];

        if (WorkoutType === 'TrainingProgram') {
            var { TrainingProgram } = this.state;

            for (var i = 0; i < TrainingProgram.TemplateDays; i++) {
                SelectedDates_New.push(moment(DayDate).add(i, 'days').format('YYYY-MM-DD'));
            }

            AssigningDetails.StartDate = DayDate;
        } else {
            var SelectedIndex = AssigningDetails.Dates.indexOf(DayDate);

            if (SelectedIndex === -1) SelectedDates_New = [ ...AssigningDetails.Dates, DayDate ];
            else {
                SelectedDates_New = [ ...AssigningDetails.Dates ];
                SelectedDates_New.splice(SelectedIndex, 1);
            }
        }

        AssigningDetails.Dates = SelectedDates_New;

        this.setState({ AssigningDetails });
    }

    onSelectIntensity = IntensityId => {
        if (this.state.Activity.IntensityId !== IntensityId) {
            var NewActivity = { ...this.state.Activity };
            NewActivity.Template.TemplateDetails.IntensityId = IntensityId;

            this.setState({ IsModified: 1, Activity: NewActivity });
        }
    }

    onSelectTemplate = ({ Template, WorkoutType }) => {
        this.onToggleShowTemplateLibraryModal({ ShowTemplateLibraryModal: false, TemplateType: WorkoutType });

        var ActivityDetails = {};
        var OptionalDetails = {
            ActivityId: null,
            ActivityName: '',
            ActivityPicture: null,
            ActivityPictureId: null,
            ActivityPictures: [],
            CustomImage: 0,
            Duration: 0,
            ImageTooLarge: false,
            Location: '',
            Name: '',
            SelectedImage: null,
            StartTime: moment().format('YYYY-MM-DD HH:mm'),
            WorkoutImage: null,
            WrongImageType: false
        }

        // Template Was Selected From Library View, So It Is Missing Template Details and Need To Retrieve
            if (!Template.TemplateDetails) {
                this.props.GetWorkoutTemplateDetail({ TemplateId: Template.TemplateId }).then(Template => {
                    var { AdditionalActivityDetails, TemplateDetails: { ActivityId, CustomImage, CustomName: Name, Duration, Location, StartTime, WorkoutImage } } = Template;

                    ActivityDetails = this.getActivityPicturesFromActivity({ AdditionalActivities: AdditionalActivityDetails.AdditionalActivities, ActivityId, CustomImage, WorkoutImage });

                    OptionalDetails = { ...ActivityDetails, CustomImage, Duration, Location, Name, StartTime: StartTime ? moment(`${moment().format('YYYY-MM-DD')} ${StartTime}`).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm'), WorkoutImage };

                    // Make Each WorkoutExercise Be { Collapsed: true }
                        if (WorkoutType === 'Strength') Template = { ...Template, WorkoutExercises: Template.WorkoutExercises.map(WorkoutExercise => ({ ...WorkoutExercise, WorkoutExercises: WorkoutExercise.WorkoutExercises.map(CircuitWorkoutExercise => ({ ...CircuitWorkoutExercise, Collapsed: true })) })) }
                        else if (WorkoutType === 'Endurance') Template.WorkoutLaps.Laps = [].concat(...Template.WorkoutLaps.Laps.map(WL => WL.Laps.map(Lap => ({ ...Lap, LapType: WL.Type }))))

                    this.setState({ AdditionalActivityDetails, OptionalDetails, ShowDateTimes: !!StartTime, [WorkoutType]: { ...this.state[WorkoutType], Template } }, () => this.onChangeScreenNumber(1));
                });
            }
        // Template Was Selected From Template Detail Modal, So It Has All Required Details Already
            else {
                var { AdditionalActivityDetails, TemplateDetails: { ActivityId, CustomImage, CustomName: Name, Duration, Location, StartTime, WorkoutImage } } = Template;
                
                ActivityDetails = this.getActivityPicturesFromActivity({ AdditionalActivities: AdditionalActivityDetails.AdditionalActivities, ActivityId, CustomImage, WorkoutImage });

                OptionalDetails = { ...ActivityDetails, CustomImage, Duration, Location, Name, StartTime: StartTime ? moment(`${moment().format('YYYY-MM-DD')} ${StartTime}`).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm'), WorkoutImage };

                // Make Each WorkoutExercise Be { Collapsed: true }
                    if (WorkoutType === 'Strength') Template = { ...Template, WorkoutExercises: Template.WorkoutExercises.map(WorkoutExercise => ({ ...WorkoutExercise, WorkoutExercises: WorkoutExercise.WorkoutExercises.map(CircuitWorkoutExercise => ({ ...CircuitWorkoutExercise, Collapsed: true })) })) }

                this.setState({ AdditionalActivityDetails, OptionalDetails, ShowDateTimes: !!StartTime, [WorkoutType]: { ...this.state[WorkoutType], Template } }, () => this.onChangeScreenNumber(1));
            }
    }

    onSelectTrainingProgram = async TrainingProgram => {
        this.onToggleShowTrainingProgramLibraryModal(false);

        if (!TrainingProgram.Days) {
            var ReturnedResult = await this.props.GetTrainingProgramDetail({ TrainingProgramId: TrainingProgram.TrainingProgramId });
            TrainingProgram = ReturnedResult.TrainingProgram;
        }

        var NewTrainingProgram = ConvertTrainingProgramToDetails(TrainingProgram);

        this.setState({ TrainingProgram: NewTrainingProgram }, () => this.onChangeScreenNumber(1));
    }

    onToggleCollapsed = () => {
        this.setState({ Collapsed: !this.state.Collapsed });
    }

    onToggleCollapsedExercise = ({ CircuitWorkoutExerciseIndex, Collapsed, WorkoutExerciseIndex }) => {
        var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

        var WorkoutExercises = [];

        if (!!ShowEditTrainingProgramWorkoutTemplate) WorkoutExercises = [ ...ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises ];
        else WorkoutExercises = [ ...this.state.Strength.Template.WorkoutExercises ];

        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Collapsed = Collapsed;

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            var NewShowEditTrainingProgramWorkoutTemplateDetails = { ...ShowEditTrainingProgramWorkoutTemplateDetails };
            NewShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ ShowEditTrainingProgramWorkoutTemplateDetails: NewShowEditTrainingProgramWorkoutTemplateDetails });
        }
        else {
            var NewStrength = { ...this.state.Strength };
            NewStrength.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ Strength: NewStrength });
        }
    }

    onToggleEditOriginal = () => {
        var EditOriginal = !this.state.EditOriginal;
        
        if (!!EditOriginal) this.setState({ EditOriginal, SaveAsNew: false });
        else this.setState({ EditOriginal });
    }

    onToggleMaxSet = ({ CircuitWorkoutExerciseIndex, MaxSet, WorkoutExerciseIndex, WorkoutSetIndex }) => {
        MaxSet = !!!+MaxSet;

        var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

        var WorkoutExercises = [];

        if (!!ShowEditTrainingProgramWorkoutTemplate) WorkoutExercises = [ ...ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises ];
        else WorkoutExercises = [ ...this.state.Strength.Template.WorkoutExercises ];

        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets = WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.map((WorkoutSet, SetIndex) => {
            if (SetIndex === WorkoutSetIndex) return { ...WorkoutSet, MaxSet };
            else return { ...WorkoutSet, MaxSet: 0 }
        });

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            var NewShowEditTrainingProgramWorkoutTemplateDetails = { ...ShowEditTrainingProgramWorkoutTemplateDetails };
            NewShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ ShowEditTrainingProgramWorkoutTemplateDetails: NewShowEditTrainingProgramWorkoutTemplateDetails });
        }
        else {
            var NewStrength = { ...this.state.Strength };
            NewStrength.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ IsModified: 1, Strength: NewStrength });
        }
    }

    onToggleShowAddExerciseModal = ShowAddExerciselModal => {
        this.setState({ ShowAddExerciselModal });
    }

    onToggleShowAddWorkoutTemplateToProgramModal = ({ ShowAddWorkoutTemplateToProgramModal_DayIndex = null, ShowAddWorkoutTemplateToProgramModal }) => {
        this.setState({ ShowAddWorkoutTemplateToProgramModal_DayIndex, ShowAddWorkoutTemplateToProgramModal });
    }

    onToggleShowAddWorkoutTemplateToProgramModal_DisplayNone = ShowAddWorkoutTemplateToProgramModal_DisplayNone => {
        this.setState({ ShowAddWorkoutTemplateToProgramModal_DisplayNone });
    }

    onToggleShowChangeExerciseModal = ({ CircuitWorkoutExerciseIndex, ShowChangeExerciselModal, WorkoutExerciseIndex }) => {
        var ChangeExerciseInfo = {};

        if (!!ShowChangeExerciselModal) ChangeExerciseInfo = { CircuitWorkoutExerciseIndex, WorkoutExerciseIndex };

        this.setState({ ChangeExerciseInfo, ShowChangeExerciselModal });
    }

    onToggleShowDateTimes = () => {
        this.setState({ ShowDateTimes: !this.state.ShowDateTimes });
    }

    onToggleShowExerciseDetailModal = (ShowExerciseDetailModal, Exercise) => {
        this.setState({ ShowExerciseDetailModal, ShowExerciseDetailPreview: Exercise || {} });
    }

    onToggleShowHowToBuildResetWarningModal = ShowHowToBuildResetWarningModal => {
        this.setState({ ShowHowToBuildResetWarningModal });
    }

    onToggleShowRemoveWorkoutTemplateWarningModal = ({ ShowRemoveWorkoutTemplateWarningModal_TemplateId = null, ShowRemoveWorkoutTemplateWarningModal }) => {
        this.setState({ ShowRemoveWorkoutTemplateWarningModal_TemplateId, ShowRemoveWorkoutTemplateWarningModal });
    }

    onToggleShowReorderExercises = ShowReorderExercises => {
        var NewStrength = { ...this.state.Strength };
        NewStrength.ShowReorderExercises = ShowReorderExercises;

        this.setState({ IsModified: 0, Strength: NewStrength });
    }

    onToggleShowSaveAsNew = () => {
        var SaveAsNew = !this.state.SaveAsNew;
        
        if (!!SaveAsNew) this.setState({ EditOriginal: false, SaveAsNew });
        else this.setState({ SaveAsNew });
    }

    onToggleShowTemplateLibraryModal = ({ ShowTemplateLibraryModal, TemplateType }) => {
        this.setState({ [`ShowTemplateLibraryModal_${TemplateType}`]: ShowTemplateLibraryModal });
    }

    onToggleShowTemplateLibraryModal_Activity_DisplayNone = ShowTemplateLibraryModal_Activity_DisplayNone => {
        this.setState({ ShowTemplateLibraryModal_Activity_DisplayNone });
    }

    onToggleShowTemplateLibraryModal_Endurance_DisplayNone = ShowTemplateLibraryModal_Endurance_DisplayNone => {
        this.setState({ ShowTemplateLibraryModal_Endurance_DisplayNone });
    }

    onToggleShowTemplateLibraryModal_Strength_DisplayNone = ShowTemplateLibraryModal_Strength_DisplayNone => {
        this.setState({ ShowTemplateLibraryModal_Strength_DisplayNone });
    }

    onToggleShowTrainingProgramLibraryModal = ShowTrainingProgramLibraryModal => {
        this.setState({ ShowTrainingProgramLibraryModal });
    }

    onToggleShowTrainingProgramLibraryModal_DisplayNone = ShowTrainingProgramLibraryModal_DisplayNone => {
        this.setState({ ShowTrainingProgramLibraryModal_DisplayNone });
    }

    onUpdateWorkoutLap = ({ Active, BreakTime, LapIndex, LapType, Rest, Speed }) => {
        if (LapType === 'Active') {
            Active = +Active;
            BreakTime = 0;
            Rest = +Rest;
            Speed = +Speed;
        } else if (LapType === 'Break') {
            Active = 0;
            BreakTime = +BreakTime;
            Rest = 0;
            Speed = 0;
        }

        var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

        var Laps = [];

        if (!!ShowEditTrainingProgramWorkoutTemplate) Laps = [ ...ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutLaps.Laps ];
        else Laps = [ ...this.state.Endurance.Template.WorkoutLaps.Laps ];

        Laps[LapIndex] = { ...Laps[LapIndex], Active, BreakTime, Rest, Speed };

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            var NewShowEditTrainingProgramWorkoutTemplateDetails = { ...ShowEditTrainingProgramWorkoutTemplateDetails };
            NewShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutLaps.Laps = Laps;

            this.setState({ ShowEditTrainingProgramWorkoutTemplateDetails: NewShowEditTrainingProgramWorkoutTemplateDetails });
        }
        else {
            var NewEndurance = { ...this.state.Endurance };
            NewEndurance.Template.WorkoutLaps.Laps = Laps;

            this.setState({ IsModified: 1, Endurance: NewEndurance });
        }
    }

    onUpdateWorkoutSet = ({ CircuitWorkoutExerciseIndex, Distance, MaxSet, Reps, Seconds, TotalBreak: Rest, Weight, WorkoutExerciseIndex, WorkoutSetIndex }) => {
        var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

        var WorkoutExercises = [];

        if (!!ShowEditTrainingProgramWorkoutTemplate) WorkoutExercises = [ ...ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises ];
        else WorkoutExercises = [ ...this.state.Strength.Template.WorkoutExercises ];

        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutSetIndex] = { ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutSetIndex], ...Distance && { Distance }, MaxSet, ...Reps && { Reps }, Rest, ...Seconds && { Seconds }, ...Weight && { Weight } };
        
        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            var NewShowEditTrainingProgramWorkoutTemplateDetails = { ...ShowEditTrainingProgramWorkoutTemplateDetails };
            NewShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ ShowEditTrainingProgramWorkoutTemplateDetails: NewShowEditTrainingProgramWorkoutTemplateDetails });
        }
        else {
            var NewStrength = { ...this.state.Strength };
            NewStrength.Template.WorkoutExercises = WorkoutExercises;

            this.setState({ IsModified: 1, Strength: NewStrength });
        }
    }

    onUploadWorkoutImage = event => {
        if (event.target.files.length) {
            var SelectedImage = event.target.files[0];
            var FileExtension = getFileExtension(SelectedImage.name);

            var OptionalDetails = { ...this.state.OptionalDetails };

            if (validateFile({ FileExtension, FileType: 'Images' })) {
                if (SelectedImage.size <= this.state.MaxImageSize) {
                    var WorkoutImage = URL.createObjectURL(SelectedImage);

                    OptionalDetails.ActivityPicture = WorkoutImage;
                    OptionalDetails.ActivityPictureId = 'custom';
                    OptionalDetails.ImageTooLarge = false;
                    OptionalDetails.SelectedImage = SelectedImage;
                    OptionalDetails.WorkoutImage = WorkoutImage;
                    OptionalDetails.WrongImageType = false;

                    this.setState({ OptionalDetails });
                } else {
                    document.getElementById('WorkoutImage').value = null;
                    OptionalDetails.ImageTooLarge = true;
                    OptionalDetails.WrongImageType = false;

                    this.setState({ OptionalDetails });
                }
            } else {
                OptionalDetails.ImageTooLarge = false;
                OptionalDetails.WrongImageType = true;
                this.setState({ OptionalDetails });
            }
        }
    }

    renderActivityIntensity = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { HowToBuildSession, Template: { TemplateDetails } } = this.state.Activity;

        var IntensityButtons = this.state.AdditionalActivityDetails.Intensities.map(({ IntensityId: MappedIntensityId, IntensityName }) => ({ BackgroundColor: 'white', BackgroundColorHover: 'blue-abel-hover', BackgroundColorSelected: 'blue-abel', ColorHover: 'white', ColorSelected: 'white', FontFamily: 'semibold', OnClick: () => this.onSelectIntensity(MappedIntensityId), Selected: TemplateDetails.IntensityId === MappedIntensityId, Title: IntensityName }));

        return (
            <>
                {
                    HowToBuildSession === 'Template' &&
                    <>
                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={TemplateDetails.TemplateName} TextAlign="left" />

                        <Spacer Size="small" />
                    </>
                }

                <styles.SessionDetailHeaderTwoColumns className="SessionDetailHeaderTwoColumns">
                    <styles.IntensityContainer className="IntensityContainer">
                        <styles.StyledInputLabelMultiText
                            TextAlign="left"
                            Texts={[
                                { FontFamily: 'medium', FontSize: 'medium-2', Text: t('WorkoutPlan_Intensity') },
                                { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-3', Text: '*' },
                            ]}
                        />

                        <Spacer Size="extra-small" />

                        <ButtonGroup
                            Buttons={IntensityButtons}
                            ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                            NotTouching
                            OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                        />
                    </styles.IntensityContainer>

                    <styles.WorkoutNoteTextArea
                        OnBlur={this.onChangeTrainerNote}
                        NumRows={4}
                        Placeholder={t('WorkoutPlan_Trainer_note_to_client_placeholder')}
                        Value={TemplateDetails.TrainerNote}
                    />
                </styles.SessionDetailHeaderTwoColumns>
            </>
        );
    }

    renderAddExerciseModal = () => {
        var { ShowAddExerciselModal, ShowExerciseDetailModal } = this.state;

        if (ShowAddExerciselModal) {
            var ReturnDataPathname = '/add-workouts';
            var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

            var ExcludeExercises = [];

            if (!!ShowEditTrainingProgramWorkoutTemplate) ExcludeExercises = ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises.map(({ WorkoutExercises }) => WorkoutExercises.map(({ ExerciseId }) => +ExerciseId));
            else ExcludeExercises = this.state.Strength.Template.WorkoutExercises.map(({ WorkoutExercises }) => WorkoutExercises.map(({ ExerciseId }) => +ExerciseId));

            return (
                <AddExercise
                    DisplayNone={ShowExerciseDetailModal}
                    ExcludeExercises={ExcludeExercises}
                    OnAddExercises={SelectedExercises => this.onAddExercises(SelectedExercises)}
                    OnHide={() => this.onToggleShowAddExerciseModal(false)}
                    OnToggleShowExerciseDetailModal={(ShowExerciseDetailModal, Exercise) => this.onToggleShowExerciseDetailModal(ShowExerciseDetailModal, Exercise)}
                    ReturnData={{ pathname: ReturnDataPathname, state: { ...this.state, IsReturnData: true } }}
                    Show={ShowAddExerciselModal}
                />
            );
        }
    }

    renderAssigningTo = () => {
        var { t } = this.props;
        var { TryingGetAdditionalActivityDetails } = this.props;
        var { AdditionalActivityDetails, AssigningDetails: { Dates, Groups, UserIds, Users }, OptionalDetails: { ActivityId, ActivityName, ActivityPicture, ActivityPictures }, WorkoutType } = this.state;

        return (
            <>
                <styles.AssigningToThreeColumnsContainer className="AssigningToThreeColumnsContainer">
                    <styles.AssigningToColumnOne className="AssigningToColumnOne">
                        <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('assigned_to')} TextAlign="left" />
                            
                        <Spacer Size="extra-small" />

                        <AssignClients
                            EligibleTypes="Both"
                            GetStartedEligible="Workouts"
                            HideTitle
                            HorizontalScroll={false}
                            LeftAlign
                            OnSelectClients={Users => this.onSelectClients(Users)}
                            SelectedGroups={Groups}
                            SelectedUserIds={UserIds}
                            SelectedUsers={Users}
                            WhiteBackground
                        />
                    </styles.AssigningToColumnOne>
                    <styles.AssigningToColumnTwo className="AssigningToColumnTwo">
                        <styles.SelectedDatesContainer className="SelectedDatesContainer">
                            <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('choose_dates')} TextAlign="left" />
                            
                            <Spacer Size="extra-small" />
                            
                            <MiniCalendar
                                OnSelectDate={DayDate => this.onSelectDate(DayDate)}
                                SelectedDates={Dates}
                            />
                        </styles.SelectedDatesContainer>
                    </styles.AssigningToColumnTwo>

                    {
                        WorkoutType !== 'TrainingProgram' &&
                        <styles.AssigningToColumnThree className="AssigningToColumnThree">
                            <styles.InputLabelOptionalContainer className="InputLabelOptionalContainer">
                                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('WorkoutPlan_ChooseActivity')} TextAlign="left" />
                                
                                <HoverIcon NotAbsolute PopoverText={t('add_workout_choose_activity_description')} t={t}/>
                            </styles.InputLabelOptionalContainer>
                            
                            
                            <Spacer Size="extra-small" />

                            <Select
                                isLoading={TryingGetAdditionalActivityDetails}
                                onChange={this.onChangeActivity}
                                onFocus={this.onFocusAdditionalActivities}
                                options={AdditionalActivityDetails.AdditionalActivities}
                                placeholder={t('WorkoutPlan_ChooseActivity')}
                                styles={DropdownStyles}
                                value={ActivityId && { value: ActivityId, label: ActivityName }}
                            />

                            {
                                !!+ActivityId && <Spacer Size="medium" />
                            }

                            {
                                ActivityPictures.length > 0 &&
                                <styles.ActivityPicturesContainer>
                                    {
                                        ActivityPictures.map(Picture => {
                                            var Selected = ActivityPicture === Picture;

                                            return (
                                                <styles.ActivityPictureContainer key={Picture} onClick={() => this.onSelectActivityPicture({ ActivityPicture: Picture, ActivityPictureId: ActivityId })} Selected={Selected}>
                                                    <styles.StyledActivityImage
                                                        ContainerWidth="100%"
                                                        ImageSrc={Picture}
                                                        SelfLoading
                                                    />
                                                </styles.ActivityPictureContainer>
                                            );
                                        })
                                    }
                                </styles.ActivityPicturesContainer>
                            }

                            {/* {
                                !!+ActivityId &&
                                <>
                                    <Spacer Size="medium" />

                                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('or_upload_your_own')} TextAlign="center" />

                                    <Spacer Size="extra-small" />

                                    {this.renderUploadPicture()}
                                </>
                            } */}
                        </styles.AssigningToColumnThree>
                    }
                </styles.AssigningToThreeColumnsContainer>
            </>
        );
    }

    renderBody = () => {
        var { Endurance, ScreenNumber, Strength, WorkoutType } = this.state;

        if (ScreenNumber === 1) return this.renderWorkoutTypes();
        else if (ScreenNumber === 2 && WorkoutType === 'Strength') return this.renderWorkoutSource(WorkoutType);
        else if (ScreenNumber === 3 && WorkoutType === 'Strength') {
            if (Strength.HowToBuildSession === 'Template' || Strength.HowToBuildSession === 'Scratch') return this.renderWorkoutExercisesView();
        }
        else if (ScreenNumber === 4 && WorkoutType === 'Strength') return this.renderAssigningTo();
        else if (ScreenNumber === 5 && WorkoutType === 'Strength') return this.renderOptionalInfo();

        else if (ScreenNumber === 2 && WorkoutType === 'Endurance') return this.renderWorkoutSource(WorkoutType);
        else if (ScreenNumber === 3 && WorkoutType === 'Endurance') {
            if (Endurance.HowToBuildSession === 'Template' || Endurance.HowToBuildSession === 'Scratch') return this.renderWorkoutLapsView();
        }
        else if (ScreenNumber === 4 && WorkoutType === 'Endurance') return this.renderAssigningTo();
        else if (ScreenNumber === 5 && WorkoutType === 'Endurance') return this.renderOptionalInfo();

        else if (ScreenNumber === 2 && WorkoutType === 'Activity') return this.renderWorkoutSource(WorkoutType);
        else if (ScreenNumber === 3 && WorkoutType === 'Activity') return this.renderActivityIntensity();
        else if (ScreenNumber === 4 && WorkoutType === 'Activity') return this.renderAssigningTo();
        else if (ScreenNumber === 5 && WorkoutType === 'Activity') return this.renderOptionalInfo();

        else if (ScreenNumber === 2 && WorkoutType === 'TrainingProgram') return this.renderTrainingProgramView();
        else if (ScreenNumber === 3 && WorkoutType === 'TrainingProgram') return this.renderAssigningTo();

        return null;
    }

    renderBottomButtons = () => {
        var { t } = this.props;
        var { Activity, AssigningDetails, Endurance, OptionalDetails, ScreenNumber, Strength, TrainingProgram, WorkoutType } = this.state;

        var CanGoNext = 1;
        var LastScreen = 0;

        if (ScreenNumber === 1 && !WorkoutType) CanGoNext = 0;
        else if (ScreenNumber === 2) {
            if (WorkoutType === 'Strength' && !Strength.HowToBuildSession) CanGoNext = 0;
            else if (WorkoutType === 'Endurance' && !Endurance.HowToBuildSession) CanGoNext = 0;
            else if (WorkoutType === 'Activity' && !Activity.HowToBuildSession) CanGoNext = 0;
            else if (WorkoutType === 'TrainingProgram') {
                if (!TrainingProgram.Days.length) CanGoNext = 0;
                else if (!TrainingProgram.Days.reduce((prev, next) => prev + next.WorkoutTemplateIds.length, 0)) CanGoNext = 0;
            }
        }
        else if (ScreenNumber === 3) {
            if (WorkoutType === 'Strength' && !Strength.Template.WorkoutExercises.length) CanGoNext = 0;
            else if (WorkoutType === 'Endurance' && (!Endurance.Template.WorkoutLaps.Laps.length || !Endurance.Template.WorkoutLaps.ActiveType || !Endurance.Template.WorkoutLaps.CardioLevel || !Endurance.Template.WorkoutLaps.DataType)) CanGoNext = 0;
            else if (WorkoutType === 'Activity' && !Activity.Template.TemplateDetails.IntensityId) CanGoNext = 0;
            else if (WorkoutType === 'TrainingProgram') {
                if (!AssigningDetails.Dates.length || (!Object.entries(AssigningDetails.Groups).length && !AssigningDetails.UserIds.length)) CanGoNext = 0;
                LastScreen = 1;
            }
        }
        else if (ScreenNumber === 4) {
            if (WorkoutType === 'Strength' && (!AssigningDetails.Dates.length || (!Object.entries(AssigningDetails.Groups).length && !AssigningDetails.UserIds.length) || !OptionalDetails.ActivityId)) CanGoNext = 0;
            else if (WorkoutType === 'Endurance' && (!AssigningDetails.Dates.length || (!Object.entries(AssigningDetails.Groups).length && !AssigningDetails.UserIds.length) || !OptionalDetails.ActivityId)) CanGoNext = 0;
            else if (WorkoutType === 'Activity' && (!AssigningDetails.Dates.length || (!Object.entries(AssigningDetails.Groups).length && !AssigningDetails.UserIds.length) || !OptionalDetails.ActivityId)) CanGoNext = 0;
        }
        else if (ScreenNumber === 5) {
            if (WorkoutType === 'Strength') {
                if (!+OptionalDetails.Duration || !OptionalDetails.Name) CanGoNext = 0;
                LastScreen = 1;
            }
            else if (WorkoutType === 'Endurance') {
                if (!+OptionalDetails.Duration || !OptionalDetails.Name) CanGoNext = 0;
                LastScreen = 1;
            }
            else if (WorkoutType === 'Activity') {
                if (!+OptionalDetails.Duration || !OptionalDetails.Name) CanGoNext = 0;
                LastScreen = 1;
            }
        }

        return (
            <styles.BackNextButtons
                Buttons={[
                    { BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled: ScreenNumber === 1, FontFamily: 'semibold', OnClick: () => this.onChangeScreenNumber(-1), Title: t('back_with_first_char_uppercase') },
                    { BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled: !CanGoNext, FontFamily: 'semibold', OnClick: () => this.onChangeScreenNumber(1, CanGoNext, LastScreen), Title: t(LastScreen ? 'finish_with_first_char_uppercase' : 'next_with_first_char_uppercase') }
                ]}
            />
        );
    }

    renderChangeExerciseModal = () => {
        var { ShowChangeExerciselModal, ShowExerciseDetailModal } = this.state;

        if (ShowChangeExerciselModal) {
            var ReturnDataPathname = '/add-workouts';
            var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

            var ExcludeExercises = [];

            if (!!ShowEditTrainingProgramWorkoutTemplate) ExcludeExercises = ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises.map(({ WorkoutExercises }) => WorkoutExercises.map(({ ExerciseId }) => +ExerciseId));
            else ExcludeExercises = this.state.Strength.Template.WorkoutExercises.map(({ WorkoutExercises }) => WorkoutExercises.map(({ ExerciseId }) => +ExerciseId));

            return (
                <AddExercise
                    ChangeExercise
                    DisplayNone={ShowExerciseDetailModal}
                    ExcludeExercises={ExcludeExercises}
                    MaxExercises={1}
                    OnAddExercises={SelectedExercises => this.onChangeExercises(SelectedExercises)}
                    OnHide={() => this.onToggleShowChangeExerciseModal({ ShowChangeExerciselModal: false })}
                    OnToggleShowExerciseDetailModal={(ShowExerciseDetailModal, Exercise) => this.onToggleShowExerciseDetailModal(ShowExerciseDetailModal, Exercise)}
                    ReturnData={{ pathname: ReturnDataPathname, state: { ...this.state, IsReturnData: true } }}
                    Show={ShowChangeExerciselModal}
                />
            );
        }
    }

    renderCircuitSeparator = ({ CircuitType }) => {
        return <styles.CircuitSeparator BackgroundColor={CircuitType === 'Circuit' ? 'turquoise-dark' : 'blue-royal'} Collapsed />
    }

    renderExerciseDetailModal = () => {
        var { ShowExerciseDetailModal, ShowExerciseDetailPreview } = this.state;

        if (ShowExerciseDetailModal && Object.entries(ShowExerciseDetailPreview).length > 0) {
            return (
                <ExerciseDetail
                    ExerciseDetail_Preview={ShowExerciseDetailPreview}
                    OnHide={() => this.onToggleShowExerciseDetailModal(false)}
                    Show={ShowExerciseDetailModal}
                />
            );
        }
    }

    renderLapDetails = ({ Active, ActiveUnits, BreakTime, LapIndex, LapType, Rest, Speed, SpeedUnits, WorkoutLapId }) => {
        if (this.state.ShowEditTrainingProgramWorkoutTemplate) var { ActiveType, DataType } = this.state.ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutLaps;
        // eslint-disable-next-line
        else var { ActiveType, DataType } = this.state.Endurance.Template.WorkoutLaps;

        if (DataType === 'dynamic') SpeedUnits = '%';

        if (LapType === 'Active') {
            return (
                <ActiveLap
                    Active={Active}
                    ActiveUnits={ActiveUnits}
                    ActiveType={ActiveType}
                    DataType={DataType}
                    OnUpdateWorkoutLap={({ Active, Rest, Speed }) => this.onUpdateWorkoutLap({ Active, LapIndex, LapType, Rest, Speed })}
                    Rest={Rest}
                    SetType="Template"
                    Speed={Speed}
                    SpeedUnits={SpeedUnits}
                    WorkoutLapId={WorkoutLapId}
                />
            )
        } else if (LapType === 'Break') {
            return (
                <BreakLap
                    BreakTime={BreakTime}
                    OnUpdateWorkoutLap={({ BreakTime }) => this.onUpdateWorkoutLap({ BreakTime, LapIndex, LapType })}
                    WorkoutLapId={WorkoutLapId}
                />
            )
        }
    }

    renderLapHeader = (LapIndex, LapNumber, LapType, WorkoutLapId) => {
        var { t } = this.props;
        var { Device } = this.props;

        return (
            <styles.LapHeaderContainer className="LapHeaderContainer" ActiveLap={LapType === 'Active'}>
                <styles.LapHeaderContainerLeft>
                    {LapType === 'Active' && <PageText FontColor="white" FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${t('lap_str_all_capital').toUpperCase()} ${LapNumber}`} TextAlign="right" />}
                    {LapType === 'Break' && <PageText FontColor="white" FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('break').toUpperCase()} TextAlign="right" />}
                </styles.LapHeaderContainerLeft>

                {
                    Device !== 'laptop' &&
                    <styles.LapHeaderContainerRight>
                        <styles.DeleteSetIcon
                            Alt={`Delete lap ${WorkoutLapId}`}
                            ImageSrc={DeleteX}
                            Loading={false}
                            OnClick={() => this.onDeleteLap(LapIndex)}
                        />
                    </styles.LapHeaderContainerRight>
                }
            </styles.LapHeaderContainer>
        );
    }

    renderOptionalInfo = () => {
        var { t } = this.props;
        var { AttachSurveys, EditOriginal, HourType: StateHourType, IsModified, OptionalDetails: { Duration, Name, Location, StartTime }, ShowDateTimes, SaveAsNew, WorkoutType } = this.state;

        var SaveOptions = [];
        if (this.state[WorkoutType].HowToBuildSession === 'Template' && !!this.state[WorkoutType].Template.TemplateDetails.IsOwner) SaveOptions.push({ OnClickFunc: this.onToggleEditOriginal, Selected: !!EditOriginal, Title: `...${t('save_changes_to_edit_original_template')}` });
        SaveOptions = [ ...SaveOptions, { OnClickFunc: this.onToggleShowSaveAsNew, Selected: !!SaveAsNew, Title: `...${t('save_changes_to_save_as_new_template')}` }];

        var SaveChangesTitle = null;
        if (!!IsModified) {
            if (this.state[WorkoutType].HowToBuildSession === 'Template') SaveChangesTitle = 'add_workout_save_changes_to_this_session'
            else if (this.state[WorkoutType].HowToBuildSession === 'Scratch') SaveChangesTitle = 'add_workout_save_this_session';
        }

        return (
            <>
                <styles.OptionalInfoContainer className="OptionalInfoContainer">
                    <styles.OptionalInfoLeftContainer className="OptionalInfoLeftContainer">
                        <styles.InputParentContainer className="InputParentContainer">
                            <styles.InputLabelContainer className="InputLabelContainer">
                                <MultiText TextAlign="left"
                                    Texts={[
                                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('custom_name') },
                                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-3', Text: '*' }
                                    ]}
                                />

                                <styles.InputLabelOptionalContainer className="InputLabelOptionalContainer">                                    
                                    <HoverIcon NotAbsolute PopoverText={t('custom_name_info')} t={t}/>
                                </styles.InputLabelOptionalContainer>
                            </styles.InputLabelContainer>

                            <Spacer Size="extra-extra-small" />

                            <styles.OptionalInfoInput
                                NoLabel
                                OnChange={event => this.onChangeOptionalInfo({ event, InfoType: 'Name' })}
                                Placeholder={t('custom_name')}
                                Type="text"
                                Value={Name}
                            />
                        </styles.InputParentContainer>

                        <styles.InputParentContainer className="InputParentContainer">
                            <styles.InputLabelContainer className="InputLabelContainer">
                                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('custom_location')} TextAlign="left" />

                                <styles.InputLabelOptionalContainer className="InputLabelOptionalContainer">
                                    <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('optional')} TextAlign="left" />
                                    
                                    <HoverIcon NotAbsolute PopoverText={t('custom_location_info')} t={t}/>
                                </styles.InputLabelOptionalContainer>
                            </styles.InputLabelContainer>

                            <Spacer Size="extra-extra-small" />

                            <styles.OptionalInfoInput
                                NoLabel
                                OnChange={event => this.onChangeOptionalInfo({ event, InfoType: 'Location' })}
                                Placeholder={t('custom_location')}
                                Type="text"
                                Value={Location}
                            />
                        </styles.InputParentContainer>

                        <styles.InputParentContainer className="InputParentContainer">
                            <styles.InputLabelContainer className="InputLabelContainer">
                                <MultiText TextAlign="left"
                                    Texts={[
                                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('WorkoutPlan_duration_on_minutes') },
                                        { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-3', Text: '*' }
                                    ]}
                                />

                                <styles.InputLabelOptionalContainer className="InputLabelOptionalContainer">                                    
                                    <HoverIcon NotAbsolute PopoverText={t('custom_duration_info')} t={t}/>
                                </styles.InputLabelOptionalContainer>
                            </styles.InputLabelContainer>

                            <Spacer Size="extra-extra-small" />

                            <styles.OptionalInfoInput
                                NoLabel
                                OnChange={event => this.onChangeOptionalInfo({ event, InfoType: 'Duration' })}
                                Placeholder={'0'}
                                Type="number"
                                Value={Duration}
                            />
                        </styles.InputParentContainer>

                        <styles.InputParentContainer className="InputParentContainer">
                            <styles.InputLabelContainer className="InputLabelContainer">
                                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('activity_start_time_scheduled')} TextAlign="left" />

                                <styles.InputLabelOptionalContainer className="InputLabelOptionalContainer">
                                    <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('optional')} TextAlign="left" />
                                    
                                    <HoverIcon NotAbsolute PopoverText={t('activity_start_time_info')} t={t}/>
                                </styles.InputLabelOptionalContainer>
                            </styles.InputLabelContainer>

                            <Spacer Size="extra-extra-small" />

                            <styles.DateTimeUseAllDaysContainer>
                                <styles.DateTimeUseAllDays onClick={this.onToggleShowDateTimes} Selected={ShowDateTimes} />
                                
                                <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={this.onToggleShowDateTimes} Text={t('additional_activity_choosetimes')} TextAlign="left" />
                            </styles.DateTimeUseAllDaysContainer>

                            {
                                !!+ShowDateTimes &&
                                <styles.DateTimeParentContainer className="DateTimeParentContainer">
                                    <styles.HourTypesContainer>
                                        <styles.HourType onClick={() => this.onChangeHourType(12)} Selected={StateHourType === 12}>
                                            <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('hour_type', { HourType: '12' })} />
                                        </styles.HourType>
                                        <styles.HourType onClick={() => this.onChangeHourType(24)} Selected={StateHourType === 24}>
                                            <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('hour_type', { HourType: '24' })} />
                                        </styles.HourType>
                                    </styles.HourTypesContainer>
    
                                    <styles.DateTimesContainer className="DateTimesContainer">
                                        <styles.DateTimeContainer className="DateTimeContainer">
                                            <TimePicker
                                                defaultValue={moment(StartTime)}
                                                minuteStep={5}
                                                onChange={DateTime => this.onChangeDateTime(DateTime)}
                                                showSecond={false}
                                                use12Hours={StateHourType === 12}
                                                value={moment(StartTime)}
                                            />
                                        </styles.DateTimeContainer>
                                    </styles.DateTimesContainer>
                                </styles.DateTimeParentContainer>
                            }
                        </styles.InputParentContainer>
                    </styles.OptionalInfoLeftContainer>
                    <styles.OptionalInfoRightContainer className="OptionalInfoRightContainer">

                    </styles.OptionalInfoRightContainer>
                </styles.OptionalInfoContainer>

                {
                    <>
                        <Spacer Size="small" />

                        <styles.AttachSurveysContainer className="AttachSurveysContainer">
                            <styles.InputLabelContainer className="InputLabelContainer">
                                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('_mini_surveys_attach_to_workout')} TextAlign="left" />

                                <styles.InputLabelOptionalContainer className="InputLabelOptionalContainer" MarginLeft>
                                    <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('optional')} TextAlign="left" />
                                </styles.InputLabelOptionalContainer>
                            </styles.InputLabelContainer>

                            <styles.AttachSurveysOptionContainer className="AttachSurveysOptionContainer">
                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('_mini_survey_types_sleep')}:`} />

                                <Toggle
                                    Checked={!!AttachSurveys.Sleep}
                                    OffLabel={t('off')}
                                    OnChange={event => this.onChangeAttachSurveyToggle({ event, ToggleType: 'Sleep' })}
                                    OnLabel={t('on')}
                                />
                            </styles.AttachSurveysOptionContainer>
                            <styles.AttachSurveysOptionContainer className="AttachSurveysOptionContainer">
                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('_mini_survey_types_pain')}:`} />

                                <Toggle
                                    Checked={!!AttachSurveys.Pain}
                                    OffLabel={t('off')}
                                    OnChange={event => this.onChangeAttachSurveyToggle({ event, ToggleType: 'Pain' })}
                                    OnLabel={t('on')}
                                />
                            </styles.AttachSurveysOptionContainer>
                            <styles.AttachSurveysOptionContainer className="AttachSurveysOptionContainer">
                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('_mini_survey_types_rpe')}:`} />

                                <Toggle
                                    Checked={!!AttachSurveys.RPE}
                                    OffLabel={t('off')}
                                    OnChange={event => this.onChangeAttachSurveyToggle({ event, ToggleType: 'RPE' })}
                                    OnLabel={t('on')}
                                />
                            </styles.AttachSurveysOptionContainer>
                        </styles.AttachSurveysContainer>
                    </>
                }

                {
                    !!IsModified &&
                    <>
                        <Spacer Size="medium" />

                        <styles.SaveChangesContainer className="SaveChangesContainer">
                            <styles.InputLabelContainer className="InputLabelContainer">
                                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={`${t(SaveChangesTitle)}...`} TextAlign="left" />

                                <styles.InputLabelOptionalContainer className="InputLabelOptionalContainer" MarginLeft>
                                    <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('optional')} TextAlign="left" />
                                    
                                    
                                </styles.InputLabelOptionalContainer>
                            </styles.InputLabelContainer>

                            <Spacer Size="small" />

                            <styles.SaveOptionsContainer className="SaveOptionsContainer">
                                {
                                    SaveOptions.map((SaveOption, index) => {
                                        var { OnClickFunc, Selected, Title } = SaveOption;

                                        return (
                                            <styles.DateTimeUseAllDaysContainer key={index}>
                                                <styles.DateTimeUseAllDays onClick={OnClickFunc} Selected={!!Selected} />
                                                
                                                <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={OnClickFunc} Text={t(Title)} TextAlign="left" />
                                            </styles.DateTimeUseAllDaysContainer>
                                        );
                                    })
                                }
                            </styles.SaveOptionsContainer>
                        </styles.SaveChangesContainer>
                    </>
                }
            </>
        );
    }

    renderReorderExercises = () => {
        var { ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails } = this.state;

        var WorkoutExercises = [];

        if (!!ShowEditTrainingProgramWorkoutTemplate) WorkoutExercises = [ ...ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises ];
        else WorkoutExercises = [ ...this.state.Strength.Template.WorkoutExercises ];

        return <styles.StyledWorkoutCircuits AllWhite Circuits={WorkoutExercises} FromAddWorkouts OnCancelCircuits={this.onCancelCircuits} OnSaveCircuits={this.onSaveCircuits} />;
    }

    renderShowAddWorkoutTemplateToProgramModal = () => {
        var { ShowAddWorkoutTemplateToProgramModal_DisplayNone, ShowAddWorkoutTemplateToProgramModal } = this.state;

        if (ShowAddWorkoutTemplateToProgramModal) {
            return (
                <Info
                    DisplayNone={ShowAddWorkoutTemplateToProgramModal_DisplayNone}
                    InformationRenderer={this.renderShowAddWorkoutTemplateToProgramModal_Information}
                    NoMargin
                    OnHide={() => this.onToggleShowAddWorkoutTemplateToProgramModal({ ShowAddWorkoutTemplateToProgramModal: false })}
                    Show={ShowAddWorkoutTemplateToProgramModal}
                    Size="full"
                />
            );
        }
    }

    renderShowAddWorkoutTemplateToProgramModal_Information = () => {
        return (
            <styles.Container>
                <WorkoutTemplates
                    IsModal
                    OnSelectTemplate={Template => this.onAddTemplateToWorkoutProgram({ Template })}
                    OnShowTemplateDetail={this.onToggleShowAddWorkoutTemplateToProgramModal_DisplayNone}
                    FromAddWorkouts
                    WorkoutTypes={[]}
                />
            </styles.Container>
        );
    }

    renderShowHowToBuildResetWarningModal = () => {
        var { ShowHowToBuildResetWarningModal } = this.state;

        if (ShowHowToBuildResetWarningModal) {
            var { t } = this.props;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={() => this.onResetStrengthDetails()}
                    BottomButtonText={t('reset')}
                    Information={t('addworkout_flow_reset_strength_details')}
                    OnHide={() => this.onToggleShowHowToBuildResetWarningModal(false)}
                    Show={ShowHowToBuildResetWarningModal}
                />
            );
        }
    }

    renderShowRemoveWorkoutTemplateWarningModal = () => {
        var { ShowRemoveWorkoutTemplateWarningModal } = this.state;

        if (ShowRemoveWorkoutTemplateWarningModal) {
            var { t } = this.props;
            var { ShowRemoveWorkoutTemplateWarningModal_TemplateId } = this.state;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={() => this.onRemoveWorkoutTemplate({ FromUniqueTemplates: true, TemplateId: ShowRemoveWorkoutTemplateWarningModal_TemplateId })}
                    BottomButtonText={t('remove')}
                    Information={t('training_program_remove_unique_template_warning')}
                    OnHide={() => this.onToggleShowRemoveWorkoutTemplateWarningModal({ ShowRemoveWorkoutTemplateWarningModal: false })}
                    Show={ShowRemoveWorkoutTemplateWarningModal}
                />
            );
        }
    }

    renderShowTemplateLibraryModal_Activity = () => {
        var { ShowTemplateLibraryModal_Activity_DisplayNone, ShowTemplateLibraryModal_Activity } = this.state;

        if (ShowTemplateLibraryModal_Activity) {
            return (
                <Info
                    DisplayNone={ShowTemplateLibraryModal_Activity_DisplayNone}
                    InformationRenderer={this.renderShowTemplateLibraryModal_Activity_Information}
                    NoMargin
                    OnHide={() => this.onToggleShowTemplateLibraryModal({ ShowTemplateLibraryModal: false, TemplateType: 'Activity' })}
                    Show={ShowTemplateLibraryModal_Activity}
                    Size="full"
                />
            );
        }
    }

    renderShowTemplateLibraryModal_Activity_Information = () => {
        return (
            <styles.Container>
                <WorkoutTemplates
                    IsModal
                    OnSelectTemplate={Template => this.onSelectTemplate({ Template, WorkoutType: 'Activity' })}
                    OnShowTemplateDetail={this.onToggleShowTemplateLibraryModal_Activity_DisplayNone}
                    FromAddWorkouts
                    WorkoutTypes={[ 3 ]}
                />
            </styles.Container>
        );
    }

    renderShowTemplateLibraryModal_Endurance = () => {
        var { ShowTemplateLibraryModal_Endurance_DisplayNone, ShowTemplateLibraryModal_Endurance } = this.state;

        if (ShowTemplateLibraryModal_Endurance) {
            return (
                <Info
                    DisplayNone={ShowTemplateLibraryModal_Endurance_DisplayNone}
                    InformationRenderer={this.renderShowTemplateLibraryModal_Endurance_Information}
                    NoMargin
                    OnHide={() => this.onToggleShowTemplateLibraryModal({ ShowTemplateLibraryModal: false, TemplateType: 'Endurance' })}
                    Show={ShowTemplateLibraryModal_Endurance}
                    Size="full"
                />
            );
        }
    }

    renderShowTemplateLibraryModal_Endurance_Information = () => {
        return (
            <styles.Container>
                <WorkoutTemplates
                    IsModal
                    OnSelectTemplate={Template => this.onSelectTemplate({ Template, WorkoutType: 'Endurance' })}
                    OnShowTemplateDetail={this.onToggleShowTemplateLibraryModal_Endurance_DisplayNone}
                    FromAddWorkouts
                    WorkoutTypes={[ 2 ]}
                />
            </styles.Container>
        );
    }

    renderShowTemplateLibraryModal_Strength = () => {
        var { ShowTemplateLibraryModal_Strength_DisplayNone, ShowTemplateLibraryModal_Strength } = this.state;

        if (ShowTemplateLibraryModal_Strength) {
            return (
                <Info
                    DisplayNone={ShowTemplateLibraryModal_Strength_DisplayNone}
                    InformationRenderer={this.renderShowTemplateLibraryModal_Strength_Information}
                    NoMargin
                    OnHide={() => this.onToggleShowTemplateLibraryModal({ ShowTemplateLibraryModal: false, TemplateType: 'Strength' })}
                    Show={ShowTemplateLibraryModal_Strength}
                    Size="full"
                />
            );
        }
    }

    renderShowTemplateLibraryModal_Strength_Information = () => {
        return (
            <styles.Container>
                <WorkoutTemplates
                    IsModal
                    OnSelectTemplate={Template => this.onSelectTemplate({ Template, WorkoutType: 'Strength' })}
                    OnShowTemplateDetail={this.onToggleShowTemplateLibraryModal_Strength_DisplayNone}
                    FromAddWorkouts
                    WorkoutTypes={[ 1 ]}
                />
            </styles.Container>
        );
    }

    renderShowTrainingProgramLibraryModal = () => {
        var { ShowTrainingProgramLibraryModal_DisplayNone, ShowTrainingProgramLibraryModal } = this.state;

        if (ShowTrainingProgramLibraryModal) {
            return (
                <Info
                    DisplayNone={ShowTrainingProgramLibraryModal_DisplayNone}
                    InformationRenderer={this.renderShowTrainingProgramLibraryModal_Information}
                    NoMargin
                    OnHide={() => this.onToggleShowTrainingProgramLibraryModal(false)}
                    Show={ShowTrainingProgramLibraryModal}
                    Size="full"
                />
            );
        }
    }

    renderShowTrainingProgramLibraryModal_Information = () => {
        return (
            <styles.Container>
                <TrainingPrograms
                    FromAddWorkouts
                    IsModal
                    OnSelectTrainingProgram={this.onSelectTrainingProgram}
                    OnShowTemplateDetail={this.onToggleShowTrainingProgramLibraryModal_DisplayNone}
                />
            </styles.Container>
        );
    }

    renderTrainingProgramTemplates = () => {
        var { t } = this.props;
        var { IsDragging, TrainingProgram, TrainingProgram: { TemplateDays } } = this.state;

        return (
            <styles.TrainingProgramDaysContainer className="TrainingProgramDaysContainer">
                {TrainingProgram.Days.map(({ DayId, WorkoutTemplateIds, WorkoutTemplates }, index) => {
                    return (
                        <DayColumn
                            key={DayId}
                            DayId={DayId}
                            DayIndex={index}
                            WorkoutTemplateIds={WorkoutTemplateIds}
                            WorkoutTemplates={WorkoutTemplates}
                            DayNumber={index + 1}
                            IsDragging={IsDragging}
                            onChangeCustomSettingDisplay={this.onChangeCustomSettingDisplay}
                            onChangeCustomSetting={this.onChangeCustomSetting}
                            onClickShowEditWorkoutTemplate={this.onClickShowEditWorkoutTemplate}
                            onMoveWorkoutTemplate={this.onMoveWorkoutTemplate}
                            onRemoveTrainingProgramDay={() => this.onRemoveTrainingProgramDay({ DayIndex: index })}
                            onRemoveWorkoutTemplate={this.onRemoveWorkoutTemplate}
                            onToggleShowAddWorkoutTemplateToProgramModal={() => this.onToggleShowAddWorkoutTemplateToProgramModal({ ShowAddWorkoutTemplateToProgramModal_DayIndex: index + 1, ShowAddWorkoutTemplateToProgramModal: true })}
                            ModifiedUniqueTemplateIds={TrainingProgram.ModifiedUniqueTemplateIds}
                            t={t}
                            TemplateDays={TemplateDays}
                            ViewTemplate={Template => this.onToggleShowTemplateDetailModal(true, Template)}
                        />
                    )
                })}

                <styles.TrainingProgramAddDayContainer className="TrainingProgramAddDayContainer" onClick={this.onAddTrainingProgramDay}>
                    <styles.TrainingProgramAddDayIcon className="TrainingProgramAddDayIcon" ImageSrc={PlusButton_Active} />
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('training_program_add_day')} TextAlign="center" />
                </styles.TrainingProgramAddDayContainer>
            </styles.TrainingProgramDaysContainer>
        );
    }

    renderTrainingProgramView = () => {
        var { t } = this.props;
        var { IsModified, ShowEditTrainingProgramWorkoutTemplate, ShowEditTrainingProgramWorkoutTemplateDetails, TrainingProgram } = this.state;

        if (!!ShowEditTrainingProgramWorkoutTemplate) {
            if (ShowEditTrainingProgramWorkoutTemplateDetails.Template.TemplateDetails.WorkoutType === 1) {
                var Buttons = [
                    { BackgroundColor: 'orange', BackgroundColorHover: 'orange-hover', Border: 'none', Color: 'white', ColorHover: 'white', Icon: PlusSign_White, IconPosition: 'left', OnClick: () => this.onToggleShowAddExerciseModal(true), Title: t('WorkoutPlan_add_new_exercise') },
                    { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', ColorHover: 'black', Disabled: !ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises.length, Icon: Reorder, IconPosition: 'left', OnClick: () => this.onToggleShowReorderExercises(!this.state.Strength.ShowReorderExercises), Title: t('WorkoutPlan_reorder_exercises') }
                ];
            }

            return (
                <>
                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={ShowEditTrainingProgramWorkoutTemplateDetails.Template.TemplateDetails.TemplateName} TextAlign="left" />  

                    <Spacer Size="small" />

                    {
                        ShowEditTrainingProgramWorkoutTemplateDetails.Template.TemplateDetails.WorkoutType === 1 &&
                        <>
                            <ButtonGroup Buttons={Buttons} ContainerWidth="350px" NotTouching OwnRows />

                            <Spacer Size="small" />

                            {this.renderWorkoutExercises(ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutExercises)}
                        </>
                    }
                    {ShowEditTrainingProgramWorkoutTemplateDetails.Template.TemplateDetails.WorkoutType === 2 && this.renderWorkoutLaps(ShowEditTrainingProgramWorkoutTemplateDetails.Template.WorkoutLaps)}

                    <Spacer Size="small" />

                    <ButtonGroup
                        Buttons={[
                            { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Color: 'white', ColorHover: 'white', OnClick: this.onSaveEditTrainingProgramTemplate, Title: t('save_with_first_char_uppercase') },
                            { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Color: 'white', ColorHover: 'white', OnClick: this.onCancelEditTrainingProgramTemplate, Title: t('Cancel_with_first_char_uppercase') }
                        ]}
                        NotTouching
                    />
                </>
            );
        }

        return (
            <>
                <styles.TrainingProgramTitleContainer className="TrainingProgramTitleContainer">
                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={TrainingProgram.ProgramName} TextAlign="left" />

                    {
                        !!IsModified &&
                        <>
                            <ButtonGroup Buttons={[{ BackgroundColorHover: 'white-concrete-hover', OnClick: this.onResetTrainingProgram, Title: `${t('reset_to_original')}?` }]} />
                            {/* <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={this.onResetTrainingProgram} Text={`${t('reset_to_original')}?`} TextAlign="left" /> */}
                        </>
                    }
                </styles.TrainingProgramTitleContainer>

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={TrainingProgram.ProgramDescription} TextAlign="left" />

                <Spacer Size="small" />

                <DragDropContext
                    // onBeforeCapture={this.onBeforeCapture}
                    onDragEnd={this.onDragEnd}
                >
                    {this.renderUniqueSessionsInTrainingProgram()}

                    <Spacer Size="medium" />

                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('training_program')} TextAlign="left" />

                    <Spacer Size="small" />

                    {this.renderTrainingProgramTemplates()}
                </DragDropContext>
            </>
        );
    }

    renderUniqueSessionsInTrainingProgram = () => {
        var { t } = this.props;
        var { TrainingProgram: { ModifiedUniqueTemplateIds, TemplateDays, WorkoutTemplates } } = this.state;

        return (
            <Droppable droppableId="SelectedWorkoutTemplates" isDropDisabled>
                {provided => (
                    <div
                        className="template-dish-selections-container margintop50 border2px"
                        ref={provided.innerRef}
                        // {...provided.droppableProps}
                    >
                        <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('training_program_unique_sessions_used')} TextAlign="left" />

                        <Spacer Size="extra-extra-small" />

                        <MultiText
                            Texts={[
                                { FontFamily: 'medium', FontSize: 'small', Text: `- ${t('training_program_unique_sessions_used_editing_warning_part_1')}` },
                                { FontFamily: 'bold', FontSize: 'small', Text: t('training_program_unique_sessions_used_editing_warning_orange_border') }
                            ]}
                        />

                        <MultiText
                            Texts={[
                                { FontFamily: 'medium', FontSize: 'small', Text: `- ${t('training_program_unique_sessions_used_editing_warning_part_2')}` },
                                { FontFamily: 'bold', FontSize: 'small', Text: t('training_program_unique_sessions_used_editing_warning_green_border') }
                            ]}
                        />

                        <styles.UniqueWorkoutTemplatesContainer className="UniqueWorkoutTemplatesContainer">
                            {Object.keys(WorkoutTemplates).map((key, index) => {
                                return (
                                    <WorkoutTemplate
                                        key={index}
                                        t={t}
                                        IsUnique
                                        ModifiedUniqueTemplateIds={ModifiedUniqueTemplateIds}
                                        ShowCalendar
                                        TemplateDays={TemplateDays}
                                        onMoveWorkoutTemplate={this.onMoveWorkoutTemplate}
                                        WorkoutTemplate={WorkoutTemplates[key]}
                                        WorkoutTemplateIndex={index}
                                        onChangeCustomSettingDisplay={() => this.onChangeCustomSettingDisplay_SelectedTemplates(WorkoutTemplates[key].TemplateId)}
                                        onChangeCustomSetting={({ SettingName, Value }) => this.onChangeCustomSetting_SelectedTemplates({ SettingName, TemplateId: WorkoutTemplates[key].TemplateId, Value })}
                                        onClickShowEditWorkoutTemplate={() => this.onClickShowEditWorkoutTemplate({ FromUniqueTemplates: true, TemplateId: WorkoutTemplates[key].TemplateId })}
                                        onRemoveWorkoutTemplate={() => this.onToggleShowRemoveWorkoutTemplateWarningModal({ ShowRemoveWorkoutTemplateWarningModal_TemplateId: WorkoutTemplates[key].TemplateId, ShowRemoveWorkoutTemplateWarningModal: true })}
                                        ViewTemplate={Template => this.onToggleShowTemplateDetailModal(true, Template)}
                                    />
                                );
                            })}
                        </styles.UniqueWorkoutTemplatesContainer>

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );
    }

    renderUploadPicture = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { ActivityPictureId, ImageTooLarge, WorkoutImage, WrongImageType } = this.state.OptionalDetails;

        var Buttons = [{ BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: Camera, IconPosition: 'left', IsInput: true, InputProps: { accept: "image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg", id: "WorkoutImage", type: "file", onChange: this.onUploadWorkoutImage }, Title: t('Upload') }];

        return (
            <styles.UploadContainer>
                <styles.UploadButtonsRow>
                    <styles.UploadButtonsContainer
                        Buttons={Buttons}
                        ButtonWidth="fit-content"
                        ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                        NotTouching
                        OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                    />
                </styles.UploadButtonsRow>

                {
                    WorkoutImage && WorkoutImage.indexOf('/additionalactivities/') === -1 &&
                    <styles.ActivityPicturesContainer>
                        <styles.ActivityPictureContainer onClick={() => this.onSelectActivityPicture({ ActivityPicture: WorkoutImage, ActivityPictureId: 'custom' })} Selected={ActivityPictureId === 'custom'}>
                            <styles.StyledActivityImage
                                ContainerWidth="100%"
                                ImageSrc={WorkoutImage}
                                SelfLoading
                            />
                        </styles.ActivityPictureContainer>
                    </styles.ActivityPicturesContainer>
                }
                                
                {
                    (ImageTooLarge || WrongImageType) &&
                    <>
                        <Spacer Size="small" />

                        <PageText
                            FontColor="red-bittersweet"
                            FontFamily="semibold-italic"
                            FontSize="medium-1"
                            Text={t(ImageTooLarge ? 'media_wrongsize' : 'media_wrongtype', { AllowedTypes: '.jpg, .png, .jpeg', MaxSize: '10MB', Type: 'Image' })}
                            TextAlign="center"
                        />
                    </>
                }
            </styles.UploadContainer>
        );
    }

    renderWorkoutExercises = WorkoutExercises => {
        if (this.state.Strength.ShowReorderExercises) return this.renderReorderExercises();

        // eslint-disable-next-line
        if (!WorkoutExercises) var { WorkoutExercises } = this.state.Strength.Template;

        return (
            <styles.WorkoutExercisesContainer className="WorkoutExercisesContainer">
                {
                    WorkoutExercises.map((WorkoutExercise, WorkoutExerciseIndex) => {
                        var CircuitType = WorkoutExercise.NumExercises >= 3 ? 'Circuit' : WorkoutExercise.NumExercises === 2 ? 'Superset' : 'WorkoutExercise';

                        return (
                            <React.Fragment key={WorkoutExercise.WorkoutExercises[0].ExerciseId}>
                                {WorkoutExerciseIndex !== 0 && <Spacer Size="small" />}

                                <styles.CircuitContainer CircuitType={CircuitType}>
                                    {
                                        WorkoutExercise.WorkoutExercises.map((CircuitWorkoutExercise, CircuitWorkoutExerciseIndex) => {
                                            var { Collapsed } = CircuitWorkoutExercise;

                                            return (
                                                <React.Fragment key={CircuitWorkoutExercise.ExerciseId}>
                                                    {CircuitWorkoutExerciseIndex !== 0 && this.renderCircuitSeparator({ Collapsed, CircuitType })}
                
                                                    {
                                                        Collapsed ?
                                                        <CollapsedExerciseComponent
                                                            CircuitType={CircuitType}
                                                            Exercise={CircuitWorkoutExercise}
                                                            OnDeleteExercise={() => this.onDeleteExercise({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex })}
                                                            OnToggleCollapsed={() => this.onToggleCollapsedExercise({ CircuitWorkoutExerciseIndex, Collapsed: false, WorkoutExerciseIndex })}
                                                            OnToggleShowChangeExerciseModal={() => this.onToggleShowChangeExerciseModal({ CircuitWorkoutExerciseIndex, ShowChangeExerciselModal: true, WorkoutExerciseIndex })}
                                                            OnToggleShowExerciseDetailModal={(ShowExerciseDetailModal, Exercise) => this.onToggleShowExerciseDetailModal(ShowExerciseDetailModal, Exercise)}
                                                        />
                                                    :
                                                        <TemplateExerciseComponent
                                                            CircuitType={CircuitType}
                                                            Exercise={CircuitWorkoutExercise}
                                                            FromAddWorkouts
                                                            OnAddSet={() => this.onAddSet({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex })}
                                                            OnChangeDataType={DataType => this.onChangeDataType({ CircuitWorkoutExerciseIndex, DataType, WorkoutExerciseIndex })}
                                                            OnChangeExerciseNote={Note => this.onChangeExerciseNote({ CircuitWorkoutExerciseIndex, Note, WorkoutExerciseIndex })}
                                                            OnChangeMaxType={MaxType => this.onChangeMaxType({ CircuitWorkoutExerciseIndex, MaxType, WorkoutExerciseIndex })}
                                                            OnDeleteExercise={() => this.onDeleteExercise({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex })}
                                                            OnDeleteSet={WorkoutSetIndex => this.onDeleteSet({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex, WorkoutSetIndex })}
                                                            OnToggleCollapsed={() => this.onToggleCollapsedExercise({ CircuitWorkoutExerciseIndex, Collapsed: true, WorkoutExerciseIndex })}
                                                            OnToggleMaxSet={(MaxSet, WorkoutSetIndex) => this.onToggleMaxSet({ CircuitWorkoutExerciseIndex, MaxSet, WorkoutExerciseIndex, WorkoutSetIndex })}
                                                            OnToggleShowChangeExerciseModal={() => this.onToggleShowChangeExerciseModal({ CircuitWorkoutExerciseIndex, ShowChangeExerciselModal: true, WorkoutExerciseIndex })}
                                                            OnToggleShowExerciseDetailModal={(ShowExerciseDetailModal, Exercise) => this.onToggleShowExerciseDetailModal(ShowExerciseDetailModal, Exercise)}
                                                            OnUpdateWorkoutSet={({ Distance, MaxSet, Reps, Seconds, TotalBreak, Weight, WorkoutSetIndex }) => this.onUpdateWorkoutSet({ CircuitWorkoutExerciseIndex, Distance, MaxSet, Reps, Seconds, TotalBreak, Weight, WorkoutExerciseIndex, WorkoutSetIndex })}
                                                        />
                                                    }
                                                </React.Fragment>
                                            );
                                        })
                                    }    
                                </styles.CircuitContainer>
                            </React.Fragment>
                        );
                    })
                }
            </styles.WorkoutExercisesContainer>
        );
    }

    renderWorkoutExercisesView = () => {
        var { t } = this.props;
        var { HowToBuildSession, Template: { TemplateDetails, WorkoutExercises } } = this.state.Strength;

        var Buttons = [
            { BackgroundColor: 'orange', BackgroundColorHover: 'orange-hover', Border: 'none', Color: 'white', ColorHover: 'white', Icon: PlusSign_White, IconPosition: 'left', OnClick: () => this.onToggleShowAddExerciseModal(true), Title: t('WorkoutPlan_add_new_exercise') },
            { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', ColorHover: 'black', Disabled: !WorkoutExercises.length, Icon: Reorder, IconPosition: 'left', OnClick: () => this.onToggleShowReorderExercises(!this.state.Strength.ShowReorderExercises), Title: t('WorkoutPlan_reorder_exercises') }
        ];

        return (
            <>
                {
                    HowToBuildSession === 'Template' &&
                    <>
                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={TemplateDetails.TemplateName} TextAlign="left" />

                        <Spacer Size="small" />
                    </>
                }

                <styles.SessionDetailHeaderTwoColumns className="SessionDetailHeaderTwoColumns">
                    <ButtonGroup Buttons={Buttons} NotTouching OwnRows />

                    <styles.WorkoutNoteTextArea
                        OnBlur={this.onChangeTrainerNote}
                        NumRows={4}
                        Placeholder={t('WorkoutPlan_Trainer_note_to_client_placeholder')}
                        Value={TemplateDetails.TrainerNote}
                    />
                </styles.SessionDetailHeaderTwoColumns>

                <Spacer Size="small" />

                {this.renderWorkoutExercises()}
            </>
        );
    }

    renderWorkoutLaps = WorkoutLaps => {
        var { t } = this.props;

        // eslint-disable-next-line
        if (!WorkoutLaps) var { WorkoutLaps } = this.state.Endurance.Template;

        var { ActiveType, CardioLevel, DataType } = WorkoutLaps;

        if (!ActiveType || !CardioLevel || !DataType) {
            return (
                <>
                    <Spacer Size="extra-small" />

                    <PageText FontFamily="medium-italic" FontSize="medium-2" NoMargin Text={t('fill_above_to_see_rest')} TextAlign="center" />
                </>
            );
        }

        var { Device } = this.props;
        var { Laps } = WorkoutLaps;

        var Units = '';
        if (this.state.Endurance.HowToBuildSession === 'Template') Units = this.state.Endurance.Template.TemplateId;
        else Units = this.props.UserDetails.PreferredMeasuringSystem.toLowerCase();
        
        var New_ActiveUnits = Units === 'imperial' ? 'yards' : 'meters';

        var Buttons = [];

        return (
            <styles.WorkoutLapsContainer className="WorkoutLapsContainer">
                {
                    Laps.length > 0 ?
                    Laps.map(({ Active, ActiveUnits, BreakTime, LapNumber, LapType, Rest, Speed, SpeedUnits, WorkoutLapId }, LapIndex) => {
                        Buttons = [{ BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-light', FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onAddLap(LapIndex), Title: t('add_endurance_lap') }];
                        if (LapType === 'Active' && ((Laps[LapIndex + 1] && Laps[LapIndex + 1].LapType === 'Active') || (!Laps[LapIndex + 1 ]))) Buttons.unshift({ BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-light', FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onAddBreak(LapIndex), Title: t('add_endurance_break') });

                        ActiveUnits = ActiveUnits || New_ActiveUnits;

                        return (
                            <React.Fragment key={LapIndex}>
                                {(LapIndex !== 0) && <Spacer Size="extra-small" />}

                                <styles.LapContainer className="LapContainer">
                                    <styles.LapHeaderContainer className="LapHeaderContainer" ActiveLap={LapType === 'Active'}>
                                        {this.renderLapHeader(LapIndex, LapNumber, LapType, WorkoutLapId)}
                                    </styles.LapHeaderContainer>
                                    <styles.LapDetailsContainer className="LapDetailsContainer">
                                        {this.renderLapDetails({ Active, ActiveUnits, BreakTime, LapIndex, LapType, Rest, Speed, SpeedUnits, WorkoutLapId })}
                                    </styles.LapDetailsContainer>
                                    {
                                        Device === 'laptop' &&
                                        <styles.LapFooterContainer className="LapFooterContainer" onClick={() => this.onDeleteLap(LapIndex)}>
                                            <styles.DeleteSetIcon
                                                Alt={`Delete lap ${WorkoutLapId}`}
                                                ImageSrc={DeleteX}
                                                Loading={false}
                                            />
                                        </styles.LapFooterContainer>
                                    }
                                </styles.LapContainer>

                                {
                                    ((Laps[LapIndex + 1]) || (LapIndex === Laps.length - 1)) &&
                                    <>
                                        <Spacer Size="extra-small" />

                                        <styles.StyledAddLap
                                            Buttons={Buttons}
                                            ButtonWidth={Device === 'mobile_small' ? '100%' : 'fit-content'}
                                            NotTouching
                                            OwnRows={Device === 'mobile_small'}
                                        />
                                    </>
                                }
                            </React.Fragment>
                        );
                    })
                :
                    <>
                        <PageText FontFamily="medium-italic" FontSize="medium-2" NoMargin Text={t('add_first_lap')} TextAlign="center" />
                        
                        <Spacer Size="extra-small" />

                        <styles.StyledAddLap
                            Buttons={[{ FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onAddLap(0), Title: t('add_endurance_lap') }]}
                            ButtonWidth={Device === 'mobile_small' ? '100%' : 'fit-content'}
                            NotTouching
                            OwnRows={Device === 'mobile_small'}
                        />
                    </>
                }
            </styles.WorkoutLapsContainer>
        );
    }

    renderWorkoutLapsOptions = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { Template: { WorkoutLaps: { ActiveType, CardioLevel, DataType } } } = this.state.Endurance;

        var ButtonStart = { BackgroundColor: 'white', BackgroundColorHover: 'blue-abel-hover', BackgroundColorSelected: 'blue-abel', ColorHover: 'white', ColorSelected: 'white', FontFamily: 'semibold' };

        var ActiveTypeButtons = [
            { ...ButtonStart, OnClick: () => this.onChangeActiveType('Time'), Selected: ActiveType === 'Time', Title: t('Time') },
            { ...ButtonStart, OnClick: () => this.onChangeActiveType('Distance'), Selected: ActiveType === 'Distance', Title: t('Distance') }
        ];

        var CardioLevels = [{ CardioLevel: t('low') }, { CardioLevel: t('medium') }, { CardioLevel: t('high') }];
        var CardioLevelButtons = CardioLevels.map(({ CardioLevel: MappedCardioLevel }, CardioLevelIndex) => ({ ...ButtonStart, OnClick: () => this.onSelectCardioLevel(CardioLevelIndex + 1), Selected: (CardioLevelIndex + 1) === CardioLevel, Title: MappedCardioLevel }));

        var DataTypeButtons = [
            { ...ButtonStart, OnClick: () => this.onChangeEnduranceDataType('dynamic'), Selected: DataType === 'dynamic', Title: t('dynamic_str') },
            { ...ButtonStart, OnClick: () => this.onChangeEnduranceDataType('static'), Selected: DataType === 'static', Title: t('static_str') }
        ];

        return (
            <styles.WorkoutLapsOptionsContainer className="WorkoutLapsOptionsContainer">
                <styles.WorkoutLapsOptionsMeasureTypeContainer className="WorkoutLapsOptionsMeasureTypeContainer">
                    <styles.StyledInputLabelMultiText
                        TextAlign="center"
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-2', Text: `${t('WorkoutTemplate_Endurance_Time_or_Distance')}:` },
                            { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                        ]}
                    />

                    <Spacer Size="small" />

                    <ButtonGroup
                        Buttons={ActiveTypeButtons}
                        ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                        NotTouching
                        OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                    />
                </styles.WorkoutLapsOptionsMeasureTypeContainer>
                <styles.WorkoutLapsOptionsIntensityContainer className="WorkoutLapsOptionsIntensityContainer">
                    <styles.StyledInputLabelMultiText
                        TextAlign="center"
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-2', Text: t('WorkoutTemplate_Endurance_Dynamic_or_Static') },
                            { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                        ]}
                    />

                    <Spacer Size="small" />

                    <ButtonGroup
                        Buttons={DataTypeButtons}
                        ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                        NotTouching
                        OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                    />
                </styles.WorkoutLapsOptionsIntensityContainer>
                <styles.WorkoutLapsOptionsIntensityContainer>
                    <styles.StyledInputLabelMultiText
                        TextAlign="center"
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-2', Text: `${t('WorkoutPlan_Intensity')}:` },
                            { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-3', Text: '*' },
                        ]}
                    />

                    <Spacer Size="extra-small" />

                    <ButtonGroup
                        Buttons={CardioLevelButtons}
                        ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                        NotTouching
                        OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                    />
                </styles.WorkoutLapsOptionsIntensityContainer>
            </styles.WorkoutLapsOptionsContainer>
        );
    }

    renderWorkoutLapsView = () => {
        var { t } = this.props;
        var { HowToBuildSession, Template: { TemplateDetails } } = this.state.Endurance;

        return (
            <>
                {
                    HowToBuildSession === 'Template' &&
                    <>
                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={TemplateDetails.TemplateName} TextAlign="left" />

                        <Spacer Size="small" />
                    </>
                }

                <styles.SessionDetailHeaderTwoColumns className="SessionDetailHeaderTwoColumns">
                    <styles.WorkoutNoteTextArea
                        OnBlur={this.onChangeTrainerNote}
                        NumRows={4}
                        Placeholder={t('WorkoutPlan_Trainer_note_to_client_placeholder')}
                        Value={TemplateDetails.TrainerNote}
                    />
                </styles.SessionDetailHeaderTwoColumns>

                <Spacer Size="small" />

                {this.renderWorkoutLapsOptions()}

                <Spacer Size="medium" />

                {this.renderWorkoutLaps()}
            </>
        );
    }

    renderWorkoutSource = SourceType => {
        var { t } = this.props;
        var { Device } = this.props;
        var { HowToBuildSession } = this.state[SourceType];

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('addworkout_how_to_build_session')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <styles.MenuOptionsContainer>
                    {
                        HowToBuildSessionTypes[SourceType].map(({ HowToBuildType, ImageName, PopoverTranslation, Translation, Translation2 }, index) => {
                            return (
                                <ButtonWithHoverSpot
                                    key={index}
                                    t={t}
                                    Device={Device}
                                    ImageName={ImageName}
                                    OnClick={() => this.onClickHowToBuildSession({ HowToBuildSession: HowToBuildType, HowToBuildSessionType: SourceType })}
                                    PopoverText={PopoverTranslation}
                                    Selected={HowToBuildType === HowToBuildSession}
                                    TranslationKey={Translation}
                                    TranslationKey2={Translation2}
                                />
                            );
                        })
                    }
                </styles.MenuOptionsContainer>
            </>
        );
    }

    renderWorkoutTypes = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { WorkoutType } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('addworkout_what_type_of_session')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <styles.MenuOptionsContainer>
                    {
                        WorkoutTypes.map(({ ImageName, PopoverTranslation, Translation, WorkoutType: MappedWorkoutType }, index) => {
                            return (
                                <ButtonWithHoverSpot
                                    key={index}
                                    t={t}
                                    Device={Device}
                                    ImageName={ImageName}
                                    OnClick={() => this.onClickWorkoutType(MappedWorkoutType)}
                                    PopoverText={PopoverTranslation}
                                    Selected={MappedWorkoutType === WorkoutType}
                                    TranslationKey={Translation}
                                />
                            );
                        })
                    }
                </styles.MenuOptionsContainer>
            </>
        );
    }

    render() {
        var { t } = this.props;
        var { TryingAddExistingWorkoutTemplate, TryingAddNewWorkout, TryingAddTrainingProgram, TryingGetAdditionalActivityDetails, TryingGetTrainingProgramDetail, TryingGetWorkoutTemplateDetail, UploadProgress } = this.props;
        var { Collapsed, ScreenNumber, WorkoutType } = this.state;

        return (
            <>
                {(TryingAddExistingWorkoutTemplate || TryingAddNewWorkout || TryingAddTrainingProgram || TryingGetTrainingProgramDetail || TryingGetWorkoutTemplateDetail) && <Loading />}
                {(((ScreenNumber === 2 && WorkoutType === 'Activity') || (ScreenNumber === 3 && (WorkoutType === 'Endurance' || WorkoutType === 'Strength'))) && TryingGetAdditionalActivityDetails) && <Loading />}

                <styles.AddWorkoutsOuterContainer
                    className="AddWorkoutsOuterContainer"
                    // ref={this.topRef}
                    Collapsed={Collapsed}
                >
                    {
                        !!Collapsed ?
                        <styles.AddWorkoutsCollapsedContainer className="AddWorkoutsCollapsedContainer" onClick={this.onToggleCollapsed}>
                            <styles.AddWorkoutsCollapsedIcon ImageSrc={PlusSign_White} />
                            <PageText FontColor="white" FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('add_sessions_to_the_calendar')} TextAlign="left" />
                        </styles.AddWorkoutsCollapsedContainer>
                    :
                        <>
                            <styles.AddWorkoutsParentContainer className="AddWorkoutsParentContainer">
                                <styles.AddWorkoutsTopHeaderContainer className="AddWorkoutsTopHeaderContainer">
                                    <styles.AddWorkoutsTopHeaderContainerOne className="AddWorkoutsTopHeaderContainerOne">
                                        <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin OnClick={this.onToggleCollapsed} Text={t('add_sessions_to_the_calendar')} TextAlign="left" />
                                    </styles.AddWorkoutsTopHeaderContainerOne>
                                    <styles.AddWorkoutsTopHeaderContainerTwo className="AddWorkoutsTopHeaderContainerTwo">
                                    
                                    </styles.AddWorkoutsTopHeaderContainerTwo>
                                </styles.AddWorkoutsTopHeaderContainer>

                                <styles.StyledHR />

                                <styles.AddWorkoutsBodyContainer className="AddWorkoutsBodyContainer">
                                    {this.renderBody()}
                                </styles.AddWorkoutsBodyContainer>
                            </styles.AddWorkoutsParentContainer>

                            {
                                UploadProgress.AddWorkouts && Object.entries(UploadProgress.AddWorkouts).length > 0 && 
                                    <>
                                        <Spacer Size="medium" />

                                        <UploadProgressComponent UploadingItems={UploadProgress.AddWorkouts} />
                                    </>
                            }

                            {!this.state.ShowEditTrainingProgramWorkoutTemplate && this.renderBottomButtons()}
                        </>   
                    }
                </styles.AddWorkoutsOuterContainer>

                {this.renderAddExerciseModal()}
                {this.renderChangeExerciseModal()}
                {this.renderExerciseDetailModal()}
                {this.renderShowAddWorkoutTemplateToProgramModal()}
                {this.renderShowHowToBuildResetWarningModal()}
                {this.renderShowRemoveWorkoutTemplateWarningModal()}
                {this.renderShowTemplateLibraryModal_Activity()}
                {this.renderShowTemplateLibraryModal_Endurance()}
                {this.renderShowTemplateLibraryModal_Strength()}
                {this.renderShowTrainingProgramLibraryModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        UserDetails: state.Auth.UserDetails,

        UploadProgress: state.UploadContent.UploadProgress,

        TryingGetAdditionalActivityDetails: state.AdditionalActivities.TryingGetAdditionalActivityDetails,
        TryingGetAdditionalActivityDetailsError: state.AdditionalActivities.TryingGetAdditionalActivityDetailsError,

        TryingAddExistingWorkoutTemplate: state.Templates.TryingAddExistingWorkoutTemplate,
        TryingAddExistingWorkoutTemplateError: state.Templates.TryingAddExistingWorkoutTemplateError,
        TryingAddNewWorkout: state.Workouts.TryingAddNewWorkout,
        TryingAddNewWorkoutError: state.Workouts.TryingAddNewWorkoutError,
        TryingAddTrainingProgram: state.Templates.TryingAddTrainingProgram,
        TryingAddTrainingProgramError: state.Templates.TryingAddTrainingProgramError,
        TryingGetTrainingProgramDetail: state.Templates.TryingGetTrainingProgramDetail,
        TryingGetTrainingProgramDetailError: state.Templates.TryingGetTrainingProgramDetailError,
        TryingGetWorkoutTemplateDetail: state.Templates.TryingGetWorkoutTemplateDetail,
        TryingGetWorkoutTemplateDetailError: state.Templates.TryingGetWorkoutTemplateDetailError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddExistingWorkoutTemplate, AddTrainingProgram_Modified, AddNewWorkout, GetAdditionalActivityDetails, GetTrainingProgramDetail, GetWorkoutTemplateDetail, UploadToPresignedUrl } )(AddWorkouts));