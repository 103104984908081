import styled from 'styled-components';

import PageText from '../../Components/Text/PageText';

export const AddedClientsContainer = styled.div`
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    width: 100%;
`;

export const CoachAddedSessionContainer = styled.div`
    align-items: flex-start;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
`;

export const Container1 = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;

    background-color: var(--white);
    padding: 10px;
    border-radius: 10px;
`;

export const LeftContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: hidden;
    flex: 1;
`;

export const RightContainer = styled.div``;

export const ProfilePhotoContainer = styled.div`
    margin-right: 5px;
    width: var(--profilephoto-size-small-1);
`;

export const StyledEllipsisText = styled(PageText)`
    overflow: hidden;

    > div {    
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;