import React from 'react';

import styled from 'styled-components';

import Image from '../../Components/Image';

const StyledImage = styled(Image)`
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 75px;
    max-width: 100%;
    object-fit: cover;
`;

function DishImage(props) {
    return (
        <StyledImage ImageSrc={props.DishUrl} SelfLoading />
    );
}

export default DishImage;