import styled from 'styled-components';
import { device } from '../../Config/device';

import Dropdown from '../../Components/Dropdown';
import Icon from '../../Components/Icon';
import Input from '../../Components/Form/Input';
import LoadMore from '../../Components/Lists/LoadMore';
import Page from '../../Components/Page';


// Dashboard
export const BottomContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
    }
`;

export const CalendarIcon = styled.img`
    height: 22px;
    margin: 0 auto;
    margin-left: 35px;
    width: 22px;
`;

export const DashboardContainer = styled.div`
    margin: 0px 15px;
`;

export const DataTypeContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;

    :not(:last-child) {
        margin-right: 15px;
    }
`;

export const DataTypeRadioButton = styled.div`
    ${
        props => props.Selected ?
        `
            background-color: var(--blue-astronaut);
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--white-concrete);
        `
    }
    border-radius: 50%;
    height: 16px;
    margin-right: 7px;
    width: 16px;

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const DataTypesContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;

    @media (min-width: 451px) {
        justify-content: flex-start;
    }
`;

export const DataTypesInfoContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;

    @media (min-width: 451px) {
        justify-content: flex-start;
    }
`;

export const DefinitionsContainer = styled.div`
    flex: 1;
`;

export const MonthlyChartContainer = styled.div``;

export const MonthlyContainer = styled.div`
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    padding: 15px 20px;
    padding-top: 0px;
`;

export const MonthlyHeaderContainer = styled.div`
    align-items: center;
    border-bottom: 2px solid var(--white-concrete);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${device.maxwidth.ipad} {
        margin-top: 10px;
    }

    @media ${device.minwidth.laptop} {
        flex-direction: row;
    }
`;

export const MonthlyHeaderLeftContainer = styled.div``;

export const MonthlyHeaderRightContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    @media ${device.maxwidth.ipad} {
        margin-top: 10px;
    }

    @media ${device.minwidth.laptop} {
        flex-direction: row;
    }
`;

export const MonthlyYearContainer = styled.div`
    align-items: center;
    display: flex;
    margin-right: 15px;
    margin-top: 15px;
`;

export const MonthlyYearsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-top: -15px;
`;

export const PaymentTypeContainer = styled.div``;

export const PendingPayoutsContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 20px;

    @media ${device.maxwidth.mobile} {
        margin-top: 15px;
    }

    @media ${device.minwidth.ipad} {
        margin-left: 15px;
    }
`;

export const SummariesContainer = styled.div`
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 1fr;

    @media ${device.minwidth.laptop} {
        grid-template-columns: 1fr 1fr;
    }
`;

export const SummaryContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 20px;

    @media ${device.minwidth.laptop} {
        align-items: start;
    }
`;

export const SummarySection = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${device.minwidth.laptop} {
        align-items: start;
        justify-content: flex-start;
    }
`;

export const SummarySections = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
    width: 100%;

    @media ${device.minwidth.laptop} {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

export const SummaryTitleContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const TimePeriodContainer = styled.div`
    align-items: center;
    border-left: 2px solid var(--white-concrete);
    border-right: 2px solid var(--white-concrete);
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;

    @media ${device.maxwidth.ipad} {
        border-bottom: 2px solid var(--white-concrete);
        border-top: 2px solid var(--white-concrete);
        border-radius: 10px;
    }

    @media(hover:hover) {
        :hover {
            background-color: var(--white-concrete);
            cursor: pointer;
        }
    }
`;

export const TitleContainer = styled.div`
    background-color: var(--blue-abel);
    border-radius: 10px;
    display: inline-block;
    margin-right: 8px;
    padding: 3px 8px;
`;



export const FiltersContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;

    @media ${device.minwidth.laptop} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const FilterSubContainer = styled.div`
    max-width: 100%;

    @media ${device.maxwidth.laptop} {
        :not(:last-child) {
            margin-bottom: 15px;
        }
    }
`;

export const MenuButtons = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const StyledDropdown = styled(Dropdown)`
    max-width: 100%;
    width: 300px;

    ${props => props.ForceClose === false ? 'z-index: 1001;' : null};

    ${
        props => props.IsMobile ?
        `
            > div { max-height: none; }
        `
    :
        `
            flex-grow: 1;
            ${props.Height ? `height: ${props.Height + 45 + 15}px;` : ''}
        `
    }
`;

export const StyledLoadMore = styled(LoadMore)`
    background-color: var(--white);
    border: none;
    padding: 15px;

    > div:first-child {
        display: grid;
        grid-gap: 3px;
        grid-template-columns: 1fr;

        @media ${device.minwidth.laptop} {
            margin: 0 auto;
        }
    }
`;

export const StyledPage = styled(Page)`
    & .page-maincontentcontainer {
        margin-top: 20px;
        width: 100%;
    }
`;

export const StyledSearchInput = styled(Input)`
    > input {
        border: 2px solid var(--white-concrete);
        max-width: 100%;
        width: 300px;
    }
`;






/* PAYOUTS */

export const DownloadContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const PayoutRow = styled.div`
    align-items: center;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 2fr 6fr 1fr;
    padding: 10px 15px;

    ${props => !props.Header && `
        background-color: var(--white-concrete);

        :not(:last-child) {
            margin-bottom: 10px;
        }
    `}

    :first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    :last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    :not(:last-child) {
        border-bottom: none;
    }
`;

export const PayoutsContainer = styled.div`
    padding: 0px 15px;
`;

export const StyledPaymentsLoadMore = styled(LoadMore)`
    background-color: var(--white);
    border: none;
    padding: 0px;
`;

export const StyledPDFIcon = styled(Icon)`
    margin-right: 10px;
    width: 25px;
`;