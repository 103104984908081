import styled from 'styled-components';
import { device } from '../../Config/device';

import { Link } from 'react-router-dom';

import Icon from '../Icon';
import ProfilePhoto from '../ProfilePhoto';

export const AbelHeader = styled.div`
    align-items: center;
    // background-color: var(--white);
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 10px;
`;

export const AbelHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-right: 24px;
    width: 100%;
`;

export const AbelLogo = styled.img`
    height: ${props => props.IsHeaderMobile ? '28px' : '40px'};
    width: ${props => props.IsHeaderMobile ? '30px' : '45px'};
`;

export const AbelLogoContainer = styled.div`
    display: flex;
    margin-right: 10px;
`;

export const AbelLogoImageContainer = styled(Link)`
    align-items: center;
    display: flex;
    margin-right: 10px;
`;

export const AbelNameContainer = styled(Link)`
    align-items: center;
    color: inherit;
    display: flex;
    flex-direction: column;
`;

export const ActionsButtonContainer = styled(Link)`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    color: var(--black);
    display: inline-flex;
    font-family: var(--font-family-medium);
    font-size: var(--font-size-medium-1);
    margin-bottom: 10px;
    margin-right: 10px;
    min-height: var(--button-height-medium);
    padding: 5px 10px;
    text-align: center;

    @media ${device.maxwidth.mobile_small} {
        justify-content: center;
        width: 100%;
    }

    @media (hover:hover) {
        :hover {
            background-color: var(--gray-dark);
            color: var(--black);
            cursor: pointer;
        }
    }
`;

export const ActionsButtonsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;

    @media ${device.maxwidth.mobile_small} {
        width: 100%;
    }
`;

export const ActionsRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    @media ${device.minwidth.mobile} {
        flex-direction: row;
    }
`;

export const ActionsRowHeader = styled.div`
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-medium-1);
    margin-bottom: 10px;

    @media ${device.minwidth.mobile} {
        margin-bottom: 0px;
        margin-right: 10px;
    }
`;

export const BottomHeaderSection = styled.div`
    align-items: center;
    display: flex;
    flex-direction: ${props => props.IsHeaderMobileTwoRows ? 'column' : 'row'};
    justify-content: ${props => props.IsHeaderMobileTwoRows ? 'center' : 'space-between'};
    margin-top: 10px;
    width: 100%;
`;

export const HamburgerIcon = styled.img`
    width: 30px;

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const HeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

export const HeaderMenuIcon = styled.div`
    margin-left: 5px;
`;

export const LeftHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const LeftMenu = styled.div`
    align-items: center;
    display: flex;
`;

export const MenuContainer = styled.div`
    display: flex;

    > a {
        align-items: center;
        display: flex;

        :not(:last-child) {
            margin-right: 50px;
        }
    }

    @media ${device.maxwidth.ipad} {
        > a {
            :not(:last-child) {
                margin-right: 30px;
            }
        }
    }
`;

export const MenuIcon = styled(Icon)`
    display: block;
    width: 30px;
`;

export const MenuLink = styled.a`
    align-items: center;
    display: flex;
    ${props => props.ActiveLink ? `color: var(--blue-abel);` : null}
    font-family: var(--font-family-medium);
    font-size: var(--font-size-medium-2);

    :active {
        color: var(--blue-abel);
    }

    :visited {
        color: var(--blue-abel);
    }

    @media(hover:hover) {
        :hover {
            color: var(--link-hover);
            cursor: pointer;
        }
    }
`;

export const MobileHeaderContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const MobileHeaderTopContainer = styled.div`
    align-items: center;    
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    width: 100%;
`;

export const MobileIcon = styled(Icon)`
    display: block;
    width: var(--profilephoto-size-small-1);
`;

export const MobileIconContainer = styled.div`
    :not(:last-child) {
        margin-right: 15px;
    }

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }

    ${
        props => props.Type === 'svg' &&
        `
            > svg {
                height: 25px;
                width: 25px;
            
                fill: var(--black);
            }

            ${
                props.Selected &&
                `
                  > svg {
                    fill: var(--abel-blue);
                  }
                `
              }
        `
    }
`;

export const MenuItemContainer = styled.li`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 15px 0;

  > svg {
    height: 25px;
    width: 25px;

    fill: var(--black);
  }

  ${
    props => props.Selected &&
    `
      border-left: 5px solid var(--abel-blue);
      width: calc(100% - 5px);

      > svg {
        fill: var(--abel-blue);
      }
    `
  }

  @media (hover: hover) {
    :hover {
      cursor: pointer;

      > svg {
        fill: var(--abel-blue);
      }
    }
  }
`;

export const MobileIconsContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-left: 15px;
`;

export const MobileMenuBottomContainer = styled.div``;

export const MobileMenuLeftContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${props => props.IsHeaderMobileTwoRows && 'margin-bottom: 10px; width: 100%;'}
`;

export const MobileMenuRightContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ${props => props.IsHeaderMobileTwoRows && 'width: 100%;'}
`;

export const MobileMenuTopContainer1 = styled.div`
    align-items: center;    
    display: flex;
`;

export const MobileProfilePhoto = styled(ProfilePhoto)`
    margin-left: 15px;
`;

export const MultiActionsInfoContainer = styled.div``;

export const NotificationCountContainer = styled.div`
    align-items: center;
    background-color: var(--red-bittersweet);
    border-radius: 25px;
    color: var(--white);
    display: inline-flex;
    font-family: var(--font-family-bold);
    font-size: var(--font-size-small);
    justify-content: center;
    margin-left: 3px;
    max-height: 25px;
    min-width: 25px;
    padding: 5px;
    vertical-align: middle;
    width: fit-content;
`;

export const ProfilePhotoContainer = styled.div`
    margin-left: 0px;
`;

export const RightHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media ${device.minwidth.ipad} {
        > a {
            margin-right: 20px;
        }
    }
`;

export const RightMenu = styled.div`
    align-items: center;
    display: flex;
`;

export const SearchClientsContainer = styled.div``;

export const TopHeaderSection = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;