import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ForgotPasswordSendCode, ForgotPasswordUpdatePassword } from '../../Services/Actions';

import { CircleDiv, ForgotPasswordPicture, ForgotPasswordPictureContainer, ShowPasswordsContainer, StyledInput } from './styles';

import AbelModal from '../../Components/Modal';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import ForgotPasswordIllustration from '../../Assets/Illustrations/ForgotPassword.png';

class ForgotPassword extends React.Component {
    _isMounted = false;

    state = {
        ConfirmNewPassword: '',
        Email: '',
        HasSentEmail: false,
        HoverShowPasswords: false,
        NewPassword: '',
        OTP: '',
        ShowPasswords: false,
        WrongOTP: false
    };

    componentDidMount() {
        this._isMounted = true;

        if (this.props.Email) this.setState({ Email: this.props.Email });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChangeEmail = event => {
        this.setState({ Email: event.target.value });
    }

    onChangeOTP = event => {
        this.setState({ OTP: event.target.value });
    }

    onChangePasswordInput = ({ InputType, InputValue }) => {
        this.setState({ [InputType]: InputValue });
    }

    onForgotPassword_SendCode = () => {
        var { Email } = this.state;

        this.props.ForgotPasswordSendCode({ Email });
        this.setState({ HasSentEmail: true });
    }

    onForgotPassword_UpdatePassword = () => {
        var { Email, NewPassword, OTP } = this.state;

        this.props.ForgotPasswordUpdatePassword({ Email, NewPassword, OTP }).then(({ WrongOTP }) => {
            if (!WrongOTP) this.props.OnHide();
            else this.setState({ WrongOTP });
        });
    }

    onToggleHoverShowPasswords = () => {
        this.setState({ HoverShowPasswords: !this.state.HoverShowPasswords });
    }

    onToggleShowPasswords = () => {
        this.setState({ ShowPasswords: !this.state.ShowPasswords });
    }

    renderEmailScreen = () => {
        var { t } = this.props;

        var ReadOnly = !!this.props.Email;
        var OnChange = ReadOnly ? () => null : this.onChangeEmail;

        return (
            <>
                <ForgotPasswordPictureContainer>
                    <ForgotPasswordPicture alt={t('RegisterLogin_forgot_password')} src={ForgotPasswordIllustration} />
                </ForgotPasswordPictureContainer>

                <PageText FontFamily="semibold" FontSize="medium-2" NoMargin Text={t('RegisterLogin_forgot_password')} TextAlign="center" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('RegisterLogin_enter_your_email_below')} TextAlign="center" />

                <Spacer Size="small" />

                <StyledInput
                    FontSize="medium-3"
                    NoLabel
                    OnChange={OnChange}
                    Placeholder={t('email_with_first_char_uppercase')}
                    ReadOnly={ReadOnly}
                    Size="large"
                    Type="text"
                    Value={this.state.Email}
                />
            </>
        );
    }

    renderOTPScreen = () => {
        var { t } = this.props;
        var { ConfirmNewPassword, Email, NewPassword, OTP, ShowPasswords, WrongOTP } = this.state;

        return (
            <>
                <PageText FontFamily="semibold" FontSize="medium-2" NoMargin Text={t('RegisterLogin_a_code_has_been_sent_to') + ` ${Email}`} TextAlign="center" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('RegisterLogin_please_enter_the_6_digits_code')} TextAlign="center" />

                <Spacer Size="small" />

                <StyledInput
                    FontSize="medium-3"
                    NoLabel
                    OnChange={this.onChangeOTP}
                    Placeholder={t('code_with_first_char_uppercase')}
                    Size="large"
                    Type="number"
                    Value={OTP}
                />

                <Spacer Size="small" />

                <StyledInput
                    FontSize="medium-3"
                    NoLabel
                    OnChange={event => this.onChangePasswordInput({ InputType: 'NewPassword', InputValue: event.target.value })}
                    Placeholder={t('Profile_new_password')}
                    Size="large"
                    Type={ShowPasswords ? 'text' : 'password'}
                    Value={NewPassword}
                />

                <StyledInput
                    FontSize="medium-3"
                    NoLabel
                    OnChange={event => this.onChangePasswordInput({ InputType: 'ConfirmNewPassword', InputValue: event.target.value })}
                    Placeholder={t('confirm_password_with_first_char_uppercase')}
                    Size="large"
                    Type={ShowPasswords ? 'text' : 'password'}
                    Value={ConfirmNewPassword}
                />

                {NewPassword && ConfirmNewPassword !== NewPassword && <PageText FontColor="red-bittersweet" FontFamily="semibold" FontSize="small" JustifyContent="flex-start" Text={t('Alert_invalid_doesnot_match_password')} TextAlign="left" />}

                {this.renderShowPasswords()}

                {WrongOTP && <>
                    <Spacer Size="small" />
                    <PageText FontColor="red-bittersweet" FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" Text={t('wrong_opt')} TextAlign="left" />
                </>}
            </>
        );
    }

    renderShowPasswords = () => {
        var { t } = this.props;
        var { HoverShowPasswords, ShowPasswords } = this.state;

        return (
            <ShowPasswordsContainer onMouseEnter={this.onToggleHoverShowPasswords} onMouseLeave={this.onToggleHoverShowPasswords}>
                <CircleDiv onClick={this.onToggleShowPasswords} Selected={HoverShowPasswords || ShowPasswords} />
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={this.onToggleShowPasswords} Text={t('show_passwords')} TextAlign="left" />
            </ShowPasswordsContainer>
        );
    }

    renderModalBody = () => {
        var { HasSentEmail } = this.state;

        return (
            <>
                {this.props.TryingForgotPasswordUpdatePassword && <Loading />}
                
                {!HasSentEmail && this.renderEmailScreen()}
                {HasSentEmail && this.renderOTPScreen()}
            </>
        );
    }

    render() {
        var { t } = this.props;
        var { OnHide, Show, TryingForgotPasswordSendCode, TryingForgotPasswordUpdatePassword } = this.props;
        var { ConfirmNewPassword, Email, HasSentEmail, NewPassword, OTP } = this.state;

        var Disabled = (
            TryingForgotPasswordSendCode || TryingForgotPasswordUpdatePassword ||
            (!HasSentEmail && !Email) ||
            (HasSentEmail && (!OTP || (ConfirmNewPassword !== NewPassword)))
        );

        var BottomButtonOnClick = HasSentEmail ? this.onForgotPassword_UpdatePassword : this.onForgotPassword_SendCode;
        var BottomButtonText = HasSentEmail ? t('save_with_first_char_uppercase') : t('send');

        return (
            <AbelModal
                BottomButton="submit"
                BottomButtonOnClick={BottomButtonOnClick}
                BottomButtonText={BottomButtonText}
                Disabled={Disabled}
                GA_PathName="ForgotPassword"
                Show={Show}
                Size="medium"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

ForgotPassword.propTypes = {
    Email: PropTypes.string,
    OnHide: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        TryingForgotPasswordSendCode: state.Auth.TryingForgotPasswordSendCode,
        TryingForgotPasswordSendCodeError: state.Auth.TryingForgotPasswordSendCodeError,
        TryingForgotPasswordUpdatePassword: state.Auth.TryingForgotPasswordUpdatePassword,
        TryingForgotPasswordUpdatePasswordError: state.Auth.TryingForgotPasswordUpdatePasswordError
    };
};

export default withTranslation()(connect(mapStateToProps, { ForgotPasswordSendCode, ForgotPasswordUpdatePassword } )(ForgotPassword));