import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddWorkoutLap, DeleteWorkoutLap, RegenerateMaxSpeed, UpdateWorkoutLap } from '../../Services/Actions';

import { AddMaxContainer, DeleteSetIcon, LapContainer, LapDetailsContainer, LapFooterContainer, LapHeaderContainer, LapHeaderContainerLeft, LapHeaderContainerRight, StyledAddLap, StyledAddMaxInput, StyledCheckmark, StyledCompletedButton, WorkoutLapsContainer } from './styles';

import ActiveLap from '../WorkoutLaps/ActiveLap';
import BreakLap from '../WorkoutLaps/BreakLap';
import ButtonGroup from '../Buttons/ButtonGroup';
import Loading from '../Loading';
import PageText from '../Text/PageText';
import Spacer from '../Spacer';

import CheckmarkIcon from '../../Assets/Icons/Checkmark_White.png';
import Copy from '../../Assets/Icons/Copy_White.png';
import DeleteX from '../../Assets/Icons/X_White.png';
import Plus from '../../Assets/Icons/Plus.png';

class EnduranceWorkout extends React.Component {
    state = {
        HoveringCompletedButton: null,
        MaxSpeed: 0,
        SpeedUnits: null,
        WorkoutLaps: {}
    }

    componentDidMount() {
        var { WorkoutLaps } = this.props;

        var Laps = [].concat(...WorkoutLaps.Laps.map(WL => WL.Laps.map(Lap => ({ ...Lap, LapType: WL.Type }))));

        this.setState({ SpeedUnits: WorkoutLaps.Laps[0].Laps[0].SpeedUnits, WorkoutLaps: { ...WorkoutLaps, Laps } });
    }

    onAddBreak = LapIndex => {
        var OriginalWorkoutLaps = JSON.parse(JSON.stringify(this.state.WorkoutLaps));
        var Laps = [ ...this.state.WorkoutLaps.Laps ];

        Laps.splice(LapIndex + 1, 0, { ...Laps[LapIndex], Active: 0, ActiveUnits: null, BreakTime: 60, Completed: 0, LapType: 'Break', Rest: 0, Speed: 0, SpeedUnits: null, WorkoutLapId: null });

        var LapNumber = 1;
        Laps = Laps.map((Lap, index) => ({ ...Lap, LapNumber: Lap.LapType === 'Active' ? LapNumber++ : 0, LapOrder: index + 1 }));

        var { ClientId, WorkoutDetails: { WorkoutId }, WorkoutLaps: { WorkoutEnduranceId } } = this.props;
        var { Active, ActiveUnits, BreakTime, Completed, Intensity, LapOrder, Rest, Speed } = Laps[LapIndex + 1];

        this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, Laps } }, () => this.props.AddWorkoutLap({ ClientId, Active, ActiveUnits, BreakTime, Completed, Intensity, LapOrder, Rest, Speed, WorkoutEnduranceId, WorkoutId }).then(({ WorkoutCompleted, WorkoutLapId }) => {
            if (this.props.TryingAddWorkoutLapError) this.setState({ WorkoutLaps: OriginalWorkoutLaps });
            else {
                Laps[LapIndex + 1] = { ...Laps[LapIndex + 1], WorkoutLapId };

                this.props.OnUpdateWorkoutDetails({ CanDelete: this.props.CanDelete, WorkoutCompleted });
                this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, Laps } });
            }
        }));
    }

    onAddLap = LapIndex => {
        var { t } = this.props;
        var { ActiveType } = this.state.WorkoutLaps;

        var OriginalWorkoutLaps = JSON.parse(JSON.stringify(this.state.WorkoutLaps));
        var Laps = [ ...this.state.WorkoutLaps.Laps ];

        // Copy if previous lap is not a break lap && not the first lap
            if (LapIndex !== 0 && Laps[LapIndex].LapType === 'Active') Laps.splice(LapIndex + 1, 0, { ...Laps[LapIndex], Completed: 0, WorkoutLapId: null });
        // Else create new lap with 0 seconds
            else {
                var Units = this.props.ClientDetails.UserUnits.toLowerCase();

                var NewActiveUnits = ActiveType === 'Time' ? null : t(Units === 'imperial' ? 'yards' : 'meters').toLowerCase();
                var NewSpeedUnits = Units === 'imperial' ? 'mph' : 'km/h';

                Laps.splice(LapIndex + 1, 0, { ...Laps[LapIndex], Active: 0, ActiveUnits: NewActiveUnits, BreakTime: 0, Completed: 0, LapType: 'Active', Rest: 0, Speed: 10, SpeedUnits: NewSpeedUnits, WorkoutLapId: null });
            }

        var LapNumber = 1;
        Laps = Laps.map((Lap, index) => ({ ...Lap, LapNumber: Lap.LapType === 'Active' ? LapNumber++ : 0, LapOrder: index + 1 }));

        var { ClientId, WorkoutDetails: { WorkoutId }, WorkoutLaps: { WorkoutEnduranceId } } = this.props;
        var { Active, BreakTime, Completed, Intensity, LapOrder, Rest, Speed, Unit } = Laps[LapIndex + 1];

        this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, Laps } }, () => this.props.AddWorkoutLap({ ClientId, Active, BreakTime, Completed, Intensity, LapOrder, Rest, Speed, Unit, WorkoutEnduranceId, WorkoutId }).then(({ WorkoutCompleted, WorkoutLapId }) => {
            if (this.props.TryingAddWorkoutLapError) this.setState({ WorkoutLaps: OriginalWorkoutLaps });
            else {
                Laps[LapIndex + 1] = { ...Laps[LapIndex + 1], WorkoutLapId };

                this.props.OnUpdateWorkoutDetails({ CanDelete: !WorkoutCompleted, WorkoutCompleted });
                this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, Laps } });
            }
        }));
    }

    onBlurMaxSpeed = () => {
        var { MaxSpeed } = this.state;

        if (+MaxSpeed === 0) this.setState({ MaxSpeed: 0 });
    }

    onChangeMaxSpeed = event => {
        this.setState({ MaxSpeed: event.target.value });
    }

    onDeleteLap = (LapIndex, WorkoutLapId) => {
        var OriginalWorkoutLaps = JSON.parse(JSON.stringify(this.state.WorkoutLaps));
        var Laps = [ ...this.state.WorkoutLaps.Laps ];

        Laps = [ ...Laps.slice(0, LapIndex), ...Laps.slice(LapIndex + 1) ];

        var LapNumber = 1;
        Laps = Laps.map((Lap, index) => ({ ...Lap, LapNumber: Lap.LapType === 'Active' ? LapNumber++ : 0, LapOrder: index + 1 }));

        var { ClientId, WorkoutDetails: { WorkoutId } } = this.props;

        this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, Laps } }, () => this.props.DeleteWorkoutLap({ ClientId, WorkoutId, WorkoutLapId }).then(({ WorkoutCompleted }) => {
            if (this.props.TryingDeleteWorkoutLapError) this.setState({ WorkoutLaps: OriginalWorkoutLaps });
            else this.props.OnUpdateWorkoutDetails({ CanDelete: !WorkoutCompleted, WorkoutCompleted  });
        }));
    }

    onMarkCompleted = () => {
        var OriginalWorkoutLaps = JSON.parse(JSON.stringify(this.state.WorkoutLaps));
        var Laps = [ ...this.state.WorkoutLaps.Laps ];
        Laps = Laps.map(Lap => ({ ...Lap, Completed: 1 }));

        this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, Laps } }, () => this.props.OnMarkCompleted().then(({ Response }) => {
            if (!Response) this.setState({ WorkoutLaps: OriginalWorkoutLaps });
        }));
    }

    onToggleLapCompleted = (Completed, LapIndex, LapType, WorkoutLapId) => {
        Completed = !!!+Completed;
        var { Active, BreakTime, Rest, Speed } = this.state.WorkoutLaps.Laps[LapIndex];

        var OriginalWorkoutLaps = JSON.parse(JSON.stringify(this.state.WorkoutLaps));
        var Laps = [ ...this.state.WorkoutLaps.Laps ];
        Laps[LapIndex] = { ...Laps[LapIndex], Completed };
        
        this.setState({ HoveringCompletedButton: null, WorkoutLaps: { ...this.state.WorkoutLaps, Laps } }, () => this.onUpdateWorkoutLap({ Active, BreakTime, Completed, LapIndex, LapType, Rest, Speed, UpdateState: false, WorkoutLapId }).then(({ WorkoutCompleted }) => {
            if (this.props.TryingUpdateWorkoutLapError) this.setState({ WorkoutLaps: OriginalWorkoutLaps });
            else this.props.OnUpdateWorkoutDetails({ CanDelete: !!!WorkoutCompleted, WorkoutCompleted });
        }));
    }

    onUpdateWorkoutLap = ({ Active, BreakTime, Completed, LapIndex, LapType, Rest, Speed, UpdateState, WorkoutLapId }) => {
        Completed = +Completed;
        WorkoutLapId = +WorkoutLapId;

        if (LapType === 'Active') {
            Active = +Active;
            BreakTime = 0;
            Rest = +Rest;
            Speed = +Speed;
        } else if (LapType === 'Break') {
            Active = 0;
            BreakTime = +BreakTime;
            Rest = 0;
            Speed = 0;
        }

        var Laps = [ ...this.state.WorkoutLaps.Laps ];
        Laps[LapIndex] = { ...Laps[LapIndex], Active, BreakTime, Rest, Speed };

        var { ClientId, WorkoutDetails: { WorkoutId } } = this.props;

        return this.props.UpdateWorkoutLap({ Active, BreakTime, ClientId, Completed, LapType, Rest, Speed, WorkoutId, WorkoutLapId }).then(({ WorkoutCompleted }) => {
            if (!this.props.TryingUpdateWorkoutLapError && UpdateState !== false) this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, Laps } });

            return { WorkoutCompleted };
        });
    }

    renderLapCompleteButton = (Completed, LapIndex, LapType, WorkoutLapId) => {
        if (LapIndex !== 0 && !!!+this.state.WorkoutLaps.Laps[LapIndex - 1].Completed) return null;

        var { HoveringCompletedButton } = this.state;

        var NextSetCompleted = this.state.WorkoutLaps.Laps[LapIndex + 1] && !!+this.state.WorkoutLaps.Laps[LapIndex + 1].Completed;

        var onMouseEnter = (Completed && !NextSetCompleted) ? (() => this.setState({ HoveringCompletedButton: WorkoutLapId })) : () => null;
        var onMouseLeave = (Completed && !NextSetCompleted) ? (() => this.setState({ HoveringCompletedButton: null })) : () => null;

        var IsHoveringCompletedButton = +HoveringCompletedButton !== 0 && +HoveringCompletedButton === +WorkoutLapId;

        var NoCursor = NextSetCompleted;

        return (
            <StyledCompletedButton
                Completed={Completed}
                HoveringCompletedButton={IsHoveringCompletedButton}
                NoCursor={NoCursor}
                onClick={NoCursor ? () => null : () => this.onToggleLapCompleted(Completed, LapIndex, LapType, WorkoutLapId)}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                <StyledCheckmark ShowCompleted={Completed && !IsHoveringCompletedButton} />
            </StyledCompletedButton>
        );
    }

    onRegenerateMax = () => {
        var { ClientId } = this.props;
        var { MaxSpeed, WorkoutLaps: { EnduranceTypeId, WorkoutEnduranceId } } = this.state;

        this.props.RegenerateMaxSpeed({ ClientId, EnduranceTypeId, MaxSpeed, WorkoutEnduranceId }).then(({ WorkoutLaps }) => {
            if (!this.props.TryingRegenerateMaxSpeedError) {
                var Laps = [].concat(...WorkoutLaps.Laps.map(WL => WL.Laps.map(Lap => ({ ...Lap, LapType: WL.Type }))));

                this.setState({ WorkoutLaps: { ...this.state.WorkoutLaps, ...WorkoutLaps, Laps, HasTestLaps: 0 } });
            }
        });
    }

    renderAddMax = () => {
        var { t } = this.props;
        var { MaxSpeed, SpeedUnits } = this.state;

        var Disabled = !+MaxSpeed;

        var Buttons = [
            { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-astronaut', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: this.onRegenerateMax, Title: t('regenerate') }
        ]

        return (
            <>
                <AddMaxContainer>
                    <PageText FontFamily="semibold" FontSize="medium-2" NoMargin Text={t('add_max_speed')} TextAlign="center" />

                    <Spacer Size="small" />

                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('ptadmin_no_max_speed')} TextAlign="center" />

                    <Spacer Size="small" />

                    <StyledAddMaxInput
                        InputFontFamily="semibold"
                        InputFontSize="medium-2"
                        LabelFontFamily="medium"
                        LabelFontSize="medium-1"
                        Label={SpeedUnits.toLowerCase()}
                        OnBlur={this.onBlurMaxSpeed}
                        OnChange={this.onChangeMaxSpeed}
                        Placeholder="0"
                        Type="number"
                        Value={MaxSpeed}
                    />

                    <Spacer Size="small" />

                    <ButtonGroup
                        Buttons={Buttons}
                        ButtonWidth="fit-content"
                        NotTouching
                    />
                </AddMaxContainer>

                <Spacer Size="medium" />
            </>
        );
    }

    renderLapDetails = ({ Active, ActiveUnits, BreakTime, Completed, HasTestLaps, LapIndex, LapType, Rest, Speed, SpeedUnits, WorkoutLapId }) => {
        var { ActiveType } = this.state.WorkoutLaps;

        if (LapType === 'Active') {
            return (
                <ActiveLap
                    Active={Active}
                    ActiveUnits={ActiveUnits}
                    ActiveType={ActiveType}
                    Completed={Completed}
                    HasTestLaps={HasTestLaps}
                    OnUpdateWorkoutLap={({ Active, Rest, Speed }) => this.onUpdateWorkoutLap({ Active, Completed, LapIndex, LapType, Rest, Speed, WorkoutLapId })}
                    Rest={Rest}
                    SetType="Workout"
                    Speed={Speed}
                    SpeedUnits={SpeedUnits}
                    WorkoutLapId={WorkoutLapId}
                />
            )
        } else if (LapType === 'Break') {
            return (
                <BreakLap
                    BreakTime={BreakTime}
                    Completed={Completed}
                    HasTestLaps={HasTestLaps}
                    OnUpdateWorkoutLap={({ BreakTime }) => this.onUpdateWorkoutLap({ BreakTime, Completed, LapIndex, LapType, WorkoutLapId })}
                    WorkoutLapId={WorkoutLapId}
                />
            )
        }
    }

    renderLapHeader = (Completed, HasTestLaps, LapIndex, LapNumber, LapType, WorkoutLapId) => {
        var { t } = this.props;
        var { Device } = this.props;

        return (
            <>
                <LapHeaderContainer className="LapHeaderContainer">
                    <LapHeaderContainerLeft>
                        {LapType === 'Active' && <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${t('lap_str_all_capital').toUpperCase()} ${LapNumber}`} TextAlign="right" />}
                        {LapType === 'Break' && <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('break').toUpperCase()} TextAlign="right" />}
                        
                        {Device !== 'laptop' && !HasTestLaps && this.renderLapCompleteButton(Completed, LapIndex, LapType, WorkoutLapId)}
                    </LapHeaderContainerLeft>

                    {
                        Device !== 'laptop' && !Completed && !HasTestLaps &&
                        <LapHeaderContainerRight>
                            <DeleteSetIcon
                                Alt={`Delete lap ${WorkoutLapId}`}
                                ImageSrc={DeleteX}
                                Loading={false}
                                OnClick={() => this.onDeleteLap(LapIndex, WorkoutLapId)}
                            />
                        </LapHeaderContainerRight>
                    }
                </LapHeaderContainer>

                {Device === 'laptop' && !HasTestLaps && this.renderLapCompleteButton(Completed, LapIndex, LapType, WorkoutLapId)}
            </>
        );
    }

    renderWorkoutLaps = () => {
        if (!this.state.WorkoutLaps.Laps) return null;

        var { t } = this.props;
        var { Device } = this.props;
        var { HasTestLaps, Laps } = this.state.WorkoutLaps;

        var Buttons = [];
        HasTestLaps = !!+HasTestLaps;

        return (
            <>
                {HasTestLaps && this.renderAddMax()}

                <WorkoutLapsContainer className="WorkoutLapsContainer">
                    {
                        Laps.map(({ Active, ActiveUnits, BreakTime, Completed, LapNumber, LapType, Rest, Speed, SpeedUnits, WorkoutLapId }, LapIndex) => {
                            Completed = !!+Completed;
                            
                            Buttons = [{ FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onAddLap(LapIndex), Title: t('add_endurance_lap') }]
                            if (LapType === 'Active' && ((Laps[LapIndex + 1] && Laps[LapIndex + 1].LapType === 'Active') || (!Laps[LapIndex + 1 ]))) Buttons.unshift({ FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onAddBreak(LapIndex), Title: t('add_endurance_break') });

                            return (
                                <React.Fragment key={WorkoutLapId}>
                                    {(LapIndex !== 0) && <Spacer Size="extra-small" />}

                                    <LapContainer className="LapContainer" HasBorder={Completed}>
                                        <LapHeaderContainer className="LapHeaderContainer">
                                            {this.renderLapHeader(Completed, HasTestLaps, LapIndex, LapNumber, LapType, WorkoutLapId)}
                                        </LapHeaderContainer>
                                        <LapDetailsContainer className="LapDetailsContainer">
                                            {this.renderLapDetails({ Active, ActiveUnits, BreakTime, Completed, HasTestLaps, LapIndex, LapType, Rest, Speed, SpeedUnits, WorkoutLapId })}
                                        </LapDetailsContainer>
                                        {
                                            Device === 'laptop' &&
                                            <LapFooterContainer className="LapFooterContainer">
                                                {
                                                    !Completed && !HasTestLaps &&
                                                    <DeleteSetIcon
                                                        Alt={`Delete lap ${WorkoutLapId}`}
                                                        ImageSrc={DeleteX}
                                                        Loading={false}
                                                        OnClick={() => this.onDeleteLap(LapIndex, WorkoutLapId)}
                                                    />
                                                }
                                            </LapFooterContainer>
                                        }
                                    </LapContainer>

                                    {
                                        ((Laps[LapIndex + 1] && !!!+Laps[LapIndex + 1].Completed) || (LapIndex === Laps.length - 1)) && !HasTestLaps &&
                                        <>
                                            <Spacer Size="extra-small" />

                                            <StyledAddLap
                                                Buttons={Buttons}
                                                ButtonWidth={Device === 'mobile_small' ? '100%' : 'fit-content'}
                                                NotTouching
                                                OwnRows={Device === 'mobile_small'}
                                            />
                                        </>
                                    }
                                </React.Fragment>
                            );
                        })
                    }
                </WorkoutLapsContainer>
            </>
        );
    }

    renderWorkoutOptions = () => {
        // Complete / Incomplete, Delete Workout
        var { t } = this.props;
        var { Device, Completed, CanDelete, OnCopyWorkoutToTemplate, OnDeleteWorkout } = this.props;
        var { HasTestLaps } = this.state.WorkoutLaps;

        var Buttons = [];

        if (!+Completed && !HasTestLaps) Buttons.push({ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Icon: CheckmarkIcon, IconPosition: 'left', FontFamily: 'semibold', OnClick: this.onMarkCompleted, Title: t('Mark_as_completed') });
        if (CanDelete) Buttons.push({ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: DeleteX, IconPosition: 'left', OnClick: OnDeleteWorkout, Title: t('WorkoutPlan_Delete_workout') });
        Buttons.push({ BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: Copy, IconPosition: 'left', OnClick: () => OnCopyWorkoutToTemplate(this.state.WorkoutLaps), Title: t('copy_workout_to_template') });

        return (
            <ButtonGroup
                Buttons={Buttons}
                ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                NotTouching
                OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
            />
        );
    }

    render() {
        var { Device, TryingAddWorkoutLap, TryingCompleteWorkout, TryingDeleteWorkout, TryingDeleteWorkoutLap, TryingRegenerateMaxSpeed, TryingUpdateWorkoutLap } = this.props;

        return (
            <>
                {(TryingAddWorkoutLap || TryingCompleteWorkout || TryingDeleteWorkout || TryingDeleteWorkoutLap || TryingRegenerateMaxSpeed || TryingUpdateWorkoutLap) ? <Loading /> : null}

                {this.renderWorkoutLaps()}

                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                {this.renderWorkoutOptions()}
            </>
        );
    }
}

EnduranceWorkout.propTypes = {
    CanDelete: PropTypes.bool,
    ClientId: PropTypes.number,
    Completed: PropTypes.bool,
    ExistingWorkoutId: PropTypes.number,
    OnCopyWorkoutToTemplate: PropTypes.func.isRequired,
    OnDeleteWorkout: PropTypes.func.isRequired,
    OnMarkCompleted: PropTypes.func.isRequired,
    OnUpdateWorkoutDetails: PropTypes.func.isRequired,
    TryingCompleteWorkout: PropTypes.bool.isRequired,
    TryingDeleteWorkout: PropTypes.bool.isRequired,
    WorkoutDetails: PropTypes.object.isRequired,
    WorkoutLaps: PropTypes.object.isRequired,
}

EnduranceWorkout.defaultProps = {
    ExistingWorkoutId: 0
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        ClientDetails: state.Client.ClientDetails,

        TryingAddWorkoutLap: state.Workout.TryingAddWorkoutLap,
        TryingAddWorkoutLapError: state.Workout.TryingAddWorkoutLapError,
        TryingDeleteWorkoutLap: state.Workout.TryingDeleteWorkoutLap,
        TryingDeleteWorkoutLapError: state.Workout.TryingDeleteWorkoutLapError,
        TryingRegenerateMaxSpeed: state.Workout.TryingRegenerateMaxSpeed,
        TryingRegenerateMaxSpeedError: state.Workout.TryingRegenerateMaxSpeedError,
        TryingUpdateWorkoutLap: state.Workout.TryingUpdateWorkoutLap,
        TryingUpdateWorkoutLapError: state.Workout.TryingUpdateWorkoutLapError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddWorkoutLap, DeleteWorkoutLap, RegenerateMaxSpeed, UpdateWorkoutLap } )(EnduranceWorkout));