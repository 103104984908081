import styled from 'styled-components';

import Image from '../../Components/Image';
import PageText from '../../Components/Text/PageText';

export const GroupDetailContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin-left: 15px;
    padding: 0px 20px;
`;

export const GroupHeaderContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
    padding: 10px 20px;

    ${
        props => props.HasPicture &&
        '> div:first-child { min-width: 40px; }'
    }

    ${
        props => props.Clickable &&
        `
            @media(hover:hover) {
                :hover {
                    cursor: pointer;
                }
            }
        `
    }

    @media(min-width: 391px) {
        flex-direction: row;
        justify-content: flex-start;
    }
`;

export const GroupInfoContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media(min-width: 391px) {
        align-items: flex-start;
    }
`;

export const GroupInfoSubContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media(min-width: 301px) {
        flex-direction: row;
    }
`;

export const ReorderGroupContainer = styled.div`
    display: flex;
    z-index: 1000000;

    :not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const ReorderGroupsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ReorderNumberContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 50%;
    display: flex;
    font-family: var(--font-family-bold);
    font-size: var(--font-size-large);
    height: 50px;
    justify-content: center;
    width: 50px;

    :hover {
        cursor: pointer;
    }
`;

export const StyledGroupName = styled(PageText)`
    @media(max-width: 390px) {
        text-align: center;
    }
`;

export const StyledPageTextWithDotAfter = styled(PageText)`
    align-items: center;

    :after {
        background-color: var(--black);
        border-radius: 7px;
        content: '';
        height: 7px;
        margin: 0 7px;
        width: 7px;
    }
`;

export const StyledTeamPicture = styled(Image)`
    height: 40px;
    object-fit: cover;
    width: 40px;

    > div {
        width: 100%;
    }
`;