import styled from 'styled-components';
import { device } from '../../Config/device';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Dropdown from '../../Components/Dropdown';
import Image from '../../Components/Image';
import Input from '../../Components/Form/Input';
import MultiText from '../../Components/Text/MultiText';
import TextArea from '../../Components/Form/TextArea';

import Checkmark from '../../Assets/Icons/Checkmark_Selected.png';

export const ActivityPictureContainer = styled.div`
    border: 4px solid ${props => props.Selected ? 'var(--blue-abel)' : 'transparent'};
    border-radius: 15px;
    width: 150px;

    @media(hover:hover) {
        :hover {
            border: 4px solid var(--blue-abel);
            cursor: pointer;
        }
    }
`;

export const ActivityPicturesContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;

export const AdditionalActivityItem = styled.div`
    ${
        props => props.Selected ?
        `
            color: var(--blue-abel);
        `
    :
        null
    }

    :not(:last-child) {
        margin-bottom: 5px;
    }

    @media(hover:hover) {
        :hover {
            color: var(--blue-abel);
            cursor: pointer;
        }
    }
`;

export const AdditionalActivityList = styled.div``;

export const CircuitContainer = styled.div`
    ${
        props => props.CircuitType !== 'WorkoutExercise' &&
        `
            border: 5px solid ${props.CircuitType === 'Circuit' ? 'var(--turquoise-dark)' : 'var(--blue-royal)'};   
        `
    }
    border-radius: 10px;
`;

export const CircuitSeparator = styled.div`
    align-items: center;
    background-color: var(--${props => props.BackgroundColor});
    display: flex;
    flex-direction: column;
    height: var(--spacer-size-medium);
    justify-content: center;
`;

export const DateTimeContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
    margin-top: 15px;
    width: 175px;
`;

export const DateTimesContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: -15px;
    margin-top: -15px;
`;

export const DateTimeUseAllDays = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    display: block;
    height: 25px;
    margin-right: 5px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
            border: none;
            cursor: pointer;
        }
    }

    :nth-child(2) {
        max-width: 600px;
    }
`;

export const DateTimeUseAllDaysContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 10px;
`;

export const DurationContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    border: 2px solid ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white-concrete)'};
    border-radius: 10px;
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    display: flex;
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-medium-1);
    justify-content: center;
    margin-right: 15px;
    margin-top: 15px;
    padding: 7px 15px;

    @media(hover:hover) {
        :hover {
            background-color: var(--blue-astronaut);
            color: var(--white);
            cursor: pointer;
        }
    }
`;

export const DurationsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 10px;
    margin-right: -15px;
    margin-top: -15px;
`;

export const DeleteSetIcon = styled(Image)`
    height: 20px;
    width: 20px;
`;

export const EditIcon = styled(Image)`
    height: 30px;
    margin-right: 15px;
    margin-top: 15px;
    width: 30px;
`;

export const HourType = styled.div`
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    padding: 6px 15px;

    :hover {
        cursor: pointer;
    }
`;

export const HourTypesContainer = styled.div`
    align-items: center;
    border: 2px solid var(--blue-astronaut);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    height: 35px;
    margin: 0 auto;
    overflow: hidden;
    width: fit-content;
`;

export const LapContainer = styled.div`
    align-items: stretch;
    border: 2px solid ${props => props.HasBorder ? 'var(--blue-astronaut)' : 'var(--white-concrete)'};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

    @media ${device.minwidth.laptop} {
        display: grid;
        grid-template-columns: 1fr 8.5fr 0.5fr;
    }
`;

export const LapDetailsContainer = styled.div`
    width: 100%;
`;

export const LapFooterContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    display: flex;
    justify-content: center;
`;

export const LapHeaderContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    display: flex;
    flex-direction: row;
    padding: 5px;
    width: 100%;

    @media ${device.minwidth.laptop} {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

export const LapHeaderContainerLeft = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;

    @media ${device.minwidth.laptop} {
        justify-content: center
        width: 100%;
    }
`;

export const LapHeaderContainerRight = styled.div``;

export const SelectedDateContainer = styled.div`
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    margin-right: 15px;
    margin-top: 15px;
    padding: 5px 12px;
`;

export const SelectedDatesContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -15px;
    margin-top: -15px;
`;

export const StyledActivityImage = styled(Image)`
    border-radius: 10px;
    height: 130px;
    object-fit: cover;
    width: 100%;

    > div {
        width: 100%;
    }
`;

export const StyledAddLap = styled(ButtonGroup)`
    justify-content: flex-start;

    @media ${device.maxwidth.mobile_small} {
        width: 100%;
    }
`;

export const StyledDropdown = styled(Dropdown)`
    background-color: var(--white-concrete);
    margin: 0 auto;
    max-width: 350px;
    width: 100%;

    ${props => props.ForceClose === false ? 'z-index: 1001;' : null};

    ${
        props => props.IsMobile ?
        `
            > div { max-height: none; }
        `
    :
        `
            ${props.Height ? `height: ${props.Height + 45 + 15}px;` : ''}
            margin: 0 auto;
            width: 350px
        `
    }
`;

export const StyledDuration = styled(Input)`
    margin: 0 auto;
    max-width: 100%;
    width: 350px;

    > input {
        background-color: var(--white-concrete);
        border: none;
        border-radius: 10px;
        text-align: center;
        text-indent: 0px;
    }
`;

export const StyledInputLabelMultiText = styled(MultiText)`
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const StyledIntensities = styled(ButtonGroup)`
    margin: 0 auto;
    max-width: 100%;
    width: 350px;
`;

export const StyledWorkoutName = styled(Input)`
    margin: 0 auto;
    max-width: 100%;
    width: 350px;

    > input {
        background-color: var(--white-concrete);
        border: none;
        border-radius: 10px;
        text-align: center;
        text-indent: 0px;
    }
`;

export const TrainerNoteContainer = styled.div``;

export const TrainerNoteTextArea = styled(TextArea)`
    margin: 0 auto;
    max-width: 675px;

    > textarea {
        background-color: var(--white-concrete);
        border: none;
    }
`;

export const UploadButtonsContainer = styled(ButtonGroup)`
    justify-content: center;
`;

export const UploadContainer = styled.div`
    margin-top: 10px;
`;

export const UploadButtonsRow = styled.div`
    margin-bottom: 10px;
`;

export const WorkoutExercisesContainer = styled.div``;

export const WorkoutLapsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;