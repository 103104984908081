import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ButtonGroup from '../../Buttons/ButtonGroup';
import Image from '../../Image';
import PageText from '../../Text/PageText';
import Spacer from '../../Spacer';

import AbelIcon from '../../../Assets/Icons/Abel_HeaderIcon.png';
import Checkmark from '../../../Assets/Icons/Checkmark_Selected.png';
import Female from '../../../Assets/Icons/Female.png';
import Male from '../../../Assets/Icons/Male.png';

const DishContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;

    ${
        props => props.Selected ?
        `
            background-color: rgba(26, 151, 255, 0.35);
            border: 5px solid var(--blue-abel);
            border-radius: 20px;
            overflow: hidden;

            @media(hover:hover) {
                :hover {
                    background-color: rgba(26, 151, 255, 0.35);
                }
            }
        `
    :
        null
    }

    > div:first-child {
        width: 100%;
    }
`;

const MultiSelect = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    height: 25px;
    left: 15px;
    position: absolute;
    top: 15px;
    width: 25px;
    z-index: 1;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
            border: none;
            cursor: pointer;
        }
    }
`;

const StyledExerciseImage = styled(Image)`
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    height: 130px;
    object-fit: cover;
    width: 100%;

    > div {
        width: 100%;
    }
`;

const StyledExerciseName = styled(PageText)`
    text-transform: uppercase;
`;

// const StyledExerciseVideo = styled.video`
//     border: 2px solid var(--white-concrete);
//     border-radius: 10px;
//     display: flex;
//     height: 130px;
//     object-fit: cover;
//     max-width: 100%;
//     width: 100%;

//     > div {
//         width: 100%;
//     }

//     @media(hover:hover) {
//         :hover {
//             cursor: pointer;
//         }
//     }
// `;

const StyledProfilePhoto = styled(Image)`
    height: 25px;
    left: 15px;
    position: absolute;
    top: 15px;
    width: 25px;

    ${props => !props.IsAbel && 'border-radius: 50%;'}
    overflow: hidden;
`;

class ExerciseListItem extends React.Component {
    componentDidMount() {
        // console.log('Exercise: ', this.props.Item);
    }

    onSelectExercise = Item => {
        var { IsMultiSelect, MultiSelectItemIds, OnMultiSelect, SelectExercise } = this.props;
        if (!IsMultiSelect) SelectExercise(Item);
        else {
            var MultiSelectIndex = MultiSelectItemIds.indexOf(+Item.ExerciseId);

            OnMultiSelect(Item, MultiSelectIndex);
        }
    }

    onUpdateSharedToCoachStatus = ({ event, ContentId, NewStatus }) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        this.props.OnUpdateSharedToCoachStatus({ ContentId, NewStatus, PersonalResponse: 1 });
    }

    renderMultiSelect = ExerciseOwner => {
        if (!this.props.FromAddExercise && ExerciseOwner !== 'Self') return null;
        
        var { Item, MultiSelectItemIds, OnMultiSelect } = this.props;

        var { ExerciseId } = Item;

        var MultiSelectIndex = MultiSelectItemIds.indexOf(Number(ExerciseId));

        return <MultiSelect onClick={() => OnMultiSelect(Item, MultiSelectIndex)} Selected={MultiSelectIndex !== -1} />;
    }

    renderNonSelectable = () => {
        var { t } = this.props;
        var { className, HideMadeBy, Item, ViewExercise } = this.props;

        var { ExerciseId, ExerciseName, ExerciseImage } = Item;

        return (
            <DishContainer id={`itemid-${ExerciseId}`} className={className}>
                <StyledExerciseImage
                    Alt={ExerciseName}
                    ContainerWidth="100%"
                    ImageSrc={ExerciseImage}
                    OnClick={() => ViewExercise(Item)}
                    SelfLoading
                    ShowOverlay
                />

                {!HideMadeBy && this.renderUserPhoto()}

                <Spacer Size="extra-small" />

                <ButtonGroup
                    Buttons={[
                        {
                            BackgroundColorHover: 'blue-abel',
                            Border: '2px solid var(--white-concrete)',
                            ColorHover: 'white',
                            Disabled: true,
                            FontFamily: 'medium',
                            FontSize: 'small',
                            Size: 'small',
                            Title: t('select_with_first_char_uppercase')
                        }]}
                    ContainerWidth="100%"
                    NormalWhiteSpace
                />
                
                <Spacer Size="extra-extra-small" />
                
                <StyledExerciseName className="styled-dish-name" FontFamily="bold" FontSize="small" NoMargin Text={ExerciseName} TextAlign="center"/>
            </DishContainer>
        );
    }

    renderUserPhoto = () => {
        var { DisplayName, Sex, UserId, UserPhoto } = this.props.Item;

        var SexImage = Sex === 'Male' ? Male : Female;

        if (UserId === 16) UserPhoto = AbelIcon;

        return <StyledProfilePhoto Alt={DisplayName} ImageSrc={UserPhoto} IsAbel={UserId === 16} NoImage={SexImage} NoParent />;
    }

    render() {
        var { t } = this.props;
        var { className, FromAddExercise, HideMadeBy, HideSelect, Item, MultiSelectItemIds, SelectedItemIds, ViewExercise } = this.props;

        var { ExerciseId, ExerciseName, ExerciseImage, RelationshipType, SharedStatus } = Item;
        // var { ExerciseId, ExerciseName, ExerciseImage, ExerciseVideo, RelationshipType, SharedStatus } = Item;

        // if (!!ExerciseImage) {
        //     const imageRequest = JSON.stringify({
        //         bucket: "abel-dev",
        //         key: ExerciseImage.split('https://abel-dev.s3.amazonaws.com/')[1],
        //         edits: {
        //             resize: {
        //                 width: 1000,
        //                 height: 1000,
        //                 fit: "cover"
        //             },
        //             grayscale: true
        //         }
        //     });
    
        //     // console.log('Image Request: ', imageRequest);
    
        //     var CloudFrontUrl = 'https://dg111mtvgarh5.cloudfront.net';
            
        //     ExerciseImage = `${CloudFrontUrl}/${Buffer.from(imageRequest).toString('base64')}`;
        // }

        // If FromAddExercise (then multi-select is on), Allow All Exercises Except When SharedTo and SharedStatus = 2
            if (!!FromAddExercise && RelationshipType === 'SharedTo' && SharedStatus !== 1) return this.renderNonSelectable();

        // If Select Button is Showing, Hide Abel Templates and Pending SharedTo as they can't do anything in the multi-select options
            // else if (!FromAddExercise && (RelationshipType === 'Abel' || (RelationshipType === 'SharedTo' && SharedStatus === 2)) && !HideSelect && IsMultiSelect) return this.renderNonSelectable();

        // If RelationshipType === 'SharedTo' && SharedStatus === 2, Then Show Only Accept / Pending Buttons
            if ((RelationshipType === 'SharedTo' && SharedStatus === 2)) {
                return (
                    <DishContainer id={`itemid-${ExerciseId}`} className={className}>
                        {
                        //     !!ExerciseVideo ?
                        //     // <StyledExerciseVideo id="video" { ...ExerciseImage && { poster: ExerciseImage } } controls><source src={ExerciseVideo} type="video/mp4" />Your browser does not support the video tag.</StyledExerciseVideo>
                        //     <StyledExerciseVideo
                        //         autoplay={false}
                        //         controls={false}
                        //         muted
                        //         { ...ExerciseImage && { poster: ExerciseImage } }
                        //         onClick={() => ViewExercise(Item)}
                        //         onMouseOver={e => e.target.play()}
                        //         onMouseOut={e => e.target.load()}
                        //         // onMouseOver={e => e.target.play()}
                        //         // onMouseOut={e => e.target.load()}
                        //         src={ExerciseVideo}
                        //     />
                        // :
                            <StyledExerciseImage
                                Alt={ExerciseName}
                                ContainerWidth="100%"
                                ImageSrc={ExerciseImage}
                                OnClick={() => ViewExercise(Item)}
                                SelfLoading
                                ShowOverlay
                            />
                        }

                        {!HideMadeBy && this.renderUserPhoto()}

                        <Spacer Size="extra-extra-small" />

                        <StyledExerciseName className="styled-dish-name" FontFamily="bold" FontSize="small" NoMargin Text={ExerciseName} TextAlign="center"/>

                        <Spacer Size="extra-extra-small" />

                        <ButtonGroup
                            Buttons={[
                                { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', OnClick: event => this.onUpdateSharedToCoachStatus({ event, ContentId: ExerciseId, NewStatus: 'reject' }), Size: 'small', Title: t('reject') },
                                { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', OnClick: event => this.onUpdateSharedToCoachStatus({ event, ContentId: ExerciseId, NewStatus: 'accept' }), Size: 'small', Title: t('accept') }
                            ]}
                            // ButtonWidth="fit-content"
                            NotTouching
                            OwnRows
                        />
                    </DishContainer>
                );
            }
        
        var Selected = SelectedItemIds.indexOf(+ExerciseId) > -1 || MultiSelectItemIds.indexOf(+ExerciseId) > -1;

        return (
            <DishContainer id={`itemid-${ExerciseId}`} Selected={Selected}>
                {/* {IsMultiSelect && this.renderMultiSelect(ExerciseOwner)} */}

                {
                //     !!ExerciseVideo ?
                //     // <StyledExerciseVideo id="video" { ...ExerciseImage && { poster: ExerciseImage } } controls><source src={ExerciseVideo} type="video/mp4" />Your browser does not support the video tag.</StyledExerciseVideo>
                //     <StyledExerciseVideo
                //         autoplay={false}
                //         controls={false}
                //         muted
                //         { ...ExerciseImage && { poster: ExerciseImage } }
                //         onClick={() => ViewExercise(Item)}
                //         onMouseOver={e => e.target.play()}
                //         onMouseOut={e => e.target.pause()}
                //         // onMouseOver={e => e.target.play()}
                //         // onMouseOut={e => e.target.pause()}
                //         src={ExerciseVideo}
                //     />
                // :
                    <StyledExerciseImage
                        Alt={ExerciseName}
                        ContainerWidth="100%"
                        ForceOverlay={Selected}
                        ImageSrc={ExerciseImage}
                        OnClick={() => ViewExercise(Item)}
                        OverlayColor={(HideSelect && Selected) ? 'rgba(26, 151, 255, 0.35)' : null}
                        SelfLoading
                        ShowOverlay
                    />
                }

                {!HideMadeBy && this.renderUserPhoto()}

                {
                    !HideSelect &&
                    <>
                        <Spacer Size="extra-small" />
                
                        <ButtonGroup
                            Buttons={[
                                {
                                    BackgroundColorHover: 'blue-abel',
                                    BackgroundColorSelected: 'blue-abel',
                                    Border: Selected ? '2px solid var(--blue-abel)' : '2px solid var(--white-concrete)',
                                    ColorHover: 'white',
                                    ColorSelected: 'white',
                                    FontFamily: 'medium',
                                    FontSize: 'small',
                                    OnClick: () => this.onSelectExercise(Item), Selected, Size: 'small',
                                    Title: t(Selected ? 'WorkoutPlanSettings_selected' : 'select_with_first_char_uppercase')
                                }]}
                            ContainerWidth="100%"
                            NormalWhiteSpace
                        />
                    </>
                }

                <Spacer Size="extra-extra-small" />

                <StyledExerciseName className="styled-dish-name" FontFamily="bold" FontSize="small" NoMargin Text={ExerciseName} TextAlign="center"/>
            </DishContainer>
        );
    }
}

ExerciseListItem.propTypes = {
    AssignToClients: PropTypes.bool,
    FromAddExercise: PropTypes.bool,
    HideOwnerRating: PropTypes.bool,
    HideSelect: PropTypes.bool,
    IsMultiSelect: PropTypes.bool,
    Item: PropTypes.object,
    OnAssignToClients: PropTypes.func,
    OnMultiSelect: PropTypes.func,
    MultiSelectItemIds: PropTypes.array,
    SelectExercise: PropTypes.func,
    SelectedItemIds: PropTypes.array,
    SelectedUsers: PropTypes.array,
    ViewExercise: PropTypes.func
}

ExerciseListItem.defaultProps = {
    FromAddExercise: false,
    MultiSelectItemIds: [],
    SelectedItemIds: []
}

const mapStateToProps = state => {
    return {
        UserDetails: state.Auth.UserDetails
    };
};

export default withTranslation()(connect(mapStateToProps, null )(ExerciseListItem));