import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearSaveData, DeleteContract, SearchTrainerContracts, SearchTrainerContractsClients, SearchTrainerContractsHistory, SearchTrainerContractsHistoryClients } from '../../Services/Actions';
import history from '../../history';

import { FiltersContainer, FilterSubContainer, StyledDropdown, StyledLoadMore, StyledSearchInput } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import { ContractListItem } from '../../Components/Lists/ListItems';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Info from '../../Modals/Info';

import Plus from '../../Assets/Icons/Plus.png';

class Contracts extends React.Component {
    state = {
        Clients_Active: [],
        Clients_History: [],
        Contracts: [],
        ContractsFilters: { ClientIds: [], LastSearchedUsername: '', SortId: 2, UserName: '' },
        ContractsPageNo: 1,
        ContractsPageSize: 30,
        ContractsTotalRecords: 0,
        ContractsType: 'Active',
        DeleteContractId: null,
        DisplayingFilter: null,
        History: [],
        HistoryFilters: { ClientIds: [], LastSearchedUsername: '', SortId: 1, UserName: '' },
        HistoryPageNo: 1,
        HistoryPageSize: 30,
        HistoryTotalRecords: 0,
        IsMoreResults: false,
        LoadedContracts: false,
        LoadedHistory: false,
        OpenFilterSize: 0,
        RetrievedActiveContractClients: false,
        RetrievedHistoryContractClients: false,
        ShowAddContract: false,
        ShowDeleteContractModal: false
    }

    componentDidMount() {
        this.onLoadContracts();
    }

    onChangeSearchUsername = ({ event, ContractsType }) => {
        if (ContractsType === 'Active') this.setState({ ContractsFilters: { ...this.state.ContractsFilters, UserName: event.target.value } });
        else if (ContractsType === 'History') this.setState({ HistoryFilters: { ...this.state.HistoryFilters, UserName: event.target.value } });
    }

    onDeleteContract = () => {
        this.props.DeleteContract({ ContractId: this.state.DeleteContractId }).then(() => {
            this.onToggleShowDeleteContractModal({});
            this.onLoadContracts();
        });
    }

    onGetSize = Height => {
        this.setState({ OpenFilterSize: Height });
    }

    onGoToCreateContract = () => {
        history.push('/add-contract');
    }

    onKeyPressUsername = ({ key }) => {
        if (key === 'Enter' && !this.props.TryingSearchTrainerContracts) {
            var LastSearchedValue, SearchValue;

            if (this.state.ContractsType === 'Active') {
                LastSearchedValue = this.state.ContractsFilters.LastSearchedUsername;
                SearchValue = this.state.ContractsFilters.UserName;

                if (SearchValue !== LastSearchedValue) this.setState({ ContractsPageNo: 1, ContractsFilters: { ...this.state.ContractsFilters, LastSearchedUsername: SearchValue } }, () => this.onLoadContracts());
            }
            else if (this.state.ContractsType === 'History') {
                LastSearchedValue = this.state.HistoryFilters.LastSearchedUsername;
                SearchValue = this.state.HistoryFilters.UserName;

                if (SearchValue !== LastSearchedValue) this.setState({ HistoryPageNo: 1, HistoryFilters: { ...this.state.HistoryFilters, LastSearchedUsername: SearchValue } }, () => this.onLoadHistory());
            }
        }
    }

    onLoadContracts = Increment => {
        var { ContractsFilters: { ClientIds, SortId, UserName }, ContractsPageNo, ContractsPageSize } = this.state;

        if (Increment) ContractsPageNo = ContractsPageNo + 1;
        else ContractsPageNo = 1;

        var OrderBy = (SortId === 1 || SortId === 2) ? 'CreatedDate' : 'UserName';
        var OrderType = (SortId === 1 || SortId === 3) ? 'ASC' : 'DESC';

        this.setState({ ContractsPageNo, LoadedContracts: Increment }, () => this.props.SearchTrainerContracts({ ClientIds, PageNo: ContractsPageNo, PageSize: ContractsPageSize, OrderBy, OrderType, UserName }).then(({ IsMoreResults: ContractsIsMoreResults, Contracts: NewContracts, TotalRecords: ContractsTotalRecords }) => {
            if (Increment) NewContracts = [ ...this.state.Contracts, ...NewContracts ];

            this.setState({ ContractsIsMoreResults, Contracts: NewContracts, ContractsTotalRecords, LoadedContracts: true });
        }));
    }

    onLoadHistory = Increment => {
        var { HistoryFilters: { ClientIds, SortId, UserName }, HistoryPageNo, HistoryPageSize } = this.state;

        if (Increment) HistoryPageNo = HistoryPageNo + 1;
        else HistoryPageNo = 1;

        var OrderBy = (SortId === 1 || SortId === 2) ? 'CreatedDate' : 'UserName';
        var OrderType = (SortId === 1 || SortId === 3) ? 'ASC' : 'DESC';

        this.setState({ HistoryPageNo, LoadedHistory: Increment }, () => this.props.SearchTrainerContractsHistory({ ClientIds, PageNo: HistoryPageNo, PageSize: HistoryPageSize, OrderBy, OrderType, UserName }).then(({ IsMoreResults: HistoryIsMoreResults, Contracts: NewHistory, TotalRecords: HistoryTotalRecords }) => {
            if (Increment) NewHistory = [ ...this.state.History, ...NewHistory ];

            this.setState({ HistoryIsMoreResults, History: NewHistory, HistoryTotalRecords, LoadedHistory: true });
        }))
    }

    onRetrieveContractClients = ({ ContractsType }) => {
        if (ContractsType === 'Active') this.props.SearchTrainerContractsClients({ Type: 'Active' }).then(({ Clients }) => this.setState({ Clients_Active: Clients, RetrievedActiveContractClients: true }));
        else if (ContractsType === 'History') this.props.SearchTrainerContractsClients({ Type: 'History' }).then(({ Clients }) => this.setState({ Clients_History: Clients, RetrievedHistoryContractClients: true }));
    }

    onSelectClientId = (event, value, index) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = index === -1;

        if (checked) {
            if (this.state.ContractsType === 'Active') this.setState({ ContractsFilters: { ...this.state.ContractsFilters, ClientIds: [ ...this.state.ContractsFilters.ClientIds, +value ] }}, () => this.onLoadContracts());
            else if (this.state.ContractsType === 'History') this.setState({ HistoryFilters: { ...this.state.HistoryFilters, ClientIds: [ ...this.state.HistoryFilters.ClientIds, +value ] }}, () => this.onLoadHistory());
        } else {
            if (this.state.ContractsType === 'Active') this.setState({ ContractsFilters: { ...this.state.ContractsFilters, ClientIds: [ ...this.state.ContractsFilters.ClientIds.slice(0, index), ...this.state.ContractsFilters.ClientIds.slice(index + 1) ] }}, () => this.onLoadContracts());
            if (this.state.ContractsType === 'History') this.setState({ HistoryFilters: { ...this.state.HistoryFilters, ClientIds: [ ...this.state.HistoryFilters.ClientIds.slice(0, index), ...this.state.HistoryFilters.ClientIds.slice(index + 1) ] }}, () => this.onLoadHistory());
        }
    }

    onSelectContractSort = (value) => {
        if (this.state.ContractsType === 'Active' && +this.state.ContractsFilters.SortId !== +value) this.setState({ ContractsFilters: { ...this.state.ContractsFilters, SortId: value } }, () => this.onLoadContracts());
        else if (this.state.ContractsType === 'History' && +this.state.HistoryFilters.SortId !== +value) this.setState({ HistoryFilters: { ...this.state.HistoryFilters, SortId: value } }, () => this.onLoadHistory());
    }

    onToggleContractsType = ContractsType => {
        this.setState({ ContractsType, DisplayingFilter: null });
        if (ContractsType === 'History' && !this.state.LoadedHistory) this.onLoadHistory();
    }

    onToggleDropdown = (DisplayingFilter, ToggleStatus) => {
        this.setState({ DisplayingFilter: ToggleStatus ? DisplayingFilter : null });
    }

    onToggleShowAddContract = ShowAddContract => {
        this.setState({ ShowAddContract });
    }

    onToggleShowDeleteContractModal = ({ DeleteContractId = null, ShowDeleteContractModal = false }) => {
        this.setState({ DeleteContractId, ShowDeleteContractModal });
        
    }

    renderDeleteContractModal = () => {
        var { t } = this.props;
        var { ShowDeleteContractModal } = this.state;

        if (ShowDeleteContractModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteContract}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    Information={t('contract_delete_warning')}
                    Loading={this.props.TryingDeleteWorkoutTemplates}
                    OnHide={() => this.onToggleShowDeleteContractModal({ ShowDeleteContractModal: false })}
                    Show={ShowDeleteContractModal}
                />
            );
        }
    }

    renderFilters = () => {
        var { t } = this.props;
        var { Device, TryingSearchTrainerContracts, TryingSearchTrainerContractsClients } = this.props;
        var { Clients_Active, Clients_History, ContractsFilters, ContractsType, DisplayingFilter, HistoryFilters, OpenFilterSize, RetrievedActiveContractClients, RetrievedHistoryContractClients } = this.state;

        var SortingOptions = [{ SortId: 1, SortName: t('sort_oldest_newest') }, { SortId: 2, SortName: t('sort_newest_oldest') }, { SortId: 3, SortName: t('sort_alphabetical') }];

        return (
            <FiltersContainer className="FiltersContainer">
                <FilterSubContainer className="FilterSubContainer">
                    <StyledSearchInput
                        FontSize="medium-2"
                        NoLabel
                        NoMargin
                        OnChange={event => this.onChangeSearchUsername({ event, ContractsType })}
                        OnKeyPress={this.onKeyPressUsername}
                        Placeholder={t('search_by_item', { SearchItem: t('username').toLowerCase()})}
                        Size="medium"
                        Type="text"
                        Value={ContractsType === 'Active' ? ContractsFilters.UserName : HistoryFilters.UserName}
                    />
                </FilterSubContainer>
                <FilterSubContainer className="FilterSubContainer">
                    <StyledDropdown
                        Disabled={TryingSearchTrainerContracts}
                        ForceClose={DisplayingFilter !== 'ContractsSort'}
                        GetSize={Height => this.onGetSize(Height)}
                        Height={DisplayingFilter === 'ContractsSort' ? OpenFilterSize : null}
                        HideCount
                        IsMobile={Device !== 'laptop'}
                        ItemId="SortId"
                        ItemName="SortName"
                        Items={SortingOptions}
                        OnToggleDropdown={ToggleStatus => this.onToggleDropdown('ContractsSort', ToggleStatus)}
                        OnSelectItem={(event, value, index) => this.onSelectContractSort(value)}
                        SelectedItemIds={[ ContractsType === 'Active' ? ContractsFilters.SortId : HistoryFilters.SortId ]}
                        Title={SortingOptions[(ContractsType === 'Active' ? ContractsFilters.SortId : HistoryFilters.SortId) - 1].SortName}
                    />
                </FilterSubContainer>
                <FilterSubContainer className="FilterSubContainer">
                    <StyledDropdown
                        Disabled={TryingSearchTrainerContractsClients || TryingSearchTrainerContracts}
                        ForceClose={DisplayingFilter !== 'ClientsSearch'}
                        GetSize={Height => this.onGetSize(Height)}
                        Height={DisplayingFilter === 'ClientsSearch' ? OpenFilterSize : null}
                        IsMobile={Device !== 'laptop'}
                        ItemId="UserId"
                        ItemName="DisplayName"
                        Items={ContractsType === 'Active' ? Clients_Active : Clients_History}
                        Loading={TryingSearchTrainerContractsClients}
                        Name={ContractsType}
                        NeedsToLoad={ContractsType === 'Active' ? !RetrievedActiveContractClients : !RetrievedHistoryContractClients}
                        NeedsToLoadFunc={() => this.onRetrieveContractClients({ ContractsType })}
                        OnToggleDropdown={ToggleStatus => this.onToggleDropdown('ClientsSearch', ToggleStatus)}
                        OnSelectItem={(event, value, index) => this.onSelectClientId(event, value, index)}
                        SelectedItemIds={ContractsType === 'Active' ? ContractsFilters.ClientIds : HistoryFilters.ClientIds}
                        Title={t('choose_clients')}
                    />
                </FilterSubContainer>
            </FiltersContainer>
        );
    }

    render() {
        var { t } = this.props;
        var { Device, TryingDeleteContract, TryingSearchTrainerContracts } = this.props;
        var { Contracts, ContractsIsMoreResults, ContractsPageNo, ContractsPageSize, ContractsTotalRecords, ContractsType, History, HistoryIsMoreResults, HistoryPageNo, HistoryPageSize, HistoryTotalRecords, LoadedContracts, LoadedHistory } = this.state;

        var ButtonData = { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white' };

        return (
            <>
                <div>
                    {((ContractsType === 'Active' && !LoadedContracts) || (ContractsType === 'History' && !LoadedHistory) || TryingDeleteContract) && <Loading />}

                    <ButtonGroup
                        ButtonWidth="fit-content"
                        Buttons={[
                            { ...ButtonData, OnClick: () => this.onToggleContractsType('Active'), Selected: ContractsType === 'Active', Title: t('active') },
                            { ...ButtonData, OnClick: () => this.onToggleContractsType('History'), Selected: ContractsType === 'History', Title: t('history') }
                        ]}
                    />

                    <Spacer Size="medium" />

                    <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="center" NoMargin Text={ContractsType === 'Active' ? t('active') : t('history')} TextAlign="center" />

                    <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text={ContractsType === 'Active' ? t('contracts_active_description') : t('contracts_history_description')} TextAlign="center" />

                    {
                        ContractsType === 'Active' &&
                        <>
                            <Spacer Size="small" />

                            <ButtonGroup
                                Buttons={[{ Icon: Plus, IconPosition: 'left', FontFamily: 'semibold', FontSize: 'medium-1', OnClick: this.onGoToCreateContract, Size: 'small', Title: t('contracts_create') }]}
                                ButtonWidth="fit-content"
                                ContainerWidth={Device === 'mobile' || Device === 'mobile_small' ? '100%' : null}
                            />
                        </>
                    }

                    <Spacer Size="medium" />

                    {this.renderFilters()}

                    <Spacer Size="medium" />

                    <StyledLoadMore
                        ActiveSearch
                        CompletedFirstSearch
                        IsLoading={TryingSearchTrainerContracts}
                        ItemDescriptionType={t('contracts').toLowerCase()}
                        ItemId="ContractId"
                        ItemName="ContractDate"
                        ItemRenderer={ContractListItem}
                        ItemProps={{ DeleteContract: this.onDeleteContract, ToggleShowDeleteContractModal: this.onToggleShowDeleteContractModal }}
                        Items={ContractsType === 'Active' ? Contracts : History}
                        HasMoreItems={ContractsType === 'Active' ? !!+ContractsIsMoreResults : !!+HistoryIsMoreResults}
                        LoadMore={ContractsType === 'Active' ? () => this.onLoadContracts(true) : () => this.onLoadHistory(true)}
                        NewSearch={false}
                        NoItemsText={t('no_contracts')}
                        PageNo={ContractsType === 'Active' ? ContractsPageNo : HistoryPageNo}
                        PageSize={ContractsType === 'Active' ? ContractsPageSize : HistoryPageSize}
                        TotalRecords={ContractsType === 'Active' ? ContractsTotalRecords : HistoryTotalRecords}
                    />
                </div>

                {this.renderDeleteContractModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        
        TryingDeleteContract: state.Sales.TryingDeleteContract,
        TryingDeleteContractError: state.Sales.TryingDeleteContractError,
        TryingSearchTrainerContracts: state.Sales.TryingSearchTrainerContracts,
        TryingSearchTrainerContractsError: state.Sales.TryingSearchTrainerContractsError,
        TryingSearchTrainerContractsClients: state.Sales.TryingSearchTrainerContractsClients,
        TryingSearchTrainerContractsClientsError: state.Sales.TryingSearchTrainerContractsClientsError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearSaveData, DeleteContract, SearchTrainerContracts, SearchTrainerContractsClients, SearchTrainerContractsHistory, SearchTrainerContractsHistoryClients } )(Contracts));