import {
    TRYING_GET_DATACENTER_DASHBOARD,
    TRYING_GET_DATACENTER_DASHBOARD_SUCCESS,
    TRYING_GET_DATACENTER_DASHBOARD_ERROR,

    TRYING_GET_DATACENTER_DASHBOARD_FILTERS,
    TRYING_GET_DATACENTER_DASHBOARD_FILTERS_SUCCESS,
    TRYING_GET_DATACENTER_DASHBOARD_FILTERS_ERROR
} from '../Types';

const INITIAL_STATE = {
    TryingGetDataCenterDashboard: false,
    TryingGetDataCenterDashboardError: null,
    TryingGetDataCenterDashboardFilters: false,
    TryingGetDataCenterDashboardFiltersError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_GET_DATACENTER_DASHBOARD:
            return { ...state, TryingGetDataCenterDashboard: true, TryingGetDataCenterDashboardError: null };
        case TRYING_GET_DATACENTER_DASHBOARD_ERROR:
            return { ...state, TryingGetDataCenterDashboard: false, TryingGetDataCenterDashboardError: action.payload };
        case TRYING_GET_DATACENTER_DASHBOARD_SUCCESS:
            return { ...state, TryingGetDataCenterDashboard: false, TryingGetDataCenterDashboardError: null };

        case TRYING_GET_DATACENTER_DASHBOARD_FILTERS:
            return { ...state, TryingGetDataCenterDashboard: true, TryingGetDataCenterDashboardError: null };
        case TRYING_GET_DATACENTER_DASHBOARD_FILTERS_ERROR:
            return { ...state, TryingGetDataCenterDashboard: false, TryingGetDataCenterDashboardError: action.payload };
        case TRYING_GET_DATACENTER_DASHBOARD_FILTERS_SUCCESS:
            return { ...state, TryingGetDataCenterDashboard: false, TryingGetDataCenterDashboardError: null };
        default:
            return state;
    }
};
