import React from 'react';
import PropTypes from 'prop-types';

import { Container, SpanText, TextsContainer } from './styles';

class MultiText extends React.Component {
    render() {
        var { AddMargin, className, ContainerWidth, FontColorHover: ContainerFontColorHover, TextAlign, Texts } = this.props;

        return (
            <Container
                className={className}
                AddMargin={AddMargin}
                ContainerWidth={ContainerWidth}
                FontColorHover={ContainerFontColorHover}
                TextAlign={TextAlign}
            >
                <TextsContainer>
                    {
                        Texts.map(({ FontColor, FontColorHex, FontColorHover, FontFamily, FontSize, OnClick, Text, Underline, WhiteSpace }, index) => {
                            return (
                                <SpanText
                                    key={index}
                                    Clickable={OnClick ? true : false}
                                    FontColor={FontColor}
                                    FontColorHex={FontColorHex}
                                    FontColorHover={FontColorHover}
                                    FontFamily={FontFamily}
                                    FontSize={FontSize}
                                    onClick={OnClick ? OnClick : null}
                                    Underline={Underline}
                                    WhiteSpace={WhiteSpace}
                                >
                                    {index !== 0 && <>&nbsp;</>}
                                    {Text}
                                </SpanText>
                            );
                        })
                    }
                </TextsContainer>
            </Container>
        );
    }
}

MultiText.propTypes = {
    AddMargin: PropTypes.bool,
    ContainerWidth: PropTypes.string,
    FontColorHover: PropTypes.string,
    TextAlign: PropTypes.string,
    Texts: PropTypes.array.isRequired
};

export default MultiText;