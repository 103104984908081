import React from 'react';
import queryString from 'query-string';

import * as styles from './styles';

import Page from '../../Components/Page';

import Header from './Header';

import Companies from './Companies';
import DeliveryTracking from './DeliveryTracking';
import DHCs from './DHCs';
import Employees from './Employees';
import Leaders from './Leaders';
import MiddleLeaders from './MiddleLeaders';
import Orders from './Orders';
import Sales from './Sales';
import TopLeaders from './TopLeaders';

const LINKS = {
    'top-leaders': 'TopLeaders',
    'middle-leaders': 'MiddleLeaders',
    'leaders': 'Leaders',
    'companies': 'Companies',
    'dhcs': 'DHCs',
    'employees': 'Employees',
    'orders': 'Orders',
    'delivery-tracking': 'DeliveryTracking',
    'sales': 'Sales'
}

class DeliverySite extends React.Component {
    state = {
        PageName: null
    }

    componentDidMount() {
        this._isMounted = true;

        this.getPageName();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            this.getPageName();
        }
    }

    getPageName = () => {
        const QueryValues = queryString.parse(this.props.location.search);

        var PageName;

        if (Object.entries(QueryValues).length > 0 && QueryValues.tab) PageName = LINKS[QueryValues.tab.toLowerCase()];

        PageName = PageName || 'Orders';

        this.setState({ PageName });
    }

    render() {
        var { PageName } = this.state;

        return (
            <Page NoMargin NoPadding>
                <styles.MainContainer className="MainContainer">
                    <Header PageName={PageName} />

                    {PageName === 'Companies' && <Companies />}
                    {PageName === 'DeliveryTracking' && <DeliveryTracking />}
                    {PageName === 'DHCs' && <DHCs />}
                    {PageName === 'Employees' && <Employees />}
                    {PageName === 'Leaders' && <Leaders />}
                    {PageName === 'MiddleLeaders' && <MiddleLeaders />}
                    {PageName === 'Orders' && <Orders />}
                    {PageName === 'Sales' && <Sales />}
                    {PageName === 'TopLeaders' && <TopLeaders />}
                </styles.MainContainer>
            </Page>
        )
    }
}

export default DeliverySite;