import styled from "styled-components";
import ButtonGroup from "../../Components/Buttons/ButtonGroup";
import Page from "../../Components/Page";
import PageText from "../../Components/Text/PageText";
import Image from "../../Components/Image";
import { device } from "../../Config/device";

export const ActionButton = styled.div`
  align-items: center;
  background-color: ${(props) => props.BackgroundColor};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  margin-left: 10px;

  @media (hover: hover) {
    :hover {
      background-color: ${(props) => props.BackgroundColorHover};
      cursor: pointer;
    }
  }
`;

export const ActionButtonIcon = styled(Image)`
  height: 20px;
  width: ${(props) => (props.ExtraWidth ? "30px" : "20px")};
`;

export const ActiveTab = styled.div`
  display: inline-flex;

  > div {
    margin-right: 15px;
  }
`;

export const ActiveTabsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0 15px;
  width: 100%;
`;

export const AddCallsButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 25px 0;
`;

export const ButtonsContainer = styled(ButtonGroup)`
  justify-content: flex-start;
`;

export const ButtonTitle = styled(PageText)`
  color: var(--white);
  margin: 0 5px;
`;

export const ClientContainer = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  position: relative;
`;

export const ClientListContainer = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
`;

export const HeaderLeft = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 50%;
`;

export const HeaderRight = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 50%;
`;

export const Instructions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  line-height: 40px;
`;

export const InstructionsContainer = styled.div`
  background-color: var(--white-concrete);
  padding: 15px;
`;

export const Pagination = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  ${(props) => !props.HasPrevLink && "margin-left: -10px;"}
`;

export const PageNumberContainer = styled.div`
  align-items: center;
  background-color: ${(props) =>
    props.Selected ? "var(--blue-astronaut)" : "var(--white)"};
  border-radius: 10px;
  color: ${(props) => (props.Selected ? "var(--white)" : "var(--black)")};
  display: flex;
  flex-direction: row;
  font-family: var(--font-family-medium);
  font-size: var(--font-size-medium-1);
  height: 35px;
  justify-content: center;
  width: 35px;

  @media (hover: hover) {
    :hover {
      background-color: var(--blue-astronaut);
      color: var(--white);
      cursor: pointer;
    }
  }
`;

export const PageNumbersContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 10px;

  > div:not(:last-child) {
    margin-right: 10px;
  }
`;

export const PaginationContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${device.maxwidth.mobile_small} {
    flex-direction: column;
    justify-content: center;
  }
`;

export const ScheduledCallsContainer = styled.div`
  background-color: var(--white-concrete);
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  margin: 20px 0;
`;

export const ScheduledCallsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const StyledHR = styled.div`
  background-color: var(--white-concrete);
  border-radius: 10px;
  height: 2px;
  margin: 30px 0px;
`;

export const StyledPage = styled(Page)`
  & .page-maincontentcontainer {
    margin-top: 20px;
    width: 100%;
  }
`;

export const StyledPageText = styled(PageText)`
  margin-left: 15px;
  width: 80%;
  justify-content: flex-start;
`;

export const StyledVideoCallsContainer = styled.div``;

export const UserStatusText = styled(PageText)`
  width: 20%;
  margin: 0 5px;
  color: ${(props) =>
    props.IsAccepted ? "var(--abel-blue)" : "var(--warning)"};
`;

export const VideoCallsHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
