import styled from 'styled-components';

export const Container = styled.div`
    background-image: url('${props => props.ImageSrc}'), url('${props => props.NoImage}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;

    ${props => props.onClick ?
        `:hover { background-color: white; cursor: pointer; }`
    :
        null
    }
`;

export const ImageContainer = styled.div`
    overflow: hidden;
    position: relative;
`;

export const LoadingImage = styled.img`
    height: 0px;
    opacity: 0;
    width: 0px;
`;

export const Overlay = styled.div`
    background-color: var(--white);
    border-radius: 10px;
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0.3;
    position: absolute;
    right: 0;
    top: 0;
    transition: .5s ease;
    width: 100%;

    :hover {
        cursor: pointer;
    }
`;