import styled from 'styled-components';

import ButtonGroup from '../Buttons/ButtonGroup';
import Dropdown from '../Dropdown';
import Input from '../Form/Input';

import Checkmark from '../../Assets/Icons/Checkmark_Selected.png';

export const ActivityContainer = styled.div``;

export const AdditionalActivityItem = styled.div`
    ${
        props => props.Selected ?
        `
            color: var(--blue-abel);
        `
    :
        null
    }

    :not(:last-child) {
        margin-bottom: 5px;
    }

    @media(hover:hover) {
        :hover {
            color: var(--blue-abel);
            cursor: pointer;
        }
    }
`;

export const AdditionalActivityList = styled.div``;

export const Container = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: -30px;
    margin-top: -30px;

    > div {
        margin-right: 30px;
        margin-top: 30px;
    }
`;

export const DateTimeContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
    margin-top: 15px;
    width: 175px;

    > span > input {
        background-color: var(--white-concrete);
    }
`;

export const DateTimeParentContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DateTimesContainer = styled.div`
    align-items: flex-start;
    align-self: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: -15px;
    margin-top: -15px;
`;

export const DateTimeUseAllDays = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    display: block;
    height: 25px;
    margin-right: 5px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const DateTimeUseAllDaysContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 10px;
`;

export const HourType = styled.div`
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    padding: 6px 15px;

    :hover {
        cursor: pointer;
    }
`;

export const HourTypesContainer = styled.div`
    align-items: center;
    border: 2px solid var(--blue-astronaut);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    height: 35px;
    margin: 0 auto;
    overflow: hidden;
    width: fit-content;
`;

export const LocationContainer = styled.div``;

export const StyledDropdown = styled(Dropdown)`
    background-color: var(--white-concrete);
    // border: none;
    margin: 0 auto;
    max-width: 350px;
    width: 100%;

    ${props => props.ForceClose === false ? 'z-index: 1001;' : null};

    ${
        props => props.IsMobile ?
        `
            > div { max-height: none; }
        `
    :
        `
            ${props.Height ? `height: ${props.Height + 45 + 15}px;` : ''}
            margin: 0 auto;
            width: 350px
        `
    }
`;

export const StyledDuration = styled(Input)`
    margin: 0 auto;
    max-width: 100%;
    width: 350px;

    > input {
        background-color: var(--white-concrete);
        border: none;
        border-radius: 10px;
        text-align: center;
        text-indent: 0px;
    }
`;

export const StyledIntensities = styled(ButtonGroup)`
    margin: 0 auto;
    max-width: 100%;
    width: 350px;
`;