import React from 'react';
import { connect } from 'react-redux';
import { CurusLogin, LoginUser } from '../../Services/Actions';
// import history from '../../history';
import queryString from 'query-string';

import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';

class CurusCallback extends React.Component {
    state = { CurusData: {}, Loading: true };

    componentDidMount() {
        const QueryValues = queryString.parse(window.location.search);

        console.log('QueryValues: ', QueryValues);

        this.props.CurusLogin(QueryValues.code).then(({ CurusData }) => {
            this.setState({ CurusData, Loading: false });
        });
    }

    render() {
        if (this.state.Loading) return <Loading />;

        return (
            <Page>
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={JSON.stringify(this.state.CurusData)} TextAlign="center" />
            </Page>
        );
    }
}

export default connect(null, { CurusLogin, LoginUser } )(CurusCallback);