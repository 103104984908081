import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SaveDataInProps, UpdateSelectedTab } from '../../Services/Actions';
import history from '../../history';

import { BreadcrumbLink, BreadcrumbNoLink, Container } from './styles';

class Breadcrumbs extends React.Component {
    onGoToSelectedTab = (event, Props, SelectedTab, LinkURL, Search) => {
        event.preventDefault();
        
        if (SelectedTab) this.props.UpdateSelectedTab(SelectedTab).then(() => history.push({ pathname: `/${LinkURL}`, ...Search ? { search: Search } : {}, state: Props }))
        else if (Props) this.props.SaveDataInProps(Props).then(() => history.push({ pathname: `/${LinkURL}`, ...Search ? { search: Search } : {}, state: Props }))
        else history.push({ pathname: `/${LinkURL}`, state: Props });
    }

    render() {
        return (
            <Container>
                {
                    this.props.Breadcrumbs.map(({ Name, LinkURL, Props, Search, SelectedTab }, index) => {
                        if ((LinkURL || LinkURL === '') && index > 0) return <React.Fragment key={index}> / <BreadcrumbLink onClick={event => this.onGoToSelectedTab(event, Props, SelectedTab, LinkURL, Search)} to={{ pathname: `/${LinkURL}`, state: Props }}>{Name}</BreadcrumbLink></React.Fragment>;
                        else if ((LinkURL || LinkURL === '')) return <React.Fragment key={index}><BreadcrumbLink onClick={event => this.onGoToSelectedTab(event, Props, SelectedTab, LinkURL, Search)} to={{ pathname: `/${LinkURL}`, state: Props }}>{Name}</BreadcrumbLink></React.Fragment>;
                        else if (index > 0) return <React.Fragment key={index}> / <BreadcrumbNoLink>{Name}</BreadcrumbNoLink></React.Fragment>;
                        else return <React.Fragment key={index}><BreadcrumbNoLink>{Name}</BreadcrumbNoLink></React.Fragment>;
                    })
                }
            </Container>
        )
    }
}

Breadcrumbs.propTypes = {
    Breadcrumbs: PropTypes.array.isRequired
}

export default connect(null, { SaveDataInProps, UpdateSelectedTab } )(Breadcrumbs);