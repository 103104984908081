import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { UserAccounts_ChangeEmail, UserAccounts_ChangeSubPrice, UserAccounts_ResetPassword, UserAccounts_SearchUsers, UserAccounts_UndeleteUser, UserAccounts_VerifyUser } from '../../Services/Actions';
import { toast } from 'react-toastify';
import moment from 'moment';

import * as styles from './styles';

import Loading from '../../Components/Loading';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import MultiText from '../../Components/Text/MultiText';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import ProfilePhoto from '../../Components/ProfilePhoto';
import Spacer from '../../Components/Spacer';

import Info from '../../Modals/Info';

import DownArrow from '../../Assets/Icons/DownArrow.png';
import UpArrow from '../../Assets/Icons/UpArrow.png';

import { DynamicSort, emailValidator } from '../../Functions';

const TABLE_COLUMNS = [
    { Id: 1, SortCol: 'UserId', Title: 'User Id' },
    { Id: 2, SortCol: 'FirstName', Title: 'First Name' },
    { Id: 3, SortCol: 'LastName', Title: 'Last Name' },
    { Id: 4, SortCol: 'Email', Title: 'Email' },
    { Id: 5, SortCol: 'DTOR', Title: 'Register Date' },
    { Id: 6, SortCol: 'OrganizationId', Title: 'Is Organization' },
    { Id: 7, SortCol: 'IsCoach', Title: 'IsCoach' },
    { Id: 8, SortCol: 'SubPrice', Title: 'Subscription Price' },
    { Id: 9, SortCol: 'IsActiveSub', Title: 'Subscription Status' },
    { Id: 10, SortCol: 'FreeTrial', Title: 'Free Trial Status' },
    { Id: 11, SortCol: 'BonusDaysLeft', Title: 'Bonus Days Left' },
    { Id: 13, SortCol: 'IsVerified', Title: 'Verified' },
    { Id: 14, SortCol: 'IsPreRegistered', Title: 'Pre Registered' },
    { Id: 15, SortCol: 'IsAccountDeleted', Title: 'Deleted' }
]

class UserAccounts extends React.Component {
    state = {
        ChangeEmail: '',
        ChangeSubPrice: '',
        DisplayName: '',
        ShowChangeEmail: false,
        ShowChangeSubPrice: false,
        ShowUserDetailsModal: false,
        Sorting: {
            SortCol: 'UserId',
            SortDir: 'ASC'
        },
        UserDetailsModal: { User: {}, UserIndex: null },
        Users: []
    }

    onChangeEmail = ({ ChangeEmail, Email, UserIndex }) => {
        this.props.UserAccounts_ChangeEmail({ Email, NewEmail: ChangeEmail }).then(({ Response }) => {
            if (!!Response) {
                var Users = [ ...this.state.Users ];
                Users[UserIndex].Email = ChangeEmail;

                var UserDetailsModal = { ...this.state.UserDetailsModal };
                UserDetailsModal.User.Email = ChangeEmail;

                this.setState({ ShowChangeEmail: false, UserDetailsModal, Users }, () => {
                    toast.success('User Email Changed Successfully!', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            }
        })
    }

    onChangeSubPrice = ({ ChangeSubPrice, IsCoach, UserId, UserIndex }) => {
        this.props.UserAccounts_ChangeSubPrice({ IsCoach, SubPrice: ChangeSubPrice, UserId }).then(({ Response }) => {
            if (!!Response) {
                var Users = [ ...this.state.Users ];
                Users[UserIndex][!IsCoach ? 'CustomAdminSubPrice' : 'SubPrice'] = ChangeSubPrice;

                var UserDetailsModal = { ...this.state.UserDetailsModal };
                UserDetailsModal.User[!IsCoach ? 'CustomAdminSubPrice' : 'SubPrice'] = ChangeSubPrice;

                this.setState({ ShowChangeSubPrice: false, UserDetailsModal, Users }, () => {
                    toast.success('User Price Changed Successfully!', {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            }
        })
    }

    onChangeEmailText = event => {
        this.setState({ ChangeEmail: event.target.value });
    }

    onChangeSubPriceText = event => {
        this.setState({ ChangeSubPrice: event.target.value });
    }

    onChangeUsersSearch = event => {
        this.setState({ DisplayName: event.target.value });
    }

    onCloseUserDetailsModal = () => {
        this.setState({ ChangeEmail: '', ShowChangeEmail: false, ShowUserDetailsModal: false, UserDetailsModal: { User: {}, UserIndex: null } });
    }

    onHideChangeEmail = () => {
        this.setState({ ShowChangeEmail: false });
    }

    onHideChangeSubPrice = () => {
        this.setState({ ShowChangeSubPrice: false });
    }

    onOpenUserDetailsModal = ({ User, UserIndex }) => {
        this.setState({ ShowUserDetailsModal: true, UserDetailsModal: { User, UserIndex } });
    }

    onResetPassword = ({ UserId }) => {
        this.props.UserAccounts_ResetPassword({ Password: 'abel', UserId }).then(() => {
            toast.success('User Password Reset Successfully!', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }

    onSearchUsers = () => {
        this.props.UserAccounts_SearchUsers({ DisplayName: this.state.DisplayName }).then(({ Users }) => {
            this.setState({ Sorting: { SortCol: 'UserId', SortDir: 'ASC' }, Users });
        });
    }

    onShowChangeEmail = () => {
        this.setState({ ChangeEmail: '', ShowChangeEmail: true });
    }

    onShowChangeSubPrice = () => {
        var { CustomAdminSubPrice, IsCoach, SubPrice } = this.state.UserDetailsModal.User;

        this.setState({ ChangeSubPrice: !IsCoach ? CustomAdminSubPrice : SubPrice, ShowChangeSubPrice: true });
    }

    onToggleSorting = ({ SortCol: NewSortCol }) => {
        if (!this.state.Users.length) return null;

        var { SortCol, SortDir } = this.state.Sorting;

        // SortDir Goes From ASC --> DESC --> Nothing

        // Toggle Sorting Of Already Sorted Column
            if (SortCol === NewSortCol) {
                if (SortDir === 'ASC') SortDir = 'DESC';
                else if (SortDir === 'DESC') {
                    SortDir = '';
                    SortCol = '';
                }
                else if (SortDir === '') SortDir = 'ASC';
            }
        // Start Sorting On New Column
            else {
                SortCol = NewSortCol;
                SortDir = 'ASC';
            }

        // Sort and Add Empty Object In Again
            var NewUsers = [ ...this.state.Users.sort(DynamicSort({ SortCol, SortDir })) ];

        this.setState({ Users: NewUsers, Sorting: { SortCol, SortDir }})
    }

    onUndeleteUser = ({ UserIndex, UserId }) => {
        this.props.UserAccounts_UndeleteUser({ UserId }).then(() => {
            var Users = [ ...this.state.Users ];
            Users[UserIndex].IsAccountDeleted = 0;

            var UserDetailsModal = { ...this.state.UserDetailsModal };
            UserDetailsModal.User.IsAccountDeleted = 0;

            this.setState({ UserDetailsModal, Users }, () => {
                toast.success('User Undeleted Successfully!', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        })
    }

    onVerifyUser = ({ UserIndex, UserId }) => {
        this.props.UserAccounts_VerifyUser({ UserId }).then(() => {
            var Users = [ ...this.state.Users ];
            Users[UserIndex].IsVerified = 1;

            var UserDetailsModal = { ...this.state.UserDetailsModal };
            UserDetailsModal.User.IsVerified = 1;

            this.setState({ UserDetailsModal, Users }, () => {
                toast.success('User Verified Successfully!', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        })
    }

    renderUserDetailsModalInformation = () => {
        var { ChangeEmail, ChangeSubPrice, ShowChangeEmail, ShowChangeSubPrice, UserDetailsModal: { User, UserIndex } } = this.state;

        var { CustomAdminSubPrice, DisplayName, Email, IsAccountDeleted, IsCoach, IsPreRegistered, IsVerified, Sex, SubPrice, UserId, UserPhoto } = User;

        return (
            <styles.UserDetailModalContainer className="UserDetailModalContainer">
                <styles.UserDetailModal_UserName className="UserDetailModal_UserName">
                    <styles.ProfilePhotoContainer className="ProfilePhotoContainer">
                        <ProfilePhoto Sex={Sex || 'Male'} Size="small-3" Source={UserPhoto || ''} />
                    </styles.ProfilePhotoContainer>

                    <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={DisplayName} TextAlign="left" />
                </styles.UserDetailModal_UserName>

                <Spacer Size="small" />

                {
                    !!IsPreRegistered ?
                    <styles.UserDetailModal_UpdateRow className="UserDetailModal_UpdateRow">
                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Pre registered?" TextAlign="left" />
                        
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={!!IsPreRegistered ? 'Yes' : 'No'} TextAlign="left" />
                    </styles.UserDetailModal_UpdateRow>
                :
                    !!IsAccountDeleted ?
                    <styles.UserDetailModal_UpdateRow className="UserDetailModal_UpdateRow">
                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Deleted Account?" TextAlign="left" />
                        
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={!!IsAccountDeleted ? 'Yes' : 'No'} TextAlign="left" />

                        {!!IsAccountDeleted && <PageText FontColor="abel-blue" FontColorHover="abel-blue-hover" FontFamily="semibold-italic" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onUndeleteUser({ UserIndex, UserId })} Text="Undelete account" TextAlign="left" />}
                    </styles.UserDetailModal_UpdateRow>
                :
                    !!ShowChangeEmail ?
                    <styles.ShowChangeEmailContainer className="ShowChangeEmailContainer">
                        <MultiText
                            Texts={[
                                { FontFamily: 'semibold', FontSize: 'medium-1', Text: 'Old Email: ' },
                                { FontFamily: 'medium', FontSize: 'medium-1', Text: Email }
                            ]}
                        />

                        <Spacer Size="extra-extra-small" />

                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="New Email:" TextAlign="left" />
                        <styles.ChangeEmailInputContainer className="ChangeEmailInputContainer">
                            <styles.SearchUsersInput
                                FontSize="medium-1"
                                NoLabel
                                NoMargin
                                OnChange={this.onChangeEmailText}
                                Placeholder="New Email"
                                Size="medium"
                                Type="text"
                                Value={ChangeEmail}
                            />

                            <Spacer Size="extra-small" />

                            <ButtonGroup
                                Buttons={[
                                    { Color: 'blue-astronaut', FontFamily: 'semibold', OnClick: this.onHideChangeEmail, Title: 'Back' },
                                    { Color: 'blue-astronaut', Disabled: (!ChangeEmail || Email === ChangeEmail || !emailValidator(ChangeEmail)), FontFamily: 'semibold', OnClick: () => this.onChangeEmail({ ChangeEmail, Email, UserIndex }), Title: 'Change' }
                                ]}
                                ButtonWidth="fit-content"
                                NotTouching
                            />
                        </styles.ChangeEmailInputContainer>
                    </styles.ShowChangeEmailContainer>
                :
                    !!ShowChangeSubPrice ?
                    <styles.ShowChangeEmailContainer className="ShowChangeEmailContainer">
                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={!IsCoach ? 'Custom start up price:' : 'Current price:'} TextAlign="left" />
                        <styles.ChangeEmailInputContainer className="ChangeEmailInputContainer">
                            <styles.SearchUsersInput
                                FontSize="medium-1"
                                NoLabel
                                NoMargin
                                OnChange={this.onChangeSubPriceText}
                                Placeholder="0"
                                Size="medium"
                                Type="text"
                                Value={ChangeSubPrice}
                            />

                            <Spacer Size="extra-small" />

                            <ButtonGroup
                                Buttons={[
                                    { Color: 'blue-astronaut', FontFamily: 'semibold', OnClick: this.onHideChangeSubPrice, Title: 'Back' },
                                    { Color: 'blue-astronaut', Disabled: ((!+ChangeSubPrice && !!IsCoach) || (!!IsCoach && +SubPrice === +ChangeSubPrice) || (!IsCoach && +CustomAdminSubPrice === +ChangeSubPrice)), FontFamily: 'semibold', OnClick: () => this.onChangeSubPrice({ ChangeSubPrice, IsCoach, UserId, UserIndex }), Title: 'Change' }
                                ]}
                                ButtonWidth="fit-content"
                                NotTouching
                            />
                        </styles.ChangeEmailInputContainer>
                    </styles.ShowChangeEmailContainer>
                :
                    <>
                        {/* Email & Change Email */}
                            <styles.UserDetailModal_UpdateRow className="UserDetailModal_UpdateRow">
                                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Email Address" TextAlign="left" />
                                
                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={Email} TextAlign="left" />

                                {<PageText FontColor="abel-blue" FontColorHover="abel-blue-hover" FontFamily="semibold-italic" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={this.onShowChangeEmail} Text="Change Email?" TextAlign="left" />}
                            </styles.UserDetailModal_UpdateRow>

                        {/* Verified */}
                            <styles.UserDetailModal_UpdateRow className="UserDetailModal_UpdateRow">
                                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Verified?" TextAlign="left" />
                                
                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={!!IsVerified ? 'Yes' : 'No'} TextAlign="left" />

                                {!IsVerified && <PageText FontColor="abel-blue" FontColorHover="abel-blue-hover" FontFamily="semibold-italic" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onVerifyUser({ UserIndex, UserId })} Text="Verify now" TextAlign="left" />}
                            </styles.UserDetailModal_UpdateRow>

                        {/* PreRegistered */}
                            <styles.UserDetailModal_UpdateRow className="UserDetailModal_UpdateRow">
                                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Pre registered?" TextAlign="left" />
                                
                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={!!IsPreRegistered ? 'Yes' : 'No'} TextAlign="left" />
                            </styles.UserDetailModal_UpdateRow>

                        {/* Account Deleted */}
                            <styles.UserDetailModal_UpdateRow className="UserDetailModal_UpdateRow">
                                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Deleted Account?" TextAlign="left" />
                                
                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={!!IsAccountDeleted ? 'Yes' : 'No'} TextAlign="left" />

                                {!!IsAccountDeleted && <PageText FontColor="abel-blue" FontColorHover="abel-blue-hover" FontFamily="semibold-italic" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onUndeleteUser({ UserIndex, UserId })} Text="Undelete account" TextAlign="left" />}
                            </styles.UserDetailModal_UpdateRow>

                        {/* Reset Password */}
                            <styles.UserDetailModal_UpdateRow className="UserDetailModal_UpdateRow">
                                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Make password = abel?" TextAlign="left" />
                                
                                {<PageText FontColor="abel-blue" FontColorHover="abel-blue-hover" FontFamily="semibold-italic" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onResetPassword({ UserId })} Text="Reset now" TextAlign="left" />}
                            </styles.UserDetailModal_UpdateRow>

                        {/* Subscription Details */}
                            <styles.UserDetailModal_UpdateRow className="UserDetailModal_UpdateRow">
                                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Subscription Price" TextAlign="left" />
                                
                                {<PageText FontColor="abel-blue" FontColorHover="abel-blue-hover" FontFamily="semibold-italic" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={this.onShowChangeSubPrice} Text={!IsCoach ? `Custom start up price = ${CustomAdminSubPrice}. Edit?` : `Current price = ${SubPrice}. Edit?`} TextAlign="left" />}
                            </styles.UserDetailModal_UpdateRow> 
                    </>
                }
            </styles.UserDetailModalContainer>
        );
    }

    renderUserDetailsModal = () => {
        // var { t } = this.props;
        var { ShowUserDetailsModal } = this.state;

        if (ShowUserDetailsModal) {
            return (
                <Info
                    BottomButton="cancel"
                    InformationRenderer={this.renderUserDetailsModalInformation}
                    Loading={this.props.TryingUserAccountsAction}
                    OnHide={this.onCloseUserDetailsModal}
                    Show={ShowUserDetailsModal}
                />
            );
        }
    }

    renderUserAccounts = () => {
        var { Sorting: { SortCol: StateSortCol, SortDir: StateSortDir }, Users } = this.state;

        return (
            <>
                <Spacer Size="small" />

                <styles.UsersContainer className="UsersContainer">
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${Users.length} users`} />

                    <Spacer Size="small" />

                    <styles.UsersTableContainer className="UsersTableContainer">
                        <styles.UsersTable className="UsersTable">
                            <styles.UsersTableHeader className="UsersTableHeader">
                                <styles.UsersTableHeaderRow className="UsersTableHeaderRow">
                                    {
                                        TABLE_COLUMNS.map(({ Id, SortCol, Title }) => {
                                            return (
                                                <styles.UsersTableHeaderCell className="UsersTableHeaderCell" key={Id} onClick={() => this.onToggleSorting({ SortCol })}>
                                                    <styles.UsersTableHeaderCellInnerDiv className="UsersTableHeaderCellInnerDiv">
                                                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Title} />
                                                        {StateSortCol === SortCol && <styles.SortingIcon ImageSrc={StateSortDir === 'DESC' ? DownArrow : UpArrow} />}
                                                    </styles.UsersTableHeaderCellInnerDiv>
                                                </styles.UsersTableHeaderCell>
                                            );
                                        })
                                    }
                                </styles.UsersTableHeaderRow>
                            </styles.UsersTableHeader>
                            <styles.UsersTableBody className="UsersTableBody">
                                {
                                    Users.map((User, UserIndex) => {
                                        var { BonusDaysLeft, BonusDaysStopDate, CurrencyName, DTOR, Email, FirstName, FreeTrial, IsAccountDeleted, IsActiveSub, IsCoach, IsOrgMember, IsPreRegistered, IsVerified, LastDeactivationDate, LastName, OrganizationId, OrgMembersList, SubPrice, UserId } = User;
                                        return (
                                            <styles.UsersTableBodyRow className="UsersTableBodyRow" key={UserId} onClick={() => this.onOpenUserDetailsModal({ User, UserIndex })}>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {UserId}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {FirstName}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {LastName}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {Email}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {DTOR && moment(DTOR).format('[TrackingHistory]')}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {!!OrganizationId ? 1 : 0}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {IsCoach}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {IsCoach ? IsOrgMember ? '' : `${SubPrice} ${CurrencyName}` : ''}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {(IsCoach || !!OrganizationId) ? IsOrgMember ? `Org Member (${OrgMembersList})` : `${IsActiveSub} ${IsActiveSub === 'Active' ? `(${!!+BonusDaysLeft ? FreeTrial ? 'Free Trial' : 'Bonus Days' : 'Subscription'})` : `(${moment(LastDeactivationDate).format('[NumbersDate]')})`}` : ''}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {(IsCoach && FreeTrial) ? moment(FreeTrial).format('[NumbersDate]') : ''}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {!!+BonusDaysLeft && !FreeTrial ? `${BonusDaysLeft} (${moment(BonusDaysStopDate).format('[NumbersDate]')})` : ''}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {IsVerified}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {IsPreRegistered}
                                                </styles.UsersTableBodyCell>
                                                <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                    {IsAccountDeleted}
                                                </styles.UsersTableBodyCell>
                                            </styles.UsersTableBodyRow>
                                        )
                                    })
                                }
                            </styles.UsersTableBody>
                        </styles.UsersTable>
                    </styles.UsersTableContainer>
                </styles.UsersContainer>
            </>
        );
    }

    render() {
        var { t } = this.props;

        var { DisplayName, ShowUserDetailsModal, Users } = this.state;

        return (
            <>
                {(this.props.TryingUserAccountsAction) && !ShowUserDetailsModal && <Loading />}

                <Page NoMargin>
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text="User Accounts" TextAlign="center" />

                    <Spacer Size="medium" />

                    <styles.SearchUsersInput
                        FontSize="medium-1"
                        NoLabel
                        NoMargin
                        OnBlur={this.onSearchUsers}
                        OnChange={this.onChangeUsersSearch}
                        Placeholder={t('search_for_client')}
                        Size="medium"
                        Type="text"
                        Value={DisplayName}
                    />

                    <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text="Search by first name, last name, or email" />

                    {!!Users.length && this.renderUserAccounts()}
                </Page>

                {this.renderUserDetailsModal()}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        TryingUserAccountsAction: state.UserAccounts.TryingUserAccountsAction,
        TryingUserAccountsActionError: state.UserAccounts.TryingUserAccountsActionError
    };
};

export default withTranslation()(connect(mapStateToProps, { UserAccounts_ChangeEmail, UserAccounts_ChangeSubPrice, UserAccounts_ResetPassword, UserAccounts_SearchUsers, UserAccounts_UndeleteUser, UserAccounts_VerifyUser })(UserAccounts));