import React from 'react';
import styled from 'styled-components';

import Input from './Components/Form/Input';
import PageText from './Components/Text/PageText';
import Spacer from './Components/Spacer';

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 25px;
`;

const StyledInput = styled(Input)`
    width: 200px;

    :not(:last-child) {
        margin-right: 20px;
    }
`;

const GetRoundAmount = ({ Units, Weight }) => {
    var RoundAmount = null;

    if (Units === 'metric') {
        if (Weight < 10) RoundAmount = 1;
        else RoundAmount = 2.5;
    }

    if (Units === 'imperial') {
        if (Weight < 20) RoundAmount = 2.5;
        else RoundAmount = 5;
    }

    return RoundAmount;
}

const roundWeight = (Units, Weight, RoundDirection = 'down') => {
    var RoundAmount = GetRoundAmount({ Units, Weight });

    if (RoundDirection === 'up') return (Math.ceil(Weight / RoundAmount) * RoundAmount) || (Units === 'metric' ? 1 : 2.5);

    return (Math.floor(Weight / RoundAmount) * RoundAmount) || (Units === 'metric' ? 1 : 2.5);
}

const CalculatedNewRecordRepsOrWeight = ({ DataType, Max, Reps, Units, Weight }) => {
    // DataType Is One Of [ "dynamic-all", "dynamic-reps", "dynamic-weight" ]
    var NewRecordReps = null;
    var NewRecordWeight = null;

    var Explanation = [];

    Max = +Max;
    Reps = +Reps;
    Weight = +Weight;

    // Weight Is Percent, Reps Are Not Provided. Calculate Weight To Be Static Value, And Perform Same Logic As "dynamic-reps"
    // Round Weight Up To Nearest 2.5 or 5 based on Units
        if (DataType === 'dynamic-all') Weight = roundWeight(Units, ((Weight / 100) * Max), 'up');

    // Need To Calculate Reps From A Static Weight
        if (DataType === 'dynamic-all' || DataType === 'dynamic-reps') {
            NewRecordReps = Math.ceil(30 * ((Max / Weight) - 1));
            
            Explanation.push(`Using formula of "Math.ceil(30 * ((Max / Weight) - 1))", we get "Math.ceil(30 * ((${Max} / ${Weight}) - 1))", which equals ${NewRecordReps} for the reps value`);

            if (NewRecordReps <= 0) {
                NewRecordReps = 1;

                Explanation.push(`Reps <= 0, so we set the Reps = 1`);
            }
            // If Reps === 1, Check If Weight > Max, Else Add 1 Rep
                if (NewRecordReps === 1 && (Weight <= Max)) {
                    NewRecordReps += 1;

                    Explanation.push(`The reps === 1 AND the Weight input <= Max, so we increment the Reps by 1`);
                }

            Explanation.push(`We need to calculate what the new calculated max will be, and compare (using 2 decimal places) to make sure that our new calculated values are bigger. To do this we will take this formula: "(Math.round((Weight * (1 + (NewRecordReps / 30))) / 0.01) * 0.01)", like this: "(Math.round((${Weight} * (1 + (${+NewRecordReps} / 30))) / 0.01) * 0.01)", which gives us ${(Math.round((Weight * (1 + (+NewRecordReps / 30))) / 0.01) * 0.01)} and compare this to the Max, which is ${Max}`);

            // Round to 2 decimal places and compare if equal to Max
                if ((Math.round((Weight * (1 + (NewRecordReps / 30))) / 0.01) * 0.01) === Max) {
                    NewRecordReps += 1;

                    Explanation.push(`The calculated max from our values === Max, so we need to increment the reps by 1`);
                } else Explanation.push(`The calculated max from our values was not equal to Max, so we do not need to make any changes`);

            NewRecordWeight = Weight;
        }
    // Need To Calculate Weight From Static Reps
        else if (DataType === 'dynamic-weight') {
            NewRecordReps = +Reps;

            Explanation.push(`Check if Reps <= 0. If it is, then set it equal to 1`);
            if (NewRecordReps <= 0) {
                NewRecordReps = 1;
                Explanation.push('Reps <= 0, so set it to 1');
            }

            // If 1 Rep Only, Set Weight = Rounded Up Of Current Max
                if (NewRecordReps === 1) {
                    Explanation.push(`If the reps equal 1, then we need to round the max weight up to the nearest smallest increment`);
                    NewRecordWeight = roundWeight(Units, Max, 'up');// (Max + GetRoundAmount({ Units, Weight: Max }));

                    Explanation.push(`In this case, that gives us an amount of ${NewRecordWeight}. We will compare if this is <= Max.`);

                    if (NewRecordWeight <= Max) {
                        Explanation.push(`Because ${NewRecordWeight} <= ${Max}, we need to increment the NewRecordWeight by the smallest increment, which gives us ${NewRecordWeight + GetRoundAmount({ Units, Weight: NewRecordWeight })}`);
                        NewRecordWeight += GetRoundAmount({ Units, Weight: NewRecordWeight });
                    } else Explanation.push('The new rounded weight > Max, so this will become our new weight for the user');
                }
            // Calculate New Weight Based on Static Reps, And Round Up
                else {
                    Explanation.push(`The reps are not equal to 1, so we will start by calculating the newly needed weight based on the reps from this formula: "Max / (1 + (Reps / 30))", which gives us "${Max} / (1 + (${Reps} / 30)) = ${Max / (1 + (Reps / 30))}"`);
                    NewRecordWeight = roundWeight(Units, (Max / (1 + (Reps / 30))), 'up');
                    Explanation.push(`Then we round this weight up to the nearest increment, to give us: ${NewRecordWeight}`);

                    // If NewRecordWeight calculated at Reps = Max, Then Increase NewRecordWeight By Smallest Amount
                    // Round to 2 decimal places and compare if equal to Max
                        Explanation.push(`We need to calculate what the new calculated max will be, and compare (using 2 decimal places) to make sure that our new calculated values are bigger. To do this we will take this formula: "(Math.round((Weight * (1 + (NewRecordReps / 30))) / 0.01) * 0.01)", like this: "(Math.round((${NewRecordWeight} * (1 + (${NewRecordReps} / 30))) / 0.01) * 0.01)", which gives us ${(Math.round((NewRecordWeight * (1 + (NewRecordReps / 30))) / 0.01) * 0.01)} and compare this to the Max, which is ${Max}`);
                        if ((Math.round((NewRecordWeight * (1 + (Reps / 30))) / 0.01) * 0.01) === Max) {
                            NewRecordWeight += GetRoundAmount({ Units, Weight: NewRecordWeight });
                            Explanation.push(`The calculated amount using our values === Max, so we need to increment the weight by the smallest increment`);
                        }
                }
        }

    return { Explanation, Reps: NewRecordReps, Weight: NewRecordWeight };
}

class CalculateMax extends React.Component {
    state = {
        Explanation: [],
        Max: 436.25,
        Reps: 0,
        Units: 'metric',
        Weight: 330
    }

    componentDidMount() {
        var { Explanation, Reps, Weight } = CalculatedNewRecordRepsOrWeight({ DataType: 'dynamic-reps', Max: this.state.Max, Weight: this.state.Weight, Units: this.state.Units });

        this.setState({ Explanation, Reps, Weight });
    }

    onChangeMax = event => {
        var Max = event.target.value;

        this.setState({ Max }, () => {
            var { Explanation, Reps, Weight } = CalculatedNewRecordRepsOrWeight({ DataType: 'dynamic-reps', Max, Weight: this.state.Weight, Units: this.state.Units });

            this.setState({ Explanation, Reps, Weight });
        });
    }

    onChangeReps = event => {
        var Reps = event.target.value;

        this.setState({ Reps }, () => {
            var { Explanation, Weight } = CalculatedNewRecordRepsOrWeight({ DataType: 'dynamic-weight', Max: this.state.Max, Reps, Units: this.state.Units })

            this.setState({ Explanation, Weight });
        });
    }

    onChangeWeight = event => {
        var Weight = event.target.value;

        this.setState({ Weight }, () => {
            var { Explanation, Reps } = CalculatedNewRecordRepsOrWeight({ DataType: 'dynamic-reps', Max: this.state.Max, Weight, Units: this.state.Units })
            this.setState({ Explanation, Reps });
        });
    }

    render() {
        var { Explanation, Max, Reps, Weight } = this.state;

        var CalculatedMax = +Reps === 1 ? Weight : (Weight * (1 + (Reps / 30)));

        var FontSizes = [ 35, 30, 25, 20, 18, 16, 14 ];

        return (
            <>
                <Container>
                    <StyledInput Label="Max" OnChange={this.onChangeMax} Type="number" Value={Max} />

                    <Spacer Size="small" />

                    <StyledInput Label="Reps" OnChange={this.onChangeReps} Type="number" Value={Reps} />

                    <Spacer Size="small" />

                    <StyledInput Label="Weight" OnChange={this.onChangeWeight} Type="number" Value={Weight} />
                </Container>

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`Calculated Max: ${CalculatedMax}`} TextAlign="left" />

                <Spacer Size="small" />

                {
                    Explanation.length > 0 &&
                    <>
                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Explanation" TextAlign="left" />

                        <Spacer Size="extra-small" />

                        {
                            Explanation.map((Explained, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={Explained} TextAlign="left" />

                                        {
                                            index !== Explanation.length - 1 && <Spacer Size="extra-small" />
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </>
                }

                <Spacer Size="extra-large" />

                {FontSizes.map((FontSize, index) => <p key={index} style={{ fontSize: `${FontSize}px` }}>Example Text - {FontSize}px</p>)}
            </>
        );
    }
}

export default CalculateMax;