import styled from 'styled-components';
import { device } from '../../Config/device';

import Icon from '../../Components/Icon';
import Image from '../../Components/Image';
import Page from '../../Components/Page';

export const AnnotationContainer = styled.div``;

export const AnnotationsContainer = styled.div``;

export const DocumentContainer = styled.a`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    color: var(--black);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    width: 100%;

    :not(:last-child) {
        margin-bottom: 10px;
    }

    @media(hover:hover) {
        :hover {
            border-color: var(--blue-abel);
            color: var(--blue-abel);
            cursor: pointer;
        }
    }
`;

export const DocumentIcon = styled.img`
    height: 25px;
`;

export const DocumentPlatformContainer = styled.div`
    align-items: center;
    // border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding: 15px 20px;
`;

export const DocumentPlatformsContainer = styled.div`
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 1fr;

    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(${props => props.NumPlatforms}, 1fr);
    }
`;

export const LearnAbelAdminChildContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
        justify-content: flex-start;
    }
`;

export const LearnAbelAdminChildContainer2 = styled.div``;

export const LearnAbelAdminContainer = styled.div`
    align-items: flex-start;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 15px;
`;

export const LearnAbelFacebookGroupsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -15px;
    margin-top: -15px;
`;

export const LearnAbelFacebookImage = styled(Image)`
    margin-right: 15px;
    margin-top: 15px;

    max-width: 100%;
    width: 225px;
`;

export const MenuButtons = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const NewFeaturesContainer = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    padding: 10px 15px;
`;

export const PlatformDifferencesContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 15px;
`;

export const PlatformDifferencesPicture = styled(Image)`
    max-width: 100%;
`;

export const PlatformIconsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 15px;
`;

export const PlatformTextsContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
`;

export const StyledCustomerLoveIcon = styled(Icon)`
    margin-right: 10px;

    > img {
        max-width: 95px;
        min-width: 65px;
    }
`;

export const StyledNewFeaturesImage = styled(Image)`
    max-width: 100%;
`;

export const StyledPage = styled(Page)``;

export const StyledPlatformIcon = styled(Icon)`
    max-width: 45px;
`;

export const SubContainer = styled.div``;

export const VideoContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    :not(:last-child) {
        margin-bottom: 20px;
    }
`;

export const VideoDetailContainer = styled.div`
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;