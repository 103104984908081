import styled from 'styled-components';
import { Link } from 'react-router-dom';

import LoadingCircle from '../../Charts/LoadingCircle';
import PageText from '../../Text/PageText';

import Checkmark from '../../../Assets/Icons/Checkmark_Selected.png';

export const AddButtonImage = styled.img`
    height: 20px;
    width: 20px;
`;

export const ClockIcon = styled.img`
    height: 16px;
    margin-right: 5px;
    width: 16px;
`;

export const DayHeaderContainer = styled.div`
    align-items: center;
    background-color: ${props => props.IsToday ? 'var(--blue-astronaut)' : 'var(--white-concrete)'};
    color: ${props => props.IsToday ? 'var(--white)' : 'var(--black)'};
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;

    ${props => !props.IsCurrentMonth && 'opacity: var(--opacity-low);'}

    ${({ Hover, IsToday }) => (
        Hover ?
            `:hover {
                background-color: ${IsToday ? 'var(--blue-astronaut-hover)' : 'var(--white-concrete-hover)'};
                cursor: pointer;
            }`
        :
            null
    )}
`;

export const DurationContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    > img {
        max-height: 17px !important;
        max-width: 17px !important;
    }

    > svg {
        max-height: 17px !important;
        max-width: 17px !important;
    }
`;

export const EventContainer = styled.div`
    ${props => !props.IsCurrentMonth && 'opacity: var(--opacity-low);'}

    position: relative;

    @media(hover:hover) {
        :hover {
            ${props => props.Toggleable && 'cursor: pointer;'}
        }
    }
`;

// export const EventImageContainer = styled.div`
export const EventImageContainer = styled(Link)`
    border-radius: 10px;
    color: var(--black);
    display: block;
    position: relative;

    :hover {
        background-color: var(--white-concrete-hover);
        cursor: pointer;
    }

    img {
        ${props => props.completed && `border: 3px solid var(--${props.completed === 'complete' ? 'abel-blue' : props.completed === 'incomplete' ? 'red-bittersweet' : 'yellow-medium'});`}
        border-radius: 10px;
        display: block;
        max-width: 100%;
        height: 125px;
        width: 100%;
        object-fit: cover;
    }
`;

export const EventNameContainer = styled(Link)`
    background-color: var(--white-concrete);
    ${props => props.completed && `border: 3px solid var(--${props.completed === 'complete' ? 'abel-blue' : props.completed === 'incomplete' ? 'red-bittersweet' : 'yellow-medium'});`}
    border-radius: 10px;
    color: var(--black);
    display: block;
    padding: 10px;
    position: relative;

    :hover {
        background-color: var(--white-concrete-hover);
        cursor: pointer;
    }
`;

export const InlineTexts = styled.div`
    > div {
        display: inline-block;
    }
`;

export const MultiSelectCheckmark = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 20px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    height: 20px;
    left: 15px;
    position: absolute;
    top: 15px;
    width: 20px;
    z-index: 1;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 20px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const OverlayTextContainer = styled.div`
    background-color: var(--white);
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-small);
    left: 7px;
    overflow: hidden;
    padding: 5px;
    position: absolute;
    right: 7px;
    text-align: center;
    text-overflow: ellipsis;
    top: 7px;
    white-space: nowrap;
`;

export const PopoverContent = styled.div`
    align-items: flex-start;
    background-color: var(--white);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
`;

export const PseudoCheckbox = styled.div`
    ${
        props => props.Selected && `background-color: #${props.BackgroundColor};`
    }

    border: 2px solid var(--white-concrete);
    border-radius: 5px;
    height: 25px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            ${props => `background-color: #${props.BackgroundColor};`}
            cursor: pointer;
        }
    }
`;

export const RadioButtonPageText = styled(PageText)`
    @media(hover:hover) {
        :hover {
            ${props => !props.ReadOnly ? `cursor: pointer;` : `cursor: not-allowed;`}
        }
    }
`;

export const RadioButtonParentContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;

    > div:first-child {
        margin-right: 5px;
    }
`;

export const ReorderIcon = styled.img`
    height: 16px;
    width: 16px;

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const SectionContainer = styled.div`
    align-items: center;
    background-color: ${props => props.IsToday ? 'var(--blue-astronaut)' : 'var(--white-concrete)'};
    color: ${props => props.IsToday ? 'var(--white)' : 'var(--black)'};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;

    ${props => !props.IsCurrentMonth && 'opacity: var(--opacity-low);'}

    ${({ Hover, IsToday }) => (
        Hover ?
            `:hover {
                background-color: ${IsToday ? 'var(--blue-astronaut-hover)' : 'var(--white-concrete-hover)'};
                cursor: pointer;
            }`
        :
            null
    )}
`;

export const StyledHR = styled.div`
    background-color: var(--white);
    border-radius: 10px;
    height: 2px;
    margin: 7px 0px;
    width: 100%;
`;

export const StyledLoadingCircle = styled(LoadingCircle)`
    position: absolute;

    bottom: 10px;
    right: 10px;
`;

export const StyledPopover = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    box-shadow: 1px 1px 8px #5A5A5A29;
    max-height: 500px;
    max-width: 250px;
    overflow-y: scroll;
`;

export const TasksAndStatusesContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    justify-content: center;
`;

export const TasksAndStatusesSectionHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const TasksAndStatusContainer = styled.div`
    align-items: flex-start;
    background-color: var(--white-concrete);
    border: 2px solid ${props => props.CompletionStatus === 'complete' ? 'var(--abel-blue)' : props.CompletionStatus === 'incomplete' ? 'var(--red-bittersweet)' : 'var(--white-concrete)'};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    min-height: 90px;
    justify-content: center;
    padding: 10px;
    width: 100%;

    img {
        max-height: 20px !important;
        min-width: 20px;
        width: 20px !important;
    }

    ${
        props => !props.ReadOnly &&
        `
            @media(hover:hover) {
                :hover {
                    background-color: var(--white-concrete-hover);
                    cursor: pointer;
                }
            }
        `
    }
`;

export const TasksAndStatusContainerTop = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    justify-content: space-between;
    width: 100%;
`;

export const TasksAndStatusContainerLeft = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    grid-gap: 5px;
    justify-content: flex-start;
`;

export const TaskStatusDraggableContainer = styled.div`
    display: grid;
    grid-gap: 15px;
`;

export const ToggleShadow = styled.div`
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    // box-shadow: inset 0 0 0 5px var(--black);
    border-radius: 10px;

    ${props => props.Selected && `box-shadow: inset 0 0 0 5px var(--green-success);`}
`;

export const UserTaskAndStatusDetailModal_StatusContainer = styled.div`
    .TasksAndStatusContainer {
        margin: 0 auto;
        max-width: 300px;

        @media(hover:hover) {
            :hover {
                background-color: var(--white-concrete);
                cursor: unset;
            }
        }
    }
`;

export const WeekContainer = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;

    :not(:first-child) {
        margin-top: 45px;
    }
`;

export const WeeklyDay = styled.div`
    ${props => !props.IsCurrentMonth && 'opacity: 0.5;'}
`;

export const WeeklyEventsContainer = styled.div`
    display: grid;
    grid-gap: 60px 15px;
    grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
`;

export const WeekNumberBar = styled.div`
    background-color: ${props => props.IsCurrentWeekNumber ? 'var(--blue-astronaut)' : 'var(--white-concrete)'};
    border-radius: 10px;
    padding: 10px;
`;

export const WeeklyViewContainer = styled.div`
`;

export const WorkoutDetailContainer = styled(Link)`
    background-color: var(--white-concrete);
    border-radius: 10px;
    color: var(--black);
    display: block;
    padding: 3px;
    position: relative;
    ${props => props.completed ? `border: 3px solid var(--${props.completed === 'complete' ? 'abel-blue' : props.completed === 'incomplete' ? 'red-bittersweet' : 'yellow-medium'});` : 'border: 3px solid var(--white-concrete);'}

    img {
        border-radius: 10px;
        display: block;
        max-width: 100%;
        height: 125px;
        width: 100%;
        object-fit: cover;
    }
`;

export const WorkoutDetailsContainer = styled.div`
    
`;

export const EventImageContainer11 = styled(Link)`

    :hover {
        background-color: var(--white-concrete-hover);
        cursor: pointer;
    }

    img {
        ${props => props.completed && `border: 3px solid var(--${props.completed === 'complete' ? 'abel-blue' : props.completed === 'incomplete' ? 'red-bittersweet' : 'yellow-medium'});`}
        border-radius: 10px;
        display: block;
        max-width: 100%;
        height: 125px;
        width: 100%;
        object-fit: cover;
    }
`;