import {
    CLEAR_PRIVATE_EXERCISE_SETTINGS,
    TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS,
    TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS_SUCCESS,
    TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS_ERROR,

    CLEAR_EXERCISE_DETAIL,
    TRYING_GET_EXERCISE_DETAIL,
    TRYING_GET_EXERCISE_DETAIL_SUCCESS,
    TRYING_GET_EXERCISE_DETAIL_ERROR,

    TRYING_GET_EXERCISE_TRACKING_HISTORY,
    TRYING_GET_EXERCISE_TRACKING_HISTORY_SUCCESS,
    TRYING_GET_EXERCISE_TRACKING_HISTORY_ERROR,

    TRYING_INSERTEDIT_PRIVATE_EXERCISE,
    TRYING_INSERTEDIT_PRIVATE_EXERCISE_SUCCESS,
    TRYING_INSERTEDIT_PRIVATE_EXERCISE_ERROR,

    TRYING_UPDATE_PAUSED_SHARING_EXERCISES,
    TRYING_UPDATE_PAUSED_SHARING_EXERCISES_SUCCESS,
    TRYING_UPDATE_PAUSED_SHARING_EXERCISES_ERROR,

    TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES_SUCCESS,

    TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES,
    TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES_SUCCESS,
    TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES_ERROR,
} from '../Types';

const INITIAL_STATE = {
    CustomExerciseSettings: {},
    ExerciseDetail: {},

    TryingGetCreatePrivateExerciseSettings: false,
    TryingGetCreatePrivateExerciseSettingsError: null,
    TryingGetExerciseDetail: false,
    TryingGetExerciseDetailError: null,
    TryingGetExerciseTrackingHistory: false,
    TryingGetExerciseTrackingHistoryError: null,
    TryingInsertEditPrivateExercise: false,
    TryingInsertEditPrivateExerciseError: null,
    TryingUpdatePausedSharing_Exercises: false,
    TryingUpdatePausedSharing_ExercisesError: null,
    TryingUpdateSharedToCoachStatus_Exercises: false,
    TryingUpdateSharedToCoachStatus_ExercisesError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_PRIVATE_EXERCISE_SETTINGS:
            return { ...state, CustomExerciseSettings: {} };
        case TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS:
            return { ...state, TryingGetCreatePrivateExerciseSettings: true, TryingGetCreatePrivateExerciseSettingsError: null };
        case TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS_ERROR:
            return { ...state, TryingGetCreatePrivateExerciseSettings: false, TryingGetCreatePrivateExerciseSettingsError: action.payload };
        case TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS_SUCCESS:
            return { ...state, CustomExerciseSettings: action.payload, TryingGetCreatePrivateExerciseSettings: false, TryingGetCreatePrivateExerciseSettingsError: null };

        case CLEAR_EXERCISE_DETAIL:
            return { ...state, ExerciseDetail: {} };
        case TRYING_GET_EXERCISE_DETAIL:
            return { ...state, TryingGetExerciseDetail: true, TryingGetExerciseDetailError: null };
        case TRYING_GET_EXERCISE_DETAIL_ERROR:
            return { ...state, TryingGetExerciseDetail: false, TryingGetExerciseDetailError: action.payload };
        case TRYING_GET_EXERCISE_DETAIL_SUCCESS:
            return { ...state, ExerciseDetail: action.payload, TryingGetExerciseDetail: false, TryingGetExerciseDetailError: null };

        case TRYING_GET_EXERCISE_TRACKING_HISTORY:
            return { ...state, TryingGetExerciseTrackingHistory: true, TryingGetExerciseTrackingHistoryError: null };
        case TRYING_GET_EXERCISE_TRACKING_HISTORY_ERROR:
            return { ...state, TryingGetExerciseTrackingHistory: false, TryingGetExerciseTrackingHistoryError: action.payload };
        case TRYING_GET_EXERCISE_TRACKING_HISTORY_SUCCESS:
            var { AddToExisting, IsMoreResults, TotalRecords, TrackingHistory } = action.payload;

            var NewTrackingHistory = { ...state.ExerciseDetail.TrackingHistory, IsMoreResults, TotalRecords };
            if (AddToExisting) NewTrackingHistory.TrackingHistory = [ ...NewTrackingHistory.TrackingHistory, ...TrackingHistory ];
            else NewTrackingHistory.TrackingHistory = [ ...TrackingHistory ];

            return { ...state, ExerciseDetail: { ...state.ExerciseDetail, TrackingHistory: NewTrackingHistory }, TryingGetExerciseTrackingHistory: false, TryingGetExerciseTrackingHistoryError: null };

        case TRYING_INSERTEDIT_PRIVATE_EXERCISE:
            return { ...state, TryingInsertEditPrivateExercise: true, TryingInsertEditPrivateExerciseError: null };
        case TRYING_INSERTEDIT_PRIVATE_EXERCISE_ERROR:
            return { ...state, TryingInsertEditPrivateExercise: false, TryingInsertEditPrivateExerciseError: action.payload };
        case TRYING_INSERTEDIT_PRIVATE_EXERCISE_SUCCESS:
            return { ...state, TryingInsertEditPrivateExercise: false, TryingInsertEditPrivateExerciseError: null }; 

        case TRYING_UPDATE_PAUSED_SHARING_EXERCISES:
            return { ...state, TryingUpdatePausedSharing_ExercisesError: true, TryingUpdatePausedSharing_ExercisesErrorError: null };
        case TRYING_UPDATE_PAUSED_SHARING_EXERCISES_ERROR:
            return { ...state, TryingUpdatePausedSharing_ExercisesError: false, TryingUpdatePausedSharing_ExercisesErrorError: action.payload };
        case TRYING_UPDATE_PAUSED_SHARING_EXERCISES_SUCCESS:
            // eslint-disable-next-line
            var { IsPaused } = action.payload;

            // eslint-disable-next-line
            var ExerciseDetail = { ...state.ExerciseDetail };
            ExerciseDetail.SharingDetails = { ...ExerciseDetail.SharingDetails, IsPaused };

            return { ...state, ExerciseDetail, TryingUpdatePausedSharing_ExercisesError: false, TryingUpdatePausedSharing_ExercisesErrorError: null };

        case TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES_SUCCESS:
            // eslint-disable-next-line
            var { NewStatus: ShareAbilityStatusId, NewStatusName: ShareAbility } = action.payload;

            // eslint-disable-next-line
            var ExerciseDetail = { ...state.ExerciseDetail };
            ExerciseDetail.SharingDetails = { ...ExerciseDetail.SharingDetails, ShareAbility, ShareAbilityStatusId };

            return { ...state, ExerciseDetail };

        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES:
            return { ...state, TryingUpdateSharedToCoachStatus_Exercises: true, TryingUpdateSharedToCoachStatus_ExercisesError: null };
        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES_ERROR:
            return { ...state, TryingUpdateSharedToCoachStatus_Exercises: false, TryingUpdateSharedToCoachStatus_ExercisesError: action.payload };
        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES_SUCCESS:
            // eslint-disable-next-line
            var { UpdateSingleTemplate, SharingDetails } = action.payload;

            if (!UpdateSingleTemplate) return { ...state, TryingUpdateSharedToCoachStatus_Exercises: false, TryingUpdateSharedToCoachStatus_ExercisesError: null };

            // eslint-disable-next-line
            var ExerciseDetail = { ...state.ExerciseDetail, SharingDetails };

            return { ...state, ExerciseDetail, TryingUpdateSharedToCoachStatus_Exercises: false, TryingUpdateSharedToCoachStatus_ExercisesError: null };

        default:
            return state;
    }
};
