import React from 'react';
import { withTranslation } from 'react-i18next';
import { S3_PREFIX } from '../../Config';

import { DocumentContainer, DocumentIcon, DocumentPlatformContainer, DocumentPlatformsContainer, SubContainer } from './styles';

import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Download from '../../Assets/Icons/Download.png';
import PDF from '../../Assets/Icons/PDF.png';

class Documents extends React.Component {
    render() {
        var { t } = this.props;

        var Platforms = [
            // {
            //     Documents: [{ DocumentName: "Admin_user_manual.pdf", DocumentLink: `${S3_PREFIX}manuals/Admin_user_manual.pdf` }, { DocumentName: "Admin_brukermanual.pdf", DocumentLink: `${S3_PREFIX}manuals/Admin_brukermanual.pdf` }],
            //     Name: 'Admin'
            // },
            {
                Documents: [{ DocumentName: "Android_user_manual.pdf", DocumentLink: `${S3_PREFIX}manuals/Android_user_manual.pdf` }, { DocumentName: "Android_brukermanual.pdf", DocumentLink: `${S3_PREFIX}manuals/Android_brukermanual.pdf` }],
                Name: 'Android'
            },
            {
                Documents: [{ DocumentName: "iOS_user_manual.pdf", DocumentLink: `${S3_PREFIX}manuals/iOS_user_manual.pdf` }, { DocumentName: "iOS_brukermanual.pdf", DocumentLink: `${S3_PREFIX}manuals/iOS_brukermanual.pdf` }],
                Name: 'iOS'
            }
        ];

        return (
            <SubContainer>
                <DocumentPlatformsContainer NumPlatforms={Platforms.length}>
                    {
                        Platforms.map(({ Documents, Name }) => {
                            return (
                                <DocumentPlatformContainer key={Name}>
                                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={`${Name} ${t('User_manual').toLowerCase()}`} TextAlign="center" />

                                    <Spacer Size="small" />
                                    
                                    {
                                        Documents.map(({ DocumentName, DocumentLink }) => {
                                            return (
                                                <DocumentContainer key={DocumentName} href={DocumentLink} target="_blank">
                                                    <DocumentIcon alt="Manual" src={PDF} />

                                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={DocumentName} TextAlign="center" />

                                                    <DocumentIcon alt="Download Manual" src={Download} />
                                                </DocumentContainer>
                                            );
                                        })
                                    }
                                </DocumentPlatformContainer>
                            );
                        })
                    }
                </DocumentPlatformsContainer>
            </SubContainer>
        );
    }
}

export default withTranslation()(Documents);