import styled from 'styled-components';
import { device } from '../../Config/device';

import Input from '../../Components/Form/Input';
import ProfilePhoto from '../../Components/ProfilePhoto';

export const AddRegionsAndBranchesHeaderContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

export const BodyContainer = styled.div`
    grid-area: body;
    padding: 15px;
`;

export const ChooseRegionContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    display: flex;
    flex-direction: column;
    padding: 7px 15px;

    ${
        props => props.Clickable &&
        `
            @media(hover:hover) {
                :hover {
                    background-color: var(--white-concrete-hover);
                    cursor: pointer;
                }
            }
        `
    }
`;

export const ChooseRegionsContainer = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    justify-content: center;
`;

export const NoResultsTextContainer = styled.div`
    padding: 0px 10px 10px 10px;
`;

export const OrganizationIncomeContainer = styled.div`
    > div:not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const OrganizationRegionBranchContainer = styled.div`
    align-items: stretch;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const OrganizationRegionBranchTrainerContainer = styled.div`
    align-items: stretch;
    background-color: var(--white-concrete);
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const OrganizationRegionBranchTrainerContainerHeader = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;

    ${
        props => !!props.Clickable &&
        `
            @media(hover:hover) {
                :hover {
                    cursor: pointer;
                }
            }
        `
    }
`;

export const OrganizationRegionBranchTrainerDataContainer = styled.div``;

export const OrganizationRegion_RABContainer = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

export const OrganizationRegionBranch_RABContainer = styled.div`
    align-items: flex-start;
    display: grid;
    grid-gap: 7px;
    grid-template-columns: 4fr 2fr 1fr 1fr;
`;

export const OrganzationRegionBranches_RABContainer = styled.div`
    display: grid;
    grid-gap: 10px;
`;

export const OrganizationRegionContainer = styled.div`
    align-items: stretch;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const OrganizationRegionContainerHeader = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;

    ${
        props => !!props.Clickable &&
        `
            @media(hover:hover) {
                :hover {
                    cursor: pointer;
                }
            }
        `
    }
`;

export const OrganizationRegionContainerHeaderDataContainer = styled.div`
    align-items: flex-start;
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(${props => props.Columns ? props.Columns : 3}, 1fr);
    padding: 10px;
`;

export const OrganizationRegionContainerHeaderDataContainerColumnContainer = styled.div``;

export const OrganizationRegionContainerHeaderDataContainerColumnContainerTextContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-gap: 10px;

    > div:first-child {
        width: 20px;
    }
`;

export const OrganizationRegionContainerHeaderRight = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div:nth-child(2) {
        margin-left: 10px;
    }
`;

export const OrganizationRegionDataContainer = styled.div``;

export const OrganizationRegionSubDataContainer = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 5px;

    > div:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
`;

export const OverallSummaryHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const OverallSummaryHeaderContainerRightContainer = styled.div`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const PageContainer = styled.div`
    border-radius: 10px;
    display: grid;
    grid-template-rows: 53px 1fr;

    ${
        props => !props.HideFilters ?
        `
            @media ${device.minwidth.laptop} {
                grid-template-areas: "header filter"
                                    "body filter";
                grid-template-columns: 1fr 300px;
            }

            @media ${device.maxwidth.ipad} {
                grid-template-areas: "header" "body";
                grid-template-columns: 1fr;
            }
        `
    :
        `
            grid-template-areas: "header" "body";
            grid-template-columns: 1fr;
        `
    }

    min-height: 100vh;
    overflow: hidden;
    width: 100%;
`;

export const PhotoNameContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    > div:first-child {
        margin-right: 5px;
    }
`;

export const PopoverContent = styled.div`
    align-items: flex-start;
    background-color: var(--white);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const RegionsContainer = styled.div`
    align-items: flex-start;
    display: grid;
    

    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
        grid-gap: 100px 30px;
    }

    @media ${device.maxwidth.mobile} {
        grid-template-columns: 1fr
        grid-gap: 50px 30px;
    }
`;

export const StyledInput = styled(Input)`
    > input {
        background-color: var(--white-concrete);
        border-radius: 10px;
        text-indent: 0px;
    }
`;

export const StyledPopover = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    box-shadow: 1px 1px 8px #5A5A5A29;
    max-height: 500px;
    max-width: 250px;
    overflow-y: scroll;
`;

export const StyledProfilePhoto = styled(ProfilePhoto)`
    margin-right: 10px;
`;

export const ToggleIcon = styled.div`
     > img {
        height: 10px;

        :hover {
            cursor: pointer;
        }
    }
`;