import styled from 'styled-components';
import { device } from '../../Config/device';

import Icon from '../../Components/Icon';

export const BodyContainer = styled.div`
    grid-area: body;
    padding: 15px;
`;

export const PageContainer = styled.div`
    border-radius: 10px;
    display: grid;
    grid-template-rows: 1fr;

    ${
        props => !props.HideFilters ?
        `
            @media ${device.minwidth.laptop} {
                grid-template-areas: "body filter";
                grid-template-columns: 1fr 300px;
            }

            @media ${device.maxwidth.ipad} {
                grid-template-areas: "body";
                grid-template-columns: 1fr;
            }
        `
    :
        `
            grid-template-areas: "body";
            grid-template-columns: 1fr;
        `
    }

    min-height: 100vh;
    overflow: hidden;
    width: 100%;
`;

export const SortingIcon = styled(Icon)`
    height: 15px;
    max-width: 15px;
`;

export const TDCell = styled.td`
    white-space: nowrap;
    overflow-y: scroll;
    height: 35px;
    max-width: 200px;
    min-width: 200px;
    
    padding: 0px 3px;

    ${props => !!props.IsNumber && 'text-align: right;'}

    ${props => props.Bold && 'font-family: var(--font-family-semibold);'}
`;

export const THCell = styled.th`
    white-space: nowrap;
    overflow-y: scroll;
    height: 35px;
    max-width: 200px;
    min-width: 200px;
    text-align: left;
    font-family: var(--font-family-semibold);

    padding: 0px 3px;

    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;