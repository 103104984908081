import styled from 'styled-components';
import { device } from '../../Config/device';

import Button from '../Buttons/Button';
import ButtonGroup from '../Buttons/ButtonGroup';
import Image from '../Image';
import Input from '../Form/Input';
import PageText from '../Text/PageText';

export const ActivityLevelContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    display: flex;
    flex-direction: row;
    padding: 15px;

    :hover {
        background-color: var(--blue-astronaut);
        color: var(--white);
        cursor: pointer;
    }
`;

export const ActivityLevelsContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr;

    @media ${device.minwidth.ipad} {
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1050px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

export const ActivityLevelDetailsContainer = styled.div`
    margin-left: 15px;
`;

export const AddGymNoteContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
        width: 60%;
    }
`;

export const BackNextButtons = styled(ButtonGroup)`
    > div {
        :first-child {
            border-top-left-radius: 0px
        }

        :last-child {
            border-top-right-radius: 0px;
        }
    }
`;

export const Container = styled.div`
    align-items: flex-start;
    display: flex;
    justify-content: center;
`;

export const DailyCalorieContainer = styled.div`
    flex: 1;
    opacity: ${props => props.Opacity};
`;

export const DefaultGymContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 15px;
    width: 200px;
`;

export const DropdownContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    > div {
        width: 100%;

        :not(:last-child) {
            margin-bottom: 20px;
        }
    }

    @media ${device.minwidth.ipad} {
        align-items: flex-start;
        flex-direction: row;

        > div {
            min-width: 200px;
            width: fit-content;

            :not(:last-child) {
                margin-bottom: 0px;
                margin-right: 20px;
            }
        }
    }
`;

export const ExperienceLevelContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    display: flex;
    flex-direction: column;
    padding: 15px;

    :hover {
        background-color: var(--blue-astronaut);
        color: var(--white);
        cursor: pointer;
    }
`;

export const ExperienceLevelsContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr;

    @media ${device.minwidth.laptop} {
        grid-template-columns: 1fr 1fr;
    }
`;

export const GetStartedButton = styled(Button)`
    border-radius: 10px;
`;

export const GymIcon = styled.img`
    margin-bottom: 15px;
    margin-right: 10px;    
    width: 50px;

    @media ${device.minwidth.ipad} {
        margin-bottom: 0px;
    }
`;

export const GymLogo = styled(Image)`
    height: 45px;
    max-width: 100%;
`;

export const HeightFeetIcon = styled(PageText)`
    color: var(--gray-silver);
    line-height: 0;

    :last-child {
    }
`;

export const HeightInput = styled(Input)`
    > input {
        border: none;
        line-height: 55px;
        text-align: center;
        text-indent: 0px;
        padding: 0px;
        width: 60px;
    }
`;

export const Illustration = styled.img`
    height: 150px;
    width: 150px;

    @media ${device.minwidth.ipad} {
        height: 250px;
        width: 250px;
    }
`;

export const IllustrationContainer = styled.div`
    @media ${device.minwidth.ipad} {
        margin-right: 50px;
    }
`;

export const IntroContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
    }
`;

export const InstructionsContainer = styled.div``;

export const MultiUnit = styled.div`
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    padding: 6px 15px;

    :hover {
        cursor: pointer;
    }
`;

export const MultiUnitContainer = styled.div`
    align-items: center;
    border: 2px solid var(--blue-astronaut);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    height: 35px;
    margin: 0 auto;
    overflow: hidden;
    width: fit-content;
`;

export const NumberOfMeal = styled.div`
    align-items: center;
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    display: flex;
    justify-content: center;
    height: 45px;
    margin: 0 auto;
    width: 45px;

    :hover {
        background-color: var(--blue-astronaut);
        color: var(--white);
        cursor: pointer;
    }
`;

export const NumberOfMealsContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;

    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(4, 1fr);

    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(8, 1fr);
    }
`;

export const QuestionContainer = styled.div`
    border: 2px solid var(--white-concrete);
    border-bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px 20px 40px;
`;

export const QuestionCount = styled.div`
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white-concrete)'};
    border-radius: 17px;
    height: 17px;
    margin-right: 15px;
    width: 17px;
`;

export const QuestionCountContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const UnitInput = styled(Input)`
    > input {
        border: none;
        line-height: 55px;
        text-align: center;
        text-indent: 0px;
        padding: 0px;
    }
`;

export const UnitInputContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    width: 200px;
`;