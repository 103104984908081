import {
    TRYING_VIEW_ENDURANCE_TEMPLATES,
    TRYING_VIEW_ENDURANCE_TEMPLATES_SUCCESS,
    TRYING_VIEW_ENDURANCE_TEMPLATES_ERROR
} from '../Types';

const INITIAL_STATE = {
    EnduranceTemplates: {},

    TryingViewEnduranceTemplates: false,
    TryingViewEnduranceTemplatesError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_VIEW_ENDURANCE_TEMPLATES:
            return { ...state, TryingViewEnduranceTemplates: true, TryingViewEnduranceTemplatesError: null };
        case TRYING_VIEW_ENDURANCE_TEMPLATES_ERROR:
            return { ...state, TryingViewEnduranceTemplates: false, TryingViewEnduranceTemplatesError: action.payload };
        case TRYING_VIEW_ENDURANCE_TEMPLATES_SUCCESS:
            return { ...state, TryingViewEnduranceTemplates: false, TryingViewEnduranceTemplatesError: null };

        default:
            return state;
    }
};
