import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ChooseTrainingProgramTags, DeleteTrainingProgramTags, EditTrainingProgramTag, GetAllTagsForAddingRemoving_TrainingPrograms, GetTrainingProgramFilterSettings, InsertEditTrainingProgramFilter, InsertTrainingProgramTags, MoveTrainingProgramsToTrash, RecoverTrainingProgramsFromTrash, RemoveCategory_TrainingProgram, ReorderTrainingProgramFilters, SaveDataInProps, SearchTrainingPrograms_SAYT, SearchTrainingPrograms, UpdateTrainingProgramsTags, UpdateShareAbilityStatus_TrainingPrograms, UpdateSharedToCoachStatusAll_TrainingPrograms, UpdateSharedToCoachStatus_TrainingPrograms } from '../../../../Services/Actions';
import history from '../../../../history';
import Popover from 'react-popover';

import * as Styles from './styles';

import AddRemoveTagsPopoverComponent from '../../../../Components/AddRemoveTagsPopover';
import ButtonGroup from '../../../../Components/Buttons/ButtonGroup';
import Checkmark from '../../../../Components/Form/Checkmark';
import CustomizeFilters from '../../CustomizeFilters';
import Filters from '../../Filters';
import Header from '../../Header';
import Loading from '../../../../Components/Loading';
import MultiText from '../../../../Components/Text/MultiText';
import Spacer from '../../../../Components/Spacer';
import PageText from '../../../../Components/Text/PageText';
import Toggle from '../../../../Components/Form/Toggle';
import ViewCount from '../../../../Components/ViewCount';
import { TrainingProgramListItem } from '../../../../Components/Lists/ListItems';

import EditShareAbilityStatusModal from '../../../../Modals/EditShareAbilityStatusModal';
import HowSharingWorksModal from '../../../../Modals/HowSharingWorksModal';
import Info from '../../../../Modals/Info';
import ShareContentModal from '../../../../Modals/ShareContentModal';
import TrainingProgramModal from '../../../../Modals/TrainingProgram';

import { GetTranslatedFilterName } from '../../../../Functions';

import AbelIcon from '../../../../Assets/Icons/Abel_HeaderIcon.png';
import Blocked from '../../../../Assets/Icons/Blocked.png';
import Checkmark_Selected from '../../../../Assets/Icons/Checkmark_Selected.png';
import Female from '../../../../Assets/Icons/Female.png';
import Male from '../../../../Assets/Icons/Male.png';
import Plus from '../../../../Assets/Icons/PlusSign_White.png';
import Plus_Dark from '../../../../Assets/Icons/PlusSign.png';
import Sharing_Gift from '../../../../Assets/Illustrations/Sharing_gift.png';
import { ReactComponent as FilterSVG } from '../../../../Assets/SVG/Filter.svg';
import { ReactComponent as TrashSVG } from '../../../../Assets/SVG/Trash.svg';

const INITIAL_STATE = {
    Injuries: [],
    LastSearchedValue: '',
    Owners: [],
    PageNo: 1, PageSize: 49,
    ProgramName: '',
    TagIds: [],
    TrainingFocus: [],
    SearchValue: ''
}

const SELECTED_CATEGORIES_DEFAULT = {
    Injuries: [],
    MadeBy: [],
    Tags: [],
    TrainingFocus: []
}

const GetSelectedCategories = Categories => {
    var SelectedCategories = JSON.parse(JSON.stringify(SELECTED_CATEGORIES_DEFAULT));

    for (var i = 0; i < Categories.length; i++) {
        var { IsAbel, SecondaryTagType, TagId, TagType } = Categories[i];

        if (!!IsAbel) {
            if (TagType === 'Joints' || TagType === 'Muscles') SelectedCategories[`${SecondaryTagType}${TagType}`].push(+TagId);
            else SelectedCategories[TagType].push(+TagId);
        } else SelectedCategories[TagType].push(+TagId);;
    }

    return SelectedCategories;
}

class TrainingProgramLibrary extends React.Component {
    _isMounted = false;

    state = {
        ...INITIAL_STATE,
        EditTrainingProgramTagError: false,
        ForceReload: false,
        Loading: true,
        MergeTags: true,
        MultiSelectTrainingProgramIds: [],
        MultiSelectTrainingPrograms: [],
        SelectedCategories: [],
        SelectedCategoriesIndex: [],
        SelectedCategories_Modal: { ...SELECTED_CATEGORIES_DEFAULT },
        SelectedTrainingProgram: {},
        SelectedTrainingProgramId: null,
        ShowAddRemoveTagsPopover: false,
        ShowAddRemoveTagsPopover_ActionType: null,
        ShowChooseCategoriesModal: false,
        ShowDeleteTrainingProgramsModal: false,
        ShowEditShareAbilityStatusModal: false,
        ShowFiltersModals: false,
        ShowHowSharingWorksModal: false,
        ShowShareContentModal: false,
        ShowMultiSelect: false,
        ShowTrainingProgramDetailModal: false,
        StateLoading: false,
        TrainingProgramDetail_Preview: {},
        TryingChooseCategories: false
    };

    componentDidMount() {
        this._isMounted = true;

        this.props.GetTrainingProgramFilterSettings(true).then(() => {
            if (this._isMounted && !this.props.TryingGetTrainingProgramFilterSettingsError) this.onLoadTrainingPrograms();
        });
    }

    componentDidUpdate(prevProps) {
        if (!!prevProps.ShowCustomizeFilters && !this.props.ShowCustomizeFilters && this.state.ForceReload) {
            this.setState({ ForceReload: false });
            this.onLoadTrainingPrograms();
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    onAssignToClients = (event, Template) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        this.props.SaveDataInProps({ SelectedTemplate: Template }).then(() => history.push({ pathname: '/add-training-program', state: { from: 'Library' } }));
    }

    onCancelMultiSelect = () => {
        this.setState({ MultiSelectTrainingProgramIds: [], MultiSelectTrainingPrograms: [], ShowMultiSelect: false });
    }

    onChooseCategories = () => {
        var Categories = this.state.SelectedCategories_Modal;

        var SelectedCategories = [];

        let IsAbel, TagType, SecondaryTagType, ContentId, TagId;

        Object.keys(Categories).map(key => {
            TagType = key;
            SecondaryTagType = null;
            ContentId = null;
            TagId = null;

            if (TagType === 'Tags') IsAbel = 0;
            else IsAbel = 1;

            for (var i = 0; i < Categories[key].length; i++) {
                if (TagType === 'Tags') TagId = Categories[key][i];
                else ContentId = Categories[key][i];

                SelectedCategories.push({ IsAbel, TagType, SecondaryTagType, ContentId, TagId })
            }

            return null;
        });

        this.setState({ TryingChooseCategories: true });
        this.props.ChooseTrainingProgramTags({ Categories: JSON.stringify(SelectedCategories) }).then(() => {
            this.setState({ TryingChooseCategories: false });
            this.onToggleShowChooseCategoryTagsModal(false);
        });
    }

    onClearAllFilters = () => {
        var { Device } = this.props;

        this.setState({ Injuries: [], Owners: [], TagIds: [], TrainingFocus: [] }, () => Device === 'laptop' && this.onLoadTrainingPrograms());
    }

    onClickCreateTrainingProgram = () => {
        history.push('library/training-program');
    }

    onDeleteCategory = ({ CategoryId }) => {
        var SelectedCategoryIndex = this.state.SelectedCategoriesIndex.indexOf(CategoryId);

        if (SelectedCategoryIndex !== -1) {
            var SelectedCategories = [ ...this.state.SelectedCategories.slice(0, SelectedCategoryIndex), ...this.state.SelectedCategories.slice(SelectedCategoryIndex + 1) ];
            var SelectedCategoriesIndex = [ ...this.state.SelectedCategoriesIndex.slice(0, SelectedCategoryIndex), ...this.state.SelectedCategoriesIndex.slice(SelectedCategoryIndex + 1) ];
            
            this.setState({ ForceReload: true, SelectedCategories, SelectedCategoriesIndex });
            this.props.RemoveCategory_TrainingProgram({ CategoryId });
        }
        else this.props.RemoveCategory_TrainingProgram({ CategoryId });
    }

    onDeleteTrainingPrograms = () => {
        var { MultiSelectTrainingProgramIds } = this.state;

        if (!MultiSelectTrainingProgramIds.length) this.onToggleShowDeleteTrainingProgramsModal(false);
        else {
            console.log('MultiSelectTrainingProgramIds: ', MultiSelectTrainingProgramIds);
        }
    }

    onDeleteTags = ({ Tag }) => {
        var SelectedTagIndex = this.state.TagIds.indexOf(+Tag.TagId);
        if (SelectedTagIndex !== -1) this.setState({ ForceReload: true, TagIds: [ ...this.state.TagIds.slice(0, SelectedTagIndex), ...this.state.TagIds.slice(SelectedTagIndex + 1) ] });
        
        this.props.DeleteTrainingProgramTags({ TagIds: [ Tag.TagId ] });
    }

    onDeleteToTrash = () => {
        var { MultiSelectTrainingProgramIds } = this.state;

        this.setState({ StateLoading: true });
        this.props.MoveTrainingProgramsToTrash({ TrainingProgramIds: MultiSelectTrainingProgramIds }).then(() => {
            this.setState({ MultiSelectTrainingProgramIds: [], MultiSelectTrainingPrograms: [], ShowMultiSelect: false, StateLoading: false }, () => {
                this.onLoadTrainingPrograms();
            });
        });
    }

    onEditTrainingProgramTag = ({ TagId, TagName }) => {
        return this.props.EditTrainingProgramTag({ TagId, TagName }).then(({ IsDuplicate }) => IsDuplicate);
    }

    onInsertEditTrainingProgramFilter = FilterSettings => {
        this.props.InsertEditTrainingProgramFilter({ ...FilterSettings });
    }

    onInsertTrainingProgramTags = NewTags => {
        this.props.InsertTrainingProgramTags({ NewTags });
    }

    onLoadTrainingPrograms = Increment => {
        var { ForceReload, Injuries, NewSearch, Owners, PageNo, PageSize, SearchValue, SelectedCategories, TagIds, TrainingFocus } = this.state;
        var { TrainingProgramFilterSettings: { HasDeleted }, ShowTrash, UserDetails: { LanguageId } } = this.props;

        var HideDeleted = !!ShowTrash ? 0 : 1;

        if (!HasDeleted && !!ShowTrash && !ForceReload) this.setState({ Loading: false });
        else {
            if (Increment) {
                NewSearch = false;
                PageNo = PageNo + 1;
            } else {
                NewSearch = true;
                PageNo = 1;
            }
    
            for (var i = 0; i < SelectedCategories.length; i++) {
                var { IsAbel, TagId, TagType } = SelectedCategories[i];
    
                if (IsAbel) {
                    if (TagType === 'Injuries') Injuries = [ ...new Set([ ...Injuries, TagId ]) ];
                    else if (TagType === 'MadeBy') Owners = [ ...new Set([ ...Owners, TagId ]) ];
                    else if (TagType === 'Tags') TagIds = [ ...new Set([ ...TagIds, TagId ]) ];
                    else if (TagType === 'TrainingFocus') TrainingFocus = [ ...new Set([ ...TrainingFocus, TagId ]) ];
                }
                else TagIds = [ ...new Set([ ...TagIds, TagId ]) ];
            }
        
            this.setState({ ActiveSearch: true, ForceReload: false, NewSearch, PageNo }, () => this.props.SearchTrainingPrograms({ AddToExisting: Increment, HasDeleted, HideDeleted, Injuries, LanguageId, Owners, PageNo, PageSize, ProgramName: SearchValue, ShowTrash, TagIds, TrainingFocus }).then(this.setState({ ActiveSearch: false, CompletedFirstSearch: true, Loading: false })));
        }
    }

    onMultiSelect = (TrainingProgram, MultiSelectIndex) => {
        if (MultiSelectIndex === -1) this.setState({ MultiSelectTrainingProgramIds: [ ...this.state.MultiSelectTrainingProgramIds, Number(TrainingProgram.TrainingProgramId)], MultiSelectTrainingPrograms: [ ...this.state.MultiSelectTrainingPrograms, TrainingProgram ] });
        else {
            this.setState((prevState) => {
                return {
                    MultiSelectTrainingProgramIds: [ ...prevState.MultiSelectTrainingProgramIds.slice(0, MultiSelectIndex), ...prevState.MultiSelectTrainingProgramIds.slice(MultiSelectIndex + 1) ],
                    MultiSelectTrainingPrograms: [ ...prevState.MultiSelectTrainingPrograms.slice(0, MultiSelectIndex), ...prevState.MultiSelectTrainingPrograms.slice(MultiSelectIndex + 1) ]
                }
            });
        }
    }

    onRecoverFromTrash = () => {
        var { MultiSelectTrainingProgramIds } = this.state;

        this.setState({ ForceReload: true, StateLoading: true });
        this.props.RecoverTrainingProgramsFromTrash({ TrainingProgramIds: MultiSelectTrainingProgramIds }).then(() => {
            this.setState({ MultiSelectTrainingProgramIds: [], MultiSelectTrainingPrograms: [], ShowMultiSelect: false, StateLoading: false }, () => {
                this.onLoadTrainingPrograms();
            });
        });
    }

    onReorderFilters = ReorderedFilters => {
        this.props.ReorderTrainingProgramFilters({ Filters: ReorderedFilters });
    }

    onSearchFromFiltersModal = () => {
        this.setState({ ShowFiltersModals: false }, () => this.onLoadTrainingPrograms());
    }

    onSearchFromSAYT = SearchValue => {
        if (!this.props.TryingSearchTrainingPrograms) {
            var { LastSearchedValue } = this.state;

            if (SearchValue !== LastSearchedValue) {
                this.setState({ PageNo: 1, PageSize: INITIAL_STATE.PageSize, LastSearchedValue: SearchValue, SearchValue }, () => this.onLoadTrainingPrograms());
            }
        }
    }

    onSelectAll = () => {
        var { TrainingProgramsList } = this.props;

        var MultiSelectTrainingPrograms = [ ...this.state.MultiSelectTrainingPrograms ];
        var MultiSelectTrainingProgramIds = [ ...this.state.MultiSelectTrainingProgramIds ];

        TrainingProgramsList.forEach(element => {
            var TrainingProgramId = +element.TrainingProgramId;

            if (MultiSelectTrainingProgramIds.indexOf(TrainingProgramId) === -1) {
                MultiSelectTrainingPrograms.push(element);
                MultiSelectTrainingProgramIds.push(TrainingProgramId);
            }
        });

        this.setState({ MultiSelectTrainingPrograms, MultiSelectTrainingProgramIds });
    }

    onSelectCategory = (Category, SelectedCategoryIndex) => {
        var { SelectedCategories, SelectedCategoriesIndex } = this.state;

        if (SelectedCategoryIndex === -1) { SelectedCategories = [ ...SelectedCategories, Category ]; SelectedCategoriesIndex = [ ...SelectedCategoriesIndex, +Category.CategoryId ]; }
        else {
            SelectedCategories = [ ...SelectedCategories.slice(0, SelectedCategoryIndex), ...SelectedCategories.slice(SelectedCategoryIndex + 1) ];
            SelectedCategoriesIndex = [ ...SelectedCategoriesIndex.slice(0, SelectedCategoryIndex), ...SelectedCategoriesIndex.slice(SelectedCategoryIndex + 1) ];
        }

        this.setState({ SelectedCategories, SelectedCategoriesIndex }, () => this.onLoadTrainingPrograms());
    }

    onSelectMergeTagsCheckmark = event => {
        var { MergeTags } = this.state;

        if (event) MergeTags = +(event.target.checked);
        else MergeTags = +!MergeTags;

        this.setState({ MergeTags });
    }

    onSelectTag = (event, Filter, TagId, TagIndex, TargetAvoid, OppositeTargetAvoidIndex) => {
        var checked;
        if (event) checked = event.target.checked;
        else checked = TagIndex === -1;

        var { SelectedCategories_Modal } = this.state;

        var FilterName = Filter.FilterName;
        if (FilterName === 'Joints' || FilterName === 'Muscles') {
            FilterName = `${TargetAvoid}${FilterName}`;
            Filter.TargetAvoid = TargetAvoid;
        }

        if (checked) {
            SelectedCategories_Modal[FilterName] = [ ...SelectedCategories_Modal[FilterName], TagId ];

            // Remove the opposite target/avoid if already selected
                if ((Filter.FilterName === 'Joints' || Filter.FilterName === 'Muscles') && OppositeTargetAvoidIndex !== -1) {
                    var OppositeFilterName = `${TargetAvoid === 'Avoid' ? 'Target' : 'Avoid'}${Filter.FilterName}`;
                    SelectedCategories_Modal[OppositeFilterName] = [ ...this.state.SelectedCategories_Modal[OppositeFilterName].slice(0, OppositeTargetAvoidIndex), ...this.state.SelectedCategories_Modal[OppositeFilterName].slice(OppositeTargetAvoidIndex + 1) ];
                }
        } else SelectedCategories_Modal[FilterName] = [ ...this.state.SelectedCategories_Modal[FilterName].slice(0, TagIndex), ...this.state.SelectedCategories_Modal[FilterName].slice(TagIndex + 1) ];

        this.setState({ SelectedCategories_Modal });
    }

    onSelectTrainingProgram = TrainingProgram => {
        var { FromAddWorkouts } = this.props;

        if (!!FromAddWorkouts) this.props.OnSelectTrainingProgram(TrainingProgram);
    }

    onShareContentToEmails = ({ Emails, ContentIds }) => {
        this.props.UpdateSharedToCoachStatus_TrainingPrograms({ Emails, NewStatus: 'Pending', TrainingProgramIds: ContentIds }).then(() => {
            this.onToggleShowShareContentModal(false);
        });
    }

    onToggleShowAddRemoveTagsPopover = ({ ShowAddRemoveTagsPopover_ActionType = null }) => {
        this.setState({ ShowAddRemoveTagsPopover: !this.state.ShowAddRemoveTagsPopover, ShowAddRemoveTagsPopover_ActionType });
    }

    onToggleFilterStartOpen = Filter => {
        var { StartOpen, TagFilterId } = Filter;

        this.onInsertEditTrainingProgramFilter({ FilterId: TagFilterId, ...Filter, StartOpen: +!!!StartOpen });
    }

    onToggleFilterVisibility = (Filter, VisibilityStatus) => {
        var { TagFilterId } = Filter;
        var IsHidden = VisibilityStatus === 'Show' ? 0 : 1;

        this.onInsertEditTrainingProgramFilter({ FilterId: TagFilterId, ...Filter, IsHidden });
    }

    onToggleMultiSelect = event => {
        this.setState({ MultiSelectTrainingProgramIds: [], MultiSelectTrainingPrograms: [], ShowMultiSelect: event.target.checked });
    }

    onToggleShowChooseCategoryTagsModal = ShowChooseCategoryTagsModal => {
        var SelectedCategories_Modal = ShowChooseCategoryTagsModal ? GetSelectedCategories(this.props.TrainingProgramFilterSettings.Categories) : { ...SELECTED_CATEGORIES_DEFAULT };

        this.setState({ SelectedCategories_Modal, ShowChooseCategoryTagsModal });
    }

    onToggleShowDeleteTrainingProgramsModal = ShowDeleteTrainingProgramsModal => {
        if (!this.state.MultiSelectTrainingProgramIds.length && !!ShowDeleteTrainingProgramsModal) {}
        else this.setState({ ShowDeleteTrainingProgramsModal });
    }

    onToggleShowEditShareAbilityStatusModal = ({ ContentId = null, ContentIndex = null, ShowEditShareAbilityStatusModal, CurrentStatus = null }) => {
        this.setState({ ShowEditShareAbilityStatusModal, ShowEditShareAbilityStatusModalDetails: { ContentId, ContentIndex, CurrentStatus } });
    }

    onToggleShowFiltersModal = ShowFiltersModals => {
        this.setState({ ShowFiltersModals });
    }

    onToggleShowHowSharingWorksModal = ShowHowSharingWorksModal => {
        this.setState({ ShowHowSharingWorksModal });
    }

    onToggleShowShareContentModal = ShowShareContentModal => {
        if (!this.state.MultiSelectTrainingProgramIds.length && !!ShowShareContentModal) {}
        else this.setState({ ShowShareContentModal });
    }

    onToggleShowTrainingProgramDetailModal = (ShowTrainingProgramDetailModal, TrainingProgramDetail_Preview, TrainingProgramId) => {
        var { SelectedTrainingProgram, SelectedTrainingProgramId } = this.state;

        if (TrainingProgramId && Number(TrainingProgramId) === Number(SelectedTrainingProgramId)) { SelectedTrainingProgram = {}; SelectedTrainingProgramId = null; }

        if (!!this.props.FromAddWorkouts) this.props.OnShowTemplateDetail(ShowTrainingProgramDetailModal);
        
        this.setState({ SelectedTrainingProgram, SelectedTrainingProgramId, ShowTrainingProgramDetailModal, TrainingProgramDetail_Preview });
    }

    onUpdateFilters = (Filter, Selected, DontSearch = false) => {
        var { FilterName, IsAbel } = Filter;

        if (IsAbel) {
            if (FilterName === 'Injuries') this.setState({ Injuries: Selected }, () => !DontSearch && this.onLoadTrainingPrograms());
            else if (FilterName === 'MadeBy') this.setState({ Owners: Selected }, () => !DontSearch && this.onLoadTrainingPrograms());
            else if (FilterName === 'Tags') this.setState({ TagIds: Selected }, () => !DontSearch && this.onLoadTrainingPrograms());
            else if (FilterName === 'TrainingFocus') this.setState({ TrainingFocus: Selected }, () => !DontSearch && this.onLoadTrainingPrograms());
        }
        else this.setState({ TagIds: Selected }, () => !DontSearch && this.onLoadTrainingPrograms());
    }

    onUpdateMultiSelectList = ({ ContentId, NewStatus, NewStatusName }) => {
        var ContentIndex = this.state.MultiSelectTrainingProgramIds.indexOf(+ContentId);

        if (ContentIndex !== -1) {
            this.setState(prevState => {
                var MultiSelectTrainingPrograms = [ ...prevState.MultiSelectTrainingPrograms ];
                MultiSelectTrainingPrograms[ContentIndex] = { ...MultiSelectTrainingPrograms[ContentIndex], ShareAbility: NewStatusName, ShareAbilityStatusId: NewStatus };
                
                return { ...prevState, MultiSelectTrainingPrograms };
            });
        }
    }
    
    onUpdateShareAbilityStatus = ({ ContentId, ContentIndex, NewStatus, NewStatusName }) => {
        return this.props.UpdateShareAbilityStatus_TrainingPrograms({ TrainingProgramId: ContentId, NewStatus, NewStatusName }).then(() => {
            this.setState(prevState => {
                var MultiSelectTrainingPrograms = [ ...prevState.MultiSelectTrainingPrograms ];
                MultiSelectTrainingPrograms[ContentIndex] = { ...MultiSelectTrainingPrograms[ContentIndex], ShareAbility: NewStatusName, ShareAbilityStatusId: NewStatus };
                
                return { ...prevState, MultiSelectTrainingPrograms };
            });
        });
    }

    onUpdateSharedToCoachStatus = ({ ContentId, NewStatus, PersonalResponse }) => {
        this.setState({ StateLoading: true });
        this.props.UpdateSharedToCoachStatus_TrainingPrograms({ Emails: [ this.props.UserDetails.Email ], TrainingProgramIds: [ ContentId ], NewStatus, PersonalResponse }).then(() => {
            this.setState({ StateLoading: false });
            this.onLoadTrainingPrograms();
        });
    }

    onUpdateSharedToCoachStatus_All = ({ NewStatus }) => {
        var { MergeTags } = this.state;

        this.setState({ StateLoading: true });
        this.props.UpdateSharedToCoachStatusAll_TrainingPrograms({ MergeTags, NewStatus }).then(() => {
            this.setState({ StateLoading: false });
            this.onLoadTrainingPrograms();
        });
    }

    onUpdateTrainingProgramsTags = ({ ActionType, TagIds }) => {
        this.props.UpdateTrainingProgramsTags({ ActionType, TrainingProgramIds: this.state.MultiSelectTrainingProgramIds.join(','), TagIds }).then(() => {
            this.onToggleShowAddRemoveTagsPopover({});
            // this.onCancelMultiSelect();
            if (this.state.TagIds.some(v => TagIds.includes(v))) this.onLoadTrainingPrograms();
        });
    }

    renderBottomBar = () => {
        var { t } = this.props;
        var { MultiSelectTrainingProgramIds, ShowAddRemoveTagsPopover_ActionType, ShowMultiSelect } = this.state;

        if (!ShowMultiSelect && !MultiSelectTrainingProgramIds.length) return null;
        // if (!MultiSelectTrainingProgramIds.length) return null;

        var { Device } = this.props;

        var Buttons = [];
        var AddRemoveTagsButtons = [];

        if (this.props.ShowTrash) {
            Buttons = [
                ...Buttons,
                { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', OnClick: () => this.onRecoverFromTrash(true), Title: t('_recover') },
                // { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', IconSVG: TrashSVG, IconPosition: 'left', OnClick: this.onDeleteTrainingPrograms, Title: t('_delete_for_good') },
                { Border: 'none', ColorHover: 'black', NoBackground: true, OnClick: this.onCancelMultiSelect, Title: t('Cancel_with_first_char_uppercase') }
            ];
        }
        else {
            Buttons = [
                ...Buttons,
                { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', Disabled: !MultiSelectTrainingProgramIds.length, OnClick: () => this.onToggleShowShareContentModal(true), Title: t('_share_with') },
                { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', Disabled: !MultiSelectTrainingProgramIds.length, IconSVG: TrashSVG, IconPosition: 'left', OnClick: this.onDeleteToTrash, Title: t('_move_to_trash') },
                { Border: 'none', ColorHover: 'black', NoBackground: true, OnClick: this.onCancelMultiSelect, Title: t('Cancel_with_first_char_uppercase') }
            ];

            AddRemoveTagsButtons = [
                { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', Disabled: !MultiSelectTrainingProgramIds.length, OnClick: () => this.onToggleShowAddRemoveTagsPopover({ ShowAddRemoveTagsPopover_ActionType: 'Adding' }), Title: t('_add_tags') },
                { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', Disabled: !MultiSelectTrainingProgramIds.length, OnClick: () => this.onToggleShowAddRemoveTagsPopover({ ShowAddRemoveTagsPopover_ActionType: 'Removing' }), Title: t('_remove_tags') }
            ]
        }

        const popoverProps = {
            isOpen: this.state.ShowAddRemoveTagsPopover,
            preferPlace: 'above',
            onOuterAction: () => this.onToggleShowAddRemoveTagsPopover({}),
            body: <AddRemoveTagsPopoverComponent ActionType={ShowAddRemoveTagsPopover_ActionType} TagIds={MultiSelectTrainingProgramIds} GetAllTagsForAddingRemoving={({ ActionType, TagIds: TrainingProgramIds }) => this.props.GetAllTagsForAddingRemoving_TrainingPrograms({ ActionType, TrainingProgramIds })} t={t} UpdateTags={this.onUpdateTrainingProgramsTags} />,
            tipSize: 10
        }

        return (
            <>
                <Spacer Size="extra-large" />

                <Styles.StyledBottomBar className="StyledBottomBar">
                    <Styles.BottomBarItems className="BottomBarItems" FirstOne>
                        <Styles.BottomBarLeftContainer className="BottomBarLeftContainer">
                            <PageText ElementType="span" FontColor="blue-abel" FontFamily="bold" FontSize="medium-3" Text={`${MultiSelectTrainingProgramIds.length}`} />
                            &nbsp;
                            <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('WorkoutPlanSettings_selected').toLowerCase()} />
                        </Styles.BottomBarLeftContainer>

                        <Styles.BottomBarRightContainer className="BottomBarRightContainer">
                            <PageText FontFamily="bold" FontSize="medium-1" NoMargin OnClick={this.onSelectAll} Text={t('select_all')} />
                        </Styles.BottomBarRightContainer>

                        {
                            !this.props.ShowTrash &&
                            <Popover {...popoverProps}>
                                <Styles.AddRemoveTagsButtons
                                    Buttons={AddRemoveTagsButtons}
                                    ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                                    NotTouching
                                    OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                                />
                            </Popover>
                        }
                    </Styles.BottomBarItems>
                    <Styles.BottomBarItems className="BottomBarItems" FullWidth={true}>
                        <ButtonGroup
                            Buttons={Buttons}
                            ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                            NotTouching
                            OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                        />
                    </Styles.BottomBarItems>
                </Styles.StyledBottomBar>
            </>
        );
    }

    renderDeleteTrainingProgramsInformation = () => {
        var { t } = this.props;
        var { MultiSelectTrainingPrograms } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" Text={t('template_delete_warning')} TextAlign="center" />

                <Styles.DeleteTemplatesList>
                    {
                        MultiSelectTrainingPrograms.map(TrainingProgram => <TrainingProgramListItem key={TrainingProgram.TrainingProgramId} HideSelect Item={TrainingProgram} ViewTemplate={TrainingProgram => this.onToggleShowTrainingProgramDetailModal(true, TrainingProgram)} />)
                    }
                </Styles.DeleteTemplatesList>
            </>
        );
    }

    renderDeleteTrainingProgramsModal = () => {
        var { t } = this.props;
        var { TryingDeleteTrainingPrograms } = this.props;
        var { ShowDeleteTrainingProgramsModal, ShowTrainingProgramDetailModal } = this.state;

        if (ShowDeleteTrainingProgramsModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteTrainingPrograms}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    DisplayNone={ShowTrainingProgramDetailModal}
                    InformationRenderer={this.renderDeleteTrainingProgramsInformation}
                    Loading={TryingDeleteTrainingPrograms}
                    OnHide={() => this.onToggleShowDeleteTrainingProgramsModal(false)}
                    Show={ShowDeleteTrainingProgramsModal}
                />
            );
        }
    }

    renderFiltersModal_Info = () => {
        var { Device, ShowTrash } = this.props;
        var { Injuries, Owners, TagIds, TrainingFocus } = this.state;

        var DefaultSelections = { Injuries, MadeBy: Owners, Tags: TagIds, TrainingFocus };
        
        return (
            <Filters
                DefaultSelections={DefaultSelections}
                Device={Device}
                Filters={this.props.TrainingProgramFilterSettings ? this.props.TrainingProgramFilterSettings.Filters : []}
                HideCustomizeButton
                OnClearAllFilters={this.onClearAllFilters}
                OnDeleteTag={this.onDeleteTags}
                OnUpdateSelections={(Filter, Selected) => this.onUpdateFilters(Filter, Selected, true)}
                ShowTrash={ShowTrash}
            />
        );
    }

    renderFiltersModal = () => {
        var { t } = this.props;
        var { ShowFiltersModals } = this.state;

        if (ShowFiltersModals) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onSearchFromFiltersModal}
                    BottomButtonText={t('_search')}
                    InformationRenderer={this.renderFiltersModal_Info}
                    OnHide={() => this.onToggleShowFiltersModal(false)}
                    Show={ShowFiltersModals}
                />
            );
        }
    }

    renderTrainingPrograms = () => {
        var { t } = this.props;
        var { TrainingProgramsList, TrainingProgramsList_NumPendingShared, TrainingProgramsList_TotalRecords, FromAddWorkouts, IsMoreTrainingPrograms, SelectedItemIds } = this.props;
        var { ActiveSearch, CompletedFirstSearch, MergeTags, NewSearch, PageNo, PageSize, ShowMultiSelect } = this.state;

        ActiveSearch = false;
        CompletedFirstSearch = false;

        return (
            <>
                {
                    !!TrainingProgramsList_NumPendingShared &&
                    <Styles.PendingSharedContainer className="PendingSharedContainer">
                        <Styles.PendingSharedLeftContainer className="PendingSharedLeftContainer">
                            <Styles.PendingSharedImage className="PendingSharedImage" ImageSrc={Sharing_Gift} SelfLoading />
                        </Styles.PendingSharedLeftContainer>
                        <Styles.PendingSharedRightContainer className="PendingSharedRightContainer">
                            <Styles.PendingSharingRightTopContainer className="PendingSharingRightTopContainer">
                                <MultiText
                                    Texts={[
                                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('_you_have') },
                                        { FontFamily: 'bold', FontSize: 'medium-2', Text: `${TrainingProgramsList_NumPendingShared}` },
                                        { FontFamily: 'medium', FontSize: 'medium-2', Text: t('_sharing_requests_pending') }
                                    ]}
                                />
                                
                                <Styles.CheckmarkTextContainer className="CheckmarkTextContainer">
                                    <Checkmark
                                        ItemId={0}
                                        OnSelectItem={this.onSelectMergeTagsCheckmark}
                                        SelectedIndex={MergeTags ? 1 : -1}
                                        Title="MergeTags"
                                    />

                                    <Styles.CheckmarkItemName
                                        ElementType="div"
                                        FontFamily="medium"
                                        FontSize="small"
                                        NoMargin
                                        OnClick={() => this.onSelectMergeTagsCheckmark(null)}
                                        Text={t('_i_want_receive_custom_tags_generic')}
                                        Title="MergeTags"
                                    />
                                </Styles.CheckmarkTextContainer>
                            </Styles.PendingSharingRightTopContainer>
                            <ButtonGroup
                                Buttons={[
                                    { BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', OnClick: () => this.onUpdateSharedToCoachStatus_All({ NewStatus: 'accept' }), Size: 'small', Title: t('_accept') },
                                    { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', OnClick: () => this.onUpdateSharedToCoachStatus_All({ NewStatus: 'reject' }), Size: 'small', Title: t('_decline') }
                                ]}
                                ButtonWidth="fit-content"
                                JustifyContent="flex-start"
                                NotTouching
                            />
                        </Styles.PendingSharedRightContainer>
                    </Styles.PendingSharedContainer>
                }

                <Styles.StyledLoadMore
                    ActiveSearch={ActiveSearch}
                    CompletedFirstSearch={CompletedFirstSearch}
                    HideViewCount
                    IsLoading={false}
                    ItemDescriptionType={t('programs_plural').toLowerCase()}
                    ItemId="TrainingProgramId"
                    ItemName="ProgramName"
                    ItemProps={{
                        AssignToClients: !!FromAddWorkouts ? false : true,
                        HideMadeBy: true,
                        HideSelect: !!FromAddWorkouts ? false : !ShowMultiSelect,
                        IsMultiSelect: !!FromAddWorkouts ? false : true,
                        MultiSelectItemIds: this.state.MultiSelectTrainingProgramIds,
                        OnAssignToClients: (event, TrainingProgram) => this.onAssignToClients(event, TrainingProgram),
                        OnMultiSelect: (TrainingProgram, MultiSelectIndex) => this.onMultiSelect(TrainingProgram, MultiSelectIndex),
                        OnUpdateSharedToCoachStatus: this.onUpdateSharedToCoachStatus,
                        SelectTemplate: this.onSelectTrainingProgram,
                        ShowDescription: true,
                        ViewTemplate: TrainingProgram => this.onToggleShowTrainingProgramDetailModal(true, TrainingProgram)
                    }}
                    ItemRenderer={TrainingProgramListItem}
                    Items={TrainingProgramsList}
                    HasMoreItems={IsMoreTrainingPrograms}
                    LoadMore={() => this.onLoadTrainingPrograms(true)}
                    NewSearch={NewSearch}
                    NoItemsText={t('search_noresults')}
                    PageNo={PageNo}
                    PageSize={PageSize}
                    RenderHeader={(!!+TrainingProgramsList_NumPendingShared && !ShowMultiSelect) ? this.onRenderListHeader : null}
                    SelectedItemIds={SelectedItemIds}
                    TotalRecords={TrainingProgramsList_TotalRecords}
                />
            </>
        );
    }

    renderSearchAndCategories = () => {
        var { t } = this.props;
        var { Device, IsModal, TrainingProgramFilterSettings, UserDetails: { LanguageId } } = this.props;
        var { SelectedCategoriesIndex } = this.state;

        if (Device === 'laptop') {
            return (
                <Styles.SearchAndCategoriesContainer className="SearchAndCategoriesContainer">
                    <Styles.StyledSAYT
                        ExtraSearchProps={{ LanguageId }}
                        ItemName="ProgramName"
                        OnSearch={this.onSearchFromSAYT}
                        Placeholder={t('_search')}
                        SearchFunc={this.props.SearchTrainingPrograms_SAYT}
                    />
    
                    {
                        TrainingProgramFilterSettings.Categories && TrainingProgramFilterSettings.Categories.map(Category => {
                            var { CategoryId, TagName } = Category;
                            var SelectedCategoryIndex = SelectedCategoriesIndex.indexOf(+CategoryId);
    
                            return (
                                <Styles.CategoryContainer className="CategoryContainer" key={Category.CategoryId} onClick={() => this.onSelectCategory(Category, SelectedCategoryIndex)} Selected={SelectedCategoryIndex !== -1}>
                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={TagName} TextAlign="center" />
                                </Styles.CategoryContainer>
                            );
                        })
                    }
    
                    <Styles.CategoryContainer className="CategoryContainer" onClick={() => this.onToggleShowChooseCategoryTagsModal(true)}>
                        <Styles.AddCategoryIcon ImageSrc={Plus_Dark} />
                    </Styles.CategoryContainer>
                </Styles.SearchAndCategoriesContainer>
            );
        } else {
            return (
                <Styles.SearchAndCategoriesContainer className="SearchAndCategoriesContainer" IsModal={IsModal}>
                    <Styles.SearchAndCategoriesTopContainer className="SearchAndCategoriesTopContainer" IsModal={IsModal}>
                        <Styles.StyledSAYT
                            ExtraSearchProps={{ LanguageId }}
                            IsModal={IsModal}
                            ItemName="ProgramName"
                            OnSearch={this.onSearchFromSAYT}
                            Placeholder={t('_search')}
                            SearchFunc={this.props.SearchTrainingPrograms_SAYT}
                        />

                        <ButtonGroup Buttons={[{ BackgroundColor: "white-concrete", BackgroundColorHover: 'white-concrete-hover', Border: 'none', Color: 'black', ColorHover: 'black', IconSVG: FilterSVG, IconSVGColor: 'black', IconPosition: 'left', OnClick: () => this.onToggleShowFiltersModal(true), Title: t('_select_filters') }]} />
                    </Styles.SearchAndCategoriesTopContainer>
    
                    <Styles.CategoriesContainer className="CategoriesContainer">
                        {
                            TrainingProgramFilterSettings.Categories && TrainingProgramFilterSettings.Categories.map(Category => {
                                var { CategoryId, TagName } = Category;
                                var SelectedCategoryIndex = SelectedCategoriesIndex.indexOf(+CategoryId);
        
                                return (
                                    <Styles.CategoryContainer className="CategoryContainer" key={Category.CategoryId} onClick={() => this.onSelectCategory(Category, SelectedCategoryIndex)} Selected={SelectedCategoryIndex !== -1}>
                                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={TagName} TextAlign="center" />
                                    </Styles.CategoryContainer>
                                );
                            })
                        }
        
                        {
                            !IsModal &&
                            <Styles.CategoryContainer className="CategoryContainer" onClick={() => this.onToggleShowChooseCategoryTagsModal(true)}>
                                <Styles.AddCategoryIcon ImageSrc={Plus_Dark} />
                            </Styles.CategoryContainer>
                        }
                    </Styles.CategoriesContainer>
                </Styles.SearchAndCategoriesContainer>
            );
        }
    }

    renderShowChooseCategoryTagsModal = () => {
        var { ShowChooseCategoryTagsModal } = this.state;

        if (ShowChooseCategoryTagsModal) {
            var { t } = this.props;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onChooseCategories}
                    BottomButtonText={t('_save')}
                    InformationRenderer={this.renderShowChooseCategoryTagsModal_Information}
                    OnHide={() => this.onToggleShowChooseCategoryTagsModal(false)}
                    Show={ShowChooseCategoryTagsModal}
                    Size="medium"
                />
            );
        }
    }

    renderShowChooseCategoryTagsModal_Information = () => {
        var { t } = this.props;
        var { Filters } = this.props.TrainingProgramFilterSettings;

        var { TryingChooseCategories } = this.state;

        return (
            <>
                {TryingChooseCategories && <Loading />}

                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('_pick_tags_as_highlights1')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <Styles.FiltersContainer className="FiltersContainer">
                    {
                        Filters.map(F => {
                            var { FilterName, IsAbel, TagFilterId, Tags } = F;
                            var TranslatedFilterName = GetTranslatedFilterName(F, t);

                            return (
                                <Styles.FilterSectionContainer className="FilterSectionContainer" key={TagFilterId}>
                                    <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={TranslatedFilterName} TextAlign="left" />

                                    <Spacer Size="extra-extra-small" />

                                    <Styles.TagsContainer className="TagsContainer">
                                        {
                                            IsAbel && (FilterName === 'Joints' || FilterName === 'Muscles') &&
                                            <Styles.FilterTagContainer className="FilterTagContainer" JustifyContent="space-between">
                                                <Styles.CheckmarkIcon ImageSrc={Blocked} Loading={false} />

                                                <Styles.CheckmarkIcon ImageSrc={Checkmark_Selected} Loading={false} />
                                            </Styles.FilterTagContainer>
                                        }
                                        {Tags.map(Tag => this.renderTag(F, Tag))}
                                    </Styles.TagsContainer>
                                </Styles.FilterSectionContainer>
                            );
                        })
                    }
                </Styles.FiltersContainer>
            </>
        );
    }

    renderShowEditShareAbilityStatusModal = () => {
        var { ShowEditShareAbilityStatusModal, ShowEditShareAbilityStatusModalDetails } = this.state;

        if (ShowEditShareAbilityStatusModal) {
            return (
                <EditShareAbilityStatusModal
                    ContentId={ShowEditShareAbilityStatusModalDetails.ContentId}
                    ContentIndex={ShowEditShareAbilityStatusModalDetails.ContentIndex}
                    CurrentStatus={ShowEditShareAbilityStatusModalDetails.CurrentStatus}
                    Loading={this.props.TryingUpdateShareAbilityStatus_TrainingPrograms}
                    OnHide={() => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: false })}
                    OnUpdateShareAbilityStatus={this.onUpdateShareAbilityStatus}
                    Show={ShowEditShareAbilityStatusModal}
                />
            );
        }
    }

    renderShowHowSharingWorksModal = () => {
        var { ShowHowSharingWorksModal } = this.state;

        if (ShowHowSharingWorksModal) {
            return (
                <HowSharingWorksModal
                    OnHide={() => this.onToggleShowHowSharingWorksModal(false)}
                    Show={ShowHowSharingWorksModal}
                />
            );
        }
    }

    renderShowShareContentModal = () => {
        var { MultiSelectTrainingPrograms, ShowEditShareAbilityStatusModal, ShowHowSharingWorksModal, ShowShareContentModal } = this.state;

        if (ShowShareContentModal) {
            var { TryingUpdateSharedToCoachStatus_TrainingPrograms } = this.props;

            return (
                <ShareContentModal
                    ContentIdName="TrainingProgramId"
                    ContentType="TrainingPrograms"
                    DisplayNone={!!ShowEditShareAbilityStatusModal || !!ShowHowSharingWorksModal}
                    OnHide={() => this.onToggleShowShareContentModal(false)}
                    OnSubmit={({ Emails, ContentIds }) => this.onShareContentToEmails({ Emails, ContentIds })}
                    OnToggleShowEditShareAbilityStatusModal={({ ContentIndex, ContentId, CurrentStatus }) => this.onToggleShowEditShareAbilityStatusModal({ ShowEditShareAbilityStatusModal: true, ContentId, ContentIndex, CurrentStatus })}
                    OnToggleShowHowSharingWorksModal={() => this.onToggleShowHowSharingWorksModal(true)}
                    Loading={TryingUpdateSharedToCoachStatus_TrainingPrograms}
                    SharingContent={MultiSelectTrainingPrograms}
                    Show={ShowShareContentModal}
                />
            );
        }
    }

    renderTag = (Filter, Tag) => {
        var { SelectedCategories_Modal: SelectedCategories } = this.state;

        var key = Tag.TagId;
        if (Filter.IsAbel && Filter.FilterName === 'Equipment') key = Tag.EquipmentCategory;

        if (Filter.IsAbel && (Filter.FilterName === 'Joints' || Filter.FilterName === 'Muscles')) {
            var SelectedIndex_Avoid = SelectedCategories[`Avoid${Filter.FilterName}`].indexOf(Tag.TagId);
            var SelectedIndex_Target = SelectedCategories[`Target${Filter.FilterName}`].indexOf(Tag.TagId);

            return (
                <Styles.FilterTagContainer className="FilterTagContainer" key={key}>
                    <Checkmark
                        BlockIcon
                        ItemId={Tag.TagId}
                        OnSelectItem={event => this.onSelectTag(event, Filter, Tag.TagId, SelectedIndex_Avoid, 'Avoid', SelectedIndex_Target)}
                        SelectedIndex={SelectedIndex_Avoid}
                        Title={Tag.TagName}
                    />

                    <Styles.CheckmarkItemName
                        ElementType="div"
                        FontFamily="medium"
                        FontSize="small"
                        NoMargin
                        // OnClick={() => this.onSelectTag(null, Tag.TagId, SelectedIndex)}
                        Text={Tag.TagName}
                        Title={Tag.TagName}
                        TwoBoxes
                    />

                    <Checkmark
                        ItemId={Tag.TagId}
                        OnSelectItem={event => this.onSelectTag(event, Filter, Tag.TagId, SelectedIndex_Target, 'Target', SelectedIndex_Avoid)}
                        SelectedIndex={SelectedIndex_Target}
                        Title={Tag.TagName}
                    />
                </Styles.FilterTagContainer>
            );
        }
        else if (!(Filter.IsAbel && Filter.FilterName === 'Equipment')) {
            var SelectedIndex = SelectedCategories[Filter.FilterName].indexOf(Tag.TagId);

            return (
                <Styles.FilterTagContainer className="FilterTagContainer" key={key}>
                    <Checkmark
                        ItemId={Tag.TagId}
                        OnSelectItem={event => this.onSelectTag(event, Filter, Tag.TagId, SelectedIndex)}
                        SelectedIndex={SelectedIndex}
                        Title={Tag.TagName}
                    />

                    {Filter.FilterName === 'MadeBy' && this.renderUserPhoto(Filter, Tag, SelectedIndex)}

                    <Styles.CheckmarkItemName
                        ElementType="div"
                        FontFamily="medium"
                        FontSize="small"
                        NoMargin
                        OnClick={() => this.onSelectTag(null, Filter, Tag.TagId, SelectedIndex)}
                        Text={Tag.TagName}
                        Title={Tag.TagName}
                    />
                </Styles.FilterTagContainer>
            );
        }
        else if (Filter.IsAbel && Filter.FilterName === 'Equipment') {
            if (!Tag.EquipmentList) return null;

            return (
                <Styles.EquipmentFilterContainer className="EquipmentFilterContainer" key={key}>
                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={Tag.EquipmentCategory} TextAlign="left" />

                    <Spacer Size="extra-small" />

                    {
                        Tag.EquipmentList && Tag.EquipmentList.map(Equipment => {
                            var SelectedIndex = SelectedCategories[Filter.FilterName].indexOf(Equipment.TagId);

                            return (
                                <Styles.FilterTagContainer className="FilterTagContainer" key={Equipment.TagId}>
                                    <Checkmark
                                        ItemId={Equipment.TagId}
                                        OnSelectItem={event => this.onSelectTag(event, Filter, Equipment.TagId, SelectedIndex)}
                                        SelectedIndex={SelectedIndex}
                                        Title={Equipment.TagName}
                                    />

                                    <Styles.CheckmarkItemName
                                        ElementType="div"
                                        FontFamily="medium"
                                        FontSize="small"
                                        NoMargin
                                        OnClick={() => this.onSelectTag(null, Filter, Equipment.TagId, SelectedIndex)}
                                        Text={Equipment.TagName}
                                        Title={Equipment.TagName}
                                    />
                                </Styles.FilterTagContainer>
                            );
                        })
                    }
                </Styles.EquipmentFilterContainer>
            );
        }

        return (
            <pre key={key}>
                {JSON.stringify(Tag, null, 2)}
            </pre>
        );
    }

    renderTrainingProgramDetailModal = () => {
        var { ShowTrainingProgramDetailModal, TrainingProgramDetail_Preview } = this.state;

        if (ShowTrainingProgramDetailModal) {
            return (
                <TrainingProgramModal
                    OnAssignClients={!!this.props.FromAddWorkouts ? this.onSelectTrainingProgram : null}
                    OnHide={TrainingProgramId => this.onToggleShowTrainingProgramDetailModal(false, null, TrainingProgramId)}
                    OnLoadPrograms={this.onLoadTrainingPrograms}
                    OnUpdateMultiSelectList={this.onUpdateMultiSelectList}
                    TemplateDetail_Preview={TrainingProgramDetail_Preview}
                    Show={ShowTrainingProgramDetailModal}
                />
            );
        }
    }

    renderUserPhoto = (Filter, Tag, SelectedIndex) => {
        var { Sex, TagId, TagName, UserId, UserPhoto } = Tag;

        var SexImage = Sex === 'Male' ? Male : Female;

        if (+UserId === 16) UserPhoto = AbelIcon;

        return <Styles.StyledProfilePhoto Alt={TagName} ImageSrc={UserPhoto} IsAbel={UserId === 16} NoImage={SexImage} NoParent OnClick={() => this.onSelectTag(null, Filter, TagId, SelectedIndex)} />;
    }

    renderViewCount = () => {
        var { t } = this.props;
        var { TrainingProgramsList_TotalRecords: TotalRecords } = this.props;
        var { PageNo, PageSize } = this.state;

        if (!TotalRecords) TotalRecords = 0;

        return <ViewCount PageNo={PageNo} PageSize={PageSize} TextAlign="left" TotalRecords={TotalRecords} ViewingObjectType={t('_sessions').toLowerCase()} />;
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { Device } = this.props;

        if (this.props.ShowCustomizeFilters && Device === 'laptop') {
            return (
                <>
                    {(this.props.TryingInsertEditLibraryFilterTrainingPrograms) && <Loading />}

                    <Header LibraryType="TrainingPrograms" ShowTrash={this.props.ShowTrash} />

                    <Styles.BodyContainer>
                        <CustomizeFilters
                            Categories={this.props.TrainingProgramFilterSettings ? this.props.TrainingProgramFilterSettings.Categories : []}
                            Filters={this.props.TrainingProgramFilterSettings ? this.props.TrainingProgramFilterSettings.Filters : []}
                            OnChooseCategories={this.onChooseCategories}
                            OnDeleteCategory={this.onDeleteCategory}
                            OnDeleteTag={this.onDeleteTags}
                            OnEditTag={this.onEditTrainingProgramTag}
                            OnInsertTags={this.onInsertTrainingProgramTags}
                            OnReorderFilters={this.onReorderFilters}
                            OnToggleFilterStartOpen={this.onToggleFilterStartOpen}
                            OnToggleFilterVisibility={this.onToggleFilterVisibility}
                            OnToggleShowChooseCategoryTagsModal={this.onToggleShowChooseCategoryTagsModal}
                            OnToggleShowHowToTagModal={this.props.OnToggleShowHowToTagModal}
                        />
                    </Styles.BodyContainer>

                    {this.renderShowChooseCategoryTagsModal()}
                </>
            );
        }

        var { t } = this.props;
        var { IsModal, ShowTrash, TryingSearchTrainingPrograms } = this.props;
        var { Injuries, Owners, ShowFiltersModals, ShowMultiSelect, StateLoading, TagIds, TrainingFocus } = this.state;

        var DefaultSelections = { Injuries, MadeBy: Owners, Tags: TagIds, TrainingFocus };

        var HeaderTitle = !!ShowTrash ? '_trash' : '_programs';

        if (!!IsModal && ShowFiltersModals) return this.renderFiltersModal();

        return (
            <>
                {(StateLoading || TryingSearchTrainingPrograms) && <Loading />}

                {!IsModal && <Header LibraryType="TrainingPrograms" ShowTrash={this.props.ShowTrash} />}

                {
                    Device === 'laptop' &&
                    <Filters
                        DefaultSelections={DefaultSelections}
                        Device={Device}
                        Filters={this.props.TrainingProgramFilterSettings ? this.props.TrainingProgramFilterSettings.Filters : []}
                        HideCustomizeButton={!!IsModal}
                        OnClearAllFilters={this.onClearAllFilters}
                        OnDeleteTag={this.onDeleteTags}
                        OnUpdateSelections={this.onUpdateFilters}
                        ShowTrash={ShowTrash}
                    />
                }

                <Styles.BodyContainer>
                    {
                        !IsModal &&
                        <Styles.BodyHeader className="BodyHeader">
                            <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={t(HeaderTitle)} TextAlign="left" />
    
                            <Styles.BodyHeaderRight className="BodyHeaderRight" ShowTrash={ShowTrash}>
                                <Styles.ToggleContainer className="ToggleContainer">
                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('_multi_select')}: ${t('_multiselect_variation1')}`} TextAlign="left"  />
    
                                    <Toggle
                                        Checked={!!+ShowMultiSelect}
                                        OffLabel={t('_off')}
                                        OnChange={this.onToggleMultiSelect}
                                        OneLabel="after"
                                        OnLabel={t('_on')}
                                    />
                                </Styles.ToggleContainer>
    
                                {!ShowTrash && <ButtonGroup Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: this.onClickCreateTrainingProgram, Title: t('_create_new') }]} />}
                            </Styles.BodyHeaderRight>
                        </Styles.BodyHeader>
                    }

                    <Spacer Size="small" />

                    {this.renderSearchAndCategories()}

                    {
                        (!!ShowTrash && !this.props.TrainingProgramFilterSettings.HasDeleted) ?
                        <>
                            <Spacer Size="large" />

                            <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('_trash_empty')} TextAlign="center" />
                        </>
                    :
                        <>
                            <Spacer Size="small" />

                            {this.renderViewCount()}

                            <Spacer Size="small" />

                            {this.renderTrainingPrograms()}
                        </>
                    }

                    {this.renderBottomBar()}
                </Styles.BodyContainer>

                {this.renderDeleteTrainingProgramsModal()}
                {this.renderFiltersModal()}
                {this.renderShowChooseCategoryTagsModal()}
                {this.renderShowEditShareAbilityStatusModal()}
                {this.renderShowHowSharingWorksModal()}
                {this.renderShowShareContentModal()}
                {this.renderTrainingProgramDetailModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        TrainingProgramFilterSettings: state.Templates.TrainingProgramFilterSettings,
        TrainingProgramsList: state.Templates.TrainingProgramsList,
        TrainingProgramsList_NumPendingShared: state.Templates.TrainingProgramsList_NumPendingShared,
        TrainingProgramsList_TotalRecords: state.Templates.TrainingProgramsList_TotalRecords,
        IsMoreTrainingPrograms: state.Templates.IsMoreTrainingPrograms,

        TryingDeleteTrainingPrograms: state.Templates.TryingDeleteTrainingPrograms,
        TryingDeleteTrainingProgramsError: state.Templates.TryingDeleteTrainingProgramsError,
        TryingGetTrainingProgramFilterSettings: state.Templates.TryingGetTrainingProgramFilterSettings,
        TryingGetTrainingProgramFilterSettingsError: state.Templates.TryingGetTrainingProgramFilterSettingsError,
        TryingInsertEditLibraryFilterTrainingPrograms: state.Templates.TryingInsertEditLibraryFilterTrainingPrograms,
        TryingInsertEditLibraryFilterTrainingProgramsError: state.Templates.TryingInsertEditLibraryFilterTrainingProgramsError,
        TryingSearchTrainingPrograms: state.Templates.TryingSearchTrainingPrograms,
        TryingSearchTrainingProgramsError: state.Templates.TryingSearchTrainingProgramsError,
        TryingUpdateShareAbilityStatus_TrainingPrograms: state.Templates.TryingUpdateShareAbilityStatus_TrainingPrograms,
        TryingUpdateShareAbilityStatus_TrainingProgramsError: state.Templates.TryingUpdateShareAbilityStatus_TrainingProgramsError,
        TryingUpdateSharedToCoachStatus_TrainingPrograms: state.Templates.TryingUpdateSharedToCoachStatus_TrainingPrograms,
        TryingUpdateSharedToCoachStatus_TrainingProgramsError: state.Templates.TryingUpdateSharedToCoachStatus_TrainingProgramsError,
        TryingUpdateSharedToCoachStatusAll_TrainingPrograms: state.Templates.TryingUpdateSharedToCoachStatusAll_TrainingPrograms,
        TryingUpdateSharedToCoachStatusAll_TrainingProgramsError: state.Templates.TryingUpdateSharedToCoachStatusAll_TrainingProgramsError
    };
};

export default withTranslation()(connect(mapStateToProps, { ChooseTrainingProgramTags, DeleteTrainingProgramTags, EditTrainingProgramTag, GetAllTagsForAddingRemoving_TrainingPrograms, GetTrainingProgramFilterSettings, InsertEditTrainingProgramFilter, InsertTrainingProgramTags, MoveTrainingProgramsToTrash, RecoverTrainingProgramsFromTrash, RemoveCategory_TrainingProgram, ReorderTrainingProgramFilters, SaveDataInProps, SearchTrainingPrograms_SAYT, SearchTrainingPrograms, UpdateTrainingProgramsTags, UpdateShareAbilityStatus_TrainingPrograms, UpdateSharedToCoachStatusAll_TrainingPrograms, UpdateSharedToCoachStatus_TrainingPrograms } )(TrainingProgramLibrary));