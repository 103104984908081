import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CreateDishCategory } from '../../Services/Actions';

import { InputContainer } from './styles';

import AbelModal from '../../Components/Modal';
import Input from '../../Components/Form/Input';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

class AddDishCategory extends React.Component {
    _isMounted = false;

    state = { CategoryName: '' };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onClickSubmit = () => {
        var { CategoryName } = this.state;

        this.props.CreateDishCategory(CategoryName).then(LastCategoryId => {
            if (!this.props.TryingCreateDishCategoryError) this.props.OnAddDishCategory(LastCategoryId);
        });
    }

    onChangeCategoryName = event => {
        this.setState({ CategoryName: event.target.value });
    }

    onKeyPress = event => {
        if (event.key === 'Enter' && !this.props.TryingCreateDishCategory) {
            this.onClickSubmit();
        }
    }

    renderModalBody = () => {
        var { t } = this.props;

        return (
            <div>
                {this.props.TryingCreateDishCategory ? <Loading /> : null}

                <PageText FontFamily="medium" FontSize="large" NoMargin Text={t('Create_new_category_str')} TextAlign="center" />

                <Spacer Size="small" />

                <InputContainer>
                    <Input FontSize="medium-2" NoLabel OnChange={this.onChangeCategoryName} OnKeyPress={this.onKeyPress} Placeholder={t('Name_the_category_str')} Size="medium" Type="text" Value={this.state.CategoryName} />
                </InputContainer>

                {
                    this.props.TryingCreateDishCategoryError ?
                    <PageText FontColor="red-wellread" FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={this.props.TryingCreateDishCategoryError} />
                :
                    null
                }
            </div>
        );
    }

    render() {
        var { t } = this.props;
        var { OnHide, Show } = this.props;
        var { CategoryName } = this.state;

        return (
            <AbelModal
                BottomButton="submit"
                BottomButtonOnClick={this.onClickSubmit}
                BottomButtonText={t('Create_new_category_str')}
                Disabled={!CategoryName || this.props.TryingCreateDishCategory}
                GA_PathName="AddDishCategory"
                Show={Show}
                Size="large"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

AddDishCategory.propTypes = {
    OnAddDishCategory: PropTypes.func.isRequired,
    OnHide: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        TryingCreateDishCategory: state.Dishes.TryingCreateDishCategory,
        TryingCreateDishCategoryError: state.Dishes.TryingCreateDishCategoryError
    };
};

export default withTranslation()(connect(mapStateToProps, { CreateDishCategory } )(AddDishCategory));