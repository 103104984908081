import styled from 'styled-components';
import { device } from '../../Config/device';

import Icon from '../../Components/Icon';
import PageText from '../../Components/Text/PageText';

export const ActiveShareContainer = styled.div`
    align-items: center;
    background-color: var(--white);
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr;
    padding: 10px;
    width: 100%;

    :not(:last-child) {
        margin-bottom: 3px;
    }

    > div:not(:last-child) {
        margin-right: 3px;
    }

    @media ${device.minwidth.ipad} {
        grid-template-columns: 4fr 1.5fr 1.5fr 2fr 1.5fr;
    }
`;

export const AddCoachPlusIcon = styled(Icon)`
    height: 20px;
    margin-right: 10px;
    width: 20px;
`;

export const AddCoachTextContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center
`;

export const Container = styled.div`
    align-items: flex-start;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
`;

export const InactiveShareContainer = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;

    :not(:last-child) {
        margin-bottom: 3px;
    }

    > div:not(:last-child) {
        margin-right: 3px;
    }

    @media ${device.minwidth.ipad} {
        grid-template-columns: 4fr 1.5fr 1.5fr 2fr 1.5fr;
    }
`;

export const PausedContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const ShareAbilityStatusContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const SharingListContainer = styled.div`
    max-height: 275px;
    overflow: scroll;    
    width: 100%;

    @media ${device.minwidth.ipad} {
        max-height: 200px;
    }
`;

export const StyledEditIcon = styled(Icon)`
    height: 13px;
    margin-left: 4px;
    width: 13px;
`;

export const StyledEllipsisText = styled(PageText)`
    overflow: hidden;

    > div {    
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const StyledPauseText = styled(PageText)`
    margin-right: 5px;
`;