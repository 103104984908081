import {
    CLEAR_SAVE_DATA,
    SAVE_DATA_IN_PROPS
} from '../Types';

const INITIAL_STATE = {
    ClientDetails: {},
    CopyDishDetail: {},
    CopyTrainingProgramDetails: {},
    DayDate: null,
    ExerciseToExerciseDetails: {},
    GroupDetails: {},
    LibraryMenu: '',
    LibrarySubmenu: '',
    SelectedTemplate: {},
    TemplateToTemplateDetails: {},
    TemplateToWorkoutDetails: {},
    WeeklyMealsToTemplateDetails: {},
    WorkoutToTemplateDetails: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_SAVE_DATA:
            return { ...INITIAL_STATE };
        case SAVE_DATA_IN_PROPS:
            return { ...state, ...action.payload };
        
        default:
            return state;
    }
};
