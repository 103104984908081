import React from 'react';
import { withTranslation } from 'react-i18next';

import * as styles from './styles';

import PageText from '../../Components/Text/PageText';
import ProfilePhoto from '../../Components/ProfilePhoto';

import Pencil from '../../Assets/Icons/Pencil_Gray.png';
import RedX from '../../Assets/Icons/X_Red.png';

class OrganizationMemberComponent extends React.Component {
    state = {
        IncomeShare: this.props.OrganizationMember.IncomeShare,
        ShowEditingOrgIncome: false
    };

    onBlurIncomeShare = () => {
        var { IncomeShare } = this.state;

        if (IncomeShare !== '') {
            IncomeShare = (Math.round(+IncomeShare * 10)) / 10;

            if (IncomeShare < 0) IncomeShare = 0;
            if (IncomeShare > 100) IncomeShare = 100;

            this.setState({ IncomeShare });
        }
    }

    onChangeIncomeShare = event => {
        this.setState({ IncomeShare: event.target.value });
    }

    onToggleShowEditingOrgIncome = ShowEditingOrgIncome => {
        this.setState({ ShowEditingOrgIncome });
    }

    onSaveMemberDetails = () => {
        this.onToggleShowEditingOrgIncome(false);
        this.props.SaveMemberDetails({ IncomeShare: this.state.IncomeShare, OrganizationMemberId: this.props.OrganizationMember.OrganizationMemberId });
    }

    render() {
        var { t } = this.props;
        var { MemberIndex, OrgIncomeShare, OrganizationMember: { DisplayName, Email, OrganizationMemberId, Sex, UserId, UserPhoto }, ToggleShowDeleteCoachesModal, ToggleShowTrainerContractsModal } = this.props;
        var { IncomeShare, ShowEditingOrgIncome } = this.state;

        return (
            <styles.CoachContainer className="CoachContainer" IsMember>
                <styles.CoachDetailsContainer className="CoachDetailsContainer">
                    <styles.DeleteIconContainer className="DeleteIconContainer">
                        <styles.DeleteIcon ImageSrc={RedX} OnClick={event => ToggleShowDeleteCoachesModal({ event, DeleteMember: { MemberIndex, MemberType: 'Accepted', OrganizationMemberId }, ShowDeleteCoachesModal: true })} />
                    </styles.DeleteIconContainer>

                    <styles.CoachPictureContainer className="CoachPictureContainer">
                        <styles.ProfilePhotoContainer className="ProfilePhotoContainer">
                            <ProfilePhoto OnClick={() => ToggleShowTrainerContractsModal({ CoachDetail: { CoachId: UserId, DisplayName }, ShowTrainerContractsModal: true })} Sex={Sex || 'Male'} ShowOverlay={false} Size="small-2" Source={UserPhoto || ''} />
                        </styles.ProfilePhotoContainer>
                    </styles.CoachPictureContainer>

                    <styles.StyledDisplayName className="StyledDisplayName">
                        <styles.StyledEllipsisText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => ToggleShowTrainerContractsModal({ CoachDetail: { CoachId: UserId, DisplayName }, ShowTrainerContractsModal: true })} Text={DisplayName} TextAlign="left" />
                        {/* <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={DisplayName} TextAlign="left" /> */}
                    </styles.StyledDisplayName>
                </styles.CoachDetailsContainer>
                <styles.StyledDisplayName className="StyledDisplayName">
                    <styles.StyledEllipsisText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-end" NoMargin OnClick={() => ToggleShowTrainerContractsModal({ CoachDetail: { CoachId: UserId, DisplayName }, ShowTrainerContractsModal: true })} Text={Email} TextAlign="right" WhiteSpace="nowrap" />
                    {/* <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-end" NoMargin Text={Email} TextAlign="right" WhiteSpace="nowrap" /> */}
                </styles.StyledDisplayName>

                <styles.OrgIncomeMemberContainer className="OrgIncomeMemberContainer">
                    {
                        ShowEditingOrgIncome ?
                        <>
                            <PageText FontColor="abel-blue" FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin OnClick={this.onSaveMemberDetails} Text={t('save_with_first_char_uppercase')} TextAlign="center" />

                            <styles.IncomeChoiceContainer className="IncomeChoiceContainer">
                                <styles.IncomeChoiceInputContainer className="IncomeChoiceInputContainer">
                                    <styles.IncomeChoiceInput BackgroundColor="white" FontFamily="bold" FontSize="medium-2" NoLabel NoMargin OnBlur={this.onBlurIncomeShare} OnChange={this.onChangeIncomeShare} Placeholder={`${OrgIncomeShare}`} Size="small-1" Type="number" Value={IncomeShare} />
                                </styles.IncomeChoiceInputContainer>

                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text="%" TextAlign="center" />
                            </styles.IncomeChoiceContainer>
                        </>
                    :
                        <>
                            <styles.DeleteIconContainer className="DeleteIconContainer">
                                <styles.DeleteIcon ImageSrc={Pencil} OnClick={event => this.onToggleShowEditingOrgIncome({ ShowEditingOrgIncome: true })} />
                            </styles.DeleteIconContainer>

                            <styles.StyledIncomeShareText FontFamily="bold" FontSize="medium-1" JustifyContent="center" NoMargin Opacity={this.props.OrganizationMember.IncomeShare !== null ? 1 : 0.3} Text={`${this.props.OrganizationMember.IncomeShare !== null ? IncomeShare : OrgIncomeShare}%`} TextAlign="center" />
                        </>
                    }


                </styles.OrgIncomeMemberContainer>
            </styles.CoachContainer>
        );
    }
}

export default withTranslation()(OrganizationMemberComponent);