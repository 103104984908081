import styled from 'styled-components';
import { device } from '../../Config/device';

import Dropdown from '../../Components/Dropdown';
import Input from '../../Components/Form/Input';
import InputLabelSideBySide from '../../Components/Form/InputLabelSideBySide';
import PageText from '../../Components/Text/PageText';

import Checkmark from '../../Assets/Icons/Checkmark_Selected.png';

export const ContractParties = styled.div`
    font-family: var(--font-family-medium);
    font-size: var(--font-size-medium-1);
`;

export const DateShortcutCheckbox = styled.div`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 28px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 5px
    display: block;
    height: 28px;
    margin-right: 10px;
    min-width: 28px;
    width: 28px;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 28px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const DateShortcutContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    :not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const DateShortcutInput = styled(Input)`
    margin: 0 auto;
    margin-right: 5px;
    max-width: 40px;

    > input {
        ${props => props.ReadOnly && 'border-color: var(--white-concrete);'}
        border-radius: 10px;
        height: 100%;
        overflow: hidden;
        padding: 3px;
        text-align: center;
        text-indent: 0px;
    }
`;

export const DateShortcutsContainer = styled.div``;

export const DateShortcutSubContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const DateShortcutTextContainer = styled.div`
    align-items: ${props => props.HasExtraContainer ? 'flex-start' : 'center'};
    display: flex;
    flex-direction: ${props => props.HasExtraContainer ? 'column' : 'row'};
    justify-content: ${props => props.HasExtraContainer ? 'center' : 'flex-start'};

    @media ${device.maxwidth.ipad} {
        ${props => props.HasExtraContainer && '> div:first-child { margin-bottom: 5px; }'};
    }

    @media ${device.minwidth.laptop} {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
`;

export const DayOptionContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Disabled ? 'var(--white-concrete)' : props.Selected ? 'var(--blue-abel)' : 'var(--white)'};
    border: 2px solid ${props => props.Disabled ? 'var(--white-concrete)' : props.Selected ? 'var(--blue-abel)' : 'var(--white-concrete)'};
    border-radius: 5px;
    color: ${props => props.Disabled ? 'var(--black)' : props.Selected ? 'var(--white)' : 'var(--black)'};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 3px 8px;

    ${
        props => !props.Disabled &&
        `
            @media(hover:hover) {
                :hover {
                    background-color: var(--blue-abel);
                    border-color: var(--blue-abel);
                    color: var(--white);
                    cursor: pointer;
                }
            }
        `
    }
`;

export const DayOptionsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    > div:not(:last-child) {
        margin-right: 5px;
    }

    @media ${device.minwidth.laptop} {
        margin-left: 10px;
    }
`;

export const DetailText = styled.div`
    ${props => props.FontColor && `color: var(--${props.FontColor});`}
    font-family: ${props => props.FontFamily ? `var(--font-family-${props.FontFamily})` :  'var(--font-family-medium)'};
    font-size: var(--font-size-small);
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${props => props.Underline && 'text-decoration: underline;'}
`;

export const FakeCheckmarkBox = styled.div`
    border: 2px solid var(--black);
    border-radius: 5px;
    height: 20px;
    width: 20px;

    :not(:first-child) {
        margin-left: 5px;
    }
`;

export const FullNameText = styled.span`
    color: var(--${props => props.UserType === 'Trainer' ? 'purple' : 'blue-abel'});
    font-family: var(--font-family-semibold);
    text-transform: uppercase;
`;

export const HealthDeclarationContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
`;

export const HealthDeclarationOptionsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 5px;

    > div:first-child {
        margin-right: 25px;
    }
`;

export const HealthDeclarationsContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 1000px;
`;

export const HealthDeclarationTextContainer = styled.div`
    flex: 1;
`;

export const InvoiceAmountContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    > div:first-child {
        margin-right: 5px;
    }

    @media ${device.minwidth.laptop} {
        align-items: center;
    }
`;

export const InvoiceContainer = styled.div`
    align-items: flex-start;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;
    margin-top: 30px;
    padding: 14px 21px;

    @media ${device.maxwidth.ipad} {
        > div:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    @media ${device.minwidth.laptop} {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
`;

export const InvoiceDateButtonContainer = styled.div`
    border: 2px solid ${props => props.IsPaymentDateBeforeSentDate ? 'var(--red-bittersweet)' : 'var(--white-concrete)'};
    border-radius: 10px;
    margin: 0 5px;
    padding: 8px;

    @media(hover:hover) {
        :hover {
            border-color: var(--blue-abel);
            cursor: pointer;
        }
    }
`;

export const InvoiceDateContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${device.maxwidth.ipad} {
        > div:nth-child(2) {
            margin-top: -10px;
        }
    }

    // @media ${device.minwidth.laptop} {
    //     align-items: center;
    //     flex-direction: row;
    // }
`;

export const InvoiceDateSubContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const InvoicePreviewAmountContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-right: 10px;
    width: 120px;

    > div:first-child {
        margin-right: 5px;
    }
`;

export const InvoicePreviewContainer = styled.div``;

export const InvoicePreviewDateContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    > div:first-child {
        margin-right: 5px;
    }
`;

export const InvoicePreviewSubContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    
    :not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const InvoicesContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -30px;
    margin-top: -30px;
    width: 100%;
`;

export const MultiSelectRadioButton = styled.div`
    background-color: ${props => props.ReadOnly ? 'var(--white-concrete)' : props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    border: 2px solid var(--white-concrete);
    border-radius: 50%;
    height: 25px;
    max-width: 25px;
    width: 100%;

    @media(hover:hover) {
        :hover {
            ${props => !props.ReadOnly && `
                background-color: var(--blue-astronaut);
                cursor: pointer;
            `}
        }
    }
`;

export const NonAbelUserInfoModalInformationContainer = styled.div``;

export const OptionalLine = styled.div`
    border-bottom: 1px solid var(--gray-silver);
    line-height: 0.1em;
    margin: 10px auto;
    max-width: 800px;
    text-align: center;
    width: 100%;

    > span {
        background-color: var(--white); 
        padding: 0 10px; 
    }
`;

export const ProductDescriptionContainer = styled.div`
    :not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const ProductDescriptionsContainer = styled.div``;

export const ProductsListBlockContainer = styled.div`
    margin-right: 70px;
    margin-top: 30px;
`;

export const ProductsListBlockRowContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 30px;

    > div:first-child {
        margin-right: 5px;
    }
`;

export const ProductsListBlocksContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -70px;
    margin-top: -30px;
`;

export const ProductsListTableContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

export const ProductsListTableRowContainer = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    width: 100%;

    :not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const StyledSignContractPageText = styled(PageText)``;

export const SignContractCheckmarkContainer = styled.div`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 28px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 5px
    display: block;
    height: 28px;
    margin-right: 10px;
    min-width: 28px;
    width: 28px;

    ${StyledSignContractPageText}:hover & {
        background-image: url(${Checkmark});
        background-position: center;
        background-repeat: no-repeat;
        background-size: 28px;
        border: none;
        cursor: pointer;
    }

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 28px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const SignContractContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const StyledAmountInput = styled(InputLabelSideBySide)`
    width: 130px;

    > input {
        ${props => props.ReadOnly && 'border: none;'}
        border-radius: 10px;
        height: 100%;
        overflow: hidden;
        padding: 10px 15px;
        text-align: ${props => props.TextAlign ? props.TextAlign : 'center'};
        text-indent: 0px;
    }
`;

export const StyledDropdown = styled(Dropdown)`
    margin: 0 auto;
    min-height: 45px;
    width: 100%;
    word-break: none;

    ${props => props.ForceClose === false ? 'z-index: 1001;' : null};

    ${
        props => props.IsMobile ?
        `
            ${props.Height ? `height: ${props.Height + 45 + 15 < 45 ? 45 : props.Height + 45 + 15}px;` : 'height: inherit;'}
            
            > div { max-height: none; }
        `
    :
        `
            ${props.Height ? `height: ${props.Height + 45 + 15 < 45 ? 45 : props.Height + 45 + 15}px;` : 'height: inherit;'}
            margin: 0 auto;
        `
    }
`;

export const StyledHR = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    grid-column: 1/-1;
    height: 2px;
    margin: 10px 0;
`;

export const StyledInput = styled(Input)`
    ${props => props.InputWidth && `width: ${props.InputWidth};`}

    width: 100%;

    @media ${device.maxwidth.ipad} {
        max-width: 200px;
    }

    > input {
        ${props => props.ReadOnly && 'border: none;'}
        border-radius: 10px;
        height: 100%;
        overflow: hidden;
        padding: 10px 15px;
        text-align: ${props => props.TextAlign ? props.TextAlign : 'center'};
        text-indent: 0px;
    }
`;

export const StyledInputLabelSideBySide = styled(InputLabelSideBySide)`
    width: 100%;

    @media ${device.maxwidth.ipad} {
        max-width: 200px;
    }

    & input {
        border-radius: 10px;
    }
`;

export const StyledPaymentNumberPageText = styled(PageText)`
    width: 75px;
`;

export const StyledSplitUpInput = styled(Input)`
    margin: 0 auto;
    max-width: 85px;

    > input {
        ${props => props.ReadOnly && 'border: none;'}
        border-radius: 10px;
        height: 100%;
        overflow: hidden;
        padding: 10px 15px;
        text-align: center;
        text-indent: 0px;
    }
`;

export const TermsConditionsContainer = styled.div`
    font-family: var(--font-family-medium);
    font-size: var(--font-size-medium-1);
    margin: 0 auto;
    max-width: 1000px;
    white-space: pre-wrap;
`;

export const UnitListColumnContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: ${props => props.LeftAlign ? 'flex-start' : 'center'};
    flex-direction: column;

    ${props => props.ReadOnly &&
        `
            background-color: var(--white-concrete);
            border-radius: 10px;
        `
    }
`;

export const UnitListColumnSubContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div:first-child {
        margin-right: 5px;
    }
`;

export const UnitOptionsItem = styled.div`
    ${
        props => props.Selected ? `color: var(--blue-abel);`
    :
        props => props.Disabled ? `color: var(--gray);`
    :
        null
    }

    :not(:last-child) {
        margin-bottom: 5px;
    }

    ${props => !props.Disabled &&
        `
            @media(hover:hover) {
                :hover {
                    color: var(--blue-abel);
                    cursor: pointer;
                }
            }
        `
    }
`;

export const UnitOptionsList = styled.div``;

export const UnitsListContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
`;

export const UnitsListRow = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;

    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(3, 1fr);
    }

    @media ${device.minwidth.laptop} {
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    }
`;