import styled from 'styled-components';
import { device } from '../../Config/device';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Image from '../../Components/Image';
import Input from '../../Components/Form/Input';
import PageText from '../../Components/Text/PageText';

import Checkmark from '../../Assets/Icons/Checkmark_Selected.png';

export const ActiveSectionContainer = styled.div`
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px 25px;
    position: relative;
    width: 100%;
`;

export const BankAccountContainer = styled.div`
    > div {
        max-width: 100%;
    }
`;

export const CircleDiv = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 15px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    display: block;
    height: 15px;
    margin-right: 5px;
    margin-top: 3px;
    width: 15px;
    z-index: 1;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 15px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const Container = styled.div`
    align-items: center;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const FlagsContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const FlagContainer = styled.div`
    align-items: center;
    border: 2px solid ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white-concrete)'};
    border-radius: 27px;
    display: flex;
    flex-direction: row;
    height: 60px;
    justify-content: flex-start;
    padding: 15px;
    width: 100%;

    :not(:last-child) {
        margin-bottom: 15px;
    }

    @media ${device.minwidth.ipad} {
        width: 300px;
    }

    @media(hover:hover) {
        :hover {
            border: 2px solid var(--blue-astronaut);
            cursor: pointer;
        }
    }
`;

export const FlagImage = styled(Image)`
    height: 30px;
    margin-right: 15px;
`;

export const HeightFeetIcon = styled(PageText)`
    color: var(--gray-silver);
    line-height: 0;
`;

export const HeightInput = styled(Input)`
    > input {
        border: none;
        height: 46px;
        line-height: 48px;
        text-align: center;
        text-indent: 0px;
        padding: 0px;
        width: 50px;
    }
`;

export const NotificationCategory = styled.div`
    :not(:last-child) {
        margin-bottom: 25px;
    }
`;

export const NotificationSetting = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    :not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const PageContainer = styled.div`
    background-color: var(--chat-gray);
    border-radius: 10px;
    box-shadow: 1px 1px 8px #B5B5B529;
    overflow: hidden;
`;

export const ProfilePhotoContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;

    > div:first-child {
        margin-right: 15px;
    }
`;

export const SectionsContainer = styled.div`
    margin-right: -50px;
    margin-top: 10px;
    padding: 15px;

    > div > div {
        margin-right: 50px;
        margin-top: 15px;
    }
`;

export const ShowPasswordsContainer = styled.div`
    display: flex;
`;

export const StyledAccountInput = styled(Input)`
    ${props => props.MaxWidth && `width: ${props.MaxWidth};`}

    > input {
        border-radius: 10px;
    }
`;

export const StyledHeightPageText = styled(PageText)`
    margin-bottom: 10px;
`;

export const StyledHR = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    grid-column: 1/-1;
    height: 2px;
    margin: 10px 0;
`;

export const StyledHR2 = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    grid-column: 1/-1;
    height: 2px;
    margin: 10px 0;
    width: calc(100% - 50px);
`;

export const StyledSexButtonGroups = styled(ButtonGroup)`
    justify-content: flex-start;
    margin-top: 10px;
`;

export const UnitInputContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    width: 140px;
`;

export const UserDetailsContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(1, 1fr);

    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const UserDetailsItemContainer = styled.div``;