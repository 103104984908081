import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddWorkoutExercises, AddWorkoutSet, ChangeWorkoutExerciseMaxSet, ChangeWorkoutExerciseNote, DeleteWorkoutExercise, DeleteWorkoutSet, RegenerateTestSet, ReorderExercises, UpdateWorkoutSet } from '../../Services/Actions';

import { CircuitContainer, CircuitSeparator, StyledHR, WorkoutExercisesContainer } from './styles';

import ButtonGroup from '../Buttons/ButtonGroup';
import { GA_Event } from '../GoogleAnalytics';
import Loading from '../Loading';
import PageText from '../Text/PageText';
import Spacer from '../Spacer';
import WorkoutCircuits from '../WorkoutCircuits';
import { WorkoutExercise as WorkoutExerciseComponent } from '../WorkoutExercise';

import AddExercise from '../../Modals/AddExercise';
import ExerciseDetail from '../../Modals/ExerciseDetail';

import CheckmarkIcon from '../../Assets/Icons/Checkmark_White.png';
import Copy from '../../Assets/Icons/Copy_White.png';
import DeleteX from '../../Assets/Icons/X_White.png';
import Plus from '../../Assets/Icons/Plus.png';

class StrengthWorkout extends React.Component {
    state = {
        RealTimeSetUpdating: null,
        RegeneratingExerciseId: null,
        ShowAddExerciselModal: false,
        ShowExerciseDetailModal: false,
        ShowExerciseDetailPreview: {},
        ShowReorderExercises: false,
        WorkoutExercises: []
    };

    componentDidMount() {
        var { ExistingWorkoutId, WorkoutExercises } = this.props;

        if (!ExistingWorkoutId) console.log('!ExistingWorkoutId');
        else this.setState({ WorkoutExercises });

        if (this.props.WSClient) {
            this.props.WSClient.onmessage = message => {
                var data = JSON.parse(message.data);
    
                var { CircuitWorkoutExerciseIndex, MessageType, WorkoutExerciseIndex, WorkoutSet, WorkoutSetIndex, WorkoutId } = data;
    
                if (+WorkoutId === +ExistingWorkoutId && MessageType === 'WorkoutSetUpdated') {
                    var WorkoutExercises = [ ...this.state.WorkoutExercises ];
                    WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutSetIndex] = { ...WorkoutSet };
    
                    var RealTimeSetUpdating = { CircuitWorkoutExerciseIndex, WorkoutExerciseIndex, WorkoutSetIndex };
    
                    this.setState({ RealTimeSetUpdating, WorkoutExercises }, () => {
                        setTimeout(() => this.setState({ RealTimeSetUpdating: null }), 2000);
                    });
                }
            };
        }
    }

    onAddExercises = SelectedExercises => {
        var { ClientId, WorkoutDetails: { WorkoutId, WorkoutRM } } = this.props;

        var ExercisesLength = this.state.WorkoutExercises.length;

        var SelectedExercisesStr = SelectedExercises.map(({ ExerciseId, ExerciseType }, SelectedExerciseIndex) => `${ExerciseId},${ExerciseType},${ExercisesLength + SelectedExerciseIndex + 1}`).join(';');
        this.props.AddWorkoutExercises({ ClientId, SelectedExercises: SelectedExercisesStr, WorkoutId, WorkoutRM }).then(({ AddedExercises, WorkoutCompleted }) => {
            if (!this.props.TryingAddWorkoutExercisesError) {
                this.props.OnUpdateWorkoutDetails({ CanDelete: this.props.CanDelete, WorkoutCompleted });
                this.setState({ WorkoutExercises: [ ...this.state.WorkoutExercises, ...AddedExercises ] });
            }
        });
    }

    onAddSet = ({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex }) => {
        var OriginalWorkoutExercises = JSON.parse(JSON.stringify(this.state.WorkoutExercises));
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];
        
        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets = [
            ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets,
            { ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.length - 1], Completed: 0, MaxSet: 0, SetId: null }
        ];

        var { WorkoutExerciseId } = WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex];
        var { Break, Distance, Reps, Seconds, Weight }  = WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.length - 1];
        var { ClientId, WorkoutDetails: { WorkoutId } } = this.props;

        this.setState({ WorkoutExercises }, () => this.props.AddWorkoutSet({ ClientId, Distance, Reps, Rest: Break, Seconds, Weight, WorkoutExerciseId, WorkoutId }).then(({ WorkoutCompleted, WorkoutSetId }) => {
            if (this.props.TryingAddWorkoutSetError) this.setState({ WorkoutExercises: OriginalWorkoutExercises });
            else {
                WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.length - 1] = { ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.length - 1], SetId: WorkoutSetId };

                this.props.OnUpdateWorkoutDetails({ CanDelete: this.props.CanDelete, WorkoutCompleted });
                this.setState({ WorkoutExercises });
            }
        }));
    }

    onCancelCircuits = () => {
        this.setState({ ShowReorderExercises: false });
    }

    onChangeExercise = WorkoutExerciseIndex => {
        // console.log(`Change ${this.state.WorkoutExercises[WorkoutExerciseIndex].ExerciseName}`);
    }

    onChangeExerciseNote = ({ CircuitWorkoutExerciseIndex, Note, WorkoutExerciseId, WorkoutExerciseIndex }) => {
        var OriginalWorkoutExercises = JSON.parse(JSON.stringify(this.state.WorkoutExercises));
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];
        
        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Notes.Coach = Note;

        var { WorkoutDetails: { WorkoutId } } = this.props;

        this.setState({ WorkoutExercises }, () => this.props.ChangeWorkoutExerciseNote({ ExerciseNote: Note, WorkoutExerciseId, WorkoutId }).then(({ CanDelete }) => {
            if (this.props.TryingChangeWorkoutExerciseNoteError) this.setState({ WorkoutExercises: OriginalWorkoutExercises });
            else this.props.OnUpdateWorkoutDetails({ CanDelete, WorkoutCompleted: this.props.WorkoutCompleted });
        }));
    }

    onChangeMaxSet = ({ CircuitWorkoutExerciseIndex, IsCurrentMaxSet, WorkoutExerciseId, WorkoutExerciseIndex, WorkoutSetId, WorkoutSetIndex }) => {
        IsCurrentMaxSet = +IsCurrentMaxSet;

        var OriginalWorkoutExercises = JSON.parse(JSON.stringify(this.state.WorkoutExercises));
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];
        
        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets = WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.map((WorkoutSet, index) => ({ ...WorkoutSet, MaxSet: index === WorkoutSetIndex ? !IsCurrentMaxSet : 0 }));

        var { ClientId, WorkoutDetails: { WorkoutId } } = this.props;

        this.setState({ WorkoutExercises }, () => this.props.ChangeWorkoutExerciseMaxSet({ ClientId, IsCurrentMaxSet, WorkoutExerciseId, WorkoutId, WorkoutSetId }).then(({ CanDelete }) => {
            if (this.props.TryingChangeWorkoutExerciseMaxSetError) this.setState({ WorkoutExercises: OriginalWorkoutExercises });
            else this.props.OnUpdateWorkoutDetails({ CanDelete, WorkoutCompleted: this.props.WorkoutCompleted });
        }));
    }

    onDeleteExercise = ({ CircuitWorkoutExerciseIndex, WorkoutExerciseId, WorkoutExerciseIndex }) => {
        var OriginalWorkoutExercises = JSON.parse(JSON.stringify(this.state.WorkoutExercises));

        var NewWorkoutExercises = [];
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        WorkoutExercises.map((Circuit, index) => {
            NewWorkoutExercises.push({ ...Circuit });

            if (WorkoutExerciseIndex === index) {
                NewWorkoutExercises[NewWorkoutExercises.length - 1].WorkoutExercises = [];

                Circuit.WorkoutExercises.map((WE, WEIndex) => {
                    if (WEIndex !== CircuitWorkoutExerciseIndex) NewWorkoutExercises[NewWorkoutExercises.length - 1].WorkoutExercises.push({ ...WE });
                    
                    return null;
                });
            }

            return null;
        });

        var CircuitNumber = 1;
        var UpdatableCircuits = NewWorkoutExercises.map(Circuit => {
            Circuit.NumExercises = Circuit.WorkoutExercises.length;

            if (Circuit.WorkoutExercises.length === 1) Circuit = { ...Circuit, CircuitNumber: 0, Type: 'WorkoutExercise' };
            else {
                Circuit.CircuitNumber = CircuitNumber;
                CircuitNumber++;
            }

            return Circuit;
        }).filter(Circuit => !!Circuit.NumExercises);

        var { ClientId, WorkoutDetails: { WorkoutId } } = this.props;

        this.setState({ WorkoutExercises: UpdatableCircuits }, () => this.props.DeleteWorkoutExercise({ ClientId, WorkoutExerciseId, WorkoutId }).then(({ WorkoutCompleted }) => {
            if (this.props.TryingDeleteWorkoutExerciseError) this.setState({ WorkoutExercises: OriginalWorkoutExercises });
            else this.props.OnUpdateWorkoutDetails({ CanDelete: this.props.CanDelete, WorkoutCompleted });
        }));
    }

    onDeleteSet = ({ CircuitWorkoutExerciseIndex, WorkoutExerciseId, WorkoutExerciseIndex, WorkoutSetId, WorkoutSetIndex }) => {
        var OriginalWorkoutExercises = JSON.parse(JSON.stringify(this.state.WorkoutExercises));
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        // Delete Individual Set
            if (WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.length > 1) {
                WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets = [ ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.slice(0, WorkoutSetIndex), ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets.slice(WorkoutSetIndex + 1) ];

                var { ClientId, WorkoutDetails: { WorkoutId } } = this.props;

                this.setState({ WorkoutExercises }, () => this.props.DeleteWorkoutSet({ ClientId, WorkoutId, WorkoutSetId }).then(({ WorkoutCompleted }) => {
                    if (this.props.TryingDeleteWorkoutSetError) this.setState({ WorkoutExercises: OriginalWorkoutExercises });
                    else this.props.OnUpdateWorkoutDetails({ CanDelete: this.props.CanDelete, WorkoutCompleted });
                }));
            }
        // Deleting last set, so remove full Workout Exercise
            else this.onDeleteExercise({ CircuitWorkoutExerciseIndex, WorkoutExerciseId, WorkoutExerciseIndex });
    }

    onMarkCompleted = () => {
        var OriginalWorkoutExercises = JSON.parse(JSON.stringify(this.state.WorkoutExercises));
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];

        WorkoutExercises = WorkoutExercises.map(WorkoutExercise => ({ ...WorkoutExercise, WorkoutExercises: WorkoutExercise.WorkoutExercises.map(CircuitWorkoutExercise => ({ ...CircuitWorkoutExercise, Sets: CircuitWorkoutExercise.Sets.map(WorkoutSet => ({ ...WorkoutSet, Completed: 1 })) })) }));

        this.setState({ WorkoutExercises }, () => this.props.OnMarkCompleted().then(({ Response }) => {
            if (!Response) this.setState({ WorkoutExercises: OriginalWorkoutExercises });
        }));
    }

    onRegenerateMax = ({ CircuitWorkoutExerciseIndex, MaxDistance, MaxReps, MaxSeconds, MaxWeight, WorkoutExerciseIndex }) => {
        var { ClientDetails: { UserUnits }, ClientId, WorkoutDetails: { WorkoutId, WorkoutRM } } = this.props;
        var { ExerciseId, ExerciseType, WorkoutExerciseId } = this.state.WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex];

        this.setState({ RegeneratingExerciseId: ExerciseId }, () => {
            this.props.RegenerateTestSet({ ClientId, Distance: MaxDistance, ExerciseId, ExerciseType, Reps: MaxReps, Seconds: MaxSeconds, Units: UserUnits.toLowerCase(), Weight: MaxWeight, WorkoutExerciseId, WorkoutId, WorkoutRM }).then(({ Exercise }) => {
                if (!this.props.TryingRegenerateTestSetError) {
                    var WorkoutExercises = [ ...this.state.WorkoutExercises ];
                    WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex] = Exercise;

                    this.setState({ RegeneratingExerciseId: null, WorkoutExercises });
                } else this.setState({ RegeneratingExerciseId: null });
            });
        });
    }

    onSaveCircuits = WorkoutExercises => {
        var OriginalWorkoutExercises = JSON.parse(JSON.stringify(this.state.WorkoutExercises));

        var OriginalExerciseOrder = this.state.WorkoutExercises.map(({ CircuitNumber, WorkoutExercises }) => WorkoutExercises.map(({ ExerciseId }) => `${ExerciseId},${CircuitNumber || 0}`).join(';')).join(';');
        var ExerciseOrder = WorkoutExercises.map(({ CircuitNumber, WorkoutExercises }) => WorkoutExercises.map(({ ExerciseId }) => `${ExerciseId},${CircuitNumber || 0}`).join(';')).join(';');

        if (OriginalExerciseOrder === ExerciseOrder) {
            this.setState({ ShowReorderExercises: false, WorkoutExercises });
            GA_Event({ Category: 'Workout', Action: 'Reorder Exercises', Label: 'Hide Reorder Exercises - No Update' });
        }
        else {
            var { ClientId, WorkoutDetails: { WorkoutId } } = this.props;

            this.setState({ ShowReorderExercises: false, WorkoutExercises }, () => this.props.ReorderExercises({ ClientId, ExerciseOrder, WorkoutId }).then(() => {
                GA_Event({ Category: 'Workout', Action: 'Reorder Exercises', Label: 'Hide Reorder Exercises - With Update' });
                if (this.props.TryingReorderExercisesError) this.setState({ WorkoutExercises: OriginalWorkoutExercises });
            }));
        }
    }

    onToggleSetCompleted = ({ CircuitWorkoutExerciseIndex, Completed, MaxSet, WorkoutExerciseIndex, WorkoutSetIndex }) => {
        // if (!!Completed && MaxSet) return null;
        
        var { ExerciseId, ExerciseType, WorkoutExerciseId } = this.state.WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex];
        var { Break, Distance, Reps, Seconds, SetId: WorkoutSetId, Weight } = this.state.WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutSetIndex];

        Completed = !!!Completed;

        var OriginalWorkoutExercises = JSON.parse(JSON.stringify(this.state.WorkoutExercises));
        var WorkoutExercises = [ ...this.state.WorkoutExercises ];
        WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutSetIndex] = { ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutSetIndex], Completed: +Completed };

        this.setState({ WorkoutExercises }, () => this.onUpdateWorkoutSet({ Completed, Distance, ExerciseId, ExerciseType, MaxSet, Reps, Seconds, TotalBreak: Break, UpdateState: false, Weight, WorkoutExerciseId, WorkoutSetId }).then(({ CanDelete, WorkoutCompleted }) => {
            if (this.props.TryingUpdateWorkoutSetError) this.setState({ WorkoutExercises: OriginalWorkoutExercises });
            else this.props.OnUpdateWorkoutDetails({ CanDelete, WorkoutCompleted });
        }));
    }

    onToggleShowAddExerciseModal = ShowAddExerciselModal => {
        this.setState({ ShowAddExerciselModal });
    }

    onToggleShowExerciseDetailModal = (ShowExerciseDetailModal, Exercise = {}) => {
        this.setState({ ShowExerciseDetailModal, ShowExerciseDetailPreview: Exercise });
    }

    onToggleShowReorderExercises = ShowReorderExercises => {
        if (ShowReorderExercises) GA_Event({ Category: 'Workout', Action: 'Reorder Exercises', Label: 'Show Reorder Exercises' });
        this.setState({ ShowReorderExercises });
    }

    onUpdateWorkoutSet = ({ CircuitWorkoutExerciseIndex, Completed, Distance, ExerciseId, ExerciseType, MaxSet, Reps, Seconds, TotalBreak: Rest, UpdateState, Weight, WorkoutExerciseId, WorkoutExerciseIndex, WorkoutSetId, WorkoutSetIndex }) => {
        var { ClientId, WorkoutDetails: { WorkoutId } } = this.props;

        Completed = +Completed;
        MaxSet = +MaxSet;

        if (Distance) Distance = +Distance;
        if (Reps) Reps = +Reps;
        if (Rest) Rest = +Rest;
        if (Seconds) Seconds = +Seconds;
        if (Weight) Weight = +Weight;

        return this.props.UpdateWorkoutSet({ ClientId, Completed, Distance, ExerciseId, ExerciseType, MaxSet, Reps, Rest, Seconds, Weight, WorkoutExerciseId, WorkoutId, WorkoutSetId }).then(({ CanDelete, WorkoutCompleted }) => {
            if (!this.props.TryingUpdateWorkoutSetError && UpdateState !== false) {
                var WorkoutExercises = [ ...this.state.WorkoutExercises ];
                var WorkoutSet = { ...WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutSetIndex], Break: Rest, Completed, ...Distance && { Distance }, ...Reps && { Reps }, ...Seconds && { Seconds }, ...Weight && { Weight } };
                WorkoutExercises[WorkoutExerciseIndex].WorkoutExercises[CircuitWorkoutExerciseIndex].Sets[WorkoutSetIndex] = WorkoutSet;
                
                this.setState({ WorkoutExercises }, () => {
                    if (this.props.WSClient) this.props.WSClient.send(JSON.stringify({ CircuitWorkoutExerciseIndex, MessageType: 'WorkoutSetUpdated', WorkoutExerciseIndex, WorkoutSet, WorkoutSetIndex, WorkoutId }));
                });
            }

            return { CanDelete, WorkoutCompleted };
        });
    }

    renderAddExerciseModal = () => {
        var { ShowAddExerciselModal, ShowExerciseDetailModal } = this.state;

        if (ShowAddExerciselModal) {
            return (
                <AddExercise
                    DisplayNone={ShowExerciseDetailModal}
                    ExcludeExercises={this.state.WorkoutExercises.map(({ WorkoutExercises }) => WorkoutExercises.map(({ ExerciseId }) => +ExerciseId))}
                    OnAddExercises={SelectedExercises => this.onAddExercises(SelectedExercises)}
                    OnHide={() => this.onToggleShowAddExerciseModal(false)}
                    OnToggleShowExerciseDetailModal={(ShowExerciseDetailModal, Exercise) => this.onToggleShowExerciseDetailModal(ShowExerciseDetailModal, Exercise)}
                    Show={ShowAddExerciselModal}
                />
            );
        }
    }

    renderCircuitSeparator = ({ CircuitType }) => {
        var { t } = this.props;

        return (
            <CircuitSeparator BackgroundColor={CircuitType === 'Circuit' ? 'turquoise-dark' : 'blue-royal'}>
                <PageText FontColor="white" FontFamily="bold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t(`Workout_Set_Type_${CircuitType}`)} TextAlign="center" />
            </CircuitSeparator>
        );
    }

    renderExerciseDetailModal = () => {
        var { ShowExerciseDetailModal, ShowExerciseDetailPreview } = this.state;

        if (ShowExerciseDetailModal) {
            return (
                <ExerciseDetail
                    ClientId={this.props.ClientId}
                    ExerciseDetail_Preview={ShowExerciseDetailPreview}
                    OnHide={() => this.onToggleShowExerciseDetailModal(false)}
                    Show={ShowExerciseDetailModal}
                />
            );
        }
    }

    renderReorderExercises = () => {
        var { WorkoutExercises } = this.state;

        return <WorkoutCircuits Circuits={WorkoutExercises} OnCancelCircuits={this.onCancelCircuits} OnSaveCircuits={this.onSaveCircuits} />;
    }

    renderWorkoutExercises = () => {
        var { RealTimeSetUpdating, RegeneratingExerciseId, WorkoutExercises } = this.state;

        return (
            <WorkoutExercisesContainer className="WorkoutExercisesContainer">
                {
                    WorkoutExercises.map((WorkoutExercise, WorkoutExerciseIndex) => {
                        var CircuitType = WorkoutExercise.NumExercises >= 3 ? 'Circuit' : WorkoutExercise.NumExercises === 2 ? 'Superset' : 'WorkoutExercise';

                        return (
                            <React.Fragment key={WorkoutExercise.WorkoutExercises[0].ExerciseId}>
                                {WorkoutExerciseIndex !== 0 && <Spacer Size="medium" />}

                                <CircuitContainer CircuitType={CircuitType}>
                                    {
                                        WorkoutExercise.WorkoutExercises.map((CircuitWorkoutExercise, CircuitWorkoutExerciseIndex) => { 
                                            var TryingRegenerateTestSet = this.props.TryingRegenerateTestSet && RegeneratingExerciseId === CircuitWorkoutExercise.ExerciseId;

                                            return (
                                                <React.Fragment key={CircuitWorkoutExercise.ExerciseId}>
                                                    {CircuitWorkoutExerciseIndex !== 0 && this.renderCircuitSeparator({ CircuitType })}
                
                                                    <WorkoutExerciseComponent
                                                        CircuitType={CircuitType}
                                                        Exercise={CircuitWorkoutExercise}
                                                        OnAddSet={() => this.onAddSet({ CircuitWorkoutExerciseIndex, WorkoutExerciseIndex })}
                                                        OnChangeExercise={() => this.onChangeExercise(WorkoutExerciseIndex)}
                                                        OnChangeExerciseNote={Note => this.onChangeExerciseNote({ CircuitWorkoutExerciseIndex, Note, WorkoutExerciseId: CircuitWorkoutExercise.WorkoutExerciseId, WorkoutExerciseIndex })}
                                                        OnChangeMaxSet={(IsCurrentMaxSet, WorkoutSetId, WorkoutSetIndex) => this.onChangeMaxSet({ CircuitWorkoutExerciseIndex, IsCurrentMaxSet, WorkoutExerciseId: CircuitWorkoutExercise.WorkoutExerciseId, WorkoutExerciseIndex, WorkoutSetId, WorkoutSetIndex })}
                                                        OnDeleteExercise={() => this.onDeleteExercise({ CircuitWorkoutExerciseIndex, WorkoutExerciseId: CircuitWorkoutExercise.WorkoutExerciseId, WorkoutExerciseIndex })}
                                                        OnDeleteSet={(WorkoutSetId, WorkoutSetIndex) => this.onDeleteSet({ CircuitWorkoutExerciseIndex, WorkoutExerciseId: CircuitWorkoutExercise.WorkoutExerciseId, WorkoutExerciseIndex, WorkoutSetId, WorkoutSetIndex })}
                                                        OnRegenerateMax={({ MaxDistance, MaxReps, MaxSeconds, MaxWeight }) => this.onRegenerateMax({ CircuitWorkoutExerciseIndex, MaxDistance, MaxReps, MaxSeconds, MaxWeight, WorkoutExerciseIndex })}
                                                        OnToggleSetCompleted={(Completed, MaxSet, WorkoutSetIndex) => this.onToggleSetCompleted({ CircuitWorkoutExerciseIndex, Completed, MaxSet, WorkoutExerciseIndex, WorkoutSetIndex })}
                                                        OnToggleShowExerciseDetailModal={(ShowExerciseDetailModal, Exercise) => this.onToggleShowExerciseDetailModal(ShowExerciseDetailModal, Exercise)}
                                                        OnUpdateWorkoutSet={({ Completed, Distance, ExerciseId, ExerciseType, MaxSet, Reps, Seconds, TotalBreak, UpdateState, Weight, WorkoutExerciseId, WorkoutSetId, WorkoutSetIndex }) => this.onUpdateWorkoutSet({ CircuitWorkoutExerciseIndex, Completed, Distance, ExerciseId, ExerciseType, MaxSet, Reps, Seconds, TotalBreak, UpdateState, Weight, WorkoutExerciseId, WorkoutExerciseIndex, WorkoutSetId, WorkoutSetIndex })}
                                                        RealTimeSetUpdatingIndex={RealTimeSetUpdating && RealTimeSetUpdating.CircuitWorkoutExerciseIndex === CircuitWorkoutExerciseIndex && RealTimeSetUpdating.WorkoutExerciseIndex === WorkoutExerciseIndex && RealTimeSetUpdating.WorkoutSetIndex ? RealTimeSetUpdating.WorkoutSetIndex : -1}
                                                        TryingRegenerateTestSet={TryingRegenerateTestSet}
                                                    />
                                                </React.Fragment>
                                            );
                                        })
                                    }    
                                </CircuitContainer>
                            </React.Fragment>
                        );
                    })
                }
            </WorkoutExercisesContainer>
        );
    }

    renderWorkoutOptions = () => {
        // Complete / Incomplete, Delete Workout
        var { t } = this.props;
        var { Device, CanDelete, Completed, OnCopyWorkoutToTemplate, OnDeleteWorkout } = this.props;

        var Buttons = [];

        if (!+Completed) Buttons.push({ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Icon: CheckmarkIcon, IconPosition: 'left', FontFamily: 'semibold', OnClick: this.onMarkCompleted, Title: t('Mark_as_completed') });
        if (CanDelete) Buttons.push({ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: DeleteX, IconPosition: 'left', OnClick: OnDeleteWorkout, Title: t('WorkoutPlan_Delete_workout') });
        Buttons.push({ BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: Copy, IconPosition: 'left', OnClick: OnCopyWorkoutToTemplate, Title: t('copy_workout_to_template') });

        return (
            <ButtonGroup
                Buttons={Buttons}
                ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                NotTouching
                OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
            />
        );
    }

    render() {
        var { t } = this.props;
        var { Device, TryingAddWorkoutExercises, TryingAddWorkoutSet, TryingChangeWorkoutExerciseMaxSet, TryingChangeWorkoutExerciseNote, TryingCompleteWorkout, TryingDeleteWorkout, TryingDeleteWorkoutExercise, TryingDeleteWorkoutSet, TryingReorderExercises, TryingUpdateWorkoutSet } = this.props;
        var { ShowReorderExercises } = this.state;

        if (ShowReorderExercises) return this.renderReorderExercises();

        return (
            <>
                {(TryingAddWorkoutExercises || TryingAddWorkoutSet || TryingChangeWorkoutExerciseMaxSet || TryingChangeWorkoutExerciseNote || TryingCompleteWorkout || TryingDeleteWorkout || TryingDeleteWorkoutExercise || TryingDeleteWorkoutSet || TryingReorderExercises || TryingUpdateWorkoutSet) ? <Loading /> : null}

                {this.renderWorkoutExercises()}

                <Spacer Size="medium" />

                <ButtonGroup
                    Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onToggleShowAddExerciseModal(true), Title: t('WorkoutPlan_add_new_exercise') }]}
                    ButtonWidth="100%"
                />

                <Spacer Size="medium" />

                <ButtonGroup
                    Buttons={[{ BackgroundColor: 'white-concrete', BackgroundColorHover: 'white-concrete-hover', Border: 'none', FontFamily: 'semibold', OnClick: () => this.onToggleShowReorderExercises(true), Title: t('WorkoutPlan_reorder_exercises') }]}
                    ButtonWidth={Device === 'laptop' || Device === 'ipad' ? 'fit-content' : '100%'}
                />

                <Spacer Size="medium" />

                <StyledHR />

                <Spacer Size="medium" />

                {this.renderWorkoutOptions()}

                {this.renderAddExerciseModal()}
                {this.renderExerciseDetailModal()}
            </>
        );
    }
}

StrengthWorkout.propTypes = {
    CanDelete: PropTypes.bool,
    ClientId: PropTypes.number,
    Completed: PropTypes.bool,
    ExistingWorkoutId: PropTypes.number,
    OnCopyWorkoutToTemplate: PropTypes.func.isRequired,
    OnDeleteWorkout: PropTypes.func.isRequired,
    OnMarkCompleted: PropTypes.func.isRequired,
    OnUpdateWorkoutDetails: PropTypes.func.isRequired,
    TryingCompleteWorkout: PropTypes.bool.isRequired,
    TryingDeleteWorkout: PropTypes.bool.isRequired,
    WorkoutDetails: PropTypes.object.isRequired,
    WorkoutExercises: PropTypes.array.isRequired,
}

StrengthWorkout.defaultProps = {
    ExistingWorkoutId: 0
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        WSClient: state.Window.WSClient,

        ClientDetails: state.Client.ClientDetails,

        TryingAddWorkoutExercises: state.Workout.TryingAddWorkoutExercises,
        TryingAddWorkoutExercisesError: state.Workout.TryingAddWorkoutExercisesError,
        TryingAddWorkoutSet: state.Workout.TryingAddWorkoutSet,
        TryingAddWorkoutSetError: state.Workout.TryingAddWorkoutSetError,
        TryingChangeWorkoutExerciseMaxSet: state.Workout.TryingChangeWorkoutExerciseMaxSet,
        TryingChangeWorkoutExerciseMaxSetError: state.Workout.TryingChangeWorkoutExerciseMaxSetError,
        TryingChangeWorkoutExerciseNote: state.Workout.TryingChangeWorkoutExerciseNote,
        TryingChangeWorkoutExerciseNoteError: state.Workout.TryingChangeWorkoutExerciseNoteError,
        TryingDeleteWorkoutExercise: state.Workout.TryingDeleteWorkoutExercise,
        TryingDeleteWorkoutExerciseError: state.Workout.TryingDeleteWorkoutExerciseError,
        TryingDeleteWorkoutSet: state.Workout.TryingDeleteWorkoutSet,
        TryingDeleteWorkoutSetError: state.Workout.TryingDeleteWorkoutSetError,
        TryingRegenerateTestSet: state.Workout.TryingRegenerateTestSet,
        TryingRegenerateTestSetError: state.Workout.TryingRegenerateTestSetError,
        TryingReorderExercises: state.Workout.TryingReorderExercises,
        TryingReorderExercisesError: state.Workout.TryingReorderExercisesError,
        TryingUpdateWorkoutSet: state.Workout.TryingUpdateWorkoutSet,
        TryingUpdateWorkoutSetError: state.Workout.TryingUpdateWorkoutSetError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddWorkoutExercises, AddWorkoutSet, ChangeWorkoutExerciseMaxSet, ChangeWorkoutExerciseNote, DeleteWorkoutExercise, DeleteWorkoutSet, RegenerateTestSet, ReorderExercises, UpdateWorkoutSet } )(StrengthWorkout));