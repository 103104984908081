import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CancelSubscription, ChangeToBusinessFromOrgMember, ChoosePrimaryOrganization, DeleteOrganizationMember, DownloadSubscriptionReceipt, LeaveOrganization, ReactivateMembership, RegisterUserInPaymentGateway, UpdateOrganizationMemberInvitation, ViewOrganizationMembers, ViewSubscriptionBillingHistory } from '../../Services/Actions';
import moment from 'moment';

import * as styles from './SubscriptionStyles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Info from '../../Modals/Info';

import BusinessIcon from '../../Assets/Icons/Subscription_Business.png';
import MasterCard from '../../Assets/Icons/Payments/MasterCard.png';
import OrganizationIcon from '../../Assets/Icons/Subscription_Organization.png';
import PDF from '../../Assets/Icons/PDF.png';
import Vipps from '../../Assets/Icons/Payments/Vipps.png';
import Visa from '../../Assets/Icons/Payments/Visa.png';

import { SUBSCRIPTION_CANCEL_URL, SUBSCRIPTION_RETURN_URL } from '../../Config';

const SubscriptionsContent = {
    '1': {
        Color: 'yellow-gold',
        Image: BusinessIcon,
        MeantFor: [
            { Id: 1, Text: 'personal_trainers' },
            { Id: 2, Text: 'nutritionists' },
            { Id: 3, Text: 'physical_therapists' }
        ],
        Name: 'Business',
        Price: {
            '1': { Amount: 99, Currency: 'USD' },
            '3': { Amount: 999, Currency: 'NOK' },
        },
        WhatYouGet: [
            { Id: 1, Text: 'unlimited_clients' },
            { Id: 2, Text: 'can_take_payments' }
        ]
    },
    '3': {
        Color: 'red',
        Image: OrganizationIcon,
        MeantFor: [
            { Id: 1, Text: 'meantfor_members_of_organizations' }
        ],
        Name: 'OrgMember',
        Price: {
            '1': { Amount: 0, Currency: 'USD' },
            '3': { Amount: 0, Currency: 'NOK' },
        },
        WhatYouGet: []
    }
}

class SubscriptionsComponent extends React.Component {
    state = {
        AcceptMembership: null,
        BillingHistory: [],
        DeleteMembership: null,
        LeaveOrganization: null,
        Loading: false,
        PrimaryOrganizationUserId: null,
        ShowAcceptMembershipModal: false,
        ShowBillingHistory: false,
        ShowChangeToBusinessFromOrgMemberModal: false,
        ShowDeleteMembershipModal: false,
        ShowLeaveOrganizationModal: false,
        TryingGetBillingHistory: false
    };

    componentDidMount() {
        this.setState({ PrimaryOrganizationUserId: this.props.UserDetails.SubscriptionStatus.primaryOrganizationUserId });
    }

    onAcceptMembership = () => {
        this.props.UpdateOrganizationMemberInvitation({ Acceptance: 1, OrganizationId: this.state.AcceptMembership.OrganizationId, OrganizationUserId: this.state.AcceptMembership.OrganizationUserId }).then(() => this.setState({ AcceptMembership: null, ShowAcceptMembershipModal: false }));
    }

    onAddCardDetails = () => {
        this.setState({ Loading: true }, () => {
            this.props.RegisterUserInPaymentGateway({ CancelUrl: SUBSCRIPTION_CANCEL_URL, ReturnUrl: `${SUBSCRIPTION_RETURN_URL}/RegisterUser` }).then(({ hostedPaymentPageUrl }) => {
                window.location.href = hostedPaymentPageUrl;
            });
        });
    }

    onCancelMembership = () => {
        this.props.CancelSubscription();
    }
    
    onChangeCard = () => {
        this.setState({ Loading: true }, () => {
            this.props.RegisterUserInPaymentGateway({ CancelUrl: SUBSCRIPTION_CANCEL_URL, ReturnUrl: `${SUBSCRIPTION_RETURN_URL}/RegisterUser` }).then(({ hostedPaymentPageUrl }) => {
                window.location.href = hostedPaymentPageUrl;
            });
        });
    }

    onChangeToBusinessFromOrgMember = () => {
        this.props.ChangeToBusinessFromOrgMember().then(() => this.setState({ ShowChangeToBusinessFromOrgMemberModal: false }));
    }

    onChoosePrimaryOrganization = ({ OrganizationUserId }) => {
        var { PrimaryOrganizationUserId } = this.state;
        
        if (+OrganizationUserId !== PrimaryOrganizationUserId) {
            this.setState({ PrimaryOrganizationUserId: OrganizationUserId }, () => {
                this.props.ChoosePrimaryOrganization({ OrganizationUserId });
            });
        }
    }

    onDeleteMembership = ({ OrganizationId }) => {
        if (!OrganizationId) OrganizationId = this.state.DeleteMembership.OrganizationId;

        this.props.UpdateOrganizationMemberInvitation({ Acceptance: 0, OrganizationId, OrganizationUserId: this.state.AcceptMembership.OrganizationUserId }).then(() => this.setState({ DeleteMembership: null, ShowDeleteMembershipModal: false, AcceptMembership: null, ShowAcceptMembershipModal: false }));
    }

    onLeaveOrganization = () => {
        this.props.LeaveOrganization({ OrganizationId: this.state.LeaveOrganization.OrganizationId, OrganizationUserId: this.state.LeaveOrganization.OrganizationUserId }).then(() => this.setState({ LeaveOrganization: null, ShowLeaveOrganizationModal: false }));
    }

    onReactivateMembership = () => {
        this.props.ReactivateMembership();
    }

    onToggleShowAcceptMembershipModal = ({ AcceptMembership = null, ShowAcceptMembershipModal }) => {
        this.setState({ AcceptMembership, ShowAcceptMembershipModal });
    }

    onToggleShowBillingHistory = ShowBillingHistory => {
        if (ShowBillingHistory) {
            this.setState({ ShowBillingHistory, TryingGetBillingHistory: true }, () => {
                this.props.ViewSubscriptionBillingHistory().then(BillingHistory => {
                    this.setState({ BillingHistory, TryingGetBillingHistory: false });
                })
            });
        }
        else this.setState({ ShowBillingHistory });
    }

    onToggleShowChangeToBusinessFromOrgMemberModal = ShowChangeToBusinessFromOrgMemberModal => {
        this.setState({ ShowChangeToBusinessFromOrgMemberModal });
    }

    onToggleShowDeleteMembershipModal = ({ DeleteMembership = null, ShowDeleteMembershipModal }) => {
        this.setState({ DeleteMembership, ShowDeleteMembershipModal });
    }

    onToggleShowLeaveOrganizationModal = ({ LeaveOrganization = null, ShowLeaveOrganizationModal }) => {
        this.setState({ LeaveOrganization, ShowLeaveOrganizationModal });
    }

    onViewSubscriptionReceipt = TransactionId => {
        this.props.DownloadSubscriptionReceipt({ TransactionId });
    }

    renderAcceptMembership = () => {
        var { t } = this.props;
        var { AcceptMembership } = this.state;

        return (
            <styles.AcceptMembershipContainer className="AcceptMembershipContainer">
                <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text={t('organization_membership_terms_title')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium" JustifyContent="center" NoMargin Text={t('organization_membership_terms', { OrgName: AcceptMembership.OrgName })} TextAlign="center" />
            </styles.AcceptMembershipContainer>
        );
    }

    renderAcceptMembershipBottomButton = () => {
        var { t } = this.props;

        var Buttons = [];
        Buttons.push({ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onDeleteMembership({ OrganizationId: this.state.AcceptMembership.OrganizationId }), Title: t('do_not_agree_to_join_organization') });
        Buttons.push({ BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: this.onAcceptMembership, Title: t('agree') });

        return (
            <styles.StyledAcceptMembershipBottomButtons Buttons={Buttons} />
        );
    }

    renderAcceptMembershipModal = () => {
        var { ShowAcceptMembershipModal } = this.state;

        if (this.state.ShowAcceptMembershipModal) {
            return (
                <Info
                    BottomButtonRender={this.renderAcceptMembershipBottomButton}
                    InformationRenderer={this.renderAcceptMembership}
                    Loading={this.props.TryingUpdateOrganizationMembershipStatus}
                    OnHide={() => this.onToggleShowAcceptMembershipModal({ ShowAcceptMembershipModal: false })}
                    Show={ShowAcceptMembershipModal}
                    Size="medium"
                />
            );
        }
    }

    renderBillingHistory = () => {
        var { t } = this.props;
        var { SubscriptionStatus } = this.props.UserDetails;
        var { BillingHistory } = this.state;

        if (!BillingHistory.length) {
            return (
                <styles.BillingHistoryContainer>
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('subscription_no_billing_history')} TextAlign="left" />
                </styles.BillingHistoryContainer>
            );
        }

        return (
            <styles.BillingHistoryContainer className="BillingHistoryContainer">
                {
                    SubscriptionStatus.subscriberTypeId !== 3 &&
                    <>
                        <styles.BillingHistorySummaryContainer className="BillingHistorySummaryContainer">
                            <styles.BillingHistorySummaryYourPlanContainer className="BillingHistorySummaryYourPlanContainer">
                                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('subscription_your_plan')} TextAlign="left" />

                                <Spacer Size="extra-extra-small" />

                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={SubscriptionStatus.subscriberType} TextAlign="left" />

                                <Spacer Size="extra-extra-small" />

                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${SubscriptionStatus.subAmountText} ${t('subscription_per_month')}`} TextAlign="left" />
                            </styles.BillingHistorySummaryYourPlanContainer>
                            <styles.BillingHistorySummaryNextBillContainer className="BillingHistorySummaryNextBillContainer">
                                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('subscription_your_next_bill')} TextAlign="left" />

                                <Spacer Size="extra-extra-small" />

                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={moment(SubscriptionStatus.activeExpiration).format('[NumbersDate]')} TextAlign="left" />
                            </styles.BillingHistorySummaryNextBillContainer>
                        </styles.BillingHistorySummaryContainer>

                        <Spacer Size="small" />
                    </>
                }

                <styles.BillingHistoryTable className="BillingHistoryTable">
                    <styles.BillingHistoryTableRow className="BillingHistoryTableRow">
                        <styles.BillingHistoryTableColumn className="BillingHistoryTableColumn">
                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('Date_str')} TextAlign="left" />
                        </styles.BillingHistoryTableColumn>
                        <styles.BillingHistoryTableColumn className="BillingHistoryTableColumn">
                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('description')} TextAlign="left" />
                        </styles.BillingHistoryTableColumn>
                        <styles.BillingHistoryTableColumn className="BillingHistoryTableColumn">
                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('subscription_service_period')} TextAlign="left" />
                        </styles.BillingHistoryTableColumn>
                        <styles.BillingHistoryTableColumn className="BillingHistoryTableColumn">
                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('payment_method')} TextAlign="left" />
                        </styles.BillingHistoryTableColumn>
                        <styles.BillingHistoryTableColumn className="BillingHistoryTableColumn">
                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('total')} TextAlign="left" />
                        </styles.BillingHistoryTableColumn>
                    </styles.BillingHistoryTableRow>

                    <styles.StyledHR />

                    {
                        BillingHistory.map(HistoryRecord => {
                            var { invoiceAmountText, invoiceDate, invoiceNo, maskedCardPan, paymentMethod, promotionBonusName, serviceStartDate, serviceStopDate, transactionAmount, transactionId } = HistoryRecord;

                            return (
                                <React.Fragment key={`${invoiceNo}`}>
                                    <styles.BillingHistoryTableRow className="BillingHistoryTableRow">
                                        <styles.BillingHistoryTableColumn className="BillingHistoryTableColumn">
                                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={moment(invoiceDate).format('[NumbersDate]')} TextAlign="left" />
                                        </styles.BillingHistoryTableColumn>
                                        <styles.BillingHistoryTableColumn className="BillingHistoryTableColumn">
                                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={promotionBonusName || t('subscription_abel_description')} TextAlign="left" />
                                        </styles.BillingHistoryTableColumn>
                                        <styles.BillingHistoryTableColumn className="BillingHistoryTableColumn">
                                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${moment(serviceStartDate).format('[NumbersDate]')} - ${moment(serviceStopDate).format('[NumbersDate]')}`} TextAlign="left" />
                                        </styles.BillingHistoryTableColumn>
                                        <styles.BillingHistoryTableColumn className="BillingHistoryTableColumn">
                                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={paymentMethod ? `${paymentMethod} ${maskedCardPan}` : 'N/A'} TextAlign="left" />
                                        </styles.BillingHistoryTableColumn>
                                        <styles.BillingHistoryTableColumn className="BillingHistoryTableColumn">
                                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={invoiceAmountText} TextAlign="left" />

                                            {
                                                transactionAmount > 0 &&
                                                <styles.DownloadContainer>
                                                    <styles.StyledPDFIcon ImageSrc={PDF} OnClick={() => this.onViewSubscriptionReceipt(transactionId)} />
                                                    <PageText FontColor="blue-abel" FontColorHover="blue-abel-hover" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onViewSubscriptionReceipt(transactionId)} Text={t('download.pdf')} TextAlign="left" />
                                                </styles.DownloadContainer>
                                            }
                                        </styles.BillingHistoryTableColumn>
                                    </styles.BillingHistoryTableRow>

                                    <styles.StyledHR />
                                </React.Fragment>
                            );
                        })
                    }
                </styles.BillingHistoryTable>

                {/* <Spacer Size="large" />

                <pre>
                    {JSON.stringify(BillingHistory, null, 2)}
                </pre> */}
            </styles.BillingHistoryContainer>
        );
    }

    renderChangeToBusinessFromOrgMemberModal = () => {
        var { ShowChangeToBusinessFromOrgMemberModal } = this.state;

        if (this.state.ShowChangeToBusinessFromOrgMemberModal) {
            var { t } = this.props;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onChangeToBusinessFromOrgMember}
                    BottomButtonText={t('change_to_business_from_orgmember_title')}
                    Information={t('change_to_business_from_orgmember_description')}
                    Loading={this.props.TryingUpdateOrganizationMembershipStatus}
                    OnHide={() => this.onToggleShowChangeToBusinessFromOrgMemberModal(false)}
                    Show={ShowChangeToBusinessFromOrgMemberModal}
                    Size="medium"
                />
            );
        }
    }

    renderDeleteMembershipModal = () => {
        var { ShowDeleteMembershipModal } = this.state;

        if (this.state.ShowDeleteMembershipModal) {
            var { t } = this.props;
            var { OrgName } = this.state.DeleteMembership;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteMembership}
                    BottomButtonText={t('delete_organization_membership_invitation')}
                    Information={t('delete_organization_membership_invitation_warning', { OrgName })}
                    Loading={this.props.TryingUpdateOrganizationMembershipStatus}
                    OnHide={() => this.onToggleShowDeleteMembershipModal({ ShowDeleteMembershipModal: false })}
                    Show={ShowDeleteMembershipModal}
                    Size="medium"
                />
            );
        }
    }

    renderHeader = () => {
        var { t } = this.props;
        var { ShowBillingHistory } = this.state;

        if (ShowBillingHistory) {
            return (
                <styles.HeaderContainer>
                    <PageText FontFamily="medium" FontSize="large" NoMargin JustifyContent="flex-start" Text={t('subscription_billing_history')} TextAlign="left" />

                    <PageText FontColor="blue-abel" FontColorHover="blue-abel-hover" FontFamily="bold" FontSize="medium-1" NoMargin JustifyContent="flex-end" OnClick={() => this.onToggleShowBillingHistory(false)} Text={t('back_with_first_char_uppercase')} TextAlign="right" />
                </styles.HeaderContainer>
            );
        }

        return (
            <styles.HeaderContainer>
                <PageText FontFamily="medium" FontSize="large" NoMargin JustifyContent="flex-start" Text={t('Subscription')} TextAlign="left" />

                <PageText FontColor="blue-abel" FontColorHover="blue-abel-hover" FontFamily="bold" FontSize="medium-1" NoMargin JustifyContent="flex-end" OnClick={() => this.onToggleShowBillingHistory(true)} Text={t('subscription_view_billing_history')} TextAlign="right" />
            </styles.HeaderContainer>
        );
    }

    renderLeaveOrganizationModal = () => {
        var { ShowLeaveOrganizationModal } = this.state;

        if (this.state.ShowLeaveOrganizationModal) {
            var { t } = this.props;
            var { OrgName } = this.state.LeaveOrganization;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onLeaveOrganization}
                    BottomButtonText={t('leave_organization_membership')}
                    Information={t('leave_organization_membership_warning', { OrgName })}
                    Loading={this.props.TryingUpdateOrganizationMembershipStatus}
                    OnHide={() => this.onToggleShowLeaveOrganizationModal({ ShowLeaveOrganizationModal: false })}
                    Show={ShowLeaveOrganizationModal}
                    Size="medium"
                />
            );
        }
    }

    renderFreeTrial = () => {
        var { t } = this.props;
        var { hasCancelled, hasPaymentCardAdded, isActiveSubscription, promotionExpireDate } = this.props.UserDetails.SubscriptionStatus;

        return (
            <>
                <MultiText
                    Texts={[
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('subscription_using_free_trial', { DaysLeft: moment(promotionExpireDate).diff(moment().format('YYYY-MM-DD'), 'days') }) },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: (!hasCancelled && !!+isActiveSubscription && !!hasPaymentCardAdded) ? t('subscription_using_free_trial_hassubscription') : t('subscription_using_free_trial_nosubscription') }
                    ]}
                />
            </>
        );
    }

    renderMembershipRequests = () => {
        var { t } = this.props;
        var { MembershipRequests } = this.props.UserDetails.SubscriptionStatus;
        var { PrimaryOrganizationUserId } = this.state;

        return (
            <styles.MembershipRequestsContainer className="MembershipRequestsContainer">
                {
                    MembershipRequests.map(({ MembershipStatus, Name, OrganizationId, OrganizationUserId }) => {
                        var IsPrimaryOrg = +OrganizationUserId === +PrimaryOrganizationUserId;

                        return (
                            <styles.MembershipRequestContainer className="MembershipRequestContainer" key={OrganizationId} IsPrimaryOrg={IsPrimaryOrg} MembershipStatus={MembershipStatus}>
                                {
                                    MembershipStatus === 'Accepted' ?
                                    <>
                                        <PageText FontFamily="bold" FontSize="medium-3" NoMargin JustifyContent="flex-start" Text={Name} TextAlign="left" />

                                        <Spacer Size="extra-extra-small" />

                                        {
                                            IsPrimaryOrg ?
                                            <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin JustifyContent="flex-start" Text={t('_this_is_your_primary_org')} TextAlign="left" />
                                        :
                                            <styles.DateTimeUseAllDaysContainer>
                                                <styles.DateTimeUseAllDays onClick={() => this.onChoosePrimaryOrganization({ OrganizationUserId })} Selected={IsPrimaryOrg} />
                                                <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={() => this.onChoosePrimaryOrganization({ OrganizationUserId })} Text={t('_choose_primary_org')} TextAlign="left" />
                                            </styles.DateTimeUseAllDaysContainer>
                                        }

                                        <Spacer Size="extra-small" />

                                        <PageText FontColor="red-bittersweet" FontColorHover="red-bittersweet-hover" FontFamily="semibold" FontSize="medium-1" NoMargin JustifyContent="flex-start" OnClick={() => this.onToggleShowLeaveOrganizationModal({ LeaveOrganization: { OrganizationId, OrgName: Name, OrganizationUserId }, ShowLeaveOrganizationModal: true })} Text={t('leave_organization_membership')} TextAlign="left" />
                                    </>
                                :
                                    <>
                                        <PageText FontFamily="medium" FontSize="medium-1" NoMargin JustifyContent="flex-start" Text={`${t('membership_request_from')}:`} TextAlign="left" />

                                        <PageText FontFamily="bold" FontSize="medium-3" NoMargin JustifyContent="flex-start" Text={Name} TextAlign="left" />
                                        
                                        <Spacer Size="extra-extra-small" />

                                        <ButtonGroup
                                            Buttons={[
                                                { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Color: 'white', ColorHover: 'white', OnClick: () => this.onToggleShowAcceptMembershipModal({ AcceptMembership: { OrganizationId, OrgName: Name, OrganizationUserId }, ShowAcceptMembershipModal: true }), Size: 'small', Title: t('accept') },
                                                { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Color: 'white', ColorHover: 'white', OnClick: () => this.onToggleShowDeleteMembershipModal({ DeleteMembership: { OrganizationId, OrgName: Name }, ShowDeleteMembershipModal: true }), Size: 'small', Title: t('delete_with_first_char_uppercase') }
                                            ]}
                                            ButtonWidth="fit-content"
                                            NotTouching
                                        />
                                    </>
                                }
                            </styles.MembershipRequestContainer>
                        );
                    })
                }
            </styles.MembershipRequestsContainer>
        );
    }

    renderPaymentCardInfo = ({ maskedCardPan, paymentMethod, paymentType }) => {
        var CardImage = paymentType === 'CARD' ? paymentMethod === 'Visa' ? Visa : paymentMethod === 'MasterCard' ? MasterCard : paymentMethod === 'Vipps' ? Vipps : null : null;

        return (
            <styles.PaymentCardContainer className="PaymentCardContainer">
                {CardImage && <styles.PaymentCardImage className="PaymentCardImage" ImageSrc={CardImage} />}

                <PageText FontFamily="bold" FontSize="small" NoMargin JustifyContent="flex-start" Text={maskedCardPan} TextAlign="left" />
            </styles.PaymentCardContainer>
        );
    }

    renderSubscriptionDetails = () => {
        var { t } = this.props;
        var { activeExpiration, bonusDaysLeft, hasCancelled, hasPaymentCardAdded, isActiveSubscription, maskedCardPan, MembershipRequests, paymentMethod, paymentType, promotionType, subscriberTypeId } = this.props.UserDetails.SubscriptionStatus;

        var HasExtraSpace = (subscriberTypeId === 3 || promotionType === 'Trial'  || !+isActiveSubscription || !+hasPaymentCardAdded);

        return (
            <styles.SubscriptionDetailsContainer className="SubscriptionDetailsContainer">
                {promotionType === 'Trial' && +bonusDaysLeft > 0 && subscriberTypeId !== 3 && this.renderFreeTrial()}

                {
                    subscriberTypeId === 3 &&
                    <>
                        <PageText FontFamily="medium" FontSize="large" NoMargin JustifyContent="flex-start" Text={t('subscription_you_are_a_member_of')} TextAlign="left" />

                        <PageText FontFamily="medium" FontSize="small" NoMargin JustifyContent="flex-start" Text={t('subscription_you_are_a_member_of_description')} TextAlign="left" />
                    </>
                }

                {
                    // subscriberTypeId === 1 && !+isActiveSubscription && (!!hasCancelled || promotionType !== 'Trial') &&
                    subscriberTypeId === 1 && !bonusDaysLeft && (!!hasCancelled || promotionType !== 'Trial') &&
                    <>
                        <MultiText
                            Texts={[
                                { FontFamily: 'bold', FontSize: 'small', Text: t('subscription_has_access_not_active_days_left_you_have') },
                                { FontColor: 'blue-abel', FontFamily: 'bold', FontSize: 'medium-3', Text: `${moment(activeExpiration).diff(moment().format('YYYY-MM-DD'), 'days')} ${t('days').toLowerCase()}` },
                                { FontFamily: 'bold', FontSize: 'small', Text: t('subscription_has_access_not_active_days_left_paid_for') }
                            ]}
                        />

                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="small" NoMargin JustifyContent="flex-start" Text={t('subscription_has_access_not_active_days_left_description')} TextAlign="left" />

                        <Spacer Size="small" />

                        <PageText FontColor="blue-abel" FontColorHover="blue-abel-hover" FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={this.onReactivateMembership} Text={t('reactivate_membership')} TextAlign="left" />
                    </>
                }

                {
                    hasPaymentCardAdded && subscriberTypeId === 1 && !!maskedCardPan && !hasCancelled &&
                    <>
                        {promotionType === 'Trial' && <Spacer Size="medium" />}

                        <PageText FontFamily="semibold" FontSize="large" NoMargin JustifyContent="flex-start" Text={t('payment')} TextAlign="left" />

                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('subscription_automatically_charge_card')} TextAlign="left" />

                        <Spacer Size="small" />
                        
                        <PageText FontFamily="semibold" FontSize="small" NoMargin JustifyContent="flex-start" Text={t('payment_method')} TextAlign="left" />

                        <Spacer Size="extra-small" />

                        {this.renderPaymentCardInfo({ maskedCardPan, paymentMethod, paymentType })}

                        <Spacer Size="extra-small" />

                        <PageText FontColor="blue-abel" FontColorHover="blue-abel-hover" FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={this.onChangeCard} Text={t('subscription_change_card')} TextAlign="left" />
                    </>
                }
                
                {
                    !!MembershipRequests.length &&
                    <>
                        {HasExtraSpace && <Spacer Size="small" />}
                        
                        {this.renderMembershipRequests()}
                    </>
                }

                {/* {
                    !MembershipRequests.length &&
                    <>
                        <Spacer Size="small" />
                        
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('subscriptions_no_pending_org_requests')} TextAlign="left" />
                    </>
                } */}
            </styles.SubscriptionDetailsContainer>
        );
    }

    renderSubscriptionType = ({ Type }) => {
        var { t } = this.props;
        var { hasCancelled, hasPaymentCardAdded, subAmountText, subscriberTypeId } = this.props.UserDetails.SubscriptionStatus;
        var CurrencyId = this.props.UserDetails.CurrencyId || '3';
        var { Color, Image, MeantFor, Name, Price, WhatYouGet } = SubscriptionsContent[`${Type}`];

        var ShowButton = (Type === 1 && subscriberTypeId === 3 && !hasCancelled) || (Type === 1 && subscriberTypeId === 1 && !hasPaymentCardAdded);
        var ShowChangeButton = Type === 1 && subscriberTypeId === 3 && !hasCancelled;

        var PriceMultiTexts = [];
        if (subscriberTypeId === Type && !hasCancelled && subscriberTypeId === 1) {
            PriceMultiTexts = [
                { FontColor: 'white', FontFamily: "bold", FontSize: "medium-3", Text: subAmountText },
                { FontColor: 'white', FontFamily: "medium-italic", FontSize: "small", Text: `/${t('timeperiod_month_singular')}` }
            ]
        } else {
            PriceMultiTexts = [
                { FontColor: 'white', FontFamily: "bold", FontSize: "large", Text: Price[CurrencyId].Amount },
                { FontColor: 'white', FontFamily: "bold", FontSize: "small", Text: Price[CurrencyId].Currency },
                { FontColor: 'white', FontFamily: "medium-italic", FontSize: "small", Text: `/${t('timeperiod_month_singular')}` }
            ];
        }

        return (
            <styles.SubscriptionTypeContainer className="SubscriptionTypeContainer" Color={Color} Selected={subscriberTypeId === Type && !hasCancelled} ShowChangeButton={ShowButton}>
                <styles.CircularPhotoContainer className="CircularPhotoContainer">
                    <styles.StyledImage alt={Name} src={Image} />
                </styles.CircularPhotoContainer>

                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="center" NoMargin Text={t(`subscription_type_${Name.toLowerCase()}`)} TextAlign="center" />

                <Spacer Size="small" />

                <styles.SubscriptionPriceContainer className="SubscriptionPriceContainer" Color={Color}>
                    <MultiText Texts={PriceMultiTexts} />
                </styles.SubscriptionPriceContainer>

                <Spacer Size="small" />

                <styles.SubscriptionDescriptionContainer className="SubscriptionDescriptionContainer">
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('meant_for')}:`} TextAlign="left" />

                    <styles.SubscriptionListContainer className="SubscriptionListContainer">
                        {
                            MeantFor.map(({ Id, Text }) => {
                                return <li key={Id}><PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t(Text)} TextAlign="left" /></li>;
                            })
                        }
                    </styles.SubscriptionListContainer>
                </styles.SubscriptionDescriptionContainer>

                {
                    !!WhatYouGet.length &&
                    <>
                        <Spacer Size="small" />

                        <styles.SubscriptionDescriptionContainer className="SubscriptionDescriptionContainer">
                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('what_you_get')}:`} TextAlign="left" />

                            <styles.SubscriptionListContainer className="SubscriptionListContainer">
                                {
                                    WhatYouGet.map(({ Id, Text }) => {
                                        return <li key={Id}><PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t(Text)} TextAlign="left" /></li>;
                                    })
                                }
                            </styles.SubscriptionListContainer>
                        </styles.SubscriptionDescriptionContainer>
                    </>
                }

                {
                    ShowChangeButton &&
                    <styles.ChooseSubscriptionButtonContainer className="ChooseSubscriptionButtonContainer" onClick={() => this.onToggleShowChangeToBusinessFromOrgMemberModal(true)}>
                        <PageText FontColor="white" FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('change_with_first_char_uppercase')} TextAlign="center" />
                    </styles.ChooseSubscriptionButtonContainer>
                }

                {
                    !ShowChangeButton && Type === 1 && !hasPaymentCardAdded &&
                    <styles.ChooseSubscriptionButtonContainer className="ChooseSubscriptionButtonContainer" onClick={this.onAddCardDetails}>
                        <PageText FontColor="white" FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('subscription_upgrade')} TextAlign="center" />
                    </styles.ChooseSubscriptionButtonContainer>
                }
            </styles.SubscriptionTypeContainer>
        );
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { t } = this.props;
        var { TryingChoosePrimaryOrganization, TryingDownloadContractPDF, TryingUpdateOrganizationMembershipStatus } = this.props;
        var { hasCancelled, hasPaymentCardAdded, isActiveSubscription, subscriberTypeId } = this.props.UserDetails.SubscriptionStatus;
        var { MembershipRequests } = this.props.UserDetails.SubscriptionStatus;
        var { ShowBillingHistory, TryingGetBillingHistory } = this.state;

        return (
            <>
                {(TryingChoosePrimaryOrganization || TryingUpdateOrganizationMembershipStatus || TryingDownloadContractPDF || TryingGetBillingHistory) && <Loading />}

                {this.renderHeader()}

                <Spacer Size="medium" />

                {
                    ShowBillingHistory ?
                    this.renderBillingHistory()
                :
                    <styles.SubscriptionsContainer className="SubscriptionsContainer" HasRequests={!!MembershipRequests.length}>
                        {this.renderSubscriptionType({ Type: 1 })}
                        {this.renderSubscriptionType({ Type: 3 })}
                        {this.renderSubscriptionDetails()}

                        {subscriberTypeId === 1 && !hasCancelled && !!+isActiveSubscription && !!+hasPaymentCardAdded && <PageText FontColor="yellow-gold" FontColorHover="yellow-gold-hover" FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin OnClick={this.onCancelMembership} Text={t('cancel_subscription')} TextAlign="center" />}
                    </styles.SubscriptionsContainer>
                }

                {/* <Spacer Size="large" />

                <pre>
                    {JSON.stringify(this.props.UserDetails.SubscriptionStatus, null, 2)}
                </pre> */}

                {this.renderAcceptMembershipModal()}
                {this.renderChangeToBusinessFromOrgMemberModal()}
                {this.renderDeleteMembershipModal()}
                {this.renderLeaveOrganizationModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        TryingDownloadContractPDF: state.Sales.TryingDownloadContractPDF,
        TryingDownloadContractPDFError: state.Sales.TryingDownloadContractPDFError,
        TryingChoosePrimaryOrganization: state.Auth.TryingChoosePrimaryOrganization,
        TryingChoosePrimaryOrganizationError: state.Auth.TryingChoosePrimaryOrganizationError,
        TryingUpdateOrganizationMembershipStatus: state.Auth.TryingUpdateOrganizationMembershipStatus,
        TryingUpdateOrganizationMembershipStatusError: state.Auth.TryingUpdateOrganizationMembershipStatusError
    };
};

export default withTranslation()(connect(mapStateToProps, { CancelSubscription, ChangeToBusinessFromOrgMember, ChoosePrimaryOrganization, DeleteOrganizationMember, DownloadSubscriptionReceipt, LeaveOrganization, ReactivateMembership, RegisterUserInPaymentGateway, UpdateOrganizationMemberInvitation, ViewOrganizationMembers, ViewSubscriptionBillingHistory })(SubscriptionsComponent));