import styled from 'styled-components';

import Image from '../Image';

export const CircuitContainer = styled.div`
    ${
        props => props.CircuitType !== 'WorkoutExercise' &&
        `
            border: 5px solid ${props.CircuitType === 'Circuit' ? 'var(--turquoise-dark)' : 'var(--blue-royal)'};   
        `
    }
    border-radius: 10px;
`;

export const CircuitSeparator = styled.div`
    align-items: center;
    background-color: var(--${props => props.BackgroundColor});
    display: flex;
    flex-direction: column;
    height: var(--spacer-size-medium);
    justify-content: center;
`;

export const StyledHR = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    height: 2px;
    width: 100%;
`;

export const StyledWorkoutImage = styled(Image)`
    border-radius: 10px;    
    height: 200px;
    object-fit: cover;
    width: 100%;

    > div {
        width: 100%;
    }
`;

export const WorkoutExercisesContainer = styled.div``;