import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DeliverySite_GetOrders, DeliverySite_GetCompanies_Basic, DeliverySite_GetDHCs_Basic, DeliverySite_GetDHCDetails, DeliverySite_GetEmployees_Basic, DeliverySite_GetInvoiceEmployees, DeliverySite_GetLeaders_Basic, DeliverySite_GetMiddleLeaders_Basic, DeliverySite_GetOrderDetails, DeliverySite_GetProducts_Basic, DeliverySite_GetTopLeaders_Basic, DeliverySite_InsertInvoice, DeliverySite_InsertOrder } from '../../../Services/Actions';
import Select from 'react-select';
import Popover from 'react-popover';

import * as styles from './styles';

import ButtonGroup from '../../../Components/Buttons/ButtonGroup';
import Loading from '../../../Components/Loading';
import MultiText from '../../../Components/Text/MultiText';
import PageText from '../../../Components/Text/PageText';
import PaginationContainer from '../../../Components/PaginationCounter';
import ProfilePhoto from '../../../Components/ProfilePhoto';
import Spacer from '../../../Components/Spacer';

import Info from '../../../Modals/Info';

import DownArrow from '../../../Assets/Icons/DownArrow.png';
import InfoIcon from '../../../Assets/Icons/Info.png';
import Plus from '../../../Assets/Icons/PlusSign_White.png';
import UpArrow from '../../../Assets/Icons/UpArrow.png';

import { ReactComponent as CheckmarkSVG } from '../../../Assets/SVG/Checkmark_Circle.svg';
import { ReactComponent as ExclamationSVG } from '../../../Assets/SVG/Exclamation.svg';

const INVOICE_EMPLOYEE_TABLE_COLUMNS = [
    { Id: 1, Title: 'Employee' },
    { Id: 2, Title: 'DHC' },
    { Id: 3, Title: 'Delivery Weeks' },
    { Id: 4, Title: 'Delivered %' }
];

const customStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "initial"
    }),
    input: (provided, state) => ({
        ...provided,
        minWidth: '20%'
    })
}

class OrderComponent extends React.Component {
    state = {
        OrderDetails: {},
        OrderDetails_Loaded: false,
        ToggleOpen: false
    }

    onToggle = () => {
        this.setState({ ToggleOpen: !this.state.ToggleOpen }, () => {
            if (this.state.ToggleOpen && !this.state.OrderDetails_Loaded) {
                this.props.GetOrderDetails({ OrderId: this.props.Order.OrderId }).then(({ Order }) => {
                    this.setState({ OrderDetails: Order, OrderDetails_Loaded: true });
                });
            }
        });
    }

    onViewInvoiceEmployees = ({ InvoiceId, InvoiceIndex }) => {
        var OrderDetails = { ...this.state.OrderDetails };

        if (!OrderDetails.Invoices[InvoiceIndex].Employees) {
            this.props.GetInvoiceEmployees({ InvoiceId }).then(({ Employees }) => {
                OrderDetails.Invoices[InvoiceIndex].Employees = Employees;
                OrderDetails.Invoices[InvoiceIndex].ViewEmployees = true;

                this.setState({ OrderDetails });
            })
        }
        else if (!OrderDetails.Invoices[InvoiceIndex].ViewEmployees) {
            OrderDetails.Invoices[InvoiceIndex].ViewEmployees = true;

            this.setState({ OrderDetails });
        }
        else if (!!OrderDetails.Invoices[InvoiceIndex].ViewEmployees) {
            OrderDetails.Invoices[InvoiceIndex].ViewEmployees = false;

            this.setState({ OrderDetails });
        }
    }

    renderInvoiceEmployees = Employees => {
        return (
            <>
                <Spacer Size="small" />

                <styles.UsersTableContainer className="UsersTableContainer">
                    <styles.UsersTable className="UsersTable">
                        <styles.UsersTableHeader className="UsersTableHeader">
                            <styles.UsersTableHeaderRow className="UsersTableHeaderRow">
                                {
                                    INVOICE_EMPLOYEE_TABLE_COLUMNS.map(({ Id, Title }) => {
                                        return (
                                            <styles.UsersTableHeaderCell className="UsersTableHeaderCell" key={Id}>
                                                <styles.UsersTableHeaderCellInnerDiv className="UsersTableHeaderCellInnerDiv">
                                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Title} />
                                                </styles.UsersTableHeaderCellInnerDiv>
                                            </styles.UsersTableHeaderCell>
                                        );
                                    })
                                }
                            </styles.UsersTableHeaderRow>
                        </styles.UsersTableHeader>
                        <styles.UsersTableBody className="UsersTableBody">
                            {
                                Employees.map((Employee, UserIndex) => {
                                    var {
                                        DHC_DisplayName, DHC_ProfilePhotoUrl, DHC_Sex,
                                        Employee_DisplayName, Employee_ProfilePhotoUrl, Employee_Sex,
                                        NumWeeks, CompletedWeeks, DeliveryCompletion
                                    } = Employee;

                                    return (
                                        <styles.UsersTableBodyRow className="UsersTableBodyRow" key={UserIndex}>
                                            <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                <styles.UsersTableBodyCellFlexed className="UsersTableBodyCellFlexed">
                                                    <styles.ProfilePhotoContainer className="ProfilePhotoContainer">
                                                        <ProfilePhoto Sex={Employee_Sex || 'Male'} Size="small-3" Source={Employee_ProfilePhotoUrl || ''} />
                                                    </styles.ProfilePhotoContainer>
                                                    {Employee_DisplayName}
                                                </styles.UsersTableBodyCellFlexed>
                                            </styles.UsersTableBodyCell>
                                            <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                <styles.UsersTableBodyCellFlexed className="UsersTableBodyCellFlexed">
                                                    <styles.ProfilePhotoContainer className="ProfilePhotoContainer">
                                                        <ProfilePhoto Sex={DHC_Sex || 'Male'} Size="small-3" Source={DHC_ProfilePhotoUrl || ''} />
                                                    </styles.ProfilePhotoContainer>
                                                    {DHC_DisplayName}
                                                </styles.UsersTableBodyCellFlexed>
                                            </styles.UsersTableBodyCell>
                                            <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                {CompletedWeeks} / {NumWeeks}
                                            </styles.UsersTableBodyCell>
                                            <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                {DeliveryCompletion}%
                                            </styles.UsersTableBodyCell>
                                        </styles.UsersTableBodyRow>
                                    )
                                })
                            }
                        </styles.UsersTableBody>
                    </styles.UsersTable>
                </styles.UsersTableContainer>
            </>
        );
    }

    renderOrderStatus = ({ HasOverdueInvoices }) => {
        var Color;
        var SVGIcon;
        var Text;

        if (!+HasOverdueInvoices) { Color = 'green-success'; SVGIcon = CheckmarkSVG; Text = 'Active'; }
        else { Color = 'orange'; SVGIcon = ExclamationSVG; Text = 'Overdue'; }

        return (
            <styles.OrderStatusContainer className="OrderStatusContainer" FillColor={Color}>
                <SVGIcon />

                <PageText FontColor={Color} FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={Text} TextAlign="left" />
            </styles.OrderStatusContainer>
        );
    }

    renderOrderBody = () => {
        var { OrderDetails, OrderDetails_Loaded } = this.state;

        if (!OrderDetails_Loaded) return null;

        var { Details, Invoices } = OrderDetails;

        var { AccountManager_DisplayName, CompanyId, CreatedDate, OrderId, ProductName, StartDate } = Details;

        return (
            <styles.OrderContainerBody className="OrderContainerBody">
                <MultiText
                    Texts={[
                        { FontColor: 'black', FontFamily: 'semibold', FontSize: 'medium-1', Text: 'Product: ' },
                        { FontColor: 'black', FontFamily: 'medium', FontSize: 'medium-1', Text: `${ProductName}` }
                    ]}
                />

                <MultiText
                    Texts={[
                        { FontColor: 'black', FontFamily: 'semibold', FontSize: 'medium-1', Text: 'Order created: ' },
                        { FontColor: 'black', FontFamily: 'medium', FontSize: 'medium-1', Text: `${CreatedDate}` }
                    ]}
                />

                <MultiText
                    Texts={[
                        { FontColor: 'black', FontFamily: 'semibold', FontSize: 'medium-1', Text: 'Start date: ' },
                        { FontColor: 'black', FontFamily: 'medium', FontSize: 'medium-1', Text: `${StartDate}` }
                    ]}
                />

                <MultiText
                    Texts={[
                        { FontColor: 'black', FontFamily: 'semibold', FontSize: 'medium-1', Text: 'Company account manager: ' },
                        { FontColor: 'black', FontFamily: 'medium', FontSize: 'medium-1', Text: `${AccountManager_DisplayName}` }
                    ]}
                />

                <styles.StyledHR />

                <styles.OrderContainerBodyInvoicesContainer className="OrderContainerBodyInvoicesContainer">
                    <styles.OrderContainerBodyInvoiceContainerHeader className="OrderContainerBodyInvoiceContainerHeader">
                        <div/>

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Sum" TextAlign="left" />
                        
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Delivery Periods" TextAlign="left" />
                        
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Delivery Weeks" TextAlign="left" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Due date" TextAlign="left" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Paid" TextAlign="left" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Employees" TextAlign="left" />
                    </styles.OrderContainerBodyInvoiceContainerHeader>

                    {
                        Invoices.map(({ CompletedWeeks, DeliveryCompletion, DeliveryPeriods, Employees, InvoiceAmount, InvoiceId, NumEmployees, NumWeeks, PaidDate, PaymentDueDate, PaymentStatus, PaymentStatusDays, ViewEmployees }, InvoiceIndex) => {
                            var PaidStatusColor;
                            var PaidStatusText;

                            if (PaymentStatus === 'Paid') { PaidStatusColor = 'success-green'; PaidStatusText = `${PaidDate}`; }
                            else if (PaymentStatus === 'PaidLate') { PaidStatusColor = 'orange'; PaidStatusText = `${PaidDate} (${PaymentStatusDays} days late)`; }
                            else if (PaymentStatus === 'Overdue') { PaidStatusColor = 'orange'; PaidStatusText = `Overdue ${PaymentStatusDays * -1} days`; }
                            else if (PaymentStatus === 'Pending') { PaidStatusColor = null; PaidStatusText = `Not yet (${PaymentStatusDays} days left)`; }

                            return (
                                <styles.OrderContainerBodyInvoiceContainer className="OrderContainerBodyInvoiceContainer" key={InvoiceId} BorderColor={PaidStatusColor}>
                                    <styles.OrderContainerBodyInvoiceContainerMain className="OrderContainerBodyInvoiceContainerMain">
                                        <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={`${InvoiceIndex + 1}`} TextAlign="left" />

                                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${new Intl.NumberFormat('no-NO', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(InvoiceAmount)}`} TextAlign="left" />

                                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${DeliveryPeriods}`} TextAlign="left" />

                                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${CompletedWeeks} / ${NumWeeks} (${DeliveryCompletion}%)`} TextAlign="left" />

                                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={PaymentDueDate} TextAlign="left" />

                                        <PageText FontColor={PaidStatusColor || 'black'} FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={PaidStatusText} TextAlign="left" />

                                        <MultiText
                                            Texts={[
                                                { FontColor: 'black', FontFamily: 'semibold', FontSize: 'medium-1', Text: `${NumEmployees} ` },
                                                { FontColor: 'abel-blue', FontFamily: 'medium-italic', FontSize: 'small', OnClick: () => this.onViewInvoiceEmployees({ InvoiceId, InvoiceIndex }), Text: ViewEmployees ? 'Hide' : 'View' }
                                            ]}
                                        />
                                    </styles.OrderContainerBodyInvoiceContainerMain>

                                    {(!!ViewEmployees && Employees.length > 0) && this.renderInvoiceEmployees(Employees)}
                                </styles.OrderContainerBodyInvoiceContainer>
                            );
                        })
                    }
                </styles.OrderContainerBodyInvoicesContainer>

                <Spacer Size="extra-small" />

                <ButtonGroup
                    Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.props.OnToggleShowAddInvoiceModal({ CompanyId, OrderId, ShowAddInvoiceModal: true }), Title: "New Invoice" }]}
                    ButtonWidth="fit-content"
                    ContainerWidth="fit-content"
                    JustifyContent="center"
                />
            </styles.OrderContainerBody>
        );
    }

    renderOrderHeader = () => {
        var { ActiveEmployees, CompanyName, HasOverdueInvoices, IsActive, NumEmployees, ProductName, TotalSold } = this.props.Order;

        var ToggleImageSrc = this.state.ToggleOpen ? UpArrow : DownArrow;

        return (
            <styles.OrderContainerHeader className="OrderContainerHeader" onClick={this.onToggle}>
                <styles.OrderContainerHeaderLeft className="OrderContainerHeaderLeft">
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={CompanyName} TextAlign="left" />
                </styles.OrderContainerHeaderLeft>
                <styles.OrderContainerHeaderRight className="OrderContainerHeaderRight">
                    <MultiText
                        Texts={[
                            { FontColor: 'black', FontFamily: 'medium', FontSize: 'medium-1', Text: `${ProductName}: ` },
                            { FontColor: 'black', FontFamily: 'semibold', FontSize: 'medium-1', Text: `${new Intl.NumberFormat('no-NO', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(TotalSold)} kr` }
                        ]}
                    />

                    <MultiText
                        Texts={[
                            { FontColor: 'black', FontFamily: 'medium', FontSize: 'medium-1', Text: `Active:` },
                            { FontColor: 'black', FontFamily: 'semibold', FontSize: 'medium-1', Text: `${ActiveEmployees} / ${NumEmployees} (${(ActiveEmployees/NumEmployees) * 100}%)` }
                        ]}
                    />

                    {!!+IsActive && this.renderOrderStatus({ HasOverdueInvoices })}

                    <styles.ToggleIcon ImageSrc={ToggleImageSrc} />
                </styles.OrderContainerHeaderRight>
            </styles.OrderContainerHeader>
        );
    }

    render() {
        return (
            <styles.OrderContainer className="OrderContainer">
                {this.renderOrderHeader()}
                
                {
                    this.state.ToggleOpen &&
                    <>
                        <Spacer Size="small" />

                        {this.renderOrderBody()}
                    </>
                }
            </styles.OrderContainer>
        );
    }
}

class OrdersComponent extends React.Component {
    TimeoutId = null;

    state = {
        DisplayName_Previous: '',
        DisplayName: '',
        Orders: [],
        IsMoreResults: null,
        PageNo: 1,
        PageSize: 10,
        Sorting: {
            SortCol: 'StartDate',
            SortDir: 'ASC'
        },
        TotalRecords: null,

        Filters: {
            HasOverdueInvoices: null,
            IsActive: 1,
            Companies: {
                Companies: [],
                IsLoading: false,
                Loaded: false,
                Selected: []
            },
            DHCs: {
                IsLoading: false,
                Loaded: false,
                Selected: [],
                Users: []
            },
            Employees: {
                IsLoading: false,
                Loaded: false,
                Selected: [],
                Users: []
            },
            Leaders: {
                IsLoading: false,
                Loaded: false,
                Selected: [],
                Users: []
            },
            MiddleLeaders: {
                IsLoading: false,
                Loaded: false,
                Selected: [],
                Users: []
            },
            Products: {
                IsLoading: false,
                Loaded: false,
                Products: [],
                Selected: []
            },
            TopLeaders: {
                IsLoading: false,
                Loaded: false,
                Selected: [],
                Users: []
            }
        },

        PopoverIsOpen_Companies: false,
        PopoverIsOpen_DHCs: false,
        PopoverIsOpen_Employees: false,
        PopoverIsOpen_Leaders: false,
        PopoverIsOpen_MiddleLeaders: false,
        PopoverIsOpen_TopLeaders: false,
        PopoverIsOpen_Users: false,

        ShowAddInvoiceModal: false,
        ShowAddInvoiceModal_CompanyId: null,
        ShowAddInvoiceModal_OrderId: null,
        ShowAddOrderModal: false,
        ShowAddOrderModal_CompanyId: null,
        ShowAddOrderModal_EmployeeDHCs: [],
        ShowAddOrderModal_EmployeeDHCs_Selected: [],
        ShowAddOrderModal_Employees: {
            IsLoading: false,
            Loaded: false,
            Selected: [],
            Users: []
        },
        ShowAddOrderModal_Invoices: [{ Duration: 0, PaymentDueDate: '', PaymentMadeDate: '' }],
        ShowAddOrderModal_ProductId: null,
        ShowAddOrderModal_UnitPrice: null,
        ShowAddOrderModal_Loading: false,

        ShowDHCDetailModal: false,
        ShowDHCDetailModal_Data: {},
        ShowDHCDetailModal_Loading: false
    }

    componentDidMount() {
        this.onLoadOrders();
    }

    onAddInvoiceToExistingOrder = () => {
        var { ShowAddInvoiceModal_OrderId: OrderId, ShowAddOrderModal_EmployeeDHCs: Employees, ShowAddOrderModal_Invoices: Invoices } = this.state;

        this.setState({ ShowAddOrderModal_Loading: true }, () => {
            this.props.DeliverySite_InsertInvoice({ Employees, Invoices, OrderId }).then(() => {
                this.onToggleShowAddInvoiceModal({ ShowAddInvoiceModal: false });
                this.props.DeliverySite_GetOrderDetails({ OrderId });
            })
        })
    }

    onAddNewInvoice = () => {
        var ShowAddOrderModal_Invoices = [ ...this.state.ShowAddOrderModal_Invoices, { Duration: 0, PaymentDueDate: '', PaymentMadeDate: '' } ];

        this.setState({ ShowAddOrderModal_Invoices });
    }

    onAddOrder = () => {
        var { ShowAddOrderModal_CompanyId: CompanyId, ShowAddOrderModal_EmployeeDHCs: Employees, ShowAddOrderModal_Invoices: Invoices, ShowAddOrderModal_ProductId: ProductId, ShowAddOrderModal_UnitPrice: UnitPrice } = this.state;

        this.setState({ ShowAddOrderModal_Loading: true }, () => {
            this.props.DeliverySite_InsertOrder({ CompanyId, Employees, Invoices, ProductId, UnitPrice }).then(() => {
                this.onToggleShowAddOrderModal({ ShowAddOrderModal: false });
                this.onLoadOrders();
            })
        })
    }

    onBlurFilter_Companies = () => {
        this.setState({ PageNo: 1 }, () => this.onLoadOrders());
    }

    onBlurFilter_DHCs = () => {
        this.setState({ PageNo: 1 }, () => this.onLoadOrders());
    }

    onBlurFilter_Employees = () => {
        this.setState({ PageNo: 1 }, () => this.onLoadOrders());
    }

    onBlurFilter_Leaders = () => {
        this.setState({ PageNo: 1 }, () => this.onLoadOrders());
    }

    onBlurFilter_MiddleLeaders = () => {
        this.setState({ PageNo: 1 }, () => this.onLoadOrders());
    }

    onBlurFilter_TopLeaders = () => {
        this.setState({ PageNo: 1 }, () => this.onLoadOrders());
    }

    onChangeAddOrderModal_Employees = data => {
        if (data.length === 0) this.setState({ ShowAddOrderModal_Employees: { ...this.state.ShowAddOrderModal_Employees, Selected: [] }, ShowAddOrderModal_EmployeeDHCs: [], ShowAddOrderModal_EmployeeDHCs_Selected: [] });
        else {
            var ShowAddOrderModal_EmployeeDHCs = [ ...this.state.ShowAddOrderModal_EmployeeDHCs ];
            var ShowAddOrderModal_EmployeeDHCs_Selected = [ ...this.state.ShowAddOrderModal_EmployeeDHCs_Selected ];

            let removedItem = this.state.ShowAddOrderModal_Employees.Selected.filter(x => !data.includes(x));

            // Removed an employee
                if (removedItem.length > 0) {
                    var DeleteIndex = ShowAddOrderModal_EmployeeDHCs.map(({ Employee_UserId }) => Employee_UserId).indexOf(removedItem[0].UserId);

                    ShowAddOrderModal_EmployeeDHCs = ShowAddOrderModal_EmployeeDHCs.filter(x => x.Employee_UserId !== removedItem[0].UserId);
                    ShowAddOrderModal_EmployeeDHCs_Selected.splice(DeleteIndex, 1);
                }
            // Added an employee
                else {
                    var { DHC_DisplayName: DisplayName, DHC_UserId, UserId: Employee_UserId } = data[data.length - 1];

                    ShowAddOrderModal_EmployeeDHCs = [ ...ShowAddOrderModal_EmployeeDHCs, { DHC_UserId, Employee_UserId } ];
                    ShowAddOrderModal_EmployeeDHCs_Selected = [ ...ShowAddOrderModal_EmployeeDHCs_Selected, { UserId: DHC_UserId, DisplayName } ];
                }

            var ShowAddOrderModal_Employees = { ...this.state.ShowAddOrderModal_Employees };
            ShowAddOrderModal_Employees.Selected = data;

            this.setState({ ShowAddOrderModal_Employees, ShowAddOrderModal_EmployeeDHCs, ShowAddOrderModal_EmployeeDHCs_Selected });
        }
    }

    onChangeAddOrderModal_Company = data => {
        var CurrentCompanyId = this.state.ShowAddOrderModal_CompanyId;

        this.setState({ ShowAddOrderModal_CompanyId: data.CompanyId, ShowAddOrderModal_Loading: true }, () => {
            if (+data.CompanyId !== +CurrentCompanyId) {
                this.props.DeliverySite_GetEmployees_Basic({ CompanyId: data.CompanyId, GetDefaultCoach: 1 }).then(({ Users }) => {
                    this.setState({ ShowAddOrderModal_Loading: false, ShowAddOrderModal_EmployeeDHCs: [], ShowAddOrderModal_Employees: { ...this.state.ShowAddOrderModal_Employees, Selected: [], Users } });
                })
            } else this.setState({ ShowAddOrderModal_Loading: false });
        });
    }

    onChangeAddOrderModal_DHC = ({ data, Employee_UserId, SelectedIndex }) => {
        var ShowAddOrderModal_EmployeeDHCs = [ ...this.state.ShowAddOrderModal_EmployeeDHCs ];
        var ShowAddOrderModal_EmployeeDHCs_Selected = [ ...this.state.ShowAddOrderModal_EmployeeDHCs_Selected ];

        ShowAddOrderModal_EmployeeDHCs[SelectedIndex] = { Employee_UserId, DHC_UserId: data.UserId };
        ShowAddOrderModal_EmployeeDHCs_Selected[SelectedIndex] = { ...data };

        this.setState({ ShowAddOrderModal_EmployeeDHCs, ShowAddOrderModal_EmployeeDHCs_Selected });
    }

    onChangeAddOrderModal_Product = data => {
        this.setState({ ShowAddOrderModal_ProductId: data.ProductId, ShowAddOrderModal_UnitPrice: data.DefaultPrice });
    }

    onChangeAddOrderModal_UnitPrice = event => {
        this.setState({ ShowAddOrderModal_UnitPrice: event.target.value });
    }

    onChangeDHCSearch = event => {
        this.setState({ DisplayName: event.target.value });
    }

    onChangeFilter_Companies = data => {
        if (!data) data = [];
        
        var Filters = { ...this.state.Filters };
        Filters.Companies.Selected = data;

        this.setState({ Filters });
    }

    onChangeFilter_DHCs = data => {
        if (!data) data = [];
        
        var Filters = { ...this.state.Filters };
        Filters.DHCs.Selected = data;

        this.setState({ Filters });
    }

    onChangeFilter_Employees = data => {
        if (!data) data = [];
        
        var Filters = { ...this.state.Filters };
        Filters.Employees.Selected = data;

        this.setState({ Filters });
    }

    onChangeFilter_Leaders = data => {
        if (!data) data = [];
        
        var Filters = { ...this.state.Filters };
        Filters.Leaders.Selected = data;

        this.setState({ Filters });
    }

    onChangeFilter_MiddleLeaders = data => {
        if (!data) data = [];
        
        var Filters = { ...this.state.Filters };
        Filters.MiddleLeaders.Selected = data;

        this.setState({ Filters });
    }

    onChangeFilter_Products = data => {
        if (!data) data = [];
        
        var Filters = { ...this.state.Filters };
        Filters.Products.Selected = data;

        this.setState({ Filters });
    }

    onChangeFilter_TopLeaders = data => {
        if (!data) data = [];
        
        var Filters = { ...this.state.Filters };
        Filters.TopLeaders.Selected = data;

        this.setState({ Filters });
    }

    onChangeInvoiceDueDate = ({ event, InvoiceIndex }) => {
        var PaymentDueDate = event.target.value;

        var ShowAddOrderModal_Invoices = [ ...this.state.ShowAddOrderModal_Invoices ];
        ShowAddOrderModal_Invoices[InvoiceIndex].PaymentDueDate = PaymentDueDate;

        this.setState({ ShowAddOrderModal_Invoices });
    }

    onChangeInvoiceDuration = ({ event, InvoiceIndex }) => {
        var Duration = event.target.value;

        var ShowAddOrderModal_Invoices = [ ...this.state.ShowAddOrderModal_Invoices ];
        ShowAddOrderModal_Invoices[InvoiceIndex].Duration = Duration;

        this.setState({ ShowAddOrderModal_Invoices });
    }

    onChangeInvoiceMadeDate = ({ event, InvoiceIndex }) => {
        var PaymentMadeDate = event.target.value;

        var ShowAddOrderModal_Invoices = [ ...this.state.ShowAddOrderModal_Invoices ];
        ShowAddOrderModal_Invoices[InvoiceIndex].PaymentMadeDate = PaymentMadeDate;

        this.setState({ ShowAddOrderModal_Invoices });
    }

    onFocusFilter_Companies = () => {
        if (!this.state.Filters.Companies.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.Companies = { ...Filters.Companies };
            Filters.Companies.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetCompanies_Basic({}).then(({ Companies }) => {
                    Filters.Companies.IsLoading = false;
                    Filters.Companies.Companies = Companies;
                    Filters.Companies.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onFocusFilter_DHCs = () => {
        if (!this.state.Filters.DHCs.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.DHCs = { ...Filters.DHCs };
            Filters.DHCs.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetDHCs_Basic({}).then(({ Users }) => {
                    Filters.DHCs.IsLoading = false;
                    Filters.DHCs.Users = Users;
                    Filters.DHCs.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onFocusFilter_Employees = () => {
        if (!this.state.Filters.Employees.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.Employees = { ...Filters.Employees };
            Filters.Employees.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetEmployees_Basic({}).then(({ Users }) => {
                    Filters.Employees.IsLoading = false;
                    Filters.Employees.Users = Users;
                    Filters.Employees.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onFocusFilter_Leaders = () => {
        if (!this.state.Filters.Leaders.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.Leaders = { ...Filters.Leaders };
            Filters.Leaders.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetLeaders_Basic().then(({ Users }) => {
                    Filters.Leaders.IsLoading = false;
                    Filters.Leaders.Users = Users;
                    Filters.Leaders.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onFocusFilter_MiddleLeaders = () => {
        if (!this.state.Filters.MiddleLeaders.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.MiddleLeaders = { ...Filters.MiddleLeaders };
            Filters.MiddleLeaders.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetMiddleLeaders_Basic().then(({ Users }) => {
                    Filters.MiddleLeaders.IsLoading = false;
                    Filters.MiddleLeaders.Users = Users;
                    Filters.MiddleLeaders.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onFocusFilter_Products = () => {
        if (!this.state.Filters.Products.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.Products = { ...Filters.Products };
            Filters.Products.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetProducts_Basic({}).then(({ Products }) => {
                    Filters.Products.IsLoading = false;
                    Filters.Products.Products = Products;
                    Filters.Products.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onFocusFilter_TopLeaders = () => {
        if (!this.state.Filters.TopLeaders.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.TopLeaders = { ...Filters.TopLeaders };
            Filters.TopLeaders.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetTopLeaders_Basic().then(({ Users }) => {
                    Filters.TopLeaders.IsLoading = false;
                    Filters.TopLeaders.Users = Users;
                    Filters.TopLeaders.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onLoadOrders = Increment => {
        var { Filters: { HasOverdueInvoices, IsActive, Companies, DHCs, Employees, Leaders, MiddleLeaders, TopLeaders }, PageNo, PageSize, Sorting: { SortCol, SortDir } } = this.state;

        var CompanyIds = Companies.Selected.length > 0 ? Companies.Selected.map(({ CompanyId }) => CompanyId).join(',') : null;
        var DHC_UserIds = DHCs.Selected.length > 0 ? DHCs.Selected.map(({ UserId }) => UserId).join(',') : null;
        var Employee_UserIds = Employees.Selected.length > 0 ? Employees.Selected.map(({ UserId }) => UserId).join(',') : null;
        var Leader_UserIds = Leaders.Selected.length > 0 ? Leaders.Selected.map(({ UserId }) => UserId).join(',') : null;
        var MiddleLeader_UserIds = MiddleLeaders.Selected.length > 0 ? MiddleLeaders.Selected.map(({ UserId }) => UserId).join(',') : null;
        var TopLeader_UserIds = TopLeaders.Selected.length > 0 ? TopLeaders.Selected.map(({ UserId }) => UserId).join(',') : null;

        if (Increment) PageNo++;

        this.props.DeliverySite_GetOrders({ CompanyIds, DHC_UserIds, Employee_UserIds, HasOverdueInvoices, IsActive, Leader_UserIds, MiddleLeader_UserIds, PageNo, PageSize, SortCol, SortDir, TopLeader_UserIds }).then(({ Orders, IsMoreResults, TotalRecords }) => {
            this.setState({ Orders, IsMoreResults, TotalRecords });
        });
    }

    onPaginate = ({ NewPageNo }) => {
        if (this.statePageNo !== NewPageNo) this.setState({ PageNo: NewPageNo }, () => this.onLoadOrders());
    }

    onRemoveInvoice = InvoiceIndex => {
        var ShowAddOrderModal_Invoices = [ ...this.state.ShowAddOrderModal_Invoices ];
        ShowAddOrderModal_Invoices.splice(InvoiceIndex, 1);

        this.setState({ ShowAddOrderModal_Invoices });
    }

    onTogglePopover = PopoverType => {
        this.setState({ [PopoverType]: !this.state[PopoverType] }, () => {
            if (!this.state[PopoverType] && !!this.TimeoutId) clearTimeout(this.TimeoutId);
        });
    }

    onToggleShowAddOrderModal = ({ ShowAddOrderModal }) => {
        if (!ShowAddOrderModal) this.setState({ ShowAddOrderModal, ShowAddOrderModal_CompanyId: null, ShowAddOrderModal_EmployeeDHCs: [], ShowAddOrderModal_EmployeeDHCs_Selected: [], ShowAddOrderModal_Invoices: [{ Duration: 0, PaymentDueDate: '', PaymentMadeDate: '' }], ShowAddOrderModal_ProductId: null, ShowAddOrderModal_UnitPrice: null, ShowAddOrderModal_Loading: false, ShowAddOrderModal_Employees: { IsLoading: false, Loaded: false, Selected: [], Users: [] } });
        else this.setState({ ShowAddOrderModal, ShowAddInvoiceModal: false }, () => this.onFocusFilter_DHCs());
    }

    onToggleShowAddInvoiceModal = ({ CompanyId, OrderId, ShowAddInvoiceModal }) => {
        if (!ShowAddInvoiceModal) this.setState({ ShowAddInvoiceModal, ShowAddInvoiceModal_CompanyId: null, ShowAddInvoiceModal_OrderId: null, ShowAddOrderModal_CompanyId: null, ShowAddOrderModal_EmployeeDHCs: [], ShowAddOrderModal_EmployeeDHCs_Selected: [], ShowAddOrderModal_Invoices: [{ Duration: 0, PaymentDueDate: '', PaymentMadeDate: '' }], ShowAddOrderModal_ProductId: null, ShowAddOrderModal_UnitPrice: null, ShowAddOrderModal_Loading: false, ShowAddOrderModal_Employees: { IsLoading: false, Loaded: false, Selected: [], Users: [] } });
        else this.setState({ ShowAddInvoiceModal, ShowAddInvoiceModal_CompanyId: CompanyId, ShowAddInvoiceModal_OrderId: OrderId }, () => this.onChangeAddOrderModal_Company({ CompanyId }));
    }

    onToggleShowDHCDetailModal = ({ DHC_UserId, ShowDHCDetailModal }) => {
        if (!ShowDHCDetailModal) this.setState({ ShowDHCDetailModal, ShowDHCDetailModal_Loading: false });
        else {
            this.setState({ ShowDHCDetailModal, ShowDHCDetailModal_Loading: true }, () => {
                this.props.DeliverySite_GetDHCDetails({ DHC_UserId }).then(({ UserDetails }) => {
                    this.setState({ ShowDHCDetailModal_Data: UserDetails, ShowDHCDetailModal_Loading: false });
                })
            });
        }
    }

    onToggleSorting = ({ SortCol: NewSortCol }) => {
        var { SortCol, SortDir } = this.state.Sorting;

        // SortDir Goes From ASC --> DESC --> Nothing

        // Toggle Sorting Of Already Sorted Column
            if (SortCol === NewSortCol) {
                if (SortDir === 'ASC') SortDir = 'DESC';
                else if (SortDir === 'DESC') {
                    SortDir = '';
                    SortCol = '';
                }
                else if (SortDir === '') SortDir = 'ASC';
            }
        // Start Sorting On New Column
            else {
                SortCol = NewSortCol;
                SortDir = 'ASC';
            }

        this.setState({ PageNo: 1, Sorting: { SortCol, SortDir } }, () => this.onLoadOrders());
    }

    renderAddOrderModal = () => {
        var { t } = this.props;
        var { ShowAddInvoiceModal, ShowAddOrderModal, ShowAddOrderModal_CompanyId, ShowAddOrderModal_EmployeeDHCs, ShowAddOrderModal_Employees, ShowAddOrderModal_Invoices, ShowAddOrderModal_ProductId, ShowAddOrderModal_UnitPrice, ShowAddOrderModal_Loading } = this.state;

        if (ShowAddInvoiceModal || ShowAddOrderModal) {
            var AllEmployeesHaveDHC = ShowAddOrderModal_EmployeeDHCs.filter(data => !!data && !!+data.DHC_UserId).length === ShowAddOrderModal_Employees.Selected.length;
            var AllInvoicesFilled = ShowAddOrderModal_Invoices.filter(data => !!data && !!+data.Duration && !!data.PaymentDueDate).length === ShowAddOrderModal_Invoices.length;

            var Disabled = false;
            if (!!ShowAddInvoiceModal && (!AllEmployeesHaveDHC || !AllInvoicesFilled || !ShowAddOrderModal_EmployeeDHCs.length || !ShowAddOrderModal_Invoices.length)) Disabled = true;
            else if (!!ShowAddOrderModal && (!AllEmployeesHaveDHC || !AllInvoicesFilled || !ShowAddOrderModal_CompanyId || !ShowAddOrderModal_EmployeeDHCs.length || !ShowAddOrderModal_Invoices.length || !ShowAddOrderModal_ProductId || !ShowAddOrderModal_UnitPrice)) Disabled = true;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={!!ShowAddInvoiceModal ? this.onAddInvoiceToExistingOrder : this.onAddOrder}
                    BottomButtonDisabled={Disabled}
                    BottomButtonText={t('_save')}
                    InformationRenderer={this.renderAddOrderModal_Information}
                    Loading={(ShowAddOrderModal_Loading)}
                    OnHide={!!ShowAddInvoiceModal ? () => this.onToggleShowAddInvoiceModal({ ShowAddInvoiceModal: false }) : () => this.onToggleShowAddOrderModal({ ShowAddOrderModal: false })}
                    Show={ShowAddInvoiceModal || ShowAddOrderModal}
                />
            );
        }
    }

    renderAddOrderModal_Information = () => {
        var { Filters: { Companies, DHCs, Products }, ShowAddInvoiceModal, ShowAddOrderModal_CompanyId, ShowAddOrderModal_EmployeeDHCs_Selected, ShowAddOrderModal_Employees, ShowAddOrderModal_Invoices, ShowAddOrderModal_ProductId, ShowAddOrderModal_UnitPrice } = this.state;

        var TitleText = !!ShowAddInvoiceModal ? 'Add invoice to existing order' : 'Add new order';

        return (
            <styles.InsertEditModalContainer className="InsertEditModalContainer">
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={TitleText} TextAlign="center" />

                {
                    !ShowAddInvoiceModal &&
                    <>
                        <Spacer Size="medium" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text="Choose company" TextAlign="center" />

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={true}
                            getOptionLabel={option => option.CompanyName}
                            getOptionValue={option => option.CompanyId}
                            isLoading={Companies.IsLoading}
                            isMulti={false}
                            // components={{ ValueContainer }}
                            hideSelectedOptions={true}
                            isSearchable
                            onChange={this.onChangeAddOrderModal_Company}
                            onFocus={this.onFocusFilter_Companies}
                            options={Companies.Companies}
                            placeholder="Companies"
                            styles={customStyles}
                            menuPosition="fixed"
                        />

                        <Spacer Size="medium" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text="Choose a product" TextAlign="center" />

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={true}
                            getOptionLabel={option => option.DisplayName}
                            getOptionValue={option => option.ProductId}
                            isLoading={Products.IsLoading}
                            isMulti={false}
                            // components={{ ValueContainer }}
                            hideSelectedOptions={true}
                            isSearchable
                            onChange={this.onChangeAddOrderModal_Product}
                            onFocus={this.onFocusFilter_Products}
                            options={Products.Products}
                            placeholder="Products"
                            styles={customStyles}
                            menuPosition="fixed"
                        />

                        {
                            !!ShowAddOrderModal_ProductId &&
                            <>
                                <Spacer Size="small" />
        
                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Unit price per delivery period per employee" TextAlign="left" />
        
                                <Spacer Size="extra-small" />
        
                                <styles.StyledAmountInput
                                    InputFontFamily="medium"
                                    InputFontSize="medium-1"
                                    InputWidth="100%"
                                    LabelFontFamily="medium"
                                    LabelFontSize="medium-1"
                                    Label="kr"
                                    OnChange={this.onChangeAddOrderModal_UnitPrice}
                                    Placeholder="0"
                                    Type="number"
                                    Value={ShowAddOrderModal_UnitPrice}
                                />
                            </>
                        }
                    </>
                }

                {
                    (!!ShowAddInvoiceModal || (!!ShowAddOrderModal_CompanyId && !!ShowAddOrderModal_ProductId && !!ShowAddOrderModal_UnitPrice)) &&
                    <>
                        <Spacer Size="medium" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text="Add employees" TextAlign="center" />

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            getOptionLabel={option => option.DisplayName}
                            getOptionValue={option => option.UserId}
                            isLoading={ShowAddOrderModal_Employees.IsLoading}
                            isMulti={true}
                            // components={{ ValueContainer }}
                            value={ShowAddOrderModal_Employees.Selected}
                            hideSelectedOptions={true}
                            isSearchable
                            onChange={this.onChangeAddOrderModal_Employees}
                            // onFocus={this.onFocusFilter_AddOrderModal_Employees}
                            options={ShowAddOrderModal_Employees.Users}
                            placeholder="Employees"
                            styles={customStyles}
                            menuPosition="fixed"
                        />

                        <Spacer Size="medium" />

                        <styles.AddEmployeesContainer className="AddEmployeesContainer">
                            {
                                ShowAddOrderModal_Employees.Selected.length > 0 &&
                                <styles.AddEmployeeContainer className="AddEmployeeContainer">
                                    <styles.AddEmployeeContainer_Employee className="AddEmployeeContainer_Employee">
                                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Employee" TextAlign="center" />
                                    </styles.AddEmployeeContainer_Employee>
                                    <styles.AddEmployeeContainer_DHC className="AddEmployeeContainer_DHC">
                                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="DHC" TextAlign="center" />
                                    </styles.AddEmployeeContainer_DHC>
                                </styles.AddEmployeeContainer>
                            }
                            {
                                ShowAddOrderModal_Employees.Selected.map(({ DisplayName, ProfilePhotoUrl, Sex, UserId }, SelectedIndex) => {
                                    return (
                                        <styles.AddEmployeeContainer className="AddEmployeeContainer" key={SelectedIndex}>
                                            <styles.AddEmployeeContainer_Employee className="AddEmployeeContainer_Employee">
                                                <styles.ProfilePhotoContainer className="ProfilePhotoContainer">
                                                    <ProfilePhoto Sex={Sex || 'Male'} Size="small-3" Source={ProfilePhotoUrl || ''} />
                                                </styles.ProfilePhotoContainer>
                                                {DisplayName}
                                            </styles.AddEmployeeContainer_Employee>
                                            <styles.AddEmployeeContainer_DHC className="AddEmployeeContainer_DHC">
                                                <Select
                                                    closeMenuOnSelect={true}
                                                    getOptionLabel={option => option.DisplayName}
                                                    getOptionValue={option => option.UserId}
                                                    isLoading={DHCs.IsLoading}
                                                    isMulti={false}
                                                    // components={{ ValueContainer }}
                                                    value={ShowAddOrderModal_EmployeeDHCs_Selected[SelectedIndex]}
                                                    hideSelectedOptions={true}
                                                    isSearchable
                                                    onChange={data => this.onChangeAddOrderModal_DHC({ data, Employee_UserId: UserId, SelectedIndex })}
                                                    options={DHCs.Users}
                                                    placeholder="DHC"
                                                    styles={customStyles}
                                                    menuPosition="fixed"
                                                />
                                            </styles.AddEmployeeContainer_DHC>
                                        </styles.AddEmployeeContainer>
                                    );
                                })
                            }
                        </styles.AddEmployeesContainer>   
                    </>
                }

                {
                    (!!ShowAddInvoiceModal || (!!ShowAddOrderModal_CompanyId && !!ShowAddOrderModal_ProductId && !!ShowAddOrderModal_UnitPrice && !!ShowAddOrderModal_Employees.Selected.length)) &&
                    <>
                        <Spacer Size="medium" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text="Build invoices" TextAlign="center" />

                        <Spacer Size="small" />

                        <styles.InvoicesContainer className="InvoicesContainer">
                            {
                                ShowAddOrderModal_Invoices.map(({ Duration, PaymentDueDate, PaymentMadeDate }, InvoiceIndex) => {
                                    Duration = Duration || 0;

                                    return (
                                        <styles.InvoiceContainer className="InvoiceContainer" key={InvoiceIndex}>
                                            <styles.InvoiceDataContainer className="InvoiceDataContainer">
                                                <label htmlFor="duration">Duration</label>
                                                <input
                                                    type="range"
                                                    id="duration"
                                                    step="1"
                                                    value={Duration}
                                                    min={0}
                                                    max={13} // 1-year, as 13 4-week periods in year
                                                    onChange={event => this.onChangeInvoiceDuration({ event, InvoiceIndex })}
                                                />

                                                <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${Duration}`} TextAlign="center" />
                                            </styles.InvoiceDataContainer>
                                            <styles.InvoiceDataContainer>
                                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text="Payment Amount" TextAlign="center" />
        
                                                <Spacer Size="extra-small" />

                                                <PageText FontFamily="semibold-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${ShowAddOrderModal_UnitPrice * ShowAddOrderModal_Employees.Selected.length * Duration} kr`} TextAlign="center" />
                                            </styles.InvoiceDataContainer>
                                            <styles.InvoiceDataContainer>
                                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text="Due date" TextAlign="center" />

                                                <Spacer Size="extra-small" />

                                                <input
                                                    type="date"
                                                    onChange={event => this.onChangeInvoiceDueDate({ event, InvoiceIndex })}
                                                    value={PaymentDueDate}
                                                />
                                            </styles.InvoiceDataContainer>
                                            <styles.InvoiceDataContainer>
                                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text="Paid date" TextAlign="center" />

                                                <Spacer Size="extra-small" />

                                                <input
                                                    type="date"
                                                    onChange={event => this.onChangeInvoiceMadeDate({ event, InvoiceIndex })}
                                                    value={PaymentMadeDate}
                                                />
                                            </styles.InvoiceDataContainer>
                                            <styles.InvoiceDataContainer>
                                                {ShowAddOrderModal_Invoices.length > 1 && <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin OnClick={() => this.onRemoveInvoice(InvoiceIndex)} Text="Delete Invoice?" TextAlign="center" />}
                                            </styles.InvoiceDataContainer>
                                        </styles.InvoiceContainer>
                                    );
                                })
                            }
                        </styles.InvoicesContainer>

                        {
                            !ShowAddInvoiceModal &&
                            <>
                                <Spacer Size="extra-small" />

                                <ButtonGroup
                                    Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: this.onAddNewInvoice, Title: "New Invoice" }]}
                                    ButtonWidth="fit-content"
                                    ContainerWidth="fit-content"
                                    JustifyContent="center"
                                />
                            </>
                        }
                    </>
                }
            </styles.InsertEditModalContainer>
        );
    }

    renderFilters = () => {
        var { t } = this.props;

        const popoverProps_Companies = {
            isOpen: this.state.PopoverIsOpen_Companies,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_Companies'),
            body: this.renderPopoverContent('Companies'),
            tipSize: 0.01
        }

        const popoverProps_DHCs = {
            isOpen: this.state.PopoverIsOpen_DHCs,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_DHCs'),
            body: this.renderPopoverContent('DHCs'),
            tipSize: 0.01
        }

        const popoverProps_Employees = {
            isOpen: this.state.PopoverIsOpen_Employees,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_Employees'),
            body: this.renderPopoverContent('Employees'),
            tipSize: 0.01
        }

        const popoverProps_Leaders = {
            isOpen: this.state.PopoverIsOpen_Leaders,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_Leaders'),
            body: this.renderPopoverContent('Leaders'),
            tipSize: 0.01
        }

        const popoverProps_MiddleLeaders = {
            isOpen: this.state.PopoverIsOpen_MiddleLeaders,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_MiddleLeaders'),
            body: this.renderPopoverContent('MiddleLeaders'),
            tipSize: 0.01
        }

        const popoverProps_TopLeaders = {
            isOpen: this.state.PopoverIsOpen_TopLeaders,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_TopLeaders'),
            body: this.renderPopoverContent('TopLeaders'),
            tipSize: 0.01
        }

        var { Filters: { Companies, DHCs, Employees, Leaders, MiddleLeaders, TopLeaders }} = this.state;

        return (
            <styles.FiltersContainer className="FiltersContainer">
                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Filters" TextAlign="left" />

                <Spacer Size="extra-small" />

                <styles.FiltersSubContainer className="FiltersSubContainer">
                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Companies" />

                            <Popover {...popoverProps_Companies}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_Companies')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_Companies')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            getOptionLabel={option => option.CompanyName}
                            getOptionValue={option => option.CompanyId}
                            isLoading={Companies.IsLoading}
                            isMulti
                            // components={{ ValueContainer }}
                            hideSelectedOptions={true}
                            isSearchable
                            onBlur={this.onBlurFilter_Companies}
                            onChange={data => this.onChangeFilter_Companies(data)}
                            onFocus={this.onFocusFilter_Companies}
                            options={Companies.Companies}
                            placeholder={t('Filter by Company')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>


                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="DHCs" />

                            <Popover {...popoverProps_DHCs}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_DHCs')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_DHCs')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            getOptionLabel={option => option.DisplayName}
                            getOptionValue={option => option.UserId}
                            isLoading={DHCs.IsLoading}
                            isMulti
                            // components={{ ValueContainer }}
                            hideSelectedOptions={true}
                            isSearchable
                            onBlur={this.onBlurFilter_DHCs}
                            onChange={data => this.onChangeFilter_DHCs(data)}
                            onFocus={this.onFocusFilter_DHCs}
                            options={DHCs.Users}
                            placeholder={t('Filter by DHC')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>


                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Employees" />

                            <Popover {...popoverProps_Employees}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_Employees')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_Employees')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            getOptionLabel={option => option.DisplayName}
                            getOptionValue={option => option.UserId}
                            isLoading={Employees.IsLoading}
                            isMulti
                            // components={{ ValueContainer }}
                            hideSelectedOptions={true}
                            isSearchable
                            onBlur={this.onBlurFilter_Employees}
                            onChange={data => this.onChangeFilter_Employees(data)}
                            onFocus={this.onFocusFilter_Employees}
                            options={Employees.Users}
                            placeholder={t('Filter by Employee')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>

                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Leaders" />

                            <Popover {...popoverProps_Leaders}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_Leaders')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_Leaders')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            getOptionLabel={option => option.DisplayName}
                            getOptionValue={option => option.UserId}
                            isLoading={Leaders.IsLoading}
                            isMulti
                            // components={{ ValueContainer }}
                            hideSelectedOptions={true}
                            isSearchable
                            onBlur={this.onBlurFilter_Leaders}
                            onChange={data => this.onChangeFilter_Leaders(data)}
                            onFocus={this.onFocusFilter_Leaders}
                            options={Leaders.Users}
                            placeholder={t('Filter by Leader')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>

                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Middle Leaders" />

                            <Popover {...popoverProps_MiddleLeaders}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_MiddleLeaders')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_MiddleLeaders')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            defaultValue={[]}
                            getOptionLabel={option => option.DisplayName}
                            getOptionValue={option => option.UserId}
                            isLoading={MiddleLeaders.IsLoading}
                            isMulti
                            // components={{ ValueContainer }}
                            hideSelectedOptions={false}
                            isSearchable
                            onBlur={this.onBlurFilter_MiddleLeaders}
                            onChange={data => this.onChangeFilter_MiddleLeaders(data)}
                            onFocus={this.onFocusFilter_MiddleLeaders}
                            options={MiddleLeaders.Users}
                            placeholder={t('Filter by Middle Leader')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>

                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Top Leaders" />

                            <Popover {...popoverProps_TopLeaders}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_TopLeaders')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_TopLeaders')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            defaultValue={[]}
                            getOptionLabel={option => option.DisplayName}
                            getOptionValue={option => option.UserId}
                            isLoading={TopLeaders.IsLoading}
                            isMulti
                            // components={{ ValueContainer }}
                            hideSelectedOptions={false}
                            isSearchable
                            onBlur={this.onBlurFilter_TopLeaders}
                            onChange={data => this.onChangeFilter_TopLeaders(data)}
                            onFocus={this.onFocusFilter_TopLeaders}
                            options={TopLeaders.Users}
                            placeholder={t('Filter by Top Leader')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>
                </styles.FiltersSubContainer>
            </styles.FiltersContainer>
        );
    }

    renderOrders = () => {
        var { Orders, PageNo, PageSize, TotalRecords } = this.state;

        return (
            <styles.BodyContainer className="BodyContainer">
                <styles.BodyHeader className="BodyHeader">
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text="Orders" TextAlign="left" />

                    <styles.BodyHeaderRight className="BodyHeaderRight">
                        <ButtonGroup
                            Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onToggleShowAddOrderModal({ ShowAddOrderModal: true }), Title: "New Order" }]}
                            ButtonWidth="fit-content"
                            ContainerWidth="fit-content"
                            JustifyContent="center"
                        />
                    </styles.BodyHeaderRight>
                </styles.BodyHeader>

                <Spacer Size="small" />

                {this.renderViewCount()}

                {
                    TotalRecords > 0 &&
                    <>
                        <Spacer Size="small" />

                        {
                            (PageNo !== 1 || PageSize < TotalRecords) &&
                            <>
                                <PaginationContainer OnPaginate={this.onPaginate} PageNo={PageNo} PageSize={PageSize} TotalRecords={TotalRecords} />
        
                                <Spacer Size="medium" />
                            </>
                        }

                        <styles.OrdersContainer className="OrdersContainer">
                            {
                                Orders.map(Order => {
                                    return (
                                        <OrderComponent
                                            key={Order.OrderId}
                                            GetInvoiceEmployees={this.props.DeliverySite_GetInvoiceEmployees}
                                            GetOrderDetails={this.props.DeliverySite_GetOrderDetails}
                                            OnToggleShowAddInvoiceModal={this.onToggleShowAddInvoiceModal}
                                            Order={Order}
                                        />
                                    )
                                    
                                })
                            }
                        </styles.OrdersContainer>
                    </>
                }
            </styles.BodyContainer>
        );
    }

    renderPopoverContent = ContentType => {
        // var { t } = this.props;

        return (
            <styles.PopoverContent className="PopoverContent">
                {ContentType === 'Companies' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Shows orders who have employees in the selected companies" TextAlign="center" />}
                {ContentType === 'DHCs' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Shows orders who have DHCs who have employees in the selected DHCs" TextAlign="center" />}
                {ContentType === 'Employees' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Shows orders who have employees in the selected employees" TextAlign="center" />}
                {ContentType === 'Leaders' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Shows all orders who have an employee who has a DHC who has a leader in the selected leaders" TextAlign="center" />}
                {ContentType === 'MiddleLeaders' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Shows all orders who have an employee who has a DHC who has a leader who has a middle leader in the selected middle leaders" TextAlign="center" />}
                {ContentType === 'TopLeaders' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Shows all orders who have an employee who has a DHC who has a leader who has a middle leader who has a top leader in the selected top leaders" TextAlign="center" />}
            </styles.PopoverContent>
        );
    }

    renderShowDHCDetailModal = () => {
        // var { t } = this.props;
        var { ShowDHCDetailModal, ShowDHCDetailModal_Loading } = this.state;

        if (ShowDHCDetailModal) {
            return (
                <Info
                    BottomButton="cancel"
                    InformationRenderer={this.renderShowDHCDetailModal_Information}
                    Loading={ShowDHCDetailModal_Loading}
                    OnHide={() => this.onToggleShowDHCDetailModal({ ShowDHCDetailModal: false })}
                    Show={ShowDHCDetailModal}
                    Size="small"
                />
            );
        }
    }

    renderShowDHCDetailModal_Information = () => {
        var { ShowDHCDetailModal_Data: UserDetails } = this.state;

        if (!UserDetails || Object.keys(UserDetails).length === 0) return null;

        var { BankAccountNo, DisplayName, DOB, Email, Employees, Leader, PersonalClients, PhoneNumber, ProfilePhotoUrl, Sex } = UserDetails;

        return (
            <styles.DHCDetailModalInfoContainer className="DHCDetailModalInfoContainer">
                <styles.ProfilePhotoContainer_Popup className="ProfilePhotoContainer_Popup">
                    <ProfilePhoto Sex={Sex || 'Male'} Size="extra-large" Source={ProfilePhotoUrl || ''} />
                </styles.ProfilePhotoContainer_Popup>

                <Spacer Size="extra-small" />

                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin TextAlign="left" Text={DisplayName} />
                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Email} />

                {
                    PhoneNumber &&
                    <>
                        <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={PhoneNumber} />
                    </>
                }

                <Spacer Size="extra-small" />

                <MultiText
                    Texts={[
                        { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: 'Responsible for' },
                        { FontColor: 'black', FontFamily: 'semibold-italic', FontSize: 'medium-1', Text: Employees.length },
                        { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: 'active employees' }
                    ]}
                />

                <MultiText
                    Texts={[
                        { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: 'Responsible for' },
                        { FontColor: 'black', FontFamily: 'semibold-italic', FontSize: 'medium-1', Text: PersonalClients.NumClients },
                        { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: 'personal clients' }
                    ]}
                />

                <styles.StyledHR />

                {
                    Employees.length > 0 &&
                    <>
                        <div>List companies and employee count</div>

                        <styles.StyledHR />
                    </>
                }

                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Leader" />
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Leader.DisplayName} />

                <styles.StyledHR />

                <styles.DHCDetailModalInfoFlexContainer className="DHCDetailModalInfoFlexContainer">
                    <MultiText
                        Texts={[
                            { FontColor: 'black', FontFamily: 'semibold-italic', FontSize: 'medium-1', Text: 'Sex:' },
                            { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: Sex }
                        ]}
                    />
                </styles.DHCDetailModalInfoFlexContainer>
                <styles.DHCDetailModalInfoFlexContainer className="DHCDetailModalInfoFlexContainer">
                    <MultiText
                        Texts={[
                            { FontColor: 'black', FontFamily: 'semibold-italic', FontSize: 'medium-1', Text: 'Birthday:' },
                            { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: DOB }
                        ]}
                    />
                </styles.DHCDetailModalInfoFlexContainer>
                <styles.DHCDetailModalInfoFlexContainer className="DHCDetailModalInfoFlexContainer">
                    <MultiText
                        Texts={[
                            { FontColor: 'black', FontFamily: 'semibold-italic', FontSize: 'medium-1', Text: 'Bank Account Number:' },
                            { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: BankAccountNo }
                        ]}
                    />
                </styles.DHCDetailModalInfoFlexContainer>
            </styles.DHCDetailModalInfoContainer>
        )
    }

    renderViewCount = () => {
        var { t } = this.props;

        var { PageNo, PageSize, TotalRecords } = this.state;

        if (!TotalRecords) TotalRecords = 0;

        return (
            <div className="ViewCount">
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${!TotalRecords ? 0 : ((PageNo - 1) * PageSize) + 1} - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                &nbsp;
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords} orders`} />
            </div>
        )
    }

    render() {
        return (
            <>
                {this.props.TryingDeliverySiteAction && <Loading />}

                <>
                    {this.renderFilters()}
                    {this.renderOrders()}
                </>

                {this.renderAddOrderModal()}
                {this.renderShowDHCDetailModal()}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        TryingDeliverySiteAction: state.DeliverySite.TryingDeliverySiteAction,
        TryingDeliverySiteActionError: state.DeliverySite.TryingDeliverySiteActionError
    };
};

export default withTranslation()(connect(mapStateToProps, { DeliverySite_GetOrders, DeliverySite_GetCompanies_Basic, DeliverySite_GetDHCs_Basic, DeliverySite_GetDHCDetails, DeliverySite_GetEmployees_Basic, DeliverySite_GetInvoiceEmployees, DeliverySite_GetLeaders_Basic, DeliverySite_GetMiddleLeaders_Basic, DeliverySite_GetOrderDetails, DeliverySite_GetProducts_Basic, DeliverySite_GetTopLeaders_Basic, DeliverySite_InsertInvoice, DeliverySite_InsertOrder } )(OrdersComponent));