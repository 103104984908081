import React from 'react';
import PropTypes from 'prop-types';

import { Container, CheckmarkIcon, CheckmarkItemName, DropdownContent, DropdownToggleIcon, HeaderContent, HeaderTitle, HeaderTitleContainer, IconTypesContainer, ItemContainer, ItemIcon, MarginRight, SelectedCountDot, SelectedCountDotContainer, SelectedCountText, StyledLoading, TitleIconImage } from './styles';

import Checkmark from '../Form/Checkmark';
import Spacer from '../Spacer';

import Blocked from '../../Assets/Icons/Blocked.png';
import Checkmark_Selected from '../../Assets/Icons/Checkmark_Selected.png';
import DownArrow from '../../Assets/Icons/DownArrow.png';
import UpArrow from '../../Assets/Icons/UpArrow.png';

class Dropdown extends React.Component {
    state = { HasOpened: false, HoveringTitleIcon: false, IsDropdownOpen: false };

    componentDidUpdate(prevProps) {
        if (!prevProps.ForceClose && this.props.ForceClose) this.setState({ IsDropdownOpen: false });

        if (prevProps.Name && this.props.Name && prevProps.Name !== this.props.Name) this.setState({ HasOpened: false });

        if (this.props.GetSize && ((prevProps.NeedsToLoad && !this.props.NeedsToLoad) || (!prevProps.NeedsToLoad && !this.props.NeedsToLoad)) && this.doReportSize) {
            // Subtract 20 from Header as we only need extra space from this
            this.props.GetSize(this.elementRef.getBoundingClientRect().height + (this.elementRefHeader.getBoundingClientRect().height - 20));
            this.doReportSize = false;
        }
    }

    onHoverTitleIcon = HoveringTitleIcon => {
        this.setState({ HoveringTitleIcon });
    }

    onToggleDropdownOpen = () => {
        var { HasOpened, IsDropdownOpen } = this.state;
        var { NeedsToLoad, NeedsToLoadFunc, OnToggleDropdown } = this.props;

        if (!IsDropdownOpen) this.doReportSize = true;
        else this.doReportSize = false;

        if (OnToggleDropdown) {
            if (!HasOpened && NeedsToLoad && NeedsToLoadFunc) this.setState({ HasOpened: true, IsDropdownOpen: !IsDropdownOpen }, () => { NeedsToLoadFunc(); OnToggleDropdown(this.state.IsDropdownOpen); });
            else this.setState({ IsDropdownOpen: !IsDropdownOpen }, () => OnToggleDropdown(this.state.IsDropdownOpen));
        } else {
            if (!HasOpened && NeedsToLoad && NeedsToLoadFunc) this.setState({ HasOpened: true, IsDropdownOpen: !IsDropdownOpen }, () => NeedsToLoadFunc());
            else this.setState({ IsDropdownOpen: !IsDropdownOpen });
        }
    }

    refCallback = element => {
        if (this.props.GetSize && element) {
            this.elementRef = element;
            this.props.GetSize(element.getBoundingClientRect().height);
        }
    }

    refCallbackHeader = element => {
        if (this.props.GetSize && element) this.elementRefHeader = element;
    }

    renderContent = () => {
        var { ContentRenderer, ContentRendererProps } = this.props;

        if (!ContentRenderer) return null;

        return (
            <>
                <ContentRenderer {...ContentRendererProps} />
            </>
        );
    }

    renderLoading = () => {
        return <StyledLoading />;
    }

    renderItems = () => {
        var { Disabled, ItemId, ItemIsIcon, ItemName, Items, OnSelectItem, SelectedAvoidItemIds, SelectedItemIds, ShowAvoidOption, Title } = this.props;

        return (
            <>
                {
                    ShowAvoidOption &&
                    <>
                        <IconTypesContainer>
                            <CheckmarkIcon ImageSrc={Blocked} Loading={false} />
                            <CheckmarkIcon ImageSrc={Checkmark_Selected} Loading={false} />
                        </IconTypesContainer>

                        <Spacer Size="extra-extra-small" />
                    </>
                }

                {
                    Items.map(Item => {
                        var ItemId_Actual = Number(Item[ItemId]);
                        var ItemName_Actual = Item[ItemName];
                        var SelectedIndex = SelectedItemIds.indexOf(ItemId_Actual);
                        var SelectedAvoidIndex = SelectedAvoidItemIds.indexOf(ItemId_Actual);

                        return (
                            <ItemContainer key={ItemId_Actual}>
                                {
                                    ShowAvoidOption &&
                                    <>
                                        <Checkmark
                                            BlockIcon
                                            Disabled={Disabled}
                                            ItemId={ItemId_Actual}
                                            OnSelectItem={e => OnSelectItem(e, ItemId_Actual, SelectedIndex, SelectedAvoidIndex, 'Avoid')}
                                            SelectedIndex={SelectedAvoidIndex}
                                            Title={Title}
                                        />

                                        <MarginRight />
                                    </>
                                }
                                <Checkmark
                                    Disabled={Disabled}
                                    ItemId={ItemId_Actual}
                                    OnSelectItem={e => OnSelectItem(e, ItemId_Actual, SelectedIndex, SelectedAvoidIndex, 'Target')}
                                    SelectedIndex={SelectedIndex}
                                    Title={Title}
                                />

                                {
                                    ItemIsIcon ?
                                    <ItemIcon
                                        ImageSrc={ItemName_Actual}
                                        Loading={false}
                                        OnClick={(!Disabled && !ShowAvoidOption) ? () => OnSelectItem(null, ItemId_Actual, SelectedIndex, SelectedAvoidIndex) : null}
                                    />
                                :
                                    <CheckmarkItemName
                                        Disabled={Disabled}
                                        ElementType="div"
                                        FontFamily="medium"
                                        FontSize="small"
                                        NoMargin
                                        OnClick={(!Disabled && !ShowAvoidOption) ? () => OnSelectItem(null, ItemId_Actual, SelectedIndex, SelectedAvoidIndex) : null}
                                        Text={`${ItemName_Actual}`}
                                    />
                                }
                            </ItemContainer>
                        );
                    })
                }
            </>
        );
    }

    renderSelectedCount = (SelectedItems, SelectedAvoidItems) => {
        return (
            <>
                {
                    SelectedAvoidItems && SelectedAvoidItems.length > 0 &&
                    <>
                        <SelectedCountDotContainer>
                            <SelectedCountDot Type="AvoidItem" />
                        </SelectedCountDotContainer>
                        <SelectedCountText ElementType="span" FontColor="red-bittersweet" FontFamily="semibold" FontSize="medium-1" Text={`${SelectedAvoidItems.length}`} />
                    </>
                }

                {
                    SelectedItems && SelectedItems.length > 0 &&
                    <>
                        <SelectedCountDotContainer>
                            <SelectedCountDot Type="SelectedItem" />
                        </SelectedCountDotContainer>
                        <SelectedCountText ElementType="span" FontColor="blue-abel" FontFamily="semibold" FontSize="medium-1" Text={`${SelectedItems.length}`} />
                    </>
                }
            </>
        );
    }

    render() {
        var { className, DefaultHeight, HideCount, IsList, NeedsToLoad, SelectedAvoidItemIds, SelectedItemIds, Title, TitleFontColor, TitleFontFamily, TitleFontSize, TitleIcon, TitleIconOnClick, TitleIconOnHover } = this.props;
        var { HasOpened, HoveringTitleIcon, IsDropdownOpen } = this.state;

        var TitleIconImageSrc = TitleIcon;
        if (TitleIconOnHover && HoveringTitleIcon) TitleIconImageSrc = TitleIconOnHover;

        return (
            <Container className={className} DefaultHeight={DefaultHeight} IsOpen={IsDropdownOpen}>
                <HeaderContent onClick={this.onToggleDropdownOpen} ref={this.refCallbackHeader}>
                    <HeaderTitleContainer>
                        {
                            TitleIcon &&
                            <TitleIconImage
                                alt="ABEL"
                                onClick={TitleIconOnClick}
                                onMouseEnter={TitleIconOnHover ? () => this.onHoverTitleIcon(true) : () => null}
                                onMouseLeave={TitleIconOnHover ? () => this.onHoverTitleIcon(false) : () => null}
                                src={TitleIconImageSrc}
                            />
                        }
                        <HeaderTitle ElementType="span" FontColor={TitleFontColor || null} FontFamily={TitleFontFamily || 'medium'} FontSize={TitleFontSize || 'small'} JustifyContent="flex-start" NoMargin Text={Title} />
                        {
                            (!HideCount && ((SelectedItemIds && SelectedItemIds.length) || (SelectedAvoidItemIds && SelectedAvoidItemIds.length))) ?
                            this.renderSelectedCount(SelectedItemIds, SelectedAvoidItemIds)
                        :
                            null
                        }
                    </HeaderTitleContainer>
                    <DropdownToggleIcon ImageSrc={IsDropdownOpen ? UpArrow : DownArrow} Loading={false} />
                </HeaderContent>
                <DropdownContent IsDropdownOpen={IsDropdownOpen} ref={this.refCallback}>
                    {
                        HasOpened && NeedsToLoad ?
                        this.renderLoading()
                    :
                        IsDropdownOpen ?
                        IsList ?
                        this.renderItems()
                    :
                        this.renderContent()
                    :
                        null
                    }
                </DropdownContent>
            </Container>
        )
    }
}

Dropdown.propTypes = {
    ContentRenderer: PropTypes.func,
    ContentRendererProps: PropTypes.object,
    DefaultHeight: PropTypes.string,
    Disabled: PropTypes.bool,
    ForceClose: PropTypes.bool,
    HideCount: PropTypes.bool,
    IsList: PropTypes.bool,
    ItemId: PropTypes.string,
    ItemIsIcon: PropTypes.bool,
    ItemName: PropTypes.string,
    Items: PropTypes.array,
    Loading: PropTypes.bool,
    NeedsToLoad: PropTypes.bool,
    NeedsToLoadFunc: PropTypes.func,
    OnToggleDropdown: PropTypes.func,
    OnSelectItem: PropTypes.func,
    SelectedAvoidItemIds: PropTypes.array,
    SelectedItemIds: PropTypes.array.isRequired,
    ShowAvoidOption: PropTypes.bool,
    Title: PropTypes.string,
    TitleFontColor: PropTypes.string,
    TitleFontFamily: PropTypes.string,
    TitleFontSize: PropTypes.string,
    TitleIcon: PropTypes.string,
    TitleIconOnClick: PropTypes.func,
    TitleIconOnHover: PropTypes.string,
}

Dropdown.defaultProps = {
    Disabled: false,
    HideCount: false,
    IsList: true,
    SelectedAvoidItemIds: [],
    ShowAvoidOption: false
}

export default Dropdown;