import {
    TRYING_ADD_EXISTING_DISH,
    TRYING_ADD_EXISTING_DISH_SUCCESS,
    TRYING_ADD_EXISTING_DISH_ERROR,

    TRYING_ADD_GET_STARTED_MEALS_SUCCESS,

    TRYING_ADD_LOG_FOOD,
    TRYING_ADD_LOG_FOOD_SUCCESS,
    TRYING_ADD_LOG_FOOD_ERROR,

    TRYING_COPY_MEALS,
    TRYING_COPY_MEALS_SUCCESS,
    TRYING_COPY_MEALS_ERROR,

    TRYING_DELETE_MEALS,
    TRYING_DELETE_MEALS_SUCCESS,
    TRYING_DELETE_MEALS_ERROR,

    TRYING_GET_MEAL_DISH_PREVIEW,
    TRYING_GET_MEAL_DISH_PREVIEW_SUCCESS,
    TRYING_GET_MEAL_DISH_PREVIEW_ERROR,

    TRYING_MOVE_MEALS,
    TRYING_MOVE_MEALS_SUCCESS,
    TRYING_MOVE_MEALS_ERROR,
    
    CLEAR_CLIENT_DETAILS,
    TRYING_GET_CLIENT_MEALS,
    TRYING_GET_CLIENT_MEALS_SUCCESS,
    TRYING_GET_CLIENT_MEALS_ERROR,

    TRYING_UPDATE_DAILY_ACTIVITY_LEVEL,

    TRYING_UPDATE_MEAL_ACCESS,
    TRYING_UPDATE_MEAL_ACCESS_SUCCESS,
    TRYING_UPDATE_MEAL_ACCESS_ERROR
} from '../Types';

const INITIAL_STATE = {
    ClientMeals: [],
    GetStarted_NeedsHeight: false,
    GetStarted_NeedsWeight: false,
    MealAccess: false,
    ShowGetStarted: false,
    
    TryingAddExistingDish: false,
    TryingAddExistingDishError: null,
    TryingAddLogFood: false,
    TryingAddLogFoodError: null,
    TryingCopyMeals: false,
    TryingCopyMealsError: null,
    TryingDeleteMeals: false,
    TryingDeleteMealsError: null,
    TryingGetClientMeals: false,
    TryingGetClientMealsError: null,
    TryingGetMealDishPreview: false,
    TryingGetMealDishPreviewError: null,
    TryingGiveMealAccess: false,
    TryingGiveMealAccessError: null,
    TryingMoveMeals: false,
    TryingMoveMealsError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_ADD_EXISTING_DISH:
            return { ...state, TryingAddExistingDish: true, TryingAddExistingDishError: null };
        case TRYING_ADD_EXISTING_DISH_ERROR:
            return { ...state, TryingAddExistingDish: false, TryingAddExistingDishError: action.payload };
        case TRYING_ADD_EXISTING_DISH_SUCCESS:
            return { ...state, TryingAddExistingDish: false, TryingAddExistingDishError: null };

        case TRYING_ADD_GET_STARTED_MEALS_SUCCESS:
            return { ...state, ShowGetStarted: false };
            // return { ...state, ShowGetStarted: false, TryingGetClientMeals: true };

        case TRYING_ADD_LOG_FOOD:
            return { ...state, TryingAddLogFood: true, TryingAddLogFoodError: null };
        case TRYING_ADD_LOG_FOOD_ERROR:
            return { ...state, TryingAddLogFood: false, TryingAddLogFoodError: action.payload };
        case TRYING_ADD_LOG_FOOD_SUCCESS:
            return { ...state, TryingAddLogFood: false, TryingAddLogFoodError: null };

        case TRYING_COPY_MEALS:
            return { ...state, TryingCopyMeals: true, TryingCopyMealsError: null };
        case TRYING_COPY_MEALS_ERROR:
            return { ...state, TryingCopyMeals: false, TryingCopyMealsError: action.payload };
        case TRYING_COPY_MEALS_SUCCESS:
            return { ...state, TryingCopyMeals: false, TryingCopyMealsError: null };

        case TRYING_DELETE_MEALS:
            return { ...state, TryingDeleteMeals: true, TryingDeleteMealsError: null };
        case TRYING_DELETE_MEALS_ERROR:
            return { ...state, TryingDeleteMeals: false, TryingDeleteMealsError: action.payload };
        case TRYING_DELETE_MEALS_SUCCESS:
            var DeletedMeals = action.payload;

            var AfterDeleteClientMeals = [];
            state.ClientMeals.map(Day => {
                Day.dataMeals = Day.dataMeals.filter(MealDish => DeletedMeals.indexOf(+MealDish.uId) === -1);
                AfterDeleteClientMeals.push(Day);
                return Day;
            });

                return { ...state, ClientMeals: AfterDeleteClientMeals, TryingDeleteMeals: false, TryingDeleteMealsError: null };

        case CLEAR_CLIENT_DETAILS:
            return { ...state, ClientMeals: [], GetStarted_NeedsHeight: false, GetStarted_NeedsWeight: false, ShowGetStarted: false };
        case TRYING_GET_CLIENT_MEALS:
            return { ...state, TryingGetClientMeals: true, TryingGetClientMealsError: null };
        case TRYING_GET_CLIENT_MEALS_ERROR:
            return { ...state, TryingGetClientMeals: false, TryingGetClientMealsError: action.payload };
        case TRYING_GET_CLIENT_MEALS_SUCCESS:
            var { Meals, MealAccess, NeedsHeight, NeedsWeight, ShowGetStarted } = action.payload;

            return { ...state, ClientMeals: Meals, GetStarted_NeedsHeight: NeedsHeight, GetStarted_NeedsWeight: NeedsWeight, MealAccess: !!MealAccess, ShowGetStarted: !!ShowGetStarted, TryingGetClientMeals: false, TryingGetClientMealsError: null };

        case TRYING_GET_MEAL_DISH_PREVIEW:
            return { ...state, TryingGetMealDishPreview: true, TryingGetMealDishPreviewError: null };
        case TRYING_GET_MEAL_DISH_PREVIEW_ERROR:
            return { ...state, TryingGetMealDishPreview: false, TryingGetMealDishPreviewError: action.payload };
        case TRYING_GET_MEAL_DISH_PREVIEW_SUCCESS:
            return { ...state, TryingGetMealDishPreview: false, TryingGetMealDishPreviewError: null };

        case TRYING_MOVE_MEALS:
            return { ...state, TryingMoveMeals: true, TryingMoveMealsError: null };
        case TRYING_MOVE_MEALS_ERROR:
            return { ...state, TryingMoveMeals: false, TryingMoveMealsError: action.payload };
        case TRYING_MOVE_MEALS_SUCCESS:
            var { IsBetween, MealIds } = action.payload;

            var AfterMoveMeals = [];

            // If the date the meals were moved to are not in currently weekly view, then delete meals from the week
            if (!IsBetween) {
                state.ClientMeals.map(Day => {
                    Day.dataMeals = Day.dataMeals.filter(MealDish => MealIds.indexOf(+MealDish.uId) === -1);
                    AfterMoveMeals.push(Day);
                    return Day;
                });
            } else AfterMoveMeals = state.ClientMeals;

            return { ...state, ClientMeals: AfterMoveMeals, TryingMoveMeals: false, TryingMoveMealsError: null };

        case TRYING_UPDATE_DAILY_ACTIVITY_LEVEL:
            // eslint-disable-next-line
            var { ActivityLevel, Calories, DayIndex } = action.payload;

            var ClientMeals = [ ...state.ClientMeals ];
            ClientMeals[DayIndex] = { ...ClientMeals[DayIndex], ActivityLevel, Calories };

            return { ...state, ClientMeals };

        case TRYING_UPDATE_MEAL_ACCESS:
            if (action.payload.ReturnDataType !== 'ClientDetail') return { ...state };
            return { ...state, TryingGiveMealAccess: true, TryingGiveMealAccessError: null };
        case TRYING_UPDATE_MEAL_ACCESS_ERROR:
            if (action.payload.ReturnDataType !== 'ClientDetail') return { ...state };
            return { ...state, TryingGiveMealAccess: false, TryingGiveMealAccessError: action.payload.ErrorMessage };
        case TRYING_UPDATE_MEAL_ACCESS_SUCCESS:
            if (action.payload.ReturnDataType !== 'ClientDetail') return { ...state };
            // eslint-disable-next-line
            var { Meals, MealAccess, NeedsHeight, NeedsWeight, ShowGetStarted } = action.payload;

            return { ...state, ClientMeals: Meals || [], GetStarted_NeedsHeight: NeedsHeight, GetStarted_NeedsWeight: NeedsWeight, ShowGetStarted: !!ShowGetStarted, MealAccess: !!MealAccess, TryingGiveMealAccess: false, TryingGiveMealAccessError: null };

        default:
            return state;
    }
};
