import {
    TRYING_ADD_ADDITIONAL_ACTIVITIES,
    TRYING_ADD_ADDITIONAL_ACTIVITIES_SUCCESS,
    TRYING_ADD_ADDITIONAL_ACTIVITIES_ERROR,

    CLEAR_ADDITIONAL_ACTIVITY_DETAILS,
    TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS,
    TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS_SUCCESS,
    TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS_ERROR
} from '../Types';

const INITIAL_STATE = {
    AdditionalActivities: [],
    Intensities: [],
    RetrievedAdditionalActivitySettings: false,
    
    TryingAddAdditionalActivities: false,
    TryingAddAdditionalActivitiesError: null,
    TryingGetAdditionalActivityDetails: false,
    TryingGetAdditionalActivityDetailsError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_ADD_ADDITIONAL_ACTIVITIES:
            return { ...state, TryingAddAdditionalActivities: true, TryingAddAdditionalActivitiesError: null };
        case TRYING_ADD_ADDITIONAL_ACTIVITIES_ERROR:
            return { ...state, TryingAddAdditionalActivities: false, TryingAddAdditionalActivitiesError: action.payload };
        case TRYING_ADD_ADDITIONAL_ACTIVITIES_SUCCESS:
            return { ...state, TryingAddAdditionalActivities: false, TryingAddAdditionalActivitiesError: null };

        case CLEAR_ADDITIONAL_ACTIVITY_DETAILS:
            return { ...state, AdditionalActivities: [], Intensities: [], RetrievedAdditionalActivitySettings: false };
        case TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS:
            return { ...state, TryingGetAdditionalActivityDetails: true, TryingGetAdditionalActivityDetailsError: null };
        case TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS_ERROR:
            return { ...state, TryingGetAdditionalActivityDetails: false, TryingGetAdditionalActivityDetailsError: action.payload };
        case TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS_SUCCESS:
            return { ...state, ...action.payload, RetrievedAdditionalActivitySettings: true, TryingGetAdditionalActivityDetails: false, TryingGetAdditionalActivityDetailsError: null };
        default:
            return state;
    }
};
