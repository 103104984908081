import styled from 'styled-components';
import { device } from '../../Config/device';

import Dropdown from '../../Components/Dropdown';
import Image from '../../Components/Image';
import Input from '../../Components/Form/Input';
import InputLabelSideBySide from '../../Components/Form/InputLabelSideBySide';

export const ExampleContainer = styled.div`
    :first-child {
        margin-right: 20px;
    }

    @media ${device.minwidth.ipad} {
        :first-child {
            margin-right: 40px;
        }
    }
`;

export const HelpBoxContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-width: 100%;
    padding: 20px;

    @media ${device.minwidth.ipad} {
        padding: 50px 100px;
    }
`;

export const MultiSelectRadioButton = styled.div`
    background-color: ${props => props.ReadOnly ? 'var(--white-concrete)' : props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    border: 2px solid var(--white-concrete);
    border-radius: 50%;
    height: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            ${props => !props.ReadOnly && `
                background-color: var(--blue-astronaut);
                cursor: pointer;
            `}
        }
    }
`;

export const NutrientImage = styled(Image)`
    height: 50px;
`;

export const NutrientInput = styled(Input)`
    > input {
        border: none;
        line-height: 55px;
        text-align: center;
        text-indent: 0px;
        padding: 0px;
    }
`;

export const NutrientInputContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    width: 100px;
`;

export const NutrientContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const NutrientsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const NutrientSection = styled.div`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(${props => props.OneColumn ? 1 : 2 }, 1fr);
    margin-bottom: 20px;

    @media ${device.minwidth.mobile} {
        grid-template-columns: repeat(${props => props.OneColumn ? 1 : 3 }, 1fr);
    }

    @media ${device.minwidth.ipad} {
        grid-gap: 60px;
        margin-bottom: 60px;
    }
`;

export const OptionalLine = styled.div`
    border-bottom: 1px solid var(--black);
    line-height: 0.1em;
    margin: 10px 0 20px;
    max-width: 600px;
    text-align: center;
    width: 100%;

    > span {
        background-color: var(--white); 
        padding: 0 10px; 
    }
`;

export const RequiredHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    text-align: center;
`;

export const StyledDropdown = styled(Dropdown)`
    margin: 0 auto;
    min-height: 45px;
    width: 100%;

    ${props => props.ForceClose === false ? 'z-index: 1001;' : null};

    ${
        props => props.IsMobile ?
        `
            ${props.Height ? `height: ${props.Height + 45 + 15 < 45 ? 45 : props.Height + 45 + 15}px;` : 'height: inherit;'}
            
            > div { max-height: none; }
        `
    :
        `
            ${props.Height ? `height: ${props.Height + 45 + 15 < 45 ? 45 : props.Height + 45 + 15}px;` : 'height: inherit;'}
            margin: 0 auto;
        `
    }
`;

export const StyledFoodName = styled(Input)`
    > div {
        font-family: var(--font-family-medium);
        font-size: var(--font-size-medium-3);
        margin-bottom: 20px;
        text-align: center;
    }

    > input {
        text-align: center;
        text-indent: 0px;
    }
`;

export const StyledHR = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    grid-column: 1/-1;
    height: 2px;
    margin: 10px 0;
`;

export const StyledInput = styled(Input)`
    ${props => props.InputWidth && `width: ${props.InputWidth};`}

    width: 100%;

    @media ${device.maxwidth.ipad} {
        max-width: 200px;
    }

    > input {
        ${props => props.ReadOnly && 'border: none;'}
        border-radius: 10px;
        height: 100%;
        overflow: hidden;
        padding: 10px 15px;
        text-align: ${props => props.TextAlign ? props.TextAlign : 'center'};
        text-indent: 0px;
    }
`;

export const StyledInputLabelSideBySide = styled(InputLabelSideBySide)`
    width: 100%;

    @media ${device.maxwidth.ipad} {
        max-width: 200px;
    }

    & input {
        border-radius: 10px;
    }
`;

export const UnitListColumnContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: ${props => props.LeftAlign ? 'flex-start' : 'center'};
    flex-direction: column;

    ${props => props.ReadOnly &&
        `
            background-color: var(--white-concrete);
            border-radius: 10px;
        `
    }
`;

export const UnitsListContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
`;

export const UnitOptionsItem = styled.div`
    ${
        props => props.Selected ? `color: var(--blue-abel);`
    :
        props => props.Disabled ? `color: var(--gray);`
    :
        null
    }

    :not(:last-child) {
        margin-bottom: 5px;
    }

    ${props => !props.Disabled &&
        `
            @media(hover:hover) {
                :hover {
                    color: var(--blue-abel);
                    cursor: pointer;
                }
            }
        `
    }
`;

export const UnitOptionsList = styled.div``;

export const UnitsListRow = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;

    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(3, 1fr);
    }

    @media ${device.minwidth.laptop} {
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    }
`;

export const WordExplanationContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
`;

export const WordExplanationsContainer = styled.div`
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(1, 1fr);

    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(3, 1fr);
    }
`;