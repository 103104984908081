import styled from 'styled-components';
import { device } from '../../Config/device';

import BodyMuscles from '../../Components/BodyMuscles';
import Dropdown from '../../Components/Dropdown';
import Image from '../../Components/Image';
import TextArea from '../../Components/Form/TextArea';

import Checkmark from '../../Assets/Icons/Checkmark_Selected.png';

export const DateTimeContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 15px;
    margin-top: 15px;
`;

export const DateTimesContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -15px;
    margin-top: -15px;
`;

export const EditIcon = styled(Image)`
    height: 30px;
    margin-right: 15px;
    margin-top: 15px;
    width: 30px;
`;

export const GoalContainer = styled.div`
    align-items: center;
    border: ${props => props.Selected ? '5px solid var(--blue-abel)' : '2px solid var(--white-concrete)'};
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px;
    
    @media ${device.maxwidth.mobile} {
        width: 100%;

        :not(:last-child) {
            margin-bottom: 10px;
        }
    }

    @media ${device.minwidth.ipad} {
        flex-direction: column;
        height: 180px;
        justify-content: center;
        margin-right: 15px;
        margin-top: 15px;
        padding: 20px;
        width: 245px;
    }

    @media(hover:hover) {
        :hover {
            border: 5px solid var(--blue-abel);
            cursor: pointer;
        }
    }
`;

export const GoalImage = styled(Image)`
    height: 45px;
    margin-right: 15px;

    @media ${device.minwidth.ipad} {
        height: 75px;
        margin-right: 0px;
    }
`;

export const GoalsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-right: -15px;
        margin-top: -15px;
    }
`;

export const InjuriesDropdownContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${device.maxwidth.mobile} {
        > div {
            width: 100%;

            :not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    @media ${device.minwidth.ipad} {
        align-items: flex-start;
        flex-direction: row;

        > div {
            min-width: 200px;
            width: fit-content;
        }
    }
`;

export const SelectedDateContainer = styled.div`
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    margin-right: 15px;
    margin-top: 15px;
    padding: 5px 12px;
`;

export const SelectedDatesContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -15px;
    margin-top: -15px;
`;

export const StyledBodyMuscles = styled(BodyMuscles)`
    margin: 0 auto;
    max-width: 100%;
    width: 400px;
`;

export const StyledDropdown = styled(Dropdown)`
    margin: 0 auto;
    max-width: 350px;
    width: 100%;

    ${props => props.ForceClose === false ? 'z-index: 1001;' : null};

    ${
        props => props.IsMobile ?
        `
            > div { max-height: none; }
        `
    :
        `
            ${props.Height ? `height: ${props.Height + 45 + 15}px;` : ''}
            margin: 0 auto;
            width: 350px
        `
    }
`;

export const TrainerNoteContainer = styled.div``;

export const TrainerNoteTextArea = styled(TextArea)``;

export const WorkoutTimeOptionContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : props.Disabled ? 'var(--gray-silver)' : 'var(--white)'};
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    color: ${props => props.Selected ? 'var(--white)' : props.Disabled ? 'var(--black)' : 'var(--black)'};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 10px;

    @media(hover:hover) {
        ${props => !props.Disabled && `
            :hover {
                background-color: var(--blue-astronaut);
                color: var(--white);
            }
        `}

        cursor: ${props => props.Disabled ? 'not-allowed' : 'pointer'};
    }
`;

export const WorkoutTimeOptionsContainer = styled.div`
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(3, 1fr);
    padding: 10px;
    width: 100%;
`;

export const WorkoutTimeUseAllDays = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    display: block;
    height: 25px;
    margin-right: 5px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const WorkoutTimeUseAllDaysContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 10px;
`;