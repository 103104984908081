import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearClientDetails, ClearEnduranceProgress, ViewEnduranceProgress, ViewUserWorkoutMaxes_Endurance } from '../../Services/Actions';
import history from '../../history';

import  { ExerciseContainer, ExerciseImageContainer, LinkContainer, ListContainer2, ListProgressContainer, OverallPercentageContainer, ProgressStatsContainer2, ProgressStatsContainerInner2, StyledExerciseImage, StyledLineBar, StyledExerciseLoadMore, StyledSearchInput, TotalProgressCircleContainer } from './styles';

import CircleProgress from '../../Components/Charts/ProgressBars/Circle';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import { SecondsToTime } from '../../Functions';

class Endurance extends React.PureComponent {
    _isMounted = false;

    state = {
        ActiveSearch: false,
        LastSearchedValue: '',
        Loading: true,
        NewSearch: false,
        PageNo: 1,
        PageSize: 48,
        SearchValue: ''
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientId } = this.props.match.params;

        if (!+ClientId) history.push('/');
        else {
            var { PageNo, PageSize } = this.state;

            this.props.ViewEnduranceProgress({ ClientId, PageNo, PageSize }).then(() => {
                if (this.props.TryingViewEnduranceProgressError) history.push('/clients');
                else this.setState({ Loading: false });
            });
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        
        this.props.ClearClientDetails();
        this.props.ClearEnduranceProgress();
    }

    onChangeSearch = event => {
        this.setState({ SearchValue: event.target.value });
    }

    onKeyPress = ({ key }) => {
        if (key === 'Enter' && !this.props.TryingGetUserWorkoutMaxes_Endurance) {
            var { SearchValue, LastSearchedValue } = this.state;

            if (SearchValue !== LastSearchedValue) {
                this.setState({ PageNo: 1, LastSearchedValue: SearchValue }, () => this.onLoadEndurances());
            }
        }
    }

    onLoadEndurances = Increment => {
        var { ClientId } = this.props.match.params;
        var { NewSearch, PageNo, PageSize, SearchValue } = this.state;

        if (Increment) PageNo = PageNo + 1;
        else {
            NewSearch = true;
            PageNo = 1;
        }

        this.setState({ ActiveSearch: true, NewSearch, PageNo }, () => this.props.ViewUserWorkoutMaxes_Endurance({ AddToExisting: Increment, ClientId, EnduranceName: SearchValue, PageNo, PageSize }).then(() => this._isMounted && this.setState({ ActiveSearch: false, NewSearch: false })));
    }

    renderEndurances = () => {
        var { t } = this.props;
        var { Endurances: { Endurances, Endurances_IsMoreResults, TotalRecords }, TryingGetUserWorkoutMaxes_Endurance } = this.props;
        var { ActiveSearch, NewSearch, PageNo, PageSize, SearchValue } = this.state;

        return (
            <ListContainer2>
                <StyledSearchInput
                    FontSize="medium-2"
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeSearch}
                    OnKeyPress={this.onKeyPress}
                    Placeholder={t('search_with_first_char_uppercase_dots')}
                    Size="medium"
                    Type="text"
                    Value={SearchValue}
                />

                <Spacer Size="small" />

                <StyledExerciseLoadMore
                    ActiveSearch={ActiveSearch}
                    CompletedFirstSearch
                    IsLoading={TryingGetUserWorkoutMaxes_Endurance}
                    ItemDescriptionType={t('intervals').toLowerCase()}
                    ItemId="EnduranceTemplateId"
                    ItemName="EnduranceName"
                    ItemRenderer={this.renderEndurancesList}
                    Items={Endurances}
                    HasMoreItems={!!+Endurances_IsMoreResults}
                    LoadMore={() => this.onLoadEndurances(true)}
                    NewSearch={NewSearch}
                    NoItemsText={t('search_noresults')}
                    PageNo={PageNo}
                    PageSize={PageSize}
                    TotalRecords={TotalRecords}
                />
            </ListContainer2>
        );
    }

    renderEndurancesList = ({ Item: { ActiveType, CompletionPercentage, CompletionStatus, EnduranceTemplateId, EnduranceImage, EnduranceName, Progress: { Current, Goal, ProgressLine, Starting }, Units }}) => {
        var { t } = this.props;
        var { ClientId } = this.props.match.params;

        var CurrentText = `${Current}`;
        var GoalText = `${Goal}`;
        var StartingText = `${Starting}`;

        if (ActiveType === 'Distance') {
            CurrentText = SecondsToTime(Current);
            GoalText = SecondsToTime(Goal);
            StartingText = SecondsToTime(Starting);
        }

        return (
            <ExerciseContainer className="ExerciseContainer" Completed={CompletionStatus === 'ok'}>
                <ExerciseImageContainer NoBorder>
                    <StyledExerciseImage ImageSrc={EnduranceImage || ''} SelfLoading />
                </ExerciseImageContainer>

                <Spacer Size="small" />

                <LinkContainer to={`/client/${ClientId}/endurance/${EnduranceTemplateId}`}>
                    <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={EnduranceName} TextAlign="center" />
                </LinkContainer>

                <Spacer Size="small" />

                <ListProgressContainer className="ListProgressContainer">
                    {
                        !!+Goal &&
                        <>
                            <PageText FontFamily="medium" FontSize="small" NoMargin Text={`${CompletionPercentage}% ${t('ptadmin_contenttype_header_progress')}`} TextAlign="center" />

                            <Spacer Size="small" />
                        </>
                    }

{
                        !!+Starting && ActiveType === 'Time' &&
                        <>
                            <PageText FontFamily="medium" FontSize="small" NoMargin Text={Units} TextAlign="center" />

                            <Spacer Size="small" />
                        </>
                    }

                    {
                        !!+Starting ?
                        <>
                            <ProgressStatsContainer2 className="ProgressStatsContainer2">
                                <ProgressStatsContainerInner2>
                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('WorkoutPlan_Starting')} TextAlign="center" WordBreak="keep-all" />

                                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={StartingText} TextAlign="center" WordBreak="keep-all" />
                                </ProgressStatsContainerInner2>

                                <ProgressStatsContainerInner2>
                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${t('WorkoutPlan_Current')}`} TextAlign="center" WordBreak="keep-all" />

                                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={CurrentText} TextAlign="center" WordBreak="keep-all" />
                                </ProgressStatsContainerInner2>

                                <ProgressStatsContainerInner2>
                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${t('Goal')}`} TextAlign="center" WordBreak="keep-all" />

                                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={GoalText} TextAlign="center" WordBreak="keep-all" />
                                </ProgressStatsContainerInner2>
                            </ProgressStatsContainer2>

                            {
                                !!+Goal &&
                                <>
                                    <Spacer Size="extra-small" />

                                    <StyledLineBar
                                        CompletionStatus={CompletionStatus}
                                        CurrentValue={Current}
                                        GoalMax={0}
                                        GoalMin={Goal}
                                        HideCurrent
                                        IsTime={ActiveType === 'Distance'}
                                        ProgressLine={ProgressLine}
                                        Units={Units}
                                    />
                                </>
                            }
                        </>
                    :
                        <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('WorkoutPlan_This_is_a_new_interval')} TextAlign="center" />
                    }
                </ListProgressContainer>
            </ExerciseContainer>
        );
    }

    render() {
        if (this.state.Loading || this.props.TryingViewEnduranceProgress) return <Loading />;

        var { t } = this.props;
        var { ClientDetails: { ClientId, UserName }, Endurances: { OverallPercentage }, TryingViewEnduranceProgress } = this.props;

        return (
            <>
                {TryingViewEnduranceProgress && <Loading />}

                <Page
                    renderBreadcrumbs={[
                        { Name: `@${UserName}`, LinkURL: `client/${ClientId}`, Props: { SelectedTab: 'Progress' }},
                        { Name: t('Endurance')}
                    ]}
                >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={t('Endurance')} />

                    <Spacer Size="large" />
                    
                    <OverallPercentageContainer>
                        <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('total_progress')} TextAlign="center" />

                        <Spacer Size="small" />

                        <TotalProgressCircleContainer>
                            <CircleProgress Percentage={OverallPercentage} />
                        </TotalProgressCircleContainer>
                    </OverallPercentageContainer>

                    <Spacer Size="large" />

                    <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('intervals')} TextAlign="center" />

                    <Spacer Size="small" />

                    {this.renderEndurances()}
                </Page>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        ClientDetails: state.Client.ClientDetails,
        
        Endurances: state.Progress.Endurances,

        TryingGetUserWorkoutMaxes_Endurance: state.Progress.TryingGetUserWorkoutMaxes_Endurance,
        TryingGetUserWorkoutMaxes_EnduranceError: state.Progress.TryingGetUserWorkoutMaxes_EnduranceError,
        TryingViewEnduranceProgress: state.Progress.TryingViewEnduranceProgress,
        TryingViewEnduranceProgressError: state.Progress.TryingViewEnduranceProgressError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearClientDetails, ClearEnduranceProgress, ViewEnduranceProgress, ViewUserWorkoutMaxes_Endurance } )(Endurance));