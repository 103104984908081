import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { GroupHeaderContainer, GroupInfoContainer, GroupInfoSubContainer, StyledGroupName, StyledPageTextWithDotAfter, StyledTeamPicture } from './styles';

import MultiText from '../../Components/Text/MultiText';

class Group extends React.Component {
    state = {
        LoadingTeamPicture: true
    }

    onLoadedTeamPicture = () => {
        this.setState({ LoadingTeamPicture: false });
    }

    render() {
        var { t } = this.props;
        var { GroupId, GroupImage, GroupName, GroupType, NumManagers, NumMembers } = this.props.Group;
        var { LoadingTeamPicture } = this.state;

        GroupType = GroupType.toLowerCase();

        var GroupTypeText = t(`${GroupType === 'team' ? 'Team' : 'Group'}`);
        
        if (GroupType === 'team') var ManagerTexts = [{ FontFamily: "semibold", FontSize: "medium-1", Text: `${NumManagers}` }, { FontFamily: 'medium', FontSize: 'medium-1', Text: NumManagers === 1 ? t('manager').toLowerCase() : t('managers').toLowerCase() }];
        var MemberTexts = [{ FontFamily: "semibold", FontSize: "medium-1", Text: `${NumMembers}` }, { FontFamily: 'medium', FontSize: 'medium-1', Text: NumMembers === 1 ? t('ptadmin_clients_singular').toLowerCase() : t('ptadmin_clients_plural').toLowerCase() }];
        
        return (
            <GroupHeaderContainer className="GroupHeaderContainer" HasPicture={+GroupId !== 0}>
                {
                    +GroupId !== 0 &&
                    <StyledTeamPicture
                        ImageSrc={GroupImage || ''}
                        Loading={LoadingTeamPicture}
                        Margin="0 20px 0 0"
                        OnLoaded={this.onLoadedTeamPicture}
                        RoundImage
                    />
                }                
                <GroupInfoContainer className="GroupInfoContainer">
                    <StyledGroupName FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={GroupName} TextAlign="left" />

                    <GroupInfoSubContainer className="GroupInfoSubContainer">
                        {!!+GroupId && <StyledPageTextWithDotAfter FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={GroupTypeText} TextAlign="left" />}

                        <MultiText Texts={MemberTexts} />

                        {
                            GroupType === 'team' &&
                            <>
                                <StyledPageTextWithDotAfter FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="" TextAlign="left" />

                                <MultiText Texts={ManagerTexts} />
                            </>
                        }
                    </GroupInfoSubContainer>
                </GroupInfoContainer>
            </GroupHeaderContainer>
        );
    }
}

Group.propTypes = {
    Group: PropTypes.object.isRequired
}

export default withTranslation()(Group);