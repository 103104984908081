import React from 'react';
// import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ForgotPasswordSendCode, ForgotPasswordUpdatePassword, LoginUser, AccountIntegration_Login, ValidateUser } from '../../Services/Actions';
// import history from '../../history';

import { APPLE_CLIENT_ID, APPLE_REDIRECT_URI, BETA_SITE_URL, FACEBOOK_CLIENT_ID, GOOGLE_CLIENT_ID } from '../../Config';

import AppleLogin from 'react-apple-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';

import { AbelLogo, AbelLogoContainer, AbelLogoImageContainer, AbelNameContainer, BottomHalf, Container, CurusLoginContainer, CurusLoginFields, CurusLogoContainer, CurusLogoStyledImage, CurusStyledInput, ForgotPasswordImageContainer, InnerContainer, LanguageContainer, LanguageFlagIcon, LeftArrowContainer, LoginFormContainer, LoginOptionsContainer, OptionalLine, SocialLoginOptions, SocialLoginsTopRow, StyledInput, StyledSexButtonGroups, TopHalf } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import AbelIcon from '../../Assets/Icons/Abel_HeaderIcon.png';
import Apple from '../../Assets/Illustrations/Apple.png';
import Facebook from '../../Assets/Illustrations/Facebook.png';
import Female from '../../Assets/Icons/FemaleSymbol_Color.png';
import Flag_English from '../../Assets/Illustrations/Flag_English.png';
import Flag_Norway from '../../Assets/Illustrations/Flag_Norway.png';
import ForgotPassword from '../../Assets/Illustrations/ForgotPassword.png';
import Google from '../../Assets/Illustrations/Google.png';
import LeftArrow from '../../Assets/Icons/LeftArrow.png';
import LeftArrow_White from '../../Assets/Icons/LeftArrow_White.png';
import Male from '../../Assets/Icons/MaleSymbol_Color.png';
import TrustMe from '../../Assets/Illustrations/TrustMe_Wide.png';

import { emailValidator } from '../../Functions';

const UsernameRegex = UserName => {
    return /^(?=[a-zA-Z0-9._-]{3,32}$)(?!.*[._-]{2})[^._-].*[^._-]$/.test(UserName);
}

const UserNameRules = [
    { Id: 1, Name: 'username_validation_length', regex: /^[a-zA-Z0-9._-]{3,32}$/, regexAnswer: true },
    { Id: 2, Name: 'username_validation_chars', regex: /^[a-zA-Z0-9._-]*$/, regexAnswer: true },
    { Id: 3, Name: 'username_validation_startend', regex: /^[^._-].*[^._-]$/, regexAnswer: true },
    { Id: 4, Name: 'username_validation_multiplechars_inarow', regex: /[._-]{2,}/, regexAnswer: false }
]

class Login extends React.Component {
    state = {
        Code: '',
        ConfirmPassword: '',
        DisplayScreen: 'Login',
        DOB: '',
        Email: '',
        IsEmail: false,
        Loading: true,
        NewPassword: '',
        Password: '',
        Sex: null,
        ShowCurusExtraInfo: false,
        UserName: '',
        ValidPassword: false,
        WrongOTP: '',

        Language: null,

        FocusedInput: null,
        LastValidatedUserName: '',
        ValidUserName: false,
        UserNameTaken: false
    };

    componentDidMount() {
        window.location = `${BETA_SITE_URL}/login`;

        // if (this.props.IsLoggedIn) {
        //     if (!this.props.UserDetails.OrganizationId) history.push('/clients');
        //     else history.push('/income');
        // }
        // else {
        //     var { language } = i18n;

        //     if (!language) language = 'en';

        //     if (language.substring('no') > -1) this.setState({ Language: 2, Loading: false });
        //     else this.setState({ Language: 1, Loading: false });
        // }
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    onBlurUserName = () => {
        var { LastValidatedUserName, UserName } = this.state;

        if (LastValidatedUserName === UserName) this.setState({ FocusedInput: null });
        else if (!UsernameRegex(UserName)) this.setState({ FocusedInput: null, ValidUserName: false });
        else {
            this.props.ValidateUser({ Register: true, UserName }).then(({ UserNameTaken }) => {
                this.setState({ FocusedInput: null, LastValidatedUserName: UserName, ValidUserName: true, UserNameTaken: !!UserNameTaken });
            });
        }
    }

    onChangeDisplayScreen = DisplayScreen => {
        this.setState({ DisplayScreen, ...DisplayScreen === 'Login' && { ShowCurusExtraInfo: false } });
    }

    onChangeInput = (event, InputName, InputValue) => {
        var { IsEmail, ValidPassword } = this.state;
        if (InputName === 'Email') IsEmail = emailValidator(event.target.value);
        if (InputName === 'ConfirmPassword') ValidPassword = event.target.value === this.state.NewPassword;
        if (InputName === 'NewPassword') ValidPassword = event.target.value === this.state.ConfirmPassword;

        var NewValue = '';
        if (event) NewValue = event.target.value;
        else NewValue = InputValue;

        if (InputName === 'UserName') NewValue = NewValue.replace(/[^a-zA-Z0-9._-]/g, '');

        this.setState({ [InputName]: NewValue, IsEmail, ValidPassword });
    }

    onChangeLanguage = Language => {
        this.setState({ Language }, () => {
            var LanguageCode = +Language === 1 ? 'en' : 'no';

            this.props.i18n.changeLanguage(LanguageCode);
        });
    }

    onClickLoginCurus = () => {
        var ClientId = '918af5a3-8f14-4b1e-b86d-540c0ac0ba76'; // Dev
        ClientId = '918afd40-85b9-4edf-9019-e6efe3872ce1'; // Local
        var RedirectUri = 'https://dev.admin.abel.fit/curus_callback'; // Dev
        RedirectUri = 'http://localhost:3000/curus/callback'; // Local
        var ResponseType = 'code';
        var Scope = '';
        var State = '';

        return window.open(`https://www.curus.no/oauth/authorize?client_id=${ClientId}&redirect_uri=${RedirectUri}&response_type=${ResponseType}&scope=${Scope}&state=${State}`, '_blank');
    }

    onFocusInput = ({ InputName }) => {
        this.setState({ FocusedInput: InputName });
    }

    onForgotPassword = () => {
        if (!this.props.TryingForgotPasswordSendCode) {
            this.props.ForgotPasswordSendCode({ Email: this.state.Email });
            this.onChangeDisplayScreen('NewPassword');
        }
    }

    onKeyPress = event => {
        if (event.key === 'Enter' && !this.props.TryingLoginUser) {
            var { DisplayScreen, Email, Password } = this.state;

            if (DisplayScreen === 'Login' && Email && Password) this.onLogin();
            else if (DisplayScreen === 'ForgotPassword' && this.state.Email && this.state.IsEmail) this.onForgotPassword();
            else if (DisplayScreen === 'NewPassword' && this.state.Code && this.state.NewPassword && this.state.ConfirmPassword && this.state.ValidPassword) this.onNewPassword();
        }
    }

    onLogin = () => {
        if (!this.props.TryingLoginUser) {
            var { Email, Password } = this.state;

            this.props.LoginUser({ Email, Password });
        }
    }

    onLoginCurus = () => {
        if (!this.props.TryingLoginUser) {
            var { DOB, Email, Password, Sex, UserName } = this.state;

            this.props.AccountIntegration_Login({ ...DOB && { DOB }, Email, Password, ...Sex && { Sex }, ...UserName && { UserName } }).then(({ NeedsMoreInfo }) => {
                if (NeedsMoreInfo) this.setState({ ShowCurusExtraInfo: true });
            });
        }
    }

    onLoginFacebook = response => {
        console.log('Facebook response: ', response);
        var { email: Email, id: FacebookId, picture: { data: { url: UserPhoto } } } = response;

        // accessToken: "EAAQl7rB9QfkBABiZALgN6ueF2bGrvTn0ldVyBrlHlDOr9Q16XgPXAcBDZCguYnhOhgAMP5tF42MjJ42ZBqXvbkG6rxxoTirexOxFnCtGN5zfP5eZCXUFpUqbIfbJzqjaUYM0ZCDV3DbBFstix8vRAn3Q9ZC3iDFGV3UGvk5VU99bkEIinPw0SBMttnfhHCvWJaYUzlqd8k4wZDZD"
        // data_access_expiration_time: 1605635820
        // email: "ridgescores@gmail.com"
        // expiresIn: 3780
        // graphDomain: "facebook"
        // id: "10157571562860959"
        // name: "Ridge Robinson"
        // picture:
        // data:
        // height: 50
        // is_silhouette: false
        // url: "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=10157571562860959&height=50&width=50&ext=1600451820&hash=AeSyJRTJ-iug0LVm"
        // width: 50
        // __proto__: Object
        // __proto__: Object
        // signedRequest: "DtDcXJDUYtsO8pmd1SAbsBqfzc9h8w2wEU0Kl__SJhM.eyJ1c2VyX2lkIjoiMTAxNTc1NzE1NjI4NjA5NTkiLCJjb2RlIjoiQVFCSjRtLXdBTFpRRDNIZHU3OEpVVlU3WHk0aFY0Uzhud2NQcXVzSlVNdjd5VHFMaUZ6aFhpY3BkaXNYdlBnX3c4ZklIZTR4Wmk1S3BUSFN1SVp1bGcwcU5jZVdCb0UtNWE2UzdJbUFhRy12VGRHamJCWU5EMG5fOVBDN2F1d2RORy05ZnA1UERfOGFJTXN5UDJYel9IX0V2eHBTZ1FlSURZNVdMMEU5WERBUktkOU1sd0IxMWlXN0ZfeTZ0cmlwTTdvNnZDZHhkNWNRdUpLdWtIZlhSOWJvam1tbVRXTE1NM0Nxd1hXaUNBNHFXNmdaY0FsU3JSQ3ZVTGN3R09CaTl1WlNxb0pMVTY0RW5iUllGM2tMejdab01jTk1kajZvVmFmWG93MVJ2U0pLMlpIZUFweXhGXzdRa1lHeG04R1FFNUdNLUZHbUVQZnR3bXVmajR1UVc0bVIiLCJhbGdvcml0aG0iOiJITUFDLVNIQTI1NiIsImlzc3VlZF9hdCI6MTU5Nzg1OTgyMH0"
        // userID: "10157571562860959"

        if (!this.props.TryingLoginUser) this.props.LoginUser({ Email, FacebookId, UserPhoto });
    }

    onLoginGoogle = response => {
        // console.log('Google response: ', response);
        if (!response.error) {
            var { profileObj: { email: Email, familyName: LastName, givenName: FirstName, googleId: GmailId, imageUrl: UserPhoto } } = response;

            // Da: "102748667033122119194"
            // accessToken: "ya29.a0AfH6SMBbZLml_BmMJXy_AIWwH2o05F4cxU-7SEBenIb-CDFO1dXUvqTdtIgmLni8uvLx_EPhNPL_STXALcrAbch8IbNaqvAaix1kYml2SZCMjh5oLh-iqE7g40U42B0TL_YZT2s64IgO82R3Ierik1_EojY6a9gw1As"
            // googleId: "102748667033122119194"
            // profileObj: {googleId: "102748667033122119194", imageUrl: "https://lh3.googleusercontent.com/a-/AOh14Ggd8A5P1iOW6hjpNhYJYXVJpw_ONK52HFvIKYrQlg=s96-c", email: "ridgescores@gmail.com", name: "Ridge Robinson", givenName: "Ridge", …}
            // rt: Ew {NT: "102748667033122119194", Ad: "Ridge Robinson", tV: "Ridge", uT: "Robinson", TJ: "https://lh3.googleusercontent.com/a-/AOh14Ggd8A5P1iOW6hjpNhYJYXVJpw_ONK52HFvIKYrQlg=s96-c", …}
            // tokenId: "eyJhbGciOiJSUzI1NiIsImtpZCI6IjZiYzYzZTlmMThkNTYxYjM0ZjU2NjhmODhhZTI3ZDQ4ODc2ZDgwNzMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiMjcxNTExNDUzNTI5LWwwOWhnYjdoMzJncjJpdmo5c3N2N2Q5cGEyNDUzcXVuLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMjcxNTExNDUzNTI5LWwwOWhnYjdoMzJncjJpdmo5c3N2N2Q5cGEyNDUzcXVuLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTAyNzQ4NjY3MDMzMTIyMTE5MTk0IiwiZW1haWwiOiJyaWRnZXNjb3Jlc0BnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6Ikh6WGc0UG9ZUTA0MHJ0SndrbFFXcGciLCJuYW1lIjoiUmlkZ2UgUm9iaW5zb24iLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EtL0FPaDE0R2dkOEE1UDFpT1c2aGpwTmhZSllYVkpwd19PTks1MkhGdklLWXJRbGc9czk2LWMiLCJnaXZlbl9uYW1lIjoiUmlkZ2UiLCJmYW1pbHlfbmFtZSI6IlJvYmluc29uIiwibG9jYWxlIjoiZW4iLCJpYXQiOjE1OTc4NjAwMjIsImV4cCI6MTU5Nzg2MzYyMiwianRpIjoiZGQ5ZTU0MmRhYWI0ZjQ2NTY5YjQ4MTcxOTA0YWU1M2ZkZWVkNGRiOCJ9.LkaifaWvrJT9YEYx4DWNgrEtkLDXT9ae2rwBYoNy4y1tfWSCRqrGh4fNSCTsT27gI_A0pHHM0NviZZoQgDrl0X0kFZxNVOSBIV14jsZAO_o6OgHQBQow5ojmK5L3BS245wTl4vIG9n6ITnxEoHLT3Vok3xJIc-kgbK6OFbxqbETEJolnoWqG-cWosO4q_h10xOjKPejRUUBJd7zdjBwrUUcYZzjMy8mhRBww2Yyco8V-ilcecwM1nQZYNYef578FTu4Y-Z90Z2tuNS4Un6bPJA-2IgDTETtlrSrET9xrgU0OEScqO7klPSt20hiVHUO4Ya3wvpXgLVk6F1kryDtGvw"
            // tokenObj: {token_type: "Bearer", access_token: "ya29.a0AfH6SMBbZLml_BmMJXy_AIWwH2o05F4cxU-7SEBenIb…-iqE7g40U42B0TL_YZT2s64IgO82R3Ierik1_EojY6a9gw1As", scope: "email profile https://www.googleapis.com/auth/user…//www.googleapis.com/auth/userinfo.profile openid", login_hint: "AJDLj6J9nXGC8nvyinFBHbJdEwBhl_MpGHR0pm1cKTiIeq8qEy3XeEs1i2X3iOo3g9HgI_MsntktHGuYJGnCXpAtZCf6XlgHZQ", expires_in: 3599, …}
            // wc: {token_type: "Bearer", access_token: "ya29.a0AfH6SMBbZLml_BmMJXy_AIWwH2o05F4cxU-7SEBenIb…-iqE7g40U42B0TL_YZT2s64IgO82R3Ierik1_EojY6a9gw1As", scope: "email profile https://www.googleapis.com/auth/user…//www.googleapis.com/auth/userinfo.profile openid", login_hint: "AJDLj6J9nXGC8nvyinFBHbJdEwBhl_MpGHR0pm1cKTiIeq8qEy3XeEs1i2X3iOo3g9HgI_MsntktHGuYJGnCXpAtZCf6XlgHZQ", expires_in: 3599, …}
            // __proto__: Object

            if (!this.props.TryingLoginUser) this.props.LoginUser({ Email, FirstName, GmailId, LastName, UserPhoto });
        }
    }

    onNewPassword = () => {
        if (!this.props.TryingForgotPasswordUpdatePassword) {
            var { Code, Email, NewPassword } = this.state;
        
            this.props.ForgotPasswordUpdatePassword({ Email, NewPassword, OTP: Code }).then(({ WrongOTP }) => {
                if (!WrongOTP) this.onChangeDisplayScreen('Login');
                else this.setState({ WrongOTP });
            });
        }
    }

    renderBackArrow = () => {
        var { DisplayScreen } = this.state;

        if (DisplayScreen === 'Login') return null;

        var ImageSrc = LeftArrow;
        if (DisplayScreen === 'CurusLogin') ImageSrc = LeftArrow_White;

        return (
            <LeftArrowContainer>
                <img src={ImageSrc} alt="Go back" onClick={() => this.onChangeDisplayScreen('Login')} />
            </LeftArrowContainer>
        );
    }

    renderCode = () => {
        var { t } = this.props;

        return (
            <StyledInput
                FontSize="medium-2"
                NoLabel
                OnChange={event => this.onChangeInput(event, 'Code')}
                OnKeyPress={this.onKeyPress}
                Placeholder={t('code_with_first_char_uppercase')}
                Size="extra-large"
                Type="text"
                Value={this.state.Code}
            />
        );
    }

    renderCurusLogin = () => {
        var { t } = this.props;
        var { TryingLoginUserError } = this.props;
        var { DOB, FocusedInput, Sex, ShowCurusExtraInfo, UserName, UserNameTaken, ValidUserName } = this.state;

        var Disabled = false;
        if (!this.state.Email || !this.state.Password) Disabled = true;
        if (!Disabled && ShowCurusExtraInfo && (!DOB || !Sex || !UserName || !!UserNameTaken || !ValidUserName)) Disabled = true;

        if (ShowCurusExtraInfo) {
            var Buttons = [];
            Buttons.push({ BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Border: Sex === 'Female' ? '3px solid var(--blue-astronaut)' : null, Color: 'black', ColorHover: 'black', FontFamily: Sex === 'Female' ? 'semibold' : 'medium', Icon: Female, IconPosition: 'left', OnClick: () => this.onChangeInput(null, 'Sex', 'Female'), Title: t('female_with_first_char_uppercase') });
            Buttons.push({ BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Border: Sex === 'Male' ? '3px solid var(--blue-astronaut)' : null, Color: 'black', ColorHover: 'black', FontFamily: Sex === 'Male' ? 'semibold' : 'medium', Icon: Male, IconPosition: 'left', OnClick: () => this.onChangeInput(null, 'Sex', 'Male'), Title: t('male_with_first_char_uppercase') });

            return (
                <CurusLoginContainer className="CurusLoginContainer">
                    <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('account_integration_extra_info_needed')} TextAlign="center" />

                    <Spacer Size="medium" />

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('username')} TextAlign="center" />

                    <CurusStyledInput
                        FontSize="medium-3"
                        OnBlur={this.onBlurUserName}
                        OnChange={event => this.onChangeInput(event, 'UserName')}
                        OnFocus={() => this.onFocusInput({ InputName: 'UserName' })}
                        Placeholder={t('username')}
                        Size="large"
                        Type="text"
                        Value={UserName}
                    />

                    {UserNameTaken && <PageText FontColor="red-bittersweet" FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('username_taken')} TextAlign="left" />}

                    {
                        FocusedInput === 'UserName' &&
                        <>
                            {/* <Spacer Size="small" /> */}

                            <ul>
                                {
                                    UserNameRules.map(({ Id, Name, regex, regexAnswer }) => {
                                        return (
                                            <React.Fragment key={Id}>
                                                <li>
                                                    <PageText
                                                        FontColor={regex.test(UserName) === regexAnswer ? 'green-success' : 'red-bittersweet'}
                                                        FontFamily="medium"
                                                        FontSize="small"
                                                        JustifyContent="flex-start"
                                                        NoMargin
                                                        Text={t(Name)}
                                                        TextAlign="left"
                                                    />
                                                </li>
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </ul>
                        </> 
                    }

                    <Spacer Size="small" />

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('birthday')} TextAlign="center" />

                    <CurusStyledInput
                        FontSize="medium-3"
                        OnChange={event => this.onChangeInput(event, 'DOB')}
                        Size="large"
                        Type="date"
                        Value={DOB}
                    />

                    <Spacer Size="small" />

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('sex')} TextAlign="center" />

                    <StyledSexButtonGroups
                        Buttons={Buttons}
                        ButtonWidth="fit-content"
                        NotTouching
                    />

                    <Spacer Size="medium" />

                    <ButtonGroup Buttons={[{ BackgroundColor: 'curus-green', BackgroundColorHover: 'curus-green-hover', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', FontSize: 'medium-2', OnClick: Disabled ? null : this.onLoginCurus, Size: 'large', Title: t('login_with_first_char_uppercase') }]} />

                    {
                        TryingLoginUserError && 
                        <>
                            <Spacer Size="small" />

                            <PageText FontColor="warning" FontFamily="semibold" FontSize="medium-2" JustifyContent="center" Text={TryingLoginUserError === 'Invalid Credentials' ? t('invalid_credentials') : t('account_integration_notactive')} TextAlign="center" />
                        </>
                    }
                </CurusLoginContainer>
            );
        }

        return (
            <CurusLoginContainer className="CurusLoginContainer">
                <CurusLogoContainer className="CurusLogoContainer">
                    <CurusLogoStyledImage ImageSrc={TrustMe} SelfLoading />
                </CurusLogoContainer>

                <Spacer Size="medium" />

                <CurusLoginFields className="CurusLoginFields">
                    <CurusStyledInput
                        AutoComplete="email"
                        FontSize="medium-2"
                        Label={t('email_with_first_char_uppercase')}
                        OnChange={event => this.onChangeInput(event, 'Email')}
                        OnKeyPress={this.onKeyPress}
                        // Placeholder={t('email_with_first_char_uppercase')}
                        Size="extra-large"
                        Type="text"
                        Value={this.state.Email}
                    />

                    <CurusStyledInput
                        AutoComplete="current-password"
                        FontSize="medium-2"
                        Label={t('password_with_first_char_uppercase')}
                        OnChange={event => this.onChangeInput(event, 'Password')}
                        OnKeyPress={this.onKeyPress}
                        // Placeholder={t('password_with_first_char_uppercase')}
                        Size="extra-large"
                        Type="password"
                        Value={this.state.Password}
                    />
                </CurusLoginFields>

                <ButtonGroup Buttons={[{ BackgroundColor: 'curus-green', BackgroundColorHover: 'curus-green-hover', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', FontSize: 'medium-2', OnClick: Disabled ? null : this.onLoginCurus, Size: 'large', Title: t('login_with_first_char_uppercase') }]} />

                {
                    TryingLoginUserError && 
                    <>
                        <Spacer Size="small" />

                        <PageText FontColor="warning" FontFamily="semibold" FontSize="medium-2" JustifyContent="center" Text={TryingLoginUserError === 'Invalid Credentials' ? t('invalid_credentials') : t('account_integration_notactive')} TextAlign="center" />
                    </>
                }
            </CurusLoginContainer>
        );
    }

    renderEmail = FromInput => {
        var { t } = this.props;

        var Placeholder = t('email_or_username_with_first_char_uppercase');
        if (FromInput === 'ForgotPassword') Placeholder = t('email_with_first_char_uppercase');

        return (
            <StyledInput
                AutoComplete="email"
                FontSize="medium-2"
                NoLabel
                OnChange={event => this.onChangeInput(event, 'Email')}
                OnKeyPress={this.onKeyPress}
                Placeholder={Placeholder}
                Size="extra-large"
                Type="text"
                Value={this.state.Email}
            />
        );
    }

    renderForgotPassword = () => {
        var { t } = this.props;

        return (
            <>
                <ForgotPasswordImageContainer>
                    <img src={ForgotPassword} alt={t('RegisterLogin_forgot_password')} />
                </ForgotPasswordImageContainer>
                
                <PageText FontFamily="semibold" FontSize="large" JustifyContent="center" NoMargin Text={t('RegisterLogin_forgot_your_password')} TextAlign="center" />
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('RegisterLogin_enter_your_email_below')} TextAlign="center" />

                <Spacer Size="medium-1" />

                <form onSubmit={this.handleSubmit}>
                    {this.renderEmail('ForgotPassword')}

                    <Spacer Size="large" />

                    {this.renderForgotPasswordError()}
                    {this.renderForgotPasswordButton()}
                </form>
            </>
        );
    }

    renderForgotPasswordButton = () => {
        var { t } = this.props;

        var Disabled = false;
        if (!this.state.Email || !this.state.IsEmail) Disabled = true;

        var Buttons = [{ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', FontSize: 'medium-2', OnClick: Disabled ? null : this.onForgotPassword, Size: 'large', Title: t('RegisterLogin_reset_password') }];

        return <ButtonGroup Buttons={Buttons} />;
    }

    renderForgotPasswordError = () => {
        var { t } = this.props;

        if (this.state.Email && !this.state.IsEmail) {
            return <PageText FontColor="warning" FontFamily="semibold" FontSize="medium-2" JustifyContent="center" Text={t('provide_valid_email_address')} TextAlign="center" />;
        }
    }

    renderLanguage = () => {
        var { DisplayScreen, Language } = this.state;

        var Languages = {
            '1': { Icon: Flag_English, LanguageId: 1, LanguageName: 'English'},
            '2': { Icon: Flag_Norway, LanguageId: 2, LanguageName: 'Norsk'}
        };

        return (
            <LanguageContainer onClick={() => this.onChangeLanguage(Language === 1 ? 2 : 1)}>
                <LanguageFlagIcon alt="Change Language" src={Languages[`${Language}`].Icon} />
                <PageText FontColor={DisplayScreen === 'CurusLogin' ? 'white' : 'black'} FontFamily="black" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={Languages[`${Language}`].LanguageName.toUpperCase()} TextAlign="left" />
            </LanguageContainer>
        );
    }

    renderLoginButton = () => {
        var { t } = this.props;

        var Disabled = false;
        if (!this.state.Email || !this.state.Password) Disabled = true;

        var Buttons = [{ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', FontSize: 'medium-2', OnClick: Disabled ? null : this.onLogin, Size: 'large', Title: t('login_with_first_char_uppercase') }];

        return <ButtonGroup Buttons={Buttons} />;
    }

    renderLoginButton_Social = ({ Color, Icon, OnClick, Title }) => {
        return <ButtonGroup Buttons={[{ Border: `5px solid var(--${Color})`, Color, ColorHover: Color, FontFamily: 'semibold', FontSize: 'medium-2', Icon, IconPosition: 'left', OnClick, Size: 'small', Title }]} />;
    }

    renderLoginError = () => {
        var { t } = this.props;

        if (this.props.TryingLoginUserError) {
            return <PageText FontColor="warning" FontFamily="semibold" FontSize="medium-2" JustifyContent="center" Text={t('invalid_credentials')} TextAlign="center" />;
        }
    }

    renderLoginForm = () => {
        var { t } = this.props;

        return (
            <>
                <LoginFormContainer className="LoginFormContainer">
                    <TopHalf className="TopHalf">
                        <PageText FontFamily="black" FontSize="extra-large-2" JustifyContent="center" NoMargin Text={t('login_with_first_char_uppercase').toUpperCase()} TextAlign="center" />

                        {/* <Spacer Size="extra-extra-small" />

                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('dont_have_account')} TextAlign="center" />
                        <PageText FontColor="blue-abel" FontFamily="bold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('Register_here')} TextAlign="center" />
                        */}

                        <Spacer Size="medium-1" />

                        <form onSubmit={this.handleSubmit}>
                            {this.renderEmail()}
                            {this.renderPassword()}

                            <Spacer Size="large-1" />

                            {this.renderLoginError()}

                            <LoginOptionsContainer className="LoginOptionsContainer">
                                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-end" NoMargin OnClick={() => this.onChangeDisplayScreen('ForgotPassword')} Text={t('RegisterLogin_forgot_password')} TextAlign="right" />
                            </LoginOptionsContainer>

                            <Spacer Size="small" />

                            {this.renderLoginButton()}
                        </form>
                    </TopHalf>

                    <Spacer Size="extra-large" />

                    <BottomHalf className="BottomHalf">
                        <OptionalLine>
                            <PageText ElementType="span" FontFamily="medium" FontSize="medium-2" NoMargin Text={t('login_with')} TextAlign="center" />
                        </OptionalLine>

                        <Spacer Size="extra-small" />

                        <SocialLoginOptions>
                            <SocialLoginsTopRow>
                                <FacebookLogin
                                    appId={FACEBOOK_CLIENT_ID}
                                    fields="name,email,picture"
                                    callback={this.onLoginFacebook}
                                    disableMobileRedirect={true}
                                    isMobile={false}
                                    render={({ onClick }) => this.renderLoginButton_Social({ Color: 'facebook', Icon: Facebook, OnClick: onClick, Title: 'Facebook' })}
                                />

                                <GoogleLogin
                                    clientId={GOOGLE_CLIENT_ID}
                                    buttonText="LOGIN WITH GOOGLE"
                                    onSuccess={this.onLoginGoogle}
                                    onFailure={this.onLoginGoogle}
                                    render={({ onClick }) => this.renderLoginButton_Social({ Color: 'google', Icon: Google, OnClick: onClick, Title: 'Google' })}
                                />

                                <AppleLogin
                                    clientId={APPLE_CLIENT_ID}
                                    redirectURI={APPLE_REDIRECT_URI}
                                    responseMode="fragment"
                                    responseType="code id_token"
                                    render={({ onClick }) => this.renderLoginButton_Social({ Color: 'apple', Icon: Apple, OnClick: onClick, Title: 'Apple' })}
                                />
                            </SocialLoginsTopRow>

                            <ButtonGroup Buttons={[{ BackgroundColor: 'curus-green', BackgroundColorHover: 'curus-green-hover', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', FontSize: 'medium-2', OnClick: () => this.onChangeDisplayScreen('CurusLogin'), Size: 'small', Title: 'TrustMe - Ed' }]} />
                        </SocialLoginOptions>
                    </BottomHalf>   
                </LoginFormContainer>
            </>
        );
    }

    renderLoginForm_Old = () => {
        var { t } = this.props;

        return (
            <>
                <LoginFormContainer className="LoginFormContainer">
                    <TopHalf className="TopHalf">
                        <PageText FontFamily="black" FontSize="extra-large-2" JustifyContent="center" NoMargin Text={t('login_with_first_char_uppercase').toUpperCase()} TextAlign="center" />

                        {/* <Spacer Size="extra-extra-small" />

                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('dont_have_account')} TextAlign="center" />
                        <PageText FontColor="blue-abel" FontFamily="bold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('Register_here')} TextAlign="center" /> */}

                        <Spacer Size="medium-1" />

                        <form onSubmit={this.handleSubmit}>
                            {this.renderEmail()}
                            {this.renderPassword()}

                            <Spacer Size="large-1" />

                            {this.renderLoginError()}

                            <LoginOptionsContainer className="LoginOptionsContainer">
                                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-end" NoMargin OnClick={() => this.onChangeDisplayScreen('ForgotPassword')} Text={t('RegisterLogin_forgot_password')} TextAlign="right" />
                            </LoginOptionsContainer>

                            <Spacer Size="small" />

                            {this.renderLoginButton()}

                            <Spacer Size="extra-small" />

                            <ButtonGroup Buttons={[{ BackgroundColor: 'curus-green', BackgroundColorHover: 'curus-green-hover', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', FontSize: 'medium-2', OnClick: () => this.onChangeDisplayScreen('CurusLogin'), Size: 'large', Title: t('curus_login') }]} ButtonWidth="fit-content" />
                        </form>
                    </TopHalf>
                </LoginFormContainer>
            </>
        );
    }

    renderLogo = () => {
        var { Device } = this.props; // Change FontColor If Mobile As Logo Is On White Background Then
        var FontColor = 'white';
        if (Device === 'mobile' || Device === 'mobile_small') FontColor = 'blue-abel';

        return (
            <AbelLogoContainer>
                <AbelLogoImageContainer>
                    <AbelLogo src={AbelIcon} alt="ABEL - The Digital Personal Trainer" />
                </AbelLogoImageContainer>
                <AbelNameContainer>
                    <PageText FontColor={FontColor} FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text="ABEL" TextAlign="left" />
                    <PageText FontColor={FontColor} FontFamily="medium" FontSize="medium-1half" JustifyContent="flex-start" NoMargin Text="Admin" TextAlign="left" />
                </AbelNameContainer>
            </AbelLogoContainer>
        );
    }

    renderNewPasswordButton = () => {
        var { t } = this.props;

        var Disabled = false;
        if (!this.state.Code || !this.state.NewPassword || !this.state.ConfirmPassword || !this.state.ValidPassword) Disabled = true;

        var Buttons = [{ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', FontSize: 'medium-2', OnClick: Disabled ? null : this.onNewPassword, Size: 'large', Title: t('save_with_first_char_uppercase') }];

        return <ButtonGroup Buttons={Buttons} />;
    }

    renderNewPasswordError = () => {
        var { t } = this.props;

        if (this.state.ConfirmPassword && this.state.NewPassword && !this.state.ValidPassword) {
            return <PageText FontColor="warning" FontFamily="semibold" FontSize="medium-2" JustifyContent="center" Text={t('passwords_do_not_match')} TextAlign="center" />;
        }

        if (this.state.WrongOTP) {
            return <PageText FontColor="warning" FontFamily="semibold" FontSize="medium-2" JustifyContent="center" Text={t('wrong_opt')} TextAlign="center" />;
        }
    }

    renderNewPasswordForm = () => {
        var { t } = this.props;

        return (
            <>
                <PageText FontFamily="semibold" FontSize="large" JustifyContent="center" NoMargin Text={`${t('RegisterLogin_a_code_has_been_sent_to')} ${this.state.Email}`} TextAlign="center" />
                
                <Spacer Size="extra-small" />
                
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('RegisterLogin_please_enter_the_6_digits_code')} TextAlign="center" />

                <Spacer Size="medium-1" />

                <form onSubmit={this.handleSubmit}>
                    {this.renderCode()}

                    <Spacer Size="medium" />

                    {this.renderPassword('NewPassword')}
                    {this.renderPassword('ConfirmPassword')}

                    <Spacer Size="large" />

                    {this.renderNewPasswordError()}
                    {this.renderNewPasswordButton()}
                </form>
            </>
        );
    }

    renderPassword = PasswordType => {
        var { t } = this.props;

        var InputName = PasswordType || 'Password';
        var Value = this.state[InputName];
        var Placeholder = PasswordType ? PasswordType === 'NewPassword' ? t('Profile_new_password') : t('confirm_password_with_first_char_uppercase') : t('password_with_first_char_uppercase');

        return (
            <StyledInput
                AutoComplete="current-password"
                FontSize="medium-2"
                NoLabel
                NoMargin={PasswordType === 'NewPassword' ? false : true}
                OnChange={event => this.onChangeInput(event, InputName)}
                OnKeyPress={this.onKeyPress}
                Placeholder={Placeholder}
                Size="extra-large"
                Type="password"
                Value={Value}
            />
        );
    }

    render() {
        if (this.state.Loading) return <Loading />

        var { DisplayScreen } = this.state;
        var { TryingForgotPasswordUpdatePassword, TryingLoginUser } = this.props;

        return (
            <>
                <Container className="Container" DisplayScreen={DisplayScreen}>
                    {(TryingForgotPasswordUpdatePassword || TryingLoginUser) && <Loading />}

                    {this.renderLanguage()}

                    {this.renderBackArrow()}

                    <InnerContainer className="InnerContainer">
                        {
                            DisplayScreen === 'Login' ?
                            this.renderLoginForm()
                        :
                            DisplayScreen === 'ForgotPassword' ?
                            this.renderForgotPassword()
                        :
                            DisplayScreen === 'NewPassword' ?
                            this.renderNewPasswordForm()
                        :
                            DisplayScreen === 'CurusLogin' ?
                            this.renderCurusLogin()
                        :
                            null
                        }
                    </InnerContainer>
                </Container>

                {this.renderLogo()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        IsLoggedIn: state.Auth.IsLoggedIn,

        TryingForgotPasswordSendCode: state.Auth.TryingForgotPasswordSendCode,
        TryingForgotPasswordSendCodeError: state.Auth.TryingForgotPasswordSendCodeError,
        TryingForgotPasswordUpdatePassword: state.Auth.TryingForgotPasswordUpdatePassword,
        TryingForgotPasswordUpdatePasswordError: state.Auth.TryingForgotPasswordUpdatePasswordError,
        TryingLoginUser: state.Auth.TryingLoginUser,
        TryingLoginUserError: state.Auth.TryingLoginUserError,
        TryingValidateUser: state.Auth.TryingValidateUser,
        TryingValidateUserError: state.Auth.TryingValidateUserError
    };
};
  
export default withTranslation()(connect(mapStateToProps, { ForgotPasswordSendCode, ForgotPasswordUpdatePassword, LoginUser, AccountIntegration_Login, ValidateUser } )(Login));