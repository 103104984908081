import React from 'react';

// import * as Styles from './styles';

import Exercises from '../Exercises';
import TrainingPrograms from '../TrainingPrograms';
import WorkoutTemplates from '../WorkoutTemplates';

class Trash extends React.Component {
    _isMounted = false;

    state = {}

    renderBody = () => {
        var { LibraryType, ShowTrash } = this.props;

        if (LibraryType === 'Exercises') return <Exercises ShowTrash={ShowTrash} />
        else if (LibraryType === 'TrainingPrograms') return <TrainingPrograms ShowTrash={ShowTrash} />
        else if (LibraryType === 'WorkoutTemplates') return <WorkoutTemplates ShowTrash={ShowTrash} />

        return null;
    }

    render() {
        return (
            <>
                {this.renderBody()}
            </>
        );
    }
}

export default Trash;