import styled from 'styled-components';

export const CalendarIcon = styled.img`
    height: 30px;
    margin: 0 auto;
    width: 30px;

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    width: 400px;
}
`;

export const DateContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

export const Image = styled.img`
    width: 19px;

    :hover {
        cursor: pointer;
    }
`;