import styled from 'styled-components';
import { device } from '../../Config/device';

import BackgroundImage from '../../Components/BackgroundImage';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Input from '../../Components/Form/Input';
import PageText from '../../Components/Text/PageText';

export const ColumnMargin = styled.div`
    min-width: 20px;
    max-width: 45px;
    width: 4%;
`;

export const DeleteIcon = styled.div`
    align-items: center;
    background-color: var(--red-bittersweet);
    border: 2px solid var(--red-bittersweet);
    border-radius: 50%;
    color: var(--white);
    display: flex;
    height: 30px;
    justify-content: center;
    margin-left: 10px;
    width: 30px;

    @media ${device.minwidth.ipad} {
        background-color: var(--white);
        border-color: var(--white-concrete);
        color: var(--white-concrete);

        :hover {
            background-color: var(--red-bittersweet);
            border-color: var(--red-bittersweet);
            color: var(--white);
            cursor: pointer;
        }
    }
`;

export const DishImageContainer = styled.div``;

// ^^ DishImageContainer AFTER Nutrition Facts Added
// @media ${device.minwidth.laptop} {
//     flex: 1 1 0;
//     max-width: 50%;
//     overflow: hidden;
// }

export const IngredientContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 15px;

    @media ${device.minwidth.mobile} {
        flex-direction: row;
    }
`;

export const IngredientListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const IngredientNameStyled = styled(PageText)`
    flex: 1;
`;

export const IngredientOptionsContainer = styled.div`
    display: flex;

    @media ${device.maxwidth.mobile_small} {
        margin-top: 10px;
        width: 100%;
    }
`;

export const IngredientQuantityStyledInput = styled(Input)`
    @media ${device.minwidth.mobile} {
        width: 60px;
    }

    @media ${device.maxwidth.mobile_small} {
        flex: 1;
    }

    > input {
        border: 2px solid var(--white-concrete);
        text-align: center;
        text-indent: 0px;
    }
`;

export const IngredientsContainer = styled.div``;

export const IngredientUnit = styled(PageText)`
    border: 2px solid var(--white-concrete);
    border-radius: 21px;
    margin-left: 10px;
    padding: 2.5px 7px;

    @media ${device.maxwidth.mobile_small} {
        flex: 1;
    }

    :hover {
        background-color: var(--white-concrete);
        cursor: pointer;
    }
`;

export const InstructionContainer = styled.div`
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    line-height: 21px;    
    margin-bottom: 15px;
    padding: 15px;
`;

export const InstructionsContainer = styled.div``;

export const ResponsiveColumns = styled.div`
    display: flex;
    flex-direction: column;
    
    > div {
        flex: 1
    }

    @media ${device.minwidth.laptop} {
        flex-direction: row;
    }
`;

export const Section1 = styled.div`
    display: flex;
    flex-direction: column;
`;

// ^^ Section1 AFTER Nutrition Facts Added
// @media ${device.minwidth.laptop} {
//     flex-direction: row;
// }

export const StyledBackgroundImage = styled(BackgroundImage)`
    height: 350px;
    width: 100%;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
    text-align: center;
`;