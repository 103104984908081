import styled from 'styled-components';

export const NutrientContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    > div:first-child {
        margin-right: 7px;
    }
`;

export const NutrientsContainer = styled.div``;

export const NutritionCategoryContainer = styled.div``;