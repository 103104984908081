import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { ItemsContainer, LoadingContainer, LoadMoreContainer, ListContainer, ListItemContainer, StyledLoading, ViewCount } from './styles';

import Button from '../../Buttons/Button';
import Loading from '../../Loading';
import PageText from '../../Text/PageText';
import Spacer from '../../Spacer';

class PaginatedList extends React.PureComponent {
    renderItems = Items => {
        if (!Items) return null;

        var { ItemId, ItemProps, ItemRenderer, OnSelectItem, SelectedItemIds } = this.props;

        if (ItemRenderer) return Items.map(Item => <ItemRenderer key={Item[ItemId]} {...ItemProps} Item={Item} OnSelectItem={Item => OnSelectItem(Item)} SelectedItemIds={SelectedItemIds} />);
        else return Items.map(Item => this.renderListItem(Item));
    }

    renderLoadingItem = () => {
        return (
            <>
                <Spacer Size="medium" />

                <LoadingContainer>
                    <StyledLoading />
                </LoadingContainer>
            </>
        );
    }

    renderLoadMoreItems = () => {
        var { t } = this.props;
        var { IsLoading, LoadMore } = this.props;

        return (
            <>
                <Spacer Size="medium" />
                
                <LoadMoreContainer>
                    <Button
                        BackgroundColor="white"
                        BackgroundColor_Hover="blue-abel"
                        Border="2px solid var(--white-concrete)"
                        Border_Hover="2px solid var(--blue-abel)"
                        Disabled={IsLoading}
                        FontColor="black"
                        FontColor_Hover="white"
                        FontFamily="semibold"
                        FontSize="medium-1"
                        OnClick={LoadMore}
                        Size="medium"
                        Title={IsLoading ? `${t('loading')}...` : t('load_more')}
                    />
                </LoadMoreContainer>
            </>
        );
    }

    renderListItem = Item => {
        var { ItemId, ItemName, OnSelectItem, SelectedItemIds } = this.props;

        var ItemId_Actual = Item[ItemId];
        var ItemName_Actual = Item[ItemName];

        var Selected = SelectedItemIds ? SelectedItemIds.indexOf(Number(ItemId_Actual)) > -1 : false;

        return (
            <ListItemContainer id={`itemid-${ItemId_Actual}`} key={ItemId_Actual} NewLine={Item.NewLine} onClick={() => OnSelectItem(Item)} Selected={Selected}>
                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={ItemName_Actual} />
            </ListItemContainer>
        );
    }

    renderViewCount = () => {
        var { t } = this.props;
        var { ItemDescriptionType, PageNo, PageSize, TotalRecords } = this.props;

        return (
            <>
                <ViewCount>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`1 - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords}`} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={ItemDescriptionType} />
                </ViewCount>

                <Spacer Size="small" />
            </>
        )
    }

    render() {
        var { t } = this.props;
        var { className, CompletedFirstSearch, FilterRenderer, HideViewCount, HasMoreItems, HideNoResults, IsLoading, Items, NewSearch, NoItemsText } = this.props;

        if (IsLoading && (!CompletedFirstSearch || NewSearch)) return <Loading />;
        else if (!IsLoading && !Items.length && CompletedFirstSearch && !HideNoResults) return <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={NoItemsText || t('search_noresults')} />
        else if (!Items.length) return null;

        return (
            <>
                {
                    FilterRenderer &&
                    <>
                        <FilterRenderer />
                        <Spacer Size="small" />
                    </>
                }

                {!HideViewCount && this.renderViewCount()}

                <ListContainer className={className}>
                    <ItemsContainer>
                        {this.renderItems(Items)}
                    </ItemsContainer>
                </ListContainer>

                {IsLoading && this.renderLoadingItem()}
                {HasMoreItems && this.renderLoadMoreItems()}
            </>
        )
    }
}

PaginatedList.propTypes = {
    CompletedFirstSearch: PropTypes.bool.isRequired,
    FilterRenderer: PropTypes.func,
    HideNoResults: PropTypes.bool,
    HideViewCount: PropTypes.bool,
    IsLoading: PropTypes.bool.isRequired,
    ItemDescriptionType: PropTypes.string,
    ItemId: PropTypes.string.isRequired,
    ItemName: PropTypes.string.isRequired,
    ItemProps: PropTypes.object,
    ItemRenderer: PropTypes.func,
    Items: PropTypes.array.isRequired,
    HasMoreItems: PropTypes.bool.isRequired,
    LoadMore: PropTypes.func.isRequired,
    NewSearch: PropTypes.bool,
    NoItemsText: PropTypes.string,
    OnSelectItem: PropTypes.func,
    PageNo: PropTypes.number,
    PageSize: PropTypes.number,
    SelectedItemIds: PropTypes.array,
    TotalRecords: PropTypes.number
}

export default withTranslation()(PaginatedList);