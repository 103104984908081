import styled from "styled-components";

import Image from "../Image";
import Loading from "../Loading";
import PageText from "../Text/PageText";

export const CheckmarkIcon = styled(Image)`
  height: 14px;
  width: 14px;
`;

export const CheckmarkItemName = styled(PageText)`
  ${(props) => props.Disabled && "color: var(--gray-silver);"}
  margin-left: 10px;
`;

export const Container = styled.div`
  background-color: var(--white);
  border: 2px solid var(--white-concrete);
  border-radius: 10px;
  height: ${(props) =>
    props.IsOpen ? "auto" : props.DefaultHeight ? props.DefaultHeight : "45px"};
  padding: 10px 15px;
  z-index: 1000;
`;

export const DropdownContent = styled.div`
  margin-top: ${(props) => (props.IsDropdownOpen ? "15px" : "0px")};
  max-height: 300px;
  overflow-y: auto;
`;

export const DropdownToggleIcon = styled(Image)`
  height: 10px;
`;

export const IconTypesContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  > div:first-child {
    margin-right: 10px;
  }
`;

export const HeaderContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`;

export const HeaderTitle = styled(PageText)`
  word-break: break-word;
`;

export const HeaderTitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  margin-right: 15px;
`;

export const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  min-height: 24px;
  max-width: 100%;
`;

export const ItemIcon = styled(Image)`
  height: 18px;
  margin-left: 10px;
`;

export const MarginRight = styled.div`
  width: 10px;
`;

export const SelectedCountDot = styled.div`
  background-color: ${(props) =>
    props.Type === "AvoidItem" ? "var(--red-bittersweet)" : "var(--blue-abel)"};
  border-radius: 6px;
  display: block;
  height: 6px;
  margin: 0 3px 0px 10px;
  width: 6px;
`;

export const SelectedCountDotContainer = styled.div``;

export const SelectedCountText = styled(PageText)``;

export const StyledLoading = styled(Loading)`
  background-color: inherit;
  position: unset;
`;

export const TitleIconImage = styled.img`
  height: 20px;
  margin-right: 10px;

  @media (hover: hover) {
    :hover {
      cursor: pointer;
    }
  }
`;
