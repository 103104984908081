import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddSubscriber, DeleteOrganizationMember, LogoutUser, SubscribePaymentGateway, UpdateOrganizationMemberInvitation, ViewOrganizationMembers } from '../../Services/Actions';

import * as styles from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Info from '../../Modals/Info';

import AbelIcon from '../../Assets/Icons/Abel_HeaderIcon.png';
import BusinessIcon from '../../Assets/Icons/Subscription_Business.png';
import OrganizationIcon from '../../Assets/Icons/Subscription_Organization.png';

import { SUBSCRIPTION_CANCEL_URL, SUBSCRIPTION_RETURN_URL } from '../../Config';

const SubscriptionsContent = {
    '1': {
        Color: 'yellow-gold',
        Image: BusinessIcon,
        MeantFor: [
            { Id: 1, Text: 'personal_trainers' },
            { Id: 2, Text: 'nutritionists' },
            { Id: 3, Text: 'physical_therapists' }
        ],
        Name: 'Business',
        Price: {
            '1': { Amount: 150, Currency: 'USD' },
            '3': { Amount: 999, Currency: 'NOK' },
        },
        WhatYouGet: [
            { Id: 1, Text: 'unlimited_clients' },
            { Id: 2, Text: 'can_take_payments' }
        ]
    },
    '3': {
        Color: 'red',
        Image: OrganizationIcon,
        MeantFor: [
            { Id: 1, Text: 'meantfor_members_of_organizations' }
        ],
        Name: 'OrgMember',
        Price: {
            '1': { Amount: 0, Currency: 'USD' },
            '3': { Amount: 0, Currency: 'NOK' },
        },
        WhatYouGet: []
    }
}

const ThreeBoxesContent = [
    { Id: 1, Text: 'activate_business_subscription_info', Title: 'activate_business_subscription' },
    { Id: 2, Text: 'activate_organization_subscription_info', Title: 'activate_organization_subscription' },
    // { Id: 3, Text: 'activate_subscription_freetrial_contact_info', Title: 'activate_subscription_freetrial_contact' }
]

class FrozenComponent extends React.Component {
    state = {
        AcceptMembership: null,
        DeleteMembership: null,
        Loading: false,
        ShowAcceptMembershipModal: false,
        ShowDeleteMembershipModal: false
    };

    onAcceptMembership = () => {
        this.props.UpdateOrganizationMemberInvitation({ Acceptance: 1, OrganizationId: this.state.AcceptMembership.OrganizationId, OrganizationUserId: this.state.AcceptMembership.OrganizationUserId }).then(() => this.setState({ AcceptMembership: null, ShowAcceptMembershipModal: false }));
    }

    onAddSubscription = () => {
        var PromotionTypeId = 0;

        var CancelUrl = SUBSCRIPTION_CANCEL_URL;
        var ReturnUrl = `${SUBSCRIPTION_RETURN_URL}/AddSubscriber`;

        this.setState({ Loading: true }, () => {
            this.props.AddSubscriber({ CancelUrl, PromotionTypeId, ReturnUrl }).then(({ hostedPaymentPageUrl }) => {
                if (!!hostedPaymentPageUrl) window.location.href = hostedPaymentPageUrl;
                else window.location.href = ReturnUrl;
            });
        });
    }

    onDeleteMembership = ({ OrganizationId }) => {
        if (!OrganizationId) OrganizationId = this.state.DeleteMembership.OrganizationId;

        this.props.UpdateOrganizationMemberInvitation({ Acceptance: 0, OrganizationId }).then(() => this.setState({ DeleteMembership: null, ShowDeleteMembershipModal: false }));
    }

    onToggleShowAcceptMembershipModal = ({ AcceptMembership = null, ShowAcceptMembershipModal }) => {
        this.setState({ AcceptMembership, ShowAcceptMembershipModal });
    }

    onToggleShowDeleteMembershipModal = ({ DeleteMembership = null, ShowDeleteMembershipModal }) => {
        this.setState({ DeleteMembership, ShowDeleteMembershipModal });
    }

    renderAcceptMembership = () => {
        var { t } = this.props;
        var { AcceptMembership } = this.state;

        return (
            <styles.AcceptMembershipContainer className="AcceptMembershipContainer">
                <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text={t('organization_membership_terms_title')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium" JustifyContent="center" NoMargin Text={t('organization_membership_terms', { OrgName: AcceptMembership.OrgName })} TextAlign="center" />
            </styles.AcceptMembershipContainer>
        );
    }

    renderAcceptMembershipBottomButton = () => {
        var { t } = this.props;

        var Buttons = [];
        Buttons.push({ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onDeleteMembership({ OrganizationId: this.state.AcceptMembership.OrganizationId }), Title: t('do_not_agree_to_join_organization') });
        Buttons.push({ BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: this.onAcceptMembership, Title: t('agree') });

        return <styles.StyledAcceptMembershipBottomButtons Buttons={Buttons} />;
    }

    renderAcceptMembershipModal = () => {
        var { ShowAcceptMembershipModal } = this.state;

        if (this.state.ShowAcceptMembershipModal) {
            return (
                <Info
                    BottomButtonRender={this.renderAcceptMembershipBottomButton}
                    InformationRenderer={this.renderAcceptMembership}
                    Loading={this.props.TryingUpdateOrganizationMembershipStatus}
                    OnHide={() => this.onToggleShowAcceptMembershipModal({ ShowAcceptMembershipModal: false })}
                    Show={ShowAcceptMembershipModal}
                    Size="medium"
                />
            );
        }
    }

    renderDeleteMembershipModal = () => {
        var { ShowDeleteMembershipModal } = this.state;

        if (this.state.ShowDeleteMembershipModal) {
            var { t } = this.props;
            var { OrgName } = this.state.DeleteMembership;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteMembership}
                    BottomButtonText={t('delete_organization_membership_invitation')}
                    Information={t('delete_organization_membership_invitation_warning', { OrgName })}
                    Loading={this.props.TryingUpdateOrganizationMembershipStatus}
                    OnHide={() => this.onToggleShowDeleteMembershipModal({ ShowDeleteMembershipModal: false })}
                    Show={ShowDeleteMembershipModal}
                    Size="medium"
                />
            );
        }
    }

    renderMembershipRequests = () => {
        var { t } = this.props;
        var { MembershipRequests } = this.props.UserDetails.SubscriptionStatus;

        return (
            <styles.MembershipRequestsContainer className="MembershipRequestsContainer">
                {
                    MembershipRequests.map(({ MembershipStatus, Name, OrganizationId, OrganizationUserId }) => {
                        return (
                            <styles.MembershipRequestContainer className="MembershipRequestContainer" key={OrganizationId} MembershipStatus={MembershipStatus}>
                                {
                                    MembershipStatus === 'Accepted' ?
                                    <>
                                        <PageText FontFamily="bold" FontSize="medium-3" NoMargin JustifyContent="flex-start" Text={Name} TextAlign="left" />

                                        <PageText FontColor="red-bittersweet" FontColorHover="red-bittersweet-hover" FontFamily="semibold" FontSize="medium-1" NoMargin JustifyContent="flex-start" OnClick={() => this.onToggleShowLeaveOrganizationModal({ LeaveOrganization: { OrganizationId, OrgName: Name, OrganizationUserId }, ShowLeaveOrganizationModal: true })} Text={t('leave_organization_membership')} TextAlign="left" />
                                    </>
                                :
                                    <>
                                        <PageText FontFamily="medium" FontSize="medium-1" NoMargin JustifyContent="flex-start" Text={`${t('membership_request_from')}:`} TextAlign="left" />

                                        <PageText FontFamily="bold" FontSize="medium-3" NoMargin JustifyContent="flex-start" Text={Name} TextAlign="left" />
                                        
                                        <Spacer Size="extra-extra-small" />

                                        <ButtonGroup
                                            Buttons={[
                                                { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Color: 'white', ColorHover: 'white', OnClick: () => this.onToggleShowAcceptMembershipModal({ AcceptMembership: { OrganizationId, OrgName: Name, OrganizationUserId }, ShowAcceptMembershipModal: true }), Size: 'small', Title: t('accept') },
                                                { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Color: 'white', ColorHover: 'white', OnClick: () => this.onToggleShowDeleteMembershipModal({ DeleteMembership: { OrganizationId, OrgName: Name }, ShowDeleteMembershipModal: true }), Size: 'small', Title: t('delete_with_first_char_uppercase') }
                                            ]}
                                            ButtonWidth="fit-content"
                                            NotTouching
                                        />
                                    </>
                                }
                            </styles.MembershipRequestContainer>
                        );
                    })
                }
            </styles.MembershipRequestsContainer>
        );
    }

    renderSubscriptionDetails = () => {
        return (
            <styles.SubscriptionDetailsContainer className="SubscriptionDetailsContainer">
                {this.renderMembershipRequests()}
            </styles.SubscriptionDetailsContainer>
        );
    }

    renderSubscriptionType = ({ Type }) => {
        var { t } = this.props;
        var { subAmountText, subscriptionType, subscriberTypeId } = this.props.UserDetails.SubscriptionStatus;
        var CurrencyId = +this.props.UserDetails.CurrencyId || '3';
        var { Color, Image, MeantFor, Name, Price, WhatYouGet } = SubscriptionsContent[Type];

        var PriceMultiTexts = [];
        if (subscriberTypeId === Type && subscriberTypeId === 1) {
            PriceMultiTexts = [
                { FontColor: 'white', FontFamily: "bold", FontSize: "medium-3", Text: subAmountText },
                { FontColor: 'white', FontFamily: "medium-italic", FontSize: "small", Text: `/${t('timeperiod_month_singular')}` }
            ]
        } else {
            if (+Type === 3) {
                PriceMultiTexts = [
                    { FontColor: 'white', FontFamily: "medium", FontSize: "medium-3", Text: t('_scalable_pricing') }
                ];
            } else {
                PriceMultiTexts = [
                    { FontColor: 'white', FontFamily: "bold", FontSize: "large", Text: Price[CurrencyId].Amount },
                    { FontColor: 'white', FontFamily: "bold", FontSize: "small", Text: Price[CurrencyId].Currency },
                    { FontColor: 'white', FontFamily: "medium-italic", FontSize: "small", Text: `/${t('timeperiod_month_singular')}` }
                ];
            }
        }

        return (
            <styles.SubscriptionTypeContainer className="SubscriptionTypeContainer" Color={Color} Selected={subscriptionType === Type}>
                <styles.CircularPhotoContainer className="CircularPhotoContainer">
                    <styles.StyledImage alt={Name} src={Image} />
                </styles.CircularPhotoContainer>

                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="center" NoMargin Text={t(`subscription_type_${Name.toLowerCase()}`)} TextAlign="center" />

                <Spacer Size="small" />

                <styles.SubscriptionPriceContainer className="SubscriptionPriceContainer" Color={Color}>
                    <MultiText Texts={PriceMultiTexts} />
                </styles.SubscriptionPriceContainer>

                <Spacer Size="small" />

                <styles.SubscriptionDescriptionContainer className="SubscriptionDescriptionContainer">
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('meant_for')}:`} TextAlign="left" />

                    <styles.SubscriptionListContainer className="SubscriptionListContainer">
                        {
                            MeantFor.map(({ Id, Text }) => {
                                return <li key={Id}><PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t(Text)} TextAlign="left" /></li>;
                            })
                        }
                    </styles.SubscriptionListContainer>
                </styles.SubscriptionDescriptionContainer>

                {
                    !!WhatYouGet.length &&
                    <>
                        <Spacer Size="small" />

                        <styles.SubscriptionDescriptionContainer className="SubscriptionDescriptionContainer">
                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('what_you_get')}:`} TextAlign="left" />

                            <styles.SubscriptionListContainer className="SubscriptionListContainer">
                                {
                                    WhatYouGet.map(({ Id, Text }) => {
                                        return <li key={Id}><PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t(Text)} TextAlign="left" /></li>;
                                    })
                                }
                            </styles.SubscriptionListContainer>
                        </styles.SubscriptionDescriptionContainer>
                    </>
                }

                {
                    Type === 1 &&
                    <styles.ChooseSubscriptionButtonContainer className="ChooseSubscriptionButtonContainer" onClick={this.onAddSubscription}>
                        <PageText FontColor="white" FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('select_with_first_char_uppercase')} TextAlign="center" />
                    </styles.ChooseSubscriptionButtonContainer>
                }
            </styles.SubscriptionTypeContainer>
        );
    }

    renderThreeBoxes = () => {
        var { t } = this.props;

        return (
            <styles.ThreeBoxesContainer className="ThreeBoxesContainer">
                {
                    ThreeBoxesContent.map(({ Id, Text, Title }) => {
                        return (
                            <styles.ThreeBoxContainer className="ThreeBoxContainer" key={Id}>
                                <PageText FontFamily="bold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t(Title)} TextAlign="left" />

                                <Spacer Size="extra-small" />

                                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t(Text)} TextAlign="left" />
                            </styles.ThreeBoxContainer>
                        );
                    })
                }
            </styles.ThreeBoxesContainer>
        );
    }

    renderTopRow = () => {
        var { t } = this.props;

        return (
            <styles.TopRowContainer className="TopRowContainer">
                <styles.LogoContainer className="LogoContainer">
                    <styles.AbelLogoImageContainer className="clickable">
                        <styles.AbelLogo src={AbelIcon} alt="ABEL - The Digital Personal Trainer" />
                    </styles.AbelLogoImageContainer>
                    <styles.AbelNameContainer>
                        <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text="ABEL" />

                        <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text="Admin" />
                    </styles.AbelNameContainer>
                </styles.LogoContainer>

                <styles.LogOutContainer className="LogOutContainer">
                    <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin OnClick={this.props.LogoutUser} Text={t('Profile_Log_Out')} TextAlign="center" />
                </styles.LogOutContainer>
            </styles.TopRowContainer>
        );
    }

    render() {
        var { t } = this.props;
        var { Email, SubscriptionStatus: { MembershipRequests } } = this.props.UserDetails;

        return (
            <>
                {(this.state.Loading) && <Loading />}

                <styles.FrozenContainer className="FrozenContainer">
                    {this.renderTopRow()}

                    <Spacer Size="medium" />

                    <PageText FontFamily="medium" FontSize="large" NoMargin JustifyContent="center" Text={t('hi_username', { UserName: Email })} TextAlign="center" />

                    <Spacer Size="extra-small" />

                    <PageText FontFamily="semibold" FontSize="extra-extra-large" NoMargin JustifyContent="center" Text={t('welcome_to_abel_admin')} TextAlign="center" />

                    <Spacer Size="extra-small" />

                    <PageText FontFamily="semibold" FontSize="medium-3" NoMargin JustifyContent="center" Text={t('welcome_to_abel_need_subscription')} TextAlign="center" />

                    <Spacer Size="small" />

                    {this.renderThreeBoxes()}

                    <Spacer Size="large" />

                    <styles.SubscriptionsContainer className="SubscriptionsContainer" HasMembershipRequests={!!MembershipRequests.length}>
                        {this.renderSubscriptionType({ Type: 1 })}
                        {this.renderSubscriptionType({ Type: 3 })}
                        {!!MembershipRequests.length && this.renderSubscriptionDetails()}
                    </styles.SubscriptionsContainer>

                    <Spacer Size="large" />
                </styles.FrozenContainer>

                {this.renderAcceptMembershipModal()}
                {this.renderDeleteMembershipModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        TryingUpdateOrganizationMembershipStatus: state.Auth.TryingUpdateOrganizationMembershipStatus,
        TryingUpdateOrganizationMembershipStatusError: state.Auth.TryingUpdateOrganizationMembershipStatusError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddSubscriber, DeleteOrganizationMember, LogoutUser, SubscribePaymentGateway, UpdateOrganizationMemberInvitation, ViewOrganizationMembers })(FrozenComponent));