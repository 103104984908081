import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { LoadingContainer, ListItemContainer, StyledInfiniteScroll, StyledLoading, ViewCount } from './styles';

import Loading from '../../Loading';
import PageText from '../../Text/PageText';
import Spacer from '../../Spacer';

class ScrollingList extends React.Component {
    componentDidUpdate() {
        var { ActiveSearch, ItemId, Items, NewSearch } = this.props;

        if (!ActiveSearch && NewSearch && Items.length) {
            document.getElementById(`itemid-${Items[0][ItemId]}`).scrollIntoView();
        }
    }

    renderItems = Items => {
        if (!Items) return null;

        var { ItemId, ItemRenderer, OnSelectItem, SelectedItemIds } = this.props;

        if (ItemRenderer) return Items.map(Item => <ItemRenderer key={Item[ItemId]} Item={Item} OnSelectItem={Item => OnSelectItem(Item)} SelectedItemIds={SelectedItemIds} />);
        else return Items.map(Item => this.renderListItem(Item));
    }

    renderLoadingItem = () => {
        return (
            <LoadingContainer>
                <StyledLoading />
            </LoadingContainer>
        );
    }

    renderListItem = Item => {
        var { ItemId, ItemName, OnSelectItem, SelectedItemIds } = this.props;

        var ItemId_Actual = Item[ItemId];
        var ItemName_Actual = Item[ItemName];

        var Selected = SelectedItemIds ? SelectedItemIds.indexOf(Number(ItemId_Actual)) > -1 : false;

        return (
            <ListItemContainer id={`itemid-${ItemId_Actual}`} key={ItemId_Actual} NewLine={Item.NewLine} onClick={() => OnSelectItem(Item)} Selected={Selected}>
                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={ItemName_Actual} />
            </ListItemContainer>
        );
    }

    renderViewCount = () => {
        var { t } = this.props;
        var { ItemDescriptionType, PageNo, PageSize, TotalRecords } = this.props;

        return (
            <>
                <ViewCount>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`1 - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords}`} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={ItemDescriptionType || 'items'} />
                </ViewCount>

                <Spacer Size="small" />
            </>
        )
    }

    render() {
        var { t } = this.props;
        var { className, CompletedFirstSearch, HideViewCount, IsFlexed, IsLoading, ItemHeight, Items, HasMoreItems, LoadMore, NewSearch, NoItemsText } = this.props;

        if (IsLoading && (!CompletedFirstSearch || NewSearch)) return <Loading />;
        else if (!IsLoading && !Items.length && CompletedFirstSearch) return <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={NoItemsText || t('search_noresults')} />
        else if (!Items.length) return null;

        var BorderHeight = 4;
        var SingleHeight =  ItemHeight || 41;
        var TotalHeight = IsFlexed ? `${(Math.ceil(Items.length / IsFlexed) * (ItemHeight + 15)) + BorderHeight}px` : SingleHeight * Items.length >= 500 ? '500px' : `${(SingleHeight * Items.length) + BorderHeight}px`;

        return (
            <>
                {!HideViewCount ? this.renderViewCount() : null}

                <StyledInfiniteScroll
                    className={className}
                    dataLength={Items.length}
                    height={TotalHeight}
                    next={LoadMore}
                    hasMore={HasMoreItems}
                    loader={this.renderLoadingItem()}
                >
                    {this.renderItems(Items)}
                </StyledInfiniteScroll>
            </>
        )
    }
}

ScrollingList.propTypes = {
    ActiveSearch: PropTypes.bool.isRequired,
    CompletedFirstSearch: PropTypes.bool.isRequired,
    HideViewCount: PropTypes.bool,
    IsFlexed: PropTypes.number,
    IsLoading: PropTypes.bool.isRequired,
    ItemDescriptionType: PropTypes.string,
    ItemHeight: PropTypes.number,
    ItemId: PropTypes.string.isRequired,
    ItemName: PropTypes.string.isRequired,
    ItemRenderer: PropTypes.func,
    Items: PropTypes.array.isRequired,
    HasMoreItems: PropTypes.bool.isRequired,
    LoadMore: PropTypes.func.isRequired,
    NewSearch: PropTypes.bool,
    NoItemsText: PropTypes.string,
    OnSelectItem: PropTypes.func.isRequired,
    PageNo: PropTypes.number,
    PageSize: PropTypes.number,
    SelectedItemIds: PropTypes.array,
    TotalRecords: PropTypes.number
}

export default withTranslation()(ScrollingList);