import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { SaveDataInProps } from '../../Services/Actions';
import history from '../../history';

import { MenuItem, MenuItemIllustration, MenuOptionsContainer } from './styles';

import AbelModal from '../../Components/Modal';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import AddDish from '../../Assets/Illustrations/Meals_Small.png';
import AddLogFood from '../../Assets/Illustrations/AppleWithBiteTaken.png';
import AddMealTemplate from '../../Assets/Illustrations/WeeklyMeals_Small.png';

class AddMealMenu extends React.Component {
    onClickMenuItem = ({ event, href, search }) => {
        event.preventDefault();
        var { OnHide, SaveData, SaveDataInProps } = this.props;

        SaveDataInProps(SaveData).then(() => history.push({ pathname: href, ...search ? { search } : {}, state: { from: 'Client' } }));
        OnHide();
    }

    render() {
        var { t } = this.props;
        var { className, OnHide, Show } = this.props;

        var MenuItems = [
            { ImageSrc: AddDish, Link: '/add-dish', Name: t('ptadmin_menu_addsingledish') },
            { ImageSrc: AddLogFood, Link: '/add-log-food', Name: t('ptadmin_menu_add_logged_food') },
            { ImageSrc: AddMealTemplate, Link: '/add-meal-template', Name: t('ptadmin_menu_addmealtemplate') }
        ];

        return (
            <AbelModal
                className={className}
                GA_PathName="AddMealMenu"
                Show={Show}
                Size="medium"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                <MenuOptionsContainer>
                    {
                        MenuItems.map(({ ImageSrc, Link, Name }) => {
                            return (
                                <MenuItem key={Link} onClick={event => this.onClickMenuItem({ event, href: Link })} href={Link}>
                                    <MenuItemIllustration alt={Name} src={ImageSrc} />
                                    
                                    <Spacer Size="small" />
                                    
                                    <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={Name} TextAlign="center" />
                                </MenuItem>
                            );
                        })
                    }
                </MenuOptionsContainer>
            </AbelModal>
        );
    }
}

AddMealMenu.propTypes = {
    OnHide: PropTypes.func.isRequired,
    SaveData: PropTypes.object,
    Show: PropTypes.bool.isRequired
}

export default withTranslation()(connect(null, { SaveDataInProps } )(AddMealMenu));