import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Container, CircuitContainer, CircuitDetailContainer, CircuitHeaderContainer, CircuitsContainer, DragHandleIcon, DynamicDragHandle, ExerciseDetailContainer, ExercisesContainer, ExerciseContainer, ExplanationContainer, IconTextContainer, IconTextContainerIcon, StyledExerciseImage, StyledExerciseName } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import GroupDarkGray from '../../Assets/Icons/Group_DarkGray.png';
import GroupGray from '../../Assets/Icons/Group_Gray.png';
import GroupWhite from '../../Assets/Icons/Group_White.png';
import Reorder from '../../Assets/Icons/Reorder.png';
import ReorderDarkGray from '../../Assets/Icons/Reorder_DarkGray.png';
import XWhite from '../../Assets/Icons/X_White.png';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [ removed ] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};
  
const getItemStyle = (isDragging, draggableStyle) => ({
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "",
  
    // styles we need to apply on draggables
    ...draggableStyle
});
  
const getListStyle = isDraggingOver => ({ background: isDraggingOver ? "lightblue" : "" });

class WorkoutCircuits extends Component {
    topRef = React.createRef();

    state = { Circuits: [], NextId: 0 };

    componentDidMount() {
        var LoopingCircuits = [ ...this.props.Circuits ];

        var CircuitGroups = [];
        for (var i = 0; i < LoopingCircuits.length; i++) {
            var Circuit = LoopingCircuits[i];

            if (i === 0) {
                var NumExercises = Circuit.NumExercises || 1;
                var Type = Circuit.Type;

                var WorkoutExercises = Circuit.WorkoutExercises || [{ ...Circuit }];
                WorkoutExercises = WorkoutExercises.map(WE => ({ ...WE, ExerciseId2: `Exercise-${WE.ExerciseId}` }));

                CircuitGroups.push({ id: i, NumExercises, Type, WorkoutExercises });
            }
            else {
                // if (Circuit.Type === 'Circuit' && LoopingCircuits[i - 1].Type === 'Circuit') {

                //     CircuitGroups[CircuitGroups.length - 1].WorkoutExercises.push({ ...Circuit, ExerciseId: `Exercise-${Circuit.ExerciseId}` });
                //     CircuitGroups[CircuitGroups.length - 1].NumExercises = CircuitGroups[CircuitGroups.length - 1].NumExercises + 1;
                // } else {
                    // eslint-disable-next-line
                    var NumExercises = Circuit.NumExercises || 1;
                    // eslint-disable-next-line
                    var Type = Circuit.Type;

                    // eslint-disable-next-line
                    var WorkoutExercises = Circuit.WorkoutExercises || [{ ...Circuit }];
                    WorkoutExercises = WorkoutExercises.map(WE => ({ ...WE, ExerciseId2: `Exercise-${WE.ExerciseId}` }));

                    CircuitGroups.push({ id: i, NumExercises, Type, WorkoutExercises });
                // }
            }
        }

        window.scrollTo(0, this.topRef.current.offsetTop);
    
        this.setState({ Circuits: CircuitGroups, NextId: CircuitGroups.length });
    }

    onCancelCircuits = () => {
        this.props.OnCancelCircuits();
    }

    onDeleteCircuit = CircuitIndex => {
        var NewWorkoutExercises = [];

        var { NextId } = this.state;

        this.state.Circuits.map((Circuit, index) => {
            if (CircuitIndex === index) {
                Circuit.WorkoutExercises.map(WE => {
                    NewWorkoutExercises.push({ id: NextId, WorkoutExercises: [{ ...WE }], NumExercises: 1, Type: 'WorkoutExercise' });
                    NextId += 1;
                    return null;
                })
            } else NewWorkoutExercises.push({ ...Circuit });

            return null;
        });

        this.setState({ Circuits: NewWorkoutExercises, NextId });
    }

    onDragEnd = result => {
        // dropped outside the list
        // console.log(result);

        if (!result.destination) return;

        const sourceIndex = result.source.index;
        const destIndex = result.destination.index;

        // Moving Circuit
            if (result.type === "droppableItem") {
                const Circuits = reorder(this.state.Circuits, sourceIndex, destIndex);
            
                this.setState({ Circuits });
            }
        // Moving Exercises
            else if (result.type === "droppableSubItem") {
                const itemSubItemMap = this.state.Circuits.reduce((acc, item) => {
                    acc[item.id] = item.WorkoutExercises;
                    return acc;
                }, {});
        
                const sourceParentId = parseInt(result.source.droppableId);
                const destParentId = parseInt(result.destination.droppableId);
            
                const sourceSubItems = itemSubItemMap[sourceParentId];
                const destSubItems = itemSubItemMap[destParentId];
            
                let newCircuits = [ ...this.state.Circuits ];
        
            // In this case, the Exercise is re-ordered inside same circuit
                if (sourceParentId === destParentId) {
                    const reorderedSubItems = reorder(sourceSubItems, sourceIndex, destIndex);

                    newCircuits = newCircuits.map(item => {
                        if (item.id === sourceParentId) item.WorkoutExercises = reorderedSubItems;
                        return item;
                    });

                    this.setState({ Circuits: newCircuits });
                }
            // Exercise Changes Circuit, So Count the NumExercises For Source and Destination
            // If Source Has No More Exercises, Remove It
                else {
                    let newSourceSubItems = [ ...sourceSubItems ];
                    const [ draggedItem ] = newSourceSubItems.splice(sourceIndex, 1);
            
                    let newDestSubItems = [ ...destSubItems ];
                    newDestSubItems.splice(destIndex, 0, draggedItem);

                    newCircuits = newCircuits.map(item => {
                        if (item.id === sourceParentId) {
                            item.WorkoutExercises = newSourceSubItems;
                            item.NumExercises = newSourceSubItems.length;
                        }
                        else if (item.id === destParentId) {
                            item.WorkoutExercises = newDestSubItems;
                            item.NumExercises = newDestSubItems.length;
                        }

                        item.Type = (!item.NumExercises || item.NumExercises >= 2) ? 'Circuit' : 'WorkoutExercise';
                        
                        return item;
                    }).filter(item => ((!!+item.NumExercises && item.id === sourceParentId) || item.id !== sourceParentId));

                    this.setState({ Circuits: newCircuits });
                }
            }
    }

    onSaveCircuits = () => {
        var CircuitNumber = 1;
        var UpdatableCircuits = this.state.Circuits.map(Circuit => {
            if (Circuit.Type === 'Circuit') {
                Circuit.NumExercises = Circuit.WorkoutExercises.length;

                if (Circuit.WorkoutExercises.length === 1) Circuit = { ...Circuit, Type: 'WorkoutExercise' };
                else {
                    Circuit.CircuitNumber = CircuitNumber;
                    CircuitNumber++;
                }
            }

            delete Circuit.id;

            return Circuit;
        }).filter(Circuit => (Circuit.Type === 'WorkoutExercise' || !!Circuit.NumExercises));

        this.props.OnSaveCircuits(UpdatableCircuits);
    }

    renderDroppable = ({ WorkoutExercises, type }) => {
        var { AllWhite, FromAddWorkouts } = this.props;

        return (
            <Droppable className="Droppable2" droppableId={`${type}`} type="droppableSubItem">
                {(provided, snapshot) => (
                    <ExercisesContainer className="ExercisesContainer" ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                        {
                            WorkoutExercises.map((item, index) => (
                                <Draggable className="Droppable2 Draggable" key={item.ExerciseId2} draggableId={`${item.ExerciseId2}`} index={index}>
                                    {(provided, snapshot) => (
                                        <React.Fragment>
                                            <ExerciseContainer className="ExerciseContainer"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                <span {...provided.dragHandleProps}>
                                                    <DragHandleIcon ImageSrc={!!AllWhite ? GroupDarkGray : WorkoutExercises.length >= 2 ? GroupWhite : GroupGray} />
                                                </span>
    
                                                <ExerciseDetailContainer className="ExerciseDetailContainer" FromAddWorkouts={FromAddWorkouts}>
                                                    <StyledExerciseImage
                                                        Alt={item.ExerciseName}
                                                        ImageSrc={item.ExerciseImage}
                                                        Loading={false}
                                                    />
                                                    <StyledExerciseName FontFamily="bold" FontSize="medium-2" NoMargin Text={item.ExerciseName} TextAlign="center" />
                                                </ExerciseDetailContainer>
                                            </ExerciseContainer>
                                            {provided.placeholder}
                                        </React.Fragment>
                                    )}
                                </Draggable>
                            ))
                        }
                        {provided.placeholder}
                    </ExercisesContainer>
                )}
            </Droppable>
        );
    }

    renderExplanation = () => {
        var { t } = this.props;
        var { AllWhite } = this.props;

        return (
            <ExplanationContainer className="ExplanationContainer">
                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('explanation_for_use')} TextAlign="left" />

                <Spacer Size="extra-small" />

                <IconTextContainer className="IconTextContainer">
                    <IconTextContainerIcon ImageSrc={!!AllWhite ? ReorderDarkGray : Reorder} />

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('drag_drop_to_change_order')} TextAlign="left" />
                </IconTextContainer>

                <Spacer Size="extra-small" />

                <IconTextContainer className="IconTextContainer">
                    <IconTextContainerIcon ImageSrc={!!AllWhite ? GroupDarkGray : GroupGray} />

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('drag_drop_exercises_in_circuit')} TextAlign="left" />
                </IconTextContainer>

                <Spacer Size="extra-small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'bold', FontSize: 'medium-1', Text: '2' },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('grouped_exercises')} = ` },
                        { FontColor: 'blue-royal', FontFamily: 'bold', FontSize: 'medium-1', Text: t('a_superset') }
                    ]}
                />

                <Spacer Size="extra-small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'bold', FontSize: 'medium-1', Text: '3+' },
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('grouped_exercises')} = ` },
                        { FontColor: 'turquoise-dark', FontFamily: 'bold', FontSize: 'medium-1', Text: t('circuit_training') }
                    ]}
                />
            </ExplanationContainer>
        );
    }

    render() {
        var { t } = this.props;
        var { className, AllWhite, FromAddWorkouts } = this.props;

        return (
            <Container ref={this.topRef} className={`WorkoutCircuitsContainer ${className}`}>
                {this.renderExplanation()}

                <Spacer Size="medium" />

                {
                    !!FromAddWorkouts &&
                    <>
                        <ButtonGroup
                            Buttons={[
                                { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: this.onCancelCircuits, Size: 'medium', Title: t('Cancel_with_first_char_uppercase') },
                                { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-astronaut', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: this.onSaveCircuits, Size: 'medium', Title: t('save_with_first_char_uppercase') }
                            ]}
                            ContainerWidth="800px"
                            NotTouching
                        />

                        <Spacer Size="medium" />
                    </>
                }

                <DragDropContext className="DragDropContext" onDragEnd={this.onDragEnd}>
                    <Droppable className="render Droppable" droppableId="droppable" type="droppableItem">
                        {(provided, snapshot) => (
                            <CircuitsContainer className="CircuitsContainer" ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                {this.state.Circuits.map((item, index) => (
                                    <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                                        {(provided, snapshot) => (
                                            <>
                                                <CircuitContainer className="CircuitContainer"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                    Type={item.Type}
                                                >
                                                    <DynamicDragHandle {...provided.dragHandleProps} Type={item.Type}>
                                                        <DragHandleIcon ImageSrc={!!AllWhite ? ReorderDarkGray : Reorder} />
                                                    </DynamicDragHandle>

                                                    <CircuitDetailContainer className="CircuitDetailContainer" NumExercises={item.NumExercises} Type={item.Type}>
                                                        {
                                                            item.NumExercises >= 2 &&
                                                            <CircuitHeaderContainer className="CircuitHeaderContainer">
                                                                {item.NumExercises === 2 && <PageText FontColor="white" FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t(`Workout_Set_Type_Superset`)} TextAlign="left" />}
                                                                {item.NumExercises >= 3 && <PageText FontColor="white" FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t(`Workout_Set_Type_Circuit`)} TextAlign="left" />}

                                                                <DragHandleIcon ImageSrc={XWhite} OnClick={() => this.onDeleteCircuit(index)} />
                                                            </CircuitHeaderContainer>
                                                        }

                                                        {item.NumExercises >= 2 && <Spacer Size="small" />}

                                                        {this.renderDroppable({ WorkoutExercises: item.WorkoutExercises, type: item.id })}
                                                    </CircuitDetailContainer>
                                                </CircuitContainer>
                                                {provided.placeholder}
                                            </>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </CircuitsContainer>
                        )}
                    </Droppable>
                </DragDropContext>

                {
                    !FromAddWorkouts &&
                    <>
                        <Spacer Size="medium" />
        
                        <ButtonGroup
                            Buttons={[
                                { BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: this.onCancelCircuits, Size: 'large', Title: t('Cancel_with_first_char_uppercase') },
                                { BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-astronaut', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: this.onSaveCircuits, Size: 'large', Title: t('save_with_first_char_uppercase') }
                            ]}
                            ContainerWidth="800px"
                            NotTouching
                        />
                    </>
                }
            </Container>
        );
    }
}

WorkoutCircuits.propTypes = {
    AllWhite: PropTypes.bool,
    Circuits: PropTypes.array.isRequired,
    FromAddWorkouts: PropTypes.bool,
    OnCancelCircuits: PropTypes.func.isRequired,
    OnSaveCircuits: PropTypes.func.isRequired
}

WorkoutCircuits.defaultProps = {
    AllWhite: false,
    FromAddWorkouts: false
}

export default withTranslation()(WorkoutCircuits);