import styled from 'styled-components';

import Image from '../../Image';
import Input from '../Input';

export const CheckmarkIcon = styled(Image)`
    height: 14px;
    width: 14px;
`;

export const CheckmarkInput = styled(Input)`
    ${props => props.Hidden ? 'display: none;' : ''}

    > input {
        align-items: center;
        display: flex;
        height: 14px;
        justify-content: center;
        width: 14px;

        @media(hover:hover) {
            :hover {
                cursor: ${props => props.Disabled ? 'not-allowed' : 'pointer'};
            }
        }
    }
`;

export const CheckmarkLabel = styled.label`
    ${props => props.Hidden ? 'display: none;' : ''}

    @media(hover:hover) {
        :hover {
            cursor: ${props => props.Disabled ? 'not-allowed' : 'pointer'};
        }
    }
`;