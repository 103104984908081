import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearCurrencies, ClearExpenses, ClearTrainerPayoutSummary, ClearSaveData } from '../../Services/Actions';
import queryString from 'query-string';
import history from '../../history';

import { MenuButtons, StyledPage } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Spacer from '../../Components/Spacer';

import Accounting from './Accounting';
import Contracts from './Contracts';
import Dashboard from './Dashboard';
import Payouts from './Payouts';
import Products from './Products';

import { BETA_SITE_URL } from '../../Config';

class Payments extends React.Component {
    state = { AllTimeMax: 200, Reps: 10, SelectedMenu: 'Dashboard', SmallestIncrement: this.props.PreferredMeasuringSystem.toLowerCase() === 'metric' ? 2.5 : 5, Weight: 100 };

    componentDidMount() {
        if (+this.props.IsSalesGetStarted === 0) window.location = `${BETA_SITE_URL}/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=sales&tab=products`;
        else {
            const QueryValues = queryString.parse(this.props.location.search);
            if (Object.entries(QueryValues).length > 0 && QueryValues.tab) {
                var SelectedMenu = 'Dashboard';
                
                if (QueryValues.tab.toLowerCase() === 'dashboard') SelectedMenu = 'Dashboard';
                else if (QueryValues.tab.toLowerCase() === 'products') SelectedMenu = 'Products';
                else if (QueryValues.tab.toLowerCase() === 'contracts') SelectedMenu = 'Contracts';
                else if (QueryValues.tab.toLowerCase() === 'accounting') SelectedMenu = 'Accounting';
                // else if (QueryValues.tab.toLowerCase() === 'payouts') SelectedMenu = 'Payouts';

                if (SelectedMenu === 'Products') window.location = `${BETA_SITE_URL}/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=sales&tab=products`;
                else if (SelectedMenu === 'Accounting') window.location = `${BETA_SITE_URL}/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=sales&tab=accounting`;
                else if (SelectedMenu === 'Contracts') window.location = `${BETA_SITE_URL}/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=sales&tab=contracts`;
                else this.setState({ SelectedMenu });
            } else history.push({ pathname: '/sales', search: `?tab=dashboard` });
        }
    }

    componentWillUnmount() {
        this.props.ClearCurrencies();
        this.props.ClearExpenses();
        this.props.ClearTrainerPayoutSummary();
        this.props.ClearSaveData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            var QueryValues = queryString.parse(this.props.location.search);
            if (Object.entries(QueryValues).length > 0 && QueryValues.tab) {
                if (QueryValues.tab.toLowerCase() === 'dashboard') this.setState({ SelectedMenu: 'Dashboard' });
                else if (QueryValues.tab.toLowerCase() === 'products') window.location = `${BETA_SITE_URL}/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=sales&tab=products`;
                else if (QueryValues.tab.toLowerCase() === 'contracts') window.location = `${BETA_SITE_URL}/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=sales&tab=contracts`;
                else if (QueryValues.tab.toLowerCase() === 'accounting') window.location = `${BETA_SITE_URL}/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=sales&tab=accounting`;
                // else if (QueryValues.tab.toLowerCase() === 'payouts') this.setState({ SelectedMenu: 'Payouts' });
            }
        }
    }

    calculateMax = ({ Reps, Round, Weight }) => {
        var MaxWeight = Weight;
        if (+Reps > 1) MaxWeight = (Weight * (1 + (Reps / 30)));

        if (Round) MaxWeight = Math.round(MaxWeight * 100) / 100;

        return MaxWeight;
    }

    roundWeight = Weight => {
        var { SmallestIncrement } = this.state;
        return (Math.ceil(Weight / SmallestIncrement) * SmallestIncrement) || SmallestIncrement;
    }

    onChangeAllTimeMax = event => {
        var AllTimeMax = +event.target.value;
        var { Weight } = this.state;

        this.setState({ AllTimeMax: +AllTimeMax });        

        var Reps = Math.ceil(((30 * (AllTimeMax)) / Weight) - 30);
        this.setState({ Reps });
    }

    onChangeReps = event => {
        var Reps = event.target.value;
        var { AllTimeMax, SmallestIncrement } = this.state;

        this.setState({ Reps });

        var Weight = AllTimeMax;
        if (+Reps === 1) Weight += SmallestIncrement;
        if (+Reps > 1) Weight = this.roundWeight(Weight / (1 + (Reps / 30)));

        var NewMax = this.calculateMax({ Reps, Round: true, Weight });
        if (NewMax === AllTimeMax) Weight += SmallestIncrement;

        this.setState({ Weight });
    }

    onChangeWeight = event => {
        var Weight = event.target.value;
        var { AllTimeMax } = this.state;

        this.setState({ Weight });

        var Reps = Math.ceil(((30 * (AllTimeMax)) / Weight) - 30);
        
        var NewMax = this.calculateMax({ Reps, Round: true, Weight });

        if (NewMax === AllTimeMax) Reps += 1;

        this.setState({ Reps });
    }

    onSelectMenu = SelectedMenu => {
        if (SelectedMenu === 'Products') window.location = `${BETA_SITE_URL}/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=sales&tab=products`;
        else if (SelectedMenu === 'Accounting') window.location = `${BETA_SITE_URL}/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=sales&tab=accounting`;
        else if (SelectedMenu === 'Contracts') window.location = `${BETA_SITE_URL}/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=sales&tab=contracts`;
        else history.push({ pathname: '/sales', search: `?tab=${SelectedMenu.toLowerCase()}` });
        // this.setState({ SelectedMenu });
    }

    renderDashboard = () => {
        var { AllTimeMax, Reps, SmallestIncrement, Weight } = this.state;

        return (
            <div>
                <h1>All Time Max: <input onChange={this.onChangeAllTimeMax} type="number" value={AllTimeMax} /></h1>

                <h3>Smallest Increment: {SmallestIncrement}</h3>

                <h1>Calculated New Max: {this.calculateMax({ Reps, Round: true, Weight })}</h1>
                <h1>Calculated New Max (no rounding): {this.calculateMax({ Reps, Round: false, Weight })}</h1>

                <h1>Reps: <input onChange={this.onChangeReps} type="number" value={Reps} /></h1>
                <h1>Weight: <input onChange={this.onChangeWeight} type="number" value={Weight} /></h1>
            </div>
        );
    }

    renderMenus = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { SelectedMenu } = this.state;
    
        var ButtonData = { BackgroundColorHover: 'blue-astronaut', ColorHover: 'white' };
    
        return (
            <MenuButtons>
                <ButtonGroup
                    ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                    ContainerWidth={Device === 'mobile' || Device === 'mobile_small' ? '100%' : null}
                    NotTouching={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}

                    Buttons={[
                        { ...ButtonData, OnClick: () => this.onSelectMenu('Dashboard'), Selected: SelectedMenu === 'Dashboard', Title: t('Dashboard') },
                        { ...ButtonData, OnClick: () => this.onSelectMenu('Products'), Selected: SelectedMenu === 'Products', Title: t('products') },
                        { ...ButtonData, OnClick: () => this.onSelectMenu('Contracts'), Selected: SelectedMenu === 'Contracts', Title: t('contracts') },
                        { ...ButtonData, OnClick: () => this.onSelectMenu('Accounting'), Selected: SelectedMenu === 'Accounting', Title: t('accounting') },
                        // { ...ButtonData, OnClick: () => this.onSelectMenu('Payouts'), Selected: SelectedMenu === 'Payouts', Title: t('Payouts') }
                    ]}
                />
            </MenuButtons>
        );
    }

    render() {
        var { SelectedMenu } = this.state;

        return (
            <StyledPage NoMargin>
                {this.renderMenus()}
    
                <Spacer Size="medium" />
    
                {SelectedMenu === 'Accounting' && <Accounting />}
                {SelectedMenu === 'Contracts' && <Contracts />}
                {SelectedMenu === 'Dashboard' && <Dashboard />}
                {/* {SelectedMenu === 'Dashboard' && this.renderDashboard()} */}
                {SelectedMenu === 'Products' && <Products />}
                {SelectedMenu === 'Payouts' && <Payouts />}
            </StyledPage>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserId: state.Auth.UserDetails.UserId,
        IsSalesGetStarted: state.Auth.UserDetails.IsSalesGetStarted,
        PreferredMeasuringSystem: state.Auth.UserDetails.PreferredMeasuringSystem
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearCurrencies, ClearExpenses, ClearTrainerPayoutSummary, ClearSaveData } )(Payments));