import React from 'react';
import PropTypes from 'prop-types';

import { CheckmarkIcon, CheckmarkInput, CheckmarkLabel } from './styles';

import Blocked from '../../../Assets/Icons/Blocked.png';
import Checkmark_Minus from '../../../Assets/Icons/Checkmark_Minus.png';
import Checkmark_Selected from '../../../Assets/Icons/Checkmark_Selected.png';

class Checkmark extends React.Component {
    onSelectItem = (e, ItemId, SelectedIndex) => {
        var { Disabled, OnSelectItem } = this.props;

        if (!Disabled) return OnSelectItem(e, ItemId, SelectedIndex);
        else return null;
    }

    render() {
        var { BlockIcon, className, Disabled, ItemId, OnSelectItem, Title, SelectedExcept, SelectedIndex, ShowBlocked } = this.props;

        return (
            <div className={className}>
                <CheckmarkInput
                    Checked={SelectedIndex > -1 ? true : false}
                    Disabled={(Disabled || (ShowBlocked && SelectedIndex === -1)) ? true : false}
                    Hidden={(ShowBlocked && SelectedIndex === -1) || SelectedIndex > -1}
                    HtmlFor={`${Title}-${ItemId}`}
                    NoLabel
                    NoMargin
                    OnChange={e => this.onSelectItem(e, ItemId, SelectedIndex)}
                    ReadOnly={Disabled}
                    Type="checkbox"
                    Value={`${ItemId}`}
                />

                <CheckmarkLabel
                    Disabled={Disabled || (ShowBlocked && SelectedIndex === -1)}
                    htmlFor={`${Title}-${ItemId}`}
                    Hidden={!ShowBlocked && SelectedIndex === -1}
                    onClick={(Disabled || (ShowBlocked && SelectedIndex === -1)) ? null : () => OnSelectItem(null, ItemId, SelectedIndex)}
                >
                    <CheckmarkIcon ImageSrc={(ShowBlocked || BlockIcon) ? Blocked : SelectedExcept ? Checkmark_Minus : Checkmark_Selected} Loading={false} />
                </CheckmarkLabel>
            </div>
        );
    }
}

Checkmark.propTypes = {
    BlockIcon: PropTypes.bool,
    Disabled: PropTypes.bool,
    ItemId: PropTypes.number.isRequired,
    OnSelectItem: PropTypes.func.isRequired,
    SelectedExcept: PropTypes.bool,
    SelectedIndex: PropTypes.number.isRequired,
    ShowBlocked: PropTypes.bool,
    Title: PropTypes.string.isRequired,
}

Checkmark.defaultProps = {
    Disabled: false
}

export default Checkmark;