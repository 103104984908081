import {
    TRYING_ADD_TRAINER_CLIENT_SUCCESS,

    TRYING_DELETE_TRAINER_CLIENT,
    TRYING_DELETE_TRAINER_CLIENT_SUCCESS,
    TRYING_DELETE_TRAINER_CLIENT_ERROR,

    CLEAR_ADD_TRAINER_CLIENT_ERROR,
    CLEAR_CLIENT_DASHBOARD,
    TRYING_GET_CLIENT_DASHBOARD,
    TRYING_GET_CLIENT_DASHBOARD_SUCCESS,
    TRYING_GET_CLIENT_DASHBOARD_ERROR,

    TRYING_GET_CLIENT_DASHBOARD_EVENT,
    TRYING_GET_CLIENT_DASHBOARD_EVENT_SUCCESS,
    TRYING_GET_CLIENT_DASHBOARD_EVENT_ERROR,

    TRYING_GET_GROUP_DETAILS_SUCCESS,

    TRYING_INSERTEDIT_USER_GROUP,
    TRYING_INSERTEDIT_USER_GROUP_SUCCESS,
    TRYING_INSERTEDIT_USER_GROUP_ERROR,

    TRYING_UPDATE_MEAL_ACCESS,
    TRYING_UPDATE_MEAL_ACCESS_SUCCESS,
    TRYING_UPDATE_MEAL_ACCESS_ERROR,

    TRYING_UPDATE_WORKOUT_ACCESS,
    TRYING_UPDATE_WORKOUT_ACCESS_SUCCESS,
    TRYING_UPDATE_WORKOUT_ACCESS_ERROR
} from '../Types';

const INITIAL_STATE = {
    Clients: { Clients: [], TotalPages: 0, TotalRecords: 0 },
    DashboardEventDetails: {},
    Groups: { Groups: [], TotalPages: 0, TotalRecords: 0 },

    TryingAddTrainerClient: false,
    TryingAddTrainerClientError: null,
    TryingDeleteTrainerClient: false,
    TryingDeleteTrainerClientError: null,
    TryingGetClientDashboard: false,
    TryingGetClientDashboardError: null,
    TryingGetClientDashboardEvent: false,
    TryingGetClientDashboardEventError: null,
    TryingUpdateMealAccess: false,
    TryingUpdateMealAccessError: null,
    TryingUpdateWorkoutAccess: false,
    TryingUpdateWorkoutAccessError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_ADD_TRAINER_CLIENT_SUCCESS:
            // If Client Invited Directly, It Happened From Client Dashboard In Groups, So Need To Update All Groups With Client To Have RequestTypeId = 1
            var UserId = action.payload.UserId;
            if (!UserId) return { ...state };

            var NewGroups = { ...state.Groups };

            group_loop:
            for (var i = 0; i < NewGroups.Groups.length; i++) {
                for (var a = 0; a < NewGroups.Groups[i].GroupMembers.GroupMembers.length; a++) {
                    if (+NewGroups.Groups[i].GroupMembers.GroupMembers[a].UserId === +UserId) {
                        NewGroups.Groups[i].GroupMembers.GroupMembers[a].RequestTypeId = 1;
                        continue group_loop;
                    }
                }
            }

            return { ...state, Groups: NewGroups };

        case TRYING_DELETE_TRAINER_CLIENT:
            return { ...state, TryingDeleteTrainerClient: true, TryingDeleteTrainerClientError: null };
        case TRYING_DELETE_TRAINER_CLIENT_ERROR:
            return { ...state, TryingDeleteTrainerClient: false, TryingDeleteTrainerClientError: action.payload };
        case TRYING_DELETE_TRAINER_CLIENT_SUCCESS:
            if (action.payload.ReturnDataType !== 'Dashboard') return { ...state, TryingDeleteTrainerClient: false };

            var { ClientId, ClientType } = action.payload;

            // Splice user from Clients
                if (ClientType === 'TrainerClients') {
                    // eslint-disable-next-line
                    var NewClients = { ...state.Clients };
                    NewClients.Clients = NewClients.Clients.flatMap(Client => +Client.UserId === +ClientId ? [] : Client);

                    return { ...state, Clients: NewClients, TryingDeleteTrainerClient: false, TryingDeleteTrainerClientError: null };
                }

            // Splice user from Private Groups, and update RequestTypeId in Teams
                // eslint-disable-next-line
                var NewGroups = { ...state.Groups };
                NewGroups.Groups = NewGroups.Groups.map(Group => {
                    return {
                        ...Group,
                        GroupMembers: {
                            ...Group.GroupMembers,
                            GroupMembers: Group.GroupMembers.GroupMembers.flatMap(Client => {
                                if (+Client.UserId === +ClientId) {
                                    if (Group.GroupType === 'Private') return []
                                    else return { ...Client, RequestTypeId: 0 };
                                } else return Client;
                            })
                        }
                    }
                });

                return { ...state, Groups: NewGroups, TryingDeleteTrainerClient: false, TryingDeleteTrainerClientError: null };

        case CLEAR_ADD_TRAINER_CLIENT_ERROR:
            return { ...state, TryingAddTrainerClient: false, TryingAddTrainerClientError: null };
        
        case CLEAR_CLIENT_DASHBOARD:
            return { ...state, Clients: { Clients: [], TotalPages: 0, TotalRecords: 0 }, DashboardEventDetails: {}, Groups: { Groups: [], TotalPages: 0, TotalRecords: 0 }, TryingGetClientDashboard: false, TryingGetClientDashboardError: null };
        case TRYING_GET_CLIENT_DASHBOARD:
            return { ...state, TryingGetClientDashboard: true, TryingGetClientDashboardError: null };
        case TRYING_GET_CLIENT_DASHBOARD_ERROR:
            return { ...state, TryingGetClientDashboard: false, TryingGetClientDashboardError: action.payload };
        case TRYING_GET_CLIENT_DASHBOARD_SUCCESS:
            // eslint-disable-next-line
            var { Clients, GroupIndex, Groups: NewGroups } = action.payload;

            // Paginated a specific group, so only update that group
                if (GroupIndex !== null) {
                    var PaginatedGroup = { ...state.Groups };
                    PaginatedGroup.Groups[GroupIndex].GroupMembers.GroupMembers = [ ...PaginatedGroup.Groups[GroupIndex].GroupMembers.GroupMembers, ...NewGroups.Groups[0].GroupMembers.GroupMembers ];

                    NewGroups = PaginatedGroup;
                }

            return { ...state, Clients, Groups: NewGroups, TryingGetClientDashboard: false, TryingGetClientDashboardError: null };

        case TRYING_GET_CLIENT_DASHBOARD_EVENT:
            return { ...state, TryingGetClientDashboardEvent: true, TryingGetClientDashboardEventError: null };
        case TRYING_GET_CLIENT_DASHBOARD_EVENT_ERROR:
            return { ...state, TryingGetClientDashboardEvent: false, TryingGetClientDashboardEventError: action.payload };
        case TRYING_GET_CLIENT_DASHBOARD_EVENT_SUCCESS:
            // eslint-disable-next-line
            var { EventDetails, EventId, EventType, UserId } = action.payload;

            var NewDashboardEventDetails = { ...state.DashboardEventDetails };
            NewDashboardEventDetails[`${UserId}_${EventType}_${EventId}`] = EventDetails;

            return { ...state, DashboardEventDetails: NewDashboardEventDetails, TryingGetClientDashboardEvent: false, TryingGetClientDashboardEventError: null };

        case TRYING_GET_GROUP_DETAILS_SUCCESS:
            if (!action.payload.FromDashboard) return  { ...state };

            // eslint-disable-next-line
            var { Groups, GroupIndex } = action.payload;

            // eslint-disable-next-line
            var NewGroups = { ...state.Groups };
            NewGroups.Groups[GroupIndex] = Groups.Groups[0];

            return { ...state, Groups: NewGroups };

        case TRYING_INSERTEDIT_USER_GROUP:
            if (+action.payload.GroupImage !== 0 && !action.payload.GroupImage) return { ...state };

            // eslint-disable-next-line
            var { GroupImage, GroupIndex } = action.payload;
            if (+GroupImage !== 0) GroupImage = URL.createObjectURL(GroupImage);
            else GroupImage = null;

            var EditedGroups = { ...state.Groups };
            EditedGroups.Groups[GroupIndex].GroupImage = GroupImage;

            return { ...state, Groups: EditedGroups };
        case TRYING_INSERTEDIT_USER_GROUP_SUCCESS:
            // eslint-disable-next-line
            var { GroupId, Groups } = action.payload;

            if (!GroupId) {
                // eslint-disable-next-line
                var NewGroups = { ...state.Groups };
                
                // If last group is ungrouped, add new group directly before it
                    if (NewGroups.Groups.length && !NewGroups.Groups[NewGroups.Groups.length - 1].GroupId) NewGroups.Groups.splice(NewGroups.Groups.length - 1, 0, Groups.Groups[0]);
                // Else Add New Group To Very End
                    else NewGroups.Groups = [ ...NewGroups.Groups, Groups.Groups[0] ]

                return { ...state, Groups: NewGroups };
            }

            return { ...state };
        case TRYING_INSERTEDIT_USER_GROUP_ERROR:
            if (!action.payload.GroupImage) return { ...state };

            // eslint-disable-next-line
            var { GroupIndex, OriginalImage } = action.payload;

            var OriginalGroups = { ...state.Groups };
            OriginalGroups.Groups[GroupIndex].GroupImage = OriginalImage;

            return { ...state, Groups: OriginalGroups };

        case TRYING_UPDATE_MEAL_ACCESS:
            return { ...state, TryingUpdateMealAccess: true, TryingUpdateMealAccessError: null };
        case TRYING_UPDATE_MEAL_ACCESS_ERROR:
            return { ...state, TryingUpdateMealAccess: false, TryingUpdateMealAccessError: action.payload };
        case TRYING_UPDATE_MEAL_ACCESS_SUCCESS:
            if (action.payload.ReturnDataType !== 'Dashboard') return { ...state };

            // eslint-disable-next-line
            var { Clients, ClientType, UserId } = action.payload;

            if (ClientType === 'TrainerClients') {
                // eslint-disable-next-line
                var NewClients = { ...state.Clients };
                NewClients.Clients = NewClients.Clients.map(Client => {
                    if (+Client.UserId === +UserId) return Clients.Clients[0];
                    else return Client;
                });

                return { ...state, Clients: NewClients, TryingUpdateMealAccess: false, TryingUpdateMealAccessError: null };
            }

            // eslint-disable-next-line
            var NewGroups = { ...state.Groups };
            NewGroups.Groups = NewGroups.Groups.map(Group => {
                return {
                    ...Group,
                    GroupMembers: {
                        ...Group.GroupMembers,
                        GroupMembers: Group.GroupMembers.GroupMembers.flatMap(Client => {
                            if (+Client.UserId === +UserId) return Clients.Clients[0];
                            else return Client;
                        })
                    }
                }
            });

            return { ...state, Groups: NewGroups, TryingUpdateMealAccess: false, TryingUpdateMealAccessError: null };

        case TRYING_UPDATE_WORKOUT_ACCESS:
            return { ...state, TryingUpdateWorkoutAccess: true, TryingUpdateWorkoutAccessError: null };
        case TRYING_UPDATE_WORKOUT_ACCESS_ERROR:
            return { ...state, TryingUpdateWorkoutAccess: false, TryingUpdateWorkoutAccessError: action.payload };
        case TRYING_UPDATE_WORKOUT_ACCESS_SUCCESS:
            if (action.payload.ReturnDataType !== 'Dashboard') return { ...state };

            // eslint-disable-next-line
            var { Clients, ClientType, UserId } = action.payload;

            if (ClientType === 'TrainerClients') {
                // eslint-disable-next-line
                var NewClients = { ...state.Clients };
                NewClients.Clients = NewClients.Clients.map(Client => {
                    if (+Client.UserId === +UserId) return Clients.Clients[0];
                    else return Client;
                });

                return { ...state, Clients: NewClients, TryingUpdateWorkoutAccess: false, TryingUpdateWorkoutAccessError: null };
            }

            // eslint-disable-next-line
            var NewGroups = { ...state.Groups };
            NewGroups.Groups = NewGroups.Groups.map(Group => {
                return {
                    ...Group,
                    GroupMembers: {
                        ...Group.GroupMembers,
                        GroupMembers: Group.GroupMembers.GroupMembers.flatMap(Client => {
                            if (+Client.UserId === +UserId) return Clients.Clients[0];
                            else return Client;
                        })
                    }
                }
            });

            return { ...state, Groups: NewGroups, TryingUpdateWorkoutAccess: false, TryingUpdateWorkoutAccessError: null };

        default:
            return state;
    }
};
