import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearGyms, SearchGyms } from '../../Services/Actions';

import  { StyledInput, StyledLoadMore } from './styles';

import AbelModal from '../../Components/Modal';
import { GymListItem } from '../../Components/Lists/ListItems';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

class ChooseGym extends React.Component {
    _isMounted = false;

    state = {
        ActiveSearch: false,
        CompletedFirstSearch: false,
        DefaultGym: null,
        DefaultGymId: this.props.DefaultGymId,
        GymName: '',
        LastGymName: '',
        Loading: false,
        NewSearch: false,
        PageNo: 1,
        PageSize: 48
    };

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.ClearGyms();
    }

    onChangeSearch = event => {
        this.setState({ GymName: event.target.value });
    }

    onChooseGym = () => {
        this.props.OnSelectGym(this.state.DefaultGym);
    }

    onKeyPress = ({ key }) => {
        if (key === 'Enter' && !this.props.TryingSearchGyms) {
            var { GymName, LastGymName } = this.state;

            if (GymName !== LastGymName) {
                this.setState({ PageNo: 1, PageSize: 48, LastGymName: GymName }, () => {
                    if (GymName !== '') this.onLoadGyms();
                });
            }
        }
    }

    onLoadGyms = Increment => {
        var { GymName, NewSearch, PageNo, PageSize } = this.state;

        if (Increment) {
            NewSearch = false;
            PageNo = PageNo + 1;
        } else NewSearch = true;

        this.setState({ ActiveSearch: true, NewSearch, PageNo }, () => this.props.SearchGyms({ AddToExisting: Increment, GymName, NewSearch, PageNo, PageSize }).then(() => this.setState({ ActiveSearch: false, CompletedFirstSearch: true, NewSearch: false })));
    }

    onSelectGym = DefaultGym => {
        this.setState({ DefaultGym, DefaultGymId: DefaultGym.GymId });
    }

    renderGymList = () => {
        var { t } = this.props;
        var { Device, GymList, GymList_TotalRecords, IsMoreGyms, TryingSearchGyms } = this.props;
        var { ActiveSearch, CompletedFirstSearch, DefaultGymId, NewSearch, PageNo, PageSize } = this.state;

        var SelectedItemIds = DefaultGymId ? [ Number(DefaultGymId) ] : [];
        var IsFlexed = Device === 'laptop' ? 4 : Device === 'ipad' ? 3 : Device === 'mobile' ? 2 : 1;

        return (
            <StyledLoadMore
                ActiveSearch={ActiveSearch}
                CompletedFirstSearch={CompletedFirstSearch}
                IsFlexed={IsFlexed}
                IsLoading={TryingSearchGyms}
                ItemDescriptionType={t('gyms').toLowerCase()}
                ItemHeight={120}
                ItemId="GymId"
                ItemName="GymName"
                ItemRenderer={GymListItem}
                Items={GymList}
                HasMoreItems={IsMoreGyms}
                LoadMore={() => this.onLoadGyms(true)}
                NewSearch={NewSearch}
                NoItemsText={t('search_noresults')}
                OnSelectItem={DefaultGym => this.onSelectGym(DefaultGym)}
                PageNo={PageNo}
                PageSize={PageSize}
                SelectedItemIds={SelectedItemIds}
                TotalRecords={GymList_TotalRecords}
            />
        );
    }

    renderModalBody = () => {
        var { t } = this.props;
        var { CompletedFirstSearch, GymName } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('WorkoutPlan_find_public_gym_from_list')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('WorkoutPlan_find_public_gym_from_list_description')} TextAlign="center" />

                <Spacer Size="medium" />

                <StyledInput
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeSearch}
                    OnKeyPress={this.onKeyPress}
                    Placeholder={t('search_with_first_char_uppercase_dots')}
                    Size="medium"
                    Type="text"
                    Value={GymName}
                />

                {CompletedFirstSearch ? <Spacer Size="medium" /> : null}

                {this.renderGymList()}
            </>
        );
    }

    render() {
        var { t } = this.props;
        var { OnHide, Show } = this.props;
        var { DefaultGymId } = this.state;

        return (
            <AbelModal
                BottomButton="submit"
                BottomButtonOnClick={this.onChooseGym}
                BottomButtonText={t('WorkoutPlan_add_new_gym')}
                Disabled={!DefaultGymId}
                GA_PathName="ChooseGym"
                Show={Show}
                Size="large"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

ChooseGym.propTypes = {
    ClientId: PropTypes.number,
    DefaultGymId: PropTypes.number,
    OnHide: PropTypes.func.isRequired,
    OnSelectGym: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        GymList: state.Gym.GymList,
        GymList_TotalRecords: state.Gym.GymList_TotalRecords,
        IsMoreGyms: state.Gym.IsMoreGyms,
        
        TryingSearchGyms: state.Gym.TryingSearchGyms,
        TryingSearchGymsError: state.Gym.TryingSearchGymsError,
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearGyms, SearchGyms } )(ChooseGym));