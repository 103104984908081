import styled from 'styled-components';

export const HeaderContainer = styled.div`
    border-bottom: 2px solid var(--white-concrete);
    display: flex;
    grid-area: header;
    padding: 20px 30px;
`;

export const HeaderLeftContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;

    > div:not(:last-child) {
        margin-right: 10px;
    }
`;

export const HeaderRightContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;