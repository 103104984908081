import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearGroups, ClearTrainerClients, GetChooseClients, GetTrainerClients, SearchGroups, SearchTrainerClients } from '../../Services/Actions';

import  { ClientGroupContainer, GroupClientContainer, LoadMoreContainer, StyledClientName, StyledTrainerClientsPaginatedList } from './styles';

import AbelModal from '../../Components/Modal';
import Accordion from '../../Components/Accordion';
import Button from '../../Components/Buttons/Button';
import ChooseClientHeader from '../../Components/Accordion/ChooseClientHeader';
import Group from './Group';
import { GroupMemberListItem } from '../../Components/Lists/ListItems';

import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import ProfilePhoto from '../../Components/ProfilePhoto';
import Spacer from '../../Components/Spacer';

class ChooseClients extends React.Component {
    _isMounted = false;

    state = {
        AllPrivateGroupIds: [],
        ChooseClientsPageNo: 1,
        ChooseClientsPageSize: 30,
        GetOtherGroups: 1,
        Groups: [],
        Groups_IsMoreResults: null,
        Groups_TotalRecords: 0,
        Loading: false,
        MaxClientsReached: false,
        SearchGroupsPageNo: 1,
        SearchGroupsPageSize: 30,
        SelectedClientIds: this.props.SelectedClientIds || [],
        SelectedClients: this.props.SelectedClients || [],
        SelectedGroupIds: [],
        SelectedGroups: {},
        TrainerClients: [],
        TrainerClients_IsMoreResults: null,
        TrainerClients_TotalRecords: 0,
        TrainerClientsPageNo: 1,
        TrainerClientsPageSize: 30
    };

    componentDidMount() {
        this._isMounted = true;

        var { EligibleTypes, GetStartedEligible, SelectedGroups } = this.props;
        var { ChooseClientsPageNo, ChooseClientsPageSize } = this.state;

        this.props.GetChooseClients({ EligibleTypes, GetStartedEligible, PageNo: ChooseClientsPageNo, PageSize: ChooseClientsPageSize }).then(({ Groups: { Groups, IsMoreResults: Groups_IsMoreResults, TotalRecords: Groups_TotalRecords }, TrainerClients: { TrainerClients, IsMoreResults: TrainerClients_IsMoreResults, TotalRecords: TrainerClients_TotalRecords} }) => {
            var AllPrivateGroupIds = Groups.map(({ GroupId, GroupType }) => `${GroupType}_GroupId-${GroupId}`);
            this.setState({ AllPrivateGroupIds, Groups, Groups_IsMoreResults, Groups_TotalRecords, Loading: false, SelectedGroups, TrainerClients, TrainerClients_IsMoreResults, TrainerClients_TotalRecords }, () => this.onUpdateSelectedGroups());
        });
    }

    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearGroups();
        this.props.ClearTrainerClients();
    }

    onLoadedNewGroupUsers = ({ GroupId, GroupIndex, Users }) => {
        var Groups = [ ...this.state.Groups ];
        Groups[GroupIndex].GroupMembers.GroupMembers = [ ...Groups[GroupIndex].GroupMembers.GroupMembers, ...Users ];

        if (this.state.Groups[GroupIndex].GroupType === 'Private' && this.state.SelectedGroupIds.indexOf(+GroupId) !== -1) {
            var SelectedClientIds = [ ...this.state.SelectedClientIds ];
            var SelectedClients = [ ...this.state.SelectedClients ];

            Users.map(User => {
                if (SelectedClientIds.indexOf(+User.UserId) === -1) {
                    SelectedClientIds.push(User.UserId);
                    SelectedClients.push({ ...User, ClientId: User.UserId });
                }

                return null;
            });

            this.setState({ Groups, SelectedClientIds, SelectedClients }, () => this.onUpdateSelectedGroups());
        } else this.setState({ Groups }, () => this.onUpdateSelectedGroups());
    }

    onLoadGroups = Increment => {
        var { GetOtherGroups, SearchGroupsPageNo: PageNo, SearchGroupsPageSize: PageSize } = this.state;
        var { EligibleTypes } = this.props;

        if (Increment) PageNo = PageNo + 1;
        else PageNo = 1;

        this.setState({ SearchGroupsPageNo: PageNo },
            () => this.props.SearchGroups({ AddToExisting: Increment, EligibleTypes, GetOtherGroups, PageNo, PageSize, ReturnData: true }).then(({ Groups, IsMoreResults: Groups_IsMoreResults, TotalRecords: Groups_TotalRecords }) => {
                if (this._isMounted && !this.props.TryingSearchGroupsError) {
                    var AllPrivateGroupList = [];
                    var GroupsList = [];
                    
                    if (Increment) {
                        AllPrivateGroupList = [ ...this.state.AllPrivateGroupIds, ...Groups.map(({ GroupId, GroupType }) => `${GroupType}_GroupId-${GroupId}`) ];
                        GroupsList = [ ...this.state.Groups, ...Groups ];
                    }
                    else {
                        AllPrivateGroupList = [ ...Groups.map(({ GroupId, GroupType }) => `${GroupType}_GroupId-${GroupId}`) ];
                        GroupsList = [ ...Groups ];
                    }
                
                    this.setState({ AllPrivateGroupIds: AllPrivateGroupList, Groups: GroupsList, Groups_IsMoreResults, Groups_TotalRecords }, () => this.onUpdateSelectedGroups());
                }
            })
        );
    }

    onLoadTrainerClients = Increment => {
        var { EditingType, GetOtherGroups, TrainerClientsPageNo: PageNo, TrainerClientsPageSize: PageSize, NewSearch, SearchValue } = this.state;
        var { GetStartedEligible, GroupId } = this.props;

        if (Increment) {
            NewSearch = false;
            PageNo = PageNo + 1;
        } else {
            NewSearch = true;
            PageNo = 1;
        }

        this.setState({ NewSearch, TrainerClientsPageNo: PageNo },
            () => this.props.SearchTrainerClients({ AddToExisting: Increment, EditingType, GetOtherGroups, GetStartedEligible, GroupId, PageNo, PageSize, ReturnData: true, UserSearch: SearchValue }).then(({ TrainerClients, IsMoreResults: TrainerClients_IsMoreResults, TotalRecords: TrainerClients_TotalRecords }) => {
                if (this._isMounted) {
                    var NewTrainerClients = [];
                    
                    if (!NewSearch) NewTrainerClients = [ ...this.state.TrainerClients, ...TrainerClients ];
                    else NewTrainerClients = [ ...TrainerClients ];
                
                    this.setState({ TrainerClients: NewTrainerClients, TrainerClients_IsMoreResults, TrainerClients_TotalRecords });
                }
            })
        );
    }

    onSelectClient = User => {
        var { MaxClients } = this.props;
        var { SelectedClientIds } = this.state;

        var SelectedIndex = this.state.SelectedClientIds.indexOf(+User.UserId);

        if (SelectedIndex === -1) {
            if (!MaxClients || SelectedClientIds.length < MaxClients) {
                this.setState({
                    SelectedClientIds: [ ...this.state.SelectedClientIds, User.UserId ],
                    SelectedClients: [ ...this.state.SelectedClients, { ...User, ClientId: User.UserId }]
                }, () => this.onUpdateSelectedGroups());
            }
            else this.setState({ MaxClientsReached: true });
        }
        else {
            var SelectedClientIds_New = [ ...this.state.SelectedClientIds ];
            SelectedClientIds_New.splice(SelectedIndex, 1);

            var SelectedClients = [ ...this.state.SelectedClients ];
            SelectedClients.splice(SelectedIndex, 1);

            this.setState({
                MaxClientsReached: false, SelectedClientIds: SelectedClientIds_New, SelectedClients
            }, () => this.onUpdateSelectedGroups());
        }
    }

    onSelectClients = () => {
        var { SelectedClients, SelectedGroups } = this.state;

        var NewSelectedGroups = {};

        Object.keys(SelectedGroups).map(ObjectKey => {
            NewSelectedGroups[ObjectKey] = { ...SelectedGroups[ObjectKey] };
            var { AllMembers, Members } = NewSelectedGroups[ObjectKey];

            if (!!AllMembers && Members.length) NewSelectedGroups[ObjectKey].Members = [];

            return null;
        });

        this.props.OnSelectClients({ SelectedClients, SelectedGroups: NewSelectedGroups });
    }

    onSelectGroup = (GroupIndex, GroupId) => {
        var { TrainerGroups } = this.props;
        var { SelectedGroupIds } = this.state;

        var SelectedGroupIds_Index = SelectedGroupIds.indexOf(+GroupId);

        var SelectedClientIds_New = [ ...this.state.SelectedClientIds ];
        var SelectedClients_New = [ ...this.state.SelectedClients ];

        TrainerGroups[GroupIndex].ClientIds.map((ClientId, MapIndex) => {
            var ClientIndex = SelectedClientIds_New.indexOf(+ClientId);

            // Select everybody in the group
                if (SelectedGroupIds_Index === -1 && ClientIndex === -1) {
                    SelectedClientIds_New.push(+ClientId);
                    SelectedClients_New.push({ ...TrainerGroups[GroupIndex].Clients[MapIndex] });
                }
            // Unselect everybody in the group
                else if (SelectedGroupIds_Index > -1 && ClientIndex > -1) {
                    SelectedClientIds_New.splice(ClientIndex, 1);
                    SelectedClients_New.splice(ClientIndex, 1);
                }

                return null;
        });

        this.setState({ SelectedClientIds: SelectedClientIds_New, SelectedClients: SelectedClients_New }, () => this.onUpdateSelectedGroups());
    }

    onSelectGroups = () => {
        var { SelectedGroups } = this.state;

        var NewSelectedGroups = {};

        Object.keys(SelectedGroups).map(ObjectKey => {
            NewSelectedGroups[ObjectKey] = { ...SelectedGroups[ObjectKey] };
            var { AllMembers, Members } = NewSelectedGroups[ObjectKey];

            if (!!AllMembers && Members.length) NewSelectedGroups[ObjectKey].Members = [];

            return null;
        })

        // this.props.OnSelectGroups(SelectedGroups);
        console.log('SelectedGroups: ', NewSelectedGroups);
    }

    onSelectGroupTeam = ({ GroupId, GroupIndex }) => {
        var { Groups, SelectedClientIds, SelectedGroups } = this.state;

        var SelectedClientIds_New = [ ...SelectedClientIds ];
        var SelectedClients = [ ...this.state.SelectedClients ];
        var SelectedGroupIds = [ ...this.state.SelectedGroupIds ];

        var { GroupImage, GroupMembers: { GroupMembers }, GroupName, GroupType } = Groups[GroupIndex];

        var ObjectKey = `${GroupType}_GroupId-${GroupId}`;
        
        var SelectedGroupIdsIndex = SelectedGroupIds.indexOf(+GroupId);

        var Members = [];

        // Already Selected ... If AllMembers = true, then delete it ... otherwise Set AllMembers = true
            if (SelectedGroups.hasOwnProperty(ObjectKey)) {
                if (!!SelectedGroups[ObjectKey].AllMembers) {
                    delete SelectedGroups[ObjectKey];
                    GroupMembers.map(User => {
                        if (GroupType === 'Private') {
                            var SelectedClientIdIndex = SelectedClientIds_New.indexOf(+User.UserId);

                            if (SelectedClientIdIndex !== -1) {
                                SelectedClientIds_New.splice(SelectedClientIdIndex, 1);
                                SelectedClients.splice(SelectedClientIdIndex, 1);
                            }
                        }
                        return null;
                    });

                    if (GroupType === 'Private' && SelectedGroupIdsIndex !== -1) SelectedGroupIds.splice(SelectedGroupIdsIndex, 1);
                } else {
                    for (var i = 0; i < GroupMembers.length; i++) {
                        var User = GroupMembers[i];
                        
                        if (User.HasAccess === 0) continue;
    
                        if (GroupType === 'Private') {
                            if (SelectedClientIds_New.indexOf(+User.UserId) === -1) {
                                SelectedClientIds_New.push(User.UserId);
                                SelectedClients.push({ ...User, ClientId: User.UserId });
                            }
                        }
                        
                        Members.push(+User.UserId);
                    }

                    if (Members.length > 0 && Members.length === GroupMembers.length) {
                        SelectedGroups[ObjectKey] = { AllMembers: true, GroupImage, GroupName, GroupType, Members, RemoveMembers: [] };
                        if (GroupType === 'Private' && SelectedGroupIdsIndex === -1) SelectedGroupIds.push(+GroupId);
                    } else if (Members.length > 0) SelectedGroups[ObjectKey] = { AllMembers: false, GroupImage, GroupName, GroupType, Members, RemoveMembers: [] };
                }
            }
        // Not Selected, So Add It ... And If Private Group, Then Add Members to Selected Clients
            else {
                for (var a = 0; a < GroupMembers.length; a++) {
                    // eslint-disable-next-line
                    var User = GroupMembers[a];
                    
                    if (User.HasAccess === 0) continue;

                    if (GroupType === 'Private') {
                        if (SelectedClientIds_New.indexOf(+User.UserId) === -1) {
                            SelectedClientIds_New.push(User.UserId);
                            SelectedClients.push({ ...User, ClientId: User.UserId });
                        }
                    }
                    
                    Members.push(+User.UserId);
                }

                if (Members.length > 0 && Members.length === GroupMembers.length) {
                    SelectedGroups[ObjectKey] = { AllMembers: true, GroupImage, GroupName, GroupType, Members, RemoveMembers: [] };
                    if (GroupType === 'Private' && SelectedGroupIdsIndex === -1) SelectedGroupIds.push(+GroupId);
                } else if (Members.length > 0) SelectedGroups[ObjectKey] = { AllMembers: false, GroupImage, GroupName, GroupType, Members, RemoveMembers: [] };
            }

        this.setState({ SelectedClientIds: SelectedClientIds_New, SelectedClients, SelectedGroupIds, SelectedGroups }, () => GroupType === 'Private' && this.onUpdateSelectedGroups());
    }

    onSelectGroupTeamUser = ({ GroupId, GroupIndex, NumMembers, User }) => {
        var { MaxClients } = this.props;
        var { Groups, SelectedClientIds, SelectedGroups } = this.state;
        
        var UserId = +User.UserId;
        var { GroupImage, GroupMembers: { GroupMembers }, GroupName, GroupType } = Groups[GroupIndex];
        var ObjectKey = `${GroupType}_GroupId-${GroupId}`;

        // (Un)Select Individual Client If GroupType = 'Private'
            var SelectedClientIds_New = [ ...this.state.SelectedClientIds ];
            var SelectedClients = [ ...this.state.SelectedClients ];
            var MaxClientsReached = this.state.MaxClientsReached;

            if (GroupType === 'Private') {
                var SelectedClientIdIndex = SelectedClientIds.indexOf(UserId);

                if (SelectedClientIdIndex === -1) {
                    if (!MaxClients || SelectedClientIds.length < MaxClients) {
                        SelectedClientIds_New.push(User.UserId);
                        SelectedClients.push({ ...User, ClientId: User.UserId });
                    }
                    else MaxClientsReached = true;
                }
                else {
                    SelectedClientIds_New.splice(SelectedClientIdIndex, 1);
                    SelectedClients.splice(SelectedClientIdIndex, 1);

                    MaxClientsReached = false;
                }

                this.setState({ MaxClientsReached, SelectedClientIds: SelectedClientIds_New, SelectedClients }, () => this.onUpdateSelectedGroups());
            }
            else {
                // Group Already Exists
                    if (SelectedGroups.hasOwnProperty(ObjectKey)) {     
                        var MembersIndex = SelectedGroups[ObjectKey].Members.indexOf(UserId);

                        // If AllMembers = true ... Check RemoveMembers Index. Add or remove from RemoveMembers
                            if (!!SelectedGroups[ObjectKey].AllMembers) {
                                var RemoveMembersIndex = SelectedGroups[ObjectKey].RemoveMembers.indexOf(UserId);

                                // User Already Selected
                                if (MembersIndex !== -1) {
                                    // Only Member, So Delete Group
                                    if (NumMembers === 1) delete SelectedGroups[ObjectKey];
                                    else SelectedGroups[ObjectKey].Members.splice(MembersIndex, 1);
                                }

                                // Not Selected, So Add
                                    if (RemoveMembersIndex === -1) {
                                        // Delete Group, Because Only Member In Group Is Unselected
                                            if (NumMembers === 1) delete SelectedGroups[ObjectKey];
                                        // Add User To RemoveMembers ... Unless All GroupMembers are Showing, Then Truncate RemoveMembers And Set AllMembers = false
                                            else if (GroupMembers.length === NumMembers) SelectedGroups[ObjectKey] = { ...SelectedGroups[ObjectKey], AllMembers: false, RemoveMembers: [] };
                                            else SelectedGroups[ObjectKey].RemoveMembers.push(UserId);
                                    }
                                // Already Added, So Remove
                                    else SelectedGroups[ObjectKey].RemoveMembers.splice(RemoveMembersIndex, 1);
                            }
                        // If AllMembers = false ... Check Members Index. Add or remove from Members
                            else {
                                // Not Selected, So Add
                                    if (MembersIndex === -1) {
                                        SelectedGroups[ObjectKey].Members.push(UserId);

                                        // Everybody in group is now selected, So Keep Members (so when they are unselected, I can remove AllMembers: true and still have them be selected) and Select Group
                                            if (SelectedGroups[ObjectKey].Members.length === NumMembers) {
                                                SelectedGroups[ObjectKey] = { ...SelectedGroups[ObjectKey], AllMembers: true, RemoveMembers: [] };
                                            }
                                    }
                                // Already Selected, So Delete Group Or Remove User
                                    else {
                                        // Delete Group, Because Only Member In Group Is Unselected
                                            if (SelectedGroups[ObjectKey].Members.length === 1) delete SelectedGroups[ObjectKey];
                                        // Remove User From Members
                                            else SelectedGroups[ObjectKey].Members.splice(MembersIndex, 1);
                                    }
                            }
                    }
                // Group Doesn't Exist
                    else {
                        // Add Group ... Set All Members true IF NumMembers = 1, Otherwise false AND Add User to Members
                            SelectedGroups[ObjectKey] = { AllMembers: NumMembers === 1, GroupImage, GroupName, GroupType, Members: NumMembers === 1 ? [] : [ UserId ], RemoveMembers: [] };
                    }

                this.setState({ SelectedGroups });
            }
    }

    onSelectUser = User => {
        // console.log('User: ', User);
        // console.log('AllPrivateGroupIds: ', this.state.AllPrivateGroupIds);
        // var { AllPrivateGroupIds, Groups } = this.state;

        // var UserId = +User.UserId;
        // var { UserGroups } = User; // All UserGroups Are Private In Array

        // var SelectedGroups = { ...this.state.SelectedGroups };

        // var SelectedClientIndex = this.state.SelectedClientIds.indexOf(UserId);
        // var SelectedClientIds = [ ...this.state.SelectedClientIds ];
        // var SelectedClients = [ ...this.state.SelectedClients ];

        // Not Selected, So Add To Selected Clients and Either Add Group With Member Or Push Member Into Existing Group, Toggling All Members IF Members.length = Group's NumMembers
        // Only Adding Groups That Have Been Loaded
            // if (SelectedClientIndex === -1) {
            //     SelectedClientIds.push(UserId);
            //     SelectedClients.push({ ...User, ClientId: UserId });

                // UserGroups.map(GroupId => {
                //     var ObjectKey = `Private_GroupId-${GroupId}`;
                //     var { GroupImage, GroupName, GroupType, NumMembers } = Groups[AllPrivateGroupIds.indexOf(ObjectKey)];

                //     if (!SelectedGroups.hasOwnProperty(ObjectKey)) SelectedGroups[ObjectKey] = { AllMembers: NumMembers === 1, GroupImage, GroupName, GroupType, Members: NumMembers === 1 ? [] : [ UserId ], RemoveMembers: [] };
                //     else SelectedGroups[ObjectKey] = { AllMembers: SelectedGroups[ObjectKey].Members.length === NumMembers - 1, GroupImage, GroupName, GroupType, Members: [ ...SelectedGroups[ObjectKey].Members, UserId ], RemoveMembers: [] };

                //     return null;
                // });
            // }

        // Is Selected, So Remove From Selected Clients And Either Delete Group If Only Member, Or If AllMembers = true, then add to RemoveMembers, or if All members = false, then just remove member from Members
            // else {
            //     SelectedClientIds.splice(SelectedClientIndex, 1);
            //     SelectedClients.splice(SelectedClientIndex, 1);

                // UserGroups.map(GroupId => {
                //     var ObjectKey = `Private_GroupId-${GroupId}`;
                //     var { GroupMembers: { GroupMembers }, NumMembers } = Groups[AllPrivateGroupIds.indexOf(ObjectKey)];
                //     var { AllMembers, Members } = SelectedGroups[ObjectKey];

                //     // If User Is Only Member, Delete The Group
                //         if (NumMembers === 1) delete SelectedGroups[ObjectKey];
                //     // If All Members And All Members Showing, Then Toggle Off AllMembers, Remove From Members, And Don't Add User To RemoveMembers
                //         else if (!!AllMembers && GroupMembers.length === NumMembers) {
                //             SelectedGroups[ObjectKey].AllMembers = false;
                //             SelectedGroups[ObjectKey].Members.splice(SelectedGroups[ObjectKey].Members.indexOf(UserId), 1);
                //         }
                //     // If All Members And All Members Not Showing, Leave AllMembers On, And Add To RemoveMembers
                //         else if (!!AllMembers && GroupMembers.length < NumMembers) SelectedGroups[ObjectKey] = { ...SelectedGroups[ObjectKey], RemoveMembers: [ ...SelectedGroups[ObjectKey].RemoveMembers, UserId ] };
                //     // If Not All Members, And Member Length > 1, Remove From Members
                //         else if (!AllMembers && Members.length > 1) SelectedGroups[ObjectKey].Members.splice(SelectedGroups[ObjectKey].Members.indexOf(UserId), 1);
                //     // If Not All Members, And Member Length === 1, Delete The Group
                //         else if (!AllMembers && Members.length === 1) delete SelectedGroups[ObjectKey];

                //     return null;
                // });
            // }

        // this.setState({ SelectedClientIds, SelectedClients }, () => this.onUpdateAllGroups());
    }

    onUpdateAllGroups = () => {
        // var { AllPrivateGroupIds, Groups } = this.state;
        // var SelectedClients = [ ...this.state.SelectedClients ];

        // SelectedClients.map(User => {
        //     User.UserGroups.map(GroupId => {
        //         var ObjectKey = `Private_GroupId-${GroupId}`;
        //         var { GroupImage, GroupName, GroupType, NumMembers } = Groups[AllPrivateGroupIds.indexOf(ObjectKey)];

        //         if (!SelectedGroups.hasOwnProperty(ObjectKey)) SelectedGroups[ObjectKey] = { AllMembers: NumMembers === 1, GroupImage, GroupName, GroupType, Members: NumMembers === 1 ? [] : [ UserId ], RemoveMembers: [] };
        //         else SelectedGroups[ObjectKey] = { AllMembers: SelectedGroups[ObjectKey].Members.length === NumMembers - 1, GroupImage, GroupName, GroupType, Members: [ ...SelectedGroups[ObjectKey].Members, UserId ], RemoveMembers: [] };

        //         return null;
        //     });

        //     return null;
        // })

        console.log('state: ', JSON.parse(JSON.stringify(this.state)))
    }

    onUpdateSelectedGroups = () => {
        var { Groups, SelectedClientIds, SelectedGroups } = this.state;

        var SelectedGroupIds = [ ...this.state.SelectedGroupIds ];
        var NewSelectedGroups = { ...SelectedGroups };
        // console.log('NewSelectedGroups START: ', JSON.parse(JSON.stringify(NewSelectedGroups)));

        Groups.map(({ GroupId, GroupImage, GroupMembers: { GroupMembers }, GroupName, GroupType, NumMembers }) => {
            if (GroupType !== 'Private') return null;

            var ObjectKey = `${GroupType}_GroupId-${GroupId}`;
            if (!NewSelectedGroups.hasOwnProperty(ObjectKey)) NewSelectedGroups[ObjectKey] = { AllMembers: false, GroupImage, GroupName, GroupType, Members: [], RemoveMembers: [] };

            GroupMembers.map(({ UserId }) => {
                var MembersIndex = NewSelectedGroups[ObjectKey].Members.indexOf(+UserId);

                // Not In Selected Clients ... Remove from Members and Add To RemoveMembers --> If AllMembers = true AND GroupMembers.length !== NumMembers Else Set AllMembers = false
                    if (SelectedClientIds.indexOf(+UserId) === -1) {
                        if (MembersIndex !== -1) NewSelectedGroups[ObjectKey].Members.splice(MembersIndex, 1);
                        if (!!NewSelectedGroups[ObjectKey].AllMembers && NewSelectedGroups[ObjectKey].RemoveMembers.indexOf(+UserId) === -1) {
                            // All Users Currently Showing, So Set AllMembers = false and don't add to Remove Members
                                if (GroupMembers.length === NumMembers) NewSelectedGroups[ObjectKey] = { ...NewSelectedGroups[ObjectKey], AllMembers: false, RemoveMembers: [] };
                                else NewSelectedGroups[ObjectKey].RemoveMembers.push(+UserId);
                        }
                    }
                // In Selected Clients ... Add To Members
                    else {
                        if (MembersIndex === -1) {
                            // Everybody in group is now selected, So Remove Members and Select Group
                                if (NewSelectedGroups[ObjectKey].Members.length === NumMembers - 1) {
                                    NewSelectedGroups[ObjectKey] = { AllMembers: true, GroupImage, GroupName, GroupType, Members: [], RemoveMembers: [] };
                                }
                            // Add User To Members
                                else NewSelectedGroups[ObjectKey].Members.push(+UserId);
                        }
                    }

                return null;
            })

            // If Group Doesn't Have AllMembers, or Members or Remove Members, Then Delete From SelectedGroups
                if (!!!NewSelectedGroups[ObjectKey].AllMembers && !!!NewSelectedGroups[ObjectKey].Members.length && !!!NewSelectedGroups[ObjectKey].RemoveMembers.length) delete NewSelectedGroups[ObjectKey];

            // Update SelectedGroupIds
                var SelectedGroupIdsIndex = SelectedGroupIds.indexOf(+GroupId);
                if (NewSelectedGroups[ObjectKey] && NewSelectedGroups[ObjectKey].AllMembers && SelectedGroupIdsIndex === -1) SelectedGroupIds.push(+GroupId);
                else if (NewSelectedGroups[ObjectKey] && !NewSelectedGroups[ObjectKey].AllMembers && SelectedGroupIdsIndex !== -1) SelectedGroupIds.splice(SelectedGroupIdsIndex, 1);

            return null;
        });

        // console.log('NewSelectedGroups END: ', JSON.parse(JSON.stringify(NewSelectedGroups)));
        // console.log('SelectedGroupIds: ', JSON.parse(JSON.stringify(SelectedGroupIds)));
        this.setState({ SelectedGroupIds, SelectedGroups: NewSelectedGroups });
    }

    renderGroups = () => {
        var { t } = this.props;
        var { HideSelectAll, TryingSearchGroups } = this.props;
        var { GetOtherGroups, Groups, Groups_IsMoreResults, SelectedGroups } = this.state;

        var NumGroups = Groups.length;

        return (
            <>
                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('Groups')} TextAlign="center" />
                    
                <Spacer Size="small" />

                {
                    Groups.map((GroupDetails, GroupIndex) => {
                        var GroupId = +GroupDetails.GroupId;
                        var { GroupType } = GroupDetails;
                        var ObjectKey = `${GroupType}_GroupId-${GroupId}`;
                        var Selected = SelectedGroups.hasOwnProperty(ObjectKey) && !!SelectedGroups[ObjectKey].AllMembers;
                        var SelectedExcept = Selected && !!+SelectedGroups[ObjectKey].RemoveMembers.length;
                        var SelectedMembers = SelectedGroups.hasOwnProperty(ObjectKey) ? SelectedGroups[ObjectKey].Members : [];
                        var SelectedRemoveMembers = SelectedGroups.hasOwnProperty(ObjectKey) ? SelectedGroups[ObjectKey].RemoveMembers : [];
            
                        return (
                            <React.Fragment key={`${GroupType}_${GroupId}`}>
                                <Group
                                    GetOtherGroups={GetOtherGroups}
                                    Group={GroupDetails}
                                    HideSelectAll={HideSelectAll}
                                    OnLoadedNewUsers={Users => this.onLoadedNewGroupUsers({ GroupId, GroupIndex, Users })}
                                    Selected={Selected}
                                    SelectedExcept={SelectedExcept}
                                    SelectedMembers={SelectedMembers}
                                    SelectedRemoveMembers={SelectedRemoveMembers}
                                    OnSelectGroup={() => this.onSelectGroupTeam({ GroupId, GroupIndex })}
                                    OnSelectUser={User => this.onSelectGroupTeamUser({ GroupId, GroupIndex, NumMembers: GroupDetails.NumMembers, User })}
                                    ToggleOpen={GroupIndex === 0}
                                />
                                
                                {GroupIndex < NumGroups - 1 && <Spacer Size="extra-small" />}
            
                                {
                                    GroupIndex === NumGroups - 1 && !!+Groups_IsMoreResults &&
                                    <>
                                        <Spacer Size="medium" />
                                        
                                        <LoadMoreContainer>
                                            <Button
                                                BackgroundColor="white"
                                                BackgroundColor_Hover="blue-abel"
                                                Border="2px solid var(--white-concrete)"
                                                Border_Hover="2px solid var(--blue-abel)"
                                                Disabled={TryingSearchGroups}
                                                FontColor="black"
                                                FontColor_Hover="white"
                                                FontFamily="semibold"
                                                FontSize="medium-1"
                                                OnClick={() => this.onLoadGroups(true)}
                                                Size="medium"
                                                Title={TryingSearchGroups ? `${t('loading')}...` : t('load_more')}
                                            />
                                        </LoadMoreContainer>
                                    </>
                                }
                            </React.Fragment>
                        );
                    })
                }
            </>
        );
    }

    renderTrainerClients = () => {
        var { t } = this.props;
        var { SelectedClientIds, TrainerClients, TrainerClients_IsMoreResults, TrainerClients_TotalRecords, TrainerClientsPageNo, TrainerClientsPageSize } = this.state;

        return (
            <>
                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('all_clients')} TextAlign="center" />
                    
                <Spacer Size="small" />

                <StyledTrainerClientsPaginatedList
                    CompletedFirstSearch={true}
                    HideNoResults
                    HideViewCount
                    IsLoading={false}
                    ItemDescriptionType={t('ptadmin_navigation_clients').toLowerCase()}
                    ItemId="UserId"
                    ItemName="UserName"
                    ItemProps={{
                        HasBorder: true,
                        OnSelectMember: User => this.onSelectClient(User)
                    }}
                    ItemRenderer={GroupMemberListItem}
                    Items={TrainerClients}
                    HasMoreItems={!!+TrainerClients_IsMoreResults}
                    LoadMore={() => this.onLoadTrainerClients(true)}
                    NewSearch={false}
                    NoItemsText={t('search_noresults')}
                    PageNo={TrainerClientsPageNo}
                    PageSize={TrainerClientsPageSize}
                    SelectedItemIds={SelectedClientIds}
                    TotalRecords={TrainerClients_TotalRecords}
                />
            </>
        );
    }

    renderTrainerClientsOld = () => {
        var { t } = this.props;
        var { MaxClients, TrainerGroups } = this.props;

        var NumGroups = TrainerGroups.length;

        return TrainerGroups.map(({ Clients, GroupId, GroupName }, GroupIndex) => {
            var NumClients = Clients.length;
            var GroupSelectedIndex = this.state.SelectedGroupIds.indexOf(Number(GroupId));
            var ClientCount = <PageText FontFamily="semibold" FontSize="medium-1" NoMargin NoWrap Text={`${NumClients} ${NumClients === 1 ? t('ptadmin_clients_singular').toLowerCase() : t('ptadmin_clients_plural').toLowerCase()}`} TextAlign="right" />;

            var BorderColor = GroupSelectedIndex > -1 ? 'var(--blue-abel)' : 'var(--white-concrete)';

            return (
                <React.Fragment key={GroupId}>
                    <Accordion
                        BackgroundColor="var(--white)"
                        BorderColor={BorderColor}
                        HeaderData={{ GroupName, NumClients, OnSelectGroup: () => this.onSelectGroup(GroupIndex, GroupId), SelectedIndex: GroupSelectedIndex }}
                        HeaderRenderer={+MaxClients !== 1 ? ChooseClientHeader : null}
                        RightItem={ClientCount}
                        Text={GroupName}
                        ToggleOpen={GroupIndex === 0}
                    >
                        <ClientGroupContainer>
                            {
                                Clients.map(({ Sex, UserId, UserPhoto, UserName }) => {
                                    var SelectedIndex = this.state.SelectedClientIds.indexOf(UserId);

                                    return (
                                        <GroupClientContainer key={UserId} onClick={() => this.onSelectClient({ Sex, UserId, UserPhoto, UserName }, SelectedIndex)} Selected={SelectedIndex > -1}>
                                            <ProfilePhoto Sex={Sex} Size="medium" Source={UserPhoto || ''} />
                                            <StyledClientName FontFamily="medium" FontSize="medium-1" NoMargin Text={UserName} JustifyContent="flex-start" />
                                        </GroupClientContainer>
                                    );
                                })
                            }
                        </ClientGroupContainer>
                    </Accordion>
                    
                    {GroupIndex < NumGroups - 1 && <Spacer Size="extra-small" />}
                </React.Fragment>
            );
        });
    }

    renderModalBody = () => {
        if (this.state.Loading || this.props.TryingGetChooseClients) return <Loading />;

        var { t } = this.props;
        var { TryingSearchGroupMembers, TryingSearchGroups, TryingSearchTrainerClients } = this.props;

        var ChooseText = 'ptadmin_chooseclients';

        return (
            <>
                {(TryingSearchGroupMembers || TryingSearchGroups || TryingSearchTrainerClients) && <Loading />}

                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t(ChooseText)} TextAlign="center" />

                <Spacer Size="medium" />

                <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('ptadmin_blocked_icon_no_access')} TextAlign="center" />

                <Spacer Size="small" />

                {this.renderTrainerClients()}

                <Spacer Size="medium" />

                {this.renderGroups()}
            </>
        );
    }

    render() {
        var { t } = this.props;
        var { OnHide, Show, TryingGetTrainerClients } = this.props;

        return (
            <AbelModal
                BottomButton="submit"
                BottomButtonOnClick={this.onSelectClients}
                BottomButtonText={t('select_with_first_char_uppercase')}
                Disabled={TryingGetTrainerClients}
                GA_PathName="ChooseClients"
                Show={Show}
                Size="large"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

ChooseClients.propTypes = {
    EligibleTypes: PropTypes.string,
    GetStartedEligible: PropTypes.string,
    HideSelectAll: PropTypes.bool,
    MaxClients: PropTypes.number,
    OnHide: PropTypes.func.isRequired,
    OnSelectClients: PropTypes.func.isRequired,
    SelectedClientIds: PropTypes.array.isRequired,
    SelectedClients: PropTypes.array.isRequired,
    SelectedGroups: PropTypes.object,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        TrainerClients: state.Clients.TrainerClients,
        TrainerGroups: state.Clients.TrainerGroups,

        TryingGetChooseClients: state.Clients.TryingGetChooseClients,
        TryingGetChooseClientsError: state.Clients.TryingGetChooseClientsError,
        TryingGetTrainerClients: state.Clients.TryingGetTrainerClients,
        TryingGetTrainerClientsError: state.Clients.TryingGetTrainerClientsError,
        TryingSearchTrainerClients: state.Clients.TryingSearchTrainerClients,
        TryingSearchTrainerClientsError: state.Clients.TryingSearchTrainerClientsError,

        TryingSearchGroupMembers: state.Group.TryingSearchGroupMembers,
        TryingSearchGroupMembersError: state.Group.TryingSearchGroupMembersError,

        TryingSearchGroups: state.Groups.TryingSearchGroups,
        TryingSearchGroupsError: state.Groups.TryingSearchGroupsError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearGroups, ClearTrainerClients, GetChooseClients, GetTrainerClients, SearchGroups, SearchTrainerClients } )(ChooseClients));