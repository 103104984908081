import styled from 'styled-components';

import Input from '../Input';
import PageText from '../../Text/PageText';

export const Container = styled.div`
    align-items: center;
    background-color: ${props => props.ReadOnly ? 'var(--white-concrete)' : 'var(--white)'};
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const DataContainer = styled.div`
    display: flex;
    padding: 4px 8px;

    ${
        props => props.HasBorder && 1 === 2 &&
        `
            border-${props.HasBorder}: 2px solid var(--white-concrete);
        `
    }

    :first-child {
        border-right: 2px solid var(--white-concrete);
        flex: 1;
    }
`;

export const StyledInput = styled(Input)`
    width: ${props => props.InputWidth ? props.InputWidth : props.IsTime ? props.IsTime === 'minutes' ? '25px' : '35px' : '50px'};

    > input {
        border: none;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        overflow: hidden;
        padding: 0px;
        text-align: center;
        text-indent: 0px;
    }
`;

export const StyledLabel = styled(PageText)`
    align-items: center;
    color: var(--black);
`;

export const TimeColon = styled.div`
    color: var(--black);
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-medium-2);
`;