import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import ProfilePhoto from "../../ProfilePhoto";
import PageText from "../../Text/PageText";
import Info from "../../../Modals/Info";
import Loading from "../../Loading";

import DeleteX from "../../../Assets/Icons/X_White.png";
import Pencil_White from "../../../Assets/Icons/Pencil_White_Filled.png";
import VideoIcon from "../../../Assets/Icons/Camera_White.png";

import {
  ScheduledCallsContainer,
  ScheduledCallsHeader,
  ClientContainer,
  StyledPageText,
  ClientListContainer,
  DescriptionContainer,
  HeaderRight,
  HeaderLeft,
  ActionButtonIcon,
  ActionButton,
  ButtonTitle,
  UserStatusText,
  // StyledWorkoutImage,
  // WorkoutImageContainer,
} from "./styles";

import { DeleteVideoSession } from "../../../Services/Actions";
import { getDateDifference } from "../../../Functions";
import { workout } from "../../../Utils/constants";

class ScheduledVideoSessions extends Component {
  state = {
    VideoSessionToDelete: null,
  };

  componentDidUpdate() {
    this.renderStartSessionButton();
  }

  onDeleteSession() {
    const { VideoSessionToDelete } = this.state;
    this.setState({ VideoSessionToDelete: null }, () => {
      this.props
        .DeleteVideoSession({ videoSessionId: VideoSessionToDelete })
        .then(() => {
          this.props.isSessionDeleted(true);
        });
    });
  }

  onStartSession(hostUrl) {
    window.open(hostUrl, "_blank");
  }

  onToggleDeleteVideoSessionModal(VideoSessionToDelete) {
    this.setState({ VideoSessionToDelete });
  }

  renderDeleteVideoSessionModal = () => {
    const { VideoSessionToDelete } = this.state;

    if (!!VideoSessionToDelete) {
      const { t } = this.props;

      return (
        <Info
          BottomButton="submit"
          BottomButtonOnClick={() => this.onDeleteSession()}
          BottomButtonText={t("delete_with_first_char_uppercase")}
          Information={t(
            "video_session_are_you_sure_you_want_to_remove_this_session"
          )}
          OnHide={() => this.onToggleDeleteVideoSessionModal(null)}
          Show={!!VideoSessionToDelete}
          Size="small"
          TextAlign="center"
        />
      );
    }
  };

  renderEditSessionButton(session) {
    if (session) {
      const currentDate = moment().subtract(1, "days").format();
      const dateDifferenceInDays = getDateDifference(
        session.Date,
        currentDate,
        "days"
      );
      return dateDifferenceInDays < 0;
    } else return false;
  }

  renderStartSessionButton(session) {
    if (session) {
      const currentDate = moment().format();
      const dateDifferenceInMinutes = getDateDifference(
        currentDate,
        session.Date,
        "minutes"
      );
      // Start session button will be shown before 10 minutes till 30 minutes start of session
      return dateDifferenceInMinutes <= 10 && dateDifferenceInMinutes > -30;
    } else return false;
  }

  render() {
    const { session, t, onEditSession } = this.props;
    if (this.props.TryingGetSingleVideoSession) return <Loading />;

    return (
      <>
        <ScheduledCallsContainer
          key={session.VideoSessionId}
          className="ScheduledCallsContainer"
        >
          <ScheduledCallsHeader className="ScheduledCallsHeader">
            <HeaderLeft>
              <PageText
                FontFamily="bold"
                FontSize="medium-3"
                NoMargin
                JustifyContent="flex-start"
                Text={
                  moment(session.Date).format("DD-MM-YYYY") ===
                  moment().format("DD-MM-YYYY")
                    ? "Today"
                    : moment(session.Date).format("ddd")
                }
                TextAlign="left"
                FontColor={
                  moment(session.Date).format("DD-MM-YYYY") ===
                  moment().format("DD-MM-YYYY")
                    ? "abel-blue"
                    : "black"
                }
              />
              <PageText
                FontFamily="bold"
                FontSize="medium-3"
                NoMargin
                JustifyContent="flex-start"
                Text={moment(session.Date).format("DD MMM YYYY")}
                TextAlign="left"
              />
              <PageText
                FontFamily="medium"
                FontSize="medium-3"
                NoMargin
                JustifyContent="flex-start"
                Text={
                  session.Is24HourFormat
                    ? moment(session.Date).format("HH:mm")
                    : moment(session.Date).format("hh:mm a")
                }
                TextAlign="left"
              />
              <PageText
                FontFamily="medium"
                FontSize="medium-1"
                NoMargin
                JustifyContent="flex-start"
                Text={session && (session.SessionType).toLowerCase() === workout ? t("Workout") : t('Meal')}
                TextAlign="left"
              />
              {/* Notification */}
              {/* <PageText
                FontFamily="medium"
                FontSize="medium-1"
                NoMargin
                JustifyContent="flex-start"
                Text={t("video_session_notify_before", {
                  NotifyBefore:
                    session.NotifyBefore === 0
                      ? "No"
                      : `${
                          session.NotifyBefore
                        } ${session.NotifyPeriod.toLowerCase()} before`,
                })}
                TextAlign="left"
              /> */}
            </HeaderLeft>

            <HeaderRight>
              {this.renderStartSessionButton(session) && (
                <ActionButton
                  className="ActionButton"
                  BackgroundColor="var(--abel-blue)"
                  BackgroundColorHover="var(--abel-blue-hover)"
                  onClick={() => this.onStartSession(session.HostUrl)}
                >
                  <ActionButtonIcon ExtraWidth={true} ImageSrc={VideoIcon} />

                  <ButtonTitle
                    FontFamily="semibold"
                    FontSize="medium-1"
                    Text={t("video_session_start_the_video_meeting")}
                  />
                </ActionButton>
              )}

              {this.renderEditSessionButton(session) && (
                <ActionButton
                  className="ActionButton"
                  BackgroundColor="var(--blue-astronaut)"
                  BackgroundColorHover="var(--blue-astronaut-hover)"
                  onClick={() => onEditSession(session)}
                >
                  <ActionButtonIcon ImageSrc={Pencil_White} />
                </ActionButton>
              )}

              <ActionButton
                className="ActionButton"
                BackgroundColor="var(--warning);"
                BackgroundColorHover="var(--warning-hover)"
                onClick={() =>
                  this.onToggleDeleteVideoSessionModal(session.VideoSessionId)
                }
              >
                <ActionButtonIcon Alt={"Delete"} ImageSrc={DeleteX} />
              </ActionButton>
            </HeaderRight>
          </ScheduledCallsHeader>
          <DescriptionContainer className="DescriptionContainer">
            {/* Skipping workout video session feature for current release */}
            {/* {false && session.WorkoutId && (
              <WorkoutImageContainer className="WorkoutImageContainer">
                <StyledWorkoutImage
                  ImageSrc={session.WorkoutImage}
                  SelfLoading
                />
                <div>
                  {session.WorkoutName && (
                    <PageText
                      FontFamily="medium"
                      FontSize="small"
                      JustifyContent="center"
                      Text={session.WorkoutName}
                      TextAlign="left"
                    />
                  )}
                  {session.WorkoutDuration && (
                    <PageText
                      FontFamily="medium"
                      FontSize="small"
                      JustifyContent="center"
                      Text={`${session.WorkoutDuration} ${t("min_str")}`}
                      TextAlign="left"
                    />
                  )}
                </div>
              </WorkoutImageContainer>
            )} */}
            <PageText
              FontFamily="medium"
              FontSize="small"
              JustifyContent="flex-start"
              Text={session.Description}
              TextAlign="left"
            />
          </DescriptionContainer>
          <PageText
            FontFamily="medium"
            FontSize="medium-1"
            JustifyContent="flex-start"
            Text={t("video_session_are_the_client_coming")}
            TextAlign="left"
          />
          <ClientListContainer>
            {session.Users.map((user) => (
              <ClientContainer key={user.UserId}>
                <ProfilePhoto
                  Sex={user.Gender}
                  Size="medium"
                  Source={user.UserPhoto || ""}
                />
                <StyledPageText
                  FontFamily="medium"
                  FontSize="medium-1"
                  NoMargin
                  Text={user.DisplayName}
                />

                <UserStatusText
                  FontFamily="bold"
                  FontSize="medium-1"
                  IsAccepted={user.Status === "Yes"}
                  Text={
                    user.Status === "Yes"
                      ? t("yes_with_first_char_uppercase")
                      : t("no_with_first_char_uppercase")
                  }
                />
              </ClientContainer>
            ))}
          </ClientListContainer>
        </ScheduledCallsContainer>
        {this.renderDeleteVideoSessionModal()}
      </>
    );
  }
}

ScheduledVideoSessions.propTypes = {
  session: PropTypes.shape({}).isRequired,
  isSessionDeleted: PropTypes.func.isRequired,
  onEditSession: PropTypes.func.isRequired,
};

ScheduledVideoSessions.defaultProps = {};

const mapStateToProps = (state) => {
  return {
    TryingGetSingleVideoSession: state.VideoSession.TryingGetSingleVideoSession,
  };
};

export default withTranslation()(
  connect(mapStateToProps, {
    DeleteVideoSession,
  })(ScheduledVideoSessions)
);
