import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DeliverySite_GetDHCs, DeliverySite_GetCompanies_Basic, DeliverySite_GetDHCDetails, DeliverySite_GetLeaders_Basic, DeliverySite_GetMiddleLeaders_Basic, DeliverySite_GetTopLeaders_Basic, DeliverySite_InsertEditDHC } from '../../../Services/Actions';
import Select from 'react-select';
import Popover from 'react-popover';

import * as styles from './styles';

import ButtonGroup from '../../../Components/Buttons/ButtonGroup';
import Loading from '../../../Components/Loading';
import MultiText from '../../../Components/Text/MultiText';
import PageText from '../../../Components/Text/PageText';
import PaginationContainer from '../../../Components/PaginationCounter';
import ProfilePhoto from '../../../Components/ProfilePhoto';
import Spacer from '../../../Components/Spacer';

import Info from '../../../Modals/Info';

import DownArrow from '../../../Assets/Icons/DownArrow.png';
import InfoIcon from '../../../Assets/Icons/Info.png';
import Plus from '../../../Assets/Icons/PlusSign_White.png';
import UpArrow from '../../../Assets/Icons/UpArrow.png';

import { emailValidator } from '../../../Functions';

const DHC_TABLE_COLUMNS = [
    { Id: 1, SortCol: 'UserId', Title: 'User Id' },
    { Id: 2, SortCol: 'DisplayName', Title: 'Name' },
    { Id: 3, SortCol: null, Title: 'Leader' },
    { Id: 4, SortCol: 'NumEmployees', Title: 'Employees' },
    { Id: 5, SortCol: 'NumPersonalClients', Title: 'Personal Clients' },
    { Id: 6, SortCol: 'NumCompletedSessions', Title: 'Completed Workouts' },
    { Id: 7, SortCol: 'CompletedSessionsPercentage', Title: 'Completion Percentage' }
];

const customStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "initial"
    }),
    input: (provided, state) => ({
        ...provided,
        minWidth: '20%'
    })
}

class DHCsComponent extends React.Component {
    TimeoutId = null;

    state = {
        DisplayName_Previous: '',
        DisplayName: '',
        DHCs: [],
        IsMoreResults: null,
        PageNo: 1,
        PageSize: 10,
        Sorting: {
            SortCol: 'DisplayName',
            SortDir: 'ASC'
        },
        TotalRecords: null,

        Filters: {
            Companies: {
                Companies: [],
                IsLoading: false,
                Loaded: false,
                Selected: []
            },
            Leaders: {
                IsLoading: false,
                Loaded: false,
                Selected: [],
                Users: []
            },
            MiddleLeaders: {
                IsLoading: false,
                Loaded: false,
                Selected: [],
                Users: []
            },
            TopLeaders: {
                IsLoading: false,
                Loaded: false,
                Selected: [],
                Users: []
            }
        },

        PopoverIsOpen_Companies: false,
        PopoverIsOpen_Leaders: false,
        PopoverIsOpen_MiddleLeaders: false,
        PopoverIsOpen_TopLeaders: false,
        PopoverIsOpen_Users: false,

        ShowAddDHCModal: false,
        ShowAddDHCModal_DHCEmail: '',
        ShowAddDHCModal_Leader_UserId: null,
        ShowAddDHCModal_Loading: false,

        ShowDHCDetailModal: false,
        ShowDHCDetailModal_Data: {},
        ShowDHCDetailModal_Loading: false
    }

    componentDidMount() {
        this.onLoadDHCs();
    }

    onAddDHC = () => {
        var { ShowAddDHCModal_DHCEmail: Email, ShowAddDHCModal_Leader_UserId: Leader_UserId } = this.state;

        this.setState({ ShowAddDHCModal_Loading: true }, () => {
            this.props.DeliverySite_InsertEditDHC({ Email, Leader_UserId }).then(() => {
                this.onToggleShowAddDHCModal({ ShowAddDHCModal: false });
                this.onLoadDHCs();
            })
        })
    }

    onBlurFilter_Companies = () => {
        this.setState({ PageNo: 1 }, () => this.onLoadDHCs());
    }

    onBlurFilter_Leaders = () => {
        this.setState({ PageNo: 1 }, () => this.onLoadDHCs());
    }

    onBlurFilter_MiddleLeaders = () => {
        this.setState({ PageNo: 1 }, () => this.onLoadDHCs());
    }

    onBlurFilter_TopLeaders = () => {
        this.setState({ PageNo: 1 }, () => this.onLoadDHCs());
    }

    onBlurSearchDHCs = () => {
        var { DisplayName, DisplayName_Previous } = this.state;

        if (DisplayName !== DisplayName_Previous) {
            this.setState({ DisplayName_Previous: DisplayName, PageNo: 1 }, () => this.onLoadDHCs());
        }
    }

    onChangeAddDHCModal_DHCEmail = event => {
        this.setState({ ShowAddDHCModal_DHCEmail: event.target.value });
    }

    onChangeAddDHCModal_Leader = data => {
        this.setState({ ShowAddDHCModal_Leader_UserId: data.UserId });
    }

    onChangeDHCSearch = event => {
        this.setState({ DisplayName: event.target.value });
    }

    onChangeFilter_Companies = data => {
        if (!data) data = [];
        
        var Filters = { ...this.state.Filters };
        Filters.Companies.Selected = data;

        this.setState({ Filters });
    }

    onChangeFilter_Leaders = data => {
        if (!data) data = [];
        
        var Filters = { ...this.state.Filters };
        Filters.Leaders.Selected = data;

        this.setState({ Filters });
    }

    onChangeFilter_MiddleLeaders = data => {
        if (!data) data = [];
        
        var Filters = { ...this.state.Filters };
        Filters.MiddleLeaders.Selected = data;

        this.setState({ Filters });
    }

    onChangeFilter_TopLeaders = data => {
        if (!data) data = [];
        
        var Filters = { ...this.state.Filters };
        Filters.TopLeaders.Selected = data;

        this.setState({ Filters });
    }

    onFocusFilter_Companies = () => {
        if (!this.state.Filters.Companies.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.Companies = { ...Filters.Companies };
            Filters.Companies.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetCompanies_Basic({}).then(({ Companies }) => {
                    Filters.Companies.IsLoading = false;
                    Filters.Companies.Companies = Companies;
                    Filters.Companies.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onFocusFilter_Leaders = () => {
        if (!this.state.Filters.Leaders.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.Leaders = { ...Filters.Leaders };
            Filters.Leaders.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetLeaders_Basic().then(({ Users }) => {
                    Filters.Leaders.IsLoading = false;
                    Filters.Leaders.Users = Users;
                    Filters.Leaders.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onFocusFilter_MiddleLeaders = () => {
        if (!this.state.Filters.MiddleLeaders.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.MiddleLeaders = { ...Filters.MiddleLeaders };
            Filters.MiddleLeaders.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetMiddleLeaders_Basic().then(({ Users }) => {
                    Filters.MiddleLeaders.IsLoading = false;
                    Filters.MiddleLeaders.Users = Users;
                    Filters.MiddleLeaders.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onFocusFilter_TopLeaders = () => {
        if (!this.state.Filters.TopLeaders.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.TopLeaders = { ...Filters.TopLeaders };
            Filters.TopLeaders.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetTopLeaders_Basic().then(({ Users }) => {
                    Filters.TopLeaders.IsLoading = false;
                    Filters.TopLeaders.Users = Users;
                    Filters.TopLeaders.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onLoadDHCs = Increment => {
        var { DisplayName, Filters: { Companies, Leaders, MiddleLeaders, TopLeaders }, PageNo, PageSize, Sorting: { SortCol, SortDir } } = this.state;

        var CompanyIds = Companies.Selected.length > 0 ? Companies.Selected.map(({ CompanyId }) => CompanyId).join(',') : null;
        var Leader_UserIds = Leaders.Selected.length > 0 ? Leaders.Selected.map(({ UserId }) => UserId).join(',') : null;
        var MiddleLeader_UserIds = MiddleLeaders.Selected.length > 0 ? MiddleLeaders.Selected.map(({ UserId }) => UserId).join(',') : null;
        var TopLeader_UserIds = TopLeaders.Selected.length > 0 ? TopLeaders.Selected.map(({ UserId }) => UserId).join(',') : null;

        if (Increment) PageNo++;

        this.props.DeliverySite_GetDHCs({ CompanyIds, DHCName: DisplayName, Leader_UserIds, MiddleLeader_UserIds, PageNo, PageSize, SortCol, SortDir, TopLeader_UserIds }).then(({ DHCs, IsMoreResults, TotalRecords }) => {
            this.setState({ DHCs, IsMoreResults, TotalRecords });
        });
    }

    onPaginate = ({ NewPageNo }) => {
        if (this.statePageNo !== NewPageNo) this.setState({ PageNo: NewPageNo }, () => this.onLoadDHCs());
    }

    onTogglePopover = PopoverType => {
        this.setState({ [PopoverType]: !this.state[PopoverType] }, () => {
            if (!this.state[PopoverType] && !!this.TimeoutId) clearTimeout(this.TimeoutId);
        });
    }

    onToggleShowAddDHCModal = ({ ShowAddDHCModal }) => {
        if (!ShowAddDHCModal) this.setState({ ShowAddDHCModal, ShowAddDHCModal_DHCEmail: '', ShowAddDHCModal_Leader_UserId: null, ShowAddDHCModal_Loading: false });
        else this.setState({ ShowAddDHCModal });
    }

    onToggleShowDHCDetailModal = ({ DHC_UserId, ShowDHCDetailModal }) => {
        if (!ShowDHCDetailModal) this.setState({ ShowDHCDetailModal, ShowDHCDetailModal_Loading: false });
        else {
            this.setState({ ShowDHCDetailModal, ShowDHCDetailModal_Loading: true }, () => {
                this.props.DeliverySite_GetDHCDetails({ DHC_UserId }).then(({ UserDetails }) => {
                    this.setState({ ShowDHCDetailModal_Data: UserDetails, ShowDHCDetailModal_Loading: false });
                })
            });
        }
    }

    onToggleSorting = ({ SortCol: NewSortCol }) => {
        var { SortCol, SortDir } = this.state.Sorting;

        // SortDir Goes From ASC --> DESC --> Nothing

        // Toggle Sorting Of Already Sorted Column
            if (SortCol === NewSortCol) {
                if (SortDir === 'ASC') SortDir = 'DESC';
                else if (SortDir === 'DESC') {
                    SortDir = '';
                    SortCol = '';
                }
                else if (SortDir === '') SortDir = 'ASC';
            }
        // Start Sorting On New Column
            else {
                SortCol = NewSortCol;
                SortDir = 'ASC';
            }

        this.setState({ PageNo: 1, Sorting: { SortCol, SortDir } }, () => this.onLoadDHCs());
    }

    renderAddDHCModal = () => {
        var { t } = this.props;
        var { ShowAddDHCModal, ShowAddDHCModal_DHCEmail, ShowAddDHCModal_Leader_UserId, ShowAddDHCModal_Loading } = this.state;

        if (ShowAddDHCModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onAddDHC}
                    BottomButtonDisabled={!ShowAddDHCModal_DHCEmail || !emailValidator(ShowAddDHCModal_DHCEmail) || !ShowAddDHCModal_Leader_UserId}
                    BottomButtonText={t('_save')}
                    InformationRenderer={this.renderAddDHCModal_Information}
                    Loading={(ShowAddDHCModal_Loading)}
                    OnHide={() => this.onToggleShowAddDHCModal({ ShowAddDHCModal: false })}
                    Show={ShowAddDHCModal}
                />
            );
        }
    }

    renderAddDHCModal_Information = () => {
        var { Filters: { Leaders }, ShowAddDHCModal_DHCEmail } = this.state;

        return (
            <styles.InsertEditModalContainer className="InsertEditModalContainer">
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text="Add DHC" TextAlign="center" />

                <Spacer Size="extra-small" />

                <styles.SearchInput
                    AutoFocus
                    FontSize="medium-1"
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeAddDHCModal_DHCEmail}
                    Placeholder="DHC email"
                    Size="medium"
                    Type="text"
                    Value={ShowAddDHCModal_DHCEmail}
                />

                <Spacer Size="medium" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text="Choose leader" TextAlign="center" />

                <Select
                    closeMenuOnSelect={false}
                    getOptionLabel={option => option.DisplayName}
                    getOptionValue={option => option.UserId}
                    isLoading={Leaders.IsLoading}
                    isMulti={false}
                    // components={{ ValueContainer }}
                    hideSelectedOptions={true}
                    isSearchable
                    onChange={this.onChangeAddDHCModal_Leader}
                    onFocus={this.onFocusFilter_Leaders}
                    options={Leaders.Users}
                    placeholder="Leaders"
                    styles={customStyles}
                    menuPosition="fixed"
                />
            </styles.InsertEditModalContainer>
        );
    }

    renderDHCs = () => {
        var { DHCs, PageNo, PageSize, Sorting: { SortCol: StateSortCol, SortDir: StateSortDir }, TotalRecords } = this.state;

        return (
            <styles.BodyContainer className="BodyContainer">
                <styles.BodyHeader className="BodyHeader">
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text="DHCs" TextAlign="left" />

                    <styles.BodyHeaderRight className="BodyHeaderRight">
                        <ButtonGroup
                            Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onToggleShowAddDHCModal({ ShowAddDHCModal: true }), Title: "New DHC" }]}
                            ButtonWidth="fit-content"
                            ContainerWidth="fit-content"
                            JustifyContent="center"
                        />
                    </styles.BodyHeaderRight>
                </styles.BodyHeader>

                <Spacer Size="small" />

                {this.renderViewCount()}

                {
                    TotalRecords > 0 &&
                    <>
                        <Spacer Size="small" />

                        {
                            (PageNo !== 1 || PageSize < TotalRecords) &&
                            <>
                                <PaginationContainer OnPaginate={this.onPaginate} PageNo={PageNo} PageSize={PageSize} TotalRecords={TotalRecords} />
        
                                <Spacer Size="medium" />
                            </>
                        }

                        <styles.UsersContainer className="UsersContainer">
                            <styles.UsersTableContainer className="UsersTableContainer">
                                <styles.UsersTable className="UsersTable">
                                    <styles.UsersTableHeader className="UsersTableHeader">
                                        <styles.UsersTableHeaderRow className="UsersTableHeaderRow">
                                            {
                                                DHC_TABLE_COLUMNS.map(({ Id, SortCol, Title }) => {
                                                    return (
                                                        <styles.UsersTableHeaderCell className="UsersTableHeaderCell" key={Id} onClick={SortCol ? () => this.onToggleSorting({ SortCol }) : null}>
                                                            <styles.UsersTableHeaderCellInnerDiv className="UsersTableHeaderCellInnerDiv">
                                                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Title} />
                                                                {SortCol ? StateSortCol === SortCol && <styles.SortingIcon ImageSrc={StateSortDir === 'DESC' ? DownArrow : UpArrow} /> : null}
                                                            </styles.UsersTableHeaderCellInnerDiv>
                                                        </styles.UsersTableHeaderCell>
                                                    );
                                                })
                                            }
                                        </styles.UsersTableHeaderRow>
                                    </styles.UsersTableHeader>
                                    <styles.UsersTableBody className="UsersTableBody">
                                        {
                                            DHCs.map((User, UserIndex) => {
                                                var {
                                                    DisplayName, ProfilePhotoUrl, Sex, UserId,
                                                    Leader_DisplayName, Leader_ProfilePhotoUrl, Leader_Sex,
                                                    NumEmployees, NumPersonalClients,
                                                    NumCompletedSessions, NumWorkouts, CompletedSessionsPercentage
                                                } = User;

                                                return (
                                                    <styles.UsersTableBodyRow className="UsersTableBodyRow" key={UserId}>
                                                        <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                            {UserId}
                                                        </styles.UsersTableBodyCell>
                                                        <styles.UsersTableBodyCell className="UsersTableBodyCell" onClick={() => this.onToggleShowDHCDetailModal({ DHC_UserId: UserId, ShowDHCDetailModal: true })}>
                                                            <styles.UsersTableBodyCellFlexed className="UsersTableBodyCellFlexed">
                                                                <styles.ProfilePhotoContainer className="ProfilePhotoContainer">
                                                                    <ProfilePhoto Sex={Sex || 'Male'} Size="small-3" Source={ProfilePhotoUrl || ''} />
                                                                </styles.ProfilePhotoContainer>
                                                                {DisplayName}
                                                            </styles.UsersTableBodyCellFlexed>
                                                        </styles.UsersTableBodyCell>
                                                        <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                            <styles.UsersTableBodyCellFlexed className="UsersTableBodyCellFlexed">
                                                                <styles.ProfilePhotoContainer className="ProfilePhotoContainer">
                                                                    <ProfilePhoto Sex={Leader_Sex || 'Male'} Size="small-3" Source={Leader_ProfilePhotoUrl || ''} />
                                                                </styles.ProfilePhotoContainer>
                                                                {Leader_DisplayName}
                                                            </styles.UsersTableBodyCellFlexed>
                                                        </styles.UsersTableBodyCell>
                                                        <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                            {NumEmployees}
                                                        </styles.UsersTableBodyCell>
                                                        <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                            {NumPersonalClients}
                                                        </styles.UsersTableBodyCell>
                                                        <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                            {NumCompletedSessions} / {NumWorkouts}
                                                        </styles.UsersTableBodyCell>
                                                        <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                            {CompletedSessionsPercentage}%
                                                        </styles.UsersTableBodyCell>
                                                    </styles.UsersTableBodyRow>
                                                )
                                            })
                                        }
                                    </styles.UsersTableBody>
                                </styles.UsersTable>
                            </styles.UsersTableContainer>
                        </styles.UsersContainer>
                    </>
                }
            </styles.BodyContainer>
        );
    }

    renderFilters = () => {
        var { t } = this.props;

        const popoverProps_Companies = {
            isOpen: this.state.PopoverIsOpen_Companies,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_Companies'),
            body: this.renderPopoverContent('Companies'),
            tipSize: 0.01
        }

        const popoverProps_Leaders = {
            isOpen: this.state.PopoverIsOpen_Leaders,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_Leaders'),
            body: this.renderPopoverContent('Leaders'),
            tipSize: 0.01
        }

        const popoverProps_MiddleLeaders = {
            isOpen: this.state.PopoverIsOpen_MiddleLeaders,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_MiddleLeaders'),
            body: this.renderPopoverContent('MiddleLeaders'),
            tipSize: 0.01
        }

        const popoverProps_TopLeaders = {
            isOpen: this.state.PopoverIsOpen_TopLeaders,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_TopLeaders'),
            body: this.renderPopoverContent('TopLeaders'),
            tipSize: 0.01
        }

        const popoverProps_Users = {
            isOpen: this.state.PopoverIsOpen_Users,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_Users'),
            body: this.renderPopoverContent('Users'),
            tipSize: 0.01
        }

        var { DisplayName, Filters: { Companies, Leaders, MiddleLeaders, TopLeaders }} = this.state;

        return (
            <styles.FiltersContainer className="FiltersContainer">
                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Filters" TextAlign="left" />

                <Spacer Size="extra-small" />

                <styles.FiltersSubContainer className="FiltersSubContainer">
                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Search DHCs" />

                            <Popover {...popoverProps_Users}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_Users')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_Users')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <styles.SearchUsersInput
                            FontSize="medium-1"
                            NoLabel
                            NoMargin
                            OnBlur={this.onBlurSearchDHCs}
                            OnChange={this.onChangeDHCSearch}
                            Placeholder={t('_search')}
                            Size="medium"
                            Type="text"
                            Value={DisplayName}
                        />
                    </styles.FilterContainer>

                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Companies" />

                            <Popover {...popoverProps_Companies}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_Companies')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_Companies')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            getOptionLabel={option => option.CompanyName}
                            getOptionValue={option => option.CompanyId}
                            isLoading={Companies.IsLoading}
                            isMulti
                            // components={{ ValueContainer }}
                            hideSelectedOptions={true}
                            isSearchable
                            onBlur={this.onBlurFilter_Companies}
                            onChange={data => this.onChangeFilter_Companies(data)}
                            onFocus={this.onFocusFilter_Companies}
                            options={Companies.Companies}
                            placeholder={t('Filter by Company')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>

                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Leaders" />

                            <Popover {...popoverProps_Leaders}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_Leaders')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_Leaders')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            getOptionLabel={option => option.DisplayName}
                            getOptionValue={option => option.UserId}
                            isLoading={Leaders.IsLoading}
                            isMulti
                            // components={{ ValueContainer }}
                            hideSelectedOptions={true}
                            isSearchable
                            onBlur={this.onBlurFilter_Leaders}
                            onChange={data => this.onChangeFilter_Leaders(data)}
                            onFocus={this.onFocusFilter_Leaders}
                            options={Leaders.Users}
                            placeholder={t('Filter by Leader')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>

                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Middle Leaders" />

                            <Popover {...popoverProps_MiddleLeaders}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_MiddleLeaders')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_MiddleLeaders')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            defaultValue={[]}
                            getOptionLabel={option => option.DisplayName}
                            getOptionValue={option => option.UserId}
                            isLoading={MiddleLeaders.IsLoading}
                            isMulti
                            // components={{ ValueContainer }}
                            hideSelectedOptions={false}
                            isSearchable
                            onBlur={this.onBlurFilter_MiddleLeaders}
                            onChange={data => this.onChangeFilter_MiddleLeaders(data)}
                            onFocus={this.onFocusFilter_MiddleLeaders}
                            options={MiddleLeaders.Users}
                            placeholder={t('Filter by Middle Leader')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>

                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Top Leaders" />

                            <Popover {...popoverProps_TopLeaders}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_TopLeaders')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_TopLeaders')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            defaultValue={[]}
                            getOptionLabel={option => option.DisplayName}
                            getOptionValue={option => option.UserId}
                            isLoading={TopLeaders.IsLoading}
                            isMulti
                            // components={{ ValueContainer }}
                            hideSelectedOptions={false}
                            isSearchable
                            onBlur={this.onBlurFilter_TopLeaders}
                            onChange={data => this.onChangeFilter_TopLeaders(data)}
                            onFocus={this.onFocusFilter_TopLeaders}
                            options={TopLeaders.Users}
                            placeholder={t('Filter by Top Leader')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>
                </styles.FiltersSubContainer>
            </styles.FiltersContainer>
        );
    }

    renderPopoverContent = ContentType => {
        // var { t } = this.props;

        return (
            <styles.PopoverContent className="PopoverContent">
                {ContentType === 'Companies' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Shows DHCs who have ACTIVE orders with employees in the selected companies" TextAlign="center" />}
                {ContentType === 'Leaders' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Shows all DHCs who have a leader in the selected leaders" TextAlign="center" />}
                {ContentType === 'MiddleLeaders' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Shows all DHCs who have a leader who has a middle leader in the selected middle leaders" TextAlign="center" />}
                {ContentType === 'TopLeaders' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Shows all DHCs who have a leader who has a middle leader who has a top leader in the selected top leaders" TextAlign="center" />}
                {ContentType === 'Users' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Search by first name, last name, full name or email address of the DHC" TextAlign="center" />}
            </styles.PopoverContent>
        );
    }

    renderShowDHCDetailModal = () => {
        // var { t } = this.props;
        var { ShowDHCDetailModal, ShowDHCDetailModal_Loading } = this.state;

        if (ShowDHCDetailModal) {
            return (
                <Info
                    BottomButton="cancel"
                    InformationRenderer={this.renderShowDHCDetailModal_Information}
                    Loading={ShowDHCDetailModal_Loading}
                    OnHide={() => this.onToggleShowDHCDetailModal({ ShowDHCDetailModal: false })}
                    Show={ShowDHCDetailModal}
                    Size="small"
                />
            );
        }
    }

    renderShowDHCDetailModal_Information = () => {
        var { ShowDHCDetailModal_Data: UserDetails } = this.state;

        if (!UserDetails || Object.keys(UserDetails).length === 0) return null;

        var { BankAccountNo, DisplayName, DOB, Email, Employees, Leader, PersonalClients, PhoneNumber, ProfilePhotoUrl, Sex } = UserDetails;

        return (
            <styles.DHCDetailModalInfoContainer className="DHCDetailModalInfoContainer">
                <styles.ProfilePhotoContainer_Popup className="ProfilePhotoContainer_Popup">
                    <ProfilePhoto Sex={Sex || 'Male'} Size="extra-large" Source={ProfilePhotoUrl || ''} />
                </styles.ProfilePhotoContainer_Popup>

                <Spacer Size="extra-small" />

                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin TextAlign="left" Text={DisplayName} />
                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Email} />

                {
                    PhoneNumber &&
                    <>
                        <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={PhoneNumber} />
                    </>
                }

                <Spacer Size="extra-small" />

                <MultiText
                    Texts={[
                        { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: 'Responsible for' },
                        { FontColor: 'black', FontFamily: 'semibold-italic', FontSize: 'medium-1', Text: Employees.length },
                        { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: 'active employees' }
                    ]}
                />

                <MultiText
                    Texts={[
                        { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: 'Responsible for' },
                        { FontColor: 'black', FontFamily: 'semibold-italic', FontSize: 'medium-1', Text: PersonalClients.NumClients },
                        { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: 'personal clients' }
                    ]}
                />

                <styles.StyledHR />

                {
                    Employees.length > 0 &&
                    <>
                        <div>List companies and employee count</div>

                        <styles.StyledHR />
                    </>
                }

                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Leader" />
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Leader.DisplayName} />

                <styles.StyledHR />

                <styles.DHCDetailModalInfoFlexContainer className="DHCDetailModalInfoFlexContainer">
                    <MultiText
                        Texts={[
                            { FontColor: 'black', FontFamily: 'semibold-italic', FontSize: 'medium-1', Text: 'Sex:' },
                            { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: Sex }
                        ]}
                    />
                </styles.DHCDetailModalInfoFlexContainer>
                <styles.DHCDetailModalInfoFlexContainer className="DHCDetailModalInfoFlexContainer">
                    <MultiText
                        Texts={[
                            { FontColor: 'black', FontFamily: 'semibold-italic', FontSize: 'medium-1', Text: 'Birthday:' },
                            { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: DOB }
                        ]}
                    />
                </styles.DHCDetailModalInfoFlexContainer>
                <styles.DHCDetailModalInfoFlexContainer className="DHCDetailModalInfoFlexContainer">
                    <MultiText
                        Texts={[
                            { FontColor: 'black', FontFamily: 'semibold-italic', FontSize: 'medium-1', Text: 'Bank Account Number:' },
                            { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: BankAccountNo }
                        ]}
                    />
                </styles.DHCDetailModalInfoFlexContainer>
            </styles.DHCDetailModalInfoContainer>
        )
    }

    renderViewCount = () => {
        var { t } = this.props;

        var { PageNo, PageSize, TotalRecords } = this.state;

        if (!TotalRecords) TotalRecords = 0;

        return (
            <div className="ViewCount">
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${!TotalRecords ? 0 : ((PageNo - 1) * PageSize) + 1} - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                &nbsp;
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords} DHCs`} />
            </div>
        )
    }

    render() {
        // var { t } = this.props;

        return (
            <>
                {this.props.TryingDeliverySiteAction && <Loading />}

                {this.renderFilters()}
                {this.renderDHCs()}

                {this.renderAddDHCModal()}
                {this.renderShowDHCDetailModal()}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        TryingDeliverySiteAction: state.DeliverySite.TryingDeliverySiteAction,
        TryingDeliverySiteActionError: state.DeliverySite.TryingDeliverySiteActionError
    };
};

export default withTranslation()(connect(mapStateToProps, { DeliverySite_GetDHCs, DeliverySite_GetCompanies_Basic, DeliverySite_GetDHCDetails, DeliverySite_GetLeaders_Basic, DeliverySite_GetMiddleLeaders_Basic, DeliverySite_GetTopLeaders_Basic, DeliverySite_InsertEditDHC } )(DHCsComponent));