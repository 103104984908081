import React from "react";

import { BETA_SITE_URL } from '../../Config';

import Loading from '../../Components/Loading';

class SessionRestore extends React.Component {
    componentDidMount() {
        var awsToken = new URLSearchParams(window.location.search).get("awsToken");
        var refreshToken = new URLSearchParams(window.location.search).get("refreshToken");
        var redirect = new URLSearchParams(window.location.search).get("redirect");
        var tab = new URLSearchParams(window.location.search).get("tab");

        // No Token, Redirect Back To Beta Login
            if (!awsToken || !refreshToken) window.location.href = `${BETA_SITE_URL}/login`;
        // Has Token ... Save Token & Redirect
            else {
                let redirectUrl = `/${redirect}`;

                if (tab) redirectUrl += `/${tab}`;

                localStorage.setItem("accessToken", awsToken);
                localStorage.setItem("refreshToken", refreshToken);

                window.location.href = redirectUrl;
            }
    }

    render() {
        return <Loading />
    }
}

export default SessionRestore;