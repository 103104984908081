import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { device } from '../../../Config/device';
import moment from 'moment';
import { BETA_SITE_URL } from '../../../Config';

import { DownloadContractPDF, DownloadContractInvoicePDF, GenerateWebInvoiceUrl, UpdateContractProductDeliveredQuantity } from '../../../Services/Actions';

import ButtonGroup from '../../Buttons/ButtonGroup';
import Icon from '../../Icon';
import Input from '../../Form/Input';
import Loading from '../../Loading';
import MultiText from '../../Text/MultiText';
import PageText from '../../Text/PageText';
import ProfilePhoto from '../../ProfilePhoto';
import Spacer from '../../Spacer';

import DeleteX from '../../../Assets/Icons/Delete_X.png';
import DownArrow from '../../../Assets/Icons/DownArrow.png';
import Pending from '../../../Assets/Icons/Pending.png';
import PDF from '../../../Assets/Icons/PDF.png';
import ThumbsDown from '../../../Assets/Icons/ThumbsDown.png';
import ThumbsUp from '../../../Assets/Illustrations/ThumbsUp.png';
import UpArrow from '../../../Assets/Icons/UpArrow.png';

const BodyContainer = styled.div`
    width: 100%;
`;

const ClientContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media ${device.maxwidth.mobile} {
        margin-bottom: 10px;
    }
`;

const ContractAcceptanceContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const ContractAcceptanceIcon = styled(Icon)`
    margin-right: 10px;

    > img {
        width: 25px;
    }
`;

const ContractContainer = styled.div`
    align-items: center;
    border: 2px solid ${props => props.ContractStatus === 'Completed' ? 'var(--green-success)' : props.ContractStatus === 'Pending' ? 'var(--orange-selectiveyellow)' : (props.ContractStatus === 'Rejected' || props.ContractStatus === 'Cancelled') ? 'var(--red-bittersweet)' : 'var(--white-concrete)'};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px 21px;

    :not(:last-child) {
        margin-bottom: 15px;
    }
`;

const DetailText = styled.div`
    ${props => props.FontColor && `color: var(--${props.FontColor});`}
    font-family: ${props => props.FontFamily ? `var(--font-family-${props.FontFamily})` :  'var(--font-family-medium)'};
    font-size: var(--font-size-small);
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${props => props.Underline && 'text-decoration: underline;'}
`;

const DownloadContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

const HeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const HeaderDetailsContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const InvoiceContainer = styled.div`
    border: 2px solid ${props => props.PaymentStatus === 'Paid' ? 'var(--green-success)' : (props.IsLate || props.PaymentStatus === 'Failed' || props.ContractStatus === 'Cancelled') ? 'var(--red-bittersweet)' : 'var(--white-concrete)'};
    border-radius: 10px;
    padding: 13px 17px;
`;

const InvoicesContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`;

const MadeWithOrganizationContainer = styled.div`
    margin: 10px 0px;
`;

const OnTimeContainer = styled.div``;

const OrgNameContainer = styled.div`
    margin-left: 25px;
`;

const ProductsListBlockContainer = styled.div`
    margin-right: 70px;
    margin-top: 30px;
`;

const ProductsListBlockRowContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 30px;

    > div:first-child {
        margin-right: 5px;
    }
`;

const ProductsListBlocksContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -70px;
    margin-top: -30px;
`;

const ProductsListTableContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

const ProductsListTableRowContainer = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 3fr;
    width: 100%;

    :not(:last-child) {
        margin-bottom: 10px;
    }
`;

const ProfilePhotoContainer = styled.div`
    margin-right: 10px;
`;

const StyledHR = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    height: 2px;
    margin: 10px 0;
`;

const StyledNumberCompletedInput = styled(Input)`
    margin-bottom: 0px;
    width: 60px;

    > input {
        ${props => props.HasError && 'border-color: var(--red-bittersweet);'}
        border-radius: 10px;
        text-align: center;
        text-indent: 0px;
    }
`;

const StyledPDFIcon = styled(Icon)`
    margin-right: 10px;
    width: 25px;
`;

const ToggleIcon = styled(Icon)`
    > img {
        height: 10px;

        :hover {
            cursor: pointer;
        }
    }
`;

class ContractListItem extends React.Component {
    state = { Contract: {}, Loading: true, OriginalContract: {}, ToggleOpen: false };

    componentDidMount() {
        if (this.props.ItemIndex === 0) this.onToggle();

        this.setState({ Contract: this.props.Item, Loading: false, OriginalContract: { ...this.props.Item } });
    }

    onBlurDeliveredQuantity = ({ ProductListIndex }) => {
        var ContractProduct = { ...this.state.Contract.Products.ProductsList[ProductListIndex] };
        var { DeliveredQuantity, ProductId, ProductQuantity } = ContractProduct;

        DeliveredQuantity = +DeliveredQuantity;
        ContractProduct = { ...ContractProduct, DeliveredQuantity };

        this.setState({ Contract: { ...this.state.Contract, Products: { ...this.state.Contract.Products, ProductsList: Object.assign([ ...this.state.Contract.Products.ProductsList ], { [ProductListIndex]: ContractProduct }) } } });

        var ProductsList = [];

        // Invalid DeliveredQuantity
            if (+DeliveredQuantity < 0 || +DeliveredQuantity > +ProductQuantity) {
                ContractProduct.DeliveredQuantityError = true;
                ProductsList = Object.assign([ ...this.state.Contract.Products.ProductsList ], { [ProductListIndex]: ContractProduct });
                this.setState({ Contract: { ...this.state.Contract, Products: { ...this.state.Contract.Products, ProductsList } } });
            }
        // Valid Quantity, And Different Than Last Saved Quantity
            else if (+DeliveredQuantity !== +this.state.OriginalContract.Products.ProductsList[ProductListIndex].DeliveredQuantity) {
                ContractProduct.DeliveredQuantityError = false;

                this.props.UpdateContractProductDeliveredQuantity({ ContractId: this.state.Contract.ContractId, DeliveredQuantity, ProductId }).then(() => {
                    // Error Updating Delivered Quantity, So Revert To Original
                        if (this.props.TryingUpdateContractProductDeliveredQuantityError) {
                            ContractProduct.DeliveredQuantity = this.state.OriginalContract.Products.ProductsList[ProductListIndex].DeliveredQuantity;
                            ProductsList = Object.assign([ ...this.state.Contract.Products.ProductsList ], { [ProductListIndex]: ContractProduct });
                            this.setState({ Contract: { ...this.state.Contract, Products: { ...this.state.Contract.Products, ProductsList } } });
                        }
                    // Updated Successfully, So Update Delivered Quantity In Original, And DeliveredQuantityError In Contract
                        else {
                            ProductsList = Object.assign([ ...this.state.Contract.Products.ProductsList ], { [ProductListIndex]: ContractProduct });
                            var OriginalProductsList = Object.assign([ ...this.state.OriginalContract.Products.ProductsList ], { [ProductListIndex]: ContractProduct });
                            this.setState({
                                Contract: { ...this.state.Contract, Products: { ...this.state.Contract.Products, ProductsList } },
                                OriginalContract: { ...this.state.OriginalContract, Products: { ...this.state.OriginalContract.Products, ProductsList: OriginalProductsList } }
                            });
                        }
                })
            }
        // Valid Quantity, And Same As Last Saved (Remove Error If There Is One)
            else if (ContractProduct.DeliveredQuantityError) {
                ContractProduct.DeliveredQuantityError = false;
                ProductsList = Object.assign([ ...this.state.Contract.Products.ProductsList ], { [ProductListIndex]: ContractProduct });
                this.setState({ Contract: { ...this.state.Contract, Products: { ...this.state.Contract.Products, ProductsList } } });
            }
    }

    onChangeDeliveredQuantity = ({ event, ProductListIndex }) => {
        var ContractProduct = { ...this.state.Contract.Products.ProductsList[ProductListIndex] };
        ContractProduct.DeliveredQuantity = event.target.value;

        var ProductsList = Object.assign([ ...this.state.Contract.Products.ProductsList ], { [ProductListIndex]: ContractProduct });

        this.setState({ Contract: { ...this.state.Contract, Products: { ...this.state.Contract.Products, ProductsList } } });
    }

    onGenerateWebInvoiceUrl = InvoiceId => {

        this.props.GenerateWebInvoiceUrl({ InvoiceId }).then(({ InvoiceUrl }) => {
            var open = window.open(InvoiceUrl, "_blank");
            if (open == null || typeof(open) == 'undefined') {
                var { t } = this.props;

                alert(`${t('_popup_blocked_copy_link_here')}:\n\n${InvoiceUrl}`);
            }
        })
    }

    onLinkToBetaAdmin = ({ IsBooking }) => {
        var Link = BETA_SITE_URL + `/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=booking?tab=`;

        if (!!IsBooking) Link += `booking`
        else Link += `confirm-delivery`;

        window.open(Link, '_self');
    }

    onToggle = () => {
        this.setState({ ToggleOpen: !this.state.ToggleOpen });
    }

    onViewContractPDF = () => {
        this.props.DownloadContractPDF({ ContractId: this.state.Contract.ContractId });
    }

    onViewContractInvoicePDF = InvoiceId => {
        this.props.DownloadContractInvoicePDF({ InvoiceId });
    }

    renderBody = () => {
        var { t } = this.props;
        var { ContractStatus, Organization } = this.state.Contract;

        return (
            <BodyContainer>
                <StyledHR />

                <Spacer Size="extra-extra-small" />

                {
                    ContractStatus === 'Pending' &&
                    <>
                        <ButtonGroup Buttons={[{ Color: 'red-bittersweet', ColorHover: 'red-bittersweet', FontFamily: 'semibold', Icon: DeleteX, IconPosition: 'left', OnClick: () => this.props.ToggleShowDeleteContractModal({ DeleteContractId: this.state.Contract.ContractId, ShowDeleteContractModal: true }), Size: 'small', Title: t('contract_delete') }]} ButtonWidth="fit-content" NotTouching OwnRows />
                        
                        <Spacer Size="small" />
                    </>
                }

                {this.renderProducts()}

                {
                    !!Organization &&
                    <MadeWithOrganizationContainer>
                        <MultiText
                            Texts={[
                                { FontFamily: 'medium', FontSize: 'medium-1', Text: `${t('collaboration_with')}:` },
                                { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${Organization.OrganizationName} - ${Organization.BranchName}` }
                            ]}
                        />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`(${t('contract_organization_takes_percent_income', { Percent: Organization.OrgIncome })})`} />
                    </MadeWithOrganizationContainer>
                }

                {
                    ContractStatus !== 'Pending' && ContractStatus !== 'Rejected' &&
                    <>
                        <Spacer Size="small" />
                    
                        <DownloadContainer>
                            <StyledPDFIcon ImageSrc={PDF} OnClick={() => this.onViewContractPDF()} />
                            <PageText FontColor="blue-abel" FontColorHover="blue-abel-hover" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onViewContractPDF()} Text={t('download.pdf')} TextAlign="left" />
                        </DownloadContainer>
                    </>
                }

                <StyledHR />

                <Spacer Size="extra-extra-small" />

                {this.renderInvoices()}
            </BodyContainer>
        );
    }

    renderDeliveredInput = ({ DeliveredQuantity, DeliveredQuantityError, ProductListIndex }) => {
        return (
            <StyledNumberCompletedInput
                HasError={!!DeliveredQuantityError}
                NoDecimal
                NoLabel
                OnBlur={() => this.onBlurDeliveredQuantity({ ProductListIndex })}
                OnChange={event => this.onChangeDeliveredQuantity({ event, ProductListIndex })}
                Placeholder="0"
                Type="number"
                Value={DeliveredQuantity}
            />
        );
    }

    renderHeader = () => {
        var { t } = this.props;
        var { ContractStatus, ContractTo: { DisplayName, Gender, ProfilePhotoUrl, UserName }, Organization } = this.state.Contract;
        var { ToggleOpen } = this.state;

        var ToggleImageSrc = ToggleOpen ? UpArrow : DownArrow;

        var AcceptanceIconSrc = ContractStatus === 'Pending' ? Pending : (ContractStatus === 'Rejected' || ContractStatus === 'Cancelled') ? ThumbsDown : ContractStatus === 'Completed' ? ThumbsUp : '';
        var AcceptanceText = ContractStatus === 'Pending' ? t('contract_status_client_pending') : ContractStatus === 'Rejected' ? t('contract_status_client_rejected') : ContractStatus === 'Cancelled' ? t('cancelled') : ContractStatus === 'Completed' ? t('completed') : '';

        return (
            <HeaderContainer>
                <HeaderDetailsContainer>
                    <ClientContainer>
                        {
                            UserName &&
                            <ProfilePhotoContainer>
                                <ProfilePhoto Sex={Gender || 'Male'} ShowOverlay={false} Size="medium" Source={ProfilePhotoUrl} />
                            </ProfilePhotoContainer>
                        }

                        <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={DisplayName} TextAlign="left" />

                        {
                            !!Organization && Organization.OrganizationName &&
                            <OrgNameContainer className="OrgNameContainer">
                                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={Organization.OrganizationName} TextAlign="left" />
                            </OrgNameContainer>
                        }
                    </ClientContainer>

                    {
                        ContractStatus !== 'Agreed' &&
                        <ContractAcceptanceContainer>
                            <ContractAcceptanceIcon ImageSrc={AcceptanceIconSrc} />
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={AcceptanceText} TextAlign="left" />
                        </ContractAcceptanceContainer>   
                    }
                </HeaderDetailsContainer>

                <ToggleIcon ImageSrc={ToggleImageSrc} OnClick={this.onToggle} />
            </HeaderContainer>
        );
    }

    renderInvoices = () => {
        var { t } = this.props;
        var { ContractStatus, Invoices: { InvoicesList } } = this.state.Contract;

        return (
            <InvoicesContainer>
                {
                    InvoicesList.map(({ AmountText, InvoiceSentDate, InvoiceId, IsClientNotified, IsRefunded, PaymentMadeDate, PaymentDueDate, PaymentStatus }, InvoiceIndex) => {                        
                        var DaysLate = PaymentMadeDate ? moment(PaymentDueDate).diff(moment(PaymentMadeDate), 'days') : moment(PaymentDueDate).diff(moment().utc().format('YYYY-MM-DD'), 'days');
                        var IsLate = DaysLate >= 0 ? 0 : 1;

                        return (
                            <InvoiceContainer key={InvoiceId} ContractStatus={ContractStatus} IsLate={IsLate} PaymentStatus={PaymentStatus}>
                                <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={t('contracts_invoice_part_number', { PartNumber: InvoiceIndex + 1 })} TextAlign="left" />
                                <ProductsListBlockRowContainer>
                                    <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={`${t('contracts_invoice_amount')}:`} TextAlign="left" />
                                    <DetailText>{AmountText}</DetailText>
                                </ProductsListBlockRowContainer>
                                <ProductsListBlockRowContainer>
                                    <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={`${t(IsClientNotified ? 'contracts_invoice_sent' : 'contracts_invoice_will_be_sent')}:`} TextAlign="left" />
                                    <DetailText>{moment(InvoiceSentDate || PaymentDueDate).format('[NumbersDate]')}</DetailText>
                                </ProductsListBlockRowContainer>
                                <ProductsListBlockRowContainer>
                                    <PageText FontColor={IsLate && PaymentStatus !== 'Paid' ? 'red-bittersweet' : 'black'} FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={`${t('contracts_invoice_deadline_date')}:`} TextAlign="left" />
                                    <DetailText FontColor={IsLate && PaymentStatus !== 'Paid' ? 'red-bittersweet' : 'black'}>{`${moment(PaymentDueDate).format('[NumbersDate]')}`}</DetailText>
                                </ProductsListBlockRowContainer>
                                {this.renderPaidStatus({ DaysLate, IsLate, IsRefunded, PaymentMadeDate, PaymentStatus })}

                                {
                                    PaymentStatus === 'Paid' &&
                                    <>
                                        <Spacer Size="small" />

                                        <DownloadContainer>
                                            <StyledPDFIcon ImageSrc={PDF} OnClick={() => this.onViewContractInvoicePDF(InvoiceId)} />
                                            <PageText FontColor="blue-abel" FontColorHover="blue-abel-hover" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onViewContractInvoicePDF(InvoiceId)} Text={t('download.pdf')} TextAlign="left" />
                                        </DownloadContainer>
                                    </>
                                }
                                {
                                    PaymentStatus !== 'Paid' && ContractStatus === 'Agreed' &&
                                    <>
                                        <Spacer Size="small" />
                                        
                                        <PageText FontColor="blue-abel" FontColorHover="blue-abel-hover" FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin OnClick={() => this.onGenerateWebInvoiceUrl(InvoiceId)} Text={t('_generate_web_invoice_url')} TextAlign="left" />
                                    </>
                                }
                            </InvoiceContainer>
                        );
                    })
                }
            </InvoicesContainer>
        );
    }

    renderPaidStatus = ({ DaysLate, IsLate, IsRefunded, PaymentMadeDate, PaymentStatus }) => {
        var { t } = this.props;

        // Paid
            if (PaymentStatus === 'Paid') {
                return (
                    <>
                        <ProductsListBlockRowContainer>
                            <PageText FontColor="green-success" FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={`${t('contracts_invoice_paid')}:`} TextAlign="left" Uppercase />
                            <DetailText FontColor="green-success">{moment(PaymentMadeDate).format('[NumbersDate]')}</DetailText>
                        </ProductsListBlockRowContainer>
                        <OnTimeContainer>
                            {
                                IsLate ?
                                <PageText FontColor="orange-selectiveyellow" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={t('contracts_invoice_days_late', { DaysLate: Math.abs(DaysLate) })} TextAlign="left" />
                            :
                                IsRefunded ?
                                <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={t('payment_refunded')} TextAlign="left" />
                            :
                                <PageText FontColor="green-success" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={t('contracts_invoice_on_time')} TextAlign="left" />
                            }
                        </OnTimeContainer>
                    </>
                );
            }
        // Pending
            else if (PaymentStatus === 'Pending') {
                if (IsLate) {
                    return (
                        <>
                            <ProductsListBlockRowContainer>
                                <PageText FontColor="red-bittersweet" FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={t('contracts_invoice_not_paid')} TextAlign="left" Uppercase />
                            </ProductsListBlockRowContainer>
                            <OnTimeContainer>
                                <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={t('contracts_invoice_days_late', { DaysLate: Math.abs(DaysLate) })} TextAlign="left" />
                            </OnTimeContainer>
                        </>
                    );
                }
                
                return (
                    <ProductsListBlockRowContainer>
                        <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={`${DaysLate} ${t('days').toLowerCase()}`} TextAlign="left" />
                        <OnTimeContainer>
                            <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin NoWrap Text={t('contracts_invoice_until_deadline')} TextAlign="left" />
                        </OnTimeContainer>
                    </ProductsListBlockRowContainer>
                );
            }
    }

    renderProducts = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { ContractStatus, Products: { ProductsList, TotalPriceText: TotalProductsPriceText } } = this.state.Contract;

        return (
            <>
                {
                    Device === 'laptop' ?
                    <ProductsListTableContainer>
                        <ProductsListTableRowContainer>
                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('product')} TextAlign="left" />
                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('completed_plural')} TextAlign="left" />
                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('Remaining')} TextAlign="left" />
                        </ProductsListTableRowContainer>
                        {
                            ProductsList.map(({ DeliveredQuantity, IsBooking, ProductId, ProductName, ProductQuantity }) => {
                                return (
                                    <ProductsListTableRowContainer key={ProductId}>
                                        <DetailText>{ProductName}</DetailText>
                                        <DetailText>{`${DeliveredQuantity} / ${ProductQuantity}`}</DetailText>
                                        <DetailText>{`${+ProductQuantity - +DeliveredQuantity}`}</DetailText>
                                        {
                                            ContractStatus === 'Agreed' &&
                                            <div>
                                                <PageText
                                                    FontFamily="medium-italic"
                                                    FontSize="small"
                                                    JustifyContent="flex-start"
                                                    NoMargin
                                                    Text={`${!!IsBooking ? t('_deduct_through_booking_calendar') : t('_deduct_through_confirm_delivery')}.`}
                                                    TextAlign="left"
                                                />
                                                <PageText
                                                    FontColor="abel-blue"
                                                    FontColorHover="abel-blue-hover"
                                                    FontFamily="bold-italic"
                                                    FontSize="small"
                                                    JustifyContent="flex-start"
                                                    NoMargin
                                                    OnClick={() => this.onLinkToBetaAdmin({ IsBooking })}
                                                    Text={t('_take_me_there')}
                                                    TextAlign="left"
                                                />
                                            </div>
                                        }
                                    </ProductsListTableRowContainer>
                                );
                            })
                        }
                    </ProductsListTableContainer>
                :
                    <ProductsListBlocksContainer>
                        {
                            ProductsList.map(({ DeliveredQuantity, IsBooking, ProductId, ProductName, ProductQuantity }) => {
                                return (
                                    <ProductsListBlockContainer key={ProductId}>
                                        <ProductsListBlockRowContainer>
                                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('product')}:`} TextAlign="left" />
                                            <DetailText>{ProductName}</DetailText>
                                        </ProductsListBlockRowContainer>
                                        <ProductsListBlockRowContainer>
                                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('completed_plural')}:`} TextAlign="left" />
                                            <DetailText>{`${DeliveredQuantity} / ${ProductQuantity}`}</DetailText>
                                        </ProductsListBlockRowContainer>
                                        <ProductsListBlockRowContainer>
                                            <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('Remaining')}:`} TextAlign="left" />
                                            <DetailText>{`${+ProductQuantity - +DeliveredQuantity}`}</DetailText>
                                        </ProductsListBlockRowContainer>
                                        <ProductsListBlockRowContainer>
                                            {
                                                ContractStatus === 'Agreed' &&
                                                <div>
                                                    <PageText
                                                        FontFamily="medium-italic"
                                                        FontSize="small"
                                                        JustifyContent="flex-start"
                                                        NoMargin
                                                        Text={`${!!IsBooking ? t('_deduct_through_booking_calendar') : t('_deduct_through_confirm_delivery')}.`}
                                                        TextAlign="left"
                                                    />
                                                    <PageText
                                                        FontColor="abel-blue"
                                                        FontColorHover="abel-blue-hover"
                                                        FontFamily="bold-italic"
                                                        FontSize="small"
                                                        JustifyContent="flex-start"
                                                        NoMargin
                                                        OnClick={() => this.onLinkToBetaAdmin({ IsBooking })}
                                                        Text={t('_take_me_there')}
                                                        TextAlign="left"
                                                    />
                                                </div>
                                            }
                                        </ProductsListBlockRowContainer>
                                    </ProductsListBlockContainer>
                                );
                            })
                        }
                    </ProductsListBlocksContainer>
                }

                <Spacer Size="extra-small" />

                <ProductsListBlockRowContainer>
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('total_price')}:`} TextAlign="left" />
                    <DetailText FontFamily="bold" Underline>{TotalProductsPriceText}</DetailText>
                </ProductsListBlockRowContainer>
            </>
        );
    }

    render() {
        var { TryingDownloadContractPDF, TryingUpdateContractProductDeliveredQuantity } = this.props;
        var { Contract: { ContractStatus }, ToggleOpen } = this.state;

        if (this.state.Loading) return <Loading />;

        return (
            <>
                {(TryingDownloadContractPDF || TryingUpdateContractProductDeliveredQuantity) && <Loading />}

                <ContractContainer className="ContractContainer" ContractStatus={ContractStatus}>
                    {this.renderHeader()}

                    {ToggleOpen && this.renderBody()}
                </ContractContainer>
            </>
        );
    }
}

ContractListItem.propTypes = {
    Item: PropTypes.object
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        TryingDownloadContractPDF: state.Sales.TryingDownloadContractPDF,
        TryingDownloadContractPDFError: state.Sales.TryingDownloadContractPDFError,
        TryingUpdateContractProductDeliveredQuantity: state.Sales.TryingUpdateContractProductDeliveredQuantity,
        TryingUpdateContractProductDeliveredQuantityError: state.Sales.TryingUpdateContractProductDeliveredQuantityError
    };
};

export default withTranslation()(connect(mapStateToProps, { DownloadContractPDF, DownloadContractInvoicePDF, GenerateWebInvoiceUrl, UpdateContractProductDeliveredQuantity })(ContractListItem));