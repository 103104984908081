import styled from 'styled-components';
import { device } from '../../Config/device';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Icon from '../../Components/Icon';
import Input from '../../Components/Form/Input';

export const BodyContainer = styled.div`
    margin: 0 5%;
`;

export const BottomButton = styled(ButtonGroup)`
    > div {
        :first-child {
            border-top-left-radius: 0px
        }

        :last-child {
            border-top-right-radius: 0px;
        }
    }
`;

export const InputContainer = styled.div`
    margin: 0 auto;
    max-width: 500px;
    width: 100%;

    :not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const InputInnerContainer = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 9fr 1fr;
`;

export const InstructionContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
`;

export const InstructionsContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr;

    @media ${device.minwidth.ipad} {
        grid-template-columns: 1fr 1fr;
    }
`;

export const StyledDeleteIcon = styled(Icon)`
    height: 20px;
    margin-left: 10px;
    width: 20px;
`;

export const StyledInput = styled(Input)`
    background-color: var(--white-concrete);
    border: none; 
    border-radius: 10px;

    > input {
        background-color: var(--white-concrete);
    }
`;