import styled from 'styled-components';
import { device } from '../../Config/device';

import Icon from '../../Components/Icon';
import Input from '../../Components/Form/Input';
import PageText from '../../Components/Text/PageText';

export const AcceptedCoachesContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    > div:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const AcceptedCoachesParentContainer = styled.div`

`;

export const AllMembersContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr;

    @media ${device.minwidth.laptop} {
        grid-template-columns: 1.5fr 1fr;
    }
`;

export const CoachContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    width: 100%;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
        justify-content: space-between;
    }

    ${
        props => !!props.IsMember &&
        `
            @media(hover:hover) {
                :hover {
                    // background-color: var(--white-concrete-hover);
                    // cursor: pointer;
                }
            }
        `
    }
`;

export const CoachDetailsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
    overflow: hidden;
`;

export const CoachPictureContainer = styled.div``;

export const DeleteIcon = styled(Icon)`
    height: 20px;
    margin-right: 5px;
    width: 20px;
`;

export const DeleteIconContainer = styled.div``;

export const IncomeChoiceContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media ${device.minwidth.mobile} {
        > div:first-child {
            margin-right: 5px;
        }
    }

    @media ${device.maxwidth.mobile_small} {
        > div:first-child {
            margin-top: 5px;
        }
    }
`;

export const IncomeChoiceInputContainer = styled.div`
    @media ${device.minwidth.mobile} {
        margin-right: 5px;
    }

    @media ${device.maxwidth.mobile_small} {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 7px;

        > div:first-child {
            margin-right: 5px;
        }
    }
`;

export const IncomeChoiceInput = styled(Input)`
    width: 60px;

    > input {
        background-color: ${props => props.BackgroundColor ? `var(--${props.BackgroundColor})` : 'var(--white-concrete)'};
        padding: 0px;
        text-indent: 9px;
    }
`;

export const OptionsContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const OrgIncomeMemberContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 5px;
`;

export const PendingCoachesContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 100%;

    > div:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const PendingCoachesParentContainer = styled.div`

`;

export const ProfilePhotoContainer = styled.div`
    margin-right: 5px;
    width: var(--profilephoto-size-small-2);
`;

export const StyledDisplayName = styled.div`
    max-width: 200px;
    overflow: hidden;

    @media ${device.minwidth.mobile} {
        max-width: 250px;
    }

    @media (min-width: 1126px) {
        max-width: 200px;
    }

    @media (min-width: 1400px) {
        max-width: 350px;
    }
`;

export const StyledEllipsisText = styled(PageText)`
    overflow: hidden;

    > div {    
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const StyledIncomeShareText = styled(PageText)`
    opacity: ${props => props.Opacity};
`;

export const TrainerContractContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border: 2px solid ${props => !!props.BelongsToOrg ? `#${props.BelongsToOrg}` : 'var(--white-concrete)'};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
`;

export const TrainerContractsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const TrainerContractsListContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    width: 100%;
`;