import React from 'react';
import { withTranslation } from 'react-i18next';
import history from '../../../history';

import * as Styles from './styles';

import PageText from '../../../Components/Text/PageText';

const PageTitles = {
    'Income': { Name: '_income', Slug: 'income' },
    'Contracts': { Name: '_contracts', Slug: 'contracts' },
    'Invoices': { Name: '_invoices', Slug: 'invoices' }
}

class Header extends React.Component {
    onChangePageTitle = PageTitleSlug => {
        history.push({ pathname: `/income`, search: `?tab=${PageTitleSlug}` });
    }

    render() {
        var { t } = this.props;
        var { PageTitle } = this.props;

        return (
            <Styles.HeaderContainer className="HeaderContainer">
                <Styles.HeaderLeftContainer className="HeaderLeftContainer">
                    {
                        Object.keys(PageTitles).map((key, PTIndex) => {
                            var { Name, Slug } = PageTitles[key];

                            return (
                                <React.Fragment key={PTIndex}>
                                    <PageText
                                        FontColor="black"
                                        FontFamily={(PageTitle === key) ? 'bold' : 'medium'}
                                        FontFamilyHover="bold"
                                        FontSize="medium-1"
                                        JustifyContent="flex-start"
                                        NoMargin
                                        OnClick={() => this.onChangePageTitle(Slug)}
                                        Selectable
                                        SelectedOptions={{ Position: 'left', Selected: (PageTitle === key) }}
                                        Text={t(Name)}
                                        TextAlign="left"
                                    />
                                </React.Fragment>
                            );
                        })
                    }
                </Styles.HeaderLeftContainer>
            </Styles.HeaderContainer>
        );
    }
}

export default withTranslation()(Header);