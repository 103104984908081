import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearDishDetail, GetDishDetail, SaveDataInProps } from '../../Services/Actions';
import history from '../../history';

import { Container, CookingTimeContainer, CookingTimesAbsoluteContainer, CookingTimesContainer, DishImage, DishImageContainer, IngredientContainer, IngredientListContainer, IngredientNameStyled, IngredientsContainer, InstructionContainer, InstructionsContainer, StyledCopyDish } from './styles';

import AbelModal from '../../Components/Modal';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Edit from '../../Assets/Icons/Pencil_White_Filled.png';
import Plus from '../../Assets/Icons/Plus.png';

class DishDetail extends React.Component {
    state = { Loading: true, LoadingImage: true };

    componentDidMount() {
        this._isMounted = true;

        this.props.GetDishDetail({ DishId: this.props.DishDetail_Preview.DishId }).then(() => {
            if (this._isMounted) this.setState({ DishDetail: this.props.DishDetail, Loading: false });
        });
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        this.props.ClearDishDetail();
    }
    
    onClickCreatePrivateDish = () => {
        this.props.OnHide();
        this.props.SaveDataInProps({ CopyDishDetail: this.props.DishDetail }).then(() => history.push('/library/dish'));
    }

    onClickEditDish = () => {
        this.props.OnHide();
        history.push(`/library/dish/${this.props.DishDetail.DishId}`);
    }

    onLoadedImage = () => {
        this.setState({ LoadingImage: false });
    }

    renderCookingTimes = () => {
        var { t } = this.props;
        var { DishDetail: { ActiveTime, PrepareTime }, TryingGetDishDetail } = this.props;
        var { Loading } = this.state;

        if (Loading || TryingGetDishDetail) return null;

        return (
            <CookingTimesAbsoluteContainer>
                <CookingTimesContainer>
                    <CookingTimeContainer>
                        <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={t('MealPlan_Preparing_time')} />
                        &nbsp;
                        <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={`- ${PrepareTime} ${t('min_str')}`} />
                    </CookingTimeContainer>
                    <CookingTimeContainer>
                        <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={t('MealPlan_Cooking_Time')} />
                        &nbsp;
                        <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={`- ${ActiveTime} ${t('min_str')}`} />
                    </CookingTimeContainer>
                </CookingTimesContainer>
            </CookingTimesAbsoluteContainer>
        );
    }

    renderButtons = () => {
        if (this.state.Loading || this.props.TryingGetDishDetail) return null;
        if (+this.props.DishDetail.DishUserId !== +this.props.UserId) return null;

        var { t } = this.props;
        var { Device } = this.props;

        var Buttons = [{ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: Edit, IconPosition: 'left', OnClick: this.onClickEditDish, Title: t('edit_with_first_char_uppercase') }];

        return (
            <>
                <Spacer Size="medium" />

                <ButtonGroup
                    Buttons={Buttons}
                    ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                    NotTouching
                    OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
                />
            </>
        );
    }

    renderIngredients = () => {
        var { t } = this.props;
        var { Device, DishDetail: { dataFoods }, TryingGetDishDetail } = this.props;
        var { Loading } = this.state;

        if (Loading || TryingGetDishDetail) return null;

        return (
            <IngredientsContainer>
                <PageText FontFamily="medium" FontSize="medium-3" Text={t('ingredients')} />
                <IngredientListContainer>
                    {
                        dataFoods.map(({ FoodId, FoodName, Quantity, UnitName }) => {
                            return (
                                <IngredientContainer key={FoodId}>
                                    <IngredientNameStyled FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={FoodName} TextAlign={Device === 'laptop' || Device === 'ipad' ? 'left' : 'center'} />
                                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-end" NoMargin Text={`${Quantity} ${UnitName}`} TextAlign="right" />
                                </IngredientContainer>
                            );
                        })
                    }
                </IngredientListContainer>
            </IngredientsContainer>
        );
    }

    renderInstructions = () => {
        var { t } = this.props;
        var { DishDetail: { dataInstructions }, TryingGetDishDetail } = this.props;
        var { Loading } = this.state;

        if (Loading || TryingGetDishDetail) return null;
        if (!dataInstructions.length) return null;

        return (
            <InstructionsContainer>
                <PageText FontFamily="medium" FontSize="medium-3" Text={t('instructions')} />
                {
                    dataInstructions.map(({ InstructionName }, InstructionIndex) => {
                        return (
                            <InstructionContainer key={InstructionIndex}>
                                <PageText ElementType="span" FontFamily="bold" FontSize="medium-1" NoMargin Text={`${InstructionIndex + 1} -`} />
                                &nbsp;
                                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={`${InstructionName}`} />
                            </InstructionContainer>
                        );
                    })
                }
            </InstructionsContainer>
        );
    }

    renderModalBody = () => {
        var { t } = this.props;
        var { Device, DishDetail_Preview: { DishName, DishUrl }, TryingGetDishDetail } = this.props;

        return (
            <>
                {TryingGetDishDetail ? <Loading /> : null}

                <PageText FontFamily="medium" FontSize="large" NoMargin Text={DishName} TextAlign="center" />
                
                {this.renderButtons()}

                <Spacer Size="medium" />

                <DishImageContainer className="DishImageContainer">
                    <DishImage ImageSrc={DishUrl} Loading={this.state.LoadingImage} OnLoaded={this.onLoadedImage} />
                    {this.renderCookingTimes()}
                </DishImageContainer>

                <Spacer Size="medium" />

                <Container>
                    <StyledCopyDish
                        Buttons={[
                            {
                                Icon: Plus,
                                IconPosition: 'left',
                                OnClick: this.onClickCreatePrivateDish,
                                Title: `${t('copy_dish')} - ${t('MealPlan_Create_a_new_dish_with_existing_dish')}`
                            }
                        ]}
                        ContainerWidth={Device === 'mobile_small' || Device === 'mobile' ? '100%' : null}
                        NormalWhiteSpace
                    />

                    <Spacer Size="medium" />

                    <PageText ContainerWidth="90%" FontFamily="medium-italic" FontSize="medium-1" Text={t('scaling_description')} TextAlign="center" />

                    <Spacer Size="medium" />

                    {this.renderIngredients()}

                    <Spacer Size="medium" />

                    {this.renderInstructions()}
                </Container>
            </>
        );
    }

    render() {
        var { OnHide, Show } = this.props;

        return (
            <AbelModal
                BottomButton="cancel"
                BottomButtonOnClick={OnHide}
                GA_PathName="DishDetail"
                NoMargin
                Show={Show}
                Size="medium"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

DishDetail.propTypes = {
    DishDetail_Preview: PropTypes.object.isRequired,
    OnHide: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        UserId: state.Auth.UserDetails.UserId,

        DishDetail: state.Meal.DishDetail,

        TryingGetDishDetail: state.Meal.TryingGetDishDetail,
        TryingGetDishDetailError: state.Meal.TryingGetDishDetailError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearDishDetail, GetDishDetail, SaveDataInProps } )(DishDetail));