import React from 'react';
import PropTypes from 'prop-types';

import { Container, StyledImage } from './styles';

import Female from '../../Assets/Icons/Female.png';
import Male from '../../Assets/Icons/Male.png';
import NoImage from '../../Assets/Icons/NoImage.png';

class ProfilePhoto extends React.Component {
    state = { LoadingImage: true, NoImage: NoImage };

    componentDidMount() {
        var { Sex } = this.props;

        if (Sex) this.setState({ NoImage: Sex.toLowerCase() === 'male' ? Male : Female });
    }

    componentDidUpdate(prevProps) {
        // console.log('prevProps: ', prevProps);
        // console.log('this.props: ', this.props);
    }

    onLoadedImage = () => {
        this.setState({ LoadingImage: false });
    }

    render() {
        var { className, OnClick, ShowOverlay, Size, Source } = this.props;
        var { LoadingImage, NoImage: NoImageState } = this.state;

        return (
            <Container className={className} onClick={OnClick ? OnClick : null}>
                <StyledImage
                    Alt="Profile"
                    ImageSrc={Source}
                    Loading={LoadingImage}
                    LoadingImageSrc={NoImageState}
                    NoImage={NoImageState}
                    OnLoaded={this.onLoadedImage}
                    RoundImage
                    Size={Size}
                    ShowOverlay={ShowOverlay}
                />
            </Container>
        );
    }
}

ProfilePhoto.propTypes = {
    OnClick: PropTypes.func,
    Sex: PropTypes.string.isRequired,
    ShowOverlay: PropTypes.bool,
    Size: PropTypes.string,
    Source: PropTypes.string.isRequired
}

ProfilePhoto.defaultProps = {
    ShowOverlay: true
}

export default ProfilePhoto;