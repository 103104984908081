import React from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import Popover from 'react-popover';

import { BodyContainer, Container, CopiedPageText, DotsContainer, GiveAccessButton, GiveAccessIcon, HeaderContainer, HeaderContainerLeft, HeaderContainerRight, HeaderNameContainer, HeaderPictureContainer, MealsContainer, PopoverContainer, PopoverHeaderContainer, PopoverHeaderEmailContainer, ProfilePhotoContainer, StyledDisplayNameLink, StyledEllipsisText, StyledThreeDotsIcon, WorkoutsContainer } from './BoxItem_styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import PopoverEvent from './PopoverEvent';
import ProfilePhoto from '../../Components/ProfilePhoto';
import Spacer from '../../Components/Spacer';

import GiveAccess_Meals from '../../Assets/Icons/ClientDashboard/Meals.png';
import GiveAccess_Workouts from '../../Assets/Icons/ClientDashboard/Workouts.png';
import ThreeDots from '../../Assets/Icons/ClientDashboard/ThreeDots.png';

class BoxItem extends React.Component {
    TimeoutId = null;

    state = { CopiedSuccess: null, PopoverIsOpen: false };

    onCopyEmail = () => {
        var { Email } = this.props.Client;

        if (!!this.TimeoutId) clearTimeout(this.TimeoutId);

        navigator.clipboard.writeText(Email);

        this.setState({ CopiedSuccess: true });

        var Timer = () => setTimeout(() => {
            this.setState({ CopiedSuccess: false });
            this.TimeoutId = null;
        }, 1000);

        this.TimeoutId = Timer();
    }

    onDeleteClient = () => {
        if (this.props.ClientType !== 'Groups') this.onTogglePopover();
        this.props.OnDeleteClient();
    }

    onGiveAccess = ({ AccessType, UpdateType }) => {
        if (AccessType === 'Workouts') this.props.OnUpdateWorkoutAccess({ UpdateType });
        else if (AccessType === 'Meals') this.props.OnUpdateMealAccess({ UpdateType });
    }

    onRequestAccess = () => {
        this.props.OnRequestClientAccess();
    }

    onShowGetStartedMeals = () => {
        this.onTogglePopover();
        this.props.OnShowGetStartedMeals();
    }

    onTogglePopover = () => {
        this.setState({ PopoverIsOpen: !this.state.PopoverIsOpen }, () => {
            if (!this.state.PopoverIsOpen && !!this.TimeoutId) clearTimeout(this.TimeoutId);
        });
    }

    renderDots = ({ Events, EventType }) => {
        var { Client: { UserId }, ClientIndex, ClientType, UpdatingAccess_AccessType, UpdatingAccess_ClientIndex } = this.props;

        if (UpdatingAccess_AccessType === EventType && ClientIndex === UpdatingAccess_ClientIndex) {
            return (
                <GiveAccessButton className="GiveAccessButton">
                    <GiveAccessIcon ImageSrc={EventType === 'Meals' ? GiveAccess_Meals : GiveAccess_Workouts} />

                    <i className="fas fa-spinner fa-spin"></i>
                </GiveAccessButton>
            )
        }

        return Events.map(({ AssignedDate, CompletionStatus, uId }) => {
            var IsCompleted = Number(CompletionStatus) ? 'complete' : moment(AssignedDate.substring(0, 10)).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ? 'incomplete' : '';

            return <PopoverEvent key={uId} AssignedDate={AssignedDate} ClientType={ClientType} CompletionStatus={IsCompleted} EventType={EventType} uId={uId} UserId={UserId} />;
        });
    }

    renderGiveAccess = ({ AccessType, ShowGetStartedMeals }) => {
        var { t } = this.props;
        var { Client: { MealAccess }, ClientIndex, UpdatingAccess_AccessType, UpdatingAccess_ClientIndex } = this.props;

        var OnClick;
        if (AccessType === 'Workouts' || !ShowGetStartedMeals) OnClick = () => this.onGiveAccess({ AccessType, UpdateType: 'give' });
        else OnClick = () => this.props.OnShowGetStartedMeals();

        return (
            <GiveAccessButton className="GiveAccessButton" onClick={OnClick}>
                <GiveAccessIcon ImageSrc={AccessType === 'Meals' ? GiveAccess_Meals : GiveAccess_Workouts} />

                {
                    (UpdatingAccess_AccessType === AccessType && ClientIndex === UpdatingAccess_ClientIndex) ?
                    <i className="fas fa-spinner fa-spin"></i>
                :
                    (AccessType === 'Meals' || (AccessType === 'Workouts' && !MealAccess)) ?
                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t(`ptadmin_getstarted_${AccessType.toLowerCase()}`)} TextAlign="left" />
                :
                    null
                }
            </GiveAccessButton>
        );
    }

    renderPopoverContent = () => {
        var { t } = this.props;
        var { Client: { Age, DateAdded, DOB, Email, IsPreRegistered, MealAccess, RequestTypeId, ShowGetStartedMeals, UserId, WorkoutAccess }, ClientIndex, DeletingClientAccess_UserId, RequestingClientAccess_UserId, UpdatingAccess_AccessType, UpdatingAccess_ClientIndex } = this.props;
        var { CopiedSuccess } = this.state;

        var Buttons = [];

        if (RequestTypeId === 3) {
            // Meal Button
                if (!!MealAccess) Buttons.push({ FontSize: 'small', FontFamily: 'semibold', OnClick: () => this.onGiveAccess({ AccessType: 'Meals', UpdateType: 'remove' }), Title: (UpdatingAccess_AccessType === 'Meals' && ClientIndex === UpdatingAccess_ClientIndex) ? <i className="fas fa-spinner fa-spin"></i> : t('ptadmin_getstarted_meals_remove') });
                else Buttons.push({ FontSize: 'small', FontFamily: 'semibold', OnClick: !!ShowGetStartedMeals ? this.onShowGetStartedMeals : () => this.onGiveAccess({ AccessType: 'Meals', UpdateType: 'give' }), Title: (UpdatingAccess_AccessType === 'Meals' && ClientIndex === UpdatingAccess_ClientIndex) ? <i className="fas fa-spinner fa-spin"></i> : t('ptadmin_getstarted_meals') });

            // Workout Button
                if (!!WorkoutAccess) Buttons.push({ FontSize: 'small', FontFamily: 'semibold', OnClick: () => this.onGiveAccess({ AccessType: 'Workouts', UpdateType: 'remove' }), Title: (UpdatingAccess_AccessType === 'Workouts' && ClientIndex === UpdatingAccess_ClientIndex) ? <i className="fas fa-spinner fa-spin"></i> : t('ptadmin_getstarted_workouts_remove') });
                else Buttons.push({ FontSize: 'small', FontFamily: 'semibold', OnClick: () => this.onGiveAccess({ AccessType: 'Workouts', UpdateType: 'give' }), Title: (UpdatingAccess_AccessType === 'Workouts' && ClientIndex === UpdatingAccess_ClientIndex) ?<i className="fas fa-spinner fa-spin"></i> : t('ptadmin_getstarted_workouts') });
        }
        else if (RequestTypeId === 0) Buttons.push({ FontSize: 'small', FontFamily: 'semibold', OnClick: this.onRequestAccess, Title: RequestingClientAccess_UserId === UserId ? <i className="fas fa-spinner fa-spin"></i> : t('TrainerClient_Request_Client_Access') });
        else Buttons.push({ FontSize: 'small', FontFamily: 'semibold', OnClick: this.onDeleteClient, Title: DeletingClientAccess_UserId === UserId ? <i className="fas fa-spinner fa-spin"></i> : t('TrainerClient_Request_Delete') });

        return (
            <PopoverContainer className="PopoverContainer">
                <PopoverHeaderContainer className="PopoverHeaderContainer">
                    <PopoverHeaderEmailContainer className="PopoverHeaderEmailContainer">
                        <StyledEllipsisText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={Email} TextAlign="left" />
                    </PopoverHeaderEmailContainer>

                    {navigator.clipboard && <CopiedPageText FontColor="abel-blue" FontFamily="semibold" FontSize="small" JustifyContent="flex-end" NoMargin OnClick={this.onCopyEmail} Text={t(!!CopiedSuccess ? 'copied': 'copy')} TextAlign="right" />}
                </PopoverHeaderContainer>

                <Spacer Size="extra-small" />

                {
                    RequestTypeId === 3 &&
                    <>
                        <MultiText ContainerWidth="100%" TextAlign="left" Texts={[{ FontFamily: 'medium', FontSize: 'small', Text: `${t('ptadmin_client_was_added')}:` }, { FontFamily: 'semibold', FontSize: 'small', Text: moment.utc(DateAdded).format('[NumbersDate]') }]} />

                        {
                            !IsPreRegistered &&
                            <>
                                <Spacer Size="extra-extra-small" />

                                <MultiText ContainerWidth="100%" TextAlign="left" Texts={[{ FontFamily: 'medium', FontSize: 'small', Text:`${t('birthday')}:` }, { FontFamily: 'semibold', FontSize: 'small', Text: `${moment.utc(DOB).format('[NumbersDate]')} (${Age} ${t('years_old')})` }]} />
                            </>
                        }

                        <Spacer Size="extra-small" />
                    </>
                }

                {
                    RequestTypeId === 1 &&
                    <>
                        <MultiText ContainerWidth="100%" TextAlign="left" Texts={[{ FontFamily: 'medium', FontSize: 'small', Text:`${t('Age')}:` }, { FontFamily: 'semibold', FontSize: 'small', Text: `${Age} ${t('years_old')}` }]} />

                        <Spacer Size="extra-small" />
                    </>
                }

                {
                    !!IsPreRegistered &&
                    <>
                        <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('ptadmin_giveaccess_preregistered_info')} TextAlign="left" />

                        <Spacer Size="extra-small" />
                    </>
                }

                {
                    RequestTypeId === 1 &&
                    <>
                        <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('ptadmin_giveaccess_pendingacceptance_info')} TextAlign="left" />

                        <Spacer Size="extra-small" />
                    </>
                }

                {
                    RequestTypeId === 0 &&
                    <>
                        <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('ptadmin_giveaccess_not_a_client')} TextAlign="left" />

                        <Spacer Size="extra-small" />
                    </>
                }
                
                <ButtonGroup Buttons={Buttons} ContainerWidth="100%" NotTouching OwnRows />

                {
                    RequestTypeId === 3 &&
                    <>
                        <Spacer Size="extra-small" />
                        
                        {
                            DeletingClientAccess_UserId === UserId ?
                            <i className="fas fa-spinner fa-spin"></i>
                        :
                            <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin OnClick={this.onDeleteClient} Text={t('ptadmin_clients_delete_singular')} TextAlign="center" />
                        }
                    </>
                }
            </PopoverContainer>
        );
    }

    render() {
        var { t } = this.props;
        var { Client: { Age, DisplayName, DOB, IsPreRegistered, MealAccess, Meals, RequestTypeId, Sex, ShowGetStartedMeals, UserId, UserPhoto, WorkoutAccess, Workouts }, ClientType } = this.props;

        const popoverProps = {
            isOpen: this.state.PopoverIsOpen,
            preferPlace: 'below',
            onOuterAction: this.onTogglePopover,
            body: this.renderPopoverContent(),
            tipSize: 10
        }

        return (
            <Container className="Container" ClientType={ClientType}>
                <HeaderContainer className="HeaderContainer">
                    <HeaderContainerLeft className="HeaderContainerLeft">
                        {
                            !IsPreRegistered && RequestTypeId === 3 &&
                            <HeaderPictureContainer className="HeaderPictureContainer" to={`/client/${UserId}`}>
                                <ProfilePhotoContainer className="ProfilePhotoContainer">
                                    <ProfilePhoto Sex={Sex || 'Male'} Size="small-3" Source={UserPhoto || ''} />
                                </ProfilePhotoContainer>
                            </HeaderPictureContainer>
                        }
                        <HeaderNameContainer className="HeaderNameContainer">
                            <StyledDisplayNameLink to={`/client/${UserId}`}>
                                <StyledEllipsisText FontColorHover="blue-abel" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={DisplayName} TextAlign="left" />
                            </StyledDisplayNameLink>
                            
                            {!IsPreRegistered && !!DOB && <StyledEllipsisText FontFamily="bold" FontSize="extra-small" JustifyContent="flex-start" NoMargin Text={`${Age} ${t('years_old')}, ${Sex.toLowerCase()}`} TextAlign="left" />}
                            {!!IsPreRegistered && <StyledEllipsisText FontFamily="medium-italic" FontSize="extra-small" JustifyContent="flex-start" NoMargin Text={t('ptadmin_client_does_not_have_account')} TextAlign="left" />}
                        </HeaderNameContainer>
                    </HeaderContainerLeft>
                    <HeaderContainerRight className="HeaderContainerRight" IsPreRegistered={IsPreRegistered} RequestTypeId={RequestTypeId}>
                        {RequestTypeId === 1 && <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-end" NoMargin Text={t('TrainerClient_Request_Sent_Short')} TextAlign="right" />}

                        {RequestTypeId === 3 && !!IsPreRegistered && <PageText FontFamily="bold" FontSize="small" JustifyContent="flex-end" NoMargin Text={t('TrainerClient_Invite_Sent_Short')} TextAlign="right" />}

                        <Popover {...popoverProps}>
                            <StyledThreeDotsIcon ImageSrc={ThreeDots} OnClick={this.onTogglePopover} />
                        </Popover>
                    </HeaderContainerRight>
                </HeaderContainer>

                <Spacer Size="extra-small" />

                <BodyContainer className="BodyContainer" RequestTypeId={RequestTypeId}>
                    {
                        RequestTypeId === 1 ?
                        <PageText FontFamily="medium-italic" FontSize="extra-small" JustifyContent="flex-start" NoMargin Text={t('ptadmin_giveaccess_pendingacceptance_notavailable')} TextAlign="left" />
                    :
                        RequestTypeId === 0 ?
                        <>
                            <ButtonGroup Buttons={[{ BackgroundColorHover: 'abel-blue', ColorHover: 'white', FontSize: 'small', FontFamily: 'semibold', OnClick: this.onRequestAccess, Title: t('TrainerClient_Request_Client_Access') }]} ContainerWidth="100%" NotTouching OwnRows />

                            <PageText FontFamily="medium-italic" FontSize="extra-small" JustifyContent="left" NoMargin Text={t('ptadmin_giveaccess_not_a_client')} TextAlign="left" />
                        </>
                    :
                        <>
                            <MealsContainer className="MealsContainer" ClientType={ClientType} MealAccess={MealAccess} WorkoutAccess={WorkoutAccess}>
                                {
                                    !!MealAccess ?
                                    <>
                                        <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin Text={t('ptadmin_contenttype_header_meals')} TextAlign="left" />

                                        <Spacer Size="extra-extra-small" />
                                        
                                        <DotsContainer className="DotsContainer">
                                            {this.renderDots({ Events: Meals, EventType: 'Meals' })}
                                        </DotsContainer>
                                    </>
                                :
                                    this.renderGiveAccess({ AccessType: 'Meals', ShowGetStartedMeals })
                                }
                            </MealsContainer>
                            <WorkoutsContainer className="WorkoutsContainer">
                                {
                                    !!WorkoutAccess ?
                                    <>
                                        <PageText FontFamily="medium" FontSize="extra-small" JustifyContent="flex-start" NoMargin Text={t('ptadmin_contenttype_header_workouts')} TextAlign="left" />

                                        <Spacer Size="extra-extra-small" />

                                        <DotsContainer className="DotsContainer">
                                            {this.renderDots({ Events: Workouts, EventType: 'Workouts' })}
                                        </DotsContainer>
                                    </>
                                :
                                    this.renderGiveAccess({ AccessType: 'Workouts' })
                                }
                            </WorkoutsContainer>
                        </>
                    }
                </BodyContainer>
            </Container>
        );
    }
}

export default withTranslation()(BoxItem);