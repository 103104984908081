import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddExistingDish, ClearSaveData, UpdateSelectedTab } from '../../Services/Actions';
import history from '../../history';

import { DishImage, SelectedDatesContainer, SelectedDishContainer, SelectedDishDetailContainer, SelectedPreviewContainer, StyledDishes, StyledDishName } from './styles';

import AssignClients from '../../Components/AssignClients';
import InputRange from '../../Components/Form/InputRange';
import Loading from '../../Components/Loading';
import MiniCalendar from '../../Components/Calendar/MiniCalendar';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

class AddDish extends React.Component {
    _isMounted = false;

    state = {
        IsBlockedAllergiesDietTypes: true,
        IsBlockedIngredients: true,
        RenewClientMealSettings: false,
        SelectedDates: [],
        SelectedDish: {},
        SelectedDishId: null,
        SelectedDishImageLoading: false,
        SelectedUserIds: [],
        SelectedUsers: []
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientDetails, DayDate } = this.props;

        var SelectedDates = DayDate ? [ DayDate ] : [];
        var SelectedUserIds = [];
        var SelectedUsers = [];

        var IsBlockedAllergiesDietTypes = false;
        var IsBlockedIngredients = false;

        if (Object.entries(ClientDetails).length !== 0) {
            SelectedUserIds = [ Number(ClientDetails.ClientId) ];
            SelectedUsers = [{ ...ClientDetails, UserId: Number(ClientDetails.ClientId) }];
            IsBlockedAllergiesDietTypes = true;
            IsBlockedIngredients = true;
        }

        this.setState({ IsBlockedAllergiesDietTypes, IsBlockedIngredients, SelectedDates, SelectedUserIds, SelectedUsers });
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearSaveData();
    }

    onAddDish = () => {
        var { SelectedDates, SelectedDishId, SelectedUserIds } = this.state;

        this.props.AddExistingDish({ ClientIds: SelectedUserIds, DishId: SelectedDishId, MealDates: SelectedDates }).then(() => {
            if (this._isMounted && !this.props.TryingAddExistingDishError) {
                if (SelectedUserIds.length === 1) history.push({ pathname: `/client/${SelectedUserIds[0]}`, search: '?tab=meals' });
                else history.push('/clients');
            }
        })
    }

    onLoadedSelectedDishImage = () => {
        this.setState({ SelectedDishImageLoading: false });
    }

    onSelectClients = ({ SelectedClients: SelectedUsers }) => {
        var SelectedUserIds_New = SelectedUsers.map(User => User.UserId);
        SelectedUsers = SelectedUsers.map(User => ({ ...User, Sex: User.Gender }));

        var { IsBlockedAllergiesDietTypes, IsBlockedIngredients, SelectedUserIds } = this.state;

        // Check if going from 0 to 2+, or 2+ to 0 ... as no need to reload then
        var SkippingOne = (SelectedUserIds_New.length === 0 && SelectedUserIds.length === 2) || (SelectedUserIds_New.length === 2 && SelectedUserIds.length === 0);

        // Check if new SelectedUsers match existing one, so that there is no need to reload dishes
        // Only Check If SkippingOne is false
        // var IsEqual = SkippingOne || SelectedUserIds.sort().toString() === SelectedUserIds_New.sort().toString();

        if (SkippingOne) this.setState({ RenewClientMealSettings: true, SelectedUsers, SelectedUserIds: SelectedUserIds_New });
        else {
            if (SelectedUsers.length !== 1) { IsBlockedAllergiesDietTypes = false; IsBlockedIngredients = false; }
            else { IsBlockedAllergiesDietTypes = true; IsBlockedIngredients = true; }

            this.setState({ IsBlockedAllergiesDietTypes, IsBlockedIngredients, RenewClientMealSettings: true, SelectedUsers, SelectedUserIds: SelectedUserIds_New });
        }
    }

    onSelectDate = DayDate => {
        var SelectedDateIndex = this.state.SelectedDates.indexOf(DayDate);

        if (SelectedDateIndex === -1) {
            this.setState((prevState) => {
                return {
                    SelectedDates: [ ...prevState.SelectedDates, ...[ DayDate ] ],
                }
            });
        } else {
            this.setState((prevState) => {
                return {
                    SelectedDates: [ ...prevState.SelectedDates.slice(0, SelectedDateIndex), ...prevState.SelectedDates.slice(SelectedDateIndex + 1) ],
                }
            });
        }
    }

    onSelectDish = Dish => {
        if (Number(Dish.DishId) !== this.state.SelectedDishId) this.setState({ SelectedDish: { ...Dish }, SelectedDishId: Number(Dish.DishId), SelectedDishImageLoading: true });
    }

    renderMaxCookingTime = () => {
        var { MaxCookingTime } = this.state;

        return (
            <InputRange
                MaxValue={60}
                MinValue={5}
                OnChangeComplete={MaxCookingTime => this.onChangeMaxCookingTime(MaxCookingTime, true)}
                RenderText={Value => this.renderMaxCookingTimeText(Value)}
                Step={5}
                Value={MaxCookingTime}
            />
        );
    }

    renderMaxCookingTimeText = MaxCookingTime => {
        var { t } = this.props;

        return (Number(MaxCookingTime) === 60 ? '60+' : `${MaxCookingTime}`) + ' ' + t('min_str');
    }

    renderSelectedPreview = () => {
        var { t } = this.props;
        var { SelectedDates, SelectedDish: { DishName, DishUrl }, SelectedDishId, SelectedDishImageLoading } = this.state;

        return (
            <SelectedPreviewContainer className="SelectedPreviewContainer">
                <SelectedDishContainer className="SelectedDishContainer">
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('WorkoutPlanSettings_selected')} TextAlign="center" />
                    <Spacer Size="small" />
                    {
                        SelectedDishId ?
                        <SelectedDishDetailContainer className="SelectedDishDetailContainer">
                            <DishImage
                                Alt={DishName}
                                ImageSrc={DishUrl}
                                Loading={SelectedDishImageLoading}
                                OnClick={() => this.onToggleShowDishDetailModal(true, this.state.SelectedDish)}
                                OnLoaded={this.onLoadedSelectedDishImage}
                            />

                            <StyledDishName className="styled-dish-name" FontFamily="bold" FontSize="small" NoMargin Text={DishName} TextAlign="left"/>
                        </SelectedDishDetailContainer>
                    :
                        null
                    }
                </SelectedDishContainer>
                <SelectedDatesContainer className="SelectedDatesContainer">
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('choose_dates')} TextAlign="center" />
                    
                    <Spacer Size="extra-small" />
                    
                    <MiniCalendar
                        OnSelectDate={DayDate => this.onSelectDate(DayDate)}
                        SelectedDates={SelectedDates}
                    />
                </SelectedDatesContainer>
            </SelectedPreviewContainer>
        );
    }

    render() {
        var { t } = this.props;
        var { Device, TryingAddExistingDish } = this.props;
        var { SelectedDates, SelectedDishId, SelectedUserIds, SelectedUsers } = this.state;

        var Breadcrumbs = [{ Name: t('select_dish') }];
        var SubmitButtonProps = {
            Disabled: !SelectedDates.length || !SelectedDishId || !SelectedUsers.length,
            OnClick: this.onAddDish,
            Title: t('select_dish'),
        }

        return (
            <>
                {TryingAddExistingDish ? <Loading /> : null}

                <Page
                    renderBreadcrumbs={Breadcrumbs}
                    renderSubmitButton={SubmitButtonProps}
                >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={t('select_dish')} />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'large' : 'medium'} />

                    <AssignClients
                        GetStartedEligible="Meals"
                        HorizontalScroll={false}
                        OnSelectClients={Users => this.onSelectClients(Users)}
                        SelectedUserIds={SelectedUserIds}
                        SelectedUsers={SelectedUsers}
                    />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'large' : 'medium'} />

                    <StyledDishes
                        HideSelect={false}
                        IsMultiSelect={false}
                        OnSelectDish={Dish => this.onSelectDish(Dish)}
                        SelectedItemIds={SelectedDishId ? [ Number(SelectedDishId) ] : []}
                        SelectedUsers={SelectedUsers}
                    />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'large' : 'medium'} />

                    {this.renderSelectedPreview()}
                </Page>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        DishFilterSettings: state.Dishes.DishFilterSettings,
        DishList: state.Dishes.DishList,
        DishList_TotalRecords: state.Dishes.DishList_TotalRecords,
        IsMoreDishes: state.Dishes.IsMoreDishes,

        ClientDetails: state.SaveData.ClientDetails,
        DayDate: state.SaveData.DayDate,

        ClientMealSettings: state.Client.ClientMealSettings,
        RetrievedDietaryRestrictions: state.Client.RetrievedDietaryRestrictions,
        TryingAddExistingDish: state.Meals.TryingAddExistingDish,
        TryingAddExistingDishError: state.Meals.TryingAddExistingDishError,
        TryingGetClientMealSettings: state.Client.TryingGetClientMealSettings,
        TryingGetClientMealSettingsError: state.Client.TryingGetClientMealSettingsError,
        TryingGetDishFilterSettings: state.Dishes.TryingGetDishFilterSettings,
        TryingGetDishFilterSettingsError: state.Dishes.TryingGetDishFilterSettingsError,
        TryingSearchDishes: state.Dishes.TryingSearchDishes,
        TryingSearchDishesError: state.Dishes.TryingSearchDishesError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddExistingDish, ClearSaveData, UpdateSelectedTab } )(AddDish));