import {
    TRYING_CHARGE_SUBSCRIPTION,
    TRYING_CHARGE_SUBSCRIPTION_SUCCESS,
    TRYING_CHARGE_SUBSCRIPTION_ERROR,

    TRYING_CREATE_CONTRACT,
    TRYING_CREATE_CONTRACT_SUCCESS,
    TRYING_CREATE_CONTRACT_ERROR,

    TRYING_DELETE_CONTRACT,
    TRYING_DELETE_CONTRACT_SUCCESS,
    TRYING_DELETE_CONTRACT_ERROR,

    TRYING_DELETE_EXPENSE,
    TRYING_DELETE_EXPENSE_SUCCESS,
    TRYING_DELETE_EXPENSE_ERROR,

    TRYING_DELETE_PRODUCTS,
    TRYING_DELETE_PRODUCTS_SUCCESS,
    TRYING_DELETE_PRODUCTS_ERROR,

    TRYING_DOWNLOAD_CONTRACT_PDF,
    TRYING_DOWNLOAD_CONTRACT_PDF_SUCCESS,
    TRYING_DOWNLOAD_CONTRACT_PDF_ERROR,

    TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY,
    TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY_SUCCESS,
    TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY_ERROR,

    CLEAR_CREATE_CONTRACT_DETAILS,
    TRYING_GET_CREATE_CONTRACT_DETAILS,
    TRYING_GET_CREATE_CONTRACT_DETAILS_SUCCESS,
    TRYING_GET_CREATE_CONTRACT_DETAILS_ERROR,

    TRYING_INSERTEDIT_EXPENSE,
    TRYING_INSERTEDIT_EXPENSE_SUCCESS,
    TRYING_INSERTEDIT_EXPENSE_ERROR,

    TRYING_INSERTEDIT_PRODUCT,
    TRYING_INSERTEDIT_PRODUCT_SUCCESS,
    TRYING_INSERTEDIT_PRODUCT_ERROR,

    TRYING_SEARCH_CURRENCIES,
    TRYING_SEARCH_CURRENCIES_SUCCESS,
    TRYING_SEARCH_CURRENCIES_ERROR,

    CLEAR_SEARCH_EXPENSES,
    TRYING_SEARCH_EXPENSES,
    TRYING_SEARCH_EXPENSES_SUCCESS,
    TRYING_SEARCH_EXPENSES_ERROR,

    TRYING_SEARCH_PAYOUTS,
    TRYING_SEARCH_PAYOUTS_SUCCESS,
    TRYING_SEARCH_PAYOUTS_ERROR,

    TRYING_SEARCH_PRODUCTS,
    TRYING_SEARCH_PRODUCTS_SUCCESS,
    TRYING_SEARCH_PRODUCTS_ERROR,

    TRYING_SEARCH_TRAINER_CONTRACTS,
    TRYING_SEARCH_TRAINER_CONTRACTS_SUCCESS,
    TRYING_SEARCH_TRAINER_CONTRACTS_ERROR,

    TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS,
    TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS_SUCCESS,
    TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS_ERROR,

    TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY,
    TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY_SUCCESS,
    TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY_ERROR,

    TRYING_VIEW_EXPENSE_CATEGORIES,
    TRYING_VIEW_EXPENSE_CATEGORIES_SUCCESS,
    TRYING_VIEW_EXPENSE_CATEGORIES_ERROR,

    TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD,
    TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_SUCCESS,
    TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_ERROR,

    TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY,
    TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY_SUCCESS,
    TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY_ERROR,

    CLEAR_TRAINER_PAYOUT_SUMMARY,
    TRYING_VIEW_TRAINER_PAYOUT_SUMMARY,
    TRYING_VIEW_TRAINER_PAYOUT_SUMMARY_SUCCESS,
    TRYING_VIEW_TRAINER_PAYOUT_SUMMARY_ERROR
} from '../Types';

const INITIAL_STATE = {
    CreateContractDetails: { AccuracyStatement: '', HealthDeclarations: [], Products: [], TermsConditions: '' },
    Currencies: [],
    ExpenseCategories: [],
    Expenses: { Expenses: [], Stats: {}, TotalPages: 0, TotalRecords: 0 },
    Payouts: { IsMoreResults: 0, Payouts: [], TotalRecords: 0 },

    TryingChargeSubsription: false,
    TryingChargeSubsriptionError: null,
    TryingCreateContract: false,
    TryingCreateContractError: null,
    TryingDeleteContract: false,
    TryingDeleteContractError: null,
    TryingDeleteExpense: false,
    TryingDeleteExpenseError: null,
    TryingDeleteProducts: false,
    TryingDeleteProductsError: null,
    TryingDownloadContractPDF: false,
    TryingDownloadContractPDFError: null,
    TryingDownloadTrainerPayoutSummary: false,
    TryingDownloadTrainerPayoutSummaryError: null,
    TryingGetCreateContractDetails: false,
    TryingGetCreateContractDetailsError: null,
    TryingInsertEditExpense: false,
    TryingInsertEditExpenseError: null,
    TryingInsertEditProduct: false,
    TryingInsertEditProductError: null,
    TryingSearchCurrencies: false,
    TryingSearchCurrenciesError: null,
    TryingSearchExpenses: false,
    TryingSearchExpensesError: null,
    TryingSearchPayouts: false,
    TryingSearchPayoutsError: null,
    TryingSearchProducts: false,
    TryingSearchProductsError: null,
    TryingSearchTrainerContracts: false,
    TryingSearchTrainerContractsError: null,
    TryingSearchTrainerContractsClients: false,
    TryingSearchTrainerContractsClientsError: null,
    TryingUpdateContractProductDeliveredQuantity: false,
    TryingUpdateContractProductDeliveredQuantityError: null,
    TryingViewExpenseCategories: false,
    TryingViewExpenseCategoriesError: null,
    TryingViewTrainerPaymentDashboard: false,
    TryingViewTrainerPaymentDashboardError: null,
    TryingViewTrainerPaymentDashboardMonthly: false,
    TryingViewTrainerPaymentDashboardMonthlyError: null,
    TryingViewTrainerPayoutSummary: false,
    TryingViewTrainerPayoutSummaryError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_CHARGE_SUBSCRIPTION:
            return { ...state, TryingChargeSubsription: true, TryingChargeSubsriptionError: null };
        case TRYING_CHARGE_SUBSCRIPTION_ERROR:
            return { ...state, TryingChargeSubsription: false, TryingChargeSubsriptionError: action.payload };
        case TRYING_CHARGE_SUBSCRIPTION_SUCCESS:
            return { ...state, TryingChargeSubsription: false, TryingChargeSubsriptionError: null };

        case TRYING_CREATE_CONTRACT:
            return { ...state, TryingCreateContract: true, TryingCreateContractError: null };
        case TRYING_CREATE_CONTRACT_ERROR:
            return { ...state, TryingCreateContract: false, TryingCreateContractError: action.payload };
        case TRYING_CREATE_CONTRACT_SUCCESS:
            return { ...state, TryingCreateContract: false, TryingCreateContractError: null };

        case TRYING_DELETE_CONTRACT:
            return { ...state, TryingDeleteContract: true, TryingDeleteContractError: null };
        case TRYING_DELETE_CONTRACT_ERROR:
            return { ...state, TryingDeleteContract: false, TryingDeleteContractError: action.payload };
        case TRYING_DELETE_CONTRACT_SUCCESS:
            return { ...state, TryingDeleteContract: false, TryingDeleteContractError: null };

        case TRYING_DELETE_EXPENSE:
            return { ...state, TryingDeleteExpense: true, TryingDeleteExpenseError: null };
        case TRYING_DELETE_EXPENSE_ERROR:
            return { ...state, TryingDeleteExpense: false, TryingDeleteExpenseError: action.payload };
        case TRYING_DELETE_EXPENSE_SUCCESS:
            return { ...state, TryingDeleteExpense: false, TryingDeleteExpenseError: null };

        case TRYING_DELETE_PRODUCTS:
            return { ...state, TryingDeleteProducts: true, TryingDeleteProductsError: null };
        case TRYING_DELETE_PRODUCTS_ERROR:
            return { ...state, TryingDeleteProducts: false, TryingDeleteProductsError: action.payload };
        case TRYING_DELETE_PRODUCTS_SUCCESS:
            return { ...state, TryingDeleteProducts: false, TryingDeleteProductsError: null };

        case TRYING_DOWNLOAD_CONTRACT_PDF:
            return { ...state, TryingDownloadContractPDF: true, TryingDownloadContractPDFError: null };
        case TRYING_DOWNLOAD_CONTRACT_PDF_ERROR:
            return { ...state, TryingDownloadContractPDF: false, TryingDownloadContractPDFError: action.payload };
        case TRYING_DOWNLOAD_CONTRACT_PDF_SUCCESS:
            return { ...state, TryingDownloadContractPDF: false, TryingDownloadContractPDFError: null };

        case TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY:
            return { ...state, TryingDownloadTrainerPayoutSummary: true, TryingDownloadTrainerPayoutSummaryError: null };
        case TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY_ERROR:
            return { ...state, TryingDownloadTrainerPayoutSummary: false, TryingDownloadTrainerPayoutSummaryError: action.payload };
        case TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY_SUCCESS:
            return { ...state, TryingDownloadTrainerPayoutSummary: false, TryingDownloadTrainerPayoutSummaryError: null };
            
        case CLEAR_CREATE_CONTRACT_DETAILS:
            return { ...state, CreateContractDetails: { AccuracyStatement: '', HealthDeclarations: [], Products: [], TermsConditions: '' } };
        case TRYING_GET_CREATE_CONTRACT_DETAILS:
            return { ...state, TryingGetCreateContractDetails: true, TryingGetCreateContractDetailsError: null };
        case TRYING_GET_CREATE_CONTRACT_DETAILS_ERROR:
            return { ...state, TryingGetCreateContractDetails: false, TryingGetCreateContractDetailsError: action.payload };
        case TRYING_GET_CREATE_CONTRACT_DETAILS_SUCCESS:
            return { ...state, CreateContractDetails: { ...action.payload }, TryingGetCreateContractDetails: false, TryingGetCreateContractDetailsError: null };

        case TRYING_INSERTEDIT_EXPENSE:
            return { ...state, TryingInsertEditExpense: true, TryingInsertEditExpenseError: null };
        case TRYING_INSERTEDIT_EXPENSE_ERROR:
            return { ...state, TryingInsertEditExpense: false, TryingInsertEditExpenseError: action.payload };
        case TRYING_INSERTEDIT_EXPENSE_SUCCESS:
            var { Expense, ExpenseIndex, Stats } = action.payload;

            var NewExpenses = { ...state.Expenses };
            if (ExpenseIndex !== -1) NewExpenses.Expenses[ExpenseIndex] = Expense;
            else NewExpenses.TotalRecords += 1;

            NewExpenses.Stats = Stats;

            return { ...state, Expenses: NewExpenses, TryingInsertEditExpense: false, TryingInsertEditExpenseError: null };
        
        case TRYING_INSERTEDIT_PRODUCT:
            return { ...state, TryingInsertEditProduct: true, TryingInsertEditProductError: null };
        case TRYING_INSERTEDIT_PRODUCT_ERROR:
            return { ...state, TryingInsertEditProduct: false, TryingInsertEditProductError: action.payload };
        case TRYING_INSERTEDIT_PRODUCT_SUCCESS:
            return { ...state, TryingInsertEditProduct: false, TryingInsertEditProductError: null };

        case TRYING_SEARCH_CURRENCIES:
            return { ...state, TryingSearchCurrencies: true, TryingSearchCurrenciesError: null };
        case TRYING_SEARCH_CURRENCIES_ERROR:
            return { ...state, TryingSearchCurrencies: false, TryingSearchCurrenciesError: action.payload };
        case TRYING_SEARCH_CURRENCIES_SUCCESS:
            return { ...state, Currencies: action.payload, TryingSearchCurrencies: false, TryingSearchCurrenciesError: null };

        case CLEAR_SEARCH_EXPENSES:
            return { ...state, Expenses: { Expenses: [], Stats: {}, TotalPages: 0, TotalRecords: 0 } };
        case TRYING_SEARCH_EXPENSES:
            return { ...state, TryingSearchExpenses: true, TryingSearchExpensesError: null };
        case TRYING_SEARCH_EXPENSES_ERROR:
            return { ...state, TryingSearchExpenses: false, TryingSearchExpensesError: action.payload };
        case TRYING_SEARCH_EXPENSES_SUCCESS:
            // eslint-disable-next-line
            var { Expenses, Stats, TotalPages, TotalRecords } = action.payload;

            return { ...state, Expenses: { Expenses, Stats: Stats ? Stats : { ...state.Expenses.Stats }, TotalPages, TotalRecords }, TryingSearchExpenses: false, TryingSearchExpensesError: null };

        case TRYING_SEARCH_PAYOUTS:
            return { ...state, TryingSearchPayouts: true, TryingSearchPayoutsError: null };
        case TRYING_SEARCH_PAYOUTS_ERROR:
            return { ...state, TryingSearchPayouts: false, TryingSearchPayoutsError: action.payload };
        case TRYING_SEARCH_PAYOUTS_SUCCESS:
            var { Payouts } = action.payload;

            return { ...state, Payouts, TryingSearchPayouts: false, TryingSearchPayoutsError: null };

        case TRYING_SEARCH_PRODUCTS:
            return { ...state, TryingSearchProducts: true, TryingSearchProductsError: null };
        case TRYING_SEARCH_PRODUCTS_ERROR:
            return { ...state, TryingSearchProducts: false, TryingSearchProductsError: action.payload };
        case TRYING_SEARCH_PRODUCTS_SUCCESS:
            return { ...state, TryingSearchProducts: false, TryingSearchProductsError: null };

        case TRYING_SEARCH_TRAINER_CONTRACTS:
            return { ...state, TryingSearchTrainerContracts: true, TryingSearchTrainerContractsError: null };
        case TRYING_SEARCH_TRAINER_CONTRACTS_ERROR:
            return { ...state, TryingSearchTrainerContracts: false, TryingSearchTrainerContractsError: action.payload };
        case TRYING_SEARCH_TRAINER_CONTRACTS_SUCCESS:
            return { ...state, TryingSearchTrainerContracts: false, TryingSearchTrainerContractsError: null };

        case TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS:
            return { ...state, TryingSearchTrainerContractsClients: true, TryingSearchTrainerContractsClientsError: null };
        case TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS_ERROR:
            return { ...state, TryingSearchTrainerContractsClients: false, TryingSearchTrainerContractsClientsError: action.payload };
        case TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS_SUCCESS:
            return { ...state, TryingSearchTrainerContractsClients: false, TryingSearchTrainerContractsClientsError: null };

        case TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY:
            return { ...state, TryingUpdateContractProductDeliveredQuantity: true, TryingUpdateContractProductDeliveredQuantityError: null };
        case TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY_ERROR:
            return { ...state, TryingUpdateContractProductDeliveredQuantity: false, TryingUpdateContractProductDeliveredQuantityError: action.payload };
        case TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY_SUCCESS:
            return { ...state, TryingUpdateContractProductDeliveredQuantity: false, TryingUpdateContractProductDeliveredQuantityError: null };

        case TRYING_VIEW_EXPENSE_CATEGORIES:
            return { ...state, TryingViewExpenseCategories: true, TryingViewExpenseCategoriesError: null };
        case TRYING_VIEW_EXPENSE_CATEGORIES_ERROR:
            return { ...state, TryingViewExpenseCategories: false, TryingViewExpenseCategoriesError: action.payload };
        case TRYING_VIEW_EXPENSE_CATEGORIES_SUCCESS:
            return { ...state, ExpenseCategories: action.payload.ExpenseCategories, TryingViewExpenseCategories: false, TryingViewExpenseCategoriesError: null };

        case TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD:
            return { ...state, TryingViewTrainerPaymentDashboard: true, TryingViewTrainerPaymentDashboardError: null };
        case TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_ERROR:
            return { ...state, TryingViewTrainerPaymentDashboard: false, TryingViewTrainerPaymentDashboardError: action.payload };
        case TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_SUCCESS:
            return { ...state, TryingViewTrainerPaymentDashboard: false, TryingViewTrainerPaymentDashboardError: null };

        case TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY:
            return { ...state, TryingViewTrainerPaymentDashboardMonthly: true, TryingViewTrainerPaymentDashboardMonthlyError: null };
        case TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY_ERROR:
            return { ...state, TryingViewTrainerPaymentDashboardMonthly: false, TryingViewTrainerPaymentDashboardMonthlyError: action.payload };
        case TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY_SUCCESS:
            return { ...state, TryingViewTrainerPaymentDashboardMonthly: false, TryingViewTrainerPaymentDashboardMonthlyError: null };

        case CLEAR_TRAINER_PAYOUT_SUMMARY:
            return { ...state, Payouts: { IsMoreResults: 0, Payouts: [], TotalRecords: 0 } }
        case TRYING_VIEW_TRAINER_PAYOUT_SUMMARY:
            return { ...state, TryingViewTrainerPayoutSummary: true, TryingViewTrainerPayoutSummaryError: null };
        case TRYING_VIEW_TRAINER_PAYOUT_SUMMARY_ERROR:
            return { ...state, TryingViewTrainerPayoutSummary: false, TryingViewTrainerPayoutSummaryError: action.payload };
        case TRYING_VIEW_TRAINER_PAYOUT_SUMMARY_SUCCESS:
            // eslint-disable-next-line
            var { AddToExisting, IsMoreResults, Summary, TotalRecords } = action.payload;

            var NewPayouts = { IsMoreResults, TotalRecords };
            if (AddToExisting) NewPayouts.Payouts = [ ...state.Payouts.Payouts, ...Summary ];
            else NewPayouts.Payouts = [ ...Summary ];

            return { ...state, Payouts: NewPayouts, TryingViewTrainerPayoutSummary: false, TryingViewTrainerPayoutSummaryError: null };    

        default:
            return state;
    }
};
