import React from 'react';
import PropTypes from 'prop-types';

import { SpacerContainer } from './styles';

class Spacer extends React.Component {
    render() {
        return <SpacerContainer BackgroundColor={this.props.BackgroundColor} Size={this.props.Size} />
    }
}

Spacer.propTypes = {
    BackgroundColor: PropTypes.string,
    Size: PropTypes.string.isRequired,
};

export default Spacer;