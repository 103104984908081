import moment from 'moment';
import {
    CLEAR_GET_STARTED,

    TRYING_ADD_CLIENT_JOURNAL_ENTRY,
    TRYING_ADD_CLIENT_JOURNAL_ENTRY_SUCCESS,
    TRYING_ADD_CLIENT_JOURNAL_ENTRY_ERROR,

    TRYING_ADD_GET_STARTED_MEALS,
    TRYING_ADD_GET_STARTED_MEALS_SUCCESS,
    TRYING_ADD_GET_STARTED_MEALS_ERROR,

    TRYING_ADD_GET_STARTED_WORKOUTS,
    TRYING_ADD_GET_STARTED_WORKOUTS_SUCCESS,
    TRYING_ADD_GET_STARTED_WORKOUTS_ERROR,

    CLEAR_ACTIVITY_LEVELS,
    TRYING_GET_ACTIVITY_LEVELS,
    TRYING_GET_ACTIVITY_LEVELS_SUCCESS,
    TRYING_GET_ACTIVITY_LEVELS_ERROR,

    TRYING_TASKS_ACTION_COPY,

    CLEAR_CLIENT_DETAILS,
    TRYING_GET_CLIENT_DETAILS,
    TRYING_GET_CLIENT_DETAILS_SUCCESS,
    TRYING_GET_CLIENT_DETAILS_ERROR,

    TRYING_GET_CLIENT_MEAL_SETTINGS,
    TRYING_GET_CLIENT_MEAL_SETTINGS_SUCCESS,
    TRYING_GET_CLIENT_MEAL_SETTINGS_ERROR,

    TRYING_GET_CLIENT_JOURNAL_ENTRIES,
    TRYING_GET_CLIENT_JOURNAL_ENTRIES_SUCCESS,
    TRYING_GET_CLIENT_JOURNAL_ENTRIES_ERROR,

    TRYING_GET_CLIENT_MEALS,
    TRYING_GET_CLIENT_WORKOUTS,

    TRYING_GET_EXPERIENCE_LEVELS,
    TRYING_GET_EXPERIENCE_LEVELS_SUCCESS,
    TRYING_GET_EXPERIENCE_LEVELS_ERROR,

    TRYING_GET_GOALS,
    TRYING_GET_GOALS_SUCCESS,
    TRYING_GET_GOALS_ERROR,

    TRYING_GET_INJURIES,
    TRYING_GET_INJURIES_SUCCESS,
    TRYING_GET_INJURIES_ERROR,

    TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA,
    TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA_SUCCESS,
    TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA_ERROR,

    TRYING_UPDATE_DAILY_ACTIVITY_LEVEL,
    TRYING_UPDATE_DAILY_ACTIVITY_LEVEL_SUCCESS,
    TRYING_UPDATE_DAILY_ACTIVITY_LEVEL_ERROR,

    TRYING_UPDATE_TRAINER_CLIENT_NOTE,
    TRYING_UPDATE_TRAINER_CLIENT_NOTE_SUCCESS,
    TRYING_UPDATE_TRAINER_CLIENT_NOTE_ERROR,

    TRYING_UPDATE_WORKOUT_ACCESS_SUCCESS,

    UPDATE_SELECTED_TAB
} from '../Types';

const INITIAL_STATE = {
    ActivityLevels: {},
    CalendarDifference_Meals: 0,
    CalendarDifference_Tasks: 0,
    CalendarDifference_Workouts: 0,
    ClientDetails: {},
    ClientMealSettings: { Allergies: [], BlockedIngredients: [], DietTypes: [] },
    ExperienceLevels: {},
    Goals: [],
    Joints: [],
    LastViewedClientId: null,
    MonthlyView_Meals_Difference: 0,
    MonthlyView_Meals_EndDate: null,
    MonthlyView_Meals_StartDate: null,
    Muscles: [],
    RetrievedActivityLevels: null,
    RetrievedDietaryRestrictions: null,
    RetrievedExperienceLevels: null,
    RetrievedGoals: null,
    RetrievedInjuries: null,
    SelectedTab: null,
    WeeklyView_Workouts_Difference: 0,
    WeeklyView_Workouts_EndDate: null,
    WeeklyView_Workouts_StartDate: null,

    TryingAddClientJournalEntry: false,
    TryingAddClientJournalEntryError: false,
    TryingAddGetStartedMeals: false,
    TryingAddGetStartedMealsError: null,
    TryingAddGetStartedWorkouts: false,
    TryingAddGetStartedWorkoutsError: null,
    TryingGetActivityLevels: false,
    TryingGetActivityLevelsError: null,
    TryingGetClientDetails: false,
    TryingGetClientDetailsError: null,
    TryingGetClientJournalEntries: false,
    TryingGetClientJournalEntriesError: false,
    TryingGetClientMealSettings: false,
    TryingGetClientMealSettingsError: null,
    TryingGetExperienceLevels: false,
    TryingGetExperienceLevelsError: null,
    TryingGetGoals: false,
    TryingGetGoalsError: null,
    TryingGetInjuries: false,
    TryingGetInjuriesError: null,
    TryingInsertEditTrainerClientMedia: false,
    TryingInsertEditTrainerClientMediaError: null,
    TryingUpdateDailyActivityLevel: false,
    TryingUpdateDailyActivityLevelError: null,
    TryingUpdateTrainerClientNote: false,
    TryingUpdateTrainerClientNoteError: null
};

var INITIAL_WEEKLYVIEW_STATE = {
    WeeklyView_Workouts_Difference: 0,
    WeeklyView_Workouts_EndDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
    WeeklyView_Workouts_StartDate: moment().startOf('isoWeek').format('YYYY-MM-DD')
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_GET_STARTED:
            return { ...state, ActivityLevels: {}, ClientMealSettings: { Allergies: [], DietTypes: [] }, ExperienceLevels: {}, Goals: [], Joints: [], Muscles: [], RetrievedActivityLevels: null, RetrievedDietaryRestrictions: null, RetrievedExperienceLevels: null, RetrievedGoals: null, RetrievedInjuries: null };

        case TRYING_ADD_CLIENT_JOURNAL_ENTRY:
            return { ...state, TryingAddClientJournalEntry: true, TryingAddClientJournalEntryError: null };
        case TRYING_ADD_CLIENT_JOURNAL_ENTRY_ERROR:
            return { ...state, TryingAddClientJournalEntry: false, TryingAddClientJournalEntryError: action.payload };
        case TRYING_ADD_CLIENT_JOURNAL_ENTRY_SUCCESS:
            return { ...state, TryingAddClientJournalEntry: false, TryingAddClientJournalEntryError: null };

        case TRYING_ADD_GET_STARTED_MEALS:
            return { ...state, TryingAddGetStartedMeals: true, TryingAddGetStartedMealsError: null };
        case TRYING_ADD_GET_STARTED_MEALS_ERROR:
            return { ...state, TryingAddGetStartedMeals: false, TryingAddGetStartedMealsError: action.payload };
        case TRYING_ADD_GET_STARTED_MEALS_SUCCESS:
            return { ...state, ClientDetails: { ...state.ClientDetails, ...action.payload, GetStartedCompleted_Meals: 1 }, TryingAddGetStartedMeals: false, TryingAddGetStartedMealsError: null };

        case TRYING_ADD_GET_STARTED_WORKOUTS:
            return { ...state, TryingAddGetStartedWorkouts: true, TryingAddGetStartedWorkoutsError: null };
        case TRYING_ADD_GET_STARTED_WORKOUTS_ERROR:
            return { ...state, TryingAddGetStartedWorkouts: false, TryingAddGetStartedWorkoutsError: action.payload };
        case TRYING_ADD_GET_STARTED_WORKOUTS_SUCCESS:
            return { ...state, ClientDetails: { ...state.ClientDetails, ...action.payload, GetStartedCompleted_Workouts: 1 }, TryingAddGetStartedWorkouts: false, TryingAddGetStartedWorkoutsError: null };

        case CLEAR_ACTIVITY_LEVELS:
            return { ...state, ActivityLevels: {}, RetrievedActivityLevels: null };
        case TRYING_GET_ACTIVITY_LEVELS:
            return { ...state, RetrievedActivityLevels: false, TryingGetActivityLevels: true, TryingGetActivityLevelsError: null };
        case TRYING_GET_ACTIVITY_LEVELS_ERROR:
            return { ...state, RetrievedActivityLevels: true, TryingGetActivityLevels: false, TryingGetActivityLevelsError: action.payload };
        case TRYING_GET_ACTIVITY_LEVELS_SUCCESS:
            return { ...state, ActivityLevels: action.payload, RetrievedActivityLevels: true, TryingGetActivityLevels: false, TryingGetActivityLevelsError: null };

        case CLEAR_CLIENT_DETAILS:
            return { ...state, ClientDetails: {}, KeepCurrentClient: false, SelectedTab: null };
        case TRYING_GET_CLIENT_DETAILS:
            return { ...state, ...state.LastViewedClientId === action.payload ? {} : { CalendarDifference_Meals: 0, CalendarDifference_Tasks: 0, CalendarDifference_Workouts: 0, ...INITIAL_WEEKLYVIEW_STATE }, LastViewedClientId: action.payload, TryingGetClientDetails: true, TryingGetClientDetailsError: null };
        case TRYING_GET_CLIENT_DETAILS_ERROR:
            return { ...state, TryingGetClientDetails: false, TryingGetClientDetailsError: action.payload };
        case TRYING_GET_CLIENT_DETAILS_SUCCESS:
            return { ...state, ClientDetails: { ...action.payload, ClientColor: action.payload.ClientColor || 'C1C1C1' }, TryingGetClientDetails: false, TryingGetClientDetailsError: null };

        case TRYING_GET_CLIENT_JOURNAL_ENTRIES:
            return { ...state, TryingGetClientJournalEntries: true, TryingGetClientJournalEntriesError: null };
        case TRYING_GET_CLIENT_JOURNAL_ENTRIES_ERROR:
            return { ...state, TryingGetClientJournalEntries: false, TryingGetClientJournalEntriesError: action.payload };
        case TRYING_GET_CLIENT_JOURNAL_ENTRIES_SUCCESS:
            return { ...state, TryingGetClientJournalEntries: false, TryingGetClientJournalEntriesError: null };

        case TRYING_GET_CLIENT_MEAL_SETTINGS:
            return { ...state, TryingGetClientMealSettings: true, TryingGetClientMealSettingsError: null };
        case TRYING_GET_CLIENT_MEAL_SETTINGS_ERROR:
            return { ...state, TryingGetClientMealSettings: false, TryingGetClientMealSettingsError: action.payload };
        case TRYING_GET_CLIENT_MEAL_SETTINGS_SUCCESS:
            return { ...state, ClientMealSettings: { ...action.payload }, RetrievedDietaryRestrictions: true, TryingGetClientMealSettings: false, TryingGetClientMealSettingsError: null };
        
        case TRYING_GET_CLIENT_MEALS:
            // eslint-disable-next-line
            var { CalendarDifference } = action.payload;

            return { ...state, CalendarDifference_Meals: CalendarDifference };
        case TRYING_TASKS_ACTION_COPY:
            // eslint-disable-next-line
            var { CalendarDifference } = action.payload;

            return { ...state, CalendarDifference_Tasks: CalendarDifference };
        case TRYING_GET_CLIENT_WORKOUTS:
            // eslint-disable-next-line
            var { CalendarDifference } = action.payload;

            return { ...state, CalendarDifference_Workouts: CalendarDifference };

        case TRYING_GET_EXPERIENCE_LEVELS:
            return { ...state, RetrievedExperienceLevels: false, TryingGetExperienceLevels: true, TryingGetExperienceLevelsError: null };
        case TRYING_GET_EXPERIENCE_LEVELS_ERROR:
            return { ...state, RetrievedExperienceLevels: true, TryingGetExperienceLevels: false, TryingGetExperienceLevelsError: action.payload };
        case TRYING_GET_EXPERIENCE_LEVELS_SUCCESS:
            return { ...state, ExperienceLevels: action.payload, RetrievedExperienceLevels: true, TryingGetExperienceLevels: false, TryingGetExperienceLevelsError: null };

        case TRYING_GET_GOALS:
            return { ...state, RetrievedGoals: false, TryingGetGoals: true, TryingGetGoalsError: null };
        case TRYING_GET_GOALS_ERROR:
            return { ...state, RetrievedGoals: true, TryingGetGoals: false, TryingGetGoalsError: action.payload };
        case TRYING_GET_GOALS_SUCCESS:
            return { ...state, Goals: action.payload, RetrievedGoals: true, TryingGetGoals: false, TryingGetGoalsError: null };

        case TRYING_GET_INJURIES:
            return { ...state, RetrievedInjuries: false, TryingGetInjuries: true, TryingGetInjuriesError: null };
        case TRYING_GET_INJURIES_ERROR:
            return { ...state, RetrievedInjuries: true, TryingGetInjuries: false, TryingGetInjuriesError: action.payload };
        case TRYING_GET_INJURIES_SUCCESS:
            var { Joints, Muscles } = action.payload;

            return { ...state, Joints, Muscles, RetrievedInjuries: true, TryingGetInjuries: false, TryingGetInjuriesError: null };
        
        case TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA:
            return { ...state, TryingInsertEditTrainerClientMedia: true, TryingInsertEditTrainerClientMediaError: null };
        case TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA_ERROR:
            return { ...state, TryingInsertEditTrainerClientMedia: false, TryingInsertEditTrainerClientMediaError: action.payload };
        case TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA_SUCCESS:
            return { ...state, TryingInsertEditTrainerClientMedia: false, TryingInsertEditTrainerClientMediaError: null };

        case TRYING_UPDATE_DAILY_ACTIVITY_LEVEL:
            return { ...state, TryingUpdateDailyActivityLevel: true, TryingUpdateDailyActivityLevelError: null };
        case TRYING_UPDATE_DAILY_ACTIVITY_LEVEL_ERROR:
            return { ...state, TryingUpdateDailyActivityLevel: false, TryingUpdateDailyActivityLevelError: action.payload };
        case TRYING_UPDATE_DAILY_ACTIVITY_LEVEL_SUCCESS:
            return { ...state, TryingUpdateDailyActivityLevel: false, TryingUpdateDailyActivityLevelError: null };

        case TRYING_UPDATE_TRAINER_CLIENT_NOTE:
            return { ...state, TryingUpdateTrainerClientNote: true, TryingUpdateTrainerClientNoteError: null };
        case TRYING_UPDATE_TRAINER_CLIENT_NOTE_ERROR:
            return { ...state, TryingUpdateTrainerClientNote: false, TryingUpdateTrainerClientNoteError: action.payload };
        case TRYING_UPDATE_TRAINER_CLIENT_NOTE_SUCCESS:
            return { ...state, TryingUpdateTrainerClientNote: false, TryingUpdateTrainerClientNoteError: null };

        case TRYING_UPDATE_WORKOUT_ACCESS_SUCCESS:
            if (action.payload.ReturnDataType !== 'ClientDetail') return { ...state };
            return { ...state, ClientDetails: { ...state.ClientDetails, GetStartedCompleted_Workouts: action.payload.WorkoutAccess } };

        case UPDATE_SELECTED_TAB:
            return { ...state, SelectedTab: action.payload };
        default:
            return state;
    }
};
