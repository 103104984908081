import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import Chart from "chart.js";
import moment from 'moment';

import { SecondsToTime } from '../../../Functions';

//--Chart Style Options--//
Chart.defaults.global.defaultFontFamily = "'branding-medium'"
Chart.defaults.global.legend.display = false;
//--Chart Style Options--//

class LineGraph extends PureComponent {
    chartRef = React.createRef();

    componentDidMount() {
        this.buildChart();
    }

    componentDidUpdate() {
        this.myChart.destroy();
        this.buildChart();
    }

    buildChart = () => {
        var { Goals, Increment, IsTime, Maximum, Minimum, Measurements, Units } = this.props;

        var datasets = this.buildChartData({ Goals, Measurements });
        var { MaxDate, MinDate, StepSize, TimeUnit } = this.buildChartDates();

        this.myChart = new Chart(this.chartRef.current, {
            type: "line",
            data: { datasets },
            options: {
                responsive: true,
                scales: {
                    xAxes: [{
                        type: "time",
                        ticks: {
                            min: MinDate,
                            max: MaxDate,
                        },
                        time: {
                            stepSize: StepSize,
                            unit: TimeUnit
                        }
                    }],
                    yAxes: [{
                        scaleLabel: {
                            display: false,
                            labelString: Units
                        },
                        stacked: false,
                        ticks: {
                            callback: (v) => {
                                if (IsTime) return SecondsToTime(v)
                                else return v;
                            },
                            maxTicksLimit: 5,
                            stepSize: Increment,
                            suggestedMin: Minimum,
                            suggestedMax: Maximum
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        title: (tooltipItems) => `${tooltipItems[0].xLabel}`,
                        label: ({ yLabel }) => `${yLabel} ${Units}`
                    }
                }
            }
        });
    }

    buildChartData = ({ Goals, Measurements }) => {
        var datasets = [];

        var INITIAL_DATASET = { data: [], fill: false, label: null, lineTension: 0, pointRadius: 3 };
        var INTIAL_GOAL_DATASET = { backgroundColor: 'rgba(104, 224, 16, 0.2)', borderColor: '#68E010' };

        var data = [];
        var fill = null;

        // Add Goals To Datasets
            Goals.map(({ EndDate, GoalMax, GoalMin, StartDate }, GoalIndex) => {
                
                if (+GoalMin) {
                    data = [{ x: StartDate, y: GoalMin }];
                    if (StartDate !== EndDate) data.push({ x: EndDate, y: GoalMin });

                    fill = +GoalMax ? null : 'end';

                    datasets.push({ ...INITIAL_DATASET, ...INTIAL_GOAL_DATASET, label: `Goal_${GoalIndex}_min`, fill, data });
                }

                if (+GoalMax) {
                    data = [{ x: StartDate, y: GoalMax }];
                    if (StartDate !== EndDate) data.push({ x: EndDate, y: GoalMax });

                    fill = +GoalMin ? datasets.length - 1 : 'origin';

                    datasets.push({ ...INITIAL_DATASET, ...INTIAL_GOAL_DATASET, label: `Goal_${GoalIndex}_max`, fill, data });
                }

                return null;
            });

        // Add Measurements To Datasets
            datasets.push({
                ...INITIAL_DATASET, borderColor: '#1A97FF', label: 'Measurements',
                data: Measurements.map(({ Measurement, MeasurementDate, Units }) => ({ Units, x: MeasurementDate, y: Measurement }))
            });

        return datasets;
    }

    buildChartDates = () => {
        var { AllTime, EndDate, Goals, Measurements, StartDate, TimePeriod } = this.props;

        // Build Max and Min Dates
            var MinDate, MaxDate;

            MaxDate = moment().format('YYYY-MM-DD');

            if (AllTime) {
                let moments = [];
                if (Goals.length) moments.push(moment(Goals[0].StartDate));
                if (Measurements.length) moments.push(moment(Measurements[0].MeasurementDate));

                MinDate = moment.min(moments).format('YYYY-MM-DD');
            }
            else if (EndDate && StartDate) {
                MaxDate = moment(EndDate).format('YYYY-MM-DD');
                MinDate = moment(StartDate).format('YYYY-MM-DD');
            }
            else {
                if (TimePeriod === '1W') MinDate = moment().subtract(1, 'weeks').format('YYYY-MM-DD');
                else if (TimePeriod === '1M') MinDate = moment().subtract(4, 'weeks').format('YYYY-MM-DD');
                else if (TimePeriod === '1Y') MinDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
            }

            // console.log('MaxDate: ', MaxDate);
            // console.log('MinDate: ', MinDate);

            // Build StepSize (how much time between each label, according to TimeUnit, showing on x-axis)
            // Build TimeUnit (type of date showing on x-axis)
                var StepSize;
                var TimeUnit;

            var DateDifference = moment.duration(moment(MaxDate).diff(moment(MinDate))).asDays();

            if (DateDifference <= 7) {
                // 1 day between each label
                StepSize = 1;
                TimeUnit = 'day';
            }
            else if (DateDifference <= 31) {
                // 7 days between each label
                StepSize = 7;
                TimeUnit = 'day';
            }
            // 1.5 years
            else if (DateDifference < 550) {
                // 1 month between each label
                StepSize = 1;
                TimeUnit = 'month';
            }

        return { MaxDate, MinDate, StepSize, TimeUnit };
    }

    render() {

        return (
            <div>
                <canvas ref={this.chartRef} />
            </div>
        )
    }
}

LineGraph.propTypes = {
    AllTime: PropTypes.bool,
    EndDate: PropTypes.string,
    Goals: PropTypes.array.isRequired,
    Increment: PropTypes.number.isRequired,
    IsTime: PropTypes.bool,
    Maximum: PropTypes.number.isRequired,
    Minimum: PropTypes.number.isRequired,
    Measurements: PropTypes.array.isRequired,
    StartDate: PropTypes.string,
    TimePeriod: PropTypes.string,
    Units: PropTypes.string.isRequired
}

export default LineGraph;