import styled from 'styled-components';

import Image from '../../Components/Image';

export const RecommendedContainer = styled.div`
    align-items: center;
    background-color: var(--orange);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4px 9px;
`;

export const SectionContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: -10px;
    margin-right: -10px;
    padding: 10px;

    > div:nth-child(2) {
        margin-left: 5px;
    }
`;

export const StyledPicture1 = styled(Image)`
    max-width: 100%;
`;

export const StyledPicture2 = styled(Image)`
    max-width: 170px;
`;

export const StyledPicture3 = styled(Image)`
    max-width: 100%;
`;

export const StyledPicture4 = styled(Image)`
    max-width: 100%;
`;

export const StyledPicture5 = styled(Image)`
    max-width: 100%;
`;

export const StyledPicture6 = styled(Image)`
    max-width: 100%;
`;

export const StyledPicture7 = styled(Image)`
    max-width: 100%;
`;

export const StyledVideo = styled.video`
    border-radius: 10px;
    margin: 0 auto;
    max-width: 100%;
`;