import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import * as styles from './ListItemStyles';

import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import AbelIcon from '../../Assets/Icons/Abel_HeaderIcon.png';
import AdditionalActivity from '../../Assets/Icons/AdditionalActivity.png';
import EditIcon from '../../Assets/Icons/Pencil_Gray.png';
import Endurance from '../../Assets/Icons/Endurance.png';
import Female from '../../Assets/Icons/Female.png';
import Male from '../../Assets/Icons/Male.png';
import Strength from '../../Assets/Icons/Strength.png';

const renderUserPhoto = Content => {
    var { DisplayName, Sex, UserId, UserPhoto } = Content;

    var SexImage = Sex === 'Male' ? Male : Female;

    if (UserId === 16) UserPhoto = AbelIcon;

    return <styles.StyledProfilePhoto Alt={DisplayName} ImageSrc={UserPhoto} IsAbel={UserId === 16} NoImage={SexImage} NoParent />;
}

const renderWorkoutType = WorkoutType => {
    var IconSrc = WorkoutType === 1 ? Strength : WorkoutType === 2 ? Endurance : AdditionalActivity;

    return <styles.WorkoutTypeIcon ImageSrc={IconSrc} alt="Workout Type" />
}

function WorkoutTemplate(props) {
    const { t } = useTranslation();

    var { AuthedUserId, Content } = props;

    var IsOwner = +AuthedUserId === +Content.UserId;
    var CannotShare = (Content.ShareAbilityStatusId === 3 && !IsOwner);

    return (
        <>
            <styles.Container className="Container" CannotShare={CannotShare}>
                <styles.LeftContainer className="LeftContainer">
                    {renderUserPhoto(Content)}

                    {renderWorkoutType(Content.WorkoutType)}

                    <styles.StyledContentName FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={Content.TemplateName} TextAlign="left" />
                </styles.LeftContainer>

                <styles.RightContainer className="RightContainer">
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('sharing_details_shareability')}: `} TextAlign="left" />

                    <Spacer Size="extra-extra-small" />

                    <styles.ShareAbilityStatusContainer className="ShareAbilityStatusContainer">
                        <PageText FontColorHover={IsOwner ? 'black-hover' : 'black'} FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={IsOwner ? () => props.OnToggleShowEditShareAbilityStatusModal({ ContentId: Content.TemplateId, CurrentStatus: Content.ShareAbilityStatusId }) : null} Text={Content.ShareAbility} TextAlign="left" />

                        {IsOwner && <styles.StyledEditIcon ImageSrc={EditIcon} OnClick={() => props.OnToggleShowEditShareAbilityStatusModal({ ContentId: Content.TemplateId, CurrentStatus: Content.ShareAbilityStatusId })} />}
                    </styles.ShareAbilityStatusContainer>
                </styles.RightContainer>
            </styles.Container>

            {
                CannotShare &&
                <>
                    <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={+Content.UserId === 16 ? t('sharing_details_cannot_share_abel_content') : t('sharing_details_unauthorized_to_share')} TextAlign="left" />

                    <Spacer Size="extra-small" />
                </>
            }
        </>
    );
}

WorkoutTemplate.propTypes = {
    AuthedUserId: PropTypes.number.isRequired,
    Content: PropTypes.object.isRequired,
    OnToggleShowEditShareAbilityStatusModal: PropTypes.func.isRequired
}

export default WorkoutTemplate;