import {
  HIDE_ERROR_MODAL,
  LOGOUT_USER,

  TRYING_CHANGE_PASSWORD,
  TRYING_CHANGE_PASSWORD_SUCCESS,
  TRYING_CHANGE_PASSWORD_ERROR,

  TRYING_CHOOSE_PRIMARY_ORGANIZATION,
  TRYING_CHOOSE_PRIMARY_ORGANIZATION_SUCCESS,
  TRYING_CHOOSE_PRIMARY_ORGANIZATION_ERROR,

  TRYING_EDIT_USER_PROFILE,
  TRYING_EDIT_USER_PROFILE_SUCCESS,
  TRYING_EDIT_USER_PROFILE_ERROR,

  TRYING_FORGOT_PASSWORD_SEND_CODE,
  TRYING_FORGOT_PASSWORD_SEND_CODE_SUCCESS,
  TRYING_FORGOT_PASSWORD_SEND_CODE_ERROR,

  TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD,
  TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD_SUCCESS,
  TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD_ERROR,

  TRYING_LOGIN_USER,
  TRYING_LOGIN_USER_SUCCESS,
  TRYING_LOGIN_USER_ERROR,

  TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS,
  TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_SUCCESS,
  TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_ERROR,

  TRYING_UPDATE_SUBSCRIPTION_STATUS,

  TRYING_UPDATE_USER_SETTINGS,
  TRYING_UPDATE_USER_SETTINGS_SUCCESS,
  TRYING_UPDATE_USER_SETTINGS_ERROR,

  TRYING_UPLOAD_PROFILE_PICTURE,
  TRYING_UPLOAD_PROFILE_PICTURE_SUCCESS,
  TRYING_UPLOAD_PROFILE_PICTURE_ERROR,

  TRYING_VALIDATE_USER,
  TRYING_VALIDATE_USER_SUCCESS,
  TRYING_VALIDATE_USER_ERROR,

  TRYING_VIEW_AVAILABLE_LANGUAGES,
  TRYING_VIEW_AVAILABLE_LANGUAGES_SUCCESS,
  TRYING_VIEW_AVAILABLE_LANGUAGES_ERROR,

  UPDATE_ERROR_MODAL_WARNING,
  UPDATE_PDF_LOGO
} from '../Types';

const INITIAL_STATE = {
  IsLoggedIn: null,
  ModalError: null,
  ModalErrorLink: null,
  ModalErrorNoLink: null,
  ShowErrorModal: false,
  UserDetails: {},

  TryingChangePassword: false,
  TryingChangePasswordError: null,
  TryingChoosePrimaryOrganization: false,
  TryingChoosePrimaryOrganizationError: null,
  TryingEditUserProfile: false,
  TryingEditUserProfileError: null,
  TryingForgotPasswordSendCode: false,
  TryingForgotPasswordSendCodeError: null,
  TryingForgotPasswordUpdatePassword: false,
  TryingForgotPasswordUpdatePasswordError: null,
  TryingLoginUser: false,
  TryingLoginUserError: null,
  TryingUpdateOrganizationMembershipStatus: false,
  TryingUpdateOrganizationMembershipStatusError: null,
  TryingUpdateUserSettings: false,
  TryingUpdateUserSettingsError: null,
  TryingUploadProfilePicture: false,
  TryingUploadProfilePictureError: null,
  TryingValidateUser: false,
  TryingValidateUserError: null,
  TryingViewAvailableLanguages: false,
  TryingViewAvailableLanguagesError: null,
  TryingViewModels: false,
  TryingViewModelsError: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HIDE_ERROR_MODAL:
      return { ...state, ModalError: null, ShowErrorModal: false };

    case LOGOUT_USER:
      return { ...state, IsLoggedIn: 0, TryingLoginUserError: action.payload };

    case TRYING_CHANGE_PASSWORD:
      return { ...state, TryingChangePassword: true, TryingChangePasswordError: null };
    case TRYING_CHANGE_PASSWORD_ERROR:
      return { ...state, TryingChangePassword: false, TryingChangePasswordError: action.payload };
    case TRYING_CHANGE_PASSWORD_SUCCESS:
      return { ...state, TryingChangePassword: false, TryingChangePasswordError: null };

    case TRYING_CHOOSE_PRIMARY_ORGANIZATION:
      return { ...state, TryingChoosePrimaryOrganization: true, TryingChoosePrimaryOrganizationError: null };
    case TRYING_CHOOSE_PRIMARY_ORGANIZATION_ERROR:
      return { ...state, TryingChoosePrimaryOrganization: false, TryingChoosePrimaryOrganizationError: action.payload };
    case TRYING_CHOOSE_PRIMARY_ORGANIZATION_SUCCESS:
      var { OrganizationUserId } = action.payload;
      
      var UserDetails = { ...state.UserDetails };
      UserDetails.SubscriptionStatus = { ...UserDetails.SubscriptionStatus, primaryOrganizationUserId: OrganizationUserId };

      return { ...state, UserDetails, TryingChoosePrimaryOrganization: false, TryingChoosePrimaryOrganizationError: null };

    case TRYING_EDIT_USER_PROFILE:
      return { ...state, TryingEditUserProfile: true, TryingEditUserProfileError: null };
    case TRYING_EDIT_USER_PROFILE_ERROR:
      return { ...state, IsLoggedIn: 0, TryingEditUserProfile: false, TryingEditUserProfileError: action.payload };
    case TRYING_EDIT_USER_PROFILE_SUCCESS:
      return { ...state, UserDetails: { ...state.UserDetails, ...action.payload }, TryingEditUserProfile: false, TryingEditUserProfileError: null };

    case TRYING_FORGOT_PASSWORD_SEND_CODE:
      return { ...state, TryingForgotPasswordSendCode: true, TryingForgotPasswordSendCodeError: null };
    case TRYING_FORGOT_PASSWORD_SEND_CODE_ERROR:
      return { ...state, TryingForgotPasswordSendCode: false, TryingForgotPasswordSendCodeError: action.payload };
    case TRYING_FORGOT_PASSWORD_SEND_CODE_SUCCESS:
      return { ...state, TryingForgotPasswordSendCode: false, TryingForgotPasswordSendCodeError: null };

    case TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD:
      return { ...state, TryingForgotPasswordUpdatePassword: true, TryingForgotPasswordUpdatePasswordError: null };
    case TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD_ERROR:
      return { ...state, TryingForgotPasswordUpdatePassword: false, TryingForgotPasswordUpdatePasswordError: action.payload };
    case TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD_SUCCESS:
      return { ...state, TryingForgotPasswordUpdatePassword: false, TryingForgotPasswordUpdatePasswordError: null };

    case TRYING_LOGIN_USER:
      return { ...state, TryingLoginUser: true, TryingLoginUserError: null };
    case TRYING_LOGIN_USER_ERROR:
      return { ...state, IsLoggedIn: 0, TryingLoginUser: false, TryingLoginUserError: action.payload };
    case TRYING_LOGIN_USER_SUCCESS:
      return { ...state, ...action.payload && { IsLoggedIn: 1, UserDetails: action.payload }, TryingLoginUser: false, TryingLoginUserError: null };

    case TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS:
      return { ...state, TryingUpdateOrganizationMembershipStatus: true, TryingUpdateOrganizationMembershipStatusError: null };
    case TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_ERROR:
      return { ...state, TryingUpdateOrganizationMembershipStatus: false, TryingUpdateOrganizationMembershipStatusError: action.payload };
    case TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_SUCCESS:
      // eslint-disable-next-line
      var { UserDetails } = action.payload;

      return { ...state, UserDetails, TryingUpdateOrganizationMembershipStatus: false, TryingUpdateOrganizationMembershipStatusError: null };

    case TRYING_UPDATE_SUBSCRIPTION_STATUS:
      return { ...state, UserDetails: { ...state.UserDetails, SubscriptionStatus: { MembershipRequests: state.UserDetails.SubscriptionStatus.MembershipRequests, ...action.payload } } };

    case TRYING_UPDATE_USER_SETTINGS:
      return { ...state, TryingUpdateUserSettings: true, TryingUpdateUserSettingsError: null };
    case TRYING_UPDATE_USER_SETTINGS_ERROR:
      return { ...state, TryingUpdateUserSettings: false, TryingUpdateUserSettingsError: action.payload };
    case TRYING_UPDATE_USER_SETTINGS_SUCCESS:
      return { ...state, UserDetails: { ...state.UserDetails, ...action.payload }, TryingUpdateUserSettings: false, TryingUpdateUserSettingsError: null };

    case TRYING_UPLOAD_PROFILE_PICTURE:
      return { ...state, TryingUploadProfilePicture: true, TryingUploadProfilePictureError: null };
    case TRYING_UPLOAD_PROFILE_PICTURE_ERROR:
      return { ...state, TryingUploadProfilePicture: false, TryingUploadProfilePictureError: action.payload };
    case TRYING_UPLOAD_PROFILE_PICTURE_SUCCESS:
      var { ProfilePhotoUrl } = action.payload;

      return { ...state, UserDetails: { ...state.UserDetails, ProfilePhotoUrl }, TryingUploadProfilePicture: false, TryingUploadProfilePictureError: null };

    case TRYING_VALIDATE_USER:
      return { ...state, TryingValidateUser: true, TryingValidateUserError: null };
    case TRYING_VALIDATE_USER_ERROR:
      return { ...state, TryingValidateUser: false, TryingValidateUserError: action.payload };
    case TRYING_VALIDATE_USER_SUCCESS:
      return { ...state, TryingValidateUser: false, TryingValidateUserError: null };

    case TRYING_VIEW_AVAILABLE_LANGUAGES:
      return { ...state, TryingViewAvailableLanguages: true, TryingViewAvailableLanguagesError: null };
    case TRYING_VIEW_AVAILABLE_LANGUAGES_ERROR:
      return { ...state, TryingViewAvailableLanguages: false, TryingViewAvailableLanguagesError: action.payload };
    case TRYING_VIEW_AVAILABLE_LANGUAGES_SUCCESS:
      return { ...state, TryingViewAvailableLanguages: false, TryingViewAvailableLanguagesError: null };

    case UPDATE_PDF_LOGO:
      const { PDFLogo } = action.payload;
      return { ...state, UserDetails: { ...state.UserDetails, PDFLogo } };

    case UPDATE_ERROR_MODAL_WARNING:
      var { ModalError, ModalErrorLink, ModalErrorNoLink } = action.payload;

      return { ...state, ModalError, ModalErrorLink, ModalErrorNoLink, ShowErrorModal: true };
    default:
      return state;
  }
};
