import styled from 'styled-components';
import { device } from '../../Config/device';

import Icon from '../../Components/Icon';
import Page from '../../Components/Page';

export const LeftPagination = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    ${props => !props.HasPrevLink && 'margin-left: -10px;'}
`;

export const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${device.maxwidth.ipad} {
        > div:first-child {
            margin-bottom: 10px;
        }
    }

    @media ${device.minwidth.laptop} {
        flex-direction: row;
    }
`;

export const OptionsLeftContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 545px) {
        > div:first-child {
            margin-bottom: 15px;
        }
    }

    @media (min-width: 546px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const OptionsRightContainer = styled.div``;

export const PageNumberContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    border-radius: 10px;
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    display: flex;
    flex-direction: row;
    font-family: var(--font-family-medium);
    font-size: var(--font-size-medium-1);
    height: 35px;
    justify-content: center;
    width: 35px;

    @media(hover:hover) {
        :hover {
            background-color: var(--blue-astronaut);
            color: var(--white);
            cursor: pointer;
        }
    }
`;

export const PageNumbersContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 10px;

    > div:not(:last-child) {
        margin-right: 10px;
    }
`;

export const PaginationContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media ${device.maxwidth.mobile_small} {
        flex-direction: column;
        justify-content: center;
    }
`;

export const RightPagination = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

export const StyledPage = styled(Page)`
    & .page-maincontentcontainer {
        width: 100%;
    }
`;

export const ViewCount = styled.div`
    text-align: center;
`;

export const ViewTypeIcon = styled(Icon)`
    display: block;
    width: 30px;

    :not(:last-child) {
        margin-right: 10px;
    }
`;

export const ViewTypesContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 10px;
`;