import styled from 'styled-components';

import { Link } from 'react-router-dom';
import Popover from 'react-bootstrap/Popover';

import Icon from '../../Components/Icon';
import PageText from '../../Components/Text/PageText';

export const BodyContainer = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 -5px;

    > div {
        margin-left: 5px;
        margin-right: 5px;
    }
`;

export const Container = styled.div`
    align-items: stretch;
    background-color: ${props => props.ClientType === 'Groups' ? 'var(--white-concrete)' : 'var(--chat-gray)'};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
`;

export const CopiedPageText = styled(PageText)`
    margin-left: 5px;
`;

export const DotsContainer = styled.div`
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: -3px;
    margin-top: -3px;
    height: 30px;
`;

export const EventContainer = styled.div`
    background-color: ${props => props.Completed === 'complete' ? 'var(--green-lima)' : props.Completed === 'incomplete' ? 'var(--red-bittersweet)' : 'var(--white-concrete)'};
    border: 1px solid rgba(84, 84, 84, 0.1);
    border-radius: 100%;
    height: 9px;
    margin-right: 3px;
    margin-top: 3px;
    width: 9px;

    @media(hover:hover) {
        :hover {
            background-color: ${props => props.Completed === 'complete' ? 'var(--green-lima-hover)' : props.Completed === 'incomplete' ? 'var(--red-bittersweet-hover)' : 'var(--white-concrete-hover)'};
            cursor: pointer;
        }
    }
`;

export const GiveAccessButton = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    margin-left: 5px;
    padding: 3px;

    @media(hover:hover) {
        :hover {
            background-color: var(--abel-blue);
            color: var(--white);
            cursor: pointer;
        }
    }
`;

export const GiveAccessIcon = styled(Icon)`
    > img {
        height: auto;
        width: 25px;
    }
`;

export const HeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const HeaderContainerLeft = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
    overflow: hidden;
`;

export const HeaderContainerRight = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 5px;
`;

export const HeaderNameContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
`;

export const HeaderPictureContainer = styled(Link)``;

export const MealsContainer = styled.div`
    flex: ${props => props.MealAccess ? '2' : '1'};

    ${props => props.MealAccess && props.WorkoutAccess && 'border-right: 2px solid var(--white-concrete);'}

    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
`;

export const PopoverContainer = styled.div`
    align-items: center;
    background-color: var(--white);
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    width: 300px;
`;

export const PopoverContent = styled.div`
    background-color: var(--white);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 15px;
`;

export const PopoverHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const PopoverHeaderEmailContainer = styled.div`
    flex: 1;
    overflow: hidden;
`;

export const ProfilePhotoContainer = styled.div`
    margin-right: 5px;
    width: var(--profilephoto-size-small-3);
`;

export const StyledDisplayNameLink = styled(Link)`
    color: inherit;
`;

export const StyledEllipsisText = styled(PageText)`
    overflow: hidden;

    > div {    
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

export const StyledPopover = styled(Popover)`
    background-color: var(--white-concrete);
    border-radius: 10px;
    box-shadow: 1px 1px 8px #5A5A5A29;
    max-width: 250px;
`;

export const StyledPopoverTitle = styled(PageText)`
    padding: 5px 15px;
`;

export const StyledThreeDotsIcon = styled(Icon)`
    margin-left: 5px;

    > img {
        height: var(--profilephoto-size-small-1);
    }
`;

export const WorkoutsContainer = styled.div`
    flex: 1;

    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
`;