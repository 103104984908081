import styled from 'styled-components';

export const Container = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -10px;
    margin-left: -10px;
`;

export const RatingImage = styled.img`
    margin-bottom: 10px;
    margin-left: 10px;

    @media (hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;