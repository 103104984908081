import axios from 'axios';
import { AUTH_API, BETA_SITE_URL } from '../../Config';

const axiosInstance = axios.create();
updateAuthorizationHeader();

let isRefreshing = false;
let requestsQueue = [];

function updateAuthorizationHeader(token) {
  const accessToken = token || localStorage.getItem('accessToken');
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
}

const processQueue = (newToken = null) => {
  requestsQueue.forEach(pending => {
      pending.originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
      pending.resolve(axiosInstance(pending.originalRequest));
  });

  requestsQueue = [];
};

function processQueueWithError(error) {
    requestsQueue.forEach(pending => {
      pending.reject(error);
    });
    requestsQueue = [];
}

const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');

    const response = await axios.post(`${AUTH_API}token/refresh`, { refreshToken, clientType: 'web' });

    const { accessToken, refreshToken: newRefreshToken } = response.data.result;

    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', newRefreshToken);

    document.dispatchEvent(new Event("tokenRefreshed"));

    updateAuthorizationHeader(accessToken);

    return accessToken;
  } catch (error) {
    localStorage.clear();
    
    processQueueWithError(error);

    window.location.href = `${BETA_SITE_URL}/logout`;
  }
};

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    console.log('originalRequest: ', originalRequest);

    if (error.response && error.response.status === 401) {
      return new Promise((resolve, reject) => {
        requestsQueue.push({ resolve, reject, originalRequest });

        if (!isRefreshing) {
          isRefreshing = true;
          refreshToken().then(newToken => {
              processQueue(newToken);
              isRefreshing = false;
          });
        }
      });
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;