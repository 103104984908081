import styled from 'styled-components';
import { device } from './../../Config/device';

import ButtonGroup from '../Buttons/ButtonGroup';
import Input from '../Form/Input';
import TextArea from '../Form/TextArea';

import Checkmark from '../../Assets/Icons/Checkmark_Selected.png';

export const LanguageSelectorRow = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const LeftContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media ${device.minwidth.ipad} {
        > div:nth-child(2) {
            margin-left: 10px;
        }
    }

    @media ${device.maxwidth.mobile} {
        align-items: start;
        flex-direction: column;

        > div:nth-child(2) {
            margin-top: 10px;
        }
    }
`;

export const RadioButtonContainer = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    display: block;
    height: 25px;
    margin-right: 5px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const RadioButtonParentContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    // margin-top: 10px;
`;

export const StyledAddLanguageButtonGroup = styled(ButtonGroup)`
    justify-content: flex-start;
`;

export const StyledDeleteLanguageButton = styled(ButtonGroup)``;

export const StyledInputContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const StyledInput = styled(Input)`
    width: 100%;

    > input {
        background-color: var(--white-concrete);
        border: none;
        border-radius: 10px;
        text-align: left;
        text-indent: 0px;
    }
`;

export const StyledTextArea = styled(TextArea)`
    margin-bottom: 0px;
    width: 100%;

    > textarea {
        background-color: var(--white-concrete);
        border: none;
        resize: vertical !important;
    }
`;

export const TranslationContainer = styled.div`
    max-width: 100%;
    width: ${props => props.FullWidth ? '100%' : '840px'};
`;

export const TranslationsContainer = styled.div``;