import styled from 'styled-components';

export const IconContainer = styled.div`
    position: relative;
`;

export const StyledIcon = styled.img`
    display: block;
    max-width: 100%;

    ${props => props.onClick ? '@media(hover:hover) { :hover { cursor: pointer; } }' : null}
`;