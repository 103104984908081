import React from 'react';
import PropTypes from 'prop-types';

import { Container, DataContainer, StyledInput, StyledLabel, TimeColon } from './styles';

class InputLabelSideBySide extends React.Component {
    render() {
        var { className, InputFontFamily, InputFontSize, InputWidth, Label, LabelFontFamily, LabelFontSize, OnBlur, OnChange, Placeholder, ReadOnly, TimeValueMinutes, TimeValueSeconds, Type, Value } = this.props;

        // Make Double Input
            if (Type.toLowerCase() === 'time') {
                return (
                    <Container className={className} ReadOnly={ReadOnly}>
                        <DataContainer>
                            <StyledInput
                                FontFamily={InputFontFamily}
                                FontSize={InputFontSize}
                                IsTime="minutes"
                                NoDecimal
                                NoLabel
                                NoMargin
                                OnBlur={OnBlur}
                                OnChange={event => OnChange(event, 'Minutes', TimeValueSeconds)}
                                Placeholder={Placeholder}
                                ReadOnly={ReadOnly}
                                Type="number"
                                Value={TimeValueMinutes}
                            />
                            <TimeColon>:</TimeColon>
                            <StyledInput
                                FontFamily={InputFontFamily}
                                FontSize={InputFontSize}
                                InputWidth={InputWidth}
                                IsTime="seconds"
                                NoDecimal
                                NoLabel
                                NoMargin
                                OnBlur={OnBlur}
                                OnChange={event => OnChange(event, 'Seconds', TimeValueMinutes)}
                                Placeholder={Placeholder && Placeholder === '0' ? '00' : Placeholder}
                                ReadOnly={ReadOnly}
                                Type="number"
                                Value={TimeValueSeconds}
                            />
                        </DataContainer>

                        <DataContainer HasBorder="left">
                            <StyledLabel
                                FontFamily={LabelFontFamily}
                                FontSize={LabelFontSize}
                                NoMargin
                                Text={Label}
                                TextAlign="center"
                            />
                        </DataContainer>
                    </Container>
                );
            }
        
        return (
            <Container className={className} ReadOnly={ReadOnly}>
                <DataContainer HasBorder="right">
                    <StyledInput
                        FontFamily={InputFontFamily}
                        FontSize={InputFontSize}
                        InputWidth={InputWidth}
                        NoLabel
                        NoMargin
                        OnBlur={OnBlur}
                        OnChange={OnChange}
                        Placeholder={Placeholder}
                        ReadOnly={ReadOnly}
                        Type={Type}
                        Value={Value}
                    />
                </DataContainer>

                <DataContainer>
                    <StyledLabel
                        FontFamily={LabelFontFamily}
                        FontSize={LabelFontSize}
                        NoMargin
                        Text={Label}
                        TextAlign="center"
                    />
                </DataContainer>
            </Container>
        );
    }
}

InputLabelSideBySide.propTypes = {
    InputFontFamily: PropTypes.string,
    InputFontSize: PropTypes.string,
    InputWidth: PropTypes.string,
    Label: PropTypes.string.isRequired,
    LabelFontFamily: PropTypes.string,
    LabelFontSize: PropTypes.string,
    OnBlur: PropTypes.func,
    OnChange: PropTypes.func.isRequired,
    Placeholder: PropTypes.string,
    ReadOnly: PropTypes.bool,
    TimeValueMinutes: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    TimeValueSeconds: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    Type: PropTypes.string.isRequired,
    Value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
}

export default InputLabelSideBySide;