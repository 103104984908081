import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AbelModal from '../../Components/Modal';
import Image from '../../Components/Image';

const StyledPreviewMedia = styled(Image)`
    margin: 0 auto;
    max-width: 100%;
    object-fit: cover;
`;

class PreviewMedia extends React.Component {
    state = { LoadedPrevewMedia: false, MediaUrl: this.props.MediaUrl, RetriedGetContentUrl: false }

    onLoadedPreviewMedia = () => {
        this.setState({ LoadedPrevewMedia: true });
    }

    onRetryImage = async () => {
        if (this.props.Media) {
            var MediaUrl = await this.props.Media.getContentUrl();
            this.setState({ MediaUrl, RetriedGetContentUrl: true });
        }
    }

    render() {
        var { className, FileType, OnHide, Show } = this.props;
        var { LoadedPrevewMedia, MediaUrl, RetriedGetContentUrl } = this.state;

        return (
            <AbelModal
                className={className}
                GA_PathName="PreviewMedia"
                Show={Show}
                Size="large"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {
                    FileType && FileType === 'video' &&
                    <video
                        autoPlay={true}
                        controls={true}
                        height="100%"
                        width="100%"
                        src={MediaUrl}
                    />
                }

                {
                    !FileType &&
                    <StyledPreviewMedia
                        HandleImageError={!RetriedGetContentUrl ? this.onRetryImage : null}
                        ImageSrc={MediaUrl}
                        Loading={!LoadedPrevewMedia}
                        OnLoaded={this.onLoadedPreviewMedia}
                    />
                }
            </AbelModal>
        );
    }
}

PreviewMedia.propTypes = {
    FileType: PropTypes.string,
    Media: PropTypes.object,
    MediaUrl: PropTypes.string.isRequired,
    OnHide: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired
}

export default PreviewMedia;