import styled from "styled-components";
import PageText from "../../Text/PageText";
import Image from "../../Image";
import { device } from "../../../Config/device";

export const ActionButton = styled.div`
  align-items: center;
  background-color: ${(props) => props.BackgroundColor};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  margin-left: 10px;

  @media (hover: hover) {
    :hover {
      background-color: ${(props) => props.BackgroundColorHover};
      cursor: pointer;
    }
  }
`;

export const ActionButtonIcon = styled(Image)`
  height: 20px;
  width: ${(props) => (props.ExtraWidth ? "30px" : "20px")};
`;

export const ButtonTitle = styled(PageText)`
  color: var(--white);
  margin: 0 5px;
`;

export const ClientContainer = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  position: relative;
`;

export const ClientListContainer = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
`;

export const DescriptionContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

export const HeaderLeft = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 70%;

  @media ${device.maxwidth.ipad} {
    width: 100%;
    display: grid;
    grid-template-columns: 0.3fr 0.3fr 0.2fr 0.2fr;
  }
`;

export const HeaderRight = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 30%;

  @media ${device.maxwidth.ipad} {
    width: 100%;
    justify-content: center;
    margin: 10px;
  }
`;

export const ScheduledCallsContainer = styled.div`
  background-color: var(--white-concrete);
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  margin: 20px 0;
`;

export const ScheduledCallsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  @media ${device.maxwidth.ipad} {
    flex-direction: column;
  }
`;

export const StyledWorkoutImage = styled(Image)`
  border-radius: 10px;
  height: 70px;
  object-fit: cover;
  margin: 0 10px 10px 0;
`;

export const StyledPageText = styled(PageText)`
  margin-left: 15px;
  width: 80%;
  justify-content: flex-start;
`;

export const UserStatusText = styled(PageText)`
  width: 20%;
  margin: 0 5px;
  color: ${(props) =>
    props.IsAccepted ? "var(--abel-blue)" : "var(--warning)"};
`;

export const WorkoutImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px 0 0;
`;
