import React from 'react';
import { useTranslation } from 'react-i18next';

import Popover from 'react-popover';

import { EllipsisText, NoResultsContainer, SAYTResultsContainer, SAYTResult, SearchInput } from './styles';

import PageText from '../../Text/PageText';

const { useEffect, useReducer, useState } = React;

const BuildResultText = ({ Search, SearchResult }) => {
    SearchResult = SearchResult.replace(new RegExp(Search, "gi"), match => `<span style="color: var(--blue-abel); font-family: var(--font-family-semibold);">${match}</span>`);

    return <EllipsisText dangerouslySetInnerHTML={{ __html: SearchResult }} />;
}

function fetchReducer(state, action) {
    switch (action.type) {
      case "FETCH_START":
        return { ...state, isLoading: true, hasError: false }
      case "FETCH_SUCCESS":
        return { ...state, isLoading: false, hasError: false, SearchResults: action.payload }
      case "FETCH_FAILURE":
        return { ...state, isLoading: false, hasError: true };

      default:
        throw new Error()
    }
  }
  
async function fetchSearchResults(SearchFunc, query, dispatch, ExtraSearchProps = {}) {
    dispatch({ type: "FETCH_START" });

    try {
        if (query === '') {
            dispatch({ type: "FETCH_SUCCESS", payload: [] });
        } else {
            const result = await SearchFunc({ PageNo: 1, PageSize: 10, Search: query, ...ExtraSearchProps });
            dispatch({ type: "FETCH_SUCCESS", payload: result.SearchResults });
        }
    } catch (err) {
      dispatch({ type: "FETCH_FAILURE" });
    }
}

export default function SAYT(props) {
    const { t } = useTranslation();
    const [{ SearchResults, isLoading }, dispatch] = useReducer(fetchReducer, { SearchResults: [], isLoading: true });
    const [query, setQuery] = useState("");
    const [popoverIsOpen, setPopoverIsOpen] = useState(false);

    const onSearch = SearchValue => {
        setPopoverIsOpen(false);

        SearchValue = SearchValue || query;

        props.OnSearch(SearchValue);
    }

    const onClickSearchResult = Item => {
        setQuery(Item[props.ItemName]);
        
        onSearch(Item[props.ItemName]);
    }

    const renderPopoverContent = () => {
        if (!popoverIsOpen) return <SAYTResultsContainer></SAYTResultsContainer>;

        return (
            <SAYTResultsContainer className="SAYTResultsContainer">
                {isLoading && <i className="fas fa-spinner fa-spin"></i>}
                {
                    SearchResults.length ?
                    SearchResults.map((Item, SearchIndex) => {   
                        var SearchResult = props.ItemName ? Item[props.ItemName] : `${SearchIndex}`;

                        return (
                            <SAYTResult className="SAYTResult" key={SearchIndex} onClick={() => onClickSearchResult(Item)}>
                                {BuildResultText({ Search: query, SearchResult })}
                            </SAYTResult>
                        );
                    })
                :
                    <NoResultsContainer className="NoResultsContainer">
                        <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('search_noresults')} TextAlign="center" />
                    </NoResultsContainer>
                }
            </SAYTResultsContainer>
        );
    }

    const popoverProps = {
        isOpen: popoverIsOpen,
        preferPlace: 'below',
        onOuterAction: () => setPopoverIsOpen(!popoverIsOpen),
        body: renderPopoverContent(),
        tipSize: 0.01
    }
  
    useEffect(() => {
        const timeOutId = setTimeout(() => fetchSearchResults(props.SearchFunc, query, dispatch, props.ExtraSearchProps), 500);
        return () => clearTimeout(timeOutId);
    }, [props.SearchFunc, query, props.ExtraSearchProps]);

    const onChangeSearch = event => {
        var Search = event.target.value;

        setQuery(Search);
        setPopoverIsOpen(!!Search);
    }

    const onFocus = () => {
        if (!popoverIsOpen) setPopoverIsOpen(true);
    }

    const onKeyPress = ({ key }) => {
        if (key === 'Enter') onSearch();
    }

    return (
        <Popover {...popoverProps}>
            <SearchInput
                className={props.className}
                FontSize="medium-1"
                NoLabel
                NoMargin
                OnFocus={onFocus}
                OnKeyPress={onKeyPress}
                OnChange={onChangeSearch}
                Placeholder={props.Placeholder || t('search_with_first_char_uppercase_dots')}
                Size={props.Size || "medium"}
                Type="text"
                Value={query}
            />
        </Popover>
    );
}