import React from 'react';
import styled from 'styled-components';

import Image from '../../Image';
import PageText from '../../Text/PageText';
import Spacer from '../../Spacer';

const DetailContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;

    :last-child {
        flex: 1
    }
`;

const GymContainer = styled.div`
    align-items: center;
    background-color: var(--white);
    border: ${props => props.Selected ? '5px solid var(--blue-abel)' : '2px solid var(--white-concrete)'};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;

    :hover {
        background-color: var(--white-concrete);
        cursor: pointer;
    }
`;

const GymImage = styled(Image)`
    border-radius: 0px;
    height: 45px;
    margin: 0 auto;
    object-fit: contain;
    width: 50%;
`;

class GymListItem extends React.Component {
    state = { LoadingImage: true };

    onLoadedImage = () => {
        this.setState({ LoadingImage: false });
    }

    render() {
        var { Item, OnSelectItem, SelectedItemIds } = this.props;

        var { GymCityAddress, GymId, GymImage: GymImageUrl, GymName, GymStreetAddress } = Item;

        var Selected = SelectedItemIds.indexOf(Number(GymId)) > -1;

        return (
            <GymContainer id={`itemid-${GymId}`} onClick={() => OnSelectItem(Item)} Selected={Selected}>
                <div>
                    <GymImage
                        Alt="Gym"
                        ImageSrc={GymImageUrl}
                        Loading={this.state.LoadingImage}
                        OnLoaded={this.onLoadedImage}
                    />
                    <Spacer Size="extra-small" />
                </div>
                <DetailContainer>
                    <PageText FontFamily="bold" FontSize="medium-1" NoMargin Text={GymName} TextAlign="center"/>
                    <PageText FontFamily="medium" FontSize="small" NoMargin Text={`${GymStreetAddress}, ${GymCityAddress}`} TextAlign="center"/>
                </DetailContainer>
            </GymContainer>
        );
    }
}

export default GymListItem;