import styled from 'styled-components';
import { device } from '../../Config/device';

import BottomBar from '../../Components/BottomBar';
import PageText from '../../Components/Text/PageText';
import PaginatedList from '../../Components/Lists/Paginated';
import ProfilePhoto from '../../Components/ProfilePhoto';

export const BottomBarHeightAdjustment = styled.div`
    height: ${props => props.Height}px;
    width: 100%;
`;

export const BottomBarItems = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    @media ${device.maxwidth.mobile} {
        :first-child {
            margin-bottom: 15px;
        }
    }

    @media ${device.minwidth.ipad} {
        justify-content: flex-start;

        :last-child {
            justify-content: flex-end;
        }
    }
`;

export const GroupMembersContainer = styled.div``;

export const GroupOwnerContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const GroupOwnerSubContainer = styled.div`
    align-items: center;
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px 80px;
`;

export const MultiOptionContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px 30px;

    @media ${device.minwidth.mobile} {
        :last-child {
            grid-column-start: 3;
        }
    }

    ${props => props.Clickable &&
        `
            @media(hover:hover) {
                :hover {
                    border-color: var(--blue-abel);
                    cursor: pointer;
                }
            }
        `
    }
`;

export const MultiOptionsContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;

    @media ${device.minwidth.mobile} {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const SelectedItems = styled(PageText)`
    background-color: var(--blue-abel);
    border-radius: 20px;
    padding: 5px 15px;
`;

export const StyledBottomBar = styled(BottomBar)`
    > div {
        flex-direction: column;

        @media ${device.minwidth.ipad} {
            flex-direction: row;
        }
    }
`;

export const StyledPaginatedList = styled(PaginatedList)`
    padding: 15px;

    > div:first-child {
        display: grid;
        grid-gap: 15px;
        grid-template-columns: repeat(1, 2fr);

        @media ${device.minwidth.mobile} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media ${device.minwidth.ipad} {
            grid-template-columns: repeat(2, 1fr);
        }

        @media ${device.minwidth.laptop} {
            grid-template-columns: repeat(4, 1fr);
        }
    }
`;

export const StyledProfilePhoto = styled(ProfilePhoto)``;