import styled from 'styled-components';
import { device } from '../../Config/device';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Image from '../../Components/Image';
import PageText from '../../Components/Text/PageText';

export const Container = styled.div`
    margin: 0 5%;
`;

export const CookingTimeContainer = styled.div`
    background-color: var(--white);
    border-radius: 10px;
    padding: 10px 40px;

    :first-child {
        margin-bottom: 10px;
    }

    @media ${device.minwidth.mobile} {
        :first-child {
            margin-bottom: 0px;
        }

        margin-left: 10px;
        margin-right: 10px;
        text-align: center;
    }
`;

export const CookingTimesAbsoluteContainer = styled.div`
    bottom: 10px;
    position: absolute;
    width: 100%;

    @media ${device.minwidth.mobile} {
        bottom: 25px;
    }
`;

export const CookingTimesContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media ${device.minwidth.mobile} {
        flex-direction: row;
    }
`;

export const DishImage = styled(Image)`
    height: 235px;
    object-fit: cover;
    width: 100%;

    @media ${device.minwidth.ipad} {
        height: 350px;
    }
`;

export const DishImageContainer = styled.div`
    position: relative;
`;

export const IngredientContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 15px;

    @media ${device.minwidth.mobile} {
        flex-direction: row;
    }
`;

export const IngredientListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const IngredientNameStyled = styled(PageText)`
    flex: 1;
`;

export const IngredientsContainer = styled.div``;

export const InstructionContainer = styled.div`
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    line-height: 21px;    
    margin-bottom: 15px;
    padding: 15px;
`;

export const InstructionsContainer = styled.div``;

export const StyledCopyDish = styled(ButtonGroup)`
    
`;