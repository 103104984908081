import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ShowErrorModalWarning } from '../../Services/Actions';

import { DataContainer, DataParentContainer, LapDetailContainer, StyledHeaders, StyledInputLabelSideBySide } from './styles';

import MultiText from '../Text/MultiText';

import { SecondsToMinutesAndSeconds, SecondsToTime } from '../../Functions';

class ActiveLap extends React.Component {
    state = {
        BreakMinutes: 0,
        BreakSeconds: 0,
        OriginalBreak: 0,
        TotalBreak: 0
    };

    componentDidMount() {
        var { BreakTime } = this.props;

        var { Minutes: BreakMinutes, Seconds: BreakSeconds } = SecondsToMinutesAndSeconds(BreakTime);

        BreakSeconds = +BreakSeconds === 0 ? '00' : +BreakSeconds < 10 ? `0${BreakSeconds}` : BreakSeconds;

        this.setState({ BreakMinutes, BreakSeconds, OriginalBreak: BreakTime, TotalBreak: BreakTime });
    }

    componentDidUpdate(prevProps) {
        var { WorkoutLapId: PrevWorkoutLapId } = prevProps;
        var { WorkoutLapId: CurrWorkoutLapId } = this.props;

        if (+PrevWorkoutLapId !== +CurrWorkoutLapId) {
            var { BreakTime } = this.props;

            var { Minutes: BreakMinutes, Seconds: BreakSeconds } = SecondsToMinutesAndSeconds(BreakTime);

            BreakSeconds = +BreakSeconds === 0 ? '00' : +BreakSeconds < 10 ? `0${BreakSeconds}` : BreakSeconds;

            this.setState({ BreakMinutes, BreakSeconds, OriginalBreak: BreakTime, TotalBreak: BreakTime });
        }
    }

    onBlurBreakTime = () => {
        var { t } = this.props;
        var TotalBreak = 0;
        TotalBreak += (this.state.BreakMinutes * 60) + +this.state.BreakSeconds;

        // Required to not be 0
            if (+TotalBreak === 0) {
                var { OriginalBreak } = this.state;
                var { Minutes: ResetBreakMinutes, Seconds: ResetBreakSeconds } = SecondsToMinutesAndSeconds(OriginalBreak);

                this.setState({ BreakMinutes: ResetBreakMinutes, BreakSeconds: ResetBreakSeconds, TotalBreak: OriginalBreak }, () => this.props.ShowErrorModalWarning({ ModalError: t('breaktime_greater_than_0') }));
            } else {
                var { Minutes: BreakMinutes, Seconds: BreakSeconds } = SecondsToMinutesAndSeconds(TotalBreak);

                BreakSeconds = +BreakSeconds === 0 ? '00' : +BreakSeconds < 10 ? `0${BreakSeconds}` : BreakSeconds;
                BreakMinutes = +BreakMinutes === 0 ? 0 : BreakMinutes;

                if (+TotalBreak !== +this.state.OriginalBreak) this.setState({ BreakMinutes, BreakSeconds, TotalBreak }, () => this.onUpdateWorkoutLap());
                else this.setState({ BreakMinutes, BreakSeconds, TotalBreak });
            }
    }

    onChangeBreakTime = (event, TimeType, OtherTimeTypeValue) => {
        var TotalBreak = 0;
        if (TimeType === 'Minutes') TotalBreak += (event.target.value * 60) + OtherTimeTypeValue;
        else if (TimeType === 'Seconds') TotalBreak += event.target.value + (OtherTimeTypeValue * 60);

        this.setState({ [`Break${TimeType}`]: event.target.value, TotalBreak });
    }

    onUpdateWorkoutLap = () => {
        var { TotalBreak } = this.state;

        this.setState({ OriginalBreak: TotalBreak }, () => this.props.OnUpdateWorkoutLap({ BreakTime: TotalBreak }));
    }

    render() {
        var { t } = this.props;
        var { Completed, HasTestLaps, ReadOnly } = this.props;
        var { BreakMinutes, BreakSeconds, TotalBreak } = this.state;

        var BreakText = SecondsToTime(TotalBreak);
        var BreakLabel = `${t('min_str').toLowerCase()} / ${t('Seconds_short').toLowerCase()}`;

        return (
            <LapDetailContainer className="Container">
                <DataParentContainer className="DataParentContainer">
                    <DataContainer className="DataContainer" Completed={Completed}>
                        <StyledHeaders FontFamily="bold" FontSize="medium-1" NoMargin Text={t('break')} />

                        {
                            (ReadOnly || (Completed || HasTestLaps)) ?
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'medium-3', Text: BreakText }
                                ]}
                            />
                        :
                            <StyledInputLabelSideBySide
                                InputFontFamily="semibold"
                                InputFontSize="medium-2"
                                LabelFontFamily="medium"
                                LabelFontSize="medium-1"
                                Label={BreakLabel}
                                OnBlur={this.onBlurBreakTime}
                                OnChange={(event, TimeType, OtherTimeTypeValue) => this.onChangeBreakTime(event, TimeType, OtherTimeTypeValue)}
                                Placeholder="0"
                                TimeValueMinutes={BreakMinutes}
                                TimeValueSeconds={BreakSeconds}
                                Type="time"
                            />
                        }
                    </DataContainer>
                </DataParentContainer>
            </LapDetailContainer>
        );
    }
}

ActiveLap.propTypes = {
    BreakTime: PropTypes.number.isRequired,
    Completed: PropTypes.bool,
    HasTestLaps: PropTypes.bool,
    ReadOnly: PropTypes.bool,
    OnUpdateWorkoutLap: PropTypes.func,
    WorkoutLapId: PropTypes.number
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device
    };
};

export default withTranslation()(connect(mapStateToProps, { ShowErrorModalWarning } )(ActiveLap));