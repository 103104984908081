import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearMarkAllAsRead, ClearNewlyReadNotificationId, ReactToNotification, ReadAllNotifications, ReadNotification, ViewNotificationHistory } from '../../Services/Actions';
import moment from 'moment';

import { CheckmarkIcon, Container, HasNewNotificationsContainer, InnerContainer, MarkAsReadContainer, NotificationContainer, NotificationContent, NotificationText, StyledLink, StyledLoadMore } from './styles';
import { ReactionContainer } from './styles';

import NotificationReactionPicker from './NotificationReactionPicker';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import ProfilePhoto from '../../Components/ProfilePhoto';
import Spacer from '../../Components/Spacer';

import Checkmark from '../../Assets/Icons/Checkmark_White.png';

import { GetNotificationUrl } from '../../Functions';

class Notifications extends React.Component {
    state = {
        CurrentDateTime: moment().utc(),
        HasNewNotifications: false,
        IsMoreNotifications: false,
        Loading: true,
        Notifications: [],
        PageNo: this.props.PageNo,
        PageSize: this.props.PageSize,
        TotalRecords: null
    };

    componentDidMount() {
        this._isMounted = true;

        this.onLoadNotificationHistory();
    }

    componentDidUpdate(prevProps) {
        if (this._isMounted && !prevProps.NewlyReadNotificationId && this.props.NewlyReadNotificationId) this.onMarkNotificationAsRead(this.props.NewlyReadNotificationId);
        if (this._isMounted && !prevProps.NewNotification && this.props.NewNotification) {
            if (this.props.AutomaticallyRefreshNewNotifications) this.onLoadNotificationHistory();
            else this.setState({ HasNewNotifications: true });
        }
        if (this._isMounted && !prevProps.MarkAllAsRead && this.props.MarkAllAsRead) this.onUpdateMarkAllAsRead();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    onChangeReaction = ({ NewEmoji, NewEmojiUnicode, NotificationId, NotificationIndex }) => {
        var Notifications = [ ...this.state.Notifications ];

        // Only decrement notification count if IsRead = false, as it is now getting marked as read
        var IsRead_Already = Notifications[NotificationIndex].isRead;

        Notifications[NotificationIndex].isRead = true;
        Notifications[NotificationIndex].reaction = NewEmojiUnicode;

        var UserId = this.props.UserDetails.UserId;

        this.props.ReactToNotification({ IsRead_Already, notificationId: NotificationId, reaction: NewEmoji, UserId });
        this.props.ClearNewlyReadNotificationId();
        
        this.setState({ Notifications });
    }

    onClickNotification = ({ IsRead, MarkOnly, NotificationId, NotificationIndex, to }) => {
        var { UserId } = this.props.UserDetails;

        if (!!!IsRead || MarkOnly) this.props.ReadNotification({ NotificationId, UserId });

        if (this.props.IsModal && !MarkOnly) this.props.OnClickNotification();
        else if (this._isMounted && !!!IsRead) {
            var NewNotifications = [ ...this.state.Notifications ];
            NewNotifications[NotificationIndex].isRead = true;

            this.setState({ Notifications: NewNotifications });
        }

        window.open(to, '_self');
    }

    onLoadNotificationHistory = Increment => {
        var { PageNo, PageSize } = this.state;
        var { UserId } = this.props.UserDetails;

        if (Increment) PageNo = PageNo + 1;
        else PageNo = 1;

        this.setState({ Loading: true });
        this.props.ViewNotificationHistory({ PageNo, PageSize, UserId }).then(({ IsMoreResults: IsMoreNotifications, Notifications, TotalRecords }) => {
            if (Increment) Notifications = [ ...this.state.Notifications, ...Notifications ];

            this.setState({ CurrentDateTime: moment().utc(), IsMoreNotifications, Loading: false, Notifications, PageNo, TotalRecords })
        });
    }

    onMarkAllAsRead = () => {
        var { UserId } = this.props.UserDetails;

        this.props.ReadAllNotifications({ UserId }).then(() => {
            if (!this.props.TryingReadAllNotificationsError) this.onUpdateMarkAllAsRead();
        })
    }

    onMarkNotificationAsRead = NotificationId => {
        var NewNotifications = [ ...this.state.Notifications ];

        for (var i = 0; i < NewNotifications.length; i++) {
            if (+NewNotifications[i].NotificationId === +NotificationId) {
                NewNotifications[i].IsRead = true;

                break;
            }
        }

        this.setState({ Notifications: NewNotifications });
        this.props.ClearNewlyReadNotificationId();
    }

    onReloadNotificationHistory = () => {
        window.scrollTo(0,0);
        this.setState({ HasNewNotifications: false });
        this.onLoadNotificationHistory();
    }

    onUpdateMarkAllAsRead = () => {
        var NewNotifications = this.state.Notifications.map(ExistingNotification => ({ ...ExistingNotification, isRead: 1 }));

        this.setState({ CurrentDateTime: moment().utc(), Notifications: NewNotifications });
        this.props.ClearMarkAllAsRead();
    }

    renderHasNewNotifications = () => {
        var { t } = this.props;

        return (
            <HasNewNotificationsContainer onClick={this.onReloadNotificationHistory}>
                <PageText FontColor="white" FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('load_new_notification')} TextAlign="center" />
            </HasNewNotificationsContainer>
        );
    }

    renderNotificationContent = ({ Body, Title }) => {
        return (
            <NotificationText>
                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={Title} TextAlign="Left" />

                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={Body} TextAlign="Left" />
            </NotificationText>
        );
    }

    renderNotificationListItem = ({ Item, ItemIndex }) => {
        var { CurrentDateTime, Notifications } = this.state;
        var { body: Body, referenceData, createdAt: CreatedDate, isRead: IsRead, id: NotificationId, typeName:  NotificationType, reaction: ReactionType, title: Title, senderGender: SourceUserGender, senderPhoto: SourceUserPhoto } = Item;
        var { IsModal, UserDetails } = this.props;

        var NewCreatedDate = moment(CreatedDate).from(CurrentDateTime);

        var FirstItem = ItemIndex === 0;
        var LastItem = ItemIndex === Notifications.length - 1;

        var to = GetNotificationUrl({ notificationType: NotificationType, referenceData, userId: UserDetails.UserId });

        return (
            <InnerContainer>
                <StyledLink onClick={() => this.onClickNotification({ IsRead, NotificationId, NotificationIndex: ItemIndex })} to={to}>
                    <NotificationContainer FirstItem={FirstItem} IsModal={IsModal} IsRead={IsRead} LastItem={LastItem}>
                        <NotificationContent>
                            <ProfilePhoto Size="medium" Source={SourceUserPhoto} Sex={SourceUserGender || 'Male'} ShowOverlay={false} />

                            {this.renderNotificationContent({ Body, NotificationType, Title })}
                        </NotificationContent>

                        {<PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-end" NoMargin Text={NewCreatedDate} TextAlign="right" WhiteSpace="nowrap" />}
                    </NotificationContainer>
                </StyledLink>

                <ReactionContainer className="ReactionContainer" IsModal={IsModal} IsRead={IsRead}>
                    <NotificationReactionPicker OnChangeReaction={({ NewEmoji, NewEmojiUnicode }) => this.onChangeReaction({ NewEmoji, NewEmojiUnicode, NotificationId, NotificationIndex: ItemIndex })} Reaction={ReactionType} />
                </ReactionContainer>

                {
                    !IsRead &&
                    <MarkAsReadContainer onClick={() => this.onClickNotification({ IsRead, MarkOnly: true, NotificationId, NotificationIndex: ItemIndex })} FirstItem={FirstItem} IsModal={IsModal} LastItem={LastItem}>
                        <CheckmarkIcon alt="Mark as read" src={Checkmark} />
                    </MarkAsReadContainer>
                }
            </InnerContainer>
        );
    }

    render() {
        var { t } = this.props;
        var { IsModal, TryingReadAllNotifications, UnreadNotificationCount } = this.props;
        var { HasNewNotifications, IsMoreNotifications, Loading: StateLoading, Notifications, PageNo, PageSize, TotalRecords } = this.state;

        if (IsModal) {
            IsMoreNotifications = 0;
            StateLoading = false;
        }

        return (
            <>
                {(StateLoading) && <Loading />}

                <Container>
                    {(HasNewNotifications && !IsModal) && this.renderHasNewNotifications()}

                    {TryingReadAllNotifications && <Loading />}

                    {
                        !!+UnreadNotificationCount && !IsModal &&
                        <>
                            <ButtonGroup
                                Buttons={[
                                    {
                                        BackgroundColor: 'white-concrete',
                                        BackgroundColorHover: 'white-concrete-hover',
                                        FontFamily: 'semibold',
                                        FontSize: 'small',
                                        OnClick: this.onMarkAllAsRead,
                                        Title: t('read_all')
                                    }
                                ]}
                                ButtonWidth="fit-content"
                                NotTouching
                            />

                            <Spacer Size="small" />
                        </>
                    }

                    <StyledLoadMore
                        ActiveSearch
                        CompletedFirstSearch
                        HideViewCount={!!IsModal}
                        // IsLoading={TryingViewNotificationHistory}
                        IsLoading={this.state.Loading}
                        ItemDescriptionType={t('ptadmin_navigation_notifications').toLowerCase()}
                        ItemId="id"
                        ItemName="typeName"
                        ItemRenderer={this.renderNotificationListItem}
                        Items={Notifications}
                        IsModal={IsModal}
                        HasMoreItems={!!+IsMoreNotifications}
                        LoadMore={() => this.onLoadNotificationHistory(true)}
                        NewSearch={false}
                        NoItemsText={t('no_notifications')}
                        PageNo={PageNo}
                        PageSize={PageSize}
                        TotalRecords={TotalRecords}
                    />
                </Container>
            </>
        );
    }
}

Notifications.propTypes = {
    AutomaticallyRefreshNewNotifications: PropTypes.bool,
    IsModal: PropTypes.bool,
    OnClickNotification: PropTypes.func,
    PageNo: PropTypes.number,
    PageString: PropTypes.number
}

Notifications.defaultProps = {
    AutomaticallyRefreshNewNotifications: false,
    IsModal: false,
    OnClickNotification: () => null,
    PageNo: 1,
    PageSize: 30
}

const mapStateToProps = state => {
    return {
        UserDetails: state.Auth.UserDetails,

        MarkAllAsRead: state.Notifications.MarkAllAsRead,
        NewNotification: state.Notifications.NewNotification,
        NewlyReadNotificationId: state.Notifications.NewlyReadNotificationId,
        UnreadNotificationCount: state.Notifications.UnreadNotificationCount,

        TryingReadAllNotifications: state.Notifications.TryingReadAllNotifications,
        TryingReadAllNotificationsError: state.Notifications.TryingReadAllNotificationsError,
        TryingViewNotificationHistory: state.Notifications.TryingViewNotificationHistory,
        TryingViewNotificationHistoryError: state.Notifications.TryingViewNotificationHistoryError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearMarkAllAsRead, ClearNewlyReadNotificationId, ReactToNotification, ReadAllNotifications, ReadNotification, ViewNotificationHistory } )(Notifications));