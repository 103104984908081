import React from 'react';
import PropTypes from 'prop-types';

import PageText from '../../Text/PageText';
import Spacer from '../../Spacer';

import { ButtonContainer, ButtonIcon, ButtonGroupContainer, IconSVGContainer, StyledInput, StyledLink } from './styles';

class ButtonGroup extends React.Component {
    render() {
        var { Buttons, ButtonWidth, className, ContainerWidth, JustifyContent, NormalWhiteSpace, NotTouching, OwnRows } = this.props;

        return (
          <ButtonGroupContainer
            className={className}
            ContainerWidth={ContainerWidth}
            FitContent={ButtonWidth ? 1 : 0}
            JustifyContent={JustifyContent}
            OwnRows={OwnRows}
          >
            {Buttons.map(
              (
                {
                  BackgroundColor,
                  BackgroundColorHex,
                  BackgroundColorHover,
                  BackgroundColorSelected,
                  BackgroundGradient,
                  Border,
                  BorderHover,
                  Color,
                  ColorHex,
                  ColorHover,
                  ColorSelected,
                  Disabled,
                  FontFamily,
                  FontSize,
                  Icon,
                  IconOnly,
                  IconPosition,
                  IconSize,
                  IconSVG,
                  IconSVGColor,
                  InputProps,
                  IsInput,
                  IsLink,
                  JustifyContent: JustifyContentInner,
                  LinkProps,
                  LinkUrl,
                  Margin,
                  NoBackground,
                  OnClick,
                  OnMouseEnter,
                  OnMouseLeave,
                  Padding,
                  Selected,
                  Size,
                  Subtitle,
                  TextAlign,
                  Title,
                  WordBreak,
                },
                index
              ) => {
                if (!!IsLink && !Disabled) {
                  return (
                    <StyledLink
                      key={index}
                      onClick={Disabled ? () => null : OnClick}
                      // to={LinkUrl}
                      to={{ pathname: `${LinkUrl}`, state: LinkProps }}
                      backgroundcolor={BackgroundColor}
                      backgroundcolorhex={BackgroundColorHex}
                      backgroundcolorhover={BackgroundColorHover}
                      backgroundcolorselected={BackgroundColorSelected}
                      border={Border}
                      borderhover={BorderHover}
                      buttonwidth={ButtonWidth}
                      color={Color}
                      colorhex={ColorHex}
                      colorhover={ColorHover}
                      colorselected={ColorSelected}
                      disabled={Disabled}
                      fontFamily={FontFamily}
                      fontSize={FontSize}
                      iconposition={IconPosition}
                      isbutton={1}
                      normalwhitespace={NormalWhiteSpace}
                      nottouching={NotTouching}
                      ownrows={OwnRows}
                      selected={Selected}
                      size={Size}
                      textalign={TextAlign}
                    >
                      {Icon &&
                        (IconPosition === "left" || IconPosition === "top") && (
                          <ButtonIcon
                            IconOnly={IconOnly}
                            IconPosition={IconPosition}
                            IconSize={IconSize}
                            Title={Title}
                            src={Icon}
                            alt="Button icon"
                          />
                        )
                      }

                      {IconSVG && (IconPosition === "left" || IconPosition === "top") && <IconSVGContainer className="IconSVGContainer" IconSVGColor={IconSVGColor}><IconSVG /></IconSVGContainer>}

                      {!IconOnly && Title}

                      {!!IsInput && <StyledInput {...InputProps} />}

                      {IconSVG && (IconPosition === "right") && <IconSVGContainer className="IconSVGContainer" IconSVGColor={IconSVGColor}><IconSVG /></IconSVGContainer>}

                      {Icon && IconPosition === "right" && (
                        <ButtonIcon
                          IconOnly={IconOnly}
                          IconSize={IconSize}
                          src={Icon}
                          alt="Button icon"
                        />
                      )}
                    </StyledLink>
                  );
                }

                return (
                  <ButtonContainer
                    key={index}
                    BackgroundColor={BackgroundColor}
                    BackgroundColorHex={BackgroundColorHex}
                    BackgroundColorHover={BackgroundColorHover}
                    BackgroundColorSelected={BackgroundColorSelected}
                    BackgroundGradient={BackgroundGradient}
                    Border={Border}
                    BorderHover={BorderHover}
                    ButtonWidth={ButtonWidth}
                    Color={Color}
                    ColorHex={ColorHex}
                    ColorHover={ColorHover}
                    ColorSelected={ColorSelected}
                    Disabled={Disabled}
                    FontFamily={FontFamily}
                    FontSize={FontSize}
                    IconPosition={IconPosition}
                    IsButton={OnClick || !!IsInput ? 1 : 0}
                    JustifyContent={JustifyContentInner}
                    Margin={Margin}
                    NoBackground={NoBackground}
                    NormalWhiteSpace={NormalWhiteSpace}
                    NotTouching={NotTouching}
                    onClick={Disabled ? () => null : OnClick}
                    onMouseEnter={Disabled ? () => null : OnMouseEnter}
                    onMouseLeave={Disabled ? () => null : OnMouseLeave}
                    OwnRows={OwnRows}
                    Padding={Padding}
                    Selected={Selected}
                    Size={Size}
                    TextAlign={TextAlign}
                    WordBreak={WordBreak}
                  >
                    {Icon &&
                      (!IconPosition ||
                        IconPosition === "left" ||
                        IconPosition === "top") && (
                        <ButtonIcon
                          IconOnly={IconOnly}
                          IconPosition={IconPosition}
                          IconSize={IconSize}
                          src={Icon}
                          alt="Button icon"
                        />
                      )
                    }

                    {IconSVG && (IconPosition === "left" || IconPosition === "top") && <IconSVGContainer className="IconSVGContainer" IconSVGColor={IconSVGColor}><IconSVG /></IconSVGContainer>}

                    {!IconOnly && Title}

                    {!IconOnly && !!Subtitle && (
                      <>
                        <Spacer Size="extra-extra-small" />

                        <PageText
                          FontFamily="medium-italic"
                          FontSize="small"
                          JustifyContent="center"
                          NoMargin
                          Text={Subtitle}
                          TextAlign="center"
                        />
                      </>
                    )}

                    {!!IsInput && <StyledInput {...InputProps} />}

                    {IconSVG && (IconPosition === "right") && <IconSVGContainer className="IconSVGContainer" IconSVGColor={IconSVGColor}><IconSVG /></IconSVGContainer>}

                    {Icon && IconPosition === "right" && (
                      <ButtonIcon
                        IconOnly={IconOnly}
                        IconSize={IconSize}
                        src={Icon}
                        alt="Button icon"
                      />
                    )}
                  </ButtonContainer>
                );
              }
            )}
          </ButtonGroupContainer>
        );
    }
}

ButtonGroup.propTypes = {
    Buttons: PropTypes.array.isRequired,
    ButtonWidth: PropTypes.string,
    ContainerWidth: PropTypes.string,
    NormalWhiteSpace: PropTypes.bool,
    NotTouching: PropTypes.bool,
    OwnRows: PropTypes.bool
}

export default ButtonGroup;