import { arrayMove } from '../../Functions';
import {
    CLEAR_ADD_TRAINER_CLIENT_ERROR,
    TRYING_ADD_TRAINER_CLIENT,
    TRYING_ADD_TRAINER_CLIENT_SUCCESS,
    TRYING_ADD_TRAINER_CLIENT_ERROR,

    TRYING_DELETE_TRAINER_CLIENT,
    TRYING_DELETE_TRAINER_CLIENT_SUCCESS,
    TRYING_DELETE_TRAINER_CLIENT_ERROR,

    TRYING_DELETE_TRAINER_GROUP,
    TRYING_DELETE_TRAINER_GROUP_SUCCESS,
    TRYING_DELETE_TRAINER_GROUP_ERROR,

    TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT,
    TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT_SUCCESS,
    TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT_ERROR,

    TRYING_GET_CHOOSE_CLIENTS,
    TRYING_GET_CHOOSE_CLIENTS_SUCCESS,
    TRYING_GET_CHOOSE_CLIENTS_ERROR,

    TRYING_GET_CLIENTS,
    TRYING_GET_CLIENTS_SUCCESS,
    TRYING_GET_CLIENTS_ERROR,

    CLEAR_TRAINER_CLIENTS,
    TRYING_GET_TRAINER_CLIENTS,
    TRYING_GET_TRAINER_CLIENTS_SUCCESS,
    TRYING_GET_TRAINER_CLIENTS_ERROR,

    TRYING_UPDATE_WORKOUT_ACCESS,
    TRYING_UPDATE_WORKOUT_ACCESS_SUCCESS,
    TRYING_UPDATE_WORKOUT_ACCESS_ERROR,

    TRYING_INSERTEDIT_TRAINER_GROUP,
    TRYING_INSERTEDIT_TRAINER_GROUP_SUCCESS,
    TRYING_INSERTEDIT_TRAINER_GROUP_ERROR,

    TRYING_REORDER_TRAINER_GROUPS,
    TRYING_REORDER_TRAINER_GROUPS_SUCCESS,
    TRYING_REORDER_TRAINER_GROUPS_ERROR,
    UPDATE_TRAINER_GROUP_ORDER,

    TRYING_SEARCH_TRAINER_CLIENTS,
    TRYING_SEARCH_TRAINER_CLIENTS_SUCCESS,
    TRYING_SEARCH_TRAINER_CLIENTS_ERROR,

    TRYING_SEARCH_TRAINER_CLIENTS_SAYT,
    TRYING_SEARCH_TRAINER_CLIENTS_SAYT_SUCCESS,
    TRYING_SEARCH_TRAINER_CLIENTS_SAYT_ERROR,

    TRYING_UPDATE_TRAINER_CLIENT_COLOR,
    TRYING_UPDATE_TRAINER_CLIENT_COLOR_SUCCESS,
    TRYING_UPDATE_TRAINER_CLIENT_COLOR_ERROR
} from '../Types';

const INITIAL_STATE = {
    AllClients: [],
    TrainerClients: [],
    TrainerGroups: [],

    TryingAddTrainerClient: false,
    TryingAddTrainerClientError: null,
    TryingDeleteTrainerClient: false,
    TryingDeleteTrainerClientError: null,
    TryingDeleteTrainerGroup: false,
    TryingDeleteTrainerGroupError: null,
    TryingGetAllTrainerGroupsForNewClient: false,
    TryingGetAllTrainerGroupsForNewClientError: null,
    TryingGetChooseClients: false,
    TryingGetChooseClientsError: null,
    TryingGetClients: false,
    TryingGetClientsError: null,
    TryingGetTrainerClients: false,
    TryingGetTrainerClientsError: null,
    TryingInsertEditTrainerGroup: false,
    TryingInsertEditTrainerGroupError: null,
    TryingSearchTrainerClients: false,
    TryingSearchTrainerClientsError: null,
    TryingSearchTrainerClientsSAYT: false,
    TryingSearchTrainerClientsSAYTError: null
};

var i, a;

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_ADD_TRAINER_CLIENT_ERROR:
            return { ...state, TryingAddTrainerClient: false, TryingAddTrainerClientError: null };
        case TRYING_ADD_TRAINER_CLIENT:
            return { ...state, TryingAddTrainerClient: true, TryingAddTrainerClientError: null };
        case TRYING_ADD_TRAINER_CLIENT_ERROR:
            return { ...state, TryingAddTrainerClient: false, TryingAddTrainerClientError: action.payload };
        case TRYING_ADD_TRAINER_CLIENT_SUCCESS:
            return { ...state, TryingAddTrainerClient: false, TryingAddTrainerClientError: null };

        case TRYING_DELETE_TRAINER_CLIENT:
            // eslint-disable-next-line
            var ClientId = +action.payload;
            // eslint-disable-next-line
            var { AllClients } = state;

            // eslint-disable-next-line
            var NewAllClients = []; var NewClients = [];

            for (i = 0; i < AllClients.length; i++) {
                if (ClientId !== +AllClients[i].UserId) NewAllClients.push({ ...AllClients[i] });
            }

            // for (i = 0; i < Clients.length; i++) {
            //     // eslint-disable-next-line
            //     var Group = { ...Clients[i], Clients: [] };

            //     for (a = 0; a < Clients[i].Clients.length; a++) {
            //         if (ClientId !== Number(Clients[i].Clients[a].UserId)) Group.Clients.push({ ...Clients[i].Clients[a] });
            //     }

            //     if (Group.Clients.length) NewClients.push(Group);
            // }

            return { ...state, AllClients: NewAllClients, TryingDeleteTrainerClient: true, TryingDeleteTrainerClientError: null };
        case TRYING_DELETE_TRAINER_CLIENT_ERROR:
            return { ...state, TryingDeleteTrainerClient: false, TryingDeleteTrainerClientError: action.payload };
        case TRYING_DELETE_TRAINER_CLIENT_SUCCESS:
            return { ...state, TryingDeleteTrainerClient: false, TryingDeleteTrainerClientError: null };

        case TRYING_DELETE_TRAINER_GROUP:
            return { ...state, TryingDeleteTrainerGroup: true, TryingDeleteTrainerGroupError: null };
        case TRYING_DELETE_TRAINER_GROUP_ERROR:
            return { ...state, TryingDeleteTrainerGroup: false, TryingDeleteTrainerGroupError: action.payload };
        case TRYING_DELETE_TRAINER_GROUP_SUCCESS:
            return { ...state, TryingDeleteTrainerGroup: false, TryingDeleteTrainerGroupError: null };

        case TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT:
            return { ...state, TryingGetAllTrainerGroupsForNewClient: true, TryingGetAllTrainerGroupsForNewClientError: null };
        case TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT_ERROR:
            return { ...state, TryingGetAllTrainerGroupsForNewClient: false, TryingGetAllTrainerGroupsForNewClientError: action.payload };
        case TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT_SUCCESS:
            return { ...state, TryingGetAllTrainerGroupsForNewClient: false, TryingGetAllTrainerGroupsForNewClientError: null };

        case TRYING_GET_CHOOSE_CLIENTS:
            return { ...state, TryingGetChooseClients: true, TryingGetChooseClientsError: null };
        case TRYING_GET_CHOOSE_CLIENTS_ERROR:
            return { ...state, TryingGetChooseClients: false, TryingGetChooseClientsError: action.payload };
        case TRYING_GET_CHOOSE_CLIENTS_SUCCESS:
            return { ...state, TryingGetChooseClients: false, TryingGetChooseClientsError: null };

        case TRYING_GET_CLIENTS:
            return { ...state, TryingGetClients: true, TryingGetClientsError: null };
        case TRYING_GET_CLIENTS_ERROR:
            return { ...state, TryingGetClients: false, TryingGetClientsError: action.payload };
        case TRYING_GET_CLIENTS_SUCCESS:
            return { ...state, AllClients: action.payload.Clients, TryingGetClients: false, TryingGetClientsError: null };

        case CLEAR_TRAINER_CLIENTS:
            return { ...state, TrainerClients: [], TrainerGroups: [] };
        case TRYING_GET_TRAINER_CLIENTS:
            return { ...state, TryingGetTrainerClients: true, TryingGetTrainerClientsError: null };
        case TRYING_GET_TRAINER_CLIENTS_ERROR:
            return { ...state, TryingGetTrainerClients: false, TryingGetTrainerClientsError: action.payload };
        case TRYING_GET_TRAINER_CLIENTS_SUCCESS:
            return { ...state, TrainerClients: action.payload.Clients, TrainerGroups: action.payload.Groups, TryingGetTrainerClients: false, TryingGetTrainerClientsError: null };

        case TRYING_UPDATE_WORKOUT_ACCESS:
            if (action.payload.ReturnDataType !== 'Dashboard') return { ...state };
            return { ...state, TryingGetClients: true, TryingGetClientsError: null };
        case TRYING_UPDATE_WORKOUT_ACCESS_ERROR:
            if (action.payload.ReturnDataType !== 'Dashboard') return { ...state };
            return { ...state, TryingGetClients: false, TryingGetClientsError: action.payload.ErrorMessage };
        case TRYING_UPDATE_WORKOUT_ACCESS_SUCCESS:
            if (action.payload.ReturnDataType !== 'Dashboard') return { ...state };

            return { ...state, AllClients: action.payload.Clients, TryingGetClients: false, TryingGetClientsError: null };

        case TRYING_INSERTEDIT_TRAINER_GROUP:
            return { ...state, TryingInsertEditTrainerGroup: true, TryingInsertEditTrainerGroupError: null };
        case TRYING_INSERTEDIT_TRAINER_GROUP_ERROR:
            return { ...state, TryingInsertEditTrainerGroup: false, TryingInsertEditTrainerGroupError: action.payload };
        case TRYING_INSERTEDIT_TRAINER_GROUP_SUCCESS:
            return { ...state, TryingInsertEditTrainerGroup: false, TryingInsertEditTrainerGroupError: null };

        case TRYING_REORDER_TRAINER_GROUPS:
            return { ...state, TryingReorderTrainerGroups: true, TryingReorderTrainerGroupsError: null };
        case TRYING_REORDER_TRAINER_GROUPS_ERROR:
            return { ...state, TryingReorderTrainerGroups: false, TryingReorderTrainerGroupsError: action.payload };
        case TRYING_REORDER_TRAINER_GROUPS_SUCCESS:
            return { ...state, TryingReorderTrainerGroups: false, TryingReorderTrainerGroupsError: null };
        case UPDATE_TRAINER_GROUP_ORDER:
            var { OldIndex, NewIndex } = action.payload;

            var ReorderedClients = [ ...state.Clients ];
            ReorderedClients = arrayMove(ReorderedClients, OldIndex, NewIndex);

            return { ...state, Clients: ReorderedClients };

        case TRYING_SEARCH_TRAINER_CLIENTS:
            return { ...state, TryingSearchTrainerClients: true, TryingSearchTrainerClientsError: null };
        case TRYING_SEARCH_TRAINER_CLIENTS_ERROR:
            return { ...state, TryingSearchTrainerClients: false, TryingSearchTrainerClientsError: action.payload };
        case TRYING_SEARCH_TRAINER_CLIENTS_SUCCESS:
            return { ...state, TryingSearchTrainerClients: false, TryingSearchTrainerClientsError: null };

        case TRYING_SEARCH_TRAINER_CLIENTS_SAYT:
            return { ...state, TryingSearchTrainerClientsSAYT: true, TryingSearchTrainerClientsSAYTError: null };
        case TRYING_SEARCH_TRAINER_CLIENTS_SAYT_ERROR:
            return { ...state, TryingSearchTrainerClientsSAYT: false, TryingSearchTrainerClientsSAYTError: action.payload };
        case TRYING_SEARCH_TRAINER_CLIENTS_SAYT_SUCCESS:
            return { ...state, TryingSearchTrainerClientsSAYT: false, TryingSearchTrainerClientsSAYTError: null };

        case TRYING_UPDATE_TRAINER_CLIENT_COLOR:
            // eslint-disable-next-line
            var { ClientColor, ClientId } = action.payload;
            ClientId = Number(ClientId);
            
            // eslint-disable-next-line
            var { AllClients, Clients } = state;

            // eslint-disable-next-line
            var NewAllClients = []; var NewClients = [];

            for (i = 0; i < AllClients.length; i++) {
                NewAllClients.push({ ...AllClients[i], ClientColor: ClientId === Number(AllClients[i].UserId) ? ClientColor : AllClients[i].ClientColor });
            }

            for (i = 0; i < Clients.length; i++) {
                // eslint-disable-next-line
                var Group = { ...Clients[i], Clients: [] };

                for (a = 0; a < Clients[i].Clients.length; a++) {
                    Group.Clients.push({ ...Clients[i].Clients[a], ClientColor: ClientId === Number(Clients[i].Clients[a].UserId) ? ClientColor : Clients[i].Clients[a].ClientColor });
                }

                NewClients.push(Group);
            }

            return { ...state, AllClients: NewAllClients, Clients: NewClients, TryingDeleteTrainerClient: true, TryingDeleteTrainerClientError: null };
        case TRYING_UPDATE_TRAINER_CLIENT_COLOR_ERROR:
            return { ...state, TryingDeleteTrainerClient: false, TryingDeleteTrainerClientError: action.payload };
        case TRYING_UPDATE_TRAINER_CLIENT_COLOR_SUCCESS:
            return { ...state, TryingDeleteTrainerClient: false, TryingDeleteTrainerClientError: null };

        default:
            return state;
    }
};
