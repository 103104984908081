import styled from 'styled-components';
import { device } from '../../Config/device';

import Input from '../../Components/Form/Input';

export const ExpensesContainer = styled.div``;

export const FiltersContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const FiltersContainer2 = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${device.maxwidth.ipad} {
        > div:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    @media ${device.minwidth.laptop} {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        > div:not(:last-child) {
            margin-right: 15px;
        }
    }
`;

export const PageNumberContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white-concrete)'};
    border-radius: 10px;
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    display: flex;
    flex-direction: row;
    font-family: var(--font-family-medium);
    font-size: var(--font-size-medium-1);
    height: 35px;
    justify-content: center;
    width: 35px;

    @media(hover:hover) {
        :hover {
            background-color: var(--blue-astronaut);
            color: var(--white);
            cursor: pointer;
        }
    }
`;

export const PageNumbersContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 10px;

    > div:not(:last-child) {
        margin-right: 10px;
    }
`;

export const PaginationContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media ${device.maxwidth.mobile_small} {
        flex-direction: column;
        justify-content: center;
    }
`;

export const StatsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
    max-width: 100%;
    width: 600px;

    @media ${device.minwidth.laptop} {
        > div:first-child {
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
        }
    
        > div:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
        }
    }

    @media ${device.maxwidth.mobile} {
        grid-template-columns: 1fr;

        > div:first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    
        > div:last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
`;

export const StatsInnerContainer = styled.div`
    background-color: var(--white-concrete);
    padding: 10px;
    overflow: hidden;
`;

export const StyledSearchInput = styled(Input)`
    max-width: 100%;
    width: 200px;

    > input {
        background-color: var(--white-concrete);
        border: none;
        border-radius: 10px;
    }
`;

export const TopFiltersContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @media ${device.minwidth.laptop} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const ViewCount = styled.div`
    text-align: center;
`;

export const YearlyChoicesContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;