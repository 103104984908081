import {
    CLEAR_GYMS,
    TRYING_GET_GYMS,
    TRYING_GET_GYMS_SUCCESS,
    TRYING_GET_GYMS_ERROR
} from '../Types';

const INITIAL_STATE = {
    GymList: [],
    GymList_TotalRecords: null,
    IsMoreGyms: false,
    
    TryingSearchGyms: false,
    TryingSearchGymsError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_GYMS:
            return { ...state, GymList: [], GymList_TotalRecords: null, IsMoreGyms: false };
        case TRYING_GET_GYMS:
            return { ...state, TryingSearchGyms: true, TryingSearchGymsError: null };
        case TRYING_GET_GYMS_ERROR:
            return { ...state, TryingSearchGyms: false, TryingSearchGymsError: action.payload };
        case TRYING_GET_GYMS_SUCCESS:
            // eslint-disable-next-line
            var { AddToExisting, Gyms_IsMoreResults, Gyms, TotalRecords } = action.payload;
            var GymList = [];

            if (AddToExisting) GymList = [ ...state.GymList, ...Gyms ];
            else GymList = [ ...Gyms ];

            return { ...state, GymList, GymList_TotalRecords: Number(TotalRecords), IsMoreGyms: Gyms_IsMoreResults ? true : false, TryingSearchGyms: false, TryingSearchGymsError: null };
        default:
            return state;
    }
};
