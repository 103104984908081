import {
    TRYING_SEARCH_GROUPS,
    TRYING_SEARCH_GROUPS_SUCCESS,
    TRYING_SEARCH_GROUPS_ERROR
} from '../Types';

const INITIAL_STATE = {
    Groups: [],

    TryingSearchGroups: false,
    TryingSearchGroupsError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_SEARCH_GROUPS:
            return { ...state, TryingSearchGroups: true, TryingSearchGroupsError: null };
        case TRYING_SEARCH_GROUPS_ERROR:
            return { ...state, TryingSearchGroups: false, TryingSearchGroupsError: action.payload };
        case TRYING_SEARCH_GROUPS_SUCCESS:
            return { ...state, TryingSearchGroups: false, TryingSearchGroupsError: null };

        default:
            return state;
    }
};
