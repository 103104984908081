import styled from 'styled-components';

export const NutrientCell = styled.div`
    background-color: var(--${props => !props.WithinLimits ? 'white' : props.WithinLimits === 'below' ? 'abel-blue' : props.WithinLimits === 'above' ? 'red-bittersweet' : 'green'});
`;

export const NutrientCellHeader = styled.div``;

export const NutritionProgressTableContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const NutrientRow = styled.div`
    align-items: center;
    border-bottom: 1px solid var(--black);
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: space-between;
    padding: 5px 10px;
    width: 100%;
`;

export const NutrientRowHeader = styled.div`
    align-items: center;
    background: var(--black);
    color: var(--white);
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: space-between;
    padding: 5px 10px;
    width: 100%;
`;