import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { UnitContainer, UnitsContainer } from './styles';

import AbelModal from '../../Components/Modal';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

class FoodUnits extends React.Component {
    onSelectUnit = ({ UnitId, UnitName }) => {
        var { OnHide, OnSelectUnit, SelectedUnitId } = this.props;

        if (Number(UnitId) === SelectedUnitId) OnHide();
        else OnSelectUnit({ UnitId, UnitName });
    }

    render() {
        var { t } = this.props;
        var { FoodUnits, OnHide, SelectedUnitId, Show } = this.props;

        return (
            <AbelModal
                GA_PathName="FoodUnits"
                Show={Show}
                Size="medium"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('MealPlan_Pick_unit')} TextAlign="center" />

                <Spacer Size="medium" />

                <UnitsContainer>
                    {
                        FoodUnits.map(({ UnitId, UnitName}) => {
                            var Selected = Number(UnitId) === SelectedUnitId;

                            return (
                                <UnitContainer key={UnitId} Selected={Selected} onClick={() => this.onSelectUnit({ UnitId, UnitName })}>
                                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={UnitName} />
                                </UnitContainer>
                            );
                        })
                    }
                </UnitsContainer>
            </AbelModal>
        );
    }
}

FoodUnits.propTypes = {
    FoodUnits: PropTypes.array.isRequired,
    OnHide: PropTypes.func.isRequired,
    OnSelectUnit: PropTypes.func.isRequired,
    SelectedUnitId: PropTypes.number,
    Show: PropTypes.bool.isRequired
}

export default withTranslation()(FoodUnits);