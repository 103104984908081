import styled from 'styled-components';
import Image from '../Image';

export const Container = styled.div`
    position: relative;
`;

export const StyledImage = styled(Image)`
    border-radius: 50%;
    height: ${props => props.Size ? `var(--profilephoto-size-${props.Size.toLowerCase()})` : 'var(--profilephoto-size-large)'};
    width: ${props => props.Size ? `var(--profilephoto-size-${props.Size.toLowerCase()})` : 'var(--profilephoto-size-large)'};

    ${props => props.OnClick ? `:hover { cursor: pointer; }` : ''}
`;