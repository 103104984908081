import React from 'react';

import Popover from 'react-popover';

import * as styles from './styles';

import PageText from '../Text/PageText';

class PopoverItem extends React.Component {
    state = { PopoverIsOpen: false };

    onTogglePopover = PopoverIsOpen => {
        this.setState({ PopoverIsOpen });
    }

    renderPopoverContent = () => {
        var { FontFamily, FontSize, JustifyContent, Text, TextAlign } = this.props;

        return (
            <styles.StyledPopover>
                <styles.PopoverContent>
                    <PageText
                        FontFamily={FontFamily || 'medium-italic'}
                        FontSize={FontSize || 'small'}
                        JustifyContent={JustifyContent || 'center'}
                        NoMargin
                        Text={Text || ''}
                        TextAlign={TextAlign || 'center'}
                    />
                </styles.PopoverContent>
            </styles.StyledPopover>
        );
    }

    render() {
        const popoverProps = {
            isOpen: this.state.PopoverIsOpen,
            preferPlace: 'above',
            body: this.renderPopoverContent(),
            onOuterAction: () => this.onTogglePopover(false),
            tipSize: 6
        }

        return (
            <Popover {...popoverProps}>
                <styles.PopoverIcon className="PopoverIcon" onClick={() => this.onTogglePopover(true)} onMouseEnter={() => this.onTogglePopover(true)} onMouseLeave={() => this.onTogglePopover(false)}>
                    <PageText FontColor="white" FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text="?" TextAlign="center" />
                </styles.PopoverIcon>
            </Popover>
        );
    }
}

export default PopoverItem;