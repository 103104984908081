import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { buildStyles, CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import '../../../index.css';

const InnerImage = styled.img`
    border-radius: 100%;
    height: 85%;
    width: 85%;
`;

const TextContainer = styled.div`
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-medium-1);
    max-width: 82%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

class Circle extends React.Component {
    render() {
        var { InnerText, Percentage, Picture } = this.props;

        if (InnerText) {
            return (
                <CircularProgressbarWithChildren
                    value={Percentage}
                    styles={buildStyles({
                        pathColor: Percentage === 100 ? 'var(--green)' : 'var(--abel-blue)',
                        textColor: 'var(--black)',
                        trailColor: 'var(--white-concrete)'
                    })}
                >
                    <TextContainer>{InnerText}</TextContainer>
                </CircularProgressbarWithChildren>
            );
        }

        if (Picture) {
            return (
                <CircularProgressbarWithChildren
                    value={Percentage}
                    styles={buildStyles({
                        pathColor: Percentage === 100 ? 'var(--green)' : 'var(--abel-blue)',
                        textColor: 'var(--black)',
                        trailColor: 'var(--white-concrete)'
                    })}
                >
                    <InnerImage src={Picture} alt="doge" />
                </CircularProgressbarWithChildren>
            );
        }

        return (
            <CircularProgressbar
                value={Percentage}
                text={`${Math.floor(Percentage * 10) / 10}%`}
                styles={buildStyles({
                    pathColor: Percentage === 100 ? 'var(--green)' : 'var(--abel-blue)',
                    textColor: 'var(--black)',
                    trailColor: 'var(--white-concrete)'
                })}
            />
        );
    }
}

Circle.propTypes = {
    Percentage: PropTypes.number.isRequired,
    Picture: PropTypes.string
}

export default Circle;