import styled from 'styled-components';

export const PopoverContent = styled.div`
    align-items: flex-start;
    background-color: var(--white);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
`;

export const PopoverIcon = styled.div`
    background-color: var(--orange);
    border-radius: 50%;
    margin-left: 10px;
    padding: 5px;
    width: 30px;

    @media(hover:hover) {
        :hover {
            background-color: var(--orange-hover);
            cursor: pointer;
        }
    }
`;

export const StyledPopover = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    box-shadow: 1px 1px 8px #5A5A5A29;
    max-height: 500px;
    max-width: 250px;
    overflow-y: scroll;
`;