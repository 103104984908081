import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DeliverySite_GetOrders_Basic, DeliverySite_GetDeliveryTracking } from '../../../Services/Actions';
import Select from 'react-select';
import Popover from 'react-popover';
import moment from 'moment';

import * as styles from './styles';

import Loading from '../../../Components/Loading';
import MultiText from '../../../Components/Text/MultiText';
import PageText from '../../../Components/Text/PageText';
import ProfilePhoto from '../../../Components/ProfilePhoto';
import Spacer from '../../../Components/Spacer';

import InfoIcon from '../../../Assets/Icons/Info.png';

import { formatCallDuration } from '../../../Functions';

const customStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "initial"
    }),
    input: (provided, state) => ({
        ...provided,
        minWidth: '20%'
    })
}

class DeliveryTrackingComponent extends React.Component {
    TimeoutId = null;

    state = {
        DeliveryTracking: {},

        Filters: {
            CheckingDate: null,
            Orders: {
                IsLoading: false,
                Loaded: false,
                Orders: [],
                Selected: {}
            }
        },

        PopoverIsOpen_Orders: false
    }

    onChangeFilter_Orders = data => {
        if (!data) data = {};
        
        var Filters = { ...this.state.Filters };
        Filters.Orders.Selected = data;

        this.setState({ Filters }, () => this.onLoadDeliveryTracking());
    }

    onFocusFilter_Orders = () => {
        if (!this.state.Filters.Orders.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.Orders = { ...Filters.Orders };
            Filters.Orders.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetOrders_Basic({}).then(({ Orders }) => {
                    Filters.Orders.IsLoading = false;
                    Filters.Orders.Orders = Orders;
                    Filters.Orders.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onLoadDeliveryTracking = () => {
        var { Filters: { CheckingDate, Orders } } = this.state;

        var OrderId = Orders.Selected ? Orders.Selected.OrderId : null;

        this.props.DeliverySite_GetDeliveryTracking({ CheckingDate, OrderId }).then(({ DeliveryTracking }) => {
            this.setState({ DeliveryTracking });
        });
    }

    onTogglePopover = PopoverType => {
        this.setState({ [PopoverType]: !this.state[PopoverType] }, () => {
            if (!this.state[PopoverType] && !!this.TimeoutId) clearTimeout(this.TimeoutId);
        });
    }

    renderDeliveryTracking = () => {
        var { DeliveryTracking, Filters: { Orders: { Selected } } } = this.state;

        return (
            <styles.BodyContainer className="BodyContainer">
                <styles.BodyHeader className="BodyHeader">
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text="Delivery Tracking" TextAlign="left" />
                </styles.BodyHeader>

                <Spacer Size="small" />

                {Object.keys(Selected).length === 0 && <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Select an order to view the delivery tracking for it" TextAlign="left" />}
                
                {
                    Object.keys(DeliveryTracking).length > 0 &&
                    <>
                        {this.renderDeliveryTracking_OrderDetails(DeliveryTracking.OrderDetails)}

                        <Spacer Size="medium" />

                        <styles.UsersContainer className="UsersContainer">
                            <styles.UsersTableContainer className="UsersTableContainer">
                                <styles.UsersTable className="UsersTable">
                                    <styles.UsersTableHeader className="UsersTableHeader">
                                        <styles.UsersTableHeaderRow className="UsersTableHeaderRow">
                                            <styles.UsersTableHeaderCell className="UsersTableHeaderCell">
                                                <styles.UsersTableHeaderCellInnerDiv className="UsersTableHeaderCellInnerDiv">
                                                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Employee" />
                                                </styles.UsersTableHeaderCellInnerDiv>
                                            </styles.UsersTableHeaderCell>
                                            
                                            {
                                                [ ...Array((+DeliveryTracking.TotalWeeks)).keys()].map((WeekCount, WeekIndex) => {
                                                    return (
                                                        <styles.UsersTableHeaderCell className="UsersTableHeaderCell" key={WeekIndex}>
                                                            <styles.UsersTableHeaderCellInnerDiv className="UsersTableHeaderCellInnerDiv">
                                                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={`Week #${WeekCount + 1} / ${DeliveryTracking.TotalWeeks}`} />
                                                            </styles.UsersTableHeaderCellInnerDiv>
                                                        </styles.UsersTableHeaderCell>
                                                    );
                                                })
                                            }
                                        </styles.UsersTableHeaderRow>
                                    </styles.UsersTableHeader>
                                    <styles.UsersTableBody className="UsersTableBody">
                                        {
                                            DeliveryTracking.Employees.map((User, UserIndex) => {
                                                var { UserId, StartDate, Weeks } = User;

                                                return (
                                                    <styles.UsersTableBodyRow className="UsersTableBodyRow" key={UserId}>
                                                        <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                            {this.renderEmployeeDetails(User)}
                                                        </styles.UsersTableBodyCell>

                                                        {
                                                            (StartDate && Weeks.length > 0) &&
                                                            Weeks.map((WeekDetail, WeekIndex) => this.renderWeekDetails({ WeekDetail, WeekIndex }))
                                                        }
                                                    </styles.UsersTableBodyRow>
                                                )
                                            })
                                        }
                                    </styles.UsersTableBody>
                                </styles.UsersTable>
                            </styles.UsersTableContainer>
                        </styles.UsersContainer>
                    </>
                }
            </styles.BodyContainer>
        );
    }

    renderDeliveryTracking_OrderDetails = Details => {
        var { AccountManager_DisplayName, CompanyName, CreatedDate, ProductName, Requirements, StartDate } = Details;
        
        return (
            <>
                <MultiText
                    Texts={[
                        { FontColor: 'black', FontFamily: 'semibold', FontSize: 'medium-1', Text: 'Company: ' },
                        { FontColor: 'black', FontFamily: 'medium', FontSize: 'medium-1', Text: `${CompanyName}` }
                    ]}
                />

                <MultiText
                    Texts={[
                        { FontColor: 'black', FontFamily: 'semibold', FontSize: 'medium-1', Text: 'Product: ' },
                        { FontColor: 'black', FontFamily: 'medium', FontSize: 'medium-1', Text: `${ProductName}` }
                    ]}
                />

                <MultiText
                    Texts={[
                        { FontColor: 'black', FontFamily: 'semibold', FontSize: 'medium-1', Text: 'Order created: ' },
                        { FontColor: 'black', FontFamily: 'medium', FontSize: 'medium-1', Text: `${CreatedDate}` }
                    ]}
                />

                <MultiText
                    Texts={[
                        { FontColor: 'black', FontFamily: 'semibold', FontSize: 'medium-1', Text: 'Start date: ' },
                        { FontColor: 'black', FontFamily: 'medium', FontSize: 'medium-1', Text: `${StartDate}` }
                    ]}
                />

                <MultiText
                    Texts={[
                        { FontColor: 'black', FontFamily: 'semibold', FontSize: 'medium-1', Text: 'Company account manager: ' },
                        { FontColor: 'black', FontFamily: 'medium', FontSize: 'medium-1', Text: `${AccountManager_DisplayName}` }
                    ]}
                />

                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Requirements:" />
                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Requirements} WhiteSpace="pre-wrap" />
            </>
        );
    }

    renderEmployeeDetails = User => {
        var {
            DisplayName, ProfilePhotoUrl, Sex,
            DHC_DisplayName, DHC_ProfilePhotoUrl, DHC_Sex,
            StartDate
        } = User;

        return (
            <styles.EmployeeDetailsContainer className="EmployeeDetailsContainer">
                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Employee" />
                <styles.UsersTableBodyCellFlexed className="UsersTableBodyCellFlexed">
                    <styles.ProfilePhotoContainer className="ProfilePhotoContainer">
                        <ProfilePhoto Sex={Sex || 'Male'} Size="small-3" Source={ProfilePhotoUrl || ''} />
                    </styles.ProfilePhotoContainer>
                    {DisplayName}
                </styles.UsersTableBodyCellFlexed>

                <Spacer Size="extra-small" />

                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="DHC" />
                <styles.UsersTableBodyCellFlexed className="UsersTableBodyCellFlexed">
                    <styles.ProfilePhotoContainer className="ProfilePhotoContainer">
                        <ProfilePhoto Sex={DHC_Sex || 'Male'} Size="small-3" Source={DHC_ProfilePhotoUrl || ''} />
                    </styles.ProfilePhotoContainer>
                    {DHC_DisplayName}
                </styles.UsersTableBodyCellFlexed>

                <Spacer Size="extra-small" />

                <MultiText
                    Texts={[
                        { FontColor: 'black', FontFamily: 'semibold-italic', FontSize: 'medium-1', Text: 'StartDate:' },
                        { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: StartDate ? moment(StartDate).format('YYYY-MM-DD') : '' }
                    ]}
                />
            </styles.EmployeeDetailsContainer>
        );
    }

    renderFilters = () => {
        var { t } = this.props;

        const popoverProps_Orders = {
            isOpen: this.state.PopoverIsOpen_Orders,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_Orders'),
            body: this.renderPopoverContent('Orders'),
            tipSize: 0.01
        }

        var { Filters: { Orders } } = this.state;

        return (
            <styles.FiltersContainer className="FiltersContainer">
                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Filters" TextAlign="left" />

                <Spacer Size="extra-small" />

                <styles.FiltersSubContainer className="FiltersSubContainer">
                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Orders" />

                            <Popover {...popoverProps_Orders}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_Orders')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_Orders')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={true}
                            getOptionLabel={option => option.DisplayName}
                            getOptionValue={option => option.OrderId}
                            isLoading={Orders.IsLoading}
                            isMulti={false}
                            // components={{ ValueContainer }}
                            hideSelectedOptions={true}
                            isSearchable
                            // onBlur={this.onBlurFilter_Orders}
                            onChange={data => this.onChangeFilter_Orders(data)}
                            onFocus={this.onFocusFilter_Orders}
                            options={Orders.Orders}
                            placeholder={t('Filter by Order')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>
                </styles.FiltersSubContainer>
            </styles.FiltersContainer>
        );
    }

    renderPopoverContent = ContentType => {
        // var { t } = this.props;

        return (
            <styles.PopoverContent className="PopoverContent">
                {ContentType === 'Orders' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Choose an order to view delivery tracking for that order" TextAlign="center" />}
            </styles.PopoverContent>
        );
    }

    renderWeekDetails = ({ WeekDetail, WeekIndex }) => {
        var {
            Activities, Activities_Completed, Activities_Completed_Percentage,
            CallDays: Days, CallHours: Hours, CallMinutes: Minutes, CallSeconds: Seconds,
            WeekStartDate, WeekEndDate
        } = WeekDetail;

        return (
            <styles.UsersTableBodyCell className="UsersTableBodyCell" key={WeekIndex}>
                <styles.WeekDetailContainer className="WeekDetailContainer">
                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin TextAlign="center" Text={`${moment(WeekStartDate).format('YYYY-MM-DD')} - ${moment(WeekEndDate).format('YYYY-MM-DD')}`} />

                    <MultiText
                        Texts={[
                            { FontColor: 'black', FontFamily: 'semibold-italic', FontSize: 'medium-1', Text: 'Activities:' },
                            { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: `${Activities_Completed} / ${Activities} (${Activities_Completed_Percentage}%)` }
                        ]}
                    />

                    <MultiText
                        Texts={[
                            { FontColor: 'black', FontFamily: 'semibold-italic', FontSize: 'medium-1', Text: 'Calls:' },
                            { FontColor: 'black', FontFamily: 'medium-italic', FontSize: 'medium-1', Text: formatCallDuration({ Days, Hours, Minutes, Seconds }) }
                        ]}
                    />
                </styles.WeekDetailContainer>
            </styles.UsersTableBodyCell>
        );
    }

    render() {
        // var { t } = this.props;

        return (
            <>
                {this.props.TryingDeliverySiteAction && <Loading />}

                {this.renderFilters()}
                {this.renderDeliveryTracking()}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        TryingDeliverySiteAction: state.DeliverySite.TryingDeliverySiteAction,
        TryingDeliverySiteActionError: state.DeliverySite.TryingDeliverySiteActionError
    };
};

export default withTranslation()(connect(mapStateToProps, { DeliverySite_GetOrders_Basic, DeliverySite_GetDeliveryTracking } )(DeliveryTrackingComponent));