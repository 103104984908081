import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearClientDetails, ClearEnduranceDetail, GetEnduranceDetail, GetEnduranceTrackingHistory, InsertEditEnduranceGoal } from '../../Services/Actions';
import history from '../../history';

import moment from 'moment';

import  { ChartContainer, Container, DetailContainer, EditGoalContainer, ExerciseImageContainer, HeaderContainer, LineGraphContainer, ProgressContainer, ProgressStatsContainer, ProgressStatsContainerInner, StyledExerciseImage, StyledGoal, StyledInputLabelSideBySide, StyledLineBar, StyledLoadMore, TrackingHistoryContainer, TrackingHistoryRow, ViewCount } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import LineGraph from '../../Components/Charts/LineGraph';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Info from '../../Modals/Info';

import { SecondsToMinutesAndSeconds, SecondsToTime } from '../../Functions';

class EnduranceDetail extends React.PureComponent {
    _isMounted = false;

    state = {
        ActiveSearch: false,
        Goal: 0,
        GoalMinutes: 0,
        GoalSeconds: 0,
        Loading: true,
        ShowEditGoalModal: false,
        PageNo: 1,
        PageSize: 50
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientId, EnduranceTemplateId } = this.props.match.params;

        if (!+ClientId || !+EnduranceTemplateId) history.push('/');
        else {
            this.props.GetEnduranceDetail({ ClientId, EnduranceTemplateId }).then(() => {
                if (this.props.TryingGetEnduranceDetailError) history.push('/clients');
                else {
                    var { EnduranceMethod, Progress: { Goal } } = this.props.EnduranceDetail;

                    if (EnduranceMethod === 'Distance') {
                        var { Minutes: GoalMinutes, Seconds: GoalSeconds } = SecondsToMinutesAndSeconds(Goal);
    
                        GoalSeconds = +GoalSeconds === 0 ? '00' : +GoalSeconds < 10 ? `0${GoalSeconds}` : GoalSeconds;
    
                        this.setState({ Goal, GoalMinutes, GoalSeconds });
                    }

                    this.setState({ Loading: false });
                }
            });
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        
        this.props.ClearClientDetails();
        this.props.ClearEnduranceDetail();
    }

    onBlurGoal = () => {
        var { Goal } = this.state;

        if (+Goal === 0) this.setState({ Goal: 0 });
    }

    onBlurGoalTime = () => {
        var Goal = 0;
        Goal += (this.state.GoalMinutes * 60) + +this.state.GoalSeconds;

        var { Minutes: GoalMinutes, Seconds: GoalSeconds } = SecondsToMinutesAndSeconds(Goal);

        GoalSeconds = +GoalSeconds === 0 ? '00' : +GoalSeconds < 10 ? `0${GoalSeconds}` : GoalSeconds;
        GoalMinutes = +GoalMinutes === 0 ? 0 : GoalMinutes;

        this.setState({ Goal, GoalMinutes, GoalSeconds });
    }

    onChangeGoal = event => {
        this.setState({ Goal: event.target.value });
    }

    onChangeTime = (event, TimeType, OtherTimeTypeValue) => {
        var Goal = 0;
        if (TimeType === 'Minutes') Goal += (event.target.value * 60) + OtherTimeTypeValue;
        else if (TimeType === 'Seconds') Goal += event.target.value + (OtherTimeTypeValue * 60);

        this.setState({ [`Goal${TimeType}`]: event.target.value, Goal });
    }

    onInsertEditEnduranceGoal = () => {
        var { ClientId } = this.props.match.params;
        var { EnduranceMethod, EnduranceTemplateId, Progress: { Goal: CurrentGoal } } = this.props.EnduranceDetail;
        var { Goal, PageNo } = this.state;

        if (+Goal && +Goal !== +CurrentGoal) {
            var Seconds, Speed;

            if (EnduranceMethod === 'Time') Speed = Goal;
            else if (EnduranceMethod === 'Distance') Seconds = Goal;

            // Reset Tracking History
            PageNo = 1;

            this.props.InsertEditEnduranceGoal({ ClientId, EnduranceTemplateId, Seconds, Speed });
        }

        this.onToggleShowEditGoalModal({ PageNo, ShowEditGoalModal: false });
    }

    onLoadTrackingHistory = Increment => {
        var { PageNo, PageSize } = this.state;
        var { EnduranceTemplateId } = this.props.EnduranceDetail;
        var { ClientId } = this.props.match.params;

        if (Increment) PageNo = PageNo + 1;

        this.setState({ ActiveSearch: true, PageNo }, () => this.props.GetEnduranceTrackingHistory({ AddToExisting: Increment, ClientId, EnduranceTemplateId, TrackingPageNo: PageNo, TrackingPageSize: PageSize }).then(() => this.setState({ ActiveSearch: false })));
    }

    onToggleShowEditGoalModal = ({ Goal = 0, ShowEditGoalModal }) => {
        if (ShowEditGoalModal) Goal = this.props.EnduranceDetail.Progress.Goal;
        this.setState({ Goal, ShowEditGoalModal });
    }

    renderChart = () => {
        var { t } = this.props;
        var { Chart, EnduranceMethod, Units } = this.props.EnduranceDetail;

        return (
            <ChartContainer>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('graph_to_show_progress')} TextAlign="center" />
                
                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('graph_shows_alltime_maxes')} TextAlign="center" />

                <Spacer Size="small" />

                <LineGraphContainer>
                    <LineGraph
                        AllTime
                        Goals={Chart.Goals}
                        Increment={Chart.Increment}
                        IsTime={EnduranceMethod === 'Distance'}
                        Maximum={Chart.Maximum}
                        Minimum={Chart.Minimum}
                        Measurements={Chart.Measurements}
                        TimePeriod="1M"
                        Units={Units}
                    />
                </LineGraphContainer>
            </ChartContainer>
        );
    }

    renderProgress = () => {
        var { t } = this.props;
        var { EnduranceDetail: { EnduranceMethod, Progress: { CompletionStatus, Current, Goal, ProgressLine, Starting, Units } } } = this.props;

        var Buttons = [{ FontFamily: 'semibold', OnClick: () => this.onToggleShowEditGoalModal({ ShowEditGoalModal: true }), Title: t('edit_goal') }];

        return (
            <>
                <DetailContainer>
                    <PageText FontFamily="semibold" FontSize="medium-2" NoMargin Text={`${t('ptadmin_contenttype_header_progress')} (${Units})`} TextAlign="center" />

                    <Spacer Size="small" />

                    {
                        Starting !== null ?
                        <>
                            {this.renderStats()}

                            {
                                !!+Goal &&
                                <>
                                    <Spacer Size="extra-small" />

                                    <StyledLineBar
                                        CompletionStatus={CompletionStatus}
                                        CurrentValue={Current}
                                        GoalMax={0}
                                        GoalMin={Goal}
                                        HideCurrent
                                        IsTime={EnduranceMethod === 'Distance'}
                                        ProgressLine={ProgressLine}
                                        Units={Units}
                                    />
                                </>
                            }

                            <Spacer Size="small" />
        
                            <ButtonGroup Buttons={Buttons} ButtonWidth="fit-content" />
                        </>
                    :
                        <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('WorkoutPlan_This_is_a_new_interval')} TextAlign="center" />
                    }
                </DetailContainer>
            </>
        );
    }

    renderShowEditGoalModal = () => {
        var { t } = this.props;
        var { Goal: CurrentGoal } = this.props.EnduranceDetail.Progress;
        var { Goal, ShowEditGoalModal } = this.state;

        var ActionText = !!+CurrentGoal ? t('save_with_first_char_uppercase') : t('ptadmin_clientnote_options_add');

        if (ShowEditGoalModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonDisabled={!+Goal}
                    BottomButtonOnClick={this.onInsertEditEnduranceGoal}
                    BottomButtonText={ActionText}
                    InformationRenderer={this.renderShowEditGoalModalInfo}
                    OnHide={() => this.onToggleShowEditGoalModal({ ShowEditGoalModal: false })}
                    Show={ShowEditGoalModal}
                    Size="medium"
                    TextAlign="center"
                />
            );
        }
    }

    renderShowEditGoalModalInfo = () => {
        var { t } = this.props;
        var { ExerciseType, Progress: { Goal: CurrentGoal }, Units } = this.props.EnduranceDetail;
        var { Goal, GoalMinutes, GoalSeconds } = this.state;

        var TitleText = !!+CurrentGoal ? t('edit_goal') : t('add_goal');

        return (
            <EditGoalContainer>
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={TitleText} TextAlign="center" />

                <Spacer Size="small" />

                {
                    !!+CurrentGoal &&
                    <>
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('current_goal')} TextAlign="center" />

                        <Spacer Size="extra-extra-small" />

                        <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={`${CurrentGoal} ${Units}`} TextAlign="center" />

                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('new_goal')} TextAlign="center" />

                        <Spacer Size="extra-extra-small" />
                    </>
                }

                {
                    ExerciseType === 2 ?
                    <StyledInputLabelSideBySide
                        InputFontFamily="semibold"
                        InputFontSize="medium-3"
                        LabelFontFamily="medium"
                        LabelFontSize="medium-1"
                        Label={`${t('min_str').toLowerCase()} / ${t('Seconds_short').toLowerCase()}`}
                        OnBlur={this.onBlurGoalTime}
                        OnChange={(event, TimeType, OtherTimeTypeValue) => this.onChangeTime(event, TimeType, OtherTimeTypeValue)}
                        Placeholder="0"
                        TimeValueMinutes={GoalMinutes}
                        TimeValueSeconds={GoalSeconds}
                        Type="time"
                    />
                :
                    <StyledGoal
                        FontColor="blue-astronaut"
                        FontFamily="semibold"
                        FontSize="medium-3"
                        NoLabel
                        NoMargin
                        OnBlur={this.onBlurGoal}
                        OnChange={this.onChangeGoal}
                        Placeholder="0"
                        Size="medium"
                        Type="text"
                        Value={Goal}
                    />
                }
            </EditGoalContainer>
        );
    }

    renderStats = () => {
        var { t } = this.props;
        var { EnduranceDetail: { EnduranceMethod, Progress: { Current, Goal, Starting } } } = this.props;

        var CurrentText = `${Current}`;
        var GoalText = `${Goal}`;
        var StartingText = `${Starting}`;

        if (EnduranceMethod === 'Distance') {
            CurrentText = SecondsToTime(Current);
            GoalText = SecondsToTime(Goal);
            StartingText = SecondsToTime(Starting);
        }

        return (
            <ProgressStatsContainer>
                <ProgressStatsContainerInner>
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('WorkoutPlan_Starting')} TextAlign="center" />

                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={StartingText} TextAlign="center" />
                </ProgressStatsContainerInner>

                <ProgressStatsContainerInner>
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${t('WorkoutPlan_Current')}`} TextAlign="center" />

                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={CurrentText} TextAlign="center" />
                </ProgressStatsContainerInner>

                <ProgressStatsContainerInner>
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${t('Goal')}`} TextAlign="center" />

                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={GoalText} TextAlign="center" />
                </ProgressStatsContainerInner>
            </ProgressStatsContainer>
        );
    }

    renderTrackingHistory = () => {
        var { t } = this.props;
        var { EnduranceDetail: { EnduranceMethod, TrackingHistory: { IsMoreResults, TotalRecords, TrackingHistory } }, TryingGetEnduranceTrackingHistory } = this.props;
        var { ActiveSearch, PageNo, PageSize } = this.state;

        var ColumnCount = 2;
        var Column1Text = EnduranceMethod === 'Time' ? t('Speed') : t('Seconds');

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('ProgressSection_Tracking_history')} TextAlign="center" />

                <Spacer Size="small" />

                <ViewCount>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`1 - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords}`} />
                    &nbsp;
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('measurements').toLowerCase()} />
                </ViewCount>

                <Spacer Size="small" />

                <TrackingHistoryContainer>
                    <TrackingHistoryRow ColumnCount={ColumnCount}>
                        <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('Date_str')} TextAlign="center" />
                        <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" NoMargin Text={Column1Text} TextAlign="center" />
                    </TrackingHistoryRow>

                    <StyledLoadMore
                        ActiveSearch={ActiveSearch}
                        CompletedFirstSearch
                        IsLoading={TryingGetEnduranceTrackingHistory}
                        ItemDescriptionType={t('measurements').toLowerCase()}
                        ItemId="TrackingHistoryId"
                        ItemName=""
                        ItemRenderer={this.renderTrackingHistoryList}
                        Items={TrackingHistory}
                        HasMoreItems={!!+IsMoreResults}
                        HideViewCount
                        LoadMore={() => this.onLoadTrackingHistory(true)}
                        NoItemsText={t('search_noresults')}
                        PageNo={PageNo}
                        PageSize={PageSize}
                        TotalRecords={TotalRecords}
                    />
                </TrackingHistoryContainer>
            </>
        );
    }

    renderTrackingHistoryList = ({ Item: { Seconds, Speed, TrackingHistoryId, Units, UpdateDate } }) => {
        var { EnduranceMethod } = this.props.EnduranceDetail;
        
        var ColumnCount = 2;
        var Column1Value = EnduranceMethod === 'Time' ? `${Speed} ${Units}` : SecondsToTime(Seconds);

        return (
            <TrackingHistoryRow key={TrackingHistoryId} ColumnCount={ColumnCount}>
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={moment(UpdateDate).format('[NumbersDate]')} TextAlign="center" />
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" NoMargin Text={Column1Value} TextAlign="center" />
            </TrackingHistoryRow>
        );
    }

    render() {
        if (this.state.Loading || this.props.TryingGetEnduranceDetail) return <Loading />;

        var { Device, ClientDetails: { ClientId, UserName }, EnduranceDetail: { EnduranceImage, EnduranceName, Progress: { Starting } }, TryingInsertEditEnduranceGoal } = this.props;

        return (
            <>
                {TryingInsertEditEnduranceGoal && <Loading />}

                <Page
                    renderBreadcrumbs={[
                        { Name: `@${UserName}`, LinkURL: `client/${ClientId}`, Props: { SelectedTab: 'Progress' }},
                        { Name: EnduranceName}
                    ]}
                >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={EnduranceName} />

                    <Spacer Size={Device === 'mobile_small' ? 'small' : 'medium'} />
                    
                    <HeaderContainer>
                        <ExerciseImageContainer>
                            <StyledExerciseImage ImageSrc={EnduranceImage || ''} SelfLoading />
                        </ExerciseImageContainer>
                        <ProgressContainer>
                            {this.renderProgress()}
                        </ProgressContainer>
                    </HeaderContainer>                

                    <Spacer Size="medium" />

                    <Container>
                        {
                            !!+Starting &&
                            <>
                                <Spacer Size="medium" />

                                {this.renderChart()}

                                <Spacer Size="medium" />

                                {this.renderTrackingHistory()}
                            </>
                        }
                    </Container>
                </Page>

                {this.renderShowEditGoalModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        ClientDetails: state.Client.ClientDetails,

        EnduranceDetail: state.Endurance.EnduranceDetail,

        TryingGetEnduranceDetail: state.Endurance.TryingGetEnduranceDetail,
        TryingGetEnduranceDetailError: state.Endurance.TryingGetEnduranceDetailError,
        TryingGetEnduranceTrackingHistory: state.Endurance.TryingGetEnduranceTrackingHistory,
        TryingGetEnduranceTrackingHistoryError: state.Endurance.TryingGetEnduranceTrackingHistoryError,

        TryingInsertEditEnduranceGoal: state.Progress.TryingInsertEditEnduranceGoal,
        TryingInsertEditEnduranceGoalError: state.Progress.TryingInsertEditEnduranceGoalError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearClientDetails, ClearEnduranceDetail, GetEnduranceDetail, GetEnduranceTrackingHistory, InsertEditEnduranceGoal } )(EnduranceDetail));