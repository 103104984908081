import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ChangePassword, ClearNotificationSettings, ClearOrganizationDashboard, EditUserProfile, LogoutUser, SearchCurrencies, UpdateBankAccountNo, UpdateNotificationSettings, UploadToPresignedUrl, UploadProfilePicture, ValidateUser, ViewAvailableLanguages, ViewNotificationSettings } from '../../Services/Actions';
import queryString from 'query-string';
import Select from 'react-select';
import history from '../../history';
import { toast } from 'react-toastify';

import { BETA_SITE_URL } from '../../Config';

import { ActiveSectionContainer, BankAccountContainer, CircleDiv, FlagsContainer, FlagContainer, FlagImage, HeightFeetIcon, HeightInput, NotificationCategory, NotificationSetting, ProfilePhotoContainer, SectionsContainer, ShowPasswordsContainer, StyledAccountInput, StyledHeightPageText, StyledHR, StyledHR2, StyledSexButtonGroups, UnitInputContainer, UserDetailsContainer, UserDetailsItemContainer } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import FlexWrap from '../../Components/Lists/FlexWrap';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import ProfilePhoto from '../../Components/ProfilePhoto';
import Spacer from '../../Components/Spacer';
import Subscriptions from './Subscriptions';
import Toggle from '../../Components/Form/Toggle';
import UploadProgressComponent from '../../Components/UploadProgress';

import ForgotPassword from '../../Modals/ForgotPassword';
import ImageUploadCrop from '../../Modals/ImageUploadCrop';

import { CreateUploadToPresignedUrlPromise, SetLanguage } from '../../Functions';

import Female from '../../Assets/Icons/FemaleSymbol_Color.png';
import Male from '../../Assets/Icons/MaleSymbol_Color.png';
import Plus from '../../Assets/Icons/Plus.png';

// Always start with trimming the start and end, to remove any extra spaces
// Between 3 and 32 characters (so it can be 3 or 32)
// Valid characters equal [ a - z ], [ A - Z ], [ 0 - 9 ], [ -, _, . ]
// [ -, _, . ] cannot be at the start or end of the username
// [ -, _, . ] cannot be two times in a row, such as “ridge..robinson” or “ridge-_robinson”

const UsernameRegex = UserName => {
    return /^(?=[a-zA-Z0-9._-]{3,32}$)(?!.*[._-]{2})[^._-].*[^._-]$/.test(UserName);
}

const DropdownStyles = {
    control: (styles, { isDisabled }) => ({ ...styles, backgroundColor: 'var(--white-concrete)', border: 'none', cursor: isDisabled ? 'not-allowed' : 'default', height: '45px', maxWidth: '100%', width: '200px' }),
    input: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    menu: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    option: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '200px' })
};

class Account extends React.Component {
    state = {
        ActiveSection: 'Subscriptions',
        AvailableLanguages: [],
        HoverShowPasswords: false,
        ImageTooLarge: false,
        FocusedInput: null,
        LastValidatedUserName: '',
        Loading: true,
        MaxImageSize: 10000000,
        RetrievedLanguages: false,
        SelectedImage: null,
        ShowImageUploadModal: false,
        ShowForgotPasswordModal: false,
        ShowPasswords: false,
        UserNameTaken: false,
        WrongImageType: false,
        WrongPassword: false,

        NotificationCategories: [],
        RetrievedNotificationCategories: false,

        ConfirmNewPassword: '',
        CurrentPassword: '',
        NewPassword: '',

        BankAccountNo: '',
        CurrencyId: null,
        CurrencyName: '',
        DOB: null,
        Email: '',
        FirstName: '',
        Gender: null,
        Height: '',
        Height_Feet: '',
        Height_Inches: '',
        HerId: '',
        HeightUnit: null,
        LastName: '',
        LanguageId: null,
        PersonalNumber: '',
        PreferredMeasuringSystem: null,
        UserName: '',
        Weight: '',
        WeightUnit: null,

        OriginalUserDetails: {}
    }

    componentDidMount() {
        window.location = `${BETA_SITE_URL}/session-restore?awsToken=${localStorage.getItem("accessToken")}&refreshToken=${localStorage.getItem("refreshToken")}&redirect=settings`;

        // var { BankAccountNo, CurrencyId, CurrencyName, DOB, Email, FirstName, Gender, Height, HeightUnit, HERId: HerId, LastName, LanguageId, OrganizationId, PersonalNumber, PreferredMeasuringSystem, UserName, Weight, WeightUnit } = this.props.UserDetails;

        // Height = +Height;
        // var Height_Feet = 0;
        // var Height_Inches = 0;
        // if (HeightUnit !== PreferredMeasuringSystem) {
        //     // Currently Have Metric, and Need Imperial
        //         if (PreferredMeasuringSystem === 'Imperial') {
        //             var NewValue = Height ? Math.round((Height / 2.54) * 10) / 10 : Height;
        //             Height_Feet = Math.floor(NewValue / 12);
        //             Height_Inches = Math.floor(NewValue - (Height_Feet * 12));
        //         }
        //     // Currently Have Imperial, and Need Metric
        //         else if (PreferredMeasuringSystem === 'Metric') Height = Math.round((Height * 2.54) * 10) / 10;
        // } else if (PreferredMeasuringSystem === 'Imperial') {
        //     Height_Feet = Math.floor(Height / 12);
        //     Height_Inches = Math.floor(Height - (Height_Feet * 12));
        // }
        
        // if (WeightUnit !== PreferredMeasuringSystem) {
        //     Weight = +Weight;

        //     if (PreferredMeasuringSystem === 'Imperial') Weight = Math.round((Weight * 2.20462) * 10) / 10;
        //     else if (PreferredMeasuringSystem === 'Metric') Weight = Math.round((Weight / 2.20462) * 10) / 10;
        // }

        // var ActiveSection = 'Account';

        // const QueryValues = queryString.parse(this.props.location.search);
        // if (Object.entries(QueryValues).length > 0 && QueryValues.section) {
        //     if (QueryValues.section.toLowerCase() === 'account') ActiveSection = 'Account';
        //     else if (QueryValues.section.toLowerCase() === 'emailpassword') ActiveSection = 'EmailPassword';
        //     else if (QueryValues.section.toLowerCase() === 'notifications') ActiveSection = 'Notifications';
        //     else if (!+OrganizationId && QueryValues.section.toLowerCase() === 'subscriptions') ActiveSection = 'Subscriptions';
        // }

        // this.setState({ ActiveSection, Loading: false, BankAccountNo, CurrencyId, CurrencyName, DOB, Email, FirstName, Gender, Height, Height_Feet, Height_Inches, HeightUnit, HerId, LastName, LanguageId, LastValidatedUserName: UserName, PersonalNumber, PreferredMeasuringSystem, UserName, Weight, WeightUnit, OriginalUserDetails: { CurrencyId, CurrencyName, DOB, FirstName, Gender, Height, HeightUnit, HerId, LastName, LanguageId, PersonalNumber, PreferredMeasuringSystem, UserName, Weight, WeightUnit } });
    }

    componentWillUnmount() {
        this.props.ClearOrganizationDashboard();
        this.props.ClearNotificationSettings();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            var QueryValues = queryString.parse(this.props.location.search);
            if (Object.entries(QueryValues).length > 0 && QueryValues.section) {
                if (QueryValues.section.toLowerCase() === 'account') this.setState({ ActiveSection: 'Account' });
                else if (QueryValues.section.toLowerCase() === 'emailpassword') this.setState({ ActiveSection: 'EmailPassword' });
                else if (QueryValues.section.toLowerCase() === 'notifications') this.setState({ ActiveSection: 'Notifications' });
                else if (QueryValues.section.toLowerCase() === 'subscriptions') this.setState({ ActiveSection: 'Subscriptions' });
            }
        }
    }

    onBlurBankAccount = () => {
        var { BankAccountNo } = this.state;

        this.props.UpdateBankAccountNo(BankAccountNo).then(() => {
            toast.success(this.props.t('_bank_account_updated'), {
                position: "bottom-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    onBlurHeight = HeightType => {
        var EditedHeight = this.state[HeightType];

        if (+EditedHeight === 0) this.setState({ [HeightType]: 0 });
    }

    onBlurHerId = () => {
        if (this.state.HerId === '') {
            this.setState({ HerId: this.state.OriginalUserDetails.HerId });
        }
    }

    onBlurPersonalNumber = () => {
        if (this.state.PersonalNumber === '') {
            this.setState({ PersonalNumber: this.state.OriginalUserDetails.PersonalNumber });
        }
    }

    onBlurUserName = () => {
        var { LastValidatedUserName, OriginalUserDetails: { UserName: OriginalUserName }, UserName } = this.state;

        if (!UsernameRegex(UserName)) this.setState({ FocusedInput: null, UserName: LastValidatedUserName });
        else {
            if (OriginalUserName === UserName) this.setState({ FocusedInput: null, UserNameTaken: false });

            UserName !== '' && LastValidatedUserName !== UserName && OriginalUserName !== UserName && this.props.ValidateUser({ UserName }).then(({ UserNameTaken }) => {
                this.setState({ FocusedInput: null, LastValidatedUserName: UserName, UserNameTaken: !!UserNameTaken });
            });

            if (OriginalUserName === UserName) this.setState({ FocusedInput: null, UserNameTaken: false });
        }
    }

    onChangeCurrency = ({ CurrencyId, CurrencyName }) => {
        this.setState({ CurrencyId, CurrencyName });
    }

    onChangeHeight = (event, HeightType) => {
        var InputValue = event.target.value;
        
        if (!isNaN(InputValue)) {
            if (HeightType === 'Height_Feet' || HeightType === 'Height_Inches') {
                var { Height_Feet, Height_Inches } = this.state;
                Height_Feet = +Height_Feet;
                Height_Inches = +Height_Inches;

                Height_Feet = HeightType === 'Height_Feet' ? InputValue : Height_Feet;
                Height_Inches = HeightType === 'Height_Inches' ? InputValue >= 12 ? 11 : InputValue : Height_Inches;

                this.setState({ Height_Feet, Height_Inches });
            }
            else this.setState({ Height: InputValue });
        }
    }

    onChangeInput = ({ InputName, InputValue }) => {
        if (InputName === 'UserName') InputValue = InputValue.replace(/[^a-zA-Z0-9._-]/g, '');

        this.setState({ [InputName]: InputValue });
    }

    onChangeLanguageId = LanguageId => {
        this.setState({ LanguageId: +LanguageId });
    }

    onChangePassword = () => {
        var { CurrentPassword, NewPassword } = this.state;

        this.props.ChangePassword({ CurrentPassword, NewPassword }).then(({ WrongPassword }) => {
            if (WrongPassword) this.setState({ CurrentPassword: '', WrongPassword });
            else this.setState({ ConfirmNewPassword: '', CurrentPassword: '', NewPassword: '', WrongPassword });
        })
    }

    onChangePasswordInput = ({ InputType, InputValue }) => {
        this.setState({ [InputType]: InputValue });
    }

    onChangePreferredMeasuringSystem = PreferredMeasuringSystem => {
        if (this.state.PreferredMeasuringSystem !== PreferredMeasuringSystem) {
            var HeightUnit = PreferredMeasuringSystem;
            var WeightUnit = PreferredMeasuringSystem;

            var { Height, Height_Feet, Height_Inches, Weight } = this.state;

            // Height
                // Currently Have Metric, and Need Imperial
                    if (PreferredMeasuringSystem === 'Imperial') {
                        var NewValue = Height ? Math.round((Height / 2.54) * 10) / 10 : Height;
                        Height_Feet = Math.floor(NewValue / 12);
                        Height_Inches = Math.floor(NewValue - (Height_Feet * 12));
                    }
                // Currently Have Imperial, and Need Metric
                    else if (PreferredMeasuringSystem === 'Metric') {
                        Height = (Height_Feet * 12) + Height_Inches;
                        Height = Math.round((Height * 2.54) * 10) / 10;
                    }
            
            // Weight
                if (PreferredMeasuringSystem === 'Imperial') Weight = Math.round((Weight * 2.20462) * 10) / 10;
                else if (PreferredMeasuringSystem === 'Metric') Weight = Math.round((Weight / 2.20462) * 10) / 10;

            this.setState({ Height, Height_Feet, Height_Inches, HeightUnit, PreferredMeasuringSystem, Weight, WeightUnit });
        }
    }

    onClickFileOpen = () => {
        document.getElementById('ImageUpload').click();
    }

    onEditUserProfile = () => {
        var { BankAccountNo, CurrencyId, DOB, FirstName, Gender, Height, Height_Feet, Height_Inches, HerId, LanguageId, LastName, PersonalNumber, PreferredMeasuringSystem, RetrievedNotificationCategories, UserName, Weight } = this.state;

        var HeightUnit = PreferredMeasuringSystem;
        var WeightUnit = PreferredMeasuringSystem;

        Height = HeightUnit === 'Imperial' ? (+Height_Feet * 12) + +Height_Inches : +Height;

        this.props.EditUserProfile({ BankAccountNo, CurrencyId, DOB, FirstName, Gender, Height, HeightUnit, HerId, LanguageId, LastName, PersonalNumber, PreferredMeasuringSystem, UserName, Weight, WeightUnit }).then(({ PresignedFormData, UserDetails, UserNameTaken }) => {
            if (!this.props.TryingEditUserProfileError) {
                if (!UserNameTaken) {
                    if (LanguageId !== this.state.OriginalUserDetails.LanguageId) { RetrievedNotificationCategories = false; SetLanguage(LanguageId); }

                    this.setState({ OriginalUserDetails: { ...UserDetails, LanguageId: +LanguageId, PreferredMeasuringSystem }, RetrievedNotificationCategories, UserNameTaken });

                    // // Update Height && Weight If PreferredMeasuringSystem Has Changed
                    //     var { Height, Height_Feet, Height_Inches, OriginalUserDetails: { PreferredMeasuringSystem: OriginalPreferredMeasuringSystem }, Weight } = this.state;

                    //     if (PreferredMeasuringSystem !== OriginalPreferredMeasuringSystem) {
                    //         // Height
                    //             // Currently Have Metric, and Need Imperial
                    //                 if (PreferredMeasuringSystem === 'Imperial') {
                    //                     var NewValue = Height ? Math.round((Height / 2.54) * 10) / 10 : Height;
                    //                     Height_Feet = Math.floor(NewValue / 12);
                    //                     Height_Inches = Math.floor(NewValue - (Height_Feet * 12));
                    //                 }
                    //             // Currently Have Imperial, and Need Metric
                    //                 else if (PreferredMeasuringSystem === 'Metric') {
                    //                     Height = (Height_Feet * 12) + Height_Inches;
                    //                     Height = Math.round((Height * 2.54) * 10) / 10;
                    //                 }
                            
                    //         // Weight
                    //             if (PreferredMeasuringSystem === 'Imperial') Weight = Math.round((Weight * 2.20462) * 10) / 10;
                    //             else if (PreferredMeasuringSystem === 'Metric') Weight = Math.round((Weight / 2.20462) * 10) / 10;
                    //     }

                    // this.setState({ Height, Height_Feet, Height_Inches, HeightUnit, OriginalUserDetails: { BankAccountNo, DOB, FirstName, Gender, Height, HeightUnit, LanguageId: +LanguageId, LastName, PreferredMeasuringSystem, UserName, Weight, WeightUnit }, RetrievedNotificationCategories, UserNameTaken, Weight, WeightUnit });
                }
                else this.setState({ UserNameTaken });
            } else this.setState({ ...this.state, ...this.state.OriginalUserDetails });
        });
    }

    onFocusCurrencies = () => {
        var { Currencies, TryingSearchCurrencies } = this.props;

        if (!Currencies.length && !TryingSearchCurrencies) this.props.SearchCurrencies();
    }

    onFocusInput = ({ InputName }) => {
        this.setState({ FocusedInput: InputName });
    }

    onToggleActiveSection = ActiveSection => {
        history.push({ pathname: '/account', search: `?section=${ActiveSection.toLowerCase()}` });
        
        // this.setState({ ActiveSection });
    }

    onToggleHoverShowPasswords = () => {
        this.setState({ HoverShowPasswords: !this.state.HoverShowPasswords });
    }

    onToggleNotificationSetting = ({ CategoryIndex, CategoryType, NotificationTypeIndex }) => {
        var NewNotificationCategories = { ...this.state.NotificationCategories };

        var { IsNotificationOn, NotificationType } = NewNotificationCategories[CategoryType][CategoryIndex].Settings[NotificationTypeIndex];

        IsNotificationOn = !IsNotificationOn;

        NewNotificationCategories[CategoryType][CategoryIndex].Settings[NotificationTypeIndex].IsNotificationOn = IsNotificationOn;

        this.setState({ NotificationCategories: NewNotificationCategories }, () => this.props.UpdateNotificationSettings({ Settings: [{ isNotificationOn: IsNotificationOn, notificationType: NotificationType }]}));
    }

    onToggleShowForgotPasswordModal = ShowForgotPasswordModal => {
        this.setState({ ShowForgotPasswordModal });
    }

    onToggleShowImageUploadModal = ShowImageUploadModal => {
        this.setState({ ShowImageUploadModal });
    }

    onToggleShowPasswords = () => {
        this.setState({ ShowPasswords: !this.state.ShowPasswords });
    }

    onUploadOrgPicture = CroppedImage => {
        var { type: ContentType, name: FileName } = CroppedImage;

        this.props.UploadProfilePicture({ ContentType, FileName, MediaUrl: URL.createObjectURL(CroppedImage) }).then(({ PresignedFormData }) => {
            if (!this.props.TryingUploadProfilePictureError) {
                var UploadFunction = this.props.UploadToPresignedUrl;
                var Promises = [ CreateUploadToPresignedUrlPromise({ ContentType: 'ProfilePhoto', File: CroppedImage, PresignedFormData, UploadFunction }) ];

                Promise.all(Promises).then(() => {
                    this.setState({ ShowImageUploadModal: false });
                });
            }
        });
    }

    onViewAvailableLanguages = () => {
        this.props.ViewAvailableLanguages().then(({ Languages }) => this.setState({ AvailableLanguages: Languages, RetrievedLanguages: true }));
    }

    onViewNotificationSettings = () => {
        var { UserId } = this.props.UserDetails;

        // this.setState({ RetrievedNotificationCategories: true }, () => {
            this.props.ViewNotificationSettings({ UserId }).then(() => {
                this.setState({ NotificationCategories: this.props.NotificationCategories, RetrievedNotificationCategories: true });
            })
        // });
    }

    renderAccountDetails = () => {
        var { t } = this.props;
        var { Device, Currencies, TryingSearchCurrencies } = this.props;
        var { BankAccountNo, CurrencyId, CurrencyName, DOB, FirstName, Gender, HerId, LastName, PersonalNumber, PreferredMeasuringSystem, Weight } = this.state;

        var HeightUnitText = PreferredMeasuringSystem.toLowerCase() === 'imperial' ? 'ft' : 'cm';
        var WeightUnitText = PreferredMeasuringSystem.toLowerCase() === 'imperial' ? 'lbs' : 'kg';

        var Buttons = [];
        Buttons.push({ BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Border: Gender === 'Female' ? '3px solid var(--blue-astronaut)' : null, Color: 'black', ColorHover: 'black', FontFamily: Gender === 'Female' ? 'semibold' : 'medium', Icon: Female, IconPosition: 'left', OnClick: () => this.onChangeInput({ InputName: 'Gender', InputValue: 'Female' }), Title: t('female_with_first_char_uppercase') });
        Buttons.push({ BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Border: Gender === 'Male' ? '3px solid var(--blue-astronaut)' : null, Color: 'black', ColorHover: 'black', FontFamily: Gender === 'Male' ? 'semibold' : 'medium', Icon: Male, IconPosition: 'left', OnClick: () => this.onChangeInput({ InputName: 'Gender', InputValue: 'Male' }), Title: t('male_with_first_char_uppercase') });

        return (
            <>
                <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={t('account_details')} TextAlign="left" />

                <Spacer Size="small" />

                {/* PROFILE PHOTO */}
                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('Profile_picture')} TextAlign="left" />

                    <Spacer Size="extra-small" />

                    {this.renderProfilePhoto()}

                <Spacer Size="medium" />

                {/* USER DETAILS */}
                    <UserDetailsContainer className="UserDetailsContainer">
                        <UserDetailsItemContainer className="UserDetailsItemContainer">
                            <StyledAccountInput
                                FontSize="medium-3"
                                Label={t('birthday')}
                                OnChange={event => this.onChangeInput({ InputName: 'DOB', InputValue: event.target.value })}
                                Size="large"
                                Type="date"
                                Value={DOB}
                            />
                        </UserDetailsItemContainer>

                        <UserDetailsItemContainer className="UserDetailsItemContainer">
                            <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('sex')} TextAlign="left" />

                            <StyledSexButtonGroups
                                Buttons={Buttons}
                                ButtonWidth="fit-content"
                                NotTouching
                                OwnRows={Device === 'ipad'}
                            />
                        </UserDetailsItemContainer>

                        <UserDetailsItemContainer className="UserDetailsItemContainer">
                            <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('Name')} TextAlign="left" />

                            <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('first_name')} TextAlign="left" />
                            <StyledAccountInput
                                FontSize="medium-3"
                                NoLabel
                                OnChange={event => this.onChangeInput({ InputName: 'FirstName', InputValue: event.target.value })}
                                Placeholder={t('first_name')}
                                Size="large"
                                Type="text"
                                Value={FirstName}
                            />

                            <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('last_name')} TextAlign="left" />
                            <StyledAccountInput
                                FontSize="medium-3"
                                NoLabel
                                OnChange={event => this.onChangeInput({ InputName: 'LastName', InputValue: event.target.value })}
                                Placeholder={t('last_name')}
                                Size="large"
                                Type="text"
                                Value={LastName}
                            />
                        </UserDetailsItemContainer>

                        <UserDetailsItemContainer className="UserDetailsItemContainer">
                            <StyledAccountInput
                                FontSize="medium-3"
                                Label={t('Weight') + ` (${WeightUnitText})`}
                                OnChange={event => this.onChangeInput({ InputName: 'Weight', InputValue: event.target.value })}
                                Placeholder={t('Weight')}
                                Size="large"
                                Type="number"
                                Value={Weight}
                            />
                        </UserDetailsItemContainer>

                        <UserDetailsItemContainer className="UserDetailsItemContainer">
                            <StyledHeightPageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('height') + ` (${HeightUnitText})`} TextAlign="left" />
                            {this.renderHeight()}
                        </UserDetailsItemContainer>
                    </UserDetailsContainer>

                    <Spacer Size="small" />

                    <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('Currency')} TextAlign="left" />

                    <Select
                        getOptionLabel={option => option.CurrencyName}
                        getOptionValue={option => option.CurrencyId}
                        isLoading={TryingSearchCurrencies}
                        onChange={this.onChangeCurrency}
                        onFocus={this.onFocusCurrencies}
                        options={Currencies}
                        placeholder={t('Currency')}
                        styles={DropdownStyles}
                        value={CurrencyId && { CurrencyId, CurrencyName }}
                    />

                    <Spacer Size="medium" />

                    <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={t('account_details_bank')} TextAlign="left" />

                    <Spacer Size="small" />

                    <BankAccountContainer>
                        <StyledAccountInput
                            FontSize="medium-3"
                            Label={t('bank_account_no_description')}
                            MaxWidth="500px"
                            OnBlur={this.onBlurBankAccount}
                            OnChange={event => this.onChangeInput({ InputName: 'BankAccountNo', InputValue: event.target.value })}
                            Placeholder={t('bank_account_no')}
                            Size="large"
                            Type="number"
                            Value={BankAccountNo}
                        />
                    </BankAccountContainer>

                    <Spacer Size="small" />

                    {
                        ((PersonalNumber || this.state.OriginalUserDetails.PersonalNumber) && (HerId || this.state.OriginalUserDetails.HerId)) &&
                        // 1 < 2 &&
                        <>
                            <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={t('journal')} TextAlign="left" />

                            <Spacer Size="small" />

                            <BankAccountContainer>
                                {/* <StyledAccountInput
                                    FontSize="medium-3"
                                    Label={t('journal_instructions_personalnumber')}
                                    MaxWidth="500px"
                                    OnBlur={this.onBlurPersonalNumber}
                                    OnChange={event => this.onChangeInput({ InputName: 'PersonalNumber', InputValue: event.target.value })}
                                    Placeholder=""
                                    Size="large"
                                    Type="number"
                                    Value={PersonalNumber}
                                /> */}

                                <StyledAccountInput
                                    FontSize="medium-3"
                                    Label={t('journal_instructions_herid')}
                                    MaxWidth="500px"
                                    OnBlur={this.onBlurHerId}
                                    OnChange={event => this.onChangeInput({ InputName: 'HerId', InputValue: event.target.value })}
                                    Placeholder=""
                                    Size="large"
                                    Type="number"
                                    Value={HerId}
                                />
                            </BankAccountContainer>

                            <Spacer Size="small" />
                        </>
                    }

                    {this.renderLanguage()}

                    <Spacer Size="small" />

                    {this.renderPreferredMeasuringSystem()}

                    <Spacer Size="large" />

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-left" NoMargin OnClick={this.props.LogoutUser} Text={t('Profile_Log_Out')} TextAlign="left" />
            </>
        );
    }

    renderActiveSection = () => {
        var { t } = this.props;
        var { TryingChangePassword, TryingValidateUser, UploadProgress } = this.props;
        var { ActiveSection, ConfirmNewPassword, CurrentPassword, Height, Height_Feet, LanguageId, NewPassword, PreferredMeasuringSystem, UserName, UserNameTaken, Weight, OriginalUserDetails: { OrganizationId } } = this.state;

        var Disabled = (
            TryingChangePassword || TryingValidateUser ||
            (ActiveSection === 'Account' && ((PreferredMeasuringSystem === 'Metric' && !Height) || (PreferredMeasuringSystem === 'Imperial' && !Height_Feet) || !PreferredMeasuringSystem || !LanguageId || !UserName || UserNameTaken || !Weight)) ||
            (ActiveSection === 'EmailPassword' && (!CurrentPassword || !NewPassword || (ConfirmNewPassword !== NewPassword) || (CurrentPassword === NewPassword)))
        );

        var SubmitAction = null;
        switch (ActiveSection) {
            case 'Account':
                SubmitAction = this.onEditUserProfile;
                break;
            case 'EmailPassword':
                SubmitAction = this.onChangePassword;
                break;

            default:
                SubmitAction = null;
        }

        var Buttons = [{ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: SubmitAction, Title: t('save_changes_with_first_char_uppercase') }];

        return (
            <ActiveSectionContainer className="ActiveSectionContainer" handleDrop={() => null}>
                {ActiveSection === 'Account' && this.renderAccountDetails()}

                {ActiveSection === 'EmailPassword' && this.renderEmailPassword()}

                {/* {ActiveSection === 'PreferredMeasuringSystem' && this.renderPreferredMeasuringSystem()}

                {ActiveSection === 'Language' && this.renderLanguage()} */}

                {ActiveSection === 'Notifications' && this.renderNotificationSettings()}
                
                {ActiveSection === 'Subscriptions' && !+OrganizationId && this.renderSubscriptions()}

                {
                    UploadProgress.ProfilePhoto && Object.entries(UploadProgress.ProfilePhoto).length > 0 && 
                        <>
                            <Spacer Size="medium" />

                            <UploadProgressComponent UploadingItems={UploadProgress.ProfilePhoto} />
                        </>
                }

                {
                    SubmitAction &&
                    <>
                        <Spacer Size="medium" />
                        <ButtonGroup Buttons={Buttons} ButtonWidth="fit-content" NotTouching />
                    </>
                }
            </ActiveSectionContainer>
        );
    }

    renderEmailPassword = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { ConfirmNewPassword, CurrentPassword, Email, NewPassword, ShowPasswords, WrongPassword } = this.state;

        var MaxWidth = Device === 'mobile_small' || Device === 'mobile' ? null : '400px';

        return (
            <>
                <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={t('email_password')} TextAlign="left" />

                <Spacer Size="small" />

                <StyledAccountInput
                    FontSize="medium-3"
                    Label={t('email_with_first_char_uppercase')}
                    MaxWidth={MaxWidth}
                    OnChange={() => null}
                    ReadOnly
                    Size="large"
                    Type="text"
                    Value={Email}
                />

                <Spacer Size="small" />

                <StyledAccountInput
                    FontSize="medium-3"
                    Label={t('password_with_first_char_uppercase')}
                    MaxWidth={MaxWidth}
                    OnChange={event => this.onChangePasswordInput({ InputType: 'CurrentPassword', InputValue: event.target.value })}
                    Placeholder={t('current_password')}
                    Size="large"
                    Type={ShowPasswords ? 'text' : 'password'}
                    Value={CurrentPassword}
                />

                <PageText FontColor="blue-abel" FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={() => this.onToggleShowForgotPasswordModal(true)} Text={t('RegisterLogin_forgot_password')} TextAlign="left" />

                <Spacer Size="small" />

                <StyledAccountInput
                    FontSize="medium-3"
                    MaxWidth={MaxWidth}
                    NoLabel
                    OnChange={event => this.onChangePasswordInput({ InputType: 'NewPassword', InputValue: event.target.value })}
                    Placeholder={t('Profile_new_password')}
                    Size="large"
                    Type={ShowPasswords ? 'text' : 'password'}
                    Value={NewPassword}
                />

                <StyledAccountInput
                    FontSize="medium-3"
                    MaxWidth={MaxWidth}
                    NoLabel
                    OnChange={event => this.onChangePasswordInput({ InputType: 'ConfirmNewPassword', InputValue: event.target.value })}
                    Placeholder={t('confirm_password_with_first_char_uppercase')}
                    Size="large"
                    Type={ShowPasswords ? 'text' : 'password'}
                    Value={ConfirmNewPassword}
                />

                {NewPassword && ConfirmNewPassword !== NewPassword && <PageText FontColor="red-bittersweet" FontFamily="semibold" FontSize="small" JustifyContent="flex-start" Text={t('Alert_invalid_doesnot_match_password')} TextAlign="left" />}

                {(NewPassword && ConfirmNewPassword === NewPassword) && NewPassword === CurrentPassword && <PageText FontColor="red-bittersweet" FontFamily="semibold" FontSize="small" JustifyContent="flex-start" Text={t('new_password_must_be_different')} TextAlign="left" />}

                {this.renderShowPasswords()}

                {WrongPassword && <>
                    <Spacer Size="small" />
                    <PageText FontColor="red-bittersweet" FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" Text={t('wrong_password')} TextAlign="left" />
                </>}
            </>
        );
    }

    renderForgotPasswordModal = () => {
        var { ShowForgotPasswordModal } = this.state;

        if (ShowForgotPasswordModal) {
            return (
                <ForgotPassword
                    Email={this.state.Email}
                    OnHide={() => this.onToggleShowForgotPasswordModal(false)}
                    Show={ShowForgotPasswordModal}
                />
            );
        }
    }

    renderHeight = () => {
        var { t } = this.props;
        var { Height, Height_Feet, Height_Inches, PreferredMeasuringSystem } = this.state;

        if (PreferredMeasuringSystem === 'Imperial') {
            return (
                <UnitInputContainer>
                    <HeightInput
                        FontFamily="medium"
                        FontSize="medium-3"
                        NoLabel
                        NoMargin
                        OnBlur={() => this.onBlurHeight('Height_Feet')}
                        OnChange={e => this.onChangeHeight(e, 'Height_Feet')}
                        Placeholder="0"
                        Size="large"
                        Type="number"
                        Value={`${Height_Feet}`}
                    />
                    <HeightFeetIcon ElementType="span" FontSize="medium-3" Text="'" />
                    <HeightInput
                        FontFamily="medium"
                        FontSize="medium-3"
                        NoLabel
                        NoMargin
                        OnBlur={() => this.onBlurHeight('Height_Inches')}
                        OnChange={e => this.onChangeHeight(e, 'Height_Inches')}
                        Placeholder="0"
                        Size="large"
                        Type="number"
                        Value={`${Height_Inches}`}
                    />
                    <HeightFeetIcon ElementType="span" FontSize="medium-3" Text={`"`} />
                </UnitInputContainer>
            );
        }

        return (
            <StyledAccountInput
                FontSize="medium-3"
                NoLabel
                OnBlur={() => this.onBlurHeight('Height')}
                OnChange={e => this.onChangeHeight(e, 'Height')}
                Placeholder={t('Height')}
                Size="large"
                Type="number"
                Value={Height}
            />
        );
    }

    renderImageUploadModal = () => {
        var { ShowImageUploadModal } = this.state;

        if (ShowImageUploadModal) {
            return (
                <ImageUploadCrop
                    CropSettings={{ unit: "%", aspect: 1 / 1, width: 90 }}
                    Loading={this.props.TryingUploadProfilePicture}
                    OnHide={() => this.onToggleShowImageUploadModal(false)}
                    OnSaveMedia={this.onUploadOrgPicture}
                    Show={ShowImageUploadModal}
                />
            );
        }
    }

    renderLanguage = () => {
        var { t } = this.props;
        var { AvailableLanguages, LanguageId: CurrentLanguageId, RetrievedLanguages } = this.state;

        if (!RetrievedLanguages && !this.props.TryingViewAvailableLanguages) return this.onViewAvailableLanguages();

        return (
            <>
                <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={t('language')} TextAlign="left" />

                <Spacer Size="small" />

                <FlagsContainer>
                    {
                        AvailableLanguages.map(({ FlagUrl, LanguageId, LanguageName }) => {
                            var Selected = +CurrentLanguageId === +LanguageId;

                            return (
                                <FlagContainer key={LanguageId} onClick={() => this.onChangeLanguageId(LanguageId)} Selected={Selected}>
                                    <FlagImage ImageSrc={FlagUrl} SelfLoading />
                                    <PageText ElementType="span" FontColor={Selected ? 'blue-astronaut' : 'black'} FontFamily={Selected ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={LanguageName} TextAlign="left" />
                                </FlagContainer>
                            );
                        })
                    }
                </FlagsContainer>
            </>
        );
    }

    renderNotificationSettings = () => {
        var { t } = this.props;
        var { TryingViewNotificationSettings } = this.props;
        var { NotificationCategories, RetrievedNotificationCategories } = this.state;

        if (!RetrievedNotificationCategories && !TryingViewNotificationSettings) this.onViewNotificationSettings();

        return (
            <>
                <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={t('ptadmin_navigation_notifications')} TextAlign="left" />

                <Spacer Size="small" />

                {
                    NotificationCategories.hasOwnProperty('Trainer') && NotificationCategories.Trainer.length > 0 &&
                    <>
                        <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" Text={t('about_the_clients')} TextAlign="left" />
                        <Spacer Size="small" />
                    </>
                }

                {
                    NotificationCategories.hasOwnProperty('Trainer') && NotificationCategories.Trainer.length > 0 && NotificationCategories.Trainer.map(({ CategoryName, Settings }, TrainerCategoryIndex) => {
                        return (
                            <NotificationCategory key={TrainerCategoryIndex}>
                                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" Text={CategoryName} TextAlign="left" />

                                <StyledHR />

                                {
                                    Settings.map(({ IsNotificationOn, NotificationName, NotificationType }, NotificationTypeIndex) => {
                                        return (
                                            <React.Fragment key={NotificationType}>
                                                <NotificationSetting>
                                                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={NotificationName} TextAlign="left" />

                                                    <Toggle
                                                        Checked={!!+IsNotificationOn}
                                                        OffLabel={t('off')}
                                                        OnChange={() => this.onToggleNotificationSetting({ CategoryIndex: TrainerCategoryIndex, CategoryType: 'Trainer', NotificationTypeIndex })}
                                                        OnLabel={t('on')}
                                                    />
                                                </NotificationSetting>

                                                <StyledHR />
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </NotificationCategory>
                        );
                    })
                }

                <Spacer Size="small" />

                {
                    NotificationCategories.hasOwnProperty('User') && NotificationCategories.User.length > 0 &&
                    <>
                        <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" Text={t('about_you')} TextAlign="left" />
                        <Spacer Size="small" />
                    </>
                }

                {
                    NotificationCategories.hasOwnProperty('User') && NotificationCategories.User.length > 0 && NotificationCategories.User.map(({ CategoryName, Settings }, UserCategoryIndex) => {
                        return (
                            <NotificationCategory key={UserCategoryIndex}>
                                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" Text={CategoryName} TextAlign="left" />

                                <StyledHR />

                                {
                                    Settings.map(({ IsNotificationOn, NotificationName, NotificationType }, NotificationTypeIndex) => {
                                        return (
                                            <React.Fragment key={NotificationType}>
                                                <NotificationSetting>
                                                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={NotificationName} TextAlign="left" />

                                                    <Toggle
                                                        Checked={!!+IsNotificationOn}
                                                        OffLabel={t('off')}
                                                        OnChange={() => this.onToggleNotificationSetting({ CategoryIndex: UserCategoryIndex, CategoryType: 'User', NotificationTypeIndex })}
                                                        OnLabel={t('on')}
                                                    />
                                                </NotificationSetting>

                                                <StyledHR />
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </NotificationCategory>
                        );
                    })
                }
            </>
        );
    }

    renderPreferredMeasuringSystem = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { PreferredMeasuringSystem } = this.state;

        var Buttons = [];
        Buttons.push({ BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Border: PreferredMeasuringSystem === 'Imperial' ? '3px solid var(--blue-astronaut)' : null, Color: 'black', ColorHover: 'black', FontFamily: PreferredMeasuringSystem === 'Imperial' ? 'semibold' : 'medium', OnClick: () => this.onChangePreferredMeasuringSystem('Imperial'), Title: `${t('imperial')} - ${t('imperial_examples')}` });
        Buttons.push({ BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Border: PreferredMeasuringSystem === 'Metric' ? '3px solid var(--blue-astronaut)' : null, Color: 'black', ColorHover: 'black', FontFamily: PreferredMeasuringSystem === 'Metric' ? 'semibold' : 'medium', OnClick: () => this.onChangePreferredMeasuringSystem('Metric'), Title: `${t('metric')} - ${t('metric_examples')}` });

        return (
            <>
                <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text={t('units')} TextAlign="left" />

                <Spacer Size="small" />

                <StyledSexButtonGroups
                    Buttons={Buttons}
                    // ButtonWidth={Device === 'laptop' && "350px"}
                    NotTouching
                    OwnRows={Device !== 'laptop'}
                />
            </>
        );
    }

    renderProfilePhoto = () => {
        var { t } = this.props;
        var { ProfilePhotoUrl } = this.props.UserDetails;
        var { Gender, ImageTooLarge, WrongImageType } = this.state;

        var Buttons = [{ BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: Plus, IconPosition: 'left', OnClick: () => this.onToggleShowImageUploadModal(true), Title: t('Upload') }];

        return (
            <>
                <ProfilePhotoContainer>
                    <ProfilePhoto Size="large-2" Source={ProfilePhotoUrl} Sex={Gender} ShowOverlay={false} />

                    <ButtonGroup
                        Buttons={Buttons}
                        ButtonWidth="fit-content"
                        NotTouching
                    />
                </ProfilePhotoContainer>

                {
                    ImageTooLarge || WrongImageType ?
                    <>
                        <Spacer Size="small" />

                        <PageText
                            FontColor="red-bittersweet"
                            FontFamily="semibold-italic"
                            FontSize="medium-1"
                            Text={t(ImageTooLarge ? 'media_wrongsize' : 'media_wrongtype', { AllowedTypes: '.jpg, .png, .jpeg', MaxSize: '10MB', Type: 'Image' })}
                            TextAlign="center"
                        />
                    </>
                :
                    null
                }
            </>
        );
    }

    renderSectionLink = SectionLink => {
        var { FontFamily, Name, OnClick } = SectionLink.Item;

        return <PageText FontFamily={FontFamily} FontSize="medium-1" JustifyContent="center" NoMargin OnClick={OnClick} Text={Name} TextAlign="center" />;
    }

    renderSections = () => {
        var { t } = this.props;
        var { OrganizationId } = this.props.UserDetails;
        var { ActiveSection } = this.state;

        var SettingsLinks = [
            { FontFamily: ActiveSection === 'Account' ? 'semibold' : 'medium', Id: 'Account', Name: t('account_details'), OnClick: () => this.onToggleActiveSection('Account') },
            { FontFamily: ActiveSection === 'EmailPassword' ? 'semibold' : 'medium', Id: 'EmailPassword', Name: t('email_password'), OnClick: () => this.onToggleActiveSection('EmailPassword') },
            // { FontFamily: ActiveSection === 'PreferredMeasuringSystem' ? 'semibold' : 'medium', Id: 'PreferredMeasuringSystem', Name: t('units'), OnClick: () => this.onToggleActiveSection('PreferredMeasuringSystem') },
            // { FontFamily: ActiveSection === 'Language' ? 'semibold' : 'medium', Id: 'Language', Name: t('language'), OnClick: () => this.onToggleActiveSection('Language') },
            { FontFamily: ActiveSection === 'Notifications' ? 'semibold' : 'medium', Id: 'Notifications', Name: t('ptadmin_navigation_notifications'), OnClick: () => this.onToggleActiveSection('Notifications') },
            { FontFamily: ActiveSection === 'Subscriptions' ? 'semibold' : 'medium', Id: 'Subscriptions', Name: t('Subscriptions'), OnClick: () => this.onToggleActiveSection('Subscriptions') }
            // { FontFamily: ActiveSection === 'Income' ? 'semibold' : 'medium', Id: 'Income', Name: t('income'), OnClick: () => this.onToggleActiveSection('Income') },
            // { FontFamily: ActiveSection === 'Coaches' ? 'semibold' : 'medium', Id: 'Coaches', Name: t('your_coaches'), OnClick: () => this.onToggleActiveSection('Coaches') }
        ];

        // if (!+OrganizationId) SettingsLinks = SettingsLinks.filter(SettingsLink => (SettingsLink.Id !== 'Coaches' || SettingsLink.Id !== 'Income'));
        if (!!+OrganizationId) SettingsLinks = SettingsLinks.filter(SettingsLink => SettingsLink.Id !== 'Subscriptions');

        return (
            <>
                <SectionsContainer className="SectionsContainer">
                    <FlexWrap
                        ItemId="Id"
                        ItemName="Name"
                        ItemRenderer={this.renderSectionLink}
                        Items={SettingsLinks}
                        OnSelectItem={(Item, ItemIndex) => this.onSelectCircumferenceBodyArea(Item, ItemIndex)}
                        SelectedItemIds={[]}
                    />
                </SectionsContainer>

                <StyledHR2 />
            </>
        )
    }

    renderShowPasswords = () => {
        var { t } = this.props;
        var { HoverShowPasswords, ShowPasswords } = this.state;

        return (
            <ShowPasswordsContainer onMouseEnter={this.onToggleHoverShowPasswords} onMouseLeave={this.onToggleHoverShowPasswords}>
                <CircleDiv onClick={this.onToggleShowPasswords} Selected={HoverShowPasswords || ShowPasswords} />
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin OnClick={this.onToggleShowPasswords} Text={t('show_passwords')} TextAlign="left" />
            </ShowPasswordsContainer>
        );
    }

    renderSubscriptions = () => {
        return <Subscriptions />
    }

    render() {
        if (this.state.Loading) return <Loading />

        var { TryingChangePassword, TryingEditUserProfile, TryingViewAvailableLanguages, TryingViewNotificationSettings, TryingUpdateNotificationSettings } = this.props;
        
        return (
            <>
                {(TryingChangePassword || TryingEditUserProfile || TryingViewAvailableLanguages || TryingViewNotificationSettings || TryingUpdateNotificationSettings) && <Loading />}

                <Page NoMargin NoPadding>
                    {this.renderSections()}
                    {this.renderActiveSection()}
                </Page>

                {this.renderForgotPasswordModal()}
                {this.renderImageUploadModal()}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        TryingChangePassword: state.Auth.TryingChangePassword,
        TryingChangePasswordError: state.Auth.TryingChangePasswordError,
        TryingEditUserProfile: state.Auth.TryingEditUserProfile,
        TryingEditUserProfileError: state.Auth.TryingEditUserProfileError,
        TryingUploadProfilePicture: state.Auth.TryingUploadProfilePicture,
        TryingUploadProfilePictureError: state.Auth.TryingUploadProfilePictureError,
        TryingValidateUser: state.Auth.TryingValidateUser,
        TryingValidateUserError: state.Auth.TryingValidateUserError,
        TryingViewAvailableLanguages: state.Auth.TryingViewAvailableLanguages,
        TryingViewAvailableLanguagesError: state.Auth.TryingViewAvailableLanguagesError,

        NotificationCategories: state.Notifications.NotificationCategories,

        Currencies: state.Sales.Currencies,

        UploadProgress: state.UploadContent.UploadProgress,

        TryingSearchCurrencies: state.Sales.TryingSearchCurrencies,
        TryingSearchCurrenciesError: state.Sales.TryingSearchCurrenciesError,

        TryingUpdateNotificationSettings: state.Notifications.TryingUpdateNotificationSettings,
        TryingUpdateNotificationSettingsError: state.Notifications.TryingUpdateNotificationSettingsError,
        TryingViewNotificationSettings: state.Notifications.TryingViewNotificationSettings,
        TryingViewNotificationSettingsError: state.Notifications.TryingViewNotificationSettingsError
    };
};

export default withTranslation()(connect(mapStateToProps, { ChangePassword, ClearNotificationSettings, ClearOrganizationDashboard, EditUserProfile, LogoutUser, SearchCurrencies, UpdateBankAccountNo, UpdateNotificationSettings, UploadToPresignedUrl, UploadProfilePicture, ValidateUser, ViewAvailableLanguages, ViewNotificationSettings })(Account));