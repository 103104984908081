import styled from 'styled-components';

export const CalendarContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 200px;
`;

export const CalendarDayBodyContainer = styled.div``;

export const CalendarDayContainer = styled.div`
    align-items: center;
    display: flex;
    grid-gap: 7px;
    flex-direction: column;
    justify-content: center;
`;

export const CalendarDayInnerContainer = styled.div`
    position: relative;
    height: 65px;
    width: 100%;

    .gridline:nth-child(2) {
        top: 20%;
    }

    .gridline:nth-child(3) {
        top: 40%;
    }

    .gridline:nth-child(4) {
        top: 60%;
    }

    .gridline:nth-child(5) {
        top: 80%;
    }

    .gridline:nth-child(6) {
        top: 100%;
    }
`;

export const CalendarDayGridLine = styled.div`
    background-color: var(--white-concrete);
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0%;
`;

export const CalendarDayRatingContainer = styled.div`
    background-color: var(--${props => props.CompletionStatus === 'Complete' ? 'green-success' : props.CompletionStatus === 'Overdue' ? 'red-bittersweet' : props.CompletionStatus === 'Pending' ? 'orange' : 'gray-silver'});
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    bottom: 0;
    height: ${props => props.CompletionStatus === 'Complete' ? ((props.Rating / 5) * 100) : 5}%;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 30%;
`;

export const CalendarDayStatusContainer = styled.div`
    background-color: var(--${props => props.CompletionStatus === 'Complete' ? 'green-success' : props.CompletionStatus === 'Overdue' ? 'red-bittersweet' : props.CompletionStatus === 'Pending' ? 'orange' : 'gray-silver'});
    border-radius: 20px;
    height: 100%;
    width: 5px;
`;

export const CalendarWeekContainer = styled.div`
    display: grid;
    grid-gap: 50px 10px;
    grid-template-columns: repeat(8, 100px);
`;

export const CalendarWeekColumn = styled.div``;

export const CalendarYearColumn = styled.div``;

export const CalendarYearContainer = styled.div``;

export const CalendarYearHeaderContainer = styled.div`
    display: grid;
    grid-gap: 50px 10px;
    grid-template-columns: repeat(8, 100px);
`;

export const CalendarYearWeeksContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
`;

export const CompletionStatusIcon = styled.img`
    height: 20px;
    max-width: 100%;
`;

export const EmptyIconContainer = styled.div`
    height: 20px;
`;

export const HeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;