import styled from 'styled-components';
import { device } from '../../Config/device';

import ButtonGroup from '../Buttons/ButtonGroup';
import Image from '../Image';
import Loading from '../Loading';
import PageText from '../Text/PageText';
import TextArea from '../Form/TextArea';

export const ButtonsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${device.maxwidth.mobile_small} {
        margin-bottom: 10px;
        flex-direction: row;
        justify-content: flex-start;
    }

    ${props => props.FromAddWorkouts && 'width: 100%;'}
`;

export const CollapsedWorkoutSetContainer = styled.div`
    background-color: var(--white-concrete);
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
    margin-right: 5px;
    margin-top: 5px;
    width: 30px;
`;

export const CollapsedWorkoutSetsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: -5px;
    margin-right: -5px;
`;

export const CompleteButton = styled.div`
    align-items: center;
    background-color: ${props => (props.MaxSet && props.Completed) ? 'var(--orange)' : 'var(--blue-abel)'};
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 3px;
    width: 100%;

    @media (hover:hover) {
        :hover {
            background-color: ${props => (props.MaxSet && props.Completed) ? 'var(--orange-hover)' : 'var(--blue-abel-hover)'};
            cursor: pointer;
        }
    }

    @media ${device.maxwidth.ipad} {
        background-color: ${props => props.MaxSet ? 'var(--orange)' : 'var(--blue-astronaut)'};
        padding: 6px;

        @media (hover:hover) {
            :hover {
                background-color: ${props => props.MaxSet ? 'var(--orange-hover)' : 'var(--blue-astronaut-hover)'};
            }
        }
    }
`;

export const CompleteButtonIcon = styled(Image)`
    width: 15px;
`;

export const DataTypeContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;

    :not(:last-child) {
        margin-right: 15px;
    }
`;

export const DataTypesContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;

    @media (min-width: 451px) {
        justify-content: flex-start;
    }
`;

export const DataTypesInfoContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;

    @media (min-width: 451px) {
        justify-content: flex-start;
    }
`;

export const DeleteButton = styled.div`
    align-items: center;
    background-color: var(--blue-astronaut);
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 3px;
    width: 100%;

    @media (hover:hover) {
        :hover {
            background-color: var(--blue-astronaut-hover);
            cursor: pointer;
        }
    }

    ${
        props => props.MaxSet &&
        `
            @media ${device.maxwidth.ipad} {
                background-color: var(--orange);
            }

            @media (hover:hover) {
                :hover {
                    background-color: var(--orange-hover);
                }
            }
        `
    }
`;

export const DeleteSetIcon = styled(Image)`
    max-height: 20px;
    max-width: 20px;
`;

export const EditButton = styled.div`
    align-items: center;
    background-color: ${props => props.MaxSet ? 'var(--orange)' : 'var(--blue-astronaut)'};
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 3px;
    width: 100%;

    @media (hover:hover) {
        :hover {
            background-color: ${props => props.MaxSet ? 'var(--orange-hover)' : 'var(--blue-astronaut-hover)'};
            cursor: pointer;
        }
    }
`;

export const EditSetIcon = styled(Image)`
    width: 15px;
`;

export const ExerciseDetailContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;

    @media (max-width: 450px) {
        width: 100%;
    }

    @media ${device.minwidth.mobile} {
        flex-direction: row;
    }
`;

export const ExerciseNameContainer = styled.div`
    @media (max-width: 450px) {
        width: 100%;
    }
`;

export const ExerciseNotesContainer = styled.div`
    ${
        props => !!props.FromAddWorkouts ?
        `
            background-color: var(--white);
            padding: 10px;
        `
    :
        `
            background-color: var(--chat-gray);
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 15px 20px;
        `
    }
`;

export const ExerciseNotesTextArea = styled(TextArea)`
    ${
        props => !!props.FromAddWorkouts &&
        `
            background-color: var(--chat-gray);
            border-radius: 10px;
            padding: 10px;
        `
    }

    margin-bottom: 0px;
    width: 100%;

    > textarea {
        padding: 10px;
        resize: none;
    }
`;

export const ExerciseSetTypeOptions = styled.div`
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;

    @media ${device.minwidth.laptop} {
        margin-right: 20px;
    }

    @media ${device.maxwidth.ipad} {
        width: 100%;
    }

    > div:not(:last-child) {
        margin-bottom: 10px;
    }
`;

export const ExerciseSetTypeOptionsRowContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: -10px;
    margin-top: -10px;
    width: 100%;

    > div {
        margin-right: 10px;
        margin-top: 10px;
    }

    :not(:last-child) {
        border-bottom: 3px solid ${props => !!props.FromAddWorkouts ? 'var(--white-concrete)' : 'var(--white)'};
        padding-bottom: 10px;
    }

    > div:not(:last-child) {
        margin-right: 10px;

        @media ${device.minwidth.ipad} {
            margin-right: 25px;
        }
    }
`;

export const HeaderLeftContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;

    @media ${device.minwidth.ipad} {
        align-items: flex-start;
        flex-direction: row;
    }
`;

export const HeaderLeftContainer2 = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media ${device.minwidth.ipad} {
        align-items: flex-start;
    }
`;

export const HeaderRightButton = styled.div``;

export const HeaderRightButtonsLeft = styled.div`
    display: grid;
    grid-gap: 5px;
`;

export const HeaderRightButtonsRight = styled.div`
    display: grid;
`;

export const HeaderRightContainer = styled.div`
    @media ${device.maxwidth.mobile} {
        margin-top: 10px;
        width: 100%;
    }
`;

export const InfoIcon = styled(Image)`
    height: 20px;
    margin-right: 10px;
    width: 20px;
`;

export const LoadingContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 15px;
`;

export const MaxSetButton = styled.div`
    align-items: center;
    background-color: var(--orange);
    border-radius: 10px;
    color: var(--white);
    display: flex;
    justify-content: center;
    padding: 3px 7px;

    @media ${device.maxwidth.ipad} {
        margin-left: 10px;
    }
`;

export const OuterWorkoutSetsContainer = styled.div`
    background-color: var(--white);
    padding: 10px;

    // ${props => props.CircuitType === 'WorkoutExercise' && 'border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;'}
`;

export const SetOptionsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: flex-end;

    @media ${device.minwidth.laptop} {
        flex-direction: column;
        height: 100%;
        justify-content: center;
    }

    @media ${device.maxwidth.ipad} {
        > div:nth-child(2) {
            margin-left: 5px;
        }
    }
`;

export const StyledAddAnotherSet = styled(ButtonGroup)`
    justify-content: flex-start;
`;

export const StyledCompletedButton = styled.div`
    border-radius: 10px;
    display: flex;
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-medium-1);
    max-width: 150px;
    padding: 3px 5px;
    text-align: center;

    ${
        props => props.Completed &&
        `
            background-color: var(--blue-astronaut);
            color: var(--white);
        `
    }

    @media ${device.maxwidth.ipad} {
        margin-left: 10px;
    }

    @media(hover:hover) {
        :hover {
            ${props => !props.NoCursor && 'cursor: pointer;'}

            ${
                props => !props.Completed &&
                `
                    background-color: var(--white);
                `
            }
        }
    }
`;

export const StyledExerciseImage = styled(Image)`
    border-radius: 10px;
    height: 100px;
    object-fit: cover;
    margin-right: 10px;
    width: 130px;

    > div {
        width: 100%;
    }

    @media ${device.minwidth.mobile} {
        height: 130px;
        margin-right: 20px;
        width: 170px;
    }
`;

export const StyledExerciseImageSmall = styled(Image)`
    border-radius: 10px;
    height: 90px;
    object-fit: cover;
    margin-right: 20px;
    width: 115px;

    > div {
        width: 100%;
    }
`;

export const StyledExerciseVideoSmall = styled.video`
    border-radius: 10px;
    display: flex;
    height: 90px;
    object-fit: cover;
    margin-right: 20px;
    max-width: 100%;
    width: 115px;

    > div {
        width: 100%;
    }

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const StyledExerciseImageWorkout = styled(Image)`
    border-radius: 10px;
    height: 85px;
    object-fit: cover;
    margin-right: 10px;
    width: 115px;

    > div {
        width: 100%;
    }
`;

export const StyledExerciseOptions = styled(ButtonGroup)`
    @media ${device.maxwidth.mobile} {
        width: 100%;
    }

    @media (max-width: 900px) {
        margin-top: 10px;
    }
`;

export const StyledExerciseOptionsTest = styled(ButtonGroup)`
    justify-content: flex-start;

    @media ${device.maxwidth.mobile} {
        justify-content: center;
        width: 100%;
    }

    @media (max-width: 900px) {
        margin-top: 10px;
    }
`;

export const StyledExerciseName = styled(PageText)`
    text-transform: uppercase;

    @media ${device.minwidth.mobile} {
        justify-content: flex-start;
        text-align: left;
    }

    @media ${device.maxwidth.ipad} {
        flex: 1;
        margin-right: 10px;
    }
`;

export const StyledExerciseNameCollapsed = styled(PageText)`
    text-transform: uppercase;
`;

export const StyledExerciseVideo = styled.video`
    border-radius: 10px;
    display: flex;
    height: 85px;
    object-fit: cover;
    margin-right: 10px;
    max-width: 100%;
    width: 115px;

    > div {
        width: 100%;
    }

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const StyledHeaderButtons = styled(ButtonGroup)`
    height: 100%;

    > div:first-child {
        height: 100%;
    }
`;

export const StyledLearnButton = styled(ButtonGroup)`
    @media ${device.minwidth.mobile} {
        margin-bottom: 15px;
    }
`;

export const StyledLoading = styled(Loading)`
    background-color: inherit;
    position: unset;
`;

export const StyledMaxSetButton = styled.div`
    background-color: var(--blue-astronaut-light);
    border-radius: 10px;
    color: var(--white);
    display: flex;
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-medium-1);
    max-width: 150px;
    padding: 3px 5px;
    text-align: center;

    ${props => props.IsMax && `background-color: var(--yellow-medium);`}

    @media ${device.maxwidth.ipad} {
        margin-left: 10px;
    }

    ${
        props => !props.ReadOnly &&
        `
            @media(hover:hover) {
                :hover {
                    background-color: ${props.IsMax ? 'var(--orange-hover)' : 'var(--blue-astronaut-hover)'};
                    cursor: pointer;
                }
            }   
        `
    }
`;

export const StyledSkipTest = styled(PageText)`
    text-decoration: underline;
    text-decoration-color: var(--blue-abel);
`;

export const StyledTestHeader = styled(PageText)`
    justify-content: center;
    text-align: center;

    @media ${device.minwidth.mobile} {
        justify-content: flex-start;
        text-align: left;
    }
`;

export const StyledTestSetOptions = styled(ButtonGroup)`
    @media (max-width: 900px) {
        flex-direction: column;
        width: 100%;

        > div {
            width: 100%;
        }

        > div:not(:first-child) {
            margin-left: 0px;
        }

        > div:not(:last-child) {
            margin-bottom: 10px;
        }
    }
`;

export const TestSetDescriptionContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-end;

    @media ${device.maxwidth.ipad} {
        border-top: 3px solid var(--white);
        justify-content: space-between;
        // margin-top: 15px;
        padding-top: 15px;
        width: 100%;
    }
`;

export const TestSetDescriptionContainerInner = styled.div`
    
`;

export const TestSetDescriptionImage = styled(Image)`
    height: 150px;

    @media ${device.maxwidth.ipad} {
        margin-right: 10px;
    }

    @media ${device.minwidth.laptop} {
        margin-left: 10px;
    }
`;

export const WEHeaderContainerCollapsed = styled.div`
    align-items: flex-start;
    background-color: ${props => (props.Collapsed || props.FromAddWorkouts) ? 'var(--white)' : props.Completed && props.TestSets ? 'var(--blue-astronaut)' : 'var(--chat-gray)'};

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    ${
        props => ((props.Completed && props.TestSets) || props.Collapsed) &&
        `
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        `
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 20px;

    @media ${device.maxwidth.ipad} {
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }
`;

export const WEHeaderContainer = styled.div`
    align-items: center;
    background-color: ${props => (props.Collapsed || props.FromAddWorkouts) ? 'var(--white)' : props.Completed && props.TestSets ? 'var(--blue-astronaut)' : 'var(--chat-gray)'};

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    ${
        props => ((props.Completed && props.TestSets) || props.Collapsed) &&
        `
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        `
    }

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 20px;

    @media ${device.maxwidth.ipad} {
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;
    }
`;

export const WEHeaderTopContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    @media ${device.maxwidth.ipad} {
        margin-bottom: 10px;
    }

    > div:first-child {
        align-self: center;
    }
`;

export const WEHeaderTopContainerCollapsed = styled.div`
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media ${device.maxwidth.ipad} {
        flex-direction: column;
        justify-content: center;
        margin-bottom: 10px;
    }

    > div:first-child {
        align-self: center;
    }
`;

export const WEHeaderTopContainerCollapsed1 = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 100%;

    @media ${device.maxwidth.ipad} {
        margin-bottom: 10px;
    }
`;

export const WESetAndMaxContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;

        justify-content: space-between;
        width: 100%;
`;

export const WESetAndMaxContainerLeft = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media ${device.minwidth.laptop} {
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }
`;

export const WESetAndMaxContainerRight = styled.div``;

export const WESetContainer = styled.div`
    align-items: stretch;

    ${props => props.MaxSet && !props.Completed && 'border: 2px solid var(--orange);'}

    // border: 2px solid ${props => props.HasBorder ? props.BorderColor ? props.BorderColor : props.MaxSet ? 'var(--orange)' : 'var(--blue-astronaut)' : 'var(--white-concrete)'};
    border-radius: 10px;
    ${props => props.JustUpdated && 'box-shadow: 0px 0px 10px 5px var(--yellow-medium);'}
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media ${device.minwidth.laptop} {
        display: grid;
        grid-template-columns: 0.5fr 8fr 0.25fr;
    }
`;

export const WESetDetailsContainer = styled.div`
    width: 100%;
`;

export const WESetFooterContainer = styled.div`
    align-items: center;
    background-color: var(--blue-astronaut);
    display: flex;
    justify-content: center;
    padding: 7px;

    ${props => !!props.onClick && `
        @media(hover:hover) {
            :hover {
                background-color: var(--blue-astronaut-hover);
                cursor: pointer;
            }
        }
    `}
`;

export const WESetFooterContainer2 = styled.div`
    ${props => props.Completed && 'border-left: 2px solid var(--white-concrete);'}
`;

export const WESetHeaderContainer = styled.div`
    align-items: center;
    background-color: ${props => !!props.MaxSet ? 'var(--orange)' : 'var(--blue-astronaut)'};
    display: flex;
    flex-direction: row;
    padding: 8px;
    width: 100%;

    ${
        props => props.Completed &&
        `
            @media ${device.minwidth.laptop} {
                border-right: 2px solid var(--white-concrete);
            }

            @media ${device.maxwidth.ipad} {
                border-bottom: 2px solid var(--white-concrete);
            }
        `
    }

    @media ${device.minwidth.laptop} {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
`;

export const WESetsContainer = styled.div`
    background-color: var(--chat-gray);
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    
    ${props => props.HasOnlyBottomMargin && 'padding: 0px 0px 15px;'}

    ${props => !!props.FromAddWorkouts && 'border-radius: 10px;'}
`;

export const WorkoutExerciseContainer = styled.div`
    ${props => props.CircuitType === 'WorkoutExercise' && 'border-radius: 10px;'}

    // ${props => props.Completed && `background-color: var(--blue-astronaut);`}
`;