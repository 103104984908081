import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearDishDetail, ClearPrivateDishSettings, ClearSaveData, InsertEditPrivateDish, GetDishDetail, UploadToPresignedUrl, ViewCreatePrivateDishSettings, ViewFoodUnits } from '../../Services/Actions';
import history from '../../history';

import { BETA_SITE_URL } from '../../Config';

import { CookingTimeIcon, CookingTimeInput, CookingTimeOptionContainer, CookingTimesContainer, CookingTimeOptionsContainer, DeleteIcon, DishImageContainer, DishImageInputContainer, IngredientContainer, IngredientListContainer, IngredientNameStyled, IngredientOptionsContainer, IngredientQuantityStyledInput, IngredientUnit, IngredientsContainer, InstructionContainer, InstructionsContainer, RatingImage, RatingsContainer, RequiredHeaderContainer, StyledDishInput, StyledDishName, StyledInputIcon, StyledInstruction } from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import UploadProgressComponent from '../../Components/UploadProgress';

import AddDishCategory from '../../Modals/AddDishCategory';
import AddFood from '../../Modals/AddFood';
import FoodUnits from '../../Modals/FoodUnits';

import Blocked from '../../Assets/Icons/Ratings/Blocked_White.png';
import Blocked_Selected from '../../Assets/Icons/Ratings/Blocked_Filled.png';
import FourStar from '../../Assets/Icons/Ratings/4Star_White.png';
import FourStar_Selected from '../../Assets/Icons/Ratings/4Star_Filled.png';
import FiveStar from '../../Assets/Icons/Ratings/5Star_White.png';
import FiveStar_Selected from '../../Assets/Icons/Ratings/5Star_Filled.png';
import Plus from '../../Assets/Icons/Plus.png'; 
import ThreeStar from '../../Assets/Icons/Ratings/3Star_White.png';
import ThreeStar_Selected from '../../Assets/Icons/Ratings/3Star_Filled.png';
import TwoStar from '../../Assets/Icons/Ratings/2Star_White.png';
import TwoStar_Selected from '../../Assets/Icons/Ratings/2Star_Filled.png';

import FlexWrap from '../../Components/Lists/FlexWrap';

import { CreateUploadToPresignedUrlPromise, getFileExtension, validateFile } from '../../Functions';

class Dish extends React.Component {
    _isMounted = false;

    state = {
        CookingTimes: { ActiveTime: 0, PrepareTime: 0 },
        DishCategory: 0,
        DishId: 0,
        DishImage: null,
        DishIngredients: [],
        DishIngredientsIndex: [],
        DishInstructions: [],
        DishName: '',
        ExistingDishId: 0,
        FoodUnitsData: {},
        HoveringRating: 0,
        ImageTooLarge: false,
        IsImageNew: true,
        Loading: true,
        MaxImageSize: 10000000,
        MealTypesIndex: [],
        Rating: 0,
        SelectedImage: null,
        ShowAddDishCategoryModal: false,
        ShowAddFoodModal: false,
        ShowFoodUnitsModal: false,
        WrongImageType: false
    };

    componentDidMount() {
        var { CopyDishDetail } = this.props;
        var { DishId } = this.props.match.params;

        var RedirectLink = `${BETA_SITE_URL}/nutrition-library/dish`;

        if (Object.entries(CopyDishDetail).length !== 0) RedirectLink += `/${CopyDishDetail.DishId}?createFromExistingDish=true`;
        else if (!!+DishId) RedirectLink += `/${DishId}`;

        window.location = RedirectLink;

        // this._isMounted = true;

        // var { CopyDishDetail } = this.props;
        // var DishDetail = {};

        // if (Object.entries(CopyDishDetail).length !== 0) {
        //     var { ActiveTime, dataFoods, dataInstructions, dataMealTypes, DishId: ExistingDishId, DishName, DishUrl, PrepareTime, PrivateDishCategoryId, Rating } = CopyDishDetail;

        //     DishDetail = {
        //         CookingTimes: { ActiveTime, PrepareTime },
        //         DishCategory: +PrivateDishCategoryId,
        //         DishIngredients: dataFoods.map(({ dataUnits, FoodId, FoodName, Quantity, UnitId, UnitName }) => ({ dataUnits, FoodId, FoodName, Quantity, UnitId, UnitName })),
        //         DishIngredientsIndex: dataFoods.map(({ FoodId }) => Number(FoodId)),
        //         DishInstructions: dataInstructions.map(({ InstructionName }) => InstructionName),
        //         DishImage: DishUrl,
        //         DishName,
        //         ExistingDishId: +ExistingDishId,
        //         IsImageNew: false,
        //         MealTypesIndex: dataMealTypes.filter(({ IsSelected }) => +IsSelected).map(({ MealTypeId }) => +MealTypeId),
        //         Rating: +Rating
        //     }
        // }

        // var { DishId } = this.props.match.params;
        // DishId = +DishId || 0;

        // this.props.ViewCreatePrivateDishSettings().then(() => {
        //     if (this._isMounted) {
        //         if (DishId) {
        //             this.props.GetDishDetail({ DishId }).then(() => {
        //                 if (this._isMounted) {
        //                     var { ActiveTime, dataFoods, dataInstructions, dataMealTypes, DishName, DishUrl, PrepareTime, PrivateDishCategoryId, Rating } = this.props.DishDetail;
                    
        //                     DishDetail = {
        //                         CookingTimes: { ActiveTime, PrepareTime },
        //                         DishCategory: +PrivateDishCategoryId,
        //                         DishIngredients: dataFoods.map(({ dataUnits, FoodId, FoodName, Quantity, UnitId, UnitName }) => ({ dataUnits, FoodId, FoodName, Quantity, UnitId, UnitName })),
        //                         DishIngredientsIndex: dataFoods.map(({ FoodId }) => Number(FoodId)),
        //                         DishInstructions: dataInstructions.map(({ InstructionName }) => InstructionName),
        //                         DishImage: DishUrl,
        //                         DishName,
        //                         ExistingDishId: +DishId,
        //                         IsImageNew: false,
        //                         MealTypesIndex: dataMealTypes.filter(({ IsSelected }) => +IsSelected).map(({ MealTypeId }) => +MealTypeId),
        //                         Rating: +Rating
        //                     }

        //                     this.setState({ ...DishDetail, DishId, Loading: false });
        //                 }
        //             });
        //         } else this.setState({ ...DishDetail, Loading: false });
        //     }
        // });
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        
        this.props.ClearSaveData();
        this.props.ClearPrivateDishSettings();

        if (this.state.DishId) this.props.ClearDishDetail();
    }

    onAddDishCategory = LastCategoryId => {
        this.setState({ DishCategory: Number(LastCategoryId), ShowAddDishCategoryModal: false });
    }

    onAddFood = ({ FoodId, FoodName, UnitId, UnitName }) => {
        this.setState({ ShowAddFoodModal: false }, () => {
            this.props.ViewFoodUnits([ FoodId ]).then(FoodUnits => {
                if (!this.props.TryingViewFoodUnitsError) {
                    var NewIngredient = { FoodId, FoodName, UnitId, UnitName, Quantity: '1', dataUnits: FoodUnits[0].dataUnits };
        
                    this.setState({
                        DishIngredients: [ ...this.state.DishIngredients, ...[ NewIngredient ] ],
                        DishIngredientsIndex: [ ...this.state.DishIngredientsIndex, FoodId ]
                    });
                }
            })
        });
    }

    onAddInstruction = () => {
        this.setState({ DishInstructions: [ ...this.state.DishInstructions, ...[ '' ] ] });
    }

    onBlurFoodQuantity = FoodIndex => {
        var UpdateFoodQuantity_DishIngredients = this.state.DishIngredients;

        if (+UpdateFoodQuantity_DishIngredients[FoodIndex].Quantity === 0) {
            UpdateFoodQuantity_DishIngredients[FoodIndex].Quantity = 0;
            this.setState({ DishIngredients: UpdateFoodQuantity_DishIngredients });
        }
    }

    onChangeCookingTime = (event, TimeType) => {
        var CookingTimes = this.state.CookingTimes;
        CookingTimes[`${TimeType}`] = event.target.value;

        this.setState({ CookingTimes });
    }

    onChangeDishName = event => {
        this.setState({ DishName: event.target.value });
    }

    onChangeFoodQuantity = (event, FoodIndex) => {
        var UpdateFoodQuantity_DishIngredients = this.state.DishIngredients;
        UpdateFoodQuantity_DishIngredients[FoodIndex].Quantity = event.target.value;

        this.setState({ DishIngredients: UpdateFoodQuantity_DishIngredients });
    }

    onChangeInstruction = (event, InstructionIndex) => {
        var UpdateDishInstruction = this.state.DishInstructions;
        UpdateDishInstruction[InstructionIndex] = event.target.value;

        this.setState({ DishInstructions: UpdateDishInstruction });
    }

    onChangeRating = Rating => {
        this.setState({ Rating });
    }

    onDeleteFood = (FoodId, FoodIndex) => {
        var DishIngredients = [ ...this.state.DishIngredients.slice(0, FoodIndex), ...this.state.DishIngredients.slice(FoodIndex + 1) ];

        var DishIngredientsIndex_Index = this.state.DishIngredientsIndex.indexOf(FoodId);
        var DishIngredientsIndex = [ ...this.state.DishIngredientsIndex.slice(0, DishIngredientsIndex_Index), ...this.state.DishIngredientsIndex.slice(DishIngredientsIndex_Index + 1) ];

        this.setState({ DishIngredients, DishIngredientsIndex });
    }

    onHoverRating = HoveringRating => {
        this.setState({ HoveringRating });
    }

    onInsertEditDish = () => {
        var { CookingTimes: { ActiveTime, PrepareTime }, DishCategory, DishId, DishIngredients, DishInstructions, DishName, ExistingDishId, IsImageNew, MealTypesIndex, Rating, SelectedImage } = this.state;

        if (SelectedImage) var { type: ContentType, name: FileName } = SelectedImage;

        this.props.InsertEditPrivateDish({ ActiveTime, ContentType, DishId, DishName, FileName, Ingredients: DishIngredients, ExistingDishId, Instructions: DishInstructions, IsImageNew: IsImageNew ? 1 : 0, MealTypes: MealTypesIndex, PrepareTime, PrivateDishCategoryId: DishCategory, Rating }).then(({ PresignedFormData }) => {
            if (this._isMounted && !this.props.TryingCreatePrivateDishError) {
                // If Image, Then Use PreSignedUrl to Upload To AWS
                if (SelectedImage && PresignedFormData) {
                    var UploadFunction = this.props.UploadToPresignedUrl;
                    var Promises = [ CreateUploadToPresignedUrlPromise({ ContentType: 'CustomDish', File: SelectedImage, PresignedFormData, UploadFunction }) ];

                    Promise.all(Promises).then(() => {
                        history.push({ pathname: '/library', search: '?tab=dishes' });
                    });
                    
                } else history.push({ pathname: '/library', search: '?tab=dishes' });
            }
        });
    }

    onSelectDishCategory = ({ CategoryId }) => {
        this.setState({ DishCategory: Number(CategoryId) });
    }

    onSelectDishImage = event => {
        if (event.target.files.length) {
            var SelectedImage = event.target.files[0];
            var FileExtension = getFileExtension(SelectedImage.name);

            if (validateFile({ FileExtension, FileType: 'Images' })) {
                if (SelectedImage.size <= this.state.MaxImageSize) {
                    this.setState({ DishImage: URL.createObjectURL(SelectedImage), ImageTooLarge: false, IsImageNew: true, SelectedImage, WrongImageType: false});
                } else {
                    document.getElementById('DishImage').value = null;
                    this.setState({ ImageTooLarge: true, WrongImageType: false });
                }
            } else this.setState({ ImageTooLarge: false, WrongImageType: true });
        }
        //else this.setState({ SelectedImage: null, DishImage: null, ImageTooLarge: false, WrongImageType: false });
    }

    onSelectMealType = ({ MealTypeId }, MealTypeIndex) => {
        if (MealTypeIndex === -1) this.setState({ MealTypesIndex: [ ...this.state.MealTypesIndex, Number(MealTypeId) ] });
        else {
            this.setState((prevState) => {
                return {
                    MealTypesIndex: [ ...prevState.MealTypesIndex.slice(0, MealTypeIndex), ...prevState.MealTypesIndex.slice(MealTypeIndex + 1) ]
                }
            });
        }
    }

    onSelectUnit = ({ FoodIndex, UnitId, UnitName }) => {
        var UpdateFoodUnits_DishIngredients = this.state.DishIngredients;
        UpdateFoodUnits_DishIngredients[FoodIndex].UnitId = UnitId;
        UpdateFoodUnits_DishIngredients[FoodIndex].UnitName = UnitName;

        this.setState({ DishIngredients: UpdateFoodUnits_DishIngredients, ShowFoodUnitsModal: false });
    }

    onToggleAddDishCategoryModal = ShowAddDishCategoryModal => {
        this.setState({ ShowAddDishCategoryModal });
    }

    onToggleAddFoodModal = ShowAddFoodModal => {
        this.setState({ ShowAddFoodModal });
    }

    onToggleFoodUnitsModal = (ShowFoodUnitsModal, FoodIndex, FoodUnits, SelectedUnitId) => {
        this.setState({ ShowFoodUnitsModal, FoodUnitsData: { FoodIndex, FoodUnits, SelectedUnitId } });
    }

    renderAddDishCategoryModal = () => {
        var { ShowAddDishCategoryModal } = this.state;

        if (ShowAddDishCategoryModal) {
            return (
                <AddDishCategory
                    OnAddDishCategory={LastCategoryId => this.onAddDishCategory(LastCategoryId)}
                    OnHide={() => this.onToggleAddDishCategoryModal(false)}
                    Show={ShowAddDishCategoryModal}
                />
            );
        }
    }

    renderAddFoodModal = () => {
        var { DishIngredientsIndex, ShowAddFoodModal } = this.state;

        if (ShowAddFoodModal) {
            return (
                <AddFood
                    ExcludeFoodIds={DishIngredientsIndex}
                    OnAddFood={Food => this.onAddFood(Food)}
                    OnHide={() => this.onToggleAddFoodModal(false)}
                    Show={ShowAddFoodModal}
                />
            );
        }
    }

    renderCookingTimes = () => {
        var { t } = this.props;
        var { CookingTimes: { ActiveTime, PrepareTime } } = this.state;

        return (
            <CookingTimesContainer className="CookingTimesContainer">
                <PageText FontFamily="medium" FontSize="medium-3" Text={t('MealPlan_How_long_does_it_take_to_make_this_dish')} TextAlign="center" />
                <CookingTimeOptionsContainer className="CookingTimeOptionsContainer">
                    <CookingTimeOptionContainer className="CookingTimeOptionContainer">
                        <CookingTimeIcon ImageSrc={Plus} Loading={false} />
                        <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('MealPlan_Preparing_time')} />
                        <CookingTimeInput
                            FontFamily="bold"
                            FontSize="medium-3"
                            NoDecimal
                            NoLabel
                            OnChange={event => this.onChangeCookingTime(event, 'PrepareTime')}
                            Placeholder="0"
                            Size="medium"
                            Type="number"
                            Value={PrepareTime}
                        />
                        <PageText FontFamily="medium" FontSize="medium-1" Text={t('min_str')} TextAlign="center" />
                    </CookingTimeOptionContainer>
                    <CookingTimeOptionContainer className="CookingTimeOptionContainer">
                        <CookingTimeIcon ImageSrc={Plus} Loading={false} />
                        <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('MealPlan_Cooking_Time')} />
                        <CookingTimeInput
                            FontFamily="bold"
                            FontSize="medium-3"
                            NoDecimal
                            NoLabel
                            OnChange={event => this.onChangeCookingTime(event, 'ActiveTime')}
                            Placeholder="0"
                            Size="medium"
                            Type="number"
                            Value={ActiveTime}
                        />
                        <PageText FontFamily="medium" FontSize="medium-1" Text={t('min_str')} TextAlign="center" />
                    </CookingTimeOptionContainer>
                </CookingTimeOptionsContainer>
            </CookingTimesContainer>
        );
    }

    renderDishCategories = () => {
        var { t } = this.props;
        var { DishCategories } = this.props;
        var { DishCategory } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" Text={t('MealPlan_Which_category_do_you_want_to_add_this_dish_to')} TextAlign="center" />
                
                <FlexWrap
                    IsMultiSelect={false}
                    ItemId="CategoryId"
                    ItemName="CategoryName"
                    Items={DishCategories}
                    OnSelectItem={Item => this.onSelectDishCategory(Item)}
                    SelectedItemIds={[ DishCategory ]}
                />

                <Spacer Size="medium" />

                <ButtonGroup
                    Buttons={[
                        { Icon: Plus, IconPosition: 'left', OnClick: () => this.onToggleAddDishCategoryModal(true), Title: `${t('Create_new_category_str')}`}
                    ]}
                    ButtonWidth="auto"
                />
            </>
        );
    }

    renderDishImage = () => {
        var { t } = this.props;
        var { DishImage, ImageTooLarge, WrongImageType } = this.state;

        return (
            <DishImageContainer>
                <PageText FontFamily="medium" FontSize="medium-3" Text={t('Profile_upload_a_picture_screenshot_button_title')} />
                <DishImageInputContainer BackgroundImage={DishImage}>
                    <StyledInputIcon ImageSrc={Plus} Loading={false} />
                    <StyledDishInput
                        accept="image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg"
                        id="DishImage"
                        type="file"
                        onChange={this.onSelectDishImage}
                    />
                </DishImageInputContainer>

                {
                    ImageTooLarge || WrongImageType ?
                    <>
                        <Spacer Size="small" />
                        <PageText
                            FontColor="red-bittersweet"
                            FontFamily="semibold-italic"
                            FontSize="medium-1"
                            Text={t(ImageTooLarge ? 'media_wrongsize' : 'media_wrongtype', { AllowedTypes: '.jpg, .png, .jpeg', MaxSize: '10MB', Type: 'Image' })}
                            TextAlign="center"
                        />
                    </>
                :
                    null
                }
            </DishImageContainer>
        );
    }

    renderDishName = () => {
        var { t } = this.props;
        var { DishName } = this.state;

        return (
            <>
                <RequiredHeaderContainer>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-3" Text={t('MealPlan_Name_your_dish')} />
                    <PageText ElementType="span" FontColor="red-bittersweet" FontFamily="medium" FontSize="medium-3" Text=" *" />
                </RequiredHeaderContainer>

                <StyledDishName
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeDishName}
                    Placeholder={t('MealPlan_Name_your_dish')}
                    Size="medium"
                    Type="text"
                    Value={DishName}
                />
            </>
        );
    }

    renderFoodUnitsModal = () => {
        var { FoodUnitsData: { FoodIndex, FoodUnits: SelectedFoodUnits, SelectedUnitId }, ShowFoodUnitsModal } = this.state;

        if (ShowFoodUnitsModal) {
            return (
                <FoodUnits
                    FoodUnits={SelectedFoodUnits}
                    OnHide={() => this.onToggleFoodUnitsModal(false)}
                    OnSelectUnit={({ UnitId, UnitName }) => this.onSelectUnit({ FoodIndex, UnitId, UnitName })}
                    SelectedUnitId={Number(SelectedUnitId)}
                    Show={ShowFoodUnitsModal}
                />
            );
        }
    }

    renderIngredients = () => {
        var { t } = this.props;
        var { DishIngredients } = this.state;

        return (
            <IngredientsContainer>
                <RequiredHeaderContainer>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-3" Text={t('ingredients')} />
                    <PageText ElementType="span" FontColor="red-bittersweet" FontFamily="medium" FontSize="medium-3" Text=" *" />
                </RequiredHeaderContainer>

                <PageText FontFamily="medium" FontSize="medium-1" Text={t('scaling_description')} TextAlign="center" />
                <IngredientListContainer>
                    {
                        DishIngredients.map(({ dataUnits, FoodId, FoodName, Quantity, UnitId, UnitName }, FoodIndex) => {
                            return (
                                <IngredientContainer key={FoodId}>
                                    <IngredientNameStyled FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={FoodName} TextAlign="left" />
                                    <IngredientOptionsContainer>
                                        <IngredientQuantityStyledInput
                                            FontFamily="semibold"
                                            NoLabel
                                            NoMargin
                                            OnBlur={() => this.onBlurFoodQuantity(FoodIndex)}
                                            OnChange={event => this.onChangeFoodQuantity(event, FoodIndex)}
                                            Type="number"
                                            Value={Quantity}
                                        />
                                        <IngredientUnit FontSize="medium-1" NoMargin OnClick={() => this.onToggleFoodUnitsModal(true, FoodIndex, dataUnits, UnitId)} OnClickTarget="container" Text={UnitName} />
                                        <DeleteIcon onClick={() => this.onDeleteFood(FoodId, FoodIndex)}>X</DeleteIcon>
                                    </IngredientOptionsContainer>
                                </IngredientContainer>
                            );
                        })
                    }
                    <ButtonGroup
                        Buttons={[
                            { Icon: Plus, IconPosition: 'left', OnClick: () => this.onToggleAddFoodModal(true), Title: `${t('MealPlan_Add_ingredient')}`}
                        ]}
                    />
                </IngredientListContainer>
            </IngredientsContainer>
        );
    }

    renderInstructions = () => {
        var { t } = this.props;
        var { DishInstructions } = this.state;

        return (
            <InstructionsContainer>
                <PageText FontFamily="medium" FontSize="medium-3" Text={t('instructions')} />
                <PageText FontFamily="medium" FontSize="medium-1" Text={t('instructions_description')} TextAlign="center" />
                {
                    DishInstructions.map((InstructionName, InstructionIndex) => {
                        return (
                            <InstructionContainer key={InstructionIndex}>
                                <PageText ElementType="span" FontFamily="bold" FontSize="medium-1" NoMargin Text={`${InstructionIndex + 1} -`} />
                                &nbsp;
                                <StyledInstruction
                                    NumRows={4}
                                    OnBlur={event => this.onChangeInstruction(event, InstructionIndex)}
                                    Value={InstructionName}
                                />
                            </InstructionContainer>
                        );
                    })
                }

                <ButtonGroup
                    Buttons={[
                        { Icon: Plus, IconPosition: 'left', OnClick: this.onAddInstruction, Title: `${t('MealPlan_Add_instruction')}`}
                    ]}
                />
            </InstructionsContainer>
        );
    }

    renderMealTypes = () => {
        var { t } = this.props;
        var { MealTypes } = this.props;
        var { MealTypesIndex } = this.state;

        return (
            <>
                <RequiredHeaderContainer>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-3" Text={t('MealPlan_Which_meal_does_this_dish_fit_in')} />
                    <PageText ElementType="span" FontColor="red-bittersweet" FontFamily="medium" FontSize="medium-3" Text=" *" />
                </RequiredHeaderContainer>

                <FlexWrap
                    IsMultiSelect
                    ItemId="MealTypeId"
                    ItemName="MealTypeName"
                    Items={MealTypes}
                    OnSelectItem={(Item, ItemIndex) => this.onSelectMealType(Item, ItemIndex)}
                    SelectedItemIds={MealTypesIndex}
                />
            </>
        );
    }

    renderRatings = () => {
        var { t } = this.props;
        var { HoveringRating, Rating } = this.state;

        var Ratings = [
            { Id: 1, Image: (HoveringRating === 1 || Rating === 1) ? Blocked_Selected : Blocked },
            { Id: 2, Image: (HoveringRating === 2 || Rating === 2) ? TwoStar_Selected : TwoStar },
            { Id: 3, Image: (HoveringRating === 3 || Rating === 3) ? ThreeStar_Selected : ThreeStar },
            { Id: 4, Image: (HoveringRating === 4 || Rating === 4) ? FourStar_Selected : FourStar },
            { Id: 5, Image: (HoveringRating === 5 || Rating === 5) ? FiveStar_Selected : FiveStar }
        ];

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" Text={t('MealPlan_want_to_rate_this_dish')} TextAlign="center" />

                <RatingsContainer>
                    {
                        Ratings.map(({ Id, Image }) =>
                            <RatingImage
                                key={Id}
                                alt="Rating"
                                onClick={() => this.onChangeRating(Id)}
                                onMouseEnter={() => this.onHoverRating(Id)}
                                onMouseLeave={this.onHoverRating}
                                src={Image}
                            />
                        )
                    }
                </RatingsContainer>
            </>
        );
    }

    render() {
        if (this.state.Loading || this.props.TryingGetCreatePrivateDishSettings) return <Loading />;

        var { t } = this.props;
        var { Device, TryingCreatePrivateDish, TryingViewFoodUnits, UploadProgress } = this.props;
        var { DishId, DishIngredientsIndex, DishName, MealTypesIndex } = this.state;

        var Breadcrumbs = [{ Name: t('ptadmin_navigation_library'), LinkURL: 'library' }, { Name: t(DishId ? 'MealPlan_Edit_private_dish' : 'MealPlan_Create_private_dish') }];
        var SubmitButtonProps = {
            Disabled: (DishIngredientsIndex.length && DishName && MealTypesIndex.length) ? false : true ,
            OnClick: this.onInsertEditDish,
            Title: t(DishId ? 'ptadmin_clientnote_options_save' : 'MealPlan_Create_private_dish'),
        }

        return (
            <>
                {TryingCreatePrivateDish || TryingViewFoodUnits ? <Loading /> : null}

                <Page
                    renderBreadcrumbs={Breadcrumbs}
                    renderSubmitButton={SubmitButtonProps}
                >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={t(DishId ? 'MealPlan_Edit_private_dish' : 'MealPlan_Create_private_dish')} />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderDishName()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderIngredients()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderInstructions()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderDishImage()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderCookingTimes()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderMealTypes()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderDishCategories()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderRatings()}

                    {
                        UploadProgress.CustomDish && Object.entries(UploadProgress.CustomDish).length > 0 && 
                            <>
                                <Spacer Size="medium" />

                                <UploadProgressComponent UploadingItems={UploadProgress.CustomDish} />
                            </>
                    }
                </Page>

                {this.renderAddDishCategoryModal()}
                {this.renderAddFoodModal()}
                {this.renderFoodUnitsModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        CopyDishDetail: state.SaveData.CopyDishDetail,

        DishCategories: state.Dish.DishCategories,
        MealTypes: state.Dish.MealTypes,
        
        DishDetail: state.Meal.DishDetail,

        UploadProgress: state.UploadContent.UploadProgress,

        TryingCreatePrivateDish: state.Dish.TryingCreatePrivateDish,
        TryingCreatePrivateDishError: state.Dish.TryingCreatePrivateDishError,
        TryingGetCreatePrivateDishSettings: state.Dish.TryingGetCreatePrivateDishSettings,
        TryingGetCreatePrivateDishSettingsError: state.Dish.TryingGetCreatePrivateDishSettingsError,
        TryingGetDishDetail: state.Meal.TryingGetDishDetail,
        TryingGetDishDetailError: state.Meal.TryingGetDishDetailError,
        TryingViewFoodUnits: state.Food.TryingViewFoodUnits,
        TryingViewFoodUnitsError: state.Food.TryingViewFoodUnitsError,
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearDishDetail, ClearPrivateDishSettings, ClearSaveData, GetDishDetail, InsertEditPrivateDish, UploadToPresignedUrl, ViewCreatePrivateDishSettings, ViewFoodUnits } )(Dish));