import { useState, useEffect } from "react";
import { jwtDecode } from 'jwt-decode';

const decodeToken = token => {
    if (!token) {
      return null;
    }
    
    try {
      const decode = jwtDecode(token);
      return decode;
    } catch (error) {
      console.error("Failed to decode a token", error);
      return null;
    }
};

const useHasExpired = () => {
    const [hasExpired, setHasExpired] = useState(false);

    const setupInterval = () => {
        const refreshToken = localStorage.getItem("refreshToken") || "";

        const decoded = decodeToken(refreshToken);
        const timestamp = decoded ? decoded.exp : 0;

        if (!timestamp) return;

        const now = Math.floor(Date.now() / 1000);
        setHasExpired(now > timestamp);

        const interval = setInterval(() => {
            const current = Math.floor(Date.now() / 1000);
            setHasExpired(current > timestamp);

            if (current > timestamp) {
                clearInterval(interval);
            }
        }, 1000);

        return interval;
    };

    useEffect(() => {
        let interval = setupInterval();

        const handleTokenRefresh = () => {
            if (interval) clearInterval(interval);
            interval = setupInterval();
        };

        document.addEventListener("tokenRefreshed", handleTokenRefresh);
            
        // Clean up
        return () => {
            if (interval) clearInterval(interval);
            document.removeEventListener("tokenRefreshed", handleTokenRefresh);
        };
    }, []);

    return hasExpired;
};

export default useHasExpired;