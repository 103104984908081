import React from 'react';
import history from '../../../history';

import * as Styles from './styles';

import PageText from '../../../Components/Text/PageText';

const LINKS = {
    'TopLeaders': { Name: 'Top Leaders', Slug: 'top-leaders' },
    'MiddleLeaders': { Name: 'Middle Leaders', Slug: 'middle-leaders' },
    'Leaders': { Name: 'Leaders', Slug: 'leaders' },
    'Companies': { Name: 'Companies', Slug: 'companies' },
    'DHCs': { Name: 'DHCs', Slug: 'dhcs' },
    'Employees': { Name: 'Employees', Slug: 'employees' },
    'Orders': { Name: 'Orders', Slug: 'orders' },
    'DeliveryTracking': { Name: 'Delivery', Slug: 'delivery-tracking' },
    'Sales': { Name: 'Sales', Slug: 'sales' }
}

class Header extends React.Component {
    onChangeLink = PageNameSlug => {
        history.push({ pathname: `/delivery-site`, search: `?tab=${PageNameSlug}` });
    }

    render() {
        var { PageName } = this.props;

        return (
            <Styles.HeaderContainer className="HeaderContainer">
                <Styles.HeaderLeftContainer className="HeaderLeftContainer">
                    {
                        Object.keys(LINKS).map((key, LinkIndex) => {
                            var { Name, Slug } = LINKS[key];

                            return (
                                <React.Fragment key={LinkIndex}>
                                    <PageText
                                        FontColor="black"
                                        FontFamily={(PageName === key) ? 'bold' : 'medium'}
                                        FontFamilyHover="bold"
                                        FontSize="medium-1"
                                        JustifyContent="flex-start"
                                        NoMargin
                                        OnClick={() => this.onChangeLink(Slug)}
                                        Selectable
                                        SelectedOptions={{ Position: 'left', Selected: (PageName === key) }}
                                        Text={Name}
                                        TextAlign="left"
                                    />
                                </React.Fragment>
                            );
                        })
                    }
                </Styles.HeaderLeftContainer>
            </Styles.HeaderContainer>
        );
    }
}

export default Header;