import styled from 'styled-components';

import Page from '../../Components/Page';

export const MenuButtons = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 500px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const StyledPage = styled(Page)``;