import {
    CLEAR_ENDURANCE_DETAIL,
    TRYING_GET_ENDURANCE_DETAIL,
    TRYING_GET_ENDURANCE_DETAIL_SUCCESS,
    TRYING_GET_ENDURANCE_DETAIL_ERROR,

    TRYING_GET_ENDURANCE_TRACKING_HISTORY,
    TRYING_GET_ENDURANCE_TRACKING_HISTORY_SUCCESS,
    TRYING_GET_ENDURANCE_TRACKING_HISTORY_ERROR
} from '../Types';

const INITIAL_STATE = {
    EnduranceDetail: {},

    TryingGetEnduranceDetail: false,
    TryingGetEnduranceDetailError: null,
    TryingGetEnduranceTrackingHistory: false,
    TryingGetEnduranceTrackingHistoryError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_ENDURANCE_DETAIL:
            return { ...state, EnduranceDetail: {} };
        case TRYING_GET_ENDURANCE_DETAIL:
            return { ...state, TryingGetEnduranceDetail: true, TryingGetEnduranceDetailError: null };
        case TRYING_GET_ENDURANCE_DETAIL_ERROR:
            return { ...state, TryingGetEnduranceDetail: false, TryingGetEnduranceDetailError: action.payload };
        case TRYING_GET_ENDURANCE_DETAIL_SUCCESS:
            return { ...state, EnduranceDetail: action.payload, TryingGetEnduranceDetail: false, TryingGetEnduranceDetailError: null };

        case TRYING_GET_ENDURANCE_TRACKING_HISTORY:
            return { ...state, TryingGetEnduranceTrackingHistory: true, TryingGetEnduranceTrackingHistoryError: null };
        case TRYING_GET_ENDURANCE_TRACKING_HISTORY_ERROR:
            return { ...state, TryingGetEnduranceTrackingHistory: false, TryingGetEnduranceTrackingHistoryError: action.payload };
        case TRYING_GET_ENDURANCE_TRACKING_HISTORY_SUCCESS:
            var { AddToExisting, IsMoreResults, TotalRecords, TrackingHistory } = action.payload;

            var NewTrackingHistory = { ...state.EnduranceDetail.TrackingHistory, IsMoreResults, TotalRecords };
            if (AddToExisting) NewTrackingHistory.TrackingHistory = [ ...NewTrackingHistory.TrackingHistory, ...TrackingHistory ];
            else NewTrackingHistory.TrackingHistory = [ ...TrackingHistory ];

            return { ...state, EnduranceDetail: { ...state.EnduranceDetail, TrackingHistory: NewTrackingHistory }, TryingGetEnduranceTrackingHistory: false, TryingGetEnduranceTrackingHistoryError: null };
        default:
            return state;
    }
};
