import React from 'react';
import PropTypes from 'prop-types';

import AbelModal from '../../Components/Modal';
import GetStarted from '../../Components/GetStarted';

class GetStartedMeals extends React.Component {
    renderModalBody = () => {
        var { ClientType, Height, HeightUnit, IsPreRegistered, UserId, UserUnits, Weight, WeightUnit } = this.props;

        return (
            <GetStarted
                ClientId={UserId}
                ClientType={ClientType}
                FromDashboard
                NeedsHeight={!+Height}
                NeedsWeight={!+Weight}
                ProvidedUserDetails={{ Height, HeightUnit, IsPreRegistered, UserUnits, Weight, WeightUnit }}
                RefreshWeekly={this.props.OnHide}
                Type="Meals"
            />
        );
    }

    render() {
        var { OnHide, Show } = this.props;

        return (
            <AbelModal Show={Show} Size="large" OnHide={OnHide} TopButton="cancel" TopButtonOnClick={OnHide}>
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

GetStartedMeals.propTypes = {
    ClientType: PropTypes.string.isRequired,
    Height: PropTypes.number,
    HeightUnit: PropTypes.string,
    IsPreRegistered: PropTypes.number,
    UserUnits: PropTypes.string,
    Weight: PropTypes.number,
    WeightUnit: PropTypes.string,
    OnHide: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired,
    UserId: PropTypes.number.isRequired
}

export default GetStartedMeals;