import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearClientDetails, ViewNutritionProgress } from '../../Services/Actions';
import history from '../../history';
import moment from 'moment';
import { DateRangePicker } from '@mantine/dates';

import  * as Styles from './NutritionStyles';

import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

class NutritionProgress extends React.PureComponent {
    _isMounted = false;

    state = {
        EndDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
        Loading: true,
        NutritionProgress: [],
        StartDate: moment().startOf('isoWeek').format('YYYY-MM-DD')
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientId } = this.props.match.params;

        if (!+ClientId) history.push('/');
        else this.onGetNutritionProgress();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        
        this.props.ClearClientDetails();
    }

    onChangeDate = NewDates => {
        if (NewDates[0] && NewDates[1]) {
            var EndDate = NewDates[1];
            var StartDate = NewDates[0];

            StartDate = StartDate.toISOString().slice(0, 10);
            EndDate = EndDate.toISOString().slice(0, 10);

            this.setState({ EndDate, StartDate }, () => this.onGetNutritionProgress());
        }
    }

    onGetNutritionProgress = () => {
        var { EndDate, StartDate } = this.state;
        var { ClientId } = this.props.match.params;

        this.props.ViewNutritionProgress({ EndDate, StartDate, UserId: ClientId }).then(({ NutritionProgress }) => {
            if (this.props.TryingViewNutritionProgressError) history.push('/clients');
            else this.setState({ Loading: false, NutritionProgress });
        });
    }

    renderNutrient = Nutrient => {
        var { LowerLimit, NutrientId, NutrientName, Unit, UpperLimit, Value, WithinLimits } = Nutrient;

        return (
            <Styles.NutrientRow className="NutrientRow" key={NutrientId}>
                <Styles.NutrientCell className="NutrientCell">
                    <PageText FontFamily="medium" FontSize="medium" NoMargin Text={NutrientName} />
                </Styles.NutrientCell>
                <Styles.NutrientCell className="NutrientCell">
                    <PageText FontFamily="medium" FontSize="medium" NoMargin Text={Unit} />
                </Styles.NutrientCell>
                <Styles.NutrientCell className="NutrientCell">
                    <PageText FontFamily="medium" FontSize="medium" NoMargin Text={`${Value}`} />
                </Styles.NutrientCell>
                <Styles.NutrientCell className="NutrientCell">
                    <PageText FontFamily="medium" FontSize="medium" NoMargin Text={LowerLimit ? `${LowerLimit}` : ''} />
                </Styles.NutrientCell>
                <Styles.NutrientCell className="NutrientCell">
                    <PageText FontFamily="medium" FontSize="medium" NoMargin Text={UpperLimit ? `${UpperLimit}` : ''} />
                </Styles.NutrientCell>
                <Styles.NutrientCell className="NutrientCell" WithinLimits={WithinLimits}>
                    <PageText FontFamily="medium" FontSize="medium" NoMargin Text={`${WithinLimits}`} />
                </Styles.NutrientCell>
            </Styles.NutrientRow>
        );
    }

    renderNutrients = () => {
        return (
            <Styles.NutritionProgressTableContainer className="NutritionProgressTableContainer">
                <Styles.NutrientRowHeader className="NutrientRow">
                    <Styles.NutrientCellHeader className="NutrientCellHeader">
                        <PageText FontFamily="semibold" FontSize="medium" NoMargin Text={`Nutrient Name`} />
                    </Styles.NutrientCellHeader>
                    <Styles.NutrientCellHeader className="NutrientCellHeader">
                        <PageText FontFamily="semibold" FontSize="medium" NoMargin Text={`Unit`} />
                    </Styles.NutrientCellHeader>
                    <Styles.NutrientCellHeader className="NutrientCellHeader">
                        <PageText FontFamily="semibold" FontSize="medium" NoMargin Text={'Current Value'} />
                    </Styles.NutrientCellHeader>
                    <Styles.NutrientCellHeader className="NutrientCellHeader">
                        <PageText FontFamily="semibold" FontSize="medium" NoMargin Text={'Lower Limit'} />
                    </Styles.NutrientCellHeader>
                    <Styles.NutrientCellHeader className="NutrientCellHeader">
                        <PageText FontFamily="semibold" FontSize="medium" NoMargin Text={'Upper Limit'} />
                    </Styles.NutrientCellHeader>
                    <Styles.NutrientCellHeader className="NutrientCellHeader">
                        <PageText FontFamily="semibold" FontSize="medium" NoMargin Text={'Status'} />
                    </Styles.NutrientCellHeader>
                </Styles.NutrientRowHeader>

                {
                    this.state.NutritionProgress.map(Nutrient => {
                        return this.renderNutrient(Nutrient);
                    })
                }
            </Styles.NutritionProgressTableContainer>
        );
    }

    render() {
        if (this.state.Loading) return <Loading />;

        var { t } = this.props;
        var { ClientDetails: { ClientId, UserName }, TryingViewNutritionProgress } = this.props;
        var { EndDate, StartDate } = this.state;

        return (
            <>
                {TryingViewNutritionProgress && <Loading />}

                <Page
                    renderBreadcrumbs={[
                        { Name: `@${UserName}`, LinkURL: `client/${ClientId}`, Props: { SelectedTab: 'Progress' }},
                        { Name: t('nutrition')}
                    ]}
                >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={t('nutrition')} />

                    <Spacer Size="large" />

                    <DateRangePicker
                        value={[
                            new Date(StartDate.split('-')[0], StartDate.split('-')[1] - 1, StartDate.split('-')[2]),
                            new Date(EndDate.split('-')[0], EndDate.split('-')[1] - 1, EndDate.split('-')[2])
                        ]}
                        allowSingleDateInRange
                        onChange={this.onChangeDate}
                        inputFormat="MMM DD, YYYY"
                    />

                    <Spacer Size="small" />

                    {this.renderNutrients()}
                </Page>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        ClientDetails: state.Client.ClientDetails,

        TryingViewNutritionProgress: state.Progress.TryingViewNutritionProgress,
        TryingViewNutritionProgressError: state.Progress.TryingViewNutritionProgressError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearClientDetails, ViewNutritionProgress } )(NutritionProgress));