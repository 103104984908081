import styled from 'styled-components';
import { device } from '../../Config/device';

import Popover from 'react-bootstrap/Popover';
import TimePicker from 'rc-time-picker';

import BackgroundImage from '../../Components/BackgroundImage';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Dropdown from '../../Components/Dropdown';
import Image from '../../Components/Image';
import Input from '../../Components/Form/Input';
import LoadMore from '../../Components/Lists/LoadMore';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import TextArea from '../../Components/Form/TextArea';

export const AllFilters = styled.div`
    align-items: start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
    }
`;

export const BlockedRestrictionsContainer = styled.div``;

export const ButtonsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    justify-content: center;
    margin: 0px 15px 15px;
`;

export const ChooseDishesContainer = styled.div``;

export const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ColumnTaskList = styled.div`
    transition: background-color 0.2s ease;
    background-color: ${props => (props.isDraggingOver ? 'skyblue' : 'white')}
    flex-grow: 1;
    min-height: 200px;
`;

export const ColumnTitle = styled.div`
    background-color: ${props => !!props.IsRestDay ? 'var(--orange)' : 'var(--blue-astronaut)'};
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    color: var(--white);
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-medium-2);
    padding: 8px;
    text-align: center;
`;

export const Container = styled.div`
    display: grid;
    // grid-gap: 10px;
    // grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

    // @media (min-width: 1168px) {
    //     grid-template-columns: repeat(7, minmax(100px, 140px));
    // }

    grid-gap: 40px 15px;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
`;

export const CustomizationContainer = styled.div`
    padding: 0px 15px;
`;

export const CustomizationItemContainer = styled.div`
    :not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const CustomizationItemContainerHeader = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 7px;
`;

export const CustomizationTextInput = styled(Input)`
    opacity: ${props => props.Opacity};
`;

export const CustomInstructionContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete-light);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
`;

export const CustomInstructionsContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;

    @media ${device.maxwidth.mobile} {
        grid-template-columns: 1fr;
    }
`;

export const DeleteContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const DishOptionsContainer = styled.div`
    padding: 5px;
`;

export const DishOptionsContainer2 = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
`;

export const DraggableWorkoutListItem = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    > div:first-of-type {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
`;

export const DraggingWTContainer = styled.div`
    translate(0px, 0px);
`;

export const FiltersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -15px;
    margin-left: -15px;
`;

export const JointEquipmentContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(${props => props.Type === 'Joints' ? '120px' : '200px'}, 1fr));
`;

export const JointEquipmentItem = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    > div:first-child {
        margin-right: 7px;
    }
`;

export const ReadMoreLessContainer = styled.div`
    align-items: center;
    display: flex;
    margin: 10px 0px;
    padding: 0px 15px;

    ${props => !props.HasTopPadding && 'margin-top: 0px;'}
`;

export const PseudoCheckbox = styled.div`
    ${
        props => props.Selected && `background-color: #${props.BackgroundColor};`
    }

    border: 2px solid var(--white-concrete);
    border-radius: 5px;
    height: 25px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            ${props => `background-color: #${props.BackgroundColor};`}
            cursor: pointer;
        }
    }
`;

export const ReadMoreText = styled(PageText)`
    text-decoration: underline;
    text-decoration-color: var(--black);
`;

export const RequiredHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    text-align: center;
`;

export const RestDayContainer = styled.div`
    align-items: center; 
    background-color: var(--orange);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    padding: 10px;
`;

export const RestDayImage = styled(Image)`
    // height: 20px;
    max-width: 80px;

    // margin-right: 7px;
`;

export const SelectedDishesContainer = styled.div``;


export const SelectedDishImage = styled(Image)`
    border-radius: 10px;
    height: 75px;
    max-width: 100%;
    object-fit: cover;
`;

export const SelectedDishImage2 = styled(BackgroundImage)`
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 75px;
    max-width: 100%;
    object-fit: cover;
`;

export const SelectedWorkoutTemplates = styled.div`
    display: grid;
    grid-gap: 40px 15px;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    margin: 30px 0px;
    max-width: 100%;
`;

export const StyledCalendarIcon = styled.img`
    height: 20px;
    right: 15px;
    position: absolute;
    top: 20px;
    width: 20px;

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const StyledClearFilters = styled(ButtonGroup)`
    height: 45px;
    margin-bottom: 15px;
    margin-left: 15px;
`;

export const StyledDescriptionTextArea = styled(TextArea)`
    margin: 5px auto 0;
    max-width: 100%;
    width: 430px;

    > textarea {
        background-color: var(--white-concrete);
    }
`;

export const StyledDishPercentage = styled(Input)`
    width: 75px;

    > input {
        text-align: center;
        text-indent: -10px;
    }
`;

export const StyledDishPercentageSign = styled(PageText)`
    left: 50px;
    position: absolute;
    top: 4px;
`;

export const StyledDishRemoveIcon = styled(Image)`
    height: 20px;
    width: 20px;

    margin-right: 7px;
`;

export const StyledDropdown = styled(Dropdown)`
    margin-bottom: 10px;
    width: 100%;

    @media ${device.minwidth.laptop} {
        // margin: 0 60px;
        margin-left: 60px;
        max-width: 300px;
    }
`;

export const StyledLoadMore = styled(LoadMore)`
    background-color: var(--white);
    max-height: 500px;
    overflow: auto;

    > div:first-child {
        display: grid;
        grid-gap: 40px 15px;
        // grid-template-columns: repeat(1, 2fr);

        // @media ${device.minwidth.ipad} {
        //     grid-template-columns: repeat(2, 1fr);
        // }

        // @media ${device.minwidth.laptop} {
        //     grid-template-columns: repeat(4, 1fr);
        // }

        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
        max-width: 100%;
    }
`;

export const StyledMultiText = styled(MultiText)`
    align-items: center;
    justify-content: center;
`;

export const StyledPlusSignIcon = styled(Image)`
    height: 20px;
    width: 20px;

    margin: 0px 5px 10px;
`;

export const StyledPopover = styled(Popover)`
    background-color: var(--white-concrete);
    border-radius: 10px;
    box-shadow: 1px 1px 8px #5A5A5A29;
    padding: 15px;
    max-width: 300px;
    width: 300px;
`;

export const StyledProfilePhoto = styled(Image)`
    height: 25px;
    left: 15px;
    position: absolute;
    top: 15px;
    width: 25px;

    ${props => !props.IsAbel && 'border-radius: 50%;'}
    overflow: hidden;
`;

export const StyledSearchInput = styled(Input)`
    margin-bottom: 10px;
    width: 100%;

    > input {
        border: 2px solid var(--white-concrete);
    }

    @media ${device.minwidth.ipad} {
        margin-bottom: 0px;
    }
`;

export const StyledTemplateDays = styled(Input)`
    > div {
        font-size: var(--font-size-medium-3);
        margin-bottom: 20px;
        text-align: center;
    }

    > input {
        background-color: var(--white-concrete);
        border-radius: 10px;
        font-family: var(--font-family-bold);
        text-align: center;
        text-indent: 0px;
    }
`;

export const StyledTemplateName = styled(Input)`
    margin: 0 auto;
    max-width: 100%;
    width: 430px;

    > input {
        background-color: var(--white-concrete);
        text-align: center;
        text-indent: 0px;
    }
`;

export const StyledTemplateNameText = styled(PageText)`
    text-transform: uppercase;
`;

export const StyledTimePicker = styled(TimePicker)`
    opacity: ${props => props.Opacity};
    width: 100%;

    > input {
        border-radius: 20px;
        font-family: var(--font-family-medium);
        font-size: var(--font-size-small);
    }
`;

export const StyledWorkoutNoteTextArea = styled(TextArea)`
    margin-bottom: 0px;
    opacity: ${props => props.Opacity};
    width: 100%;

    > textarea {
        border: none;
        padding: 5px;
        resize: none;
    }
`;

export const Template = styled.div`
    border: 2px solid var(--white-concrete);
    border-radius: 23px;
    padding: 15px;
`;

export const TemplateDetailContainer = styled.div`
    background-color: var(--white-concrete-light);
    border: 5px solid var(--white-concrete-light);
    border-radius: 20px;
    padding: 15px 38px;
`;

export const ToggleContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    padding: 15px 20px;

    :first-child {
        margin-bottom: 10px;
    }

    @media ${device.minwidth.ipad} {
        :first-child {
            margin-bottom: 0px;
            margin-right: 10px;
        }
    }
`;

export const ToggleInnerContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const TogglesContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
    }
`;

export const ViewAllPageText = styled(PageText)`
    color: var(--blue-abel);
    text-decoration: underline;

    :hover {
        cursor: pointer;
    }
`;

export const TemplateDaysContainer2 = styled.div`
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 375px;
`;


export const TemplateDaysInnerContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 150px;
`;

export const TemplateIcon = styled.img`
    height: 20px;
    left: 15px;
    position: absolute;
    top: 20px;
    width: 20px;
`;

export const WorkoutTemplateContainer = styled.div`
    background-color: var(--white-concrete-light);
    border-radius: 10px;
    margin: 20px 0px;
    overflow: hidden;

    &:last-child {
        margin-bottom: 0px;
    }
`;

export const WorkoutTemplateContainerHeader = styled.div``;

export const WorkoutTemplatesContainer = styled.div`
    max-height: 500px;
    overflow: auto;
`;