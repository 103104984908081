import styled from 'styled-components';

export const CalendarContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 200px;
`;

export const CalendarDayBodyContainer = styled.div``;

export const CalendarDayContainer = styled.div`
    align-items: flex-start;
    display: flex;
    grid-gap: 7px;
    flex-direction: row;
    justify-content: flex-start;
    
    ${props => (+props.TaskType === 2 || +props.TaskType === 3) && `min-height: 65px;`}
`;

export const CalendarDayStatusContainer = styled.div`
    background-color: var(--${props => props.CompletionStatus === 'Complete' ? 'green-success' : props.CompletionStatus === 'Overdue' ? 'red-bittersweet' : props.CompletionStatus === 'Pending' ? 'orange' : 'gray-silver'});
    border-radius: 20px;
    height: 100%;
    width: 5px;
`;

export const CalendarWeekContainer = styled.div`
    display: grid;
    grid-gap: 50px 10px;
    grid-template-columns: repeat(8, 125px);
`;

export const CalendarWeekColumn = styled.div``;

export const CalendarYearColumn = styled.div``;

export const CalendarYearContainer = styled.div``;

export const CalendarYearHeaderContainer = styled.div`
    display: grid;
    grid-gap: 50px 10px;
    grid-template-columns: repeat(8, 125px);
`;

export const CalendarYearWeeksContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
`;

export const CompletionStatusIcon = styled.img`
    height: 20px;
    max-width: 100%;
`;

export const EmptyIconContainer = styled.div`
    height: 20px;
`;

export const HeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;