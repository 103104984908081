import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ReadAllNotifications } from '../../Services/Actions';

import { ChildrenContainer, LinkContainer, StyledModal, StyledReadAll, TitleContainer } from './styles';

import { GA_ModalView } from '../../Components/GoogleAnalytics';
import NotificationsList from '../../Components/Notifications';
import PageText from '../../Components/Text/PageText';

class Notifications extends React.Component {
    componentDidMount() {
        GA_ModalView('Notifications');
    }

    componentWillUnmount() {
        this.props.OnHide();
    }

    onMarkAllAsRead = () => {
        var { UserId } = this.props.UserDetails;

        this.props.ReadAllNotifications({ UserId });
    }

    render() {
        var { t } = this.props;
        var { OnHide, Show, UnreadNotificationCount } = this.props;

        return (
            <StyledModal dialogClassName={`modal-size-small NotificationsModal`} show={Show} onHide={OnHide} aria-labelledby="contained-modal-title-vcenter" centered>
                <TitleContainer>
                    <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('ptadmin_navigation_notifications')} TextAlign="left" />
                    {
                        !!+UnreadNotificationCount &&
                        <>
                            <StyledReadAll
                                Buttons={[
                                    {
                                        BackgroundColor: 'white-concrete',
                                        BackgroundColorHover: 'white-concrete-hover',
                                        FontFamily: 'semibold',
                                        FontSize: 'small',
                                        OnClick: this.onMarkAllAsRead,
                                        Title: t('read_all')
                                    }
                                ]}
                                ButtonWidth="fit-content"
                                NotTouching
                            />
                        </>
                    }
                </TitleContainer>

                <ChildrenContainer>
                    <NotificationsList AutomaticallyRefreshNewNotifications IsModal OnClickNotification={OnHide} PageSize={5} />
                </ChildrenContainer>

                <LinkContainer onClick={OnHide} to={'/notifications'}>
                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${t('view_all')} ${t('ptadmin_navigation_notifications').toLowerCase()}`} TextAlign="center" />
                </LinkContainer>
            </StyledModal>
        );
    }
}

Notifications.propTypes = {
    OnHide: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
    return {
        UserDetails: state.Auth.UserDetails,
        
        UnreadNotificationCount: state.Notifications.UnreadNotificationCount,

        TryingReadAllNotifications: state.Notifications.TryingReadAllNotifications,
        TryingReadAllNotificationsError: state.Notifications.TryingReadAllNotificationsError
    };
};

export default withTranslation()(connect(mapStateToProps, { ReadAllNotifications })(Notifications));