import React from 'react';
import PropTypes from 'prop-types';

import { FlexWrapContainer, FlexWrapItem, StyledCheckmark } from './styles';

import PageText from '../../Text/PageText';

class FlexWrap extends React.Component {
    renderItems = Items => {
        if (!Items) return null;

        var { ItemId, ItemProps, ItemRenderer, OnSelectItem, SelectedItemIds } = this.props;

        if (ItemRenderer) return Items.map(Item => <ItemRenderer key={Item[ItemId]} {...ItemProps} Item={Item} OnSelectItem={Item => OnSelectItem(Item)} SelectedItemIds={SelectedItemIds} />);
        else return Items.map(Item => this.renderListItem(Item));
    }

    renderListItem = Item => {
        var { IsMultiSelect, ItemId, ItemName, OnSelectItem, SelectedItemIds } = this.props;

        var ItemId_Actual = Number(Item[ItemId]);
        var ItemName_Actual = Item[ItemName];

        var SelectedIndex = SelectedItemIds.indexOf(Number(ItemId_Actual));
        var Selected = SelectedItemIds ? SelectedIndex > -1 : false;

        return (
            <FlexWrapItem key={ItemId_Actual} IsMultiSelect={IsMultiSelect} NewLine={Item.NewLine} onClick={() => OnSelectItem(Item, SelectedIndex)} Selected={Selected}>
                {
                    IsMultiSelect ?
                    <StyledCheckmark
                        Disabled={false}
                        IsMultiSelect={IsMultiSelect}
                        ItemId={ItemId_Actual}
                        OnSelectItem={() => null}
                        SelectedIndex={SelectedIndex}
                        Title={ItemName_Actual}
                    />
                :
                    null
                }
                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={ItemName_Actual} />
            </FlexWrapItem>
        );
    }

    render() {
        return (
            <FlexWrapContainer>
                {this.renderItems(this.props.Items)}
            </FlexWrapContainer>
        );
    }
}

FlexWrap.propTypes = {
    IsMultiSelect: PropTypes.bool,
    ItemId: PropTypes.string.isRequired,
    ItemName: PropTypes.string.isRequired,
    ItemProps: PropTypes.object,
    ItemRenderer: PropTypes.func,
    Items: PropTypes.array.isRequired,
    OnSelectItem: PropTypes.func,
    SelectedItemIds: PropTypes.array.isRequired
}

export default FlexWrap;