import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ClearClientDetails, ClearStrengthProgress, ViewStrengthProgress, ViewUserWorkoutMaxes_Strength } from '../../Services/Actions';
import history from '../../history';

import  { ExerciseContainer, ExerciseImageContainer, LinkContainer, ListContainer2, ListProgressContainer, OverallPercentageContainer, ProgressStatsContainer2, ProgressStatsContainerInner2, StyledExerciseImage, StyledLineBar, StyledExerciseLoadMore, StyledSearchInput, TotalProgressCircleContainer } from './styles';

import CircleProgress from '../../Components/Charts/ProgressBars/Circle';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import { SecondsToTime } from '../../Functions';

class Strength extends React.PureComponent {
    _isMounted = false;

    state = {
        ActiveSearch: false,
        LastSearchedValue: '',
        Loading: true,
        NewSearch: false,
        PageNo: 1,
        PageSize: 48,
        SearchValue: ''
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientId } = this.props.match.params;

        if (!+ClientId) history.push('/');
        else {
            var { PageNo, PageSize } = this.state;

            this.props.ViewStrengthProgress({ ClientId, PageNo, PageSize }).then(() => {
                if (this.props.TryingViewStrengthProgressError) history.push('/clients');
                else this.setState({ Loading: false });
            });
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        
        this.props.ClearClientDetails();
        this.props.ClearStrengthProgress();
    }

    onChangeSearch = event => {
        this.setState({ SearchValue: event.target.value });
    }

    onKeyPress = ({ key }) => {
        if (key === 'Enter' && !this.props.TryingGetUserWorkoutMaxes_Strength) {
            var { SearchValue, LastSearchedValue } = this.state;

            if (SearchValue !== LastSearchedValue) {
                this.setState({ PageNo: 1, LastSearchedValue: SearchValue }, () => this.onLoadExercises());
            }
        }
    }

    onLoadExercises = Increment => {
        var { ClientId } = this.props.match.params;
        var { NewSearch, PageNo, PageSize, SearchValue } = this.state;

        if (Increment) PageNo = PageNo + 1;
        else {
            NewSearch = true;
            PageNo = 1;
        }

        this.setState({ ActiveSearch: true, NewSearch, PageNo }, () => this.props.ViewUserWorkoutMaxes_Strength({ AddToExisting: Increment, ClientId, ExerciseName: SearchValue, PageNo, PageSize }).then(() => this._isMounted && this.setState({ ActiveSearch: false, NewSearch: false })));
    }

    renderExercises = () => {
        var { t } = this.props;
        var { Exercises: { Exercises, Exercises_IsMoreResults, TotalRecords }, TryingGetUserWorkoutMaxes_Strength } = this.props;
        var { ActiveSearch, NewSearch, PageNo, PageSize, SearchValue } = this.state;

        return (
            <ListContainer2>
                <StyledSearchInput
                    FontSize="medium-2"
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeSearch}
                    OnKeyPress={this.onKeyPress}
                    Placeholder={t('search_with_first_char_uppercase_dots')}
                    Size="medium"
                    Type="text"
                    Value={SearchValue}
                />

                <Spacer Size="small" />

                <StyledExerciseLoadMore
                    ActiveSearch={ActiveSearch}
                    CompletedFirstSearch
                    IsLoading={TryingGetUserWorkoutMaxes_Strength}
                    ItemDescriptionType={t('ptadmin_exercises_plural').toLowerCase()}
                    ItemId="ExerciseId"
                    ItemName="ExerciseName"
                    ItemRenderer={this.renderExercisesList}
                    Items={Exercises}
                    HasMoreItems={!!+Exercises_IsMoreResults}
                    LoadMore={() => this.onLoadExercises(true)}
                    NewSearch={NewSearch}
                    NoItemsText={t('search_noresults')}
                    PageNo={PageNo}
                    PageSize={PageSize}
                    TotalRecords={TotalRecords}
                />
            </ListContainer2>
        );
    }

    renderExercisesList = ({ Item: { CompletionPercentage, CompletionStatus, ExerciseId, ExerciseImage, ExerciseName, ExerciseType, Progress: { Current, Goal, ProgressLine, Starting }, Units }}) => {
        var { t } = this.props;
        var { ClientId } = this.props.match.params;

        var CurrentText = `${Current}`;
        var GoalText = `${Goal}`;
        var StartingText = `${Starting}`;

        if (ExerciseType === 2) {
            CurrentText = SecondsToTime(Current);
            GoalText = SecondsToTime(Goal);
            StartingText = SecondsToTime(Starting);
        }

        return (
            <ExerciseContainer className="ExerciseContainer" Completed={CompletionStatus === 'ok'}>
                <ExerciseImageContainer NoBorder>
                    <StyledExerciseImage ImageSrc={ExerciseImage || ''} SelfLoading />
                </ExerciseImageContainer>

                <Spacer Size="small" />

                <LinkContainer to={`/client/${ClientId}/exercise/${ExerciseId}`}>
                    <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={ExerciseName} TextAlign="center" />
                </LinkContainer>

                <Spacer Size="small" />

                <ListProgressContainer className="ListProgressContainer">
                    {
                        !!+Goal &&
                        <>
                            <PageText FontFamily="medium" FontSize="small" NoMargin Text={`${CompletionPercentage}% ${t('ptadmin_contenttype_header_progress')}`} TextAlign="center" />

                            <Spacer Size="small" />
                        </>
                    }

                    {
                        !!+Starting &&
                        <>
                            <PageText FontFamily="medium" FontSize="small" NoMargin Text={Units} TextAlign="center" />

                            <Spacer Size="small" />
                        </>
                    }

                    {
                        !!+Starting ?
                        <>
                            <ProgressStatsContainer2 className="ProgressStatsContainer2">
                                <ProgressStatsContainerInner2>
                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('WorkoutPlan_Starting')} TextAlign="center" WordBreak="keep-all" />

                                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={StartingText} TextAlign="center" WordBreak="keep-all" />
                                </ProgressStatsContainerInner2>

                                <ProgressStatsContainerInner2>
                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${t('WorkoutPlan_Current')}`} TextAlign="center" WordBreak="keep-all" />

                                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={CurrentText} TextAlign="center" WordBreak="keep-all" />
                                </ProgressStatsContainerInner2>

                                <ProgressStatsContainerInner2>
                                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={`${t('Goal')}`} TextAlign="center" WordBreak="keep-all" />

                                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={GoalText} TextAlign="center" WordBreak="keep-all" />
                                </ProgressStatsContainerInner2>
                            </ProgressStatsContainer2>

                            {
                                !!+Goal &&
                                <>
                                    <Spacer Size="extra-small" />

                                    <StyledLineBar
                                        CompletionStatus={CompletionStatus}
                                        CurrentValue={Current}
                                        GoalMax={0}
                                        GoalMin={Goal}
                                        HideCurrent
                                        IsTime={+ExerciseType === 2}
                                        ProgressLine={ProgressLine}
                                        Units={Units}
                                    />
                                </>
                            }
                        </>
                    :
                        <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={t('WorkoutPlan_This_is_a_new_exercise')} TextAlign="center" />
                    }
                </ListProgressContainer>
            </ExerciseContainer>
        );
    }

    render() {
        if (this.state.Loading || this.props.TryingViewStrengthProgress) return <Loading />;

        var { t } = this.props;
        var { ClientDetails: { ClientId, UserName }, Exercises: { OverallPercentage }, TryingViewStrengthProgress } = this.props;

        return (
            <>
                {TryingViewStrengthProgress && <Loading />}

                <Page
                    renderBreadcrumbs={[
                        { Name: `@${UserName}`, LinkURL: `client/${ClientId}`, Props: { SelectedTab: 'Progress' }},
                        { Name: t('Strength')}
                    ]}
                >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={t('Strength')} />

                    <Spacer Size="large" />
                    
                    <OverallPercentageContainer>
                        <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('total_progress')} TextAlign="center" />

                        <Spacer Size="small" />

                        <TotalProgressCircleContainer>
                            <CircleProgress Percentage={OverallPercentage} />
                        </TotalProgressCircleContainer>
                    </OverallPercentageContainer>

                    <Spacer Size="large" />

                    <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('ptadmin_exercises_plural')} TextAlign="center" />

                    <Spacer Size="small" />

                    {this.renderExercises()}
                </Page>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,
        ClientDetails: state.Client.ClientDetails,
        
        Exercises: state.Progress.Exercises,

        TryingGetUserWorkoutMaxes_Strength: state.Progress.TryingGetUserWorkoutMaxes_Strength,
        TryingGetUserWorkoutMaxes_StrengthError: state.Progress.TryingGetUserWorkoutMaxes_StrengthError,
        TryingViewStrengthProgress: state.Progress.TryingViewStrengthProgress,
        TryingViewStrengthProgressError: state.Progress.TryingViewStrengthProgressError
    };
};

export default withTranslation()(connect(mapStateToProps, { ClearClientDetails, ClearStrengthProgress, ViewStrengthProgress, ViewUserWorkoutMaxes_Strength } )(Strength));