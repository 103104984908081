import styled from 'styled-components';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Icon from '../../Components/Icon';
import Image from '../../Components/Image';
import Input from '../../Components/Form/Input';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import TextArea from '../../Components/Form/TextArea';

import Checkmark from '../../Assets/Icons/Checkmark_Selected.png';

export const DateTimeUseAllDays = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 13px
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    // border-radius: 50%;
    display: block;
    height: 13px
    margin-right: 5px;
    min-width: 13px
    width: 13px

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 13px
            border: none;
            cursor: pointer;
        }
    }

    :nth-child(2) {
        max-width: 600px;
    }
`;

export const DateTimeUseAllDaysContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
`;

export const DynamicTemplateWarning = styled(PageText)`
    max-width: 50%;
`;

export const EmailBodyTextArea = styled(TextArea)`
    margin-bottom: 0px;
    margin-top: 0px;
    max-width: 100%;
    width: 400px;

    > textarea {
        background-color: var(--white-concrete);
        border: none;
    }
`;

export const EmailContainer = styled.div`
    margin-right: 15px;
    margin-top: 15px;
    max-width: 100%;
`;

export const EmailFormInput = styled(Input)`
    flex: 1;
    max-width: 100%;

    > input {
        background-color: transparent;
        border: none;
        border-radius: 10px;
        text-align: left;
        text-indent: 0px;
    }
`;

export const EmailInputContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 100%;
    padding: 3px;
    width: 400px;

    ${props => (props.EmailAddress && !props.IsValid) && 'border: 1px solid var(--red-bittersweet);'}
`;

export const EmailsListParentContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: -15px;
    margin-top: -15px;
`;

export const ExerciseContainer = styled.div`
    align-items: flex-start;
    background-color: var(--white-concrete);
    border-radius 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
`;

export const ExerciseHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const ExerciseImage = styled(Image)`
    border-radius: 10px;
    height: 85px;
    object-fit: cover;
    width: 110px;
`;

export const ExerciseName = styled(PageText)`
    margin-left: 15px;
    text-transform: uppercase;
`;

export const ExerciseNoteTextArea = styled(TextArea)`
    margin-bottom: 0px;
    margin-top: 0px;
    max-width: 100%;
    width: 100%;

    > textarea {
        background-color: var(--white);
        border: none;
    }
`;

export const ExercisesContainer = styled.div`
    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(auto-fit,minmax(400px, 1fr));

    @media (max-width: 687px) {
        grid-template-columns: 1fr;
    }
`;

export const ExportPDFPreviewContainer = styled.div``;

export const FormContainer = styled.div`
    align-items: flex-start;
    display: grid;
    grid-gap: 15px 25px;

    @media (min-width: 1130px) {
        grid-template-areas: "first middle last";
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: 688px) and (max-width: 1129px) {
        grid-template-areas: "first middle" "last last";
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 687px) {
        grid-template-areas: "first" "middle" "last";
        grid-template-columns: 1fr;
    }
`;

export const FormContainerFirst = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    grid-area: first;
    justify-content: center;
`;

export const FormContainerLast = styled.div`
    grid-area: last;

    @media (min-width: 1130px) {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @media (min-width: 688px) and (max-width: 1129px) {
        display: grid;
        grid-gap: 15px 25px;
        grid-template-columns: 1fr 1fr;
    }
`;

export const FormContainerMiddle = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;
    grid-area: middle;
    justify-content: center;
`;

export const FormInput = styled(Input)`
    margin-top: 10px;
    max-width: 100%;

    > input {
        background-color: var(--white-concrete);
        border: none;
        border-radius: 10px;
        text-align: left;
        text-indent: 0px;
    }
`;

export const FormInputContainer = styled.div`
    margin-bottom: 45px;

    @media (max-width: 687px) {
        margin-bottom: 0px;
    }
`;

export const FormTextArea = styled(TextArea)`
    margin-top: 10px;
    max-width: 100%;

    > textarea {
        background-color: var(--white-concrete);
        border: none;
    }
`;

export const MaxPercentInfoIcon = styled(Icon)`
    height: 20px;
    width: 20px;
`;

export const MultipleChoiceContainer = styled.div`
    margin-bottom: 45px;

    @media (min-width: 688px) and (max-width: 1129px) {
        margin-bottom: 0px;
    }
`;

export const NumEmptySpacesContainer = styled.div``;

export const NumEmptySpacesTitleContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div:first-child {
        flex: 1;
    }
`;

export const MinusContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 10px;

    @media(hover:hover) {
        :hover {
            ${
                props => !props.disabled ?
                `
                    background-color: var(--white-concrete-hover);
                    cursor: pointer;
                `
            :
                `
                    cursor: not-allowed;
                `
            }
        }
    }
`;

export const PlusContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 10px;

    @media(hover:hover) {
        :hover {
            ${
                props => !props.disabled ?
                `
                    background-color: var(--white-concrete-hover);
                    cursor: pointer;
                `
            :
                `
                    cursor: not-allowed;
                `
            }
        }
    }
`;

export const PlusMinusInputContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 120px;
    overflow: hidden;
`;

export const PlusMinusNumberContainer = styled.div`
    align-items: center;
    background-color: var(--white);
    display: flex;
    height: 40px;
    justify-content: center;
    width: 100%;
`;

export const PopoverContent = styled.div`
    align-items: center;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 200px;
    padding: 10px;
`;

export const SaveLogoCheckmark = styled.span`
    ${props => props.Selected ?
    `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
        `
    :
    `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
  };
    border-radius: 50%;
    display: block;
    height: 25px;
    margin-right: 5px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
            border: none;
            cursor: pointer;
        }
    }

    :nth-child(2) {
        max-width: 600px;
    }
`;

export const SaveLogoContainer = styled.div`
    align-items: center;
    display: flex;
    margin-top: 10px;
`;

export const StyledAddEmailButtonGroup = styled(ButtonGroup)`
    height: 51px;
    margin-right: 15px;
    margin-top: 15px;
`;

export const StyledHR = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    height: 2px;
    margin: 20px 0px;
    width: 100%;
`;

export const StyledImageContainer = styled.div`
    width: 50%;
    display: inline-flex;
    justify-content: space-between;
    margin: 10px 0;
`;

export const StyledLogoButtons = styled(ButtonGroup)`
    display: flex;
    flex: 2;
    justify-content: space-between;

    ${props => !!props.NeedsMargin && 'margin-left: 10px;'}
`;

export const StyledLogoImage = styled(Image)`
    background-color: var(--white-concrete);
    border-radius: 10px;
    height: 88px;
    object-fit: contain;
    width: 120px;
`;

export const StyledMultiText = styled(MultiText)`
    align-items: center;
    justify-content: center;
    font-style: italic;
`;

export const UploadButtonsContainer = styled(ButtonGroup)`
    justify-content: space-between;
`;

export const UploadButtonsRow = styled.div`
    margin: 10px 0;
`;