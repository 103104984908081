import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SaveDataInProps } from '../../Services/Actions';
import history from '../../history';

import { Container, MenuItem } from './styles';

import AbelModal from '../../Components/Modal';
import PageText from '../../Components/Text/PageText';

class Menu extends React.Component {
    onClickLink = (Link, SaveDataInProps, SaveData) => {
        if (SaveDataInProps) this.props.SaveDataInProps(SaveData).then(() => history.push(`/${Link}`));
        else history.push(`/${Link}`);
    }

    renderModalBody = () => {
        var { MenuItems } = this.props;

        return (
            <Container>
                {
                    MenuItems.map(({ Link, Name, OnClick, SaveData, SaveDataInProps }, MenuIndex) => {
                        return (
                            <MenuItem key={Link || MenuIndex} onClick={OnClick ? OnClick : () => this.onClickLink(Link, SaveDataInProps, SaveData)}>
                                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={Name} TextAlign="center" />
                            </MenuItem>
                        );
                    })
                }
            </Container>
        );
    }

    render() {
        var { OnHide, Show } = this.props;

        return (
            <AbelModal
                NoButtons
                NoMargin
                Show={Show}
                Size="small"
                OnHide={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

Menu.propTypes = {
    MenuItems: PropTypes.array.isRequired,
    OnHide: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired
}

export default connect(null, { SaveDataInProps } )(Menu);