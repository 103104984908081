import styled from 'styled-components';

import Checkmark from '../../Form/Checkmark';

export const FlexWrapContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -15px;
`;

export const FlexWrapItem = styled.div`
    align-items: center;
    background-color: ${props => props.Selected && !props.IsMultiSelect ? 'var(--blue-abel)' : 'var(--white-concrete)'};
    border: 2px solid ${props => props.Selected ? 'var(--blue-abel)' : 'var(--white-concrete)'};
    border-radius: 10px;
    color: ${props => props.Selected && props.IsMultiSelect ? 'var(--blue-abel)' : props.Selected && !props.IsMultiSelect ? 'var(--white)' : 'var(--black)'};
    display: flex;
    justify-content: center;
    margin-top: 15px;
    padding: 10px 15px;

    :not(:last-child) {
        margin-right: 10px;
    }

    @media(hover:hover) {
        :hover {
            border: 2px solid var(--blue-abel);
            color: ${props => props.Selected && props.IsMultiSelect ? 'var(--blue-abel)' : props.Selected && !props.IsMultiSelect ? 'var(--white)' : 'var(--black)'};
            cursor: pointer;
        }
    }
`;

export const ItemsContainer = styled.div``;

export const StyledCheckmark = styled(Checkmark)`
    margin-right: 5px;
`;