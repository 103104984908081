import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container, DeleteIcon, EditIcon, Flex1, HeaderContainer, Icon } from './styles';

import PageText from '../Text/PageText';
import Spacer from '../Spacer';

import DownArrow from '../../Assets/Icons/DownArrow.png';
import EditDark from '../../Assets/Icons/Edit_Dark.png';
import EditLight from '../../Assets/Icons/Edit_Light.png';
import UpArrow from '../../Assets/Icons/UpArrow.png';

class Accordion extends React.PureComponent {
    state = {
        HoveringEdit: false,
        ToggleOpen: this.props.ToggleOpen || false
    };

    onHoverEdit = HoveringEdit => {
        this.setState({ HoveringEdit });
    }

    onToggle = () => {
        this.setState({ ToggleOpen: !this.state.ToggleOpen });
    }

    renderHeaderContent = () => {
        var { HeaderData, HeaderRenderer, LeftItem, RightItem, Text } = this.props;

        if (HeaderRenderer) return <HeaderRenderer HeaderData={HeaderData} OnToggle={this.onToggle} />

        return (
            <>
                {
                    LeftItem || RightItem ?
                    <Flex1 MarginLeft={LeftItem || RightItem ? 1 : 0} />
                :
                    null
                }
                <PageText
                    OnClick={this.onToggle}
                    FontFamily="semibold"
                    FontSize="medium-1"
                    NoMargin
                    Text={Text || 'ABEL'}
                />
                {
                    LeftItem || RightItem ?
                    <Flex1 MarginRight={LeftItem || RightItem ? 1 : 0}>
                        {RightItem}
                    </Flex1>
                :
                    null
                }
            </>
        );
    }

    renderShowDelete = () => {
        return (
            <DeleteIcon onClick={this.props.OnDelete}>X</DeleteIcon>
        );
    }

    renderShowEdit = () => {
        var { Device, ShowDelete } = this.props;
        var { HoveringEdit } = this.state;

        var onMouseEnter = (Device === 'laptop' || Device === 'ipad') ?  () => this.onHoverEdit(true) : () => null;
        var onMouseLeave = (Device === 'laptop' || Device === 'ipad') ?  () => this.onHoverEdit(false) : () => null;
        var src = ((Device === 'laptop' || Device === 'ipad') && !HoveringEdit) ? EditLight : EditDark;

        return (
            <EditIcon
                onClick={this.props.OnEdit}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                ShowDelete={ShowDelete}
                src={src}
            />
        );
    }

    renderToggle = () => {
        return (
            <Icon onClick={this.onToggle}>
                <img src={this.state.ToggleOpen ? UpArrow : DownArrow} alt="Open and close the accordion" />
            </Icon>
        );
    }

    render() {
        var { className, BackgroundColor, BorderColor, HeaderRenderer, HideToggle, ShowDelete, ShowEdit } = this.props;

        return (
            <Container className={className} BackgroundColor={BackgroundColor} BorderColor={BorderColor}>
                <HeaderContainer HasHeader={!!HeaderRenderer}>
                    {this.renderHeaderContent()}

                    {!!ShowEdit && this.renderShowEdit()}

                    {!!ShowDelete && this.renderShowDelete()}

                    {!!!HideToggle && this.renderToggle()}
                </HeaderContainer>

                {
                    this.state.ToggleOpen &&
                    <>
                        <Spacer Size="small" />
                        {this.props.children}
                    </>
                }
            </Container>
        );
    }
}

Accordion.propTypes = {
    BackgroundColor: PropTypes.string,
    BorderColor: PropTypes.string,
    HeaderData: PropTypes.object,
    HeaderRenderer: PropTypes.func,
    HideToggle: PropTypes.bool,
    LeftItem: PropTypes.element,
    OnDelete: PropTypes.func,
    OnEdit: PropTypes.func,
    RightItem: PropTypes.element,
    ShowDelete: PropTypes.bool,
    ShowEdit: PropTypes.bool,
    Text: PropTypes.string,
    ToggleOpen: PropTypes.bool
};

Accordion.defaultProps = {
    OnDelete: () => null,
    OnEdit: () => null,
    ShowDelete: false,
    ShowEdit: false
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device
    };
};

export default connect(mapStateToProps, null )(Accordion);