import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddExistingWorkoutTemplate, ClearWorkoutTemplates, ClearSaveData, SaveDataInProps, UpdateSelectedTab } from '../../Services/Actions';
import moment from 'moment';
import history from '../../history';

import { Container, EditIcon, SelectedDateContainer, SelectedDatesContainer, SelectedTemplateContainer, TrainerNoteContainer, TrainerNoteTextArea } from './styles';

import AssignClients from '../../Components/AssignClients';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import { WorkoutTemplateListItem } from '../../Components/Lists/ListItems';

import WorkoutTemplates from '../../Pages/Library2/Workout/WorkoutTemplates';

import ChooseDates from '../../Modals/ChooseDates';
import WorkoutTemplate from '../../Modals/WorkoutTemplate';

import Edit_Dark from '../../Assets/Icons/Edit_Dark.png';
import Plus from '../../Assets/Icons/Plus.png';

class AddWorkoutTemplate extends React.Component {
    _isMounted = false;

    state = {
        SelectedDates: [],
        SelectedGroups: {},
        SelectedTemplate: {},
        SelectedTemplateId: null,
        ShowChooseDatesModal: false,
        ShowTemplateDetailModal: false,
        TemplateDetail_Preview: {},
        TrainerNote: ''
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientDetails, DayDate, GroupDetails, SelectedTemplate } = this.props;

        var SelectedDates = DayDate ? [ DayDate ] : [];
        var SelectedGroups = {};
        var SelectedUserIds = [];
        var SelectedUsers = [];
        var SelectedTemplate_State = { ...SelectedTemplate };
        var SelectedTemplateId = Number(SelectedTemplate.TemplateId);

        if (Object.entries(ClientDetails).length !== 0) {
            SelectedUserIds = [ Number(ClientDetails.ClientId) ];
            SelectedUsers = [{ ...ClientDetails, UserId: Number(ClientDetails.ClientId) }];
        }

        if (Object.entries(GroupDetails).length !== 0) {
            var { GroupId, GroupImage, GroupName, GroupType } = GroupDetails;
            SelectedGroups[`${GroupType}_GroupId-${GroupId}`] = { AllMembers: true, GroupImage, GroupName, GroupType, Members: [], RemoveMembers: [] };
        }

        this.setState({ SelectedDates, SelectedGroups, SelectedTemplate: SelectedTemplate_State, SelectedTemplateId, SelectedUserIds, SelectedUsers });
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearSaveData();
        this.props.ClearWorkoutTemplates();
    }

    onAddWorkoutTemplate = () => {
        var { SelectedDates, SelectedGroups, SelectedUserIds, SelectedTemplate: { Units }, SelectedTemplateId, TrainerNote } = this.state;

        var AssignedDates = SelectedDates.join(',');
        var ClientIds = SelectedUserIds.join(',');

        this.props.AddExistingWorkoutTemplate({ AssignedDates, ClientIds, Groups: SelectedGroups, NeedsBaseTrainerNote: 1, TemplateId: SelectedTemplateId, TrainerNote, Units }).then(() => {
            if (this._isMounted && !this.props.TryingAddExistingWorkoutTemplateError) {
                if (this.props.location.state && this.props.location.state.from) history.goBack();
                else history.push({ pathname: '/library-workouts', search: '?tab=workout-templates' });
            }
        });
    }

    onChangeTrainerNote = event => {
        this.setState({ TrainerNote: event.target.value });
    }

    onSelectClients = ({ SelectedClients: SelectedUsers, SelectedGroups }) => {
        var SelectedUserIds_New = SelectedUsers.map(User => User.UserId);
        SelectedUsers = SelectedUsers.map(User => ({ ...User, Sex: User.Gender }));

        this.setState({ SelectedGroups, SelectedUsers, SelectedUserIds: SelectedUserIds_New });
    }

    onSelectDates = SelectedDates => {
        this.setState({ SelectedDates, ShowChooseDatesModal: false });
    }

    onSelectTemplate = SelectedTemplate => {
        var { TemplateId } = SelectedTemplate;

        if (SelectedTemplate.hasOwnProperty('TemplateDetails')) SelectedTemplate = SelectedTemplate.TemplateDetails;

        this.setState({ SelectedTemplate, SelectedTemplateId: TemplateId });
    }

    onToggleShowChooseDatesModal = ShowChooseDatesModal => {
        this.setState({ ShowChooseDatesModal });
    }

    onToggleShowTemplateDetailModal = (ShowTemplateDetailModal, TemplateDetail_Preview, TemplateId) => {
        var { SelectedTemplate, SelectedTemplateId } = this.state;

        if (TemplateId && Number(TemplateId) === Number(SelectedTemplateId)) { SelectedTemplate = {}; SelectedTemplateId = null; }
        
        this.setState({ SelectedTemplate, SelectedTemplateId, ShowTemplateDetailModal, TemplateDetail_Preview });
    }

    renderAddTrainerNote = () => {
        var { t } = this.props;
        var { TrainerNote } = this.state;

        return (
            <TrainerNoteContainer>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('WorkoutPlan_Trainer_note_to_client')} TextAlign="center" />

                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('note_additional_to_template_note')} TextAlign="center" />

                <Spacer Size="small" />

                <TrainerNoteTextArea
                    OnBlur={this.onChangeTrainerNote}
                    NumRows={4}
                    Placeholder={t('WorkoutPlan_Trainer_note_to_client_placeholder')}
                    Value={TrainerNote}
                />
            </TrainerNoteContainer>
        );
    }

    renderDates = () => {
        var { t } = this.props;
        var { SelectedDates } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('choose_date')} />
                
                <Spacer Size="small" />

                {
                    SelectedDates.length ?
                    this.renderSelectedDates()
                :
                    <ButtonGroup
                        Buttons={[
                            {
                                FontFamily: 'semibold',
                                Icon: Plus,
                                IconPosition: 'left',
                                OnClick: () => this.onToggleShowChooseDatesModal(true),
                                Title: t('add_date')
                            }
                        ]}
                        ButtonWidth="fit-content"
                        NotTouching
                    />
                }
            </>
        );
    }

    renderSelectedDates = () => {
        var { SelectedDates } = this.state;

        return (
            <SelectedDatesContainer>
                {
                    SelectedDates.map(SelectedDate => 
                        <SelectedDateContainer key={SelectedDate}>
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={moment(SelectedDate).format('DD.MMM')} />
                        </SelectedDateContainer>
                    )
                }

                <EditIcon
                    ImageSrc={Edit_Dark}
                    Loading={false}
                    OnClick={() => this.onToggleShowChooseDatesModal(true)}
                />
            </SelectedDatesContainer>
        );
    }

    renderSelectedPreview = () => {
        if (!this.state.SelectedTemplateId) return null;
        
        var { t } = this.props;
        var { Device } = this.props;
        var { SelectedTemplateId } = this.state;

        return (
            <>
                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'large' : 'medium'} />

                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('WorkoutPlanSettings_selected')} TextAlign="center" />
                    
                <Spacer Size="small" />

                <SelectedTemplateContainer className="SelectedTemplateContainer">
                    <WorkoutTemplateListItem
                        HideSelect
                        Item={{ ...this.state.SelectedTemplate, TemplateId: SelectedTemplateId }}
                        SelectTemplate={() => null}
                        SelectedItemIds={[]}
                        ViewTemplate={() => this.onToggleShowTemplateDetailModal(true, { TemplateId: SelectedTemplateId, ...this.state.SelectedTemplate })}
                    />
                </SelectedTemplateContainer>
            </>
        );
    }

    renderShowChooseDatesModal = () => {
        var { ShowChooseDatesModal } = this.state;

        if (ShowChooseDatesModal) {
            return (
                <ChooseDates
                    OnHide={() => this.onToggleShowChooseDatesModal(false)}
                    OnSelectDates={SelectedDates => this.onSelectDates(SelectedDates)}
                    SelectedDates={this.state.SelectedDates}
                    Show={ShowChooseDatesModal}
                />
            );
        }
    }

    renderTemplateDetailModal = () => {
        var { ShowTemplateDetailModal, TemplateDetail_Preview } = this.state;

        if (ShowTemplateDetailModal) {
            return (
                <WorkoutTemplate
                    OnAssignClients={() => this.onSelectTemplate(TemplateDetail_Preview)}
                    OnHide={TemplateId => this.onToggleShowTemplateDetailModal(false, null, TemplateId)}
                    OnLoadTemplates={() => null}
                    TemplateDetail_Preview={TemplateDetail_Preview}
                    Show={ShowTemplateDetailModal}
                />
            );
        }
    }

    renderWorkoutTemplates = () => {
        var { SelectedTemplateId } = this.state;

        var SelectedItemIds = SelectedTemplateId ? [ Number(SelectedTemplateId) ] : [];

        return (
            <Container>
                <WorkoutTemplates
                    IsModal
                    OnSelectTemplate={this.onSelectTemplate}
                    OnShowTemplateDetail={Template => this.onToggleShowTemplateDetailModal(true, Template)}
                    FromAddWorkouts
                    UseNativeTemplateDetail
                    SelectedItemIds={SelectedItemIds}
                    WorkoutTypes={[]}
                />
            </Container>
        );
    }

    render() {
        var { t } = this.props;
        var { Device, TryingAddExistingWorkoutTemplate } = this.props;
        var { SelectedDates, SelectedGroups, SelectedTemplateId, SelectedUserIds, SelectedUsers } = this.state;

        var Breadcrumbs = [{ Name: t('add_workout_template') }];
        var SubmitButtonProps = {
            Disabled: !SelectedDates.length || !SelectedTemplateId || (!Object.entries(SelectedGroups).length && !SelectedUserIds.length),
            OnClick: this.onAddWorkoutTemplate,
            Title: t('add_workout_template'),
        }

        return (
            <>
                {TryingAddExistingWorkoutTemplate ? <Loading /> : null}

                <Page
                    renderBreadcrumbs={Breadcrumbs}
                    renderSubmitButton={SubmitButtonProps}
                >
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text={t('add_workout_template')} TextAlign="center" />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    <AssignClients
                        EligibleTypes="Both"
                        GetStartedEligible="Workouts"
                        HorizontalScroll={false}
                        OnSelectClients={Users => this.onSelectClients(Users)}
                        SelectedGroups={SelectedGroups}
                        SelectedUserIds={SelectedUserIds}
                        SelectedUsers={SelectedUsers}
                    />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderDates()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderWorkoutTemplates()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderAddTrainerNote()}

                    {this.renderSelectedPreview()}
                </Page>

                {this.renderShowChooseDatesModal()}
                {this.renderTemplateDetailModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        ClientDetails: state.SaveData.ClientDetails,
        DayDate: state.SaveData.DayDate,
        GroupDetails: state.SaveData.GroupDetails,
        SelectedTemplate: state.SaveData.SelectedTemplate,

        IsMoreWorkoutTemplates: state.Templates.IsMoreWorkoutTemplates,
        WorkoutTemplateList: state.Templates.WorkoutTemplateList,
        WorkoutTemplateList_TotalRecords: state.Templates.WorkoutTemplateList_TotalRecords,

        TryingAddExistingWorkoutTemplate: state.Templates.TryingAddExistingWorkoutTemplate,
        TryingAddExistingWorkoutTemplateError: state.Templates.TryingAddExistingWorkoutTemplateError,
        TryingSearchWorkoutTemplates: state.Templates.TryingSearchWorkoutTemplates,
        TryingSearchWorkoutTemplatesError: state.Templates.TryingSearchWorkoutTemplatesError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddExistingWorkoutTemplate, ClearWorkoutTemplates, ClearSaveData, SaveDataInProps, UpdateSelectedTab } )(AddWorkoutTemplate));