import styled from 'styled-components';

import InputLabelSideBySide from '../Form/InputLabelSideBySide';
import PageText from '../Text/PageText';

export const DataContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Completed ? 'var(--blue-astronaut)' : 'var(--white)'};
    color: ${props => props.Completed ? 'var(--white)' : 'var(--black)'};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;

    @media (max-width: 400px) {
        flex: 1;
        flex-direction: column;
        justify-content: center;
    }

    @media (min-width: 901px) {
        flex: 1;
        flex-direction: column;
        justify-content: center;
    }
`;

export const DataParentContainer = styled.div`
    flex: 1;
    width: 100%;

    @media (max-width: 900px) {
        :not(:last-child) {
            border-bottom: 2px solid var(--white-concrete);
        }
    }

    @media (min-width: 901px) {
        :not(:last-child) {
            border-right: 2px solid var(--white-concrete);
        }
    }
`;

export const LapDetailContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 901px) {
        flex-direction: row;
    }
`;

export const StyledHeaders = styled(PageText)`
    text-transform: uppercase;

    @media (max-width: 400px) {
        margin-bottom: 10px;
    }

    @media (min-width: 901px) {
        margin-bottom: 10px;
    }
`;

export const StyledInputLabelSideBySide = styled(InputLabelSideBySide)`
    & input {
        border-radius: 10px;
    }
`;