import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddCompanyEmployees, DeleteCompanyEmployees, GetCompanies, GetCompany, InsertEditCompany } from '../../Services/Actions';
import Select from 'react-select';

import * as Styles from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';
import Toggle from '../../Components/Form/Toggle';

import { emailValidator } from '../../Functions';

import Info from '../../Modals/Info';

import Plus from '../../Assets/Icons/PlusSign_White.png';
import RedX from '../../Assets/Icons/X_Red.png';

const DropdownStyles = {
    control: (styles, { isDisabled }) => ({ ...styles, backgroundColor: 'var(--white-concrete)', border: 'none', cursor: isDisabled ? 'not-allowed' : 'default', height: '45px', maxWidth: '100%', width: '200px' }),
    input: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    menu: styles => ({ ...styles, maxWidth: '100%', width: '200px' }),
    option: (styles, { isDisabled }) => ({ ...styles, cursor: isDisabled ? 'not-allowed' : 'default', maxWidth: '100%', width: '200px' })
};

class CompaniesComponent extends React.Component {
    _isMounted = false;

    state = {
        Companies: [],
        InsertEditCompany: {},
        ShowAddEmployeesModal: false,
        ShowAddEmployeesModal_CompanyId: null,
        ShowAddEmployeesModal_CompanyName: '',
        ShowAddEmployeesModal_Emails: [],
        ShowAddEmployeesModal_TrainerEmail: '',
        ShowInsertEditCompanyModal: false,
        SearchCompanyName: ''
    }

    componentDidMount() {
        this._isMounted = true;

        this.onGetCompanies();
    }

    onAddCompanyEmployees = () => {
        var { ShowAddEmployeesModal_CompanyId: CompanyId, ShowAddEmployeesModal_Emails: Emails, ShowAddEmployeesModal_TrainerEmail: TrainerEmail } = this.state;

        Emails = Emails.filter(({ Valid }) => !!Valid).map(({ Email }) => Email).join(';');

        if (Emails.length) {
            this.props.AddCompanyEmployees({ CompanyId, Emails, TrainerEmail }).then(({ Company }) => {
                if (Company) {
                    this.onToggleShowAddEmployeesModal({ ShowAddEmployeesModal: false });
                    if (+CompanyId === +this.state.InsertEditCompany.CompanyId) this.setState({ InsertEditCompany: Company });
                }
            })
        }
    }

    onAddEmail = () => {
        this.setState({ ShowAddEmployeesModal_Emails: [ ...this.state.ShowAddEmployeesModal_Emails, { Email: '', Valid: false } ] });
    }

    onBlurEmail = EmailIndex => {
        var ShowAddEmployeesModal_Emails = [ ...this.state.ShowAddEmployeesModal_Emails ];
        ShowAddEmployeesModal_Emails[EmailIndex].Valid = !!emailValidator(ShowAddEmployeesModal_Emails[EmailIndex].Email);

        this.setState({ ShowAddEmployeesModal_Emails });
    }

    onChangeAddEmployees_Company = ({ CompanyId, CompanyName }) => {
        this.setState({ ShowAddEmployeesModal_CompanyId: CompanyId, ShowAddEmployeesModal_CompanyName: CompanyName });
    }

    onChangeCompanyName = event => {
        var InsertEditCompany = { ...this.state.InsertEditCompany };
        InsertEditCompany.CompanyName = event.target.value;

        this.setState({ InsertEditCompany });
    }

    onChangeEmail = ({ event, EmailIndex }) => {
        var ShowAddEmployeesModal_Emails = [ ...this.state.ShowAddEmployeesModal_Emails ];
        ShowAddEmployeesModal_Emails[EmailIndex] = { Email: event.target.value, Valid: true };

        this.setState({ ShowAddEmployeesModal_Emails });
    }

    onChangeSearchCompanyName = event => {
        this.setState({ SearchCompanyName: event.target.value });
    }

    onChangeTrainerEmail = event => {
        this.setState({ ShowAddEmployeesModal_TrainerEmail: event.target.value });
    }

    onDeleteEmail = EmailIndex => {
        this.setState({ ShowAddEmployeesModal_Emails: [ ...this.state.ShowAddEmployeesModal_Emails.slice(0, EmailIndex), ...this.state.ShowAddEmployeesModal_Emails.slice(EmailIndex + 1) ] });
    }

    onGetCompanies = () => {
        var CompanyName = this.state.SearchCompanyName;

        this.props.GetCompanies({ CompanyName }).then(({ Companies }) => {
            this.setState({ Companies });
        });
    }

    onInsertEditCompany = () => {
        var { CompanyId, CompanyName, HasWhitelabeling } = this.state.InsertEditCompany;

        this.props.InsertEditCompany({ CompanyId, CompanyName, HasWhitelabeling }).then(() => {
            this.onToggleShowInsertEditCompanyModal({ ShowInsertEditCompanyModal: false });
            this.onGetCompanies();
        });
    }

    onRemoveCompanyEmployee = ({ CompanyId, UserId }) => {
        this.props.DeleteCompanyEmployees({ CompanyId, UserIds: `${UserId}`}).then(({ Company }) => {
            this.setState({ InsertEditCompany: Company });
        });
    }

    onToggleHasWhitelabeling = event => {
        var InsertEditCompany = { ...this.state.InsertEditCompany };
        InsertEditCompany.HasWhitelabeling = event.target.checked;

        this.setState({ InsertEditCompany });
    }

    onToggleShowAddEmployeesModal = ({ ShowAddEmployeesModal, ShowAddEmployeesModal_CompanyId = null, ShowAddEmployeesModal_CompanyName = '' }) => {
        this.setState({ ShowAddEmployeesModal, ShowAddEmployeesModal_CompanyId, ShowAddEmployeesModal_CompanyName, ShowAddEmployeesModal_Emails: [], ShowAddEmployeesModal_TrainerEmail: '' });
    }

    onToggleShowInsertEditCompanyModal = ({ InsertEditCompany = { CompanyId: null, CompanyName: '', Employees: [], HasWhitelabeling: false }, ShowInsertEditCompanyModal }) => {
        var LoadCompanyDetails = false;
        // Editing a company, so get company details
            if (!!+InsertEditCompany.CompanyId) LoadCompanyDetails = true;

        this.setState({ InsertEditCompany, ShowInsertEditCompanyModal }, () => {
            if (LoadCompanyDetails) this.props.GetCompany({ CompanyId: InsertEditCompany.CompanyId }).then(({ Company }) => {
                this.setState({ InsertEditCompany: Company });
            })
        });
    }

    renderAddEmployeesModal = () => {
        var { t } = this.props;
        var { TryingAddCompanyEmployees } = this.props;
        var { ShowAddEmployeesModal, ShowAddEmployeesModal_TrainerEmail } = this.state;

        if (ShowAddEmployeesModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onAddCompanyEmployees}
                    BottomButtonDisabled={!ShowAddEmployeesModal_TrainerEmail}
                    BottomButtonText={t('_save')}
                    InformationRenderer={this.renderAddEmployeesModal_Information}
                    Loading={(TryingAddCompanyEmployees)}
                    OnHide={() => this.onToggleShowAddEmployeesModal({ ShowAddEmployeesModal: false })}
                    Show={ShowAddEmployeesModal}
                />
            );
        }
    }

    renderAddEmployeesModal_Information = () => {
        var { t } = this.props;
        var { Companies, ShowAddEmployeesModal_CompanyId, ShowAddEmployeesModal_CompanyName, ShowAddEmployeesModal_Emails, ShowAddEmployeesModal_TrainerEmail } = this.state;

        return (
            <Styles.InsertEditCompanyModalContainer className="InsertEditCompanyModalContainer">
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text="Add Employees" TextAlign="center" />

                <Spacer Size="medium" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text="Choose company" TextAlign="center" />

                <Select
                    getOptionLabel={option => option.CompanyName}
                    getOptionValue={option => option.CompanyId}
                    onChange={this.onChangeAddEmployees_Company}
                    options={Companies}
                    placeholder="Companies"
                    styles={DropdownStyles}
                    value={ShowAddEmployeesModal_CompanyId && { CompanyId: ShowAddEmployeesModal_CompanyId, CompanyName: ShowAddEmployeesModal_CompanyName }}
                />

                <Spacer Size="medium" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text="Enter DHC email to preregister any new users" TextAlign="center" />

                <Spacer Size="extra-small" />

                <Styles.SearchCompaniesInput
                    AutoFocus
                    FontSize="medium-1"
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeTrainerEmail}
                    Placeholder="DHC email"
                    Size="medium"
                    Type="text"
                    Value={ShowAddEmployeesModal_TrainerEmail}
                />

                <Spacer Size="medium" />

                {
                    ShowAddEmployeesModal_Emails.map(({ Email, Valid }, EmailIndex) => {
                        return (
                            <Styles.InputContainer className="InputContainer" key={EmailIndex}>
                                <Styles.InputInnerContainer className="InputInnerContainer">
                                    <Styles.StyledInput FontSize="medium-2" NoLabel NoMargin OnBlur={() => this.onBlurEmail(EmailIndex)} OnChange={event => this.onChangeEmail({ event, EmailIndex })} Placeholder={t('email_with_first_char_uppercase')} Size="medium" Type="text" Value={Email} />

                                    {
                                        EmailIndex !== 0 ?
                                        <Styles.StyledDeleteIcon ImageSrc={RedX} OnClick={() => this.onDeleteEmail(EmailIndex)} />
                                    :
                                        <div />
                                    }
                                </Styles.InputInnerContainer>

                                {!!Email.length && !Valid && <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('provide_valid_email_address')} TextAlign="left" />}
                            </Styles.InputContainer>           
                        );
                    })
                }

                <Spacer Size="extra-small" />

                <ButtonGroup
                    Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: this.onAddEmail, Title: "Add New Employee Email" }]}
                    ButtonWidth="fit-content"
                    NotTouching
                />
            </Styles.InsertEditCompanyModalContainer>
        );
    }

    renderCompanies = () => {
        var { Companies, SearchCompanyName } = this.state;

        if (SearchCompanyName) {
            SearchCompanyName = SearchCompanyName.toLowerCase();
            Companies = Companies.filter(({ CompanyName }) => CompanyName.toLowerCase().includes(SearchCompanyName));
        }

        return (
            <Styles.CompaniesContainer className="CompaniesContainer">
                <Styles.CompaniesHeaderRow className="CompaniesHeaderRow">
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="CompanyId" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Name" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Has White Labeling" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Number of Employees" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                    <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                        <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Created Date" TextAlign="center" />
                    </Styles.CompaniesColumnContainer>
                </Styles.CompaniesHeaderRow>

                {
                    Companies.map(Company => {
                        var { CompanyId, CompanyName, CreatedDate, HasWhitelabeling, NumEmployees } = Company;

                        return (
                            <Styles.CompaniesBodyRow key={CompanyId} className="CompaniesBodyRow" onClick={() => this.onToggleShowInsertEditCompanyModal({ InsertEditCompany: Company, ShowInsertEditCompanyModal: true })}>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${CompanyId}`} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={CompanyName} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={!!+HasWhitelabeling ? 'Yes' : 'No'} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${NumEmployees}`} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                                <Styles.CompaniesColumnContainer className="CompaniesColumnContainer">
                                    <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={new Date(CreatedDate).toLocaleString()} TextAlign="center" />
                                </Styles.CompaniesColumnContainer>
                            </Styles.CompaniesBodyRow>
                        );
                    })
                }
            </Styles.CompaniesContainer>
        );
    }

    renderInsertEditCompanyModel = () => {
        var { t } = this.props;
        var { TryingDeleteCompanyEmployees, TryingInsertEditCompany } = this.props;
        var { InsertEditCompany, ShowAddEmployeesModal, ShowInsertEditCompanyModal } = this.state;

        if (ShowInsertEditCompanyModal && !ShowAddEmployeesModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonDisabled={!InsertEditCompany.CompanyName}
                    BottomButtonOnClick={this.onInsertEditCompany}
                    BottomButtonText={t('_save')}
                    InformationRenderer={this.renderInsertEditCompanyModel_Information}
                    Loading={(TryingDeleteCompanyEmployees || TryingInsertEditCompany)}
                    OnHide={() => this.onToggleShowInsertEditCompanyModal({ ShowInsertEditCompanyModal: false })}
                    Show={ShowInsertEditCompanyModal}
                />
            );
        }
    }

    renderInsertEditCompanyModel_Information = () => {
        var { TryingGetCompany } = this.props;
        var { CompanyId, CompanyName, Employees, HasWhitelabeling } = this.state.InsertEditCompany;

        return (
            <Styles.InsertEditCompanyModalContainer className="InsertEditCompanyModalContainer">
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text="Company Detail" TextAlign="center" />

                <Spacer Size="medium" />

                <Styles.SearchAndAddContainer className="SearchAndAddContainer">
                    <Styles.SearchCompaniesInput
                        FontSize="medium-1"
                        NoLabel
                        NoMargin
                        OnChange={this.onChangeCompanyName}
                        Placeholder="Company name"
                        Size="medium"
                        Type="text"
                        Value={CompanyName}
                    />

                    <Styles.ToggleContainer className="ToggleContainer">
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Has White Labeling" TextAlign="left"  />

                        <Toggle
                            Checked={!!+HasWhitelabeling}
                            OffLabel="No"
                            OnChange={this.onToggleHasWhitelabeling}
                            OneLabel="after"
                            OnLabel="Yes"
                        />
                    </Styles.ToggleContainer>
                </Styles.SearchAndAddContainer>

                {
                    !!+CompanyId &&
                    <>
                        <Spacer Size="medium" />

                        <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="center" NoMargin Text="Employees" TextAlign="center" />

                        {
                            TryingGetCompany &&
                            <>
                                <Spacer Size="extra-small" />
                                
                                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Loading Employees" TextAlign="center" />
                            </>
                        }

                        {
                            Employees && !!+Employees.length &&
                            <>
                                <Spacer Size="extra-small" />

                                <ButtonGroup
                                    Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onToggleShowAddEmployeesModal({ ShowAddEmployeesModal: true, ShowAddEmployeesModal_CompanyId: CompanyId, ShowAddEmployeesModal_CompanyName: CompanyName }), Title: "Add Employees" }]}
                                    ButtonWidth="fit-content"
                                    ContainerWidth="fit-content"
                                />

                                <Spacer Size="extra-small" />

                                <Styles.EmployeesContainer className="EmployeesContainer">
                                    <Styles.EmployeesHeaderRow className="EmployeesHeaderRow">
                                        <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                            <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Employee" TextAlign="center" />
                                        </Styles.EmployeesColumnContainer>
                                        <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                            <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="DHC" TextAlign="center" />
                                        </Styles.EmployeesColumnContainer>
                                        <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                            <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Account status" TextAlign="center" />
                                        </Styles.EmployeesColumnContainer>
                                        <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                            <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Created Date" TextAlign="center" />
                                        </Styles.EmployeesColumnContainer>
                                        <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                            <PageText FontFamily="semibold" FontSize="small" JustifyContent="center" NoMargin Text="Remove" TextAlign="center" />
                                        </Styles.EmployeesColumnContainer>
                                    </Styles.EmployeesHeaderRow>

                                    {
                                        Employees.map(Company => {
                                            var { CreatedDate, Email, FullName, IsPreRegistered, TrainerEmail, TrainerFullName, UserId } = Company;

                                            return (
                                                <Styles.EmployeesBodyRow key={UserId} className="EmployeesBodyRow">
                                                    <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${FullName}`} TextAlign="center" />
                                                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${Email}`} TextAlign="center" />
                                                    </Styles.EmployeesColumnContainer>
                                                    <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${TrainerFullName}`} TextAlign="center" />
                                                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={`${TrainerEmail}`} TextAlign="center" />
                                                    </Styles.EmployeesColumnContainer>
                                                    <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={!!+IsPreRegistered ? 'PreRegistered' : 'Registered'} TextAlign="center" />
                                                    </Styles.EmployeesColumnContainer>
                                                    <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin Text={new Date(CreatedDate).toLocaleString()} TextAlign="center" />
                                                    </Styles.EmployeesColumnContainer>
                                                    <Styles.EmployeesColumnContainer className="EmployeesColumnContainer">
                                                        <PageText FontFamily="medium" FontSize="small" JustifyContent="center" NoMargin OnClick={() => this.onRemoveCompanyEmployee({ CompanyId, UserId })} Text="Remove" TextAlign="center" />
                                                    </Styles.EmployeesColumnContainer>
                                                </Styles.EmployeesBodyRow>
                                            );
                                        })
                                    }
                                </Styles.EmployeesContainer>
                            </>
                        }

                        {
                            Employees && !Employees.length &&
                            <>
                                <Spacer Size="extra-small" />

                                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="No Employees" TextAlign="center" />

                                <Spacer Size="extra-small" />

                                <ButtonGroup
                                    Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onToggleShowAddEmployeesModal({ ShowAddEmployeesModal: true, ShowAddEmployeesModal_CompanyId: CompanyId, ShowAddEmployeesModal_CompanyName: CompanyName }), Title: "Add Employees" }]}
                                    ButtonWidth="fit-content"
                                    ContainerWidth="fit-content"
                                />
                            </>
                        }
                    </>
                }
            </Styles.InsertEditCompanyModalContainer>
        );
    }

    render() {
        var { TryingGetCompanies } = this.props;
        var { SearchCompanyName } = this.state;

        return (
            <>
                {(TryingGetCompanies) && <Loading />}

                <Page>
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text="Companies" TextAlign="center" />

                    <Spacer Size="medium" />

                    <Styles.SearchAndAddContainer className="SearchAndAddContainer">
                        <Styles.SearchCompaniesInput
                            FontSize="medium-1"
                            NoLabel
                            NoMargin
                            OnChange={this.onChangeSearchCompanyName}
                            Placeholder="Search by company name"
                            Size="medium"
                            Type="text"
                            Value={SearchCompanyName}
                        />

                        <ButtonGroup
                            Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onToggleShowInsertEditCompanyModal({ ShowInsertEditCompanyModal: true }), Title: "New Company" }]}
                            ButtonWidth="fit-content"
                            ContainerWidth="fit-content"
                        />
                    </Styles.SearchAndAddContainer>

                    <Spacer Size="medium" />

                    {this.renderAddEmployeesModal()}
                    {this.renderCompanies()}
                </Page>

                {this.renderInsertEditCompanyModel()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        TryingAddCompanyEmployees: state.Companies.TryingAddCompanyEmployees,
        TryingAddCompanyEmployeesError: state.Companies.TryingAddCompanyEmployeesError,
        TryingDeleteCompanyEmployees: state.Companies.TryingDeleteCompanyEmployees,
        TryingDeleteCompanyEmployeesError: state.Companies.TryingDeleteCompanyEmployeesError,
        TryingGetCompanies: state.Companies.TryingGetCompanies,
        TryingGetCompaniesError: state.Companies.TryingGetCompaniesError,
        TryingGetCompany: state.Companies.TryingGetCompany,
        TryingGetCompanyError: state.Companies.TryingGetCompanyError,
        TryingInsertEditCompany: state.Companies.TryingInsertEditCompany,
        TryingInsertEditCompanyError: state.Companies.TryingInsertEditCompanyError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddCompanyEmployees, DeleteCompanyEmployees, GetCompanies, GetCompany, InsertEditCompany } )(CompaniesComponent));