import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddAdditionalActivities, ClearAdditionalActivityDetails, ClearSaveData, GetAdditionalActivityDetails, UpdateSelectedTab } from '../../Services/Actions';
import moment from 'moment';
import history from '../../history';

import TimePicker from 'rc-time-picker';

import { AdditionalActivityItem, AdditionalActivityList, DateTimeContainer, DateTimesContainer, DateTimeUseAllDays, DateTimeUseAllDaysContainer, EditIcon, HourType, HourTypesContainer, SelectedDateContainer, SelectedDatesContainer, StyledDropdown, StyledDuration, StyledIntensities, TrainerNoteContainer, TrainerNoteTextArea } from './styles';

import AssignClients from '../../Components/AssignClients';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import ChooseDates from '../../Modals/ChooseDates';
import Info from '../../Modals/Info';

import Edit_Dark from '../../Assets/Icons/Edit_Dark.png';
import Plus from '../../Assets/Icons/Plus.png';

import { DateSort } from '../../Functions';

class AddAdditionalActivity extends React.Component {
    state = {
        ActivityId: null,
        ActivityName: '',
        CustomName: '',
        DisplayingFilter: null,
        DropdownSize: 0,
        Duration: 0,
        HourType: 24,
        IntensityId: null,
        Loading: true,
        Location: '',
        SelectedDates_Existing: [],
        SelectedDates: [],
        SelectedGroups: {},
        SelectedUserIds: [],
        SelectedUsers: [],
        ShowAdditionalActivityInfoModal: false,
        ShowChooseDatesModal: false,
        ShowDateTimes: true,
        ShowLocation: true,
        SyncAllDateTimes: false,
        TrainerNote: ''
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientDetails, DayDate, GroupDetails } = this.props;

        var FirstDate = DayDate ? moment(moment(DayDate).format('YYYY-MM-DD') + ' ' + moment().format('HH:mm')).format('YYYY-MM-DD HH:mm') : null;

        var SelectedDates = FirstDate ? [ FirstDate ] : [];
        var SelectedDates_Existing = [ ...SelectedDates ];
        var SelectedGroups = {};
        var SelectedUserIds = [];
        var SelectedUsers = [];

        if (Object.entries(ClientDetails).length !== 0) {
            SelectedUserIds = [ Number(ClientDetails.ClientId) ];
            SelectedUsers = [{ ...ClientDetails, UserId: Number(ClientDetails.ClientId) }];
        }

        if (Object.entries(GroupDetails).length !== 0) {
            var { GroupId, GroupImage, GroupName, GroupType } = GroupDetails;
            SelectedGroups[`${GroupType}_GroupId-${GroupId}`] = { AllMembers: true, GroupImage, GroupName, GroupType, Members: [], RemoveMembers: [] };
        }

        this.props.GetAdditionalActivityDetails({ ForWorkoutTemplates: false }).then(() => {
            this.setState({ Loading: false, SelectedDates_Existing, SelectedDates, SelectedGroups, SelectedUserIds, SelectedUsers });
        });
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearAdditionalActivityDetails();
        this.props.ClearSaveData();
    }

    onAddActivity = () => {
        var { ActivityId, CustomName, Duration, IntensityId, Location, SelectedDates, SelectedGroups, SelectedUserIds, ShowDateTimes, ShowLocation, TrainerNote } = this.state;

        var AssignedDates = '';
        if (!ShowDateTimes) AssignedDates = SelectedDates.map(AssignedDate => `${AssignedDate.substring(0,10)} 00:00`);
        AssignedDates = SelectedDates.join(',');

        var ClientIds = SelectedUserIds.join(',');

        this.props.AddAdditionalActivities({ ActivityId, AssignedDates, ClientIds, CustomName, Duration, Groups: SelectedGroups, HasDateTimes: ShowDateTimes, HasLocation: ShowLocation, IntensityId, Location, TrainerNote }).then(() => {
            if (this._isMounted && !this.props.TryingAddAdditionalActivitiesError) {
                if (Object.keys(SelectedGroups).length === 1) {
                    var GroupId = /Team_GroupId-(.*)/.exec(Object.keys(SelectedGroups)[0]) && /Team_GroupId-(.*)/.exec(Object.keys(SelectedGroups)[0])[1];
                    
                    if (GroupId) history.push({ pathname: `group/${GroupId}`, state: { WorkoutDate: SelectedDates[0] } });
                    else if (SelectedUserIds.length === 1) history.push({ pathname: `/client/${SelectedUserIds[0]}`, search: '?type=workouts', state: { WorkoutDate: SelectedDates[0] } });
                    else history.push('/clients');
                }
                else if (SelectedUserIds.length === 1) history.push({ pathname: `/client/${SelectedUserIds[0]}`, search: '?type=workouts', state: { WorkoutDate: SelectedDates[0] } });
                else history.push('/clients');
            }
        });
    }

    onChangeCustomName = event => {
        this.setState({ CustomName: event.target.value });
    }

    onChangeDateTime = (DateTime, SelectedDateIndex) => {
        var NewDateTime = moment(DateTime).format('YYYY-MM-DD HH:mm');

        var { SelectedDates_Existing, SelectedDates, SyncAllDateTimes } = this.state;

        if (!SyncAllDateTimes) {
            SelectedDates_Existing[SelectedDateIndex] = NewDateTime;
            SelectedDates[SelectedDateIndex] = NewDateTime;

            this.setState({ SelectedDates_Existing, SelectedDates });
        } else {
            var NewSelectedDates_Existing = [];
            var NewSelectedDates = [];

            var FirstTime = moment(NewDateTime).format('HH:mm');
            var NewDate;

            for (var i = 0; i < SelectedDates.length; i++) {
                NewDate = moment(moment(SelectedDates[i]).format('YYYY-MM-DD') + ' ' + FirstTime).format('YYYY-MM-DD HH:mm');
                NewSelectedDates_Existing.push(NewDate);
                NewSelectedDates.push(NewDate);
            }

            this.setState({ SelectedDates_Existing: NewSelectedDates_Existing, SelectedDates: NewSelectedDates });
        }
    }

    onChangeDuration = event => {
        this.setState({ Duration: event.target.value });
    }

    onChangeHourType = HourType => {
        this.setState({ HourType });
    }

    onChangeLocation = event => {
        this.setState({ Location: event.target.value });
    }

    onChangeTrainerNote = event => {
        this.setState({ TrainerNote: event.target.value });
    }

    onGetSize = Height => {
        this.setState({ DropdownSize: Height });
    }

    onSelectAdditionalActivity = (ActivityId, ActivityName) => {
        this.setState({ ActivityId, ActivityName, DisplayingFilter: null });
    }

    onSelectClients = ({ SelectedClients: SelectedUsers, SelectedGroups }) => {
        var SelectedUserIds_New = SelectedUsers.map(User => User.UserId);
        SelectedUsers = SelectedUsers.map(User => ({ ...User, Sex: User.Gender }));

        this.setState({ SelectedGroups, SelectedUsers, SelectedUserIds: SelectedUserIds_New });
    }

    onSelectDates = SelectedDates => {
        var { SelectedDates_Existing, SyncAllDateTimes } = this.state;

        var Time = (SyncAllDateTimes && SelectedDates_Existing.length > 1) ? moment(SelectedDates_Existing[0]).format('HH:mm')  : moment().format('HH:mm');
        
        var NewSelectedDates = [];

        var SelectedDates_ExistingIndex = null;
        for (var i = 0; i < SelectedDates.length; i++) {
            SelectedDates_ExistingIndex = SelectedDates_Existing.indexOf(SelectedDates[i]);

            if (SelectedDates_ExistingIndex > -1) NewSelectedDates.push(SelectedDates_Existing[SelectedDates_ExistingIndex]);
            else NewSelectedDates.push(moment(SelectedDates[i] + ' ' + Time).format('YYYY-MM-DD HH:mm'));
        }

        NewSelectedDates.sort(DateSort);

        this.setState({ SelectedDates_Existing: NewSelectedDates, SelectedDates: NewSelectedDates, ShowChooseDatesModal: false });
    }

    onSelectIntensity = IntensityId => {
        this.setState({ IntensityId });
    }

    onSelectUseAllDays = () => {
        var { SelectedDates, SyncAllDateTimes } = this.state;
        
        if (SyncAllDateTimes) this.setState({ SyncAllDateTimes: false });
        else if (!SyncAllDateTimes && SelectedDates.length === 1) this.setState({ SyncAllDateTimes: true });
        
        // Sync all times and more than one date selected
        else {
            var NewSelectedDates_Existing = [];
            var NewSelectedDates = [];

            var FirstTime = moment(SelectedDates[0]).format('HH:mm');
            var NewDate;
            for (var i = 0; i < SelectedDates.length; i++) {
                if (i === 0) {
                    NewSelectedDates_Existing.push(SelectedDates[i]);
                    NewSelectedDates.push(SelectedDates[i]);
                } else {
                    NewDate = moment(moment(SelectedDates[i]).format('YYYY-MM-DD') + ' ' + FirstTime).format('YYYY-MM-DD HH:mm');
                    NewSelectedDates_Existing.push(NewDate);
                    NewSelectedDates.push(NewDate);
                }
            }

            this.setState({ SelectedDates_Existing: NewSelectedDates_Existing, SelectedDates: NewSelectedDates, SyncAllDateTimes: true });
        }
    }

    onToggleDropdown = (DisplayingFilter, ToggleStatus) => {
        this.setState({ DisplayingFilter: ToggleStatus ? DisplayingFilter : null });
    }

    onToggleShowAdditionalActivityInfoModal = ShowAdditionalActivityInfoModal => {
        this.setState({ ShowAdditionalActivityInfoModal });
    }

    onToggleShowChooseDatesModal = ShowChooseDatesModal => {
        this.setState({ ShowChooseDatesModal });
    }

    onToggleShowDateTimes = () => {
        this.setState({ ShowDateTimes: !this.state.ShowDateTimes });
    }

    onToggleShowLocation = () => {
        this.setState({ ShowLocation: !this.state.ShowLocation });
    }

    renderAdditionalActivities = () => {
        return (
            <AdditionalActivityList>
                {
                    this.props.AdditionalActivities.map(({ value, label }) => {
                        var Selected = value === this.state.ActivityId;

                        return (
                            <AdditionalActivityItem key={value} onClick={() => this.onSelectAdditionalActivity(value, label)} Selected={Selected}>
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={label} TextAlign="left" />
                            </AdditionalActivityItem>
                        );
                    })
                }
            </AdditionalActivityList>
        );
    }

    renderAddTrainerNote = () => {
        var { t } = this.props;
        var { TrainerNote } = this.state;

        return (
            <TrainerNoteContainer>
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('WorkoutPlan_Trainer_note_to_client')} TextAlign="center" />

                <Spacer Size="small" />

                <TrainerNoteTextArea
                    OnBlur={this.onChangeTrainerNote}
                    NumRows={4}
                    Placeholder={t('WorkoutPlan_Trainer_note_to_client_placeholder')}
                    Value={TrainerNote}
                />
            </TrainerNoteContainer>
        );
    }

    renderDates = () => {
        var { t } = this.props;
        var { SelectedDates } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('choose_dates')} />
                
                <Spacer Size="small" />

                {
                    SelectedDates.length ?
                    this.renderSelectedDates()
                :
                    <ButtonGroup
                        Buttons={[
                            {
                                Icon: Plus,
                                IconPosition: 'left',
                                OnClick: () => this.onToggleShowChooseDatesModal(true),
                                Title: t('add_dates')
                            }
                        ]}
                        ButtonWidth="fit-content"
                        NotTouching
                    />
                }
            </>
        );
    }

    renderDateTimes = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { HourType: StateHourType, SelectedDates, ShowDateTimes, SyncAllDateTimes } = this.state;

        if (!SelectedDates.length) return null;

        return (
            <>
                <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('activity_start_time')} />

                <DateTimeUseAllDaysContainer>
                    <DateTimeUseAllDays onClick={this.onToggleShowDateTimes} Selected={ShowDateTimes} />
                    <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={this.onToggleShowDateTimes} Text={t('additional_activity_choosetimes')} TextAlign="left" />
                </DateTimeUseAllDaysContainer>

                {
                    ShowDateTimes &&
                    <>
                        <Spacer Size="small" />

                        <HourTypesContainer>
                            <HourType onClick={() => this.onChangeHourType(12)} Selected={StateHourType === 12}>
                                <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('hour_type', { HourType: '12' })} />
                            </HourType>
                            <HourType onClick={() => this.onChangeHourType(24)} Selected={StateHourType === 24}>
                                <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('hour_type', { HourType: '24' })} />
                            </HourType>
                        </HourTypesContainer>

                        <Spacer Size="small" />

                        <DateTimesContainer>
                            {
                                SelectedDates.map((SelectedDate, index) => {
                                    return (
                                        <DateTimeContainer key={SelectedDate}>
                                            <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={moment(SelectedDate).format('DD.MMM')} />
                                            
                                            <Spacer Size="extra-extra-small" />
                                            
                                            <TimePicker
                                                defaultValue={moment(SelectedDate)}
                                                disabled={SyncAllDateTimes && index !== 0}
                                                minuteStep={5}
                                                onChange={DateTime => this.onChangeDateTime(DateTime, index)}
                                                showSecond={false}
                                                use12Hours={StateHourType === 12}
                                                value={moment(SelectedDate)}
                                            />
                                            {
                                                index === 0 && SelectedDates.length > 1 ?
                                                <DateTimeUseAllDaysContainer>
                                                    <DateTimeUseAllDays onClick={this.onSelectUseAllDays} Selected={SyncAllDateTimes} />
                                                    <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" Text={t('use_for_all_days')} TextAlign="left" />
                                                </DateTimeUseAllDaysContainer>
                                            :
                                                null
                                            }
                                        </DateTimeContainer>
                                    );
                                })
                            }
                        </DateTimesContainer>
                    </>
                }
            </>
        );
    }

    renderIntensities = () => {
        var { Device, Intensities } = this.props;
        var Buttons = Intensities.map(({ IntensityId, IntensityName }) => ({ BackgroundColor: 'white', BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', OnClick: () => this.onSelectIntensity(IntensityId), Selected: this.state.IntensityId === IntensityId, Title: IntensityName }));

        return (
            <StyledIntensities
                Buttons={Buttons}
                ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                NotTouching
                OwnRows={Device === 'mobile_small' || Device === 'mobile'}
            />
        );
    }

    renderSelectedDates = () => {
        var { SelectedDates } = this.state;

        return (
            <SelectedDatesContainer>
                {
                    SelectedDates.map(SelectedDate => 
                        <SelectedDateContainer key={SelectedDate}>
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={moment(SelectedDate).format('DD.MMM')} />
                        </SelectedDateContainer>
                    )
                }

                <EditIcon
                    ImageSrc={Edit_Dark}
                    Loading={false}
                    OnClick={() => this.onToggleShowChooseDatesModal(true)}
                />
            </SelectedDatesContainer>
        );
    }

    renderShowAdditionalActivityInfoModal = () => {
        var { ShowAdditionalActivityInfoModal } = this.state;

        if (ShowAdditionalActivityInfoModal) {
            var { t } = this.props;

            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onDeleteWorkout}
                    BottomButtonText={t('delete_with_first_char_uppercase')}
                    Information={t('WorkoutPlan_are_you_sure_you_want_to_remove_this_workout')}
                    OnHide={() => this.onToggleShowAdditionalActivityInfoModal(false)}
                    Show={ShowAdditionalActivityInfoModal}
                    Size="small"
                    TextAlign="center"
                />
            );
        }
    }

    renderShowChooseDatesModal = () => {
        var { ShowChooseDatesModal } = this.state;

        if (ShowChooseDatesModal) {
            return (
                <ChooseDates
                    OnHide={() => this.onToggleShowChooseDatesModal(false)}
                    OnSelectDates={SelectedDates => this.onSelectDates(SelectedDates)}
                    SelectedDates={this.state.SelectedDates}
                    Show={ShowChooseDatesModal}
                />
            );
        }
    }

    render() {
        if (this.props.TryingGetAdditionalActivityDetails) return <Loading />;

        var { t } = this.props;
        var { Device, AdditionalActivities, TryingAddAdditionalActivities } = this.props;
        var { ActivityId, ActivityName, CustomName, DisplayingFilter, DropdownSize, Duration, IntensityId, Location, SelectedDates, SelectedGroups, SelectedUserIds, SelectedUsers, ShowLocation } = this.state;

        var Breadcrumbs = [{ Name: t('add_activity') }];
        var SubmitButtonProps = {
            Disabled: !ActivityId || !+Duration || !IntensityId || !SelectedDates.length || (!Object.entries(SelectedGroups).length === 0 && !SelectedUserIds.length) || (ShowLocation && !Location),
            OnClick: this.onAddActivity,
            Title: t('add_activity')
        }

        return (
            <>
                {TryingAddAdditionalActivities ? <Loading /> : null}

                <Page
                    renderBreadcrumbs={Breadcrumbs}
                    renderSubmitButton={SubmitButtonProps}
                >
                    <PageText FontFamily="medium" FontSize="large" NoMargin Text={t('add_activity')} />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    <AssignClients
                        EligibleTypes="Both"
                        GetStartedEligible="Workouts"
                        HorizontalScroll={false}
                        OnSelectClients={Users => this.onSelectClients(Users)}
                        SelectedGroups={SelectedGroups}
                        SelectedUserIds={SelectedUserIds}
                        SelectedUsers={SelectedUsers}
                    />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderDates()}

                    {this.renderDateTimes()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    <PageText FontFamily="medium" FontSize="medium-2" Text={t('WorkoutPlan_ChooseActivity')} TextAlign="center" />
                    <StyledDropdown
                        ContentRenderer={this.renderAdditionalActivities}
                        Disabled={false}
                        ForceClose={DisplayingFilter !== 'AdditionalActivities'}
                        GetSize={Height => this.onGetSize(Height)}
                        Height={DisplayingFilter === 'AdditionalActivities' && DropdownSize}
                        IsList={false}
                        IsMobile={Device === 'mobile_small' || Device === 'mobile'}
                        ItemId="AdditionalActivities"
                        NeedsToLoad={AdditionalActivities.length === 0}
                        OnToggleDropdown={ToggleStatus => this.onToggleDropdown('AdditionalActivities', ToggleStatus)}
                        SelectedItemIds={[]}
                        Title={ActivityName || t('WorkoutPlan_ChooseActivity')}
                        TitleFontColor={ActivityName ? 'blue-astronaut' : null}
                        TitleFontFamily="semibold"
                    />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    <PageText FontFamily="medium" FontSize="medium-2" Text={t('location')} TextAlign="center" />
                    
                    <DateTimeUseAllDaysContainer>
                        <DateTimeUseAllDays onClick={this.onToggleShowLocation} Selected={ShowLocation} />
                        <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={this.onToggleShowLocation} Text={t('additional_activity_chooselocation')} TextAlign="left" />
                    </DateTimeUseAllDaysContainer>
                    
                    {
                        ShowLocation &&
                        <>
                            <Spacer Size="small" />

                            <StyledDuration
                                NoLabel
                                NoMargin
                                OnChange={this.onChangeLocation}
                                Placeholder={t('location_placeholder')}
                                Size="medium"
                                Type="text"
                                Value={Location}
                            />
                        </>
                    }

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />
    
                    <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('custom_name')} TextAlign="center" />
                    <PageText FontFamily="medium" FontSize="medium-1" Text={t('custom_name_additional_activity_info')} TextAlign="center" />
                    <StyledDuration
                        NoDecimal
                        NoLabel
                        NoMargin
                        OnChange={this.onChangeCustomName}
                        Placeholder={t('custom_name')}
                        Size="medium"
                        Type="text"
                        Value={CustomName}
                    />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    <PageText FontFamily="medium" FontSize="medium-2" Text={t('WorkoutPlan_Intensity')} TextAlign="center" />
                    {this.renderIntensities()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    <PageText FontFamily="medium" FontSize="medium-2" Text={t('WorkoutPlan_duration_on_minutes')} TextAlign="center" />
                    <StyledDuration
                        NoDecimal
                        NoLabel
                        NoMargin
                        OnChange={this.onChangeDuration}
                        Placeholder='0'
                        Size="medium"
                        Type="number"
                        Value={Duration}
                    />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderAddTrainerNote()}
                </Page>

                {this.renderShowChooseDatesModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        ClientDetails: state.SaveData.ClientDetails,
        DayDate: state.SaveData.DayDate,
        GroupDetails: state.SaveData.GroupDetails,

        AdditionalActivities: state.AdditionalActivities.AdditionalActivities,
        Intensities: state.AdditionalActivities.Intensities,

        TryingAddAdditionalActivities: state.AdditionalActivities.TryingAddAdditionalActivities,
        TryingAddAdditionalActivitiesError: state.AdditionalActivities.TryingAddAdditionalActivitiesError,
        TryingGetAdditionalActivityDetails: state.AdditionalActivities.TryingGetAdditionalActivityDetails,
        TryingGetAdditionalActivityDetailsError: state.AdditionalActivities.TryingGetAdditionalActivityDetailsError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddAdditionalActivities, ClearAdditionalActivityDetails, ClearSaveData, GetAdditionalActivityDetails, UpdateSelectedTab } )(AddAdditionalActivity));