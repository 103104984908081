import {
    TRYING_DELETE_EXERCISES,
    TRYING_DELETE_EXERCISES_SUCCESS,
    TRYING_DELETE_EXERCISES_ERROR,

    TRYING_GET_EXERCISE_FILTER_SETTINGS,
    TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS,
    TRYING_GET_EXERCISE_FILTER_SETTINGS_ERROR,

    TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES,
    TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_SUCCESS,
    TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_ERROR,

    CLEAR_SEARCH_EXERCISES,
    TRYING_SEARCH_EXERCISES,
    TRYING_SEARCH_EXERCISES_SUCCESS,
    TRYING_SEARCH_EXERCISES_ERROR,

    TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES,
    TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES_SUCCESS,
    TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES_ERROR,

    TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES,
    TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES_SUCCESS,
    TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES_ERROR
} from '../Types';

const INITIAL_STATE = {
    ExerciseFilterSettings: {},
    ExerciseList: [],
    ExerciseList_NumPendingShared: null,
    ExerciseList_TotalRecords: null,
    IsMoreExercises: false,
    
    TryingDeleteExercises: false,
    TryingDeleteExercisesError: null,
    TryingGetExerciseFilterSettings: false,
    TryingGetExerciseFilterSettingsError: null,
    TryingInsertEditLibraryFilterExercises: false,
    TryingInsertEditLibraryFilterExercisesError: null,
    TryingSearchExercises: false,
    TryingSearchExercisesError: null,
    TryingUpdateShareAbilityStatus_Exercises: false,
    TryingUpdateShareAbilityStatus_ExercisesError: null,
    TryingUpdateSharedToCoachStatusAll_Exercises: false,
    TryingUpdateSharedToCoachStatusAll_Exercises_Error: null
};

var ExerciseList;
var i;

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_DELETE_EXERCISES:
            return { ...state, TryingDeleteExercises: true, TryingDeleteExercisesError: null };
        case TRYING_DELETE_EXERCISES_ERROR:
            return { ...state, TryingDeleteExercises: false, TryingDeleteExercisesError: action.payload };
        case TRYING_DELETE_EXERCISES_SUCCESS:
            return { ...state, TryingDeleteExercises: false, TryingDeleteExercisesError: null };

        case TRYING_GET_EXERCISE_FILTER_SETTINGS:
            return { ...state, TryingGetExerciseFilterSettings: true, TryingGetExerciseFilterSettingsError: null };
        case TRYING_GET_EXERCISE_FILTER_SETTINGS_ERROR:
            return { ...state, TryingGetExerciseFilterSettings: false, TryingGetExerciseFilterSettingsError: action.payload };
        case TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS:
            return { ...state, ExerciseFilterSettings: action.payload, TryingGetExerciseFilterSettings: false, TryingGetExerciseFilterSettingsError: null };

        case TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES:
            return { ...state, TryingInsertEditLibraryFilterExercises: true, TryingInsertEditLibraryFilterExercisesError: null };
        case TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_ERROR:
            return { ...state, TryingInsertEditLibraryFilterExercises: false, TryingInsertEditLibraryFilterExercisesError: action.payload };
        case TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_SUCCESS:
            return { ...state, TryingInsertEditLibraryFilterExercises: false, TryingInsertEditLibraryFilterExercisesError: null };

        case CLEAR_SEARCH_EXERCISES:
            return { ...state, ExerciseFilterSettings: {}, ExerciseList: [], ExerciseList_NumPendingShared: null, ExerciseList_TotalRecords: null, IsMoreExercises: false };
        case TRYING_SEARCH_EXERCISES:
            return { ...state, TryingSearchExercises: true, TryingSearchExercisesError: null };
        case TRYING_SEARCH_EXERCISES_ERROR:
            return { ...state, ExerciseList: [], ExerciseList_TotalRecords: null, IsMoreExercises: false, TryingSearchExercises: false, TryingSearchExercisesError: action.payload };
        case TRYING_SEARCH_EXERCISES_SUCCESS:
            // eslint-disable-next-line
            var { AddToExisting, Exercises, NumPendingShared, IsMoreResults, TotalRecords } = action.payload;
            ExerciseList = [];

            if (AddToExisting) ExerciseList = [ ...state.ExerciseList, ...Exercises ];
            else ExerciseList = [ ...Exercises ];

            return { ...state, ExerciseList, ExerciseList_NumPendingShared: NumPendingShared, ExerciseList_TotalRecords: +TotalRecords, IsMoreExercises: !!IsMoreResults, TryingSearchExercises: false, TryingSearchExercisesError: null };

        case TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES:
            return { ...state, TryingUpdateShareAbilityStatus_Exercises: true, TryingUpdateShareAbilityStatus_ExercisesError: null };
        case TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES_ERROR:
            return { ...state, TryingUpdateShareAbilityStatus_Exercises: false, TryingUpdateShareAbilityStatus_ExercisesError: action.payload };
        case TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES_SUCCESS:
            // eslint-disable-next-line
            var { NewStatus: ShareAbilityStatusId, NewStatusName: ShareAbility, ExerciseId } = action.payload;

            // eslint-disable-next-line
            var ExerciseList_New = [ ...state.ExerciseList ];

            for (i = 0; i < ExerciseList_New.length; i++) {
                if (+ExerciseList_New[i].ExerciseId === +ExerciseId) {
                    ExerciseList_New[i] = { ...ExerciseList_New[i], ShareAbility, ShareAbilityStatusId };
                    break;
                }
            }

            return { ...state, ExerciseList: ExerciseList_New, TryingUpdateShareAbilityStatus_Exercises: false, TryingUpdateShareAbilityStatus_ExercisesError: null };

        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES:
            return { ...state, TryingUpdateSharedToCoachStatusAll_Exercises: true, TryingUpdateSharedToCoachStatusAll_ExercisesError: null };
        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES_ERROR:
            return { ...state, TryingUpdateSharedToCoachStatusAll_Exercises: false, TryingUpdateSharedToCoachStatusAll_ExercisesError: action.payload };
        case TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES_SUCCESS:
            return { ...state, TryingUpdateSharedToCoachStatusAll_Exercises: false, TryingUpdateSharedToCoachStatusAll_ExercisesError: null };

        default:
            return state;
    }
};
