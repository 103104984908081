import styled from 'styled-components';

import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';

export const ChildrenContainer = styled.div`
    margin: 0;
`;

export const LinkContainer = styled(Link)`
    color: var(--blue-abel);
    padding: 15px;
    
    @media(hover:hover) {
        :hover {
            color: var(--link-hover);
            cursor: pointer;
        }
    }

    :active {
        color: var(--blue-abel);
    }
`;

export const StyledModal = styled(Modal)``;

export const StyledReadAll = styled(ButtonGroup)`
    margin-left: 10px;
`;

export const TitleContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 15px;

    
`;