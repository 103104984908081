import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types';
import Chart from "chart.js";
// import moment from 'moment';

import { SecondsToTime, TimeToSeconds } from '../../../Functions';

//--Chart Style Options--//
Chart.defaults.global.defaultFontFamily = "'branding-medium'"
// Chart.defaults.global.legend.display = false;
//--Chart Style Options--//


class LineGraph extends PureComponent {
    chartRef = React.createRef();

    componentDidMount() {
        this.buildChart();
    }

    componentDidUpdate() {
        this.myChart.destroy();
        this.buildChart();
    }

    buildChart = () => {
        var { Measurements, Summary, xLabels, yLabels } = this.props;

        var datasets = Measurements.length ? this.buildChartData({ Measurements, Summary }) : [];
        xLabels = xLabels.map(time => TimeToSeconds(time));

        this.myChart = new Chart(this.chartRef.current, {
            type: 'scatter',
            data: { datasets },
            options: {
                responsive: true,
                scales: {
                    xAxes: [
                        {
                            display: true,
                            gridLines: {
                                display:false
                            },
                            position: 'bottom',
                            ticks: {
                                min: xLabels[0],
                                max: xLabels[xLabels.length - 1],
                                callback: value => SecondsToTime(value),
                                autoSkip: false,
                                maxRotation: 45,
                                minRotation: 45,
                                stepSize: xLabels.length > 2 ? xLabels[1] - xLabels[0] : null
                            }
                        }
                    ],
                    yAxes: [{
                        gridLines: {
                            display: true
                        },
                        ticks: {
                            min: yLabels[0],
                            max: yLabels[yLabels.length - 1]
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        title: (tooltipItems) => SecondsToTime(tooltipItems[0].xLabel),
                        label: ({ yLabel }) => `${yLabel}`
                    }
                }
            }
        });
    }

    buildChartData = ({ Measurements, Summary }) => {
        var datasets = [];

        // Add Measurements To Datasets
            datasets.push({
                label: 'Measurements',
                backgroundColor: 'rgba(26, 151, 255, 0.2)', borderColor: '#1A97FF',
                pointRadius: 1,
                pointHitRadius: 0,
                showLine: true,
                data: Measurements.map(({ heartRate, time }) => ({ x: time, y: heartRate }))
            });

        // Add Average HR line
            datasets.push({
                label: 'Average',
                backgroundColor: 'rgba(26, 151, 255, 0.0)', borderColor: '#FD9326',
                showLine: true,
                pointRadius: 0,
                pointHitRadius: 0,
                data: [{ x: Measurements[0].time, y: Summary.AverageHR }, { x: Measurements[Measurements.length - 1].time, y: Summary.AverageHR }]
            })

        return datasets;
    }

    render() {

        return (
            <div>
                <canvas ref={this.chartRef} />
            </div>
        )
    }
}

LineGraph.propTypes = {
    // AllTime: PropTypes.bool,
    // EndDate: PropTypes.string,
    // Goals: PropTypes.array.isRequired,
    // Increment: PropTypes.number.isRequired,
    // IsTime: PropTypes.bool,
    // Maximum: PropTypes.number.isRequired,
    // Minimum: PropTypes.number.isRequired,
    // Measurements: PropTypes.array.isRequired,
    // StartDate: PropTypes.string,
    // TimePeriod: PropTypes.string,
    // Units: PropTypes.string.isRequired
}

export default LineGraph;