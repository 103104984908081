import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { TextAreaContainer, TextAreaElement, TextAreaLabel } from './styles';

import PageText from '../../Text/PageText';

class TextArea extends React.Component {
    state = { CharactersLeft: null, MaxCharactersValid: null, Value: this.props.Value };

    componentDidMount() {
        var { MaxCharacters, OnChangeMaxCharactersValidation, Value } = this.props;

        var CharactersLeft = MaxCharacters - (Value ? Value.length : 0);
        var MaxCharactersValid = CharactersLeft >= 0;

        if (MaxCharacters) this.setState({ CharactersLeft, MaxCharactersValid }, () => {
            if (OnChangeMaxCharactersValidation) OnChangeMaxCharactersValidation(MaxCharactersValid);
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.ControlledComponent && this.props.ShowCharacterCount && prevProps.Value !== this.props.Value) {
            var event = { target: { value: this.props.Value } };
            this.onChange(event);
        }
    }

    onBlur = event => {
        if (this.props.MaxCharacters && this.props.ShowExtraWillBeRemoved) {
            var Value = event.target.value;

            if (this.state.CharactersLeft < 0) {
                Value = Value.slice(0, this.props.MaxCharacters);
                
                this.setState({ CharactersLeft: 0, MaxCharactersValid: true, Value }, () => {
                    this.props.OnBlur(Value);
                })
            } else this.props.OnBlur(Value);
        } else this.props.OnBlur(event, this.state.CharactersLeft);
    }

    onChange = event => {
        var Value = event.target.value;

        var { MaxCharacters, OnChangeMaxCharactersValidation } = this.props;
        var { MaxCharactersValid: OriginalMaxCharactersValid } = this.state;

        var CharactersLeft = MaxCharacters - Value.length;
        var MaxCharactersValid = CharactersLeft >= 0;

        this.setState({ CharactersLeft, MaxCharactersValid, Value }, () => {
            if (OnChangeMaxCharactersValidation && OriginalMaxCharactersValid !== MaxCharactersValid) OnChangeMaxCharactersValidation(MaxCharactersValid);
        });
    }

    render() {
        var { t } = this.props;
        var { className, ControlledComponent, Label, LabelFontFamily, LabelFontSize, MaxRows, NoMargin, NumRows, OnChange, OnKeyPress, Placeholder, ShowCharacterCount, ShowExtraWillBeRemoved, Value: PropsValue } = this.props;
        var { CharactersLeft, MaxCharactersValid, Value } = this.state;

        if (ControlledComponent) {
            Value = PropsValue;
        }
        else OnChange = this.onChange;

        return (
            <TextAreaContainer className={className} NoMargin={NoMargin}>
                {Label && <TextAreaLabel FontFamily={LabelFontFamily} FontSize={LabelFontSize}>{Label}</TextAreaLabel>}

                <TextAreaElement
                    maxRows={MaxRows}
                    rows={NumRows || 8}
                    placeholder={Placeholder}
                    onBlur={this.onBlur}
                    onChange={OnChange}
                    onKeyPress={OnKeyPress}
                    type="textarea"
                    value={Value}
                />

                {ShowCharacterCount && <PageText FontColor={MaxCharactersValid ? 'black' : 'red-bittersweet'} FontFamily={MaxCharactersValid ? 'medium' : 'semibold'} FontSize="small" JustifyContent="flex-start" NoMargin Text={`${t('characters_left')}: ${CharactersLeft}`} TextAlign="left" />}
                {ShowExtraWillBeRemoved && !MaxCharactersValid && <PageText FontColor="red-bittersweet" FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin Text={t('_extra_chars_deleted')} TextAlign="left" />}
            </TextAreaContainer>
            
        );
    }
}

TextArea.propTypes = {
    ControlledComponent: PropTypes.bool,
    Label: PropTypes.string,
    LabelFontFamily: PropTypes.string,
    LabelFontSize: PropTypes.string,
    MaxCharacters: PropTypes.number,
    MaxRows: PropTypes.number,
    NoMargin: PropTypes.bool,
    NumRows: PropTypes.number,
    OnBlur: PropTypes.func,
    OnChange: PropTypes.func,
    OnChangeMaxCharactersValidation: PropTypes.func,
    OnKeyPress: PropTypes.func,
    ShowCharacterCount: PropTypes.bool,
    ShowExtraWillBeRemoved: PropTypes.bool,
    Placeholder: PropTypes.string,
    Value: PropTypes.string.isRequired
}

TextArea.defaultProps = {
    OnBlur: () => null,
    MaxCharacters: 0,
    ShowExtraWillBeRemoved: false
}

export default withTranslation()(TextArea);