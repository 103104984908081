import styled from 'styled-components';
import { device } from '../../Config/device';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Icon from '../../Components/Icon';
import Image from '../../Components/Image';

import Checkmark from '../../Assets/Icons/Checkmark_Selected.png';

export const AcceptMembershipContainer = styled.div``;

export const BillingHistoryContainer = styled.div``;

export const BillingHistorySummaryContainer = styled.div`
    align-items: flex-start;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 15px 25px;
    width: fit-content;
`;

export const BillingHistorySummaryNextBillContainer = styled.div``;

export const BillingHistorySummaryYourPlanContainer = styled.div`
    margin-right: 30px;
`;

export const BillingHistoryTable = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

export const BillingHistoryTableColumn = styled.div``;

export const BillingHistoryTableRow = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 2fr 2fr 2fr 1fr;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
`;

export const ChooseSubscriptionButtonContainer = styled.div`
    align-items: center;
    background-color: var(--yellow-gold);
    border-radius: 10px;
    bottom: -15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 10px;
    position: absolute;
    width: 85%;

    @media(hover:hover) {
        :hover {
            background-color: var(--yellow-gold-dark);
            cursor: pointer;
        }
    }
`;

export const CircularPhotoContainer = styled.div`
    position: absolute;
    top: -30px;
`;

export const DateTimeUseAllDays = styled.span`
    ${
        props => props.Selected ?
        `
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
        `
    :
        `
            background-color: var(--white);
            border: 2px solid var(--gray-light);
        `
    };
    border-radius: 50%;
    display: block;
    height: 25px;
    margin-right: 5px;
    min-width: 25px;
    width: 25px;

    @media(hover:hover) {
        :hover {
            background-image: url(${Checkmark});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 25px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const DateTimeUseAllDaysContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 10px;
`;

export const DownloadContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const HeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media ${device.maxwidth.mobile} {
        flex-direction: column;
        justify-content: center;
    }
`;

export const MembershipRequestContainer = styled.div`
    align-items: flex-start;
    border: 2px solid ${props => props.IsPrimaryOrg ? 'var(--abel-blue)' : 'var(--white-concrete)'};
    border-radius: 10px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    ${
        props => props.MembershipStatus === 'Pending' &&
        `
            background-color: var(--white-concrete);
            border-radius: 10px;
            padding: 10px;
        `
    }
`;

export const MembershipRequestsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > div:not(:last-child) {
        margin-bottom: 15px;
    }
`;

export const PaymentCardContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const PaymentCardImage = styled(Image)`
    height: 30px;
    margin-right: 15px;
`;

export const StyledAcceptMembershipBottomButtons = styled(ButtonGroup)`
    > div {
        :first-child {
            border-top-left-radius: 0px
        }

        :last-child {
            border-top-right-radius: 0px;
        }
    }
`;

export const StyledHR = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    height: 2px;
    width: 100%;
`;

export const StyledImage = styled.img`
    border-radius: 50%;
    height: ${props => props.Size ? `var(--profilephoto-size-${props.Size.toLowerCase()})` : 'var(--profilephoto-size-large)'};
    width: ${props => props.Size ? `var(--profilephoto-size-${props.Size.toLowerCase()})` : 'var(--profilephoto-size-large)'};
`;

export const StyledPDFIcon = styled(Icon)`
    margin-right: 10px;
    width: 25px;
`;

export const SubscriptionDescriptionContainer = styled.div`
    width: 100%;
`;

export const SubscriptionDetailsContainer = styled.div``;

export const SubscriptionListContainer = styled.ul`
    margin: 0px;
    padding-left: 20px;
`;

export const SubscriptionPriceContainer = styled.div`
    align-items: center;
    background-color: var(--${props => props.Color});
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    width: calc(100% + 20px);
`;

export const SubscriptionsContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    // grid-template-columns: 1fr 1fr 1fr;
    // grid-template-columns: 1fr ${props => !!props.HasRequests && `1fr 1fr`};
    grid-template-columns: minmax(150px, 250px) minmax(150px, 250px) 1fr;// When subscriptions are added again
    margin-top: 15px;
    max-width: 1200px;
`;

export const SubscriptionTypeContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border: 5px solid ${props => !!props.Selected ? `var(--${props.Color})` : 'var(--white)'};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 10px ${props => !!props.ShowChangeButton ? '30px' : '10px'};
    position: relative;
`;
