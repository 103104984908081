import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import * as styles from './styles';

import AbelModal from '../../Components/Modal';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

function HowSharingWorksModal(props) {
    const { t } = useTranslation();

    const renderModalBody = () => {
        return (
            <styles.Container>
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('sharing_details_how_it_works')} TextAlign="left" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('sharing_details_how_it_works_info')} TextAlign="left" WhiteSpace="pre-line" />

                <Spacer Size="medium" />

                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('sharing_details_what_is_shareability')} TextAlign="left" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('sharing_details_what_is_shareability_info')} TextAlign="left" WhiteSpace="pre-line" />

                <Spacer Size="small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${t('sharing_details_shareability_status_option_always')}: `},
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('sharing_details_shareability_status_option_always_info') }
                    ]}
                />

                <Spacer Size="small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${t('sharing_details_shareability_status_option_never')}: `},
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('sharing_details_shareability_status_option_never_info') }
                    ]}
                />

                {/* <Spacer Size="small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'semibold', FontSize: 'medium-1', Text: `${t('sharing_details_shareability_status_option_ask')}: `},
                        { FontFamily: 'medium', FontSize: 'medium-1', Text: t('sharing_details_shareability_status_option_ask_info') }
                    ]}
                /> */}

                <Spacer Size="medium" />

                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="flex-start" NoMargin Text={t('sharing_details_child_content_title')} TextAlign="left" />

                <Spacer Size="small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={t('sharing_details_child_content_description')} TextAlign="left" WhiteSpace="pre-line" />
            </styles.Container>
        );
    }

    return (
        <AbelModal
            Show={props.Show}
            Size="medium"
            OnHide={props.OnHide}
            TopButton="cancel"
            TopButtonOnClick={props.OnHide}
        >
            {renderModalBody()}
        </AbelModal>
    );
}

HowSharingWorksModal.propTypes = {
    OnHide: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired
}

export default HowSharingWorksModal;