import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ShowErrorModalWarning } from '../../Services/Actions';

import { DataContainer, DataParentContainer, LapDetailContainer, StyledHeaders, StyledInputLabelSideBySide } from './styles';

import MultiText from '../Text/MultiText';
import PageText from '../Text/PageText';

import { SecondsToMinutesAndSeconds, SecondsToTime } from '../../Functions';

class ActiveLap extends React.Component {
    state = {
        ActiveMinutes: 0,
        ActiveSeconds: 0,
        ActiveUnits: this.props.ActiveUnits,
        RestMinutes: 0,
        RestSeconds: 0,
        OriginalActive: 0,
        OriginalRest: 0,
        OriginalSpeed: 0,
        Speed: 0,
        SpeedUnits: this.props.SpeedUnits,
        TotalActive: 0,
        TotalRest: 0
    };

    componentDidMount() {
        var { Active, ActiveType, Rest, Speed } = this.props;

        var { Minutes: RestMinutes, Seconds: RestSeconds } = SecondsToMinutesAndSeconds(Rest);
        if (ActiveType === 'Time') var { Minutes: ActiveMinutes, Seconds: ActiveSeconds } = SecondsToMinutesAndSeconds(Active);

        RestSeconds = +RestSeconds === 0 ? '00' : +RestSeconds < 10 ? `0${RestSeconds}` : RestSeconds;
        if (ActiveType === 'Time') ActiveSeconds = +ActiveSeconds === 0 ? '00' : +ActiveSeconds < 10 ? `0${ActiveSeconds}` : ActiveSeconds;

        this.setState({
            ActiveMinutes, ActiveSeconds,
            RestMinutes, RestSeconds,
            OriginalActive: Active, OriginalRest: Rest, OriginalSpeed: +Speed,
            Speed: +Speed,
            TotalActive: Active, TotalRest: Rest
        });
    }

    componentDidUpdate(prevProps) {
        var { Speed: PrevSpeed, SpeedUnits: PrevSpeedUnits, WorkoutLapId: PrevWorkoutLapId } = prevProps;
        var { Speed: CurrSpeed, SpeedUnits: CurrSpeedUnits, WorkoutLapId: CurrWorkoutLapId } = this.props;

        if (+PrevWorkoutLapId !== +CurrWorkoutLapId) {
            var { Active, ActiveType, Rest, Speed } = this.props;

            var { Minutes: RestMinutes, Seconds: RestSeconds } = SecondsToMinutesAndSeconds(Rest);
            if (ActiveType === 'Time') var { Minutes: ActiveMinutes, Seconds: ActiveSeconds } = SecondsToMinutesAndSeconds(Active);

            RestSeconds = +RestSeconds === 0 ? '00' : +RestSeconds < 10 ? `0${RestSeconds}` : RestSeconds;
            if (ActiveType === 'Time') ActiveSeconds = +ActiveSeconds === 0 ? '00' : +ActiveSeconds < 10 ? `0${ActiveSeconds}` : ActiveSeconds;

            this.setState({
                ActiveMinutes, ActiveSeconds,
                RestMinutes, RestSeconds,
                OriginalActive: Active, OriginalRest: Rest, OriginalSpeed: +Speed,
                Speed: +Speed,
                TotalActive: Active, TotalRest: Rest
            });
        }

        // Used in Regenerate Max Speed
        if (!+PrevSpeed && +CurrSpeed) this.setState({ Speed: +CurrSpeed });
        
        if (PrevSpeedUnits !== CurrSpeedUnits) this.setState({ SpeedUnits: CurrSpeedUnits });
    }

    onBlurActiveDistance = () => {
        var { t } = this.props;
        var { OriginalActive, TotalActive } = this.state;

        // Required to not be 0
            if (+TotalActive === 0) this.setState({ TotalActive: OriginalActive }, () => this.props.ShowErrorModalWarning({ ModalError: t('active_greater_than_0') }));
            else if (+TotalActive !== +OriginalActive) this.onUpdateWorkoutLap();
    }

    onBlurActiveTime = () => {
        var { t } = this.props;
        var TotalActive = 0;
        TotalActive += (this.state.ActiveMinutes * 60) + +this.state.ActiveSeconds;

        // Required to not be 0
            if (+TotalActive === 0) {
                var { OriginalActive } = this.state;
                var { Minutes: ResetActiveMinutes, Seconds: ResetActiveSeconds } = SecondsToMinutesAndSeconds(OriginalActive);

                this.setState({ ActiveMinutes: ResetActiveMinutes, ActiveSeconds: ResetActiveSeconds, TotalActive: OriginalActive }, () => this.props.ShowErrorModalWarning({ ModalError: t('active_greater_than_0') }));
            } else {
                var { Minutes: ActiveMinutes, Seconds: ActiveSeconds } = SecondsToMinutesAndSeconds(TotalActive);

                ActiveSeconds = +ActiveSeconds === 0 ? '00' : +ActiveSeconds < 10 ? `0${ActiveSeconds}` : ActiveSeconds;
                ActiveMinutes = +ActiveMinutes === 0 ? 0 : ActiveMinutes;

                if (+TotalActive !== +this.state.OriginalActive) this.setState({ ActiveMinutes, ActiveSeconds, TotalActive }, () => this.onUpdateWorkoutLap());
                else this.setState({ ActiveMinutes, ActiveSeconds, TotalActive });
            }
    }

    onBlurRestTime = () => {
        var TotalRest = 0;
        TotalRest += (this.state.RestMinutes * 60) + +this.state.RestSeconds;

        var { Minutes: RestMinutes, Seconds: RestSeconds } = SecondsToMinutesAndSeconds(TotalRest);

        RestSeconds = +RestSeconds === 0 ? '00' : +RestSeconds < 10 ? `0${RestSeconds}` : RestSeconds;
        RestMinutes = +RestMinutes === 0 ? 0 : RestMinutes;

        if (+TotalRest !== +this.state.OriginalRest) this.setState({ RestMinutes, RestSeconds, TotalRest }, () => this.onUpdateWorkoutLap());
        else this.setState({ RestMinutes, RestSeconds, TotalRest });
    }

    onBlurSpeed = () => {
        var { OriginalSpeed, Speed } = this.state;

        if (+Speed === 0) this.setState({ Speed: 0 });

        if (+OriginalSpeed !== +Speed) this.onUpdateWorkoutLap();
    }

    onChangeActiveDistance = event => {
        this.setState({ TotalActive: event.target.value });
    }

    onChangeActiveTime = (event, TimeType, OtherTimeTypeValue) => {
        var TotalActive = 0;
        if (TimeType === 'Minutes') TotalActive += (event.target.value * 60) + OtherTimeTypeValue;
        else if (TimeType === 'Seconds') TotalActive += event.target.value + (OtherTimeTypeValue * 60);

        this.setState({ [`Active${TimeType}`]: event.target.value, TotalActive });
    }

    onChangeRestTime = (event, TimeType, OtherTimeTypeValue) => {
        var TotalRest = 0;
        if (TimeType === 'Minutes') TotalRest += (event.target.value * 60) + OtherTimeTypeValue;
        else if (TimeType === 'Seconds') TotalRest += event.target.value + (OtherTimeTypeValue * 60);

        this.setState({ [`Rest${TimeType}`]: event.target.value, TotalRest });
    }

    onChangeSpeed = event => {
        this.setState({ Speed: event.target.value });
    }

    onUpdateWorkoutLap = () => {
        var { Speed, TotalActive, TotalRest } = this.state;

        this.setState({ OriginalActive: TotalActive, OriginalRest: TotalRest, OriginalSpeed: Speed }, () => this.props.OnUpdateWorkoutLap({ Active: TotalActive, Rest: TotalRest, Speed }));
    }

    render() {
        var { t } = this.props;
        var { ActiveType, Completed, DataType, HasTestLaps, ReadOnly, SetType } = this.props;
        var { ActiveMinutes, ActiveSeconds, ActiveUnits, RestMinutes, RestSeconds, Speed, SpeedUnits, TotalActive, TotalRest } = this.state;

        var ActiveText = ActiveType === 'Time' ? SecondsToTime(TotalActive) : `${TotalActive} ${ActiveUnits.toLowerCase()}`;
        var ActiveLabel = ActiveType === 'Time' ? `${t('min_str').toLowerCase()} / ${t('Seconds_short').toLowerCase()}` : ActiveUnits.toLowerCase();

        var RestText = SecondsToTime(TotalRest);
        var RestLabel = `${t('min_str').toLowerCase()} / ${t('Seconds_short').toLowerCase()}`;

        var SpeedText = `${Speed} ${SpeedUnits && SpeedUnits.toLowerCase()}`;
        var SpeedLabel = SpeedUnits && SpeedUnits.toLowerCase();

        // Add % text if template and dynamic
        var SpeedColumnText = '';
        if (SetType === 'Template' && DataType === 'dynamic') SpeedColumnText = t('percent_of_max', { MeasureType: t('Speed').toLowerCase() });
        else SpeedColumnText = t('Speed');

        return (
            <LapDetailContainer className="Container">
                <DataParentContainer className="DataParentContainer">
                    <DataContainer className="DataContainer" Completed={Completed}>
                        <StyledHeaders FontFamily="bold" FontSize="medium-1" NoMargin Text={t('WorkoutPlan_endurance_active_type_header')} />

                        {
                            (ReadOnly || (Completed || HasTestLaps)) ?
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'medium-3', Text: ActiveText }
                                ]}
                            />
                        :
                            ActiveType === 'Time' ?
                            <StyledInputLabelSideBySide
                                InputFontFamily="semibold"
                                InputFontSize="medium-2"
                                LabelFontFamily="medium"
                                LabelFontSize="medium-1"
                                Label={`${t('min_str').toLowerCase()} / ${t('Seconds_short').toLowerCase()}`}
                                OnBlur={this.onBlurActiveTime}
                                OnChange={(event, TimeType, OtherTimeTypeValue) => this.onChangeActiveTime(event, TimeType, OtherTimeTypeValue)}
                                Placeholder="0"
                                TimeValueMinutes={ActiveMinutes}
                                TimeValueSeconds={ActiveSeconds}
                                Type="time"
                            />
                        :
                            <StyledInputLabelSideBySide
                                InputFontFamily="semibold"
                                InputFontSize="medium-2"
                                LabelFontFamily="medium"
                                LabelFontSize="medium-1"
                                Label={ActiveLabel}
                                OnBlur={this.onBlurActiveDistance}
                                OnChange={this.onChangeActiveDistance}
                                Placeholder="0"
                                Type="number"
                                Value={TotalActive}
                            />
                        }
                    </DataContainer>
                </DataParentContainer>

                <DataParentContainer className="DataParentContainer">
                    <DataContainer className="DataContainer" Completed={Completed}>
                        <StyledHeaders FontFamily="bold" FontSize="medium-1" NoMargin Text={SpeedColumnText} />

                        {
                            (ReadOnly || (Completed || HasTestLaps)) ?
                            <PageText FontFamily="semibold" FontSize="medium-3" NoMargin Text={SpeedText} />
                        :
                            <StyledInputLabelSideBySide
                                InputFontFamily="semibold"
                                InputFontSize="medium-2"
                                LabelFontFamily="medium"
                                LabelFontSize="medium-1"
                                Label={SpeedLabel}
                                OnBlur={this.onBlurSpeed}
                                OnChange={this.onChangeSpeed}
                                Placeholder="0"
                                Type="number"
                                Value={Speed}
                            />
                        }
                    </DataContainer>
                </DataParentContainer>

                <DataParentContainer className="DataParentContainer">
                    <DataContainer className="DataContainer" Completed={Completed}>
                        <StyledHeaders FontFamily="bold" FontSize="medium-1" NoMargin Text={t('rest_str')} />

                        {
                            (ReadOnly || (Completed || HasTestLaps)) ?
                            <MultiText
                                Texts={[
                                    { FontFamily: 'semibold', FontSize: 'medium-3', Text: RestText }
                                ]}
                            />
                        :
                            <StyledInputLabelSideBySide
                                InputFontFamily="semibold"
                                InputFontSize="medium-2"
                                LabelFontFamily="medium"
                                LabelFontSize="medium-1"
                                Label={RestLabel}
                                OnBlur={this.onBlurRestTime}
                                OnChange={(event, TimeType, OtherTimeTypeValue) => this.onChangeRestTime(event, TimeType, OtherTimeTypeValue)}
                                Placeholder="0"
                                TimeValueMinutes={RestMinutes}
                                TimeValueSeconds={RestSeconds}
                                Type="time"
                            />
                        }
                    </DataContainer>
                </DataParentContainer>
            </LapDetailContainer>
        );
    }
}

ActiveLap.propTypes = {
    Active: PropTypes.number.isRequired,
    ActiveUnits: PropTypes.string,
    ActiveType: PropTypes.string.isRequired,
    Completed: PropTypes.bool,
    DataType: PropTypes.string,
    HasTestLaps: PropTypes.bool,
    OnUpdateWorkoutLap: PropTypes.func,
    ReadOnly: PropTypes.bool,
    Rest: PropTypes.number.isRequired,
    SetType: PropTypes.string.isRequired,
    Speed: PropTypes.number.isRequired,
    SpeedUnits: PropTypes.string.isRequired,
    WorkoutLapId: PropTypes.number
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device
    };
};

export default withTranslation()(connect(mapStateToProps, { ShowErrorModalWarning } )(ActiveLap));