import {
    TRYING_ADD_GET_STARTED_WORKOUTS_SUCCESS,

    TRYING_ADD_NEW_WORKOUT,
    TRYING_ADD_NEW_WORKOUT_SUCCESS,
    TRYING_ADD_NEW_WORKOUT_ERROR,

    TRYING_ADD_SINGLE_GENERATED_WORKOUT,
    TRYING_ADD_SINGLE_GENERATED_WORKOUT_SUCCESS,
    TRYING_ADD_SINGLE_GENERATED_WORKOUT_ERROR,

    TRYING_BUILD_WORKOUT_PLAN,
    TRYING_BUILD_WORKOUT_PLAN_SUCCESS,
    TRYING_BUILD_WORKOUT_PLAN_ERROR,

    TRYING_COPY_WORKOUTS,
    TRYING_COPY_WORKOUTS_SUCCESS,
    TRYING_COPY_WORKOUTS_ERROR,

    TRYING_DELETE_WORKOUTS,
    TRYING_DELETE_WORKOUTS_SUCCESS,
    TRYING_DELETE_WORKOUTS_ERROR,

    CLEAR_BUILD_WORKOUT_PLAN_DETAILS,
    TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS,
    TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS_SUCCESS,
    TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS_ERROR,

    CLEAR_CLIENT_DETAILS,
    TRYING_GET_CLIENT_WORKOUTS,
    TRYING_GET_CLIENT_WORKOUTS_SUCCESS,
    TRYING_GET_CLIENT_WORKOUTS_ERROR,

    CLEAR_SINGLE_GENERATED_WORKOUT_DETAILS,
    TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS,
    TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS_SUCCESS,
    TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS_ERROR,

    TRYING_GET_WORKOUT_PREVIEW,
    TRYING_GET_WORKOUT_PREVIEW_SUCCESS,
    TRYING_GET_WORKOUT_PREVIEW_ERROR,

    TRYING_UPDATE_WORKOUT_ACCESS,
    TRYING_UPDATE_WORKOUT_ACCESS_SUCCESS,
    TRYING_UPDATE_WORKOUT_ACCESS_ERROR,

    TRYING_MOVE_WORKOUTS,
    TRYING_MOVE_WORKOUTS_SUCCESS,
    TRYING_MOVE_WORKOUTS_ERROR,
} from '../Types';

const INITIAL_STATE = {
    BuildWorkoutPlanDetails: {},
    ClientWorkouts: [],
    GetStarted_NeedsHeight: false,
    GetStarted_NeedsWeight: false,
    ShowGetStarted: false,
    SingleGeneratedDetails: {},
    WorkoutAccess: false,
    
    TryingAddNewWorkout: false,
    TryingAddNewWorkoutError: null,
    TryingAddSingleGeneratedWorkout: false,
    TryingAddSingleGeneratedWorkoutError: null,
    TryingBuildWorkoutPlan: false,
    TryingBuildWorkoutPlanError: null,
    TryingCopyWorkouts: false,
    TryingCopyWorkoutsError: null,
    TryingDeleteWorkouts: false,
    TryingDeleteWorkoutsError: null,
    TryingGetBuildWorkoutPlanDetails: false,
    TryingGetBuildWorkoutPlanDetailsError: null,
    TryingGetClientWorkouts: false,
    TryingGetClientWorkoutsError: null,
    TryingGetSingleGeneratedWorkoutDetails: false,
    TryingGetSingleGeneratedWorkoutDetailsError: null,
    TryingGetWorkoutPreview: false,
    TryingGetWorkoutPreviewError: null,
    TryingGiveWorkoutAccess: false,
    TryingGiveWorkoutAccessError: null,
    TryingMoveWorkouts: false,
    TryingMoveWorkoutsError: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_ADD_GET_STARTED_WORKOUTS_SUCCESS:
            return { ...state, ShowGetStarted: false };

        case TRYING_ADD_NEW_WORKOUT:
            return { ...state, TryingAddNewWorkout: true, TryingAddNewWorkoutError: null };
        case TRYING_ADD_NEW_WORKOUT_ERROR:
            return { ...state, TryingAddNewWorkout: false, TryingAddNewWorkoutError: action.payload };
        case TRYING_ADD_NEW_WORKOUT_SUCCESS:
            return { ...state, TryingAddNewWorkout: false, TryingAddNewWorkoutError: null };

        case TRYING_ADD_SINGLE_GENERATED_WORKOUT:
            return { ...state, TryingAddSingleGeneratedWorkout: true, TryingAddSingleGeneratedWorkoutError: null };
        case TRYING_ADD_SINGLE_GENERATED_WORKOUT_ERROR:
            return { ...state, TryingAddSingleGeneratedWorkout: false, TryingAddSingleGeneratedWorkoutError: action.payload };
        case TRYING_ADD_SINGLE_GENERATED_WORKOUT_SUCCESS:
            return { ...state, TryingAddSingleGeneratedWorkout: false, TryingAddSingleGeneratedWorkoutError: null };

        case TRYING_BUILD_WORKOUT_PLAN:
            return { ...state, TryingBuildWorkoutPlan: true, TryingBuildWorkoutPlanError: null };
        case TRYING_BUILD_WORKOUT_PLAN_ERROR:
            return { ...state, TryingBuildWorkoutPlan: false, TryingBuildWorkoutPlanError: action.payload };
        case TRYING_BUILD_WORKOUT_PLAN_SUCCESS:
            return { ...state, TryingBuildWorkoutPlan: false, TryingBuildWorkoutPlanError: null };

        case TRYING_COPY_WORKOUTS:
            return { ...state, TryingCopyWorkouts: true, TryingCopyWorkoutsError: null };
        case TRYING_COPY_WORKOUTS_ERROR:
            return { ...state, TryingCopyWorkouts: false, TryingCopyWorkoutsError: action.payload };
        case TRYING_COPY_WORKOUTS_SUCCESS:
            return { ...state, TryingCopyWorkouts: false, TryingCopyWorkoutsError: null };

        case TRYING_DELETE_WORKOUTS:
            return { ...state, TryingDeleteWorkouts: true, TryingDeleteWorkoutsError: null };
        case TRYING_DELETE_WORKOUTS_ERROR:
            return { ...state, TryingDeleteWorkouts: false, TryingDeleteWorkoutsError: action.payload };
        case TRYING_DELETE_WORKOUTS_SUCCESS:
            var DeletedWorkouts = action.payload;

            var AfterDeleteClientWorkouts = [];
            state.ClientWorkouts.map(Day => {
                Day.Workouts = Day.Workouts.filter(Workout => DeletedWorkouts.indexOf(Workout.WorkoutId) === -1);
                AfterDeleteClientWorkouts.push(Day);
                return Day;
            });

            return { ...state, ClientWorkouts: AfterDeleteClientWorkouts, TryingDeleteWorkouts: false, TryingDeleteWorkoutsError: null };

        case CLEAR_BUILD_WORKOUT_PLAN_DETAILS:
            return { ...state, BuildWorkoutPlanDetails: {} };
        case TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS:
            return { ...state, TryingGetBuildWorkoutPlanDetails: true, TryingGetBuildWorkoutPlanDetailsError: null };
        case TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS_ERROR:
            return { ...state, TryingGetBuildWorkoutPlanDetails: false, TryingGetBuildWorkoutPlanDetailsError: action.payload };
        case TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS_SUCCESS:
            return { ...state, BuildWorkoutPlanDetails: action.payload, TryingGetBuildWorkoutPlanDetails: false, TryingGetBuildWorkoutPlanDetailsError: null };

        case CLEAR_CLIENT_DETAILS:
            return { ...state, ClientWorkouts: [], GetStarted_NeedsHeight: false, GetStarted_NeedsWeight: false, ShowGetStarted: false };
        case TRYING_GET_CLIENT_WORKOUTS:
            return { ...state, TryingGetClientWorkouts: true, TryingGetClientWorkoutsError: null };
        case TRYING_GET_CLIENT_WORKOUTS_ERROR:
            return { ...state, TryingGetClientWorkouts: false, TryingGetClientWorkoutsError: action.payload };
        case TRYING_GET_CLIENT_WORKOUTS_SUCCESS:
            var { Workouts, NeedsHeight, NeedsWeight, ShowGetStarted, WorkoutAccess } = action.payload;

            return { ...state, ClientWorkouts: Workouts || [], GetStarted_NeedsHeight: NeedsHeight, GetStarted_NeedsWeight: NeedsWeight, ShowGetStarted: !!ShowGetStarted, WorkoutAccess: !!WorkoutAccess, TryingGetClientWorkouts: false, TryingGetClientWorkoutsError: null };

        case CLEAR_SINGLE_GENERATED_WORKOUT_DETAILS:
            return { ...state, SingleGeneratedDetails: {} };
        case TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS:
            return { ...state, TryingGetSingleGeneratedWorkoutDetails: true, TryingGetSingleGeneratedWorkoutDetailsError: null };
        case TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS_ERROR:
            return { ...state, TryingGetSingleGeneratedWorkoutDetails: false, TryingGetSingleGeneratedWorkoutDetailsError: action.payload };
        case TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS_SUCCESS:
            var SingleGeneratedDetails = action.payload;

            return { ...state, SingleGeneratedDetails, TryingGetSingleGeneratedWorkoutDetails: false, TryingGetSingleGeneratedWorkoutDetailsError: null };

        case TRYING_GET_WORKOUT_PREVIEW:
            return { ...state, TryingGetWorkoutPreview: true, TryingGetWorkoutPreviewError: null };
        case TRYING_GET_WORKOUT_PREVIEW_ERROR:
            return { ...state, TryingGetWorkoutPreview: false, TryingGetWorkoutPreviewError: action.payload };
        case TRYING_GET_WORKOUT_PREVIEW_SUCCESS:
            return { ...state, TryingGetWorkoutPreview: false, TryingGetWorkoutPreviewError: null };

        case TRYING_UPDATE_WORKOUT_ACCESS:
            if (action.payload.ReturnDataType !== 'ClientDetail') return { ...state };
            return { ...state, TryingGiveWorkoutAccess: true, TryingGiveWorkoutAccessError: null };
        case TRYING_UPDATE_WORKOUT_ACCESS_ERROR:
            if (action.payload.ReturnDataType !== 'ClientDetail') return { ...state };
            return { ...state, TryingGiveWorkoutAccess: false, TryingGiveWorkoutAccessError: action.payload.ErrorMessage };
        case TRYING_UPDATE_WORKOUT_ACCESS_SUCCESS:
            if (action.payload.ReturnDataType !== 'ClientDetail') return { ...state };
            // eslint-disable-next-line
            var { Workouts, NeedsHeight, NeedsWeight, ShowGetStarted, WorkoutAccess } = action.payload;

            return { ...state, ClientWorkouts: Workouts || [], GetStarted_NeedsHeight: NeedsHeight, GetStarted_NeedsWeight: NeedsWeight, ShowGetStarted: !!ShowGetStarted, WorkoutAccess: !!WorkoutAccess, TryingGiveWorkoutAccess: false, TryingGiveWorkoutAccessError: null };

        case TRYING_MOVE_WORKOUTS:
            return { ...state, TryingMoveWorkouts: true, TryingMoveWorkoutsError: null };
        case TRYING_MOVE_WORKOUTS_ERROR:
            return { ...state, TryingMoveWorkouts: false, TryingMoveWorkoutsError: action.payload };
        case TRYING_MOVE_WORKOUTS_SUCCESS:
            var { IsBetween, WorkoutIds } = action.payload;

            var AfterMoveWorkouts = [];

            // If the date the workouts were moved to are not in currently weekly view, then delete workouts from the week
            if (!IsBetween) {
                state.ClientWorkouts.map(Day => {
                    Day.Workouts = Day.Workouts.filter(Workout => WorkoutIds.indexOf(Workout.WorkoutId) === -1);
                    AfterMoveWorkouts.push(Day);
                    return Day;
                });
            } else AfterMoveWorkouts = state.ClientWorkouts;

            return { ...state, ClientWorkouts: AfterMoveWorkouts, TryingMoveWorkouts: false, TryingMoveWorkoutsError: null };

        default:
            return state;
    }
};
