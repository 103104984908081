import styled from 'styled-components';
import { device } from '../../../Config/device';

import Image from '../../../Components/Image';

export const HowToTagModalImage = styled(Image)`
    max-height: 60px;
    max-width: 100%;
`;

export const LibraryContainer = styled.div`
    border-radius: 10px;
    display: grid;

    ${
        props => !props.ShowCustomizeFilters ?
        `
            @media ${device.minwidth.laptop} {
                grid-template-areas: "header filter"
                                    "body filter";
                grid-template-columns: 1fr 300px;
            }

            @media ${device.maxwidth.ipad} {
                grid-template-areas: "header" "body";
                grid-template-columns: 1fr;
            }
        `
    :
        `
            grid-template-areas: "header" "body";
            grid-template-columns: 1fr;
        `
    }

    grid-template-rows: 53px 1fr;
    overflow: hidden;
    width: 100%;
`;