import moment from 'moment';
import {
    TRYING_COPY_GROUP_WORKOUTS,
    TRYING_COPY_GROUP_WORKOUTS_SUCCESS,
    TRYING_COPY_GROUP_WORKOUTS_ERROR,

    TRYING_DELETE_GROUP_WORKOUTS,
    TRYING_DELETE_GROUP_WORKOUTS_SUCCESS,
    TRYING_DELETE_GROUP_WORKOUTS_ERROR,

    CLEAR_ADD_EDIT_GROUP_SETTINGS,
    TRYING_GET_ADD_EDIT_GROUP_SETTINGS,
    TRYING_GET_ADD_EDIT_GROUP_SETTINGS_SUCCESS,
    TRYING_GET_ADD_EDIT_GROUP_SETTINGS_ERROR,

    CLEAR_GROUP_DETAILS,
    TRYING_GET_GROUP_DETAILS,
    TRYING_GET_GROUP_DETAILS_SUCCESS,
    TRYING_GET_GROUP_DETAILS_ERROR,

    TRYING_GET_GROUP_WORKOUTS,
    TRYING_GET_GROUP_WORKOUTS_SUCCESS,
    TRYING_GET_GROUP_WORKOUTS_ERROR,

    TRYING_INSERTEDIT_USER_GROUP,
    TRYING_INSERTEDIT_USER_GROUP_SUCCESS,
    TRYING_INSERTEDIT_USER_GROUP_ERROR,

    TRYING_INSERTEDIT_USER_GROUP_MEMBERS,
    TRYING_INSERTEDIT_USER_GROUP_MEMBERS_SUCCESS,
    TRYING_INSERTEDIT_USER_GROUP_MEMBERS_ERROR,

    TRYING_MOVE_GROUP_WORKOUTS,
    TRYING_MOVE_GROUP_WORKOUTS_SUCCESS,
    TRYING_MOVE_GROUP_WORKOUTS_ERROR,

    TRYING_SEARCH_GROUP_MANAGERS,
    TRYING_SEARCH_GROUP_MANAGERS_SUCCESS,
    TRYING_SEARCH_GROUP_MANAGERS_ERROR,

    TRYING_SEARCH_GROUP_MEMBERS,
    TRYING_SEARCH_GROUP_MEMBERS_SUCCESS,
    TRYING_SEARCH_GROUP_MEMBERS_ERROR
} from '../Types';

const INITIAL_STATE = {
    ExistingMembers: [],
    GroupDetails: {},
    LastViewedGroupId: null,
    NewMembers: [],
    SelectedTab: null,
    GroupWorkouts: [],

    TryingCopyGroupWorkouts: false,
    TryingCopyGroupWorkoutsError: null,
    TryingDeleteGroupWorkouts: false,
    TryingDeleteGroupWorkoutsError: null,
    TryingGetAddEditGroupSettings: false,
    TryingGetAddEditGroupSettingsError: null,
    TryingGetGroupDetails: false,
    TryingGetGroupDetailsError: null,
    TryingGetGroupWorkouts: false,
    TryingGetGroupWorkoutsError: null,
    TryingInsertEditUserGroup: false,
    TryingInsertEditUserGroupError: null,
    TryingInsertEditUserGroupMembers: false,
    TryingInsertEditUserGroupMembersError: null,
    TryingMoveGroupWorkouts: false,
    TryingMoveGroupWorkoutsError: null,
    TryingSearchGroupManagers: false,
    TryingSearchGroupManagersError: null,
    TryingSearchGroupMembers: false,
    TryingSearchGroupMembersError: null
};

var INITIAL_WEEKLYVIEW_STATE = {
    WeeklyView_GroupWorkouts_Difference: 0,
    WeeklyView_GroupWorkouts_EndDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
    WeeklyView_GroupWorkouts_StartDate: moment().startOf('isoWeek').format('YYYY-MM-DD')
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_COPY_GROUP_WORKOUTS:
            return { ...state, TryingCopyGroupWorkouts: true, TryingCopyGroupWorkoutsError: null };
        case TRYING_COPY_GROUP_WORKOUTS_ERROR:
            return { ...state, TryingCopyGroupWorkouts: false, TryingCopyGroupWorkoutsError: action.payload };
        case TRYING_COPY_GROUP_WORKOUTS_SUCCESS:
            return { ...state, TryingCopyGroupWorkouts: false, TryingCopyGroupWorkoutsError: null };

        case TRYING_DELETE_GROUP_WORKOUTS:
            return { ...state, TryingDeleteGroupWorkouts: true, TryingDeleteGroupWorkoutsError: null };
        case TRYING_DELETE_GROUP_WORKOUTS_ERROR:
            return { ...state, TryingDeleteGroupWorkouts: false, TryingDeleteGroupWorkoutsError: action.payload };
        case TRYING_DELETE_GROUP_WORKOUTS_SUCCESS:
            var DeletedGroupWorkouts = action.payload;

            var AfterDeleteGroupWorkouts = [];
            state.GroupWorkouts.map(Day => {
                Day.Workouts = Day.Workouts.filter(Workout => DeletedGroupWorkouts.indexOf(Workout.GroupWorkoutId) === -1);
                AfterDeleteGroupWorkouts.push(Day);
                return Day;
            });

            return { ...state, GroupWorkouts: AfterDeleteGroupWorkouts, TryingDeleteGroupWorkouts: false, TryingDeleteGroupWorkoutsError: null };

        case CLEAR_ADD_EDIT_GROUP_SETTINGS:
            return { ...state, ExistingMembers: [], NewMembers: [] };
        case TRYING_GET_ADD_EDIT_GROUP_SETTINGS:
            return { ...state, TryingGetAddEditGroupSettings: true, TryingGetAddEditGroupSettingsError: null };
        case TRYING_GET_ADD_EDIT_GROUP_SETTINGS_ERROR:
            return { ...state, TryingGetAddEditGroupSettings: false, TryingGetAddEditGroupSettingsError: action.payload };
        case TRYING_GET_ADD_EDIT_GROUP_SETTINGS_SUCCESS:
            var { ExistingMembers, NewMembers } = action.payload;

            return { ...state, ExistingMembers, NewMembers, TryingGetAddEditGroupSettings: false, TryingGetAddEditGroupSettingsError: null };

        case CLEAR_GROUP_DETAILS:
            return { ...state, GroupDetails: {}, SelectedTab: null, GroupWorkouts: [] };
        case TRYING_GET_GROUP_DETAILS:
            return { ...state, ...state.LastViewedGroupId === action.payload ? {} : { ...INITIAL_WEEKLYVIEW_STATE }, LastViewedGroupId: action.payload, TryingGetGroupDetails: true, TryingGetGroupDetailsError: null };
        case TRYING_GET_GROUP_DETAILS_ERROR:
            return { ...state, TryingGetGroupDetails: false, TryingGetGroupDetailsError: action.payload };
        case TRYING_GET_GROUP_DETAILS_SUCCESS:
            return { ...state, GroupDetails: { ...action.payload.GroupDetails }, TryingGetGroupDetails: false, TryingGetGroupDetailsError: null };

        case TRYING_GET_GROUP_WORKOUTS:
            var { EndDate, StartDate, WeeklyDifference } = action.payload;

            return { ...state, TryingGetGroupWorkouts: true, TryingGetGroupWorkoutsError: null, WeeklyView_GroupWorkouts_Difference: WeeklyDifference, WeeklyView_GroupWorkouts_EndDate: EndDate, WeeklyView_GroupWorkouts_StartDate: StartDate };
        case TRYING_GET_GROUP_WORKOUTS_ERROR:
            return { ...state, TryingGetGroupWorkouts: false, TryingGetGroupWorkoutsError: action.payload };
        case TRYING_GET_GROUP_WORKOUTS_SUCCESS:
            var { Workouts } = action.payload;

            return { ...state, GroupWorkouts: Workouts || [], TryingGetGroupWorkouts: false, TryingGetGroupWorkoutsError: null };

        case TRYING_INSERTEDIT_USER_GROUP:
            return { ...state, TryingInsertEditUserGroup: true, TryingInsertEditUserGroupError: null };
        case TRYING_INSERTEDIT_USER_GROUP_ERROR:
            return { ...state, TryingInsertEditUserGroup: false, TryingInsertEditUserGroupError: action.payload.ErrorMessage };
        case TRYING_INSERTEDIT_USER_GROUP_SUCCESS:
            return { ...state, TryingInsertEditUserGroup: false, TryingInsertEditUserGroupError: null };

        case TRYING_INSERTEDIT_USER_GROUP_MEMBERS:
            return { ...state, TryingInsertEditUserGroupMembers: true, TryingInsertEditUserGroupMembersError: null };
        case TRYING_INSERTEDIT_USER_GROUP_MEMBERS_ERROR:
            return { ...state, TryingInsertEditUserGroupMembers: false, TryingInsertEditUserGroupMembersError: action.payload };
        case TRYING_INSERTEDIT_USER_GROUP_MEMBERS_SUCCESS:
            return { ...state, TryingInsertEditUserGroupMembers: false, TryingInsertEditUserGroupMembersError: null };

        case TRYING_MOVE_GROUP_WORKOUTS:
            return { ...state, TryingMoveGroupWorkouts: true, TryingMoveGroupWorkoutsError: null };
        case TRYING_MOVE_GROUP_WORKOUTS_ERROR:
            return { ...state, TryingMoveGroupWorkouts: false, TryingMoveGroupWorkoutsError: action.payload };
        case TRYING_MOVE_GROUP_WORKOUTS_SUCCESS:
            var { IsBetween, GroupWorkoutIds } = action.payload;

            var AfterMoveWorkouts = [];

            // If the date the workouts were moved to are not in currently weekly view, then delete workouts from the week
            if (!IsBetween) {
                state.GroupWorkouts.map(Day => {
                    Day.Workouts = Day.Workouts.filter(GroupWorkout => GroupWorkoutIds.indexOf(GroupWorkout.GroupWorkoutId) === -1);
                    AfterMoveWorkouts.push(Day);
                    return Day;
                });
            } else AfterMoveWorkouts = state.GroupWorkouts;

            return { ...state, GroupWorkouts: AfterMoveWorkouts, TryingMoveGroupWorkouts: false, TryingMoveGroupWorkoutsError: null };

        case TRYING_SEARCH_GROUP_MANAGERS:
            return { ...state, TryingSearchGroupManagers: true, TryingSearchGroupManagersError: null };
        case TRYING_SEARCH_GROUP_MANAGERS_ERROR:
            return { ...state, TryingSearchGroupManagers: false, TryingSearchGroupManagersError: action.payload };
        case TRYING_SEARCH_GROUP_MANAGERS_SUCCESS:
            return { ...state, TryingSearchGroupManagers: false, TryingSearchGroupManagersError: null };

        case TRYING_SEARCH_GROUP_MEMBERS:
            return { ...state, TryingSearchGroupMembers: true, TryingSearchGroupMembersError: null };
        case TRYING_SEARCH_GROUP_MEMBERS_ERROR:
            return { ...state, TryingSearchGroupMembers: false, TryingSearchGroupMembersError: action.payload };
        case TRYING_SEARCH_GROUP_MEMBERS_SUCCESS:
            return { ...state, TryingSearchGroupMembers: false, TryingSearchGroupMembersError: null };

        default:
            return state;
    }
};
