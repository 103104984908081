import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddBodyMeasurements_BodyFat, AddBodyMeasurements_BodyWeight, AddBodyMeasurements_Caliper, AddBodyMeasurements_Circumference, AddBodyMeasurements_MuscleMass, AddBodyMeasurements_Pain, AddBodyMeasurements_RPE, AddBodyMeasurements_Sleep, CalculateBodyFat, GetAddMeasurementDetails } from '../../Services/Actions';

import DateTime from 'react-datetime';
import moment from 'moment';

import Languages from '../../Config/languages';

import  { BackNextButtons, InputContainer, MultiLineButtonTitle, QuestionContainer, StyledInput, StyledMeasurementTypeButtons, Toggle, ToggleContainer } from './styles';

import AbelModal from '../../Components/Modal';
import FlexWrap from '../../Components/Lists/FlexWrap';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

class AddMeasurements extends React.Component {
    _isMounted = false;

    state = {
        AddMeasurementDetails: {},
        DateFormat: 'MM-DD-YYYY',
        Loading: false,
        MeasurementType: null,
        TimeFormat: 'hh:mm a',
        TrackingId: null
    };

    componentDidMount() {
        this._isMounted = true;

        var { TrackingId } = this.props.TrackingType;
        var { LanguageId } = this.props.UserDetails;

        this.setState({
            AddMeasurementDetails: { [TrackingId]: { ...this.props.TrackingType } }, MeasurementType: TrackingId, TrackingId,
            DateFormat: Languages[`${LanguageId}`] ? Languages[`${LanguageId}`].longDateFormat["[NumbersDate]"] : Languages[`1`].longDateFormat["[NumbersDate]"],
            TimeFormat: Languages[`${LanguageId}`] ? Languages[`${LanguageId}`].longDateFormat["[TrackingHistoryTime]"] : Languages[`1`].longDateFormat["[TrackingHistoryTime]"]
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onAddBodyMeasurements = () => {
        var { AddMeasurementDetails, MeasurementType, TrackingId } = this.state;
        var { CaliperType, ChartEndDate, ChartStartDate, Current, GoalMax, GoalMin, HasGoals, MeasurementDate, SelectedBodyAreas, SelectedBodyAreasIndex, TrackingHistoryId, TrackingPageNo, TrackingPageSize } = AddMeasurementDetails[MeasurementType];
        var { ClientId } = this.props;

        var GetDashboardData = !TrackingId;

        if (!HasGoals) { GoalMax = 0; GoalMin = 0; }

        if (MeasurementDate) MeasurementDate = moment(MeasurementDate).format();

        if (MeasurementType === 'bodyfat') this.props.AddBodyMeasurements_BodyFat({ BodyGoalTypeId: 5, ChartEndDate, ChartStartDate, ClientId, GetDashboardData, GoalMaxMeasurement: GoalMax, GoalMinMeasurement: GoalMin, Measurement: Current, MeasurementDate, TrackingPageNo, TrackingPageSize });
        else if (MeasurementType === 'bodyweight') this.props.AddBodyMeasurements_BodyWeight({ ChartEndDate, ChartStartDate, ClientId, GetDashboardData, GoalMaxMeasurement: GoalMax, GoalMinMeasurement: GoalMin, Measurement: Current, MeasurementDate, TrackingPageNo, TrackingPageSize });
        else if (MeasurementType === 'caliper') {
            // eslint-disable-next-line
            var { GoalMax, GoalMin, HasGoals } = this.state.AddMeasurementDetails.caliper[CaliperType === 'caliper3' ? 'ThreePoint' : 'SevenPoint'];

            if (!HasGoals) { GoalMax = 0; GoalMin = 0; }

            this.props.AddBodyMeasurements_Caliper({ BodyGoalTypeId: CaliperType === 'caliper3' ? 3 : 4, Calipers: SelectedBodyAreas, ChartEndDate, ChartStartDate, ClientId, GetDashboardData, GoalMaxMeasurement: GoalMax, GoalMinMeasurement: GoalMin, MeasurementDate, TrackingHistoryId, TrackingPageNo, TrackingPageSize });
        }
        else if (MeasurementType === 'circumference') {
            if (!!TrackingId) var BodyAreaId = SelectedBodyAreasIndex[0];

            SelectedBodyAreas = SelectedBodyAreas.map(BodyArea => ({ ...BodyArea, MeasurementDate: moment(BodyArea.MeasurementDate).format() }));

            this.props.AddBodyMeasurements_Circumference({ BodyAreaId, Circumferences: SelectedBodyAreas, ChartEndDate, ChartStartDate, ClientId, GetDashboardData, TrackingPageNo, TrackingPageSize });
        }
        else if (MeasurementType === 'musclemass') this.props.AddBodyMeasurements_MuscleMass({ ChartEndDate, ChartStartDate, ClientId, GetDashboardData, GoalMaxMeasurement: GoalMax, GoalMinMeasurement: GoalMin, Measurement: Current, MeasurementDate, TrackingPageNo, TrackingPageSize });
        else if (MeasurementType === 'pain') this.props.AddBodyMeasurements_Pain({ ChartEndDate, ChartStartDate, ClientId, GetDashboardData, GoalMaxMeasurement: GoalMax, GoalMinMeasurement: GoalMin, Measurement: Current, MeasurementDate, TrackingPageNo, TrackingPageSize });
        else if (MeasurementType === 'rpe') this.props.AddBodyMeasurements_RPE({ ChartEndDate, ChartStartDate, ClientId, GetDashboardData, GoalMaxMeasurement: GoalMax, GoalMinMeasurement: GoalMin, Measurement: Current, MeasurementDate, TrackingPageNo, TrackingPageSize });
        else if (MeasurementType === 'sleep') this.props.AddBodyMeasurements_Sleep({ ChartEndDate, ChartStartDate, ClientId, GetDashboardData, GoalMaxMeasurement: GoalMax, GoalMinMeasurement: GoalMin, Measurement: Current, MeasurementDate, TrackingPageNo, TrackingPageSize });

        this.props.OnAddMeasurement()
    }

    onBlurInput = ({ event, CaliperType, Key, KeyIndex, MeasurementType }) => {
        if (+event.target.value === 0) event.target.value = 0;

        if (MeasurementType === 'circumference') {
            // eslint-disable-next-line
            var SelectedBodyAreas = [ ...this.state.AddMeasurementDetails[MeasurementType].SelectedBodyAreas ];
            SelectedBodyAreas[KeyIndex] = { ...SelectedBodyAreas[KeyIndex], [Key]: event.target.value };

            this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], SelectedBodyAreas } } });
        }
        else if (MeasurementType === 'caliper') {
            if (CaliperType) this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], [CaliperType]: { ...this.state.AddMeasurementDetails[MeasurementType][CaliperType], [Key]: event.target.value } } } });
            else {
                // eslint-disable-next-line
                var SelectedBodyAreas = [ ...this.state.AddMeasurementDetails[MeasurementType].SelectedBodyAreas ];
                SelectedBodyAreas[KeyIndex] = { ...SelectedBodyAreas[KeyIndex], [Key]: event.target.value };

                // Reset Calculated Body Fat As Inputs Have Changed
                    this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], CalculatedBodyFat: null, SelectedBodyAreas } } });
            }
        }
        else this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], [Key]: event.target.value } } });
    }

    onCalculateBodyFat = () => {
        var { CaliperType, SelectedBodyAreas } = this.state.AddMeasurementDetails.caliper;

        var BodyGoalTypeId = CaliperType === 'caliper3' ? 3 : 4;
        var Measurements = SelectedBodyAreas.map(({ BodyAreaId, Current }) => ({ BodyAreaId, Measurement: Current }));

        this.props.CalculateBodyFat({ BodyGoalTypeId, Measurements }).then(({ BodyFat }) => {
            this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, caliper: { ...this.state.AddMeasurementDetails.caliper, CalculatedBodyFat: BodyFat } } });
        })
    }

    onChangeInput = ({ event, CaliperType, Key, KeyIndex, MeasurementType }) => {
        if (MeasurementType === 'circumference') {
            // eslint-disable-next-line
            var SelectedBodyAreas = [ ...this.state.AddMeasurementDetails[MeasurementType].SelectedBodyAreas ];
            SelectedBodyAreas[KeyIndex] = { ...SelectedBodyAreas[KeyIndex], [Key]: event.target.value };

            this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], SelectedBodyAreas } } });
        }
        else if (MeasurementType === 'caliper') {
            if (CaliperType) this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], [CaliperType]: { ...this.state.AddMeasurementDetails[MeasurementType][CaliperType], [Key]: event.target.value } } } });
            else {
                // eslint-disable-next-line
                var SelectedBodyAreas = [ ...this.state.AddMeasurementDetails[MeasurementType].SelectedBodyAreas ];
                SelectedBodyAreas[KeyIndex] = { ...SelectedBodyAreas[KeyIndex], [Key]: event.target.value };

                // Reset Calculated Body Fat As Inputs Have Changed
                    this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], CalculatedBodyFat: null, SelectedBodyAreas } } });
            }
        }
        else this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], [Key]: event.target.value } } });
    }

    onChangeMeasurementDate = ({ KeyIndex, MeasurementDate, MeasurementType }) => {
        if (MeasurementType === 'circumference') {
            // eslint-disable-next-line
            var SelectedBodyAreas = [ ...this.state.AddMeasurementDetails[MeasurementType].SelectedBodyAreas ];
            SelectedBodyAreas[KeyIndex] = { ...SelectedBodyAreas[KeyIndex], MeasurementDate };

            this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], SelectedBodyAreas } } });
        }
        else this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], MeasurementDate } } });
    }

    onChangeScreenNumber({ Direction, CanGoNext, LastScreen }) {
        var { AddMeasurementDetails, MeasurementType } = this.state;

        if (MeasurementType === 'bodyscan' && Direction === -1) this.setState({ MeasurementType: null });
        else {
            var { ScreenNumber } = AddMeasurementDetails[MeasurementType];

            ScreenNumber = Direction === 1 ? ScreenNumber + 1 : ScreenNumber - 1;

            if (ScreenNumber <= 0 && (MeasurementType === 'bodyfat' || MeasurementType === 'musclemass')) this.setState({ MeasurementType: 'bodyscan' });
            else if (ScreenNumber <= 0) this.setState({ MeasurementType: null });
            else {
                // BODY WEIGHT
                    if (MeasurementType === 'bodyweight') {
                        if (Direction === 1 && CanGoNext && LastScreen) this.onAddBodyMeasurements();
                        else if (Direction === 1 && CanGoNext) this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], ScreenNumber }} });
                        else if (Direction === -1) this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], ScreenNumber }} });
                    }
                // MUSCLE MASS
                    else if (MeasurementType === 'musclemass') {
                        if (Direction === 1 && CanGoNext && LastScreen) this.onAddBodyMeasurements();
                        else if (Direction === 1 && CanGoNext) this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], ScreenNumber }} });
                        else if (Direction === -1) this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], ScreenNumber }} });
                    }
                // BODY FAT
                    else if (MeasurementType === 'bodyfat') {
                        if (Direction === 1 && CanGoNext && LastScreen) this.onAddBodyMeasurements();
                        else if (Direction === 1 && CanGoNext) this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], ScreenNumber }} });
                        else if (Direction === -1) this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], ScreenNumber }} });
                    }
                // CIRCUMFERENCE
                    else if (MeasurementType === 'circumference') {
                        if (Direction === 1 && CanGoNext && LastScreen) this.onAddBodyMeasurements();
                        else if (Direction === 1 && CanGoNext) this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], ScreenNumber }} });
                        else if (Direction === -1) this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], ScreenNumber }} });
                    }
                // CALIPER
                    else if (MeasurementType === 'caliper') {
                        if (Direction === 1 && CanGoNext && LastScreen) this.onAddBodyMeasurements();
                        else if (Direction === 1 && CanGoNext) {
                            var { CalculatedBodyFat, SelectedBodyAreas } = AddMeasurementDetails[MeasurementType];

                            // Calculate Body Fat Through API If At The End Of Body Area Measurements
                                if (!CalculatedBodyFat && ((ScreenNumber === 5 && SelectedBodyAreas.length === 3) || (ScreenNumber === 9 && SelectedBodyAreas.length === 7))) this.onCalculateBodyFat();

                            this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], ScreenNumber }} });
                        }
                        else if (Direction === -1) this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], ScreenNumber }} });
                    }
                // PAIN
                    else if (MeasurementType === 'pain') {
                        if (Direction === 1 && CanGoNext && LastScreen) this.onAddBodyMeasurements();
                        else if (Direction === 1 && CanGoNext) this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], ScreenNumber }} });
                        else if (Direction === -1) this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], ScreenNumber }} });
                    }
                // RPE
                    else if (MeasurementType === 'rpe') {
                        if (Direction === 1 && CanGoNext && LastScreen) this.onAddBodyMeasurements();
                        else if (Direction === 1 && CanGoNext) this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], ScreenNumber }} });
                        else if (Direction === -1) this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], ScreenNumber }} });
                    }
                // SLEEP
                    else if (MeasurementType === 'sleep') {
                        if (Direction === 1 && CanGoNext && LastScreen) this.onAddBodyMeasurements();
                        else if (Direction === 1 && CanGoNext) this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], ScreenNumber }} });
                        else if (Direction === -1) this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], ScreenNumber }} });
                    }
            }
        }
    }

    onGetAddMeasurementDetails = () => {
        var { MeasurementType } = this.state;
        var { ClientId } = this.props;

        if (MeasurementType === 'bodyfat' || MeasurementType === 'musclemass') MeasurementType = 'bodyscan';

        var MeasurementDate = moment().format();

        this.props.GetAddMeasurementDetails({ ClientId, MeasurementType }).then(({ AddMeasurementDetails }) => {
            if (MeasurementType === 'bodyscan') this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, bodyfat: { ...AddMeasurementDetails.BodyFat, HasGoals: 1, MeasurementDate, ScreenNumber: 1 }, musclemass: { ...AddMeasurementDetails.MuscleMass, HasGoals: 1, MeasurementDate, ScreenNumber: 1 } } });
            else if (MeasurementType === 'circumference') this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, circumference: { BodyAreas: AddMeasurementDetails.map(BodyArea => ({ ...BodyArea, HasGoals: 1 })), MeasurementDate, ScreenNumber: 1, SelectedBodyAreas: [], SelectedBodyAreasIndex: [] } } });
            else if (MeasurementType === 'caliper') this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, caliper: { BodyAreas: AddMeasurementDetails.Current, CalculatedBodyFat: null, CaliperType: null, MeasurementDate, ScreenNumber: 1, SelectedBodyAreas: [], SevenPoint: { ...AddMeasurementDetails.SevenPoint, HasGoals: 1 }, ThreePoint: { ...AddMeasurementDetails.ThreePoint, HasGoals: 1 } } } });
            else this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...AddMeasurementDetails, HasGoals: 1, MeasurementDate, ScreenNumber: 1 } }});
        });
    }

    onSelectCaliperType = ({ BodyAreas, CaliperType }) => {
        var SelectedBodyAreas = BodyAreas.filter(BodyArea => ((CaliperType === 'caliper3' && BodyArea.ThreePoint) || (CaliperType === 'caliper7' && BodyArea.SevenPoint)));

        this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, caliper: { ...this.state.AddMeasurementDetails.caliper, CaliperType, SelectedBodyAreas }} });
    }

    onSelectCircumferenceBodyArea = (Item, ItemIndex) => {
        var SelectedBodyAreas = [ ...this.state.AddMeasurementDetails.circumference.SelectedBodyAreas ];
        var SelectedBodyAreasIndex = [ ...this.state.AddMeasurementDetails.circumference.SelectedBodyAreasIndex ];
        
        if (ItemIndex === -1) {
            SelectedBodyAreas = [ ...SelectedBodyAreas, Item ];
            SelectedBodyAreasIndex = [ ...SelectedBodyAreasIndex, +Item.BodyAreaId ];
        } else {
            SelectedBodyAreas = [ ...SelectedBodyAreas.slice(0, ItemIndex), ...SelectedBodyAreas.slice(ItemIndex + 1) ];
            SelectedBodyAreasIndex = [ ...SelectedBodyAreasIndex.slice(0, ItemIndex), ...SelectedBodyAreasIndex.slice(ItemIndex + 1) ];
        }

        this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, circumference: { ...this.state.AddMeasurementDetails.circumference, SelectedBodyAreas, SelectedBodyAreasIndex } } });
    }

    onSelectMeasurementType = MeasurementType => {
        this.setState({ MeasurementType }, () => {
            // BodyFat && Muscle Mass use "body scan" in API, but "bodyfat" and "musclemass" here, so need to check state with these values
                var CheckingMeasurementType = MeasurementType === 'bodyscan' ? 'musclemass' : MeasurementType;

            if ([ 'bodyweight', 'circumference', 'caliper', 'bodyscan', 'pain', 'rpe', 'sleep' ].includes(MeasurementType) && !this.state.AddMeasurementDetails.hasOwnProperty(CheckingMeasurementType)) {
                this.onGetAddMeasurementDetails();
            }
        });
    }

    onToggleHasGoals = ({ CaliperType, KeyIndex, MeasurementType }) => {
        if (MeasurementType === 'circumference') {
            // eslint-disable-next-line
            var SelectedBodyAreas = [ ...this.state.AddMeasurementDetails[MeasurementType].SelectedBodyAreas ];
            SelectedBodyAreas[KeyIndex] = { ...SelectedBodyAreas[KeyIndex], HasGoals: !this.state.AddMeasurementDetails[MeasurementType].SelectedBodyAreas[KeyIndex].HasGoals };

            this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], SelectedBodyAreas } } });
        }
        else if (MeasurementType === 'caliper') {
            this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], [CaliperType]: { ...this.state.AddMeasurementDetails[MeasurementType][CaliperType], HasGoals: !this.state.AddMeasurementDetails[MeasurementType][CaliperType].HasGoals } } } });
        }
        else this.setState({ AddMeasurementDetails: { ...this.state.AddMeasurementDetails, [MeasurementType]: { ...this.state.AddMeasurementDetails[MeasurementType], HasGoals: !this.state.AddMeasurementDetails[MeasurementType].HasGoals } } });
    }

    renderBodyFatQuestions = () => {
        var { t } = this.props;
        var { AddMeasurementDetails, MeasurementType } = this.state;

        if (!AddMeasurementDetails.hasOwnProperty('bodyfat')) return null;

        var { Current, GoalMax, GoalMin, HasGoals, MeasurementDate, ScreenNumber, Units } = AddMeasurementDetails.bodyfat;

        // Ask for current weight
            if (ScreenNumber === 1) {
                var { DateFormat, TimeFormat } = this.state;

                return (
                    <QuestionContainer className="QuestionContainer">
                        <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('Progress_Current_BodyFat')} TextAlign="center" />

                        <Spacer Size="small" />

                        <InputContainer className="InputContainer">
                            <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Current_Measurements_By_Default')} TextAlign="center" />

                            <Spacer Size="small" />

                            <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('current')} ${t('body_fat').toLowerCase()} (${Units})`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'Current', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'Current', MeasurementType })}
                                Placeholder="0"
                                Size="auto"
                                Type="number"
                                Value={`${Current}`}
                            />
                        </InputContainer>

                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('measurement_date')} TextAlign="center" />

                        <Spacer Size="extra-extra-small" />

                        <DateTime
                            dateFormat={DateFormat}
                            timeFormat={TimeFormat}
                            onChange={MeasurementDate => this.onChangeMeasurementDate({ MeasurementDate, MeasurementType })}
                            value={moment(MeasurementDate)}
                        />
                    </QuestionContainer>
                );
            }
        
        // Ask for goal weight
            else if (ScreenNumber === 2) {
                return (
                    <QuestionContainer className="QuestionContainer">
                        <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('Progress_Goal_BodyArea', { BodyArea: t('body_fat').toLowerCase() })} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Goal_Description')} TextAlign="center" />

                        <Spacer Size="small" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Current_Measurements_By_Default')} TextAlign="center" />

                        <Spacer Size="small" />

                        <ToggleContainer>
                            <Toggle onClick={() => this.onToggleHasGoals({ MeasurementType })} Selected={HasGoals} />
                            <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={() => this.onToggleHasGoals({ MeasurementType })} Text={t('Progress_Goals_Toggle')} TextAlign="left" />
                        </ToggleContainer>

                        <Spacer Size="small" />

                        <InputContainer className="InputContainer">
                            <PageText FontColor={!HasGoals ? 'gray-silver' : null} FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('minimum')} ${t('body_fat').toLowerCase()} (${Units})`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontColor={!HasGoals ? 'gray-silver' : null} 
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'GoalMin', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'GoalMin', MeasurementType })}
                                Placeholder="0"
                                ReadOnly={!HasGoals}
                                Size="auto"
                                Type="number"
                                Value={`${HasGoals ? GoalMin : 0}`}
                            />
                        </InputContainer>

                        <Spacer Size="medium" />

                        <InputContainer className="InputContainer">
                            <PageText FontColor={!HasGoals ? 'gray-silver' : null} FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('maximum')} ${t('body_fat').toLowerCase()} (${Units})`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontColor={!HasGoals ? 'gray-silver' : null} 
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'GoalMax', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'GoalMax', MeasurementType })}
                                Placeholder="0"
                                ReadOnly={!HasGoals}
                                Size="auto"
                                Type="number"
                                Value={`${HasGoals ? GoalMax : 0}`}
                            />
                        </InputContainer>

                        <Spacer Size="medium" />

                        {
                            !!+HasGoals && !!+GoalMax && !!+GoalMin && +GoalMin >= +GoalMax && 
                            <>
                                <Spacer Size="small" />

                                <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Goal_MinMax_Validation')} TextAlign="center" />
                            </>
                        }
                    </QuestionContainer>
                );
            }

        return null;
    }

    renderBodyScanQuestions = () => {
        var { t } = this.props;
        var { MeasurementType } = this.state;

        var ButtonStart = { BackgroundColorHover: 'blue-astronaut', BackgroundColorSelected: 'blue-astronaut', ColorHover: 'white', ColorSelected: 'white' };
        var Buttons = [
            { ...ButtonStart, OnClick: () => this.onSelectMeasurementType('bodyfat'), Selected: MeasurementType === 'bodyfat', Title: t('body_fat') },
            { ...ButtonStart, OnClick: () => this.onSelectMeasurementType('musclemass'), Selected: MeasurementType === 'musclemass', Title: t('musclemass') },
        ];

        return <StyledMeasurementTypeButtons Buttons={Buttons} NormalWhiteSpace NotTouching OwnRows />;
    }

    renderBodyWeightQuestions = () => {
        var { t } = this.props;
        var { AddMeasurementDetails, DateFormat, MeasurementType, TimeFormat } = this.state;

        if (!AddMeasurementDetails.hasOwnProperty('bodyweight')) return null;

        var { Current, GoalMax, GoalMin, HasGoals, MeasurementDate, ScreenNumber, Units } = AddMeasurementDetails.bodyweight;

        // Ask for current weight
            if (ScreenNumber === 1) {
                return (
                    <QuestionContainer className="QuestionContainer">
                        <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('Progress_Current_BodyWeight')} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Current_Measurements_By_Default')} TextAlign="center" />

                        <Spacer Size="small" />

                        <InputContainer className="InputContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('current')} ${t('Weight').toLowerCase()} (${Units})`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'Current', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'Current', MeasurementType })}
                                Placeholder="0"
                                Size="auto"
                                Type="number"
                                Value={`${Current}`}
                            />
                        </InputContainer>

                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('measurement_date')} TextAlign="center" />

                        <Spacer Size="extra-extra-small" />

                        <DateTime
                            dateFormat={DateFormat}
                            timeFormat={TimeFormat}
                            onChange={MeasurementDate => this.onChangeMeasurementDate({ MeasurementDate, MeasurementType })}
                            value={moment(MeasurementDate)}
                        />
                    </QuestionContainer>
                );
            }
        
        // Ask for goal weight
            else if (ScreenNumber === 2) {
                return (
                    <QuestionContainer className="QuestionContainer">
                        <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('Progress_Goal_BodyWeight')} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Goal_Description')} TextAlign="center" />

                        <Spacer Size="small" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Current_Measurements_By_Default')} TextAlign="center" />

                        <Spacer Size="small" />

                        <ToggleContainer>
                            <Toggle onClick={() => this.onToggleHasGoals({ MeasurementType })} Selected={HasGoals} />
                            <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={() => this.onToggleHasGoals({ MeasurementType })} Text={t('Progress_Goals_Toggle')} TextAlign="left" />
                        </ToggleContainer>

                        <Spacer Size="small" />

                        <InputContainer className="InputContainer">
                            <PageText FontColor={!HasGoals ? 'gray-silver' : null} FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('minimum')} ${t('Weight').toLowerCase()} (${Units})`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontColor={!HasGoals ? 'gray-silver' : null}
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'GoalMin', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'GoalMin', MeasurementType })}
                                Placeholder="0"
                                ReadOnly={!HasGoals}
                                Size="auto"
                                Type="number"
                                Value={`${HasGoals ? GoalMin : 0}`}
                            />
                        </InputContainer>

                        <Spacer Size="medium" />

                        <InputContainer className="InputContainer">
                            <PageText FontColor={!HasGoals ? 'gray-silver' : null}  FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('maximum')} ${t('Weight').toLowerCase()} (${Units})`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontColor={!HasGoals ? 'gray-silver' : null}
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'GoalMax', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'GoalMax', MeasurementType })}
                                Placeholder="0"
                                ReadOnly={!HasGoals}
                                Size="auto"
                                Type="number"
                                Value={`${HasGoals ? GoalMax : 0}`}
                            />
                        </InputContainer>

                        <Spacer Size="medium" />

                        {
                            !!+HasGoals && !!+GoalMax && !!+GoalMin && +GoalMin >= +GoalMax && 
                            <>
                                <Spacer Size="small" />

                                <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Goal_MinMax_Validation')} TextAlign="center" />
                            </>
                        }
                    </QuestionContainer>
                );
            }

        return null;
    }

    renderCaliperMeasurementTypeSelection = ({ BodyAreas }) => {
        var { CaliperType } = this.state.AddMeasurementDetails.caliper;

        var ButtonStart = { BackgroundColorHover: 'blue-astronaut', BackgroundColorSelected: 'blue-astronaut', ColorHover: 'white', ColorSelected: 'white' };
        var Buttons = [
            { ...ButtonStart, OnClick: () => this.onSelectCaliperType({ BodyAreas, CaliperType: 'caliper3' }), Selected: CaliperType === 'caliper3', Title: this.renderCaliperSelectionTitle({ CaliperType: 'caliper3' }) },
            { ...ButtonStart, OnClick: () => this.onSelectCaliperType({ BodyAreas, CaliperType: 'caliper7' }), Selected: CaliperType === 'caliper7', Title: this.renderCaliperSelectionTitle({ CaliperType: 'caliper7' }) },
        ];

        return <StyledMeasurementTypeButtons Buttons={Buttons} NormalWhiteSpace NotTouching OwnRows />;
    }

    renderCaliperQuestions = () => {
        var { t } = this.props;
        var { AddMeasurementDetails, DateFormat, MeasurementType, TimeFormat } = this.state;

        if (!AddMeasurementDetails.hasOwnProperty('caliper')) return null;

        var { BodyAreas, CalculatedBodyFat, CaliperType, MeasurementDate, ScreenNumber, SelectedBodyAreas } = AddMeasurementDetails.caliper;
        
        if (ScreenNumber === 1) return this.renderCaliperMeasurementTypeSelection({ BodyAreas });

        // CaliperType = "caliper3" || "caliper7"

        var KeyIndex = ScreenNumber - 2;

        if ((ScreenNumber === 5 && SelectedBodyAreas.length === 3) || (ScreenNumber === 9 && SelectedBodyAreas.length === 7)) {
            var CalculatedBodyFatText = CalculatedBodyFat ? `${CalculatedBodyFat}%` : `${t('calculating')} ...`;

            return (
                <>
                    <QuestionContainer className="QuestionContainer">
                        <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('Progress_Calculated_BodyFat_Is')} TextAlign="center" />

                        <Spacer Size="small" />

                        <PageText FontFamily="bold" FontSize="large" NoMargin Text={CalculatedBodyFatText} TextAlign="center" />
                    </QuestionContainer>

                    <Spacer Size="small" />

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('measurement_date')} TextAlign="center" />

                    <Spacer Size="extra-extra-small" />

                    <DateTime
                        dateFormat={DateFormat}
                        timeFormat={TimeFormat}
                        onChange={MeasurementDate => this.onChangeMeasurementDate({ MeasurementDate, MeasurementType })}
                        value={moment(MeasurementDate)}
                    />
                </>
            );
        }

        if ((ScreenNumber === 6 && SelectedBodyAreas.length === 3) || (ScreenNumber === 10 && SelectedBodyAreas.length === 7)) {
            var ParamName = CaliperType === 'caliper3' ? 'ThreePoint' : 'SevenPoint';
            var { GoalMax, GoalMin, HasGoals, Units } = AddMeasurementDetails.caliper[ParamName];

            return (
                <QuestionContainer className="QuestionContainer">
                    <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('Progress_Goal_BodyArea', { BodyArea: t('body_fat').toLowerCase() })} TextAlign="center" />

                    <Spacer Size="extra-small" />

                    <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Goal_Description')} TextAlign="center" />

                    <Spacer Size="small" />

                    <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Current_Measurements_By_Default')} TextAlign="center" />

                    <Spacer Size="small" />

                    <ToggleContainer>
                        <Toggle onClick={() => this.onToggleHasGoals({ CaliperType: ParamName, KeyIndex, MeasurementType })} Selected={HasGoals} />
                        <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={() => this.onToggleHasGoals({ CaliperType: ParamName, KeyIndex, MeasurementType })} Text={t('Progress_Goals_Toggle')} TextAlign="left" />
                    </ToggleContainer>

                    <Spacer Size="small" />

                    <InputContainer className="InputContainer">
                        <PageText FontColor={!HasGoals ? 'gray-silver' : null} FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('minimum')} ${t('measurement').toLowerCase()} (${Units})`} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <StyledInput
                            FontColor={!HasGoals ? 'gray-silver' : null} 
                            FontFamily="semibold"
                            FontSize="extra-large-2"
                            NoLabel
                            NoMargin
                            OnBlur={event => this.onBlurInput({ event, CaliperType: ParamName, Key: 'GoalMin', KeyIndex, MeasurementType })}
                            OnChange={event => this.onChangeInput({ event, CaliperType: ParamName, Key: 'GoalMin', KeyIndex, MeasurementType })}
                            Placeholder="0"
                            ReadOnly={!HasGoals}
                            Size="auto"
                            Type="number"
                            Value={`${HasGoals ? GoalMin : 0}`}
                        />
                    </InputContainer>

                    <Spacer Size="medium" />

                    <InputContainer className="InputContainer">
                        <PageText FontColor={!HasGoals ? 'gray-silver' : null} FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('maximum')} ${t('measurement').toLowerCase()} (${Units})`} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={Units} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <StyledInput
                            FontColor={!HasGoals ? 'gray-silver' : null} 
                            FontFamily="semibold"
                            FontSize="extra-large-2"
                            NoLabel
                            NoMargin
                            OnBlur={event => this.onBlurInput({ event, CaliperType: ParamName, Key: 'GoalMax', KeyIndex, MeasurementType })}
                            OnChange={event => this.onChangeInput({ event, CaliperType: ParamName, Key: 'GoalMax', KeyIndex, MeasurementType })}
                            Placeholder="0"
                            ReadOnly={!HasGoals}
                            Size="auto"
                            Type="number"
                            Value={`${HasGoals ? GoalMax : 0}`}
                        />
                    </InputContainer>

                    <Spacer Size="medium" />

                    {
                        !!+HasGoals && !!+GoalMax && !!+GoalMin && +GoalMin >= +GoalMax && 
                        <>
                            <Spacer Size="small" />

                            <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Goal_MinMax_Validation')} TextAlign="center" />
                        </>
                    }
                </QuestionContainer>
            );
        }

        // eslint-disable-next-line
        var { BodyAreaName, Current, Units } = SelectedBodyAreas[KeyIndex];

        return (
            <QuestionContainer className="QuestionContainer">
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={BodyAreaName} TextAlign="center" />

                <Spacer Size="small" />

                <InputContainer className="InputContainer">
                    <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Current_Measurements_By_Default')} TextAlign="center" />

                    <Spacer Size="small" />

                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('current')} ${t('measurement').toLowerCase()} (${Units})`} TextAlign="center" />

                    <Spacer Size="extra-small" />

                    <StyledInput
                        FontFamily="semibold"
                        FontSize="extra-large-2"
                        NoLabel
                        NoMargin
                        OnBlur={event => this.onBlurInput({ event, Key: 'Current', KeyIndex, MeasurementType })}
                        OnChange={event => this.onChangeInput({ event, Key: 'Current', KeyIndex, MeasurementType })}
                        Placeholder="0"
                        Size="auto"
                        Type="number"
                        Value={`${Current}`}
                    />
                </InputContainer>
            </QuestionContainer>
        );

        // return null;
    }

    renderCaliperSelectionTitle = ({ CaliperType }) => {
        var { t } = this.props;

        var TitleText = CaliperType === 'caliper3' ? t('Progress_Caliper3_Title') : t('Progress_Caliper7_Title');
        var SubtitleText = CaliperType === 'caliper3' ? t('Progress_Caliper3_Subtitle') : t('Progress_Caliper7_Subtitle');

        return (
            <MultiLineButtonTitle>
                <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={TitleText} TextAlign="center" />

                <Spacer Size="extra-extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={SubtitleText} TextAlign="center" />
            </MultiLineButtonTitle>
        );
    }

    renderCircumferenceBodyAreaSelection = ({ BodyAreas, SelectedBodyAreasIndex }) => {
        var { t } = this.props;

        return (
           <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('Progress_Choose_BodyAreas')} TextAlign="center" />

                <Spacer Size="small" />

                <FlexWrap
                    IsMultiSelect
                    ItemId="BodyAreaId"
                    ItemName="BodyAreaName"
                    Items={BodyAreas}
                    OnSelectItem={(Item, ItemIndex) => this.onSelectCircumferenceBodyArea(Item, ItemIndex)}
                    SelectedItemIds={SelectedBodyAreasIndex}
                />
            </>
        );
    }

    renderCircumferenceQuestions = () => {
        var { t } = this.props;
        var { AddMeasurementDetails, DateFormat, MeasurementType, TimeFormat } = this.state;

        if (!AddMeasurementDetails.hasOwnProperty('circumference')) return null;

        var { BodyAreas, ScreenNumber, SelectedBodyAreas, SelectedBodyAreasIndex } = AddMeasurementDetails.circumference;
        
        if (ScreenNumber === 1) return this.renderCircumferenceBodyAreaSelection({ BodyAreas, SelectedBodyAreasIndex });
        
        // Even ScreenNumbers = Current, Odd = Goal
        // KeyIndex Used To Round Down To Nearest 2, As Each Body Area Has 2 Screens That Need Same Index
            var KeyIndex = ((Math.floor(ScreenNumber / 2) * 2) - 2) / 2;

        // 2,3   = 0 |  2 - 2 = 0 | (2 - 2)  / 2 = 0
        // 4,5   = 1 |  4 - 3 = 1 | (4 - 2)  / 2 = 1
        // 6,7   = 2 |  6 - 4 = 2 | (6 - 2)  / 2 = 2
        // 8,9   = 3 |  8 - 5 = 3 | (8 - 2)  / 2 = 3
        // 10,11 = 4 | 10 - 6 = 4 | (10 - 2) / 2 = 4

        var { BodyAreaName, Current, GoalMax, GoalMin, HasGoals, MeasurementDate, Units } = SelectedBodyAreas[KeyIndex];

        if (ScreenNumber % 2 === 0) {
            return (
                <QuestionContainer className="QuestionContainer">
                    <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={BodyAreaName} TextAlign="center" />
    
                    <Spacer Size="small" />
    
                    <InputContainer className="InputContainer">
                        <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Current_Measurements_By_Default')} TextAlign="center" />

                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('current')} ${t('measurement').toLowerCase()} (${Units})`} TextAlign="center" />
    
                        <Spacer Size="extra-small" />

                        <StyledInput
                            FontFamily="semibold"
                            FontSize="extra-large-2"
                            NoLabel
                            NoMargin
                            OnBlur={event => this.onBlurInput({ event, Key: 'Current', KeyIndex, MeasurementType })}
                            OnChange={event => this.onChangeInput({ event, Key: 'Current', KeyIndex, MeasurementType })}
                            Placeholder="0"
                            Size="auto"
                            Type="number"
                            Value={`${Current}`}
                        />
                    </InputContainer>

                    <Spacer Size="small" />

                    <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('measurement_date')} TextAlign="center" />

                    <Spacer Size="extra-extra-small" />

                    <DateTime
                        dateFormat={DateFormat}
                        timeFormat={TimeFormat}
                        onChange={MeasurementDate => this.onChangeMeasurementDate({ KeyIndex, MeasurementDate, MeasurementType })}
                        value={moment(MeasurementDate)}
                    />
                </QuestionContainer>
            );
        }
        
        else if (ScreenNumber % 2 !== 0) {
            return (
                <QuestionContainer className="QuestionContainer">
                    <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('Progress_Goal_Circumference', { BodyArea: BodyAreaName.toLowerCase() })} TextAlign="center" />

                    <Spacer Size="extra-small" />

                    <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Goal_Description')} TextAlign="center" />

                    <Spacer Size="small" />

                    <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Current_Measurements_By_Default')} TextAlign="center" />

                    <Spacer Size="small" />

                    <ToggleContainer>
                        <Toggle onClick={() => this.onToggleHasGoals({ KeyIndex, MeasurementType })} Selected={HasGoals} />
                        <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={() => this.onToggleHasGoals({ KeyIndex, MeasurementType })} Text={t('Progress_Goals_Toggle')} TextAlign="left" />
                    </ToggleContainer>

                    <Spacer Size="small" />

                    <InputContainer className="InputContainer">
                        <PageText FontColor={!HasGoals ? 'gray-silver' : null} FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('minimum')} ${t('circumference').toLowerCase()} (${Units})`} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <StyledInput
                            FontColor={!HasGoals ? 'gray-silver' : null} 
                            FontFamily="semibold"
                            FontSize="extra-large-2"
                            NoLabel
                            NoMargin
                            OnBlur={event => this.onBlurInput({ event, Key: 'GoalMin', KeyIndex, MeasurementType })}
                            OnChange={event => this.onChangeInput({ event, Key: 'GoalMin', KeyIndex, MeasurementType })}
                            Placeholder="0"
                            ReadOnly={!HasGoals}
                            Size="auto"
                            Type="number"
                            Value={`${HasGoals ? GoalMin : 0}`}
                        />
                    </InputContainer>

                    <Spacer Size="medium" />

                    <InputContainer className="InputContainer">
                        <PageText FontColor={!HasGoals ? 'gray-silver' : null} FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('maximum')} ${t('circumference').toLowerCase()} (${Units})`} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <StyledInput
                            FontColor={!HasGoals ? 'gray-silver' : null} 
                            FontFamily="semibold"
                            FontSize="extra-large-2"
                            NoLabel
                            NoMargin
                            OnBlur={event => this.onBlurInput({ event, Key: 'GoalMax', KeyIndex, MeasurementType })}
                            OnChange={event => this.onChangeInput({ event, Key: 'GoalMax', KeyIndex, MeasurementType })}
                            Placeholder="0"
                            ReadOnly={!HasGoals}
                            Size="auto"
                            Type="number"
                            Value={`${HasGoals ? GoalMax : 0}`}
                        />
                    </InputContainer>

                    <Spacer Size="medium" />

                    {
                        !!+HasGoals && !!+GoalMax && !!+GoalMin && +GoalMin >= +GoalMax && 
                        <>
                            <Spacer Size="small" />

                            <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Goal_MinMax_Validation')} TextAlign="center" />
                        </>
                    }
                </QuestionContainer>
            );
        }

        return null;
    }

    renderMeasurementTypeQuestions = () => {
        var { t } = this.props;
        var { MeasurementType } = this.state;

        var ButtonStart = { BackgroundColorHover: 'blue-astronaut', BackgroundColorSelected: 'blue-astronaut', ColorHover: 'white', ColorSelected: 'white' };
        var Buttons = [
            { ...ButtonStart, OnClick: () => this.onSelectMeasurementType('bodyweight'), Selected: MeasurementType === 'bodyweight', Title: t('body_weight') },
            { ...ButtonStart, OnClick: () => this.onSelectMeasurementType('circumference'), Selected: MeasurementType === 'circumference', Title: t('circumference') },
            { ...ButtonStart, OnClick: () => this.onSelectMeasurementType('caliper'), Selected: MeasurementType === 'caliper', Title: t('caliper') },
            { ...ButtonStart, OnClick: () => this.onSelectMeasurementType('bodyscan'), Selected: MeasurementType === 'bodyscan', Title: t('body_scan') },
            { ...ButtonStart, OnClick: () => this.onSelectMeasurementType('pain'), Selected: MeasurementType === 'pain', Title: t('_mini_survey_types_pain') },
            { ...ButtonStart, OnClick: () => this.onSelectMeasurementType('rpe'), Selected: MeasurementType === 'rpe', Title: t('_mini_survey_types_rpe') },
            { ...ButtonStart, OnClick: () => this.onSelectMeasurementType('sleep'), Selected: MeasurementType === 'sleep', Title: t('_mini_survey_types_sleep') }
        ];

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('Progress_AddMeasurement_ChooseType')} TextAlign="center" />

                <Spacer Size="extra-small" />

                <StyledMeasurementTypeButtons Buttons={Buttons} NormalWhiteSpace NotTouching OwnRows />
            </>
        );
    }

    renderMuscleMassQuestions = () => {
        var { t } = this.props;
        var { AddMeasurementDetails, DateFormat, MeasurementType, TimeFormat } = this.state;

        if (!AddMeasurementDetails.hasOwnProperty('musclemass')) return null;

        var { Current, GoalMax, GoalMin, HasGoals, MeasurementDate, ScreenNumber, Units } = AddMeasurementDetails.musclemass;

        // Ask for current weight
            if (ScreenNumber === 1) {
                return (
                    <QuestionContainer className="QuestionContainer">
                        <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('Progress_Current_MuscleMass')} TextAlign="center" />

                        <Spacer Size="small" />

                        <InputContainer className="InputContainer">
                            <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Current_Measurements_By_Default')} TextAlign="center" />

                            <Spacer Size="small" />

                            <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('current')} ${t('musclemass').toLowerCase()} (${Units})`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'Current', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'Current', MeasurementType })}
                                Placeholder="0"
                                Size="auto"
                                Type="number"
                                Value={`${Current}`}
                            />
                        </InputContainer>

                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('measurement_date')} TextAlign="center" />

                        <Spacer Size="extra-extra-small" />

                        <DateTime
                            dateFormat={DateFormat}
                            timeFormat={TimeFormat}
                            onChange={MeasurementDate => this.onChangeMeasurementDate({ MeasurementDate, MeasurementType })}
                            value={moment(MeasurementDate)}
                        />
                    </QuestionContainer>
                );
            }
        
        // Ask for goal weight
            else if (ScreenNumber === 2) {
                return (
                    <QuestionContainer className="QuestionContainer">
                        <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('Progress_Goal_MuscleMass')} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Goal_Description')} TextAlign="center" />

                        <Spacer Size="small" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Current_Measurements_By_Default')} TextAlign="center" />

                        <Spacer Size="small" />

                        <ToggleContainer>
                            <Toggle onClick={() => this.onToggleHasGoals({ MeasurementType })} Selected={HasGoals} />
                            <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={() => this.onToggleHasGoals({ MeasurementType })} Text={t('Progress_Goals_Toggle')} TextAlign="left" />
                        </ToggleContainer>

                        <Spacer Size="small" />

                        <InputContainer className="InputContainer">
                            <PageText FontColor={!HasGoals ? 'gray-silver' : null} FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('minimum')} ${t('musclemass').toLowerCase()} (${Units})`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontColor={!HasGoals ? 'gray-silver' : null} 
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'GoalMin', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'GoalMin', MeasurementType })}
                                Placeholder="0"
                                ReadOnly={!HasGoals}
                                Size="auto"
                                Type="number"
                                Value={`${HasGoals ? GoalMin : 0}`}
                            />
                        </InputContainer>

                        <Spacer Size="medium" />

                        <InputContainer className="InputContainer">
                            <PageText FontColor={!HasGoals ? 'gray-silver' : null} FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('maximum')} ${t('musclemass').toLowerCase()} (${Units})`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontColor={!HasGoals ? 'gray-silver' : null} 
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'GoalMax', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'GoalMax', MeasurementType })}
                                Placeholder="0"
                                ReadOnly={!HasGoals}
                                Size="auto"
                                Type="number"
                                Value={`${HasGoals ? GoalMax : 0}`}
                            />
                        </InputContainer>

                        <Spacer Size="medium" />

                        {
                            !!+HasGoals && !!+GoalMax && !!+GoalMin && +GoalMin >= +GoalMax && 
                            <>
                                <Spacer Size="small" />

                                <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Goal_MinMax_Validation')} TextAlign="center" />
                            </>
                        }
                    </QuestionContainer>
                );
            }

        return null;
    }

    renderMultiButton = () => {
        var { t } = this.props;
        var { AddMeasurementDetails, MeasurementType, TrackingId  } = this.state; // TrackingId means this was opened on Detail Page And Should Only Be Able to Answer This Body Goal Questions

        if (!MeasurementType || (MeasurementType !== 'bodyscan' && !AddMeasurementDetails.hasOwnProperty(MeasurementType))) return null;

        var CanGoBack = 1;
        var CanGoNext = 0;
        var LastScreen = 0;

        // Check Body Fat Start Measurement && If Goals, That Min < Max
            if (MeasurementType === 'bodyfat') {
                // eslint-disable-next-line
                var { Current, GoalMax, GoalMin, ScreenNumber } = AddMeasurementDetails.bodyfat;
                GoalMax = +GoalMax;
                GoalMin = +GoalMin;
                Current = +Current;

                CanGoBack = !(TrackingId && ScreenNumber === 1);

                if (Current && (
                    (!GoalMin && !GoalMax) ||
                    (GoalMin && !GoalMax) ||
                    (!GoalMin && GoalMax) ||
                    (GoalMin && GoalMax && GoalMin < GoalMax)
                )) CanGoNext = 1;

                LastScreen = ScreenNumber === 2;
            }
        // Check BodyWeight Start Measurement && If Goals, That Min < Max
            else if (MeasurementType === 'bodyweight') {
                // eslint-disable-next-line
                var { Current, GoalMax, GoalMin, HasGoals, ScreenNumber } = AddMeasurementDetails.bodyweight;
                GoalMax = +GoalMax;
                GoalMin = +GoalMin;
                Current = +Current;

                CanGoBack = !(TrackingId && ScreenNumber === 1);

                if (Current && (ScreenNumber === 1 || (ScreenNumber === 2 && (!HasGoals || (HasGoals && (
                    (!GoalMin && !GoalMax) ||
                    (GoalMin && !GoalMax) ||
                    (!GoalMin && GoalMax) ||
                    (GoalMin && GoalMax && GoalMin < GoalMax)
                )))))) CanGoNext = 1;

                LastScreen = ScreenNumber === 2;
            }
        // Check Caliper Has Selected If ScreenNumber = 1 And For Each BodyArea, Then Check Start Measurement
        // && If Goals, That Min < Max
            else if (MeasurementType === 'caliper') {
                // eslint-disable-next-line
                var { CaliperType, SelectedBodyAreas, ScreenNumber } = AddMeasurementDetails.caliper;

                CanGoBack = !(TrackingId && ScreenNumber === 2);

                if (ScreenNumber === 1 && SelectedBodyAreas.length > 0) CanGoNext = 1;
                // Showing Calculated Body Fat
                    else if ((ScreenNumber === 5 && SelectedBodyAreas.length === 3) || (ScreenNumber === 9 && SelectedBodyAreas.length === 7)) CanGoNext = 1;
                // Showing Min And Max Body Fat Goal
                    else if ((ScreenNumber === 6 && SelectedBodyAreas.length === 3) || (ScreenNumber === 10 && SelectedBodyAreas.length === 7)) {
                        // eslint-disable-next-line
                        var { GoalMax, GoalMin, HasGoals } = this.state.AddMeasurementDetails.caliper[CaliperType === 'caliper3' ? 'ThreePoint' : 'SevenPoint'];
                        
                        GoalMax = +GoalMax;
                        GoalMin = +GoalMin;

                        if (!HasGoals || (HasGoals && ((!GoalMin && !GoalMax) ||
                            (GoalMin && !GoalMax) ||
                            (!GoalMin && GoalMax) ||
                            (GoalMin && GoalMax && GoalMin < GoalMax)
                        ))) CanGoNext = 1;

                        LastScreen = 1;
                    }
                else if (ScreenNumber > 1) {
                    // eslint-disable-next-line
                    var KeyIndex = ScreenNumber - 2;

                    // eslint-disable-next-line
                    var { Current } = SelectedBodyAreas[KeyIndex];

                    if (+Current) CanGoNext = 1;
                }
            }
        // Check Circumference Has Selected If ScreenNumber = 1 And For Each BodyArea, Then Check Start Measurement && If Goals, That Min < Max
            else if (MeasurementType === 'circumference') {
                // eslint-disable-next-line
                var { SelectedBodyAreas, ScreenNumber } = AddMeasurementDetails.circumference;

                CanGoBack = !(TrackingId && ScreenNumber === 2);

                if (ScreenNumber === 1 && SelectedBodyAreas.length > 0) CanGoNext = 1;
                else if (ScreenNumber > 1) {
                    // eslint-disable-next-line
                    var KeyIndex = ((Math.floor(ScreenNumber / 2) * 2) - 2) / 2;

                    // eslint-disable-next-line
                    var { Current, GoalMax, GoalMin } = SelectedBodyAreas[KeyIndex];
                    GoalMax = +GoalMax;
                    GoalMin = +GoalMin;
                    Current = +Current;

                    if (Current && (
                        (!GoalMin && !GoalMax) ||
                        (GoalMin && !GoalMax) ||
                        (!GoalMin && GoalMax) ||
                        (GoalMin && GoalMax && GoalMin < GoalMax)
                    )) CanGoNext = 1;
                }

                // LastScreen If Not Screen 1 (that is for choosing body areas) and then if Key Index = length of body areas
                // And % 2 !== 0 So That It Is On Goal Screen, which is always odd
                    LastScreen = ScreenNumber !== 1 && KeyIndex === SelectedBodyAreas.length - 1 && ScreenNumber % 2 !== 0;
            }
        // Check Muscle Mass Start Measurement && If Goals, That Min < Max
            else if (MeasurementType === 'musclemass') {
                // eslint-disable-next-line
                var { Current, GoalMax, GoalMin, HasGoals, ScreenNumber } = AddMeasurementDetails.musclemass;
                GoalMax = +GoalMax;
                GoalMin = +GoalMin;
                Current = +Current;

                CanGoBack = !(TrackingId && ScreenNumber === 1);

                if (Current && (!HasGoals || (HasGoals && (
                    (!GoalMin && !GoalMax) ||
                    (GoalMin && !GoalMax) ||
                    (!GoalMin && GoalMax) ||
                    (GoalMin && GoalMax && GoalMin < GoalMax)
                )))) CanGoNext = 1;

                LastScreen = ScreenNumber === 2;
            }
        // Check Pain Start Measurement && If Goals, That Min < Max
            else if (MeasurementType === 'pain') {
                // eslint-disable-next-line
                var { Current, GoalMax, GoalMin, HasGoals, ScreenNumber } = AddMeasurementDetails.pain;
                GoalMax = +GoalMax;
                GoalMin = +GoalMin;
                Current = +Current;

                CanGoBack = !(TrackingId && ScreenNumber === 1);

                if (Current && (!HasGoals || (HasGoals && (
                    (!GoalMin && !GoalMax) ||
                    (GoalMin && !GoalMax) ||
                    (!GoalMin && GoalMax) ||
                    (GoalMin && GoalMax && GoalMin < GoalMax)
                )))) CanGoNext = 1;

                LastScreen = ScreenNumber === 2;
            }
        // Check RPE Start Measurement && If Goals, That Min < Max
            else if (MeasurementType === 'rpe') {
                // eslint-disable-next-line
                var { Current, GoalMax, GoalMin, HasGoals, ScreenNumber } = AddMeasurementDetails.rpe;
                GoalMax = +GoalMax;
                GoalMin = +GoalMin;
                Current = +Current;

                CanGoBack = !(TrackingId && ScreenNumber === 1);

                if (Current && (!HasGoals || (HasGoals && (
                    (!GoalMin && !GoalMax) ||
                    (GoalMin && !GoalMax) ||
                    (!GoalMin && GoalMax) ||
                    (GoalMin && GoalMax && GoalMin < GoalMax)
                )))) CanGoNext = 1;

                LastScreen = ScreenNumber === 2;
            }
        // Check Sleep Start Measurement && If Goals, That Min < Max
            else if (MeasurementType === 'sleep') {
                // eslint-disable-next-line
                var { Current, GoalMax, GoalMin, HasGoals, ScreenNumber } = AddMeasurementDetails.sleep;
                GoalMax = +GoalMax;
                GoalMin = +GoalMin;
                Current = +Current;

                CanGoBack = !(TrackingId && ScreenNumber === 1);

                if (Current && (!HasGoals || (HasGoals && (
                    (!GoalMin && !GoalMax) ||
                    (GoalMin && !GoalMax) ||
                    (!GoalMin && GoalMax) ||
                    (GoalMin && GoalMax && GoalMin < GoalMax)
                )))) CanGoNext = 1;

                LastScreen = ScreenNumber === 2;
            }

        return (
            <BackNextButtons
                Buttons={[
                    { BackgroundColor: 'blue-astronaut', BackgroundColorHover: 'blue-astronaut-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled: !CanGoBack, FontFamily: 'semibold', OnClick: () => this.onChangeScreenNumber({ Direction: -1 }), Title: t('back_with_first_char_uppercase') },
                    { BackgroundColor: 'abel-blue', BackgroundColorHover: 'abel-blue-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled: !CanGoNext, FontFamily: 'semibold', OnClick: () => this.onChangeScreenNumber({ Direction: 1, CanGoNext, LastScreen }), Title: t(LastScreen ? 'finish_with_first_char_uppercase' : 'next_with_first_char_uppercase') }
                ]}
            />
        );
    }

    renderPainQuestions = () => {
        var { t } = this.props;
        var { AddMeasurementDetails, DateFormat, MeasurementType, TimeFormat } = this.state;

        if (!AddMeasurementDetails.hasOwnProperty('pain')) return null;

        var { Current, GoalMax, GoalMin, HasGoals, MeasurementDate, ScreenNumber } = AddMeasurementDetails.pain;

        // Ask for current weight
            if (ScreenNumber === 1) {
                return (
                    <QuestionContainer className="QuestionContainer">
                        <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('_mini_survey_types_pain')} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Current_Measurements_By_Default')} TextAlign="center" />

                        <Spacer Size="small" />

                        <InputContainer className="InputContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('current')}`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'Current', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'Current', MeasurementType })}
                                Placeholder="0"
                                Size="auto"
                                Type="number"
                                Value={`${Current}`}
                            />
                        </InputContainer>

                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('measurement_date')} TextAlign="center" />

                        <Spacer Size="extra-extra-small" />

                        <DateTime
                            dateFormat={DateFormat}
                            timeFormat={TimeFormat}
                            onChange={MeasurementDate => this.onChangeMeasurementDate({ MeasurementDate, MeasurementType })}
                            value={moment(MeasurementDate)}
                        />
                    </QuestionContainer>
                );
            }
        
        // Ask for goal weight
            else if (ScreenNumber === 2) {
                return (
                    <QuestionContainer className="QuestionContainer">
                        <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('_mini_survey_types_pain')} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Goal_Description')} TextAlign="center" />

                        <Spacer Size="small" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Current_Measurements_By_Default')} TextAlign="center" />

                        <Spacer Size="small" />

                        <ToggleContainer>
                            <Toggle onClick={() => this.onToggleHasGoals({ MeasurementType })} Selected={HasGoals} />
                            <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={() => this.onToggleHasGoals({ MeasurementType })} Text={t('Progress_Goals_Toggle')} TextAlign="left" />
                        </ToggleContainer>

                        <Spacer Size="small" />

                        <InputContainer className="InputContainer">
                            <PageText FontColor={!HasGoals ? 'gray-silver' : null} FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('minimum')}`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontColor={!HasGoals ? 'gray-silver' : null}
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'GoalMin', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'GoalMin', MeasurementType })}
                                Placeholder="0"
                                ReadOnly={!HasGoals}
                                Size="auto"
                                Type="number"
                                Value={`${HasGoals ? GoalMin : 0}`}
                            />
                        </InputContainer>

                        <Spacer Size="medium" />

                        <InputContainer className="InputContainer">
                            <PageText FontColor={!HasGoals ? 'gray-silver' : null}  FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('maximum')}`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontColor={!HasGoals ? 'gray-silver' : null}
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'GoalMax', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'GoalMax', MeasurementType })}
                                Placeholder="0"
                                ReadOnly={!HasGoals}
                                Size="auto"
                                Type="number"
                                Value={`${HasGoals ? GoalMax : 0}`}
                            />
                        </InputContainer>

                        <Spacer Size="medium" />

                        {
                            !!+HasGoals && !!+GoalMax && !!+GoalMin && +GoalMin >= +GoalMax && 
                            <>
                                <Spacer Size="small" />

                                <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Goal_MinMax_Validation')} TextAlign="center" />
                            </>
                        }
                    </QuestionContainer>
                );
            }

        return null;
    }

    renderRPEQuestions = () => {
        var { t } = this.props;
        var { AddMeasurementDetails, DateFormat, MeasurementType, TimeFormat } = this.state;

        if (!AddMeasurementDetails.hasOwnProperty('rpe')) return null;

        var { Current, GoalMax, GoalMin, HasGoals, MeasurementDate, ScreenNumber } = AddMeasurementDetails.rpe;

        // Ask for current weight
            if (ScreenNumber === 1) {
                return (
                    <QuestionContainer className="QuestionContainer">
                        <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('_mini_survey_types_rpe')} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Current_Measurements_By_Default')} TextAlign="center" />

                        <Spacer Size="small" />

                        <InputContainer className="InputContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('current')}`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'Current', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'Current', MeasurementType })}
                                Placeholder="0"
                                Size="auto"
                                Type="number"
                                Value={`${Current}`}
                            />
                        </InputContainer>

                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('measurement_date')} TextAlign="center" />

                        <Spacer Size="extra-extra-small" />

                        <DateTime
                            dateFormat={DateFormat}
                            timeFormat={TimeFormat}
                            onChange={MeasurementDate => this.onChangeMeasurementDate({ MeasurementDate, MeasurementType })}
                            value={moment(MeasurementDate)}
                        />
                    </QuestionContainer>
                );
            }
        
        // Ask for goal weight
            else if (ScreenNumber === 2) {
                return (
                    <QuestionContainer className="QuestionContainer">
                        <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('_mini_survey_types_rpe')} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Goal_Description')} TextAlign="center" />

                        <Spacer Size="small" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Current_Measurements_By_Default')} TextAlign="center" />

                        <Spacer Size="small" />

                        <ToggleContainer>
                            <Toggle onClick={() => this.onToggleHasGoals({ MeasurementType })} Selected={HasGoals} />
                            <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={() => this.onToggleHasGoals({ MeasurementType })} Text={t('Progress_Goals_Toggle')} TextAlign="left" />
                        </ToggleContainer>

                        <Spacer Size="small" />

                        <InputContainer className="InputContainer">
                            <PageText FontColor={!HasGoals ? 'gray-silver' : null} FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('minimum')}`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontColor={!HasGoals ? 'gray-silver' : null}
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'GoalMin', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'GoalMin', MeasurementType })}
                                Placeholder="0"
                                ReadOnly={!HasGoals}
                                Size="auto"
                                Type="number"
                                Value={`${HasGoals ? GoalMin : 0}`}
                            />
                        </InputContainer>

                        <Spacer Size="medium" />

                        <InputContainer className="InputContainer">
                            <PageText FontColor={!HasGoals ? 'gray-silver' : null}  FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('maximum')}`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontColor={!HasGoals ? 'gray-silver' : null}
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'GoalMax', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'GoalMax', MeasurementType })}
                                Placeholder="0"
                                ReadOnly={!HasGoals}
                                Size="auto"
                                Type="number"
                                Value={`${HasGoals ? GoalMax : 0}`}
                            />
                        </InputContainer>

                        <Spacer Size="medium" />

                        {
                            !!+HasGoals && !!+GoalMax && !!+GoalMin && +GoalMin >= +GoalMax && 
                            <>
                                <Spacer Size="small" />

                                <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Goal_MinMax_Validation')} TextAlign="center" />
                            </>
                        }
                    </QuestionContainer>
                );
            }

        return null;
    }

    renderSleepQuestions = () => {
        var { t } = this.props;
        var { AddMeasurementDetails, DateFormat, MeasurementType, TimeFormat } = this.state;

        if (!AddMeasurementDetails.hasOwnProperty('sleep')) return null;

        var { Current, GoalMax, GoalMin, HasGoals, MeasurementDate, ScreenNumber } = AddMeasurementDetails.sleep;

        // Ask for current weight
            if (ScreenNumber === 1) {
                return (
                    <QuestionContainer className="QuestionContainer">
                        <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('_mini_survey_types_sleep')} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Current_Measurements_By_Default')} TextAlign="center" />

                        <Spacer Size="small" />

                        <InputContainer className="InputContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('current')} ${t('_hours').toLowerCase()}`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'Current', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'Current', MeasurementType })}
                                Placeholder="0"
                                Size="auto"
                                Type="number"
                                Value={`${Current}`}
                            />
                        </InputContainer>

                        <Spacer Size="small" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('measurement_date')} TextAlign="center" />

                        <Spacer Size="extra-extra-small" />

                        <DateTime
                            dateFormat={DateFormat}
                            timeFormat={TimeFormat}
                            onChange={MeasurementDate => this.onChangeMeasurementDate({ MeasurementDate, MeasurementType })}
                            value={moment(MeasurementDate)}
                        />
                    </QuestionContainer>
                );
            }
        
        // Ask for goal weight
            else if (ScreenNumber === 2) {
                return (
                    <QuestionContainer className="QuestionContainer">
                        <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('_mini_survey_types_sleep')} TextAlign="center" />

                        <Spacer Size="extra-small" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Goal_Description')} TextAlign="center" />

                        <Spacer Size="small" />

                        <PageText FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Current_Measurements_By_Default')} TextAlign="center" />

                        <Spacer Size="small" />

                        <ToggleContainer>
                            <Toggle onClick={() => this.onToggleHasGoals({ MeasurementType })} Selected={HasGoals} />
                            <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={() => this.onToggleHasGoals({ MeasurementType })} Text={t('Progress_Goals_Toggle')} TextAlign="left" />
                        </ToggleContainer>

                        <Spacer Size="small" />

                        <InputContainer className="InputContainer">
                            <PageText FontColor={!HasGoals ? 'gray-silver' : null} FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('minimum')} ${t('_hours').toLowerCase()}`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontColor={!HasGoals ? 'gray-silver' : null}
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'GoalMin', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'GoalMin', MeasurementType })}
                                Placeholder="0"
                                ReadOnly={!HasGoals}
                                Size="auto"
                                Type="number"
                                Value={`${HasGoals ? GoalMin : 0}`}
                            />
                        </InputContainer>

                        <Spacer Size="medium" />

                        <InputContainer className="InputContainer">
                            <PageText FontColor={!HasGoals ? 'gray-silver' : null}  FontFamily="medium" FontSize="medium-1" NoMargin Text={`${t('maximum')} ${t('_hours').toLowerCase()}`} TextAlign="center" />

                            <Spacer Size="extra-small" />

                            <StyledInput
                                FontColor={!HasGoals ? 'gray-silver' : null}
                                FontFamily="semibold"
                                FontSize="extra-large-2"
                                NoLabel
                                NoMargin
                                OnBlur={event => this.onBlurInput({ event, Key: 'GoalMax', MeasurementType })}
                                OnChange={event => this.onChangeInput({ event, Key: 'GoalMax', MeasurementType })}
                                Placeholder="0"
                                ReadOnly={!HasGoals}
                                Size="auto"
                                Type="number"
                                Value={`${HasGoals ? GoalMax : 0}`}
                            />
                        </InputContainer>

                        <Spacer Size="medium" />

                        {
                            !!+HasGoals && !!+GoalMax && !!+GoalMin && +GoalMin >= +GoalMax && 
                            <>
                                <Spacer Size="small" />

                                <PageText FontColor="red-bittersweet" FontFamily="medium-italic" FontSize="medium-1" NoMargin Text={t('Progress_Goal_MinMax_Validation')} TextAlign="center" />
                            </>
                        }
                    </QuestionContainer>
                );
            }

        return null;
    }

    renderModalBody = () => {
        var { TryingCalculateBodyFat, TryingGetAddMeasurementDetails } = this.props;
        var { MeasurementType } = this.state;

        return (
            <>
                {(TryingCalculateBodyFat || TryingGetAddMeasurementDetails) && <Loading />}

                {!MeasurementType && this.renderMeasurementTypeQuestions()}
                {MeasurementType === 'bodyweight' && this.renderBodyWeightQuestions()}

                {MeasurementType === 'bodyscan' && this.renderBodyScanQuestions()}
                {MeasurementType === 'bodyfat' && this.renderBodyFatQuestions()}
                {MeasurementType === 'musclemass' && this.renderMuscleMassQuestions()}

                {MeasurementType === 'circumference' && this.renderCircumferenceQuestions()}

                {MeasurementType === 'caliper' && this.renderCaliperQuestions()}

                {MeasurementType === 'pain' && this.renderPainQuestions()}
                {MeasurementType === 'rpe' && this.renderRPEQuestions()}
                {MeasurementType === 'sleep' && this.renderSleepQuestions()}
            </>
        );
    }

    render() {
        var { OnHide, Show } = this.props;

        return (
            <AbelModal
                BottomButtonRender={this.renderMultiButton}
                GA_PathName="AddMeasurements"
                NoMargin
                Show={Show}
                Size="medium"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {this.renderModalBody()}
            </AbelModal>
        );
    }
}

AddMeasurements.propTypes = {
    ClientId: PropTypes.string.isRequired,
    OnAddMeasurement: PropTypes.func.isRequired,
    OnHide: PropTypes.func.isRequired,
    Show: PropTypes.bool.isRequired,
    TrackingType: PropTypes.object
}

AddMeasurements.defaultProps = {
    TrackingType: { TrackingId: null, TrackingPageNo: null, TrackingPageSize: null }
}

const mapStateToProps = state => {
    return {
        UserDetails: state.Auth.UserDetails,

        Device: state.Window.Device,
        
        TryingCalculateBodyFat: state.Progress.TryingCalculateBodyFat,
        TryingCalculateBodyFatError: state.Progress.TryingCalculateBodyFatError,
        TryingGetAddMeasurementDetails: state.Progress.TryingGetAddMeasurementDetails,
        TryingGetAddMeasurementDetailsError: state.Progress.TryingGetAddMeasurementDetailsError,
    };
};

export default withTranslation()(connect(mapStateToProps, { AddBodyMeasurements_BodyFat, AddBodyMeasurements_BodyWeight, AddBodyMeasurements_Caliper, AddBodyMeasurements_Circumference, AddBodyMeasurements_MuscleMass, AddBodyMeasurements_Pain, AddBodyMeasurements_RPE, AddBodyMeasurements_Sleep, CalculateBodyFat, GetAddMeasurementDetails } )(AddMeasurements));