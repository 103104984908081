import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DeliverySite_GetCompanies, DeliverySite_GetCompanies_Basic, DeliverySite_GetDHCs_Basic, DeliverySite_GetCompanyDetails, DeliverySite_GetLeaders_Basic, DeliverySite_GetMiddleLeaders_Basic, DeliverySite_GetTopLeaders_Basic, DeliverySite_InsertEditCompany } from '../../../Services/Actions';
import Select from 'react-select';
import Popover from 'react-popover';

import * as styles from './styles';

import ButtonGroup from '../../../Components/Buttons/ButtonGroup';
import Loading from '../../../Components/Loading';
import PageText from '../../../Components/Text/PageText';
import PaginationContainer from '../../../Components/PaginationCounter';
import Spacer from '../../../Components/Spacer';

import Info from '../../../Modals/Info';

import DownArrow from '../../../Assets/Icons/DownArrow.png';
import InfoIcon from '../../../Assets/Icons/Info.png';
import Plus from '../../../Assets/Icons/PlusSign_White.png';
import UpArrow from '../../../Assets/Icons/UpArrow.png';

// import { emailValidator } from '../../../Functions';

const COMPANY_TABLE_COLUMNS = [
    { Id: 1, SortCol: 'CompanyId', Title: 'Company Id' },
    { Id: 2, SortCol: 'CompanyName', Title: 'Name' },
    { Id: 3, SortCol: 'ActiveEmployee', Title: 'Active Employees' },
    { Id: 4, SortCol: 'TotalEmployees', Title: 'Total Employees' },
    { Id: 5, SortCol: 'ActiveEmployeePercentage', Title: 'Active Employees Percentage' }
];

const customStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "initial"
    }),
    input: (provided, state) => ({
        ...provided,
        minWidth: '20%'
    })
}

const ShowAddCompanyModal_Data_Default = {
    CompanyType: null,
    CompanyName: '',
    Parent_CompanyId: null
}

class CompaniesComponent extends React.Component {
    TimeoutId = null;

    state = {
        Companies: [],
        DisplayName_Previous: '',
        DisplayName: '',
        IsMoreResults: null,
        PageNo: 1,
        PageSize: 10,
        Sorting: {
            SortCol: 'CompanyName',
            SortDir: 'ASC'
        },
        TotalRecords: null,

        Filters: {
            DHCs: {
                IsLoading: false,
                Loaded: false,
                Selected: [],
                Users: []
            },
            Leaders: {
                IsLoading: false,
                Loaded: false,
                Selected: [],
                Users: []
            },
            MiddleLeaders: {
                IsLoading: false,
                Loaded: false,
                Selected: [],
                Users: []
            },
            TopLeaders: {
                IsLoading: false,
                Loaded: false,
                Selected: [],
                Users: []
            }
        },

        PopoverIsOpen_Companies: false,
        PopoverIsOpen_DHCs: false,
        PopoverIsOpen_Leaders: false,
        PopoverIsOpen_MiddleLeaders: false,
        PopoverIsOpen_TopLeaders: false,

        ShowAddCompanyModal: false,
        ShowAddCompanyModal_CompaniesList: [],
        ShowAddCompanyModal_CompaniesList_Loading: false,
        ShowAddCompanyModal_Loading: false,
        ShowAddCompanyModal_Data: { ...ShowAddCompanyModal_Data_Default },

        ShowCompanyDetailModal: false,
        ShowCompanyDetailModal_Data: {},
        ShowCompanyDetailModal_Loading: false
    }

    componentDidMount() {
        this.onLoadCompanies();
    }

    onAddCompany = () => {
        var { CompanyName } = this.state.ShowAddCompanyModal_Data;

        this.setState({ ShowAddCompanyModal_Loading: true }, () => {
            this.props.DeliverySite_InsertEditCompany({ CompanyName }).then(() => {
                this.onToggleShowAddCompanyModal({ ShowAddCompanyModal: false });
                this.onLoadCompanies();
            })
        })
    }

    onBlurFilter_DHCs = () => {
        this.setState({ PageNo: 1 }, () => this.onLoadCompanies());
    }

    onBlurFilter_Leaders = () => {
        this.setState({ PageNo: 1 }, () => this.onLoadCompanies());
    }

    onBlurFilter_MiddleLeaders = () => {
        this.setState({ PageNo: 1 }, () => this.onLoadCompanies());
    }

    onBlurFilter_TopLeaders = () => {
        this.setState({ PageNo: 1 }, () => this.onLoadCompanies());
    }

    onBlurSearchCompanies = () => {
        var { DisplayName, DisplayName_Previous } = this.state;

        if (DisplayName !== DisplayName_Previous) {
            this.setState({ DisplayName_Previous: DisplayName, PageNo: 1 }, () => this.onLoadCompanies());
        }
    }

    onChangeAddCompanyModal_CompanyName = event => {
        var ShowAddCompanyModal_Data = { ...this.state.ShowAddCompanyModal_Data };
        ShowAddCompanyModal_Data.CompanyName = event.target.value;

        this.setState({ ShowAddCompanyModal_Data });
    }

    onChangeAddCompanyModal_CompanyType = event => {
        var ShowAddCompanyModal_Data = { ...this.state.ShowAddCompanyModal_Data };
        var ShowAddCompanyModal_CompaniesList = [ ...this.state.ShowAddCompanyModal_CompaniesList ];

        // Reset Companies List to load again if new company type selected
            if (ShowAddCompanyModal_Data.CompanyType !== event.target.value) ShowAddCompanyModal_CompaniesList = [];

        ShowAddCompanyModal_Data.CompanyType = event.target.value;

        this.setState({ ShowAddCompanyModal_Data, ShowAddCompanyModal_CompaniesList });
    }

    onChangeCompanySearch = event => {
        this.setState({ DisplayName: event.target.value });
    }

    onChangeFilter_DHCs = data => {
        if (!data) data = [];
        
        var Filters = { ...this.state.Filters };
        Filters.DHCs.Selected = data;

        this.setState({ Filters });
    }

    onChangeFilter_Leaders = data => {
        if (!data) data = [];
        
        var Filters = { ...this.state.Filters };
        Filters.Leaders.Selected = data;

        this.setState({ Filters });
    }

    onChangeFilter_MiddleLeaders = data => {
        if (!data) data = [];
        
        var Filters = { ...this.state.Filters };
        Filters.MiddleLeaders.Selected = data;

        this.setState({ Filters });
    }

    onChangeFilter_TopLeaders = data => {
        if (!data) data = [];
        
        var Filters = { ...this.state.Filters };
        Filters.TopLeaders.Selected = data;

        this.setState({ Filters });
    }

    onChangeShowAddCompanyModal_CompaniesList = data => {
        var ShowAddCompanyModal_Data = { ...this.state.ShowAddCompanyModal_Data };
        ShowAddCompanyModal_Data.Parent_CompanyId = data.CompanyId;

        this.setState({ ShowAddCompanyModal_Data });
    }

    onFocusFilter_AddCompanyModal_CompaniesList = () => {
        if (this.state.ShowAddCompanyModal_CompaniesList.length === 0) {
            this.setState({ ShowAddCompanyModal_CompaniesList_Loading: true }, () => {
                var { CompanyType } = this.state.ShowAddCompanyModal_Data;

                var IsNotParent;
                var IsParent;

                if (CompanyType === 'Child') IsParent = 1; // Search For Parents
                else if (CompanyType === 'Parent') IsNotParent = 1; // Search For Non-Parents
                
                this.props.DeliverySite_GetCompanies_Basic({ IsNotParent, IsParent }).then(({ Companies }) => {
                    this.setState({ ShowAddCompanyModal_CompaniesList: Companies, ShowAddCompanyModal_CompaniesList_Loading: false });
                })
            });
        }
    }

    onFocusFilter_DHCs = () => {
        if (!this.state.Filters.DHCs.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.DHCs = { ...Filters.DHCs };
            Filters.DHCs.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetDHCs_Basic().then(({ Users }) => {
                    Filters.DHCs.IsLoading = false;
                    Filters.DHCs.Users = Users;
                    Filters.DHCs.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onFocusFilter_Leaders = () => {
        if (!this.state.Filters.Leaders.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.Leaders = { ...Filters.Leaders };
            Filters.Leaders.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetLeaders_Basic().then(({ Users }) => {
                    Filters.Leaders.IsLoading = false;
                    Filters.Leaders.Users = Users;
                    Filters.Leaders.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onFocusFilter_MiddleLeaders = () => {
        if (!this.state.Filters.MiddleLeaders.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.MiddleLeaders = { ...Filters.MiddleLeaders };
            Filters.MiddleLeaders.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetMiddleLeaders_Basic().then(({ Users }) => {
                    Filters.MiddleLeaders.IsLoading = false;
                    Filters.MiddleLeaders.Users = Users;
                    Filters.MiddleLeaders.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onFocusFilter_TopLeaders = () => {
        if (!this.state.Filters.TopLeaders.Loaded) {
            var Filters = { ...this.state.Filters };
            Filters.TopLeaders = { ...Filters.TopLeaders };
            Filters.TopLeaders.IsLoading = true;

            this.setState({ Filters }, () => {
                this.props.DeliverySite_GetTopLeaders_Basic().then(({ Users }) => {
                    Filters.TopLeaders.IsLoading = false;
                    Filters.TopLeaders.Users = Users;
                    Filters.TopLeaders.Loaded = true;
                    this.setState({ Filters });
                });
            });
        }
    }

    onLoadCompanies = Increment => {
        var { DisplayName, Filters: { DHCs, Leaders, MiddleLeaders, TopLeaders }, PageNo, PageSize, Sorting: { SortCol, SortDir } } = this.state;

        var DHC_UserIds = DHCs.Selected.length > 0 ? DHCs.Selected.map(({ UserId }) => UserId).join(',') : null;
        var Leader_UserIds = Leaders.Selected.length > 0 ? Leaders.Selected.map(({ UserId }) => UserId).join(',') : null;
        var MiddleLeader_UserIds = MiddleLeaders.Selected.length > 0 ? MiddleLeaders.Selected.map(({ UserId }) => UserId).join(',') : null;
        var TopLeader_UserIds = TopLeaders.Selected.length > 0 ? TopLeaders.Selected.map(({ UserId }) => UserId).join(',') : null;

        var IsIndividual;
        var IsParent;

        if (Increment) PageNo++;

        this.props.DeliverySite_GetCompanies({ CompanyName: DisplayName, DHC_UserIds, IsIndividual, IsParent, Leader_UserIds, MiddleLeader_UserIds, PageNo, PageSize, SortCol, SortDir, TopLeader_UserIds }).then(({ Companies, IsMoreResults, TotalRecords }) => {
            this.setState({ Companies, IsMoreResults, TotalRecords });
        });
    }

    onPaginate = ({ NewPageNo }) => {
        if (this.statePageNo !== NewPageNo) this.setState({ PageNo: NewPageNo }, () => this.onLoadCompanies());
    }

    onTogglePopover = PopoverType => {
        this.setState({ [PopoverType]: !this.state[PopoverType] }, () => {
            if (!this.state[PopoverType] && !!this.TimeoutId) clearTimeout(this.TimeoutId);
        });
    }

    onToggleShowAddCompanyModal = ({ ShowAddCompanyModal }) => {
        if (!ShowAddCompanyModal) this.setState({ ShowAddCompanyModal, ShowAddCompanyModal_CompaniesList: [], ShowAddCompanyModal_CompaniesList_Loading: false, ShowAddCompanyModal_Data: { ...ShowAddCompanyModal_Data_Default }, ShowAddCompanyModal_Loading: false });
        else this.setState({ ShowAddCompanyModal });
    }

    onToggleShowCompanyDetailModal = ({ CompanyId, ShowCompanyDetailModal }) => {
        if (!ShowCompanyDetailModal) this.setState({ ShowCompanyDetailModal, ShowCompanyDetailModal_Loading: false });
        else {
            this.setState({ ShowCompanyDetailModal, ShowCompanyDetailModal_Loading: true }, () => {
                this.props.DeliverySite_GetCompanyDetails({ CompanyId }).then(({ CompanyDetails }) => {
                    this.setState({ ShowCompanyDetailModal_Data: CompanyDetails, ShowCompanyDetailModal_Loading: false });
                })
            });
        }
    }

    onToggleSorting = ({ SortCol: NewSortCol }) => {
        var { SortCol, SortDir } = this.state.Sorting;

        // SortDir Goes From ASC --> DESC --> Nothing

        // Toggle Sorting Of Already Sorted Column
            if (SortCol === NewSortCol) {
                if (SortDir === 'ASC') SortDir = 'DESC';
                else if (SortDir === 'DESC') {
                    SortDir = '';
                    SortCol = '';
                }
                else if (SortDir === '') SortDir = 'ASC';
            }
        // Start Sorting On New Column
            else {
                SortCol = NewSortCol;
                SortDir = 'ASC';
            }

        this.setState({ PageNo: 1, Sorting: { SortCol, SortDir } }, () => this.onLoadCompanies());
    }

    renderAddCompanyModal = () => {
        var { t } = this.props;
        var { ShowAddCompanyModal, ShowAddCompanyModal_Data, ShowAddCompanyModal_Loading } = this.state;

        if (ShowAddCompanyModal) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={this.onAddCompany}
                    // BottomButtonDisabled={!ShowAddCompanyModal_Data.CompanyType || !ShowAddCompanyModal_Data.CompanyName || (ShowAddCompanyModal_Data.CompanyType === 'Child' && !ShowAddCompanyModal_Data.Parent_CompanyId)}
                    BottomButtonDisabled={!ShowAddCompanyModal_Data.CompanyName}
                    BottomButtonText={t('_save')}
                    InformationRenderer={this.renderAddCompanyModal_Information}
                    Loading={(ShowAddCompanyModal_Loading)}
                    OnHide={() => this.onToggleShowAddCompanyModal({ ShowAddCompanyModal: false })}
                    Show={ShowAddCompanyModal}
                />
            );
        }
    }

    renderAddCompanyModal_Information = () => {
        // var { Filters: { Leaders }, ShowAddCompanyModal_CompaniesList, ShowAddCompanyModal_CompaniesList_Loading, ShowAddCompanyModal_Data } = this.state;
        var { ShowAddCompanyModal_Data } = this.state;

        return (
            <styles.InsertEditModalContainer className="InsertEditModalContainer">
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text="Add Company" TextAlign="center" />

                {/* <Spacer Size="extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text="Company type" TextAlign="center" />

                <form>
                    <div className="radio">
                        <label>
                            <input type="radio" value="Independent" checked={ShowAddCompanyModal_Data.CompanyType === 'Independent'} onChange={this.onChangeAddCompanyModal_CompanyType} />
                            Independent company
                        </label>
                    </div>
                    <div className="radio">
                        <label>
                            <input type="radio" value="Child" checked={ShowAddCompanyModal_Data.CompanyType === 'Child'} onChange={this.onChangeAddCompanyModal_CompanyType} />
                            Company with a parent
                        </label>
                    </div>
                    <div className="radio">
                        <label>
                            <input type="radio" value="Parent" checked={ShowAddCompanyModal_Data.CompanyType === 'Parent'} onChange={this.onChangeAddCompanyModal_CompanyType} />
                            Parent company
                        </label>
                    </div>
                </form>

                <Spacer Size="extra-small" /> */}

                <styles.SearchInput
                    AutoFocus
                    FontSize="medium-1"
                    NoLabel
                    NoMargin
                    OnChange={this.onChangeAddCompanyModal_CompanyName}
                    Placeholder="Company Name"
                    Size="medium"
                    Type="text"
                    Value={ShowAddCompanyModal_Data.CompanyName}
                />

                {/* {
                    (ShowAddCompanyModal_Data.CompanyType === 'Child' || ShowAddCompanyModal_Data.CompanyType === 'Parent') &&
                    <>
                        <Spacer Size="extra-small" />

                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={`Choose a ${ShowAddCompanyModal_Data.CompanyType === 'Child' ? 'parent' : 'child'} company`} TextAlign="center" />

                        <Select
                            closeMenuOnSelect={true}
                            getOptionLabel={option => option.CompanyName}
                            getOptionValue={option => option.CompanyId}
                            isLoading={ShowAddCompanyModal_CompaniesList_Loading}
                            isMulti={false}
                            // components={{ ValueContainer }}
                            hideSelectedOptions={true}
                            isSearchable
                            onChange={this.onChangeShowAddCompanyModal_CompaniesList}
                            onFocus={this.onFocusFilter_AddCompanyModal_CompaniesList}
                            options={ShowAddCompanyModal_CompaniesList}
                            placeholder="Companies"
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </>
                } */}
            </styles.InsertEditModalContainer>
        );
    }

    renderCompanies = () => {
        var { Companies, PageNo, PageSize, Sorting: { SortCol: StateSortCol, SortDir: StateSortDir }, TotalRecords } = this.state;

        return (
            <styles.BodyContainer className="BodyContainer">
                <styles.BodyHeader className="BodyHeader">
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="flex-start" NoMargin Text="Companies" TextAlign="left" />

                    <styles.BodyHeaderRight className="BodyHeaderRight">
                        <ButtonGroup
                            Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onToggleShowAddCompanyModal({ ShowAddCompanyModal: true }), Title: "New Company" }]}
                            ButtonWidth="fit-content"
                            ContainerWidth="fit-content"
                            JustifyContent="center"
                        />
                    </styles.BodyHeaderRight>
                </styles.BodyHeader>

                <Spacer Size="small" />

                {this.renderViewCount()}

                {
                    TotalRecords > 0 &&
                    <>
                        <Spacer Size="small" />

                        {
                            (PageNo !== 1 || PageSize < TotalRecords) &&
                            <>
                                <PaginationContainer OnPaginate={this.onPaginate} PageNo={PageNo} PageSize={PageSize} TotalRecords={TotalRecords} />
        
                                <Spacer Size="medium" />
                            </>
                        }

                        <styles.UsersContainer className="UsersContainer">
                            <styles.UsersTableContainer className="UsersTableContainer">
                                <styles.UsersTable className="UsersTable">
                                    <styles.UsersTableHeader className="UsersTableHeader">
                                        <styles.UsersTableHeaderRow className="UsersTableHeaderRow">
                                            {
                                                COMPANY_TABLE_COLUMNS.map(({ Id, SortCol, Title }) => {
                                                    return (
                                                        <styles.UsersTableHeaderCell className="UsersTableHeaderCell" key={Id} onClick={SortCol ? () => this.onToggleSorting({ SortCol }) : null}>
                                                            <styles.UsersTableHeaderCellInnerDiv className="UsersTableHeaderCellInnerDiv">
                                                                <PageText FontFamily="semibold" FontSize="small" JustifyContent="flex-start" NoMargin TextAlign="left" Text={Title} />
                                                                {SortCol ? StateSortCol === SortCol && <styles.SortingIcon ImageSrc={StateSortDir === 'DESC' ? DownArrow : UpArrow} /> : null}
                                                            </styles.UsersTableHeaderCellInnerDiv>
                                                        </styles.UsersTableHeaderCell>
                                                    );
                                                })
                                            }
                                        </styles.UsersTableHeaderRow>
                                    </styles.UsersTableHeader>
                                    <styles.UsersTableBody className="UsersTableBody">
                                        {
                                            Companies.map((Company, UserIndex) => {
                                                var {
                                                    CompanyId, CompanyName,
                                                    // HasParent, IsParent,
                                                    NumActiveEmployees, TotalEmployees, ActiveEmployeePercentage
                                                } = Company;

                                                return (
                                                    <styles.UsersTableBodyRow className="UsersTableBodyRow" key={CompanyId}>
                                                        <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                            {CompanyId}
                                                        </styles.UsersTableBodyCell>
                                                        <styles.UsersTableBodyCell className="UsersTableBodyCell" onClick={() => this.onToggleShowCompanyDetailModal({ CompanyId, ShowCompanyDetailModal: true })}>
                                                            {CompanyName}
                                                        </styles.UsersTableBodyCell>
                                                        <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                            {NumActiveEmployees}
                                                        </styles.UsersTableBodyCell>
                                                        <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                            {TotalEmployees}
                                                        </styles.UsersTableBodyCell>
                                                        <styles.UsersTableBodyCell className="UsersTableBodyCell">
                                                            {ActiveEmployeePercentage}%
                                                        </styles.UsersTableBodyCell>
                                                    </styles.UsersTableBodyRow>
                                                )
                                            })
                                        }
                                    </styles.UsersTableBody>
                                </styles.UsersTable>
                            </styles.UsersTableContainer>
                        </styles.UsersContainer>
                    </>
                }
            </styles.BodyContainer>
        );
    }

    renderFilters = () => {
        var { t } = this.props;

        const popoverProps_Companies = {
            isOpen: this.state.PopoverIsOpen_Companies,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_Companies'),
            body: this.renderPopoverContent('Companies'),
            tipSize: 0.01
        }

        const popoverProps_DHCs = {
            isOpen: this.state.PopoverIsOpen_DHCs,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_DHCs'),
            body: this.renderPopoverContent('DHCs'),
            tipSize: 0.01
        }

        const popoverProps_Leaders = {
            isOpen: this.state.PopoverIsOpen_Leaders,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_Leaders'),
            body: this.renderPopoverContent('Leaders'),
            tipSize: 0.01
        }

        const popoverProps_MiddleLeaders = {
            isOpen: this.state.PopoverIsOpen_MiddleLeaders,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_MiddleLeaders'),
            body: this.renderPopoverContent('MiddleLeaders'),
            tipSize: 0.01
        }

        const popoverProps_TopLeaders = {
            isOpen: this.state.PopoverIsOpen_TopLeaders,
            preferPlace: 'below',
            onOuterAction: () => this.onTogglePopover('PopoverIsOpen_TopLeaders'),
            body: this.renderPopoverContent('TopLeaders'),
            tipSize: 0.01
        }

        var { DisplayName, Filters: { DHCs, Leaders, MiddleLeaders, TopLeaders }} = this.state;

        return (
            <styles.FiltersContainer className="FiltersContainer">
                <PageText FontFamily="bold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Filters" TextAlign="left" />

                <Spacer Size="extra-small" />

                <styles.FiltersSubContainer>
                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Search Companies" />

                            <Popover {...popoverProps_Companies}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_Companies')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_Companies')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <styles.SearchUsersInput
                            FontSize="medium-1"
                            NoLabel
                            NoMargin
                            OnBlur={this.onBlurSearchCompanies}
                            OnChange={this.onChangeCompanySearch}
                            Placeholder={t('_search')}
                            Size="medium"
                            Type="text"
                            Value={DisplayName}
                        />
                    </styles.FilterContainer>

                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="DHCs" />

                            <Popover {...popoverProps_DHCs}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_DHCs')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_DHCs')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            getOptionLabel={option => option.DisplayName}
                            getOptionValue={option => option.UserId}
                            isLoading={DHCs.IsLoading}
                            isMulti
                            // components={{ ValueContainer }}
                            hideSelectedOptions={true}
                            isSearchable
                            onBlur={this.onBlurFilter_DHCs}
                            onChange={data => this.onChangeFilter_DHCs(data)}
                            onFocus={this.onFocusFilter_DHCs}
                            options={DHCs.Users}
                            placeholder={t('Filter by DHCs')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>

                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Leaders" />

                            <Popover {...popoverProps_Leaders}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_Leaders')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_Leaders')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            getOptionLabel={option => option.DisplayName}
                            getOptionValue={option => option.UserId}
                            isLoading={Leaders.IsLoading}
                            isMulti
                            // components={{ ValueContainer }}
                            hideSelectedOptions={true}
                            isSearchable
                            onBlur={this.onBlurFilter_Leaders}
                            onChange={data => this.onChangeFilter_Leaders(data)}
                            onFocus={this.onFocusFilter_Leaders}
                            options={Leaders.Users}
                            placeholder={t('Filter by Leader')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>

                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Middle Leaders" />

                            <Popover {...popoverProps_MiddleLeaders}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_MiddleLeaders')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_MiddleLeaders')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            defaultValue={[]}
                            getOptionLabel={option => option.DisplayName}
                            getOptionValue={option => option.UserId}
                            isLoading={MiddleLeaders.IsLoading}
                            isMulti
                            // components={{ ValueContainer }}
                            hideSelectedOptions={false}
                            isSearchable
                            onBlur={this.onBlurFilter_MiddleLeaders}
                            onChange={data => this.onChangeFilter_MiddleLeaders(data)}
                            onFocus={this.onFocusFilter_MiddleLeaders}
                            options={MiddleLeaders.Users}
                            placeholder={t('Filter by Middle Leader')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>

                    <styles.FilterContainer className="FilterContainer">
                        <styles.FilterHeaderContainer className="FilterHeaderContainer">
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin TextAlign="left" Text="Top Leaders" />

                            <Popover {...popoverProps_TopLeaders}>
                                <styles.MaxPercentInfoIcon ImageSrc={InfoIcon} OnMouseEnter={() => this.onTogglePopover('PopoverIsOpen_TopLeaders')} OnMouseLeave={() => this.onTogglePopover('PopoverIsOpen_TopLeaders')} />
                            </Popover>
                        </styles.FilterHeaderContainer>

                        <Spacer Size="extra-extra-small" />

                        <Select
                            closeMenuOnSelect={false}
                            defaultValue={[]}
                            getOptionLabel={option => option.DisplayName}
                            getOptionValue={option => option.UserId}
                            isLoading={TopLeaders.IsLoading}
                            isMulti
                            // components={{ ValueContainer }}
                            hideSelectedOptions={false}
                            isSearchable
                            onBlur={this.onBlurFilter_TopLeaders}
                            onChange={data => this.onChangeFilter_TopLeaders(data)}
                            onFocus={this.onFocusFilter_TopLeaders}
                            options={TopLeaders.Users}
                            placeholder={t('Filter by Top Leader')}
                            styles={customStyles}
                            menuPosition="fixed"
                        />
                    </styles.FilterContainer>
                </styles.FiltersSubContainer>
            </styles.FiltersContainer>
        );
    }

    renderPopoverContent = ContentType => {
        // var { t } = this.props;

        return (
            <styles.PopoverContent className="PopoverContent">
                {ContentType === 'Companies' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Search by company name" TextAlign="center" />}
                {ContentType === 'DHCs' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Shows all companies who have ACTIVE orders with DHCs in the selected DHCs" TextAlign="center" />}
                {ContentType === 'Leaders' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Shows all companies who have ACTIVE orders with DHCS who has a leader in the selected leaders" TextAlign="center" />}
                {ContentType === 'MiddleLeaders' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Shows all companies who have ACTIVE orders with DHCS who has a leader who has a middle leader in the selected middle leaders" TextAlign="center" />}
                {ContentType === 'TopLeaders' && <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text="Shows all companies who have ACTIVE orders with DHCS who has a leader who has a middle leader who has a top leader in the selected top leaders" TextAlign="center" />}
            </styles.PopoverContent>
        );
    }

    renderShowCompanyDetailModal = () => {
        // var { t } = this.props;
        var { ShowCompanyDetailModal, ShowCompanyDetailModal_Loading } = this.state;

        if (ShowCompanyDetailModal) {
            return (
                <Info
                    BottomButton="cancel"
                    InformationRenderer={this.renderShowCompanyDetailModal_Information}
                    Loading={ShowCompanyDetailModal_Loading}
                    OnHide={() => this.onToggleShowCompanyDetailModal({ ShowCompanyDetailModal: false })}
                    Show={ShowCompanyDetailModal}
                    Size="small"
                />
            );
        }
    }

    renderShowCompanyDetailModal_Information = () => {
        var { ShowCompanyDetailModal_Data: CompanyDetails } = this.state;

        if (!CompanyDetails || Object.keys(CompanyDetails).length === 0) return null;

        var { CompanyName } = CompanyDetails;

        return (
            <styles.DHCDetailModalInfoContainer className="DHCDetailModalInfoContainer">
                <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="flex-start" NoMargin TextAlign="left" Text={CompanyName} />
            </styles.DHCDetailModalInfoContainer>
        )
    }

    renderViewCount = () => {
        var { t } = this.props;

        var { PageNo, PageSize, TotalRecords } = this.state;

        if (!TotalRecords) TotalRecords = 0;

        return (
            <div className="ViewCount">
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${!TotalRecords ? 0 : ((PageNo - 1) * PageSize) + 1} - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                &nbsp;
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords} companies`} />
            </div>
        )
    }

    render() {
        // var { t } = this.props;

        return (
            <>
                {this.props.TryingDeliverySiteAction && <Loading />}

                {this.renderFilters()}
                {this.renderCompanies()}

                {this.renderAddCompanyModal()}
                {this.renderShowCompanyDetailModal()}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        TryingDeliverySiteAction: state.DeliverySite.TryingDeliverySiteAction,
        TryingDeliverySiteActionError: state.DeliverySite.TryingDeliverySiteActionError
    };
};

export default withTranslation()(connect(mapStateToProps, { DeliverySite_GetCompanies, DeliverySite_GetCompanies_Basic, DeliverySite_GetDHCs_Basic, DeliverySite_GetCompanyDetails, DeliverySite_GetLeaders_Basic, DeliverySite_GetMiddleLeaders_Basic, DeliverySite_GetTopLeaders_Basic, DeliverySite_InsertEditCompany } )(CompaniesComponent));