import styled from 'styled-components';
import { device } from '../../Config/device';
import { Link } from 'react-router-dom';

import Image from '../../Components/Image';
import PageText from '../../Components/Text/PageText';

export const BoxListContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(314px, 1fr));
`;

export const CompactListContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(314px, 1fr));
`;

export const Container1 = styled.div``;

export const Container2 = styled.div``;

export const Container3 = styled.div`
    overflow: hidden;

    @media (max-width: 1350px) {
        display: none;
    }
`;

export const Container4 = styled.div``;

export const Container5 = styled.div`
    overflow: hidden;

    @media ${device.maxwidth.ipad} {
        display: none;
    }
`;

export const Container6 = styled.div`
    padding-right: 10px;
    width: 322px;
`;

export const Container7 = styled.div`
    padding-right: 10px;
    width: 118px;
`;

export const Container8 = styled.div``;

export const GroupHeaderContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: 20px 40px 20px 0px;

    ${
        props => props.HasPicture &&
        '> div:first-child { min-width: 75px; }'
    }

    ${
        props => props.Clickable &&
        `
            // @media(hover:hover) {
            //     :hover {
            //         cursor: pointer;
            //     }
            // }
        `
    }

    @media(min-width: 391px) {
        flex-direction: row;
        justify-content: flex-start;
    }
`;

export const GroupInfoContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media(min-width: 391px) {
        align-items: flex-start;
    }
`;

export const GroupInfoSubContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media(min-width: 301px) {
        flex-direction: row;
    }
`;

export const LoadMoreContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 15px;
`;

export const ListBodyContainer = styled.div`
    > div:not(:last-child) {
        border-bottom: 2px solid var(--white-concrete);
    }
`;

export const ListContainer = styled.div``;

export const ListHeaderContainer = styled.div`
    align-items: center;
    border-bottom: 2px solid var(--white-concrete);
    display: grid;
    grid-template-columns: 4fr 1fr 3.5fr 2fr 1.5fr 4.25fr 2fr 0.25fr;
    padding: 10px;

    @media (max-width: 1350px) {
        grid-template-columns: 4fr 1fr 2fr 1.5fr 4.25fr 2fr 0.25fr;
    }

    @media ${device.maxwidth.ipad} {
        grid-template-columns: 4fr 1fr 1.5fr 4.25fr 2fr 0.25fr;
    }
`;

export const StyledGroupName = styled(PageText)`
    @media(max-width: 390px) {
        text-align: center;
    }
`;

export const StyledLink = styled(Link)`
    color: var(--blue-abel);

    @media(hover:hover) {
        :hover {
            color: var(--link-hover);
            cursor: pointer;
        }
    }

    :active {
        color: var(--blue-abel);
    }
`;

export const StyledPageTextWithDotAfter = styled(PageText)`
    align-items: center;

    :after {
        background-color: var(--black);
        border-radius: 7px;
        content: '';
        height: 7px;
        margin: 0 7px;
        width: 7px;
    }
`;

export const StyledTeamPicture = styled(Image)`
    height: 75px;
    object-fit: cover;
    width: 75px;

    > div {
        width: 100%;
    }
`;