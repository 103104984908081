import styled from 'styled-components';
import { device } from '../../Config/device';

import BottomBar from '../../Components/BottomBar';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Image from '../../Components/Image';
import Input from '../../Components/Form/Input';
import PageText from '../../Components/Text/PageText';

import DeleteX from '../../Assets/Icons/Delete_X.png';
import DeleteX_Gray from '../../Assets/Icons/Delete_X_Gray.png';

export const ActivityLevelContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    display: flex;
    flex-direction: row;
    padding: 15px;

    :hover {
        background-color: var(--blue-astronaut);
        color: var(--white);
        cursor: pointer;
    }
`;

export const ActivityLevelsContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr;

    @media ${device.minwidth.ipad} {
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1050px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

export const ActivityLevelDetailsContainer = styled.div`
    margin-left: 15px;
`;

export const AdditionalInformationContainer = styled.div``;

export const AddGymNoteContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;

    @media ${device.minwidth.ipad} {
        flex-direction: row;
        width: 60%;
    }
`;

export const AnswerText = styled.div`
    color: var(--gray-silver);
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-medium-1);
    margin-left: 10px;
    max-width: 35%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const BottomBarHeightAdjustment = styled.div`
    height: ${props => props.Height}px;
    width: 100%;
`;

export const BottomBarItems = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    @media ${device.maxwidth.mobile} {
        :first-child {
            margin-bottom: 15px;
        }
    }

    @media ${device.minwidth.ipad} {
        justify-content: flex-start;

        :last-child {
            justify-content: flex-end;
        }
    }
`;

export const ButtonIcon = styled.img`
    height: 25px;
    margin-right: 10px;
    max-width: 25px;
`;

export const Container = styled.div`
    align-items: flex-start;
    display: flex;
    justify-content: center;
`;

export const DailyCalorieContainer = styled.div`
    flex: 1;
    opacity: ${props => props.Opacity};
`;

export const DefaultGymContainer = styled.div`
    align-items: center;
    background-color: var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 15px;
    width: 200px;
`;

export const DropdownContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    > div {
        width: 100%;

        :not(:last-child) {
            margin-bottom: 20px;
        }
    }

    @media ${device.minwidth.ipad} {
        align-items: flex-start;
        flex-direction: row;

        > div {
            min-width: 200px;
            width: fit-content;

            :not(:last-child) {
                margin-bottom: 0px;
                margin-right: 20px;
            }
        }
    }
`;

export const ExperienceLevelContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    display: flex;
    flex-direction: column;
    padding: 15px;

    :hover {
        background-color: var(--blue-astronaut);
        color: var(--white);
        cursor: pointer;
    }
`;

export const ExperienceLevelsContainer = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr;

    @media ${device.minwidth.laptop} {
        grid-template-columns: 1fr 1fr;
    }
`;

export const GymIcon = styled.img`
    margin-bottom: 15px;
    margin-right: 10px;    
    width: 50px;

    @media ${device.minwidth.ipad} {
        margin-bottom: 0px;
    }
`;

export const GymLogo = styled(Image)`
    height: 45px;
    max-width: 100%;
`;

export const HeightFeetIcon = styled(PageText)`
    color: var(--gray-silver);
    line-height: 0;

    :last-child {
    }
`;

export const HeightInput = styled(Input)`
    > input {
        border: none;
        line-height: 55px;
        text-align: center;
        text-indent: 0px;
        padding: 0px;
        width: 60px;
    }
`;

export const InformationContainer = styled.div`
    align-items: center;
    background-color: var(--white);
    border: 2px solid var(--white);
    border-radius: 26px;
    display: flex;
    flex-direction: row;
    height: 45px;
    justify-content: space-between;
    overflow: hidden;
    padding: 13px 22px;

    @media(hover:hover) {
        :hover {
            border: 2px solid var(--blue-abel);
            cursor: pointer;
        }
    }
`;

export const JournalArea = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    padding: 15px;
`;

export const JournalButton = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    padding: 15px;
    position: relative;
    width: 130px;

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const JournalIcon = styled.div`
    position: absolute;
    right: 15px;
    top: 15px;

    > img {
        height: 10px;
    }
`;

export const MediaPreview = styled.div`
    position: relative;
`;

export const MobileBottom = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const MobileTop = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media ${device.maxwidth.mobile} {
        justify-content: center;
    }
`;

export const MultiOptionContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px 30px;

    @media ${device.minwidth.mobile} {
        :last-child {
            grid-column-start: 3;
        }
    }

    ${props => props.Clickable &&
        `
            @media(hover:hover) {
                :hover {
                    border-color: var(--blue-abel);
                    cursor: pointer;
                }
            }
        `
    }
`;

export const MultiOptionsContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;

    @media ${device.minwidth.mobile} {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const MultiSelect = styled.span`
    ${props => props.Selected ? `background-image: url(${DeleteX});` : `background-image: url(${DeleteX_Gray});`}

    background-color: var(--white);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    border-radius: 50%;
    height: 20px;
    right: 5px;
    position: absolute;
    top: 5px;
    width: 20px;
    z-index: 1;

    @media(hover:hover) {
        :hover {
            background-image: url(${DeleteX});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 20px;
            border: none;
            cursor: pointer;
        }
    }
`;

export const MultiUnit = styled.div`
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    padding: 6px 15px;

    :hover {
        cursor: pointer;
    }
`;

export const MultiUnitContainer = styled.div`
    align-items: center;
    border: 2px solid var(--blue-astronaut);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    height: 35px;
    margin: 0 auto;
    overflow: hidden;
    width: fit-content;
`;

export const NumberOfMeal = styled.div`
    align-items: center;
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white)'};
    border: 2px solid var(--white-concrete);
    border-radius: 10px;
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    display: flex;
    justify-content: center;
    height: 45px;
    margin: 0 auto;
    width: 45px;

    :hover {
        background-color: var(--blue-astronaut);
        color: var(--white);
        cursor: pointer;
    }
`;

export const NumberOfMealsContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;

    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(4, 1fr);

    @media ${device.minwidth.ipad} {
        grid-template-columns: repeat(8, 1fr);
    }
`;

export const QuestionsContainer = styled.div`
    display: grid;
    grid-gap: 10px 20px;
    grid-template-columns: repeat(1fr);

    @media (min-width: 985px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const ProfilePhotoContainer = styled.div`
    margin-right: 5px;
    width: var(--profilephoto-size-small-3);
`;

export const SelectedItems = styled(PageText)`
    background-color: var(--blue-abel);
    border-radius: 20px;
    padding: 5px 15px;
`;

export const StyledBottomBar = styled(BottomBar)`
    > div {
        flex-direction: column;

        @media ${device.minwidth.ipad} {
            flex-direction: row;
        }
    }
`;

export const StyledExternalLink = styled.a`
    margin: 5px;
`;

export const StyledHR = styled.div`
    background-color: var(--white-concrete);
    border-radius: 10px;
    height: 2px;
    margin: 10px -30px;
`;

export const StyledMediaDocumentPreview = styled.div`
    align-items: center;
    background-color: var(--white);
    border: 2px solid var(--white-concrete);
    ${props => props.IsDeletedSelected && 'border-color: var(--red-bittersweet);'}
    ${props => props.IsNewMedia && 'border-color: var(--blue-abel);'}
    ${props => props.NewlySaved && 'border-color: var(--orange-selectiveyellow);'}
    border-radius: 10px;
    color: var(--black);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 45px;
    padding: 7px;
    padding-right: 30px;
    position: relative;
    
    ${
        props => !props.IsNewMedia &&
        `
            @media(hover:hover) {
                :hover {
                    color: var(--link-hover);
                    cursor: pointer;
                }
            }

            :active {
                color: var(--blue-abel);
            }
        `
    }
`;

export const StyledMediaPreview = styled(Image)`
    background-color: var(--white);
    ${props => props.IsDeletedSelected && 'border: 2px solid var(--red-bittersweet);'}
    ${props => props.IsNewMedia && 'border: 2px solid var(--blue-abel);'}
    ${props => props.NewlySaved && 'border: 2px solid var(--orange-selectiveyellow);'}

    border-radius: 10px;
    height: 45px;
    object-fit: cover;
    width: 90px;

    > div {
        width: 100%;
    }
`;

export const StyledVideoPreview = styled.video`
    background-color: var(--white);
    border: 2px solid var(--black);

    ${props => props.IsDeletedSelected && 'border-color: var(--red-bittersweet);'}
    ${props => props.IsNewMedia && 'border-color: var(--blue-abel);'}
    ${props => props.NewlySaved && 'border-color: var(--orange-selectiveyellow);'}

    border-radius: 10px;
    height: 45px;
    object-fit: cover;
    width: 90px;

    > div {
        width: 100%;
    }

    @media(hover:hover) {
        :hover {
            cursor: pointer;
        }
    }
`;

export const TitleText = styled.div`
    flex: 1;
    font-family: var(--font-family-semibold);
    font-size: var(--font-size-medium-1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const TopArea = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const TopAreaBottom = styled.div`
    width: 100%;
`;

export const TopAreaItem1 = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    > div:first-child {
        margin-right: 5px;
    }

    @media ${device.maxwidth.mobile} {
        justify-content: center;

        > div:first-child {
            margin-right: 0px;
        }
    }
`;

export const TopAreaItem2 = styled.div`
`;

export const TopAreaItem3 = styled.div`
    justify-self: flex-end;
`;

export const TopAreaTop = styled.div`
    align-items: center;
    width: 100%;

    @media ${device.maxwidth.mobile} {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @media ${device.minwidth.ipad} {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
`;

export const UnitInput = styled(Input)`
    > input {
        border: none;
        line-height: 55px;
        text-align: center;
        text-indent: 0px;
        padding: 0px;
    }
`;

export const UnitInputContainer = styled.div`
    align-items: center;
    border: 2px solid var(--white-concrete);
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;
    width: 200px;
`;

export const UploadButtonsContainer = styled(ButtonGroup)`
    justify-content: space-between;
`;

export const UploadContainer = styled.div`
    margin-top: 10px;
`;

export const UploadButtonsRow = styled.div`
    margin-bottom: 10px;
`;

export const UploadDocumentsContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    > div {
        margin: 5px;
    }
`;