import styled from 'styled-components';
import { device } from '../../Config/device';

export const PageNumberContainer = styled.div`
    align-items: center;
    background-color: ${props => props.Selected ? 'var(--blue-astronaut)' : 'var(--white-concrete)'};
    border-radius: 10px;
    color: ${props => props.Selected ? 'var(--white)' : 'var(--black)'};
    display: flex;
    flex-direction: row;
    font-family: var(--font-family-medium);
    font-size: var(--font-size-medium-1);
    height: 35px;
    justify-content: center;
    width: 35px;

    @media(hover:hover) {
        :hover {
            background-color: var(--blue-astronaut);
            color: var(--white);
            cursor: pointer;
        }
    }
`;

export const PageNumbersContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 10px;

    > div:not(:last-child) {
        margin-right: 10px;
    }
`;

export const PaginationContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media ${device.maxwidth.mobile_small} {
        flex-direction: column;
        justify-content: center;
    }
`;