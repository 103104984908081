import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { UnitContainer, UnitsContainer } from './styles';

import AbelModal from '../../Components/Modal';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

class PrivateDishCategories extends React.Component {
    onSelectCategory = ({ PrivateDishCategoryId, PrivateDishCategoryName }) => {
        var { OnHide, OnSelectCategory, SelectedCategoryId } = this.props;

        if (Number(PrivateDishCategoryId) === SelectedCategoryId) OnHide();
        else OnSelectCategory({ PrivateDishCategoryId, PrivateDishCategoryName });
    }

    render() {
        var { t } = this.props;
        var { IsLoading, OnHide, PrivateDishCategories, SelectedCategoryId, Show } = this.props;

        return (
            <AbelModal
                GA_PathName="PrivateDishCategories"
                Show={Show}
                Size="medium"
                OnHide={OnHide}
                TopButton="cancel"
                TopButtonOnClick={OnHide}
            >
                {IsLoading ? <Loading /> : null}

                <PageText FontFamily="medium" FontSize="medium-2" NoMargin Text={t('MealPlan_Which_category_do_you_want_to_move_these_dishes_to')} TextAlign="center" />

                <Spacer Size="medium" />

                <UnitsContainer>
                    {
                        PrivateDishCategories.map(({ PrivateDishCategoryId, PrivateDishCategoryName}) => {
                            var Selected = Number(PrivateDishCategoryId) === SelectedCategoryId;

                            return (
                                <UnitContainer key={PrivateDishCategoryId} Selected={Selected} onClick={() => this.onSelectCategory({ PrivateDishCategoryId, PrivateDishCategoryName })}>
                                    <PageText FontFamily="medium" FontSize="medium-1" NoMargin Text={PrivateDishCategoryName} />
                                </UnitContainer>
                            );
                        })
                    }
                </UnitsContainer>
            </AbelModal>
        );
    }
}

PrivateDishCategories.propTypes = {
    IsLoading: PropTypes.bool,
    OnHide: PropTypes.func.isRequired,
    OnSelectCategory: PropTypes.func.isRequired,
    PrivateDishCategories: PropTypes.array.isRequired,
    SelectedCategoryId: PropTypes.number,
    Show: PropTypes.bool.isRequired
}

export default withTranslation()(PrivateDishCategories);