import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { InsertEditAdditionalActivity } from '../../Services/Actions';
import moment from 'moment';

import TimePicker from 'rc-time-picker';

import { AdditionalActivityItem, AdditionalActivityList, DateTimeContainer, DateTimesContainer, DateTimeUseAllDays, DateTimeUseAllDaysContainer, HourType, HourTypesContainer, StyledDropdown, StyledDuration, StyledIntensities } from './styles';
import * as styles from './styles';

import ButtonGroup from '../Buttons/ButtonGroup';
import Loading from '../Loading';
import MultiText from '../Text/MultiText';
import PageText from '../Text/PageText';
import Spacer from '../Spacer';

import CheckmarkIcon from '../../Assets/Icons/Checkmark_White.png';
import DeleteX from '../../Assets/Icons/X_White.png';

class AdditionalActivity extends React.Component {
    state = {
        ActivityId: null,
        ActivityName: '',
        AssignedDate: null,
        Completed: false,
        DisplayingFilter: null,
        DropdownSize: 0,
        Duration: 0,
        HourType: 24,
        IntensityId: null,
        Location: '',
        OriginalActivityId: null,
        OriginalActivityName: '',
        OriginalAssignedDate: null,
        OriginalLocation: '',
        ShowDateTimes: null
    };

    componentDidMount() {
        var { AdditionalActivityDetails: { ActivityId, ActivityName, AssignedDate, Duration, IntensityId, ShowWorkoutTime, WorkoutLocation }, Completed, ExistingWorkoutId } = this.props;

        AssignedDate = moment(AssignedDate.substring(0,10) + ' ' + AssignedDate.substring(11,16)).format('YYYY-MM-DD HH:mm');

        if (!ExistingWorkoutId) console.log('get all the data');
        else this.setState({ ActivityId, ActivityName, AssignedDate, Completed, Duration, IntensityId, Location: WorkoutLocation, OriginalActivityId:ActivityId, OriginalActivityName:ActivityName, OriginalAssignedDate: AssignedDate, OriginalLocation: WorkoutLocation, ShowDateTimes: !!ShowWorkoutTime });
    }

    componentDidUpdate(prevProps) {
        var { AdditionalActivityDetails: { AssignedDate: PrevAssignedDate } } = prevProps;
        var { AdditionalActivityDetails: { AssignedDate: CurrAssignedDate } } = this.props;

        // Date changed for workout, so update AssignedDate && OriginalAssignedDate
            if (CurrAssignedDate !== PrevAssignedDate) this.setState({ AssignedDate: moment(CurrAssignedDate.substring(0,10) + ' ' + this.state.AssignedDate.substring(11,16)).format('YYYY-MM-DD HH:mm') });
    }

    onInsertEditAdditionalActivity = () => {
        var { ClientId, ExistingWorkoutId } = this.props;
        var { ActivityId, ActivityName, AssignedDate, Completed, Duration, IntensityId, Location, ShowDateTimes } = this.state;

        if (!ShowDateTimes) AssignedDate = `${AssignedDate.substring(0,10)} 00:00`;

        return this.props.InsertEditAdditionalActivity({ ActivityId, ActivityName, AssignedDate, ClientId, Completed, Duration, HasDateTimes: +ShowDateTimes, HasLocation: Location !== '', IntensityId, Location, WorkoutId: ExistingWorkoutId }).then(({ Response }) => {
            if (!this.props.TryingInsertEditAdditionalActivityError) this.props.OnUpdateWorkoutDetails({ CanDelete: !!!Completed, WorkoutCompleted: Completed });

            return { Response };
        });
    }

    onBlurLocation = () => {
        var { Location, OriginalLocation } = this.state;
        if (Location !== OriginalLocation) this.onInsertEditAdditionalActivity().then(({ Response }) => {
            if (Response) this.setState({ OriginalLocation: Location });
        });
    }

    onChangeDateTime = AssignedDate => {
        this.setState({ AssignedDate: moment(AssignedDate).format('YYYY-MM-DD HH:mm') });
    }

    onChangeDuration = event => {
        this.setState({ Duration: event.target.value });
    }

    onChangeDurationBlur = () => {
        this.props.ExistingWorkoutId && this.onInsertEditAdditionalActivity()
    }

    onChangeHourType = HourType => {
        this.setState({ HourType });
    }

    onChangeLocation = event => {
        this.setState({ Location: event.target.value });
    }

    onCloseTimePicker = () => {
        var { AssignedDate, OriginalAssignedDate } = this.state;

        if (AssignedDate !== OriginalAssignedDate) this.onInsertEditAdditionalActivity().then(({ Response }) => {
            if (Response) {
                this.props.OnUpdateAssignedDate(AssignedDate);
                this.setState({ OriginalAssignedDate: AssignedDate });
            }
        });
    }

    onGetSize = Height => {
        this.setState({ DropdownSize: Height });
    }

    onSelectAdditionalActivity = ({ ActivityId, ActivityName, ActivityPictures }) => {
        var { OnUpdateWorkoutImage, OnUpdateWorkoutName } = this.props;
        var { OriginalActivityId, OriginalActivityName } = this.state;

        if (ActivityId !== OriginalActivityId) {
            OnUpdateWorkoutImage(ActivityPictures[0]);
            OnUpdateWorkoutName(ActivityName);

            this.setState({ ActivityId, ActivityName, DisplayingFilter: null }, () => this.props.ExistingWorkoutId && this.onInsertEditAdditionalActivity().then(({ Response }) => {
                if (Response) this.setState({ OriginalActivityId: ActivityId, OriginalActivityName: ActivityName });
                else {
                    OnUpdateWorkoutName(OriginalActivityName);
                    this.setState({ ActivityId: OriginalActivityId, ActivityName: OriginalActivityName });
                };
            }));
        } else this.setState({ DisplayingFilter: null });
    }

    onSelectIntensity = IntensityId => {
        this.setState({ IntensityId }, () => this.props.ExistingWorkoutId && this.onInsertEditAdditionalActivity());
    }

    onToggleDropdown = (DisplayingFilter, ToggleStatus) => {
        this.setState({ DisplayingFilter: ToggleStatus ? DisplayingFilter : null });
    }

    onToggleShowDateTimes = () => {
        var { ShowDateTimes } = this.state;
        ShowDateTimes = !ShowDateTimes;

        this.setState({ ShowDateTimes }, () => {
            // InsertEdit so that it either updates ShowTime: 0, or ShowTime: 1 with time as midnight
                this.onInsertEditAdditionalActivity().then(({ Response }) => {
                    if (!Response) this.setState({ ShowDateTimes: !ShowDateTimes });
                });
        });
    }

    onUpdateCompletionStatus = () => {
        this.setState({ Completed: !this.state.Completed }, () => this.props.ExistingWorkoutId && this.onInsertEditAdditionalActivity());
    }

    renderAdditionalActivities = () => {
        var { AdditionalActivityDetails: { AdditionalActivities } } = this.props;

        return (
            <AdditionalActivityList>
                {
                    AdditionalActivities.map(({ label, Pictures, value }) => {
                        var Selected = value === this.state.ActivityId;

                        return (
                            <AdditionalActivityItem key={value} onClick={() => this.onSelectAdditionalActivity({ ActivityId: value, ActivityName: label, ActivityPictures: Pictures })} Selected={Selected}>
                                <PageText FontFamily="medium" FontSize="small" JustifyContent="flex-start" NoMargin Text={label} TextAlign="left" />
                            </AdditionalActivityItem>
                        );
                    })
                }
            </AdditionalActivityList>
        );
    }

    renderDateTimes = () => {
        var { t } = this.props;
        var { AssignedDate, HourType: StateHourType, ShowDateTimes } = this.state;

        return (
            <styles.DateTimeParentContainer className="DateTimeParentContainer">
                <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text={t('activity_start_time_scheduled')} TextAlign="left" />

                <DateTimeUseAllDaysContainer>
                    <DateTimeUseAllDays onClick={this.onToggleShowDateTimes} Selected={ShowDateTimes} />
                    <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={this.onToggleShowDateTimes} Text={t('additional_activity_choosetimes')} TextAlign="left" />
                </DateTimeUseAllDaysContainer>

                {
                    ShowDateTimes &&
                    <>
                        <Spacer Size="small" />

                        <HourTypesContainer>
                            <HourType onClick={() => this.onChangeHourType(12)} Selected={StateHourType === 12}>
                                <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('hour_type', { HourType: '12' })} />
                            </HourType>
                            <HourType onClick={() => this.onChangeHourType(24)} Selected={StateHourType === 24}>
                                <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={t('hour_type', { HourType: '24' })} />
                            </HourType>
                        </HourTypesContainer>

                        <Spacer Size="small" />

                        <DateTimesContainer className="DateTimesContainer">
                            <DateTimeContainer className="DateTimeContainer">
                                <PageText FontFamily="semibold" FontSize="medium-1" NoMargin Text={moment(AssignedDate).format('DD.MMM')} />
                                
                                <Spacer Size="extra-extra-small" />
                                
                                <TimePicker
                                    defaultValue={moment(AssignedDate)}
                                    minuteStep={5}
                                    onChange={DateTime => this.onChangeDateTime(DateTime)}
                                    onClose={this.onCloseTimePicker}
                                    showSecond={false}
                                    use12Hours={StateHourType === 12}
                                    value={moment(AssignedDate)}
                                />
                            </DateTimeContainer>
                        </DateTimesContainer>
                    </>
                }
            </styles.DateTimeParentContainer>
        );
    }

    renderIntensities = () => {
        var { Device, AdditionalActivityDetails: { Intensities } } = this.props;
        var Buttons = Intensities.map(({ IntensityId, IntensityName }) => ({ BackgroundColor: 'white', BackgroundColorHover: 'blue-astronaut', ColorHover: 'white', OnClick: () => this.onSelectIntensity(IntensityId), Selected: this.state.IntensityId === IntensityId, Title: IntensityName }));

        return (
            <StyledIntensities
                Buttons={Buttons}
                ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                NotTouching
                OwnRows={Device === 'mobile_small' || Device === 'mobile'}
            />
        );
    }

    renderWorkoutOptions = () => {
        // Complete / Incomplete, Delete Workout
        var { t } = this.props;
        var { Device, CanDelete, OnDeleteWorkout } = this.props;
        var { Completed } = this.state;

        var Buttons = [{ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Icon: CheckmarkIcon, IconPosition: 'left', FontFamily: 'semibold', OnClick: this.onUpdateCompletionStatus, Title: t(Number(Completed) ? 'Mark_as_incomplete' : 'Mark_as_completed') }];
        if (CanDelete) Buttons.push({ BackgroundColor: 'red-bittersweet', BackgroundColorHover: 'red-bittersweet-hover', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', Icon: DeleteX, IconPosition: 'left', OnClick: OnDeleteWorkout, Title: t('WorkoutPlan_Delete_workout') });

        return (
            <ButtonGroup
                Buttons={Buttons}
                ButtonWidth={Device === 'mobile' || Device === 'mobile_small' ? null : 'fit-content'}
                NotTouching
                OwnRows={Device === 'mobile' || Device === 'mobile_small' ? true : false}
            />
        );
    }

    render() {
        var { t } = this.props;
        var { Device, AdditionalActivityDetails: { AdditionalActivities }, SurveyData, TryingDeleteWorkout, TryingInsertEditAdditionalActivity, WorkoutType } = this.props;
        var { ActivityName, DisplayingFilter, DropdownSize, Duration, Location } = this.state;

        return (
            <>
                <styles.Container className="Container">
                    {(TryingDeleteWorkout || TryingInsertEditAdditionalActivity) && <Loading />}

                    <styles.ActivityContainer className="ActivityContainer">
                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" Text={t('WorkoutPlan_ChooseActivity')} TextAlign="left" />

                        <StyledDropdown
                            ContentRenderer={this.renderAdditionalActivities}
                            Disabled={false}
                            ForceClose={DisplayingFilter !== 'AdditionalActivities'}
                            GetSize={Height => this.onGetSize(Height)}
                            Height={DisplayingFilter === 'AdditionalActivities' && DropdownSize}
                            IsList={false}
                            IsMobile={Device === 'mobile_small' || Device === 'mobile'}
                            ItemId="AdditionalActivities"
                            NeedsToLoad={AdditionalActivities.length === 0}
                            OnToggleDropdown={ToggleStatus => this.onToggleDropdown('AdditionalActivities', ToggleStatus)}
                            SelectedItemIds={[]}
                            Title={ActivityName || t('WorkoutPlan_ChooseActivity')}
                            TitleFontColor={ActivityName ? 'blue-astronaut' : null}
                            TitleFontFamily="semibold"
                        />
                    </styles.ActivityContainer>

                    <styles.LocationContainer className="LocationContainer">
                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" Text={t('location')} TextAlign="left" />

                        <StyledDuration
                            NoLabel
                            NoMargin
                            OnBlur={this.onBlurLocation}
                            OnChange={this.onChangeLocation}
                            Placeholder={t('location_placeholder')}
                            Size="medium"
                            Type="text"
                            Value={Location}
                        />
                    </styles.LocationContainer>

                    {this.renderDateTimes()}
                </styles.Container>

                {
                    !!SurveyData &&
                    <>
                        <Spacer Size="medium" />

                        <PageText FontFamily="medium" FontSize="medium-2" JustifyContent="flex-start" Text={t('_mini_surveys_attached_to_workout')} TextAlign="left" />

                        {
                            SurveyData.map(({ SurveyResponse, SurveyType }, SurveyIndex) => {
                                return <MultiText key={SurveyIndex} Texts={[{ FontFamily: 'semibold', FontSize: 'small', Text: `${t(`_mini_survey_types_${SurveyType.toLowerCase()}`)}:` }, { FontFamily: 'medium', FontSize: 'small', Text: !!+SurveyResponse ? `${SurveyResponse} ${SurveyType === 'Sleep' ? t('_hours').toLowerCase() : ' / 10'}` : 'N/A' }]} />
                            })
                        }
                    </>
                }
                
                {
                    WorkoutType === 3 &&
                    <>
                        <Spacer Size="medium" />

                        <PageText FontFamily="medium" FontSize="medium-2" Text={t('WorkoutPlan_Intensity')} TextAlign="center" />
                        {this.renderIntensities()}

                        <Spacer Size="medium" />

                        <PageText FontFamily="medium" FontSize="medium-2" Text={t('WorkoutPlan_duration_on_minutes')} TextAlign="center" />
                        <StyledDuration
                            NoDecimal
                            NoLabel
                            NoMargin
                            OnBlur={this.onChangeDurationBlur}
                            OnChange={this.onChangeDuration}
                            Placeholder='0'
                            Size="medium"
                            Type="number"
                            Value={Duration}
                        />

                        <Spacer Size="medium" />

                        {this.renderWorkoutOptions()}
                    </>
                }
            </>
        );
    }
}

AdditionalActivity.propTypes = {
    AdditionalActivityDetails: PropTypes.object,
    CanDelete: PropTypes.bool,
    ClientId: PropTypes.number,
    Completed: PropTypes.bool,
    ExistingWorkoutId: PropTypes.number,
    OnDeleteWorkout: PropTypes.func.isRequired,
    OnUpdateAssignedDate: PropTypes.func.isRequired,
    OnUpdateWorkoutDetails: PropTypes.func.isRequired,
    OnUpdateWorkoutImage: PropTypes.func.isRequired,
    OnUpdateWorkoutName: PropTypes.func.isRequired,
    TryingDeleteWorkout: PropTypes.bool.isRequired,
    WorkoutType: PropTypes.number.isRequired
}

AdditionalActivity.defaultProps = {
    ExistingWorkoutId: 0
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        TryingInsertEditAdditionalActivity: state.Workout.TryingInsertEditAdditionalActivity,
        TryingInsertEditAdditionalActivityError: state.Workout.TryingInsertEditAdditionalActivityError,
    };
};

export default withTranslation()(connect(mapStateToProps, { InsertEditAdditionalActivity } )(AdditionalActivity));