import React from "react";
import { withTranslation } from "react-i18next";
import queryString from "query-string";
import { connect } from "react-redux";
import ButtonGroup from "../../Components/Buttons/ButtonGroup";
import Loading from "../../Components/Loading";
import PageText from "../../Components/Text/PageText";
import ScheduleVideoSession from "../../Components/ScheduleVideoSession/CreateSession";
import ScheduledVideoSessions from "../../Components/ScheduleVideoSession/GetSession";
import {
  AddCallsButtonContainer,
  ActiveTab,
  ActiveTabsContainer,
  ButtonsContainer,
  InstructionsContainer,
  Instructions,
  Pagination,
  PaginationContainer,
  PageNumberContainer,
  PageNumbersContainer,
  StyledHR,
  StyledPage,
  StyledVideoCallsContainer,
  VideoCallsHeader,
} from "./styles";
import InfoManual from "../../Assets/Icons/info@2x.png";
import PlusButtonActive from "../../Assets/Icons/PlusButton_Active.png";
import PlusButtonInactive from "../../Assets/Icons/PlusButton_Inactive.png";
import {
  DeleteVideoSession,
  GetAllVideoSessions,
} from "../../Services/Actions";
import moment from "moment";
import { PageRange } from "../../Functions";
import { Completed, Upcoming, Today } from "../../Utils/constants";

class VideoCalls extends React.Component {
  state = {
    SelectedAddButton: null,
    ButtonIcon: PlusButtonActive,
    SelectedSession: null,
    IsVideoSessionDeleted: false,
    SessionData: {
      Page: 1,
      When: Today,
      PageSize: 15,
    },
  };

  componentDidMount() {
    const { SessionData } = this.state;
    var QueryValues = queryString.parse(this.props.location.search);

    if (Object.entries(QueryValues).length > 0 && QueryValues.tab) {
      const tabName =
        QueryValues.tab[0].toUpperCase() + QueryValues.tab.substr(1);
      this.setState(
        { SessionData: { Page: 1, When: tabName, PageSize: 15 } },
        () => {
          this.props.GetAllVideoSessions({
            Page: 1,
            When: tabName,
            PageSize: 15,
          });
        }
      );
    } else {
      this.props.GetAllVideoSessions(SessionData);
    }
  }

  componentDidUpdate() {
    this.renderStartSessionButton();
    if (this.state.IsVideoSessionDeleted) {
      const { SessionData } = this.state;
      this.props.GetAllVideoSessions(SessionData);
      this.setState({ IsVideoSessionDeleted: false });
    }
  }

  onCancelSession() {
    this.setState({ SelectedSession: null });
  }

  hideCreateSession() {
    this.setState({ SelectedAddButton: null });
  }

  isSessionDeleted(IsVideoSessionDeleted) {
    this.setState({ IsVideoSessionDeleted });
  }

  onEditSession(SelectedSession) {
    this.setState({ SelectedSession });
  }

  onStartSession(hostUrl) {
    window.open(hostUrl, "_blank");
  }

  onToggleAddButtonState(button) {
    this.setState({ SelectedAddButton: button });
  }

  onMouseHoverIconState(icon) {
    this.setState({ ButtonIcon: icon });
  }

  onPaginate(pageNo) {
    const { SessionData } = this.state;
    this.setState({ SessionData: { ...SessionData, Page: pageNo } }, () => {
      this.props.GetAllVideoSessions({
        ...SessionData,
        Page: pageNo,
      });
    });
  }

  onTabChange(SessionListType) {
    this.setState(
      {
        SelectedSession: null,
        SessionData: {
          Page: 1,
          When: SessionListType,
          PageSize: 15,
        },
      },
      () => {
        this.props.GetAllVideoSessions({
          Page: 1,
          When: SessionListType,
          PageSize: 15,
        });
      }
    );
  }

  renderInstructions() {
    const { t } = this.props;
    return (
      <InstructionsContainer className="InstructionsContainer">
        <Instructions className="Instructions">
          <PageText
            FontFamily="medium"
            FontSize="medium-4"
            NoMargin
            JustifyContent="flex-start"
            Text={t("video_session_how_to")}
            TextAlign="left"
          />
          <PageText
            FontFamily="medium"
            FontSize="medium-1half"
            NoMargin
            JustifyContent="flex-start"
            Text={t("video_session_instruction_1")}
            TextAlign="left"
          />
          <PageText
            FontFamily="medium"
            FontSize="medium-1half"
            NoMargin
            JustifyContent="flex-start"
            Text={t("video_session_instruction_2")}
            TextAlign="left"
          />
          <PageText
            FontFamily="medium"
            FontSize="medium-1half"
            NoMargin
            JustifyContent="flex-start"
            Text={t("video_session_instruction_3")}
            TextAlign="left"
          />
        </Instructions>
      </InstructionsContainer>
    );
  }

  renderPagination = () => {
    const { t } = this.props;
    const { SessionData } = this.state;
    const { TotalPages } = this.props;

    const CurrentPageNo = SessionData.Page;

    const { EndPageNo, StartPageNo } = PageRange({
      PageNo: CurrentPageNo,
      TotalPages,
    });

    return (
      <PaginationContainer className="PaginationContainer">
        {!!TotalPages && (
          <Pagination className="Pagination" HasPrevLink={CurrentPageNo !== 1}>
            {TotalPages !== 1 && (
              <>
                {CurrentPageNo !== 1 && (
                  <PageText
                    FontColorHover={"blue-abel"}
                    FontFamily="semibold"
                    FontSize="medium-1"
                    JustifyContent="flex-start"
                    NoMargin
                    OnClick={() => this.onPaginate(CurrentPageNo - 1)}
                    Text={t("prev")}
                    TextAlign="left"
                  />
                )}

                <PageNumbersContainer className="PageNumbersContainer">
                  {Array(EndPageNo - StartPageNo + 1)
                    .fill()
                    .map((item, index) => {
                      var Selected = CurrentPageNo === StartPageNo + index;

                      return (
                        <PageNumberContainer
                          className="PageNumberContainer"
                          key={index}
                          onClick={() => this.onPaginate(StartPageNo + index)}
                          Selected={Selected}
                        >
                          {`${StartPageNo + index}`}
                        </PageNumberContainer>
                      );
                    })}
                </PageNumbersContainer>

                {CurrentPageNo !== TotalPages && (
                  <PageText
                    FontColorHover={"blue-abel"}
                    FontFamily="semibold"
                    FontSize="medium-1"
                    JustifyContent="flex-end"
                    NoMargin
                    OnClick={() => this.onPaginate(CurrentPageNo + 1)}
                    Text={t("next_with_first_char_uppercase")}
                    TextAlign="right"
                  />
                )}
              </>
            )}
          </Pagination>
        )}
      </PaginationContainer>
    );
  };

  renderStartSessionButton(session) {
    if (session) {
      const sessionDate = moment(session.Date);
      const currentDate = moment().utc();
      const minutesDiff = sessionDate.diff(currentDate, "minutes");
      return Math.abs(minutesDiff) <= 10;
    } else return false;
  }

  renderScheduledCalls() {
    const { t, VideoSessions, TryingGetVideoSessions } = this.props;
    const { SessionData } = this.state;

    return (
      <>
        <PageText
          FontFamily="medium"
          FontSize="medium-4"
          NoMargin
          JustifyContent="flex-start"
          Text={t("video_session_scheduled_calls")}
          TextAlign="left"
        />

        <ActiveTabsContainer className="ActiveTabsContainer">
          <ActiveTab>
            <PageText
              FontColor="black"
              FontFamily={SessionData.When === Today ? "bold" : "medium"}
              FontFamilyHover="bold"
              FontSize="medium-1"
              JustifyContent="flex-start"
              NoMargin
              OnClick={() => this.onTabChange(Today)}
              Selectable={true}
              SelectedOptions={{
                Position: "left",
                Selected: SessionData.When === Today,
              }}
              Text={Today}
              TextAlign="left"
            />
            <PageText
              FontColor="black"
              FontFamily={SessionData.When === Upcoming ? "bold" : "medium"}
              FontFamilyHover="bold"
              FontSize="medium-1"
              JustifyContent="flex-start"
              NoMargin
              OnClick={() => this.onTabChange(Upcoming)}
              Selectable={true}
              SelectedOptions={{
                Position: "left",
                Selected: SessionData.When === Upcoming,
              }}
              Text={Upcoming}
              TextAlign="left"
            />
            <PageText
              FontColor="black"
              FontFamily={SessionData.When === Completed ? "bold" : "medium"}
              FontFamilyHover="bold"
              FontSize="medium-1"
              JustifyContent="flex-start"
              NoMargin
              OnClick={() => this.onTabChange(Completed)}
              Selectable={true}
              SelectedOptions={{
                Position: "left",
                Selected: SessionData.When === Completed,
              }}
              Text={Completed}
              TextAlign="left"
            />
          </ActiveTab>

          {!TryingGetVideoSessions && this.renderPagination()}
        </ActiveTabsContainer>

        {!TryingGetVideoSessions ? (
          VideoSessions && VideoSessions.length === 0 ? (
            <PageText
              FontFamily="medium"
              FontSize="medium-1"
              NoMargin
              JustifyContent="flex-start"
              Text={
                SessionData.When === Today
                  ? t("video_session_no_call_for_today_note")
                  : SessionData.When === Upcoming
                  ? t("video_session_no_upcoming_call_note")
                  : t("video_session_no_completed_call_note")
              }
              TextAlign="left"
            />
          ) : (
            <>
              {SessionData.When !== Completed && (
                <PageText
                  FontFamily="medium"
                  FontSize="small"
                  JustifyContent="flex-start"
                  FontColor="warning"
                  Text={t("video_session_note_for_starting_session")}
                  TextAlign="left"
                />
              )}
              {VideoSessions.map((session) => {
                return session.VideoSessionId ===
                  (this.state.SelectedSession &&
                    this.state.SelectedSession.VideoSessionId) ? (
                  <ScheduleVideoSession
                    key={session.VideoSessionId}
                    CurrentPageStatus={this.state.SessionData}
                    WorkoutId={Number(this.state.SelectedSession.WorkoutId)}
                    SelectedSession={this.state.SelectedSession}
                    onCancelSession={this.onCancelSession.bind(this)}
                  />
                ) : (
                  <ScheduledVideoSessions
                    key={session.VideoSessionId}
                    session={session}
                    isSessionDeleted={this.isSessionDeleted.bind(this)}
                    CurrentPageStatus={this.state.SessionData}
                    onEditSession={this.onEditSession.bind(this)}
                  />
                );
              })}
            </>
          )
        ) : null}
      </>
    );
  }

  render() {
    const { t, Device, TryingGetVideoSessions, TryingDeleteVideoSession } =
      this.props;
    const { SelectedAddButton, ButtonIcon } = this.state;

    const Buttons = [
      {
        BackgroundColor: SelectedAddButton === 0 ? "blue-astronaut" : "gray",
        BackgroundColorHover: "blue-astronaut",
        Border: "none",
        ColorHover: "white",
        Color: SelectedAddButton === 0 ? "white" : "black",
        Icon: SelectedAddButton === 0 ? PlusButtonInactive : ButtonIcon,
        FontFamily: "medium",
        FontSize: "medium-1half",
        Title: t("video_session_add_call_to_a_day"),
        OnClick: () => this.onToggleAddButtonState(0),
        OnMouseEnter: () => this.onMouseHoverIconState(PlusButtonInactive),
        OnMouseLeave: () => this.onMouseHoverIconState(PlusButtonActive),
      },
    ];

    return (
      <div>
        <StyledPage NoMargin>
          <StyledVideoCallsContainer className="StyledVideoCallsContainer">
            {(TryingGetVideoSessions || TryingDeleteVideoSession) && (
              <Loading />
            )}

            <VideoCallsHeader className="VideoCallsHeader">
              <div>
                <PageText
                  FontFamily="medium"
                  FontSize="medium-4"
                  NoMargin
                  JustifyContent="flex-start"
                  Text={t("ptadmin_navigation_video_calls")}
                  TextAlign="left"
                />
                <PageText
                  FontFamily="medium"
                  FontSize="medium-1"
                  NoMargin
                  JustifyContent="flex-start"
                  Text={t("video_seesion_subtitle")}
                  TextAlign="left"
                />
              </div>
              {false && (
                <ButtonGroup
                  Buttons={[
                    {
                      Icon: InfoManual,
                      IconPosition: "top",
                      FontFamily: "medium",
                      FontSize: "medium-small",
                      Size: "small",
                      Title: "User Manual",
                    },
                  ]}
                />
              )}
            </VideoCallsHeader>
            <AddCallsButtonContainer className="AddCallsButtonContainer">
              <ButtonsContainer
                Buttons={Buttons}
                ButtonWidth="fit-content"
                ContainerWidth={
                  Device === "mobile_small" || Device === "mobile"
                    ? "100%"
                    : null
                }
                className="ButtonsContainer"
                NotTouching
              />
            </AddCallsButtonContainer>
            {SelectedAddButton === 0 && (
              <ScheduleVideoSession
                CurrentPageStatus={this.state.SessionData}
                hideCreateSession={this.hideCreateSession.bind(this)}
              />
            )}
            {/* {SelectedAddButton === 1 && this.renderInstructions()} */}
          </StyledVideoCallsContainer>

          <StyledHR />

          {this.renderScheduledCalls()}
        </StyledPage>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Device: state.Window.Device,
    VideoSessions: state.VideoSession.VideoSessions,
    TotalPages: state.VideoSession.TotalPages,
    TryingDeleteVideoSession: state.VideoSession.TryingDeleteVideoSession,
    TryingGetVideoSessions: state.VideoSession.TryingGetVideoSessions,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { DeleteVideoSession, GetAllVideoSessions })(
    VideoCalls
  )
);
