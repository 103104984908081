import {
    TRYING_BULK_ADD_CLIENTS,
    TRYING_BULK_ADD_CLIENTS_SUCCESS,
    TRYING_BULK_ADD_CLIENTS_ERROR,

    TRYING_GET_UPLOAD_CONTENT,
    TRYING_GET_UPLOAD_CONTENT_SUCCESS,
    TRYING_GET_UPLOAD_CONTENT_ERROR,

    TRYING_UPLOAD_CONTENT,
    TRYING_UPLOAD_CONTENT_SUCCESS,
    TRYING_UPLOAD_CONTENT_ERROR,
    TRYING_UPLOAD_CONTENT_PROGRESS
} from '../Types';

const INITIAL_STATE = {
    UploadProgress: {
        AddWorkouts: {},
        ClientJournalEntry: {},
        ClientNotes: {},
        CustomDish: {},
        CustomExercise: {},
        Expenses: {},
        SingleGenerated: {},
        Organization: {},
        PDFLogo: {},
        ProfilePhoto: {},
        UserGroup: {},
        Workout: {},
        WorkoutNotes: {},
        WorkoutTemplate: {}
    },

    TryingBulkAddClients: false,
    TryingBulkAddClientsError: null,
    TryingGetUploadContent: false,
    TryingGetUploadContentError: null,
    TryingUploadContent: false,
    TryingUploadContentError: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_BULK_ADD_CLIENTS:
            return { ...state, TryingBulkAddClients: true, TryingBulkAddClientsError: null };
        case TRYING_BULK_ADD_CLIENTS_ERROR:
            return { ...state, TryingBulkAddClients: false, TryingBulkAddClientsError: action.payload };
        case TRYING_BULK_ADD_CLIENTS_SUCCESS:
            return { ...state, TryingBulkAddClients: false, TryingBulkAddClientsError: null };

        case TRYING_GET_UPLOAD_CONTENT:
            return { ...state, TryingGetUploadContent: true, TryingGetUploadContentError: null };
        case TRYING_GET_UPLOAD_CONTENT_ERROR:
            return { ...state, TryingGetUploadContent: false, TryingGetUploadContentError: action.payload };
        case TRYING_GET_UPLOAD_CONTENT_SUCCESS:
            return { ...state, TryingGetUploadContent: false, TryingGetUploadContentError: null };

        case TRYING_UPLOAD_CONTENT:
            return { ...state, TryingUploadContent: true, TryingUploadContentError: null };
        case TRYING_UPLOAD_CONTENT_ERROR:
            return { ...state, TryingUploadContent: false, TryingUploadContentError: action.payload };
        case TRYING_UPLOAD_CONTENT_SUCCESS:
            return { ...state, TryingUploadContent: false, TryingUploadContentError: null };
        
        case TRYING_UPLOAD_CONTENT_PROGRESS:
            var { labelName, loaded, status, total, type } = action.payload;

            var NewUploadProgress = { ...state.UploadProgress };

            if (status === 'Complete') delete NewUploadProgress[type][labelName];
            else if (status === 'Uploading') NewUploadProgress[type][labelName] = { LabelName: labelName, Percentage: Math.floor((loaded / total) * 100) };

            return { ...state, UploadProgress: NewUploadProgress };

        default:
            return state;
    }
};
