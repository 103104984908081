import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ViewTrainerPaymentDashboard, ViewTrainerPaymentDashboardMonthly } from '../../Services/Actions';
import moment from 'moment';

import { BottomContainer, CalendarIcon, DashboardContainer, DataTypeContainer, DataTypeRadioButton, DataTypesContainer, DataTypesInfoContainer, DefinitionsContainer, MonthlyChartContainer, MonthlyContainer, MonthlyHeaderContainer, MonthlyHeaderLeftContainer, MonthlyHeaderRightContainer, MonthlyYearContainer, MonthlyYearsContainer, PaymentTypeContainer, PendingPayoutsContainer, SummariesContainer, SummaryContainer, SummarySection, SummarySections, SummaryTitleContainer, TimePeriodContainer, TitleContainer } from './styles';

import BarChart from '../../Components/Charts/BarChart';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

import Info from '../../Modals/Info';

import Calendar from '../../Assets/Icons/Calendar.png';

class Dashboard extends React.PureComponent {
    state = {
        Dashboard: {},
        HoveringMonthlyYearPopup: null,
        HoveringPaymentType: null,
        Loading: true,
        MonthlyYear: moment().year(),
        MonthlyYearPopup: null,
        PaymentType: 'sold',
        ShowChooseMonthlyYear: false,
        TimePeriodYears: [ ]
    }

    componentDidMount() {
        this.props.ViewTrainerPaymentDashboard({ MonthlyYear: this.state.MonthlyYear }).then(({ Dashboard }) => {
            this.setState({ Dashboard, Loading: false });
        });
    }

    onChangeMonthlyYearPopup = MonthlyYearPopup => {
        this.setState({ MonthlyYearPopup });
    }

    onHoverMonthlyYearPopup = HoveringMonthlyYearPopup => {
        this.setState({ HoveringMonthlyYearPopup });
    }

    onChangePaymentType = PaymentType => {
        this.setState({ PaymentType });
    }

    onHoverPaymentType = HoveringPaymentType => {
        this.setState({ HoveringPaymentType });
    }

    onLoadMonthly = ({ MonthlyYear }) => {
        this.setState({ Dashboard: { ...this.state.Dashboard, Monthly: { ...this.state.Dashboard.Monthly, Year: MonthlyYear } }, HoveringMonthlyYearPopup: null, MonthlyYearPopup: null, ShowChooseMonthlyYear: false }, () => {
            if (MonthlyYear !== this.state.MonthlyYear) {
                this.props.ViewTrainerPaymentDashboardMonthly({ MonthlyYear }).then(({ Monthly }) => {
                    this.setState({ Dashboard: { ...this.state.Dashboard, Monthly }, MonthlyYear });
                });
            }
        });
    }

    onToggleShowChooseMonthlyYear = ({ ShowChooseMonthlyYear = false }) => {
        var HoveringMonthlyYearPopup = null;
        var MonthlyYearPopup = null;

        if (ShowChooseMonthlyYear) MonthlyYearPopup = this.state.MonthlyYear;

        if (ShowChooseMonthlyYear && !this.state.TimePeriodYears.length) {
            var StartYear = moment(this.state.Dashboard.StartDate).year();
            var LastYear = moment(this.state.Dashboard.LastDate).year();

            var TimePeriodYears = [ StartYear ];

            if (LastYear !== StartYear) {
                for (var i = 1; i <= (LastYear - StartYear); i++) {
                    TimePeriodYears.push(StartYear + i);
                }
            }

            this.setState({ HoveringMonthlyYearPopup, MonthlyYearPopup, ShowChooseMonthlyYear, TimePeriodYears });
        }
        else this.setState({ HoveringMonthlyYearPopup, MonthlyYearPopup, ShowChooseMonthlyYear });
    }

    renderAllTime = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { AllTime, StartDate } = this.state.Dashboard;

        var JustifyContent = Device === 'laptop' ? 'center' : 'flex-start';
        var TextAlign = Device === 'laptop' ? 'center' : 'left';

        return (
            <SummaryContainer className="SummaryContainer">
                <SummaryTitleContainer className="SummaryTitleContainer">
                    <TitleContainer className="TitleContainer">
                        <PageText FontColor="white" FontFamily="semibold" FontSize="medium-1" JustifyContent={JustifyContent} NoMargin Text={t('AllTime')} TextAlign={TextAlign} />
                    </TitleContainer>

                    {StartDate && <PageText FontFamily="medium-italic" FontSize="small" JustifyContent={JustifyContent} NoMargin Text={`- ${t('since_date', { SinceDate: moment(StartDate).format('MMMM YYYY') })}`} TextAlign={TextAlign} />}
                </SummaryTitleContainer>

                <Spacer Size="small" />

                <SummarySections className="SummarySections">
                    <SummarySection className="SummarySection">
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent={JustifyContent} NoMargin Text={t('sold')} TextAlign={TextAlign} />
                        <PageText FontFamily="semibold" FontSize="medium-4" JustifyContent={JustifyContent} NoMargin Text={AllTime.SoldText} TextAlign={TextAlign} />
                        <PageText FontFamily="semibold-italic" FontSize="small" JustifyContent={JustifyContent} NoMargin Text={t('number_of_sales', { NumSales: AllTime.NumberOfSales })} TextAlign={TextAlign} />
                    </SummarySection>
                    <SummarySection className="SummarySection">
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent={JustifyContent} NoMargin Text={t('paid')} TextAlign={TextAlign} />
                        <PageText FontFamily="semibold" FontSize="medium-4" JustifyContent={JustifyContent} NoMargin Text={AllTime.PaidText} TextAlign={TextAlign} />
                    </SummarySection>
                    <SummarySection className="SummarySection">
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent={JustifyContent} NoMargin Text={t('pending_payments')} TextAlign={TextAlign} />
                        <PageText FontFamily="semibold" FontSize="medium-4" JustifyContent={JustifyContent} NoMargin Text={AllTime.PendingText} TextAlign={TextAlign} />
                    </SummarySection>
                </SummarySections>
            </SummaryContainer>
        );
    }

    renderDefinitions = () => {
        var { t } = this.props;

        return (
            <DefinitionsContainer className="DefinitionsContainer">
                <MultiText
                    Texts={[
                        { FontFamily: 'semibold', FontSize: 'small', Text: t('sold') },
                        { FontFamily: 'medium-italic', FontSize: 'small', Text: t('sold_definition') }
                    ]}
                />

                <Spacer Size="extra-small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'semibold', FontSize: 'small', Text: t('paid') },
                        { FontFamily: 'medium-italic', FontSize: 'small', Text: t('paid_definition') }
                    ]}
                />

                <Spacer Size="extra-small" />

                <MultiText
                    Texts={[
                        { FontFamily: 'semibold', FontSize: 'small', Text: t('pending_payments') },
                        { FontFamily: 'medium-italic', FontSize: 'small', Text: t('pending_payments_definition') }
                    ]}
                />
            </DefinitionsContainer>
        );
    }

    renderMonthlyChart = ({ Months, PaymentType }) => {
        var { Device } = this.props;

        var ChartData = Months.map(({ MonthNumber, Paid, PaidText, Pending, PendingText, Sold, SoldText }) => {
            var MonthObj = { Label: moment.months(MonthNumber - 1), Measurement: null, MeasurementText: '' };

            if (PaymentType === 'sold') { MonthObj.Measurement = Sold; MonthObj.MeasurementText = SoldText }
            else if (PaymentType === 'paid') { MonthObj.Measurement = Paid; MonthObj.MeasurementText = PaidText }
            else if (PaymentType === 'pending') { MonthObj.Measurement = Pending; MonthObj.MeasurementText = PendingText };

            return MonthObj;
        });

        var Orientation = (Device === 'laptop' || Device === 'ipad') ? 'vertical' : 'horizontal';

        return <BarChart ChartData={ChartData} Orientation={Orientation} ReRenderObj={{ ChartType: 'PaymentDashboard', MonthlyYear: this.state.MonthlyYear, PaymentType }} />;
    }

    renderMonths = () => {
        var { t } = this.props;
        var { Dashboard: { Monthly: { Months, Year } }, HoveringPaymentType, PaymentType } = this.state;

        return (
            <MonthlyContainer className="MonthlyContainer">
                <MonthlyHeaderContainer className="MonthlyHeaderContainer">
                    <MonthlyHeaderLeftContainer className="MonthlyHeaderLeftContainer">
                        <TitleContainer className="TitleContainer">
                            <PageText FontColor="white" FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('per_month')} TextAlign="center" />
                        </TitleContainer>
                    </MonthlyHeaderLeftContainer>
                    <MonthlyHeaderRightContainer className="MonthlyHeaderRightContainer">
                        <TimePeriodContainer className="TimePeriodContainer" onClick={() => this.onToggleShowChooseMonthlyYear({ ShowChooseMonthlyYear: true })}>
                            <MultiText
                                Texts={[
                                    { FontFamily: "medium", FontSize: "medium-1", Text: `${t('time_period')}:` },
                                    { FontFamily: "semibold", FontSize: "medium-1", Text: ` ${Year}` }
                                ]}
                            />

                            <CalendarIcon src={Calendar} alt="Choose year" />
                        </TimePeriodContainer>
                        <PaymentTypeContainer className="PaymentTypeContainer">
                            <DataTypesInfoContainer className="DataTypesInfoContainer">
                                <DataTypesContainer>
                                    <DataTypeContainer onMouseEnter={() => this.onHoverPaymentType('sold')} onMouseLeave={this.onHoverPaymentType}>
                                        <DataTypeRadioButton onClick={() => this.onChangePaymentType('sold')} Selected={PaymentType === 'sold' || HoveringPaymentType === 'sold'} />
                                        <PageText ElementType="span" FontColor={(PaymentType === 'sold' || HoveringPaymentType === 'sold') ? 'blue-astronaut' : 'black'} FontFamily="semibold" FontSize="medium-1" OnClick={() => this.onChangePaymentType('sold')} Text={t('sold')} WhiteSpace="nowrap" />
                                    </DataTypeContainer>
                                    <DataTypeContainer onMouseEnter={() => this.onHoverPaymentType('paid')} onMouseLeave={this.onHoverPaymentType}>
                                        <DataTypeRadioButton onClick={() => this.onChangePaymentType('paid')} Selected={PaymentType === 'paid' || HoveringPaymentType === 'paid'} />
                                        <PageText ElementType="span" FontColor={(PaymentType === 'paid' || HoveringPaymentType === 'paid') ? 'blue-astronaut' : 'black'} FontFamily="semibold" FontSize="medium-1" OnClick={() => this.onChangePaymentType('paid')} Text={t('paid')} WhiteSpace="nowrap" />
                                    </DataTypeContainer>
                                    <DataTypeContainer onMouseEnter={() => this.onHoverPaymentType('pending')} onMouseLeave={this.onHoverPaymentType}>
                                        <DataTypeRadioButton onClick={() => this.onChangePaymentType('pending')} Selected={PaymentType === 'pending' || HoveringPaymentType === 'pending'} />
                                        <PageText ElementType="span" FontColor={(PaymentType === 'pending' || HoveringPaymentType === 'pending') ? 'blue-astronaut' : 'black'} FontFamily="semibold" FontSize="medium-1" OnClick={() => this.onChangePaymentType('pending')} Text={t('pending_payments')} WhiteSpace="nowrap" />
                                    </DataTypeContainer>
                                </DataTypesContainer>
                            </DataTypesInfoContainer>    
                        </PaymentTypeContainer>
                    </MonthlyHeaderRightContainer>
                </MonthlyHeaderContainer>

                <Spacer Size="small" />

                <MonthlyChartContainer>
                    {this.renderMonthlyChart({ Months, PaymentType })}
                </MonthlyChartContainer>
            </MonthlyContainer>
        );
    }

    renderPendingPayouts = () => {
        var { t } = this.props;
        var { PendingPayoutText } = this.state.Dashboard;

        return (
            <PendingPayoutsContainer className="PendingPayoutsContainer">
                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('accounts_receivable')} TextAlign="center" />

                <Spacer Size="extra-extra-small" />

                <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('accounts_receivable_definition')} TextAlign="center" />

                <Spacer Size="small" />

                <PageText FontFamily="semibold" FontSize="medium-4" JustifyContent="center" NoMargin Text={PendingPayoutText} TextAlign="center" />
            </PendingPayoutsContainer>
        );
    }

    renderShowChooseMonthlyYearModalInformation = () => {
        var { t } = this.props;
        var { HoveringMonthlyYearPopup, MonthlyYearPopup, TimePeriodYears } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('select_year')} TextAlign="center" />

                <Spacer Size="medium" />

                <MonthlyYearsContainer className="MonthlyYearsContainer">
                    {
                        TimePeriodYears.map(TimePeriodYear => {
                            return (
                                <MonthlyYearContainer className="MonthlyYearContainer" key={TimePeriodYear} onMouseEnter={() => this.onHoverMonthlyYearPopup(TimePeriodYear)} onMouseLeave={this.onHoverMonthlyYearPopup}>
                                    <DataTypeRadioButton onClick={() => this.onChangeMonthlyYearPopup(TimePeriodYear)} Selected={MonthlyYearPopup === TimePeriodYear || HoveringMonthlyYearPopup === TimePeriodYear} />
                                    <PageText ElementType="span" FontColor={(MonthlyYearPopup === TimePeriodYear || HoveringMonthlyYearPopup === TimePeriodYear) ? 'blue-astronaut' : 'black'} FontFamily="semibold" FontSize="medium-1" OnClick={() => this.onChangeMonthlyYearPopup(TimePeriodYear)} Text={`${TimePeriodYear}`} />
                                </MonthlyYearContainer>
                            );
                        })
                    }
                </MonthlyYearsContainer>
            </>
        );
    }

    renderShowChooseMonthlyYearModal = () => {
        var { t } = this.props;
        var { MonthlyYearPopup, ShowChooseMonthlyYear } = this.state;

        if (ShowChooseMonthlyYear) {
            return (
                <Info
                    BottomButton="submit"
                    BottomButtonOnClick={() => this.onLoadMonthly({ MonthlyYear: MonthlyYearPopup })}
                    BottomButtonText={t('select_with_first_char_uppercase')}
                    InformationRenderer={this.renderShowChooseMonthlyYearModalInformation}
                    OnHide={() => this.onToggleShowChooseMonthlyYear({ ShowChooseMonthlyYear: false })}
                    Show={ShowChooseMonthlyYear}
                    Size="small"
                />
            );
        }
    }

    renderYearToDate = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { YearToDate } = this.state.Dashboard;

        var JustifyContent = Device === 'laptop' ? 'center' : 'flex-start';
        var TextAlign = Device === 'laptop' ? 'center' : 'left';

        return (
            <SummaryContainer className="SummaryContainer">
                <SummaryTitleContainer className="SummaryTitleContainer">
                    <TitleContainer className="TitleContainer">
                        <PageText FontColor="white" FontFamily="semibold" FontSize="medium-1" JustifyContent={JustifyContent} NoMargin Text={t('YearToDate')} TextAlign={TextAlign} />
                    </TitleContainer>

                    <PageText FontFamily="medium-italic" FontSize="small" JustifyContent={JustifyContent} NoMargin Text={`- ${moment().year()}`} TextAlign={TextAlign} />
                </SummaryTitleContainer>

                <Spacer Size="small" />

                <SummarySections className="SummarySections">
                    <SummarySection className="SummarySection">
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent={JustifyContent} NoMargin Text={t('sold')} TextAlign={TextAlign} />
                        <PageText FontFamily="semibold" FontSize="medium-4" JustifyContent={JustifyContent} NoMargin Text={YearToDate.SoldText} TextAlign={TextAlign} />
                        <PageText FontFamily="semibold-italic" FontSize="small" JustifyContent={JustifyContent} NoMargin Text={t('number_of_sales', { NumSales: YearToDate.NumberOfSales })} TextAlign={TextAlign} />
                    </SummarySection>
                    <SummarySection className="SummarySection">
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent={JustifyContent} NoMargin Text={t('paid')} TextAlign={TextAlign} />
                        <PageText FontFamily="semibold" FontSize="medium-4" JustifyContent={JustifyContent} NoMargin Text={YearToDate.PaidText} TextAlign={TextAlign} />
                    </SummarySection>
                    <SummarySection className="SummarySection">
                        <PageText FontFamily="medium" FontSize="medium-1" JustifyContent={JustifyContent} NoMargin Text={t('pending_payments')} TextAlign={TextAlign} />
                        <PageText FontFamily="semibold" FontSize="medium-4" JustifyContent={JustifyContent} NoMargin Text={YearToDate.PendingText} TextAlign={TextAlign} />
                    </SummarySection>
                </SummarySections>
            </SummaryContainer>
        );
    }

    render() {
        if (this.state.Loading || this.props.TryingViewTrainerPaymentDashboard) return <Loading />;

        var { t } = this.props;
        var { TryingViewTrainerPaymentDashboardMonthly } = this.props;

        return (
            <>
                <DashboardContainer className="DashboardContainer">
                    {(TryingViewTrainerPaymentDashboardMonthly) && <Loading />}

                    <PageText FontFamily="semibold" FontSize="medium-3" JustifyContent="center" NoMargin Text={t('Dashboard')} TextAlign="center" />

                    <PageText FontFamily="medium-italic" FontSize="small" JustifyContent="center" NoMargin Text={t('sales_dashboard_after_all_expenses')} TextAlign="center" />

                    <Spacer Size="small" />

                    <SummariesContainer className="SummariesContainer">
                        {this.renderAllTime()}
                        {this.renderYearToDate()}
                    </SummariesContainer>

                    <Spacer Size="medium" />

                    {this.renderMonths()}

                    <Spacer Size="medium" />

                    <BottomContainer className="BottomContainer">
                        {this.renderDefinitions()}
                        {this.renderPendingPayouts()}
                    </BottomContainer>
                </DashboardContainer>

                {this.renderShowChooseMonthlyYearModal()}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        TryingViewTrainerPaymentDashboard: state.Sales.TryingViewTrainerPaymentDashboard,
        TryingViewTrainerPaymentDashboardError: state.Sales.TryingViewTrainerPaymentDashboardError,
        TryingViewTrainerPaymentDashboardMonthly: state.Sales.TryingViewTrainerPaymentDashboardMonthly,
        TryingViewTrainerPaymentDashboardMonthlyError: state.Sales.TryingViewTrainerPaymentDashboardMonthlyError
    };
};

export default withTranslation()(connect(mapStateToProps, { ViewTrainerPaymentDashboard, ViewTrainerPaymentDashboardMonthly } )(Dashboard));