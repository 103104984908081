import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddLogFood, ClearSaveData, UpdateSelectedTab, ViewFoodUnits } from '../../Services/Actions';
import history from '../../history';
import moment from 'moment';

import { DeleteIcon, EditIcon, IngredientContainer, IngredientListContainer, IngredientNameStyled, IngredientOptionsContainer, IngredientQuantityStyledInput, IngredientsContainer, IngredientUnit, MarkAsCompletedContainer, MarkAsCompleted, ResponsiveColumns, SelectedDateContainer, SelectedDatesContainer } from './styles';

import AssignClients from '../../Components/AssignClients';
import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import PageText from '../../Components/Text/PageText';
import Page from '../../Components/Page';
import Spacer from '../../Components/Spacer';

import AddFood from '../../Modals/AddFood';
import ChooseDates from '../../Modals/ChooseDates';
import FoodUnits from '../../Modals/FoodUnits';

import Edit_Dark from '../../Assets/Icons/Edit_Dark.png';
import Plus from '../../Assets/Icons/Plus.png';

class AddLogFoodPage extends React.Component {
    _isMounted = false;

    state = {
        ExcludeFoodIds: [],
        Foods: [],
        FoodUnitsData: {},
        MarkCompleted: false,
        SelectedDates: [],
        SelectedUsers: [],
        SelectedUserIds: [],
        ShowAddFoodModal: false,
        ShowChooseDatesModal: false,
        ShowFoodUnitsModal: false
    };

    componentDidMount() {
        this._isMounted = true;

        var { ClientDetails, DayDate } = this.props;

        if (Object.entries(ClientDetails).length !== 0) {
            var SelectedDates = DayDate ? [ DayDate ] : [];
            var SelectedUsers = [];
            var SelectedUserIds = [];

            var ClientId = +ClientDetails.ClientId;

            SelectedUserIds = [ ClientId ];
            SelectedUsers = [{ ...ClientDetails, UserId: ClientId }];

            this.setState({ SelectedDates, SelectedUserIds, SelectedUsers });
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;

        this.props.ClearSaveData();
    }

    onAddFood = ({ FoodId, FoodName, UnitId, UnitName }) => {
        this.setState({ ShowAddFoodModal: false }, () => {
            this.props.ViewFoodUnits([ FoodId ]).then(FoodUnits => {
                if (!this.props.TryingViewFoodUnitsError) {
                    var ExcludeFoodIds = [ ...this.state.ExcludeFoodIds, +FoodId ];
                    var Foods = [ ...this.state.Foods, { FoodId, FoodName, Quantity: 1, UnitId, UnitName, dataUnits: FoodUnits[0].dataUnits }];
        
                    this.setState({ ExcludeFoodIds, Foods, ShowAddFoodModal: false });
                }
            })
        });
    }

    onAddLogFood = () => {
        var { Foods, MarkCompleted, SelectedDates, SelectedUserIds } = this.state;
        var dataFoodDetail = Foods.map(({ FoodId, Quantity, UnitId }) => ({ FoodId, Quantity, UnitId }));

        var ClientId = SelectedUserIds[0];

        this.props.AddLogFood({ dataFoodDetail, IsDone: MarkCompleted, MealDate: SelectedDates[0], UserId: ClientId }).then(() => {
            if (!this.props.TryingAddLogFoodError) history.push({ pathname: `/client/${ClientId}`, search: '?tab=meals' });
        });
    }

    onChangeFoodQuantity = ({ event, FoodIndex }) => {
        var Foods = [ ...this.state.Foods ];
        Foods[FoodIndex] = { ...this.state.Foods[FoodIndex], Quantity: event.target.value };

        this.setState({ Foods });
    }

    onDeleteFood = ({ FoodIndex }) => {
        var ExcludeFoodIds = [ ...this.state.ExcludeFoodIds.slice(0, FoodIndex), ...this.state.ExcludeFoodIds.slice(FoodIndex + 1) ];
        var Foods = [ ...this.state.Foods.slice(0, FoodIndex), ...this.state.Foods.slice(FoodIndex + 1) ];

        this.setState({ ExcludeFoodIds, Foods });
    }

    onSelectClients = ({ SelectedClients: SelectedUsers }) => {
        if (SelectedUsers.length) {
            var { SelectedUserIds } = this.state;

            if (SelectedUserIds[0] !== +SelectedUsers[0].UserId) {
                var SelectedUserIds_New = SelectedUsers.map(User => User.UserId);

                this.setState({ SelectedUsers, SelectedUserIds: SelectedUserIds_New });
            }
        } else this.setState({ SelectedUsers: [], SelectedUserIds: [] });
    }

    onSelectDates = SelectedDates => {
        this.setState({ SelectedDates, ShowChooseDatesModal: false });
    }

    onSelectUnit = ({ FoodIndex, UnitId, UnitName }) => {
        var Foods = [ ...this.state.Foods ];
        Foods[FoodIndex] = { ...this.state.Foods[FoodIndex], UnitId, UnitName };

        this.setState({ Foods, ShowFoodUnitsModal: false });
    }

    onToggleAddFoodModal = ShowAddFoodModal => {
        this.setState({ ShowAddFoodModal });
    }

    onToggleFoodUnitsModal = (ShowFoodUnitsModal, FoodIndex, FoodUnits, SelectedUnitId) => {
        this.setState({ ShowFoodUnitsModal, FoodUnitsData: { FoodIndex, FoodUnits, SelectedUnitId } });
    }

    onToggleMarkCompleted = () => {
        this.setState({ MarkCompleted: !this.state.MarkCompleted });
    }

    onToggleShowChooseDatesModal = ShowChooseDatesModal => {
        this.setState({ ShowChooseDatesModal });
    }

    renderAddFoodModal = () => {
        var { ExcludeFoodIds, ShowAddFoodModal } = this.state;

        if (ShowAddFoodModal) {
            return (
                <AddFood
                    ExcludeFoodIds={ExcludeFoodIds}
                    IngredientsShown={[ 1, 2 ]}
                    OnAddFood={Food => this.onAddFood(Food)}
                    OnHide={() => this.onToggleAddFoodModal(false)}
                    Show={ShowAddFoodModal}
                />
            );
        }
    }

    renderDates = () => {
        var { t } = this.props;
        var { SelectedDates } = this.state;

        return (
            <>
                <PageText FontFamily="medium" FontSize="medium-3" NoMargin Text={t('choose_date')} />
                
                <Spacer Size="small" />

                {
                    SelectedDates.length ?
                    this.renderSelectedDates()
                :
                    <ButtonGroup
                        Buttons={[
                            {
                                FontFamily: 'semibold',
                                Icon: Plus,
                                IconPosition: 'left',
                                OnClick: () => this.onToggleShowChooseDatesModal(true),
                                Title: t('add_date')
                            }
                        ]}
                        ButtonWidth="fit-content"
                        NotTouching
                    />
                }
            </>
        );
    }

    renderFoodUnitsModal = () => {
        var { FoodUnitsData: { FoodIndex, FoodUnits: SelectedFoodUnits, SelectedUnitId }, ShowFoodUnitsModal } = this.state;

        if (ShowFoodUnitsModal) {
            return (
                <FoodUnits
                    FoodUnits={SelectedFoodUnits}
                    OnHide={() => this.onToggleFoodUnitsModal(false)}
                    OnSelectUnit={({ UnitId, UnitName }) => this.onSelectUnit({ FoodIndex, UnitId, UnitName })}
                    SelectedUnitId={Number(SelectedUnitId)}
                    Show={ShowFoodUnitsModal}
                />
            );
        }
    }

    renderIngredients = () => {
        var { t } = this.props;
        var { Foods } = this.state;

        var Buttons = [{ Icon: Plus, IconPosition: 'left', OnClick: () => this.onToggleAddFoodModal(true), Title: `${t('MealPlan_Add_ingredient')}` }];

        return (
            <ResponsiveColumns>
                <IngredientsContainer>
                    <PageText FontFamily="medium" FontSize="medium-3" Text={t('ingredients')} />
                    <IngredientListContainer>
                        {
                            Foods.map(({ dataUnits, FoodId, FoodName, Quantity, UnitId, UnitName }, FoodIndex) => {
                                return (
                                    <IngredientContainer key={FoodId}>
                                        <IngredientNameStyled FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={FoodName} TextAlign="left" />
                                        <IngredientOptionsContainer>
                                            <IngredientQuantityStyledInput
                                                FontFamily="semibold"
                                                NoLabel
                                                NoMargin
                                                OnChange={event => this.onChangeFoodQuantity({ event, FoodIndex })}
                                                Type="number"
                                                Value={Quantity}
                                            />
                                            <IngredientUnit FontSize="medium-1" NoMargin OnClick={() => this.onToggleFoodUnitsModal(true, FoodIndex, dataUnits, UnitId)} OnClickTarget="container" Text={UnitName} />
                                            <DeleteIcon onClick={() => this.onDeleteFood({ FoodId, FoodIndex })}>X</DeleteIcon>
                                        </IngredientOptionsContainer>
                                    </IngredientContainer>
                                );
                            })
                        }
                        <ButtonGroup
                            Buttons={Buttons}
                        />
                    </IngredientListContainer>
                </IngredientsContainer>
            </ResponsiveColumns>
        )
    }

    renderSelectedDates = () => {
        var { SelectedDates } = this.state;

        return (
            <SelectedDatesContainer>
                {
                    SelectedDates.map(SelectedDate => 
                        <SelectedDateContainer key={SelectedDate}>
                            <PageText FontFamily="medium" FontSize="medium-1" JustifyContent="center" NoMargin Text={moment(SelectedDate).format('DD.MMM')} />
                        </SelectedDateContainer>
                    )
                }

                <EditIcon
                    ImageSrc={Edit_Dark}
                    Loading={false}
                    OnClick={() => this.onToggleShowChooseDatesModal(true)}
                />
            </SelectedDatesContainer>
        );
    }

    renderShowChooseDatesModal = () => {
        var { ShowChooseDatesModal } = this.state;

        if (ShowChooseDatesModal) {
            return (
                <ChooseDates
                    MaxDates={1}
                    OnHide={() => this.onToggleShowChooseDatesModal(false)}
                    OnSelectDates={SelectedDates => this.onSelectDates(SelectedDates)}
                    SelectedDates={this.state.SelectedDates}
                    Show={ShowChooseDatesModal}
                />
            );
        }
    }

    render() {
        var { t } = this.props;
        var { Device, TryingAddLogFood } = this.props;
        var { Foods, MarkCompleted, SelectedDates, SelectedUserIds, SelectedUsers } = this.state;

        var Breadcrumbs = [{ Name: t('logged_food_title') }];
        var SubmitButtonProps = { Disabled: (Foods.length === 0 || SelectedDates.length === 0 || SelectedUserIds.length === 0), OnClick: this.onAddLogFood, Title: t('add_logged_food') };

        return (
            <>
                {TryingAddLogFood && <Loading />}

                <Page renderBreadcrumbs={Breadcrumbs} renderSubmitButton={SubmitButtonProps}>
                    <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text={t('logged_food_title')} TextAlign="center" />

                    <Spacer Size={Device === 'laptop' ? 'extra-extra-large' : 'large'} />

                    <AssignClients
                        GetStartedEligible="Meals"
                        HideSelectAll
                        // HideTitle
                        // HorizontalScroll={false}
                        MaxClients={1}
                        OnSelectClients={Users => this.onSelectClients(Users)}
                        SelectedUserIds={SelectedUserIds}
                        SelectedUsers={SelectedUsers}
                    />

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderDates()}

                    <Spacer Size={Device === 'laptop' || Device === 'ipad' ? 'extra-large' : 'large'} />

                    {this.renderIngredients()}

                    <Spacer Size={Device === 'laptop' ? 'extra-extra-large' : 'large'} />

                    <MarkAsCompletedContainer>
                        <MarkAsCompleted onClick={this.onToggleMarkCompleted} Selected={MarkCompleted} />
                        <PageText ElementType="span" FontFamily="medium-italic" FontSize="medium-1" OnClick={this.onToggleMarkCompleted} Text={t('Mark_as_completed')} TextAlign="left" />
                    </MarkAsCompletedContainer>
                </Page>

                {this.renderAddFoodModal()}
                {this.renderFoodUnitsModal()}
                {this.renderShowChooseDatesModal()}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        ClientDetails: state.SaveData.ClientDetails,
        DayDate: state.SaveData.DayDate,

        TryingViewFoodUnits: state.Food.TryingViewFoodUnits,
        TryingViewFoodUnitsError: state.Food.TryingViewFoodUnitsError,

        TryingAddLogFood: state.Meals.TryingAddLogFood,
        TryingAddLogFoodError: state.Meals.TryingAddLogFoodError
    };
};

export default withTranslation()(connect(mapStateToProps, { AddLogFood, ClearSaveData, UpdateSelectedTab, ViewFoodUnits } )(AddLogFoodPage));